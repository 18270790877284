import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  date: any;
  float8: any;
  timestamp: any;
  timestamptz: any;
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars["String"]>;
  _gt?: Maybe<Scalars["String"]>;
  _gte?: Maybe<Scalars["String"]>;
  _ilike?: Maybe<Scalars["String"]>;
  _in?: Maybe<Array<Scalars["String"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _like?: Maybe<Scalars["String"]>;
  _lt?: Maybe<Scalars["String"]>;
  _lte?: Maybe<Scalars["String"]>;
  _neq?: Maybe<Scalars["String"]>;
  _nilike?: Maybe<Scalars["String"]>;
  _nin?: Maybe<Array<Scalars["String"]>>;
  _nlike?: Maybe<Scalars["String"]>;
  _nsimilar?: Maybe<Scalars["String"]>;
  _similar?: Maybe<Scalars["String"]>;
};

/** expression to compare columns of type bigint. All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars["bigint"]>;
  _gt?: Maybe<Scalars["bigint"]>;
  _gte?: Maybe<Scalars["bigint"]>;
  _in?: Maybe<Array<Scalars["bigint"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["bigint"]>;
  _lte?: Maybe<Scalars["bigint"]>;
  _neq?: Maybe<Scalars["bigint"]>;
  _nin?: Maybe<Array<Scalars["bigint"]>>;
};

/** expression to compare columns of type date. All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars["date"]>;
  _gt?: Maybe<Scalars["date"]>;
  _gte?: Maybe<Scalars["date"]>;
  _in?: Maybe<Array<Scalars["date"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["date"]>;
  _lte?: Maybe<Scalars["date"]>;
  _neq?: Maybe<Scalars["date"]>;
  _nin?: Maybe<Array<Scalars["date"]>>;
};

/** expression to compare columns of type float8. All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: Maybe<Scalars["float8"]>;
  _gt?: Maybe<Scalars["float8"]>;
  _gte?: Maybe<Scalars["float8"]>;
  _in?: Maybe<Array<Scalars["float8"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["float8"]>;
  _lte?: Maybe<Scalars["float8"]>;
  _neq?: Maybe<Scalars["float8"]>;
  _nin?: Maybe<Array<Scalars["float8"]>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: "mutation_root";
  /** delete data from the table: "rtcs_db.EntityFilter" */
  delete_rtcs_db_EntityFilter?: Maybe<Rtcs_Db_EntityFilter_Mutation_Response>;
  /** delete data from the table: "rtcs_db.Filter" */
  delete_rtcs_db_Filter?: Maybe<Rtcs_Db_Filter_Mutation_Response>;
  /** delete data from the table: "rtcs_db.GazzetteRates" */
  delete_rtcs_db_GazzetteRates?: Maybe<Rtcs_Db_GazzetteRates_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_EntityFilter" */
  delete_rtcs_db_PH_EntityFilter?: Maybe<Rtcs_Db_Ph_EntityFilter_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_Filter" */
  delete_rtcs_db_PH_Filter?: Maybe<Rtcs_Db_Ph_Filter_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_GazzetteRates" */
  delete_rtcs_db_PH_GazzetteRates?: Maybe<Rtcs_Db_Ph_GazzetteRates_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
  delete_rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_RPT_IndividualComposite" */
  delete_rtcs_db_PH_RPT_IndividualComposite?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_RPT_IndividualRegistration" */
  delete_rtcs_db_PH_RPT_IndividualRegistration?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
  delete_rtcs_db_PH_RPT_IndividualTaxSummaryData?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_RPT_KccaAffiliates" */
  delete_rtcs_db_PH_RPT_KccaAffiliates?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
  delete_rtcs_db_PH_RPT_KccaCustomerRegistrationData?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_RPT_KccaPropertyRegistration" */
  delete_rtcs_db_PH_RPT_KccaPropertyRegistration?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
  delete_rtcs_db_PH_RPT_KccaPropertyRegistrationData?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
  delete_rtcs_db_PH_RPT_NonIndividualBalanceSheet?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
  delete_rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_RPT_NonIndividualComposite" */
  delete_rtcs_db_PH_RPT_NonIndividualComposite?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
  delete_rtcs_db_PH_RPT_NonIndividualTaxSummaryData?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_RPT_PropertiesComposite" */
  delete_rtcs_db_PH_RPT_PropertiesComposite?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_RPT_PropertyValuation" */
  delete_rtcs_db_PH_RPT_PropertyValuation?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_RPT_RentalTenantPayments" */
  delete_rtcs_db_PH_RPT_RentalTenantPayments?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_gazette_CommercialRates" */
  delete_rtcs_db_PH_gazette_CommercialRates?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_gazette_ResidentialRates" */
  delete_rtcs_db_PH_gazette_ResidentialRates?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_AssociateType" */
  delete_rtcs_db_PH_kcca_AssociateType?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_CommercialProperties" */
  delete_rtcs_db_PH_kcca_CommercialProperties?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_County" */
  delete_rtcs_db_PH_kcca_County?: Maybe<Rtcs_Db_Ph_Kcca_County_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_Customer" */
  delete_rtcs_db_PH_kcca_Customer?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_CustomerApplicantType" */
  delete_rtcs_db_PH_kcca_CustomerApplicantType?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
  delete_rtcs_db_PH_kcca_CustomerBusinessAssociate?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_CustomerBusinessType" */
  delete_rtcs_db_PH_kcca_CustomerBusinessType?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
  delete_rtcs_db_PH_kcca_CustomerCoinDeactivateReactive?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_CustomerContactPerson" */
  delete_rtcs_db_PH_kcca_CustomerContactPerson?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_CustomerDocumentItem" */
  delete_rtcs_db_PH_kcca_CustomerDocumentItem?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_CustomerGuardian" */
  delete_rtcs_db_PH_kcca_CustomerGuardian?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_CustomerReference" */
  delete_rtcs_db_PH_kcca_CustomerReference?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_CustomerRegistrationReason" */
  delete_rtcs_db_PH_kcca_CustomerRegistrationReason?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_CustomerRevenueAgent" */
  delete_rtcs_db_PH_kcca_CustomerRevenueAgent?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_CustomerType" */
  delete_rtcs_db_PH_kcca_CustomerType?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_Customer_sid" */
  delete_rtcs_db_PH_kcca_Customer_sid?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_District" */
  delete_rtcs_db_PH_kcca_District?: Maybe<Rtcs_Db_Ph_Kcca_District_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_Division" */
  delete_rtcs_db_PH_kcca_Division?: Maybe<Rtcs_Db_Ph_Kcca_Division_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_ImgsData" */
  delete_rtcs_db_PH_kcca_ImgsData?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_Parish" */
  delete_rtcs_db_PH_kcca_Parish?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_PaymentDetails" */
  delete_rtcs_db_PH_kcca_PaymentDetails?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_Payments" */
  delete_rtcs_db_PH_kcca_Payments?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_Property" */
  delete_rtcs_db_PH_kcca_Property?: Maybe<Rtcs_Db_Ph_Kcca_Property_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_PropertyFloors" */
  delete_rtcs_db_PH_kcca_PropertyFloors?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
  delete_rtcs_db_PH_kcca_PropertyNumberBedrooms?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_PropertyRentedStatus" */
  delete_rtcs_db_PH_kcca_PropertyRentedStatus?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_PropertySecurity" */
  delete_rtcs_db_PH_kcca_PropertySecurity?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_PropertySubType" */
  delete_rtcs_db_PH_kcca_PropertySubType?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_PropertyType" */
  delete_rtcs_db_PH_kcca_PropertyType?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_RawProperties1" */
  delete_rtcs_db_PH_kcca_RawProperties1?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_RawProperties2" */
  delete_rtcs_db_PH_kcca_RawProperties2?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_ResidentialProperties" */
  delete_rtcs_db_PH_kcca_ResidentialProperties?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_RevenueType" */
  delete_rtcs_db_PH_kcca_RevenueType?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_SubCounty" */
  delete_rtcs_db_PH_kcca_SubCounty?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
  delete_rtcs_db_PH_kcca_SubpropertyTypeConversion?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_TradingLicense" */
  delete_rtcs_db_PH_kcca_TradingLicense?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_kcca_Village" */
  delete_rtcs_db_PH_kcca_Village?: Maybe<Rtcs_Db_Ph_Kcca_Village_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_master_MasterIndiv" */
  delete_rtcs_db_PH_master_MasterIndiv?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_mlhud_KCCA" */
  delete_rtcs_db_PH_mlhud_KCCA?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_mlhud_Mukono" */
  delete_rtcs_db_PH_mlhud_Mukono?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_mlhud_Properties" */
  delete_rtcs_db_PH_mlhud_Properties?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_mlhud_WakisoBusiro" */
  delete_rtcs_db_PH_mlhud_WakisoBusiro?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_mlhud_WakisoKyadondo" */
  delete_rtcs_db_PH_mlhud_WakisoKyadondo?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_nira_Nira" */
  delete_rtcs_db_PH_nira_Nira?: Maybe<Rtcs_Db_Ph_Nira_Nira_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_nira_RawData" */
  delete_rtcs_db_PH_nira_RawData?: Maybe<Rtcs_Db_Ph_Nira_RawData_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_nwsc_Customers" */
  delete_rtcs_db_PH_nwsc_Customers?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_nwsc_NWCustomers" */
  delete_rtcs_db_PH_nwsc_NWCustomers?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_nwsc_RPT_Transactions" */
  delete_rtcs_db_PH_nwsc_RPT_Transactions?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_nwsc_Transactions" */
  delete_rtcs_db_PH_nwsc_Transactions?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_nwsc_URATransactions" */
  delete_rtcs_db_PH_nwsc_URATransactions?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rcapture_RPTUnittypes" */
  delete_rtcs_db_PH_rcapture_RPTUnittypes?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rcapture_RPTlessors" */
  delete_rtcs_db_PH_rcapture_RPTlessors?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rcapture_RPTmeta" */
  delete_rtcs_db_PH_rcapture_RPTmeta?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rcapture_RPTproperties" */
  delete_rtcs_db_PH_rcapture_RPTproperties?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rcapture_RPTtenants" */
  delete_rtcs_db_PH_rcapture_RPTtenants?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_Individual" */
  delete_rtcs_db_PH_rmatch_Individual?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
  delete_rtcs_db_PH_rmatch_IndividualComplianceDetailReport?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
  delete_rtcs_db_PH_rmatch_IndividualComplianceSummaryReport?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_IndividualRiskScore" */
  delete_rtcs_db_PH_rmatch_IndividualRiskScore?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_IndividualsNetwork" */
  delete_rtcs_db_PH_rmatch_IndividualsNetwork?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
  delete_rtcs_db_PH_rmatch_IndividualsNetworkTesting?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
  delete_rtcs_db_PH_rmatch_KccaToKccaIndividualOwners?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
  delete_rtcs_db_PH_rmatch_KccaToKccaNonindividualOwners?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
  delete_rtcs_db_PH_rmatch_MatchIndividualsProperties?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_MatchIndividualsProps" */
  delete_rtcs_db_PH_rmatch_MatchIndividualsProps?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_NoReturns" */
  delete_rtcs_db_PH_rmatch_NoReturns?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_NonIndividual" */
  delete_rtcs_db_PH_rmatch_NonIndividual?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
  delete_rtcs_db_PH_rmatch_NonIndividualComplianceDetailReport?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
  delete_rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
  delete_rtcs_db_PH_rmatch_NonIndividualRiskScore?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_NonIndividuals" */
  delete_rtcs_db_PH_rmatch_NonIndividuals?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
  delete_rtcs_db_PH_rmatch_NonIndividualsNetwork?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_NonindividualOwners" */
  delete_rtcs_db_PH_rmatch_NonindividualOwners?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_Nonindividuals" */
  delete_rtcs_db_PH_rmatch_Nonindividuals?: Maybe<Rtcs_Db_Ph_Rmatch_Nonindividuals_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_PropertiesComposite" */
  delete_rtcs_db_PH_rmatch_PropertiesComposite?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_PropertiesNetwork" */
  delete_rtcs_db_PH_rmatch_PropertiesNetwork?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_PropertyNetwork" */
  delete_rtcs_db_PH_rmatch_PropertyNetwork?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_RMatchDetailed904" */
  delete_rtcs_db_PH_rmatch_RMatchDetailed904?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_RMatchDetailed916" */
  delete_rtcs_db_PH_rmatch_RMatchDetailed916?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
  delete_rtcs_db_PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_RMatchDetailed937" */
  delete_rtcs_db_PH_rmatch_RMatchDetailed937?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_RMatchKccaProperties" */
  delete_rtcs_db_PH_rmatch_RMatchKccaProperties?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_RMatchKccaProperty" */
  delete_rtcs_db_PH_rmatch_RMatchKccaProperty?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
  delete_rtcs_db_PH_rmatch_RMatchNonIndividual903?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_RMatchProperties1115" */
  delete_rtcs_db_PH_rmatch_RMatchProperties1115?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_RMatchSummary904" */
  delete_rtcs_db_PH_rmatch_RMatchSummary904?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_RMatchSummary916" */
  delete_rtcs_db_PH_rmatch_RMatchSummary916?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
  delete_rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_RMatchSummary937" */
  delete_rtcs_db_PH_rmatch_RMatchSummary937?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_TinsURA" */
  delete_rtcs_db_PH_rmatch_TinsURA?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_Undeclared" */
  delete_rtcs_db_PH_rmatch_Undeclared?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_Underreported" */
  delete_rtcs_db_PH_rmatch_Underreported?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_Unregistered" */
  delete_rtcs_db_PH_rmatch_Unregistered?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_rmatch_mlhudConsolidated" */
  delete_rtcs_db_PH_rmatch_mlhudConsolidated?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
  delete_rtcs_db_PH_streetmap_OsmMukonoMunicipality?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
  delete_rtcs_db_PH_streetmap_OsmMukonoNakifuma?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
  delete_rtcs_db_PH_streetmap_OsmWakisoEntebbe?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_streetmap_OsmWakisoKira" */
  delete_rtcs_db_PH_streetmap_OsmWakisoKira?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
  delete_rtcs_db_PH_streetmap_OsmWakisoMakindyeSsabagabo?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_streetmap_OsmWakisoNansana" */
  delete_rtcs_db_PH_streetmap_OsmWakisoNansana?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ucc_UCC" */
  delete_rtcs_db_PH_ucc_UCC?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_umeme_SubscriberAndProperty" */
  delete_rtcs_db_PH_umeme_SubscriberAndProperty?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_umeme_Transactions" */
  delete_rtcs_db_PH_umeme_Transactions?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_BalanceSheetInformation" */
  delete_rtcs_db_PH_ura_BalanceSheetInformation?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
  delete_rtcs_db_PH_ura_CombinedOfficersAndOwners?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_EmploymentIncomeDetail" */
  delete_rtcs_db_PH_ura_EmploymentIncomeDetail?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
  delete_rtcs_db_PH_ura_GazetteCommercialRatesPerSquareFootage?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
  delete_rtcs_db_PH_ura_GazetteResidentialRatesPerBedroom?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_HoldingOfficers" */
  delete_rtcs_db_PH_ura_HoldingOfficers?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_HoldingOwner" */
  delete_rtcs_db_PH_ura_HoldingOwner?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_IncomeTaxHolding" */
  delete_rtcs_db_PH_ura_IncomeTaxHolding?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
  delete_rtcs_db_PH_ura_IncomeTaxSummaryIndividual?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
  delete_rtcs_db_PH_ura_IncomeTaxSummaryNonIndividual?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_IndividualMortgage" */
  delete_rtcs_db_PH_ura_IndividualMortgage?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
  delete_rtcs_db_PH_ura_IndividualsAssociatedBusiness?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_LandlordIncomeDetail" */
  delete_rtcs_db_PH_ura_LandlordIncomeDetail?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_LandlordIncomeSummary" */
  delete_rtcs_db_PH_ura_LandlordIncomeSummary?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
  delete_rtcs_db_PH_ura_NonIndividualsAssociatedBusiness?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_RegAssociates" */
  delete_rtcs_db_PH_ura_RegAssociates?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_RegIndividual" */
  delete_rtcs_db_PH_ura_RegIndividual?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_RegIndividual_sid" */
  delete_rtcs_db_PH_ura_RegIndividual_sid?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_RegNonIndividual" */
  delete_rtcs_db_PH_ura_RegNonIndividual?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_RegNonIndividual_sid" */
  delete_rtcs_db_PH_ura_RegNonIndividual_sid?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_RentExpenseDetails" */
  delete_rtcs_db_PH_ura_RentExpenseDetails?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_RentalLandLordIncome" */
  delete_rtcs_db_PH_ura_RentalLandLordIncome?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_RentalTenantPayments" */
  delete_rtcs_db_PH_ura_RentalTenantPayments?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
  delete_rtcs_db_PH_ura_RipoIndRentalIncSummary?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_TenantIncomeDetail" */
  delete_rtcs_db_PH_ura_TenantIncomeDetail?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ura_Town" */
  delete_rtcs_db_PH_ura_Town?: Maybe<Rtcs_Db_Ph_Ura_Town_Mutation_Response>;
  /** delete data from the table: "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
  delete_rtcs_db_PH_ursb_CompaniesAndBusinessNamesURSB?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Mutation_Response>;
  /** delete data from the table: "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
  delete_rtcs_db_RPT_IndividualComplianceSummaryPlusRisk?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Mutation_Response>;
  /** delete data from the table: "rtcs_db.RPT_IndividualComposite" */
  delete_rtcs_db_RPT_IndividualComposite?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Mutation_Response>;
  /** delete data from the table: "rtcs_db.RPT_IndividualRegistration" */
  delete_rtcs_db_RPT_IndividualRegistration?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Mutation_Response>;
  /** delete data from the table: "rtcs_db.RPT_IndividualTaxSummaryData" */
  delete_rtcs_db_RPT_IndividualTaxSummaryData?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Mutation_Response>;
  /** delete data from the table: "rtcs_db.RPT_KccaAffiliates" */
  delete_rtcs_db_RPT_KccaAffiliates?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Mutation_Response>;
  /** delete data from the table: "rtcs_db.RPT_KccaCustomerRegistrationData" */
  delete_rtcs_db_RPT_KccaCustomerRegistrationData?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Mutation_Response>;
  /** delete data from the table: "rtcs_db.RPT_KccaPropertyRegistration" */
  delete_rtcs_db_RPT_KccaPropertyRegistration?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Mutation_Response>;
  /** delete data from the table: "rtcs_db.RPT_KccaPropertyRegistrationData" */
  delete_rtcs_db_RPT_KccaPropertyRegistrationData?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Mutation_Response>;
  /** delete data from the table: "rtcs_db.RPT_NonIndividualBalanceSheet" */
  delete_rtcs_db_RPT_NonIndividualBalanceSheet?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Mutation_Response>;
  /** delete data from the table: "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
  delete_rtcs_db_RPT_NonIndividualComplianceSummaryPlusRisk?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Mutation_Response>;
  /** delete data from the table: "rtcs_db.RPT_NonIndividualComposite" */
  delete_rtcs_db_RPT_NonIndividualComposite?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Mutation_Response>;
  /** delete data from the table: "rtcs_db.RPT_NonIndividualTaxSummaryData" */
  delete_rtcs_db_RPT_NonIndividualTaxSummaryData?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Mutation_Response>;
  /** delete data from the table: "rtcs_db.RPT_PropertiesComposite" */
  delete_rtcs_db_RPT_PropertiesComposite?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Mutation_Response>;
  /** delete data from the table: "rtcs_db.RPT_PropertyValuation" */
  delete_rtcs_db_RPT_PropertyValuation?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Mutation_Response>;
  /** delete data from the table: "rtcs_db.RPT_RentalTenantPayments" */
  delete_rtcs_db_RPT_RentalTenantPayments?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Mutation_Response>;
  /** delete data from the table: "rtcs_db.aggregate_result" */
  delete_rtcs_db_aggregate_result?: Maybe<Rtcs_Db_Aggregate_Result_Mutation_Response>;
  /** delete data from the table: "rtcs_db.aggregate_result_txt" */
  delete_rtcs_db_aggregate_result_txt?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Mutation_Response>;
  /** delete data from the table: "rtcs_db.gazette_CommercialRates" */
  delete_rtcs_db_gazette_CommercialRates?: Maybe<Rtcs_Db_Gazette_CommercialRates_Mutation_Response>;
  /** delete data from the table: "rtcs_db.gazette_ResidentialRates" */
  delete_rtcs_db_gazette_ResidentialRates?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_AssociateType" */
  delete_rtcs_db_kcca_AssociateType?: Maybe<Rtcs_Db_Kcca_AssociateType_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_CommercialProperties" */
  delete_rtcs_db_kcca_CommercialProperties?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_County" */
  delete_rtcs_db_kcca_County?: Maybe<Rtcs_Db_Kcca_County_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_Customer" */
  delete_rtcs_db_kcca_Customer?: Maybe<Rtcs_Db_Kcca_Customer_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_CustomerApplicantType" */
  delete_rtcs_db_kcca_CustomerApplicantType?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_CustomerBusinessAssociate" */
  delete_rtcs_db_kcca_CustomerBusinessAssociate?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_CustomerBusinessType" */
  delete_rtcs_db_kcca_CustomerBusinessType?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
  delete_rtcs_db_kcca_CustomerCoinDeactivateReactive?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_CustomerContactPerson" */
  delete_rtcs_db_kcca_CustomerContactPerson?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_CustomerDocumentItem" */
  delete_rtcs_db_kcca_CustomerDocumentItem?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_CustomerGuardian" */
  delete_rtcs_db_kcca_CustomerGuardian?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_CustomerReference" */
  delete_rtcs_db_kcca_CustomerReference?: Maybe<Rtcs_Db_Kcca_CustomerReference_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_CustomerRegistrationReason" */
  delete_rtcs_db_kcca_CustomerRegistrationReason?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_CustomerRevenueAgent" */
  delete_rtcs_db_kcca_CustomerRevenueAgent?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_CustomerType" */
  delete_rtcs_db_kcca_CustomerType?: Maybe<Rtcs_Db_Kcca_CustomerType_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_Customer_sid" */
  delete_rtcs_db_kcca_Customer_sid?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_District" */
  delete_rtcs_db_kcca_District?: Maybe<Rtcs_Db_Kcca_District_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_Division" */
  delete_rtcs_db_kcca_Division?: Maybe<Rtcs_Db_Kcca_Division_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_ImgsData" */
  delete_rtcs_db_kcca_ImgsData?: Maybe<Rtcs_Db_Kcca_ImgsData_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_Parish" */
  delete_rtcs_db_kcca_Parish?: Maybe<Rtcs_Db_Kcca_Parish_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_PaymentDetails" */
  delete_rtcs_db_kcca_PaymentDetails?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_Payments" */
  delete_rtcs_db_kcca_Payments?: Maybe<Rtcs_Db_Kcca_Payments_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_Property" */
  delete_rtcs_db_kcca_Property?: Maybe<Rtcs_Db_Kcca_Property_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_PropertyFloors" */
  delete_rtcs_db_kcca_PropertyFloors?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_PropertyNumberBedrooms" */
  delete_rtcs_db_kcca_PropertyNumberBedrooms?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_PropertyRentedStatus" */
  delete_rtcs_db_kcca_PropertyRentedStatus?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_PropertySecurity" */
  delete_rtcs_db_kcca_PropertySecurity?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_PropertySubType" */
  delete_rtcs_db_kcca_PropertySubType?: Maybe<Rtcs_Db_Kcca_PropertySubType_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_PropertyType" */
  delete_rtcs_db_kcca_PropertyType?: Maybe<Rtcs_Db_Kcca_PropertyType_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_RawProperties1" */
  delete_rtcs_db_kcca_RawProperties1?: Maybe<Rtcs_Db_Kcca_RawProperties1_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_RawProperties2" */
  delete_rtcs_db_kcca_RawProperties2?: Maybe<Rtcs_Db_Kcca_RawProperties2_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_ResidentialProperties" */
  delete_rtcs_db_kcca_ResidentialProperties?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_RevenueType" */
  delete_rtcs_db_kcca_RevenueType?: Maybe<Rtcs_Db_Kcca_RevenueType_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_SubCounty" */
  delete_rtcs_db_kcca_SubCounty?: Maybe<Rtcs_Db_Kcca_SubCounty_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_SubpropertyTypeConversion" */
  delete_rtcs_db_kcca_SubpropertyTypeConversion?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_TradingLicense" */
  delete_rtcs_db_kcca_TradingLicense?: Maybe<Rtcs_Db_Kcca_TradingLicense_Mutation_Response>;
  /** delete data from the table: "rtcs_db.kcca_Village" */
  delete_rtcs_db_kcca_Village?: Maybe<Rtcs_Db_Kcca_Village_Mutation_Response>;
  /** delete data from the table: "rtcs_db.master_MasterIndiv" */
  delete_rtcs_db_master_MasterIndiv?: Maybe<Rtcs_Db_Master_MasterIndiv_Mutation_Response>;
  /** delete data from the table: "rtcs_db.mlhud_KCCA" */
  delete_rtcs_db_mlhud_KCCA?: Maybe<Rtcs_Db_Mlhud_Kcca_Mutation_Response>;
  /** delete data from the table: "rtcs_db.mlhud_Mukono" */
  delete_rtcs_db_mlhud_Mukono?: Maybe<Rtcs_Db_Mlhud_Mukono_Mutation_Response>;
  /** delete data from the table: "rtcs_db.mlhud_Properties" */
  delete_rtcs_db_mlhud_Properties?: Maybe<Rtcs_Db_Mlhud_Properties_Mutation_Response>;
  /** delete data from the table: "rtcs_db.mlhud_WakisoBusiro" */
  delete_rtcs_db_mlhud_WakisoBusiro?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Mutation_Response>;
  /** delete data from the table: "rtcs_db.mlhud_WakisoKyadondo" */
  delete_rtcs_db_mlhud_WakisoKyadondo?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Mutation_Response>;
  /** delete data from the table: "rtcs_db.nira_Nira" */
  delete_rtcs_db_nira_Nira?: Maybe<Rtcs_Db_Nira_Nira_Mutation_Response>;
  /** delete data from the table: "rtcs_db.nira_RawData" */
  delete_rtcs_db_nira_RawData?: Maybe<Rtcs_Db_Nira_RawData_Mutation_Response>;
  /** delete data from the table: "rtcs_db.nwsc_Customers" */
  delete_rtcs_db_nwsc_Customers?: Maybe<Rtcs_Db_Nwsc_Customers_Mutation_Response>;
  /** delete data from the table: "rtcs_db.nwsc_NWCustomers" */
  delete_rtcs_db_nwsc_NWCustomers?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Mutation_Response>;
  /** delete data from the table: "rtcs_db.nwsc_RPT_Transactions" */
  delete_rtcs_db_nwsc_RPT_Transactions?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Mutation_Response>;
  /** delete data from the table: "rtcs_db.nwsc_Transactions" */
  delete_rtcs_db_nwsc_Transactions?: Maybe<Rtcs_Db_Nwsc_Transactions_Mutation_Response>;
  /** delete data from the table: "rtcs_db.nwsc_URATransactions" */
  delete_rtcs_db_nwsc_URATransactions?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rcapture_RPTUnittypes" */
  delete_rtcs_db_rcapture_RPTUnittypes?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rcapture_RPTlessors" */
  delete_rtcs_db_rcapture_RPTlessors?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rcapture_RPTmeta" */
  delete_rtcs_db_rcapture_RPTmeta?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rcapture_RPTproperties" */
  delete_rtcs_db_rcapture_RPTproperties?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rcapture_RPTtenants" */
  delete_rtcs_db_rcapture_RPTtenants?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_Individual" */
  delete_rtcs_db_rmatch_Individual?: Maybe<Rtcs_Db_Rmatch_Individual_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_IndividualComplianceDetailReport" */
  delete_rtcs_db_rmatch_IndividualComplianceDetailReport?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
  delete_rtcs_db_rmatch_IndividualComplianceSummaryReport?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_IndividualRiskScore" */
  delete_rtcs_db_rmatch_IndividualRiskScore?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_IndividualsNetwork" */
  delete_rtcs_db_rmatch_IndividualsNetwork?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_IndividualsNetworkTesting" */
  delete_rtcs_db_rmatch_IndividualsNetworkTesting?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
  delete_rtcs_db_rmatch_KccaToKccaIndividualOwners?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
  delete_rtcs_db_rmatch_KccaToKccaNonindividualOwners?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_MatchIndividualsProperties" */
  delete_rtcs_db_rmatch_MatchIndividualsProperties?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_MatchIndividualsProps" */
  delete_rtcs_db_rmatch_MatchIndividualsProps?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_NoReturns" */
  delete_rtcs_db_rmatch_NoReturns?: Maybe<Rtcs_Db_Rmatch_NoReturns_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_NonIndividual" */
  delete_rtcs_db_rmatch_NonIndividual?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
  delete_rtcs_db_rmatch_NonIndividualComplianceDetailReport?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
  delete_rtcs_db_rmatch_NonIndividualComplianceSummaryReport?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_NonIndividualRiskScore" */
  delete_rtcs_db_rmatch_NonIndividualRiskScore?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_NonIndividuals" */
  delete_rtcs_db_rmatch_NonIndividuals?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_NonIndividualsNetwork" */
  delete_rtcs_db_rmatch_NonIndividualsNetwork?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_NonindividualOwners" */
  delete_rtcs_db_rmatch_NonindividualOwners?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_Nonindividuals" */
  delete_rtcs_db_rmatch_Nonindividuals?: Maybe<Rtcs_Db_Rmatch_Nonindividuals_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_PropertiesComposite" */
  delete_rtcs_db_rmatch_PropertiesComposite?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_PropertiesNetwork" */
  delete_rtcs_db_rmatch_PropertiesNetwork?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_PropertyNetwork" */
  delete_rtcs_db_rmatch_PropertyNetwork?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_RMatchDetailed904" */
  delete_rtcs_db_rmatch_RMatchDetailed904?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_RMatchDetailed916" */
  delete_rtcs_db_rmatch_RMatchDetailed916?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
  delete_rtcs_db_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_RMatchDetailed937" */
  delete_rtcs_db_rmatch_RMatchDetailed937?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_RMatchKccaProperties" */
  delete_rtcs_db_rmatch_RMatchKccaProperties?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_RMatchKccaProperty" */
  delete_rtcs_db_rmatch_RMatchKccaProperty?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_RMatchNonIndividual903" */
  delete_rtcs_db_rmatch_RMatchNonIndividual903?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_RMatchProperties1115" */
  delete_rtcs_db_rmatch_RMatchProperties1115?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_RMatchSummary904" */
  delete_rtcs_db_rmatch_RMatchSummary904?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_RMatchSummary916" */
  delete_rtcs_db_rmatch_RMatchSummary916?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
  delete_rtcs_db_rmatch_RMatchSummary916ExcessiveOwnerOccupancy?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_RMatchSummary937" */
  delete_rtcs_db_rmatch_RMatchSummary937?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_TinsURA" */
  delete_rtcs_db_rmatch_TinsURA?: Maybe<Rtcs_Db_Rmatch_TinsUra_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_Undeclared" */
  delete_rtcs_db_rmatch_Undeclared?: Maybe<Rtcs_Db_Rmatch_Undeclared_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_Underreported" */
  delete_rtcs_db_rmatch_Underreported?: Maybe<Rtcs_Db_Rmatch_Underreported_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_Unregistered" */
  delete_rtcs_db_rmatch_Unregistered?: Maybe<Rtcs_Db_Rmatch_Unregistered_Mutation_Response>;
  /** delete data from the table: "rtcs_db.rmatch_mlhudConsolidated" */
  delete_rtcs_db_rmatch_mlhudConsolidated?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Mutation_Response>;
  /** delete data from the table: "rtcs_db.streetmap_OsmMukonoMunicipality" */
  delete_rtcs_db_streetmap_OsmMukonoMunicipality?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Mutation_Response>;
  /** delete data from the table: "rtcs_db.streetmap_OsmMukonoNakifuma" */
  delete_rtcs_db_streetmap_OsmMukonoNakifuma?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Mutation_Response>;
  /** delete data from the table: "rtcs_db.streetmap_OsmWakisoEntebbe" */
  delete_rtcs_db_streetmap_OsmWakisoEntebbe?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Mutation_Response>;
  /** delete data from the table: "rtcs_db.streetmap_OsmWakisoKira" */
  delete_rtcs_db_streetmap_OsmWakisoKira?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Mutation_Response>;
  /** delete data from the table: "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
  delete_rtcs_db_streetmap_OsmWakisoMakindyeSsabagabo?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Mutation_Response>;
  /** delete data from the table: "rtcs_db.streetmap_OsmWakisoNansana" */
  delete_rtcs_db_streetmap_OsmWakisoNansana?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ucc_UCC" */
  delete_rtcs_db_ucc_UCC?: Maybe<Rtcs_Db_Ucc_Ucc_Mutation_Response>;
  /** delete data from the table: "rtcs_db.umeme_SubscriberAndProperty" */
  delete_rtcs_db_umeme_SubscriberAndProperty?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Mutation_Response>;
  /** delete data from the table: "rtcs_db.umeme_Transactions" */
  delete_rtcs_db_umeme_Transactions?: Maybe<Rtcs_Db_Umeme_Transactions_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_BalanceSheetInformation" */
  delete_rtcs_db_ura_BalanceSheetInformation?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_CombinedOfficersAndOwners" */
  delete_rtcs_db_ura_CombinedOfficersAndOwners?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_EmploymentIncomeDetail" */
  delete_rtcs_db_ura_EmploymentIncomeDetail?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
  delete_rtcs_db_ura_GazetteCommercialRatesPerSquareFootage?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
  delete_rtcs_db_ura_GazetteResidentialRatesPerBedroom?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_HoldingOfficers" */
  delete_rtcs_db_ura_HoldingOfficers?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_HoldingOwner" */
  delete_rtcs_db_ura_HoldingOwner?: Maybe<Rtcs_Db_Ura_HoldingOwner_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_IncomeTaxHolding" */
  delete_rtcs_db_ura_IncomeTaxHolding?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_IncomeTaxSummaryIndividual" */
  delete_rtcs_db_ura_IncomeTaxSummaryIndividual?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
  delete_rtcs_db_ura_IncomeTaxSummaryNonIndividual?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_IndividualMortgage" */
  delete_rtcs_db_ura_IndividualMortgage?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_IndividualsAssociatedBusiness" */
  delete_rtcs_db_ura_IndividualsAssociatedBusiness?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_LandlordIncomeDetail" */
  delete_rtcs_db_ura_LandlordIncomeDetail?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_LandlordIncomeSummary" */
  delete_rtcs_db_ura_LandlordIncomeSummary?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
  delete_rtcs_db_ura_NonIndividualsAssociatedBusiness?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_RegAssociates" */
  delete_rtcs_db_ura_RegAssociates?: Maybe<Rtcs_Db_Ura_RegAssociates_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_RegIndividual" */
  delete_rtcs_db_ura_RegIndividual?: Maybe<Rtcs_Db_Ura_RegIndividual_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_RegIndividual_sid" */
  delete_rtcs_db_ura_RegIndividual_sid?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_RegNonIndividual" */
  delete_rtcs_db_ura_RegNonIndividual?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_RegNonIndividual_sid" */
  delete_rtcs_db_ura_RegNonIndividual_sid?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_RentExpenseDetails" */
  delete_rtcs_db_ura_RentExpenseDetails?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_RentalLandLordIncome" */
  delete_rtcs_db_ura_RentalLandLordIncome?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_RentalTenantPayments" */
  delete_rtcs_db_ura_RentalTenantPayments?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_RipoIndRentalIncSummary" */
  delete_rtcs_db_ura_RipoIndRentalIncSummary?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_TenantIncomeDetail" */
  delete_rtcs_db_ura_TenantIncomeDetail?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ura_Town" */
  delete_rtcs_db_ura_Town?: Maybe<Rtcs_Db_Ura_Town_Mutation_Response>;
  /** delete data from the table: "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
  delete_rtcs_db_ursb_CompaniesAndBusinessNamesURSB?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Mutation_Response>;
  /** insert data into the table: "rtcs_db.EntityFilter" */
  insert_rtcs_db_EntityFilter?: Maybe<Rtcs_Db_EntityFilter_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.EntityFilter" */
  insert_rtcs_db_EntityFilter_one?: Maybe<Rtcs_Db_EntityFilter>;
  /** insert data into the table: "rtcs_db.Filter" */
  insert_rtcs_db_Filter?: Maybe<Rtcs_Db_Filter_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.Filter" */
  insert_rtcs_db_Filter_one?: Maybe<Rtcs_Db_Filter>;
  /** insert data into the table: "rtcs_db.GazzetteRates" */
  insert_rtcs_db_GazzetteRates?: Maybe<Rtcs_Db_GazzetteRates_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.GazzetteRates" */
  insert_rtcs_db_GazzetteRates_one?: Maybe<Rtcs_Db_GazzetteRates>;
  /** insert data into the table: "rtcs_db.PH_EntityFilter" */
  insert_rtcs_db_PH_EntityFilter?: Maybe<Rtcs_Db_Ph_EntityFilter_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_EntityFilter" */
  insert_rtcs_db_PH_EntityFilter_one?: Maybe<Rtcs_Db_Ph_EntityFilter>;
  /** insert data into the table: "rtcs_db.PH_Filter" */
  insert_rtcs_db_PH_Filter?: Maybe<Rtcs_Db_Ph_Filter_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_Filter" */
  insert_rtcs_db_PH_Filter_one?: Maybe<Rtcs_Db_Ph_Filter>;
  /** insert data into the table: "rtcs_db.PH_GazzetteRates" */
  insert_rtcs_db_PH_GazzetteRates?: Maybe<Rtcs_Db_Ph_GazzetteRates_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_GazzetteRates" */
  insert_rtcs_db_PH_GazzetteRates_one?: Maybe<Rtcs_Db_Ph_GazzetteRates>;
  /** insert data into the table: "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
  insert_rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
  insert_rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk_one?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk>;
  /** insert data into the table: "rtcs_db.PH_RPT_IndividualComposite" */
  insert_rtcs_db_PH_RPT_IndividualComposite?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_RPT_IndividualComposite" */
  insert_rtcs_db_PH_RPT_IndividualComposite_one?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite>;
  /** insert data into the table: "rtcs_db.PH_RPT_IndividualRegistration" */
  insert_rtcs_db_PH_RPT_IndividualRegistration?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_RPT_IndividualRegistration" */
  insert_rtcs_db_PH_RPT_IndividualRegistration_one?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration>;
  /** insert data into the table: "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
  insert_rtcs_db_PH_RPT_IndividualTaxSummaryData?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
  insert_rtcs_db_PH_RPT_IndividualTaxSummaryData_one?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData>;
  /** insert data into the table: "rtcs_db.PH_RPT_KccaAffiliates" */
  insert_rtcs_db_PH_RPT_KccaAffiliates?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_RPT_KccaAffiliates" */
  insert_rtcs_db_PH_RPT_KccaAffiliates_one?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates>;
  /** insert data into the table: "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
  insert_rtcs_db_PH_RPT_KccaCustomerRegistrationData?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
  insert_rtcs_db_PH_RPT_KccaCustomerRegistrationData_one?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData>;
  /** insert data into the table: "rtcs_db.PH_RPT_KccaPropertyRegistration" */
  insert_rtcs_db_PH_RPT_KccaPropertyRegistration?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Mutation_Response>;
  /** insert data into the table: "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
  insert_rtcs_db_PH_RPT_KccaPropertyRegistrationData?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
  insert_rtcs_db_PH_RPT_KccaPropertyRegistrationData_one?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData>;
  /** insert a single row into the table: "rtcs_db.PH_RPT_KccaPropertyRegistration" */
  insert_rtcs_db_PH_RPT_KccaPropertyRegistration_one?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration>;
  /** insert data into the table: "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
  insert_rtcs_db_PH_RPT_NonIndividualBalanceSheet?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
  insert_rtcs_db_PH_RPT_NonIndividualBalanceSheet_one?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet>;
  /** insert data into the table: "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
  insert_rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
  insert_rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk_one?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk>;
  /** insert data into the table: "rtcs_db.PH_RPT_NonIndividualComposite" */
  insert_rtcs_db_PH_RPT_NonIndividualComposite?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_RPT_NonIndividualComposite" */
  insert_rtcs_db_PH_RPT_NonIndividualComposite_one?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite>;
  /** insert data into the table: "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
  insert_rtcs_db_PH_RPT_NonIndividualTaxSummaryData?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
  insert_rtcs_db_PH_RPT_NonIndividualTaxSummaryData_one?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData>;
  /** insert data into the table: "rtcs_db.PH_RPT_PropertiesComposite" */
  insert_rtcs_db_PH_RPT_PropertiesComposite?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_RPT_PropertiesComposite" */
  insert_rtcs_db_PH_RPT_PropertiesComposite_one?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite>;
  /** insert data into the table: "rtcs_db.PH_RPT_PropertyValuation" */
  insert_rtcs_db_PH_RPT_PropertyValuation?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_RPT_PropertyValuation" */
  insert_rtcs_db_PH_RPT_PropertyValuation_one?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation>;
  /** insert data into the table: "rtcs_db.PH_RPT_RentalTenantPayments" */
  insert_rtcs_db_PH_RPT_RentalTenantPayments?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_RPT_RentalTenantPayments" */
  insert_rtcs_db_PH_RPT_RentalTenantPayments_one?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments>;
  /** insert data into the table: "rtcs_db.PH_gazette_CommercialRates" */
  insert_rtcs_db_PH_gazette_CommercialRates?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_gazette_CommercialRates" */
  insert_rtcs_db_PH_gazette_CommercialRates_one?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates>;
  /** insert data into the table: "rtcs_db.PH_gazette_ResidentialRates" */
  insert_rtcs_db_PH_gazette_ResidentialRates?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_gazette_ResidentialRates" */
  insert_rtcs_db_PH_gazette_ResidentialRates_one?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates>;
  /** insert data into the table: "rtcs_db.PH_kcca_AssociateType" */
  insert_rtcs_db_PH_kcca_AssociateType?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_AssociateType" */
  insert_rtcs_db_PH_kcca_AssociateType_one?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType>;
  /** insert data into the table: "rtcs_db.PH_kcca_CommercialProperties" */
  insert_rtcs_db_PH_kcca_CommercialProperties?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_CommercialProperties" */
  insert_rtcs_db_PH_kcca_CommercialProperties_one?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties>;
  /** insert data into the table: "rtcs_db.PH_kcca_County" */
  insert_rtcs_db_PH_kcca_County?: Maybe<Rtcs_Db_Ph_Kcca_County_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_County" */
  insert_rtcs_db_PH_kcca_County_one?: Maybe<Rtcs_Db_Ph_Kcca_County>;
  /** insert data into the table: "rtcs_db.PH_kcca_Customer" */
  insert_rtcs_db_PH_kcca_Customer?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Mutation_Response>;
  /** insert data into the table: "rtcs_db.PH_kcca_CustomerApplicantType" */
  insert_rtcs_db_PH_kcca_CustomerApplicantType?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_CustomerApplicantType" */
  insert_rtcs_db_PH_kcca_CustomerApplicantType_one?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType>;
  /** insert data into the table: "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
  insert_rtcs_db_PH_kcca_CustomerBusinessAssociate?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
  insert_rtcs_db_PH_kcca_CustomerBusinessAssociate_one?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate>;
  /** insert data into the table: "rtcs_db.PH_kcca_CustomerBusinessType" */
  insert_rtcs_db_PH_kcca_CustomerBusinessType?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_CustomerBusinessType" */
  insert_rtcs_db_PH_kcca_CustomerBusinessType_one?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType>;
  /** insert data into the table: "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
  insert_rtcs_db_PH_kcca_CustomerCoinDeactivateReactive?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
  insert_rtcs_db_PH_kcca_CustomerCoinDeactivateReactive_one?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive>;
  /** insert data into the table: "rtcs_db.PH_kcca_CustomerContactPerson" */
  insert_rtcs_db_PH_kcca_CustomerContactPerson?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_CustomerContactPerson" */
  insert_rtcs_db_PH_kcca_CustomerContactPerson_one?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson>;
  /** insert data into the table: "rtcs_db.PH_kcca_CustomerDocumentItem" */
  insert_rtcs_db_PH_kcca_CustomerDocumentItem?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_CustomerDocumentItem" */
  insert_rtcs_db_PH_kcca_CustomerDocumentItem_one?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem>;
  /** insert data into the table: "rtcs_db.PH_kcca_CustomerGuardian" */
  insert_rtcs_db_PH_kcca_CustomerGuardian?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_CustomerGuardian" */
  insert_rtcs_db_PH_kcca_CustomerGuardian_one?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian>;
  /** insert data into the table: "rtcs_db.PH_kcca_CustomerReference" */
  insert_rtcs_db_PH_kcca_CustomerReference?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_CustomerReference" */
  insert_rtcs_db_PH_kcca_CustomerReference_one?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference>;
  /** insert data into the table: "rtcs_db.PH_kcca_CustomerRegistrationReason" */
  insert_rtcs_db_PH_kcca_CustomerRegistrationReason?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_CustomerRegistrationReason" */
  insert_rtcs_db_PH_kcca_CustomerRegistrationReason_one?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason>;
  /** insert data into the table: "rtcs_db.PH_kcca_CustomerRevenueAgent" */
  insert_rtcs_db_PH_kcca_CustomerRevenueAgent?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_CustomerRevenueAgent" */
  insert_rtcs_db_PH_kcca_CustomerRevenueAgent_one?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent>;
  /** insert data into the table: "rtcs_db.PH_kcca_CustomerType" */
  insert_rtcs_db_PH_kcca_CustomerType?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_CustomerType" */
  insert_rtcs_db_PH_kcca_CustomerType_one?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_Customer" */
  insert_rtcs_db_PH_kcca_Customer_one?: Maybe<Rtcs_Db_Ph_Kcca_Customer>;
  /** insert data into the table: "rtcs_db.PH_kcca_Customer_sid" */
  insert_rtcs_db_PH_kcca_Customer_sid?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_Customer_sid" */
  insert_rtcs_db_PH_kcca_Customer_sid_one?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid>;
  /** insert data into the table: "rtcs_db.PH_kcca_District" */
  insert_rtcs_db_PH_kcca_District?: Maybe<Rtcs_Db_Ph_Kcca_District_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_District" */
  insert_rtcs_db_PH_kcca_District_one?: Maybe<Rtcs_Db_Ph_Kcca_District>;
  /** insert data into the table: "rtcs_db.PH_kcca_Division" */
  insert_rtcs_db_PH_kcca_Division?: Maybe<Rtcs_Db_Ph_Kcca_Division_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_Division" */
  insert_rtcs_db_PH_kcca_Division_one?: Maybe<Rtcs_Db_Ph_Kcca_Division>;
  /** insert data into the table: "rtcs_db.PH_kcca_ImgsData" */
  insert_rtcs_db_PH_kcca_ImgsData?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_ImgsData" */
  insert_rtcs_db_PH_kcca_ImgsData_one?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData>;
  /** insert data into the table: "rtcs_db.PH_kcca_Parish" */
  insert_rtcs_db_PH_kcca_Parish?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_Parish" */
  insert_rtcs_db_PH_kcca_Parish_one?: Maybe<Rtcs_Db_Ph_Kcca_Parish>;
  /** insert data into the table: "rtcs_db.PH_kcca_PaymentDetails" */
  insert_rtcs_db_PH_kcca_PaymentDetails?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_PaymentDetails" */
  insert_rtcs_db_PH_kcca_PaymentDetails_one?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails>;
  /** insert data into the table: "rtcs_db.PH_kcca_Payments" */
  insert_rtcs_db_PH_kcca_Payments?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_Payments" */
  insert_rtcs_db_PH_kcca_Payments_one?: Maybe<Rtcs_Db_Ph_Kcca_Payments>;
  /** insert data into the table: "rtcs_db.PH_kcca_Property" */
  insert_rtcs_db_PH_kcca_Property?: Maybe<Rtcs_Db_Ph_Kcca_Property_Mutation_Response>;
  /** insert data into the table: "rtcs_db.PH_kcca_PropertyFloors" */
  insert_rtcs_db_PH_kcca_PropertyFloors?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_PropertyFloors" */
  insert_rtcs_db_PH_kcca_PropertyFloors_one?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors>;
  /** insert data into the table: "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
  insert_rtcs_db_PH_kcca_PropertyNumberBedrooms?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
  insert_rtcs_db_PH_kcca_PropertyNumberBedrooms_one?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms>;
  /** insert data into the table: "rtcs_db.PH_kcca_PropertyRentedStatus" */
  insert_rtcs_db_PH_kcca_PropertyRentedStatus?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_PropertyRentedStatus" */
  insert_rtcs_db_PH_kcca_PropertyRentedStatus_one?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus>;
  /** insert data into the table: "rtcs_db.PH_kcca_PropertySecurity" */
  insert_rtcs_db_PH_kcca_PropertySecurity?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_PropertySecurity" */
  insert_rtcs_db_PH_kcca_PropertySecurity_one?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity>;
  /** insert data into the table: "rtcs_db.PH_kcca_PropertySubType" */
  insert_rtcs_db_PH_kcca_PropertySubType?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_PropertySubType" */
  insert_rtcs_db_PH_kcca_PropertySubType_one?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType>;
  /** insert data into the table: "rtcs_db.PH_kcca_PropertyType" */
  insert_rtcs_db_PH_kcca_PropertyType?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_PropertyType" */
  insert_rtcs_db_PH_kcca_PropertyType_one?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_Property" */
  insert_rtcs_db_PH_kcca_Property_one?: Maybe<Rtcs_Db_Ph_Kcca_Property>;
  /** insert data into the table: "rtcs_db.PH_kcca_RawProperties1" */
  insert_rtcs_db_PH_kcca_RawProperties1?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_RawProperties1" */
  insert_rtcs_db_PH_kcca_RawProperties1_one?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1>;
  /** insert data into the table: "rtcs_db.PH_kcca_RawProperties2" */
  insert_rtcs_db_PH_kcca_RawProperties2?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_RawProperties2" */
  insert_rtcs_db_PH_kcca_RawProperties2_one?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2>;
  /** insert data into the table: "rtcs_db.PH_kcca_ResidentialProperties" */
  insert_rtcs_db_PH_kcca_ResidentialProperties?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_ResidentialProperties" */
  insert_rtcs_db_PH_kcca_ResidentialProperties_one?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties>;
  /** insert data into the table: "rtcs_db.PH_kcca_RevenueType" */
  insert_rtcs_db_PH_kcca_RevenueType?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_RevenueType" */
  insert_rtcs_db_PH_kcca_RevenueType_one?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType>;
  /** insert data into the table: "rtcs_db.PH_kcca_SubCounty" */
  insert_rtcs_db_PH_kcca_SubCounty?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_SubCounty" */
  insert_rtcs_db_PH_kcca_SubCounty_one?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty>;
  /** insert data into the table: "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
  insert_rtcs_db_PH_kcca_SubpropertyTypeConversion?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
  insert_rtcs_db_PH_kcca_SubpropertyTypeConversion_one?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion>;
  /** insert data into the table: "rtcs_db.PH_kcca_TradingLicense" */
  insert_rtcs_db_PH_kcca_TradingLicense?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_TradingLicense" */
  insert_rtcs_db_PH_kcca_TradingLicense_one?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense>;
  /** insert data into the table: "rtcs_db.PH_kcca_Village" */
  insert_rtcs_db_PH_kcca_Village?: Maybe<Rtcs_Db_Ph_Kcca_Village_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_kcca_Village" */
  insert_rtcs_db_PH_kcca_Village_one?: Maybe<Rtcs_Db_Ph_Kcca_Village>;
  /** insert data into the table: "rtcs_db.PH_master_MasterIndiv" */
  insert_rtcs_db_PH_master_MasterIndiv?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_master_MasterIndiv" */
  insert_rtcs_db_PH_master_MasterIndiv_one?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv>;
  /** insert data into the table: "rtcs_db.PH_mlhud_KCCA" */
  insert_rtcs_db_PH_mlhud_KCCA?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_mlhud_KCCA" */
  insert_rtcs_db_PH_mlhud_KCCA_one?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca>;
  /** insert data into the table: "rtcs_db.PH_mlhud_Mukono" */
  insert_rtcs_db_PH_mlhud_Mukono?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_mlhud_Mukono" */
  insert_rtcs_db_PH_mlhud_Mukono_one?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono>;
  /** insert data into the table: "rtcs_db.PH_mlhud_Properties" */
  insert_rtcs_db_PH_mlhud_Properties?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_mlhud_Properties" */
  insert_rtcs_db_PH_mlhud_Properties_one?: Maybe<Rtcs_Db_Ph_Mlhud_Properties>;
  /** insert data into the table: "rtcs_db.PH_mlhud_WakisoBusiro" */
  insert_rtcs_db_PH_mlhud_WakisoBusiro?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_mlhud_WakisoBusiro" */
  insert_rtcs_db_PH_mlhud_WakisoBusiro_one?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro>;
  /** insert data into the table: "rtcs_db.PH_mlhud_WakisoKyadondo" */
  insert_rtcs_db_PH_mlhud_WakisoKyadondo?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_mlhud_WakisoKyadondo" */
  insert_rtcs_db_PH_mlhud_WakisoKyadondo_one?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo>;
  /** insert data into the table: "rtcs_db.PH_nira_Nira" */
  insert_rtcs_db_PH_nira_Nira?: Maybe<Rtcs_Db_Ph_Nira_Nira_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_nira_Nira" */
  insert_rtcs_db_PH_nira_Nira_one?: Maybe<Rtcs_Db_Ph_Nira_Nira>;
  /** insert data into the table: "rtcs_db.PH_nira_RawData" */
  insert_rtcs_db_PH_nira_RawData?: Maybe<Rtcs_Db_Ph_Nira_RawData_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_nira_RawData" */
  insert_rtcs_db_PH_nira_RawData_one?: Maybe<Rtcs_Db_Ph_Nira_RawData>;
  /** insert data into the table: "rtcs_db.PH_nwsc_Customers" */
  insert_rtcs_db_PH_nwsc_Customers?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_nwsc_Customers" */
  insert_rtcs_db_PH_nwsc_Customers_one?: Maybe<Rtcs_Db_Ph_Nwsc_Customers>;
  /** insert data into the table: "rtcs_db.PH_nwsc_NWCustomers" */
  insert_rtcs_db_PH_nwsc_NWCustomers?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_nwsc_NWCustomers" */
  insert_rtcs_db_PH_nwsc_NWCustomers_one?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers>;
  /** insert data into the table: "rtcs_db.PH_nwsc_RPT_Transactions" */
  insert_rtcs_db_PH_nwsc_RPT_Transactions?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_nwsc_RPT_Transactions" */
  insert_rtcs_db_PH_nwsc_RPT_Transactions_one?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions>;
  /** insert data into the table: "rtcs_db.PH_nwsc_Transactions" */
  insert_rtcs_db_PH_nwsc_Transactions?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_nwsc_Transactions" */
  insert_rtcs_db_PH_nwsc_Transactions_one?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions>;
  /** insert data into the table: "rtcs_db.PH_nwsc_URATransactions" */
  insert_rtcs_db_PH_nwsc_URATransactions?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_nwsc_URATransactions" */
  insert_rtcs_db_PH_nwsc_URATransactions_one?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions>;
  /** insert data into the table: "rtcs_db.PH_rcapture_RPTUnittypes" */
  insert_rtcs_db_PH_rcapture_RPTUnittypes?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rcapture_RPTUnittypes" */
  insert_rtcs_db_PH_rcapture_RPTUnittypes_one?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes>;
  /** insert data into the table: "rtcs_db.PH_rcapture_RPTlessors" */
  insert_rtcs_db_PH_rcapture_RPTlessors?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rcapture_RPTlessors" */
  insert_rtcs_db_PH_rcapture_RPTlessors_one?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors>;
  /** insert data into the table: "rtcs_db.PH_rcapture_RPTmeta" */
  insert_rtcs_db_PH_rcapture_RPTmeta?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rcapture_RPTmeta" */
  insert_rtcs_db_PH_rcapture_RPTmeta_one?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta>;
  /** insert data into the table: "rtcs_db.PH_rcapture_RPTproperties" */
  insert_rtcs_db_PH_rcapture_RPTproperties?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rcapture_RPTproperties" */
  insert_rtcs_db_PH_rcapture_RPTproperties_one?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties>;
  /** insert data into the table: "rtcs_db.PH_rcapture_RPTtenants" */
  insert_rtcs_db_PH_rcapture_RPTtenants?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rcapture_RPTtenants" */
  insert_rtcs_db_PH_rcapture_RPTtenants_one?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants>;
  /** insert data into the table: "rtcs_db.PH_rmatch_Individual" */
  insert_rtcs_db_PH_rmatch_Individual?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Mutation_Response>;
  /** insert data into the table: "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
  insert_rtcs_db_PH_rmatch_IndividualComplianceDetailReport?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
  insert_rtcs_db_PH_rmatch_IndividualComplianceDetailReport_one?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport>;
  /** insert data into the table: "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
  insert_rtcs_db_PH_rmatch_IndividualComplianceSummaryReport?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
  insert_rtcs_db_PH_rmatch_IndividualComplianceSummaryReport_one?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport>;
  /** insert data into the table: "rtcs_db.PH_rmatch_IndividualRiskScore" */
  insert_rtcs_db_PH_rmatch_IndividualRiskScore?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_IndividualRiskScore" */
  insert_rtcs_db_PH_rmatch_IndividualRiskScore_one?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_Individual" */
  insert_rtcs_db_PH_rmatch_Individual_one?: Maybe<Rtcs_Db_Ph_Rmatch_Individual>;
  /** insert data into the table: "rtcs_db.PH_rmatch_IndividualsNetwork" */
  insert_rtcs_db_PH_rmatch_IndividualsNetwork?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Mutation_Response>;
  /** insert data into the table: "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
  insert_rtcs_db_PH_rmatch_IndividualsNetworkTesting?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
  insert_rtcs_db_PH_rmatch_IndividualsNetworkTesting_one?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_IndividualsNetwork" */
  insert_rtcs_db_PH_rmatch_IndividualsNetwork_one?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork>;
  /** insert data into the table: "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
  insert_rtcs_db_PH_rmatch_KccaToKccaIndividualOwners?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
  insert_rtcs_db_PH_rmatch_KccaToKccaIndividualOwners_one?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners>;
  /** insert data into the table: "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
  insert_rtcs_db_PH_rmatch_KccaToKccaNonindividualOwners?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
  insert_rtcs_db_PH_rmatch_KccaToKccaNonindividualOwners_one?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners>;
  /** insert data into the table: "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
  insert_rtcs_db_PH_rmatch_MatchIndividualsProperties?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
  insert_rtcs_db_PH_rmatch_MatchIndividualsProperties_one?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties>;
  /** insert data into the table: "rtcs_db.PH_rmatch_MatchIndividualsProps" */
  insert_rtcs_db_PH_rmatch_MatchIndividualsProps?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_MatchIndividualsProps" */
  insert_rtcs_db_PH_rmatch_MatchIndividualsProps_one?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps>;
  /** insert data into the table: "rtcs_db.PH_rmatch_NoReturns" */
  insert_rtcs_db_PH_rmatch_NoReturns?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_NoReturns" */
  insert_rtcs_db_PH_rmatch_NoReturns_one?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns>;
  /** insert data into the table: "rtcs_db.PH_rmatch_NonIndividual" */
  insert_rtcs_db_PH_rmatch_NonIndividual?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Mutation_Response>;
  /** insert data into the table: "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
  insert_rtcs_db_PH_rmatch_NonIndividualComplianceDetailReport?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
  insert_rtcs_db_PH_rmatch_NonIndividualComplianceDetailReport_one?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport>;
  /** insert data into the table: "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
  insert_rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
  insert_rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport_one?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport>;
  /** insert data into the table: "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
  insert_rtcs_db_PH_rmatch_NonIndividualRiskScore?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
  insert_rtcs_db_PH_rmatch_NonIndividualRiskScore_one?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_NonIndividual" */
  insert_rtcs_db_PH_rmatch_NonIndividual_one?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual>;
  /** insert data into the table: "rtcs_db.PH_rmatch_NonIndividuals" */
  insert_rtcs_db_PH_rmatch_NonIndividuals?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Mutation_Response>;
  /** insert data into the table: "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
  insert_rtcs_db_PH_rmatch_NonIndividualsNetwork?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
  insert_rtcs_db_PH_rmatch_NonIndividualsNetwork_one?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_NonIndividuals" */
  insert_rtcs_db_PH_rmatch_NonIndividuals_one?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals>;
  /** insert data into the table: "rtcs_db.PH_rmatch_NonindividualOwners" */
  insert_rtcs_db_PH_rmatch_NonindividualOwners?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_NonindividualOwners" */
  insert_rtcs_db_PH_rmatch_NonindividualOwners_one?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners>;
  /** insert data into the table: "rtcs_db.PH_rmatch_Nonindividuals" */
  insert_rtcs_db_PH_rmatch_Nonindividuals?: Maybe<Rtcs_Db_Ph_Rmatch_Nonindividuals_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_Nonindividuals" */
  insert_rtcs_db_PH_rmatch_Nonindividuals_one?: Maybe<Rtcs_Db_Ph_Rmatch_Nonindividuals>;
  /** insert data into the table: "rtcs_db.PH_rmatch_PropertiesComposite" */
  insert_rtcs_db_PH_rmatch_PropertiesComposite?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_PropertiesComposite" */
  insert_rtcs_db_PH_rmatch_PropertiesComposite_one?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite>;
  /** insert data into the table: "rtcs_db.PH_rmatch_PropertiesNetwork" */
  insert_rtcs_db_PH_rmatch_PropertiesNetwork?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_PropertiesNetwork" */
  insert_rtcs_db_PH_rmatch_PropertiesNetwork_one?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork>;
  /** insert data into the table: "rtcs_db.PH_rmatch_PropertyNetwork" */
  insert_rtcs_db_PH_rmatch_PropertyNetwork?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_PropertyNetwork" */
  insert_rtcs_db_PH_rmatch_PropertyNetwork_one?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork>;
  /** insert data into the table: "rtcs_db.PH_rmatch_RMatchDetailed904" */
  insert_rtcs_db_PH_rmatch_RMatchDetailed904?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_RMatchDetailed904" */
  insert_rtcs_db_PH_rmatch_RMatchDetailed904_one?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904>;
  /** insert data into the table: "rtcs_db.PH_rmatch_RMatchDetailed916" */
  insert_rtcs_db_PH_rmatch_RMatchDetailed916?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Mutation_Response>;
  /** insert data into the table: "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
  insert_rtcs_db_PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
  insert_rtcs_db_PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_one?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_RMatchDetailed916" */
  insert_rtcs_db_PH_rmatch_RMatchDetailed916_one?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916>;
  /** insert data into the table: "rtcs_db.PH_rmatch_RMatchDetailed937" */
  insert_rtcs_db_PH_rmatch_RMatchDetailed937?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_RMatchDetailed937" */
  insert_rtcs_db_PH_rmatch_RMatchDetailed937_one?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937>;
  /** insert data into the table: "rtcs_db.PH_rmatch_RMatchKccaProperties" */
  insert_rtcs_db_PH_rmatch_RMatchKccaProperties?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_RMatchKccaProperties" */
  insert_rtcs_db_PH_rmatch_RMatchKccaProperties_one?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties>;
  /** insert data into the table: "rtcs_db.PH_rmatch_RMatchKccaProperty" */
  insert_rtcs_db_PH_rmatch_RMatchKccaProperty?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_RMatchKccaProperty" */
  insert_rtcs_db_PH_rmatch_RMatchKccaProperty_one?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty>;
  /** insert data into the table: "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
  insert_rtcs_db_PH_rmatch_RMatchNonIndividual903?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
  insert_rtcs_db_PH_rmatch_RMatchNonIndividual903_one?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903>;
  /** insert data into the table: "rtcs_db.PH_rmatch_RMatchProperties1115" */
  insert_rtcs_db_PH_rmatch_RMatchProperties1115?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_RMatchProperties1115" */
  insert_rtcs_db_PH_rmatch_RMatchProperties1115_one?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115>;
  /** insert data into the table: "rtcs_db.PH_rmatch_RMatchSummary904" */
  insert_rtcs_db_PH_rmatch_RMatchSummary904?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_RMatchSummary904" */
  insert_rtcs_db_PH_rmatch_RMatchSummary904_one?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904>;
  /** insert data into the table: "rtcs_db.PH_rmatch_RMatchSummary916" */
  insert_rtcs_db_PH_rmatch_RMatchSummary916?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Mutation_Response>;
  /** insert data into the table: "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
  insert_rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
  insert_rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_one?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_RMatchSummary916" */
  insert_rtcs_db_PH_rmatch_RMatchSummary916_one?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916>;
  /** insert data into the table: "rtcs_db.PH_rmatch_RMatchSummary937" */
  insert_rtcs_db_PH_rmatch_RMatchSummary937?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_RMatchSummary937" */
  insert_rtcs_db_PH_rmatch_RMatchSummary937_one?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937>;
  /** insert data into the table: "rtcs_db.PH_rmatch_TinsURA" */
  insert_rtcs_db_PH_rmatch_TinsURA?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_TinsURA" */
  insert_rtcs_db_PH_rmatch_TinsURA_one?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra>;
  /** insert data into the table: "rtcs_db.PH_rmatch_Undeclared" */
  insert_rtcs_db_PH_rmatch_Undeclared?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_Undeclared" */
  insert_rtcs_db_PH_rmatch_Undeclared_one?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared>;
  /** insert data into the table: "rtcs_db.PH_rmatch_Underreported" */
  insert_rtcs_db_PH_rmatch_Underreported?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_Underreported" */
  insert_rtcs_db_PH_rmatch_Underreported_one?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported>;
  /** insert data into the table: "rtcs_db.PH_rmatch_Unregistered" */
  insert_rtcs_db_PH_rmatch_Unregistered?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_Unregistered" */
  insert_rtcs_db_PH_rmatch_Unregistered_one?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered>;
  /** insert data into the table: "rtcs_db.PH_rmatch_mlhudConsolidated" */
  insert_rtcs_db_PH_rmatch_mlhudConsolidated?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_rmatch_mlhudConsolidated" */
  insert_rtcs_db_PH_rmatch_mlhudConsolidated_one?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated>;
  /** insert data into the table: "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
  insert_rtcs_db_PH_streetmap_OsmMukonoMunicipality?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
  insert_rtcs_db_PH_streetmap_OsmMukonoMunicipality_one?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality>;
  /** insert data into the table: "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
  insert_rtcs_db_PH_streetmap_OsmMukonoNakifuma?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
  insert_rtcs_db_PH_streetmap_OsmMukonoNakifuma_one?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma>;
  /** insert data into the table: "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
  insert_rtcs_db_PH_streetmap_OsmWakisoEntebbe?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
  insert_rtcs_db_PH_streetmap_OsmWakisoEntebbe_one?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe>;
  /** insert data into the table: "rtcs_db.PH_streetmap_OsmWakisoKira" */
  insert_rtcs_db_PH_streetmap_OsmWakisoKira?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_streetmap_OsmWakisoKira" */
  insert_rtcs_db_PH_streetmap_OsmWakisoKira_one?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira>;
  /** insert data into the table: "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
  insert_rtcs_db_PH_streetmap_OsmWakisoMakindyeSsabagabo?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
  insert_rtcs_db_PH_streetmap_OsmWakisoMakindyeSsabagabo_one?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo>;
  /** insert data into the table: "rtcs_db.PH_streetmap_OsmWakisoNansana" */
  insert_rtcs_db_PH_streetmap_OsmWakisoNansana?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_streetmap_OsmWakisoNansana" */
  insert_rtcs_db_PH_streetmap_OsmWakisoNansana_one?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana>;
  /** insert data into the table: "rtcs_db.PH_ucc_UCC" */
  insert_rtcs_db_PH_ucc_UCC?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ucc_UCC" */
  insert_rtcs_db_PH_ucc_UCC_one?: Maybe<Rtcs_Db_Ph_Ucc_Ucc>;
  /** insert data into the table: "rtcs_db.PH_umeme_SubscriberAndProperty" */
  insert_rtcs_db_PH_umeme_SubscriberAndProperty?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_umeme_SubscriberAndProperty" */
  insert_rtcs_db_PH_umeme_SubscriberAndProperty_one?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty>;
  /** insert data into the table: "rtcs_db.PH_umeme_Transactions" */
  insert_rtcs_db_PH_umeme_Transactions?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_umeme_Transactions" */
  insert_rtcs_db_PH_umeme_Transactions_one?: Maybe<Rtcs_Db_Ph_Umeme_Transactions>;
  /** insert data into the table: "rtcs_db.PH_ura_BalanceSheetInformation" */
  insert_rtcs_db_PH_ura_BalanceSheetInformation?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_BalanceSheetInformation" */
  insert_rtcs_db_PH_ura_BalanceSheetInformation_one?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation>;
  /** insert data into the table: "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
  insert_rtcs_db_PH_ura_CombinedOfficersAndOwners?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
  insert_rtcs_db_PH_ura_CombinedOfficersAndOwners_one?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners>;
  /** insert data into the table: "rtcs_db.PH_ura_EmploymentIncomeDetail" */
  insert_rtcs_db_PH_ura_EmploymentIncomeDetail?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_EmploymentIncomeDetail" */
  insert_rtcs_db_PH_ura_EmploymentIncomeDetail_one?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail>;
  /** insert data into the table: "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
  insert_rtcs_db_PH_ura_GazetteCommercialRatesPerSquareFootage?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
  insert_rtcs_db_PH_ura_GazetteCommercialRatesPerSquareFootage_one?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage>;
  /** insert data into the table: "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
  insert_rtcs_db_PH_ura_GazetteResidentialRatesPerBedroom?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
  insert_rtcs_db_PH_ura_GazetteResidentialRatesPerBedroom_one?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom>;
  /** insert data into the table: "rtcs_db.PH_ura_HoldingOfficers" */
  insert_rtcs_db_PH_ura_HoldingOfficers?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_HoldingOfficers" */
  insert_rtcs_db_PH_ura_HoldingOfficers_one?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers>;
  /** insert data into the table: "rtcs_db.PH_ura_HoldingOwner" */
  insert_rtcs_db_PH_ura_HoldingOwner?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_HoldingOwner" */
  insert_rtcs_db_PH_ura_HoldingOwner_one?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner>;
  /** insert data into the table: "rtcs_db.PH_ura_IncomeTaxHolding" */
  insert_rtcs_db_PH_ura_IncomeTaxHolding?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_IncomeTaxHolding" */
  insert_rtcs_db_PH_ura_IncomeTaxHolding_one?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding>;
  /** insert data into the table: "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
  insert_rtcs_db_PH_ura_IncomeTaxSummaryIndividual?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
  insert_rtcs_db_PH_ura_IncomeTaxSummaryIndividual_one?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual>;
  /** insert data into the table: "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
  insert_rtcs_db_PH_ura_IncomeTaxSummaryNonIndividual?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
  insert_rtcs_db_PH_ura_IncomeTaxSummaryNonIndividual_one?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual>;
  /** insert data into the table: "rtcs_db.PH_ura_IndividualMortgage" */
  insert_rtcs_db_PH_ura_IndividualMortgage?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_IndividualMortgage" */
  insert_rtcs_db_PH_ura_IndividualMortgage_one?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage>;
  /** insert data into the table: "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
  insert_rtcs_db_PH_ura_IndividualsAssociatedBusiness?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
  insert_rtcs_db_PH_ura_IndividualsAssociatedBusiness_one?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness>;
  /** insert data into the table: "rtcs_db.PH_ura_LandlordIncomeDetail" */
  insert_rtcs_db_PH_ura_LandlordIncomeDetail?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_LandlordIncomeDetail" */
  insert_rtcs_db_PH_ura_LandlordIncomeDetail_one?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail>;
  /** insert data into the table: "rtcs_db.PH_ura_LandlordIncomeSummary" */
  insert_rtcs_db_PH_ura_LandlordIncomeSummary?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_LandlordIncomeSummary" */
  insert_rtcs_db_PH_ura_LandlordIncomeSummary_one?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary>;
  /** insert data into the table: "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
  insert_rtcs_db_PH_ura_NonIndividualsAssociatedBusiness?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
  insert_rtcs_db_PH_ura_NonIndividualsAssociatedBusiness_one?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness>;
  /** insert data into the table: "rtcs_db.PH_ura_RegAssociates" */
  insert_rtcs_db_PH_ura_RegAssociates?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_RegAssociates" */
  insert_rtcs_db_PH_ura_RegAssociates_one?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates>;
  /** insert data into the table: "rtcs_db.PH_ura_RegIndividual" */
  insert_rtcs_db_PH_ura_RegIndividual?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_RegIndividual" */
  insert_rtcs_db_PH_ura_RegIndividual_one?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual>;
  /** insert data into the table: "rtcs_db.PH_ura_RegIndividual_sid" */
  insert_rtcs_db_PH_ura_RegIndividual_sid?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_RegIndividual_sid" */
  insert_rtcs_db_PH_ura_RegIndividual_sid_one?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid>;
  /** insert data into the table: "rtcs_db.PH_ura_RegNonIndividual" */
  insert_rtcs_db_PH_ura_RegNonIndividual?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_RegNonIndividual" */
  insert_rtcs_db_PH_ura_RegNonIndividual_one?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual>;
  /** insert data into the table: "rtcs_db.PH_ura_RegNonIndividual_sid" */
  insert_rtcs_db_PH_ura_RegNonIndividual_sid?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_RegNonIndividual_sid" */
  insert_rtcs_db_PH_ura_RegNonIndividual_sid_one?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid>;
  /** insert data into the table: "rtcs_db.PH_ura_RentExpenseDetails" */
  insert_rtcs_db_PH_ura_RentExpenseDetails?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_RentExpenseDetails" */
  insert_rtcs_db_PH_ura_RentExpenseDetails_one?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails>;
  /** insert data into the table: "rtcs_db.PH_ura_RentalLandLordIncome" */
  insert_rtcs_db_PH_ura_RentalLandLordIncome?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_RentalLandLordIncome" */
  insert_rtcs_db_PH_ura_RentalLandLordIncome_one?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome>;
  /** insert data into the table: "rtcs_db.PH_ura_RentalTenantPayments" */
  insert_rtcs_db_PH_ura_RentalTenantPayments?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_RentalTenantPayments" */
  insert_rtcs_db_PH_ura_RentalTenantPayments_one?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments>;
  /** insert data into the table: "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
  insert_rtcs_db_PH_ura_RipoIndRentalIncSummary?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
  insert_rtcs_db_PH_ura_RipoIndRentalIncSummary_one?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary>;
  /** insert data into the table: "rtcs_db.PH_ura_TenantIncomeDetail" */
  insert_rtcs_db_PH_ura_TenantIncomeDetail?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_TenantIncomeDetail" */
  insert_rtcs_db_PH_ura_TenantIncomeDetail_one?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail>;
  /** insert data into the table: "rtcs_db.PH_ura_Town" */
  insert_rtcs_db_PH_ura_Town?: Maybe<Rtcs_Db_Ph_Ura_Town_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ura_Town" */
  insert_rtcs_db_PH_ura_Town_one?: Maybe<Rtcs_Db_Ph_Ura_Town>;
  /** insert data into the table: "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
  insert_rtcs_db_PH_ursb_CompaniesAndBusinessNamesURSB?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
  insert_rtcs_db_PH_ursb_CompaniesAndBusinessNamesURSB_one?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb>;
  /** insert data into the table: "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
  insert_rtcs_db_RPT_IndividualComplianceSummaryPlusRisk?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
  insert_rtcs_db_RPT_IndividualComplianceSummaryPlusRisk_one?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk>;
  /** insert data into the table: "rtcs_db.RPT_IndividualComposite" */
  insert_rtcs_db_RPT_IndividualComposite?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.RPT_IndividualComposite" */
  insert_rtcs_db_RPT_IndividualComposite_one?: Maybe<Rtcs_Db_Rpt_IndividualComposite>;
  /** insert data into the table: "rtcs_db.RPT_IndividualRegistration" */
  insert_rtcs_db_RPT_IndividualRegistration?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.RPT_IndividualRegistration" */
  insert_rtcs_db_RPT_IndividualRegistration_one?: Maybe<Rtcs_Db_Rpt_IndividualRegistration>;
  /** insert data into the table: "rtcs_db.RPT_IndividualTaxSummaryData" */
  insert_rtcs_db_RPT_IndividualTaxSummaryData?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.RPT_IndividualTaxSummaryData" */
  insert_rtcs_db_RPT_IndividualTaxSummaryData_one?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData>;
  /** insert data into the table: "rtcs_db.RPT_KccaAffiliates" */
  insert_rtcs_db_RPT_KccaAffiliates?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.RPT_KccaAffiliates" */
  insert_rtcs_db_RPT_KccaAffiliates_one?: Maybe<Rtcs_Db_Rpt_KccaAffiliates>;
  /** insert data into the table: "rtcs_db.RPT_KccaCustomerRegistrationData" */
  insert_rtcs_db_RPT_KccaCustomerRegistrationData?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.RPT_KccaCustomerRegistrationData" */
  insert_rtcs_db_RPT_KccaCustomerRegistrationData_one?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData>;
  /** insert data into the table: "rtcs_db.RPT_KccaPropertyRegistration" */
  insert_rtcs_db_RPT_KccaPropertyRegistration?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Mutation_Response>;
  /** insert data into the table: "rtcs_db.RPT_KccaPropertyRegistrationData" */
  insert_rtcs_db_RPT_KccaPropertyRegistrationData?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.RPT_KccaPropertyRegistrationData" */
  insert_rtcs_db_RPT_KccaPropertyRegistrationData_one?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData>;
  /** insert a single row into the table: "rtcs_db.RPT_KccaPropertyRegistration" */
  insert_rtcs_db_RPT_KccaPropertyRegistration_one?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration>;
  /** insert data into the table: "rtcs_db.RPT_NonIndividualBalanceSheet" */
  insert_rtcs_db_RPT_NonIndividualBalanceSheet?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.RPT_NonIndividualBalanceSheet" */
  insert_rtcs_db_RPT_NonIndividualBalanceSheet_one?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet>;
  /** insert data into the table: "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
  insert_rtcs_db_RPT_NonIndividualComplianceSummaryPlusRisk?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
  insert_rtcs_db_RPT_NonIndividualComplianceSummaryPlusRisk_one?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk>;
  /** insert data into the table: "rtcs_db.RPT_NonIndividualComposite" */
  insert_rtcs_db_RPT_NonIndividualComposite?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.RPT_NonIndividualComposite" */
  insert_rtcs_db_RPT_NonIndividualComposite_one?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite>;
  /** insert data into the table: "rtcs_db.RPT_NonIndividualTaxSummaryData" */
  insert_rtcs_db_RPT_NonIndividualTaxSummaryData?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.RPT_NonIndividualTaxSummaryData" */
  insert_rtcs_db_RPT_NonIndividualTaxSummaryData_one?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData>;
  /** insert data into the table: "rtcs_db.RPT_PropertiesComposite" */
  insert_rtcs_db_RPT_PropertiesComposite?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.RPT_PropertiesComposite" */
  insert_rtcs_db_RPT_PropertiesComposite_one?: Maybe<Rtcs_Db_Rpt_PropertiesComposite>;
  /** insert data into the table: "rtcs_db.RPT_PropertyValuation" */
  insert_rtcs_db_RPT_PropertyValuation?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.RPT_PropertyValuation" */
  insert_rtcs_db_RPT_PropertyValuation_one?: Maybe<Rtcs_Db_Rpt_PropertyValuation>;
  /** insert data into the table: "rtcs_db.RPT_RentalTenantPayments" */
  insert_rtcs_db_RPT_RentalTenantPayments?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.RPT_RentalTenantPayments" */
  insert_rtcs_db_RPT_RentalTenantPayments_one?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments>;
  /** insert data into the table: "rtcs_db.aggregate_result" */
  insert_rtcs_db_aggregate_result?: Maybe<Rtcs_Db_Aggregate_Result_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.aggregate_result" */
  insert_rtcs_db_aggregate_result_one?: Maybe<Rtcs_Db_Aggregate_Result>;
  /** insert data into the table: "rtcs_db.aggregate_result_txt" */
  insert_rtcs_db_aggregate_result_txt?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.aggregate_result_txt" */
  insert_rtcs_db_aggregate_result_txt_one?: Maybe<Rtcs_Db_Aggregate_Result_Txt>;
  /** insert data into the table: "rtcs_db.gazette_CommercialRates" */
  insert_rtcs_db_gazette_CommercialRates?: Maybe<Rtcs_Db_Gazette_CommercialRates_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.gazette_CommercialRates" */
  insert_rtcs_db_gazette_CommercialRates_one?: Maybe<Rtcs_Db_Gazette_CommercialRates>;
  /** insert data into the table: "rtcs_db.gazette_ResidentialRates" */
  insert_rtcs_db_gazette_ResidentialRates?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.gazette_ResidentialRates" */
  insert_rtcs_db_gazette_ResidentialRates_one?: Maybe<Rtcs_Db_Gazette_ResidentialRates>;
  /** insert data into the table: "rtcs_db.kcca_AssociateType" */
  insert_rtcs_db_kcca_AssociateType?: Maybe<Rtcs_Db_Kcca_AssociateType_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_AssociateType" */
  insert_rtcs_db_kcca_AssociateType_one?: Maybe<Rtcs_Db_Kcca_AssociateType>;
  /** insert data into the table: "rtcs_db.kcca_CommercialProperties" */
  insert_rtcs_db_kcca_CommercialProperties?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_CommercialProperties" */
  insert_rtcs_db_kcca_CommercialProperties_one?: Maybe<Rtcs_Db_Kcca_CommercialProperties>;
  /** insert data into the table: "rtcs_db.kcca_County" */
  insert_rtcs_db_kcca_County?: Maybe<Rtcs_Db_Kcca_County_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_County" */
  insert_rtcs_db_kcca_County_one?: Maybe<Rtcs_Db_Kcca_County>;
  /** insert data into the table: "rtcs_db.kcca_Customer" */
  insert_rtcs_db_kcca_Customer?: Maybe<Rtcs_Db_Kcca_Customer_Mutation_Response>;
  /** insert data into the table: "rtcs_db.kcca_CustomerApplicantType" */
  insert_rtcs_db_kcca_CustomerApplicantType?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_CustomerApplicantType" */
  insert_rtcs_db_kcca_CustomerApplicantType_one?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType>;
  /** insert data into the table: "rtcs_db.kcca_CustomerBusinessAssociate" */
  insert_rtcs_db_kcca_CustomerBusinessAssociate?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_CustomerBusinessAssociate" */
  insert_rtcs_db_kcca_CustomerBusinessAssociate_one?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate>;
  /** insert data into the table: "rtcs_db.kcca_CustomerBusinessType" */
  insert_rtcs_db_kcca_CustomerBusinessType?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_CustomerBusinessType" */
  insert_rtcs_db_kcca_CustomerBusinessType_one?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType>;
  /** insert data into the table: "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
  insert_rtcs_db_kcca_CustomerCoinDeactivateReactive?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
  insert_rtcs_db_kcca_CustomerCoinDeactivateReactive_one?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive>;
  /** insert data into the table: "rtcs_db.kcca_CustomerContactPerson" */
  insert_rtcs_db_kcca_CustomerContactPerson?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_CustomerContactPerson" */
  insert_rtcs_db_kcca_CustomerContactPerson_one?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson>;
  /** insert data into the table: "rtcs_db.kcca_CustomerDocumentItem" */
  insert_rtcs_db_kcca_CustomerDocumentItem?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_CustomerDocumentItem" */
  insert_rtcs_db_kcca_CustomerDocumentItem_one?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem>;
  /** insert data into the table: "rtcs_db.kcca_CustomerGuardian" */
  insert_rtcs_db_kcca_CustomerGuardian?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_CustomerGuardian" */
  insert_rtcs_db_kcca_CustomerGuardian_one?: Maybe<Rtcs_Db_Kcca_CustomerGuardian>;
  /** insert data into the table: "rtcs_db.kcca_CustomerReference" */
  insert_rtcs_db_kcca_CustomerReference?: Maybe<Rtcs_Db_Kcca_CustomerReference_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_CustomerReference" */
  insert_rtcs_db_kcca_CustomerReference_one?: Maybe<Rtcs_Db_Kcca_CustomerReference>;
  /** insert data into the table: "rtcs_db.kcca_CustomerRegistrationReason" */
  insert_rtcs_db_kcca_CustomerRegistrationReason?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_CustomerRegistrationReason" */
  insert_rtcs_db_kcca_CustomerRegistrationReason_one?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason>;
  /** insert data into the table: "rtcs_db.kcca_CustomerRevenueAgent" */
  insert_rtcs_db_kcca_CustomerRevenueAgent?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_CustomerRevenueAgent" */
  insert_rtcs_db_kcca_CustomerRevenueAgent_one?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent>;
  /** insert data into the table: "rtcs_db.kcca_CustomerType" */
  insert_rtcs_db_kcca_CustomerType?: Maybe<Rtcs_Db_Kcca_CustomerType_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_CustomerType" */
  insert_rtcs_db_kcca_CustomerType_one?: Maybe<Rtcs_Db_Kcca_CustomerType>;
  /** insert a single row into the table: "rtcs_db.kcca_Customer" */
  insert_rtcs_db_kcca_Customer_one?: Maybe<Rtcs_Db_Kcca_Customer>;
  /** insert data into the table: "rtcs_db.kcca_Customer_sid" */
  insert_rtcs_db_kcca_Customer_sid?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_Customer_sid" */
  insert_rtcs_db_kcca_Customer_sid_one?: Maybe<Rtcs_Db_Kcca_Customer_Sid>;
  /** insert data into the table: "rtcs_db.kcca_District" */
  insert_rtcs_db_kcca_District?: Maybe<Rtcs_Db_Kcca_District_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_District" */
  insert_rtcs_db_kcca_District_one?: Maybe<Rtcs_Db_Kcca_District>;
  /** insert data into the table: "rtcs_db.kcca_Division" */
  insert_rtcs_db_kcca_Division?: Maybe<Rtcs_Db_Kcca_Division_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_Division" */
  insert_rtcs_db_kcca_Division_one?: Maybe<Rtcs_Db_Kcca_Division>;
  /** insert data into the table: "rtcs_db.kcca_ImgsData" */
  insert_rtcs_db_kcca_ImgsData?: Maybe<Rtcs_Db_Kcca_ImgsData_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_ImgsData" */
  insert_rtcs_db_kcca_ImgsData_one?: Maybe<Rtcs_Db_Kcca_ImgsData>;
  /** insert data into the table: "rtcs_db.kcca_Parish" */
  insert_rtcs_db_kcca_Parish?: Maybe<Rtcs_Db_Kcca_Parish_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_Parish" */
  insert_rtcs_db_kcca_Parish_one?: Maybe<Rtcs_Db_Kcca_Parish>;
  /** insert data into the table: "rtcs_db.kcca_PaymentDetails" */
  insert_rtcs_db_kcca_PaymentDetails?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_PaymentDetails" */
  insert_rtcs_db_kcca_PaymentDetails_one?: Maybe<Rtcs_Db_Kcca_PaymentDetails>;
  /** insert data into the table: "rtcs_db.kcca_Payments" */
  insert_rtcs_db_kcca_Payments?: Maybe<Rtcs_Db_Kcca_Payments_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_Payments" */
  insert_rtcs_db_kcca_Payments_one?: Maybe<Rtcs_Db_Kcca_Payments>;
  /** insert data into the table: "rtcs_db.kcca_Property" */
  insert_rtcs_db_kcca_Property?: Maybe<Rtcs_Db_Kcca_Property_Mutation_Response>;
  /** insert data into the table: "rtcs_db.kcca_PropertyFloors" */
  insert_rtcs_db_kcca_PropertyFloors?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_PropertyFloors" */
  insert_rtcs_db_kcca_PropertyFloors_one?: Maybe<Rtcs_Db_Kcca_PropertyFloors>;
  /** insert data into the table: "rtcs_db.kcca_PropertyNumberBedrooms" */
  insert_rtcs_db_kcca_PropertyNumberBedrooms?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_PropertyNumberBedrooms" */
  insert_rtcs_db_kcca_PropertyNumberBedrooms_one?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms>;
  /** insert data into the table: "rtcs_db.kcca_PropertyRentedStatus" */
  insert_rtcs_db_kcca_PropertyRentedStatus?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_PropertyRentedStatus" */
  insert_rtcs_db_kcca_PropertyRentedStatus_one?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus>;
  /** insert data into the table: "rtcs_db.kcca_PropertySecurity" */
  insert_rtcs_db_kcca_PropertySecurity?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_PropertySecurity" */
  insert_rtcs_db_kcca_PropertySecurity_one?: Maybe<Rtcs_Db_Kcca_PropertySecurity>;
  /** insert data into the table: "rtcs_db.kcca_PropertySubType" */
  insert_rtcs_db_kcca_PropertySubType?: Maybe<Rtcs_Db_Kcca_PropertySubType_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_PropertySubType" */
  insert_rtcs_db_kcca_PropertySubType_one?: Maybe<Rtcs_Db_Kcca_PropertySubType>;
  /** insert data into the table: "rtcs_db.kcca_PropertyType" */
  insert_rtcs_db_kcca_PropertyType?: Maybe<Rtcs_Db_Kcca_PropertyType_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_PropertyType" */
  insert_rtcs_db_kcca_PropertyType_one?: Maybe<Rtcs_Db_Kcca_PropertyType>;
  /** insert a single row into the table: "rtcs_db.kcca_Property" */
  insert_rtcs_db_kcca_Property_one?: Maybe<Rtcs_Db_Kcca_Property>;
  /** insert data into the table: "rtcs_db.kcca_RawProperties1" */
  insert_rtcs_db_kcca_RawProperties1?: Maybe<Rtcs_Db_Kcca_RawProperties1_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_RawProperties1" */
  insert_rtcs_db_kcca_RawProperties1_one?: Maybe<Rtcs_Db_Kcca_RawProperties1>;
  /** insert data into the table: "rtcs_db.kcca_RawProperties2" */
  insert_rtcs_db_kcca_RawProperties2?: Maybe<Rtcs_Db_Kcca_RawProperties2_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_RawProperties2" */
  insert_rtcs_db_kcca_RawProperties2_one?: Maybe<Rtcs_Db_Kcca_RawProperties2>;
  /** insert data into the table: "rtcs_db.kcca_ResidentialProperties" */
  insert_rtcs_db_kcca_ResidentialProperties?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_ResidentialProperties" */
  insert_rtcs_db_kcca_ResidentialProperties_one?: Maybe<Rtcs_Db_Kcca_ResidentialProperties>;
  /** insert data into the table: "rtcs_db.kcca_RevenueType" */
  insert_rtcs_db_kcca_RevenueType?: Maybe<Rtcs_Db_Kcca_RevenueType_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_RevenueType" */
  insert_rtcs_db_kcca_RevenueType_one?: Maybe<Rtcs_Db_Kcca_RevenueType>;
  /** insert data into the table: "rtcs_db.kcca_SubCounty" */
  insert_rtcs_db_kcca_SubCounty?: Maybe<Rtcs_Db_Kcca_SubCounty_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_SubCounty" */
  insert_rtcs_db_kcca_SubCounty_one?: Maybe<Rtcs_Db_Kcca_SubCounty>;
  /** insert data into the table: "rtcs_db.kcca_SubpropertyTypeConversion" */
  insert_rtcs_db_kcca_SubpropertyTypeConversion?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_SubpropertyTypeConversion" */
  insert_rtcs_db_kcca_SubpropertyTypeConversion_one?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion>;
  /** insert data into the table: "rtcs_db.kcca_TradingLicense" */
  insert_rtcs_db_kcca_TradingLicense?: Maybe<Rtcs_Db_Kcca_TradingLicense_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_TradingLicense" */
  insert_rtcs_db_kcca_TradingLicense_one?: Maybe<Rtcs_Db_Kcca_TradingLicense>;
  /** insert data into the table: "rtcs_db.kcca_Village" */
  insert_rtcs_db_kcca_Village?: Maybe<Rtcs_Db_Kcca_Village_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.kcca_Village" */
  insert_rtcs_db_kcca_Village_one?: Maybe<Rtcs_Db_Kcca_Village>;
  /** insert data into the table: "rtcs_db.master_MasterIndiv" */
  insert_rtcs_db_master_MasterIndiv?: Maybe<Rtcs_Db_Master_MasterIndiv_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.master_MasterIndiv" */
  insert_rtcs_db_master_MasterIndiv_one?: Maybe<Rtcs_Db_Master_MasterIndiv>;
  /** insert data into the table: "rtcs_db.mlhud_KCCA" */
  insert_rtcs_db_mlhud_KCCA?: Maybe<Rtcs_Db_Mlhud_Kcca_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.mlhud_KCCA" */
  insert_rtcs_db_mlhud_KCCA_one?: Maybe<Rtcs_Db_Mlhud_Kcca>;
  /** insert data into the table: "rtcs_db.mlhud_Mukono" */
  insert_rtcs_db_mlhud_Mukono?: Maybe<Rtcs_Db_Mlhud_Mukono_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.mlhud_Mukono" */
  insert_rtcs_db_mlhud_Mukono_one?: Maybe<Rtcs_Db_Mlhud_Mukono>;
  /** insert data into the table: "rtcs_db.mlhud_Properties" */
  insert_rtcs_db_mlhud_Properties?: Maybe<Rtcs_Db_Mlhud_Properties_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.mlhud_Properties" */
  insert_rtcs_db_mlhud_Properties_one?: Maybe<Rtcs_Db_Mlhud_Properties>;
  /** insert data into the table: "rtcs_db.mlhud_WakisoBusiro" */
  insert_rtcs_db_mlhud_WakisoBusiro?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.mlhud_WakisoBusiro" */
  insert_rtcs_db_mlhud_WakisoBusiro_one?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro>;
  /** insert data into the table: "rtcs_db.mlhud_WakisoKyadondo" */
  insert_rtcs_db_mlhud_WakisoKyadondo?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.mlhud_WakisoKyadondo" */
  insert_rtcs_db_mlhud_WakisoKyadondo_one?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo>;
  /** insert data into the table: "rtcs_db.nira_Nira" */
  insert_rtcs_db_nira_Nira?: Maybe<Rtcs_Db_Nira_Nira_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.nira_Nira" */
  insert_rtcs_db_nira_Nira_one?: Maybe<Rtcs_Db_Nira_Nira>;
  /** insert data into the table: "rtcs_db.nira_RawData" */
  insert_rtcs_db_nira_RawData?: Maybe<Rtcs_Db_Nira_RawData_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.nira_RawData" */
  insert_rtcs_db_nira_RawData_one?: Maybe<Rtcs_Db_Nira_RawData>;
  /** insert data into the table: "rtcs_db.nwsc_Customers" */
  insert_rtcs_db_nwsc_Customers?: Maybe<Rtcs_Db_Nwsc_Customers_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.nwsc_Customers" */
  insert_rtcs_db_nwsc_Customers_one?: Maybe<Rtcs_Db_Nwsc_Customers>;
  /** insert data into the table: "rtcs_db.nwsc_NWCustomers" */
  insert_rtcs_db_nwsc_NWCustomers?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.nwsc_NWCustomers" */
  insert_rtcs_db_nwsc_NWCustomers_one?: Maybe<Rtcs_Db_Nwsc_NwCustomers>;
  /** insert data into the table: "rtcs_db.nwsc_RPT_Transactions" */
  insert_rtcs_db_nwsc_RPT_Transactions?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.nwsc_RPT_Transactions" */
  insert_rtcs_db_nwsc_RPT_Transactions_one?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions>;
  /** insert data into the table: "rtcs_db.nwsc_Transactions" */
  insert_rtcs_db_nwsc_Transactions?: Maybe<Rtcs_Db_Nwsc_Transactions_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.nwsc_Transactions" */
  insert_rtcs_db_nwsc_Transactions_one?: Maybe<Rtcs_Db_Nwsc_Transactions>;
  /** insert data into the table: "rtcs_db.nwsc_URATransactions" */
  insert_rtcs_db_nwsc_URATransactions?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.nwsc_URATransactions" */
  insert_rtcs_db_nwsc_URATransactions_one?: Maybe<Rtcs_Db_Nwsc_UraTransactions>;
  /** insert data into the table: "rtcs_db.rcapture_RPTUnittypes" */
  insert_rtcs_db_rcapture_RPTUnittypes?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rcapture_RPTUnittypes" */
  insert_rtcs_db_rcapture_RPTUnittypes_one?: Maybe<Rtcs_Db_Rcapture_RptUnittypes>;
  /** insert data into the table: "rtcs_db.rcapture_RPTlessors" */
  insert_rtcs_db_rcapture_RPTlessors?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rcapture_RPTlessors" */
  insert_rtcs_db_rcapture_RPTlessors_one?: Maybe<Rtcs_Db_Rcapture_RpTlessors>;
  /** insert data into the table: "rtcs_db.rcapture_RPTmeta" */
  insert_rtcs_db_rcapture_RPTmeta?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rcapture_RPTmeta" */
  insert_rtcs_db_rcapture_RPTmeta_one?: Maybe<Rtcs_Db_Rcapture_RpTmeta>;
  /** insert data into the table: "rtcs_db.rcapture_RPTproperties" */
  insert_rtcs_db_rcapture_RPTproperties?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rcapture_RPTproperties" */
  insert_rtcs_db_rcapture_RPTproperties_one?: Maybe<Rtcs_Db_Rcapture_RpTproperties>;
  /** insert data into the table: "rtcs_db.rcapture_RPTtenants" */
  insert_rtcs_db_rcapture_RPTtenants?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rcapture_RPTtenants" */
  insert_rtcs_db_rcapture_RPTtenants_one?: Maybe<Rtcs_Db_Rcapture_RpTtenants>;
  /** insert data into the table: "rtcs_db.rmatch_Individual" */
  insert_rtcs_db_rmatch_Individual?: Maybe<Rtcs_Db_Rmatch_Individual_Mutation_Response>;
  /** insert data into the table: "rtcs_db.rmatch_IndividualComplianceDetailReport" */
  insert_rtcs_db_rmatch_IndividualComplianceDetailReport?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_IndividualComplianceDetailReport" */
  insert_rtcs_db_rmatch_IndividualComplianceDetailReport_one?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport>;
  /** insert data into the table: "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
  insert_rtcs_db_rmatch_IndividualComplianceSummaryReport?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
  insert_rtcs_db_rmatch_IndividualComplianceSummaryReport_one?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport>;
  /** insert data into the table: "rtcs_db.rmatch_IndividualRiskScore" */
  insert_rtcs_db_rmatch_IndividualRiskScore?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_IndividualRiskScore" */
  insert_rtcs_db_rmatch_IndividualRiskScore_one?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore>;
  /** insert a single row into the table: "rtcs_db.rmatch_Individual" */
  insert_rtcs_db_rmatch_Individual_one?: Maybe<Rtcs_Db_Rmatch_Individual>;
  /** insert data into the table: "rtcs_db.rmatch_IndividualsNetwork" */
  insert_rtcs_db_rmatch_IndividualsNetwork?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Mutation_Response>;
  /** insert data into the table: "rtcs_db.rmatch_IndividualsNetworkTesting" */
  insert_rtcs_db_rmatch_IndividualsNetworkTesting?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_IndividualsNetworkTesting" */
  insert_rtcs_db_rmatch_IndividualsNetworkTesting_one?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting>;
  /** insert a single row into the table: "rtcs_db.rmatch_IndividualsNetwork" */
  insert_rtcs_db_rmatch_IndividualsNetwork_one?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork>;
  /** insert data into the table: "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
  insert_rtcs_db_rmatch_KccaToKccaIndividualOwners?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
  insert_rtcs_db_rmatch_KccaToKccaIndividualOwners_one?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners>;
  /** insert data into the table: "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
  insert_rtcs_db_rmatch_KccaToKccaNonindividualOwners?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
  insert_rtcs_db_rmatch_KccaToKccaNonindividualOwners_one?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners>;
  /** insert data into the table: "rtcs_db.rmatch_MatchIndividualsProperties" */
  insert_rtcs_db_rmatch_MatchIndividualsProperties?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_MatchIndividualsProperties" */
  insert_rtcs_db_rmatch_MatchIndividualsProperties_one?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties>;
  /** insert data into the table: "rtcs_db.rmatch_MatchIndividualsProps" */
  insert_rtcs_db_rmatch_MatchIndividualsProps?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_MatchIndividualsProps" */
  insert_rtcs_db_rmatch_MatchIndividualsProps_one?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps>;
  /** insert data into the table: "rtcs_db.rmatch_NoReturns" */
  insert_rtcs_db_rmatch_NoReturns?: Maybe<Rtcs_Db_Rmatch_NoReturns_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_NoReturns" */
  insert_rtcs_db_rmatch_NoReturns_one?: Maybe<Rtcs_Db_Rmatch_NoReturns>;
  /** insert data into the table: "rtcs_db.rmatch_NonIndividual" */
  insert_rtcs_db_rmatch_NonIndividual?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Mutation_Response>;
  /** insert data into the table: "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
  insert_rtcs_db_rmatch_NonIndividualComplianceDetailReport?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
  insert_rtcs_db_rmatch_NonIndividualComplianceDetailReport_one?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport>;
  /** insert data into the table: "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
  insert_rtcs_db_rmatch_NonIndividualComplianceSummaryReport?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
  insert_rtcs_db_rmatch_NonIndividualComplianceSummaryReport_one?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport>;
  /** insert data into the table: "rtcs_db.rmatch_NonIndividualRiskScore" */
  insert_rtcs_db_rmatch_NonIndividualRiskScore?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_NonIndividualRiskScore" */
  insert_rtcs_db_rmatch_NonIndividualRiskScore_one?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore>;
  /** insert a single row into the table: "rtcs_db.rmatch_NonIndividual" */
  insert_rtcs_db_rmatch_NonIndividual_one?: Maybe<Rtcs_Db_Rmatch_NonIndividual>;
  /** insert data into the table: "rtcs_db.rmatch_NonIndividuals" */
  insert_rtcs_db_rmatch_NonIndividuals?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Mutation_Response>;
  /** insert data into the table: "rtcs_db.rmatch_NonIndividualsNetwork" */
  insert_rtcs_db_rmatch_NonIndividualsNetwork?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_NonIndividualsNetwork" */
  insert_rtcs_db_rmatch_NonIndividualsNetwork_one?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork>;
  /** insert a single row into the table: "rtcs_db.rmatch_NonIndividuals" */
  insert_rtcs_db_rmatch_NonIndividuals_one?: Maybe<Rtcs_Db_Rmatch_NonIndividuals>;
  /** insert data into the table: "rtcs_db.rmatch_NonindividualOwners" */
  insert_rtcs_db_rmatch_NonindividualOwners?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_NonindividualOwners" */
  insert_rtcs_db_rmatch_NonindividualOwners_one?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners>;
  /** insert data into the table: "rtcs_db.rmatch_Nonindividuals" */
  insert_rtcs_db_rmatch_Nonindividuals?: Maybe<Rtcs_Db_Rmatch_Nonindividuals_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_Nonindividuals" */
  insert_rtcs_db_rmatch_Nonindividuals_one?: Maybe<Rtcs_Db_Rmatch_Nonindividuals>;
  /** insert data into the table: "rtcs_db.rmatch_PropertiesComposite" */
  insert_rtcs_db_rmatch_PropertiesComposite?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_PropertiesComposite" */
  insert_rtcs_db_rmatch_PropertiesComposite_one?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite>;
  /** insert data into the table: "rtcs_db.rmatch_PropertiesNetwork" */
  insert_rtcs_db_rmatch_PropertiesNetwork?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_PropertiesNetwork" */
  insert_rtcs_db_rmatch_PropertiesNetwork_one?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork>;
  /** insert data into the table: "rtcs_db.rmatch_PropertyNetwork" */
  insert_rtcs_db_rmatch_PropertyNetwork?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_PropertyNetwork" */
  insert_rtcs_db_rmatch_PropertyNetwork_one?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork>;
  /** insert data into the table: "rtcs_db.rmatch_RMatchDetailed904" */
  insert_rtcs_db_rmatch_RMatchDetailed904?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_RMatchDetailed904" */
  insert_rtcs_db_rmatch_RMatchDetailed904_one?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904>;
  /** insert data into the table: "rtcs_db.rmatch_RMatchDetailed916" */
  insert_rtcs_db_rmatch_RMatchDetailed916?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Mutation_Response>;
  /** insert data into the table: "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
  insert_rtcs_db_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
  insert_rtcs_db_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_one?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy>;
  /** insert a single row into the table: "rtcs_db.rmatch_RMatchDetailed916" */
  insert_rtcs_db_rmatch_RMatchDetailed916_one?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916>;
  /** insert data into the table: "rtcs_db.rmatch_RMatchDetailed937" */
  insert_rtcs_db_rmatch_RMatchDetailed937?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_RMatchDetailed937" */
  insert_rtcs_db_rmatch_RMatchDetailed937_one?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937>;
  /** insert data into the table: "rtcs_db.rmatch_RMatchKccaProperties" */
  insert_rtcs_db_rmatch_RMatchKccaProperties?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_RMatchKccaProperties" */
  insert_rtcs_db_rmatch_RMatchKccaProperties_one?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties>;
  /** insert data into the table: "rtcs_db.rmatch_RMatchKccaProperty" */
  insert_rtcs_db_rmatch_RMatchKccaProperty?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_RMatchKccaProperty" */
  insert_rtcs_db_rmatch_RMatchKccaProperty_one?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty>;
  /** insert data into the table: "rtcs_db.rmatch_RMatchNonIndividual903" */
  insert_rtcs_db_rmatch_RMatchNonIndividual903?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_RMatchNonIndividual903" */
  insert_rtcs_db_rmatch_RMatchNonIndividual903_one?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903>;
  /** insert data into the table: "rtcs_db.rmatch_RMatchProperties1115" */
  insert_rtcs_db_rmatch_RMatchProperties1115?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_RMatchProperties1115" */
  insert_rtcs_db_rmatch_RMatchProperties1115_one?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115>;
  /** insert data into the table: "rtcs_db.rmatch_RMatchSummary904" */
  insert_rtcs_db_rmatch_RMatchSummary904?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_RMatchSummary904" */
  insert_rtcs_db_rmatch_RMatchSummary904_one?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904>;
  /** insert data into the table: "rtcs_db.rmatch_RMatchSummary916" */
  insert_rtcs_db_rmatch_RMatchSummary916?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Mutation_Response>;
  /** insert data into the table: "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
  insert_rtcs_db_rmatch_RMatchSummary916ExcessiveOwnerOccupancy?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
  insert_rtcs_db_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_one?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy>;
  /** insert a single row into the table: "rtcs_db.rmatch_RMatchSummary916" */
  insert_rtcs_db_rmatch_RMatchSummary916_one?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916>;
  /** insert data into the table: "rtcs_db.rmatch_RMatchSummary937" */
  insert_rtcs_db_rmatch_RMatchSummary937?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_RMatchSummary937" */
  insert_rtcs_db_rmatch_RMatchSummary937_one?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937>;
  /** insert data into the table: "rtcs_db.rmatch_TinsURA" */
  insert_rtcs_db_rmatch_TinsURA?: Maybe<Rtcs_Db_Rmatch_TinsUra_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_TinsURA" */
  insert_rtcs_db_rmatch_TinsURA_one?: Maybe<Rtcs_Db_Rmatch_TinsUra>;
  /** insert data into the table: "rtcs_db.rmatch_Undeclared" */
  insert_rtcs_db_rmatch_Undeclared?: Maybe<Rtcs_Db_Rmatch_Undeclared_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_Undeclared" */
  insert_rtcs_db_rmatch_Undeclared_one?: Maybe<Rtcs_Db_Rmatch_Undeclared>;
  /** insert data into the table: "rtcs_db.rmatch_Underreported" */
  insert_rtcs_db_rmatch_Underreported?: Maybe<Rtcs_Db_Rmatch_Underreported_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_Underreported" */
  insert_rtcs_db_rmatch_Underreported_one?: Maybe<Rtcs_Db_Rmatch_Underreported>;
  /** insert data into the table: "rtcs_db.rmatch_Unregistered" */
  insert_rtcs_db_rmatch_Unregistered?: Maybe<Rtcs_Db_Rmatch_Unregistered_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_Unregistered" */
  insert_rtcs_db_rmatch_Unregistered_one?: Maybe<Rtcs_Db_Rmatch_Unregistered>;
  /** insert data into the table: "rtcs_db.rmatch_mlhudConsolidated" */
  insert_rtcs_db_rmatch_mlhudConsolidated?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.rmatch_mlhudConsolidated" */
  insert_rtcs_db_rmatch_mlhudConsolidated_one?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated>;
  /** insert data into the table: "rtcs_db.streetmap_OsmMukonoMunicipality" */
  insert_rtcs_db_streetmap_OsmMukonoMunicipality?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.streetmap_OsmMukonoMunicipality" */
  insert_rtcs_db_streetmap_OsmMukonoMunicipality_one?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality>;
  /** insert data into the table: "rtcs_db.streetmap_OsmMukonoNakifuma" */
  insert_rtcs_db_streetmap_OsmMukonoNakifuma?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.streetmap_OsmMukonoNakifuma" */
  insert_rtcs_db_streetmap_OsmMukonoNakifuma_one?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma>;
  /** insert data into the table: "rtcs_db.streetmap_OsmWakisoEntebbe" */
  insert_rtcs_db_streetmap_OsmWakisoEntebbe?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.streetmap_OsmWakisoEntebbe" */
  insert_rtcs_db_streetmap_OsmWakisoEntebbe_one?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe>;
  /** insert data into the table: "rtcs_db.streetmap_OsmWakisoKira" */
  insert_rtcs_db_streetmap_OsmWakisoKira?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.streetmap_OsmWakisoKira" */
  insert_rtcs_db_streetmap_OsmWakisoKira_one?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira>;
  /** insert data into the table: "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
  insert_rtcs_db_streetmap_OsmWakisoMakindyeSsabagabo?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
  insert_rtcs_db_streetmap_OsmWakisoMakindyeSsabagabo_one?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo>;
  /** insert data into the table: "rtcs_db.streetmap_OsmWakisoNansana" */
  insert_rtcs_db_streetmap_OsmWakisoNansana?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.streetmap_OsmWakisoNansana" */
  insert_rtcs_db_streetmap_OsmWakisoNansana_one?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana>;
  /** insert data into the table: "rtcs_db.ucc_UCC" */
  insert_rtcs_db_ucc_UCC?: Maybe<Rtcs_Db_Ucc_Ucc_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ucc_UCC" */
  insert_rtcs_db_ucc_UCC_one?: Maybe<Rtcs_Db_Ucc_Ucc>;
  /** insert data into the table: "rtcs_db.umeme_SubscriberAndProperty" */
  insert_rtcs_db_umeme_SubscriberAndProperty?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.umeme_SubscriberAndProperty" */
  insert_rtcs_db_umeme_SubscriberAndProperty_one?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty>;
  /** insert data into the table: "rtcs_db.umeme_Transactions" */
  insert_rtcs_db_umeme_Transactions?: Maybe<Rtcs_Db_Umeme_Transactions_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.umeme_Transactions" */
  insert_rtcs_db_umeme_Transactions_one?: Maybe<Rtcs_Db_Umeme_Transactions>;
  /** insert data into the table: "rtcs_db.ura_BalanceSheetInformation" */
  insert_rtcs_db_ura_BalanceSheetInformation?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_BalanceSheetInformation" */
  insert_rtcs_db_ura_BalanceSheetInformation_one?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation>;
  /** insert data into the table: "rtcs_db.ura_CombinedOfficersAndOwners" */
  insert_rtcs_db_ura_CombinedOfficersAndOwners?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_CombinedOfficersAndOwners" */
  insert_rtcs_db_ura_CombinedOfficersAndOwners_one?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners>;
  /** insert data into the table: "rtcs_db.ura_EmploymentIncomeDetail" */
  insert_rtcs_db_ura_EmploymentIncomeDetail?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_EmploymentIncomeDetail" */
  insert_rtcs_db_ura_EmploymentIncomeDetail_one?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail>;
  /** insert data into the table: "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
  insert_rtcs_db_ura_GazetteCommercialRatesPerSquareFootage?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
  insert_rtcs_db_ura_GazetteCommercialRatesPerSquareFootage_one?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage>;
  /** insert data into the table: "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
  insert_rtcs_db_ura_GazetteResidentialRatesPerBedroom?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
  insert_rtcs_db_ura_GazetteResidentialRatesPerBedroom_one?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom>;
  /** insert data into the table: "rtcs_db.ura_HoldingOfficers" */
  insert_rtcs_db_ura_HoldingOfficers?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_HoldingOfficers" */
  insert_rtcs_db_ura_HoldingOfficers_one?: Maybe<Rtcs_Db_Ura_HoldingOfficers>;
  /** insert data into the table: "rtcs_db.ura_HoldingOwner" */
  insert_rtcs_db_ura_HoldingOwner?: Maybe<Rtcs_Db_Ura_HoldingOwner_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_HoldingOwner" */
  insert_rtcs_db_ura_HoldingOwner_one?: Maybe<Rtcs_Db_Ura_HoldingOwner>;
  /** insert data into the table: "rtcs_db.ura_IncomeTaxHolding" */
  insert_rtcs_db_ura_IncomeTaxHolding?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_IncomeTaxHolding" */
  insert_rtcs_db_ura_IncomeTaxHolding_one?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding>;
  /** insert data into the table: "rtcs_db.ura_IncomeTaxSummaryIndividual" */
  insert_rtcs_db_ura_IncomeTaxSummaryIndividual?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_IncomeTaxSummaryIndividual" */
  insert_rtcs_db_ura_IncomeTaxSummaryIndividual_one?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual>;
  /** insert data into the table: "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
  insert_rtcs_db_ura_IncomeTaxSummaryNonIndividual?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
  insert_rtcs_db_ura_IncomeTaxSummaryNonIndividual_one?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual>;
  /** insert data into the table: "rtcs_db.ura_IndividualMortgage" */
  insert_rtcs_db_ura_IndividualMortgage?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_IndividualMortgage" */
  insert_rtcs_db_ura_IndividualMortgage_one?: Maybe<Rtcs_Db_Ura_IndividualMortgage>;
  /** insert data into the table: "rtcs_db.ura_IndividualsAssociatedBusiness" */
  insert_rtcs_db_ura_IndividualsAssociatedBusiness?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_IndividualsAssociatedBusiness" */
  insert_rtcs_db_ura_IndividualsAssociatedBusiness_one?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness>;
  /** insert data into the table: "rtcs_db.ura_LandlordIncomeDetail" */
  insert_rtcs_db_ura_LandlordIncomeDetail?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_LandlordIncomeDetail" */
  insert_rtcs_db_ura_LandlordIncomeDetail_one?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail>;
  /** insert data into the table: "rtcs_db.ura_LandlordIncomeSummary" */
  insert_rtcs_db_ura_LandlordIncomeSummary?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_LandlordIncomeSummary" */
  insert_rtcs_db_ura_LandlordIncomeSummary_one?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary>;
  /** insert data into the table: "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
  insert_rtcs_db_ura_NonIndividualsAssociatedBusiness?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
  insert_rtcs_db_ura_NonIndividualsAssociatedBusiness_one?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness>;
  /** insert data into the table: "rtcs_db.ura_RegAssociates" */
  insert_rtcs_db_ura_RegAssociates?: Maybe<Rtcs_Db_Ura_RegAssociates_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_RegAssociates" */
  insert_rtcs_db_ura_RegAssociates_one?: Maybe<Rtcs_Db_Ura_RegAssociates>;
  /** insert data into the table: "rtcs_db.ura_RegIndividual" */
  insert_rtcs_db_ura_RegIndividual?: Maybe<Rtcs_Db_Ura_RegIndividual_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_RegIndividual" */
  insert_rtcs_db_ura_RegIndividual_one?: Maybe<Rtcs_Db_Ura_RegIndividual>;
  /** insert data into the table: "rtcs_db.ura_RegIndividual_sid" */
  insert_rtcs_db_ura_RegIndividual_sid?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_RegIndividual_sid" */
  insert_rtcs_db_ura_RegIndividual_sid_one?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid>;
  /** insert data into the table: "rtcs_db.ura_RegNonIndividual" */
  insert_rtcs_db_ura_RegNonIndividual?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_RegNonIndividual" */
  insert_rtcs_db_ura_RegNonIndividual_one?: Maybe<Rtcs_Db_Ura_RegNonIndividual>;
  /** insert data into the table: "rtcs_db.ura_RegNonIndividual_sid" */
  insert_rtcs_db_ura_RegNonIndividual_sid?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_RegNonIndividual_sid" */
  insert_rtcs_db_ura_RegNonIndividual_sid_one?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid>;
  /** insert data into the table: "rtcs_db.ura_RentExpenseDetails" */
  insert_rtcs_db_ura_RentExpenseDetails?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_RentExpenseDetails" */
  insert_rtcs_db_ura_RentExpenseDetails_one?: Maybe<Rtcs_Db_Ura_RentExpenseDetails>;
  /** insert data into the table: "rtcs_db.ura_RentalLandLordIncome" */
  insert_rtcs_db_ura_RentalLandLordIncome?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_RentalLandLordIncome" */
  insert_rtcs_db_ura_RentalLandLordIncome_one?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome>;
  /** insert data into the table: "rtcs_db.ura_RentalTenantPayments" */
  insert_rtcs_db_ura_RentalTenantPayments?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_RentalTenantPayments" */
  insert_rtcs_db_ura_RentalTenantPayments_one?: Maybe<Rtcs_Db_Ura_RentalTenantPayments>;
  /** insert data into the table: "rtcs_db.ura_RipoIndRentalIncSummary" */
  insert_rtcs_db_ura_RipoIndRentalIncSummary?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_RipoIndRentalIncSummary" */
  insert_rtcs_db_ura_RipoIndRentalIncSummary_one?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary>;
  /** insert data into the table: "rtcs_db.ura_TenantIncomeDetail" */
  insert_rtcs_db_ura_TenantIncomeDetail?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_TenantIncomeDetail" */
  insert_rtcs_db_ura_TenantIncomeDetail_one?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail>;
  /** insert data into the table: "rtcs_db.ura_Town" */
  insert_rtcs_db_ura_Town?: Maybe<Rtcs_Db_Ura_Town_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ura_Town" */
  insert_rtcs_db_ura_Town_one?: Maybe<Rtcs_Db_Ura_Town>;
  /** insert data into the table: "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
  insert_rtcs_db_ursb_CompaniesAndBusinessNamesURSB?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Mutation_Response>;
  /** insert a single row into the table: "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
  insert_rtcs_db_ursb_CompaniesAndBusinessNamesURSB_one?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb>;
  /** update data of the table: "rtcs_db.EntityFilter" */
  update_rtcs_db_EntityFilter?: Maybe<Rtcs_Db_EntityFilter_Mutation_Response>;
  /** update data of the table: "rtcs_db.Filter" */
  update_rtcs_db_Filter?: Maybe<Rtcs_Db_Filter_Mutation_Response>;
  /** update data of the table: "rtcs_db.GazzetteRates" */
  update_rtcs_db_GazzetteRates?: Maybe<Rtcs_Db_GazzetteRates_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_EntityFilter" */
  update_rtcs_db_PH_EntityFilter?: Maybe<Rtcs_Db_Ph_EntityFilter_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_Filter" */
  update_rtcs_db_PH_Filter?: Maybe<Rtcs_Db_Ph_Filter_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_GazzetteRates" */
  update_rtcs_db_PH_GazzetteRates?: Maybe<Rtcs_Db_Ph_GazzetteRates_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
  update_rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_RPT_IndividualComposite" */
  update_rtcs_db_PH_RPT_IndividualComposite?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_RPT_IndividualRegistration" */
  update_rtcs_db_PH_RPT_IndividualRegistration?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
  update_rtcs_db_PH_RPT_IndividualTaxSummaryData?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_RPT_KccaAffiliates" */
  update_rtcs_db_PH_RPT_KccaAffiliates?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
  update_rtcs_db_PH_RPT_KccaCustomerRegistrationData?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_RPT_KccaPropertyRegistration" */
  update_rtcs_db_PH_RPT_KccaPropertyRegistration?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
  update_rtcs_db_PH_RPT_KccaPropertyRegistrationData?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
  update_rtcs_db_PH_RPT_NonIndividualBalanceSheet?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
  update_rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_RPT_NonIndividualComposite" */
  update_rtcs_db_PH_RPT_NonIndividualComposite?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
  update_rtcs_db_PH_RPT_NonIndividualTaxSummaryData?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_RPT_PropertiesComposite" */
  update_rtcs_db_PH_RPT_PropertiesComposite?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_RPT_PropertyValuation" */
  update_rtcs_db_PH_RPT_PropertyValuation?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_RPT_RentalTenantPayments" */
  update_rtcs_db_PH_RPT_RentalTenantPayments?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_gazette_CommercialRates" */
  update_rtcs_db_PH_gazette_CommercialRates?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_gazette_ResidentialRates" */
  update_rtcs_db_PH_gazette_ResidentialRates?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_AssociateType" */
  update_rtcs_db_PH_kcca_AssociateType?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_CommercialProperties" */
  update_rtcs_db_PH_kcca_CommercialProperties?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_County" */
  update_rtcs_db_PH_kcca_County?: Maybe<Rtcs_Db_Ph_Kcca_County_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_Customer" */
  update_rtcs_db_PH_kcca_Customer?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_CustomerApplicantType" */
  update_rtcs_db_PH_kcca_CustomerApplicantType?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
  update_rtcs_db_PH_kcca_CustomerBusinessAssociate?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_CustomerBusinessType" */
  update_rtcs_db_PH_kcca_CustomerBusinessType?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
  update_rtcs_db_PH_kcca_CustomerCoinDeactivateReactive?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_CustomerContactPerson" */
  update_rtcs_db_PH_kcca_CustomerContactPerson?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_CustomerDocumentItem" */
  update_rtcs_db_PH_kcca_CustomerDocumentItem?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_CustomerGuardian" */
  update_rtcs_db_PH_kcca_CustomerGuardian?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_CustomerReference" */
  update_rtcs_db_PH_kcca_CustomerReference?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_CustomerRegistrationReason" */
  update_rtcs_db_PH_kcca_CustomerRegistrationReason?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_CustomerRevenueAgent" */
  update_rtcs_db_PH_kcca_CustomerRevenueAgent?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_CustomerType" */
  update_rtcs_db_PH_kcca_CustomerType?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_Customer_sid" */
  update_rtcs_db_PH_kcca_Customer_sid?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_District" */
  update_rtcs_db_PH_kcca_District?: Maybe<Rtcs_Db_Ph_Kcca_District_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_Division" */
  update_rtcs_db_PH_kcca_Division?: Maybe<Rtcs_Db_Ph_Kcca_Division_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_ImgsData" */
  update_rtcs_db_PH_kcca_ImgsData?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_Parish" */
  update_rtcs_db_PH_kcca_Parish?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_PaymentDetails" */
  update_rtcs_db_PH_kcca_PaymentDetails?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_Payments" */
  update_rtcs_db_PH_kcca_Payments?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_Property" */
  update_rtcs_db_PH_kcca_Property?: Maybe<Rtcs_Db_Ph_Kcca_Property_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_PropertyFloors" */
  update_rtcs_db_PH_kcca_PropertyFloors?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
  update_rtcs_db_PH_kcca_PropertyNumberBedrooms?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_PropertyRentedStatus" */
  update_rtcs_db_PH_kcca_PropertyRentedStatus?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_PropertySecurity" */
  update_rtcs_db_PH_kcca_PropertySecurity?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_PropertySubType" */
  update_rtcs_db_PH_kcca_PropertySubType?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_PropertyType" */
  update_rtcs_db_PH_kcca_PropertyType?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_RawProperties1" */
  update_rtcs_db_PH_kcca_RawProperties1?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_RawProperties2" */
  update_rtcs_db_PH_kcca_RawProperties2?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_ResidentialProperties" */
  update_rtcs_db_PH_kcca_ResidentialProperties?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_RevenueType" */
  update_rtcs_db_PH_kcca_RevenueType?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_SubCounty" */
  update_rtcs_db_PH_kcca_SubCounty?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
  update_rtcs_db_PH_kcca_SubpropertyTypeConversion?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_TradingLicense" */
  update_rtcs_db_PH_kcca_TradingLicense?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_kcca_Village" */
  update_rtcs_db_PH_kcca_Village?: Maybe<Rtcs_Db_Ph_Kcca_Village_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_master_MasterIndiv" */
  update_rtcs_db_PH_master_MasterIndiv?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_mlhud_KCCA" */
  update_rtcs_db_PH_mlhud_KCCA?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_mlhud_Mukono" */
  update_rtcs_db_PH_mlhud_Mukono?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_mlhud_Properties" */
  update_rtcs_db_PH_mlhud_Properties?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_mlhud_WakisoBusiro" */
  update_rtcs_db_PH_mlhud_WakisoBusiro?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_mlhud_WakisoKyadondo" */
  update_rtcs_db_PH_mlhud_WakisoKyadondo?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_nira_Nira" */
  update_rtcs_db_PH_nira_Nira?: Maybe<Rtcs_Db_Ph_Nira_Nira_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_nira_RawData" */
  update_rtcs_db_PH_nira_RawData?: Maybe<Rtcs_Db_Ph_Nira_RawData_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_nwsc_Customers" */
  update_rtcs_db_PH_nwsc_Customers?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_nwsc_NWCustomers" */
  update_rtcs_db_PH_nwsc_NWCustomers?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_nwsc_RPT_Transactions" */
  update_rtcs_db_PH_nwsc_RPT_Transactions?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_nwsc_Transactions" */
  update_rtcs_db_PH_nwsc_Transactions?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_nwsc_URATransactions" */
  update_rtcs_db_PH_nwsc_URATransactions?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rcapture_RPTUnittypes" */
  update_rtcs_db_PH_rcapture_RPTUnittypes?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rcapture_RPTlessors" */
  update_rtcs_db_PH_rcapture_RPTlessors?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rcapture_RPTmeta" */
  update_rtcs_db_PH_rcapture_RPTmeta?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rcapture_RPTproperties" */
  update_rtcs_db_PH_rcapture_RPTproperties?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rcapture_RPTtenants" */
  update_rtcs_db_PH_rcapture_RPTtenants?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_Individual" */
  update_rtcs_db_PH_rmatch_Individual?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
  update_rtcs_db_PH_rmatch_IndividualComplianceDetailReport?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
  update_rtcs_db_PH_rmatch_IndividualComplianceSummaryReport?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_IndividualRiskScore" */
  update_rtcs_db_PH_rmatch_IndividualRiskScore?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_IndividualsNetwork" */
  update_rtcs_db_PH_rmatch_IndividualsNetwork?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
  update_rtcs_db_PH_rmatch_IndividualsNetworkTesting?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
  update_rtcs_db_PH_rmatch_KccaToKccaIndividualOwners?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
  update_rtcs_db_PH_rmatch_KccaToKccaNonindividualOwners?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
  update_rtcs_db_PH_rmatch_MatchIndividualsProperties?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_MatchIndividualsProps" */
  update_rtcs_db_PH_rmatch_MatchIndividualsProps?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_NoReturns" */
  update_rtcs_db_PH_rmatch_NoReturns?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_NonIndividual" */
  update_rtcs_db_PH_rmatch_NonIndividual?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
  update_rtcs_db_PH_rmatch_NonIndividualComplianceDetailReport?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
  update_rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
  update_rtcs_db_PH_rmatch_NonIndividualRiskScore?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_NonIndividuals" */
  update_rtcs_db_PH_rmatch_NonIndividuals?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
  update_rtcs_db_PH_rmatch_NonIndividualsNetwork?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_NonindividualOwners" */
  update_rtcs_db_PH_rmatch_NonindividualOwners?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_Nonindividuals" */
  update_rtcs_db_PH_rmatch_Nonindividuals?: Maybe<Rtcs_Db_Ph_Rmatch_Nonindividuals_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_PropertiesComposite" */
  update_rtcs_db_PH_rmatch_PropertiesComposite?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_PropertiesNetwork" */
  update_rtcs_db_PH_rmatch_PropertiesNetwork?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_PropertyNetwork" */
  update_rtcs_db_PH_rmatch_PropertyNetwork?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_RMatchDetailed904" */
  update_rtcs_db_PH_rmatch_RMatchDetailed904?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_RMatchDetailed916" */
  update_rtcs_db_PH_rmatch_RMatchDetailed916?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
  update_rtcs_db_PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_RMatchDetailed937" */
  update_rtcs_db_PH_rmatch_RMatchDetailed937?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_RMatchKccaProperties" */
  update_rtcs_db_PH_rmatch_RMatchKccaProperties?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_RMatchKccaProperty" */
  update_rtcs_db_PH_rmatch_RMatchKccaProperty?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
  update_rtcs_db_PH_rmatch_RMatchNonIndividual903?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_RMatchProperties1115" */
  update_rtcs_db_PH_rmatch_RMatchProperties1115?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_RMatchSummary904" */
  update_rtcs_db_PH_rmatch_RMatchSummary904?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_RMatchSummary916" */
  update_rtcs_db_PH_rmatch_RMatchSummary916?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
  update_rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_RMatchSummary937" */
  update_rtcs_db_PH_rmatch_RMatchSummary937?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_TinsURA" */
  update_rtcs_db_PH_rmatch_TinsURA?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_Undeclared" */
  update_rtcs_db_PH_rmatch_Undeclared?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_Underreported" */
  update_rtcs_db_PH_rmatch_Underreported?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_Unregistered" */
  update_rtcs_db_PH_rmatch_Unregistered?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_rmatch_mlhudConsolidated" */
  update_rtcs_db_PH_rmatch_mlhudConsolidated?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
  update_rtcs_db_PH_streetmap_OsmMukonoMunicipality?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
  update_rtcs_db_PH_streetmap_OsmMukonoNakifuma?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
  update_rtcs_db_PH_streetmap_OsmWakisoEntebbe?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_streetmap_OsmWakisoKira" */
  update_rtcs_db_PH_streetmap_OsmWakisoKira?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
  update_rtcs_db_PH_streetmap_OsmWakisoMakindyeSsabagabo?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_streetmap_OsmWakisoNansana" */
  update_rtcs_db_PH_streetmap_OsmWakisoNansana?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ucc_UCC" */
  update_rtcs_db_PH_ucc_UCC?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_umeme_SubscriberAndProperty" */
  update_rtcs_db_PH_umeme_SubscriberAndProperty?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_umeme_Transactions" */
  update_rtcs_db_PH_umeme_Transactions?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_BalanceSheetInformation" */
  update_rtcs_db_PH_ura_BalanceSheetInformation?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
  update_rtcs_db_PH_ura_CombinedOfficersAndOwners?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_EmploymentIncomeDetail" */
  update_rtcs_db_PH_ura_EmploymentIncomeDetail?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
  update_rtcs_db_PH_ura_GazetteCommercialRatesPerSquareFootage?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
  update_rtcs_db_PH_ura_GazetteResidentialRatesPerBedroom?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_HoldingOfficers" */
  update_rtcs_db_PH_ura_HoldingOfficers?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_HoldingOwner" */
  update_rtcs_db_PH_ura_HoldingOwner?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_IncomeTaxHolding" */
  update_rtcs_db_PH_ura_IncomeTaxHolding?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
  update_rtcs_db_PH_ura_IncomeTaxSummaryIndividual?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
  update_rtcs_db_PH_ura_IncomeTaxSummaryNonIndividual?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_IndividualMortgage" */
  update_rtcs_db_PH_ura_IndividualMortgage?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
  update_rtcs_db_PH_ura_IndividualsAssociatedBusiness?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_LandlordIncomeDetail" */
  update_rtcs_db_PH_ura_LandlordIncomeDetail?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_LandlordIncomeSummary" */
  update_rtcs_db_PH_ura_LandlordIncomeSummary?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
  update_rtcs_db_PH_ura_NonIndividualsAssociatedBusiness?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_RegAssociates" */
  update_rtcs_db_PH_ura_RegAssociates?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_RegIndividual" */
  update_rtcs_db_PH_ura_RegIndividual?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_RegIndividual_sid" */
  update_rtcs_db_PH_ura_RegIndividual_sid?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_RegNonIndividual" */
  update_rtcs_db_PH_ura_RegNonIndividual?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_RegNonIndividual_sid" */
  update_rtcs_db_PH_ura_RegNonIndividual_sid?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_RentExpenseDetails" */
  update_rtcs_db_PH_ura_RentExpenseDetails?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_RentalLandLordIncome" */
  update_rtcs_db_PH_ura_RentalLandLordIncome?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_RentalTenantPayments" */
  update_rtcs_db_PH_ura_RentalTenantPayments?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
  update_rtcs_db_PH_ura_RipoIndRentalIncSummary?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_TenantIncomeDetail" */
  update_rtcs_db_PH_ura_TenantIncomeDetail?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ura_Town" */
  update_rtcs_db_PH_ura_Town?: Maybe<Rtcs_Db_Ph_Ura_Town_Mutation_Response>;
  /** update data of the table: "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
  update_rtcs_db_PH_ursb_CompaniesAndBusinessNamesURSB?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Mutation_Response>;
  /** update data of the table: "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
  update_rtcs_db_RPT_IndividualComplianceSummaryPlusRisk?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Mutation_Response>;
  /** update data of the table: "rtcs_db.RPT_IndividualComposite" */
  update_rtcs_db_RPT_IndividualComposite?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Mutation_Response>;
  /** update data of the table: "rtcs_db.RPT_IndividualRegistration" */
  update_rtcs_db_RPT_IndividualRegistration?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Mutation_Response>;
  /** update data of the table: "rtcs_db.RPT_IndividualTaxSummaryData" */
  update_rtcs_db_RPT_IndividualTaxSummaryData?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Mutation_Response>;
  /** update data of the table: "rtcs_db.RPT_KccaAffiliates" */
  update_rtcs_db_RPT_KccaAffiliates?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Mutation_Response>;
  /** update data of the table: "rtcs_db.RPT_KccaCustomerRegistrationData" */
  update_rtcs_db_RPT_KccaCustomerRegistrationData?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Mutation_Response>;
  /** update data of the table: "rtcs_db.RPT_KccaPropertyRegistration" */
  update_rtcs_db_RPT_KccaPropertyRegistration?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Mutation_Response>;
  /** update data of the table: "rtcs_db.RPT_KccaPropertyRegistrationData" */
  update_rtcs_db_RPT_KccaPropertyRegistrationData?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Mutation_Response>;
  /** update data of the table: "rtcs_db.RPT_NonIndividualBalanceSheet" */
  update_rtcs_db_RPT_NonIndividualBalanceSheet?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Mutation_Response>;
  /** update data of the table: "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
  update_rtcs_db_RPT_NonIndividualComplianceSummaryPlusRisk?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Mutation_Response>;
  /** update data of the table: "rtcs_db.RPT_NonIndividualComposite" */
  update_rtcs_db_RPT_NonIndividualComposite?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Mutation_Response>;
  /** update data of the table: "rtcs_db.RPT_NonIndividualTaxSummaryData" */
  update_rtcs_db_RPT_NonIndividualTaxSummaryData?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Mutation_Response>;
  /** update data of the table: "rtcs_db.RPT_PropertiesComposite" */
  update_rtcs_db_RPT_PropertiesComposite?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Mutation_Response>;
  /** update data of the table: "rtcs_db.RPT_PropertyValuation" */
  update_rtcs_db_RPT_PropertyValuation?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Mutation_Response>;
  /** update data of the table: "rtcs_db.RPT_RentalTenantPayments" */
  update_rtcs_db_RPT_RentalTenantPayments?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Mutation_Response>;
  /** update data of the table: "rtcs_db.aggregate_result" */
  update_rtcs_db_aggregate_result?: Maybe<Rtcs_Db_Aggregate_Result_Mutation_Response>;
  /** update data of the table: "rtcs_db.aggregate_result_txt" */
  update_rtcs_db_aggregate_result_txt?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Mutation_Response>;
  /** update data of the table: "rtcs_db.gazette_CommercialRates" */
  update_rtcs_db_gazette_CommercialRates?: Maybe<Rtcs_Db_Gazette_CommercialRates_Mutation_Response>;
  /** update data of the table: "rtcs_db.gazette_ResidentialRates" */
  update_rtcs_db_gazette_ResidentialRates?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_AssociateType" */
  update_rtcs_db_kcca_AssociateType?: Maybe<Rtcs_Db_Kcca_AssociateType_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_CommercialProperties" */
  update_rtcs_db_kcca_CommercialProperties?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_County" */
  update_rtcs_db_kcca_County?: Maybe<Rtcs_Db_Kcca_County_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_Customer" */
  update_rtcs_db_kcca_Customer?: Maybe<Rtcs_Db_Kcca_Customer_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_CustomerApplicantType" */
  update_rtcs_db_kcca_CustomerApplicantType?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_CustomerBusinessAssociate" */
  update_rtcs_db_kcca_CustomerBusinessAssociate?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_CustomerBusinessType" */
  update_rtcs_db_kcca_CustomerBusinessType?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
  update_rtcs_db_kcca_CustomerCoinDeactivateReactive?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_CustomerContactPerson" */
  update_rtcs_db_kcca_CustomerContactPerson?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_CustomerDocumentItem" */
  update_rtcs_db_kcca_CustomerDocumentItem?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_CustomerGuardian" */
  update_rtcs_db_kcca_CustomerGuardian?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_CustomerReference" */
  update_rtcs_db_kcca_CustomerReference?: Maybe<Rtcs_Db_Kcca_CustomerReference_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_CustomerRegistrationReason" */
  update_rtcs_db_kcca_CustomerRegistrationReason?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_CustomerRevenueAgent" */
  update_rtcs_db_kcca_CustomerRevenueAgent?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_CustomerType" */
  update_rtcs_db_kcca_CustomerType?: Maybe<Rtcs_Db_Kcca_CustomerType_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_Customer_sid" */
  update_rtcs_db_kcca_Customer_sid?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_District" */
  update_rtcs_db_kcca_District?: Maybe<Rtcs_Db_Kcca_District_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_Division" */
  update_rtcs_db_kcca_Division?: Maybe<Rtcs_Db_Kcca_Division_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_ImgsData" */
  update_rtcs_db_kcca_ImgsData?: Maybe<Rtcs_Db_Kcca_ImgsData_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_Parish" */
  update_rtcs_db_kcca_Parish?: Maybe<Rtcs_Db_Kcca_Parish_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_PaymentDetails" */
  update_rtcs_db_kcca_PaymentDetails?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_Payments" */
  update_rtcs_db_kcca_Payments?: Maybe<Rtcs_Db_Kcca_Payments_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_Property" */
  update_rtcs_db_kcca_Property?: Maybe<Rtcs_Db_Kcca_Property_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_PropertyFloors" */
  update_rtcs_db_kcca_PropertyFloors?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_PropertyNumberBedrooms" */
  update_rtcs_db_kcca_PropertyNumberBedrooms?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_PropertyRentedStatus" */
  update_rtcs_db_kcca_PropertyRentedStatus?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_PropertySecurity" */
  update_rtcs_db_kcca_PropertySecurity?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_PropertySubType" */
  update_rtcs_db_kcca_PropertySubType?: Maybe<Rtcs_Db_Kcca_PropertySubType_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_PropertyType" */
  update_rtcs_db_kcca_PropertyType?: Maybe<Rtcs_Db_Kcca_PropertyType_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_RawProperties1" */
  update_rtcs_db_kcca_RawProperties1?: Maybe<Rtcs_Db_Kcca_RawProperties1_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_RawProperties2" */
  update_rtcs_db_kcca_RawProperties2?: Maybe<Rtcs_Db_Kcca_RawProperties2_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_ResidentialProperties" */
  update_rtcs_db_kcca_ResidentialProperties?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_RevenueType" */
  update_rtcs_db_kcca_RevenueType?: Maybe<Rtcs_Db_Kcca_RevenueType_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_SubCounty" */
  update_rtcs_db_kcca_SubCounty?: Maybe<Rtcs_Db_Kcca_SubCounty_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_SubpropertyTypeConversion" */
  update_rtcs_db_kcca_SubpropertyTypeConversion?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_TradingLicense" */
  update_rtcs_db_kcca_TradingLicense?: Maybe<Rtcs_Db_Kcca_TradingLicense_Mutation_Response>;
  /** update data of the table: "rtcs_db.kcca_Village" */
  update_rtcs_db_kcca_Village?: Maybe<Rtcs_Db_Kcca_Village_Mutation_Response>;
  /** update data of the table: "rtcs_db.master_MasterIndiv" */
  update_rtcs_db_master_MasterIndiv?: Maybe<Rtcs_Db_Master_MasterIndiv_Mutation_Response>;
  /** update data of the table: "rtcs_db.mlhud_KCCA" */
  update_rtcs_db_mlhud_KCCA?: Maybe<Rtcs_Db_Mlhud_Kcca_Mutation_Response>;
  /** update data of the table: "rtcs_db.mlhud_Mukono" */
  update_rtcs_db_mlhud_Mukono?: Maybe<Rtcs_Db_Mlhud_Mukono_Mutation_Response>;
  /** update data of the table: "rtcs_db.mlhud_Properties" */
  update_rtcs_db_mlhud_Properties?: Maybe<Rtcs_Db_Mlhud_Properties_Mutation_Response>;
  /** update data of the table: "rtcs_db.mlhud_WakisoBusiro" */
  update_rtcs_db_mlhud_WakisoBusiro?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Mutation_Response>;
  /** update data of the table: "rtcs_db.mlhud_WakisoKyadondo" */
  update_rtcs_db_mlhud_WakisoKyadondo?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Mutation_Response>;
  /** update data of the table: "rtcs_db.nira_Nira" */
  update_rtcs_db_nira_Nira?: Maybe<Rtcs_Db_Nira_Nira_Mutation_Response>;
  /** update data of the table: "rtcs_db.nira_RawData" */
  update_rtcs_db_nira_RawData?: Maybe<Rtcs_Db_Nira_RawData_Mutation_Response>;
  /** update data of the table: "rtcs_db.nwsc_Customers" */
  update_rtcs_db_nwsc_Customers?: Maybe<Rtcs_Db_Nwsc_Customers_Mutation_Response>;
  /** update data of the table: "rtcs_db.nwsc_NWCustomers" */
  update_rtcs_db_nwsc_NWCustomers?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Mutation_Response>;
  /** update data of the table: "rtcs_db.nwsc_RPT_Transactions" */
  update_rtcs_db_nwsc_RPT_Transactions?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Mutation_Response>;
  /** update data of the table: "rtcs_db.nwsc_Transactions" */
  update_rtcs_db_nwsc_Transactions?: Maybe<Rtcs_Db_Nwsc_Transactions_Mutation_Response>;
  /** update data of the table: "rtcs_db.nwsc_URATransactions" */
  update_rtcs_db_nwsc_URATransactions?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Mutation_Response>;
  /** update data of the table: "rtcs_db.rcapture_RPTUnittypes" */
  update_rtcs_db_rcapture_RPTUnittypes?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Mutation_Response>;
  /** update data of the table: "rtcs_db.rcapture_RPTlessors" */
  update_rtcs_db_rcapture_RPTlessors?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Mutation_Response>;
  /** update data of the table: "rtcs_db.rcapture_RPTmeta" */
  update_rtcs_db_rcapture_RPTmeta?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Mutation_Response>;
  /** update data of the table: "rtcs_db.rcapture_RPTproperties" */
  update_rtcs_db_rcapture_RPTproperties?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Mutation_Response>;
  /** update data of the table: "rtcs_db.rcapture_RPTtenants" */
  update_rtcs_db_rcapture_RPTtenants?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_Individual" */
  update_rtcs_db_rmatch_Individual?: Maybe<Rtcs_Db_Rmatch_Individual_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_IndividualComplianceDetailReport" */
  update_rtcs_db_rmatch_IndividualComplianceDetailReport?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
  update_rtcs_db_rmatch_IndividualComplianceSummaryReport?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_IndividualRiskScore" */
  update_rtcs_db_rmatch_IndividualRiskScore?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_IndividualsNetwork" */
  update_rtcs_db_rmatch_IndividualsNetwork?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_IndividualsNetworkTesting" */
  update_rtcs_db_rmatch_IndividualsNetworkTesting?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
  update_rtcs_db_rmatch_KccaToKccaIndividualOwners?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
  update_rtcs_db_rmatch_KccaToKccaNonindividualOwners?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_MatchIndividualsProperties" */
  update_rtcs_db_rmatch_MatchIndividualsProperties?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_MatchIndividualsProps" */
  update_rtcs_db_rmatch_MatchIndividualsProps?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_NoReturns" */
  update_rtcs_db_rmatch_NoReturns?: Maybe<Rtcs_Db_Rmatch_NoReturns_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_NonIndividual" */
  update_rtcs_db_rmatch_NonIndividual?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
  update_rtcs_db_rmatch_NonIndividualComplianceDetailReport?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
  update_rtcs_db_rmatch_NonIndividualComplianceSummaryReport?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_NonIndividualRiskScore" */
  update_rtcs_db_rmatch_NonIndividualRiskScore?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_NonIndividuals" */
  update_rtcs_db_rmatch_NonIndividuals?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_NonIndividualsNetwork" */
  update_rtcs_db_rmatch_NonIndividualsNetwork?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_NonindividualOwners" */
  update_rtcs_db_rmatch_NonindividualOwners?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_Nonindividuals" */
  update_rtcs_db_rmatch_Nonindividuals?: Maybe<Rtcs_Db_Rmatch_Nonindividuals_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_PropertiesComposite" */
  update_rtcs_db_rmatch_PropertiesComposite?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_PropertiesNetwork" */
  update_rtcs_db_rmatch_PropertiesNetwork?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_PropertyNetwork" */
  update_rtcs_db_rmatch_PropertyNetwork?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_RMatchDetailed904" */
  update_rtcs_db_rmatch_RMatchDetailed904?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_RMatchDetailed916" */
  update_rtcs_db_rmatch_RMatchDetailed916?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
  update_rtcs_db_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_RMatchDetailed937" */
  update_rtcs_db_rmatch_RMatchDetailed937?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_RMatchKccaProperties" */
  update_rtcs_db_rmatch_RMatchKccaProperties?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_RMatchKccaProperty" */
  update_rtcs_db_rmatch_RMatchKccaProperty?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_RMatchNonIndividual903" */
  update_rtcs_db_rmatch_RMatchNonIndividual903?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_RMatchProperties1115" */
  update_rtcs_db_rmatch_RMatchProperties1115?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_RMatchSummary904" */
  update_rtcs_db_rmatch_RMatchSummary904?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_RMatchSummary916" */
  update_rtcs_db_rmatch_RMatchSummary916?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
  update_rtcs_db_rmatch_RMatchSummary916ExcessiveOwnerOccupancy?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_RMatchSummary937" */
  update_rtcs_db_rmatch_RMatchSummary937?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_TinsURA" */
  update_rtcs_db_rmatch_TinsURA?: Maybe<Rtcs_Db_Rmatch_TinsUra_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_Undeclared" */
  update_rtcs_db_rmatch_Undeclared?: Maybe<Rtcs_Db_Rmatch_Undeclared_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_Underreported" */
  update_rtcs_db_rmatch_Underreported?: Maybe<Rtcs_Db_Rmatch_Underreported_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_Unregistered" */
  update_rtcs_db_rmatch_Unregistered?: Maybe<Rtcs_Db_Rmatch_Unregistered_Mutation_Response>;
  /** update data of the table: "rtcs_db.rmatch_mlhudConsolidated" */
  update_rtcs_db_rmatch_mlhudConsolidated?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Mutation_Response>;
  /** update data of the table: "rtcs_db.streetmap_OsmMukonoMunicipality" */
  update_rtcs_db_streetmap_OsmMukonoMunicipality?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Mutation_Response>;
  /** update data of the table: "rtcs_db.streetmap_OsmMukonoNakifuma" */
  update_rtcs_db_streetmap_OsmMukonoNakifuma?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Mutation_Response>;
  /** update data of the table: "rtcs_db.streetmap_OsmWakisoEntebbe" */
  update_rtcs_db_streetmap_OsmWakisoEntebbe?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Mutation_Response>;
  /** update data of the table: "rtcs_db.streetmap_OsmWakisoKira" */
  update_rtcs_db_streetmap_OsmWakisoKira?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Mutation_Response>;
  /** update data of the table: "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
  update_rtcs_db_streetmap_OsmWakisoMakindyeSsabagabo?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Mutation_Response>;
  /** update data of the table: "rtcs_db.streetmap_OsmWakisoNansana" */
  update_rtcs_db_streetmap_OsmWakisoNansana?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Mutation_Response>;
  /** update data of the table: "rtcs_db.ucc_UCC" */
  update_rtcs_db_ucc_UCC?: Maybe<Rtcs_Db_Ucc_Ucc_Mutation_Response>;
  /** update data of the table: "rtcs_db.umeme_SubscriberAndProperty" */
  update_rtcs_db_umeme_SubscriberAndProperty?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Mutation_Response>;
  /** update data of the table: "rtcs_db.umeme_Transactions" */
  update_rtcs_db_umeme_Transactions?: Maybe<Rtcs_Db_Umeme_Transactions_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_BalanceSheetInformation" */
  update_rtcs_db_ura_BalanceSheetInformation?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_CombinedOfficersAndOwners" */
  update_rtcs_db_ura_CombinedOfficersAndOwners?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_EmploymentIncomeDetail" */
  update_rtcs_db_ura_EmploymentIncomeDetail?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
  update_rtcs_db_ura_GazetteCommercialRatesPerSquareFootage?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
  update_rtcs_db_ura_GazetteResidentialRatesPerBedroom?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_HoldingOfficers" */
  update_rtcs_db_ura_HoldingOfficers?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_HoldingOwner" */
  update_rtcs_db_ura_HoldingOwner?: Maybe<Rtcs_Db_Ura_HoldingOwner_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_IncomeTaxHolding" */
  update_rtcs_db_ura_IncomeTaxHolding?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_IncomeTaxSummaryIndividual" */
  update_rtcs_db_ura_IncomeTaxSummaryIndividual?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
  update_rtcs_db_ura_IncomeTaxSummaryNonIndividual?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_IndividualMortgage" */
  update_rtcs_db_ura_IndividualMortgage?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_IndividualsAssociatedBusiness" */
  update_rtcs_db_ura_IndividualsAssociatedBusiness?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_LandlordIncomeDetail" */
  update_rtcs_db_ura_LandlordIncomeDetail?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_LandlordIncomeSummary" */
  update_rtcs_db_ura_LandlordIncomeSummary?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
  update_rtcs_db_ura_NonIndividualsAssociatedBusiness?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_RegAssociates" */
  update_rtcs_db_ura_RegAssociates?: Maybe<Rtcs_Db_Ura_RegAssociates_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_RegIndividual" */
  update_rtcs_db_ura_RegIndividual?: Maybe<Rtcs_Db_Ura_RegIndividual_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_RegIndividual_sid" */
  update_rtcs_db_ura_RegIndividual_sid?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_RegNonIndividual" */
  update_rtcs_db_ura_RegNonIndividual?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_RegNonIndividual_sid" */
  update_rtcs_db_ura_RegNonIndividual_sid?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_RentExpenseDetails" */
  update_rtcs_db_ura_RentExpenseDetails?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_RentalLandLordIncome" */
  update_rtcs_db_ura_RentalLandLordIncome?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_RentalTenantPayments" */
  update_rtcs_db_ura_RentalTenantPayments?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_RipoIndRentalIncSummary" */
  update_rtcs_db_ura_RipoIndRentalIncSummary?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_TenantIncomeDetail" */
  update_rtcs_db_ura_TenantIncomeDetail?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Mutation_Response>;
  /** update data of the table: "rtcs_db.ura_Town" */
  update_rtcs_db_ura_Town?: Maybe<Rtcs_Db_Ura_Town_Mutation_Response>;
  /** update data of the table: "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
  update_rtcs_db_ursb_CompaniesAndBusinessNamesURSB?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Mutation_Response>;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_EntityFilterArgs = {
  where: Rtcs_Db_EntityFilter_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_FilterArgs = {
  where: Rtcs_Db_Filter_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_GazzetteRatesArgs = {
  where: Rtcs_Db_GazzetteRates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_EntityFilterArgs = {
  where: Rtcs_Db_Ph_EntityFilter_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_FilterArgs = {
  where: Rtcs_Db_Ph_Filter_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_GazzetteRatesArgs = {
  where: Rtcs_Db_Ph_GazzetteRates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRiskArgs =
  {
    where: Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rpt_IndividualCompositeArgs = {
  where: Rtcs_Db_Ph_Rpt_IndividualComposite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rpt_IndividualRegistrationArgs = {
  where: Rtcs_Db_Ph_Rpt_IndividualRegistration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rpt_IndividualTaxSummaryDataArgs = {
  where: Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rpt_KccaAffiliatesArgs = {
  where: Rtcs_Db_Ph_Rpt_KccaAffiliates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationDataArgs =
  {
    where: Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationArgs = {
  where: Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationDataArgs =
  {
    where: Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheetArgs = {
  where: Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRiskArgs =
  {
    where: Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rpt_NonIndividualCompositeArgs = {
  where: Rtcs_Db_Ph_Rpt_NonIndividualComposite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryDataArgs =
  {
    where: Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rpt_PropertiesCompositeArgs = {
  where: Rtcs_Db_Ph_Rpt_PropertiesComposite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rpt_PropertyValuationArgs = {
  where: Rtcs_Db_Ph_Rpt_PropertyValuation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rpt_RentalTenantPaymentsArgs = {
  where: Rtcs_Db_Ph_Rpt_RentalTenantPayments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Gazette_CommercialRatesArgs = {
  where: Rtcs_Db_Ph_Gazette_CommercialRates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Gazette_ResidentialRatesArgs = {
  where: Rtcs_Db_Ph_Gazette_ResidentialRates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_AssociateTypeArgs = {
  where: Rtcs_Db_Ph_Kcca_AssociateType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_CommercialPropertiesArgs = {
  where: Rtcs_Db_Ph_Kcca_CommercialProperties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_CountyArgs = {
  where: Rtcs_Db_Ph_Kcca_County_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_CustomerArgs = {
  where: Rtcs_Db_Ph_Kcca_Customer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_CustomerApplicantTypeArgs = {
  where: Rtcs_Db_Ph_Kcca_CustomerApplicantType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_CustomerBusinessAssociateArgs =
  {
    where: Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_CustomerBusinessTypeArgs = {
  where: Rtcs_Db_Ph_Kcca_CustomerBusinessType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactiveArgs =
  {
    where: Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_CustomerContactPersonArgs = {
  where: Rtcs_Db_Ph_Kcca_CustomerContactPerson_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_CustomerDocumentItemArgs = {
  where: Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_CustomerGuardianArgs = {
  where: Rtcs_Db_Ph_Kcca_CustomerGuardian_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_CustomerReferenceArgs = {
  where: Rtcs_Db_Ph_Kcca_CustomerReference_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_CustomerRegistrationReasonArgs =
  {
    where: Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_CustomerRevenueAgentArgs = {
  where: Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_CustomerTypeArgs = {
  where: Rtcs_Db_Ph_Kcca_CustomerType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_Customer_SidArgs = {
  where: Rtcs_Db_Ph_Kcca_Customer_Sid_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_DistrictArgs = {
  where: Rtcs_Db_Ph_Kcca_District_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_DivisionArgs = {
  where: Rtcs_Db_Ph_Kcca_Division_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_ImgsDataArgs = {
  where: Rtcs_Db_Ph_Kcca_ImgsData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_ParishArgs = {
  where: Rtcs_Db_Ph_Kcca_Parish_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_PaymentDetailsArgs = {
  where: Rtcs_Db_Ph_Kcca_PaymentDetails_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_PaymentsArgs = {
  where: Rtcs_Db_Ph_Kcca_Payments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_PropertyArgs = {
  where: Rtcs_Db_Ph_Kcca_Property_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_PropertyFloorsArgs = {
  where: Rtcs_Db_Ph_Kcca_PropertyFloors_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_PropertyNumberBedroomsArgs = {
  where: Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_PropertyRentedStatusArgs = {
  where: Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_PropertySecurityArgs = {
  where: Rtcs_Db_Ph_Kcca_PropertySecurity_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_PropertySubTypeArgs = {
  where: Rtcs_Db_Ph_Kcca_PropertySubType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_PropertyTypeArgs = {
  where: Rtcs_Db_Ph_Kcca_PropertyType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_RawProperties1Args = {
  where: Rtcs_Db_Ph_Kcca_RawProperties1_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_RawProperties2Args = {
  where: Rtcs_Db_Ph_Kcca_RawProperties2_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_ResidentialPropertiesArgs = {
  where: Rtcs_Db_Ph_Kcca_ResidentialProperties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_RevenueTypeArgs = {
  where: Rtcs_Db_Ph_Kcca_RevenueType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_SubCountyArgs = {
  where: Rtcs_Db_Ph_Kcca_SubCounty_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_SubpropertyTypeConversionArgs =
  {
    where: Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_TradingLicenseArgs = {
  where: Rtcs_Db_Ph_Kcca_TradingLicense_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Kcca_VillageArgs = {
  where: Rtcs_Db_Ph_Kcca_Village_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Master_MasterIndivArgs = {
  where: Rtcs_Db_Ph_Master_MasterIndiv_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Mlhud_KccaArgs = {
  where: Rtcs_Db_Ph_Mlhud_Kcca_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Mlhud_MukonoArgs = {
  where: Rtcs_Db_Ph_Mlhud_Mukono_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Mlhud_PropertiesArgs = {
  where: Rtcs_Db_Ph_Mlhud_Properties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Mlhud_WakisoBusiroArgs = {
  where: Rtcs_Db_Ph_Mlhud_WakisoBusiro_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Mlhud_WakisoKyadondoArgs = {
  where: Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Nira_NiraArgs = {
  where: Rtcs_Db_Ph_Nira_Nira_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Nira_RawDataArgs = {
  where: Rtcs_Db_Ph_Nira_RawData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Nwsc_CustomersArgs = {
  where: Rtcs_Db_Ph_Nwsc_Customers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Nwsc_NwCustomersArgs = {
  where: Rtcs_Db_Ph_Nwsc_NwCustomers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Nwsc_Rpt_TransactionsArgs = {
  where: Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Nwsc_TransactionsArgs = {
  where: Rtcs_Db_Ph_Nwsc_Transactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Nwsc_UraTransactionsArgs = {
  where: Rtcs_Db_Ph_Nwsc_UraTransactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rcapture_RptUnittypesArgs = {
  where: Rtcs_Db_Ph_Rcapture_RptUnittypes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rcapture_RpTlessorsArgs = {
  where: Rtcs_Db_Ph_Rcapture_RpTlessors_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rcapture_RpTmetaArgs = {
  where: Rtcs_Db_Ph_Rcapture_RpTmeta_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rcapture_RpTpropertiesArgs = {
  where: Rtcs_Db_Ph_Rcapture_RpTproperties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rcapture_RpTtenantsArgs = {
  where: Rtcs_Db_Ph_Rcapture_RpTtenants_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_IndividualArgs = {
  where: Rtcs_Db_Ph_Rmatch_Individual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReportArgs =
  {
    where: Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReportArgs =
  {
    where: Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_IndividualRiskScoreArgs = {
  where: Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_IndividualsNetworkArgs = {
  where: Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_IndividualsNetworkTestingArgs =
  {
    where: Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwnersArgs =
  {
    where: Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwnersArgs =
  {
    where: Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_MatchIndividualsPropertiesArgs =
  {
    where: Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_MatchIndividualsPropsArgs = {
  where: Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_NoReturnsArgs = {
  where: Rtcs_Db_Ph_Rmatch_NoReturns_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_NonIndividualArgs = {
  where: Rtcs_Db_Ph_Rmatch_NonIndividual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReportArgs =
  {
    where: Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReportArgs =
  {
    where: Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_NonIndividualRiskScoreArgs = {
  where: Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_NonIndividualsArgs = {
  where: Rtcs_Db_Ph_Rmatch_NonIndividuals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_NonIndividualsNetworkArgs = {
  where: Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_NonindividualOwnersArgs = {
  where: Rtcs_Db_Ph_Rmatch_NonindividualOwners_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_NonindividualsArgs = {
  where: Rtcs_Db_Ph_Rmatch_Nonindividuals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_PropertiesCompositeArgs = {
  where: Rtcs_Db_Ph_Rmatch_PropertiesComposite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_PropertiesNetworkArgs = {
  where: Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_PropertyNetworkArgs = {
  where: Rtcs_Db_Ph_Rmatch_PropertyNetwork_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_RMatchDetailed904Args = {
  where: Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_RMatchDetailed916Args = {
  where: Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancyArgs =
  {
    where: Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_RMatchDetailed937Args = {
  where: Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_RMatchKccaPropertiesArgs = {
  where: Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_RMatchKccaPropertyArgs = {
  where: Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903Args = {
  where: Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_RMatchProperties1115Args = {
  where: Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_RMatchSummary904Args = {
  where: Rtcs_Db_Ph_Rmatch_RMatchSummary904_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_RMatchSummary916Args = {
  where: Rtcs_Db_Ph_Rmatch_RMatchSummary916_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancyArgs =
  {
    where: Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_RMatchSummary937Args = {
  where: Rtcs_Db_Ph_Rmatch_RMatchSummary937_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_TinsUraArgs = {
  where: Rtcs_Db_Ph_Rmatch_TinsUra_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_UndeclaredArgs = {
  where: Rtcs_Db_Ph_Rmatch_Undeclared_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_UnderreportedArgs = {
  where: Rtcs_Db_Ph_Rmatch_Underreported_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_UnregisteredArgs = {
  where: Rtcs_Db_Ph_Rmatch_Unregistered_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Rmatch_MlhudConsolidatedArgs = {
  where: Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipalityArgs =
  {
    where: Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Streetmap_OsmMukonoNakifumaArgs = {
  where: Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbeArgs = {
  where: Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Streetmap_OsmWakisoKiraArgs = {
  where: Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagaboArgs =
  {
    where: Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Streetmap_OsmWakisoNansanaArgs = {
  where: Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ucc_UccArgs = {
  where: Rtcs_Db_Ph_Ucc_Ucc_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Umeme_SubscriberAndPropertyArgs = {
  where: Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Umeme_TransactionsArgs = {
  where: Rtcs_Db_Ph_Umeme_Transactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_BalanceSheetInformationArgs = {
  where: Rtcs_Db_Ph_Ura_BalanceSheetInformation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_CombinedOfficersAndOwnersArgs = {
  where: Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_EmploymentIncomeDetailArgs = {
  where: Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootageArgs =
  {
    where: Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroomArgs =
  {
    where: Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_HoldingOfficersArgs = {
  where: Rtcs_Db_Ph_Ura_HoldingOfficers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_HoldingOwnerArgs = {
  where: Rtcs_Db_Ph_Ura_HoldingOwner_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_IncomeTaxHoldingArgs = {
  where: Rtcs_Db_Ph_Ura_IncomeTaxHolding_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividualArgs =
  {
    where: Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividualArgs =
  {
    where: Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_IndividualMortgageArgs = {
  where: Rtcs_Db_Ph_Ura_IndividualMortgage_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_IndividualsAssociatedBusinessArgs =
  {
    where: Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_LandlordIncomeDetailArgs = {
  where: Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_LandlordIncomeSummaryArgs = {
  where: Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusinessArgs =
  {
    where: Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_RegAssociatesArgs = {
  where: Rtcs_Db_Ph_Ura_RegAssociates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_RegIndividualArgs = {
  where: Rtcs_Db_Ph_Ura_RegIndividual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_RegIndividual_SidArgs = {
  where: Rtcs_Db_Ph_Ura_RegIndividual_Sid_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_RegNonIndividualArgs = {
  where: Rtcs_Db_Ph_Ura_RegNonIndividual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_RegNonIndividual_SidArgs = {
  where: Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_RentExpenseDetailsArgs = {
  where: Rtcs_Db_Ph_Ura_RentExpenseDetails_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_RentalLandLordIncomeArgs = {
  where: Rtcs_Db_Ph_Ura_RentalLandLordIncome_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_RentalTenantPaymentsArgs = {
  where: Rtcs_Db_Ph_Ura_RentalTenantPayments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_RipoIndRentalIncSummaryArgs = {
  where: Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_TenantIncomeDetailArgs = {
  where: Rtcs_Db_Ph_Ura_TenantIncomeDetail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ura_TownArgs = {
  where: Rtcs_Db_Ph_Ura_Town_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsbArgs =
  {
    where: Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRiskArgs =
  {
    where: Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rpt_IndividualCompositeArgs = {
  where: Rtcs_Db_Rpt_IndividualComposite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rpt_IndividualRegistrationArgs = {
  where: Rtcs_Db_Rpt_IndividualRegistration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rpt_IndividualTaxSummaryDataArgs = {
  where: Rtcs_Db_Rpt_IndividualTaxSummaryData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rpt_KccaAffiliatesArgs = {
  where: Rtcs_Db_Rpt_KccaAffiliates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rpt_KccaCustomerRegistrationDataArgs = {
  where: Rtcs_Db_Rpt_KccaCustomerRegistrationData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rpt_KccaPropertyRegistrationArgs = {
  where: Rtcs_Db_Rpt_KccaPropertyRegistration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rpt_KccaPropertyRegistrationDataArgs = {
  where: Rtcs_Db_Rpt_KccaPropertyRegistrationData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rpt_NonIndividualBalanceSheetArgs = {
  where: Rtcs_Db_Rpt_NonIndividualBalanceSheet_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRiskArgs =
  {
    where: Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rpt_NonIndividualCompositeArgs = {
  where: Rtcs_Db_Rpt_NonIndividualComposite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rpt_NonIndividualTaxSummaryDataArgs = {
  where: Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rpt_PropertiesCompositeArgs = {
  where: Rtcs_Db_Rpt_PropertiesComposite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rpt_PropertyValuationArgs = {
  where: Rtcs_Db_Rpt_PropertyValuation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rpt_RentalTenantPaymentsArgs = {
  where: Rtcs_Db_Rpt_RentalTenantPayments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Aggregate_ResultArgs = {
  where: Rtcs_Db_Aggregate_Result_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Aggregate_Result_TxtArgs = {
  where: Rtcs_Db_Aggregate_Result_Txt_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Gazette_CommercialRatesArgs = {
  where: Rtcs_Db_Gazette_CommercialRates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Gazette_ResidentialRatesArgs = {
  where: Rtcs_Db_Gazette_ResidentialRates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_AssociateTypeArgs = {
  where: Rtcs_Db_Kcca_AssociateType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_CommercialPropertiesArgs = {
  where: Rtcs_Db_Kcca_CommercialProperties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_CountyArgs = {
  where: Rtcs_Db_Kcca_County_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_CustomerArgs = {
  where: Rtcs_Db_Kcca_Customer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_CustomerApplicantTypeArgs = {
  where: Rtcs_Db_Kcca_CustomerApplicantType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_CustomerBusinessAssociateArgs = {
  where: Rtcs_Db_Kcca_CustomerBusinessAssociate_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_CustomerBusinessTypeArgs = {
  where: Rtcs_Db_Kcca_CustomerBusinessType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_CustomerCoinDeactivateReactiveArgs =
  {
    where: Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_CustomerContactPersonArgs = {
  where: Rtcs_Db_Kcca_CustomerContactPerson_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_CustomerDocumentItemArgs = {
  where: Rtcs_Db_Kcca_CustomerDocumentItem_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_CustomerGuardianArgs = {
  where: Rtcs_Db_Kcca_CustomerGuardian_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_CustomerReferenceArgs = {
  where: Rtcs_Db_Kcca_CustomerReference_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_CustomerRegistrationReasonArgs = {
  where: Rtcs_Db_Kcca_CustomerRegistrationReason_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_CustomerRevenueAgentArgs = {
  where: Rtcs_Db_Kcca_CustomerRevenueAgent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_CustomerTypeArgs = {
  where: Rtcs_Db_Kcca_CustomerType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_Customer_SidArgs = {
  where: Rtcs_Db_Kcca_Customer_Sid_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_DistrictArgs = {
  where: Rtcs_Db_Kcca_District_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_DivisionArgs = {
  where: Rtcs_Db_Kcca_Division_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_ImgsDataArgs = {
  where: Rtcs_Db_Kcca_ImgsData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_ParishArgs = {
  where: Rtcs_Db_Kcca_Parish_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_PaymentDetailsArgs = {
  where: Rtcs_Db_Kcca_PaymentDetails_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_PaymentsArgs = {
  where: Rtcs_Db_Kcca_Payments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_PropertyArgs = {
  where: Rtcs_Db_Kcca_Property_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_PropertyFloorsArgs = {
  where: Rtcs_Db_Kcca_PropertyFloors_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_PropertyNumberBedroomsArgs = {
  where: Rtcs_Db_Kcca_PropertyNumberBedrooms_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_PropertyRentedStatusArgs = {
  where: Rtcs_Db_Kcca_PropertyRentedStatus_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_PropertySecurityArgs = {
  where: Rtcs_Db_Kcca_PropertySecurity_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_PropertySubTypeArgs = {
  where: Rtcs_Db_Kcca_PropertySubType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_PropertyTypeArgs = {
  where: Rtcs_Db_Kcca_PropertyType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_RawProperties1Args = {
  where: Rtcs_Db_Kcca_RawProperties1_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_RawProperties2Args = {
  where: Rtcs_Db_Kcca_RawProperties2_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_ResidentialPropertiesArgs = {
  where: Rtcs_Db_Kcca_ResidentialProperties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_RevenueTypeArgs = {
  where: Rtcs_Db_Kcca_RevenueType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_SubCountyArgs = {
  where: Rtcs_Db_Kcca_SubCounty_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_SubpropertyTypeConversionArgs = {
  where: Rtcs_Db_Kcca_SubpropertyTypeConversion_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_TradingLicenseArgs = {
  where: Rtcs_Db_Kcca_TradingLicense_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Kcca_VillageArgs = {
  where: Rtcs_Db_Kcca_Village_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Master_MasterIndivArgs = {
  where: Rtcs_Db_Master_MasterIndiv_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Mlhud_KccaArgs = {
  where: Rtcs_Db_Mlhud_Kcca_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Mlhud_MukonoArgs = {
  where: Rtcs_Db_Mlhud_Mukono_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Mlhud_PropertiesArgs = {
  where: Rtcs_Db_Mlhud_Properties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Mlhud_WakisoBusiroArgs = {
  where: Rtcs_Db_Mlhud_WakisoBusiro_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Mlhud_WakisoKyadondoArgs = {
  where: Rtcs_Db_Mlhud_WakisoKyadondo_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Nira_NiraArgs = {
  where: Rtcs_Db_Nira_Nira_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Nira_RawDataArgs = {
  where: Rtcs_Db_Nira_RawData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Nwsc_CustomersArgs = {
  where: Rtcs_Db_Nwsc_Customers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Nwsc_NwCustomersArgs = {
  where: Rtcs_Db_Nwsc_NwCustomers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Nwsc_Rpt_TransactionsArgs = {
  where: Rtcs_Db_Nwsc_Rpt_Transactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Nwsc_TransactionsArgs = {
  where: Rtcs_Db_Nwsc_Transactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Nwsc_UraTransactionsArgs = {
  where: Rtcs_Db_Nwsc_UraTransactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rcapture_RptUnittypesArgs = {
  where: Rtcs_Db_Rcapture_RptUnittypes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rcapture_RpTlessorsArgs = {
  where: Rtcs_Db_Rcapture_RpTlessors_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rcapture_RpTmetaArgs = {
  where: Rtcs_Db_Rcapture_RpTmeta_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rcapture_RpTpropertiesArgs = {
  where: Rtcs_Db_Rcapture_RpTproperties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rcapture_RpTtenantsArgs = {
  where: Rtcs_Db_Rcapture_RpTtenants_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_IndividualArgs = {
  where: Rtcs_Db_Rmatch_Individual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_IndividualComplianceDetailReportArgs =
  {
    where: Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_IndividualComplianceSummaryReportArgs =
  {
    where: Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_IndividualRiskScoreArgs = {
  where: Rtcs_Db_Rmatch_IndividualRiskScore_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_IndividualsNetworkArgs = {
  where: Rtcs_Db_Rmatch_IndividualsNetwork_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_IndividualsNetworkTestingArgs = {
  where: Rtcs_Db_Rmatch_IndividualsNetworkTesting_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_KccaToKccaIndividualOwnersArgs =
  {
    where: Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_KccaToKccaNonindividualOwnersArgs =
  {
    where: Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_MatchIndividualsPropertiesArgs =
  {
    where: Rtcs_Db_Rmatch_MatchIndividualsProperties_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_MatchIndividualsPropsArgs = {
  where: Rtcs_Db_Rmatch_MatchIndividualsProps_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_NoReturnsArgs = {
  where: Rtcs_Db_Rmatch_NoReturns_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_NonIndividualArgs = {
  where: Rtcs_Db_Rmatch_NonIndividual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_NonIndividualComplianceDetailReportArgs =
  {
    where: Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReportArgs =
  {
    where: Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_NonIndividualRiskScoreArgs = {
  where: Rtcs_Db_Rmatch_NonIndividualRiskScore_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_NonIndividualsArgs = {
  where: Rtcs_Db_Rmatch_NonIndividuals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_NonIndividualsNetworkArgs = {
  where: Rtcs_Db_Rmatch_NonIndividualsNetwork_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_NonindividualOwnersArgs = {
  where: Rtcs_Db_Rmatch_NonindividualOwners_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_NonindividualsArgs = {
  where: Rtcs_Db_Rmatch_Nonindividuals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_PropertiesCompositeArgs = {
  where: Rtcs_Db_Rmatch_PropertiesComposite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_PropertiesNetworkArgs = {
  where: Rtcs_Db_Rmatch_PropertiesNetwork_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_PropertyNetworkArgs = {
  where: Rtcs_Db_Rmatch_PropertyNetwork_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_RMatchDetailed904Args = {
  where: Rtcs_Db_Rmatch_RMatchDetailed904_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_RMatchDetailed916Args = {
  where: Rtcs_Db_Rmatch_RMatchDetailed916_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancyArgs =
  {
    where: Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_RMatchDetailed937Args = {
  where: Rtcs_Db_Rmatch_RMatchDetailed937_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_RMatchKccaPropertiesArgs = {
  where: Rtcs_Db_Rmatch_RMatchKccaProperties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_RMatchKccaPropertyArgs = {
  where: Rtcs_Db_Rmatch_RMatchKccaProperty_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_RMatchNonIndividual903Args = {
  where: Rtcs_Db_Rmatch_RMatchNonIndividual903_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_RMatchProperties1115Args = {
  where: Rtcs_Db_Rmatch_RMatchProperties1115_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_RMatchSummary904Args = {
  where: Rtcs_Db_Rmatch_RMatchSummary904_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_RMatchSummary916Args = {
  where: Rtcs_Db_Rmatch_RMatchSummary916_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancyArgs =
  {
    where: Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_RMatchSummary937Args = {
  where: Rtcs_Db_Rmatch_RMatchSummary937_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_TinsUraArgs = {
  where: Rtcs_Db_Rmatch_TinsUra_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_UndeclaredArgs = {
  where: Rtcs_Db_Rmatch_Undeclared_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_UnderreportedArgs = {
  where: Rtcs_Db_Rmatch_Underreported_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_UnregisteredArgs = {
  where: Rtcs_Db_Rmatch_Unregistered_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Rmatch_MlhudConsolidatedArgs = {
  where: Rtcs_Db_Rmatch_MlhudConsolidated_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Streetmap_OsmMukonoMunicipalityArgs = {
  where: Rtcs_Db_Streetmap_OsmMukonoMunicipality_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Streetmap_OsmMukonoNakifumaArgs = {
  where: Rtcs_Db_Streetmap_OsmMukonoNakifuma_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Streetmap_OsmWakisoEntebbeArgs = {
  where: Rtcs_Db_Streetmap_OsmWakisoEntebbe_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Streetmap_OsmWakisoKiraArgs = {
  where: Rtcs_Db_Streetmap_OsmWakisoKira_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagaboArgs =
  {
    where: Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Streetmap_OsmWakisoNansanaArgs = {
  where: Rtcs_Db_Streetmap_OsmWakisoNansana_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ucc_UccArgs = {
  where: Rtcs_Db_Ucc_Ucc_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Umeme_SubscriberAndPropertyArgs = {
  where: Rtcs_Db_Umeme_SubscriberAndProperty_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Umeme_TransactionsArgs = {
  where: Rtcs_Db_Umeme_Transactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_BalanceSheetInformationArgs = {
  where: Rtcs_Db_Ura_BalanceSheetInformation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_CombinedOfficersAndOwnersArgs = {
  where: Rtcs_Db_Ura_CombinedOfficersAndOwners_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_EmploymentIncomeDetailArgs = {
  where: Rtcs_Db_Ura_EmploymentIncomeDetail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootageArgs =
  {
    where: Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_GazetteResidentialRatesPerBedroomArgs =
  {
    where: Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_HoldingOfficersArgs = {
  where: Rtcs_Db_Ura_HoldingOfficers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_HoldingOwnerArgs = {
  where: Rtcs_Db_Ura_HoldingOwner_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_IncomeTaxHoldingArgs = {
  where: Rtcs_Db_Ura_IncomeTaxHolding_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_IncomeTaxSummaryIndividualArgs = {
  where: Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_IncomeTaxSummaryNonIndividualArgs =
  {
    where: Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_IndividualMortgageArgs = {
  where: Rtcs_Db_Ura_IndividualMortgage_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_IndividualsAssociatedBusinessArgs =
  {
    where: Rtcs_Db_Ura_IndividualsAssociatedBusiness_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_LandlordIncomeDetailArgs = {
  where: Rtcs_Db_Ura_LandlordIncomeDetail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_LandlordIncomeSummaryArgs = {
  where: Rtcs_Db_Ura_LandlordIncomeSummary_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_NonIndividualsAssociatedBusinessArgs =
  {
    where: Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_RegAssociatesArgs = {
  where: Rtcs_Db_Ura_RegAssociates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_RegIndividualArgs = {
  where: Rtcs_Db_Ura_RegIndividual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_RegIndividual_SidArgs = {
  where: Rtcs_Db_Ura_RegIndividual_Sid_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_RegNonIndividualArgs = {
  where: Rtcs_Db_Ura_RegNonIndividual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_RegNonIndividual_SidArgs = {
  where: Rtcs_Db_Ura_RegNonIndividual_Sid_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_RentExpenseDetailsArgs = {
  where: Rtcs_Db_Ura_RentExpenseDetails_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_RentalLandLordIncomeArgs = {
  where: Rtcs_Db_Ura_RentalLandLordIncome_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_RentalTenantPaymentsArgs = {
  where: Rtcs_Db_Ura_RentalTenantPayments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_RipoIndRentalIncSummaryArgs = {
  where: Rtcs_Db_Ura_RipoIndRentalIncSummary_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_TenantIncomeDetailArgs = {
  where: Rtcs_Db_Ura_TenantIncomeDetail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ura_TownArgs = {
  where: Rtcs_Db_Ura_Town_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsbArgs =
  {
    where: Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_EntityFilterArgs = {
  objects: Array<Rtcs_Db_EntityFilter_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_EntityFilter_OneArgs = {
  object: Rtcs_Db_EntityFilter_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_FilterArgs = {
  objects: Array<Rtcs_Db_Filter_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Filter_OneArgs = {
  object: Rtcs_Db_Filter_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_GazzetteRatesArgs = {
  objects: Array<Rtcs_Db_GazzetteRates_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_GazzetteRates_OneArgs = {
  object: Rtcs_Db_GazzetteRates_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_EntityFilterArgs = {
  objects: Array<Rtcs_Db_Ph_EntityFilter_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_EntityFilter_OneArgs = {
  object: Rtcs_Db_Ph_EntityFilter_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_FilterArgs = {
  objects: Array<Rtcs_Db_Ph_Filter_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Filter_OneArgs = {
  object: Rtcs_Db_Ph_Filter_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_GazzetteRatesArgs = {
  objects: Array<Rtcs_Db_Ph_GazzetteRates_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_GazzetteRates_OneArgs = {
  object: Rtcs_Db_Ph_GazzetteRates_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRiskArgs =
  {
    objects: Array<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_OneArgs =
  {
    object: Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_IndividualCompositeArgs = {
  objects: Array<Rtcs_Db_Ph_Rpt_IndividualComposite_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_IndividualComposite_OneArgs = {
  object: Rtcs_Db_Ph_Rpt_IndividualComposite_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_IndividualRegistrationArgs = {
  objects: Array<Rtcs_Db_Ph_Rpt_IndividualRegistration_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_IndividualRegistration_OneArgs =
  {
    object: Rtcs_Db_Ph_Rpt_IndividualRegistration_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_IndividualTaxSummaryDataArgs = {
  objects: Array<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_OneArgs =
  {
    object: Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_KccaAffiliatesArgs = {
  objects: Array<Rtcs_Db_Ph_Rpt_KccaAffiliates_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_KccaAffiliates_OneArgs = {
  object: Rtcs_Db_Ph_Rpt_KccaAffiliates_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationDataArgs =
  {
    objects: Array<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_OneArgs =
  {
    object: Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationArgs = {
  objects: Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationDataArgs =
  {
    objects: Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_OneArgs =
  {
    object: Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_OneArgs =
  {
    object: Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheetArgs = {
  objects: Array<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_OneArgs =
  {
    object: Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRiskArgs =
  {
    objects: Array<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_OneArgs =
  {
    object: Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_NonIndividualCompositeArgs = {
  objects: Array<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_NonIndividualComposite_OneArgs =
  {
    object: Rtcs_Db_Ph_Rpt_NonIndividualComposite_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryDataArgs =
  {
    objects: Array<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_OneArgs =
  {
    object: Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_PropertiesCompositeArgs = {
  objects: Array<Rtcs_Db_Ph_Rpt_PropertiesComposite_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_PropertiesComposite_OneArgs = {
  object: Rtcs_Db_Ph_Rpt_PropertiesComposite_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_PropertyValuationArgs = {
  objects: Array<Rtcs_Db_Ph_Rpt_PropertyValuation_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_PropertyValuation_OneArgs = {
  object: Rtcs_Db_Ph_Rpt_PropertyValuation_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_RentalTenantPaymentsArgs = {
  objects: Array<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rpt_RentalTenantPayments_OneArgs = {
  object: Rtcs_Db_Ph_Rpt_RentalTenantPayments_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Gazette_CommercialRatesArgs = {
  objects: Array<Rtcs_Db_Ph_Gazette_CommercialRates_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Gazette_CommercialRates_OneArgs = {
  object: Rtcs_Db_Ph_Gazette_CommercialRates_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Gazette_ResidentialRatesArgs = {
  objects: Array<Rtcs_Db_Ph_Gazette_ResidentialRates_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Gazette_ResidentialRates_OneArgs = {
  object: Rtcs_Db_Ph_Gazette_ResidentialRates_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_AssociateTypeArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_AssociateType_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_AssociateType_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_AssociateType_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CommercialPropertiesArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_CommercialProperties_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CommercialProperties_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_CommercialProperties_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CountyArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_County_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_County_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_County_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CustomerArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_Customer_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CustomerApplicantTypeArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CustomerApplicantType_OneArgs =
  {
    object: Rtcs_Db_Ph_Kcca_CustomerApplicantType_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CustomerBusinessAssociateArgs =
  {
    objects: Array<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_OneArgs =
  {
    object: Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CustomerBusinessTypeArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CustomerBusinessType_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_CustomerBusinessType_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactiveArgs =
  {
    objects: Array<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_OneArgs =
  {
    object: Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CustomerContactPersonArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CustomerContactPerson_OneArgs =
  {
    object: Rtcs_Db_Ph_Kcca_CustomerContactPerson_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CustomerDocumentItemArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CustomerDocumentItem_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CustomerGuardianArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_CustomerGuardian_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CustomerGuardian_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_CustomerGuardian_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CustomerReferenceArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_CustomerReference_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CustomerReference_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_CustomerReference_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CustomerRegistrationReasonArgs =
  {
    objects: Array<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_OneArgs =
  {
    object: Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CustomerRevenueAgentArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CustomerTypeArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_CustomerType_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_CustomerType_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_CustomerType_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_Customer_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_Customer_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_Customer_SidArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_Customer_Sid_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_Customer_Sid_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_Customer_Sid_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_DistrictArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_District_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_District_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_District_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_DivisionArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_Division_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_Division_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_Division_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_ImgsDataArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_ImgsData_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_ImgsData_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_ImgsData_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_ParishArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_Parish_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_Parish_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_Parish_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_PaymentDetailsArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_PaymentDetails_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_PaymentDetails_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_PaymentDetails_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_PaymentsArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_Payments_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_Payments_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_Payments_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_PropertyArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_Property_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_PropertyFloorsArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_PropertyFloors_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_PropertyFloors_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_PropertyFloors_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_PropertyNumberBedroomsArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_OneArgs =
  {
    object: Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_PropertyRentedStatusArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_PropertyRentedStatus_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_PropertySecurityArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_PropertySecurity_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_PropertySecurity_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_PropertySecurity_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_PropertySubTypeArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_PropertySubType_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_PropertySubType_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_PropertySubType_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_PropertyTypeArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_PropertyType_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_PropertyType_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_PropertyType_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_Property_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_Property_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_RawProperties1Args = {
  objects: Array<Rtcs_Db_Ph_Kcca_RawProperties1_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_RawProperties1_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_RawProperties1_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_RawProperties2Args = {
  objects: Array<Rtcs_Db_Ph_Kcca_RawProperties2_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_RawProperties2_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_RawProperties2_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_ResidentialPropertiesArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_ResidentialProperties_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_ResidentialProperties_OneArgs =
  {
    object: Rtcs_Db_Ph_Kcca_ResidentialProperties_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_RevenueTypeArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_RevenueType_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_RevenueType_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_RevenueType_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_SubCountyArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_SubCounty_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_SubCounty_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_SubCounty_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_SubpropertyTypeConversionArgs =
  {
    objects: Array<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_OneArgs =
  {
    object: Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_TradingLicenseArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_TradingLicense_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_TradingLicense_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_TradingLicense_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_VillageArgs = {
  objects: Array<Rtcs_Db_Ph_Kcca_Village_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Kcca_Village_OneArgs = {
  object: Rtcs_Db_Ph_Kcca_Village_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Master_MasterIndivArgs = {
  objects: Array<Rtcs_Db_Ph_Master_MasterIndiv_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Master_MasterIndiv_OneArgs = {
  object: Rtcs_Db_Ph_Master_MasterIndiv_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Mlhud_KccaArgs = {
  objects: Array<Rtcs_Db_Ph_Mlhud_Kcca_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Mlhud_Kcca_OneArgs = {
  object: Rtcs_Db_Ph_Mlhud_Kcca_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Mlhud_MukonoArgs = {
  objects: Array<Rtcs_Db_Ph_Mlhud_Mukono_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Mlhud_Mukono_OneArgs = {
  object: Rtcs_Db_Ph_Mlhud_Mukono_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Mlhud_PropertiesArgs = {
  objects: Array<Rtcs_Db_Ph_Mlhud_Properties_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Mlhud_Properties_OneArgs = {
  object: Rtcs_Db_Ph_Mlhud_Properties_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Mlhud_WakisoBusiroArgs = {
  objects: Array<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Mlhud_WakisoBusiro_OneArgs = {
  object: Rtcs_Db_Ph_Mlhud_WakisoBusiro_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Mlhud_WakisoKyadondoArgs = {
  objects: Array<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Mlhud_WakisoKyadondo_OneArgs = {
  object: Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Nira_NiraArgs = {
  objects: Array<Rtcs_Db_Ph_Nira_Nira_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Nira_Nira_OneArgs = {
  object: Rtcs_Db_Ph_Nira_Nira_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Nira_RawDataArgs = {
  objects: Array<Rtcs_Db_Ph_Nira_RawData_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Nira_RawData_OneArgs = {
  object: Rtcs_Db_Ph_Nira_RawData_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Nwsc_CustomersArgs = {
  objects: Array<Rtcs_Db_Ph_Nwsc_Customers_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Nwsc_Customers_OneArgs = {
  object: Rtcs_Db_Ph_Nwsc_Customers_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Nwsc_NwCustomersArgs = {
  objects: Array<Rtcs_Db_Ph_Nwsc_NwCustomers_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Nwsc_NwCustomers_OneArgs = {
  object: Rtcs_Db_Ph_Nwsc_NwCustomers_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Nwsc_Rpt_TransactionsArgs = {
  objects: Array<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Nwsc_Rpt_Transactions_OneArgs = {
  object: Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Nwsc_TransactionsArgs = {
  objects: Array<Rtcs_Db_Ph_Nwsc_Transactions_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Nwsc_Transactions_OneArgs = {
  object: Rtcs_Db_Ph_Nwsc_Transactions_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Nwsc_UraTransactionsArgs = {
  objects: Array<Rtcs_Db_Ph_Nwsc_UraTransactions_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Nwsc_UraTransactions_OneArgs = {
  object: Rtcs_Db_Ph_Nwsc_UraTransactions_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rcapture_RptUnittypesArgs = {
  objects: Array<Rtcs_Db_Ph_Rcapture_RptUnittypes_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rcapture_RptUnittypes_OneArgs = {
  object: Rtcs_Db_Ph_Rcapture_RptUnittypes_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rcapture_RpTlessorsArgs = {
  objects: Array<Rtcs_Db_Ph_Rcapture_RpTlessors_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rcapture_RpTlessors_OneArgs = {
  object: Rtcs_Db_Ph_Rcapture_RpTlessors_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rcapture_RpTmetaArgs = {
  objects: Array<Rtcs_Db_Ph_Rcapture_RpTmeta_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rcapture_RpTmeta_OneArgs = {
  object: Rtcs_Db_Ph_Rcapture_RpTmeta_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rcapture_RpTpropertiesArgs = {
  objects: Array<Rtcs_Db_Ph_Rcapture_RpTproperties_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rcapture_RpTproperties_OneArgs = {
  object: Rtcs_Db_Ph_Rcapture_RpTproperties_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rcapture_RpTtenantsArgs = {
  objects: Array<Rtcs_Db_Ph_Rcapture_RpTtenants_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rcapture_RpTtenants_OneArgs = {
  object: Rtcs_Db_Ph_Rcapture_RpTtenants_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_IndividualArgs = {
  objects: Array<Rtcs_Db_Ph_Rmatch_Individual_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReportArgs =
  {
    objects: Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_OneArgs =
  {
    object: Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReportArgs =
  {
    objects: Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_OneArgs =
  {
    object: Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_IndividualRiskScoreArgs = {
  objects: Array<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_IndividualRiskScore_OneArgs =
  {
    object: Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_Individual_OneArgs = {
  object: Rtcs_Db_Ph_Rmatch_Individual_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_IndividualsNetworkArgs = {
  objects: Array<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_IndividualsNetworkTestingArgs =
  {
    objects: Array<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_OneArgs =
  {
    object: Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_IndividualsNetwork_OneArgs = {
  object: Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwnersArgs =
  {
    objects: Array<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_OneArgs =
  {
    object: Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwnersArgs =
  {
    objects: Array<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_OneArgs =
  {
    object: Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_MatchIndividualsPropertiesArgs =
  {
    objects: Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_OneArgs =
  {
    object: Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_MatchIndividualsPropsArgs = {
  objects: Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_OneArgs =
  {
    object: Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_NoReturnsArgs = {
  objects: Array<Rtcs_Db_Ph_Rmatch_NoReturns_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_NoReturns_OneArgs = {
  object: Rtcs_Db_Ph_Rmatch_NoReturns_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_NonIndividualArgs = {
  objects: Array<Rtcs_Db_Ph_Rmatch_NonIndividual_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReportArgs =
  {
    objects: Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_OneArgs =
  {
    object: Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReportArgs =
  {
    objects: Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_OneArgs =
  {
    object: Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_NonIndividualRiskScoreArgs = {
  objects: Array<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_OneArgs =
  {
    object: Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_NonIndividual_OneArgs = {
  object: Rtcs_Db_Ph_Rmatch_NonIndividual_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_NonIndividualsArgs = {
  objects: Array<Rtcs_Db_Ph_Rmatch_NonIndividuals_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_NonIndividualsNetworkArgs = {
  objects: Array<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_OneArgs =
  {
    object: Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_NonIndividuals_OneArgs = {
  object: Rtcs_Db_Ph_Rmatch_NonIndividuals_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_NonindividualOwnersArgs = {
  objects: Array<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_NonindividualOwners_OneArgs =
  {
    object: Rtcs_Db_Ph_Rmatch_NonindividualOwners_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_NonindividualsArgs = {
  objects: Array<Rtcs_Db_Ph_Rmatch_Nonindividuals_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_Nonindividuals_OneArgs = {
  object: Rtcs_Db_Ph_Rmatch_Nonindividuals_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_PropertiesCompositeArgs = {
  objects: Array<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_PropertiesComposite_OneArgs =
  {
    object: Rtcs_Db_Ph_Rmatch_PropertiesComposite_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_PropertiesNetworkArgs = {
  objects: Array<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_PropertiesNetwork_OneArgs = {
  object: Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_PropertyNetworkArgs = {
  objects: Array<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_PropertyNetwork_OneArgs = {
  object: Rtcs_Db_Ph_Rmatch_PropertyNetwork_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_RMatchDetailed904Args = {
  objects: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_RMatchDetailed904_OneArgs = {
  object: Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_RMatchDetailed916Args = {
  objects: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancyArgs =
  {
    objects: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_OneArgs =
  {
    object: Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_RMatchDetailed916_OneArgs = {
  object: Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_RMatchDetailed937Args = {
  objects: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_RMatchDetailed937_OneArgs = {
  object: Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_RMatchKccaPropertiesArgs = {
  objects: Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_OneArgs =
  {
    object: Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_RMatchKccaPropertyArgs = {
  objects: Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_OneArgs = {
  object: Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903Args = {
  objects: Array<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_OneArgs =
  {
    object: Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_RMatchProperties1115Args = {
  objects: Array<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_RMatchProperties1115_OneArgs =
  {
    object: Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_RMatchSummary904Args = {
  objects: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_RMatchSummary904_OneArgs = {
  object: Rtcs_Db_Ph_Rmatch_RMatchSummary904_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_RMatchSummary916Args = {
  objects: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancyArgs =
  {
    objects: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_OneArgs =
  {
    object: Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_RMatchSummary916_OneArgs = {
  object: Rtcs_Db_Ph_Rmatch_RMatchSummary916_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_RMatchSummary937Args = {
  objects: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_RMatchSummary937_OneArgs = {
  object: Rtcs_Db_Ph_Rmatch_RMatchSummary937_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_TinsUraArgs = {
  objects: Array<Rtcs_Db_Ph_Rmatch_TinsUra_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_TinsUra_OneArgs = {
  object: Rtcs_Db_Ph_Rmatch_TinsUra_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_UndeclaredArgs = {
  objects: Array<Rtcs_Db_Ph_Rmatch_Undeclared_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_Undeclared_OneArgs = {
  object: Rtcs_Db_Ph_Rmatch_Undeclared_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_UnderreportedArgs = {
  objects: Array<Rtcs_Db_Ph_Rmatch_Underreported_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_Underreported_OneArgs = {
  object: Rtcs_Db_Ph_Rmatch_Underreported_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_UnregisteredArgs = {
  objects: Array<Rtcs_Db_Ph_Rmatch_Unregistered_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_Unregistered_OneArgs = {
  object: Rtcs_Db_Ph_Rmatch_Unregistered_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_MlhudConsolidatedArgs = {
  objects: Array<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Rmatch_MlhudConsolidated_OneArgs = {
  object: Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipalityArgs =
  {
    objects: Array<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_OneArgs =
  {
    object: Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Streetmap_OsmMukonoNakifumaArgs = {
  objects: Array<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_OneArgs =
  {
    object: Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbeArgs = {
  objects: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_OneArgs =
  {
    object: Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Streetmap_OsmWakisoKiraArgs = {
  objects: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Streetmap_OsmWakisoKira_OneArgs = {
  object: Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagaboArgs =
  {
    objects: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_OneArgs =
  {
    object: Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Streetmap_OsmWakisoNansanaArgs = {
  objects: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_OneArgs =
  {
    object: Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ucc_UccArgs = {
  objects: Array<Rtcs_Db_Ph_Ucc_Ucc_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ucc_Ucc_OneArgs = {
  object: Rtcs_Db_Ph_Ucc_Ucc_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Umeme_SubscriberAndPropertyArgs = {
  objects: Array<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Umeme_SubscriberAndProperty_OneArgs =
  {
    object: Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Umeme_TransactionsArgs = {
  objects: Array<Rtcs_Db_Ph_Umeme_Transactions_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Umeme_Transactions_OneArgs = {
  object: Rtcs_Db_Ph_Umeme_Transactions_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_BalanceSheetInformationArgs = {
  objects: Array<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_BalanceSheetInformation_OneArgs =
  {
    object: Rtcs_Db_Ph_Ura_BalanceSheetInformation_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_CombinedOfficersAndOwnersArgs = {
  objects: Array<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_OneArgs =
  {
    object: Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_EmploymentIncomeDetailArgs = {
  objects: Array<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_OneArgs =
  {
    object: Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootageArgs =
  {
    objects: Array<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_OneArgs =
  {
    object: Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroomArgs =
  {
    objects: Array<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_OneArgs =
  {
    object: Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_HoldingOfficersArgs = {
  objects: Array<Rtcs_Db_Ph_Ura_HoldingOfficers_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_HoldingOfficers_OneArgs = {
  object: Rtcs_Db_Ph_Ura_HoldingOfficers_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_HoldingOwnerArgs = {
  objects: Array<Rtcs_Db_Ph_Ura_HoldingOwner_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_HoldingOwner_OneArgs = {
  object: Rtcs_Db_Ph_Ura_HoldingOwner_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_IncomeTaxHoldingArgs = {
  objects: Array<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_IncomeTaxHolding_OneArgs = {
  object: Rtcs_Db_Ph_Ura_IncomeTaxHolding_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividualArgs =
  {
    objects: Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_OneArgs =
  {
    object: Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividualArgs =
  {
    objects: Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_OneArgs =
  {
    object: Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_IndividualMortgageArgs = {
  objects: Array<Rtcs_Db_Ph_Ura_IndividualMortgage_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_IndividualMortgage_OneArgs = {
  object: Rtcs_Db_Ph_Ura_IndividualMortgage_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_IndividualsAssociatedBusinessArgs =
  {
    objects: Array<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_OneArgs =
  {
    object: Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_LandlordIncomeDetailArgs = {
  objects: Array<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_LandlordIncomeDetail_OneArgs = {
  object: Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_LandlordIncomeSummaryArgs = {
  objects: Array<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_LandlordIncomeSummary_OneArgs = {
  object: Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusinessArgs =
  {
    objects: Array<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_OneArgs =
  {
    object: Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_RegAssociatesArgs = {
  objects: Array<Rtcs_Db_Ph_Ura_RegAssociates_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_RegAssociates_OneArgs = {
  object: Rtcs_Db_Ph_Ura_RegAssociates_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_RegIndividualArgs = {
  objects: Array<Rtcs_Db_Ph_Ura_RegIndividual_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_RegIndividual_OneArgs = {
  object: Rtcs_Db_Ph_Ura_RegIndividual_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_RegIndividual_SidArgs = {
  objects: Array<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_RegIndividual_Sid_OneArgs = {
  object: Rtcs_Db_Ph_Ura_RegIndividual_Sid_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_RegNonIndividualArgs = {
  objects: Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_RegNonIndividual_OneArgs = {
  object: Rtcs_Db_Ph_Ura_RegNonIndividual_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_RegNonIndividual_SidArgs = {
  objects: Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_OneArgs = {
  object: Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_RentExpenseDetailsArgs = {
  objects: Array<Rtcs_Db_Ph_Ura_RentExpenseDetails_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_RentExpenseDetails_OneArgs = {
  object: Rtcs_Db_Ph_Ura_RentExpenseDetails_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_RentalLandLordIncomeArgs = {
  objects: Array<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_RentalLandLordIncome_OneArgs = {
  object: Rtcs_Db_Ph_Ura_RentalLandLordIncome_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_RentalTenantPaymentsArgs = {
  objects: Array<Rtcs_Db_Ph_Ura_RentalTenantPayments_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_RentalTenantPayments_OneArgs = {
  object: Rtcs_Db_Ph_Ura_RentalTenantPayments_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_RipoIndRentalIncSummaryArgs = {
  objects: Array<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_OneArgs =
  {
    object: Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_TenantIncomeDetailArgs = {
  objects: Array<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_TenantIncomeDetail_OneArgs = {
  object: Rtcs_Db_Ph_Ura_TenantIncomeDetail_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_TownArgs = {
  objects: Array<Rtcs_Db_Ph_Ura_Town_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ura_Town_OneArgs = {
  object: Rtcs_Db_Ph_Ura_Town_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsbArgs =
  {
    objects: Array<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_OneArgs =
  {
    object: Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRiskArgs =
  {
    objects: Array<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_OneArgs =
  {
    object: Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_IndividualCompositeArgs = {
  objects: Array<Rtcs_Db_Rpt_IndividualComposite_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_IndividualComposite_OneArgs = {
  object: Rtcs_Db_Rpt_IndividualComposite_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_IndividualRegistrationArgs = {
  objects: Array<Rtcs_Db_Rpt_IndividualRegistration_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_IndividualRegistration_OneArgs = {
  object: Rtcs_Db_Rpt_IndividualRegistration_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_IndividualTaxSummaryDataArgs = {
  objects: Array<Rtcs_Db_Rpt_IndividualTaxSummaryData_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_IndividualTaxSummaryData_OneArgs = {
  object: Rtcs_Db_Rpt_IndividualTaxSummaryData_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_KccaAffiliatesArgs = {
  objects: Array<Rtcs_Db_Rpt_KccaAffiliates_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_KccaAffiliates_OneArgs = {
  object: Rtcs_Db_Rpt_KccaAffiliates_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_KccaCustomerRegistrationDataArgs = {
  objects: Array<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_KccaCustomerRegistrationData_OneArgs =
  {
    object: Rtcs_Db_Rpt_KccaCustomerRegistrationData_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_KccaPropertyRegistrationArgs = {
  objects: Array<Rtcs_Db_Rpt_KccaPropertyRegistration_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_KccaPropertyRegistrationDataArgs = {
  objects: Array<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_KccaPropertyRegistrationData_OneArgs =
  {
    object: Rtcs_Db_Rpt_KccaPropertyRegistrationData_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_KccaPropertyRegistration_OneArgs = {
  object: Rtcs_Db_Rpt_KccaPropertyRegistration_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_NonIndividualBalanceSheetArgs = {
  objects: Array<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_NonIndividualBalanceSheet_OneArgs =
  {
    object: Rtcs_Db_Rpt_NonIndividualBalanceSheet_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRiskArgs =
  {
    objects: Array<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_OneArgs =
  {
    object: Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_NonIndividualCompositeArgs = {
  objects: Array<Rtcs_Db_Rpt_NonIndividualComposite_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_NonIndividualComposite_OneArgs = {
  object: Rtcs_Db_Rpt_NonIndividualComposite_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_NonIndividualTaxSummaryDataArgs = {
  objects: Array<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_NonIndividualTaxSummaryData_OneArgs =
  {
    object: Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_PropertiesCompositeArgs = {
  objects: Array<Rtcs_Db_Rpt_PropertiesComposite_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_PropertiesComposite_OneArgs = {
  object: Rtcs_Db_Rpt_PropertiesComposite_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_PropertyValuationArgs = {
  objects: Array<Rtcs_Db_Rpt_PropertyValuation_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_PropertyValuation_OneArgs = {
  object: Rtcs_Db_Rpt_PropertyValuation_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_RentalTenantPaymentsArgs = {
  objects: Array<Rtcs_Db_Rpt_RentalTenantPayments_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rpt_RentalTenantPayments_OneArgs = {
  object: Rtcs_Db_Rpt_RentalTenantPayments_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Aggregate_ResultArgs = {
  objects: Array<Rtcs_Db_Aggregate_Result_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Aggregate_Result_OneArgs = {
  object: Rtcs_Db_Aggregate_Result_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Aggregate_Result_TxtArgs = {
  objects: Array<Rtcs_Db_Aggregate_Result_Txt_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Aggregate_Result_Txt_OneArgs = {
  object: Rtcs_Db_Aggregate_Result_Txt_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Gazette_CommercialRatesArgs = {
  objects: Array<Rtcs_Db_Gazette_CommercialRates_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Gazette_CommercialRates_OneArgs = {
  object: Rtcs_Db_Gazette_CommercialRates_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Gazette_ResidentialRatesArgs = {
  objects: Array<Rtcs_Db_Gazette_ResidentialRates_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Gazette_ResidentialRates_OneArgs = {
  object: Rtcs_Db_Gazette_ResidentialRates_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_AssociateTypeArgs = {
  objects: Array<Rtcs_Db_Kcca_AssociateType_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_AssociateType_OneArgs = {
  object: Rtcs_Db_Kcca_AssociateType_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CommercialPropertiesArgs = {
  objects: Array<Rtcs_Db_Kcca_CommercialProperties_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CommercialProperties_OneArgs = {
  object: Rtcs_Db_Kcca_CommercialProperties_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CountyArgs = {
  objects: Array<Rtcs_Db_Kcca_County_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_County_OneArgs = {
  object: Rtcs_Db_Kcca_County_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CustomerArgs = {
  objects: Array<Rtcs_Db_Kcca_Customer_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CustomerApplicantTypeArgs = {
  objects: Array<Rtcs_Db_Kcca_CustomerApplicantType_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CustomerApplicantType_OneArgs = {
  object: Rtcs_Db_Kcca_CustomerApplicantType_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CustomerBusinessAssociateArgs = {
  objects: Array<Rtcs_Db_Kcca_CustomerBusinessAssociate_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CustomerBusinessAssociate_OneArgs =
  {
    object: Rtcs_Db_Kcca_CustomerBusinessAssociate_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CustomerBusinessTypeArgs = {
  objects: Array<Rtcs_Db_Kcca_CustomerBusinessType_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CustomerBusinessType_OneArgs = {
  object: Rtcs_Db_Kcca_CustomerBusinessType_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CustomerCoinDeactivateReactiveArgs =
  {
    objects: Array<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_OneArgs =
  {
    object: Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CustomerContactPersonArgs = {
  objects: Array<Rtcs_Db_Kcca_CustomerContactPerson_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CustomerContactPerson_OneArgs = {
  object: Rtcs_Db_Kcca_CustomerContactPerson_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CustomerDocumentItemArgs = {
  objects: Array<Rtcs_Db_Kcca_CustomerDocumentItem_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CustomerDocumentItem_OneArgs = {
  object: Rtcs_Db_Kcca_CustomerDocumentItem_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CustomerGuardianArgs = {
  objects: Array<Rtcs_Db_Kcca_CustomerGuardian_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CustomerGuardian_OneArgs = {
  object: Rtcs_Db_Kcca_CustomerGuardian_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CustomerReferenceArgs = {
  objects: Array<Rtcs_Db_Kcca_CustomerReference_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CustomerReference_OneArgs = {
  object: Rtcs_Db_Kcca_CustomerReference_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CustomerRegistrationReasonArgs = {
  objects: Array<Rtcs_Db_Kcca_CustomerRegistrationReason_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CustomerRegistrationReason_OneArgs =
  {
    object: Rtcs_Db_Kcca_CustomerRegistrationReason_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CustomerRevenueAgentArgs = {
  objects: Array<Rtcs_Db_Kcca_CustomerRevenueAgent_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CustomerRevenueAgent_OneArgs = {
  object: Rtcs_Db_Kcca_CustomerRevenueAgent_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CustomerTypeArgs = {
  objects: Array<Rtcs_Db_Kcca_CustomerType_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_CustomerType_OneArgs = {
  object: Rtcs_Db_Kcca_CustomerType_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_Customer_OneArgs = {
  object: Rtcs_Db_Kcca_Customer_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_Customer_SidArgs = {
  objects: Array<Rtcs_Db_Kcca_Customer_Sid_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_Customer_Sid_OneArgs = {
  object: Rtcs_Db_Kcca_Customer_Sid_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_DistrictArgs = {
  objects: Array<Rtcs_Db_Kcca_District_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_District_OneArgs = {
  object: Rtcs_Db_Kcca_District_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_DivisionArgs = {
  objects: Array<Rtcs_Db_Kcca_Division_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_Division_OneArgs = {
  object: Rtcs_Db_Kcca_Division_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_ImgsDataArgs = {
  objects: Array<Rtcs_Db_Kcca_ImgsData_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_ImgsData_OneArgs = {
  object: Rtcs_Db_Kcca_ImgsData_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_ParishArgs = {
  objects: Array<Rtcs_Db_Kcca_Parish_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_Parish_OneArgs = {
  object: Rtcs_Db_Kcca_Parish_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_PaymentDetailsArgs = {
  objects: Array<Rtcs_Db_Kcca_PaymentDetails_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_PaymentDetails_OneArgs = {
  object: Rtcs_Db_Kcca_PaymentDetails_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_PaymentsArgs = {
  objects: Array<Rtcs_Db_Kcca_Payments_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_Payments_OneArgs = {
  object: Rtcs_Db_Kcca_Payments_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_PropertyArgs = {
  objects: Array<Rtcs_Db_Kcca_Property_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_PropertyFloorsArgs = {
  objects: Array<Rtcs_Db_Kcca_PropertyFloors_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_PropertyFloors_OneArgs = {
  object: Rtcs_Db_Kcca_PropertyFloors_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_PropertyNumberBedroomsArgs = {
  objects: Array<Rtcs_Db_Kcca_PropertyNumberBedrooms_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_PropertyNumberBedrooms_OneArgs = {
  object: Rtcs_Db_Kcca_PropertyNumberBedrooms_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_PropertyRentedStatusArgs = {
  objects: Array<Rtcs_Db_Kcca_PropertyRentedStatus_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_PropertyRentedStatus_OneArgs = {
  object: Rtcs_Db_Kcca_PropertyRentedStatus_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_PropertySecurityArgs = {
  objects: Array<Rtcs_Db_Kcca_PropertySecurity_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_PropertySecurity_OneArgs = {
  object: Rtcs_Db_Kcca_PropertySecurity_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_PropertySubTypeArgs = {
  objects: Array<Rtcs_Db_Kcca_PropertySubType_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_PropertySubType_OneArgs = {
  object: Rtcs_Db_Kcca_PropertySubType_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_PropertyTypeArgs = {
  objects: Array<Rtcs_Db_Kcca_PropertyType_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_PropertyType_OneArgs = {
  object: Rtcs_Db_Kcca_PropertyType_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_Property_OneArgs = {
  object: Rtcs_Db_Kcca_Property_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_RawProperties1Args = {
  objects: Array<Rtcs_Db_Kcca_RawProperties1_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_RawProperties1_OneArgs = {
  object: Rtcs_Db_Kcca_RawProperties1_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_RawProperties2Args = {
  objects: Array<Rtcs_Db_Kcca_RawProperties2_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_RawProperties2_OneArgs = {
  object: Rtcs_Db_Kcca_RawProperties2_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_ResidentialPropertiesArgs = {
  objects: Array<Rtcs_Db_Kcca_ResidentialProperties_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_ResidentialProperties_OneArgs = {
  object: Rtcs_Db_Kcca_ResidentialProperties_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_RevenueTypeArgs = {
  objects: Array<Rtcs_Db_Kcca_RevenueType_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_RevenueType_OneArgs = {
  object: Rtcs_Db_Kcca_RevenueType_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_SubCountyArgs = {
  objects: Array<Rtcs_Db_Kcca_SubCounty_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_SubCounty_OneArgs = {
  object: Rtcs_Db_Kcca_SubCounty_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_SubpropertyTypeConversionArgs = {
  objects: Array<Rtcs_Db_Kcca_SubpropertyTypeConversion_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_SubpropertyTypeConversion_OneArgs =
  {
    object: Rtcs_Db_Kcca_SubpropertyTypeConversion_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_TradingLicenseArgs = {
  objects: Array<Rtcs_Db_Kcca_TradingLicense_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_TradingLicense_OneArgs = {
  object: Rtcs_Db_Kcca_TradingLicense_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_VillageArgs = {
  objects: Array<Rtcs_Db_Kcca_Village_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Kcca_Village_OneArgs = {
  object: Rtcs_Db_Kcca_Village_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Master_MasterIndivArgs = {
  objects: Array<Rtcs_Db_Master_MasterIndiv_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Master_MasterIndiv_OneArgs = {
  object: Rtcs_Db_Master_MasterIndiv_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Mlhud_KccaArgs = {
  objects: Array<Rtcs_Db_Mlhud_Kcca_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Mlhud_Kcca_OneArgs = {
  object: Rtcs_Db_Mlhud_Kcca_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Mlhud_MukonoArgs = {
  objects: Array<Rtcs_Db_Mlhud_Mukono_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Mlhud_Mukono_OneArgs = {
  object: Rtcs_Db_Mlhud_Mukono_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Mlhud_PropertiesArgs = {
  objects: Array<Rtcs_Db_Mlhud_Properties_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Mlhud_Properties_OneArgs = {
  object: Rtcs_Db_Mlhud_Properties_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Mlhud_WakisoBusiroArgs = {
  objects: Array<Rtcs_Db_Mlhud_WakisoBusiro_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Mlhud_WakisoBusiro_OneArgs = {
  object: Rtcs_Db_Mlhud_WakisoBusiro_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Mlhud_WakisoKyadondoArgs = {
  objects: Array<Rtcs_Db_Mlhud_WakisoKyadondo_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Mlhud_WakisoKyadondo_OneArgs = {
  object: Rtcs_Db_Mlhud_WakisoKyadondo_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Nira_NiraArgs = {
  objects: Array<Rtcs_Db_Nira_Nira_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Nira_Nira_OneArgs = {
  object: Rtcs_Db_Nira_Nira_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Nira_RawDataArgs = {
  objects: Array<Rtcs_Db_Nira_RawData_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Nira_RawData_OneArgs = {
  object: Rtcs_Db_Nira_RawData_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Nwsc_CustomersArgs = {
  objects: Array<Rtcs_Db_Nwsc_Customers_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Nwsc_Customers_OneArgs = {
  object: Rtcs_Db_Nwsc_Customers_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Nwsc_NwCustomersArgs = {
  objects: Array<Rtcs_Db_Nwsc_NwCustomers_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Nwsc_NwCustomers_OneArgs = {
  object: Rtcs_Db_Nwsc_NwCustomers_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Nwsc_Rpt_TransactionsArgs = {
  objects: Array<Rtcs_Db_Nwsc_Rpt_Transactions_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Nwsc_Rpt_Transactions_OneArgs = {
  object: Rtcs_Db_Nwsc_Rpt_Transactions_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Nwsc_TransactionsArgs = {
  objects: Array<Rtcs_Db_Nwsc_Transactions_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Nwsc_Transactions_OneArgs = {
  object: Rtcs_Db_Nwsc_Transactions_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Nwsc_UraTransactionsArgs = {
  objects: Array<Rtcs_Db_Nwsc_UraTransactions_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Nwsc_UraTransactions_OneArgs = {
  object: Rtcs_Db_Nwsc_UraTransactions_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rcapture_RptUnittypesArgs = {
  objects: Array<Rtcs_Db_Rcapture_RptUnittypes_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rcapture_RptUnittypes_OneArgs = {
  object: Rtcs_Db_Rcapture_RptUnittypes_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rcapture_RpTlessorsArgs = {
  objects: Array<Rtcs_Db_Rcapture_RpTlessors_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rcapture_RpTlessors_OneArgs = {
  object: Rtcs_Db_Rcapture_RpTlessors_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rcapture_RpTmetaArgs = {
  objects: Array<Rtcs_Db_Rcapture_RpTmeta_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rcapture_RpTmeta_OneArgs = {
  object: Rtcs_Db_Rcapture_RpTmeta_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rcapture_RpTpropertiesArgs = {
  objects: Array<Rtcs_Db_Rcapture_RpTproperties_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rcapture_RpTproperties_OneArgs = {
  object: Rtcs_Db_Rcapture_RpTproperties_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rcapture_RpTtenantsArgs = {
  objects: Array<Rtcs_Db_Rcapture_RpTtenants_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rcapture_RpTtenants_OneArgs = {
  object: Rtcs_Db_Rcapture_RpTtenants_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_IndividualArgs = {
  objects: Array<Rtcs_Db_Rmatch_Individual_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_IndividualComplianceDetailReportArgs =
  {
    objects: Array<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_IndividualComplianceDetailReport_OneArgs =
  {
    object: Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_IndividualComplianceSummaryReportArgs =
  {
    objects: Array<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_OneArgs =
  {
    object: Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_IndividualRiskScoreArgs = {
  objects: Array<Rtcs_Db_Rmatch_IndividualRiskScore_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_IndividualRiskScore_OneArgs = {
  object: Rtcs_Db_Rmatch_IndividualRiskScore_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_Individual_OneArgs = {
  object: Rtcs_Db_Rmatch_Individual_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_IndividualsNetworkArgs = {
  objects: Array<Rtcs_Db_Rmatch_IndividualsNetwork_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_IndividualsNetworkTestingArgs = {
  objects: Array<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_IndividualsNetworkTesting_OneArgs =
  {
    object: Rtcs_Db_Rmatch_IndividualsNetworkTesting_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_IndividualsNetwork_OneArgs = {
  object: Rtcs_Db_Rmatch_IndividualsNetwork_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_KccaToKccaIndividualOwnersArgs =
  {
    objects: Array<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_OneArgs =
  {
    object: Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_KccaToKccaNonindividualOwnersArgs =
  {
    objects: Array<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_OneArgs =
  {
    object: Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_MatchIndividualsPropertiesArgs =
  {
    objects: Array<Rtcs_Db_Rmatch_MatchIndividualsProperties_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_MatchIndividualsProperties_OneArgs =
  {
    object: Rtcs_Db_Rmatch_MatchIndividualsProperties_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_MatchIndividualsPropsArgs = {
  objects: Array<Rtcs_Db_Rmatch_MatchIndividualsProps_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_MatchIndividualsProps_OneArgs = {
  object: Rtcs_Db_Rmatch_MatchIndividualsProps_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_NoReturnsArgs = {
  objects: Array<Rtcs_Db_Rmatch_NoReturns_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_NoReturns_OneArgs = {
  object: Rtcs_Db_Rmatch_NoReturns_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_NonIndividualArgs = {
  objects: Array<Rtcs_Db_Rmatch_NonIndividual_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_NonIndividualComplianceDetailReportArgs =
  {
    objects: Array<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_OneArgs =
  {
    object: Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReportArgs =
  {
    objects: Array<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_OneArgs =
  {
    object: Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_NonIndividualRiskScoreArgs = {
  objects: Array<Rtcs_Db_Rmatch_NonIndividualRiskScore_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_NonIndividualRiskScore_OneArgs =
  {
    object: Rtcs_Db_Rmatch_NonIndividualRiskScore_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_NonIndividual_OneArgs = {
  object: Rtcs_Db_Rmatch_NonIndividual_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_NonIndividualsArgs = {
  objects: Array<Rtcs_Db_Rmatch_NonIndividuals_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_NonIndividualsNetworkArgs = {
  objects: Array<Rtcs_Db_Rmatch_NonIndividualsNetwork_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_NonIndividualsNetwork_OneArgs = {
  object: Rtcs_Db_Rmatch_NonIndividualsNetwork_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_NonIndividuals_OneArgs = {
  object: Rtcs_Db_Rmatch_NonIndividuals_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_NonindividualOwnersArgs = {
  objects: Array<Rtcs_Db_Rmatch_NonindividualOwners_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_NonindividualOwners_OneArgs = {
  object: Rtcs_Db_Rmatch_NonindividualOwners_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_NonindividualsArgs = {
  objects: Array<Rtcs_Db_Rmatch_Nonindividuals_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_Nonindividuals_OneArgs = {
  object: Rtcs_Db_Rmatch_Nonindividuals_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_PropertiesCompositeArgs = {
  objects: Array<Rtcs_Db_Rmatch_PropertiesComposite_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_PropertiesComposite_OneArgs = {
  object: Rtcs_Db_Rmatch_PropertiesComposite_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_PropertiesNetworkArgs = {
  objects: Array<Rtcs_Db_Rmatch_PropertiesNetwork_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_PropertiesNetwork_OneArgs = {
  object: Rtcs_Db_Rmatch_PropertiesNetwork_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_PropertyNetworkArgs = {
  objects: Array<Rtcs_Db_Rmatch_PropertyNetwork_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_PropertyNetwork_OneArgs = {
  object: Rtcs_Db_Rmatch_PropertyNetwork_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_RMatchDetailed904Args = {
  objects: Array<Rtcs_Db_Rmatch_RMatchDetailed904_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_RMatchDetailed904_OneArgs = {
  object: Rtcs_Db_Rmatch_RMatchDetailed904_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_RMatchDetailed916Args = {
  objects: Array<Rtcs_Db_Rmatch_RMatchDetailed916_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancyArgs =
  {
    objects: Array<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_OneArgs =
  {
    object: Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_RMatchDetailed916_OneArgs = {
  object: Rtcs_Db_Rmatch_RMatchDetailed916_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_RMatchDetailed937Args = {
  objects: Array<Rtcs_Db_Rmatch_RMatchDetailed937_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_RMatchDetailed937_OneArgs = {
  object: Rtcs_Db_Rmatch_RMatchDetailed937_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_RMatchKccaPropertiesArgs = {
  objects: Array<Rtcs_Db_Rmatch_RMatchKccaProperties_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_RMatchKccaProperties_OneArgs = {
  object: Rtcs_Db_Rmatch_RMatchKccaProperties_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_RMatchKccaPropertyArgs = {
  objects: Array<Rtcs_Db_Rmatch_RMatchKccaProperty_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_RMatchKccaProperty_OneArgs = {
  object: Rtcs_Db_Rmatch_RMatchKccaProperty_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_RMatchNonIndividual903Args = {
  objects: Array<Rtcs_Db_Rmatch_RMatchNonIndividual903_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_RMatchNonIndividual903_OneArgs =
  {
    object: Rtcs_Db_Rmatch_RMatchNonIndividual903_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_RMatchProperties1115Args = {
  objects: Array<Rtcs_Db_Rmatch_RMatchProperties1115_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_RMatchProperties1115_OneArgs = {
  object: Rtcs_Db_Rmatch_RMatchProperties1115_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_RMatchSummary904Args = {
  objects: Array<Rtcs_Db_Rmatch_RMatchSummary904_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_RMatchSummary904_OneArgs = {
  object: Rtcs_Db_Rmatch_RMatchSummary904_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_RMatchSummary916Args = {
  objects: Array<Rtcs_Db_Rmatch_RMatchSummary916_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancyArgs =
  {
    objects: Array<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_OneArgs =
  {
    object: Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_RMatchSummary916_OneArgs = {
  object: Rtcs_Db_Rmatch_RMatchSummary916_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_RMatchSummary937Args = {
  objects: Array<Rtcs_Db_Rmatch_RMatchSummary937_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_RMatchSummary937_OneArgs = {
  object: Rtcs_Db_Rmatch_RMatchSummary937_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_TinsUraArgs = {
  objects: Array<Rtcs_Db_Rmatch_TinsUra_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_TinsUra_OneArgs = {
  object: Rtcs_Db_Rmatch_TinsUra_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_UndeclaredArgs = {
  objects: Array<Rtcs_Db_Rmatch_Undeclared_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_Undeclared_OneArgs = {
  object: Rtcs_Db_Rmatch_Undeclared_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_UnderreportedArgs = {
  objects: Array<Rtcs_Db_Rmatch_Underreported_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_Underreported_OneArgs = {
  object: Rtcs_Db_Rmatch_Underreported_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_UnregisteredArgs = {
  objects: Array<Rtcs_Db_Rmatch_Unregistered_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_Unregistered_OneArgs = {
  object: Rtcs_Db_Rmatch_Unregistered_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_MlhudConsolidatedArgs = {
  objects: Array<Rtcs_Db_Rmatch_MlhudConsolidated_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Rmatch_MlhudConsolidated_OneArgs = {
  object: Rtcs_Db_Rmatch_MlhudConsolidated_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Streetmap_OsmMukonoMunicipalityArgs = {
  objects: Array<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Streetmap_OsmMukonoMunicipality_OneArgs =
  {
    object: Rtcs_Db_Streetmap_OsmMukonoMunicipality_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Streetmap_OsmMukonoNakifumaArgs = {
  objects: Array<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Streetmap_OsmMukonoNakifuma_OneArgs = {
  object: Rtcs_Db_Streetmap_OsmMukonoNakifuma_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Streetmap_OsmWakisoEntebbeArgs = {
  objects: Array<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Streetmap_OsmWakisoEntebbe_OneArgs = {
  object: Rtcs_Db_Streetmap_OsmWakisoEntebbe_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Streetmap_OsmWakisoKiraArgs = {
  objects: Array<Rtcs_Db_Streetmap_OsmWakisoKira_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Streetmap_OsmWakisoKira_OneArgs = {
  object: Rtcs_Db_Streetmap_OsmWakisoKira_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagaboArgs =
  {
    objects: Array<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_OneArgs =
  {
    object: Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Streetmap_OsmWakisoNansanaArgs = {
  objects: Array<Rtcs_Db_Streetmap_OsmWakisoNansana_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Streetmap_OsmWakisoNansana_OneArgs = {
  object: Rtcs_Db_Streetmap_OsmWakisoNansana_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ucc_UccArgs = {
  objects: Array<Rtcs_Db_Ucc_Ucc_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ucc_Ucc_OneArgs = {
  object: Rtcs_Db_Ucc_Ucc_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Umeme_SubscriberAndPropertyArgs = {
  objects: Array<Rtcs_Db_Umeme_SubscriberAndProperty_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Umeme_SubscriberAndProperty_OneArgs = {
  object: Rtcs_Db_Umeme_SubscriberAndProperty_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Umeme_TransactionsArgs = {
  objects: Array<Rtcs_Db_Umeme_Transactions_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Umeme_Transactions_OneArgs = {
  object: Rtcs_Db_Umeme_Transactions_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_BalanceSheetInformationArgs = {
  objects: Array<Rtcs_Db_Ura_BalanceSheetInformation_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_BalanceSheetInformation_OneArgs = {
  object: Rtcs_Db_Ura_BalanceSheetInformation_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_CombinedOfficersAndOwnersArgs = {
  objects: Array<Rtcs_Db_Ura_CombinedOfficersAndOwners_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_CombinedOfficersAndOwners_OneArgs =
  {
    object: Rtcs_Db_Ura_CombinedOfficersAndOwners_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_EmploymentIncomeDetailArgs = {
  objects: Array<Rtcs_Db_Ura_EmploymentIncomeDetail_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_EmploymentIncomeDetail_OneArgs = {
  object: Rtcs_Db_Ura_EmploymentIncomeDetail_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootageArgs =
  {
    objects: Array<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_OneArgs =
  {
    object: Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_GazetteResidentialRatesPerBedroomArgs =
  {
    objects: Array<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_OneArgs =
  {
    object: Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_HoldingOfficersArgs = {
  objects: Array<Rtcs_Db_Ura_HoldingOfficers_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_HoldingOfficers_OneArgs = {
  object: Rtcs_Db_Ura_HoldingOfficers_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_HoldingOwnerArgs = {
  objects: Array<Rtcs_Db_Ura_HoldingOwner_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_HoldingOwner_OneArgs = {
  object: Rtcs_Db_Ura_HoldingOwner_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_IncomeTaxHoldingArgs = {
  objects: Array<Rtcs_Db_Ura_IncomeTaxHolding_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_IncomeTaxHolding_OneArgs = {
  object: Rtcs_Db_Ura_IncomeTaxHolding_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_IncomeTaxSummaryIndividualArgs = {
  objects: Array<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_IncomeTaxSummaryIndividual_OneArgs =
  {
    object: Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_IncomeTaxSummaryNonIndividualArgs =
  {
    objects: Array<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_OneArgs =
  {
    object: Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_IndividualMortgageArgs = {
  objects: Array<Rtcs_Db_Ura_IndividualMortgage_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_IndividualMortgage_OneArgs = {
  object: Rtcs_Db_Ura_IndividualMortgage_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_IndividualsAssociatedBusinessArgs =
  {
    objects: Array<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_IndividualsAssociatedBusiness_OneArgs =
  {
    object: Rtcs_Db_Ura_IndividualsAssociatedBusiness_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_LandlordIncomeDetailArgs = {
  objects: Array<Rtcs_Db_Ura_LandlordIncomeDetail_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_LandlordIncomeDetail_OneArgs = {
  object: Rtcs_Db_Ura_LandlordIncomeDetail_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_LandlordIncomeSummaryArgs = {
  objects: Array<Rtcs_Db_Ura_LandlordIncomeSummary_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_LandlordIncomeSummary_OneArgs = {
  object: Rtcs_Db_Ura_LandlordIncomeSummary_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_NonIndividualsAssociatedBusinessArgs =
  {
    objects: Array<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_OneArgs =
  {
    object: Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Insert_Input;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_RegAssociatesArgs = {
  objects: Array<Rtcs_Db_Ura_RegAssociates_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_RegAssociates_OneArgs = {
  object: Rtcs_Db_Ura_RegAssociates_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_RegIndividualArgs = {
  objects: Array<Rtcs_Db_Ura_RegIndividual_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_RegIndividual_OneArgs = {
  object: Rtcs_Db_Ura_RegIndividual_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_RegIndividual_SidArgs = {
  objects: Array<Rtcs_Db_Ura_RegIndividual_Sid_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_RegIndividual_Sid_OneArgs = {
  object: Rtcs_Db_Ura_RegIndividual_Sid_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_RegNonIndividualArgs = {
  objects: Array<Rtcs_Db_Ura_RegNonIndividual_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_RegNonIndividual_OneArgs = {
  object: Rtcs_Db_Ura_RegNonIndividual_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_RegNonIndividual_SidArgs = {
  objects: Array<Rtcs_Db_Ura_RegNonIndividual_Sid_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_RegNonIndividual_Sid_OneArgs = {
  object: Rtcs_Db_Ura_RegNonIndividual_Sid_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_RentExpenseDetailsArgs = {
  objects: Array<Rtcs_Db_Ura_RentExpenseDetails_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_RentExpenseDetails_OneArgs = {
  object: Rtcs_Db_Ura_RentExpenseDetails_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_RentalLandLordIncomeArgs = {
  objects: Array<Rtcs_Db_Ura_RentalLandLordIncome_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_RentalLandLordIncome_OneArgs = {
  object: Rtcs_Db_Ura_RentalLandLordIncome_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_RentalTenantPaymentsArgs = {
  objects: Array<Rtcs_Db_Ura_RentalTenantPayments_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_RentalTenantPayments_OneArgs = {
  object: Rtcs_Db_Ura_RentalTenantPayments_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_RipoIndRentalIncSummaryArgs = {
  objects: Array<Rtcs_Db_Ura_RipoIndRentalIncSummary_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_RipoIndRentalIncSummary_OneArgs = {
  object: Rtcs_Db_Ura_RipoIndRentalIncSummary_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_TenantIncomeDetailArgs = {
  objects: Array<Rtcs_Db_Ura_TenantIncomeDetail_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_TenantIncomeDetail_OneArgs = {
  object: Rtcs_Db_Ura_TenantIncomeDetail_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_TownArgs = {
  objects: Array<Rtcs_Db_Ura_Town_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ura_Town_OneArgs = {
  object: Rtcs_Db_Ura_Town_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsbArgs =
  {
    objects: Array<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Insert_Input>;
  };

/** mutation root */
export type Mutation_RootInsert_Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_OneArgs =
  {
    object: Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Insert_Input;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_EntityFilterArgs = {
  _inc?: Maybe<Rtcs_Db_EntityFilter_Inc_Input>;
  _set?: Maybe<Rtcs_Db_EntityFilter_Set_Input>;
  where: Rtcs_Db_EntityFilter_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_FilterArgs = {
  _set?: Maybe<Rtcs_Db_Filter_Set_Input>;
  where: Rtcs_Db_Filter_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_GazzetteRatesArgs = {
  _inc?: Maybe<Rtcs_Db_GazzetteRates_Inc_Input>;
  _set?: Maybe<Rtcs_Db_GazzetteRates_Set_Input>;
  where: Rtcs_Db_GazzetteRates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_EntityFilterArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_EntityFilter_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_EntityFilter_Set_Input>;
  where: Rtcs_Db_Ph_EntityFilter_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_FilterArgs = {
  _set?: Maybe<Rtcs_Db_Ph_Filter_Set_Input>;
  where: Rtcs_Db_Ph_Filter_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_GazzetteRatesArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_GazzetteRates_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_GazzetteRates_Set_Input>;
  where: Rtcs_Db_Ph_GazzetteRates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRiskArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Set_Input>;
    where: Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rpt_IndividualCompositeArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Set_Input>;
  where: Rtcs_Db_Ph_Rpt_IndividualComposite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rpt_IndividualRegistrationArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Set_Input>;
  where: Rtcs_Db_Ph_Rpt_IndividualRegistration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rpt_IndividualTaxSummaryDataArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Set_Input>;
  where: Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rpt_KccaAffiliatesArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Set_Input>;
  where: Rtcs_Db_Ph_Rpt_KccaAffiliates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationDataArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Set_Input>;
    where: Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Set_Input>;
  where: Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationDataArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Set_Input>;
    where: Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheetArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Set_Input>;
  where: Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRiskArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Set_Input>;
    where: Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rpt_NonIndividualCompositeArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Set_Input>;
  where: Rtcs_Db_Ph_Rpt_NonIndividualComposite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryDataArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Set_Input>;
    where: Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rpt_PropertiesCompositeArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Set_Input>;
  where: Rtcs_Db_Ph_Rpt_PropertiesComposite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rpt_PropertyValuationArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Set_Input>;
  where: Rtcs_Db_Ph_Rpt_PropertyValuation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rpt_RentalTenantPaymentsArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Set_Input>;
  where: Rtcs_Db_Ph_Rpt_RentalTenantPayments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Gazette_CommercialRatesArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Set_Input>;
  where: Rtcs_Db_Ph_Gazette_CommercialRates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Gazette_ResidentialRatesArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Set_Input>;
  where: Rtcs_Db_Ph_Gazette_ResidentialRates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_AssociateTypeArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_AssociateType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_CommercialPropertiesArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_CommercialProperties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_CountyArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_County_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_County_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_County_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_CustomerArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_Customer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_CustomerApplicantTypeArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_CustomerApplicantType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_CustomerBusinessAssociateArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Set_Input>;
    where: Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_CustomerBusinessTypeArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_CustomerBusinessType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactiveArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Set_Input>;
    where: Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_CustomerContactPersonArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_CustomerContactPerson_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_CustomerDocumentItemArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_CustomerGuardianArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_CustomerGuardian_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_CustomerReferenceArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_CustomerReference_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_CustomerRegistrationReasonArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Set_Input>;
    where: Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_CustomerRevenueAgentArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_CustomerTypeArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_CustomerType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_Customer_SidArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_Customer_Sid_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_DistrictArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_District_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_District_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_District_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_DivisionArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_Division_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_Division_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_Division_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_ImgsDataArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_ImgsData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_ParishArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_Parish_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_PaymentDetailsArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_PaymentDetails_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_PaymentsArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_Payments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_PropertyArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_Property_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_Property_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_Property_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_PropertyFloorsArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_PropertyFloors_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_PropertyNumberBedroomsArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_PropertyRentedStatusArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_PropertySecurityArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_PropertySecurity_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_PropertySubTypeArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_PropertySubType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_PropertyTypeArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_PropertyType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_RawProperties1Args = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_RawProperties1_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_RawProperties2Args = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_RawProperties2_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_ResidentialPropertiesArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_ResidentialProperties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_RevenueTypeArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_RevenueType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_SubCountyArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_SubCounty_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_SubpropertyTypeConversionArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Set_Input>;
    where: Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_TradingLicenseArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_TradingLicense_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Kcca_VillageArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Kcca_Village_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Kcca_Village_Set_Input>;
  where: Rtcs_Db_Ph_Kcca_Village_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Master_MasterIndivArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Set_Input>;
  where: Rtcs_Db_Ph_Master_MasterIndiv_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Mlhud_KccaArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Set_Input>;
  where: Rtcs_Db_Ph_Mlhud_Kcca_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Mlhud_MukonoArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Set_Input>;
  where: Rtcs_Db_Ph_Mlhud_Mukono_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Mlhud_PropertiesArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Set_Input>;
  where: Rtcs_Db_Ph_Mlhud_Properties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Mlhud_WakisoBusiroArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Set_Input>;
  where: Rtcs_Db_Ph_Mlhud_WakisoBusiro_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Mlhud_WakisoKyadondoArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Set_Input>;
  where: Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Nira_NiraArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Nira_Nira_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Nira_Nira_Set_Input>;
  where: Rtcs_Db_Ph_Nira_Nira_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Nira_RawDataArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Nira_RawData_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Nira_RawData_Set_Input>;
  where: Rtcs_Db_Ph_Nira_RawData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Nwsc_CustomersArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Set_Input>;
  where: Rtcs_Db_Ph_Nwsc_Customers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Nwsc_NwCustomersArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Set_Input>;
  where: Rtcs_Db_Ph_Nwsc_NwCustomers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Nwsc_Rpt_TransactionsArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Set_Input>;
  where: Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Nwsc_TransactionsArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Set_Input>;
  where: Rtcs_Db_Ph_Nwsc_Transactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Nwsc_UraTransactionsArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Set_Input>;
  where: Rtcs_Db_Ph_Nwsc_UraTransactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rcapture_RptUnittypesArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Set_Input>;
  where: Rtcs_Db_Ph_Rcapture_RptUnittypes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rcapture_RpTlessorsArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Set_Input>;
  where: Rtcs_Db_Ph_Rcapture_RpTlessors_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rcapture_RpTmetaArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Set_Input>;
  where: Rtcs_Db_Ph_Rcapture_RpTmeta_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rcapture_RpTpropertiesArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Set_Input>;
  where: Rtcs_Db_Ph_Rcapture_RpTproperties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rcapture_RpTtenantsArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Set_Input>;
  where: Rtcs_Db_Ph_Rcapture_RpTtenants_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_IndividualArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_Individual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReportArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Set_Input>;
    where: Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReportArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Set_Input>;
    where: Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_IndividualRiskScoreArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_IndividualsNetworkArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_IndividualsNetworkTestingArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Set_Input>;
    where: Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwnersArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Set_Input>;
    where: Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwnersArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Set_Input>;
    where: Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_MatchIndividualsPropertiesArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Set_Input>;
    where: Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_MatchIndividualsPropsArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_NoReturnsArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_NoReturns_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_NonIndividualArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_NonIndividual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReportArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Set_Input>;
    where: Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReportArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Set_Input>;
    where: Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_NonIndividualRiskScoreArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_NonIndividualsArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_NonIndividuals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_NonIndividualsNetworkArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_NonindividualOwnersArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_NonindividualOwners_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_NonindividualsArgs = {
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_Nonindividuals_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_Nonindividuals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_PropertiesCompositeArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_PropertiesComposite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_PropertiesNetworkArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_PropertyNetworkArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_PropertyNetwork_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_RMatchDetailed904Args = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_RMatchDetailed916Args = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancyArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Set_Input>;
    where: Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_RMatchDetailed937Args = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_RMatchKccaPropertiesArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_RMatchKccaPropertyArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903Args = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_RMatchProperties1115Args = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_RMatchSummary904Args = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_RMatchSummary904_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_RMatchSummary916Args = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_RMatchSummary916_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancyArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Set_Input>;
    where: Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_RMatchSummary937Args = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_RMatchSummary937_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_TinsUraArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_TinsUra_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_UndeclaredArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_Undeclared_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_UnderreportedArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_Underreported_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_UnregisteredArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_Unregistered_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Rmatch_MlhudConsolidatedArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Set_Input>;
  where: Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipalityArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Set_Input>;
    where: Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Streetmap_OsmMukonoNakifumaArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Set_Input>;
  where: Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbeArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Set_Input>;
  where: Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Streetmap_OsmWakisoKiraArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Set_Input>;
  where: Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagaboArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Set_Input>;
    where: Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Streetmap_OsmWakisoNansanaArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Set_Input>;
  where: Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ucc_UccArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Set_Input>;
  where: Rtcs_Db_Ph_Ucc_Ucc_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Umeme_SubscriberAndPropertyArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Set_Input>;
  where: Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Umeme_TransactionsArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Set_Input>;
  where: Rtcs_Db_Ph_Umeme_Transactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_BalanceSheetInformationArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Set_Input>;
  where: Rtcs_Db_Ph_Ura_BalanceSheetInformation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_CombinedOfficersAndOwnersArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Set_Input>;
  where: Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_EmploymentIncomeDetailArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Set_Input>;
  where: Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootageArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Set_Input>;
    where: Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroomArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Set_Input>;
    where: Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_HoldingOfficersArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Set_Input>;
  where: Rtcs_Db_Ph_Ura_HoldingOfficers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_HoldingOwnerArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Set_Input>;
  where: Rtcs_Db_Ph_Ura_HoldingOwner_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_IncomeTaxHoldingArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Set_Input>;
  where: Rtcs_Db_Ph_Ura_IncomeTaxHolding_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividualArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Set_Input>;
    where: Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividualArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Set_Input>;
    where: Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_IndividualMortgageArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Set_Input>;
  where: Rtcs_Db_Ph_Ura_IndividualMortgage_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_IndividualsAssociatedBusinessArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Set_Input>;
    where: Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_LandlordIncomeDetailArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Set_Input>;
  where: Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_LandlordIncomeSummaryArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Set_Input>;
  where: Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusinessArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Set_Input>;
    where: Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_RegAssociatesArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Set_Input>;
  where: Rtcs_Db_Ph_Ura_RegAssociates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_RegIndividualArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Set_Input>;
  where: Rtcs_Db_Ph_Ura_RegIndividual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_RegIndividual_SidArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Set_Input>;
  where: Rtcs_Db_Ph_Ura_RegIndividual_Sid_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_RegNonIndividualArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Set_Input>;
  where: Rtcs_Db_Ph_Ura_RegNonIndividual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_RegNonIndividual_SidArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Set_Input>;
  where: Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_RentExpenseDetailsArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Set_Input>;
  where: Rtcs_Db_Ph_Ura_RentExpenseDetails_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_RentalLandLordIncomeArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Set_Input>;
  where: Rtcs_Db_Ph_Ura_RentalLandLordIncome_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_RentalTenantPaymentsArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Set_Input>;
  where: Rtcs_Db_Ph_Ura_RentalTenantPayments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_RipoIndRentalIncSummaryArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Set_Input>;
  where: Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_TenantIncomeDetailArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Set_Input>;
  where: Rtcs_Db_Ph_Ura_TenantIncomeDetail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ura_TownArgs = {
  _inc?: Maybe<Rtcs_Db_Ph_Ura_Town_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ph_Ura_Town_Set_Input>;
  where: Rtcs_Db_Ph_Ura_Town_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsbArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Set_Input>;
    where: Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRiskArgs =
  {
    _inc?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Set_Input>;
    where: Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rpt_IndividualCompositeArgs = {
  _inc?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Set_Input>;
  where: Rtcs_Db_Rpt_IndividualComposite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rpt_IndividualRegistrationArgs = {
  _inc?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Set_Input>;
  where: Rtcs_Db_Rpt_IndividualRegistration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rpt_IndividualTaxSummaryDataArgs = {
  _inc?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Set_Input>;
  where: Rtcs_Db_Rpt_IndividualTaxSummaryData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rpt_KccaAffiliatesArgs = {
  _inc?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Set_Input>;
  where: Rtcs_Db_Rpt_KccaAffiliates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rpt_KccaCustomerRegistrationDataArgs = {
  _inc?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Set_Input>;
  where: Rtcs_Db_Rpt_KccaCustomerRegistrationData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rpt_KccaPropertyRegistrationArgs = {
  _inc?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Set_Input>;
  where: Rtcs_Db_Rpt_KccaPropertyRegistration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rpt_KccaPropertyRegistrationDataArgs = {
  _inc?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Set_Input>;
  where: Rtcs_Db_Rpt_KccaPropertyRegistrationData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rpt_NonIndividualBalanceSheetArgs = {
  _inc?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Set_Input>;
  where: Rtcs_Db_Rpt_NonIndividualBalanceSheet_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRiskArgs =
  {
    _inc?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Set_Input>;
    where: Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rpt_NonIndividualCompositeArgs = {
  _inc?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Set_Input>;
  where: Rtcs_Db_Rpt_NonIndividualComposite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rpt_NonIndividualTaxSummaryDataArgs = {
  _inc?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Set_Input>;
  where: Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rpt_PropertiesCompositeArgs = {
  _inc?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Set_Input>;
  where: Rtcs_Db_Rpt_PropertiesComposite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rpt_PropertyValuationArgs = {
  _inc?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Set_Input>;
  where: Rtcs_Db_Rpt_PropertyValuation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rpt_RentalTenantPaymentsArgs = {
  _inc?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Set_Input>;
  where: Rtcs_Db_Rpt_RentalTenantPayments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Aggregate_ResultArgs = {
  _inc?: Maybe<Rtcs_Db_Aggregate_Result_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Aggregate_Result_Set_Input>;
  where: Rtcs_Db_Aggregate_Result_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Aggregate_Result_TxtArgs = {
  _set?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Set_Input>;
  where: Rtcs_Db_Aggregate_Result_Txt_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Gazette_CommercialRatesArgs = {
  _inc?: Maybe<Rtcs_Db_Gazette_CommercialRates_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Gazette_CommercialRates_Set_Input>;
  where: Rtcs_Db_Gazette_CommercialRates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Gazette_ResidentialRatesArgs = {
  _inc?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Set_Input>;
  where: Rtcs_Db_Gazette_ResidentialRates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_AssociateTypeArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_AssociateType_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_AssociateType_Set_Input>;
  where: Rtcs_Db_Kcca_AssociateType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_CommercialPropertiesArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Set_Input>;
  where: Rtcs_Db_Kcca_CommercialProperties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_CountyArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_County_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_County_Set_Input>;
  where: Rtcs_Db_Kcca_County_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_CustomerArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_Customer_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_Customer_Set_Input>;
  where: Rtcs_Db_Kcca_Customer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_CustomerApplicantTypeArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Set_Input>;
  where: Rtcs_Db_Kcca_CustomerApplicantType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_CustomerBusinessAssociateArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Set_Input>;
  where: Rtcs_Db_Kcca_CustomerBusinessAssociate_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_CustomerBusinessTypeArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Set_Input>;
  where: Rtcs_Db_Kcca_CustomerBusinessType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_CustomerCoinDeactivateReactiveArgs =
  {
    _inc?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Set_Input>;
    where: Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_CustomerContactPersonArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Set_Input>;
  where: Rtcs_Db_Kcca_CustomerContactPerson_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_CustomerDocumentItemArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Set_Input>;
  where: Rtcs_Db_Kcca_CustomerDocumentItem_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_CustomerGuardianArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Set_Input>;
  where: Rtcs_Db_Kcca_CustomerGuardian_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_CustomerReferenceArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_CustomerReference_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_CustomerReference_Set_Input>;
  where: Rtcs_Db_Kcca_CustomerReference_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_CustomerRegistrationReasonArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Set_Input>;
  where: Rtcs_Db_Kcca_CustomerRegistrationReason_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_CustomerRevenueAgentArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Set_Input>;
  where: Rtcs_Db_Kcca_CustomerRevenueAgent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_CustomerTypeArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_CustomerType_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_CustomerType_Set_Input>;
  where: Rtcs_Db_Kcca_CustomerType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_Customer_SidArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Set_Input>;
  where: Rtcs_Db_Kcca_Customer_Sid_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_DistrictArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_District_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_District_Set_Input>;
  where: Rtcs_Db_Kcca_District_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_DivisionArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_Division_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_Division_Set_Input>;
  where: Rtcs_Db_Kcca_Division_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_ImgsDataArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_ImgsData_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_ImgsData_Set_Input>;
  where: Rtcs_Db_Kcca_ImgsData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_ParishArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_Parish_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_Parish_Set_Input>;
  where: Rtcs_Db_Kcca_Parish_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_PaymentDetailsArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Set_Input>;
  where: Rtcs_Db_Kcca_PaymentDetails_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_PaymentsArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_Payments_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_Payments_Set_Input>;
  where: Rtcs_Db_Kcca_Payments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_PropertyArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_Property_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_Property_Set_Input>;
  where: Rtcs_Db_Kcca_Property_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_PropertyFloorsArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Set_Input>;
  where: Rtcs_Db_Kcca_PropertyFloors_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_PropertyNumberBedroomsArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Set_Input>;
  where: Rtcs_Db_Kcca_PropertyNumberBedrooms_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_PropertyRentedStatusArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Set_Input>;
  where: Rtcs_Db_Kcca_PropertyRentedStatus_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_PropertySecurityArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Set_Input>;
  where: Rtcs_Db_Kcca_PropertySecurity_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_PropertySubTypeArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_PropertySubType_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_PropertySubType_Set_Input>;
  where: Rtcs_Db_Kcca_PropertySubType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_PropertyTypeArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_PropertyType_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_PropertyType_Set_Input>;
  where: Rtcs_Db_Kcca_PropertyType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_RawProperties1Args = {
  _inc?: Maybe<Rtcs_Db_Kcca_RawProperties1_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_RawProperties1_Set_Input>;
  where: Rtcs_Db_Kcca_RawProperties1_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_RawProperties2Args = {
  _inc?: Maybe<Rtcs_Db_Kcca_RawProperties2_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_RawProperties2_Set_Input>;
  where: Rtcs_Db_Kcca_RawProperties2_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_ResidentialPropertiesArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Set_Input>;
  where: Rtcs_Db_Kcca_ResidentialProperties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_RevenueTypeArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_RevenueType_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_RevenueType_Set_Input>;
  where: Rtcs_Db_Kcca_RevenueType_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_SubCountyArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_SubCounty_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_SubCounty_Set_Input>;
  where: Rtcs_Db_Kcca_SubCounty_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_SubpropertyTypeConversionArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Set_Input>;
  where: Rtcs_Db_Kcca_SubpropertyTypeConversion_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_TradingLicenseArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_TradingLicense_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_TradingLicense_Set_Input>;
  where: Rtcs_Db_Kcca_TradingLicense_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Kcca_VillageArgs = {
  _inc?: Maybe<Rtcs_Db_Kcca_Village_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Kcca_Village_Set_Input>;
  where: Rtcs_Db_Kcca_Village_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Master_MasterIndivArgs = {
  _inc?: Maybe<Rtcs_Db_Master_MasterIndiv_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Master_MasterIndiv_Set_Input>;
  where: Rtcs_Db_Master_MasterIndiv_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Mlhud_KccaArgs = {
  _inc?: Maybe<Rtcs_Db_Mlhud_Kcca_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Mlhud_Kcca_Set_Input>;
  where: Rtcs_Db_Mlhud_Kcca_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Mlhud_MukonoArgs = {
  _inc?: Maybe<Rtcs_Db_Mlhud_Mukono_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Mlhud_Mukono_Set_Input>;
  where: Rtcs_Db_Mlhud_Mukono_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Mlhud_PropertiesArgs = {
  _inc?: Maybe<Rtcs_Db_Mlhud_Properties_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Mlhud_Properties_Set_Input>;
  where: Rtcs_Db_Mlhud_Properties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Mlhud_WakisoBusiroArgs = {
  _inc?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Set_Input>;
  where: Rtcs_Db_Mlhud_WakisoBusiro_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Mlhud_WakisoKyadondoArgs = {
  _inc?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Set_Input>;
  where: Rtcs_Db_Mlhud_WakisoKyadondo_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Nira_NiraArgs = {
  _inc?: Maybe<Rtcs_Db_Nira_Nira_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Nira_Nira_Set_Input>;
  where: Rtcs_Db_Nira_Nira_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Nira_RawDataArgs = {
  _inc?: Maybe<Rtcs_Db_Nira_RawData_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Nira_RawData_Set_Input>;
  where: Rtcs_Db_Nira_RawData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Nwsc_CustomersArgs = {
  _inc?: Maybe<Rtcs_Db_Nwsc_Customers_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Nwsc_Customers_Set_Input>;
  where: Rtcs_Db_Nwsc_Customers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Nwsc_NwCustomersArgs = {
  _inc?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Set_Input>;
  where: Rtcs_Db_Nwsc_NwCustomers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Nwsc_Rpt_TransactionsArgs = {
  _inc?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Set_Input>;
  where: Rtcs_Db_Nwsc_Rpt_Transactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Nwsc_TransactionsArgs = {
  _inc?: Maybe<Rtcs_Db_Nwsc_Transactions_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Nwsc_Transactions_Set_Input>;
  where: Rtcs_Db_Nwsc_Transactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Nwsc_UraTransactionsArgs = {
  _inc?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Set_Input>;
  where: Rtcs_Db_Nwsc_UraTransactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rcapture_RptUnittypesArgs = {
  _inc?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Set_Input>;
  where: Rtcs_Db_Rcapture_RptUnittypes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rcapture_RpTlessorsArgs = {
  _inc?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Set_Input>;
  where: Rtcs_Db_Rcapture_RpTlessors_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rcapture_RpTmetaArgs = {
  _inc?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Set_Input>;
  where: Rtcs_Db_Rcapture_RpTmeta_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rcapture_RpTpropertiesArgs = {
  _inc?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Set_Input>;
  where: Rtcs_Db_Rcapture_RpTproperties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rcapture_RpTtenantsArgs = {
  _inc?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Set_Input>;
  where: Rtcs_Db_Rcapture_RpTtenants_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_IndividualArgs = {
  _inc?: Maybe<Rtcs_Db_Rmatch_Individual_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_Individual_Set_Input>;
  where: Rtcs_Db_Rmatch_Individual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_IndividualComplianceDetailReportArgs =
  {
    _inc?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Set_Input>;
    where: Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_IndividualComplianceSummaryReportArgs =
  {
    _inc?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Set_Input>;
    where: Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_IndividualRiskScoreArgs = {
  _inc?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Set_Input>;
  where: Rtcs_Db_Rmatch_IndividualRiskScore_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_IndividualsNetworkArgs = {
  _inc?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Set_Input>;
  where: Rtcs_Db_Rmatch_IndividualsNetwork_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_IndividualsNetworkTestingArgs = {
  _inc?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Set_Input>;
  where: Rtcs_Db_Rmatch_IndividualsNetworkTesting_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_KccaToKccaIndividualOwnersArgs =
  {
    _inc?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Set_Input>;
    where: Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_KccaToKccaNonindividualOwnersArgs =
  {
    _inc?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Set_Input>;
    where: Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_MatchIndividualsPropertiesArgs =
  {
    _inc?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Set_Input>;
    where: Rtcs_Db_Rmatch_MatchIndividualsProperties_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_MatchIndividualsPropsArgs = {
  _inc?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Set_Input>;
  where: Rtcs_Db_Rmatch_MatchIndividualsProps_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_NoReturnsArgs = {
  _inc?: Maybe<Rtcs_Db_Rmatch_NoReturns_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_NoReturns_Set_Input>;
  where: Rtcs_Db_Rmatch_NoReturns_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_NonIndividualArgs = {
  _inc?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Set_Input>;
  where: Rtcs_Db_Rmatch_NonIndividual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_NonIndividualComplianceDetailReportArgs =
  {
    _inc?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Set_Input>;
    where: Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReportArgs =
  {
    _inc?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Set_Input>;
    where: Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_NonIndividualRiskScoreArgs = {
  _inc?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Set_Input>;
  where: Rtcs_Db_Rmatch_NonIndividualRiskScore_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_NonIndividualsArgs = {
  _inc?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Set_Input>;
  where: Rtcs_Db_Rmatch_NonIndividuals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_NonIndividualsNetworkArgs = {
  _inc?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Set_Input>;
  where: Rtcs_Db_Rmatch_NonIndividualsNetwork_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_NonindividualOwnersArgs = {
  _inc?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Set_Input>;
  where: Rtcs_Db_Rmatch_NonindividualOwners_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_NonindividualsArgs = {
  _set?: Maybe<Rtcs_Db_Rmatch_Nonindividuals_Set_Input>;
  where: Rtcs_Db_Rmatch_Nonindividuals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_PropertiesCompositeArgs = {
  _inc?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Set_Input>;
  where: Rtcs_Db_Rmatch_PropertiesComposite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_PropertiesNetworkArgs = {
  _inc?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Set_Input>;
  where: Rtcs_Db_Rmatch_PropertiesNetwork_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_PropertyNetworkArgs = {
  _inc?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Set_Input>;
  where: Rtcs_Db_Rmatch_PropertyNetwork_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_RMatchDetailed904Args = {
  _inc?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Set_Input>;
  where: Rtcs_Db_Rmatch_RMatchDetailed904_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_RMatchDetailed916Args = {
  _inc?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Set_Input>;
  where: Rtcs_Db_Rmatch_RMatchDetailed916_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancyArgs =
  {
    _inc?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Set_Input>;
    where: Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_RMatchDetailed937Args = {
  _inc?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Set_Input>;
  where: Rtcs_Db_Rmatch_RMatchDetailed937_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_RMatchKccaPropertiesArgs = {
  _inc?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Set_Input>;
  where: Rtcs_Db_Rmatch_RMatchKccaProperties_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_RMatchKccaPropertyArgs = {
  _inc?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Set_Input>;
  where: Rtcs_Db_Rmatch_RMatchKccaProperty_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_RMatchNonIndividual903Args = {
  _inc?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Set_Input>;
  where: Rtcs_Db_Rmatch_RMatchNonIndividual903_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_RMatchProperties1115Args = {
  _inc?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Set_Input>;
  where: Rtcs_Db_Rmatch_RMatchProperties1115_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_RMatchSummary904Args = {
  _inc?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Set_Input>;
  where: Rtcs_Db_Rmatch_RMatchSummary904_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_RMatchSummary916Args = {
  _inc?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Set_Input>;
  where: Rtcs_Db_Rmatch_RMatchSummary916_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancyArgs =
  {
    _inc?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Set_Input>;
    where: Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_RMatchSummary937Args = {
  _inc?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Set_Input>;
  where: Rtcs_Db_Rmatch_RMatchSummary937_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_TinsUraArgs = {
  _inc?: Maybe<Rtcs_Db_Rmatch_TinsUra_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_TinsUra_Set_Input>;
  where: Rtcs_Db_Rmatch_TinsUra_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_UndeclaredArgs = {
  _inc?: Maybe<Rtcs_Db_Rmatch_Undeclared_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_Undeclared_Set_Input>;
  where: Rtcs_Db_Rmatch_Undeclared_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_UnderreportedArgs = {
  _inc?: Maybe<Rtcs_Db_Rmatch_Underreported_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_Underreported_Set_Input>;
  where: Rtcs_Db_Rmatch_Underreported_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_UnregisteredArgs = {
  _inc?: Maybe<Rtcs_Db_Rmatch_Unregistered_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_Unregistered_Set_Input>;
  where: Rtcs_Db_Rmatch_Unregistered_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Rmatch_MlhudConsolidatedArgs = {
  _inc?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Set_Input>;
  where: Rtcs_Db_Rmatch_MlhudConsolidated_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Streetmap_OsmMukonoMunicipalityArgs = {
  _inc?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Set_Input>;
  where: Rtcs_Db_Streetmap_OsmMukonoMunicipality_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Streetmap_OsmMukonoNakifumaArgs = {
  _inc?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Set_Input>;
  where: Rtcs_Db_Streetmap_OsmMukonoNakifuma_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Streetmap_OsmWakisoEntebbeArgs = {
  _inc?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Set_Input>;
  where: Rtcs_Db_Streetmap_OsmWakisoEntebbe_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Streetmap_OsmWakisoKiraArgs = {
  _inc?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Set_Input>;
  where: Rtcs_Db_Streetmap_OsmWakisoKira_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagaboArgs =
  {
    _inc?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Set_Input>;
    where: Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Streetmap_OsmWakisoNansanaArgs = {
  _inc?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Set_Input>;
  where: Rtcs_Db_Streetmap_OsmWakisoNansana_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ucc_UccArgs = {
  _inc?: Maybe<Rtcs_Db_Ucc_Ucc_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ucc_Ucc_Set_Input>;
  where: Rtcs_Db_Ucc_Ucc_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Umeme_SubscriberAndPropertyArgs = {
  _inc?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Set_Input>;
  where: Rtcs_Db_Umeme_SubscriberAndProperty_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Umeme_TransactionsArgs = {
  _inc?: Maybe<Rtcs_Db_Umeme_Transactions_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Umeme_Transactions_Set_Input>;
  where: Rtcs_Db_Umeme_Transactions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_BalanceSheetInformationArgs = {
  _inc?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Set_Input>;
  where: Rtcs_Db_Ura_BalanceSheetInformation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_CombinedOfficersAndOwnersArgs = {
  _inc?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Set_Input>;
  where: Rtcs_Db_Ura_CombinedOfficersAndOwners_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_EmploymentIncomeDetailArgs = {
  _inc?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Set_Input>;
  where: Rtcs_Db_Ura_EmploymentIncomeDetail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootageArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Set_Input>;
    where: Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_GazetteResidentialRatesPerBedroomArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Set_Input>;
    where: Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_HoldingOfficersArgs = {
  _inc?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Set_Input>;
  where: Rtcs_Db_Ura_HoldingOfficers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_HoldingOwnerArgs = {
  _inc?: Maybe<Rtcs_Db_Ura_HoldingOwner_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ura_HoldingOwner_Set_Input>;
  where: Rtcs_Db_Ura_HoldingOwner_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_IncomeTaxHoldingArgs = {
  _inc?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Set_Input>;
  where: Rtcs_Db_Ura_IncomeTaxHolding_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_IncomeTaxSummaryIndividualArgs = {
  _inc?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Set_Input>;
  where: Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_IncomeTaxSummaryNonIndividualArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Set_Input>;
    where: Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_IndividualMortgageArgs = {
  _inc?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Set_Input>;
  where: Rtcs_Db_Ura_IndividualMortgage_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_IndividualsAssociatedBusinessArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Set_Input>;
    where: Rtcs_Db_Ura_IndividualsAssociatedBusiness_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_LandlordIncomeDetailArgs = {
  _inc?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Set_Input>;
  where: Rtcs_Db_Ura_LandlordIncomeDetail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_LandlordIncomeSummaryArgs = {
  _inc?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Set_Input>;
  where: Rtcs_Db_Ura_LandlordIncomeSummary_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_NonIndividualsAssociatedBusinessArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Set_Input>;
    where: Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_RegAssociatesArgs = {
  _inc?: Maybe<Rtcs_Db_Ura_RegAssociates_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ura_RegAssociates_Set_Input>;
  where: Rtcs_Db_Ura_RegAssociates_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_RegIndividualArgs = {
  _inc?: Maybe<Rtcs_Db_Ura_RegIndividual_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ura_RegIndividual_Set_Input>;
  where: Rtcs_Db_Ura_RegIndividual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_RegIndividual_SidArgs = {
  _inc?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Set_Input>;
  where: Rtcs_Db_Ura_RegIndividual_Sid_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_RegNonIndividualArgs = {
  _inc?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Set_Input>;
  where: Rtcs_Db_Ura_RegNonIndividual_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_RegNonIndividual_SidArgs = {
  _inc?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Set_Input>;
  where: Rtcs_Db_Ura_RegNonIndividual_Sid_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_RentExpenseDetailsArgs = {
  _inc?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Set_Input>;
  where: Rtcs_Db_Ura_RentExpenseDetails_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_RentalLandLordIncomeArgs = {
  _inc?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Set_Input>;
  where: Rtcs_Db_Ura_RentalLandLordIncome_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_RentalTenantPaymentsArgs = {
  _inc?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Set_Input>;
  where: Rtcs_Db_Ura_RentalTenantPayments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_RipoIndRentalIncSummaryArgs = {
  _inc?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Set_Input>;
  where: Rtcs_Db_Ura_RipoIndRentalIncSummary_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_TenantIncomeDetailArgs = {
  _inc?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Set_Input>;
  where: Rtcs_Db_Ura_TenantIncomeDetail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ura_TownArgs = {
  _inc?: Maybe<Rtcs_Db_Ura_Town_Inc_Input>;
  _set?: Maybe<Rtcs_Db_Ura_Town_Set_Input>;
  where: Rtcs_Db_Ura_Town_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsbArgs =
  {
    _inc?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Inc_Input>;
    _set?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Set_Input>;
    where: Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp;
  };

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = "asc",
  /** in the ascending order, nulls first */
  AscNullsFirst = "asc_nulls_first",
  /** in the ascending order, nulls last */
  AscNullsLast = "asc_nulls_last",
  /** in the descending order, nulls first */
  Desc = "desc",
  /** in the descending order, nulls first */
  DescNullsFirst = "desc_nulls_first",
  /** in the descending order, nulls last */
  DescNullsLast = "desc_nulls_last",
}

/** query root */
export type Query_Root = {
  __typename?: "query_root";
  /** fetch data from the table: "rtcs_db.EntityFilter" */
  rtcs_db_EntityFilter: Array<Rtcs_Db_EntityFilter>;
  /** fetch aggregated fields from the table: "rtcs_db.EntityFilter" */
  rtcs_db_EntityFilter_aggregate: Rtcs_Db_EntityFilter_Aggregate;
  /** fetch data from the table: "rtcs_db.Filter" */
  rtcs_db_Filter: Array<Rtcs_Db_Filter>;
  /** fetch aggregated fields from the table: "rtcs_db.Filter" */
  rtcs_db_Filter_aggregate: Rtcs_Db_Filter_Aggregate;
  /** execute function "rtcs_db.FnEntityFilter" which returns "rtcs_db.PH_EntityFilter" */
  rtcs_db_FnEntityFilter: Array<Rtcs_Db_Ph_EntityFilter>;
  /** execute function "rtcs_db.FnEntityFilter" and query aggregates on result of table type "rtcs_db.PH_EntityFilter" */
  rtcs_db_FnEntityFilter_aggregate: Rtcs_Db_Ph_EntityFilter_Aggregate;
  /** execute function "rtcs_db.FnEntityFilter_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnEntityFilter_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnEntityFilter_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnEntityFilter_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnEntityFilter_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnEntityFilter_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnEntityFilter_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnEntityFilter_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnFilter" which returns "rtcs_db.PH_Filter" */
  rtcs_db_FnFilter: Array<Rtcs_Db_Ph_Filter>;
  /** execute function "rtcs_db.FnFilter" and query aggregates on result of table type "rtcs_db.PH_Filter" */
  rtcs_db_FnFilter_aggregate: Rtcs_Db_Ph_Filter_Aggregate;
  /** execute function "rtcs_db.FnFilter_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnFilter_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnFilter_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnFilter_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnFilter_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnFilter_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnFilter_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnFilter_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnGazzetteRates" which returns "rtcs_db.PH_GazzetteRates" */
  rtcs_db_FnGazzetteRates: Array<Rtcs_Db_Ph_GazzetteRates>;
  /** execute function "rtcs_db.FnGazzetteRates" and query aggregates on result of table type "rtcs_db.PH_GazzetteRates" */
  rtcs_db_FnGazzetteRates_aggregate: Rtcs_Db_Ph_GazzetteRates_Aggregate;
  /** execute function "rtcs_db.FnGazzetteRates_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnGazzetteRates_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnGazzetteRates_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnGazzetteRates_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnGazzetteRates_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnGazzetteRates_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnGazzetteRates_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnGazzetteRates_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_IndividualComplianceSummaryPlusRisk" which returns "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
  rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk: Array<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk>;
  /** execute function "rtcs_db.FnRPT_IndividualComplianceSummaryPlusRisk" and query aggregates on result of table type "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
  rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk_aggregate: Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Aggregate;
  /** execute function "rtcs_db.FnRPT_IndividualComplianceSummaryPlusRisk_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_IndividualComplianceSummaryPlusRisk_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_IndividualComplianceSummaryPlusRisk_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_IndividualComplianceSummaryPlusRisk_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_IndividualComposite" which returns "rtcs_db.PH_RPT_IndividualComposite" */
  rtcs_db_FnRPT_IndividualComposite: Array<Rtcs_Db_Ph_Rpt_IndividualComposite>;
  /** execute function "rtcs_db.FnRPT_IndividualComposite" and query aggregates on result of table type "rtcs_db.PH_RPT_IndividualComposite" */
  rtcs_db_FnRPT_IndividualComposite_aggregate: Rtcs_Db_Ph_Rpt_IndividualComposite_Aggregate;
  /** execute function "rtcs_db.FnRPT_IndividualComposite_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_IndividualComposite_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_IndividualComposite_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_IndividualComposite_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_IndividualComposite_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_IndividualComposite_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_IndividualComposite_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_IndividualComposite_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_IndividualRegistration" which returns "rtcs_db.PH_RPT_IndividualRegistration" */
  rtcs_db_FnRPT_IndividualRegistration: Array<Rtcs_Db_Ph_Rpt_IndividualRegistration>;
  /** execute function "rtcs_db.FnRPT_IndividualRegistration" and query aggregates on result of table type "rtcs_db.PH_RPT_IndividualRegistration" */
  rtcs_db_FnRPT_IndividualRegistration_aggregate: Rtcs_Db_Ph_Rpt_IndividualRegistration_Aggregate;
  /** execute function "rtcs_db.FnRPT_IndividualRegistration_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_IndividualRegistration_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_IndividualRegistration_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_IndividualRegistration_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_IndividualRegistration_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_IndividualRegistration_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_IndividualRegistration_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_IndividualRegistration_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_IndividualTaxSummaryData" which returns "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
  rtcs_db_FnRPT_IndividualTaxSummaryData: Array<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData>;
  /** execute function "rtcs_db.FnRPT_IndividualTaxSummaryData" and query aggregates on result of table type "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
  rtcs_db_FnRPT_IndividualTaxSummaryData_aggregate: Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Aggregate;
  /** execute function "rtcs_db.FnRPT_IndividualTaxSummaryData_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_IndividualTaxSummaryData_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_IndividualTaxSummaryData_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_IndividualTaxSummaryData_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_IndividualTaxSummaryData_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_IndividualTaxSummaryData_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_IndividualTaxSummaryData_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_IndividualTaxSummaryData_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_KccaAffiliates" which returns "rtcs_db.PH_RPT_KccaAffiliates" */
  rtcs_db_FnRPT_KccaAffiliates: Array<Rtcs_Db_Ph_Rpt_KccaAffiliates>;
  /** execute function "rtcs_db.FnRPT_KccaAffiliates" and query aggregates on result of table type "rtcs_db.PH_RPT_KccaAffiliates" */
  rtcs_db_FnRPT_KccaAffiliates_aggregate: Rtcs_Db_Ph_Rpt_KccaAffiliates_Aggregate;
  /** execute function "rtcs_db.FnRPT_KccaAffiliates_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_KccaAffiliates_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_KccaAffiliates_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_KccaAffiliates_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_KccaAffiliates_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_KccaAffiliates_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_KccaAffiliates_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_KccaAffiliates_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_KccaCustomerRegistrationData" which returns "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
  rtcs_db_FnRPT_KccaCustomerRegistrationData: Array<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData>;
  /** execute function "rtcs_db.FnRPT_KccaCustomerRegistrationData" and query aggregates on result of table type "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
  rtcs_db_FnRPT_KccaCustomerRegistrationData_aggregate: Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Aggregate;
  /** execute function "rtcs_db.FnRPT_KccaCustomerRegistrationData_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_KccaCustomerRegistrationData_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_KccaCustomerRegistrationData_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_KccaCustomerRegistrationData_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_KccaCustomerRegistrationData_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_KccaCustomerRegistrationData_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_KccaCustomerRegistrationData_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_KccaCustomerRegistrationData_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_KccaPropertyRegistration" which returns "rtcs_db.PH_RPT_KccaPropertyRegistration" */
  rtcs_db_FnRPT_KccaPropertyRegistration: Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration>;
  /** execute function "rtcs_db.FnRPT_KccaPropertyRegistrationData" which returns "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
  rtcs_db_FnRPT_KccaPropertyRegistrationData: Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData>;
  /** execute function "rtcs_db.FnRPT_KccaPropertyRegistrationData" and query aggregates on result of table type "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
  rtcs_db_FnRPT_KccaPropertyRegistrationData_aggregate: Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Aggregate;
  /** execute function "rtcs_db.FnRPT_KccaPropertyRegistrationData_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_KccaPropertyRegistrationData_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_KccaPropertyRegistrationData_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_KccaPropertyRegistrationData_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_KccaPropertyRegistrationData_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_KccaPropertyRegistrationData_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_KccaPropertyRegistrationData_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_KccaPropertyRegistrationData_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_KccaPropertyRegistration" and query aggregates on result of table type "rtcs_db.PH_RPT_KccaPropertyRegistration" */
  rtcs_db_FnRPT_KccaPropertyRegistration_aggregate: Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Aggregate;
  /** execute function "rtcs_db.FnRPT_KccaPropertyRegistration_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_KccaPropertyRegistration_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_KccaPropertyRegistration_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_KccaPropertyRegistration_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_KccaPropertyRegistration_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_KccaPropertyRegistration_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_KccaPropertyRegistration_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_KccaPropertyRegistration_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_NonIndividualBalanceSheet" which returns "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
  rtcs_db_FnRPT_NonIndividualBalanceSheet: Array<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet>;
  /** execute function "rtcs_db.FnRPT_NonIndividualBalanceSheet" and query aggregates on result of table type "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
  rtcs_db_FnRPT_NonIndividualBalanceSheet_aggregate: Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Aggregate;
  /** execute function "rtcs_db.FnRPT_NonIndividualBalanceSheet_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_NonIndividualBalanceSheet_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_NonIndividualBalanceSheet_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_NonIndividualBalanceSheet_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_NonIndividualBalanceSheet_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_NonIndividualBalanceSheet_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_NonIndividualBalanceSheet_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_NonIndividualBalanceSheet_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_NonIndividualComplianceSummaryPlusRisk" which returns "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
  rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk: Array<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk>;
  /** execute function "rtcs_db.FnRPT_NonIndividualComplianceSummaryPlusRisk" and query aggregates on result of table type "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
  rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk_aggregate: Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Aggregate;
  /** execute function "rtcs_db.FnRPT_NonIndividualComplianceSummaryPlusRisk_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_NonIndividualComplianceSummaryPlusRisk_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_NonIndividualComplianceSummaryPlusRisk_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_NonIndividualComplianceSummaryPlusRisk_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_NonIndividualComposite" which returns "rtcs_db.PH_RPT_NonIndividualComposite" */
  rtcs_db_FnRPT_NonIndividualComposite: Array<Rtcs_Db_Ph_Rpt_NonIndividualComposite>;
  /** execute function "rtcs_db.FnRPT_NonIndividualComposite" and query aggregates on result of table type "rtcs_db.PH_RPT_NonIndividualComposite" */
  rtcs_db_FnRPT_NonIndividualComposite_aggregate: Rtcs_Db_Ph_Rpt_NonIndividualComposite_Aggregate;
  /** execute function "rtcs_db.FnRPT_NonIndividualComposite_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_NonIndividualComposite_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_NonIndividualComposite_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_NonIndividualComposite_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_NonIndividualComposite_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_NonIndividualComposite_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_NonIndividualComposite_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_NonIndividualComposite_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_NonIndividualTaxSummaryData" which returns "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
  rtcs_db_FnRPT_NonIndividualTaxSummaryData: Array<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData>;
  /** execute function "rtcs_db.FnRPT_NonIndividualTaxSummaryData" and query aggregates on result of table type "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
  rtcs_db_FnRPT_NonIndividualTaxSummaryData_aggregate: Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Aggregate;
  /** execute function "rtcs_db.FnRPT_NonIndividualTaxSummaryData_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_NonIndividualTaxSummaryData_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_NonIndividualTaxSummaryData_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_NonIndividualTaxSummaryData_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_NonIndividualTaxSummaryData_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_NonIndividualTaxSummaryData_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_NonIndividualTaxSummaryData_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_NonIndividualTaxSummaryData_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_PropertiesComposite" which returns "rtcs_db.PH_RPT_PropertiesComposite" */
  rtcs_db_FnRPT_PropertiesComposite: Array<Rtcs_Db_Ph_Rpt_PropertiesComposite>;
  /** execute function "rtcs_db.FnRPT_PropertiesComposite" and query aggregates on result of table type "rtcs_db.PH_RPT_PropertiesComposite" */
  rtcs_db_FnRPT_PropertiesComposite_aggregate: Rtcs_Db_Ph_Rpt_PropertiesComposite_Aggregate;
  /** execute function "rtcs_db.FnRPT_PropertiesComposite_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_PropertiesComposite_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_PropertiesComposite_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_PropertiesComposite_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_PropertiesComposite_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_PropertiesComposite_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_PropertiesComposite_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_PropertiesComposite_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_PropertyValuation" which returns "rtcs_db.PH_RPT_PropertyValuation" */
  rtcs_db_FnRPT_PropertyValuation: Array<Rtcs_Db_Ph_Rpt_PropertyValuation>;
  /** execute function "rtcs_db.FnRPT_PropertyValuation" and query aggregates on result of table type "rtcs_db.PH_RPT_PropertyValuation" */
  rtcs_db_FnRPT_PropertyValuation_aggregate: Rtcs_Db_Ph_Rpt_PropertyValuation_Aggregate;
  /** execute function "rtcs_db.FnRPT_PropertyValuation_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_PropertyValuation_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_PropertyValuation_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_PropertyValuation_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_PropertyValuation_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_PropertyValuation_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_PropertyValuation_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_PropertyValuation_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_RentalTenantPayments" which returns "rtcs_db.PH_RPT_RentalTenantPayments" */
  rtcs_db_FnRPT_RentalTenantPayments: Array<Rtcs_Db_Ph_Rpt_RentalTenantPayments>;
  /** execute function "rtcs_db.FnRPT_RentalTenantPayments" and query aggregates on result of table type "rtcs_db.PH_RPT_RentalTenantPayments" */
  rtcs_db_FnRPT_RentalTenantPayments_aggregate: Rtcs_Db_Ph_Rpt_RentalTenantPayments_Aggregate;
  /** execute function "rtcs_db.FnRPT_RentalTenantPayments_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_RentalTenantPayments_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_RentalTenantPayments_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_RentalTenantPayments_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_RentalTenantPayments_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_RentalTenantPayments_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_RentalTenantPayments_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_RentalTenantPayments_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fngazette_CommercialRates" which returns "rtcs_db.PH_gazette_CommercialRates" */
  rtcs_db_Fngazette_CommercialRates: Array<Rtcs_Db_Ph_Gazette_CommercialRates>;
  /** execute function "rtcs_db.Fngazette_CommercialRates" and query aggregates on result of table type "rtcs_db.PH_gazette_CommercialRates" */
  rtcs_db_Fngazette_CommercialRates_aggregate: Rtcs_Db_Ph_Gazette_CommercialRates_Aggregate;
  /** execute function "rtcs_db.Fngazette_CommercialRates_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fngazette_CommercialRates_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fngazette_CommercialRates_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fngazette_CommercialRates_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fngazette_CommercialRates_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fngazette_CommercialRates_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fngazette_CommercialRates_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fngazette_CommercialRates_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fngazette_ResidentialRates" which returns "rtcs_db.PH_gazette_ResidentialRates" */
  rtcs_db_Fngazette_ResidentialRates: Array<Rtcs_Db_Ph_Gazette_ResidentialRates>;
  /** execute function "rtcs_db.Fngazette_ResidentialRates" and query aggregates on result of table type "rtcs_db.PH_gazette_ResidentialRates" */
  rtcs_db_Fngazette_ResidentialRates_aggregate: Rtcs_Db_Ph_Gazette_ResidentialRates_Aggregate;
  /** execute function "rtcs_db.Fngazette_ResidentialRates_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fngazette_ResidentialRates_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fngazette_ResidentialRates_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fngazette_ResidentialRates_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fngazette_ResidentialRates_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fngazette_ResidentialRates_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fngazette_ResidentialRates_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fngazette_ResidentialRates_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_AssociateType" which returns "rtcs_db.PH_kcca_AssociateType" */
  rtcs_db_Fnkcca_AssociateType: Array<Rtcs_Db_Ph_Kcca_AssociateType>;
  /** execute function "rtcs_db.Fnkcca_AssociateType" and query aggregates on result of table type "rtcs_db.PH_kcca_AssociateType" */
  rtcs_db_Fnkcca_AssociateType_aggregate: Rtcs_Db_Ph_Kcca_AssociateType_Aggregate;
  /** execute function "rtcs_db.Fnkcca_AssociateType_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_AssociateType_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_AssociateType_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_AssociateType_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_AssociateType_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_AssociateType_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_AssociateType_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_AssociateType_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CommercialProperties" which returns "rtcs_db.PH_kcca_CommercialProperties" */
  rtcs_db_Fnkcca_CommercialProperties: Array<Rtcs_Db_Ph_Kcca_CommercialProperties>;
  /** execute function "rtcs_db.Fnkcca_CommercialProperties" and query aggregates on result of table type "rtcs_db.PH_kcca_CommercialProperties" */
  rtcs_db_Fnkcca_CommercialProperties_aggregate: Rtcs_Db_Ph_Kcca_CommercialProperties_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CommercialProperties_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CommercialProperties_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_CommercialProperties_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CommercialProperties_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CommercialProperties_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CommercialProperties_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_CommercialProperties_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CommercialProperties_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_County" which returns "rtcs_db.PH_kcca_County" */
  rtcs_db_Fnkcca_County: Array<Rtcs_Db_Ph_Kcca_County>;
  /** execute function "rtcs_db.Fnkcca_County" and query aggregates on result of table type "rtcs_db.PH_kcca_County" */
  rtcs_db_Fnkcca_County_aggregate: Rtcs_Db_Ph_Kcca_County_Aggregate;
  /** execute function "rtcs_db.Fnkcca_County_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_County_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_County_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_County_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_County_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_County_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_County_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_County_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Customer" which returns "rtcs_db.PH_kcca_Customer" */
  rtcs_db_Fnkcca_Customer: Array<Rtcs_Db_Ph_Kcca_Customer>;
  /** execute function "rtcs_db.Fnkcca_CustomerApplicantType" which returns "rtcs_db.PH_kcca_CustomerApplicantType" */
  rtcs_db_Fnkcca_CustomerApplicantType: Array<Rtcs_Db_Ph_Kcca_CustomerApplicantType>;
  /** execute function "rtcs_db.Fnkcca_CustomerApplicantType" and query aggregates on result of table type "rtcs_db.PH_kcca_CustomerApplicantType" */
  rtcs_db_Fnkcca_CustomerApplicantType_aggregate: Rtcs_Db_Ph_Kcca_CustomerApplicantType_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerApplicantType_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerApplicantType_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_CustomerApplicantType_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerApplicantType_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerApplicantType_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerApplicantType_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_CustomerApplicantType_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerApplicantType_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerBusinessAssociate" which returns "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
  rtcs_db_Fnkcca_CustomerBusinessAssociate: Array<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate>;
  /** execute function "rtcs_db.Fnkcca_CustomerBusinessAssociate" and query aggregates on result of table type "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
  rtcs_db_Fnkcca_CustomerBusinessAssociate_aggregate: Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerBusinessAssociate_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerBusinessAssociate_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_CustomerBusinessAssociate_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerBusinessAssociate_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerBusinessAssociate_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerBusinessAssociate_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_CustomerBusinessAssociate_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerBusinessAssociate_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerBusinessType" which returns "rtcs_db.PH_kcca_CustomerBusinessType" */
  rtcs_db_Fnkcca_CustomerBusinessType: Array<Rtcs_Db_Ph_Kcca_CustomerBusinessType>;
  /** execute function "rtcs_db.Fnkcca_CustomerBusinessType" and query aggregates on result of table type "rtcs_db.PH_kcca_CustomerBusinessType" */
  rtcs_db_Fnkcca_CustomerBusinessType_aggregate: Rtcs_Db_Ph_Kcca_CustomerBusinessType_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerBusinessType_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerBusinessType_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_CustomerBusinessType_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerBusinessType_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerBusinessType_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerBusinessType_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_CustomerBusinessType_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerBusinessType_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerCoinDeactivateReactive" which returns "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
  rtcs_db_Fnkcca_CustomerCoinDeactivateReactive: Array<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive>;
  /** execute function "rtcs_db.Fnkcca_CustomerCoinDeactivateReactive" and query aggregates on result of table type "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
  rtcs_db_Fnkcca_CustomerCoinDeactivateReactive_aggregate: Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerCoinDeactivateReactive_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerCoinDeactivateReactive_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_CustomerCoinDeactivateReactive_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerCoinDeactivateReactive_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerCoinDeactivateReactive_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerCoinDeactivateReactive_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_CustomerCoinDeactivateReactive_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerCoinDeactivateReactive_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerContactPerson" which returns "rtcs_db.PH_kcca_CustomerContactPerson" */
  rtcs_db_Fnkcca_CustomerContactPerson: Array<Rtcs_Db_Ph_Kcca_CustomerContactPerson>;
  /** execute function "rtcs_db.Fnkcca_CustomerContactPerson" and query aggregates on result of table type "rtcs_db.PH_kcca_CustomerContactPerson" */
  rtcs_db_Fnkcca_CustomerContactPerson_aggregate: Rtcs_Db_Ph_Kcca_CustomerContactPerson_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerContactPerson_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerContactPerson_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_CustomerContactPerson_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerContactPerson_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerContactPerson_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerContactPerson_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_CustomerContactPerson_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerContactPerson_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerDocumentItem" which returns "rtcs_db.PH_kcca_CustomerDocumentItem" */
  rtcs_db_Fnkcca_CustomerDocumentItem: Array<Rtcs_Db_Ph_Kcca_CustomerDocumentItem>;
  /** execute function "rtcs_db.Fnkcca_CustomerDocumentItem" and query aggregates on result of table type "rtcs_db.PH_kcca_CustomerDocumentItem" */
  rtcs_db_Fnkcca_CustomerDocumentItem_aggregate: Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerDocumentItem_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerDocumentItem_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_CustomerDocumentItem_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerDocumentItem_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerDocumentItem_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerDocumentItem_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_CustomerDocumentItem_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerDocumentItem_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerGuardian" which returns "rtcs_db.PH_kcca_CustomerGuardian" */
  rtcs_db_Fnkcca_CustomerGuardian: Array<Rtcs_Db_Ph_Kcca_CustomerGuardian>;
  /** execute function "rtcs_db.Fnkcca_CustomerGuardian" and query aggregates on result of table type "rtcs_db.PH_kcca_CustomerGuardian" */
  rtcs_db_Fnkcca_CustomerGuardian_aggregate: Rtcs_Db_Ph_Kcca_CustomerGuardian_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerGuardian_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerGuardian_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_CustomerGuardian_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerGuardian_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerGuardian_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerGuardian_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_CustomerGuardian_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerGuardian_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerReference" which returns "rtcs_db.PH_kcca_CustomerReference" */
  rtcs_db_Fnkcca_CustomerReference: Array<Rtcs_Db_Ph_Kcca_CustomerReference>;
  /** execute function "rtcs_db.Fnkcca_CustomerReference" and query aggregates on result of table type "rtcs_db.PH_kcca_CustomerReference" */
  rtcs_db_Fnkcca_CustomerReference_aggregate: Rtcs_Db_Ph_Kcca_CustomerReference_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerReference_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerReference_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_CustomerReference_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerReference_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerReference_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerReference_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_CustomerReference_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerReference_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerRegistrationReason" which returns "rtcs_db.PH_kcca_CustomerRegistrationReason" */
  rtcs_db_Fnkcca_CustomerRegistrationReason: Array<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason>;
  /** execute function "rtcs_db.Fnkcca_CustomerRegistrationReason" and query aggregates on result of table type "rtcs_db.PH_kcca_CustomerRegistrationReason" */
  rtcs_db_Fnkcca_CustomerRegistrationReason_aggregate: Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerRegistrationReason_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerRegistrationReason_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_CustomerRegistrationReason_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerRegistrationReason_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerRegistrationReason_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerRegistrationReason_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_CustomerRegistrationReason_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerRegistrationReason_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerRevenueAgent" which returns "rtcs_db.PH_kcca_CustomerRevenueAgent" */
  rtcs_db_Fnkcca_CustomerRevenueAgent: Array<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent>;
  /** execute function "rtcs_db.Fnkcca_CustomerRevenueAgent" and query aggregates on result of table type "rtcs_db.PH_kcca_CustomerRevenueAgent" */
  rtcs_db_Fnkcca_CustomerRevenueAgent_aggregate: Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerRevenueAgent_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerRevenueAgent_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_CustomerRevenueAgent_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerRevenueAgent_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerRevenueAgent_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerRevenueAgent_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_CustomerRevenueAgent_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerRevenueAgent_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerType" which returns "rtcs_db.PH_kcca_CustomerType" */
  rtcs_db_Fnkcca_CustomerType: Array<Rtcs_Db_Ph_Kcca_CustomerType>;
  /** execute function "rtcs_db.Fnkcca_CustomerType" and query aggregates on result of table type "rtcs_db.PH_kcca_CustomerType" */
  rtcs_db_Fnkcca_CustomerType_aggregate: Rtcs_Db_Ph_Kcca_CustomerType_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerType_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerType_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_CustomerType_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerType_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerType_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerType_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_CustomerType_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerType_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Customer" and query aggregates on result of table type "rtcs_db.PH_kcca_Customer" */
  rtcs_db_Fnkcca_Customer_aggregate: Rtcs_Db_Ph_Kcca_Customer_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Customer_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Customer_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_Customer_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Customer_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Customer_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Customer_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_Customer_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Customer_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Customer_sid" which returns "rtcs_db.PH_kcca_Customer_sid" */
  rtcs_db_Fnkcca_Customer_sid: Array<Rtcs_Db_Ph_Kcca_Customer_Sid>;
  /** execute function "rtcs_db.Fnkcca_Customer_sid" and query aggregates on result of table type "rtcs_db.PH_kcca_Customer_sid" */
  rtcs_db_Fnkcca_Customer_sid_aggregate: Rtcs_Db_Ph_Kcca_Customer_Sid_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Customer_sid_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Customer_sid_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_Customer_sid_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Customer_sid_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Customer_sid_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Customer_sid_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_Customer_sid_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Customer_sid_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_District" which returns "rtcs_db.PH_kcca_District" */
  rtcs_db_Fnkcca_District: Array<Rtcs_Db_Ph_Kcca_District>;
  /** execute function "rtcs_db.Fnkcca_District" and query aggregates on result of table type "rtcs_db.PH_kcca_District" */
  rtcs_db_Fnkcca_District_aggregate: Rtcs_Db_Ph_Kcca_District_Aggregate;
  /** execute function "rtcs_db.Fnkcca_District_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_District_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_District_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_District_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_District_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_District_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_District_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_District_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Division" which returns "rtcs_db.PH_kcca_Division" */
  rtcs_db_Fnkcca_Division: Array<Rtcs_Db_Ph_Kcca_Division>;
  /** execute function "rtcs_db.Fnkcca_Division" and query aggregates on result of table type "rtcs_db.PH_kcca_Division" */
  rtcs_db_Fnkcca_Division_aggregate: Rtcs_Db_Ph_Kcca_Division_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Division_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Division_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_Division_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Division_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Division_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Division_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_Division_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Division_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_ImgsData" which returns "rtcs_db.PH_kcca_ImgsData" */
  rtcs_db_Fnkcca_ImgsData: Array<Rtcs_Db_Ph_Kcca_ImgsData>;
  /** execute function "rtcs_db.Fnkcca_ImgsData" and query aggregates on result of table type "rtcs_db.PH_kcca_ImgsData" */
  rtcs_db_Fnkcca_ImgsData_aggregate: Rtcs_Db_Ph_Kcca_ImgsData_Aggregate;
  /** execute function "rtcs_db.Fnkcca_ImgsData_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_ImgsData_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_ImgsData_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_ImgsData_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_ImgsData_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_ImgsData_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_ImgsData_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_ImgsData_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Parish" which returns "rtcs_db.PH_kcca_Parish" */
  rtcs_db_Fnkcca_Parish: Array<Rtcs_Db_Ph_Kcca_Parish>;
  /** execute function "rtcs_db.Fnkcca_Parish" and query aggregates on result of table type "rtcs_db.PH_kcca_Parish" */
  rtcs_db_Fnkcca_Parish_aggregate: Rtcs_Db_Ph_Kcca_Parish_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Parish_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Parish_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_Parish_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Parish_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Parish_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Parish_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_Parish_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Parish_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PaymentDetails" which returns "rtcs_db.PH_kcca_PaymentDetails" */
  rtcs_db_Fnkcca_PaymentDetails: Array<Rtcs_Db_Ph_Kcca_PaymentDetails>;
  /** execute function "rtcs_db.Fnkcca_PaymentDetails" and query aggregates on result of table type "rtcs_db.PH_kcca_PaymentDetails" */
  rtcs_db_Fnkcca_PaymentDetails_aggregate: Rtcs_Db_Ph_Kcca_PaymentDetails_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PaymentDetails_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PaymentDetails_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_PaymentDetails_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PaymentDetails_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PaymentDetails_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PaymentDetails_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_PaymentDetails_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PaymentDetails_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Payments" which returns "rtcs_db.PH_kcca_Payments" */
  rtcs_db_Fnkcca_Payments: Array<Rtcs_Db_Ph_Kcca_Payments>;
  /** execute function "rtcs_db.Fnkcca_Payments" and query aggregates on result of table type "rtcs_db.PH_kcca_Payments" */
  rtcs_db_Fnkcca_Payments_aggregate: Rtcs_Db_Ph_Kcca_Payments_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Payments_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Payments_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_Payments_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Payments_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Payments_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Payments_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_Payments_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Payments_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Property" which returns "rtcs_db.PH_kcca_Property" */
  rtcs_db_Fnkcca_Property: Array<Rtcs_Db_Ph_Kcca_Property>;
  /** execute function "rtcs_db.Fnkcca_PropertyFloors" which returns "rtcs_db.PH_kcca_PropertyFloors" */
  rtcs_db_Fnkcca_PropertyFloors: Array<Rtcs_Db_Ph_Kcca_PropertyFloors>;
  /** execute function "rtcs_db.Fnkcca_PropertyFloors" and query aggregates on result of table type "rtcs_db.PH_kcca_PropertyFloors" */
  rtcs_db_Fnkcca_PropertyFloors_aggregate: Rtcs_Db_Ph_Kcca_PropertyFloors_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertyFloors_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PropertyFloors_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_PropertyFloors_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PropertyFloors_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertyFloors_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PropertyFloors_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_PropertyFloors_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PropertyFloors_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertyNumberBedrooms" which returns "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
  rtcs_db_Fnkcca_PropertyNumberBedrooms: Array<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms>;
  /** execute function "rtcs_db.Fnkcca_PropertyNumberBedrooms" and query aggregates on result of table type "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
  rtcs_db_Fnkcca_PropertyNumberBedrooms_aggregate: Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertyNumberBedrooms_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PropertyNumberBedrooms_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_PropertyNumberBedrooms_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PropertyNumberBedrooms_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertyNumberBedrooms_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PropertyNumberBedrooms_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_PropertyNumberBedrooms_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PropertyNumberBedrooms_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertyRentedStatus" which returns "rtcs_db.PH_kcca_PropertyRentedStatus" */
  rtcs_db_Fnkcca_PropertyRentedStatus: Array<Rtcs_Db_Ph_Kcca_PropertyRentedStatus>;
  /** execute function "rtcs_db.Fnkcca_PropertyRentedStatus" and query aggregates on result of table type "rtcs_db.PH_kcca_PropertyRentedStatus" */
  rtcs_db_Fnkcca_PropertyRentedStatus_aggregate: Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertyRentedStatus_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PropertyRentedStatus_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_PropertyRentedStatus_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PropertyRentedStatus_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertyRentedStatus_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PropertyRentedStatus_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_PropertyRentedStatus_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PropertyRentedStatus_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertySecurity" which returns "rtcs_db.PH_kcca_PropertySecurity" */
  rtcs_db_Fnkcca_PropertySecurity: Array<Rtcs_Db_Ph_Kcca_PropertySecurity>;
  /** execute function "rtcs_db.Fnkcca_PropertySecurity" and query aggregates on result of table type "rtcs_db.PH_kcca_PropertySecurity" */
  rtcs_db_Fnkcca_PropertySecurity_aggregate: Rtcs_Db_Ph_Kcca_PropertySecurity_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertySecurity_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PropertySecurity_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_PropertySecurity_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PropertySecurity_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertySecurity_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PropertySecurity_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_PropertySecurity_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PropertySecurity_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertySubType" which returns "rtcs_db.PH_kcca_PropertySubType" */
  rtcs_db_Fnkcca_PropertySubType: Array<Rtcs_Db_Ph_Kcca_PropertySubType>;
  /** execute function "rtcs_db.Fnkcca_PropertySubType" and query aggregates on result of table type "rtcs_db.PH_kcca_PropertySubType" */
  rtcs_db_Fnkcca_PropertySubType_aggregate: Rtcs_Db_Ph_Kcca_PropertySubType_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertySubType_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PropertySubType_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_PropertySubType_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PropertySubType_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertySubType_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PropertySubType_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_PropertySubType_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PropertySubType_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertyType" which returns "rtcs_db.PH_kcca_PropertyType" */
  rtcs_db_Fnkcca_PropertyType: Array<Rtcs_Db_Ph_Kcca_PropertyType>;
  /** execute function "rtcs_db.Fnkcca_PropertyType" and query aggregates on result of table type "rtcs_db.PH_kcca_PropertyType" */
  rtcs_db_Fnkcca_PropertyType_aggregate: Rtcs_Db_Ph_Kcca_PropertyType_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertyType_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PropertyType_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_PropertyType_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PropertyType_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertyType_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PropertyType_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_PropertyType_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PropertyType_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Property" and query aggregates on result of table type "rtcs_db.PH_kcca_Property" */
  rtcs_db_Fnkcca_Property_aggregate: Rtcs_Db_Ph_Kcca_Property_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Property_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Property_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_Property_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Property_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Property_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Property_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_Property_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Property_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_RawProperties1" which returns "rtcs_db.PH_kcca_RawProperties1" */
  rtcs_db_Fnkcca_RawProperties1: Array<Rtcs_Db_Ph_Kcca_RawProperties1>;
  /** execute function "rtcs_db.Fnkcca_RawProperties1" and query aggregates on result of table type "rtcs_db.PH_kcca_RawProperties1" */
  rtcs_db_Fnkcca_RawProperties1_aggregate: Rtcs_Db_Ph_Kcca_RawProperties1_Aggregate;
  /** execute function "rtcs_db.Fnkcca_RawProperties1_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_RawProperties1_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_RawProperties1_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_RawProperties1_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_RawProperties1_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_RawProperties1_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_RawProperties1_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_RawProperties1_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_RawProperties2" which returns "rtcs_db.PH_kcca_RawProperties2" */
  rtcs_db_Fnkcca_RawProperties2: Array<Rtcs_Db_Ph_Kcca_RawProperties2>;
  /** execute function "rtcs_db.Fnkcca_RawProperties2" and query aggregates on result of table type "rtcs_db.PH_kcca_RawProperties2" */
  rtcs_db_Fnkcca_RawProperties2_aggregate: Rtcs_Db_Ph_Kcca_RawProperties2_Aggregate;
  /** execute function "rtcs_db.Fnkcca_RawProperties2_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_RawProperties2_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_RawProperties2_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_RawProperties2_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_RawProperties2_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_RawProperties2_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_RawProperties2_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_RawProperties2_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_ResidentialProperties" which returns "rtcs_db.PH_kcca_ResidentialProperties" */
  rtcs_db_Fnkcca_ResidentialProperties: Array<Rtcs_Db_Ph_Kcca_ResidentialProperties>;
  /** execute function "rtcs_db.Fnkcca_ResidentialProperties" and query aggregates on result of table type "rtcs_db.PH_kcca_ResidentialProperties" */
  rtcs_db_Fnkcca_ResidentialProperties_aggregate: Rtcs_Db_Ph_Kcca_ResidentialProperties_Aggregate;
  /** execute function "rtcs_db.Fnkcca_ResidentialProperties_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_ResidentialProperties_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_ResidentialProperties_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_ResidentialProperties_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_ResidentialProperties_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_ResidentialProperties_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_ResidentialProperties_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_ResidentialProperties_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_RevenueType" which returns "rtcs_db.PH_kcca_RevenueType" */
  rtcs_db_Fnkcca_RevenueType: Array<Rtcs_Db_Ph_Kcca_RevenueType>;
  /** execute function "rtcs_db.Fnkcca_RevenueType" and query aggregates on result of table type "rtcs_db.PH_kcca_RevenueType" */
  rtcs_db_Fnkcca_RevenueType_aggregate: Rtcs_Db_Ph_Kcca_RevenueType_Aggregate;
  /** execute function "rtcs_db.Fnkcca_RevenueType_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_RevenueType_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_RevenueType_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_RevenueType_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_RevenueType_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_RevenueType_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_RevenueType_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_RevenueType_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_SubCounty" which returns "rtcs_db.PH_kcca_SubCounty" */
  rtcs_db_Fnkcca_SubCounty: Array<Rtcs_Db_Ph_Kcca_SubCounty>;
  /** execute function "rtcs_db.Fnkcca_SubCounty" and query aggregates on result of table type "rtcs_db.PH_kcca_SubCounty" */
  rtcs_db_Fnkcca_SubCounty_aggregate: Rtcs_Db_Ph_Kcca_SubCounty_Aggregate;
  /** execute function "rtcs_db.Fnkcca_SubCounty_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_SubCounty_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_SubCounty_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_SubCounty_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_SubCounty_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_SubCounty_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_SubCounty_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_SubCounty_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_SubpropertyTypeConversion" which returns "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
  rtcs_db_Fnkcca_SubpropertyTypeConversion: Array<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion>;
  /** execute function "rtcs_db.Fnkcca_SubpropertyTypeConversion" and query aggregates on result of table type "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
  rtcs_db_Fnkcca_SubpropertyTypeConversion_aggregate: Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Aggregate;
  /** execute function "rtcs_db.Fnkcca_SubpropertyTypeConversion_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_SubpropertyTypeConversion_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_SubpropertyTypeConversion_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_SubpropertyTypeConversion_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_SubpropertyTypeConversion_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_SubpropertyTypeConversion_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_SubpropertyTypeConversion_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_SubpropertyTypeConversion_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_TradingLicense" which returns "rtcs_db.PH_kcca_TradingLicense" */
  rtcs_db_Fnkcca_TradingLicense: Array<Rtcs_Db_Ph_Kcca_TradingLicense>;
  /** execute function "rtcs_db.Fnkcca_TradingLicense" and query aggregates on result of table type "rtcs_db.PH_kcca_TradingLicense" */
  rtcs_db_Fnkcca_TradingLicense_aggregate: Rtcs_Db_Ph_Kcca_TradingLicense_Aggregate;
  /** execute function "rtcs_db.Fnkcca_TradingLicense_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_TradingLicense_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_TradingLicense_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_TradingLicense_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_TradingLicense_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_TradingLicense_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_TradingLicense_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_TradingLicense_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Village" which returns "rtcs_db.PH_kcca_Village" */
  rtcs_db_Fnkcca_Village: Array<Rtcs_Db_Ph_Kcca_Village>;
  /** execute function "rtcs_db.Fnkcca_Village" and query aggregates on result of table type "rtcs_db.PH_kcca_Village" */
  rtcs_db_Fnkcca_Village_aggregate: Rtcs_Db_Ph_Kcca_Village_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Village_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Village_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_Village_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Village_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Village_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Village_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_Village_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Village_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnmaster_MasterIndiv" which returns "rtcs_db.PH_master_MasterIndiv" */
  rtcs_db_Fnmaster_MasterIndiv: Array<Rtcs_Db_Ph_Master_MasterIndiv>;
  /** execute function "rtcs_db.Fnmaster_MasterIndiv" and query aggregates on result of table type "rtcs_db.PH_master_MasterIndiv" */
  rtcs_db_Fnmaster_MasterIndiv_aggregate: Rtcs_Db_Ph_Master_MasterIndiv_Aggregate;
  /** execute function "rtcs_db.Fnmaster_MasterIndiv_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnmaster_MasterIndiv_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnmaster_MasterIndiv_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnmaster_MasterIndiv_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnmaster_MasterIndiv_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnmaster_MasterIndiv_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnmaster_MasterIndiv_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnmaster_MasterIndiv_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_KCCA" which returns "rtcs_db.PH_mlhud_KCCA" */
  rtcs_db_Fnmlhud_KCCA: Array<Rtcs_Db_Ph_Mlhud_Kcca>;
  /** execute function "rtcs_db.Fnmlhud_KCCA" and query aggregates on result of table type "rtcs_db.PH_mlhud_KCCA" */
  rtcs_db_Fnmlhud_KCCA_aggregate: Rtcs_Db_Ph_Mlhud_Kcca_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_KCCA_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnmlhud_KCCA_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnmlhud_KCCA_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnmlhud_KCCA_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_KCCA_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnmlhud_KCCA_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnmlhud_KCCA_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnmlhud_KCCA_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_Mukono" which returns "rtcs_db.PH_mlhud_Mukono" */
  rtcs_db_Fnmlhud_Mukono: Array<Rtcs_Db_Ph_Mlhud_Mukono>;
  /** execute function "rtcs_db.Fnmlhud_Mukono" and query aggregates on result of table type "rtcs_db.PH_mlhud_Mukono" */
  rtcs_db_Fnmlhud_Mukono_aggregate: Rtcs_Db_Ph_Mlhud_Mukono_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_Mukono_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnmlhud_Mukono_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnmlhud_Mukono_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnmlhud_Mukono_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_Mukono_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnmlhud_Mukono_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnmlhud_Mukono_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnmlhud_Mukono_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_Properties" which returns "rtcs_db.PH_mlhud_Properties" */
  rtcs_db_Fnmlhud_Properties: Array<Rtcs_Db_Ph_Mlhud_Properties>;
  /** execute function "rtcs_db.Fnmlhud_Properties" and query aggregates on result of table type "rtcs_db.PH_mlhud_Properties" */
  rtcs_db_Fnmlhud_Properties_aggregate: Rtcs_Db_Ph_Mlhud_Properties_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_Properties_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnmlhud_Properties_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnmlhud_Properties_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnmlhud_Properties_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_Properties_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnmlhud_Properties_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnmlhud_Properties_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnmlhud_Properties_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_WakisoBusiro" which returns "rtcs_db.PH_mlhud_WakisoBusiro" */
  rtcs_db_Fnmlhud_WakisoBusiro: Array<Rtcs_Db_Ph_Mlhud_WakisoBusiro>;
  /** execute function "rtcs_db.Fnmlhud_WakisoBusiro" and query aggregates on result of table type "rtcs_db.PH_mlhud_WakisoBusiro" */
  rtcs_db_Fnmlhud_WakisoBusiro_aggregate: Rtcs_Db_Ph_Mlhud_WakisoBusiro_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_WakisoBusiro_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnmlhud_WakisoBusiro_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnmlhud_WakisoBusiro_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnmlhud_WakisoBusiro_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_WakisoBusiro_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnmlhud_WakisoBusiro_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnmlhud_WakisoBusiro_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnmlhud_WakisoBusiro_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_WakisoKyadondo" which returns "rtcs_db.PH_mlhud_WakisoKyadondo" */
  rtcs_db_Fnmlhud_WakisoKyadondo: Array<Rtcs_Db_Ph_Mlhud_WakisoKyadondo>;
  /** execute function "rtcs_db.Fnmlhud_WakisoKyadondo" and query aggregates on result of table type "rtcs_db.PH_mlhud_WakisoKyadondo" */
  rtcs_db_Fnmlhud_WakisoKyadondo_aggregate: Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_WakisoKyadondo_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnmlhud_WakisoKyadondo_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnmlhud_WakisoKyadondo_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnmlhud_WakisoKyadondo_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_WakisoKyadondo_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnmlhud_WakisoKyadondo_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnmlhud_WakisoKyadondo_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnmlhud_WakisoKyadondo_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnnira_Nira" which returns "rtcs_db.PH_nira_Nira" */
  rtcs_db_Fnnira_Nira: Array<Rtcs_Db_Ph_Nira_Nira>;
  /** execute function "rtcs_db.Fnnira_Nira" and query aggregates on result of table type "rtcs_db.PH_nira_Nira" */
  rtcs_db_Fnnira_Nira_aggregate: Rtcs_Db_Ph_Nira_Nira_Aggregate;
  /** execute function "rtcs_db.Fnnira_Nira_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnnira_Nira_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnnira_Nira_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnnira_Nira_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnnira_Nira_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnira_Nira_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnnira_Nira_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnira_Nira_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnnira_RawData" which returns "rtcs_db.PH_nira_RawData" */
  rtcs_db_Fnnira_RawData: Array<Rtcs_Db_Ph_Nira_RawData>;
  /** execute function "rtcs_db.Fnnira_RawData" and query aggregates on result of table type "rtcs_db.PH_nira_RawData" */
  rtcs_db_Fnnira_RawData_aggregate: Rtcs_Db_Ph_Nira_RawData_Aggregate;
  /** execute function "rtcs_db.Fnnira_RawData_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnnira_RawData_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnnira_RawData_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnnira_RawData_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnnira_RawData_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnira_RawData_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnnira_RawData_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnira_RawData_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_Customers" which returns "rtcs_db.PH_nwsc_Customers" */
  rtcs_db_Fnnwsc_Customers: Array<Rtcs_Db_Ph_Nwsc_Customers>;
  /** execute function "rtcs_db.Fnnwsc_Customers" and query aggregates on result of table type "rtcs_db.PH_nwsc_Customers" */
  rtcs_db_Fnnwsc_Customers_aggregate: Rtcs_Db_Ph_Nwsc_Customers_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_Customers_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnnwsc_Customers_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnnwsc_Customers_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnnwsc_Customers_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_Customers_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnwsc_Customers_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnnwsc_Customers_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnwsc_Customers_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_NWCustomers" which returns "rtcs_db.PH_nwsc_NWCustomers" */
  rtcs_db_Fnnwsc_NWCustomers: Array<Rtcs_Db_Ph_Nwsc_NwCustomers>;
  /** execute function "rtcs_db.Fnnwsc_NWCustomers" and query aggregates on result of table type "rtcs_db.PH_nwsc_NWCustomers" */
  rtcs_db_Fnnwsc_NWCustomers_aggregate: Rtcs_Db_Ph_Nwsc_NwCustomers_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_NWCustomers_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnnwsc_NWCustomers_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnnwsc_NWCustomers_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnnwsc_NWCustomers_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_NWCustomers_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnwsc_NWCustomers_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnnwsc_NWCustomers_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnwsc_NWCustomers_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_RPT_Transactions" which returns "rtcs_db.PH_nwsc_RPT_Transactions" */
  rtcs_db_Fnnwsc_RPT_Transactions: Array<Rtcs_Db_Ph_Nwsc_Rpt_Transactions>;
  /** execute function "rtcs_db.Fnnwsc_RPT_Transactions" and query aggregates on result of table type "rtcs_db.PH_nwsc_RPT_Transactions" */
  rtcs_db_Fnnwsc_RPT_Transactions_aggregate: Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_RPT_Transactions_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnnwsc_RPT_Transactions_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnnwsc_RPT_Transactions_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnnwsc_RPT_Transactions_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_RPT_Transactions_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnwsc_RPT_Transactions_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnnwsc_RPT_Transactions_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnwsc_RPT_Transactions_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_Transactions" which returns "rtcs_db.PH_nwsc_Transactions" */
  rtcs_db_Fnnwsc_Transactions: Array<Rtcs_Db_Ph_Nwsc_Transactions>;
  /** execute function "rtcs_db.Fnnwsc_Transactions" and query aggregates on result of table type "rtcs_db.PH_nwsc_Transactions" */
  rtcs_db_Fnnwsc_Transactions_aggregate: Rtcs_Db_Ph_Nwsc_Transactions_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_Transactions_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnnwsc_Transactions_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnnwsc_Transactions_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnnwsc_Transactions_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_Transactions_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnwsc_Transactions_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnnwsc_Transactions_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnwsc_Transactions_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_URATransactions" which returns "rtcs_db.PH_nwsc_URATransactions" */
  rtcs_db_Fnnwsc_URATransactions: Array<Rtcs_Db_Ph_Nwsc_UraTransactions>;
  /** execute function "rtcs_db.Fnnwsc_URATransactions" and query aggregates on result of table type "rtcs_db.PH_nwsc_URATransactions" */
  rtcs_db_Fnnwsc_URATransactions_aggregate: Rtcs_Db_Ph_Nwsc_UraTransactions_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_URATransactions_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnnwsc_URATransactions_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnnwsc_URATransactions_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnnwsc_URATransactions_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_URATransactions_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnwsc_URATransactions_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnnwsc_URATransactions_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnwsc_URATransactions_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTUnittypes" which returns "rtcs_db.PH_rcapture_RPTUnittypes" */
  rtcs_db_Fnrcapture_RPTUnittypes: Array<Rtcs_Db_Ph_Rcapture_RptUnittypes>;
  /** execute function "rtcs_db.Fnrcapture_RPTUnittypes" and query aggregates on result of table type "rtcs_db.PH_rcapture_RPTUnittypes" */
  rtcs_db_Fnrcapture_RPTUnittypes_aggregate: Rtcs_Db_Ph_Rcapture_RptUnittypes_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTUnittypes_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrcapture_RPTUnittypes_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrcapture_RPTUnittypes_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrcapture_RPTUnittypes_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTUnittypes_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrcapture_RPTUnittypes_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrcapture_RPTUnittypes_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrcapture_RPTUnittypes_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTlessors" which returns "rtcs_db.PH_rcapture_RPTlessors" */
  rtcs_db_Fnrcapture_RPTlessors: Array<Rtcs_Db_Ph_Rcapture_RpTlessors>;
  /** execute function "rtcs_db.Fnrcapture_RPTlessors" and query aggregates on result of table type "rtcs_db.PH_rcapture_RPTlessors" */
  rtcs_db_Fnrcapture_RPTlessors_aggregate: Rtcs_Db_Ph_Rcapture_RpTlessors_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTlessors_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrcapture_RPTlessors_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrcapture_RPTlessors_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrcapture_RPTlessors_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTlessors_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrcapture_RPTlessors_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrcapture_RPTlessors_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrcapture_RPTlessors_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTmeta" which returns "rtcs_db.PH_rcapture_RPTmeta" */
  rtcs_db_Fnrcapture_RPTmeta: Array<Rtcs_Db_Ph_Rcapture_RpTmeta>;
  /** execute function "rtcs_db.Fnrcapture_RPTmeta" and query aggregates on result of table type "rtcs_db.PH_rcapture_RPTmeta" */
  rtcs_db_Fnrcapture_RPTmeta_aggregate: Rtcs_Db_Ph_Rcapture_RpTmeta_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTmeta_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrcapture_RPTmeta_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrcapture_RPTmeta_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrcapture_RPTmeta_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTmeta_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrcapture_RPTmeta_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrcapture_RPTmeta_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrcapture_RPTmeta_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTproperties" which returns "rtcs_db.PH_rcapture_RPTproperties" */
  rtcs_db_Fnrcapture_RPTproperties: Array<Rtcs_Db_Ph_Rcapture_RpTproperties>;
  /** execute function "rtcs_db.Fnrcapture_RPTproperties" and query aggregates on result of table type "rtcs_db.PH_rcapture_RPTproperties" */
  rtcs_db_Fnrcapture_RPTproperties_aggregate: Rtcs_Db_Ph_Rcapture_RpTproperties_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTproperties_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrcapture_RPTproperties_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrcapture_RPTproperties_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrcapture_RPTproperties_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTproperties_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrcapture_RPTproperties_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrcapture_RPTproperties_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrcapture_RPTproperties_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTtenants" which returns "rtcs_db.PH_rcapture_RPTtenants" */
  rtcs_db_Fnrcapture_RPTtenants: Array<Rtcs_Db_Ph_Rcapture_RpTtenants>;
  /** execute function "rtcs_db.Fnrcapture_RPTtenants" and query aggregates on result of table type "rtcs_db.PH_rcapture_RPTtenants" */
  rtcs_db_Fnrcapture_RPTtenants_aggregate: Rtcs_Db_Ph_Rcapture_RpTtenants_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTtenants_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrcapture_RPTtenants_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrcapture_RPTtenants_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrcapture_RPTtenants_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTtenants_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrcapture_RPTtenants_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrcapture_RPTtenants_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrcapture_RPTtenants_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Individual" which returns "rtcs_db.PH_rmatch_Individual" */
  rtcs_db_Fnrmatch_Individual: Array<Rtcs_Db_Ph_Rmatch_Individual>;
  /** execute function "rtcs_db.Fnrmatch_IndividualComplianceDetailReport" which returns "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
  rtcs_db_Fnrmatch_IndividualComplianceDetailReport: Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport>;
  /** execute function "rtcs_db.Fnrmatch_IndividualComplianceDetailReport" and query aggregates on result of table type "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
  rtcs_db_Fnrmatch_IndividualComplianceDetailReport_aggregate: Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualComplianceDetailReport_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_IndividualComplianceDetailReport_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_IndividualComplianceDetailReport_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_IndividualComplianceDetailReport_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualComplianceDetailReport_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_IndividualComplianceDetailReport_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_IndividualComplianceDetailReport_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_IndividualComplianceDetailReport_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualComplianceSummaryReport" which returns "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
  rtcs_db_Fnrmatch_IndividualComplianceSummaryReport: Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport>;
  /** execute function "rtcs_db.Fnrmatch_IndividualComplianceSummaryReport" and query aggregates on result of table type "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
  rtcs_db_Fnrmatch_IndividualComplianceSummaryReport_aggregate: Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualComplianceSummaryReport_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_IndividualComplianceSummaryReport_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_IndividualComplianceSummaryReport_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_IndividualComplianceSummaryReport_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualComplianceSummaryReport_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_IndividualComplianceSummaryReport_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_IndividualComplianceSummaryReport_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_IndividualComplianceSummaryReport_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualRiskScore" which returns "rtcs_db.PH_rmatch_IndividualRiskScore" */
  rtcs_db_Fnrmatch_IndividualRiskScore: Array<Rtcs_Db_Ph_Rmatch_IndividualRiskScore>;
  /** execute function "rtcs_db.Fnrmatch_IndividualRiskScore" and query aggregates on result of table type "rtcs_db.PH_rmatch_IndividualRiskScore" */
  rtcs_db_Fnrmatch_IndividualRiskScore_aggregate: Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualRiskScore_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_IndividualRiskScore_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_IndividualRiskScore_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_IndividualRiskScore_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualRiskScore_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_IndividualRiskScore_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_IndividualRiskScore_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_IndividualRiskScore_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Individual" and query aggregates on result of table type "rtcs_db.PH_rmatch_Individual" */
  rtcs_db_Fnrmatch_Individual_aggregate: Rtcs_Db_Ph_Rmatch_Individual_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Individual_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_Individual_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_Individual_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_Individual_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Individual_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_Individual_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_Individual_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_Individual_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualsNetwork" which returns "rtcs_db.PH_rmatch_IndividualsNetwork" */
  rtcs_db_Fnrmatch_IndividualsNetwork: Array<Rtcs_Db_Ph_Rmatch_IndividualsNetwork>;
  /** execute function "rtcs_db.Fnrmatch_IndividualsNetworkTesting" which returns "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
  rtcs_db_Fnrmatch_IndividualsNetworkTesting: Array<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting>;
  /** execute function "rtcs_db.Fnrmatch_IndividualsNetworkTesting" and query aggregates on result of table type "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
  rtcs_db_Fnrmatch_IndividualsNetworkTesting_aggregate: Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualsNetworkTesting_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_IndividualsNetworkTesting_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_IndividualsNetworkTesting_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_IndividualsNetworkTesting_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualsNetworkTesting_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_IndividualsNetworkTesting_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_IndividualsNetworkTesting_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_IndividualsNetworkTesting_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualsNetwork" and query aggregates on result of table type "rtcs_db.PH_rmatch_IndividualsNetwork" */
  rtcs_db_Fnrmatch_IndividualsNetwork_aggregate: Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualsNetwork_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_IndividualsNetwork_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_IndividualsNetwork_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_IndividualsNetwork_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualsNetwork_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_IndividualsNetwork_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_IndividualsNetwork_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_IndividualsNetwork_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_KccaToKccaIndividualOwners" which returns "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
  rtcs_db_Fnrmatch_KccaToKccaIndividualOwners: Array<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners>;
  /** execute function "rtcs_db.Fnrmatch_KccaToKccaIndividualOwners" and query aggregates on result of table type "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
  rtcs_db_Fnrmatch_KccaToKccaIndividualOwners_aggregate: Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_KccaToKccaIndividualOwners_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_KccaToKccaIndividualOwners_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_KccaToKccaIndividualOwners_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_KccaToKccaIndividualOwners_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_KccaToKccaIndividualOwners_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_KccaToKccaIndividualOwners_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_KccaToKccaIndividualOwners_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_KccaToKccaIndividualOwners_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_KccaToKccaNonindividualOwners" which returns "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
  rtcs_db_Fnrmatch_KccaToKccaNonindividualOwners: Array<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners>;
  /** execute function "rtcs_db.Fnrmatch_KccaToKccaNonindividualOwners" and query aggregates on result of table type "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
  rtcs_db_Fnrmatch_KccaToKccaNonindividualOwners_aggregate: Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_KccaToKccaNonindividualOwners_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_KccaToKccaNonindividualOwners_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_KccaToKccaNonindividualOwners_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_KccaToKccaNonindividualOwners_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_KccaToKccaNonindividualOwners_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_KccaToKccaNonindividualOwners_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_KccaToKccaNonindividualOwners_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_KccaToKccaNonindividualOwners_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_MatchIndividualsProperties" which returns "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
  rtcs_db_Fnrmatch_MatchIndividualsProperties: Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties>;
  /** execute function "rtcs_db.Fnrmatch_MatchIndividualsProperties" and query aggregates on result of table type "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
  rtcs_db_Fnrmatch_MatchIndividualsProperties_aggregate: Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_MatchIndividualsProperties_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_MatchIndividualsProperties_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_MatchIndividualsProperties_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_MatchIndividualsProperties_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_MatchIndividualsProperties_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_MatchIndividualsProperties_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_MatchIndividualsProperties_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_MatchIndividualsProperties_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_MatchIndividualsProps" which returns "rtcs_db.PH_rmatch_MatchIndividualsProps" */
  rtcs_db_Fnrmatch_MatchIndividualsProps: Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps>;
  /** execute function "rtcs_db.Fnrmatch_MatchIndividualsProps" and query aggregates on result of table type "rtcs_db.PH_rmatch_MatchIndividualsProps" */
  rtcs_db_Fnrmatch_MatchIndividualsProps_aggregate: Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_MatchIndividualsProps_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_MatchIndividualsProps_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_MatchIndividualsProps_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_MatchIndividualsProps_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_MatchIndividualsProps_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_MatchIndividualsProps_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_MatchIndividualsProps_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_MatchIndividualsProps_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NoReturns" which returns "rtcs_db.PH_rmatch_NoReturns" */
  rtcs_db_Fnrmatch_NoReturns: Array<Rtcs_Db_Ph_Rmatch_NoReturns>;
  /** execute function "rtcs_db.Fnrmatch_NoReturns" and query aggregates on result of table type "rtcs_db.PH_rmatch_NoReturns" */
  rtcs_db_Fnrmatch_NoReturns_aggregate: Rtcs_Db_Ph_Rmatch_NoReturns_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NoReturns_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NoReturns_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_NoReturns_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NoReturns_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NoReturns_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NoReturns_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_NoReturns_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NoReturns_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividual" which returns "rtcs_db.PH_rmatch_NonIndividual" */
  rtcs_db_Fnrmatch_NonIndividual: Array<Rtcs_Db_Ph_Rmatch_NonIndividual>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualComplianceDetailReport" which returns "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
  rtcs_db_Fnrmatch_NonIndividualComplianceDetailReport: Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualComplianceDetailReport" and query aggregates on result of table type "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
  rtcs_db_Fnrmatch_NonIndividualComplianceDetailReport_aggregate: Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualComplianceDetailReport_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonIndividualComplianceDetailReport_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualComplianceDetailReport_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonIndividualComplianceDetailReport_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualComplianceDetailReport_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonIndividualComplianceDetailReport_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualComplianceDetailReport_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonIndividualComplianceDetailReport_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualComplianceSummaryReport" which returns "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
  rtcs_db_Fnrmatch_NonIndividualComplianceSummaryReport: Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualComplianceSummaryReport" and query aggregates on result of table type "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
  rtcs_db_Fnrmatch_NonIndividualComplianceSummaryReport_aggregate: Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualComplianceSummaryReport_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonIndividualComplianceSummaryReport_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualComplianceSummaryReport_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonIndividualComplianceSummaryReport_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualComplianceSummaryReport_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonIndividualComplianceSummaryReport_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualComplianceSummaryReport_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonIndividualComplianceSummaryReport_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualRiskScore" which returns "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
  rtcs_db_Fnrmatch_NonIndividualRiskScore: Array<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualRiskScore" and query aggregates on result of table type "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
  rtcs_db_Fnrmatch_NonIndividualRiskScore_aggregate: Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualRiskScore_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonIndividualRiskScore_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualRiskScore_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonIndividualRiskScore_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualRiskScore_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonIndividualRiskScore_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualRiskScore_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonIndividualRiskScore_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividual" and query aggregates on result of table type "rtcs_db.PH_rmatch_NonIndividual" */
  rtcs_db_Fnrmatch_NonIndividual_aggregate: Rtcs_Db_Ph_Rmatch_NonIndividual_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividual_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonIndividual_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividual_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonIndividual_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividual_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonIndividual_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividual_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonIndividual_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividuals" which returns "rtcs_db.PH_rmatch_NonIndividuals" */
  rtcs_db_Fnrmatch_NonIndividuals: Array<Rtcs_Db_Ph_Rmatch_NonIndividuals>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualsNetwork" which returns "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
  rtcs_db_Fnrmatch_NonIndividualsNetwork: Array<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualsNetwork" and query aggregates on result of table type "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
  rtcs_db_Fnrmatch_NonIndividualsNetwork_aggregate: Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualsNetwork_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonIndividualsNetwork_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualsNetwork_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonIndividualsNetwork_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualsNetwork_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonIndividualsNetwork_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualsNetwork_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonIndividualsNetwork_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividuals" and query aggregates on result of table type "rtcs_db.PH_rmatch_NonIndividuals" */
  rtcs_db_Fnrmatch_NonIndividuals_aggregate: Rtcs_Db_Ph_Rmatch_NonIndividuals_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividuals_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonIndividuals_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividuals_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonIndividuals_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividuals_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonIndividuals_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividuals_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonIndividuals_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonindividualOwners" which returns "rtcs_db.PH_rmatch_NonindividualOwners" */
  rtcs_db_Fnrmatch_NonindividualOwners: Array<Rtcs_Db_Ph_Rmatch_NonindividualOwners>;
  /** execute function "rtcs_db.Fnrmatch_NonindividualOwners" and query aggregates on result of table type "rtcs_db.PH_rmatch_NonindividualOwners" */
  rtcs_db_Fnrmatch_NonindividualOwners_aggregate: Rtcs_Db_Ph_Rmatch_NonindividualOwners_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonindividualOwners_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonindividualOwners_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_NonindividualOwners_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonindividualOwners_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonindividualOwners_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonindividualOwners_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_NonindividualOwners_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonindividualOwners_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Nonindividuals" which returns "rtcs_db.PH_rmatch_Nonindividuals" */
  rtcs_db_Fnrmatch_Nonindividuals: Array<Rtcs_Db_Ph_Rmatch_Nonindividuals>;
  /** execute function "rtcs_db.Fnrmatch_Nonindividuals" and query aggregates on result of table type "rtcs_db.PH_rmatch_Nonindividuals" */
  rtcs_db_Fnrmatch_Nonindividuals_aggregate: Rtcs_Db_Ph_Rmatch_Nonindividuals_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Nonindividuals_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_Nonindividuals_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_Nonindividuals_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_Nonindividuals_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Nonindividuals_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_Nonindividuals_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_Nonindividuals_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_Nonindividuals_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_PropertiesComposite" which returns "rtcs_db.PH_rmatch_PropertiesComposite" */
  rtcs_db_Fnrmatch_PropertiesComposite: Array<Rtcs_Db_Ph_Rmatch_PropertiesComposite>;
  /** execute function "rtcs_db.Fnrmatch_PropertiesComposite" and query aggregates on result of table type "rtcs_db.PH_rmatch_PropertiesComposite" */
  rtcs_db_Fnrmatch_PropertiesComposite_aggregate: Rtcs_Db_Ph_Rmatch_PropertiesComposite_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_PropertiesComposite_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_PropertiesComposite_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_PropertiesComposite_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_PropertiesComposite_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_PropertiesComposite_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_PropertiesComposite_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_PropertiesComposite_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_PropertiesComposite_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_PropertiesNetwork" which returns "rtcs_db.PH_rmatch_PropertiesNetwork" */
  rtcs_db_Fnrmatch_PropertiesNetwork: Array<Rtcs_Db_Ph_Rmatch_PropertiesNetwork>;
  /** execute function "rtcs_db.Fnrmatch_PropertiesNetwork" and query aggregates on result of table type "rtcs_db.PH_rmatch_PropertiesNetwork" */
  rtcs_db_Fnrmatch_PropertiesNetwork_aggregate: Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_PropertiesNetwork_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_PropertiesNetwork_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_PropertiesNetwork_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_PropertiesNetwork_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_PropertiesNetwork_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_PropertiesNetwork_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_PropertiesNetwork_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_PropertiesNetwork_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_PropertyNetwork" which returns "rtcs_db.PH_rmatch_PropertyNetwork" */
  rtcs_db_Fnrmatch_PropertyNetwork: Array<Rtcs_Db_Ph_Rmatch_PropertyNetwork>;
  /** execute function "rtcs_db.Fnrmatch_PropertyNetwork" and query aggregates on result of table type "rtcs_db.PH_rmatch_PropertyNetwork" */
  rtcs_db_Fnrmatch_PropertyNetwork_aggregate: Rtcs_Db_Ph_Rmatch_PropertyNetwork_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_PropertyNetwork_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_PropertyNetwork_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_PropertyNetwork_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_PropertyNetwork_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_PropertyNetwork_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_PropertyNetwork_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_PropertyNetwork_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_PropertyNetwork_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed904" which returns "rtcs_db.PH_rmatch_RMatchDetailed904" */
  rtcs_db_Fnrmatch_RMatchDetailed904: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed904>;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed904" and query aggregates on result of table type "rtcs_db.PH_rmatch_RMatchDetailed904" */
  rtcs_db_Fnrmatch_RMatchDetailed904_aggregate: Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed904_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchDetailed904_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed904_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchDetailed904_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed904_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchDetailed904_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed904_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchDetailed904_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed916" which returns "rtcs_db.PH_rmatch_RMatchDetailed916" */
  rtcs_db_Fnrmatch_RMatchDetailed916: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916>;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy" which returns "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
  rtcs_db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy>;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy" and query aggregates on result of table type "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
  rtcs_db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregate: Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed916" and query aggregates on result of table type "rtcs_db.PH_rmatch_RMatchDetailed916" */
  rtcs_db_Fnrmatch_RMatchDetailed916_aggregate: Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed916_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchDetailed916_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed916_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchDetailed916_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed916_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchDetailed916_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed916_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchDetailed916_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed937" which returns "rtcs_db.PH_rmatch_RMatchDetailed937" */
  rtcs_db_Fnrmatch_RMatchDetailed937: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed937>;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed937" and query aggregates on result of table type "rtcs_db.PH_rmatch_RMatchDetailed937" */
  rtcs_db_Fnrmatch_RMatchDetailed937_aggregate: Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed937_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchDetailed937_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed937_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchDetailed937_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed937_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchDetailed937_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed937_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchDetailed937_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchKccaProperties" which returns "rtcs_db.PH_rmatch_RMatchKccaProperties" */
  rtcs_db_Fnrmatch_RMatchKccaProperties: Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties>;
  /** execute function "rtcs_db.Fnrmatch_RMatchKccaProperties" and query aggregates on result of table type "rtcs_db.PH_rmatch_RMatchKccaProperties" */
  rtcs_db_Fnrmatch_RMatchKccaProperties_aggregate: Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchKccaProperties_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchKccaProperties_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_RMatchKccaProperties_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchKccaProperties_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchKccaProperties_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchKccaProperties_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_RMatchKccaProperties_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchKccaProperties_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchKccaProperty" which returns "rtcs_db.PH_rmatch_RMatchKccaProperty" */
  rtcs_db_Fnrmatch_RMatchKccaProperty: Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty>;
  /** execute function "rtcs_db.Fnrmatch_RMatchKccaProperty" and query aggregates on result of table type "rtcs_db.PH_rmatch_RMatchKccaProperty" */
  rtcs_db_Fnrmatch_RMatchKccaProperty_aggregate: Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchKccaProperty_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchKccaProperty_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_RMatchKccaProperty_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchKccaProperty_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchKccaProperty_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchKccaProperty_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_RMatchKccaProperty_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchKccaProperty_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchNonIndividual903" which returns "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
  rtcs_db_Fnrmatch_RMatchNonIndividual903: Array<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903>;
  /** execute function "rtcs_db.Fnrmatch_RMatchNonIndividual903" and query aggregates on result of table type "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
  rtcs_db_Fnrmatch_RMatchNonIndividual903_aggregate: Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchNonIndividual903_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchNonIndividual903_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_RMatchNonIndividual903_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchNonIndividual903_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchNonIndividual903_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchNonIndividual903_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_RMatchNonIndividual903_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchNonIndividual903_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchProperties1115" which returns "rtcs_db.PH_rmatch_RMatchProperties1115" */
  rtcs_db_Fnrmatch_RMatchProperties1115: Array<Rtcs_Db_Ph_Rmatch_RMatchProperties1115>;
  /** execute function "rtcs_db.Fnrmatch_RMatchProperties1115" and query aggregates on result of table type "rtcs_db.PH_rmatch_RMatchProperties1115" */
  rtcs_db_Fnrmatch_RMatchProperties1115_aggregate: Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchProperties1115_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchProperties1115_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_RMatchProperties1115_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchProperties1115_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchProperties1115_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchProperties1115_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_RMatchProperties1115_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchProperties1115_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary904" which returns "rtcs_db.PH_rmatch_RMatchSummary904" */
  rtcs_db_Fnrmatch_RMatchSummary904: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary904>;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary904" and query aggregates on result of table type "rtcs_db.PH_rmatch_RMatchSummary904" */
  rtcs_db_Fnrmatch_RMatchSummary904_aggregate: Rtcs_Db_Ph_Rmatch_RMatchSummary904_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary904_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchSummary904_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary904_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchSummary904_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary904_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchSummary904_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary904_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchSummary904_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary916" which returns "rtcs_db.PH_rmatch_RMatchSummary916" */
  rtcs_db_Fnrmatch_RMatchSummary916: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916>;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy" which returns "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
  rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy>;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy" and query aggregates on result of table type "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
  rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregate: Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary916" and query aggregates on result of table type "rtcs_db.PH_rmatch_RMatchSummary916" */
  rtcs_db_Fnrmatch_RMatchSummary916_aggregate: Rtcs_Db_Ph_Rmatch_RMatchSummary916_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary916_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchSummary916_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary916_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchSummary916_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary916_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchSummary916_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary916_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchSummary916_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary937" which returns "rtcs_db.PH_rmatch_RMatchSummary937" */
  rtcs_db_Fnrmatch_RMatchSummary937: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary937>;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary937" and query aggregates on result of table type "rtcs_db.PH_rmatch_RMatchSummary937" */
  rtcs_db_Fnrmatch_RMatchSummary937_aggregate: Rtcs_Db_Ph_Rmatch_RMatchSummary937_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary937_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchSummary937_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary937_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchSummary937_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary937_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchSummary937_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary937_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchSummary937_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_TinsURA" which returns "rtcs_db.PH_rmatch_TinsURA" */
  rtcs_db_Fnrmatch_TinsURA: Array<Rtcs_Db_Ph_Rmatch_TinsUra>;
  /** execute function "rtcs_db.Fnrmatch_TinsURA" and query aggregates on result of table type "rtcs_db.PH_rmatch_TinsURA" */
  rtcs_db_Fnrmatch_TinsURA_aggregate: Rtcs_Db_Ph_Rmatch_TinsUra_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_TinsURA_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_TinsURA_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_TinsURA_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_TinsURA_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_TinsURA_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_TinsURA_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_TinsURA_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_TinsURA_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Undeclared" which returns "rtcs_db.PH_rmatch_Undeclared" */
  rtcs_db_Fnrmatch_Undeclared: Array<Rtcs_Db_Ph_Rmatch_Undeclared>;
  /** execute function "rtcs_db.Fnrmatch_Undeclared" and query aggregates on result of table type "rtcs_db.PH_rmatch_Undeclared" */
  rtcs_db_Fnrmatch_Undeclared_aggregate: Rtcs_Db_Ph_Rmatch_Undeclared_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Undeclared_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_Undeclared_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_Undeclared_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_Undeclared_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Undeclared_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_Undeclared_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_Undeclared_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_Undeclared_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Underreported" which returns "rtcs_db.PH_rmatch_Underreported" */
  rtcs_db_Fnrmatch_Underreported: Array<Rtcs_Db_Ph_Rmatch_Underreported>;
  /** execute function "rtcs_db.Fnrmatch_Underreported" and query aggregates on result of table type "rtcs_db.PH_rmatch_Underreported" */
  rtcs_db_Fnrmatch_Underreported_aggregate: Rtcs_Db_Ph_Rmatch_Underreported_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Underreported_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_Underreported_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_Underreported_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_Underreported_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Underreported_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_Underreported_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_Underreported_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_Underreported_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Unregistered" which returns "rtcs_db.PH_rmatch_Unregistered" */
  rtcs_db_Fnrmatch_Unregistered: Array<Rtcs_Db_Ph_Rmatch_Unregistered>;
  /** execute function "rtcs_db.Fnrmatch_Unregistered" and query aggregates on result of table type "rtcs_db.PH_rmatch_Unregistered" */
  rtcs_db_Fnrmatch_Unregistered_aggregate: Rtcs_Db_Ph_Rmatch_Unregistered_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Unregistered_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_Unregistered_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_Unregistered_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_Unregistered_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Unregistered_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_Unregistered_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_Unregistered_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_Unregistered_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_mlhudConsolidated" which returns "rtcs_db.PH_rmatch_mlhudConsolidated" */
  rtcs_db_Fnrmatch_mlhudConsolidated: Array<Rtcs_Db_Ph_Rmatch_MlhudConsolidated>;
  /** execute function "rtcs_db.Fnrmatch_mlhudConsolidated" and query aggregates on result of table type "rtcs_db.PH_rmatch_mlhudConsolidated" */
  rtcs_db_Fnrmatch_mlhudConsolidated_aggregate: Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_mlhudConsolidated_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_mlhudConsolidated_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_mlhudConsolidated_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_mlhudConsolidated_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_mlhudConsolidated_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_mlhudConsolidated_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_mlhudConsolidated_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_mlhudConsolidated_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmMukonoMunicipality" which returns "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
  rtcs_db_Fnstreetmap_OsmMukonoMunicipality: Array<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality>;
  /** execute function "rtcs_db.Fnstreetmap_OsmMukonoMunicipality" and query aggregates on result of table type "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
  rtcs_db_Fnstreetmap_OsmMukonoMunicipality_aggregate: Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmMukonoMunicipality_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnstreetmap_OsmMukonoMunicipality_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnstreetmap_OsmMukonoMunicipality_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnstreetmap_OsmMukonoMunicipality_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmMukonoMunicipality_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnstreetmap_OsmMukonoMunicipality_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnstreetmap_OsmMukonoMunicipality_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnstreetmap_OsmMukonoMunicipality_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmMukonoNakifuma" which returns "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
  rtcs_db_Fnstreetmap_OsmMukonoNakifuma: Array<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma>;
  /** execute function "rtcs_db.Fnstreetmap_OsmMukonoNakifuma" and query aggregates on result of table type "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
  rtcs_db_Fnstreetmap_OsmMukonoNakifuma_aggregate: Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmMukonoNakifuma_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnstreetmap_OsmMukonoNakifuma_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnstreetmap_OsmMukonoNakifuma_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnstreetmap_OsmMukonoNakifuma_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmMukonoNakifuma_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnstreetmap_OsmMukonoNakifuma_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnstreetmap_OsmMukonoNakifuma_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnstreetmap_OsmMukonoNakifuma_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoEntebbe" which returns "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
  rtcs_db_Fnstreetmap_OsmWakisoEntebbe: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe>;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoEntebbe" and query aggregates on result of table type "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
  rtcs_db_Fnstreetmap_OsmWakisoEntebbe_aggregate: Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoEntebbe_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnstreetmap_OsmWakisoEntebbe_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoEntebbe_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnstreetmap_OsmWakisoEntebbe_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoEntebbe_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnstreetmap_OsmWakisoEntebbe_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoEntebbe_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnstreetmap_OsmWakisoEntebbe_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoKira" which returns "rtcs_db.PH_streetmap_OsmWakisoKira" */
  rtcs_db_Fnstreetmap_OsmWakisoKira: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoKira>;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoKira" and query aggregates on result of table type "rtcs_db.PH_streetmap_OsmWakisoKira" */
  rtcs_db_Fnstreetmap_OsmWakisoKira_aggregate: Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoKira_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnstreetmap_OsmWakisoKira_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoKira_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnstreetmap_OsmWakisoKira_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoKira_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnstreetmap_OsmWakisoKira_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoKira_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnstreetmap_OsmWakisoKira_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoMakindyeSsabagabo" which returns "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
  rtcs_db_Fnstreetmap_OsmWakisoMakindyeSsabagabo: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo>;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoMakindyeSsabagabo" and query aggregates on result of table type "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
  rtcs_db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_aggregate: Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoMakindyeSsabagabo_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoMakindyeSsabagabo_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoMakindyeSsabagabo_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoMakindyeSsabagabo_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoNansana" which returns "rtcs_db.PH_streetmap_OsmWakisoNansana" */
  rtcs_db_Fnstreetmap_OsmWakisoNansana: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana>;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoNansana" and query aggregates on result of table type "rtcs_db.PH_streetmap_OsmWakisoNansana" */
  rtcs_db_Fnstreetmap_OsmWakisoNansana_aggregate: Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoNansana_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnstreetmap_OsmWakisoNansana_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoNansana_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnstreetmap_OsmWakisoNansana_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoNansana_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnstreetmap_OsmWakisoNansana_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoNansana_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnstreetmap_OsmWakisoNansana_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnucc_UCC" which returns "rtcs_db.PH_ucc_UCC" */
  rtcs_db_Fnucc_UCC: Array<Rtcs_Db_Ph_Ucc_Ucc>;
  /** execute function "rtcs_db.Fnucc_UCC" and query aggregates on result of table type "rtcs_db.PH_ucc_UCC" */
  rtcs_db_Fnucc_UCC_aggregate: Rtcs_Db_Ph_Ucc_Ucc_Aggregate;
  /** execute function "rtcs_db.Fnucc_UCC_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnucc_UCC_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnucc_UCC_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnucc_UCC_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnucc_UCC_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnucc_UCC_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnucc_UCC_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnucc_UCC_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnumeme_SubscriberAndProperty" which returns "rtcs_db.PH_umeme_SubscriberAndProperty" */
  rtcs_db_Fnumeme_SubscriberAndProperty: Array<Rtcs_Db_Ph_Umeme_SubscriberAndProperty>;
  /** execute function "rtcs_db.Fnumeme_SubscriberAndProperty" and query aggregates on result of table type "rtcs_db.PH_umeme_SubscriberAndProperty" */
  rtcs_db_Fnumeme_SubscriberAndProperty_aggregate: Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Aggregate;
  /** execute function "rtcs_db.Fnumeme_SubscriberAndProperty_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnumeme_SubscriberAndProperty_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnumeme_SubscriberAndProperty_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnumeme_SubscriberAndProperty_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnumeme_SubscriberAndProperty_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnumeme_SubscriberAndProperty_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnumeme_SubscriberAndProperty_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnumeme_SubscriberAndProperty_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnumeme_Transactions" which returns "rtcs_db.PH_umeme_Transactions" */
  rtcs_db_Fnumeme_Transactions: Array<Rtcs_Db_Ph_Umeme_Transactions>;
  /** execute function "rtcs_db.Fnumeme_Transactions" and query aggregates on result of table type "rtcs_db.PH_umeme_Transactions" */
  rtcs_db_Fnumeme_Transactions_aggregate: Rtcs_Db_Ph_Umeme_Transactions_Aggregate;
  /** execute function "rtcs_db.Fnumeme_Transactions_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnumeme_Transactions_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnumeme_Transactions_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnumeme_Transactions_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnumeme_Transactions_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnumeme_Transactions_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnumeme_Transactions_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnumeme_Transactions_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_BalanceSheetInformation" which returns "rtcs_db.PH_ura_BalanceSheetInformation" */
  rtcs_db_Fnura_BalanceSheetInformation: Array<Rtcs_Db_Ph_Ura_BalanceSheetInformation>;
  /** execute function "rtcs_db.Fnura_BalanceSheetInformation" and query aggregates on result of table type "rtcs_db.PH_ura_BalanceSheetInformation" */
  rtcs_db_Fnura_BalanceSheetInformation_aggregate: Rtcs_Db_Ph_Ura_BalanceSheetInformation_Aggregate;
  /** execute function "rtcs_db.Fnura_BalanceSheetInformation_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_BalanceSheetInformation_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_BalanceSheetInformation_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_BalanceSheetInformation_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_BalanceSheetInformation_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_BalanceSheetInformation_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_BalanceSheetInformation_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_BalanceSheetInformation_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_CombinedOfficersAndOwners" which returns "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
  rtcs_db_Fnura_CombinedOfficersAndOwners: Array<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners>;
  /** execute function "rtcs_db.Fnura_CombinedOfficersAndOwners" and query aggregates on result of table type "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
  rtcs_db_Fnura_CombinedOfficersAndOwners_aggregate: Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Aggregate;
  /** execute function "rtcs_db.Fnura_CombinedOfficersAndOwners_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_CombinedOfficersAndOwners_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_CombinedOfficersAndOwners_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_CombinedOfficersAndOwners_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_CombinedOfficersAndOwners_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_CombinedOfficersAndOwners_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_CombinedOfficersAndOwners_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_CombinedOfficersAndOwners_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_EmploymentIncomeDetail" which returns "rtcs_db.PH_ura_EmploymentIncomeDetail" */
  rtcs_db_Fnura_EmploymentIncomeDetail: Array<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail>;
  /** execute function "rtcs_db.Fnura_EmploymentIncomeDetail" and query aggregates on result of table type "rtcs_db.PH_ura_EmploymentIncomeDetail" */
  rtcs_db_Fnura_EmploymentIncomeDetail_aggregate: Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Aggregate;
  /** execute function "rtcs_db.Fnura_EmploymentIncomeDetail_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_EmploymentIncomeDetail_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_EmploymentIncomeDetail_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_EmploymentIncomeDetail_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_EmploymentIncomeDetail_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_EmploymentIncomeDetail_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_EmploymentIncomeDetail_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_EmploymentIncomeDetail_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_GazetteCommercialRatesPerSquareFootage" which returns "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
  rtcs_db_Fnura_GazetteCommercialRatesPerSquareFootage: Array<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage>;
  /** execute function "rtcs_db.Fnura_GazetteCommercialRatesPerSquareFootage" and query aggregates on result of table type "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
  rtcs_db_Fnura_GazetteCommercialRatesPerSquareFootage_aggregate: Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Aggregate;
  /** execute function "rtcs_db.Fnura_GazetteCommercialRatesPerSquareFootage_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_GazetteCommercialRatesPerSquareFootage_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_GazetteCommercialRatesPerSquareFootage_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_GazetteCommercialRatesPerSquareFootage_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_GazetteCommercialRatesPerSquareFootage_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_GazetteCommercialRatesPerSquareFootage_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_GazetteCommercialRatesPerSquareFootage_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_GazetteCommercialRatesPerSquareFootage_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_GazetteResidentialRatesPerBedroom" which returns "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
  rtcs_db_Fnura_GazetteResidentialRatesPerBedroom: Array<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom>;
  /** execute function "rtcs_db.Fnura_GazetteResidentialRatesPerBedroom" and query aggregates on result of table type "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
  rtcs_db_Fnura_GazetteResidentialRatesPerBedroom_aggregate: Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Aggregate;
  /** execute function "rtcs_db.Fnura_GazetteResidentialRatesPerBedroom_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_GazetteResidentialRatesPerBedroom_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_GazetteResidentialRatesPerBedroom_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_GazetteResidentialRatesPerBedroom_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_GazetteResidentialRatesPerBedroom_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_GazetteResidentialRatesPerBedroom_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_GazetteResidentialRatesPerBedroom_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_GazetteResidentialRatesPerBedroom_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_HoldingOfficers" which returns "rtcs_db.PH_ura_HoldingOfficers" */
  rtcs_db_Fnura_HoldingOfficers: Array<Rtcs_Db_Ph_Ura_HoldingOfficers>;
  /** execute function "rtcs_db.Fnura_HoldingOfficers" and query aggregates on result of table type "rtcs_db.PH_ura_HoldingOfficers" */
  rtcs_db_Fnura_HoldingOfficers_aggregate: Rtcs_Db_Ph_Ura_HoldingOfficers_Aggregate;
  /** execute function "rtcs_db.Fnura_HoldingOfficers_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_HoldingOfficers_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_HoldingOfficers_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_HoldingOfficers_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_HoldingOfficers_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_HoldingOfficers_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_HoldingOfficers_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_HoldingOfficers_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_HoldingOwner" which returns "rtcs_db.PH_ura_HoldingOwner" */
  rtcs_db_Fnura_HoldingOwner: Array<Rtcs_Db_Ph_Ura_HoldingOwner>;
  /** execute function "rtcs_db.Fnura_HoldingOwner" and query aggregates on result of table type "rtcs_db.PH_ura_HoldingOwner" */
  rtcs_db_Fnura_HoldingOwner_aggregate: Rtcs_Db_Ph_Ura_HoldingOwner_Aggregate;
  /** execute function "rtcs_db.Fnura_HoldingOwner_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_HoldingOwner_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_HoldingOwner_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_HoldingOwner_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_HoldingOwner_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_HoldingOwner_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_HoldingOwner_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_HoldingOwner_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_IncomeTaxHolding" which returns "rtcs_db.PH_ura_IncomeTaxHolding" */
  rtcs_db_Fnura_IncomeTaxHolding: Array<Rtcs_Db_Ph_Ura_IncomeTaxHolding>;
  /** execute function "rtcs_db.Fnura_IncomeTaxHolding" and query aggregates on result of table type "rtcs_db.PH_ura_IncomeTaxHolding" */
  rtcs_db_Fnura_IncomeTaxHolding_aggregate: Rtcs_Db_Ph_Ura_IncomeTaxHolding_Aggregate;
  /** execute function "rtcs_db.Fnura_IncomeTaxHolding_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_IncomeTaxHolding_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_IncomeTaxHolding_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_IncomeTaxHolding_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_IncomeTaxHolding_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_IncomeTaxHolding_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_IncomeTaxHolding_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_IncomeTaxHolding_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_IncomeTaxSummaryIndividual" which returns "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
  rtcs_db_Fnura_IncomeTaxSummaryIndividual: Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual>;
  /** execute function "rtcs_db.Fnura_IncomeTaxSummaryIndividual" and query aggregates on result of table type "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
  rtcs_db_Fnura_IncomeTaxSummaryIndividual_aggregate: Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Aggregate;
  /** execute function "rtcs_db.Fnura_IncomeTaxSummaryIndividual_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_IncomeTaxSummaryIndividual_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_IncomeTaxSummaryIndividual_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_IncomeTaxSummaryIndividual_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_IncomeTaxSummaryIndividual_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_IncomeTaxSummaryIndividual_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_IncomeTaxSummaryIndividual_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_IncomeTaxSummaryIndividual_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_IncomeTaxSummaryNonIndividual" which returns "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
  rtcs_db_Fnura_IncomeTaxSummaryNonIndividual: Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual>;
  /** execute function "rtcs_db.Fnura_IncomeTaxSummaryNonIndividual" and query aggregates on result of table type "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
  rtcs_db_Fnura_IncomeTaxSummaryNonIndividual_aggregate: Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Aggregate;
  /** execute function "rtcs_db.Fnura_IncomeTaxSummaryNonIndividual_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_IncomeTaxSummaryNonIndividual_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_IncomeTaxSummaryNonIndividual_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_IncomeTaxSummaryNonIndividual_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_IncomeTaxSummaryNonIndividual_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_IncomeTaxSummaryNonIndividual_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_IncomeTaxSummaryNonIndividual_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_IncomeTaxSummaryNonIndividual_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_IndividualMortgage" which returns "rtcs_db.PH_ura_IndividualMortgage" */
  rtcs_db_Fnura_IndividualMortgage: Array<Rtcs_Db_Ph_Ura_IndividualMortgage>;
  /** execute function "rtcs_db.Fnura_IndividualMortgage" and query aggregates on result of table type "rtcs_db.PH_ura_IndividualMortgage" */
  rtcs_db_Fnura_IndividualMortgage_aggregate: Rtcs_Db_Ph_Ura_IndividualMortgage_Aggregate;
  /** execute function "rtcs_db.Fnura_IndividualMortgage_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_IndividualMortgage_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_IndividualMortgage_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_IndividualMortgage_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_IndividualMortgage_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_IndividualMortgage_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_IndividualMortgage_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_IndividualMortgage_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_IndividualsAssociatedBusiness" which returns "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
  rtcs_db_Fnura_IndividualsAssociatedBusiness: Array<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness>;
  /** execute function "rtcs_db.Fnura_IndividualsAssociatedBusiness" and query aggregates on result of table type "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
  rtcs_db_Fnura_IndividualsAssociatedBusiness_aggregate: Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Aggregate;
  /** execute function "rtcs_db.Fnura_IndividualsAssociatedBusiness_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_IndividualsAssociatedBusiness_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_IndividualsAssociatedBusiness_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_IndividualsAssociatedBusiness_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_IndividualsAssociatedBusiness_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_IndividualsAssociatedBusiness_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_IndividualsAssociatedBusiness_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_IndividualsAssociatedBusiness_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_LandlordIncomeDetail" which returns "rtcs_db.PH_ura_LandlordIncomeDetail" */
  rtcs_db_Fnura_LandlordIncomeDetail: Array<Rtcs_Db_Ph_Ura_LandlordIncomeDetail>;
  /** execute function "rtcs_db.Fnura_LandlordIncomeDetail" and query aggregates on result of table type "rtcs_db.PH_ura_LandlordIncomeDetail" */
  rtcs_db_Fnura_LandlordIncomeDetail_aggregate: Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Aggregate;
  /** execute function "rtcs_db.Fnura_LandlordIncomeDetail_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_LandlordIncomeDetail_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_LandlordIncomeDetail_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_LandlordIncomeDetail_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_LandlordIncomeDetail_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_LandlordIncomeDetail_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_LandlordIncomeDetail_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_LandlordIncomeDetail_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_LandlordIncomeSummary" which returns "rtcs_db.PH_ura_LandlordIncomeSummary" */
  rtcs_db_Fnura_LandlordIncomeSummary: Array<Rtcs_Db_Ph_Ura_LandlordIncomeSummary>;
  /** execute function "rtcs_db.Fnura_LandlordIncomeSummary" and query aggregates on result of table type "rtcs_db.PH_ura_LandlordIncomeSummary" */
  rtcs_db_Fnura_LandlordIncomeSummary_aggregate: Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Aggregate;
  /** execute function "rtcs_db.Fnura_LandlordIncomeSummary_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_LandlordIncomeSummary_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_LandlordIncomeSummary_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_LandlordIncomeSummary_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_LandlordIncomeSummary_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_LandlordIncomeSummary_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_LandlordIncomeSummary_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_LandlordIncomeSummary_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_NonIndividualsAssociatedBusiness" which returns "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
  rtcs_db_Fnura_NonIndividualsAssociatedBusiness: Array<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness>;
  /** execute function "rtcs_db.Fnura_NonIndividualsAssociatedBusiness" and query aggregates on result of table type "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
  rtcs_db_Fnura_NonIndividualsAssociatedBusiness_aggregate: Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Aggregate;
  /** execute function "rtcs_db.Fnura_NonIndividualsAssociatedBusiness_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_NonIndividualsAssociatedBusiness_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_NonIndividualsAssociatedBusiness_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_NonIndividualsAssociatedBusiness_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_NonIndividualsAssociatedBusiness_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_NonIndividualsAssociatedBusiness_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_NonIndividualsAssociatedBusiness_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_NonIndividualsAssociatedBusiness_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_RegAssociates" which returns "rtcs_db.PH_ura_RegAssociates" */
  rtcs_db_Fnura_RegAssociates: Array<Rtcs_Db_Ph_Ura_RegAssociates>;
  /** execute function "rtcs_db.Fnura_RegAssociates" and query aggregates on result of table type "rtcs_db.PH_ura_RegAssociates" */
  rtcs_db_Fnura_RegAssociates_aggregate: Rtcs_Db_Ph_Ura_RegAssociates_Aggregate;
  /** execute function "rtcs_db.Fnura_RegAssociates_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RegAssociates_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_RegAssociates_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RegAssociates_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_RegAssociates_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RegAssociates_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_RegAssociates_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RegAssociates_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_RegIndividual" which returns "rtcs_db.PH_ura_RegIndividual" */
  rtcs_db_Fnura_RegIndividual: Array<Rtcs_Db_Ph_Ura_RegIndividual>;
  /** execute function "rtcs_db.Fnura_RegIndividual" and query aggregates on result of table type "rtcs_db.PH_ura_RegIndividual" */
  rtcs_db_Fnura_RegIndividual_aggregate: Rtcs_Db_Ph_Ura_RegIndividual_Aggregate;
  /** execute function "rtcs_db.Fnura_RegIndividual_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RegIndividual_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_RegIndividual_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RegIndividual_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_RegIndividual_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RegIndividual_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_RegIndividual_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RegIndividual_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_RegIndividual_sid" which returns "rtcs_db.PH_ura_RegIndividual_sid" */
  rtcs_db_Fnura_RegIndividual_sid: Array<Rtcs_Db_Ph_Ura_RegIndividual_Sid>;
  /** execute function "rtcs_db.Fnura_RegIndividual_sid" and query aggregates on result of table type "rtcs_db.PH_ura_RegIndividual_sid" */
  rtcs_db_Fnura_RegIndividual_sid_aggregate: Rtcs_Db_Ph_Ura_RegIndividual_Sid_Aggregate;
  /** execute function "rtcs_db.Fnura_RegIndividual_sid_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RegIndividual_sid_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_RegIndividual_sid_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RegIndividual_sid_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_RegIndividual_sid_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RegIndividual_sid_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_RegIndividual_sid_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RegIndividual_sid_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_RegNonIndividual" which returns "rtcs_db.PH_ura_RegNonIndividual" */
  rtcs_db_Fnura_RegNonIndividual: Array<Rtcs_Db_Ph_Ura_RegNonIndividual>;
  /** execute function "rtcs_db.Fnura_RegNonIndividual" and query aggregates on result of table type "rtcs_db.PH_ura_RegNonIndividual" */
  rtcs_db_Fnura_RegNonIndividual_aggregate: Rtcs_Db_Ph_Ura_RegNonIndividual_Aggregate;
  /** execute function "rtcs_db.Fnura_RegNonIndividual_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RegNonIndividual_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_RegNonIndividual_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RegNonIndividual_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_RegNonIndividual_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RegNonIndividual_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_RegNonIndividual_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RegNonIndividual_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_RegNonIndividual_sid" which returns "rtcs_db.PH_ura_RegNonIndividual_sid" */
  rtcs_db_Fnura_RegNonIndividual_sid: Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid>;
  /** execute function "rtcs_db.Fnura_RegNonIndividual_sid" and query aggregates on result of table type "rtcs_db.PH_ura_RegNonIndividual_sid" */
  rtcs_db_Fnura_RegNonIndividual_sid_aggregate: Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Aggregate;
  /** execute function "rtcs_db.Fnura_RegNonIndividual_sid_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RegNonIndividual_sid_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_RegNonIndividual_sid_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RegNonIndividual_sid_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_RegNonIndividual_sid_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RegNonIndividual_sid_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_RegNonIndividual_sid_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RegNonIndividual_sid_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_RentExpenseDetails" which returns "rtcs_db.PH_ura_RentExpenseDetails" */
  rtcs_db_Fnura_RentExpenseDetails: Array<Rtcs_Db_Ph_Ura_RentExpenseDetails>;
  /** execute function "rtcs_db.Fnura_RentExpenseDetails" and query aggregates on result of table type "rtcs_db.PH_ura_RentExpenseDetails" */
  rtcs_db_Fnura_RentExpenseDetails_aggregate: Rtcs_Db_Ph_Ura_RentExpenseDetails_Aggregate;
  /** execute function "rtcs_db.Fnura_RentExpenseDetails_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RentExpenseDetails_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_RentExpenseDetails_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RentExpenseDetails_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_RentExpenseDetails_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RentExpenseDetails_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_RentExpenseDetails_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RentExpenseDetails_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_RentalLandLordIncome" which returns "rtcs_db.PH_ura_RentalLandLordIncome" */
  rtcs_db_Fnura_RentalLandLordIncome: Array<Rtcs_Db_Ph_Ura_RentalLandLordIncome>;
  /** execute function "rtcs_db.Fnura_RentalLandLordIncome" and query aggregates on result of table type "rtcs_db.PH_ura_RentalLandLordIncome" */
  rtcs_db_Fnura_RentalLandLordIncome_aggregate: Rtcs_Db_Ph_Ura_RentalLandLordIncome_Aggregate;
  /** execute function "rtcs_db.Fnura_RentalLandLordIncome_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RentalLandLordIncome_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_RentalLandLordIncome_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RentalLandLordIncome_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_RentalLandLordIncome_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RentalLandLordIncome_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_RentalLandLordIncome_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RentalLandLordIncome_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_RentalTenantPayments" which returns "rtcs_db.PH_ura_RentalTenantPayments" */
  rtcs_db_Fnura_RentalTenantPayments: Array<Rtcs_Db_Ph_Ura_RentalTenantPayments>;
  /** execute function "rtcs_db.Fnura_RentalTenantPayments" and query aggregates on result of table type "rtcs_db.PH_ura_RentalTenantPayments" */
  rtcs_db_Fnura_RentalTenantPayments_aggregate: Rtcs_Db_Ph_Ura_RentalTenantPayments_Aggregate;
  /** execute function "rtcs_db.Fnura_RentalTenantPayments_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RentalTenantPayments_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_RentalTenantPayments_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RentalTenantPayments_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_RentalTenantPayments_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RentalTenantPayments_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_RentalTenantPayments_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RentalTenantPayments_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_RipoIndRentalIncSummary" which returns "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
  rtcs_db_Fnura_RipoIndRentalIncSummary: Array<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary>;
  /** execute function "rtcs_db.Fnura_RipoIndRentalIncSummary" and query aggregates on result of table type "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
  rtcs_db_Fnura_RipoIndRentalIncSummary_aggregate: Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Aggregate;
  /** execute function "rtcs_db.Fnura_RipoIndRentalIncSummary_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RipoIndRentalIncSummary_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_RipoIndRentalIncSummary_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RipoIndRentalIncSummary_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_RipoIndRentalIncSummary_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RipoIndRentalIncSummary_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_RipoIndRentalIncSummary_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RipoIndRentalIncSummary_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_TenantIncomeDetail" which returns "rtcs_db.PH_ura_TenantIncomeDetail" */
  rtcs_db_Fnura_TenantIncomeDetail: Array<Rtcs_Db_Ph_Ura_TenantIncomeDetail>;
  /** execute function "rtcs_db.Fnura_TenantIncomeDetail" and query aggregates on result of table type "rtcs_db.PH_ura_TenantIncomeDetail" */
  rtcs_db_Fnura_TenantIncomeDetail_aggregate: Rtcs_Db_Ph_Ura_TenantIncomeDetail_Aggregate;
  /** execute function "rtcs_db.Fnura_TenantIncomeDetail_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_TenantIncomeDetail_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_TenantIncomeDetail_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_TenantIncomeDetail_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_TenantIncomeDetail_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_TenantIncomeDetail_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_TenantIncomeDetail_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_TenantIncomeDetail_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_Town" which returns "rtcs_db.PH_ura_Town" */
  rtcs_db_Fnura_Town: Array<Rtcs_Db_Ph_Ura_Town>;
  /** execute function "rtcs_db.Fnura_Town" and query aggregates on result of table type "rtcs_db.PH_ura_Town" */
  rtcs_db_Fnura_Town_aggregate: Rtcs_Db_Ph_Ura_Town_Aggregate;
  /** execute function "rtcs_db.Fnura_Town_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_Town_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_Town_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_Town_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_Town_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_Town_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_Town_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_Town_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnursb_CompaniesAndBusinessNamesURSB" which returns "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
  rtcs_db_Fnursb_CompaniesAndBusinessNamesURSB: Array<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb>;
  /** execute function "rtcs_db.Fnursb_CompaniesAndBusinessNamesURSB" and query aggregates on result of table type "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
  rtcs_db_Fnursb_CompaniesAndBusinessNamesURSB_aggregate: Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Aggregate;
  /** execute function "rtcs_db.Fnursb_CompaniesAndBusinessNamesURSB_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnursb_CompaniesAndBusinessNamesURSB_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnursb_CompaniesAndBusinessNamesURSB_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnursb_CompaniesAndBusinessNamesURSB_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnursb_CompaniesAndBusinessNamesURSB_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnursb_CompaniesAndBusinessNamesURSB_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnursb_CompaniesAndBusinessNamesURSB_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnursb_CompaniesAndBusinessNamesURSB_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** fetch data from the table: "rtcs_db.GazzetteRates" */
  rtcs_db_GazzetteRates: Array<Rtcs_Db_GazzetteRates>;
  /** fetch aggregated fields from the table: "rtcs_db.GazzetteRates" */
  rtcs_db_GazzetteRates_aggregate: Rtcs_Db_GazzetteRates_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_EntityFilter" */
  rtcs_db_PH_EntityFilter: Array<Rtcs_Db_Ph_EntityFilter>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_EntityFilter" */
  rtcs_db_PH_EntityFilter_aggregate: Rtcs_Db_Ph_EntityFilter_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_Filter" */
  rtcs_db_PH_Filter: Array<Rtcs_Db_Ph_Filter>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_Filter" */
  rtcs_db_PH_Filter_aggregate: Rtcs_Db_Ph_Filter_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_GazzetteRates" */
  rtcs_db_PH_GazzetteRates: Array<Rtcs_Db_Ph_GazzetteRates>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_GazzetteRates" */
  rtcs_db_PH_GazzetteRates_aggregate: Rtcs_Db_Ph_GazzetteRates_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
  rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk: Array<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
  rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk_aggregate: Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_IndividualComposite" */
  rtcs_db_PH_RPT_IndividualComposite: Array<Rtcs_Db_Ph_Rpt_IndividualComposite>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_IndividualComposite" */
  rtcs_db_PH_RPT_IndividualComposite_aggregate: Rtcs_Db_Ph_Rpt_IndividualComposite_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_IndividualRegistration" */
  rtcs_db_PH_RPT_IndividualRegistration: Array<Rtcs_Db_Ph_Rpt_IndividualRegistration>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_IndividualRegistration" */
  rtcs_db_PH_RPT_IndividualRegistration_aggregate: Rtcs_Db_Ph_Rpt_IndividualRegistration_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
  rtcs_db_PH_RPT_IndividualTaxSummaryData: Array<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
  rtcs_db_PH_RPT_IndividualTaxSummaryData_aggregate: Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_KccaAffiliates" */
  rtcs_db_PH_RPT_KccaAffiliates: Array<Rtcs_Db_Ph_Rpt_KccaAffiliates>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_KccaAffiliates" */
  rtcs_db_PH_RPT_KccaAffiliates_aggregate: Rtcs_Db_Ph_Rpt_KccaAffiliates_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
  rtcs_db_PH_RPT_KccaCustomerRegistrationData: Array<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
  rtcs_db_PH_RPT_KccaCustomerRegistrationData_aggregate: Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_KccaPropertyRegistration" */
  rtcs_db_PH_RPT_KccaPropertyRegistration: Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration>;
  /** fetch data from the table: "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
  rtcs_db_PH_RPT_KccaPropertyRegistrationData: Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
  rtcs_db_PH_RPT_KccaPropertyRegistrationData_aggregate: Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_KccaPropertyRegistration" */
  rtcs_db_PH_RPT_KccaPropertyRegistration_aggregate: Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
  rtcs_db_PH_RPT_NonIndividualBalanceSheet: Array<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
  rtcs_db_PH_RPT_NonIndividualBalanceSheet_aggregate: Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
  rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk: Array<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
  rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk_aggregate: Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_NonIndividualComposite" */
  rtcs_db_PH_RPT_NonIndividualComposite: Array<Rtcs_Db_Ph_Rpt_NonIndividualComposite>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_NonIndividualComposite" */
  rtcs_db_PH_RPT_NonIndividualComposite_aggregate: Rtcs_Db_Ph_Rpt_NonIndividualComposite_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
  rtcs_db_PH_RPT_NonIndividualTaxSummaryData: Array<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
  rtcs_db_PH_RPT_NonIndividualTaxSummaryData_aggregate: Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_PropertiesComposite" */
  rtcs_db_PH_RPT_PropertiesComposite: Array<Rtcs_Db_Ph_Rpt_PropertiesComposite>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_PropertiesComposite" */
  rtcs_db_PH_RPT_PropertiesComposite_aggregate: Rtcs_Db_Ph_Rpt_PropertiesComposite_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_PropertyValuation" */
  rtcs_db_PH_RPT_PropertyValuation: Array<Rtcs_Db_Ph_Rpt_PropertyValuation>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_PropertyValuation" */
  rtcs_db_PH_RPT_PropertyValuation_aggregate: Rtcs_Db_Ph_Rpt_PropertyValuation_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_RentalTenantPayments" */
  rtcs_db_PH_RPT_RentalTenantPayments: Array<Rtcs_Db_Ph_Rpt_RentalTenantPayments>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_RentalTenantPayments" */
  rtcs_db_PH_RPT_RentalTenantPayments_aggregate: Rtcs_Db_Ph_Rpt_RentalTenantPayments_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_gazette_CommercialRates" */
  rtcs_db_PH_gazette_CommercialRates: Array<Rtcs_Db_Ph_Gazette_CommercialRates>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_gazette_CommercialRates" */
  rtcs_db_PH_gazette_CommercialRates_aggregate: Rtcs_Db_Ph_Gazette_CommercialRates_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_gazette_ResidentialRates" */
  rtcs_db_PH_gazette_ResidentialRates: Array<Rtcs_Db_Ph_Gazette_ResidentialRates>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_gazette_ResidentialRates" */
  rtcs_db_PH_gazette_ResidentialRates_aggregate: Rtcs_Db_Ph_Gazette_ResidentialRates_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_AssociateType" */
  rtcs_db_PH_kcca_AssociateType: Array<Rtcs_Db_Ph_Kcca_AssociateType>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_AssociateType" */
  rtcs_db_PH_kcca_AssociateType_aggregate: Rtcs_Db_Ph_Kcca_AssociateType_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_CommercialProperties" */
  rtcs_db_PH_kcca_CommercialProperties: Array<Rtcs_Db_Ph_Kcca_CommercialProperties>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_CommercialProperties" */
  rtcs_db_PH_kcca_CommercialProperties_aggregate: Rtcs_Db_Ph_Kcca_CommercialProperties_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_County" */
  rtcs_db_PH_kcca_County: Array<Rtcs_Db_Ph_Kcca_County>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_County" */
  rtcs_db_PH_kcca_County_aggregate: Rtcs_Db_Ph_Kcca_County_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_Customer" */
  rtcs_db_PH_kcca_Customer: Array<Rtcs_Db_Ph_Kcca_Customer>;
  /** fetch data from the table: "rtcs_db.PH_kcca_CustomerApplicantType" */
  rtcs_db_PH_kcca_CustomerApplicantType: Array<Rtcs_Db_Ph_Kcca_CustomerApplicantType>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_CustomerApplicantType" */
  rtcs_db_PH_kcca_CustomerApplicantType_aggregate: Rtcs_Db_Ph_Kcca_CustomerApplicantType_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
  rtcs_db_PH_kcca_CustomerBusinessAssociate: Array<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
  rtcs_db_PH_kcca_CustomerBusinessAssociate_aggregate: Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_CustomerBusinessType" */
  rtcs_db_PH_kcca_CustomerBusinessType: Array<Rtcs_Db_Ph_Kcca_CustomerBusinessType>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_CustomerBusinessType" */
  rtcs_db_PH_kcca_CustomerBusinessType_aggregate: Rtcs_Db_Ph_Kcca_CustomerBusinessType_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
  rtcs_db_PH_kcca_CustomerCoinDeactivateReactive: Array<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
  rtcs_db_PH_kcca_CustomerCoinDeactivateReactive_aggregate: Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_CustomerContactPerson" */
  rtcs_db_PH_kcca_CustomerContactPerson: Array<Rtcs_Db_Ph_Kcca_CustomerContactPerson>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_CustomerContactPerson" */
  rtcs_db_PH_kcca_CustomerContactPerson_aggregate: Rtcs_Db_Ph_Kcca_CustomerContactPerson_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_CustomerDocumentItem" */
  rtcs_db_PH_kcca_CustomerDocumentItem: Array<Rtcs_Db_Ph_Kcca_CustomerDocumentItem>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_CustomerDocumentItem" */
  rtcs_db_PH_kcca_CustomerDocumentItem_aggregate: Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_CustomerGuardian" */
  rtcs_db_PH_kcca_CustomerGuardian: Array<Rtcs_Db_Ph_Kcca_CustomerGuardian>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_CustomerGuardian" */
  rtcs_db_PH_kcca_CustomerGuardian_aggregate: Rtcs_Db_Ph_Kcca_CustomerGuardian_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_CustomerReference" */
  rtcs_db_PH_kcca_CustomerReference: Array<Rtcs_Db_Ph_Kcca_CustomerReference>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_CustomerReference" */
  rtcs_db_PH_kcca_CustomerReference_aggregate: Rtcs_Db_Ph_Kcca_CustomerReference_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_CustomerRegistrationReason" */
  rtcs_db_PH_kcca_CustomerRegistrationReason: Array<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_CustomerRegistrationReason" */
  rtcs_db_PH_kcca_CustomerRegistrationReason_aggregate: Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_CustomerRevenueAgent" */
  rtcs_db_PH_kcca_CustomerRevenueAgent: Array<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_CustomerRevenueAgent" */
  rtcs_db_PH_kcca_CustomerRevenueAgent_aggregate: Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_CustomerType" */
  rtcs_db_PH_kcca_CustomerType: Array<Rtcs_Db_Ph_Kcca_CustomerType>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_CustomerType" */
  rtcs_db_PH_kcca_CustomerType_aggregate: Rtcs_Db_Ph_Kcca_CustomerType_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_Customer" */
  rtcs_db_PH_kcca_Customer_aggregate: Rtcs_Db_Ph_Kcca_Customer_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_Customer_sid" */
  rtcs_db_PH_kcca_Customer_sid: Array<Rtcs_Db_Ph_Kcca_Customer_Sid>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_Customer_sid" */
  rtcs_db_PH_kcca_Customer_sid_aggregate: Rtcs_Db_Ph_Kcca_Customer_Sid_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_District" */
  rtcs_db_PH_kcca_District: Array<Rtcs_Db_Ph_Kcca_District>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_District" */
  rtcs_db_PH_kcca_District_aggregate: Rtcs_Db_Ph_Kcca_District_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_Division" */
  rtcs_db_PH_kcca_Division: Array<Rtcs_Db_Ph_Kcca_Division>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_Division" */
  rtcs_db_PH_kcca_Division_aggregate: Rtcs_Db_Ph_Kcca_Division_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_ImgsData" */
  rtcs_db_PH_kcca_ImgsData: Array<Rtcs_Db_Ph_Kcca_ImgsData>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_ImgsData" */
  rtcs_db_PH_kcca_ImgsData_aggregate: Rtcs_Db_Ph_Kcca_ImgsData_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_Parish" */
  rtcs_db_PH_kcca_Parish: Array<Rtcs_Db_Ph_Kcca_Parish>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_Parish" */
  rtcs_db_PH_kcca_Parish_aggregate: Rtcs_Db_Ph_Kcca_Parish_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_PaymentDetails" */
  rtcs_db_PH_kcca_PaymentDetails: Array<Rtcs_Db_Ph_Kcca_PaymentDetails>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_PaymentDetails" */
  rtcs_db_PH_kcca_PaymentDetails_aggregate: Rtcs_Db_Ph_Kcca_PaymentDetails_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_Payments" */
  rtcs_db_PH_kcca_Payments: Array<Rtcs_Db_Ph_Kcca_Payments>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_Payments" */
  rtcs_db_PH_kcca_Payments_aggregate: Rtcs_Db_Ph_Kcca_Payments_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_Property" */
  rtcs_db_PH_kcca_Property: Array<Rtcs_Db_Ph_Kcca_Property>;
  /** fetch data from the table: "rtcs_db.PH_kcca_PropertyFloors" */
  rtcs_db_PH_kcca_PropertyFloors: Array<Rtcs_Db_Ph_Kcca_PropertyFloors>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_PropertyFloors" */
  rtcs_db_PH_kcca_PropertyFloors_aggregate: Rtcs_Db_Ph_Kcca_PropertyFloors_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
  rtcs_db_PH_kcca_PropertyNumberBedrooms: Array<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
  rtcs_db_PH_kcca_PropertyNumberBedrooms_aggregate: Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_PropertyRentedStatus" */
  rtcs_db_PH_kcca_PropertyRentedStatus: Array<Rtcs_Db_Ph_Kcca_PropertyRentedStatus>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_PropertyRentedStatus" */
  rtcs_db_PH_kcca_PropertyRentedStatus_aggregate: Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_PropertySecurity" */
  rtcs_db_PH_kcca_PropertySecurity: Array<Rtcs_Db_Ph_Kcca_PropertySecurity>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_PropertySecurity" */
  rtcs_db_PH_kcca_PropertySecurity_aggregate: Rtcs_Db_Ph_Kcca_PropertySecurity_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_PropertySubType" */
  rtcs_db_PH_kcca_PropertySubType: Array<Rtcs_Db_Ph_Kcca_PropertySubType>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_PropertySubType" */
  rtcs_db_PH_kcca_PropertySubType_aggregate: Rtcs_Db_Ph_Kcca_PropertySubType_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_PropertyType" */
  rtcs_db_PH_kcca_PropertyType: Array<Rtcs_Db_Ph_Kcca_PropertyType>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_PropertyType" */
  rtcs_db_PH_kcca_PropertyType_aggregate: Rtcs_Db_Ph_Kcca_PropertyType_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_Property" */
  rtcs_db_PH_kcca_Property_aggregate: Rtcs_Db_Ph_Kcca_Property_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_RawProperties1" */
  rtcs_db_PH_kcca_RawProperties1: Array<Rtcs_Db_Ph_Kcca_RawProperties1>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_RawProperties1" */
  rtcs_db_PH_kcca_RawProperties1_aggregate: Rtcs_Db_Ph_Kcca_RawProperties1_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_RawProperties2" */
  rtcs_db_PH_kcca_RawProperties2: Array<Rtcs_Db_Ph_Kcca_RawProperties2>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_RawProperties2" */
  rtcs_db_PH_kcca_RawProperties2_aggregate: Rtcs_Db_Ph_Kcca_RawProperties2_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_ResidentialProperties" */
  rtcs_db_PH_kcca_ResidentialProperties: Array<Rtcs_Db_Ph_Kcca_ResidentialProperties>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_ResidentialProperties" */
  rtcs_db_PH_kcca_ResidentialProperties_aggregate: Rtcs_Db_Ph_Kcca_ResidentialProperties_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_RevenueType" */
  rtcs_db_PH_kcca_RevenueType: Array<Rtcs_Db_Ph_Kcca_RevenueType>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_RevenueType" */
  rtcs_db_PH_kcca_RevenueType_aggregate: Rtcs_Db_Ph_Kcca_RevenueType_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_SubCounty" */
  rtcs_db_PH_kcca_SubCounty: Array<Rtcs_Db_Ph_Kcca_SubCounty>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_SubCounty" */
  rtcs_db_PH_kcca_SubCounty_aggregate: Rtcs_Db_Ph_Kcca_SubCounty_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
  rtcs_db_PH_kcca_SubpropertyTypeConversion: Array<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
  rtcs_db_PH_kcca_SubpropertyTypeConversion_aggregate: Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_TradingLicense" */
  rtcs_db_PH_kcca_TradingLicense: Array<Rtcs_Db_Ph_Kcca_TradingLicense>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_TradingLicense" */
  rtcs_db_PH_kcca_TradingLicense_aggregate: Rtcs_Db_Ph_Kcca_TradingLicense_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_Village" */
  rtcs_db_PH_kcca_Village: Array<Rtcs_Db_Ph_Kcca_Village>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_Village" */
  rtcs_db_PH_kcca_Village_aggregate: Rtcs_Db_Ph_Kcca_Village_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_master_MasterIndiv" */
  rtcs_db_PH_master_MasterIndiv: Array<Rtcs_Db_Ph_Master_MasterIndiv>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_master_MasterIndiv" */
  rtcs_db_PH_master_MasterIndiv_aggregate: Rtcs_Db_Ph_Master_MasterIndiv_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_mlhud_KCCA" */
  rtcs_db_PH_mlhud_KCCA: Array<Rtcs_Db_Ph_Mlhud_Kcca>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_mlhud_KCCA" */
  rtcs_db_PH_mlhud_KCCA_aggregate: Rtcs_Db_Ph_Mlhud_Kcca_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_mlhud_Mukono" */
  rtcs_db_PH_mlhud_Mukono: Array<Rtcs_Db_Ph_Mlhud_Mukono>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_mlhud_Mukono" */
  rtcs_db_PH_mlhud_Mukono_aggregate: Rtcs_Db_Ph_Mlhud_Mukono_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_mlhud_Properties" */
  rtcs_db_PH_mlhud_Properties: Array<Rtcs_Db_Ph_Mlhud_Properties>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_mlhud_Properties" */
  rtcs_db_PH_mlhud_Properties_aggregate: Rtcs_Db_Ph_Mlhud_Properties_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_mlhud_WakisoBusiro" */
  rtcs_db_PH_mlhud_WakisoBusiro: Array<Rtcs_Db_Ph_Mlhud_WakisoBusiro>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_mlhud_WakisoBusiro" */
  rtcs_db_PH_mlhud_WakisoBusiro_aggregate: Rtcs_Db_Ph_Mlhud_WakisoBusiro_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_mlhud_WakisoKyadondo" */
  rtcs_db_PH_mlhud_WakisoKyadondo: Array<Rtcs_Db_Ph_Mlhud_WakisoKyadondo>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_mlhud_WakisoKyadondo" */
  rtcs_db_PH_mlhud_WakisoKyadondo_aggregate: Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_nira_Nira" */
  rtcs_db_PH_nira_Nira: Array<Rtcs_Db_Ph_Nira_Nira>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_nira_Nira" */
  rtcs_db_PH_nira_Nira_aggregate: Rtcs_Db_Ph_Nira_Nira_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_nira_RawData" */
  rtcs_db_PH_nira_RawData: Array<Rtcs_Db_Ph_Nira_RawData>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_nira_RawData" */
  rtcs_db_PH_nira_RawData_aggregate: Rtcs_Db_Ph_Nira_RawData_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_nwsc_Customers" */
  rtcs_db_PH_nwsc_Customers: Array<Rtcs_Db_Ph_Nwsc_Customers>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_nwsc_Customers" */
  rtcs_db_PH_nwsc_Customers_aggregate: Rtcs_Db_Ph_Nwsc_Customers_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_nwsc_NWCustomers" */
  rtcs_db_PH_nwsc_NWCustomers: Array<Rtcs_Db_Ph_Nwsc_NwCustomers>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_nwsc_NWCustomers" */
  rtcs_db_PH_nwsc_NWCustomers_aggregate: Rtcs_Db_Ph_Nwsc_NwCustomers_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_nwsc_RPT_Transactions" */
  rtcs_db_PH_nwsc_RPT_Transactions: Array<Rtcs_Db_Ph_Nwsc_Rpt_Transactions>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_nwsc_RPT_Transactions" */
  rtcs_db_PH_nwsc_RPT_Transactions_aggregate: Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_nwsc_Transactions" */
  rtcs_db_PH_nwsc_Transactions: Array<Rtcs_Db_Ph_Nwsc_Transactions>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_nwsc_Transactions" */
  rtcs_db_PH_nwsc_Transactions_aggregate: Rtcs_Db_Ph_Nwsc_Transactions_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_nwsc_URATransactions" */
  rtcs_db_PH_nwsc_URATransactions: Array<Rtcs_Db_Ph_Nwsc_UraTransactions>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_nwsc_URATransactions" */
  rtcs_db_PH_nwsc_URATransactions_aggregate: Rtcs_Db_Ph_Nwsc_UraTransactions_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rcapture_RPTUnittypes" */
  rtcs_db_PH_rcapture_RPTUnittypes: Array<Rtcs_Db_Ph_Rcapture_RptUnittypes>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rcapture_RPTUnittypes" */
  rtcs_db_PH_rcapture_RPTUnittypes_aggregate: Rtcs_Db_Ph_Rcapture_RptUnittypes_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rcapture_RPTlessors" */
  rtcs_db_PH_rcapture_RPTlessors: Array<Rtcs_Db_Ph_Rcapture_RpTlessors>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rcapture_RPTlessors" */
  rtcs_db_PH_rcapture_RPTlessors_aggregate: Rtcs_Db_Ph_Rcapture_RpTlessors_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rcapture_RPTmeta" */
  rtcs_db_PH_rcapture_RPTmeta: Array<Rtcs_Db_Ph_Rcapture_RpTmeta>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rcapture_RPTmeta" */
  rtcs_db_PH_rcapture_RPTmeta_aggregate: Rtcs_Db_Ph_Rcapture_RpTmeta_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rcapture_RPTproperties" */
  rtcs_db_PH_rcapture_RPTproperties: Array<Rtcs_Db_Ph_Rcapture_RpTproperties>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rcapture_RPTproperties" */
  rtcs_db_PH_rcapture_RPTproperties_aggregate: Rtcs_Db_Ph_Rcapture_RpTproperties_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rcapture_RPTtenants" */
  rtcs_db_PH_rcapture_RPTtenants: Array<Rtcs_Db_Ph_Rcapture_RpTtenants>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rcapture_RPTtenants" */
  rtcs_db_PH_rcapture_RPTtenants_aggregate: Rtcs_Db_Ph_Rcapture_RpTtenants_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_Individual" */
  rtcs_db_PH_rmatch_Individual: Array<Rtcs_Db_Ph_Rmatch_Individual>;
  /** fetch data from the table: "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
  rtcs_db_PH_rmatch_IndividualComplianceDetailReport: Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
  rtcs_db_PH_rmatch_IndividualComplianceDetailReport_aggregate: Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
  rtcs_db_PH_rmatch_IndividualComplianceSummaryReport: Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
  rtcs_db_PH_rmatch_IndividualComplianceSummaryReport_aggregate: Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_IndividualRiskScore" */
  rtcs_db_PH_rmatch_IndividualRiskScore: Array<Rtcs_Db_Ph_Rmatch_IndividualRiskScore>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_IndividualRiskScore" */
  rtcs_db_PH_rmatch_IndividualRiskScore_aggregate: Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_Individual" */
  rtcs_db_PH_rmatch_Individual_aggregate: Rtcs_Db_Ph_Rmatch_Individual_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_IndividualsNetwork" */
  rtcs_db_PH_rmatch_IndividualsNetwork: Array<Rtcs_Db_Ph_Rmatch_IndividualsNetwork>;
  /** fetch data from the table: "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
  rtcs_db_PH_rmatch_IndividualsNetworkTesting: Array<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
  rtcs_db_PH_rmatch_IndividualsNetworkTesting_aggregate: Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_IndividualsNetwork" */
  rtcs_db_PH_rmatch_IndividualsNetwork_aggregate: Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
  rtcs_db_PH_rmatch_KccaToKccaIndividualOwners: Array<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
  rtcs_db_PH_rmatch_KccaToKccaIndividualOwners_aggregate: Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
  rtcs_db_PH_rmatch_KccaToKccaNonindividualOwners: Array<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
  rtcs_db_PH_rmatch_KccaToKccaNonindividualOwners_aggregate: Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
  rtcs_db_PH_rmatch_MatchIndividualsProperties: Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
  rtcs_db_PH_rmatch_MatchIndividualsProperties_aggregate: Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_MatchIndividualsProps" */
  rtcs_db_PH_rmatch_MatchIndividualsProps: Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_MatchIndividualsProps" */
  rtcs_db_PH_rmatch_MatchIndividualsProps_aggregate: Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_NoReturns" */
  rtcs_db_PH_rmatch_NoReturns: Array<Rtcs_Db_Ph_Rmatch_NoReturns>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_NoReturns" */
  rtcs_db_PH_rmatch_NoReturns_aggregate: Rtcs_Db_Ph_Rmatch_NoReturns_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_NonIndividual" */
  rtcs_db_PH_rmatch_NonIndividual: Array<Rtcs_Db_Ph_Rmatch_NonIndividual>;
  /** fetch data from the table: "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
  rtcs_db_PH_rmatch_NonIndividualComplianceDetailReport: Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
  rtcs_db_PH_rmatch_NonIndividualComplianceDetailReport_aggregate: Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
  rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport: Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
  rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport_aggregate: Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
  rtcs_db_PH_rmatch_NonIndividualRiskScore: Array<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
  rtcs_db_PH_rmatch_NonIndividualRiskScore_aggregate: Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_NonIndividual" */
  rtcs_db_PH_rmatch_NonIndividual_aggregate: Rtcs_Db_Ph_Rmatch_NonIndividual_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_NonIndividuals" */
  rtcs_db_PH_rmatch_NonIndividuals: Array<Rtcs_Db_Ph_Rmatch_NonIndividuals>;
  /** fetch data from the table: "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
  rtcs_db_PH_rmatch_NonIndividualsNetwork: Array<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
  rtcs_db_PH_rmatch_NonIndividualsNetwork_aggregate: Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_NonIndividuals" */
  rtcs_db_PH_rmatch_NonIndividuals_aggregate: Rtcs_Db_Ph_Rmatch_NonIndividuals_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_NonindividualOwners" */
  rtcs_db_PH_rmatch_NonindividualOwners: Array<Rtcs_Db_Ph_Rmatch_NonindividualOwners>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_NonindividualOwners" */
  rtcs_db_PH_rmatch_NonindividualOwners_aggregate: Rtcs_Db_Ph_Rmatch_NonindividualOwners_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_Nonindividuals" */
  rtcs_db_PH_rmatch_Nonindividuals: Array<Rtcs_Db_Ph_Rmatch_Nonindividuals>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_Nonindividuals" */
  rtcs_db_PH_rmatch_Nonindividuals_aggregate: Rtcs_Db_Ph_Rmatch_Nonindividuals_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_PropertiesComposite" */
  rtcs_db_PH_rmatch_PropertiesComposite: Array<Rtcs_Db_Ph_Rmatch_PropertiesComposite>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_PropertiesComposite" */
  rtcs_db_PH_rmatch_PropertiesComposite_aggregate: Rtcs_Db_Ph_Rmatch_PropertiesComposite_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_PropertiesNetwork" */
  rtcs_db_PH_rmatch_PropertiesNetwork: Array<Rtcs_Db_Ph_Rmatch_PropertiesNetwork>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_PropertiesNetwork" */
  rtcs_db_PH_rmatch_PropertiesNetwork_aggregate: Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_PropertyNetwork" */
  rtcs_db_PH_rmatch_PropertyNetwork: Array<Rtcs_Db_Ph_Rmatch_PropertyNetwork>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_PropertyNetwork" */
  rtcs_db_PH_rmatch_PropertyNetwork_aggregate: Rtcs_Db_Ph_Rmatch_PropertyNetwork_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_RMatchDetailed904" */
  rtcs_db_PH_rmatch_RMatchDetailed904: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed904>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_RMatchDetailed904" */
  rtcs_db_PH_rmatch_RMatchDetailed904_aggregate: Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_RMatchDetailed916" */
  rtcs_db_PH_rmatch_RMatchDetailed916: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916>;
  /** fetch data from the table: "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
  rtcs_db_PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
  rtcs_db_PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregate: Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_RMatchDetailed916" */
  rtcs_db_PH_rmatch_RMatchDetailed916_aggregate: Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_RMatchDetailed937" */
  rtcs_db_PH_rmatch_RMatchDetailed937: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed937>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_RMatchDetailed937" */
  rtcs_db_PH_rmatch_RMatchDetailed937_aggregate: Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_RMatchKccaProperties" */
  rtcs_db_PH_rmatch_RMatchKccaProperties: Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_RMatchKccaProperties" */
  rtcs_db_PH_rmatch_RMatchKccaProperties_aggregate: Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_RMatchKccaProperty" */
  rtcs_db_PH_rmatch_RMatchKccaProperty: Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_RMatchKccaProperty" */
  rtcs_db_PH_rmatch_RMatchKccaProperty_aggregate: Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
  rtcs_db_PH_rmatch_RMatchNonIndividual903: Array<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
  rtcs_db_PH_rmatch_RMatchNonIndividual903_aggregate: Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_RMatchProperties1115" */
  rtcs_db_PH_rmatch_RMatchProperties1115: Array<Rtcs_Db_Ph_Rmatch_RMatchProperties1115>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_RMatchProperties1115" */
  rtcs_db_PH_rmatch_RMatchProperties1115_aggregate: Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_RMatchSummary904" */
  rtcs_db_PH_rmatch_RMatchSummary904: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary904>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_RMatchSummary904" */
  rtcs_db_PH_rmatch_RMatchSummary904_aggregate: Rtcs_Db_Ph_Rmatch_RMatchSummary904_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_RMatchSummary916" */
  rtcs_db_PH_rmatch_RMatchSummary916: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916>;
  /** fetch data from the table: "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
  rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
  rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregate: Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_RMatchSummary916" */
  rtcs_db_PH_rmatch_RMatchSummary916_aggregate: Rtcs_Db_Ph_Rmatch_RMatchSummary916_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_RMatchSummary937" */
  rtcs_db_PH_rmatch_RMatchSummary937: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary937>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_RMatchSummary937" */
  rtcs_db_PH_rmatch_RMatchSummary937_aggregate: Rtcs_Db_Ph_Rmatch_RMatchSummary937_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_TinsURA" */
  rtcs_db_PH_rmatch_TinsURA: Array<Rtcs_Db_Ph_Rmatch_TinsUra>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_TinsURA" */
  rtcs_db_PH_rmatch_TinsURA_aggregate: Rtcs_Db_Ph_Rmatch_TinsUra_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_Undeclared" */
  rtcs_db_PH_rmatch_Undeclared: Array<Rtcs_Db_Ph_Rmatch_Undeclared>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_Undeclared" */
  rtcs_db_PH_rmatch_Undeclared_aggregate: Rtcs_Db_Ph_Rmatch_Undeclared_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_Underreported" */
  rtcs_db_PH_rmatch_Underreported: Array<Rtcs_Db_Ph_Rmatch_Underreported>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_Underreported" */
  rtcs_db_PH_rmatch_Underreported_aggregate: Rtcs_Db_Ph_Rmatch_Underreported_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_Unregistered" */
  rtcs_db_PH_rmatch_Unregistered: Array<Rtcs_Db_Ph_Rmatch_Unregistered>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_Unregistered" */
  rtcs_db_PH_rmatch_Unregistered_aggregate: Rtcs_Db_Ph_Rmatch_Unregistered_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_mlhudConsolidated" */
  rtcs_db_PH_rmatch_mlhudConsolidated: Array<Rtcs_Db_Ph_Rmatch_MlhudConsolidated>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_mlhudConsolidated" */
  rtcs_db_PH_rmatch_mlhudConsolidated_aggregate: Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
  rtcs_db_PH_streetmap_OsmMukonoMunicipality: Array<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
  rtcs_db_PH_streetmap_OsmMukonoMunicipality_aggregate: Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
  rtcs_db_PH_streetmap_OsmMukonoNakifuma: Array<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
  rtcs_db_PH_streetmap_OsmMukonoNakifuma_aggregate: Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
  rtcs_db_PH_streetmap_OsmWakisoEntebbe: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
  rtcs_db_PH_streetmap_OsmWakisoEntebbe_aggregate: Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_streetmap_OsmWakisoKira" */
  rtcs_db_PH_streetmap_OsmWakisoKira: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoKira>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_streetmap_OsmWakisoKira" */
  rtcs_db_PH_streetmap_OsmWakisoKira_aggregate: Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
  rtcs_db_PH_streetmap_OsmWakisoMakindyeSsabagabo: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
  rtcs_db_PH_streetmap_OsmWakisoMakindyeSsabagabo_aggregate: Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_streetmap_OsmWakisoNansana" */
  rtcs_db_PH_streetmap_OsmWakisoNansana: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_streetmap_OsmWakisoNansana" */
  rtcs_db_PH_streetmap_OsmWakisoNansana_aggregate: Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ucc_UCC" */
  rtcs_db_PH_ucc_UCC: Array<Rtcs_Db_Ph_Ucc_Ucc>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ucc_UCC" */
  rtcs_db_PH_ucc_UCC_aggregate: Rtcs_Db_Ph_Ucc_Ucc_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_umeme_SubscriberAndProperty" */
  rtcs_db_PH_umeme_SubscriberAndProperty: Array<Rtcs_Db_Ph_Umeme_SubscriberAndProperty>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_umeme_SubscriberAndProperty" */
  rtcs_db_PH_umeme_SubscriberAndProperty_aggregate: Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_umeme_Transactions" */
  rtcs_db_PH_umeme_Transactions: Array<Rtcs_Db_Ph_Umeme_Transactions>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_umeme_Transactions" */
  rtcs_db_PH_umeme_Transactions_aggregate: Rtcs_Db_Ph_Umeme_Transactions_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_BalanceSheetInformation" */
  rtcs_db_PH_ura_BalanceSheetInformation: Array<Rtcs_Db_Ph_Ura_BalanceSheetInformation>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_BalanceSheetInformation" */
  rtcs_db_PH_ura_BalanceSheetInformation_aggregate: Rtcs_Db_Ph_Ura_BalanceSheetInformation_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
  rtcs_db_PH_ura_CombinedOfficersAndOwners: Array<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
  rtcs_db_PH_ura_CombinedOfficersAndOwners_aggregate: Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_EmploymentIncomeDetail" */
  rtcs_db_PH_ura_EmploymentIncomeDetail: Array<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_EmploymentIncomeDetail" */
  rtcs_db_PH_ura_EmploymentIncomeDetail_aggregate: Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
  rtcs_db_PH_ura_GazetteCommercialRatesPerSquareFootage: Array<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
  rtcs_db_PH_ura_GazetteCommercialRatesPerSquareFootage_aggregate: Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
  rtcs_db_PH_ura_GazetteResidentialRatesPerBedroom: Array<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
  rtcs_db_PH_ura_GazetteResidentialRatesPerBedroom_aggregate: Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_HoldingOfficers" */
  rtcs_db_PH_ura_HoldingOfficers: Array<Rtcs_Db_Ph_Ura_HoldingOfficers>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_HoldingOfficers" */
  rtcs_db_PH_ura_HoldingOfficers_aggregate: Rtcs_Db_Ph_Ura_HoldingOfficers_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_HoldingOwner" */
  rtcs_db_PH_ura_HoldingOwner: Array<Rtcs_Db_Ph_Ura_HoldingOwner>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_HoldingOwner" */
  rtcs_db_PH_ura_HoldingOwner_aggregate: Rtcs_Db_Ph_Ura_HoldingOwner_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_IncomeTaxHolding" */
  rtcs_db_PH_ura_IncomeTaxHolding: Array<Rtcs_Db_Ph_Ura_IncomeTaxHolding>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_IncomeTaxHolding" */
  rtcs_db_PH_ura_IncomeTaxHolding_aggregate: Rtcs_Db_Ph_Ura_IncomeTaxHolding_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
  rtcs_db_PH_ura_IncomeTaxSummaryIndividual: Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
  rtcs_db_PH_ura_IncomeTaxSummaryIndividual_aggregate: Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
  rtcs_db_PH_ura_IncomeTaxSummaryNonIndividual: Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
  rtcs_db_PH_ura_IncomeTaxSummaryNonIndividual_aggregate: Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_IndividualMortgage" */
  rtcs_db_PH_ura_IndividualMortgage: Array<Rtcs_Db_Ph_Ura_IndividualMortgage>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_IndividualMortgage" */
  rtcs_db_PH_ura_IndividualMortgage_aggregate: Rtcs_Db_Ph_Ura_IndividualMortgage_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
  rtcs_db_PH_ura_IndividualsAssociatedBusiness: Array<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
  rtcs_db_PH_ura_IndividualsAssociatedBusiness_aggregate: Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_LandlordIncomeDetail" */
  rtcs_db_PH_ura_LandlordIncomeDetail: Array<Rtcs_Db_Ph_Ura_LandlordIncomeDetail>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_LandlordIncomeDetail" */
  rtcs_db_PH_ura_LandlordIncomeDetail_aggregate: Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_LandlordIncomeSummary" */
  rtcs_db_PH_ura_LandlordIncomeSummary: Array<Rtcs_Db_Ph_Ura_LandlordIncomeSummary>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_LandlordIncomeSummary" */
  rtcs_db_PH_ura_LandlordIncomeSummary_aggregate: Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
  rtcs_db_PH_ura_NonIndividualsAssociatedBusiness: Array<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
  rtcs_db_PH_ura_NonIndividualsAssociatedBusiness_aggregate: Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_RegAssociates" */
  rtcs_db_PH_ura_RegAssociates: Array<Rtcs_Db_Ph_Ura_RegAssociates>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_RegAssociates" */
  rtcs_db_PH_ura_RegAssociates_aggregate: Rtcs_Db_Ph_Ura_RegAssociates_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_RegIndividual" */
  rtcs_db_PH_ura_RegIndividual: Array<Rtcs_Db_Ph_Ura_RegIndividual>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_RegIndividual" */
  rtcs_db_PH_ura_RegIndividual_aggregate: Rtcs_Db_Ph_Ura_RegIndividual_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_RegIndividual_sid" */
  rtcs_db_PH_ura_RegIndividual_sid: Array<Rtcs_Db_Ph_Ura_RegIndividual_Sid>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_RegIndividual_sid" */
  rtcs_db_PH_ura_RegIndividual_sid_aggregate: Rtcs_Db_Ph_Ura_RegIndividual_Sid_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_RegNonIndividual" */
  rtcs_db_PH_ura_RegNonIndividual: Array<Rtcs_Db_Ph_Ura_RegNonIndividual>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_RegNonIndividual" */
  rtcs_db_PH_ura_RegNonIndividual_aggregate: Rtcs_Db_Ph_Ura_RegNonIndividual_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_RegNonIndividual_sid" */
  rtcs_db_PH_ura_RegNonIndividual_sid: Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_RegNonIndividual_sid" */
  rtcs_db_PH_ura_RegNonIndividual_sid_aggregate: Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_RentExpenseDetails" */
  rtcs_db_PH_ura_RentExpenseDetails: Array<Rtcs_Db_Ph_Ura_RentExpenseDetails>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_RentExpenseDetails" */
  rtcs_db_PH_ura_RentExpenseDetails_aggregate: Rtcs_Db_Ph_Ura_RentExpenseDetails_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_RentalLandLordIncome" */
  rtcs_db_PH_ura_RentalLandLordIncome: Array<Rtcs_Db_Ph_Ura_RentalLandLordIncome>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_RentalLandLordIncome" */
  rtcs_db_PH_ura_RentalLandLordIncome_aggregate: Rtcs_Db_Ph_Ura_RentalLandLordIncome_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_RentalTenantPayments" */
  rtcs_db_PH_ura_RentalTenantPayments: Array<Rtcs_Db_Ph_Ura_RentalTenantPayments>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_RentalTenantPayments" */
  rtcs_db_PH_ura_RentalTenantPayments_aggregate: Rtcs_Db_Ph_Ura_RentalTenantPayments_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
  rtcs_db_PH_ura_RipoIndRentalIncSummary: Array<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
  rtcs_db_PH_ura_RipoIndRentalIncSummary_aggregate: Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_TenantIncomeDetail" */
  rtcs_db_PH_ura_TenantIncomeDetail: Array<Rtcs_Db_Ph_Ura_TenantIncomeDetail>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_TenantIncomeDetail" */
  rtcs_db_PH_ura_TenantIncomeDetail_aggregate: Rtcs_Db_Ph_Ura_TenantIncomeDetail_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_Town" */
  rtcs_db_PH_ura_Town: Array<Rtcs_Db_Ph_Ura_Town>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_Town" */
  rtcs_db_PH_ura_Town_aggregate: Rtcs_Db_Ph_Ura_Town_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
  rtcs_db_PH_ursb_CompaniesAndBusinessNamesURSB: Array<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
  rtcs_db_PH_ursb_CompaniesAndBusinessNamesURSB_aggregate: Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
  rtcs_db_RPT_IndividualComplianceSummaryPlusRisk: Array<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
  rtcs_db_RPT_IndividualComplianceSummaryPlusRisk_aggregate: Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_IndividualComposite" */
  rtcs_db_RPT_IndividualComposite: Array<Rtcs_Db_Rpt_IndividualComposite>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_IndividualComposite" */
  rtcs_db_RPT_IndividualComposite_aggregate: Rtcs_Db_Rpt_IndividualComposite_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_IndividualRegistration" */
  rtcs_db_RPT_IndividualRegistration: Array<Rtcs_Db_Rpt_IndividualRegistration>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_IndividualRegistration" */
  rtcs_db_RPT_IndividualRegistration_aggregate: Rtcs_Db_Rpt_IndividualRegistration_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_IndividualTaxSummaryData" */
  rtcs_db_RPT_IndividualTaxSummaryData: Array<Rtcs_Db_Rpt_IndividualTaxSummaryData>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_IndividualTaxSummaryData" */
  rtcs_db_RPT_IndividualTaxSummaryData_aggregate: Rtcs_Db_Rpt_IndividualTaxSummaryData_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_KccaAffiliates" */
  rtcs_db_RPT_KccaAffiliates: Array<Rtcs_Db_Rpt_KccaAffiliates>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_KccaAffiliates" */
  rtcs_db_RPT_KccaAffiliates_aggregate: Rtcs_Db_Rpt_KccaAffiliates_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_KccaCustomerRegistrationData" */
  rtcs_db_RPT_KccaCustomerRegistrationData: Array<Rtcs_Db_Rpt_KccaCustomerRegistrationData>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_KccaCustomerRegistrationData" */
  rtcs_db_RPT_KccaCustomerRegistrationData_aggregate: Rtcs_Db_Rpt_KccaCustomerRegistrationData_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_KccaPropertyRegistration" */
  rtcs_db_RPT_KccaPropertyRegistration: Array<Rtcs_Db_Rpt_KccaPropertyRegistration>;
  /** fetch data from the table: "rtcs_db.RPT_KccaPropertyRegistrationData" */
  rtcs_db_RPT_KccaPropertyRegistrationData: Array<Rtcs_Db_Rpt_KccaPropertyRegistrationData>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_KccaPropertyRegistrationData" */
  rtcs_db_RPT_KccaPropertyRegistrationData_aggregate: Rtcs_Db_Rpt_KccaPropertyRegistrationData_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_KccaPropertyRegistration" */
  rtcs_db_RPT_KccaPropertyRegistration_aggregate: Rtcs_Db_Rpt_KccaPropertyRegistration_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_NonIndividualBalanceSheet" */
  rtcs_db_RPT_NonIndividualBalanceSheet: Array<Rtcs_Db_Rpt_NonIndividualBalanceSheet>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_NonIndividualBalanceSheet" */
  rtcs_db_RPT_NonIndividualBalanceSheet_aggregate: Rtcs_Db_Rpt_NonIndividualBalanceSheet_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
  rtcs_db_RPT_NonIndividualComplianceSummaryPlusRisk: Array<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
  rtcs_db_RPT_NonIndividualComplianceSummaryPlusRisk_aggregate: Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_NonIndividualComposite" */
  rtcs_db_RPT_NonIndividualComposite: Array<Rtcs_Db_Rpt_NonIndividualComposite>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_NonIndividualComposite" */
  rtcs_db_RPT_NonIndividualComposite_aggregate: Rtcs_Db_Rpt_NonIndividualComposite_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_NonIndividualTaxSummaryData" */
  rtcs_db_RPT_NonIndividualTaxSummaryData: Array<Rtcs_Db_Rpt_NonIndividualTaxSummaryData>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_NonIndividualTaxSummaryData" */
  rtcs_db_RPT_NonIndividualTaxSummaryData_aggregate: Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_PropertiesComposite" */
  rtcs_db_RPT_PropertiesComposite: Array<Rtcs_Db_Rpt_PropertiesComposite>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_PropertiesComposite" */
  rtcs_db_RPT_PropertiesComposite_aggregate: Rtcs_Db_Rpt_PropertiesComposite_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_PropertyValuation" */
  rtcs_db_RPT_PropertyValuation: Array<Rtcs_Db_Rpt_PropertyValuation>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_PropertyValuation" */
  rtcs_db_RPT_PropertyValuation_aggregate: Rtcs_Db_Rpt_PropertyValuation_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_RentalTenantPayments" */
  rtcs_db_RPT_RentalTenantPayments: Array<Rtcs_Db_Rpt_RentalTenantPayments>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_RentalTenantPayments" */
  rtcs_db_RPT_RentalTenantPayments_aggregate: Rtcs_Db_Rpt_RentalTenantPayments_Aggregate;
  /** fetch data from the table: "rtcs_db.aggregate_result" */
  rtcs_db_aggregate_result: Array<Rtcs_Db_Aggregate_Result>;
  /** fetch aggregated fields from the table: "rtcs_db.aggregate_result" */
  rtcs_db_aggregate_result_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** fetch data from the table: "rtcs_db.aggregate_result_txt" */
  rtcs_db_aggregate_result_txt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** fetch aggregated fields from the table: "rtcs_db.aggregate_result_txt" */
  rtcs_db_aggregate_result_txt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** fetch data from the table: "rtcs_db.gazette_CommercialRates" */
  rtcs_db_gazette_CommercialRates: Array<Rtcs_Db_Gazette_CommercialRates>;
  /** fetch aggregated fields from the table: "rtcs_db.gazette_CommercialRates" */
  rtcs_db_gazette_CommercialRates_aggregate: Rtcs_Db_Gazette_CommercialRates_Aggregate;
  /** fetch data from the table: "rtcs_db.gazette_ResidentialRates" */
  rtcs_db_gazette_ResidentialRates: Array<Rtcs_Db_Gazette_ResidentialRates>;
  /** fetch aggregated fields from the table: "rtcs_db.gazette_ResidentialRates" */
  rtcs_db_gazette_ResidentialRates_aggregate: Rtcs_Db_Gazette_ResidentialRates_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_AssociateType" */
  rtcs_db_kcca_AssociateType: Array<Rtcs_Db_Kcca_AssociateType>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_AssociateType" */
  rtcs_db_kcca_AssociateType_aggregate: Rtcs_Db_Kcca_AssociateType_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_CommercialProperties" */
  rtcs_db_kcca_CommercialProperties: Array<Rtcs_Db_Kcca_CommercialProperties>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_CommercialProperties" */
  rtcs_db_kcca_CommercialProperties_aggregate: Rtcs_Db_Kcca_CommercialProperties_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_County" */
  rtcs_db_kcca_County: Array<Rtcs_Db_Kcca_County>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_County" */
  rtcs_db_kcca_County_aggregate: Rtcs_Db_Kcca_County_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_Customer" */
  rtcs_db_kcca_Customer: Array<Rtcs_Db_Kcca_Customer>;
  /** fetch data from the table: "rtcs_db.kcca_CustomerApplicantType" */
  rtcs_db_kcca_CustomerApplicantType: Array<Rtcs_Db_Kcca_CustomerApplicantType>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_CustomerApplicantType" */
  rtcs_db_kcca_CustomerApplicantType_aggregate: Rtcs_Db_Kcca_CustomerApplicantType_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_CustomerBusinessAssociate" */
  rtcs_db_kcca_CustomerBusinessAssociate: Array<Rtcs_Db_Kcca_CustomerBusinessAssociate>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_CustomerBusinessAssociate" */
  rtcs_db_kcca_CustomerBusinessAssociate_aggregate: Rtcs_Db_Kcca_CustomerBusinessAssociate_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_CustomerBusinessType" */
  rtcs_db_kcca_CustomerBusinessType: Array<Rtcs_Db_Kcca_CustomerBusinessType>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_CustomerBusinessType" */
  rtcs_db_kcca_CustomerBusinessType_aggregate: Rtcs_Db_Kcca_CustomerBusinessType_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
  rtcs_db_kcca_CustomerCoinDeactivateReactive: Array<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
  rtcs_db_kcca_CustomerCoinDeactivateReactive_aggregate: Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_CustomerContactPerson" */
  rtcs_db_kcca_CustomerContactPerson: Array<Rtcs_Db_Kcca_CustomerContactPerson>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_CustomerContactPerson" */
  rtcs_db_kcca_CustomerContactPerson_aggregate: Rtcs_Db_Kcca_CustomerContactPerson_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_CustomerDocumentItem" */
  rtcs_db_kcca_CustomerDocumentItem: Array<Rtcs_Db_Kcca_CustomerDocumentItem>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_CustomerDocumentItem" */
  rtcs_db_kcca_CustomerDocumentItem_aggregate: Rtcs_Db_Kcca_CustomerDocumentItem_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_CustomerGuardian" */
  rtcs_db_kcca_CustomerGuardian: Array<Rtcs_Db_Kcca_CustomerGuardian>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_CustomerGuardian" */
  rtcs_db_kcca_CustomerGuardian_aggregate: Rtcs_Db_Kcca_CustomerGuardian_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_CustomerReference" */
  rtcs_db_kcca_CustomerReference: Array<Rtcs_Db_Kcca_CustomerReference>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_CustomerReference" */
  rtcs_db_kcca_CustomerReference_aggregate: Rtcs_Db_Kcca_CustomerReference_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_CustomerRegistrationReason" */
  rtcs_db_kcca_CustomerRegistrationReason: Array<Rtcs_Db_Kcca_CustomerRegistrationReason>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_CustomerRegistrationReason" */
  rtcs_db_kcca_CustomerRegistrationReason_aggregate: Rtcs_Db_Kcca_CustomerRegistrationReason_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_CustomerRevenueAgent" */
  rtcs_db_kcca_CustomerRevenueAgent: Array<Rtcs_Db_Kcca_CustomerRevenueAgent>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_CustomerRevenueAgent" */
  rtcs_db_kcca_CustomerRevenueAgent_aggregate: Rtcs_Db_Kcca_CustomerRevenueAgent_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_CustomerType" */
  rtcs_db_kcca_CustomerType: Array<Rtcs_Db_Kcca_CustomerType>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_CustomerType" */
  rtcs_db_kcca_CustomerType_aggregate: Rtcs_Db_Kcca_CustomerType_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_Customer" */
  rtcs_db_kcca_Customer_aggregate: Rtcs_Db_Kcca_Customer_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_Customer_sid" */
  rtcs_db_kcca_Customer_sid: Array<Rtcs_Db_Kcca_Customer_Sid>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_Customer_sid" */
  rtcs_db_kcca_Customer_sid_aggregate: Rtcs_Db_Kcca_Customer_Sid_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_District" */
  rtcs_db_kcca_District: Array<Rtcs_Db_Kcca_District>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_District" */
  rtcs_db_kcca_District_aggregate: Rtcs_Db_Kcca_District_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_Division" */
  rtcs_db_kcca_Division: Array<Rtcs_Db_Kcca_Division>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_Division" */
  rtcs_db_kcca_Division_aggregate: Rtcs_Db_Kcca_Division_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_ImgsData" */
  rtcs_db_kcca_ImgsData: Array<Rtcs_Db_Kcca_ImgsData>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_ImgsData" */
  rtcs_db_kcca_ImgsData_aggregate: Rtcs_Db_Kcca_ImgsData_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_Parish" */
  rtcs_db_kcca_Parish: Array<Rtcs_Db_Kcca_Parish>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_Parish" */
  rtcs_db_kcca_Parish_aggregate: Rtcs_Db_Kcca_Parish_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_PaymentDetails" */
  rtcs_db_kcca_PaymentDetails: Array<Rtcs_Db_Kcca_PaymentDetails>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_PaymentDetails" */
  rtcs_db_kcca_PaymentDetails_aggregate: Rtcs_Db_Kcca_PaymentDetails_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_Payments" */
  rtcs_db_kcca_Payments: Array<Rtcs_Db_Kcca_Payments>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_Payments" */
  rtcs_db_kcca_Payments_aggregate: Rtcs_Db_Kcca_Payments_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_Property" */
  rtcs_db_kcca_Property: Array<Rtcs_Db_Kcca_Property>;
  /** fetch data from the table: "rtcs_db.kcca_PropertyFloors" */
  rtcs_db_kcca_PropertyFloors: Array<Rtcs_Db_Kcca_PropertyFloors>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_PropertyFloors" */
  rtcs_db_kcca_PropertyFloors_aggregate: Rtcs_Db_Kcca_PropertyFloors_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_PropertyNumberBedrooms" */
  rtcs_db_kcca_PropertyNumberBedrooms: Array<Rtcs_Db_Kcca_PropertyNumberBedrooms>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_PropertyNumberBedrooms" */
  rtcs_db_kcca_PropertyNumberBedrooms_aggregate: Rtcs_Db_Kcca_PropertyNumberBedrooms_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_PropertyRentedStatus" */
  rtcs_db_kcca_PropertyRentedStatus: Array<Rtcs_Db_Kcca_PropertyRentedStatus>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_PropertyRentedStatus" */
  rtcs_db_kcca_PropertyRentedStatus_aggregate: Rtcs_Db_Kcca_PropertyRentedStatus_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_PropertySecurity" */
  rtcs_db_kcca_PropertySecurity: Array<Rtcs_Db_Kcca_PropertySecurity>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_PropertySecurity" */
  rtcs_db_kcca_PropertySecurity_aggregate: Rtcs_Db_Kcca_PropertySecurity_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_PropertySubType" */
  rtcs_db_kcca_PropertySubType: Array<Rtcs_Db_Kcca_PropertySubType>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_PropertySubType" */
  rtcs_db_kcca_PropertySubType_aggregate: Rtcs_Db_Kcca_PropertySubType_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_PropertyType" */
  rtcs_db_kcca_PropertyType: Array<Rtcs_Db_Kcca_PropertyType>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_PropertyType" */
  rtcs_db_kcca_PropertyType_aggregate: Rtcs_Db_Kcca_PropertyType_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_Property" */
  rtcs_db_kcca_Property_aggregate: Rtcs_Db_Kcca_Property_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_RawProperties1" */
  rtcs_db_kcca_RawProperties1: Array<Rtcs_Db_Kcca_RawProperties1>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_RawProperties1" */
  rtcs_db_kcca_RawProperties1_aggregate: Rtcs_Db_Kcca_RawProperties1_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_RawProperties2" */
  rtcs_db_kcca_RawProperties2: Array<Rtcs_Db_Kcca_RawProperties2>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_RawProperties2" */
  rtcs_db_kcca_RawProperties2_aggregate: Rtcs_Db_Kcca_RawProperties2_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_ResidentialProperties" */
  rtcs_db_kcca_ResidentialProperties: Array<Rtcs_Db_Kcca_ResidentialProperties>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_ResidentialProperties" */
  rtcs_db_kcca_ResidentialProperties_aggregate: Rtcs_Db_Kcca_ResidentialProperties_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_RevenueType" */
  rtcs_db_kcca_RevenueType: Array<Rtcs_Db_Kcca_RevenueType>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_RevenueType" */
  rtcs_db_kcca_RevenueType_aggregate: Rtcs_Db_Kcca_RevenueType_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_SubCounty" */
  rtcs_db_kcca_SubCounty: Array<Rtcs_Db_Kcca_SubCounty>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_SubCounty" */
  rtcs_db_kcca_SubCounty_aggregate: Rtcs_Db_Kcca_SubCounty_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_SubpropertyTypeConversion" */
  rtcs_db_kcca_SubpropertyTypeConversion: Array<Rtcs_Db_Kcca_SubpropertyTypeConversion>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_SubpropertyTypeConversion" */
  rtcs_db_kcca_SubpropertyTypeConversion_aggregate: Rtcs_Db_Kcca_SubpropertyTypeConversion_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_TradingLicense" */
  rtcs_db_kcca_TradingLicense: Array<Rtcs_Db_Kcca_TradingLicense>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_TradingLicense" */
  rtcs_db_kcca_TradingLicense_aggregate: Rtcs_Db_Kcca_TradingLicense_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_Village" */
  rtcs_db_kcca_Village: Array<Rtcs_Db_Kcca_Village>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_Village" */
  rtcs_db_kcca_Village_aggregate: Rtcs_Db_Kcca_Village_Aggregate;
  /** fetch data from the table: "rtcs_db.master_MasterIndiv" */
  rtcs_db_master_MasterIndiv: Array<Rtcs_Db_Master_MasterIndiv>;
  /** fetch aggregated fields from the table: "rtcs_db.master_MasterIndiv" */
  rtcs_db_master_MasterIndiv_aggregate: Rtcs_Db_Master_MasterIndiv_Aggregate;
  /** fetch data from the table: "rtcs_db.mlhud_KCCA" */
  rtcs_db_mlhud_KCCA: Array<Rtcs_Db_Mlhud_Kcca>;
  /** fetch aggregated fields from the table: "rtcs_db.mlhud_KCCA" */
  rtcs_db_mlhud_KCCA_aggregate: Rtcs_Db_Mlhud_Kcca_Aggregate;
  /** fetch data from the table: "rtcs_db.mlhud_Mukono" */
  rtcs_db_mlhud_Mukono: Array<Rtcs_Db_Mlhud_Mukono>;
  /** fetch aggregated fields from the table: "rtcs_db.mlhud_Mukono" */
  rtcs_db_mlhud_Mukono_aggregate: Rtcs_Db_Mlhud_Mukono_Aggregate;
  /** fetch data from the table: "rtcs_db.mlhud_Properties" */
  rtcs_db_mlhud_Properties: Array<Rtcs_Db_Mlhud_Properties>;
  /** fetch aggregated fields from the table: "rtcs_db.mlhud_Properties" */
  rtcs_db_mlhud_Properties_aggregate: Rtcs_Db_Mlhud_Properties_Aggregate;
  /** fetch data from the table: "rtcs_db.mlhud_WakisoBusiro" */
  rtcs_db_mlhud_WakisoBusiro: Array<Rtcs_Db_Mlhud_WakisoBusiro>;
  /** fetch aggregated fields from the table: "rtcs_db.mlhud_WakisoBusiro" */
  rtcs_db_mlhud_WakisoBusiro_aggregate: Rtcs_Db_Mlhud_WakisoBusiro_Aggregate;
  /** fetch data from the table: "rtcs_db.mlhud_WakisoKyadondo" */
  rtcs_db_mlhud_WakisoKyadondo: Array<Rtcs_Db_Mlhud_WakisoKyadondo>;
  /** fetch aggregated fields from the table: "rtcs_db.mlhud_WakisoKyadondo" */
  rtcs_db_mlhud_WakisoKyadondo_aggregate: Rtcs_Db_Mlhud_WakisoKyadondo_Aggregate;
  /** fetch data from the table: "rtcs_db.nira_Nira" */
  rtcs_db_nira_Nira: Array<Rtcs_Db_Nira_Nira>;
  /** fetch aggregated fields from the table: "rtcs_db.nira_Nira" */
  rtcs_db_nira_Nira_aggregate: Rtcs_Db_Nira_Nira_Aggregate;
  /** fetch data from the table: "rtcs_db.nira_RawData" */
  rtcs_db_nira_RawData: Array<Rtcs_Db_Nira_RawData>;
  /** fetch aggregated fields from the table: "rtcs_db.nira_RawData" */
  rtcs_db_nira_RawData_aggregate: Rtcs_Db_Nira_RawData_Aggregate;
  /** fetch data from the table: "rtcs_db.nwsc_Customers" */
  rtcs_db_nwsc_Customers: Array<Rtcs_Db_Nwsc_Customers>;
  /** fetch aggregated fields from the table: "rtcs_db.nwsc_Customers" */
  rtcs_db_nwsc_Customers_aggregate: Rtcs_Db_Nwsc_Customers_Aggregate;
  /** fetch data from the table: "rtcs_db.nwsc_NWCustomers" */
  rtcs_db_nwsc_NWCustomers: Array<Rtcs_Db_Nwsc_NwCustomers>;
  /** fetch aggregated fields from the table: "rtcs_db.nwsc_NWCustomers" */
  rtcs_db_nwsc_NWCustomers_aggregate: Rtcs_Db_Nwsc_NwCustomers_Aggregate;
  /** fetch data from the table: "rtcs_db.nwsc_RPT_Transactions" */
  rtcs_db_nwsc_RPT_Transactions: Array<Rtcs_Db_Nwsc_Rpt_Transactions>;
  /** fetch aggregated fields from the table: "rtcs_db.nwsc_RPT_Transactions" */
  rtcs_db_nwsc_RPT_Transactions_aggregate: Rtcs_Db_Nwsc_Rpt_Transactions_Aggregate;
  /** fetch data from the table: "rtcs_db.nwsc_Transactions" */
  rtcs_db_nwsc_Transactions: Array<Rtcs_Db_Nwsc_Transactions>;
  /** fetch aggregated fields from the table: "rtcs_db.nwsc_Transactions" */
  rtcs_db_nwsc_Transactions_aggregate: Rtcs_Db_Nwsc_Transactions_Aggregate;
  /** fetch data from the table: "rtcs_db.nwsc_URATransactions" */
  rtcs_db_nwsc_URATransactions: Array<Rtcs_Db_Nwsc_UraTransactions>;
  /** fetch aggregated fields from the table: "rtcs_db.nwsc_URATransactions" */
  rtcs_db_nwsc_URATransactions_aggregate: Rtcs_Db_Nwsc_UraTransactions_Aggregate;
  /** fetch data from the table: "rtcs_db.rcapture_RPTUnittypes" */
  rtcs_db_rcapture_RPTUnittypes: Array<Rtcs_Db_Rcapture_RptUnittypes>;
  /** fetch aggregated fields from the table: "rtcs_db.rcapture_RPTUnittypes" */
  rtcs_db_rcapture_RPTUnittypes_aggregate: Rtcs_Db_Rcapture_RptUnittypes_Aggregate;
  /** fetch data from the table: "rtcs_db.rcapture_RPTlessors" */
  rtcs_db_rcapture_RPTlessors: Array<Rtcs_Db_Rcapture_RpTlessors>;
  /** fetch aggregated fields from the table: "rtcs_db.rcapture_RPTlessors" */
  rtcs_db_rcapture_RPTlessors_aggregate: Rtcs_Db_Rcapture_RpTlessors_Aggregate;
  /** fetch data from the table: "rtcs_db.rcapture_RPTmeta" */
  rtcs_db_rcapture_RPTmeta: Array<Rtcs_Db_Rcapture_RpTmeta>;
  /** fetch aggregated fields from the table: "rtcs_db.rcapture_RPTmeta" */
  rtcs_db_rcapture_RPTmeta_aggregate: Rtcs_Db_Rcapture_RpTmeta_Aggregate;
  /** fetch data from the table: "rtcs_db.rcapture_RPTproperties" */
  rtcs_db_rcapture_RPTproperties: Array<Rtcs_Db_Rcapture_RpTproperties>;
  /** fetch aggregated fields from the table: "rtcs_db.rcapture_RPTproperties" */
  rtcs_db_rcapture_RPTproperties_aggregate: Rtcs_Db_Rcapture_RpTproperties_Aggregate;
  /** fetch data from the table: "rtcs_db.rcapture_RPTtenants" */
  rtcs_db_rcapture_RPTtenants: Array<Rtcs_Db_Rcapture_RpTtenants>;
  /** fetch aggregated fields from the table: "rtcs_db.rcapture_RPTtenants" */
  rtcs_db_rcapture_RPTtenants_aggregate: Rtcs_Db_Rcapture_RpTtenants_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_Individual" */
  rtcs_db_rmatch_Individual: Array<Rtcs_Db_Rmatch_Individual>;
  /** fetch data from the table: "rtcs_db.rmatch_IndividualComplianceDetailReport" */
  rtcs_db_rmatch_IndividualComplianceDetailReport: Array<Rtcs_Db_Rmatch_IndividualComplianceDetailReport>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_IndividualComplianceDetailReport" */
  rtcs_db_rmatch_IndividualComplianceDetailReport_aggregate: Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
  rtcs_db_rmatch_IndividualComplianceSummaryReport: Array<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
  rtcs_db_rmatch_IndividualComplianceSummaryReport_aggregate: Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_IndividualRiskScore" */
  rtcs_db_rmatch_IndividualRiskScore: Array<Rtcs_Db_Rmatch_IndividualRiskScore>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_IndividualRiskScore" */
  rtcs_db_rmatch_IndividualRiskScore_aggregate: Rtcs_Db_Rmatch_IndividualRiskScore_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_Individual" */
  rtcs_db_rmatch_Individual_aggregate: Rtcs_Db_Rmatch_Individual_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_IndividualsNetwork" */
  rtcs_db_rmatch_IndividualsNetwork: Array<Rtcs_Db_Rmatch_IndividualsNetwork>;
  /** fetch data from the table: "rtcs_db.rmatch_IndividualsNetworkTesting" */
  rtcs_db_rmatch_IndividualsNetworkTesting: Array<Rtcs_Db_Rmatch_IndividualsNetworkTesting>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_IndividualsNetworkTesting" */
  rtcs_db_rmatch_IndividualsNetworkTesting_aggregate: Rtcs_Db_Rmatch_IndividualsNetworkTesting_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_IndividualsNetwork" */
  rtcs_db_rmatch_IndividualsNetwork_aggregate: Rtcs_Db_Rmatch_IndividualsNetwork_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
  rtcs_db_rmatch_KccaToKccaIndividualOwners: Array<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
  rtcs_db_rmatch_KccaToKccaIndividualOwners_aggregate: Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
  rtcs_db_rmatch_KccaToKccaNonindividualOwners: Array<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
  rtcs_db_rmatch_KccaToKccaNonindividualOwners_aggregate: Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_MatchIndividualsProperties" */
  rtcs_db_rmatch_MatchIndividualsProperties: Array<Rtcs_Db_Rmatch_MatchIndividualsProperties>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_MatchIndividualsProperties" */
  rtcs_db_rmatch_MatchIndividualsProperties_aggregate: Rtcs_Db_Rmatch_MatchIndividualsProperties_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_MatchIndividualsProps" */
  rtcs_db_rmatch_MatchIndividualsProps: Array<Rtcs_Db_Rmatch_MatchIndividualsProps>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_MatchIndividualsProps" */
  rtcs_db_rmatch_MatchIndividualsProps_aggregate: Rtcs_Db_Rmatch_MatchIndividualsProps_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_NoReturns" */
  rtcs_db_rmatch_NoReturns: Array<Rtcs_Db_Rmatch_NoReturns>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_NoReturns" */
  rtcs_db_rmatch_NoReturns_aggregate: Rtcs_Db_Rmatch_NoReturns_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_NonIndividual" */
  rtcs_db_rmatch_NonIndividual: Array<Rtcs_Db_Rmatch_NonIndividual>;
  /** fetch data from the table: "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
  rtcs_db_rmatch_NonIndividualComplianceDetailReport: Array<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
  rtcs_db_rmatch_NonIndividualComplianceDetailReport_aggregate: Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
  rtcs_db_rmatch_NonIndividualComplianceSummaryReport: Array<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
  rtcs_db_rmatch_NonIndividualComplianceSummaryReport_aggregate: Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_NonIndividualRiskScore" */
  rtcs_db_rmatch_NonIndividualRiskScore: Array<Rtcs_Db_Rmatch_NonIndividualRiskScore>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_NonIndividualRiskScore" */
  rtcs_db_rmatch_NonIndividualRiskScore_aggregate: Rtcs_Db_Rmatch_NonIndividualRiskScore_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_NonIndividual" */
  rtcs_db_rmatch_NonIndividual_aggregate: Rtcs_Db_Rmatch_NonIndividual_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_NonIndividuals" */
  rtcs_db_rmatch_NonIndividuals: Array<Rtcs_Db_Rmatch_NonIndividuals>;
  /** fetch data from the table: "rtcs_db.rmatch_NonIndividualsNetwork" */
  rtcs_db_rmatch_NonIndividualsNetwork: Array<Rtcs_Db_Rmatch_NonIndividualsNetwork>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_NonIndividualsNetwork" */
  rtcs_db_rmatch_NonIndividualsNetwork_aggregate: Rtcs_Db_Rmatch_NonIndividualsNetwork_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_NonIndividuals" */
  rtcs_db_rmatch_NonIndividuals_aggregate: Rtcs_Db_Rmatch_NonIndividuals_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_NonindividualOwners" */
  rtcs_db_rmatch_NonindividualOwners: Array<Rtcs_Db_Rmatch_NonindividualOwners>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_NonindividualOwners" */
  rtcs_db_rmatch_NonindividualOwners_aggregate: Rtcs_Db_Rmatch_NonindividualOwners_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_Nonindividuals" */
  rtcs_db_rmatch_Nonindividuals: Array<Rtcs_Db_Rmatch_Nonindividuals>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_Nonindividuals" */
  rtcs_db_rmatch_Nonindividuals_aggregate: Rtcs_Db_Rmatch_Nonindividuals_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_PropertiesComposite" */
  rtcs_db_rmatch_PropertiesComposite: Array<Rtcs_Db_Rmatch_PropertiesComposite>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_PropertiesComposite" */
  rtcs_db_rmatch_PropertiesComposite_aggregate: Rtcs_Db_Rmatch_PropertiesComposite_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_PropertiesNetwork" */
  rtcs_db_rmatch_PropertiesNetwork: Array<Rtcs_Db_Rmatch_PropertiesNetwork>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_PropertiesNetwork" */
  rtcs_db_rmatch_PropertiesNetwork_aggregate: Rtcs_Db_Rmatch_PropertiesNetwork_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_PropertyNetwork" */
  rtcs_db_rmatch_PropertyNetwork: Array<Rtcs_Db_Rmatch_PropertyNetwork>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_PropertyNetwork" */
  rtcs_db_rmatch_PropertyNetwork_aggregate: Rtcs_Db_Rmatch_PropertyNetwork_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_RMatchDetailed904" */
  rtcs_db_rmatch_RMatchDetailed904: Array<Rtcs_Db_Rmatch_RMatchDetailed904>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_RMatchDetailed904" */
  rtcs_db_rmatch_RMatchDetailed904_aggregate: Rtcs_Db_Rmatch_RMatchDetailed904_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_RMatchDetailed916" */
  rtcs_db_rmatch_RMatchDetailed916: Array<Rtcs_Db_Rmatch_RMatchDetailed916>;
  /** fetch data from the table: "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
  rtcs_db_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy: Array<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
  rtcs_db_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregate: Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_RMatchDetailed916" */
  rtcs_db_rmatch_RMatchDetailed916_aggregate: Rtcs_Db_Rmatch_RMatchDetailed916_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_RMatchDetailed937" */
  rtcs_db_rmatch_RMatchDetailed937: Array<Rtcs_Db_Rmatch_RMatchDetailed937>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_RMatchDetailed937" */
  rtcs_db_rmatch_RMatchDetailed937_aggregate: Rtcs_Db_Rmatch_RMatchDetailed937_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_RMatchKccaProperties" */
  rtcs_db_rmatch_RMatchKccaProperties: Array<Rtcs_Db_Rmatch_RMatchKccaProperties>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_RMatchKccaProperties" */
  rtcs_db_rmatch_RMatchKccaProperties_aggregate: Rtcs_Db_Rmatch_RMatchKccaProperties_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_RMatchKccaProperty" */
  rtcs_db_rmatch_RMatchKccaProperty: Array<Rtcs_Db_Rmatch_RMatchKccaProperty>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_RMatchKccaProperty" */
  rtcs_db_rmatch_RMatchKccaProperty_aggregate: Rtcs_Db_Rmatch_RMatchKccaProperty_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_RMatchNonIndividual903" */
  rtcs_db_rmatch_RMatchNonIndividual903: Array<Rtcs_Db_Rmatch_RMatchNonIndividual903>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_RMatchNonIndividual903" */
  rtcs_db_rmatch_RMatchNonIndividual903_aggregate: Rtcs_Db_Rmatch_RMatchNonIndividual903_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_RMatchProperties1115" */
  rtcs_db_rmatch_RMatchProperties1115: Array<Rtcs_Db_Rmatch_RMatchProperties1115>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_RMatchProperties1115" */
  rtcs_db_rmatch_RMatchProperties1115_aggregate: Rtcs_Db_Rmatch_RMatchProperties1115_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_RMatchSummary904" */
  rtcs_db_rmatch_RMatchSummary904: Array<Rtcs_Db_Rmatch_RMatchSummary904>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_RMatchSummary904" */
  rtcs_db_rmatch_RMatchSummary904_aggregate: Rtcs_Db_Rmatch_RMatchSummary904_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_RMatchSummary916" */
  rtcs_db_rmatch_RMatchSummary916: Array<Rtcs_Db_Rmatch_RMatchSummary916>;
  /** fetch data from the table: "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
  rtcs_db_rmatch_RMatchSummary916ExcessiveOwnerOccupancy: Array<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
  rtcs_db_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregate: Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_RMatchSummary916" */
  rtcs_db_rmatch_RMatchSummary916_aggregate: Rtcs_Db_Rmatch_RMatchSummary916_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_RMatchSummary937" */
  rtcs_db_rmatch_RMatchSummary937: Array<Rtcs_Db_Rmatch_RMatchSummary937>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_RMatchSummary937" */
  rtcs_db_rmatch_RMatchSummary937_aggregate: Rtcs_Db_Rmatch_RMatchSummary937_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_TinsURA" */
  rtcs_db_rmatch_TinsURA: Array<Rtcs_Db_Rmatch_TinsUra>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_TinsURA" */
  rtcs_db_rmatch_TinsURA_aggregate: Rtcs_Db_Rmatch_TinsUra_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_Undeclared" */
  rtcs_db_rmatch_Undeclared: Array<Rtcs_Db_Rmatch_Undeclared>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_Undeclared" */
  rtcs_db_rmatch_Undeclared_aggregate: Rtcs_Db_Rmatch_Undeclared_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_Underreported" */
  rtcs_db_rmatch_Underreported: Array<Rtcs_Db_Rmatch_Underreported>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_Underreported" */
  rtcs_db_rmatch_Underreported_aggregate: Rtcs_Db_Rmatch_Underreported_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_Unregistered" */
  rtcs_db_rmatch_Unregistered: Array<Rtcs_Db_Rmatch_Unregistered>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_Unregistered" */
  rtcs_db_rmatch_Unregistered_aggregate: Rtcs_Db_Rmatch_Unregistered_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_mlhudConsolidated" */
  rtcs_db_rmatch_mlhudConsolidated: Array<Rtcs_Db_Rmatch_MlhudConsolidated>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_mlhudConsolidated" */
  rtcs_db_rmatch_mlhudConsolidated_aggregate: Rtcs_Db_Rmatch_MlhudConsolidated_Aggregate;
  /** fetch data from the table: "rtcs_db.streetmap_OsmMukonoMunicipality" */
  rtcs_db_streetmap_OsmMukonoMunicipality: Array<Rtcs_Db_Streetmap_OsmMukonoMunicipality>;
  /** fetch aggregated fields from the table: "rtcs_db.streetmap_OsmMukonoMunicipality" */
  rtcs_db_streetmap_OsmMukonoMunicipality_aggregate: Rtcs_Db_Streetmap_OsmMukonoMunicipality_Aggregate;
  /** fetch data from the table: "rtcs_db.streetmap_OsmMukonoNakifuma" */
  rtcs_db_streetmap_OsmMukonoNakifuma: Array<Rtcs_Db_Streetmap_OsmMukonoNakifuma>;
  /** fetch aggregated fields from the table: "rtcs_db.streetmap_OsmMukonoNakifuma" */
  rtcs_db_streetmap_OsmMukonoNakifuma_aggregate: Rtcs_Db_Streetmap_OsmMukonoNakifuma_Aggregate;
  /** fetch data from the table: "rtcs_db.streetmap_OsmWakisoEntebbe" */
  rtcs_db_streetmap_OsmWakisoEntebbe: Array<Rtcs_Db_Streetmap_OsmWakisoEntebbe>;
  /** fetch aggregated fields from the table: "rtcs_db.streetmap_OsmWakisoEntebbe" */
  rtcs_db_streetmap_OsmWakisoEntebbe_aggregate: Rtcs_Db_Streetmap_OsmWakisoEntebbe_Aggregate;
  /** fetch data from the table: "rtcs_db.streetmap_OsmWakisoKira" */
  rtcs_db_streetmap_OsmWakisoKira: Array<Rtcs_Db_Streetmap_OsmWakisoKira>;
  /** fetch aggregated fields from the table: "rtcs_db.streetmap_OsmWakisoKira" */
  rtcs_db_streetmap_OsmWakisoKira_aggregate: Rtcs_Db_Streetmap_OsmWakisoKira_Aggregate;
  /** fetch data from the table: "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
  rtcs_db_streetmap_OsmWakisoMakindyeSsabagabo: Array<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo>;
  /** fetch aggregated fields from the table: "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
  rtcs_db_streetmap_OsmWakisoMakindyeSsabagabo_aggregate: Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Aggregate;
  /** fetch data from the table: "rtcs_db.streetmap_OsmWakisoNansana" */
  rtcs_db_streetmap_OsmWakisoNansana: Array<Rtcs_Db_Streetmap_OsmWakisoNansana>;
  /** fetch aggregated fields from the table: "rtcs_db.streetmap_OsmWakisoNansana" */
  rtcs_db_streetmap_OsmWakisoNansana_aggregate: Rtcs_Db_Streetmap_OsmWakisoNansana_Aggregate;
  /** fetch data from the table: "rtcs_db.ucc_UCC" */
  rtcs_db_ucc_UCC: Array<Rtcs_Db_Ucc_Ucc>;
  /** fetch aggregated fields from the table: "rtcs_db.ucc_UCC" */
  rtcs_db_ucc_UCC_aggregate: Rtcs_Db_Ucc_Ucc_Aggregate;
  /** fetch data from the table: "rtcs_db.umeme_SubscriberAndProperty" */
  rtcs_db_umeme_SubscriberAndProperty: Array<Rtcs_Db_Umeme_SubscriberAndProperty>;
  /** fetch aggregated fields from the table: "rtcs_db.umeme_SubscriberAndProperty" */
  rtcs_db_umeme_SubscriberAndProperty_aggregate: Rtcs_Db_Umeme_SubscriberAndProperty_Aggregate;
  /** fetch data from the table: "rtcs_db.umeme_Transactions" */
  rtcs_db_umeme_Transactions: Array<Rtcs_Db_Umeme_Transactions>;
  /** fetch aggregated fields from the table: "rtcs_db.umeme_Transactions" */
  rtcs_db_umeme_Transactions_aggregate: Rtcs_Db_Umeme_Transactions_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_BalanceSheetInformation" */
  rtcs_db_ura_BalanceSheetInformation: Array<Rtcs_Db_Ura_BalanceSheetInformation>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_BalanceSheetInformation" */
  rtcs_db_ura_BalanceSheetInformation_aggregate: Rtcs_Db_Ura_BalanceSheetInformation_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_CombinedOfficersAndOwners" */
  rtcs_db_ura_CombinedOfficersAndOwners: Array<Rtcs_Db_Ura_CombinedOfficersAndOwners>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_CombinedOfficersAndOwners" */
  rtcs_db_ura_CombinedOfficersAndOwners_aggregate: Rtcs_Db_Ura_CombinedOfficersAndOwners_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_EmploymentIncomeDetail" */
  rtcs_db_ura_EmploymentIncomeDetail: Array<Rtcs_Db_Ura_EmploymentIncomeDetail>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_EmploymentIncomeDetail" */
  rtcs_db_ura_EmploymentIncomeDetail_aggregate: Rtcs_Db_Ura_EmploymentIncomeDetail_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
  rtcs_db_ura_GazetteCommercialRatesPerSquareFootage: Array<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
  rtcs_db_ura_GazetteCommercialRatesPerSquareFootage_aggregate: Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
  rtcs_db_ura_GazetteResidentialRatesPerBedroom: Array<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
  rtcs_db_ura_GazetteResidentialRatesPerBedroom_aggregate: Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_HoldingOfficers" */
  rtcs_db_ura_HoldingOfficers: Array<Rtcs_Db_Ura_HoldingOfficers>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_HoldingOfficers" */
  rtcs_db_ura_HoldingOfficers_aggregate: Rtcs_Db_Ura_HoldingOfficers_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_HoldingOwner" */
  rtcs_db_ura_HoldingOwner: Array<Rtcs_Db_Ura_HoldingOwner>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_HoldingOwner" */
  rtcs_db_ura_HoldingOwner_aggregate: Rtcs_Db_Ura_HoldingOwner_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_IncomeTaxHolding" */
  rtcs_db_ura_IncomeTaxHolding: Array<Rtcs_Db_Ura_IncomeTaxHolding>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_IncomeTaxHolding" */
  rtcs_db_ura_IncomeTaxHolding_aggregate: Rtcs_Db_Ura_IncomeTaxHolding_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_IncomeTaxSummaryIndividual" */
  rtcs_db_ura_IncomeTaxSummaryIndividual: Array<Rtcs_Db_Ura_IncomeTaxSummaryIndividual>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_IncomeTaxSummaryIndividual" */
  rtcs_db_ura_IncomeTaxSummaryIndividual_aggregate: Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
  rtcs_db_ura_IncomeTaxSummaryNonIndividual: Array<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
  rtcs_db_ura_IncomeTaxSummaryNonIndividual_aggregate: Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_IndividualMortgage" */
  rtcs_db_ura_IndividualMortgage: Array<Rtcs_Db_Ura_IndividualMortgage>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_IndividualMortgage" */
  rtcs_db_ura_IndividualMortgage_aggregate: Rtcs_Db_Ura_IndividualMortgage_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_IndividualsAssociatedBusiness" */
  rtcs_db_ura_IndividualsAssociatedBusiness: Array<Rtcs_Db_Ura_IndividualsAssociatedBusiness>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_IndividualsAssociatedBusiness" */
  rtcs_db_ura_IndividualsAssociatedBusiness_aggregate: Rtcs_Db_Ura_IndividualsAssociatedBusiness_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_LandlordIncomeDetail" */
  rtcs_db_ura_LandlordIncomeDetail: Array<Rtcs_Db_Ura_LandlordIncomeDetail>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_LandlordIncomeDetail" */
  rtcs_db_ura_LandlordIncomeDetail_aggregate: Rtcs_Db_Ura_LandlordIncomeDetail_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_LandlordIncomeSummary" */
  rtcs_db_ura_LandlordIncomeSummary: Array<Rtcs_Db_Ura_LandlordIncomeSummary>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_LandlordIncomeSummary" */
  rtcs_db_ura_LandlordIncomeSummary_aggregate: Rtcs_Db_Ura_LandlordIncomeSummary_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
  rtcs_db_ura_NonIndividualsAssociatedBusiness: Array<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
  rtcs_db_ura_NonIndividualsAssociatedBusiness_aggregate: Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_RegAssociates" */
  rtcs_db_ura_RegAssociates: Array<Rtcs_Db_Ura_RegAssociates>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_RegAssociates" */
  rtcs_db_ura_RegAssociates_aggregate: Rtcs_Db_Ura_RegAssociates_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_RegIndividual" */
  rtcs_db_ura_RegIndividual: Array<Rtcs_Db_Ura_RegIndividual>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_RegIndividual" */
  rtcs_db_ura_RegIndividual_aggregate: Rtcs_Db_Ura_RegIndividual_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_RegIndividual_sid" */
  rtcs_db_ura_RegIndividual_sid: Array<Rtcs_Db_Ura_RegIndividual_Sid>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_RegIndividual_sid" */
  rtcs_db_ura_RegIndividual_sid_aggregate: Rtcs_Db_Ura_RegIndividual_Sid_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_RegNonIndividual" */
  rtcs_db_ura_RegNonIndividual: Array<Rtcs_Db_Ura_RegNonIndividual>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_RegNonIndividual" */
  rtcs_db_ura_RegNonIndividual_aggregate: Rtcs_Db_Ura_RegNonIndividual_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_RegNonIndividual_sid" */
  rtcs_db_ura_RegNonIndividual_sid: Array<Rtcs_Db_Ura_RegNonIndividual_Sid>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_RegNonIndividual_sid" */
  rtcs_db_ura_RegNonIndividual_sid_aggregate: Rtcs_Db_Ura_RegNonIndividual_Sid_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_RentExpenseDetails" */
  rtcs_db_ura_RentExpenseDetails: Array<Rtcs_Db_Ura_RentExpenseDetails>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_RentExpenseDetails" */
  rtcs_db_ura_RentExpenseDetails_aggregate: Rtcs_Db_Ura_RentExpenseDetails_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_RentalLandLordIncome" */
  rtcs_db_ura_RentalLandLordIncome: Array<Rtcs_Db_Ura_RentalLandLordIncome>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_RentalLandLordIncome" */
  rtcs_db_ura_RentalLandLordIncome_aggregate: Rtcs_Db_Ura_RentalLandLordIncome_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_RentalTenantPayments" */
  rtcs_db_ura_RentalTenantPayments: Array<Rtcs_Db_Ura_RentalTenantPayments>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_RentalTenantPayments" */
  rtcs_db_ura_RentalTenantPayments_aggregate: Rtcs_Db_Ura_RentalTenantPayments_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_RipoIndRentalIncSummary" */
  rtcs_db_ura_RipoIndRentalIncSummary: Array<Rtcs_Db_Ura_RipoIndRentalIncSummary>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_RipoIndRentalIncSummary" */
  rtcs_db_ura_RipoIndRentalIncSummary_aggregate: Rtcs_Db_Ura_RipoIndRentalIncSummary_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_TenantIncomeDetail" */
  rtcs_db_ura_TenantIncomeDetail: Array<Rtcs_Db_Ura_TenantIncomeDetail>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_TenantIncomeDetail" */
  rtcs_db_ura_TenantIncomeDetail_aggregate: Rtcs_Db_Ura_TenantIncomeDetail_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_Town" */
  rtcs_db_ura_Town: Array<Rtcs_Db_Ura_Town>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_Town" */
  rtcs_db_ura_Town_aggregate: Rtcs_Db_Ura_Town_Aggregate;
  /** fetch data from the table: "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
  rtcs_db_ursb_CompaniesAndBusinessNamesURSB: Array<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb>;
  /** fetch aggregated fields from the table: "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
  rtcs_db_ursb_CompaniesAndBusinessNamesURSB_aggregate: Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Aggregate;
};

/** query root */
export type Query_RootRtcs_Db_EntityFilterArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_EntityFilter_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_EntityFilter_Order_By>>;
  where?: Maybe<Rtcs_Db_EntityFilter_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_EntityFilter_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_EntityFilter_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_EntityFilter_Order_By>>;
  where?: Maybe<Rtcs_Db_EntityFilter_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FilterArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Filter_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Filter_Order_By>>;
  where?: Maybe<Rtcs_Db_Filter_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Filter_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Filter_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Filter_Order_By>>;
  where?: Maybe<Rtcs_Db_Filter_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnEntityFilterArgs = {
  args: Rtcs_Db_FnEntityFilter_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_EntityFilter_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_EntityFilter_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_EntityFilter_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnEntityFilter_AggregateArgs = {
  args: Rtcs_Db_FnEntityFilter_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_EntityFilter_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_EntityFilter_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_EntityFilter_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnEntityFilter_AggregatecmArgs = {
  args: Rtcs_Db_FnEntityFilter_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnEntityFilter_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_FnEntityFilter_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnEntityFilter_AggregatetxtArgs = {
  args: Rtcs_Db_FnEntityFilter_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnEntityFilter_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_FnEntityFilter_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnFilterArgs = {
  args: Rtcs_Db_FnFilter_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Filter_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Filter_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Filter_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnFilter_AggregateArgs = {
  args: Rtcs_Db_FnFilter_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Filter_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Filter_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Filter_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnFilter_AggregatecmArgs = {
  args: Rtcs_Db_FnFilter_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnFilter_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_FnFilter_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnFilter_AggregatetxtArgs = {
  args: Rtcs_Db_FnFilter_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnFilter_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_FnFilter_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnGazzetteRatesArgs = {
  args: Rtcs_Db_FnGazzetteRates_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_GazzetteRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_GazzetteRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_GazzetteRates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnGazzetteRates_AggregateArgs = {
  args: Rtcs_Db_FnGazzetteRates_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_GazzetteRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_GazzetteRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_GazzetteRates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnGazzetteRates_AggregatecmArgs = {
  args: Rtcs_Db_FnGazzetteRates_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnGazzetteRates_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_FnGazzetteRates_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnGazzetteRates_AggregatetxtArgs = {
  args: Rtcs_Db_FnGazzetteRates_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnGazzetteRates_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_FnGazzetteRates_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_IndividualComplianceSummaryPlusRiskArgs = {
  args: Rtcs_Db_FnRpt_IndividualComplianceSummaryPlusRisk_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_IndividualComplianceSummaryPlusRisk_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualComplianceSummaryPlusRisk_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_IndividualComplianceSummaryPlusRisk_AggregatecmArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualComplianceSummaryPlusRisk_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_IndividualComplianceSummaryPlusRisk_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualComplianceSummaryPlusRisk_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_IndividualComplianceSummaryPlusRisk_AggregatetxtArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualComplianceSummaryPlusRisk_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_IndividualComplianceSummaryPlusRisk_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualComplianceSummaryPlusRisk_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_IndividualCompositeArgs = {
  args: Rtcs_Db_FnRpt_IndividualComposite_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_IndividualComposite_AggregateArgs = {
  args: Rtcs_Db_FnRpt_IndividualComposite_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_IndividualComposite_AggregatecmArgs = {
  args: Rtcs_Db_FnRpt_IndividualComposite_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_IndividualComposite_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualComposite_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_IndividualComposite_AggregatetxtArgs = {
  args: Rtcs_Db_FnRpt_IndividualComposite_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_IndividualComposite_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualComposite_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_IndividualRegistrationArgs = {
  args: Rtcs_Db_FnRpt_IndividualRegistration_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_IndividualRegistration_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualRegistration_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_IndividualRegistration_AggregateArgs = {
  args: Rtcs_Db_FnRpt_IndividualRegistration_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_IndividualRegistration_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualRegistration_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_IndividualRegistration_AggregatecmArgs = {
  args: Rtcs_Db_FnRpt_IndividualRegistration_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_IndividualRegistration_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualRegistration_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_IndividualRegistration_AggregatetxtArgs = {
  args: Rtcs_Db_FnRpt_IndividualRegistration_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_IndividualRegistration_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualRegistration_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_IndividualTaxSummaryDataArgs = {
  args: Rtcs_Db_FnRpt_IndividualTaxSummaryData_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_IndividualTaxSummaryData_AggregateArgs = {
  args: Rtcs_Db_FnRpt_IndividualTaxSummaryData_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_IndividualTaxSummaryData_AggregatecmArgs = {
  args: Rtcs_Db_FnRpt_IndividualTaxSummaryData_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_IndividualTaxSummaryData_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualTaxSummaryData_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_IndividualTaxSummaryData_AggregatetxtArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualTaxSummaryData_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_IndividualTaxSummaryData_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualTaxSummaryData_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_KccaAffiliatesArgs = {
  args: Rtcs_Db_FnRpt_KccaAffiliates_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaAffiliates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaAffiliates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_KccaAffiliates_AggregateArgs = {
  args: Rtcs_Db_FnRpt_KccaAffiliates_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaAffiliates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaAffiliates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_KccaAffiliates_AggregatecmArgs = {
  args: Rtcs_Db_FnRpt_KccaAffiliates_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_KccaAffiliates_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_FnRpt_KccaAffiliates_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_KccaAffiliates_AggregatetxtArgs = {
  args: Rtcs_Db_FnRpt_KccaAffiliates_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_KccaAffiliates_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_KccaAffiliates_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_KccaCustomerRegistrationDataArgs = {
  args: Rtcs_Db_FnRpt_KccaCustomerRegistrationData_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_KccaCustomerRegistrationData_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_KccaCustomerRegistrationData_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_KccaCustomerRegistrationData_AggregatecmArgs =
  {
    args: Rtcs_Db_FnRpt_KccaCustomerRegistrationData_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_KccaCustomerRegistrationData_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_KccaCustomerRegistrationData_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_KccaCustomerRegistrationData_AggregatetxtArgs =
  {
    args: Rtcs_Db_FnRpt_KccaCustomerRegistrationData_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_KccaCustomerRegistrationData_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_KccaCustomerRegistrationData_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_KccaPropertyRegistrationArgs = {
  args: Rtcs_Db_FnRpt_KccaPropertyRegistration_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_KccaPropertyRegistrationDataArgs = {
  args: Rtcs_Db_FnRpt_KccaPropertyRegistrationData_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_KccaPropertyRegistrationData_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_KccaPropertyRegistrationData_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_KccaPropertyRegistrationData_AggregatecmArgs =
  {
    args: Rtcs_Db_FnRpt_KccaPropertyRegistrationData_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_KccaPropertyRegistrationData_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_KccaPropertyRegistrationData_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_KccaPropertyRegistrationData_AggregatetxtArgs =
  {
    args: Rtcs_Db_FnRpt_KccaPropertyRegistrationData_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_KccaPropertyRegistrationData_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_KccaPropertyRegistrationData_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_KccaPropertyRegistration_AggregateArgs = {
  args: Rtcs_Db_FnRpt_KccaPropertyRegistration_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_KccaPropertyRegistration_AggregatecmArgs = {
  args: Rtcs_Db_FnRpt_KccaPropertyRegistration_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_KccaPropertyRegistration_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_KccaPropertyRegistration_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_KccaPropertyRegistration_AggregatetxtArgs =
  {
    args: Rtcs_Db_FnRpt_KccaPropertyRegistration_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_KccaPropertyRegistration_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_KccaPropertyRegistration_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_NonIndividualBalanceSheetArgs = {
  args: Rtcs_Db_FnRpt_NonIndividualBalanceSheet_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_NonIndividualBalanceSheet_AggregateArgs = {
  args: Rtcs_Db_FnRpt_NonIndividualBalanceSheet_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_NonIndividualBalanceSheet_AggregatecmArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualBalanceSheet_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_NonIndividualBalanceSheet_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualBalanceSheet_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_NonIndividualBalanceSheet_AggregatetxtArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualBalanceSheet_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_NonIndividualBalanceSheet_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualBalanceSheet_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRiskArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRisk_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRisk_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRisk_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRisk_AggregatecmArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRisk_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRisk_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRisk_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRisk_AggregatetxtArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRisk_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRisk_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRisk_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_NonIndividualCompositeArgs = {
  args: Rtcs_Db_FnRpt_NonIndividualComposite_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_NonIndividualComposite_AggregateArgs = {
  args: Rtcs_Db_FnRpt_NonIndividualComposite_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_NonIndividualComposite_AggregatecmArgs = {
  args: Rtcs_Db_FnRpt_NonIndividualComposite_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_NonIndividualComposite_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualComposite_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_NonIndividualComposite_AggregatetxtArgs = {
  args: Rtcs_Db_FnRpt_NonIndividualComposite_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_NonIndividualComposite_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualComposite_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_NonIndividualTaxSummaryDataArgs = {
  args: Rtcs_Db_FnRpt_NonIndividualTaxSummaryData_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_NonIndividualTaxSummaryData_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualTaxSummaryData_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_NonIndividualTaxSummaryData_AggregatecmArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualTaxSummaryData_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_NonIndividualTaxSummaryData_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualTaxSummaryData_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_NonIndividualTaxSummaryData_AggregatetxtArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualTaxSummaryData_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_NonIndividualTaxSummaryData_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualTaxSummaryData_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_PropertiesCompositeArgs = {
  args: Rtcs_Db_FnRpt_PropertiesComposite_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertiesComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertiesComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_PropertiesComposite_AggregateArgs = {
  args: Rtcs_Db_FnRpt_PropertiesComposite_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertiesComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertiesComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_PropertiesComposite_AggregatecmArgs = {
  args: Rtcs_Db_FnRpt_PropertiesComposite_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_PropertiesComposite_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_PropertiesComposite_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_PropertiesComposite_AggregatetxtArgs = {
  args: Rtcs_Db_FnRpt_PropertiesComposite_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_PropertiesComposite_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_PropertiesComposite_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_PropertyValuationArgs = {
  args: Rtcs_Db_FnRpt_PropertyValuation_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertyValuation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertyValuation_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_PropertyValuation_AggregateArgs = {
  args: Rtcs_Db_FnRpt_PropertyValuation_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertyValuation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertyValuation_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_PropertyValuation_AggregatecmArgs = {
  args: Rtcs_Db_FnRpt_PropertyValuation_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_PropertyValuation_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_PropertyValuation_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_PropertyValuation_AggregatetxtArgs = {
  args: Rtcs_Db_FnRpt_PropertyValuation_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_PropertyValuation_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_PropertyValuation_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_RentalTenantPaymentsArgs = {
  args: Rtcs_Db_FnRpt_RentalTenantPayments_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_RentalTenantPayments_AggregateArgs = {
  args: Rtcs_Db_FnRpt_RentalTenantPayments_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_RentalTenantPayments_AggregatecmArgs = {
  args: Rtcs_Db_FnRpt_RentalTenantPayments_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_RentalTenantPayments_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_RentalTenantPayments_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_FnRpt_RentalTenantPayments_AggregatetxtArgs = {
  args: Rtcs_Db_FnRpt_RentalTenantPayments_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_FnRpt_RentalTenantPayments_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_RentalTenantPayments_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fngazette_CommercialRatesArgs = {
  args: Rtcs_Db_Fngazette_CommercialRates_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Gazette_CommercialRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Gazette_CommercialRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fngazette_CommercialRates_AggregateArgs = {
  args: Rtcs_Db_Fngazette_CommercialRates_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Gazette_CommercialRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Gazette_CommercialRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fngazette_CommercialRates_AggregatecmArgs = {
  args: Rtcs_Db_Fngazette_CommercialRates_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fngazette_CommercialRates_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fngazette_CommercialRates_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fngazette_CommercialRates_AggregatetxtArgs = {
  args: Rtcs_Db_Fngazette_CommercialRates_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fngazette_CommercialRates_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fngazette_CommercialRates_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fngazette_ResidentialRatesArgs = {
  args: Rtcs_Db_Fngazette_ResidentialRates_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Gazette_ResidentialRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Gazette_ResidentialRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fngazette_ResidentialRates_AggregateArgs = {
  args: Rtcs_Db_Fngazette_ResidentialRates_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Gazette_ResidentialRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Gazette_ResidentialRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fngazette_ResidentialRates_AggregatecmArgs = {
  args: Rtcs_Db_Fngazette_ResidentialRates_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fngazette_ResidentialRates_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fngazette_ResidentialRates_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fngazette_ResidentialRates_AggregatetxtArgs = {
  args: Rtcs_Db_Fngazette_ResidentialRates_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fngazette_ResidentialRates_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fngazette_ResidentialRates_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_AssociateTypeArgs = {
  args: Rtcs_Db_Fnkcca_AssociateType_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_AssociateType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_AssociateType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_AssociateType_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_AssociateType_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_AssociateType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_AssociateType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_AssociateType_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_AssociateType_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_AssociateType_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_AssociateType_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_AssociateType_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_AssociateType_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_AssociateType_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_AssociateType_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CommercialPropertiesArgs = {
  args: Rtcs_Db_Fnkcca_CommercialProperties_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CommercialProperties_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CommercialProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CommercialProperties_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_CommercialProperties_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CommercialProperties_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CommercialProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CommercialProperties_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_CommercialProperties_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CommercialProperties_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CommercialProperties_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CommercialProperties_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_CommercialProperties_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CommercialProperties_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CommercialProperties_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CountyArgs = {
  args: Rtcs_Db_Fnkcca_County_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_County_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_County_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_County_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_County_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_County_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_County_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_County_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_County_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_County_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_County_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_County_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_County_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_County_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_County_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_County_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_County_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerArgs = {
  args: Rtcs_Db_Fnkcca_Customer_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerApplicantTypeArgs = {
  args: Rtcs_Db_Fnkcca_CustomerApplicantType_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerApplicantType_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_CustomerApplicantType_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerApplicantType_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_CustomerApplicantType_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerApplicantType_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerApplicantType_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerApplicantType_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_CustomerApplicantType_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerApplicantType_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerApplicantType_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerBusinessAssociateArgs = {
  args: Rtcs_Db_Fnkcca_CustomerBusinessAssociate_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerBusinessAssociate_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_CustomerBusinessAssociate_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerBusinessAssociate_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerBusinessAssociate_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerBusinessAssociate_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerBusinessAssociate_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerBusinessAssociate_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerBusinessAssociate_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerBusinessAssociate_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerBusinessAssociate_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerBusinessTypeArgs = {
  args: Rtcs_Db_Fnkcca_CustomerBusinessType_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerBusinessType_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_CustomerBusinessType_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerBusinessType_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_CustomerBusinessType_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerBusinessType_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerBusinessType_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerBusinessType_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_CustomerBusinessType_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerBusinessType_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerBusinessType_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerCoinDeactivateReactiveArgs = {
  args: Rtcs_Db_Fnkcca_CustomerCoinDeactivateReactive_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerCoinDeactivateReactive_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerCoinDeactivateReactive_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerCoinDeactivateReactive_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerCoinDeactivateReactive_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerCoinDeactivateReactive_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerCoinDeactivateReactive_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerCoinDeactivateReactive_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerCoinDeactivateReactive_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerCoinDeactivateReactive_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerCoinDeactivateReactive_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerContactPersonArgs = {
  args: Rtcs_Db_Fnkcca_CustomerContactPerson_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerContactPerson_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_CustomerContactPerson_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerContactPerson_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_CustomerContactPerson_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerContactPerson_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerContactPerson_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerContactPerson_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_CustomerContactPerson_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerContactPerson_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerContactPerson_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerDocumentItemArgs = {
  args: Rtcs_Db_Fnkcca_CustomerDocumentItem_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerDocumentItem_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_CustomerDocumentItem_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerDocumentItem_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_CustomerDocumentItem_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerDocumentItem_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerDocumentItem_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerDocumentItem_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_CustomerDocumentItem_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerDocumentItem_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerDocumentItem_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerGuardianArgs = {
  args: Rtcs_Db_Fnkcca_CustomerGuardian_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerGuardian_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerGuardian_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerGuardian_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_CustomerGuardian_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerGuardian_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerGuardian_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerGuardian_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_CustomerGuardian_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerGuardian_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerGuardian_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerGuardian_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_CustomerGuardian_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerGuardian_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerGuardian_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerReferenceArgs = {
  args: Rtcs_Db_Fnkcca_CustomerReference_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerReference_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerReference_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerReference_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_CustomerReference_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerReference_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerReference_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerReference_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_CustomerReference_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerReference_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerReference_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerReference_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_CustomerReference_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerReference_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerReference_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerRegistrationReasonArgs = {
  args: Rtcs_Db_Fnkcca_CustomerRegistrationReason_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerRegistrationReason_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerRegistrationReason_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerRegistrationReason_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerRegistrationReason_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerRegistrationReason_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerRegistrationReason_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerRegistrationReason_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerRegistrationReason_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerRegistrationReason_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerRegistrationReason_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerRevenueAgentArgs = {
  args: Rtcs_Db_Fnkcca_CustomerRevenueAgent_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerRevenueAgent_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_CustomerRevenueAgent_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerRevenueAgent_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_CustomerRevenueAgent_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerRevenueAgent_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerRevenueAgent_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerRevenueAgent_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_CustomerRevenueAgent_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerRevenueAgent_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerRevenueAgent_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerTypeArgs = {
  args: Rtcs_Db_Fnkcca_CustomerType_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerType_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_CustomerType_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerType_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_CustomerType_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerType_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_CustomerType_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerType_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_CustomerType_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_CustomerType_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_CustomerType_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Customer_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Customer_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Customer_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_Customer_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Customer_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Customer_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Customer_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_Customer_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Customer_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Customer_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Customer_SidArgs = {
  args: Rtcs_Db_Fnkcca_Customer_Sid_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Customer_Sid_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Customer_Sid_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Customer_Sid_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_Customer_Sid_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Customer_Sid_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Customer_Sid_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Customer_Sid_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_Customer_Sid_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Customer_Sid_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Customer_Sid_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_DistrictArgs = {
  args: Rtcs_Db_Fnkcca_District_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_District_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_District_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_District_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_District_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_District_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_District_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_District_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_District_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_District_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_District_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_District_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_District_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_District_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_District_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_District_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_District_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_DivisionArgs = {
  args: Rtcs_Db_Fnkcca_Division_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Division_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Division_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Division_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Division_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Division_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Division_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Division_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Division_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Division_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_Division_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Division_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Division_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Division_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_Division_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Division_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Division_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_ImgsDataArgs = {
  args: Rtcs_Db_Fnkcca_ImgsData_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_ImgsData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_ImgsData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_ImgsData_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_ImgsData_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_ImgsData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_ImgsData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_ImgsData_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_ImgsData_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_ImgsData_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_ImgsData_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_ImgsData_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_ImgsData_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_ImgsData_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_ImgsData_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_ParishArgs = {
  args: Rtcs_Db_Fnkcca_Parish_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Parish_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Parish_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Parish_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Parish_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Parish_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Parish_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Parish_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_Parish_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Parish_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Parish_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Parish_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_Parish_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Parish_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Parish_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PaymentDetailsArgs = {
  args: Rtcs_Db_Fnkcca_PaymentDetails_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PaymentDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PaymentDetails_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PaymentDetails_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_PaymentDetails_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PaymentDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PaymentDetails_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PaymentDetails_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_PaymentDetails_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PaymentDetails_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PaymentDetails_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PaymentDetails_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_PaymentDetails_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PaymentDetails_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PaymentDetails_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PaymentsArgs = {
  args: Rtcs_Db_Fnkcca_Payments_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Payments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Payments_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Payments_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Payments_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Payments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Payments_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Payments_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_Payments_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Payments_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Payments_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Payments_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_Payments_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Payments_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Payments_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertyArgs = {
  args: Rtcs_Db_Fnkcca_Property_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Property_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Property_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Property_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertyFloorsArgs = {
  args: Rtcs_Db_Fnkcca_PropertyFloors_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyFloors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyFloors_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertyFloors_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_PropertyFloors_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyFloors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyFloors_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertyFloors_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_PropertyFloors_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertyFloors_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertyFloors_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertyFloors_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_PropertyFloors_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertyFloors_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertyFloors_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertyNumberBedroomsArgs = {
  args: Rtcs_Db_Fnkcca_PropertyNumberBedrooms_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertyNumberBedrooms_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_PropertyNumberBedrooms_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertyNumberBedrooms_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_PropertyNumberBedrooms_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertyNumberBedrooms_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertyNumberBedrooms_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertyNumberBedrooms_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_PropertyNumberBedrooms_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertyNumberBedrooms_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertyNumberBedrooms_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertyRentedStatusArgs = {
  args: Rtcs_Db_Fnkcca_PropertyRentedStatus_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertyRentedStatus_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_PropertyRentedStatus_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertyRentedStatus_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_PropertyRentedStatus_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertyRentedStatus_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertyRentedStatus_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertyRentedStatus_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_PropertyRentedStatus_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertyRentedStatus_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertyRentedStatus_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertySecurityArgs = {
  args: Rtcs_Db_Fnkcca_PropertySecurity_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySecurity_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySecurity_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertySecurity_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_PropertySecurity_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySecurity_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySecurity_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertySecurity_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_PropertySecurity_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertySecurity_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertySecurity_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertySecurity_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_PropertySecurity_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertySecurity_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertySecurity_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertySubTypeArgs = {
  args: Rtcs_Db_Fnkcca_PropertySubType_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySubType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySubType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertySubType_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_PropertySubType_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySubType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySubType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertySubType_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_PropertySubType_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertySubType_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertySubType_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertySubType_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_PropertySubType_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertySubType_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertySubType_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertyTypeArgs = {
  args: Rtcs_Db_Fnkcca_PropertyType_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertyType_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_PropertyType_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertyType_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_PropertyType_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertyType_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_PropertyType_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertyType_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_PropertyType_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_PropertyType_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_PropertyType_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Property_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Property_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Property_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Property_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Property_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Property_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_Property_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Property_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Property_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Property_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_Property_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Property_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Property_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_RawProperties1Args = {
  args: Rtcs_Db_Fnkcca_RawProperties1_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties1_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties1_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_RawProperties1_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_RawProperties1_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties1_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties1_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_RawProperties1_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_RawProperties1_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_RawProperties1_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_RawProperties1_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_RawProperties1_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_RawProperties1_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_RawProperties1_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_RawProperties1_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_RawProperties2Args = {
  args: Rtcs_Db_Fnkcca_RawProperties2_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties2_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties2_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_RawProperties2_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_RawProperties2_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties2_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties2_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_RawProperties2_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_RawProperties2_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_RawProperties2_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_RawProperties2_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_RawProperties2_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_RawProperties2_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_RawProperties2_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_RawProperties2_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_ResidentialPropertiesArgs = {
  args: Rtcs_Db_Fnkcca_ResidentialProperties_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_ResidentialProperties_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_ResidentialProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_ResidentialProperties_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_ResidentialProperties_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_ResidentialProperties_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_ResidentialProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_ResidentialProperties_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_ResidentialProperties_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_ResidentialProperties_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_ResidentialProperties_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_ResidentialProperties_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_ResidentialProperties_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_ResidentialProperties_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_ResidentialProperties_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_RevenueTypeArgs = {
  args: Rtcs_Db_Fnkcca_RevenueType_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_RevenueType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_RevenueType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_RevenueType_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_RevenueType_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_RevenueType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_RevenueType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_RevenueType_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_RevenueType_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_RevenueType_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_RevenueType_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_RevenueType_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_RevenueType_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_RevenueType_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_RevenueType_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_SubCountyArgs = {
  args: Rtcs_Db_Fnkcca_SubCounty_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_SubCounty_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_SubCounty_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_SubCounty_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_SubCounty_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_SubCounty_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_SubCounty_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_SubCounty_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_SubCounty_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_SubCounty_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_SubCounty_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_SubCounty_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_SubCounty_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_SubCounty_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_SubCounty_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_SubpropertyTypeConversionArgs = {
  args: Rtcs_Db_Fnkcca_SubpropertyTypeConversion_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_SubpropertyTypeConversion_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_SubpropertyTypeConversion_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_SubpropertyTypeConversion_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnkcca_SubpropertyTypeConversion_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_SubpropertyTypeConversion_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_SubpropertyTypeConversion_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_SubpropertyTypeConversion_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnkcca_SubpropertyTypeConversion_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_SubpropertyTypeConversion_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_SubpropertyTypeConversion_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_TradingLicenseArgs = {
  args: Rtcs_Db_Fnkcca_TradingLicense_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_TradingLicense_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_TradingLicense_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_TradingLicense_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_TradingLicense_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_TradingLicense_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_TradingLicense_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_TradingLicense_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_TradingLicense_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_TradingLicense_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_TradingLicense_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_TradingLicense_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_TradingLicense_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_TradingLicense_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_TradingLicense_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnkcca_VillageArgs = {
  args: Rtcs_Db_Fnkcca_Village_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Village_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Village_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Village_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Village_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Village_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Village_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Village_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Village_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Village_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_Village_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Village_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Village_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Village_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_Village_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnkcca_Village_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Village_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmaster_MasterIndivArgs = {
  args: Rtcs_Db_Fnmaster_MasterIndiv_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Master_MasterIndiv_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Master_MasterIndiv_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmaster_MasterIndiv_AggregateArgs = {
  args: Rtcs_Db_Fnmaster_MasterIndiv_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Master_MasterIndiv_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Master_MasterIndiv_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmaster_MasterIndiv_AggregatecmArgs = {
  args: Rtcs_Db_Fnmaster_MasterIndiv_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmaster_MasterIndiv_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnmaster_MasterIndiv_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmaster_MasterIndiv_AggregatetxtArgs = {
  args: Rtcs_Db_Fnmaster_MasterIndiv_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmaster_MasterIndiv_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnmaster_MasterIndiv_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_KccaArgs = {
  args: Rtcs_Db_Fnmlhud_Kcca_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Kcca_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Kcca_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_Kcca_AggregateArgs = {
  args: Rtcs_Db_Fnmlhud_Kcca_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Kcca_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Kcca_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_Kcca_AggregatecmArgs = {
  args: Rtcs_Db_Fnmlhud_Kcca_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_Kcca_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnmlhud_Kcca_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_Kcca_AggregatetxtArgs = {
  args: Rtcs_Db_Fnmlhud_Kcca_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_Kcca_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnmlhud_Kcca_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_MukonoArgs = {
  args: Rtcs_Db_Fnmlhud_Mukono_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Mukono_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Mukono_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_Mukono_AggregateArgs = {
  args: Rtcs_Db_Fnmlhud_Mukono_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Mukono_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Mukono_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_Mukono_AggregatecmArgs = {
  args: Rtcs_Db_Fnmlhud_Mukono_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_Mukono_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnmlhud_Mukono_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_Mukono_AggregatetxtArgs = {
  args: Rtcs_Db_Fnmlhud_Mukono_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_Mukono_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnmlhud_Mukono_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_PropertiesArgs = {
  args: Rtcs_Db_Fnmlhud_Properties_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Properties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Properties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_Properties_AggregateArgs = {
  args: Rtcs_Db_Fnmlhud_Properties_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Properties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Properties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_Properties_AggregatecmArgs = {
  args: Rtcs_Db_Fnmlhud_Properties_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_Properties_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnmlhud_Properties_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_Properties_AggregatetxtArgs = {
  args: Rtcs_Db_Fnmlhud_Properties_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_Properties_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnmlhud_Properties_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_WakisoBusiroArgs = {
  args: Rtcs_Db_Fnmlhud_WakisoBusiro_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_WakisoBusiro_AggregateArgs = {
  args: Rtcs_Db_Fnmlhud_WakisoBusiro_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_WakisoBusiro_AggregatecmArgs = {
  args: Rtcs_Db_Fnmlhud_WakisoBusiro_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_WakisoBusiro_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnmlhud_WakisoBusiro_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_WakisoBusiro_AggregatetxtArgs = {
  args: Rtcs_Db_Fnmlhud_WakisoBusiro_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_WakisoBusiro_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnmlhud_WakisoBusiro_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_WakisoKyadondoArgs = {
  args: Rtcs_Db_Fnmlhud_WakisoKyadondo_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_WakisoKyadondo_AggregateArgs = {
  args: Rtcs_Db_Fnmlhud_WakisoKyadondo_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_WakisoKyadondo_AggregatecmArgs = {
  args: Rtcs_Db_Fnmlhud_WakisoKyadondo_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_WakisoKyadondo_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnmlhud_WakisoKyadondo_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_WakisoKyadondo_AggregatetxtArgs = {
  args: Rtcs_Db_Fnmlhud_WakisoKyadondo_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnmlhud_WakisoKyadondo_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnmlhud_WakisoKyadondo_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnnira_NiraArgs = {
  args: Rtcs_Db_Fnnira_Nira_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nira_Nira_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nira_Nira_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nira_Nira_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnira_Nira_AggregateArgs = {
  args: Rtcs_Db_Fnnira_Nira_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nira_Nira_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nira_Nira_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nira_Nira_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnira_Nira_AggregatecmArgs = {
  args: Rtcs_Db_Fnnira_Nira_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnira_Nira_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnnira_Nira_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnira_Nira_AggregatetxtArgs = {
  args: Rtcs_Db_Fnnira_Nira_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnira_Nira_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnnira_Nira_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnira_RawDataArgs = {
  args: Rtcs_Db_Fnnira_RawData_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nira_RawData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nira_RawData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nira_RawData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnira_RawData_AggregateArgs = {
  args: Rtcs_Db_Fnnira_RawData_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nira_RawData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nira_RawData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nira_RawData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnira_RawData_AggregatecmArgs = {
  args: Rtcs_Db_Fnnira_RawData_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnira_RawData_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnnira_RawData_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnira_RawData_AggregatetxtArgs = {
  args: Rtcs_Db_Fnnira_RawData_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnira_RawData_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnnira_RawData_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_CustomersArgs = {
  args: Rtcs_Db_Fnnwsc_Customers_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Customers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Customers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_Customers_AggregateArgs = {
  args: Rtcs_Db_Fnnwsc_Customers_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Customers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Customers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_Customers_AggregatecmArgs = {
  args: Rtcs_Db_Fnnwsc_Customers_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_Customers_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnnwsc_Customers_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_Customers_AggregatetxtArgs = {
  args: Rtcs_Db_Fnnwsc_Customers_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_Customers_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnnwsc_Customers_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_NwCustomersArgs = {
  args: Rtcs_Db_Fnnwsc_NwCustomers_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_NwCustomers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_NwCustomers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_NwCustomers_AggregateArgs = {
  args: Rtcs_Db_Fnnwsc_NwCustomers_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_NwCustomers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_NwCustomers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_NwCustomers_AggregatecmArgs = {
  args: Rtcs_Db_Fnnwsc_NwCustomers_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_NwCustomers_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnnwsc_NwCustomers_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_NwCustomers_AggregatetxtArgs = {
  args: Rtcs_Db_Fnnwsc_NwCustomers_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_NwCustomers_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnnwsc_NwCustomers_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_Rpt_TransactionsArgs = {
  args: Rtcs_Db_Fnnwsc_Rpt_Transactions_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_Rpt_Transactions_AggregateArgs = {
  args: Rtcs_Db_Fnnwsc_Rpt_Transactions_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_Rpt_Transactions_AggregatecmArgs = {
  args: Rtcs_Db_Fnnwsc_Rpt_Transactions_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_Rpt_Transactions_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnnwsc_Rpt_Transactions_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_Rpt_Transactions_AggregatetxtArgs = {
  args: Rtcs_Db_Fnnwsc_Rpt_Transactions_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_Rpt_Transactions_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnnwsc_Rpt_Transactions_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_TransactionsArgs = {
  args: Rtcs_Db_Fnnwsc_Transactions_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_Transactions_AggregateArgs = {
  args: Rtcs_Db_Fnnwsc_Transactions_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_Transactions_AggregatecmArgs = {
  args: Rtcs_Db_Fnnwsc_Transactions_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_Transactions_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnnwsc_Transactions_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_Transactions_AggregatetxtArgs = {
  args: Rtcs_Db_Fnnwsc_Transactions_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_Transactions_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnnwsc_Transactions_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_UraTransactionsArgs = {
  args: Rtcs_Db_Fnnwsc_UraTransactions_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_UraTransactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_UraTransactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_UraTransactions_AggregateArgs = {
  args: Rtcs_Db_Fnnwsc_UraTransactions_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_UraTransactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_UraTransactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_UraTransactions_AggregatecmArgs = {
  args: Rtcs_Db_Fnnwsc_UraTransactions_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_UraTransactions_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnnwsc_UraTransactions_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_UraTransactions_AggregatetxtArgs = {
  args: Rtcs_Db_Fnnwsc_UraTransactions_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnnwsc_UraTransactions_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnnwsc_UraTransactions_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RptUnittypesArgs = {
  args: Rtcs_Db_Fnrcapture_RptUnittypes_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RptUnittypes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RptUnittypes_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RptUnittypes_AggregateArgs = {
  args: Rtcs_Db_Fnrcapture_RptUnittypes_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RptUnittypes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RptUnittypes_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RptUnittypes_AggregatecmArgs = {
  args: Rtcs_Db_Fnrcapture_RptUnittypes_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RptUnittypes_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrcapture_RptUnittypes_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RptUnittypes_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrcapture_RptUnittypes_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RptUnittypes_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrcapture_RptUnittypes_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RpTlessorsArgs = {
  args: Rtcs_Db_Fnrcapture_RpTlessors_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTlessors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTlessors_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RpTlessors_AggregateArgs = {
  args: Rtcs_Db_Fnrcapture_RpTlessors_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTlessors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTlessors_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RpTlessors_AggregatecmArgs = {
  args: Rtcs_Db_Fnrcapture_RpTlessors_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RpTlessors_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrcapture_RpTlessors_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RpTlessors_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrcapture_RpTlessors_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RpTlessors_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrcapture_RpTlessors_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RpTmetaArgs = {
  args: Rtcs_Db_Fnrcapture_RpTmeta_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTmeta_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTmeta_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RpTmeta_AggregateArgs = {
  args: Rtcs_Db_Fnrcapture_RpTmeta_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTmeta_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTmeta_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RpTmeta_AggregatecmArgs = {
  args: Rtcs_Db_Fnrcapture_RpTmeta_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RpTmeta_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnrcapture_RpTmeta_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RpTmeta_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrcapture_RpTmeta_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RpTmeta_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnrcapture_RpTmeta_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RpTpropertiesArgs = {
  args: Rtcs_Db_Fnrcapture_RpTproperties_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTproperties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTproperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RpTproperties_AggregateArgs = {
  args: Rtcs_Db_Fnrcapture_RpTproperties_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTproperties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTproperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RpTproperties_AggregatecmArgs = {
  args: Rtcs_Db_Fnrcapture_RpTproperties_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RpTproperties_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrcapture_RpTproperties_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RpTproperties_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrcapture_RpTproperties_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RpTproperties_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrcapture_RpTproperties_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RpTtenantsArgs = {
  args: Rtcs_Db_Fnrcapture_RpTtenants_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTtenants_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTtenants_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RpTtenants_AggregateArgs = {
  args: Rtcs_Db_Fnrcapture_RpTtenants_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTtenants_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTtenants_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RpTtenants_AggregatecmArgs = {
  args: Rtcs_Db_Fnrcapture_RpTtenants_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RpTtenants_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrcapture_RpTtenants_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RpTtenants_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrcapture_RpTtenants_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrcapture_RpTtenants_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrcapture_RpTtenants_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualArgs = {
  args: Rtcs_Db_Fnrmatch_Individual_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Individual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Individual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualComplianceDetailReportArgs = {
  args: Rtcs_Db_Fnrmatch_IndividualComplianceDetailReport_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualComplianceDetailReport_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualComplianceDetailReport_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualComplianceDetailReport_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualComplianceDetailReport_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualComplianceDetailReport_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualComplianceDetailReport_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualComplianceDetailReport_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualComplianceDetailReport_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualComplianceDetailReport_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualComplianceDetailReport_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualComplianceSummaryReportArgs = {
  args: Rtcs_Db_Fnrmatch_IndividualComplianceSummaryReport_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualComplianceSummaryReport_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualComplianceSummaryReport_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualComplianceSummaryReport_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualComplianceSummaryReport_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualComplianceSummaryReport_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualComplianceSummaryReport_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualComplianceSummaryReport_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualComplianceSummaryReport_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualComplianceSummaryReport_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualComplianceSummaryReport_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualRiskScoreArgs = {
  args: Rtcs_Db_Fnrmatch_IndividualRiskScore_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualRiskScore_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_IndividualRiskScore_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualRiskScore_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_IndividualRiskScore_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualRiskScore_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualRiskScore_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualRiskScore_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_IndividualRiskScore_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualRiskScore_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualRiskScore_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_Individual_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_Individual_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Individual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Individual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_Individual_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_Individual_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_Individual_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_Individual_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_Individual_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_Individual_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_Individual_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_Individual_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualsNetworkArgs = {
  args: Rtcs_Db_Fnrmatch_IndividualsNetwork_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualsNetworkTestingArgs = {
  args: Rtcs_Db_Fnrmatch_IndividualsNetworkTesting_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualsNetworkTesting_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualsNetworkTesting_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualsNetworkTesting_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualsNetworkTesting_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualsNetworkTesting_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualsNetworkTesting_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualsNetworkTesting_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualsNetworkTesting_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualsNetworkTesting_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualsNetworkTesting_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualsNetwork_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_IndividualsNetwork_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualsNetwork_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_IndividualsNetwork_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualsNetwork_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualsNetwork_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualsNetwork_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_IndividualsNetwork_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_IndividualsNetwork_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualsNetwork_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_KccaToKccaIndividualOwnersArgs = {
  args: Rtcs_Db_Fnrmatch_KccaToKccaIndividualOwners_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_KccaToKccaIndividualOwners_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_KccaToKccaIndividualOwners_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_KccaToKccaIndividualOwners_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_KccaToKccaIndividualOwners_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_KccaToKccaIndividualOwners_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_KccaToKccaIndividualOwners_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_KccaToKccaIndividualOwners_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_KccaToKccaIndividualOwners_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_KccaToKccaIndividualOwners_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_KccaToKccaIndividualOwners_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_KccaToKccaNonindividualOwnersArgs = {
  args: Rtcs_Db_Fnrmatch_KccaToKccaNonindividualOwners_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_KccaToKccaNonindividualOwners_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_KccaToKccaNonindividualOwners_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_KccaToKccaNonindividualOwners_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_KccaToKccaNonindividualOwners_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_KccaToKccaNonindividualOwners_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_KccaToKccaNonindividualOwners_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_KccaToKccaNonindividualOwners_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_KccaToKccaNonindividualOwners_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_KccaToKccaNonindividualOwners_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_KccaToKccaNonindividualOwners_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_MatchIndividualsPropertiesArgs = {
  args: Rtcs_Db_Fnrmatch_MatchIndividualsProperties_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_MatchIndividualsProperties_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_MatchIndividualsProperties_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_MatchIndividualsProperties_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_MatchIndividualsProperties_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_MatchIndividualsProperties_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_MatchIndividualsProperties_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_MatchIndividualsProperties_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_MatchIndividualsProperties_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_MatchIndividualsProperties_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_MatchIndividualsProperties_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_MatchIndividualsPropsArgs = {
  args: Rtcs_Db_Fnrmatch_MatchIndividualsProps_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_MatchIndividualsProps_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_MatchIndividualsProps_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_MatchIndividualsProps_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_MatchIndividualsProps_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_MatchIndividualsProps_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_MatchIndividualsProps_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_MatchIndividualsProps_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_MatchIndividualsProps_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_MatchIndividualsProps_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_MatchIndividualsProps_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NoReturnsArgs = {
  args: Rtcs_Db_Fnrmatch_NoReturns_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NoReturns_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NoReturns_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NoReturns_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_NoReturns_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NoReturns_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NoReturns_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NoReturns_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_NoReturns_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NoReturns_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_NoReturns_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NoReturns_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_NoReturns_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NoReturns_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_NoReturns_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualArgs = {
  args: Rtcs_Db_Fnrmatch_NonIndividual_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualComplianceDetailReportArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualComplianceDetailReport_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualComplianceDetailReport_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualComplianceDetailReport_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualComplianceDetailReport_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualComplianceDetailReport_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualComplianceDetailReport_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualComplianceDetailReport_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualComplianceDetailReport_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualComplianceDetailReport_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualComplianceDetailReport_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualComplianceDetailReport_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReportArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReport_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReport_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReport_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReport_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReport_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReport_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReport_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReport_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReport_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReport_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReport_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualRiskScoreArgs = {
  args: Rtcs_Db_Fnrmatch_NonIndividualRiskScore_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualRiskScore_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_NonIndividualRiskScore_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualRiskScore_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualRiskScore_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualRiskScore_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualRiskScore_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualRiskScore_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualRiskScore_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualRiskScore_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualRiskScore_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividual_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_NonIndividual_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividual_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_NonIndividual_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividual_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividual_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividual_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_NonIndividual_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividual_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividual_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualsArgs = {
  args: Rtcs_Db_Fnrmatch_NonIndividuals_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividuals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividuals_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualsNetworkArgs = {
  args: Rtcs_Db_Fnrmatch_NonIndividualsNetwork_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualsNetwork_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_NonIndividualsNetwork_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualsNetwork_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_NonIndividualsNetwork_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualsNetwork_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualsNetwork_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualsNetwork_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualsNetwork_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividualsNetwork_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualsNetwork_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividuals_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_NonIndividuals_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividuals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividuals_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividuals_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_NonIndividuals_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividuals_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividuals_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividuals_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_NonIndividuals_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonIndividuals_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividuals_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonindividualOwnersArgs = {
  args: Rtcs_Db_Fnrmatch_NonindividualOwners_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonindividualOwners_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_NonindividualOwners_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonindividualOwners_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_NonindividualOwners_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonindividualOwners_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonindividualOwners_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonindividualOwners_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_NonindividualOwners_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonindividualOwners_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonindividualOwners_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_NonindividualsArgs = {
  args: Rtcs_Db_Fnrmatch_Nonindividuals_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Nonindividuals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Nonindividuals_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Nonindividuals_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_Nonindividuals_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_Nonindividuals_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Nonindividuals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Nonindividuals_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Nonindividuals_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_Nonindividuals_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_Nonindividuals_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_Nonindividuals_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_Nonindividuals_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_Nonindividuals_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_Nonindividuals_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_Nonindividuals_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_Nonindividuals_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_PropertiesCompositeArgs = {
  args: Rtcs_Db_Fnrmatch_PropertiesComposite_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_PropertiesComposite_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_PropertiesComposite_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_PropertiesComposite_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_PropertiesComposite_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_PropertiesComposite_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_PropertiesComposite_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_PropertiesComposite_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_PropertiesComposite_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_PropertiesComposite_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_PropertiesComposite_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_PropertiesNetworkArgs = {
  args: Rtcs_Db_Fnrmatch_PropertiesNetwork_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_PropertiesNetwork_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_PropertiesNetwork_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_PropertiesNetwork_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_PropertiesNetwork_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_PropertiesNetwork_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_PropertiesNetwork_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_PropertiesNetwork_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_PropertiesNetwork_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_PropertiesNetwork_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_PropertiesNetwork_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_PropertyNetworkArgs = {
  args: Rtcs_Db_Fnrmatch_PropertyNetwork_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_PropertyNetwork_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_PropertyNetwork_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_PropertyNetwork_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_PropertyNetwork_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_PropertyNetwork_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_PropertyNetwork_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_PropertyNetwork_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_PropertyNetwork_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_PropertyNetwork_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_PropertyNetwork_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchDetailed904Args = {
  args: Rtcs_Db_Fnrmatch_RMatchDetailed904_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchDetailed904_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchDetailed904_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchDetailed904_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchDetailed904_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchDetailed904_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed904_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchDetailed904_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchDetailed904_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchDetailed904_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed904_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchDetailed916Args = {
  args: Rtcs_Db_Fnrmatch_RMatchDetailed916_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancyArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchDetailed916_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchDetailed916_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchDetailed916_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchDetailed916_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchDetailed916_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed916_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchDetailed916_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchDetailed916_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchDetailed916_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed916_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchDetailed937Args = {
  args: Rtcs_Db_Fnrmatch_RMatchDetailed937_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchDetailed937_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchDetailed937_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchDetailed937_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchDetailed937_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchDetailed937_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed937_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchDetailed937_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchDetailed937_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchDetailed937_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed937_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchKccaPropertiesArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchKccaProperties_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchKccaProperties_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchKccaProperties_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchKccaProperties_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchKccaProperties_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchKccaProperties_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchKccaProperties_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchKccaProperties_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchKccaProperties_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchKccaProperties_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchKccaProperties_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchKccaPropertyArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchKccaProperty_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchKccaProperty_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchKccaProperty_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchKccaProperty_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchKccaProperty_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchKccaProperty_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchKccaProperty_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchKccaProperty_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchKccaProperty_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchKccaProperty_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchKccaProperty_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchNonIndividual903Args = {
  args: Rtcs_Db_Fnrmatch_RMatchNonIndividual903_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchNonIndividual903_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchNonIndividual903_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchNonIndividual903_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchNonIndividual903_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchNonIndividual903_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchNonIndividual903_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchNonIndividual903_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchNonIndividual903_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchNonIndividual903_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchNonIndividual903_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchProperties1115Args = {
  args: Rtcs_Db_Fnrmatch_RMatchProperties1115_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchProperties1115_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchProperties1115_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchProperties1115_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchProperties1115_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchProperties1115_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchProperties1115_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchProperties1115_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchProperties1115_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchProperties1115_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchProperties1115_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchSummary904Args = {
  args: Rtcs_Db_Fnrmatch_RMatchSummary904_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchSummary904_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchSummary904_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchSummary904_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchSummary904_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchSummary904_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary904_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchSummary904_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchSummary904_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchSummary904_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary904_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchSummary916Args = {
  args: Rtcs_Db_Fnrmatch_RMatchSummary916_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancyArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchSummary916_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchSummary916_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchSummary916_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchSummary916_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchSummary916_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary916_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchSummary916_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchSummary916_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchSummary916_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary916_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchSummary937Args = {
  args: Rtcs_Db_Fnrmatch_RMatchSummary937_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchSummary937_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchSummary937_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchSummary937_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchSummary937_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchSummary937_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary937_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchSummary937_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchSummary937_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_RMatchSummary937_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary937_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_TinsUraArgs = {
  args: Rtcs_Db_Fnrmatch_TinsUra_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_TinsUra_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_TinsUra_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_TinsUra_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_TinsUra_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_TinsUra_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_TinsUra_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_TinsUra_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_TinsUra_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_TinsUra_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_TinsUra_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_TinsUra_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_TinsUra_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_TinsUra_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_TinsUra_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_UndeclaredArgs = {
  args: Rtcs_Db_Fnrmatch_Undeclared_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Undeclared_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Undeclared_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_Undeclared_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_Undeclared_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Undeclared_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Undeclared_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_Undeclared_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_Undeclared_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_Undeclared_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_Undeclared_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_Undeclared_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_Undeclared_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_Undeclared_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_Undeclared_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_UnderreportedArgs = {
  args: Rtcs_Db_Fnrmatch_Underreported_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Underreported_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Underreported_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_Underreported_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_Underreported_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Underreported_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Underreported_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_Underreported_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_Underreported_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_Underreported_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_Underreported_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_Underreported_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_Underreported_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_Underreported_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_Underreported_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_UnregisteredArgs = {
  args: Rtcs_Db_Fnrmatch_Unregistered_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Unregistered_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Unregistered_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_Unregistered_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_Unregistered_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Unregistered_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Unregistered_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_Unregistered_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_Unregistered_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_Unregistered_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_Unregistered_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_Unregistered_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_Unregistered_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_Unregistered_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_Unregistered_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_MlhudConsolidatedArgs = {
  args: Rtcs_Db_Fnrmatch_MlhudConsolidated_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_MlhudConsolidated_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_MlhudConsolidated_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_MlhudConsolidated_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_MlhudConsolidated_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_MlhudConsolidated_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_MlhudConsolidated_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_MlhudConsolidated_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_MlhudConsolidated_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnrmatch_MlhudConsolidated_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_MlhudConsolidated_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmMukonoMunicipalityArgs = {
  args: Rtcs_Db_Fnstreetmap_OsmMukonoMunicipality_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmMukonoMunicipality_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmMukonoMunicipality_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmMukonoMunicipality_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmMukonoMunicipality_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmMukonoMunicipality_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmMukonoMunicipality_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmMukonoMunicipality_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmMukonoMunicipality_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmMukonoMunicipality_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmMukonoMunicipality_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmMukonoNakifumaArgs = {
  args: Rtcs_Db_Fnstreetmap_OsmMukonoNakifuma_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmMukonoNakifuma_AggregateArgs = {
  args: Rtcs_Db_Fnstreetmap_OsmMukonoNakifuma_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmMukonoNakifuma_AggregatecmArgs = {
  args: Rtcs_Db_Fnstreetmap_OsmMukonoNakifuma_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmMukonoNakifuma_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmMukonoNakifuma_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmMukonoNakifuma_AggregatetxtArgs = {
  args: Rtcs_Db_Fnstreetmap_OsmMukonoNakifuma_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmMukonoNakifuma_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmMukonoNakifuma_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmWakisoEntebbeArgs = {
  args: Rtcs_Db_Fnstreetmap_OsmWakisoEntebbe_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmWakisoEntebbe_AggregateArgs = {
  args: Rtcs_Db_Fnstreetmap_OsmWakisoEntebbe_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmWakisoEntebbe_AggregatecmArgs = {
  args: Rtcs_Db_Fnstreetmap_OsmWakisoEntebbe_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmWakisoEntebbe_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoEntebbe_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmWakisoEntebbe_AggregatetxtArgs = {
  args: Rtcs_Db_Fnstreetmap_OsmWakisoEntebbe_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmWakisoEntebbe_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoEntebbe_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmWakisoKiraArgs = {
  args: Rtcs_Db_Fnstreetmap_OsmWakisoKira_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmWakisoKira_AggregateArgs = {
  args: Rtcs_Db_Fnstreetmap_OsmWakisoKira_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmWakisoKira_AggregatecmArgs = {
  args: Rtcs_Db_Fnstreetmap_OsmWakisoKira_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmWakisoKira_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoKira_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmWakisoKira_AggregatetxtArgs = {
  args: Rtcs_Db_Fnstreetmap_OsmWakisoKira_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmWakisoKira_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoKira_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagaboArgs = {
  args: Rtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmWakisoNansanaArgs = {
  args: Rtcs_Db_Fnstreetmap_OsmWakisoNansana_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmWakisoNansana_AggregateArgs = {
  args: Rtcs_Db_Fnstreetmap_OsmWakisoNansana_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmWakisoNansana_AggregatecmArgs = {
  args: Rtcs_Db_Fnstreetmap_OsmWakisoNansana_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmWakisoNansana_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoNansana_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmWakisoNansana_AggregatetxtArgs = {
  args: Rtcs_Db_Fnstreetmap_OsmWakisoNansana_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnstreetmap_OsmWakisoNansana_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoNansana_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnucc_UccArgs = {
  args: Rtcs_Db_Fnucc_Ucc_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ucc_Ucc_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ucc_Ucc_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnucc_Ucc_AggregateArgs = {
  args: Rtcs_Db_Fnucc_Ucc_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ucc_Ucc_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ucc_Ucc_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnucc_Ucc_AggregatecmArgs = {
  args: Rtcs_Db_Fnucc_Ucc_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnucc_Ucc_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnucc_Ucc_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnucc_Ucc_AggregatetxtArgs = {
  args: Rtcs_Db_Fnucc_Ucc_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnucc_Ucc_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnucc_Ucc_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnumeme_SubscriberAndPropertyArgs = {
  args: Rtcs_Db_Fnumeme_SubscriberAndProperty_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnumeme_SubscriberAndProperty_AggregateArgs = {
  args: Rtcs_Db_Fnumeme_SubscriberAndProperty_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnumeme_SubscriberAndProperty_AggregatecmArgs = {
  args: Rtcs_Db_Fnumeme_SubscriberAndProperty_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnumeme_SubscriberAndProperty_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnumeme_SubscriberAndProperty_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnumeme_SubscriberAndProperty_AggregatetxtArgs = {
  args: Rtcs_Db_Fnumeme_SubscriberAndProperty_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnumeme_SubscriberAndProperty_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnumeme_SubscriberAndProperty_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnumeme_TransactionsArgs = {
  args: Rtcs_Db_Fnumeme_Transactions_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Umeme_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Umeme_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnumeme_Transactions_AggregateArgs = {
  args: Rtcs_Db_Fnumeme_Transactions_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Umeme_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Umeme_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnumeme_Transactions_AggregatecmArgs = {
  args: Rtcs_Db_Fnumeme_Transactions_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnumeme_Transactions_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnumeme_Transactions_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnumeme_Transactions_AggregatetxtArgs = {
  args: Rtcs_Db_Fnumeme_Transactions_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnumeme_Transactions_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnumeme_Transactions_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_BalanceSheetInformationArgs = {
  args: Rtcs_Db_Fnura_BalanceSheetInformation_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_BalanceSheetInformation_AggregateArgs = {
  args: Rtcs_Db_Fnura_BalanceSheetInformation_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_BalanceSheetInformation_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_BalanceSheetInformation_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_BalanceSheetInformation_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_BalanceSheetInformation_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_BalanceSheetInformation_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_BalanceSheetInformation_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_BalanceSheetInformation_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_BalanceSheetInformation_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_CombinedOfficersAndOwnersArgs = {
  args: Rtcs_Db_Fnura_CombinedOfficersAndOwners_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_CombinedOfficersAndOwners_AggregateArgs = {
  args: Rtcs_Db_Fnura_CombinedOfficersAndOwners_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_CombinedOfficersAndOwners_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnura_CombinedOfficersAndOwners_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_CombinedOfficersAndOwners_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_CombinedOfficersAndOwners_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_CombinedOfficersAndOwners_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_CombinedOfficersAndOwners_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_CombinedOfficersAndOwners_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_CombinedOfficersAndOwners_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_EmploymentIncomeDetailArgs = {
  args: Rtcs_Db_Fnura_EmploymentIncomeDetail_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_EmploymentIncomeDetail_AggregateArgs = {
  args: Rtcs_Db_Fnura_EmploymentIncomeDetail_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_EmploymentIncomeDetail_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_EmploymentIncomeDetail_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_EmploymentIncomeDetail_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_EmploymentIncomeDetail_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_EmploymentIncomeDetail_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_EmploymentIncomeDetail_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_EmploymentIncomeDetail_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_EmploymentIncomeDetail_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootageArgs =
  {
    args: Rtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootage_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootage_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootage_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootage_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootage_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootage_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootage_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootage_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootage_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootage_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootage_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_GazetteResidentialRatesPerBedroomArgs = {
  args: Rtcs_Db_Fnura_GazetteResidentialRatesPerBedroom_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_GazetteResidentialRatesPerBedroom_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_GazetteResidentialRatesPerBedroom_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_GazetteResidentialRatesPerBedroom_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnura_GazetteResidentialRatesPerBedroom_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_GazetteResidentialRatesPerBedroom_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_GazetteResidentialRatesPerBedroom_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_GazetteResidentialRatesPerBedroom_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_GazetteResidentialRatesPerBedroom_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_GazetteResidentialRatesPerBedroom_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_GazetteResidentialRatesPerBedroom_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_HoldingOfficersArgs = {
  args: Rtcs_Db_Fnura_HoldingOfficers_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOfficers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOfficers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_HoldingOfficers_AggregateArgs = {
  args: Rtcs_Db_Fnura_HoldingOfficers_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOfficers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOfficers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_HoldingOfficers_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_HoldingOfficers_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_HoldingOfficers_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_HoldingOfficers_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_HoldingOfficers_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_HoldingOfficers_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_HoldingOfficers_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_HoldingOfficers_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_HoldingOwnerArgs = {
  args: Rtcs_Db_Fnura_HoldingOwner_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOwner_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOwner_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_HoldingOwner_AggregateArgs = {
  args: Rtcs_Db_Fnura_HoldingOwner_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOwner_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOwner_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_HoldingOwner_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_HoldingOwner_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_HoldingOwner_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnura_HoldingOwner_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_HoldingOwner_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_HoldingOwner_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_HoldingOwner_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnura_HoldingOwner_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_IncomeTaxHoldingArgs = {
  args: Rtcs_Db_Fnura_IncomeTaxHolding_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_IncomeTaxHolding_AggregateArgs = {
  args: Rtcs_Db_Fnura_IncomeTaxHolding_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_IncomeTaxHolding_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_IncomeTaxHolding_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_IncomeTaxHolding_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_IncomeTaxHolding_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_IncomeTaxHolding_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_IncomeTaxHolding_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_IncomeTaxHolding_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_IncomeTaxHolding_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_IncomeTaxSummaryIndividualArgs = {
  args: Rtcs_Db_Fnura_IncomeTaxSummaryIndividual_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_IncomeTaxSummaryIndividual_AggregateArgs = {
  args: Rtcs_Db_Fnura_IncomeTaxSummaryIndividual_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_IncomeTaxSummaryIndividual_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnura_IncomeTaxSummaryIndividual_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_IncomeTaxSummaryIndividual_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_IncomeTaxSummaryIndividual_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_IncomeTaxSummaryIndividual_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_IncomeTaxSummaryIndividual_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_IncomeTaxSummaryIndividual_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_IncomeTaxSummaryIndividual_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_IncomeTaxSummaryNonIndividualArgs = {
  args: Rtcs_Db_Fnura_IncomeTaxSummaryNonIndividual_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_IncomeTaxSummaryNonIndividual_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_IncomeTaxSummaryNonIndividual_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_IncomeTaxSummaryNonIndividual_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnura_IncomeTaxSummaryNonIndividual_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_IncomeTaxSummaryNonIndividual_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_IncomeTaxSummaryNonIndividual_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_IncomeTaxSummaryNonIndividual_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_IncomeTaxSummaryNonIndividual_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_IncomeTaxSummaryNonIndividual_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_IncomeTaxSummaryNonIndividual_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_IndividualMortgageArgs = {
  args: Rtcs_Db_Fnura_IndividualMortgage_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_IndividualMortgage_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_IndividualMortgage_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_IndividualMortgage_AggregateArgs = {
  args: Rtcs_Db_Fnura_IndividualMortgage_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_IndividualMortgage_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_IndividualMortgage_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_IndividualMortgage_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_IndividualMortgage_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_IndividualMortgage_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_IndividualMortgage_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_IndividualMortgage_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_IndividualMortgage_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_IndividualMortgage_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_IndividualMortgage_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_IndividualsAssociatedBusinessArgs = {
  args: Rtcs_Db_Fnura_IndividualsAssociatedBusiness_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_IndividualsAssociatedBusiness_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_IndividualsAssociatedBusiness_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_IndividualsAssociatedBusiness_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnura_IndividualsAssociatedBusiness_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_IndividualsAssociatedBusiness_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_IndividualsAssociatedBusiness_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_IndividualsAssociatedBusiness_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_IndividualsAssociatedBusiness_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_IndividualsAssociatedBusiness_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_IndividualsAssociatedBusiness_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_LandlordIncomeDetailArgs = {
  args: Rtcs_Db_Fnura_LandlordIncomeDetail_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_LandlordIncomeDetail_AggregateArgs = {
  args: Rtcs_Db_Fnura_LandlordIncomeDetail_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_LandlordIncomeDetail_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_LandlordIncomeDetail_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_LandlordIncomeDetail_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_LandlordIncomeDetail_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_LandlordIncomeDetail_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_LandlordIncomeDetail_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_LandlordIncomeDetail_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_LandlordIncomeDetail_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_LandlordIncomeSummaryArgs = {
  args: Rtcs_Db_Fnura_LandlordIncomeSummary_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_LandlordIncomeSummary_AggregateArgs = {
  args: Rtcs_Db_Fnura_LandlordIncomeSummary_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_LandlordIncomeSummary_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_LandlordIncomeSummary_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_LandlordIncomeSummary_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_LandlordIncomeSummary_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_LandlordIncomeSummary_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_LandlordIncomeSummary_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_LandlordIncomeSummary_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_LandlordIncomeSummary_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_NonIndividualsAssociatedBusinessArgs = {
  args: Rtcs_Db_Fnura_NonIndividualsAssociatedBusiness_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_NonIndividualsAssociatedBusiness_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_NonIndividualsAssociatedBusiness_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_NonIndividualsAssociatedBusiness_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnura_NonIndividualsAssociatedBusiness_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_NonIndividualsAssociatedBusiness_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_NonIndividualsAssociatedBusiness_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_NonIndividualsAssociatedBusiness_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_NonIndividualsAssociatedBusiness_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_NonIndividualsAssociatedBusiness_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_NonIndividualsAssociatedBusiness_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_RegAssociatesArgs = {
  args: Rtcs_Db_Fnura_RegAssociates_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegAssociates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegAssociates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RegAssociates_AggregateArgs = {
  args: Rtcs_Db_Fnura_RegAssociates_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegAssociates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegAssociates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RegAssociates_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_RegAssociates_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RegAssociates_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnura_RegAssociates_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RegAssociates_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_RegAssociates_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RegAssociates_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnura_RegAssociates_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RegIndividualArgs = {
  args: Rtcs_Db_Fnura_RegIndividual_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RegIndividual_AggregateArgs = {
  args: Rtcs_Db_Fnura_RegIndividual_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RegIndividual_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_RegIndividual_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RegIndividual_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnura_RegIndividual_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RegIndividual_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_RegIndividual_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RegIndividual_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnura_RegIndividual_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RegIndividual_SidArgs = {
  args: Rtcs_Db_Fnura_RegIndividual_Sid_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RegIndividual_Sid_AggregateArgs = {
  args: Rtcs_Db_Fnura_RegIndividual_Sid_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RegIndividual_Sid_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_RegIndividual_Sid_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RegIndividual_Sid_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RegIndividual_Sid_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_RegIndividual_Sid_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_RegIndividual_Sid_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RegIndividual_Sid_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RegIndividual_Sid_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_RegNonIndividualArgs = {
  args: Rtcs_Db_Fnura_RegNonIndividual_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RegNonIndividual_AggregateArgs = {
  args: Rtcs_Db_Fnura_RegNonIndividual_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RegNonIndividual_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_RegNonIndividual_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RegNonIndividual_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RegNonIndividual_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_RegNonIndividual_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_RegNonIndividual_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RegNonIndividual_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RegNonIndividual_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_RegNonIndividual_SidArgs = {
  args: Rtcs_Db_Fnura_RegNonIndividual_Sid_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RegNonIndividual_Sid_AggregateArgs = {
  args: Rtcs_Db_Fnura_RegNonIndividual_Sid_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RegNonIndividual_Sid_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_RegNonIndividual_Sid_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RegNonIndividual_Sid_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RegNonIndividual_Sid_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_RegNonIndividual_Sid_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_RegNonIndividual_Sid_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RegNonIndividual_Sid_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RegNonIndividual_Sid_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_RentExpenseDetailsArgs = {
  args: Rtcs_Db_Fnura_RentExpenseDetails_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RentExpenseDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RentExpenseDetails_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RentExpenseDetails_AggregateArgs = {
  args: Rtcs_Db_Fnura_RentExpenseDetails_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RentExpenseDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RentExpenseDetails_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RentExpenseDetails_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_RentExpenseDetails_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RentExpenseDetails_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RentExpenseDetails_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_RentExpenseDetails_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_RentExpenseDetails_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RentExpenseDetails_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RentExpenseDetails_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_RentalLandLordIncomeArgs = {
  args: Rtcs_Db_Fnura_RentalLandLordIncome_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RentalLandLordIncome_AggregateArgs = {
  args: Rtcs_Db_Fnura_RentalLandLordIncome_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RentalLandLordIncome_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_RentalLandLordIncome_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RentalLandLordIncome_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RentalLandLordIncome_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_RentalLandLordIncome_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_RentalLandLordIncome_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RentalLandLordIncome_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RentalLandLordIncome_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_RentalTenantPaymentsArgs = {
  args: Rtcs_Db_Fnura_RentalTenantPayments_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalTenantPayments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalTenantPayments_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RentalTenantPayments_AggregateArgs = {
  args: Rtcs_Db_Fnura_RentalTenantPayments_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalTenantPayments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalTenantPayments_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RentalTenantPayments_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_RentalTenantPayments_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RentalTenantPayments_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RentalTenantPayments_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_RentalTenantPayments_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_RentalTenantPayments_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RentalTenantPayments_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RentalTenantPayments_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_RipoIndRentalIncSummaryArgs = {
  args: Rtcs_Db_Fnura_RipoIndRentalIncSummary_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RipoIndRentalIncSummary_AggregateArgs = {
  args: Rtcs_Db_Fnura_RipoIndRentalIncSummary_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RipoIndRentalIncSummary_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_RipoIndRentalIncSummary_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RipoIndRentalIncSummary_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RipoIndRentalIncSummary_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_RipoIndRentalIncSummary_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_RipoIndRentalIncSummary_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_RipoIndRentalIncSummary_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RipoIndRentalIncSummary_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_TenantIncomeDetailArgs = {
  args: Rtcs_Db_Fnura_TenantIncomeDetail_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_TenantIncomeDetail_AggregateArgs = {
  args: Rtcs_Db_Fnura_TenantIncomeDetail_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_TenantIncomeDetail_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_TenantIncomeDetail_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_TenantIncomeDetail_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_TenantIncomeDetail_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_TenantIncomeDetail_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_TenantIncomeDetail_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_TenantIncomeDetail_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_TenantIncomeDetail_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnura_TownArgs = {
  args: Rtcs_Db_Fnura_Town_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_Town_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_Town_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_Town_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_Town_AggregateArgs = {
  args: Rtcs_Db_Fnura_Town_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_Town_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_Town_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_Town_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_Town_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_Town_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_Town_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnura_Town_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_Town_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_Town_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnura_Town_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnura_Town_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsbArgs = {
  args: Rtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsb_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsb_AggregateArgs =
  {
    args: Rtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsb_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsb_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsb_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsb_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsb_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsb_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsb_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsb_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsb_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_GazzetteRatesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_GazzetteRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_GazzetteRates_Order_By>>;
  where?: Maybe<Rtcs_Db_GazzetteRates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_GazzetteRates_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_GazzetteRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_GazzetteRates_Order_By>>;
  where?: Maybe<Rtcs_Db_GazzetteRates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_EntityFilterArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_EntityFilter_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_EntityFilter_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_EntityFilter_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_EntityFilter_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_EntityFilter_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_EntityFilter_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_EntityFilter_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_FilterArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Filter_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Filter_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Filter_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Filter_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Filter_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Filter_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Filter_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_GazzetteRatesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_GazzetteRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_GazzetteRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_GazzetteRates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_GazzetteRates_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_GazzetteRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_GazzetteRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_GazzetteRates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRiskArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_IndividualCompositeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_IndividualComposite_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_IndividualRegistrationArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_IndividualRegistration_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualRegistration_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_IndividualRegistration_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_IndividualRegistration_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualRegistration_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_IndividualTaxSummaryDataArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_IndividualTaxSummaryData_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_KccaAffiliatesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaAffiliates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaAffiliates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_KccaAffiliates_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaAffiliates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaAffiliates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_KccaCustomerRegistrationDataArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_KccaPropertyRegistrationArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_KccaPropertyRegistrationDataArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_KccaPropertyRegistration_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_NonIndividualBalanceSheetArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRiskArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_NonIndividualCompositeArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_NonIndividualComposite_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_NonIndividualTaxSummaryDataArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_PropertiesCompositeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertiesComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertiesComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_PropertiesComposite_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertiesComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertiesComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_PropertyValuationArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertyValuation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertyValuation_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_PropertyValuation_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertyValuation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertyValuation_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_RentalTenantPaymentsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rpt_RentalTenantPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Gazette_CommercialRatesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Gazette_CommercialRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Gazette_CommercialRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Gazette_CommercialRates_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Gazette_CommercialRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Gazette_CommercialRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Gazette_ResidentialRatesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Gazette_ResidentialRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Gazette_ResidentialRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Gazette_ResidentialRates_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Gazette_ResidentialRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Gazette_ResidentialRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_AssociateTypeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_AssociateType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_AssociateType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_AssociateType_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_AssociateType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_AssociateType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CommercialPropertiesArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CommercialProperties_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CommercialProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CommercialProperties_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CommercialProperties_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CommercialProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CountyArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_County_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_County_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_County_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_County_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_County_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_County_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_County_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CustomerArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CustomerApplicantTypeArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CustomerApplicantType_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CustomerBusinessAssociateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CustomerBusinessAssociate_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CustomerBusinessTypeArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CustomerBusinessType_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactiveArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CustomerContactPersonArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CustomerContactPerson_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CustomerDocumentItemArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CustomerDocumentItem_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CustomerGuardianArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerGuardian_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerGuardian_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CustomerGuardian_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerGuardian_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerGuardian_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CustomerReferenceArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerReference_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerReference_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CustomerReference_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerReference_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerReference_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CustomerRegistrationReasonArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CustomerRegistrationReason_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CustomerRevenueAgentArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CustomerRevenueAgent_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CustomerTypeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_CustomerType_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_Customer_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_Customer_SidArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_Customer_Sid_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_DistrictArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_District_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_District_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_District_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_District_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_District_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_District_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_District_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_DivisionArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Division_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Division_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Division_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_Division_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Division_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Division_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Division_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_ImgsDataArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_ImgsData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_ImgsData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_ImgsData_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_ImgsData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_ImgsData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_ParishArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Parish_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Parish_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_Parish_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Parish_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Parish_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_PaymentDetailsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PaymentDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PaymentDetails_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_PaymentDetails_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PaymentDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PaymentDetails_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_PaymentsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Payments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Payments_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_Payments_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Payments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Payments_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_PropertyArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Property_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Property_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Property_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_PropertyFloorsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyFloors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyFloors_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_PropertyFloors_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyFloors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyFloors_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_PropertyNumberBedroomsArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_PropertyNumberBedrooms_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_PropertyRentedStatusArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_PropertyRentedStatus_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_PropertySecurityArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySecurity_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySecurity_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_PropertySecurity_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySecurity_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySecurity_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_PropertySubTypeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySubType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySubType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_PropertySubType_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySubType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySubType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_PropertyTypeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_PropertyType_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_Property_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Property_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Property_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Property_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_RawProperties1Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties1_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties1_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_RawProperties1_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties1_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties1_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_RawProperties2Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties2_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties2_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_RawProperties2_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties2_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties2_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_ResidentialPropertiesArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_ResidentialProperties_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_ResidentialProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_ResidentialProperties_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_ResidentialProperties_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_ResidentialProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_RevenueTypeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_RevenueType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_RevenueType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_RevenueType_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_RevenueType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_RevenueType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_SubCountyArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_SubCounty_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_SubCounty_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_SubCounty_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_SubCounty_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_SubCounty_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_SubpropertyTypeConversionArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_SubpropertyTypeConversion_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_TradingLicenseArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_TradingLicense_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_TradingLicense_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_TradingLicense_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_TradingLicense_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_TradingLicense_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_VillageArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Village_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Village_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Village_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Kcca_Village_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Village_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Village_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Village_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Master_MasterIndivArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Master_MasterIndiv_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Master_MasterIndiv_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Master_MasterIndiv_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Master_MasterIndiv_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Master_MasterIndiv_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Mlhud_KccaArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Kcca_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Kcca_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Mlhud_Kcca_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Kcca_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Kcca_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Mlhud_MukonoArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Mukono_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Mukono_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Mlhud_Mukono_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Mukono_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Mukono_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Mlhud_PropertiesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Properties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Properties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Mlhud_Properties_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Properties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Properties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Mlhud_WakisoBusiroArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Mlhud_WakisoBusiro_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Mlhud_WakisoKyadondoArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Mlhud_WakisoKyadondo_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Nira_NiraArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nira_Nira_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nira_Nira_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nira_Nira_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Nira_Nira_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nira_Nira_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nira_Nira_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nira_Nira_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Nira_RawDataArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nira_RawData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nira_RawData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nira_RawData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Nira_RawData_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nira_RawData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nira_RawData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nira_RawData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Nwsc_CustomersArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Customers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Customers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Nwsc_Customers_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Customers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Customers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Nwsc_NwCustomersArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_NwCustomers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_NwCustomers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Nwsc_NwCustomers_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_NwCustomers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_NwCustomers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Nwsc_Rpt_TransactionsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Nwsc_Rpt_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Nwsc_TransactionsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Nwsc_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Nwsc_UraTransactionsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_UraTransactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_UraTransactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Nwsc_UraTransactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_UraTransactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_UraTransactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rcapture_RptUnittypesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RptUnittypes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RptUnittypes_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rcapture_RptUnittypes_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RptUnittypes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RptUnittypes_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rcapture_RpTlessorsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTlessors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTlessors_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rcapture_RpTlessors_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTlessors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTlessors_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rcapture_RpTmetaArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTmeta_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTmeta_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rcapture_RpTmeta_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTmeta_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTmeta_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rcapture_RpTpropertiesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTproperties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTproperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rcapture_RpTproperties_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTproperties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTproperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rcapture_RpTtenantsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTtenants_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTtenants_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rcapture_RpTtenants_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTtenants_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTtenants_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_IndividualArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Individual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Individual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_IndividualComplianceDetailReportArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReportArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_IndividualRiskScoreArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_IndividualRiskScore_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_Individual_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Individual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Individual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_IndividualsNetworkArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_IndividualsNetworkTestingArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_IndividualsNetwork_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwnersArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwnersArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_MatchIndividualsPropertiesArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_MatchIndividualsProperties_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_MatchIndividualsPropsArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_MatchIndividualsProps_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_NoReturnsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NoReturns_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NoReturns_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_NoReturns_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NoReturns_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NoReturns_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_NonIndividualArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReportArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReportArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_NonIndividualRiskScoreArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_NonIndividualRiskScore_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_NonIndividual_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_NonIndividualsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividuals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividuals_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_NonIndividualsNetworkArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_NonIndividualsNetwork_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_NonIndividuals_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividuals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividuals_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_NonindividualOwnersArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_NonindividualOwners_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_NonindividualsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Nonindividuals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Nonindividuals_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Nonindividuals_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_Nonindividuals_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Nonindividuals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Nonindividuals_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Nonindividuals_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_PropertiesCompositeArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_PropertiesComposite_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_PropertiesNetworkArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_PropertiesNetwork_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_PropertyNetworkArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_PropertyNetwork_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_RMatchDetailed904Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_RMatchDetailed904_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_RMatchDetailed916Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancyArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_RMatchDetailed916_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_RMatchDetailed937Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_RMatchDetailed937_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_RMatchKccaPropertiesArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_RMatchKccaProperties_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_RMatchKccaPropertyArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_RMatchKccaProperty_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_RMatchNonIndividual903Args = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_RMatchNonIndividual903_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_RMatchProperties1115Args = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_RMatchProperties1115_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_RMatchSummary904Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_RMatchSummary904_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_RMatchSummary916Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancyArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_RMatchSummary916_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_RMatchSummary937Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_RMatchSummary937_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_TinsUraArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_TinsUra_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_TinsUra_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_TinsUra_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_TinsUra_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_TinsUra_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_UndeclaredArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Undeclared_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Undeclared_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_Undeclared_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Undeclared_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Undeclared_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_UnderreportedArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Underreported_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Underreported_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_Underreported_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Underreported_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Underreported_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_UnregisteredArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Unregistered_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Unregistered_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_Unregistered_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Unregistered_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Unregistered_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_MlhudConsolidatedArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Rmatch_MlhudConsolidated_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Streetmap_OsmMukonoMunicipalityArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Streetmap_OsmMukonoNakifumaArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Streetmap_OsmWakisoEntebbeArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Streetmap_OsmWakisoKiraArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Streetmap_OsmWakisoKira_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagaboArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Streetmap_OsmWakisoNansanaArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Streetmap_OsmWakisoNansana_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ucc_UccArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ucc_Ucc_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ucc_Ucc_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ucc_Ucc_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ucc_Ucc_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ucc_Ucc_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Umeme_SubscriberAndPropertyArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Umeme_SubscriberAndProperty_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Umeme_TransactionsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Umeme_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Umeme_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Umeme_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Umeme_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Umeme_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_BalanceSheetInformationArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_BalanceSheetInformation_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_CombinedOfficersAndOwnersArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_CombinedOfficersAndOwners_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_EmploymentIncomeDetailArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_EmploymentIncomeDetail_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootageArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroomArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_HoldingOfficersArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOfficers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOfficers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_HoldingOfficers_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOfficers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOfficers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_HoldingOwnerArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOwner_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOwner_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_HoldingOwner_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOwner_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOwner_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_IncomeTaxHoldingArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_IncomeTaxHolding_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_IncomeTaxSummaryIndividualArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividualArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_IndividualMortgageArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_IndividualMortgage_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_IndividualMortgage_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_IndividualMortgage_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_IndividualMortgage_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_IndividualMortgage_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_IndividualsAssociatedBusinessArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_LandlordIncomeDetailArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_LandlordIncomeDetail_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_LandlordIncomeSummaryArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_LandlordIncomeSummary_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_NonIndividualsAssociatedBusinessArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_RegAssociatesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegAssociates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegAssociates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_RegAssociates_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegAssociates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegAssociates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_RegIndividualArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_RegIndividual_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_RegIndividual_SidArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_RegIndividual_Sid_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_RegNonIndividualArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_RegNonIndividual_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_RegNonIndividual_SidArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_RegNonIndividual_Sid_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_RentExpenseDetailsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RentExpenseDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RentExpenseDetails_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_RentExpenseDetails_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RentExpenseDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RentExpenseDetails_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_RentalLandLordIncomeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_RentalLandLordIncome_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_RentalTenantPaymentsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalTenantPayments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalTenantPayments_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_RentalTenantPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalTenantPayments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalTenantPayments_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_RipoIndRentalIncSummaryArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_RipoIndRentalIncSummary_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_TenantIncomeDetailArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_TenantIncomeDetail_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_TownArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_Town_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_Town_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_Town_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ura_Town_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_Town_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_Town_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_Town_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsbArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Rpt_IndividualComplianceSummaryPlusRiskArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Rpt_IndividualCompositeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_IndividualComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_IndividualComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_IndividualComposite_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_IndividualComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_IndividualComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_IndividualRegistrationArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_IndividualRegistration_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_IndividualRegistration_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_IndividualRegistration_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_IndividualRegistration_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_IndividualRegistration_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_IndividualTaxSummaryDataArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rpt_IndividualTaxSummaryData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_IndividualTaxSummaryData_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_IndividualTaxSummaryData_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rpt_IndividualTaxSummaryData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_IndividualTaxSummaryData_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_KccaAffiliatesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_KccaAffiliates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_KccaAffiliates_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_KccaAffiliates_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_KccaAffiliates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_KccaAffiliates_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_KccaCustomerRegistrationDataArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_KccaCustomerRegistrationData_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_KccaPropertyRegistrationArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rpt_KccaPropertyRegistration_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_KccaPropertyRegistration_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_KccaPropertyRegistrationDataArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_KccaPropertyRegistrationData_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_KccaPropertyRegistration_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rpt_KccaPropertyRegistration_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_KccaPropertyRegistration_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_NonIndividualBalanceSheetArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_NonIndividualBalanceSheet_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRiskArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Rpt_NonIndividualCompositeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_NonIndividualComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_NonIndividualComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_NonIndividualComposite_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_NonIndividualComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_NonIndividualComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_NonIndividualTaxSummaryDataArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_NonIndividualTaxSummaryData_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_PropertiesCompositeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_PropertiesComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_PropertiesComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_PropertiesComposite_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_PropertiesComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_PropertiesComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_PropertyValuationArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_PropertyValuation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_PropertyValuation_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_PropertyValuation_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_PropertyValuation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_PropertyValuation_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_RentalTenantPaymentsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_RentalTenantPayments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_RentalTenantPayments_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rpt_RentalTenantPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_RentalTenantPayments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_RentalTenantPayments_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Aggregate_ResultArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Aggregate_Result_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Aggregate_Result_TxtArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Aggregate_Result_Txt_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Gazette_CommercialRatesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Gazette_CommercialRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Gazette_CommercialRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Gazette_CommercialRates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Gazette_CommercialRates_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Gazette_CommercialRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Gazette_CommercialRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Gazette_CommercialRates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Gazette_ResidentialRatesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Gazette_ResidentialRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Gazette_ResidentialRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Gazette_ResidentialRates_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Gazette_ResidentialRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Gazette_ResidentialRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_AssociateTypeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_AssociateType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_AssociateType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_AssociateType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_AssociateType_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_AssociateType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_AssociateType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_AssociateType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_CommercialPropertiesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CommercialProperties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CommercialProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_CommercialProperties_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CommercialProperties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CommercialProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_CountyArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_County_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_County_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_County_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_County_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_County_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_County_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_County_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_CustomerArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Customer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Customer_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Customer_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_CustomerApplicantTypeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerApplicantType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerApplicantType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_CustomerApplicantType_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerApplicantType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerApplicantType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_CustomerBusinessAssociateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Kcca_CustomerBusinessAssociate_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerBusinessAssociate_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_CustomerBusinessAssociate_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Kcca_CustomerBusinessAssociate_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerBusinessAssociate_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_CustomerBusinessTypeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerBusinessType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerBusinessType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_CustomerBusinessType_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerBusinessType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerBusinessType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_CustomerCoinDeactivateReactiveArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_CustomerCoinDeactivateReactive_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Kcca_CustomerContactPersonArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerContactPerson_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerContactPerson_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_CustomerContactPerson_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerContactPerson_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerContactPerson_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_CustomerDocumentItemArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerDocumentItem_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerDocumentItem_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_CustomerDocumentItem_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerDocumentItem_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerDocumentItem_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_CustomerGuardianArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerGuardian_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerGuardian_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_CustomerGuardian_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerGuardian_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerGuardian_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_CustomerReferenceArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerReference_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerReference_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerReference_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_CustomerReference_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerReference_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerReference_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerReference_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_CustomerRegistrationReasonArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Kcca_CustomerRegistrationReason_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerRegistrationReason_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_CustomerRegistrationReason_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Kcca_CustomerRegistrationReason_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerRegistrationReason_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_CustomerRevenueAgentArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerRevenueAgent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerRevenueAgent_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_CustomerRevenueAgent_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerRevenueAgent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerRevenueAgent_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_CustomerTypeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_CustomerType_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_Customer_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Customer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Customer_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Customer_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_Customer_SidArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Customer_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Customer_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_Customer_Sid_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Customer_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Customer_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_DistrictArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_District_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_District_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_District_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_District_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_District_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_District_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_District_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_DivisionArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Division_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Division_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Division_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_Division_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Division_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Division_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Division_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_ImgsDataArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_ImgsData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_ImgsData_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_ImgsData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_ImgsData_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_ImgsData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_ImgsData_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_ImgsData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_ParishArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Parish_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Parish_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Parish_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_Parish_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Parish_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Parish_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Parish_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_PaymentDetailsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PaymentDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PaymentDetails_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_PaymentDetails_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PaymentDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PaymentDetails_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_PaymentsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Payments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Payments_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Payments_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_Payments_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Payments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Payments_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Payments_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_PropertyArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Property_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Property_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Property_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_PropertyFloorsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PropertyFloors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PropertyFloors_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_PropertyFloors_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PropertyFloors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PropertyFloors_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_PropertyNumberBedroomsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PropertyNumberBedrooms_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PropertyNumberBedrooms_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_PropertyNumberBedrooms_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PropertyNumberBedrooms_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PropertyNumberBedrooms_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_PropertyRentedStatusArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PropertyRentedStatus_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PropertyRentedStatus_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_PropertyRentedStatus_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PropertyRentedStatus_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PropertyRentedStatus_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_PropertySecurityArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PropertySecurity_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PropertySecurity_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_PropertySecurity_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PropertySecurity_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PropertySecurity_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_PropertySubTypeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PropertySubType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PropertySubType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PropertySubType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_PropertySubType_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PropertySubType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PropertySubType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PropertySubType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_PropertyTypeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PropertyType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PropertyType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PropertyType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_PropertyType_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PropertyType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PropertyType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PropertyType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_Property_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Property_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Property_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Property_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_RawProperties1Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_RawProperties1_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_RawProperties1_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_RawProperties1_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_RawProperties1_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_RawProperties1_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_RawProperties1_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_RawProperties1_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_RawProperties2Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_RawProperties2_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_RawProperties2_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_RawProperties2_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_RawProperties2_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_RawProperties2_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_RawProperties2_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_RawProperties2_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_ResidentialPropertiesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_ResidentialProperties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_ResidentialProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_ResidentialProperties_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_ResidentialProperties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_ResidentialProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_RevenueTypeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_RevenueType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_RevenueType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_RevenueType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_RevenueType_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_RevenueType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_RevenueType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_RevenueType_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_SubCountyArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_SubCounty_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_SubCounty_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_SubCounty_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_SubCounty_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_SubCounty_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_SubCounty_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_SubCounty_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_SubpropertyTypeConversionArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Kcca_SubpropertyTypeConversion_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_SubpropertyTypeConversion_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_SubpropertyTypeConversion_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Kcca_SubpropertyTypeConversion_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_SubpropertyTypeConversion_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_TradingLicenseArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_TradingLicense_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_TradingLicense_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_TradingLicense_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_TradingLicense_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_TradingLicense_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_TradingLicense_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_TradingLicense_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_VillageArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Village_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Village_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Village_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Kcca_Village_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Village_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Village_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Village_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Master_MasterIndivArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Master_MasterIndiv_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Master_MasterIndiv_Order_By>>;
  where?: Maybe<Rtcs_Db_Master_MasterIndiv_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Master_MasterIndiv_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Master_MasterIndiv_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Master_MasterIndiv_Order_By>>;
  where?: Maybe<Rtcs_Db_Master_MasterIndiv_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Mlhud_KccaArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Mlhud_Kcca_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Mlhud_Kcca_Order_By>>;
  where?: Maybe<Rtcs_Db_Mlhud_Kcca_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Mlhud_Kcca_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Mlhud_Kcca_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Mlhud_Kcca_Order_By>>;
  where?: Maybe<Rtcs_Db_Mlhud_Kcca_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Mlhud_MukonoArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Mlhud_Mukono_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Mlhud_Mukono_Order_By>>;
  where?: Maybe<Rtcs_Db_Mlhud_Mukono_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Mlhud_Mukono_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Mlhud_Mukono_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Mlhud_Mukono_Order_By>>;
  where?: Maybe<Rtcs_Db_Mlhud_Mukono_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Mlhud_PropertiesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Mlhud_Properties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Mlhud_Properties_Order_By>>;
  where?: Maybe<Rtcs_Db_Mlhud_Properties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Mlhud_Properties_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Mlhud_Properties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Mlhud_Properties_Order_By>>;
  where?: Maybe<Rtcs_Db_Mlhud_Properties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Mlhud_WakisoBusiroArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Mlhud_WakisoBusiro_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Mlhud_WakisoBusiro_Order_By>>;
  where?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Mlhud_WakisoBusiro_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Mlhud_WakisoBusiro_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Mlhud_WakisoBusiro_Order_By>>;
  where?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Mlhud_WakisoKyadondoArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Mlhud_WakisoKyadondo_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Mlhud_WakisoKyadondo_Order_By>>;
  where?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Mlhud_WakisoKyadondo_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Mlhud_WakisoKyadondo_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Mlhud_WakisoKyadondo_Order_By>>;
  where?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Nira_NiraArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nira_Nira_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nira_Nira_Order_By>>;
  where?: Maybe<Rtcs_Db_Nira_Nira_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Nira_Nira_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nira_Nira_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nira_Nira_Order_By>>;
  where?: Maybe<Rtcs_Db_Nira_Nira_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Nira_RawDataArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nira_RawData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nira_RawData_Order_By>>;
  where?: Maybe<Rtcs_Db_Nira_RawData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Nira_RawData_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nira_RawData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nira_RawData_Order_By>>;
  where?: Maybe<Rtcs_Db_Nira_RawData_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Nwsc_CustomersArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nwsc_Customers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nwsc_Customers_Order_By>>;
  where?: Maybe<Rtcs_Db_Nwsc_Customers_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Nwsc_Customers_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nwsc_Customers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nwsc_Customers_Order_By>>;
  where?: Maybe<Rtcs_Db_Nwsc_Customers_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Nwsc_NwCustomersArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nwsc_NwCustomers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nwsc_NwCustomers_Order_By>>;
  where?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Nwsc_NwCustomers_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nwsc_NwCustomers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nwsc_NwCustomers_Order_By>>;
  where?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Nwsc_Rpt_TransactionsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nwsc_Rpt_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nwsc_Rpt_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Nwsc_Rpt_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nwsc_Rpt_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nwsc_Rpt_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Nwsc_TransactionsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nwsc_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nwsc_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Nwsc_Transactions_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Nwsc_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nwsc_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nwsc_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Nwsc_Transactions_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Nwsc_UraTransactionsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nwsc_UraTransactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nwsc_UraTransactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Nwsc_UraTransactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nwsc_UraTransactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nwsc_UraTransactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rcapture_RptUnittypesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rcapture_RptUnittypes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rcapture_RptUnittypes_Order_By>>;
  where?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rcapture_RptUnittypes_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rcapture_RptUnittypes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rcapture_RptUnittypes_Order_By>>;
  where?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rcapture_RpTlessorsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rcapture_RpTlessors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rcapture_RpTlessors_Order_By>>;
  where?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rcapture_RpTlessors_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rcapture_RpTlessors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rcapture_RpTlessors_Order_By>>;
  where?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rcapture_RpTmetaArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rcapture_RpTmeta_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rcapture_RpTmeta_Order_By>>;
  where?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rcapture_RpTmeta_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rcapture_RpTmeta_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rcapture_RpTmeta_Order_By>>;
  where?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rcapture_RpTpropertiesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rcapture_RpTproperties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rcapture_RpTproperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rcapture_RpTproperties_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rcapture_RpTproperties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rcapture_RpTproperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rcapture_RpTtenantsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rcapture_RpTtenants_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rcapture_RpTtenants_Order_By>>;
  where?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rcapture_RpTtenants_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rcapture_RpTtenants_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rcapture_RpTtenants_Order_By>>;
  where?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_IndividualArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_Individual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_Individual_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_Individual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_IndividualComplianceDetailReportArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_IndividualComplianceDetailReport_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Rmatch_IndividualComplianceSummaryReportArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_IndividualComplianceSummaryReport_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Rmatch_IndividualRiskScoreArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_IndividualRiskScore_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_IndividualRiskScore_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_IndividualRiskScore_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_IndividualRiskScore_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_IndividualRiskScore_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_Individual_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_Individual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_Individual_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_Individual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_IndividualsNetworkArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_IndividualsNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_IndividualsNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_IndividualsNetworkTestingArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_IndividualsNetworkTesting_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_IndividualsNetwork_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_IndividualsNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_IndividualsNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_KccaToKccaIndividualOwnersArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_KccaToKccaIndividualOwners_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Order_By>>;
    where?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Rmatch_KccaToKccaNonindividualOwnersArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_KccaToKccaNonindividualOwners_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Rmatch_MatchIndividualsPropertiesArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rmatch_MatchIndividualsProperties_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_MatchIndividualsProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_MatchIndividualsProperties_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_MatchIndividualsProperties_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Rmatch_MatchIndividualsProperties_Order_By>>;
    where?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Rmatch_MatchIndividualsPropsArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rmatch_MatchIndividualsProps_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_MatchIndividualsProps_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_MatchIndividualsProps_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rmatch_MatchIndividualsProps_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_MatchIndividualsProps_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_NoReturnsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_NoReturns_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_NoReturns_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_NoReturns_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_NoReturns_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_NoReturns_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_NoReturns_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_NoReturns_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_NonIndividualArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_NonIndividualComplianceDetailReportArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_NonIndividualComplianceDetailReport_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Rmatch_NonIndividualComplianceSummaryReportArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Rmatch_NonIndividualRiskScoreArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rmatch_NonIndividualRiskScore_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividualRiskScore_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_NonIndividualRiskScore_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rmatch_NonIndividualRiskScore_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividualRiskScore_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_NonIndividual_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_NonIndividualsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividuals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividuals_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_NonIndividualsNetworkArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rmatch_NonIndividualsNetwork_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividualsNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_NonIndividualsNetwork_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rmatch_NonIndividualsNetwork_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividualsNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_NonIndividuals_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividuals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividuals_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_NonindividualOwnersArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_NonindividualOwners_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_NonindividualOwners_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_NonindividualOwners_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_NonindividualOwners_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_NonindividualOwners_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_NonindividualsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_Nonindividuals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_Nonindividuals_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_Nonindividuals_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_Nonindividuals_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_Nonindividuals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_Nonindividuals_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_Nonindividuals_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_PropertiesCompositeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_PropertiesComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_PropertiesComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_PropertiesComposite_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_PropertiesComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_PropertiesComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_PropertiesNetworkArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_PropertiesNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_PropertiesNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_PropertiesNetwork_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_PropertiesNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_PropertiesNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_PropertyNetworkArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_PropertyNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_PropertyNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_PropertyNetwork_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_PropertyNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_PropertyNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_RMatchDetailed904Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed904_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_RMatchDetailed904_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed904_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_RMatchDetailed916Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed916_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancyArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Rmatch_RMatchDetailed916_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed916_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_RMatchDetailed937Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed937_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed937_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_RMatchDetailed937_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed937_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed937_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_RMatchKccaPropertiesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchKccaProperties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchKccaProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_RMatchKccaProperties_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchKccaProperties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchKccaProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_RMatchKccaPropertyArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchKccaProperty_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchKccaProperty_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_RMatchKccaProperty_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchKccaProperty_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchKccaProperty_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_RMatchNonIndividual903Args = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rmatch_RMatchNonIndividual903_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchNonIndividual903_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_RMatchNonIndividual903_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rmatch_RMatchNonIndividual903_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchNonIndividual903_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_RMatchProperties1115Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchProperties1115_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchProperties1115_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_RMatchProperties1115_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchProperties1115_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchProperties1115_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_RMatchSummary904Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary904_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_RMatchSummary904_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary904_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_RMatchSummary916Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary916_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancyArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Rmatch_RMatchSummary916_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary916_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_RMatchSummary937Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary937_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary937_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_RMatchSummary937_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary937_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary937_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_TinsUraArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_TinsUra_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_TinsUra_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_TinsUra_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_TinsUra_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_TinsUra_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_TinsUra_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_TinsUra_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_UndeclaredArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_Undeclared_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_Undeclared_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_Undeclared_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_Undeclared_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_Undeclared_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_Undeclared_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_Undeclared_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_UnderreportedArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_Underreported_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_Underreported_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_Underreported_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_Underreported_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_Underreported_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_Underreported_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_Underreported_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_UnregisteredArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_Unregistered_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_Unregistered_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_Unregistered_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_Unregistered_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_Unregistered_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_Unregistered_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_Unregistered_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_MlhudConsolidatedArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_MlhudConsolidated_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_MlhudConsolidated_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Rmatch_MlhudConsolidated_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_MlhudConsolidated_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_MlhudConsolidated_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Streetmap_OsmMukonoMunicipalityArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Order_By>>;
  where?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Streetmap_OsmMukonoMunicipality_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Order_By>>;
  where?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Streetmap_OsmMukonoNakifumaArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Order_By>>;
  where?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Streetmap_OsmMukonoNakifuma_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Order_By>>;
  where?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Streetmap_OsmWakisoEntebbeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Order_By>>;
  where?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Streetmap_OsmWakisoEntebbe_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Order_By>>;
  where?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Streetmap_OsmWakisoKiraArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Streetmap_OsmWakisoKira_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Streetmap_OsmWakisoKira_Order_By>>;
  where?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Streetmap_OsmWakisoKira_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Streetmap_OsmWakisoKira_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Streetmap_OsmWakisoKira_Order_By>>;
  where?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Streetmap_OsmWakisoMakindyeSsabagaboArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Streetmap_OsmWakisoNansanaArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Streetmap_OsmWakisoNansana_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Streetmap_OsmWakisoNansana_Order_By>>;
  where?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Streetmap_OsmWakisoNansana_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Streetmap_OsmWakisoNansana_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Streetmap_OsmWakisoNansana_Order_By>>;
  where?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ucc_UccArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ucc_Ucc_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ucc_Ucc_Order_By>>;
  where?: Maybe<Rtcs_Db_Ucc_Ucc_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ucc_Ucc_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ucc_Ucc_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ucc_Ucc_Order_By>>;
  where?: Maybe<Rtcs_Db_Ucc_Ucc_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Umeme_SubscriberAndPropertyArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Umeme_SubscriberAndProperty_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Umeme_SubscriberAndProperty_Order_By>>;
  where?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Umeme_SubscriberAndProperty_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Umeme_SubscriberAndProperty_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Umeme_SubscriberAndProperty_Order_By>>;
  where?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Umeme_TransactionsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Umeme_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Umeme_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Umeme_Transactions_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Umeme_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Umeme_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Umeme_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Umeme_Transactions_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_BalanceSheetInformationArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_BalanceSheetInformation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_BalanceSheetInformation_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_BalanceSheetInformation_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_BalanceSheetInformation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_BalanceSheetInformation_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_CombinedOfficersAndOwnersArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ura_CombinedOfficersAndOwners_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_CombinedOfficersAndOwners_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_CombinedOfficersAndOwners_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ura_CombinedOfficersAndOwners_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_CombinedOfficersAndOwners_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_EmploymentIncomeDetailArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_EmploymentIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_EmploymentIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_EmploymentIncomeDetail_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_EmploymentIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_EmploymentIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_GazetteCommercialRatesPerSquareFootageArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ura_GazetteResidentialRatesPerBedroomArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_GazetteResidentialRatesPerBedroom_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ura_HoldingOfficersArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_HoldingOfficers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_HoldingOfficers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_HoldingOfficers_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_HoldingOfficers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_HoldingOfficers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_HoldingOwnerArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_HoldingOwner_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_HoldingOwner_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_HoldingOwner_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_HoldingOwner_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_HoldingOwner_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_HoldingOwner_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_HoldingOwner_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_IncomeTaxHoldingArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_IncomeTaxHolding_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_IncomeTaxHolding_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_IncomeTaxHolding_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_IncomeTaxHolding_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_IncomeTaxHolding_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_IncomeTaxSummaryIndividualArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_IncomeTaxSummaryIndividual_AggregateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_IncomeTaxSummaryNonIndividualArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_IncomeTaxSummaryNonIndividual_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Order_By>>;
    where?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ura_IndividualMortgageArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_IndividualMortgage_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_IndividualMortgage_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_IndividualMortgage_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_IndividualMortgage_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_IndividualMortgage_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_IndividualsAssociatedBusinessArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_IndividualsAssociatedBusiness_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Order_By>>;
    where?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ura_LandlordIncomeDetailArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_LandlordIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_LandlordIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_LandlordIncomeDetail_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_LandlordIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_LandlordIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_LandlordIncomeSummaryArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_LandlordIncomeSummary_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_LandlordIncomeSummary_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_LandlordIncomeSummary_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_LandlordIncomeSummary_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_LandlordIncomeSummary_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_NonIndividualsAssociatedBusinessArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_NonIndividualsAssociatedBusiness_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Bool_Exp>;
  };

/** query root */
export type Query_RootRtcs_Db_Ura_RegAssociatesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RegAssociates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RegAssociates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RegAssociates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_RegAssociates_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RegAssociates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RegAssociates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RegAssociates_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_RegIndividualArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RegIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RegIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RegIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_RegIndividual_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RegIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RegIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RegIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_RegIndividual_SidArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RegIndividual_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RegIndividual_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_RegIndividual_Sid_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RegIndividual_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RegIndividual_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_RegNonIndividualArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RegNonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RegNonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_RegNonIndividual_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RegNonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RegNonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_RegNonIndividual_SidArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RegNonIndividual_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RegNonIndividual_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_RegNonIndividual_Sid_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RegNonIndividual_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RegNonIndividual_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_RentExpenseDetailsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RentExpenseDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RentExpenseDetails_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_RentExpenseDetails_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RentExpenseDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RentExpenseDetails_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_RentalLandLordIncomeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RentalLandLordIncome_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RentalLandLordIncome_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_RentalLandLordIncome_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RentalLandLordIncome_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RentalLandLordIncome_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_RentalTenantPaymentsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RentalTenantPayments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RentalTenantPayments_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_RentalTenantPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RentalTenantPayments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RentalTenantPayments_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_RipoIndRentalIncSummaryArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RipoIndRentalIncSummary_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RipoIndRentalIncSummary_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_RipoIndRentalIncSummary_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RipoIndRentalIncSummary_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RipoIndRentalIncSummary_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_TenantIncomeDetailArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_TenantIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_TenantIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_TenantIncomeDetail_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_TenantIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_TenantIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_TownArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_Town_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_Town_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_Town_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ura_Town_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_Town_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_Town_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_Town_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ursb_CompaniesAndBusinessNamesUrsbArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Order_By>>;
  where?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp>;
};

/** query root */
export type Query_RootRtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp>;
  };

/** columns and relationships of "rtcs_db.EntityFilter" */
export type Rtcs_Db_EntityFilter = {
  __typename?: "rtcs_db_EntityFilter";
  EntityId?: Maybe<Scalars["String"]>;
  EntityIdType?: Maybe<Scalars["String"]>;
  FilterName?: Maybe<Scalars["String"]>;
  FilterValue?: Maybe<Scalars["bigint"]>;
};

/** aggregated selection of "rtcs_db.EntityFilter" */
export type Rtcs_Db_EntityFilter_Aggregate = {
  __typename?: "rtcs_db_EntityFilter_aggregate";
  aggregate?: Maybe<Rtcs_Db_EntityFilter_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_EntityFilter>;
};

/** aggregate fields of "rtcs_db.EntityFilter" */
export type Rtcs_Db_EntityFilter_Aggregate_Fields = {
  __typename?: "rtcs_db_EntityFilter_aggregate_fields";
  avg?: Maybe<Rtcs_Db_EntityFilter_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_EntityFilter_Max_Fields>;
  min?: Maybe<Rtcs_Db_EntityFilter_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_EntityFilter_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_EntityFilter_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_EntityFilter_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_EntityFilter_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_EntityFilter_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_EntityFilter_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_EntityFilter_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.EntityFilter" */
export type Rtcs_Db_EntityFilter_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_EntityFilter_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.EntityFilter" */
export type Rtcs_Db_EntityFilter_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_EntityFilter_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_EntityFilter_Max_Order_By>;
  min?: Maybe<Rtcs_Db_EntityFilter_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_EntityFilter_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_EntityFilter_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_EntityFilter_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_EntityFilter_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_EntityFilter_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_EntityFilter_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_EntityFilter_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.EntityFilter" */
export type Rtcs_Db_EntityFilter_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_EntityFilter_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_EntityFilter_Avg_Fields = {
  __typename?: "rtcs_db_EntityFilter_avg_fields";
  FilterValue?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.EntityFilter" */
export type Rtcs_Db_EntityFilter_Avg_Order_By = {
  FilterValue?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.EntityFilter". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_EntityFilter_Bool_Exp = {
  EntityId?: Maybe<String_Comparison_Exp>;
  EntityIdType?: Maybe<String_Comparison_Exp>;
  FilterName?: Maybe<String_Comparison_Exp>;
  FilterValue?: Maybe<Bigint_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_EntityFilter_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_EntityFilter_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_EntityFilter_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.EntityFilter" */
export type Rtcs_Db_EntityFilter_Inc_Input = {
  FilterValue?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.EntityFilter" */
export type Rtcs_Db_EntityFilter_Insert_Input = {
  EntityId?: Maybe<Scalars["String"]>;
  EntityIdType?: Maybe<Scalars["String"]>;
  FilterName?: Maybe<Scalars["String"]>;
  FilterValue?: Maybe<Scalars["bigint"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_EntityFilter_Max_Fields = {
  __typename?: "rtcs_db_EntityFilter_max_fields";
  EntityId?: Maybe<Scalars["String"]>;
  EntityIdType?: Maybe<Scalars["String"]>;
  FilterName?: Maybe<Scalars["String"]>;
  FilterValue?: Maybe<Scalars["bigint"]>;
};

/** order by max() on columns of table "rtcs_db.EntityFilter" */
export type Rtcs_Db_EntityFilter_Max_Order_By = {
  EntityId?: Maybe<Order_By>;
  EntityIdType?: Maybe<Order_By>;
  FilterName?: Maybe<Order_By>;
  FilterValue?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_EntityFilter_Min_Fields = {
  __typename?: "rtcs_db_EntityFilter_min_fields";
  EntityId?: Maybe<Scalars["String"]>;
  EntityIdType?: Maybe<Scalars["String"]>;
  FilterName?: Maybe<Scalars["String"]>;
  FilterValue?: Maybe<Scalars["bigint"]>;
};

/** order by min() on columns of table "rtcs_db.EntityFilter" */
export type Rtcs_Db_EntityFilter_Min_Order_By = {
  EntityId?: Maybe<Order_By>;
  EntityIdType?: Maybe<Order_By>;
  FilterName?: Maybe<Order_By>;
  FilterValue?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.EntityFilter" */
export type Rtcs_Db_EntityFilter_Mutation_Response = {
  __typename?: "rtcs_db_EntityFilter_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_EntityFilter>;
};

/** input type for inserting object relation for remote table "rtcs_db.EntityFilter" */
export type Rtcs_Db_EntityFilter_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_EntityFilter_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.EntityFilter" */
export type Rtcs_Db_EntityFilter_Order_By = {
  EntityId?: Maybe<Order_By>;
  EntityIdType?: Maybe<Order_By>;
  FilterName?: Maybe<Order_By>;
  FilterValue?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.EntityFilter" */
export enum Rtcs_Db_EntityFilter_Select_Column {
  /** column name */
  EntityId = "EntityId",
  /** column name */
  EntityIdType = "EntityIdType",
  /** column name */
  FilterName = "FilterName",
  /** column name */
  FilterValue = "FilterValue",
}

/** input type for updating data in table "rtcs_db.EntityFilter" */
export type Rtcs_Db_EntityFilter_Set_Input = {
  EntityId?: Maybe<Scalars["String"]>;
  EntityIdType?: Maybe<Scalars["String"]>;
  FilterName?: Maybe<Scalars["String"]>;
  FilterValue?: Maybe<Scalars["bigint"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_EntityFilter_Stddev_Fields = {
  __typename?: "rtcs_db_EntityFilter_stddev_fields";
  FilterValue?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.EntityFilter" */
export type Rtcs_Db_EntityFilter_Stddev_Order_By = {
  FilterValue?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_EntityFilter_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_EntityFilter_stddev_pop_fields";
  FilterValue?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.EntityFilter" */
export type Rtcs_Db_EntityFilter_Stddev_Pop_Order_By = {
  FilterValue?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_EntityFilter_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_EntityFilter_stddev_samp_fields";
  FilterValue?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.EntityFilter" */
export type Rtcs_Db_EntityFilter_Stddev_Samp_Order_By = {
  FilterValue?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_EntityFilter_Sum_Fields = {
  __typename?: "rtcs_db_EntityFilter_sum_fields";
  FilterValue?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.EntityFilter" */
export type Rtcs_Db_EntityFilter_Sum_Order_By = {
  FilterValue?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_EntityFilter_Var_Pop_Fields = {
  __typename?: "rtcs_db_EntityFilter_var_pop_fields";
  FilterValue?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.EntityFilter" */
export type Rtcs_Db_EntityFilter_Var_Pop_Order_By = {
  FilterValue?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_EntityFilter_Var_Samp_Fields = {
  __typename?: "rtcs_db_EntityFilter_var_samp_fields";
  FilterValue?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.EntityFilter" */
export type Rtcs_Db_EntityFilter_Var_Samp_Order_By = {
  FilterValue?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_EntityFilter_Variance_Fields = {
  __typename?: "rtcs_db_EntityFilter_variance_fields";
  FilterValue?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.EntityFilter" */
export type Rtcs_Db_EntityFilter_Variance_Order_By = {
  FilterValue?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.Filter" */
export type Rtcs_Db_Filter = {
  __typename?: "rtcs_db_Filter";
  SqlClause?: Maybe<Scalars["String"]>;
  TableName?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.Filter" */
export type Rtcs_Db_Filter_Aggregate = {
  __typename?: "rtcs_db_Filter_aggregate";
  aggregate?: Maybe<Rtcs_Db_Filter_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Filter>;
};

/** aggregate fields of "rtcs_db.Filter" */
export type Rtcs_Db_Filter_Aggregate_Fields = {
  __typename?: "rtcs_db_Filter_aggregate_fields";
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Filter_Max_Fields>;
  min?: Maybe<Rtcs_Db_Filter_Min_Fields>;
};

/** aggregate fields of "rtcs_db.Filter" */
export type Rtcs_Db_Filter_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Filter_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.Filter" */
export type Rtcs_Db_Filter_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Filter_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Filter_Min_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.Filter" */
export type Rtcs_Db_Filter_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Filter_Insert_Input>;
};

/** Boolean expression to filter rows from the table "rtcs_db.Filter". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Filter_Bool_Exp = {
  SqlClause?: Maybe<String_Comparison_Exp>;
  TableName?: Maybe<String_Comparison_Exp>;
  Type?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Filter_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Filter_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Filter_Bool_Exp>>>;
};

/** input type for inserting data into table "rtcs_db.Filter" */
export type Rtcs_Db_Filter_Insert_Input = {
  SqlClause?: Maybe<Scalars["String"]>;
  TableName?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Filter_Max_Fields = {
  __typename?: "rtcs_db_Filter_max_fields";
  SqlClause?: Maybe<Scalars["String"]>;
  TableName?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.Filter" */
export type Rtcs_Db_Filter_Max_Order_By = {
  SqlClause?: Maybe<Order_By>;
  TableName?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Filter_Min_Fields = {
  __typename?: "rtcs_db_Filter_min_fields";
  SqlClause?: Maybe<Scalars["String"]>;
  TableName?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.Filter" */
export type Rtcs_Db_Filter_Min_Order_By = {
  SqlClause?: Maybe<Order_By>;
  TableName?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.Filter" */
export type Rtcs_Db_Filter_Mutation_Response = {
  __typename?: "rtcs_db_Filter_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Filter>;
};

/** input type for inserting object relation for remote table "rtcs_db.Filter" */
export type Rtcs_Db_Filter_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Filter_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.Filter" */
export type Rtcs_Db_Filter_Order_By = {
  SqlClause?: Maybe<Order_By>;
  TableName?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.Filter" */
export enum Rtcs_Db_Filter_Select_Column {
  /** column name */
  SqlClause = "SqlClause",
  /** column name */
  TableName = "TableName",
  /** column name */
  Type = "Type",
}

/** input type for updating data in table "rtcs_db.Filter" */
export type Rtcs_Db_Filter_Set_Input = {
  SqlClause?: Maybe<Scalars["String"]>;
  TableName?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnEntityFilter_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnEntityFilter_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnEntityFilter_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnFilter_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnFilter_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnFilter_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnGazzetteRates_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnGazzetteRates_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnGazzetteRates_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_IndividualComplianceSummaryPlusRisk_Aggregatecm_Args =
  {
    aggregate_params?: Maybe<Scalars["String"]>;
    params?: Maybe<Scalars["String"]>;
  };

export type Rtcs_Db_FnRpt_IndividualComplianceSummaryPlusRisk_Aggregatetxt_Args =
  {
    aggregate_params?: Maybe<Scalars["String"]>;
    params?: Maybe<Scalars["String"]>;
  };

export type Rtcs_Db_FnRpt_IndividualComplianceSummaryPlusRisk_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_IndividualComposite_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_IndividualComposite_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_IndividualComposite_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_IndividualRegistration_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_IndividualRegistration_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_IndividualRegistration_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_IndividualTaxSummaryData_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_IndividualTaxSummaryData_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_IndividualTaxSummaryData_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_KccaAffiliates_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_KccaAffiliates_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_KccaAffiliates_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_KccaCustomerRegistrationData_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_KccaCustomerRegistrationData_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_KccaCustomerRegistrationData_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_KccaPropertyRegistrationData_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_KccaPropertyRegistrationData_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_KccaPropertyRegistrationData_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_KccaPropertyRegistration_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_KccaPropertyRegistration_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_KccaPropertyRegistration_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_NonIndividualBalanceSheet_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_NonIndividualBalanceSheet_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_NonIndividualBalanceSheet_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRisk_Aggregatecm_Args =
  {
    aggregate_params?: Maybe<Scalars["String"]>;
    params?: Maybe<Scalars["String"]>;
  };

export type Rtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRisk_Aggregatetxt_Args =
  {
    aggregate_params?: Maybe<Scalars["String"]>;
    params?: Maybe<Scalars["String"]>;
  };

export type Rtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRisk_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_NonIndividualComposite_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_NonIndividualComposite_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_NonIndividualComposite_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_NonIndividualTaxSummaryData_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_NonIndividualTaxSummaryData_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_NonIndividualTaxSummaryData_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_PropertiesComposite_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_PropertiesComposite_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_PropertiesComposite_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_PropertyValuation_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_PropertyValuation_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_PropertyValuation_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_RentalTenantPayments_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_RentalTenantPayments_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_FnRpt_RentalTenantPayments_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fngazette_CommercialRates_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fngazette_CommercialRates_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fngazette_CommercialRates_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fngazette_ResidentialRates_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fngazette_ResidentialRates_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fngazette_ResidentialRates_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_AssociateType_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_AssociateType_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_AssociateType_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CommercialProperties_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CommercialProperties_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CommercialProperties_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_County_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_County_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_County_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerApplicantType_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerApplicantType_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerApplicantType_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerBusinessAssociate_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerBusinessAssociate_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerBusinessAssociate_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerBusinessType_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerBusinessType_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerBusinessType_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerCoinDeactivateReactive_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerCoinDeactivateReactive_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerCoinDeactivateReactive_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerContactPerson_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerContactPerson_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerContactPerson_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerDocumentItem_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerDocumentItem_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerDocumentItem_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerGuardian_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerGuardian_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerGuardian_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerReference_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerReference_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerReference_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerRegistrationReason_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerRegistrationReason_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerRegistrationReason_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerRevenueAgent_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerRevenueAgent_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerRevenueAgent_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerType_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerType_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_CustomerType_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_Customer_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_Customer_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_Customer_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_Customer_Sid_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_Customer_Sid_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_Customer_Sid_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_District_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_District_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_District_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_Division_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_Division_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_Division_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_ImgsData_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_ImgsData_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_ImgsData_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_Parish_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_Parish_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_Parish_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_PaymentDetails_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_PaymentDetails_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_PaymentDetails_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_Payments_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_Payments_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_Payments_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_PropertyFloors_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_PropertyFloors_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_PropertyFloors_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_PropertyNumberBedrooms_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_PropertyNumberBedrooms_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_PropertyNumberBedrooms_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_PropertyRentedStatus_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_PropertyRentedStatus_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_PropertyRentedStatus_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_PropertySecurity_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_PropertySecurity_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_PropertySecurity_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_PropertySubType_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_PropertySubType_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_PropertySubType_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_PropertyType_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_PropertyType_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_PropertyType_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_Property_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_Property_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_Property_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_RawProperties1_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_RawProperties1_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_RawProperties1_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_RawProperties2_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_RawProperties2_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_RawProperties2_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_ResidentialProperties_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_ResidentialProperties_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_ResidentialProperties_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_RevenueType_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_RevenueType_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_RevenueType_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_SubCounty_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_SubCounty_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_SubCounty_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_SubpropertyTypeConversion_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_SubpropertyTypeConversion_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_SubpropertyTypeConversion_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_TradingLicense_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_TradingLicense_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_TradingLicense_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_Village_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_Village_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnkcca_Village_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnmaster_MasterIndiv_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnmaster_MasterIndiv_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnmaster_MasterIndiv_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnmlhud_Kcca_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnmlhud_Kcca_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnmlhud_Kcca_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnmlhud_Mukono_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnmlhud_Mukono_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnmlhud_Mukono_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnmlhud_Properties_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnmlhud_Properties_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnmlhud_Properties_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnmlhud_WakisoBusiro_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnmlhud_WakisoBusiro_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnmlhud_WakisoBusiro_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnmlhud_WakisoKyadondo_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnmlhud_WakisoKyadondo_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnmlhud_WakisoKyadondo_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnnira_Nira_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnnira_Nira_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnnira_Nira_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnnira_RawData_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnnira_RawData_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnnira_RawData_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnnwsc_Customers_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnnwsc_Customers_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnnwsc_Customers_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnnwsc_NwCustomers_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnnwsc_NwCustomers_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnnwsc_NwCustomers_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnnwsc_Rpt_Transactions_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnnwsc_Rpt_Transactions_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnnwsc_Rpt_Transactions_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnnwsc_Transactions_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnnwsc_Transactions_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnnwsc_Transactions_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnnwsc_UraTransactions_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnnwsc_UraTransactions_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnnwsc_UraTransactions_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrcapture_RptUnittypes_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrcapture_RptUnittypes_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrcapture_RptUnittypes_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrcapture_RpTlessors_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrcapture_RpTlessors_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrcapture_RpTlessors_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrcapture_RpTmeta_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrcapture_RpTmeta_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrcapture_RpTmeta_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrcapture_RpTproperties_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrcapture_RpTproperties_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrcapture_RpTproperties_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrcapture_RpTtenants_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrcapture_RpTtenants_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrcapture_RpTtenants_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_IndividualComplianceDetailReport_Aggregatecm_Args =
  {
    aggregate_params?: Maybe<Scalars["String"]>;
    params?: Maybe<Scalars["String"]>;
  };

export type Rtcs_Db_Fnrmatch_IndividualComplianceDetailReport_Aggregatetxt_Args =
  {
    aggregate_params?: Maybe<Scalars["String"]>;
    params?: Maybe<Scalars["String"]>;
  };

export type Rtcs_Db_Fnrmatch_IndividualComplianceDetailReport_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_IndividualComplianceSummaryReport_Aggregatecm_Args =
  {
    aggregate_params?: Maybe<Scalars["String"]>;
    params?: Maybe<Scalars["String"]>;
  };

export type Rtcs_Db_Fnrmatch_IndividualComplianceSummaryReport_Aggregatetxt_Args =
  {
    aggregate_params?: Maybe<Scalars["String"]>;
    params?: Maybe<Scalars["String"]>;
  };

export type Rtcs_Db_Fnrmatch_IndividualComplianceSummaryReport_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_IndividualRiskScore_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_IndividualRiskScore_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_IndividualRiskScore_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_Individual_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_Individual_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_Individual_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_IndividualsNetworkTesting_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_IndividualsNetworkTesting_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_IndividualsNetworkTesting_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_IndividualsNetwork_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_IndividualsNetwork_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_IndividualsNetwork_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_KccaToKccaIndividualOwners_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_KccaToKccaIndividualOwners_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_KccaToKccaIndividualOwners_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_KccaToKccaNonindividualOwners_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_KccaToKccaNonindividualOwners_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_KccaToKccaNonindividualOwners_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_MatchIndividualsProperties_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_MatchIndividualsProperties_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_MatchIndividualsProperties_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_MatchIndividualsProps_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_MatchIndividualsProps_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_MatchIndividualsProps_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_NoReturns_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_NoReturns_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_NoReturns_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_NonIndividualComplianceDetailReport_Aggregatecm_Args =
  {
    aggregate_params?: Maybe<Scalars["String"]>;
    params?: Maybe<Scalars["String"]>;
  };

export type Rtcs_Db_Fnrmatch_NonIndividualComplianceDetailReport_Aggregatetxt_Args =
  {
    aggregate_params?: Maybe<Scalars["String"]>;
    params?: Maybe<Scalars["String"]>;
  };

export type Rtcs_Db_Fnrmatch_NonIndividualComplianceDetailReport_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReport_Aggregatecm_Args =
  {
    aggregate_params?: Maybe<Scalars["String"]>;
    params?: Maybe<Scalars["String"]>;
  };

export type Rtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReport_Aggregatetxt_Args =
  {
    aggregate_params?: Maybe<Scalars["String"]>;
    params?: Maybe<Scalars["String"]>;
  };

export type Rtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReport_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_NonIndividualRiskScore_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_NonIndividualRiskScore_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_NonIndividualRiskScore_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_NonIndividual_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_NonIndividual_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_NonIndividual_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_NonIndividualsNetwork_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_NonIndividualsNetwork_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_NonIndividualsNetwork_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_NonIndividuals_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_NonIndividuals_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_NonIndividuals_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_NonindividualOwners_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_NonindividualOwners_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_NonindividualOwners_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_Nonindividuals_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_Nonindividuals_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_Nonindividuals_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_PropertiesComposite_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_PropertiesComposite_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_PropertiesComposite_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_PropertiesNetwork_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_PropertiesNetwork_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_PropertiesNetwork_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_PropertyNetwork_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_PropertyNetwork_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_PropertyNetwork_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchDetailed904_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchDetailed904_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchDetailed904_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregatecm_Args =
  {
    aggregate_params?: Maybe<Scalars["String"]>;
    params?: Maybe<Scalars["String"]>;
  };

export type Rtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregatetxt_Args =
  {
    aggregate_params?: Maybe<Scalars["String"]>;
    params?: Maybe<Scalars["String"]>;
  };

export type Rtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchDetailed916_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchDetailed916_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchDetailed916_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchDetailed937_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchDetailed937_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchDetailed937_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchKccaProperties_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchKccaProperties_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchKccaProperties_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchKccaProperty_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchKccaProperty_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchKccaProperty_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchNonIndividual903_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchNonIndividual903_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchNonIndividual903_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchProperties1115_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchProperties1115_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchProperties1115_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchSummary904_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchSummary904_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchSummary904_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregatecm_Args =
  {
    aggregate_params?: Maybe<Scalars["String"]>;
    params?: Maybe<Scalars["String"]>;
  };

export type Rtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregatetxt_Args =
  {
    aggregate_params?: Maybe<Scalars["String"]>;
    params?: Maybe<Scalars["String"]>;
  };

export type Rtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchSummary916_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchSummary916_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchSummary916_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchSummary937_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchSummary937_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_RMatchSummary937_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_TinsUra_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_TinsUra_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_TinsUra_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_Undeclared_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_Undeclared_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_Undeclared_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_Underreported_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_Underreported_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_Underreported_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_Unregistered_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_Unregistered_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_Unregistered_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_MlhudConsolidated_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_MlhudConsolidated_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnrmatch_MlhudConsolidated_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnstreetmap_OsmMukonoMunicipality_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnstreetmap_OsmMukonoMunicipality_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnstreetmap_OsmMukonoMunicipality_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnstreetmap_OsmMukonoNakifuma_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnstreetmap_OsmMukonoNakifuma_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnstreetmap_OsmMukonoNakifuma_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnstreetmap_OsmWakisoEntebbe_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnstreetmap_OsmWakisoEntebbe_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnstreetmap_OsmWakisoEntebbe_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnstreetmap_OsmWakisoKira_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnstreetmap_OsmWakisoKira_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnstreetmap_OsmWakisoKira_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnstreetmap_OsmWakisoNansana_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnstreetmap_OsmWakisoNansana_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnstreetmap_OsmWakisoNansana_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnucc_Ucc_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnucc_Ucc_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnucc_Ucc_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnumeme_SubscriberAndProperty_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnumeme_SubscriberAndProperty_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnumeme_SubscriberAndProperty_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnumeme_Transactions_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnumeme_Transactions_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnumeme_Transactions_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_BalanceSheetInformation_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_BalanceSheetInformation_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_BalanceSheetInformation_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_CombinedOfficersAndOwners_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_CombinedOfficersAndOwners_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_CombinedOfficersAndOwners_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_EmploymentIncomeDetail_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_EmploymentIncomeDetail_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_EmploymentIncomeDetail_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootage_Aggregatecm_Args =
  {
    aggregate_params?: Maybe<Scalars["String"]>;
    params?: Maybe<Scalars["String"]>;
  };

export type Rtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootage_Aggregatetxt_Args =
  {
    aggregate_params?: Maybe<Scalars["String"]>;
    params?: Maybe<Scalars["String"]>;
  };

export type Rtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootage_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_GazetteResidentialRatesPerBedroom_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_GazetteResidentialRatesPerBedroom_Aggregatetxt_Args =
  {
    aggregate_params?: Maybe<Scalars["String"]>;
    params?: Maybe<Scalars["String"]>;
  };

export type Rtcs_Db_Fnura_GazetteResidentialRatesPerBedroom_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_HoldingOfficers_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_HoldingOfficers_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_HoldingOfficers_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_HoldingOwner_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_HoldingOwner_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_HoldingOwner_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_IncomeTaxHolding_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_IncomeTaxHolding_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_IncomeTaxHolding_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_IncomeTaxSummaryIndividual_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_IncomeTaxSummaryIndividual_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_IncomeTaxSummaryIndividual_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_IncomeTaxSummaryNonIndividual_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_IncomeTaxSummaryNonIndividual_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_IncomeTaxSummaryNonIndividual_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_IndividualMortgage_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_IndividualMortgage_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_IndividualMortgage_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_IndividualsAssociatedBusiness_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_IndividualsAssociatedBusiness_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_IndividualsAssociatedBusiness_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_LandlordIncomeDetail_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_LandlordIncomeDetail_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_LandlordIncomeDetail_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_LandlordIncomeSummary_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_LandlordIncomeSummary_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_LandlordIncomeSummary_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_NonIndividualsAssociatedBusiness_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_NonIndividualsAssociatedBusiness_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_NonIndividualsAssociatedBusiness_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RegAssociates_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RegAssociates_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RegAssociates_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RegIndividual_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RegIndividual_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RegIndividual_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RegIndividual_Sid_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RegIndividual_Sid_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RegIndividual_Sid_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RegNonIndividual_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RegNonIndividual_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RegNonIndividual_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RegNonIndividual_Sid_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RegNonIndividual_Sid_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RegNonIndividual_Sid_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RentExpenseDetails_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RentExpenseDetails_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RentExpenseDetails_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RentalLandLordIncome_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RentalLandLordIncome_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RentalLandLordIncome_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RentalTenantPayments_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RentalTenantPayments_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RentalTenantPayments_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RipoIndRentalIncSummary_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RipoIndRentalIncSummary_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_RipoIndRentalIncSummary_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_TenantIncomeDetail_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_TenantIncomeDetail_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_TenantIncomeDetail_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_Town_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_Town_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnura_Town_Args = {
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsb_Aggregatecm_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsb_Aggregatetxt_Args = {
  aggregate_params?: Maybe<Scalars["String"]>;
  params?: Maybe<Scalars["String"]>;
};

export type Rtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsb_Args = {
  params?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.GazzetteRates" */
export type Rtcs_Db_GazzetteRates = {
  __typename?: "rtcs_db_GazzetteRates";
  Gazzette?: Maybe<Scalars["bigint"]>;
  Location_Room?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.GazzetteRates" */
export type Rtcs_Db_GazzetteRates_Aggregate = {
  __typename?: "rtcs_db_GazzetteRates_aggregate";
  aggregate?: Maybe<Rtcs_Db_GazzetteRates_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_GazzetteRates>;
};

/** aggregate fields of "rtcs_db.GazzetteRates" */
export type Rtcs_Db_GazzetteRates_Aggregate_Fields = {
  __typename?: "rtcs_db_GazzetteRates_aggregate_fields";
  avg?: Maybe<Rtcs_Db_GazzetteRates_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_GazzetteRates_Max_Fields>;
  min?: Maybe<Rtcs_Db_GazzetteRates_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_GazzetteRates_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_GazzetteRates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_GazzetteRates_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_GazzetteRates_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_GazzetteRates_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_GazzetteRates_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_GazzetteRates_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.GazzetteRates" */
export type Rtcs_Db_GazzetteRates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_GazzetteRates_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.GazzetteRates" */
export type Rtcs_Db_GazzetteRates_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_GazzetteRates_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_GazzetteRates_Max_Order_By>;
  min?: Maybe<Rtcs_Db_GazzetteRates_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_GazzetteRates_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_GazzetteRates_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_GazzetteRates_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_GazzetteRates_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_GazzetteRates_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_GazzetteRates_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_GazzetteRates_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.GazzetteRates" */
export type Rtcs_Db_GazzetteRates_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_GazzetteRates_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_GazzetteRates_Avg_Fields = {
  __typename?: "rtcs_db_GazzetteRates_avg_fields";
  Gazzette?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.GazzetteRates" */
export type Rtcs_Db_GazzetteRates_Avg_Order_By = {
  Gazzette?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.GazzetteRates". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_GazzetteRates_Bool_Exp = {
  Gazzette?: Maybe<Bigint_Comparison_Exp>;
  Location_Room?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_GazzetteRates_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_GazzetteRates_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_GazzetteRates_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.GazzetteRates" */
export type Rtcs_Db_GazzetteRates_Inc_Input = {
  Gazzette?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.GazzetteRates" */
export type Rtcs_Db_GazzetteRates_Insert_Input = {
  Gazzette?: Maybe<Scalars["bigint"]>;
  Location_Room?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_GazzetteRates_Max_Fields = {
  __typename?: "rtcs_db_GazzetteRates_max_fields";
  Gazzette?: Maybe<Scalars["bigint"]>;
  Location_Room?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.GazzetteRates" */
export type Rtcs_Db_GazzetteRates_Max_Order_By = {
  Gazzette?: Maybe<Order_By>;
  Location_Room?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_GazzetteRates_Min_Fields = {
  __typename?: "rtcs_db_GazzetteRates_min_fields";
  Gazzette?: Maybe<Scalars["bigint"]>;
  Location_Room?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.GazzetteRates" */
export type Rtcs_Db_GazzetteRates_Min_Order_By = {
  Gazzette?: Maybe<Order_By>;
  Location_Room?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.GazzetteRates" */
export type Rtcs_Db_GazzetteRates_Mutation_Response = {
  __typename?: "rtcs_db_GazzetteRates_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_GazzetteRates>;
};

/** input type for inserting object relation for remote table "rtcs_db.GazzetteRates" */
export type Rtcs_Db_GazzetteRates_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_GazzetteRates_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.GazzetteRates" */
export type Rtcs_Db_GazzetteRates_Order_By = {
  Gazzette?: Maybe<Order_By>;
  Location_Room?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.GazzetteRates" */
export enum Rtcs_Db_GazzetteRates_Select_Column {
  /** column name */
  Gazzette = "Gazzette",
  /** column name */
  LocationRoom = "Location_Room",
}

/** input type for updating data in table "rtcs_db.GazzetteRates" */
export type Rtcs_Db_GazzetteRates_Set_Input = {
  Gazzette?: Maybe<Scalars["bigint"]>;
  Location_Room?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_GazzetteRates_Stddev_Fields = {
  __typename?: "rtcs_db_GazzetteRates_stddev_fields";
  Gazzette?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.GazzetteRates" */
export type Rtcs_Db_GazzetteRates_Stddev_Order_By = {
  Gazzette?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_GazzetteRates_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_GazzetteRates_stddev_pop_fields";
  Gazzette?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.GazzetteRates" */
export type Rtcs_Db_GazzetteRates_Stddev_Pop_Order_By = {
  Gazzette?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_GazzetteRates_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_GazzetteRates_stddev_samp_fields";
  Gazzette?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.GazzetteRates" */
export type Rtcs_Db_GazzetteRates_Stddev_Samp_Order_By = {
  Gazzette?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_GazzetteRates_Sum_Fields = {
  __typename?: "rtcs_db_GazzetteRates_sum_fields";
  Gazzette?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.GazzetteRates" */
export type Rtcs_Db_GazzetteRates_Sum_Order_By = {
  Gazzette?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_GazzetteRates_Var_Pop_Fields = {
  __typename?: "rtcs_db_GazzetteRates_var_pop_fields";
  Gazzette?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.GazzetteRates" */
export type Rtcs_Db_GazzetteRates_Var_Pop_Order_By = {
  Gazzette?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_GazzetteRates_Var_Samp_Fields = {
  __typename?: "rtcs_db_GazzetteRates_var_samp_fields";
  Gazzette?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.GazzetteRates" */
export type Rtcs_Db_GazzetteRates_Var_Samp_Order_By = {
  Gazzette?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_GazzetteRates_Variance_Fields = {
  __typename?: "rtcs_db_GazzetteRates_variance_fields";
  Gazzette?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.GazzetteRates" */
export type Rtcs_Db_GazzetteRates_Variance_Order_By = {
  Gazzette?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_EntityFilter" */
export type Rtcs_Db_Ph_EntityFilter = {
  __typename?: "rtcs_db_PH_EntityFilter";
  EntityId?: Maybe<Scalars["String"]>;
  EntityIdType?: Maybe<Scalars["String"]>;
  FilterName?: Maybe<Scalars["String"]>;
  FilterValue?: Maybe<Scalars["bigint"]>;
};

/** aggregated selection of "rtcs_db.PH_EntityFilter" */
export type Rtcs_Db_Ph_EntityFilter_Aggregate = {
  __typename?: "rtcs_db_PH_EntityFilter_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_EntityFilter_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_EntityFilter>;
};

/** aggregate fields of "rtcs_db.PH_EntityFilter" */
export type Rtcs_Db_Ph_EntityFilter_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_EntityFilter_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_EntityFilter_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_EntityFilter_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_EntityFilter_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_EntityFilter_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_EntityFilter_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_EntityFilter_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_EntityFilter_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_EntityFilter_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_EntityFilter_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_EntityFilter_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_EntityFilter" */
export type Rtcs_Db_Ph_EntityFilter_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_EntityFilter_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_EntityFilter" */
export type Rtcs_Db_Ph_EntityFilter_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_EntityFilter_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_EntityFilter_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_EntityFilter_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_EntityFilter_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_EntityFilter_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_EntityFilter_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_EntityFilter_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_EntityFilter_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_EntityFilter_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_EntityFilter_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_EntityFilter" */
export type Rtcs_Db_Ph_EntityFilter_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_EntityFilter_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_EntityFilter_Avg_Fields = {
  __typename?: "rtcs_db_PH_EntityFilter_avg_fields";
  FilterValue?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_EntityFilter" */
export type Rtcs_Db_Ph_EntityFilter_Avg_Order_By = {
  FilterValue?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_EntityFilter". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_EntityFilter_Bool_Exp = {
  EntityId?: Maybe<String_Comparison_Exp>;
  EntityIdType?: Maybe<String_Comparison_Exp>;
  FilterName?: Maybe<String_Comparison_Exp>;
  FilterValue?: Maybe<Bigint_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_EntityFilter_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_EntityFilter_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_EntityFilter_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_EntityFilter" */
export type Rtcs_Db_Ph_EntityFilter_Inc_Input = {
  FilterValue?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_EntityFilter" */
export type Rtcs_Db_Ph_EntityFilter_Insert_Input = {
  EntityId?: Maybe<Scalars["String"]>;
  EntityIdType?: Maybe<Scalars["String"]>;
  FilterName?: Maybe<Scalars["String"]>;
  FilterValue?: Maybe<Scalars["bigint"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_EntityFilter_Max_Fields = {
  __typename?: "rtcs_db_PH_EntityFilter_max_fields";
  EntityId?: Maybe<Scalars["String"]>;
  EntityIdType?: Maybe<Scalars["String"]>;
  FilterName?: Maybe<Scalars["String"]>;
  FilterValue?: Maybe<Scalars["bigint"]>;
};

/** order by max() on columns of table "rtcs_db.PH_EntityFilter" */
export type Rtcs_Db_Ph_EntityFilter_Max_Order_By = {
  EntityId?: Maybe<Order_By>;
  EntityIdType?: Maybe<Order_By>;
  FilterName?: Maybe<Order_By>;
  FilterValue?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_EntityFilter_Min_Fields = {
  __typename?: "rtcs_db_PH_EntityFilter_min_fields";
  EntityId?: Maybe<Scalars["String"]>;
  EntityIdType?: Maybe<Scalars["String"]>;
  FilterName?: Maybe<Scalars["String"]>;
  FilterValue?: Maybe<Scalars["bigint"]>;
};

/** order by min() on columns of table "rtcs_db.PH_EntityFilter" */
export type Rtcs_Db_Ph_EntityFilter_Min_Order_By = {
  EntityId?: Maybe<Order_By>;
  EntityIdType?: Maybe<Order_By>;
  FilterName?: Maybe<Order_By>;
  FilterValue?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_EntityFilter" */
export type Rtcs_Db_Ph_EntityFilter_Mutation_Response = {
  __typename?: "rtcs_db_PH_EntityFilter_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_EntityFilter>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_EntityFilter" */
export type Rtcs_Db_Ph_EntityFilter_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_EntityFilter_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_EntityFilter" */
export type Rtcs_Db_Ph_EntityFilter_Order_By = {
  EntityId?: Maybe<Order_By>;
  EntityIdType?: Maybe<Order_By>;
  FilterName?: Maybe<Order_By>;
  FilterValue?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_EntityFilter" */
export enum Rtcs_Db_Ph_EntityFilter_Select_Column {
  /** column name */
  EntityId = "EntityId",
  /** column name */
  EntityIdType = "EntityIdType",
  /** column name */
  FilterName = "FilterName",
  /** column name */
  FilterValue = "FilterValue",
}

/** input type for updating data in table "rtcs_db.PH_EntityFilter" */
export type Rtcs_Db_Ph_EntityFilter_Set_Input = {
  EntityId?: Maybe<Scalars["String"]>;
  EntityIdType?: Maybe<Scalars["String"]>;
  FilterName?: Maybe<Scalars["String"]>;
  FilterValue?: Maybe<Scalars["bigint"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_EntityFilter_Stddev_Fields = {
  __typename?: "rtcs_db_PH_EntityFilter_stddev_fields";
  FilterValue?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_EntityFilter" */
export type Rtcs_Db_Ph_EntityFilter_Stddev_Order_By = {
  FilterValue?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_EntityFilter_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_EntityFilter_stddev_pop_fields";
  FilterValue?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_EntityFilter" */
export type Rtcs_Db_Ph_EntityFilter_Stddev_Pop_Order_By = {
  FilterValue?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_EntityFilter_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_EntityFilter_stddev_samp_fields";
  FilterValue?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_EntityFilter" */
export type Rtcs_Db_Ph_EntityFilter_Stddev_Samp_Order_By = {
  FilterValue?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_EntityFilter_Sum_Fields = {
  __typename?: "rtcs_db_PH_EntityFilter_sum_fields";
  FilterValue?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_EntityFilter" */
export type Rtcs_Db_Ph_EntityFilter_Sum_Order_By = {
  FilterValue?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_EntityFilter_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_EntityFilter_var_pop_fields";
  FilterValue?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_EntityFilter" */
export type Rtcs_Db_Ph_EntityFilter_Var_Pop_Order_By = {
  FilterValue?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_EntityFilter_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_EntityFilter_var_samp_fields";
  FilterValue?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_EntityFilter" */
export type Rtcs_Db_Ph_EntityFilter_Var_Samp_Order_By = {
  FilterValue?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_EntityFilter_Variance_Fields = {
  __typename?: "rtcs_db_PH_EntityFilter_variance_fields";
  FilterValue?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_EntityFilter" */
export type Rtcs_Db_Ph_EntityFilter_Variance_Order_By = {
  FilterValue?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_Filter" */
export type Rtcs_Db_Ph_Filter = {
  __typename?: "rtcs_db_PH_Filter";
  SqlClause?: Maybe<Scalars["String"]>;
  TableName?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_Filter" */
export type Rtcs_Db_Ph_Filter_Aggregate = {
  __typename?: "rtcs_db_PH_Filter_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Filter_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Filter>;
};

/** aggregate fields of "rtcs_db.PH_Filter" */
export type Rtcs_Db_Ph_Filter_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_Filter_aggregate_fields";
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Filter_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Filter_Min_Fields>;
};

/** aggregate fields of "rtcs_db.PH_Filter" */
export type Rtcs_Db_Ph_Filter_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Filter_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_Filter" */
export type Rtcs_Db_Ph_Filter_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Filter_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Filter_Min_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_Filter" */
export type Rtcs_Db_Ph_Filter_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Filter_Insert_Input>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_Filter". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Filter_Bool_Exp = {
  SqlClause?: Maybe<String_Comparison_Exp>;
  TableName?: Maybe<String_Comparison_Exp>;
  Type?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Filter_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Filter_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Filter_Bool_Exp>>>;
};

/** input type for inserting data into table "rtcs_db.PH_Filter" */
export type Rtcs_Db_Ph_Filter_Insert_Input = {
  SqlClause?: Maybe<Scalars["String"]>;
  TableName?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Filter_Max_Fields = {
  __typename?: "rtcs_db_PH_Filter_max_fields";
  SqlClause?: Maybe<Scalars["String"]>;
  TableName?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_Filter" */
export type Rtcs_Db_Ph_Filter_Max_Order_By = {
  SqlClause?: Maybe<Order_By>;
  TableName?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Filter_Min_Fields = {
  __typename?: "rtcs_db_PH_Filter_min_fields";
  SqlClause?: Maybe<Scalars["String"]>;
  TableName?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_Filter" */
export type Rtcs_Db_Ph_Filter_Min_Order_By = {
  SqlClause?: Maybe<Order_By>;
  TableName?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_Filter" */
export type Rtcs_Db_Ph_Filter_Mutation_Response = {
  __typename?: "rtcs_db_PH_Filter_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Filter>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_Filter" */
export type Rtcs_Db_Ph_Filter_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Filter_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_Filter" */
export type Rtcs_Db_Ph_Filter_Order_By = {
  SqlClause?: Maybe<Order_By>;
  TableName?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_Filter" */
export enum Rtcs_Db_Ph_Filter_Select_Column {
  /** column name */
  SqlClause = "SqlClause",
  /** column name */
  TableName = "TableName",
  /** column name */
  Type = "Type",
}

/** input type for updating data in table "rtcs_db.PH_Filter" */
export type Rtcs_Db_Ph_Filter_Set_Input = {
  SqlClause?: Maybe<Scalars["String"]>;
  TableName?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.PH_GazzetteRates" */
export type Rtcs_Db_Ph_GazzetteRates = {
  __typename?: "rtcs_db_PH_GazzetteRates";
  Gazzette?: Maybe<Scalars["bigint"]>;
  Location_Room?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_GazzetteRates" */
export type Rtcs_Db_Ph_GazzetteRates_Aggregate = {
  __typename?: "rtcs_db_PH_GazzetteRates_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_GazzetteRates_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_GazzetteRates>;
};

/** aggregate fields of "rtcs_db.PH_GazzetteRates" */
export type Rtcs_Db_Ph_GazzetteRates_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_GazzetteRates_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_GazzetteRates_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_GazzetteRates_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_GazzetteRates_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_GazzetteRates_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_GazzetteRates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_GazzetteRates_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_GazzetteRates_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_GazzetteRates_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_GazzetteRates_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_GazzetteRates_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_GazzetteRates" */
export type Rtcs_Db_Ph_GazzetteRates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_GazzetteRates_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_GazzetteRates" */
export type Rtcs_Db_Ph_GazzetteRates_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_GazzetteRates_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_GazzetteRates_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_GazzetteRates_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_GazzetteRates_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_GazzetteRates_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_GazzetteRates_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_GazzetteRates_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_GazzetteRates_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_GazzetteRates_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_GazzetteRates_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_GazzetteRates" */
export type Rtcs_Db_Ph_GazzetteRates_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_GazzetteRates_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_GazzetteRates_Avg_Fields = {
  __typename?: "rtcs_db_PH_GazzetteRates_avg_fields";
  Gazzette?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_GazzetteRates" */
export type Rtcs_Db_Ph_GazzetteRates_Avg_Order_By = {
  Gazzette?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_GazzetteRates". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_GazzetteRates_Bool_Exp = {
  Gazzette?: Maybe<Bigint_Comparison_Exp>;
  Location_Room?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_GazzetteRates_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_GazzetteRates_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_GazzetteRates_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_GazzetteRates" */
export type Rtcs_Db_Ph_GazzetteRates_Inc_Input = {
  Gazzette?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_GazzetteRates" */
export type Rtcs_Db_Ph_GazzetteRates_Insert_Input = {
  Gazzette?: Maybe<Scalars["bigint"]>;
  Location_Room?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_GazzetteRates_Max_Fields = {
  __typename?: "rtcs_db_PH_GazzetteRates_max_fields";
  Gazzette?: Maybe<Scalars["bigint"]>;
  Location_Room?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_GazzetteRates" */
export type Rtcs_Db_Ph_GazzetteRates_Max_Order_By = {
  Gazzette?: Maybe<Order_By>;
  Location_Room?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_GazzetteRates_Min_Fields = {
  __typename?: "rtcs_db_PH_GazzetteRates_min_fields";
  Gazzette?: Maybe<Scalars["bigint"]>;
  Location_Room?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_GazzetteRates" */
export type Rtcs_Db_Ph_GazzetteRates_Min_Order_By = {
  Gazzette?: Maybe<Order_By>;
  Location_Room?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_GazzetteRates" */
export type Rtcs_Db_Ph_GazzetteRates_Mutation_Response = {
  __typename?: "rtcs_db_PH_GazzetteRates_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_GazzetteRates>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_GazzetteRates" */
export type Rtcs_Db_Ph_GazzetteRates_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_GazzetteRates_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_GazzetteRates" */
export type Rtcs_Db_Ph_GazzetteRates_Order_By = {
  Gazzette?: Maybe<Order_By>;
  Location_Room?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_GazzetteRates" */
export enum Rtcs_Db_Ph_GazzetteRates_Select_Column {
  /** column name */
  Gazzette = "Gazzette",
  /** column name */
  LocationRoom = "Location_Room",
}

/** input type for updating data in table "rtcs_db.PH_GazzetteRates" */
export type Rtcs_Db_Ph_GazzetteRates_Set_Input = {
  Gazzette?: Maybe<Scalars["bigint"]>;
  Location_Room?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_GazzetteRates_Stddev_Fields = {
  __typename?: "rtcs_db_PH_GazzetteRates_stddev_fields";
  Gazzette?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_GazzetteRates" */
export type Rtcs_Db_Ph_GazzetteRates_Stddev_Order_By = {
  Gazzette?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_GazzetteRates_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_GazzetteRates_stddev_pop_fields";
  Gazzette?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_GazzetteRates" */
export type Rtcs_Db_Ph_GazzetteRates_Stddev_Pop_Order_By = {
  Gazzette?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_GazzetteRates_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_GazzetteRates_stddev_samp_fields";
  Gazzette?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_GazzetteRates" */
export type Rtcs_Db_Ph_GazzetteRates_Stddev_Samp_Order_By = {
  Gazzette?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_GazzetteRates_Sum_Fields = {
  __typename?: "rtcs_db_PH_GazzetteRates_sum_fields";
  Gazzette?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_GazzetteRates" */
export type Rtcs_Db_Ph_GazzetteRates_Sum_Order_By = {
  Gazzette?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_GazzetteRates_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_GazzetteRates_var_pop_fields";
  Gazzette?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_GazzetteRates" */
export type Rtcs_Db_Ph_GazzetteRates_Var_Pop_Order_By = {
  Gazzette?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_GazzetteRates_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_GazzetteRates_var_samp_fields";
  Gazzette?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_GazzetteRates" */
export type Rtcs_Db_Ph_GazzetteRates_Var_Samp_Order_By = {
  Gazzette?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_GazzetteRates_Variance_Fields = {
  __typename?: "rtcs_db_PH_GazzetteRates_variance_fields";
  Gazzette?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_GazzetteRates" */
export type Rtcs_Db_Ph_GazzetteRates_Variance_Order_By = {
  Gazzette?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk = {
  __typename?: "rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["float8"]>;
  Kccamobile2?: Maybe<Scalars["float8"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["float8"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  RiskCode?: Maybe<Scalars["String"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** aggregated selection of "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Aggregate = {
  __typename?: "rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk>;
};

/** aggregate fields of "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Aggregate_Fields =
  {
    __typename?: "rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk_aggregate_fields";
    avg?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Avg_Fields>;
    count?: Maybe<Scalars["Int"]>;
    max?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Max_Fields>;
    min?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Min_Fields>;
    stddev?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Stddev_Fields>;
    stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Stddev_Samp_Fields>;
    sum?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Sum_Fields>;
    var_pop?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Var_Pop_Fields>;
    var_samp?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Var_Samp_Fields>;
    variance?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Variance_Fields>;
  };

/** aggregate fields of "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Aggregate_Order_By =
  {
    avg?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Max_Order_By>;
    min?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Min_Order_By>;
    stddev?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Stddev_Order_By>;
    stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Stddev_Samp_Order_By>;
    sum?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Sum_Order_By>;
    var_pop?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Var_Pop_Order_By>;
    var_samp?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Var_Samp_Order_By>;
    variance?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Variance_Order_By>;
  };

/** input type for inserting array relation for remote table "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Avg_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk_avg_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Countura?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Kccamobile1?: Maybe<Scalars["Float"]>;
  Kccamobile2?: Maybe<Scalars["Float"]>;
  Kccatelephone?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Mobilenumber?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  RiskScore?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Avg_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Countura?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  RiskScore?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Bool_Exp = {
  Averagerateperrentedproperty?: Maybe<Float8_Comparison_Exp>;
  Compliancecategory?: Maybe<String_Comparison_Exp>;
  Countura?: Maybe<Float8_Comparison_Exp>;
  Customerid?: Maybe<String_Comparison_Exp>;
  Difference?: Maybe<Float8_Comparison_Exp>;
  Emailid?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Fullname?: Maybe<String_Comparison_Exp>;
  Grossrentalincome?: Maybe<Float8_Comparison_Exp>;
  Isamendment?: Maybe<String_Comparison_Exp>;
  Isincomesrcbsns?: Maybe<String_Comparison_Exp>;
  Isincomesrcemp?: Maybe<String_Comparison_Exp>;
  Isincomesrcprpty?: Maybe<String_Comparison_Exp>;
  Isincomesrcrental?: Maybe<String_Comparison_Exp>;
  Kccabirthdate?: Maybe<String_Comparison_Exp>;
  Kccaemail?: Maybe<String_Comparison_Exp>;
  Kccafirstname?: Maybe<String_Comparison_Exp>;
  Kccamiddlename?: Maybe<String_Comparison_Exp>;
  Kccamobile1?: Maybe<Float8_Comparison_Exp>;
  Kccamobile2?: Maybe<Float8_Comparison_Exp>;
  Kccasurname?: Maybe<String_Comparison_Exp>;
  Kccatelephone?: Maybe<Float8_Comparison_Exp>;
  Kccatin?: Maybe<String_Comparison_Exp>;
  Matchcategory?: Maybe<String_Comparison_Exp>;
  Matchscore?: Maybe<Float8_Comparison_Exp>;
  Matchscoreattributes?: Maybe<String_Comparison_Exp>;
  Mobilenumber?: Maybe<Float8_Comparison_Exp>;
  Nationalid?: Maybe<String_Comparison_Exp>;
  Numproperties?: Maybe<Bigint_Comparison_Exp>;
  Numrentedproperties?: Maybe<Bigint_Comparison_Exp>;
  Percentagedifference?: Maybe<Float8_Comparison_Exp>;
  Propincincome?: Maybe<Float8_Comparison_Exp>;
  RiskCode?: Maybe<String_Comparison_Exp>;
  RiskScore?: Maybe<Bigint_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Rtnno?: Maybe<String_Comparison_Exp>;
  Rtnperiodyear?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  Sumadjustedincome?: Maybe<Float8_Comparison_Exp>;
  Sumgrossvalue?: Maybe<Float8_Comparison_Exp>;
  Sumnonrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Sumrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Urafirstname?: Maybe<String_Comparison_Exp>;
  Uramiddlename?: Maybe<String_Comparison_Exp>;
  Urasurname?: Maybe<String_Comparison_Exp>;
  Uratinno?: Maybe<Float8_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Inc_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Countura?: Maybe<Scalars["float8"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Kccamobile1?: Maybe<Scalars["float8"]>;
  Kccamobile2?: Maybe<Scalars["float8"]>;
  Kccatelephone?: Maybe<Scalars["float8"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Insert_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["float8"]>;
  Kccamobile2?: Maybe<Scalars["float8"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["float8"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  RiskCode?: Maybe<Scalars["String"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Max_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk_max_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["float8"]>;
  Kccamobile2?: Maybe<Scalars["float8"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["float8"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  RiskCode?: Maybe<Scalars["String"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** order by max() on columns of table "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Max_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Countura?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccabirthdate?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  Matchcategory?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  RiskCode?: Maybe<Order_By>;
  RiskScore?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramiddlename?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Min_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk_min_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["float8"]>;
  Kccamobile2?: Maybe<Scalars["float8"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["float8"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  RiskCode?: Maybe<Scalars["String"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** order by min() on columns of table "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Min_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Countura?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccabirthdate?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  Matchcategory?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  RiskCode?: Maybe<Order_By>;
  RiskScore?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramiddlename?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Mutation_Response =
  {
    __typename?: "rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk_mutation_response";
    /** number of affected rows by the mutation */
    affected_rows: Scalars["Int"];
    /** data of the affected rows by the mutation */
    returning: Array<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk>;
  };

/** input type for inserting object relation for remote table "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Countura?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccabirthdate?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  Matchcategory?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  RiskCode?: Maybe<Order_By>;
  RiskScore?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramiddlename?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
export enum Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column {
  /** column name */
  Averagerateperrentedproperty = "Averagerateperrentedproperty",
  /** column name */
  Compliancecategory = "Compliancecategory",
  /** column name */
  Countura = "Countura",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  Difference = "Difference",
  /** column name */
  Emailid = "Emailid",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Fullname = "Fullname",
  /** column name */
  Grossrentalincome = "Grossrentalincome",
  /** column name */
  Isamendment = "Isamendment",
  /** column name */
  Isincomesrcbsns = "Isincomesrcbsns",
  /** column name */
  Isincomesrcemp = "Isincomesrcemp",
  /** column name */
  Isincomesrcprpty = "Isincomesrcprpty",
  /** column name */
  Isincomesrcrental = "Isincomesrcrental",
  /** column name */
  Kccabirthdate = "Kccabirthdate",
  /** column name */
  Kccaemail = "Kccaemail",
  /** column name */
  Kccafirstname = "Kccafirstname",
  /** column name */
  Kccamiddlename = "Kccamiddlename",
  /** column name */
  Kccamobile1 = "Kccamobile1",
  /** column name */
  Kccamobile2 = "Kccamobile2",
  /** column name */
  Kccasurname = "Kccasurname",
  /** column name */
  Kccatelephone = "Kccatelephone",
  /** column name */
  Kccatin = "Kccatin",
  /** column name */
  Matchcategory = "Matchcategory",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Matchscoreattributes = "Matchscoreattributes",
  /** column name */
  Mobilenumber = "Mobilenumber",
  /** column name */
  Nationalid = "Nationalid",
  /** column name */
  Numproperties = "Numproperties",
  /** column name */
  Numrentedproperties = "Numrentedproperties",
  /** column name */
  Percentagedifference = "Percentagedifference",
  /** column name */
  Propincincome = "Propincincome",
  /** column name */
  RiskCode = "RiskCode",
  /** column name */
  RiskScore = "RiskScore",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Rtnno = "Rtnno",
  /** column name */
  Rtnperiodyear = "Rtnperiodyear",
  /** column name */
  Source = "Source",
  /** column name */
  Sumadjustedincome = "Sumadjustedincome",
  /** column name */
  Sumgrossvalue = "Sumgrossvalue",
  /** column name */
  Sumnonrateablevalue = "Sumnonrateablevalue",
  /** column name */
  Sumrateablevalue = "Sumrateablevalue",
  /** column name */
  Surname = "Surname",
  /** column name */
  Urafirstname = "Urafirstname",
  /** column name */
  Uramiddlename = "Uramiddlename",
  /** column name */
  Urasurname = "Urasurname",
  /** column name */
  Uratinno = "Uratinno",
}

/** input type for updating data in table "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Set_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["float8"]>;
  Kccamobile2?: Maybe<Scalars["float8"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["float8"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  RiskCode?: Maybe<Scalars["String"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Stddev_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk_stddev_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Countura?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Kccamobile1?: Maybe<Scalars["Float"]>;
  Kccamobile2?: Maybe<Scalars["Float"]>;
  Kccatelephone?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Mobilenumber?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  RiskScore?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Stddev_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Countura?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Kccamobile1?: Maybe<Order_By>;
    Kccamobile2?: Maybe<Order_By>;
    Kccatelephone?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Propincincome?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Sumadjustedincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumnonrateablevalue?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Stddev_Pop_Fields =
  {
    __typename?: "rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk_stddev_pop_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Countura?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Kccamobile1?: Maybe<Scalars["Float"]>;
    Kccamobile2?: Maybe<Scalars["Float"]>;
    Kccatelephone?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Mobilenumber?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Propincincome?: Maybe<Scalars["Float"]>;
    RiskScore?: Maybe<Scalars["Float"]>;
    Sumadjustedincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
    Uratinno?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_pop() on columns of table "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Stddev_Pop_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Countura?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Kccamobile1?: Maybe<Order_By>;
    Kccamobile2?: Maybe<Order_By>;
    Kccatelephone?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Propincincome?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Sumadjustedincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumnonrateablevalue?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Stddev_Samp_Fields =
  {
    __typename?: "rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk_stddev_samp_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Countura?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Kccamobile1?: Maybe<Scalars["Float"]>;
    Kccamobile2?: Maybe<Scalars["Float"]>;
    Kccatelephone?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Mobilenumber?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Propincincome?: Maybe<Scalars["Float"]>;
    RiskScore?: Maybe<Scalars["Float"]>;
    Sumadjustedincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
    Uratinno?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_samp() on columns of table "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Stddev_Samp_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Countura?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Kccamobile1?: Maybe<Order_By>;
    Kccamobile2?: Maybe<Order_By>;
    Kccatelephone?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Propincincome?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Sumadjustedincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumnonrateablevalue?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Sum_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk_sum_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Countura?: Maybe<Scalars["float8"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Kccamobile1?: Maybe<Scalars["float8"]>;
  Kccamobile2?: Maybe<Scalars["float8"]>;
  Kccatelephone?: Maybe<Scalars["float8"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Sum_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Countura?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  RiskScore?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Var_Pop_Fields =
  {
    __typename?: "rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk_var_pop_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Countura?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Kccamobile1?: Maybe<Scalars["Float"]>;
    Kccamobile2?: Maybe<Scalars["Float"]>;
    Kccatelephone?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Mobilenumber?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Propincincome?: Maybe<Scalars["Float"]>;
    RiskScore?: Maybe<Scalars["Float"]>;
    Sumadjustedincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
    Uratinno?: Maybe<Scalars["Float"]>;
  };

/** order by var_pop() on columns of table "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Var_Pop_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Countura?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Kccamobile1?: Maybe<Order_By>;
    Kccamobile2?: Maybe<Order_By>;
    Kccatelephone?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Propincincome?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Sumadjustedincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumnonrateablevalue?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Var_Samp_Fields =
  {
    __typename?: "rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk_var_samp_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Countura?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Kccamobile1?: Maybe<Scalars["Float"]>;
    Kccamobile2?: Maybe<Scalars["Float"]>;
    Kccatelephone?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Mobilenumber?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Propincincome?: Maybe<Scalars["Float"]>;
    RiskScore?: Maybe<Scalars["Float"]>;
    Sumadjustedincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
    Uratinno?: Maybe<Scalars["Float"]>;
  };

/** order by var_samp() on columns of table "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Var_Samp_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Countura?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Kccamobile1?: Maybe<Order_By>;
    Kccamobile2?: Maybe<Order_By>;
    Kccatelephone?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Propincincome?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Sumadjustedincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumnonrateablevalue?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Variance_Fields =
  {
    __typename?: "rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk_variance_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Countura?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Kccamobile1?: Maybe<Scalars["Float"]>;
    Kccamobile2?: Maybe<Scalars["Float"]>;
    Kccatelephone?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Mobilenumber?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Propincincome?: Maybe<Scalars["Float"]>;
    RiskScore?: Maybe<Scalars["Float"]>;
    Sumadjustedincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
    Uratinno?: Maybe<Scalars["Float"]>;
  };

/** order by variance() on columns of table "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Variance_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Countura?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Kccamobile1?: Maybe<Order_By>;
    Kccamobile2?: Maybe<Order_By>;
    Kccatelephone?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Propincincome?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Sumadjustedincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumnonrateablevalue?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** columns and relationships of "rtcs_db.PH_RPT_IndividualComposite" */
export type Rtcs_Db_Ph_Rpt_IndividualComposite = {
  __typename?: "rtcs_db_PH_RPT_IndividualComposite";
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["timestamptz"]>;
  BirthState?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["float8"]>;
  File_x?: Maybe<Scalars["String"]>;
  File_y?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated_x?: Maybe<Scalars["String"]>;
  LastUpdated_y?: Maybe<Scalars["String"]>;
  Line_x?: Maybe<Scalars["float8"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchScoreDetails?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["float8"]>;
  TownName?: Maybe<Scalars["String"]>;
  UraFamilyName?: Maybe<Scalars["String"]>;
  UraFirstName?: Maybe<Scalars["String"]>;
  UraMiddleName?: Maybe<Scalars["String"]>;
  UraOtherName?: Maybe<Scalars["String"]>;
  UraSurName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated_x?: Maybe<Scalars["String"]>;
  WhoUpdated_y?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_RPT_IndividualComposite" */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Aggregate = {
  __typename?: "rtcs_db_PH_RPT_IndividualComposite_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rpt_IndividualComposite>;
};

/** aggregate fields of "rtcs_db.PH_RPT_IndividualComposite" */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualComposite_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_RPT_IndividualComposite" */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualComposite_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_RPT_IndividualComposite" */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_RPT_IndividualComposite" */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rpt_IndividualComposite_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Avg_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualComposite_avg_fields";
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  BirthCity?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Fax?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_RPT_IndividualComposite" */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Avg_Order_By = {
  AdditionalProperties?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_RPT_IndividualComposite". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Bool_Exp = {
  AdditionalProperties?: Maybe<Float8_Comparison_Exp>;
  AppliedForTin?: Maybe<String_Comparison_Exp>;
  AuthctcdEmailId?: Maybe<String_Comparison_Exp>;
  AuthctcdFamilyName?: Maybe<String_Comparison_Exp>;
  AuthctcdFirstName?: Maybe<String_Comparison_Exp>;
  AuthctcdLandlineNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdMobileNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdOtherName?: Maybe<String_Comparison_Exp>;
  AuthctcdPrsnDesgn?: Maybe<String_Comparison_Exp>;
  AuthctcdSurname?: Maybe<String_Comparison_Exp>;
  AuthctcdTin?: Maybe<String_Comparison_Exp>;
  BirthCity?: Maybe<Bigint_Comparison_Exp>;
  BirthCountry?: Maybe<String_Comparison_Exp>;
  BirthDt?: Maybe<Timestamptz_Comparison_Exp>;
  BirthState?: Maybe<String_Comparison_Exp>;
  Brn?: Maybe<String_Comparison_Exp>;
  BsnsCertNumber?: Maybe<String_Comparison_Exp>;
  BsnsName?: Maybe<String_Comparison_Exp>;
  BuildingName?: Maybe<String_Comparison_Exp>;
  BusinessName?: Maybe<String_Comparison_Exp>;
  CitizenCountry?: Maybe<String_Comparison_Exp>;
  Coin?: Maybe<Float8_Comparison_Exp>;
  CountUra?: Maybe<Float8_Comparison_Exp>;
  CreatedBy?: Maybe<Float8_Comparison_Exp>;
  CreatedDate?: Maybe<String_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  DistrictId?: Maybe<Float8_Comparison_Exp>;
  EmailId?: Maybe<String_Comparison_Exp>;
  EntityLegalName?: Maybe<String_Comparison_Exp>;
  ExistTinNo?: Maybe<String_Comparison_Exp>;
  Fax?: Maybe<Float8_Comparison_Exp>;
  File_x?: Maybe<String_Comparison_Exp>;
  File_y?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Fullname?: Maybe<String_Comparison_Exp>;
  Gender?: Maybe<String_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  HomeAddrBuildingname?: Maybe<String_Comparison_Exp>;
  HomeAddrDistrictName?: Maybe<String_Comparison_Exp>;
  HomeAddrLocalCouncil?: Maybe<String_Comparison_Exp>;
  HomeAddrParishName?: Maybe<String_Comparison_Exp>;
  HomeAddrPlotno?: Maybe<String_Comparison_Exp>;
  HomeAddrStrretAddr?: Maybe<String_Comparison_Exp>;
  HomeAddrSubCountyName?: Maybe<String_Comparison_Exp>;
  HomeAddrTownName?: Maybe<String_Comparison_Exp>;
  HomeAddrTradeCentre?: Maybe<String_Comparison_Exp>;
  HomeAddrVillageName?: Maybe<String_Comparison_Exp>;
  IdentificationRefugeeId?: Maybe<String_Comparison_Exp>;
  IdentificationWorkPermit?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcEmp?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  LandlineNumber?: Maybe<String_Comparison_Exp>;
  LastUpdated_x?: Maybe<String_Comparison_Exp>;
  LastUpdated_y?: Maybe<String_Comparison_Exp>;
  Line_x?: Maybe<Float8_Comparison_Exp>;
  Line_y?: Maybe<Float8_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Bigint_Comparison_Exp>;
  MatchScoreAttributes?: Maybe<String_Comparison_Exp>;
  MatchScoreDetails?: Maybe<String_Comparison_Exp>;
  MatchedNiraNin?: Maybe<String_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  MobileNumber?: Maybe<String_Comparison_Exp>;
  NationalId?: Maybe<String_Comparison_Exp>;
  NiraNin?: Maybe<String_Comparison_Exp>;
  NssfId?: Maybe<String_Comparison_Exp>;
  NumProperties?: Maybe<Float8_Comparison_Exp>;
  Photo?: Maybe<Float8_Comparison_Exp>;
  PhotoContent?: Maybe<Float8_Comparison_Exp>;
  PhotoMime?: Maybe<Float8_Comparison_Exp>;
  PhotoName?: Maybe<Float8_Comparison_Exp>;
  PhotoSize?: Maybe<Float8_Comparison_Exp>;
  PostaddrDistictName?: Maybe<String_Comparison_Exp>;
  PostaddrId?: Maybe<String_Comparison_Exp>;
  RefEmailId?: Maybe<String_Comparison_Exp>;
  RefFamilyName?: Maybe<String_Comparison_Exp>;
  RefFirstName?: Maybe<String_Comparison_Exp>;
  RefLandlineNumber?: Maybe<String_Comparison_Exp>;
  RefMobileNumber?: Maybe<String_Comparison_Exp>;
  RefOtherName?: Maybe<String_Comparison_Exp>;
  RefPrsnDesgn?: Maybe<String_Comparison_Exp>;
  RefSurname?: Maybe<String_Comparison_Exp>;
  RefTin?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Float8_Comparison_Exp>;
  SumGrossRentValueAdjusted?: Maybe<Float8_Comparison_Exp>;
  SumNonRateableValue?: Maybe<Float8_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  TaxPayerId?: Maybe<String_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TaxPayerType?: Maybe<String_Comparison_Exp>;
  TownCd?: Maybe<String_Comparison_Exp>;
  TownId?: Maybe<Float8_Comparison_Exp>;
  TownName?: Maybe<String_Comparison_Exp>;
  UraFamilyName?: Maybe<String_Comparison_Exp>;
  UraFirstName?: Maybe<String_Comparison_Exp>;
  UraMiddleName?: Maybe<String_Comparison_Exp>;
  UraOtherName?: Maybe<String_Comparison_Exp>;
  UraSurName?: Maybe<String_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  Uuid?: Maybe<String_Comparison_Exp>;
  WhoUpdated_x?: Maybe<String_Comparison_Exp>;
  WhoUpdated_y?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_RPT_IndividualComposite" */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Inc_Input = {
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  Fax?: Maybe<Scalars["float8"]>;
  Line_x?: Maybe<Scalars["float8"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  TownId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_RPT_IndividualComposite" */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Insert_Input = {
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["timestamptz"]>;
  BirthState?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["float8"]>;
  File_x?: Maybe<Scalars["String"]>;
  File_y?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated_x?: Maybe<Scalars["String"]>;
  LastUpdated_y?: Maybe<Scalars["String"]>;
  Line_x?: Maybe<Scalars["float8"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchScoreDetails?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["float8"]>;
  TownName?: Maybe<Scalars["String"]>;
  UraFamilyName?: Maybe<Scalars["String"]>;
  UraFirstName?: Maybe<Scalars["String"]>;
  UraMiddleName?: Maybe<Scalars["String"]>;
  UraOtherName?: Maybe<Scalars["String"]>;
  UraSurName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated_x?: Maybe<Scalars["String"]>;
  WhoUpdated_y?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Max_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualComposite_max_fields";
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["timestamptz"]>;
  BirthState?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["float8"]>;
  File_x?: Maybe<Scalars["String"]>;
  File_y?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated_x?: Maybe<Scalars["String"]>;
  LastUpdated_y?: Maybe<Scalars["String"]>;
  Line_x?: Maybe<Scalars["float8"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchScoreDetails?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["float8"]>;
  TownName?: Maybe<Scalars["String"]>;
  UraFamilyName?: Maybe<Scalars["String"]>;
  UraFirstName?: Maybe<Scalars["String"]>;
  UraMiddleName?: Maybe<Scalars["String"]>;
  UraOtherName?: Maybe<Scalars["String"]>;
  UraSurName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated_x?: Maybe<Scalars["String"]>;
  WhoUpdated_y?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_RPT_IndividualComposite" */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Max_Order_By = {
  AdditionalProperties?: Maybe<Order_By>;
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  Brn?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  File_x?: Maybe<Order_By>;
  File_y?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated_x?: Maybe<Order_By>;
  LastUpdated_y?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MatchScoreDetails?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TownCd?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
  TownName?: Maybe<Order_By>;
  UraFamilyName?: Maybe<Order_By>;
  UraFirstName?: Maybe<Order_By>;
  UraMiddleName?: Maybe<Order_By>;
  UraOtherName?: Maybe<Order_By>;
  UraSurName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated_x?: Maybe<Order_By>;
  WhoUpdated_y?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Min_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualComposite_min_fields";
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["timestamptz"]>;
  BirthState?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["float8"]>;
  File_x?: Maybe<Scalars["String"]>;
  File_y?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated_x?: Maybe<Scalars["String"]>;
  LastUpdated_y?: Maybe<Scalars["String"]>;
  Line_x?: Maybe<Scalars["float8"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchScoreDetails?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["float8"]>;
  TownName?: Maybe<Scalars["String"]>;
  UraFamilyName?: Maybe<Scalars["String"]>;
  UraFirstName?: Maybe<Scalars["String"]>;
  UraMiddleName?: Maybe<Scalars["String"]>;
  UraOtherName?: Maybe<Scalars["String"]>;
  UraSurName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated_x?: Maybe<Scalars["String"]>;
  WhoUpdated_y?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_RPT_IndividualComposite" */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Min_Order_By = {
  AdditionalProperties?: Maybe<Order_By>;
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  Brn?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  File_x?: Maybe<Order_By>;
  File_y?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated_x?: Maybe<Order_By>;
  LastUpdated_y?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MatchScoreDetails?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TownCd?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
  TownName?: Maybe<Order_By>;
  UraFamilyName?: Maybe<Order_By>;
  UraFirstName?: Maybe<Order_By>;
  UraMiddleName?: Maybe<Order_By>;
  UraOtherName?: Maybe<Order_By>;
  UraSurName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated_x?: Maybe<Order_By>;
  WhoUpdated_y?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_RPT_IndividualComposite" */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Mutation_Response = {
  __typename?: "rtcs_db_PH_RPT_IndividualComposite_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rpt_IndividualComposite>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_RPT_IndividualComposite" */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rpt_IndividualComposite_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_RPT_IndividualComposite" */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Order_By = {
  AdditionalProperties?: Maybe<Order_By>;
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  Brn?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  File_x?: Maybe<Order_By>;
  File_y?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated_x?: Maybe<Order_By>;
  LastUpdated_y?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MatchScoreDetails?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TownCd?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
  TownName?: Maybe<Order_By>;
  UraFamilyName?: Maybe<Order_By>;
  UraFirstName?: Maybe<Order_By>;
  UraMiddleName?: Maybe<Order_By>;
  UraOtherName?: Maybe<Order_By>;
  UraSurName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated_x?: Maybe<Order_By>;
  WhoUpdated_y?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_RPT_IndividualComposite" */
export enum Rtcs_Db_Ph_Rpt_IndividualComposite_Select_Column {
  /** column name */
  AdditionalProperties = "AdditionalProperties",
  /** column name */
  AppliedForTin = "AppliedForTin",
  /** column name */
  AuthctcdEmailId = "AuthctcdEmailId",
  /** column name */
  AuthctcdFamilyName = "AuthctcdFamilyName",
  /** column name */
  AuthctcdFirstName = "AuthctcdFirstName",
  /** column name */
  AuthctcdLandlineNumber = "AuthctcdLandlineNumber",
  /** column name */
  AuthctcdMobileNumber = "AuthctcdMobileNumber",
  /** column name */
  AuthctcdOtherName = "AuthctcdOtherName",
  /** column name */
  AuthctcdPrsnDesgn = "AuthctcdPrsnDesgn",
  /** column name */
  AuthctcdSurname = "AuthctcdSurname",
  /** column name */
  AuthctcdTin = "AuthctcdTin",
  /** column name */
  BirthCity = "BirthCity",
  /** column name */
  BirthCountry = "BirthCountry",
  /** column name */
  BirthDt = "BirthDt",
  /** column name */
  BirthState = "BirthState",
  /** column name */
  Brn = "Brn",
  /** column name */
  BsnsCertNumber = "BsnsCertNumber",
  /** column name */
  BsnsName = "BsnsName",
  /** column name */
  BuildingName = "BuildingName",
  /** column name */
  BusinessName = "BusinessName",
  /** column name */
  CitizenCountry = "CitizenCountry",
  /** column name */
  Coin = "Coin",
  /** column name */
  CountUra = "CountUra",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DistrictId = "DistrictId",
  /** column name */
  EmailId = "EmailId",
  /** column name */
  EntityLegalName = "EntityLegalName",
  /** column name */
  ExistTinNo = "ExistTinNo",
  /** column name */
  Fax = "Fax",
  /** column name */
  FileX = "File_x",
  /** column name */
  FileY = "File_y",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Fullname = "Fullname",
  /** column name */
  Gender = "Gender",
  /** column name */
  Gml = "Gml",
  /** column name */
  HomeAddrBuildingname = "HomeAddrBuildingname",
  /** column name */
  HomeAddrDistrictName = "HomeAddrDistrictName",
  /** column name */
  HomeAddrLocalCouncil = "HomeAddrLocalCouncil",
  /** column name */
  HomeAddrParishName = "HomeAddrParishName",
  /** column name */
  HomeAddrPlotno = "HomeAddrPlotno",
  /** column name */
  HomeAddrStrretAddr = "HomeAddrStrretAddr",
  /** column name */
  HomeAddrSubCountyName = "HomeAddrSubCountyName",
  /** column name */
  HomeAddrTownName = "HomeAddrTownName",
  /** column name */
  HomeAddrTradeCentre = "HomeAddrTradeCentre",
  /** column name */
  HomeAddrVillageName = "HomeAddrVillageName",
  /** column name */
  IdentificationRefugeeId = "IdentificationRefugeeId",
  /** column name */
  IdentificationWorkPermit = "IdentificationWorkPermit",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcEmp = "IsIncomeSrcEmp",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  LandlineNumber = "LandlineNumber",
  /** column name */
  LastUpdatedX = "LastUpdated_x",
  /** column name */
  LastUpdatedY = "LastUpdated_y",
  /** column name */
  LineX = "Line_x",
  /** column name */
  LineY = "Line_y",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MatchScoreAttributes = "MatchScoreAttributes",
  /** column name */
  MatchScoreDetails = "MatchScoreDetails",
  /** column name */
  MatchedNiraNin = "MatchedNiraNin",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  NationalId = "NationalId",
  /** column name */
  NiraNin = "NiraNin",
  /** column name */
  NssfId = "NssfId",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  Photo = "Photo",
  /** column name */
  PhotoContent = "PhotoContent",
  /** column name */
  PhotoMime = "PhotoMime",
  /** column name */
  PhotoName = "PhotoName",
  /** column name */
  PhotoSize = "PhotoSize",
  /** column name */
  PostaddrDistictName = "PostaddrDistictName",
  /** column name */
  PostaddrId = "PostaddrId",
  /** column name */
  RefEmailId = "RefEmailId",
  /** column name */
  RefFamilyName = "RefFamilyName",
  /** column name */
  RefFirstName = "RefFirstName",
  /** column name */
  RefLandlineNumber = "RefLandlineNumber",
  /** column name */
  RefMobileNumber = "RefMobileNumber",
  /** column name */
  RefOtherName = "RefOtherName",
  /** column name */
  RefPrsnDesgn = "RefPrsnDesgn",
  /** column name */
  RefSurname = "RefSurname",
  /** column name */
  RefTin = "RefTin",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Source = "Source",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  SumGrossRentValueAdjusted = "SumGrossRentValueAdjusted",
  /** column name */
  SumNonRateableValue = "SumNonRateableValue",
  /** column name */
  Surname = "Surname",
  /** column name */
  TaxPayerId = "TaxPayerId",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TaxPayerType = "TaxPayerType",
  /** column name */
  TownCd = "TownCd",
  /** column name */
  TownId = "TownId",
  /** column name */
  TownName = "TownName",
  /** column name */
  UraFamilyName = "UraFamilyName",
  /** column name */
  UraFirstName = "UraFirstName",
  /** column name */
  UraMiddleName = "UraMiddleName",
  /** column name */
  UraOtherName = "UraOtherName",
  /** column name */
  UraSurName = "UraSurName",
  /** column name */
  UraTinNo = "UraTinNo",
  /** column name */
  Uuid = "Uuid",
  /** column name */
  WhoUpdatedX = "WhoUpdated_x",
  /** column name */
  WhoUpdatedY = "WhoUpdated_y",
}

/** input type for updating data in table "rtcs_db.PH_RPT_IndividualComposite" */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Set_Input = {
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["timestamptz"]>;
  BirthState?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["float8"]>;
  File_x?: Maybe<Scalars["String"]>;
  File_y?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated_x?: Maybe<Scalars["String"]>;
  LastUpdated_y?: Maybe<Scalars["String"]>;
  Line_x?: Maybe<Scalars["float8"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchScoreDetails?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["float8"]>;
  TownName?: Maybe<Scalars["String"]>;
  UraFamilyName?: Maybe<Scalars["String"]>;
  UraFirstName?: Maybe<Scalars["String"]>;
  UraMiddleName?: Maybe<Scalars["String"]>;
  UraOtherName?: Maybe<Scalars["String"]>;
  UraSurName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated_x?: Maybe<Scalars["String"]>;
  WhoUpdated_y?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Stddev_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualComposite_stddev_fields";
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  BirthCity?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Fax?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_RPT_IndividualComposite" */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Stddev_Order_By = {
  AdditionalProperties?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualComposite_stddev_pop_fields";
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  BirthCity?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Fax?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_RPT_IndividualComposite" */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Stddev_Pop_Order_By = {
  AdditionalProperties?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualComposite_stddev_samp_fields";
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  BirthCity?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Fax?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_RPT_IndividualComposite" */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Stddev_Samp_Order_By = {
  AdditionalProperties?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Sum_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualComposite_sum_fields";
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  Fax?: Maybe<Scalars["float8"]>;
  Line_x?: Maybe<Scalars["float8"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  TownId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_RPT_IndividualComposite" */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Sum_Order_By = {
  AdditionalProperties?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualComposite_var_pop_fields";
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  BirthCity?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Fax?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_RPT_IndividualComposite" */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Var_Pop_Order_By = {
  AdditionalProperties?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualComposite_var_samp_fields";
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  BirthCity?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Fax?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_RPT_IndividualComposite" */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Var_Samp_Order_By = {
  AdditionalProperties?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Variance_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualComposite_variance_fields";
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  BirthCity?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Fax?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_RPT_IndividualComposite" */
export type Rtcs_Db_Ph_Rpt_IndividualComposite_Variance_Order_By = {
  AdditionalProperties?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_RPT_IndividualRegistration" */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration = {
  __typename?: "rtcs_db_PH_RPT_IndividualRegistration";
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["timestamptz"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["float8"]>;
  TownName?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_RPT_IndividualRegistration" */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Aggregate = {
  __typename?: "rtcs_db_PH_RPT_IndividualRegistration_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rpt_IndividualRegistration>;
};

/** aggregate fields of "rtcs_db.PH_RPT_IndividualRegistration" */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualRegistration_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_RPT_IndividualRegistration" */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualRegistration_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_RPT_IndividualRegistration" */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_RPT_IndividualRegistration" */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rpt_IndividualRegistration_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Avg_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualRegistration_avg_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_RPT_IndividualRegistration" */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Avg_Order_By = {
  BirthCity?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_RPT_IndividualRegistration". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Bool_Exp = {
  AppliedForTin?: Maybe<String_Comparison_Exp>;
  AuthctcdEmailId?: Maybe<String_Comparison_Exp>;
  AuthctcdFamilyName?: Maybe<String_Comparison_Exp>;
  AuthctcdFirstName?: Maybe<String_Comparison_Exp>;
  AuthctcdLandlineNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdMobileNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdOtherName?: Maybe<String_Comparison_Exp>;
  AuthctcdPrsnDesgn?: Maybe<String_Comparison_Exp>;
  AuthctcdSurname?: Maybe<String_Comparison_Exp>;
  AuthctcdTin?: Maybe<String_Comparison_Exp>;
  BirthCity?: Maybe<Bigint_Comparison_Exp>;
  BirthCountry?: Maybe<String_Comparison_Exp>;
  BirthDt?: Maybe<Timestamptz_Comparison_Exp>;
  BirthState?: Maybe<String_Comparison_Exp>;
  BsnsCertNumber?: Maybe<String_Comparison_Exp>;
  BsnsName?: Maybe<String_Comparison_Exp>;
  CitizenCountry?: Maybe<String_Comparison_Exp>;
  DistrictId?: Maybe<Float8_Comparison_Exp>;
  EmailId?: Maybe<String_Comparison_Exp>;
  ExistTinNo?: Maybe<String_Comparison_Exp>;
  FamilyName?: Maybe<String_Comparison_Exp>;
  FirstName?: Maybe<String_Comparison_Exp>;
  FullName?: Maybe<String_Comparison_Exp>;
  Gender?: Maybe<String_Comparison_Exp>;
  HomeAddrBuildingname?: Maybe<String_Comparison_Exp>;
  HomeAddrDistrictName?: Maybe<String_Comparison_Exp>;
  HomeAddrLocalCouncil?: Maybe<String_Comparison_Exp>;
  HomeAddrParishName?: Maybe<String_Comparison_Exp>;
  HomeAddrPlotno?: Maybe<String_Comparison_Exp>;
  HomeAddrStrretAddr?: Maybe<String_Comparison_Exp>;
  HomeAddrSubCountyName?: Maybe<String_Comparison_Exp>;
  HomeAddrTownName?: Maybe<String_Comparison_Exp>;
  HomeAddrTradeCentre?: Maybe<String_Comparison_Exp>;
  HomeAddrVillageName?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcEmp?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  LandlineNumber?: Maybe<String_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  MobileNumber?: Maybe<String_Comparison_Exp>;
  NationalId?: Maybe<String_Comparison_Exp>;
  NssfId?: Maybe<String_Comparison_Exp>;
  OtherName?: Maybe<String_Comparison_Exp>;
  PostaddrDistictName?: Maybe<String_Comparison_Exp>;
  PostaddrId?: Maybe<String_Comparison_Exp>;
  RefEmailId?: Maybe<String_Comparison_Exp>;
  RefFamilyName?: Maybe<String_Comparison_Exp>;
  RefFirstName?: Maybe<String_Comparison_Exp>;
  RefLandlineNumber?: Maybe<String_Comparison_Exp>;
  RefMobileNumber?: Maybe<String_Comparison_Exp>;
  RefOtherName?: Maybe<String_Comparison_Exp>;
  RefPrsnDesgn?: Maybe<String_Comparison_Exp>;
  RefSurname?: Maybe<String_Comparison_Exp>;
  RefTin?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  SurName?: Maybe<String_Comparison_Exp>;
  TaxPayerId?: Maybe<String_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TaxPayerType?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  TownCd?: Maybe<String_Comparison_Exp>;
  TownId?: Maybe<Float8_Comparison_Exp>;
  TownName?: Maybe<String_Comparison_Exp>;
  Uuid?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_RPT_IndividualRegistration" */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Inc_Input = {
  BirthCity?: Maybe<Scalars["bigint"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  TownId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_RPT_IndividualRegistration" */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Insert_Input = {
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["timestamptz"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["float8"]>;
  TownName?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Max_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualRegistration_max_fields";
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["timestamptz"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["float8"]>;
  TownName?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_RPT_IndividualRegistration" */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Max_Order_By = {
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SurName?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TownCd?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
  TownName?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Min_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualRegistration_min_fields";
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["timestamptz"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["float8"]>;
  TownName?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_RPT_IndividualRegistration" */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Min_Order_By = {
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SurName?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TownCd?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
  TownName?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_RPT_IndividualRegistration" */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Mutation_Response = {
  __typename?: "rtcs_db_PH_RPT_IndividualRegistration_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rpt_IndividualRegistration>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_RPT_IndividualRegistration" */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rpt_IndividualRegistration_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_RPT_IndividualRegistration" */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Order_By = {
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SurName?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TownCd?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
  TownName?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_RPT_IndividualRegistration" */
export enum Rtcs_Db_Ph_Rpt_IndividualRegistration_Select_Column {
  /** column name */
  AppliedForTin = "AppliedForTin",
  /** column name */
  AuthctcdEmailId = "AuthctcdEmailId",
  /** column name */
  AuthctcdFamilyName = "AuthctcdFamilyName",
  /** column name */
  AuthctcdFirstName = "AuthctcdFirstName",
  /** column name */
  AuthctcdLandlineNumber = "AuthctcdLandlineNumber",
  /** column name */
  AuthctcdMobileNumber = "AuthctcdMobileNumber",
  /** column name */
  AuthctcdOtherName = "AuthctcdOtherName",
  /** column name */
  AuthctcdPrsnDesgn = "AuthctcdPrsnDesgn",
  /** column name */
  AuthctcdSurname = "AuthctcdSurname",
  /** column name */
  AuthctcdTin = "AuthctcdTin",
  /** column name */
  BirthCity = "BirthCity",
  /** column name */
  BirthCountry = "BirthCountry",
  /** column name */
  BirthDt = "BirthDt",
  /** column name */
  BirthState = "BirthState",
  /** column name */
  BsnsCertNumber = "BsnsCertNumber",
  /** column name */
  BsnsName = "BsnsName",
  /** column name */
  CitizenCountry = "CitizenCountry",
  /** column name */
  DistrictId = "DistrictId",
  /** column name */
  EmailId = "EmailId",
  /** column name */
  ExistTinNo = "ExistTinNo",
  /** column name */
  FamilyName = "FamilyName",
  /** column name */
  FirstName = "FirstName",
  /** column name */
  FullName = "FullName",
  /** column name */
  Gender = "Gender",
  /** column name */
  HomeAddrBuildingname = "HomeAddrBuildingname",
  /** column name */
  HomeAddrDistrictName = "HomeAddrDistrictName",
  /** column name */
  HomeAddrLocalCouncil = "HomeAddrLocalCouncil",
  /** column name */
  HomeAddrParishName = "HomeAddrParishName",
  /** column name */
  HomeAddrPlotno = "HomeAddrPlotno",
  /** column name */
  HomeAddrStrretAddr = "HomeAddrStrretAddr",
  /** column name */
  HomeAddrSubCountyName = "HomeAddrSubCountyName",
  /** column name */
  HomeAddrTownName = "HomeAddrTownName",
  /** column name */
  HomeAddrTradeCentre = "HomeAddrTradeCentre",
  /** column name */
  HomeAddrVillageName = "HomeAddrVillageName",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcEmp = "IsIncomeSrcEmp",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  LandlineNumber = "LandlineNumber",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  NationalId = "NationalId",
  /** column name */
  NssfId = "NssfId",
  /** column name */
  OtherName = "OtherName",
  /** column name */
  PostaddrDistictName = "PostaddrDistictName",
  /** column name */
  PostaddrId = "PostaddrId",
  /** column name */
  RefEmailId = "RefEmailId",
  /** column name */
  RefFamilyName = "RefFamilyName",
  /** column name */
  RefFirstName = "RefFirstName",
  /** column name */
  RefLandlineNumber = "RefLandlineNumber",
  /** column name */
  RefMobileNumber = "RefMobileNumber",
  /** column name */
  RefOtherName = "RefOtherName",
  /** column name */
  RefPrsnDesgn = "RefPrsnDesgn",
  /** column name */
  RefSurname = "RefSurname",
  /** column name */
  RefTin = "RefTin",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  SurName = "SurName",
  /** column name */
  TaxPayerId = "TaxPayerId",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TaxPayerType = "TaxPayerType",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  TownCd = "TownCd",
  /** column name */
  TownId = "TownId",
  /** column name */
  TownName = "TownName",
  /** column name */
  Uuid = "Uuid",
}

/** input type for updating data in table "rtcs_db.PH_RPT_IndividualRegistration" */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Set_Input = {
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["timestamptz"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["float8"]>;
  TownName?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Stddev_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualRegistration_stddev_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_RPT_IndividualRegistration" */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Stddev_Order_By = {
  BirthCity?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualRegistration_stddev_pop_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_RPT_IndividualRegistration" */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Stddev_Pop_Order_By = {
  BirthCity?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualRegistration_stddev_samp_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_RPT_IndividualRegistration" */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Stddev_Samp_Order_By = {
  BirthCity?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Sum_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualRegistration_sum_fields";
  BirthCity?: Maybe<Scalars["bigint"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  TownId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_RPT_IndividualRegistration" */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Sum_Order_By = {
  BirthCity?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualRegistration_var_pop_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_RPT_IndividualRegistration" */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Var_Pop_Order_By = {
  BirthCity?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualRegistration_var_samp_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_RPT_IndividualRegistration" */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Var_Samp_Order_By = {
  BirthCity?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Variance_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualRegistration_variance_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_RPT_IndividualRegistration" */
export type Rtcs_Db_Ph_Rpt_IndividualRegistration_Variance_Order_By = {
  BirthCity?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData = {
  __typename?: "rtcs_db_PH_RPT_IndividualTaxSummaryData";
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["float8"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["String"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["float8"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  RentalIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Aggregate = {
  __typename?: "rtcs_db_PH_RPT_IndividualTaxSummaryData_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData>;
};

/** aggregate fields of "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualTaxSummaryData_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Avg_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualTaxSummaryData_avg_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentalIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Avg_Order_By = {
  BirthCity?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentalIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_RPT_IndividualTaxSummaryData". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Bool_Exp = {
  AppliedForTin?: Maybe<String_Comparison_Exp>;
  AuthctcdEmailId?: Maybe<String_Comparison_Exp>;
  AuthctcdFamilyName?: Maybe<String_Comparison_Exp>;
  AuthctcdFirstName?: Maybe<String_Comparison_Exp>;
  AuthctcdLandlineNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdMobileNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdOtherName?: Maybe<String_Comparison_Exp>;
  AuthctcdPrsnDesgn?: Maybe<String_Comparison_Exp>;
  AuthctcdSurname?: Maybe<String_Comparison_Exp>;
  AuthctcdTin?: Maybe<String_Comparison_Exp>;
  BirthCity?: Maybe<Float8_Comparison_Exp>;
  BirthCountry?: Maybe<String_Comparison_Exp>;
  BirthDt?: Maybe<String_Comparison_Exp>;
  BirthState?: Maybe<String_Comparison_Exp>;
  BsnsCertNumber?: Maybe<String_Comparison_Exp>;
  BsnsName?: Maybe<String_Comparison_Exp>;
  ChargeableIncome?: Maybe<Float8_Comparison_Exp>;
  ChargeableIncomeP?: Maybe<Float8_Comparison_Exp>;
  ChrgdRentalIncm?: Maybe<Float8_Comparison_Exp>;
  CitizenCountry?: Maybe<String_Comparison_Exp>;
  DeductIncmTax?: Maybe<Float8_Comparison_Exp>;
  EmailId?: Maybe<String_Comparison_Exp>;
  EmplymntIncome?: Maybe<Float8_Comparison_Exp>;
  EmplymntRate?: Maybe<Float8_Comparison_Exp>;
  EmplymntTax?: Maybe<Float8_Comparison_Exp>;
  ExistTinNo?: Maybe<String_Comparison_Exp>;
  FamilyName?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FirstName?: Maybe<String_Comparison_Exp>;
  Gender?: Maybe<String_Comparison_Exp>;
  GrossRentalIncm?: Maybe<Float8_Comparison_Exp>;
  GrossRentalIncome?: Maybe<Float8_Comparison_Exp>;
  HomeAddrBuildingname?: Maybe<String_Comparison_Exp>;
  HomeAddrDistrictName?: Maybe<String_Comparison_Exp>;
  HomeAddrLocalCouncil?: Maybe<String_Comparison_Exp>;
  HomeAddrParishName?: Maybe<String_Comparison_Exp>;
  HomeAddrPlotno?: Maybe<String_Comparison_Exp>;
  HomeAddrStrretAddr?: Maybe<String_Comparison_Exp>;
  HomeAddrSubCountyName?: Maybe<String_Comparison_Exp>;
  HomeAddrTownName?: Maybe<String_Comparison_Exp>;
  HomeAddrTradeCentre?: Maybe<String_Comparison_Exp>;
  HomeAddrVillageName?: Maybe<String_Comparison_Exp>;
  IsAmendment?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcEmp?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  IsResident?: Maybe<String_Comparison_Exp>;
  LandlineNumber?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Float8_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  MobileNumber?: Maybe<String_Comparison_Exp>;
  MotgageIntDecd?: Maybe<Float8_Comparison_Exp>;
  NationalId?: Maybe<String_Comparison_Exp>;
  NetRentIncm?: Maybe<Float8_Comparison_Exp>;
  NssfId?: Maybe<String_Comparison_Exp>;
  OtherName?: Maybe<String_Comparison_Exp>;
  PartnerRentIncome?: Maybe<Float8_Comparison_Exp>;
  PostaddrDistictName?: Maybe<String_Comparison_Exp>;
  PostaddrId?: Maybe<String_Comparison_Exp>;
  PropincIncome?: Maybe<Float8_Comparison_Exp>;
  PropincRate?: Maybe<Float8_Comparison_Exp>;
  PropincTax?: Maybe<Float8_Comparison_Exp>;
  RefEmailId?: Maybe<String_Comparison_Exp>;
  RefFamilyName?: Maybe<String_Comparison_Exp>;
  RefFirstName?: Maybe<String_Comparison_Exp>;
  RefLandlineNumber?: Maybe<String_Comparison_Exp>;
  RefMobileNumber?: Maybe<String_Comparison_Exp>;
  RefOtherName?: Maybe<String_Comparison_Exp>;
  RefPrsnDesgn?: Maybe<String_Comparison_Exp>;
  RefSurname?: Maybe<String_Comparison_Exp>;
  RefTin?: Maybe<String_Comparison_Exp>;
  RentalIncomeP?: Maybe<Float8_Comparison_Exp>;
  RentincRate?: Maybe<Float8_Comparison_Exp>;
  RentincTax?: Maybe<Float8_Comparison_Exp>;
  ReturnType?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  RtnDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnFromDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnPeriodYear?: Maybe<String_Comparison_Exp>;
  RtnPeriodYearGenerated?: Maybe<String_Comparison_Exp>;
  RtnToDt?: Maybe<String_Comparison_Exp>;
  SurName?: Maybe<String_Comparison_Exp>;
  TaxAssessed?: Maybe<Float8_Comparison_Exp>;
  TaxPayerId?: Maybe<String_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TaxPayerType?: Maybe<String_Comparison_Exp>;
  ThresholdAmnt?: Maybe<Float8_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  TotIncome?: Maybe<Float8_Comparison_Exp>;
  TotRate?: Maybe<Float8_Comparison_Exp>;
  TotRentalIncm?: Maybe<Float8_Comparison_Exp>;
  TotTax?: Maybe<Float8_Comparison_Exp>;
  Uuid?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Inc_Input = {
  BirthCity?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["float8"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RentalIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Insert_Input = {
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["float8"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["String"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["float8"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  RentalIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Max_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualTaxSummaryData_max_fields";
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["float8"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["String"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["float8"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  RentalIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Max_Order_By = {
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  ChrgdRentalIncm?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  RentalIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnPeriodYearGenerated?: Maybe<Order_By>;
  RtnToDt?: Maybe<Order_By>;
  SurName?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Min_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualTaxSummaryData_min_fields";
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["float8"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["String"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["float8"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  RentalIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Min_Order_By = {
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  ChrgdRentalIncm?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  RentalIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnPeriodYearGenerated?: Maybe<Order_By>;
  RtnToDt?: Maybe<Order_By>;
  SurName?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Mutation_Response = {
  __typename?: "rtcs_db_PH_RPT_IndividualTaxSummaryData_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Order_By = {
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  ChrgdRentalIncm?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  RentalIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnPeriodYearGenerated?: Maybe<Order_By>;
  RtnToDt?: Maybe<Order_By>;
  SurName?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
export enum Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Select_Column {
  /** column name */
  AppliedForTin = "AppliedForTin",
  /** column name */
  AuthctcdEmailId = "AuthctcdEmailId",
  /** column name */
  AuthctcdFamilyName = "AuthctcdFamilyName",
  /** column name */
  AuthctcdFirstName = "AuthctcdFirstName",
  /** column name */
  AuthctcdLandlineNumber = "AuthctcdLandlineNumber",
  /** column name */
  AuthctcdMobileNumber = "AuthctcdMobileNumber",
  /** column name */
  AuthctcdOtherName = "AuthctcdOtherName",
  /** column name */
  AuthctcdPrsnDesgn = "AuthctcdPrsnDesgn",
  /** column name */
  AuthctcdSurname = "AuthctcdSurname",
  /** column name */
  AuthctcdTin = "AuthctcdTin",
  /** column name */
  BirthCity = "BirthCity",
  /** column name */
  BirthCountry = "BirthCountry",
  /** column name */
  BirthDt = "BirthDt",
  /** column name */
  BirthState = "BirthState",
  /** column name */
  BsnsCertNumber = "BsnsCertNumber",
  /** column name */
  BsnsName = "BsnsName",
  /** column name */
  ChargeableIncome = "ChargeableIncome",
  /** column name */
  ChargeableIncomeP = "ChargeableIncomeP",
  /** column name */
  ChrgdRentalIncm = "ChrgdRentalIncm",
  /** column name */
  CitizenCountry = "CitizenCountry",
  /** column name */
  DeductIncmTax = "DeductIncmTax",
  /** column name */
  EmailId = "EmailId",
  /** column name */
  EmplymntIncome = "EmplymntIncome",
  /** column name */
  EmplymntRate = "EmplymntRate",
  /** column name */
  EmplymntTax = "EmplymntTax",
  /** column name */
  ExistTinNo = "ExistTinNo",
  /** column name */
  FamilyName = "FamilyName",
  /** column name */
  File = "File",
  /** column name */
  FirstName = "FirstName",
  /** column name */
  Gender = "Gender",
  /** column name */
  GrossRentalIncm = "GrossRentalIncm",
  /** column name */
  GrossRentalIncome = "GrossRentalIncome",
  /** column name */
  HomeAddrBuildingname = "HomeAddrBuildingname",
  /** column name */
  HomeAddrDistrictName = "HomeAddrDistrictName",
  /** column name */
  HomeAddrLocalCouncil = "HomeAddrLocalCouncil",
  /** column name */
  HomeAddrParishName = "HomeAddrParishName",
  /** column name */
  HomeAddrPlotno = "HomeAddrPlotno",
  /** column name */
  HomeAddrStrretAddr = "HomeAddrStrretAddr",
  /** column name */
  HomeAddrSubCountyName = "HomeAddrSubCountyName",
  /** column name */
  HomeAddrTownName = "HomeAddrTownName",
  /** column name */
  HomeAddrTradeCentre = "HomeAddrTradeCentre",
  /** column name */
  HomeAddrVillageName = "HomeAddrVillageName",
  /** column name */
  IsAmendment = "IsAmendment",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcEmp = "IsIncomeSrcEmp",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  IsResident = "IsResident",
  /** column name */
  LandlineNumber = "LandlineNumber",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  MotgageIntDecd = "MotgageIntDecd",
  /** column name */
  NationalId = "NationalId",
  /** column name */
  NetRentIncm = "NetRentIncm",
  /** column name */
  NssfId = "NssfId",
  /** column name */
  OtherName = "OtherName",
  /** column name */
  PartnerRentIncome = "PartnerRentIncome",
  /** column name */
  PostaddrDistictName = "PostaddrDistictName",
  /** column name */
  PostaddrId = "PostaddrId",
  /** column name */
  PropincIncome = "PropincIncome",
  /** column name */
  PropincRate = "PropincRate",
  /** column name */
  PropincTax = "PropincTax",
  /** column name */
  RefEmailId = "RefEmailId",
  /** column name */
  RefFamilyName = "RefFamilyName",
  /** column name */
  RefFirstName = "RefFirstName",
  /** column name */
  RefLandlineNumber = "RefLandlineNumber",
  /** column name */
  RefMobileNumber = "RefMobileNumber",
  /** column name */
  RefOtherName = "RefOtherName",
  /** column name */
  RefPrsnDesgn = "RefPrsnDesgn",
  /** column name */
  RefSurname = "RefSurname",
  /** column name */
  RefTin = "RefTin",
  /** column name */
  RentalIncomeP = "RentalIncomeP",
  /** column name */
  RentincRate = "RentincRate",
  /** column name */
  RentincTax = "RentincTax",
  /** column name */
  ReturnType = "ReturnType",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  RtnDt = "RtnDt",
  /** column name */
  RtnFromDt = "RtnFromDt",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnPeriodYear = "RtnPeriodYear",
  /** column name */
  RtnPeriodYearGenerated = "RtnPeriodYearGenerated",
  /** column name */
  RtnToDt = "RtnToDt",
  /** column name */
  SurName = "SurName",
  /** column name */
  TaxAssessed = "TaxAssessed",
  /** column name */
  TaxPayerId = "TaxPayerId",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TaxPayerType = "TaxPayerType",
  /** column name */
  ThresholdAmnt = "ThresholdAmnt",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  TotIncome = "TotIncome",
  /** column name */
  TotRate = "TotRate",
  /** column name */
  TotRentalIncm = "TotRentalIncm",
  /** column name */
  TotTax = "TotTax",
  /** column name */
  Uuid = "Uuid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Set_Input = {
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["float8"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["String"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["float8"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  RentalIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Stddev_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualTaxSummaryData_stddev_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentalIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Stddev_Order_By = {
  BirthCity?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentalIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualTaxSummaryData_stddev_pop_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentalIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Stddev_Pop_Order_By = {
  BirthCity?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentalIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualTaxSummaryData_stddev_samp_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentalIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Stddev_Samp_Order_By = {
  BirthCity?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentalIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Sum_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualTaxSummaryData_sum_fields";
  BirthCity?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["float8"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RentalIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Sum_Order_By = {
  BirthCity?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentalIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualTaxSummaryData_var_pop_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentalIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Var_Pop_Order_By = {
  BirthCity?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentalIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualTaxSummaryData_var_samp_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentalIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Var_Samp_Order_By = {
  BirthCity?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentalIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Variance_Fields = {
  __typename?: "rtcs_db_PH_RPT_IndividualTaxSummaryData_variance_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentalIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Variance_Order_By = {
  BirthCity?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentalIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_RPT_KccaAffiliates" */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates = {
  __typename?: "rtcs_db_PH_RPT_KccaAffiliates";
  AcquiredName?: Maybe<Scalars["String"]>;
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["float8"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["float8"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["float8"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["float8"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["float8"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerGuardianCoin?: Maybe<Scalars["float8"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["float8"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["float8"]>;
  CustomerGuardianSurname?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  CustomerReferenceCoin?: Maybe<Scalars["float8"]>;
  CustomerReferenceEmail?: Maybe<Scalars["String"]>;
  CustomerReferenceFirstname?: Maybe<Scalars["String"]>;
  CustomerReferenceMiddleName?: Maybe<Scalars["String"]>;
  CustomerReferenceMobile?: Maybe<Scalars["String"]>;
  CustomerReferenceModifiedBy?: Maybe<Scalars["String"]>;
  CustomerReferenceModifiedDate?: Maybe<Scalars["String"]>;
  CustomerReferenceRelationshipDesignation?: Maybe<Scalars["String"]>;
  CustomerReferenceSurname?: Maybe<Scalars["String"]>;
  CustomerReferenceTelephone?: Maybe<Scalars["String"]>;
  EffectDate?: Maybe<Scalars["bigint"]>;
  IdentificationNumber?: Maybe<Scalars["float8"]>;
  IdentificationTypeId?: Maybe<Scalars["float8"]>;
  MotherMaidenName?: Maybe<Scalars["float8"]>;
  OfficialDesignation?: Maybe<Scalars["float8"]>;
  OtherTitle?: Maybe<Scalars["float8"]>;
};

/** aggregated selection of "rtcs_db.PH_RPT_KccaAffiliates" */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Aggregate = {
  __typename?: "rtcs_db_PH_RPT_KccaAffiliates_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rpt_KccaAffiliates>;
};

/** aggregate fields of "rtcs_db.PH_RPT_KccaAffiliates" */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaAffiliates_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_RPT_KccaAffiliates" */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaAffiliates_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_RPT_KccaAffiliates" */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_RPT_KccaAffiliates" */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rpt_KccaAffiliates_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Avg_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaAffiliates_avg_fields";
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["Float"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["Float"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["Float"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["Float"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["Float"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerGuardianCoin?: Maybe<Scalars["Float"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["Float"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["Float"]>;
  CustomerGuardianSurname?: Maybe<Scalars["Float"]>;
  CustomerReferenceCoin?: Maybe<Scalars["Float"]>;
  EffectDate?: Maybe<Scalars["Float"]>;
  IdentificationNumber?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  MotherMaidenName?: Maybe<Scalars["Float"]>;
  OfficialDesignation?: Maybe<Scalars["Float"]>;
  OtherTitle?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_RPT_KccaAffiliates" */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Avg_Order_By = {
  CustomerBusinessAssociateAcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateFirstname?: Maybe<Order_By>;
  CustomerBusinessAssociateMiddleName?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Order_By>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Order_By>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Order_By>;
  CustomerBusinessAssociateSurname?: Maybe<Order_By>;
  CustomerBusinessAssociateTitleId?: Maybe<Order_By>;
  CustomerContactPersonAcquiredName?: Maybe<Order_By>;
  CustomerContactPersonCoin?: Maybe<Order_By>;
  CustomerContactPersonEmail?: Maybe<Order_By>;
  CustomerContactPersonFirstname?: Maybe<Order_By>;
  CustomerContactPersonMiddleName?: Maybe<Order_By>;
  CustomerContactPersonMobile?: Maybe<Order_By>;
  CustomerContactPersonModifiedBy?: Maybe<Order_By>;
  CustomerContactPersonModifiedDate?: Maybe<Order_By>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Order_By>;
  CustomerContactPersonSurname?: Maybe<Order_By>;
  CustomerContactPersonTelephone?: Maybe<Order_By>;
  CustomerContactPersonTitleId?: Maybe<Order_By>;
  CustomerGuardianAcquiredName?: Maybe<Order_By>;
  CustomerGuardianCoin?: Maybe<Order_By>;
  CustomerGuardianFirstname?: Maybe<Order_By>;
  CustomerGuardianMiddleName?: Maybe<Order_By>;
  CustomerGuardianSurname?: Maybe<Order_By>;
  CustomerReferenceCoin?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_RPT_KccaAffiliates". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Bool_Exp = {
  AcquiredName?: Maybe<String_Comparison_Exp>;
  CustomerBusinessAssociateAcquiredName?: Maybe<Float8_Comparison_Exp>;
  CustomerBusinessAssociateFirstname?: Maybe<Float8_Comparison_Exp>;
  CustomerBusinessAssociateMiddleName?: Maybe<Float8_Comparison_Exp>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Float8_Comparison_Exp>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Bigint_Comparison_Exp>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Float8_Comparison_Exp>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Float8_Comparison_Exp>;
  CustomerBusinessAssociateSurname?: Maybe<Float8_Comparison_Exp>;
  CustomerBusinessAssociateTitleId?: Maybe<Float8_Comparison_Exp>;
  CustomerContactPersonAcquiredName?: Maybe<Float8_Comparison_Exp>;
  CustomerContactPersonCoin?: Maybe<Float8_Comparison_Exp>;
  CustomerContactPersonEmail?: Maybe<Float8_Comparison_Exp>;
  CustomerContactPersonFirstname?: Maybe<Float8_Comparison_Exp>;
  CustomerContactPersonMiddleName?: Maybe<Float8_Comparison_Exp>;
  CustomerContactPersonMobile?: Maybe<Float8_Comparison_Exp>;
  CustomerContactPersonModifiedBy?: Maybe<Float8_Comparison_Exp>;
  CustomerContactPersonModifiedDate?: Maybe<Bigint_Comparison_Exp>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Float8_Comparison_Exp>;
  CustomerContactPersonSurname?: Maybe<Float8_Comparison_Exp>;
  CustomerContactPersonTelephone?: Maybe<Float8_Comparison_Exp>;
  CustomerContactPersonTitleId?: Maybe<Float8_Comparison_Exp>;
  CustomerGuardianAcquiredName?: Maybe<Float8_Comparison_Exp>;
  CustomerGuardianCoin?: Maybe<Float8_Comparison_Exp>;
  CustomerGuardianFirstname?: Maybe<Float8_Comparison_Exp>;
  CustomerGuardianMiddleName?: Maybe<Float8_Comparison_Exp>;
  CustomerGuardianSurname?: Maybe<Float8_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  CustomerReferenceCoin?: Maybe<Float8_Comparison_Exp>;
  CustomerReferenceEmail?: Maybe<String_Comparison_Exp>;
  CustomerReferenceFirstname?: Maybe<String_Comparison_Exp>;
  CustomerReferenceMiddleName?: Maybe<String_Comparison_Exp>;
  CustomerReferenceMobile?: Maybe<String_Comparison_Exp>;
  CustomerReferenceModifiedBy?: Maybe<String_Comparison_Exp>;
  CustomerReferenceModifiedDate?: Maybe<String_Comparison_Exp>;
  CustomerReferenceRelationshipDesignation?: Maybe<String_Comparison_Exp>;
  CustomerReferenceSurname?: Maybe<String_Comparison_Exp>;
  CustomerReferenceTelephone?: Maybe<String_Comparison_Exp>;
  EffectDate?: Maybe<Bigint_Comparison_Exp>;
  IdentificationNumber?: Maybe<Float8_Comparison_Exp>;
  IdentificationTypeId?: Maybe<Float8_Comparison_Exp>;
  MotherMaidenName?: Maybe<Float8_Comparison_Exp>;
  OfficialDesignation?: Maybe<Float8_Comparison_Exp>;
  OtherTitle?: Maybe<Float8_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_RPT_KccaAffiliates" */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Inc_Input = {
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["float8"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["float8"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["float8"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["float8"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["float8"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerGuardianCoin?: Maybe<Scalars["float8"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["float8"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["float8"]>;
  CustomerGuardianSurname?: Maybe<Scalars["float8"]>;
  CustomerReferenceCoin?: Maybe<Scalars["float8"]>;
  EffectDate?: Maybe<Scalars["bigint"]>;
  IdentificationNumber?: Maybe<Scalars["float8"]>;
  IdentificationTypeId?: Maybe<Scalars["float8"]>;
  MotherMaidenName?: Maybe<Scalars["float8"]>;
  OfficialDesignation?: Maybe<Scalars["float8"]>;
  OtherTitle?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_RPT_KccaAffiliates" */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Insert_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["float8"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["float8"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["float8"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["float8"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["float8"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerGuardianCoin?: Maybe<Scalars["float8"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["float8"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["float8"]>;
  CustomerGuardianSurname?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  CustomerReferenceCoin?: Maybe<Scalars["float8"]>;
  CustomerReferenceEmail?: Maybe<Scalars["String"]>;
  CustomerReferenceFirstname?: Maybe<Scalars["String"]>;
  CustomerReferenceMiddleName?: Maybe<Scalars["String"]>;
  CustomerReferenceMobile?: Maybe<Scalars["String"]>;
  CustomerReferenceModifiedBy?: Maybe<Scalars["String"]>;
  CustomerReferenceModifiedDate?: Maybe<Scalars["String"]>;
  CustomerReferenceRelationshipDesignation?: Maybe<Scalars["String"]>;
  CustomerReferenceSurname?: Maybe<Scalars["String"]>;
  CustomerReferenceTelephone?: Maybe<Scalars["String"]>;
  EffectDate?: Maybe<Scalars["bigint"]>;
  IdentificationNumber?: Maybe<Scalars["float8"]>;
  IdentificationTypeId?: Maybe<Scalars["float8"]>;
  MotherMaidenName?: Maybe<Scalars["float8"]>;
  OfficialDesignation?: Maybe<Scalars["float8"]>;
  OtherTitle?: Maybe<Scalars["float8"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Max_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaAffiliates_max_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["float8"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["float8"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["float8"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["float8"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["float8"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerGuardianCoin?: Maybe<Scalars["float8"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["float8"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["float8"]>;
  CustomerGuardianSurname?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  CustomerReferenceCoin?: Maybe<Scalars["float8"]>;
  CustomerReferenceEmail?: Maybe<Scalars["String"]>;
  CustomerReferenceFirstname?: Maybe<Scalars["String"]>;
  CustomerReferenceMiddleName?: Maybe<Scalars["String"]>;
  CustomerReferenceMobile?: Maybe<Scalars["String"]>;
  CustomerReferenceModifiedBy?: Maybe<Scalars["String"]>;
  CustomerReferenceModifiedDate?: Maybe<Scalars["String"]>;
  CustomerReferenceRelationshipDesignation?: Maybe<Scalars["String"]>;
  CustomerReferenceSurname?: Maybe<Scalars["String"]>;
  CustomerReferenceTelephone?: Maybe<Scalars["String"]>;
  EffectDate?: Maybe<Scalars["bigint"]>;
  IdentificationNumber?: Maybe<Scalars["float8"]>;
  IdentificationTypeId?: Maybe<Scalars["float8"]>;
  MotherMaidenName?: Maybe<Scalars["float8"]>;
  OfficialDesignation?: Maybe<Scalars["float8"]>;
  OtherTitle?: Maybe<Scalars["float8"]>;
};

/** order by max() on columns of table "rtcs_db.PH_RPT_KccaAffiliates" */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Max_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateAcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateFirstname?: Maybe<Order_By>;
  CustomerBusinessAssociateMiddleName?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Order_By>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Order_By>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Order_By>;
  CustomerBusinessAssociateSurname?: Maybe<Order_By>;
  CustomerBusinessAssociateTitleId?: Maybe<Order_By>;
  CustomerContactPersonAcquiredName?: Maybe<Order_By>;
  CustomerContactPersonCoin?: Maybe<Order_By>;
  CustomerContactPersonEmail?: Maybe<Order_By>;
  CustomerContactPersonFirstname?: Maybe<Order_By>;
  CustomerContactPersonMiddleName?: Maybe<Order_By>;
  CustomerContactPersonMobile?: Maybe<Order_By>;
  CustomerContactPersonModifiedBy?: Maybe<Order_By>;
  CustomerContactPersonModifiedDate?: Maybe<Order_By>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Order_By>;
  CustomerContactPersonSurname?: Maybe<Order_By>;
  CustomerContactPersonTelephone?: Maybe<Order_By>;
  CustomerContactPersonTitleId?: Maybe<Order_By>;
  CustomerGuardianAcquiredName?: Maybe<Order_By>;
  CustomerGuardianCoin?: Maybe<Order_By>;
  CustomerGuardianFirstname?: Maybe<Order_By>;
  CustomerGuardianMiddleName?: Maybe<Order_By>;
  CustomerGuardianSurname?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  CustomerReferenceCoin?: Maybe<Order_By>;
  CustomerReferenceEmail?: Maybe<Order_By>;
  CustomerReferenceFirstname?: Maybe<Order_By>;
  CustomerReferenceMiddleName?: Maybe<Order_By>;
  CustomerReferenceMobile?: Maybe<Order_By>;
  CustomerReferenceModifiedBy?: Maybe<Order_By>;
  CustomerReferenceModifiedDate?: Maybe<Order_By>;
  CustomerReferenceRelationshipDesignation?: Maybe<Order_By>;
  CustomerReferenceSurname?: Maybe<Order_By>;
  CustomerReferenceTelephone?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Min_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaAffiliates_min_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["float8"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["float8"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["float8"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["float8"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["float8"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerGuardianCoin?: Maybe<Scalars["float8"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["float8"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["float8"]>;
  CustomerGuardianSurname?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  CustomerReferenceCoin?: Maybe<Scalars["float8"]>;
  CustomerReferenceEmail?: Maybe<Scalars["String"]>;
  CustomerReferenceFirstname?: Maybe<Scalars["String"]>;
  CustomerReferenceMiddleName?: Maybe<Scalars["String"]>;
  CustomerReferenceMobile?: Maybe<Scalars["String"]>;
  CustomerReferenceModifiedBy?: Maybe<Scalars["String"]>;
  CustomerReferenceModifiedDate?: Maybe<Scalars["String"]>;
  CustomerReferenceRelationshipDesignation?: Maybe<Scalars["String"]>;
  CustomerReferenceSurname?: Maybe<Scalars["String"]>;
  CustomerReferenceTelephone?: Maybe<Scalars["String"]>;
  EffectDate?: Maybe<Scalars["bigint"]>;
  IdentificationNumber?: Maybe<Scalars["float8"]>;
  IdentificationTypeId?: Maybe<Scalars["float8"]>;
  MotherMaidenName?: Maybe<Scalars["float8"]>;
  OfficialDesignation?: Maybe<Scalars["float8"]>;
  OtherTitle?: Maybe<Scalars["float8"]>;
};

/** order by min() on columns of table "rtcs_db.PH_RPT_KccaAffiliates" */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Min_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateAcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateFirstname?: Maybe<Order_By>;
  CustomerBusinessAssociateMiddleName?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Order_By>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Order_By>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Order_By>;
  CustomerBusinessAssociateSurname?: Maybe<Order_By>;
  CustomerBusinessAssociateTitleId?: Maybe<Order_By>;
  CustomerContactPersonAcquiredName?: Maybe<Order_By>;
  CustomerContactPersonCoin?: Maybe<Order_By>;
  CustomerContactPersonEmail?: Maybe<Order_By>;
  CustomerContactPersonFirstname?: Maybe<Order_By>;
  CustomerContactPersonMiddleName?: Maybe<Order_By>;
  CustomerContactPersonMobile?: Maybe<Order_By>;
  CustomerContactPersonModifiedBy?: Maybe<Order_By>;
  CustomerContactPersonModifiedDate?: Maybe<Order_By>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Order_By>;
  CustomerContactPersonSurname?: Maybe<Order_By>;
  CustomerContactPersonTelephone?: Maybe<Order_By>;
  CustomerContactPersonTitleId?: Maybe<Order_By>;
  CustomerGuardianAcquiredName?: Maybe<Order_By>;
  CustomerGuardianCoin?: Maybe<Order_By>;
  CustomerGuardianFirstname?: Maybe<Order_By>;
  CustomerGuardianMiddleName?: Maybe<Order_By>;
  CustomerGuardianSurname?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  CustomerReferenceCoin?: Maybe<Order_By>;
  CustomerReferenceEmail?: Maybe<Order_By>;
  CustomerReferenceFirstname?: Maybe<Order_By>;
  CustomerReferenceMiddleName?: Maybe<Order_By>;
  CustomerReferenceMobile?: Maybe<Order_By>;
  CustomerReferenceModifiedBy?: Maybe<Order_By>;
  CustomerReferenceModifiedDate?: Maybe<Order_By>;
  CustomerReferenceRelationshipDesignation?: Maybe<Order_By>;
  CustomerReferenceSurname?: Maybe<Order_By>;
  CustomerReferenceTelephone?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_RPT_KccaAffiliates" */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Mutation_Response = {
  __typename?: "rtcs_db_PH_RPT_KccaAffiliates_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rpt_KccaAffiliates>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_RPT_KccaAffiliates" */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rpt_KccaAffiliates_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_RPT_KccaAffiliates" */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateAcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateFirstname?: Maybe<Order_By>;
  CustomerBusinessAssociateMiddleName?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Order_By>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Order_By>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Order_By>;
  CustomerBusinessAssociateSurname?: Maybe<Order_By>;
  CustomerBusinessAssociateTitleId?: Maybe<Order_By>;
  CustomerContactPersonAcquiredName?: Maybe<Order_By>;
  CustomerContactPersonCoin?: Maybe<Order_By>;
  CustomerContactPersonEmail?: Maybe<Order_By>;
  CustomerContactPersonFirstname?: Maybe<Order_By>;
  CustomerContactPersonMiddleName?: Maybe<Order_By>;
  CustomerContactPersonMobile?: Maybe<Order_By>;
  CustomerContactPersonModifiedBy?: Maybe<Order_By>;
  CustomerContactPersonModifiedDate?: Maybe<Order_By>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Order_By>;
  CustomerContactPersonSurname?: Maybe<Order_By>;
  CustomerContactPersonTelephone?: Maybe<Order_By>;
  CustomerContactPersonTitleId?: Maybe<Order_By>;
  CustomerGuardianAcquiredName?: Maybe<Order_By>;
  CustomerGuardianCoin?: Maybe<Order_By>;
  CustomerGuardianFirstname?: Maybe<Order_By>;
  CustomerGuardianMiddleName?: Maybe<Order_By>;
  CustomerGuardianSurname?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  CustomerReferenceCoin?: Maybe<Order_By>;
  CustomerReferenceEmail?: Maybe<Order_By>;
  CustomerReferenceFirstname?: Maybe<Order_By>;
  CustomerReferenceMiddleName?: Maybe<Order_By>;
  CustomerReferenceMobile?: Maybe<Order_By>;
  CustomerReferenceModifiedBy?: Maybe<Order_By>;
  CustomerReferenceModifiedDate?: Maybe<Order_By>;
  CustomerReferenceRelationshipDesignation?: Maybe<Order_By>;
  CustomerReferenceSurname?: Maybe<Order_By>;
  CustomerReferenceTelephone?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_RPT_KccaAffiliates" */
export enum Rtcs_Db_Ph_Rpt_KccaAffiliates_Select_Column {
  /** column name */
  AcquiredName = "AcquiredName",
  /** column name */
  CustomerBusinessAssociateAcquiredName = "CustomerBusinessAssociateAcquiredName",
  /** column name */
  CustomerBusinessAssociateFirstname = "CustomerBusinessAssociateFirstname",
  /** column name */
  CustomerBusinessAssociateMiddleName = "CustomerBusinessAssociateMiddleName",
  /** column name */
  CustomerBusinessAssociateModifiedBy = "CustomerBusinessAssociateModifiedBy",
  /** column name */
  CustomerBusinessAssociateModifiedDate = "CustomerBusinessAssociateModifiedDate",
  /** column name */
  CustomerBusinessAssociateMotherMaidenName = "CustomerBusinessAssociateMotherMaidenName",
  /** column name */
  CustomerBusinessAssociateOtherTitle = "CustomerBusinessAssociateOtherTitle",
  /** column name */
  CustomerBusinessAssociateSurname = "CustomerBusinessAssociateSurname",
  /** column name */
  CustomerBusinessAssociateTitleId = "CustomerBusinessAssociateTitleId",
  /** column name */
  CustomerContactPersonAcquiredName = "CustomerContactPersonAcquiredName",
  /** column name */
  CustomerContactPersonCoin = "CustomerContactPersonCoin",
  /** column name */
  CustomerContactPersonEmail = "CustomerContactPersonEmail",
  /** column name */
  CustomerContactPersonFirstname = "CustomerContactPersonFirstname",
  /** column name */
  CustomerContactPersonMiddleName = "CustomerContactPersonMiddleName",
  /** column name */
  CustomerContactPersonMobile = "CustomerContactPersonMobile",
  /** column name */
  CustomerContactPersonModifiedBy = "CustomerContactPersonModifiedBy",
  /** column name */
  CustomerContactPersonModifiedDate = "CustomerContactPersonModifiedDate",
  /** column name */
  CustomerContactPersonRelationshipDesignation = "CustomerContactPersonRelationshipDesignation",
  /** column name */
  CustomerContactPersonSurname = "CustomerContactPersonSurname",
  /** column name */
  CustomerContactPersonTelephone = "CustomerContactPersonTelephone",
  /** column name */
  CustomerContactPersonTitleId = "CustomerContactPersonTitleId",
  /** column name */
  CustomerGuardianAcquiredName = "CustomerGuardianAcquiredName",
  /** column name */
  CustomerGuardianCoin = "CustomerGuardianCoin",
  /** column name */
  CustomerGuardianFirstname = "CustomerGuardianFirstname",
  /** column name */
  CustomerGuardianMiddleName = "CustomerGuardianMiddleName",
  /** column name */
  CustomerGuardianSurname = "CustomerGuardianSurname",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  CustomerReferenceCoin = "CustomerReferenceCoin",
  /** column name */
  CustomerReferenceEmail = "CustomerReferenceEmail",
  /** column name */
  CustomerReferenceFirstname = "CustomerReferenceFirstname",
  /** column name */
  CustomerReferenceMiddleName = "CustomerReferenceMiddleName",
  /** column name */
  CustomerReferenceMobile = "CustomerReferenceMobile",
  /** column name */
  CustomerReferenceModifiedBy = "CustomerReferenceModifiedBy",
  /** column name */
  CustomerReferenceModifiedDate = "CustomerReferenceModifiedDate",
  /** column name */
  CustomerReferenceRelationshipDesignation = "CustomerReferenceRelationshipDesignation",
  /** column name */
  CustomerReferenceSurname = "CustomerReferenceSurname",
  /** column name */
  CustomerReferenceTelephone = "CustomerReferenceTelephone",
  /** column name */
  EffectDate = "EffectDate",
  /** column name */
  IdentificationNumber = "IdentificationNumber",
  /** column name */
  IdentificationTypeId = "IdentificationTypeId",
  /** column name */
  MotherMaidenName = "MotherMaidenName",
  /** column name */
  OfficialDesignation = "OfficialDesignation",
  /** column name */
  OtherTitle = "OtherTitle",
}

/** input type for updating data in table "rtcs_db.PH_RPT_KccaAffiliates" */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Set_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["float8"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["float8"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["float8"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["float8"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["float8"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerGuardianCoin?: Maybe<Scalars["float8"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["float8"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["float8"]>;
  CustomerGuardianSurname?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  CustomerReferenceCoin?: Maybe<Scalars["float8"]>;
  CustomerReferenceEmail?: Maybe<Scalars["String"]>;
  CustomerReferenceFirstname?: Maybe<Scalars["String"]>;
  CustomerReferenceMiddleName?: Maybe<Scalars["String"]>;
  CustomerReferenceMobile?: Maybe<Scalars["String"]>;
  CustomerReferenceModifiedBy?: Maybe<Scalars["String"]>;
  CustomerReferenceModifiedDate?: Maybe<Scalars["String"]>;
  CustomerReferenceRelationshipDesignation?: Maybe<Scalars["String"]>;
  CustomerReferenceSurname?: Maybe<Scalars["String"]>;
  CustomerReferenceTelephone?: Maybe<Scalars["String"]>;
  EffectDate?: Maybe<Scalars["bigint"]>;
  IdentificationNumber?: Maybe<Scalars["float8"]>;
  IdentificationTypeId?: Maybe<Scalars["float8"]>;
  MotherMaidenName?: Maybe<Scalars["float8"]>;
  OfficialDesignation?: Maybe<Scalars["float8"]>;
  OtherTitle?: Maybe<Scalars["float8"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Stddev_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaAffiliates_stddev_fields";
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["Float"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["Float"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["Float"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["Float"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["Float"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerGuardianCoin?: Maybe<Scalars["Float"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["Float"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["Float"]>;
  CustomerGuardianSurname?: Maybe<Scalars["Float"]>;
  CustomerReferenceCoin?: Maybe<Scalars["Float"]>;
  EffectDate?: Maybe<Scalars["Float"]>;
  IdentificationNumber?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  MotherMaidenName?: Maybe<Scalars["Float"]>;
  OfficialDesignation?: Maybe<Scalars["Float"]>;
  OtherTitle?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_RPT_KccaAffiliates" */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Stddev_Order_By = {
  CustomerBusinessAssociateAcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateFirstname?: Maybe<Order_By>;
  CustomerBusinessAssociateMiddleName?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Order_By>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Order_By>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Order_By>;
  CustomerBusinessAssociateSurname?: Maybe<Order_By>;
  CustomerBusinessAssociateTitleId?: Maybe<Order_By>;
  CustomerContactPersonAcquiredName?: Maybe<Order_By>;
  CustomerContactPersonCoin?: Maybe<Order_By>;
  CustomerContactPersonEmail?: Maybe<Order_By>;
  CustomerContactPersonFirstname?: Maybe<Order_By>;
  CustomerContactPersonMiddleName?: Maybe<Order_By>;
  CustomerContactPersonMobile?: Maybe<Order_By>;
  CustomerContactPersonModifiedBy?: Maybe<Order_By>;
  CustomerContactPersonModifiedDate?: Maybe<Order_By>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Order_By>;
  CustomerContactPersonSurname?: Maybe<Order_By>;
  CustomerContactPersonTelephone?: Maybe<Order_By>;
  CustomerContactPersonTitleId?: Maybe<Order_By>;
  CustomerGuardianAcquiredName?: Maybe<Order_By>;
  CustomerGuardianCoin?: Maybe<Order_By>;
  CustomerGuardianFirstname?: Maybe<Order_By>;
  CustomerGuardianMiddleName?: Maybe<Order_By>;
  CustomerGuardianSurname?: Maybe<Order_By>;
  CustomerReferenceCoin?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaAffiliates_stddev_pop_fields";
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["Float"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["Float"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["Float"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["Float"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["Float"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerGuardianCoin?: Maybe<Scalars["Float"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["Float"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["Float"]>;
  CustomerGuardianSurname?: Maybe<Scalars["Float"]>;
  CustomerReferenceCoin?: Maybe<Scalars["Float"]>;
  EffectDate?: Maybe<Scalars["Float"]>;
  IdentificationNumber?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  MotherMaidenName?: Maybe<Scalars["Float"]>;
  OfficialDesignation?: Maybe<Scalars["Float"]>;
  OtherTitle?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_RPT_KccaAffiliates" */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Stddev_Pop_Order_By = {
  CustomerBusinessAssociateAcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateFirstname?: Maybe<Order_By>;
  CustomerBusinessAssociateMiddleName?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Order_By>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Order_By>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Order_By>;
  CustomerBusinessAssociateSurname?: Maybe<Order_By>;
  CustomerBusinessAssociateTitleId?: Maybe<Order_By>;
  CustomerContactPersonAcquiredName?: Maybe<Order_By>;
  CustomerContactPersonCoin?: Maybe<Order_By>;
  CustomerContactPersonEmail?: Maybe<Order_By>;
  CustomerContactPersonFirstname?: Maybe<Order_By>;
  CustomerContactPersonMiddleName?: Maybe<Order_By>;
  CustomerContactPersonMobile?: Maybe<Order_By>;
  CustomerContactPersonModifiedBy?: Maybe<Order_By>;
  CustomerContactPersonModifiedDate?: Maybe<Order_By>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Order_By>;
  CustomerContactPersonSurname?: Maybe<Order_By>;
  CustomerContactPersonTelephone?: Maybe<Order_By>;
  CustomerContactPersonTitleId?: Maybe<Order_By>;
  CustomerGuardianAcquiredName?: Maybe<Order_By>;
  CustomerGuardianCoin?: Maybe<Order_By>;
  CustomerGuardianFirstname?: Maybe<Order_By>;
  CustomerGuardianMiddleName?: Maybe<Order_By>;
  CustomerGuardianSurname?: Maybe<Order_By>;
  CustomerReferenceCoin?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaAffiliates_stddev_samp_fields";
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["Float"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["Float"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["Float"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["Float"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["Float"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerGuardianCoin?: Maybe<Scalars["Float"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["Float"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["Float"]>;
  CustomerGuardianSurname?: Maybe<Scalars["Float"]>;
  CustomerReferenceCoin?: Maybe<Scalars["Float"]>;
  EffectDate?: Maybe<Scalars["Float"]>;
  IdentificationNumber?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  MotherMaidenName?: Maybe<Scalars["Float"]>;
  OfficialDesignation?: Maybe<Scalars["Float"]>;
  OtherTitle?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_RPT_KccaAffiliates" */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Stddev_Samp_Order_By = {
  CustomerBusinessAssociateAcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateFirstname?: Maybe<Order_By>;
  CustomerBusinessAssociateMiddleName?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Order_By>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Order_By>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Order_By>;
  CustomerBusinessAssociateSurname?: Maybe<Order_By>;
  CustomerBusinessAssociateTitleId?: Maybe<Order_By>;
  CustomerContactPersonAcquiredName?: Maybe<Order_By>;
  CustomerContactPersonCoin?: Maybe<Order_By>;
  CustomerContactPersonEmail?: Maybe<Order_By>;
  CustomerContactPersonFirstname?: Maybe<Order_By>;
  CustomerContactPersonMiddleName?: Maybe<Order_By>;
  CustomerContactPersonMobile?: Maybe<Order_By>;
  CustomerContactPersonModifiedBy?: Maybe<Order_By>;
  CustomerContactPersonModifiedDate?: Maybe<Order_By>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Order_By>;
  CustomerContactPersonSurname?: Maybe<Order_By>;
  CustomerContactPersonTelephone?: Maybe<Order_By>;
  CustomerContactPersonTitleId?: Maybe<Order_By>;
  CustomerGuardianAcquiredName?: Maybe<Order_By>;
  CustomerGuardianCoin?: Maybe<Order_By>;
  CustomerGuardianFirstname?: Maybe<Order_By>;
  CustomerGuardianMiddleName?: Maybe<Order_By>;
  CustomerGuardianSurname?: Maybe<Order_By>;
  CustomerReferenceCoin?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Sum_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaAffiliates_sum_fields";
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["float8"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["float8"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["float8"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["float8"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["float8"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerGuardianCoin?: Maybe<Scalars["float8"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["float8"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["float8"]>;
  CustomerGuardianSurname?: Maybe<Scalars["float8"]>;
  CustomerReferenceCoin?: Maybe<Scalars["float8"]>;
  EffectDate?: Maybe<Scalars["bigint"]>;
  IdentificationNumber?: Maybe<Scalars["float8"]>;
  IdentificationTypeId?: Maybe<Scalars["float8"]>;
  MotherMaidenName?: Maybe<Scalars["float8"]>;
  OfficialDesignation?: Maybe<Scalars["float8"]>;
  OtherTitle?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_RPT_KccaAffiliates" */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Sum_Order_By = {
  CustomerBusinessAssociateAcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateFirstname?: Maybe<Order_By>;
  CustomerBusinessAssociateMiddleName?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Order_By>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Order_By>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Order_By>;
  CustomerBusinessAssociateSurname?: Maybe<Order_By>;
  CustomerBusinessAssociateTitleId?: Maybe<Order_By>;
  CustomerContactPersonAcquiredName?: Maybe<Order_By>;
  CustomerContactPersonCoin?: Maybe<Order_By>;
  CustomerContactPersonEmail?: Maybe<Order_By>;
  CustomerContactPersonFirstname?: Maybe<Order_By>;
  CustomerContactPersonMiddleName?: Maybe<Order_By>;
  CustomerContactPersonMobile?: Maybe<Order_By>;
  CustomerContactPersonModifiedBy?: Maybe<Order_By>;
  CustomerContactPersonModifiedDate?: Maybe<Order_By>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Order_By>;
  CustomerContactPersonSurname?: Maybe<Order_By>;
  CustomerContactPersonTelephone?: Maybe<Order_By>;
  CustomerContactPersonTitleId?: Maybe<Order_By>;
  CustomerGuardianAcquiredName?: Maybe<Order_By>;
  CustomerGuardianCoin?: Maybe<Order_By>;
  CustomerGuardianFirstname?: Maybe<Order_By>;
  CustomerGuardianMiddleName?: Maybe<Order_By>;
  CustomerGuardianSurname?: Maybe<Order_By>;
  CustomerReferenceCoin?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaAffiliates_var_pop_fields";
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["Float"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["Float"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["Float"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["Float"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["Float"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerGuardianCoin?: Maybe<Scalars["Float"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["Float"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["Float"]>;
  CustomerGuardianSurname?: Maybe<Scalars["Float"]>;
  CustomerReferenceCoin?: Maybe<Scalars["Float"]>;
  EffectDate?: Maybe<Scalars["Float"]>;
  IdentificationNumber?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  MotherMaidenName?: Maybe<Scalars["Float"]>;
  OfficialDesignation?: Maybe<Scalars["Float"]>;
  OtherTitle?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_RPT_KccaAffiliates" */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Var_Pop_Order_By = {
  CustomerBusinessAssociateAcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateFirstname?: Maybe<Order_By>;
  CustomerBusinessAssociateMiddleName?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Order_By>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Order_By>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Order_By>;
  CustomerBusinessAssociateSurname?: Maybe<Order_By>;
  CustomerBusinessAssociateTitleId?: Maybe<Order_By>;
  CustomerContactPersonAcquiredName?: Maybe<Order_By>;
  CustomerContactPersonCoin?: Maybe<Order_By>;
  CustomerContactPersonEmail?: Maybe<Order_By>;
  CustomerContactPersonFirstname?: Maybe<Order_By>;
  CustomerContactPersonMiddleName?: Maybe<Order_By>;
  CustomerContactPersonMobile?: Maybe<Order_By>;
  CustomerContactPersonModifiedBy?: Maybe<Order_By>;
  CustomerContactPersonModifiedDate?: Maybe<Order_By>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Order_By>;
  CustomerContactPersonSurname?: Maybe<Order_By>;
  CustomerContactPersonTelephone?: Maybe<Order_By>;
  CustomerContactPersonTitleId?: Maybe<Order_By>;
  CustomerGuardianAcquiredName?: Maybe<Order_By>;
  CustomerGuardianCoin?: Maybe<Order_By>;
  CustomerGuardianFirstname?: Maybe<Order_By>;
  CustomerGuardianMiddleName?: Maybe<Order_By>;
  CustomerGuardianSurname?: Maybe<Order_By>;
  CustomerReferenceCoin?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaAffiliates_var_samp_fields";
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["Float"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["Float"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["Float"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["Float"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["Float"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerGuardianCoin?: Maybe<Scalars["Float"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["Float"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["Float"]>;
  CustomerGuardianSurname?: Maybe<Scalars["Float"]>;
  CustomerReferenceCoin?: Maybe<Scalars["Float"]>;
  EffectDate?: Maybe<Scalars["Float"]>;
  IdentificationNumber?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  MotherMaidenName?: Maybe<Scalars["Float"]>;
  OfficialDesignation?: Maybe<Scalars["Float"]>;
  OtherTitle?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_RPT_KccaAffiliates" */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Var_Samp_Order_By = {
  CustomerBusinessAssociateAcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateFirstname?: Maybe<Order_By>;
  CustomerBusinessAssociateMiddleName?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Order_By>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Order_By>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Order_By>;
  CustomerBusinessAssociateSurname?: Maybe<Order_By>;
  CustomerBusinessAssociateTitleId?: Maybe<Order_By>;
  CustomerContactPersonAcquiredName?: Maybe<Order_By>;
  CustomerContactPersonCoin?: Maybe<Order_By>;
  CustomerContactPersonEmail?: Maybe<Order_By>;
  CustomerContactPersonFirstname?: Maybe<Order_By>;
  CustomerContactPersonMiddleName?: Maybe<Order_By>;
  CustomerContactPersonMobile?: Maybe<Order_By>;
  CustomerContactPersonModifiedBy?: Maybe<Order_By>;
  CustomerContactPersonModifiedDate?: Maybe<Order_By>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Order_By>;
  CustomerContactPersonSurname?: Maybe<Order_By>;
  CustomerContactPersonTelephone?: Maybe<Order_By>;
  CustomerContactPersonTitleId?: Maybe<Order_By>;
  CustomerGuardianAcquiredName?: Maybe<Order_By>;
  CustomerGuardianCoin?: Maybe<Order_By>;
  CustomerGuardianFirstname?: Maybe<Order_By>;
  CustomerGuardianMiddleName?: Maybe<Order_By>;
  CustomerGuardianSurname?: Maybe<Order_By>;
  CustomerReferenceCoin?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Variance_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaAffiliates_variance_fields";
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["Float"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["Float"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["Float"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["Float"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["Float"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerGuardianCoin?: Maybe<Scalars["Float"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["Float"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["Float"]>;
  CustomerGuardianSurname?: Maybe<Scalars["Float"]>;
  CustomerReferenceCoin?: Maybe<Scalars["Float"]>;
  EffectDate?: Maybe<Scalars["Float"]>;
  IdentificationNumber?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  MotherMaidenName?: Maybe<Scalars["Float"]>;
  OfficialDesignation?: Maybe<Scalars["Float"]>;
  OtherTitle?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_RPT_KccaAffiliates" */
export type Rtcs_Db_Ph_Rpt_KccaAffiliates_Variance_Order_By = {
  CustomerBusinessAssociateAcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateFirstname?: Maybe<Order_By>;
  CustomerBusinessAssociateMiddleName?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Order_By>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Order_By>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Order_By>;
  CustomerBusinessAssociateSurname?: Maybe<Order_By>;
  CustomerBusinessAssociateTitleId?: Maybe<Order_By>;
  CustomerContactPersonAcquiredName?: Maybe<Order_By>;
  CustomerContactPersonCoin?: Maybe<Order_By>;
  CustomerContactPersonEmail?: Maybe<Order_By>;
  CustomerContactPersonFirstname?: Maybe<Order_By>;
  CustomerContactPersonMiddleName?: Maybe<Order_By>;
  CustomerContactPersonMobile?: Maybe<Order_By>;
  CustomerContactPersonModifiedBy?: Maybe<Order_By>;
  CustomerContactPersonModifiedDate?: Maybe<Order_By>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Order_By>;
  CustomerContactPersonSurname?: Maybe<Order_By>;
  CustomerContactPersonTelephone?: Maybe<Order_By>;
  CustomerContactPersonTitleId?: Maybe<Order_By>;
  CustomerGuardianAcquiredName?: Maybe<Order_By>;
  CustomerGuardianCoin?: Maybe<Order_By>;
  CustomerGuardianFirstname?: Maybe<Order_By>;
  CustomerGuardianMiddleName?: Maybe<Order_By>;
  CustomerGuardianSurname?: Maybe<Order_By>;
  CustomerReferenceCoin?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData = {
  __typename?: "rtcs_db_PH_RPT_KccaCustomerRegistrationData";
  AcquiredName?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["String"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerApplicantType?: Maybe<Scalars["String"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessType?: Maybe<Scalars["String"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerType?: Maybe<Scalars["String"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCounty?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrict?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParish?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCounty?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillage?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["String"]>;
  ResidentialAddressCounty?: Maybe<Scalars["String"]>;
  ResidentialAddressDistrict?: Maybe<Scalars["String"]>;
  ResidentialAddressParish?: Maybe<Scalars["String"]>;
  ResidentialAddressSubCounty?: Maybe<Scalars["String"]>;
  ResidentialAddressVillage?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Aggregate = {
  __typename?: "rtcs_db_PH_RPT_KccaCustomerRegistrationData_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData>;
};

/** aggregate fields of "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaCustomerRegistrationData_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Avg_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaCustomerRegistrationData_avg_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Avg_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_RPT_KccaCustomerRegistrationData". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Bool_Exp = {
  AcquiredName?: Maybe<String_Comparison_Exp>;
  Birthdate?: Maybe<String_Comparison_Exp>;
  BoxDistrictId?: Maybe<Float8_Comparison_Exp>;
  BoxNumber?: Maybe<String_Comparison_Exp>;
  Brn?: Maybe<String_Comparison_Exp>;
  BuildingName?: Maybe<String_Comparison_Exp>;
  BusinessName?: Maybe<String_Comparison_Exp>;
  CitizenshipCountryId?: Maybe<Float8_Comparison_Exp>;
  Coin?: Maybe<Float8_Comparison_Exp>;
  CreatedBy?: Maybe<String_Comparison_Exp>;
  CreatedDate?: Maybe<String_Comparison_Exp>;
  CustomerApplicantType?: Maybe<String_Comparison_Exp>;
  CustomerApplicantTypeId?: Maybe<Float8_Comparison_Exp>;
  CustomerBusinessType?: Maybe<String_Comparison_Exp>;
  CustomerBusinessTypeId?: Maybe<Float8_Comparison_Exp>;
  CustomerType?: Maybe<String_Comparison_Exp>;
  CustomerTypeId?: Maybe<Bigint_Comparison_Exp>;
  DateOfIncorporation?: Maybe<String_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  EntityLegalName?: Maybe<String_Comparison_Exp>;
  Fax?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  FormerBusinessName?: Maybe<String_Comparison_Exp>;
  GenderId?: Maybe<Float8_Comparison_Exp>;
  HaveAlternatePrimaryContact?: Maybe<Float8_Comparison_Exp>;
  HaveReference?: Maybe<Float8_Comparison_Exp>;
  HaveRevenueAgent?: Maybe<Float8_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  IdentificationDiplomaticId?: Maybe<String_Comparison_Exp>;
  IdentificationDrivingPermit?: Maybe<String_Comparison_Exp>;
  IdentificationEmployeeId?: Maybe<String_Comparison_Exp>;
  IdentificationFinancialCard?: Maybe<String_Comparison_Exp>;
  IdentificationNationalId?: Maybe<String_Comparison_Exp>;
  IdentificationNssfNumber?: Maybe<String_Comparison_Exp>;
  IdentificationPassportNo?: Maybe<String_Comparison_Exp>;
  IdentificationRefugeeId?: Maybe<String_Comparison_Exp>;
  IdentificationVillageId?: Maybe<String_Comparison_Exp>;
  IdentificationVoterId?: Maybe<String_Comparison_Exp>;
  IdentificationWorkId?: Maybe<String_Comparison_Exp>;
  IdentificationWorkPermit?: Maybe<String_Comparison_Exp>;
  IsForcedAction?: Maybe<Float8_Comparison_Exp>;
  IsMinor?: Maybe<Float8_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  Mobile?: Maybe<String_Comparison_Exp>;
  MotherMaidenName?: Maybe<String_Comparison_Exp>;
  Photo?: Maybe<Float8_Comparison_Exp>;
  PhotoContent?: Maybe<Float8_Comparison_Exp>;
  PhotoMime?: Maybe<Float8_Comparison_Exp>;
  PhotoName?: Maybe<Float8_Comparison_Exp>;
  PhotoSize?: Maybe<Float8_Comparison_Exp>;
  PlotNumber?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressBuildingName?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressCounty?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressCountyId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressDistrict?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressDistrictId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressParish?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressParishId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressPlotNumber?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressStreetName?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressSubCounty?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressSubCountyId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressTradingCenter?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressVillage?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressVillageId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessName?: Maybe<String_Comparison_Exp>;
  ResidentialAddressCounty?: Maybe<String_Comparison_Exp>;
  ResidentialAddressDistrict?: Maybe<String_Comparison_Exp>;
  ResidentialAddressParish?: Maybe<String_Comparison_Exp>;
  ResidentialAddressSubCounty?: Maybe<String_Comparison_Exp>;
  ResidentialAddressVillage?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  SecondMobile?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Float8_Comparison_Exp>;
  StreetName?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Telephone?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<String_Comparison_Exp>;
  TitleId?: Maybe<Float8_Comparison_Exp>;
  TradingCenter?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Inc_Input = {
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TitleId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Insert_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["String"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerApplicantType?: Maybe<Scalars["String"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessType?: Maybe<Scalars["String"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerType?: Maybe<Scalars["String"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCounty?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrict?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParish?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCounty?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillage?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["String"]>;
  ResidentialAddressCounty?: Maybe<Scalars["String"]>;
  ResidentialAddressDistrict?: Maybe<Scalars["String"]>;
  ResidentialAddressParish?: Maybe<Scalars["String"]>;
  ResidentialAddressSubCounty?: Maybe<Scalars["String"]>;
  ResidentialAddressVillage?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Max_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaCustomerRegistrationData_max_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["String"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerApplicantType?: Maybe<Scalars["String"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessType?: Maybe<Scalars["String"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerType?: Maybe<Scalars["String"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCounty?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrict?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParish?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCounty?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillage?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["String"]>;
  ResidentialAddressCounty?: Maybe<Scalars["String"]>;
  ResidentialAddressDistrict?: Maybe<Scalars["String"]>;
  ResidentialAddressParish?: Maybe<Scalars["String"]>;
  ResidentialAddressSubCounty?: Maybe<Scalars["String"]>;
  ResidentialAddressVillage?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Max_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Birthdate?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  BoxNumber?: Maybe<Order_By>;
  Brn?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerApplicantType?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessType?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerType?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FormerBusinessName?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationDiplomaticId?: Maybe<Order_By>;
  IdentificationDrivingPermit?: Maybe<Order_By>;
  IdentificationEmployeeId?: Maybe<Order_By>;
  IdentificationFinancialCard?: Maybe<Order_By>;
  IdentificationNationalId?: Maybe<Order_By>;
  IdentificationNssfNumber?: Maybe<Order_By>;
  IdentificationPassportNo?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationVillageId?: Maybe<Order_By>;
  IdentificationVoterId?: Maybe<Order_By>;
  IdentificationWorkId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressBuildingName?: Maybe<Order_By>;
  PreviousBusinessAddressCounty?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrict?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParish?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressStreetName?: Maybe<Order_By>;
  PreviousBusinessAddressSubCounty?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressTradingCenter?: Maybe<Order_By>;
  PreviousBusinessAddressVillage?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCounty?: Maybe<Order_By>;
  ResidentialAddressDistrict?: Maybe<Order_By>;
  ResidentialAddressParish?: Maybe<Order_By>;
  ResidentialAddressSubCounty?: Maybe<Order_By>;
  ResidentialAddressVillage?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetName?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TradingCenter?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Min_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaCustomerRegistrationData_min_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["String"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerApplicantType?: Maybe<Scalars["String"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessType?: Maybe<Scalars["String"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerType?: Maybe<Scalars["String"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCounty?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrict?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParish?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCounty?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillage?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["String"]>;
  ResidentialAddressCounty?: Maybe<Scalars["String"]>;
  ResidentialAddressDistrict?: Maybe<Scalars["String"]>;
  ResidentialAddressParish?: Maybe<Scalars["String"]>;
  ResidentialAddressSubCounty?: Maybe<Scalars["String"]>;
  ResidentialAddressVillage?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Min_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Birthdate?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  BoxNumber?: Maybe<Order_By>;
  Brn?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerApplicantType?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessType?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerType?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FormerBusinessName?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationDiplomaticId?: Maybe<Order_By>;
  IdentificationDrivingPermit?: Maybe<Order_By>;
  IdentificationEmployeeId?: Maybe<Order_By>;
  IdentificationFinancialCard?: Maybe<Order_By>;
  IdentificationNationalId?: Maybe<Order_By>;
  IdentificationNssfNumber?: Maybe<Order_By>;
  IdentificationPassportNo?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationVillageId?: Maybe<Order_By>;
  IdentificationVoterId?: Maybe<Order_By>;
  IdentificationWorkId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressBuildingName?: Maybe<Order_By>;
  PreviousBusinessAddressCounty?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrict?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParish?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressStreetName?: Maybe<Order_By>;
  PreviousBusinessAddressSubCounty?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressTradingCenter?: Maybe<Order_By>;
  PreviousBusinessAddressVillage?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCounty?: Maybe<Order_By>;
  ResidentialAddressDistrict?: Maybe<Order_By>;
  ResidentialAddressParish?: Maybe<Order_By>;
  ResidentialAddressSubCounty?: Maybe<Order_By>;
  ResidentialAddressVillage?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetName?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TradingCenter?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Mutation_Response = {
  __typename?: "rtcs_db_PH_RPT_KccaCustomerRegistrationData_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Birthdate?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  BoxNumber?: Maybe<Order_By>;
  Brn?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerApplicantType?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessType?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerType?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FormerBusinessName?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationDiplomaticId?: Maybe<Order_By>;
  IdentificationDrivingPermit?: Maybe<Order_By>;
  IdentificationEmployeeId?: Maybe<Order_By>;
  IdentificationFinancialCard?: Maybe<Order_By>;
  IdentificationNationalId?: Maybe<Order_By>;
  IdentificationNssfNumber?: Maybe<Order_By>;
  IdentificationPassportNo?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationVillageId?: Maybe<Order_By>;
  IdentificationVoterId?: Maybe<Order_By>;
  IdentificationWorkId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressBuildingName?: Maybe<Order_By>;
  PreviousBusinessAddressCounty?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrict?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParish?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressStreetName?: Maybe<Order_By>;
  PreviousBusinessAddressSubCounty?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressTradingCenter?: Maybe<Order_By>;
  PreviousBusinessAddressVillage?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCounty?: Maybe<Order_By>;
  ResidentialAddressDistrict?: Maybe<Order_By>;
  ResidentialAddressParish?: Maybe<Order_By>;
  ResidentialAddressSubCounty?: Maybe<Order_By>;
  ResidentialAddressVillage?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetName?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TradingCenter?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
export enum Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Select_Column {
  /** column name */
  AcquiredName = "AcquiredName",
  /** column name */
  Birthdate = "Birthdate",
  /** column name */
  BoxDistrictId = "BoxDistrictId",
  /** column name */
  BoxNumber = "BoxNumber",
  /** column name */
  Brn = "Brn",
  /** column name */
  BuildingName = "BuildingName",
  /** column name */
  BusinessName = "BusinessName",
  /** column name */
  CitizenshipCountryId = "CitizenshipCountryId",
  /** column name */
  Coin = "Coin",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerApplicantType = "CustomerApplicantType",
  /** column name */
  CustomerApplicantTypeId = "CustomerApplicantTypeId",
  /** column name */
  CustomerBusinessType = "CustomerBusinessType",
  /** column name */
  CustomerBusinessTypeId = "CustomerBusinessTypeId",
  /** column name */
  CustomerType = "CustomerType",
  /** column name */
  CustomerTypeId = "CustomerTypeId",
  /** column name */
  DateOfIncorporation = "DateOfIncorporation",
  /** column name */
  Email = "Email",
  /** column name */
  EntityLegalName = "EntityLegalName",
  /** column name */
  Fax = "Fax",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  FormerBusinessName = "FormerBusinessName",
  /** column name */
  GenderId = "GenderId",
  /** column name */
  HaveAlternatePrimaryContact = "HaveAlternatePrimaryContact",
  /** column name */
  HaveReference = "HaveReference",
  /** column name */
  HaveRevenueAgent = "HaveRevenueAgent",
  /** column name */
  Id = "Id",
  /** column name */
  IdentificationDiplomaticId = "IdentificationDiplomaticId",
  /** column name */
  IdentificationDrivingPermit = "IdentificationDrivingPermit",
  /** column name */
  IdentificationEmployeeId = "IdentificationEmployeeId",
  /** column name */
  IdentificationFinancialCard = "IdentificationFinancialCard",
  /** column name */
  IdentificationNationalId = "IdentificationNationalId",
  /** column name */
  IdentificationNssfNumber = "IdentificationNssfNumber",
  /** column name */
  IdentificationPassportNo = "IdentificationPassportNo",
  /** column name */
  IdentificationRefugeeId = "IdentificationRefugeeId",
  /** column name */
  IdentificationVillageId = "IdentificationVillageId",
  /** column name */
  IdentificationVoterId = "IdentificationVoterId",
  /** column name */
  IdentificationWorkId = "IdentificationWorkId",
  /** column name */
  IdentificationWorkPermit = "IdentificationWorkPermit",
  /** column name */
  IsForcedAction = "IsForcedAction",
  /** column name */
  IsMinor = "IsMinor",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  MotherMaidenName = "MotherMaidenName",
  /** column name */
  Photo = "Photo",
  /** column name */
  PhotoContent = "PhotoContent",
  /** column name */
  PhotoMime = "PhotoMime",
  /** column name */
  PhotoName = "PhotoName",
  /** column name */
  PhotoSize = "PhotoSize",
  /** column name */
  PlotNumber = "PlotNumber",
  /** column name */
  PreviousBusinessAddressBuildingName = "PreviousBusinessAddressBuildingName",
  /** column name */
  PreviousBusinessAddressCounty = "PreviousBusinessAddressCounty",
  /** column name */
  PreviousBusinessAddressCountyId = "PreviousBusinessAddressCountyId",
  /** column name */
  PreviousBusinessAddressDistrict = "PreviousBusinessAddressDistrict",
  /** column name */
  PreviousBusinessAddressDistrictId = "PreviousBusinessAddressDistrictId",
  /** column name */
  PreviousBusinessAddressParish = "PreviousBusinessAddressParish",
  /** column name */
  PreviousBusinessAddressParishId = "PreviousBusinessAddressParishId",
  /** column name */
  PreviousBusinessAddressPlotNumber = "PreviousBusinessAddressPlotNumber",
  /** column name */
  PreviousBusinessAddressStreetName = "PreviousBusinessAddressStreetName",
  /** column name */
  PreviousBusinessAddressSubCounty = "PreviousBusinessAddressSubCounty",
  /** column name */
  PreviousBusinessAddressSubCountyId = "PreviousBusinessAddressSubCountyId",
  /** column name */
  PreviousBusinessAddressTradingCenter = "PreviousBusinessAddressTradingCenter",
  /** column name */
  PreviousBusinessAddressVillage = "PreviousBusinessAddressVillage",
  /** column name */
  PreviousBusinessAddressVillageId = "PreviousBusinessAddressVillageId",
  /** column name */
  PreviousBusinessName = "PreviousBusinessName",
  /** column name */
  ResidentialAddressCounty = "ResidentialAddressCounty",
  /** column name */
  ResidentialAddressDistrict = "ResidentialAddressDistrict",
  /** column name */
  ResidentialAddressParish = "ResidentialAddressParish",
  /** column name */
  ResidentialAddressSubCounty = "ResidentialAddressSubCounty",
  /** column name */
  ResidentialAddressVillage = "ResidentialAddressVillage",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  SecondMobile = "SecondMobile",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  StreetName = "StreetName",
  /** column name */
  Surname = "Surname",
  /** column name */
  Telephone = "Telephone",
  /** column name */
  Tin = "Tin",
  /** column name */
  TitleId = "TitleId",
  /** column name */
  TradingCenter = "TradingCenter",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Set_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["String"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerApplicantType?: Maybe<Scalars["String"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessType?: Maybe<Scalars["String"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerType?: Maybe<Scalars["String"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCounty?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrict?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParish?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCounty?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillage?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["String"]>;
  ResidentialAddressCounty?: Maybe<Scalars["String"]>;
  ResidentialAddressDistrict?: Maybe<Scalars["String"]>;
  ResidentialAddressParish?: Maybe<Scalars["String"]>;
  ResidentialAddressSubCounty?: Maybe<Scalars["String"]>;
  ResidentialAddressVillage?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Stddev_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaCustomerRegistrationData_stddev_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Stddev_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaCustomerRegistrationData_stddev_pop_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Stddev_Pop_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaCustomerRegistrationData_stddev_samp_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Stddev_Samp_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Sum_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaCustomerRegistrationData_sum_fields";
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TitleId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Sum_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaCustomerRegistrationData_var_pop_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Var_Pop_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaCustomerRegistrationData_var_samp_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Var_Samp_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Variance_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaCustomerRegistrationData_variance_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Variance_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistration";
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  Address?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  ContactEmail?: Maybe<Scalars["String"]>;
  ContactLandline?: Maybe<Scalars["String"]>;
  ContactMobile1?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  OwnerFirstname?: Maybe<Scalars["String"]>;
  OwnerIndividualCompany?: Maybe<Scalars["String"]>;
  OwnerLegalSurnameMaiden?: Maybe<Scalars["String"]>;
  OwnerMiddlename?: Maybe<Scalars["String"]>;
  OwnerOthernames?: Maybe<Scalars["String"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["float8"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  ParticularsTypeOfLandInterest?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatus?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidOwner?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistrationData";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatus?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Aggregate = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistrationData_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData>;
};

/** aggregate fields of "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistrationData_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Avg_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistrationData_avg_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Avg_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_RPT_KccaPropertyRegistrationData". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  BlockNo?: Maybe<String_Comparison_Exp>;
  CamvId?: Maybe<String_Comparison_Exp>;
  CreatedBy?: Maybe<String_Comparison_Exp>;
  CreatedDate?: Maybe<String_Comparison_Exp>;
  CurrentRateableValue?: Maybe<String_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  Division?: Maybe<String_Comparison_Exp>;
  DivisionId?: Maybe<Bigint_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  EntityLegalName?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  GprsCordinateX?: Maybe<String_Comparison_Exp>;
  GprsCordinateY?: Maybe<String_Comparison_Exp>;
  GrossValue?: Maybe<String_Comparison_Exp>;
  HouseNo?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  ImgPath?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<String_Comparison_Exp>;
  Latitude?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  LocationAddress?: Maybe<String_Comparison_Exp>;
  Longitude?: Maybe<String_Comparison_Exp>;
  MapTitle?: Maybe<String_Comparison_Exp>;
  Mobile?: Maybe<String_Comparison_Exp>;
  ModifiedBy?: Maybe<String_Comparison_Exp>;
  ModifiedDate?: Maybe<String_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  Parish?: Maybe<String_Comparison_Exp>;
  ParishId?: Maybe<Bigint_Comparison_Exp>;
  ParticularsFrontages?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyNo?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatus?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatusId?: Maybe<Bigint_Comparison_Exp>;
  PropertySubType?: Maybe<String_Comparison_Exp>;
  PropertyType?: Maybe<String_Comparison_Exp>;
  PropertyTypeId?: Maybe<Bigint_Comparison_Exp>;
  RateableValue?: Maybe<String_Comparison_Exp>;
  Remisson?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  RoadOthers?: Maybe<String_Comparison_Exp>;
  SecondMobile?: Maybe<String_Comparison_Exp>;
  SerialNo?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Float8_Comparison_Exp>;
  StreetId?: Maybe<String_Comparison_Exp>;
  SubPropertyTypeId?: Maybe<Float8_Comparison_Exp>;
  Supplementary?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<String_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  VillageId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Inc_Input = {
  DivisionId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatus?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Max_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistrationData_max_fields";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatus?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Max_Order_By = {
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GprsCordinateX?: Maybe<Order_By>;
  GprsCordinateY?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LocationAddress?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatus?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertySubType?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RoadOthers?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  SerialNo?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Min_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistrationData_min_fields";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatus?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Min_Order_By = {
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GprsCordinateX?: Maybe<Order_By>;
  GprsCordinateY?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LocationAddress?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatus?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertySubType?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RoadOthers?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  SerialNo?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Mutation_Response = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistrationData_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Order_By = {
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GprsCordinateX?: Maybe<Order_By>;
  GprsCordinateY?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LocationAddress?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatus?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertySubType?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RoadOthers?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  SerialNo?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
export enum Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  CamvId = "CamvId",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CurrentRateableValue = "CurrentRateableValue",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  Division = "Division",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  Email = "Email",
  /** column name */
  EntityLegalName = "EntityLegalName",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  GprsCordinateX = "GprsCordinateX",
  /** column name */
  GprsCordinateY = "GprsCordinateY",
  /** column name */
  GrossValue = "GrossValue",
  /** column name */
  HouseNo = "HouseNo",
  /** column name */
  Id = "Id",
  /** column name */
  ImgPath = "ImgPath",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  LocationAddress = "LocationAddress",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MapTitle = "MapTitle",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  Name = "Name",
  /** column name */
  Parish = "Parish",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  ParticularsFrontages = "ParticularsFrontages",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyNo = "PropertyNo",
  /** column name */
  PropertyRentedStatus = "PropertyRentedStatus",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertySubType = "PropertySubType",
  /** column name */
  PropertyType = "PropertyType",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  RateableValue = "RateableValue",
  /** column name */
  Remisson = "Remisson",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  RoadOthers = "RoadOthers",
  /** column name */
  SecondMobile = "SecondMobile",
  /** column name */
  SerialNo = "SerialNo",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  SubPropertyTypeId = "SubPropertyTypeId",
  /** column name */
  Supplementary = "Supplementary",
  /** column name */
  Surname = "Surname",
  /** column name */
  Tin = "Tin",
  /** column name */
  Village = "Village",
  /** column name */
  VillageId = "VillageId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatus?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Stddev_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistrationData_stddev_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Stddev_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistrationData_stddev_pop_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Stddev_Pop_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistrationData_stddev_samp_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Stddev_Samp_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Sum_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistrationData_sum_fields";
  DivisionId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Sum_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistrationData_var_pop_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Var_Pop_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistrationData_var_samp_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Var_Samp_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Variance_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistrationData_variance_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Variance_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregated selection of "rtcs_db.PH_RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Aggregate = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistration_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration>;
};

/** aggregate fields of "rtcs_db.PH_RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistration_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Avg_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistration_avg_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Avg_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_RPT_KccaPropertyRegistration". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Bool_Exp = {
  AccomodationBedrooms?: Maybe<Float8_Comparison_Exp>;
  Address?: Maybe<String_Comparison_Exp>;
  Birthdate?: Maybe<String_Comparison_Exp>;
  BlockNo?: Maybe<String_Comparison_Exp>;
  BusinessName?: Maybe<String_Comparison_Exp>;
  CamvId?: Maybe<String_Comparison_Exp>;
  Coin?: Maybe<Float8_Comparison_Exp>;
  ContactEmail?: Maybe<String_Comparison_Exp>;
  ContactLandline?: Maybe<String_Comparison_Exp>;
  ContactMobile1?: Maybe<String_Comparison_Exp>;
  CreatedBy?: Maybe<String_Comparison_Exp>;
  CreatedDate?: Maybe<String_Comparison_Exp>;
  CurrentRateableValue?: Maybe<String_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  Division?: Maybe<String_Comparison_Exp>;
  DivisionId?: Maybe<Bigint_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  EntityLegalName?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  GprsCordinateX?: Maybe<String_Comparison_Exp>;
  GprsCordinateY?: Maybe<String_Comparison_Exp>;
  GrossValue?: Maybe<String_Comparison_Exp>;
  HouseNo?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  ImgPath?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<String_Comparison_Exp>;
  Latitude?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  LocationAddress?: Maybe<String_Comparison_Exp>;
  Longitude?: Maybe<String_Comparison_Exp>;
  MapTitle?: Maybe<String_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  Mobile?: Maybe<String_Comparison_Exp>;
  ModifiedBy?: Maybe<String_Comparison_Exp>;
  ModifiedDate?: Maybe<String_Comparison_Exp>;
  MotherMaidenName?: Maybe<String_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  OwnerFirstname?: Maybe<String_Comparison_Exp>;
  OwnerIndividualCompany?: Maybe<String_Comparison_Exp>;
  OwnerLegalSurnameMaiden?: Maybe<String_Comparison_Exp>;
  OwnerMiddlename?: Maybe<String_Comparison_Exp>;
  OwnerOthernames?: Maybe<String_Comparison_Exp>;
  OwnerTin?: Maybe<Float8_Comparison_Exp>;
  OwnerTitle?: Maybe<Float8_Comparison_Exp>;
  Parish?: Maybe<String_Comparison_Exp>;
  ParishId?: Maybe<Bigint_Comparison_Exp>;
  ParticularsFrontages?: Maybe<String_Comparison_Exp>;
  ParticularsTypeOfLandInterest?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyNo?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatus?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatusId?: Maybe<Bigint_Comparison_Exp>;
  PropertySubType?: Maybe<String_Comparison_Exp>;
  PropertyType?: Maybe<String_Comparison_Exp>;
  PropertyTypeId?: Maybe<Bigint_Comparison_Exp>;
  RateableValue?: Maybe<String_Comparison_Exp>;
  Remisson?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  RnidOwner?: Maybe<String_Comparison_Exp>;
  RoadOthers?: Maybe<String_Comparison_Exp>;
  SecondMobile?: Maybe<String_Comparison_Exp>;
  SerialNo?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Float8_Comparison_Exp>;
  StreetId?: Maybe<String_Comparison_Exp>;
  SubPropertyTypeId?: Maybe<Float8_Comparison_Exp>;
  Supplementary?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<String_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  VillageId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Inc_Input = {
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Insert_Input = {
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  Address?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  ContactEmail?: Maybe<Scalars["String"]>;
  ContactLandline?: Maybe<Scalars["String"]>;
  ContactMobile1?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  OwnerFirstname?: Maybe<Scalars["String"]>;
  OwnerIndividualCompany?: Maybe<Scalars["String"]>;
  OwnerLegalSurnameMaiden?: Maybe<Scalars["String"]>;
  OwnerMiddlename?: Maybe<Scalars["String"]>;
  OwnerOthernames?: Maybe<Scalars["String"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["float8"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  ParticularsTypeOfLandInterest?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatus?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidOwner?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Max_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistration_max_fields";
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  Address?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  ContactEmail?: Maybe<Scalars["String"]>;
  ContactLandline?: Maybe<Scalars["String"]>;
  ContactMobile1?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  OwnerFirstname?: Maybe<Scalars["String"]>;
  OwnerIndividualCompany?: Maybe<Scalars["String"]>;
  OwnerLegalSurnameMaiden?: Maybe<Scalars["String"]>;
  OwnerMiddlename?: Maybe<Scalars["String"]>;
  OwnerOthernames?: Maybe<Scalars["String"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["float8"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  ParticularsTypeOfLandInterest?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatus?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidOwner?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Max_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  Address?: Maybe<Order_By>;
  Birthdate?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  ContactEmail?: Maybe<Order_By>;
  ContactLandline?: Maybe<Order_By>;
  ContactMobile1?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GprsCordinateX?: Maybe<Order_By>;
  GprsCordinateY?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LocationAddress?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OwnerFirstname?: Maybe<Order_By>;
  OwnerIndividualCompany?: Maybe<Order_By>;
  OwnerLegalSurnameMaiden?: Maybe<Order_By>;
  OwnerMiddlename?: Maybe<Order_By>;
  OwnerOthernames?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  ParticularsTypeOfLandInterest?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatus?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertySubType?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidOwner?: Maybe<Order_By>;
  RoadOthers?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  SerialNo?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Min_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistration_min_fields";
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  Address?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  ContactEmail?: Maybe<Scalars["String"]>;
  ContactLandline?: Maybe<Scalars["String"]>;
  ContactMobile1?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  OwnerFirstname?: Maybe<Scalars["String"]>;
  OwnerIndividualCompany?: Maybe<Scalars["String"]>;
  OwnerLegalSurnameMaiden?: Maybe<Scalars["String"]>;
  OwnerMiddlename?: Maybe<Scalars["String"]>;
  OwnerOthernames?: Maybe<Scalars["String"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["float8"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  ParticularsTypeOfLandInterest?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatus?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidOwner?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Min_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  Address?: Maybe<Order_By>;
  Birthdate?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  ContactEmail?: Maybe<Order_By>;
  ContactLandline?: Maybe<Order_By>;
  ContactMobile1?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GprsCordinateX?: Maybe<Order_By>;
  GprsCordinateY?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LocationAddress?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OwnerFirstname?: Maybe<Order_By>;
  OwnerIndividualCompany?: Maybe<Order_By>;
  OwnerLegalSurnameMaiden?: Maybe<Order_By>;
  OwnerMiddlename?: Maybe<Order_By>;
  OwnerOthernames?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  ParticularsTypeOfLandInterest?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatus?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertySubType?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidOwner?: Maybe<Order_By>;
  RoadOthers?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  SerialNo?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Mutation_Response = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistration_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  Address?: Maybe<Order_By>;
  Birthdate?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  ContactEmail?: Maybe<Order_By>;
  ContactLandline?: Maybe<Order_By>;
  ContactMobile1?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GprsCordinateX?: Maybe<Order_By>;
  GprsCordinateY?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LocationAddress?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OwnerFirstname?: Maybe<Order_By>;
  OwnerIndividualCompany?: Maybe<Order_By>;
  OwnerLegalSurnameMaiden?: Maybe<Order_By>;
  OwnerMiddlename?: Maybe<Order_By>;
  OwnerOthernames?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  ParticularsTypeOfLandInterest?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatus?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertySubType?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidOwner?: Maybe<Order_By>;
  RoadOthers?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  SerialNo?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_RPT_KccaPropertyRegistration" */
export enum Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Select_Column {
  /** column name */
  AccomodationBedrooms = "AccomodationBedrooms",
  /** column name */
  Address = "Address",
  /** column name */
  Birthdate = "Birthdate",
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  BusinessName = "BusinessName",
  /** column name */
  CamvId = "CamvId",
  /** column name */
  Coin = "Coin",
  /** column name */
  ContactEmail = "ContactEmail",
  /** column name */
  ContactLandline = "ContactLandline",
  /** column name */
  ContactMobile1 = "ContactMobile1",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CurrentRateableValue = "CurrentRateableValue",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  Division = "Division",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  Email = "Email",
  /** column name */
  EntityLegalName = "EntityLegalName",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  GprsCordinateX = "GprsCordinateX",
  /** column name */
  GprsCordinateY = "GprsCordinateY",
  /** column name */
  GrossValue = "GrossValue",
  /** column name */
  HouseNo = "HouseNo",
  /** column name */
  Id = "Id",
  /** column name */
  ImgPath = "ImgPath",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  LocationAddress = "LocationAddress",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MapTitle = "MapTitle",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  MotherMaidenName = "MotherMaidenName",
  /** column name */
  Name = "Name",
  /** column name */
  OwnerFirstname = "OwnerFirstname",
  /** column name */
  OwnerIndividualCompany = "OwnerIndividualCompany",
  /** column name */
  OwnerLegalSurnameMaiden = "OwnerLegalSurnameMaiden",
  /** column name */
  OwnerMiddlename = "OwnerMiddlename",
  /** column name */
  OwnerOthernames = "OwnerOthernames",
  /** column name */
  OwnerTin = "OwnerTin",
  /** column name */
  OwnerTitle = "OwnerTitle",
  /** column name */
  Parish = "Parish",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  ParticularsFrontages = "ParticularsFrontages",
  /** column name */
  ParticularsTypeOfLandInterest = "ParticularsTypeOfLandInterest",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyNo = "PropertyNo",
  /** column name */
  PropertyRentedStatus = "PropertyRentedStatus",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertySubType = "PropertySubType",
  /** column name */
  PropertyType = "PropertyType",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  RateableValue = "RateableValue",
  /** column name */
  Remisson = "Remisson",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  RnidOwner = "RnidOwner",
  /** column name */
  RoadOthers = "RoadOthers",
  /** column name */
  SecondMobile = "SecondMobile",
  /** column name */
  SerialNo = "SerialNo",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  SubPropertyTypeId = "SubPropertyTypeId",
  /** column name */
  Supplementary = "Supplementary",
  /** column name */
  Surname = "Surname",
  /** column name */
  Tin = "Tin",
  /** column name */
  Village = "Village",
  /** column name */
  VillageId = "VillageId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Set_Input = {
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  Address?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  ContactEmail?: Maybe<Scalars["String"]>;
  ContactLandline?: Maybe<Scalars["String"]>;
  ContactMobile1?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  OwnerFirstname?: Maybe<Scalars["String"]>;
  OwnerIndividualCompany?: Maybe<Scalars["String"]>;
  OwnerLegalSurnameMaiden?: Maybe<Scalars["String"]>;
  OwnerMiddlename?: Maybe<Scalars["String"]>;
  OwnerOthernames?: Maybe<Scalars["String"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["float8"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  ParticularsTypeOfLandInterest?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatus?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidOwner?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Stddev_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistration_stddev_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Stddev_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistration_stddev_pop_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Stddev_Pop_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistration_stddev_samp_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Stddev_Samp_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Sum_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistration_sum_fields";
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Sum_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistration_var_pop_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Var_Pop_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistration_var_samp_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Var_Samp_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Variance_Fields = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistration_variance_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Variance_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualBalanceSheet";
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  ApplicationFund?: Maybe<Scalars["String"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetCurrentAsset?: Maybe<Scalars["String"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  OthrLiability?: Maybe<Scalars["String"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProfitLossAccount?: Maybe<Scalars["String"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  RtnId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnId?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAccntRcvdDebtors?: Maybe<Scalars["String"]>;
  TotAssetLoanAdvance?: Maybe<Scalars["String"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotCurrLiability?: Maybe<Scalars["String"]>;
  TotCurrentAsset?: Maybe<Scalars["String"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLiabilityProv?: Maybe<Scalars["String"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalLoanFund?: Maybe<Scalars["String"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureCreditorLoan?: Maybe<Scalars["String"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Aggregate = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualBalanceSheet_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet>;
};

/** aggregate fields of "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualBalanceSheet_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Avg_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualBalanceSheet_avg_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Avg_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_RPT_NonIndividualBalanceSheet". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Bool_Exp = {
  AccntRcvdOthers?: Maybe<Float8_Comparison_Exp>;
  AccntRcvdOverSix?: Maybe<Float8_Comparison_Exp>;
  AdvanceCorporate?: Maybe<Float8_Comparison_Exp>;
  AdvanceRecoverable?: Maybe<Float8_Comparison_Exp>;
  ApplicationFund?: Maybe<String_Comparison_Exp>;
  BalRevenueAuth?: Maybe<Float8_Comparison_Exp>;
  BankBalance?: Maybe<Float8_Comparison_Exp>;
  BankOverDraft?: Maybe<Float8_Comparison_Exp>;
  CnsmPckInventory?: Maybe<Float8_Comparison_Exp>;
  Debenture?: Maybe<Float8_Comparison_Exp>;
  DeferTaxLiability?: Maybe<Float8_Comparison_Exp>;
  DeferredAsset?: Maybe<Float8_Comparison_Exp>;
  Deposits?: Maybe<Float8_Comparison_Exp>;
  Depreciation?: Maybe<Float8_Comparison_Exp>;
  EquityShare?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FinishTradeGoods?: Maybe<Float8_Comparison_Exp>;
  FixedDeposit?: Maybe<Float8_Comparison_Exp>;
  FurnitureFixture?: Maybe<Float8_Comparison_Exp>;
  GeneralReserve?: Maybe<Float8_Comparison_Exp>;
  GovtSecurities?: Maybe<Float8_Comparison_Exp>;
  HandCash?: Maybe<Float8_Comparison_Exp>;
  IncomeTaxProv?: Maybe<Float8_Comparison_Exp>;
  IntLeaseAsset?: Maybe<Float8_Comparison_Exp>;
  IntangibleAssets?: Maybe<Float8_Comparison_Exp>;
  IsAmendment?: Maybe<String_Comparison_Exp>;
  LandBuild?: Maybe<Float8_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  LessDrawings?: Maybe<Float8_Comparison_Exp>;
  LiableLeaseAsset?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  LoanSubCompany?: Maybe<Float8_Comparison_Exp>;
  MotorVehicles?: Maybe<Float8_Comparison_Exp>;
  NetCurrentAsset?: Maybe<String_Comparison_Exp>;
  NetFixedAsset?: Maybe<Float8_Comparison_Exp>;
  OtherInvestments?: Maybe<Float8_Comparison_Exp>;
  OtherProvision?: Maybe<Float8_Comparison_Exp>;
  OtherReserve?: Maybe<Float8_Comparison_Exp>;
  OthrCurrentAsset?: Maybe<Float8_Comparison_Exp>;
  OthrDeprecAsset?: Maybe<Float8_Comparison_Exp>;
  OthrLiability?: Maybe<String_Comparison_Exp>;
  PlantMachine?: Maybe<Float8_Comparison_Exp>;
  Prepayments?: Maybe<Float8_Comparison_Exp>;
  ProfitLossAccount?: Maybe<String_Comparison_Exp>;
  ProprietorCapital?: Maybe<Float8_Comparison_Exp>;
  ProvForBadDebts?: Maybe<Float8_Comparison_Exp>;
  RawMtrlInventory?: Maybe<Float8_Comparison_Exp>;
  ReturnType?: Maybe<String_Comparison_Exp>;
  RevaluationReserve?: Maybe<Float8_Comparison_Exp>;
  RtnId?: Maybe<String_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnNoRtnId?: Maybe<String_Comparison_Exp>;
  RtnPeriodYear?: Maybe<String_Comparison_Exp>;
  SecureDebtIssued?: Maybe<Float8_Comparison_Exp>;
  SecureLoanBank?: Maybe<Float8_Comparison_Exp>;
  SecureLoanOther?: Maybe<Float8_Comparison_Exp>;
  SecureLoanParties?: Maybe<Float8_Comparison_Exp>;
  SecureTotLoan?: Maybe<String_Comparison_Exp>;
  SourceFund?: Maybe<String_Comparison_Exp>;
  StockInProcess?: Maybe<Float8_Comparison_Exp>;
  SundryCreditors?: Maybe<Float8_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  TotAccntRcvdDebtors?: Maybe<String_Comparison_Exp>;
  TotAssetLoanAdvance?: Maybe<String_Comparison_Exp>;
  TotBalanceAvail?: Maybe<Float8_Comparison_Exp>;
  TotCurrLiability?: Maybe<String_Comparison_Exp>;
  TotCurrentAsset?: Maybe<String_Comparison_Exp>;
  TotFixedAsset?: Maybe<Float8_Comparison_Exp>;
  TotInventory?: Maybe<Float8_Comparison_Exp>;
  TotLiabilityProv?: Maybe<String_Comparison_Exp>;
  TotLoanAdvance?: Maybe<Float8_Comparison_Exp>;
  TotTradeInvestment?: Maybe<Float8_Comparison_Exp>;
  TotalLoanFund?: Maybe<String_Comparison_Exp>;
  TotalPropFund?: Maybe<String_Comparison_Exp>;
  TotalProvision?: Maybe<Float8_Comparison_Exp>;
  TranslationReserve?: Maybe<Float8_Comparison_Exp>;
  UnpaidMatureDebts?: Maybe<Float8_Comparison_Exp>;
  UnsecureCreditorLoan?: Maybe<String_Comparison_Exp>;
  UnsecureLoanBank?: Maybe<Float8_Comparison_Exp>;
  UnsecureLoanOther?: Maybe<Float8_Comparison_Exp>;
  UnsecureLoanParties?: Maybe<Float8_Comparison_Exp>;
  UnsecureTotLoan?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Inc_Input = {
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Insert_Input = {
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  ApplicationFund?: Maybe<Scalars["String"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetCurrentAsset?: Maybe<Scalars["String"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  OthrLiability?: Maybe<Scalars["String"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProfitLossAccount?: Maybe<Scalars["String"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  RtnId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnId?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAccntRcvdDebtors?: Maybe<Scalars["String"]>;
  TotAssetLoanAdvance?: Maybe<Scalars["String"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotCurrLiability?: Maybe<Scalars["String"]>;
  TotCurrentAsset?: Maybe<Scalars["String"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLiabilityProv?: Maybe<Scalars["String"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalLoanFund?: Maybe<Scalars["String"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureCreditorLoan?: Maybe<Scalars["String"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Max_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualBalanceSheet_max_fields";
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  ApplicationFund?: Maybe<Scalars["String"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetCurrentAsset?: Maybe<Scalars["String"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  OthrLiability?: Maybe<Scalars["String"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProfitLossAccount?: Maybe<Scalars["String"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  RtnId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnId?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAccntRcvdDebtors?: Maybe<Scalars["String"]>;
  TotAssetLoanAdvance?: Maybe<Scalars["String"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotCurrLiability?: Maybe<Scalars["String"]>;
  TotCurrentAsset?: Maybe<Scalars["String"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLiabilityProv?: Maybe<Scalars["String"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalLoanFund?: Maybe<Scalars["String"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureCreditorLoan?: Maybe<Scalars["String"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Max_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  ApplicationFund?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetCurrentAsset?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  OthrLiability?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProfitLossAccount?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  RtnId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnId?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  SecureTotLoan?: Maybe<Order_By>;
  SourceFund?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotAccntRcvdDebtors?: Maybe<Order_By>;
  TotAssetLoanAdvance?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotCurrLiability?: Maybe<Order_By>;
  TotCurrentAsset?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLiabilityProv?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalLoanFund?: Maybe<Order_By>;
  TotalPropFund?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureCreditorLoan?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
  UnsecureTotLoan?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Min_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualBalanceSheet_min_fields";
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  ApplicationFund?: Maybe<Scalars["String"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetCurrentAsset?: Maybe<Scalars["String"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  OthrLiability?: Maybe<Scalars["String"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProfitLossAccount?: Maybe<Scalars["String"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  RtnId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnId?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAccntRcvdDebtors?: Maybe<Scalars["String"]>;
  TotAssetLoanAdvance?: Maybe<Scalars["String"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotCurrLiability?: Maybe<Scalars["String"]>;
  TotCurrentAsset?: Maybe<Scalars["String"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLiabilityProv?: Maybe<Scalars["String"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalLoanFund?: Maybe<Scalars["String"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureCreditorLoan?: Maybe<Scalars["String"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Min_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  ApplicationFund?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetCurrentAsset?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  OthrLiability?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProfitLossAccount?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  RtnId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnId?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  SecureTotLoan?: Maybe<Order_By>;
  SourceFund?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotAccntRcvdDebtors?: Maybe<Order_By>;
  TotAssetLoanAdvance?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotCurrLiability?: Maybe<Order_By>;
  TotCurrentAsset?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLiabilityProv?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalLoanFund?: Maybe<Order_By>;
  TotalPropFund?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureCreditorLoan?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
  UnsecureTotLoan?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Mutation_Response = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualBalanceSheet_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  ApplicationFund?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetCurrentAsset?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  OthrLiability?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProfitLossAccount?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  RtnId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnId?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  SecureTotLoan?: Maybe<Order_By>;
  SourceFund?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotAccntRcvdDebtors?: Maybe<Order_By>;
  TotAssetLoanAdvance?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotCurrLiability?: Maybe<Order_By>;
  TotCurrentAsset?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLiabilityProv?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalLoanFund?: Maybe<Order_By>;
  TotalPropFund?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureCreditorLoan?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
  UnsecureTotLoan?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
export enum Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Select_Column {
  /** column name */
  AccntRcvdOthers = "AccntRcvdOthers",
  /** column name */
  AccntRcvdOverSix = "AccntRcvdOverSix",
  /** column name */
  AdvanceCorporate = "AdvanceCorporate",
  /** column name */
  AdvanceRecoverable = "AdvanceRecoverable",
  /** column name */
  ApplicationFund = "ApplicationFund",
  /** column name */
  BalRevenueAuth = "BalRevenueAuth",
  /** column name */
  BankBalance = "BankBalance",
  /** column name */
  BankOverDraft = "BankOverDraft",
  /** column name */
  CnsmPckInventory = "CnsmPckInventory",
  /** column name */
  Debenture = "Debenture",
  /** column name */
  DeferTaxLiability = "DeferTaxLiability",
  /** column name */
  DeferredAsset = "DeferredAsset",
  /** column name */
  Deposits = "Deposits",
  /** column name */
  Depreciation = "Depreciation",
  /** column name */
  EquityShare = "EquityShare",
  /** column name */
  File = "File",
  /** column name */
  FinishTradeGoods = "FinishTradeGoods",
  /** column name */
  FixedDeposit = "FixedDeposit",
  /** column name */
  FurnitureFixture = "FurnitureFixture",
  /** column name */
  GeneralReserve = "GeneralReserve",
  /** column name */
  GovtSecurities = "GovtSecurities",
  /** column name */
  HandCash = "HandCash",
  /** column name */
  IncomeTaxProv = "IncomeTaxProv",
  /** column name */
  IntLeaseAsset = "IntLeaseAsset",
  /** column name */
  IntangibleAssets = "IntangibleAssets",
  /** column name */
  IsAmendment = "IsAmendment",
  /** column name */
  LandBuild = "LandBuild",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  LessDrawings = "LessDrawings",
  /** column name */
  LiableLeaseAsset = "LiableLeaseAsset",
  /** column name */
  Line = "Line",
  /** column name */
  LoanSubCompany = "LoanSubCompany",
  /** column name */
  MotorVehicles = "MotorVehicles",
  /** column name */
  NetCurrentAsset = "NetCurrentAsset",
  /** column name */
  NetFixedAsset = "NetFixedAsset",
  /** column name */
  OtherInvestments = "OtherInvestments",
  /** column name */
  OtherProvision = "OtherProvision",
  /** column name */
  OtherReserve = "OtherReserve",
  /** column name */
  OthrCurrentAsset = "OthrCurrentAsset",
  /** column name */
  OthrDeprecAsset = "OthrDeprecAsset",
  /** column name */
  OthrLiability = "OthrLiability",
  /** column name */
  PlantMachine = "PlantMachine",
  /** column name */
  Prepayments = "Prepayments",
  /** column name */
  ProfitLossAccount = "ProfitLossAccount",
  /** column name */
  ProprietorCapital = "ProprietorCapital",
  /** column name */
  ProvForBadDebts = "ProvForBadDebts",
  /** column name */
  RawMtrlInventory = "RawMtrlInventory",
  /** column name */
  ReturnType = "ReturnType",
  /** column name */
  RevaluationReserve = "RevaluationReserve",
  /** column name */
  RtnId = "RtnId",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnNoRtnId = "RtnNoRtnId",
  /** column name */
  RtnPeriodYear = "RtnPeriodYear",
  /** column name */
  SecureDebtIssued = "SecureDebtIssued",
  /** column name */
  SecureLoanBank = "SecureLoanBank",
  /** column name */
  SecureLoanOther = "SecureLoanOther",
  /** column name */
  SecureLoanParties = "SecureLoanParties",
  /** column name */
  SecureTotLoan = "SecureTotLoan",
  /** column name */
  SourceFund = "SourceFund",
  /** column name */
  StockInProcess = "StockInProcess",
  /** column name */
  SundryCreditors = "SundryCreditors",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  TotAccntRcvdDebtors = "TotAccntRcvdDebtors",
  /** column name */
  TotAssetLoanAdvance = "TotAssetLoanAdvance",
  /** column name */
  TotBalanceAvail = "TotBalanceAvail",
  /** column name */
  TotCurrLiability = "TotCurrLiability",
  /** column name */
  TotCurrentAsset = "TotCurrentAsset",
  /** column name */
  TotFixedAsset = "TotFixedAsset",
  /** column name */
  TotInventory = "TotInventory",
  /** column name */
  TotLiabilityProv = "TotLiabilityProv",
  /** column name */
  TotLoanAdvance = "TotLoanAdvance",
  /** column name */
  TotTradeInvestment = "TotTradeInvestment",
  /** column name */
  TotalLoanFund = "TotalLoanFund",
  /** column name */
  TotalPropFund = "TotalPropFund",
  /** column name */
  TotalProvision = "TotalProvision",
  /** column name */
  TranslationReserve = "TranslationReserve",
  /** column name */
  UnpaidMatureDebts = "UnpaidMatureDebts",
  /** column name */
  UnsecureCreditorLoan = "UnsecureCreditorLoan",
  /** column name */
  UnsecureLoanBank = "UnsecureLoanBank",
  /** column name */
  UnsecureLoanOther = "UnsecureLoanOther",
  /** column name */
  UnsecureLoanParties = "UnsecureLoanParties",
  /** column name */
  UnsecureTotLoan = "UnsecureTotLoan",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Set_Input = {
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  ApplicationFund?: Maybe<Scalars["String"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetCurrentAsset?: Maybe<Scalars["String"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  OthrLiability?: Maybe<Scalars["String"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProfitLossAccount?: Maybe<Scalars["String"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  RtnId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnId?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAccntRcvdDebtors?: Maybe<Scalars["String"]>;
  TotAssetLoanAdvance?: Maybe<Scalars["String"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotCurrLiability?: Maybe<Scalars["String"]>;
  TotCurrentAsset?: Maybe<Scalars["String"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLiabilityProv?: Maybe<Scalars["String"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalLoanFund?: Maybe<Scalars["String"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureCreditorLoan?: Maybe<Scalars["String"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Stddev_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualBalanceSheet_stddev_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Stddev_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualBalanceSheet_stddev_pop_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Stddev_Pop_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualBalanceSheet_stddev_samp_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Stddev_Samp_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Sum_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualBalanceSheet_sum_fields";
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Sum_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualBalanceSheet_var_pop_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Var_Pop_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualBalanceSheet_var_samp_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Var_Samp_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Variance_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualBalanceSheet_variance_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Variance_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  RiskCode?: Maybe<Scalars["String"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** aggregated selection of "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Aggregate = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk>;
};

/** aggregate fields of "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Aggregate_Fields =
  {
    __typename?: "rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk_aggregate_fields";
    avg?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Avg_Fields>;
    count?: Maybe<Scalars["Int"]>;
    max?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Max_Fields>;
    min?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Min_Fields>;
    stddev?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Stddev_Fields>;
    stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Stddev_Samp_Fields>;
    sum?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Sum_Fields>;
    var_pop?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Var_Pop_Fields>;
    var_samp?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Var_Samp_Fields>;
    variance?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Variance_Fields>;
  };

/** aggregate fields of "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Aggregate_Order_By =
  {
    avg?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Max_Order_By>;
    min?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Min_Order_By>;
    stddev?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Stddev_Order_By>;
    stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Stddev_Samp_Order_By>;
    sum?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Sum_Order_By>;
    var_pop?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Var_Pop_Order_By>;
    var_samp?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Var_Samp_Order_By>;
    variance?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Variance_Order_By>;
  };

/** input type for inserting array relation for remote table "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Avg_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk_avg_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Mobilenumber?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  RiskScore?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumofadjustedincome?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Tinno?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Avg_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Tinno?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** Boolean expression to filter rows from the table "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Bool_Exp = {
  Averagerateperrentedproperty?: Maybe<Float8_Comparison_Exp>;
  Compliancecategory?: Maybe<String_Comparison_Exp>;
  Difference?: Maybe<Float8_Comparison_Exp>;
  Emailid?: Maybe<String_Comparison_Exp>;
  Formerbusinessname?: Maybe<String_Comparison_Exp>;
  Grossrentalincome?: Maybe<Float8_Comparison_Exp>;
  Holdingcompname?: Maybe<String_Comparison_Exp>;
  Holdingcomptin?: Maybe<String_Comparison_Exp>;
  Isamendment?: Maybe<String_Comparison_Exp>;
  Isincomesrcbsns?: Maybe<String_Comparison_Exp>;
  Isincomesrcprpty?: Maybe<String_Comparison_Exp>;
  Isincomesrcrental?: Maybe<String_Comparison_Exp>;
  Kccacustomerid?: Maybe<String_Comparison_Exp>;
  Kccaname?: Maybe<String_Comparison_Exp>;
  Matchscore?: Maybe<Float8_Comparison_Exp>;
  Mobilenumber?: Maybe<Float8_Comparison_Exp>;
  Numproperties?: Maybe<Float8_Comparison_Exp>;
  Numrentedproperties?: Maybe<Float8_Comparison_Exp>;
  Orgsubtype?: Maybe<String_Comparison_Exp>;
  Orgtype?: Maybe<String_Comparison_Exp>;
  Percentagedifference?: Maybe<Float8_Comparison_Exp>;
  Rentincincome?: Maybe<Float8_Comparison_Exp>;
  RiskCode?: Maybe<String_Comparison_Exp>;
  RiskScore?: Maybe<Bigint_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Rtnno?: Maybe<String_Comparison_Exp>;
  Rtnperiodyear?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  Sumgrossvalue?: Maybe<Float8_Comparison_Exp>;
  Sumofadjustedincome?: Maybe<Float8_Comparison_Exp>;
  Sumrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Taxpayerassumed?: Maybe<String_Comparison_Exp>;
  Tinno?: Maybe<Float8_Comparison_Exp>;
  Tradingcenter?: Maybe<String_Comparison_Exp>;
  Tradingname?: Maybe<String_Comparison_Exp>;
  Uraname?: Maybe<String_Comparison_Exp>;
  Uratinno?: Maybe<Float8_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Inc_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Insert_Input =
  {
    Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
    Compliancecategory?: Maybe<Scalars["String"]>;
    Difference?: Maybe<Scalars["float8"]>;
    Emailid?: Maybe<Scalars["String"]>;
    Formerbusinessname?: Maybe<Scalars["String"]>;
    Grossrentalincome?: Maybe<Scalars["float8"]>;
    Holdingcompname?: Maybe<Scalars["String"]>;
    Holdingcomptin?: Maybe<Scalars["String"]>;
    Isamendment?: Maybe<Scalars["String"]>;
    Isincomesrcbsns?: Maybe<Scalars["String"]>;
    Isincomesrcprpty?: Maybe<Scalars["String"]>;
    Isincomesrcrental?: Maybe<Scalars["String"]>;
    Kccacustomerid?: Maybe<Scalars["String"]>;
    Kccaname?: Maybe<Scalars["String"]>;
    Matchscore?: Maybe<Scalars["float8"]>;
    Mobilenumber?: Maybe<Scalars["float8"]>;
    Numproperties?: Maybe<Scalars["float8"]>;
    Numrentedproperties?: Maybe<Scalars["float8"]>;
    Orgsubtype?: Maybe<Scalars["String"]>;
    Orgtype?: Maybe<Scalars["String"]>;
    Percentagedifference?: Maybe<Scalars["float8"]>;
    Rentincincome?: Maybe<Scalars["float8"]>;
    RiskCode?: Maybe<Scalars["String"]>;
    RiskScore?: Maybe<Scalars["bigint"]>;
    Rnid?: Maybe<Scalars["String"]>;
    Rtnno?: Maybe<Scalars["String"]>;
    Rtnperiodyear?: Maybe<Scalars["String"]>;
    Source?: Maybe<Scalars["String"]>;
    Sumgrossvalue?: Maybe<Scalars["float8"]>;
    Sumofadjustedincome?: Maybe<Scalars["float8"]>;
    Sumrateablevalue?: Maybe<Scalars["float8"]>;
    Taxpayerassumed?: Maybe<Scalars["String"]>;
    Tinno?: Maybe<Scalars["float8"]>;
    Tradingcenter?: Maybe<Scalars["String"]>;
    Tradingname?: Maybe<Scalars["String"]>;
    Uraname?: Maybe<Scalars["String"]>;
    Uratinno?: Maybe<Scalars["float8"]>;
  };

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Max_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk_max_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  RiskCode?: Maybe<Scalars["String"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** order by max() on columns of table "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Max_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Compliancecategory?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Emailid?: Maybe<Order_By>;
    Formerbusinessname?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Holdingcompname?: Maybe<Order_By>;
    Holdingcomptin?: Maybe<Order_By>;
    Isamendment?: Maybe<Order_By>;
    Isincomesrcbsns?: Maybe<Order_By>;
    Isincomesrcprpty?: Maybe<Order_By>;
    Isincomesrcrental?: Maybe<Order_By>;
    Kccacustomerid?: Maybe<Order_By>;
    Kccaname?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Orgsubtype?: Maybe<Order_By>;
    Orgtype?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    RiskCode?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Rnid?: Maybe<Order_By>;
    Rtnno?: Maybe<Order_By>;
    Rtnperiodyear?: Maybe<Order_By>;
    Source?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Taxpayerassumed?: Maybe<Order_By>;
    Tinno?: Maybe<Order_By>;
    Tradingcenter?: Maybe<Order_By>;
    Tradingname?: Maybe<Order_By>;
    Uraname?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Min_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk_min_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  RiskCode?: Maybe<Scalars["String"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** order by min() on columns of table "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Min_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Compliancecategory?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Emailid?: Maybe<Order_By>;
    Formerbusinessname?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Holdingcompname?: Maybe<Order_By>;
    Holdingcomptin?: Maybe<Order_By>;
    Isamendment?: Maybe<Order_By>;
    Isincomesrcbsns?: Maybe<Order_By>;
    Isincomesrcprpty?: Maybe<Order_By>;
    Isincomesrcrental?: Maybe<Order_By>;
    Kccacustomerid?: Maybe<Order_By>;
    Kccaname?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Orgsubtype?: Maybe<Order_By>;
    Orgtype?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    RiskCode?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Rnid?: Maybe<Order_By>;
    Rtnno?: Maybe<Order_By>;
    Rtnperiodyear?: Maybe<Order_By>;
    Source?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Taxpayerassumed?: Maybe<Order_By>;
    Tinno?: Maybe<Order_By>;
    Tradingcenter?: Maybe<Order_By>;
    Tradingname?: Maybe<Order_By>;
    Uraname?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** response of any mutation on the table "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Mutation_Response =
  {
    __typename?: "rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk_mutation_response";
    /** number of affected rows by the mutation */
    affected_rows: Scalars["Int"];
    /** data of the affected rows by the mutation */
    returning: Array<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk>;
  };

/** input type for inserting object relation for remote table "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  Formerbusinessname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Holdingcompname?: Maybe<Order_By>;
  Holdingcomptin?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Kccaname?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Orgsubtype?: Maybe<Order_By>;
  Orgtype?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  RiskCode?: Maybe<Order_By>;
  RiskScore?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Taxpayerassumed?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Tradingcenter?: Maybe<Order_By>;
  Tradingname?: Maybe<Order_By>;
  Uraname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
export enum Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Select_Column {
  /** column name */
  Averagerateperrentedproperty = "Averagerateperrentedproperty",
  /** column name */
  Compliancecategory = "Compliancecategory",
  /** column name */
  Difference = "Difference",
  /** column name */
  Emailid = "Emailid",
  /** column name */
  Formerbusinessname = "Formerbusinessname",
  /** column name */
  Grossrentalincome = "Grossrentalincome",
  /** column name */
  Holdingcompname = "Holdingcompname",
  /** column name */
  Holdingcomptin = "Holdingcomptin",
  /** column name */
  Isamendment = "Isamendment",
  /** column name */
  Isincomesrcbsns = "Isincomesrcbsns",
  /** column name */
  Isincomesrcprpty = "Isincomesrcprpty",
  /** column name */
  Isincomesrcrental = "Isincomesrcrental",
  /** column name */
  Kccacustomerid = "Kccacustomerid",
  /** column name */
  Kccaname = "Kccaname",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Mobilenumber = "Mobilenumber",
  /** column name */
  Numproperties = "Numproperties",
  /** column name */
  Numrentedproperties = "Numrentedproperties",
  /** column name */
  Orgsubtype = "Orgsubtype",
  /** column name */
  Orgtype = "Orgtype",
  /** column name */
  Percentagedifference = "Percentagedifference",
  /** column name */
  Rentincincome = "Rentincincome",
  /** column name */
  RiskCode = "RiskCode",
  /** column name */
  RiskScore = "RiskScore",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Rtnno = "Rtnno",
  /** column name */
  Rtnperiodyear = "Rtnperiodyear",
  /** column name */
  Source = "Source",
  /** column name */
  Sumgrossvalue = "Sumgrossvalue",
  /** column name */
  Sumofadjustedincome = "Sumofadjustedincome",
  /** column name */
  Sumrateablevalue = "Sumrateablevalue",
  /** column name */
  Taxpayerassumed = "Taxpayerassumed",
  /** column name */
  Tinno = "Tinno",
  /** column name */
  Tradingcenter = "Tradingcenter",
  /** column name */
  Tradingname = "Tradingname",
  /** column name */
  Uraname = "Uraname",
  /** column name */
  Uratinno = "Uratinno",
}

/** input type for updating data in table "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Set_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  RiskCode?: Maybe<Scalars["String"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Stddev_Fields =
  {
    __typename?: "rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk_stddev_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Mobilenumber?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Rentincincome?: Maybe<Scalars["Float"]>;
    RiskScore?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumofadjustedincome?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
    Tinno?: Maybe<Scalars["Float"]>;
    Uratinno?: Maybe<Scalars["Float"]>;
  };

/** order by stddev() on columns of table "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Stddev_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Tinno?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Stddev_Pop_Fields =
  {
    __typename?: "rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk_stddev_pop_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Mobilenumber?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Rentincincome?: Maybe<Scalars["Float"]>;
    RiskScore?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumofadjustedincome?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
    Tinno?: Maybe<Scalars["Float"]>;
    Uratinno?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_pop() on columns of table "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Stddev_Pop_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Tinno?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Stddev_Samp_Fields =
  {
    __typename?: "rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk_stddev_samp_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Mobilenumber?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Rentincincome?: Maybe<Scalars["Float"]>;
    RiskScore?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumofadjustedincome?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
    Tinno?: Maybe<Scalars["Float"]>;
    Uratinno?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_samp() on columns of table "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Stddev_Samp_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Tinno?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Sum_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk_sum_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Sum_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Tinno?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Var_Pop_Fields =
  {
    __typename?: "rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk_var_pop_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Mobilenumber?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Rentincincome?: Maybe<Scalars["Float"]>;
    RiskScore?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumofadjustedincome?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
    Tinno?: Maybe<Scalars["Float"]>;
    Uratinno?: Maybe<Scalars["Float"]>;
  };

/** order by var_pop() on columns of table "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Var_Pop_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Tinno?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Var_Samp_Fields =
  {
    __typename?: "rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk_var_samp_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Mobilenumber?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Rentincincome?: Maybe<Scalars["Float"]>;
    RiskScore?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumofadjustedincome?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
    Tinno?: Maybe<Scalars["Float"]>;
    Uratinno?: Maybe<Scalars["Float"]>;
  };

/** order by var_samp() on columns of table "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Var_Samp_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Tinno?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Variance_Fields =
  {
    __typename?: "rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk_variance_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Mobilenumber?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Rentincincome?: Maybe<Scalars["Float"]>;
    RiskScore?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumofadjustedincome?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
    Tinno?: Maybe<Scalars["Float"]>;
    Uratinno?: Maybe<Scalars["Float"]>;
  };

/** order by variance() on columns of table "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Variance_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Tinno?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** columns and relationships of "rtcs_db.PH_RPT_NonIndividualComposite" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualComposite";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["float8"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_RPT_NonIndividualComposite" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Aggregate = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualComposite_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rpt_NonIndividualComposite>;
};

/** aggregate fields of "rtcs_db.PH_RPT_NonIndividualComposite" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualComposite_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_RPT_NonIndividualComposite" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_RPT_NonIndividualComposite" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_RPT_NonIndividualComposite" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Avg_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualComposite_avg_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  IdentificationRefugeeId?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_RPT_NonIndividualComposite" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Avg_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_RPT_NonIndividualComposite". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Bool_Exp = {
  ActvtyDesc?: Maybe<String_Comparison_Exp>;
  AdditionalOwners?: Maybe<Float8_Comparison_Exp>;
  AdditionalProperties?: Maybe<Float8_Comparison_Exp>;
  AuthctcdSurname?: Maybe<String_Comparison_Exp>;
  AuthctcdTin?: Maybe<String_Comparison_Exp>;
  Brn?: Maybe<String_Comparison_Exp>;
  BsnsName?: Maybe<String_Comparison_Exp>;
  BsnsaddrLocalCouncil?: Maybe<String_Comparison_Exp>;
  BuildingName?: Maybe<String_Comparison_Exp>;
  BusinessStartDt?: Maybe<String_Comparison_Exp>;
  Coin?: Maybe<Float8_Comparison_Exp>;
  CreatedBy?: Maybe<Float8_Comparison_Exp>;
  CreatedDate?: Maybe<String_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  DateOfIncorporation?: Maybe<String_Comparison_Exp>;
  EmailId?: Maybe<String_Comparison_Exp>;
  Fax?: Maybe<String_Comparison_Exp>;
  FormerBusinessName?: Maybe<String_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  HoldingCompName?: Maybe<String_Comparison_Exp>;
  HoldingCompTin?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  IdentificationRefugeeId?: Maybe<Float8_Comparison_Exp>;
  IdentificationWorkPermit?: Maybe<String_Comparison_Exp>;
  KccaName?: Maybe<String_Comparison_Exp>;
  KccaTin?: Maybe<String_Comparison_Exp>;
  LandlineNumber?: Maybe<Float8_Comparison_Exp>;
  MainActivity?: Maybe<String_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Bigint_Comparison_Exp>;
  MatchScoreAttributes?: Maybe<String_Comparison_Exp>;
  MobileNumber?: Maybe<String_Comparison_Exp>;
  NumProperties?: Maybe<Bigint_Comparison_Exp>;
  OrgSubType?: Maybe<String_Comparison_Exp>;
  OrgSubTypeDesc?: Maybe<String_Comparison_Exp>;
  OrgType?: Maybe<String_Comparison_Exp>;
  Photo?: Maybe<Float8_Comparison_Exp>;
  PhotoContent?: Maybe<Float8_Comparison_Exp>;
  PhotoMime?: Maybe<Float8_Comparison_Exp>;
  PhotoName?: Maybe<Float8_Comparison_Exp>;
  PhotoSize?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressPlotNumber?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessName?: Maybe<Float8_Comparison_Exp>;
  RefEmailId?: Maybe<String_Comparison_Exp>;
  RefFirstName?: Maybe<String_Comparison_Exp>;
  RefLandlineNumber?: Maybe<String_Comparison_Exp>;
  RefMobileNumber?: Maybe<String_Comparison_Exp>;
  RefPrsnDesgn?: Maybe<String_Comparison_Exp>;
  RefSurname?: Maybe<String_Comparison_Exp>;
  RefTin?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Float8_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TaxPayerType?: Maybe<String_Comparison_Exp>;
  TradingName?: Maybe<String_Comparison_Exp>;
  UraName?: Maybe<String_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_RPT_NonIndividualComposite" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Inc_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  IdentificationRefugeeId?: Maybe<Scalars["float8"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_RPT_NonIndividualComposite" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Insert_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["float8"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Max_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualComposite_max_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["float8"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_RPT_NonIndividualComposite" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Max_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  Brn?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BsnsaddrLocalCouncil?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessStartDt?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  FormerBusinessName?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  HoldingCompName?: Maybe<Order_By>;
  HoldingCompTin?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  KccaName?: Maybe<Order_By>;
  KccaTin?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  OrgSubType?: Maybe<Order_By>;
  OrgSubTypeDesc?: Maybe<Order_By>;
  OrgType?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TradingName?: Maybe<Order_By>;
  UraName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Min_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualComposite_min_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["float8"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_RPT_NonIndividualComposite" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Min_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  Brn?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BsnsaddrLocalCouncil?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessStartDt?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  FormerBusinessName?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  HoldingCompName?: Maybe<Order_By>;
  HoldingCompTin?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  KccaName?: Maybe<Order_By>;
  KccaTin?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  OrgSubType?: Maybe<Order_By>;
  OrgSubTypeDesc?: Maybe<Order_By>;
  OrgType?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TradingName?: Maybe<Order_By>;
  UraName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_RPT_NonIndividualComposite" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Mutation_Response = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualComposite_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rpt_NonIndividualComposite>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_RPT_NonIndividualComposite" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rpt_NonIndividualComposite_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_RPT_NonIndividualComposite" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  Brn?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BsnsaddrLocalCouncil?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessStartDt?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  FormerBusinessName?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  HoldingCompName?: Maybe<Order_By>;
  HoldingCompTin?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  KccaName?: Maybe<Order_By>;
  KccaTin?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  OrgSubType?: Maybe<Order_By>;
  OrgSubTypeDesc?: Maybe<Order_By>;
  OrgType?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TradingName?: Maybe<Order_By>;
  UraName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_RPT_NonIndividualComposite" */
export enum Rtcs_Db_Ph_Rpt_NonIndividualComposite_Select_Column {
  /** column name */
  ActvtyDesc = "ActvtyDesc",
  /** column name */
  AdditionalOwners = "AdditionalOwners",
  /** column name */
  AdditionalProperties = "AdditionalProperties",
  /** column name */
  AuthctcdSurname = "AuthctcdSurname",
  /** column name */
  AuthctcdTin = "AuthctcdTin",
  /** column name */
  Brn = "Brn",
  /** column name */
  BsnsName = "BsnsName",
  /** column name */
  BsnsaddrLocalCouncil = "BsnsaddrLocalCouncil",
  /** column name */
  BuildingName = "BuildingName",
  /** column name */
  BusinessStartDt = "BusinessStartDt",
  /** column name */
  Coin = "Coin",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DateOfIncorporation = "DateOfIncorporation",
  /** column name */
  EmailId = "EmailId",
  /** column name */
  Fax = "Fax",
  /** column name */
  FormerBusinessName = "FormerBusinessName",
  /** column name */
  Gml = "Gml",
  /** column name */
  HoldingCompName = "HoldingCompName",
  /** column name */
  HoldingCompTin = "HoldingCompTin",
  /** column name */
  Id = "Id",
  /** column name */
  IdentificationRefugeeId = "IdentificationRefugeeId",
  /** column name */
  IdentificationWorkPermit = "IdentificationWorkPermit",
  /** column name */
  KccaName = "KccaName",
  /** column name */
  KccaTin = "KccaTin",
  /** column name */
  LandlineNumber = "LandlineNumber",
  /** column name */
  MainActivity = "MainActivity",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MatchScoreAttributes = "MatchScoreAttributes",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  OrgSubType = "OrgSubType",
  /** column name */
  OrgSubTypeDesc = "OrgSubTypeDesc",
  /** column name */
  OrgType = "OrgType",
  /** column name */
  Photo = "Photo",
  /** column name */
  PhotoContent = "PhotoContent",
  /** column name */
  PhotoMime = "PhotoMime",
  /** column name */
  PhotoName = "PhotoName",
  /** column name */
  PhotoSize = "PhotoSize",
  /** column name */
  PreviousBusinessAddressPlotNumber = "PreviousBusinessAddressPlotNumber",
  /** column name */
  PreviousBusinessName = "PreviousBusinessName",
  /** column name */
  RefEmailId = "RefEmailId",
  /** column name */
  RefFirstName = "RefFirstName",
  /** column name */
  RefLandlineNumber = "RefLandlineNumber",
  /** column name */
  RefMobileNumber = "RefMobileNumber",
  /** column name */
  RefPrsnDesgn = "RefPrsnDesgn",
  /** column name */
  RefSurname = "RefSurname",
  /** column name */
  RefTin = "RefTin",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Source = "Source",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TaxPayerType = "TaxPayerType",
  /** column name */
  TradingName = "TradingName",
  /** column name */
  UraName = "UraName",
  /** column name */
  UraTinNo = "UraTinNo",
}

/** input type for updating data in table "rtcs_db.PH_RPT_NonIndividualComposite" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Set_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["float8"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Stddev_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualComposite_stddev_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  IdentificationRefugeeId?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_RPT_NonIndividualComposite" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Stddev_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualComposite_stddev_pop_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  IdentificationRefugeeId?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_RPT_NonIndividualComposite" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Stddev_Pop_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualComposite_stddev_samp_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  IdentificationRefugeeId?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_RPT_NonIndividualComposite" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Stddev_Samp_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Sum_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualComposite_sum_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  IdentificationRefugeeId?: Maybe<Scalars["float8"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_RPT_NonIndividualComposite" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Sum_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualComposite_var_pop_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  IdentificationRefugeeId?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_RPT_NonIndividualComposite" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Var_Pop_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualComposite_var_samp_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  IdentificationRefugeeId?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_RPT_NonIndividualComposite" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Var_Samp_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Variance_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualComposite_variance_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  IdentificationRefugeeId?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_RPT_NonIndividualComposite" */
export type Rtcs_Db_Ph_Rpt_NonIndividualComposite_Variance_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualTaxSummaryData";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BalanceCarryForward?: Maybe<Scalars["String"]>;
  BalancePrevPeriod?: Maybe<Scalars["String"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  File_x?: Maybe<Scalars["String"]>;
  File_y?: Maybe<Scalars["String"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["String"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  LastUpdated_x?: Maybe<Scalars["timestamptz"]>;
  LastUpdated_y?: Maybe<Scalars["String"]>;
  Line_x?: Maybe<Scalars["bigint"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["String"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalReserve?: Maybe<Scalars["String"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
  TradingName?: Maybe<Scalars["String"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated_x?: Maybe<Scalars["String"]>;
  WhoUpdated_y?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Aggregate = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualTaxSummaryData_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData>;
};

/** aggregate fields of "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualTaxSummaryData_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Avg_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualTaxSummaryData_avg_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  AuthctcdTin?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Avg_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_RPT_NonIndividualTaxSummaryData". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Bool_Exp = {
  ActvtyDesc?: Maybe<String_Comparison_Exp>;
  AdjProfitLossRental?: Maybe<Float8_Comparison_Exp>;
  AdjRentlIncmLoss?: Maybe<Float8_Comparison_Exp>;
  AdministratingExp?: Maybe<Float8_Comparison_Exp>;
  AllowblApportionedCap?: Maybe<Float8_Comparison_Exp>;
  AppliedForTin?: Maybe<String_Comparison_Exp>;
  AssessedChrgblRentlIncm?: Maybe<Float8_Comparison_Exp>;
  AssessedRentlLoss?: Maybe<Float8_Comparison_Exp>;
  AuthctcdEmailId?: Maybe<String_Comparison_Exp>;
  AuthctcdFamilyName?: Maybe<String_Comparison_Exp>;
  AuthctcdFirstName?: Maybe<String_Comparison_Exp>;
  AuthctcdLandlineNumber?: Maybe<Float8_Comparison_Exp>;
  AuthctcdMobileNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdOtherName?: Maybe<String_Comparison_Exp>;
  AuthctcdPrsnDesgn?: Maybe<String_Comparison_Exp>;
  AuthctcdSurname?: Maybe<String_Comparison_Exp>;
  AuthctcdTin?: Maybe<Float8_Comparison_Exp>;
  BadDebtWriteOff?: Maybe<Float8_Comparison_Exp>;
  BalanceCarryForward?: Maybe<String_Comparison_Exp>;
  BalancePrevPeriod?: Maybe<String_Comparison_Exp>;
  BrghtFwdAssessedLoss?: Maybe<Float8_Comparison_Exp>;
  BsnsCertNumber?: Maybe<String_Comparison_Exp>;
  BsnsName?: Maybe<String_Comparison_Exp>;
  BsnsaddrBuildname?: Maybe<String_Comparison_Exp>;
  BsnsaddrDistrictName?: Maybe<String_Comparison_Exp>;
  BsnsaddrLocalCouncil?: Maybe<String_Comparison_Exp>;
  BsnsaddrParishName?: Maybe<String_Comparison_Exp>;
  BsnsaddrPlotNo?: Maybe<String_Comparison_Exp>;
  BsnsaddrStreetAddr?: Maybe<String_Comparison_Exp>;
  BsnsaddrSubCountyName?: Maybe<String_Comparison_Exp>;
  BsnsaddrTownName?: Maybe<String_Comparison_Exp>;
  BsnsaddrTradeCentre?: Maybe<String_Comparison_Exp>;
  BsnsaddrVillageName?: Maybe<String_Comparison_Exp>;
  BusinessIncome?: Maybe<Float8_Comparison_Exp>;
  BusinessRate?: Maybe<Float8_Comparison_Exp>;
  BusinessStartDt?: Maybe<String_Comparison_Exp>;
  BusinessTax?: Maybe<Float8_Comparison_Exp>;
  ChargeableIncome?: Maybe<Float8_Comparison_Exp>;
  ChargeableIncomeP?: Maybe<Float8_Comparison_Exp>;
  CostOfSales?: Maybe<Float8_Comparison_Exp>;
  Debenture?: Maybe<Float8_Comparison_Exp>;
  DeferTaxLiability?: Maybe<Float8_Comparison_Exp>;
  Depreciation?: Maybe<Float8_Comparison_Exp>;
  EmailId?: Maybe<String_Comparison_Exp>;
  EquityShare?: Maybe<Float8_Comparison_Exp>;
  ExistTinNo?: Maybe<String_Comparison_Exp>;
  FactoryRentRates?: Maybe<Float8_Comparison_Exp>;
  File_x?: Maybe<String_Comparison_Exp>;
  File_y?: Maybe<String_Comparison_Exp>;
  FinanceExp?: Maybe<Float8_Comparison_Exp>;
  FixedDeposit?: Maybe<Float8_Comparison_Exp>;
  GovtSecurities?: Maybe<String_Comparison_Exp>;
  GrossProfit?: Maybe<Float8_Comparison_Exp>;
  GrossRentalIncome?: Maybe<Float8_Comparison_Exp>;
  HoldingCompName?: Maybe<String_Comparison_Exp>;
  HoldingCompTin?: Maybe<String_Comparison_Exp>;
  IsAmendment?: Maybe<String_Comparison_Exp>;
  IsHldCmpInUganda?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  IsResident?: Maybe<String_Comparison_Exp>;
  IsSubsidaryComp?: Maybe<String_Comparison_Exp>;
  LandBuild?: Maybe<String_Comparison_Exp>;
  LandlineNumber?: Maybe<Float8_Comparison_Exp>;
  LastUpdated_x?: Maybe<Timestamptz_Comparison_Exp>;
  LastUpdated_y?: Maybe<String_Comparison_Exp>;
  Line_x?: Maybe<Bigint_Comparison_Exp>;
  Line_y?: Maybe<Float8_Comparison_Exp>;
  MainActivity?: Maybe<String_Comparison_Exp>;
  MobileNumber?: Maybe<String_Comparison_Exp>;
  NonAllowblExp?: Maybe<Float8_Comparison_Exp>;
  OperatingExp?: Maybe<Float8_Comparison_Exp>;
  OrgSubType?: Maybe<String_Comparison_Exp>;
  OrgSubTypeDesc?: Maybe<String_Comparison_Exp>;
  OrgType?: Maybe<String_Comparison_Exp>;
  OtherInvestments?: Maybe<Float8_Comparison_Exp>;
  OthrAllowblExp?: Maybe<Float8_Comparison_Exp>;
  PayableAmtInc?: Maybe<Float8_Comparison_Exp>;
  PayableAmtRent?: Maybe<Float8_Comparison_Exp>;
  PostaddrDistictName?: Maybe<String_Comparison_Exp>;
  PostaddrId?: Maybe<String_Comparison_Exp>;
  ProfitAfterDividend?: Maybe<Float8_Comparison_Exp>;
  ProfitAfterTax?: Maybe<Float8_Comparison_Exp>;
  ProfitBeforeTax?: Maybe<Float8_Comparison_Exp>;
  ProfitLossRental?: Maybe<Float8_Comparison_Exp>;
  ProposedDividend?: Maybe<Float8_Comparison_Exp>;
  ProvisionIncomeTax?: Maybe<Float8_Comparison_Exp>;
  RefEmailId?: Maybe<String_Comparison_Exp>;
  RefFamilyName?: Maybe<String_Comparison_Exp>;
  RefFirstName?: Maybe<String_Comparison_Exp>;
  RefLandlineNumber?: Maybe<String_Comparison_Exp>;
  RefMiddleName?: Maybe<String_Comparison_Exp>;
  RefMobileNumber?: Maybe<String_Comparison_Exp>;
  RefOtherName?: Maybe<String_Comparison_Exp>;
  RefPrsnDesgn?: Maybe<String_Comparison_Exp>;
  RefSurname?: Maybe<String_Comparison_Exp>;
  RefTin?: Maybe<String_Comparison_Exp>;
  RefundAmtInc?: Maybe<Float8_Comparison_Exp>;
  RefundAmtRent?: Maybe<Float8_Comparison_Exp>;
  RentRates?: Maybe<Float8_Comparison_Exp>;
  RentincIncome?: Maybe<Float8_Comparison_Exp>;
  RentincIncomeP?: Maybe<Float8_Comparison_Exp>;
  RentincRate?: Maybe<Float8_Comparison_Exp>;
  RentincTax?: Maybe<Float8_Comparison_Exp>;
  ReturnType?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  RtnDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnFromDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnPeriodYear?: Maybe<String_Comparison_Exp>;
  RtnPeriodYearGenerated?: Maybe<String_Comparison_Exp>;
  SecureTotLoan?: Maybe<String_Comparison_Exp>;
  SelfAssessTaxPaidInc?: Maybe<Float8_Comparison_Exp>;
  SelfAssessTaxPaidRent?: Maybe<Float8_Comparison_Exp>;
  SourceFund?: Maybe<String_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TaxPayerType?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  TotAdminExpense?: Maybe<Float8_Comparison_Exp>;
  TotBsnsProfSales?: Maybe<Float8_Comparison_Exp>;
  TotExpPurelyRental?: Maybe<Float8_Comparison_Exp>;
  TotFinancialExpense?: Maybe<Float8_Comparison_Exp>;
  TotFixedAsset?: Maybe<String_Comparison_Exp>;
  TotIncome?: Maybe<Float8_Comparison_Exp>;
  TotNonChargeableIncome?: Maybe<Float8_Comparison_Exp>;
  TotOperationExpense?: Maybe<Float8_Comparison_Exp>;
  TotOtherExpense?: Maybe<Float8_Comparison_Exp>;
  TotRate?: Maybe<Float8_Comparison_Exp>;
  TotRentAdminiExpns?: Maybe<Float8_Comparison_Exp>;
  TotRentFinancExpns?: Maybe<Float8_Comparison_Exp>;
  TotRentIncmExpns?: Maybe<Float8_Comparison_Exp>;
  TotRentOprtgExpns?: Maybe<Float8_Comparison_Exp>;
  TotTax?: Maybe<Float8_Comparison_Exp>;
  TotTaxLiability?: Maybe<Float8_Comparison_Exp>;
  TotTaxLiabilityInc?: Maybe<Float8_Comparison_Exp>;
  TotTaxLiabilityRent?: Maybe<Float8_Comparison_Exp>;
  TotTaxesPaidInc?: Maybe<Float8_Comparison_Exp>;
  TotTaxesPaidRent?: Maybe<Float8_Comparison_Exp>;
  TotTradeInvestment?: Maybe<String_Comparison_Exp>;
  TotWthdTaxDeduct?: Maybe<Float8_Comparison_Exp>;
  TotalPropFund?: Maybe<String_Comparison_Exp>;
  TotalReserve?: Maybe<String_Comparison_Exp>;
  TotalShareCapital?: Maybe<Float8_Comparison_Exp>;
  TradingName?: Maybe<String_Comparison_Exp>;
  UnsecureTotLoan?: Maybe<String_Comparison_Exp>;
  Uuid?: Maybe<String_Comparison_Exp>;
  WhoUpdated_x?: Maybe<String_Comparison_Exp>;
  WhoUpdated_y?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Inc_Input = {
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  AuthctcdTin?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  Line_x?: Maybe<Scalars["bigint"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Insert_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BalanceCarryForward?: Maybe<Scalars["String"]>;
  BalancePrevPeriod?: Maybe<Scalars["String"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  File_x?: Maybe<Scalars["String"]>;
  File_y?: Maybe<Scalars["String"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["String"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  LastUpdated_x?: Maybe<Scalars["timestamptz"]>;
  LastUpdated_y?: Maybe<Scalars["String"]>;
  Line_x?: Maybe<Scalars["bigint"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["String"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalReserve?: Maybe<Scalars["String"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
  TradingName?: Maybe<Scalars["String"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated_x?: Maybe<Scalars["String"]>;
  WhoUpdated_y?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Max_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualTaxSummaryData_max_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BalanceCarryForward?: Maybe<Scalars["String"]>;
  BalancePrevPeriod?: Maybe<Scalars["String"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  File_x?: Maybe<Scalars["String"]>;
  File_y?: Maybe<Scalars["String"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["String"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  LastUpdated_x?: Maybe<Scalars["timestamptz"]>;
  LastUpdated_y?: Maybe<Scalars["String"]>;
  Line_x?: Maybe<Scalars["bigint"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["String"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalReserve?: Maybe<Scalars["String"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
  TradingName?: Maybe<Scalars["String"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated_x?: Maybe<Scalars["String"]>;
  WhoUpdated_y?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Max_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AppliedForTin?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BalanceCarryForward?: Maybe<Order_By>;
  BalancePrevPeriod?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BsnsaddrBuildname?: Maybe<Order_By>;
  BsnsaddrDistrictName?: Maybe<Order_By>;
  BsnsaddrLocalCouncil?: Maybe<Order_By>;
  BsnsaddrParishName?: Maybe<Order_By>;
  BsnsaddrPlotNo?: Maybe<Order_By>;
  BsnsaddrStreetAddr?: Maybe<Order_By>;
  BsnsaddrSubCountyName?: Maybe<Order_By>;
  BsnsaddrTownName?: Maybe<Order_By>;
  BsnsaddrTradeCentre?: Maybe<Order_By>;
  BsnsaddrVillageName?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessStartDt?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  File_x?: Maybe<Order_By>;
  File_y?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  HoldingCompName?: Maybe<Order_By>;
  HoldingCompTin?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsHldCmpInUganda?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  IsSubsidaryComp?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated_x?: Maybe<Order_By>;
  LastUpdated_y?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OrgSubType?: Maybe<Order_By>;
  OrgSubTypeDesc?: Maybe<Order_By>;
  OrgType?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMiddleName?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnPeriodYearGenerated?: Maybe<Order_By>;
  SecureTotLoan?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  SourceFund?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalPropFund?: Maybe<Order_By>;
  TotalReserve?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
  TradingName?: Maybe<Order_By>;
  UnsecureTotLoan?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated_x?: Maybe<Order_By>;
  WhoUpdated_y?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Min_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualTaxSummaryData_min_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BalanceCarryForward?: Maybe<Scalars["String"]>;
  BalancePrevPeriod?: Maybe<Scalars["String"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  File_x?: Maybe<Scalars["String"]>;
  File_y?: Maybe<Scalars["String"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["String"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  LastUpdated_x?: Maybe<Scalars["timestamptz"]>;
  LastUpdated_y?: Maybe<Scalars["String"]>;
  Line_x?: Maybe<Scalars["bigint"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["String"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalReserve?: Maybe<Scalars["String"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
  TradingName?: Maybe<Scalars["String"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated_x?: Maybe<Scalars["String"]>;
  WhoUpdated_y?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Min_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AppliedForTin?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BalanceCarryForward?: Maybe<Order_By>;
  BalancePrevPeriod?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BsnsaddrBuildname?: Maybe<Order_By>;
  BsnsaddrDistrictName?: Maybe<Order_By>;
  BsnsaddrLocalCouncil?: Maybe<Order_By>;
  BsnsaddrParishName?: Maybe<Order_By>;
  BsnsaddrPlotNo?: Maybe<Order_By>;
  BsnsaddrStreetAddr?: Maybe<Order_By>;
  BsnsaddrSubCountyName?: Maybe<Order_By>;
  BsnsaddrTownName?: Maybe<Order_By>;
  BsnsaddrTradeCentre?: Maybe<Order_By>;
  BsnsaddrVillageName?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessStartDt?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  File_x?: Maybe<Order_By>;
  File_y?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  HoldingCompName?: Maybe<Order_By>;
  HoldingCompTin?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsHldCmpInUganda?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  IsSubsidaryComp?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated_x?: Maybe<Order_By>;
  LastUpdated_y?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OrgSubType?: Maybe<Order_By>;
  OrgSubTypeDesc?: Maybe<Order_By>;
  OrgType?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMiddleName?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnPeriodYearGenerated?: Maybe<Order_By>;
  SecureTotLoan?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  SourceFund?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalPropFund?: Maybe<Order_By>;
  TotalReserve?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
  TradingName?: Maybe<Order_By>;
  UnsecureTotLoan?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated_x?: Maybe<Order_By>;
  WhoUpdated_y?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Mutation_Response = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualTaxSummaryData_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AppliedForTin?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BalanceCarryForward?: Maybe<Order_By>;
  BalancePrevPeriod?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BsnsaddrBuildname?: Maybe<Order_By>;
  BsnsaddrDistrictName?: Maybe<Order_By>;
  BsnsaddrLocalCouncil?: Maybe<Order_By>;
  BsnsaddrParishName?: Maybe<Order_By>;
  BsnsaddrPlotNo?: Maybe<Order_By>;
  BsnsaddrStreetAddr?: Maybe<Order_By>;
  BsnsaddrSubCountyName?: Maybe<Order_By>;
  BsnsaddrTownName?: Maybe<Order_By>;
  BsnsaddrTradeCentre?: Maybe<Order_By>;
  BsnsaddrVillageName?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessStartDt?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  File_x?: Maybe<Order_By>;
  File_y?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  HoldingCompName?: Maybe<Order_By>;
  HoldingCompTin?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsHldCmpInUganda?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  IsSubsidaryComp?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated_x?: Maybe<Order_By>;
  LastUpdated_y?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OrgSubType?: Maybe<Order_By>;
  OrgSubTypeDesc?: Maybe<Order_By>;
  OrgType?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMiddleName?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnPeriodYearGenerated?: Maybe<Order_By>;
  SecureTotLoan?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  SourceFund?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalPropFund?: Maybe<Order_By>;
  TotalReserve?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
  TradingName?: Maybe<Order_By>;
  UnsecureTotLoan?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated_x?: Maybe<Order_By>;
  WhoUpdated_y?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
export enum Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Select_Column {
  /** column name */
  ActvtyDesc = "ActvtyDesc",
  /** column name */
  AdjProfitLossRental = "AdjProfitLossRental",
  /** column name */
  AdjRentlIncmLoss = "AdjRentlIncmLoss",
  /** column name */
  AdministratingExp = "AdministratingExp",
  /** column name */
  AllowblApportionedCap = "AllowblApportionedCap",
  /** column name */
  AppliedForTin = "AppliedForTin",
  /** column name */
  AssessedChrgblRentlIncm = "AssessedChrgblRentlIncm",
  /** column name */
  AssessedRentlLoss = "AssessedRentlLoss",
  /** column name */
  AuthctcdEmailId = "AuthctcdEmailId",
  /** column name */
  AuthctcdFamilyName = "AuthctcdFamilyName",
  /** column name */
  AuthctcdFirstName = "AuthctcdFirstName",
  /** column name */
  AuthctcdLandlineNumber = "AuthctcdLandlineNumber",
  /** column name */
  AuthctcdMobileNumber = "AuthctcdMobileNumber",
  /** column name */
  AuthctcdOtherName = "AuthctcdOtherName",
  /** column name */
  AuthctcdPrsnDesgn = "AuthctcdPrsnDesgn",
  /** column name */
  AuthctcdSurname = "AuthctcdSurname",
  /** column name */
  AuthctcdTin = "AuthctcdTin",
  /** column name */
  BadDebtWriteOff = "BadDebtWriteOff",
  /** column name */
  BalanceCarryForward = "BalanceCarryForward",
  /** column name */
  BalancePrevPeriod = "BalancePrevPeriod",
  /** column name */
  BrghtFwdAssessedLoss = "BrghtFwdAssessedLoss",
  /** column name */
  BsnsCertNumber = "BsnsCertNumber",
  /** column name */
  BsnsName = "BsnsName",
  /** column name */
  BsnsaddrBuildname = "BsnsaddrBuildname",
  /** column name */
  BsnsaddrDistrictName = "BsnsaddrDistrictName",
  /** column name */
  BsnsaddrLocalCouncil = "BsnsaddrLocalCouncil",
  /** column name */
  BsnsaddrParishName = "BsnsaddrParishName",
  /** column name */
  BsnsaddrPlotNo = "BsnsaddrPlotNo",
  /** column name */
  BsnsaddrStreetAddr = "BsnsaddrStreetAddr",
  /** column name */
  BsnsaddrSubCountyName = "BsnsaddrSubCountyName",
  /** column name */
  BsnsaddrTownName = "BsnsaddrTownName",
  /** column name */
  BsnsaddrTradeCentre = "BsnsaddrTradeCentre",
  /** column name */
  BsnsaddrVillageName = "BsnsaddrVillageName",
  /** column name */
  BusinessIncome = "BusinessIncome",
  /** column name */
  BusinessRate = "BusinessRate",
  /** column name */
  BusinessStartDt = "BusinessStartDt",
  /** column name */
  BusinessTax = "BusinessTax",
  /** column name */
  ChargeableIncome = "ChargeableIncome",
  /** column name */
  ChargeableIncomeP = "ChargeableIncomeP",
  /** column name */
  CostOfSales = "CostOfSales",
  /** column name */
  Debenture = "Debenture",
  /** column name */
  DeferTaxLiability = "DeferTaxLiability",
  /** column name */
  Depreciation = "Depreciation",
  /** column name */
  EmailId = "EmailId",
  /** column name */
  EquityShare = "EquityShare",
  /** column name */
  ExistTinNo = "ExistTinNo",
  /** column name */
  FactoryRentRates = "FactoryRentRates",
  /** column name */
  FileX = "File_x",
  /** column name */
  FileY = "File_y",
  /** column name */
  FinanceExp = "FinanceExp",
  /** column name */
  FixedDeposit = "FixedDeposit",
  /** column name */
  GovtSecurities = "GovtSecurities",
  /** column name */
  GrossProfit = "GrossProfit",
  /** column name */
  GrossRentalIncome = "GrossRentalIncome",
  /** column name */
  HoldingCompName = "HoldingCompName",
  /** column name */
  HoldingCompTin = "HoldingCompTin",
  /** column name */
  IsAmendment = "IsAmendment",
  /** column name */
  IsHldCmpInUganda = "IsHldCmpInUganda",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  IsResident = "IsResident",
  /** column name */
  IsSubsidaryComp = "IsSubsidaryComp",
  /** column name */
  LandBuild = "LandBuild",
  /** column name */
  LandlineNumber = "LandlineNumber",
  /** column name */
  LastUpdatedX = "LastUpdated_x",
  /** column name */
  LastUpdatedY = "LastUpdated_y",
  /** column name */
  LineX = "Line_x",
  /** column name */
  LineY = "Line_y",
  /** column name */
  MainActivity = "MainActivity",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  NonAllowblExp = "NonAllowblExp",
  /** column name */
  OperatingExp = "OperatingExp",
  /** column name */
  OrgSubType = "OrgSubType",
  /** column name */
  OrgSubTypeDesc = "OrgSubTypeDesc",
  /** column name */
  OrgType = "OrgType",
  /** column name */
  OtherInvestments = "OtherInvestments",
  /** column name */
  OthrAllowblExp = "OthrAllowblExp",
  /** column name */
  PayableAmtInc = "PayableAmtInc",
  /** column name */
  PayableAmtRent = "PayableAmtRent",
  /** column name */
  PostaddrDistictName = "PostaddrDistictName",
  /** column name */
  PostaddrId = "PostaddrId",
  /** column name */
  ProfitAfterDividend = "ProfitAfterDividend",
  /** column name */
  ProfitAfterTax = "ProfitAfterTax",
  /** column name */
  ProfitBeforeTax = "ProfitBeforeTax",
  /** column name */
  ProfitLossRental = "ProfitLossRental",
  /** column name */
  ProposedDividend = "ProposedDividend",
  /** column name */
  ProvisionIncomeTax = "ProvisionIncomeTax",
  /** column name */
  RefEmailId = "RefEmailId",
  /** column name */
  RefFamilyName = "RefFamilyName",
  /** column name */
  RefFirstName = "RefFirstName",
  /** column name */
  RefLandlineNumber = "RefLandlineNumber",
  /** column name */
  RefMiddleName = "RefMiddleName",
  /** column name */
  RefMobileNumber = "RefMobileNumber",
  /** column name */
  RefOtherName = "RefOtherName",
  /** column name */
  RefPrsnDesgn = "RefPrsnDesgn",
  /** column name */
  RefSurname = "RefSurname",
  /** column name */
  RefTin = "RefTin",
  /** column name */
  RefundAmtInc = "RefundAmtInc",
  /** column name */
  RefundAmtRent = "RefundAmtRent",
  /** column name */
  RentRates = "RentRates",
  /** column name */
  RentincIncome = "RentincIncome",
  /** column name */
  RentincIncomeP = "RentincIncomeP",
  /** column name */
  RentincRate = "RentincRate",
  /** column name */
  RentincTax = "RentincTax",
  /** column name */
  ReturnType = "ReturnType",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  RtnDt = "RtnDt",
  /** column name */
  RtnFromDt = "RtnFromDt",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnPeriodYear = "RtnPeriodYear",
  /** column name */
  RtnPeriodYearGenerated = "RtnPeriodYearGenerated",
  /** column name */
  SecureTotLoan = "SecureTotLoan",
  /** column name */
  SelfAssessTaxPaidInc = "SelfAssessTaxPaidInc",
  /** column name */
  SelfAssessTaxPaidRent = "SelfAssessTaxPaidRent",
  /** column name */
  SourceFund = "SourceFund",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TaxPayerType = "TaxPayerType",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  TotAdminExpense = "TotAdminExpense",
  /** column name */
  TotBsnsProfSales = "TotBsnsProfSales",
  /** column name */
  TotExpPurelyRental = "TotExpPurelyRental",
  /** column name */
  TotFinancialExpense = "TotFinancialExpense",
  /** column name */
  TotFixedAsset = "TotFixedAsset",
  /** column name */
  TotIncome = "TotIncome",
  /** column name */
  TotNonChargeableIncome = "TotNonChargeableIncome",
  /** column name */
  TotOperationExpense = "TotOperationExpense",
  /** column name */
  TotOtherExpense = "TotOtherExpense",
  /** column name */
  TotRate = "TotRate",
  /** column name */
  TotRentAdminiExpns = "TotRentAdminiExpns",
  /** column name */
  TotRentFinancExpns = "TotRentFinancExpns",
  /** column name */
  TotRentIncmExpns = "TotRentIncmExpns",
  /** column name */
  TotRentOprtgExpns = "TotRentOprtgExpns",
  /** column name */
  TotTax = "TotTax",
  /** column name */
  TotTaxLiability = "TotTaxLiability",
  /** column name */
  TotTaxLiabilityInc = "TotTaxLiabilityInc",
  /** column name */
  TotTaxLiabilityRent = "TotTaxLiabilityRent",
  /** column name */
  TotTaxesPaidInc = "TotTaxesPaidInc",
  /** column name */
  TotTaxesPaidRent = "TotTaxesPaidRent",
  /** column name */
  TotTradeInvestment = "TotTradeInvestment",
  /** column name */
  TotWthdTaxDeduct = "TotWthdTaxDeduct",
  /** column name */
  TotalPropFund = "TotalPropFund",
  /** column name */
  TotalReserve = "TotalReserve",
  /** column name */
  TotalShareCapital = "TotalShareCapital",
  /** column name */
  TradingName = "TradingName",
  /** column name */
  UnsecureTotLoan = "UnsecureTotLoan",
  /** column name */
  Uuid = "Uuid",
  /** column name */
  WhoUpdatedX = "WhoUpdated_x",
  /** column name */
  WhoUpdatedY = "WhoUpdated_y",
}

/** input type for updating data in table "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Set_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BalanceCarryForward?: Maybe<Scalars["String"]>;
  BalancePrevPeriod?: Maybe<Scalars["String"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  File_x?: Maybe<Scalars["String"]>;
  File_y?: Maybe<Scalars["String"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["String"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  LastUpdated_x?: Maybe<Scalars["timestamptz"]>;
  LastUpdated_y?: Maybe<Scalars["String"]>;
  Line_x?: Maybe<Scalars["bigint"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["String"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalReserve?: Maybe<Scalars["String"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
  TradingName?: Maybe<Scalars["String"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated_x?: Maybe<Scalars["String"]>;
  WhoUpdated_y?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Stddev_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualTaxSummaryData_stddev_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  AuthctcdTin?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Stddev_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualTaxSummaryData_stddev_pop_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  AuthctcdTin?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Stddev_Pop_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualTaxSummaryData_stddev_samp_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  AuthctcdTin?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Stddev_Samp_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Sum_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualTaxSummaryData_sum_fields";
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  AuthctcdTin?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  Line_x?: Maybe<Scalars["bigint"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Sum_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualTaxSummaryData_var_pop_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  AuthctcdTin?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Var_Pop_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualTaxSummaryData_var_samp_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  AuthctcdTin?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Var_Samp_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Variance_Fields = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualTaxSummaryData_variance_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  AuthctcdTin?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Variance_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_RPT_PropertiesComposite" */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite = {
  __typename?: "rtcs_db_PH_RPT_PropertiesComposite";
  BlockNo?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  CustomerMatchScore?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  GrossRentValueAdjusted?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["String"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_RPT_PropertiesComposite" */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Aggregate = {
  __typename?: "rtcs_db_PH_RPT_PropertiesComposite_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rpt_PropertiesComposite>;
};

/** aggregate fields of "rtcs_db.PH_RPT_PropertiesComposite" */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_RPT_PropertiesComposite_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_RPT_PropertiesComposite" */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertiesComposite_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_RPT_PropertiesComposite" */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_RPT_PropertiesComposite" */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rpt_PropertiesComposite_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Avg_Fields = {
  __typename?: "rtcs_db_PH_RPT_PropertiesComposite_avg_fields";
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_RPT_PropertiesComposite" */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Avg_Order_By = {
  CurrentRateableValue?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_RPT_PropertiesComposite". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Bool_Exp = {
  BlockNo?: Maybe<String_Comparison_Exp>;
  BusinessName?: Maybe<String_Comparison_Exp>;
  CamvId?: Maybe<String_Comparison_Exp>;
  CountyId?: Maybe<String_Comparison_Exp>;
  CreatedDate?: Maybe<String_Comparison_Exp>;
  CurrentRateableValue?: Maybe<Float8_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  CustomerMatchScore?: Maybe<String_Comparison_Exp>;
  DistrictId?: Maybe<String_Comparison_Exp>;
  DivisionId?: Maybe<String_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  EntityLegalName?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  GrossRentValueAdjusted?: Maybe<String_Comparison_Exp>;
  GrossValue?: Maybe<Float8_Comparison_Exp>;
  ImgPath?: Maybe<String_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  MapTitle?: Maybe<String_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Float8_Comparison_Exp>;
  NumProperties?: Maybe<Bigint_Comparison_Exp>;
  NumRentedProperties?: Maybe<Bigint_Comparison_Exp>;
  NwscCustomerId?: Maybe<String_Comparison_Exp>;
  NwscCustomerName?: Maybe<String_Comparison_Exp>;
  ParishId?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyNo?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatusId?: Maybe<String_Comparison_Exp>;
  PropertyTypeId?: Maybe<String_Comparison_Exp>;
  RateableValue?: Maybe<Float8_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  RnidCustomer?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  SourceCustomer?: Maybe<String_Comparison_Exp>;
  StreetId?: Maybe<String_Comparison_Exp>;
  SubCountyId?: Maybe<String_Comparison_Exp>;
  SubPropertyTypeId?: Maybe<String_Comparison_Exp>;
  Supplementary?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  VillageId?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_RPT_PropertiesComposite" */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Inc_Input = {
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  GrossValue?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_RPT_PropertiesComposite" */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Insert_Input = {
  BlockNo?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  CustomerMatchScore?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  GrossRentValueAdjusted?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["String"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Max_Fields = {
  __typename?: "rtcs_db_PH_RPT_PropertiesComposite_max_fields";
  BlockNo?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  CustomerMatchScore?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  GrossRentValueAdjusted?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["String"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_RPT_PropertiesComposite" */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Max_Order_By = {
  BlockNo?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  CustomerMatchScore?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  GrossRentValueAdjusted?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerName?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidCustomer?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SourceCustomer?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Min_Fields = {
  __typename?: "rtcs_db_PH_RPT_PropertiesComposite_min_fields";
  BlockNo?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  CustomerMatchScore?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  GrossRentValueAdjusted?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["String"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_RPT_PropertiesComposite" */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Min_Order_By = {
  BlockNo?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  CustomerMatchScore?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  GrossRentValueAdjusted?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerName?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidCustomer?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SourceCustomer?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_RPT_PropertiesComposite" */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Mutation_Response = {
  __typename?: "rtcs_db_PH_RPT_PropertiesComposite_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rpt_PropertiesComposite>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_RPT_PropertiesComposite" */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rpt_PropertiesComposite_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_RPT_PropertiesComposite" */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Order_By = {
  BlockNo?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  CustomerMatchScore?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  GrossRentValueAdjusted?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerName?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidCustomer?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SourceCustomer?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_RPT_PropertiesComposite" */
export enum Rtcs_Db_Ph_Rpt_PropertiesComposite_Select_Column {
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  BusinessName = "BusinessName",
  /** column name */
  CamvId = "CamvId",
  /** column name */
  CountyId = "CountyId",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CurrentRateableValue = "CurrentRateableValue",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  CustomerMatchScore = "CustomerMatchScore",
  /** column name */
  DistrictId = "DistrictId",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  Email = "Email",
  /** column name */
  EntityLegalName = "EntityLegalName",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  Gml = "Gml",
  /** column name */
  GrossRentValueAdjusted = "GrossRentValueAdjusted",
  /** column name */
  GrossValue = "GrossValue",
  /** column name */
  ImgPath = "ImgPath",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MapTitle = "MapTitle",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  NumRentedProperties = "NumRentedProperties",
  /** column name */
  NwscCustomerId = "NwscCustomerId",
  /** column name */
  NwscCustomerName = "NwscCustomerName",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyNo = "PropertyNo",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  RateableValue = "RateableValue",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  RnidCustomer = "RnidCustomer",
  /** column name */
  Source = "Source",
  /** column name */
  SourceCustomer = "SourceCustomer",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  SubCountyId = "SubCountyId",
  /** column name */
  SubPropertyTypeId = "SubPropertyTypeId",
  /** column name */
  Supplementary = "Supplementary",
  /** column name */
  Surname = "Surname",
  /** column name */
  VillageId = "VillageId",
}

/** input type for updating data in table "rtcs_db.PH_RPT_PropertiesComposite" */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Set_Input = {
  BlockNo?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  CustomerMatchScore?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  GrossRentValueAdjusted?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["String"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Stddev_Fields = {
  __typename?: "rtcs_db_PH_RPT_PropertiesComposite_stddev_fields";
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_RPT_PropertiesComposite" */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Stddev_Order_By = {
  CurrentRateableValue?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_PropertiesComposite_stddev_pop_fields";
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_RPT_PropertiesComposite" */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Stddev_Pop_Order_By = {
  CurrentRateableValue?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_PropertiesComposite_stddev_samp_fields";
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_RPT_PropertiesComposite" */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Stddev_Samp_Order_By = {
  CurrentRateableValue?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Sum_Fields = {
  __typename?: "rtcs_db_PH_RPT_PropertiesComposite_sum_fields";
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  GrossValue?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_RPT_PropertiesComposite" */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Sum_Order_By = {
  CurrentRateableValue?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_PropertiesComposite_var_pop_fields";
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_RPT_PropertiesComposite" */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Var_Pop_Order_By = {
  CurrentRateableValue?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_PropertiesComposite_var_samp_fields";
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_RPT_PropertiesComposite" */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Var_Samp_Order_By = {
  CurrentRateableValue?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Variance_Fields = {
  __typename?: "rtcs_db_PH_RPT_PropertiesComposite_variance_fields";
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_RPT_PropertiesComposite" */
export type Rtcs_Db_Ph_Rpt_PropertiesComposite_Variance_Order_By = {
  CurrentRateableValue?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_RPT_PropertyValuation" */
export type Rtcs_Db_Ph_Rpt_PropertyValuation = {
  __typename?: "rtcs_db_PH_RPT_PropertyValuation";
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["float8"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  AnnualGrossValue?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["float8"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  RentPerSquareMeter?: Maybe<Scalars["float8"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_RPT_PropertyValuation" */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Aggregate = {
  __typename?: "rtcs_db_PH_RPT_PropertyValuation_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rpt_PropertyValuation>;
};

/** aggregate fields of "rtcs_db.PH_RPT_PropertyValuation" */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_RPT_PropertyValuation_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_RPT_PropertyValuation" */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertyValuation_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_RPT_PropertyValuation" */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_RPT_PropertyValuation" */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rpt_PropertyValuation_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Avg_Fields = {
  __typename?: "rtcs_db_PH_RPT_PropertyValuation_avg_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["Float"]>;
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  AnnualGrossValue?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  RentPerSquareMeter?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_RPT_PropertyValuation" */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Avg_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AnnualGazetteValue?: Maybe<Order_By>;
  AnnualGrossValue?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  RentPerSquareMeter?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_RPT_PropertyValuation". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Bool_Exp = {
  AccomodationBedrooms?: Maybe<Float8_Comparison_Exp>;
  AccomodationBuiltUpArea?: Maybe<Float8_Comparison_Exp>;
  AccomodationOthers?: Maybe<String_Comparison_Exp>;
  AnnualGazetteValue?: Maybe<Float8_Comparison_Exp>;
  AnnualGrossValue?: Maybe<Float8_Comparison_Exp>;
  BlockNo?: Maybe<String_Comparison_Exp>;
  CamvId?: Maybe<String_Comparison_Exp>;
  CountyId?: Maybe<String_Comparison_Exp>;
  CreatedDate?: Maybe<String_Comparison_Exp>;
  CurrentRateableValue?: Maybe<Float8_Comparison_Exp>;
  CustomerId?: Maybe<Bigint_Comparison_Exp>;
  DerivedNoOfBedrooms?: Maybe<Float8_Comparison_Exp>;
  DistrictId?: Maybe<String_Comparison_Exp>;
  DivisionId?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  Grossrentvalueadjusted?: Maybe<Float8_Comparison_Exp>;
  Grossvalue?: Maybe<Float8_Comparison_Exp>;
  ImgPath?: Maybe<String_Comparison_Exp>;
  KccaNoOfBedrooms?: Maybe<Float8_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  MapTitle?: Maybe<String_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Float8_Comparison_Exp>;
  MonthlyGazetteValue?: Maybe<Float8_Comparison_Exp>;
  NumProperties?: Maybe<Bigint_Comparison_Exp>;
  NumRentedProperties?: Maybe<Bigint_Comparison_Exp>;
  NwscCustomerId?: Maybe<Float8_Comparison_Exp>;
  NwscCustomerName?: Maybe<String_Comparison_Exp>;
  ParishId?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyId?: Maybe<String_Comparison_Exp>;
  PropertyLegalEntity?: Maybe<String_Comparison_Exp>;
  PropertyNo?: Maybe<String_Comparison_Exp>;
  PropertyOwnerFirstname?: Maybe<String_Comparison_Exp>;
  PropertyOwnerSurname?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatusId?: Maybe<String_Comparison_Exp>;
  PropertyType?: Maybe<String_Comparison_Exp>;
  PropertyTypeId?: Maybe<String_Comparison_Exp>;
  Propertyno?: Maybe<String_Comparison_Exp>;
  Propertyrentedstatus?: Maybe<String_Comparison_Exp>;
  Rateablevalue?: Maybe<Float8_Comparison_Exp>;
  RentPerSquareMeter?: Maybe<Float8_Comparison_Exp>;
  RnSubpropertyType?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  RnidCustomer?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  SourceCustomer?: Maybe<String_Comparison_Exp>;
  SquareMeters?: Maybe<Float8_Comparison_Exp>;
  StreetId?: Maybe<String_Comparison_Exp>;
  SubCountyId?: Maybe<String_Comparison_Exp>;
  SubPropertyTypeId?: Maybe<String_Comparison_Exp>;
  Supplementary?: Maybe<Float8_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  VillageId?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_RPT_PropertyValuation" */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Inc_Input = {
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["float8"]>;
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  AnnualGrossValue?: Maybe<Scalars["float8"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  RentPerSquareMeter?: Maybe<Scalars["float8"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_RPT_PropertyValuation" */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Insert_Input = {
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["float8"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  AnnualGrossValue?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["float8"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  RentPerSquareMeter?: Maybe<Scalars["float8"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Max_Fields = {
  __typename?: "rtcs_db_PH_RPT_PropertyValuation_max_fields";
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["float8"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  AnnualGrossValue?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["float8"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  RentPerSquareMeter?: Maybe<Scalars["float8"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_RPT_PropertyValuation" */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Max_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AccomodationOthers?: Maybe<Order_By>;
  AnnualGazetteValue?: Maybe<Order_By>;
  AnnualGrossValue?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerName?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyLegalEntity?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyOwnerFirstname?: Maybe<Order_By>;
  PropertyOwnerSurname?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  RentPerSquareMeter?: Maybe<Order_By>;
  RnSubpropertyType?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidCustomer?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SourceCustomer?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Min_Fields = {
  __typename?: "rtcs_db_PH_RPT_PropertyValuation_min_fields";
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["float8"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  AnnualGrossValue?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["float8"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  RentPerSquareMeter?: Maybe<Scalars["float8"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_RPT_PropertyValuation" */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Min_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AccomodationOthers?: Maybe<Order_By>;
  AnnualGazetteValue?: Maybe<Order_By>;
  AnnualGrossValue?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerName?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyLegalEntity?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyOwnerFirstname?: Maybe<Order_By>;
  PropertyOwnerSurname?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  RentPerSquareMeter?: Maybe<Order_By>;
  RnSubpropertyType?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidCustomer?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SourceCustomer?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_RPT_PropertyValuation" */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Mutation_Response = {
  __typename?: "rtcs_db_PH_RPT_PropertyValuation_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rpt_PropertyValuation>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_RPT_PropertyValuation" */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rpt_PropertyValuation_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_RPT_PropertyValuation" */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AccomodationOthers?: Maybe<Order_By>;
  AnnualGazetteValue?: Maybe<Order_By>;
  AnnualGrossValue?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerName?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyLegalEntity?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyOwnerFirstname?: Maybe<Order_By>;
  PropertyOwnerSurname?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  RentPerSquareMeter?: Maybe<Order_By>;
  RnSubpropertyType?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidCustomer?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SourceCustomer?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_RPT_PropertyValuation" */
export enum Rtcs_Db_Ph_Rpt_PropertyValuation_Select_Column {
  /** column name */
  AccomodationBedrooms = "AccomodationBedrooms",
  /** column name */
  AccomodationBuiltUpArea = "AccomodationBuiltUpArea",
  /** column name */
  AccomodationOthers = "AccomodationOthers",
  /** column name */
  AnnualGazetteValue = "AnnualGazetteValue",
  /** column name */
  AnnualGrossValue = "AnnualGrossValue",
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  CamvId = "CamvId",
  /** column name */
  CountyId = "CountyId",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CurrentRateableValue = "CurrentRateableValue",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DerivedNoOfBedrooms = "DerivedNoOfBedrooms",
  /** column name */
  DistrictId = "DistrictId",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  Gml = "Gml",
  /** column name */
  Grossrentvalueadjusted = "Grossrentvalueadjusted",
  /** column name */
  Grossvalue = "Grossvalue",
  /** column name */
  ImgPath = "ImgPath",
  /** column name */
  KccaNoOfBedrooms = "KccaNoOfBedrooms",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MapTitle = "MapTitle",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MonthlyGazetteValue = "MonthlyGazetteValue",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  NumRentedProperties = "NumRentedProperties",
  /** column name */
  NwscCustomerId = "NwscCustomerId",
  /** column name */
  NwscCustomerName = "NwscCustomerName",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyId = "PropertyId",
  /** column name */
  PropertyLegalEntity = "PropertyLegalEntity",
  /** column name */
  PropertyNo = "PropertyNo",
  /** column name */
  PropertyOwnerFirstname = "PropertyOwnerFirstname",
  /** column name */
  PropertyOwnerSurname = "PropertyOwnerSurname",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertyType = "PropertyType",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  Propertyno = "Propertyno",
  /** column name */
  Propertyrentedstatus = "Propertyrentedstatus",
  /** column name */
  Rateablevalue = "Rateablevalue",
  /** column name */
  RentPerSquareMeter = "RentPerSquareMeter",
  /** column name */
  RnSubpropertyType = "RnSubpropertyType",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  RnidCustomer = "RnidCustomer",
  /** column name */
  Source = "Source",
  /** column name */
  SourceCustomer = "SourceCustomer",
  /** column name */
  SquareMeters = "SquareMeters",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  SubCountyId = "SubCountyId",
  /** column name */
  SubPropertyTypeId = "SubPropertyTypeId",
  /** column name */
  Supplementary = "Supplementary",
  /** column name */
  Village = "Village",
  /** column name */
  VillageId = "VillageId",
}

/** input type for updating data in table "rtcs_db.PH_RPT_PropertyValuation" */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Set_Input = {
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["float8"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  AnnualGrossValue?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["float8"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  RentPerSquareMeter?: Maybe<Scalars["float8"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Stddev_Fields = {
  __typename?: "rtcs_db_PH_RPT_PropertyValuation_stddev_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["Float"]>;
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  AnnualGrossValue?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  RentPerSquareMeter?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_RPT_PropertyValuation" */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Stddev_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AnnualGazetteValue?: Maybe<Order_By>;
  AnnualGrossValue?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  RentPerSquareMeter?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_PropertyValuation_stddev_pop_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["Float"]>;
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  AnnualGrossValue?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  RentPerSquareMeter?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_RPT_PropertyValuation" */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Stddev_Pop_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AnnualGazetteValue?: Maybe<Order_By>;
  AnnualGrossValue?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  RentPerSquareMeter?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_PropertyValuation_stddev_samp_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["Float"]>;
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  AnnualGrossValue?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  RentPerSquareMeter?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_RPT_PropertyValuation" */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Stddev_Samp_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AnnualGazetteValue?: Maybe<Order_By>;
  AnnualGrossValue?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  RentPerSquareMeter?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Sum_Fields = {
  __typename?: "rtcs_db_PH_RPT_PropertyValuation_sum_fields";
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["float8"]>;
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  AnnualGrossValue?: Maybe<Scalars["float8"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  RentPerSquareMeter?: Maybe<Scalars["float8"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_RPT_PropertyValuation" */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Sum_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AnnualGazetteValue?: Maybe<Order_By>;
  AnnualGrossValue?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  RentPerSquareMeter?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_PropertyValuation_var_pop_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["Float"]>;
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  AnnualGrossValue?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  RentPerSquareMeter?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_RPT_PropertyValuation" */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Var_Pop_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AnnualGazetteValue?: Maybe<Order_By>;
  AnnualGrossValue?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  RentPerSquareMeter?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_PropertyValuation_var_samp_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["Float"]>;
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  AnnualGrossValue?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  RentPerSquareMeter?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_RPT_PropertyValuation" */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Var_Samp_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AnnualGazetteValue?: Maybe<Order_By>;
  AnnualGrossValue?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  RentPerSquareMeter?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Variance_Fields = {
  __typename?: "rtcs_db_PH_RPT_PropertyValuation_variance_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["Float"]>;
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  AnnualGrossValue?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  RentPerSquareMeter?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_RPT_PropertyValuation" */
export type Rtcs_Db_Ph_Rpt_PropertyValuation_Variance_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AnnualGazetteValue?: Maybe<Order_By>;
  AnnualGrossValue?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  RentPerSquareMeter?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_RPT_RentalTenantPayments" */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments = {
  __typename?: "rtcs_db_PH_RPT_RentalTenantPayments";
  File?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["String"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["String"]>;
  TenancyPeriodTo?: Maybe<Scalars["String"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_RPT_RentalTenantPayments" */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Aggregate = {
  __typename?: "rtcs_db_PH_RPT_RentalTenantPayments_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rpt_RentalTenantPayments>;
};

/** aggregate fields of "rtcs_db.PH_RPT_RentalTenantPayments" */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_RPT_RentalTenantPayments_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_RPT_RentalTenantPayments" */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_RPT_RentalTenantPayments" */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_RPT_RentalTenantPayments" */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Avg_Fields = {
  __typename?: "rtcs_db_PH_RPT_RentalTenantPayments_avg_fields";
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_RPT_RentalTenantPayments" */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Avg_Order_By = {
  RentPaid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_RPT_RentalTenantPayments". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  IsAmendment?: Maybe<String_Comparison_Exp>;
  LandlordName?: Maybe<String_Comparison_Exp>;
  LandlordTin?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<String_Comparison_Exp>;
  PremiseLocation?: Maybe<String_Comparison_Exp>;
  RentPaid?: Maybe<Float8_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnRentPaidId?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  TenancyPeriodFrom?: Maybe<String_Comparison_Exp>;
  TenancyPeriodTo?: Maybe<String_Comparison_Exp>;
  TenantName?: Maybe<String_Comparison_Exp>;
  TenantTin?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_RPT_RentalTenantPayments" */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Inc_Input = {
  RentPaid?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_RPT_RentalTenantPayments" */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["String"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["String"]>;
  TenancyPeriodTo?: Maybe<Scalars["String"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Max_Fields = {
  __typename?: "rtcs_db_PH_RPT_RentalTenantPayments_max_fields";
  File?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["String"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["String"]>;
  TenancyPeriodTo?: Maybe<Scalars["String"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_RPT_RentalTenantPayments" */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Max_Order_By = {
  File?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  LandlordName?: Maybe<Order_By>;
  LandlordTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnRentPaidId?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  TenantName?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Min_Fields = {
  __typename?: "rtcs_db_PH_RPT_RentalTenantPayments_min_fields";
  File?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["String"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["String"]>;
  TenancyPeriodTo?: Maybe<Scalars["String"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_RPT_RentalTenantPayments" */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Min_Order_By = {
  File?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  LandlordName?: Maybe<Order_By>;
  LandlordTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnRentPaidId?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  TenantName?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_RPT_RentalTenantPayments" */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Mutation_Response = {
  __typename?: "rtcs_db_PH_RPT_RentalTenantPayments_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rpt_RentalTenantPayments>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_RPT_RentalTenantPayments" */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rpt_RentalTenantPayments_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_RPT_RentalTenantPayments" */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Order_By = {
  File?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  LandlordName?: Maybe<Order_By>;
  LandlordTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnRentPaidId?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  TenantName?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_RPT_RentalTenantPayments" */
export enum Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  IsAmendment = "IsAmendment",
  /** column name */
  LandlordName = "LandlordName",
  /** column name */
  LandlordTin = "LandlordTin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  PremiseLocation = "PremiseLocation",
  /** column name */
  RentPaid = "RentPaid",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnRentPaidId = "RtnRentPaidId",
  /** column name */
  Source = "Source",
  /** column name */
  TenancyPeriodFrom = "TenancyPeriodFrom",
  /** column name */
  TenancyPeriodTo = "TenancyPeriodTo",
  /** column name */
  TenantName = "TenantName",
  /** column name */
  TenantTin = "TenantTin",
}

/** input type for updating data in table "rtcs_db.PH_RPT_RentalTenantPayments" */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["String"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["String"]>;
  TenancyPeriodTo?: Maybe<Scalars["String"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Stddev_Fields = {
  __typename?: "rtcs_db_PH_RPT_RentalTenantPayments_stddev_fields";
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_RPT_RentalTenantPayments" */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Stddev_Order_By = {
  RentPaid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_RentalTenantPayments_stddev_pop_fields";
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_RPT_RentalTenantPayments" */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Stddev_Pop_Order_By = {
  RentPaid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_RentalTenantPayments_stddev_samp_fields";
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_RPT_RentalTenantPayments" */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Stddev_Samp_Order_By = {
  RentPaid?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Sum_Fields = {
  __typename?: "rtcs_db_PH_RPT_RentalTenantPayments_sum_fields";
  RentPaid?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_RPT_RentalTenantPayments" */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Sum_Order_By = {
  RentPaid?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_RPT_RentalTenantPayments_var_pop_fields";
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_RPT_RentalTenantPayments" */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Var_Pop_Order_By = {
  RentPaid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_RPT_RentalTenantPayments_var_samp_fields";
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_RPT_RentalTenantPayments" */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Var_Samp_Order_By = {
  RentPaid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Variance_Fields = {
  __typename?: "rtcs_db_PH_RPT_RentalTenantPayments_variance_fields";
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_RPT_RentalTenantPayments" */
export type Rtcs_Db_Ph_Rpt_RentalTenantPayments_Variance_Order_By = {
  RentPaid?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_gazette_CommercialRates" */
export type Rtcs_Db_Ph_Gazette_CommercialRates = {
  __typename?: "rtcs_db_PH_gazette_CommercialRates";
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["String"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_gazette_CommercialRates" */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Aggregate = {
  __typename?: "rtcs_db_PH_gazette_CommercialRates_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Gazette_CommercialRates>;
};

/** aggregate fields of "rtcs_db.PH_gazette_CommercialRates" */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_gazette_CommercialRates_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_gazette_CommercialRates" */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Gazette_CommercialRates_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_gazette_CommercialRates" */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_gazette_CommercialRates" */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Gazette_CommercialRates_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Avg_Fields = {
  __typename?: "rtcs_db_PH_gazette_CommercialRates_avg_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_gazette_CommercialRates" */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Avg_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_gazette_CommercialRates". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Bool_Exp = {
  AnnualGazetteValue?: Maybe<Float8_Comparison_Exp>;
  DerivedNoOfBedrooms?: Maybe<String_Comparison_Exp>;
  KccaNoOfBedrooms?: Maybe<Float8_Comparison_Exp>;
  MonthlyGazetteValue?: Maybe<Float8_Comparison_Exp>;
  PropertyId?: Maybe<String_Comparison_Exp>;
  PropertyLegalEntity?: Maybe<String_Comparison_Exp>;
  PropertyOwnerFirstname?: Maybe<String_Comparison_Exp>;
  PropertyOwnerSurname?: Maybe<String_Comparison_Exp>;
  PropertyType?: Maybe<String_Comparison_Exp>;
  RnSubpropertyType?: Maybe<String_Comparison_Exp>;
  SquareMeters?: Maybe<Float8_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_gazette_CommercialRates" */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Inc_Input = {
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_gazette_CommercialRates" */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Insert_Input = {
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["String"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Max_Fields = {
  __typename?: "rtcs_db_PH_gazette_CommercialRates_max_fields";
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["String"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_gazette_CommercialRates" */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Max_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyLegalEntity?: Maybe<Order_By>;
  PropertyOwnerFirstname?: Maybe<Order_By>;
  PropertyOwnerSurname?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  RnSubpropertyType?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Min_Fields = {
  __typename?: "rtcs_db_PH_gazette_CommercialRates_min_fields";
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["String"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_gazette_CommercialRates" */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Min_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyLegalEntity?: Maybe<Order_By>;
  PropertyOwnerFirstname?: Maybe<Order_By>;
  PropertyOwnerSurname?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  RnSubpropertyType?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_gazette_CommercialRates" */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Mutation_Response = {
  __typename?: "rtcs_db_PH_gazette_CommercialRates_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Gazette_CommercialRates>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_gazette_CommercialRates" */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Gazette_CommercialRates_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_gazette_CommercialRates" */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyLegalEntity?: Maybe<Order_By>;
  PropertyOwnerFirstname?: Maybe<Order_By>;
  PropertyOwnerSurname?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  RnSubpropertyType?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_gazette_CommercialRates" */
export enum Rtcs_Db_Ph_Gazette_CommercialRates_Select_Column {
  /** column name */
  AnnualGazetteValue = "AnnualGazetteValue",
  /** column name */
  DerivedNoOfBedrooms = "DerivedNoOfBedrooms",
  /** column name */
  KccaNoOfBedrooms = "KccaNoOfBedrooms",
  /** column name */
  MonthlyGazetteValue = "MonthlyGazetteValue",
  /** column name */
  PropertyId = "PropertyId",
  /** column name */
  PropertyLegalEntity = "PropertyLegalEntity",
  /** column name */
  PropertyOwnerFirstname = "PropertyOwnerFirstname",
  /** column name */
  PropertyOwnerSurname = "PropertyOwnerSurname",
  /** column name */
  PropertyType = "PropertyType",
  /** column name */
  RnSubpropertyType = "RnSubpropertyType",
  /** column name */
  SquareMeters = "SquareMeters",
  /** column name */
  Village = "Village",
}

/** input type for updating data in table "rtcs_db.PH_gazette_CommercialRates" */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Set_Input = {
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["String"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Stddev_Fields = {
  __typename?: "rtcs_db_PH_gazette_CommercialRates_stddev_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_gazette_CommercialRates" */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Stddev_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_gazette_CommercialRates_stddev_pop_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_gazette_CommercialRates" */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Stddev_Pop_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_gazette_CommercialRates_stddev_samp_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_gazette_CommercialRates" */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Stddev_Samp_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Sum_Fields = {
  __typename?: "rtcs_db_PH_gazette_CommercialRates_sum_fields";
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_gazette_CommercialRates" */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Sum_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_gazette_CommercialRates_var_pop_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_gazette_CommercialRates" */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Var_Pop_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_gazette_CommercialRates_var_samp_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_gazette_CommercialRates" */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Var_Samp_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Variance_Fields = {
  __typename?: "rtcs_db_PH_gazette_CommercialRates_variance_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_gazette_CommercialRates" */
export type Rtcs_Db_Ph_Gazette_CommercialRates_Variance_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_gazette_ResidentialRates" */
export type Rtcs_Db_Ph_Gazette_ResidentialRates = {
  __typename?: "rtcs_db_PH_gazette_ResidentialRates";
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_gazette_ResidentialRates" */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Aggregate = {
  __typename?: "rtcs_db_PH_gazette_ResidentialRates_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Gazette_ResidentialRates>;
};

/** aggregate fields of "rtcs_db.PH_gazette_ResidentialRates" */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_gazette_ResidentialRates_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_gazette_ResidentialRates" */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Gazette_ResidentialRates_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_gazette_ResidentialRates" */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_gazette_ResidentialRates" */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Gazette_ResidentialRates_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Avg_Fields = {
  __typename?: "rtcs_db_PH_gazette_ResidentialRates_avg_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_gazette_ResidentialRates" */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Avg_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_gazette_ResidentialRates". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Bool_Exp = {
  AnnualGazetteValue?: Maybe<Float8_Comparison_Exp>;
  DerivedNoOfBedrooms?: Maybe<Float8_Comparison_Exp>;
  KccaNoOfBedrooms?: Maybe<Float8_Comparison_Exp>;
  MonthlyGazetteValue?: Maybe<Float8_Comparison_Exp>;
  PropertyId?: Maybe<String_Comparison_Exp>;
  PropertyLegalEntity?: Maybe<String_Comparison_Exp>;
  PropertyOwnerFirstname?: Maybe<String_Comparison_Exp>;
  PropertyOwnerSurname?: Maybe<String_Comparison_Exp>;
  PropertyType?: Maybe<String_Comparison_Exp>;
  RnSubpropertyType?: Maybe<String_Comparison_Exp>;
  SquareMeters?: Maybe<Float8_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_gazette_ResidentialRates" */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Inc_Input = {
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_gazette_ResidentialRates" */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Insert_Input = {
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Max_Fields = {
  __typename?: "rtcs_db_PH_gazette_ResidentialRates_max_fields";
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_gazette_ResidentialRates" */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Max_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyLegalEntity?: Maybe<Order_By>;
  PropertyOwnerFirstname?: Maybe<Order_By>;
  PropertyOwnerSurname?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  RnSubpropertyType?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Min_Fields = {
  __typename?: "rtcs_db_PH_gazette_ResidentialRates_min_fields";
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_gazette_ResidentialRates" */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Min_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyLegalEntity?: Maybe<Order_By>;
  PropertyOwnerFirstname?: Maybe<Order_By>;
  PropertyOwnerSurname?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  RnSubpropertyType?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_gazette_ResidentialRates" */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Mutation_Response = {
  __typename?: "rtcs_db_PH_gazette_ResidentialRates_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Gazette_ResidentialRates>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_gazette_ResidentialRates" */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Gazette_ResidentialRates_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_gazette_ResidentialRates" */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyLegalEntity?: Maybe<Order_By>;
  PropertyOwnerFirstname?: Maybe<Order_By>;
  PropertyOwnerSurname?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  RnSubpropertyType?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_gazette_ResidentialRates" */
export enum Rtcs_Db_Ph_Gazette_ResidentialRates_Select_Column {
  /** column name */
  AnnualGazetteValue = "AnnualGazetteValue",
  /** column name */
  DerivedNoOfBedrooms = "DerivedNoOfBedrooms",
  /** column name */
  KccaNoOfBedrooms = "KccaNoOfBedrooms",
  /** column name */
  MonthlyGazetteValue = "MonthlyGazetteValue",
  /** column name */
  PropertyId = "PropertyId",
  /** column name */
  PropertyLegalEntity = "PropertyLegalEntity",
  /** column name */
  PropertyOwnerFirstname = "PropertyOwnerFirstname",
  /** column name */
  PropertyOwnerSurname = "PropertyOwnerSurname",
  /** column name */
  PropertyType = "PropertyType",
  /** column name */
  RnSubpropertyType = "RnSubpropertyType",
  /** column name */
  SquareMeters = "SquareMeters",
  /** column name */
  Village = "Village",
}

/** input type for updating data in table "rtcs_db.PH_gazette_ResidentialRates" */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Set_Input = {
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Stddev_Fields = {
  __typename?: "rtcs_db_PH_gazette_ResidentialRates_stddev_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_gazette_ResidentialRates" */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Stddev_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_gazette_ResidentialRates_stddev_pop_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_gazette_ResidentialRates" */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Stddev_Pop_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_gazette_ResidentialRates_stddev_samp_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_gazette_ResidentialRates" */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Stddev_Samp_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Sum_Fields = {
  __typename?: "rtcs_db_PH_gazette_ResidentialRates_sum_fields";
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_gazette_ResidentialRates" */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Sum_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_gazette_ResidentialRates_var_pop_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_gazette_ResidentialRates" */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Var_Pop_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_gazette_ResidentialRates_var_samp_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_gazette_ResidentialRates" */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Var_Samp_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Variance_Fields = {
  __typename?: "rtcs_db_PH_gazette_ResidentialRates_variance_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_gazette_ResidentialRates" */
export type Rtcs_Db_Ph_Gazette_ResidentialRates_Variance_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_AssociateType" */
export type Rtcs_Db_Ph_Kcca_AssociateType = {
  __typename?: "rtcs_db_PH_kcca_AssociateType";
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_AssociateType" */
export type Rtcs_Db_Ph_Kcca_AssociateType_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_AssociateType_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_AssociateType>;
};

/** aggregate fields of "rtcs_db.PH_kcca_AssociateType" */
export type Rtcs_Db_Ph_Kcca_AssociateType_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_AssociateType_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_AssociateType" */
export type Rtcs_Db_Ph_Kcca_AssociateType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_AssociateType_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_AssociateType" */
export type Rtcs_Db_Ph_Kcca_AssociateType_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_AssociateType" */
export type Rtcs_Db_Ph_Kcca_AssociateType_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_AssociateType_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_AssociateType_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_AssociateType_avg_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_AssociateType" */
export type Rtcs_Db_Ph_Kcca_AssociateType_Avg_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_AssociateType". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_AssociateType_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamp_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_AssociateType" */
export type Rtcs_Db_Ph_Kcca_AssociateType_Inc_Input = {
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_AssociateType" */
export type Rtcs_Db_Ph_Kcca_AssociateType_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_AssociateType_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_AssociateType_max_fields";
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_AssociateType" */
export type Rtcs_Db_Ph_Kcca_AssociateType_Max_Order_By = {
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_AssociateType_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_AssociateType_min_fields";
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_AssociateType" */
export type Rtcs_Db_Ph_Kcca_AssociateType_Min_Order_By = {
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_AssociateType" */
export type Rtcs_Db_Ph_Kcca_AssociateType_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_AssociateType_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_AssociateType>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_AssociateType" */
export type Rtcs_Db_Ph_Kcca_AssociateType_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_AssociateType_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_AssociateType" */
export type Rtcs_Db_Ph_Kcca_AssociateType_Order_By = {
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_AssociateType" */
export enum Rtcs_Db_Ph_Kcca_AssociateType_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_AssociateType" */
export type Rtcs_Db_Ph_Kcca_AssociateType_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_AssociateType_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_AssociateType_stddev_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_AssociateType" */
export type Rtcs_Db_Ph_Kcca_AssociateType_Stddev_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_AssociateType_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_AssociateType_stddev_pop_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_AssociateType" */
export type Rtcs_Db_Ph_Kcca_AssociateType_Stddev_Pop_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_AssociateType_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_AssociateType_stddev_samp_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_AssociateType" */
export type Rtcs_Db_Ph_Kcca_AssociateType_Stddev_Samp_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_AssociateType_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_AssociateType_sum_fields";
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_AssociateType" */
export type Rtcs_Db_Ph_Kcca_AssociateType_Sum_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_AssociateType_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_AssociateType_var_pop_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_AssociateType" */
export type Rtcs_Db_Ph_Kcca_AssociateType_Var_Pop_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_AssociateType_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_AssociateType_var_samp_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_AssociateType" */
export type Rtcs_Db_Ph_Kcca_AssociateType_Var_Samp_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_AssociateType_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_AssociateType_variance_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_AssociateType" */
export type Rtcs_Db_Ph_Kcca_AssociateType_Variance_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_CommercialProperties" */
export type Rtcs_Db_Ph_Kcca_CommercialProperties = {
  __typename?: "rtcs_db_PH_kcca_CommercialProperties";
  Address?: Maybe<Scalars["String"]>;
  AnnualGrossIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CurrencyType?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  MonthlyGrossIncome?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RippleNamiPropertySubtype?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_CommercialProperties" */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_CommercialProperties_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_CommercialProperties>;
};

/** aggregate fields of "rtcs_db.PH_kcca_CommercialProperties" */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_CommercialProperties_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_CommercialProperties" */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_CommercialProperties_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_CommercialProperties" */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_CommercialProperties" */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_CommercialProperties_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_CommercialProperties_avg_fields";
  AnnualGrossIncome?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  MonthlyGrossIncome?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_CommercialProperties" */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Avg_Order_By = {
  AnnualGrossIncome?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  MonthlyGrossIncome?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_CommercialProperties". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  AnnualGrossIncome?: Maybe<Float8_Comparison_Exp>;
  BlockNo?: Maybe<String_Comparison_Exp>;
  CamvId?: Maybe<String_Comparison_Exp>;
  CurrencyType?: Maybe<String_Comparison_Exp>;
  CustomerId?: Maybe<Bigint_Comparison_Exp>;
  DivisionId?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  GrossValue?: Maybe<String_Comparison_Exp>;
  Grossrentvalueadjusted?: Maybe<Float8_Comparison_Exp>;
  HouseNo?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  MonthlyGazetteValue?: Maybe<Float8_Comparison_Exp>;
  MonthlyGrossIncome?: Maybe<Float8_Comparison_Exp>;
  ParishId?: Maybe<Float8_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyNo?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatusId?: Maybe<Float8_Comparison_Exp>;
  PropertyType?: Maybe<String_Comparison_Exp>;
  PropertyTypeId?: Maybe<Float8_Comparison_Exp>;
  Propertyrentedstatus?: Maybe<String_Comparison_Exp>;
  RateableValue?: Maybe<Float8_Comparison_Exp>;
  RippleNamiPropertySubtype?: Maybe<String_Comparison_Exp>;
  SquareMeters?: Maybe<Float8_Comparison_Exp>;
  StreetId?: Maybe<String_Comparison_Exp>;
  VillageId?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_CommercialProperties" */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Inc_Input = {
  AnnualGrossIncome?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  MonthlyGrossIncome?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_CommercialProperties" */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  AnnualGrossIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CurrencyType?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  MonthlyGrossIncome?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RippleNamiPropertySubtype?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_CommercialProperties_max_fields";
  Address?: Maybe<Scalars["String"]>;
  AnnualGrossIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CurrencyType?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  MonthlyGrossIncome?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RippleNamiPropertySubtype?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_CommercialProperties" */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Max_Order_By = {
  Address?: Maybe<Order_By>;
  AnnualGrossIncome?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CurrencyType?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  MonthlyGrossIncome?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RippleNamiPropertySubtype?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_CommercialProperties_min_fields";
  Address?: Maybe<Scalars["String"]>;
  AnnualGrossIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CurrencyType?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  MonthlyGrossIncome?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RippleNamiPropertySubtype?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_CommercialProperties" */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Min_Order_By = {
  Address?: Maybe<Order_By>;
  AnnualGrossIncome?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CurrencyType?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  MonthlyGrossIncome?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RippleNamiPropertySubtype?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_CommercialProperties" */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_CommercialProperties_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_CommercialProperties>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_CommercialProperties" */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_CommercialProperties_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_CommercialProperties" */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Order_By = {
  Address?: Maybe<Order_By>;
  AnnualGrossIncome?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CurrencyType?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  MonthlyGrossIncome?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RippleNamiPropertySubtype?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_CommercialProperties" */
export enum Rtcs_Db_Ph_Kcca_CommercialProperties_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  AnnualGrossIncome = "AnnualGrossIncome",
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  CamvId = "CamvId",
  /** column name */
  CurrencyType = "CurrencyType",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  File = "File",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  GrossValue = "GrossValue",
  /** column name */
  Grossrentvalueadjusted = "Grossrentvalueadjusted",
  /** column name */
  HouseNo = "HouseNo",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MonthlyGazetteValue = "MonthlyGazetteValue",
  /** column name */
  MonthlyGrossIncome = "MonthlyGrossIncome",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyNo = "PropertyNo",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertyType = "PropertyType",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  Propertyrentedstatus = "Propertyrentedstatus",
  /** column name */
  RateableValue = "RateableValue",
  /** column name */
  RippleNamiPropertySubtype = "RippleNamiPropertySubtype",
  /** column name */
  SquareMeters = "SquareMeters",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  VillageId = "VillageId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_CommercialProperties" */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  AnnualGrossIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CurrencyType?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  MonthlyGrossIncome?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RippleNamiPropertySubtype?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_CommercialProperties_stddev_fields";
  AnnualGrossIncome?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  MonthlyGrossIncome?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_CommercialProperties" */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Stddev_Order_By = {
  AnnualGrossIncome?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  MonthlyGrossIncome?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_CommercialProperties_stddev_pop_fields";
  AnnualGrossIncome?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  MonthlyGrossIncome?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_CommercialProperties" */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Stddev_Pop_Order_By = {
  AnnualGrossIncome?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  MonthlyGrossIncome?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_CommercialProperties_stddev_samp_fields";
  AnnualGrossIncome?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  MonthlyGrossIncome?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_CommercialProperties" */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Stddev_Samp_Order_By = {
  AnnualGrossIncome?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  MonthlyGrossIncome?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_CommercialProperties_sum_fields";
  AnnualGrossIncome?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  MonthlyGrossIncome?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_CommercialProperties" */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Sum_Order_By = {
  AnnualGrossIncome?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  MonthlyGrossIncome?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_CommercialProperties_var_pop_fields";
  AnnualGrossIncome?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  MonthlyGrossIncome?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_CommercialProperties" */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Var_Pop_Order_By = {
  AnnualGrossIncome?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  MonthlyGrossIncome?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_CommercialProperties_var_samp_fields";
  AnnualGrossIncome?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  MonthlyGrossIncome?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_CommercialProperties" */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Var_Samp_Order_By = {
  AnnualGrossIncome?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  MonthlyGrossIncome?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_CommercialProperties_variance_fields";
  AnnualGrossIncome?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  MonthlyGrossIncome?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_CommercialProperties" */
export type Rtcs_Db_Ph_Kcca_CommercialProperties_Variance_Order_By = {
  AnnualGrossIncome?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  MonthlyGrossIncome?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_County" */
export type Rtcs_Db_Ph_Kcca_County = {
  __typename?: "rtcs_db_PH_kcca_County";
  CountryId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_County" */
export type Rtcs_Db_Ph_Kcca_County_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_County_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_County_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_County>;
};

/** aggregate fields of "rtcs_db.PH_kcca_County" */
export type Rtcs_Db_Ph_Kcca_County_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_County_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_County_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_County_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_County_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_County_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_County_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_County_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_County_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_County_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_County_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_County_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_County" */
export type Rtcs_Db_Ph_Kcca_County_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_County_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_County" */
export type Rtcs_Db_Ph_Kcca_County_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_County_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_County_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_County_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_County_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_County_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_County_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_County_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_County_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_County_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_County_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_County" */
export type Rtcs_Db_Ph_Kcca_County_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_County_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_County_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_County_avg_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_County" */
export type Rtcs_Db_Ph_Kcca_County_Avg_Order_By = {
  CountryId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_County". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_County_Bool_Exp = {
  CountryId?: Maybe<Bigint_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  DistrictId?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_County_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_County_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_County_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_County" */
export type Rtcs_Db_Ph_Kcca_County_Inc_Input = {
  CountryId?: Maybe<Scalars["bigint"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_County" */
export type Rtcs_Db_Ph_Kcca_County_Insert_Input = {
  CountryId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_County_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_County_max_fields";
  CountryId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_County" */
export type Rtcs_Db_Ph_Kcca_County_Max_Order_By = {
  CountryId?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_County_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_County_min_fields";
  CountryId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_County" */
export type Rtcs_Db_Ph_Kcca_County_Min_Order_By = {
  CountryId?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_County" */
export type Rtcs_Db_Ph_Kcca_County_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_County_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_County>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_County" */
export type Rtcs_Db_Ph_Kcca_County_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_County_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_County" */
export type Rtcs_Db_Ph_Kcca_County_Order_By = {
  CountryId?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_County" */
export enum Rtcs_Db_Ph_Kcca_County_Select_Column {
  /** column name */
  CountryId = "CountryId",
  /** column name */
  Description = "Description",
  /** column name */
  DistrictId = "DistrictId",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_County" */
export type Rtcs_Db_Ph_Kcca_County_Set_Input = {
  CountryId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_County_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_County_stddev_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_County" */
export type Rtcs_Db_Ph_Kcca_County_Stddev_Order_By = {
  CountryId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_County_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_County_stddev_pop_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_County" */
export type Rtcs_Db_Ph_Kcca_County_Stddev_Pop_Order_By = {
  CountryId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_County_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_County_stddev_samp_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_County" */
export type Rtcs_Db_Ph_Kcca_County_Stddev_Samp_Order_By = {
  CountryId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_County_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_County_sum_fields";
  CountryId?: Maybe<Scalars["bigint"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_County" */
export type Rtcs_Db_Ph_Kcca_County_Sum_Order_By = {
  CountryId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_County_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_County_var_pop_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_County" */
export type Rtcs_Db_Ph_Kcca_County_Var_Pop_Order_By = {
  CountryId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_County_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_County_var_samp_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_County" */
export type Rtcs_Db_Ph_Kcca_County_Var_Samp_Order_By = {
  CountryId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_County_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_County_variance_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_County" */
export type Rtcs_Db_Ph_Kcca_County_Variance_Order_By = {
  CountryId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_Customer" */
export type Rtcs_Db_Ph_Kcca_Customer = {
  __typename?: "rtcs_db_PH_kcca_Customer";
  AcquiredName?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["timestamp"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["String"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.PH_kcca_CustomerApplicantType" */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType = {
  __typename?: "rtcs_db_PH_kcca_CustomerApplicantType";
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_CustomerApplicantType" */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_CustomerApplicantType_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_CustomerApplicantType>;
};

/** aggregate fields of "rtcs_db.PH_kcca_CustomerApplicantType" */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerApplicantType_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_CustomerApplicantType" */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_CustomerApplicantType" */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_CustomerApplicantType" */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerApplicantType_avg_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_CustomerApplicantType" */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Avg_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_CustomerApplicantType". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_CustomerApplicantType" */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Inc_Input = {
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_CustomerApplicantType" */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerApplicantType_max_fields";
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_CustomerApplicantType" */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Max_Order_By = {
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerApplicantType_min_fields";
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_CustomerApplicantType" */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Min_Order_By = {
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_CustomerApplicantType" */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_CustomerApplicantType_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_CustomerApplicantType>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_CustomerApplicantType" */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_CustomerApplicantType_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_CustomerApplicantType" */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Order_By = {
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_CustomerApplicantType" */
export enum Rtcs_Db_Ph_Kcca_CustomerApplicantType_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_CustomerApplicantType" */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerApplicantType_stddev_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_CustomerApplicantType" */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Stddev_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerApplicantType_stddev_pop_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_CustomerApplicantType" */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Stddev_Pop_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerApplicantType_stddev_samp_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_CustomerApplicantType" */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Stddev_Samp_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerApplicantType_sum_fields";
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_CustomerApplicantType" */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Sum_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerApplicantType_var_pop_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_CustomerApplicantType" */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Var_Pop_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerApplicantType_var_samp_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_CustomerApplicantType" */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Var_Samp_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerApplicantType_variance_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_CustomerApplicantType" */
export type Rtcs_Db_Ph_Kcca_CustomerApplicantType_Variance_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessAssociate";
  AcquiredName?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  EffectDate?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IdentificationNumber?: Maybe<Scalars["String"]>;
  IdentificationTypeId?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  OfficialDesignation?: Maybe<Scalars["String"]>;
  OtherTitle?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessAssociate_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate>;
};

/** aggregate fields of "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessAssociate_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessAssociate_avg_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Avg_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_CustomerBusinessAssociate". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Bool_Exp = {
  AcquiredName?: Maybe<String_Comparison_Exp>;
  CreatedBy?: Maybe<Float8_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamptz_Comparison_Exp>;
  CustomerId?: Maybe<Bigint_Comparison_Exp>;
  EffectDate?: Maybe<Timestamptz_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  IdentificationNumber?: Maybe<String_Comparison_Exp>;
  IdentificationTypeId?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  ModifiedBy?: Maybe<Float8_Comparison_Exp>;
  ModifiedDate?: Maybe<Timestamptz_Comparison_Exp>;
  MotherMaidenName?: Maybe<String_Comparison_Exp>;
  OfficialDesignation?: Maybe<String_Comparison_Exp>;
  OtherTitle?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  TitleId?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Inc_Input = {
  CreatedBy?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IdentificationTypeId?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  TitleId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Insert_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  EffectDate?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IdentificationNumber?: Maybe<Scalars["String"]>;
  IdentificationTypeId?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  OfficialDesignation?: Maybe<Scalars["String"]>;
  OtherTitle?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessAssociate_max_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  EffectDate?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IdentificationNumber?: Maybe<Scalars["String"]>;
  IdentificationTypeId?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  OfficialDesignation?: Maybe<Scalars["String"]>;
  OtherTitle?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Max_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessAssociate_min_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  EffectDate?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IdentificationNumber?: Maybe<Scalars["String"]>;
  IdentificationTypeId?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  OfficialDesignation?: Maybe<Scalars["String"]>;
  OtherTitle?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Min_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessAssociate_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
export enum Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Select_Column {
  /** column name */
  AcquiredName = "AcquiredName",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  EffectDate = "EffectDate",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Id = "Id",
  /** column name */
  IdentificationNumber = "IdentificationNumber",
  /** column name */
  IdentificationTypeId = "IdentificationTypeId",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  MotherMaidenName = "MotherMaidenName",
  /** column name */
  OfficialDesignation = "OfficialDesignation",
  /** column name */
  OtherTitle = "OtherTitle",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  Surname = "Surname",
  /** column name */
  TitleId = "TitleId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Set_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  EffectDate?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IdentificationNumber?: Maybe<Scalars["String"]>;
  IdentificationTypeId?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  OfficialDesignation?: Maybe<Scalars["String"]>;
  OtherTitle?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessAssociate_stddev_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Stddev_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessAssociate_stddev_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Stddev_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessAssociate_stddev_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Stddev_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessAssociate_sum_fields";
  CreatedBy?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IdentificationTypeId?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  TitleId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Sum_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessAssociate_var_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Var_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessAssociate_var_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Var_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessAssociate_variance_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Variance_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_CustomerBusinessType" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessType";
  CustomerApplicantTypeId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_CustomerBusinessType" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessType_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_CustomerBusinessType>;
};

/** aggregate fields of "rtcs_db.PH_kcca_CustomerBusinessType" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessType_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_CustomerBusinessType" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_CustomerBusinessType" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_CustomerBusinessType" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessType_avg_fields";
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_CustomerBusinessType" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Avg_Order_By = {
  CustomerApplicantTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_CustomerBusinessType". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Bool_Exp = {
  CustomerApplicantTypeId?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_CustomerBusinessType" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Inc_Input = {
  CustomerApplicantTypeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_CustomerBusinessType" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Insert_Input = {
  CustomerApplicantTypeId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessType_max_fields";
  CustomerApplicantTypeId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_CustomerBusinessType" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Max_Order_By = {
  CustomerApplicantTypeId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessType_min_fields";
  CustomerApplicantTypeId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_CustomerBusinessType" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Min_Order_By = {
  CustomerApplicantTypeId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_CustomerBusinessType" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessType_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_CustomerBusinessType>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_CustomerBusinessType" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_CustomerBusinessType_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_CustomerBusinessType" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Order_By = {
  CustomerApplicantTypeId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_CustomerBusinessType" */
export enum Rtcs_Db_Ph_Kcca_CustomerBusinessType_Select_Column {
  /** column name */
  CustomerApplicantTypeId = "CustomerApplicantTypeId",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_CustomerBusinessType" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Set_Input = {
  CustomerApplicantTypeId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessType_stddev_fields";
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_CustomerBusinessType" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Stddev_Order_By = {
  CustomerApplicantTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessType_stddev_pop_fields";
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_CustomerBusinessType" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Stddev_Pop_Order_By = {
  CustomerApplicantTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessType_stddev_samp_fields";
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_CustomerBusinessType" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Stddev_Samp_Order_By = {
  CustomerApplicantTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessType_sum_fields";
  CustomerApplicantTypeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_CustomerBusinessType" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Sum_Order_By = {
  CustomerApplicantTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessType_var_pop_fields";
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_CustomerBusinessType" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Var_Pop_Order_By = {
  CustomerApplicantTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessType_var_samp_fields";
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_CustomerBusinessType" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Var_Samp_Order_By = {
  CustomerApplicantTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessType_variance_fields";
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_CustomerBusinessType" */
export type Rtcs_Db_Ph_Kcca_CustomerBusinessType_Variance_Order_By = {
  CustomerApplicantTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive = {
  __typename?: "rtcs_db_PH_kcca_CustomerCoinDeactivateReactive";
  AcknowledgementId?: Maybe<Scalars["bigint"]>;
  ApplicationReason?: Maybe<Scalars["String"]>;
  ApplicationType?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DeactivateReactivate?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsForwardingContact?: Maybe<Scalars["bigint"]>;
  IsNewOwner?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamp"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_CustomerCoinDeactivateReactive_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive>;
};

/** aggregate fields of "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerCoinDeactivateReactive_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Aggregate_Order_By =
  {
    avg?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Max_Order_By>;
    min?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Min_Order_By>;
    stddev?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Stddev_Order_By>;
    stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Stddev_Samp_Order_By>;
    sum?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Sum_Order_By>;
    var_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Var_Pop_Order_By>;
    var_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Var_Samp_Order_By>;
    variance?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Variance_Order_By>;
  };

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerCoinDeactivateReactive_avg_fields";
  AcknowledgementId?: Maybe<Scalars["Float"]>;
  ApplicationType?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsForwardingContact?: Maybe<Scalars["Float"]>;
  IsNewOwner?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Avg_Order_By = {
  AcknowledgementId?: Maybe<Order_By>;
  ApplicationType?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsForwardingContact?: Maybe<Order_By>;
  IsNewOwner?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Bool_Exp = {
  AcknowledgementId?: Maybe<Bigint_Comparison_Exp>;
  ApplicationReason?: Maybe<String_Comparison_Exp>;
  ApplicationType?: Maybe<Bigint_Comparison_Exp>;
  CreatedBy?: Maybe<Bigint_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamp_Comparison_Exp>;
  CustomerId?: Maybe<Bigint_Comparison_Exp>;
  DeactivateReactivate?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  IsForcedAction?: Maybe<Float8_Comparison_Exp>;
  IsForwardingContact?: Maybe<Bigint_Comparison_Exp>;
  IsNewOwner?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamp_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  ModifiedBy?: Maybe<Float8_Comparison_Exp>;
  ModifiedDate?: Maybe<Timestamp_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Inc_Input = {
  AcknowledgementId?: Maybe<Scalars["bigint"]>;
  ApplicationType?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsForwardingContact?: Maybe<Scalars["bigint"]>;
  IsNewOwner?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Insert_Input = {
  AcknowledgementId?: Maybe<Scalars["bigint"]>;
  ApplicationReason?: Maybe<Scalars["String"]>;
  ApplicationType?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DeactivateReactivate?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsForwardingContact?: Maybe<Scalars["bigint"]>;
  IsNewOwner?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamp"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerCoinDeactivateReactive_max_fields";
  AcknowledgementId?: Maybe<Scalars["bigint"]>;
  ApplicationReason?: Maybe<Scalars["String"]>;
  ApplicationType?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DeactivateReactivate?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsForwardingContact?: Maybe<Scalars["bigint"]>;
  IsNewOwner?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamp"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Max_Order_By = {
  AcknowledgementId?: Maybe<Order_By>;
  ApplicationReason?: Maybe<Order_By>;
  ApplicationType?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DeactivateReactivate?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsForwardingContact?: Maybe<Order_By>;
  IsNewOwner?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerCoinDeactivateReactive_min_fields";
  AcknowledgementId?: Maybe<Scalars["bigint"]>;
  ApplicationReason?: Maybe<Scalars["String"]>;
  ApplicationType?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DeactivateReactivate?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsForwardingContact?: Maybe<Scalars["bigint"]>;
  IsNewOwner?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamp"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Min_Order_By = {
  AcknowledgementId?: Maybe<Order_By>;
  ApplicationReason?: Maybe<Order_By>;
  ApplicationType?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DeactivateReactivate?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsForwardingContact?: Maybe<Order_By>;
  IsNewOwner?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_CustomerCoinDeactivateReactive_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Order_By = {
  AcknowledgementId?: Maybe<Order_By>;
  ApplicationReason?: Maybe<Order_By>;
  ApplicationType?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DeactivateReactivate?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsForwardingContact?: Maybe<Order_By>;
  IsNewOwner?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
export enum Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Select_Column {
  /** column name */
  AcknowledgementId = "AcknowledgementId",
  /** column name */
  ApplicationReason = "ApplicationReason",
  /** column name */
  ApplicationType = "ApplicationType",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DeactivateReactivate = "DeactivateReactivate",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  IsForcedAction = "IsForcedAction",
  /** column name */
  IsForwardingContact = "IsForwardingContact",
  /** column name */
  IsNewOwner = "IsNewOwner",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Set_Input = {
  AcknowledgementId?: Maybe<Scalars["bigint"]>;
  ApplicationReason?: Maybe<Scalars["String"]>;
  ApplicationType?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DeactivateReactivate?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsForwardingContact?: Maybe<Scalars["bigint"]>;
  IsNewOwner?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamp"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerCoinDeactivateReactive_stddev_fields";
  AcknowledgementId?: Maybe<Scalars["Float"]>;
  ApplicationType?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsForwardingContact?: Maybe<Scalars["Float"]>;
  IsNewOwner?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Stddev_Order_By = {
  AcknowledgementId?: Maybe<Order_By>;
  ApplicationType?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsForwardingContact?: Maybe<Order_By>;
  IsNewOwner?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerCoinDeactivateReactive_stddev_pop_fields";
  AcknowledgementId?: Maybe<Scalars["Float"]>;
  ApplicationType?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsForwardingContact?: Maybe<Scalars["Float"]>;
  IsNewOwner?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Stddev_Pop_Order_By =
  {
    AcknowledgementId?: Maybe<Order_By>;
    ApplicationType?: Maybe<Order_By>;
    CreatedBy?: Maybe<Order_By>;
    CustomerId?: Maybe<Order_By>;
    Id?: Maybe<Order_By>;
    IsForcedAction?: Maybe<Order_By>;
    IsForwardingContact?: Maybe<Order_By>;
    IsNewOwner?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    ModifiedBy?: Maybe<Order_By>;
    StatusId?: Maybe<Order_By>;
  };

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Stddev_Samp_Fields =
  {
    __typename?: "rtcs_db_PH_kcca_CustomerCoinDeactivateReactive_stddev_samp_fields";
    AcknowledgementId?: Maybe<Scalars["Float"]>;
    ApplicationType?: Maybe<Scalars["Float"]>;
    CreatedBy?: Maybe<Scalars["Float"]>;
    CustomerId?: Maybe<Scalars["Float"]>;
    Id?: Maybe<Scalars["Float"]>;
    IsForcedAction?: Maybe<Scalars["Float"]>;
    IsForwardingContact?: Maybe<Scalars["Float"]>;
    IsNewOwner?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    ModifiedBy?: Maybe<Scalars["Float"]>;
    StatusId?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Stddev_Samp_Order_By =
  {
    AcknowledgementId?: Maybe<Order_By>;
    ApplicationType?: Maybe<Order_By>;
    CreatedBy?: Maybe<Order_By>;
    CustomerId?: Maybe<Order_By>;
    Id?: Maybe<Order_By>;
    IsForcedAction?: Maybe<Order_By>;
    IsForwardingContact?: Maybe<Order_By>;
    IsNewOwner?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    ModifiedBy?: Maybe<Order_By>;
    StatusId?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerCoinDeactivateReactive_sum_fields";
  AcknowledgementId?: Maybe<Scalars["bigint"]>;
  ApplicationType?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsForwardingContact?: Maybe<Scalars["bigint"]>;
  IsNewOwner?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Sum_Order_By = {
  AcknowledgementId?: Maybe<Order_By>;
  ApplicationType?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsForwardingContact?: Maybe<Order_By>;
  IsNewOwner?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerCoinDeactivateReactive_var_pop_fields";
  AcknowledgementId?: Maybe<Scalars["Float"]>;
  ApplicationType?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsForwardingContact?: Maybe<Scalars["Float"]>;
  IsNewOwner?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Var_Pop_Order_By = {
  AcknowledgementId?: Maybe<Order_By>;
  ApplicationType?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsForwardingContact?: Maybe<Order_By>;
  IsNewOwner?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerCoinDeactivateReactive_var_samp_fields";
  AcknowledgementId?: Maybe<Scalars["Float"]>;
  ApplicationType?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsForwardingContact?: Maybe<Scalars["Float"]>;
  IsNewOwner?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Var_Samp_Order_By = {
  AcknowledgementId?: Maybe<Order_By>;
  ApplicationType?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsForwardingContact?: Maybe<Order_By>;
  IsNewOwner?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerCoinDeactivateReactive_variance_fields";
  AcknowledgementId?: Maybe<Scalars["Float"]>;
  ApplicationType?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsForwardingContact?: Maybe<Scalars["Float"]>;
  IsNewOwner?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Variance_Order_By = {
  AcknowledgementId?: Maybe<Order_By>;
  ApplicationType?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsForwardingContact?: Maybe<Order_By>;
  IsNewOwner?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_CustomerContactPerson" */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson = {
  __typename?: "rtcs_db_PH_kcca_CustomerContactPerson";
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  OtherTitle?: Maybe<Scalars["String"]>;
  RelationshipDesignation?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_CustomerContactPerson" */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_CustomerContactPerson_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_CustomerContactPerson>;
};

/** aggregate fields of "rtcs_db.PH_kcca_CustomerContactPerson" */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerContactPerson_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_CustomerContactPerson" */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_CustomerContactPerson" */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_CustomerContactPerson" */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerContactPerson_avg_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_CustomerContactPerson" */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Avg_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_CustomerContactPerson". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Bool_Exp = {
  AcquiredName?: Maybe<String_Comparison_Exp>;
  Coin?: Maybe<Float8_Comparison_Exp>;
  CreatedBy?: Maybe<Float8_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamptz_Comparison_Exp>;
  CustomerId?: Maybe<Bigint_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  Mobile?: Maybe<String_Comparison_Exp>;
  ModifiedBy?: Maybe<String_Comparison_Exp>;
  ModifiedDate?: Maybe<Timestamptz_Comparison_Exp>;
  MotherMaidenName?: Maybe<String_Comparison_Exp>;
  OtherTitle?: Maybe<String_Comparison_Exp>;
  RelationshipDesignation?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Float8_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Telephone?: Maybe<String_Comparison_Exp>;
  TitleId?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_CustomerContactPerson" */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Inc_Input = {
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TitleId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_CustomerContactPerson" */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Insert_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  OtherTitle?: Maybe<Scalars["String"]>;
  RelationshipDesignation?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerContactPerson_max_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  OtherTitle?: Maybe<Scalars["String"]>;
  RelationshipDesignation?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_CustomerContactPerson" */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Max_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
  RelationshipDesignation?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerContactPerson_min_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  OtherTitle?: Maybe<Scalars["String"]>;
  RelationshipDesignation?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_CustomerContactPerson" */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Min_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
  RelationshipDesignation?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_CustomerContactPerson" */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_CustomerContactPerson_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_CustomerContactPerson>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_CustomerContactPerson" */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_CustomerContactPerson_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_CustomerContactPerson" */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
  RelationshipDesignation?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_CustomerContactPerson" */
export enum Rtcs_Db_Ph_Kcca_CustomerContactPerson_Select_Column {
  /** column name */
  AcquiredName = "AcquiredName",
  /** column name */
  Coin = "Coin",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  Email = "Email",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  MotherMaidenName = "MotherMaidenName",
  /** column name */
  OtherTitle = "OtherTitle",
  /** column name */
  RelationshipDesignation = "RelationshipDesignation",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  Surname = "Surname",
  /** column name */
  Telephone = "Telephone",
  /** column name */
  TitleId = "TitleId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_CustomerContactPerson" */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Set_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  OtherTitle?: Maybe<Scalars["String"]>;
  RelationshipDesignation?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerContactPerson_stddev_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_CustomerContactPerson" */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Stddev_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerContactPerson_stddev_pop_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_CustomerContactPerson" */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Stddev_Pop_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerContactPerson_stddev_samp_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_CustomerContactPerson" */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Stddev_Samp_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerContactPerson_sum_fields";
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TitleId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_CustomerContactPerson" */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Sum_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerContactPerson_var_pop_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_CustomerContactPerson" */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Var_Pop_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerContactPerson_var_samp_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_CustomerContactPerson" */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Var_Samp_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerContactPerson_variance_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_CustomerContactPerson" */
export type Rtcs_Db_Ph_Kcca_CustomerContactPerson_Variance_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_CustomerDocumentItem" */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem = {
  __typename?: "rtcs_db_PH_kcca_CustomerDocumentItem";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  DocumentId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MovementType?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_CustomerDocumentItem" */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_CustomerDocumentItem_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_CustomerDocumentItem>;
};

/** aggregate fields of "rtcs_db.PH_kcca_CustomerDocumentItem" */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerDocumentItem_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_CustomerDocumentItem" */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_CustomerDocumentItem" */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_CustomerDocumentItem" */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerDocumentItem_avg_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  DocumentId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MovementType?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_CustomerDocumentItem" */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Avg_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MovementType?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_CustomerDocumentItem". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Bool_Exp = {
  CreatedBy?: Maybe<Bigint_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamptz_Comparison_Exp>;
  DocumentId?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MovementType?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_CustomerDocumentItem" */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Inc_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  DocumentId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MovementType?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_CustomerDocumentItem" */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Insert_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  DocumentId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MovementType?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerDocumentItem_max_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  DocumentId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MovementType?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_CustomerDocumentItem" */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Max_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MovementType?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerDocumentItem_min_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  DocumentId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MovementType?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_CustomerDocumentItem" */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Min_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MovementType?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_CustomerDocumentItem" */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_CustomerDocumentItem_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_CustomerDocumentItem>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_CustomerDocumentItem" */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_CustomerDocumentItem" */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MovementType?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_CustomerDocumentItem" */
export enum Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Select_Column {
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  DocumentId = "DocumentId",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MovementType = "MovementType",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_CustomerDocumentItem" */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Set_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  DocumentId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MovementType?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerDocumentItem_stddev_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  DocumentId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MovementType?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_CustomerDocumentItem" */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Stddev_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MovementType?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerDocumentItem_stddev_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  DocumentId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MovementType?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_CustomerDocumentItem" */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Stddev_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MovementType?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerDocumentItem_stddev_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  DocumentId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MovementType?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_CustomerDocumentItem" */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Stddev_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MovementType?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerDocumentItem_sum_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  DocumentId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MovementType?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_CustomerDocumentItem" */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Sum_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MovementType?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerDocumentItem_var_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  DocumentId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MovementType?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_CustomerDocumentItem" */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Var_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MovementType?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerDocumentItem_var_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  DocumentId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MovementType?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_CustomerDocumentItem" */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Var_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MovementType?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerDocumentItem_variance_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  DocumentId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MovementType?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_CustomerDocumentItem" */
export type Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Variance_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MovementType?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_CustomerGuardian" */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian = {
  __typename?: "rtcs_db_PH_kcca_CustomerGuardian";
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_CustomerGuardian" */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_CustomerGuardian_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_CustomerGuardian>;
};

/** aggregate fields of "rtcs_db.PH_kcca_CustomerGuardian" */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerGuardian_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_CustomerGuardian" */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerGuardian_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_CustomerGuardian" */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_CustomerGuardian" */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_CustomerGuardian_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerGuardian_avg_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_CustomerGuardian" */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Avg_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_CustomerGuardian". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Bool_Exp = {
  AcquiredName?: Maybe<String_Comparison_Exp>;
  Coin?: Maybe<Float8_Comparison_Exp>;
  CreatedBy?: Maybe<Bigint_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamp_Comparison_Exp>;
  CustomerId?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamp_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_CustomerGuardian" */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Inc_Input = {
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_CustomerGuardian" */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Insert_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerGuardian_max_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_CustomerGuardian" */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Max_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerGuardian_min_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_CustomerGuardian" */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Min_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_CustomerGuardian" */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_CustomerGuardian_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_CustomerGuardian>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_CustomerGuardian" */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_CustomerGuardian_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_CustomerGuardian" */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_CustomerGuardian" */
export enum Rtcs_Db_Ph_Kcca_CustomerGuardian_Select_Column {
  /** column name */
  AcquiredName = "AcquiredName",
  /** column name */
  Coin = "Coin",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  Surname = "Surname",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_CustomerGuardian" */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Set_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerGuardian_stddev_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_CustomerGuardian" */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Stddev_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerGuardian_stddev_pop_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_CustomerGuardian" */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Stddev_Pop_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerGuardian_stddev_samp_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_CustomerGuardian" */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Stddev_Samp_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerGuardian_sum_fields";
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_CustomerGuardian" */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Sum_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerGuardian_var_pop_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_CustomerGuardian" */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Var_Pop_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerGuardian_var_samp_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_CustomerGuardian" */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Var_Samp_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerGuardian_variance_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_CustomerGuardian" */
export type Rtcs_Db_Ph_Kcca_CustomerGuardian_Variance_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_CustomerReference" */
export type Rtcs_Db_Ph_Kcca_CustomerReference = {
  __typename?: "rtcs_db_PH_kcca_CustomerReference";
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["float8"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  RelationshipDesignation?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_CustomerReference" */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_CustomerReference_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_CustomerReference>;
};

/** aggregate fields of "rtcs_db.PH_kcca_CustomerReference" */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerReference_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_CustomerReference" */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerReference_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_CustomerReference" */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_CustomerReference" */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_CustomerReference_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerReference_avg_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_CustomerReference" */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Avg_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_CustomerReference". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Bool_Exp = {
  AcquiredName?: Maybe<String_Comparison_Exp>;
  Coin?: Maybe<Float8_Comparison_Exp>;
  CreatedBy?: Maybe<Float8_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamptz_Comparison_Exp>;
  CustomerId?: Maybe<Float8_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  Mobile?: Maybe<String_Comparison_Exp>;
  ModifiedBy?: Maybe<String_Comparison_Exp>;
  ModifiedDate?: Maybe<Timestamptz_Comparison_Exp>;
  RelationshipDesignation?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Float8_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Telephone?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_CustomerReference" */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Inc_Input = {
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_CustomerReference" */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Insert_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["float8"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  RelationshipDesignation?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerReference_max_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["float8"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  RelationshipDesignation?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_CustomerReference" */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Max_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  RelationshipDesignation?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerReference_min_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["float8"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  RelationshipDesignation?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_CustomerReference" */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Min_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  RelationshipDesignation?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_CustomerReference" */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_CustomerReference_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_CustomerReference>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_CustomerReference" */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_CustomerReference_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_CustomerReference" */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  RelationshipDesignation?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_CustomerReference" */
export enum Rtcs_Db_Ph_Kcca_CustomerReference_Select_Column {
  /** column name */
  AcquiredName = "AcquiredName",
  /** column name */
  Coin = "Coin",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  Email = "Email",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  RelationshipDesignation = "RelationshipDesignation",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  Surname = "Surname",
  /** column name */
  Telephone = "Telephone",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_CustomerReference" */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Set_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["float8"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  RelationshipDesignation?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerReference_stddev_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_CustomerReference" */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Stddev_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerReference_stddev_pop_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_CustomerReference" */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Stddev_Pop_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerReference_stddev_samp_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_CustomerReference" */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Stddev_Samp_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerReference_sum_fields";
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_CustomerReference" */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Sum_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerReference_var_pop_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_CustomerReference" */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Var_Pop_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerReference_var_samp_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_CustomerReference" */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Var_Samp_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerReference_variance_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_CustomerReference" */
export type Rtcs_Db_Ph_Kcca_CustomerReference_Variance_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason = {
  __typename?: "rtcs_db_PH_kcca_CustomerRegistrationReason";
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RevenueSystemCode?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_CustomerRegistrationReason_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason>;
};

/** aggregate fields of "rtcs_db.PH_kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerRegistrationReason_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerRegistrationReason_avg_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Avg_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_CustomerRegistrationReason". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Bool_Exp = {
  CreatedBy?: Maybe<Float8_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamptz_Comparison_Exp>;
  CustomerId?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  RevenueSystemCode?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Inc_Input = {
  CreatedBy?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Insert_Input = {
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RevenueSystemCode?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerRegistrationReason_max_fields";
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RevenueSystemCode?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Max_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RevenueSystemCode?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerRegistrationReason_min_fields";
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RevenueSystemCode?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Min_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RevenueSystemCode?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_CustomerRegistrationReason_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RevenueSystemCode?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_CustomerRegistrationReason" */
export enum Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Select_Column {
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  RevenueSystemCode = "RevenueSystemCode",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Set_Input = {
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RevenueSystemCode?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerRegistrationReason_stddev_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Stddev_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerRegistrationReason_stddev_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Stddev_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerRegistrationReason_stddev_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Stddev_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerRegistrationReason_sum_fields";
  CreatedBy?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Sum_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerRegistrationReason_var_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Var_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerRegistrationReason_var_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Var_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerRegistrationReason_variance_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Variance_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent = {
  __typename?: "rtcs_db_PH_kcca_CustomerRevenueAgent";
  AgentLegalName?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_CustomerRevenueAgent_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent>;
};

/** aggregate fields of "rtcs_db.PH_kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerRevenueAgent_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerRevenueAgent_avg_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Avg_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_CustomerRevenueAgent". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Bool_Exp = {
  AgentLegalName?: Maybe<String_Comparison_Exp>;
  CreatedBy?: Maybe<Bigint_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamptz_Comparison_Exp>;
  CustomerId?: Maybe<Bigint_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Mobile?: Maybe<String_Comparison_Exp>;
  ModifiedBy?: Maybe<Float8_Comparison_Exp>;
  ModifiedDate?: Maybe<Timestamptz_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  Telephone?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Inc_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Insert_Input = {
  AgentLegalName?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerRevenueAgent_max_fields";
  AgentLegalName?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Max_Order_By = {
  AgentLegalName?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerRevenueAgent_min_fields";
  AgentLegalName?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Min_Order_By = {
  AgentLegalName?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_CustomerRevenueAgent_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Order_By = {
  AgentLegalName?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_CustomerRevenueAgent" */
export enum Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Select_Column {
  /** column name */
  AgentLegalName = "AgentLegalName",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  Email = "Email",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  Telephone = "Telephone",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Set_Input = {
  AgentLegalName?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerRevenueAgent_stddev_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Stddev_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerRevenueAgent_stddev_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Stddev_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerRevenueAgent_stddev_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Stddev_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerRevenueAgent_sum_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Sum_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerRevenueAgent_var_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Var_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerRevenueAgent_var_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Var_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerRevenueAgent_variance_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Variance_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_CustomerType" */
export type Rtcs_Db_Ph_Kcca_CustomerType = {
  __typename?: "rtcs_db_PH_kcca_CustomerType";
  Description?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_CustomerType" */
export type Rtcs_Db_Ph_Kcca_CustomerType_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_CustomerType_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_CustomerType>;
};

/** aggregate fields of "rtcs_db.PH_kcca_CustomerType" */
export type Rtcs_Db_Ph_Kcca_CustomerType_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerType_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_CustomerType" */
export type Rtcs_Db_Ph_Kcca_CustomerType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerType_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_CustomerType" */
export type Rtcs_Db_Ph_Kcca_CustomerType_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_CustomerType" */
export type Rtcs_Db_Ph_Kcca_CustomerType_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_CustomerType_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_CustomerType_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerType_avg_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_CustomerType" */
export type Rtcs_Db_Ph_Kcca_CustomerType_Avg_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_CustomerType". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_CustomerType_Bool_Exp = {
  Description?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_CustomerType" */
export type Rtcs_Db_Ph_Kcca_CustomerType_Inc_Input = {
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_CustomerType" */
export type Rtcs_Db_Ph_Kcca_CustomerType_Insert_Input = {
  Description?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_CustomerType_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerType_max_fields";
  Description?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_CustomerType" */
export type Rtcs_Db_Ph_Kcca_CustomerType_Max_Order_By = {
  Description?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_CustomerType_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerType_min_fields";
  Description?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_CustomerType" */
export type Rtcs_Db_Ph_Kcca_CustomerType_Min_Order_By = {
  Description?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_CustomerType" */
export type Rtcs_Db_Ph_Kcca_CustomerType_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_CustomerType_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_CustomerType>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_CustomerType" */
export type Rtcs_Db_Ph_Kcca_CustomerType_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_CustomerType_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_CustomerType" */
export type Rtcs_Db_Ph_Kcca_CustomerType_Order_By = {
  Description?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_CustomerType" */
export enum Rtcs_Db_Ph_Kcca_CustomerType_Select_Column {
  /** column name */
  Description = "Description",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_CustomerType" */
export type Rtcs_Db_Ph_Kcca_CustomerType_Set_Input = {
  Description?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_CustomerType_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerType_stddev_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_CustomerType" */
export type Rtcs_Db_Ph_Kcca_CustomerType_Stddev_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_CustomerType_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerType_stddev_pop_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_CustomerType" */
export type Rtcs_Db_Ph_Kcca_CustomerType_Stddev_Pop_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_CustomerType_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerType_stddev_samp_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_CustomerType" */
export type Rtcs_Db_Ph_Kcca_CustomerType_Stddev_Samp_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_CustomerType_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerType_sum_fields";
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_CustomerType" */
export type Rtcs_Db_Ph_Kcca_CustomerType_Sum_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_CustomerType_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerType_var_pop_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_CustomerType" */
export type Rtcs_Db_Ph_Kcca_CustomerType_Var_Pop_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_CustomerType_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerType_var_samp_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_CustomerType" */
export type Rtcs_Db_Ph_Kcca_CustomerType_Var_Samp_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_CustomerType_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_CustomerType_variance_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_CustomerType" */
export type Rtcs_Db_Ph_Kcca_CustomerType_Variance_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregated selection of "rtcs_db.PH_kcca_Customer" */
export type Rtcs_Db_Ph_Kcca_Customer_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_Customer_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_Customer>;
};

/** aggregate fields of "rtcs_db.PH_kcca_Customer" */
export type Rtcs_Db_Ph_Kcca_Customer_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_Customer_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_Customer" */
export type Rtcs_Db_Ph_Kcca_Customer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_Customer" */
export type Rtcs_Db_Ph_Kcca_Customer_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_Customer" */
export type Rtcs_Db_Ph_Kcca_Customer_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_Customer_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_Customer_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_Customer_avg_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_Customer" */
export type Rtcs_Db_Ph_Kcca_Customer_Avg_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_Customer". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_Customer_Bool_Exp = {
  AcquiredName?: Maybe<String_Comparison_Exp>;
  Birthdate?: Maybe<Timestamp_Comparison_Exp>;
  BoxDistrictId?: Maybe<Float8_Comparison_Exp>;
  BoxNumber?: Maybe<String_Comparison_Exp>;
  Brn?: Maybe<String_Comparison_Exp>;
  BuildingName?: Maybe<String_Comparison_Exp>;
  BusinessName?: Maybe<String_Comparison_Exp>;
  CitizenshipCountryId?: Maybe<Float8_Comparison_Exp>;
  Coin?: Maybe<Float8_Comparison_Exp>;
  CreatedBy?: Maybe<String_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamptz_Comparison_Exp>;
  CustomerApplicantTypeId?: Maybe<Float8_Comparison_Exp>;
  CustomerBusinessTypeId?: Maybe<Float8_Comparison_Exp>;
  CustomerTypeId?: Maybe<Bigint_Comparison_Exp>;
  DateOfIncorporation?: Maybe<Timestamptz_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  EntityLegalName?: Maybe<String_Comparison_Exp>;
  Fax?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  FormerBusinessName?: Maybe<String_Comparison_Exp>;
  GenderId?: Maybe<Float8_Comparison_Exp>;
  HaveAlternatePrimaryContact?: Maybe<Float8_Comparison_Exp>;
  HaveReference?: Maybe<Float8_Comparison_Exp>;
  HaveRevenueAgent?: Maybe<Float8_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  IdentificationDiplomaticId?: Maybe<String_Comparison_Exp>;
  IdentificationDrivingPermit?: Maybe<String_Comparison_Exp>;
  IdentificationEmployeeId?: Maybe<String_Comparison_Exp>;
  IdentificationFinancialCard?: Maybe<String_Comparison_Exp>;
  IdentificationNationalId?: Maybe<String_Comparison_Exp>;
  IdentificationNssfNumber?: Maybe<String_Comparison_Exp>;
  IdentificationPassportNo?: Maybe<String_Comparison_Exp>;
  IdentificationRefugeeId?: Maybe<String_Comparison_Exp>;
  IdentificationVillageId?: Maybe<String_Comparison_Exp>;
  IdentificationVoterId?: Maybe<String_Comparison_Exp>;
  IdentificationWorkId?: Maybe<String_Comparison_Exp>;
  IdentificationWorkPermit?: Maybe<String_Comparison_Exp>;
  IsForcedAction?: Maybe<Float8_Comparison_Exp>;
  IsMinor?: Maybe<Float8_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  Mobile?: Maybe<String_Comparison_Exp>;
  MotherMaidenName?: Maybe<String_Comparison_Exp>;
  Photo?: Maybe<Float8_Comparison_Exp>;
  PhotoContent?: Maybe<Float8_Comparison_Exp>;
  PhotoMime?: Maybe<Float8_Comparison_Exp>;
  PhotoName?: Maybe<Float8_Comparison_Exp>;
  PhotoSize?: Maybe<Float8_Comparison_Exp>;
  PlotNumber?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressBuildingName?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressCountyId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressDistrictId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressParishId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressPlotNumber?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressStreetName?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressSubCountyId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressTradingCenter?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressVillageId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessName?: Maybe<String_Comparison_Exp>;
  ResidentialAddressCountyId?: Maybe<Float8_Comparison_Exp>;
  ResidentialAddressDistrictId?: Maybe<Float8_Comparison_Exp>;
  ResidentialAddressParishId?: Maybe<Float8_Comparison_Exp>;
  ResidentialAddressSubCountyId?: Maybe<Float8_Comparison_Exp>;
  ResidentialAddressVillageId?: Maybe<Float8_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  SecondMobile?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Float8_Comparison_Exp>;
  StreetName?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Telephone?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<String_Comparison_Exp>;
  TitleId?: Maybe<Float8_Comparison_Exp>;
  TradingCenter?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_Customer_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_Customer_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_Customer" */
export type Rtcs_Db_Ph_Kcca_Customer_Inc_Input = {
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TitleId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_Customer" */
export type Rtcs_Db_Ph_Kcca_Customer_Insert_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["timestamp"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["String"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_Customer_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_Customer_max_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["timestamp"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["String"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_Customer" */
export type Rtcs_Db_Ph_Kcca_Customer_Max_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Birthdate?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  BoxNumber?: Maybe<Order_By>;
  Brn?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FormerBusinessName?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationDiplomaticId?: Maybe<Order_By>;
  IdentificationDrivingPermit?: Maybe<Order_By>;
  IdentificationEmployeeId?: Maybe<Order_By>;
  IdentificationFinancialCard?: Maybe<Order_By>;
  IdentificationNationalId?: Maybe<Order_By>;
  IdentificationNssfNumber?: Maybe<Order_By>;
  IdentificationPassportNo?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationVillageId?: Maybe<Order_By>;
  IdentificationVoterId?: Maybe<Order_By>;
  IdentificationWorkId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressBuildingName?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressStreetName?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressTradingCenter?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetName?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TradingCenter?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_Customer_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_Customer_min_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["timestamp"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["String"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_Customer" */
export type Rtcs_Db_Ph_Kcca_Customer_Min_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Birthdate?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  BoxNumber?: Maybe<Order_By>;
  Brn?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FormerBusinessName?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationDiplomaticId?: Maybe<Order_By>;
  IdentificationDrivingPermit?: Maybe<Order_By>;
  IdentificationEmployeeId?: Maybe<Order_By>;
  IdentificationFinancialCard?: Maybe<Order_By>;
  IdentificationNationalId?: Maybe<Order_By>;
  IdentificationNssfNumber?: Maybe<Order_By>;
  IdentificationPassportNo?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationVillageId?: Maybe<Order_By>;
  IdentificationVoterId?: Maybe<Order_By>;
  IdentificationWorkId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressBuildingName?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressStreetName?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressTradingCenter?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetName?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TradingCenter?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_Customer" */
export type Rtcs_Db_Ph_Kcca_Customer_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_Customer_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_Customer>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_Customer" */
export type Rtcs_Db_Ph_Kcca_Customer_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_Customer_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_Customer" */
export type Rtcs_Db_Ph_Kcca_Customer_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Birthdate?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  BoxNumber?: Maybe<Order_By>;
  Brn?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FormerBusinessName?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationDiplomaticId?: Maybe<Order_By>;
  IdentificationDrivingPermit?: Maybe<Order_By>;
  IdentificationEmployeeId?: Maybe<Order_By>;
  IdentificationFinancialCard?: Maybe<Order_By>;
  IdentificationNationalId?: Maybe<Order_By>;
  IdentificationNssfNumber?: Maybe<Order_By>;
  IdentificationPassportNo?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationVillageId?: Maybe<Order_By>;
  IdentificationVoterId?: Maybe<Order_By>;
  IdentificationWorkId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressBuildingName?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressStreetName?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressTradingCenter?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetName?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TradingCenter?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_Customer" */
export enum Rtcs_Db_Ph_Kcca_Customer_Select_Column {
  /** column name */
  AcquiredName = "AcquiredName",
  /** column name */
  Birthdate = "Birthdate",
  /** column name */
  BoxDistrictId = "BoxDistrictId",
  /** column name */
  BoxNumber = "BoxNumber",
  /** column name */
  Brn = "Brn",
  /** column name */
  BuildingName = "BuildingName",
  /** column name */
  BusinessName = "BusinessName",
  /** column name */
  CitizenshipCountryId = "CitizenshipCountryId",
  /** column name */
  Coin = "Coin",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerApplicantTypeId = "CustomerApplicantTypeId",
  /** column name */
  CustomerBusinessTypeId = "CustomerBusinessTypeId",
  /** column name */
  CustomerTypeId = "CustomerTypeId",
  /** column name */
  DateOfIncorporation = "DateOfIncorporation",
  /** column name */
  Email = "Email",
  /** column name */
  EntityLegalName = "EntityLegalName",
  /** column name */
  Fax = "Fax",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  FormerBusinessName = "FormerBusinessName",
  /** column name */
  GenderId = "GenderId",
  /** column name */
  HaveAlternatePrimaryContact = "HaveAlternatePrimaryContact",
  /** column name */
  HaveReference = "HaveReference",
  /** column name */
  HaveRevenueAgent = "HaveRevenueAgent",
  /** column name */
  Id = "Id",
  /** column name */
  IdentificationDiplomaticId = "IdentificationDiplomaticId",
  /** column name */
  IdentificationDrivingPermit = "IdentificationDrivingPermit",
  /** column name */
  IdentificationEmployeeId = "IdentificationEmployeeId",
  /** column name */
  IdentificationFinancialCard = "IdentificationFinancialCard",
  /** column name */
  IdentificationNationalId = "IdentificationNationalId",
  /** column name */
  IdentificationNssfNumber = "IdentificationNssfNumber",
  /** column name */
  IdentificationPassportNo = "IdentificationPassportNo",
  /** column name */
  IdentificationRefugeeId = "IdentificationRefugeeId",
  /** column name */
  IdentificationVillageId = "IdentificationVillageId",
  /** column name */
  IdentificationVoterId = "IdentificationVoterId",
  /** column name */
  IdentificationWorkId = "IdentificationWorkId",
  /** column name */
  IdentificationWorkPermit = "IdentificationWorkPermit",
  /** column name */
  IsForcedAction = "IsForcedAction",
  /** column name */
  IsMinor = "IsMinor",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  MotherMaidenName = "MotherMaidenName",
  /** column name */
  Photo = "Photo",
  /** column name */
  PhotoContent = "PhotoContent",
  /** column name */
  PhotoMime = "PhotoMime",
  /** column name */
  PhotoName = "PhotoName",
  /** column name */
  PhotoSize = "PhotoSize",
  /** column name */
  PlotNumber = "PlotNumber",
  /** column name */
  PreviousBusinessAddressBuildingName = "PreviousBusinessAddressBuildingName",
  /** column name */
  PreviousBusinessAddressCountyId = "PreviousBusinessAddressCountyId",
  /** column name */
  PreviousBusinessAddressDistrictId = "PreviousBusinessAddressDistrictId",
  /** column name */
  PreviousBusinessAddressParishId = "PreviousBusinessAddressParishId",
  /** column name */
  PreviousBusinessAddressPlotNumber = "PreviousBusinessAddressPlotNumber",
  /** column name */
  PreviousBusinessAddressStreetName = "PreviousBusinessAddressStreetName",
  /** column name */
  PreviousBusinessAddressSubCountyId = "PreviousBusinessAddressSubCountyId",
  /** column name */
  PreviousBusinessAddressTradingCenter = "PreviousBusinessAddressTradingCenter",
  /** column name */
  PreviousBusinessAddressVillageId = "PreviousBusinessAddressVillageId",
  /** column name */
  PreviousBusinessName = "PreviousBusinessName",
  /** column name */
  ResidentialAddressCountyId = "ResidentialAddressCountyId",
  /** column name */
  ResidentialAddressDistrictId = "ResidentialAddressDistrictId",
  /** column name */
  ResidentialAddressParishId = "ResidentialAddressParishId",
  /** column name */
  ResidentialAddressSubCountyId = "ResidentialAddressSubCountyId",
  /** column name */
  ResidentialAddressVillageId = "ResidentialAddressVillageId",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  SecondMobile = "SecondMobile",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  StreetName = "StreetName",
  /** column name */
  Surname = "Surname",
  /** column name */
  Telephone = "Telephone",
  /** column name */
  Tin = "Tin",
  /** column name */
  TitleId = "TitleId",
  /** column name */
  TradingCenter = "TradingCenter",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_Customer" */
export type Rtcs_Db_Ph_Kcca_Customer_Set_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["timestamp"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["String"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.PH_kcca_Customer_sid" */
export type Rtcs_Db_Ph_Kcca_Customer_Sid = {
  __typename?: "rtcs_db_PH_kcca_Customer_sid";
  AcquiredName?: Maybe<Scalars["String"]>;
  Balance?: Maybe<Scalars["float8"]>;
  Birthdate?: Maybe<Scalars["date"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["date"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  TransactionalStatus?: Maybe<Scalars["float8"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_Customer_sid" */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_Customer_sid_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_Customer_Sid>;
};

/** aggregate fields of "rtcs_db.PH_kcca_Customer_sid" */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_Customer_sid_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_Customer_sid" */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Sid_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_Customer_sid" */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_Customer_sid" */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_Customer_Sid_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_Customer_sid_avg_fields";
  Balance?: Maybe<Scalars["Float"]>;
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
  TransactionalStatus?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_Customer_sid" */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Avg_Order_By = {
  Balance?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TransactionalStatus?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_Customer_sid". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Bool_Exp = {
  AcquiredName?: Maybe<String_Comparison_Exp>;
  Balance?: Maybe<Float8_Comparison_Exp>;
  Birthdate?: Maybe<Date_Comparison_Exp>;
  BoxDistrictId?: Maybe<Float8_Comparison_Exp>;
  BoxNumber?: Maybe<String_Comparison_Exp>;
  BuildingName?: Maybe<String_Comparison_Exp>;
  BusinessName?: Maybe<String_Comparison_Exp>;
  CitizenshipCountryId?: Maybe<Float8_Comparison_Exp>;
  Coin?: Maybe<Float8_Comparison_Exp>;
  CreatedBy?: Maybe<Float8_Comparison_Exp>;
  CreatedDate?: Maybe<String_Comparison_Exp>;
  CustomerApplicantTypeId?: Maybe<Float8_Comparison_Exp>;
  CustomerBusinessTypeId?: Maybe<Float8_Comparison_Exp>;
  CustomerTypeId?: Maybe<Bigint_Comparison_Exp>;
  DateOfIncorporation?: Maybe<Date_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  EntityLegalName?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  FormerBusinessName?: Maybe<String_Comparison_Exp>;
  GenderId?: Maybe<Float8_Comparison_Exp>;
  HaveAlternatePrimaryContact?: Maybe<Float8_Comparison_Exp>;
  HaveReference?: Maybe<Float8_Comparison_Exp>;
  HaveRevenueAgent?: Maybe<Float8_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  IdentificationDiplomaticId?: Maybe<String_Comparison_Exp>;
  IdentificationDrivingPermit?: Maybe<String_Comparison_Exp>;
  IdentificationEmployeeId?: Maybe<String_Comparison_Exp>;
  IdentificationFinancialCard?: Maybe<String_Comparison_Exp>;
  IdentificationNationalId?: Maybe<String_Comparison_Exp>;
  IdentificationNssfNumber?: Maybe<String_Comparison_Exp>;
  IdentificationPassportNo?: Maybe<String_Comparison_Exp>;
  IdentificationRefugeeId?: Maybe<String_Comparison_Exp>;
  IdentificationVillageId?: Maybe<String_Comparison_Exp>;
  IdentificationVoterId?: Maybe<String_Comparison_Exp>;
  IdentificationWorkId?: Maybe<String_Comparison_Exp>;
  IdentificationWorkPermit?: Maybe<String_Comparison_Exp>;
  IsForcedAction?: Maybe<Float8_Comparison_Exp>;
  IsMinor?: Maybe<Float8_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  Mobile?: Maybe<String_Comparison_Exp>;
  ModifiedBy?: Maybe<Float8_Comparison_Exp>;
  ModifiedDate?: Maybe<String_Comparison_Exp>;
  MotherMaidenName?: Maybe<String_Comparison_Exp>;
  PlotNumber?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressBuildingName?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressCountyId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressDistrictId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressParishId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressPlotNumber?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressStreetName?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressSubCountyId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressTradingCenter?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressVillageId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessName?: Maybe<Float8_Comparison_Exp>;
  ResidentialAddressCountyId?: Maybe<Float8_Comparison_Exp>;
  ResidentialAddressDistrictId?: Maybe<Float8_Comparison_Exp>;
  ResidentialAddressParishId?: Maybe<Float8_Comparison_Exp>;
  ResidentialAddressSubCountyId?: Maybe<Float8_Comparison_Exp>;
  ResidentialAddressVillageId?: Maybe<Float8_Comparison_Exp>;
  SecondMobile?: Maybe<String_Comparison_Exp>;
  Sid?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Float8_Comparison_Exp>;
  StreetName?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Telephone?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<String_Comparison_Exp>;
  TitleId?: Maybe<Float8_Comparison_Exp>;
  TradingCenter?: Maybe<String_Comparison_Exp>;
  TransactionalStatus?: Maybe<Float8_Comparison_Exp>;
  VisibleLevel?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_Customer_sid" */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Inc_Input = {
  Balance?: Maybe<Scalars["float8"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TransactionalStatus?: Maybe<Scalars["float8"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_Customer_sid" */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Insert_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  Balance?: Maybe<Scalars["float8"]>;
  Birthdate?: Maybe<Scalars["date"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["date"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  TransactionalStatus?: Maybe<Scalars["float8"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_Customer_sid_max_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  Balance?: Maybe<Scalars["float8"]>;
  Birthdate?: Maybe<Scalars["date"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["date"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  TransactionalStatus?: Maybe<Scalars["float8"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_Customer_sid" */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Max_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Balance?: Maybe<Order_By>;
  Birthdate?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  BoxNumber?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FormerBusinessName?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationDiplomaticId?: Maybe<Order_By>;
  IdentificationDrivingPermit?: Maybe<Order_By>;
  IdentificationEmployeeId?: Maybe<Order_By>;
  IdentificationFinancialCard?: Maybe<Order_By>;
  IdentificationNationalId?: Maybe<Order_By>;
  IdentificationNssfNumber?: Maybe<Order_By>;
  IdentificationPassportNo?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationVillageId?: Maybe<Order_By>;
  IdentificationVoterId?: Maybe<Order_By>;
  IdentificationWorkId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  PlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressBuildingName?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressStreetName?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressTradingCenter?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  Sid?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetName?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TradingCenter?: Maybe<Order_By>;
  TransactionalStatus?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_Customer_sid_min_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  Balance?: Maybe<Scalars["float8"]>;
  Birthdate?: Maybe<Scalars["date"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["date"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  TransactionalStatus?: Maybe<Scalars["float8"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_Customer_sid" */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Min_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Balance?: Maybe<Order_By>;
  Birthdate?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  BoxNumber?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FormerBusinessName?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationDiplomaticId?: Maybe<Order_By>;
  IdentificationDrivingPermit?: Maybe<Order_By>;
  IdentificationEmployeeId?: Maybe<Order_By>;
  IdentificationFinancialCard?: Maybe<Order_By>;
  IdentificationNationalId?: Maybe<Order_By>;
  IdentificationNssfNumber?: Maybe<Order_By>;
  IdentificationPassportNo?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationVillageId?: Maybe<Order_By>;
  IdentificationVoterId?: Maybe<Order_By>;
  IdentificationWorkId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  PlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressBuildingName?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressStreetName?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressTradingCenter?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  Sid?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetName?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TradingCenter?: Maybe<Order_By>;
  TransactionalStatus?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_Customer_sid" */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_Customer_sid_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_Customer_Sid>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_Customer_sid" */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_Customer_Sid_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_Customer_sid" */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Balance?: Maybe<Order_By>;
  Birthdate?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  BoxNumber?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FormerBusinessName?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationDiplomaticId?: Maybe<Order_By>;
  IdentificationDrivingPermit?: Maybe<Order_By>;
  IdentificationEmployeeId?: Maybe<Order_By>;
  IdentificationFinancialCard?: Maybe<Order_By>;
  IdentificationNationalId?: Maybe<Order_By>;
  IdentificationNssfNumber?: Maybe<Order_By>;
  IdentificationPassportNo?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationVillageId?: Maybe<Order_By>;
  IdentificationVoterId?: Maybe<Order_By>;
  IdentificationWorkId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  PlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressBuildingName?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressStreetName?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressTradingCenter?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  Sid?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetName?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TradingCenter?: Maybe<Order_By>;
  TransactionalStatus?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_Customer_sid" */
export enum Rtcs_Db_Ph_Kcca_Customer_Sid_Select_Column {
  /** column name */
  AcquiredName = "AcquiredName",
  /** column name */
  Balance = "Balance",
  /** column name */
  Birthdate = "Birthdate",
  /** column name */
  BoxDistrictId = "BoxDistrictId",
  /** column name */
  BoxNumber = "BoxNumber",
  /** column name */
  BuildingName = "BuildingName",
  /** column name */
  BusinessName = "BusinessName",
  /** column name */
  CitizenshipCountryId = "CitizenshipCountryId",
  /** column name */
  Coin = "Coin",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerApplicantTypeId = "CustomerApplicantTypeId",
  /** column name */
  CustomerBusinessTypeId = "CustomerBusinessTypeId",
  /** column name */
  CustomerTypeId = "CustomerTypeId",
  /** column name */
  DateOfIncorporation = "DateOfIncorporation",
  /** column name */
  Email = "Email",
  /** column name */
  EntityLegalName = "EntityLegalName",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  FormerBusinessName = "FormerBusinessName",
  /** column name */
  GenderId = "GenderId",
  /** column name */
  HaveAlternatePrimaryContact = "HaveAlternatePrimaryContact",
  /** column name */
  HaveReference = "HaveReference",
  /** column name */
  HaveRevenueAgent = "HaveRevenueAgent",
  /** column name */
  Id = "Id",
  /** column name */
  IdentificationDiplomaticId = "IdentificationDiplomaticId",
  /** column name */
  IdentificationDrivingPermit = "IdentificationDrivingPermit",
  /** column name */
  IdentificationEmployeeId = "IdentificationEmployeeId",
  /** column name */
  IdentificationFinancialCard = "IdentificationFinancialCard",
  /** column name */
  IdentificationNationalId = "IdentificationNationalId",
  /** column name */
  IdentificationNssfNumber = "IdentificationNssfNumber",
  /** column name */
  IdentificationPassportNo = "IdentificationPassportNo",
  /** column name */
  IdentificationRefugeeId = "IdentificationRefugeeId",
  /** column name */
  IdentificationVillageId = "IdentificationVillageId",
  /** column name */
  IdentificationVoterId = "IdentificationVoterId",
  /** column name */
  IdentificationWorkId = "IdentificationWorkId",
  /** column name */
  IdentificationWorkPermit = "IdentificationWorkPermit",
  /** column name */
  IsForcedAction = "IsForcedAction",
  /** column name */
  IsMinor = "IsMinor",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  MotherMaidenName = "MotherMaidenName",
  /** column name */
  PlotNumber = "PlotNumber",
  /** column name */
  PreviousBusinessAddressBuildingName = "PreviousBusinessAddressBuildingName",
  /** column name */
  PreviousBusinessAddressCountyId = "PreviousBusinessAddressCountyId",
  /** column name */
  PreviousBusinessAddressDistrictId = "PreviousBusinessAddressDistrictId",
  /** column name */
  PreviousBusinessAddressParishId = "PreviousBusinessAddressParishId",
  /** column name */
  PreviousBusinessAddressPlotNumber = "PreviousBusinessAddressPlotNumber",
  /** column name */
  PreviousBusinessAddressStreetName = "PreviousBusinessAddressStreetName",
  /** column name */
  PreviousBusinessAddressSubCountyId = "PreviousBusinessAddressSubCountyId",
  /** column name */
  PreviousBusinessAddressTradingCenter = "PreviousBusinessAddressTradingCenter",
  /** column name */
  PreviousBusinessAddressVillageId = "PreviousBusinessAddressVillageId",
  /** column name */
  PreviousBusinessName = "PreviousBusinessName",
  /** column name */
  ResidentialAddressCountyId = "ResidentialAddressCountyId",
  /** column name */
  ResidentialAddressDistrictId = "ResidentialAddressDistrictId",
  /** column name */
  ResidentialAddressParishId = "ResidentialAddressParishId",
  /** column name */
  ResidentialAddressSubCountyId = "ResidentialAddressSubCountyId",
  /** column name */
  ResidentialAddressVillageId = "ResidentialAddressVillageId",
  /** column name */
  SecondMobile = "SecondMobile",
  /** column name */
  Sid = "Sid",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  StreetName = "StreetName",
  /** column name */
  Surname = "Surname",
  /** column name */
  Telephone = "Telephone",
  /** column name */
  Tin = "Tin",
  /** column name */
  TitleId = "TitleId",
  /** column name */
  TradingCenter = "TradingCenter",
  /** column name */
  TransactionalStatus = "TransactionalStatus",
  /** column name */
  VisibleLevel = "VisibleLevel",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_Customer_sid" */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Set_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  Balance?: Maybe<Scalars["float8"]>;
  Birthdate?: Maybe<Scalars["date"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["date"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  TransactionalStatus?: Maybe<Scalars["float8"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_Customer_sid_stddev_fields";
  Balance?: Maybe<Scalars["Float"]>;
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
  TransactionalStatus?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_Customer_sid" */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Stddev_Order_By = {
  Balance?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TransactionalStatus?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_Customer_sid_stddev_pop_fields";
  Balance?: Maybe<Scalars["Float"]>;
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
  TransactionalStatus?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_Customer_sid" */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Stddev_Pop_Order_By = {
  Balance?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TransactionalStatus?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_Customer_sid_stddev_samp_fields";
  Balance?: Maybe<Scalars["Float"]>;
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
  TransactionalStatus?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_Customer_sid" */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Stddev_Samp_Order_By = {
  Balance?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TransactionalStatus?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_Customer_sid_sum_fields";
  Balance?: Maybe<Scalars["float8"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TransactionalStatus?: Maybe<Scalars["float8"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_Customer_sid" */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Sum_Order_By = {
  Balance?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TransactionalStatus?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_Customer_sid_var_pop_fields";
  Balance?: Maybe<Scalars["Float"]>;
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
  TransactionalStatus?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_Customer_sid" */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Var_Pop_Order_By = {
  Balance?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TransactionalStatus?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_Customer_sid_var_samp_fields";
  Balance?: Maybe<Scalars["Float"]>;
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
  TransactionalStatus?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_Customer_sid" */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Var_Samp_Order_By = {
  Balance?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TransactionalStatus?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_Customer_sid_variance_fields";
  Balance?: Maybe<Scalars["Float"]>;
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
  TransactionalStatus?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_Customer_sid" */
export type Rtcs_Db_Ph_Kcca_Customer_Sid_Variance_Order_By = {
  Balance?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TransactionalStatus?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_Customer_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_Customer_stddev_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_Customer" */
export type Rtcs_Db_Ph_Kcca_Customer_Stddev_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_Customer_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_Customer_stddev_pop_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_Customer" */
export type Rtcs_Db_Ph_Kcca_Customer_Stddev_Pop_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_Customer_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_Customer_stddev_samp_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_Customer" */
export type Rtcs_Db_Ph_Kcca_Customer_Stddev_Samp_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_Customer_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_Customer_sum_fields";
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TitleId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_Customer" */
export type Rtcs_Db_Ph_Kcca_Customer_Sum_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_Customer_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_Customer_var_pop_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_Customer" */
export type Rtcs_Db_Ph_Kcca_Customer_Var_Pop_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_Customer_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_Customer_var_samp_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_Customer" */
export type Rtcs_Db_Ph_Kcca_Customer_Var_Samp_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_Customer_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_Customer_variance_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_Customer" */
export type Rtcs_Db_Ph_Kcca_Customer_Variance_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_District" */
export type Rtcs_Db_Ph_Kcca_District = {
  __typename?: "rtcs_db_PH_kcca_District";
  CountryId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_District" */
export type Rtcs_Db_Ph_Kcca_District_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_District_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_District_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_District>;
};

/** aggregate fields of "rtcs_db.PH_kcca_District" */
export type Rtcs_Db_Ph_Kcca_District_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_District_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_District_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_District_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_District_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_District_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_District_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_District_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_District_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_District_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_District_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_District_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_District" */
export type Rtcs_Db_Ph_Kcca_District_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_District_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_District" */
export type Rtcs_Db_Ph_Kcca_District_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_District_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_District_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_District_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_District_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_District_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_District_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_District_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_District_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_District_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_District_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_District" */
export type Rtcs_Db_Ph_Kcca_District_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_District_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_District_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_District_avg_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_District" */
export type Rtcs_Db_Ph_Kcca_District_Avg_Order_By = {
  CountryId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_District". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_District_Bool_Exp = {
  CountryId?: Maybe<Bigint_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamp_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_District_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_District_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_District_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_District" */
export type Rtcs_Db_Ph_Kcca_District_Inc_Input = {
  CountryId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_District" */
export type Rtcs_Db_Ph_Kcca_District_Insert_Input = {
  CountryId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_District_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_District_max_fields";
  CountryId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_District" */
export type Rtcs_Db_Ph_Kcca_District_Max_Order_By = {
  CountryId?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_District_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_District_min_fields";
  CountryId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_District" */
export type Rtcs_Db_Ph_Kcca_District_Min_Order_By = {
  CountryId?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_District" */
export type Rtcs_Db_Ph_Kcca_District_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_District_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_District>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_District" */
export type Rtcs_Db_Ph_Kcca_District_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_District_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_District" */
export type Rtcs_Db_Ph_Kcca_District_Order_By = {
  CountryId?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_District" */
export enum Rtcs_Db_Ph_Kcca_District_Select_Column {
  /** column name */
  CountryId = "CountryId",
  /** column name */
  Description = "Description",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_District" */
export type Rtcs_Db_Ph_Kcca_District_Set_Input = {
  CountryId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_District_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_District_stddev_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_District" */
export type Rtcs_Db_Ph_Kcca_District_Stddev_Order_By = {
  CountryId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_District_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_District_stddev_pop_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_District" */
export type Rtcs_Db_Ph_Kcca_District_Stddev_Pop_Order_By = {
  CountryId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_District_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_District_stddev_samp_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_District" */
export type Rtcs_Db_Ph_Kcca_District_Stddev_Samp_Order_By = {
  CountryId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_District_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_District_sum_fields";
  CountryId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_District" */
export type Rtcs_Db_Ph_Kcca_District_Sum_Order_By = {
  CountryId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_District_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_District_var_pop_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_District" */
export type Rtcs_Db_Ph_Kcca_District_Var_Pop_Order_By = {
  CountryId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_District_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_District_var_samp_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_District" */
export type Rtcs_Db_Ph_Kcca_District_Var_Samp_Order_By = {
  CountryId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_District_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_District_variance_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_District" */
export type Rtcs_Db_Ph_Kcca_District_Variance_Order_By = {
  CountryId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_Division" */
export type Rtcs_Db_Ph_Kcca_Division = {
  __typename?: "rtcs_db_PH_kcca_Division";
  Address?: Maybe<Scalars["String"]>;
  CityId?: Maybe<Scalars["bigint"]>;
  Code?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_Division" */
export type Rtcs_Db_Ph_Kcca_Division_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_Division_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_Division_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_Division>;
};

/** aggregate fields of "rtcs_db.PH_kcca_Division" */
export type Rtcs_Db_Ph_Kcca_Division_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_Division_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_Division_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_Division_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_Division_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_Division_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_Division_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_Division_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_Division_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_Division_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_Division_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_Division_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_Division" */
export type Rtcs_Db_Ph_Kcca_Division_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_Division_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_Division" */
export type Rtcs_Db_Ph_Kcca_Division_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_Division_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_Division_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_Division_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_Division_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_Division_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_Division_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_Division_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_Division_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_Division_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_Division_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_Division" */
export type Rtcs_Db_Ph_Kcca_Division_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_Division_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_Division_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_Division_avg_fields";
  CityId?: Maybe<Scalars["Float"]>;
  Code?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_Division" */
export type Rtcs_Db_Ph_Kcca_Division_Avg_Order_By = {
  CityId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_Division". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_Division_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  CityId?: Maybe<Bigint_Comparison_Exp>;
  Code?: Maybe<Bigint_Comparison_Exp>;
  CreatedBy?: Maybe<Bigint_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamptz_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  ModifiedBy?: Maybe<Float8_Comparison_Exp>;
  ModifiedDate?: Maybe<Timestamptz_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  Telephone?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_Division_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_Division_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_Division_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_Division" */
export type Rtcs_Db_Ph_Kcca_Division_Inc_Input = {
  CityId?: Maybe<Scalars["bigint"]>;
  Code?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_Division" */
export type Rtcs_Db_Ph_Kcca_Division_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  CityId?: Maybe<Scalars["bigint"]>;
  Code?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_Division_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_Division_max_fields";
  Address?: Maybe<Scalars["String"]>;
  CityId?: Maybe<Scalars["bigint"]>;
  Code?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_Division" */
export type Rtcs_Db_Ph_Kcca_Division_Max_Order_By = {
  Address?: Maybe<Order_By>;
  CityId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_Division_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_Division_min_fields";
  Address?: Maybe<Scalars["String"]>;
  CityId?: Maybe<Scalars["bigint"]>;
  Code?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_Division" */
export type Rtcs_Db_Ph_Kcca_Division_Min_Order_By = {
  Address?: Maybe<Order_By>;
  CityId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_Division" */
export type Rtcs_Db_Ph_Kcca_Division_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_Division_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_Division>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_Division" */
export type Rtcs_Db_Ph_Kcca_Division_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_Division_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_Division" */
export type Rtcs_Db_Ph_Kcca_Division_Order_By = {
  Address?: Maybe<Order_By>;
  CityId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_Division" */
export enum Rtcs_Db_Ph_Kcca_Division_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  CityId = "CityId",
  /** column name */
  Code = "Code",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  Email = "Email",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  Telephone = "Telephone",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_Division" */
export type Rtcs_Db_Ph_Kcca_Division_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  CityId?: Maybe<Scalars["bigint"]>;
  Code?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_Division_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_Division_stddev_fields";
  CityId?: Maybe<Scalars["Float"]>;
  Code?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_Division" */
export type Rtcs_Db_Ph_Kcca_Division_Stddev_Order_By = {
  CityId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_Division_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_Division_stddev_pop_fields";
  CityId?: Maybe<Scalars["Float"]>;
  Code?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_Division" */
export type Rtcs_Db_Ph_Kcca_Division_Stddev_Pop_Order_By = {
  CityId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_Division_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_Division_stddev_samp_fields";
  CityId?: Maybe<Scalars["Float"]>;
  Code?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_Division" */
export type Rtcs_Db_Ph_Kcca_Division_Stddev_Samp_Order_By = {
  CityId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_Division_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_Division_sum_fields";
  CityId?: Maybe<Scalars["bigint"]>;
  Code?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_Division" */
export type Rtcs_Db_Ph_Kcca_Division_Sum_Order_By = {
  CityId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_Division_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_Division_var_pop_fields";
  CityId?: Maybe<Scalars["Float"]>;
  Code?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_Division" */
export type Rtcs_Db_Ph_Kcca_Division_Var_Pop_Order_By = {
  CityId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_Division_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_Division_var_samp_fields";
  CityId?: Maybe<Scalars["Float"]>;
  Code?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_Division" */
export type Rtcs_Db_Ph_Kcca_Division_Var_Samp_Order_By = {
  CityId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_Division_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_Division_variance_fields";
  CityId?: Maybe<Scalars["Float"]>;
  Code?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_Division" */
export type Rtcs_Db_Ph_Kcca_Division_Variance_Order_By = {
  CityId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_ImgsData" */
export type Rtcs_Db_Ph_Kcca_ImgsData = {
  __typename?: "rtcs_db_PH_kcca_ImgsData";
  CamvId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FolderName?: Maybe<Scalars["String"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_ImgsData" */
export type Rtcs_Db_Ph_Kcca_ImgsData_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_ImgsData_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_ImgsData>;
};

/** aggregate fields of "rtcs_db.PH_kcca_ImgsData" */
export type Rtcs_Db_Ph_Kcca_ImgsData_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_ImgsData_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_ImgsData" */
export type Rtcs_Db_Ph_Kcca_ImgsData_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_ImgsData_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_ImgsData" */
export type Rtcs_Db_Ph_Kcca_ImgsData_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_ImgsData" */
export type Rtcs_Db_Ph_Kcca_ImgsData_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_ImgsData_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_ImgsData_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_ImgsData_avg_fields";
  CamvId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_ImgsData" */
export type Rtcs_Db_Ph_Kcca_ImgsData_Avg_Order_By = {
  CamvId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_ImgsData". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_ImgsData_Bool_Exp = {
  CamvId?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FolderName?: Maybe<String_Comparison_Exp>;
  ImgPath?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  PropertyNo?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_ImgsData" */
export type Rtcs_Db_Ph_Kcca_ImgsData_Inc_Input = {
  CamvId?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_ImgsData" */
export type Rtcs_Db_Ph_Kcca_ImgsData_Insert_Input = {
  CamvId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FolderName?: Maybe<Scalars["String"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_ImgsData_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_ImgsData_max_fields";
  CamvId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FolderName?: Maybe<Scalars["String"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_ImgsData" */
export type Rtcs_Db_Ph_Kcca_ImgsData_Max_Order_By = {
  CamvId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FolderName?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_ImgsData_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_ImgsData_min_fields";
  CamvId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FolderName?: Maybe<Scalars["String"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_ImgsData" */
export type Rtcs_Db_Ph_Kcca_ImgsData_Min_Order_By = {
  CamvId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FolderName?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_ImgsData" */
export type Rtcs_Db_Ph_Kcca_ImgsData_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_ImgsData_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_ImgsData>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_ImgsData" */
export type Rtcs_Db_Ph_Kcca_ImgsData_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_ImgsData_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_ImgsData" */
export type Rtcs_Db_Ph_Kcca_ImgsData_Order_By = {
  CamvId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FolderName?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_ImgsData" */
export enum Rtcs_Db_Ph_Kcca_ImgsData_Select_Column {
  /** column name */
  CamvId = "CamvId",
  /** column name */
  File = "File",
  /** column name */
  FolderName = "FolderName",
  /** column name */
  ImgPath = "ImgPath",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  PropertyNo = "PropertyNo",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_ImgsData" */
export type Rtcs_Db_Ph_Kcca_ImgsData_Set_Input = {
  CamvId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FolderName?: Maybe<Scalars["String"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_ImgsData_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_ImgsData_stddev_fields";
  CamvId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_ImgsData" */
export type Rtcs_Db_Ph_Kcca_ImgsData_Stddev_Order_By = {
  CamvId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_ImgsData_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_ImgsData_stddev_pop_fields";
  CamvId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_ImgsData" */
export type Rtcs_Db_Ph_Kcca_ImgsData_Stddev_Pop_Order_By = {
  CamvId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_ImgsData_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_ImgsData_stddev_samp_fields";
  CamvId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_ImgsData" */
export type Rtcs_Db_Ph_Kcca_ImgsData_Stddev_Samp_Order_By = {
  CamvId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_ImgsData_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_ImgsData_sum_fields";
  CamvId?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_ImgsData" */
export type Rtcs_Db_Ph_Kcca_ImgsData_Sum_Order_By = {
  CamvId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_ImgsData_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_ImgsData_var_pop_fields";
  CamvId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_ImgsData" */
export type Rtcs_Db_Ph_Kcca_ImgsData_Var_Pop_Order_By = {
  CamvId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_ImgsData_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_ImgsData_var_samp_fields";
  CamvId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_ImgsData" */
export type Rtcs_Db_Ph_Kcca_ImgsData_Var_Samp_Order_By = {
  CamvId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_ImgsData_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_ImgsData_variance_fields";
  CamvId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_ImgsData" */
export type Rtcs_Db_Ph_Kcca_ImgsData_Variance_Order_By = {
  CamvId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_Parish" */
export type Rtcs_Db_Ph_Kcca_Parish = {
  __typename?: "rtcs_db_PH_kcca_Parish";
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_Parish" */
export type Rtcs_Db_Ph_Kcca_Parish_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_Parish_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_Parish>;
};

/** aggregate fields of "rtcs_db.PH_kcca_Parish" */
export type Rtcs_Db_Ph_Kcca_Parish_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_Parish_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_Parish" */
export type Rtcs_Db_Ph_Kcca_Parish_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_Parish_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_Parish" */
export type Rtcs_Db_Ph_Kcca_Parish_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_Parish" */
export type Rtcs_Db_Ph_Kcca_Parish_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_Parish_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_Parish_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_Parish_avg_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_Parish" */
export type Rtcs_Db_Ph_Kcca_Parish_Avg_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_Parish". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_Parish_Bool_Exp = {
  CountryId?: Maybe<Float8_Comparison_Exp>;
  CountyId?: Maybe<Bigint_Comparison_Exp>;
  CreatedBy?: Maybe<Float8_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamp_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  DistrictId?: Maybe<Bigint_Comparison_Exp>;
  DivisionId?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamp_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  SubCountyId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_Parish_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_Parish_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_Parish" */
export type Rtcs_Db_Ph_Kcca_Parish_Inc_Input = {
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_Parish" */
export type Rtcs_Db_Ph_Kcca_Parish_Insert_Input = {
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_Parish_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_Parish_max_fields";
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_Parish" */
export type Rtcs_Db_Ph_Kcca_Parish_Max_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_Parish_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_Parish_min_fields";
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_Parish" */
export type Rtcs_Db_Ph_Kcca_Parish_Min_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_Parish" */
export type Rtcs_Db_Ph_Kcca_Parish_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_Parish_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_Parish>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_Parish" */
export type Rtcs_Db_Ph_Kcca_Parish_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_Parish_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_Parish" */
export type Rtcs_Db_Ph_Kcca_Parish_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_Parish" */
export enum Rtcs_Db_Ph_Kcca_Parish_Select_Column {
  /** column name */
  CountryId = "CountryId",
  /** column name */
  CountyId = "CountyId",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  Description = "Description",
  /** column name */
  DistrictId = "DistrictId",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  SubCountyId = "SubCountyId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_Parish" */
export type Rtcs_Db_Ph_Kcca_Parish_Set_Input = {
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_Parish_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_Parish_stddev_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_Parish" */
export type Rtcs_Db_Ph_Kcca_Parish_Stddev_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_Parish_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_Parish_stddev_pop_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_Parish" */
export type Rtcs_Db_Ph_Kcca_Parish_Stddev_Pop_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_Parish_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_Parish_stddev_samp_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_Parish" */
export type Rtcs_Db_Ph_Kcca_Parish_Stddev_Samp_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_Parish_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_Parish_sum_fields";
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_Parish" */
export type Rtcs_Db_Ph_Kcca_Parish_Sum_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_Parish_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_Parish_var_pop_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_Parish" */
export type Rtcs_Db_Ph_Kcca_Parish_Var_Pop_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_Parish_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_Parish_var_samp_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_Parish" */
export type Rtcs_Db_Ph_Kcca_Parish_Var_Samp_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_Parish_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_Parish_variance_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_Parish" */
export type Rtcs_Db_Ph_Kcca_Parish_Variance_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_PaymentDetails" */
export type Rtcs_Db_Ph_Kcca_PaymentDetails = {
  __typename?: "rtcs_db_PH_kcca_PaymentDetails";
  Amount?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  PaymentId?: Maybe<Scalars["String"]>;
  Reason?: Maybe<Scalars["String"]>;
  ReconciliationStatus?: Maybe<Scalars["String"]>;
  ReferenceId?: Maybe<Scalars["bigint"]>;
  ReferenceName?: Maybe<Scalars["String"]>;
  RevenueModelId?: Maybe<Scalars["float8"]>;
  RevenueTypeId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_PaymentDetails" */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_PaymentDetails_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_PaymentDetails>;
};

/** aggregate fields of "rtcs_db.PH_kcca_PaymentDetails" */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_PaymentDetails_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_PaymentDetails" */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_PaymentDetails_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_PaymentDetails" */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_PaymentDetails" */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_PaymentDetails_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_PaymentDetails_avg_fields";
  Amount?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ReferenceId?: Maybe<Scalars["Float"]>;
  RevenueModelId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_PaymentDetails" */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Avg_Order_By = {
  Amount?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ReferenceId?: Maybe<Order_By>;
  RevenueModelId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_PaymentDetails". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Bool_Exp = {
  Amount?: Maybe<Bigint_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamptz_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  DivisionId?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FinancialYear?: Maybe<Bigint_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  ModifiedBy?: Maybe<Float8_Comparison_Exp>;
  ModifiedDate?: Maybe<String_Comparison_Exp>;
  PaymentId?: Maybe<String_Comparison_Exp>;
  Reason?: Maybe<String_Comparison_Exp>;
  ReconciliationStatus?: Maybe<String_Comparison_Exp>;
  ReferenceId?: Maybe<Bigint_Comparison_Exp>;
  ReferenceName?: Maybe<String_Comparison_Exp>;
  RevenueModelId?: Maybe<Float8_Comparison_Exp>;
  RevenueTypeId?: Maybe<Float8_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_PaymentDetails" */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Inc_Input = {
  Amount?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ReferenceId?: Maybe<Scalars["bigint"]>;
  RevenueModelId?: Maybe<Scalars["float8"]>;
  RevenueTypeId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_PaymentDetails" */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Insert_Input = {
  Amount?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  PaymentId?: Maybe<Scalars["String"]>;
  Reason?: Maybe<Scalars["String"]>;
  ReconciliationStatus?: Maybe<Scalars["String"]>;
  ReferenceId?: Maybe<Scalars["bigint"]>;
  ReferenceName?: Maybe<Scalars["String"]>;
  RevenueModelId?: Maybe<Scalars["float8"]>;
  RevenueTypeId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_PaymentDetails_max_fields";
  Amount?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  PaymentId?: Maybe<Scalars["String"]>;
  Reason?: Maybe<Scalars["String"]>;
  ReconciliationStatus?: Maybe<Scalars["String"]>;
  ReferenceId?: Maybe<Scalars["bigint"]>;
  ReferenceName?: Maybe<Scalars["String"]>;
  RevenueModelId?: Maybe<Scalars["float8"]>;
  RevenueTypeId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_PaymentDetails" */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Max_Order_By = {
  Amount?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  PaymentId?: Maybe<Order_By>;
  Reason?: Maybe<Order_By>;
  ReconciliationStatus?: Maybe<Order_By>;
  ReferenceId?: Maybe<Order_By>;
  ReferenceName?: Maybe<Order_By>;
  RevenueModelId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_PaymentDetails_min_fields";
  Amount?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  PaymentId?: Maybe<Scalars["String"]>;
  Reason?: Maybe<Scalars["String"]>;
  ReconciliationStatus?: Maybe<Scalars["String"]>;
  ReferenceId?: Maybe<Scalars["bigint"]>;
  ReferenceName?: Maybe<Scalars["String"]>;
  RevenueModelId?: Maybe<Scalars["float8"]>;
  RevenueTypeId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_PaymentDetails" */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Min_Order_By = {
  Amount?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  PaymentId?: Maybe<Order_By>;
  Reason?: Maybe<Order_By>;
  ReconciliationStatus?: Maybe<Order_By>;
  ReferenceId?: Maybe<Order_By>;
  ReferenceName?: Maybe<Order_By>;
  RevenueModelId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_PaymentDetails" */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_PaymentDetails_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_PaymentDetails>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_PaymentDetails" */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_PaymentDetails_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_PaymentDetails" */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Order_By = {
  Amount?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  PaymentId?: Maybe<Order_By>;
  Reason?: Maybe<Order_By>;
  ReconciliationStatus?: Maybe<Order_By>;
  ReferenceId?: Maybe<Order_By>;
  ReferenceName?: Maybe<Order_By>;
  RevenueModelId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_PaymentDetails" */
export enum Rtcs_Db_Ph_Kcca_PaymentDetails_Select_Column {
  /** column name */
  Amount = "Amount",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  File = "File",
  /** column name */
  FinancialYear = "FinancialYear",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  PaymentId = "PaymentId",
  /** column name */
  Reason = "Reason",
  /** column name */
  ReconciliationStatus = "ReconciliationStatus",
  /** column name */
  ReferenceId = "ReferenceId",
  /** column name */
  ReferenceName = "ReferenceName",
  /** column name */
  RevenueModelId = "RevenueModelId",
  /** column name */
  RevenueTypeId = "RevenueTypeId",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_PaymentDetails" */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Set_Input = {
  Amount?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  PaymentId?: Maybe<Scalars["String"]>;
  Reason?: Maybe<Scalars["String"]>;
  ReconciliationStatus?: Maybe<Scalars["String"]>;
  ReferenceId?: Maybe<Scalars["bigint"]>;
  ReferenceName?: Maybe<Scalars["String"]>;
  RevenueModelId?: Maybe<Scalars["float8"]>;
  RevenueTypeId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_PaymentDetails_stddev_fields";
  Amount?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ReferenceId?: Maybe<Scalars["Float"]>;
  RevenueModelId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_PaymentDetails" */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Stddev_Order_By = {
  Amount?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ReferenceId?: Maybe<Order_By>;
  RevenueModelId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_PaymentDetails_stddev_pop_fields";
  Amount?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ReferenceId?: Maybe<Scalars["Float"]>;
  RevenueModelId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_PaymentDetails" */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Stddev_Pop_Order_By = {
  Amount?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ReferenceId?: Maybe<Order_By>;
  RevenueModelId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_PaymentDetails_stddev_samp_fields";
  Amount?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ReferenceId?: Maybe<Scalars["Float"]>;
  RevenueModelId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_PaymentDetails" */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Stddev_Samp_Order_By = {
  Amount?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ReferenceId?: Maybe<Order_By>;
  RevenueModelId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_PaymentDetails_sum_fields";
  Amount?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ReferenceId?: Maybe<Scalars["bigint"]>;
  RevenueModelId?: Maybe<Scalars["float8"]>;
  RevenueTypeId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_PaymentDetails" */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Sum_Order_By = {
  Amount?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ReferenceId?: Maybe<Order_By>;
  RevenueModelId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_PaymentDetails_var_pop_fields";
  Amount?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ReferenceId?: Maybe<Scalars["Float"]>;
  RevenueModelId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_PaymentDetails" */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Var_Pop_Order_By = {
  Amount?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ReferenceId?: Maybe<Order_By>;
  RevenueModelId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_PaymentDetails_var_samp_fields";
  Amount?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ReferenceId?: Maybe<Scalars["Float"]>;
  RevenueModelId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_PaymentDetails" */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Var_Samp_Order_By = {
  Amount?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ReferenceId?: Maybe<Order_By>;
  RevenueModelId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_PaymentDetails_variance_fields";
  Amount?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ReferenceId?: Maybe<Scalars["Float"]>;
  RevenueModelId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_PaymentDetails" */
export type Rtcs_Db_Ph_Kcca_PaymentDetails_Variance_Order_By = {
  Amount?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ReferenceId?: Maybe<Order_By>;
  RevenueModelId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_Payments" */
export type Rtcs_Db_Ph_Kcca_Payments = {
  __typename?: "rtcs_db_PH_kcca_Payments";
  Amount?: Maybe<Scalars["bigint"]>;
  ApiId?: Maybe<Scalars["float8"]>;
  BankBranchCode?: Maybe<Scalars["float8"]>;
  CheckNumber?: Maybe<Scalars["float8"]>;
  CollectionAgentId?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerBankAccountName?: Maybe<Scalars["String"]>;
  CustomerBankAccountNumber?: Maybe<Scalars["String"]>;
  CustomerBankName?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DatePaid?: Maybe<Scalars["timestamptz"]>;
  ExpiryDate?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  ObjectionBy?: Maybe<Scalars["float8"]>;
  ObjectionReason?: Maybe<Scalars["String"]>;
  ObjectionStatus?: Maybe<Scalars["float8"]>;
  PaymentModeId?: Maybe<Scalars["bigint"]>;
  PaymentTypeId?: Maybe<Scalars["bigint"]>;
  Prn?: Maybe<Scalars["String"]>;
  ReconciliationStatus?: Maybe<Scalars["String"]>;
  RegistrationStatus?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  TransactionId?: Maybe<Scalars["String"]>;
  TransactionStatus?: Maybe<Scalars["String"]>;
  UraResponseId?: Maybe<Scalars["float8"]>;
  ValueDate?: Maybe<Scalars["timestamptz"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_Payments" */
export type Rtcs_Db_Ph_Kcca_Payments_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_Payments_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_Payments>;
};

/** aggregate fields of "rtcs_db.PH_kcca_Payments" */
export type Rtcs_Db_Ph_Kcca_Payments_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_Payments_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_Payments" */
export type Rtcs_Db_Ph_Kcca_Payments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_Payments_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_Payments" */
export type Rtcs_Db_Ph_Kcca_Payments_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_Payments" */
export type Rtcs_Db_Ph_Kcca_Payments_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_Payments_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_Payments_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_Payments_avg_fields";
  Amount?: Maybe<Scalars["Float"]>;
  ApiId?: Maybe<Scalars["Float"]>;
  BankBranchCode?: Maybe<Scalars["Float"]>;
  CheckNumber?: Maybe<Scalars["Float"]>;
  CollectionAgentId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ObjectionBy?: Maybe<Scalars["Float"]>;
  ObjectionStatus?: Maybe<Scalars["Float"]>;
  PaymentModeId?: Maybe<Scalars["Float"]>;
  PaymentTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  UraResponseId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_Payments" */
export type Rtcs_Db_Ph_Kcca_Payments_Avg_Order_By = {
  Amount?: Maybe<Order_By>;
  ApiId?: Maybe<Order_By>;
  BankBranchCode?: Maybe<Order_By>;
  CheckNumber?: Maybe<Order_By>;
  CollectionAgentId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ObjectionBy?: Maybe<Order_By>;
  ObjectionStatus?: Maybe<Order_By>;
  PaymentModeId?: Maybe<Order_By>;
  PaymentTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UraResponseId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_Payments". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_Payments_Bool_Exp = {
  Amount?: Maybe<Bigint_Comparison_Exp>;
  ApiId?: Maybe<Float8_Comparison_Exp>;
  BankBranchCode?: Maybe<Float8_Comparison_Exp>;
  CheckNumber?: Maybe<Float8_Comparison_Exp>;
  CollectionAgentId?: Maybe<Bigint_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamptz_Comparison_Exp>;
  CustomerBankAccountName?: Maybe<String_Comparison_Exp>;
  CustomerBankAccountNumber?: Maybe<String_Comparison_Exp>;
  CustomerBankName?: Maybe<String_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  DatePaid?: Maybe<Timestamptz_Comparison_Exp>;
  ExpiryDate?: Maybe<Timestamptz_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FinancialYear?: Maybe<Bigint_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  ModifiedDate?: Maybe<Timestamptz_Comparison_Exp>;
  ObjectionBy?: Maybe<Float8_Comparison_Exp>;
  ObjectionReason?: Maybe<String_Comparison_Exp>;
  ObjectionStatus?: Maybe<Float8_Comparison_Exp>;
  PaymentModeId?: Maybe<Bigint_Comparison_Exp>;
  PaymentTypeId?: Maybe<Bigint_Comparison_Exp>;
  Prn?: Maybe<String_Comparison_Exp>;
  ReconciliationStatus?: Maybe<String_Comparison_Exp>;
  RegistrationStatus?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  TransactionId?: Maybe<String_Comparison_Exp>;
  TransactionStatus?: Maybe<String_Comparison_Exp>;
  UraResponseId?: Maybe<Float8_Comparison_Exp>;
  ValueDate?: Maybe<Timestamptz_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_Payments_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_Payments_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_Payments" */
export type Rtcs_Db_Ph_Kcca_Payments_Inc_Input = {
  Amount?: Maybe<Scalars["bigint"]>;
  ApiId?: Maybe<Scalars["float8"]>;
  BankBranchCode?: Maybe<Scalars["float8"]>;
  CheckNumber?: Maybe<Scalars["float8"]>;
  CollectionAgentId?: Maybe<Scalars["bigint"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ObjectionBy?: Maybe<Scalars["float8"]>;
  ObjectionStatus?: Maybe<Scalars["float8"]>;
  PaymentModeId?: Maybe<Scalars["bigint"]>;
  PaymentTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  UraResponseId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_Payments" */
export type Rtcs_Db_Ph_Kcca_Payments_Insert_Input = {
  Amount?: Maybe<Scalars["bigint"]>;
  ApiId?: Maybe<Scalars["float8"]>;
  BankBranchCode?: Maybe<Scalars["float8"]>;
  CheckNumber?: Maybe<Scalars["float8"]>;
  CollectionAgentId?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerBankAccountName?: Maybe<Scalars["String"]>;
  CustomerBankAccountNumber?: Maybe<Scalars["String"]>;
  CustomerBankName?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DatePaid?: Maybe<Scalars["timestamptz"]>;
  ExpiryDate?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  ObjectionBy?: Maybe<Scalars["float8"]>;
  ObjectionReason?: Maybe<Scalars["String"]>;
  ObjectionStatus?: Maybe<Scalars["float8"]>;
  PaymentModeId?: Maybe<Scalars["bigint"]>;
  PaymentTypeId?: Maybe<Scalars["bigint"]>;
  Prn?: Maybe<Scalars["String"]>;
  ReconciliationStatus?: Maybe<Scalars["String"]>;
  RegistrationStatus?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  TransactionId?: Maybe<Scalars["String"]>;
  TransactionStatus?: Maybe<Scalars["String"]>;
  UraResponseId?: Maybe<Scalars["float8"]>;
  ValueDate?: Maybe<Scalars["timestamptz"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_Payments_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_Payments_max_fields";
  Amount?: Maybe<Scalars["bigint"]>;
  ApiId?: Maybe<Scalars["float8"]>;
  BankBranchCode?: Maybe<Scalars["float8"]>;
  CheckNumber?: Maybe<Scalars["float8"]>;
  CollectionAgentId?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerBankAccountName?: Maybe<Scalars["String"]>;
  CustomerBankAccountNumber?: Maybe<Scalars["String"]>;
  CustomerBankName?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DatePaid?: Maybe<Scalars["timestamptz"]>;
  ExpiryDate?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  ObjectionBy?: Maybe<Scalars["float8"]>;
  ObjectionReason?: Maybe<Scalars["String"]>;
  ObjectionStatus?: Maybe<Scalars["float8"]>;
  PaymentModeId?: Maybe<Scalars["bigint"]>;
  PaymentTypeId?: Maybe<Scalars["bigint"]>;
  Prn?: Maybe<Scalars["String"]>;
  ReconciliationStatus?: Maybe<Scalars["String"]>;
  RegistrationStatus?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  TransactionId?: Maybe<Scalars["String"]>;
  TransactionStatus?: Maybe<Scalars["String"]>;
  UraResponseId?: Maybe<Scalars["float8"]>;
  ValueDate?: Maybe<Scalars["timestamptz"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_Payments" */
export type Rtcs_Db_Ph_Kcca_Payments_Max_Order_By = {
  Amount?: Maybe<Order_By>;
  ApiId?: Maybe<Order_By>;
  BankBranchCode?: Maybe<Order_By>;
  CheckNumber?: Maybe<Order_By>;
  CollectionAgentId?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerBankAccountName?: Maybe<Order_By>;
  CustomerBankAccountNumber?: Maybe<Order_By>;
  CustomerBankName?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DatePaid?: Maybe<Order_By>;
  ExpiryDate?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  ObjectionBy?: Maybe<Order_By>;
  ObjectionReason?: Maybe<Order_By>;
  ObjectionStatus?: Maybe<Order_By>;
  PaymentModeId?: Maybe<Order_By>;
  PaymentTypeId?: Maybe<Order_By>;
  Prn?: Maybe<Order_By>;
  ReconciliationStatus?: Maybe<Order_By>;
  RegistrationStatus?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TransactionId?: Maybe<Order_By>;
  TransactionStatus?: Maybe<Order_By>;
  UraResponseId?: Maybe<Order_By>;
  ValueDate?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_Payments_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_Payments_min_fields";
  Amount?: Maybe<Scalars["bigint"]>;
  ApiId?: Maybe<Scalars["float8"]>;
  BankBranchCode?: Maybe<Scalars["float8"]>;
  CheckNumber?: Maybe<Scalars["float8"]>;
  CollectionAgentId?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerBankAccountName?: Maybe<Scalars["String"]>;
  CustomerBankAccountNumber?: Maybe<Scalars["String"]>;
  CustomerBankName?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DatePaid?: Maybe<Scalars["timestamptz"]>;
  ExpiryDate?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  ObjectionBy?: Maybe<Scalars["float8"]>;
  ObjectionReason?: Maybe<Scalars["String"]>;
  ObjectionStatus?: Maybe<Scalars["float8"]>;
  PaymentModeId?: Maybe<Scalars["bigint"]>;
  PaymentTypeId?: Maybe<Scalars["bigint"]>;
  Prn?: Maybe<Scalars["String"]>;
  ReconciliationStatus?: Maybe<Scalars["String"]>;
  RegistrationStatus?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  TransactionId?: Maybe<Scalars["String"]>;
  TransactionStatus?: Maybe<Scalars["String"]>;
  UraResponseId?: Maybe<Scalars["float8"]>;
  ValueDate?: Maybe<Scalars["timestamptz"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_Payments" */
export type Rtcs_Db_Ph_Kcca_Payments_Min_Order_By = {
  Amount?: Maybe<Order_By>;
  ApiId?: Maybe<Order_By>;
  BankBranchCode?: Maybe<Order_By>;
  CheckNumber?: Maybe<Order_By>;
  CollectionAgentId?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerBankAccountName?: Maybe<Order_By>;
  CustomerBankAccountNumber?: Maybe<Order_By>;
  CustomerBankName?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DatePaid?: Maybe<Order_By>;
  ExpiryDate?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  ObjectionBy?: Maybe<Order_By>;
  ObjectionReason?: Maybe<Order_By>;
  ObjectionStatus?: Maybe<Order_By>;
  PaymentModeId?: Maybe<Order_By>;
  PaymentTypeId?: Maybe<Order_By>;
  Prn?: Maybe<Order_By>;
  ReconciliationStatus?: Maybe<Order_By>;
  RegistrationStatus?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TransactionId?: Maybe<Order_By>;
  TransactionStatus?: Maybe<Order_By>;
  UraResponseId?: Maybe<Order_By>;
  ValueDate?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_Payments" */
export type Rtcs_Db_Ph_Kcca_Payments_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_Payments_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_Payments>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_Payments" */
export type Rtcs_Db_Ph_Kcca_Payments_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_Payments_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_Payments" */
export type Rtcs_Db_Ph_Kcca_Payments_Order_By = {
  Amount?: Maybe<Order_By>;
  ApiId?: Maybe<Order_By>;
  BankBranchCode?: Maybe<Order_By>;
  CheckNumber?: Maybe<Order_By>;
  CollectionAgentId?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerBankAccountName?: Maybe<Order_By>;
  CustomerBankAccountNumber?: Maybe<Order_By>;
  CustomerBankName?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DatePaid?: Maybe<Order_By>;
  ExpiryDate?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  ObjectionBy?: Maybe<Order_By>;
  ObjectionReason?: Maybe<Order_By>;
  ObjectionStatus?: Maybe<Order_By>;
  PaymentModeId?: Maybe<Order_By>;
  PaymentTypeId?: Maybe<Order_By>;
  Prn?: Maybe<Order_By>;
  ReconciliationStatus?: Maybe<Order_By>;
  RegistrationStatus?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TransactionId?: Maybe<Order_By>;
  TransactionStatus?: Maybe<Order_By>;
  UraResponseId?: Maybe<Order_By>;
  ValueDate?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_Payments" */
export enum Rtcs_Db_Ph_Kcca_Payments_Select_Column {
  /** column name */
  Amount = "Amount",
  /** column name */
  ApiId = "ApiId",
  /** column name */
  BankBranchCode = "BankBranchCode",
  /** column name */
  CheckNumber = "CheckNumber",
  /** column name */
  CollectionAgentId = "CollectionAgentId",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerBankAccountName = "CustomerBankAccountName",
  /** column name */
  CustomerBankAccountNumber = "CustomerBankAccountNumber",
  /** column name */
  CustomerBankName = "CustomerBankName",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DatePaid = "DatePaid",
  /** column name */
  ExpiryDate = "ExpiryDate",
  /** column name */
  File = "File",
  /** column name */
  FinancialYear = "FinancialYear",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  ObjectionBy = "ObjectionBy",
  /** column name */
  ObjectionReason = "ObjectionReason",
  /** column name */
  ObjectionStatus = "ObjectionStatus",
  /** column name */
  PaymentModeId = "PaymentModeId",
  /** column name */
  PaymentTypeId = "PaymentTypeId",
  /** column name */
  Prn = "Prn",
  /** column name */
  ReconciliationStatus = "ReconciliationStatus",
  /** column name */
  RegistrationStatus = "RegistrationStatus",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  TransactionId = "TransactionId",
  /** column name */
  TransactionStatus = "TransactionStatus",
  /** column name */
  UraResponseId = "UraResponseId",
  /** column name */
  ValueDate = "ValueDate",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_Payments" */
export type Rtcs_Db_Ph_Kcca_Payments_Set_Input = {
  Amount?: Maybe<Scalars["bigint"]>;
  ApiId?: Maybe<Scalars["float8"]>;
  BankBranchCode?: Maybe<Scalars["float8"]>;
  CheckNumber?: Maybe<Scalars["float8"]>;
  CollectionAgentId?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerBankAccountName?: Maybe<Scalars["String"]>;
  CustomerBankAccountNumber?: Maybe<Scalars["String"]>;
  CustomerBankName?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DatePaid?: Maybe<Scalars["timestamptz"]>;
  ExpiryDate?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  ObjectionBy?: Maybe<Scalars["float8"]>;
  ObjectionReason?: Maybe<Scalars["String"]>;
  ObjectionStatus?: Maybe<Scalars["float8"]>;
  PaymentModeId?: Maybe<Scalars["bigint"]>;
  PaymentTypeId?: Maybe<Scalars["bigint"]>;
  Prn?: Maybe<Scalars["String"]>;
  ReconciliationStatus?: Maybe<Scalars["String"]>;
  RegistrationStatus?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  TransactionId?: Maybe<Scalars["String"]>;
  TransactionStatus?: Maybe<Scalars["String"]>;
  UraResponseId?: Maybe<Scalars["float8"]>;
  ValueDate?: Maybe<Scalars["timestamptz"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_Payments_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_Payments_stddev_fields";
  Amount?: Maybe<Scalars["Float"]>;
  ApiId?: Maybe<Scalars["Float"]>;
  BankBranchCode?: Maybe<Scalars["Float"]>;
  CheckNumber?: Maybe<Scalars["Float"]>;
  CollectionAgentId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ObjectionBy?: Maybe<Scalars["Float"]>;
  ObjectionStatus?: Maybe<Scalars["Float"]>;
  PaymentModeId?: Maybe<Scalars["Float"]>;
  PaymentTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  UraResponseId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_Payments" */
export type Rtcs_Db_Ph_Kcca_Payments_Stddev_Order_By = {
  Amount?: Maybe<Order_By>;
  ApiId?: Maybe<Order_By>;
  BankBranchCode?: Maybe<Order_By>;
  CheckNumber?: Maybe<Order_By>;
  CollectionAgentId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ObjectionBy?: Maybe<Order_By>;
  ObjectionStatus?: Maybe<Order_By>;
  PaymentModeId?: Maybe<Order_By>;
  PaymentTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UraResponseId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_Payments_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_Payments_stddev_pop_fields";
  Amount?: Maybe<Scalars["Float"]>;
  ApiId?: Maybe<Scalars["Float"]>;
  BankBranchCode?: Maybe<Scalars["Float"]>;
  CheckNumber?: Maybe<Scalars["Float"]>;
  CollectionAgentId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ObjectionBy?: Maybe<Scalars["Float"]>;
  ObjectionStatus?: Maybe<Scalars["Float"]>;
  PaymentModeId?: Maybe<Scalars["Float"]>;
  PaymentTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  UraResponseId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_Payments" */
export type Rtcs_Db_Ph_Kcca_Payments_Stddev_Pop_Order_By = {
  Amount?: Maybe<Order_By>;
  ApiId?: Maybe<Order_By>;
  BankBranchCode?: Maybe<Order_By>;
  CheckNumber?: Maybe<Order_By>;
  CollectionAgentId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ObjectionBy?: Maybe<Order_By>;
  ObjectionStatus?: Maybe<Order_By>;
  PaymentModeId?: Maybe<Order_By>;
  PaymentTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UraResponseId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_Payments_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_Payments_stddev_samp_fields";
  Amount?: Maybe<Scalars["Float"]>;
  ApiId?: Maybe<Scalars["Float"]>;
  BankBranchCode?: Maybe<Scalars["Float"]>;
  CheckNumber?: Maybe<Scalars["Float"]>;
  CollectionAgentId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ObjectionBy?: Maybe<Scalars["Float"]>;
  ObjectionStatus?: Maybe<Scalars["Float"]>;
  PaymentModeId?: Maybe<Scalars["Float"]>;
  PaymentTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  UraResponseId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_Payments" */
export type Rtcs_Db_Ph_Kcca_Payments_Stddev_Samp_Order_By = {
  Amount?: Maybe<Order_By>;
  ApiId?: Maybe<Order_By>;
  BankBranchCode?: Maybe<Order_By>;
  CheckNumber?: Maybe<Order_By>;
  CollectionAgentId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ObjectionBy?: Maybe<Order_By>;
  ObjectionStatus?: Maybe<Order_By>;
  PaymentModeId?: Maybe<Order_By>;
  PaymentTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UraResponseId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_Payments_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_Payments_sum_fields";
  Amount?: Maybe<Scalars["bigint"]>;
  ApiId?: Maybe<Scalars["float8"]>;
  BankBranchCode?: Maybe<Scalars["float8"]>;
  CheckNumber?: Maybe<Scalars["float8"]>;
  CollectionAgentId?: Maybe<Scalars["bigint"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ObjectionBy?: Maybe<Scalars["float8"]>;
  ObjectionStatus?: Maybe<Scalars["float8"]>;
  PaymentModeId?: Maybe<Scalars["bigint"]>;
  PaymentTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  UraResponseId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_Payments" */
export type Rtcs_Db_Ph_Kcca_Payments_Sum_Order_By = {
  Amount?: Maybe<Order_By>;
  ApiId?: Maybe<Order_By>;
  BankBranchCode?: Maybe<Order_By>;
  CheckNumber?: Maybe<Order_By>;
  CollectionAgentId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ObjectionBy?: Maybe<Order_By>;
  ObjectionStatus?: Maybe<Order_By>;
  PaymentModeId?: Maybe<Order_By>;
  PaymentTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UraResponseId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_Payments_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_Payments_var_pop_fields";
  Amount?: Maybe<Scalars["Float"]>;
  ApiId?: Maybe<Scalars["Float"]>;
  BankBranchCode?: Maybe<Scalars["Float"]>;
  CheckNumber?: Maybe<Scalars["Float"]>;
  CollectionAgentId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ObjectionBy?: Maybe<Scalars["Float"]>;
  ObjectionStatus?: Maybe<Scalars["Float"]>;
  PaymentModeId?: Maybe<Scalars["Float"]>;
  PaymentTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  UraResponseId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_Payments" */
export type Rtcs_Db_Ph_Kcca_Payments_Var_Pop_Order_By = {
  Amount?: Maybe<Order_By>;
  ApiId?: Maybe<Order_By>;
  BankBranchCode?: Maybe<Order_By>;
  CheckNumber?: Maybe<Order_By>;
  CollectionAgentId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ObjectionBy?: Maybe<Order_By>;
  ObjectionStatus?: Maybe<Order_By>;
  PaymentModeId?: Maybe<Order_By>;
  PaymentTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UraResponseId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_Payments_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_Payments_var_samp_fields";
  Amount?: Maybe<Scalars["Float"]>;
  ApiId?: Maybe<Scalars["Float"]>;
  BankBranchCode?: Maybe<Scalars["Float"]>;
  CheckNumber?: Maybe<Scalars["Float"]>;
  CollectionAgentId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ObjectionBy?: Maybe<Scalars["Float"]>;
  ObjectionStatus?: Maybe<Scalars["Float"]>;
  PaymentModeId?: Maybe<Scalars["Float"]>;
  PaymentTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  UraResponseId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_Payments" */
export type Rtcs_Db_Ph_Kcca_Payments_Var_Samp_Order_By = {
  Amount?: Maybe<Order_By>;
  ApiId?: Maybe<Order_By>;
  BankBranchCode?: Maybe<Order_By>;
  CheckNumber?: Maybe<Order_By>;
  CollectionAgentId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ObjectionBy?: Maybe<Order_By>;
  ObjectionStatus?: Maybe<Order_By>;
  PaymentModeId?: Maybe<Order_By>;
  PaymentTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UraResponseId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_Payments_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_Payments_variance_fields";
  Amount?: Maybe<Scalars["Float"]>;
  ApiId?: Maybe<Scalars["Float"]>;
  BankBranchCode?: Maybe<Scalars["Float"]>;
  CheckNumber?: Maybe<Scalars["Float"]>;
  CollectionAgentId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ObjectionBy?: Maybe<Scalars["Float"]>;
  ObjectionStatus?: Maybe<Scalars["Float"]>;
  PaymentModeId?: Maybe<Scalars["Float"]>;
  PaymentTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  UraResponseId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_Payments" */
export type Rtcs_Db_Ph_Kcca_Payments_Variance_Order_By = {
  Amount?: Maybe<Order_By>;
  ApiId?: Maybe<Order_By>;
  BankBranchCode?: Maybe<Order_By>;
  CheckNumber?: Maybe<Order_By>;
  CollectionAgentId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ObjectionBy?: Maybe<Order_By>;
  ObjectionStatus?: Maybe<Order_By>;
  PaymentModeId?: Maybe<Order_By>;
  PaymentTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UraResponseId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_Property" */
export type Rtcs_Db_Ph_Kcca_Property = {
  __typename?: "rtcs_db_PH_kcca_Property";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.PH_kcca_PropertyFloors" */
export type Rtcs_Db_Ph_Kcca_PropertyFloors = {
  __typename?: "rtcs_db_PH_kcca_PropertyFloors";
  File?: Maybe<Scalars["String"]>;
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_PropertyFloors" */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_PropertyFloors_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_PropertyFloors>;
};

/** aggregate fields of "rtcs_db.PH_kcca_PropertyFloors" */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyFloors_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_PropertyFloors" */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyFloors_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_PropertyFloors" */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_PropertyFloors" */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_PropertyFloors_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyFloors_avg_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_PropertyFloors" */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Avg_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_PropertyFloors". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  GradeId?: Maybe<Bigint_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_PropertyFloors" */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Inc_Input = {
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_PropertyFloors" */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyFloors_max_fields";
  File?: Maybe<Scalars["String"]>;
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_PropertyFloors" */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Max_Order_By = {
  File?: Maybe<Order_By>;
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyFloors_min_fields";
  File?: Maybe<Scalars["String"]>;
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_PropertyFloors" */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Min_Order_By = {
  File?: Maybe<Order_By>;
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_PropertyFloors" */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_PropertyFloors_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_PropertyFloors>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_PropertyFloors" */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_PropertyFloors_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_PropertyFloors" */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Order_By = {
  File?: Maybe<Order_By>;
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_PropertyFloors" */
export enum Rtcs_Db_Ph_Kcca_PropertyFloors_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  GradeId = "GradeId",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_PropertyFloors" */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyFloors_stddev_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_PropertyFloors" */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Stddev_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyFloors_stddev_pop_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_PropertyFloors" */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Stddev_Pop_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyFloors_stddev_samp_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_PropertyFloors" */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Stddev_Samp_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyFloors_sum_fields";
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_PropertyFloors" */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Sum_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyFloors_var_pop_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_PropertyFloors" */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Var_Pop_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyFloors_var_samp_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_PropertyFloors" */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Var_Samp_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyFloors_variance_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_PropertyFloors" */
export type Rtcs_Db_Ph_Kcca_PropertyFloors_Variance_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms = {
  __typename?: "rtcs_db_PH_kcca_PropertyNumberBedrooms";
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_PropertyNumberBedrooms_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms>;
};

/** aggregate fields of "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyNumberBedrooms_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyNumberBedrooms_avg_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Avg_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_PropertyNumberBedrooms". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamp_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Inc_Input = {
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyNumberBedrooms_max_fields";
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Max_Order_By = {
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyNumberBedrooms_min_fields";
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Min_Order_By = {
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_PropertyNumberBedrooms_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Order_By = {
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
export enum Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyNumberBedrooms_stddev_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Stddev_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyNumberBedrooms_stddev_pop_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Stddev_Pop_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyNumberBedrooms_stddev_samp_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Stddev_Samp_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyNumberBedrooms_sum_fields";
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Sum_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyNumberBedrooms_var_pop_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Var_Pop_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyNumberBedrooms_var_samp_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Var_Samp_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyNumberBedrooms_variance_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Variance_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_PropertyRentedStatus" */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus = {
  __typename?: "rtcs_db_PH_kcca_PropertyRentedStatus";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["date"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_PropertyRentedStatus" */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_PropertyRentedStatus_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_PropertyRentedStatus>;
};

/** aggregate fields of "rtcs_db.PH_kcca_PropertyRentedStatus" */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyRentedStatus_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_PropertyRentedStatus" */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_PropertyRentedStatus" */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_PropertyRentedStatus" */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyRentedStatus_avg_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_PropertyRentedStatus" */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Avg_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_PropertyRentedStatus". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Bool_Exp = {
  CreatedBy?: Maybe<Bigint_Comparison_Exp>;
  CreatedDate?: Maybe<Date_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamp_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_PropertyRentedStatus" */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Inc_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_PropertyRentedStatus" */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Insert_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["date"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyRentedStatus_max_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["date"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_PropertyRentedStatus" */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Max_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyRentedStatus_min_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["date"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_PropertyRentedStatus" */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Min_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_PropertyRentedStatus" */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_PropertyRentedStatus_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_PropertyRentedStatus>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_PropertyRentedStatus" */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_PropertyRentedStatus" */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_PropertyRentedStatus" */
export enum Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Select_Column {
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_PropertyRentedStatus" */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Set_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["date"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyRentedStatus_stddev_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_PropertyRentedStatus" */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Stddev_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyRentedStatus_stddev_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_PropertyRentedStatus" */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Stddev_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyRentedStatus_stddev_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_PropertyRentedStatus" */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Stddev_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyRentedStatus_sum_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_PropertyRentedStatus" */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Sum_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyRentedStatus_var_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_PropertyRentedStatus" */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Var_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyRentedStatus_var_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_PropertyRentedStatus" */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Var_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyRentedStatus_variance_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_PropertyRentedStatus" */
export type Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Variance_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_PropertySecurity" */
export type Rtcs_Db_Ph_Kcca_PropertySecurity = {
  __typename?: "rtcs_db_PH_kcca_PropertySecurity";
  File?: Maybe<Scalars["String"]>;
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_PropertySecurity" */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_PropertySecurity_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_PropertySecurity>;
};

/** aggregate fields of "rtcs_db.PH_kcca_PropertySecurity" */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertySecurity_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_PropertySecurity" */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySecurity_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_PropertySecurity" */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_PropertySecurity" */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_PropertySecurity_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertySecurity_avg_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_PropertySecurity" */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Avg_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_PropertySecurity". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  GradeId?: Maybe<Bigint_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_PropertySecurity" */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Inc_Input = {
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_PropertySecurity" */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertySecurity_max_fields";
  File?: Maybe<Scalars["String"]>;
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_PropertySecurity" */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Max_Order_By = {
  File?: Maybe<Order_By>;
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertySecurity_min_fields";
  File?: Maybe<Scalars["String"]>;
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_PropertySecurity" */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Min_Order_By = {
  File?: Maybe<Order_By>;
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_PropertySecurity" */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_PropertySecurity_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_PropertySecurity>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_PropertySecurity" */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_PropertySecurity_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_PropertySecurity" */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Order_By = {
  File?: Maybe<Order_By>;
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_PropertySecurity" */
export enum Rtcs_Db_Ph_Kcca_PropertySecurity_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  GradeId = "GradeId",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_PropertySecurity" */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertySecurity_stddev_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_PropertySecurity" */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Stddev_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertySecurity_stddev_pop_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_PropertySecurity" */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Stddev_Pop_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertySecurity_stddev_samp_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_PropertySecurity" */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Stddev_Samp_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertySecurity_sum_fields";
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_PropertySecurity" */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Sum_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertySecurity_var_pop_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_PropertySecurity" */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Var_Pop_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertySecurity_var_samp_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_PropertySecurity" */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Var_Samp_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertySecurity_variance_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_PropertySecurity" */
export type Rtcs_Db_Ph_Kcca_PropertySecurity_Variance_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_PropertySubType" */
export type Rtcs_Db_Ph_Kcca_PropertySubType = {
  __typename?: "rtcs_db_PH_kcca_PropertySubType";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_PropertySubType" */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_PropertySubType_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_PropertySubType>;
};

/** aggregate fields of "rtcs_db.PH_kcca_PropertySubType" */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertySubType_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_PropertySubType" */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySubType_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_PropertySubType" */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_PropertySubType" */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_PropertySubType_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertySubType_avg_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_PropertySubType" */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Avg_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_PropertySubType". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Bool_Exp = {
  CreatedBy?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_PropertySubType" */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Inc_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_PropertySubType" */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Insert_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertySubType_max_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_PropertySubType" */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Max_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertySubType_min_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_PropertySubType" */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Min_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_PropertySubType" */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_PropertySubType_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_PropertySubType>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_PropertySubType" */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_PropertySubType_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_PropertySubType" */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_PropertySubType" */
export enum Rtcs_Db_Ph_Kcca_PropertySubType_Select_Column {
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_PropertySubType" */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Set_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertySubType_stddev_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_PropertySubType" */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Stddev_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertySubType_stddev_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_PropertySubType" */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Stddev_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertySubType_stddev_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_PropertySubType" */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Stddev_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertySubType_sum_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_PropertySubType" */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Sum_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertySubType_var_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_PropertySubType" */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Var_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertySubType_var_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_PropertySubType" */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Var_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertySubType_variance_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_PropertySubType" */
export type Rtcs_Db_Ph_Kcca_PropertySubType_Variance_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_PropertyType" */
export type Rtcs_Db_Ph_Kcca_PropertyType = {
  __typename?: "rtcs_db_PH_kcca_PropertyType";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  PaymentFrequencyId?: Maybe<Scalars["float8"]>;
  ReferenceName?: Maybe<Scalars["String"]>;
  RevenueTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  UraCode?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_PropertyType" */
export type Rtcs_Db_Ph_Kcca_PropertyType_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_PropertyType_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_PropertyType>;
};

/** aggregate fields of "rtcs_db.PH_kcca_PropertyType" */
export type Rtcs_Db_Ph_Kcca_PropertyType_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyType_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_PropertyType" */
export type Rtcs_Db_Ph_Kcca_PropertyType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyType_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_PropertyType" */
export type Rtcs_Db_Ph_Kcca_PropertyType_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_PropertyType" */
export type Rtcs_Db_Ph_Kcca_PropertyType_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_PropertyType_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_PropertyType_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyType_avg_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PaymentFrequencyId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_PropertyType" */
export type Rtcs_Db_Ph_Kcca_PropertyType_Avg_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PaymentFrequencyId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_PropertyType". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_PropertyType_Bool_Exp = {
  CreatedBy?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  PaymentFrequencyId?: Maybe<Float8_Comparison_Exp>;
  ReferenceName?: Maybe<String_Comparison_Exp>;
  RevenueTypeId?: Maybe<Bigint_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  UraCode?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_PropertyType" */
export type Rtcs_Db_Ph_Kcca_PropertyType_Inc_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PaymentFrequencyId?: Maybe<Scalars["float8"]>;
  RevenueTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_PropertyType" */
export type Rtcs_Db_Ph_Kcca_PropertyType_Insert_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  PaymentFrequencyId?: Maybe<Scalars["float8"]>;
  ReferenceName?: Maybe<Scalars["String"]>;
  RevenueTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  UraCode?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_PropertyType_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyType_max_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  PaymentFrequencyId?: Maybe<Scalars["float8"]>;
  ReferenceName?: Maybe<Scalars["String"]>;
  RevenueTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  UraCode?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_PropertyType" */
export type Rtcs_Db_Ph_Kcca_PropertyType_Max_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  PaymentFrequencyId?: Maybe<Order_By>;
  ReferenceName?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UraCode?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_PropertyType_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyType_min_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  PaymentFrequencyId?: Maybe<Scalars["float8"]>;
  ReferenceName?: Maybe<Scalars["String"]>;
  RevenueTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  UraCode?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_PropertyType" */
export type Rtcs_Db_Ph_Kcca_PropertyType_Min_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  PaymentFrequencyId?: Maybe<Order_By>;
  ReferenceName?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UraCode?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_PropertyType" */
export type Rtcs_Db_Ph_Kcca_PropertyType_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_PropertyType_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_PropertyType>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_PropertyType" */
export type Rtcs_Db_Ph_Kcca_PropertyType_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_PropertyType_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_PropertyType" */
export type Rtcs_Db_Ph_Kcca_PropertyType_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  PaymentFrequencyId?: Maybe<Order_By>;
  ReferenceName?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UraCode?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_PropertyType" */
export enum Rtcs_Db_Ph_Kcca_PropertyType_Select_Column {
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  PaymentFrequencyId = "PaymentFrequencyId",
  /** column name */
  ReferenceName = "ReferenceName",
  /** column name */
  RevenueTypeId = "RevenueTypeId",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  UraCode = "UraCode",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_PropertyType" */
export type Rtcs_Db_Ph_Kcca_PropertyType_Set_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  PaymentFrequencyId?: Maybe<Scalars["float8"]>;
  ReferenceName?: Maybe<Scalars["String"]>;
  RevenueTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  UraCode?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_PropertyType_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyType_stddev_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PaymentFrequencyId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_PropertyType" */
export type Rtcs_Db_Ph_Kcca_PropertyType_Stddev_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PaymentFrequencyId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_PropertyType_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyType_stddev_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PaymentFrequencyId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_PropertyType" */
export type Rtcs_Db_Ph_Kcca_PropertyType_Stddev_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PaymentFrequencyId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_PropertyType_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyType_stddev_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PaymentFrequencyId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_PropertyType" */
export type Rtcs_Db_Ph_Kcca_PropertyType_Stddev_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PaymentFrequencyId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_PropertyType_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyType_sum_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PaymentFrequencyId?: Maybe<Scalars["float8"]>;
  RevenueTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_PropertyType" */
export type Rtcs_Db_Ph_Kcca_PropertyType_Sum_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PaymentFrequencyId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_PropertyType_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyType_var_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PaymentFrequencyId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_PropertyType" */
export type Rtcs_Db_Ph_Kcca_PropertyType_Var_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PaymentFrequencyId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_PropertyType_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyType_var_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PaymentFrequencyId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_PropertyType" */
export type Rtcs_Db_Ph_Kcca_PropertyType_Var_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PaymentFrequencyId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_PropertyType_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_PropertyType_variance_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PaymentFrequencyId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_PropertyType" */
export type Rtcs_Db_Ph_Kcca_PropertyType_Variance_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PaymentFrequencyId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregated selection of "rtcs_db.PH_kcca_Property" */
export type Rtcs_Db_Ph_Kcca_Property_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_Property_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_Property_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_Property>;
};

/** aggregate fields of "rtcs_db.PH_kcca_Property" */
export type Rtcs_Db_Ph_Kcca_Property_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_Property_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_Property_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_Property_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_Property_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_Property_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_Property_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_Property_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_Property_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_Property_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_Property_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_Property_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_Property" */
export type Rtcs_Db_Ph_Kcca_Property_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_Property_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_Property" */
export type Rtcs_Db_Ph_Kcca_Property_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_Property_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_Property_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_Property_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_Property_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_Property_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_Property_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_Property_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_Property_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_Property_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_Property_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_Property" */
export type Rtcs_Db_Ph_Kcca_Property_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_Property_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_Property_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_Property_avg_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_Property" */
export type Rtcs_Db_Ph_Kcca_Property_Avg_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_Property". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_Property_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  BlockNo?: Maybe<String_Comparison_Exp>;
  CamvId?: Maybe<String_Comparison_Exp>;
  CreatedBy?: Maybe<String_Comparison_Exp>;
  CreatedDate?: Maybe<String_Comparison_Exp>;
  CurrentRateableValue?: Maybe<String_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  DivisionId?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  GprsCordinateX?: Maybe<String_Comparison_Exp>;
  GprsCordinateY?: Maybe<String_Comparison_Exp>;
  GrossValue?: Maybe<String_Comparison_Exp>;
  HouseNo?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  ImgPath?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<String_Comparison_Exp>;
  Latitude?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  LocationAddress?: Maybe<String_Comparison_Exp>;
  Longitude?: Maybe<String_Comparison_Exp>;
  MapTitle?: Maybe<String_Comparison_Exp>;
  ModifiedBy?: Maybe<String_Comparison_Exp>;
  ModifiedDate?: Maybe<String_Comparison_Exp>;
  ParishId?: Maybe<Bigint_Comparison_Exp>;
  ParticularsFrontages?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyNo?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatusId?: Maybe<Bigint_Comparison_Exp>;
  PropertyTypeId?: Maybe<Bigint_Comparison_Exp>;
  RateableValue?: Maybe<String_Comparison_Exp>;
  Remisson?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  RoadOthers?: Maybe<String_Comparison_Exp>;
  SerialNo?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Float8_Comparison_Exp>;
  StreetId?: Maybe<String_Comparison_Exp>;
  SubPropertyTypeId?: Maybe<Float8_Comparison_Exp>;
  Supplementary?: Maybe<String_Comparison_Exp>;
  VillageId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_Property_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_Property_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_Property_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_Property" */
export type Rtcs_Db_Ph_Kcca_Property_Inc_Input = {
  DivisionId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_Property" */
export type Rtcs_Db_Ph_Kcca_Property_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_Property_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_Property_max_fields";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_Property" */
export type Rtcs_Db_Ph_Kcca_Property_Max_Order_By = {
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GprsCordinateX?: Maybe<Order_By>;
  GprsCordinateY?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LocationAddress?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RoadOthers?: Maybe<Order_By>;
  SerialNo?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_Property_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_Property_min_fields";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_Property" */
export type Rtcs_Db_Ph_Kcca_Property_Min_Order_By = {
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GprsCordinateX?: Maybe<Order_By>;
  GprsCordinateY?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LocationAddress?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RoadOthers?: Maybe<Order_By>;
  SerialNo?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_Property" */
export type Rtcs_Db_Ph_Kcca_Property_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_Property_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_Property>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_Property" */
export type Rtcs_Db_Ph_Kcca_Property_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_Property_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_Property" */
export type Rtcs_Db_Ph_Kcca_Property_Order_By = {
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GprsCordinateX?: Maybe<Order_By>;
  GprsCordinateY?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LocationAddress?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RoadOthers?: Maybe<Order_By>;
  SerialNo?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_Property" */
export enum Rtcs_Db_Ph_Kcca_Property_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  CamvId = "CamvId",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CurrentRateableValue = "CurrentRateableValue",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  File = "File",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  GprsCordinateX = "GprsCordinateX",
  /** column name */
  GprsCordinateY = "GprsCordinateY",
  /** column name */
  GrossValue = "GrossValue",
  /** column name */
  HouseNo = "HouseNo",
  /** column name */
  Id = "Id",
  /** column name */
  ImgPath = "ImgPath",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  LocationAddress = "LocationAddress",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MapTitle = "MapTitle",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  ParticularsFrontages = "ParticularsFrontages",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyNo = "PropertyNo",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  RateableValue = "RateableValue",
  /** column name */
  Remisson = "Remisson",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  RoadOthers = "RoadOthers",
  /** column name */
  SerialNo = "SerialNo",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  SubPropertyTypeId = "SubPropertyTypeId",
  /** column name */
  Supplementary = "Supplementary",
  /** column name */
  VillageId = "VillageId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_Property" */
export type Rtcs_Db_Ph_Kcca_Property_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_Property_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_Property_stddev_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_Property" */
export type Rtcs_Db_Ph_Kcca_Property_Stddev_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_Property_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_Property_stddev_pop_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_Property" */
export type Rtcs_Db_Ph_Kcca_Property_Stddev_Pop_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_Property_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_Property_stddev_samp_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_Property" */
export type Rtcs_Db_Ph_Kcca_Property_Stddev_Samp_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_Property_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_Property_sum_fields";
  DivisionId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_Property" */
export type Rtcs_Db_Ph_Kcca_Property_Sum_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_Property_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_Property_var_pop_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_Property" */
export type Rtcs_Db_Ph_Kcca_Property_Var_Pop_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_Property_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_Property_var_samp_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_Property" */
export type Rtcs_Db_Ph_Kcca_Property_Var_Samp_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_Property_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_Property_variance_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_Property" */
export type Rtcs_Db_Ph_Kcca_Property_Variance_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_RawProperties1" */
export type Rtcs_Db_Ph_Kcca_RawProperties1 = {
  __typename?: "rtcs_db_PH_kcca_RawProperties1";
  AccomodationBathroom?: Maybe<Scalars["float8"]>;
  AccomodationBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["String"]>;
  AccomodationComment?: Maybe<Scalars["String"]>;
  AccomodationDiningRooms?: Maybe<Scalars["float8"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["float8"]>;
  AccomodationEnviromentalNoise?: Maybe<Scalars["String"]>;
  AccomodationGarage?: Maybe<Scalars["float8"]>;
  AccomodationKitchen?: Maybe<Scalars["float8"]>;
  AccomodationLaundry?: Maybe<Scalars["float8"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  AccomodationSecurity?: Maybe<Scalars["String"]>;
  AccomodationShops?: Maybe<Scalars["float8"]>;
  AccomodationShower?: Maybe<Scalars["float8"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["float8"]>;
  AccomodationStore?: Maybe<Scalars["float8"]>;
  AccomodationStudyRoom?: Maybe<Scalars["float8"]>;
  AccomodationVerandah?: Maybe<Scalars["float8"]>;
  AccomodationWc?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroom?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationWcShower?: Maybe<Scalars["float8"]>;
  AlternateEmail?: Maybe<Scalars["String"]>;
  AlternateFirstname?: Maybe<Scalars["String"]>;
  AlternateLandlineWithCode?: Maybe<Scalars["String"]>;
  AlternateMiddlename?: Maybe<Scalars["String"]>;
  AlternateMobile1?: Maybe<Scalars["String"]>;
  AlternateMobile2?: Maybe<Scalars["float8"]>;
  AlternateSurname?: Maybe<Scalars["String"]>;
  AlternateTittle?: Maybe<Scalars["String"]>;
  AlternativeDrivingPermit?: Maybe<Scalars["String"]>;
  AlternativeFinancialCard?: Maybe<Scalars["String"]>;
  AlternativeNationalId?: Maybe<Scalars["String"]>;
  AlternativeNssfCard?: Maybe<Scalars["float8"]>;
  AlternativePassport?: Maybe<Scalars["String"]>;
  AlternativeVillageId?: Maybe<Scalars["String"]>;
  BuildingBlockNumber?: Maybe<Scalars["String"]>;
  BuildingCondition?: Maybe<Scalars["String"]>;
  BuildingFlatNumber?: Maybe<Scalars["String"]>;
  BuildingFloorNumber?: Maybe<Scalars["float8"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BuildingNumber?: Maybe<Scalars["String"]>;
  BuildingOtherServices?: Maybe<Scalars["String"]>;
  BuildingParkingSlots?: Maybe<Scalars["String"]>;
  BuildingParkingSpaceAvailable?: Maybe<Scalars["String"]>;
  BuildingParkingSpaceSufficient?: Maybe<Scalars["String"]>;
  BuildingPowerSupply?: Maybe<Scalars["String"]>;
  BuildingRent?: Maybe<Scalars["String"]>;
  BuildingSanitationType?: Maybe<Scalars["String"]>;
  BuildingType?: Maybe<Scalars["String"]>;
  BuildingVerticalCirculation?: Maybe<Scalars["String"]>;
  BuildingWaterAccessibility?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  ConstructionCeiling?: Maybe<Scalars["String"]>;
  ConstructionDoorOthers?: Maybe<Scalars["String"]>;
  ConstructionDoors?: Maybe<Scalars["String"]>;
  ConstructionFloorsFinish?: Maybe<Scalars["String"]>;
  ConstructionFoorsConstruction?: Maybe<Scalars["String"]>;
  ConstructionOtherDetails?: Maybe<Scalars["String"]>;
  ConstructionRoofCovering?: Maybe<Scalars["String"]>;
  ConstructionWallFinish?: Maybe<Scalars["String"]>;
  ConstructionWalls?: Maybe<Scalars["String"]>;
  ConstructionWindowOthers?: Maybe<Scalars["String"]>;
  ConstructionWindows?: Maybe<Scalars["String"]>;
  ContactAddress?: Maybe<Scalars["String"]>;
  ContactCountry?: Maybe<Scalars["String"]>;
  ContactCountyMunicipality?: Maybe<Scalars["String"]>;
  ContactDistrict?: Maybe<Scalars["String"]>;
  ContactEmail?: Maybe<Scalars["String"]>;
  ContactLandline?: Maybe<Scalars["String"]>;
  ContactMobile1?: Maybe<Scalars["String"]>;
  ContactMobile2?: Maybe<Scalars["String"]>;
  ContactParishWard?: Maybe<Scalars["String"]>;
  ContactSubcountryDivision?: Maybe<Scalars["String"]>;
  ContactVillageCellZone?: Maybe<Scalars["String"]>;
  CordinateX?: Maybe<Scalars["String"]>;
  CordinateY?: Maybe<Scalars["String"]>;
  DataDate?: Maybe<Scalars["timestamptz"]>;
  Division?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Grade?: Maybe<Scalars["float8"]>;
  Gv?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationMaritalCertificate?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfCard?: Maybe<Scalars["float8"]>;
  IdentificationPassport?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  NameContact?: Maybe<Scalars["String"]>;
  OtherBoundaryFencespecify?: Maybe<Scalars["String"]>;
  OwnerCoin?: Maybe<Scalars["String"]>;
  OwnerFirstname?: Maybe<Scalars["String"]>;
  OwnerIndividualCompany?: Maybe<Scalars["String"]>;
  OwnerLegalSurnameMaiden?: Maybe<Scalars["String"]>;
  OwnerMiddlename?: Maybe<Scalars["String"]>;
  OwnerOthernames?: Maybe<Scalars["String"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["bigint"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParticularsAccess?: Maybe<Scalars["String"]>;
  ParticularsBlockName?: Maybe<Scalars["String"]>;
  ParticularsBlockNumber?: Maybe<Scalars["String"]>;
  ParticularsFolioNumber?: Maybe<Scalars["String"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["float8"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["float8"]>;
  ParticularsHouseNumber?: Maybe<Scalars["String"]>;
  ParticularsIsTitled?: Maybe<Scalars["String"]>;
  ParticularsLandReference?: Maybe<Scalars["String"]>;
  ParticularsNeighborhood?: Maybe<Scalars["String"]>;
  ParticularsPlotNumber?: Maybe<Scalars["String"]>;
  ParticularsPlotShape?: Maybe<Scalars["String"]>;
  ParticularsPropertyName?: Maybe<Scalars["String"]>;
  ParticularsRoadStreet?: Maybe<Scalars["String"]>;
  ParticularsTopographyOthers?: Maybe<Scalars["String"]>;
  ParticularsTopograpy?: Maybe<Scalars["String"]>;
  ParticularsTypeOfLandInterest?: Maybe<Scalars["String"]>;
  ParticularsVolume?: Maybe<Scalars["String"]>;
  PropertyOwnerOccupiedOrRented?: Maybe<Scalars["String"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  Pvillage?: Maybe<Scalars["String"]>;
  Reference?: Maybe<Scalars["float8"]>;
  ReferenceNumberNew?: Maybe<Scalars["float8"]>;
  RoadsOtherspecify?: Maybe<Scalars["String"]>;
  Rv?: Maybe<Scalars["float8"]>;
  SiteworksBoundaryFence?: Maybe<Scalars["String"]>;
  SiteworksBuildingNumber?: Maybe<Scalars["String"]>;
  SiteworksCompound?: Maybe<Scalars["String"]>;
  SiteworksCourt?: Maybe<Scalars["String"]>;
  SiteworksGate?: Maybe<Scalars["String"]>;
  SiteworksOtherFacilities?: Maybe<Scalars["String"]>;
  SiteworksOthers?: Maybe<Scalars["String"]>;
  SiteworksSwimmingPool?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_RawProperties1" */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_RawProperties1_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_RawProperties1>;
};

/** aggregate fields of "rtcs_db.PH_kcca_RawProperties1" */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_RawProperties1_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_RawProperties1" */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties1_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_RawProperties1" */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_RawProperties1" */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_RawProperties1_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_RawProperties1_avg_fields";
  AccomodationBathroom?: Maybe<Scalars["Float"]>;
  AccomodationBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationDiningRooms?: Maybe<Scalars["Float"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["Float"]>;
  AccomodationGarage?: Maybe<Scalars["Float"]>;
  AccomodationKitchen?: Maybe<Scalars["Float"]>;
  AccomodationLaundry?: Maybe<Scalars["Float"]>;
  AccomodationShops?: Maybe<Scalars["Float"]>;
  AccomodationShower?: Maybe<Scalars["Float"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["Float"]>;
  AccomodationStore?: Maybe<Scalars["Float"]>;
  AccomodationStudyRoom?: Maybe<Scalars["Float"]>;
  AccomodationVerandah?: Maybe<Scalars["Float"]>;
  AccomodationWc?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroom?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationWcShower?: Maybe<Scalars["Float"]>;
  AlternateMobile2?: Maybe<Scalars["Float"]>;
  AlternativeNssfCard?: Maybe<Scalars["Float"]>;
  BuildingFloorNumber?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  Grade?: Maybe<Scalars["Float"]>;
  IdentificationNssfCard?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["Float"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  ReferenceNumberNew?: Maybe<Scalars["Float"]>;
  Rv?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_RawProperties1" */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Avg_Order_By = {
  AccomodationBathroom?: Maybe<Order_By>;
  AccomodationBathroomShower?: Maybe<Order_By>;
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationDiningRooms?: Maybe<Order_By>;
  AccomodationEntertainmentRoom?: Maybe<Order_By>;
  AccomodationGarage?: Maybe<Order_By>;
  AccomodationKitchen?: Maybe<Order_By>;
  AccomodationLaundry?: Maybe<Order_By>;
  AccomodationShops?: Maybe<Order_By>;
  AccomodationShower?: Maybe<Order_By>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Order_By>;
  AccomodationStore?: Maybe<Order_By>;
  AccomodationStudyRoom?: Maybe<Order_By>;
  AccomodationVerandah?: Maybe<Order_By>;
  AccomodationWc?: Maybe<Order_By>;
  AccomodationWcBathroom?: Maybe<Order_By>;
  AccomodationWcBathroomShower?: Maybe<Order_By>;
  AccomodationWcShower?: Maybe<Order_By>;
  AlternateMobile2?: Maybe<Order_By>;
  AlternativeNssfCard?: Maybe<Order_By>;
  BuildingFloorNumber?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  Grade?: Maybe<Order_By>;
  IdentificationNssfCard?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParticularsGpsEastingsUtm?: Maybe<Order_By>;
  ParticularsGpsNorthingsUtm?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  ReferenceNumberNew?: Maybe<Order_By>;
  Rv?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_RawProperties1". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Bool_Exp = {
  AccomodationBathroom?: Maybe<Float8_Comparison_Exp>;
  AccomodationBathroomShower?: Maybe<Float8_Comparison_Exp>;
  AccomodationBedrooms?: Maybe<Float8_Comparison_Exp>;
  AccomodationBuiltUpArea?: Maybe<String_Comparison_Exp>;
  AccomodationComment?: Maybe<String_Comparison_Exp>;
  AccomodationDiningRooms?: Maybe<Float8_Comparison_Exp>;
  AccomodationEntertainmentRoom?: Maybe<Float8_Comparison_Exp>;
  AccomodationEnviromentalNoise?: Maybe<String_Comparison_Exp>;
  AccomodationGarage?: Maybe<Float8_Comparison_Exp>;
  AccomodationKitchen?: Maybe<Float8_Comparison_Exp>;
  AccomodationLaundry?: Maybe<Float8_Comparison_Exp>;
  AccomodationOthers?: Maybe<String_Comparison_Exp>;
  AccomodationSecurity?: Maybe<String_Comparison_Exp>;
  AccomodationShops?: Maybe<Float8_Comparison_Exp>;
  AccomodationShower?: Maybe<Float8_Comparison_Exp>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Float8_Comparison_Exp>;
  AccomodationStore?: Maybe<Float8_Comparison_Exp>;
  AccomodationStudyRoom?: Maybe<Float8_Comparison_Exp>;
  AccomodationVerandah?: Maybe<Float8_Comparison_Exp>;
  AccomodationWc?: Maybe<Float8_Comparison_Exp>;
  AccomodationWcBathroom?: Maybe<Float8_Comparison_Exp>;
  AccomodationWcBathroomShower?: Maybe<Float8_Comparison_Exp>;
  AccomodationWcShower?: Maybe<Float8_Comparison_Exp>;
  AlternateEmail?: Maybe<String_Comparison_Exp>;
  AlternateFirstname?: Maybe<String_Comparison_Exp>;
  AlternateLandlineWithCode?: Maybe<String_Comparison_Exp>;
  AlternateMiddlename?: Maybe<String_Comparison_Exp>;
  AlternateMobile1?: Maybe<String_Comparison_Exp>;
  AlternateMobile2?: Maybe<Float8_Comparison_Exp>;
  AlternateSurname?: Maybe<String_Comparison_Exp>;
  AlternateTittle?: Maybe<String_Comparison_Exp>;
  AlternativeDrivingPermit?: Maybe<String_Comparison_Exp>;
  AlternativeFinancialCard?: Maybe<String_Comparison_Exp>;
  AlternativeNationalId?: Maybe<String_Comparison_Exp>;
  AlternativeNssfCard?: Maybe<Float8_Comparison_Exp>;
  AlternativePassport?: Maybe<String_Comparison_Exp>;
  AlternativeVillageId?: Maybe<String_Comparison_Exp>;
  BuildingBlockNumber?: Maybe<String_Comparison_Exp>;
  BuildingCondition?: Maybe<String_Comparison_Exp>;
  BuildingFlatNumber?: Maybe<String_Comparison_Exp>;
  BuildingFloorNumber?: Maybe<Float8_Comparison_Exp>;
  BuildingName?: Maybe<String_Comparison_Exp>;
  BuildingNumber?: Maybe<String_Comparison_Exp>;
  BuildingOtherServices?: Maybe<String_Comparison_Exp>;
  BuildingParkingSlots?: Maybe<String_Comparison_Exp>;
  BuildingParkingSpaceAvailable?: Maybe<String_Comparison_Exp>;
  BuildingParkingSpaceSufficient?: Maybe<String_Comparison_Exp>;
  BuildingPowerSupply?: Maybe<String_Comparison_Exp>;
  BuildingRent?: Maybe<String_Comparison_Exp>;
  BuildingSanitationType?: Maybe<String_Comparison_Exp>;
  BuildingType?: Maybe<String_Comparison_Exp>;
  BuildingVerticalCirculation?: Maybe<String_Comparison_Exp>;
  BuildingWaterAccessibility?: Maybe<String_Comparison_Exp>;
  Coin?: Maybe<Float8_Comparison_Exp>;
  ConstructionCeiling?: Maybe<String_Comparison_Exp>;
  ConstructionDoorOthers?: Maybe<String_Comparison_Exp>;
  ConstructionDoors?: Maybe<String_Comparison_Exp>;
  ConstructionFloorsFinish?: Maybe<String_Comparison_Exp>;
  ConstructionFoorsConstruction?: Maybe<String_Comparison_Exp>;
  ConstructionOtherDetails?: Maybe<String_Comparison_Exp>;
  ConstructionRoofCovering?: Maybe<String_Comparison_Exp>;
  ConstructionWallFinish?: Maybe<String_Comparison_Exp>;
  ConstructionWalls?: Maybe<String_Comparison_Exp>;
  ConstructionWindowOthers?: Maybe<String_Comparison_Exp>;
  ConstructionWindows?: Maybe<String_Comparison_Exp>;
  ContactAddress?: Maybe<String_Comparison_Exp>;
  ContactCountry?: Maybe<String_Comparison_Exp>;
  ContactCountyMunicipality?: Maybe<String_Comparison_Exp>;
  ContactDistrict?: Maybe<String_Comparison_Exp>;
  ContactEmail?: Maybe<String_Comparison_Exp>;
  ContactLandline?: Maybe<String_Comparison_Exp>;
  ContactMobile1?: Maybe<String_Comparison_Exp>;
  ContactMobile2?: Maybe<String_Comparison_Exp>;
  ContactParishWard?: Maybe<String_Comparison_Exp>;
  ContactSubcountryDivision?: Maybe<String_Comparison_Exp>;
  ContactVillageCellZone?: Maybe<String_Comparison_Exp>;
  CordinateX?: Maybe<String_Comparison_Exp>;
  CordinateY?: Maybe<String_Comparison_Exp>;
  DataDate?: Maybe<Timestamptz_Comparison_Exp>;
  Division?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Grade?: Maybe<Float8_Comparison_Exp>;
  Gv?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  IdentificationDrivingPermit?: Maybe<String_Comparison_Exp>;
  IdentificationMaritalCertificate?: Maybe<String_Comparison_Exp>;
  IdentificationNationalId?: Maybe<String_Comparison_Exp>;
  IdentificationNssfCard?: Maybe<Float8_Comparison_Exp>;
  IdentificationPassport?: Maybe<String_Comparison_Exp>;
  IdentificationVillageId?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  NameContact?: Maybe<String_Comparison_Exp>;
  OtherBoundaryFencespecify?: Maybe<String_Comparison_Exp>;
  OwnerCoin?: Maybe<String_Comparison_Exp>;
  OwnerFirstname?: Maybe<String_Comparison_Exp>;
  OwnerIndividualCompany?: Maybe<String_Comparison_Exp>;
  OwnerLegalSurnameMaiden?: Maybe<String_Comparison_Exp>;
  OwnerMiddlename?: Maybe<String_Comparison_Exp>;
  OwnerOthernames?: Maybe<String_Comparison_Exp>;
  OwnerTin?: Maybe<Float8_Comparison_Exp>;
  OwnerTitle?: Maybe<Bigint_Comparison_Exp>;
  Parish?: Maybe<String_Comparison_Exp>;
  ParticularsAccess?: Maybe<String_Comparison_Exp>;
  ParticularsBlockName?: Maybe<String_Comparison_Exp>;
  ParticularsBlockNumber?: Maybe<String_Comparison_Exp>;
  ParticularsFolioNumber?: Maybe<String_Comparison_Exp>;
  ParticularsFrontages?: Maybe<String_Comparison_Exp>;
  ParticularsGpsEastingsUtm?: Maybe<Float8_Comparison_Exp>;
  ParticularsGpsNorthingsUtm?: Maybe<Float8_Comparison_Exp>;
  ParticularsHouseNumber?: Maybe<String_Comparison_Exp>;
  ParticularsIsTitled?: Maybe<String_Comparison_Exp>;
  ParticularsLandReference?: Maybe<String_Comparison_Exp>;
  ParticularsNeighborhood?: Maybe<String_Comparison_Exp>;
  ParticularsPlotNumber?: Maybe<String_Comparison_Exp>;
  ParticularsPlotShape?: Maybe<String_Comparison_Exp>;
  ParticularsPropertyName?: Maybe<String_Comparison_Exp>;
  ParticularsRoadStreet?: Maybe<String_Comparison_Exp>;
  ParticularsTopographyOthers?: Maybe<String_Comparison_Exp>;
  ParticularsTopograpy?: Maybe<String_Comparison_Exp>;
  ParticularsTypeOfLandInterest?: Maybe<String_Comparison_Exp>;
  ParticularsVolume?: Maybe<String_Comparison_Exp>;
  PropertyOwnerOccupiedOrRented?: Maybe<String_Comparison_Exp>;
  PropertySubType?: Maybe<String_Comparison_Exp>;
  PropertyType?: Maybe<String_Comparison_Exp>;
  Pvillage?: Maybe<String_Comparison_Exp>;
  Reference?: Maybe<Float8_Comparison_Exp>;
  ReferenceNumberNew?: Maybe<Float8_Comparison_Exp>;
  RoadsOtherspecify?: Maybe<String_Comparison_Exp>;
  Rv?: Maybe<Float8_Comparison_Exp>;
  SiteworksBoundaryFence?: Maybe<String_Comparison_Exp>;
  SiteworksBuildingNumber?: Maybe<String_Comparison_Exp>;
  SiteworksCompound?: Maybe<String_Comparison_Exp>;
  SiteworksCourt?: Maybe<String_Comparison_Exp>;
  SiteworksGate?: Maybe<String_Comparison_Exp>;
  SiteworksOtherFacilities?: Maybe<String_Comparison_Exp>;
  SiteworksOthers?: Maybe<String_Comparison_Exp>;
  SiteworksSwimmingPool?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_RawProperties1" */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Inc_Input = {
  AccomodationBathroom?: Maybe<Scalars["float8"]>;
  AccomodationBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationDiningRooms?: Maybe<Scalars["float8"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["float8"]>;
  AccomodationGarage?: Maybe<Scalars["float8"]>;
  AccomodationKitchen?: Maybe<Scalars["float8"]>;
  AccomodationLaundry?: Maybe<Scalars["float8"]>;
  AccomodationShops?: Maybe<Scalars["float8"]>;
  AccomodationShower?: Maybe<Scalars["float8"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["float8"]>;
  AccomodationStore?: Maybe<Scalars["float8"]>;
  AccomodationStudyRoom?: Maybe<Scalars["float8"]>;
  AccomodationVerandah?: Maybe<Scalars["float8"]>;
  AccomodationWc?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroom?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationWcShower?: Maybe<Scalars["float8"]>;
  AlternateMobile2?: Maybe<Scalars["float8"]>;
  AlternativeNssfCard?: Maybe<Scalars["float8"]>;
  BuildingFloorNumber?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  Grade?: Maybe<Scalars["float8"]>;
  IdentificationNssfCard?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["bigint"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["float8"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["float8"]>;
  Reference?: Maybe<Scalars["float8"]>;
  ReferenceNumberNew?: Maybe<Scalars["float8"]>;
  Rv?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_RawProperties1" */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Insert_Input = {
  AccomodationBathroom?: Maybe<Scalars["float8"]>;
  AccomodationBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["String"]>;
  AccomodationComment?: Maybe<Scalars["String"]>;
  AccomodationDiningRooms?: Maybe<Scalars["float8"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["float8"]>;
  AccomodationEnviromentalNoise?: Maybe<Scalars["String"]>;
  AccomodationGarage?: Maybe<Scalars["float8"]>;
  AccomodationKitchen?: Maybe<Scalars["float8"]>;
  AccomodationLaundry?: Maybe<Scalars["float8"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  AccomodationSecurity?: Maybe<Scalars["String"]>;
  AccomodationShops?: Maybe<Scalars["float8"]>;
  AccomodationShower?: Maybe<Scalars["float8"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["float8"]>;
  AccomodationStore?: Maybe<Scalars["float8"]>;
  AccomodationStudyRoom?: Maybe<Scalars["float8"]>;
  AccomodationVerandah?: Maybe<Scalars["float8"]>;
  AccomodationWc?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroom?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationWcShower?: Maybe<Scalars["float8"]>;
  AlternateEmail?: Maybe<Scalars["String"]>;
  AlternateFirstname?: Maybe<Scalars["String"]>;
  AlternateLandlineWithCode?: Maybe<Scalars["String"]>;
  AlternateMiddlename?: Maybe<Scalars["String"]>;
  AlternateMobile1?: Maybe<Scalars["String"]>;
  AlternateMobile2?: Maybe<Scalars["float8"]>;
  AlternateSurname?: Maybe<Scalars["String"]>;
  AlternateTittle?: Maybe<Scalars["String"]>;
  AlternativeDrivingPermit?: Maybe<Scalars["String"]>;
  AlternativeFinancialCard?: Maybe<Scalars["String"]>;
  AlternativeNationalId?: Maybe<Scalars["String"]>;
  AlternativeNssfCard?: Maybe<Scalars["float8"]>;
  AlternativePassport?: Maybe<Scalars["String"]>;
  AlternativeVillageId?: Maybe<Scalars["String"]>;
  BuildingBlockNumber?: Maybe<Scalars["String"]>;
  BuildingCondition?: Maybe<Scalars["String"]>;
  BuildingFlatNumber?: Maybe<Scalars["String"]>;
  BuildingFloorNumber?: Maybe<Scalars["float8"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BuildingNumber?: Maybe<Scalars["String"]>;
  BuildingOtherServices?: Maybe<Scalars["String"]>;
  BuildingParkingSlots?: Maybe<Scalars["String"]>;
  BuildingParkingSpaceAvailable?: Maybe<Scalars["String"]>;
  BuildingParkingSpaceSufficient?: Maybe<Scalars["String"]>;
  BuildingPowerSupply?: Maybe<Scalars["String"]>;
  BuildingRent?: Maybe<Scalars["String"]>;
  BuildingSanitationType?: Maybe<Scalars["String"]>;
  BuildingType?: Maybe<Scalars["String"]>;
  BuildingVerticalCirculation?: Maybe<Scalars["String"]>;
  BuildingWaterAccessibility?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  ConstructionCeiling?: Maybe<Scalars["String"]>;
  ConstructionDoorOthers?: Maybe<Scalars["String"]>;
  ConstructionDoors?: Maybe<Scalars["String"]>;
  ConstructionFloorsFinish?: Maybe<Scalars["String"]>;
  ConstructionFoorsConstruction?: Maybe<Scalars["String"]>;
  ConstructionOtherDetails?: Maybe<Scalars["String"]>;
  ConstructionRoofCovering?: Maybe<Scalars["String"]>;
  ConstructionWallFinish?: Maybe<Scalars["String"]>;
  ConstructionWalls?: Maybe<Scalars["String"]>;
  ConstructionWindowOthers?: Maybe<Scalars["String"]>;
  ConstructionWindows?: Maybe<Scalars["String"]>;
  ContactAddress?: Maybe<Scalars["String"]>;
  ContactCountry?: Maybe<Scalars["String"]>;
  ContactCountyMunicipality?: Maybe<Scalars["String"]>;
  ContactDistrict?: Maybe<Scalars["String"]>;
  ContactEmail?: Maybe<Scalars["String"]>;
  ContactLandline?: Maybe<Scalars["String"]>;
  ContactMobile1?: Maybe<Scalars["String"]>;
  ContactMobile2?: Maybe<Scalars["String"]>;
  ContactParishWard?: Maybe<Scalars["String"]>;
  ContactSubcountryDivision?: Maybe<Scalars["String"]>;
  ContactVillageCellZone?: Maybe<Scalars["String"]>;
  CordinateX?: Maybe<Scalars["String"]>;
  CordinateY?: Maybe<Scalars["String"]>;
  DataDate?: Maybe<Scalars["timestamptz"]>;
  Division?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Grade?: Maybe<Scalars["float8"]>;
  Gv?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationMaritalCertificate?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfCard?: Maybe<Scalars["float8"]>;
  IdentificationPassport?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  NameContact?: Maybe<Scalars["String"]>;
  OtherBoundaryFencespecify?: Maybe<Scalars["String"]>;
  OwnerCoin?: Maybe<Scalars["String"]>;
  OwnerFirstname?: Maybe<Scalars["String"]>;
  OwnerIndividualCompany?: Maybe<Scalars["String"]>;
  OwnerLegalSurnameMaiden?: Maybe<Scalars["String"]>;
  OwnerMiddlename?: Maybe<Scalars["String"]>;
  OwnerOthernames?: Maybe<Scalars["String"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["bigint"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParticularsAccess?: Maybe<Scalars["String"]>;
  ParticularsBlockName?: Maybe<Scalars["String"]>;
  ParticularsBlockNumber?: Maybe<Scalars["String"]>;
  ParticularsFolioNumber?: Maybe<Scalars["String"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["float8"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["float8"]>;
  ParticularsHouseNumber?: Maybe<Scalars["String"]>;
  ParticularsIsTitled?: Maybe<Scalars["String"]>;
  ParticularsLandReference?: Maybe<Scalars["String"]>;
  ParticularsNeighborhood?: Maybe<Scalars["String"]>;
  ParticularsPlotNumber?: Maybe<Scalars["String"]>;
  ParticularsPlotShape?: Maybe<Scalars["String"]>;
  ParticularsPropertyName?: Maybe<Scalars["String"]>;
  ParticularsRoadStreet?: Maybe<Scalars["String"]>;
  ParticularsTopographyOthers?: Maybe<Scalars["String"]>;
  ParticularsTopograpy?: Maybe<Scalars["String"]>;
  ParticularsTypeOfLandInterest?: Maybe<Scalars["String"]>;
  ParticularsVolume?: Maybe<Scalars["String"]>;
  PropertyOwnerOccupiedOrRented?: Maybe<Scalars["String"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  Pvillage?: Maybe<Scalars["String"]>;
  Reference?: Maybe<Scalars["float8"]>;
  ReferenceNumberNew?: Maybe<Scalars["float8"]>;
  RoadsOtherspecify?: Maybe<Scalars["String"]>;
  Rv?: Maybe<Scalars["float8"]>;
  SiteworksBoundaryFence?: Maybe<Scalars["String"]>;
  SiteworksBuildingNumber?: Maybe<Scalars["String"]>;
  SiteworksCompound?: Maybe<Scalars["String"]>;
  SiteworksCourt?: Maybe<Scalars["String"]>;
  SiteworksGate?: Maybe<Scalars["String"]>;
  SiteworksOtherFacilities?: Maybe<Scalars["String"]>;
  SiteworksOthers?: Maybe<Scalars["String"]>;
  SiteworksSwimmingPool?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_RawProperties1_max_fields";
  AccomodationBathroom?: Maybe<Scalars["float8"]>;
  AccomodationBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["String"]>;
  AccomodationComment?: Maybe<Scalars["String"]>;
  AccomodationDiningRooms?: Maybe<Scalars["float8"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["float8"]>;
  AccomodationEnviromentalNoise?: Maybe<Scalars["String"]>;
  AccomodationGarage?: Maybe<Scalars["float8"]>;
  AccomodationKitchen?: Maybe<Scalars["float8"]>;
  AccomodationLaundry?: Maybe<Scalars["float8"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  AccomodationSecurity?: Maybe<Scalars["String"]>;
  AccomodationShops?: Maybe<Scalars["float8"]>;
  AccomodationShower?: Maybe<Scalars["float8"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["float8"]>;
  AccomodationStore?: Maybe<Scalars["float8"]>;
  AccomodationStudyRoom?: Maybe<Scalars["float8"]>;
  AccomodationVerandah?: Maybe<Scalars["float8"]>;
  AccomodationWc?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroom?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationWcShower?: Maybe<Scalars["float8"]>;
  AlternateEmail?: Maybe<Scalars["String"]>;
  AlternateFirstname?: Maybe<Scalars["String"]>;
  AlternateLandlineWithCode?: Maybe<Scalars["String"]>;
  AlternateMiddlename?: Maybe<Scalars["String"]>;
  AlternateMobile1?: Maybe<Scalars["String"]>;
  AlternateMobile2?: Maybe<Scalars["float8"]>;
  AlternateSurname?: Maybe<Scalars["String"]>;
  AlternateTittle?: Maybe<Scalars["String"]>;
  AlternativeDrivingPermit?: Maybe<Scalars["String"]>;
  AlternativeFinancialCard?: Maybe<Scalars["String"]>;
  AlternativeNationalId?: Maybe<Scalars["String"]>;
  AlternativeNssfCard?: Maybe<Scalars["float8"]>;
  AlternativePassport?: Maybe<Scalars["String"]>;
  AlternativeVillageId?: Maybe<Scalars["String"]>;
  BuildingBlockNumber?: Maybe<Scalars["String"]>;
  BuildingCondition?: Maybe<Scalars["String"]>;
  BuildingFlatNumber?: Maybe<Scalars["String"]>;
  BuildingFloorNumber?: Maybe<Scalars["float8"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BuildingNumber?: Maybe<Scalars["String"]>;
  BuildingOtherServices?: Maybe<Scalars["String"]>;
  BuildingParkingSlots?: Maybe<Scalars["String"]>;
  BuildingParkingSpaceAvailable?: Maybe<Scalars["String"]>;
  BuildingParkingSpaceSufficient?: Maybe<Scalars["String"]>;
  BuildingPowerSupply?: Maybe<Scalars["String"]>;
  BuildingRent?: Maybe<Scalars["String"]>;
  BuildingSanitationType?: Maybe<Scalars["String"]>;
  BuildingType?: Maybe<Scalars["String"]>;
  BuildingVerticalCirculation?: Maybe<Scalars["String"]>;
  BuildingWaterAccessibility?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  ConstructionCeiling?: Maybe<Scalars["String"]>;
  ConstructionDoorOthers?: Maybe<Scalars["String"]>;
  ConstructionDoors?: Maybe<Scalars["String"]>;
  ConstructionFloorsFinish?: Maybe<Scalars["String"]>;
  ConstructionFoorsConstruction?: Maybe<Scalars["String"]>;
  ConstructionOtherDetails?: Maybe<Scalars["String"]>;
  ConstructionRoofCovering?: Maybe<Scalars["String"]>;
  ConstructionWallFinish?: Maybe<Scalars["String"]>;
  ConstructionWalls?: Maybe<Scalars["String"]>;
  ConstructionWindowOthers?: Maybe<Scalars["String"]>;
  ConstructionWindows?: Maybe<Scalars["String"]>;
  ContactAddress?: Maybe<Scalars["String"]>;
  ContactCountry?: Maybe<Scalars["String"]>;
  ContactCountyMunicipality?: Maybe<Scalars["String"]>;
  ContactDistrict?: Maybe<Scalars["String"]>;
  ContactEmail?: Maybe<Scalars["String"]>;
  ContactLandline?: Maybe<Scalars["String"]>;
  ContactMobile1?: Maybe<Scalars["String"]>;
  ContactMobile2?: Maybe<Scalars["String"]>;
  ContactParishWard?: Maybe<Scalars["String"]>;
  ContactSubcountryDivision?: Maybe<Scalars["String"]>;
  ContactVillageCellZone?: Maybe<Scalars["String"]>;
  CordinateX?: Maybe<Scalars["String"]>;
  CordinateY?: Maybe<Scalars["String"]>;
  DataDate?: Maybe<Scalars["timestamptz"]>;
  Division?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Grade?: Maybe<Scalars["float8"]>;
  Gv?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationMaritalCertificate?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfCard?: Maybe<Scalars["float8"]>;
  IdentificationPassport?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  NameContact?: Maybe<Scalars["String"]>;
  OtherBoundaryFencespecify?: Maybe<Scalars["String"]>;
  OwnerCoin?: Maybe<Scalars["String"]>;
  OwnerFirstname?: Maybe<Scalars["String"]>;
  OwnerIndividualCompany?: Maybe<Scalars["String"]>;
  OwnerLegalSurnameMaiden?: Maybe<Scalars["String"]>;
  OwnerMiddlename?: Maybe<Scalars["String"]>;
  OwnerOthernames?: Maybe<Scalars["String"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["bigint"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParticularsAccess?: Maybe<Scalars["String"]>;
  ParticularsBlockName?: Maybe<Scalars["String"]>;
  ParticularsBlockNumber?: Maybe<Scalars["String"]>;
  ParticularsFolioNumber?: Maybe<Scalars["String"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["float8"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["float8"]>;
  ParticularsHouseNumber?: Maybe<Scalars["String"]>;
  ParticularsIsTitled?: Maybe<Scalars["String"]>;
  ParticularsLandReference?: Maybe<Scalars["String"]>;
  ParticularsNeighborhood?: Maybe<Scalars["String"]>;
  ParticularsPlotNumber?: Maybe<Scalars["String"]>;
  ParticularsPlotShape?: Maybe<Scalars["String"]>;
  ParticularsPropertyName?: Maybe<Scalars["String"]>;
  ParticularsRoadStreet?: Maybe<Scalars["String"]>;
  ParticularsTopographyOthers?: Maybe<Scalars["String"]>;
  ParticularsTopograpy?: Maybe<Scalars["String"]>;
  ParticularsTypeOfLandInterest?: Maybe<Scalars["String"]>;
  ParticularsVolume?: Maybe<Scalars["String"]>;
  PropertyOwnerOccupiedOrRented?: Maybe<Scalars["String"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  Pvillage?: Maybe<Scalars["String"]>;
  Reference?: Maybe<Scalars["float8"]>;
  ReferenceNumberNew?: Maybe<Scalars["float8"]>;
  RoadsOtherspecify?: Maybe<Scalars["String"]>;
  Rv?: Maybe<Scalars["float8"]>;
  SiteworksBoundaryFence?: Maybe<Scalars["String"]>;
  SiteworksBuildingNumber?: Maybe<Scalars["String"]>;
  SiteworksCompound?: Maybe<Scalars["String"]>;
  SiteworksCourt?: Maybe<Scalars["String"]>;
  SiteworksGate?: Maybe<Scalars["String"]>;
  SiteworksOtherFacilities?: Maybe<Scalars["String"]>;
  SiteworksOthers?: Maybe<Scalars["String"]>;
  SiteworksSwimmingPool?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_RawProperties1" */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Max_Order_By = {
  AccomodationBathroom?: Maybe<Order_By>;
  AccomodationBathroomShower?: Maybe<Order_By>;
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AccomodationComment?: Maybe<Order_By>;
  AccomodationDiningRooms?: Maybe<Order_By>;
  AccomodationEntertainmentRoom?: Maybe<Order_By>;
  AccomodationEnviromentalNoise?: Maybe<Order_By>;
  AccomodationGarage?: Maybe<Order_By>;
  AccomodationKitchen?: Maybe<Order_By>;
  AccomodationLaundry?: Maybe<Order_By>;
  AccomodationOthers?: Maybe<Order_By>;
  AccomodationSecurity?: Maybe<Order_By>;
  AccomodationShops?: Maybe<Order_By>;
  AccomodationShower?: Maybe<Order_By>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Order_By>;
  AccomodationStore?: Maybe<Order_By>;
  AccomodationStudyRoom?: Maybe<Order_By>;
  AccomodationVerandah?: Maybe<Order_By>;
  AccomodationWc?: Maybe<Order_By>;
  AccomodationWcBathroom?: Maybe<Order_By>;
  AccomodationWcBathroomShower?: Maybe<Order_By>;
  AccomodationWcShower?: Maybe<Order_By>;
  AlternateEmail?: Maybe<Order_By>;
  AlternateFirstname?: Maybe<Order_By>;
  AlternateLandlineWithCode?: Maybe<Order_By>;
  AlternateMiddlename?: Maybe<Order_By>;
  AlternateMobile1?: Maybe<Order_By>;
  AlternateMobile2?: Maybe<Order_By>;
  AlternateSurname?: Maybe<Order_By>;
  AlternateTittle?: Maybe<Order_By>;
  AlternativeDrivingPermit?: Maybe<Order_By>;
  AlternativeFinancialCard?: Maybe<Order_By>;
  AlternativeNationalId?: Maybe<Order_By>;
  AlternativeNssfCard?: Maybe<Order_By>;
  AlternativePassport?: Maybe<Order_By>;
  AlternativeVillageId?: Maybe<Order_By>;
  BuildingBlockNumber?: Maybe<Order_By>;
  BuildingCondition?: Maybe<Order_By>;
  BuildingFlatNumber?: Maybe<Order_By>;
  BuildingFloorNumber?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BuildingNumber?: Maybe<Order_By>;
  BuildingOtherServices?: Maybe<Order_By>;
  BuildingParkingSlots?: Maybe<Order_By>;
  BuildingParkingSpaceAvailable?: Maybe<Order_By>;
  BuildingParkingSpaceSufficient?: Maybe<Order_By>;
  BuildingPowerSupply?: Maybe<Order_By>;
  BuildingRent?: Maybe<Order_By>;
  BuildingSanitationType?: Maybe<Order_By>;
  BuildingType?: Maybe<Order_By>;
  BuildingVerticalCirculation?: Maybe<Order_By>;
  BuildingWaterAccessibility?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  ConstructionCeiling?: Maybe<Order_By>;
  ConstructionDoorOthers?: Maybe<Order_By>;
  ConstructionDoors?: Maybe<Order_By>;
  ConstructionFloorsFinish?: Maybe<Order_By>;
  ConstructionFoorsConstruction?: Maybe<Order_By>;
  ConstructionOtherDetails?: Maybe<Order_By>;
  ConstructionRoofCovering?: Maybe<Order_By>;
  ConstructionWallFinish?: Maybe<Order_By>;
  ConstructionWalls?: Maybe<Order_By>;
  ConstructionWindowOthers?: Maybe<Order_By>;
  ConstructionWindows?: Maybe<Order_By>;
  ContactAddress?: Maybe<Order_By>;
  ContactCountry?: Maybe<Order_By>;
  ContactCountyMunicipality?: Maybe<Order_By>;
  ContactDistrict?: Maybe<Order_By>;
  ContactEmail?: Maybe<Order_By>;
  ContactLandline?: Maybe<Order_By>;
  ContactMobile1?: Maybe<Order_By>;
  ContactMobile2?: Maybe<Order_By>;
  ContactParishWard?: Maybe<Order_By>;
  ContactSubcountryDivision?: Maybe<Order_By>;
  ContactVillageCellZone?: Maybe<Order_By>;
  CordinateX?: Maybe<Order_By>;
  CordinateY?: Maybe<Order_By>;
  DataDate?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Grade?: Maybe<Order_By>;
  Gv?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationDrivingPermit?: Maybe<Order_By>;
  IdentificationMaritalCertificate?: Maybe<Order_By>;
  IdentificationNationalId?: Maybe<Order_By>;
  IdentificationNssfCard?: Maybe<Order_By>;
  IdentificationPassport?: Maybe<Order_By>;
  IdentificationVillageId?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NameContact?: Maybe<Order_By>;
  OtherBoundaryFencespecify?: Maybe<Order_By>;
  OwnerCoin?: Maybe<Order_By>;
  OwnerFirstname?: Maybe<Order_By>;
  OwnerIndividualCompany?: Maybe<Order_By>;
  OwnerLegalSurnameMaiden?: Maybe<Order_By>;
  OwnerMiddlename?: Maybe<Order_By>;
  OwnerOthernames?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  ParticularsAccess?: Maybe<Order_By>;
  ParticularsBlockName?: Maybe<Order_By>;
  ParticularsBlockNumber?: Maybe<Order_By>;
  ParticularsFolioNumber?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  ParticularsGpsEastingsUtm?: Maybe<Order_By>;
  ParticularsGpsNorthingsUtm?: Maybe<Order_By>;
  ParticularsHouseNumber?: Maybe<Order_By>;
  ParticularsIsTitled?: Maybe<Order_By>;
  ParticularsLandReference?: Maybe<Order_By>;
  ParticularsNeighborhood?: Maybe<Order_By>;
  ParticularsPlotNumber?: Maybe<Order_By>;
  ParticularsPlotShape?: Maybe<Order_By>;
  ParticularsPropertyName?: Maybe<Order_By>;
  ParticularsRoadStreet?: Maybe<Order_By>;
  ParticularsTopographyOthers?: Maybe<Order_By>;
  ParticularsTopograpy?: Maybe<Order_By>;
  ParticularsTypeOfLandInterest?: Maybe<Order_By>;
  ParticularsVolume?: Maybe<Order_By>;
  PropertyOwnerOccupiedOrRented?: Maybe<Order_By>;
  PropertySubType?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  Pvillage?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  ReferenceNumberNew?: Maybe<Order_By>;
  RoadsOtherspecify?: Maybe<Order_By>;
  Rv?: Maybe<Order_By>;
  SiteworksBoundaryFence?: Maybe<Order_By>;
  SiteworksBuildingNumber?: Maybe<Order_By>;
  SiteworksCompound?: Maybe<Order_By>;
  SiteworksCourt?: Maybe<Order_By>;
  SiteworksGate?: Maybe<Order_By>;
  SiteworksOtherFacilities?: Maybe<Order_By>;
  SiteworksOthers?: Maybe<Order_By>;
  SiteworksSwimmingPool?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_RawProperties1_min_fields";
  AccomodationBathroom?: Maybe<Scalars["float8"]>;
  AccomodationBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["String"]>;
  AccomodationComment?: Maybe<Scalars["String"]>;
  AccomodationDiningRooms?: Maybe<Scalars["float8"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["float8"]>;
  AccomodationEnviromentalNoise?: Maybe<Scalars["String"]>;
  AccomodationGarage?: Maybe<Scalars["float8"]>;
  AccomodationKitchen?: Maybe<Scalars["float8"]>;
  AccomodationLaundry?: Maybe<Scalars["float8"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  AccomodationSecurity?: Maybe<Scalars["String"]>;
  AccomodationShops?: Maybe<Scalars["float8"]>;
  AccomodationShower?: Maybe<Scalars["float8"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["float8"]>;
  AccomodationStore?: Maybe<Scalars["float8"]>;
  AccomodationStudyRoom?: Maybe<Scalars["float8"]>;
  AccomodationVerandah?: Maybe<Scalars["float8"]>;
  AccomodationWc?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroom?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationWcShower?: Maybe<Scalars["float8"]>;
  AlternateEmail?: Maybe<Scalars["String"]>;
  AlternateFirstname?: Maybe<Scalars["String"]>;
  AlternateLandlineWithCode?: Maybe<Scalars["String"]>;
  AlternateMiddlename?: Maybe<Scalars["String"]>;
  AlternateMobile1?: Maybe<Scalars["String"]>;
  AlternateMobile2?: Maybe<Scalars["float8"]>;
  AlternateSurname?: Maybe<Scalars["String"]>;
  AlternateTittle?: Maybe<Scalars["String"]>;
  AlternativeDrivingPermit?: Maybe<Scalars["String"]>;
  AlternativeFinancialCard?: Maybe<Scalars["String"]>;
  AlternativeNationalId?: Maybe<Scalars["String"]>;
  AlternativeNssfCard?: Maybe<Scalars["float8"]>;
  AlternativePassport?: Maybe<Scalars["String"]>;
  AlternativeVillageId?: Maybe<Scalars["String"]>;
  BuildingBlockNumber?: Maybe<Scalars["String"]>;
  BuildingCondition?: Maybe<Scalars["String"]>;
  BuildingFlatNumber?: Maybe<Scalars["String"]>;
  BuildingFloorNumber?: Maybe<Scalars["float8"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BuildingNumber?: Maybe<Scalars["String"]>;
  BuildingOtherServices?: Maybe<Scalars["String"]>;
  BuildingParkingSlots?: Maybe<Scalars["String"]>;
  BuildingParkingSpaceAvailable?: Maybe<Scalars["String"]>;
  BuildingParkingSpaceSufficient?: Maybe<Scalars["String"]>;
  BuildingPowerSupply?: Maybe<Scalars["String"]>;
  BuildingRent?: Maybe<Scalars["String"]>;
  BuildingSanitationType?: Maybe<Scalars["String"]>;
  BuildingType?: Maybe<Scalars["String"]>;
  BuildingVerticalCirculation?: Maybe<Scalars["String"]>;
  BuildingWaterAccessibility?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  ConstructionCeiling?: Maybe<Scalars["String"]>;
  ConstructionDoorOthers?: Maybe<Scalars["String"]>;
  ConstructionDoors?: Maybe<Scalars["String"]>;
  ConstructionFloorsFinish?: Maybe<Scalars["String"]>;
  ConstructionFoorsConstruction?: Maybe<Scalars["String"]>;
  ConstructionOtherDetails?: Maybe<Scalars["String"]>;
  ConstructionRoofCovering?: Maybe<Scalars["String"]>;
  ConstructionWallFinish?: Maybe<Scalars["String"]>;
  ConstructionWalls?: Maybe<Scalars["String"]>;
  ConstructionWindowOthers?: Maybe<Scalars["String"]>;
  ConstructionWindows?: Maybe<Scalars["String"]>;
  ContactAddress?: Maybe<Scalars["String"]>;
  ContactCountry?: Maybe<Scalars["String"]>;
  ContactCountyMunicipality?: Maybe<Scalars["String"]>;
  ContactDistrict?: Maybe<Scalars["String"]>;
  ContactEmail?: Maybe<Scalars["String"]>;
  ContactLandline?: Maybe<Scalars["String"]>;
  ContactMobile1?: Maybe<Scalars["String"]>;
  ContactMobile2?: Maybe<Scalars["String"]>;
  ContactParishWard?: Maybe<Scalars["String"]>;
  ContactSubcountryDivision?: Maybe<Scalars["String"]>;
  ContactVillageCellZone?: Maybe<Scalars["String"]>;
  CordinateX?: Maybe<Scalars["String"]>;
  CordinateY?: Maybe<Scalars["String"]>;
  DataDate?: Maybe<Scalars["timestamptz"]>;
  Division?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Grade?: Maybe<Scalars["float8"]>;
  Gv?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationMaritalCertificate?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfCard?: Maybe<Scalars["float8"]>;
  IdentificationPassport?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  NameContact?: Maybe<Scalars["String"]>;
  OtherBoundaryFencespecify?: Maybe<Scalars["String"]>;
  OwnerCoin?: Maybe<Scalars["String"]>;
  OwnerFirstname?: Maybe<Scalars["String"]>;
  OwnerIndividualCompany?: Maybe<Scalars["String"]>;
  OwnerLegalSurnameMaiden?: Maybe<Scalars["String"]>;
  OwnerMiddlename?: Maybe<Scalars["String"]>;
  OwnerOthernames?: Maybe<Scalars["String"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["bigint"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParticularsAccess?: Maybe<Scalars["String"]>;
  ParticularsBlockName?: Maybe<Scalars["String"]>;
  ParticularsBlockNumber?: Maybe<Scalars["String"]>;
  ParticularsFolioNumber?: Maybe<Scalars["String"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["float8"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["float8"]>;
  ParticularsHouseNumber?: Maybe<Scalars["String"]>;
  ParticularsIsTitled?: Maybe<Scalars["String"]>;
  ParticularsLandReference?: Maybe<Scalars["String"]>;
  ParticularsNeighborhood?: Maybe<Scalars["String"]>;
  ParticularsPlotNumber?: Maybe<Scalars["String"]>;
  ParticularsPlotShape?: Maybe<Scalars["String"]>;
  ParticularsPropertyName?: Maybe<Scalars["String"]>;
  ParticularsRoadStreet?: Maybe<Scalars["String"]>;
  ParticularsTopographyOthers?: Maybe<Scalars["String"]>;
  ParticularsTopograpy?: Maybe<Scalars["String"]>;
  ParticularsTypeOfLandInterest?: Maybe<Scalars["String"]>;
  ParticularsVolume?: Maybe<Scalars["String"]>;
  PropertyOwnerOccupiedOrRented?: Maybe<Scalars["String"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  Pvillage?: Maybe<Scalars["String"]>;
  Reference?: Maybe<Scalars["float8"]>;
  ReferenceNumberNew?: Maybe<Scalars["float8"]>;
  RoadsOtherspecify?: Maybe<Scalars["String"]>;
  Rv?: Maybe<Scalars["float8"]>;
  SiteworksBoundaryFence?: Maybe<Scalars["String"]>;
  SiteworksBuildingNumber?: Maybe<Scalars["String"]>;
  SiteworksCompound?: Maybe<Scalars["String"]>;
  SiteworksCourt?: Maybe<Scalars["String"]>;
  SiteworksGate?: Maybe<Scalars["String"]>;
  SiteworksOtherFacilities?: Maybe<Scalars["String"]>;
  SiteworksOthers?: Maybe<Scalars["String"]>;
  SiteworksSwimmingPool?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_RawProperties1" */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Min_Order_By = {
  AccomodationBathroom?: Maybe<Order_By>;
  AccomodationBathroomShower?: Maybe<Order_By>;
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AccomodationComment?: Maybe<Order_By>;
  AccomodationDiningRooms?: Maybe<Order_By>;
  AccomodationEntertainmentRoom?: Maybe<Order_By>;
  AccomodationEnviromentalNoise?: Maybe<Order_By>;
  AccomodationGarage?: Maybe<Order_By>;
  AccomodationKitchen?: Maybe<Order_By>;
  AccomodationLaundry?: Maybe<Order_By>;
  AccomodationOthers?: Maybe<Order_By>;
  AccomodationSecurity?: Maybe<Order_By>;
  AccomodationShops?: Maybe<Order_By>;
  AccomodationShower?: Maybe<Order_By>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Order_By>;
  AccomodationStore?: Maybe<Order_By>;
  AccomodationStudyRoom?: Maybe<Order_By>;
  AccomodationVerandah?: Maybe<Order_By>;
  AccomodationWc?: Maybe<Order_By>;
  AccomodationWcBathroom?: Maybe<Order_By>;
  AccomodationWcBathroomShower?: Maybe<Order_By>;
  AccomodationWcShower?: Maybe<Order_By>;
  AlternateEmail?: Maybe<Order_By>;
  AlternateFirstname?: Maybe<Order_By>;
  AlternateLandlineWithCode?: Maybe<Order_By>;
  AlternateMiddlename?: Maybe<Order_By>;
  AlternateMobile1?: Maybe<Order_By>;
  AlternateMobile2?: Maybe<Order_By>;
  AlternateSurname?: Maybe<Order_By>;
  AlternateTittle?: Maybe<Order_By>;
  AlternativeDrivingPermit?: Maybe<Order_By>;
  AlternativeFinancialCard?: Maybe<Order_By>;
  AlternativeNationalId?: Maybe<Order_By>;
  AlternativeNssfCard?: Maybe<Order_By>;
  AlternativePassport?: Maybe<Order_By>;
  AlternativeVillageId?: Maybe<Order_By>;
  BuildingBlockNumber?: Maybe<Order_By>;
  BuildingCondition?: Maybe<Order_By>;
  BuildingFlatNumber?: Maybe<Order_By>;
  BuildingFloorNumber?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BuildingNumber?: Maybe<Order_By>;
  BuildingOtherServices?: Maybe<Order_By>;
  BuildingParkingSlots?: Maybe<Order_By>;
  BuildingParkingSpaceAvailable?: Maybe<Order_By>;
  BuildingParkingSpaceSufficient?: Maybe<Order_By>;
  BuildingPowerSupply?: Maybe<Order_By>;
  BuildingRent?: Maybe<Order_By>;
  BuildingSanitationType?: Maybe<Order_By>;
  BuildingType?: Maybe<Order_By>;
  BuildingVerticalCirculation?: Maybe<Order_By>;
  BuildingWaterAccessibility?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  ConstructionCeiling?: Maybe<Order_By>;
  ConstructionDoorOthers?: Maybe<Order_By>;
  ConstructionDoors?: Maybe<Order_By>;
  ConstructionFloorsFinish?: Maybe<Order_By>;
  ConstructionFoorsConstruction?: Maybe<Order_By>;
  ConstructionOtherDetails?: Maybe<Order_By>;
  ConstructionRoofCovering?: Maybe<Order_By>;
  ConstructionWallFinish?: Maybe<Order_By>;
  ConstructionWalls?: Maybe<Order_By>;
  ConstructionWindowOthers?: Maybe<Order_By>;
  ConstructionWindows?: Maybe<Order_By>;
  ContactAddress?: Maybe<Order_By>;
  ContactCountry?: Maybe<Order_By>;
  ContactCountyMunicipality?: Maybe<Order_By>;
  ContactDistrict?: Maybe<Order_By>;
  ContactEmail?: Maybe<Order_By>;
  ContactLandline?: Maybe<Order_By>;
  ContactMobile1?: Maybe<Order_By>;
  ContactMobile2?: Maybe<Order_By>;
  ContactParishWard?: Maybe<Order_By>;
  ContactSubcountryDivision?: Maybe<Order_By>;
  ContactVillageCellZone?: Maybe<Order_By>;
  CordinateX?: Maybe<Order_By>;
  CordinateY?: Maybe<Order_By>;
  DataDate?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Grade?: Maybe<Order_By>;
  Gv?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationDrivingPermit?: Maybe<Order_By>;
  IdentificationMaritalCertificate?: Maybe<Order_By>;
  IdentificationNationalId?: Maybe<Order_By>;
  IdentificationNssfCard?: Maybe<Order_By>;
  IdentificationPassport?: Maybe<Order_By>;
  IdentificationVillageId?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NameContact?: Maybe<Order_By>;
  OtherBoundaryFencespecify?: Maybe<Order_By>;
  OwnerCoin?: Maybe<Order_By>;
  OwnerFirstname?: Maybe<Order_By>;
  OwnerIndividualCompany?: Maybe<Order_By>;
  OwnerLegalSurnameMaiden?: Maybe<Order_By>;
  OwnerMiddlename?: Maybe<Order_By>;
  OwnerOthernames?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  ParticularsAccess?: Maybe<Order_By>;
  ParticularsBlockName?: Maybe<Order_By>;
  ParticularsBlockNumber?: Maybe<Order_By>;
  ParticularsFolioNumber?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  ParticularsGpsEastingsUtm?: Maybe<Order_By>;
  ParticularsGpsNorthingsUtm?: Maybe<Order_By>;
  ParticularsHouseNumber?: Maybe<Order_By>;
  ParticularsIsTitled?: Maybe<Order_By>;
  ParticularsLandReference?: Maybe<Order_By>;
  ParticularsNeighborhood?: Maybe<Order_By>;
  ParticularsPlotNumber?: Maybe<Order_By>;
  ParticularsPlotShape?: Maybe<Order_By>;
  ParticularsPropertyName?: Maybe<Order_By>;
  ParticularsRoadStreet?: Maybe<Order_By>;
  ParticularsTopographyOthers?: Maybe<Order_By>;
  ParticularsTopograpy?: Maybe<Order_By>;
  ParticularsTypeOfLandInterest?: Maybe<Order_By>;
  ParticularsVolume?: Maybe<Order_By>;
  PropertyOwnerOccupiedOrRented?: Maybe<Order_By>;
  PropertySubType?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  Pvillage?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  ReferenceNumberNew?: Maybe<Order_By>;
  RoadsOtherspecify?: Maybe<Order_By>;
  Rv?: Maybe<Order_By>;
  SiteworksBoundaryFence?: Maybe<Order_By>;
  SiteworksBuildingNumber?: Maybe<Order_By>;
  SiteworksCompound?: Maybe<Order_By>;
  SiteworksCourt?: Maybe<Order_By>;
  SiteworksGate?: Maybe<Order_By>;
  SiteworksOtherFacilities?: Maybe<Order_By>;
  SiteworksOthers?: Maybe<Order_By>;
  SiteworksSwimmingPool?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_RawProperties1" */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_RawProperties1_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_RawProperties1>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_RawProperties1" */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_RawProperties1_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_RawProperties1" */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Order_By = {
  AccomodationBathroom?: Maybe<Order_By>;
  AccomodationBathroomShower?: Maybe<Order_By>;
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AccomodationComment?: Maybe<Order_By>;
  AccomodationDiningRooms?: Maybe<Order_By>;
  AccomodationEntertainmentRoom?: Maybe<Order_By>;
  AccomodationEnviromentalNoise?: Maybe<Order_By>;
  AccomodationGarage?: Maybe<Order_By>;
  AccomodationKitchen?: Maybe<Order_By>;
  AccomodationLaundry?: Maybe<Order_By>;
  AccomodationOthers?: Maybe<Order_By>;
  AccomodationSecurity?: Maybe<Order_By>;
  AccomodationShops?: Maybe<Order_By>;
  AccomodationShower?: Maybe<Order_By>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Order_By>;
  AccomodationStore?: Maybe<Order_By>;
  AccomodationStudyRoom?: Maybe<Order_By>;
  AccomodationVerandah?: Maybe<Order_By>;
  AccomodationWc?: Maybe<Order_By>;
  AccomodationWcBathroom?: Maybe<Order_By>;
  AccomodationWcBathroomShower?: Maybe<Order_By>;
  AccomodationWcShower?: Maybe<Order_By>;
  AlternateEmail?: Maybe<Order_By>;
  AlternateFirstname?: Maybe<Order_By>;
  AlternateLandlineWithCode?: Maybe<Order_By>;
  AlternateMiddlename?: Maybe<Order_By>;
  AlternateMobile1?: Maybe<Order_By>;
  AlternateMobile2?: Maybe<Order_By>;
  AlternateSurname?: Maybe<Order_By>;
  AlternateTittle?: Maybe<Order_By>;
  AlternativeDrivingPermit?: Maybe<Order_By>;
  AlternativeFinancialCard?: Maybe<Order_By>;
  AlternativeNationalId?: Maybe<Order_By>;
  AlternativeNssfCard?: Maybe<Order_By>;
  AlternativePassport?: Maybe<Order_By>;
  AlternativeVillageId?: Maybe<Order_By>;
  BuildingBlockNumber?: Maybe<Order_By>;
  BuildingCondition?: Maybe<Order_By>;
  BuildingFlatNumber?: Maybe<Order_By>;
  BuildingFloorNumber?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BuildingNumber?: Maybe<Order_By>;
  BuildingOtherServices?: Maybe<Order_By>;
  BuildingParkingSlots?: Maybe<Order_By>;
  BuildingParkingSpaceAvailable?: Maybe<Order_By>;
  BuildingParkingSpaceSufficient?: Maybe<Order_By>;
  BuildingPowerSupply?: Maybe<Order_By>;
  BuildingRent?: Maybe<Order_By>;
  BuildingSanitationType?: Maybe<Order_By>;
  BuildingType?: Maybe<Order_By>;
  BuildingVerticalCirculation?: Maybe<Order_By>;
  BuildingWaterAccessibility?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  ConstructionCeiling?: Maybe<Order_By>;
  ConstructionDoorOthers?: Maybe<Order_By>;
  ConstructionDoors?: Maybe<Order_By>;
  ConstructionFloorsFinish?: Maybe<Order_By>;
  ConstructionFoorsConstruction?: Maybe<Order_By>;
  ConstructionOtherDetails?: Maybe<Order_By>;
  ConstructionRoofCovering?: Maybe<Order_By>;
  ConstructionWallFinish?: Maybe<Order_By>;
  ConstructionWalls?: Maybe<Order_By>;
  ConstructionWindowOthers?: Maybe<Order_By>;
  ConstructionWindows?: Maybe<Order_By>;
  ContactAddress?: Maybe<Order_By>;
  ContactCountry?: Maybe<Order_By>;
  ContactCountyMunicipality?: Maybe<Order_By>;
  ContactDistrict?: Maybe<Order_By>;
  ContactEmail?: Maybe<Order_By>;
  ContactLandline?: Maybe<Order_By>;
  ContactMobile1?: Maybe<Order_By>;
  ContactMobile2?: Maybe<Order_By>;
  ContactParishWard?: Maybe<Order_By>;
  ContactSubcountryDivision?: Maybe<Order_By>;
  ContactVillageCellZone?: Maybe<Order_By>;
  CordinateX?: Maybe<Order_By>;
  CordinateY?: Maybe<Order_By>;
  DataDate?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Grade?: Maybe<Order_By>;
  Gv?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationDrivingPermit?: Maybe<Order_By>;
  IdentificationMaritalCertificate?: Maybe<Order_By>;
  IdentificationNationalId?: Maybe<Order_By>;
  IdentificationNssfCard?: Maybe<Order_By>;
  IdentificationPassport?: Maybe<Order_By>;
  IdentificationVillageId?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NameContact?: Maybe<Order_By>;
  OtherBoundaryFencespecify?: Maybe<Order_By>;
  OwnerCoin?: Maybe<Order_By>;
  OwnerFirstname?: Maybe<Order_By>;
  OwnerIndividualCompany?: Maybe<Order_By>;
  OwnerLegalSurnameMaiden?: Maybe<Order_By>;
  OwnerMiddlename?: Maybe<Order_By>;
  OwnerOthernames?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  ParticularsAccess?: Maybe<Order_By>;
  ParticularsBlockName?: Maybe<Order_By>;
  ParticularsBlockNumber?: Maybe<Order_By>;
  ParticularsFolioNumber?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  ParticularsGpsEastingsUtm?: Maybe<Order_By>;
  ParticularsGpsNorthingsUtm?: Maybe<Order_By>;
  ParticularsHouseNumber?: Maybe<Order_By>;
  ParticularsIsTitled?: Maybe<Order_By>;
  ParticularsLandReference?: Maybe<Order_By>;
  ParticularsNeighborhood?: Maybe<Order_By>;
  ParticularsPlotNumber?: Maybe<Order_By>;
  ParticularsPlotShape?: Maybe<Order_By>;
  ParticularsPropertyName?: Maybe<Order_By>;
  ParticularsRoadStreet?: Maybe<Order_By>;
  ParticularsTopographyOthers?: Maybe<Order_By>;
  ParticularsTopograpy?: Maybe<Order_By>;
  ParticularsTypeOfLandInterest?: Maybe<Order_By>;
  ParticularsVolume?: Maybe<Order_By>;
  PropertyOwnerOccupiedOrRented?: Maybe<Order_By>;
  PropertySubType?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  Pvillage?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  ReferenceNumberNew?: Maybe<Order_By>;
  RoadsOtherspecify?: Maybe<Order_By>;
  Rv?: Maybe<Order_By>;
  SiteworksBoundaryFence?: Maybe<Order_By>;
  SiteworksBuildingNumber?: Maybe<Order_By>;
  SiteworksCompound?: Maybe<Order_By>;
  SiteworksCourt?: Maybe<Order_By>;
  SiteworksGate?: Maybe<Order_By>;
  SiteworksOtherFacilities?: Maybe<Order_By>;
  SiteworksOthers?: Maybe<Order_By>;
  SiteworksSwimmingPool?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_RawProperties1" */
export enum Rtcs_Db_Ph_Kcca_RawProperties1_Select_Column {
  /** column name */
  AccomodationBathroom = "AccomodationBathroom",
  /** column name */
  AccomodationBathroomShower = "AccomodationBathroomShower",
  /** column name */
  AccomodationBedrooms = "AccomodationBedrooms",
  /** column name */
  AccomodationBuiltUpArea = "AccomodationBuiltUpArea",
  /** column name */
  AccomodationComment = "AccomodationComment",
  /** column name */
  AccomodationDiningRooms = "AccomodationDiningRooms",
  /** column name */
  AccomodationEntertainmentRoom = "AccomodationEntertainmentRoom",
  /** column name */
  AccomodationEnviromentalNoise = "AccomodationEnviromentalNoise",
  /** column name */
  AccomodationGarage = "AccomodationGarage",
  /** column name */
  AccomodationKitchen = "AccomodationKitchen",
  /** column name */
  AccomodationLaundry = "AccomodationLaundry",
  /** column name */
  AccomodationOthers = "AccomodationOthers",
  /** column name */
  AccomodationSecurity = "AccomodationSecurity",
  /** column name */
  AccomodationShops = "AccomodationShops",
  /** column name */
  AccomodationShower = "AccomodationShower",
  /** column name */
  AccomodationSittingRoomLoungeNumber = "AccomodationSittingRoomLoungeNumber",
  /** column name */
  AccomodationStore = "AccomodationStore",
  /** column name */
  AccomodationStudyRoom = "AccomodationStudyRoom",
  /** column name */
  AccomodationVerandah = "AccomodationVerandah",
  /** column name */
  AccomodationWc = "AccomodationWc",
  /** column name */
  AccomodationWcBathroom = "AccomodationWcBathroom",
  /** column name */
  AccomodationWcBathroomShower = "AccomodationWcBathroomShower",
  /** column name */
  AccomodationWcShower = "AccomodationWcShower",
  /** column name */
  AlternateEmail = "AlternateEmail",
  /** column name */
  AlternateFirstname = "AlternateFirstname",
  /** column name */
  AlternateLandlineWithCode = "AlternateLandlineWithCode",
  /** column name */
  AlternateMiddlename = "AlternateMiddlename",
  /** column name */
  AlternateMobile1 = "AlternateMobile1",
  /** column name */
  AlternateMobile2 = "AlternateMobile2",
  /** column name */
  AlternateSurname = "AlternateSurname",
  /** column name */
  AlternateTittle = "AlternateTittle",
  /** column name */
  AlternativeDrivingPermit = "AlternativeDrivingPermit",
  /** column name */
  AlternativeFinancialCard = "AlternativeFinancialCard",
  /** column name */
  AlternativeNationalId = "AlternativeNationalId",
  /** column name */
  AlternativeNssfCard = "AlternativeNssfCard",
  /** column name */
  AlternativePassport = "AlternativePassport",
  /** column name */
  AlternativeVillageId = "AlternativeVillageId",
  /** column name */
  BuildingBlockNumber = "BuildingBlockNumber",
  /** column name */
  BuildingCondition = "BuildingCondition",
  /** column name */
  BuildingFlatNumber = "BuildingFlatNumber",
  /** column name */
  BuildingFloorNumber = "BuildingFloorNumber",
  /** column name */
  BuildingName = "BuildingName",
  /** column name */
  BuildingNumber = "BuildingNumber",
  /** column name */
  BuildingOtherServices = "BuildingOtherServices",
  /** column name */
  BuildingParkingSlots = "BuildingParkingSlots",
  /** column name */
  BuildingParkingSpaceAvailable = "BuildingParkingSpaceAvailable",
  /** column name */
  BuildingParkingSpaceSufficient = "BuildingParkingSpaceSufficient",
  /** column name */
  BuildingPowerSupply = "BuildingPowerSupply",
  /** column name */
  BuildingRent = "BuildingRent",
  /** column name */
  BuildingSanitationType = "BuildingSanitationType",
  /** column name */
  BuildingType = "BuildingType",
  /** column name */
  BuildingVerticalCirculation = "BuildingVerticalCirculation",
  /** column name */
  BuildingWaterAccessibility = "BuildingWaterAccessibility",
  /** column name */
  Coin = "Coin",
  /** column name */
  ConstructionCeiling = "ConstructionCeiling",
  /** column name */
  ConstructionDoorOthers = "ConstructionDoorOthers",
  /** column name */
  ConstructionDoors = "ConstructionDoors",
  /** column name */
  ConstructionFloorsFinish = "ConstructionFloorsFinish",
  /** column name */
  ConstructionFoorsConstruction = "ConstructionFoorsConstruction",
  /** column name */
  ConstructionOtherDetails = "ConstructionOtherDetails",
  /** column name */
  ConstructionRoofCovering = "ConstructionRoofCovering",
  /** column name */
  ConstructionWallFinish = "ConstructionWallFinish",
  /** column name */
  ConstructionWalls = "ConstructionWalls",
  /** column name */
  ConstructionWindowOthers = "ConstructionWindowOthers",
  /** column name */
  ConstructionWindows = "ConstructionWindows",
  /** column name */
  ContactAddress = "ContactAddress",
  /** column name */
  ContactCountry = "ContactCountry",
  /** column name */
  ContactCountyMunicipality = "ContactCountyMunicipality",
  /** column name */
  ContactDistrict = "ContactDistrict",
  /** column name */
  ContactEmail = "ContactEmail",
  /** column name */
  ContactLandline = "ContactLandline",
  /** column name */
  ContactMobile1 = "ContactMobile1",
  /** column name */
  ContactMobile2 = "ContactMobile2",
  /** column name */
  ContactParishWard = "ContactParishWard",
  /** column name */
  ContactSubcountryDivision = "ContactSubcountryDivision",
  /** column name */
  ContactVillageCellZone = "ContactVillageCellZone",
  /** column name */
  CordinateX = "CordinateX",
  /** column name */
  CordinateY = "CordinateY",
  /** column name */
  DataDate = "DataDate",
  /** column name */
  Division = "Division",
  /** column name */
  File = "File",
  /** column name */
  Grade = "Grade",
  /** column name */
  Gv = "Gv",
  /** column name */
  Id = "Id",
  /** column name */
  IdentificationDrivingPermit = "IdentificationDrivingPermit",
  /** column name */
  IdentificationMaritalCertificate = "IdentificationMaritalCertificate",
  /** column name */
  IdentificationNationalId = "IdentificationNationalId",
  /** column name */
  IdentificationNssfCard = "IdentificationNssfCard",
  /** column name */
  IdentificationPassport = "IdentificationPassport",
  /** column name */
  IdentificationVillageId = "IdentificationVillageId",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  NameContact = "NameContact",
  /** column name */
  OtherBoundaryFencespecify = "OtherBoundaryFencespecify",
  /** column name */
  OwnerCoin = "OwnerCoin",
  /** column name */
  OwnerFirstname = "OwnerFirstname",
  /** column name */
  OwnerIndividualCompany = "OwnerIndividualCompany",
  /** column name */
  OwnerLegalSurnameMaiden = "OwnerLegalSurnameMaiden",
  /** column name */
  OwnerMiddlename = "OwnerMiddlename",
  /** column name */
  OwnerOthernames = "OwnerOthernames",
  /** column name */
  OwnerTin = "OwnerTin",
  /** column name */
  OwnerTitle = "OwnerTitle",
  /** column name */
  Parish = "Parish",
  /** column name */
  ParticularsAccess = "ParticularsAccess",
  /** column name */
  ParticularsBlockName = "ParticularsBlockName",
  /** column name */
  ParticularsBlockNumber = "ParticularsBlockNumber",
  /** column name */
  ParticularsFolioNumber = "ParticularsFolioNumber",
  /** column name */
  ParticularsFrontages = "ParticularsFrontages",
  /** column name */
  ParticularsGpsEastingsUtm = "ParticularsGpsEastingsUtm",
  /** column name */
  ParticularsGpsNorthingsUtm = "ParticularsGpsNorthingsUtm",
  /** column name */
  ParticularsHouseNumber = "ParticularsHouseNumber",
  /** column name */
  ParticularsIsTitled = "ParticularsIsTitled",
  /** column name */
  ParticularsLandReference = "ParticularsLandReference",
  /** column name */
  ParticularsNeighborhood = "ParticularsNeighborhood",
  /** column name */
  ParticularsPlotNumber = "ParticularsPlotNumber",
  /** column name */
  ParticularsPlotShape = "ParticularsPlotShape",
  /** column name */
  ParticularsPropertyName = "ParticularsPropertyName",
  /** column name */
  ParticularsRoadStreet = "ParticularsRoadStreet",
  /** column name */
  ParticularsTopographyOthers = "ParticularsTopographyOthers",
  /** column name */
  ParticularsTopograpy = "ParticularsTopograpy",
  /** column name */
  ParticularsTypeOfLandInterest = "ParticularsTypeOfLandInterest",
  /** column name */
  ParticularsVolume = "ParticularsVolume",
  /** column name */
  PropertyOwnerOccupiedOrRented = "PropertyOwnerOccupiedOrRented",
  /** column name */
  PropertySubType = "PropertySubType",
  /** column name */
  PropertyType = "PropertyType",
  /** column name */
  Pvillage = "Pvillage",
  /** column name */
  Reference = "Reference",
  /** column name */
  ReferenceNumberNew = "ReferenceNumberNew",
  /** column name */
  RoadsOtherspecify = "RoadsOtherspecify",
  /** column name */
  Rv = "Rv",
  /** column name */
  SiteworksBoundaryFence = "SiteworksBoundaryFence",
  /** column name */
  SiteworksBuildingNumber = "SiteworksBuildingNumber",
  /** column name */
  SiteworksCompound = "SiteworksCompound",
  /** column name */
  SiteworksCourt = "SiteworksCourt",
  /** column name */
  SiteworksGate = "SiteworksGate",
  /** column name */
  SiteworksOtherFacilities = "SiteworksOtherFacilities",
  /** column name */
  SiteworksOthers = "SiteworksOthers",
  /** column name */
  SiteworksSwimmingPool = "SiteworksSwimmingPool",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_RawProperties1" */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Set_Input = {
  AccomodationBathroom?: Maybe<Scalars["float8"]>;
  AccomodationBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["String"]>;
  AccomodationComment?: Maybe<Scalars["String"]>;
  AccomodationDiningRooms?: Maybe<Scalars["float8"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["float8"]>;
  AccomodationEnviromentalNoise?: Maybe<Scalars["String"]>;
  AccomodationGarage?: Maybe<Scalars["float8"]>;
  AccomodationKitchen?: Maybe<Scalars["float8"]>;
  AccomodationLaundry?: Maybe<Scalars["float8"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  AccomodationSecurity?: Maybe<Scalars["String"]>;
  AccomodationShops?: Maybe<Scalars["float8"]>;
  AccomodationShower?: Maybe<Scalars["float8"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["float8"]>;
  AccomodationStore?: Maybe<Scalars["float8"]>;
  AccomodationStudyRoom?: Maybe<Scalars["float8"]>;
  AccomodationVerandah?: Maybe<Scalars["float8"]>;
  AccomodationWc?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroom?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationWcShower?: Maybe<Scalars["float8"]>;
  AlternateEmail?: Maybe<Scalars["String"]>;
  AlternateFirstname?: Maybe<Scalars["String"]>;
  AlternateLandlineWithCode?: Maybe<Scalars["String"]>;
  AlternateMiddlename?: Maybe<Scalars["String"]>;
  AlternateMobile1?: Maybe<Scalars["String"]>;
  AlternateMobile2?: Maybe<Scalars["float8"]>;
  AlternateSurname?: Maybe<Scalars["String"]>;
  AlternateTittle?: Maybe<Scalars["String"]>;
  AlternativeDrivingPermit?: Maybe<Scalars["String"]>;
  AlternativeFinancialCard?: Maybe<Scalars["String"]>;
  AlternativeNationalId?: Maybe<Scalars["String"]>;
  AlternativeNssfCard?: Maybe<Scalars["float8"]>;
  AlternativePassport?: Maybe<Scalars["String"]>;
  AlternativeVillageId?: Maybe<Scalars["String"]>;
  BuildingBlockNumber?: Maybe<Scalars["String"]>;
  BuildingCondition?: Maybe<Scalars["String"]>;
  BuildingFlatNumber?: Maybe<Scalars["String"]>;
  BuildingFloorNumber?: Maybe<Scalars["float8"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BuildingNumber?: Maybe<Scalars["String"]>;
  BuildingOtherServices?: Maybe<Scalars["String"]>;
  BuildingParkingSlots?: Maybe<Scalars["String"]>;
  BuildingParkingSpaceAvailable?: Maybe<Scalars["String"]>;
  BuildingParkingSpaceSufficient?: Maybe<Scalars["String"]>;
  BuildingPowerSupply?: Maybe<Scalars["String"]>;
  BuildingRent?: Maybe<Scalars["String"]>;
  BuildingSanitationType?: Maybe<Scalars["String"]>;
  BuildingType?: Maybe<Scalars["String"]>;
  BuildingVerticalCirculation?: Maybe<Scalars["String"]>;
  BuildingWaterAccessibility?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  ConstructionCeiling?: Maybe<Scalars["String"]>;
  ConstructionDoorOthers?: Maybe<Scalars["String"]>;
  ConstructionDoors?: Maybe<Scalars["String"]>;
  ConstructionFloorsFinish?: Maybe<Scalars["String"]>;
  ConstructionFoorsConstruction?: Maybe<Scalars["String"]>;
  ConstructionOtherDetails?: Maybe<Scalars["String"]>;
  ConstructionRoofCovering?: Maybe<Scalars["String"]>;
  ConstructionWallFinish?: Maybe<Scalars["String"]>;
  ConstructionWalls?: Maybe<Scalars["String"]>;
  ConstructionWindowOthers?: Maybe<Scalars["String"]>;
  ConstructionWindows?: Maybe<Scalars["String"]>;
  ContactAddress?: Maybe<Scalars["String"]>;
  ContactCountry?: Maybe<Scalars["String"]>;
  ContactCountyMunicipality?: Maybe<Scalars["String"]>;
  ContactDistrict?: Maybe<Scalars["String"]>;
  ContactEmail?: Maybe<Scalars["String"]>;
  ContactLandline?: Maybe<Scalars["String"]>;
  ContactMobile1?: Maybe<Scalars["String"]>;
  ContactMobile2?: Maybe<Scalars["String"]>;
  ContactParishWard?: Maybe<Scalars["String"]>;
  ContactSubcountryDivision?: Maybe<Scalars["String"]>;
  ContactVillageCellZone?: Maybe<Scalars["String"]>;
  CordinateX?: Maybe<Scalars["String"]>;
  CordinateY?: Maybe<Scalars["String"]>;
  DataDate?: Maybe<Scalars["timestamptz"]>;
  Division?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Grade?: Maybe<Scalars["float8"]>;
  Gv?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationMaritalCertificate?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfCard?: Maybe<Scalars["float8"]>;
  IdentificationPassport?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  NameContact?: Maybe<Scalars["String"]>;
  OtherBoundaryFencespecify?: Maybe<Scalars["String"]>;
  OwnerCoin?: Maybe<Scalars["String"]>;
  OwnerFirstname?: Maybe<Scalars["String"]>;
  OwnerIndividualCompany?: Maybe<Scalars["String"]>;
  OwnerLegalSurnameMaiden?: Maybe<Scalars["String"]>;
  OwnerMiddlename?: Maybe<Scalars["String"]>;
  OwnerOthernames?: Maybe<Scalars["String"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["bigint"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParticularsAccess?: Maybe<Scalars["String"]>;
  ParticularsBlockName?: Maybe<Scalars["String"]>;
  ParticularsBlockNumber?: Maybe<Scalars["String"]>;
  ParticularsFolioNumber?: Maybe<Scalars["String"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["float8"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["float8"]>;
  ParticularsHouseNumber?: Maybe<Scalars["String"]>;
  ParticularsIsTitled?: Maybe<Scalars["String"]>;
  ParticularsLandReference?: Maybe<Scalars["String"]>;
  ParticularsNeighborhood?: Maybe<Scalars["String"]>;
  ParticularsPlotNumber?: Maybe<Scalars["String"]>;
  ParticularsPlotShape?: Maybe<Scalars["String"]>;
  ParticularsPropertyName?: Maybe<Scalars["String"]>;
  ParticularsRoadStreet?: Maybe<Scalars["String"]>;
  ParticularsTopographyOthers?: Maybe<Scalars["String"]>;
  ParticularsTopograpy?: Maybe<Scalars["String"]>;
  ParticularsTypeOfLandInterest?: Maybe<Scalars["String"]>;
  ParticularsVolume?: Maybe<Scalars["String"]>;
  PropertyOwnerOccupiedOrRented?: Maybe<Scalars["String"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  Pvillage?: Maybe<Scalars["String"]>;
  Reference?: Maybe<Scalars["float8"]>;
  ReferenceNumberNew?: Maybe<Scalars["float8"]>;
  RoadsOtherspecify?: Maybe<Scalars["String"]>;
  Rv?: Maybe<Scalars["float8"]>;
  SiteworksBoundaryFence?: Maybe<Scalars["String"]>;
  SiteworksBuildingNumber?: Maybe<Scalars["String"]>;
  SiteworksCompound?: Maybe<Scalars["String"]>;
  SiteworksCourt?: Maybe<Scalars["String"]>;
  SiteworksGate?: Maybe<Scalars["String"]>;
  SiteworksOtherFacilities?: Maybe<Scalars["String"]>;
  SiteworksOthers?: Maybe<Scalars["String"]>;
  SiteworksSwimmingPool?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_RawProperties1_stddev_fields";
  AccomodationBathroom?: Maybe<Scalars["Float"]>;
  AccomodationBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationDiningRooms?: Maybe<Scalars["Float"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["Float"]>;
  AccomodationGarage?: Maybe<Scalars["Float"]>;
  AccomodationKitchen?: Maybe<Scalars["Float"]>;
  AccomodationLaundry?: Maybe<Scalars["Float"]>;
  AccomodationShops?: Maybe<Scalars["Float"]>;
  AccomodationShower?: Maybe<Scalars["Float"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["Float"]>;
  AccomodationStore?: Maybe<Scalars["Float"]>;
  AccomodationStudyRoom?: Maybe<Scalars["Float"]>;
  AccomodationVerandah?: Maybe<Scalars["Float"]>;
  AccomodationWc?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroom?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationWcShower?: Maybe<Scalars["Float"]>;
  AlternateMobile2?: Maybe<Scalars["Float"]>;
  AlternativeNssfCard?: Maybe<Scalars["Float"]>;
  BuildingFloorNumber?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  Grade?: Maybe<Scalars["Float"]>;
  IdentificationNssfCard?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["Float"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  ReferenceNumberNew?: Maybe<Scalars["Float"]>;
  Rv?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_RawProperties1" */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Stddev_Order_By = {
  AccomodationBathroom?: Maybe<Order_By>;
  AccomodationBathroomShower?: Maybe<Order_By>;
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationDiningRooms?: Maybe<Order_By>;
  AccomodationEntertainmentRoom?: Maybe<Order_By>;
  AccomodationGarage?: Maybe<Order_By>;
  AccomodationKitchen?: Maybe<Order_By>;
  AccomodationLaundry?: Maybe<Order_By>;
  AccomodationShops?: Maybe<Order_By>;
  AccomodationShower?: Maybe<Order_By>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Order_By>;
  AccomodationStore?: Maybe<Order_By>;
  AccomodationStudyRoom?: Maybe<Order_By>;
  AccomodationVerandah?: Maybe<Order_By>;
  AccomodationWc?: Maybe<Order_By>;
  AccomodationWcBathroom?: Maybe<Order_By>;
  AccomodationWcBathroomShower?: Maybe<Order_By>;
  AccomodationWcShower?: Maybe<Order_By>;
  AlternateMobile2?: Maybe<Order_By>;
  AlternativeNssfCard?: Maybe<Order_By>;
  BuildingFloorNumber?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  Grade?: Maybe<Order_By>;
  IdentificationNssfCard?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParticularsGpsEastingsUtm?: Maybe<Order_By>;
  ParticularsGpsNorthingsUtm?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  ReferenceNumberNew?: Maybe<Order_By>;
  Rv?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_RawProperties1_stddev_pop_fields";
  AccomodationBathroom?: Maybe<Scalars["Float"]>;
  AccomodationBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationDiningRooms?: Maybe<Scalars["Float"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["Float"]>;
  AccomodationGarage?: Maybe<Scalars["Float"]>;
  AccomodationKitchen?: Maybe<Scalars["Float"]>;
  AccomodationLaundry?: Maybe<Scalars["Float"]>;
  AccomodationShops?: Maybe<Scalars["Float"]>;
  AccomodationShower?: Maybe<Scalars["Float"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["Float"]>;
  AccomodationStore?: Maybe<Scalars["Float"]>;
  AccomodationStudyRoom?: Maybe<Scalars["Float"]>;
  AccomodationVerandah?: Maybe<Scalars["Float"]>;
  AccomodationWc?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroom?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationWcShower?: Maybe<Scalars["Float"]>;
  AlternateMobile2?: Maybe<Scalars["Float"]>;
  AlternativeNssfCard?: Maybe<Scalars["Float"]>;
  BuildingFloorNumber?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  Grade?: Maybe<Scalars["Float"]>;
  IdentificationNssfCard?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["Float"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  ReferenceNumberNew?: Maybe<Scalars["Float"]>;
  Rv?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_RawProperties1" */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Stddev_Pop_Order_By = {
  AccomodationBathroom?: Maybe<Order_By>;
  AccomodationBathroomShower?: Maybe<Order_By>;
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationDiningRooms?: Maybe<Order_By>;
  AccomodationEntertainmentRoom?: Maybe<Order_By>;
  AccomodationGarage?: Maybe<Order_By>;
  AccomodationKitchen?: Maybe<Order_By>;
  AccomodationLaundry?: Maybe<Order_By>;
  AccomodationShops?: Maybe<Order_By>;
  AccomodationShower?: Maybe<Order_By>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Order_By>;
  AccomodationStore?: Maybe<Order_By>;
  AccomodationStudyRoom?: Maybe<Order_By>;
  AccomodationVerandah?: Maybe<Order_By>;
  AccomodationWc?: Maybe<Order_By>;
  AccomodationWcBathroom?: Maybe<Order_By>;
  AccomodationWcBathroomShower?: Maybe<Order_By>;
  AccomodationWcShower?: Maybe<Order_By>;
  AlternateMobile2?: Maybe<Order_By>;
  AlternativeNssfCard?: Maybe<Order_By>;
  BuildingFloorNumber?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  Grade?: Maybe<Order_By>;
  IdentificationNssfCard?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParticularsGpsEastingsUtm?: Maybe<Order_By>;
  ParticularsGpsNorthingsUtm?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  ReferenceNumberNew?: Maybe<Order_By>;
  Rv?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_RawProperties1_stddev_samp_fields";
  AccomodationBathroom?: Maybe<Scalars["Float"]>;
  AccomodationBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationDiningRooms?: Maybe<Scalars["Float"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["Float"]>;
  AccomodationGarage?: Maybe<Scalars["Float"]>;
  AccomodationKitchen?: Maybe<Scalars["Float"]>;
  AccomodationLaundry?: Maybe<Scalars["Float"]>;
  AccomodationShops?: Maybe<Scalars["Float"]>;
  AccomodationShower?: Maybe<Scalars["Float"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["Float"]>;
  AccomodationStore?: Maybe<Scalars["Float"]>;
  AccomodationStudyRoom?: Maybe<Scalars["Float"]>;
  AccomodationVerandah?: Maybe<Scalars["Float"]>;
  AccomodationWc?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroom?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationWcShower?: Maybe<Scalars["Float"]>;
  AlternateMobile2?: Maybe<Scalars["Float"]>;
  AlternativeNssfCard?: Maybe<Scalars["Float"]>;
  BuildingFloorNumber?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  Grade?: Maybe<Scalars["Float"]>;
  IdentificationNssfCard?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["Float"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  ReferenceNumberNew?: Maybe<Scalars["Float"]>;
  Rv?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_RawProperties1" */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Stddev_Samp_Order_By = {
  AccomodationBathroom?: Maybe<Order_By>;
  AccomodationBathroomShower?: Maybe<Order_By>;
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationDiningRooms?: Maybe<Order_By>;
  AccomodationEntertainmentRoom?: Maybe<Order_By>;
  AccomodationGarage?: Maybe<Order_By>;
  AccomodationKitchen?: Maybe<Order_By>;
  AccomodationLaundry?: Maybe<Order_By>;
  AccomodationShops?: Maybe<Order_By>;
  AccomodationShower?: Maybe<Order_By>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Order_By>;
  AccomodationStore?: Maybe<Order_By>;
  AccomodationStudyRoom?: Maybe<Order_By>;
  AccomodationVerandah?: Maybe<Order_By>;
  AccomodationWc?: Maybe<Order_By>;
  AccomodationWcBathroom?: Maybe<Order_By>;
  AccomodationWcBathroomShower?: Maybe<Order_By>;
  AccomodationWcShower?: Maybe<Order_By>;
  AlternateMobile2?: Maybe<Order_By>;
  AlternativeNssfCard?: Maybe<Order_By>;
  BuildingFloorNumber?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  Grade?: Maybe<Order_By>;
  IdentificationNssfCard?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParticularsGpsEastingsUtm?: Maybe<Order_By>;
  ParticularsGpsNorthingsUtm?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  ReferenceNumberNew?: Maybe<Order_By>;
  Rv?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_RawProperties1_sum_fields";
  AccomodationBathroom?: Maybe<Scalars["float8"]>;
  AccomodationBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationDiningRooms?: Maybe<Scalars["float8"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["float8"]>;
  AccomodationGarage?: Maybe<Scalars["float8"]>;
  AccomodationKitchen?: Maybe<Scalars["float8"]>;
  AccomodationLaundry?: Maybe<Scalars["float8"]>;
  AccomodationShops?: Maybe<Scalars["float8"]>;
  AccomodationShower?: Maybe<Scalars["float8"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["float8"]>;
  AccomodationStore?: Maybe<Scalars["float8"]>;
  AccomodationStudyRoom?: Maybe<Scalars["float8"]>;
  AccomodationVerandah?: Maybe<Scalars["float8"]>;
  AccomodationWc?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroom?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationWcShower?: Maybe<Scalars["float8"]>;
  AlternateMobile2?: Maybe<Scalars["float8"]>;
  AlternativeNssfCard?: Maybe<Scalars["float8"]>;
  BuildingFloorNumber?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  Grade?: Maybe<Scalars["float8"]>;
  IdentificationNssfCard?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["bigint"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["float8"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["float8"]>;
  Reference?: Maybe<Scalars["float8"]>;
  ReferenceNumberNew?: Maybe<Scalars["float8"]>;
  Rv?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_RawProperties1" */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Sum_Order_By = {
  AccomodationBathroom?: Maybe<Order_By>;
  AccomodationBathroomShower?: Maybe<Order_By>;
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationDiningRooms?: Maybe<Order_By>;
  AccomodationEntertainmentRoom?: Maybe<Order_By>;
  AccomodationGarage?: Maybe<Order_By>;
  AccomodationKitchen?: Maybe<Order_By>;
  AccomodationLaundry?: Maybe<Order_By>;
  AccomodationShops?: Maybe<Order_By>;
  AccomodationShower?: Maybe<Order_By>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Order_By>;
  AccomodationStore?: Maybe<Order_By>;
  AccomodationStudyRoom?: Maybe<Order_By>;
  AccomodationVerandah?: Maybe<Order_By>;
  AccomodationWc?: Maybe<Order_By>;
  AccomodationWcBathroom?: Maybe<Order_By>;
  AccomodationWcBathroomShower?: Maybe<Order_By>;
  AccomodationWcShower?: Maybe<Order_By>;
  AlternateMobile2?: Maybe<Order_By>;
  AlternativeNssfCard?: Maybe<Order_By>;
  BuildingFloorNumber?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  Grade?: Maybe<Order_By>;
  IdentificationNssfCard?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParticularsGpsEastingsUtm?: Maybe<Order_By>;
  ParticularsGpsNorthingsUtm?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  ReferenceNumberNew?: Maybe<Order_By>;
  Rv?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_RawProperties1_var_pop_fields";
  AccomodationBathroom?: Maybe<Scalars["Float"]>;
  AccomodationBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationDiningRooms?: Maybe<Scalars["Float"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["Float"]>;
  AccomodationGarage?: Maybe<Scalars["Float"]>;
  AccomodationKitchen?: Maybe<Scalars["Float"]>;
  AccomodationLaundry?: Maybe<Scalars["Float"]>;
  AccomodationShops?: Maybe<Scalars["Float"]>;
  AccomodationShower?: Maybe<Scalars["Float"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["Float"]>;
  AccomodationStore?: Maybe<Scalars["Float"]>;
  AccomodationStudyRoom?: Maybe<Scalars["Float"]>;
  AccomodationVerandah?: Maybe<Scalars["Float"]>;
  AccomodationWc?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroom?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationWcShower?: Maybe<Scalars["Float"]>;
  AlternateMobile2?: Maybe<Scalars["Float"]>;
  AlternativeNssfCard?: Maybe<Scalars["Float"]>;
  BuildingFloorNumber?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  Grade?: Maybe<Scalars["Float"]>;
  IdentificationNssfCard?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["Float"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  ReferenceNumberNew?: Maybe<Scalars["Float"]>;
  Rv?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_RawProperties1" */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Var_Pop_Order_By = {
  AccomodationBathroom?: Maybe<Order_By>;
  AccomodationBathroomShower?: Maybe<Order_By>;
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationDiningRooms?: Maybe<Order_By>;
  AccomodationEntertainmentRoom?: Maybe<Order_By>;
  AccomodationGarage?: Maybe<Order_By>;
  AccomodationKitchen?: Maybe<Order_By>;
  AccomodationLaundry?: Maybe<Order_By>;
  AccomodationShops?: Maybe<Order_By>;
  AccomodationShower?: Maybe<Order_By>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Order_By>;
  AccomodationStore?: Maybe<Order_By>;
  AccomodationStudyRoom?: Maybe<Order_By>;
  AccomodationVerandah?: Maybe<Order_By>;
  AccomodationWc?: Maybe<Order_By>;
  AccomodationWcBathroom?: Maybe<Order_By>;
  AccomodationWcBathroomShower?: Maybe<Order_By>;
  AccomodationWcShower?: Maybe<Order_By>;
  AlternateMobile2?: Maybe<Order_By>;
  AlternativeNssfCard?: Maybe<Order_By>;
  BuildingFloorNumber?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  Grade?: Maybe<Order_By>;
  IdentificationNssfCard?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParticularsGpsEastingsUtm?: Maybe<Order_By>;
  ParticularsGpsNorthingsUtm?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  ReferenceNumberNew?: Maybe<Order_By>;
  Rv?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_RawProperties1_var_samp_fields";
  AccomodationBathroom?: Maybe<Scalars["Float"]>;
  AccomodationBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationDiningRooms?: Maybe<Scalars["Float"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["Float"]>;
  AccomodationGarage?: Maybe<Scalars["Float"]>;
  AccomodationKitchen?: Maybe<Scalars["Float"]>;
  AccomodationLaundry?: Maybe<Scalars["Float"]>;
  AccomodationShops?: Maybe<Scalars["Float"]>;
  AccomodationShower?: Maybe<Scalars["Float"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["Float"]>;
  AccomodationStore?: Maybe<Scalars["Float"]>;
  AccomodationStudyRoom?: Maybe<Scalars["Float"]>;
  AccomodationVerandah?: Maybe<Scalars["Float"]>;
  AccomodationWc?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroom?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationWcShower?: Maybe<Scalars["Float"]>;
  AlternateMobile2?: Maybe<Scalars["Float"]>;
  AlternativeNssfCard?: Maybe<Scalars["Float"]>;
  BuildingFloorNumber?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  Grade?: Maybe<Scalars["Float"]>;
  IdentificationNssfCard?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["Float"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  ReferenceNumberNew?: Maybe<Scalars["Float"]>;
  Rv?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_RawProperties1" */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Var_Samp_Order_By = {
  AccomodationBathroom?: Maybe<Order_By>;
  AccomodationBathroomShower?: Maybe<Order_By>;
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationDiningRooms?: Maybe<Order_By>;
  AccomodationEntertainmentRoom?: Maybe<Order_By>;
  AccomodationGarage?: Maybe<Order_By>;
  AccomodationKitchen?: Maybe<Order_By>;
  AccomodationLaundry?: Maybe<Order_By>;
  AccomodationShops?: Maybe<Order_By>;
  AccomodationShower?: Maybe<Order_By>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Order_By>;
  AccomodationStore?: Maybe<Order_By>;
  AccomodationStudyRoom?: Maybe<Order_By>;
  AccomodationVerandah?: Maybe<Order_By>;
  AccomodationWc?: Maybe<Order_By>;
  AccomodationWcBathroom?: Maybe<Order_By>;
  AccomodationWcBathroomShower?: Maybe<Order_By>;
  AccomodationWcShower?: Maybe<Order_By>;
  AlternateMobile2?: Maybe<Order_By>;
  AlternativeNssfCard?: Maybe<Order_By>;
  BuildingFloorNumber?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  Grade?: Maybe<Order_By>;
  IdentificationNssfCard?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParticularsGpsEastingsUtm?: Maybe<Order_By>;
  ParticularsGpsNorthingsUtm?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  ReferenceNumberNew?: Maybe<Order_By>;
  Rv?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_RawProperties1_variance_fields";
  AccomodationBathroom?: Maybe<Scalars["Float"]>;
  AccomodationBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationDiningRooms?: Maybe<Scalars["Float"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["Float"]>;
  AccomodationGarage?: Maybe<Scalars["Float"]>;
  AccomodationKitchen?: Maybe<Scalars["Float"]>;
  AccomodationLaundry?: Maybe<Scalars["Float"]>;
  AccomodationShops?: Maybe<Scalars["Float"]>;
  AccomodationShower?: Maybe<Scalars["Float"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["Float"]>;
  AccomodationStore?: Maybe<Scalars["Float"]>;
  AccomodationStudyRoom?: Maybe<Scalars["Float"]>;
  AccomodationVerandah?: Maybe<Scalars["Float"]>;
  AccomodationWc?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroom?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationWcShower?: Maybe<Scalars["Float"]>;
  AlternateMobile2?: Maybe<Scalars["Float"]>;
  AlternativeNssfCard?: Maybe<Scalars["Float"]>;
  BuildingFloorNumber?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  Grade?: Maybe<Scalars["Float"]>;
  IdentificationNssfCard?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["Float"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  ReferenceNumberNew?: Maybe<Scalars["Float"]>;
  Rv?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_RawProperties1" */
export type Rtcs_Db_Ph_Kcca_RawProperties1_Variance_Order_By = {
  AccomodationBathroom?: Maybe<Order_By>;
  AccomodationBathroomShower?: Maybe<Order_By>;
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationDiningRooms?: Maybe<Order_By>;
  AccomodationEntertainmentRoom?: Maybe<Order_By>;
  AccomodationGarage?: Maybe<Order_By>;
  AccomodationKitchen?: Maybe<Order_By>;
  AccomodationLaundry?: Maybe<Order_By>;
  AccomodationShops?: Maybe<Order_By>;
  AccomodationShower?: Maybe<Order_By>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Order_By>;
  AccomodationStore?: Maybe<Order_By>;
  AccomodationStudyRoom?: Maybe<Order_By>;
  AccomodationVerandah?: Maybe<Order_By>;
  AccomodationWc?: Maybe<Order_By>;
  AccomodationWcBathroom?: Maybe<Order_By>;
  AccomodationWcBathroomShower?: Maybe<Order_By>;
  AccomodationWcShower?: Maybe<Order_By>;
  AlternateMobile2?: Maybe<Order_By>;
  AlternativeNssfCard?: Maybe<Order_By>;
  BuildingFloorNumber?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  Grade?: Maybe<Order_By>;
  IdentificationNssfCard?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParticularsGpsEastingsUtm?: Maybe<Order_By>;
  ParticularsGpsNorthingsUtm?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  ReferenceNumberNew?: Maybe<Order_By>;
  Rv?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_RawProperties2" */
export type Rtcs_Db_Ph_Kcca_RawProperties2 = {
  __typename?: "rtcs_db_PH_kcca_RawProperties2";
  Access?: Maybe<Scalars["String"]>;
  Agent?: Maybe<Scalars["String"]>;
  Alternative?: Maybe<Scalars["String"]>;
  Alternativeemailaddress?: Maybe<Scalars["String"]>;
  Alternativefirstname?: Maybe<Scalars["String"]>;
  Alternativelandlinenumber?: Maybe<Scalars["float8"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["float8"]>;
  Alternativeothername?: Maybe<Scalars["String"]>;
  Alternativephonenumber1?: Maybe<Scalars["float8"]>;
  Alternativephonenumber1code?: Maybe<Scalars["String"]>;
  Alternativephonenumber2?: Maybe<Scalars["String"]>;
  Alternativephonenumber2code?: Maybe<Scalars["String"]>;
  Alternativesurname?: Maybe<Scalars["String"]>;
  Altphn1?: Maybe<Scalars["float8"]>;
  Altphn2?: Maybe<Scalars["float8"]>;
  Anyother?: Maybe<Scalars["String"]>;
  AreaCount?: Maybe<Scalars["String"]>;
  Authorizedcontactperson?: Maybe<Scalars["String"]>;
  Bldg2?: Maybe<Scalars["String"]>;
  Blockname?: Maybe<Scalars["String"]>;
  Blocknumber?: Maybe<Scalars["String"]>;
  Blockother?: Maybe<Scalars["String"]>;
  Boundary?: Maybe<Scalars["String"]>;
  Boundaryfenceother?: Maybe<Scalars["String"]>;
  Buildingcondition?: Maybe<Scalars["String"]>;
  Buildingname?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["bigint"]>;
  CamvSerial?: Maybe<Scalars["String"]>;
  Ceiling?: Maybe<Scalars["String"]>;
  Ceilingothers?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["String"]>;
  Coin1?: Maybe<Scalars["String"]>;
  Comment?: Maybe<Scalars["String"]>;
  Compound?: Maybe<Scalars["String"]>;
  Compoundother?: Maybe<Scalars["String"]>;
  Contactdetailsofdirector?: Maybe<Scalars["String"]>;
  Country?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  County1?: Maybe<Scalars["String"]>;
  Creationdate?: Maybe<Scalars["timestamptz"]>;
  Creator?: Maybe<Scalars["String"]>;
  Ctrycde?: Maybe<Scalars["String"]>;
  Ctrycde1?: Maybe<Scalars["String"]>;
  Ctrycde2?: Maybe<Scalars["String"]>;
  Ctrycde4?: Maybe<Scalars["String"]>;
  Datacollector?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  District1?: Maybe<Scalars["String"]>;
  Districtorcitywhereboxislocated?: Maybe<Scalars["String"]>;
  Districtorcitywhereboxislocated1?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Doors?: Maybe<Scalars["String"]>;
  Doorsothers?: Maybe<Scalars["String"]>;
  Editdate?: Maybe<Scalars["timestamptz"]>;
  Editor?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Email1?: Maybe<Scalars["String"]>;
  Email3?: Maybe<Scalars["String"]>;
  Email5?: Maybe<Scalars["String"]>;
  Entitytype?: Maybe<Scalars["String"]>;
  Environment?: Maybe<Scalars["String"]>;
  Facilities?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Firstname3?: Maybe<Scalars["String"]>;
  Flatblocknumber?: Maybe<Scalars["String"]>;
  Flatnumber?: Maybe<Scalars["String"]>;
  Floorconstruction?: Maybe<Scalars["String"]>;
  Floorconstructionothers?: Maybe<Scalars["String"]>;
  Floorfinish?: Maybe<Scalars["String"]>;
  Floorfinishothers?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Games?: Maybe<Scalars["String"]>;
  Gamesother?: Maybe<Scalars["String"]>;
  Gate?: Maybe<Scalars["String"]>;
  Gateother?: Maybe<Scalars["String"]>;
  Globalid?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Hsenbr?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Id2?: Maybe<Scalars["String"]>;
  Id3?: Maybe<Scalars["bigint"]>;
  Land4?: Maybe<Scalars["float8"]>;
  Landline?: Maybe<Scalars["String"]>;
  Landline1?: Maybe<Scalars["String"]>;
  Landline4?: Maybe<Scalars["String"]>;
  Landlinecode?: Maybe<Scalars["String"]>;
  Landlinecode4?: Maybe<Scalars["String"]>;
  Landregister?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  LatitudeA?: Maybe<Scalars["float8"]>;
  Latlong?: Maybe<Scalars["String"]>;
  Legalname?: Maybe<Scalars["String"]>;
  Levelofcompletion?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Lln?: Maybe<Scalars["String"]>;
  Lln1?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  LongitudeB?: Maybe<Scalars["float8"]>;
  Lvls?: Maybe<Scalars["String"]>;
  Mainbusinessname?: Maybe<Scalars["String"]>;
  Mob?: Maybe<Scalars["float8"]>;
  Mob1?: Maybe<Scalars["float8"]>;
  Mob3?: Maybe<Scalars["String"]>;
  Mob4?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  Mobile1?: Maybe<Scalars["String"]>;
  Mobile1code?: Maybe<Scalars["String"]>;
  Mobile3?: Maybe<Scalars["String"]>;
  Mobile3code?: Maybe<Scalars["String"]>;
  Mobile4?: Maybe<Scalars["String"]>;
  Mobile4code?: Maybe<Scalars["String"]>;
  Mobile5?: Maybe<Scalars["String"]>;
  Mobile5code?: Maybe<Scalars["String"]>;
  Mobilecode?: Maybe<Scalars["String"]>;
  Name2?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["float8"]>;
  Nationalid2?: Maybe<Scalars["float8"]>;
  Nbrofslots?: Maybe<Scalars["String"]>;
  Neighbourhoodactivities?: Maybe<Scalars["String"]>;
  Neighbourhoodstatus?: Maybe<Scalars["String"]>;
  Nin?: Maybe<Scalars["String"]>;
  Nin2?: Maybe<Scalars["String"]>;
  Objectid?: Maybe<Scalars["float8"]>;
  Other?: Maybe<Scalars["String"]>;
  Otherfacilities?: Maybe<Scalars["String"]>;
  Otherid?: Maybe<Scalars["String"]>;
  Otherid1?: Maybe<Scalars["String"]>;
  Otherid2?: Maybe<Scalars["String"]>;
  Otherid3?: Maybe<Scalars["float8"]>;
  Otherid4?: Maybe<Scalars["String"]>;
  Otherid4nbr?: Maybe<Scalars["String"]>;
  Otherid6?: Maybe<Scalars["String"]>;
  Otherid7?: Maybe<Scalars["float8"]>;
  Othernames?: Maybe<Scalars["String"]>;
  Othernames3?: Maybe<Scalars["String"]>;
  Otherservices?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Parish1?: Maybe<Scalars["String"]>;
  Parish2?: Maybe<Scalars["String"]>;
  Parking?: Maybe<Scalars["String"]>;
  Parkingsufficient?: Maybe<Scalars["String"]>;
  Partiiindividualownershipdetails?: Maybe<Scalars["String"]>;
  Partinonindividualownershipdetails?: Maybe<Scalars["String"]>;
  Pass?: Maybe<Scalars["float8"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Postalofficeboxnumber?: Maybe<Scalars["String"]>;
  Postalofficeboxnumber1?: Maybe<Scalars["String"]>;
  Powersupply?: Maybe<Scalars["String"]>;
  Propertyname?: Maybe<Scalars["String"]>;
  Propertysubtype?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Reference?: Maybe<Scalars["bigint"]>;
  Relationshiptoowner?: Maybe<Scalars["String"]>;
  Roadfrontageorgrid?: Maybe<Scalars["String"]>;
  Roadname?: Maybe<Scalars["String"]>;
  Roofcovering?: Maybe<Scalars["String"]>;
  Roofcoveringothers?: Maybe<Scalars["String"]>;
  Sanitation?: Maybe<Scalars["String"]>;
  Sectionapropertyownersdetails?: Maybe<Scalars["String"]>;
  Security?: Maybe<Scalars["String"]>;
  Shape?: Maybe<Scalars["String"]>;
  Shapeofplot?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Subcounty1?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Surname3?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Title1?: Maybe<Scalars["String"]>;
  Topography?: Maybe<Scalars["String"]>;
  TotalBuiltUpArea?: Maybe<Scalars["float8"]>;
  TotalGrossArea?: Maybe<Scalars["float8"]>;
  Typeofaccess?: Maybe<Scalars["String"]>;
  Typeofbuilding?: Maybe<Scalars["String"]>;
  Typeofcompany?: Maybe<Scalars["String"]>;
  Typeoflandinterest?: Maybe<Scalars["String"]>;
  Username?: Maybe<Scalars["float8"]>;
  Verticalcirculation?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  Village1?: Maybe<Scalars["String"]>;
  Village2?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  Wallfinish?: Maybe<Scalars["String"]>;
  Wallfinishothers?: Maybe<Scalars["String"]>;
  Walls?: Maybe<Scalars["String"]>;
  Wallsothers?: Maybe<Scalars["String"]>;
  Wateraccessibility?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Windows?: Maybe<Scalars["String"]>;
  Windowsothers?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_RawProperties2" */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_RawProperties2_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_RawProperties2>;
};

/** aggregate fields of "rtcs_db.PH_kcca_RawProperties2" */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_RawProperties2_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_RawProperties2" */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties2_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_RawProperties2" */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_RawProperties2" */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_RawProperties2_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_RawProperties2_avg_fields";
  Alternativelandlinenumber?: Maybe<Scalars["Float"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["Float"]>;
  Alternativephonenumber1?: Maybe<Scalars["Float"]>;
  Altphn1?: Maybe<Scalars["Float"]>;
  Altphn2?: Maybe<Scalars["Float"]>;
  CamvId?: Maybe<Scalars["Float"]>;
  Id3?: Maybe<Scalars["Float"]>;
  Land4?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  LatitudeA?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LongitudeB?: Maybe<Scalars["Float"]>;
  Mob?: Maybe<Scalars["Float"]>;
  Mob1?: Maybe<Scalars["Float"]>;
  Nationalid?: Maybe<Scalars["Float"]>;
  Nationalid2?: Maybe<Scalars["Float"]>;
  Objectid?: Maybe<Scalars["Float"]>;
  Otherid3?: Maybe<Scalars["Float"]>;
  Otherid7?: Maybe<Scalars["Float"]>;
  Pass?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
  TotalBuiltUpArea?: Maybe<Scalars["Float"]>;
  TotalGrossArea?: Maybe<Scalars["Float"]>;
  Username?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_RawProperties2" */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Avg_Order_By = {
  Alternativelandlinenumber?: Maybe<Order_By>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Order_By>;
  Alternativephonenumber1?: Maybe<Order_By>;
  Altphn1?: Maybe<Order_By>;
  Altphn2?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  Id3?: Maybe<Order_By>;
  Land4?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  LatitudeA?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeB?: Maybe<Order_By>;
  Mob?: Maybe<Order_By>;
  Mob1?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Nationalid2?: Maybe<Order_By>;
  Objectid?: Maybe<Order_By>;
  Otherid3?: Maybe<Order_By>;
  Otherid7?: Maybe<Order_By>;
  Pass?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TotalBuiltUpArea?: Maybe<Order_By>;
  TotalGrossArea?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_RawProperties2". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Bool_Exp = {
  Access?: Maybe<String_Comparison_Exp>;
  Agent?: Maybe<String_Comparison_Exp>;
  Alternative?: Maybe<String_Comparison_Exp>;
  Alternativeemailaddress?: Maybe<String_Comparison_Exp>;
  Alternativefirstname?: Maybe<String_Comparison_Exp>;
  Alternativelandlinenumber?: Maybe<Float8_Comparison_Exp>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Float8_Comparison_Exp>;
  Alternativeothername?: Maybe<String_Comparison_Exp>;
  Alternativephonenumber1?: Maybe<Float8_Comparison_Exp>;
  Alternativephonenumber1code?: Maybe<String_Comparison_Exp>;
  Alternativephonenumber2?: Maybe<String_Comparison_Exp>;
  Alternativephonenumber2code?: Maybe<String_Comparison_Exp>;
  Alternativesurname?: Maybe<String_Comparison_Exp>;
  Altphn1?: Maybe<Float8_Comparison_Exp>;
  Altphn2?: Maybe<Float8_Comparison_Exp>;
  Anyother?: Maybe<String_Comparison_Exp>;
  AreaCount?: Maybe<String_Comparison_Exp>;
  Authorizedcontactperson?: Maybe<String_Comparison_Exp>;
  Bldg2?: Maybe<String_Comparison_Exp>;
  Blockname?: Maybe<String_Comparison_Exp>;
  Blocknumber?: Maybe<String_Comparison_Exp>;
  Blockother?: Maybe<String_Comparison_Exp>;
  Boundary?: Maybe<String_Comparison_Exp>;
  Boundaryfenceother?: Maybe<String_Comparison_Exp>;
  Buildingcondition?: Maybe<String_Comparison_Exp>;
  Buildingname?: Maybe<String_Comparison_Exp>;
  CamvId?: Maybe<Bigint_Comparison_Exp>;
  CamvSerial?: Maybe<String_Comparison_Exp>;
  Ceiling?: Maybe<String_Comparison_Exp>;
  Ceilingothers?: Maybe<String_Comparison_Exp>;
  Coin?: Maybe<String_Comparison_Exp>;
  Coin1?: Maybe<String_Comparison_Exp>;
  Comment?: Maybe<String_Comparison_Exp>;
  Compound?: Maybe<String_Comparison_Exp>;
  Compoundother?: Maybe<String_Comparison_Exp>;
  Contactdetailsofdirector?: Maybe<String_Comparison_Exp>;
  Country?: Maybe<String_Comparison_Exp>;
  County?: Maybe<String_Comparison_Exp>;
  County1?: Maybe<String_Comparison_Exp>;
  Creationdate?: Maybe<Timestamptz_Comparison_Exp>;
  Creator?: Maybe<String_Comparison_Exp>;
  Ctrycde?: Maybe<String_Comparison_Exp>;
  Ctrycde1?: Maybe<String_Comparison_Exp>;
  Ctrycde2?: Maybe<String_Comparison_Exp>;
  Ctrycde4?: Maybe<String_Comparison_Exp>;
  Datacollector?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  District1?: Maybe<String_Comparison_Exp>;
  Districtorcitywhereboxislocated?: Maybe<String_Comparison_Exp>;
  Districtorcitywhereboxislocated1?: Maybe<String_Comparison_Exp>;
  Division?: Maybe<String_Comparison_Exp>;
  Doors?: Maybe<String_Comparison_Exp>;
  Doorsothers?: Maybe<String_Comparison_Exp>;
  Editdate?: Maybe<Timestamptz_Comparison_Exp>;
  Editor?: Maybe<String_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  Email1?: Maybe<String_Comparison_Exp>;
  Email3?: Maybe<String_Comparison_Exp>;
  Email5?: Maybe<String_Comparison_Exp>;
  Entitytype?: Maybe<String_Comparison_Exp>;
  Environment?: Maybe<String_Comparison_Exp>;
  Facilities?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Firstname3?: Maybe<String_Comparison_Exp>;
  Flatblocknumber?: Maybe<String_Comparison_Exp>;
  Flatnumber?: Maybe<String_Comparison_Exp>;
  Floorconstruction?: Maybe<String_Comparison_Exp>;
  Floorconstructionothers?: Maybe<String_Comparison_Exp>;
  Floorfinish?: Maybe<String_Comparison_Exp>;
  Floorfinishothers?: Maybe<String_Comparison_Exp>;
  Folio?: Maybe<String_Comparison_Exp>;
  Games?: Maybe<String_Comparison_Exp>;
  Gamesother?: Maybe<String_Comparison_Exp>;
  Gate?: Maybe<String_Comparison_Exp>;
  Gateother?: Maybe<String_Comparison_Exp>;
  Globalid?: Maybe<String_Comparison_Exp>;
  Housenumber?: Maybe<String_Comparison_Exp>;
  Hsenbr?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  Id2?: Maybe<String_Comparison_Exp>;
  Id3?: Maybe<Bigint_Comparison_Exp>;
  Land4?: Maybe<Float8_Comparison_Exp>;
  Landline?: Maybe<String_Comparison_Exp>;
  Landline1?: Maybe<String_Comparison_Exp>;
  Landline4?: Maybe<String_Comparison_Exp>;
  Landlinecode?: Maybe<String_Comparison_Exp>;
  Landlinecode4?: Maybe<String_Comparison_Exp>;
  Landregister?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  LatitudeA?: Maybe<Float8_Comparison_Exp>;
  Latlong?: Maybe<String_Comparison_Exp>;
  Legalname?: Maybe<String_Comparison_Exp>;
  Levelofcompletion?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Lln?: Maybe<String_Comparison_Exp>;
  Lln1?: Maybe<String_Comparison_Exp>;
  Longitude?: Maybe<String_Comparison_Exp>;
  LongitudeB?: Maybe<Float8_Comparison_Exp>;
  Lvls?: Maybe<String_Comparison_Exp>;
  Mainbusinessname?: Maybe<String_Comparison_Exp>;
  Mob?: Maybe<Float8_Comparison_Exp>;
  Mob1?: Maybe<Float8_Comparison_Exp>;
  Mob3?: Maybe<String_Comparison_Exp>;
  Mob4?: Maybe<String_Comparison_Exp>;
  Mobile?: Maybe<String_Comparison_Exp>;
  Mobile1?: Maybe<String_Comparison_Exp>;
  Mobile1code?: Maybe<String_Comparison_Exp>;
  Mobile3?: Maybe<String_Comparison_Exp>;
  Mobile3code?: Maybe<String_Comparison_Exp>;
  Mobile4?: Maybe<String_Comparison_Exp>;
  Mobile4code?: Maybe<String_Comparison_Exp>;
  Mobile5?: Maybe<String_Comparison_Exp>;
  Mobile5code?: Maybe<String_Comparison_Exp>;
  Mobilecode?: Maybe<String_Comparison_Exp>;
  Name2?: Maybe<String_Comparison_Exp>;
  Nationalid?: Maybe<Float8_Comparison_Exp>;
  Nationalid2?: Maybe<Float8_Comparison_Exp>;
  Nbrofslots?: Maybe<String_Comparison_Exp>;
  Neighbourhoodactivities?: Maybe<String_Comparison_Exp>;
  Neighbourhoodstatus?: Maybe<String_Comparison_Exp>;
  Nin?: Maybe<String_Comparison_Exp>;
  Nin2?: Maybe<String_Comparison_Exp>;
  Objectid?: Maybe<Float8_Comparison_Exp>;
  Other?: Maybe<String_Comparison_Exp>;
  Otherfacilities?: Maybe<String_Comparison_Exp>;
  Otherid?: Maybe<String_Comparison_Exp>;
  Otherid1?: Maybe<String_Comparison_Exp>;
  Otherid2?: Maybe<String_Comparison_Exp>;
  Otherid3?: Maybe<Float8_Comparison_Exp>;
  Otherid4?: Maybe<String_Comparison_Exp>;
  Otherid4nbr?: Maybe<String_Comparison_Exp>;
  Otherid6?: Maybe<String_Comparison_Exp>;
  Otherid7?: Maybe<Float8_Comparison_Exp>;
  Othernames?: Maybe<String_Comparison_Exp>;
  Othernames3?: Maybe<String_Comparison_Exp>;
  Otherservices?: Maybe<String_Comparison_Exp>;
  Parish?: Maybe<String_Comparison_Exp>;
  Parish1?: Maybe<String_Comparison_Exp>;
  Parish2?: Maybe<String_Comparison_Exp>;
  Parking?: Maybe<String_Comparison_Exp>;
  Parkingsufficient?: Maybe<String_Comparison_Exp>;
  Partiiindividualownershipdetails?: Maybe<String_Comparison_Exp>;
  Partinonindividualownershipdetails?: Maybe<String_Comparison_Exp>;
  Pass?: Maybe<Float8_Comparison_Exp>;
  Plotnumber?: Maybe<String_Comparison_Exp>;
  Postalofficeboxnumber?: Maybe<String_Comparison_Exp>;
  Postalofficeboxnumber1?: Maybe<String_Comparison_Exp>;
  Powersupply?: Maybe<String_Comparison_Exp>;
  Propertyname?: Maybe<String_Comparison_Exp>;
  Propertysubtype?: Maybe<String_Comparison_Exp>;
  Propertytype?: Maybe<String_Comparison_Exp>;
  Reference?: Maybe<Bigint_Comparison_Exp>;
  Relationshiptoowner?: Maybe<String_Comparison_Exp>;
  Roadfrontageorgrid?: Maybe<String_Comparison_Exp>;
  Roadname?: Maybe<String_Comparison_Exp>;
  Roofcovering?: Maybe<String_Comparison_Exp>;
  Roofcoveringothers?: Maybe<String_Comparison_Exp>;
  Sanitation?: Maybe<String_Comparison_Exp>;
  Sectionapropertyownersdetails?: Maybe<String_Comparison_Exp>;
  Security?: Maybe<String_Comparison_Exp>;
  Shape?: Maybe<String_Comparison_Exp>;
  Shapeofplot?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  Subcounty?: Maybe<String_Comparison_Exp>;
  Subcounty1?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Surname3?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<Float8_Comparison_Exp>;
  Title?: Maybe<String_Comparison_Exp>;
  Title1?: Maybe<String_Comparison_Exp>;
  Topography?: Maybe<String_Comparison_Exp>;
  TotalBuiltUpArea?: Maybe<Float8_Comparison_Exp>;
  TotalGrossArea?: Maybe<Float8_Comparison_Exp>;
  Typeofaccess?: Maybe<String_Comparison_Exp>;
  Typeofbuilding?: Maybe<String_Comparison_Exp>;
  Typeofcompany?: Maybe<String_Comparison_Exp>;
  Typeoflandinterest?: Maybe<String_Comparison_Exp>;
  Username?: Maybe<Float8_Comparison_Exp>;
  Verticalcirculation?: Maybe<String_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  Village1?: Maybe<String_Comparison_Exp>;
  Village2?: Maybe<String_Comparison_Exp>;
  Volume?: Maybe<String_Comparison_Exp>;
  Wallfinish?: Maybe<String_Comparison_Exp>;
  Wallfinishothers?: Maybe<String_Comparison_Exp>;
  Walls?: Maybe<String_Comparison_Exp>;
  Wallsothers?: Maybe<String_Comparison_Exp>;
  Wateraccessibility?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  Windows?: Maybe<String_Comparison_Exp>;
  Windowsothers?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_RawProperties2" */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Inc_Input = {
  Alternativelandlinenumber?: Maybe<Scalars["float8"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["float8"]>;
  Alternativephonenumber1?: Maybe<Scalars["float8"]>;
  Altphn1?: Maybe<Scalars["float8"]>;
  Altphn2?: Maybe<Scalars["float8"]>;
  CamvId?: Maybe<Scalars["bigint"]>;
  Id3?: Maybe<Scalars["bigint"]>;
  Land4?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  LatitudeA?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LongitudeB?: Maybe<Scalars["float8"]>;
  Mob?: Maybe<Scalars["float8"]>;
  Mob1?: Maybe<Scalars["float8"]>;
  Nationalid?: Maybe<Scalars["float8"]>;
  Nationalid2?: Maybe<Scalars["float8"]>;
  Objectid?: Maybe<Scalars["float8"]>;
  Otherid3?: Maybe<Scalars["float8"]>;
  Otherid7?: Maybe<Scalars["float8"]>;
  Pass?: Maybe<Scalars["float8"]>;
  Reference?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Tin?: Maybe<Scalars["float8"]>;
  TotalBuiltUpArea?: Maybe<Scalars["float8"]>;
  TotalGrossArea?: Maybe<Scalars["float8"]>;
  Username?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_RawProperties2" */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Insert_Input = {
  Access?: Maybe<Scalars["String"]>;
  Agent?: Maybe<Scalars["String"]>;
  Alternative?: Maybe<Scalars["String"]>;
  Alternativeemailaddress?: Maybe<Scalars["String"]>;
  Alternativefirstname?: Maybe<Scalars["String"]>;
  Alternativelandlinenumber?: Maybe<Scalars["float8"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["float8"]>;
  Alternativeothername?: Maybe<Scalars["String"]>;
  Alternativephonenumber1?: Maybe<Scalars["float8"]>;
  Alternativephonenumber1code?: Maybe<Scalars["String"]>;
  Alternativephonenumber2?: Maybe<Scalars["String"]>;
  Alternativephonenumber2code?: Maybe<Scalars["String"]>;
  Alternativesurname?: Maybe<Scalars["String"]>;
  Altphn1?: Maybe<Scalars["float8"]>;
  Altphn2?: Maybe<Scalars["float8"]>;
  Anyother?: Maybe<Scalars["String"]>;
  AreaCount?: Maybe<Scalars["String"]>;
  Authorizedcontactperson?: Maybe<Scalars["String"]>;
  Bldg2?: Maybe<Scalars["String"]>;
  Blockname?: Maybe<Scalars["String"]>;
  Blocknumber?: Maybe<Scalars["String"]>;
  Blockother?: Maybe<Scalars["String"]>;
  Boundary?: Maybe<Scalars["String"]>;
  Boundaryfenceother?: Maybe<Scalars["String"]>;
  Buildingcondition?: Maybe<Scalars["String"]>;
  Buildingname?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["bigint"]>;
  CamvSerial?: Maybe<Scalars["String"]>;
  Ceiling?: Maybe<Scalars["String"]>;
  Ceilingothers?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["String"]>;
  Coin1?: Maybe<Scalars["String"]>;
  Comment?: Maybe<Scalars["String"]>;
  Compound?: Maybe<Scalars["String"]>;
  Compoundother?: Maybe<Scalars["String"]>;
  Contactdetailsofdirector?: Maybe<Scalars["String"]>;
  Country?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  County1?: Maybe<Scalars["String"]>;
  Creationdate?: Maybe<Scalars["timestamptz"]>;
  Creator?: Maybe<Scalars["String"]>;
  Ctrycde?: Maybe<Scalars["String"]>;
  Ctrycde1?: Maybe<Scalars["String"]>;
  Ctrycde2?: Maybe<Scalars["String"]>;
  Ctrycde4?: Maybe<Scalars["String"]>;
  Datacollector?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  District1?: Maybe<Scalars["String"]>;
  Districtorcitywhereboxislocated?: Maybe<Scalars["String"]>;
  Districtorcitywhereboxislocated1?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Doors?: Maybe<Scalars["String"]>;
  Doorsothers?: Maybe<Scalars["String"]>;
  Editdate?: Maybe<Scalars["timestamptz"]>;
  Editor?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Email1?: Maybe<Scalars["String"]>;
  Email3?: Maybe<Scalars["String"]>;
  Email5?: Maybe<Scalars["String"]>;
  Entitytype?: Maybe<Scalars["String"]>;
  Environment?: Maybe<Scalars["String"]>;
  Facilities?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Firstname3?: Maybe<Scalars["String"]>;
  Flatblocknumber?: Maybe<Scalars["String"]>;
  Flatnumber?: Maybe<Scalars["String"]>;
  Floorconstruction?: Maybe<Scalars["String"]>;
  Floorconstructionothers?: Maybe<Scalars["String"]>;
  Floorfinish?: Maybe<Scalars["String"]>;
  Floorfinishothers?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Games?: Maybe<Scalars["String"]>;
  Gamesother?: Maybe<Scalars["String"]>;
  Gate?: Maybe<Scalars["String"]>;
  Gateother?: Maybe<Scalars["String"]>;
  Globalid?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Hsenbr?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Id2?: Maybe<Scalars["String"]>;
  Id3?: Maybe<Scalars["bigint"]>;
  Land4?: Maybe<Scalars["float8"]>;
  Landline?: Maybe<Scalars["String"]>;
  Landline1?: Maybe<Scalars["String"]>;
  Landline4?: Maybe<Scalars["String"]>;
  Landlinecode?: Maybe<Scalars["String"]>;
  Landlinecode4?: Maybe<Scalars["String"]>;
  Landregister?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  LatitudeA?: Maybe<Scalars["float8"]>;
  Latlong?: Maybe<Scalars["String"]>;
  Legalname?: Maybe<Scalars["String"]>;
  Levelofcompletion?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Lln?: Maybe<Scalars["String"]>;
  Lln1?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  LongitudeB?: Maybe<Scalars["float8"]>;
  Lvls?: Maybe<Scalars["String"]>;
  Mainbusinessname?: Maybe<Scalars["String"]>;
  Mob?: Maybe<Scalars["float8"]>;
  Mob1?: Maybe<Scalars["float8"]>;
  Mob3?: Maybe<Scalars["String"]>;
  Mob4?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  Mobile1?: Maybe<Scalars["String"]>;
  Mobile1code?: Maybe<Scalars["String"]>;
  Mobile3?: Maybe<Scalars["String"]>;
  Mobile3code?: Maybe<Scalars["String"]>;
  Mobile4?: Maybe<Scalars["String"]>;
  Mobile4code?: Maybe<Scalars["String"]>;
  Mobile5?: Maybe<Scalars["String"]>;
  Mobile5code?: Maybe<Scalars["String"]>;
  Mobilecode?: Maybe<Scalars["String"]>;
  Name2?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["float8"]>;
  Nationalid2?: Maybe<Scalars["float8"]>;
  Nbrofslots?: Maybe<Scalars["String"]>;
  Neighbourhoodactivities?: Maybe<Scalars["String"]>;
  Neighbourhoodstatus?: Maybe<Scalars["String"]>;
  Nin?: Maybe<Scalars["String"]>;
  Nin2?: Maybe<Scalars["String"]>;
  Objectid?: Maybe<Scalars["float8"]>;
  Other?: Maybe<Scalars["String"]>;
  Otherfacilities?: Maybe<Scalars["String"]>;
  Otherid?: Maybe<Scalars["String"]>;
  Otherid1?: Maybe<Scalars["String"]>;
  Otherid2?: Maybe<Scalars["String"]>;
  Otherid3?: Maybe<Scalars["float8"]>;
  Otherid4?: Maybe<Scalars["String"]>;
  Otherid4nbr?: Maybe<Scalars["String"]>;
  Otherid6?: Maybe<Scalars["String"]>;
  Otherid7?: Maybe<Scalars["float8"]>;
  Othernames?: Maybe<Scalars["String"]>;
  Othernames3?: Maybe<Scalars["String"]>;
  Otherservices?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Parish1?: Maybe<Scalars["String"]>;
  Parish2?: Maybe<Scalars["String"]>;
  Parking?: Maybe<Scalars["String"]>;
  Parkingsufficient?: Maybe<Scalars["String"]>;
  Partiiindividualownershipdetails?: Maybe<Scalars["String"]>;
  Partinonindividualownershipdetails?: Maybe<Scalars["String"]>;
  Pass?: Maybe<Scalars["float8"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Postalofficeboxnumber?: Maybe<Scalars["String"]>;
  Postalofficeboxnumber1?: Maybe<Scalars["String"]>;
  Powersupply?: Maybe<Scalars["String"]>;
  Propertyname?: Maybe<Scalars["String"]>;
  Propertysubtype?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Reference?: Maybe<Scalars["bigint"]>;
  Relationshiptoowner?: Maybe<Scalars["String"]>;
  Roadfrontageorgrid?: Maybe<Scalars["String"]>;
  Roadname?: Maybe<Scalars["String"]>;
  Roofcovering?: Maybe<Scalars["String"]>;
  Roofcoveringothers?: Maybe<Scalars["String"]>;
  Sanitation?: Maybe<Scalars["String"]>;
  Sectionapropertyownersdetails?: Maybe<Scalars["String"]>;
  Security?: Maybe<Scalars["String"]>;
  Shape?: Maybe<Scalars["String"]>;
  Shapeofplot?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Subcounty1?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Surname3?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Title1?: Maybe<Scalars["String"]>;
  Topography?: Maybe<Scalars["String"]>;
  TotalBuiltUpArea?: Maybe<Scalars["float8"]>;
  TotalGrossArea?: Maybe<Scalars["float8"]>;
  Typeofaccess?: Maybe<Scalars["String"]>;
  Typeofbuilding?: Maybe<Scalars["String"]>;
  Typeofcompany?: Maybe<Scalars["String"]>;
  Typeoflandinterest?: Maybe<Scalars["String"]>;
  Username?: Maybe<Scalars["float8"]>;
  Verticalcirculation?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  Village1?: Maybe<Scalars["String"]>;
  Village2?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  Wallfinish?: Maybe<Scalars["String"]>;
  Wallfinishothers?: Maybe<Scalars["String"]>;
  Walls?: Maybe<Scalars["String"]>;
  Wallsothers?: Maybe<Scalars["String"]>;
  Wateraccessibility?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Windows?: Maybe<Scalars["String"]>;
  Windowsothers?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_RawProperties2_max_fields";
  Access?: Maybe<Scalars["String"]>;
  Agent?: Maybe<Scalars["String"]>;
  Alternative?: Maybe<Scalars["String"]>;
  Alternativeemailaddress?: Maybe<Scalars["String"]>;
  Alternativefirstname?: Maybe<Scalars["String"]>;
  Alternativelandlinenumber?: Maybe<Scalars["float8"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["float8"]>;
  Alternativeothername?: Maybe<Scalars["String"]>;
  Alternativephonenumber1?: Maybe<Scalars["float8"]>;
  Alternativephonenumber1code?: Maybe<Scalars["String"]>;
  Alternativephonenumber2?: Maybe<Scalars["String"]>;
  Alternativephonenumber2code?: Maybe<Scalars["String"]>;
  Alternativesurname?: Maybe<Scalars["String"]>;
  Altphn1?: Maybe<Scalars["float8"]>;
  Altphn2?: Maybe<Scalars["float8"]>;
  Anyother?: Maybe<Scalars["String"]>;
  AreaCount?: Maybe<Scalars["String"]>;
  Authorizedcontactperson?: Maybe<Scalars["String"]>;
  Bldg2?: Maybe<Scalars["String"]>;
  Blockname?: Maybe<Scalars["String"]>;
  Blocknumber?: Maybe<Scalars["String"]>;
  Blockother?: Maybe<Scalars["String"]>;
  Boundary?: Maybe<Scalars["String"]>;
  Boundaryfenceother?: Maybe<Scalars["String"]>;
  Buildingcondition?: Maybe<Scalars["String"]>;
  Buildingname?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["bigint"]>;
  CamvSerial?: Maybe<Scalars["String"]>;
  Ceiling?: Maybe<Scalars["String"]>;
  Ceilingothers?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["String"]>;
  Coin1?: Maybe<Scalars["String"]>;
  Comment?: Maybe<Scalars["String"]>;
  Compound?: Maybe<Scalars["String"]>;
  Compoundother?: Maybe<Scalars["String"]>;
  Contactdetailsofdirector?: Maybe<Scalars["String"]>;
  Country?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  County1?: Maybe<Scalars["String"]>;
  Creationdate?: Maybe<Scalars["timestamptz"]>;
  Creator?: Maybe<Scalars["String"]>;
  Ctrycde?: Maybe<Scalars["String"]>;
  Ctrycde1?: Maybe<Scalars["String"]>;
  Ctrycde2?: Maybe<Scalars["String"]>;
  Ctrycde4?: Maybe<Scalars["String"]>;
  Datacollector?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  District1?: Maybe<Scalars["String"]>;
  Districtorcitywhereboxislocated?: Maybe<Scalars["String"]>;
  Districtorcitywhereboxislocated1?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Doors?: Maybe<Scalars["String"]>;
  Doorsothers?: Maybe<Scalars["String"]>;
  Editdate?: Maybe<Scalars["timestamptz"]>;
  Editor?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Email1?: Maybe<Scalars["String"]>;
  Email3?: Maybe<Scalars["String"]>;
  Email5?: Maybe<Scalars["String"]>;
  Entitytype?: Maybe<Scalars["String"]>;
  Environment?: Maybe<Scalars["String"]>;
  Facilities?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Firstname3?: Maybe<Scalars["String"]>;
  Flatblocknumber?: Maybe<Scalars["String"]>;
  Flatnumber?: Maybe<Scalars["String"]>;
  Floorconstruction?: Maybe<Scalars["String"]>;
  Floorconstructionothers?: Maybe<Scalars["String"]>;
  Floorfinish?: Maybe<Scalars["String"]>;
  Floorfinishothers?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Games?: Maybe<Scalars["String"]>;
  Gamesother?: Maybe<Scalars["String"]>;
  Gate?: Maybe<Scalars["String"]>;
  Gateother?: Maybe<Scalars["String"]>;
  Globalid?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Hsenbr?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Id2?: Maybe<Scalars["String"]>;
  Id3?: Maybe<Scalars["bigint"]>;
  Land4?: Maybe<Scalars["float8"]>;
  Landline?: Maybe<Scalars["String"]>;
  Landline1?: Maybe<Scalars["String"]>;
  Landline4?: Maybe<Scalars["String"]>;
  Landlinecode?: Maybe<Scalars["String"]>;
  Landlinecode4?: Maybe<Scalars["String"]>;
  Landregister?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  LatitudeA?: Maybe<Scalars["float8"]>;
  Latlong?: Maybe<Scalars["String"]>;
  Legalname?: Maybe<Scalars["String"]>;
  Levelofcompletion?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Lln?: Maybe<Scalars["String"]>;
  Lln1?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  LongitudeB?: Maybe<Scalars["float8"]>;
  Lvls?: Maybe<Scalars["String"]>;
  Mainbusinessname?: Maybe<Scalars["String"]>;
  Mob?: Maybe<Scalars["float8"]>;
  Mob1?: Maybe<Scalars["float8"]>;
  Mob3?: Maybe<Scalars["String"]>;
  Mob4?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  Mobile1?: Maybe<Scalars["String"]>;
  Mobile1code?: Maybe<Scalars["String"]>;
  Mobile3?: Maybe<Scalars["String"]>;
  Mobile3code?: Maybe<Scalars["String"]>;
  Mobile4?: Maybe<Scalars["String"]>;
  Mobile4code?: Maybe<Scalars["String"]>;
  Mobile5?: Maybe<Scalars["String"]>;
  Mobile5code?: Maybe<Scalars["String"]>;
  Mobilecode?: Maybe<Scalars["String"]>;
  Name2?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["float8"]>;
  Nationalid2?: Maybe<Scalars["float8"]>;
  Nbrofslots?: Maybe<Scalars["String"]>;
  Neighbourhoodactivities?: Maybe<Scalars["String"]>;
  Neighbourhoodstatus?: Maybe<Scalars["String"]>;
  Nin?: Maybe<Scalars["String"]>;
  Nin2?: Maybe<Scalars["String"]>;
  Objectid?: Maybe<Scalars["float8"]>;
  Other?: Maybe<Scalars["String"]>;
  Otherfacilities?: Maybe<Scalars["String"]>;
  Otherid?: Maybe<Scalars["String"]>;
  Otherid1?: Maybe<Scalars["String"]>;
  Otherid2?: Maybe<Scalars["String"]>;
  Otherid3?: Maybe<Scalars["float8"]>;
  Otherid4?: Maybe<Scalars["String"]>;
  Otherid4nbr?: Maybe<Scalars["String"]>;
  Otherid6?: Maybe<Scalars["String"]>;
  Otherid7?: Maybe<Scalars["float8"]>;
  Othernames?: Maybe<Scalars["String"]>;
  Othernames3?: Maybe<Scalars["String"]>;
  Otherservices?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Parish1?: Maybe<Scalars["String"]>;
  Parish2?: Maybe<Scalars["String"]>;
  Parking?: Maybe<Scalars["String"]>;
  Parkingsufficient?: Maybe<Scalars["String"]>;
  Partiiindividualownershipdetails?: Maybe<Scalars["String"]>;
  Partinonindividualownershipdetails?: Maybe<Scalars["String"]>;
  Pass?: Maybe<Scalars["float8"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Postalofficeboxnumber?: Maybe<Scalars["String"]>;
  Postalofficeboxnumber1?: Maybe<Scalars["String"]>;
  Powersupply?: Maybe<Scalars["String"]>;
  Propertyname?: Maybe<Scalars["String"]>;
  Propertysubtype?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Reference?: Maybe<Scalars["bigint"]>;
  Relationshiptoowner?: Maybe<Scalars["String"]>;
  Roadfrontageorgrid?: Maybe<Scalars["String"]>;
  Roadname?: Maybe<Scalars["String"]>;
  Roofcovering?: Maybe<Scalars["String"]>;
  Roofcoveringothers?: Maybe<Scalars["String"]>;
  Sanitation?: Maybe<Scalars["String"]>;
  Sectionapropertyownersdetails?: Maybe<Scalars["String"]>;
  Security?: Maybe<Scalars["String"]>;
  Shape?: Maybe<Scalars["String"]>;
  Shapeofplot?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Subcounty1?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Surname3?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Title1?: Maybe<Scalars["String"]>;
  Topography?: Maybe<Scalars["String"]>;
  TotalBuiltUpArea?: Maybe<Scalars["float8"]>;
  TotalGrossArea?: Maybe<Scalars["float8"]>;
  Typeofaccess?: Maybe<Scalars["String"]>;
  Typeofbuilding?: Maybe<Scalars["String"]>;
  Typeofcompany?: Maybe<Scalars["String"]>;
  Typeoflandinterest?: Maybe<Scalars["String"]>;
  Username?: Maybe<Scalars["float8"]>;
  Verticalcirculation?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  Village1?: Maybe<Scalars["String"]>;
  Village2?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  Wallfinish?: Maybe<Scalars["String"]>;
  Wallfinishothers?: Maybe<Scalars["String"]>;
  Walls?: Maybe<Scalars["String"]>;
  Wallsothers?: Maybe<Scalars["String"]>;
  Wateraccessibility?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Windows?: Maybe<Scalars["String"]>;
  Windowsothers?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_RawProperties2" */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Max_Order_By = {
  Access?: Maybe<Order_By>;
  Agent?: Maybe<Order_By>;
  Alternative?: Maybe<Order_By>;
  Alternativeemailaddress?: Maybe<Order_By>;
  Alternativefirstname?: Maybe<Order_By>;
  Alternativelandlinenumber?: Maybe<Order_By>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Order_By>;
  Alternativeothername?: Maybe<Order_By>;
  Alternativephonenumber1?: Maybe<Order_By>;
  Alternativephonenumber1code?: Maybe<Order_By>;
  Alternativephonenumber2?: Maybe<Order_By>;
  Alternativephonenumber2code?: Maybe<Order_By>;
  Alternativesurname?: Maybe<Order_By>;
  Altphn1?: Maybe<Order_By>;
  Altphn2?: Maybe<Order_By>;
  Anyother?: Maybe<Order_By>;
  AreaCount?: Maybe<Order_By>;
  Authorizedcontactperson?: Maybe<Order_By>;
  Bldg2?: Maybe<Order_By>;
  Blockname?: Maybe<Order_By>;
  Blocknumber?: Maybe<Order_By>;
  Blockother?: Maybe<Order_By>;
  Boundary?: Maybe<Order_By>;
  Boundaryfenceother?: Maybe<Order_By>;
  Buildingcondition?: Maybe<Order_By>;
  Buildingname?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CamvSerial?: Maybe<Order_By>;
  Ceiling?: Maybe<Order_By>;
  Ceilingothers?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  Coin1?: Maybe<Order_By>;
  Comment?: Maybe<Order_By>;
  Compound?: Maybe<Order_By>;
  Compoundother?: Maybe<Order_By>;
  Contactdetailsofdirector?: Maybe<Order_By>;
  Country?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  County1?: Maybe<Order_By>;
  Creationdate?: Maybe<Order_By>;
  Creator?: Maybe<Order_By>;
  Ctrycde?: Maybe<Order_By>;
  Ctrycde1?: Maybe<Order_By>;
  Ctrycde2?: Maybe<Order_By>;
  Ctrycde4?: Maybe<Order_By>;
  Datacollector?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  District1?: Maybe<Order_By>;
  Districtorcitywhereboxislocated?: Maybe<Order_By>;
  Districtorcitywhereboxislocated1?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  Doors?: Maybe<Order_By>;
  Doorsothers?: Maybe<Order_By>;
  Editdate?: Maybe<Order_By>;
  Editor?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  Email1?: Maybe<Order_By>;
  Email3?: Maybe<Order_By>;
  Email5?: Maybe<Order_By>;
  Entitytype?: Maybe<Order_By>;
  Environment?: Maybe<Order_By>;
  Facilities?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Firstname3?: Maybe<Order_By>;
  Flatblocknumber?: Maybe<Order_By>;
  Flatnumber?: Maybe<Order_By>;
  Floorconstruction?: Maybe<Order_By>;
  Floorconstructionothers?: Maybe<Order_By>;
  Floorfinish?: Maybe<Order_By>;
  Floorfinishothers?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Games?: Maybe<Order_By>;
  Gamesother?: Maybe<Order_By>;
  Gate?: Maybe<Order_By>;
  Gateother?: Maybe<Order_By>;
  Globalid?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Hsenbr?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Id2?: Maybe<Order_By>;
  Id3?: Maybe<Order_By>;
  Land4?: Maybe<Order_By>;
  Landline?: Maybe<Order_By>;
  Landline1?: Maybe<Order_By>;
  Landline4?: Maybe<Order_By>;
  Landlinecode?: Maybe<Order_By>;
  Landlinecode4?: Maybe<Order_By>;
  Landregister?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  LatitudeA?: Maybe<Order_By>;
  Latlong?: Maybe<Order_By>;
  Legalname?: Maybe<Order_By>;
  Levelofcompletion?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Lln?: Maybe<Order_By>;
  Lln1?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  LongitudeB?: Maybe<Order_By>;
  Lvls?: Maybe<Order_By>;
  Mainbusinessname?: Maybe<Order_By>;
  Mob?: Maybe<Order_By>;
  Mob1?: Maybe<Order_By>;
  Mob3?: Maybe<Order_By>;
  Mob4?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobile1?: Maybe<Order_By>;
  Mobile1code?: Maybe<Order_By>;
  Mobile3?: Maybe<Order_By>;
  Mobile3code?: Maybe<Order_By>;
  Mobile4?: Maybe<Order_By>;
  Mobile4code?: Maybe<Order_By>;
  Mobile5?: Maybe<Order_By>;
  Mobile5code?: Maybe<Order_By>;
  Mobilecode?: Maybe<Order_By>;
  Name2?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Nationalid2?: Maybe<Order_By>;
  Nbrofslots?: Maybe<Order_By>;
  Neighbourhoodactivities?: Maybe<Order_By>;
  Neighbourhoodstatus?: Maybe<Order_By>;
  Nin?: Maybe<Order_By>;
  Nin2?: Maybe<Order_By>;
  Objectid?: Maybe<Order_By>;
  Other?: Maybe<Order_By>;
  Otherfacilities?: Maybe<Order_By>;
  Otherid?: Maybe<Order_By>;
  Otherid1?: Maybe<Order_By>;
  Otherid2?: Maybe<Order_By>;
  Otherid3?: Maybe<Order_By>;
  Otherid4?: Maybe<Order_By>;
  Otherid4nbr?: Maybe<Order_By>;
  Otherid6?: Maybe<Order_By>;
  Otherid7?: Maybe<Order_By>;
  Othernames?: Maybe<Order_By>;
  Othernames3?: Maybe<Order_By>;
  Otherservices?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Parish1?: Maybe<Order_By>;
  Parish2?: Maybe<Order_By>;
  Parking?: Maybe<Order_By>;
  Parkingsufficient?: Maybe<Order_By>;
  Partiiindividualownershipdetails?: Maybe<Order_By>;
  Partinonindividualownershipdetails?: Maybe<Order_By>;
  Pass?: Maybe<Order_By>;
  Plotnumber?: Maybe<Order_By>;
  Postalofficeboxnumber?: Maybe<Order_By>;
  Postalofficeboxnumber1?: Maybe<Order_By>;
  Powersupply?: Maybe<Order_By>;
  Propertyname?: Maybe<Order_By>;
  Propertysubtype?: Maybe<Order_By>;
  Propertytype?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  Relationshiptoowner?: Maybe<Order_By>;
  Roadfrontageorgrid?: Maybe<Order_By>;
  Roadname?: Maybe<Order_By>;
  Roofcovering?: Maybe<Order_By>;
  Roofcoveringothers?: Maybe<Order_By>;
  Sanitation?: Maybe<Order_By>;
  Sectionapropertyownersdetails?: Maybe<Order_By>;
  Security?: Maybe<Order_By>;
  Shape?: Maybe<Order_By>;
  Shapeofplot?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Subcounty1?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Surname3?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Title?: Maybe<Order_By>;
  Title1?: Maybe<Order_By>;
  Topography?: Maybe<Order_By>;
  TotalBuiltUpArea?: Maybe<Order_By>;
  TotalGrossArea?: Maybe<Order_By>;
  Typeofaccess?: Maybe<Order_By>;
  Typeofbuilding?: Maybe<Order_By>;
  Typeofcompany?: Maybe<Order_By>;
  Typeoflandinterest?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
  Verticalcirculation?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  Village1?: Maybe<Order_By>;
  Village2?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  Wallfinish?: Maybe<Order_By>;
  Wallfinishothers?: Maybe<Order_By>;
  Walls?: Maybe<Order_By>;
  Wallsothers?: Maybe<Order_By>;
  Wateraccessibility?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  Windows?: Maybe<Order_By>;
  Windowsothers?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_RawProperties2_min_fields";
  Access?: Maybe<Scalars["String"]>;
  Agent?: Maybe<Scalars["String"]>;
  Alternative?: Maybe<Scalars["String"]>;
  Alternativeemailaddress?: Maybe<Scalars["String"]>;
  Alternativefirstname?: Maybe<Scalars["String"]>;
  Alternativelandlinenumber?: Maybe<Scalars["float8"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["float8"]>;
  Alternativeothername?: Maybe<Scalars["String"]>;
  Alternativephonenumber1?: Maybe<Scalars["float8"]>;
  Alternativephonenumber1code?: Maybe<Scalars["String"]>;
  Alternativephonenumber2?: Maybe<Scalars["String"]>;
  Alternativephonenumber2code?: Maybe<Scalars["String"]>;
  Alternativesurname?: Maybe<Scalars["String"]>;
  Altphn1?: Maybe<Scalars["float8"]>;
  Altphn2?: Maybe<Scalars["float8"]>;
  Anyother?: Maybe<Scalars["String"]>;
  AreaCount?: Maybe<Scalars["String"]>;
  Authorizedcontactperson?: Maybe<Scalars["String"]>;
  Bldg2?: Maybe<Scalars["String"]>;
  Blockname?: Maybe<Scalars["String"]>;
  Blocknumber?: Maybe<Scalars["String"]>;
  Blockother?: Maybe<Scalars["String"]>;
  Boundary?: Maybe<Scalars["String"]>;
  Boundaryfenceother?: Maybe<Scalars["String"]>;
  Buildingcondition?: Maybe<Scalars["String"]>;
  Buildingname?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["bigint"]>;
  CamvSerial?: Maybe<Scalars["String"]>;
  Ceiling?: Maybe<Scalars["String"]>;
  Ceilingothers?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["String"]>;
  Coin1?: Maybe<Scalars["String"]>;
  Comment?: Maybe<Scalars["String"]>;
  Compound?: Maybe<Scalars["String"]>;
  Compoundother?: Maybe<Scalars["String"]>;
  Contactdetailsofdirector?: Maybe<Scalars["String"]>;
  Country?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  County1?: Maybe<Scalars["String"]>;
  Creationdate?: Maybe<Scalars["timestamptz"]>;
  Creator?: Maybe<Scalars["String"]>;
  Ctrycde?: Maybe<Scalars["String"]>;
  Ctrycde1?: Maybe<Scalars["String"]>;
  Ctrycde2?: Maybe<Scalars["String"]>;
  Ctrycde4?: Maybe<Scalars["String"]>;
  Datacollector?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  District1?: Maybe<Scalars["String"]>;
  Districtorcitywhereboxislocated?: Maybe<Scalars["String"]>;
  Districtorcitywhereboxislocated1?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Doors?: Maybe<Scalars["String"]>;
  Doorsothers?: Maybe<Scalars["String"]>;
  Editdate?: Maybe<Scalars["timestamptz"]>;
  Editor?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Email1?: Maybe<Scalars["String"]>;
  Email3?: Maybe<Scalars["String"]>;
  Email5?: Maybe<Scalars["String"]>;
  Entitytype?: Maybe<Scalars["String"]>;
  Environment?: Maybe<Scalars["String"]>;
  Facilities?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Firstname3?: Maybe<Scalars["String"]>;
  Flatblocknumber?: Maybe<Scalars["String"]>;
  Flatnumber?: Maybe<Scalars["String"]>;
  Floorconstruction?: Maybe<Scalars["String"]>;
  Floorconstructionothers?: Maybe<Scalars["String"]>;
  Floorfinish?: Maybe<Scalars["String"]>;
  Floorfinishothers?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Games?: Maybe<Scalars["String"]>;
  Gamesother?: Maybe<Scalars["String"]>;
  Gate?: Maybe<Scalars["String"]>;
  Gateother?: Maybe<Scalars["String"]>;
  Globalid?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Hsenbr?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Id2?: Maybe<Scalars["String"]>;
  Id3?: Maybe<Scalars["bigint"]>;
  Land4?: Maybe<Scalars["float8"]>;
  Landline?: Maybe<Scalars["String"]>;
  Landline1?: Maybe<Scalars["String"]>;
  Landline4?: Maybe<Scalars["String"]>;
  Landlinecode?: Maybe<Scalars["String"]>;
  Landlinecode4?: Maybe<Scalars["String"]>;
  Landregister?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  LatitudeA?: Maybe<Scalars["float8"]>;
  Latlong?: Maybe<Scalars["String"]>;
  Legalname?: Maybe<Scalars["String"]>;
  Levelofcompletion?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Lln?: Maybe<Scalars["String"]>;
  Lln1?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  LongitudeB?: Maybe<Scalars["float8"]>;
  Lvls?: Maybe<Scalars["String"]>;
  Mainbusinessname?: Maybe<Scalars["String"]>;
  Mob?: Maybe<Scalars["float8"]>;
  Mob1?: Maybe<Scalars["float8"]>;
  Mob3?: Maybe<Scalars["String"]>;
  Mob4?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  Mobile1?: Maybe<Scalars["String"]>;
  Mobile1code?: Maybe<Scalars["String"]>;
  Mobile3?: Maybe<Scalars["String"]>;
  Mobile3code?: Maybe<Scalars["String"]>;
  Mobile4?: Maybe<Scalars["String"]>;
  Mobile4code?: Maybe<Scalars["String"]>;
  Mobile5?: Maybe<Scalars["String"]>;
  Mobile5code?: Maybe<Scalars["String"]>;
  Mobilecode?: Maybe<Scalars["String"]>;
  Name2?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["float8"]>;
  Nationalid2?: Maybe<Scalars["float8"]>;
  Nbrofslots?: Maybe<Scalars["String"]>;
  Neighbourhoodactivities?: Maybe<Scalars["String"]>;
  Neighbourhoodstatus?: Maybe<Scalars["String"]>;
  Nin?: Maybe<Scalars["String"]>;
  Nin2?: Maybe<Scalars["String"]>;
  Objectid?: Maybe<Scalars["float8"]>;
  Other?: Maybe<Scalars["String"]>;
  Otherfacilities?: Maybe<Scalars["String"]>;
  Otherid?: Maybe<Scalars["String"]>;
  Otherid1?: Maybe<Scalars["String"]>;
  Otherid2?: Maybe<Scalars["String"]>;
  Otherid3?: Maybe<Scalars["float8"]>;
  Otherid4?: Maybe<Scalars["String"]>;
  Otherid4nbr?: Maybe<Scalars["String"]>;
  Otherid6?: Maybe<Scalars["String"]>;
  Otherid7?: Maybe<Scalars["float8"]>;
  Othernames?: Maybe<Scalars["String"]>;
  Othernames3?: Maybe<Scalars["String"]>;
  Otherservices?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Parish1?: Maybe<Scalars["String"]>;
  Parish2?: Maybe<Scalars["String"]>;
  Parking?: Maybe<Scalars["String"]>;
  Parkingsufficient?: Maybe<Scalars["String"]>;
  Partiiindividualownershipdetails?: Maybe<Scalars["String"]>;
  Partinonindividualownershipdetails?: Maybe<Scalars["String"]>;
  Pass?: Maybe<Scalars["float8"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Postalofficeboxnumber?: Maybe<Scalars["String"]>;
  Postalofficeboxnumber1?: Maybe<Scalars["String"]>;
  Powersupply?: Maybe<Scalars["String"]>;
  Propertyname?: Maybe<Scalars["String"]>;
  Propertysubtype?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Reference?: Maybe<Scalars["bigint"]>;
  Relationshiptoowner?: Maybe<Scalars["String"]>;
  Roadfrontageorgrid?: Maybe<Scalars["String"]>;
  Roadname?: Maybe<Scalars["String"]>;
  Roofcovering?: Maybe<Scalars["String"]>;
  Roofcoveringothers?: Maybe<Scalars["String"]>;
  Sanitation?: Maybe<Scalars["String"]>;
  Sectionapropertyownersdetails?: Maybe<Scalars["String"]>;
  Security?: Maybe<Scalars["String"]>;
  Shape?: Maybe<Scalars["String"]>;
  Shapeofplot?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Subcounty1?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Surname3?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Title1?: Maybe<Scalars["String"]>;
  Topography?: Maybe<Scalars["String"]>;
  TotalBuiltUpArea?: Maybe<Scalars["float8"]>;
  TotalGrossArea?: Maybe<Scalars["float8"]>;
  Typeofaccess?: Maybe<Scalars["String"]>;
  Typeofbuilding?: Maybe<Scalars["String"]>;
  Typeofcompany?: Maybe<Scalars["String"]>;
  Typeoflandinterest?: Maybe<Scalars["String"]>;
  Username?: Maybe<Scalars["float8"]>;
  Verticalcirculation?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  Village1?: Maybe<Scalars["String"]>;
  Village2?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  Wallfinish?: Maybe<Scalars["String"]>;
  Wallfinishothers?: Maybe<Scalars["String"]>;
  Walls?: Maybe<Scalars["String"]>;
  Wallsothers?: Maybe<Scalars["String"]>;
  Wateraccessibility?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Windows?: Maybe<Scalars["String"]>;
  Windowsothers?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_RawProperties2" */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Min_Order_By = {
  Access?: Maybe<Order_By>;
  Agent?: Maybe<Order_By>;
  Alternative?: Maybe<Order_By>;
  Alternativeemailaddress?: Maybe<Order_By>;
  Alternativefirstname?: Maybe<Order_By>;
  Alternativelandlinenumber?: Maybe<Order_By>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Order_By>;
  Alternativeothername?: Maybe<Order_By>;
  Alternativephonenumber1?: Maybe<Order_By>;
  Alternativephonenumber1code?: Maybe<Order_By>;
  Alternativephonenumber2?: Maybe<Order_By>;
  Alternativephonenumber2code?: Maybe<Order_By>;
  Alternativesurname?: Maybe<Order_By>;
  Altphn1?: Maybe<Order_By>;
  Altphn2?: Maybe<Order_By>;
  Anyother?: Maybe<Order_By>;
  AreaCount?: Maybe<Order_By>;
  Authorizedcontactperson?: Maybe<Order_By>;
  Bldg2?: Maybe<Order_By>;
  Blockname?: Maybe<Order_By>;
  Blocknumber?: Maybe<Order_By>;
  Blockother?: Maybe<Order_By>;
  Boundary?: Maybe<Order_By>;
  Boundaryfenceother?: Maybe<Order_By>;
  Buildingcondition?: Maybe<Order_By>;
  Buildingname?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CamvSerial?: Maybe<Order_By>;
  Ceiling?: Maybe<Order_By>;
  Ceilingothers?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  Coin1?: Maybe<Order_By>;
  Comment?: Maybe<Order_By>;
  Compound?: Maybe<Order_By>;
  Compoundother?: Maybe<Order_By>;
  Contactdetailsofdirector?: Maybe<Order_By>;
  Country?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  County1?: Maybe<Order_By>;
  Creationdate?: Maybe<Order_By>;
  Creator?: Maybe<Order_By>;
  Ctrycde?: Maybe<Order_By>;
  Ctrycde1?: Maybe<Order_By>;
  Ctrycde2?: Maybe<Order_By>;
  Ctrycde4?: Maybe<Order_By>;
  Datacollector?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  District1?: Maybe<Order_By>;
  Districtorcitywhereboxislocated?: Maybe<Order_By>;
  Districtorcitywhereboxislocated1?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  Doors?: Maybe<Order_By>;
  Doorsothers?: Maybe<Order_By>;
  Editdate?: Maybe<Order_By>;
  Editor?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  Email1?: Maybe<Order_By>;
  Email3?: Maybe<Order_By>;
  Email5?: Maybe<Order_By>;
  Entitytype?: Maybe<Order_By>;
  Environment?: Maybe<Order_By>;
  Facilities?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Firstname3?: Maybe<Order_By>;
  Flatblocknumber?: Maybe<Order_By>;
  Flatnumber?: Maybe<Order_By>;
  Floorconstruction?: Maybe<Order_By>;
  Floorconstructionothers?: Maybe<Order_By>;
  Floorfinish?: Maybe<Order_By>;
  Floorfinishothers?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Games?: Maybe<Order_By>;
  Gamesother?: Maybe<Order_By>;
  Gate?: Maybe<Order_By>;
  Gateother?: Maybe<Order_By>;
  Globalid?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Hsenbr?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Id2?: Maybe<Order_By>;
  Id3?: Maybe<Order_By>;
  Land4?: Maybe<Order_By>;
  Landline?: Maybe<Order_By>;
  Landline1?: Maybe<Order_By>;
  Landline4?: Maybe<Order_By>;
  Landlinecode?: Maybe<Order_By>;
  Landlinecode4?: Maybe<Order_By>;
  Landregister?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  LatitudeA?: Maybe<Order_By>;
  Latlong?: Maybe<Order_By>;
  Legalname?: Maybe<Order_By>;
  Levelofcompletion?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Lln?: Maybe<Order_By>;
  Lln1?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  LongitudeB?: Maybe<Order_By>;
  Lvls?: Maybe<Order_By>;
  Mainbusinessname?: Maybe<Order_By>;
  Mob?: Maybe<Order_By>;
  Mob1?: Maybe<Order_By>;
  Mob3?: Maybe<Order_By>;
  Mob4?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobile1?: Maybe<Order_By>;
  Mobile1code?: Maybe<Order_By>;
  Mobile3?: Maybe<Order_By>;
  Mobile3code?: Maybe<Order_By>;
  Mobile4?: Maybe<Order_By>;
  Mobile4code?: Maybe<Order_By>;
  Mobile5?: Maybe<Order_By>;
  Mobile5code?: Maybe<Order_By>;
  Mobilecode?: Maybe<Order_By>;
  Name2?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Nationalid2?: Maybe<Order_By>;
  Nbrofslots?: Maybe<Order_By>;
  Neighbourhoodactivities?: Maybe<Order_By>;
  Neighbourhoodstatus?: Maybe<Order_By>;
  Nin?: Maybe<Order_By>;
  Nin2?: Maybe<Order_By>;
  Objectid?: Maybe<Order_By>;
  Other?: Maybe<Order_By>;
  Otherfacilities?: Maybe<Order_By>;
  Otherid?: Maybe<Order_By>;
  Otherid1?: Maybe<Order_By>;
  Otherid2?: Maybe<Order_By>;
  Otherid3?: Maybe<Order_By>;
  Otherid4?: Maybe<Order_By>;
  Otherid4nbr?: Maybe<Order_By>;
  Otherid6?: Maybe<Order_By>;
  Otherid7?: Maybe<Order_By>;
  Othernames?: Maybe<Order_By>;
  Othernames3?: Maybe<Order_By>;
  Otherservices?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Parish1?: Maybe<Order_By>;
  Parish2?: Maybe<Order_By>;
  Parking?: Maybe<Order_By>;
  Parkingsufficient?: Maybe<Order_By>;
  Partiiindividualownershipdetails?: Maybe<Order_By>;
  Partinonindividualownershipdetails?: Maybe<Order_By>;
  Pass?: Maybe<Order_By>;
  Plotnumber?: Maybe<Order_By>;
  Postalofficeboxnumber?: Maybe<Order_By>;
  Postalofficeboxnumber1?: Maybe<Order_By>;
  Powersupply?: Maybe<Order_By>;
  Propertyname?: Maybe<Order_By>;
  Propertysubtype?: Maybe<Order_By>;
  Propertytype?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  Relationshiptoowner?: Maybe<Order_By>;
  Roadfrontageorgrid?: Maybe<Order_By>;
  Roadname?: Maybe<Order_By>;
  Roofcovering?: Maybe<Order_By>;
  Roofcoveringothers?: Maybe<Order_By>;
  Sanitation?: Maybe<Order_By>;
  Sectionapropertyownersdetails?: Maybe<Order_By>;
  Security?: Maybe<Order_By>;
  Shape?: Maybe<Order_By>;
  Shapeofplot?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Subcounty1?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Surname3?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Title?: Maybe<Order_By>;
  Title1?: Maybe<Order_By>;
  Topography?: Maybe<Order_By>;
  TotalBuiltUpArea?: Maybe<Order_By>;
  TotalGrossArea?: Maybe<Order_By>;
  Typeofaccess?: Maybe<Order_By>;
  Typeofbuilding?: Maybe<Order_By>;
  Typeofcompany?: Maybe<Order_By>;
  Typeoflandinterest?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
  Verticalcirculation?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  Village1?: Maybe<Order_By>;
  Village2?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  Wallfinish?: Maybe<Order_By>;
  Wallfinishothers?: Maybe<Order_By>;
  Walls?: Maybe<Order_By>;
  Wallsothers?: Maybe<Order_By>;
  Wateraccessibility?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  Windows?: Maybe<Order_By>;
  Windowsothers?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_RawProperties2" */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_RawProperties2_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_RawProperties2>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_RawProperties2" */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_RawProperties2_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_RawProperties2" */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Order_By = {
  Access?: Maybe<Order_By>;
  Agent?: Maybe<Order_By>;
  Alternative?: Maybe<Order_By>;
  Alternativeemailaddress?: Maybe<Order_By>;
  Alternativefirstname?: Maybe<Order_By>;
  Alternativelandlinenumber?: Maybe<Order_By>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Order_By>;
  Alternativeothername?: Maybe<Order_By>;
  Alternativephonenumber1?: Maybe<Order_By>;
  Alternativephonenumber1code?: Maybe<Order_By>;
  Alternativephonenumber2?: Maybe<Order_By>;
  Alternativephonenumber2code?: Maybe<Order_By>;
  Alternativesurname?: Maybe<Order_By>;
  Altphn1?: Maybe<Order_By>;
  Altphn2?: Maybe<Order_By>;
  Anyother?: Maybe<Order_By>;
  AreaCount?: Maybe<Order_By>;
  Authorizedcontactperson?: Maybe<Order_By>;
  Bldg2?: Maybe<Order_By>;
  Blockname?: Maybe<Order_By>;
  Blocknumber?: Maybe<Order_By>;
  Blockother?: Maybe<Order_By>;
  Boundary?: Maybe<Order_By>;
  Boundaryfenceother?: Maybe<Order_By>;
  Buildingcondition?: Maybe<Order_By>;
  Buildingname?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CamvSerial?: Maybe<Order_By>;
  Ceiling?: Maybe<Order_By>;
  Ceilingothers?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  Coin1?: Maybe<Order_By>;
  Comment?: Maybe<Order_By>;
  Compound?: Maybe<Order_By>;
  Compoundother?: Maybe<Order_By>;
  Contactdetailsofdirector?: Maybe<Order_By>;
  Country?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  County1?: Maybe<Order_By>;
  Creationdate?: Maybe<Order_By>;
  Creator?: Maybe<Order_By>;
  Ctrycde?: Maybe<Order_By>;
  Ctrycde1?: Maybe<Order_By>;
  Ctrycde2?: Maybe<Order_By>;
  Ctrycde4?: Maybe<Order_By>;
  Datacollector?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  District1?: Maybe<Order_By>;
  Districtorcitywhereboxislocated?: Maybe<Order_By>;
  Districtorcitywhereboxislocated1?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  Doors?: Maybe<Order_By>;
  Doorsothers?: Maybe<Order_By>;
  Editdate?: Maybe<Order_By>;
  Editor?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  Email1?: Maybe<Order_By>;
  Email3?: Maybe<Order_By>;
  Email5?: Maybe<Order_By>;
  Entitytype?: Maybe<Order_By>;
  Environment?: Maybe<Order_By>;
  Facilities?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Firstname3?: Maybe<Order_By>;
  Flatblocknumber?: Maybe<Order_By>;
  Flatnumber?: Maybe<Order_By>;
  Floorconstruction?: Maybe<Order_By>;
  Floorconstructionothers?: Maybe<Order_By>;
  Floorfinish?: Maybe<Order_By>;
  Floorfinishothers?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Games?: Maybe<Order_By>;
  Gamesother?: Maybe<Order_By>;
  Gate?: Maybe<Order_By>;
  Gateother?: Maybe<Order_By>;
  Globalid?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Hsenbr?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Id2?: Maybe<Order_By>;
  Id3?: Maybe<Order_By>;
  Land4?: Maybe<Order_By>;
  Landline?: Maybe<Order_By>;
  Landline1?: Maybe<Order_By>;
  Landline4?: Maybe<Order_By>;
  Landlinecode?: Maybe<Order_By>;
  Landlinecode4?: Maybe<Order_By>;
  Landregister?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  LatitudeA?: Maybe<Order_By>;
  Latlong?: Maybe<Order_By>;
  Legalname?: Maybe<Order_By>;
  Levelofcompletion?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Lln?: Maybe<Order_By>;
  Lln1?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  LongitudeB?: Maybe<Order_By>;
  Lvls?: Maybe<Order_By>;
  Mainbusinessname?: Maybe<Order_By>;
  Mob?: Maybe<Order_By>;
  Mob1?: Maybe<Order_By>;
  Mob3?: Maybe<Order_By>;
  Mob4?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobile1?: Maybe<Order_By>;
  Mobile1code?: Maybe<Order_By>;
  Mobile3?: Maybe<Order_By>;
  Mobile3code?: Maybe<Order_By>;
  Mobile4?: Maybe<Order_By>;
  Mobile4code?: Maybe<Order_By>;
  Mobile5?: Maybe<Order_By>;
  Mobile5code?: Maybe<Order_By>;
  Mobilecode?: Maybe<Order_By>;
  Name2?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Nationalid2?: Maybe<Order_By>;
  Nbrofslots?: Maybe<Order_By>;
  Neighbourhoodactivities?: Maybe<Order_By>;
  Neighbourhoodstatus?: Maybe<Order_By>;
  Nin?: Maybe<Order_By>;
  Nin2?: Maybe<Order_By>;
  Objectid?: Maybe<Order_By>;
  Other?: Maybe<Order_By>;
  Otherfacilities?: Maybe<Order_By>;
  Otherid?: Maybe<Order_By>;
  Otherid1?: Maybe<Order_By>;
  Otherid2?: Maybe<Order_By>;
  Otherid3?: Maybe<Order_By>;
  Otherid4?: Maybe<Order_By>;
  Otherid4nbr?: Maybe<Order_By>;
  Otherid6?: Maybe<Order_By>;
  Otherid7?: Maybe<Order_By>;
  Othernames?: Maybe<Order_By>;
  Othernames3?: Maybe<Order_By>;
  Otherservices?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Parish1?: Maybe<Order_By>;
  Parish2?: Maybe<Order_By>;
  Parking?: Maybe<Order_By>;
  Parkingsufficient?: Maybe<Order_By>;
  Partiiindividualownershipdetails?: Maybe<Order_By>;
  Partinonindividualownershipdetails?: Maybe<Order_By>;
  Pass?: Maybe<Order_By>;
  Plotnumber?: Maybe<Order_By>;
  Postalofficeboxnumber?: Maybe<Order_By>;
  Postalofficeboxnumber1?: Maybe<Order_By>;
  Powersupply?: Maybe<Order_By>;
  Propertyname?: Maybe<Order_By>;
  Propertysubtype?: Maybe<Order_By>;
  Propertytype?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  Relationshiptoowner?: Maybe<Order_By>;
  Roadfrontageorgrid?: Maybe<Order_By>;
  Roadname?: Maybe<Order_By>;
  Roofcovering?: Maybe<Order_By>;
  Roofcoveringothers?: Maybe<Order_By>;
  Sanitation?: Maybe<Order_By>;
  Sectionapropertyownersdetails?: Maybe<Order_By>;
  Security?: Maybe<Order_By>;
  Shape?: Maybe<Order_By>;
  Shapeofplot?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Subcounty1?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Surname3?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Title?: Maybe<Order_By>;
  Title1?: Maybe<Order_By>;
  Topography?: Maybe<Order_By>;
  TotalBuiltUpArea?: Maybe<Order_By>;
  TotalGrossArea?: Maybe<Order_By>;
  Typeofaccess?: Maybe<Order_By>;
  Typeofbuilding?: Maybe<Order_By>;
  Typeofcompany?: Maybe<Order_By>;
  Typeoflandinterest?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
  Verticalcirculation?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  Village1?: Maybe<Order_By>;
  Village2?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  Wallfinish?: Maybe<Order_By>;
  Wallfinishothers?: Maybe<Order_By>;
  Walls?: Maybe<Order_By>;
  Wallsothers?: Maybe<Order_By>;
  Wateraccessibility?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  Windows?: Maybe<Order_By>;
  Windowsothers?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_RawProperties2" */
export enum Rtcs_Db_Ph_Kcca_RawProperties2_Select_Column {
  /** column name */
  Access = "Access",
  /** column name */
  Agent = "Agent",
  /** column name */
  Alternative = "Alternative",
  /** column name */
  Alternativeemailaddress = "Alternativeemailaddress",
  /** column name */
  Alternativefirstname = "Alternativefirstname",
  /** column name */
  Alternativelandlinenumber = "Alternativelandlinenumber",
  /** column name */
  Alternativelandlinenumberwithcountrycode = "Alternativelandlinenumberwithcountrycode",
  /** column name */
  Alternativeothername = "Alternativeothername",
  /** column name */
  Alternativephonenumber1 = "Alternativephonenumber1",
  /** column name */
  Alternativephonenumber1code = "Alternativephonenumber1code",
  /** column name */
  Alternativephonenumber2 = "Alternativephonenumber2",
  /** column name */
  Alternativephonenumber2code = "Alternativephonenumber2code",
  /** column name */
  Alternativesurname = "Alternativesurname",
  /** column name */
  Altphn1 = "Altphn1",
  /** column name */
  Altphn2 = "Altphn2",
  /** column name */
  Anyother = "Anyother",
  /** column name */
  AreaCount = "AreaCount",
  /** column name */
  Authorizedcontactperson = "Authorizedcontactperson",
  /** column name */
  Bldg2 = "Bldg2",
  /** column name */
  Blockname = "Blockname",
  /** column name */
  Blocknumber = "Blocknumber",
  /** column name */
  Blockother = "Blockother",
  /** column name */
  Boundary = "Boundary",
  /** column name */
  Boundaryfenceother = "Boundaryfenceother",
  /** column name */
  Buildingcondition = "Buildingcondition",
  /** column name */
  Buildingname = "Buildingname",
  /** column name */
  CamvId = "CamvId",
  /** column name */
  CamvSerial = "CamvSerial",
  /** column name */
  Ceiling = "Ceiling",
  /** column name */
  Ceilingothers = "Ceilingothers",
  /** column name */
  Coin = "Coin",
  /** column name */
  Coin1 = "Coin1",
  /** column name */
  Comment = "Comment",
  /** column name */
  Compound = "Compound",
  /** column name */
  Compoundother = "Compoundother",
  /** column name */
  Contactdetailsofdirector = "Contactdetailsofdirector",
  /** column name */
  Country = "Country",
  /** column name */
  County = "County",
  /** column name */
  County1 = "County1",
  /** column name */
  Creationdate = "Creationdate",
  /** column name */
  Creator = "Creator",
  /** column name */
  Ctrycde = "Ctrycde",
  /** column name */
  Ctrycde1 = "Ctrycde1",
  /** column name */
  Ctrycde2 = "Ctrycde2",
  /** column name */
  Ctrycde4 = "Ctrycde4",
  /** column name */
  Datacollector = "Datacollector",
  /** column name */
  District = "District",
  /** column name */
  District1 = "District1",
  /** column name */
  Districtorcitywhereboxislocated = "Districtorcitywhereboxislocated",
  /** column name */
  Districtorcitywhereboxislocated1 = "Districtorcitywhereboxislocated1",
  /** column name */
  Division = "Division",
  /** column name */
  Doors = "Doors",
  /** column name */
  Doorsothers = "Doorsothers",
  /** column name */
  Editdate = "Editdate",
  /** column name */
  Editor = "Editor",
  /** column name */
  Email = "Email",
  /** column name */
  Email1 = "Email1",
  /** column name */
  Email3 = "Email3",
  /** column name */
  Email5 = "Email5",
  /** column name */
  Entitytype = "Entitytype",
  /** column name */
  Environment = "Environment",
  /** column name */
  Facilities = "Facilities",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Firstname3 = "Firstname3",
  /** column name */
  Flatblocknumber = "Flatblocknumber",
  /** column name */
  Flatnumber = "Flatnumber",
  /** column name */
  Floorconstruction = "Floorconstruction",
  /** column name */
  Floorconstructionothers = "Floorconstructionothers",
  /** column name */
  Floorfinish = "Floorfinish",
  /** column name */
  Floorfinishothers = "Floorfinishothers",
  /** column name */
  Folio = "Folio",
  /** column name */
  Games = "Games",
  /** column name */
  Gamesother = "Gamesother",
  /** column name */
  Gate = "Gate",
  /** column name */
  Gateother = "Gateother",
  /** column name */
  Globalid = "Globalid",
  /** column name */
  Housenumber = "Housenumber",
  /** column name */
  Hsenbr = "Hsenbr",
  /** column name */
  Id = "Id",
  /** column name */
  Id2 = "Id2",
  /** column name */
  Id3 = "Id3",
  /** column name */
  Land4 = "Land4",
  /** column name */
  Landline = "Landline",
  /** column name */
  Landline1 = "Landline1",
  /** column name */
  Landline4 = "Landline4",
  /** column name */
  Landlinecode = "Landlinecode",
  /** column name */
  Landlinecode4 = "Landlinecode4",
  /** column name */
  Landregister = "Landregister",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  LatitudeA = "LatitudeA",
  /** column name */
  Latlong = "Latlong",
  /** column name */
  Legalname = "Legalname",
  /** column name */
  Levelofcompletion = "Levelofcompletion",
  /** column name */
  Line = "Line",
  /** column name */
  Lln = "Lln",
  /** column name */
  Lln1 = "Lln1",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  LongitudeB = "LongitudeB",
  /** column name */
  Lvls = "Lvls",
  /** column name */
  Mainbusinessname = "Mainbusinessname",
  /** column name */
  Mob = "Mob",
  /** column name */
  Mob1 = "Mob1",
  /** column name */
  Mob3 = "Mob3",
  /** column name */
  Mob4 = "Mob4",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  Mobile1 = "Mobile1",
  /** column name */
  Mobile1code = "Mobile1code",
  /** column name */
  Mobile3 = "Mobile3",
  /** column name */
  Mobile3code = "Mobile3code",
  /** column name */
  Mobile4 = "Mobile4",
  /** column name */
  Mobile4code = "Mobile4code",
  /** column name */
  Mobile5 = "Mobile5",
  /** column name */
  Mobile5code = "Mobile5code",
  /** column name */
  Mobilecode = "Mobilecode",
  /** column name */
  Name2 = "Name2",
  /** column name */
  Nationalid = "Nationalid",
  /** column name */
  Nationalid2 = "Nationalid2",
  /** column name */
  Nbrofslots = "Nbrofslots",
  /** column name */
  Neighbourhoodactivities = "Neighbourhoodactivities",
  /** column name */
  Neighbourhoodstatus = "Neighbourhoodstatus",
  /** column name */
  Nin = "Nin",
  /** column name */
  Nin2 = "Nin2",
  /** column name */
  Objectid = "Objectid",
  /** column name */
  Other = "Other",
  /** column name */
  Otherfacilities = "Otherfacilities",
  /** column name */
  Otherid = "Otherid",
  /** column name */
  Otherid1 = "Otherid1",
  /** column name */
  Otherid2 = "Otherid2",
  /** column name */
  Otherid3 = "Otherid3",
  /** column name */
  Otherid4 = "Otherid4",
  /** column name */
  Otherid4nbr = "Otherid4nbr",
  /** column name */
  Otherid6 = "Otherid6",
  /** column name */
  Otherid7 = "Otherid7",
  /** column name */
  Othernames = "Othernames",
  /** column name */
  Othernames3 = "Othernames3",
  /** column name */
  Otherservices = "Otherservices",
  /** column name */
  Parish = "Parish",
  /** column name */
  Parish1 = "Parish1",
  /** column name */
  Parish2 = "Parish2",
  /** column name */
  Parking = "Parking",
  /** column name */
  Parkingsufficient = "Parkingsufficient",
  /** column name */
  Partiiindividualownershipdetails = "Partiiindividualownershipdetails",
  /** column name */
  Partinonindividualownershipdetails = "Partinonindividualownershipdetails",
  /** column name */
  Pass = "Pass",
  /** column name */
  Plotnumber = "Plotnumber",
  /** column name */
  Postalofficeboxnumber = "Postalofficeboxnumber",
  /** column name */
  Postalofficeboxnumber1 = "Postalofficeboxnumber1",
  /** column name */
  Powersupply = "Powersupply",
  /** column name */
  Propertyname = "Propertyname",
  /** column name */
  Propertysubtype = "Propertysubtype",
  /** column name */
  Propertytype = "Propertytype",
  /** column name */
  Reference = "Reference",
  /** column name */
  Relationshiptoowner = "Relationshiptoowner",
  /** column name */
  Roadfrontageorgrid = "Roadfrontageorgrid",
  /** column name */
  Roadname = "Roadname",
  /** column name */
  Roofcovering = "Roofcovering",
  /** column name */
  Roofcoveringothers = "Roofcoveringothers",
  /** column name */
  Sanitation = "Sanitation",
  /** column name */
  Sectionapropertyownersdetails = "Sectionapropertyownersdetails",
  /** column name */
  Security = "Security",
  /** column name */
  Shape = "Shape",
  /** column name */
  Shapeofplot = "Shapeofplot",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  Subcounty = "Subcounty",
  /** column name */
  Subcounty1 = "Subcounty1",
  /** column name */
  Surname = "Surname",
  /** column name */
  Surname3 = "Surname3",
  /** column name */
  Tin = "Tin",
  /** column name */
  Title = "Title",
  /** column name */
  Title1 = "Title1",
  /** column name */
  Topography = "Topography",
  /** column name */
  TotalBuiltUpArea = "TotalBuiltUpArea",
  /** column name */
  TotalGrossArea = "TotalGrossArea",
  /** column name */
  Typeofaccess = "Typeofaccess",
  /** column name */
  Typeofbuilding = "Typeofbuilding",
  /** column name */
  Typeofcompany = "Typeofcompany",
  /** column name */
  Typeoflandinterest = "Typeoflandinterest",
  /** column name */
  Username = "Username",
  /** column name */
  Verticalcirculation = "Verticalcirculation",
  /** column name */
  Village = "Village",
  /** column name */
  Village1 = "Village1",
  /** column name */
  Village2 = "Village2",
  /** column name */
  Volume = "Volume",
  /** column name */
  Wallfinish = "Wallfinish",
  /** column name */
  Wallfinishothers = "Wallfinishothers",
  /** column name */
  Walls = "Walls",
  /** column name */
  Wallsothers = "Wallsothers",
  /** column name */
  Wateraccessibility = "Wateraccessibility",
  /** column name */
  WhoUpdated = "WhoUpdated",
  /** column name */
  Windows = "Windows",
  /** column name */
  Windowsothers = "Windowsothers",
}

/** input type for updating data in table "rtcs_db.PH_kcca_RawProperties2" */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Set_Input = {
  Access?: Maybe<Scalars["String"]>;
  Agent?: Maybe<Scalars["String"]>;
  Alternative?: Maybe<Scalars["String"]>;
  Alternativeemailaddress?: Maybe<Scalars["String"]>;
  Alternativefirstname?: Maybe<Scalars["String"]>;
  Alternativelandlinenumber?: Maybe<Scalars["float8"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["float8"]>;
  Alternativeothername?: Maybe<Scalars["String"]>;
  Alternativephonenumber1?: Maybe<Scalars["float8"]>;
  Alternativephonenumber1code?: Maybe<Scalars["String"]>;
  Alternativephonenumber2?: Maybe<Scalars["String"]>;
  Alternativephonenumber2code?: Maybe<Scalars["String"]>;
  Alternativesurname?: Maybe<Scalars["String"]>;
  Altphn1?: Maybe<Scalars["float8"]>;
  Altphn2?: Maybe<Scalars["float8"]>;
  Anyother?: Maybe<Scalars["String"]>;
  AreaCount?: Maybe<Scalars["String"]>;
  Authorizedcontactperson?: Maybe<Scalars["String"]>;
  Bldg2?: Maybe<Scalars["String"]>;
  Blockname?: Maybe<Scalars["String"]>;
  Blocknumber?: Maybe<Scalars["String"]>;
  Blockother?: Maybe<Scalars["String"]>;
  Boundary?: Maybe<Scalars["String"]>;
  Boundaryfenceother?: Maybe<Scalars["String"]>;
  Buildingcondition?: Maybe<Scalars["String"]>;
  Buildingname?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["bigint"]>;
  CamvSerial?: Maybe<Scalars["String"]>;
  Ceiling?: Maybe<Scalars["String"]>;
  Ceilingothers?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["String"]>;
  Coin1?: Maybe<Scalars["String"]>;
  Comment?: Maybe<Scalars["String"]>;
  Compound?: Maybe<Scalars["String"]>;
  Compoundother?: Maybe<Scalars["String"]>;
  Contactdetailsofdirector?: Maybe<Scalars["String"]>;
  Country?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  County1?: Maybe<Scalars["String"]>;
  Creationdate?: Maybe<Scalars["timestamptz"]>;
  Creator?: Maybe<Scalars["String"]>;
  Ctrycde?: Maybe<Scalars["String"]>;
  Ctrycde1?: Maybe<Scalars["String"]>;
  Ctrycde2?: Maybe<Scalars["String"]>;
  Ctrycde4?: Maybe<Scalars["String"]>;
  Datacollector?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  District1?: Maybe<Scalars["String"]>;
  Districtorcitywhereboxislocated?: Maybe<Scalars["String"]>;
  Districtorcitywhereboxislocated1?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Doors?: Maybe<Scalars["String"]>;
  Doorsothers?: Maybe<Scalars["String"]>;
  Editdate?: Maybe<Scalars["timestamptz"]>;
  Editor?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Email1?: Maybe<Scalars["String"]>;
  Email3?: Maybe<Scalars["String"]>;
  Email5?: Maybe<Scalars["String"]>;
  Entitytype?: Maybe<Scalars["String"]>;
  Environment?: Maybe<Scalars["String"]>;
  Facilities?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Firstname3?: Maybe<Scalars["String"]>;
  Flatblocknumber?: Maybe<Scalars["String"]>;
  Flatnumber?: Maybe<Scalars["String"]>;
  Floorconstruction?: Maybe<Scalars["String"]>;
  Floorconstructionothers?: Maybe<Scalars["String"]>;
  Floorfinish?: Maybe<Scalars["String"]>;
  Floorfinishothers?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Games?: Maybe<Scalars["String"]>;
  Gamesother?: Maybe<Scalars["String"]>;
  Gate?: Maybe<Scalars["String"]>;
  Gateother?: Maybe<Scalars["String"]>;
  Globalid?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Hsenbr?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Id2?: Maybe<Scalars["String"]>;
  Id3?: Maybe<Scalars["bigint"]>;
  Land4?: Maybe<Scalars["float8"]>;
  Landline?: Maybe<Scalars["String"]>;
  Landline1?: Maybe<Scalars["String"]>;
  Landline4?: Maybe<Scalars["String"]>;
  Landlinecode?: Maybe<Scalars["String"]>;
  Landlinecode4?: Maybe<Scalars["String"]>;
  Landregister?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  LatitudeA?: Maybe<Scalars["float8"]>;
  Latlong?: Maybe<Scalars["String"]>;
  Legalname?: Maybe<Scalars["String"]>;
  Levelofcompletion?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Lln?: Maybe<Scalars["String"]>;
  Lln1?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  LongitudeB?: Maybe<Scalars["float8"]>;
  Lvls?: Maybe<Scalars["String"]>;
  Mainbusinessname?: Maybe<Scalars["String"]>;
  Mob?: Maybe<Scalars["float8"]>;
  Mob1?: Maybe<Scalars["float8"]>;
  Mob3?: Maybe<Scalars["String"]>;
  Mob4?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  Mobile1?: Maybe<Scalars["String"]>;
  Mobile1code?: Maybe<Scalars["String"]>;
  Mobile3?: Maybe<Scalars["String"]>;
  Mobile3code?: Maybe<Scalars["String"]>;
  Mobile4?: Maybe<Scalars["String"]>;
  Mobile4code?: Maybe<Scalars["String"]>;
  Mobile5?: Maybe<Scalars["String"]>;
  Mobile5code?: Maybe<Scalars["String"]>;
  Mobilecode?: Maybe<Scalars["String"]>;
  Name2?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["float8"]>;
  Nationalid2?: Maybe<Scalars["float8"]>;
  Nbrofslots?: Maybe<Scalars["String"]>;
  Neighbourhoodactivities?: Maybe<Scalars["String"]>;
  Neighbourhoodstatus?: Maybe<Scalars["String"]>;
  Nin?: Maybe<Scalars["String"]>;
  Nin2?: Maybe<Scalars["String"]>;
  Objectid?: Maybe<Scalars["float8"]>;
  Other?: Maybe<Scalars["String"]>;
  Otherfacilities?: Maybe<Scalars["String"]>;
  Otherid?: Maybe<Scalars["String"]>;
  Otherid1?: Maybe<Scalars["String"]>;
  Otherid2?: Maybe<Scalars["String"]>;
  Otherid3?: Maybe<Scalars["float8"]>;
  Otherid4?: Maybe<Scalars["String"]>;
  Otherid4nbr?: Maybe<Scalars["String"]>;
  Otherid6?: Maybe<Scalars["String"]>;
  Otherid7?: Maybe<Scalars["float8"]>;
  Othernames?: Maybe<Scalars["String"]>;
  Othernames3?: Maybe<Scalars["String"]>;
  Otherservices?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Parish1?: Maybe<Scalars["String"]>;
  Parish2?: Maybe<Scalars["String"]>;
  Parking?: Maybe<Scalars["String"]>;
  Parkingsufficient?: Maybe<Scalars["String"]>;
  Partiiindividualownershipdetails?: Maybe<Scalars["String"]>;
  Partinonindividualownershipdetails?: Maybe<Scalars["String"]>;
  Pass?: Maybe<Scalars["float8"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Postalofficeboxnumber?: Maybe<Scalars["String"]>;
  Postalofficeboxnumber1?: Maybe<Scalars["String"]>;
  Powersupply?: Maybe<Scalars["String"]>;
  Propertyname?: Maybe<Scalars["String"]>;
  Propertysubtype?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Reference?: Maybe<Scalars["bigint"]>;
  Relationshiptoowner?: Maybe<Scalars["String"]>;
  Roadfrontageorgrid?: Maybe<Scalars["String"]>;
  Roadname?: Maybe<Scalars["String"]>;
  Roofcovering?: Maybe<Scalars["String"]>;
  Roofcoveringothers?: Maybe<Scalars["String"]>;
  Sanitation?: Maybe<Scalars["String"]>;
  Sectionapropertyownersdetails?: Maybe<Scalars["String"]>;
  Security?: Maybe<Scalars["String"]>;
  Shape?: Maybe<Scalars["String"]>;
  Shapeofplot?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Subcounty1?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Surname3?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Title1?: Maybe<Scalars["String"]>;
  Topography?: Maybe<Scalars["String"]>;
  TotalBuiltUpArea?: Maybe<Scalars["float8"]>;
  TotalGrossArea?: Maybe<Scalars["float8"]>;
  Typeofaccess?: Maybe<Scalars["String"]>;
  Typeofbuilding?: Maybe<Scalars["String"]>;
  Typeofcompany?: Maybe<Scalars["String"]>;
  Typeoflandinterest?: Maybe<Scalars["String"]>;
  Username?: Maybe<Scalars["float8"]>;
  Verticalcirculation?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  Village1?: Maybe<Scalars["String"]>;
  Village2?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  Wallfinish?: Maybe<Scalars["String"]>;
  Wallfinishothers?: Maybe<Scalars["String"]>;
  Walls?: Maybe<Scalars["String"]>;
  Wallsothers?: Maybe<Scalars["String"]>;
  Wateraccessibility?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Windows?: Maybe<Scalars["String"]>;
  Windowsothers?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_RawProperties2_stddev_fields";
  Alternativelandlinenumber?: Maybe<Scalars["Float"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["Float"]>;
  Alternativephonenumber1?: Maybe<Scalars["Float"]>;
  Altphn1?: Maybe<Scalars["Float"]>;
  Altphn2?: Maybe<Scalars["Float"]>;
  CamvId?: Maybe<Scalars["Float"]>;
  Id3?: Maybe<Scalars["Float"]>;
  Land4?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  LatitudeA?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LongitudeB?: Maybe<Scalars["Float"]>;
  Mob?: Maybe<Scalars["Float"]>;
  Mob1?: Maybe<Scalars["Float"]>;
  Nationalid?: Maybe<Scalars["Float"]>;
  Nationalid2?: Maybe<Scalars["Float"]>;
  Objectid?: Maybe<Scalars["Float"]>;
  Otherid3?: Maybe<Scalars["Float"]>;
  Otherid7?: Maybe<Scalars["Float"]>;
  Pass?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
  TotalBuiltUpArea?: Maybe<Scalars["Float"]>;
  TotalGrossArea?: Maybe<Scalars["Float"]>;
  Username?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_RawProperties2" */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Stddev_Order_By = {
  Alternativelandlinenumber?: Maybe<Order_By>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Order_By>;
  Alternativephonenumber1?: Maybe<Order_By>;
  Altphn1?: Maybe<Order_By>;
  Altphn2?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  Id3?: Maybe<Order_By>;
  Land4?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  LatitudeA?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeB?: Maybe<Order_By>;
  Mob?: Maybe<Order_By>;
  Mob1?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Nationalid2?: Maybe<Order_By>;
  Objectid?: Maybe<Order_By>;
  Otherid3?: Maybe<Order_By>;
  Otherid7?: Maybe<Order_By>;
  Pass?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TotalBuiltUpArea?: Maybe<Order_By>;
  TotalGrossArea?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_RawProperties2_stddev_pop_fields";
  Alternativelandlinenumber?: Maybe<Scalars["Float"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["Float"]>;
  Alternativephonenumber1?: Maybe<Scalars["Float"]>;
  Altphn1?: Maybe<Scalars["Float"]>;
  Altphn2?: Maybe<Scalars["Float"]>;
  CamvId?: Maybe<Scalars["Float"]>;
  Id3?: Maybe<Scalars["Float"]>;
  Land4?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  LatitudeA?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LongitudeB?: Maybe<Scalars["Float"]>;
  Mob?: Maybe<Scalars["Float"]>;
  Mob1?: Maybe<Scalars["Float"]>;
  Nationalid?: Maybe<Scalars["Float"]>;
  Nationalid2?: Maybe<Scalars["Float"]>;
  Objectid?: Maybe<Scalars["Float"]>;
  Otherid3?: Maybe<Scalars["Float"]>;
  Otherid7?: Maybe<Scalars["Float"]>;
  Pass?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
  TotalBuiltUpArea?: Maybe<Scalars["Float"]>;
  TotalGrossArea?: Maybe<Scalars["Float"]>;
  Username?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_RawProperties2" */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Stddev_Pop_Order_By = {
  Alternativelandlinenumber?: Maybe<Order_By>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Order_By>;
  Alternativephonenumber1?: Maybe<Order_By>;
  Altphn1?: Maybe<Order_By>;
  Altphn2?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  Id3?: Maybe<Order_By>;
  Land4?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  LatitudeA?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeB?: Maybe<Order_By>;
  Mob?: Maybe<Order_By>;
  Mob1?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Nationalid2?: Maybe<Order_By>;
  Objectid?: Maybe<Order_By>;
  Otherid3?: Maybe<Order_By>;
  Otherid7?: Maybe<Order_By>;
  Pass?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TotalBuiltUpArea?: Maybe<Order_By>;
  TotalGrossArea?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_RawProperties2_stddev_samp_fields";
  Alternativelandlinenumber?: Maybe<Scalars["Float"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["Float"]>;
  Alternativephonenumber1?: Maybe<Scalars["Float"]>;
  Altphn1?: Maybe<Scalars["Float"]>;
  Altphn2?: Maybe<Scalars["Float"]>;
  CamvId?: Maybe<Scalars["Float"]>;
  Id3?: Maybe<Scalars["Float"]>;
  Land4?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  LatitudeA?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LongitudeB?: Maybe<Scalars["Float"]>;
  Mob?: Maybe<Scalars["Float"]>;
  Mob1?: Maybe<Scalars["Float"]>;
  Nationalid?: Maybe<Scalars["Float"]>;
  Nationalid2?: Maybe<Scalars["Float"]>;
  Objectid?: Maybe<Scalars["Float"]>;
  Otherid3?: Maybe<Scalars["Float"]>;
  Otherid7?: Maybe<Scalars["Float"]>;
  Pass?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
  TotalBuiltUpArea?: Maybe<Scalars["Float"]>;
  TotalGrossArea?: Maybe<Scalars["Float"]>;
  Username?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_RawProperties2" */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Stddev_Samp_Order_By = {
  Alternativelandlinenumber?: Maybe<Order_By>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Order_By>;
  Alternativephonenumber1?: Maybe<Order_By>;
  Altphn1?: Maybe<Order_By>;
  Altphn2?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  Id3?: Maybe<Order_By>;
  Land4?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  LatitudeA?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeB?: Maybe<Order_By>;
  Mob?: Maybe<Order_By>;
  Mob1?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Nationalid2?: Maybe<Order_By>;
  Objectid?: Maybe<Order_By>;
  Otherid3?: Maybe<Order_By>;
  Otherid7?: Maybe<Order_By>;
  Pass?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TotalBuiltUpArea?: Maybe<Order_By>;
  TotalGrossArea?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_RawProperties2_sum_fields";
  Alternativelandlinenumber?: Maybe<Scalars["float8"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["float8"]>;
  Alternativephonenumber1?: Maybe<Scalars["float8"]>;
  Altphn1?: Maybe<Scalars["float8"]>;
  Altphn2?: Maybe<Scalars["float8"]>;
  CamvId?: Maybe<Scalars["bigint"]>;
  Id3?: Maybe<Scalars["bigint"]>;
  Land4?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  LatitudeA?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LongitudeB?: Maybe<Scalars["float8"]>;
  Mob?: Maybe<Scalars["float8"]>;
  Mob1?: Maybe<Scalars["float8"]>;
  Nationalid?: Maybe<Scalars["float8"]>;
  Nationalid2?: Maybe<Scalars["float8"]>;
  Objectid?: Maybe<Scalars["float8"]>;
  Otherid3?: Maybe<Scalars["float8"]>;
  Otherid7?: Maybe<Scalars["float8"]>;
  Pass?: Maybe<Scalars["float8"]>;
  Reference?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Tin?: Maybe<Scalars["float8"]>;
  TotalBuiltUpArea?: Maybe<Scalars["float8"]>;
  TotalGrossArea?: Maybe<Scalars["float8"]>;
  Username?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_RawProperties2" */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Sum_Order_By = {
  Alternativelandlinenumber?: Maybe<Order_By>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Order_By>;
  Alternativephonenumber1?: Maybe<Order_By>;
  Altphn1?: Maybe<Order_By>;
  Altphn2?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  Id3?: Maybe<Order_By>;
  Land4?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  LatitudeA?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeB?: Maybe<Order_By>;
  Mob?: Maybe<Order_By>;
  Mob1?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Nationalid2?: Maybe<Order_By>;
  Objectid?: Maybe<Order_By>;
  Otherid3?: Maybe<Order_By>;
  Otherid7?: Maybe<Order_By>;
  Pass?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TotalBuiltUpArea?: Maybe<Order_By>;
  TotalGrossArea?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_RawProperties2_var_pop_fields";
  Alternativelandlinenumber?: Maybe<Scalars["Float"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["Float"]>;
  Alternativephonenumber1?: Maybe<Scalars["Float"]>;
  Altphn1?: Maybe<Scalars["Float"]>;
  Altphn2?: Maybe<Scalars["Float"]>;
  CamvId?: Maybe<Scalars["Float"]>;
  Id3?: Maybe<Scalars["Float"]>;
  Land4?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  LatitudeA?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LongitudeB?: Maybe<Scalars["Float"]>;
  Mob?: Maybe<Scalars["Float"]>;
  Mob1?: Maybe<Scalars["Float"]>;
  Nationalid?: Maybe<Scalars["Float"]>;
  Nationalid2?: Maybe<Scalars["Float"]>;
  Objectid?: Maybe<Scalars["Float"]>;
  Otherid3?: Maybe<Scalars["Float"]>;
  Otherid7?: Maybe<Scalars["Float"]>;
  Pass?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
  TotalBuiltUpArea?: Maybe<Scalars["Float"]>;
  TotalGrossArea?: Maybe<Scalars["Float"]>;
  Username?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_RawProperties2" */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Var_Pop_Order_By = {
  Alternativelandlinenumber?: Maybe<Order_By>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Order_By>;
  Alternativephonenumber1?: Maybe<Order_By>;
  Altphn1?: Maybe<Order_By>;
  Altphn2?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  Id3?: Maybe<Order_By>;
  Land4?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  LatitudeA?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeB?: Maybe<Order_By>;
  Mob?: Maybe<Order_By>;
  Mob1?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Nationalid2?: Maybe<Order_By>;
  Objectid?: Maybe<Order_By>;
  Otherid3?: Maybe<Order_By>;
  Otherid7?: Maybe<Order_By>;
  Pass?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TotalBuiltUpArea?: Maybe<Order_By>;
  TotalGrossArea?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_RawProperties2_var_samp_fields";
  Alternativelandlinenumber?: Maybe<Scalars["Float"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["Float"]>;
  Alternativephonenumber1?: Maybe<Scalars["Float"]>;
  Altphn1?: Maybe<Scalars["Float"]>;
  Altphn2?: Maybe<Scalars["Float"]>;
  CamvId?: Maybe<Scalars["Float"]>;
  Id3?: Maybe<Scalars["Float"]>;
  Land4?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  LatitudeA?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LongitudeB?: Maybe<Scalars["Float"]>;
  Mob?: Maybe<Scalars["Float"]>;
  Mob1?: Maybe<Scalars["Float"]>;
  Nationalid?: Maybe<Scalars["Float"]>;
  Nationalid2?: Maybe<Scalars["Float"]>;
  Objectid?: Maybe<Scalars["Float"]>;
  Otherid3?: Maybe<Scalars["Float"]>;
  Otherid7?: Maybe<Scalars["Float"]>;
  Pass?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
  TotalBuiltUpArea?: Maybe<Scalars["Float"]>;
  TotalGrossArea?: Maybe<Scalars["Float"]>;
  Username?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_RawProperties2" */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Var_Samp_Order_By = {
  Alternativelandlinenumber?: Maybe<Order_By>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Order_By>;
  Alternativephonenumber1?: Maybe<Order_By>;
  Altphn1?: Maybe<Order_By>;
  Altphn2?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  Id3?: Maybe<Order_By>;
  Land4?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  LatitudeA?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeB?: Maybe<Order_By>;
  Mob?: Maybe<Order_By>;
  Mob1?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Nationalid2?: Maybe<Order_By>;
  Objectid?: Maybe<Order_By>;
  Otherid3?: Maybe<Order_By>;
  Otherid7?: Maybe<Order_By>;
  Pass?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TotalBuiltUpArea?: Maybe<Order_By>;
  TotalGrossArea?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_RawProperties2_variance_fields";
  Alternativelandlinenumber?: Maybe<Scalars["Float"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["Float"]>;
  Alternativephonenumber1?: Maybe<Scalars["Float"]>;
  Altphn1?: Maybe<Scalars["Float"]>;
  Altphn2?: Maybe<Scalars["Float"]>;
  CamvId?: Maybe<Scalars["Float"]>;
  Id3?: Maybe<Scalars["Float"]>;
  Land4?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  LatitudeA?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LongitudeB?: Maybe<Scalars["Float"]>;
  Mob?: Maybe<Scalars["Float"]>;
  Mob1?: Maybe<Scalars["Float"]>;
  Nationalid?: Maybe<Scalars["Float"]>;
  Nationalid2?: Maybe<Scalars["Float"]>;
  Objectid?: Maybe<Scalars["Float"]>;
  Otherid3?: Maybe<Scalars["Float"]>;
  Otherid7?: Maybe<Scalars["Float"]>;
  Pass?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
  TotalBuiltUpArea?: Maybe<Scalars["Float"]>;
  TotalGrossArea?: Maybe<Scalars["Float"]>;
  Username?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_RawProperties2" */
export type Rtcs_Db_Ph_Kcca_RawProperties2_Variance_Order_By = {
  Alternativelandlinenumber?: Maybe<Order_By>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Order_By>;
  Alternativephonenumber1?: Maybe<Order_By>;
  Altphn1?: Maybe<Order_By>;
  Altphn2?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  Id3?: Maybe<Order_By>;
  Land4?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  LatitudeA?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeB?: Maybe<Order_By>;
  Mob?: Maybe<Order_By>;
  Mob1?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Nationalid2?: Maybe<Order_By>;
  Objectid?: Maybe<Order_By>;
  Otherid3?: Maybe<Order_By>;
  Otherid7?: Maybe<Order_By>;
  Pass?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TotalBuiltUpArea?: Maybe<Order_By>;
  TotalGrossArea?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_ResidentialProperties" */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties = {
  __typename?: "rtcs_db_PH_kcca_ResidentialProperties";
  AccomodationBedrooms?: Maybe<Scalars["String"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["String"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RippleNamiPropertySubtype?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_ResidentialProperties" */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_ResidentialProperties_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_ResidentialProperties>;
};

/** aggregate fields of "rtcs_db.PH_kcca_ResidentialProperties" */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_ResidentialProperties_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_ResidentialProperties" */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_ResidentialProperties_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_ResidentialProperties" */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_ResidentialProperties" */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_ResidentialProperties_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_ResidentialProperties_avg_fields";
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_ResidentialProperties" */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Avg_Order_By = {
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_ResidentialProperties". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Bool_Exp = {
  AccomodationBedrooms?: Maybe<String_Comparison_Exp>;
  AccomodationBuiltUpArea?: Maybe<String_Comparison_Exp>;
  AccomodationOthers?: Maybe<String_Comparison_Exp>;
  Address?: Maybe<String_Comparison_Exp>;
  BlockNo?: Maybe<String_Comparison_Exp>;
  CamvId?: Maybe<String_Comparison_Exp>;
  CustomerId?: Maybe<Bigint_Comparison_Exp>;
  DerivedNoOfBedrooms?: Maybe<Float8_Comparison_Exp>;
  DivisionId?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  GrossValue?: Maybe<String_Comparison_Exp>;
  Grossrentvalueadjusted?: Maybe<Float8_Comparison_Exp>;
  HouseNo?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  ParishId?: Maybe<Float8_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyNo?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatusId?: Maybe<Float8_Comparison_Exp>;
  PropertyType?: Maybe<String_Comparison_Exp>;
  PropertyTypeId?: Maybe<Float8_Comparison_Exp>;
  Propertyrentedstatus?: Maybe<String_Comparison_Exp>;
  RateableValue?: Maybe<Float8_Comparison_Exp>;
  RippleNamiPropertySubtype?: Maybe<String_Comparison_Exp>;
  SquareMeters?: Maybe<Float8_Comparison_Exp>;
  StreetId?: Maybe<String_Comparison_Exp>;
  VillageId?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_ResidentialProperties" */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Inc_Input = {
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_ResidentialProperties" */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Insert_Input = {
  AccomodationBedrooms?: Maybe<Scalars["String"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["String"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RippleNamiPropertySubtype?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_ResidentialProperties_max_fields";
  AccomodationBedrooms?: Maybe<Scalars["String"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["String"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RippleNamiPropertySubtype?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_ResidentialProperties" */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Max_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AccomodationOthers?: Maybe<Order_By>;
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RippleNamiPropertySubtype?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_ResidentialProperties_min_fields";
  AccomodationBedrooms?: Maybe<Scalars["String"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["String"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RippleNamiPropertySubtype?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_ResidentialProperties" */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Min_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AccomodationOthers?: Maybe<Order_By>;
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RippleNamiPropertySubtype?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_ResidentialProperties" */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_ResidentialProperties_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_ResidentialProperties>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_ResidentialProperties" */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_ResidentialProperties_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_ResidentialProperties" */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AccomodationOthers?: Maybe<Order_By>;
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RippleNamiPropertySubtype?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_ResidentialProperties" */
export enum Rtcs_Db_Ph_Kcca_ResidentialProperties_Select_Column {
  /** column name */
  AccomodationBedrooms = "AccomodationBedrooms",
  /** column name */
  AccomodationBuiltUpArea = "AccomodationBuiltUpArea",
  /** column name */
  AccomodationOthers = "AccomodationOthers",
  /** column name */
  Address = "Address",
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  CamvId = "CamvId",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DerivedNoOfBedrooms = "DerivedNoOfBedrooms",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  File = "File",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  GrossValue = "GrossValue",
  /** column name */
  Grossrentvalueadjusted = "Grossrentvalueadjusted",
  /** column name */
  HouseNo = "HouseNo",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyNo = "PropertyNo",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertyType = "PropertyType",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  Propertyrentedstatus = "Propertyrentedstatus",
  /** column name */
  RateableValue = "RateableValue",
  /** column name */
  RippleNamiPropertySubtype = "RippleNamiPropertySubtype",
  /** column name */
  SquareMeters = "SquareMeters",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  VillageId = "VillageId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_ResidentialProperties" */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Set_Input = {
  AccomodationBedrooms?: Maybe<Scalars["String"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["String"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RippleNamiPropertySubtype?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_ResidentialProperties_stddev_fields";
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_ResidentialProperties" */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Stddev_Order_By = {
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_ResidentialProperties_stddev_pop_fields";
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_ResidentialProperties" */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Stddev_Pop_Order_By = {
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_ResidentialProperties_stddev_samp_fields";
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_ResidentialProperties" */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Stddev_Samp_Order_By = {
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_ResidentialProperties_sum_fields";
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_ResidentialProperties" */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Sum_Order_By = {
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_ResidentialProperties_var_pop_fields";
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_ResidentialProperties" */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Var_Pop_Order_By = {
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_ResidentialProperties_var_samp_fields";
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_ResidentialProperties" */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Var_Samp_Order_By = {
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_ResidentialProperties_variance_fields";
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_ResidentialProperties" */
export type Rtcs_Db_Ph_Kcca_ResidentialProperties_Variance_Order_By = {
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_RevenueType" */
export type Rtcs_Db_Ph_Kcca_RevenueType = {
  __typename?: "rtcs_db_PH_kcca_RevenueType";
  Code?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubRevenue?: Maybe<Scalars["String"]>;
  SystemCode?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_RevenueType" */
export type Rtcs_Db_Ph_Kcca_RevenueType_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_RevenueType_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_RevenueType>;
};

/** aggregate fields of "rtcs_db.PH_kcca_RevenueType" */
export type Rtcs_Db_Ph_Kcca_RevenueType_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_RevenueType_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_RevenueType" */
export type Rtcs_Db_Ph_Kcca_RevenueType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_RevenueType_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_RevenueType" */
export type Rtcs_Db_Ph_Kcca_RevenueType_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_RevenueType" */
export type Rtcs_Db_Ph_Kcca_RevenueType_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_RevenueType_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_RevenueType_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_RevenueType_avg_fields";
  Code?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_RevenueType" */
export type Rtcs_Db_Ph_Kcca_RevenueType_Avg_Order_By = {
  Code?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_RevenueType". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_RevenueType_Bool_Exp = {
  Code?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Float8_Comparison_Exp>;
  SubRevenue?: Maybe<String_Comparison_Exp>;
  SystemCode?: Maybe<String_Comparison_Exp>;
  Type?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_RevenueType" */
export type Rtcs_Db_Ph_Kcca_RevenueType_Inc_Input = {
  Code?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_RevenueType" */
export type Rtcs_Db_Ph_Kcca_RevenueType_Insert_Input = {
  Code?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubRevenue?: Maybe<Scalars["String"]>;
  SystemCode?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_RevenueType_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_RevenueType_max_fields";
  Code?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubRevenue?: Maybe<Scalars["String"]>;
  SystemCode?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_RevenueType" */
export type Rtcs_Db_Ph_Kcca_RevenueType_Max_Order_By = {
  Code?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubRevenue?: Maybe<Order_By>;
  SystemCode?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_RevenueType_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_RevenueType_min_fields";
  Code?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubRevenue?: Maybe<Scalars["String"]>;
  SystemCode?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_RevenueType" */
export type Rtcs_Db_Ph_Kcca_RevenueType_Min_Order_By = {
  Code?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubRevenue?: Maybe<Order_By>;
  SystemCode?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_RevenueType" */
export type Rtcs_Db_Ph_Kcca_RevenueType_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_RevenueType_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_RevenueType>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_RevenueType" */
export type Rtcs_Db_Ph_Kcca_RevenueType_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_RevenueType_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_RevenueType" */
export type Rtcs_Db_Ph_Kcca_RevenueType_Order_By = {
  Code?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubRevenue?: Maybe<Order_By>;
  SystemCode?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_RevenueType" */
export enum Rtcs_Db_Ph_Kcca_RevenueType_Select_Column {
  /** column name */
  Code = "Code",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  SubRevenue = "SubRevenue",
  /** column name */
  SystemCode = "SystemCode",
  /** column name */
  Type = "Type",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_RevenueType" */
export type Rtcs_Db_Ph_Kcca_RevenueType_Set_Input = {
  Code?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubRevenue?: Maybe<Scalars["String"]>;
  SystemCode?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_RevenueType_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_RevenueType_stddev_fields";
  Code?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_RevenueType" */
export type Rtcs_Db_Ph_Kcca_RevenueType_Stddev_Order_By = {
  Code?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_RevenueType_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_RevenueType_stddev_pop_fields";
  Code?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_RevenueType" */
export type Rtcs_Db_Ph_Kcca_RevenueType_Stddev_Pop_Order_By = {
  Code?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_RevenueType_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_RevenueType_stddev_samp_fields";
  Code?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_RevenueType" */
export type Rtcs_Db_Ph_Kcca_RevenueType_Stddev_Samp_Order_By = {
  Code?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_RevenueType_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_RevenueType_sum_fields";
  Code?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_RevenueType" */
export type Rtcs_Db_Ph_Kcca_RevenueType_Sum_Order_By = {
  Code?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_RevenueType_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_RevenueType_var_pop_fields";
  Code?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_RevenueType" */
export type Rtcs_Db_Ph_Kcca_RevenueType_Var_Pop_Order_By = {
  Code?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_RevenueType_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_RevenueType_var_samp_fields";
  Code?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_RevenueType" */
export type Rtcs_Db_Ph_Kcca_RevenueType_Var_Samp_Order_By = {
  Code?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_RevenueType_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_RevenueType_variance_fields";
  Code?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_RevenueType" */
export type Rtcs_Db_Ph_Kcca_RevenueType_Variance_Order_By = {
  Code?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_SubCounty" */
export type Rtcs_Db_Ph_Kcca_SubCounty = {
  __typename?: "rtcs_db_PH_kcca_SubCounty";
  CountryId?: Maybe<Scalars["bigint"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_SubCounty" */
export type Rtcs_Db_Ph_Kcca_SubCounty_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_SubCounty_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_SubCounty>;
};

/** aggregate fields of "rtcs_db.PH_kcca_SubCounty" */
export type Rtcs_Db_Ph_Kcca_SubCounty_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_SubCounty_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_SubCounty" */
export type Rtcs_Db_Ph_Kcca_SubCounty_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_SubCounty_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_SubCounty" */
export type Rtcs_Db_Ph_Kcca_SubCounty_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_SubCounty" */
export type Rtcs_Db_Ph_Kcca_SubCounty_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_SubCounty_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_SubCounty_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_SubCounty_avg_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_SubCounty" */
export type Rtcs_Db_Ph_Kcca_SubCounty_Avg_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_SubCounty". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_SubCounty_Bool_Exp = {
  CountryId?: Maybe<Bigint_Comparison_Exp>;
  CountyId?: Maybe<Bigint_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  DistrictId?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  ModifiedBy?: Maybe<Float8_Comparison_Exp>;
  ModifiedDate?: Maybe<Timestamptz_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_SubCounty" */
export type Rtcs_Db_Ph_Kcca_SubCounty_Inc_Input = {
  CountryId?: Maybe<Scalars["bigint"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_SubCounty" */
export type Rtcs_Db_Ph_Kcca_SubCounty_Insert_Input = {
  CountryId?: Maybe<Scalars["bigint"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_SubCounty_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_SubCounty_max_fields";
  CountryId?: Maybe<Scalars["bigint"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_SubCounty" */
export type Rtcs_Db_Ph_Kcca_SubCounty_Max_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_SubCounty_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_SubCounty_min_fields";
  CountryId?: Maybe<Scalars["bigint"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_SubCounty" */
export type Rtcs_Db_Ph_Kcca_SubCounty_Min_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_SubCounty" */
export type Rtcs_Db_Ph_Kcca_SubCounty_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_SubCounty_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_SubCounty>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_SubCounty" */
export type Rtcs_Db_Ph_Kcca_SubCounty_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_SubCounty_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_SubCounty" */
export type Rtcs_Db_Ph_Kcca_SubCounty_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_SubCounty" */
export enum Rtcs_Db_Ph_Kcca_SubCounty_Select_Column {
  /** column name */
  CountryId = "CountryId",
  /** column name */
  CountyId = "CountyId",
  /** column name */
  Description = "Description",
  /** column name */
  DistrictId = "DistrictId",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_SubCounty" */
export type Rtcs_Db_Ph_Kcca_SubCounty_Set_Input = {
  CountryId?: Maybe<Scalars["bigint"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_SubCounty_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_SubCounty_stddev_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_SubCounty" */
export type Rtcs_Db_Ph_Kcca_SubCounty_Stddev_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_SubCounty_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_SubCounty_stddev_pop_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_SubCounty" */
export type Rtcs_Db_Ph_Kcca_SubCounty_Stddev_Pop_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_SubCounty_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_SubCounty_stddev_samp_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_SubCounty" */
export type Rtcs_Db_Ph_Kcca_SubCounty_Stddev_Samp_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_SubCounty_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_SubCounty_sum_fields";
  CountryId?: Maybe<Scalars["bigint"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_SubCounty" */
export type Rtcs_Db_Ph_Kcca_SubCounty_Sum_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_SubCounty_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_SubCounty_var_pop_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_SubCounty" */
export type Rtcs_Db_Ph_Kcca_SubCounty_Var_Pop_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_SubCounty_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_SubCounty_var_samp_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_SubCounty" */
export type Rtcs_Db_Ph_Kcca_SubCounty_Var_Samp_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_SubCounty_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_SubCounty_variance_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_SubCounty" */
export type Rtcs_Db_Ph_Kcca_SubCounty_Variance_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion = {
  __typename?: "rtcs_db_PH_kcca_SubpropertyTypeConversion";
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RnCategory?: Maybe<Scalars["String"]>;
  SubpropertyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_SubpropertyTypeConversion_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion>;
};

/** aggregate fields of "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_SubpropertyTypeConversion_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_SubpropertyTypeConversion_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
  SubpropertyId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Avg_Order_By = {
  Line?: Maybe<Order_By>;
  SubpropertyId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_SubpropertyTypeConversion". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  RnCategory?: Maybe<String_Comparison_Exp>;
  SubpropertyId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
  SubpropertyId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RnCategory?: Maybe<Scalars["String"]>;
  SubpropertyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_SubpropertyTypeConversion_max_fields";
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RnCategory?: Maybe<Scalars["String"]>;
  SubpropertyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Max_Order_By = {
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RnCategory?: Maybe<Order_By>;
  SubpropertyId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_SubpropertyTypeConversion_min_fields";
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RnCategory?: Maybe<Scalars["String"]>;
  SubpropertyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Min_Order_By = {
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RnCategory?: Maybe<Order_By>;
  SubpropertyId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_SubpropertyTypeConversion_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Order_By = {
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RnCategory?: Maybe<Order_By>;
  SubpropertyId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
export enum Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  RnCategory = "RnCategory",
  /** column name */
  SubpropertyId = "SubpropertyId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RnCategory?: Maybe<Scalars["String"]>;
  SubpropertyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_SubpropertyTypeConversion_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
  SubpropertyId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
  SubpropertyId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_SubpropertyTypeConversion_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  SubpropertyId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  SubpropertyId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_SubpropertyTypeConversion_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  SubpropertyId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  SubpropertyId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_SubpropertyTypeConversion_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
  SubpropertyId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Sum_Order_By = {
  Line?: Maybe<Order_By>;
  SubpropertyId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_SubpropertyTypeConversion_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  SubpropertyId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  SubpropertyId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_SubpropertyTypeConversion_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  SubpropertyId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  SubpropertyId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_SubpropertyTypeConversion_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
  SubpropertyId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Variance_Order_By = {
  Line?: Maybe<Order_By>;
  SubpropertyId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_TradingLicense" */
export type Rtcs_Db_Ph_Kcca_TradingLicense = {
  __typename?: "rtcs_db_PH_kcca_TradingLicense";
  BusinessName?: Maybe<Scalars["String"]>;
  Category?: Maybe<Scalars["String"]>;
  Code?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Dob?: Maybe<Scalars["date"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  NatureOfBusiness?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_TradingLicense" */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_TradingLicense_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_TradingLicense>;
};

/** aggregate fields of "rtcs_db.PH_kcca_TradingLicense" */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_TradingLicense_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_TradingLicense" */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_TradingLicense_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_TradingLicense" */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_TradingLicense" */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_TradingLicense_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_TradingLicense_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_TradingLicense" */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Avg_Order_By = {
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_TradingLicense". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Bool_Exp = {
  BusinessName?: Maybe<String_Comparison_Exp>;
  Category?: Maybe<String_Comparison_Exp>;
  Code?: Maybe<String_Comparison_Exp>;
  Division?: Maybe<String_Comparison_Exp>;
  Dob?: Maybe<Date_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  EntityLegalName?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamp_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Mobile?: Maybe<String_Comparison_Exp>;
  NatureOfBusiness?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_TradingLicense" */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_TradingLicense" */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Insert_Input = {
  BusinessName?: Maybe<Scalars["String"]>;
  Category?: Maybe<Scalars["String"]>;
  Code?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Dob?: Maybe<Scalars["date"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  NatureOfBusiness?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_TradingLicense_max_fields";
  BusinessName?: Maybe<Scalars["String"]>;
  Category?: Maybe<Scalars["String"]>;
  Code?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Dob?: Maybe<Scalars["date"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  NatureOfBusiness?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_TradingLicense" */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Max_Order_By = {
  BusinessName?: Maybe<Order_By>;
  Category?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  Dob?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  NatureOfBusiness?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_TradingLicense_min_fields";
  BusinessName?: Maybe<Scalars["String"]>;
  Category?: Maybe<Scalars["String"]>;
  Code?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Dob?: Maybe<Scalars["date"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  NatureOfBusiness?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_TradingLicense" */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Min_Order_By = {
  BusinessName?: Maybe<Order_By>;
  Category?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  Dob?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  NatureOfBusiness?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_TradingLicense" */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_TradingLicense_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_TradingLicense>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_TradingLicense" */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_TradingLicense_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_TradingLicense" */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Order_By = {
  BusinessName?: Maybe<Order_By>;
  Category?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  Dob?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  NatureOfBusiness?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_TradingLicense" */
export enum Rtcs_Db_Ph_Kcca_TradingLicense_Select_Column {
  /** column name */
  BusinessName = "BusinessName",
  /** column name */
  Category = "Category",
  /** column name */
  Code = "Code",
  /** column name */
  Division = "Division",
  /** column name */
  Dob = "Dob",
  /** column name */
  Email = "Email",
  /** column name */
  EntityLegalName = "EntityLegalName",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  NatureOfBusiness = "NatureOfBusiness",
  /** column name */
  Surname = "Surname",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_TradingLicense" */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Set_Input = {
  BusinessName?: Maybe<Scalars["String"]>;
  Category?: Maybe<Scalars["String"]>;
  Code?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Dob?: Maybe<Scalars["date"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  NatureOfBusiness?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_TradingLicense_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_TradingLicense" */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_TradingLicense_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_TradingLicense" */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_TradingLicense_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_TradingLicense" */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_TradingLicense_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_TradingLicense" */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Sum_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_TradingLicense_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_TradingLicense" */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_TradingLicense_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_TradingLicense" */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_TradingLicense_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_TradingLicense" */
export type Rtcs_Db_Ph_Kcca_TradingLicense_Variance_Order_By = {
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_kcca_Village" */
export type Rtcs_Db_Ph_Kcca_Village = {
  __typename?: "rtcs_db_PH_kcca_Village";
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamp"]>;
  Name?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_kcca_Village" */
export type Rtcs_Db_Ph_Kcca_Village_Aggregate = {
  __typename?: "rtcs_db_PH_kcca_Village_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Kcca_Village_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Kcca_Village>;
};

/** aggregate fields of "rtcs_db.PH_kcca_Village" */
export type Rtcs_Db_Ph_Kcca_Village_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_kcca_Village_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Kcca_Village_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_Village_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_Village_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_Village_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_Village_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_Village_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_Village_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_Village_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_Village_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_Village_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_kcca_Village" */
export type Rtcs_Db_Ph_Kcca_Village_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Kcca_Village_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_kcca_Village" */
export type Rtcs_Db_Ph_Kcca_Village_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Kcca_Village_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Kcca_Village_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Kcca_Village_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Kcca_Village_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Kcca_Village_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Kcca_Village_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Kcca_Village_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Kcca_Village_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Kcca_Village_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Kcca_Village_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_kcca_Village" */
export type Rtcs_Db_Ph_Kcca_Village_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Kcca_Village_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Kcca_Village_Avg_Fields = {
  __typename?: "rtcs_db_PH_kcca_Village_avg_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_kcca_Village" */
export type Rtcs_Db_Ph_Kcca_Village_Avg_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_kcca_Village". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Kcca_Village_Bool_Exp = {
  CountryId?: Maybe<Float8_Comparison_Exp>;
  CountyId?: Maybe<Bigint_Comparison_Exp>;
  CreatedBy?: Maybe<Float8_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamp_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  DistrictId?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamp_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  ModifiedBy?: Maybe<Float8_Comparison_Exp>;
  ModifiedDate?: Maybe<Timestamp_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  ParishId?: Maybe<Bigint_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  SubCountyId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_Village_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Kcca_Village_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Kcca_Village_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_kcca_Village" */
export type Rtcs_Db_Ph_Kcca_Village_Inc_Input = {
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_kcca_Village" */
export type Rtcs_Db_Ph_Kcca_Village_Insert_Input = {
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamp"]>;
  Name?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Kcca_Village_Max_Fields = {
  __typename?: "rtcs_db_PH_kcca_Village_max_fields";
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamp"]>;
  Name?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_kcca_Village" */
export type Rtcs_Db_Ph_Kcca_Village_Max_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Kcca_Village_Min_Fields = {
  __typename?: "rtcs_db_PH_kcca_Village_min_fields";
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamp"]>;
  Name?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_kcca_Village" */
export type Rtcs_Db_Ph_Kcca_Village_Min_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_kcca_Village" */
export type Rtcs_Db_Ph_Kcca_Village_Mutation_Response = {
  __typename?: "rtcs_db_PH_kcca_Village_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Kcca_Village>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_kcca_Village" */
export type Rtcs_Db_Ph_Kcca_Village_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Kcca_Village_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_kcca_Village" */
export type Rtcs_Db_Ph_Kcca_Village_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_kcca_Village" */
export enum Rtcs_Db_Ph_Kcca_Village_Select_Column {
  /** column name */
  CountryId = "CountryId",
  /** column name */
  CountyId = "CountyId",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  Description = "Description",
  /** column name */
  DistrictId = "DistrictId",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  Name = "Name",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  SubCountyId = "SubCountyId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_kcca_Village" */
export type Rtcs_Db_Ph_Kcca_Village_Set_Input = {
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamp"]>;
  Name?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Kcca_Village_Stddev_Fields = {
  __typename?: "rtcs_db_PH_kcca_Village_stddev_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_kcca_Village" */
export type Rtcs_Db_Ph_Kcca_Village_Stddev_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Kcca_Village_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_Village_stddev_pop_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_kcca_Village" */
export type Rtcs_Db_Ph_Kcca_Village_Stddev_Pop_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Kcca_Village_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_Village_stddev_samp_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_kcca_Village" */
export type Rtcs_Db_Ph_Kcca_Village_Stddev_Samp_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Kcca_Village_Sum_Fields = {
  __typename?: "rtcs_db_PH_kcca_Village_sum_fields";
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_kcca_Village" */
export type Rtcs_Db_Ph_Kcca_Village_Sum_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Kcca_Village_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_kcca_Village_var_pop_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_kcca_Village" */
export type Rtcs_Db_Ph_Kcca_Village_Var_Pop_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Kcca_Village_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_kcca_Village_var_samp_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_kcca_Village" */
export type Rtcs_Db_Ph_Kcca_Village_Var_Samp_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Kcca_Village_Variance_Fields = {
  __typename?: "rtcs_db_PH_kcca_Village_variance_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_kcca_Village" */
export type Rtcs_Db_Ph_Kcca_Village_Variance_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_master_MasterIndiv" */
export type Rtcs_Db_Ph_Master_MasterIndiv = {
  __typename?: "rtcs_db_PH_master_MasterIndiv";
  File?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  KccaId?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  RmatchNumProperties?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["bigint"]>;
  RmatchScoreAttributes?: Maybe<Scalars["String"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["float8"]>;
  RmatchSumGrossValue?: Maybe<Scalars["float8"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["bigint"]>;
  RmatchSumRateableValue?: Maybe<Scalars["float8"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["bigint"]>;
  RmatchUraMatchingTins?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_master_MasterIndiv" */
export type Rtcs_Db_Ph_Master_MasterIndiv_Aggregate = {
  __typename?: "rtcs_db_PH_master_MasterIndiv_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Master_MasterIndiv>;
};

/** aggregate fields of "rtcs_db.PH_master_MasterIndiv" */
export type Rtcs_Db_Ph_Master_MasterIndiv_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_master_MasterIndiv_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_master_MasterIndiv" */
export type Rtcs_Db_Ph_Master_MasterIndiv_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Master_MasterIndiv_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_master_MasterIndiv" */
export type Rtcs_Db_Ph_Master_MasterIndiv_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_master_MasterIndiv" */
export type Rtcs_Db_Ph_Master_MasterIndiv_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Master_MasterIndiv_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Master_MasterIndiv_Avg_Fields = {
  __typename?: "rtcs_db_PH_master_MasterIndiv_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  RmatchNumProperties?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["Float"]>;
  RmatchSumGrossValue?: Maybe<Scalars["Float"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["Float"]>;
  RmatchSumRateableValue?: Maybe<Scalars["Float"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_master_MasterIndiv" */
export type Rtcs_Db_Ph_Master_MasterIndiv_Avg_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchNumOwnedNonRateable?: Maybe<Order_By>;
  RmatchNumProperties?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RmatchSumAdjustedIncome?: Maybe<Order_By>;
  RmatchSumGrossValue?: Maybe<Order_By>;
  RmatchSumNonRateableValue?: Maybe<Order_By>;
  RmatchSumRateableValue?: Maybe<Order_By>;
  RmatchTotalRentedProperties?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_master_MasterIndiv". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Master_MasterIndiv_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  Fullname?: Maybe<String_Comparison_Exp>;
  KccaId?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  RmatchNumOwnedNonRateable?: Maybe<Bigint_Comparison_Exp>;
  RmatchNumProperties?: Maybe<Bigint_Comparison_Exp>;
  RmatchScore?: Maybe<Bigint_Comparison_Exp>;
  RmatchScoreAttributes?: Maybe<String_Comparison_Exp>;
  RmatchSumAdjustedIncome?: Maybe<Float8_Comparison_Exp>;
  RmatchSumGrossValue?: Maybe<Float8_Comparison_Exp>;
  RmatchSumNonRateableValue?: Maybe<Bigint_Comparison_Exp>;
  RmatchSumRateableValue?: Maybe<Float8_Comparison_Exp>;
  RmatchTotalRentedProperties?: Maybe<Bigint_Comparison_Exp>;
  RmatchUraMatchingTins?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_master_MasterIndiv" */
export type Rtcs_Db_Ph_Master_MasterIndiv_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  RmatchNumProperties?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["bigint"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["float8"]>;
  RmatchSumGrossValue?: Maybe<Scalars["float8"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["bigint"]>;
  RmatchSumRateableValue?: Maybe<Scalars["float8"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_master_MasterIndiv" */
export type Rtcs_Db_Ph_Master_MasterIndiv_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  KccaId?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  RmatchNumProperties?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["bigint"]>;
  RmatchScoreAttributes?: Maybe<Scalars["String"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["float8"]>;
  RmatchSumGrossValue?: Maybe<Scalars["float8"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["bigint"]>;
  RmatchSumRateableValue?: Maybe<Scalars["float8"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["bigint"]>;
  RmatchUraMatchingTins?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Master_MasterIndiv_Max_Fields = {
  __typename?: "rtcs_db_PH_master_MasterIndiv_max_fields";
  File?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  KccaId?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  RmatchNumProperties?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["bigint"]>;
  RmatchScoreAttributes?: Maybe<Scalars["String"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["float8"]>;
  RmatchSumGrossValue?: Maybe<Scalars["float8"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["bigint"]>;
  RmatchSumRateableValue?: Maybe<Scalars["float8"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["bigint"]>;
  RmatchUraMatchingTins?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_master_MasterIndiv" */
export type Rtcs_Db_Ph_Master_MasterIndiv_Max_Order_By = {
  File?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  KccaId?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchNumOwnedNonRateable?: Maybe<Order_By>;
  RmatchNumProperties?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RmatchScoreAttributes?: Maybe<Order_By>;
  RmatchSumAdjustedIncome?: Maybe<Order_By>;
  RmatchSumGrossValue?: Maybe<Order_By>;
  RmatchSumNonRateableValue?: Maybe<Order_By>;
  RmatchSumRateableValue?: Maybe<Order_By>;
  RmatchTotalRentedProperties?: Maybe<Order_By>;
  RmatchUraMatchingTins?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Master_MasterIndiv_Min_Fields = {
  __typename?: "rtcs_db_PH_master_MasterIndiv_min_fields";
  File?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  KccaId?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  RmatchNumProperties?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["bigint"]>;
  RmatchScoreAttributes?: Maybe<Scalars["String"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["float8"]>;
  RmatchSumGrossValue?: Maybe<Scalars["float8"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["bigint"]>;
  RmatchSumRateableValue?: Maybe<Scalars["float8"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["bigint"]>;
  RmatchUraMatchingTins?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_master_MasterIndiv" */
export type Rtcs_Db_Ph_Master_MasterIndiv_Min_Order_By = {
  File?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  KccaId?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchNumOwnedNonRateable?: Maybe<Order_By>;
  RmatchNumProperties?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RmatchScoreAttributes?: Maybe<Order_By>;
  RmatchSumAdjustedIncome?: Maybe<Order_By>;
  RmatchSumGrossValue?: Maybe<Order_By>;
  RmatchSumNonRateableValue?: Maybe<Order_By>;
  RmatchSumRateableValue?: Maybe<Order_By>;
  RmatchTotalRentedProperties?: Maybe<Order_By>;
  RmatchUraMatchingTins?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_master_MasterIndiv" */
export type Rtcs_Db_Ph_Master_MasterIndiv_Mutation_Response = {
  __typename?: "rtcs_db_PH_master_MasterIndiv_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Master_MasterIndiv>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_master_MasterIndiv" */
export type Rtcs_Db_Ph_Master_MasterIndiv_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Master_MasterIndiv_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_master_MasterIndiv" */
export type Rtcs_Db_Ph_Master_MasterIndiv_Order_By = {
  File?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  KccaId?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchNumOwnedNonRateable?: Maybe<Order_By>;
  RmatchNumProperties?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RmatchScoreAttributes?: Maybe<Order_By>;
  RmatchSumAdjustedIncome?: Maybe<Order_By>;
  RmatchSumGrossValue?: Maybe<Order_By>;
  RmatchSumNonRateableValue?: Maybe<Order_By>;
  RmatchSumRateableValue?: Maybe<Order_By>;
  RmatchTotalRentedProperties?: Maybe<Order_By>;
  RmatchUraMatchingTins?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_master_MasterIndiv" */
export enum Rtcs_Db_Ph_Master_MasterIndiv_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  Fullname = "Fullname",
  /** column name */
  KccaId = "KccaId",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  RmatchNumOwnedNonRateable = "RmatchNumOwnedNonRateable",
  /** column name */
  RmatchNumProperties = "RmatchNumProperties",
  /** column name */
  RmatchScore = "RmatchScore",
  /** column name */
  RmatchScoreAttributes = "RmatchScoreAttributes",
  /** column name */
  RmatchSumAdjustedIncome = "RmatchSumAdjustedIncome",
  /** column name */
  RmatchSumGrossValue = "RmatchSumGrossValue",
  /** column name */
  RmatchSumNonRateableValue = "RmatchSumNonRateableValue",
  /** column name */
  RmatchSumRateableValue = "RmatchSumRateableValue",
  /** column name */
  RmatchTotalRentedProperties = "RmatchTotalRentedProperties",
  /** column name */
  RmatchUraMatchingTins = "RmatchUraMatchingTins",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Source = "Source",
  /** column name */
  UraTinNo = "UraTinNo",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_master_MasterIndiv" */
export type Rtcs_Db_Ph_Master_MasterIndiv_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  KccaId?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  RmatchNumProperties?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["bigint"]>;
  RmatchScoreAttributes?: Maybe<Scalars["String"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["float8"]>;
  RmatchSumGrossValue?: Maybe<Scalars["float8"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["bigint"]>;
  RmatchSumRateableValue?: Maybe<Scalars["float8"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["bigint"]>;
  RmatchUraMatchingTins?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Master_MasterIndiv_Stddev_Fields = {
  __typename?: "rtcs_db_PH_master_MasterIndiv_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  RmatchNumProperties?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["Float"]>;
  RmatchSumGrossValue?: Maybe<Scalars["Float"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["Float"]>;
  RmatchSumRateableValue?: Maybe<Scalars["Float"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_master_MasterIndiv" */
export type Rtcs_Db_Ph_Master_MasterIndiv_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchNumOwnedNonRateable?: Maybe<Order_By>;
  RmatchNumProperties?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RmatchSumAdjustedIncome?: Maybe<Order_By>;
  RmatchSumGrossValue?: Maybe<Order_By>;
  RmatchSumNonRateableValue?: Maybe<Order_By>;
  RmatchSumRateableValue?: Maybe<Order_By>;
  RmatchTotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Master_MasterIndiv_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_master_MasterIndiv_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  RmatchNumProperties?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["Float"]>;
  RmatchSumGrossValue?: Maybe<Scalars["Float"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["Float"]>;
  RmatchSumRateableValue?: Maybe<Scalars["Float"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_master_MasterIndiv" */
export type Rtcs_Db_Ph_Master_MasterIndiv_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchNumOwnedNonRateable?: Maybe<Order_By>;
  RmatchNumProperties?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RmatchSumAdjustedIncome?: Maybe<Order_By>;
  RmatchSumGrossValue?: Maybe<Order_By>;
  RmatchSumNonRateableValue?: Maybe<Order_By>;
  RmatchSumRateableValue?: Maybe<Order_By>;
  RmatchTotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Master_MasterIndiv_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_master_MasterIndiv_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  RmatchNumProperties?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["Float"]>;
  RmatchSumGrossValue?: Maybe<Scalars["Float"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["Float"]>;
  RmatchSumRateableValue?: Maybe<Scalars["Float"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_master_MasterIndiv" */
export type Rtcs_Db_Ph_Master_MasterIndiv_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchNumOwnedNonRateable?: Maybe<Order_By>;
  RmatchNumProperties?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RmatchSumAdjustedIncome?: Maybe<Order_By>;
  RmatchSumGrossValue?: Maybe<Order_By>;
  RmatchSumNonRateableValue?: Maybe<Order_By>;
  RmatchSumRateableValue?: Maybe<Order_By>;
  RmatchTotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Master_MasterIndiv_Sum_Fields = {
  __typename?: "rtcs_db_PH_master_MasterIndiv_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  RmatchNumProperties?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["bigint"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["float8"]>;
  RmatchSumGrossValue?: Maybe<Scalars["float8"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["bigint"]>;
  RmatchSumRateableValue?: Maybe<Scalars["float8"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_master_MasterIndiv" */
export type Rtcs_Db_Ph_Master_MasterIndiv_Sum_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchNumOwnedNonRateable?: Maybe<Order_By>;
  RmatchNumProperties?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RmatchSumAdjustedIncome?: Maybe<Order_By>;
  RmatchSumGrossValue?: Maybe<Order_By>;
  RmatchSumNonRateableValue?: Maybe<Order_By>;
  RmatchSumRateableValue?: Maybe<Order_By>;
  RmatchTotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Master_MasterIndiv_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_master_MasterIndiv_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  RmatchNumProperties?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["Float"]>;
  RmatchSumGrossValue?: Maybe<Scalars["Float"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["Float"]>;
  RmatchSumRateableValue?: Maybe<Scalars["Float"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_master_MasterIndiv" */
export type Rtcs_Db_Ph_Master_MasterIndiv_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchNumOwnedNonRateable?: Maybe<Order_By>;
  RmatchNumProperties?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RmatchSumAdjustedIncome?: Maybe<Order_By>;
  RmatchSumGrossValue?: Maybe<Order_By>;
  RmatchSumNonRateableValue?: Maybe<Order_By>;
  RmatchSumRateableValue?: Maybe<Order_By>;
  RmatchTotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Master_MasterIndiv_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_master_MasterIndiv_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  RmatchNumProperties?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["Float"]>;
  RmatchSumGrossValue?: Maybe<Scalars["Float"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["Float"]>;
  RmatchSumRateableValue?: Maybe<Scalars["Float"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_master_MasterIndiv" */
export type Rtcs_Db_Ph_Master_MasterIndiv_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchNumOwnedNonRateable?: Maybe<Order_By>;
  RmatchNumProperties?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RmatchSumAdjustedIncome?: Maybe<Order_By>;
  RmatchSumGrossValue?: Maybe<Order_By>;
  RmatchSumNonRateableValue?: Maybe<Order_By>;
  RmatchSumRateableValue?: Maybe<Order_By>;
  RmatchTotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Master_MasterIndiv_Variance_Fields = {
  __typename?: "rtcs_db_PH_master_MasterIndiv_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  RmatchNumProperties?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["Float"]>;
  RmatchSumGrossValue?: Maybe<Scalars["Float"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["Float"]>;
  RmatchSumRateableValue?: Maybe<Scalars["Float"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_master_MasterIndiv" */
export type Rtcs_Db_Ph_Master_MasterIndiv_Variance_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchNumOwnedNonRateable?: Maybe<Order_By>;
  RmatchNumProperties?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RmatchSumAdjustedIncome?: Maybe<Order_By>;
  RmatchSumGrossValue?: Maybe<Order_By>;
  RmatchSumNonRateableValue?: Maybe<Order_By>;
  RmatchSumRateableValue?: Maybe<Order_By>;
  RmatchTotalRentedProperties?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_mlhud_KCCA" */
export type Rtcs_Db_Ph_Mlhud_Kcca = {
  __typename?: "rtcs_db_PH_mlhud_KCCA";
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  TitleNumber?: Maybe<Scalars["float8"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_mlhud_KCCA" */
export type Rtcs_Db_Ph_Mlhud_Kcca_Aggregate = {
  __typename?: "rtcs_db_PH_mlhud_KCCA_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Mlhud_Kcca>;
};

/** aggregate fields of "rtcs_db.PH_mlhud_KCCA" */
export type Rtcs_Db_Ph_Mlhud_Kcca_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_mlhud_KCCA_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_mlhud_KCCA" */
export type Rtcs_Db_Ph_Mlhud_Kcca_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Kcca_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_mlhud_KCCA" */
export type Rtcs_Db_Ph_Mlhud_Kcca_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_mlhud_KCCA" */
export type Rtcs_Db_Ph_Mlhud_Kcca_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Mlhud_Kcca_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Mlhud_Kcca_Avg_Fields = {
  __typename?: "rtcs_db_PH_mlhud_KCCA_avg_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TitleNumber?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_mlhud_KCCA" */
export type Rtcs_Db_Ph_Mlhud_Kcca_Avg_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TitleNumber?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_mlhud_KCCA". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Mlhud_Kcca_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  BaunitType?: Maybe<String_Comparison_Exp>;
  BlockNumber?: Maybe<String_Comparison_Exp>;
  CondoPlanNo?: Maybe<String_Comparison_Exp>;
  CondoUnitFactor?: Maybe<Float8_Comparison_Exp>;
  County?: Maybe<String_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FirstName?: Maybe<String_Comparison_Exp>;
  Folio?: Maybe<Float8_Comparison_Exp>;
  FullName?: Maybe<String_Comparison_Exp>;
  Gender?: Maybe<String_Comparison_Exp>;
  LafNumber?: Maybe<String_Comparison_Exp>;
  LandType?: Maybe<String_Comparison_Exp>;
  LandUseType?: Maybe<String_Comparison_Exp>;
  LastName?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  LegalArea?: Maybe<Float8_Comparison_Exp>;
  LegalAreaUnitType?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  Municipality?: Maybe<String_Comparison_Exp>;
  Phone?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  RegistrationDatetime?: Maybe<Timestamptz_Comparison_Exp>;
  RoadName?: Maybe<String_Comparison_Exp>;
  Subcounty?: Maybe<String_Comparison_Exp>;
  TenureType?: Maybe<String_Comparison_Exp>;
  TitleLocation?: Maybe<String_Comparison_Exp>;
  TitleNumber?: Maybe<Float8_Comparison_Exp>;
  Volume?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_mlhud_KCCA" */
export type Rtcs_Db_Ph_Mlhud_Kcca_Inc_Input = {
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  Folio?: Maybe<Scalars["float8"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  TitleNumber?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_mlhud_KCCA" */
export type Rtcs_Db_Ph_Mlhud_Kcca_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  TitleNumber?: Maybe<Scalars["float8"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Mlhud_Kcca_Max_Fields = {
  __typename?: "rtcs_db_PH_mlhud_KCCA_max_fields";
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  TitleNumber?: Maybe<Scalars["float8"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_mlhud_KCCA" */
export type Rtcs_Db_Ph_Mlhud_Kcca_Max_Order_By = {
  Address?: Maybe<Order_By>;
  BaunitType?: Maybe<Order_By>;
  BlockNumber?: Maybe<Order_By>;
  CondoPlanNo?: Maybe<Order_By>;
  CondoUnitFactor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  LafNumber?: Maybe<Order_By>;
  LandType?: Maybe<Order_By>;
  LandUseType?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  LegalAreaUnitType?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  RegistrationDatetime?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  TenureType?: Maybe<Order_By>;
  TitleLocation?: Maybe<Order_By>;
  TitleNumber?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Mlhud_Kcca_Min_Fields = {
  __typename?: "rtcs_db_PH_mlhud_KCCA_min_fields";
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  TitleNumber?: Maybe<Scalars["float8"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_mlhud_KCCA" */
export type Rtcs_Db_Ph_Mlhud_Kcca_Min_Order_By = {
  Address?: Maybe<Order_By>;
  BaunitType?: Maybe<Order_By>;
  BlockNumber?: Maybe<Order_By>;
  CondoPlanNo?: Maybe<Order_By>;
  CondoUnitFactor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  LafNumber?: Maybe<Order_By>;
  LandType?: Maybe<Order_By>;
  LandUseType?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  LegalAreaUnitType?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  RegistrationDatetime?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  TenureType?: Maybe<Order_By>;
  TitleLocation?: Maybe<Order_By>;
  TitleNumber?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_mlhud_KCCA" */
export type Rtcs_Db_Ph_Mlhud_Kcca_Mutation_Response = {
  __typename?: "rtcs_db_PH_mlhud_KCCA_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Mlhud_Kcca>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_mlhud_KCCA" */
export type Rtcs_Db_Ph_Mlhud_Kcca_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Mlhud_Kcca_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_mlhud_KCCA" */
export type Rtcs_Db_Ph_Mlhud_Kcca_Order_By = {
  Address?: Maybe<Order_By>;
  BaunitType?: Maybe<Order_By>;
  BlockNumber?: Maybe<Order_By>;
  CondoPlanNo?: Maybe<Order_By>;
  CondoUnitFactor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  LafNumber?: Maybe<Order_By>;
  LandType?: Maybe<Order_By>;
  LandUseType?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  LegalAreaUnitType?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  RegistrationDatetime?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  TenureType?: Maybe<Order_By>;
  TitleLocation?: Maybe<Order_By>;
  TitleNumber?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_mlhud_KCCA" */
export enum Rtcs_Db_Ph_Mlhud_Kcca_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  BaunitType = "BaunitType",
  /** column name */
  BlockNumber = "BlockNumber",
  /** column name */
  CondoPlanNo = "CondoPlanNo",
  /** column name */
  CondoUnitFactor = "CondoUnitFactor",
  /** column name */
  County = "County",
  /** column name */
  Description = "Description",
  /** column name */
  District = "District",
  /** column name */
  File = "File",
  /** column name */
  FirstName = "FirstName",
  /** column name */
  Folio = "Folio",
  /** column name */
  FullName = "FullName",
  /** column name */
  Gender = "Gender",
  /** column name */
  LafNumber = "LafNumber",
  /** column name */
  LandType = "LandType",
  /** column name */
  LandUseType = "LandUseType",
  /** column name */
  LastName = "LastName",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  LegalArea = "LegalArea",
  /** column name */
  LegalAreaUnitType = "LegalAreaUnitType",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  Municipality = "Municipality",
  /** column name */
  Phone = "Phone",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  RegistrationDatetime = "RegistrationDatetime",
  /** column name */
  RoadName = "RoadName",
  /** column name */
  Subcounty = "Subcounty",
  /** column name */
  TenureType = "TenureType",
  /** column name */
  TitleLocation = "TitleLocation",
  /** column name */
  TitleNumber = "TitleNumber",
  /** column name */
  Volume = "Volume",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_mlhud_KCCA" */
export type Rtcs_Db_Ph_Mlhud_Kcca_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  TitleNumber?: Maybe<Scalars["float8"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Mlhud_Kcca_Stddev_Fields = {
  __typename?: "rtcs_db_PH_mlhud_KCCA_stddev_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TitleNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_mlhud_KCCA" */
export type Rtcs_Db_Ph_Mlhud_Kcca_Stddev_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TitleNumber?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Mlhud_Kcca_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_mlhud_KCCA_stddev_pop_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TitleNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_mlhud_KCCA" */
export type Rtcs_Db_Ph_Mlhud_Kcca_Stddev_Pop_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TitleNumber?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Mlhud_Kcca_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_mlhud_KCCA_stddev_samp_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TitleNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_mlhud_KCCA" */
export type Rtcs_Db_Ph_Mlhud_Kcca_Stddev_Samp_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TitleNumber?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Mlhud_Kcca_Sum_Fields = {
  __typename?: "rtcs_db_PH_mlhud_KCCA_sum_fields";
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  Folio?: Maybe<Scalars["float8"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  TitleNumber?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_mlhud_KCCA" */
export type Rtcs_Db_Ph_Mlhud_Kcca_Sum_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TitleNumber?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Mlhud_Kcca_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_mlhud_KCCA_var_pop_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TitleNumber?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_mlhud_KCCA" */
export type Rtcs_Db_Ph_Mlhud_Kcca_Var_Pop_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TitleNumber?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Mlhud_Kcca_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_mlhud_KCCA_var_samp_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TitleNumber?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_mlhud_KCCA" */
export type Rtcs_Db_Ph_Mlhud_Kcca_Var_Samp_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TitleNumber?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Mlhud_Kcca_Variance_Fields = {
  __typename?: "rtcs_db_PH_mlhud_KCCA_variance_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TitleNumber?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_mlhud_KCCA" */
export type Rtcs_Db_Ph_Mlhud_Kcca_Variance_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TitleNumber?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_mlhud_Mukono" */
export type Rtcs_Db_Ph_Mlhud_Mukono = {
  __typename?: "rtcs_db_PH_mlhud_Mukono";
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_mlhud_Mukono" */
export type Rtcs_Db_Ph_Mlhud_Mukono_Aggregate = {
  __typename?: "rtcs_db_PH_mlhud_Mukono_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Mlhud_Mukono>;
};

/** aggregate fields of "rtcs_db.PH_mlhud_Mukono" */
export type Rtcs_Db_Ph_Mlhud_Mukono_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_mlhud_Mukono_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_mlhud_Mukono" */
export type Rtcs_Db_Ph_Mlhud_Mukono_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Mukono_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_mlhud_Mukono" */
export type Rtcs_Db_Ph_Mlhud_Mukono_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_mlhud_Mukono" */
export type Rtcs_Db_Ph_Mlhud_Mukono_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Mlhud_Mukono_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Mlhud_Mukono_Avg_Fields = {
  __typename?: "rtcs_db_PH_mlhud_Mukono_avg_fields";
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_mlhud_Mukono" */
export type Rtcs_Db_Ph_Mlhud_Mukono_Avg_Order_By = {
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_mlhud_Mukono". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Mlhud_Mukono_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  BaunitType?: Maybe<String_Comparison_Exp>;
  BlockNumber?: Maybe<String_Comparison_Exp>;
  County?: Maybe<String_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FirstName?: Maybe<String_Comparison_Exp>;
  Folio?: Maybe<Float8_Comparison_Exp>;
  FullName?: Maybe<String_Comparison_Exp>;
  Gender?: Maybe<String_Comparison_Exp>;
  LafNumber?: Maybe<String_Comparison_Exp>;
  LandType?: Maybe<String_Comparison_Exp>;
  LandUseType?: Maybe<String_Comparison_Exp>;
  LastName?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  LegalArea?: Maybe<Float8_Comparison_Exp>;
  LegalAreaUnitType?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  Municipality?: Maybe<String_Comparison_Exp>;
  Phone?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  RegistrationDatetime?: Maybe<Timestamptz_Comparison_Exp>;
  RoadName?: Maybe<String_Comparison_Exp>;
  Subcounty?: Maybe<String_Comparison_Exp>;
  TenureType?: Maybe<String_Comparison_Exp>;
  TitleLocation?: Maybe<String_Comparison_Exp>;
  Volume?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_mlhud_Mukono" */
export type Rtcs_Db_Ph_Mlhud_Mukono_Inc_Input = {
  Folio?: Maybe<Scalars["float8"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_mlhud_Mukono" */
export type Rtcs_Db_Ph_Mlhud_Mukono_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Mlhud_Mukono_Max_Fields = {
  __typename?: "rtcs_db_PH_mlhud_Mukono_max_fields";
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_mlhud_Mukono" */
export type Rtcs_Db_Ph_Mlhud_Mukono_Max_Order_By = {
  Address?: Maybe<Order_By>;
  BaunitType?: Maybe<Order_By>;
  BlockNumber?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  LafNumber?: Maybe<Order_By>;
  LandType?: Maybe<Order_By>;
  LandUseType?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  LegalAreaUnitType?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  RegistrationDatetime?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  TenureType?: Maybe<Order_By>;
  TitleLocation?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Mlhud_Mukono_Min_Fields = {
  __typename?: "rtcs_db_PH_mlhud_Mukono_min_fields";
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_mlhud_Mukono" */
export type Rtcs_Db_Ph_Mlhud_Mukono_Min_Order_By = {
  Address?: Maybe<Order_By>;
  BaunitType?: Maybe<Order_By>;
  BlockNumber?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  LafNumber?: Maybe<Order_By>;
  LandType?: Maybe<Order_By>;
  LandUseType?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  LegalAreaUnitType?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  RegistrationDatetime?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  TenureType?: Maybe<Order_By>;
  TitleLocation?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_mlhud_Mukono" */
export type Rtcs_Db_Ph_Mlhud_Mukono_Mutation_Response = {
  __typename?: "rtcs_db_PH_mlhud_Mukono_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Mlhud_Mukono>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_mlhud_Mukono" */
export type Rtcs_Db_Ph_Mlhud_Mukono_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Mlhud_Mukono_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_mlhud_Mukono" */
export type Rtcs_Db_Ph_Mlhud_Mukono_Order_By = {
  Address?: Maybe<Order_By>;
  BaunitType?: Maybe<Order_By>;
  BlockNumber?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  LafNumber?: Maybe<Order_By>;
  LandType?: Maybe<Order_By>;
  LandUseType?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  LegalAreaUnitType?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  RegistrationDatetime?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  TenureType?: Maybe<Order_By>;
  TitleLocation?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_mlhud_Mukono" */
export enum Rtcs_Db_Ph_Mlhud_Mukono_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  BaunitType = "BaunitType",
  /** column name */
  BlockNumber = "BlockNumber",
  /** column name */
  County = "County",
  /** column name */
  Description = "Description",
  /** column name */
  District = "District",
  /** column name */
  File = "File",
  /** column name */
  FirstName = "FirstName",
  /** column name */
  Folio = "Folio",
  /** column name */
  FullName = "FullName",
  /** column name */
  Gender = "Gender",
  /** column name */
  LafNumber = "LafNumber",
  /** column name */
  LandType = "LandType",
  /** column name */
  LandUseType = "LandUseType",
  /** column name */
  LastName = "LastName",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  LegalArea = "LegalArea",
  /** column name */
  LegalAreaUnitType = "LegalAreaUnitType",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  Municipality = "Municipality",
  /** column name */
  Phone = "Phone",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  RegistrationDatetime = "RegistrationDatetime",
  /** column name */
  RoadName = "RoadName",
  /** column name */
  Subcounty = "Subcounty",
  /** column name */
  TenureType = "TenureType",
  /** column name */
  TitleLocation = "TitleLocation",
  /** column name */
  Volume = "Volume",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_mlhud_Mukono" */
export type Rtcs_Db_Ph_Mlhud_Mukono_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Mlhud_Mukono_Stddev_Fields = {
  __typename?: "rtcs_db_PH_mlhud_Mukono_stddev_fields";
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_mlhud_Mukono" */
export type Rtcs_Db_Ph_Mlhud_Mukono_Stddev_Order_By = {
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Mlhud_Mukono_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_mlhud_Mukono_stddev_pop_fields";
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_mlhud_Mukono" */
export type Rtcs_Db_Ph_Mlhud_Mukono_Stddev_Pop_Order_By = {
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Mlhud_Mukono_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_mlhud_Mukono_stddev_samp_fields";
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_mlhud_Mukono" */
export type Rtcs_Db_Ph_Mlhud_Mukono_Stddev_Samp_Order_By = {
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Mlhud_Mukono_Sum_Fields = {
  __typename?: "rtcs_db_PH_mlhud_Mukono_sum_fields";
  Folio?: Maybe<Scalars["float8"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_mlhud_Mukono" */
export type Rtcs_Db_Ph_Mlhud_Mukono_Sum_Order_By = {
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Mlhud_Mukono_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_mlhud_Mukono_var_pop_fields";
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_mlhud_Mukono" */
export type Rtcs_Db_Ph_Mlhud_Mukono_Var_Pop_Order_By = {
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Mlhud_Mukono_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_mlhud_Mukono_var_samp_fields";
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_mlhud_Mukono" */
export type Rtcs_Db_Ph_Mlhud_Mukono_Var_Samp_Order_By = {
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Mlhud_Mukono_Variance_Fields = {
  __typename?: "rtcs_db_PH_mlhud_Mukono_variance_fields";
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_mlhud_Mukono" */
export type Rtcs_Db_Ph_Mlhud_Mukono_Variance_Order_By = {
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_mlhud_Properties" */
export type Rtcs_Db_Ph_Mlhud_Properties = {
  __typename?: "rtcs_db_PH_mlhud_Properties";
  BlockNumb?: Maybe<Scalars["String"]>;
  CadCounty?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  LotNumber?: Maybe<Scalars["String"]>;
  RoadName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_mlhud_Properties" */
export type Rtcs_Db_Ph_Mlhud_Properties_Aggregate = {
  __typename?: "rtcs_db_PH_mlhud_Properties_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Mlhud_Properties>;
};

/** aggregate fields of "rtcs_db.PH_mlhud_Properties" */
export type Rtcs_Db_Ph_Mlhud_Properties_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_mlhud_Properties_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_mlhud_Properties" */
export type Rtcs_Db_Ph_Mlhud_Properties_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Properties_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_mlhud_Properties" */
export type Rtcs_Db_Ph_Mlhud_Properties_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_mlhud_Properties" */
export type Rtcs_Db_Ph_Mlhud_Properties_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Mlhud_Properties_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Mlhud_Properties_Avg_Fields = {
  __typename?: "rtcs_db_PH_mlhud_Properties_avg_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_mlhud_Properties" */
export type Rtcs_Db_Ph_Mlhud_Properties_Avg_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_mlhud_Properties". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Mlhud_Properties_Bool_Exp = {
  BlockNumb?: Maybe<String_Comparison_Exp>;
  CadCounty?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  LotNumber?: Maybe<String_Comparison_Exp>;
  RoadName?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Mlhud_Properties_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Mlhud_Properties_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_mlhud_Properties" */
export type Rtcs_Db_Ph_Mlhud_Properties_Inc_Input = {
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_mlhud_Properties" */
export type Rtcs_Db_Ph_Mlhud_Properties_Insert_Input = {
  BlockNumb?: Maybe<Scalars["String"]>;
  CadCounty?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  LotNumber?: Maybe<Scalars["String"]>;
  RoadName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Mlhud_Properties_Max_Fields = {
  __typename?: "rtcs_db_PH_mlhud_Properties_max_fields";
  BlockNumb?: Maybe<Scalars["String"]>;
  CadCounty?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  LotNumber?: Maybe<Scalars["String"]>;
  RoadName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_mlhud_Properties" */
export type Rtcs_Db_Ph_Mlhud_Properties_Max_Order_By = {
  BlockNumb?: Maybe<Order_By>;
  CadCounty?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  LotNumber?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Mlhud_Properties_Min_Fields = {
  __typename?: "rtcs_db_PH_mlhud_Properties_min_fields";
  BlockNumb?: Maybe<Scalars["String"]>;
  CadCounty?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  LotNumber?: Maybe<Scalars["String"]>;
  RoadName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_mlhud_Properties" */
export type Rtcs_Db_Ph_Mlhud_Properties_Min_Order_By = {
  BlockNumb?: Maybe<Order_By>;
  CadCounty?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  LotNumber?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_mlhud_Properties" */
export type Rtcs_Db_Ph_Mlhud_Properties_Mutation_Response = {
  __typename?: "rtcs_db_PH_mlhud_Properties_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Mlhud_Properties>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_mlhud_Properties" */
export type Rtcs_Db_Ph_Mlhud_Properties_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Mlhud_Properties_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_mlhud_Properties" */
export type Rtcs_Db_Ph_Mlhud_Properties_Order_By = {
  BlockNumb?: Maybe<Order_By>;
  CadCounty?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  LotNumber?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_mlhud_Properties" */
export enum Rtcs_Db_Ph_Mlhud_Properties_Select_Column {
  /** column name */
  BlockNumb = "BlockNumb",
  /** column name */
  CadCounty = "CadCounty",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  LotNumber = "LotNumber",
  /** column name */
  RoadName = "RoadName",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_mlhud_Properties" */
export type Rtcs_Db_Ph_Mlhud_Properties_Set_Input = {
  BlockNumb?: Maybe<Scalars["String"]>;
  CadCounty?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  LotNumber?: Maybe<Scalars["String"]>;
  RoadName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Mlhud_Properties_Stddev_Fields = {
  __typename?: "rtcs_db_PH_mlhud_Properties_stddev_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_mlhud_Properties" */
export type Rtcs_Db_Ph_Mlhud_Properties_Stddev_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Mlhud_Properties_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_mlhud_Properties_stddev_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_mlhud_Properties" */
export type Rtcs_Db_Ph_Mlhud_Properties_Stddev_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Mlhud_Properties_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_mlhud_Properties_stddev_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_mlhud_Properties" */
export type Rtcs_Db_Ph_Mlhud_Properties_Stddev_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Mlhud_Properties_Sum_Fields = {
  __typename?: "rtcs_db_PH_mlhud_Properties_sum_fields";
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_mlhud_Properties" */
export type Rtcs_Db_Ph_Mlhud_Properties_Sum_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Mlhud_Properties_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_mlhud_Properties_var_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_mlhud_Properties" */
export type Rtcs_Db_Ph_Mlhud_Properties_Var_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Mlhud_Properties_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_mlhud_Properties_var_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_mlhud_Properties" */
export type Rtcs_Db_Ph_Mlhud_Properties_Var_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Mlhud_Properties_Variance_Fields = {
  __typename?: "rtcs_db_PH_mlhud_Properties_variance_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_mlhud_Properties" */
export type Rtcs_Db_Ph_Mlhud_Properties_Variance_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_mlhud_WakisoBusiro" */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro = {
  __typename?: "rtcs_db_PH_mlhud_WakisoBusiro";
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_mlhud_WakisoBusiro" */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Aggregate = {
  __typename?: "rtcs_db_PH_mlhud_WakisoBusiro_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Mlhud_WakisoBusiro>;
};

/** aggregate fields of "rtcs_db.PH_mlhud_WakisoBusiro" */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_mlhud_WakisoBusiro_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_mlhud_WakisoBusiro" */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_mlhud_WakisoBusiro" */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_mlhud_WakisoBusiro" */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Avg_Fields = {
  __typename?: "rtcs_db_PH_mlhud_WakisoBusiro_avg_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_mlhud_WakisoBusiro" */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Avg_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_mlhud_WakisoBusiro". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  BaunitType?: Maybe<String_Comparison_Exp>;
  BlockNumber?: Maybe<String_Comparison_Exp>;
  CondoPlanNo?: Maybe<String_Comparison_Exp>;
  CondoUnitFactor?: Maybe<Float8_Comparison_Exp>;
  County?: Maybe<String_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FirstName?: Maybe<String_Comparison_Exp>;
  Folio?: Maybe<String_Comparison_Exp>;
  FullName?: Maybe<String_Comparison_Exp>;
  Gender?: Maybe<String_Comparison_Exp>;
  LafNumber?: Maybe<String_Comparison_Exp>;
  LandType?: Maybe<String_Comparison_Exp>;
  LandUseType?: Maybe<String_Comparison_Exp>;
  LastName?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  LegalArea?: Maybe<Float8_Comparison_Exp>;
  LegalAreaUnitType?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  Municipality?: Maybe<String_Comparison_Exp>;
  Phone?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  RegistrationDatetime?: Maybe<Timestamptz_Comparison_Exp>;
  RoadName?: Maybe<String_Comparison_Exp>;
  Subcounty?: Maybe<String_Comparison_Exp>;
  TenureType?: Maybe<String_Comparison_Exp>;
  TitleLocation?: Maybe<String_Comparison_Exp>;
  Volume?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_mlhud_WakisoBusiro" */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Inc_Input = {
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_mlhud_WakisoBusiro" */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Max_Fields = {
  __typename?: "rtcs_db_PH_mlhud_WakisoBusiro_max_fields";
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_mlhud_WakisoBusiro" */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Max_Order_By = {
  Address?: Maybe<Order_By>;
  BaunitType?: Maybe<Order_By>;
  BlockNumber?: Maybe<Order_By>;
  CondoPlanNo?: Maybe<Order_By>;
  CondoUnitFactor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  LafNumber?: Maybe<Order_By>;
  LandType?: Maybe<Order_By>;
  LandUseType?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  LegalAreaUnitType?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  RegistrationDatetime?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  TenureType?: Maybe<Order_By>;
  TitleLocation?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Min_Fields = {
  __typename?: "rtcs_db_PH_mlhud_WakisoBusiro_min_fields";
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_mlhud_WakisoBusiro" */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Min_Order_By = {
  Address?: Maybe<Order_By>;
  BaunitType?: Maybe<Order_By>;
  BlockNumber?: Maybe<Order_By>;
  CondoPlanNo?: Maybe<Order_By>;
  CondoUnitFactor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  LafNumber?: Maybe<Order_By>;
  LandType?: Maybe<Order_By>;
  LandUseType?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  LegalAreaUnitType?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  RegistrationDatetime?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  TenureType?: Maybe<Order_By>;
  TitleLocation?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_mlhud_WakisoBusiro" */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Mutation_Response = {
  __typename?: "rtcs_db_PH_mlhud_WakisoBusiro_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Mlhud_WakisoBusiro>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_mlhud_WakisoBusiro" */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Mlhud_WakisoBusiro_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_mlhud_WakisoBusiro" */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Order_By = {
  Address?: Maybe<Order_By>;
  BaunitType?: Maybe<Order_By>;
  BlockNumber?: Maybe<Order_By>;
  CondoPlanNo?: Maybe<Order_By>;
  CondoUnitFactor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  LafNumber?: Maybe<Order_By>;
  LandType?: Maybe<Order_By>;
  LandUseType?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  LegalAreaUnitType?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  RegistrationDatetime?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  TenureType?: Maybe<Order_By>;
  TitleLocation?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_mlhud_WakisoBusiro" */
export enum Rtcs_Db_Ph_Mlhud_WakisoBusiro_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  BaunitType = "BaunitType",
  /** column name */
  BlockNumber = "BlockNumber",
  /** column name */
  CondoPlanNo = "CondoPlanNo",
  /** column name */
  CondoUnitFactor = "CondoUnitFactor",
  /** column name */
  County = "County",
  /** column name */
  Description = "Description",
  /** column name */
  District = "District",
  /** column name */
  File = "File",
  /** column name */
  FirstName = "FirstName",
  /** column name */
  Folio = "Folio",
  /** column name */
  FullName = "FullName",
  /** column name */
  Gender = "Gender",
  /** column name */
  LafNumber = "LafNumber",
  /** column name */
  LandType = "LandType",
  /** column name */
  LandUseType = "LandUseType",
  /** column name */
  LastName = "LastName",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  LegalArea = "LegalArea",
  /** column name */
  LegalAreaUnitType = "LegalAreaUnitType",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  Municipality = "Municipality",
  /** column name */
  Phone = "Phone",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  RegistrationDatetime = "RegistrationDatetime",
  /** column name */
  RoadName = "RoadName",
  /** column name */
  Subcounty = "Subcounty",
  /** column name */
  TenureType = "TenureType",
  /** column name */
  TitleLocation = "TitleLocation",
  /** column name */
  Volume = "Volume",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_mlhud_WakisoBusiro" */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Stddev_Fields = {
  __typename?: "rtcs_db_PH_mlhud_WakisoBusiro_stddev_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_mlhud_WakisoBusiro" */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Stddev_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_mlhud_WakisoBusiro_stddev_pop_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_mlhud_WakisoBusiro" */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Stddev_Pop_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_mlhud_WakisoBusiro_stddev_samp_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_mlhud_WakisoBusiro" */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Stddev_Samp_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Sum_Fields = {
  __typename?: "rtcs_db_PH_mlhud_WakisoBusiro_sum_fields";
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_mlhud_WakisoBusiro" */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Sum_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_mlhud_WakisoBusiro_var_pop_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_mlhud_WakisoBusiro" */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Var_Pop_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_mlhud_WakisoBusiro_var_samp_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_mlhud_WakisoBusiro" */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Var_Samp_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Variance_Fields = {
  __typename?: "rtcs_db_PH_mlhud_WakisoBusiro_variance_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_mlhud_WakisoBusiro" */
export type Rtcs_Db_Ph_Mlhud_WakisoBusiro_Variance_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_mlhud_WakisoKyadondo" */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo = {
  __typename?: "rtcs_db_PH_mlhud_WakisoKyadondo";
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_mlhud_WakisoKyadondo" */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Aggregate = {
  __typename?: "rtcs_db_PH_mlhud_WakisoKyadondo_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Mlhud_WakisoKyadondo>;
};

/** aggregate fields of "rtcs_db.PH_mlhud_WakisoKyadondo" */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_mlhud_WakisoKyadondo_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_mlhud_WakisoKyadondo" */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_mlhud_WakisoKyadondo" */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_mlhud_WakisoKyadondo" */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Avg_Fields = {
  __typename?: "rtcs_db_PH_mlhud_WakisoKyadondo_avg_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_mlhud_WakisoKyadondo" */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Avg_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_mlhud_WakisoKyadondo". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  BaunitType?: Maybe<String_Comparison_Exp>;
  BlockNumber?: Maybe<String_Comparison_Exp>;
  CondoPlanNo?: Maybe<String_Comparison_Exp>;
  CondoUnitFactor?: Maybe<Float8_Comparison_Exp>;
  County?: Maybe<String_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FirstName?: Maybe<String_Comparison_Exp>;
  Folio?: Maybe<String_Comparison_Exp>;
  FullName?: Maybe<String_Comparison_Exp>;
  Gender?: Maybe<String_Comparison_Exp>;
  LafNumber?: Maybe<String_Comparison_Exp>;
  LandType?: Maybe<String_Comparison_Exp>;
  LandUseType?: Maybe<String_Comparison_Exp>;
  LastName?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  LegalArea?: Maybe<Float8_Comparison_Exp>;
  LegalAreaUnitType?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  Municipality?: Maybe<String_Comparison_Exp>;
  Phone?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  RegistrationDatetime?: Maybe<Timestamptz_Comparison_Exp>;
  RoadName?: Maybe<String_Comparison_Exp>;
  Subcounty?: Maybe<String_Comparison_Exp>;
  TenureType?: Maybe<String_Comparison_Exp>;
  TitleLocation?: Maybe<String_Comparison_Exp>;
  Volume?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_mlhud_WakisoKyadondo" */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Inc_Input = {
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_mlhud_WakisoKyadondo" */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Max_Fields = {
  __typename?: "rtcs_db_PH_mlhud_WakisoKyadondo_max_fields";
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_mlhud_WakisoKyadondo" */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Max_Order_By = {
  Address?: Maybe<Order_By>;
  BaunitType?: Maybe<Order_By>;
  BlockNumber?: Maybe<Order_By>;
  CondoPlanNo?: Maybe<Order_By>;
  CondoUnitFactor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  LafNumber?: Maybe<Order_By>;
  LandType?: Maybe<Order_By>;
  LandUseType?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  LegalAreaUnitType?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  RegistrationDatetime?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  TenureType?: Maybe<Order_By>;
  TitleLocation?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Min_Fields = {
  __typename?: "rtcs_db_PH_mlhud_WakisoKyadondo_min_fields";
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_mlhud_WakisoKyadondo" */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Min_Order_By = {
  Address?: Maybe<Order_By>;
  BaunitType?: Maybe<Order_By>;
  BlockNumber?: Maybe<Order_By>;
  CondoPlanNo?: Maybe<Order_By>;
  CondoUnitFactor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  LafNumber?: Maybe<Order_By>;
  LandType?: Maybe<Order_By>;
  LandUseType?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  LegalAreaUnitType?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  RegistrationDatetime?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  TenureType?: Maybe<Order_By>;
  TitleLocation?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_mlhud_WakisoKyadondo" */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Mutation_Response = {
  __typename?: "rtcs_db_PH_mlhud_WakisoKyadondo_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Mlhud_WakisoKyadondo>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_mlhud_WakisoKyadondo" */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_mlhud_WakisoKyadondo" */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Order_By = {
  Address?: Maybe<Order_By>;
  BaunitType?: Maybe<Order_By>;
  BlockNumber?: Maybe<Order_By>;
  CondoPlanNo?: Maybe<Order_By>;
  CondoUnitFactor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  LafNumber?: Maybe<Order_By>;
  LandType?: Maybe<Order_By>;
  LandUseType?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  LegalAreaUnitType?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  RegistrationDatetime?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  TenureType?: Maybe<Order_By>;
  TitleLocation?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_mlhud_WakisoKyadondo" */
export enum Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  BaunitType = "BaunitType",
  /** column name */
  BlockNumber = "BlockNumber",
  /** column name */
  CondoPlanNo = "CondoPlanNo",
  /** column name */
  CondoUnitFactor = "CondoUnitFactor",
  /** column name */
  County = "County",
  /** column name */
  Description = "Description",
  /** column name */
  District = "District",
  /** column name */
  File = "File",
  /** column name */
  FirstName = "FirstName",
  /** column name */
  Folio = "Folio",
  /** column name */
  FullName = "FullName",
  /** column name */
  Gender = "Gender",
  /** column name */
  LafNumber = "LafNumber",
  /** column name */
  LandType = "LandType",
  /** column name */
  LandUseType = "LandUseType",
  /** column name */
  LastName = "LastName",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  LegalArea = "LegalArea",
  /** column name */
  LegalAreaUnitType = "LegalAreaUnitType",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  Municipality = "Municipality",
  /** column name */
  Phone = "Phone",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  RegistrationDatetime = "RegistrationDatetime",
  /** column name */
  RoadName = "RoadName",
  /** column name */
  Subcounty = "Subcounty",
  /** column name */
  TenureType = "TenureType",
  /** column name */
  TitleLocation = "TitleLocation",
  /** column name */
  Volume = "Volume",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_mlhud_WakisoKyadondo" */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Stddev_Fields = {
  __typename?: "rtcs_db_PH_mlhud_WakisoKyadondo_stddev_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_mlhud_WakisoKyadondo" */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Stddev_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_mlhud_WakisoKyadondo_stddev_pop_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_mlhud_WakisoKyadondo" */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Stddev_Pop_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_mlhud_WakisoKyadondo_stddev_samp_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_mlhud_WakisoKyadondo" */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Stddev_Samp_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Sum_Fields = {
  __typename?: "rtcs_db_PH_mlhud_WakisoKyadondo_sum_fields";
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_mlhud_WakisoKyadondo" */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Sum_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_mlhud_WakisoKyadondo_var_pop_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_mlhud_WakisoKyadondo" */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Var_Pop_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_mlhud_WakisoKyadondo_var_samp_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_mlhud_WakisoKyadondo" */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Var_Samp_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Variance_Fields = {
  __typename?: "rtcs_db_PH_mlhud_WakisoKyadondo_variance_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_mlhud_WakisoKyadondo" */
export type Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Variance_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_nira_Nira" */
export type Rtcs_Db_Ph_Nira_Nira = {
  __typename?: "rtcs_db_PH_nira_Nira";
  BirthCity?: Maybe<Scalars["String"]>;
  Dob?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  Nin?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_nira_Nira" */
export type Rtcs_Db_Ph_Nira_Nira_Aggregate = {
  __typename?: "rtcs_db_PH_nira_Nira_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Nira_Nira_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Nira_Nira>;
};

/** aggregate fields of "rtcs_db.PH_nira_Nira" */
export type Rtcs_Db_Ph_Nira_Nira_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_nira_Nira_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Nira_Nira_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Nira_Nira_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Nira_Nira_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Nira_Nira_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Nira_Nira_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Nira_Nira_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Nira_Nira_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Nira_Nira_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Nira_Nira_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Nira_Nira_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_nira_Nira" */
export type Rtcs_Db_Ph_Nira_Nira_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Nira_Nira_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_nira_Nira" */
export type Rtcs_Db_Ph_Nira_Nira_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Nira_Nira_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Nira_Nira_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Nira_Nira_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Nira_Nira_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Nira_Nira_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Nira_Nira_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Nira_Nira_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Nira_Nira_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Nira_Nira_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Nira_Nira_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_nira_Nira" */
export type Rtcs_Db_Ph_Nira_Nira_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Nira_Nira_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Nira_Nira_Avg_Fields = {
  __typename?: "rtcs_db_PH_nira_Nira_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_nira_Nira" */
export type Rtcs_Db_Ph_Nira_Nira_Avg_Order_By = {
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_nira_Nira". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Nira_Nira_Bool_Exp = {
  BirthCity?: Maybe<String_Comparison_Exp>;
  Dob?: Maybe<Timestamptz_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Mobile?: Maybe<String_Comparison_Exp>;
  Nin?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Nira_Nira_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Nira_Nira_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Nira_Nira_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_nira_Nira" */
export type Rtcs_Db_Ph_Nira_Nira_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_nira_Nira" */
export type Rtcs_Db_Ph_Nira_Nira_Insert_Input = {
  BirthCity?: Maybe<Scalars["String"]>;
  Dob?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  Nin?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Nira_Nira_Max_Fields = {
  __typename?: "rtcs_db_PH_nira_Nira_max_fields";
  BirthCity?: Maybe<Scalars["String"]>;
  Dob?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  Nin?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_nira_Nira" */
export type Rtcs_Db_Ph_Nira_Nira_Max_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Dob?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Nin?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Nira_Nira_Min_Fields = {
  __typename?: "rtcs_db_PH_nira_Nira_min_fields";
  BirthCity?: Maybe<Scalars["String"]>;
  Dob?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  Nin?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_nira_Nira" */
export type Rtcs_Db_Ph_Nira_Nira_Min_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Dob?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Nin?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_nira_Nira" */
export type Rtcs_Db_Ph_Nira_Nira_Mutation_Response = {
  __typename?: "rtcs_db_PH_nira_Nira_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Nira_Nira>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_nira_Nira" */
export type Rtcs_Db_Ph_Nira_Nira_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Nira_Nira_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_nira_Nira" */
export type Rtcs_Db_Ph_Nira_Nira_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Dob?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Nin?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_nira_Nira" */
export enum Rtcs_Db_Ph_Nira_Nira_Select_Column {
  /** column name */
  BirthCity = "BirthCity",
  /** column name */
  Dob = "Dob",
  /** column name */
  Email = "Email",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  Nin = "Nin",
  /** column name */
  Surname = "Surname",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_nira_Nira" */
export type Rtcs_Db_Ph_Nira_Nira_Set_Input = {
  BirthCity?: Maybe<Scalars["String"]>;
  Dob?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  Nin?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Nira_Nira_Stddev_Fields = {
  __typename?: "rtcs_db_PH_nira_Nira_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_nira_Nira" */
export type Rtcs_Db_Ph_Nira_Nira_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Nira_Nira_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_nira_Nira_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_nira_Nira" */
export type Rtcs_Db_Ph_Nira_Nira_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Nira_Nira_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_nira_Nira_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_nira_Nira" */
export type Rtcs_Db_Ph_Nira_Nira_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Nira_Nira_Sum_Fields = {
  __typename?: "rtcs_db_PH_nira_Nira_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_nira_Nira" */
export type Rtcs_Db_Ph_Nira_Nira_Sum_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Nira_Nira_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_nira_Nira_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_nira_Nira" */
export type Rtcs_Db_Ph_Nira_Nira_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Nira_Nira_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_nira_Nira_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_nira_Nira" */
export type Rtcs_Db_Ph_Nira_Nira_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Nira_Nira_Variance_Fields = {
  __typename?: "rtcs_db_PH_nira_Nira_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_nira_Nira" */
export type Rtcs_Db_Ph_Nira_Nira_Variance_Order_By = {
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_nira_RawData" */
export type Rtcs_Db_Ph_Nira_RawData = {
  __typename?: "rtcs_db_PH_nira_RawData";
  DateOfBirth?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  GivenName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalID?: Maybe<Scalars["String"]>;
  PseudoNIN?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TinNumber?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_nira_RawData" */
export type Rtcs_Db_Ph_Nira_RawData_Aggregate = {
  __typename?: "rtcs_db_PH_nira_RawData_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Nira_RawData_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Nira_RawData>;
};

/** aggregate fields of "rtcs_db.PH_nira_RawData" */
export type Rtcs_Db_Ph_Nira_RawData_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_nira_RawData_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Nira_RawData_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Nira_RawData_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Nira_RawData_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Nira_RawData_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Nira_RawData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Nira_RawData_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Nira_RawData_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Nira_RawData_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Nira_RawData_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Nira_RawData_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_nira_RawData" */
export type Rtcs_Db_Ph_Nira_RawData_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Nira_RawData_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_nira_RawData" */
export type Rtcs_Db_Ph_Nira_RawData_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Nira_RawData_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Nira_RawData_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Nira_RawData_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Nira_RawData_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Nira_RawData_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Nira_RawData_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Nira_RawData_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Nira_RawData_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Nira_RawData_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Nira_RawData_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_nira_RawData" */
export type Rtcs_Db_Ph_Nira_RawData_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Nira_RawData_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Nira_RawData_Avg_Fields = {
  __typename?: "rtcs_db_PH_nira_RawData_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_nira_RawData" */
export type Rtcs_Db_Ph_Nira_RawData_Avg_Order_By = {
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_nira_RawData". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Nira_RawData_Bool_Exp = {
  DateOfBirth?: Maybe<Timestamptz_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  GivenName?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MobileNumber?: Maybe<String_Comparison_Exp>;
  NationalID?: Maybe<String_Comparison_Exp>;
  PseudoNIN?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  TinNumber?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Nira_RawData_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Nira_RawData_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Nira_RawData_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_nira_RawData" */
export type Rtcs_Db_Ph_Nira_RawData_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_nira_RawData" */
export type Rtcs_Db_Ph_Nira_RawData_Insert_Input = {
  DateOfBirth?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  GivenName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalID?: Maybe<Scalars["String"]>;
  PseudoNIN?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TinNumber?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Nira_RawData_Max_Fields = {
  __typename?: "rtcs_db_PH_nira_RawData_max_fields";
  DateOfBirth?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  GivenName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalID?: Maybe<Scalars["String"]>;
  PseudoNIN?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TinNumber?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_nira_RawData" */
export type Rtcs_Db_Ph_Nira_RawData_Max_Order_By = {
  DateOfBirth?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GivenName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalID?: Maybe<Order_By>;
  PseudoNIN?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TinNumber?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Nira_RawData_Min_Fields = {
  __typename?: "rtcs_db_PH_nira_RawData_min_fields";
  DateOfBirth?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  GivenName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalID?: Maybe<Scalars["String"]>;
  PseudoNIN?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TinNumber?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_nira_RawData" */
export type Rtcs_Db_Ph_Nira_RawData_Min_Order_By = {
  DateOfBirth?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GivenName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalID?: Maybe<Order_By>;
  PseudoNIN?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TinNumber?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_nira_RawData" */
export type Rtcs_Db_Ph_Nira_RawData_Mutation_Response = {
  __typename?: "rtcs_db_PH_nira_RawData_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Nira_RawData>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_nira_RawData" */
export type Rtcs_Db_Ph_Nira_RawData_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Nira_RawData_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_nira_RawData" */
export type Rtcs_Db_Ph_Nira_RawData_Order_By = {
  DateOfBirth?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GivenName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalID?: Maybe<Order_By>;
  PseudoNIN?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TinNumber?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_nira_RawData" */
export enum Rtcs_Db_Ph_Nira_RawData_Select_Column {
  /** column name */
  DateOfBirth = "DateOfBirth",
  /** column name */
  Email = "Email",
  /** column name */
  File = "File",
  /** column name */
  GivenName = "GivenName",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  NationalId = "NationalID",
  /** column name */
  PseudoNin = "PseudoNIN",
  /** column name */
  Surname = "Surname",
  /** column name */
  TinNumber = "TinNumber",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_nira_RawData" */
export type Rtcs_Db_Ph_Nira_RawData_Set_Input = {
  DateOfBirth?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  GivenName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalID?: Maybe<Scalars["String"]>;
  PseudoNIN?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TinNumber?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Nira_RawData_Stddev_Fields = {
  __typename?: "rtcs_db_PH_nira_RawData_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_nira_RawData" */
export type Rtcs_Db_Ph_Nira_RawData_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Nira_RawData_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_nira_RawData_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_nira_RawData" */
export type Rtcs_Db_Ph_Nira_RawData_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Nira_RawData_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_nira_RawData_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_nira_RawData" */
export type Rtcs_Db_Ph_Nira_RawData_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Nira_RawData_Sum_Fields = {
  __typename?: "rtcs_db_PH_nira_RawData_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_nira_RawData" */
export type Rtcs_Db_Ph_Nira_RawData_Sum_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Nira_RawData_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_nira_RawData_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_nira_RawData" */
export type Rtcs_Db_Ph_Nira_RawData_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Nira_RawData_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_nira_RawData_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_nira_RawData" */
export type Rtcs_Db_Ph_Nira_RawData_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Nira_RawData_Variance_Fields = {
  __typename?: "rtcs_db_PH_nira_RawData_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_nira_RawData" */
export type Rtcs_Db_Ph_Nira_RawData_Variance_Order_By = {
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_nwsc_Customers" */
export type Rtcs_Db_Ph_Nwsc_Customers = {
  __typename?: "rtcs_db_PH_nwsc_Customers";
  AddressCons?: Maybe<Scalars["String"]>;
  AddressPay?: Maybe<Scalars["String"]>;
  AddressTrans?: Maybe<Scalars["String"]>;
  AreaPay?: Maybe<Scalars["String"]>;
  AreaTrans?: Maybe<Scalars["String"]>;
  CustomernameCons?: Maybe<Scalars["String"]>;
  CustomernamePay?: Maybe<Scalars["String"]>;
  CustomernameTrans?: Maybe<Scalars["String"]>;
  Customerphoneno1Cons?: Maybe<Scalars["String"]>;
  Customerphoneno1Pay?: Maybe<Scalars["String"]>;
  Customerphoneno1Trans?: Maybe<Scalars["String"]>;
  Customerphoneno2Cons?: Maybe<Scalars["String"]>;
  Customerphoneno2Pay?: Maybe<Scalars["String"]>;
  Customerphoneno2Trans?: Maybe<Scalars["String"]>;
  Customerphoneno3Pay?: Maybe<Scalars["String"]>;
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LatitudeCons?: Maybe<Scalars["String"]>;
  LatitudePay?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LongitudeCons?: Maybe<Scalars["String"]>;
  LongitudePay?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_nwsc_Customers" */
export type Rtcs_Db_Ph_Nwsc_Customers_Aggregate = {
  __typename?: "rtcs_db_PH_nwsc_Customers_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Nwsc_Customers>;
};

/** aggregate fields of "rtcs_db.PH_nwsc_Customers" */
export type Rtcs_Db_Ph_Nwsc_Customers_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_nwsc_Customers_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_nwsc_Customers" */
export type Rtcs_Db_Ph_Nwsc_Customers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Customers_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_nwsc_Customers" */
export type Rtcs_Db_Ph_Nwsc_Customers_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_nwsc_Customers" */
export type Rtcs_Db_Ph_Nwsc_Customers_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Nwsc_Customers_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Nwsc_Customers_Avg_Fields = {
  __typename?: "rtcs_db_PH_nwsc_Customers_avg_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_nwsc_Customers" */
export type Rtcs_Db_Ph_Nwsc_Customers_Avg_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_nwsc_Customers". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Nwsc_Customers_Bool_Exp = {
  AddressCons?: Maybe<String_Comparison_Exp>;
  AddressPay?: Maybe<String_Comparison_Exp>;
  AddressTrans?: Maybe<String_Comparison_Exp>;
  AreaPay?: Maybe<String_Comparison_Exp>;
  AreaTrans?: Maybe<String_Comparison_Exp>;
  CustomernameCons?: Maybe<String_Comparison_Exp>;
  CustomernamePay?: Maybe<String_Comparison_Exp>;
  CustomernameTrans?: Maybe<String_Comparison_Exp>;
  Customerphoneno1Cons?: Maybe<String_Comparison_Exp>;
  Customerphoneno1Pay?: Maybe<String_Comparison_Exp>;
  Customerphoneno1Trans?: Maybe<String_Comparison_Exp>;
  Customerphoneno2Cons?: Maybe<String_Comparison_Exp>;
  Customerphoneno2Pay?: Maybe<String_Comparison_Exp>;
  Customerphoneno2Trans?: Maybe<String_Comparison_Exp>;
  Customerphoneno3Pay?: Maybe<String_Comparison_Exp>;
  Customerreference?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  LatitudeCons?: Maybe<String_Comparison_Exp>;
  LatitudePay?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  LongitudeCons?: Maybe<String_Comparison_Exp>;
  LongitudePay?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Nwsc_Customers_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Nwsc_Customers_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_nwsc_Customers" */
export type Rtcs_Db_Ph_Nwsc_Customers_Inc_Input = {
  Customerreference?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_nwsc_Customers" */
export type Rtcs_Db_Ph_Nwsc_Customers_Insert_Input = {
  AddressCons?: Maybe<Scalars["String"]>;
  AddressPay?: Maybe<Scalars["String"]>;
  AddressTrans?: Maybe<Scalars["String"]>;
  AreaPay?: Maybe<Scalars["String"]>;
  AreaTrans?: Maybe<Scalars["String"]>;
  CustomernameCons?: Maybe<Scalars["String"]>;
  CustomernamePay?: Maybe<Scalars["String"]>;
  CustomernameTrans?: Maybe<Scalars["String"]>;
  Customerphoneno1Cons?: Maybe<Scalars["String"]>;
  Customerphoneno1Pay?: Maybe<Scalars["String"]>;
  Customerphoneno1Trans?: Maybe<Scalars["String"]>;
  Customerphoneno2Cons?: Maybe<Scalars["String"]>;
  Customerphoneno2Pay?: Maybe<Scalars["String"]>;
  Customerphoneno2Trans?: Maybe<Scalars["String"]>;
  Customerphoneno3Pay?: Maybe<Scalars["String"]>;
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LatitudeCons?: Maybe<Scalars["String"]>;
  LatitudePay?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LongitudeCons?: Maybe<Scalars["String"]>;
  LongitudePay?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Nwsc_Customers_Max_Fields = {
  __typename?: "rtcs_db_PH_nwsc_Customers_max_fields";
  AddressCons?: Maybe<Scalars["String"]>;
  AddressPay?: Maybe<Scalars["String"]>;
  AddressTrans?: Maybe<Scalars["String"]>;
  AreaPay?: Maybe<Scalars["String"]>;
  AreaTrans?: Maybe<Scalars["String"]>;
  CustomernameCons?: Maybe<Scalars["String"]>;
  CustomernamePay?: Maybe<Scalars["String"]>;
  CustomernameTrans?: Maybe<Scalars["String"]>;
  Customerphoneno1Cons?: Maybe<Scalars["String"]>;
  Customerphoneno1Pay?: Maybe<Scalars["String"]>;
  Customerphoneno1Trans?: Maybe<Scalars["String"]>;
  Customerphoneno2Cons?: Maybe<Scalars["String"]>;
  Customerphoneno2Pay?: Maybe<Scalars["String"]>;
  Customerphoneno2Trans?: Maybe<Scalars["String"]>;
  Customerphoneno3Pay?: Maybe<Scalars["String"]>;
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LatitudeCons?: Maybe<Scalars["String"]>;
  LatitudePay?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LongitudeCons?: Maybe<Scalars["String"]>;
  LongitudePay?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_nwsc_Customers" */
export type Rtcs_Db_Ph_Nwsc_Customers_Max_Order_By = {
  AddressCons?: Maybe<Order_By>;
  AddressPay?: Maybe<Order_By>;
  AddressTrans?: Maybe<Order_By>;
  AreaPay?: Maybe<Order_By>;
  AreaTrans?: Maybe<Order_By>;
  CustomernameCons?: Maybe<Order_By>;
  CustomernamePay?: Maybe<Order_By>;
  CustomernameTrans?: Maybe<Order_By>;
  Customerphoneno1Cons?: Maybe<Order_By>;
  Customerphoneno1Pay?: Maybe<Order_By>;
  Customerphoneno1Trans?: Maybe<Order_By>;
  Customerphoneno2Cons?: Maybe<Order_By>;
  Customerphoneno2Pay?: Maybe<Order_By>;
  Customerphoneno2Trans?: Maybe<Order_By>;
  Customerphoneno3Pay?: Maybe<Order_By>;
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LatitudeCons?: Maybe<Order_By>;
  LatitudePay?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeCons?: Maybe<Order_By>;
  LongitudePay?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Nwsc_Customers_Min_Fields = {
  __typename?: "rtcs_db_PH_nwsc_Customers_min_fields";
  AddressCons?: Maybe<Scalars["String"]>;
  AddressPay?: Maybe<Scalars["String"]>;
  AddressTrans?: Maybe<Scalars["String"]>;
  AreaPay?: Maybe<Scalars["String"]>;
  AreaTrans?: Maybe<Scalars["String"]>;
  CustomernameCons?: Maybe<Scalars["String"]>;
  CustomernamePay?: Maybe<Scalars["String"]>;
  CustomernameTrans?: Maybe<Scalars["String"]>;
  Customerphoneno1Cons?: Maybe<Scalars["String"]>;
  Customerphoneno1Pay?: Maybe<Scalars["String"]>;
  Customerphoneno1Trans?: Maybe<Scalars["String"]>;
  Customerphoneno2Cons?: Maybe<Scalars["String"]>;
  Customerphoneno2Pay?: Maybe<Scalars["String"]>;
  Customerphoneno2Trans?: Maybe<Scalars["String"]>;
  Customerphoneno3Pay?: Maybe<Scalars["String"]>;
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LatitudeCons?: Maybe<Scalars["String"]>;
  LatitudePay?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LongitudeCons?: Maybe<Scalars["String"]>;
  LongitudePay?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_nwsc_Customers" */
export type Rtcs_Db_Ph_Nwsc_Customers_Min_Order_By = {
  AddressCons?: Maybe<Order_By>;
  AddressPay?: Maybe<Order_By>;
  AddressTrans?: Maybe<Order_By>;
  AreaPay?: Maybe<Order_By>;
  AreaTrans?: Maybe<Order_By>;
  CustomernameCons?: Maybe<Order_By>;
  CustomernamePay?: Maybe<Order_By>;
  CustomernameTrans?: Maybe<Order_By>;
  Customerphoneno1Cons?: Maybe<Order_By>;
  Customerphoneno1Pay?: Maybe<Order_By>;
  Customerphoneno1Trans?: Maybe<Order_By>;
  Customerphoneno2Cons?: Maybe<Order_By>;
  Customerphoneno2Pay?: Maybe<Order_By>;
  Customerphoneno2Trans?: Maybe<Order_By>;
  Customerphoneno3Pay?: Maybe<Order_By>;
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LatitudeCons?: Maybe<Order_By>;
  LatitudePay?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeCons?: Maybe<Order_By>;
  LongitudePay?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_nwsc_Customers" */
export type Rtcs_Db_Ph_Nwsc_Customers_Mutation_Response = {
  __typename?: "rtcs_db_PH_nwsc_Customers_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Nwsc_Customers>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_nwsc_Customers" */
export type Rtcs_Db_Ph_Nwsc_Customers_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Nwsc_Customers_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_nwsc_Customers" */
export type Rtcs_Db_Ph_Nwsc_Customers_Order_By = {
  AddressCons?: Maybe<Order_By>;
  AddressPay?: Maybe<Order_By>;
  AddressTrans?: Maybe<Order_By>;
  AreaPay?: Maybe<Order_By>;
  AreaTrans?: Maybe<Order_By>;
  CustomernameCons?: Maybe<Order_By>;
  CustomernamePay?: Maybe<Order_By>;
  CustomernameTrans?: Maybe<Order_By>;
  Customerphoneno1Cons?: Maybe<Order_By>;
  Customerphoneno1Pay?: Maybe<Order_By>;
  Customerphoneno1Trans?: Maybe<Order_By>;
  Customerphoneno2Cons?: Maybe<Order_By>;
  Customerphoneno2Pay?: Maybe<Order_By>;
  Customerphoneno2Trans?: Maybe<Order_By>;
  Customerphoneno3Pay?: Maybe<Order_By>;
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LatitudeCons?: Maybe<Order_By>;
  LatitudePay?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeCons?: Maybe<Order_By>;
  LongitudePay?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_nwsc_Customers" */
export enum Rtcs_Db_Ph_Nwsc_Customers_Select_Column {
  /** column name */
  AddressCons = "AddressCons",
  /** column name */
  AddressPay = "AddressPay",
  /** column name */
  AddressTrans = "AddressTrans",
  /** column name */
  AreaPay = "AreaPay",
  /** column name */
  AreaTrans = "AreaTrans",
  /** column name */
  CustomernameCons = "CustomernameCons",
  /** column name */
  CustomernamePay = "CustomernamePay",
  /** column name */
  CustomernameTrans = "CustomernameTrans",
  /** column name */
  Customerphoneno1Cons = "Customerphoneno1Cons",
  /** column name */
  Customerphoneno1Pay = "Customerphoneno1Pay",
  /** column name */
  Customerphoneno1Trans = "Customerphoneno1Trans",
  /** column name */
  Customerphoneno2Cons = "Customerphoneno2Cons",
  /** column name */
  Customerphoneno2Pay = "Customerphoneno2Pay",
  /** column name */
  Customerphoneno2Trans = "Customerphoneno2Trans",
  /** column name */
  Customerphoneno3Pay = "Customerphoneno3Pay",
  /** column name */
  Customerreference = "Customerreference",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  LatitudeCons = "LatitudeCons",
  /** column name */
  LatitudePay = "LatitudePay",
  /** column name */
  Line = "Line",
  /** column name */
  LongitudeCons = "LongitudeCons",
  /** column name */
  LongitudePay = "LongitudePay",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_nwsc_Customers" */
export type Rtcs_Db_Ph_Nwsc_Customers_Set_Input = {
  AddressCons?: Maybe<Scalars["String"]>;
  AddressPay?: Maybe<Scalars["String"]>;
  AddressTrans?: Maybe<Scalars["String"]>;
  AreaPay?: Maybe<Scalars["String"]>;
  AreaTrans?: Maybe<Scalars["String"]>;
  CustomernameCons?: Maybe<Scalars["String"]>;
  CustomernamePay?: Maybe<Scalars["String"]>;
  CustomernameTrans?: Maybe<Scalars["String"]>;
  Customerphoneno1Cons?: Maybe<Scalars["String"]>;
  Customerphoneno1Pay?: Maybe<Scalars["String"]>;
  Customerphoneno1Trans?: Maybe<Scalars["String"]>;
  Customerphoneno2Cons?: Maybe<Scalars["String"]>;
  Customerphoneno2Pay?: Maybe<Scalars["String"]>;
  Customerphoneno2Trans?: Maybe<Scalars["String"]>;
  Customerphoneno3Pay?: Maybe<Scalars["String"]>;
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LatitudeCons?: Maybe<Scalars["String"]>;
  LatitudePay?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LongitudeCons?: Maybe<Scalars["String"]>;
  LongitudePay?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Nwsc_Customers_Stddev_Fields = {
  __typename?: "rtcs_db_PH_nwsc_Customers_stddev_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_nwsc_Customers" */
export type Rtcs_Db_Ph_Nwsc_Customers_Stddev_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Nwsc_Customers_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_nwsc_Customers_stddev_pop_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_nwsc_Customers" */
export type Rtcs_Db_Ph_Nwsc_Customers_Stddev_Pop_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Nwsc_Customers_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_nwsc_Customers_stddev_samp_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_nwsc_Customers" */
export type Rtcs_Db_Ph_Nwsc_Customers_Stddev_Samp_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Nwsc_Customers_Sum_Fields = {
  __typename?: "rtcs_db_PH_nwsc_Customers_sum_fields";
  Customerreference?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_nwsc_Customers" */
export type Rtcs_Db_Ph_Nwsc_Customers_Sum_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Nwsc_Customers_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_nwsc_Customers_var_pop_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_nwsc_Customers" */
export type Rtcs_Db_Ph_Nwsc_Customers_Var_Pop_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Nwsc_Customers_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_nwsc_Customers_var_samp_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_nwsc_Customers" */
export type Rtcs_Db_Ph_Nwsc_Customers_Var_Samp_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Nwsc_Customers_Variance_Fields = {
  __typename?: "rtcs_db_PH_nwsc_Customers_variance_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_nwsc_Customers" */
export type Rtcs_Db_Ph_Nwsc_Customers_Variance_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_nwsc_NWCustomers" */
export type Rtcs_Db_Ph_Nwsc_NwCustomers = {
  __typename?: "rtcs_db_PH_nwsc_NWCustomers";
  AddressCons?: Maybe<Scalars["String"]>;
  AddressPay?: Maybe<Scalars["String"]>;
  AddressTrans?: Maybe<Scalars["String"]>;
  AreaPay?: Maybe<Scalars["String"]>;
  AreaTrans?: Maybe<Scalars["String"]>;
  CustomernameCons?: Maybe<Scalars["String"]>;
  CustomernamePay?: Maybe<Scalars["String"]>;
  CustomernameTrans?: Maybe<Scalars["String"]>;
  Customerphoneno1Cons?: Maybe<Scalars["String"]>;
  Customerphoneno1Pay?: Maybe<Scalars["String"]>;
  Customerphoneno1Trans?: Maybe<Scalars["String"]>;
  Customerphoneno2Cons?: Maybe<Scalars["String"]>;
  Customerphoneno2Pay?: Maybe<Scalars["String"]>;
  Customerphoneno2Trans?: Maybe<Scalars["String"]>;
  Customerphoneno3Pay?: Maybe<Scalars["String"]>;
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LatitudeCons?: Maybe<Scalars["String"]>;
  LatitudePay?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LongitudeCons?: Maybe<Scalars["String"]>;
  LongitudePay?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_nwsc_NWCustomers" */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Aggregate = {
  __typename?: "rtcs_db_PH_nwsc_NWCustomers_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Nwsc_NwCustomers>;
};

/** aggregate fields of "rtcs_db.PH_nwsc_NWCustomers" */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_nwsc_NWCustomers_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_nwsc_NWCustomers" */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Nwsc_NwCustomers_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_nwsc_NWCustomers" */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_nwsc_NWCustomers" */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Nwsc_NwCustomers_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Avg_Fields = {
  __typename?: "rtcs_db_PH_nwsc_NWCustomers_avg_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_nwsc_NWCustomers" */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Avg_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_nwsc_NWCustomers". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Bool_Exp = {
  AddressCons?: Maybe<String_Comparison_Exp>;
  AddressPay?: Maybe<String_Comparison_Exp>;
  AddressTrans?: Maybe<String_Comparison_Exp>;
  AreaPay?: Maybe<String_Comparison_Exp>;
  AreaTrans?: Maybe<String_Comparison_Exp>;
  CustomernameCons?: Maybe<String_Comparison_Exp>;
  CustomernamePay?: Maybe<String_Comparison_Exp>;
  CustomernameTrans?: Maybe<String_Comparison_Exp>;
  Customerphoneno1Cons?: Maybe<String_Comparison_Exp>;
  Customerphoneno1Pay?: Maybe<String_Comparison_Exp>;
  Customerphoneno1Trans?: Maybe<String_Comparison_Exp>;
  Customerphoneno2Cons?: Maybe<String_Comparison_Exp>;
  Customerphoneno2Pay?: Maybe<String_Comparison_Exp>;
  Customerphoneno2Trans?: Maybe<String_Comparison_Exp>;
  Customerphoneno3Pay?: Maybe<String_Comparison_Exp>;
  Customerreference?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  LatitudeCons?: Maybe<String_Comparison_Exp>;
  LatitudePay?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  LongitudeCons?: Maybe<String_Comparison_Exp>;
  LongitudePay?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_nwsc_NWCustomers" */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Inc_Input = {
  Customerreference?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_nwsc_NWCustomers" */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Insert_Input = {
  AddressCons?: Maybe<Scalars["String"]>;
  AddressPay?: Maybe<Scalars["String"]>;
  AddressTrans?: Maybe<Scalars["String"]>;
  AreaPay?: Maybe<Scalars["String"]>;
  AreaTrans?: Maybe<Scalars["String"]>;
  CustomernameCons?: Maybe<Scalars["String"]>;
  CustomernamePay?: Maybe<Scalars["String"]>;
  CustomernameTrans?: Maybe<Scalars["String"]>;
  Customerphoneno1Cons?: Maybe<Scalars["String"]>;
  Customerphoneno1Pay?: Maybe<Scalars["String"]>;
  Customerphoneno1Trans?: Maybe<Scalars["String"]>;
  Customerphoneno2Cons?: Maybe<Scalars["String"]>;
  Customerphoneno2Pay?: Maybe<Scalars["String"]>;
  Customerphoneno2Trans?: Maybe<Scalars["String"]>;
  Customerphoneno3Pay?: Maybe<Scalars["String"]>;
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LatitudeCons?: Maybe<Scalars["String"]>;
  LatitudePay?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LongitudeCons?: Maybe<Scalars["String"]>;
  LongitudePay?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Max_Fields = {
  __typename?: "rtcs_db_PH_nwsc_NWCustomers_max_fields";
  AddressCons?: Maybe<Scalars["String"]>;
  AddressPay?: Maybe<Scalars["String"]>;
  AddressTrans?: Maybe<Scalars["String"]>;
  AreaPay?: Maybe<Scalars["String"]>;
  AreaTrans?: Maybe<Scalars["String"]>;
  CustomernameCons?: Maybe<Scalars["String"]>;
  CustomernamePay?: Maybe<Scalars["String"]>;
  CustomernameTrans?: Maybe<Scalars["String"]>;
  Customerphoneno1Cons?: Maybe<Scalars["String"]>;
  Customerphoneno1Pay?: Maybe<Scalars["String"]>;
  Customerphoneno1Trans?: Maybe<Scalars["String"]>;
  Customerphoneno2Cons?: Maybe<Scalars["String"]>;
  Customerphoneno2Pay?: Maybe<Scalars["String"]>;
  Customerphoneno2Trans?: Maybe<Scalars["String"]>;
  Customerphoneno3Pay?: Maybe<Scalars["String"]>;
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LatitudeCons?: Maybe<Scalars["String"]>;
  LatitudePay?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LongitudeCons?: Maybe<Scalars["String"]>;
  LongitudePay?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_nwsc_NWCustomers" */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Max_Order_By = {
  AddressCons?: Maybe<Order_By>;
  AddressPay?: Maybe<Order_By>;
  AddressTrans?: Maybe<Order_By>;
  AreaPay?: Maybe<Order_By>;
  AreaTrans?: Maybe<Order_By>;
  CustomernameCons?: Maybe<Order_By>;
  CustomernamePay?: Maybe<Order_By>;
  CustomernameTrans?: Maybe<Order_By>;
  Customerphoneno1Cons?: Maybe<Order_By>;
  Customerphoneno1Pay?: Maybe<Order_By>;
  Customerphoneno1Trans?: Maybe<Order_By>;
  Customerphoneno2Cons?: Maybe<Order_By>;
  Customerphoneno2Pay?: Maybe<Order_By>;
  Customerphoneno2Trans?: Maybe<Order_By>;
  Customerphoneno3Pay?: Maybe<Order_By>;
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LatitudeCons?: Maybe<Order_By>;
  LatitudePay?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeCons?: Maybe<Order_By>;
  LongitudePay?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Min_Fields = {
  __typename?: "rtcs_db_PH_nwsc_NWCustomers_min_fields";
  AddressCons?: Maybe<Scalars["String"]>;
  AddressPay?: Maybe<Scalars["String"]>;
  AddressTrans?: Maybe<Scalars["String"]>;
  AreaPay?: Maybe<Scalars["String"]>;
  AreaTrans?: Maybe<Scalars["String"]>;
  CustomernameCons?: Maybe<Scalars["String"]>;
  CustomernamePay?: Maybe<Scalars["String"]>;
  CustomernameTrans?: Maybe<Scalars["String"]>;
  Customerphoneno1Cons?: Maybe<Scalars["String"]>;
  Customerphoneno1Pay?: Maybe<Scalars["String"]>;
  Customerphoneno1Trans?: Maybe<Scalars["String"]>;
  Customerphoneno2Cons?: Maybe<Scalars["String"]>;
  Customerphoneno2Pay?: Maybe<Scalars["String"]>;
  Customerphoneno2Trans?: Maybe<Scalars["String"]>;
  Customerphoneno3Pay?: Maybe<Scalars["String"]>;
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LatitudeCons?: Maybe<Scalars["String"]>;
  LatitudePay?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LongitudeCons?: Maybe<Scalars["String"]>;
  LongitudePay?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_nwsc_NWCustomers" */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Min_Order_By = {
  AddressCons?: Maybe<Order_By>;
  AddressPay?: Maybe<Order_By>;
  AddressTrans?: Maybe<Order_By>;
  AreaPay?: Maybe<Order_By>;
  AreaTrans?: Maybe<Order_By>;
  CustomernameCons?: Maybe<Order_By>;
  CustomernamePay?: Maybe<Order_By>;
  CustomernameTrans?: Maybe<Order_By>;
  Customerphoneno1Cons?: Maybe<Order_By>;
  Customerphoneno1Pay?: Maybe<Order_By>;
  Customerphoneno1Trans?: Maybe<Order_By>;
  Customerphoneno2Cons?: Maybe<Order_By>;
  Customerphoneno2Pay?: Maybe<Order_By>;
  Customerphoneno2Trans?: Maybe<Order_By>;
  Customerphoneno3Pay?: Maybe<Order_By>;
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LatitudeCons?: Maybe<Order_By>;
  LatitudePay?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeCons?: Maybe<Order_By>;
  LongitudePay?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_nwsc_NWCustomers" */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Mutation_Response = {
  __typename?: "rtcs_db_PH_nwsc_NWCustomers_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Nwsc_NwCustomers>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_nwsc_NWCustomers" */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Nwsc_NwCustomers_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_nwsc_NWCustomers" */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Order_By = {
  AddressCons?: Maybe<Order_By>;
  AddressPay?: Maybe<Order_By>;
  AddressTrans?: Maybe<Order_By>;
  AreaPay?: Maybe<Order_By>;
  AreaTrans?: Maybe<Order_By>;
  CustomernameCons?: Maybe<Order_By>;
  CustomernamePay?: Maybe<Order_By>;
  CustomernameTrans?: Maybe<Order_By>;
  Customerphoneno1Cons?: Maybe<Order_By>;
  Customerphoneno1Pay?: Maybe<Order_By>;
  Customerphoneno1Trans?: Maybe<Order_By>;
  Customerphoneno2Cons?: Maybe<Order_By>;
  Customerphoneno2Pay?: Maybe<Order_By>;
  Customerphoneno2Trans?: Maybe<Order_By>;
  Customerphoneno3Pay?: Maybe<Order_By>;
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LatitudeCons?: Maybe<Order_By>;
  LatitudePay?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeCons?: Maybe<Order_By>;
  LongitudePay?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_nwsc_NWCustomers" */
export enum Rtcs_Db_Ph_Nwsc_NwCustomers_Select_Column {
  /** column name */
  AddressCons = "AddressCons",
  /** column name */
  AddressPay = "AddressPay",
  /** column name */
  AddressTrans = "AddressTrans",
  /** column name */
  AreaPay = "AreaPay",
  /** column name */
  AreaTrans = "AreaTrans",
  /** column name */
  CustomernameCons = "CustomernameCons",
  /** column name */
  CustomernamePay = "CustomernamePay",
  /** column name */
  CustomernameTrans = "CustomernameTrans",
  /** column name */
  Customerphoneno1Cons = "Customerphoneno1Cons",
  /** column name */
  Customerphoneno1Pay = "Customerphoneno1Pay",
  /** column name */
  Customerphoneno1Trans = "Customerphoneno1Trans",
  /** column name */
  Customerphoneno2Cons = "Customerphoneno2Cons",
  /** column name */
  Customerphoneno2Pay = "Customerphoneno2Pay",
  /** column name */
  Customerphoneno2Trans = "Customerphoneno2Trans",
  /** column name */
  Customerphoneno3Pay = "Customerphoneno3Pay",
  /** column name */
  Customerreference = "Customerreference",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  LatitudeCons = "LatitudeCons",
  /** column name */
  LatitudePay = "LatitudePay",
  /** column name */
  Line = "Line",
  /** column name */
  LongitudeCons = "LongitudeCons",
  /** column name */
  LongitudePay = "LongitudePay",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_nwsc_NWCustomers" */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Set_Input = {
  AddressCons?: Maybe<Scalars["String"]>;
  AddressPay?: Maybe<Scalars["String"]>;
  AddressTrans?: Maybe<Scalars["String"]>;
  AreaPay?: Maybe<Scalars["String"]>;
  AreaTrans?: Maybe<Scalars["String"]>;
  CustomernameCons?: Maybe<Scalars["String"]>;
  CustomernamePay?: Maybe<Scalars["String"]>;
  CustomernameTrans?: Maybe<Scalars["String"]>;
  Customerphoneno1Cons?: Maybe<Scalars["String"]>;
  Customerphoneno1Pay?: Maybe<Scalars["String"]>;
  Customerphoneno1Trans?: Maybe<Scalars["String"]>;
  Customerphoneno2Cons?: Maybe<Scalars["String"]>;
  Customerphoneno2Pay?: Maybe<Scalars["String"]>;
  Customerphoneno2Trans?: Maybe<Scalars["String"]>;
  Customerphoneno3Pay?: Maybe<Scalars["String"]>;
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LatitudeCons?: Maybe<Scalars["String"]>;
  LatitudePay?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LongitudeCons?: Maybe<Scalars["String"]>;
  LongitudePay?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Stddev_Fields = {
  __typename?: "rtcs_db_PH_nwsc_NWCustomers_stddev_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_nwsc_NWCustomers" */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Stddev_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_nwsc_NWCustomers_stddev_pop_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_nwsc_NWCustomers" */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Stddev_Pop_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_nwsc_NWCustomers_stddev_samp_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_nwsc_NWCustomers" */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Stddev_Samp_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Sum_Fields = {
  __typename?: "rtcs_db_PH_nwsc_NWCustomers_sum_fields";
  Customerreference?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_nwsc_NWCustomers" */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Sum_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_nwsc_NWCustomers_var_pop_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_nwsc_NWCustomers" */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Var_Pop_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_nwsc_NWCustomers_var_samp_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_nwsc_NWCustomers" */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Var_Samp_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Variance_Fields = {
  __typename?: "rtcs_db_PH_nwsc_NWCustomers_variance_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_nwsc_NWCustomers" */
export type Rtcs_Db_Ph_Nwsc_NwCustomers_Variance_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_nwsc_RPT_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions = {
  __typename?: "rtcs_db_PH_nwsc_RPT_Transactions";
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Period?: Maybe<Scalars["timestamptz"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_nwsc_RPT_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Aggregate = {
  __typename?: "rtcs_db_PH_nwsc_RPT_Transactions_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Nwsc_Rpt_Transactions>;
};

/** aggregate fields of "rtcs_db.PH_nwsc_RPT_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_nwsc_RPT_Transactions_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_nwsc_RPT_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_nwsc_RPT_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_nwsc_RPT_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Avg_Fields = {
  __typename?: "rtcs_db_PH_nwsc_RPT_Transactions_avg_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_nwsc_RPT_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Avg_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_nwsc_RPT_Transactions". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Bool_Exp = {
  Customerreference?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Period?: Maybe<Timestamptz_Comparison_Exp>;
  Type?: Maybe<String_Comparison_Exp>;
  Value?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_nwsc_RPT_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Inc_Input = {
  Customerreference?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Value?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_nwsc_RPT_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Insert_Input = {
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Period?: Maybe<Scalars["timestamptz"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Max_Fields = {
  __typename?: "rtcs_db_PH_nwsc_RPT_Transactions_max_fields";
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Period?: Maybe<Scalars["timestamptz"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_nwsc_RPT_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Max_Order_By = {
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Period?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Min_Fields = {
  __typename?: "rtcs_db_PH_nwsc_RPT_Transactions_min_fields";
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Period?: Maybe<Scalars["timestamptz"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_nwsc_RPT_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Min_Order_By = {
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Period?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_nwsc_RPT_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Mutation_Response = {
  __typename?: "rtcs_db_PH_nwsc_RPT_Transactions_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Nwsc_Rpt_Transactions>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_nwsc_RPT_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_nwsc_RPT_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Order_By = {
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Period?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_nwsc_RPT_Transactions" */
export enum Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Select_Column {
  /** column name */
  Customerreference = "Customerreference",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Period = "Period",
  /** column name */
  Type = "Type",
  /** column name */
  Value = "Value",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_nwsc_RPT_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Set_Input = {
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Period?: Maybe<Scalars["timestamptz"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Stddev_Fields = {
  __typename?: "rtcs_db_PH_nwsc_RPT_Transactions_stddev_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_nwsc_RPT_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Stddev_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_nwsc_RPT_Transactions_stddev_pop_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_nwsc_RPT_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Stddev_Pop_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_nwsc_RPT_Transactions_stddev_samp_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_nwsc_RPT_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Stddev_Samp_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Sum_Fields = {
  __typename?: "rtcs_db_PH_nwsc_RPT_Transactions_sum_fields";
  Customerreference?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Value?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_nwsc_RPT_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Sum_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_nwsc_RPT_Transactions_var_pop_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_nwsc_RPT_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Var_Pop_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_nwsc_RPT_Transactions_var_samp_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_nwsc_RPT_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Var_Samp_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Variance_Fields = {
  __typename?: "rtcs_db_PH_nwsc_RPT_Transactions_variance_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_nwsc_RPT_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Variance_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_nwsc_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Transactions = {
  __typename?: "rtcs_db_PH_nwsc_Transactions";
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Period?: Maybe<Scalars["timestamptz"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_nwsc_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Transactions_Aggregate = {
  __typename?: "rtcs_db_PH_nwsc_Transactions_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Nwsc_Transactions>;
};

/** aggregate fields of "rtcs_db.PH_nwsc_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Transactions_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_nwsc_Transactions_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_nwsc_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Transactions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Transactions_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_nwsc_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Transactions_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_nwsc_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Transactions_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Nwsc_Transactions_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Nwsc_Transactions_Avg_Fields = {
  __typename?: "rtcs_db_PH_nwsc_Transactions_avg_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_nwsc_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Transactions_Avg_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_nwsc_Transactions". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Nwsc_Transactions_Bool_Exp = {
  Customerreference?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Period?: Maybe<Timestamptz_Comparison_Exp>;
  Type?: Maybe<String_Comparison_Exp>;
  Value?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_nwsc_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Transactions_Inc_Input = {
  Customerreference?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Value?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_nwsc_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Transactions_Insert_Input = {
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Period?: Maybe<Scalars["timestamptz"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Nwsc_Transactions_Max_Fields = {
  __typename?: "rtcs_db_PH_nwsc_Transactions_max_fields";
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Period?: Maybe<Scalars["timestamptz"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_nwsc_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Transactions_Max_Order_By = {
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Period?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Nwsc_Transactions_Min_Fields = {
  __typename?: "rtcs_db_PH_nwsc_Transactions_min_fields";
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Period?: Maybe<Scalars["timestamptz"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_nwsc_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Transactions_Min_Order_By = {
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Period?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_nwsc_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Transactions_Mutation_Response = {
  __typename?: "rtcs_db_PH_nwsc_Transactions_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Nwsc_Transactions>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_nwsc_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Transactions_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Nwsc_Transactions_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_nwsc_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Transactions_Order_By = {
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Period?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_nwsc_Transactions" */
export enum Rtcs_Db_Ph_Nwsc_Transactions_Select_Column {
  /** column name */
  Customerreference = "Customerreference",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Period = "Period",
  /** column name */
  Type = "Type",
  /** column name */
  Value = "Value",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_nwsc_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Transactions_Set_Input = {
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Period?: Maybe<Scalars["timestamptz"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Nwsc_Transactions_Stddev_Fields = {
  __typename?: "rtcs_db_PH_nwsc_Transactions_stddev_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_nwsc_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Transactions_Stddev_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Nwsc_Transactions_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_nwsc_Transactions_stddev_pop_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_nwsc_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Transactions_Stddev_Pop_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Nwsc_Transactions_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_nwsc_Transactions_stddev_samp_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_nwsc_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Transactions_Stddev_Samp_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Nwsc_Transactions_Sum_Fields = {
  __typename?: "rtcs_db_PH_nwsc_Transactions_sum_fields";
  Customerreference?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Value?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_nwsc_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Transactions_Sum_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Nwsc_Transactions_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_nwsc_Transactions_var_pop_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_nwsc_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Transactions_Var_Pop_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Nwsc_Transactions_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_nwsc_Transactions_var_samp_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_nwsc_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Transactions_Var_Samp_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Nwsc_Transactions_Variance_Fields = {
  __typename?: "rtcs_db_PH_nwsc_Transactions_variance_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_nwsc_Transactions" */
export type Rtcs_Db_Ph_Nwsc_Transactions_Variance_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_nwsc_URATransactions" */
export type Rtcs_Db_Ph_Nwsc_UraTransactions = {
  __typename?: "rtcs_db_PH_nwsc_URATransactions";
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Month?: Maybe<Scalars["bigint"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Year?: Maybe<Scalars["bigint"]>;
};

/** aggregated selection of "rtcs_db.PH_nwsc_URATransactions" */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Aggregate = {
  __typename?: "rtcs_db_PH_nwsc_URATransactions_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Nwsc_UraTransactions>;
};

/** aggregate fields of "rtcs_db.PH_nwsc_URATransactions" */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_nwsc_URATransactions_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_nwsc_URATransactions" */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Nwsc_UraTransactions_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_nwsc_URATransactions" */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_nwsc_URATransactions" */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Nwsc_UraTransactions_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Avg_Fields = {
  __typename?: "rtcs_db_PH_nwsc_URATransactions_avg_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Month?: Maybe<Scalars["Float"]>;
  Year?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_nwsc_URATransactions" */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Avg_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Month?: Maybe<Order_By>;
  Year?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_nwsc_URATransactions". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Bool_Exp = {
  Customerreference?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamp_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Month?: Maybe<Bigint_Comparison_Exp>;
  Type?: Maybe<String_Comparison_Exp>;
  Value?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  Year?: Maybe<Bigint_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_nwsc_URATransactions" */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Inc_Input = {
  Customerreference?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Month?: Maybe<Scalars["bigint"]>;
  Year?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_nwsc_URATransactions" */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Insert_Input = {
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Month?: Maybe<Scalars["bigint"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Year?: Maybe<Scalars["bigint"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Max_Fields = {
  __typename?: "rtcs_db_PH_nwsc_URATransactions_max_fields";
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Month?: Maybe<Scalars["bigint"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Year?: Maybe<Scalars["bigint"]>;
};

/** order by max() on columns of table "rtcs_db.PH_nwsc_URATransactions" */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Max_Order_By = {
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Month?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  Year?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Min_Fields = {
  __typename?: "rtcs_db_PH_nwsc_URATransactions_min_fields";
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Month?: Maybe<Scalars["bigint"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Year?: Maybe<Scalars["bigint"]>;
};

/** order by min() on columns of table "rtcs_db.PH_nwsc_URATransactions" */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Min_Order_By = {
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Month?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  Year?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_nwsc_URATransactions" */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Mutation_Response = {
  __typename?: "rtcs_db_PH_nwsc_URATransactions_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Nwsc_UraTransactions>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_nwsc_URATransactions" */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Nwsc_UraTransactions_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_nwsc_URATransactions" */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Order_By = {
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Month?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  Year?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_nwsc_URATransactions" */
export enum Rtcs_Db_Ph_Nwsc_UraTransactions_Select_Column {
  /** column name */
  Customerreference = "Customerreference",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Month = "Month",
  /** column name */
  Type = "Type",
  /** column name */
  Value = "Value",
  /** column name */
  WhoUpdated = "WhoUpdated",
  /** column name */
  Year = "Year",
}

/** input type for updating data in table "rtcs_db.PH_nwsc_URATransactions" */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Set_Input = {
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Month?: Maybe<Scalars["bigint"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Year?: Maybe<Scalars["bigint"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Stddev_Fields = {
  __typename?: "rtcs_db_PH_nwsc_URATransactions_stddev_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Month?: Maybe<Scalars["Float"]>;
  Year?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_nwsc_URATransactions" */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Stddev_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Month?: Maybe<Order_By>;
  Year?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_nwsc_URATransactions_stddev_pop_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Month?: Maybe<Scalars["Float"]>;
  Year?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_nwsc_URATransactions" */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Stddev_Pop_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Month?: Maybe<Order_By>;
  Year?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_nwsc_URATransactions_stddev_samp_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Month?: Maybe<Scalars["Float"]>;
  Year?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_nwsc_URATransactions" */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Stddev_Samp_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Month?: Maybe<Order_By>;
  Year?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Sum_Fields = {
  __typename?: "rtcs_db_PH_nwsc_URATransactions_sum_fields";
  Customerreference?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Month?: Maybe<Scalars["bigint"]>;
  Year?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_nwsc_URATransactions" */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Sum_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Month?: Maybe<Order_By>;
  Year?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_nwsc_URATransactions_var_pop_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Month?: Maybe<Scalars["Float"]>;
  Year?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_nwsc_URATransactions" */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Var_Pop_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Month?: Maybe<Order_By>;
  Year?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_nwsc_URATransactions_var_samp_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Month?: Maybe<Scalars["Float"]>;
  Year?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_nwsc_URATransactions" */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Var_Samp_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Month?: Maybe<Order_By>;
  Year?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Variance_Fields = {
  __typename?: "rtcs_db_PH_nwsc_URATransactions_variance_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Month?: Maybe<Scalars["Float"]>;
  Year?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_nwsc_URATransactions" */
export type Rtcs_Db_Ph_Nwsc_UraTransactions_Variance_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Month?: Maybe<Order_By>;
  Year?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rcapture_RPTUnittypes" */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes = {
  __typename?: "rtcs_db_PH_rcapture_RPTUnittypes";
  Createdby?: Maybe<Scalars["String"]>;
  Currencyadvertisedrent?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Numberunitstype?: Maybe<Scalars["bigint"]>;
  Pguid?: Maybe<Scalars["String"]>;
  Uguid?: Maybe<Scalars["String"]>;
  Unitfurnished?: Maybe<Scalars["String"]>;
  Unitrent?: Maybe<Scalars["float8"]>;
  Unitsize?: Maybe<Scalars["float8"]>;
  Unittype?: Maybe<Scalars["String"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rcapture_RPTUnittypes" */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Aggregate = {
  __typename?: "rtcs_db_PH_rcapture_RPTUnittypes_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rcapture_RptUnittypes>;
};

/** aggregate fields of "rtcs_db.PH_rcapture_RPTUnittypes" */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTUnittypes_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rcapture_RPTUnittypes" */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RptUnittypes_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rcapture_RPTUnittypes" */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rcapture_RPTUnittypes" */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rcapture_RptUnittypes_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Avg_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTUnittypes_avg_fields";
  Currencyadvertisedrent?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Numberunitstype?: Maybe<Scalars["Float"]>;
  Unitrent?: Maybe<Scalars["Float"]>;
  Unitsize?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rcapture_RPTUnittypes" */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Avg_Order_By = {
  Currencyadvertisedrent?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Numberunitstype?: Maybe<Order_By>;
  Unitrent?: Maybe<Order_By>;
  Unitsize?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rcapture_RPTUnittypes". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Bool_Exp = {
  Createdby?: Maybe<String_Comparison_Exp>;
  Currencyadvertisedrent?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Numberbathrooms?: Maybe<Bigint_Comparison_Exp>;
  Numberbedrooms?: Maybe<Bigint_Comparison_Exp>;
  Numberunitstype?: Maybe<Bigint_Comparison_Exp>;
  Pguid?: Maybe<String_Comparison_Exp>;
  Uguid?: Maybe<String_Comparison_Exp>;
  Unitfurnished?: Maybe<String_Comparison_Exp>;
  Unitrent?: Maybe<Float8_Comparison_Exp>;
  Unitsize?: Maybe<Float8_Comparison_Exp>;
  Unittype?: Maybe<String_Comparison_Exp>;
  Uuidproperty?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rcapture_RPTUnittypes" */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Inc_Input = {
  Currencyadvertisedrent?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Numberunitstype?: Maybe<Scalars["bigint"]>;
  Unitrent?: Maybe<Scalars["float8"]>;
  Unitsize?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rcapture_RPTUnittypes" */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Insert_Input = {
  Createdby?: Maybe<Scalars["String"]>;
  Currencyadvertisedrent?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Numberunitstype?: Maybe<Scalars["bigint"]>;
  Pguid?: Maybe<Scalars["String"]>;
  Uguid?: Maybe<Scalars["String"]>;
  Unitfurnished?: Maybe<Scalars["String"]>;
  Unitrent?: Maybe<Scalars["float8"]>;
  Unitsize?: Maybe<Scalars["float8"]>;
  Unittype?: Maybe<Scalars["String"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Max_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTUnittypes_max_fields";
  Createdby?: Maybe<Scalars["String"]>;
  Currencyadvertisedrent?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Numberunitstype?: Maybe<Scalars["bigint"]>;
  Pguid?: Maybe<Scalars["String"]>;
  Uguid?: Maybe<Scalars["String"]>;
  Unitfurnished?: Maybe<Scalars["String"]>;
  Unitrent?: Maybe<Scalars["float8"]>;
  Unitsize?: Maybe<Scalars["float8"]>;
  Unittype?: Maybe<Scalars["String"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rcapture_RPTUnittypes" */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Max_Order_By = {
  Createdby?: Maybe<Order_By>;
  Currencyadvertisedrent?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Numberunitstype?: Maybe<Order_By>;
  Pguid?: Maybe<Order_By>;
  Uguid?: Maybe<Order_By>;
  Unitfurnished?: Maybe<Order_By>;
  Unitrent?: Maybe<Order_By>;
  Unitsize?: Maybe<Order_By>;
  Unittype?: Maybe<Order_By>;
  Uuidproperty?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Min_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTUnittypes_min_fields";
  Createdby?: Maybe<Scalars["String"]>;
  Currencyadvertisedrent?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Numberunitstype?: Maybe<Scalars["bigint"]>;
  Pguid?: Maybe<Scalars["String"]>;
  Uguid?: Maybe<Scalars["String"]>;
  Unitfurnished?: Maybe<Scalars["String"]>;
  Unitrent?: Maybe<Scalars["float8"]>;
  Unitsize?: Maybe<Scalars["float8"]>;
  Unittype?: Maybe<Scalars["String"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rcapture_RPTUnittypes" */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Min_Order_By = {
  Createdby?: Maybe<Order_By>;
  Currencyadvertisedrent?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Numberunitstype?: Maybe<Order_By>;
  Pguid?: Maybe<Order_By>;
  Uguid?: Maybe<Order_By>;
  Unitfurnished?: Maybe<Order_By>;
  Unitrent?: Maybe<Order_By>;
  Unitsize?: Maybe<Order_By>;
  Unittype?: Maybe<Order_By>;
  Uuidproperty?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rcapture_RPTUnittypes" */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Mutation_Response = {
  __typename?: "rtcs_db_PH_rcapture_RPTUnittypes_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rcapture_RptUnittypes>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rcapture_RPTUnittypes" */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rcapture_RptUnittypes_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rcapture_RPTUnittypes" */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Order_By = {
  Createdby?: Maybe<Order_By>;
  Currencyadvertisedrent?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Numberunitstype?: Maybe<Order_By>;
  Pguid?: Maybe<Order_By>;
  Uguid?: Maybe<Order_By>;
  Unitfurnished?: Maybe<Order_By>;
  Unitrent?: Maybe<Order_By>;
  Unitsize?: Maybe<Order_By>;
  Unittype?: Maybe<Order_By>;
  Uuidproperty?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rcapture_RPTUnittypes" */
export enum Rtcs_Db_Ph_Rcapture_RptUnittypes_Select_Column {
  /** column name */
  Createdby = "Createdby",
  /** column name */
  Currencyadvertisedrent = "Currencyadvertisedrent",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Numberbathrooms = "Numberbathrooms",
  /** column name */
  Numberbedrooms = "Numberbedrooms",
  /** column name */
  Numberunitstype = "Numberunitstype",
  /** column name */
  Pguid = "Pguid",
  /** column name */
  Uguid = "Uguid",
  /** column name */
  Unitfurnished = "Unitfurnished",
  /** column name */
  Unitrent = "Unitrent",
  /** column name */
  Unitsize = "Unitsize",
  /** column name */
  Unittype = "Unittype",
  /** column name */
  Uuidproperty = "Uuidproperty",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rcapture_RPTUnittypes" */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Set_Input = {
  Createdby?: Maybe<Scalars["String"]>;
  Currencyadvertisedrent?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Numberunitstype?: Maybe<Scalars["bigint"]>;
  Pguid?: Maybe<Scalars["String"]>;
  Uguid?: Maybe<Scalars["String"]>;
  Unitfurnished?: Maybe<Scalars["String"]>;
  Unitrent?: Maybe<Scalars["float8"]>;
  Unitsize?: Maybe<Scalars["float8"]>;
  Unittype?: Maybe<Scalars["String"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTUnittypes_stddev_fields";
  Currencyadvertisedrent?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Numberunitstype?: Maybe<Scalars["Float"]>;
  Unitrent?: Maybe<Scalars["Float"]>;
  Unitsize?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rcapture_RPTUnittypes" */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Stddev_Order_By = {
  Currencyadvertisedrent?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Numberunitstype?: Maybe<Order_By>;
  Unitrent?: Maybe<Order_By>;
  Unitsize?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTUnittypes_stddev_pop_fields";
  Currencyadvertisedrent?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Numberunitstype?: Maybe<Scalars["Float"]>;
  Unitrent?: Maybe<Scalars["Float"]>;
  Unitsize?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rcapture_RPTUnittypes" */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Stddev_Pop_Order_By = {
  Currencyadvertisedrent?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Numberunitstype?: Maybe<Order_By>;
  Unitrent?: Maybe<Order_By>;
  Unitsize?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTUnittypes_stddev_samp_fields";
  Currencyadvertisedrent?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Numberunitstype?: Maybe<Scalars["Float"]>;
  Unitrent?: Maybe<Scalars["Float"]>;
  Unitsize?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rcapture_RPTUnittypes" */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Stddev_Samp_Order_By = {
  Currencyadvertisedrent?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Numberunitstype?: Maybe<Order_By>;
  Unitrent?: Maybe<Order_By>;
  Unitsize?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Sum_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTUnittypes_sum_fields";
  Currencyadvertisedrent?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Numberunitstype?: Maybe<Scalars["bigint"]>;
  Unitrent?: Maybe<Scalars["float8"]>;
  Unitsize?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rcapture_RPTUnittypes" */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Sum_Order_By = {
  Currencyadvertisedrent?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Numberunitstype?: Maybe<Order_By>;
  Unitrent?: Maybe<Order_By>;
  Unitsize?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTUnittypes_var_pop_fields";
  Currencyadvertisedrent?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Numberunitstype?: Maybe<Scalars["Float"]>;
  Unitrent?: Maybe<Scalars["Float"]>;
  Unitsize?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rcapture_RPTUnittypes" */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Var_Pop_Order_By = {
  Currencyadvertisedrent?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Numberunitstype?: Maybe<Order_By>;
  Unitrent?: Maybe<Order_By>;
  Unitsize?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTUnittypes_var_samp_fields";
  Currencyadvertisedrent?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Numberunitstype?: Maybe<Scalars["Float"]>;
  Unitrent?: Maybe<Scalars["Float"]>;
  Unitsize?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rcapture_RPTUnittypes" */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Var_Samp_Order_By = {
  Currencyadvertisedrent?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Numberunitstype?: Maybe<Order_By>;
  Unitrent?: Maybe<Order_By>;
  Unitsize?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Variance_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTUnittypes_variance_fields";
  Currencyadvertisedrent?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Numberunitstype?: Maybe<Scalars["Float"]>;
  Unitrent?: Maybe<Scalars["Float"]>;
  Unitsize?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rcapture_RPTUnittypes" */
export type Rtcs_Db_Ph_Rcapture_RptUnittypes_Variance_Order_By = {
  Currencyadvertisedrent?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Numberunitstype?: Maybe<Order_By>;
  Unitrent?: Maybe<Order_By>;
  Unitsize?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rcapture_RPTlessors" */
export type Rtcs_Db_Ph_Rcapture_RpTlessors = {
  __typename?: "rtcs_db_PH_rcapture_RPTlessors";
  Buildingname?: Maybe<Scalars["String"]>;
  Businesstradingname?: Maybe<Scalars["String"]>;
  Cin?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Customeridsrc?: Maybe<Scalars["String"]>;
  Datecreated?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalbusinessname?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Nationality?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Phone1?: Maybe<Scalars["String"]>;
  Phone2?: Maybe<Scalars["String"]>;
  Phone3?: Maybe<Scalars["String"]>;
  Phone4?: Maybe<Scalars["String"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Positionincompany?: Maybe<Scalars["String"]>;
  Postaldistrict?: Maybe<Scalars["String"]>;
  Postalpobox?: Maybe<Scalars["String"]>;
  Propertiesuuids?: Maybe<Scalars["String"]>;
  Region?: Maybe<Scalars["String"]>;
  Registeredbusinessnumber?: Maybe<Scalars["String"]>;
  Repdob?: Maybe<Scalars["timestamptz"]>;
  Repfirstname?: Maybe<Scalars["String"]>;
  Replastname?: Maybe<Scalars["String"]>;
  Repnationality?: Maybe<Scalars["String"]>;
  Repothercountry?: Maybe<Scalars["String"]>;
  Repphone1?: Maybe<Scalars["String"]>;
  Reptitle?: Maybe<Scalars["String"]>;
  Sguid?: Maybe<Scalars["String"]>;
  Streetname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Tradinglicensenumber?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rcapture_RPTlessors" */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Aggregate = {
  __typename?: "rtcs_db_PH_rcapture_RPTlessors_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rcapture_RpTlessors>;
};

/** aggregate fields of "rtcs_db.PH_rcapture_RPTlessors" */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTlessors_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rcapture_RPTlessors" */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTlessors_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rcapture_RPTlessors" */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rcapture_RPTlessors" */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rcapture_RpTlessors_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Avg_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTlessors_avg_fields";
  Cin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rcapture_RPTlessors" */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Avg_Order_By = {
  Cin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rcapture_RPTlessors". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Bool_Exp = {
  Buildingname?: Maybe<String_Comparison_Exp>;
  Businesstradingname?: Maybe<String_Comparison_Exp>;
  Cin?: Maybe<Float8_Comparison_Exp>;
  County?: Maybe<String_Comparison_Exp>;
  Createdby?: Maybe<String_Comparison_Exp>;
  Customerid?: Maybe<String_Comparison_Exp>;
  Customeridsrc?: Maybe<String_Comparison_Exp>;
  Datecreated?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  Identifier?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Lastmodified?: Maybe<Timestamptz_Comparison_Exp>;
  Lastname?: Maybe<String_Comparison_Exp>;
  Legalbusinessname?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Modifiedby?: Maybe<String_Comparison_Exp>;
  Nationality?: Maybe<String_Comparison_Exp>;
  Parish?: Maybe<String_Comparison_Exp>;
  Phone1?: Maybe<String_Comparison_Exp>;
  Phone2?: Maybe<String_Comparison_Exp>;
  Phone3?: Maybe<String_Comparison_Exp>;
  Phone4?: Maybe<String_Comparison_Exp>;
  Plotnumber?: Maybe<String_Comparison_Exp>;
  Positionincompany?: Maybe<String_Comparison_Exp>;
  Postaldistrict?: Maybe<String_Comparison_Exp>;
  Postalpobox?: Maybe<String_Comparison_Exp>;
  Propertiesuuids?: Maybe<String_Comparison_Exp>;
  Region?: Maybe<String_Comparison_Exp>;
  Registeredbusinessnumber?: Maybe<String_Comparison_Exp>;
  Repdob?: Maybe<Timestamptz_Comparison_Exp>;
  Repfirstname?: Maybe<String_Comparison_Exp>;
  Replastname?: Maybe<String_Comparison_Exp>;
  Repnationality?: Maybe<String_Comparison_Exp>;
  Repothercountry?: Maybe<String_Comparison_Exp>;
  Repphone1?: Maybe<String_Comparison_Exp>;
  Reptitle?: Maybe<String_Comparison_Exp>;
  Sguid?: Maybe<String_Comparison_Exp>;
  Streetname?: Maybe<String_Comparison_Exp>;
  Subcounty?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<Float8_Comparison_Exp>;
  Title?: Maybe<String_Comparison_Exp>;
  Tradinglicensenumber?: Maybe<String_Comparison_Exp>;
  Type?: Maybe<String_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rcapture_RPTlessors" */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Inc_Input = {
  Cin?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Tin?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rcapture_RPTlessors" */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Insert_Input = {
  Buildingname?: Maybe<Scalars["String"]>;
  Businesstradingname?: Maybe<Scalars["String"]>;
  Cin?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Customeridsrc?: Maybe<Scalars["String"]>;
  Datecreated?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalbusinessname?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Nationality?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Phone1?: Maybe<Scalars["String"]>;
  Phone2?: Maybe<Scalars["String"]>;
  Phone3?: Maybe<Scalars["String"]>;
  Phone4?: Maybe<Scalars["String"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Positionincompany?: Maybe<Scalars["String"]>;
  Postaldistrict?: Maybe<Scalars["String"]>;
  Postalpobox?: Maybe<Scalars["String"]>;
  Propertiesuuids?: Maybe<Scalars["String"]>;
  Region?: Maybe<Scalars["String"]>;
  Registeredbusinessnumber?: Maybe<Scalars["String"]>;
  Repdob?: Maybe<Scalars["timestamptz"]>;
  Repfirstname?: Maybe<Scalars["String"]>;
  Replastname?: Maybe<Scalars["String"]>;
  Repnationality?: Maybe<Scalars["String"]>;
  Repothercountry?: Maybe<Scalars["String"]>;
  Repphone1?: Maybe<Scalars["String"]>;
  Reptitle?: Maybe<Scalars["String"]>;
  Sguid?: Maybe<Scalars["String"]>;
  Streetname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Tradinglicensenumber?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Max_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTlessors_max_fields";
  Buildingname?: Maybe<Scalars["String"]>;
  Businesstradingname?: Maybe<Scalars["String"]>;
  Cin?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Customeridsrc?: Maybe<Scalars["String"]>;
  Datecreated?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalbusinessname?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Nationality?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Phone1?: Maybe<Scalars["String"]>;
  Phone2?: Maybe<Scalars["String"]>;
  Phone3?: Maybe<Scalars["String"]>;
  Phone4?: Maybe<Scalars["String"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Positionincompany?: Maybe<Scalars["String"]>;
  Postaldistrict?: Maybe<Scalars["String"]>;
  Postalpobox?: Maybe<Scalars["String"]>;
  Propertiesuuids?: Maybe<Scalars["String"]>;
  Region?: Maybe<Scalars["String"]>;
  Registeredbusinessnumber?: Maybe<Scalars["String"]>;
  Repdob?: Maybe<Scalars["timestamptz"]>;
  Repfirstname?: Maybe<Scalars["String"]>;
  Replastname?: Maybe<Scalars["String"]>;
  Repnationality?: Maybe<Scalars["String"]>;
  Repothercountry?: Maybe<Scalars["String"]>;
  Repphone1?: Maybe<Scalars["String"]>;
  Reptitle?: Maybe<Scalars["String"]>;
  Sguid?: Maybe<Scalars["String"]>;
  Streetname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Tradinglicensenumber?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rcapture_RPTlessors" */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Max_Order_By = {
  Buildingname?: Maybe<Order_By>;
  Businesstradingname?: Maybe<Order_By>;
  Cin?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Createdby?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Customeridsrc?: Maybe<Order_By>;
  Datecreated?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Identifier?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastmodified?: Maybe<Order_By>;
  Lastname?: Maybe<Order_By>;
  Legalbusinessname?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Modifiedby?: Maybe<Order_By>;
  Nationality?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Phone1?: Maybe<Order_By>;
  Phone2?: Maybe<Order_By>;
  Phone3?: Maybe<Order_By>;
  Phone4?: Maybe<Order_By>;
  Plotnumber?: Maybe<Order_By>;
  Positionincompany?: Maybe<Order_By>;
  Postaldistrict?: Maybe<Order_By>;
  Postalpobox?: Maybe<Order_By>;
  Propertiesuuids?: Maybe<Order_By>;
  Region?: Maybe<Order_By>;
  Registeredbusinessnumber?: Maybe<Order_By>;
  Repdob?: Maybe<Order_By>;
  Repfirstname?: Maybe<Order_By>;
  Replastname?: Maybe<Order_By>;
  Repnationality?: Maybe<Order_By>;
  Repothercountry?: Maybe<Order_By>;
  Repphone1?: Maybe<Order_By>;
  Reptitle?: Maybe<Order_By>;
  Sguid?: Maybe<Order_By>;
  Streetname?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Title?: Maybe<Order_By>;
  Tradinglicensenumber?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Min_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTlessors_min_fields";
  Buildingname?: Maybe<Scalars["String"]>;
  Businesstradingname?: Maybe<Scalars["String"]>;
  Cin?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Customeridsrc?: Maybe<Scalars["String"]>;
  Datecreated?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalbusinessname?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Nationality?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Phone1?: Maybe<Scalars["String"]>;
  Phone2?: Maybe<Scalars["String"]>;
  Phone3?: Maybe<Scalars["String"]>;
  Phone4?: Maybe<Scalars["String"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Positionincompany?: Maybe<Scalars["String"]>;
  Postaldistrict?: Maybe<Scalars["String"]>;
  Postalpobox?: Maybe<Scalars["String"]>;
  Propertiesuuids?: Maybe<Scalars["String"]>;
  Region?: Maybe<Scalars["String"]>;
  Registeredbusinessnumber?: Maybe<Scalars["String"]>;
  Repdob?: Maybe<Scalars["timestamptz"]>;
  Repfirstname?: Maybe<Scalars["String"]>;
  Replastname?: Maybe<Scalars["String"]>;
  Repnationality?: Maybe<Scalars["String"]>;
  Repothercountry?: Maybe<Scalars["String"]>;
  Repphone1?: Maybe<Scalars["String"]>;
  Reptitle?: Maybe<Scalars["String"]>;
  Sguid?: Maybe<Scalars["String"]>;
  Streetname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Tradinglicensenumber?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rcapture_RPTlessors" */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Min_Order_By = {
  Buildingname?: Maybe<Order_By>;
  Businesstradingname?: Maybe<Order_By>;
  Cin?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Createdby?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Customeridsrc?: Maybe<Order_By>;
  Datecreated?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Identifier?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastmodified?: Maybe<Order_By>;
  Lastname?: Maybe<Order_By>;
  Legalbusinessname?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Modifiedby?: Maybe<Order_By>;
  Nationality?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Phone1?: Maybe<Order_By>;
  Phone2?: Maybe<Order_By>;
  Phone3?: Maybe<Order_By>;
  Phone4?: Maybe<Order_By>;
  Plotnumber?: Maybe<Order_By>;
  Positionincompany?: Maybe<Order_By>;
  Postaldistrict?: Maybe<Order_By>;
  Postalpobox?: Maybe<Order_By>;
  Propertiesuuids?: Maybe<Order_By>;
  Region?: Maybe<Order_By>;
  Registeredbusinessnumber?: Maybe<Order_By>;
  Repdob?: Maybe<Order_By>;
  Repfirstname?: Maybe<Order_By>;
  Replastname?: Maybe<Order_By>;
  Repnationality?: Maybe<Order_By>;
  Repothercountry?: Maybe<Order_By>;
  Repphone1?: Maybe<Order_By>;
  Reptitle?: Maybe<Order_By>;
  Sguid?: Maybe<Order_By>;
  Streetname?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Title?: Maybe<Order_By>;
  Tradinglicensenumber?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rcapture_RPTlessors" */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Mutation_Response = {
  __typename?: "rtcs_db_PH_rcapture_RPTlessors_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rcapture_RpTlessors>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rcapture_RPTlessors" */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rcapture_RpTlessors_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rcapture_RPTlessors" */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Order_By = {
  Buildingname?: Maybe<Order_By>;
  Businesstradingname?: Maybe<Order_By>;
  Cin?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Createdby?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Customeridsrc?: Maybe<Order_By>;
  Datecreated?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Identifier?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastmodified?: Maybe<Order_By>;
  Lastname?: Maybe<Order_By>;
  Legalbusinessname?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Modifiedby?: Maybe<Order_By>;
  Nationality?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Phone1?: Maybe<Order_By>;
  Phone2?: Maybe<Order_By>;
  Phone3?: Maybe<Order_By>;
  Phone4?: Maybe<Order_By>;
  Plotnumber?: Maybe<Order_By>;
  Positionincompany?: Maybe<Order_By>;
  Postaldistrict?: Maybe<Order_By>;
  Postalpobox?: Maybe<Order_By>;
  Propertiesuuids?: Maybe<Order_By>;
  Region?: Maybe<Order_By>;
  Registeredbusinessnumber?: Maybe<Order_By>;
  Repdob?: Maybe<Order_By>;
  Repfirstname?: Maybe<Order_By>;
  Replastname?: Maybe<Order_By>;
  Repnationality?: Maybe<Order_By>;
  Repothercountry?: Maybe<Order_By>;
  Repphone1?: Maybe<Order_By>;
  Reptitle?: Maybe<Order_By>;
  Sguid?: Maybe<Order_By>;
  Streetname?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Title?: Maybe<Order_By>;
  Tradinglicensenumber?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rcapture_RPTlessors" */
export enum Rtcs_Db_Ph_Rcapture_RpTlessors_Select_Column {
  /** column name */
  Buildingname = "Buildingname",
  /** column name */
  Businesstradingname = "Businesstradingname",
  /** column name */
  Cin = "Cin",
  /** column name */
  County = "County",
  /** column name */
  Createdby = "Createdby",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  Customeridsrc = "Customeridsrc",
  /** column name */
  Datecreated = "Datecreated",
  /** column name */
  District = "District",
  /** column name */
  Email = "Email",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Id = "Id",
  /** column name */
  Identifier = "Identifier",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Lastmodified = "Lastmodified",
  /** column name */
  Lastname = "Lastname",
  /** column name */
  Legalbusinessname = "Legalbusinessname",
  /** column name */
  Line = "Line",
  /** column name */
  Modifiedby = "Modifiedby",
  /** column name */
  Nationality = "Nationality",
  /** column name */
  Parish = "Parish",
  /** column name */
  Phone1 = "Phone1",
  /** column name */
  Phone2 = "Phone2",
  /** column name */
  Phone3 = "Phone3",
  /** column name */
  Phone4 = "Phone4",
  /** column name */
  Plotnumber = "Plotnumber",
  /** column name */
  Positionincompany = "Positionincompany",
  /** column name */
  Postaldistrict = "Postaldistrict",
  /** column name */
  Postalpobox = "Postalpobox",
  /** column name */
  Propertiesuuids = "Propertiesuuids",
  /** column name */
  Region = "Region",
  /** column name */
  Registeredbusinessnumber = "Registeredbusinessnumber",
  /** column name */
  Repdob = "Repdob",
  /** column name */
  Repfirstname = "Repfirstname",
  /** column name */
  Replastname = "Replastname",
  /** column name */
  Repnationality = "Repnationality",
  /** column name */
  Repothercountry = "Repothercountry",
  /** column name */
  Repphone1 = "Repphone1",
  /** column name */
  Reptitle = "Reptitle",
  /** column name */
  Sguid = "Sguid",
  /** column name */
  Streetname = "Streetname",
  /** column name */
  Subcounty = "Subcounty",
  /** column name */
  Tin = "Tin",
  /** column name */
  Title = "Title",
  /** column name */
  Tradinglicensenumber = "Tradinglicensenumber",
  /** column name */
  Type = "Type",
  /** column name */
  Village = "Village",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rcapture_RPTlessors" */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Set_Input = {
  Buildingname?: Maybe<Scalars["String"]>;
  Businesstradingname?: Maybe<Scalars["String"]>;
  Cin?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Customeridsrc?: Maybe<Scalars["String"]>;
  Datecreated?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalbusinessname?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Nationality?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Phone1?: Maybe<Scalars["String"]>;
  Phone2?: Maybe<Scalars["String"]>;
  Phone3?: Maybe<Scalars["String"]>;
  Phone4?: Maybe<Scalars["String"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Positionincompany?: Maybe<Scalars["String"]>;
  Postaldistrict?: Maybe<Scalars["String"]>;
  Postalpobox?: Maybe<Scalars["String"]>;
  Propertiesuuids?: Maybe<Scalars["String"]>;
  Region?: Maybe<Scalars["String"]>;
  Registeredbusinessnumber?: Maybe<Scalars["String"]>;
  Repdob?: Maybe<Scalars["timestamptz"]>;
  Repfirstname?: Maybe<Scalars["String"]>;
  Replastname?: Maybe<Scalars["String"]>;
  Repnationality?: Maybe<Scalars["String"]>;
  Repothercountry?: Maybe<Scalars["String"]>;
  Repphone1?: Maybe<Scalars["String"]>;
  Reptitle?: Maybe<Scalars["String"]>;
  Sguid?: Maybe<Scalars["String"]>;
  Streetname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Tradinglicensenumber?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTlessors_stddev_fields";
  Cin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rcapture_RPTlessors" */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Stddev_Order_By = {
  Cin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTlessors_stddev_pop_fields";
  Cin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rcapture_RPTlessors" */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Stddev_Pop_Order_By = {
  Cin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTlessors_stddev_samp_fields";
  Cin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rcapture_RPTlessors" */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Stddev_Samp_Order_By = {
  Cin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Sum_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTlessors_sum_fields";
  Cin?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Tin?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rcapture_RPTlessors" */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Sum_Order_By = {
  Cin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTlessors_var_pop_fields";
  Cin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rcapture_RPTlessors" */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Var_Pop_Order_By = {
  Cin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTlessors_var_samp_fields";
  Cin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rcapture_RPTlessors" */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Var_Samp_Order_By = {
  Cin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Variance_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTlessors_variance_fields";
  Cin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rcapture_RPTlessors" */
export type Rtcs_Db_Ph_Rcapture_RpTlessors_Variance_Order_By = {
  Cin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rcapture_RPTmeta" */
export type Rtcs_Db_Ph_Rcapture_RpTmeta = {
  __typename?: "rtcs_db_PH_rcapture_RPTmeta";
  Appversion?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  Datecreated?: Maybe<Scalars["timestamptz"]>;
  Devicename?: Maybe<Scalars["String"]>;
  Deviceos?: Maybe<Scalars["String"]>;
  Deviceosversion?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Localdate?: Maybe<Scalars["timestamptz"]>;
  Localtimestamp?: Maybe<Scalars["timestamptz"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Orgname?: Maybe<Scalars["String"]>;
  Screendate?: Maybe<Scalars["String"]>;
  Screenversion?: Maybe<Scalars["float8"]>;
  Useremail?: Maybe<Scalars["String"]>;
  Username?: Maybe<Scalars["String"]>;
  Utcshorttimestamp?: Maybe<Scalars["timestamptz"]>;
  Utctimestamp?: Maybe<Scalars["timestamptz"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rcapture_RPTmeta" */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Aggregate = {
  __typename?: "rtcs_db_PH_rcapture_RPTmeta_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rcapture_RpTmeta>;
};

/** aggregate fields of "rtcs_db.PH_rcapture_RPTmeta" */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTmeta_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rcapture_RPTmeta" */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTmeta_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rcapture_RPTmeta" */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rcapture_RPTmeta" */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rcapture_RpTmeta_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Avg_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTmeta_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
  Screenversion?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rcapture_RPTmeta" */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Avg_Order_By = {
  Line?: Maybe<Order_By>;
  Screenversion?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rcapture_RPTmeta". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Bool_Exp = {
  Appversion?: Maybe<String_Comparison_Exp>;
  Createdby?: Maybe<String_Comparison_Exp>;
  Datecreated?: Maybe<Timestamptz_Comparison_Exp>;
  Devicename?: Maybe<String_Comparison_Exp>;
  Deviceos?: Maybe<String_Comparison_Exp>;
  Deviceosversion?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Lastmodified?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Localdate?: Maybe<Timestamptz_Comparison_Exp>;
  Localtimestamp?: Maybe<Timestamptz_Comparison_Exp>;
  Modifiedby?: Maybe<String_Comparison_Exp>;
  Notes?: Maybe<String_Comparison_Exp>;
  Orgname?: Maybe<String_Comparison_Exp>;
  Screendate?: Maybe<String_Comparison_Exp>;
  Screenversion?: Maybe<Float8_Comparison_Exp>;
  Useremail?: Maybe<String_Comparison_Exp>;
  Username?: Maybe<String_Comparison_Exp>;
  Utcshorttimestamp?: Maybe<Timestamptz_Comparison_Exp>;
  Utctimestamp?: Maybe<Timestamptz_Comparison_Exp>;
  Uuidproperty?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rcapture_RPTmeta" */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
  Screenversion?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rcapture_RPTmeta" */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Insert_Input = {
  Appversion?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  Datecreated?: Maybe<Scalars["timestamptz"]>;
  Devicename?: Maybe<Scalars["String"]>;
  Deviceos?: Maybe<Scalars["String"]>;
  Deviceosversion?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Localdate?: Maybe<Scalars["timestamptz"]>;
  Localtimestamp?: Maybe<Scalars["timestamptz"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Orgname?: Maybe<Scalars["String"]>;
  Screendate?: Maybe<Scalars["String"]>;
  Screenversion?: Maybe<Scalars["float8"]>;
  Useremail?: Maybe<Scalars["String"]>;
  Username?: Maybe<Scalars["String"]>;
  Utcshorttimestamp?: Maybe<Scalars["timestamptz"]>;
  Utctimestamp?: Maybe<Scalars["timestamptz"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Max_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTmeta_max_fields";
  Appversion?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  Datecreated?: Maybe<Scalars["timestamptz"]>;
  Devicename?: Maybe<Scalars["String"]>;
  Deviceos?: Maybe<Scalars["String"]>;
  Deviceosversion?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Localdate?: Maybe<Scalars["timestamptz"]>;
  Localtimestamp?: Maybe<Scalars["timestamptz"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Orgname?: Maybe<Scalars["String"]>;
  Screendate?: Maybe<Scalars["String"]>;
  Screenversion?: Maybe<Scalars["float8"]>;
  Useremail?: Maybe<Scalars["String"]>;
  Username?: Maybe<Scalars["String"]>;
  Utcshorttimestamp?: Maybe<Scalars["timestamptz"]>;
  Utctimestamp?: Maybe<Scalars["timestamptz"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rcapture_RPTmeta" */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Max_Order_By = {
  Appversion?: Maybe<Order_By>;
  Createdby?: Maybe<Order_By>;
  Datecreated?: Maybe<Order_By>;
  Devicename?: Maybe<Order_By>;
  Deviceos?: Maybe<Order_By>;
  Deviceosversion?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastmodified?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Localdate?: Maybe<Order_By>;
  Localtimestamp?: Maybe<Order_By>;
  Modifiedby?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  Orgname?: Maybe<Order_By>;
  Screendate?: Maybe<Order_By>;
  Screenversion?: Maybe<Order_By>;
  Useremail?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
  Utcshorttimestamp?: Maybe<Order_By>;
  Utctimestamp?: Maybe<Order_By>;
  Uuidproperty?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Min_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTmeta_min_fields";
  Appversion?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  Datecreated?: Maybe<Scalars["timestamptz"]>;
  Devicename?: Maybe<Scalars["String"]>;
  Deviceos?: Maybe<Scalars["String"]>;
  Deviceosversion?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Localdate?: Maybe<Scalars["timestamptz"]>;
  Localtimestamp?: Maybe<Scalars["timestamptz"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Orgname?: Maybe<Scalars["String"]>;
  Screendate?: Maybe<Scalars["String"]>;
  Screenversion?: Maybe<Scalars["float8"]>;
  Useremail?: Maybe<Scalars["String"]>;
  Username?: Maybe<Scalars["String"]>;
  Utcshorttimestamp?: Maybe<Scalars["timestamptz"]>;
  Utctimestamp?: Maybe<Scalars["timestamptz"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rcapture_RPTmeta" */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Min_Order_By = {
  Appversion?: Maybe<Order_By>;
  Createdby?: Maybe<Order_By>;
  Datecreated?: Maybe<Order_By>;
  Devicename?: Maybe<Order_By>;
  Deviceos?: Maybe<Order_By>;
  Deviceosversion?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastmodified?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Localdate?: Maybe<Order_By>;
  Localtimestamp?: Maybe<Order_By>;
  Modifiedby?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  Orgname?: Maybe<Order_By>;
  Screendate?: Maybe<Order_By>;
  Screenversion?: Maybe<Order_By>;
  Useremail?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
  Utcshorttimestamp?: Maybe<Order_By>;
  Utctimestamp?: Maybe<Order_By>;
  Uuidproperty?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rcapture_RPTmeta" */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Mutation_Response = {
  __typename?: "rtcs_db_PH_rcapture_RPTmeta_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rcapture_RpTmeta>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rcapture_RPTmeta" */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rcapture_RpTmeta_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rcapture_RPTmeta" */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Order_By = {
  Appversion?: Maybe<Order_By>;
  Createdby?: Maybe<Order_By>;
  Datecreated?: Maybe<Order_By>;
  Devicename?: Maybe<Order_By>;
  Deviceos?: Maybe<Order_By>;
  Deviceosversion?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastmodified?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Localdate?: Maybe<Order_By>;
  Localtimestamp?: Maybe<Order_By>;
  Modifiedby?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  Orgname?: Maybe<Order_By>;
  Screendate?: Maybe<Order_By>;
  Screenversion?: Maybe<Order_By>;
  Useremail?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
  Utcshorttimestamp?: Maybe<Order_By>;
  Utctimestamp?: Maybe<Order_By>;
  Uuidproperty?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rcapture_RPTmeta" */
export enum Rtcs_Db_Ph_Rcapture_RpTmeta_Select_Column {
  /** column name */
  Appversion = "Appversion",
  /** column name */
  Createdby = "Createdby",
  /** column name */
  Datecreated = "Datecreated",
  /** column name */
  Devicename = "Devicename",
  /** column name */
  Deviceos = "Deviceos",
  /** column name */
  Deviceosversion = "Deviceosversion",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Lastmodified = "Lastmodified",
  /** column name */
  Line = "Line",
  /** column name */
  Localdate = "Localdate",
  /** column name */
  Localtimestamp = "Localtimestamp",
  /** column name */
  Modifiedby = "Modifiedby",
  /** column name */
  Notes = "Notes",
  /** column name */
  Orgname = "Orgname",
  /** column name */
  Screendate = "Screendate",
  /** column name */
  Screenversion = "Screenversion",
  /** column name */
  Useremail = "Useremail",
  /** column name */
  Username = "Username",
  /** column name */
  Utcshorttimestamp = "Utcshorttimestamp",
  /** column name */
  Utctimestamp = "Utctimestamp",
  /** column name */
  Uuidproperty = "Uuidproperty",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rcapture_RPTmeta" */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Set_Input = {
  Appversion?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  Datecreated?: Maybe<Scalars["timestamptz"]>;
  Devicename?: Maybe<Scalars["String"]>;
  Deviceos?: Maybe<Scalars["String"]>;
  Deviceosversion?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Localdate?: Maybe<Scalars["timestamptz"]>;
  Localtimestamp?: Maybe<Scalars["timestamptz"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Orgname?: Maybe<Scalars["String"]>;
  Screendate?: Maybe<Scalars["String"]>;
  Screenversion?: Maybe<Scalars["float8"]>;
  Useremail?: Maybe<Scalars["String"]>;
  Username?: Maybe<Scalars["String"]>;
  Utcshorttimestamp?: Maybe<Scalars["timestamptz"]>;
  Utctimestamp?: Maybe<Scalars["timestamptz"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTmeta_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
  Screenversion?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rcapture_RPTmeta" */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
  Screenversion?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTmeta_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  Screenversion?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rcapture_RPTmeta" */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  Screenversion?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTmeta_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  Screenversion?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rcapture_RPTmeta" */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  Screenversion?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Sum_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTmeta_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
  Screenversion?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rcapture_RPTmeta" */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Sum_Order_By = {
  Line?: Maybe<Order_By>;
  Screenversion?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTmeta_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  Screenversion?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rcapture_RPTmeta" */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  Screenversion?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTmeta_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  Screenversion?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rcapture_RPTmeta" */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  Screenversion?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Variance_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTmeta_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
  Screenversion?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rcapture_RPTmeta" */
export type Rtcs_Db_Ph_Rcapture_RpTmeta_Variance_Order_By = {
  Line?: Maybe<Order_By>;
  Screenversion?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rcapture_RPTproperties" */
export type Rtcs_Db_Ph_Rcapture_RpTproperties = {
  __typename?: "rtcs_db_PH_rcapture_RPTproperties";
  Blocknumber?: Maybe<Scalars["String"]>;
  Buildingcondition?: Maybe<Scalars["String"]>;
  Buildingname?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Found?: Maybe<Scalars["String"]>;
  Geolocation?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  Landlord1identifier?: Maybe<Scalars["String"]>;
  Landlotsize?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Lessor1firstname?: Maybe<Scalars["String"]>;
  Lessor1identifier?: Maybe<Scalars["String"]>;
  Lessor1lastname?: Maybe<Scalars["String"]>;
  Lessor2firstname?: Maybe<Scalars["String"]>;
  Lessor2identifier?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Numberunits?: Maybe<Scalars["bigint"]>;
  Outcome?: Maybe<Scalars["String"]>;
  Owner1identifier?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Pguid?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["String"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Propertyid?: Maybe<Scalars["String"]>;
  Propertyidsrc?: Maybe<Scalars["String"]>;
  Propertymanager1identifier?: Maybe<Scalars["String"]>;
  Region?: Maybe<Scalars["String"]>;
  Streetname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Subtype?: Maybe<Scalars["String"]>;
  Totallessors?: Maybe<Scalars["bigint"]>;
  Totaltenants?: Maybe<Scalars["bigint"]>;
  Totalunits?: Maybe<Scalars["bigint"]>;
  Type?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  Websiteurl?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rcapture_RPTproperties" */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Aggregate = {
  __typename?: "rtcs_db_PH_rcapture_RPTproperties_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rcapture_RpTproperties>;
};

/** aggregate fields of "rtcs_db.PH_rcapture_RPTproperties" */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTproperties_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rcapture_RPTproperties" */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTproperties_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rcapture_RPTproperties" */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rcapture_RPTproperties" */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rcapture_RpTproperties_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Avg_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTproperties_avg_fields";
  Landlotsize?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Numberunits?: Maybe<Scalars["Float"]>;
  Totallessors?: Maybe<Scalars["Float"]>;
  Totaltenants?: Maybe<Scalars["Float"]>;
  Totalunits?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rcapture_RPTproperties" */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Avg_Order_By = {
  Landlotsize?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Numberunits?: Maybe<Order_By>;
  Totallessors?: Maybe<Order_By>;
  Totaltenants?: Maybe<Order_By>;
  Totalunits?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rcapture_RPTproperties". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Bool_Exp = {
  Blocknumber?: Maybe<String_Comparison_Exp>;
  Buildingcondition?: Maybe<String_Comparison_Exp>;
  Buildingname?: Maybe<String_Comparison_Exp>;
  County?: Maybe<String_Comparison_Exp>;
  Createdby?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Found?: Maybe<String_Comparison_Exp>;
  Geolocation?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  Identifier?: Maybe<String_Comparison_Exp>;
  Landlord1identifier?: Maybe<String_Comparison_Exp>;
  Landlotsize?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Lastmodified?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Lessor1firstname?: Maybe<String_Comparison_Exp>;
  Lessor1identifier?: Maybe<String_Comparison_Exp>;
  Lessor1lastname?: Maybe<String_Comparison_Exp>;
  Lessor2firstname?: Maybe<String_Comparison_Exp>;
  Lessor2identifier?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  Modifiedby?: Maybe<String_Comparison_Exp>;
  Notes?: Maybe<String_Comparison_Exp>;
  Numberunits?: Maybe<Bigint_Comparison_Exp>;
  Outcome?: Maybe<String_Comparison_Exp>;
  Owner1identifier?: Maybe<String_Comparison_Exp>;
  Parish?: Maybe<String_Comparison_Exp>;
  Pguid?: Maybe<String_Comparison_Exp>;
  Photo?: Maybe<String_Comparison_Exp>;
  Plotnumber?: Maybe<String_Comparison_Exp>;
  Propertyid?: Maybe<String_Comparison_Exp>;
  Propertyidsrc?: Maybe<String_Comparison_Exp>;
  Propertymanager1identifier?: Maybe<String_Comparison_Exp>;
  Region?: Maybe<String_Comparison_Exp>;
  Streetname?: Maybe<String_Comparison_Exp>;
  Subcounty?: Maybe<String_Comparison_Exp>;
  Subtype?: Maybe<String_Comparison_Exp>;
  Totallessors?: Maybe<Bigint_Comparison_Exp>;
  Totaltenants?: Maybe<Bigint_Comparison_Exp>;
  Totalunits?: Maybe<Bigint_Comparison_Exp>;
  Type?: Maybe<String_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  Websiteurl?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rcapture_RPTproperties" */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Inc_Input = {
  Landlotsize?: Maybe<Scalars["bigint"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Numberunits?: Maybe<Scalars["bigint"]>;
  Totallessors?: Maybe<Scalars["bigint"]>;
  Totaltenants?: Maybe<Scalars["bigint"]>;
  Totalunits?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rcapture_RPTproperties" */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Insert_Input = {
  Blocknumber?: Maybe<Scalars["String"]>;
  Buildingcondition?: Maybe<Scalars["String"]>;
  Buildingname?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Found?: Maybe<Scalars["String"]>;
  Geolocation?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  Landlord1identifier?: Maybe<Scalars["String"]>;
  Landlotsize?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Lessor1firstname?: Maybe<Scalars["String"]>;
  Lessor1identifier?: Maybe<Scalars["String"]>;
  Lessor1lastname?: Maybe<Scalars["String"]>;
  Lessor2firstname?: Maybe<Scalars["String"]>;
  Lessor2identifier?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Numberunits?: Maybe<Scalars["bigint"]>;
  Outcome?: Maybe<Scalars["String"]>;
  Owner1identifier?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Pguid?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["String"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Propertyid?: Maybe<Scalars["String"]>;
  Propertyidsrc?: Maybe<Scalars["String"]>;
  Propertymanager1identifier?: Maybe<Scalars["String"]>;
  Region?: Maybe<Scalars["String"]>;
  Streetname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Subtype?: Maybe<Scalars["String"]>;
  Totallessors?: Maybe<Scalars["bigint"]>;
  Totaltenants?: Maybe<Scalars["bigint"]>;
  Totalunits?: Maybe<Scalars["bigint"]>;
  Type?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  Websiteurl?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Max_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTproperties_max_fields";
  Blocknumber?: Maybe<Scalars["String"]>;
  Buildingcondition?: Maybe<Scalars["String"]>;
  Buildingname?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Found?: Maybe<Scalars["String"]>;
  Geolocation?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  Landlord1identifier?: Maybe<Scalars["String"]>;
  Landlotsize?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Lessor1firstname?: Maybe<Scalars["String"]>;
  Lessor1identifier?: Maybe<Scalars["String"]>;
  Lessor1lastname?: Maybe<Scalars["String"]>;
  Lessor2firstname?: Maybe<Scalars["String"]>;
  Lessor2identifier?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Numberunits?: Maybe<Scalars["bigint"]>;
  Outcome?: Maybe<Scalars["String"]>;
  Owner1identifier?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Pguid?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["String"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Propertyid?: Maybe<Scalars["String"]>;
  Propertyidsrc?: Maybe<Scalars["String"]>;
  Propertymanager1identifier?: Maybe<Scalars["String"]>;
  Region?: Maybe<Scalars["String"]>;
  Streetname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Subtype?: Maybe<Scalars["String"]>;
  Totallessors?: Maybe<Scalars["bigint"]>;
  Totaltenants?: Maybe<Scalars["bigint"]>;
  Totalunits?: Maybe<Scalars["bigint"]>;
  Type?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  Websiteurl?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rcapture_RPTproperties" */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Max_Order_By = {
  Blocknumber?: Maybe<Order_By>;
  Buildingcondition?: Maybe<Order_By>;
  Buildingname?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Createdby?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Found?: Maybe<Order_By>;
  Geolocation?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Identifier?: Maybe<Order_By>;
  Landlord1identifier?: Maybe<Order_By>;
  Landlotsize?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastmodified?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Lessor1firstname?: Maybe<Order_By>;
  Lessor1identifier?: Maybe<Order_By>;
  Lessor1lastname?: Maybe<Order_By>;
  Lessor2firstname?: Maybe<Order_By>;
  Lessor2identifier?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Modifiedby?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  Numberunits?: Maybe<Order_By>;
  Outcome?: Maybe<Order_By>;
  Owner1identifier?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Pguid?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  Plotnumber?: Maybe<Order_By>;
  Propertyid?: Maybe<Order_By>;
  Propertyidsrc?: Maybe<Order_By>;
  Propertymanager1identifier?: Maybe<Order_By>;
  Region?: Maybe<Order_By>;
  Streetname?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Subtype?: Maybe<Order_By>;
  Totallessors?: Maybe<Order_By>;
  Totaltenants?: Maybe<Order_By>;
  Totalunits?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  Websiteurl?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Min_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTproperties_min_fields";
  Blocknumber?: Maybe<Scalars["String"]>;
  Buildingcondition?: Maybe<Scalars["String"]>;
  Buildingname?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Found?: Maybe<Scalars["String"]>;
  Geolocation?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  Landlord1identifier?: Maybe<Scalars["String"]>;
  Landlotsize?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Lessor1firstname?: Maybe<Scalars["String"]>;
  Lessor1identifier?: Maybe<Scalars["String"]>;
  Lessor1lastname?: Maybe<Scalars["String"]>;
  Lessor2firstname?: Maybe<Scalars["String"]>;
  Lessor2identifier?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Numberunits?: Maybe<Scalars["bigint"]>;
  Outcome?: Maybe<Scalars["String"]>;
  Owner1identifier?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Pguid?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["String"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Propertyid?: Maybe<Scalars["String"]>;
  Propertyidsrc?: Maybe<Scalars["String"]>;
  Propertymanager1identifier?: Maybe<Scalars["String"]>;
  Region?: Maybe<Scalars["String"]>;
  Streetname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Subtype?: Maybe<Scalars["String"]>;
  Totallessors?: Maybe<Scalars["bigint"]>;
  Totaltenants?: Maybe<Scalars["bigint"]>;
  Totalunits?: Maybe<Scalars["bigint"]>;
  Type?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  Websiteurl?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rcapture_RPTproperties" */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Min_Order_By = {
  Blocknumber?: Maybe<Order_By>;
  Buildingcondition?: Maybe<Order_By>;
  Buildingname?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Createdby?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Found?: Maybe<Order_By>;
  Geolocation?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Identifier?: Maybe<Order_By>;
  Landlord1identifier?: Maybe<Order_By>;
  Landlotsize?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastmodified?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Lessor1firstname?: Maybe<Order_By>;
  Lessor1identifier?: Maybe<Order_By>;
  Lessor1lastname?: Maybe<Order_By>;
  Lessor2firstname?: Maybe<Order_By>;
  Lessor2identifier?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Modifiedby?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  Numberunits?: Maybe<Order_By>;
  Outcome?: Maybe<Order_By>;
  Owner1identifier?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Pguid?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  Plotnumber?: Maybe<Order_By>;
  Propertyid?: Maybe<Order_By>;
  Propertyidsrc?: Maybe<Order_By>;
  Propertymanager1identifier?: Maybe<Order_By>;
  Region?: Maybe<Order_By>;
  Streetname?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Subtype?: Maybe<Order_By>;
  Totallessors?: Maybe<Order_By>;
  Totaltenants?: Maybe<Order_By>;
  Totalunits?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  Websiteurl?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rcapture_RPTproperties" */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Mutation_Response = {
  __typename?: "rtcs_db_PH_rcapture_RPTproperties_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rcapture_RpTproperties>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rcapture_RPTproperties" */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rcapture_RpTproperties_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rcapture_RPTproperties" */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Order_By = {
  Blocknumber?: Maybe<Order_By>;
  Buildingcondition?: Maybe<Order_By>;
  Buildingname?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Createdby?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Found?: Maybe<Order_By>;
  Geolocation?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Identifier?: Maybe<Order_By>;
  Landlord1identifier?: Maybe<Order_By>;
  Landlotsize?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastmodified?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Lessor1firstname?: Maybe<Order_By>;
  Lessor1identifier?: Maybe<Order_By>;
  Lessor1lastname?: Maybe<Order_By>;
  Lessor2firstname?: Maybe<Order_By>;
  Lessor2identifier?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Modifiedby?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  Numberunits?: Maybe<Order_By>;
  Outcome?: Maybe<Order_By>;
  Owner1identifier?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Pguid?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  Plotnumber?: Maybe<Order_By>;
  Propertyid?: Maybe<Order_By>;
  Propertyidsrc?: Maybe<Order_By>;
  Propertymanager1identifier?: Maybe<Order_By>;
  Region?: Maybe<Order_By>;
  Streetname?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Subtype?: Maybe<Order_By>;
  Totallessors?: Maybe<Order_By>;
  Totaltenants?: Maybe<Order_By>;
  Totalunits?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  Websiteurl?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rcapture_RPTproperties" */
export enum Rtcs_Db_Ph_Rcapture_RpTproperties_Select_Column {
  /** column name */
  Blocknumber = "Blocknumber",
  /** column name */
  Buildingcondition = "Buildingcondition",
  /** column name */
  Buildingname = "Buildingname",
  /** column name */
  County = "County",
  /** column name */
  Createdby = "Createdby",
  /** column name */
  District = "District",
  /** column name */
  File = "File",
  /** column name */
  Found = "Found",
  /** column name */
  Geolocation = "Geolocation",
  /** column name */
  Id = "Id",
  /** column name */
  Identifier = "Identifier",
  /** column name */
  Landlord1identifier = "Landlord1identifier",
  /** column name */
  Landlotsize = "Landlotsize",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Lastmodified = "Lastmodified",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Lessor1firstname = "Lessor1firstname",
  /** column name */
  Lessor1identifier = "Lessor1identifier",
  /** column name */
  Lessor1lastname = "Lessor1lastname",
  /** column name */
  Lessor2firstname = "Lessor2firstname",
  /** column name */
  Lessor2identifier = "Lessor2identifier",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  Modifiedby = "Modifiedby",
  /** column name */
  Notes = "Notes",
  /** column name */
  Numberunits = "Numberunits",
  /** column name */
  Outcome = "Outcome",
  /** column name */
  Owner1identifier = "Owner1identifier",
  /** column name */
  Parish = "Parish",
  /** column name */
  Pguid = "Pguid",
  /** column name */
  Photo = "Photo",
  /** column name */
  Plotnumber = "Plotnumber",
  /** column name */
  Propertyid = "Propertyid",
  /** column name */
  Propertyidsrc = "Propertyidsrc",
  /** column name */
  Propertymanager1identifier = "Propertymanager1identifier",
  /** column name */
  Region = "Region",
  /** column name */
  Streetname = "Streetname",
  /** column name */
  Subcounty = "Subcounty",
  /** column name */
  Subtype = "Subtype",
  /** column name */
  Totallessors = "Totallessors",
  /** column name */
  Totaltenants = "Totaltenants",
  /** column name */
  Totalunits = "Totalunits",
  /** column name */
  Type = "Type",
  /** column name */
  Village = "Village",
  /** column name */
  Websiteurl = "Websiteurl",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rcapture_RPTproperties" */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Set_Input = {
  Blocknumber?: Maybe<Scalars["String"]>;
  Buildingcondition?: Maybe<Scalars["String"]>;
  Buildingname?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Found?: Maybe<Scalars["String"]>;
  Geolocation?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  Landlord1identifier?: Maybe<Scalars["String"]>;
  Landlotsize?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Lessor1firstname?: Maybe<Scalars["String"]>;
  Lessor1identifier?: Maybe<Scalars["String"]>;
  Lessor1lastname?: Maybe<Scalars["String"]>;
  Lessor2firstname?: Maybe<Scalars["String"]>;
  Lessor2identifier?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Numberunits?: Maybe<Scalars["bigint"]>;
  Outcome?: Maybe<Scalars["String"]>;
  Owner1identifier?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Pguid?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["String"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Propertyid?: Maybe<Scalars["String"]>;
  Propertyidsrc?: Maybe<Scalars["String"]>;
  Propertymanager1identifier?: Maybe<Scalars["String"]>;
  Region?: Maybe<Scalars["String"]>;
  Streetname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Subtype?: Maybe<Scalars["String"]>;
  Totallessors?: Maybe<Scalars["bigint"]>;
  Totaltenants?: Maybe<Scalars["bigint"]>;
  Totalunits?: Maybe<Scalars["bigint"]>;
  Type?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  Websiteurl?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTproperties_stddev_fields";
  Landlotsize?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Numberunits?: Maybe<Scalars["Float"]>;
  Totallessors?: Maybe<Scalars["Float"]>;
  Totaltenants?: Maybe<Scalars["Float"]>;
  Totalunits?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rcapture_RPTproperties" */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Stddev_Order_By = {
  Landlotsize?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Numberunits?: Maybe<Order_By>;
  Totallessors?: Maybe<Order_By>;
  Totaltenants?: Maybe<Order_By>;
  Totalunits?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTproperties_stddev_pop_fields";
  Landlotsize?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Numberunits?: Maybe<Scalars["Float"]>;
  Totallessors?: Maybe<Scalars["Float"]>;
  Totaltenants?: Maybe<Scalars["Float"]>;
  Totalunits?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rcapture_RPTproperties" */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Stddev_Pop_Order_By = {
  Landlotsize?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Numberunits?: Maybe<Order_By>;
  Totallessors?: Maybe<Order_By>;
  Totaltenants?: Maybe<Order_By>;
  Totalunits?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTproperties_stddev_samp_fields";
  Landlotsize?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Numberunits?: Maybe<Scalars["Float"]>;
  Totallessors?: Maybe<Scalars["Float"]>;
  Totaltenants?: Maybe<Scalars["Float"]>;
  Totalunits?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rcapture_RPTproperties" */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Stddev_Samp_Order_By = {
  Landlotsize?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Numberunits?: Maybe<Order_By>;
  Totallessors?: Maybe<Order_By>;
  Totaltenants?: Maybe<Order_By>;
  Totalunits?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Sum_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTproperties_sum_fields";
  Landlotsize?: Maybe<Scalars["bigint"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Numberunits?: Maybe<Scalars["bigint"]>;
  Totallessors?: Maybe<Scalars["bigint"]>;
  Totaltenants?: Maybe<Scalars["bigint"]>;
  Totalunits?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rcapture_RPTproperties" */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Sum_Order_By = {
  Landlotsize?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Numberunits?: Maybe<Order_By>;
  Totallessors?: Maybe<Order_By>;
  Totaltenants?: Maybe<Order_By>;
  Totalunits?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTproperties_var_pop_fields";
  Landlotsize?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Numberunits?: Maybe<Scalars["Float"]>;
  Totallessors?: Maybe<Scalars["Float"]>;
  Totaltenants?: Maybe<Scalars["Float"]>;
  Totalunits?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rcapture_RPTproperties" */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Var_Pop_Order_By = {
  Landlotsize?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Numberunits?: Maybe<Order_By>;
  Totallessors?: Maybe<Order_By>;
  Totaltenants?: Maybe<Order_By>;
  Totalunits?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTproperties_var_samp_fields";
  Landlotsize?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Numberunits?: Maybe<Scalars["Float"]>;
  Totallessors?: Maybe<Scalars["Float"]>;
  Totaltenants?: Maybe<Scalars["Float"]>;
  Totalunits?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rcapture_RPTproperties" */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Var_Samp_Order_By = {
  Landlotsize?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Numberunits?: Maybe<Order_By>;
  Totallessors?: Maybe<Order_By>;
  Totaltenants?: Maybe<Order_By>;
  Totalunits?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Variance_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTproperties_variance_fields";
  Landlotsize?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Numberunits?: Maybe<Scalars["Float"]>;
  Totallessors?: Maybe<Scalars["Float"]>;
  Totaltenants?: Maybe<Scalars["Float"]>;
  Totalunits?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rcapture_RPTproperties" */
export type Rtcs_Db_Ph_Rcapture_RpTproperties_Variance_Order_By = {
  Landlotsize?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Numberunits?: Maybe<Order_By>;
  Totallessors?: Maybe<Order_By>;
  Totaltenants?: Maybe<Order_By>;
  Totalunits?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rcapture_RPTtenants" */
export type Rtcs_Db_Ph_Rcapture_RpTtenants = {
  __typename?: "rtcs_db_PH_rcapture_RPTtenants";
  Actualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Amountofrentreceipts?: Maybe<Scalars["float8"]>;
  Businesstradingname?: Maybe<Scalars["String"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Currencyactualrent?: Maybe<Scalars["String"]>;
  Currencyreportedrent?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Estimatedshopsize?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Floornumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  Isunitlet?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalbusinessname?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mda?: Maybe<Scalars["String"]>;
  Nationality?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Owneroccupiedorrented?: Maybe<Scalars["String"]>;
  Phone1?: Maybe<Scalars["String"]>;
  Phone2?: Maybe<Scalars["String"]>;
  Phone3?: Maybe<Scalars["String"]>;
  Phone4?: Maybe<Scalars["String"]>;
  Positionincompany?: Maybe<Scalars["String"]>;
  Registeredbusinessnumber?: Maybe<Scalars["String"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["float8"]>;
  Repemail?: Maybe<Scalars["String"]>;
  Repfirstname?: Maybe<Scalars["String"]>;
  Replastname?: Maybe<Scalars["String"]>;
  Repmiddlename?: Maybe<Scalars["String"]>;
  Repnationality?: Maybe<Scalars["String"]>;
  Repothercountry?: Maybe<Scalars["String"]>;
  Repphone1?: Maybe<Scalars["String"]>;
  Reptitle?: Maybe<Scalars["String"]>;
  Reptitleother?: Maybe<Scalars["String"]>;
  Shopname?: Maybe<Scalars["String"]>;
  Shopnumber?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  Typeofgoodssold?: Maybe<Scalars["String"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rcapture_RPTtenants" */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Aggregate = {
  __typename?: "rtcs_db_PH_rcapture_RPTtenants_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rcapture_RpTtenants>;
};

/** aggregate fields of "rtcs_db.PH_rcapture_RPTtenants" */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTtenants_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rcapture_RPTtenants" */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTtenants_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rcapture_RPTtenants" */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rcapture_RPTtenants" */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rcapture_RpTtenants_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Avg_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTtenants_avg_fields";
  Actualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Amountofrentreceipts?: Maybe<Scalars["Float"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rcapture_RPTtenants" */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Avg_Order_By = {
  Actualrentpaidpermonth?: Maybe<Order_By>;
  Amountofrentreceipts?: Maybe<Order_By>;
  Cactualrentpaidpermonth?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Rentreportedbylessorpermonth?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rcapture_RPTtenants". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Bool_Exp = {
  Actualrentpaidpermonth?: Maybe<Float8_Comparison_Exp>;
  Amountofrentreceipts?: Maybe<Float8_Comparison_Exp>;
  Businesstradingname?: Maybe<String_Comparison_Exp>;
  Cactualrentpaidpermonth?: Maybe<Float8_Comparison_Exp>;
  Currencyactualrent?: Maybe<String_Comparison_Exp>;
  Currencyreportedrent?: Maybe<String_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  Estimatedshopsize?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Floornumber?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  Identifier?: Maybe<String_Comparison_Exp>;
  Isunitlet?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Lastname?: Maybe<String_Comparison_Exp>;
  Legalbusinessname?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Mda?: Maybe<String_Comparison_Exp>;
  Nationality?: Maybe<String_Comparison_Exp>;
  Notes?: Maybe<String_Comparison_Exp>;
  Numberbathrooms?: Maybe<Bigint_Comparison_Exp>;
  Numberbedrooms?: Maybe<Bigint_Comparison_Exp>;
  Owneroccupiedorrented?: Maybe<String_Comparison_Exp>;
  Phone1?: Maybe<String_Comparison_Exp>;
  Phone2?: Maybe<String_Comparison_Exp>;
  Phone3?: Maybe<String_Comparison_Exp>;
  Phone4?: Maybe<String_Comparison_Exp>;
  Positionincompany?: Maybe<String_Comparison_Exp>;
  Registeredbusinessnumber?: Maybe<String_Comparison_Exp>;
  Rentreportedbylessorpermonth?: Maybe<Float8_Comparison_Exp>;
  Repemail?: Maybe<String_Comparison_Exp>;
  Repfirstname?: Maybe<String_Comparison_Exp>;
  Replastname?: Maybe<String_Comparison_Exp>;
  Repmiddlename?: Maybe<String_Comparison_Exp>;
  Repnationality?: Maybe<String_Comparison_Exp>;
  Repothercountry?: Maybe<String_Comparison_Exp>;
  Repphone1?: Maybe<String_Comparison_Exp>;
  Reptitle?: Maybe<String_Comparison_Exp>;
  Reptitleother?: Maybe<String_Comparison_Exp>;
  Shopname?: Maybe<String_Comparison_Exp>;
  Shopnumber?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<Float8_Comparison_Exp>;
  Title?: Maybe<String_Comparison_Exp>;
  Type?: Maybe<String_Comparison_Exp>;
  Typeofgoodssold?: Maybe<String_Comparison_Exp>;
  Uuidproperty?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rcapture_RPTtenants" */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Inc_Input = {
  Actualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Amountofrentreceipts?: Maybe<Scalars["float8"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["float8"]>;
  Tin?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rcapture_RPTtenants" */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Insert_Input = {
  Actualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Amountofrentreceipts?: Maybe<Scalars["float8"]>;
  Businesstradingname?: Maybe<Scalars["String"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Currencyactualrent?: Maybe<Scalars["String"]>;
  Currencyreportedrent?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Estimatedshopsize?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Floornumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  Isunitlet?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalbusinessname?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mda?: Maybe<Scalars["String"]>;
  Nationality?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Owneroccupiedorrented?: Maybe<Scalars["String"]>;
  Phone1?: Maybe<Scalars["String"]>;
  Phone2?: Maybe<Scalars["String"]>;
  Phone3?: Maybe<Scalars["String"]>;
  Phone4?: Maybe<Scalars["String"]>;
  Positionincompany?: Maybe<Scalars["String"]>;
  Registeredbusinessnumber?: Maybe<Scalars["String"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["float8"]>;
  Repemail?: Maybe<Scalars["String"]>;
  Repfirstname?: Maybe<Scalars["String"]>;
  Replastname?: Maybe<Scalars["String"]>;
  Repmiddlename?: Maybe<Scalars["String"]>;
  Repnationality?: Maybe<Scalars["String"]>;
  Repothercountry?: Maybe<Scalars["String"]>;
  Repphone1?: Maybe<Scalars["String"]>;
  Reptitle?: Maybe<Scalars["String"]>;
  Reptitleother?: Maybe<Scalars["String"]>;
  Shopname?: Maybe<Scalars["String"]>;
  Shopnumber?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  Typeofgoodssold?: Maybe<Scalars["String"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Max_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTtenants_max_fields";
  Actualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Amountofrentreceipts?: Maybe<Scalars["float8"]>;
  Businesstradingname?: Maybe<Scalars["String"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Currencyactualrent?: Maybe<Scalars["String"]>;
  Currencyreportedrent?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Estimatedshopsize?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Floornumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  Isunitlet?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalbusinessname?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mda?: Maybe<Scalars["String"]>;
  Nationality?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Owneroccupiedorrented?: Maybe<Scalars["String"]>;
  Phone1?: Maybe<Scalars["String"]>;
  Phone2?: Maybe<Scalars["String"]>;
  Phone3?: Maybe<Scalars["String"]>;
  Phone4?: Maybe<Scalars["String"]>;
  Positionincompany?: Maybe<Scalars["String"]>;
  Registeredbusinessnumber?: Maybe<Scalars["String"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["float8"]>;
  Repemail?: Maybe<Scalars["String"]>;
  Repfirstname?: Maybe<Scalars["String"]>;
  Replastname?: Maybe<Scalars["String"]>;
  Repmiddlename?: Maybe<Scalars["String"]>;
  Repnationality?: Maybe<Scalars["String"]>;
  Repothercountry?: Maybe<Scalars["String"]>;
  Repphone1?: Maybe<Scalars["String"]>;
  Reptitle?: Maybe<Scalars["String"]>;
  Reptitleother?: Maybe<Scalars["String"]>;
  Shopname?: Maybe<Scalars["String"]>;
  Shopnumber?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  Typeofgoodssold?: Maybe<Scalars["String"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rcapture_RPTtenants" */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Max_Order_By = {
  Actualrentpaidpermonth?: Maybe<Order_By>;
  Amountofrentreceipts?: Maybe<Order_By>;
  Businesstradingname?: Maybe<Order_By>;
  Cactualrentpaidpermonth?: Maybe<Order_By>;
  Currencyactualrent?: Maybe<Order_By>;
  Currencyreportedrent?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  Estimatedshopsize?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Floornumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Identifier?: Maybe<Order_By>;
  Isunitlet?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastname?: Maybe<Order_By>;
  Legalbusinessname?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mda?: Maybe<Order_By>;
  Nationality?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Owneroccupiedorrented?: Maybe<Order_By>;
  Phone1?: Maybe<Order_By>;
  Phone2?: Maybe<Order_By>;
  Phone3?: Maybe<Order_By>;
  Phone4?: Maybe<Order_By>;
  Positionincompany?: Maybe<Order_By>;
  Registeredbusinessnumber?: Maybe<Order_By>;
  Rentreportedbylessorpermonth?: Maybe<Order_By>;
  Repemail?: Maybe<Order_By>;
  Repfirstname?: Maybe<Order_By>;
  Replastname?: Maybe<Order_By>;
  Repmiddlename?: Maybe<Order_By>;
  Repnationality?: Maybe<Order_By>;
  Repothercountry?: Maybe<Order_By>;
  Repphone1?: Maybe<Order_By>;
  Reptitle?: Maybe<Order_By>;
  Reptitleother?: Maybe<Order_By>;
  Shopname?: Maybe<Order_By>;
  Shopnumber?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Title?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Typeofgoodssold?: Maybe<Order_By>;
  Uuidproperty?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Min_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTtenants_min_fields";
  Actualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Amountofrentreceipts?: Maybe<Scalars["float8"]>;
  Businesstradingname?: Maybe<Scalars["String"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Currencyactualrent?: Maybe<Scalars["String"]>;
  Currencyreportedrent?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Estimatedshopsize?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Floornumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  Isunitlet?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalbusinessname?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mda?: Maybe<Scalars["String"]>;
  Nationality?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Owneroccupiedorrented?: Maybe<Scalars["String"]>;
  Phone1?: Maybe<Scalars["String"]>;
  Phone2?: Maybe<Scalars["String"]>;
  Phone3?: Maybe<Scalars["String"]>;
  Phone4?: Maybe<Scalars["String"]>;
  Positionincompany?: Maybe<Scalars["String"]>;
  Registeredbusinessnumber?: Maybe<Scalars["String"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["float8"]>;
  Repemail?: Maybe<Scalars["String"]>;
  Repfirstname?: Maybe<Scalars["String"]>;
  Replastname?: Maybe<Scalars["String"]>;
  Repmiddlename?: Maybe<Scalars["String"]>;
  Repnationality?: Maybe<Scalars["String"]>;
  Repothercountry?: Maybe<Scalars["String"]>;
  Repphone1?: Maybe<Scalars["String"]>;
  Reptitle?: Maybe<Scalars["String"]>;
  Reptitleother?: Maybe<Scalars["String"]>;
  Shopname?: Maybe<Scalars["String"]>;
  Shopnumber?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  Typeofgoodssold?: Maybe<Scalars["String"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rcapture_RPTtenants" */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Min_Order_By = {
  Actualrentpaidpermonth?: Maybe<Order_By>;
  Amountofrentreceipts?: Maybe<Order_By>;
  Businesstradingname?: Maybe<Order_By>;
  Cactualrentpaidpermonth?: Maybe<Order_By>;
  Currencyactualrent?: Maybe<Order_By>;
  Currencyreportedrent?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  Estimatedshopsize?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Floornumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Identifier?: Maybe<Order_By>;
  Isunitlet?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastname?: Maybe<Order_By>;
  Legalbusinessname?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mda?: Maybe<Order_By>;
  Nationality?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Owneroccupiedorrented?: Maybe<Order_By>;
  Phone1?: Maybe<Order_By>;
  Phone2?: Maybe<Order_By>;
  Phone3?: Maybe<Order_By>;
  Phone4?: Maybe<Order_By>;
  Positionincompany?: Maybe<Order_By>;
  Registeredbusinessnumber?: Maybe<Order_By>;
  Rentreportedbylessorpermonth?: Maybe<Order_By>;
  Repemail?: Maybe<Order_By>;
  Repfirstname?: Maybe<Order_By>;
  Replastname?: Maybe<Order_By>;
  Repmiddlename?: Maybe<Order_By>;
  Repnationality?: Maybe<Order_By>;
  Repothercountry?: Maybe<Order_By>;
  Repphone1?: Maybe<Order_By>;
  Reptitle?: Maybe<Order_By>;
  Reptitleother?: Maybe<Order_By>;
  Shopname?: Maybe<Order_By>;
  Shopnumber?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Title?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Typeofgoodssold?: Maybe<Order_By>;
  Uuidproperty?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rcapture_RPTtenants" */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Mutation_Response = {
  __typename?: "rtcs_db_PH_rcapture_RPTtenants_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rcapture_RpTtenants>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rcapture_RPTtenants" */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rcapture_RpTtenants_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rcapture_RPTtenants" */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Order_By = {
  Actualrentpaidpermonth?: Maybe<Order_By>;
  Amountofrentreceipts?: Maybe<Order_By>;
  Businesstradingname?: Maybe<Order_By>;
  Cactualrentpaidpermonth?: Maybe<Order_By>;
  Currencyactualrent?: Maybe<Order_By>;
  Currencyreportedrent?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  Estimatedshopsize?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Floornumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Identifier?: Maybe<Order_By>;
  Isunitlet?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastname?: Maybe<Order_By>;
  Legalbusinessname?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mda?: Maybe<Order_By>;
  Nationality?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Owneroccupiedorrented?: Maybe<Order_By>;
  Phone1?: Maybe<Order_By>;
  Phone2?: Maybe<Order_By>;
  Phone3?: Maybe<Order_By>;
  Phone4?: Maybe<Order_By>;
  Positionincompany?: Maybe<Order_By>;
  Registeredbusinessnumber?: Maybe<Order_By>;
  Rentreportedbylessorpermonth?: Maybe<Order_By>;
  Repemail?: Maybe<Order_By>;
  Repfirstname?: Maybe<Order_By>;
  Replastname?: Maybe<Order_By>;
  Repmiddlename?: Maybe<Order_By>;
  Repnationality?: Maybe<Order_By>;
  Repothercountry?: Maybe<Order_By>;
  Repphone1?: Maybe<Order_By>;
  Reptitle?: Maybe<Order_By>;
  Reptitleother?: Maybe<Order_By>;
  Shopname?: Maybe<Order_By>;
  Shopnumber?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Title?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Typeofgoodssold?: Maybe<Order_By>;
  Uuidproperty?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rcapture_RPTtenants" */
export enum Rtcs_Db_Ph_Rcapture_RpTtenants_Select_Column {
  /** column name */
  Actualrentpaidpermonth = "Actualrentpaidpermonth",
  /** column name */
  Amountofrentreceipts = "Amountofrentreceipts",
  /** column name */
  Businesstradingname = "Businesstradingname",
  /** column name */
  Cactualrentpaidpermonth = "Cactualrentpaidpermonth",
  /** column name */
  Currencyactualrent = "Currencyactualrent",
  /** column name */
  Currencyreportedrent = "Currencyreportedrent",
  /** column name */
  Email = "Email",
  /** column name */
  Estimatedshopsize = "Estimatedshopsize",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Floornumber = "Floornumber",
  /** column name */
  Id = "Id",
  /** column name */
  Identifier = "Identifier",
  /** column name */
  Isunitlet = "Isunitlet",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Lastname = "Lastname",
  /** column name */
  Legalbusinessname = "Legalbusinessname",
  /** column name */
  Line = "Line",
  /** column name */
  Mda = "Mda",
  /** column name */
  Nationality = "Nationality",
  /** column name */
  Notes = "Notes",
  /** column name */
  Numberbathrooms = "Numberbathrooms",
  /** column name */
  Numberbedrooms = "Numberbedrooms",
  /** column name */
  Owneroccupiedorrented = "Owneroccupiedorrented",
  /** column name */
  Phone1 = "Phone1",
  /** column name */
  Phone2 = "Phone2",
  /** column name */
  Phone3 = "Phone3",
  /** column name */
  Phone4 = "Phone4",
  /** column name */
  Positionincompany = "Positionincompany",
  /** column name */
  Registeredbusinessnumber = "Registeredbusinessnumber",
  /** column name */
  Rentreportedbylessorpermonth = "Rentreportedbylessorpermonth",
  /** column name */
  Repemail = "Repemail",
  /** column name */
  Repfirstname = "Repfirstname",
  /** column name */
  Replastname = "Replastname",
  /** column name */
  Repmiddlename = "Repmiddlename",
  /** column name */
  Repnationality = "Repnationality",
  /** column name */
  Repothercountry = "Repothercountry",
  /** column name */
  Repphone1 = "Repphone1",
  /** column name */
  Reptitle = "Reptitle",
  /** column name */
  Reptitleother = "Reptitleother",
  /** column name */
  Shopname = "Shopname",
  /** column name */
  Shopnumber = "Shopnumber",
  /** column name */
  Tin = "Tin",
  /** column name */
  Title = "Title",
  /** column name */
  Type = "Type",
  /** column name */
  Typeofgoodssold = "Typeofgoodssold",
  /** column name */
  Uuidproperty = "Uuidproperty",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rcapture_RPTtenants" */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Set_Input = {
  Actualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Amountofrentreceipts?: Maybe<Scalars["float8"]>;
  Businesstradingname?: Maybe<Scalars["String"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Currencyactualrent?: Maybe<Scalars["String"]>;
  Currencyreportedrent?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Estimatedshopsize?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Floornumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  Isunitlet?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalbusinessname?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mda?: Maybe<Scalars["String"]>;
  Nationality?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Owneroccupiedorrented?: Maybe<Scalars["String"]>;
  Phone1?: Maybe<Scalars["String"]>;
  Phone2?: Maybe<Scalars["String"]>;
  Phone3?: Maybe<Scalars["String"]>;
  Phone4?: Maybe<Scalars["String"]>;
  Positionincompany?: Maybe<Scalars["String"]>;
  Registeredbusinessnumber?: Maybe<Scalars["String"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["float8"]>;
  Repemail?: Maybe<Scalars["String"]>;
  Repfirstname?: Maybe<Scalars["String"]>;
  Replastname?: Maybe<Scalars["String"]>;
  Repmiddlename?: Maybe<Scalars["String"]>;
  Repnationality?: Maybe<Scalars["String"]>;
  Repothercountry?: Maybe<Scalars["String"]>;
  Repphone1?: Maybe<Scalars["String"]>;
  Reptitle?: Maybe<Scalars["String"]>;
  Reptitleother?: Maybe<Scalars["String"]>;
  Shopname?: Maybe<Scalars["String"]>;
  Shopnumber?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  Typeofgoodssold?: Maybe<Scalars["String"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTtenants_stddev_fields";
  Actualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Amountofrentreceipts?: Maybe<Scalars["Float"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rcapture_RPTtenants" */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Stddev_Order_By = {
  Actualrentpaidpermonth?: Maybe<Order_By>;
  Amountofrentreceipts?: Maybe<Order_By>;
  Cactualrentpaidpermonth?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Rentreportedbylessorpermonth?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTtenants_stddev_pop_fields";
  Actualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Amountofrentreceipts?: Maybe<Scalars["Float"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rcapture_RPTtenants" */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Stddev_Pop_Order_By = {
  Actualrentpaidpermonth?: Maybe<Order_By>;
  Amountofrentreceipts?: Maybe<Order_By>;
  Cactualrentpaidpermonth?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Rentreportedbylessorpermonth?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTtenants_stddev_samp_fields";
  Actualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Amountofrentreceipts?: Maybe<Scalars["Float"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rcapture_RPTtenants" */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Stddev_Samp_Order_By = {
  Actualrentpaidpermonth?: Maybe<Order_By>;
  Amountofrentreceipts?: Maybe<Order_By>;
  Cactualrentpaidpermonth?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Rentreportedbylessorpermonth?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Sum_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTtenants_sum_fields";
  Actualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Amountofrentreceipts?: Maybe<Scalars["float8"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["float8"]>;
  Tin?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rcapture_RPTtenants" */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Sum_Order_By = {
  Actualrentpaidpermonth?: Maybe<Order_By>;
  Amountofrentreceipts?: Maybe<Order_By>;
  Cactualrentpaidpermonth?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Rentreportedbylessorpermonth?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTtenants_var_pop_fields";
  Actualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Amountofrentreceipts?: Maybe<Scalars["Float"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rcapture_RPTtenants" */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Var_Pop_Order_By = {
  Actualrentpaidpermonth?: Maybe<Order_By>;
  Amountofrentreceipts?: Maybe<Order_By>;
  Cactualrentpaidpermonth?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Rentreportedbylessorpermonth?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTtenants_var_samp_fields";
  Actualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Amountofrentreceipts?: Maybe<Scalars["Float"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rcapture_RPTtenants" */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Var_Samp_Order_By = {
  Actualrentpaidpermonth?: Maybe<Order_By>;
  Amountofrentreceipts?: Maybe<Order_By>;
  Cactualrentpaidpermonth?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Rentreportedbylessorpermonth?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Variance_Fields = {
  __typename?: "rtcs_db_PH_rcapture_RPTtenants_variance_fields";
  Actualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Amountofrentreceipts?: Maybe<Scalars["Float"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rcapture_RPTtenants" */
export type Rtcs_Db_Ph_Rcapture_RpTtenants_Variance_Order_By = {
  Actualrentpaidpermonth?: Maybe<Order_By>;
  Amountofrentreceipts?: Maybe<Order_By>;
  Cactualrentpaidpermonth?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Rentreportedbylessorpermonth?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_Individual" */
export type Rtcs_Db_Ph_Rmatch_Individual = {
  __typename?: "rtcs_db_PH_rmatch_Individual";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport = {
  __typename?: "rtcs_db_PH_rmatch_IndividualComplianceDetailReport";
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Createddate?: Maybe<Scalars["timestamptz"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  District?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Houseno?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Ownerfirstname?: Maybe<Scalars["String"]>;
  Ownerlegalsurnamemaiden?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Propertyrnid?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Rnidowner?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytype?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_IndividualComplianceDetailReport_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Aggregate_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_IndividualComplianceDetailReport_aggregate_fields";
    avg?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Avg_Fields>;
    count?: Maybe<Scalars["Int"]>;
    max?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Max_Fields>;
    min?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Min_Fields>;
    stddev?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Stddev_Fields>;
    stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Stddev_Samp_Fields>;
    sum?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Sum_Fields>;
    var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Var_Pop_Fields>;
    var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Var_Samp_Fields>;
    variance?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Variance_Fields>;
  };

/** aggregate fields of "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Aggregate_Order_By =
  {
    avg?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Max_Order_By>;
    min?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Min_Order_By>;
    stddev?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Stddev_Order_By>;
    stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Stddev_Samp_Order_By>;
    sum?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Sum_Order_By>;
    var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Var_Pop_Order_By>;
    var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Var_Samp_Order_By>;
    variance?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Variance_Order_By>;
  };

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualComplianceDetailReport_avg_fields";
  Currentrateablevalue?: Maybe<Scalars["Float"]>;
  Customermatchscore?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Avg_Order_By = {
  Currentrateablevalue?: Maybe<Order_By>;
  Customermatchscore?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_IndividualComplianceDetailReport". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  Blockno?: Maybe<String_Comparison_Exp>;
  Createddate?: Maybe<Timestamptz_Comparison_Exp>;
  Currentrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Customerid?: Maybe<String_Comparison_Exp>;
  Customermatchscore?: Maybe<Bigint_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  Division?: Maybe<String_Comparison_Exp>;
  Emailid?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Flatno?: Maybe<String_Comparison_Exp>;
  Fullname?: Maybe<String_Comparison_Exp>;
  Grossrentvalueadjusted?: Maybe<Float8_Comparison_Exp>;
  Grossvalue?: Maybe<Float8_Comparison_Exp>;
  Houseno?: Maybe<String_Comparison_Exp>;
  Isincomesrcbsns?: Maybe<String_Comparison_Exp>;
  Isincomesrcemp?: Maybe<String_Comparison_Exp>;
  Isincomesrcprpty?: Maybe<String_Comparison_Exp>;
  Isincomesrcrental?: Maybe<String_Comparison_Exp>;
  Kccabirthdate?: Maybe<Timestamptz_Comparison_Exp>;
  Kccaemail?: Maybe<String_Comparison_Exp>;
  Kccafirstname?: Maybe<String_Comparison_Exp>;
  Kccamiddlename?: Maybe<String_Comparison_Exp>;
  Kccamobile1?: Maybe<String_Comparison_Exp>;
  Kccamobile2?: Maybe<String_Comparison_Exp>;
  Kccasurname?: Maybe<String_Comparison_Exp>;
  Kccatelephone?: Maybe<String_Comparison_Exp>;
  Kccatin?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  Maptitle?: Maybe<String_Comparison_Exp>;
  Matchscore?: Maybe<Float8_Comparison_Exp>;
  Mobilenumber?: Maybe<String_Comparison_Exp>;
  Ownerfirstname?: Maybe<String_Comparison_Exp>;
  Ownerlegalsurnamemaiden?: Maybe<String_Comparison_Exp>;
  Parish?: Maybe<String_Comparison_Exp>;
  Plotno?: Maybe<String_Comparison_Exp>;
  Propertyno?: Maybe<String_Comparison_Exp>;
  Propertyrentedstatus?: Maybe<String_Comparison_Exp>;
  Propertyrnid?: Maybe<String_Comparison_Exp>;
  Propertytype?: Maybe<String_Comparison_Exp>;
  Rateablevalue?: Maybe<Float8_Comparison_Exp>;
  Rnidcustomer?: Maybe<String_Comparison_Exp>;
  Rnidowner?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  Streetid?: Maybe<String_Comparison_Exp>;
  Subpropertytype?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Urafirstname?: Maybe<String_Comparison_Exp>;
  Uramiddlename?: Maybe<String_Comparison_Exp>;
  Urasurname?: Maybe<String_Comparison_Exp>;
  Uratinno?: Maybe<String_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Inc_Input = {
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Createddate?: Maybe<Scalars["timestamptz"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  District?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Houseno?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Ownerfirstname?: Maybe<Scalars["String"]>;
  Ownerlegalsurnamemaiden?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Propertyrnid?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Rnidowner?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytype?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualComplianceDetailReport_max_fields";
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Createddate?: Maybe<Scalars["timestamptz"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  District?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Houseno?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Ownerfirstname?: Maybe<Scalars["String"]>;
  Ownerlegalsurnamemaiden?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Propertyrnid?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Rnidowner?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytype?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Max_Order_By = {
  Address?: Maybe<Order_By>;
  Blockno?: Maybe<Order_By>;
  Createddate?: Maybe<Order_By>;
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Customermatchscore?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Houseno?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccabirthdate?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Maptitle?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Ownerfirstname?: Maybe<Order_By>;
  Ownerlegalsurnamemaiden?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  Propertyrnid?: Maybe<Order_By>;
  Propertytype?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rnidcustomer?: Maybe<Order_By>;
  Rnidowner?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subpropertytype?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramiddlename?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualComplianceDetailReport_min_fields";
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Createddate?: Maybe<Scalars["timestamptz"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  District?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Houseno?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Ownerfirstname?: Maybe<Scalars["String"]>;
  Ownerlegalsurnamemaiden?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Propertyrnid?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Rnidowner?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytype?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Min_Order_By = {
  Address?: Maybe<Order_By>;
  Blockno?: Maybe<Order_By>;
  Createddate?: Maybe<Order_By>;
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Customermatchscore?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Houseno?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccabirthdate?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Maptitle?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Ownerfirstname?: Maybe<Order_By>;
  Ownerlegalsurnamemaiden?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  Propertyrnid?: Maybe<Order_By>;
  Propertytype?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rnidcustomer?: Maybe<Order_By>;
  Rnidowner?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subpropertytype?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramiddlename?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Mutation_Response =
  {
    __typename?: "rtcs_db_PH_rmatch_IndividualComplianceDetailReport_mutation_response";
    /** number of affected rows by the mutation */
    affected_rows: Scalars["Int"];
    /** data of the affected rows by the mutation */
    returning: Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport>;
  };

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Order_By = {
  Address?: Maybe<Order_By>;
  Blockno?: Maybe<Order_By>;
  Createddate?: Maybe<Order_By>;
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Customermatchscore?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Houseno?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccabirthdate?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Maptitle?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Ownerfirstname?: Maybe<Order_By>;
  Ownerlegalsurnamemaiden?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  Propertyrnid?: Maybe<Order_By>;
  Propertytype?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rnidcustomer?: Maybe<Order_By>;
  Rnidowner?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subpropertytype?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramiddlename?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
export enum Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  Blockno = "Blockno",
  /** column name */
  Createddate = "Createddate",
  /** column name */
  Currentrateablevalue = "Currentrateablevalue",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  Customermatchscore = "Customermatchscore",
  /** column name */
  District = "District",
  /** column name */
  Division = "Division",
  /** column name */
  Emailid = "Emailid",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Flatno = "Flatno",
  /** column name */
  Fullname = "Fullname",
  /** column name */
  Grossrentvalueadjusted = "Grossrentvalueadjusted",
  /** column name */
  Grossvalue = "Grossvalue",
  /** column name */
  Houseno = "Houseno",
  /** column name */
  Isincomesrcbsns = "Isincomesrcbsns",
  /** column name */
  Isincomesrcemp = "Isincomesrcemp",
  /** column name */
  Isincomesrcprpty = "Isincomesrcprpty",
  /** column name */
  Isincomesrcrental = "Isincomesrcrental",
  /** column name */
  Kccabirthdate = "Kccabirthdate",
  /** column name */
  Kccaemail = "Kccaemail",
  /** column name */
  Kccafirstname = "Kccafirstname",
  /** column name */
  Kccamiddlename = "Kccamiddlename",
  /** column name */
  Kccamobile1 = "Kccamobile1",
  /** column name */
  Kccamobile2 = "Kccamobile2",
  /** column name */
  Kccasurname = "Kccasurname",
  /** column name */
  Kccatelephone = "Kccatelephone",
  /** column name */
  Kccatin = "Kccatin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  Maptitle = "Maptitle",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Mobilenumber = "Mobilenumber",
  /** column name */
  Ownerfirstname = "Ownerfirstname",
  /** column name */
  Ownerlegalsurnamemaiden = "Ownerlegalsurnamemaiden",
  /** column name */
  Parish = "Parish",
  /** column name */
  Plotno = "Plotno",
  /** column name */
  Propertyno = "Propertyno",
  /** column name */
  Propertyrentedstatus = "Propertyrentedstatus",
  /** column name */
  Propertyrnid = "Propertyrnid",
  /** column name */
  Propertytype = "Propertytype",
  /** column name */
  Rateablevalue = "Rateablevalue",
  /** column name */
  Rnidcustomer = "Rnidcustomer",
  /** column name */
  Rnidowner = "Rnidowner",
  /** column name */
  Source = "Source",
  /** column name */
  Streetid = "Streetid",
  /** column name */
  Subpropertytype = "Subpropertytype",
  /** column name */
  Surname = "Surname",
  /** column name */
  Urafirstname = "Urafirstname",
  /** column name */
  Uramiddlename = "Uramiddlename",
  /** column name */
  Urasurname = "Urasurname",
  /** column name */
  Uratinno = "Uratinno",
  /** column name */
  Village = "Village",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Createddate?: Maybe<Scalars["timestamptz"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  District?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Houseno?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Ownerfirstname?: Maybe<Scalars["String"]>;
  Ownerlegalsurnamemaiden?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Propertyrnid?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Rnidowner?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytype?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualComplianceDetailReport_stddev_fields";
  Currentrateablevalue?: Maybe<Scalars["Float"]>;
  Customermatchscore?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Stddev_Order_By =
  {
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
  };

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Stddev_Pop_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_IndividualComplianceDetailReport_stddev_pop_fields";
    Currentrateablevalue?: Maybe<Scalars["Float"]>;
    Customermatchscore?: Maybe<Scalars["Float"]>;
    Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
    Grossvalue?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Rateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Stddev_Pop_Order_By =
  {
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
  };

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Stddev_Samp_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_IndividualComplianceDetailReport_stddev_samp_fields";
    Currentrateablevalue?: Maybe<Scalars["Float"]>;
    Customermatchscore?: Maybe<Scalars["Float"]>;
    Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
    Grossvalue?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Rateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Stddev_Samp_Order_By =
  {
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualComplianceDetailReport_sum_fields";
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Sum_Order_By = {
  Currentrateablevalue?: Maybe<Order_By>;
  Customermatchscore?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Var_Pop_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_IndividualComplianceDetailReport_var_pop_fields";
    Currentrateablevalue?: Maybe<Scalars["Float"]>;
    Customermatchscore?: Maybe<Scalars["Float"]>;
    Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
    Grossvalue?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Rateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Var_Pop_Order_By =
  {
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
  };

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Var_Samp_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_IndividualComplianceDetailReport_var_samp_fields";
    Currentrateablevalue?: Maybe<Scalars["Float"]>;
    Customermatchscore?: Maybe<Scalars["Float"]>;
    Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
    Grossvalue?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Rateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Var_Samp_Order_By =
  {
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
  };

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Variance_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_IndividualComplianceDetailReport_variance_fields";
    Currentrateablevalue?: Maybe<Scalars["Float"]>;
    Customermatchscore?: Maybe<Scalars["Float"]>;
    Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
    Grossvalue?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Rateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by variance() on columns of table "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Variance_Order_By =
  {
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
  };

/** columns and relationships of "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport = {
  __typename?: "rtcs_db_PH_rmatch_IndividualComplianceSummaryReport";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Returntype?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_IndividualComplianceSummaryReport_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Aggregate_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_IndividualComplianceSummaryReport_aggregate_fields";
    avg?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Avg_Fields>;
    count?: Maybe<Scalars["Int"]>;
    max?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Max_Fields>;
    min?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Min_Fields>;
    stddev?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Stddev_Fields>;
    stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Stddev_Samp_Fields>;
    sum?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Sum_Fields>;
    var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Var_Pop_Fields>;
    var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Var_Samp_Fields>;
    variance?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Variance_Fields>;
  };

/** aggregate fields of "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Aggregate_Order_By =
  {
    avg?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Max_Order_By>;
    min?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Min_Order_By>;
    stddev?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Stddev_Order_By>;
    stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Stddev_Samp_Order_By>;
    sum?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Sum_Order_By>;
    var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Var_Pop_Order_By>;
    var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Var_Samp_Order_By>;
    variance?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Variance_Order_By>;
  };

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualComplianceSummaryReport_avg_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Avg_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Bool_Exp = {
  Averagerateperrentedproperty?: Maybe<Float8_Comparison_Exp>;
  Compliancecategory?: Maybe<String_Comparison_Exp>;
  Countura?: Maybe<String_Comparison_Exp>;
  Customerid?: Maybe<String_Comparison_Exp>;
  Difference?: Maybe<Float8_Comparison_Exp>;
  Emailid?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Fullname?: Maybe<String_Comparison_Exp>;
  Grossrentalincome?: Maybe<Float8_Comparison_Exp>;
  Isamendment?: Maybe<String_Comparison_Exp>;
  Isincomesrcbsns?: Maybe<String_Comparison_Exp>;
  Isincomesrcemp?: Maybe<String_Comparison_Exp>;
  Isincomesrcprpty?: Maybe<String_Comparison_Exp>;
  Isincomesrcrental?: Maybe<String_Comparison_Exp>;
  Kccabirthdate?: Maybe<Timestamptz_Comparison_Exp>;
  Kccaemail?: Maybe<String_Comparison_Exp>;
  Kccafirstname?: Maybe<String_Comparison_Exp>;
  Kccamiddlename?: Maybe<String_Comparison_Exp>;
  Kccamobile1?: Maybe<String_Comparison_Exp>;
  Kccamobile2?: Maybe<String_Comparison_Exp>;
  Kccasurname?: Maybe<String_Comparison_Exp>;
  Kccatelephone?: Maybe<String_Comparison_Exp>;
  Kccatin?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Matchcategory?: Maybe<String_Comparison_Exp>;
  Matchscore?: Maybe<Float8_Comparison_Exp>;
  Matchscoreattributes?: Maybe<String_Comparison_Exp>;
  Mobilenumber?: Maybe<String_Comparison_Exp>;
  Nationalid?: Maybe<String_Comparison_Exp>;
  Numproperties?: Maybe<Bigint_Comparison_Exp>;
  Numrentedproperties?: Maybe<Bigint_Comparison_Exp>;
  Percentagedifference?: Maybe<Float8_Comparison_Exp>;
  Propincincome?: Maybe<Float8_Comparison_Exp>;
  Returntype?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Rtnno?: Maybe<String_Comparison_Exp>;
  Rtnperiodyear?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  Sumadjustedincome?: Maybe<Float8_Comparison_Exp>;
  Sumgrossvalue?: Maybe<Float8_Comparison_Exp>;
  Sumnonrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Sumrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Urafirstname?: Maybe<String_Comparison_Exp>;
  Uramiddlename?: Maybe<String_Comparison_Exp>;
  Urasurname?: Maybe<String_Comparison_Exp>;
  Uratinno?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Inc_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Insert_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Returntype?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualComplianceSummaryReport_max_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Returntype?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Max_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Countura?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccabirthdate?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchcategory?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Returntype?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramiddlename?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualComplianceSummaryReport_min_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Returntype?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Min_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Countura?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccabirthdate?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchcategory?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Returntype?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramiddlename?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Mutation_Response =
  {
    __typename?: "rtcs_db_PH_rmatch_IndividualComplianceSummaryReport_mutation_response";
    /** number of affected rows by the mutation */
    affected_rows: Scalars["Int"];
    /** data of the affected rows by the mutation */
    returning: Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport>;
  };

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Countura?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccabirthdate?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchcategory?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Returntype?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramiddlename?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
export enum Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Select_Column {
  /** column name */
  Averagerateperrentedproperty = "Averagerateperrentedproperty",
  /** column name */
  Compliancecategory = "Compliancecategory",
  /** column name */
  Countura = "Countura",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  Difference = "Difference",
  /** column name */
  Emailid = "Emailid",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Fullname = "Fullname",
  /** column name */
  Grossrentalincome = "Grossrentalincome",
  /** column name */
  Isamendment = "Isamendment",
  /** column name */
  Isincomesrcbsns = "Isincomesrcbsns",
  /** column name */
  Isincomesrcemp = "Isincomesrcemp",
  /** column name */
  Isincomesrcprpty = "Isincomesrcprpty",
  /** column name */
  Isincomesrcrental = "Isincomesrcrental",
  /** column name */
  Kccabirthdate = "Kccabirthdate",
  /** column name */
  Kccaemail = "Kccaemail",
  /** column name */
  Kccafirstname = "Kccafirstname",
  /** column name */
  Kccamiddlename = "Kccamiddlename",
  /** column name */
  Kccamobile1 = "Kccamobile1",
  /** column name */
  Kccamobile2 = "Kccamobile2",
  /** column name */
  Kccasurname = "Kccasurname",
  /** column name */
  Kccatelephone = "Kccatelephone",
  /** column name */
  Kccatin = "Kccatin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Matchcategory = "Matchcategory",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Matchscoreattributes = "Matchscoreattributes",
  /** column name */
  Mobilenumber = "Mobilenumber",
  /** column name */
  Nationalid = "Nationalid",
  /** column name */
  Numproperties = "Numproperties",
  /** column name */
  Numrentedproperties = "Numrentedproperties",
  /** column name */
  Percentagedifference = "Percentagedifference",
  /** column name */
  Propincincome = "Propincincome",
  /** column name */
  Returntype = "Returntype",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Rtnno = "Rtnno",
  /** column name */
  Rtnperiodyear = "Rtnperiodyear",
  /** column name */
  Source = "Source",
  /** column name */
  Sumadjustedincome = "Sumadjustedincome",
  /** column name */
  Sumgrossvalue = "Sumgrossvalue",
  /** column name */
  Sumnonrateablevalue = "Sumnonrateablevalue",
  /** column name */
  Sumrateablevalue = "Sumrateablevalue",
  /** column name */
  Surname = "Surname",
  /** column name */
  Urafirstname = "Urafirstname",
  /** column name */
  Uramiddlename = "Uramiddlename",
  /** column name */
  Urasurname = "Urasurname",
  /** column name */
  Uratinno = "Uratinno",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Set_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Returntype?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Stddev_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_IndividualComplianceSummaryReport_stddev_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Propincincome?: Maybe<Scalars["Float"]>;
    Sumadjustedincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Stddev_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Propincincome?: Maybe<Order_By>;
    Sumadjustedincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumnonrateablevalue?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
  };

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Stddev_Pop_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_IndividualComplianceSummaryReport_stddev_pop_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Propincincome?: Maybe<Scalars["Float"]>;
    Sumadjustedincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Stddev_Pop_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Propincincome?: Maybe<Order_By>;
    Sumadjustedincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumnonrateablevalue?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
  };

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Stddev_Samp_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_IndividualComplianceSummaryReport_stddev_samp_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Propincincome?: Maybe<Scalars["Float"]>;
    Sumadjustedincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Stddev_Samp_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Propincincome?: Maybe<Order_By>;
    Sumadjustedincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumnonrateablevalue?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualComplianceSummaryReport_sum_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Sum_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Var_Pop_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_IndividualComplianceSummaryReport_var_pop_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Propincincome?: Maybe<Scalars["Float"]>;
    Sumadjustedincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Var_Pop_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Propincincome?: Maybe<Order_By>;
    Sumadjustedincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumnonrateablevalue?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
  };

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Var_Samp_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_IndividualComplianceSummaryReport_var_samp_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Propincincome?: Maybe<Scalars["Float"]>;
    Sumadjustedincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Var_Samp_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Propincincome?: Maybe<Order_By>;
    Sumadjustedincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumnonrateablevalue?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
  };

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Variance_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_IndividualComplianceSummaryReport_variance_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Propincincome?: Maybe<Scalars["Float"]>;
    Sumadjustedincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by variance() on columns of table "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Variance_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Propincincome?: Maybe<Order_By>;
    Sumadjustedincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumnonrateablevalue?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
  };

/** columns and relationships of "rtcs_db.PH_rmatch_IndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore = {
  __typename?: "rtcs_db_PH_rmatch_IndividualRiskScore";
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["String"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentiledifferencezscore?: Maybe<Scalars["String"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Riskcode?: Maybe<Scalars["String"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_IndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_IndividualRiskScore_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_IndividualRiskScore>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_IndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualRiskScore_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_IndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rmatch_IndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_IndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualRiskScore_avg_fields";
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_IndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Avg_Order_By = {
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_IndividualRiskScore". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Bool_Exp = {
  Avgadjustedgrossincomeperproperty?: Maybe<Float8_Comparison_Exp>;
  Compliancecategory?: Maybe<String_Comparison_Exp>;
  Countura?: Maybe<String_Comparison_Exp>;
  Customerid?: Maybe<String_Comparison_Exp>;
  Difference?: Maybe<Float8_Comparison_Exp>;
  Emailid?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Fullname?: Maybe<String_Comparison_Exp>;
  Grossrentalincome?: Maybe<Float8_Comparison_Exp>;
  Isamendment?: Maybe<String_Comparison_Exp>;
  Isincomesrcbsns?: Maybe<String_Comparison_Exp>;
  Isincomesrcemp?: Maybe<String_Comparison_Exp>;
  Isincomesrcprpty?: Maybe<String_Comparison_Exp>;
  Isincomesrcrental?: Maybe<String_Comparison_Exp>;
  Kccabirthdate?: Maybe<Timestamptz_Comparison_Exp>;
  Kccaemail?: Maybe<String_Comparison_Exp>;
  Kccafirstname?: Maybe<String_Comparison_Exp>;
  Kccamiddlename?: Maybe<String_Comparison_Exp>;
  Kccamobile1?: Maybe<String_Comparison_Exp>;
  Kccamobile2?: Maybe<String_Comparison_Exp>;
  Kccasurname?: Maybe<String_Comparison_Exp>;
  Kccatelephone?: Maybe<String_Comparison_Exp>;
  Kccatin?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Matchscore?: Maybe<Float8_Comparison_Exp>;
  Matchscoreattributes?: Maybe<String_Comparison_Exp>;
  Mobilenumber?: Maybe<String_Comparison_Exp>;
  Nationalid?: Maybe<String_Comparison_Exp>;
  Numproperties?: Maybe<Bigint_Comparison_Exp>;
  Numrentedproperties?: Maybe<Bigint_Comparison_Exp>;
  Percentagedifference?: Maybe<Float8_Comparison_Exp>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Float8_Comparison_Exp>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Float8_Comparison_Exp>;
  Percentileavgperproperty?: Maybe<Float8_Comparison_Exp>;
  Percentileavgperpropertyzscore?: Maybe<String_Comparison_Exp>;
  Percentiledifference?: Maybe<Bigint_Comparison_Exp>;
  Percentiledifferenceavg?: Maybe<Float8_Comparison_Exp>;
  Percentiledifferenceavg1?: Maybe<Float8_Comparison_Exp>;
  Percentiledifferencestandarddeviation?: Maybe<Float8_Comparison_Exp>;
  Percentiledifferencezscore?: Maybe<String_Comparison_Exp>;
  Percentilenumrentedpropertiesavg?: Maybe<Float8_Comparison_Exp>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Float8_Comparison_Exp>;
  Percentilepropertyrented?: Maybe<Bigint_Comparison_Exp>;
  Percentilepropertyrentedzscore?: Maybe<Float8_Comparison_Exp>;
  Propincincome?: Maybe<Float8_Comparison_Exp>;
  Riskcode?: Maybe<String_Comparison_Exp>;
  Riskscore?: Maybe<Bigint_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Rtnno?: Maybe<String_Comparison_Exp>;
  Rtnperiodyear?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  Sumadjustedincome?: Maybe<Float8_Comparison_Exp>;
  Sumgrossvalue?: Maybe<Float8_Comparison_Exp>;
  Sumnonrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Sumrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Urafirstname?: Maybe<String_Comparison_Exp>;
  Uramiddlename?: Maybe<String_Comparison_Exp>;
  Urasurname?: Maybe<String_Comparison_Exp>;
  Uratinno?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_IndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Inc_Input = {
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_IndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Insert_Input = {
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["String"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentiledifferencezscore?: Maybe<Scalars["String"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Riskcode?: Maybe<Scalars["String"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualRiskScore_max_fields";
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["String"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentiledifferencezscore?: Maybe<Scalars["String"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Riskcode?: Maybe<Scalars["String"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_IndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Max_Order_By = {
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Countura?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccabirthdate?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Riskcode?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramiddlename?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualRiskScore_min_fields";
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["String"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentiledifferencezscore?: Maybe<Scalars["String"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Riskcode?: Maybe<Scalars["String"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_IndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Min_Order_By = {
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Countura?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccabirthdate?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Riskcode?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramiddlename?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_IndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_IndividualRiskScore_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_IndividualRiskScore>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_IndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_IndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Order_By = {
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Countura?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccabirthdate?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Riskcode?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramiddlename?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_IndividualRiskScore" */
export enum Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Select_Column {
  /** column name */
  Avgadjustedgrossincomeperproperty = "Avgadjustedgrossincomeperproperty",
  /** column name */
  Compliancecategory = "Compliancecategory",
  /** column name */
  Countura = "Countura",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  Difference = "Difference",
  /** column name */
  Emailid = "Emailid",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Fullname = "Fullname",
  /** column name */
  Grossrentalincome = "Grossrentalincome",
  /** column name */
  Isamendment = "Isamendment",
  /** column name */
  Isincomesrcbsns = "Isincomesrcbsns",
  /** column name */
  Isincomesrcemp = "Isincomesrcemp",
  /** column name */
  Isincomesrcprpty = "Isincomesrcprpty",
  /** column name */
  Isincomesrcrental = "Isincomesrcrental",
  /** column name */
  Kccabirthdate = "Kccabirthdate",
  /** column name */
  Kccaemail = "Kccaemail",
  /** column name */
  Kccafirstname = "Kccafirstname",
  /** column name */
  Kccamiddlename = "Kccamiddlename",
  /** column name */
  Kccamobile1 = "Kccamobile1",
  /** column name */
  Kccamobile2 = "Kccamobile2",
  /** column name */
  Kccasurname = "Kccasurname",
  /** column name */
  Kccatelephone = "Kccatelephone",
  /** column name */
  Kccatin = "Kccatin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Matchscoreattributes = "Matchscoreattributes",
  /** column name */
  Mobilenumber = "Mobilenumber",
  /** column name */
  Nationalid = "Nationalid",
  /** column name */
  Numproperties = "Numproperties",
  /** column name */
  Numrentedproperties = "Numrentedproperties",
  /** column name */
  Percentagedifference = "Percentagedifference",
  /** column name */
  Percentileavgadjustedgrossincomeperpropertyavg = "Percentileavgadjustedgrossincomeperpropertyavg",
  /** column name */
  Percentileavgadjustedgrossincomeperpropertystandarddeviation = "Percentileavgadjustedgrossincomeperpropertystandarddeviation",
  /** column name */
  Percentileavgperproperty = "Percentileavgperproperty",
  /** column name */
  Percentileavgperpropertyzscore = "Percentileavgperpropertyzscore",
  /** column name */
  Percentiledifference = "Percentiledifference",
  /** column name */
  Percentiledifferenceavg = "Percentiledifferenceavg",
  /** column name */
  Percentiledifferenceavg1 = "Percentiledifferenceavg1",
  /** column name */
  Percentiledifferencestandarddeviation = "Percentiledifferencestandarddeviation",
  /** column name */
  Percentiledifferencezscore = "Percentiledifferencezscore",
  /** column name */
  Percentilenumrentedpropertiesavg = "Percentilenumrentedpropertiesavg",
  /** column name */
  Percentilenumrentedpropertiesstandarddeviation = "Percentilenumrentedpropertiesstandarddeviation",
  /** column name */
  Percentilepropertyrented = "Percentilepropertyrented",
  /** column name */
  Percentilepropertyrentedzscore = "Percentilepropertyrentedzscore",
  /** column name */
  Propincincome = "Propincincome",
  /** column name */
  Riskcode = "Riskcode",
  /** column name */
  Riskscore = "Riskscore",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Rtnno = "Rtnno",
  /** column name */
  Rtnperiodyear = "Rtnperiodyear",
  /** column name */
  Source = "Source",
  /** column name */
  Sumadjustedincome = "Sumadjustedincome",
  /** column name */
  Sumgrossvalue = "Sumgrossvalue",
  /** column name */
  Sumnonrateablevalue = "Sumnonrateablevalue",
  /** column name */
  Sumrateablevalue = "Sumrateablevalue",
  /** column name */
  Surname = "Surname",
  /** column name */
  Urafirstname = "Urafirstname",
  /** column name */
  Uramiddlename = "Uramiddlename",
  /** column name */
  Urasurname = "Urasurname",
  /** column name */
  Uratinno = "Uratinno",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_IndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Set_Input = {
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["String"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentiledifferencezscore?: Maybe<Scalars["String"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Riskcode?: Maybe<Scalars["String"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualRiskScore_stddev_fields";
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_IndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Stddev_Order_By = {
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualRiskScore_stddev_pop_fields";
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_IndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Stddev_Pop_Order_By = {
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualRiskScore_stddev_samp_fields";
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_IndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Stddev_Samp_Order_By = {
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualRiskScore_sum_fields";
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_IndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Sum_Order_By = {
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualRiskScore_var_pop_fields";
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_IndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Var_Pop_Order_By = {
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualRiskScore_var_samp_fields";
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_IndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Var_Samp_Order_By = {
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualRiskScore_variance_fields";
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_IndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Variance_Order_By = {
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_Individual" */
export type Rtcs_Db_Ph_Rmatch_Individual_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_Individual_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_Individual>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_Individual" */
export type Rtcs_Db_Ph_Rmatch_Individual_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Individual_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_Individual" */
export type Rtcs_Db_Ph_Rmatch_Individual_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Individual_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rmatch_Individual" */
export type Rtcs_Db_Ph_Rmatch_Individual_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_Individual" */
export type Rtcs_Db_Ph_Rmatch_Individual_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_Individual_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_Individual_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Individual_avg_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_Individual" */
export type Rtcs_Db_Ph_Rmatch_Individual_Avg_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_Individual". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_Individual_Bool_Exp = {
  AdditionalOwners?: Maybe<Float8_Comparison_Exp>;
  AdditionalProperties?: Maybe<Float8_Comparison_Exp>;
  CountUra?: Maybe<Float8_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Fullname?: Maybe<String_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchDetails?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Bigint_Comparison_Exp>;
  MatchScoreAttributes?: Maybe<String_Comparison_Exp>;
  MatchedKccaDob?: Maybe<String_Comparison_Exp>;
  MatchedKccaEmail?: Maybe<String_Comparison_Exp>;
  MatchedKccaMobile?: Maybe<String_Comparison_Exp>;
  MatchedKccaNin?: Maybe<String_Comparison_Exp>;
  MatchedKccaPhone?: Maybe<String_Comparison_Exp>;
  MatchedNiraDob?: Maybe<String_Comparison_Exp>;
  MatchedNiraEmail?: Maybe<String_Comparison_Exp>;
  MatchedNiraNin?: Maybe<String_Comparison_Exp>;
  MatchedNiraPhone?: Maybe<String_Comparison_Exp>;
  MatchedUraEmail?: Maybe<String_Comparison_Exp>;
  MatchedUraMobile?: Maybe<String_Comparison_Exp>;
  NiraFirstname?: Maybe<String_Comparison_Exp>;
  NiraNin?: Maybe<String_Comparison_Exp>;
  NiraSurname?: Maybe<String_Comparison_Exp>;
  NumOwnedNonRateable?: Maybe<Float8_Comparison_Exp>;
  NumProperties?: Maybe<Float8_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  SumAdjustedIncome?: Maybe<Float8_Comparison_Exp>;
  SumGrossValue?: Maybe<Float8_Comparison_Exp>;
  SumNonRateableValue?: Maybe<Float8_Comparison_Exp>;
  SumRateableValue?: Maybe<Float8_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  TotalRentedProperties?: Maybe<Float8_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_Individual_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_Individual_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_Individual" */
export type Rtcs_Db_Ph_Rmatch_Individual_Inc_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_Individual" */
export type Rtcs_Db_Ph_Rmatch_Individual_Insert_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_Individual_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Individual_max_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_Individual" */
export type Rtcs_Db_Ph_Rmatch_Individual_Max_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MatchedKccaDob?: Maybe<Order_By>;
  MatchedKccaEmail?: Maybe<Order_By>;
  MatchedKccaMobile?: Maybe<Order_By>;
  MatchedKccaNin?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedNiraDob?: Maybe<Order_By>;
  MatchedNiraEmail?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraEmail?: Maybe<Order_By>;
  MatchedUraMobile?: Maybe<Order_By>;
  NiraFirstname?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NiraSurname?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_Individual_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Individual_min_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_Individual" */
export type Rtcs_Db_Ph_Rmatch_Individual_Min_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MatchedKccaDob?: Maybe<Order_By>;
  MatchedKccaEmail?: Maybe<Order_By>;
  MatchedKccaMobile?: Maybe<Order_By>;
  MatchedKccaNin?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedNiraDob?: Maybe<Order_By>;
  MatchedNiraEmail?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraEmail?: Maybe<Order_By>;
  MatchedUraMobile?: Maybe<Order_By>;
  NiraFirstname?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NiraSurname?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_Individual" */
export type Rtcs_Db_Ph_Rmatch_Individual_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_Individual_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_Individual>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_Individual" */
export type Rtcs_Db_Ph_Rmatch_Individual_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_Individual_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_Individual" */
export type Rtcs_Db_Ph_Rmatch_Individual_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MatchedKccaDob?: Maybe<Order_By>;
  MatchedKccaEmail?: Maybe<Order_By>;
  MatchedKccaMobile?: Maybe<Order_By>;
  MatchedKccaNin?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedNiraDob?: Maybe<Order_By>;
  MatchedNiraEmail?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraEmail?: Maybe<Order_By>;
  MatchedUraMobile?: Maybe<Order_By>;
  NiraFirstname?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NiraSurname?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_Individual" */
export enum Rtcs_Db_Ph_Rmatch_Individual_Select_Column {
  /** column name */
  AdditionalOwners = "AdditionalOwners",
  /** column name */
  AdditionalProperties = "AdditionalProperties",
  /** column name */
  CountUra = "CountUra",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Fullname = "Fullname",
  /** column name */
  Gml = "Gml",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchDetails = "MatchDetails",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MatchScoreAttributes = "MatchScoreAttributes",
  /** column name */
  MatchedKccaDob = "MatchedKccaDob",
  /** column name */
  MatchedKccaEmail = "MatchedKccaEmail",
  /** column name */
  MatchedKccaMobile = "MatchedKccaMobile",
  /** column name */
  MatchedKccaNin = "MatchedKccaNin",
  /** column name */
  MatchedKccaPhone = "MatchedKccaPhone",
  /** column name */
  MatchedNiraDob = "MatchedNiraDob",
  /** column name */
  MatchedNiraEmail = "MatchedNiraEmail",
  /** column name */
  MatchedNiraNin = "MatchedNiraNin",
  /** column name */
  MatchedNiraPhone = "MatchedNiraPhone",
  /** column name */
  MatchedUraEmail = "MatchedUraEmail",
  /** column name */
  MatchedUraMobile = "MatchedUraMobile",
  /** column name */
  NiraFirstname = "NiraFirstname",
  /** column name */
  NiraNin = "NiraNin",
  /** column name */
  NiraSurname = "NiraSurname",
  /** column name */
  NumOwnedNonRateable = "NumOwnedNonRateable",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Source = "Source",
  /** column name */
  SumAdjustedIncome = "SumAdjustedIncome",
  /** column name */
  SumGrossValue = "SumGrossValue",
  /** column name */
  SumNonRateableValue = "SumNonRateableValue",
  /** column name */
  SumRateableValue = "SumRateableValue",
  /** column name */
  Surname = "Surname",
  /** column name */
  TotalRentedProperties = "TotalRentedProperties",
  /** column name */
  UraTinNo = "UraTinNo",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_Individual" */
export type Rtcs_Db_Ph_Rmatch_Individual_Set_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_Individual_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Individual_stddev_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_Individual" */
export type Rtcs_Db_Ph_Rmatch_Individual_Stddev_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_Individual_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Individual_stddev_pop_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_Individual" */
export type Rtcs_Db_Ph_Rmatch_Individual_Stddev_Pop_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_Individual_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Individual_stddev_samp_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_Individual" */
export type Rtcs_Db_Ph_Rmatch_Individual_Stddev_Samp_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_Individual_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Individual_sum_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_Individual" */
export type Rtcs_Db_Ph_Rmatch_Individual_Sum_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_Individual_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Individual_var_pop_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_Individual" */
export type Rtcs_Db_Ph_Rmatch_Individual_Var_Pop_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_Individual_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Individual_var_samp_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_Individual" */
export type Rtcs_Db_Ph_Rmatch_Individual_Var_Samp_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_Individual_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Individual_variance_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_Individual" */
export type Rtcs_Db_Ph_Rmatch_Individual_Variance_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_IndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetwork";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedKccaTin?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetworkTesting";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedKccaTin?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetworkTesting_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetworkTesting_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetworkTesting_avg_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Avg_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_IndividualsNetworkTesting". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Bool_Exp = {
  AdditionalOwners?: Maybe<Float8_Comparison_Exp>;
  AdditionalProperties?: Maybe<Float8_Comparison_Exp>;
  CountUra?: Maybe<Float8_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Fullname?: Maybe<String_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchDetails?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Bigint_Comparison_Exp>;
  MatchScoreAttributes?: Maybe<String_Comparison_Exp>;
  MatchedKccaDob?: Maybe<String_Comparison_Exp>;
  MatchedKccaEmail?: Maybe<String_Comparison_Exp>;
  MatchedKccaMobile?: Maybe<String_Comparison_Exp>;
  MatchedKccaNin?: Maybe<String_Comparison_Exp>;
  MatchedKccaPhone?: Maybe<String_Comparison_Exp>;
  MatchedKccaTin?: Maybe<String_Comparison_Exp>;
  MatchedNiraDob?: Maybe<String_Comparison_Exp>;
  MatchedNiraEmail?: Maybe<String_Comparison_Exp>;
  MatchedNiraNin?: Maybe<String_Comparison_Exp>;
  MatchedNiraPhone?: Maybe<String_Comparison_Exp>;
  MatchedUraEmail?: Maybe<String_Comparison_Exp>;
  MatchedUraMobile?: Maybe<String_Comparison_Exp>;
  NiraFirstname?: Maybe<String_Comparison_Exp>;
  NiraNin?: Maybe<String_Comparison_Exp>;
  NiraSurname?: Maybe<String_Comparison_Exp>;
  NumOwnedNonRateable?: Maybe<Float8_Comparison_Exp>;
  NumProperties?: Maybe<Float8_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  SumAdjustedIncome?: Maybe<Float8_Comparison_Exp>;
  SumGrossValue?: Maybe<Float8_Comparison_Exp>;
  SumNonRateableValue?: Maybe<Float8_Comparison_Exp>;
  SumRateableValue?: Maybe<Float8_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  TotalRentedProperties?: Maybe<Float8_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  VisibilityLevel?: Maybe<Float8_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Inc_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Insert_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedKccaTin?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetworkTesting_max_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedKccaTin?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Max_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MatchedKccaDob?: Maybe<Order_By>;
  MatchedKccaEmail?: Maybe<Order_By>;
  MatchedKccaMobile?: Maybe<Order_By>;
  MatchedKccaNin?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedKccaTin?: Maybe<Order_By>;
  MatchedNiraDob?: Maybe<Order_By>;
  MatchedNiraEmail?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraEmail?: Maybe<Order_By>;
  MatchedUraMobile?: Maybe<Order_By>;
  NiraFirstname?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NiraSurname?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetworkTesting_min_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedKccaTin?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Min_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MatchedKccaDob?: Maybe<Order_By>;
  MatchedKccaEmail?: Maybe<Order_By>;
  MatchedKccaMobile?: Maybe<Order_By>;
  MatchedKccaNin?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedKccaTin?: Maybe<Order_By>;
  MatchedNiraDob?: Maybe<Order_By>;
  MatchedNiraEmail?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraEmail?: Maybe<Order_By>;
  MatchedUraMobile?: Maybe<Order_By>;
  NiraFirstname?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NiraSurname?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetworkTesting_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MatchedKccaDob?: Maybe<Order_By>;
  MatchedKccaEmail?: Maybe<Order_By>;
  MatchedKccaMobile?: Maybe<Order_By>;
  MatchedKccaNin?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedKccaTin?: Maybe<Order_By>;
  MatchedNiraDob?: Maybe<Order_By>;
  MatchedNiraEmail?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraEmail?: Maybe<Order_By>;
  MatchedUraMobile?: Maybe<Order_By>;
  NiraFirstname?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NiraSurname?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
export enum Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Select_Column {
  /** column name */
  AdditionalOwners = "AdditionalOwners",
  /** column name */
  AdditionalProperties = "AdditionalProperties",
  /** column name */
  CountUra = "CountUra",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Fullname = "Fullname",
  /** column name */
  Gml = "Gml",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchDetails = "MatchDetails",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MatchScoreAttributes = "MatchScoreAttributes",
  /** column name */
  MatchedKccaDob = "MatchedKccaDob",
  /** column name */
  MatchedKccaEmail = "MatchedKccaEmail",
  /** column name */
  MatchedKccaMobile = "MatchedKccaMobile",
  /** column name */
  MatchedKccaNin = "MatchedKccaNin",
  /** column name */
  MatchedKccaPhone = "MatchedKccaPhone",
  /** column name */
  MatchedKccaTin = "MatchedKccaTin",
  /** column name */
  MatchedNiraDob = "MatchedNiraDob",
  /** column name */
  MatchedNiraEmail = "MatchedNiraEmail",
  /** column name */
  MatchedNiraNin = "MatchedNiraNin",
  /** column name */
  MatchedNiraPhone = "MatchedNiraPhone",
  /** column name */
  MatchedUraEmail = "MatchedUraEmail",
  /** column name */
  MatchedUraMobile = "MatchedUraMobile",
  /** column name */
  NiraFirstname = "NiraFirstname",
  /** column name */
  NiraNin = "NiraNin",
  /** column name */
  NiraSurname = "NiraSurname",
  /** column name */
  NumOwnedNonRateable = "NumOwnedNonRateable",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Source = "Source",
  /** column name */
  SumAdjustedIncome = "SumAdjustedIncome",
  /** column name */
  SumGrossValue = "SumGrossValue",
  /** column name */
  SumNonRateableValue = "SumNonRateableValue",
  /** column name */
  SumRateableValue = "SumRateableValue",
  /** column name */
  Surname = "Surname",
  /** column name */
  TotalRentedProperties = "TotalRentedProperties",
  /** column name */
  UraTinNo = "UraTinNo",
  /** column name */
  VisibilityLevel = "VisibilityLevel",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Set_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedKccaTin?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetworkTesting_stddev_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Stddev_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetworkTesting_stddev_pop_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Stddev_Pop_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetworkTesting_stddev_samp_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Stddev_Samp_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetworkTesting_sum_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Sum_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetworkTesting_var_pop_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Var_Pop_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetworkTesting_var_samp_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Var_Samp_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetworkTesting_variance_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Variance_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_IndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetwork_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_IndividualsNetwork>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_IndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetwork_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_IndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rmatch_IndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_IndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetwork_avg_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_IndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Avg_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_IndividualsNetwork". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Bool_Exp = {
  AdditionalOwners?: Maybe<Float8_Comparison_Exp>;
  AdditionalProperties?: Maybe<Float8_Comparison_Exp>;
  CountUra?: Maybe<Float8_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Fullname?: Maybe<String_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchDetails?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Bigint_Comparison_Exp>;
  MatchScoreAttributes?: Maybe<String_Comparison_Exp>;
  MatchedKccaDob?: Maybe<String_Comparison_Exp>;
  MatchedKccaEmail?: Maybe<String_Comparison_Exp>;
  MatchedKccaMobile?: Maybe<String_Comparison_Exp>;
  MatchedKccaNin?: Maybe<String_Comparison_Exp>;
  MatchedKccaPhone?: Maybe<String_Comparison_Exp>;
  MatchedKccaTin?: Maybe<String_Comparison_Exp>;
  MatchedNiraDob?: Maybe<String_Comparison_Exp>;
  MatchedNiraEmail?: Maybe<String_Comparison_Exp>;
  MatchedNiraNin?: Maybe<String_Comparison_Exp>;
  MatchedNiraPhone?: Maybe<String_Comparison_Exp>;
  MatchedUraEmail?: Maybe<String_Comparison_Exp>;
  MatchedUraMobile?: Maybe<String_Comparison_Exp>;
  NiraFirstname?: Maybe<String_Comparison_Exp>;
  NiraNin?: Maybe<String_Comparison_Exp>;
  NiraSurname?: Maybe<String_Comparison_Exp>;
  NumOwnedNonRateable?: Maybe<Float8_Comparison_Exp>;
  NumProperties?: Maybe<Float8_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  SumAdjustedIncome?: Maybe<Float8_Comparison_Exp>;
  SumGrossRentValueAdjusted?: Maybe<Float8_Comparison_Exp>;
  SumGrossValue?: Maybe<Float8_Comparison_Exp>;
  SumNonRateableValue?: Maybe<Float8_Comparison_Exp>;
  SumRateableValue?: Maybe<Float8_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  TotalRentedProperties?: Maybe<Float8_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  VisibilityLevel?: Maybe<Float8_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_IndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Inc_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_IndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Insert_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedKccaTin?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetwork_max_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedKccaTin?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_IndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Max_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MatchedKccaDob?: Maybe<Order_By>;
  MatchedKccaEmail?: Maybe<Order_By>;
  MatchedKccaMobile?: Maybe<Order_By>;
  MatchedKccaNin?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedKccaTin?: Maybe<Order_By>;
  MatchedNiraDob?: Maybe<Order_By>;
  MatchedNiraEmail?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraEmail?: Maybe<Order_By>;
  MatchedUraMobile?: Maybe<Order_By>;
  NiraFirstname?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NiraSurname?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetwork_min_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedKccaTin?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_IndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Min_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MatchedKccaDob?: Maybe<Order_By>;
  MatchedKccaEmail?: Maybe<Order_By>;
  MatchedKccaMobile?: Maybe<Order_By>;
  MatchedKccaNin?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedKccaTin?: Maybe<Order_By>;
  MatchedNiraDob?: Maybe<Order_By>;
  MatchedNiraEmail?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraEmail?: Maybe<Order_By>;
  MatchedUraMobile?: Maybe<Order_By>;
  NiraFirstname?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NiraSurname?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_IndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetwork_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_IndividualsNetwork>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_IndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_IndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MatchedKccaDob?: Maybe<Order_By>;
  MatchedKccaEmail?: Maybe<Order_By>;
  MatchedKccaMobile?: Maybe<Order_By>;
  MatchedKccaNin?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedKccaTin?: Maybe<Order_By>;
  MatchedNiraDob?: Maybe<Order_By>;
  MatchedNiraEmail?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraEmail?: Maybe<Order_By>;
  MatchedUraMobile?: Maybe<Order_By>;
  NiraFirstname?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NiraSurname?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_IndividualsNetwork" */
export enum Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Select_Column {
  /** column name */
  AdditionalOwners = "AdditionalOwners",
  /** column name */
  AdditionalProperties = "AdditionalProperties",
  /** column name */
  CountUra = "CountUra",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Fullname = "Fullname",
  /** column name */
  Gml = "Gml",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchDetails = "MatchDetails",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MatchScoreAttributes = "MatchScoreAttributes",
  /** column name */
  MatchedKccaDob = "MatchedKccaDob",
  /** column name */
  MatchedKccaEmail = "MatchedKccaEmail",
  /** column name */
  MatchedKccaMobile = "MatchedKccaMobile",
  /** column name */
  MatchedKccaNin = "MatchedKccaNin",
  /** column name */
  MatchedKccaPhone = "MatchedKccaPhone",
  /** column name */
  MatchedKccaTin = "MatchedKccaTin",
  /** column name */
  MatchedNiraDob = "MatchedNiraDob",
  /** column name */
  MatchedNiraEmail = "MatchedNiraEmail",
  /** column name */
  MatchedNiraNin = "MatchedNiraNin",
  /** column name */
  MatchedNiraPhone = "MatchedNiraPhone",
  /** column name */
  MatchedUraEmail = "MatchedUraEmail",
  /** column name */
  MatchedUraMobile = "MatchedUraMobile",
  /** column name */
  NiraFirstname = "NiraFirstname",
  /** column name */
  NiraNin = "NiraNin",
  /** column name */
  NiraSurname = "NiraSurname",
  /** column name */
  NumOwnedNonRateable = "NumOwnedNonRateable",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Source = "Source",
  /** column name */
  SumAdjustedIncome = "SumAdjustedIncome",
  /** column name */
  SumGrossRentValueAdjusted = "SumGrossRentValueAdjusted",
  /** column name */
  SumGrossValue = "SumGrossValue",
  /** column name */
  SumNonRateableValue = "SumNonRateableValue",
  /** column name */
  SumRateableValue = "SumRateableValue",
  /** column name */
  Surname = "Surname",
  /** column name */
  TotalRentedProperties = "TotalRentedProperties",
  /** column name */
  UraTinNo = "UraTinNo",
  /** column name */
  VisibilityLevel = "VisibilityLevel",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_IndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Set_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedKccaTin?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetwork_stddev_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_IndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Stddev_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetwork_stddev_pop_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_IndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Stddev_Pop_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetwork_stddev_samp_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_IndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Stddev_Samp_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetwork_sum_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_IndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Sum_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetwork_var_pop_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_IndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Var_Pop_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetwork_var_samp_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_IndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Var_Samp_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_IndividualsNetwork_variance_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_IndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Variance_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners = {
  __typename?: "rtcs_db_PH_rmatch_KccaToKccaIndividualOwners";
  BirthdateOwner1?: Maybe<Scalars["timestamp"]>;
  BirthdateOwner2?: Maybe<Scalars["timestamptz"]>;
  EmailOwner1?: Maybe<Scalars["String"]>;
  EmailOwner2?: Maybe<Scalars["String"]>;
  FirstNameOwner1?: Maybe<Scalars["String"]>;
  FirstNameOwner2?: Maybe<Scalars["String"]>;
  IdOwner1?: Maybe<Scalars["String"]>;
  IdOwner2?: Maybe<Scalars["String"]>;
  IdentificationNationalIdOwner1?: Maybe<Scalars["String"]>;
  IdentificationNationalIdOwner2?: Maybe<Scalars["String"]>;
  MatchBirthdate?: Maybe<Scalars["timestamptz"]>;
  MatchEmail?: Maybe<Scalars["String"]>;
  MatchFirstName?: Maybe<Scalars["String"]>;
  MatchIdentificationNationalId?: Maybe<Scalars["String"]>;
  MatchMiddleName?: Maybe<Scalars["String"]>;
  MatchMobile?: Maybe<Scalars["String"]>;
  MatchSurname?: Maybe<Scalars["String"]>;
  MatchTelephone?: Maybe<Scalars["String"]>;
  MiddleNameOwner1?: Maybe<Scalars["String"]>;
  MiddleNameOwner2?: Maybe<Scalars["String"]>;
  MobileOwner1?: Maybe<Scalars["String"]>;
  MobileOwner2?: Maybe<Scalars["String"]>;
  Score?: Maybe<Scalars["bigint"]>;
  SidOwner1?: Maybe<Scalars["String"]>;
  SidOwner2?: Maybe<Scalars["String"]>;
  SurnameOwner1?: Maybe<Scalars["String"]>;
  SurnameOwner2?: Maybe<Scalars["String"]>;
  TelephoneOwner1?: Maybe<Scalars["String"]>;
  TelephoneOwner2?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_KccaToKccaIndividualOwners_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_KccaToKccaIndividualOwners_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_KccaToKccaIndividualOwners_avg_fields";
  Score?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Avg_Order_By = {
  Score?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Bool_Exp = {
  BirthdateOwner1?: Maybe<Timestamp_Comparison_Exp>;
  BirthdateOwner2?: Maybe<Timestamptz_Comparison_Exp>;
  EmailOwner1?: Maybe<String_Comparison_Exp>;
  EmailOwner2?: Maybe<String_Comparison_Exp>;
  FirstNameOwner1?: Maybe<String_Comparison_Exp>;
  FirstNameOwner2?: Maybe<String_Comparison_Exp>;
  IdOwner1?: Maybe<String_Comparison_Exp>;
  IdOwner2?: Maybe<String_Comparison_Exp>;
  IdentificationNationalIdOwner1?: Maybe<String_Comparison_Exp>;
  IdentificationNationalIdOwner2?: Maybe<String_Comparison_Exp>;
  MatchBirthdate?: Maybe<Timestamptz_Comparison_Exp>;
  MatchEmail?: Maybe<String_Comparison_Exp>;
  MatchFirstName?: Maybe<String_Comparison_Exp>;
  MatchIdentificationNationalId?: Maybe<String_Comparison_Exp>;
  MatchMiddleName?: Maybe<String_Comparison_Exp>;
  MatchMobile?: Maybe<String_Comparison_Exp>;
  MatchSurname?: Maybe<String_Comparison_Exp>;
  MatchTelephone?: Maybe<String_Comparison_Exp>;
  MiddleNameOwner1?: Maybe<String_Comparison_Exp>;
  MiddleNameOwner2?: Maybe<String_Comparison_Exp>;
  MobileOwner1?: Maybe<String_Comparison_Exp>;
  MobileOwner2?: Maybe<String_Comparison_Exp>;
  Score?: Maybe<Bigint_Comparison_Exp>;
  SidOwner1?: Maybe<String_Comparison_Exp>;
  SidOwner2?: Maybe<String_Comparison_Exp>;
  SurnameOwner1?: Maybe<String_Comparison_Exp>;
  SurnameOwner2?: Maybe<String_Comparison_Exp>;
  TelephoneOwner1?: Maybe<String_Comparison_Exp>;
  TelephoneOwner2?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Inc_Input = {
  Score?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Insert_Input = {
  BirthdateOwner1?: Maybe<Scalars["timestamp"]>;
  BirthdateOwner2?: Maybe<Scalars["timestamptz"]>;
  EmailOwner1?: Maybe<Scalars["String"]>;
  EmailOwner2?: Maybe<Scalars["String"]>;
  FirstNameOwner1?: Maybe<Scalars["String"]>;
  FirstNameOwner2?: Maybe<Scalars["String"]>;
  IdOwner1?: Maybe<Scalars["String"]>;
  IdOwner2?: Maybe<Scalars["String"]>;
  IdentificationNationalIdOwner1?: Maybe<Scalars["String"]>;
  IdentificationNationalIdOwner2?: Maybe<Scalars["String"]>;
  MatchBirthdate?: Maybe<Scalars["timestamptz"]>;
  MatchEmail?: Maybe<Scalars["String"]>;
  MatchFirstName?: Maybe<Scalars["String"]>;
  MatchIdentificationNationalId?: Maybe<Scalars["String"]>;
  MatchMiddleName?: Maybe<Scalars["String"]>;
  MatchMobile?: Maybe<Scalars["String"]>;
  MatchSurname?: Maybe<Scalars["String"]>;
  MatchTelephone?: Maybe<Scalars["String"]>;
  MiddleNameOwner1?: Maybe<Scalars["String"]>;
  MiddleNameOwner2?: Maybe<Scalars["String"]>;
  MobileOwner1?: Maybe<Scalars["String"]>;
  MobileOwner2?: Maybe<Scalars["String"]>;
  Score?: Maybe<Scalars["bigint"]>;
  SidOwner1?: Maybe<Scalars["String"]>;
  SidOwner2?: Maybe<Scalars["String"]>;
  SurnameOwner1?: Maybe<Scalars["String"]>;
  SurnameOwner2?: Maybe<Scalars["String"]>;
  TelephoneOwner1?: Maybe<Scalars["String"]>;
  TelephoneOwner2?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_KccaToKccaIndividualOwners_max_fields";
  BirthdateOwner1?: Maybe<Scalars["timestamp"]>;
  BirthdateOwner2?: Maybe<Scalars["timestamptz"]>;
  EmailOwner1?: Maybe<Scalars["String"]>;
  EmailOwner2?: Maybe<Scalars["String"]>;
  FirstNameOwner1?: Maybe<Scalars["String"]>;
  FirstNameOwner2?: Maybe<Scalars["String"]>;
  IdOwner1?: Maybe<Scalars["String"]>;
  IdOwner2?: Maybe<Scalars["String"]>;
  IdentificationNationalIdOwner1?: Maybe<Scalars["String"]>;
  IdentificationNationalIdOwner2?: Maybe<Scalars["String"]>;
  MatchBirthdate?: Maybe<Scalars["timestamptz"]>;
  MatchEmail?: Maybe<Scalars["String"]>;
  MatchFirstName?: Maybe<Scalars["String"]>;
  MatchIdentificationNationalId?: Maybe<Scalars["String"]>;
  MatchMiddleName?: Maybe<Scalars["String"]>;
  MatchMobile?: Maybe<Scalars["String"]>;
  MatchSurname?: Maybe<Scalars["String"]>;
  MatchTelephone?: Maybe<Scalars["String"]>;
  MiddleNameOwner1?: Maybe<Scalars["String"]>;
  MiddleNameOwner2?: Maybe<Scalars["String"]>;
  MobileOwner1?: Maybe<Scalars["String"]>;
  MobileOwner2?: Maybe<Scalars["String"]>;
  Score?: Maybe<Scalars["bigint"]>;
  SidOwner1?: Maybe<Scalars["String"]>;
  SidOwner2?: Maybe<Scalars["String"]>;
  SurnameOwner1?: Maybe<Scalars["String"]>;
  SurnameOwner2?: Maybe<Scalars["String"]>;
  TelephoneOwner1?: Maybe<Scalars["String"]>;
  TelephoneOwner2?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Max_Order_By = {
  BirthdateOwner1?: Maybe<Order_By>;
  BirthdateOwner2?: Maybe<Order_By>;
  EmailOwner1?: Maybe<Order_By>;
  EmailOwner2?: Maybe<Order_By>;
  FirstNameOwner1?: Maybe<Order_By>;
  FirstNameOwner2?: Maybe<Order_By>;
  IdOwner1?: Maybe<Order_By>;
  IdOwner2?: Maybe<Order_By>;
  IdentificationNationalIdOwner1?: Maybe<Order_By>;
  IdentificationNationalIdOwner2?: Maybe<Order_By>;
  MatchBirthdate?: Maybe<Order_By>;
  MatchEmail?: Maybe<Order_By>;
  MatchFirstName?: Maybe<Order_By>;
  MatchIdentificationNationalId?: Maybe<Order_By>;
  MatchMiddleName?: Maybe<Order_By>;
  MatchMobile?: Maybe<Order_By>;
  MatchSurname?: Maybe<Order_By>;
  MatchTelephone?: Maybe<Order_By>;
  MiddleNameOwner1?: Maybe<Order_By>;
  MiddleNameOwner2?: Maybe<Order_By>;
  MobileOwner1?: Maybe<Order_By>;
  MobileOwner2?: Maybe<Order_By>;
  Score?: Maybe<Order_By>;
  SidOwner1?: Maybe<Order_By>;
  SidOwner2?: Maybe<Order_By>;
  SurnameOwner1?: Maybe<Order_By>;
  SurnameOwner2?: Maybe<Order_By>;
  TelephoneOwner1?: Maybe<Order_By>;
  TelephoneOwner2?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_KccaToKccaIndividualOwners_min_fields";
  BirthdateOwner1?: Maybe<Scalars["timestamp"]>;
  BirthdateOwner2?: Maybe<Scalars["timestamptz"]>;
  EmailOwner1?: Maybe<Scalars["String"]>;
  EmailOwner2?: Maybe<Scalars["String"]>;
  FirstNameOwner1?: Maybe<Scalars["String"]>;
  FirstNameOwner2?: Maybe<Scalars["String"]>;
  IdOwner1?: Maybe<Scalars["String"]>;
  IdOwner2?: Maybe<Scalars["String"]>;
  IdentificationNationalIdOwner1?: Maybe<Scalars["String"]>;
  IdentificationNationalIdOwner2?: Maybe<Scalars["String"]>;
  MatchBirthdate?: Maybe<Scalars["timestamptz"]>;
  MatchEmail?: Maybe<Scalars["String"]>;
  MatchFirstName?: Maybe<Scalars["String"]>;
  MatchIdentificationNationalId?: Maybe<Scalars["String"]>;
  MatchMiddleName?: Maybe<Scalars["String"]>;
  MatchMobile?: Maybe<Scalars["String"]>;
  MatchSurname?: Maybe<Scalars["String"]>;
  MatchTelephone?: Maybe<Scalars["String"]>;
  MiddleNameOwner1?: Maybe<Scalars["String"]>;
  MiddleNameOwner2?: Maybe<Scalars["String"]>;
  MobileOwner1?: Maybe<Scalars["String"]>;
  MobileOwner2?: Maybe<Scalars["String"]>;
  Score?: Maybe<Scalars["bigint"]>;
  SidOwner1?: Maybe<Scalars["String"]>;
  SidOwner2?: Maybe<Scalars["String"]>;
  SurnameOwner1?: Maybe<Scalars["String"]>;
  SurnameOwner2?: Maybe<Scalars["String"]>;
  TelephoneOwner1?: Maybe<Scalars["String"]>;
  TelephoneOwner2?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Min_Order_By = {
  BirthdateOwner1?: Maybe<Order_By>;
  BirthdateOwner2?: Maybe<Order_By>;
  EmailOwner1?: Maybe<Order_By>;
  EmailOwner2?: Maybe<Order_By>;
  FirstNameOwner1?: Maybe<Order_By>;
  FirstNameOwner2?: Maybe<Order_By>;
  IdOwner1?: Maybe<Order_By>;
  IdOwner2?: Maybe<Order_By>;
  IdentificationNationalIdOwner1?: Maybe<Order_By>;
  IdentificationNationalIdOwner2?: Maybe<Order_By>;
  MatchBirthdate?: Maybe<Order_By>;
  MatchEmail?: Maybe<Order_By>;
  MatchFirstName?: Maybe<Order_By>;
  MatchIdentificationNationalId?: Maybe<Order_By>;
  MatchMiddleName?: Maybe<Order_By>;
  MatchMobile?: Maybe<Order_By>;
  MatchSurname?: Maybe<Order_By>;
  MatchTelephone?: Maybe<Order_By>;
  MiddleNameOwner1?: Maybe<Order_By>;
  MiddleNameOwner2?: Maybe<Order_By>;
  MobileOwner1?: Maybe<Order_By>;
  MobileOwner2?: Maybe<Order_By>;
  Score?: Maybe<Order_By>;
  SidOwner1?: Maybe<Order_By>;
  SidOwner2?: Maybe<Order_By>;
  SurnameOwner1?: Maybe<Order_By>;
  SurnameOwner2?: Maybe<Order_By>;
  TelephoneOwner1?: Maybe<Order_By>;
  TelephoneOwner2?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_KccaToKccaIndividualOwners_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Order_By = {
  BirthdateOwner1?: Maybe<Order_By>;
  BirthdateOwner2?: Maybe<Order_By>;
  EmailOwner1?: Maybe<Order_By>;
  EmailOwner2?: Maybe<Order_By>;
  FirstNameOwner1?: Maybe<Order_By>;
  FirstNameOwner2?: Maybe<Order_By>;
  IdOwner1?: Maybe<Order_By>;
  IdOwner2?: Maybe<Order_By>;
  IdentificationNationalIdOwner1?: Maybe<Order_By>;
  IdentificationNationalIdOwner2?: Maybe<Order_By>;
  MatchBirthdate?: Maybe<Order_By>;
  MatchEmail?: Maybe<Order_By>;
  MatchFirstName?: Maybe<Order_By>;
  MatchIdentificationNationalId?: Maybe<Order_By>;
  MatchMiddleName?: Maybe<Order_By>;
  MatchMobile?: Maybe<Order_By>;
  MatchSurname?: Maybe<Order_By>;
  MatchTelephone?: Maybe<Order_By>;
  MiddleNameOwner1?: Maybe<Order_By>;
  MiddleNameOwner2?: Maybe<Order_By>;
  MobileOwner1?: Maybe<Order_By>;
  MobileOwner2?: Maybe<Order_By>;
  Score?: Maybe<Order_By>;
  SidOwner1?: Maybe<Order_By>;
  SidOwner2?: Maybe<Order_By>;
  SurnameOwner1?: Maybe<Order_By>;
  SurnameOwner2?: Maybe<Order_By>;
  TelephoneOwner1?: Maybe<Order_By>;
  TelephoneOwner2?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
export enum Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Select_Column {
  /** column name */
  BirthdateOwner1 = "BirthdateOwner1",
  /** column name */
  BirthdateOwner2 = "BirthdateOwner2",
  /** column name */
  EmailOwner1 = "EmailOwner1",
  /** column name */
  EmailOwner2 = "EmailOwner2",
  /** column name */
  FirstNameOwner1 = "FirstNameOwner1",
  /** column name */
  FirstNameOwner2 = "FirstNameOwner2",
  /** column name */
  IdOwner1 = "IdOwner1",
  /** column name */
  IdOwner2 = "IdOwner2",
  /** column name */
  IdentificationNationalIdOwner1 = "IdentificationNationalIdOwner1",
  /** column name */
  IdentificationNationalIdOwner2 = "IdentificationNationalIdOwner2",
  /** column name */
  MatchBirthdate = "MatchBirthdate",
  /** column name */
  MatchEmail = "MatchEmail",
  /** column name */
  MatchFirstName = "MatchFirstName",
  /** column name */
  MatchIdentificationNationalId = "MatchIdentificationNationalId",
  /** column name */
  MatchMiddleName = "MatchMiddleName",
  /** column name */
  MatchMobile = "MatchMobile",
  /** column name */
  MatchSurname = "MatchSurname",
  /** column name */
  MatchTelephone = "MatchTelephone",
  /** column name */
  MiddleNameOwner1 = "MiddleNameOwner1",
  /** column name */
  MiddleNameOwner2 = "MiddleNameOwner2",
  /** column name */
  MobileOwner1 = "MobileOwner1",
  /** column name */
  MobileOwner2 = "MobileOwner2",
  /** column name */
  Score = "Score",
  /** column name */
  SidOwner1 = "SidOwner1",
  /** column name */
  SidOwner2 = "SidOwner2",
  /** column name */
  SurnameOwner1 = "SurnameOwner1",
  /** column name */
  SurnameOwner2 = "SurnameOwner2",
  /** column name */
  TelephoneOwner1 = "TelephoneOwner1",
  /** column name */
  TelephoneOwner2 = "TelephoneOwner2",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Set_Input = {
  BirthdateOwner1?: Maybe<Scalars["timestamp"]>;
  BirthdateOwner2?: Maybe<Scalars["timestamptz"]>;
  EmailOwner1?: Maybe<Scalars["String"]>;
  EmailOwner2?: Maybe<Scalars["String"]>;
  FirstNameOwner1?: Maybe<Scalars["String"]>;
  FirstNameOwner2?: Maybe<Scalars["String"]>;
  IdOwner1?: Maybe<Scalars["String"]>;
  IdOwner2?: Maybe<Scalars["String"]>;
  IdentificationNationalIdOwner1?: Maybe<Scalars["String"]>;
  IdentificationNationalIdOwner2?: Maybe<Scalars["String"]>;
  MatchBirthdate?: Maybe<Scalars["timestamptz"]>;
  MatchEmail?: Maybe<Scalars["String"]>;
  MatchFirstName?: Maybe<Scalars["String"]>;
  MatchIdentificationNationalId?: Maybe<Scalars["String"]>;
  MatchMiddleName?: Maybe<Scalars["String"]>;
  MatchMobile?: Maybe<Scalars["String"]>;
  MatchSurname?: Maybe<Scalars["String"]>;
  MatchTelephone?: Maybe<Scalars["String"]>;
  MiddleNameOwner1?: Maybe<Scalars["String"]>;
  MiddleNameOwner2?: Maybe<Scalars["String"]>;
  MobileOwner1?: Maybe<Scalars["String"]>;
  MobileOwner2?: Maybe<Scalars["String"]>;
  Score?: Maybe<Scalars["bigint"]>;
  SidOwner1?: Maybe<Scalars["String"]>;
  SidOwner2?: Maybe<Scalars["String"]>;
  SurnameOwner1?: Maybe<Scalars["String"]>;
  SurnameOwner2?: Maybe<Scalars["String"]>;
  TelephoneOwner1?: Maybe<Scalars["String"]>;
  TelephoneOwner2?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_KccaToKccaIndividualOwners_stddev_fields";
  Score?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Stddev_Order_By = {
  Score?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_KccaToKccaIndividualOwners_stddev_pop_fields";
  Score?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Stddev_Pop_Order_By = {
  Score?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_KccaToKccaIndividualOwners_stddev_samp_fields";
  Score?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Stddev_Samp_Order_By =
  {
    Score?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_KccaToKccaIndividualOwners_sum_fields";
  Score?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Sum_Order_By = {
  Score?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_KccaToKccaIndividualOwners_var_pop_fields";
  Score?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Var_Pop_Order_By = {
  Score?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_KccaToKccaIndividualOwners_var_samp_fields";
  Score?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Var_Samp_Order_By = {
  Score?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_KccaToKccaIndividualOwners_variance_fields";
  Score?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Variance_Order_By = {
  Score?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners = {
  __typename?: "rtcs_db_PH_rmatch_KccaToKccaNonindividualOwners";
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId1?: Maybe<Scalars["bigint"]>;
  KccaCustomerId2?: Maybe<Scalars["bigint"]>;
  KccaEntityLegalName1?: Maybe<Scalars["String"]>;
  KccaEntityLegalName2?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  urid?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_KccaToKccaNonindividualOwners_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_KccaToKccaNonindividualOwners_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Aggregate_Order_By =
  {
    avg?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Max_Order_By>;
    min?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Min_Order_By>;
    stddev?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Stddev_Order_By>;
    stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Stddev_Samp_Order_By>;
    sum?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Sum_Order_By>;
    var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Var_Pop_Order_By>;
    var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Var_Samp_Order_By>;
    variance?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Variance_Order_By>;
  };

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_KccaToKccaNonindividualOwners_avg_fields";
  KccaCustomerId1?: Maybe<Scalars["Float"]>;
  KccaCustomerId2?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Avg_Order_By = {
  KccaCustomerId1?: Maybe<Order_By>;
  KccaCustomerId2?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  FinalMatchCategory?: Maybe<String_Comparison_Exp>;
  KccaCustomerId1?: Maybe<Bigint_Comparison_Exp>;
  KccaCustomerId2?: Maybe<Bigint_Comparison_Exp>;
  KccaEntityLegalName1?: Maybe<String_Comparison_Exp>;
  KccaEntityLegalName2?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  RmatchScore?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Bool_Exp>>
  >;
  urid?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Inc_Input = {
  KccaCustomerId1?: Maybe<Scalars["bigint"]>;
  KccaCustomerId2?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId1?: Maybe<Scalars["bigint"]>;
  KccaCustomerId2?: Maybe<Scalars["bigint"]>;
  KccaEntityLegalName1?: Maybe<Scalars["String"]>;
  KccaEntityLegalName2?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  urid?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_KccaToKccaNonindividualOwners_max_fields";
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId1?: Maybe<Scalars["bigint"]>;
  KccaCustomerId2?: Maybe<Scalars["bigint"]>;
  KccaEntityLegalName1?: Maybe<Scalars["String"]>;
  KccaEntityLegalName2?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  urid?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Max_Order_By = {
  File?: Maybe<Order_By>;
  FinalMatchCategory?: Maybe<Order_By>;
  KccaCustomerId1?: Maybe<Order_By>;
  KccaCustomerId2?: Maybe<Order_By>;
  KccaEntityLegalName1?: Maybe<Order_By>;
  KccaEntityLegalName2?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  urid?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_KccaToKccaNonindividualOwners_min_fields";
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId1?: Maybe<Scalars["bigint"]>;
  KccaCustomerId2?: Maybe<Scalars["bigint"]>;
  KccaEntityLegalName1?: Maybe<Scalars["String"]>;
  KccaEntityLegalName2?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  urid?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Min_Order_By = {
  File?: Maybe<Order_By>;
  FinalMatchCategory?: Maybe<Order_By>;
  KccaCustomerId1?: Maybe<Order_By>;
  KccaCustomerId2?: Maybe<Order_By>;
  KccaEntityLegalName1?: Maybe<Order_By>;
  KccaEntityLegalName2?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  urid?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Mutation_Response =
  {
    __typename?: "rtcs_db_PH_rmatch_KccaToKccaNonindividualOwners_mutation_response";
    /** number of affected rows by the mutation */
    affected_rows: Scalars["Int"];
    /** data of the affected rows by the mutation */
    returning: Array<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners>;
  };

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Order_By = {
  File?: Maybe<Order_By>;
  FinalMatchCategory?: Maybe<Order_By>;
  KccaCustomerId1?: Maybe<Order_By>;
  KccaCustomerId2?: Maybe<Order_By>;
  KccaEntityLegalName1?: Maybe<Order_By>;
  KccaEntityLegalName2?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  urid?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
export enum Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  FinalMatchCategory = "FinalMatchCategory",
  /** column name */
  KccaCustomerId1 = "KccaCustomerId1",
  /** column name */
  KccaCustomerId2 = "KccaCustomerId2",
  /** column name */
  KccaEntityLegalName1 = "KccaEntityLegalName1",
  /** column name */
  KccaEntityLegalName2 = "KccaEntityLegalName2",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  RmatchScore = "RmatchScore",
  /** column name */
  WhoUpdated = "WhoUpdated",
  /** column name */
  Urid = "urid",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId1?: Maybe<Scalars["bigint"]>;
  KccaCustomerId2?: Maybe<Scalars["bigint"]>;
  KccaEntityLegalName1?: Maybe<Scalars["String"]>;
  KccaEntityLegalName2?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  urid?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_KccaToKccaNonindividualOwners_stddev_fields";
  KccaCustomerId1?: Maybe<Scalars["Float"]>;
  KccaCustomerId2?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Stddev_Order_By = {
  KccaCustomerId1?: Maybe<Order_By>;
  KccaCustomerId2?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Stddev_Pop_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_KccaToKccaNonindividualOwners_stddev_pop_fields";
    KccaCustomerId1?: Maybe<Scalars["Float"]>;
    KccaCustomerId2?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    RmatchScore?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Stddev_Pop_Order_By =
  {
    KccaCustomerId1?: Maybe<Order_By>;
    KccaCustomerId2?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    RmatchScore?: Maybe<Order_By>;
  };

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Stddev_Samp_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_KccaToKccaNonindividualOwners_stddev_samp_fields";
    KccaCustomerId1?: Maybe<Scalars["Float"]>;
    KccaCustomerId2?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    RmatchScore?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Stddev_Samp_Order_By =
  {
    KccaCustomerId1?: Maybe<Order_By>;
    KccaCustomerId2?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    RmatchScore?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_KccaToKccaNonindividualOwners_sum_fields";
  KccaCustomerId1?: Maybe<Scalars["bigint"]>;
  KccaCustomerId2?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Sum_Order_By = {
  KccaCustomerId1?: Maybe<Order_By>;
  KccaCustomerId2?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_KccaToKccaNonindividualOwners_var_pop_fields";
  KccaCustomerId1?: Maybe<Scalars["Float"]>;
  KccaCustomerId2?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Var_Pop_Order_By = {
  KccaCustomerId1?: Maybe<Order_By>;
  KccaCustomerId2?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_KccaToKccaNonindividualOwners_var_samp_fields";
  KccaCustomerId1?: Maybe<Scalars["Float"]>;
  KccaCustomerId2?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Var_Samp_Order_By =
  {
    KccaCustomerId1?: Maybe<Order_By>;
    KccaCustomerId2?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    RmatchScore?: Maybe<Order_By>;
  };

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_KccaToKccaNonindividualOwners_variance_fields";
  KccaCustomerId1?: Maybe<Scalars["Float"]>;
  KccaCustomerId2?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Variance_Order_By =
  {
    KccaCustomerId1?: Maybe<Order_By>;
    KccaCustomerId2?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    RmatchScore?: Maybe<Order_By>;
  };

/** columns and relationships of "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProperties";
  CountUra?: Maybe<Scalars["bigint"]>;
  Gml?: Maybe<Scalars["String"]>;
  JsonStructure?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProperties_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProperties_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProperties_avg_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Avg_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_MatchIndividualsProperties". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Bool_Exp = {
  CountUra?: Maybe<Bigint_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  JsonStructure?: Maybe<String_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchDetails?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Bigint_Comparison_Exp>;
  MatchScoreAttributes?: Maybe<String_Comparison_Exp>;
  NumOwnedNonRateable?: Maybe<Bigint_Comparison_Exp>;
  NumProperties?: Maybe<Bigint_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  SumAdjustedIncome?: Maybe<Float8_Comparison_Exp>;
  SumGrossValue?: Maybe<Float8_Comparison_Exp>;
  SumNonRateableValue?: Maybe<Float8_Comparison_Exp>;
  SumRateableValue?: Maybe<Float8_Comparison_Exp>;
  TotalRentedProperties?: Maybe<Bigint_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Inc_Input = {
  CountUra?: Maybe<Scalars["bigint"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Insert_Input = {
  CountUra?: Maybe<Scalars["bigint"]>;
  Gml?: Maybe<Scalars["String"]>;
  JsonStructure?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProperties_max_fields";
  CountUra?: Maybe<Scalars["bigint"]>;
  Gml?: Maybe<Scalars["String"]>;
  JsonStructure?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Max_Order_By = {
  CountUra?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  JsonStructure?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProperties_min_fields";
  CountUra?: Maybe<Scalars["bigint"]>;
  Gml?: Maybe<Scalars["String"]>;
  JsonStructure?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Min_Order_By = {
  CountUra?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  JsonStructure?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProperties_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Order_By = {
  CountUra?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  JsonStructure?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
export enum Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Select_Column {
  /** column name */
  CountUra = "CountUra",
  /** column name */
  Gml = "Gml",
  /** column name */
  JsonStructure = "JsonStructure",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchDetails = "MatchDetails",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MatchScoreAttributes = "MatchScoreAttributes",
  /** column name */
  NumOwnedNonRateable = "NumOwnedNonRateable",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  SumAdjustedIncome = "SumAdjustedIncome",
  /** column name */
  SumGrossValue = "SumGrossValue",
  /** column name */
  SumNonRateableValue = "SumNonRateableValue",
  /** column name */
  SumRateableValue = "SumRateableValue",
  /** column name */
  TotalRentedProperties = "TotalRentedProperties",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Set_Input = {
  CountUra?: Maybe<Scalars["bigint"]>;
  Gml?: Maybe<Scalars["String"]>;
  JsonStructure?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProperties_stddev_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Stddev_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProperties_stddev_pop_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Stddev_Pop_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProperties_stddev_samp_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Stddev_Samp_Order_By =
  {
    CountUra?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    NumOwnedNonRateable?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    SumAdjustedIncome?: Maybe<Order_By>;
    SumGrossValue?: Maybe<Order_By>;
    SumNonRateableValue?: Maybe<Order_By>;
    SumRateableValue?: Maybe<Order_By>;
    TotalRentedProperties?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProperties_sum_fields";
  CountUra?: Maybe<Scalars["bigint"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Sum_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProperties_var_pop_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Var_Pop_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProperties_var_samp_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Var_Samp_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProperties_variance_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Variance_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProps";
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  JsonStructure?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProps_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProps_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProps_avg_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Avg_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_MatchIndividualsProps". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Bool_Exp = {
  CountUra?: Maybe<Float8_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  JsonStructure?: Maybe<String_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchDetails?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Float8_Comparison_Exp>;
  MatchScoreAttributes?: Maybe<String_Comparison_Exp>;
  NumOwnedNonRateable?: Maybe<Float8_Comparison_Exp>;
  NumProperties?: Maybe<Float8_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  SumAdjustedIncome?: Maybe<Float8_Comparison_Exp>;
  SumGrossValue?: Maybe<Float8_Comparison_Exp>;
  SumNonRateableValue?: Maybe<Float8_Comparison_Exp>;
  SumRateableValue?: Maybe<Float8_Comparison_Exp>;
  TotalRentedProperties?: Maybe<Float8_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Inc_Input = {
  CountUra?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Insert_Input = {
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  JsonStructure?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProps_max_fields";
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  JsonStructure?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Max_Order_By = {
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  JsonStructure?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProps_min_fields";
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  JsonStructure?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Min_Order_By = {
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  JsonStructure?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProps_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Order_By = {
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  JsonStructure?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_MatchIndividualsProps" */
export enum Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Select_Column {
  /** column name */
  CountUra = "CountUra",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  Gml = "Gml",
  /** column name */
  JsonStructure = "JsonStructure",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchDetails = "MatchDetails",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MatchScoreAttributes = "MatchScoreAttributes",
  /** column name */
  NumOwnedNonRateable = "NumOwnedNonRateable",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  SumAdjustedIncome = "SumAdjustedIncome",
  /** column name */
  SumGrossValue = "SumGrossValue",
  /** column name */
  SumNonRateableValue = "SumNonRateableValue",
  /** column name */
  SumRateableValue = "SumRateableValue",
  /** column name */
  TotalRentedProperties = "TotalRentedProperties",
  /** column name */
  UraTinNo = "UraTinNo",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Set_Input = {
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  JsonStructure?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProps_stddev_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Stddev_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProps_stddev_pop_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Stddev_Pop_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProps_stddev_samp_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Stddev_Samp_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProps_sum_fields";
  CountUra?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Sum_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProps_var_pop_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Var_Pop_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProps_var_samp_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Var_Samp_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_MatchIndividualsProps_variance_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Variance_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_NoReturns" */
export type Rtcs_Db_Ph_Rmatch_NoReturns = {
  __typename?: "rtcs_db_PH_rmatch_NoReturns";
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_NoReturns" */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_NoReturns_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_NoReturns>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_NoReturns" */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NoReturns_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_NoReturns" */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NoReturns_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rmatch_NoReturns" */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_NoReturns" */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_NoReturns_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NoReturns_avg_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_NoReturns" */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Avg_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_NoReturns". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Bool_Exp = {
  Compliancecategory?: Maybe<String_Comparison_Exp>;
  Customerid?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Isincomesrcrental?: Maybe<String_Comparison_Exp>;
  Kccadob?: Maybe<String_Comparison_Exp>;
  Kccaemail?: Maybe<String_Comparison_Exp>;
  Kccafirstname?: Maybe<String_Comparison_Exp>;
  Kccalandline?: Maybe<String_Comparison_Exp>;
  Kccamiddlename?: Maybe<String_Comparison_Exp>;
  Kccamobile1?: Maybe<String_Comparison_Exp>;
  Kccamobile2?: Maybe<String_Comparison_Exp>;
  Kccanin?: Maybe<String_Comparison_Exp>;
  Kccasurname?: Maybe<String_Comparison_Exp>;
  Kccatin?: Maybe<Float8_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Matchscore?: Maybe<Bigint_Comparison_Exp>;
  Matchscoreattributes?: Maybe<String_Comparison_Exp>;
  Numproperties?: Maybe<Bigint_Comparison_Exp>;
  Sumgrossvalue?: Maybe<Float8_Comparison_Exp>;
  Sumrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Totalrentedproperties?: Maybe<Bigint_Comparison_Exp>;
  Uraemailid?: Maybe<String_Comparison_Exp>;
  Urafirstname?: Maybe<String_Comparison_Exp>;
  Uramidlename?: Maybe<String_Comparison_Exp>;
  Uramobilenumber?: Maybe<Float8_Comparison_Exp>;
  Uranationalid?: Maybe<String_Comparison_Exp>;
  Urasurname?: Maybe<String_Comparison_Exp>;
  Uratinno?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_NoReturns" */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Inc_Input = {
  Kccatin?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_NoReturns" */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Insert_Input = {
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NoReturns_max_fields";
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_NoReturns" */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Max_Order_By = {
  Compliancecategory?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccadob?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccalandline?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccanin?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uraemailid?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramidlename?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
  Uranationalid?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NoReturns_min_fields";
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_NoReturns" */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Min_Order_By = {
  Compliancecategory?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccadob?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccalandline?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccanin?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uraemailid?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramidlename?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
  Uranationalid?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_NoReturns" */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_NoReturns_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_NoReturns>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_NoReturns" */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_NoReturns_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_NoReturns" */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Order_By = {
  Compliancecategory?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccadob?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccalandline?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccanin?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uraemailid?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramidlename?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
  Uranationalid?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_NoReturns" */
export enum Rtcs_Db_Ph_Rmatch_NoReturns_Select_Column {
  /** column name */
  Compliancecategory = "Compliancecategory",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  File = "File",
  /** column name */
  Isincomesrcrental = "Isincomesrcrental",
  /** column name */
  Kccadob = "Kccadob",
  /** column name */
  Kccaemail = "Kccaemail",
  /** column name */
  Kccafirstname = "Kccafirstname",
  /** column name */
  Kccalandline = "Kccalandline",
  /** column name */
  Kccamiddlename = "Kccamiddlename",
  /** column name */
  Kccamobile1 = "Kccamobile1",
  /** column name */
  Kccamobile2 = "Kccamobile2",
  /** column name */
  Kccanin = "Kccanin",
  /** column name */
  Kccasurname = "Kccasurname",
  /** column name */
  Kccatin = "Kccatin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Matchscoreattributes = "Matchscoreattributes",
  /** column name */
  Numproperties = "Numproperties",
  /** column name */
  Sumgrossvalue = "Sumgrossvalue",
  /** column name */
  Sumrateablevalue = "Sumrateablevalue",
  /** column name */
  Totalrentedproperties = "Totalrentedproperties",
  /** column name */
  Uraemailid = "Uraemailid",
  /** column name */
  Urafirstname = "Urafirstname",
  /** column name */
  Uramidlename = "Uramidlename",
  /** column name */
  Uramobilenumber = "Uramobilenumber",
  /** column name */
  Uranationalid = "Uranationalid",
  /** column name */
  Urasurname = "Urasurname",
  /** column name */
  Uratinno = "Uratinno",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_NoReturns" */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Set_Input = {
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NoReturns_stddev_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_NoReturns" */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Stddev_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NoReturns_stddev_pop_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_NoReturns" */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Stddev_Pop_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NoReturns_stddev_samp_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_NoReturns" */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Stddev_Samp_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NoReturns_sum_fields";
  Kccatin?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_NoReturns" */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Sum_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NoReturns_var_pop_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_NoReturns" */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Var_Pop_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NoReturns_var_samp_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_NoReturns" */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Var_Samp_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NoReturns_variance_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_NoReturns" */
export type Rtcs_Db_Ph_Rmatch_NoReturns_Variance_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_NonIndividual" */
export type Rtcs_Db_Ph_Rmatch_NonIndividual = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividual";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceDetailReport";
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Createddate?: Maybe<Scalars["timestamptz"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  District?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Entitylegalname?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["bigint"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Ownerfirstname?: Maybe<Scalars["String"]>;
  Ownerlegalsurnamemaiden?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Propertyrnid?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Rnidowner?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytype?: Maybe<Scalars["String"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Taxpayername?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceDetailReport_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Aggregate_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceDetailReport_aggregate_fields";
    avg?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Avg_Fields>;
    count?: Maybe<Scalars["Int"]>;
    max?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Max_Fields>;
    min?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Min_Fields>;
    stddev?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Stddev_Fields>;
    stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Stddev_Samp_Fields>;
    sum?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Sum_Fields>;
    var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Var_Pop_Fields>;
    var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Var_Samp_Fields>;
    variance?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Variance_Fields>;
  };

/** aggregate fields of "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Aggregate_Order_By =
  {
    avg?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Max_Order_By>;
    min?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Min_Order_By>;
    stddev?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Stddev_Order_By>;
    stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Stddev_Samp_Order_By>;
    sum?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Sum_Order_By>;
    var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Var_Pop_Order_By>;
    var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Var_Samp_Order_By>;
    variance?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Variance_Order_By>;
  };

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceDetailReport_avg_fields";
  Currentrateablevalue?: Maybe<Scalars["Float"]>;
  Customermatchscore?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  Kccacustomerid?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Avg_Order_By =
  {
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Kccacustomerid?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
  };

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  Blockno?: Maybe<String_Comparison_Exp>;
  Createddate?: Maybe<Timestamptz_Comparison_Exp>;
  Currentrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Customermatchscore?: Maybe<Bigint_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  Division?: Maybe<String_Comparison_Exp>;
  Emailid?: Maybe<String_Comparison_Exp>;
  Entitylegalname?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Flatno?: Maybe<String_Comparison_Exp>;
  Grossrentvalueadjusted?: Maybe<Float8_Comparison_Exp>;
  Grossvalue?: Maybe<Float8_Comparison_Exp>;
  Holdingcompname?: Maybe<String_Comparison_Exp>;
  Holdingcomptin?: Maybe<String_Comparison_Exp>;
  Houseno?: Maybe<String_Comparison_Exp>;
  Isincomesrcbsns?: Maybe<String_Comparison_Exp>;
  Isincomesrcprpty?: Maybe<String_Comparison_Exp>;
  Isincomesrcrental?: Maybe<String_Comparison_Exp>;
  Kccacustomerid?: Maybe<Bigint_Comparison_Exp>;
  Kccaname?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  Maptitle?: Maybe<String_Comparison_Exp>;
  Matchscore?: Maybe<Float8_Comparison_Exp>;
  Mobilenumber?: Maybe<String_Comparison_Exp>;
  Orgsubtype?: Maybe<String_Comparison_Exp>;
  Orgtype?: Maybe<String_Comparison_Exp>;
  Ownerfirstname?: Maybe<String_Comparison_Exp>;
  Ownerlegalsurnamemaiden?: Maybe<String_Comparison_Exp>;
  Parish?: Maybe<String_Comparison_Exp>;
  Plotno?: Maybe<String_Comparison_Exp>;
  Propertyno?: Maybe<String_Comparison_Exp>;
  Propertyrentedstatus?: Maybe<String_Comparison_Exp>;
  Propertyrnid?: Maybe<String_Comparison_Exp>;
  Propertytype?: Maybe<String_Comparison_Exp>;
  Rateablevalue?: Maybe<Float8_Comparison_Exp>;
  Rnidcustomer?: Maybe<String_Comparison_Exp>;
  Rnidowner?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  Streetid?: Maybe<String_Comparison_Exp>;
  Subpropertytype?: Maybe<String_Comparison_Exp>;
  Taxpayerassumed?: Maybe<String_Comparison_Exp>;
  Taxpayername?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<String_Comparison_Exp>;
  Tinno?: Maybe<String_Comparison_Exp>;
  Tradingcenter?: Maybe<String_Comparison_Exp>;
  Tradingname?: Maybe<String_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Inc_Input = {
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Kccacustomerid?: Maybe<Scalars["bigint"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Insert_Input =
  {
    Address?: Maybe<Scalars["String"]>;
    Blockno?: Maybe<Scalars["String"]>;
    Createddate?: Maybe<Scalars["timestamptz"]>;
    Currentrateablevalue?: Maybe<Scalars["float8"]>;
    Customermatchscore?: Maybe<Scalars["bigint"]>;
    District?: Maybe<Scalars["String"]>;
    Division?: Maybe<Scalars["String"]>;
    Emailid?: Maybe<Scalars["String"]>;
    Entitylegalname?: Maybe<Scalars["String"]>;
    File?: Maybe<Scalars["String"]>;
    Flatno?: Maybe<Scalars["String"]>;
    Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
    Grossvalue?: Maybe<Scalars["float8"]>;
    Holdingcompname?: Maybe<Scalars["String"]>;
    Holdingcomptin?: Maybe<Scalars["String"]>;
    Houseno?: Maybe<Scalars["String"]>;
    Isincomesrcbsns?: Maybe<Scalars["String"]>;
    Isincomesrcprpty?: Maybe<Scalars["String"]>;
    Isincomesrcrental?: Maybe<Scalars["String"]>;
    Kccacustomerid?: Maybe<Scalars["bigint"]>;
    Kccaname?: Maybe<Scalars["String"]>;
    LastUpdated?: Maybe<Scalars["timestamptz"]>;
    Latitude?: Maybe<Scalars["float8"]>;
    Line?: Maybe<Scalars["bigint"]>;
    Longitude?: Maybe<Scalars["float8"]>;
    Maptitle?: Maybe<Scalars["String"]>;
    Matchscore?: Maybe<Scalars["float8"]>;
    Mobilenumber?: Maybe<Scalars["String"]>;
    Orgsubtype?: Maybe<Scalars["String"]>;
    Orgtype?: Maybe<Scalars["String"]>;
    Ownerfirstname?: Maybe<Scalars["String"]>;
    Ownerlegalsurnamemaiden?: Maybe<Scalars["String"]>;
    Parish?: Maybe<Scalars["String"]>;
    Plotno?: Maybe<Scalars["String"]>;
    Propertyno?: Maybe<Scalars["String"]>;
    Propertyrentedstatus?: Maybe<Scalars["String"]>;
    Propertyrnid?: Maybe<Scalars["String"]>;
    Propertytype?: Maybe<Scalars["String"]>;
    Rateablevalue?: Maybe<Scalars["float8"]>;
    Rnidcustomer?: Maybe<Scalars["String"]>;
    Rnidowner?: Maybe<Scalars["String"]>;
    Source?: Maybe<Scalars["String"]>;
    Streetid?: Maybe<Scalars["String"]>;
    Subpropertytype?: Maybe<Scalars["String"]>;
    Taxpayerassumed?: Maybe<Scalars["String"]>;
    Taxpayername?: Maybe<Scalars["String"]>;
    Tin?: Maybe<Scalars["String"]>;
    Tinno?: Maybe<Scalars["String"]>;
    Tradingcenter?: Maybe<Scalars["String"]>;
    Tradingname?: Maybe<Scalars["String"]>;
    Village?: Maybe<Scalars["String"]>;
    WhoUpdated?: Maybe<Scalars["String"]>;
  };

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceDetailReport_max_fields";
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Createddate?: Maybe<Scalars["timestamptz"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  District?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Entitylegalname?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["bigint"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Ownerfirstname?: Maybe<Scalars["String"]>;
  Ownerlegalsurnamemaiden?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Propertyrnid?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Rnidowner?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytype?: Maybe<Scalars["String"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Taxpayername?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Max_Order_By =
  {
    Address?: Maybe<Order_By>;
    Blockno?: Maybe<Order_By>;
    Createddate?: Maybe<Order_By>;
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    District?: Maybe<Order_By>;
    Division?: Maybe<Order_By>;
    Emailid?: Maybe<Order_By>;
    Entitylegalname?: Maybe<Order_By>;
    File?: Maybe<Order_By>;
    Flatno?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Holdingcompname?: Maybe<Order_By>;
    Holdingcomptin?: Maybe<Order_By>;
    Houseno?: Maybe<Order_By>;
    Isincomesrcbsns?: Maybe<Order_By>;
    Isincomesrcprpty?: Maybe<Order_By>;
    Isincomesrcrental?: Maybe<Order_By>;
    Kccacustomerid?: Maybe<Order_By>;
    Kccaname?: Maybe<Order_By>;
    LastUpdated?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Maptitle?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Orgsubtype?: Maybe<Order_By>;
    Orgtype?: Maybe<Order_By>;
    Ownerfirstname?: Maybe<Order_By>;
    Ownerlegalsurnamemaiden?: Maybe<Order_By>;
    Parish?: Maybe<Order_By>;
    Plotno?: Maybe<Order_By>;
    Propertyno?: Maybe<Order_By>;
    Propertyrentedstatus?: Maybe<Order_By>;
    Propertyrnid?: Maybe<Order_By>;
    Propertytype?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
    Rnidcustomer?: Maybe<Order_By>;
    Rnidowner?: Maybe<Order_By>;
    Source?: Maybe<Order_By>;
    Streetid?: Maybe<Order_By>;
    Subpropertytype?: Maybe<Order_By>;
    Taxpayerassumed?: Maybe<Order_By>;
    Taxpayername?: Maybe<Order_By>;
    Tin?: Maybe<Order_By>;
    Tinno?: Maybe<Order_By>;
    Tradingcenter?: Maybe<Order_By>;
    Tradingname?: Maybe<Order_By>;
    Village?: Maybe<Order_By>;
    WhoUpdated?: Maybe<Order_By>;
  };

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceDetailReport_min_fields";
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Createddate?: Maybe<Scalars["timestamptz"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  District?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Entitylegalname?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["bigint"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Ownerfirstname?: Maybe<Scalars["String"]>;
  Ownerlegalsurnamemaiden?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Propertyrnid?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Rnidowner?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytype?: Maybe<Scalars["String"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Taxpayername?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Min_Order_By =
  {
    Address?: Maybe<Order_By>;
    Blockno?: Maybe<Order_By>;
    Createddate?: Maybe<Order_By>;
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    District?: Maybe<Order_By>;
    Division?: Maybe<Order_By>;
    Emailid?: Maybe<Order_By>;
    Entitylegalname?: Maybe<Order_By>;
    File?: Maybe<Order_By>;
    Flatno?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Holdingcompname?: Maybe<Order_By>;
    Holdingcomptin?: Maybe<Order_By>;
    Houseno?: Maybe<Order_By>;
    Isincomesrcbsns?: Maybe<Order_By>;
    Isincomesrcprpty?: Maybe<Order_By>;
    Isincomesrcrental?: Maybe<Order_By>;
    Kccacustomerid?: Maybe<Order_By>;
    Kccaname?: Maybe<Order_By>;
    LastUpdated?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Maptitle?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Orgsubtype?: Maybe<Order_By>;
    Orgtype?: Maybe<Order_By>;
    Ownerfirstname?: Maybe<Order_By>;
    Ownerlegalsurnamemaiden?: Maybe<Order_By>;
    Parish?: Maybe<Order_By>;
    Plotno?: Maybe<Order_By>;
    Propertyno?: Maybe<Order_By>;
    Propertyrentedstatus?: Maybe<Order_By>;
    Propertyrnid?: Maybe<Order_By>;
    Propertytype?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
    Rnidcustomer?: Maybe<Order_By>;
    Rnidowner?: Maybe<Order_By>;
    Source?: Maybe<Order_By>;
    Streetid?: Maybe<Order_By>;
    Subpropertytype?: Maybe<Order_By>;
    Taxpayerassumed?: Maybe<Order_By>;
    Taxpayername?: Maybe<Order_By>;
    Tin?: Maybe<Order_By>;
    Tinno?: Maybe<Order_By>;
    Tradingcenter?: Maybe<Order_By>;
    Tradingname?: Maybe<Order_By>;
    Village?: Maybe<Order_By>;
    WhoUpdated?: Maybe<Order_By>;
  };

/** response of any mutation on the table "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Mutation_Response =
  {
    __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceDetailReport_mutation_response";
    /** number of affected rows by the mutation */
    affected_rows: Scalars["Int"];
    /** data of the affected rows by the mutation */
    returning: Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport>;
  };

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Order_By = {
  Address?: Maybe<Order_By>;
  Blockno?: Maybe<Order_By>;
  Createddate?: Maybe<Order_By>;
  Currentrateablevalue?: Maybe<Order_By>;
  Customermatchscore?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  Entitylegalname?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Holdingcompname?: Maybe<Order_By>;
  Holdingcomptin?: Maybe<Order_By>;
  Houseno?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Kccaname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Maptitle?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Orgsubtype?: Maybe<Order_By>;
  Orgtype?: Maybe<Order_By>;
  Ownerfirstname?: Maybe<Order_By>;
  Ownerlegalsurnamemaiden?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  Propertyrnid?: Maybe<Order_By>;
  Propertytype?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rnidcustomer?: Maybe<Order_By>;
  Rnidowner?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subpropertytype?: Maybe<Order_By>;
  Taxpayerassumed?: Maybe<Order_By>;
  Taxpayername?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Tradingcenter?: Maybe<Order_By>;
  Tradingname?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
export enum Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  Blockno = "Blockno",
  /** column name */
  Createddate = "Createddate",
  /** column name */
  Currentrateablevalue = "Currentrateablevalue",
  /** column name */
  Customermatchscore = "Customermatchscore",
  /** column name */
  District = "District",
  /** column name */
  Division = "Division",
  /** column name */
  Emailid = "Emailid",
  /** column name */
  Entitylegalname = "Entitylegalname",
  /** column name */
  File = "File",
  /** column name */
  Flatno = "Flatno",
  /** column name */
  Grossrentvalueadjusted = "Grossrentvalueadjusted",
  /** column name */
  Grossvalue = "Grossvalue",
  /** column name */
  Holdingcompname = "Holdingcompname",
  /** column name */
  Holdingcomptin = "Holdingcomptin",
  /** column name */
  Houseno = "Houseno",
  /** column name */
  Isincomesrcbsns = "Isincomesrcbsns",
  /** column name */
  Isincomesrcprpty = "Isincomesrcprpty",
  /** column name */
  Isincomesrcrental = "Isincomesrcrental",
  /** column name */
  Kccacustomerid = "Kccacustomerid",
  /** column name */
  Kccaname = "Kccaname",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  Maptitle = "Maptitle",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Mobilenumber = "Mobilenumber",
  /** column name */
  Orgsubtype = "Orgsubtype",
  /** column name */
  Orgtype = "Orgtype",
  /** column name */
  Ownerfirstname = "Ownerfirstname",
  /** column name */
  Ownerlegalsurnamemaiden = "Ownerlegalsurnamemaiden",
  /** column name */
  Parish = "Parish",
  /** column name */
  Plotno = "Plotno",
  /** column name */
  Propertyno = "Propertyno",
  /** column name */
  Propertyrentedstatus = "Propertyrentedstatus",
  /** column name */
  Propertyrnid = "Propertyrnid",
  /** column name */
  Propertytype = "Propertytype",
  /** column name */
  Rateablevalue = "Rateablevalue",
  /** column name */
  Rnidcustomer = "Rnidcustomer",
  /** column name */
  Rnidowner = "Rnidowner",
  /** column name */
  Source = "Source",
  /** column name */
  Streetid = "Streetid",
  /** column name */
  Subpropertytype = "Subpropertytype",
  /** column name */
  Taxpayerassumed = "Taxpayerassumed",
  /** column name */
  Taxpayername = "Taxpayername",
  /** column name */
  Tin = "Tin",
  /** column name */
  Tinno = "Tinno",
  /** column name */
  Tradingcenter = "Tradingcenter",
  /** column name */
  Tradingname = "Tradingname",
  /** column name */
  Village = "Village",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Createddate?: Maybe<Scalars["timestamptz"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  District?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Entitylegalname?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["bigint"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Ownerfirstname?: Maybe<Scalars["String"]>;
  Ownerlegalsurnamemaiden?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Propertyrnid?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Rnidowner?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytype?: Maybe<Scalars["String"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Taxpayername?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Stddev_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceDetailReport_stddev_fields";
    Currentrateablevalue?: Maybe<Scalars["Float"]>;
    Customermatchscore?: Maybe<Scalars["Float"]>;
    Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
    Grossvalue?: Maybe<Scalars["Float"]>;
    Kccacustomerid?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Rateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Stddev_Order_By =
  {
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Kccacustomerid?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
  };

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Stddev_Pop_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceDetailReport_stddev_pop_fields";
    Currentrateablevalue?: Maybe<Scalars["Float"]>;
    Customermatchscore?: Maybe<Scalars["Float"]>;
    Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
    Grossvalue?: Maybe<Scalars["Float"]>;
    Kccacustomerid?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Rateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Stddev_Pop_Order_By =
  {
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Kccacustomerid?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
  };

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Stddev_Samp_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceDetailReport_stddev_samp_fields";
    Currentrateablevalue?: Maybe<Scalars["Float"]>;
    Customermatchscore?: Maybe<Scalars["Float"]>;
    Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
    Grossvalue?: Maybe<Scalars["Float"]>;
    Kccacustomerid?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Rateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Stddev_Samp_Order_By =
  {
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Kccacustomerid?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceDetailReport_sum_fields";
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Kccacustomerid?: Maybe<Scalars["bigint"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Sum_Order_By =
  {
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Kccacustomerid?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
  };

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Var_Pop_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceDetailReport_var_pop_fields";
    Currentrateablevalue?: Maybe<Scalars["Float"]>;
    Customermatchscore?: Maybe<Scalars["Float"]>;
    Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
    Grossvalue?: Maybe<Scalars["Float"]>;
    Kccacustomerid?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Rateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Var_Pop_Order_By =
  {
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Kccacustomerid?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
  };

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Var_Samp_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceDetailReport_var_samp_fields";
    Currentrateablevalue?: Maybe<Scalars["Float"]>;
    Customermatchscore?: Maybe<Scalars["Float"]>;
    Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
    Grossvalue?: Maybe<Scalars["Float"]>;
    Kccacustomerid?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Rateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Var_Samp_Order_By =
  {
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Kccacustomerid?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
  };

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Variance_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceDetailReport_variance_fields";
    Currentrateablevalue?: Maybe<Scalars["Float"]>;
    Customermatchscore?: Maybe<Scalars["Float"]>;
    Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
    Grossvalue?: Maybe<Scalars["Float"]>;
    Kccacustomerid?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Rateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by variance() on columns of table "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Variance_Order_By =
  {
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Kccacustomerid?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
  };

/** columns and relationships of "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Returntype?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Aggregate_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport_aggregate_fields";
    avg?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Avg_Fields>;
    count?: Maybe<Scalars["Int"]>;
    max?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Max_Fields>;
    min?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Min_Fields>;
    stddev?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Stddev_Fields>;
    stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Stddev_Samp_Fields>;
    sum?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Sum_Fields>;
    var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Var_Pop_Fields>;
    var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Var_Samp_Fields>;
    variance?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Variance_Fields>;
  };

/** aggregate fields of "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Aggregate_Order_By =
  {
    avg?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Max_Order_By>;
    min?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Min_Order_By>;
    stddev?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Stddev_Order_By>;
    stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Stddev_Samp_Order_By>;
    sum?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Sum_Order_By>;
    var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Var_Pop_Order_By>;
    var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Var_Samp_Order_By>;
    variance?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Variance_Order_By>;
  };

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Avg_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport_avg_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Rentincincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumofadjustedincome?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by avg() on columns of table "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Avg_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
  };

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Bool_Exp = {
  Averagerateperrentedproperty?: Maybe<Float8_Comparison_Exp>;
  Compliancecategory?: Maybe<String_Comparison_Exp>;
  Difference?: Maybe<Float8_Comparison_Exp>;
  Emailid?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Formerbusinessname?: Maybe<String_Comparison_Exp>;
  Grossrentalincome?: Maybe<Float8_Comparison_Exp>;
  Holdingcompname?: Maybe<String_Comparison_Exp>;
  Holdingcomptin?: Maybe<String_Comparison_Exp>;
  Isamendment?: Maybe<String_Comparison_Exp>;
  Isincomesrcbsns?: Maybe<String_Comparison_Exp>;
  Isincomesrcprpty?: Maybe<String_Comparison_Exp>;
  Isincomesrcrental?: Maybe<String_Comparison_Exp>;
  Kccacustomerid?: Maybe<String_Comparison_Exp>;
  Kccaname?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Matchscore?: Maybe<Float8_Comparison_Exp>;
  Mobilenumber?: Maybe<String_Comparison_Exp>;
  Numproperties?: Maybe<Float8_Comparison_Exp>;
  Numrentedproperties?: Maybe<Float8_Comparison_Exp>;
  Orgsubtype?: Maybe<String_Comparison_Exp>;
  Orgtype?: Maybe<String_Comparison_Exp>;
  Percentagedifference?: Maybe<Float8_Comparison_Exp>;
  Rentincincome?: Maybe<Float8_Comparison_Exp>;
  Returntype?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Rtnno?: Maybe<String_Comparison_Exp>;
  Rtnperiodyear?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  Sumgrossvalue?: Maybe<Float8_Comparison_Exp>;
  Sumofadjustedincome?: Maybe<Float8_Comparison_Exp>;
  Sumrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Taxpayerassumed?: Maybe<String_Comparison_Exp>;
  Tinno?: Maybe<String_Comparison_Exp>;
  Tradingcenter?: Maybe<String_Comparison_Exp>;
  Tradingname?: Maybe<String_Comparison_Exp>;
  Uraname?: Maybe<String_Comparison_Exp>;
  Uratinno?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<
      Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Bool_Exp>
    >
  >;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Bool_Exp>;
  _or?: Maybe<
    Array<
      Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Bool_Exp>
    >
  >;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Inc_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Insert_Input =
  {
    Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
    Compliancecategory?: Maybe<Scalars["String"]>;
    Difference?: Maybe<Scalars["float8"]>;
    Emailid?: Maybe<Scalars["String"]>;
    File?: Maybe<Scalars["String"]>;
    Formerbusinessname?: Maybe<Scalars["String"]>;
    Grossrentalincome?: Maybe<Scalars["float8"]>;
    Holdingcompname?: Maybe<Scalars["String"]>;
    Holdingcomptin?: Maybe<Scalars["String"]>;
    Isamendment?: Maybe<Scalars["String"]>;
    Isincomesrcbsns?: Maybe<Scalars["String"]>;
    Isincomesrcprpty?: Maybe<Scalars["String"]>;
    Isincomesrcrental?: Maybe<Scalars["String"]>;
    Kccacustomerid?: Maybe<Scalars["String"]>;
    Kccaname?: Maybe<Scalars["String"]>;
    LastUpdated?: Maybe<Scalars["timestamptz"]>;
    Line?: Maybe<Scalars["bigint"]>;
    Matchscore?: Maybe<Scalars["float8"]>;
    Mobilenumber?: Maybe<Scalars["String"]>;
    Numproperties?: Maybe<Scalars["float8"]>;
    Numrentedproperties?: Maybe<Scalars["float8"]>;
    Orgsubtype?: Maybe<Scalars["String"]>;
    Orgtype?: Maybe<Scalars["String"]>;
    Percentagedifference?: Maybe<Scalars["float8"]>;
    Rentincincome?: Maybe<Scalars["float8"]>;
    Returntype?: Maybe<Scalars["String"]>;
    Rnid?: Maybe<Scalars["String"]>;
    Rtnno?: Maybe<Scalars["String"]>;
    Rtnperiodyear?: Maybe<Scalars["String"]>;
    Source?: Maybe<Scalars["String"]>;
    Sumgrossvalue?: Maybe<Scalars["float8"]>;
    Sumofadjustedincome?: Maybe<Scalars["float8"]>;
    Sumrateablevalue?: Maybe<Scalars["float8"]>;
    Taxpayerassumed?: Maybe<Scalars["String"]>;
    Tinno?: Maybe<Scalars["String"]>;
    Tradingcenter?: Maybe<Scalars["String"]>;
    Tradingname?: Maybe<Scalars["String"]>;
    Uraname?: Maybe<Scalars["String"]>;
    Uratinno?: Maybe<Scalars["String"]>;
    WhoUpdated?: Maybe<Scalars["String"]>;
  };

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Max_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport_max_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
    Compliancecategory?: Maybe<Scalars["String"]>;
    Difference?: Maybe<Scalars["float8"]>;
    Emailid?: Maybe<Scalars["String"]>;
    File?: Maybe<Scalars["String"]>;
    Formerbusinessname?: Maybe<Scalars["String"]>;
    Grossrentalincome?: Maybe<Scalars["float8"]>;
    Holdingcompname?: Maybe<Scalars["String"]>;
    Holdingcomptin?: Maybe<Scalars["String"]>;
    Isamendment?: Maybe<Scalars["String"]>;
    Isincomesrcbsns?: Maybe<Scalars["String"]>;
    Isincomesrcprpty?: Maybe<Scalars["String"]>;
    Isincomesrcrental?: Maybe<Scalars["String"]>;
    Kccacustomerid?: Maybe<Scalars["String"]>;
    Kccaname?: Maybe<Scalars["String"]>;
    LastUpdated?: Maybe<Scalars["timestamptz"]>;
    Line?: Maybe<Scalars["bigint"]>;
    Matchscore?: Maybe<Scalars["float8"]>;
    Mobilenumber?: Maybe<Scalars["String"]>;
    Numproperties?: Maybe<Scalars["float8"]>;
    Numrentedproperties?: Maybe<Scalars["float8"]>;
    Orgsubtype?: Maybe<Scalars["String"]>;
    Orgtype?: Maybe<Scalars["String"]>;
    Percentagedifference?: Maybe<Scalars["float8"]>;
    Rentincincome?: Maybe<Scalars["float8"]>;
    Returntype?: Maybe<Scalars["String"]>;
    Rnid?: Maybe<Scalars["String"]>;
    Rtnno?: Maybe<Scalars["String"]>;
    Rtnperiodyear?: Maybe<Scalars["String"]>;
    Source?: Maybe<Scalars["String"]>;
    Sumgrossvalue?: Maybe<Scalars["float8"]>;
    Sumofadjustedincome?: Maybe<Scalars["float8"]>;
    Sumrateablevalue?: Maybe<Scalars["float8"]>;
    Taxpayerassumed?: Maybe<Scalars["String"]>;
    Tinno?: Maybe<Scalars["String"]>;
    Tradingcenter?: Maybe<Scalars["String"]>;
    Tradingname?: Maybe<Scalars["String"]>;
    Uraname?: Maybe<Scalars["String"]>;
    Uratinno?: Maybe<Scalars["String"]>;
    WhoUpdated?: Maybe<Scalars["String"]>;
  };

/** order by max() on columns of table "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Max_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Compliancecategory?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Emailid?: Maybe<Order_By>;
    File?: Maybe<Order_By>;
    Formerbusinessname?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Holdingcompname?: Maybe<Order_By>;
    Holdingcomptin?: Maybe<Order_By>;
    Isamendment?: Maybe<Order_By>;
    Isincomesrcbsns?: Maybe<Order_By>;
    Isincomesrcprpty?: Maybe<Order_By>;
    Isincomesrcrental?: Maybe<Order_By>;
    Kccacustomerid?: Maybe<Order_By>;
    Kccaname?: Maybe<Order_By>;
    LastUpdated?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Orgsubtype?: Maybe<Order_By>;
    Orgtype?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    Returntype?: Maybe<Order_By>;
    Rnid?: Maybe<Order_By>;
    Rtnno?: Maybe<Order_By>;
    Rtnperiodyear?: Maybe<Order_By>;
    Source?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Taxpayerassumed?: Maybe<Order_By>;
    Tinno?: Maybe<Order_By>;
    Tradingcenter?: Maybe<Order_By>;
    Tradingname?: Maybe<Order_By>;
    Uraname?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
    WhoUpdated?: Maybe<Order_By>;
  };

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Min_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport_min_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
    Compliancecategory?: Maybe<Scalars["String"]>;
    Difference?: Maybe<Scalars["float8"]>;
    Emailid?: Maybe<Scalars["String"]>;
    File?: Maybe<Scalars["String"]>;
    Formerbusinessname?: Maybe<Scalars["String"]>;
    Grossrentalincome?: Maybe<Scalars["float8"]>;
    Holdingcompname?: Maybe<Scalars["String"]>;
    Holdingcomptin?: Maybe<Scalars["String"]>;
    Isamendment?: Maybe<Scalars["String"]>;
    Isincomesrcbsns?: Maybe<Scalars["String"]>;
    Isincomesrcprpty?: Maybe<Scalars["String"]>;
    Isincomesrcrental?: Maybe<Scalars["String"]>;
    Kccacustomerid?: Maybe<Scalars["String"]>;
    Kccaname?: Maybe<Scalars["String"]>;
    LastUpdated?: Maybe<Scalars["timestamptz"]>;
    Line?: Maybe<Scalars["bigint"]>;
    Matchscore?: Maybe<Scalars["float8"]>;
    Mobilenumber?: Maybe<Scalars["String"]>;
    Numproperties?: Maybe<Scalars["float8"]>;
    Numrentedproperties?: Maybe<Scalars["float8"]>;
    Orgsubtype?: Maybe<Scalars["String"]>;
    Orgtype?: Maybe<Scalars["String"]>;
    Percentagedifference?: Maybe<Scalars["float8"]>;
    Rentincincome?: Maybe<Scalars["float8"]>;
    Returntype?: Maybe<Scalars["String"]>;
    Rnid?: Maybe<Scalars["String"]>;
    Rtnno?: Maybe<Scalars["String"]>;
    Rtnperiodyear?: Maybe<Scalars["String"]>;
    Source?: Maybe<Scalars["String"]>;
    Sumgrossvalue?: Maybe<Scalars["float8"]>;
    Sumofadjustedincome?: Maybe<Scalars["float8"]>;
    Sumrateablevalue?: Maybe<Scalars["float8"]>;
    Taxpayerassumed?: Maybe<Scalars["String"]>;
    Tinno?: Maybe<Scalars["String"]>;
    Tradingcenter?: Maybe<Scalars["String"]>;
    Tradingname?: Maybe<Scalars["String"]>;
    Uraname?: Maybe<Scalars["String"]>;
    Uratinno?: Maybe<Scalars["String"]>;
    WhoUpdated?: Maybe<Scalars["String"]>;
  };

/** order by min() on columns of table "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Min_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Compliancecategory?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Emailid?: Maybe<Order_By>;
    File?: Maybe<Order_By>;
    Formerbusinessname?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Holdingcompname?: Maybe<Order_By>;
    Holdingcomptin?: Maybe<Order_By>;
    Isamendment?: Maybe<Order_By>;
    Isincomesrcbsns?: Maybe<Order_By>;
    Isincomesrcprpty?: Maybe<Order_By>;
    Isincomesrcrental?: Maybe<Order_By>;
    Kccacustomerid?: Maybe<Order_By>;
    Kccaname?: Maybe<Order_By>;
    LastUpdated?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Orgsubtype?: Maybe<Order_By>;
    Orgtype?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    Returntype?: Maybe<Order_By>;
    Rnid?: Maybe<Order_By>;
    Rtnno?: Maybe<Order_By>;
    Rtnperiodyear?: Maybe<Order_By>;
    Source?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Taxpayerassumed?: Maybe<Order_By>;
    Tinno?: Maybe<Order_By>;
    Tradingcenter?: Maybe<Order_By>;
    Tradingname?: Maybe<Order_By>;
    Uraname?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
    WhoUpdated?: Maybe<Order_By>;
  };

/** response of any mutation on the table "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Mutation_Response =
  {
    __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport_mutation_response";
    /** number of affected rows by the mutation */
    affected_rows: Scalars["Int"];
    /** data of the affected rows by the mutation */
    returning: Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport>;
  };

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Formerbusinessname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Holdingcompname?: Maybe<Order_By>;
  Holdingcomptin?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Kccaname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Orgsubtype?: Maybe<Order_By>;
  Orgtype?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Returntype?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Taxpayerassumed?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Tradingcenter?: Maybe<Order_By>;
  Tradingname?: Maybe<Order_By>;
  Uraname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
export enum Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Select_Column {
  /** column name */
  Averagerateperrentedproperty = "Averagerateperrentedproperty",
  /** column name */
  Compliancecategory = "Compliancecategory",
  /** column name */
  Difference = "Difference",
  /** column name */
  Emailid = "Emailid",
  /** column name */
  File = "File",
  /** column name */
  Formerbusinessname = "Formerbusinessname",
  /** column name */
  Grossrentalincome = "Grossrentalincome",
  /** column name */
  Holdingcompname = "Holdingcompname",
  /** column name */
  Holdingcomptin = "Holdingcomptin",
  /** column name */
  Isamendment = "Isamendment",
  /** column name */
  Isincomesrcbsns = "Isincomesrcbsns",
  /** column name */
  Isincomesrcprpty = "Isincomesrcprpty",
  /** column name */
  Isincomesrcrental = "Isincomesrcrental",
  /** column name */
  Kccacustomerid = "Kccacustomerid",
  /** column name */
  Kccaname = "Kccaname",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Mobilenumber = "Mobilenumber",
  /** column name */
  Numproperties = "Numproperties",
  /** column name */
  Numrentedproperties = "Numrentedproperties",
  /** column name */
  Orgsubtype = "Orgsubtype",
  /** column name */
  Orgtype = "Orgtype",
  /** column name */
  Percentagedifference = "Percentagedifference",
  /** column name */
  Rentincincome = "Rentincincome",
  /** column name */
  Returntype = "Returntype",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Rtnno = "Rtnno",
  /** column name */
  Rtnperiodyear = "Rtnperiodyear",
  /** column name */
  Source = "Source",
  /** column name */
  Sumgrossvalue = "Sumgrossvalue",
  /** column name */
  Sumofadjustedincome = "Sumofadjustedincome",
  /** column name */
  Sumrateablevalue = "Sumrateablevalue",
  /** column name */
  Taxpayerassumed = "Taxpayerassumed",
  /** column name */
  Tinno = "Tinno",
  /** column name */
  Tradingcenter = "Tradingcenter",
  /** column name */
  Tradingname = "Tradingname",
  /** column name */
  Uraname = "Uraname",
  /** column name */
  Uratinno = "Uratinno",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Set_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Returntype?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Stddev_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport_stddev_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Rentincincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumofadjustedincome?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Stddev_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
  };

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Stddev_Pop_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport_stddev_pop_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Rentincincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumofadjustedincome?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Stddev_Pop_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
  };

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Stddev_Samp_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport_stddev_samp_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Rentincincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumofadjustedincome?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Stddev_Samp_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Sum_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport_sum_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
    Difference?: Maybe<Scalars["float8"]>;
    Grossrentalincome?: Maybe<Scalars["float8"]>;
    Line?: Maybe<Scalars["bigint"]>;
    Matchscore?: Maybe<Scalars["float8"]>;
    Numproperties?: Maybe<Scalars["float8"]>;
    Numrentedproperties?: Maybe<Scalars["float8"]>;
    Percentagedifference?: Maybe<Scalars["float8"]>;
    Rentincincome?: Maybe<Scalars["float8"]>;
    Sumgrossvalue?: Maybe<Scalars["float8"]>;
    Sumofadjustedincome?: Maybe<Scalars["float8"]>;
    Sumrateablevalue?: Maybe<Scalars["float8"]>;
  };

/** order by sum() on columns of table "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Sum_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
  };

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Var_Pop_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport_var_pop_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Rentincincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumofadjustedincome?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Var_Pop_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
  };

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Var_Samp_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport_var_samp_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Rentincincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumofadjustedincome?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Var_Samp_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
  };

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Variance_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport_variance_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Rentincincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumofadjustedincome?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by variance() on columns of table "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Variance_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
  };

/** columns and relationships of "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualRiskScore";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["float8"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentiledifferencezscore?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Riskcode?: Maybe<Scalars["String"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualRiskScore_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualRiskScore_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualRiskScore_avg_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentiledifferencezscore?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumofadjustedincome?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Avg_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_NonIndividualRiskScore". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Bool_Exp = {
  Averagerateperrentedproperty?: Maybe<Float8_Comparison_Exp>;
  Avgadjustedgrossincomeperproperty?: Maybe<Float8_Comparison_Exp>;
  Compliancecategory?: Maybe<String_Comparison_Exp>;
  Difference?: Maybe<Float8_Comparison_Exp>;
  Emailid?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Formerbusinessname?: Maybe<String_Comparison_Exp>;
  Grossrentalincome?: Maybe<Float8_Comparison_Exp>;
  Holdingcompname?: Maybe<String_Comparison_Exp>;
  Holdingcomptin?: Maybe<String_Comparison_Exp>;
  Isamendment?: Maybe<String_Comparison_Exp>;
  Isincomesrcbsns?: Maybe<String_Comparison_Exp>;
  Isincomesrcprpty?: Maybe<String_Comparison_Exp>;
  Isincomesrcrental?: Maybe<String_Comparison_Exp>;
  Kccacustomerid?: Maybe<String_Comparison_Exp>;
  Kccaname?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Matchscore?: Maybe<Float8_Comparison_Exp>;
  Mobilenumber?: Maybe<String_Comparison_Exp>;
  Numproperties?: Maybe<Float8_Comparison_Exp>;
  Numrentedproperties?: Maybe<Float8_Comparison_Exp>;
  Orgsubtype?: Maybe<String_Comparison_Exp>;
  Orgtype?: Maybe<String_Comparison_Exp>;
  Percentagedifference?: Maybe<Float8_Comparison_Exp>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Float8_Comparison_Exp>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Float8_Comparison_Exp>;
  Percentileavgperproperty?: Maybe<Float8_Comparison_Exp>;
  Percentileavgperpropertyzscore?: Maybe<Float8_Comparison_Exp>;
  Percentiledifference?: Maybe<Bigint_Comparison_Exp>;
  Percentiledifferenceavg?: Maybe<Float8_Comparison_Exp>;
  Percentiledifferenceavg1?: Maybe<Float8_Comparison_Exp>;
  Percentiledifferencestandarddeviation?: Maybe<Float8_Comparison_Exp>;
  Percentiledifferencezscore?: Maybe<Float8_Comparison_Exp>;
  Percentilenumrentedpropertiesavg?: Maybe<Float8_Comparison_Exp>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Float8_Comparison_Exp>;
  Percentilepropertyrented?: Maybe<Bigint_Comparison_Exp>;
  Percentilepropertyrentedzscore?: Maybe<Float8_Comparison_Exp>;
  Rentincincome?: Maybe<Float8_Comparison_Exp>;
  Riskcode?: Maybe<String_Comparison_Exp>;
  Riskscore?: Maybe<Bigint_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Rtnno?: Maybe<String_Comparison_Exp>;
  Rtnperiodyear?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  Sumgrossvalue?: Maybe<Float8_Comparison_Exp>;
  Sumofadjustedincome?: Maybe<Float8_Comparison_Exp>;
  Sumrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Taxpayerassumed?: Maybe<String_Comparison_Exp>;
  Tinno?: Maybe<String_Comparison_Exp>;
  Tradingcenter?: Maybe<String_Comparison_Exp>;
  Tradingname?: Maybe<String_Comparison_Exp>;
  Uraname?: Maybe<String_Comparison_Exp>;
  Uratinno?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Inc_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["float8"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentiledifferencezscore?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Insert_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["float8"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentiledifferencezscore?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Riskcode?: Maybe<Scalars["String"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualRiskScore_max_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["float8"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentiledifferencezscore?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Riskcode?: Maybe<Scalars["String"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Max_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Formerbusinessname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Holdingcompname?: Maybe<Order_By>;
  Holdingcomptin?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Kccaname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Orgsubtype?: Maybe<Order_By>;
  Orgtype?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Riskcode?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Taxpayerassumed?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Tradingcenter?: Maybe<Order_By>;
  Tradingname?: Maybe<Order_By>;
  Uraname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualRiskScore_min_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["float8"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentiledifferencezscore?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Riskcode?: Maybe<Scalars["String"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Min_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Formerbusinessname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Holdingcompname?: Maybe<Order_By>;
  Holdingcomptin?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Kccaname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Orgsubtype?: Maybe<Order_By>;
  Orgtype?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Riskcode?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Taxpayerassumed?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Tradingcenter?: Maybe<Order_By>;
  Tradingname?: Maybe<Order_By>;
  Uraname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualRiskScore_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Formerbusinessname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Holdingcompname?: Maybe<Order_By>;
  Holdingcomptin?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Kccaname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Orgsubtype?: Maybe<Order_By>;
  Orgtype?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Riskcode?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Taxpayerassumed?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Tradingcenter?: Maybe<Order_By>;
  Tradingname?: Maybe<Order_By>;
  Uraname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
export enum Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Select_Column {
  /** column name */
  Averagerateperrentedproperty = "Averagerateperrentedproperty",
  /** column name */
  Avgadjustedgrossincomeperproperty = "Avgadjustedgrossincomeperproperty",
  /** column name */
  Compliancecategory = "Compliancecategory",
  /** column name */
  Difference = "Difference",
  /** column name */
  Emailid = "Emailid",
  /** column name */
  File = "File",
  /** column name */
  Formerbusinessname = "Formerbusinessname",
  /** column name */
  Grossrentalincome = "Grossrentalincome",
  /** column name */
  Holdingcompname = "Holdingcompname",
  /** column name */
  Holdingcomptin = "Holdingcomptin",
  /** column name */
  Isamendment = "Isamendment",
  /** column name */
  Isincomesrcbsns = "Isincomesrcbsns",
  /** column name */
  Isincomesrcprpty = "Isincomesrcprpty",
  /** column name */
  Isincomesrcrental = "Isincomesrcrental",
  /** column name */
  Kccacustomerid = "Kccacustomerid",
  /** column name */
  Kccaname = "Kccaname",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Mobilenumber = "Mobilenumber",
  /** column name */
  Numproperties = "Numproperties",
  /** column name */
  Numrentedproperties = "Numrentedproperties",
  /** column name */
  Orgsubtype = "Orgsubtype",
  /** column name */
  Orgtype = "Orgtype",
  /** column name */
  Percentagedifference = "Percentagedifference",
  /** column name */
  Percentileavgadjustedgrossincomeperpropertyavg = "Percentileavgadjustedgrossincomeperpropertyavg",
  /** column name */
  Percentileavgadjustedgrossincomeperpropertystandarddeviation = "Percentileavgadjustedgrossincomeperpropertystandarddeviation",
  /** column name */
  Percentileavgperproperty = "Percentileavgperproperty",
  /** column name */
  Percentileavgperpropertyzscore = "Percentileavgperpropertyzscore",
  /** column name */
  Percentiledifference = "Percentiledifference",
  /** column name */
  Percentiledifferenceavg = "Percentiledifferenceavg",
  /** column name */
  Percentiledifferenceavg1 = "Percentiledifferenceavg1",
  /** column name */
  Percentiledifferencestandarddeviation = "Percentiledifferencestandarddeviation",
  /** column name */
  Percentiledifferencezscore = "Percentiledifferencezscore",
  /** column name */
  Percentilenumrentedpropertiesavg = "Percentilenumrentedpropertiesavg",
  /** column name */
  Percentilenumrentedpropertiesstandarddeviation = "Percentilenumrentedpropertiesstandarddeviation",
  /** column name */
  Percentilepropertyrented = "Percentilepropertyrented",
  /** column name */
  Percentilepropertyrentedzscore = "Percentilepropertyrentedzscore",
  /** column name */
  Rentincincome = "Rentincincome",
  /** column name */
  Riskcode = "Riskcode",
  /** column name */
  Riskscore = "Riskscore",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Rtnno = "Rtnno",
  /** column name */
  Rtnperiodyear = "Rtnperiodyear",
  /** column name */
  Source = "Source",
  /** column name */
  Sumgrossvalue = "Sumgrossvalue",
  /** column name */
  Sumofadjustedincome = "Sumofadjustedincome",
  /** column name */
  Sumrateablevalue = "Sumrateablevalue",
  /** column name */
  Taxpayerassumed = "Taxpayerassumed",
  /** column name */
  Tinno = "Tinno",
  /** column name */
  Tradingcenter = "Tradingcenter",
  /** column name */
  Tradingname = "Tradingname",
  /** column name */
  Uraname = "Uraname",
  /** column name */
  Uratinno = "Uratinno",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Set_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["float8"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentiledifferencezscore?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Riskcode?: Maybe<Scalars["String"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualRiskScore_stddev_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentiledifferencezscore?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumofadjustedincome?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Stddev_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualRiskScore_stddev_pop_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentiledifferencezscore?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumofadjustedincome?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Stddev_Pop_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualRiskScore_stddev_samp_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentiledifferencezscore?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumofadjustedincome?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Stddev_Samp_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualRiskScore_sum_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["float8"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentiledifferencezscore?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Sum_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualRiskScore_var_pop_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentiledifferencezscore?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumofadjustedincome?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Var_Pop_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualRiskScore_var_samp_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentiledifferencezscore?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumofadjustedincome?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Var_Samp_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualRiskScore_variance_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentiledifferencezscore?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumofadjustedincome?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Variance_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_NonIndividual" */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividual_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_NonIndividual>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_NonIndividual" */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividual_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_NonIndividual" */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividual_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rmatch_NonIndividual" */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_NonIndividual" */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_NonIndividual_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividual_avg_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_NonIndividual" */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Avg_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_NonIndividual". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Bool_Exp = {
  AdditionalOwners?: Maybe<Float8_Comparison_Exp>;
  AdditionalProperties?: Maybe<Float8_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  KccaName?: Maybe<String_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Float8_Comparison_Exp>;
  NumProperties?: Maybe<Bigint_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  UraName?: Maybe<String_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_NonIndividual" */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Inc_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_NonIndividual" */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Insert_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividual_max_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_NonIndividual" */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Max_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  KccaName?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  UraName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividual_min_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_NonIndividual" */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Min_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  KccaName?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  UraName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_NonIndividual" */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividual_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_NonIndividual>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_NonIndividual" */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_NonIndividual_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_NonIndividual" */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  KccaName?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  UraName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_NonIndividual" */
export enum Rtcs_Db_Ph_Rmatch_NonIndividual_Select_Column {
  /** column name */
  AdditionalOwners = "AdditionalOwners",
  /** column name */
  AdditionalProperties = "AdditionalProperties",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  Gml = "Gml",
  /** column name */
  KccaName = "KccaName",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Source = "Source",
  /** column name */
  UraName = "UraName",
  /** column name */
  UraTinNo = "UraTinNo",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_NonIndividual" */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Set_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividual_stddev_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_NonIndividual" */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Stddev_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividual_stddev_pop_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_NonIndividual" */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Stddev_Pop_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividual_stddev_samp_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_NonIndividual" */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Stddev_Samp_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividual_sum_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_NonIndividual" */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Sum_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividual_var_pop_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_NonIndividual" */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Var_Pop_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividual_var_samp_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_NonIndividual" */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Var_Samp_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividual_variance_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_NonIndividual" */
export type Rtcs_Db_Ph_Rmatch_NonIndividual_Variance_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_NonIndividuals" */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividuals";
  Businessstartdt?: Maybe<Scalars["String"]>;
  Dateofincorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["float8"]>;
  Kccaentitylegalname?: Maybe<Scalars["String"]>;
  Landlinenumber?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastupdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchattributes?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Rmatchcategory?: Maybe<Scalars["String"]>;
  Rmatchconfidence?: Maybe<Scalars["float8"]>;
  Secondmobile?: Maybe<Scalars["float8"]>;
  Telephone?: Maybe<Scalars["float8"]>;
  Tin?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Urataxpayername?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
  Urid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Whoupdated?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualsNetwork";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualsNetwork_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualsNetwork_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualsNetwork_avg_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Avg_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_NonIndividualsNetwork". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Bool_Exp = {
  AdditionalOwners?: Maybe<Float8_Comparison_Exp>;
  AdditionalProperties?: Maybe<Float8_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  KccaName?: Maybe<String_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Float8_Comparison_Exp>;
  NumProperties?: Maybe<Bigint_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  UraName?: Maybe<String_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Inc_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Insert_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualsNetwork_max_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Max_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  KccaName?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  UraName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualsNetwork_min_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Min_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  KccaName?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  UraName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualsNetwork_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  KccaName?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  UraName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
export enum Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Select_Column {
  /** column name */
  AdditionalOwners = "AdditionalOwners",
  /** column name */
  AdditionalProperties = "AdditionalProperties",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  Gml = "Gml",
  /** column name */
  KccaName = "KccaName",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Source = "Source",
  /** column name */
  UraName = "UraName",
  /** column name */
  UraTinNo = "UraTinNo",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Set_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualsNetwork_stddev_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Stddev_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualsNetwork_stddev_pop_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Stddev_Pop_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualsNetwork_stddev_samp_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Stddev_Samp_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualsNetwork_sum_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Sum_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualsNetwork_var_pop_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Var_Pop_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualsNetwork_var_samp_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Var_Samp_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualsNetwork_variance_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Variance_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_NonIndividuals" */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividuals_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_NonIndividuals>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_NonIndividuals" */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividuals_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_NonIndividuals" */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividuals_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rmatch_NonIndividuals" */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_NonIndividuals" */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_NonIndividuals_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividuals_avg_fields";
  Kccacustomerid?: Maybe<Scalars["Float"]>;
  Landlinenumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Mobile?: Maybe<Scalars["Float"]>;
  Mobilenumber?: Maybe<Scalars["Float"]>;
  Rmatchconfidence?: Maybe<Scalars["Float"]>;
  Secondmobile?: Maybe<Scalars["Float"]>;
  Telephone?: Maybe<Scalars["Float"]>;
  Tinno?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_NonIndividuals" */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Avg_Order_By = {
  Kccacustomerid?: Maybe<Order_By>;
  Landlinenumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Rmatchconfidence?: Maybe<Order_By>;
  Secondmobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_NonIndividuals". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Bool_Exp = {
  Businessstartdt?: Maybe<String_Comparison_Exp>;
  Dateofincorporation?: Maybe<String_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  Emailid?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Kccacustomerid?: Maybe<Float8_Comparison_Exp>;
  Kccaentitylegalname?: Maybe<String_Comparison_Exp>;
  Landlinenumber?: Maybe<Float8_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Lastupdated?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Matchattributes?: Maybe<String_Comparison_Exp>;
  Mobile?: Maybe<Float8_Comparison_Exp>;
  Mobilenumber?: Maybe<Float8_Comparison_Exp>;
  Rmatchcategory?: Maybe<String_Comparison_Exp>;
  Rmatchconfidence?: Maybe<Float8_Comparison_Exp>;
  Secondmobile?: Maybe<Float8_Comparison_Exp>;
  Telephone?: Maybe<Float8_Comparison_Exp>;
  Tin?: Maybe<String_Comparison_Exp>;
  Tinno?: Maybe<Float8_Comparison_Exp>;
  Urataxpayername?: Maybe<String_Comparison_Exp>;
  Uratinno?: Maybe<Float8_Comparison_Exp>;
  Urid?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  Whoupdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_NonIndividuals" */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Inc_Input = {
  Kccacustomerid?: Maybe<Scalars["float8"]>;
  Landlinenumber?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Rmatchconfidence?: Maybe<Scalars["float8"]>;
  Secondmobile?: Maybe<Scalars["float8"]>;
  Telephone?: Maybe<Scalars["float8"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_NonIndividuals" */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Insert_Input = {
  Businessstartdt?: Maybe<Scalars["String"]>;
  Dateofincorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["float8"]>;
  Kccaentitylegalname?: Maybe<Scalars["String"]>;
  Landlinenumber?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastupdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchattributes?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Rmatchcategory?: Maybe<Scalars["String"]>;
  Rmatchconfidence?: Maybe<Scalars["float8"]>;
  Secondmobile?: Maybe<Scalars["float8"]>;
  Telephone?: Maybe<Scalars["float8"]>;
  Tin?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Urataxpayername?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
  Urid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Whoupdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividuals_max_fields";
  Businessstartdt?: Maybe<Scalars["String"]>;
  Dateofincorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["float8"]>;
  Kccaentitylegalname?: Maybe<Scalars["String"]>;
  Landlinenumber?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastupdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchattributes?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Rmatchcategory?: Maybe<Scalars["String"]>;
  Rmatchconfidence?: Maybe<Scalars["float8"]>;
  Secondmobile?: Maybe<Scalars["float8"]>;
  Telephone?: Maybe<Scalars["float8"]>;
  Tin?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Urataxpayername?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
  Urid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Whoupdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_NonIndividuals" */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Max_Order_By = {
  Businessstartdt?: Maybe<Order_By>;
  Dateofincorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Kccaentitylegalname?: Maybe<Order_By>;
  Landlinenumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastupdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchattributes?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Rmatchcategory?: Maybe<Order_By>;
  Rmatchconfidence?: Maybe<Order_By>;
  Secondmobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Urataxpayername?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  Urid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  Whoupdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividuals_min_fields";
  Businessstartdt?: Maybe<Scalars["String"]>;
  Dateofincorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["float8"]>;
  Kccaentitylegalname?: Maybe<Scalars["String"]>;
  Landlinenumber?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastupdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchattributes?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Rmatchcategory?: Maybe<Scalars["String"]>;
  Rmatchconfidence?: Maybe<Scalars["float8"]>;
  Secondmobile?: Maybe<Scalars["float8"]>;
  Telephone?: Maybe<Scalars["float8"]>;
  Tin?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Urataxpayername?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
  Urid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Whoupdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_NonIndividuals" */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Min_Order_By = {
  Businessstartdt?: Maybe<Order_By>;
  Dateofincorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Kccaentitylegalname?: Maybe<Order_By>;
  Landlinenumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastupdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchattributes?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Rmatchcategory?: Maybe<Order_By>;
  Rmatchconfidence?: Maybe<Order_By>;
  Secondmobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Urataxpayername?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  Urid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  Whoupdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_NonIndividuals" */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividuals_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_NonIndividuals>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_NonIndividuals" */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_NonIndividuals_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_NonIndividuals" */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Order_By = {
  Businessstartdt?: Maybe<Order_By>;
  Dateofincorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Kccaentitylegalname?: Maybe<Order_By>;
  Landlinenumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastupdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchattributes?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Rmatchcategory?: Maybe<Order_By>;
  Rmatchconfidence?: Maybe<Order_By>;
  Secondmobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Urataxpayername?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  Urid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  Whoupdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_NonIndividuals" */
export enum Rtcs_Db_Ph_Rmatch_NonIndividuals_Select_Column {
  /** column name */
  Businessstartdt = "Businessstartdt",
  /** column name */
  Dateofincorporation = "Dateofincorporation",
  /** column name */
  Email = "Email",
  /** column name */
  Emailid = "Emailid",
  /** column name */
  File = "File",
  /** column name */
  Kccacustomerid = "Kccacustomerid",
  /** column name */
  Kccaentitylegalname = "Kccaentitylegalname",
  /** column name */
  Landlinenumber = "Landlinenumber",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Lastupdated = "Lastupdated",
  /** column name */
  Line = "Line",
  /** column name */
  Matchattributes = "Matchattributes",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  Mobilenumber = "Mobilenumber",
  /** column name */
  Rmatchcategory = "Rmatchcategory",
  /** column name */
  Rmatchconfidence = "Rmatchconfidence",
  /** column name */
  Secondmobile = "Secondmobile",
  /** column name */
  Telephone = "Telephone",
  /** column name */
  Tin = "Tin",
  /** column name */
  Tinno = "Tinno",
  /** column name */
  Urataxpayername = "Urataxpayername",
  /** column name */
  Uratinno = "Uratinno",
  /** column name */
  Urid = "Urid",
  /** column name */
  WhoUpdated = "WhoUpdated",
  /** column name */
  Whoupdated = "Whoupdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_NonIndividuals" */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Set_Input = {
  Businessstartdt?: Maybe<Scalars["String"]>;
  Dateofincorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["float8"]>;
  Kccaentitylegalname?: Maybe<Scalars["String"]>;
  Landlinenumber?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastupdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchattributes?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Rmatchcategory?: Maybe<Scalars["String"]>;
  Rmatchconfidence?: Maybe<Scalars["float8"]>;
  Secondmobile?: Maybe<Scalars["float8"]>;
  Telephone?: Maybe<Scalars["float8"]>;
  Tin?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Urataxpayername?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
  Urid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Whoupdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividuals_stddev_fields";
  Kccacustomerid?: Maybe<Scalars["Float"]>;
  Landlinenumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Mobile?: Maybe<Scalars["Float"]>;
  Mobilenumber?: Maybe<Scalars["Float"]>;
  Rmatchconfidence?: Maybe<Scalars["Float"]>;
  Secondmobile?: Maybe<Scalars["Float"]>;
  Telephone?: Maybe<Scalars["Float"]>;
  Tinno?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_NonIndividuals" */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Stddev_Order_By = {
  Kccacustomerid?: Maybe<Order_By>;
  Landlinenumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Rmatchconfidence?: Maybe<Order_By>;
  Secondmobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividuals_stddev_pop_fields";
  Kccacustomerid?: Maybe<Scalars["Float"]>;
  Landlinenumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Mobile?: Maybe<Scalars["Float"]>;
  Mobilenumber?: Maybe<Scalars["Float"]>;
  Rmatchconfidence?: Maybe<Scalars["Float"]>;
  Secondmobile?: Maybe<Scalars["Float"]>;
  Telephone?: Maybe<Scalars["Float"]>;
  Tinno?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_NonIndividuals" */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Stddev_Pop_Order_By = {
  Kccacustomerid?: Maybe<Order_By>;
  Landlinenumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Rmatchconfidence?: Maybe<Order_By>;
  Secondmobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividuals_stddev_samp_fields";
  Kccacustomerid?: Maybe<Scalars["Float"]>;
  Landlinenumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Mobile?: Maybe<Scalars["Float"]>;
  Mobilenumber?: Maybe<Scalars["Float"]>;
  Rmatchconfidence?: Maybe<Scalars["Float"]>;
  Secondmobile?: Maybe<Scalars["Float"]>;
  Telephone?: Maybe<Scalars["Float"]>;
  Tinno?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_NonIndividuals" */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Stddev_Samp_Order_By = {
  Kccacustomerid?: Maybe<Order_By>;
  Landlinenumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Rmatchconfidence?: Maybe<Order_By>;
  Secondmobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividuals_sum_fields";
  Kccacustomerid?: Maybe<Scalars["float8"]>;
  Landlinenumber?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Rmatchconfidence?: Maybe<Scalars["float8"]>;
  Secondmobile?: Maybe<Scalars["float8"]>;
  Telephone?: Maybe<Scalars["float8"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_NonIndividuals" */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Sum_Order_By = {
  Kccacustomerid?: Maybe<Order_By>;
  Landlinenumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Rmatchconfidence?: Maybe<Order_By>;
  Secondmobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividuals_var_pop_fields";
  Kccacustomerid?: Maybe<Scalars["Float"]>;
  Landlinenumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Mobile?: Maybe<Scalars["Float"]>;
  Mobilenumber?: Maybe<Scalars["Float"]>;
  Rmatchconfidence?: Maybe<Scalars["Float"]>;
  Secondmobile?: Maybe<Scalars["Float"]>;
  Telephone?: Maybe<Scalars["Float"]>;
  Tinno?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_NonIndividuals" */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Var_Pop_Order_By = {
  Kccacustomerid?: Maybe<Order_By>;
  Landlinenumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Rmatchconfidence?: Maybe<Order_By>;
  Secondmobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividuals_var_samp_fields";
  Kccacustomerid?: Maybe<Scalars["Float"]>;
  Landlinenumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Mobile?: Maybe<Scalars["Float"]>;
  Mobilenumber?: Maybe<Scalars["Float"]>;
  Rmatchconfidence?: Maybe<Scalars["Float"]>;
  Secondmobile?: Maybe<Scalars["Float"]>;
  Telephone?: Maybe<Scalars["Float"]>;
  Tinno?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_NonIndividuals" */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Var_Samp_Order_By = {
  Kccacustomerid?: Maybe<Order_By>;
  Landlinenumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Rmatchconfidence?: Maybe<Order_By>;
  Secondmobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividuals_variance_fields";
  Kccacustomerid?: Maybe<Scalars["Float"]>;
  Landlinenumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Mobile?: Maybe<Scalars["Float"]>;
  Mobilenumber?: Maybe<Scalars["Float"]>;
  Rmatchconfidence?: Maybe<Scalars["Float"]>;
  Secondmobile?: Maybe<Scalars["Float"]>;
  Telephone?: Maybe<Scalars["Float"]>;
  Tinno?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_NonIndividuals" */
export type Rtcs_Db_Ph_Rmatch_NonIndividuals_Variance_Order_By = {
  Kccacustomerid?: Maybe<Order_By>;
  Landlinenumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Rmatchconfidence?: Maybe<Order_By>;
  Secondmobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_NonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners = {
  __typename?: "rtcs_db_PH_rmatch_NonindividualOwners";
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId1?: Maybe<Scalars["String"]>;
  KccaCustomerId2?: Maybe<Scalars["String"]>;
  KccaEntityLegalName1?: Maybe<Scalars["String"]>;
  KccaEntityLegalName2?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_NonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_NonindividualOwners_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_NonindividualOwners>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_NonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonindividualOwners_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_NonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rmatch_NonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_NonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonindividualOwners_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_NonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Avg_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_NonindividualOwners". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  FinalMatchCategory?: Maybe<String_Comparison_Exp>;
  KccaCustomerId1?: Maybe<String_Comparison_Exp>;
  KccaCustomerId2?: Maybe<String_Comparison_Exp>;
  KccaEntityLegalName1?: Maybe<String_Comparison_Exp>;
  KccaEntityLegalName2?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  RmatchScore?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_NonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_NonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId1?: Maybe<Scalars["String"]>;
  KccaCustomerId2?: Maybe<Scalars["String"]>;
  KccaEntityLegalName1?: Maybe<Scalars["String"]>;
  KccaEntityLegalName2?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonindividualOwners_max_fields";
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId1?: Maybe<Scalars["String"]>;
  KccaCustomerId2?: Maybe<Scalars["String"]>;
  KccaEntityLegalName1?: Maybe<Scalars["String"]>;
  KccaEntityLegalName2?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_NonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Max_Order_By = {
  File?: Maybe<Order_By>;
  FinalMatchCategory?: Maybe<Order_By>;
  KccaCustomerId1?: Maybe<Order_By>;
  KccaCustomerId2?: Maybe<Order_By>;
  KccaEntityLegalName1?: Maybe<Order_By>;
  KccaEntityLegalName2?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonindividualOwners_min_fields";
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId1?: Maybe<Scalars["String"]>;
  KccaCustomerId2?: Maybe<Scalars["String"]>;
  KccaEntityLegalName1?: Maybe<Scalars["String"]>;
  KccaEntityLegalName2?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_NonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Min_Order_By = {
  File?: Maybe<Order_By>;
  FinalMatchCategory?: Maybe<Order_By>;
  KccaCustomerId1?: Maybe<Order_By>;
  KccaCustomerId2?: Maybe<Order_By>;
  KccaEntityLegalName1?: Maybe<Order_By>;
  KccaEntityLegalName2?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_NonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_NonindividualOwners_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_NonindividualOwners>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_NonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_NonindividualOwners_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_NonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Order_By = {
  File?: Maybe<Order_By>;
  FinalMatchCategory?: Maybe<Order_By>;
  KccaCustomerId1?: Maybe<Order_By>;
  KccaCustomerId2?: Maybe<Order_By>;
  KccaEntityLegalName1?: Maybe<Order_By>;
  KccaEntityLegalName2?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_NonindividualOwners" */
export enum Rtcs_Db_Ph_Rmatch_NonindividualOwners_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  FinalMatchCategory = "FinalMatchCategory",
  /** column name */
  KccaCustomerId1 = "KccaCustomerId1",
  /** column name */
  KccaCustomerId2 = "KccaCustomerId2",
  /** column name */
  KccaEntityLegalName1 = "KccaEntityLegalName1",
  /** column name */
  KccaEntityLegalName2 = "KccaEntityLegalName2",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  RmatchScore = "RmatchScore",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_NonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId1?: Maybe<Scalars["String"]>;
  KccaCustomerId2?: Maybe<Scalars["String"]>;
  KccaEntityLegalName1?: Maybe<Scalars["String"]>;
  KccaEntityLegalName2?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonindividualOwners_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_NonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonindividualOwners_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_NonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonindividualOwners_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_NonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonindividualOwners_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_NonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Sum_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonindividualOwners_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_NonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonindividualOwners_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_NonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_NonindividualOwners_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_NonindividualOwners" */
export type Rtcs_Db_Ph_Rmatch_NonindividualOwners_Variance_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_Nonindividuals" */
export type Rtcs_Db_Ph_Rmatch_Nonindividuals = {
  __typename?: "rtcs_db_PH_rmatch_Nonindividuals";
  BusinessStartDt?: Maybe<Scalars["String"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  KccaCustomerId?: Maybe<Scalars["String"]>;
  KccaEntityLegalName?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["String"]>;
  MatchAttributes?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  RmatchCategory?: Maybe<Scalars["String"]>;
  RmatchConfidence?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  UraTaxpayerName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_Nonindividuals" */
export type Rtcs_Db_Ph_Rmatch_Nonindividuals_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_Nonindividuals_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_Nonindividuals_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_Nonindividuals>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_Nonindividuals" */
export type Rtcs_Db_Ph_Rmatch_Nonindividuals_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Nonindividuals_aggregate_fields";
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_Nonindividuals_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_Nonindividuals_Min_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_Nonindividuals" */
export type Rtcs_Db_Ph_Rmatch_Nonindividuals_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Nonindividuals_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rmatch_Nonindividuals" */
export type Rtcs_Db_Ph_Rmatch_Nonindividuals_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_Nonindividuals_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_Nonindividuals_Min_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_Nonindividuals" */
export type Rtcs_Db_Ph_Rmatch_Nonindividuals_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_Nonindividuals_Insert_Input>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_Nonindividuals". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_Nonindividuals_Bool_Exp = {
  BusinessStartDt?: Maybe<String_Comparison_Exp>;
  DateOfIncorporation?: Maybe<String_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  EmailId?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  KccaCustomerId?: Maybe<String_Comparison_Exp>;
  KccaEntityLegalName?: Maybe<String_Comparison_Exp>;
  LandlineNumber?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<String_Comparison_Exp>;
  MatchAttributes?: Maybe<String_Comparison_Exp>;
  Mobile?: Maybe<String_Comparison_Exp>;
  MobileNumber?: Maybe<String_Comparison_Exp>;
  RmatchCategory?: Maybe<String_Comparison_Exp>;
  RmatchConfidence?: Maybe<String_Comparison_Exp>;
  SecondMobile?: Maybe<String_Comparison_Exp>;
  Telephone?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  UraTaxpayerName?: Maybe<String_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_Nonindividuals_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_Nonindividuals_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_Nonindividuals_Bool_Exp>>>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_Nonindividuals" */
export type Rtcs_Db_Ph_Rmatch_Nonindividuals_Insert_Input = {
  BusinessStartDt?: Maybe<Scalars["String"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  KccaCustomerId?: Maybe<Scalars["String"]>;
  KccaEntityLegalName?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["String"]>;
  MatchAttributes?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  RmatchCategory?: Maybe<Scalars["String"]>;
  RmatchConfidence?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  UraTaxpayerName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_Nonindividuals_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Nonindividuals_max_fields";
  BusinessStartDt?: Maybe<Scalars["String"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  KccaCustomerId?: Maybe<Scalars["String"]>;
  KccaEntityLegalName?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["String"]>;
  MatchAttributes?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  RmatchCategory?: Maybe<Scalars["String"]>;
  RmatchConfidence?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  UraTaxpayerName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_Nonindividuals" */
export type Rtcs_Db_Ph_Rmatch_Nonindividuals_Max_Order_By = {
  BusinessStartDt?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  KccaCustomerId?: Maybe<Order_By>;
  KccaEntityLegalName?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchAttributes?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  RmatchCategory?: Maybe<Order_By>;
  RmatchConfidence?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  UraTaxpayerName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_Nonindividuals_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Nonindividuals_min_fields";
  BusinessStartDt?: Maybe<Scalars["String"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  KccaCustomerId?: Maybe<Scalars["String"]>;
  KccaEntityLegalName?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["String"]>;
  MatchAttributes?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  RmatchCategory?: Maybe<Scalars["String"]>;
  RmatchConfidence?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  UraTaxpayerName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_Nonindividuals" */
export type Rtcs_Db_Ph_Rmatch_Nonindividuals_Min_Order_By = {
  BusinessStartDt?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  KccaCustomerId?: Maybe<Order_By>;
  KccaEntityLegalName?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchAttributes?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  RmatchCategory?: Maybe<Order_By>;
  RmatchConfidence?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  UraTaxpayerName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_Nonindividuals" */
export type Rtcs_Db_Ph_Rmatch_Nonindividuals_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_Nonindividuals_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_Nonindividuals>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_Nonindividuals" */
export type Rtcs_Db_Ph_Rmatch_Nonindividuals_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_Nonindividuals_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_Nonindividuals" */
export type Rtcs_Db_Ph_Rmatch_Nonindividuals_Order_By = {
  BusinessStartDt?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  KccaCustomerId?: Maybe<Order_By>;
  KccaEntityLegalName?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchAttributes?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  RmatchCategory?: Maybe<Order_By>;
  RmatchConfidence?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  UraTaxpayerName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_Nonindividuals" */
export enum Rtcs_Db_Ph_Rmatch_Nonindividuals_Select_Column {
  /** column name */
  BusinessStartDt = "BusinessStartDt",
  /** column name */
  DateOfIncorporation = "DateOfIncorporation",
  /** column name */
  Email = "Email",
  /** column name */
  EmailId = "EmailId",
  /** column name */
  File = "File",
  /** column name */
  KccaCustomerId = "KccaCustomerId",
  /** column name */
  KccaEntityLegalName = "KccaEntityLegalName",
  /** column name */
  LandlineNumber = "LandlineNumber",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MatchAttributes = "MatchAttributes",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  RmatchCategory = "RmatchCategory",
  /** column name */
  RmatchConfidence = "RmatchConfidence",
  /** column name */
  SecondMobile = "SecondMobile",
  /** column name */
  Telephone = "Telephone",
  /** column name */
  Tin = "Tin",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  UraTaxpayerName = "UraTaxpayerName",
  /** column name */
  UraTinNo = "UraTinNo",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_Nonindividuals" */
export type Rtcs_Db_Ph_Rmatch_Nonindividuals_Set_Input = {
  BusinessStartDt?: Maybe<Scalars["String"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  KccaCustomerId?: Maybe<Scalars["String"]>;
  KccaEntityLegalName?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["String"]>;
  MatchAttributes?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  RmatchCategory?: Maybe<Scalars["String"]>;
  RmatchConfidence?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  UraTaxpayerName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_PropertiesComposite" */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesComposite";
  Blockno?: Maybe<Scalars["String"]>;
  Camvid?: Maybe<Scalars["String"]>;
  Countyid?: Maybe<Scalars["String"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Districtid?: Maybe<Scalars["String"]>;
  Divisionid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Imgpath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Nwsccustomerid?: Maybe<Scalars["float8"]>;
  Nwsccustomername?: Maybe<Scalars["String"]>;
  Parishid?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["String"]>;
  Propertytypeid?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sourcecustomer?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subcountyid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  Urid?: Maybe<Scalars["String"]>;
  Villageid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_PropertiesComposite" */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesComposite_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_PropertiesComposite>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_PropertiesComposite" */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesComposite_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_PropertiesComposite" */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rmatch_PropertiesComposite" */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_PropertiesComposite" */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesComposite_avg_fields";
  Currentrateablevalue?: Maybe<Scalars["Float"]>;
  Customerid?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Nwsccustomerid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_PropertiesComposite" */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Avg_Order_By = {
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Nwsccustomerid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_PropertiesComposite". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Bool_Exp = {
  Blockno?: Maybe<String_Comparison_Exp>;
  Camvid?: Maybe<String_Comparison_Exp>;
  Countyid?: Maybe<String_Comparison_Exp>;
  Currentrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Customerid?: Maybe<Bigint_Comparison_Exp>;
  Districtid?: Maybe<String_Comparison_Exp>;
  Divisionid?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Flatno?: Maybe<String_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  Grossrentvalueadjusted?: Maybe<Float8_Comparison_Exp>;
  Grossvalue?: Maybe<Float8_Comparison_Exp>;
  Imgpath?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  Maptitle?: Maybe<String_Comparison_Exp>;
  Matchcategory?: Maybe<String_Comparison_Exp>;
  Matchscore?: Maybe<Float8_Comparison_Exp>;
  Numproperties?: Maybe<Bigint_Comparison_Exp>;
  Numrentedproperties?: Maybe<Bigint_Comparison_Exp>;
  Nwsccustomerid?: Maybe<Float8_Comparison_Exp>;
  Nwsccustomername?: Maybe<String_Comparison_Exp>;
  Parishid?: Maybe<String_Comparison_Exp>;
  Plotno?: Maybe<String_Comparison_Exp>;
  Propertyno?: Maybe<String_Comparison_Exp>;
  Propertyrentedstatusid?: Maybe<String_Comparison_Exp>;
  Propertytypeid?: Maybe<String_Comparison_Exp>;
  Rateablevalue?: Maybe<Float8_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Rnidcustomer?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  Sourcecustomer?: Maybe<String_Comparison_Exp>;
  Streetid?: Maybe<String_Comparison_Exp>;
  Subcountyid?: Maybe<String_Comparison_Exp>;
  Subpropertytypeid?: Maybe<String_Comparison_Exp>;
  Supplementary?: Maybe<Float8_Comparison_Exp>;
  Urid?: Maybe<String_Comparison_Exp>;
  Villageid?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_PropertiesComposite" */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Inc_Input = {
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Nwsccustomerid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_PropertiesComposite" */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Insert_Input = {
  Blockno?: Maybe<Scalars["String"]>;
  Camvid?: Maybe<Scalars["String"]>;
  Countyid?: Maybe<Scalars["String"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Districtid?: Maybe<Scalars["String"]>;
  Divisionid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Imgpath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Nwsccustomerid?: Maybe<Scalars["float8"]>;
  Nwsccustomername?: Maybe<Scalars["String"]>;
  Parishid?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["String"]>;
  Propertytypeid?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sourcecustomer?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subcountyid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  Urid?: Maybe<Scalars["String"]>;
  Villageid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesComposite_max_fields";
  Blockno?: Maybe<Scalars["String"]>;
  Camvid?: Maybe<Scalars["String"]>;
  Countyid?: Maybe<Scalars["String"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Districtid?: Maybe<Scalars["String"]>;
  Divisionid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Imgpath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Nwsccustomerid?: Maybe<Scalars["float8"]>;
  Nwsccustomername?: Maybe<Scalars["String"]>;
  Parishid?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["String"]>;
  Propertytypeid?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sourcecustomer?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subcountyid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  Urid?: Maybe<Scalars["String"]>;
  Villageid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_PropertiesComposite" */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Max_Order_By = {
  Blockno?: Maybe<Order_By>;
  Camvid?: Maybe<Order_By>;
  Countyid?: Maybe<Order_By>;
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Districtid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Imgpath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Maptitle?: Maybe<Order_By>;
  Matchcategory?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Nwsccustomerid?: Maybe<Order_By>;
  Nwsccustomername?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rnidcustomer?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sourcecustomer?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subcountyid?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Urid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesComposite_min_fields";
  Blockno?: Maybe<Scalars["String"]>;
  Camvid?: Maybe<Scalars["String"]>;
  Countyid?: Maybe<Scalars["String"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Districtid?: Maybe<Scalars["String"]>;
  Divisionid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Imgpath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Nwsccustomerid?: Maybe<Scalars["float8"]>;
  Nwsccustomername?: Maybe<Scalars["String"]>;
  Parishid?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["String"]>;
  Propertytypeid?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sourcecustomer?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subcountyid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  Urid?: Maybe<Scalars["String"]>;
  Villageid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_PropertiesComposite" */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Min_Order_By = {
  Blockno?: Maybe<Order_By>;
  Camvid?: Maybe<Order_By>;
  Countyid?: Maybe<Order_By>;
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Districtid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Imgpath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Maptitle?: Maybe<Order_By>;
  Matchcategory?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Nwsccustomerid?: Maybe<Order_By>;
  Nwsccustomername?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rnidcustomer?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sourcecustomer?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subcountyid?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Urid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_PropertiesComposite" */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesComposite_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_PropertiesComposite>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_PropertiesComposite" */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_PropertiesComposite_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_PropertiesComposite" */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Order_By = {
  Blockno?: Maybe<Order_By>;
  Camvid?: Maybe<Order_By>;
  Countyid?: Maybe<Order_By>;
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Districtid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Imgpath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Maptitle?: Maybe<Order_By>;
  Matchcategory?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Nwsccustomerid?: Maybe<Order_By>;
  Nwsccustomername?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rnidcustomer?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sourcecustomer?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subcountyid?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Urid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_PropertiesComposite" */
export enum Rtcs_Db_Ph_Rmatch_PropertiesComposite_Select_Column {
  /** column name */
  Blockno = "Blockno",
  /** column name */
  Camvid = "Camvid",
  /** column name */
  Countyid = "Countyid",
  /** column name */
  Currentrateablevalue = "Currentrateablevalue",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  Districtid = "Districtid",
  /** column name */
  Divisionid = "Divisionid",
  /** column name */
  File = "File",
  /** column name */
  Flatno = "Flatno",
  /** column name */
  Gml = "Gml",
  /** column name */
  Grossrentvalueadjusted = "Grossrentvalueadjusted",
  /** column name */
  Grossvalue = "Grossvalue",
  /** column name */
  Imgpath = "Imgpath",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  Maptitle = "Maptitle",
  /** column name */
  Matchcategory = "Matchcategory",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Numproperties = "Numproperties",
  /** column name */
  Numrentedproperties = "Numrentedproperties",
  /** column name */
  Nwsccustomerid = "Nwsccustomerid",
  /** column name */
  Nwsccustomername = "Nwsccustomername",
  /** column name */
  Parishid = "Parishid",
  /** column name */
  Plotno = "Plotno",
  /** column name */
  Propertyno = "Propertyno",
  /** column name */
  Propertyrentedstatusid = "Propertyrentedstatusid",
  /** column name */
  Propertytypeid = "Propertytypeid",
  /** column name */
  Rateablevalue = "Rateablevalue",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Rnidcustomer = "Rnidcustomer",
  /** column name */
  Source = "Source",
  /** column name */
  Sourcecustomer = "Sourcecustomer",
  /** column name */
  Streetid = "Streetid",
  /** column name */
  Subcountyid = "Subcountyid",
  /** column name */
  Subpropertytypeid = "Subpropertytypeid",
  /** column name */
  Supplementary = "Supplementary",
  /** column name */
  Urid = "Urid",
  /** column name */
  Villageid = "Villageid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_PropertiesComposite" */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Set_Input = {
  Blockno?: Maybe<Scalars["String"]>;
  Camvid?: Maybe<Scalars["String"]>;
  Countyid?: Maybe<Scalars["String"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Districtid?: Maybe<Scalars["String"]>;
  Divisionid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Imgpath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Nwsccustomerid?: Maybe<Scalars["float8"]>;
  Nwsccustomername?: Maybe<Scalars["String"]>;
  Parishid?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["String"]>;
  Propertytypeid?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sourcecustomer?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subcountyid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  Urid?: Maybe<Scalars["String"]>;
  Villageid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesComposite_stddev_fields";
  Currentrateablevalue?: Maybe<Scalars["Float"]>;
  Customerid?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Nwsccustomerid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_PropertiesComposite" */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Stddev_Order_By = {
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Nwsccustomerid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesComposite_stddev_pop_fields";
  Currentrateablevalue?: Maybe<Scalars["Float"]>;
  Customerid?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Nwsccustomerid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_PropertiesComposite" */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Stddev_Pop_Order_By = {
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Nwsccustomerid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesComposite_stddev_samp_fields";
  Currentrateablevalue?: Maybe<Scalars["Float"]>;
  Customerid?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Nwsccustomerid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_PropertiesComposite" */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Stddev_Samp_Order_By = {
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Nwsccustomerid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesComposite_sum_fields";
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Nwsccustomerid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_PropertiesComposite" */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Sum_Order_By = {
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Nwsccustomerid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesComposite_var_pop_fields";
  Currentrateablevalue?: Maybe<Scalars["Float"]>;
  Customerid?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Nwsccustomerid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_PropertiesComposite" */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Var_Pop_Order_By = {
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Nwsccustomerid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesComposite_var_samp_fields";
  Currentrateablevalue?: Maybe<Scalars["Float"]>;
  Customerid?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Nwsccustomerid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_PropertiesComposite" */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Var_Samp_Order_By = {
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Nwsccustomerid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesComposite_variance_fields";
  Currentrateablevalue?: Maybe<Scalars["Float"]>;
  Customerid?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Nwsccustomerid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_PropertiesComposite" */
export type Rtcs_Db_Ph_Rmatch_PropertiesComposite_Variance_Order_By = {
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Nwsccustomerid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_PropertiesNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesNetwork";
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["String"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_PropertiesNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesNetwork_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_PropertiesNetwork>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_PropertiesNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesNetwork_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_PropertiesNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rmatch_PropertiesNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_PropertiesNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesNetwork_avg_fields";
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_PropertiesNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Avg_Order_By = {
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_PropertiesNetwork". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Bool_Exp = {
  BlockNo?: Maybe<String_Comparison_Exp>;
  CamvId?: Maybe<String_Comparison_Exp>;
  CountyId?: Maybe<String_Comparison_Exp>;
  CurrentRateableValue?: Maybe<String_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  DistrictId?: Maybe<String_Comparison_Exp>;
  DivisionId?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  GrossValue?: Maybe<Float8_Comparison_Exp>;
  ImgPath?: Maybe<String_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  MapTitle?: Maybe<String_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Float8_Comparison_Exp>;
  NumProperties?: Maybe<Bigint_Comparison_Exp>;
  NumRentedProperties?: Maybe<Bigint_Comparison_Exp>;
  NwscCustomerId?: Maybe<String_Comparison_Exp>;
  NwscCustomerName?: Maybe<String_Comparison_Exp>;
  ParishId?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyNo?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatusId?: Maybe<String_Comparison_Exp>;
  PropertyTypeId?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  RnidCustomer?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  SourceCustomer?: Maybe<String_Comparison_Exp>;
  Street?: Maybe<String_Comparison_Exp>;
  StreetId?: Maybe<String_Comparison_Exp>;
  SubCountyId?: Maybe<String_Comparison_Exp>;
  SubPropertyTypeId?: Maybe<String_Comparison_Exp>;
  Supplementary?: Maybe<String_Comparison_Exp>;
  VillageId?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_PropertiesNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Inc_Input = {
  GrossValue?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_PropertiesNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Insert_Input = {
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["String"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesNetwork_max_fields";
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["String"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_PropertiesNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Max_Order_By = {
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerName?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidCustomer?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SourceCustomer?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesNetwork_min_fields";
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["String"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_PropertiesNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Min_Order_By = {
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerName?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidCustomer?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SourceCustomer?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_PropertiesNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesNetwork_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_PropertiesNetwork>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_PropertiesNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_PropertiesNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Order_By = {
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerName?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidCustomer?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SourceCustomer?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_PropertiesNetwork" */
export enum Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Select_Column {
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  CamvId = "CamvId",
  /** column name */
  CountyId = "CountyId",
  /** column name */
  CurrentRateableValue = "CurrentRateableValue",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DistrictId = "DistrictId",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  Gml = "Gml",
  /** column name */
  GrossValue = "GrossValue",
  /** column name */
  ImgPath = "ImgPath",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MapTitle = "MapTitle",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  NumRentedProperties = "NumRentedProperties",
  /** column name */
  NwscCustomerId = "NwscCustomerId",
  /** column name */
  NwscCustomerName = "NwscCustomerName",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyNo = "PropertyNo",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  RnidCustomer = "RnidCustomer",
  /** column name */
  Source = "Source",
  /** column name */
  SourceCustomer = "SourceCustomer",
  /** column name */
  Street = "Street",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  SubCountyId = "SubCountyId",
  /** column name */
  SubPropertyTypeId = "SubPropertyTypeId",
  /** column name */
  Supplementary = "Supplementary",
  /** column name */
  VillageId = "VillageId",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_PropertiesNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Set_Input = {
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["String"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesNetwork_stddev_fields";
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_PropertiesNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Stddev_Order_By = {
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesNetwork_stddev_pop_fields";
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_PropertiesNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Stddev_Pop_Order_By = {
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesNetwork_stddev_samp_fields";
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_PropertiesNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Stddev_Samp_Order_By = {
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesNetwork_sum_fields";
  GrossValue?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_PropertiesNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Sum_Order_By = {
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesNetwork_var_pop_fields";
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_PropertiesNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Var_Pop_Order_By = {
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesNetwork_var_samp_fields";
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_PropertiesNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Var_Samp_Order_By = {
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesNetwork_variance_fields";
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_PropertiesNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Variance_Order_By = {
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_PropertyNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork = {
  __typename?: "rtcs_db_PH_rmatch_PropertyNetwork";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RatingZoneId?: Maybe<Scalars["float8"]>;
  Remisson?: Maybe<Scalars["float8"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_PropertyNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_PropertyNetwork_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_PropertyNetwork>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_PropertyNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertyNetwork_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_PropertyNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rmatch_PropertyNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_PropertyNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertyNetwork_avg_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  RatingZoneId?: Maybe<Scalars["Float"]>;
  Remisson?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_PropertyNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Avg_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RatingZoneId?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_PropertyNetwork". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  BlockNo?: Maybe<String_Comparison_Exp>;
  CamvId?: Maybe<String_Comparison_Exp>;
  CreatedBy?: Maybe<Float8_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamptz_Comparison_Exp>;
  CurrentRateableValue?: Maybe<Float8_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  DivisionId?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  GprsCordinateX?: Maybe<String_Comparison_Exp>;
  GprsCordinateY?: Maybe<String_Comparison_Exp>;
  GrossValue?: Maybe<String_Comparison_Exp>;
  HouseNo?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  ImgPath?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  LocationAddress?: Maybe<String_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  MapTitle?: Maybe<String_Comparison_Exp>;
  ModifiedBy?: Maybe<Float8_Comparison_Exp>;
  ModifiedDate?: Maybe<Timestamptz_Comparison_Exp>;
  ParishId?: Maybe<Float8_Comparison_Exp>;
  ParticularsFrontages?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyNo?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatusId?: Maybe<Float8_Comparison_Exp>;
  PropertyTypeId?: Maybe<Float8_Comparison_Exp>;
  RateableValue?: Maybe<Float8_Comparison_Exp>;
  RatingZoneId?: Maybe<Float8_Comparison_Exp>;
  Remisson?: Maybe<Float8_Comparison_Exp>;
  RoadOthers?: Maybe<String_Comparison_Exp>;
  SerialNo?: Maybe<String_Comparison_Exp>;
  Sid?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Float8_Comparison_Exp>;
  StreetId?: Maybe<String_Comparison_Exp>;
  SubPropertyTypeId?: Maybe<Float8_Comparison_Exp>;
  Supplementary?: Maybe<Float8_Comparison_Exp>;
  VillageId?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_PropertyNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Inc_Input = {
  CreatedBy?: Maybe<Scalars["float8"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RatingZoneId?: Maybe<Scalars["float8"]>;
  Remisson?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_PropertyNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RatingZoneId?: Maybe<Scalars["float8"]>;
  Remisson?: Maybe<Scalars["float8"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertyNetwork_max_fields";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RatingZoneId?: Maybe<Scalars["float8"]>;
  Remisson?: Maybe<Scalars["float8"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_PropertyNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Max_Order_By = {
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GprsCordinateX?: Maybe<Order_By>;
  GprsCordinateY?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LocationAddress?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RatingZoneId?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  RoadOthers?: Maybe<Order_By>;
  SerialNo?: Maybe<Order_By>;
  Sid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertyNetwork_min_fields";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RatingZoneId?: Maybe<Scalars["float8"]>;
  Remisson?: Maybe<Scalars["float8"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_PropertyNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Min_Order_By = {
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GprsCordinateX?: Maybe<Order_By>;
  GprsCordinateY?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LocationAddress?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RatingZoneId?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  RoadOthers?: Maybe<Order_By>;
  SerialNo?: Maybe<Order_By>;
  Sid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_PropertyNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_PropertyNetwork_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_PropertyNetwork>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_PropertyNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_PropertyNetwork_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_PropertyNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Order_By = {
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GprsCordinateX?: Maybe<Order_By>;
  GprsCordinateY?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LocationAddress?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RatingZoneId?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  RoadOthers?: Maybe<Order_By>;
  SerialNo?: Maybe<Order_By>;
  Sid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_PropertyNetwork" */
export enum Rtcs_Db_Ph_Rmatch_PropertyNetwork_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  CamvId = "CamvId",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CurrentRateableValue = "CurrentRateableValue",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  File = "File",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  GprsCordinateX = "GprsCordinateX",
  /** column name */
  GprsCordinateY = "GprsCordinateY",
  /** column name */
  GrossValue = "GrossValue",
  /** column name */
  HouseNo = "HouseNo",
  /** column name */
  Id = "Id",
  /** column name */
  ImgPath = "ImgPath",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  LocationAddress = "LocationAddress",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MapTitle = "MapTitle",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  ParticularsFrontages = "ParticularsFrontages",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyNo = "PropertyNo",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  RateableValue = "RateableValue",
  /** column name */
  RatingZoneId = "RatingZoneId",
  /** column name */
  Remisson = "Remisson",
  /** column name */
  RoadOthers = "RoadOthers",
  /** column name */
  SerialNo = "SerialNo",
  /** column name */
  Sid = "Sid",
  /** column name */
  Source = "Source",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  SubPropertyTypeId = "SubPropertyTypeId",
  /** column name */
  Supplementary = "Supplementary",
  /** column name */
  VillageId = "VillageId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_PropertyNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RatingZoneId?: Maybe<Scalars["float8"]>;
  Remisson?: Maybe<Scalars["float8"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertyNetwork_stddev_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  RatingZoneId?: Maybe<Scalars["Float"]>;
  Remisson?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_PropertyNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Stddev_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RatingZoneId?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertyNetwork_stddev_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  RatingZoneId?: Maybe<Scalars["Float"]>;
  Remisson?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_PropertyNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Stddev_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RatingZoneId?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertyNetwork_stddev_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  RatingZoneId?: Maybe<Scalars["Float"]>;
  Remisson?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_PropertyNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Stddev_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RatingZoneId?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertyNetwork_sum_fields";
  CreatedBy?: Maybe<Scalars["float8"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RatingZoneId?: Maybe<Scalars["float8"]>;
  Remisson?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_PropertyNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Sum_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RatingZoneId?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertyNetwork_var_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  RatingZoneId?: Maybe<Scalars["Float"]>;
  Remisson?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_PropertyNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Var_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RatingZoneId?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertyNetwork_var_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  RatingZoneId?: Maybe<Scalars["Float"]>;
  Remisson?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_PropertyNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Var_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RatingZoneId?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_PropertyNetwork_variance_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  RatingZoneId?: Maybe<Scalars["Float"]>;
  Remisson?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_PropertyNetwork" */
export type Rtcs_Db_Ph_Rmatch_PropertyNetwork_Variance_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RatingZoneId?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_RMatchDetailed904" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904 = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed904";
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaEmails?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile1?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAtributes?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraDob?: Maybe<Scalars["String"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_RMatchDetailed904" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed904_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed904>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_RMatchDetailed904" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed904_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_RMatchDetailed904" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rmatch_RMatchDetailed904" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_RMatchDetailed904" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed904_avg_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed904" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Avg_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_RMatchDetailed904". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  AdjustedIncome?: Maybe<Float8_Comparison_Exp>;
  BlockNo?: Maybe<String_Comparison_Exp>;
  CountUra?: Maybe<Bigint_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  DivisionId?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  GrossValue?: Maybe<String_Comparison_Exp>;
  HouseNo?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcEmp?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  KccaDob?: Maybe<Timestamp_Comparison_Exp>;
  KccaEmail?: Maybe<String_Comparison_Exp>;
  KccaEmails?: Maybe<String_Comparison_Exp>;
  KccaFirstName?: Maybe<String_Comparison_Exp>;
  KccaLandline?: Maybe<String_Comparison_Exp>;
  KccaMiddleName?: Maybe<String_Comparison_Exp>;
  KccaMobile1?: Maybe<String_Comparison_Exp>;
  KccaMobile2?: Maybe<String_Comparison_Exp>;
  KccaNin?: Maybe<String_Comparison_Exp>;
  KccaSurname?: Maybe<String_Comparison_Exp>;
  KccaTin?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchDetails?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Bigint_Comparison_Exp>;
  MatchScoreAtributes?: Maybe<String_Comparison_Exp>;
  NumProperties?: Maybe<Bigint_Comparison_Exp>;
  ParishId?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyId?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatusId?: Maybe<String_Comparison_Exp>;
  PropertyTypeId?: Maybe<String_Comparison_Exp>;
  RateableValue?: Maybe<Float8_Comparison_Exp>;
  StreetId?: Maybe<String_Comparison_Exp>;
  SubPropertyTypeId?: Maybe<String_Comparison_Exp>;
  UraDob?: Maybe<String_Comparison_Exp>;
  UraEmailId?: Maybe<String_Comparison_Exp>;
  UraFirstname?: Maybe<String_Comparison_Exp>;
  UraLandlineNumber?: Maybe<Float8_Comparison_Exp>;
  UraMiddlename?: Maybe<String_Comparison_Exp>;
  UraMobileNumber?: Maybe<String_Comparison_Exp>;
  UraNationalId?: Maybe<String_Comparison_Exp>;
  UraSurname?: Maybe<String_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  VillageId?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_RMatchDetailed904" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Inc_Input = {
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_RMatchDetailed904" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaEmails?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile1?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAtributes?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraDob?: Maybe<Scalars["String"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed904_max_fields";
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaEmails?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile1?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAtributes?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraDob?: Maybe<Scalars["String"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed904" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Max_Order_By = {
  Address?: Maybe<Order_By>;
  AdjustedIncome?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaDob?: Maybe<Order_By>;
  KccaEmail?: Maybe<Order_By>;
  KccaEmails?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaLandline?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaMobile1?: Maybe<Order_By>;
  KccaMobile2?: Maybe<Order_By>;
  KccaNin?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  KccaTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAtributes?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  UraDob?: Maybe<Order_By>;
  UraEmailId?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraMobileNumber?: Maybe<Order_By>;
  UraNationalId?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed904_min_fields";
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaEmails?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile1?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAtributes?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraDob?: Maybe<Scalars["String"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed904" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Min_Order_By = {
  Address?: Maybe<Order_By>;
  AdjustedIncome?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaDob?: Maybe<Order_By>;
  KccaEmail?: Maybe<Order_By>;
  KccaEmails?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaLandline?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaMobile1?: Maybe<Order_By>;
  KccaMobile2?: Maybe<Order_By>;
  KccaNin?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  KccaTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAtributes?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  UraDob?: Maybe<Order_By>;
  UraEmailId?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraMobileNumber?: Maybe<Order_By>;
  UraNationalId?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_RMatchDetailed904" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed904_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed904>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_RMatchDetailed904" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_RMatchDetailed904" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Order_By = {
  Address?: Maybe<Order_By>;
  AdjustedIncome?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaDob?: Maybe<Order_By>;
  KccaEmail?: Maybe<Order_By>;
  KccaEmails?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaLandline?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaMobile1?: Maybe<Order_By>;
  KccaMobile2?: Maybe<Order_By>;
  KccaNin?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  KccaTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAtributes?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  UraDob?: Maybe<Order_By>;
  UraEmailId?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraMobileNumber?: Maybe<Order_By>;
  UraNationalId?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_RMatchDetailed904" */
export enum Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  AdjustedIncome = "AdjustedIncome",
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  CountUra = "CountUra",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  File = "File",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  GrossValue = "GrossValue",
  /** column name */
  HouseNo = "HouseNo",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcEmp = "IsIncomeSrcEmp",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  KccaDob = "KccaDob",
  /** column name */
  KccaEmail = "KccaEmail",
  /** column name */
  KccaEmails = "KccaEmails",
  /** column name */
  KccaFirstName = "KccaFirstName",
  /** column name */
  KccaLandline = "KccaLandline",
  /** column name */
  KccaMiddleName = "KccaMiddleName",
  /** column name */
  KccaMobile1 = "KccaMobile1",
  /** column name */
  KccaMobile2 = "KccaMobile2",
  /** column name */
  KccaNin = "KccaNin",
  /** column name */
  KccaSurname = "KccaSurname",
  /** column name */
  KccaTin = "KccaTin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchDetails = "MatchDetails",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MatchScoreAtributes = "MatchScoreAtributes",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyId = "PropertyId",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  RateableValue = "RateableValue",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  SubPropertyTypeId = "SubPropertyTypeId",
  /** column name */
  UraDob = "UraDob",
  /** column name */
  UraEmailId = "UraEmailId",
  /** column name */
  UraFirstname = "UraFirstname",
  /** column name */
  UraLandlineNumber = "UraLandlineNumber",
  /** column name */
  UraMiddlename = "UraMiddlename",
  /** column name */
  UraMobileNumber = "UraMobileNumber",
  /** column name */
  UraNationalId = "UraNationalId",
  /** column name */
  UraSurname = "UraSurname",
  /** column name */
  UraTinNo = "UraTinNo",
  /** column name */
  VillageId = "VillageId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_RMatchDetailed904" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaEmails?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile1?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAtributes?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraDob?: Maybe<Scalars["String"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed904_stddev_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed904" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Stddev_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed904_stddev_pop_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed904" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Stddev_Pop_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed904_stddev_samp_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed904" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Stddev_Samp_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed904_sum_fields";
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed904" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Sum_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed904_var_pop_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed904" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Var_Pop_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed904_var_samp_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed904" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Var_Samp_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed904_variance_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed904" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Variance_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_RMatchDetailed916" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916 = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916";
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTinNo?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RateableValueAdjusted?: Maybe<Scalars["float8"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTin?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregate =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregate";
    aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregate_Fields>;
    nodes: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy>;
  };

/** aggregate fields of "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregate_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregate_fields";
    avg?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Avg_Fields>;
    count?: Maybe<Scalars["Int"]>;
    max?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Max_Fields>;
    min?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Min_Fields>;
    stddev?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Stddev_Fields>;
    stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Stddev_Samp_Fields>;
    sum?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Sum_Fields>;
    var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Var_Pop_Fields>;
    var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Var_Samp_Fields>;
    variance?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Variance_Fields>;
  };

/** aggregate fields of "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregate_Order_By =
  {
    avg?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Max_Order_By>;
    min?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Min_Order_By>;
    stddev?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Stddev_Order_By>;
    stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Stddev_Samp_Order_By>;
    sum?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Sum_Order_By>;
    var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Var_Pop_Order_By>;
    var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Var_Samp_Order_By>;
    variance?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Variance_Order_By>;
  };

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Avg_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_avg_fields";
    KccaTinNo?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    MatchScore?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    RateableValue?: Maybe<Scalars["Float"]>;
    RateableValueAdjusted?: Maybe<Scalars["Float"]>;
  };

/** order by avg() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Avg_Order_By =
  {
    KccaTinNo?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    RateableValue?: Maybe<Order_By>;
    RateableValueAdjusted?: Maybe<Order_By>;
  };

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Bool_Exp =
  {
    Address?: Maybe<String_Comparison_Exp>;
    BlockNo?: Maybe<String_Comparison_Exp>;
    CustomerId?: Maybe<String_Comparison_Exp>;
    DivisionId?: Maybe<String_Comparison_Exp>;
    File?: Maybe<String_Comparison_Exp>;
    FlatNo?: Maybe<String_Comparison_Exp>;
    GrossValue?: Maybe<String_Comparison_Exp>;
    HouseNo?: Maybe<String_Comparison_Exp>;
    KccaFirstName?: Maybe<String_Comparison_Exp>;
    KccaMiddleName?: Maybe<String_Comparison_Exp>;
    KccaSurname?: Maybe<String_Comparison_Exp>;
    KccaTinNo?: Maybe<Float8_Comparison_Exp>;
    LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
    Latitude?: Maybe<Float8_Comparison_Exp>;
    Line?: Maybe<Bigint_Comparison_Exp>;
    Longitude?: Maybe<Float8_Comparison_Exp>;
    MatchKccaUra?: Maybe<String_Comparison_Exp>;
    MatchScore?: Maybe<Bigint_Comparison_Exp>;
    NumProperties?: Maybe<Bigint_Comparison_Exp>;
    ParishId?: Maybe<String_Comparison_Exp>;
    PlotNo?: Maybe<String_Comparison_Exp>;
    PropertyId?: Maybe<String_Comparison_Exp>;
    PropertyRentedStatusId?: Maybe<String_Comparison_Exp>;
    PropertyTypeId?: Maybe<String_Comparison_Exp>;
    RateableValue?: Maybe<Float8_Comparison_Exp>;
    RateableValueAdjusted?: Maybe<Float8_Comparison_Exp>;
    ScoreMatchAtributes?: Maybe<String_Comparison_Exp>;
    StreetId?: Maybe<String_Comparison_Exp>;
    SubPropertyTypeId?: Maybe<String_Comparison_Exp>;
    UraFirstname?: Maybe<String_Comparison_Exp>;
    UraMatchingTins?: Maybe<String_Comparison_Exp>;
    UraMiddlename?: Maybe<String_Comparison_Exp>;
    UraSurname?: Maybe<String_Comparison_Exp>;
    UraTin?: Maybe<String_Comparison_Exp>;
    VillageId?: Maybe<String_Comparison_Exp>;
    WhoUpdated?: Maybe<String_Comparison_Exp>;
    _and?: Maybe<
      Array<
        Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Bool_Exp>
      >
    >;
    _not?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Bool_Exp>;
    _or?: Maybe<
      Array<
        Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Bool_Exp>
      >
    >;
  };

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Inc_Input =
  {
    KccaTinNo?: Maybe<Scalars["float8"]>;
    Latitude?: Maybe<Scalars["float8"]>;
    Line?: Maybe<Scalars["bigint"]>;
    Longitude?: Maybe<Scalars["float8"]>;
    MatchScore?: Maybe<Scalars["bigint"]>;
    NumProperties?: Maybe<Scalars["bigint"]>;
    RateableValue?: Maybe<Scalars["float8"]>;
    RateableValueAdjusted?: Maybe<Scalars["float8"]>;
  };

/** input type for inserting data into table "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Insert_Input =
  {
    Address?: Maybe<Scalars["String"]>;
    BlockNo?: Maybe<Scalars["String"]>;
    CustomerId?: Maybe<Scalars["String"]>;
    DivisionId?: Maybe<Scalars["String"]>;
    File?: Maybe<Scalars["String"]>;
    FlatNo?: Maybe<Scalars["String"]>;
    GrossValue?: Maybe<Scalars["String"]>;
    HouseNo?: Maybe<Scalars["String"]>;
    KccaFirstName?: Maybe<Scalars["String"]>;
    KccaMiddleName?: Maybe<Scalars["String"]>;
    KccaSurname?: Maybe<Scalars["String"]>;
    KccaTinNo?: Maybe<Scalars["float8"]>;
    LastUpdated?: Maybe<Scalars["timestamptz"]>;
    Latitude?: Maybe<Scalars["float8"]>;
    Line?: Maybe<Scalars["bigint"]>;
    Longitude?: Maybe<Scalars["float8"]>;
    MatchKccaUra?: Maybe<Scalars["String"]>;
    MatchScore?: Maybe<Scalars["bigint"]>;
    NumProperties?: Maybe<Scalars["bigint"]>;
    ParishId?: Maybe<Scalars["String"]>;
    PlotNo?: Maybe<Scalars["String"]>;
    PropertyId?: Maybe<Scalars["String"]>;
    PropertyRentedStatusId?: Maybe<Scalars["String"]>;
    PropertyTypeId?: Maybe<Scalars["String"]>;
    RateableValue?: Maybe<Scalars["float8"]>;
    RateableValueAdjusted?: Maybe<Scalars["float8"]>;
    ScoreMatchAtributes?: Maybe<Scalars["String"]>;
    StreetId?: Maybe<Scalars["String"]>;
    SubPropertyTypeId?: Maybe<Scalars["String"]>;
    UraFirstname?: Maybe<Scalars["String"]>;
    UraMatchingTins?: Maybe<Scalars["String"]>;
    UraMiddlename?: Maybe<Scalars["String"]>;
    UraSurname?: Maybe<Scalars["String"]>;
    UraTin?: Maybe<Scalars["String"]>;
    VillageId?: Maybe<Scalars["String"]>;
    WhoUpdated?: Maybe<Scalars["String"]>;
  };

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Max_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_max_fields";
    Address?: Maybe<Scalars["String"]>;
    BlockNo?: Maybe<Scalars["String"]>;
    CustomerId?: Maybe<Scalars["String"]>;
    DivisionId?: Maybe<Scalars["String"]>;
    File?: Maybe<Scalars["String"]>;
    FlatNo?: Maybe<Scalars["String"]>;
    GrossValue?: Maybe<Scalars["String"]>;
    HouseNo?: Maybe<Scalars["String"]>;
    KccaFirstName?: Maybe<Scalars["String"]>;
    KccaMiddleName?: Maybe<Scalars["String"]>;
    KccaSurname?: Maybe<Scalars["String"]>;
    KccaTinNo?: Maybe<Scalars["float8"]>;
    LastUpdated?: Maybe<Scalars["timestamptz"]>;
    Latitude?: Maybe<Scalars["float8"]>;
    Line?: Maybe<Scalars["bigint"]>;
    Longitude?: Maybe<Scalars["float8"]>;
    MatchKccaUra?: Maybe<Scalars["String"]>;
    MatchScore?: Maybe<Scalars["bigint"]>;
    NumProperties?: Maybe<Scalars["bigint"]>;
    ParishId?: Maybe<Scalars["String"]>;
    PlotNo?: Maybe<Scalars["String"]>;
    PropertyId?: Maybe<Scalars["String"]>;
    PropertyRentedStatusId?: Maybe<Scalars["String"]>;
    PropertyTypeId?: Maybe<Scalars["String"]>;
    RateableValue?: Maybe<Scalars["float8"]>;
    RateableValueAdjusted?: Maybe<Scalars["float8"]>;
    ScoreMatchAtributes?: Maybe<Scalars["String"]>;
    StreetId?: Maybe<Scalars["String"]>;
    SubPropertyTypeId?: Maybe<Scalars["String"]>;
    UraFirstname?: Maybe<Scalars["String"]>;
    UraMatchingTins?: Maybe<Scalars["String"]>;
    UraMiddlename?: Maybe<Scalars["String"]>;
    UraSurname?: Maybe<Scalars["String"]>;
    UraTin?: Maybe<Scalars["String"]>;
    VillageId?: Maybe<Scalars["String"]>;
    WhoUpdated?: Maybe<Scalars["String"]>;
  };

/** order by max() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Max_Order_By =
  {
    Address?: Maybe<Order_By>;
    BlockNo?: Maybe<Order_By>;
    CustomerId?: Maybe<Order_By>;
    DivisionId?: Maybe<Order_By>;
    File?: Maybe<Order_By>;
    FlatNo?: Maybe<Order_By>;
    GrossValue?: Maybe<Order_By>;
    HouseNo?: Maybe<Order_By>;
    KccaFirstName?: Maybe<Order_By>;
    KccaMiddleName?: Maybe<Order_By>;
    KccaSurname?: Maybe<Order_By>;
    KccaTinNo?: Maybe<Order_By>;
    LastUpdated?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    MatchKccaUra?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    ParishId?: Maybe<Order_By>;
    PlotNo?: Maybe<Order_By>;
    PropertyId?: Maybe<Order_By>;
    PropertyRentedStatusId?: Maybe<Order_By>;
    PropertyTypeId?: Maybe<Order_By>;
    RateableValue?: Maybe<Order_By>;
    RateableValueAdjusted?: Maybe<Order_By>;
    ScoreMatchAtributes?: Maybe<Order_By>;
    StreetId?: Maybe<Order_By>;
    SubPropertyTypeId?: Maybe<Order_By>;
    UraFirstname?: Maybe<Order_By>;
    UraMatchingTins?: Maybe<Order_By>;
    UraMiddlename?: Maybe<Order_By>;
    UraSurname?: Maybe<Order_By>;
    UraTin?: Maybe<Order_By>;
    VillageId?: Maybe<Order_By>;
    WhoUpdated?: Maybe<Order_By>;
  };

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Min_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_min_fields";
    Address?: Maybe<Scalars["String"]>;
    BlockNo?: Maybe<Scalars["String"]>;
    CustomerId?: Maybe<Scalars["String"]>;
    DivisionId?: Maybe<Scalars["String"]>;
    File?: Maybe<Scalars["String"]>;
    FlatNo?: Maybe<Scalars["String"]>;
    GrossValue?: Maybe<Scalars["String"]>;
    HouseNo?: Maybe<Scalars["String"]>;
    KccaFirstName?: Maybe<Scalars["String"]>;
    KccaMiddleName?: Maybe<Scalars["String"]>;
    KccaSurname?: Maybe<Scalars["String"]>;
    KccaTinNo?: Maybe<Scalars["float8"]>;
    LastUpdated?: Maybe<Scalars["timestamptz"]>;
    Latitude?: Maybe<Scalars["float8"]>;
    Line?: Maybe<Scalars["bigint"]>;
    Longitude?: Maybe<Scalars["float8"]>;
    MatchKccaUra?: Maybe<Scalars["String"]>;
    MatchScore?: Maybe<Scalars["bigint"]>;
    NumProperties?: Maybe<Scalars["bigint"]>;
    ParishId?: Maybe<Scalars["String"]>;
    PlotNo?: Maybe<Scalars["String"]>;
    PropertyId?: Maybe<Scalars["String"]>;
    PropertyRentedStatusId?: Maybe<Scalars["String"]>;
    PropertyTypeId?: Maybe<Scalars["String"]>;
    RateableValue?: Maybe<Scalars["float8"]>;
    RateableValueAdjusted?: Maybe<Scalars["float8"]>;
    ScoreMatchAtributes?: Maybe<Scalars["String"]>;
    StreetId?: Maybe<Scalars["String"]>;
    SubPropertyTypeId?: Maybe<Scalars["String"]>;
    UraFirstname?: Maybe<Scalars["String"]>;
    UraMatchingTins?: Maybe<Scalars["String"]>;
    UraMiddlename?: Maybe<Scalars["String"]>;
    UraSurname?: Maybe<Scalars["String"]>;
    UraTin?: Maybe<Scalars["String"]>;
    VillageId?: Maybe<Scalars["String"]>;
    WhoUpdated?: Maybe<Scalars["String"]>;
  };

/** order by min() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Min_Order_By =
  {
    Address?: Maybe<Order_By>;
    BlockNo?: Maybe<Order_By>;
    CustomerId?: Maybe<Order_By>;
    DivisionId?: Maybe<Order_By>;
    File?: Maybe<Order_By>;
    FlatNo?: Maybe<Order_By>;
    GrossValue?: Maybe<Order_By>;
    HouseNo?: Maybe<Order_By>;
    KccaFirstName?: Maybe<Order_By>;
    KccaMiddleName?: Maybe<Order_By>;
    KccaSurname?: Maybe<Order_By>;
    KccaTinNo?: Maybe<Order_By>;
    LastUpdated?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    MatchKccaUra?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    ParishId?: Maybe<Order_By>;
    PlotNo?: Maybe<Order_By>;
    PropertyId?: Maybe<Order_By>;
    PropertyRentedStatusId?: Maybe<Order_By>;
    PropertyTypeId?: Maybe<Order_By>;
    RateableValue?: Maybe<Order_By>;
    RateableValueAdjusted?: Maybe<Order_By>;
    ScoreMatchAtributes?: Maybe<Order_By>;
    StreetId?: Maybe<Order_By>;
    SubPropertyTypeId?: Maybe<Order_By>;
    UraFirstname?: Maybe<Order_By>;
    UraMatchingTins?: Maybe<Order_By>;
    UraMiddlename?: Maybe<Order_By>;
    UraSurname?: Maybe<Order_By>;
    UraTin?: Maybe<Order_By>;
    VillageId?: Maybe<Order_By>;
    WhoUpdated?: Maybe<Order_By>;
  };

/** response of any mutation on the table "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Mutation_Response =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_mutation_response";
    /** number of affected rows by the mutation */
    affected_rows: Scalars["Int"];
    /** data of the affected rows by the mutation */
    returning: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy>;
  };

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Order_By =
  {
    Address?: Maybe<Order_By>;
    BlockNo?: Maybe<Order_By>;
    CustomerId?: Maybe<Order_By>;
    DivisionId?: Maybe<Order_By>;
    File?: Maybe<Order_By>;
    FlatNo?: Maybe<Order_By>;
    GrossValue?: Maybe<Order_By>;
    HouseNo?: Maybe<Order_By>;
    KccaFirstName?: Maybe<Order_By>;
    KccaMiddleName?: Maybe<Order_By>;
    KccaSurname?: Maybe<Order_By>;
    KccaTinNo?: Maybe<Order_By>;
    LastUpdated?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    MatchKccaUra?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    ParishId?: Maybe<Order_By>;
    PlotNo?: Maybe<Order_By>;
    PropertyId?: Maybe<Order_By>;
    PropertyRentedStatusId?: Maybe<Order_By>;
    PropertyTypeId?: Maybe<Order_By>;
    RateableValue?: Maybe<Order_By>;
    RateableValueAdjusted?: Maybe<Order_By>;
    ScoreMatchAtributes?: Maybe<Order_By>;
    StreetId?: Maybe<Order_By>;
    SubPropertyTypeId?: Maybe<Order_By>;
    UraFirstname?: Maybe<Order_By>;
    UraMatchingTins?: Maybe<Order_By>;
    UraMiddlename?: Maybe<Order_By>;
    UraSurname?: Maybe<Order_By>;
    UraTin?: Maybe<Order_By>;
    VillageId?: Maybe<Order_By>;
    WhoUpdated?: Maybe<Order_By>;
  };

/** select columns of table "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export enum Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  File = "File",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  GrossValue = "GrossValue",
  /** column name */
  HouseNo = "HouseNo",
  /** column name */
  KccaFirstName = "KccaFirstName",
  /** column name */
  KccaMiddleName = "KccaMiddleName",
  /** column name */
  KccaSurname = "KccaSurname",
  /** column name */
  KccaTinNo = "KccaTinNo",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MatchKccaUra = "MatchKccaUra",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyId = "PropertyId",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  RateableValue = "RateableValue",
  /** column name */
  RateableValueAdjusted = "RateableValueAdjusted",
  /** column name */
  ScoreMatchAtributes = "ScoreMatchAtributes",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  SubPropertyTypeId = "SubPropertyTypeId",
  /** column name */
  UraFirstname = "UraFirstname",
  /** column name */
  UraMatchingTins = "UraMatchingTins",
  /** column name */
  UraMiddlename = "UraMiddlename",
  /** column name */
  UraSurname = "UraSurname",
  /** column name */
  UraTin = "UraTin",
  /** column name */
  VillageId = "VillageId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Set_Input =
  {
    Address?: Maybe<Scalars["String"]>;
    BlockNo?: Maybe<Scalars["String"]>;
    CustomerId?: Maybe<Scalars["String"]>;
    DivisionId?: Maybe<Scalars["String"]>;
    File?: Maybe<Scalars["String"]>;
    FlatNo?: Maybe<Scalars["String"]>;
    GrossValue?: Maybe<Scalars["String"]>;
    HouseNo?: Maybe<Scalars["String"]>;
    KccaFirstName?: Maybe<Scalars["String"]>;
    KccaMiddleName?: Maybe<Scalars["String"]>;
    KccaSurname?: Maybe<Scalars["String"]>;
    KccaTinNo?: Maybe<Scalars["float8"]>;
    LastUpdated?: Maybe<Scalars["timestamptz"]>;
    Latitude?: Maybe<Scalars["float8"]>;
    Line?: Maybe<Scalars["bigint"]>;
    Longitude?: Maybe<Scalars["float8"]>;
    MatchKccaUra?: Maybe<Scalars["String"]>;
    MatchScore?: Maybe<Scalars["bigint"]>;
    NumProperties?: Maybe<Scalars["bigint"]>;
    ParishId?: Maybe<Scalars["String"]>;
    PlotNo?: Maybe<Scalars["String"]>;
    PropertyId?: Maybe<Scalars["String"]>;
    PropertyRentedStatusId?: Maybe<Scalars["String"]>;
    PropertyTypeId?: Maybe<Scalars["String"]>;
    RateableValue?: Maybe<Scalars["float8"]>;
    RateableValueAdjusted?: Maybe<Scalars["float8"]>;
    ScoreMatchAtributes?: Maybe<Scalars["String"]>;
    StreetId?: Maybe<Scalars["String"]>;
    SubPropertyTypeId?: Maybe<Scalars["String"]>;
    UraFirstname?: Maybe<Scalars["String"]>;
    UraMatchingTins?: Maybe<Scalars["String"]>;
    UraMiddlename?: Maybe<Scalars["String"]>;
    UraSurname?: Maybe<Scalars["String"]>;
    UraTin?: Maybe<Scalars["String"]>;
    VillageId?: Maybe<Scalars["String"]>;
    WhoUpdated?: Maybe<Scalars["String"]>;
  };

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Stddev_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_stddev_fields";
    KccaTinNo?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    MatchScore?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    RateableValue?: Maybe<Scalars["Float"]>;
    RateableValueAdjusted?: Maybe<Scalars["Float"]>;
  };

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Stddev_Order_By =
  {
    KccaTinNo?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    RateableValue?: Maybe<Order_By>;
    RateableValueAdjusted?: Maybe<Order_By>;
  };

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Stddev_Pop_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_stddev_pop_fields";
    KccaTinNo?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    MatchScore?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    RateableValue?: Maybe<Scalars["Float"]>;
    RateableValueAdjusted?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Stddev_Pop_Order_By =
  {
    KccaTinNo?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    RateableValue?: Maybe<Order_By>;
    RateableValueAdjusted?: Maybe<Order_By>;
  };

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Stddev_Samp_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_stddev_samp_fields";
    KccaTinNo?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    MatchScore?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    RateableValue?: Maybe<Scalars["Float"]>;
    RateableValueAdjusted?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Stddev_Samp_Order_By =
  {
    KccaTinNo?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    RateableValue?: Maybe<Order_By>;
    RateableValueAdjusted?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Sum_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_sum_fields";
    KccaTinNo?: Maybe<Scalars["float8"]>;
    Latitude?: Maybe<Scalars["float8"]>;
    Line?: Maybe<Scalars["bigint"]>;
    Longitude?: Maybe<Scalars["float8"]>;
    MatchScore?: Maybe<Scalars["bigint"]>;
    NumProperties?: Maybe<Scalars["bigint"]>;
    RateableValue?: Maybe<Scalars["float8"]>;
    RateableValueAdjusted?: Maybe<Scalars["float8"]>;
  };

/** order by sum() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Sum_Order_By =
  {
    KccaTinNo?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    RateableValue?: Maybe<Order_By>;
    RateableValueAdjusted?: Maybe<Order_By>;
  };

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Var_Pop_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_var_pop_fields";
    KccaTinNo?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    MatchScore?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    RateableValue?: Maybe<Scalars["Float"]>;
    RateableValueAdjusted?: Maybe<Scalars["Float"]>;
  };

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Var_Pop_Order_By =
  {
    KccaTinNo?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    RateableValue?: Maybe<Order_By>;
    RateableValueAdjusted?: Maybe<Order_By>;
  };

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Var_Samp_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_var_samp_fields";
    KccaTinNo?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    MatchScore?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    RateableValue?: Maybe<Scalars["Float"]>;
    RateableValueAdjusted?: Maybe<Scalars["Float"]>;
  };

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Var_Samp_Order_By =
  {
    KccaTinNo?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    RateableValue?: Maybe<Order_By>;
    RateableValueAdjusted?: Maybe<Order_By>;
  };

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Variance_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_variance_fields";
    KccaTinNo?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    MatchScore?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    RateableValue?: Maybe<Scalars["Float"]>;
    RateableValueAdjusted?: Maybe<Scalars["Float"]>;
  };

/** order by variance() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Variance_Order_By =
  {
    KccaTinNo?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    RateableValue?: Maybe<Order_By>;
    RateableValueAdjusted?: Maybe<Order_By>;
  };

/** aggregated selection of "rtcs_db.PH_rmatch_RMatchDetailed916" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_RMatchDetailed916" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_RMatchDetailed916" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rmatch_RMatchDetailed916" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_RMatchDetailed916" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916_avg_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed916" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Avg_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_RMatchDetailed916". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  AdjustedIncome?: Maybe<Float8_Comparison_Exp>;
  BlockNo?: Maybe<String_Comparison_Exp>;
  CountUra?: Maybe<Bigint_Comparison_Exp>;
  CustomerId?: Maybe<Bigint_Comparison_Exp>;
  DivisionId?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  GrossValue?: Maybe<String_Comparison_Exp>;
  HouseNo?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcEmp?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  KccaFirstName?: Maybe<String_Comparison_Exp>;
  KccaMiddleName?: Maybe<String_Comparison_Exp>;
  KccaSurname?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  MatchKccaUra?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Bigint_Comparison_Exp>;
  NumProperties?: Maybe<Bigint_Comparison_Exp>;
  ParishId?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyId?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatusId?: Maybe<String_Comparison_Exp>;
  PropertyTypeId?: Maybe<String_Comparison_Exp>;
  RateableValue?: Maybe<Float8_Comparison_Exp>;
  ScoreMatchAtributes?: Maybe<String_Comparison_Exp>;
  StreetId?: Maybe<String_Comparison_Exp>;
  SubPropertyTypeId?: Maybe<String_Comparison_Exp>;
  UraEmailId?: Maybe<String_Comparison_Exp>;
  UraFirstname?: Maybe<String_Comparison_Exp>;
  UraMatchingTins?: Maybe<String_Comparison_Exp>;
  UraMiddlename?: Maybe<String_Comparison_Exp>;
  UraMobileNumber?: Maybe<String_Comparison_Exp>;
  UraNationalId?: Maybe<String_Comparison_Exp>;
  UraSurname?: Maybe<String_Comparison_Exp>;
  VillageId?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_RMatchDetailed916" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Inc_Input = {
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_RMatchDetailed916" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916_max_fields";
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed916" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Max_Order_By = {
  Address?: Maybe<Order_By>;
  AdjustedIncome?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchKccaUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  ScoreMatchAtributes?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  UraEmailId?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraMatchingTins?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraMobileNumber?: Maybe<Order_By>;
  UraNationalId?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916_min_fields";
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed916" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Min_Order_By = {
  Address?: Maybe<Order_By>;
  AdjustedIncome?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchKccaUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  ScoreMatchAtributes?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  UraEmailId?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraMatchingTins?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraMobileNumber?: Maybe<Order_By>;
  UraNationalId?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_RMatchDetailed916" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_RMatchDetailed916" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_RMatchDetailed916" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Order_By = {
  Address?: Maybe<Order_By>;
  AdjustedIncome?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchKccaUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  ScoreMatchAtributes?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  UraEmailId?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraMatchingTins?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraMobileNumber?: Maybe<Order_By>;
  UraNationalId?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_RMatchDetailed916" */
export enum Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  AdjustedIncome = "AdjustedIncome",
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  CountUra = "CountUra",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  File = "File",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  GrossValue = "GrossValue",
  /** column name */
  HouseNo = "HouseNo",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcEmp = "IsIncomeSrcEmp",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  KccaFirstName = "KccaFirstName",
  /** column name */
  KccaMiddleName = "KccaMiddleName",
  /** column name */
  KccaSurname = "KccaSurname",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MatchKccaUra = "MatchKccaUra",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyId = "PropertyId",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  RateableValue = "RateableValue",
  /** column name */
  ScoreMatchAtributes = "ScoreMatchAtributes",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  SubPropertyTypeId = "SubPropertyTypeId",
  /** column name */
  UraEmailId = "UraEmailId",
  /** column name */
  UraFirstname = "UraFirstname",
  /** column name */
  UraMatchingTins = "UraMatchingTins",
  /** column name */
  UraMiddlename = "UraMiddlename",
  /** column name */
  UraMobileNumber = "UraMobileNumber",
  /** column name */
  UraNationalId = "UraNationalId",
  /** column name */
  UraSurname = "UraSurname",
  /** column name */
  VillageId = "VillageId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_RMatchDetailed916" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916_stddev_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed916" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Stddev_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916_stddev_pop_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed916" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Stddev_Pop_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916_stddev_samp_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed916" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Stddev_Samp_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916_sum_fields";
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed916" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Sum_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916_var_pop_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed916" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Var_Pop_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916_var_samp_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed916" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Var_Samp_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed916_variance_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed916" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Variance_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_RMatchDetailed937" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937 = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed937";
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaEmails?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaNiraMatchAtributes?: Maybe<Scalars["String"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["float8"]>;
  KccaNiraMatchScore?: Maybe<Scalars["float8"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchDobKccaUra?: Maybe<Scalars["String"]>;
  MatchDobNiraUra?: Maybe<Scalars["String"]>;
  MatchEmailKccaUra?: Maybe<Scalars["String"]>;
  MatchKccaDob?: Maybe<Scalars["String"]>;
  MatchKccaEmail?: Maybe<Scalars["String"]>;
  MatchKccaFirstName?: Maybe<Scalars["String"]>;
  MatchKccaLandline?: Maybe<Scalars["String"]>;
  MatchKccaMiddleName?: Maybe<Scalars["String"]>;
  MatchKccaMobile?: Maybe<Scalars["String"]>;
  MatchKccaMobile2?: Maybe<Scalars["String"]>;
  MatchKccaNin?: Maybe<Scalars["String"]>;
  MatchKccaSurname?: Maybe<Scalars["String"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchNinKccaUra?: Maybe<Scalars["String"]>;
  MatchNiraDob?: Maybe<Scalars["String"]>;
  MatchNiraFirstname?: Maybe<Scalars["String"]>;
  MatchNiraMobile?: Maybe<Scalars["String"]>;
  MatchNiraSurname?: Maybe<Scalars["String"]>;
  MatchPhoneKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchUraDob?: Maybe<Scalars["String"]>;
  MatchUraEmailId?: Maybe<Scalars["String"]>;
  MatchUraLandlineNumber?: Maybe<Scalars["String"]>;
  MatchUraMobileNumber?: Maybe<Scalars["String"]>;
  MatchUraMobileNumber1?: Maybe<Scalars["String"]>;
  MatchUraNationalId?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaFirstName?: Maybe<Scalars["String"]>;
  MatchedKccaMiddleName?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["float8"]>;
  MatchedKccaSurname?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["float8"]>;
  MatchedUraDob?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraNin?: Maybe<Scalars["String"]>;
  MatchedUraPhone?: Maybe<Scalars["float8"]>;
  MatchedUraSurname?: Maybe<Scalars["String"]>;
  NiraBirthcity?: Maybe<Scalars["String"]>;
  NiraDob?: Maybe<Scalars["timestamptz"]>;
  NiraEmail?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraMobile?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraDob?: Maybe<Scalars["timestamptz"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_RMatchDetailed937" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed937_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed937>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_RMatchDetailed937" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed937_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_RMatchDetailed937" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rmatch_RMatchDetailed937" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_RMatchDetailed937" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed937_avg_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["Float"]>;
  KccaNiraMatchScore?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MatchedKccaPhone?: Maybe<Scalars["Float"]>;
  MatchedNiraPhone?: Maybe<Scalars["Float"]>;
  MatchedUraPhone?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraTinNo?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed937" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Avg_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  KccaNiraMatchFeatCount?: Maybe<Order_By>;
  KccaNiraMatchScore?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraPhone?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_RMatchDetailed937". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  AdjustedIncome?: Maybe<Float8_Comparison_Exp>;
  BlockNo?: Maybe<String_Comparison_Exp>;
  CountUra?: Maybe<Bigint_Comparison_Exp>;
  CustomerId?: Maybe<Bigint_Comparison_Exp>;
  DivisionId?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  GrossValue?: Maybe<String_Comparison_Exp>;
  HouseNo?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcEmp?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  KccaDob?: Maybe<Timestamp_Comparison_Exp>;
  KccaEmail?: Maybe<String_Comparison_Exp>;
  KccaEmails?: Maybe<String_Comparison_Exp>;
  KccaFirstName?: Maybe<String_Comparison_Exp>;
  KccaLandline?: Maybe<String_Comparison_Exp>;
  KccaMiddleName?: Maybe<String_Comparison_Exp>;
  KccaMobile?: Maybe<String_Comparison_Exp>;
  KccaMobile2?: Maybe<String_Comparison_Exp>;
  KccaNin?: Maybe<String_Comparison_Exp>;
  KccaNiraMatchAtributes?: Maybe<String_Comparison_Exp>;
  KccaNiraMatchFeatCount?: Maybe<Float8_Comparison_Exp>;
  KccaNiraMatchScore?: Maybe<Float8_Comparison_Exp>;
  KccaSurname?: Maybe<String_Comparison_Exp>;
  KccaTin?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  MatchDobKccaUra?: Maybe<String_Comparison_Exp>;
  MatchDobNiraUra?: Maybe<String_Comparison_Exp>;
  MatchEmailKccaUra?: Maybe<String_Comparison_Exp>;
  MatchKccaDob?: Maybe<String_Comparison_Exp>;
  MatchKccaEmail?: Maybe<String_Comparison_Exp>;
  MatchKccaFirstName?: Maybe<String_Comparison_Exp>;
  MatchKccaLandline?: Maybe<String_Comparison_Exp>;
  MatchKccaMiddleName?: Maybe<String_Comparison_Exp>;
  MatchKccaMobile?: Maybe<String_Comparison_Exp>;
  MatchKccaMobile2?: Maybe<String_Comparison_Exp>;
  MatchKccaNin?: Maybe<String_Comparison_Exp>;
  MatchKccaSurname?: Maybe<String_Comparison_Exp>;
  MatchKccaUra?: Maybe<String_Comparison_Exp>;
  MatchNinKccaUra?: Maybe<String_Comparison_Exp>;
  MatchNiraDob?: Maybe<String_Comparison_Exp>;
  MatchNiraFirstname?: Maybe<String_Comparison_Exp>;
  MatchNiraMobile?: Maybe<String_Comparison_Exp>;
  MatchNiraSurname?: Maybe<String_Comparison_Exp>;
  MatchPhoneKccaUra?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Bigint_Comparison_Exp>;
  MatchUraDob?: Maybe<String_Comparison_Exp>;
  MatchUraEmailId?: Maybe<String_Comparison_Exp>;
  MatchUraLandlineNumber?: Maybe<String_Comparison_Exp>;
  MatchUraMobileNumber?: Maybe<String_Comparison_Exp>;
  MatchUraMobileNumber1?: Maybe<String_Comparison_Exp>;
  MatchUraNationalId?: Maybe<String_Comparison_Exp>;
  MatchedKccaDob?: Maybe<String_Comparison_Exp>;
  MatchedKccaEmail?: Maybe<String_Comparison_Exp>;
  MatchedKccaFirstName?: Maybe<String_Comparison_Exp>;
  MatchedKccaMiddleName?: Maybe<String_Comparison_Exp>;
  MatchedKccaNin?: Maybe<String_Comparison_Exp>;
  MatchedKccaPhone?: Maybe<Float8_Comparison_Exp>;
  MatchedKccaSurname?: Maybe<String_Comparison_Exp>;
  MatchedNiraDob?: Maybe<String_Comparison_Exp>;
  MatchedNiraNin?: Maybe<String_Comparison_Exp>;
  MatchedNiraPhone?: Maybe<Float8_Comparison_Exp>;
  MatchedUraDob?: Maybe<String_Comparison_Exp>;
  MatchedUraEmail?: Maybe<String_Comparison_Exp>;
  MatchedUraNin?: Maybe<String_Comparison_Exp>;
  MatchedUraPhone?: Maybe<Float8_Comparison_Exp>;
  MatchedUraSurname?: Maybe<String_Comparison_Exp>;
  NiraBirthcity?: Maybe<String_Comparison_Exp>;
  NiraDob?: Maybe<Timestamptz_Comparison_Exp>;
  NiraEmail?: Maybe<String_Comparison_Exp>;
  NiraFirstname?: Maybe<String_Comparison_Exp>;
  NiraMobile?: Maybe<String_Comparison_Exp>;
  NiraNin?: Maybe<String_Comparison_Exp>;
  NiraSurname?: Maybe<String_Comparison_Exp>;
  NumProperties?: Maybe<Float8_Comparison_Exp>;
  ParishId?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyId?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatusId?: Maybe<String_Comparison_Exp>;
  PropertyTypeId?: Maybe<String_Comparison_Exp>;
  RateableValue?: Maybe<Float8_Comparison_Exp>;
  ScoreMatchAtributes?: Maybe<String_Comparison_Exp>;
  StreetId?: Maybe<String_Comparison_Exp>;
  SubPropertyTypeId?: Maybe<String_Comparison_Exp>;
  UraDob?: Maybe<Timestamptz_Comparison_Exp>;
  UraEmailId?: Maybe<String_Comparison_Exp>;
  UraFirstname?: Maybe<String_Comparison_Exp>;
  UraMatchingTins?: Maybe<String_Comparison_Exp>;
  UraMiddlename?: Maybe<String_Comparison_Exp>;
  UraMobileNumber?: Maybe<String_Comparison_Exp>;
  UraNationalId?: Maybe<String_Comparison_Exp>;
  UraSurname?: Maybe<String_Comparison_Exp>;
  UraTinNo?: Maybe<Float8_Comparison_Exp>;
  VillageId?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_RMatchDetailed937" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Inc_Input = {
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["float8"]>;
  KccaNiraMatchScore?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchedKccaPhone?: Maybe<Scalars["float8"]>;
  MatchedNiraPhone?: Maybe<Scalars["float8"]>;
  MatchedUraPhone?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_RMatchDetailed937" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaEmails?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaNiraMatchAtributes?: Maybe<Scalars["String"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["float8"]>;
  KccaNiraMatchScore?: Maybe<Scalars["float8"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchDobKccaUra?: Maybe<Scalars["String"]>;
  MatchDobNiraUra?: Maybe<Scalars["String"]>;
  MatchEmailKccaUra?: Maybe<Scalars["String"]>;
  MatchKccaDob?: Maybe<Scalars["String"]>;
  MatchKccaEmail?: Maybe<Scalars["String"]>;
  MatchKccaFirstName?: Maybe<Scalars["String"]>;
  MatchKccaLandline?: Maybe<Scalars["String"]>;
  MatchKccaMiddleName?: Maybe<Scalars["String"]>;
  MatchKccaMobile?: Maybe<Scalars["String"]>;
  MatchKccaMobile2?: Maybe<Scalars["String"]>;
  MatchKccaNin?: Maybe<Scalars["String"]>;
  MatchKccaSurname?: Maybe<Scalars["String"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchNinKccaUra?: Maybe<Scalars["String"]>;
  MatchNiraDob?: Maybe<Scalars["String"]>;
  MatchNiraFirstname?: Maybe<Scalars["String"]>;
  MatchNiraMobile?: Maybe<Scalars["String"]>;
  MatchNiraSurname?: Maybe<Scalars["String"]>;
  MatchPhoneKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchUraDob?: Maybe<Scalars["String"]>;
  MatchUraEmailId?: Maybe<Scalars["String"]>;
  MatchUraLandlineNumber?: Maybe<Scalars["String"]>;
  MatchUraMobileNumber?: Maybe<Scalars["String"]>;
  MatchUraMobileNumber1?: Maybe<Scalars["String"]>;
  MatchUraNationalId?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaFirstName?: Maybe<Scalars["String"]>;
  MatchedKccaMiddleName?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["float8"]>;
  MatchedKccaSurname?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["float8"]>;
  MatchedUraDob?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraNin?: Maybe<Scalars["String"]>;
  MatchedUraPhone?: Maybe<Scalars["float8"]>;
  MatchedUraSurname?: Maybe<Scalars["String"]>;
  NiraBirthcity?: Maybe<Scalars["String"]>;
  NiraDob?: Maybe<Scalars["timestamptz"]>;
  NiraEmail?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraMobile?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraDob?: Maybe<Scalars["timestamptz"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed937_max_fields";
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaEmails?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaNiraMatchAtributes?: Maybe<Scalars["String"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["float8"]>;
  KccaNiraMatchScore?: Maybe<Scalars["float8"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchDobKccaUra?: Maybe<Scalars["String"]>;
  MatchDobNiraUra?: Maybe<Scalars["String"]>;
  MatchEmailKccaUra?: Maybe<Scalars["String"]>;
  MatchKccaDob?: Maybe<Scalars["String"]>;
  MatchKccaEmail?: Maybe<Scalars["String"]>;
  MatchKccaFirstName?: Maybe<Scalars["String"]>;
  MatchKccaLandline?: Maybe<Scalars["String"]>;
  MatchKccaMiddleName?: Maybe<Scalars["String"]>;
  MatchKccaMobile?: Maybe<Scalars["String"]>;
  MatchKccaMobile2?: Maybe<Scalars["String"]>;
  MatchKccaNin?: Maybe<Scalars["String"]>;
  MatchKccaSurname?: Maybe<Scalars["String"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchNinKccaUra?: Maybe<Scalars["String"]>;
  MatchNiraDob?: Maybe<Scalars["String"]>;
  MatchNiraFirstname?: Maybe<Scalars["String"]>;
  MatchNiraMobile?: Maybe<Scalars["String"]>;
  MatchNiraSurname?: Maybe<Scalars["String"]>;
  MatchPhoneKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchUraDob?: Maybe<Scalars["String"]>;
  MatchUraEmailId?: Maybe<Scalars["String"]>;
  MatchUraLandlineNumber?: Maybe<Scalars["String"]>;
  MatchUraMobileNumber?: Maybe<Scalars["String"]>;
  MatchUraMobileNumber1?: Maybe<Scalars["String"]>;
  MatchUraNationalId?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaFirstName?: Maybe<Scalars["String"]>;
  MatchedKccaMiddleName?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["float8"]>;
  MatchedKccaSurname?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["float8"]>;
  MatchedUraDob?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraNin?: Maybe<Scalars["String"]>;
  MatchedUraPhone?: Maybe<Scalars["float8"]>;
  MatchedUraSurname?: Maybe<Scalars["String"]>;
  NiraBirthcity?: Maybe<Scalars["String"]>;
  NiraDob?: Maybe<Scalars["timestamptz"]>;
  NiraEmail?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraMobile?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraDob?: Maybe<Scalars["timestamptz"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed937" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Max_Order_By = {
  Address?: Maybe<Order_By>;
  AdjustedIncome?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaDob?: Maybe<Order_By>;
  KccaEmail?: Maybe<Order_By>;
  KccaEmails?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaLandline?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaMobile?: Maybe<Order_By>;
  KccaMobile2?: Maybe<Order_By>;
  KccaNin?: Maybe<Order_By>;
  KccaNiraMatchAtributes?: Maybe<Order_By>;
  KccaNiraMatchFeatCount?: Maybe<Order_By>;
  KccaNiraMatchScore?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  KccaTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchDobKccaUra?: Maybe<Order_By>;
  MatchDobNiraUra?: Maybe<Order_By>;
  MatchEmailKccaUra?: Maybe<Order_By>;
  MatchKccaDob?: Maybe<Order_By>;
  MatchKccaEmail?: Maybe<Order_By>;
  MatchKccaFirstName?: Maybe<Order_By>;
  MatchKccaLandline?: Maybe<Order_By>;
  MatchKccaMiddleName?: Maybe<Order_By>;
  MatchKccaMobile?: Maybe<Order_By>;
  MatchKccaMobile2?: Maybe<Order_By>;
  MatchKccaNin?: Maybe<Order_By>;
  MatchKccaSurname?: Maybe<Order_By>;
  MatchKccaUra?: Maybe<Order_By>;
  MatchNinKccaUra?: Maybe<Order_By>;
  MatchNiraDob?: Maybe<Order_By>;
  MatchNiraFirstname?: Maybe<Order_By>;
  MatchNiraMobile?: Maybe<Order_By>;
  MatchNiraSurname?: Maybe<Order_By>;
  MatchPhoneKccaUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchUraDob?: Maybe<Order_By>;
  MatchUraEmailId?: Maybe<Order_By>;
  MatchUraLandlineNumber?: Maybe<Order_By>;
  MatchUraMobileNumber?: Maybe<Order_By>;
  MatchUraMobileNumber1?: Maybe<Order_By>;
  MatchUraNationalId?: Maybe<Order_By>;
  MatchedKccaDob?: Maybe<Order_By>;
  MatchedKccaEmail?: Maybe<Order_By>;
  MatchedKccaFirstName?: Maybe<Order_By>;
  MatchedKccaMiddleName?: Maybe<Order_By>;
  MatchedKccaNin?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedKccaSurname?: Maybe<Order_By>;
  MatchedNiraDob?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraDob?: Maybe<Order_By>;
  MatchedUraEmail?: Maybe<Order_By>;
  MatchedUraNin?: Maybe<Order_By>;
  MatchedUraPhone?: Maybe<Order_By>;
  MatchedUraSurname?: Maybe<Order_By>;
  NiraBirthcity?: Maybe<Order_By>;
  NiraDob?: Maybe<Order_By>;
  NiraEmail?: Maybe<Order_By>;
  NiraFirstname?: Maybe<Order_By>;
  NiraMobile?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NiraSurname?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  ScoreMatchAtributes?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  UraDob?: Maybe<Order_By>;
  UraEmailId?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraMatchingTins?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraMobileNumber?: Maybe<Order_By>;
  UraNationalId?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed937_min_fields";
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaEmails?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaNiraMatchAtributes?: Maybe<Scalars["String"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["float8"]>;
  KccaNiraMatchScore?: Maybe<Scalars["float8"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchDobKccaUra?: Maybe<Scalars["String"]>;
  MatchDobNiraUra?: Maybe<Scalars["String"]>;
  MatchEmailKccaUra?: Maybe<Scalars["String"]>;
  MatchKccaDob?: Maybe<Scalars["String"]>;
  MatchKccaEmail?: Maybe<Scalars["String"]>;
  MatchKccaFirstName?: Maybe<Scalars["String"]>;
  MatchKccaLandline?: Maybe<Scalars["String"]>;
  MatchKccaMiddleName?: Maybe<Scalars["String"]>;
  MatchKccaMobile?: Maybe<Scalars["String"]>;
  MatchKccaMobile2?: Maybe<Scalars["String"]>;
  MatchKccaNin?: Maybe<Scalars["String"]>;
  MatchKccaSurname?: Maybe<Scalars["String"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchNinKccaUra?: Maybe<Scalars["String"]>;
  MatchNiraDob?: Maybe<Scalars["String"]>;
  MatchNiraFirstname?: Maybe<Scalars["String"]>;
  MatchNiraMobile?: Maybe<Scalars["String"]>;
  MatchNiraSurname?: Maybe<Scalars["String"]>;
  MatchPhoneKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchUraDob?: Maybe<Scalars["String"]>;
  MatchUraEmailId?: Maybe<Scalars["String"]>;
  MatchUraLandlineNumber?: Maybe<Scalars["String"]>;
  MatchUraMobileNumber?: Maybe<Scalars["String"]>;
  MatchUraMobileNumber1?: Maybe<Scalars["String"]>;
  MatchUraNationalId?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaFirstName?: Maybe<Scalars["String"]>;
  MatchedKccaMiddleName?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["float8"]>;
  MatchedKccaSurname?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["float8"]>;
  MatchedUraDob?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraNin?: Maybe<Scalars["String"]>;
  MatchedUraPhone?: Maybe<Scalars["float8"]>;
  MatchedUraSurname?: Maybe<Scalars["String"]>;
  NiraBirthcity?: Maybe<Scalars["String"]>;
  NiraDob?: Maybe<Scalars["timestamptz"]>;
  NiraEmail?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraMobile?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraDob?: Maybe<Scalars["timestamptz"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed937" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Min_Order_By = {
  Address?: Maybe<Order_By>;
  AdjustedIncome?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaDob?: Maybe<Order_By>;
  KccaEmail?: Maybe<Order_By>;
  KccaEmails?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaLandline?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaMobile?: Maybe<Order_By>;
  KccaMobile2?: Maybe<Order_By>;
  KccaNin?: Maybe<Order_By>;
  KccaNiraMatchAtributes?: Maybe<Order_By>;
  KccaNiraMatchFeatCount?: Maybe<Order_By>;
  KccaNiraMatchScore?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  KccaTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchDobKccaUra?: Maybe<Order_By>;
  MatchDobNiraUra?: Maybe<Order_By>;
  MatchEmailKccaUra?: Maybe<Order_By>;
  MatchKccaDob?: Maybe<Order_By>;
  MatchKccaEmail?: Maybe<Order_By>;
  MatchKccaFirstName?: Maybe<Order_By>;
  MatchKccaLandline?: Maybe<Order_By>;
  MatchKccaMiddleName?: Maybe<Order_By>;
  MatchKccaMobile?: Maybe<Order_By>;
  MatchKccaMobile2?: Maybe<Order_By>;
  MatchKccaNin?: Maybe<Order_By>;
  MatchKccaSurname?: Maybe<Order_By>;
  MatchKccaUra?: Maybe<Order_By>;
  MatchNinKccaUra?: Maybe<Order_By>;
  MatchNiraDob?: Maybe<Order_By>;
  MatchNiraFirstname?: Maybe<Order_By>;
  MatchNiraMobile?: Maybe<Order_By>;
  MatchNiraSurname?: Maybe<Order_By>;
  MatchPhoneKccaUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchUraDob?: Maybe<Order_By>;
  MatchUraEmailId?: Maybe<Order_By>;
  MatchUraLandlineNumber?: Maybe<Order_By>;
  MatchUraMobileNumber?: Maybe<Order_By>;
  MatchUraMobileNumber1?: Maybe<Order_By>;
  MatchUraNationalId?: Maybe<Order_By>;
  MatchedKccaDob?: Maybe<Order_By>;
  MatchedKccaEmail?: Maybe<Order_By>;
  MatchedKccaFirstName?: Maybe<Order_By>;
  MatchedKccaMiddleName?: Maybe<Order_By>;
  MatchedKccaNin?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedKccaSurname?: Maybe<Order_By>;
  MatchedNiraDob?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraDob?: Maybe<Order_By>;
  MatchedUraEmail?: Maybe<Order_By>;
  MatchedUraNin?: Maybe<Order_By>;
  MatchedUraPhone?: Maybe<Order_By>;
  MatchedUraSurname?: Maybe<Order_By>;
  NiraBirthcity?: Maybe<Order_By>;
  NiraDob?: Maybe<Order_By>;
  NiraEmail?: Maybe<Order_By>;
  NiraFirstname?: Maybe<Order_By>;
  NiraMobile?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NiraSurname?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  ScoreMatchAtributes?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  UraDob?: Maybe<Order_By>;
  UraEmailId?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraMatchingTins?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraMobileNumber?: Maybe<Order_By>;
  UraNationalId?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_RMatchDetailed937" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed937_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed937>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_RMatchDetailed937" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_RMatchDetailed937" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Order_By = {
  Address?: Maybe<Order_By>;
  AdjustedIncome?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaDob?: Maybe<Order_By>;
  KccaEmail?: Maybe<Order_By>;
  KccaEmails?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaLandline?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaMobile?: Maybe<Order_By>;
  KccaMobile2?: Maybe<Order_By>;
  KccaNin?: Maybe<Order_By>;
  KccaNiraMatchAtributes?: Maybe<Order_By>;
  KccaNiraMatchFeatCount?: Maybe<Order_By>;
  KccaNiraMatchScore?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  KccaTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchDobKccaUra?: Maybe<Order_By>;
  MatchDobNiraUra?: Maybe<Order_By>;
  MatchEmailKccaUra?: Maybe<Order_By>;
  MatchKccaDob?: Maybe<Order_By>;
  MatchKccaEmail?: Maybe<Order_By>;
  MatchKccaFirstName?: Maybe<Order_By>;
  MatchKccaLandline?: Maybe<Order_By>;
  MatchKccaMiddleName?: Maybe<Order_By>;
  MatchKccaMobile?: Maybe<Order_By>;
  MatchKccaMobile2?: Maybe<Order_By>;
  MatchKccaNin?: Maybe<Order_By>;
  MatchKccaSurname?: Maybe<Order_By>;
  MatchKccaUra?: Maybe<Order_By>;
  MatchNinKccaUra?: Maybe<Order_By>;
  MatchNiraDob?: Maybe<Order_By>;
  MatchNiraFirstname?: Maybe<Order_By>;
  MatchNiraMobile?: Maybe<Order_By>;
  MatchNiraSurname?: Maybe<Order_By>;
  MatchPhoneKccaUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchUraDob?: Maybe<Order_By>;
  MatchUraEmailId?: Maybe<Order_By>;
  MatchUraLandlineNumber?: Maybe<Order_By>;
  MatchUraMobileNumber?: Maybe<Order_By>;
  MatchUraMobileNumber1?: Maybe<Order_By>;
  MatchUraNationalId?: Maybe<Order_By>;
  MatchedKccaDob?: Maybe<Order_By>;
  MatchedKccaEmail?: Maybe<Order_By>;
  MatchedKccaFirstName?: Maybe<Order_By>;
  MatchedKccaMiddleName?: Maybe<Order_By>;
  MatchedKccaNin?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedKccaSurname?: Maybe<Order_By>;
  MatchedNiraDob?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraDob?: Maybe<Order_By>;
  MatchedUraEmail?: Maybe<Order_By>;
  MatchedUraNin?: Maybe<Order_By>;
  MatchedUraPhone?: Maybe<Order_By>;
  MatchedUraSurname?: Maybe<Order_By>;
  NiraBirthcity?: Maybe<Order_By>;
  NiraDob?: Maybe<Order_By>;
  NiraEmail?: Maybe<Order_By>;
  NiraFirstname?: Maybe<Order_By>;
  NiraMobile?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NiraSurname?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  ScoreMatchAtributes?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  UraDob?: Maybe<Order_By>;
  UraEmailId?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraMatchingTins?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraMobileNumber?: Maybe<Order_By>;
  UraNationalId?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_RMatchDetailed937" */
export enum Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  AdjustedIncome = "AdjustedIncome",
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  CountUra = "CountUra",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  File = "File",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  GrossValue = "GrossValue",
  /** column name */
  HouseNo = "HouseNo",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcEmp = "IsIncomeSrcEmp",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  KccaDob = "KccaDob",
  /** column name */
  KccaEmail = "KccaEmail",
  /** column name */
  KccaEmails = "KccaEmails",
  /** column name */
  KccaFirstName = "KccaFirstName",
  /** column name */
  KccaLandline = "KccaLandline",
  /** column name */
  KccaMiddleName = "KccaMiddleName",
  /** column name */
  KccaMobile = "KccaMobile",
  /** column name */
  KccaMobile2 = "KccaMobile2",
  /** column name */
  KccaNin = "KccaNin",
  /** column name */
  KccaNiraMatchAtributes = "KccaNiraMatchAtributes",
  /** column name */
  KccaNiraMatchFeatCount = "KccaNiraMatchFeatCount",
  /** column name */
  KccaNiraMatchScore = "KccaNiraMatchScore",
  /** column name */
  KccaSurname = "KccaSurname",
  /** column name */
  KccaTin = "KccaTin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MatchDobKccaUra = "MatchDobKccaUra",
  /** column name */
  MatchDobNiraUra = "MatchDobNiraUra",
  /** column name */
  MatchEmailKccaUra = "MatchEmailKccaUra",
  /** column name */
  MatchKccaDob = "MatchKccaDob",
  /** column name */
  MatchKccaEmail = "MatchKccaEmail",
  /** column name */
  MatchKccaFirstName = "MatchKccaFirstName",
  /** column name */
  MatchKccaLandline = "MatchKccaLandline",
  /** column name */
  MatchKccaMiddleName = "MatchKccaMiddleName",
  /** column name */
  MatchKccaMobile = "MatchKccaMobile",
  /** column name */
  MatchKccaMobile2 = "MatchKccaMobile2",
  /** column name */
  MatchKccaNin = "MatchKccaNin",
  /** column name */
  MatchKccaSurname = "MatchKccaSurname",
  /** column name */
  MatchKccaUra = "MatchKccaUra",
  /** column name */
  MatchNinKccaUra = "MatchNinKccaUra",
  /** column name */
  MatchNiraDob = "MatchNiraDob",
  /** column name */
  MatchNiraFirstname = "MatchNiraFirstname",
  /** column name */
  MatchNiraMobile = "MatchNiraMobile",
  /** column name */
  MatchNiraSurname = "MatchNiraSurname",
  /** column name */
  MatchPhoneKccaUra = "MatchPhoneKccaUra",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MatchUraDob = "MatchUraDob",
  /** column name */
  MatchUraEmailId = "MatchUraEmailId",
  /** column name */
  MatchUraLandlineNumber = "MatchUraLandlineNumber",
  /** column name */
  MatchUraMobileNumber = "MatchUraMobileNumber",
  /** column name */
  MatchUraMobileNumber1 = "MatchUraMobileNumber1",
  /** column name */
  MatchUraNationalId = "MatchUraNationalId",
  /** column name */
  MatchedKccaDob = "MatchedKccaDob",
  /** column name */
  MatchedKccaEmail = "MatchedKccaEmail",
  /** column name */
  MatchedKccaFirstName = "MatchedKccaFirstName",
  /** column name */
  MatchedKccaMiddleName = "MatchedKccaMiddleName",
  /** column name */
  MatchedKccaNin = "MatchedKccaNin",
  /** column name */
  MatchedKccaPhone = "MatchedKccaPhone",
  /** column name */
  MatchedKccaSurname = "MatchedKccaSurname",
  /** column name */
  MatchedNiraDob = "MatchedNiraDob",
  /** column name */
  MatchedNiraNin = "MatchedNiraNin",
  /** column name */
  MatchedNiraPhone = "MatchedNiraPhone",
  /** column name */
  MatchedUraDob = "MatchedUraDob",
  /** column name */
  MatchedUraEmail = "MatchedUraEmail",
  /** column name */
  MatchedUraNin = "MatchedUraNin",
  /** column name */
  MatchedUraPhone = "MatchedUraPhone",
  /** column name */
  MatchedUraSurname = "MatchedUraSurname",
  /** column name */
  NiraBirthcity = "NiraBirthcity",
  /** column name */
  NiraDob = "NiraDob",
  /** column name */
  NiraEmail = "NiraEmail",
  /** column name */
  NiraFirstname = "NiraFirstname",
  /** column name */
  NiraMobile = "NiraMobile",
  /** column name */
  NiraNin = "NiraNin",
  /** column name */
  NiraSurname = "NiraSurname",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyId = "PropertyId",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  RateableValue = "RateableValue",
  /** column name */
  ScoreMatchAtributes = "ScoreMatchAtributes",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  SubPropertyTypeId = "SubPropertyTypeId",
  /** column name */
  UraDob = "UraDob",
  /** column name */
  UraEmailId = "UraEmailId",
  /** column name */
  UraFirstname = "UraFirstname",
  /** column name */
  UraMatchingTins = "UraMatchingTins",
  /** column name */
  UraMiddlename = "UraMiddlename",
  /** column name */
  UraMobileNumber = "UraMobileNumber",
  /** column name */
  UraNationalId = "UraNationalId",
  /** column name */
  UraSurname = "UraSurname",
  /** column name */
  UraTinNo = "UraTinNo",
  /** column name */
  VillageId = "VillageId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_RMatchDetailed937" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaEmails?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaNiraMatchAtributes?: Maybe<Scalars["String"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["float8"]>;
  KccaNiraMatchScore?: Maybe<Scalars["float8"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchDobKccaUra?: Maybe<Scalars["String"]>;
  MatchDobNiraUra?: Maybe<Scalars["String"]>;
  MatchEmailKccaUra?: Maybe<Scalars["String"]>;
  MatchKccaDob?: Maybe<Scalars["String"]>;
  MatchKccaEmail?: Maybe<Scalars["String"]>;
  MatchKccaFirstName?: Maybe<Scalars["String"]>;
  MatchKccaLandline?: Maybe<Scalars["String"]>;
  MatchKccaMiddleName?: Maybe<Scalars["String"]>;
  MatchKccaMobile?: Maybe<Scalars["String"]>;
  MatchKccaMobile2?: Maybe<Scalars["String"]>;
  MatchKccaNin?: Maybe<Scalars["String"]>;
  MatchKccaSurname?: Maybe<Scalars["String"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchNinKccaUra?: Maybe<Scalars["String"]>;
  MatchNiraDob?: Maybe<Scalars["String"]>;
  MatchNiraFirstname?: Maybe<Scalars["String"]>;
  MatchNiraMobile?: Maybe<Scalars["String"]>;
  MatchNiraSurname?: Maybe<Scalars["String"]>;
  MatchPhoneKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchUraDob?: Maybe<Scalars["String"]>;
  MatchUraEmailId?: Maybe<Scalars["String"]>;
  MatchUraLandlineNumber?: Maybe<Scalars["String"]>;
  MatchUraMobileNumber?: Maybe<Scalars["String"]>;
  MatchUraMobileNumber1?: Maybe<Scalars["String"]>;
  MatchUraNationalId?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaFirstName?: Maybe<Scalars["String"]>;
  MatchedKccaMiddleName?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["float8"]>;
  MatchedKccaSurname?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["float8"]>;
  MatchedUraDob?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraNin?: Maybe<Scalars["String"]>;
  MatchedUraPhone?: Maybe<Scalars["float8"]>;
  MatchedUraSurname?: Maybe<Scalars["String"]>;
  NiraBirthcity?: Maybe<Scalars["String"]>;
  NiraDob?: Maybe<Scalars["timestamptz"]>;
  NiraEmail?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraMobile?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraDob?: Maybe<Scalars["timestamptz"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed937_stddev_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["Float"]>;
  KccaNiraMatchScore?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MatchedKccaPhone?: Maybe<Scalars["Float"]>;
  MatchedNiraPhone?: Maybe<Scalars["Float"]>;
  MatchedUraPhone?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraTinNo?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed937" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Stddev_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  KccaNiraMatchFeatCount?: Maybe<Order_By>;
  KccaNiraMatchScore?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraPhone?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed937_stddev_pop_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["Float"]>;
  KccaNiraMatchScore?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MatchedKccaPhone?: Maybe<Scalars["Float"]>;
  MatchedNiraPhone?: Maybe<Scalars["Float"]>;
  MatchedUraPhone?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraTinNo?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed937" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Stddev_Pop_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  KccaNiraMatchFeatCount?: Maybe<Order_By>;
  KccaNiraMatchScore?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraPhone?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed937_stddev_samp_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["Float"]>;
  KccaNiraMatchScore?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MatchedKccaPhone?: Maybe<Scalars["Float"]>;
  MatchedNiraPhone?: Maybe<Scalars["Float"]>;
  MatchedUraPhone?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraTinNo?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed937" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Stddev_Samp_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  KccaNiraMatchFeatCount?: Maybe<Order_By>;
  KccaNiraMatchScore?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraPhone?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed937_sum_fields";
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["float8"]>;
  KccaNiraMatchScore?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchedKccaPhone?: Maybe<Scalars["float8"]>;
  MatchedNiraPhone?: Maybe<Scalars["float8"]>;
  MatchedUraPhone?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed937" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Sum_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  KccaNiraMatchFeatCount?: Maybe<Order_By>;
  KccaNiraMatchScore?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraPhone?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed937_var_pop_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["Float"]>;
  KccaNiraMatchScore?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MatchedKccaPhone?: Maybe<Scalars["Float"]>;
  MatchedNiraPhone?: Maybe<Scalars["Float"]>;
  MatchedUraPhone?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraTinNo?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed937" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Var_Pop_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  KccaNiraMatchFeatCount?: Maybe<Order_By>;
  KccaNiraMatchScore?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraPhone?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed937_var_samp_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["Float"]>;
  KccaNiraMatchScore?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MatchedKccaPhone?: Maybe<Scalars["Float"]>;
  MatchedNiraPhone?: Maybe<Scalars["Float"]>;
  MatchedUraPhone?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraTinNo?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed937" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Var_Samp_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  KccaNiraMatchFeatCount?: Maybe<Order_By>;
  KccaNiraMatchScore?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraPhone?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchDetailed937_variance_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["Float"]>;
  KccaNiraMatchScore?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MatchedKccaPhone?: Maybe<Scalars["Float"]>;
  MatchedNiraPhone?: Maybe<Scalars["Float"]>;
  MatchedUraPhone?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraTinNo?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_RMatchDetailed937" */
export type Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Variance_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  KccaNiraMatchFeatCount?: Maybe<Order_By>;
  KccaNiraMatchScore?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraPhone?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperties";
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossvalue?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Rid?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperties_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperties_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperties_avg_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Avg_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_RMatchKccaProperties". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  Blockno?: Maybe<String_Comparison_Exp>;
  Customerid?: Maybe<Bigint_Comparison_Exp>;
  Divisionid?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Flatno?: Maybe<String_Comparison_Exp>;
  Grossvalue?: Maybe<String_Comparison_Exp>;
  Houseno?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  Parishid?: Maybe<Float8_Comparison_Exp>;
  Plotno?: Maybe<String_Comparison_Exp>;
  Propertyno?: Maybe<String_Comparison_Exp>;
  Propertyrentedstatusid?: Maybe<Float8_Comparison_Exp>;
  Propertytypeid?: Maybe<Float8_Comparison_Exp>;
  Rateablevalue?: Maybe<Float8_Comparison_Exp>;
  Rateablevalue2?: Maybe<Float8_Comparison_Exp>;
  Rid?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Streetid?: Maybe<String_Comparison_Exp>;
  Subpropertytypeid?: Maybe<Float8_Comparison_Exp>;
  Villageid?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Inc_Input = {
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossvalue?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Rid?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperties_max_fields";
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossvalue?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Rid?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Max_Order_By = {
  Address?: Maybe<Order_By>;
  Blockno?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Houseno?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Rid?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperties_min_fields";
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossvalue?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Rid?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Min_Order_By = {
  Address?: Maybe<Order_By>;
  Blockno?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Houseno?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Rid?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperties_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Order_By = {
  Address?: Maybe<Order_By>;
  Blockno?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Houseno?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Rid?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_RMatchKccaProperties" */
export enum Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  Blockno = "Blockno",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  Divisionid = "Divisionid",
  /** column name */
  File = "File",
  /** column name */
  Flatno = "Flatno",
  /** column name */
  Grossvalue = "Grossvalue",
  /** column name */
  Houseno = "Houseno",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  Parishid = "Parishid",
  /** column name */
  Plotno = "Plotno",
  /** column name */
  Propertyno = "Propertyno",
  /** column name */
  Propertyrentedstatusid = "Propertyrentedstatusid",
  /** column name */
  Propertytypeid = "Propertytypeid",
  /** column name */
  Rateablevalue = "Rateablevalue",
  /** column name */
  Rateablevalue2 = "Rateablevalue2",
  /** column name */
  Rid = "Rid",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Streetid = "Streetid",
  /** column name */
  Subpropertytypeid = "Subpropertytypeid",
  /** column name */
  Villageid = "Villageid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossvalue?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Rid?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperties_stddev_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Stddev_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperties_stddev_pop_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Stddev_Pop_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperties_stddev_samp_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Stddev_Samp_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperties_sum_fields";
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Sum_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperties_var_pop_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Var_Pop_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperties_var_samp_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Var_Samp_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperties_variance_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Variance_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperty";
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossvalue?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Rid?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperty_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperty_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperty_avg_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Avg_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_RMatchKccaProperty". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  Blockno?: Maybe<String_Comparison_Exp>;
  Customerid?: Maybe<Bigint_Comparison_Exp>;
  Divisionid?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Flatno?: Maybe<String_Comparison_Exp>;
  Grossvalue?: Maybe<String_Comparison_Exp>;
  Houseno?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  Parishid?: Maybe<Float8_Comparison_Exp>;
  Plotno?: Maybe<String_Comparison_Exp>;
  Propertyno?: Maybe<String_Comparison_Exp>;
  Propertyrentedstatusid?: Maybe<Float8_Comparison_Exp>;
  Propertytypeid?: Maybe<Float8_Comparison_Exp>;
  Rateablevalue?: Maybe<Float8_Comparison_Exp>;
  Rateablevalue2?: Maybe<Float8_Comparison_Exp>;
  Rid?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Streetid?: Maybe<String_Comparison_Exp>;
  Subpropertytypeid?: Maybe<Float8_Comparison_Exp>;
  Villageid?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Inc_Input = {
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossvalue?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Rid?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperty_max_fields";
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossvalue?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Rid?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Max_Order_By = {
  Address?: Maybe<Order_By>;
  Blockno?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Houseno?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Rid?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperty_min_fields";
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossvalue?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Rid?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Min_Order_By = {
  Address?: Maybe<Order_By>;
  Blockno?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Houseno?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Rid?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperty_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Order_By = {
  Address?: Maybe<Order_By>;
  Blockno?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Houseno?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Rid?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_RMatchKccaProperty" */
export enum Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  Blockno = "Blockno",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  Divisionid = "Divisionid",
  /** column name */
  File = "File",
  /** column name */
  Flatno = "Flatno",
  /** column name */
  Grossvalue = "Grossvalue",
  /** column name */
  Houseno = "Houseno",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  Parishid = "Parishid",
  /** column name */
  Plotno = "Plotno",
  /** column name */
  Propertyno = "Propertyno",
  /** column name */
  Propertyrentedstatusid = "Propertyrentedstatusid",
  /** column name */
  Propertytypeid = "Propertytypeid",
  /** column name */
  Rateablevalue = "Rateablevalue",
  /** column name */
  Rateablevalue2 = "Rateablevalue2",
  /** column name */
  Rid = "Rid",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Streetid = "Streetid",
  /** column name */
  Subpropertytypeid = "Subpropertytypeid",
  /** column name */
  Villageid = "Villageid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossvalue?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Rid?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperty_stddev_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Stddev_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperty_stddev_pop_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Stddev_Pop_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperty_stddev_samp_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Stddev_Samp_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperty_sum_fields";
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Sum_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperty_var_pop_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Var_Pop_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperty_var_samp_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Var_Samp_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchKccaProperty_variance_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Variance_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903 = {
  __typename?: "rtcs_db_PH_rmatch_RMatchNonIndividual903";
  AlgorithmMatchCategory?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchConfidence?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_RMatchNonIndividual903_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchNonIndividual903_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchNonIndividual903_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
  MatchConfidence?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Avg_Order_By = {
  Line?: Maybe<Order_By>;
  MatchConfidence?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_RMatchNonIndividual903". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Bool_Exp = {
  AlgorithmMatchCategory?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FinalMatchCategory?: Maybe<String_Comparison_Exp>;
  KccaCustomerId?: Maybe<String_Comparison_Exp>;
  KccaName?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MatchConfidence?: Maybe<Bigint_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  UraName?: Maybe<String_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
  MatchConfidence?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Insert_Input = {
  AlgorithmMatchCategory?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchConfidence?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchNonIndividual903_max_fields";
  AlgorithmMatchCategory?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchConfidence?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Max_Order_By = {
  AlgorithmMatchCategory?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinalMatchCategory?: Maybe<Order_By>;
  KccaCustomerId?: Maybe<Order_By>;
  KccaName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchConfidence?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  UraName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchNonIndividual903_min_fields";
  AlgorithmMatchCategory?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchConfidence?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Min_Order_By = {
  AlgorithmMatchCategory?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinalMatchCategory?: Maybe<Order_By>;
  KccaCustomerId?: Maybe<Order_By>;
  KccaName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchConfidence?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  UraName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_RMatchNonIndividual903_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Order_By = {
  AlgorithmMatchCategory?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinalMatchCategory?: Maybe<Order_By>;
  KccaCustomerId?: Maybe<Order_By>;
  KccaName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchConfidence?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  UraName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
export enum Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Select_Column {
  /** column name */
  AlgorithmMatchCategory = "AlgorithmMatchCategory",
  /** column name */
  File = "File",
  /** column name */
  FinalMatchCategory = "FinalMatchCategory",
  /** column name */
  KccaCustomerId = "KccaCustomerId",
  /** column name */
  KccaName = "KccaName",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MatchConfidence = "MatchConfidence",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  UraName = "UraName",
  /** column name */
  UraTinNo = "UraTinNo",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Set_Input = {
  AlgorithmMatchCategory?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchConfidence?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchNonIndividual903_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
  MatchConfidence?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
  MatchConfidence?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchNonIndividual903_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  MatchConfidence?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  MatchConfidence?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchNonIndividual903_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  MatchConfidence?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  MatchConfidence?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchNonIndividual903_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
  MatchConfidence?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Sum_Order_By = {
  Line?: Maybe<Order_By>;
  MatchConfidence?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchNonIndividual903_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  MatchConfidence?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  MatchConfidence?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchNonIndividual903_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  MatchConfidence?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  MatchConfidence?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchNonIndividual903_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
  MatchConfidence?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Variance_Order_By = {
  Line?: Maybe<Order_By>;
  MatchConfidence?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_RMatchProperties1115" */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115 = {
  __typename?: "rtcs_db_PH_rmatch_RMatchProperties1115";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CustomerName?: Maybe<Scalars["bigint"]>;
  Division?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  MatchResult?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["String"]>;
  MdaLatitude?: Maybe<Scalars["String"]>;
  MdaLongitude?: Maybe<Scalars["String"]>;
  ModifyDate?: Maybe<Scalars["String"]>;
  NwscCustomerId?: Maybe<Scalars["bigint"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  NwscCustomerReference?: Maybe<Scalars["bigint"]>;
  NwscLatitude?: Maybe<Scalars["String"]>;
  NwscLongitude?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RentedStatus?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_RMatchProperties1115" */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_RMatchProperties1115_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_RMatchProperties1115>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_RMatchProperties1115" */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchProperties1115_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_RMatchProperties1115" */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rmatch_RMatchProperties1115" */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_RMatchProperties1115" */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchProperties1115_avg_fields";
  CustomerName?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  NwscCustomerReference?: Maybe<Scalars["Float"]>;
  RentedStatus?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_RMatchProperties1115" */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Avg_Order_By = {
  CustomerName?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerReference?: Maybe<Order_By>;
  RentedStatus?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_RMatchProperties1115". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  BlockNo?: Maybe<String_Comparison_Exp>;
  CustomerName?: Maybe<Bigint_Comparison_Exp>;
  Division?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  HouseNo?: Maybe<String_Comparison_Exp>;
  MatchResult?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<String_Comparison_Exp>;
  MdaLatitude?: Maybe<String_Comparison_Exp>;
  MdaLongitude?: Maybe<String_Comparison_Exp>;
  ModifyDate?: Maybe<String_Comparison_Exp>;
  NwscCustomerId?: Maybe<Bigint_Comparison_Exp>;
  NwscCustomerName?: Maybe<String_Comparison_Exp>;
  NwscCustomerReference?: Maybe<Bigint_Comparison_Exp>;
  NwscLatitude?: Maybe<String_Comparison_Exp>;
  NwscLongitude?: Maybe<String_Comparison_Exp>;
  Parish?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyId?: Maybe<String_Comparison_Exp>;
  PropertyType?: Maybe<String_Comparison_Exp>;
  RentedStatus?: Maybe<Bigint_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Street?: Maybe<String_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_RMatchProperties1115" */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Inc_Input = {
  CustomerName?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["bigint"]>;
  NwscCustomerReference?: Maybe<Scalars["bigint"]>;
  RentedStatus?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_RMatchProperties1115" */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CustomerName?: Maybe<Scalars["bigint"]>;
  Division?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  MatchResult?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["String"]>;
  MdaLatitude?: Maybe<Scalars["String"]>;
  MdaLongitude?: Maybe<Scalars["String"]>;
  ModifyDate?: Maybe<Scalars["String"]>;
  NwscCustomerId?: Maybe<Scalars["bigint"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  NwscCustomerReference?: Maybe<Scalars["bigint"]>;
  NwscLatitude?: Maybe<Scalars["String"]>;
  NwscLongitude?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RentedStatus?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchProperties1115_max_fields";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CustomerName?: Maybe<Scalars["bigint"]>;
  Division?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  MatchResult?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["String"]>;
  MdaLatitude?: Maybe<Scalars["String"]>;
  MdaLongitude?: Maybe<Scalars["String"]>;
  ModifyDate?: Maybe<Scalars["String"]>;
  NwscCustomerId?: Maybe<Scalars["bigint"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  NwscCustomerReference?: Maybe<Scalars["bigint"]>;
  NwscLatitude?: Maybe<Scalars["String"]>;
  NwscLongitude?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RentedStatus?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_RMatchProperties1115" */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Max_Order_By = {
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CustomerName?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  MatchResult?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MdaLatitude?: Maybe<Order_By>;
  MdaLongitude?: Maybe<Order_By>;
  ModifyDate?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerName?: Maybe<Order_By>;
  NwscCustomerReference?: Maybe<Order_By>;
  NwscLatitude?: Maybe<Order_By>;
  NwscLongitude?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  RentedStatus?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchProperties1115_min_fields";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CustomerName?: Maybe<Scalars["bigint"]>;
  Division?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  MatchResult?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["String"]>;
  MdaLatitude?: Maybe<Scalars["String"]>;
  MdaLongitude?: Maybe<Scalars["String"]>;
  ModifyDate?: Maybe<Scalars["String"]>;
  NwscCustomerId?: Maybe<Scalars["bigint"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  NwscCustomerReference?: Maybe<Scalars["bigint"]>;
  NwscLatitude?: Maybe<Scalars["String"]>;
  NwscLongitude?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RentedStatus?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_RMatchProperties1115" */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Min_Order_By = {
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CustomerName?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  MatchResult?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MdaLatitude?: Maybe<Order_By>;
  MdaLongitude?: Maybe<Order_By>;
  ModifyDate?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerName?: Maybe<Order_By>;
  NwscCustomerReference?: Maybe<Order_By>;
  NwscLatitude?: Maybe<Order_By>;
  NwscLongitude?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  RentedStatus?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_RMatchProperties1115" */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_RMatchProperties1115_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_RMatchProperties1115>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_RMatchProperties1115" */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_RMatchProperties1115" */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Order_By = {
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CustomerName?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  MatchResult?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MdaLatitude?: Maybe<Order_By>;
  MdaLongitude?: Maybe<Order_By>;
  ModifyDate?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerName?: Maybe<Order_By>;
  NwscCustomerReference?: Maybe<Order_By>;
  NwscLatitude?: Maybe<Order_By>;
  NwscLongitude?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  RentedStatus?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_RMatchProperties1115" */
export enum Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  CustomerName = "CustomerName",
  /** column name */
  Division = "Division",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  HouseNo = "HouseNo",
  /** column name */
  MatchResult = "MatchResult",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MdaLatitude = "MdaLatitude",
  /** column name */
  MdaLongitude = "MdaLongitude",
  /** column name */
  ModifyDate = "ModifyDate",
  /** column name */
  NwscCustomerId = "NwscCustomerId",
  /** column name */
  NwscCustomerName = "NwscCustomerName",
  /** column name */
  NwscCustomerReference = "NwscCustomerReference",
  /** column name */
  NwscLatitude = "NwscLatitude",
  /** column name */
  NwscLongitude = "NwscLongitude",
  /** column name */
  Parish = "Parish",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyId = "PropertyId",
  /** column name */
  PropertyType = "PropertyType",
  /** column name */
  RentedStatus = "RentedStatus",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Street = "Street",
  /** column name */
  Village = "Village",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_RMatchProperties1115" */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CustomerName?: Maybe<Scalars["bigint"]>;
  Division?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  MatchResult?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["String"]>;
  MdaLatitude?: Maybe<Scalars["String"]>;
  MdaLongitude?: Maybe<Scalars["String"]>;
  ModifyDate?: Maybe<Scalars["String"]>;
  NwscCustomerId?: Maybe<Scalars["bigint"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  NwscCustomerReference?: Maybe<Scalars["bigint"]>;
  NwscLatitude?: Maybe<Scalars["String"]>;
  NwscLongitude?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RentedStatus?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchProperties1115_stddev_fields";
  CustomerName?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  NwscCustomerReference?: Maybe<Scalars["Float"]>;
  RentedStatus?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_RMatchProperties1115" */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Stddev_Order_By = {
  CustomerName?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerReference?: Maybe<Order_By>;
  RentedStatus?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchProperties1115_stddev_pop_fields";
  CustomerName?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  NwscCustomerReference?: Maybe<Scalars["Float"]>;
  RentedStatus?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_RMatchProperties1115" */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Stddev_Pop_Order_By = {
  CustomerName?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerReference?: Maybe<Order_By>;
  RentedStatus?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchProperties1115_stddev_samp_fields";
  CustomerName?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  NwscCustomerReference?: Maybe<Scalars["Float"]>;
  RentedStatus?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_RMatchProperties1115" */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Stddev_Samp_Order_By = {
  CustomerName?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerReference?: Maybe<Order_By>;
  RentedStatus?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchProperties1115_sum_fields";
  CustomerName?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["bigint"]>;
  NwscCustomerReference?: Maybe<Scalars["bigint"]>;
  RentedStatus?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_RMatchProperties1115" */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Sum_Order_By = {
  CustomerName?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerReference?: Maybe<Order_By>;
  RentedStatus?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchProperties1115_var_pop_fields";
  CustomerName?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  NwscCustomerReference?: Maybe<Scalars["Float"]>;
  RentedStatus?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_RMatchProperties1115" */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Var_Pop_Order_By = {
  CustomerName?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerReference?: Maybe<Order_By>;
  RentedStatus?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchProperties1115_var_samp_fields";
  CustomerName?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  NwscCustomerReference?: Maybe<Scalars["Float"]>;
  RentedStatus?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_RMatchProperties1115" */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Var_Samp_Order_By = {
  CustomerName?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerReference?: Maybe<Order_By>;
  RentedStatus?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchProperties1115_variance_fields";
  CustomerName?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  NwscCustomerReference?: Maybe<Scalars["Float"]>;
  RentedStatus?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_RMatchProperties1115" */
export type Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Variance_Order_By = {
  CustomerName?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerReference?: Maybe<Order_By>;
  RentedStatus?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_RMatchSummary904" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904 = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary904";
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile1?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAtributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraDob?: Maybe<Scalars["timestamptz"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_RMatchSummary904" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary904_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary904>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_RMatchSummary904" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary904_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_RMatchSummary904" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rmatch_RMatchSummary904" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_RMatchSummary904" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary904_avg_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_RMatchSummary904" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Avg_Order_By = {
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_RMatchSummary904". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Bool_Exp = {
  CountUra?: Maybe<Bigint_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcEmp?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  KccaDob?: Maybe<Timestamp_Comparison_Exp>;
  KccaEmail?: Maybe<String_Comparison_Exp>;
  KccaFirstName?: Maybe<String_Comparison_Exp>;
  KccaLandline?: Maybe<String_Comparison_Exp>;
  KccaMiddleName?: Maybe<String_Comparison_Exp>;
  KccaMobile1?: Maybe<String_Comparison_Exp>;
  KccaMobile2?: Maybe<String_Comparison_Exp>;
  KccaNin?: Maybe<String_Comparison_Exp>;
  KccaSurname?: Maybe<String_Comparison_Exp>;
  KccaTin?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchDetails?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Bigint_Comparison_Exp>;
  MatchScoreAtributes?: Maybe<String_Comparison_Exp>;
  NumOwnedNonRateable?: Maybe<Bigint_Comparison_Exp>;
  NumProperties?: Maybe<Bigint_Comparison_Exp>;
  SumAdjustedIncome?: Maybe<Float8_Comparison_Exp>;
  SumGrossValue?: Maybe<Float8_Comparison_Exp>;
  SumNonRateableValue?: Maybe<Float8_Comparison_Exp>;
  SumRateableValue?: Maybe<Float8_Comparison_Exp>;
  TotalRentedProperties?: Maybe<Bigint_Comparison_Exp>;
  UraDob?: Maybe<Timestamptz_Comparison_Exp>;
  UraEmailId?: Maybe<String_Comparison_Exp>;
  UraFirstname?: Maybe<String_Comparison_Exp>;
  UraLandlineNumber?: Maybe<Float8_Comparison_Exp>;
  UraMiddlename?: Maybe<String_Comparison_Exp>;
  UraMobileNumber?: Maybe<String_Comparison_Exp>;
  UraNationalId?: Maybe<String_Comparison_Exp>;
  UraSurname?: Maybe<String_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_RMatchSummary904" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Inc_Input = {
  CountUra?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_RMatchSummary904" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Insert_Input = {
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile1?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAtributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraDob?: Maybe<Scalars["timestamptz"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary904_max_fields";
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile1?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAtributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraDob?: Maybe<Scalars["timestamptz"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_RMatchSummary904" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Max_Order_By = {
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaDob?: Maybe<Order_By>;
  KccaEmail?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaLandline?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaMobile1?: Maybe<Order_By>;
  KccaMobile2?: Maybe<Order_By>;
  KccaNin?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  KccaTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAtributes?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraDob?: Maybe<Order_By>;
  UraEmailId?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraMobileNumber?: Maybe<Order_By>;
  UraNationalId?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary904_min_fields";
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile1?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAtributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraDob?: Maybe<Scalars["timestamptz"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_RMatchSummary904" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Min_Order_By = {
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaDob?: Maybe<Order_By>;
  KccaEmail?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaLandline?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaMobile1?: Maybe<Order_By>;
  KccaMobile2?: Maybe<Order_By>;
  KccaNin?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  KccaTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAtributes?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraDob?: Maybe<Order_By>;
  UraEmailId?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraMobileNumber?: Maybe<Order_By>;
  UraNationalId?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_RMatchSummary904" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary904_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary904>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_RMatchSummary904" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_RMatchSummary904_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_RMatchSummary904" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Order_By = {
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaDob?: Maybe<Order_By>;
  KccaEmail?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaLandline?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaMobile1?: Maybe<Order_By>;
  KccaMobile2?: Maybe<Order_By>;
  KccaNin?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  KccaTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAtributes?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraDob?: Maybe<Order_By>;
  UraEmailId?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraMobileNumber?: Maybe<Order_By>;
  UraNationalId?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_RMatchSummary904" */
export enum Rtcs_Db_Ph_Rmatch_RMatchSummary904_Select_Column {
  /** column name */
  CountUra = "CountUra",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  File = "File",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcEmp = "IsIncomeSrcEmp",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  KccaDob = "KccaDob",
  /** column name */
  KccaEmail = "KccaEmail",
  /** column name */
  KccaFirstName = "KccaFirstName",
  /** column name */
  KccaLandline = "KccaLandline",
  /** column name */
  KccaMiddleName = "KccaMiddleName",
  /** column name */
  KccaMobile1 = "KccaMobile1",
  /** column name */
  KccaMobile2 = "KccaMobile2",
  /** column name */
  KccaNin = "KccaNin",
  /** column name */
  KccaSurname = "KccaSurname",
  /** column name */
  KccaTin = "KccaTin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchDetails = "MatchDetails",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MatchScoreAtributes = "MatchScoreAtributes",
  /** column name */
  NumOwnedNonRateable = "NumOwnedNonRateable",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  SumAdjustedIncome = "SumAdjustedIncome",
  /** column name */
  SumGrossValue = "SumGrossValue",
  /** column name */
  SumNonRateableValue = "SumNonRateableValue",
  /** column name */
  SumRateableValue = "SumRateableValue",
  /** column name */
  TotalRentedProperties = "TotalRentedProperties",
  /** column name */
  UraDob = "UraDob",
  /** column name */
  UraEmailId = "UraEmailId",
  /** column name */
  UraFirstname = "UraFirstname",
  /** column name */
  UraLandlineNumber = "UraLandlineNumber",
  /** column name */
  UraMiddlename = "UraMiddlename",
  /** column name */
  UraMobileNumber = "UraMobileNumber",
  /** column name */
  UraNationalId = "UraNationalId",
  /** column name */
  UraSurname = "UraSurname",
  /** column name */
  UraTinNo = "UraTinNo",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_RMatchSummary904" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Set_Input = {
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile1?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAtributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraDob?: Maybe<Scalars["timestamptz"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary904_stddev_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_RMatchSummary904" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Stddev_Order_By = {
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary904_stddev_pop_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_RMatchSummary904" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Stddev_Pop_Order_By = {
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary904_stddev_samp_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_RMatchSummary904" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Stddev_Samp_Order_By = {
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary904_sum_fields";
  CountUra?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_RMatchSummary904" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Sum_Order_By = {
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary904_var_pop_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_RMatchSummary904" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Var_Pop_Order_By = {
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary904_var_samp_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_RMatchSummary904" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Var_Samp_Order_By = {
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary904_variance_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_RMatchSummary904" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary904_Variance_Order_By = {
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_RMatchSummary916" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916 = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary916";
  AvgRate?: Maybe<Scalars["float8"]>;
  ComplianceCategory?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Difference?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  PercentageDifference?: Maybe<Scalars["float8"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTin?: Maybe<Scalars["bigint"]>;
  Urid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy";
  CustomerId?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NumOwnedOccupied?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Riskscore?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTin?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregate =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregate";
    aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregate_Fields>;
    nodes: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy>;
  };

/** aggregate fields of "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregate_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregate_fields";
    avg?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Avg_Fields>;
    count?: Maybe<Scalars["Int"]>;
    max?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Max_Fields>;
    min?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Min_Fields>;
    stddev?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Stddev_Fields>;
    stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Stddev_Samp_Fields>;
    sum?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Sum_Fields>;
    var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Var_Pop_Fields>;
    var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Var_Samp_Fields>;
    variance?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Variance_Fields>;
  };

/** aggregate fields of "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregate_Order_By =
  {
    avg?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Max_Order_By>;
    min?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Min_Order_By>;
    stddev?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Stddev_Order_By>;
    stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Stddev_Samp_Order_By>;
    sum?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Sum_Order_By>;
    var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Var_Pop_Order_By>;
    var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Var_Samp_Order_By>;
    variance?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Variance_Order_By>;
  };

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Avg_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_avg_fields";
    MatchScore?: Maybe<Scalars["Float"]>;
    NumOwnedOccupied?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    Riskscore?: Maybe<Scalars["Float"]>;
    SumAdjustedIncome?: Maybe<Scalars["Float"]>;
    SumGrossValue?: Maybe<Scalars["Float"]>;
    SumNonRateableValue?: Maybe<Scalars["Float"]>;
    SumRateableValue?: Maybe<Scalars["Float"]>;
    TotalRentedProperties?: Maybe<Scalars["Float"]>;
  };

/** order by avg() on columns of table "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Avg_Order_By =
  {
    MatchScore?: Maybe<Order_By>;
    NumOwnedOccupied?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    Riskscore?: Maybe<Order_By>;
    SumAdjustedIncome?: Maybe<Order_By>;
    SumGrossValue?: Maybe<Order_By>;
    SumNonRateableValue?: Maybe<Order_By>;
    SumRateableValue?: Maybe<Order_By>;
    TotalRentedProperties?: Maybe<Order_By>;
  };

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Bool_Exp =
  {
    CustomerId?: Maybe<String_Comparison_Exp>;
    EmailId?: Maybe<String_Comparison_Exp>;
    IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
    KccaFirstName?: Maybe<String_Comparison_Exp>;
    KccaMiddleName?: Maybe<String_Comparison_Exp>;
    KccaSurname?: Maybe<String_Comparison_Exp>;
    KccaTin?: Maybe<String_Comparison_Exp>;
    MatchKccaUra?: Maybe<String_Comparison_Exp>;
    MatchScore?: Maybe<Bigint_Comparison_Exp>;
    MobileNumber?: Maybe<String_Comparison_Exp>;
    NationalId?: Maybe<String_Comparison_Exp>;
    NumOwnedOccupied?: Maybe<Bigint_Comparison_Exp>;
    NumProperties?: Maybe<Bigint_Comparison_Exp>;
    Riskscore?: Maybe<Float8_Comparison_Exp>;
    Rnid?: Maybe<String_Comparison_Exp>;
    ScoreMatchAtributes?: Maybe<String_Comparison_Exp>;
    SumAdjustedIncome?: Maybe<Float8_Comparison_Exp>;
    SumGrossValue?: Maybe<Float8_Comparison_Exp>;
    SumNonRateableValue?: Maybe<Float8_Comparison_Exp>;
    SumRateableValue?: Maybe<Float8_Comparison_Exp>;
    TotalRentedProperties?: Maybe<Bigint_Comparison_Exp>;
    UraFirstname?: Maybe<String_Comparison_Exp>;
    UraMatchingTins?: Maybe<String_Comparison_Exp>;
    UraMiddlename?: Maybe<String_Comparison_Exp>;
    UraSurname?: Maybe<String_Comparison_Exp>;
    UraTin?: Maybe<String_Comparison_Exp>;
    _and?: Maybe<
      Array<
        Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Bool_Exp>
      >
    >;
    _not?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Bool_Exp>;
    _or?: Maybe<
      Array<
        Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Bool_Exp>
      >
    >;
  };

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Inc_Input =
  {
    MatchScore?: Maybe<Scalars["bigint"]>;
    NumOwnedOccupied?: Maybe<Scalars["bigint"]>;
    NumProperties?: Maybe<Scalars["bigint"]>;
    Riskscore?: Maybe<Scalars["float8"]>;
    SumAdjustedIncome?: Maybe<Scalars["float8"]>;
    SumGrossValue?: Maybe<Scalars["float8"]>;
    SumNonRateableValue?: Maybe<Scalars["float8"]>;
    SumRateableValue?: Maybe<Scalars["float8"]>;
    TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  };

/** input type for inserting data into table "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Insert_Input =
  {
    CustomerId?: Maybe<Scalars["String"]>;
    EmailId?: Maybe<Scalars["String"]>;
    IsIncomeSrcRental?: Maybe<Scalars["String"]>;
    KccaFirstName?: Maybe<Scalars["String"]>;
    KccaMiddleName?: Maybe<Scalars["String"]>;
    KccaSurname?: Maybe<Scalars["String"]>;
    KccaTin?: Maybe<Scalars["String"]>;
    MatchKccaUra?: Maybe<Scalars["String"]>;
    MatchScore?: Maybe<Scalars["bigint"]>;
    MobileNumber?: Maybe<Scalars["String"]>;
    NationalId?: Maybe<Scalars["String"]>;
    NumOwnedOccupied?: Maybe<Scalars["bigint"]>;
    NumProperties?: Maybe<Scalars["bigint"]>;
    Riskscore?: Maybe<Scalars["float8"]>;
    Rnid?: Maybe<Scalars["String"]>;
    ScoreMatchAtributes?: Maybe<Scalars["String"]>;
    SumAdjustedIncome?: Maybe<Scalars["float8"]>;
    SumGrossValue?: Maybe<Scalars["float8"]>;
    SumNonRateableValue?: Maybe<Scalars["float8"]>;
    SumRateableValue?: Maybe<Scalars["float8"]>;
    TotalRentedProperties?: Maybe<Scalars["bigint"]>;
    UraFirstname?: Maybe<Scalars["String"]>;
    UraMatchingTins?: Maybe<Scalars["String"]>;
    UraMiddlename?: Maybe<Scalars["String"]>;
    UraSurname?: Maybe<Scalars["String"]>;
    UraTin?: Maybe<Scalars["String"]>;
  };

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Max_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_max_fields";
    CustomerId?: Maybe<Scalars["String"]>;
    EmailId?: Maybe<Scalars["String"]>;
    IsIncomeSrcRental?: Maybe<Scalars["String"]>;
    KccaFirstName?: Maybe<Scalars["String"]>;
    KccaMiddleName?: Maybe<Scalars["String"]>;
    KccaSurname?: Maybe<Scalars["String"]>;
    KccaTin?: Maybe<Scalars["String"]>;
    MatchKccaUra?: Maybe<Scalars["String"]>;
    MatchScore?: Maybe<Scalars["bigint"]>;
    MobileNumber?: Maybe<Scalars["String"]>;
    NationalId?: Maybe<Scalars["String"]>;
    NumOwnedOccupied?: Maybe<Scalars["bigint"]>;
    NumProperties?: Maybe<Scalars["bigint"]>;
    Riskscore?: Maybe<Scalars["float8"]>;
    Rnid?: Maybe<Scalars["String"]>;
    ScoreMatchAtributes?: Maybe<Scalars["String"]>;
    SumAdjustedIncome?: Maybe<Scalars["float8"]>;
    SumGrossValue?: Maybe<Scalars["float8"]>;
    SumNonRateableValue?: Maybe<Scalars["float8"]>;
    SumRateableValue?: Maybe<Scalars["float8"]>;
    TotalRentedProperties?: Maybe<Scalars["bigint"]>;
    UraFirstname?: Maybe<Scalars["String"]>;
    UraMatchingTins?: Maybe<Scalars["String"]>;
    UraMiddlename?: Maybe<Scalars["String"]>;
    UraSurname?: Maybe<Scalars["String"]>;
    UraTin?: Maybe<Scalars["String"]>;
  };

/** order by max() on columns of table "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Max_Order_By =
  {
    CustomerId?: Maybe<Order_By>;
    EmailId?: Maybe<Order_By>;
    IsIncomeSrcRental?: Maybe<Order_By>;
    KccaFirstName?: Maybe<Order_By>;
    KccaMiddleName?: Maybe<Order_By>;
    KccaSurname?: Maybe<Order_By>;
    KccaTin?: Maybe<Order_By>;
    MatchKccaUra?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    MobileNumber?: Maybe<Order_By>;
    NationalId?: Maybe<Order_By>;
    NumOwnedOccupied?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    Riskscore?: Maybe<Order_By>;
    Rnid?: Maybe<Order_By>;
    ScoreMatchAtributes?: Maybe<Order_By>;
    SumAdjustedIncome?: Maybe<Order_By>;
    SumGrossValue?: Maybe<Order_By>;
    SumNonRateableValue?: Maybe<Order_By>;
    SumRateableValue?: Maybe<Order_By>;
    TotalRentedProperties?: Maybe<Order_By>;
    UraFirstname?: Maybe<Order_By>;
    UraMatchingTins?: Maybe<Order_By>;
    UraMiddlename?: Maybe<Order_By>;
    UraSurname?: Maybe<Order_By>;
    UraTin?: Maybe<Order_By>;
  };

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Min_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_min_fields";
    CustomerId?: Maybe<Scalars["String"]>;
    EmailId?: Maybe<Scalars["String"]>;
    IsIncomeSrcRental?: Maybe<Scalars["String"]>;
    KccaFirstName?: Maybe<Scalars["String"]>;
    KccaMiddleName?: Maybe<Scalars["String"]>;
    KccaSurname?: Maybe<Scalars["String"]>;
    KccaTin?: Maybe<Scalars["String"]>;
    MatchKccaUra?: Maybe<Scalars["String"]>;
    MatchScore?: Maybe<Scalars["bigint"]>;
    MobileNumber?: Maybe<Scalars["String"]>;
    NationalId?: Maybe<Scalars["String"]>;
    NumOwnedOccupied?: Maybe<Scalars["bigint"]>;
    NumProperties?: Maybe<Scalars["bigint"]>;
    Riskscore?: Maybe<Scalars["float8"]>;
    Rnid?: Maybe<Scalars["String"]>;
    ScoreMatchAtributes?: Maybe<Scalars["String"]>;
    SumAdjustedIncome?: Maybe<Scalars["float8"]>;
    SumGrossValue?: Maybe<Scalars["float8"]>;
    SumNonRateableValue?: Maybe<Scalars["float8"]>;
    SumRateableValue?: Maybe<Scalars["float8"]>;
    TotalRentedProperties?: Maybe<Scalars["bigint"]>;
    UraFirstname?: Maybe<Scalars["String"]>;
    UraMatchingTins?: Maybe<Scalars["String"]>;
    UraMiddlename?: Maybe<Scalars["String"]>;
    UraSurname?: Maybe<Scalars["String"]>;
    UraTin?: Maybe<Scalars["String"]>;
  };

/** order by min() on columns of table "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Min_Order_By =
  {
    CustomerId?: Maybe<Order_By>;
    EmailId?: Maybe<Order_By>;
    IsIncomeSrcRental?: Maybe<Order_By>;
    KccaFirstName?: Maybe<Order_By>;
    KccaMiddleName?: Maybe<Order_By>;
    KccaSurname?: Maybe<Order_By>;
    KccaTin?: Maybe<Order_By>;
    MatchKccaUra?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    MobileNumber?: Maybe<Order_By>;
    NationalId?: Maybe<Order_By>;
    NumOwnedOccupied?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    Riskscore?: Maybe<Order_By>;
    Rnid?: Maybe<Order_By>;
    ScoreMatchAtributes?: Maybe<Order_By>;
    SumAdjustedIncome?: Maybe<Order_By>;
    SumGrossValue?: Maybe<Order_By>;
    SumNonRateableValue?: Maybe<Order_By>;
    SumRateableValue?: Maybe<Order_By>;
    TotalRentedProperties?: Maybe<Order_By>;
    UraFirstname?: Maybe<Order_By>;
    UraMatchingTins?: Maybe<Order_By>;
    UraMiddlename?: Maybe<Order_By>;
    UraSurname?: Maybe<Order_By>;
    UraTin?: Maybe<Order_By>;
  };

/** response of any mutation on the table "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Mutation_Response =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_mutation_response";
    /** number of affected rows by the mutation */
    affected_rows: Scalars["Int"];
    /** data of the affected rows by the mutation */
    returning: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy>;
  };

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Order_By =
  {
    CustomerId?: Maybe<Order_By>;
    EmailId?: Maybe<Order_By>;
    IsIncomeSrcRental?: Maybe<Order_By>;
    KccaFirstName?: Maybe<Order_By>;
    KccaMiddleName?: Maybe<Order_By>;
    KccaSurname?: Maybe<Order_By>;
    KccaTin?: Maybe<Order_By>;
    MatchKccaUra?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    MobileNumber?: Maybe<Order_By>;
    NationalId?: Maybe<Order_By>;
    NumOwnedOccupied?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    Riskscore?: Maybe<Order_By>;
    Rnid?: Maybe<Order_By>;
    ScoreMatchAtributes?: Maybe<Order_By>;
    SumAdjustedIncome?: Maybe<Order_By>;
    SumGrossValue?: Maybe<Order_By>;
    SumNonRateableValue?: Maybe<Order_By>;
    SumRateableValue?: Maybe<Order_By>;
    TotalRentedProperties?: Maybe<Order_By>;
    UraFirstname?: Maybe<Order_By>;
    UraMatchingTins?: Maybe<Order_By>;
    UraMiddlename?: Maybe<Order_By>;
    UraSurname?: Maybe<Order_By>;
    UraTin?: Maybe<Order_By>;
  };

/** select columns of table "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export enum Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Select_Column {
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  EmailId = "EmailId",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  KccaFirstName = "KccaFirstName",
  /** column name */
  KccaMiddleName = "KccaMiddleName",
  /** column name */
  KccaSurname = "KccaSurname",
  /** column name */
  KccaTin = "KccaTin",
  /** column name */
  MatchKccaUra = "MatchKccaUra",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  NationalId = "NationalId",
  /** column name */
  NumOwnedOccupied = "NumOwnedOccupied",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  Riskscore = "Riskscore",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  ScoreMatchAtributes = "ScoreMatchAtributes",
  /** column name */
  SumAdjustedIncome = "SumAdjustedIncome",
  /** column name */
  SumGrossValue = "SumGrossValue",
  /** column name */
  SumNonRateableValue = "SumNonRateableValue",
  /** column name */
  SumRateableValue = "SumRateableValue",
  /** column name */
  TotalRentedProperties = "TotalRentedProperties",
  /** column name */
  UraFirstname = "UraFirstname",
  /** column name */
  UraMatchingTins = "UraMatchingTins",
  /** column name */
  UraMiddlename = "UraMiddlename",
  /** column name */
  UraSurname = "UraSurname",
  /** column name */
  UraTin = "UraTin",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Set_Input =
  {
    CustomerId?: Maybe<Scalars["String"]>;
    EmailId?: Maybe<Scalars["String"]>;
    IsIncomeSrcRental?: Maybe<Scalars["String"]>;
    KccaFirstName?: Maybe<Scalars["String"]>;
    KccaMiddleName?: Maybe<Scalars["String"]>;
    KccaSurname?: Maybe<Scalars["String"]>;
    KccaTin?: Maybe<Scalars["String"]>;
    MatchKccaUra?: Maybe<Scalars["String"]>;
    MatchScore?: Maybe<Scalars["bigint"]>;
    MobileNumber?: Maybe<Scalars["String"]>;
    NationalId?: Maybe<Scalars["String"]>;
    NumOwnedOccupied?: Maybe<Scalars["bigint"]>;
    NumProperties?: Maybe<Scalars["bigint"]>;
    Riskscore?: Maybe<Scalars["float8"]>;
    Rnid?: Maybe<Scalars["String"]>;
    ScoreMatchAtributes?: Maybe<Scalars["String"]>;
    SumAdjustedIncome?: Maybe<Scalars["float8"]>;
    SumGrossValue?: Maybe<Scalars["float8"]>;
    SumNonRateableValue?: Maybe<Scalars["float8"]>;
    SumRateableValue?: Maybe<Scalars["float8"]>;
    TotalRentedProperties?: Maybe<Scalars["bigint"]>;
    UraFirstname?: Maybe<Scalars["String"]>;
    UraMatchingTins?: Maybe<Scalars["String"]>;
    UraMiddlename?: Maybe<Scalars["String"]>;
    UraSurname?: Maybe<Scalars["String"]>;
    UraTin?: Maybe<Scalars["String"]>;
  };

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Stddev_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_stddev_fields";
    MatchScore?: Maybe<Scalars["Float"]>;
    NumOwnedOccupied?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    Riskscore?: Maybe<Scalars["Float"]>;
    SumAdjustedIncome?: Maybe<Scalars["Float"]>;
    SumGrossValue?: Maybe<Scalars["Float"]>;
    SumNonRateableValue?: Maybe<Scalars["Float"]>;
    SumRateableValue?: Maybe<Scalars["Float"]>;
    TotalRentedProperties?: Maybe<Scalars["Float"]>;
  };

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Stddev_Order_By =
  {
    MatchScore?: Maybe<Order_By>;
    NumOwnedOccupied?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    Riskscore?: Maybe<Order_By>;
    SumAdjustedIncome?: Maybe<Order_By>;
    SumGrossValue?: Maybe<Order_By>;
    SumNonRateableValue?: Maybe<Order_By>;
    SumRateableValue?: Maybe<Order_By>;
    TotalRentedProperties?: Maybe<Order_By>;
  };

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Stddev_Pop_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_stddev_pop_fields";
    MatchScore?: Maybe<Scalars["Float"]>;
    NumOwnedOccupied?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    Riskscore?: Maybe<Scalars["Float"]>;
    SumAdjustedIncome?: Maybe<Scalars["Float"]>;
    SumGrossValue?: Maybe<Scalars["Float"]>;
    SumNonRateableValue?: Maybe<Scalars["Float"]>;
    SumRateableValue?: Maybe<Scalars["Float"]>;
    TotalRentedProperties?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Stddev_Pop_Order_By =
  {
    MatchScore?: Maybe<Order_By>;
    NumOwnedOccupied?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    Riskscore?: Maybe<Order_By>;
    SumAdjustedIncome?: Maybe<Order_By>;
    SumGrossValue?: Maybe<Order_By>;
    SumNonRateableValue?: Maybe<Order_By>;
    SumRateableValue?: Maybe<Order_By>;
    TotalRentedProperties?: Maybe<Order_By>;
  };

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Stddev_Samp_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_stddev_samp_fields";
    MatchScore?: Maybe<Scalars["Float"]>;
    NumOwnedOccupied?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    Riskscore?: Maybe<Scalars["Float"]>;
    SumAdjustedIncome?: Maybe<Scalars["Float"]>;
    SumGrossValue?: Maybe<Scalars["Float"]>;
    SumNonRateableValue?: Maybe<Scalars["Float"]>;
    SumRateableValue?: Maybe<Scalars["Float"]>;
    TotalRentedProperties?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Stddev_Samp_Order_By =
  {
    MatchScore?: Maybe<Order_By>;
    NumOwnedOccupied?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    Riskscore?: Maybe<Order_By>;
    SumAdjustedIncome?: Maybe<Order_By>;
    SumGrossValue?: Maybe<Order_By>;
    SumNonRateableValue?: Maybe<Order_By>;
    SumRateableValue?: Maybe<Order_By>;
    TotalRentedProperties?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Sum_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_sum_fields";
    MatchScore?: Maybe<Scalars["bigint"]>;
    NumOwnedOccupied?: Maybe<Scalars["bigint"]>;
    NumProperties?: Maybe<Scalars["bigint"]>;
    Riskscore?: Maybe<Scalars["float8"]>;
    SumAdjustedIncome?: Maybe<Scalars["float8"]>;
    SumGrossValue?: Maybe<Scalars["float8"]>;
    SumNonRateableValue?: Maybe<Scalars["float8"]>;
    SumRateableValue?: Maybe<Scalars["float8"]>;
    TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  };

/** order by sum() on columns of table "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Sum_Order_By =
  {
    MatchScore?: Maybe<Order_By>;
    NumOwnedOccupied?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    Riskscore?: Maybe<Order_By>;
    SumAdjustedIncome?: Maybe<Order_By>;
    SumGrossValue?: Maybe<Order_By>;
    SumNonRateableValue?: Maybe<Order_By>;
    SumRateableValue?: Maybe<Order_By>;
    TotalRentedProperties?: Maybe<Order_By>;
  };

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Var_Pop_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_var_pop_fields";
    MatchScore?: Maybe<Scalars["Float"]>;
    NumOwnedOccupied?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    Riskscore?: Maybe<Scalars["Float"]>;
    SumAdjustedIncome?: Maybe<Scalars["Float"]>;
    SumGrossValue?: Maybe<Scalars["Float"]>;
    SumNonRateableValue?: Maybe<Scalars["Float"]>;
    SumRateableValue?: Maybe<Scalars["Float"]>;
    TotalRentedProperties?: Maybe<Scalars["Float"]>;
  };

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Var_Pop_Order_By =
  {
    MatchScore?: Maybe<Order_By>;
    NumOwnedOccupied?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    Riskscore?: Maybe<Order_By>;
    SumAdjustedIncome?: Maybe<Order_By>;
    SumGrossValue?: Maybe<Order_By>;
    SumNonRateableValue?: Maybe<Order_By>;
    SumRateableValue?: Maybe<Order_By>;
    TotalRentedProperties?: Maybe<Order_By>;
  };

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Var_Samp_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_var_samp_fields";
    MatchScore?: Maybe<Scalars["Float"]>;
    NumOwnedOccupied?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    Riskscore?: Maybe<Scalars["Float"]>;
    SumAdjustedIncome?: Maybe<Scalars["Float"]>;
    SumGrossValue?: Maybe<Scalars["Float"]>;
    SumNonRateableValue?: Maybe<Scalars["Float"]>;
    SumRateableValue?: Maybe<Scalars["Float"]>;
    TotalRentedProperties?: Maybe<Scalars["Float"]>;
  };

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Var_Samp_Order_By =
  {
    MatchScore?: Maybe<Order_By>;
    NumOwnedOccupied?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    Riskscore?: Maybe<Order_By>;
    SumAdjustedIncome?: Maybe<Order_By>;
    SumGrossValue?: Maybe<Order_By>;
    SumNonRateableValue?: Maybe<Order_By>;
    SumRateableValue?: Maybe<Order_By>;
    TotalRentedProperties?: Maybe<Order_By>;
  };

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Variance_Fields =
  {
    __typename?: "rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_variance_fields";
    MatchScore?: Maybe<Scalars["Float"]>;
    NumOwnedOccupied?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    Riskscore?: Maybe<Scalars["Float"]>;
    SumAdjustedIncome?: Maybe<Scalars["Float"]>;
    SumGrossValue?: Maybe<Scalars["Float"]>;
    SumNonRateableValue?: Maybe<Scalars["Float"]>;
    SumRateableValue?: Maybe<Scalars["Float"]>;
    TotalRentedProperties?: Maybe<Scalars["Float"]>;
  };

/** order by variance() on columns of table "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Variance_Order_By =
  {
    MatchScore?: Maybe<Order_By>;
    NumOwnedOccupied?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    Riskscore?: Maybe<Order_By>;
    SumAdjustedIncome?: Maybe<Order_By>;
    SumGrossValue?: Maybe<Order_By>;
    SumNonRateableValue?: Maybe<Order_By>;
    SumRateableValue?: Maybe<Order_By>;
    TotalRentedProperties?: Maybe<Order_By>;
  };

/** aggregated selection of "rtcs_db.PH_rmatch_RMatchSummary916" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary916_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_RMatchSummary916" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary916_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_RMatchSummary916" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rmatch_RMatchSummary916" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_RMatchSummary916" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary916_avg_fields";
  AvgRate?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  PercentageDifference?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraTin?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_RMatchSummary916" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Avg_Order_By = {
  AvgRate?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  PercentageDifference?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTin?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_RMatchSummary916". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Bool_Exp = {
  AvgRate?: Maybe<Float8_Comparison_Exp>;
  ComplianceCategory?: Maybe<String_Comparison_Exp>;
  CountUra?: Maybe<Bigint_Comparison_Exp>;
  CustomerId?: Maybe<Bigint_Comparison_Exp>;
  Difference?: Maybe<Float8_Comparison_Exp>;
  EmailId?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  IsAmendment?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcEmp?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  KccaFirstName?: Maybe<String_Comparison_Exp>;
  KccaMiddleName?: Maybe<String_Comparison_Exp>;
  KccaSurname?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MatchKccaUra?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Bigint_Comparison_Exp>;
  MobileNumber?: Maybe<String_Comparison_Exp>;
  NationalId?: Maybe<String_Comparison_Exp>;
  NumOwnedNonRateable?: Maybe<Bigint_Comparison_Exp>;
  NumProperties?: Maybe<Bigint_Comparison_Exp>;
  PercentageDifference?: Maybe<Float8_Comparison_Exp>;
  PropincIncome?: Maybe<Float8_Comparison_Exp>;
  RentincIncome?: Maybe<Float8_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnPeriodYear?: Maybe<String_Comparison_Exp>;
  ScoreMatchAtributes?: Maybe<String_Comparison_Exp>;
  SumAdjustedIncome?: Maybe<Float8_Comparison_Exp>;
  SumGrossValue?: Maybe<Float8_Comparison_Exp>;
  SumNonRateableValue?: Maybe<Float8_Comparison_Exp>;
  SumRateableValue?: Maybe<Float8_Comparison_Exp>;
  TotalRentedProperties?: Maybe<Bigint_Comparison_Exp>;
  UraFirstname?: Maybe<String_Comparison_Exp>;
  UraMatchingTins?: Maybe<String_Comparison_Exp>;
  UraMiddlename?: Maybe<String_Comparison_Exp>;
  UraSurname?: Maybe<String_Comparison_Exp>;
  UraTin?: Maybe<Bigint_Comparison_Exp>;
  Urid?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_RMatchSummary916" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Inc_Input = {
  AvgRate?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  PercentageDifference?: Maybe<Scalars["float8"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraTin?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_RMatchSummary916" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Insert_Input = {
  AvgRate?: Maybe<Scalars["float8"]>;
  ComplianceCategory?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Difference?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  PercentageDifference?: Maybe<Scalars["float8"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTin?: Maybe<Scalars["bigint"]>;
  Urid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary916_max_fields";
  AvgRate?: Maybe<Scalars["float8"]>;
  ComplianceCategory?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Difference?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  PercentageDifference?: Maybe<Scalars["float8"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTin?: Maybe<Scalars["bigint"]>;
  Urid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_RMatchSummary916" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Max_Order_By = {
  AvgRate?: Maybe<Order_By>;
  ComplianceCategory?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchKccaUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  PercentageDifference?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  ScoreMatchAtributes?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraMatchingTins?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTin?: Maybe<Order_By>;
  Urid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary916_min_fields";
  AvgRate?: Maybe<Scalars["float8"]>;
  ComplianceCategory?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Difference?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  PercentageDifference?: Maybe<Scalars["float8"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTin?: Maybe<Scalars["bigint"]>;
  Urid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_RMatchSummary916" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Min_Order_By = {
  AvgRate?: Maybe<Order_By>;
  ComplianceCategory?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchKccaUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  PercentageDifference?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  ScoreMatchAtributes?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraMatchingTins?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTin?: Maybe<Order_By>;
  Urid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_RMatchSummary916" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary916_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_RMatchSummary916" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_RMatchSummary916_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_RMatchSummary916" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Order_By = {
  AvgRate?: Maybe<Order_By>;
  ComplianceCategory?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchKccaUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  PercentageDifference?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  ScoreMatchAtributes?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraMatchingTins?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTin?: Maybe<Order_By>;
  Urid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_RMatchSummary916" */
export enum Rtcs_Db_Ph_Rmatch_RMatchSummary916_Select_Column {
  /** column name */
  AvgRate = "AvgRate",
  /** column name */
  ComplianceCategory = "ComplianceCategory",
  /** column name */
  CountUra = "CountUra",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  Difference = "Difference",
  /** column name */
  EmailId = "EmailId",
  /** column name */
  File = "File",
  /** column name */
  IsAmendment = "IsAmendment",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcEmp = "IsIncomeSrcEmp",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  KccaFirstName = "KccaFirstName",
  /** column name */
  KccaMiddleName = "KccaMiddleName",
  /** column name */
  KccaSurname = "KccaSurname",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MatchKccaUra = "MatchKccaUra",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  NationalId = "NationalId",
  /** column name */
  NumOwnedNonRateable = "NumOwnedNonRateable",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  PercentageDifference = "PercentageDifference",
  /** column name */
  PropincIncome = "PropincIncome",
  /** column name */
  RentincIncome = "RentincIncome",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnPeriodYear = "RtnPeriodYear",
  /** column name */
  ScoreMatchAtributes = "ScoreMatchAtributes",
  /** column name */
  SumAdjustedIncome = "SumAdjustedIncome",
  /** column name */
  SumGrossValue = "SumGrossValue",
  /** column name */
  SumNonRateableValue = "SumNonRateableValue",
  /** column name */
  SumRateableValue = "SumRateableValue",
  /** column name */
  TotalRentedProperties = "TotalRentedProperties",
  /** column name */
  UraFirstname = "UraFirstname",
  /** column name */
  UraMatchingTins = "UraMatchingTins",
  /** column name */
  UraMiddlename = "UraMiddlename",
  /** column name */
  UraSurname = "UraSurname",
  /** column name */
  UraTin = "UraTin",
  /** column name */
  Urid = "Urid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_RMatchSummary916" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Set_Input = {
  AvgRate?: Maybe<Scalars["float8"]>;
  ComplianceCategory?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Difference?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  PercentageDifference?: Maybe<Scalars["float8"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTin?: Maybe<Scalars["bigint"]>;
  Urid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary916_stddev_fields";
  AvgRate?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  PercentageDifference?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraTin?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_RMatchSummary916" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Stddev_Order_By = {
  AvgRate?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  PercentageDifference?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTin?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary916_stddev_pop_fields";
  AvgRate?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  PercentageDifference?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraTin?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_RMatchSummary916" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Stddev_Pop_Order_By = {
  AvgRate?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  PercentageDifference?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTin?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary916_stddev_samp_fields";
  AvgRate?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  PercentageDifference?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraTin?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_RMatchSummary916" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Stddev_Samp_Order_By = {
  AvgRate?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  PercentageDifference?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTin?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary916_sum_fields";
  AvgRate?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  PercentageDifference?: Maybe<Scalars["float8"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraTin?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_RMatchSummary916" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Sum_Order_By = {
  AvgRate?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  PercentageDifference?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTin?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary916_var_pop_fields";
  AvgRate?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  PercentageDifference?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraTin?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_RMatchSummary916" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Var_Pop_Order_By = {
  AvgRate?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  PercentageDifference?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTin?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary916_var_samp_fields";
  AvgRate?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  PercentageDifference?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraTin?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_RMatchSummary916" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Var_Samp_Order_By = {
  AvgRate?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  PercentageDifference?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTin?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary916_variance_fields";
  AvgRate?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  PercentageDifference?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraTin?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_RMatchSummary916" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary916_Variance_Order_By = {
  AvgRate?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  PercentageDifference?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTin?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_RMatchSummary937" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937 = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary937";
  Countura?: Maybe<Scalars["bigint"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccaemail1?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccanin1?: Maybe<Scalars["String"]>;
  Kccaphones?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchdetails?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numownednonrateable?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uradob?: Maybe<Scalars["timestamptz"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Uralandlinenumber?: Maybe<Scalars["float8"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["String"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_RMatchSummary937" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary937_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary937>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_RMatchSummary937" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary937_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_RMatchSummary937" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rmatch_RMatchSummary937" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_RMatchSummary937" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary937_avg_fields";
  Countura?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numownednonrateable?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uralandlinenumber?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_RMatchSummary937" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Avg_Order_By = {
  Countura?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numownednonrateable?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uralandlinenumber?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_RMatchSummary937". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Bool_Exp = {
  Countura?: Maybe<Bigint_Comparison_Exp>;
  Customerid?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Isincomesrcbsns?: Maybe<String_Comparison_Exp>;
  Isincomesrcemp?: Maybe<String_Comparison_Exp>;
  Isincomesrcprpty?: Maybe<String_Comparison_Exp>;
  Isincomesrcrental?: Maybe<String_Comparison_Exp>;
  Kccadob?: Maybe<Timestamptz_Comparison_Exp>;
  Kccaemail?: Maybe<String_Comparison_Exp>;
  Kccaemail1?: Maybe<String_Comparison_Exp>;
  Kccafirstname?: Maybe<String_Comparison_Exp>;
  Kccalandline?: Maybe<String_Comparison_Exp>;
  Kccamiddlename?: Maybe<String_Comparison_Exp>;
  Kccamobile1?: Maybe<String_Comparison_Exp>;
  Kccamobile2?: Maybe<String_Comparison_Exp>;
  Kccanin?: Maybe<String_Comparison_Exp>;
  Kccanin1?: Maybe<String_Comparison_Exp>;
  Kccaphones?: Maybe<String_Comparison_Exp>;
  Kccasurname?: Maybe<String_Comparison_Exp>;
  Kccatin?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Matchcategory?: Maybe<String_Comparison_Exp>;
  Matchdetails?: Maybe<String_Comparison_Exp>;
  Matchscore?: Maybe<Bigint_Comparison_Exp>;
  Matchscoreattributes?: Maybe<String_Comparison_Exp>;
  Numownednonrateable?: Maybe<Bigint_Comparison_Exp>;
  Numproperties?: Maybe<Bigint_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Sumadjustedincome?: Maybe<Float8_Comparison_Exp>;
  Sumgrossvalue?: Maybe<Float8_Comparison_Exp>;
  Sumnonrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Sumrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Totalrentedproperties?: Maybe<Bigint_Comparison_Exp>;
  Uradob?: Maybe<Timestamptz_Comparison_Exp>;
  Uraemailid?: Maybe<String_Comparison_Exp>;
  Uralandlinenumber?: Maybe<Float8_Comparison_Exp>;
  Uramidlename?: Maybe<String_Comparison_Exp>;
  Uramobilenumber?: Maybe<String_Comparison_Exp>;
  Uranationalid?: Maybe<String_Comparison_Exp>;
  Urasurname?: Maybe<String_Comparison_Exp>;
  Uratinno?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_RMatchSummary937" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Inc_Input = {
  Countura?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numownednonrateable?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uralandlinenumber?: Maybe<Scalars["float8"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_RMatchSummary937" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Insert_Input = {
  Countura?: Maybe<Scalars["bigint"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccaemail1?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccanin1?: Maybe<Scalars["String"]>;
  Kccaphones?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchdetails?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numownednonrateable?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uradob?: Maybe<Scalars["timestamptz"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Uralandlinenumber?: Maybe<Scalars["float8"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["String"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary937_max_fields";
  Countura?: Maybe<Scalars["bigint"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccaemail1?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccanin1?: Maybe<Scalars["String"]>;
  Kccaphones?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchdetails?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numownednonrateable?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uradob?: Maybe<Scalars["timestamptz"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Uralandlinenumber?: Maybe<Scalars["float8"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["String"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_RMatchSummary937" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Max_Order_By = {
  Countura?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccadob?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccaemail1?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccalandline?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccanin?: Maybe<Order_By>;
  Kccanin1?: Maybe<Order_By>;
  Kccaphones?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchcategory?: Maybe<Order_By>;
  Matchdetails?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Numownednonrateable?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uradob?: Maybe<Order_By>;
  Uraemailid?: Maybe<Order_By>;
  Uralandlinenumber?: Maybe<Order_By>;
  Uramidlename?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
  Uranationalid?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary937_min_fields";
  Countura?: Maybe<Scalars["bigint"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccaemail1?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccanin1?: Maybe<Scalars["String"]>;
  Kccaphones?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchdetails?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numownednonrateable?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uradob?: Maybe<Scalars["timestamptz"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Uralandlinenumber?: Maybe<Scalars["float8"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["String"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_RMatchSummary937" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Min_Order_By = {
  Countura?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccadob?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccaemail1?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccalandline?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccanin?: Maybe<Order_By>;
  Kccanin1?: Maybe<Order_By>;
  Kccaphones?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchcategory?: Maybe<Order_By>;
  Matchdetails?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Numownednonrateable?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uradob?: Maybe<Order_By>;
  Uraemailid?: Maybe<Order_By>;
  Uralandlinenumber?: Maybe<Order_By>;
  Uramidlename?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
  Uranationalid?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_RMatchSummary937" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary937_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary937>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_RMatchSummary937" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_RMatchSummary937_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_RMatchSummary937" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Order_By = {
  Countura?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccadob?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccaemail1?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccalandline?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccanin?: Maybe<Order_By>;
  Kccanin1?: Maybe<Order_By>;
  Kccaphones?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchcategory?: Maybe<Order_By>;
  Matchdetails?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Numownednonrateable?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uradob?: Maybe<Order_By>;
  Uraemailid?: Maybe<Order_By>;
  Uralandlinenumber?: Maybe<Order_By>;
  Uramidlename?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
  Uranationalid?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_RMatchSummary937" */
export enum Rtcs_Db_Ph_Rmatch_RMatchSummary937_Select_Column {
  /** column name */
  Countura = "Countura",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  File = "File",
  /** column name */
  Isincomesrcbsns = "Isincomesrcbsns",
  /** column name */
  Isincomesrcemp = "Isincomesrcemp",
  /** column name */
  Isincomesrcprpty = "Isincomesrcprpty",
  /** column name */
  Isincomesrcrental = "Isincomesrcrental",
  /** column name */
  Kccadob = "Kccadob",
  /** column name */
  Kccaemail = "Kccaemail",
  /** column name */
  Kccaemail1 = "Kccaemail1",
  /** column name */
  Kccafirstname = "Kccafirstname",
  /** column name */
  Kccalandline = "Kccalandline",
  /** column name */
  Kccamiddlename = "Kccamiddlename",
  /** column name */
  Kccamobile1 = "Kccamobile1",
  /** column name */
  Kccamobile2 = "Kccamobile2",
  /** column name */
  Kccanin = "Kccanin",
  /** column name */
  Kccanin1 = "Kccanin1",
  /** column name */
  Kccaphones = "Kccaphones",
  /** column name */
  Kccasurname = "Kccasurname",
  /** column name */
  Kccatin = "Kccatin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Matchcategory = "Matchcategory",
  /** column name */
  Matchdetails = "Matchdetails",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Matchscoreattributes = "Matchscoreattributes",
  /** column name */
  Numownednonrateable = "Numownednonrateable",
  /** column name */
  Numproperties = "Numproperties",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Sumadjustedincome = "Sumadjustedincome",
  /** column name */
  Sumgrossvalue = "Sumgrossvalue",
  /** column name */
  Sumnonrateablevalue = "Sumnonrateablevalue",
  /** column name */
  Sumrateablevalue = "Sumrateablevalue",
  /** column name */
  Totalrentedproperties = "Totalrentedproperties",
  /** column name */
  Uradob = "Uradob",
  /** column name */
  Uraemailid = "Uraemailid",
  /** column name */
  Uralandlinenumber = "Uralandlinenumber",
  /** column name */
  Uramidlename = "Uramidlename",
  /** column name */
  Uramobilenumber = "Uramobilenumber",
  /** column name */
  Uranationalid = "Uranationalid",
  /** column name */
  Urasurname = "Urasurname",
  /** column name */
  Uratinno = "Uratinno",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_RMatchSummary937" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Set_Input = {
  Countura?: Maybe<Scalars["bigint"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccaemail1?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccanin1?: Maybe<Scalars["String"]>;
  Kccaphones?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchdetails?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numownednonrateable?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uradob?: Maybe<Scalars["timestamptz"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Uralandlinenumber?: Maybe<Scalars["float8"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["String"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary937_stddev_fields";
  Countura?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numownednonrateable?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uralandlinenumber?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_RMatchSummary937" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Stddev_Order_By = {
  Countura?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numownednonrateable?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uralandlinenumber?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary937_stddev_pop_fields";
  Countura?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numownednonrateable?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uralandlinenumber?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_RMatchSummary937" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Stddev_Pop_Order_By = {
  Countura?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numownednonrateable?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uralandlinenumber?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary937_stddev_samp_fields";
  Countura?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numownednonrateable?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uralandlinenumber?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_RMatchSummary937" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Stddev_Samp_Order_By = {
  Countura?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numownednonrateable?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uralandlinenumber?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary937_sum_fields";
  Countura?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numownednonrateable?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uralandlinenumber?: Maybe<Scalars["float8"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_RMatchSummary937" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Sum_Order_By = {
  Countura?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numownednonrateable?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uralandlinenumber?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary937_var_pop_fields";
  Countura?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numownednonrateable?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uralandlinenumber?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_RMatchSummary937" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Var_Pop_Order_By = {
  Countura?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numownednonrateable?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uralandlinenumber?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary937_var_samp_fields";
  Countura?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numownednonrateable?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uralandlinenumber?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_RMatchSummary937" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Var_Samp_Order_By = {
  Countura?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numownednonrateable?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uralandlinenumber?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary937_variance_fields";
  Countura?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numownednonrateable?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uralandlinenumber?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_RMatchSummary937" */
export type Rtcs_Db_Ph_Rmatch_RMatchSummary937_Variance_Order_By = {
  Countura?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numownednonrateable?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uralandlinenumber?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_TinsURA" */
export type Rtcs_Db_Ph_Rmatch_TinsUra = {
  __typename?: "rtcs_db_PH_rmatch_TinsURA";
  Dob?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_TinsURA" */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_TinsURA_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_TinsUra>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_TinsURA" */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_TinsURA_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_TinsURA" */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rmatch_TinsUra_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rmatch_TinsURA" */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_TinsURA" */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_TinsUra_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_TinsURA_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_TinsURA" */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Avg_Order_By = {
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_TinsURA". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Bool_Exp = {
  Dob?: Maybe<Timestamptz_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_TinsURA" */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
  Tin?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_TinsURA" */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Insert_Input = {
  Dob?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_TinsURA_max_fields";
  Dob?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_TinsURA" */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Max_Order_By = {
  Dob?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_TinsURA_min_fields";
  Dob?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_TinsURA" */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Min_Order_By = {
  Dob?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_TinsURA" */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_TinsURA_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_TinsUra>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_TinsURA" */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_TinsUra_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_TinsURA" */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Order_By = {
  Dob?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_TinsURA" */
export enum Rtcs_Db_Ph_Rmatch_TinsUra_Select_Column {
  /** column name */
  Dob = "Dob",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Surname = "Surname",
  /** column name */
  Tin = "Tin",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_TinsURA" */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Set_Input = {
  Dob?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_TinsURA_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_TinsURA" */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_TinsURA_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_TinsURA" */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_TinsURA_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_TinsURA" */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_TinsURA_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
  Tin?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_TinsURA" */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Sum_Order_By = {
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_TinsURA_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_TinsURA" */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_TinsURA_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_TinsURA" */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_TinsURA_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_TinsURA" */
export type Rtcs_Db_Ph_Rmatch_TinsUra_Variance_Order_By = {
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_Undeclared" */
export type Rtcs_Db_Ph_Rmatch_Undeclared = {
  __typename?: "rtcs_db_PH_rmatch_Undeclared";
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_Undeclared" */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_Undeclared_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_Undeclared>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_Undeclared" */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Undeclared_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_Undeclared" */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Undeclared_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rmatch_Undeclared" */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_Undeclared" */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_Undeclared_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Undeclared_avg_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_Undeclared" */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Avg_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_Undeclared". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Bool_Exp = {
  Compliancecategory?: Maybe<String_Comparison_Exp>;
  Customerid?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Isincomesrcrental?: Maybe<String_Comparison_Exp>;
  Kccadob?: Maybe<String_Comparison_Exp>;
  Kccaemail?: Maybe<String_Comparison_Exp>;
  Kccafirstname?: Maybe<String_Comparison_Exp>;
  Kccalandline?: Maybe<String_Comparison_Exp>;
  Kccamiddlename?: Maybe<String_Comparison_Exp>;
  Kccamobile1?: Maybe<String_Comparison_Exp>;
  Kccamobile2?: Maybe<String_Comparison_Exp>;
  Kccanin?: Maybe<String_Comparison_Exp>;
  Kccasurname?: Maybe<String_Comparison_Exp>;
  Kccatin?: Maybe<Float8_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Matchscore?: Maybe<Bigint_Comparison_Exp>;
  Matchscoreattributes?: Maybe<String_Comparison_Exp>;
  Numproperties?: Maybe<Bigint_Comparison_Exp>;
  Sumgrossvalue?: Maybe<Float8_Comparison_Exp>;
  Sumrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Totalrentedproperties?: Maybe<Bigint_Comparison_Exp>;
  Uraemailid?: Maybe<String_Comparison_Exp>;
  Urafirstname?: Maybe<String_Comparison_Exp>;
  Uramidlename?: Maybe<String_Comparison_Exp>;
  Uramobilenumber?: Maybe<Float8_Comparison_Exp>;
  Uranationalid?: Maybe<String_Comparison_Exp>;
  Urasurname?: Maybe<String_Comparison_Exp>;
  Uratinno?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_Undeclared" */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Inc_Input = {
  Kccatin?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_Undeclared" */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Insert_Input = {
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Undeclared_max_fields";
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_Undeclared" */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Max_Order_By = {
  Compliancecategory?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccadob?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccalandline?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccanin?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uraemailid?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramidlename?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
  Uranationalid?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Undeclared_min_fields";
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_Undeclared" */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Min_Order_By = {
  Compliancecategory?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccadob?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccalandline?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccanin?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uraemailid?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramidlename?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
  Uranationalid?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_Undeclared" */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_Undeclared_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_Undeclared>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_Undeclared" */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_Undeclared_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_Undeclared" */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Order_By = {
  Compliancecategory?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccadob?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccalandline?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccanin?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uraemailid?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramidlename?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
  Uranationalid?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_Undeclared" */
export enum Rtcs_Db_Ph_Rmatch_Undeclared_Select_Column {
  /** column name */
  Compliancecategory = "Compliancecategory",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  File = "File",
  /** column name */
  Isincomesrcrental = "Isincomesrcrental",
  /** column name */
  Kccadob = "Kccadob",
  /** column name */
  Kccaemail = "Kccaemail",
  /** column name */
  Kccafirstname = "Kccafirstname",
  /** column name */
  Kccalandline = "Kccalandline",
  /** column name */
  Kccamiddlename = "Kccamiddlename",
  /** column name */
  Kccamobile1 = "Kccamobile1",
  /** column name */
  Kccamobile2 = "Kccamobile2",
  /** column name */
  Kccanin = "Kccanin",
  /** column name */
  Kccasurname = "Kccasurname",
  /** column name */
  Kccatin = "Kccatin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Matchscoreattributes = "Matchscoreattributes",
  /** column name */
  Numproperties = "Numproperties",
  /** column name */
  Sumgrossvalue = "Sumgrossvalue",
  /** column name */
  Sumrateablevalue = "Sumrateablevalue",
  /** column name */
  Totalrentedproperties = "Totalrentedproperties",
  /** column name */
  Uraemailid = "Uraemailid",
  /** column name */
  Urafirstname = "Urafirstname",
  /** column name */
  Uramidlename = "Uramidlename",
  /** column name */
  Uramobilenumber = "Uramobilenumber",
  /** column name */
  Uranationalid = "Uranationalid",
  /** column name */
  Urasurname = "Urasurname",
  /** column name */
  Uratinno = "Uratinno",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_Undeclared" */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Set_Input = {
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Undeclared_stddev_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_Undeclared" */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Stddev_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Undeclared_stddev_pop_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_Undeclared" */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Stddev_Pop_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Undeclared_stddev_samp_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_Undeclared" */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Stddev_Samp_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Undeclared_sum_fields";
  Kccatin?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_Undeclared" */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Sum_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Undeclared_var_pop_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_Undeclared" */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Var_Pop_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Undeclared_var_samp_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_Undeclared" */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Var_Samp_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Undeclared_variance_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_Undeclared" */
export type Rtcs_Db_Ph_Rmatch_Undeclared_Variance_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_Underreported" */
export type Rtcs_Db_Ph_Rmatch_Underreported = {
  __typename?: "rtcs_db_PH_rmatch_Underreported";
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["bigint"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["bigint"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_Underreported" */
export type Rtcs_Db_Ph_Rmatch_Underreported_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_Underreported_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_Underreported>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_Underreported" */
export type Rtcs_Db_Ph_Rmatch_Underreported_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Underreported_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_Underreported" */
export type Rtcs_Db_Ph_Rmatch_Underreported_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Underreported_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rmatch_Underreported" */
export type Rtcs_Db_Ph_Rmatch_Underreported_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_Underreported" */
export type Rtcs_Db_Ph_Rmatch_Underreported_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_Underreported_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_Underreported_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Underreported_avg_fields";
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_Underreported" */
export type Rtcs_Db_Ph_Rmatch_Underreported_Avg_Order_By = {
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_Underreported". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_Underreported_Bool_Exp = {
  Compliancecategory?: Maybe<String_Comparison_Exp>;
  Customerid?: Maybe<String_Comparison_Exp>;
  Difference?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Isamendment?: Maybe<String_Comparison_Exp>;
  Isincomesrcrental?: Maybe<String_Comparison_Exp>;
  Kccaemail?: Maybe<String_Comparison_Exp>;
  Kccamobile1?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Matchscore?: Maybe<Bigint_Comparison_Exp>;
  Matchscoreattributes?: Maybe<String_Comparison_Exp>;
  Numproperties?: Maybe<Bigint_Comparison_Exp>;
  Percentagedifference?: Maybe<Bigint_Comparison_Exp>;
  Rentincincome?: Maybe<Float8_Comparison_Exp>;
  Rtnno?: Maybe<String_Comparison_Exp>;
  Rtnperiodyear?: Maybe<String_Comparison_Exp>;
  Sumgrossvalue?: Maybe<Float8_Comparison_Exp>;
  Sumrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Totalrentedproperties?: Maybe<Bigint_Comparison_Exp>;
  Uraemailid?: Maybe<String_Comparison_Exp>;
  Urafirstname?: Maybe<String_Comparison_Exp>;
  Uramidlename?: Maybe<String_Comparison_Exp>;
  Uramobilenumber?: Maybe<Bigint_Comparison_Exp>;
  Uranationalid?: Maybe<String_Comparison_Exp>;
  Urasurname?: Maybe<String_Comparison_Exp>;
  Uratinno?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_Underreported" */
export type Rtcs_Db_Ph_Rmatch_Underreported_Inc_Input = {
  Difference?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["bigint"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uramobilenumber?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_Underreported" */
export type Rtcs_Db_Ph_Rmatch_Underreported_Insert_Input = {
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["bigint"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["bigint"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_Underreported_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Underreported_max_fields";
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["bigint"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["bigint"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_Underreported" */
export type Rtcs_Db_Ph_Rmatch_Underreported_Max_Order_By = {
  Compliancecategory?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uraemailid?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramidlename?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
  Uranationalid?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_Underreported_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Underreported_min_fields";
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["bigint"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["bigint"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_Underreported" */
export type Rtcs_Db_Ph_Rmatch_Underreported_Min_Order_By = {
  Compliancecategory?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uraemailid?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramidlename?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
  Uranationalid?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_Underreported" */
export type Rtcs_Db_Ph_Rmatch_Underreported_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_Underreported_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_Underreported>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_Underreported" */
export type Rtcs_Db_Ph_Rmatch_Underreported_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_Underreported_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_Underreported" */
export type Rtcs_Db_Ph_Rmatch_Underreported_Order_By = {
  Compliancecategory?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uraemailid?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramidlename?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
  Uranationalid?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_Underreported" */
export enum Rtcs_Db_Ph_Rmatch_Underreported_Select_Column {
  /** column name */
  Compliancecategory = "Compliancecategory",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  Difference = "Difference",
  /** column name */
  File = "File",
  /** column name */
  Isamendment = "Isamendment",
  /** column name */
  Isincomesrcrental = "Isincomesrcrental",
  /** column name */
  Kccaemail = "Kccaemail",
  /** column name */
  Kccamobile1 = "Kccamobile1",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Matchscoreattributes = "Matchscoreattributes",
  /** column name */
  Numproperties = "Numproperties",
  /** column name */
  Percentagedifference = "Percentagedifference",
  /** column name */
  Rentincincome = "Rentincincome",
  /** column name */
  Rtnno = "Rtnno",
  /** column name */
  Rtnperiodyear = "Rtnperiodyear",
  /** column name */
  Sumgrossvalue = "Sumgrossvalue",
  /** column name */
  Sumrateablevalue = "Sumrateablevalue",
  /** column name */
  Totalrentedproperties = "Totalrentedproperties",
  /** column name */
  Uraemailid = "Uraemailid",
  /** column name */
  Urafirstname = "Urafirstname",
  /** column name */
  Uramidlename = "Uramidlename",
  /** column name */
  Uramobilenumber = "Uramobilenumber",
  /** column name */
  Uranationalid = "Uranationalid",
  /** column name */
  Urasurname = "Urasurname",
  /** column name */
  Uratinno = "Uratinno",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_Underreported" */
export type Rtcs_Db_Ph_Rmatch_Underreported_Set_Input = {
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["bigint"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["bigint"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_Underreported_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Underreported_stddev_fields";
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_Underreported" */
export type Rtcs_Db_Ph_Rmatch_Underreported_Stddev_Order_By = {
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_Underreported_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Underreported_stddev_pop_fields";
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_Underreported" */
export type Rtcs_Db_Ph_Rmatch_Underreported_Stddev_Pop_Order_By = {
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_Underreported_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Underreported_stddev_samp_fields";
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_Underreported" */
export type Rtcs_Db_Ph_Rmatch_Underreported_Stddev_Samp_Order_By = {
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_Underreported_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Underreported_sum_fields";
  Difference?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["bigint"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uramobilenumber?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_Underreported" */
export type Rtcs_Db_Ph_Rmatch_Underreported_Sum_Order_By = {
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_Underreported_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Underreported_var_pop_fields";
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_Underreported" */
export type Rtcs_Db_Ph_Rmatch_Underreported_Var_Pop_Order_By = {
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_Underreported_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Underreported_var_samp_fields";
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_Underreported" */
export type Rtcs_Db_Ph_Rmatch_Underreported_Var_Samp_Order_By = {
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_Underreported_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Underreported_variance_fields";
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_Underreported" */
export type Rtcs_Db_Ph_Rmatch_Underreported_Variance_Order_By = {
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_Unregistered" */
export type Rtcs_Db_Ph_Rmatch_Unregistered = {
  __typename?: "rtcs_db_PH_rmatch_Unregistered";
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_Unregistered" */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_Unregistered_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_Unregistered>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_Unregistered" */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Unregistered_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_Unregistered" */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Unregistered_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rmatch_Unregistered" */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_Unregistered" */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_Unregistered_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Unregistered_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_Unregistered" */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Avg_Order_By = {
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_Unregistered". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Bool_Exp = {
  Compliancecategory?: Maybe<String_Comparison_Exp>;
  Customerid?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Kccadob?: Maybe<String_Comparison_Exp>;
  Kccaemail?: Maybe<String_Comparison_Exp>;
  Kccafirstname?: Maybe<String_Comparison_Exp>;
  Kccalandline?: Maybe<String_Comparison_Exp>;
  Kccamiddlename?: Maybe<String_Comparison_Exp>;
  Kccamobile1?: Maybe<String_Comparison_Exp>;
  Kccamobile2?: Maybe<String_Comparison_Exp>;
  Kccanin?: Maybe<String_Comparison_Exp>;
  Kccasurname?: Maybe<String_Comparison_Exp>;
  Kccatin?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Matchscore?: Maybe<Bigint_Comparison_Exp>;
  Numproperties?: Maybe<Bigint_Comparison_Exp>;
  Sumgrossvalue?: Maybe<Float8_Comparison_Exp>;
  Sumrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Totalrentedproperties?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_Unregistered" */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_Unregistered" */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Insert_Input = {
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Unregistered_max_fields";
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_Unregistered" */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Max_Order_By = {
  Compliancecategory?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Kccadob?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccalandline?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccanin?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Unregistered_min_fields";
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_Unregistered" */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Min_Order_By = {
  Compliancecategory?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Kccadob?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccalandline?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccanin?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_Unregistered" */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_Unregistered_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_Unregistered>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_Unregistered" */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_Unregistered_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_Unregistered" */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Order_By = {
  Compliancecategory?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Kccadob?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccalandline?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccanin?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_Unregistered" */
export enum Rtcs_Db_Ph_Rmatch_Unregistered_Select_Column {
  /** column name */
  Compliancecategory = "Compliancecategory",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  File = "File",
  /** column name */
  Kccadob = "Kccadob",
  /** column name */
  Kccaemail = "Kccaemail",
  /** column name */
  Kccafirstname = "Kccafirstname",
  /** column name */
  Kccalandline = "Kccalandline",
  /** column name */
  Kccamiddlename = "Kccamiddlename",
  /** column name */
  Kccamobile1 = "Kccamobile1",
  /** column name */
  Kccamobile2 = "Kccamobile2",
  /** column name */
  Kccanin = "Kccanin",
  /** column name */
  Kccasurname = "Kccasurname",
  /** column name */
  Kccatin = "Kccatin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Numproperties = "Numproperties",
  /** column name */
  Sumgrossvalue = "Sumgrossvalue",
  /** column name */
  Sumrateablevalue = "Sumrateablevalue",
  /** column name */
  Totalrentedproperties = "Totalrentedproperties",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_Unregistered" */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Set_Input = {
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Unregistered_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_Unregistered" */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Unregistered_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_Unregistered" */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Unregistered_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_Unregistered" */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Unregistered_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_Unregistered" */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Sum_Order_By = {
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Unregistered_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_Unregistered" */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Unregistered_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_Unregistered" */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_Unregistered_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_Unregistered" */
export type Rtcs_Db_Ph_Rmatch_Unregistered_Variance_Order_By = {
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_rmatch_mlhudConsolidated" */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated = {
  __typename?: "rtcs_db_PH_rmatch_mlhudConsolidated";
  Address?: Maybe<Scalars["String"]>;
  Baunittype?: Maybe<Scalars["String"]>;
  Blocknumber?: Maybe<Scalars["String"]>;
  CadastralKey1?: Maybe<Scalars["String"]>;
  CadastralKey2?: Maybe<Scalars["String"]>;
  Cadblocknumb?: Maybe<Scalars["String"]>;
  Cadcounty?: Maybe<Scalars["String"]>;
  Cadlatitude?: Maybe<Scalars["float8"]>;
  Cadlongitude?: Maybe<Scalars["float8"]>;
  Cadlotnumber?: Maybe<Scalars["String"]>;
  Cadroadname?: Maybe<Scalars["String"]>;
  Condoplanno?: Maybe<Scalars["String"]>;
  Condounitfactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Lafnumber?: Maybe<Scalars["String"]>;
  Landtype?: Maybe<Scalars["String"]>;
  Landusetype?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalarea?: Maybe<Scalars["float8"]>;
  Legalareaunittype?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchResult?: Maybe<Scalars["String"]>;
  Middlename?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Registrationdatetime?: Maybe<Scalars["timestamptz"]>;
  Roadname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenuretype?: Maybe<Scalars["String"]>;
  Titlelocation?: Maybe<Scalars["String"]>;
  Titlenumber?: Maybe<Scalars["float8"]>;
  UgnlisKey1?: Maybe<Scalars["String"]>;
  UgnlisKey2?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_rmatch_mlhudConsolidated" */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Aggregate = {
  __typename?: "rtcs_db_PH_rmatch_mlhudConsolidated_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Rmatch_MlhudConsolidated>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_mlhudConsolidated" */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_rmatch_mlhudConsolidated_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_rmatch_mlhudConsolidated" */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_rmatch_mlhudConsolidated" */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_rmatch_mlhudConsolidated" */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Avg_Fields = {
  __typename?: "rtcs_db_PH_rmatch_mlhudConsolidated_avg_fields";
  Cadlatitude?: Maybe<Scalars["Float"]>;
  Cadlongitude?: Maybe<Scalars["Float"]>;
  Condounitfactor?: Maybe<Scalars["Float"]>;
  Legalarea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Titlenumber?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_rmatch_mlhudConsolidated" */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Avg_Order_By = {
  Cadlatitude?: Maybe<Order_By>;
  Cadlongitude?: Maybe<Order_By>;
  Condounitfactor?: Maybe<Order_By>;
  Legalarea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Titlenumber?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_rmatch_mlhudConsolidated". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  Baunittype?: Maybe<String_Comparison_Exp>;
  Blocknumber?: Maybe<String_Comparison_Exp>;
  CadastralKey1?: Maybe<String_Comparison_Exp>;
  CadastralKey2?: Maybe<String_Comparison_Exp>;
  Cadblocknumb?: Maybe<String_Comparison_Exp>;
  Cadcounty?: Maybe<String_Comparison_Exp>;
  Cadlatitude?: Maybe<Float8_Comparison_Exp>;
  Cadlongitude?: Maybe<Float8_Comparison_Exp>;
  Cadlotnumber?: Maybe<String_Comparison_Exp>;
  Cadroadname?: Maybe<String_Comparison_Exp>;
  Condoplanno?: Maybe<String_Comparison_Exp>;
  Condounitfactor?: Maybe<Float8_Comparison_Exp>;
  County?: Maybe<String_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Folio?: Maybe<String_Comparison_Exp>;
  Fullname?: Maybe<String_Comparison_Exp>;
  Gender?: Maybe<String_Comparison_Exp>;
  Lafnumber?: Maybe<String_Comparison_Exp>;
  Landtype?: Maybe<String_Comparison_Exp>;
  Landusetype?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Lastname?: Maybe<String_Comparison_Exp>;
  Legalarea?: Maybe<Float8_Comparison_Exp>;
  Legalareaunittype?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MatchResult?: Maybe<String_Comparison_Exp>;
  Middlename?: Maybe<String_Comparison_Exp>;
  Municipality?: Maybe<String_Comparison_Exp>;
  Phone?: Maybe<String_Comparison_Exp>;
  Plotno?: Maybe<String_Comparison_Exp>;
  Registrationdatetime?: Maybe<Timestamptz_Comparison_Exp>;
  Roadname?: Maybe<String_Comparison_Exp>;
  Subcounty?: Maybe<String_Comparison_Exp>;
  Tenuretype?: Maybe<String_Comparison_Exp>;
  Titlelocation?: Maybe<String_Comparison_Exp>;
  Titlenumber?: Maybe<Float8_Comparison_Exp>;
  UgnlisKey1?: Maybe<String_Comparison_Exp>;
  UgnlisKey2?: Maybe<String_Comparison_Exp>;
  Volume?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_rmatch_mlhudConsolidated" */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Inc_Input = {
  Cadlatitude?: Maybe<Scalars["float8"]>;
  Cadlongitude?: Maybe<Scalars["float8"]>;
  Condounitfactor?: Maybe<Scalars["float8"]>;
  Legalarea?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Titlenumber?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_rmatch_mlhudConsolidated" */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  Baunittype?: Maybe<Scalars["String"]>;
  Blocknumber?: Maybe<Scalars["String"]>;
  CadastralKey1?: Maybe<Scalars["String"]>;
  CadastralKey2?: Maybe<Scalars["String"]>;
  Cadblocknumb?: Maybe<Scalars["String"]>;
  Cadcounty?: Maybe<Scalars["String"]>;
  Cadlatitude?: Maybe<Scalars["float8"]>;
  Cadlongitude?: Maybe<Scalars["float8"]>;
  Cadlotnumber?: Maybe<Scalars["String"]>;
  Cadroadname?: Maybe<Scalars["String"]>;
  Condoplanno?: Maybe<Scalars["String"]>;
  Condounitfactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Lafnumber?: Maybe<Scalars["String"]>;
  Landtype?: Maybe<Scalars["String"]>;
  Landusetype?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalarea?: Maybe<Scalars["float8"]>;
  Legalareaunittype?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchResult?: Maybe<Scalars["String"]>;
  Middlename?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Registrationdatetime?: Maybe<Scalars["timestamptz"]>;
  Roadname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenuretype?: Maybe<Scalars["String"]>;
  Titlelocation?: Maybe<Scalars["String"]>;
  Titlenumber?: Maybe<Scalars["float8"]>;
  UgnlisKey1?: Maybe<Scalars["String"]>;
  UgnlisKey2?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Max_Fields = {
  __typename?: "rtcs_db_PH_rmatch_mlhudConsolidated_max_fields";
  Address?: Maybe<Scalars["String"]>;
  Baunittype?: Maybe<Scalars["String"]>;
  Blocknumber?: Maybe<Scalars["String"]>;
  CadastralKey1?: Maybe<Scalars["String"]>;
  CadastralKey2?: Maybe<Scalars["String"]>;
  Cadblocknumb?: Maybe<Scalars["String"]>;
  Cadcounty?: Maybe<Scalars["String"]>;
  Cadlatitude?: Maybe<Scalars["float8"]>;
  Cadlongitude?: Maybe<Scalars["float8"]>;
  Cadlotnumber?: Maybe<Scalars["String"]>;
  Cadroadname?: Maybe<Scalars["String"]>;
  Condoplanno?: Maybe<Scalars["String"]>;
  Condounitfactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Lafnumber?: Maybe<Scalars["String"]>;
  Landtype?: Maybe<Scalars["String"]>;
  Landusetype?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalarea?: Maybe<Scalars["float8"]>;
  Legalareaunittype?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchResult?: Maybe<Scalars["String"]>;
  Middlename?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Registrationdatetime?: Maybe<Scalars["timestamptz"]>;
  Roadname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenuretype?: Maybe<Scalars["String"]>;
  Titlelocation?: Maybe<Scalars["String"]>;
  Titlenumber?: Maybe<Scalars["float8"]>;
  UgnlisKey1?: Maybe<Scalars["String"]>;
  UgnlisKey2?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_rmatch_mlhudConsolidated" */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Max_Order_By = {
  Address?: Maybe<Order_By>;
  Baunittype?: Maybe<Order_By>;
  Blocknumber?: Maybe<Order_By>;
  CadastralKey1?: Maybe<Order_By>;
  CadastralKey2?: Maybe<Order_By>;
  Cadblocknumb?: Maybe<Order_By>;
  Cadcounty?: Maybe<Order_By>;
  Cadlatitude?: Maybe<Order_By>;
  Cadlongitude?: Maybe<Order_By>;
  Cadlotnumber?: Maybe<Order_By>;
  Cadroadname?: Maybe<Order_By>;
  Condoplanno?: Maybe<Order_By>;
  Condounitfactor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  Lafnumber?: Maybe<Order_By>;
  Landtype?: Maybe<Order_By>;
  Landusetype?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastname?: Maybe<Order_By>;
  Legalarea?: Maybe<Order_By>;
  Legalareaunittype?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchResult?: Maybe<Order_By>;
  Middlename?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Registrationdatetime?: Maybe<Order_By>;
  Roadname?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Tenuretype?: Maybe<Order_By>;
  Titlelocation?: Maybe<Order_By>;
  Titlenumber?: Maybe<Order_By>;
  UgnlisKey1?: Maybe<Order_By>;
  UgnlisKey2?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Min_Fields = {
  __typename?: "rtcs_db_PH_rmatch_mlhudConsolidated_min_fields";
  Address?: Maybe<Scalars["String"]>;
  Baunittype?: Maybe<Scalars["String"]>;
  Blocknumber?: Maybe<Scalars["String"]>;
  CadastralKey1?: Maybe<Scalars["String"]>;
  CadastralKey2?: Maybe<Scalars["String"]>;
  Cadblocknumb?: Maybe<Scalars["String"]>;
  Cadcounty?: Maybe<Scalars["String"]>;
  Cadlatitude?: Maybe<Scalars["float8"]>;
  Cadlongitude?: Maybe<Scalars["float8"]>;
  Cadlotnumber?: Maybe<Scalars["String"]>;
  Cadroadname?: Maybe<Scalars["String"]>;
  Condoplanno?: Maybe<Scalars["String"]>;
  Condounitfactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Lafnumber?: Maybe<Scalars["String"]>;
  Landtype?: Maybe<Scalars["String"]>;
  Landusetype?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalarea?: Maybe<Scalars["float8"]>;
  Legalareaunittype?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchResult?: Maybe<Scalars["String"]>;
  Middlename?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Registrationdatetime?: Maybe<Scalars["timestamptz"]>;
  Roadname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenuretype?: Maybe<Scalars["String"]>;
  Titlelocation?: Maybe<Scalars["String"]>;
  Titlenumber?: Maybe<Scalars["float8"]>;
  UgnlisKey1?: Maybe<Scalars["String"]>;
  UgnlisKey2?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_rmatch_mlhudConsolidated" */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Min_Order_By = {
  Address?: Maybe<Order_By>;
  Baunittype?: Maybe<Order_By>;
  Blocknumber?: Maybe<Order_By>;
  CadastralKey1?: Maybe<Order_By>;
  CadastralKey2?: Maybe<Order_By>;
  Cadblocknumb?: Maybe<Order_By>;
  Cadcounty?: Maybe<Order_By>;
  Cadlatitude?: Maybe<Order_By>;
  Cadlongitude?: Maybe<Order_By>;
  Cadlotnumber?: Maybe<Order_By>;
  Cadroadname?: Maybe<Order_By>;
  Condoplanno?: Maybe<Order_By>;
  Condounitfactor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  Lafnumber?: Maybe<Order_By>;
  Landtype?: Maybe<Order_By>;
  Landusetype?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastname?: Maybe<Order_By>;
  Legalarea?: Maybe<Order_By>;
  Legalareaunittype?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchResult?: Maybe<Order_By>;
  Middlename?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Registrationdatetime?: Maybe<Order_By>;
  Roadname?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Tenuretype?: Maybe<Order_By>;
  Titlelocation?: Maybe<Order_By>;
  Titlenumber?: Maybe<Order_By>;
  UgnlisKey1?: Maybe<Order_By>;
  UgnlisKey2?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_rmatch_mlhudConsolidated" */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Mutation_Response = {
  __typename?: "rtcs_db_PH_rmatch_mlhudConsolidated_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Rmatch_MlhudConsolidated>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_rmatch_mlhudConsolidated" */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_rmatch_mlhudConsolidated" */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Order_By = {
  Address?: Maybe<Order_By>;
  Baunittype?: Maybe<Order_By>;
  Blocknumber?: Maybe<Order_By>;
  CadastralKey1?: Maybe<Order_By>;
  CadastralKey2?: Maybe<Order_By>;
  Cadblocknumb?: Maybe<Order_By>;
  Cadcounty?: Maybe<Order_By>;
  Cadlatitude?: Maybe<Order_By>;
  Cadlongitude?: Maybe<Order_By>;
  Cadlotnumber?: Maybe<Order_By>;
  Cadroadname?: Maybe<Order_By>;
  Condoplanno?: Maybe<Order_By>;
  Condounitfactor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  Lafnumber?: Maybe<Order_By>;
  Landtype?: Maybe<Order_By>;
  Landusetype?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastname?: Maybe<Order_By>;
  Legalarea?: Maybe<Order_By>;
  Legalareaunittype?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchResult?: Maybe<Order_By>;
  Middlename?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Registrationdatetime?: Maybe<Order_By>;
  Roadname?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Tenuretype?: Maybe<Order_By>;
  Titlelocation?: Maybe<Order_By>;
  Titlenumber?: Maybe<Order_By>;
  UgnlisKey1?: Maybe<Order_By>;
  UgnlisKey2?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_rmatch_mlhudConsolidated" */
export enum Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  Baunittype = "Baunittype",
  /** column name */
  Blocknumber = "Blocknumber",
  /** column name */
  CadastralKey1 = "CadastralKey1",
  /** column name */
  CadastralKey2 = "CadastralKey2",
  /** column name */
  Cadblocknumb = "Cadblocknumb",
  /** column name */
  Cadcounty = "Cadcounty",
  /** column name */
  Cadlatitude = "Cadlatitude",
  /** column name */
  Cadlongitude = "Cadlongitude",
  /** column name */
  Cadlotnumber = "Cadlotnumber",
  /** column name */
  Cadroadname = "Cadroadname",
  /** column name */
  Condoplanno = "Condoplanno",
  /** column name */
  Condounitfactor = "Condounitfactor",
  /** column name */
  County = "County",
  /** column name */
  Description = "Description",
  /** column name */
  District = "District",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Folio = "Folio",
  /** column name */
  Fullname = "Fullname",
  /** column name */
  Gender = "Gender",
  /** column name */
  Lafnumber = "Lafnumber",
  /** column name */
  Landtype = "Landtype",
  /** column name */
  Landusetype = "Landusetype",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Lastname = "Lastname",
  /** column name */
  Legalarea = "Legalarea",
  /** column name */
  Legalareaunittype = "Legalareaunittype",
  /** column name */
  Line = "Line",
  /** column name */
  MatchResult = "MatchResult",
  /** column name */
  Middlename = "Middlename",
  /** column name */
  Municipality = "Municipality",
  /** column name */
  Phone = "Phone",
  /** column name */
  Plotno = "Plotno",
  /** column name */
  Registrationdatetime = "Registrationdatetime",
  /** column name */
  Roadname = "Roadname",
  /** column name */
  Subcounty = "Subcounty",
  /** column name */
  Tenuretype = "Tenuretype",
  /** column name */
  Titlelocation = "Titlelocation",
  /** column name */
  Titlenumber = "Titlenumber",
  /** column name */
  UgnlisKey1 = "UgnlisKey1",
  /** column name */
  UgnlisKey2 = "UgnlisKey2",
  /** column name */
  Volume = "Volume",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_rmatch_mlhudConsolidated" */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  Baunittype?: Maybe<Scalars["String"]>;
  Blocknumber?: Maybe<Scalars["String"]>;
  CadastralKey1?: Maybe<Scalars["String"]>;
  CadastralKey2?: Maybe<Scalars["String"]>;
  Cadblocknumb?: Maybe<Scalars["String"]>;
  Cadcounty?: Maybe<Scalars["String"]>;
  Cadlatitude?: Maybe<Scalars["float8"]>;
  Cadlongitude?: Maybe<Scalars["float8"]>;
  Cadlotnumber?: Maybe<Scalars["String"]>;
  Cadroadname?: Maybe<Scalars["String"]>;
  Condoplanno?: Maybe<Scalars["String"]>;
  Condounitfactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Lafnumber?: Maybe<Scalars["String"]>;
  Landtype?: Maybe<Scalars["String"]>;
  Landusetype?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalarea?: Maybe<Scalars["float8"]>;
  Legalareaunittype?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchResult?: Maybe<Scalars["String"]>;
  Middlename?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Registrationdatetime?: Maybe<Scalars["timestamptz"]>;
  Roadname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenuretype?: Maybe<Scalars["String"]>;
  Titlelocation?: Maybe<Scalars["String"]>;
  Titlenumber?: Maybe<Scalars["float8"]>;
  UgnlisKey1?: Maybe<Scalars["String"]>;
  UgnlisKey2?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Stddev_Fields = {
  __typename?: "rtcs_db_PH_rmatch_mlhudConsolidated_stddev_fields";
  Cadlatitude?: Maybe<Scalars["Float"]>;
  Cadlongitude?: Maybe<Scalars["Float"]>;
  Condounitfactor?: Maybe<Scalars["Float"]>;
  Legalarea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Titlenumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_rmatch_mlhudConsolidated" */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Stddev_Order_By = {
  Cadlatitude?: Maybe<Order_By>;
  Cadlongitude?: Maybe<Order_By>;
  Condounitfactor?: Maybe<Order_By>;
  Legalarea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Titlenumber?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_mlhudConsolidated_stddev_pop_fields";
  Cadlatitude?: Maybe<Scalars["Float"]>;
  Cadlongitude?: Maybe<Scalars["Float"]>;
  Condounitfactor?: Maybe<Scalars["Float"]>;
  Legalarea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Titlenumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_rmatch_mlhudConsolidated" */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Stddev_Pop_Order_By = {
  Cadlatitude?: Maybe<Order_By>;
  Cadlongitude?: Maybe<Order_By>;
  Condounitfactor?: Maybe<Order_By>;
  Legalarea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Titlenumber?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_mlhudConsolidated_stddev_samp_fields";
  Cadlatitude?: Maybe<Scalars["Float"]>;
  Cadlongitude?: Maybe<Scalars["Float"]>;
  Condounitfactor?: Maybe<Scalars["Float"]>;
  Legalarea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Titlenumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_rmatch_mlhudConsolidated" */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Stddev_Samp_Order_By = {
  Cadlatitude?: Maybe<Order_By>;
  Cadlongitude?: Maybe<Order_By>;
  Condounitfactor?: Maybe<Order_By>;
  Legalarea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Titlenumber?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Sum_Fields = {
  __typename?: "rtcs_db_PH_rmatch_mlhudConsolidated_sum_fields";
  Cadlatitude?: Maybe<Scalars["float8"]>;
  Cadlongitude?: Maybe<Scalars["float8"]>;
  Condounitfactor?: Maybe<Scalars["float8"]>;
  Legalarea?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Titlenumber?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_rmatch_mlhudConsolidated" */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Sum_Order_By = {
  Cadlatitude?: Maybe<Order_By>;
  Cadlongitude?: Maybe<Order_By>;
  Condounitfactor?: Maybe<Order_By>;
  Legalarea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Titlenumber?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_rmatch_mlhudConsolidated_var_pop_fields";
  Cadlatitude?: Maybe<Scalars["Float"]>;
  Cadlongitude?: Maybe<Scalars["Float"]>;
  Condounitfactor?: Maybe<Scalars["Float"]>;
  Legalarea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Titlenumber?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_rmatch_mlhudConsolidated" */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Var_Pop_Order_By = {
  Cadlatitude?: Maybe<Order_By>;
  Cadlongitude?: Maybe<Order_By>;
  Condounitfactor?: Maybe<Order_By>;
  Legalarea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Titlenumber?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_rmatch_mlhudConsolidated_var_samp_fields";
  Cadlatitude?: Maybe<Scalars["Float"]>;
  Cadlongitude?: Maybe<Scalars["Float"]>;
  Condounitfactor?: Maybe<Scalars["Float"]>;
  Legalarea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Titlenumber?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_rmatch_mlhudConsolidated" */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Var_Samp_Order_By = {
  Cadlatitude?: Maybe<Order_By>;
  Cadlongitude?: Maybe<Order_By>;
  Condounitfactor?: Maybe<Order_By>;
  Legalarea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Titlenumber?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Variance_Fields = {
  __typename?: "rtcs_db_PH_rmatch_mlhudConsolidated_variance_fields";
  Cadlatitude?: Maybe<Scalars["Float"]>;
  Cadlongitude?: Maybe<Scalars["Float"]>;
  Condounitfactor?: Maybe<Scalars["Float"]>;
  Legalarea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Titlenumber?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_rmatch_mlhudConsolidated" */
export type Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Variance_Order_By = {
  Cadlatitude?: Maybe<Order_By>;
  Cadlongitude?: Maybe<Order_By>;
  Condounitfactor?: Maybe<Order_By>;
  Legalarea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Titlenumber?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoMunicipality";
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Aggregate = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoMunicipality_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality>;
};

/** aggregate fields of "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoMunicipality_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Avg_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoMunicipality_avg_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Avg_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_streetmap_OsmMukonoMunicipality". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Bool_Exp = {
  Amenity?: Maybe<String_Comparison_Exp>;
  Building?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Housenumber?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  OpeningHours?: Maybe<String_Comparison_Exp>;
  Shop?: Maybe<String_Comparison_Exp>;
  Street?: Maybe<String_Comparison_Exp>;
  Tourism?: Maybe<String_Comparison_Exp>;
  Uid?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Inc_Input = {
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Uid?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Insert_Input = {
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Max_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoMunicipality_max_fields";
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Max_Order_By = {
  Amenity?: Maybe<Order_By>;
  Building?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Min_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoMunicipality_min_fields";
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Min_Order_By = {
  Amenity?: Maybe<Order_By>;
  Building?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Mutation_Response = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoMunicipality_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Order_By = {
  Amenity?: Maybe<Order_By>;
  Building?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
export enum Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Select_Column {
  /** column name */
  Amenity = "Amenity",
  /** column name */
  Building = "Building",
  /** column name */
  File = "File",
  /** column name */
  Housenumber = "Housenumber",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  Name = "Name",
  /** column name */
  OpeningHours = "OpeningHours",
  /** column name */
  Shop = "Shop",
  /** column name */
  Street = "Street",
  /** column name */
  Tourism = "Tourism",
  /** column name */
  Uid = "Uid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Set_Input = {
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Stddev_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoMunicipality_stddev_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Stddev_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoMunicipality_stddev_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Stddev_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoMunicipality_stddev_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Stddev_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Sum_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoMunicipality_sum_fields";
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Uid?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Sum_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoMunicipality_var_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Var_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoMunicipality_var_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Var_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Variance_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoMunicipality_variance_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Variance_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoNakifuma";
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Aggregate = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoNakifuma_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma>;
};

/** aggregate fields of "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoNakifuma_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Avg_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoNakifuma_avg_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Avg_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_streetmap_OsmMukonoNakifuma". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Bool_Exp = {
  Amenity?: Maybe<String_Comparison_Exp>;
  Building?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Housenumber?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  OpeningHours?: Maybe<String_Comparison_Exp>;
  Shop?: Maybe<String_Comparison_Exp>;
  Street?: Maybe<String_Comparison_Exp>;
  Tourism?: Maybe<String_Comparison_Exp>;
  Uid?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Inc_Input = {
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Uid?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Insert_Input = {
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Max_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoNakifuma_max_fields";
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Max_Order_By = {
  Amenity?: Maybe<Order_By>;
  Building?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Min_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoNakifuma_min_fields";
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Min_Order_By = {
  Amenity?: Maybe<Order_By>;
  Building?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Mutation_Response = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoNakifuma_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Order_By = {
  Amenity?: Maybe<Order_By>;
  Building?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
export enum Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Select_Column {
  /** column name */
  Amenity = "Amenity",
  /** column name */
  Building = "Building",
  /** column name */
  File = "File",
  /** column name */
  Housenumber = "Housenumber",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  Name = "Name",
  /** column name */
  OpeningHours = "OpeningHours",
  /** column name */
  Shop = "Shop",
  /** column name */
  Street = "Street",
  /** column name */
  Tourism = "Tourism",
  /** column name */
  Uid = "Uid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Set_Input = {
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Stddev_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoNakifuma_stddev_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Stddev_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoNakifuma_stddev_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Stddev_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoNakifuma_stddev_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Stddev_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Sum_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoNakifuma_sum_fields";
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Uid?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Sum_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoNakifuma_var_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Var_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoNakifuma_var_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Var_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Variance_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmMukonoNakifuma_variance_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Variance_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoEntebbe";
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Rooms?: Maybe<Scalars["float8"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Aggregate = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoEntebbe_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe>;
};

/** aggregate fields of "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoEntebbe_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Avg_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoEntebbe_avg_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Rooms?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Avg_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Rooms?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_streetmap_OsmWakisoEntebbe". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Bool_Exp = {
  Amenity?: Maybe<String_Comparison_Exp>;
  Building?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Housenumber?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  OpeningHours?: Maybe<String_Comparison_Exp>;
  Rooms?: Maybe<Float8_Comparison_Exp>;
  Shop?: Maybe<String_Comparison_Exp>;
  Street?: Maybe<String_Comparison_Exp>;
  Tourism?: Maybe<String_Comparison_Exp>;
  Uid?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Inc_Input = {
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Rooms?: Maybe<Scalars["float8"]>;
  Uid?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Insert_Input = {
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Rooms?: Maybe<Scalars["float8"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Max_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoEntebbe_max_fields";
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Rooms?: Maybe<Scalars["float8"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Max_Order_By = {
  Amenity?: Maybe<Order_By>;
  Building?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Rooms?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Min_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoEntebbe_min_fields";
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Rooms?: Maybe<Scalars["float8"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Min_Order_By = {
  Amenity?: Maybe<Order_By>;
  Building?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Rooms?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Mutation_Response = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoEntebbe_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Order_By = {
  Amenity?: Maybe<Order_By>;
  Building?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Rooms?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
export enum Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Select_Column {
  /** column name */
  Amenity = "Amenity",
  /** column name */
  Building = "Building",
  /** column name */
  File = "File",
  /** column name */
  Housenumber = "Housenumber",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  Name = "Name",
  /** column name */
  OpeningHours = "OpeningHours",
  /** column name */
  Rooms = "Rooms",
  /** column name */
  Shop = "Shop",
  /** column name */
  Street = "Street",
  /** column name */
  Tourism = "Tourism",
  /** column name */
  Uid = "Uid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Set_Input = {
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Rooms?: Maybe<Scalars["float8"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Stddev_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoEntebbe_stddev_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Rooms?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Stddev_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Rooms?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoEntebbe_stddev_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Rooms?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Stddev_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Rooms?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoEntebbe_stddev_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Rooms?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Stddev_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Rooms?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Sum_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoEntebbe_sum_fields";
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Rooms?: Maybe<Scalars["float8"]>;
  Uid?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Sum_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Rooms?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoEntebbe_var_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Rooms?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Var_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Rooms?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoEntebbe_var_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Rooms?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Var_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Rooms?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Variance_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoEntebbe_variance_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Rooms?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Variance_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Rooms?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_streetmap_OsmWakisoKira" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoKira";
  Amenity?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_streetmap_OsmWakisoKira" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Aggregate = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoKira_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoKira>;
};

/** aggregate fields of "rtcs_db.PH_streetmap_OsmWakisoKira" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoKira_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_streetmap_OsmWakisoKira" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_streetmap_OsmWakisoKira" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_streetmap_OsmWakisoKira" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Avg_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoKira_avg_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_streetmap_OsmWakisoKira" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Avg_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_streetmap_OsmWakisoKira". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Bool_Exp = {
  Amenity?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Housenumber?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  OpeningHours?: Maybe<String_Comparison_Exp>;
  Shop?: Maybe<String_Comparison_Exp>;
  Street?: Maybe<String_Comparison_Exp>;
  Tourism?: Maybe<String_Comparison_Exp>;
  Uid?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_streetmap_OsmWakisoKira" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Inc_Input = {
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Uid?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_streetmap_OsmWakisoKira" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Insert_Input = {
  Amenity?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Max_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoKira_max_fields";
  Amenity?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_streetmap_OsmWakisoKira" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Max_Order_By = {
  Amenity?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Min_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoKira_min_fields";
  Amenity?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_streetmap_OsmWakisoKira" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Min_Order_By = {
  Amenity?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_streetmap_OsmWakisoKira" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Mutation_Response = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoKira_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoKira>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_streetmap_OsmWakisoKira" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_streetmap_OsmWakisoKira" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Order_By = {
  Amenity?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_streetmap_OsmWakisoKira" */
export enum Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Select_Column {
  /** column name */
  Amenity = "Amenity",
  /** column name */
  File = "File",
  /** column name */
  Housenumber = "Housenumber",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  Name = "Name",
  /** column name */
  OpeningHours = "OpeningHours",
  /** column name */
  Shop = "Shop",
  /** column name */
  Street = "Street",
  /** column name */
  Tourism = "Tourism",
  /** column name */
  Uid = "Uid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_streetmap_OsmWakisoKira" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Set_Input = {
  Amenity?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Stddev_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoKira_stddev_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_streetmap_OsmWakisoKira" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Stddev_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoKira_stddev_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_streetmap_OsmWakisoKira" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Stddev_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoKira_stddev_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_streetmap_OsmWakisoKira" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Stddev_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Sum_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoKira_sum_fields";
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Uid?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_streetmap_OsmWakisoKira" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Sum_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoKira_var_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_streetmap_OsmWakisoKira" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Var_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoKira_var_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_streetmap_OsmWakisoKira" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Var_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Variance_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoKira_variance_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_streetmap_OsmWakisoKira" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Variance_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoMakindyeSsabagabo";
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Aggregate = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoMakindyeSsabagabo_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo>;
};

/** aggregate fields of "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoMakindyeSsabagabo_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Aggregate_Order_By =
  {
    avg?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Max_Order_By>;
    min?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Min_Order_By>;
    stddev?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Stddev_Order_By>;
    stddev_pop?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Stddev_Samp_Order_By>;
    sum?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Sum_Order_By>;
    var_pop?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Var_Pop_Order_By>;
    var_samp?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Var_Samp_Order_By>;
    variance?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Variance_Order_By>;
  };

/** input type for inserting array relation for remote table "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Avg_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoMakindyeSsabagabo_avg_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Avg_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Bool_Exp = {
  Amenity?: Maybe<String_Comparison_Exp>;
  Building?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Housenumber?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  OpeningHours?: Maybe<String_Comparison_Exp>;
  Shop?: Maybe<String_Comparison_Exp>;
  Street?: Maybe<String_Comparison_Exp>;
  Tourism?: Maybe<String_Comparison_Exp>;
  Uid?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Inc_Input = {
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Uid?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Insert_Input = {
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Max_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoMakindyeSsabagabo_max_fields";
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Max_Order_By = {
  Amenity?: Maybe<Order_By>;
  Building?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Min_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoMakindyeSsabagabo_min_fields";
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Min_Order_By = {
  Amenity?: Maybe<Order_By>;
  Building?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Mutation_Response =
  {
    __typename?: "rtcs_db_PH_streetmap_OsmWakisoMakindyeSsabagabo_mutation_response";
    /** number of affected rows by the mutation */
    affected_rows: Scalars["Int"];
    /** data of the affected rows by the mutation */
    returning: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo>;
  };

/** input type for inserting object relation for remote table "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Order_By = {
  Amenity?: Maybe<Order_By>;
  Building?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
export enum Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Select_Column {
  /** column name */
  Amenity = "Amenity",
  /** column name */
  Building = "Building",
  /** column name */
  File = "File",
  /** column name */
  Housenumber = "Housenumber",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  Name = "Name",
  /** column name */
  OpeningHours = "OpeningHours",
  /** column name */
  Shop = "Shop",
  /** column name */
  Street = "Street",
  /** column name */
  Tourism = "Tourism",
  /** column name */
  Uid = "Uid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Set_Input = {
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Stddev_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoMakindyeSsabagabo_stddev_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Stddev_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Stddev_Pop_Fields =
  {
    __typename?: "rtcs_db_PH_streetmap_OsmWakisoMakindyeSsabagabo_stddev_pop_fields";
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    Uid?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_pop() on columns of table "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Stddev_Pop_Order_By =
  {
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Uid?: Maybe<Order_By>;
  };

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Stddev_Samp_Fields =
  {
    __typename?: "rtcs_db_PH_streetmap_OsmWakisoMakindyeSsabagabo_stddev_samp_fields";
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    Uid?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_samp() on columns of table "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Stddev_Samp_Order_By =
  {
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Uid?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Sum_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoMakindyeSsabagabo_sum_fields";
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Uid?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Sum_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoMakindyeSsabagabo_var_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Var_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoMakindyeSsabagabo_var_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Var_Samp_Order_By =
  {
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Uid?: Maybe<Order_By>;
  };

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Variance_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoMakindyeSsabagabo_variance_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Variance_Order_By =
  {
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Uid?: Maybe<Order_By>;
  };

/** columns and relationships of "rtcs_db.PH_streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoNansana";
  Amenity?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Aggregate = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoNansana_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana>;
};

/** aggregate fields of "rtcs_db.PH_streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoNansana_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Avg_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoNansana_avg_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Avg_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_streetmap_OsmWakisoNansana". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Bool_Exp = {
  Amenity?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  OpeningHours?: Maybe<String_Comparison_Exp>;
  Shop?: Maybe<String_Comparison_Exp>;
  Street?: Maybe<String_Comparison_Exp>;
  Tourism?: Maybe<String_Comparison_Exp>;
  Uid?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Inc_Input = {
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Uid?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Insert_Input = {
  Amenity?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Max_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoNansana_max_fields";
  Amenity?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Max_Order_By = {
  Amenity?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Min_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoNansana_min_fields";
  Amenity?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Min_Order_By = {
  Amenity?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Mutation_Response = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoNansana_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Order_By = {
  Amenity?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_streetmap_OsmWakisoNansana" */
export enum Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Select_Column {
  /** column name */
  Amenity = "Amenity",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  Name = "Name",
  /** column name */
  OpeningHours = "OpeningHours",
  /** column name */
  Shop = "Shop",
  /** column name */
  Street = "Street",
  /** column name */
  Tourism = "Tourism",
  /** column name */
  Uid = "Uid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Set_Input = {
  Amenity?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Stddev_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoNansana_stddev_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Stddev_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoNansana_stddev_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Stddev_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoNansana_stddev_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Stddev_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Sum_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoNansana_sum_fields";
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Uid?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Sum_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoNansana_var_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Var_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoNansana_var_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Var_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Variance_Fields = {
  __typename?: "rtcs_db_PH_streetmap_OsmWakisoNansana_variance_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Variance_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_ucc_UCC" */
export type Rtcs_Db_Ph_Ucc_Ucc = {
  __typename?: "rtcs_db_PH_ucc_UCC";
  Carrier?: Maybe<Scalars["String"]>;
  Count?: Maybe<Scalars["bigint"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Idnumber?: Maybe<Scalars["String"]>;
  Idtype?: Maybe<Scalars["String"]>;
  Kccamobile?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ucc_UCC" */
export type Rtcs_Db_Ph_Ucc_Ucc_Aggregate = {
  __typename?: "rtcs_db_PH_ucc_UCC_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ucc_Ucc>;
};

/** aggregate fields of "rtcs_db.PH_ucc_UCC" */
export type Rtcs_Db_Ph_Ucc_Ucc_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ucc_UCC_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ucc_UCC" */
export type Rtcs_Db_Ph_Ucc_Ucc_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Ucc_Ucc_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_ucc_UCC" */
export type Rtcs_Db_Ph_Ucc_Ucc_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_ucc_UCC" */
export type Rtcs_Db_Ph_Ucc_Ucc_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Ucc_Ucc_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ucc_Ucc_Avg_Fields = {
  __typename?: "rtcs_db_PH_ucc_UCC_avg_fields";
  Count?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ucc_UCC" */
export type Rtcs_Db_Ph_Ucc_Ucc_Avg_Order_By = {
  Count?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ucc_UCC". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ucc_Ucc_Bool_Exp = {
  Carrier?: Maybe<String_Comparison_Exp>;
  Count?: Maybe<Bigint_Comparison_Exp>;
  Customerid?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Idnumber?: Maybe<String_Comparison_Exp>;
  Idtype?: Maybe<String_Comparison_Exp>;
  Kccamobile?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Uuid?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ucc_Ucc_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ucc_Ucc_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ucc_UCC" */
export type Rtcs_Db_Ph_Ucc_Ucc_Inc_Input = {
  Count?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ucc_UCC" */
export type Rtcs_Db_Ph_Ucc_Ucc_Insert_Input = {
  Carrier?: Maybe<Scalars["String"]>;
  Count?: Maybe<Scalars["bigint"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Idnumber?: Maybe<Scalars["String"]>;
  Idtype?: Maybe<Scalars["String"]>;
  Kccamobile?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ucc_Ucc_Max_Fields = {
  __typename?: "rtcs_db_PH_ucc_UCC_max_fields";
  Carrier?: Maybe<Scalars["String"]>;
  Count?: Maybe<Scalars["bigint"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Idnumber?: Maybe<Scalars["String"]>;
  Idtype?: Maybe<Scalars["String"]>;
  Kccamobile?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ucc_UCC" */
export type Rtcs_Db_Ph_Ucc_Ucc_Max_Order_By = {
  Carrier?: Maybe<Order_By>;
  Count?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Idnumber?: Maybe<Order_By>;
  Idtype?: Maybe<Order_By>;
  Kccamobile?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ucc_Ucc_Min_Fields = {
  __typename?: "rtcs_db_PH_ucc_UCC_min_fields";
  Carrier?: Maybe<Scalars["String"]>;
  Count?: Maybe<Scalars["bigint"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Idnumber?: Maybe<Scalars["String"]>;
  Idtype?: Maybe<Scalars["String"]>;
  Kccamobile?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ucc_UCC" */
export type Rtcs_Db_Ph_Ucc_Ucc_Min_Order_By = {
  Carrier?: Maybe<Order_By>;
  Count?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Idnumber?: Maybe<Order_By>;
  Idtype?: Maybe<Order_By>;
  Kccamobile?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ucc_UCC" */
export type Rtcs_Db_Ph_Ucc_Ucc_Mutation_Response = {
  __typename?: "rtcs_db_PH_ucc_UCC_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Ucc_Ucc>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_ucc_UCC" */
export type Rtcs_Db_Ph_Ucc_Ucc_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Ucc_Ucc_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_ucc_UCC" */
export type Rtcs_Db_Ph_Ucc_Ucc_Order_By = {
  Carrier?: Maybe<Order_By>;
  Count?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Idnumber?: Maybe<Order_By>;
  Idtype?: Maybe<Order_By>;
  Kccamobile?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ucc_UCC" */
export enum Rtcs_Db_Ph_Ucc_Ucc_Select_Column {
  /** column name */
  Carrier = "Carrier",
  /** column name */
  Count = "Count",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Idnumber = "Idnumber",
  /** column name */
  Idtype = "Idtype",
  /** column name */
  Kccamobile = "Kccamobile",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Surname = "Surname",
  /** column name */
  Uuid = "Uuid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ucc_UCC" */
export type Rtcs_Db_Ph_Ucc_Ucc_Set_Input = {
  Carrier?: Maybe<Scalars["String"]>;
  Count?: Maybe<Scalars["bigint"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Idnumber?: Maybe<Scalars["String"]>;
  Idtype?: Maybe<Scalars["String"]>;
  Kccamobile?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ucc_Ucc_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ucc_UCC_stddev_fields";
  Count?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ucc_UCC" */
export type Rtcs_Db_Ph_Ucc_Ucc_Stddev_Order_By = {
  Count?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ucc_Ucc_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_ucc_UCC_stddev_pop_fields";
  Count?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_ucc_UCC" */
export type Rtcs_Db_Ph_Ucc_Ucc_Stddev_Pop_Order_By = {
  Count?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ucc_Ucc_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_ucc_UCC_stddev_samp_fields";
  Count?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_ucc_UCC" */
export type Rtcs_Db_Ph_Ucc_Ucc_Stddev_Samp_Order_By = {
  Count?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ucc_Ucc_Sum_Fields = {
  __typename?: "rtcs_db_PH_ucc_UCC_sum_fields";
  Count?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ucc_UCC" */
export type Rtcs_Db_Ph_Ucc_Ucc_Sum_Order_By = {
  Count?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ucc_Ucc_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ucc_UCC_var_pop_fields";
  Count?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ucc_UCC" */
export type Rtcs_Db_Ph_Ucc_Ucc_Var_Pop_Order_By = {
  Count?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ucc_Ucc_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ucc_UCC_var_samp_fields";
  Count?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ucc_UCC" */
export type Rtcs_Db_Ph_Ucc_Ucc_Var_Samp_Order_By = {
  Count?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ucc_Ucc_Variance_Fields = {
  __typename?: "rtcs_db_PH_ucc_UCC_variance_fields";
  Count?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ucc_UCC" */
export type Rtcs_Db_Ph_Ucc_Ucc_Variance_Order_By = {
  Count?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_umeme_SubscriberAndProperty" */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty = {
  __typename?: "rtcs_db_PH_umeme_SubscriberAndProperty";
  CustomerName?: Maybe<Scalars["String"]>;
  Customerno?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["bigint"]>;
  MeterNo?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  SupplyPointNo?: Maybe<Scalars["String"]>;
  TariffDesc?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Town?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  XCoord?: Maybe<Scalars["float8"]>;
  YCoord?: Maybe<Scalars["float8"]>;
};

/** aggregated selection of "rtcs_db.PH_umeme_SubscriberAndProperty" */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Aggregate = {
  __typename?: "rtcs_db_PH_umeme_SubscriberAndProperty_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Umeme_SubscriberAndProperty>;
};

/** aggregate fields of "rtcs_db.PH_umeme_SubscriberAndProperty" */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_umeme_SubscriberAndProperty_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_umeme_SubscriberAndProperty" */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_umeme_SubscriberAndProperty" */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_umeme_SubscriberAndProperty" */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Avg_Fields = {
  __typename?: "rtcs_db_PH_umeme_SubscriberAndProperty_avg_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  XCoord?: Maybe<Scalars["Float"]>;
  YCoord?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_umeme_SubscriberAndProperty" */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Avg_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  XCoord?: Maybe<Order_By>;
  YCoord?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_umeme_SubscriberAndProperty". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Bool_Exp = {
  CustomerName?: Maybe<String_Comparison_Exp>;
  Customerno?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<String_Comparison_Exp>;
  Latitude?: Maybe<Bigint_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Bigint_Comparison_Exp>;
  MeterNo?: Maybe<String_Comparison_Exp>;
  Street?: Maybe<String_Comparison_Exp>;
  SupplyPointNo?: Maybe<String_Comparison_Exp>;
  TariffDesc?: Maybe<String_Comparison_Exp>;
  Telephone?: Maybe<String_Comparison_Exp>;
  Town?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  XCoord?: Maybe<Float8_Comparison_Exp>;
  YCoord?: Maybe<Float8_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_umeme_SubscriberAndProperty" */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Inc_Input = {
  Latitude?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["bigint"]>;
  XCoord?: Maybe<Scalars["float8"]>;
  YCoord?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_umeme_SubscriberAndProperty" */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Insert_Input = {
  CustomerName?: Maybe<Scalars["String"]>;
  Customerno?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["bigint"]>;
  MeterNo?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  SupplyPointNo?: Maybe<Scalars["String"]>;
  TariffDesc?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Town?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  XCoord?: Maybe<Scalars["float8"]>;
  YCoord?: Maybe<Scalars["float8"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Max_Fields = {
  __typename?: "rtcs_db_PH_umeme_SubscriberAndProperty_max_fields";
  CustomerName?: Maybe<Scalars["String"]>;
  Customerno?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["bigint"]>;
  MeterNo?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  SupplyPointNo?: Maybe<Scalars["String"]>;
  TariffDesc?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Town?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  XCoord?: Maybe<Scalars["float8"]>;
  YCoord?: Maybe<Scalars["float8"]>;
};

/** order by max() on columns of table "rtcs_db.PH_umeme_SubscriberAndProperty" */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Max_Order_By = {
  CustomerName?: Maybe<Order_By>;
  Customerno?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MeterNo?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  SupplyPointNo?: Maybe<Order_By>;
  TariffDesc?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Town?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  XCoord?: Maybe<Order_By>;
  YCoord?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Min_Fields = {
  __typename?: "rtcs_db_PH_umeme_SubscriberAndProperty_min_fields";
  CustomerName?: Maybe<Scalars["String"]>;
  Customerno?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["bigint"]>;
  MeterNo?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  SupplyPointNo?: Maybe<Scalars["String"]>;
  TariffDesc?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Town?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  XCoord?: Maybe<Scalars["float8"]>;
  YCoord?: Maybe<Scalars["float8"]>;
};

/** order by min() on columns of table "rtcs_db.PH_umeme_SubscriberAndProperty" */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Min_Order_By = {
  CustomerName?: Maybe<Order_By>;
  Customerno?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MeterNo?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  SupplyPointNo?: Maybe<Order_By>;
  TariffDesc?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Town?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  XCoord?: Maybe<Order_By>;
  YCoord?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_umeme_SubscriberAndProperty" */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Mutation_Response = {
  __typename?: "rtcs_db_PH_umeme_SubscriberAndProperty_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Umeme_SubscriberAndProperty>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_umeme_SubscriberAndProperty" */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_umeme_SubscriberAndProperty" */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Order_By = {
  CustomerName?: Maybe<Order_By>;
  Customerno?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MeterNo?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  SupplyPointNo?: Maybe<Order_By>;
  TariffDesc?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Town?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  XCoord?: Maybe<Order_By>;
  YCoord?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_umeme_SubscriberAndProperty" */
export enum Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Select_Column {
  /** column name */
  CustomerName = "CustomerName",
  /** column name */
  Customerno = "Customerno",
  /** column name */
  District = "District",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MeterNo = "MeterNo",
  /** column name */
  Street = "Street",
  /** column name */
  SupplyPointNo = "SupplyPointNo",
  /** column name */
  TariffDesc = "TariffDesc",
  /** column name */
  Telephone = "Telephone",
  /** column name */
  Town = "Town",
  /** column name */
  WhoUpdated = "WhoUpdated",
  /** column name */
  XCoord = "XCoord",
  /** column name */
  YCoord = "YCoord",
}

/** input type for updating data in table "rtcs_db.PH_umeme_SubscriberAndProperty" */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Set_Input = {
  CustomerName?: Maybe<Scalars["String"]>;
  Customerno?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["bigint"]>;
  MeterNo?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  SupplyPointNo?: Maybe<Scalars["String"]>;
  TariffDesc?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Town?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  XCoord?: Maybe<Scalars["float8"]>;
  YCoord?: Maybe<Scalars["float8"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Stddev_Fields = {
  __typename?: "rtcs_db_PH_umeme_SubscriberAndProperty_stddev_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  XCoord?: Maybe<Scalars["Float"]>;
  YCoord?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_umeme_SubscriberAndProperty" */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Stddev_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  XCoord?: Maybe<Order_By>;
  YCoord?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_umeme_SubscriberAndProperty_stddev_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  XCoord?: Maybe<Scalars["Float"]>;
  YCoord?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_umeme_SubscriberAndProperty" */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Stddev_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  XCoord?: Maybe<Order_By>;
  YCoord?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_umeme_SubscriberAndProperty_stddev_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  XCoord?: Maybe<Scalars["Float"]>;
  YCoord?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_umeme_SubscriberAndProperty" */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Stddev_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  XCoord?: Maybe<Order_By>;
  YCoord?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Sum_Fields = {
  __typename?: "rtcs_db_PH_umeme_SubscriberAndProperty_sum_fields";
  Latitude?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["bigint"]>;
  XCoord?: Maybe<Scalars["float8"]>;
  YCoord?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_umeme_SubscriberAndProperty" */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Sum_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  XCoord?: Maybe<Order_By>;
  YCoord?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_umeme_SubscriberAndProperty_var_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  XCoord?: Maybe<Scalars["Float"]>;
  YCoord?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_umeme_SubscriberAndProperty" */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Var_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  XCoord?: Maybe<Order_By>;
  YCoord?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_umeme_SubscriberAndProperty_var_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  XCoord?: Maybe<Scalars["Float"]>;
  YCoord?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_umeme_SubscriberAndProperty" */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Var_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  XCoord?: Maybe<Order_By>;
  YCoord?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Variance_Fields = {
  __typename?: "rtcs_db_PH_umeme_SubscriberAndProperty_variance_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  XCoord?: Maybe<Scalars["Float"]>;
  YCoord?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_umeme_SubscriberAndProperty" */
export type Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Variance_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  XCoord?: Maybe<Order_By>;
  YCoord?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_umeme_Transactions" */
export type Rtcs_Db_Ph_Umeme_Transactions = {
  __typename?: "rtcs_db_PH_umeme_Transactions";
  BillNumber?: Maybe<Scalars["String"]>;
  BillingDate?: Maybe<Scalars["String"]>;
  CashTendered?: Maybe<Scalars["String"]>;
  Consumption?: Maybe<Scalars["String"]>;
  CostOfEnergy?: Maybe<Scalars["String"]>;
  Customername?: Maybe<Scalars["String"]>;
  Customerno?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Meterno?: Maybe<Scalars["String"]>;
  Period?: Maybe<Scalars["String"]>;
  RevenueCategory?: Maybe<Scalars["String"]>;
  TotalRevenueNetamount?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_umeme_Transactions" */
export type Rtcs_Db_Ph_Umeme_Transactions_Aggregate = {
  __typename?: "rtcs_db_PH_umeme_Transactions_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Umeme_Transactions>;
};

/** aggregate fields of "rtcs_db.PH_umeme_Transactions" */
export type Rtcs_Db_Ph_Umeme_Transactions_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_umeme_Transactions_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_umeme_Transactions" */
export type Rtcs_Db_Ph_Umeme_Transactions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Umeme_Transactions_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_umeme_Transactions" */
export type Rtcs_Db_Ph_Umeme_Transactions_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_umeme_Transactions" */
export type Rtcs_Db_Ph_Umeme_Transactions_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Umeme_Transactions_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Umeme_Transactions_Avg_Fields = {
  __typename?: "rtcs_db_PH_umeme_Transactions_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_umeme_Transactions" */
export type Rtcs_Db_Ph_Umeme_Transactions_Avg_Order_By = {
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_umeme_Transactions". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Umeme_Transactions_Bool_Exp = {
  BillNumber?: Maybe<String_Comparison_Exp>;
  BillingDate?: Maybe<String_Comparison_Exp>;
  CashTendered?: Maybe<String_Comparison_Exp>;
  Consumption?: Maybe<String_Comparison_Exp>;
  CostOfEnergy?: Maybe<String_Comparison_Exp>;
  Customername?: Maybe<String_Comparison_Exp>;
  Customerno?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Meterno?: Maybe<String_Comparison_Exp>;
  Period?: Maybe<String_Comparison_Exp>;
  RevenueCategory?: Maybe<String_Comparison_Exp>;
  TotalRevenueNetamount?: Maybe<String_Comparison_Exp>;
  Type?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Umeme_Transactions_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Umeme_Transactions_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_umeme_Transactions" */
export type Rtcs_Db_Ph_Umeme_Transactions_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_umeme_Transactions" */
export type Rtcs_Db_Ph_Umeme_Transactions_Insert_Input = {
  BillNumber?: Maybe<Scalars["String"]>;
  BillingDate?: Maybe<Scalars["String"]>;
  CashTendered?: Maybe<Scalars["String"]>;
  Consumption?: Maybe<Scalars["String"]>;
  CostOfEnergy?: Maybe<Scalars["String"]>;
  Customername?: Maybe<Scalars["String"]>;
  Customerno?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Meterno?: Maybe<Scalars["String"]>;
  Period?: Maybe<Scalars["String"]>;
  RevenueCategory?: Maybe<Scalars["String"]>;
  TotalRevenueNetamount?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Umeme_Transactions_Max_Fields = {
  __typename?: "rtcs_db_PH_umeme_Transactions_max_fields";
  BillNumber?: Maybe<Scalars["String"]>;
  BillingDate?: Maybe<Scalars["String"]>;
  CashTendered?: Maybe<Scalars["String"]>;
  Consumption?: Maybe<Scalars["String"]>;
  CostOfEnergy?: Maybe<Scalars["String"]>;
  Customername?: Maybe<Scalars["String"]>;
  Customerno?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Meterno?: Maybe<Scalars["String"]>;
  Period?: Maybe<Scalars["String"]>;
  RevenueCategory?: Maybe<Scalars["String"]>;
  TotalRevenueNetamount?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_umeme_Transactions" */
export type Rtcs_Db_Ph_Umeme_Transactions_Max_Order_By = {
  BillNumber?: Maybe<Order_By>;
  BillingDate?: Maybe<Order_By>;
  CashTendered?: Maybe<Order_By>;
  Consumption?: Maybe<Order_By>;
  CostOfEnergy?: Maybe<Order_By>;
  Customername?: Maybe<Order_By>;
  Customerno?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Meterno?: Maybe<Order_By>;
  Period?: Maybe<Order_By>;
  RevenueCategory?: Maybe<Order_By>;
  TotalRevenueNetamount?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Umeme_Transactions_Min_Fields = {
  __typename?: "rtcs_db_PH_umeme_Transactions_min_fields";
  BillNumber?: Maybe<Scalars["String"]>;
  BillingDate?: Maybe<Scalars["String"]>;
  CashTendered?: Maybe<Scalars["String"]>;
  Consumption?: Maybe<Scalars["String"]>;
  CostOfEnergy?: Maybe<Scalars["String"]>;
  Customername?: Maybe<Scalars["String"]>;
  Customerno?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Meterno?: Maybe<Scalars["String"]>;
  Period?: Maybe<Scalars["String"]>;
  RevenueCategory?: Maybe<Scalars["String"]>;
  TotalRevenueNetamount?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_umeme_Transactions" */
export type Rtcs_Db_Ph_Umeme_Transactions_Min_Order_By = {
  BillNumber?: Maybe<Order_By>;
  BillingDate?: Maybe<Order_By>;
  CashTendered?: Maybe<Order_By>;
  Consumption?: Maybe<Order_By>;
  CostOfEnergy?: Maybe<Order_By>;
  Customername?: Maybe<Order_By>;
  Customerno?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Meterno?: Maybe<Order_By>;
  Period?: Maybe<Order_By>;
  RevenueCategory?: Maybe<Order_By>;
  TotalRevenueNetamount?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_umeme_Transactions" */
export type Rtcs_Db_Ph_Umeme_Transactions_Mutation_Response = {
  __typename?: "rtcs_db_PH_umeme_Transactions_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Umeme_Transactions>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_umeme_Transactions" */
export type Rtcs_Db_Ph_Umeme_Transactions_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Umeme_Transactions_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_umeme_Transactions" */
export type Rtcs_Db_Ph_Umeme_Transactions_Order_By = {
  BillNumber?: Maybe<Order_By>;
  BillingDate?: Maybe<Order_By>;
  CashTendered?: Maybe<Order_By>;
  Consumption?: Maybe<Order_By>;
  CostOfEnergy?: Maybe<Order_By>;
  Customername?: Maybe<Order_By>;
  Customerno?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Meterno?: Maybe<Order_By>;
  Period?: Maybe<Order_By>;
  RevenueCategory?: Maybe<Order_By>;
  TotalRevenueNetamount?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_umeme_Transactions" */
export enum Rtcs_Db_Ph_Umeme_Transactions_Select_Column {
  /** column name */
  BillNumber = "BillNumber",
  /** column name */
  BillingDate = "BillingDate",
  /** column name */
  CashTendered = "CashTendered",
  /** column name */
  Consumption = "Consumption",
  /** column name */
  CostOfEnergy = "CostOfEnergy",
  /** column name */
  Customername = "Customername",
  /** column name */
  Customerno = "Customerno",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Meterno = "Meterno",
  /** column name */
  Period = "Period",
  /** column name */
  RevenueCategory = "RevenueCategory",
  /** column name */
  TotalRevenueNetamount = "TotalRevenueNetamount",
  /** column name */
  Type = "Type",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_umeme_Transactions" */
export type Rtcs_Db_Ph_Umeme_Transactions_Set_Input = {
  BillNumber?: Maybe<Scalars["String"]>;
  BillingDate?: Maybe<Scalars["String"]>;
  CashTendered?: Maybe<Scalars["String"]>;
  Consumption?: Maybe<Scalars["String"]>;
  CostOfEnergy?: Maybe<Scalars["String"]>;
  Customername?: Maybe<Scalars["String"]>;
  Customerno?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Meterno?: Maybe<Scalars["String"]>;
  Period?: Maybe<Scalars["String"]>;
  RevenueCategory?: Maybe<Scalars["String"]>;
  TotalRevenueNetamount?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Umeme_Transactions_Stddev_Fields = {
  __typename?: "rtcs_db_PH_umeme_Transactions_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_umeme_Transactions" */
export type Rtcs_Db_Ph_Umeme_Transactions_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Umeme_Transactions_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_umeme_Transactions_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_umeme_Transactions" */
export type Rtcs_Db_Ph_Umeme_Transactions_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Umeme_Transactions_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_umeme_Transactions_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_umeme_Transactions" */
export type Rtcs_Db_Ph_Umeme_Transactions_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Umeme_Transactions_Sum_Fields = {
  __typename?: "rtcs_db_PH_umeme_Transactions_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_umeme_Transactions" */
export type Rtcs_Db_Ph_Umeme_Transactions_Sum_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Umeme_Transactions_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_umeme_Transactions_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_umeme_Transactions" */
export type Rtcs_Db_Ph_Umeme_Transactions_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Umeme_Transactions_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_umeme_Transactions_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_umeme_Transactions" */
export type Rtcs_Db_Ph_Umeme_Transactions_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Umeme_Transactions_Variance_Fields = {
  __typename?: "rtcs_db_PH_umeme_Transactions_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_umeme_Transactions" */
export type Rtcs_Db_Ph_Umeme_Transactions_Variance_Order_By = {
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_ura_BalanceSheetInformation" */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation = {
  __typename?: "rtcs_db_PH_ura_BalanceSheetInformation";
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  ApplicationFund?: Maybe<Scalars["String"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetCurrentAsset?: Maybe<Scalars["String"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  OthrLiability?: Maybe<Scalars["String"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProfitLossAccount?: Maybe<Scalars["String"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  RtnId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnId?: Maybe<Scalars["String"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TotAccntRcvdDebtors?: Maybe<Scalars["String"]>;
  TotAssetLoanAdvance?: Maybe<Scalars["String"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotCurrLiability?: Maybe<Scalars["String"]>;
  TotCurrentAsset?: Maybe<Scalars["String"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLiabilityProv?: Maybe<Scalars["String"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalLoanFund?: Maybe<Scalars["String"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureCreditorLoan?: Maybe<Scalars["String"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_BalanceSheetInformation" */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Aggregate = {
  __typename?: "rtcs_db_PH_ura_BalanceSheetInformation_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_BalanceSheetInformation>;
};

/** aggregate fields of "rtcs_db.PH_ura_BalanceSheetInformation" */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ura_BalanceSheetInformation_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ura_BalanceSheetInformation" */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_ura_BalanceSheetInformation" */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_BalanceSheetInformation" */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_BalanceSheetInformation_avg_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_BalanceSheetInformation" */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Avg_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_BalanceSheetInformation". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Bool_Exp = {
  AccntRcvdOthers?: Maybe<Float8_Comparison_Exp>;
  AccntRcvdOverSix?: Maybe<Float8_Comparison_Exp>;
  AdvanceCorporate?: Maybe<Float8_Comparison_Exp>;
  AdvanceRecoverable?: Maybe<Float8_Comparison_Exp>;
  ApplicationFund?: Maybe<String_Comparison_Exp>;
  BalRevenueAuth?: Maybe<Float8_Comparison_Exp>;
  BankBalance?: Maybe<Float8_Comparison_Exp>;
  BankOverDraft?: Maybe<Float8_Comparison_Exp>;
  CnsmPckInventory?: Maybe<Float8_Comparison_Exp>;
  Debenture?: Maybe<Float8_Comparison_Exp>;
  DeferTaxLiability?: Maybe<Float8_Comparison_Exp>;
  DeferredAsset?: Maybe<Float8_Comparison_Exp>;
  Deposits?: Maybe<Float8_Comparison_Exp>;
  Depreciation?: Maybe<Float8_Comparison_Exp>;
  EquityShare?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FinishTradeGoods?: Maybe<Float8_Comparison_Exp>;
  FixedDeposit?: Maybe<Float8_Comparison_Exp>;
  FurnitureFixture?: Maybe<Float8_Comparison_Exp>;
  GeneralReserve?: Maybe<Float8_Comparison_Exp>;
  GovtSecurities?: Maybe<Float8_Comparison_Exp>;
  HandCash?: Maybe<Float8_Comparison_Exp>;
  IncomeTaxProv?: Maybe<Float8_Comparison_Exp>;
  IntLeaseAsset?: Maybe<Float8_Comparison_Exp>;
  IntangibleAssets?: Maybe<Float8_Comparison_Exp>;
  LandBuild?: Maybe<Float8_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  LessDrawings?: Maybe<Float8_Comparison_Exp>;
  LiableLeaseAsset?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  LoanSubCompany?: Maybe<Float8_Comparison_Exp>;
  MotorVehicles?: Maybe<Float8_Comparison_Exp>;
  NetCurrentAsset?: Maybe<String_Comparison_Exp>;
  NetFixedAsset?: Maybe<Float8_Comparison_Exp>;
  OtherInvestments?: Maybe<Float8_Comparison_Exp>;
  OtherProvision?: Maybe<Float8_Comparison_Exp>;
  OtherReserve?: Maybe<Float8_Comparison_Exp>;
  OthrCurrentAsset?: Maybe<Float8_Comparison_Exp>;
  OthrDeprecAsset?: Maybe<Float8_Comparison_Exp>;
  OthrLiability?: Maybe<String_Comparison_Exp>;
  PlantMachine?: Maybe<Float8_Comparison_Exp>;
  Prepayments?: Maybe<Float8_Comparison_Exp>;
  ProfitLossAccount?: Maybe<String_Comparison_Exp>;
  ProprietorCapital?: Maybe<Float8_Comparison_Exp>;
  ProvForBadDebts?: Maybe<Float8_Comparison_Exp>;
  RawMtrlInventory?: Maybe<Float8_Comparison_Exp>;
  ReturnType?: Maybe<String_Comparison_Exp>;
  RevaluationReserve?: Maybe<Float8_Comparison_Exp>;
  RtnId?: Maybe<String_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnNoRtnId?: Maybe<String_Comparison_Exp>;
  SecureDebtIssued?: Maybe<Float8_Comparison_Exp>;
  SecureLoanBank?: Maybe<Float8_Comparison_Exp>;
  SecureLoanOther?: Maybe<Float8_Comparison_Exp>;
  SecureLoanParties?: Maybe<Float8_Comparison_Exp>;
  SecureTotLoan?: Maybe<String_Comparison_Exp>;
  SourceFund?: Maybe<String_Comparison_Exp>;
  StockInProcess?: Maybe<Float8_Comparison_Exp>;
  SundryCreditors?: Maybe<Float8_Comparison_Exp>;
  TotAccntRcvdDebtors?: Maybe<String_Comparison_Exp>;
  TotAssetLoanAdvance?: Maybe<String_Comparison_Exp>;
  TotBalanceAvail?: Maybe<Float8_Comparison_Exp>;
  TotCurrLiability?: Maybe<String_Comparison_Exp>;
  TotCurrentAsset?: Maybe<String_Comparison_Exp>;
  TotFixedAsset?: Maybe<Float8_Comparison_Exp>;
  TotInventory?: Maybe<Float8_Comparison_Exp>;
  TotLiabilityProv?: Maybe<String_Comparison_Exp>;
  TotLoanAdvance?: Maybe<Float8_Comparison_Exp>;
  TotTradeInvestment?: Maybe<Float8_Comparison_Exp>;
  TotalLoanFund?: Maybe<String_Comparison_Exp>;
  TotalPropFund?: Maybe<String_Comparison_Exp>;
  TotalProvision?: Maybe<Float8_Comparison_Exp>;
  TranslationReserve?: Maybe<Float8_Comparison_Exp>;
  UnpaidMatureDebts?: Maybe<Float8_Comparison_Exp>;
  UnsecureCreditorLoan?: Maybe<String_Comparison_Exp>;
  UnsecureLoanBank?: Maybe<Float8_Comparison_Exp>;
  UnsecureLoanOther?: Maybe<Float8_Comparison_Exp>;
  UnsecureLoanParties?: Maybe<Float8_Comparison_Exp>;
  UnsecureTotLoan?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_BalanceSheetInformation" */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Inc_Input = {
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_BalanceSheetInformation" */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Insert_Input = {
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  ApplicationFund?: Maybe<Scalars["String"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetCurrentAsset?: Maybe<Scalars["String"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  OthrLiability?: Maybe<Scalars["String"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProfitLossAccount?: Maybe<Scalars["String"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  RtnId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnId?: Maybe<Scalars["String"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TotAccntRcvdDebtors?: Maybe<Scalars["String"]>;
  TotAssetLoanAdvance?: Maybe<Scalars["String"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotCurrLiability?: Maybe<Scalars["String"]>;
  TotCurrentAsset?: Maybe<Scalars["String"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLiabilityProv?: Maybe<Scalars["String"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalLoanFund?: Maybe<Scalars["String"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureCreditorLoan?: Maybe<Scalars["String"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_BalanceSheetInformation_max_fields";
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  ApplicationFund?: Maybe<Scalars["String"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetCurrentAsset?: Maybe<Scalars["String"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  OthrLiability?: Maybe<Scalars["String"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProfitLossAccount?: Maybe<Scalars["String"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  RtnId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnId?: Maybe<Scalars["String"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TotAccntRcvdDebtors?: Maybe<Scalars["String"]>;
  TotAssetLoanAdvance?: Maybe<Scalars["String"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotCurrLiability?: Maybe<Scalars["String"]>;
  TotCurrentAsset?: Maybe<Scalars["String"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLiabilityProv?: Maybe<Scalars["String"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalLoanFund?: Maybe<Scalars["String"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureCreditorLoan?: Maybe<Scalars["String"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_BalanceSheetInformation" */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Max_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  ApplicationFund?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetCurrentAsset?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  OthrLiability?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProfitLossAccount?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  RtnId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnId?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  SecureTotLoan?: Maybe<Order_By>;
  SourceFund?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotAccntRcvdDebtors?: Maybe<Order_By>;
  TotAssetLoanAdvance?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotCurrLiability?: Maybe<Order_By>;
  TotCurrentAsset?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLiabilityProv?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalLoanFund?: Maybe<Order_By>;
  TotalPropFund?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureCreditorLoan?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
  UnsecureTotLoan?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_BalanceSheetInformation_min_fields";
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  ApplicationFund?: Maybe<Scalars["String"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetCurrentAsset?: Maybe<Scalars["String"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  OthrLiability?: Maybe<Scalars["String"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProfitLossAccount?: Maybe<Scalars["String"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  RtnId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnId?: Maybe<Scalars["String"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TotAccntRcvdDebtors?: Maybe<Scalars["String"]>;
  TotAssetLoanAdvance?: Maybe<Scalars["String"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotCurrLiability?: Maybe<Scalars["String"]>;
  TotCurrentAsset?: Maybe<Scalars["String"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLiabilityProv?: Maybe<Scalars["String"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalLoanFund?: Maybe<Scalars["String"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureCreditorLoan?: Maybe<Scalars["String"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_BalanceSheetInformation" */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Min_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  ApplicationFund?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetCurrentAsset?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  OthrLiability?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProfitLossAccount?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  RtnId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnId?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  SecureTotLoan?: Maybe<Order_By>;
  SourceFund?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotAccntRcvdDebtors?: Maybe<Order_By>;
  TotAssetLoanAdvance?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotCurrLiability?: Maybe<Order_By>;
  TotCurrentAsset?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLiabilityProv?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalLoanFund?: Maybe<Order_By>;
  TotalPropFund?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureCreditorLoan?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
  UnsecureTotLoan?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ura_BalanceSheetInformation" */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Mutation_Response = {
  __typename?: "rtcs_db_PH_ura_BalanceSheetInformation_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Ura_BalanceSheetInformation>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_BalanceSheetInformation" */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Ura_BalanceSheetInformation_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_ura_BalanceSheetInformation" */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  ApplicationFund?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetCurrentAsset?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  OthrLiability?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProfitLossAccount?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  RtnId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnId?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  SecureTotLoan?: Maybe<Order_By>;
  SourceFund?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotAccntRcvdDebtors?: Maybe<Order_By>;
  TotAssetLoanAdvance?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotCurrLiability?: Maybe<Order_By>;
  TotCurrentAsset?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLiabilityProv?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalLoanFund?: Maybe<Order_By>;
  TotalPropFund?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureCreditorLoan?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
  UnsecureTotLoan?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_BalanceSheetInformation" */
export enum Rtcs_Db_Ph_Ura_BalanceSheetInformation_Select_Column {
  /** column name */
  AccntRcvdOthers = "AccntRcvdOthers",
  /** column name */
  AccntRcvdOverSix = "AccntRcvdOverSix",
  /** column name */
  AdvanceCorporate = "AdvanceCorporate",
  /** column name */
  AdvanceRecoverable = "AdvanceRecoverable",
  /** column name */
  ApplicationFund = "ApplicationFund",
  /** column name */
  BalRevenueAuth = "BalRevenueAuth",
  /** column name */
  BankBalance = "BankBalance",
  /** column name */
  BankOverDraft = "BankOverDraft",
  /** column name */
  CnsmPckInventory = "CnsmPckInventory",
  /** column name */
  Debenture = "Debenture",
  /** column name */
  DeferTaxLiability = "DeferTaxLiability",
  /** column name */
  DeferredAsset = "DeferredAsset",
  /** column name */
  Deposits = "Deposits",
  /** column name */
  Depreciation = "Depreciation",
  /** column name */
  EquityShare = "EquityShare",
  /** column name */
  File = "File",
  /** column name */
  FinishTradeGoods = "FinishTradeGoods",
  /** column name */
  FixedDeposit = "FixedDeposit",
  /** column name */
  FurnitureFixture = "FurnitureFixture",
  /** column name */
  GeneralReserve = "GeneralReserve",
  /** column name */
  GovtSecurities = "GovtSecurities",
  /** column name */
  HandCash = "HandCash",
  /** column name */
  IncomeTaxProv = "IncomeTaxProv",
  /** column name */
  IntLeaseAsset = "IntLeaseAsset",
  /** column name */
  IntangibleAssets = "IntangibleAssets",
  /** column name */
  LandBuild = "LandBuild",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  LessDrawings = "LessDrawings",
  /** column name */
  LiableLeaseAsset = "LiableLeaseAsset",
  /** column name */
  Line = "Line",
  /** column name */
  LoanSubCompany = "LoanSubCompany",
  /** column name */
  MotorVehicles = "MotorVehicles",
  /** column name */
  NetCurrentAsset = "NetCurrentAsset",
  /** column name */
  NetFixedAsset = "NetFixedAsset",
  /** column name */
  OtherInvestments = "OtherInvestments",
  /** column name */
  OtherProvision = "OtherProvision",
  /** column name */
  OtherReserve = "OtherReserve",
  /** column name */
  OthrCurrentAsset = "OthrCurrentAsset",
  /** column name */
  OthrDeprecAsset = "OthrDeprecAsset",
  /** column name */
  OthrLiability = "OthrLiability",
  /** column name */
  PlantMachine = "PlantMachine",
  /** column name */
  Prepayments = "Prepayments",
  /** column name */
  ProfitLossAccount = "ProfitLossAccount",
  /** column name */
  ProprietorCapital = "ProprietorCapital",
  /** column name */
  ProvForBadDebts = "ProvForBadDebts",
  /** column name */
  RawMtrlInventory = "RawMtrlInventory",
  /** column name */
  ReturnType = "ReturnType",
  /** column name */
  RevaluationReserve = "RevaluationReserve",
  /** column name */
  RtnId = "RtnId",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnNoRtnId = "RtnNoRtnId",
  /** column name */
  SecureDebtIssued = "SecureDebtIssued",
  /** column name */
  SecureLoanBank = "SecureLoanBank",
  /** column name */
  SecureLoanOther = "SecureLoanOther",
  /** column name */
  SecureLoanParties = "SecureLoanParties",
  /** column name */
  SecureTotLoan = "SecureTotLoan",
  /** column name */
  SourceFund = "SourceFund",
  /** column name */
  StockInProcess = "StockInProcess",
  /** column name */
  SundryCreditors = "SundryCreditors",
  /** column name */
  TotAccntRcvdDebtors = "TotAccntRcvdDebtors",
  /** column name */
  TotAssetLoanAdvance = "TotAssetLoanAdvance",
  /** column name */
  TotBalanceAvail = "TotBalanceAvail",
  /** column name */
  TotCurrLiability = "TotCurrLiability",
  /** column name */
  TotCurrentAsset = "TotCurrentAsset",
  /** column name */
  TotFixedAsset = "TotFixedAsset",
  /** column name */
  TotInventory = "TotInventory",
  /** column name */
  TotLiabilityProv = "TotLiabilityProv",
  /** column name */
  TotLoanAdvance = "TotLoanAdvance",
  /** column name */
  TotTradeInvestment = "TotTradeInvestment",
  /** column name */
  TotalLoanFund = "TotalLoanFund",
  /** column name */
  TotalPropFund = "TotalPropFund",
  /** column name */
  TotalProvision = "TotalProvision",
  /** column name */
  TranslationReserve = "TranslationReserve",
  /** column name */
  UnpaidMatureDebts = "UnpaidMatureDebts",
  /** column name */
  UnsecureCreditorLoan = "UnsecureCreditorLoan",
  /** column name */
  UnsecureLoanBank = "UnsecureLoanBank",
  /** column name */
  UnsecureLoanOther = "UnsecureLoanOther",
  /** column name */
  UnsecureLoanParties = "UnsecureLoanParties",
  /** column name */
  UnsecureTotLoan = "UnsecureTotLoan",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_BalanceSheetInformation" */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Set_Input = {
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  ApplicationFund?: Maybe<Scalars["String"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetCurrentAsset?: Maybe<Scalars["String"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  OthrLiability?: Maybe<Scalars["String"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProfitLossAccount?: Maybe<Scalars["String"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  RtnId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnId?: Maybe<Scalars["String"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TotAccntRcvdDebtors?: Maybe<Scalars["String"]>;
  TotAssetLoanAdvance?: Maybe<Scalars["String"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotCurrLiability?: Maybe<Scalars["String"]>;
  TotCurrentAsset?: Maybe<Scalars["String"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLiabilityProv?: Maybe<Scalars["String"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalLoanFund?: Maybe<Scalars["String"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureCreditorLoan?: Maybe<Scalars["String"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ura_BalanceSheetInformation_stddev_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ura_BalanceSheetInformation" */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Stddev_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_BalanceSheetInformation_stddev_pop_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_BalanceSheetInformation" */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Stddev_Pop_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_BalanceSheetInformation_stddev_samp_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_BalanceSheetInformation" */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Stddev_Samp_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_BalanceSheetInformation_sum_fields";
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_BalanceSheetInformation" */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Sum_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_BalanceSheetInformation_var_pop_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ura_BalanceSheetInformation" */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Var_Pop_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_BalanceSheetInformation_var_samp_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ura_BalanceSheetInformation" */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Var_Samp_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Variance_Fields = {
  __typename?: "rtcs_db_PH_ura_BalanceSheetInformation_variance_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ura_BalanceSheetInformation" */
export type Rtcs_Db_Ph_Ura_BalanceSheetInformation_Variance_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners = {
  __typename?: "rtcs_db_PH_ura_CombinedOfficersAndOwners";
  Address?: Maybe<Scalars["String"]>;
  Designation?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Individualtin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  Pershare?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["float8"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["float8"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Taxpayername?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Aggregate = {
  __typename?: "rtcs_db_PH_ura_CombinedOfficersAndOwners_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners>;
};

/** aggregate fields of "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ura_CombinedOfficersAndOwners_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_CombinedOfficersAndOwners_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
  Pershare?: Maybe<Scalars["Float"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["Float"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Avg_Order_By = {
  Line?: Maybe<Order_By>;
  Pershare?: Maybe<Order_By>;
  Rtnholdingofficersdtlid?: Maybe<Order_By>;
  Rtnholdingownersdtlid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_CombinedOfficersAndOwners". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  Designation?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Individualtin?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  Pershare?: Maybe<Float8_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Rtnholdingofficersdtlid?: Maybe<Float8_Comparison_Exp>;
  Rtnholdingownersdtlid?: Maybe<Float8_Comparison_Exp>;
  Rtnno?: Maybe<String_Comparison_Exp>;
  Rtnperiodyear?: Maybe<String_Comparison_Exp>;
  Taxpayername?: Maybe<String_Comparison_Exp>;
  Tinno?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
  Pershare?: Maybe<Scalars["float8"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["float8"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  Designation?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Individualtin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  Pershare?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["float8"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["float8"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Taxpayername?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_CombinedOfficersAndOwners_max_fields";
  Address?: Maybe<Scalars["String"]>;
  Designation?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Individualtin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  Pershare?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["float8"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["float8"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Taxpayername?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Max_Order_By = {
  Address?: Maybe<Order_By>;
  Designation?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Individualtin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  Pershare?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnholdingofficersdtlid?: Maybe<Order_By>;
  Rtnholdingownersdtlid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Taxpayername?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_CombinedOfficersAndOwners_min_fields";
  Address?: Maybe<Scalars["String"]>;
  Designation?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Individualtin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  Pershare?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["float8"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["float8"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Taxpayername?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Min_Order_By = {
  Address?: Maybe<Order_By>;
  Designation?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Individualtin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  Pershare?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnholdingofficersdtlid?: Maybe<Order_By>;
  Rtnholdingownersdtlid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Taxpayername?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Mutation_Response = {
  __typename?: "rtcs_db_PH_ura_CombinedOfficersAndOwners_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Order_By = {
  Address?: Maybe<Order_By>;
  Designation?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Individualtin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  Pershare?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnholdingofficersdtlid?: Maybe<Order_By>;
  Rtnholdingownersdtlid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Taxpayername?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
export enum Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  Designation = "Designation",
  /** column name */
  File = "File",
  /** column name */
  Individualtin = "Individualtin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  Pershare = "Pershare",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Rtnholdingofficersdtlid = "Rtnholdingofficersdtlid",
  /** column name */
  Rtnholdingownersdtlid = "Rtnholdingownersdtlid",
  /** column name */
  Rtnno = "Rtnno",
  /** column name */
  Rtnperiodyear = "Rtnperiodyear",
  /** column name */
  Taxpayername = "Taxpayername",
  /** column name */
  Tinno = "Tinno",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  Designation?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Individualtin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  Pershare?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["float8"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["float8"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Taxpayername?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ura_CombinedOfficersAndOwners_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
  Pershare?: Maybe<Scalars["Float"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["Float"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
  Pershare?: Maybe<Order_By>;
  Rtnholdingofficersdtlid?: Maybe<Order_By>;
  Rtnholdingownersdtlid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_CombinedOfficersAndOwners_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  Pershare?: Maybe<Scalars["Float"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["Float"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  Pershare?: Maybe<Order_By>;
  Rtnholdingofficersdtlid?: Maybe<Order_By>;
  Rtnholdingownersdtlid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_CombinedOfficersAndOwners_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  Pershare?: Maybe<Scalars["Float"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["Float"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  Pershare?: Maybe<Order_By>;
  Rtnholdingofficersdtlid?: Maybe<Order_By>;
  Rtnholdingownersdtlid?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_CombinedOfficersAndOwners_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
  Pershare?: Maybe<Scalars["float8"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["float8"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Sum_Order_By = {
  Line?: Maybe<Order_By>;
  Pershare?: Maybe<Order_By>;
  Rtnholdingofficersdtlid?: Maybe<Order_By>;
  Rtnholdingownersdtlid?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_CombinedOfficersAndOwners_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  Pershare?: Maybe<Scalars["Float"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["Float"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  Pershare?: Maybe<Order_By>;
  Rtnholdingofficersdtlid?: Maybe<Order_By>;
  Rtnholdingownersdtlid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_CombinedOfficersAndOwners_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  Pershare?: Maybe<Scalars["Float"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["Float"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  Pershare?: Maybe<Order_By>;
  Rtnholdingofficersdtlid?: Maybe<Order_By>;
  Rtnholdingownersdtlid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Variance_Fields = {
  __typename?: "rtcs_db_PH_ura_CombinedOfficersAndOwners_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
  Pershare?: Maybe<Scalars["Float"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["Float"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Variance_Order_By = {
  Line?: Maybe<Order_By>;
  Pershare?: Maybe<Order_By>;
  Rtnholdingofficersdtlid?: Maybe<Order_By>;
  Rtnholdingownersdtlid?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail = {
  __typename?: "rtcs_db_PH_ura_EmploymentIncomeDetail";
  AllwExmptInctax?: Maybe<Scalars["float8"]>;
  AllwNotExempt?: Maybe<Scalars["float8"]>;
  BenefitValue?: Maybe<Scalars["float8"]>;
  EmployerName?: Maybe<Scalars["String"]>;
  EmployerTin?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ProfitLieuSalary?: Maybe<Scalars["float8"]>;
  RtnEmploymentIncmId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnEmploymentIncmId?: Maybe<Scalars["String"]>;
  SalaryExcldAllw?: Maybe<Scalars["float8"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Aggregate = {
  __typename?: "rtcs_db_PH_ura_EmploymentIncomeDetail_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail>;
};

/** aggregate fields of "rtcs_db.PH_ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ura_EmploymentIncomeDetail_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_EmploymentIncomeDetail_avg_fields";
  AllwExmptInctax?: Maybe<Scalars["Float"]>;
  AllwNotExempt?: Maybe<Scalars["Float"]>;
  BenefitValue?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ProfitLieuSalary?: Maybe<Scalars["Float"]>;
  SalaryExcldAllw?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Avg_Order_By = {
  AllwExmptInctax?: Maybe<Order_By>;
  AllwNotExempt?: Maybe<Order_By>;
  BenefitValue?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ProfitLieuSalary?: Maybe<Order_By>;
  SalaryExcldAllw?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_EmploymentIncomeDetail". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Bool_Exp = {
  AllwExmptInctax?: Maybe<Float8_Comparison_Exp>;
  AllwNotExempt?: Maybe<Float8_Comparison_Exp>;
  BenefitValue?: Maybe<Float8_Comparison_Exp>;
  EmployerName?: Maybe<String_Comparison_Exp>;
  EmployerTin?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  ProfitLieuSalary?: Maybe<Float8_Comparison_Exp>;
  RtnEmploymentIncmId?: Maybe<String_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnNoRtnEmploymentIncmId?: Maybe<String_Comparison_Exp>;
  SalaryExcldAllw?: Maybe<Float8_Comparison_Exp>;
  SourceType?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Inc_Input = {
  AllwExmptInctax?: Maybe<Scalars["float8"]>;
  AllwNotExempt?: Maybe<Scalars["float8"]>;
  BenefitValue?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ProfitLieuSalary?: Maybe<Scalars["float8"]>;
  SalaryExcldAllw?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Insert_Input = {
  AllwExmptInctax?: Maybe<Scalars["float8"]>;
  AllwNotExempt?: Maybe<Scalars["float8"]>;
  BenefitValue?: Maybe<Scalars["float8"]>;
  EmployerName?: Maybe<Scalars["String"]>;
  EmployerTin?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ProfitLieuSalary?: Maybe<Scalars["float8"]>;
  RtnEmploymentIncmId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnEmploymentIncmId?: Maybe<Scalars["String"]>;
  SalaryExcldAllw?: Maybe<Scalars["float8"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_EmploymentIncomeDetail_max_fields";
  AllwExmptInctax?: Maybe<Scalars["float8"]>;
  AllwNotExempt?: Maybe<Scalars["float8"]>;
  BenefitValue?: Maybe<Scalars["float8"]>;
  EmployerName?: Maybe<Scalars["String"]>;
  EmployerTin?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ProfitLieuSalary?: Maybe<Scalars["float8"]>;
  RtnEmploymentIncmId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnEmploymentIncmId?: Maybe<Scalars["String"]>;
  SalaryExcldAllw?: Maybe<Scalars["float8"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Max_Order_By = {
  AllwExmptInctax?: Maybe<Order_By>;
  AllwNotExempt?: Maybe<Order_By>;
  BenefitValue?: Maybe<Order_By>;
  EmployerName?: Maybe<Order_By>;
  EmployerTin?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ProfitLieuSalary?: Maybe<Order_By>;
  RtnEmploymentIncmId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnEmploymentIncmId?: Maybe<Order_By>;
  SalaryExcldAllw?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_EmploymentIncomeDetail_min_fields";
  AllwExmptInctax?: Maybe<Scalars["float8"]>;
  AllwNotExempt?: Maybe<Scalars["float8"]>;
  BenefitValue?: Maybe<Scalars["float8"]>;
  EmployerName?: Maybe<Scalars["String"]>;
  EmployerTin?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ProfitLieuSalary?: Maybe<Scalars["float8"]>;
  RtnEmploymentIncmId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnEmploymentIncmId?: Maybe<Scalars["String"]>;
  SalaryExcldAllw?: Maybe<Scalars["float8"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Min_Order_By = {
  AllwExmptInctax?: Maybe<Order_By>;
  AllwNotExempt?: Maybe<Order_By>;
  BenefitValue?: Maybe<Order_By>;
  EmployerName?: Maybe<Order_By>;
  EmployerTin?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ProfitLieuSalary?: Maybe<Order_By>;
  RtnEmploymentIncmId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnEmploymentIncmId?: Maybe<Order_By>;
  SalaryExcldAllw?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Mutation_Response = {
  __typename?: "rtcs_db_PH_ura_EmploymentIncomeDetail_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Order_By = {
  AllwExmptInctax?: Maybe<Order_By>;
  AllwNotExempt?: Maybe<Order_By>;
  BenefitValue?: Maybe<Order_By>;
  EmployerName?: Maybe<Order_By>;
  EmployerTin?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ProfitLieuSalary?: Maybe<Order_By>;
  RtnEmploymentIncmId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnEmploymentIncmId?: Maybe<Order_By>;
  SalaryExcldAllw?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_EmploymentIncomeDetail" */
export enum Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Select_Column {
  /** column name */
  AllwExmptInctax = "AllwExmptInctax",
  /** column name */
  AllwNotExempt = "AllwNotExempt",
  /** column name */
  BenefitValue = "BenefitValue",
  /** column name */
  EmployerName = "EmployerName",
  /** column name */
  EmployerTin = "EmployerTin",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  ProfitLieuSalary = "ProfitLieuSalary",
  /** column name */
  RtnEmploymentIncmId = "RtnEmploymentIncmId",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnNoRtnEmploymentIncmId = "RtnNoRtnEmploymentIncmId",
  /** column name */
  SalaryExcldAllw = "SalaryExcldAllw",
  /** column name */
  SourceType = "SourceType",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Set_Input = {
  AllwExmptInctax?: Maybe<Scalars["float8"]>;
  AllwNotExempt?: Maybe<Scalars["float8"]>;
  BenefitValue?: Maybe<Scalars["float8"]>;
  EmployerName?: Maybe<Scalars["String"]>;
  EmployerTin?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ProfitLieuSalary?: Maybe<Scalars["float8"]>;
  RtnEmploymentIncmId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnEmploymentIncmId?: Maybe<Scalars["String"]>;
  SalaryExcldAllw?: Maybe<Scalars["float8"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ura_EmploymentIncomeDetail_stddev_fields";
  AllwExmptInctax?: Maybe<Scalars["Float"]>;
  AllwNotExempt?: Maybe<Scalars["Float"]>;
  BenefitValue?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ProfitLieuSalary?: Maybe<Scalars["Float"]>;
  SalaryExcldAllw?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Stddev_Order_By = {
  AllwExmptInctax?: Maybe<Order_By>;
  AllwNotExempt?: Maybe<Order_By>;
  BenefitValue?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ProfitLieuSalary?: Maybe<Order_By>;
  SalaryExcldAllw?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_EmploymentIncomeDetail_stddev_pop_fields";
  AllwExmptInctax?: Maybe<Scalars["Float"]>;
  AllwNotExempt?: Maybe<Scalars["Float"]>;
  BenefitValue?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ProfitLieuSalary?: Maybe<Scalars["Float"]>;
  SalaryExcldAllw?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Stddev_Pop_Order_By = {
  AllwExmptInctax?: Maybe<Order_By>;
  AllwNotExempt?: Maybe<Order_By>;
  BenefitValue?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ProfitLieuSalary?: Maybe<Order_By>;
  SalaryExcldAllw?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_EmploymentIncomeDetail_stddev_samp_fields";
  AllwExmptInctax?: Maybe<Scalars["Float"]>;
  AllwNotExempt?: Maybe<Scalars["Float"]>;
  BenefitValue?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ProfitLieuSalary?: Maybe<Scalars["Float"]>;
  SalaryExcldAllw?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Stddev_Samp_Order_By = {
  AllwExmptInctax?: Maybe<Order_By>;
  AllwNotExempt?: Maybe<Order_By>;
  BenefitValue?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ProfitLieuSalary?: Maybe<Order_By>;
  SalaryExcldAllw?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_EmploymentIncomeDetail_sum_fields";
  AllwExmptInctax?: Maybe<Scalars["float8"]>;
  AllwNotExempt?: Maybe<Scalars["float8"]>;
  BenefitValue?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ProfitLieuSalary?: Maybe<Scalars["float8"]>;
  SalaryExcldAllw?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Sum_Order_By = {
  AllwExmptInctax?: Maybe<Order_By>;
  AllwNotExempt?: Maybe<Order_By>;
  BenefitValue?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ProfitLieuSalary?: Maybe<Order_By>;
  SalaryExcldAllw?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_EmploymentIncomeDetail_var_pop_fields";
  AllwExmptInctax?: Maybe<Scalars["Float"]>;
  AllwNotExempt?: Maybe<Scalars["Float"]>;
  BenefitValue?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ProfitLieuSalary?: Maybe<Scalars["Float"]>;
  SalaryExcldAllw?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Var_Pop_Order_By = {
  AllwExmptInctax?: Maybe<Order_By>;
  AllwNotExempt?: Maybe<Order_By>;
  BenefitValue?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ProfitLieuSalary?: Maybe<Order_By>;
  SalaryExcldAllw?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_EmploymentIncomeDetail_var_samp_fields";
  AllwExmptInctax?: Maybe<Scalars["Float"]>;
  AllwNotExempt?: Maybe<Scalars["Float"]>;
  BenefitValue?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ProfitLieuSalary?: Maybe<Scalars["Float"]>;
  SalaryExcldAllw?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Var_Samp_Order_By = {
  AllwExmptInctax?: Maybe<Order_By>;
  AllwNotExempt?: Maybe<Order_By>;
  BenefitValue?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ProfitLieuSalary?: Maybe<Order_By>;
  SalaryExcldAllw?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Variance_Fields = {
  __typename?: "rtcs_db_PH_ura_EmploymentIncomeDetail_variance_fields";
  AllwExmptInctax?: Maybe<Scalars["Float"]>;
  AllwNotExempt?: Maybe<Scalars["Float"]>;
  BenefitValue?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ProfitLieuSalary?: Maybe<Scalars["Float"]>;
  SalaryExcldAllw?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Variance_Order_By = {
  AllwExmptInctax?: Maybe<Order_By>;
  AllwNotExempt?: Maybe<Order_By>;
  BenefitValue?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ProfitLieuSalary?: Maybe<Order_By>;
  SalaryExcldAllw?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage = {
  __typename?: "rtcs_db_PH_ura_GazetteCommercialRatesPerSquareFootage";
  CommercialRentSqm?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  OfficeRentSqm?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Aggregate = {
  __typename?: "rtcs_db_PH_ura_GazetteCommercialRatesPerSquareFootage_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage>;
};

/** aggregate fields of "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Aggregate_Fields =
  {
    __typename?: "rtcs_db_PH_ura_GazetteCommercialRatesPerSquareFootage_aggregate_fields";
    avg?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Avg_Fields>;
    count?: Maybe<Scalars["Int"]>;
    max?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Max_Fields>;
    min?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Min_Fields>;
    stddev?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Stddev_Fields>;
    stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Stddev_Samp_Fields>;
    sum?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Sum_Fields>;
    var_pop?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Var_Pop_Fields>;
    var_samp?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Var_Samp_Fields>;
    variance?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Variance_Fields>;
  };

/** aggregate fields of "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Aggregate_Order_By =
  {
    avg?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Max_Order_By>;
    min?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Min_Order_By>;
    stddev?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Stddev_Order_By>;
    stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Stddev_Samp_Order_By>;
    sum?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Sum_Order_By>;
    var_pop?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Var_Pop_Order_By>;
    var_samp?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Var_Samp_Order_By>;
    variance?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Variance_Order_By>;
  };

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_GazetteCommercialRatesPerSquareFootage_avg_fields";
  CommercialRentSqm?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OfficeRentSqm?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Avg_Order_By =
  {
    CommercialRentSqm?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    OfficeRentSqm?: Maybe<Order_By>;
  };

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Bool_Exp = {
  CommercialRentSqm?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  OfficeRentSqm?: Maybe<Float8_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Inc_Input = {
  CommercialRentSqm?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  OfficeRentSqm?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Insert_Input =
  {
    CommercialRentSqm?: Maybe<Scalars["float8"]>;
    File?: Maybe<Scalars["String"]>;
    LastUpdated?: Maybe<Scalars["timestamptz"]>;
    Line?: Maybe<Scalars["bigint"]>;
    OfficeRentSqm?: Maybe<Scalars["float8"]>;
    Village?: Maybe<Scalars["String"]>;
    WhoUpdated?: Maybe<Scalars["String"]>;
  };

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_GazetteCommercialRatesPerSquareFootage_max_fields";
  CommercialRentSqm?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  OfficeRentSqm?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Max_Order_By =
  {
    CommercialRentSqm?: Maybe<Order_By>;
    File?: Maybe<Order_By>;
    LastUpdated?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    OfficeRentSqm?: Maybe<Order_By>;
    Village?: Maybe<Order_By>;
    WhoUpdated?: Maybe<Order_By>;
  };

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_GazetteCommercialRatesPerSquareFootage_min_fields";
  CommercialRentSqm?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  OfficeRentSqm?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Min_Order_By =
  {
    CommercialRentSqm?: Maybe<Order_By>;
    File?: Maybe<Order_By>;
    LastUpdated?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    OfficeRentSqm?: Maybe<Order_By>;
    Village?: Maybe<Order_By>;
    WhoUpdated?: Maybe<Order_By>;
  };

/** response of any mutation on the table "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Mutation_Response =
  {
    __typename?: "rtcs_db_PH_ura_GazetteCommercialRatesPerSquareFootage_mutation_response";
    /** number of affected rows by the mutation */
    affected_rows: Scalars["Int"];
    /** data of the affected rows by the mutation */
    returning: Array<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage>;
  };

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Order_By = {
  CommercialRentSqm?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OfficeRentSqm?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
export enum Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Select_Column {
  /** column name */
  CommercialRentSqm = "CommercialRentSqm",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  OfficeRentSqm = "OfficeRentSqm",
  /** column name */
  Village = "Village",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Set_Input = {
  CommercialRentSqm?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  OfficeRentSqm?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Stddev_Fields =
  {
    __typename?: "rtcs_db_PH_ura_GazetteCommercialRatesPerSquareFootage_stddev_fields";
    CommercialRentSqm?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    OfficeRentSqm?: Maybe<Scalars["Float"]>;
  };

/** order by stddev() on columns of table "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Stddev_Order_By =
  {
    CommercialRentSqm?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    OfficeRentSqm?: Maybe<Order_By>;
  };

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Stddev_Pop_Fields =
  {
    __typename?: "rtcs_db_PH_ura_GazetteCommercialRatesPerSquareFootage_stddev_pop_fields";
    CommercialRentSqm?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    OfficeRentSqm?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Stddev_Pop_Order_By =
  {
    CommercialRentSqm?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    OfficeRentSqm?: Maybe<Order_By>;
  };

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Stddev_Samp_Fields =
  {
    __typename?: "rtcs_db_PH_ura_GazetteCommercialRatesPerSquareFootage_stddev_samp_fields";
    CommercialRentSqm?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    OfficeRentSqm?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Stddev_Samp_Order_By =
  {
    CommercialRentSqm?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    OfficeRentSqm?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_GazetteCommercialRatesPerSquareFootage_sum_fields";
  CommercialRentSqm?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  OfficeRentSqm?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Sum_Order_By =
  {
    CommercialRentSqm?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    OfficeRentSqm?: Maybe<Order_By>;
  };

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Var_Pop_Fields =
  {
    __typename?: "rtcs_db_PH_ura_GazetteCommercialRatesPerSquareFootage_var_pop_fields";
    CommercialRentSqm?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    OfficeRentSqm?: Maybe<Scalars["Float"]>;
  };

/** order by var_pop() on columns of table "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Var_Pop_Order_By =
  {
    CommercialRentSqm?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    OfficeRentSqm?: Maybe<Order_By>;
  };

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Var_Samp_Fields =
  {
    __typename?: "rtcs_db_PH_ura_GazetteCommercialRatesPerSquareFootage_var_samp_fields";
    CommercialRentSqm?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    OfficeRentSqm?: Maybe<Scalars["Float"]>;
  };

/** order by var_samp() on columns of table "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Var_Samp_Order_By =
  {
    CommercialRentSqm?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    OfficeRentSqm?: Maybe<Order_By>;
  };

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Variance_Fields =
  {
    __typename?: "rtcs_db_PH_ura_GazetteCommercialRatesPerSquareFootage_variance_fields";
    CommercialRentSqm?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    OfficeRentSqm?: Maybe<Scalars["Float"]>;
  };

/** order by variance() on columns of table "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Variance_Order_By =
  {
    CommercialRentSqm?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    OfficeRentSqm?: Maybe<Order_By>;
  };

/** columns and relationships of "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom = {
  __typename?: "rtcs_db_PH_ura_GazetteResidentialRatesPerBedroom";
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Aggregate = {
  __typename?: "rtcs_db_PH_ura_GazetteResidentialRatesPerBedroom_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom>;
};

/** aggregate fields of "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Aggregate_Fields =
  {
    __typename?: "rtcs_db_PH_ura_GazetteResidentialRatesPerBedroom_aggregate_fields";
    avg?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Avg_Fields>;
    count?: Maybe<Scalars["Int"]>;
    max?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Max_Fields>;
    min?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Min_Fields>;
    stddev?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Stddev_Fields>;
    stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Stddev_Samp_Fields>;
    sum?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Sum_Fields>;
    var_pop?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Var_Pop_Fields>;
    var_samp?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Var_Samp_Fields>;
    variance?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Variance_Fields>;
  };

/** aggregate fields of "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Aggregate_Order_By =
  {
    avg?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Max_Order_By>;
    min?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Min_Order_By>;
    stddev?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Stddev_Order_By>;
    stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Stddev_Samp_Order_By>;
    sum?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Sum_Order_By>;
    var_pop?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Var_Pop_Order_By>;
    var_samp?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Var_Samp_Order_By>;
    variance?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Variance_Order_By>;
  };

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_GazetteResidentialRatesPerBedroom_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Avg_Order_By = {
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_GazetteResidentialRatesPerBedroom_max_fields";
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Max_Order_By = {
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_GazetteResidentialRatesPerBedroom_min_fields";
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Min_Order_By = {
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Mutation_Response =
  {
    __typename?: "rtcs_db_PH_ura_GazetteResidentialRatesPerBedroom_mutation_response";
    /** number of affected rows by the mutation */
    affected_rows: Scalars["Int"];
    /** data of the affected rows by the mutation */
    returning: Array<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom>;
  };

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Order_By = {
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
export enum Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Village = "Village",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ura_GazetteResidentialRatesPerBedroom_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Stddev_Pop_Fields =
  {
    __typename?: "rtcs_db_PH_ura_GazetteResidentialRatesPerBedroom_stddev_pop_fields";
    Line?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Stddev_Pop_Order_By =
  {
    Line?: Maybe<Order_By>;
  };

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Stddev_Samp_Fields =
  {
    __typename?: "rtcs_db_PH_ura_GazetteResidentialRatesPerBedroom_stddev_samp_fields";
    Line?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Stddev_Samp_Order_By =
  {
    Line?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_GazetteResidentialRatesPerBedroom_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Sum_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_GazetteResidentialRatesPerBedroom_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Var_Pop_Order_By =
  {
    Line?: Maybe<Order_By>;
  };

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_GazetteResidentialRatesPerBedroom_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Var_Samp_Order_By =
  {
    Line?: Maybe<Order_By>;
  };

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Variance_Fields = {
  __typename?: "rtcs_db_PH_ura_GazetteResidentialRatesPerBedroom_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Variance_Order_By =
  {
    Line?: Maybe<Order_By>;
  };

/** columns and relationships of "rtcs_db.PH_ura_HoldingOfficers" */
export type Rtcs_Db_Ph_Ura_HoldingOfficers = {
  __typename?: "rtcs_db_PH_ura_HoldingOfficers";
  Address?: Maybe<Scalars["String"]>;
  Designation?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  RtnHoldingOfficersDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnHoldingOfficersDtlId?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_HoldingOfficers" */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Aggregate = {
  __typename?: "rtcs_db_PH_ura_HoldingOfficers_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_HoldingOfficers>;
};

/** aggregate fields of "rtcs_db.PH_ura_HoldingOfficers" */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ura_HoldingOfficers_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ura_HoldingOfficers" */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOfficers_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_ura_HoldingOfficers" */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_HoldingOfficers" */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Ura_HoldingOfficers_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_HoldingOfficers_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_HoldingOfficers" */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Avg_Order_By = {
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_HoldingOfficers". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  Designation?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  RtnHoldingOfficersDtlId?: Maybe<String_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnNoRtnHoldingOfficersDtlId?: Maybe<String_Comparison_Exp>;
  SourceType?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_HoldingOfficers" */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_HoldingOfficers" */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  Designation?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  RtnHoldingOfficersDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnHoldingOfficersDtlId?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_HoldingOfficers_max_fields";
  Address?: Maybe<Scalars["String"]>;
  Designation?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  RtnHoldingOfficersDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnHoldingOfficersDtlId?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_HoldingOfficers" */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Max_Order_By = {
  Address?: Maybe<Order_By>;
  Designation?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  RtnHoldingOfficersDtlId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnHoldingOfficersDtlId?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_HoldingOfficers_min_fields";
  Address?: Maybe<Scalars["String"]>;
  Designation?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  RtnHoldingOfficersDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnHoldingOfficersDtlId?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_HoldingOfficers" */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Min_Order_By = {
  Address?: Maybe<Order_By>;
  Designation?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  RtnHoldingOfficersDtlId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnHoldingOfficersDtlId?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ura_HoldingOfficers" */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Mutation_Response = {
  __typename?: "rtcs_db_PH_ura_HoldingOfficers_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Ura_HoldingOfficers>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_HoldingOfficers" */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Ura_HoldingOfficers_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_ura_HoldingOfficers" */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Order_By = {
  Address?: Maybe<Order_By>;
  Designation?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  RtnHoldingOfficersDtlId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnHoldingOfficersDtlId?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_HoldingOfficers" */
export enum Rtcs_Db_Ph_Ura_HoldingOfficers_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  Designation = "Designation",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  RtnHoldingOfficersDtlId = "RtnHoldingOfficersDtlId",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnNoRtnHoldingOfficersDtlId = "RtnNoRtnHoldingOfficersDtlId",
  /** column name */
  SourceType = "SourceType",
  /** column name */
  Tin = "Tin",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_HoldingOfficers" */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  Designation?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  RtnHoldingOfficersDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnHoldingOfficersDtlId?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ura_HoldingOfficers_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ura_HoldingOfficers" */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_HoldingOfficers_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_HoldingOfficers" */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_HoldingOfficers_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_HoldingOfficers" */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_HoldingOfficers_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_HoldingOfficers" */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Sum_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_HoldingOfficers_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ura_HoldingOfficers" */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_HoldingOfficers_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ura_HoldingOfficers" */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Variance_Fields = {
  __typename?: "rtcs_db_PH_ura_HoldingOfficers_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ura_HoldingOfficers" */
export type Rtcs_Db_Ph_Ura_HoldingOfficers_Variance_Order_By = {
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_ura_HoldingOwner" */
export type Rtcs_Db_Ph_Ura_HoldingOwner = {
  __typename?: "rtcs_db_PH_ura_HoldingOwner";
  Address?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  PerShare?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnHoldingOwnersDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnHoldingOwnersDtlId?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_HoldingOwner" */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Aggregate = {
  __typename?: "rtcs_db_PH_ura_HoldingOwner_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_HoldingOwner>;
};

/** aggregate fields of "rtcs_db.PH_ura_HoldingOwner" */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ura_HoldingOwner_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ura_HoldingOwner" */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOwner_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_ura_HoldingOwner" */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_HoldingOwner" */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Ura_HoldingOwner_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_HoldingOwner_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
  PerShare?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_HoldingOwner" */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Avg_Order_By = {
  Line?: Maybe<Order_By>;
  PerShare?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_HoldingOwner". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  PerShare?: Maybe<Float8_Comparison_Exp>;
  ReturnType?: Maybe<String_Comparison_Exp>;
  RtnHoldingOwnersDtlId?: Maybe<String_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnNoRtnHoldingOwnersDtlId?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_HoldingOwner" */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
  PerShare?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_HoldingOwner" */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  PerShare?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnHoldingOwnersDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnHoldingOwnersDtlId?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_HoldingOwner_max_fields";
  Address?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  PerShare?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnHoldingOwnersDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnHoldingOwnersDtlId?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_HoldingOwner" */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Max_Order_By = {
  Address?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  PerShare?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RtnHoldingOwnersDtlId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnHoldingOwnersDtlId?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_HoldingOwner_min_fields";
  Address?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  PerShare?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnHoldingOwnersDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnHoldingOwnersDtlId?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_HoldingOwner" */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Min_Order_By = {
  Address?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  PerShare?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RtnHoldingOwnersDtlId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnHoldingOwnersDtlId?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ura_HoldingOwner" */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Mutation_Response = {
  __typename?: "rtcs_db_PH_ura_HoldingOwner_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Ura_HoldingOwner>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_HoldingOwner" */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Ura_HoldingOwner_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_ura_HoldingOwner" */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Order_By = {
  Address?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  PerShare?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RtnHoldingOwnersDtlId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnHoldingOwnersDtlId?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_HoldingOwner" */
export enum Rtcs_Db_Ph_Ura_HoldingOwner_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  PerShare = "PerShare",
  /** column name */
  ReturnType = "ReturnType",
  /** column name */
  RtnHoldingOwnersDtlId = "RtnHoldingOwnersDtlId",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnNoRtnHoldingOwnersDtlId = "RtnNoRtnHoldingOwnersDtlId",
  /** column name */
  Tin = "Tin",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_HoldingOwner" */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  PerShare?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnHoldingOwnersDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnHoldingOwnersDtlId?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ura_HoldingOwner_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
  PerShare?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ura_HoldingOwner" */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
  PerShare?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_HoldingOwner_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  PerShare?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_HoldingOwner" */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  PerShare?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_HoldingOwner_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  PerShare?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_HoldingOwner" */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  PerShare?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_HoldingOwner_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
  PerShare?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_HoldingOwner" */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Sum_Order_By = {
  Line?: Maybe<Order_By>;
  PerShare?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_HoldingOwner_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  PerShare?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ura_HoldingOwner" */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  PerShare?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_HoldingOwner_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  PerShare?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ura_HoldingOwner" */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  PerShare?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Variance_Fields = {
  __typename?: "rtcs_db_PH_ura_HoldingOwner_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
  PerShare?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ura_HoldingOwner" */
export type Rtcs_Db_Ph_Ura_HoldingOwner_Variance_Order_By = {
  Line?: Maybe<Order_By>;
  PerShare?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_ura_IncomeTaxHolding" */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxHolding";
  File?: Maybe<Scalars["String"]>;
  HoldingCmpnyAddress?: Maybe<Scalars["String"]>;
  HoldingCmpnyName?: Maybe<Scalars["String"]>;
  HoldingCmpnyTin?: Maybe<Scalars["String"]>;
  HoldingCompanyStatus?: Maybe<Scalars["String"]>;
  HoldingStatus?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RtnHoldingStatusDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_IncomeTaxHolding" */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Aggregate = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxHolding_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_IncomeTaxHolding>;
};

/** aggregate fields of "rtcs_db.PH_ura_IncomeTaxHolding" */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxHolding_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ura_IncomeTaxHolding" */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_ura_IncomeTaxHolding" */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_IncomeTaxHolding" */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxHolding_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_IncomeTaxHolding" */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Avg_Order_By = {
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_IncomeTaxHolding". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  HoldingCmpnyAddress?: Maybe<String_Comparison_Exp>;
  HoldingCmpnyName?: Maybe<String_Comparison_Exp>;
  HoldingCmpnyTin?: Maybe<String_Comparison_Exp>;
  HoldingCompanyStatus?: Maybe<String_Comparison_Exp>;
  HoldingStatus?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  RtnHoldingStatusDtlId?: Maybe<String_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  SourceType?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_IncomeTaxHolding" */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_IncomeTaxHolding" */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  HoldingCmpnyAddress?: Maybe<Scalars["String"]>;
  HoldingCmpnyName?: Maybe<Scalars["String"]>;
  HoldingCmpnyTin?: Maybe<Scalars["String"]>;
  HoldingCompanyStatus?: Maybe<Scalars["String"]>;
  HoldingStatus?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RtnHoldingStatusDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxHolding_max_fields";
  File?: Maybe<Scalars["String"]>;
  HoldingCmpnyAddress?: Maybe<Scalars["String"]>;
  HoldingCmpnyName?: Maybe<Scalars["String"]>;
  HoldingCmpnyTin?: Maybe<Scalars["String"]>;
  HoldingCompanyStatus?: Maybe<Scalars["String"]>;
  HoldingStatus?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RtnHoldingStatusDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_IncomeTaxHolding" */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Max_Order_By = {
  File?: Maybe<Order_By>;
  HoldingCmpnyAddress?: Maybe<Order_By>;
  HoldingCmpnyName?: Maybe<Order_By>;
  HoldingCmpnyTin?: Maybe<Order_By>;
  HoldingCompanyStatus?: Maybe<Order_By>;
  HoldingStatus?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RtnHoldingStatusDtlId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxHolding_min_fields";
  File?: Maybe<Scalars["String"]>;
  HoldingCmpnyAddress?: Maybe<Scalars["String"]>;
  HoldingCmpnyName?: Maybe<Scalars["String"]>;
  HoldingCmpnyTin?: Maybe<Scalars["String"]>;
  HoldingCompanyStatus?: Maybe<Scalars["String"]>;
  HoldingStatus?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RtnHoldingStatusDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_IncomeTaxHolding" */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Min_Order_By = {
  File?: Maybe<Order_By>;
  HoldingCmpnyAddress?: Maybe<Order_By>;
  HoldingCmpnyName?: Maybe<Order_By>;
  HoldingCmpnyTin?: Maybe<Order_By>;
  HoldingCompanyStatus?: Maybe<Order_By>;
  HoldingStatus?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RtnHoldingStatusDtlId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ura_IncomeTaxHolding" */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Mutation_Response = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxHolding_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Ura_IncomeTaxHolding>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_IncomeTaxHolding" */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Ura_IncomeTaxHolding_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_ura_IncomeTaxHolding" */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Order_By = {
  File?: Maybe<Order_By>;
  HoldingCmpnyAddress?: Maybe<Order_By>;
  HoldingCmpnyName?: Maybe<Order_By>;
  HoldingCmpnyTin?: Maybe<Order_By>;
  HoldingCompanyStatus?: Maybe<Order_By>;
  HoldingStatus?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RtnHoldingStatusDtlId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_IncomeTaxHolding" */
export enum Rtcs_Db_Ph_Ura_IncomeTaxHolding_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  HoldingCmpnyAddress = "HoldingCmpnyAddress",
  /** column name */
  HoldingCmpnyName = "HoldingCmpnyName",
  /** column name */
  HoldingCmpnyTin = "HoldingCmpnyTin",
  /** column name */
  HoldingCompanyStatus = "HoldingCompanyStatus",
  /** column name */
  HoldingStatus = "HoldingStatus",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  RtnHoldingStatusDtlId = "RtnHoldingStatusDtlId",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  SourceType = "SourceType",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_IncomeTaxHolding" */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  HoldingCmpnyAddress?: Maybe<Scalars["String"]>;
  HoldingCmpnyName?: Maybe<Scalars["String"]>;
  HoldingCmpnyTin?: Maybe<Scalars["String"]>;
  HoldingCompanyStatus?: Maybe<Scalars["String"]>;
  HoldingStatus?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RtnHoldingStatusDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxHolding_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ura_IncomeTaxHolding" */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxHolding_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_IncomeTaxHolding" */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxHolding_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_IncomeTaxHolding" */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxHolding_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_IncomeTaxHolding" */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Sum_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxHolding_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ura_IncomeTaxHolding" */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxHolding_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ura_IncomeTaxHolding" */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Variance_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxHolding_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ura_IncomeTaxHolding" */
export type Rtcs_Db_Ph_Ura_IncomeTaxHolding_Variance_Order_By = {
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryIndividual";
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Aggregate = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryIndividual_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual>;
};

/** aggregate fields of "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryIndividual_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryIndividual_avg_fields";
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Avg_Order_By = {
  ChargeableIncome?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_IncomeTaxSummaryIndividual". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Bool_Exp = {
  ChargeableIncome?: Maybe<Float8_Comparison_Exp>;
  EmplymntIncome?: Maybe<Float8_Comparison_Exp>;
  EmplymntRate?: Maybe<Float8_Comparison_Exp>;
  EmplymntTax?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  GrossRentalIncome?: Maybe<Float8_Comparison_Exp>;
  IsAmendment?: Maybe<String_Comparison_Exp>;
  IsResident?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  PropincIncome?: Maybe<Float8_Comparison_Exp>;
  PropincRate?: Maybe<Float8_Comparison_Exp>;
  PropincTax?: Maybe<Float8_Comparison_Exp>;
  RentincRate?: Maybe<Float8_Comparison_Exp>;
  RentincTax?: Maybe<Float8_Comparison_Exp>;
  RtnDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnFromDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnPeriodYear?: Maybe<String_Comparison_Exp>;
  RtnPeriodYearGenerated?: Maybe<String_Comparison_Exp>;
  RtnToDt?: Maybe<Timestamptz_Comparison_Exp>;
  SourceType?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  TotIncome?: Maybe<Float8_Comparison_Exp>;
  TotRate?: Maybe<Float8_Comparison_Exp>;
  TotTax?: Maybe<Float8_Comparison_Exp>;
  Uuid?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Inc_Input = {
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Insert_Input = {
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryIndividual_max_fields";
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Max_Order_By = {
  ChargeableIncome?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnPeriodYearGenerated?: Maybe<Order_By>;
  RtnToDt?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryIndividual_min_fields";
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Min_Order_By = {
  ChargeableIncome?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnPeriodYearGenerated?: Maybe<Order_By>;
  RtnToDt?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Mutation_Response = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryIndividual_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Order_By = {
  ChargeableIncome?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnPeriodYearGenerated?: Maybe<Order_By>;
  RtnToDt?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
export enum Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Select_Column {
  /** column name */
  ChargeableIncome = "ChargeableIncome",
  /** column name */
  EmplymntIncome = "EmplymntIncome",
  /** column name */
  EmplymntRate = "EmplymntRate",
  /** column name */
  EmplymntTax = "EmplymntTax",
  /** column name */
  File = "File",
  /** column name */
  GrossRentalIncome = "GrossRentalIncome",
  /** column name */
  IsAmendment = "IsAmendment",
  /** column name */
  IsResident = "IsResident",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  PropincIncome = "PropincIncome",
  /** column name */
  PropincRate = "PropincRate",
  /** column name */
  PropincTax = "PropincTax",
  /** column name */
  RentincRate = "RentincRate",
  /** column name */
  RentincTax = "RentincTax",
  /** column name */
  RtnDt = "RtnDt",
  /** column name */
  RtnFromDt = "RtnFromDt",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnPeriodYear = "RtnPeriodYear",
  /** column name */
  RtnPeriodYearGenerated = "RtnPeriodYearGenerated",
  /** column name */
  RtnToDt = "RtnToDt",
  /** column name */
  SourceType = "SourceType",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  TotIncome = "TotIncome",
  /** column name */
  TotRate = "TotRate",
  /** column name */
  TotTax = "TotTax",
  /** column name */
  Uuid = "Uuid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Set_Input = {
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryIndividual_stddev_fields";
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Stddev_Order_By = {
  ChargeableIncome?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryIndividual_stddev_pop_fields";
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Stddev_Pop_Order_By = {
  ChargeableIncome?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryIndividual_stddev_samp_fields";
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Stddev_Samp_Order_By = {
  ChargeableIncome?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryIndividual_sum_fields";
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Sum_Order_By = {
  ChargeableIncome?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryIndividual_var_pop_fields";
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Var_Pop_Order_By = {
  ChargeableIncome?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryIndividual_var_samp_fields";
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Var_Samp_Order_By = {
  ChargeableIncome?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Variance_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryIndividual_variance_fields";
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Variance_Order_By = {
  ChargeableIncome?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryNonIndividual";
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BalanceCarryForward?: Maybe<Scalars["String"]>;
  BalancePrevPeriod?: Maybe<Scalars["String"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["String"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["String"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalReserve?: Maybe<Scalars["String"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Aggregate = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryNonIndividual_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual>;
};

/** aggregate fields of "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryNonIndividual_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryNonIndividual_avg_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Avg_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Bool_Exp = {
  AdjProfitLossRental?: Maybe<Float8_Comparison_Exp>;
  AdjRentlIncmLoss?: Maybe<Float8_Comparison_Exp>;
  AdministratingExp?: Maybe<Float8_Comparison_Exp>;
  AllowblApportionedCap?: Maybe<Float8_Comparison_Exp>;
  AssessedChrgblRentlIncm?: Maybe<Float8_Comparison_Exp>;
  AssessedRentlLoss?: Maybe<Float8_Comparison_Exp>;
  BadDebtWriteOff?: Maybe<Float8_Comparison_Exp>;
  BalanceCarryForward?: Maybe<String_Comparison_Exp>;
  BalancePrevPeriod?: Maybe<String_Comparison_Exp>;
  BrghtFwdAssessedLoss?: Maybe<Float8_Comparison_Exp>;
  BusinessIncome?: Maybe<Float8_Comparison_Exp>;
  BusinessRate?: Maybe<Float8_Comparison_Exp>;
  BusinessTax?: Maybe<Float8_Comparison_Exp>;
  ChargeableIncome?: Maybe<Float8_Comparison_Exp>;
  CostOfSales?: Maybe<Float8_Comparison_Exp>;
  Debenture?: Maybe<Float8_Comparison_Exp>;
  DeferTaxLiability?: Maybe<Float8_Comparison_Exp>;
  Depreciation?: Maybe<Float8_Comparison_Exp>;
  EquityShare?: Maybe<Float8_Comparison_Exp>;
  FactoryRentRates?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FinanceExp?: Maybe<Float8_Comparison_Exp>;
  FixedDeposit?: Maybe<Float8_Comparison_Exp>;
  GovtSecurities?: Maybe<String_Comparison_Exp>;
  GrossProfit?: Maybe<Float8_Comparison_Exp>;
  GrossRentalIncome?: Maybe<Float8_Comparison_Exp>;
  IsAmendment?: Maybe<String_Comparison_Exp>;
  IsResident?: Maybe<String_Comparison_Exp>;
  LandBuild?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  NonAllowblExp?: Maybe<Float8_Comparison_Exp>;
  OperatingExp?: Maybe<Float8_Comparison_Exp>;
  OtherInvestments?: Maybe<Float8_Comparison_Exp>;
  OthrAllowblExp?: Maybe<Float8_Comparison_Exp>;
  PayableAmtInc?: Maybe<Float8_Comparison_Exp>;
  PayableAmtRent?: Maybe<Float8_Comparison_Exp>;
  ProfitAfterDividend?: Maybe<Float8_Comparison_Exp>;
  ProfitAfterTax?: Maybe<Float8_Comparison_Exp>;
  ProfitBeforeTax?: Maybe<Float8_Comparison_Exp>;
  ProfitLossRental?: Maybe<Float8_Comparison_Exp>;
  ProposedDividend?: Maybe<Float8_Comparison_Exp>;
  ProvisionIncomeTax?: Maybe<Float8_Comparison_Exp>;
  RefundAmtInc?: Maybe<Float8_Comparison_Exp>;
  RefundAmtRent?: Maybe<Float8_Comparison_Exp>;
  RentRates?: Maybe<Float8_Comparison_Exp>;
  RentincIncome?: Maybe<Float8_Comparison_Exp>;
  RentincRate?: Maybe<Float8_Comparison_Exp>;
  RentincTax?: Maybe<Float8_Comparison_Exp>;
  RtnDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnFromDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnPeriodYear?: Maybe<String_Comparison_Exp>;
  RtnPeriodYearGenerated?: Maybe<String_Comparison_Exp>;
  SecureTotLoan?: Maybe<String_Comparison_Exp>;
  SelfAssessTaxPaidInc?: Maybe<Float8_Comparison_Exp>;
  SelfAssessTaxPaidRent?: Maybe<Float8_Comparison_Exp>;
  SourceFund?: Maybe<String_Comparison_Exp>;
  SourceType?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  TotAdminExpense?: Maybe<Float8_Comparison_Exp>;
  TotBsnsProfSales?: Maybe<Float8_Comparison_Exp>;
  TotExpPurelyRental?: Maybe<Float8_Comparison_Exp>;
  TotFinancialExpense?: Maybe<Float8_Comparison_Exp>;
  TotFixedAsset?: Maybe<String_Comparison_Exp>;
  TotIncome?: Maybe<Float8_Comparison_Exp>;
  TotNonChargeableIncome?: Maybe<Float8_Comparison_Exp>;
  TotOperationExpense?: Maybe<Float8_Comparison_Exp>;
  TotOtherExpense?: Maybe<Float8_Comparison_Exp>;
  TotRate?: Maybe<Float8_Comparison_Exp>;
  TotRentAdminiExpns?: Maybe<Float8_Comparison_Exp>;
  TotRentFinancExpns?: Maybe<Float8_Comparison_Exp>;
  TotRentIncmExpns?: Maybe<Float8_Comparison_Exp>;
  TotRentOprtgExpns?: Maybe<Float8_Comparison_Exp>;
  TotTax?: Maybe<Float8_Comparison_Exp>;
  TotTaxLiability?: Maybe<Float8_Comparison_Exp>;
  TotTaxLiabilityInc?: Maybe<Float8_Comparison_Exp>;
  TotTaxLiabilityRent?: Maybe<Float8_Comparison_Exp>;
  TotTaxesPaidInc?: Maybe<Float8_Comparison_Exp>;
  TotTaxesPaidRent?: Maybe<Float8_Comparison_Exp>;
  TotTradeInvestment?: Maybe<String_Comparison_Exp>;
  TotWthdTaxDeduct?: Maybe<Float8_Comparison_Exp>;
  TotalPropFund?: Maybe<String_Comparison_Exp>;
  TotalReserve?: Maybe<String_Comparison_Exp>;
  TotalShareCapital?: Maybe<Float8_Comparison_Exp>;
  UnsecureTotLoan?: Maybe<String_Comparison_Exp>;
  Uuid?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Inc_Input = {
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Insert_Input = {
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BalanceCarryForward?: Maybe<Scalars["String"]>;
  BalancePrevPeriod?: Maybe<Scalars["String"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["String"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["String"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalReserve?: Maybe<Scalars["String"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryNonIndividual_max_fields";
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BalanceCarryForward?: Maybe<Scalars["String"]>;
  BalancePrevPeriod?: Maybe<Scalars["String"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["String"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["String"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalReserve?: Maybe<Scalars["String"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Max_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BalanceCarryForward?: Maybe<Order_By>;
  BalancePrevPeriod?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnPeriodYearGenerated?: Maybe<Order_By>;
  SecureTotLoan?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  SourceFund?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalPropFund?: Maybe<Order_By>;
  TotalReserve?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
  UnsecureTotLoan?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryNonIndividual_min_fields";
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BalanceCarryForward?: Maybe<Scalars["String"]>;
  BalancePrevPeriod?: Maybe<Scalars["String"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["String"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["String"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalReserve?: Maybe<Scalars["String"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Min_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BalanceCarryForward?: Maybe<Order_By>;
  BalancePrevPeriod?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnPeriodYearGenerated?: Maybe<Order_By>;
  SecureTotLoan?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  SourceFund?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalPropFund?: Maybe<Order_By>;
  TotalReserve?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
  UnsecureTotLoan?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Mutation_Response = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryNonIndividual_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BalanceCarryForward?: Maybe<Order_By>;
  BalancePrevPeriod?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnPeriodYearGenerated?: Maybe<Order_By>;
  SecureTotLoan?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  SourceFund?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalPropFund?: Maybe<Order_By>;
  TotalReserve?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
  UnsecureTotLoan?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
export enum Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Select_Column {
  /** column name */
  AdjProfitLossRental = "AdjProfitLossRental",
  /** column name */
  AdjRentlIncmLoss = "AdjRentlIncmLoss",
  /** column name */
  AdministratingExp = "AdministratingExp",
  /** column name */
  AllowblApportionedCap = "AllowblApportionedCap",
  /** column name */
  AssessedChrgblRentlIncm = "AssessedChrgblRentlIncm",
  /** column name */
  AssessedRentlLoss = "AssessedRentlLoss",
  /** column name */
  BadDebtWriteOff = "BadDebtWriteOff",
  /** column name */
  BalanceCarryForward = "BalanceCarryForward",
  /** column name */
  BalancePrevPeriod = "BalancePrevPeriod",
  /** column name */
  BrghtFwdAssessedLoss = "BrghtFwdAssessedLoss",
  /** column name */
  BusinessIncome = "BusinessIncome",
  /** column name */
  BusinessRate = "BusinessRate",
  /** column name */
  BusinessTax = "BusinessTax",
  /** column name */
  ChargeableIncome = "ChargeableIncome",
  /** column name */
  CostOfSales = "CostOfSales",
  /** column name */
  Debenture = "Debenture",
  /** column name */
  DeferTaxLiability = "DeferTaxLiability",
  /** column name */
  Depreciation = "Depreciation",
  /** column name */
  EquityShare = "EquityShare",
  /** column name */
  FactoryRentRates = "FactoryRentRates",
  /** column name */
  File = "File",
  /** column name */
  FinanceExp = "FinanceExp",
  /** column name */
  FixedDeposit = "FixedDeposit",
  /** column name */
  GovtSecurities = "GovtSecurities",
  /** column name */
  GrossProfit = "GrossProfit",
  /** column name */
  GrossRentalIncome = "GrossRentalIncome",
  /** column name */
  IsAmendment = "IsAmendment",
  /** column name */
  IsResident = "IsResident",
  /** column name */
  LandBuild = "LandBuild",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  NonAllowblExp = "NonAllowblExp",
  /** column name */
  OperatingExp = "OperatingExp",
  /** column name */
  OtherInvestments = "OtherInvestments",
  /** column name */
  OthrAllowblExp = "OthrAllowblExp",
  /** column name */
  PayableAmtInc = "PayableAmtInc",
  /** column name */
  PayableAmtRent = "PayableAmtRent",
  /** column name */
  ProfitAfterDividend = "ProfitAfterDividend",
  /** column name */
  ProfitAfterTax = "ProfitAfterTax",
  /** column name */
  ProfitBeforeTax = "ProfitBeforeTax",
  /** column name */
  ProfitLossRental = "ProfitLossRental",
  /** column name */
  ProposedDividend = "ProposedDividend",
  /** column name */
  ProvisionIncomeTax = "ProvisionIncomeTax",
  /** column name */
  RefundAmtInc = "RefundAmtInc",
  /** column name */
  RefundAmtRent = "RefundAmtRent",
  /** column name */
  RentRates = "RentRates",
  /** column name */
  RentincIncome = "RentincIncome",
  /** column name */
  RentincRate = "RentincRate",
  /** column name */
  RentincTax = "RentincTax",
  /** column name */
  RtnDt = "RtnDt",
  /** column name */
  RtnFromDt = "RtnFromDt",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnPeriodYear = "RtnPeriodYear",
  /** column name */
  RtnPeriodYearGenerated = "RtnPeriodYearGenerated",
  /** column name */
  SecureTotLoan = "SecureTotLoan",
  /** column name */
  SelfAssessTaxPaidInc = "SelfAssessTaxPaidInc",
  /** column name */
  SelfAssessTaxPaidRent = "SelfAssessTaxPaidRent",
  /** column name */
  SourceFund = "SourceFund",
  /** column name */
  SourceType = "SourceType",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  TotAdminExpense = "TotAdminExpense",
  /** column name */
  TotBsnsProfSales = "TotBsnsProfSales",
  /** column name */
  TotExpPurelyRental = "TotExpPurelyRental",
  /** column name */
  TotFinancialExpense = "TotFinancialExpense",
  /** column name */
  TotFixedAsset = "TotFixedAsset",
  /** column name */
  TotIncome = "TotIncome",
  /** column name */
  TotNonChargeableIncome = "TotNonChargeableIncome",
  /** column name */
  TotOperationExpense = "TotOperationExpense",
  /** column name */
  TotOtherExpense = "TotOtherExpense",
  /** column name */
  TotRate = "TotRate",
  /** column name */
  TotRentAdminiExpns = "TotRentAdminiExpns",
  /** column name */
  TotRentFinancExpns = "TotRentFinancExpns",
  /** column name */
  TotRentIncmExpns = "TotRentIncmExpns",
  /** column name */
  TotRentOprtgExpns = "TotRentOprtgExpns",
  /** column name */
  TotTax = "TotTax",
  /** column name */
  TotTaxLiability = "TotTaxLiability",
  /** column name */
  TotTaxLiabilityInc = "TotTaxLiabilityInc",
  /** column name */
  TotTaxLiabilityRent = "TotTaxLiabilityRent",
  /** column name */
  TotTaxesPaidInc = "TotTaxesPaidInc",
  /** column name */
  TotTaxesPaidRent = "TotTaxesPaidRent",
  /** column name */
  TotTradeInvestment = "TotTradeInvestment",
  /** column name */
  TotWthdTaxDeduct = "TotWthdTaxDeduct",
  /** column name */
  TotalPropFund = "TotalPropFund",
  /** column name */
  TotalReserve = "TotalReserve",
  /** column name */
  TotalShareCapital = "TotalShareCapital",
  /** column name */
  UnsecureTotLoan = "UnsecureTotLoan",
  /** column name */
  Uuid = "Uuid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Set_Input = {
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BalanceCarryForward?: Maybe<Scalars["String"]>;
  BalancePrevPeriod?: Maybe<Scalars["String"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["String"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["String"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalReserve?: Maybe<Scalars["String"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryNonIndividual_stddev_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Stddev_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryNonIndividual_stddev_pop_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Stddev_Pop_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryNonIndividual_stddev_samp_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Stddev_Samp_Order_By =
  {
    AdjProfitLossRental?: Maybe<Order_By>;
    AdjRentlIncmLoss?: Maybe<Order_By>;
    AdministratingExp?: Maybe<Order_By>;
    AllowblApportionedCap?: Maybe<Order_By>;
    AssessedChrgblRentlIncm?: Maybe<Order_By>;
    AssessedRentlLoss?: Maybe<Order_By>;
    BadDebtWriteOff?: Maybe<Order_By>;
    BrghtFwdAssessedLoss?: Maybe<Order_By>;
    BusinessIncome?: Maybe<Order_By>;
    BusinessRate?: Maybe<Order_By>;
    BusinessTax?: Maybe<Order_By>;
    ChargeableIncome?: Maybe<Order_By>;
    CostOfSales?: Maybe<Order_By>;
    Debenture?: Maybe<Order_By>;
    DeferTaxLiability?: Maybe<Order_By>;
    Depreciation?: Maybe<Order_By>;
    EquityShare?: Maybe<Order_By>;
    FactoryRentRates?: Maybe<Order_By>;
    FinanceExp?: Maybe<Order_By>;
    FixedDeposit?: Maybe<Order_By>;
    GrossProfit?: Maybe<Order_By>;
    GrossRentalIncome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    NonAllowblExp?: Maybe<Order_By>;
    OperatingExp?: Maybe<Order_By>;
    OtherInvestments?: Maybe<Order_By>;
    OthrAllowblExp?: Maybe<Order_By>;
    PayableAmtInc?: Maybe<Order_By>;
    PayableAmtRent?: Maybe<Order_By>;
    ProfitAfterDividend?: Maybe<Order_By>;
    ProfitAfterTax?: Maybe<Order_By>;
    ProfitBeforeTax?: Maybe<Order_By>;
    ProfitLossRental?: Maybe<Order_By>;
    ProposedDividend?: Maybe<Order_By>;
    ProvisionIncomeTax?: Maybe<Order_By>;
    RefundAmtInc?: Maybe<Order_By>;
    RefundAmtRent?: Maybe<Order_By>;
    RentRates?: Maybe<Order_By>;
    RentincIncome?: Maybe<Order_By>;
    RentincRate?: Maybe<Order_By>;
    RentincTax?: Maybe<Order_By>;
    SelfAssessTaxPaidInc?: Maybe<Order_By>;
    SelfAssessTaxPaidRent?: Maybe<Order_By>;
    TotAdminExpense?: Maybe<Order_By>;
    TotBsnsProfSales?: Maybe<Order_By>;
    TotExpPurelyRental?: Maybe<Order_By>;
    TotFinancialExpense?: Maybe<Order_By>;
    TotIncome?: Maybe<Order_By>;
    TotNonChargeableIncome?: Maybe<Order_By>;
    TotOperationExpense?: Maybe<Order_By>;
    TotOtherExpense?: Maybe<Order_By>;
    TotRate?: Maybe<Order_By>;
    TotRentAdminiExpns?: Maybe<Order_By>;
    TotRentFinancExpns?: Maybe<Order_By>;
    TotRentIncmExpns?: Maybe<Order_By>;
    TotRentOprtgExpns?: Maybe<Order_By>;
    TotTax?: Maybe<Order_By>;
    TotTaxLiability?: Maybe<Order_By>;
    TotTaxLiabilityInc?: Maybe<Order_By>;
    TotTaxLiabilityRent?: Maybe<Order_By>;
    TotTaxesPaidInc?: Maybe<Order_By>;
    TotTaxesPaidRent?: Maybe<Order_By>;
    TotWthdTaxDeduct?: Maybe<Order_By>;
    TotalShareCapital?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryNonIndividual_sum_fields";
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Sum_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryNonIndividual_var_pop_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Var_Pop_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryNonIndividual_var_samp_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Var_Samp_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Variance_Fields = {
  __typename?: "rtcs_db_PH_ura_IncomeTaxSummaryNonIndividual_variance_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Variance_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_ura_IndividualMortgage" */
export type Rtcs_Db_Ph_Ura_IndividualMortgage = {
  __typename?: "rtcs_db_PH_ura_IndividualMortgage";
  DeedNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FinanceName?: Maybe<Scalars["String"]>;
  FinanceTin?: Maybe<Scalars["bigint"]>;
  IntersetMortgage?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PeriodFrom?: Maybe<Scalars["timestamptz"]>;
  PeriodTo?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TotalMortgage?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_IndividualMortgage" */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Aggregate = {
  __typename?: "rtcs_db_PH_ura_IndividualMortgage_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_IndividualMortgage>;
};

/** aggregate fields of "rtcs_db.PH_ura_IndividualMortgage" */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ura_IndividualMortgage_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ura_IndividualMortgage" */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Ura_IndividualMortgage_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_ura_IndividualMortgage" */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_IndividualMortgage" */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Ura_IndividualMortgage_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_IndividualMortgage_avg_fields";
  FinanceTin?: Maybe<Scalars["Float"]>;
  IntersetMortgage?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TotalMortgage?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_IndividualMortgage" */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Avg_Order_By = {
  FinanceTin?: Maybe<Order_By>;
  IntersetMortgage?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TotalMortgage?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_IndividualMortgage". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Bool_Exp = {
  DeedNo?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FinanceName?: Maybe<String_Comparison_Exp>;
  FinanceTin?: Maybe<Bigint_Comparison_Exp>;
  IntersetMortgage?: Maybe<Float8_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  PeriodFrom?: Maybe<Timestamptz_Comparison_Exp>;
  PeriodTo?: Maybe<Timestamptz_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  SourceType?: Maybe<String_Comparison_Exp>;
  TotalMortgage?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_IndividualMortgage" */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Inc_Input = {
  FinanceTin?: Maybe<Scalars["bigint"]>;
  IntersetMortgage?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  TotalMortgage?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_IndividualMortgage" */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Insert_Input = {
  DeedNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FinanceName?: Maybe<Scalars["String"]>;
  FinanceTin?: Maybe<Scalars["bigint"]>;
  IntersetMortgage?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PeriodFrom?: Maybe<Scalars["timestamptz"]>;
  PeriodTo?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TotalMortgage?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_IndividualMortgage_max_fields";
  DeedNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FinanceName?: Maybe<Scalars["String"]>;
  FinanceTin?: Maybe<Scalars["bigint"]>;
  IntersetMortgage?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PeriodFrom?: Maybe<Scalars["timestamptz"]>;
  PeriodTo?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TotalMortgage?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_IndividualMortgage" */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Max_Order_By = {
  DeedNo?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinanceName?: Maybe<Order_By>;
  FinanceTin?: Maybe<Order_By>;
  IntersetMortgage?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PeriodFrom?: Maybe<Order_By>;
  PeriodTo?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  TotalMortgage?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_IndividualMortgage_min_fields";
  DeedNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FinanceName?: Maybe<Scalars["String"]>;
  FinanceTin?: Maybe<Scalars["bigint"]>;
  IntersetMortgage?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PeriodFrom?: Maybe<Scalars["timestamptz"]>;
  PeriodTo?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TotalMortgage?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_IndividualMortgage" */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Min_Order_By = {
  DeedNo?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinanceName?: Maybe<Order_By>;
  FinanceTin?: Maybe<Order_By>;
  IntersetMortgage?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PeriodFrom?: Maybe<Order_By>;
  PeriodTo?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  TotalMortgage?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ura_IndividualMortgage" */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Mutation_Response = {
  __typename?: "rtcs_db_PH_ura_IndividualMortgage_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Ura_IndividualMortgage>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_IndividualMortgage" */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Ura_IndividualMortgage_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_ura_IndividualMortgage" */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Order_By = {
  DeedNo?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinanceName?: Maybe<Order_By>;
  FinanceTin?: Maybe<Order_By>;
  IntersetMortgage?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PeriodFrom?: Maybe<Order_By>;
  PeriodTo?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  TotalMortgage?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_IndividualMortgage" */
export enum Rtcs_Db_Ph_Ura_IndividualMortgage_Select_Column {
  /** column name */
  DeedNo = "DeedNo",
  /** column name */
  File = "File",
  /** column name */
  FinanceName = "FinanceName",
  /** column name */
  FinanceTin = "FinanceTin",
  /** column name */
  IntersetMortgage = "IntersetMortgage",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  PeriodFrom = "PeriodFrom",
  /** column name */
  PeriodTo = "PeriodTo",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  SourceType = "SourceType",
  /** column name */
  TotalMortgage = "TotalMortgage",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_IndividualMortgage" */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Set_Input = {
  DeedNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FinanceName?: Maybe<Scalars["String"]>;
  FinanceTin?: Maybe<Scalars["bigint"]>;
  IntersetMortgage?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PeriodFrom?: Maybe<Scalars["timestamptz"]>;
  PeriodTo?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TotalMortgage?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ura_IndividualMortgage_stddev_fields";
  FinanceTin?: Maybe<Scalars["Float"]>;
  IntersetMortgage?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TotalMortgage?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ura_IndividualMortgage" */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Stddev_Order_By = {
  FinanceTin?: Maybe<Order_By>;
  IntersetMortgage?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TotalMortgage?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_IndividualMortgage_stddev_pop_fields";
  FinanceTin?: Maybe<Scalars["Float"]>;
  IntersetMortgage?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TotalMortgage?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_IndividualMortgage" */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Stddev_Pop_Order_By = {
  FinanceTin?: Maybe<Order_By>;
  IntersetMortgage?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TotalMortgage?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_IndividualMortgage_stddev_samp_fields";
  FinanceTin?: Maybe<Scalars["Float"]>;
  IntersetMortgage?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TotalMortgage?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_IndividualMortgage" */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Stddev_Samp_Order_By = {
  FinanceTin?: Maybe<Order_By>;
  IntersetMortgage?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TotalMortgage?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_IndividualMortgage_sum_fields";
  FinanceTin?: Maybe<Scalars["bigint"]>;
  IntersetMortgage?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  TotalMortgage?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_IndividualMortgage" */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Sum_Order_By = {
  FinanceTin?: Maybe<Order_By>;
  IntersetMortgage?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TotalMortgage?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_IndividualMortgage_var_pop_fields";
  FinanceTin?: Maybe<Scalars["Float"]>;
  IntersetMortgage?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TotalMortgage?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ura_IndividualMortgage" */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Var_Pop_Order_By = {
  FinanceTin?: Maybe<Order_By>;
  IntersetMortgage?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TotalMortgage?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_IndividualMortgage_var_samp_fields";
  FinanceTin?: Maybe<Scalars["Float"]>;
  IntersetMortgage?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TotalMortgage?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ura_IndividualMortgage" */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Var_Samp_Order_By = {
  FinanceTin?: Maybe<Order_By>;
  IntersetMortgage?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TotalMortgage?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Variance_Fields = {
  __typename?: "rtcs_db_PH_ura_IndividualMortgage_variance_fields";
  FinanceTin?: Maybe<Scalars["Float"]>;
  IntersetMortgage?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TotalMortgage?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ura_IndividualMortgage" */
export type Rtcs_Db_Ph_Ura_IndividualMortgage_Variance_Order_By = {
  FinanceTin?: Maybe<Order_By>;
  IntersetMortgage?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TotalMortgage?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness = {
  __typename?: "rtcs_db_PH_ura_IndividualsAssociatedBusiness";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTaxPayerName?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Aggregate = {
  __typename?: "rtcs_db_PH_ura_IndividualsAssociatedBusiness_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness>;
};

/** aggregate fields of "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ura_IndividualsAssociatedBusiness_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_IndividualsAssociatedBusiness_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Avg_Order_By = {
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_IndividualsAssociatedBusiness". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Bool_Exp = {
  ActvtyDesc?: Maybe<String_Comparison_Exp>;
  AssociateTaxPayerName?: Maybe<String_Comparison_Exp>;
  AssociateTin?: Maybe<String_Comparison_Exp>;
  EntityDesignation?: Maybe<String_Comparison_Exp>;
  FamilyName?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FirstName?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MainActivity?: Maybe<String_Comparison_Exp>;
  OtherName?: Maybe<String_Comparison_Exp>;
  RegAssociatedEntityDtlId?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  TaxPayerType?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Insert_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTaxPayerName?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_IndividualsAssociatedBusiness_max_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTaxPayerName?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Max_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AssociateTaxPayerName?: Maybe<Order_By>;
  AssociateTin?: Maybe<Order_By>;
  EntityDesignation?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  RegAssociatedEntityDtlId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_IndividualsAssociatedBusiness_min_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTaxPayerName?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Min_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AssociateTaxPayerName?: Maybe<Order_By>;
  AssociateTin?: Maybe<Order_By>;
  EntityDesignation?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  RegAssociatedEntityDtlId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Mutation_Response = {
  __typename?: "rtcs_db_PH_ura_IndividualsAssociatedBusiness_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AssociateTaxPayerName?: Maybe<Order_By>;
  AssociateTin?: Maybe<Order_By>;
  EntityDesignation?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  RegAssociatedEntityDtlId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
export enum Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Select_Column {
  /** column name */
  ActvtyDesc = "ActvtyDesc",
  /** column name */
  AssociateTaxPayerName = "AssociateTaxPayerName",
  /** column name */
  AssociateTin = "AssociateTin",
  /** column name */
  EntityDesignation = "EntityDesignation",
  /** column name */
  FamilyName = "FamilyName",
  /** column name */
  File = "File",
  /** column name */
  FirstName = "FirstName",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MainActivity = "MainActivity",
  /** column name */
  OtherName = "OtherName",
  /** column name */
  RegAssociatedEntityDtlId = "RegAssociatedEntityDtlId",
  /** column name */
  Surname = "Surname",
  /** column name */
  TaxPayerType = "TaxPayerType",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Set_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTaxPayerName?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ura_IndividualsAssociatedBusiness_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_IndividualsAssociatedBusiness_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_IndividualsAssociatedBusiness_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Stddev_Samp_Order_By =
  {
    Line?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_IndividualsAssociatedBusiness_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Sum_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_IndividualsAssociatedBusiness_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_IndividualsAssociatedBusiness_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Variance_Fields = {
  __typename?: "rtcs_db_PH_ura_IndividualsAssociatedBusiness_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Variance_Order_By = {
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeDetail";
  AssessedChrgbleRentlIncm?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  RtnToDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Aggregate = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeDetail_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_LandlordIncomeDetail>;
};

/** aggregate fields of "rtcs_db.PH_ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeDetail_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeDetail_avg_fields";
  AssessedChrgbleRentlIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Avg_Order_By = {
  AssessedChrgbleRentlIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_LandlordIncomeDetail". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Bool_Exp = {
  AssessedChrgbleRentlIncm?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  GrossRentalIncome?: Maybe<Float8_Comparison_Exp>;
  IsAmendment?: Maybe<String_Comparison_Exp>;
  IsResident?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  PremiseLocation?: Maybe<String_Comparison_Exp>;
  RentalIncome?: Maybe<Float8_Comparison_Exp>;
  RentincIncome?: Maybe<Float8_Comparison_Exp>;
  RtnDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnDtTaxSummary?: Maybe<Timestamptz_Comparison_Exp>;
  RtnFromDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnFromDtTaxSummary?: Maybe<Timestamptz_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnPeriodYear?: Maybe<String_Comparison_Exp>;
  RtnRentalIncomeId?: Maybe<String_Comparison_Exp>;
  RtnToDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnToDtTaxSummary?: Maybe<Timestamptz_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TenancyPeriodFrom?: Maybe<Timestamptz_Comparison_Exp>;
  TenancyPeriodTo?: Maybe<Timestamptz_Comparison_Exp>;
  TenantName?: Maybe<String_Comparison_Exp>;
  TenantTin?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Inc_Input = {
  AssessedChrgbleRentlIncm?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Insert_Input = {
  AssessedChrgbleRentlIncm?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  RtnToDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeDetail_max_fields";
  AssessedChrgbleRentlIncm?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  RtnToDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Max_Order_By = {
  AssessedChrgbleRentlIncm?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnDtTaxSummary?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnFromDtTaxSummary?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnRentalIncomeId?: Maybe<Order_By>;
  RtnToDt?: Maybe<Order_By>;
  RtnToDtTaxSummary?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  TenantName?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeDetail_min_fields";
  AssessedChrgbleRentlIncm?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  RtnToDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Min_Order_By = {
  AssessedChrgbleRentlIncm?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnDtTaxSummary?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnFromDtTaxSummary?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnRentalIncomeId?: Maybe<Order_By>;
  RtnToDt?: Maybe<Order_By>;
  RtnToDtTaxSummary?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  TenantName?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Mutation_Response = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeDetail_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Ura_LandlordIncomeDetail>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Order_By = {
  AssessedChrgbleRentlIncm?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnDtTaxSummary?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnFromDtTaxSummary?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnRentalIncomeId?: Maybe<Order_By>;
  RtnToDt?: Maybe<Order_By>;
  RtnToDtTaxSummary?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  TenantName?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_LandlordIncomeDetail" */
export enum Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Select_Column {
  /** column name */
  AssessedChrgbleRentlIncm = "AssessedChrgbleRentlIncm",
  /** column name */
  File = "File",
  /** column name */
  GrossRentalIncome = "GrossRentalIncome",
  /** column name */
  IsAmendment = "IsAmendment",
  /** column name */
  IsResident = "IsResident",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  PremiseLocation = "PremiseLocation",
  /** column name */
  RentalIncome = "RentalIncome",
  /** column name */
  RentincIncome = "RentincIncome",
  /** column name */
  RtnDt = "RtnDt",
  /** column name */
  RtnDtTaxSummary = "RtnDtTaxSummary",
  /** column name */
  RtnFromDt = "RtnFromDt",
  /** column name */
  RtnFromDtTaxSummary = "RtnFromDtTaxSummary",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnPeriodYear = "RtnPeriodYear",
  /** column name */
  RtnRentalIncomeId = "RtnRentalIncomeId",
  /** column name */
  RtnToDt = "RtnToDt",
  /** column name */
  RtnToDtTaxSummary = "RtnToDtTaxSummary",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TenancyPeriodFrom = "TenancyPeriodFrom",
  /** column name */
  TenancyPeriodTo = "TenancyPeriodTo",
  /** column name */
  TenantName = "TenantName",
  /** column name */
  TenantTin = "TenantTin",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Set_Input = {
  AssessedChrgbleRentlIncm?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  RtnToDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeDetail_stddev_fields";
  AssessedChrgbleRentlIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Stddev_Order_By = {
  AssessedChrgbleRentlIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeDetail_stddev_pop_fields";
  AssessedChrgbleRentlIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Stddev_Pop_Order_By = {
  AssessedChrgbleRentlIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeDetail_stddev_samp_fields";
  AssessedChrgbleRentlIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Stddev_Samp_Order_By = {
  AssessedChrgbleRentlIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeDetail_sum_fields";
  AssessedChrgbleRentlIncm?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Sum_Order_By = {
  AssessedChrgbleRentlIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeDetail_var_pop_fields";
  AssessedChrgbleRentlIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Var_Pop_Order_By = {
  AssessedChrgbleRentlIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeDetail_var_samp_fields";
  AssessedChrgbleRentlIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Var_Samp_Order_By = {
  AssessedChrgbleRentlIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Variance_Fields = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeDetail_variance_fields";
  AssessedChrgbleRentlIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Variance_Order_By = {
  AssessedChrgbleRentlIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeSummary";
  Difference?: Maybe<Scalars["float8"]>;
  DifferencePercentage?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  GrossDifference?: Maybe<Scalars["float8"]>;
  GrossDifferencePercentage?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  LandlordFirstName?: Maybe<Scalars["String"]>;
  LandlordSurname?: Maybe<Scalars["String"]>;
  LandlordTaxpayerName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LandlordTinSource?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["float8"]>;
  RentReportedByTenants?: Maybe<Scalars["float8"]>;
  ReturnNo?: Maybe<Scalars["String"]>;
  ReturnNoSource?: Maybe<Scalars["String"]>;
  ReturnPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  ReturnPeriodTo?: Maybe<Scalars["timestamptz"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  TaxYear?: Maybe<Scalars["String"]>;
  TaxpayerAndTinDataSource?: Maybe<Scalars["String"]>;
  Unnamed0?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Aggregate = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeSummary_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_LandlordIncomeSummary>;
};

/** aggregate fields of "rtcs_db.PH_ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeSummary_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeSummary_avg_fields";
  Difference?: Maybe<Scalars["Float"]>;
  GrossDifference?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["Float"]>;
  RentReportedByTenants?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  Unnamed0?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Avg_Order_By = {
  Difference?: Maybe<Order_By>;
  GrossDifference?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentDeclaredByLandlord?: Maybe<Order_By>;
  RentReportedByTenants?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  Unnamed0?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_LandlordIncomeSummary". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Bool_Exp = {
  Difference?: Maybe<Float8_Comparison_Exp>;
  DifferencePercentage?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  GrossDifference?: Maybe<Float8_Comparison_Exp>;
  GrossDifferencePercentage?: Maybe<String_Comparison_Exp>;
  GrossRentalIncome?: Maybe<Float8_Comparison_Exp>;
  LandlordFirstName?: Maybe<String_Comparison_Exp>;
  LandlordSurname?: Maybe<String_Comparison_Exp>;
  LandlordTaxpayerName?: Maybe<String_Comparison_Exp>;
  LandlordTin?: Maybe<String_Comparison_Exp>;
  LandlordTinSource?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  RentDeclaredByLandlord?: Maybe<Float8_Comparison_Exp>;
  RentReportedByTenants?: Maybe<Float8_Comparison_Exp>;
  ReturnNo?: Maybe<String_Comparison_Exp>;
  ReturnNoSource?: Maybe<String_Comparison_Exp>;
  ReturnPeriodFrom?: Maybe<Timestamptz_Comparison_Exp>;
  ReturnPeriodTo?: Maybe<Timestamptz_Comparison_Exp>;
  RmatchScore?: Maybe<Float8_Comparison_Exp>;
  TaxYear?: Maybe<String_Comparison_Exp>;
  TaxpayerAndTinDataSource?: Maybe<String_Comparison_Exp>;
  Unnamed0?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Inc_Input = {
  Difference?: Maybe<Scalars["float8"]>;
  GrossDifference?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["float8"]>;
  RentReportedByTenants?: Maybe<Scalars["float8"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  Unnamed0?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Insert_Input = {
  Difference?: Maybe<Scalars["float8"]>;
  DifferencePercentage?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  GrossDifference?: Maybe<Scalars["float8"]>;
  GrossDifferencePercentage?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  LandlordFirstName?: Maybe<Scalars["String"]>;
  LandlordSurname?: Maybe<Scalars["String"]>;
  LandlordTaxpayerName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LandlordTinSource?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["float8"]>;
  RentReportedByTenants?: Maybe<Scalars["float8"]>;
  ReturnNo?: Maybe<Scalars["String"]>;
  ReturnNoSource?: Maybe<Scalars["String"]>;
  ReturnPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  ReturnPeriodTo?: Maybe<Scalars["timestamptz"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  TaxYear?: Maybe<Scalars["String"]>;
  TaxpayerAndTinDataSource?: Maybe<Scalars["String"]>;
  Unnamed0?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeSummary_max_fields";
  Difference?: Maybe<Scalars["float8"]>;
  DifferencePercentage?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  GrossDifference?: Maybe<Scalars["float8"]>;
  GrossDifferencePercentage?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  LandlordFirstName?: Maybe<Scalars["String"]>;
  LandlordSurname?: Maybe<Scalars["String"]>;
  LandlordTaxpayerName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LandlordTinSource?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["float8"]>;
  RentReportedByTenants?: Maybe<Scalars["float8"]>;
  ReturnNo?: Maybe<Scalars["String"]>;
  ReturnNoSource?: Maybe<Scalars["String"]>;
  ReturnPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  ReturnPeriodTo?: Maybe<Scalars["timestamptz"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  TaxYear?: Maybe<Scalars["String"]>;
  TaxpayerAndTinDataSource?: Maybe<Scalars["String"]>;
  Unnamed0?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Max_Order_By = {
  Difference?: Maybe<Order_By>;
  DifferencePercentage?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GrossDifference?: Maybe<Order_By>;
  GrossDifferencePercentage?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  LandlordFirstName?: Maybe<Order_By>;
  LandlordSurname?: Maybe<Order_By>;
  LandlordTaxpayerName?: Maybe<Order_By>;
  LandlordTin?: Maybe<Order_By>;
  LandlordTinSource?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentDeclaredByLandlord?: Maybe<Order_By>;
  RentReportedByTenants?: Maybe<Order_By>;
  ReturnNo?: Maybe<Order_By>;
  ReturnNoSource?: Maybe<Order_By>;
  ReturnPeriodFrom?: Maybe<Order_By>;
  ReturnPeriodTo?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  TaxYear?: Maybe<Order_By>;
  TaxpayerAndTinDataSource?: Maybe<Order_By>;
  Unnamed0?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeSummary_min_fields";
  Difference?: Maybe<Scalars["float8"]>;
  DifferencePercentage?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  GrossDifference?: Maybe<Scalars["float8"]>;
  GrossDifferencePercentage?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  LandlordFirstName?: Maybe<Scalars["String"]>;
  LandlordSurname?: Maybe<Scalars["String"]>;
  LandlordTaxpayerName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LandlordTinSource?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["float8"]>;
  RentReportedByTenants?: Maybe<Scalars["float8"]>;
  ReturnNo?: Maybe<Scalars["String"]>;
  ReturnNoSource?: Maybe<Scalars["String"]>;
  ReturnPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  ReturnPeriodTo?: Maybe<Scalars["timestamptz"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  TaxYear?: Maybe<Scalars["String"]>;
  TaxpayerAndTinDataSource?: Maybe<Scalars["String"]>;
  Unnamed0?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Min_Order_By = {
  Difference?: Maybe<Order_By>;
  DifferencePercentage?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GrossDifference?: Maybe<Order_By>;
  GrossDifferencePercentage?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  LandlordFirstName?: Maybe<Order_By>;
  LandlordSurname?: Maybe<Order_By>;
  LandlordTaxpayerName?: Maybe<Order_By>;
  LandlordTin?: Maybe<Order_By>;
  LandlordTinSource?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentDeclaredByLandlord?: Maybe<Order_By>;
  RentReportedByTenants?: Maybe<Order_By>;
  ReturnNo?: Maybe<Order_By>;
  ReturnNoSource?: Maybe<Order_By>;
  ReturnPeriodFrom?: Maybe<Order_By>;
  ReturnPeriodTo?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  TaxYear?: Maybe<Order_By>;
  TaxpayerAndTinDataSource?: Maybe<Order_By>;
  Unnamed0?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Mutation_Response = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeSummary_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Ura_LandlordIncomeSummary>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Order_By = {
  Difference?: Maybe<Order_By>;
  DifferencePercentage?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GrossDifference?: Maybe<Order_By>;
  GrossDifferencePercentage?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  LandlordFirstName?: Maybe<Order_By>;
  LandlordSurname?: Maybe<Order_By>;
  LandlordTaxpayerName?: Maybe<Order_By>;
  LandlordTin?: Maybe<Order_By>;
  LandlordTinSource?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentDeclaredByLandlord?: Maybe<Order_By>;
  RentReportedByTenants?: Maybe<Order_By>;
  ReturnNo?: Maybe<Order_By>;
  ReturnNoSource?: Maybe<Order_By>;
  ReturnPeriodFrom?: Maybe<Order_By>;
  ReturnPeriodTo?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  TaxYear?: Maybe<Order_By>;
  TaxpayerAndTinDataSource?: Maybe<Order_By>;
  Unnamed0?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_LandlordIncomeSummary" */
export enum Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Select_Column {
  /** column name */
  Difference = "Difference",
  /** column name */
  DifferencePercentage = "DifferencePercentage",
  /** column name */
  File = "File",
  /** column name */
  GrossDifference = "GrossDifference",
  /** column name */
  GrossDifferencePercentage = "GrossDifferencePercentage",
  /** column name */
  GrossRentalIncome = "GrossRentalIncome",
  /** column name */
  LandlordFirstName = "LandlordFirstName",
  /** column name */
  LandlordSurname = "LandlordSurname",
  /** column name */
  LandlordTaxpayerName = "LandlordTaxpayerName",
  /** column name */
  LandlordTin = "LandlordTin",
  /** column name */
  LandlordTinSource = "LandlordTinSource",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  RentDeclaredByLandlord = "RentDeclaredByLandlord",
  /** column name */
  RentReportedByTenants = "RentReportedByTenants",
  /** column name */
  ReturnNo = "ReturnNo",
  /** column name */
  ReturnNoSource = "ReturnNoSource",
  /** column name */
  ReturnPeriodFrom = "ReturnPeriodFrom",
  /** column name */
  ReturnPeriodTo = "ReturnPeriodTo",
  /** column name */
  RmatchScore = "RmatchScore",
  /** column name */
  TaxYear = "TaxYear",
  /** column name */
  TaxpayerAndTinDataSource = "TaxpayerAndTinDataSource",
  /** column name */
  Unnamed0 = "Unnamed0",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Set_Input = {
  Difference?: Maybe<Scalars["float8"]>;
  DifferencePercentage?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  GrossDifference?: Maybe<Scalars["float8"]>;
  GrossDifferencePercentage?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  LandlordFirstName?: Maybe<Scalars["String"]>;
  LandlordSurname?: Maybe<Scalars["String"]>;
  LandlordTaxpayerName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LandlordTinSource?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["float8"]>;
  RentReportedByTenants?: Maybe<Scalars["float8"]>;
  ReturnNo?: Maybe<Scalars["String"]>;
  ReturnNoSource?: Maybe<Scalars["String"]>;
  ReturnPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  ReturnPeriodTo?: Maybe<Scalars["timestamptz"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  TaxYear?: Maybe<Scalars["String"]>;
  TaxpayerAndTinDataSource?: Maybe<Scalars["String"]>;
  Unnamed0?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeSummary_stddev_fields";
  Difference?: Maybe<Scalars["Float"]>;
  GrossDifference?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["Float"]>;
  RentReportedByTenants?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  Unnamed0?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Stddev_Order_By = {
  Difference?: Maybe<Order_By>;
  GrossDifference?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentDeclaredByLandlord?: Maybe<Order_By>;
  RentReportedByTenants?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  Unnamed0?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeSummary_stddev_pop_fields";
  Difference?: Maybe<Scalars["Float"]>;
  GrossDifference?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["Float"]>;
  RentReportedByTenants?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  Unnamed0?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Stddev_Pop_Order_By = {
  Difference?: Maybe<Order_By>;
  GrossDifference?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentDeclaredByLandlord?: Maybe<Order_By>;
  RentReportedByTenants?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  Unnamed0?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeSummary_stddev_samp_fields";
  Difference?: Maybe<Scalars["Float"]>;
  GrossDifference?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["Float"]>;
  RentReportedByTenants?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  Unnamed0?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Stddev_Samp_Order_By = {
  Difference?: Maybe<Order_By>;
  GrossDifference?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentDeclaredByLandlord?: Maybe<Order_By>;
  RentReportedByTenants?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  Unnamed0?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeSummary_sum_fields";
  Difference?: Maybe<Scalars["float8"]>;
  GrossDifference?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["float8"]>;
  RentReportedByTenants?: Maybe<Scalars["float8"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  Unnamed0?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Sum_Order_By = {
  Difference?: Maybe<Order_By>;
  GrossDifference?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentDeclaredByLandlord?: Maybe<Order_By>;
  RentReportedByTenants?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  Unnamed0?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeSummary_var_pop_fields";
  Difference?: Maybe<Scalars["Float"]>;
  GrossDifference?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["Float"]>;
  RentReportedByTenants?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  Unnamed0?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Var_Pop_Order_By = {
  Difference?: Maybe<Order_By>;
  GrossDifference?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentDeclaredByLandlord?: Maybe<Order_By>;
  RentReportedByTenants?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  Unnamed0?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeSummary_var_samp_fields";
  Difference?: Maybe<Scalars["Float"]>;
  GrossDifference?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["Float"]>;
  RentReportedByTenants?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  Unnamed0?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Var_Samp_Order_By = {
  Difference?: Maybe<Order_By>;
  GrossDifference?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentDeclaredByLandlord?: Maybe<Order_By>;
  RentReportedByTenants?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  Unnamed0?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Variance_Fields = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeSummary_variance_fields";
  Difference?: Maybe<Scalars["Float"]>;
  GrossDifference?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["Float"]>;
  RentReportedByTenants?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  Unnamed0?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Variance_Order_By = {
  Difference?: Maybe<Order_By>;
  GrossDifference?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentDeclaredByLandlord?: Maybe<Order_By>;
  RentReportedByTenants?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  Unnamed0?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness = {
  __typename?: "rtcs_db_PH_ura_NonIndividualsAssociatedBusiness";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  AssociatedNonIndividual?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Aggregate = {
  __typename?: "rtcs_db_PH_ura_NonIndividualsAssociatedBusiness_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness>;
};

/** aggregate fields of "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ura_NonIndividualsAssociatedBusiness_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Aggregate_Order_By =
  {
    avg?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Max_Order_By>;
    min?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Min_Order_By>;
    stddev?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Stddev_Order_By>;
    stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Stddev_Samp_Order_By>;
    sum?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Sum_Order_By>;
    var_pop?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Var_Pop_Order_By>;
    var_samp?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Var_Samp_Order_By>;
    variance?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Variance_Order_By>;
  };

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_NonIndividualsAssociatedBusiness_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Avg_Order_By = {
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Bool_Exp = {
  ActvtyDesc?: Maybe<String_Comparison_Exp>;
  AssociateTin?: Maybe<String_Comparison_Exp>;
  AssociatedNonIndividual?: Maybe<String_Comparison_Exp>;
  EntityDesignation?: Maybe<String_Comparison_Exp>;
  FamilyName?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MainActivity?: Maybe<String_Comparison_Exp>;
  OtherName?: Maybe<String_Comparison_Exp>;
  RegAssociatedEntityDtlId?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TaxPayerType?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Insert_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  AssociatedNonIndividual?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_NonIndividualsAssociatedBusiness_max_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  AssociatedNonIndividual?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Max_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AssociateTin?: Maybe<Order_By>;
  AssociatedNonIndividual?: Maybe<Order_By>;
  EntityDesignation?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  RegAssociatedEntityDtlId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_NonIndividualsAssociatedBusiness_min_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  AssociatedNonIndividual?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Min_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AssociateTin?: Maybe<Order_By>;
  AssociatedNonIndividual?: Maybe<Order_By>;
  EntityDesignation?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  RegAssociatedEntityDtlId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Mutation_Response =
  {
    __typename?: "rtcs_db_PH_ura_NonIndividualsAssociatedBusiness_mutation_response";
    /** number of affected rows by the mutation */
    affected_rows: Scalars["Int"];
    /** data of the affected rows by the mutation */
    returning: Array<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness>;
  };

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AssociateTin?: Maybe<Order_By>;
  AssociatedNonIndividual?: Maybe<Order_By>;
  EntityDesignation?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  RegAssociatedEntityDtlId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
export enum Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Select_Column {
  /** column name */
  ActvtyDesc = "ActvtyDesc",
  /** column name */
  AssociateTin = "AssociateTin",
  /** column name */
  AssociatedNonIndividual = "AssociatedNonIndividual",
  /** column name */
  EntityDesignation = "EntityDesignation",
  /** column name */
  FamilyName = "FamilyName",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MainActivity = "MainActivity",
  /** column name */
  OtherName = "OtherName",
  /** column name */
  RegAssociatedEntityDtlId = "RegAssociatedEntityDtlId",
  /** column name */
  Surname = "Surname",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TaxPayerType = "TaxPayerType",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Set_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  AssociatedNonIndividual?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ura_NonIndividualsAssociatedBusiness_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Stddev_Pop_Fields =
  {
    __typename?: "rtcs_db_PH_ura_NonIndividualsAssociatedBusiness_stddev_pop_fields";
    Line?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Stddev_Pop_Order_By =
  {
    Line?: Maybe<Order_By>;
  };

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Stddev_Samp_Fields =
  {
    __typename?: "rtcs_db_PH_ura_NonIndividualsAssociatedBusiness_stddev_samp_fields";
    Line?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Stddev_Samp_Order_By =
  {
    Line?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_NonIndividualsAssociatedBusiness_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Sum_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_NonIndividualsAssociatedBusiness_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_NonIndividualsAssociatedBusiness_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Var_Samp_Order_By =
  {
    Line?: Maybe<Order_By>;
  };

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Variance_Fields = {
  __typename?: "rtcs_db_PH_ura_NonIndividualsAssociatedBusiness_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Variance_Order_By =
  {
    Line?: Maybe<Order_By>;
  };

/** columns and relationships of "rtcs_db.PH_ura_RegAssociates" */
export type Rtcs_Db_Ph_Ura_RegAssociates = {
  __typename?: "rtcs_db_PH_ura_RegAssociates";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidAssociate?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TinNoRegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_RegAssociates" */
export type Rtcs_Db_Ph_Ura_RegAssociates_Aggregate = {
  __typename?: "rtcs_db_PH_ura_RegAssociates_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_RegAssociates>;
};

/** aggregate fields of "rtcs_db.PH_ura_RegAssociates" */
export type Rtcs_Db_Ph_Ura_RegAssociates_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ura_RegAssociates_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ura_RegAssociates" */
export type Rtcs_Db_Ph_Ura_RegAssociates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Ura_RegAssociates_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_ura_RegAssociates" */
export type Rtcs_Db_Ph_Ura_RegAssociates_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_RegAssociates" */
export type Rtcs_Db_Ph_Ura_RegAssociates_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Ura_RegAssociates_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_RegAssociates_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_RegAssociates_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_RegAssociates" */
export type Rtcs_Db_Ph_Ura_RegAssociates_Avg_Order_By = {
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_RegAssociates". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_RegAssociates_Bool_Exp = {
  ActvtyDesc?: Maybe<String_Comparison_Exp>;
  AssociateTin?: Maybe<String_Comparison_Exp>;
  EntityDesignation?: Maybe<String_Comparison_Exp>;
  FamilyName?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FirstName?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MainActivity?: Maybe<String_Comparison_Exp>;
  OtherName?: Maybe<String_Comparison_Exp>;
  RegAssociatedEntityDtlId?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  RnidAssociate?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TaxPayerType?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  TinNoRegAssociatedEntityDtlId?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_RegAssociates" */
export type Rtcs_Db_Ph_Ura_RegAssociates_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_RegAssociates" */
export type Rtcs_Db_Ph_Ura_RegAssociates_Insert_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidAssociate?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TinNoRegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_RegAssociates_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_RegAssociates_max_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidAssociate?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TinNoRegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_RegAssociates" */
export type Rtcs_Db_Ph_Ura_RegAssociates_Max_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AssociateTin?: Maybe<Order_By>;
  EntityDesignation?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  RegAssociatedEntityDtlId?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidAssociate?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TinNoRegAssociatedEntityDtlId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_RegAssociates_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_RegAssociates_min_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidAssociate?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TinNoRegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_RegAssociates" */
export type Rtcs_Db_Ph_Ura_RegAssociates_Min_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AssociateTin?: Maybe<Order_By>;
  EntityDesignation?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  RegAssociatedEntityDtlId?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidAssociate?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TinNoRegAssociatedEntityDtlId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ura_RegAssociates" */
export type Rtcs_Db_Ph_Ura_RegAssociates_Mutation_Response = {
  __typename?: "rtcs_db_PH_ura_RegAssociates_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Ura_RegAssociates>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_RegAssociates" */
export type Rtcs_Db_Ph_Ura_RegAssociates_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Ura_RegAssociates_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_ura_RegAssociates" */
export type Rtcs_Db_Ph_Ura_RegAssociates_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AssociateTin?: Maybe<Order_By>;
  EntityDesignation?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  RegAssociatedEntityDtlId?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidAssociate?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TinNoRegAssociatedEntityDtlId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_RegAssociates" */
export enum Rtcs_Db_Ph_Ura_RegAssociates_Select_Column {
  /** column name */
  ActvtyDesc = "ActvtyDesc",
  /** column name */
  AssociateTin = "AssociateTin",
  /** column name */
  EntityDesignation = "EntityDesignation",
  /** column name */
  FamilyName = "FamilyName",
  /** column name */
  File = "File",
  /** column name */
  FirstName = "FirstName",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MainActivity = "MainActivity",
  /** column name */
  OtherName = "OtherName",
  /** column name */
  RegAssociatedEntityDtlId = "RegAssociatedEntityDtlId",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  RnidAssociate = "RnidAssociate",
  /** column name */
  Surname = "Surname",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TaxPayerType = "TaxPayerType",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  TinNoRegAssociatedEntityDtlId = "TinNoRegAssociatedEntityDtlId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_RegAssociates" */
export type Rtcs_Db_Ph_Ura_RegAssociates_Set_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidAssociate?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TinNoRegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_RegAssociates_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ura_RegAssociates_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ura_RegAssociates" */
export type Rtcs_Db_Ph_Ura_RegAssociates_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_RegAssociates_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_RegAssociates_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_RegAssociates" */
export type Rtcs_Db_Ph_Ura_RegAssociates_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_RegAssociates_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_RegAssociates_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_RegAssociates" */
export type Rtcs_Db_Ph_Ura_RegAssociates_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_RegAssociates_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_RegAssociates_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_RegAssociates" */
export type Rtcs_Db_Ph_Ura_RegAssociates_Sum_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_RegAssociates_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_RegAssociates_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ura_RegAssociates" */
export type Rtcs_Db_Ph_Ura_RegAssociates_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_RegAssociates_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_RegAssociates_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ura_RegAssociates" */
export type Rtcs_Db_Ph_Ura_RegAssociates_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_RegAssociates_Variance_Fields = {
  __typename?: "rtcs_db_PH_ura_RegAssociates_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ura_RegAssociates" */
export type Rtcs_Db_Ph_Ura_RegAssociates_Variance_Order_By = {
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_ura_RegIndividual" */
export type Rtcs_Db_Ph_Ura_RegIndividual = {
  __typename?: "rtcs_db_PH_ura_RegIndividual";
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["String"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_RegIndividual" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Aggregate = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_RegIndividual>;
};

/** aggregate fields of "rtcs_db.PH_ura_RegIndividual" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ura_RegIndividual" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_ura_RegIndividual" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_RegIndividual" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Ura_RegIndividual_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_RegIndividual_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_avg_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_RegIndividual" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Avg_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_RegIndividual". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_RegIndividual_Bool_Exp = {
  AppliedForTin?: Maybe<String_Comparison_Exp>;
  AuthctcdEmailId?: Maybe<String_Comparison_Exp>;
  AuthctcdFamilyName?: Maybe<String_Comparison_Exp>;
  AuthctcdFirstName?: Maybe<String_Comparison_Exp>;
  AuthctcdLandlineNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdMobileNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdOtherName?: Maybe<String_Comparison_Exp>;
  AuthctcdPrsnDesgn?: Maybe<String_Comparison_Exp>;
  AuthctcdSurname?: Maybe<String_Comparison_Exp>;
  AuthctcdTin?: Maybe<String_Comparison_Exp>;
  BirthCity?: Maybe<Bigint_Comparison_Exp>;
  BirthCountry?: Maybe<String_Comparison_Exp>;
  BirthDt?: Maybe<String_Comparison_Exp>;
  BirthState?: Maybe<String_Comparison_Exp>;
  BsnsCertNumber?: Maybe<String_Comparison_Exp>;
  BsnsName?: Maybe<String_Comparison_Exp>;
  CitizenCountry?: Maybe<String_Comparison_Exp>;
  EmailId?: Maybe<String_Comparison_Exp>;
  ExistTinNo?: Maybe<String_Comparison_Exp>;
  FamilyName?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FirstName?: Maybe<String_Comparison_Exp>;
  Gender?: Maybe<String_Comparison_Exp>;
  HomeAddrBuildingname?: Maybe<String_Comparison_Exp>;
  HomeAddrDistrictName?: Maybe<String_Comparison_Exp>;
  HomeAddrLocalCouncil?: Maybe<String_Comparison_Exp>;
  HomeAddrParishName?: Maybe<String_Comparison_Exp>;
  HomeAddrPlotno?: Maybe<String_Comparison_Exp>;
  HomeAddrStrretAddr?: Maybe<String_Comparison_Exp>;
  HomeAddrSubCountyName?: Maybe<String_Comparison_Exp>;
  HomeAddrTownName?: Maybe<String_Comparison_Exp>;
  HomeAddrTradeCentre?: Maybe<String_Comparison_Exp>;
  HomeAddrVillageName?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcEmp?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  LandlineNumber?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  MobileNumber?: Maybe<String_Comparison_Exp>;
  NationalId?: Maybe<String_Comparison_Exp>;
  NssfId?: Maybe<String_Comparison_Exp>;
  OtherName?: Maybe<String_Comparison_Exp>;
  PostaddrDistictName?: Maybe<String_Comparison_Exp>;
  PostaddrId?: Maybe<String_Comparison_Exp>;
  RefEmailId?: Maybe<String_Comparison_Exp>;
  RefFamilyName?: Maybe<String_Comparison_Exp>;
  RefFirstName?: Maybe<String_Comparison_Exp>;
  RefLandlineNumber?: Maybe<String_Comparison_Exp>;
  RefMobileNumber?: Maybe<String_Comparison_Exp>;
  RefOtherName?: Maybe<String_Comparison_Exp>;
  RefPrsnDesgn?: Maybe<String_Comparison_Exp>;
  RefSurname?: Maybe<String_Comparison_Exp>;
  RefTin?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  SurName?: Maybe<String_Comparison_Exp>;
  TaxPayerId?: Maybe<String_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TaxPayerType?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  Uuid?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_RegIndividual" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Inc_Input = {
  BirthCity?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_RegIndividual" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Insert_Input = {
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["String"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_RegIndividual_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_max_fields";
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["String"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_RegIndividual" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Max_Order_By = {
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SurName?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_RegIndividual_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_min_fields";
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["String"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_RegIndividual" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Min_Order_By = {
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SurName?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ura_RegIndividual" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Mutation_Response = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Ura_RegIndividual>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_RegIndividual" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Ura_RegIndividual_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_ura_RegIndividual" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Order_By = {
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SurName?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_RegIndividual" */
export enum Rtcs_Db_Ph_Ura_RegIndividual_Select_Column {
  /** column name */
  AppliedForTin = "AppliedForTin",
  /** column name */
  AuthctcdEmailId = "AuthctcdEmailId",
  /** column name */
  AuthctcdFamilyName = "AuthctcdFamilyName",
  /** column name */
  AuthctcdFirstName = "AuthctcdFirstName",
  /** column name */
  AuthctcdLandlineNumber = "AuthctcdLandlineNumber",
  /** column name */
  AuthctcdMobileNumber = "AuthctcdMobileNumber",
  /** column name */
  AuthctcdOtherName = "AuthctcdOtherName",
  /** column name */
  AuthctcdPrsnDesgn = "AuthctcdPrsnDesgn",
  /** column name */
  AuthctcdSurname = "AuthctcdSurname",
  /** column name */
  AuthctcdTin = "AuthctcdTin",
  /** column name */
  BirthCity = "BirthCity",
  /** column name */
  BirthCountry = "BirthCountry",
  /** column name */
  BirthDt = "BirthDt",
  /** column name */
  BirthState = "BirthState",
  /** column name */
  BsnsCertNumber = "BsnsCertNumber",
  /** column name */
  BsnsName = "BsnsName",
  /** column name */
  CitizenCountry = "CitizenCountry",
  /** column name */
  EmailId = "EmailId",
  /** column name */
  ExistTinNo = "ExistTinNo",
  /** column name */
  FamilyName = "FamilyName",
  /** column name */
  File = "File",
  /** column name */
  FirstName = "FirstName",
  /** column name */
  Gender = "Gender",
  /** column name */
  HomeAddrBuildingname = "HomeAddrBuildingname",
  /** column name */
  HomeAddrDistrictName = "HomeAddrDistrictName",
  /** column name */
  HomeAddrLocalCouncil = "HomeAddrLocalCouncil",
  /** column name */
  HomeAddrParishName = "HomeAddrParishName",
  /** column name */
  HomeAddrPlotno = "HomeAddrPlotno",
  /** column name */
  HomeAddrStrretAddr = "HomeAddrStrretAddr",
  /** column name */
  HomeAddrSubCountyName = "HomeAddrSubCountyName",
  /** column name */
  HomeAddrTownName = "HomeAddrTownName",
  /** column name */
  HomeAddrTradeCentre = "HomeAddrTradeCentre",
  /** column name */
  HomeAddrVillageName = "HomeAddrVillageName",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcEmp = "IsIncomeSrcEmp",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  LandlineNumber = "LandlineNumber",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  NationalId = "NationalId",
  /** column name */
  NssfId = "NssfId",
  /** column name */
  OtherName = "OtherName",
  /** column name */
  PostaddrDistictName = "PostaddrDistictName",
  /** column name */
  PostaddrId = "PostaddrId",
  /** column name */
  RefEmailId = "RefEmailId",
  /** column name */
  RefFamilyName = "RefFamilyName",
  /** column name */
  RefFirstName = "RefFirstName",
  /** column name */
  RefLandlineNumber = "RefLandlineNumber",
  /** column name */
  RefMobileNumber = "RefMobileNumber",
  /** column name */
  RefOtherName = "RefOtherName",
  /** column name */
  RefPrsnDesgn = "RefPrsnDesgn",
  /** column name */
  RefSurname = "RefSurname",
  /** column name */
  RefTin = "RefTin",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  SurName = "SurName",
  /** column name */
  TaxPayerId = "TaxPayerId",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TaxPayerType = "TaxPayerType",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  Uuid = "Uuid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_RegIndividual" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Set_Input = {
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["String"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.PH_ura_RegIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_sid";
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["float8"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["date"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_RegIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Aggregate = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_sid_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_RegIndividual_Sid>;
};

/** aggregate fields of "rtcs_db.PH_ura_RegIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_sid_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ura_RegIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_ura_RegIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_RegIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_sid_avg_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_RegIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Avg_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_RegIndividual_sid". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Bool_Exp = {
  AppliedForTin?: Maybe<String_Comparison_Exp>;
  AuthctcdEmailId?: Maybe<String_Comparison_Exp>;
  AuthctcdFamilyName?: Maybe<String_Comparison_Exp>;
  AuthctcdFirstName?: Maybe<String_Comparison_Exp>;
  AuthctcdLandlineNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdMobileNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdOtherName?: Maybe<String_Comparison_Exp>;
  AuthctcdPrsnDesgn?: Maybe<String_Comparison_Exp>;
  AuthctcdSurname?: Maybe<String_Comparison_Exp>;
  AuthctcdTin?: Maybe<String_Comparison_Exp>;
  BirthCity?: Maybe<Float8_Comparison_Exp>;
  BirthCountry?: Maybe<String_Comparison_Exp>;
  BirthDt?: Maybe<Date_Comparison_Exp>;
  BirthState?: Maybe<String_Comparison_Exp>;
  BsnsName?: Maybe<String_Comparison_Exp>;
  CitizenCountry?: Maybe<String_Comparison_Exp>;
  EmailId?: Maybe<String_Comparison_Exp>;
  ExistTinNo?: Maybe<String_Comparison_Exp>;
  FamilyName?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FirstName?: Maybe<String_Comparison_Exp>;
  Gender?: Maybe<String_Comparison_Exp>;
  HomeAddrBuildingname?: Maybe<String_Comparison_Exp>;
  HomeAddrDistrictName?: Maybe<String_Comparison_Exp>;
  HomeAddrLocalCouncil?: Maybe<String_Comparison_Exp>;
  HomeAddrParishName?: Maybe<String_Comparison_Exp>;
  HomeAddrPlotno?: Maybe<String_Comparison_Exp>;
  HomeAddrStrretAddr?: Maybe<String_Comparison_Exp>;
  HomeAddrSubCountyName?: Maybe<String_Comparison_Exp>;
  HomeAddrTownName?: Maybe<String_Comparison_Exp>;
  HomeAddrTradeCentre?: Maybe<String_Comparison_Exp>;
  HomeAddrVillageName?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcEmp?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  LandlineNumber?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  MobileNumber?: Maybe<String_Comparison_Exp>;
  NationalId?: Maybe<String_Comparison_Exp>;
  NssfId?: Maybe<String_Comparison_Exp>;
  OtherName?: Maybe<String_Comparison_Exp>;
  PostaddrDistictName?: Maybe<String_Comparison_Exp>;
  PostaddrId?: Maybe<String_Comparison_Exp>;
  RefEmailId?: Maybe<String_Comparison_Exp>;
  RefFamilyName?: Maybe<String_Comparison_Exp>;
  RefFirstName?: Maybe<String_Comparison_Exp>;
  RefLandlineNumber?: Maybe<String_Comparison_Exp>;
  RefMobileNumber?: Maybe<String_Comparison_Exp>;
  RefOtherName?: Maybe<String_Comparison_Exp>;
  RefPrsnDesgn?: Maybe<String_Comparison_Exp>;
  RefSurname?: Maybe<String_Comparison_Exp>;
  RefTin?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Sid?: Maybe<String_Comparison_Exp>;
  SurName?: Maybe<String_Comparison_Exp>;
  TaxPayerId?: Maybe<String_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TaxPayerType?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  VisibleLevel?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_RegIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Inc_Input = {
  BirthCity?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_RegIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Insert_Input = {
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["float8"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["date"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_sid_max_fields";
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["float8"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["date"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_RegIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Max_Order_By = {
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Sid?: Maybe<Order_By>;
  SurName?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_sid_min_fields";
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["float8"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["date"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_RegIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Min_Order_By = {
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Sid?: Maybe<Order_By>;
  SurName?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ura_RegIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Mutation_Response = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_sid_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Ura_RegIndividual_Sid>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_RegIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Ura_RegIndividual_Sid_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_ura_RegIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Order_By = {
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Sid?: Maybe<Order_By>;
  SurName?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_RegIndividual_sid" */
export enum Rtcs_Db_Ph_Ura_RegIndividual_Sid_Select_Column {
  /** column name */
  AppliedForTin = "AppliedForTin",
  /** column name */
  AuthctcdEmailId = "AuthctcdEmailId",
  /** column name */
  AuthctcdFamilyName = "AuthctcdFamilyName",
  /** column name */
  AuthctcdFirstName = "AuthctcdFirstName",
  /** column name */
  AuthctcdLandlineNumber = "AuthctcdLandlineNumber",
  /** column name */
  AuthctcdMobileNumber = "AuthctcdMobileNumber",
  /** column name */
  AuthctcdOtherName = "AuthctcdOtherName",
  /** column name */
  AuthctcdPrsnDesgn = "AuthctcdPrsnDesgn",
  /** column name */
  AuthctcdSurname = "AuthctcdSurname",
  /** column name */
  AuthctcdTin = "AuthctcdTin",
  /** column name */
  BirthCity = "BirthCity",
  /** column name */
  BirthCountry = "BirthCountry",
  /** column name */
  BirthDt = "BirthDt",
  /** column name */
  BirthState = "BirthState",
  /** column name */
  BsnsName = "BsnsName",
  /** column name */
  CitizenCountry = "CitizenCountry",
  /** column name */
  EmailId = "EmailId",
  /** column name */
  ExistTinNo = "ExistTinNo",
  /** column name */
  FamilyName = "FamilyName",
  /** column name */
  File = "File",
  /** column name */
  FirstName = "FirstName",
  /** column name */
  Gender = "Gender",
  /** column name */
  HomeAddrBuildingname = "HomeAddrBuildingname",
  /** column name */
  HomeAddrDistrictName = "HomeAddrDistrictName",
  /** column name */
  HomeAddrLocalCouncil = "HomeAddrLocalCouncil",
  /** column name */
  HomeAddrParishName = "HomeAddrParishName",
  /** column name */
  HomeAddrPlotno = "HomeAddrPlotno",
  /** column name */
  HomeAddrStrretAddr = "HomeAddrStrretAddr",
  /** column name */
  HomeAddrSubCountyName = "HomeAddrSubCountyName",
  /** column name */
  HomeAddrTownName = "HomeAddrTownName",
  /** column name */
  HomeAddrTradeCentre = "HomeAddrTradeCentre",
  /** column name */
  HomeAddrVillageName = "HomeAddrVillageName",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcEmp = "IsIncomeSrcEmp",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  LandlineNumber = "LandlineNumber",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  NationalId = "NationalId",
  /** column name */
  NssfId = "NssfId",
  /** column name */
  OtherName = "OtherName",
  /** column name */
  PostaddrDistictName = "PostaddrDistictName",
  /** column name */
  PostaddrId = "PostaddrId",
  /** column name */
  RefEmailId = "RefEmailId",
  /** column name */
  RefFamilyName = "RefFamilyName",
  /** column name */
  RefFirstName = "RefFirstName",
  /** column name */
  RefLandlineNumber = "RefLandlineNumber",
  /** column name */
  RefMobileNumber = "RefMobileNumber",
  /** column name */
  RefOtherName = "RefOtherName",
  /** column name */
  RefPrsnDesgn = "RefPrsnDesgn",
  /** column name */
  RefSurname = "RefSurname",
  /** column name */
  RefTin = "RefTin",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Sid = "Sid",
  /** column name */
  SurName = "SurName",
  /** column name */
  TaxPayerId = "TaxPayerId",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TaxPayerType = "TaxPayerType",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  VisibleLevel = "VisibleLevel",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_RegIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Set_Input = {
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["float8"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["date"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_sid_stddev_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ura_RegIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Stddev_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_sid_stddev_pop_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_RegIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Stddev_Pop_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_sid_stddev_samp_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_RegIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Stddev_Samp_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_sid_sum_fields";
  BirthCity?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_RegIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Sum_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_sid_var_pop_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ura_RegIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Var_Pop_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_sid_var_samp_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ura_RegIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Var_Samp_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Variance_Fields = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_sid_variance_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ura_RegIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sid_Variance_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_RegIndividual_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_stddev_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ura_RegIndividual" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Stddev_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_RegIndividual_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_stddev_pop_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_RegIndividual" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Stddev_Pop_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_RegIndividual_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_stddev_samp_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_RegIndividual" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Stddev_Samp_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_sum_fields";
  BirthCity?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_RegIndividual" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Sum_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_RegIndividual_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_var_pop_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ura_RegIndividual" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Var_Pop_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_RegIndividual_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_var_samp_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ura_RegIndividual" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Var_Samp_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_RegIndividual_Variance_Fields = {
  __typename?: "rtcs_db_PH_ura_RegIndividual_variance_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ura_RegIndividual" */
export type Rtcs_Db_Ph_Ura_RegIndividual_Variance_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_ura_RegNonIndividual" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["timestamptz"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_RegNonIndividual" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Aggregate = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_RegNonIndividual>;
};

/** aggregate fields of "rtcs_db.PH_ura_RegNonIndividual" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ura_RegNonIndividual" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_ura_RegNonIndividual" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_RegNonIndividual" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_avg_fields";
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_RegNonIndividual" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Avg_Order_By = {
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_RegNonIndividual". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Bool_Exp = {
  ActvtyDesc?: Maybe<String_Comparison_Exp>;
  AppliedForTin?: Maybe<String_Comparison_Exp>;
  AuthctcdEmailId?: Maybe<String_Comparison_Exp>;
  AuthctcdFamilyName?: Maybe<String_Comparison_Exp>;
  AuthctcdFirstName?: Maybe<String_Comparison_Exp>;
  AuthctcdLandlineNumber?: Maybe<Float8_Comparison_Exp>;
  AuthctcdMobileNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdOtherName?: Maybe<String_Comparison_Exp>;
  AuthctcdPrsnDesgn?: Maybe<String_Comparison_Exp>;
  AuthctcdSurname?: Maybe<String_Comparison_Exp>;
  AuthctcdTin?: Maybe<String_Comparison_Exp>;
  BsnsCertNumber?: Maybe<String_Comparison_Exp>;
  BsnsName?: Maybe<String_Comparison_Exp>;
  BsnsaddrBuildname?: Maybe<String_Comparison_Exp>;
  BsnsaddrDistrictName?: Maybe<String_Comparison_Exp>;
  BsnsaddrLocalCouncil?: Maybe<String_Comparison_Exp>;
  BsnsaddrParishName?: Maybe<String_Comparison_Exp>;
  BsnsaddrPlotNo?: Maybe<String_Comparison_Exp>;
  BsnsaddrStreetAddr?: Maybe<String_Comparison_Exp>;
  BsnsaddrSubCountyName?: Maybe<String_Comparison_Exp>;
  BsnsaddrTownName?: Maybe<String_Comparison_Exp>;
  BsnsaddrTradeCentre?: Maybe<String_Comparison_Exp>;
  BsnsaddrVillageName?: Maybe<String_Comparison_Exp>;
  BusinessStartDt?: Maybe<Timestamptz_Comparison_Exp>;
  EmailId?: Maybe<String_Comparison_Exp>;
  ExistTinNo?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  HoldingCompName?: Maybe<String_Comparison_Exp>;
  HoldingCompTin?: Maybe<String_Comparison_Exp>;
  IsHldCmpInUganda?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  IsSubsidaryComp?: Maybe<String_Comparison_Exp>;
  LandlineNumber?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MainActivity?: Maybe<String_Comparison_Exp>;
  MobileNumber?: Maybe<String_Comparison_Exp>;
  OrgSubType?: Maybe<String_Comparison_Exp>;
  OrgSubTypeDesc?: Maybe<String_Comparison_Exp>;
  OrgType?: Maybe<String_Comparison_Exp>;
  PostaddrDistictName?: Maybe<String_Comparison_Exp>;
  PostaddrId?: Maybe<String_Comparison_Exp>;
  RefEmailId?: Maybe<String_Comparison_Exp>;
  RefFamilyName?: Maybe<String_Comparison_Exp>;
  RefFirstName?: Maybe<String_Comparison_Exp>;
  RefLandlineNumber?: Maybe<String_Comparison_Exp>;
  RefMiddleName?: Maybe<String_Comparison_Exp>;
  RefMobileNumber?: Maybe<String_Comparison_Exp>;
  RefOtherName?: Maybe<String_Comparison_Exp>;
  RefPrsnDesgn?: Maybe<String_Comparison_Exp>;
  RefSurname?: Maybe<String_Comparison_Exp>;
  RefTin?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TaxPayerType?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  TradingName?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_RegNonIndividual" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Inc_Input = {
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  LandlineNumber?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_RegNonIndividual" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Insert_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["timestamptz"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_max_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["timestamptz"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_RegNonIndividual" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Max_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BsnsaddrBuildname?: Maybe<Order_By>;
  BsnsaddrDistrictName?: Maybe<Order_By>;
  BsnsaddrLocalCouncil?: Maybe<Order_By>;
  BsnsaddrParishName?: Maybe<Order_By>;
  BsnsaddrPlotNo?: Maybe<Order_By>;
  BsnsaddrStreetAddr?: Maybe<Order_By>;
  BsnsaddrSubCountyName?: Maybe<Order_By>;
  BsnsaddrTownName?: Maybe<Order_By>;
  BsnsaddrTradeCentre?: Maybe<Order_By>;
  BsnsaddrVillageName?: Maybe<Order_By>;
  BusinessStartDt?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  HoldingCompName?: Maybe<Order_By>;
  HoldingCompTin?: Maybe<Order_By>;
  IsHldCmpInUganda?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  IsSubsidaryComp?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  OrgSubType?: Maybe<Order_By>;
  OrgSubTypeDesc?: Maybe<Order_By>;
  OrgType?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMiddleName?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TradingName?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_min_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["timestamptz"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_RegNonIndividual" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Min_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BsnsaddrBuildname?: Maybe<Order_By>;
  BsnsaddrDistrictName?: Maybe<Order_By>;
  BsnsaddrLocalCouncil?: Maybe<Order_By>;
  BsnsaddrParishName?: Maybe<Order_By>;
  BsnsaddrPlotNo?: Maybe<Order_By>;
  BsnsaddrStreetAddr?: Maybe<Order_By>;
  BsnsaddrSubCountyName?: Maybe<Order_By>;
  BsnsaddrTownName?: Maybe<Order_By>;
  BsnsaddrTradeCentre?: Maybe<Order_By>;
  BsnsaddrVillageName?: Maybe<Order_By>;
  BusinessStartDt?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  HoldingCompName?: Maybe<Order_By>;
  HoldingCompTin?: Maybe<Order_By>;
  IsHldCmpInUganda?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  IsSubsidaryComp?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  OrgSubType?: Maybe<Order_By>;
  OrgSubTypeDesc?: Maybe<Order_By>;
  OrgType?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMiddleName?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TradingName?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ura_RegNonIndividual" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Mutation_Response = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Ura_RegNonIndividual>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_RegNonIndividual" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Ura_RegNonIndividual_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_ura_RegNonIndividual" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BsnsaddrBuildname?: Maybe<Order_By>;
  BsnsaddrDistrictName?: Maybe<Order_By>;
  BsnsaddrLocalCouncil?: Maybe<Order_By>;
  BsnsaddrParishName?: Maybe<Order_By>;
  BsnsaddrPlotNo?: Maybe<Order_By>;
  BsnsaddrStreetAddr?: Maybe<Order_By>;
  BsnsaddrSubCountyName?: Maybe<Order_By>;
  BsnsaddrTownName?: Maybe<Order_By>;
  BsnsaddrTradeCentre?: Maybe<Order_By>;
  BsnsaddrVillageName?: Maybe<Order_By>;
  BusinessStartDt?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  HoldingCompName?: Maybe<Order_By>;
  HoldingCompTin?: Maybe<Order_By>;
  IsHldCmpInUganda?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  IsSubsidaryComp?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  OrgSubType?: Maybe<Order_By>;
  OrgSubTypeDesc?: Maybe<Order_By>;
  OrgType?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMiddleName?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TradingName?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_RegNonIndividual" */
export enum Rtcs_Db_Ph_Ura_RegNonIndividual_Select_Column {
  /** column name */
  ActvtyDesc = "ActvtyDesc",
  /** column name */
  AppliedForTin = "AppliedForTin",
  /** column name */
  AuthctcdEmailId = "AuthctcdEmailId",
  /** column name */
  AuthctcdFamilyName = "AuthctcdFamilyName",
  /** column name */
  AuthctcdFirstName = "AuthctcdFirstName",
  /** column name */
  AuthctcdLandlineNumber = "AuthctcdLandlineNumber",
  /** column name */
  AuthctcdMobileNumber = "AuthctcdMobileNumber",
  /** column name */
  AuthctcdOtherName = "AuthctcdOtherName",
  /** column name */
  AuthctcdPrsnDesgn = "AuthctcdPrsnDesgn",
  /** column name */
  AuthctcdSurname = "AuthctcdSurname",
  /** column name */
  AuthctcdTin = "AuthctcdTin",
  /** column name */
  BsnsCertNumber = "BsnsCertNumber",
  /** column name */
  BsnsName = "BsnsName",
  /** column name */
  BsnsaddrBuildname = "BsnsaddrBuildname",
  /** column name */
  BsnsaddrDistrictName = "BsnsaddrDistrictName",
  /** column name */
  BsnsaddrLocalCouncil = "BsnsaddrLocalCouncil",
  /** column name */
  BsnsaddrParishName = "BsnsaddrParishName",
  /** column name */
  BsnsaddrPlotNo = "BsnsaddrPlotNo",
  /** column name */
  BsnsaddrStreetAddr = "BsnsaddrStreetAddr",
  /** column name */
  BsnsaddrSubCountyName = "BsnsaddrSubCountyName",
  /** column name */
  BsnsaddrTownName = "BsnsaddrTownName",
  /** column name */
  BsnsaddrTradeCentre = "BsnsaddrTradeCentre",
  /** column name */
  BsnsaddrVillageName = "BsnsaddrVillageName",
  /** column name */
  BusinessStartDt = "BusinessStartDt",
  /** column name */
  EmailId = "EmailId",
  /** column name */
  ExistTinNo = "ExistTinNo",
  /** column name */
  File = "File",
  /** column name */
  HoldingCompName = "HoldingCompName",
  /** column name */
  HoldingCompTin = "HoldingCompTin",
  /** column name */
  IsHldCmpInUganda = "IsHldCmpInUganda",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  IsSubsidaryComp = "IsSubsidaryComp",
  /** column name */
  LandlineNumber = "LandlineNumber",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MainActivity = "MainActivity",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  OrgSubType = "OrgSubType",
  /** column name */
  OrgSubTypeDesc = "OrgSubTypeDesc",
  /** column name */
  OrgType = "OrgType",
  /** column name */
  PostaddrDistictName = "PostaddrDistictName",
  /** column name */
  PostaddrId = "PostaddrId",
  /** column name */
  RefEmailId = "RefEmailId",
  /** column name */
  RefFamilyName = "RefFamilyName",
  /** column name */
  RefFirstName = "RefFirstName",
  /** column name */
  RefLandlineNumber = "RefLandlineNumber",
  /** column name */
  RefMiddleName = "RefMiddleName",
  /** column name */
  RefMobileNumber = "RefMobileNumber",
  /** column name */
  RefOtherName = "RefOtherName",
  /** column name */
  RefPrsnDesgn = "RefPrsnDesgn",
  /** column name */
  RefSurname = "RefSurname",
  /** column name */
  RefTin = "RefTin",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TaxPayerType = "TaxPayerType",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  TradingName = "TradingName",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_RegNonIndividual" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Set_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["timestamptz"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.PH_ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_sid";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["float8"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Aggregate = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_sid_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid>;
};

/** aggregate fields of "rtcs_db.PH_ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_sid_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_sid_avg_fields";
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MobileNumber?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Avg_Order_By = {
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_RegNonIndividual_sid". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Bool_Exp = {
  ActvtyDesc?: Maybe<String_Comparison_Exp>;
  AppliedForTin?: Maybe<String_Comparison_Exp>;
  AuthctcdEmailId?: Maybe<String_Comparison_Exp>;
  AuthctcdFamilyName?: Maybe<String_Comparison_Exp>;
  AuthctcdFirstName?: Maybe<String_Comparison_Exp>;
  AuthctcdLandlineNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdMobileNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdOtherName?: Maybe<String_Comparison_Exp>;
  AuthctcdPrsnDesgn?: Maybe<String_Comparison_Exp>;
  AuthctcdSurname?: Maybe<String_Comparison_Exp>;
  BsnsCertNumber?: Maybe<String_Comparison_Exp>;
  BsnsName?: Maybe<String_Comparison_Exp>;
  BsnsaddrBuildname?: Maybe<String_Comparison_Exp>;
  BsnsaddrDistrictName?: Maybe<String_Comparison_Exp>;
  BsnsaddrLocalCouncil?: Maybe<String_Comparison_Exp>;
  BsnsaddrParishName?: Maybe<String_Comparison_Exp>;
  BsnsaddrPlotNo?: Maybe<String_Comparison_Exp>;
  BsnsaddrStreetAddr?: Maybe<String_Comparison_Exp>;
  BsnsaddrSubCountyName?: Maybe<String_Comparison_Exp>;
  BsnsaddrTownName?: Maybe<String_Comparison_Exp>;
  BsnsaddrTradeCentre?: Maybe<String_Comparison_Exp>;
  BsnsaddrVillageName?: Maybe<String_Comparison_Exp>;
  BusinessStartDt?: Maybe<String_Comparison_Exp>;
  EmailId?: Maybe<String_Comparison_Exp>;
  ExistTinNo?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  HoldingCompName?: Maybe<String_Comparison_Exp>;
  HoldingCompTin?: Maybe<String_Comparison_Exp>;
  IsHldCmpInUganda?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  IsSubsidaryComp?: Maybe<String_Comparison_Exp>;
  LandlineNumber?: Maybe<Float8_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MainActivity?: Maybe<String_Comparison_Exp>;
  MobileNumber?: Maybe<Float8_Comparison_Exp>;
  OrgSubType?: Maybe<String_Comparison_Exp>;
  OrgSubTypeDesc?: Maybe<String_Comparison_Exp>;
  OrgType?: Maybe<String_Comparison_Exp>;
  PostaddrDistictName?: Maybe<String_Comparison_Exp>;
  PostaddrId?: Maybe<String_Comparison_Exp>;
  RefEmailId?: Maybe<String_Comparison_Exp>;
  RefFamilyName?: Maybe<String_Comparison_Exp>;
  RefFirstName?: Maybe<String_Comparison_Exp>;
  RefLandlineNumber?: Maybe<String_Comparison_Exp>;
  RefMiddleName?: Maybe<String_Comparison_Exp>;
  RefMobileNumber?: Maybe<String_Comparison_Exp>;
  RefOtherName?: Maybe<String_Comparison_Exp>;
  RefPrsnDesgn?: Maybe<String_Comparison_Exp>;
  RefSurname?: Maybe<String_Comparison_Exp>;
  RefTin?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Sid?: Maybe<String_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TaxPayerType?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  TradingName?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Inc_Input = {
  LandlineNumber?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Insert_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["float8"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_sid_max_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["float8"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Max_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BsnsaddrBuildname?: Maybe<Order_By>;
  BsnsaddrDistrictName?: Maybe<Order_By>;
  BsnsaddrLocalCouncil?: Maybe<Order_By>;
  BsnsaddrParishName?: Maybe<Order_By>;
  BsnsaddrPlotNo?: Maybe<Order_By>;
  BsnsaddrStreetAddr?: Maybe<Order_By>;
  BsnsaddrSubCountyName?: Maybe<Order_By>;
  BsnsaddrTownName?: Maybe<Order_By>;
  BsnsaddrTradeCentre?: Maybe<Order_By>;
  BsnsaddrVillageName?: Maybe<Order_By>;
  BusinessStartDt?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  HoldingCompName?: Maybe<Order_By>;
  HoldingCompTin?: Maybe<Order_By>;
  IsHldCmpInUganda?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  IsSubsidaryComp?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  OrgSubType?: Maybe<Order_By>;
  OrgSubTypeDesc?: Maybe<Order_By>;
  OrgType?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMiddleName?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Sid?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TradingName?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_sid_min_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["float8"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Min_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BsnsaddrBuildname?: Maybe<Order_By>;
  BsnsaddrDistrictName?: Maybe<Order_By>;
  BsnsaddrLocalCouncil?: Maybe<Order_By>;
  BsnsaddrParishName?: Maybe<Order_By>;
  BsnsaddrPlotNo?: Maybe<Order_By>;
  BsnsaddrStreetAddr?: Maybe<Order_By>;
  BsnsaddrSubCountyName?: Maybe<Order_By>;
  BsnsaddrTownName?: Maybe<Order_By>;
  BsnsaddrTradeCentre?: Maybe<Order_By>;
  BsnsaddrVillageName?: Maybe<Order_By>;
  BusinessStartDt?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  HoldingCompName?: Maybe<Order_By>;
  HoldingCompTin?: Maybe<Order_By>;
  IsHldCmpInUganda?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  IsSubsidaryComp?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  OrgSubType?: Maybe<Order_By>;
  OrgSubTypeDesc?: Maybe<Order_By>;
  OrgType?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMiddleName?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Sid?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TradingName?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Mutation_Response = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_sid_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BsnsaddrBuildname?: Maybe<Order_By>;
  BsnsaddrDistrictName?: Maybe<Order_By>;
  BsnsaddrLocalCouncil?: Maybe<Order_By>;
  BsnsaddrParishName?: Maybe<Order_By>;
  BsnsaddrPlotNo?: Maybe<Order_By>;
  BsnsaddrStreetAddr?: Maybe<Order_By>;
  BsnsaddrSubCountyName?: Maybe<Order_By>;
  BsnsaddrTownName?: Maybe<Order_By>;
  BsnsaddrTradeCentre?: Maybe<Order_By>;
  BsnsaddrVillageName?: Maybe<Order_By>;
  BusinessStartDt?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  HoldingCompName?: Maybe<Order_By>;
  HoldingCompTin?: Maybe<Order_By>;
  IsHldCmpInUganda?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  IsSubsidaryComp?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  OrgSubType?: Maybe<Order_By>;
  OrgSubTypeDesc?: Maybe<Order_By>;
  OrgType?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMiddleName?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Sid?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TradingName?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_RegNonIndividual_sid" */
export enum Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Select_Column {
  /** column name */
  ActvtyDesc = "ActvtyDesc",
  /** column name */
  AppliedForTin = "AppliedForTin",
  /** column name */
  AuthctcdEmailId = "AuthctcdEmailId",
  /** column name */
  AuthctcdFamilyName = "AuthctcdFamilyName",
  /** column name */
  AuthctcdFirstName = "AuthctcdFirstName",
  /** column name */
  AuthctcdLandlineNumber = "AuthctcdLandlineNumber",
  /** column name */
  AuthctcdMobileNumber = "AuthctcdMobileNumber",
  /** column name */
  AuthctcdOtherName = "AuthctcdOtherName",
  /** column name */
  AuthctcdPrsnDesgn = "AuthctcdPrsnDesgn",
  /** column name */
  AuthctcdSurname = "AuthctcdSurname",
  /** column name */
  BsnsCertNumber = "BsnsCertNumber",
  /** column name */
  BsnsName = "BsnsName",
  /** column name */
  BsnsaddrBuildname = "BsnsaddrBuildname",
  /** column name */
  BsnsaddrDistrictName = "BsnsaddrDistrictName",
  /** column name */
  BsnsaddrLocalCouncil = "BsnsaddrLocalCouncil",
  /** column name */
  BsnsaddrParishName = "BsnsaddrParishName",
  /** column name */
  BsnsaddrPlotNo = "BsnsaddrPlotNo",
  /** column name */
  BsnsaddrStreetAddr = "BsnsaddrStreetAddr",
  /** column name */
  BsnsaddrSubCountyName = "BsnsaddrSubCountyName",
  /** column name */
  BsnsaddrTownName = "BsnsaddrTownName",
  /** column name */
  BsnsaddrTradeCentre = "BsnsaddrTradeCentre",
  /** column name */
  BsnsaddrVillageName = "BsnsaddrVillageName",
  /** column name */
  BusinessStartDt = "BusinessStartDt",
  /** column name */
  EmailId = "EmailId",
  /** column name */
  ExistTinNo = "ExistTinNo",
  /** column name */
  File = "File",
  /** column name */
  HoldingCompName = "HoldingCompName",
  /** column name */
  HoldingCompTin = "HoldingCompTin",
  /** column name */
  IsHldCmpInUganda = "IsHldCmpInUganda",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  IsSubsidaryComp = "IsSubsidaryComp",
  /** column name */
  LandlineNumber = "LandlineNumber",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MainActivity = "MainActivity",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  OrgSubType = "OrgSubType",
  /** column name */
  OrgSubTypeDesc = "OrgSubTypeDesc",
  /** column name */
  OrgType = "OrgType",
  /** column name */
  PostaddrDistictName = "PostaddrDistictName",
  /** column name */
  PostaddrId = "PostaddrId",
  /** column name */
  RefEmailId = "RefEmailId",
  /** column name */
  RefFamilyName = "RefFamilyName",
  /** column name */
  RefFirstName = "RefFirstName",
  /** column name */
  RefLandlineNumber = "RefLandlineNumber",
  /** column name */
  RefMiddleName = "RefMiddleName",
  /** column name */
  RefMobileNumber = "RefMobileNumber",
  /** column name */
  RefOtherName = "RefOtherName",
  /** column name */
  RefPrsnDesgn = "RefPrsnDesgn",
  /** column name */
  RefSurname = "RefSurname",
  /** column name */
  RefTin = "RefTin",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Sid = "Sid",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TaxPayerType = "TaxPayerType",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  TradingName = "TradingName",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Set_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["float8"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_sid_stddev_fields";
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MobileNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Stddev_Order_By = {
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_sid_stddev_pop_fields";
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MobileNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Stddev_Pop_Order_By = {
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_sid_stddev_samp_fields";
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MobileNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Stddev_Samp_Order_By = {
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_sid_sum_fields";
  LandlineNumber?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Sum_Order_By = {
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_sid_var_pop_fields";
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MobileNumber?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Var_Pop_Order_By = {
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_sid_var_samp_fields";
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MobileNumber?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Var_Samp_Order_By = {
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Variance_Fields = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_sid_variance_fields";
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MobileNumber?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Variance_Order_By = {
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_stddev_fields";
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ura_RegNonIndividual" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Stddev_Order_By = {
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_stddev_pop_fields";
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_RegNonIndividual" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Stddev_Pop_Order_By = {
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_stddev_samp_fields";
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_RegNonIndividual" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Stddev_Samp_Order_By = {
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_sum_fields";
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  LandlineNumber?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_RegNonIndividual" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Sum_Order_By = {
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_var_pop_fields";
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ura_RegNonIndividual" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Var_Pop_Order_By = {
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_var_samp_fields";
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ura_RegNonIndividual" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Var_Samp_Order_By = {
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Variance_Fields = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual_variance_fields";
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ura_RegNonIndividual" */
export type Rtcs_Db_Ph_Ura_RegNonIndividual_Variance_Order_By = {
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_ura_RentExpenseDetails" */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails = {
  __typename?: "rtcs_db_PH_ura_RentExpenseDetails";
  Amount?: Maybe<Scalars["float8"]>;
  Expensetype?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnOtherExpnsDtlId?: Maybe<Scalars["String"]>;
  RtnOtherExpnsDtlId?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_RentExpenseDetails" */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Aggregate = {
  __typename?: "rtcs_db_PH_ura_RentExpenseDetails_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_RentExpenseDetails>;
};

/** aggregate fields of "rtcs_db.PH_ura_RentExpenseDetails" */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ura_RentExpenseDetails_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ura_RentExpenseDetails" */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Ura_RentExpenseDetails_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_ura_RentExpenseDetails" */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_RentExpenseDetails" */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Ura_RentExpenseDetails_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_RentExpenseDetails_avg_fields";
  Amount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_RentExpenseDetails" */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Avg_Order_By = {
  Amount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_RentExpenseDetails". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Bool_Exp = {
  Amount?: Maybe<Float8_Comparison_Exp>;
  Expensetype?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnNoRtnOtherExpnsDtlId?: Maybe<String_Comparison_Exp>;
  RtnOtherExpnsDtlId?: Maybe<String_Comparison_Exp>;
  SourceType?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_RentExpenseDetails" */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Inc_Input = {
  Amount?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_RentExpenseDetails" */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Insert_Input = {
  Amount?: Maybe<Scalars["float8"]>;
  Expensetype?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnOtherExpnsDtlId?: Maybe<Scalars["String"]>;
  RtnOtherExpnsDtlId?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_RentExpenseDetails_max_fields";
  Amount?: Maybe<Scalars["float8"]>;
  Expensetype?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnOtherExpnsDtlId?: Maybe<Scalars["String"]>;
  RtnOtherExpnsDtlId?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_RentExpenseDetails" */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Max_Order_By = {
  Amount?: Maybe<Order_By>;
  Expensetype?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnOtherExpnsDtlId?: Maybe<Order_By>;
  RtnOtherExpnsDtlId?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_RentExpenseDetails_min_fields";
  Amount?: Maybe<Scalars["float8"]>;
  Expensetype?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnOtherExpnsDtlId?: Maybe<Scalars["String"]>;
  RtnOtherExpnsDtlId?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_RentExpenseDetails" */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Min_Order_By = {
  Amount?: Maybe<Order_By>;
  Expensetype?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnOtherExpnsDtlId?: Maybe<Order_By>;
  RtnOtherExpnsDtlId?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ura_RentExpenseDetails" */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Mutation_Response = {
  __typename?: "rtcs_db_PH_ura_RentExpenseDetails_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Ura_RentExpenseDetails>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_RentExpenseDetails" */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Ura_RentExpenseDetails_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_ura_RentExpenseDetails" */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Order_By = {
  Amount?: Maybe<Order_By>;
  Expensetype?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnOtherExpnsDtlId?: Maybe<Order_By>;
  RtnOtherExpnsDtlId?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_RentExpenseDetails" */
export enum Rtcs_Db_Ph_Ura_RentExpenseDetails_Select_Column {
  /** column name */
  Amount = "Amount",
  /** column name */
  Expensetype = "Expensetype",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnNoRtnOtherExpnsDtlId = "RtnNoRtnOtherExpnsDtlId",
  /** column name */
  RtnOtherExpnsDtlId = "RtnOtherExpnsDtlId",
  /** column name */
  SourceType = "SourceType",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_RentExpenseDetails" */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Set_Input = {
  Amount?: Maybe<Scalars["float8"]>;
  Expensetype?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnOtherExpnsDtlId?: Maybe<Scalars["String"]>;
  RtnOtherExpnsDtlId?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ura_RentExpenseDetails_stddev_fields";
  Amount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ura_RentExpenseDetails" */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Stddev_Order_By = {
  Amount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_RentExpenseDetails_stddev_pop_fields";
  Amount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_RentExpenseDetails" */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Stddev_Pop_Order_By = {
  Amount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_RentExpenseDetails_stddev_samp_fields";
  Amount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_RentExpenseDetails" */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Stddev_Samp_Order_By = {
  Amount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_RentExpenseDetails_sum_fields";
  Amount?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_RentExpenseDetails" */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Sum_Order_By = {
  Amount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_RentExpenseDetails_var_pop_fields";
  Amount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ura_RentExpenseDetails" */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Var_Pop_Order_By = {
  Amount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_RentExpenseDetails_var_samp_fields";
  Amount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ura_RentExpenseDetails" */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Var_Samp_Order_By = {
  Amount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Variance_Fields = {
  __typename?: "rtcs_db_PH_ura_RentExpenseDetails_variance_fields";
  Amount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ura_RentExpenseDetails" */
export type Rtcs_Db_Ph_Ura_RentExpenseDetails_Variance_Order_By = {
  Amount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_ura_RentalLandLordIncome" */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome = {
  __typename?: "rtcs_db_PH_ura_RentalLandLordIncome";
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_RentalLandLordIncome" */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Aggregate = {
  __typename?: "rtcs_db_PH_ura_RentalLandLordIncome_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_RentalLandLordIncome>;
};

/** aggregate fields of "rtcs_db.PH_ura_RentalLandLordIncome" */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ura_RentalLandLordIncome_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ura_RentalLandLordIncome" */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_ura_RentalLandLordIncome" */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_RentalLandLordIncome" */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_RentalLandLordIncome_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_RentalLandLordIncome" */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Avg_Order_By = {
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_RentalLandLordIncome". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  PremiseLocation?: Maybe<String_Comparison_Exp>;
  RentalIncome?: Maybe<Float8_Comparison_Exp>;
  ReturnType?: Maybe<String_Comparison_Exp>;
  RtnDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnFromDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnNoRtnRentalIncomeId?: Maybe<String_Comparison_Exp>;
  RtnRentalIncomeId?: Maybe<String_Comparison_Exp>;
  RtnToDt?: Maybe<Timestamptz_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TenancyPeriodFrom?: Maybe<Timestamptz_Comparison_Exp>;
  TenancyPeriodTo?: Maybe<Timestamptz_Comparison_Exp>;
  TenantName?: Maybe<String_Comparison_Exp>;
  TenantTin?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_RentalLandLordIncome" */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_RentalLandLordIncome" */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_RentalLandLordIncome_max_fields";
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_RentalLandLordIncome" */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Max_Order_By = {
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnRentalIncomeId?: Maybe<Order_By>;
  RtnRentalIncomeId?: Maybe<Order_By>;
  RtnToDt?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  TenantName?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_RentalLandLordIncome_min_fields";
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_RentalLandLordIncome" */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Min_Order_By = {
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnRentalIncomeId?: Maybe<Order_By>;
  RtnRentalIncomeId?: Maybe<Order_By>;
  RtnToDt?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  TenantName?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ura_RentalLandLordIncome" */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Mutation_Response = {
  __typename?: "rtcs_db_PH_ura_RentalLandLordIncome_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Ura_RentalLandLordIncome>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_RentalLandLordIncome" */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Ura_RentalLandLordIncome_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_ura_RentalLandLordIncome" */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Order_By = {
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnRentalIncomeId?: Maybe<Order_By>;
  RtnRentalIncomeId?: Maybe<Order_By>;
  RtnToDt?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  TenantName?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_RentalLandLordIncome" */
export enum Rtcs_Db_Ph_Ura_RentalLandLordIncome_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  PremiseLocation = "PremiseLocation",
  /** column name */
  RentalIncome = "RentalIncome",
  /** column name */
  ReturnType = "ReturnType",
  /** column name */
  RtnDt = "RtnDt",
  /** column name */
  RtnFromDt = "RtnFromDt",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnNoRtnRentalIncomeId = "RtnNoRtnRentalIncomeId",
  /** column name */
  RtnRentalIncomeId = "RtnRentalIncomeId",
  /** column name */
  RtnToDt = "RtnToDt",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TenancyPeriodFrom = "TenancyPeriodFrom",
  /** column name */
  TenancyPeriodTo = "TenancyPeriodTo",
  /** column name */
  TenantName = "TenantName",
  /** column name */
  TenantTin = "TenantTin",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_RentalLandLordIncome" */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ura_RentalLandLordIncome_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ura_RentalLandLordIncome" */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_RentalLandLordIncome_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_RentalLandLordIncome" */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_RentalLandLordIncome_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_RentalLandLordIncome" */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_RentalLandLordIncome_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_RentalLandLordIncome" */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Sum_Order_By = {
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_RentalLandLordIncome_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ura_RentalLandLordIncome" */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_RentalLandLordIncome_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ura_RentalLandLordIncome" */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Variance_Fields = {
  __typename?: "rtcs_db_PH_ura_RentalLandLordIncome_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ura_RentalLandLordIncome" */
export type Rtcs_Db_Ph_Ura_RentalLandLordIncome_Variance_Order_By = {
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_ura_RentalTenantPayments" */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments = {
  __typename?: "rtcs_db_PH_ura_RentalTenantPayments";
  File?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnRentPaidId?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_RentalTenantPayments" */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Aggregate = {
  __typename?: "rtcs_db_PH_ura_RentalTenantPayments_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_RentalTenantPayments>;
};

/** aggregate fields of "rtcs_db.PH_ura_RentalTenantPayments" */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ura_RentalTenantPayments_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ura_RentalTenantPayments" */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalTenantPayments_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_ura_RentalTenantPayments" */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_RentalTenantPayments" */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Ura_RentalTenantPayments_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_RentalTenantPayments_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_RentalTenantPayments" */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Avg_Order_By = {
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_RentalTenantPayments". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  LandlordName?: Maybe<String_Comparison_Exp>;
  LandlordTin?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  PremiseLocation?: Maybe<String_Comparison_Exp>;
  RentPaid?: Maybe<Float8_Comparison_Exp>;
  ReturnType?: Maybe<String_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnNoRtnRentPaidId?: Maybe<String_Comparison_Exp>;
  RtnRentPaidId?: Maybe<String_Comparison_Exp>;
  TenancyPeriodFrom?: Maybe<Timestamptz_Comparison_Exp>;
  TenancyPeriodTo?: Maybe<Timestamptz_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_RentalTenantPayments" */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_RentalTenantPayments" */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnRentPaidId?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_RentalTenantPayments_max_fields";
  File?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnRentPaidId?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_RentalTenantPayments" */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Max_Order_By = {
  File?: Maybe<Order_By>;
  LandlordName?: Maybe<Order_By>;
  LandlordTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnRentPaidId?: Maybe<Order_By>;
  RtnRentPaidId?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_RentalTenantPayments_min_fields";
  File?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnRentPaidId?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_RentalTenantPayments" */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Min_Order_By = {
  File?: Maybe<Order_By>;
  LandlordName?: Maybe<Order_By>;
  LandlordTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnRentPaidId?: Maybe<Order_By>;
  RtnRentPaidId?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ura_RentalTenantPayments" */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Mutation_Response = {
  __typename?: "rtcs_db_PH_ura_RentalTenantPayments_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Ura_RentalTenantPayments>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_RentalTenantPayments" */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Ura_RentalTenantPayments_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_ura_RentalTenantPayments" */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Order_By = {
  File?: Maybe<Order_By>;
  LandlordName?: Maybe<Order_By>;
  LandlordTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnRentPaidId?: Maybe<Order_By>;
  RtnRentPaidId?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_RentalTenantPayments" */
export enum Rtcs_Db_Ph_Ura_RentalTenantPayments_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  LandlordName = "LandlordName",
  /** column name */
  LandlordTin = "LandlordTin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  PremiseLocation = "PremiseLocation",
  /** column name */
  RentPaid = "RentPaid",
  /** column name */
  ReturnType = "ReturnType",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnNoRtnRentPaidId = "RtnNoRtnRentPaidId",
  /** column name */
  RtnRentPaidId = "RtnRentPaidId",
  /** column name */
  TenancyPeriodFrom = "TenancyPeriodFrom",
  /** column name */
  TenancyPeriodTo = "TenancyPeriodTo",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_RentalTenantPayments" */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnRentPaidId?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ura_RentalTenantPayments_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ura_RentalTenantPayments" */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_RentalTenantPayments_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_RentalTenantPayments" */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_RentalTenantPayments_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_RentalTenantPayments" */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_RentalTenantPayments_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_RentalTenantPayments" */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Sum_Order_By = {
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_RentalTenantPayments_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ura_RentalTenantPayments" */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_RentalTenantPayments_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ura_RentalTenantPayments" */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Variance_Fields = {
  __typename?: "rtcs_db_PH_ura_RentalTenantPayments_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ura_RentalTenantPayments" */
export type Rtcs_Db_Ph_Ura_RentalTenantPayments_Variance_Order_By = {
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary = {
  __typename?: "rtcs_db_PH_ura_RipoIndRentalIncSummary";
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Aggregate = {
  __typename?: "rtcs_db_PH_ura_RipoIndRentalIncSummary_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary>;
};

/** aggregate fields of "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ura_RipoIndRentalIncSummary_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_RipoIndRentalIncSummary_avg_fields";
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Avg_Order_By = {
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_RipoIndRentalIncSummary". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Bool_Exp = {
  ChrgdRentalIncm?: Maybe<Float8_Comparison_Exp>;
  DeductIncmTax?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  GrossRentalIncm?: Maybe<Float8_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MotgageIntDecd?: Maybe<Float8_Comparison_Exp>;
  NetRentIncm?: Maybe<Float8_Comparison_Exp>;
  PartnerRentIncome?: Maybe<Float8_Comparison_Exp>;
  ReturnType?: Maybe<String_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  TaxAssessed?: Maybe<Float8_Comparison_Exp>;
  ThresholdAmnt?: Maybe<Float8_Comparison_Exp>;
  TotRentalIncm?: Maybe<Float8_Comparison_Exp>;
  Uuid?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Inc_Input = {
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Insert_Input = {
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_RipoIndRentalIncSummary_max_fields";
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Max_Order_By = {
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_RipoIndRentalIncSummary_min_fields";
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Min_Order_By = {
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Mutation_Response = {
  __typename?: "rtcs_db_PH_ura_RipoIndRentalIncSummary_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Order_By = {
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
export enum Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Select_Column {
  /** column name */
  ChrgdRentalIncm = "ChrgdRentalIncm",
  /** column name */
  DeductIncmTax = "DeductIncmTax",
  /** column name */
  File = "File",
  /** column name */
  GrossRentalIncm = "GrossRentalIncm",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MotgageIntDecd = "MotgageIntDecd",
  /** column name */
  NetRentIncm = "NetRentIncm",
  /** column name */
  PartnerRentIncome = "PartnerRentIncome",
  /** column name */
  ReturnType = "ReturnType",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  TaxAssessed = "TaxAssessed",
  /** column name */
  ThresholdAmnt = "ThresholdAmnt",
  /** column name */
  TotRentalIncm = "TotRentalIncm",
  /** column name */
  Uuid = "Uuid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Set_Input = {
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ura_RipoIndRentalIncSummary_stddev_fields";
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Stddev_Order_By = {
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_RipoIndRentalIncSummary_stddev_pop_fields";
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Stddev_Pop_Order_By = {
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_RipoIndRentalIncSummary_stddev_samp_fields";
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Stddev_Samp_Order_By = {
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_RipoIndRentalIncSummary_sum_fields";
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Sum_Order_By = {
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_RipoIndRentalIncSummary_var_pop_fields";
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Var_Pop_Order_By = {
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_RipoIndRentalIncSummary_var_samp_fields";
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Var_Samp_Order_By = {
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Variance_Fields = {
  __typename?: "rtcs_db_PH_ura_RipoIndRentalIncSummary_variance_fields";
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Variance_Order_By = {
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_ura_TenantIncomeDetail" */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail = {
  __typename?: "rtcs_db_PH_ura_TenantIncomeDetail";
  Contribution?: Maybe<Scalars["float8"]>;
  ContributionAmount?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  LandlordPeriodTo?: Maybe<Scalars["timestamptz"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  TaxYear?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantTaxPayerName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_TenantIncomeDetail" */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Aggregate = {
  __typename?: "rtcs_db_PH_ura_TenantIncomeDetail_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_TenantIncomeDetail>;
};

/** aggregate fields of "rtcs_db.PH_ura_TenantIncomeDetail" */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ura_TenantIncomeDetail_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ura_TenantIncomeDetail" */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_ura_TenantIncomeDetail" */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_TenantIncomeDetail" */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_TenantIncomeDetail_avg_fields";
  Contribution?: Maybe<Scalars["Float"]>;
  ContributionAmount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  TenantTin?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_TenantIncomeDetail" */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Avg_Order_By = {
  Contribution?: Maybe<Order_By>;
  ContributionAmount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_TenantIncomeDetail". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Bool_Exp = {
  Contribution?: Maybe<Float8_Comparison_Exp>;
  ContributionAmount?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LandlordName?: Maybe<String_Comparison_Exp>;
  LandlordPeriodFrom?: Maybe<Timestamptz_Comparison_Exp>;
  LandlordPeriodTo?: Maybe<Timestamptz_Comparison_Exp>;
  LandlordTin?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  PremiseLocation?: Maybe<String_Comparison_Exp>;
  RentPaid?: Maybe<Float8_Comparison_Exp>;
  RmatchScore?: Maybe<Float8_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnRentPaidId?: Maybe<String_Comparison_Exp>;
  TaxYear?: Maybe<String_Comparison_Exp>;
  TenancyPeriodFrom?: Maybe<Timestamptz_Comparison_Exp>;
  TenancyPeriodTo?: Maybe<Timestamptz_Comparison_Exp>;
  TenantTaxPayerName?: Maybe<String_Comparison_Exp>;
  TenantTin?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_TenantIncomeDetail" */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Inc_Input = {
  Contribution?: Maybe<Scalars["float8"]>;
  ContributionAmount?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  TenantTin?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_TenantIncomeDetail" */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Insert_Input = {
  Contribution?: Maybe<Scalars["float8"]>;
  ContributionAmount?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  LandlordPeriodTo?: Maybe<Scalars["timestamptz"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  TaxYear?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantTaxPayerName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_TenantIncomeDetail_max_fields";
  Contribution?: Maybe<Scalars["float8"]>;
  ContributionAmount?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  LandlordPeriodTo?: Maybe<Scalars["timestamptz"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  TaxYear?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantTaxPayerName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_TenantIncomeDetail" */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Max_Order_By = {
  Contribution?: Maybe<Order_By>;
  ContributionAmount?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LandlordName?: Maybe<Order_By>;
  LandlordPeriodFrom?: Maybe<Order_By>;
  LandlordPeriodTo?: Maybe<Order_By>;
  LandlordTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnRentPaidId?: Maybe<Order_By>;
  TaxYear?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  TenantTaxPayerName?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_TenantIncomeDetail_min_fields";
  Contribution?: Maybe<Scalars["float8"]>;
  ContributionAmount?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  LandlordPeriodTo?: Maybe<Scalars["timestamptz"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  TaxYear?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantTaxPayerName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_TenantIncomeDetail" */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Min_Order_By = {
  Contribution?: Maybe<Order_By>;
  ContributionAmount?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LandlordName?: Maybe<Order_By>;
  LandlordPeriodFrom?: Maybe<Order_By>;
  LandlordPeriodTo?: Maybe<Order_By>;
  LandlordTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnRentPaidId?: Maybe<Order_By>;
  TaxYear?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  TenantTaxPayerName?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ura_TenantIncomeDetail" */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Mutation_Response = {
  __typename?: "rtcs_db_PH_ura_TenantIncomeDetail_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Ura_TenantIncomeDetail>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_TenantIncomeDetail" */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Ura_TenantIncomeDetail_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_ura_TenantIncomeDetail" */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Order_By = {
  Contribution?: Maybe<Order_By>;
  ContributionAmount?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LandlordName?: Maybe<Order_By>;
  LandlordPeriodFrom?: Maybe<Order_By>;
  LandlordPeriodTo?: Maybe<Order_By>;
  LandlordTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnRentPaidId?: Maybe<Order_By>;
  TaxYear?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  TenantTaxPayerName?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_TenantIncomeDetail" */
export enum Rtcs_Db_Ph_Ura_TenantIncomeDetail_Select_Column {
  /** column name */
  Contribution = "Contribution",
  /** column name */
  ContributionAmount = "ContributionAmount",
  /** column name */
  File = "File",
  /** column name */
  LandlordName = "LandlordName",
  /** column name */
  LandlordPeriodFrom = "LandlordPeriodFrom",
  /** column name */
  LandlordPeriodTo = "LandlordPeriodTo",
  /** column name */
  LandlordTin = "LandlordTin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  PremiseLocation = "PremiseLocation",
  /** column name */
  RentPaid = "RentPaid",
  /** column name */
  RmatchScore = "RmatchScore",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnRentPaidId = "RtnRentPaidId",
  /** column name */
  TaxYear = "TaxYear",
  /** column name */
  TenancyPeriodFrom = "TenancyPeriodFrom",
  /** column name */
  TenancyPeriodTo = "TenancyPeriodTo",
  /** column name */
  TenantTaxPayerName = "TenantTaxPayerName",
  /** column name */
  TenantTin = "TenantTin",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_TenantIncomeDetail" */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Set_Input = {
  Contribution?: Maybe<Scalars["float8"]>;
  ContributionAmount?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  LandlordPeriodTo?: Maybe<Scalars["timestamptz"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  TaxYear?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantTaxPayerName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ura_TenantIncomeDetail_stddev_fields";
  Contribution?: Maybe<Scalars["Float"]>;
  ContributionAmount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  TenantTin?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ura_TenantIncomeDetail" */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Stddev_Order_By = {
  Contribution?: Maybe<Order_By>;
  ContributionAmount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_TenantIncomeDetail_stddev_pop_fields";
  Contribution?: Maybe<Scalars["Float"]>;
  ContributionAmount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  TenantTin?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_TenantIncomeDetail" */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Stddev_Pop_Order_By = {
  Contribution?: Maybe<Order_By>;
  ContributionAmount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_TenantIncomeDetail_stddev_samp_fields";
  Contribution?: Maybe<Scalars["Float"]>;
  ContributionAmount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  TenantTin?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_TenantIncomeDetail" */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Stddev_Samp_Order_By = {
  Contribution?: Maybe<Order_By>;
  ContributionAmount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_TenantIncomeDetail_sum_fields";
  Contribution?: Maybe<Scalars["float8"]>;
  ContributionAmount?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  TenantTin?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_TenantIncomeDetail" */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Sum_Order_By = {
  Contribution?: Maybe<Order_By>;
  ContributionAmount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_TenantIncomeDetail_var_pop_fields";
  Contribution?: Maybe<Scalars["Float"]>;
  ContributionAmount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  TenantTin?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ura_TenantIncomeDetail" */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Var_Pop_Order_By = {
  Contribution?: Maybe<Order_By>;
  ContributionAmount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_TenantIncomeDetail_var_samp_fields";
  Contribution?: Maybe<Scalars["Float"]>;
  ContributionAmount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  TenantTin?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ura_TenantIncomeDetail" */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Var_Samp_Order_By = {
  Contribution?: Maybe<Order_By>;
  ContributionAmount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Variance_Fields = {
  __typename?: "rtcs_db_PH_ura_TenantIncomeDetail_variance_fields";
  Contribution?: Maybe<Scalars["Float"]>;
  ContributionAmount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  TenantTin?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ura_TenantIncomeDetail" */
export type Rtcs_Db_Ph_Ura_TenantIncomeDetail_Variance_Order_By = {
  Contribution?: Maybe<Order_By>;
  ContributionAmount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_ura_Town" */
export type Rtcs_Db_Ph_Ura_Town = {
  __typename?: "rtcs_db_PH_ura_Town";
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["bigint"]>;
  TownName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ura_Town" */
export type Rtcs_Db_Ph_Ura_Town_Aggregate = {
  __typename?: "rtcs_db_PH_ura_Town_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ura_Town_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ura_Town>;
};

/** aggregate fields of "rtcs_db.PH_ura_Town" */
export type Rtcs_Db_Ph_Ura_Town_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ura_Town_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ura_Town_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ura_Town_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ura_Town_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_Town_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_Town_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_Town_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_Town_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_Town_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_Town_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_Town_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ura_Town" */
export type Rtcs_Db_Ph_Ura_Town_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ph_Ura_Town_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.PH_ura_Town" */
export type Rtcs_Db_Ph_Ura_Town_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Ura_Town_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Ura_Town_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Ura_Town_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Ura_Town_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ura_Town_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ura_Town_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Ura_Town_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ura_Town_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ura_Town_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Ura_Town_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_ura_Town" */
export type Rtcs_Db_Ph_Ura_Town_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ph_Ura_Town_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ura_Town_Avg_Fields = {
  __typename?: "rtcs_db_PH_ura_Town_avg_fields";
  DistrictId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ura_Town" */
export type Rtcs_Db_Ph_Ura_Town_Avg_Order_By = {
  DistrictId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ura_Town". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ura_Town_Bool_Exp = {
  DistrictId?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamp_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  TownCd?: Maybe<String_Comparison_Exp>;
  TownId?: Maybe<Bigint_Comparison_Exp>;
  TownName?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_Town_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ph_Ura_Town_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ph_Ura_Town_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ura_Town" */
export type Rtcs_Db_Ph_Ura_Town_Inc_Input = {
  DistrictId?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  TownId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ura_Town" */
export type Rtcs_Db_Ph_Ura_Town_Insert_Input = {
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["bigint"]>;
  TownName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ura_Town_Max_Fields = {
  __typename?: "rtcs_db_PH_ura_Town_max_fields";
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["bigint"]>;
  TownName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ura_Town" */
export type Rtcs_Db_Ph_Ura_Town_Max_Order_By = {
  DistrictId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TownCd?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
  TownName?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ura_Town_Min_Fields = {
  __typename?: "rtcs_db_PH_ura_Town_min_fields";
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["bigint"]>;
  TownName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ura_Town" */
export type Rtcs_Db_Ph_Ura_Town_Min_Order_By = {
  DistrictId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TownCd?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
  TownName?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ura_Town" */
export type Rtcs_Db_Ph_Ura_Town_Mutation_Response = {
  __typename?: "rtcs_db_PH_ura_Town_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Ura_Town>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_ura_Town" */
export type Rtcs_Db_Ph_Ura_Town_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ph_Ura_Town_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.PH_ura_Town" */
export type Rtcs_Db_Ph_Ura_Town_Order_By = {
  DistrictId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TownCd?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
  TownName?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ura_Town" */
export enum Rtcs_Db_Ph_Ura_Town_Select_Column {
  /** column name */
  DistrictId = "DistrictId",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  TownCd = "TownCd",
  /** column name */
  TownId = "TownId",
  /** column name */
  TownName = "TownName",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ura_Town" */
export type Rtcs_Db_Ph_Ura_Town_Set_Input = {
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["bigint"]>;
  TownName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ura_Town_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ura_Town_stddev_fields";
  DistrictId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ura_Town" */
export type Rtcs_Db_Ph_Ura_Town_Stddev_Order_By = {
  DistrictId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ura_Town_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_Town_stddev_pop_fields";
  DistrictId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_ura_Town" */
export type Rtcs_Db_Ph_Ura_Town_Stddev_Pop_Order_By = {
  DistrictId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ura_Town_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_Town_stddev_samp_fields";
  DistrictId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_ura_Town" */
export type Rtcs_Db_Ph_Ura_Town_Stddev_Samp_Order_By = {
  DistrictId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ura_Town_Sum_Fields = {
  __typename?: "rtcs_db_PH_ura_Town_sum_fields";
  DistrictId?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  TownId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ura_Town" */
export type Rtcs_Db_Ph_Ura_Town_Sum_Order_By = {
  DistrictId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ura_Town_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ura_Town_var_pop_fields";
  DistrictId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ura_Town" */
export type Rtcs_Db_Ph_Ura_Town_Var_Pop_Order_By = {
  DistrictId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ura_Town_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ura_Town_var_samp_fields";
  DistrictId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ura_Town" */
export type Rtcs_Db_Ph_Ura_Town_Var_Samp_Order_By = {
  DistrictId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ura_Town_Variance_Fields = {
  __typename?: "rtcs_db_PH_ura_Town_variance_fields";
  DistrictId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ura_Town" */
export type Rtcs_Db_Ph_Ura_Town_Variance_Order_By = {
  DistrictId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb = {
  __typename?: "rtcs_db_PH_ursb_CompaniesAndBusinessNamesURSB";
  BusinessName?: Maybe<Scalars["String"]>;
  CompanyName?: Maybe<Scalars["String"]>;
  CompanyType?: Maybe<Scalars["String"]>;
  DataSource?: Maybe<Scalars["String"]>;
  DirectorFirstname?: Maybe<Scalars["String"]>;
  DirectorSurname?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Parish?: Maybe<Scalars["String"]>;
  PartnersFirstname?: Maybe<Scalars["String"]>;
  PartnersSurname?: Maybe<Scalars["String"]>;
  RegistrationDate?: Maybe<Scalars["timestamptz"]>;
  RegistrationNumber?: Maybe<Scalars["bigint"]>;
  ShareholderFirstname?: Maybe<Scalars["String"]>;
  ShareholdersSurname?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Subtype?: Maybe<Scalars["String"]>;
  Town?: Maybe<Scalars["String"]>;
  Trading?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Aggregate = {
  __typename?: "rtcs_db_PH_ursb_CompaniesAndBusinessNamesURSB_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb>;
};

/** aggregate fields of "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Aggregate_Fields = {
  __typename?: "rtcs_db_PH_ursb_CompaniesAndBusinessNamesURSB_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Avg_Fields = {
  __typename?: "rtcs_db_PH_ursb_CompaniesAndBusinessNamesURSB_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
  RegistrationNumber?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Avg_Order_By = {
  Line?: Maybe<Order_By>;
  RegistrationNumber?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp = {
  BusinessName?: Maybe<String_Comparison_Exp>;
  CompanyName?: Maybe<String_Comparison_Exp>;
  CompanyType?: Maybe<String_Comparison_Exp>;
  DataSource?: Maybe<String_Comparison_Exp>;
  DirectorFirstname?: Maybe<String_Comparison_Exp>;
  DirectorSurname?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Parish?: Maybe<String_Comparison_Exp>;
  PartnersFirstname?: Maybe<String_Comparison_Exp>;
  PartnersSurname?: Maybe<String_Comparison_Exp>;
  RegistrationDate?: Maybe<Timestamptz_Comparison_Exp>;
  RegistrationNumber?: Maybe<Bigint_Comparison_Exp>;
  ShareholderFirstname?: Maybe<String_Comparison_Exp>;
  ShareholdersSurname?: Maybe<String_Comparison_Exp>;
  Street?: Maybe<String_Comparison_Exp>;
  Subtype?: Maybe<String_Comparison_Exp>;
  Town?: Maybe<String_Comparison_Exp>;
  Trading?: Maybe<String_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
  RegistrationNumber?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Insert_Input = {
  BusinessName?: Maybe<Scalars["String"]>;
  CompanyName?: Maybe<Scalars["String"]>;
  CompanyType?: Maybe<Scalars["String"]>;
  DataSource?: Maybe<Scalars["String"]>;
  DirectorFirstname?: Maybe<Scalars["String"]>;
  DirectorSurname?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Parish?: Maybe<Scalars["String"]>;
  PartnersFirstname?: Maybe<Scalars["String"]>;
  PartnersSurname?: Maybe<Scalars["String"]>;
  RegistrationDate?: Maybe<Scalars["timestamptz"]>;
  RegistrationNumber?: Maybe<Scalars["bigint"]>;
  ShareholderFirstname?: Maybe<Scalars["String"]>;
  ShareholdersSurname?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Subtype?: Maybe<Scalars["String"]>;
  Town?: Maybe<Scalars["String"]>;
  Trading?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Max_Fields = {
  __typename?: "rtcs_db_PH_ursb_CompaniesAndBusinessNamesURSB_max_fields";
  BusinessName?: Maybe<Scalars["String"]>;
  CompanyName?: Maybe<Scalars["String"]>;
  CompanyType?: Maybe<Scalars["String"]>;
  DataSource?: Maybe<Scalars["String"]>;
  DirectorFirstname?: Maybe<Scalars["String"]>;
  DirectorSurname?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Parish?: Maybe<Scalars["String"]>;
  PartnersFirstname?: Maybe<Scalars["String"]>;
  PartnersSurname?: Maybe<Scalars["String"]>;
  RegistrationDate?: Maybe<Scalars["timestamptz"]>;
  RegistrationNumber?: Maybe<Scalars["bigint"]>;
  ShareholderFirstname?: Maybe<Scalars["String"]>;
  ShareholdersSurname?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Subtype?: Maybe<Scalars["String"]>;
  Town?: Maybe<Scalars["String"]>;
  Trading?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Max_Order_By = {
  BusinessName?: Maybe<Order_By>;
  CompanyName?: Maybe<Order_By>;
  CompanyType?: Maybe<Order_By>;
  DataSource?: Maybe<Order_By>;
  DirectorFirstname?: Maybe<Order_By>;
  DirectorSurname?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  PartnersFirstname?: Maybe<Order_By>;
  PartnersSurname?: Maybe<Order_By>;
  RegistrationDate?: Maybe<Order_By>;
  RegistrationNumber?: Maybe<Order_By>;
  ShareholderFirstname?: Maybe<Order_By>;
  ShareholdersSurname?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Subtype?: Maybe<Order_By>;
  Town?: Maybe<Order_By>;
  Trading?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Min_Fields = {
  __typename?: "rtcs_db_PH_ursb_CompaniesAndBusinessNamesURSB_min_fields";
  BusinessName?: Maybe<Scalars["String"]>;
  CompanyName?: Maybe<Scalars["String"]>;
  CompanyType?: Maybe<Scalars["String"]>;
  DataSource?: Maybe<Scalars["String"]>;
  DirectorFirstname?: Maybe<Scalars["String"]>;
  DirectorSurname?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Parish?: Maybe<Scalars["String"]>;
  PartnersFirstname?: Maybe<Scalars["String"]>;
  PartnersSurname?: Maybe<Scalars["String"]>;
  RegistrationDate?: Maybe<Scalars["timestamptz"]>;
  RegistrationNumber?: Maybe<Scalars["bigint"]>;
  ShareholderFirstname?: Maybe<Scalars["String"]>;
  ShareholdersSurname?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Subtype?: Maybe<Scalars["String"]>;
  Town?: Maybe<Scalars["String"]>;
  Trading?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Min_Order_By = {
  BusinessName?: Maybe<Order_By>;
  CompanyName?: Maybe<Order_By>;
  CompanyType?: Maybe<Order_By>;
  DataSource?: Maybe<Order_By>;
  DirectorFirstname?: Maybe<Order_By>;
  DirectorSurname?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  PartnersFirstname?: Maybe<Order_By>;
  PartnersSurname?: Maybe<Order_By>;
  RegistrationDate?: Maybe<Order_By>;
  RegistrationNumber?: Maybe<Order_By>;
  ShareholderFirstname?: Maybe<Order_By>;
  ShareholdersSurname?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Subtype?: Maybe<Order_By>;
  Town?: Maybe<Order_By>;
  Trading?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Mutation_Response = {
  __typename?: "rtcs_db_PH_ursb_CompaniesAndBusinessNamesURSB_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb>;
};

/** input type for inserting object relation for remote table "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Order_By = {
  BusinessName?: Maybe<Order_By>;
  CompanyName?: Maybe<Order_By>;
  CompanyType?: Maybe<Order_By>;
  DataSource?: Maybe<Order_By>;
  DirectorFirstname?: Maybe<Order_By>;
  DirectorSurname?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  PartnersFirstname?: Maybe<Order_By>;
  PartnersSurname?: Maybe<Order_By>;
  RegistrationDate?: Maybe<Order_By>;
  RegistrationNumber?: Maybe<Order_By>;
  ShareholderFirstname?: Maybe<Order_By>;
  ShareholdersSurname?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Subtype?: Maybe<Order_By>;
  Town?: Maybe<Order_By>;
  Trading?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
export enum Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column {
  /** column name */
  BusinessName = "BusinessName",
  /** column name */
  CompanyName = "CompanyName",
  /** column name */
  CompanyType = "CompanyType",
  /** column name */
  DataSource = "DataSource",
  /** column name */
  DirectorFirstname = "DirectorFirstname",
  /** column name */
  DirectorSurname = "DirectorSurname",
  /** column name */
  District = "District",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Parish = "Parish",
  /** column name */
  PartnersFirstname = "PartnersFirstname",
  /** column name */
  PartnersSurname = "PartnersSurname",
  /** column name */
  RegistrationDate = "RegistrationDate",
  /** column name */
  RegistrationNumber = "RegistrationNumber",
  /** column name */
  ShareholderFirstname = "ShareholderFirstname",
  /** column name */
  ShareholdersSurname = "ShareholdersSurname",
  /** column name */
  Street = "Street",
  /** column name */
  Subtype = "Subtype",
  /** column name */
  Town = "Town",
  /** column name */
  Trading = "Trading",
  /** column name */
  Village = "Village",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Set_Input = {
  BusinessName?: Maybe<Scalars["String"]>;
  CompanyName?: Maybe<Scalars["String"]>;
  CompanyType?: Maybe<Scalars["String"]>;
  DataSource?: Maybe<Scalars["String"]>;
  DirectorFirstname?: Maybe<Scalars["String"]>;
  DirectorSurname?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Parish?: Maybe<Scalars["String"]>;
  PartnersFirstname?: Maybe<Scalars["String"]>;
  PartnersSurname?: Maybe<Scalars["String"]>;
  RegistrationDate?: Maybe<Scalars["timestamptz"]>;
  RegistrationNumber?: Maybe<Scalars["bigint"]>;
  ShareholderFirstname?: Maybe<Scalars["String"]>;
  ShareholdersSurname?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Subtype?: Maybe<Scalars["String"]>;
  Town?: Maybe<Scalars["String"]>;
  Trading?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Fields = {
  __typename?: "rtcs_db_PH_ursb_CompaniesAndBusinessNamesURSB_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
  RegistrationNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
  RegistrationNumber?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_PH_ursb_CompaniesAndBusinessNamesURSB_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  RegistrationNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Pop_Order_By =
  {
    Line?: Maybe<Order_By>;
    RegistrationNumber?: Maybe<Order_By>;
  };

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_PH_ursb_CompaniesAndBusinessNamesURSB_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  RegistrationNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Samp_Order_By =
  {
    Line?: Maybe<Order_By>;
    RegistrationNumber?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Sum_Fields = {
  __typename?: "rtcs_db_PH_ursb_CompaniesAndBusinessNamesURSB_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
  RegistrationNumber?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Sum_Order_By = {
  Line?: Maybe<Order_By>;
  RegistrationNumber?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Var_Pop_Fields = {
  __typename?: "rtcs_db_PH_ursb_CompaniesAndBusinessNamesURSB_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  RegistrationNumber?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  RegistrationNumber?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Var_Samp_Fields = {
  __typename?: "rtcs_db_PH_ursb_CompaniesAndBusinessNamesURSB_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  RegistrationNumber?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  RegistrationNumber?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Variance_Fields = {
  __typename?: "rtcs_db_PH_ursb_CompaniesAndBusinessNamesURSB_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
  RegistrationNumber?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Variance_Order_By = {
  Line?: Maybe<Order_By>;
  RegistrationNumber?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk = {
  __typename?: "rtcs_db_RPT_IndividualComplianceSummaryPlusRisk";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["float8"]>;
  Kccamobile2?: Maybe<Scalars["float8"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["float8"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  RiskCode?: Maybe<Scalars["String"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** aggregated selection of "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Aggregate = {
  __typename?: "rtcs_db_RPT_IndividualComplianceSummaryPlusRisk_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk>;
};

/** aggregate fields of "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Aggregate_Fields = {
  __typename?: "rtcs_db_RPT_IndividualComplianceSummaryPlusRisk_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Aggregate_Order_By =
  {
    avg?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Max_Order_By>;
    min?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Min_Order_By>;
    stddev?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Stddev_Order_By>;
    stddev_pop?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Stddev_Samp_Order_By>;
    sum?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Sum_Order_By>;
    var_pop?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Var_Pop_Order_By>;
    var_samp?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Var_Samp_Order_By>;
    variance?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Variance_Order_By>;
  };

/** input type for inserting array relation for remote table "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Avg_Fields = {
  __typename?: "rtcs_db_RPT_IndividualComplianceSummaryPlusRisk_avg_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Countura?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Kccamobile1?: Maybe<Scalars["Float"]>;
  Kccamobile2?: Maybe<Scalars["Float"]>;
  Kccatelephone?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Mobilenumber?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  RiskScore?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Avg_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Countura?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  RiskScore?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Bool_Exp = {
  Averagerateperrentedproperty?: Maybe<Float8_Comparison_Exp>;
  Compliancecategory?: Maybe<String_Comparison_Exp>;
  Countura?: Maybe<Float8_Comparison_Exp>;
  Customerid?: Maybe<String_Comparison_Exp>;
  Difference?: Maybe<Float8_Comparison_Exp>;
  Emailid?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Fullname?: Maybe<String_Comparison_Exp>;
  Grossrentalincome?: Maybe<Float8_Comparison_Exp>;
  Isamendment?: Maybe<String_Comparison_Exp>;
  Isincomesrcbsns?: Maybe<String_Comparison_Exp>;
  Isincomesrcemp?: Maybe<String_Comparison_Exp>;
  Isincomesrcprpty?: Maybe<String_Comparison_Exp>;
  Isincomesrcrental?: Maybe<String_Comparison_Exp>;
  Kccabirthdate?: Maybe<String_Comparison_Exp>;
  Kccaemail?: Maybe<String_Comparison_Exp>;
  Kccafirstname?: Maybe<String_Comparison_Exp>;
  Kccamiddlename?: Maybe<String_Comparison_Exp>;
  Kccamobile1?: Maybe<Float8_Comparison_Exp>;
  Kccamobile2?: Maybe<Float8_Comparison_Exp>;
  Kccasurname?: Maybe<String_Comparison_Exp>;
  Kccatelephone?: Maybe<Float8_Comparison_Exp>;
  Kccatin?: Maybe<String_Comparison_Exp>;
  Matchcategory?: Maybe<String_Comparison_Exp>;
  Matchscore?: Maybe<Float8_Comparison_Exp>;
  Matchscoreattributes?: Maybe<String_Comparison_Exp>;
  Mobilenumber?: Maybe<Float8_Comparison_Exp>;
  Nationalid?: Maybe<String_Comparison_Exp>;
  Numproperties?: Maybe<Bigint_Comparison_Exp>;
  Numrentedproperties?: Maybe<Bigint_Comparison_Exp>;
  Percentagedifference?: Maybe<Float8_Comparison_Exp>;
  Propincincome?: Maybe<Float8_Comparison_Exp>;
  RiskCode?: Maybe<String_Comparison_Exp>;
  RiskScore?: Maybe<Bigint_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Rtnno?: Maybe<String_Comparison_Exp>;
  Rtnperiodyear?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  Sumadjustedincome?: Maybe<Float8_Comparison_Exp>;
  Sumgrossvalue?: Maybe<Float8_Comparison_Exp>;
  Sumnonrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Sumrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Urafirstname?: Maybe<String_Comparison_Exp>;
  Uramiddlename?: Maybe<String_Comparison_Exp>;
  Urasurname?: Maybe<String_Comparison_Exp>;
  Uratinno?: Maybe<Float8_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Inc_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Countura?: Maybe<Scalars["float8"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Kccamobile1?: Maybe<Scalars["float8"]>;
  Kccamobile2?: Maybe<Scalars["float8"]>;
  Kccatelephone?: Maybe<Scalars["float8"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Insert_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["float8"]>;
  Kccamobile2?: Maybe<Scalars["float8"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["float8"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  RiskCode?: Maybe<Scalars["String"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Max_Fields = {
  __typename?: "rtcs_db_RPT_IndividualComplianceSummaryPlusRisk_max_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["float8"]>;
  Kccamobile2?: Maybe<Scalars["float8"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["float8"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  RiskCode?: Maybe<Scalars["String"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** order by max() on columns of table "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Max_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Countura?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccabirthdate?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  Matchcategory?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  RiskCode?: Maybe<Order_By>;
  RiskScore?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramiddlename?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Min_Fields = {
  __typename?: "rtcs_db_RPT_IndividualComplianceSummaryPlusRisk_min_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["float8"]>;
  Kccamobile2?: Maybe<Scalars["float8"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["float8"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  RiskCode?: Maybe<Scalars["String"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** order by min() on columns of table "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Min_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Countura?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccabirthdate?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  Matchcategory?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  RiskCode?: Maybe<Order_By>;
  RiskScore?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramiddlename?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Mutation_Response =
  {
    __typename?: "rtcs_db_RPT_IndividualComplianceSummaryPlusRisk_mutation_response";
    /** number of affected rows by the mutation */
    affected_rows: Scalars["Int"];
    /** data of the affected rows by the mutation */
    returning: Array<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk>;
  };

/** input type for inserting object relation for remote table "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Countura?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccabirthdate?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  Matchcategory?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  RiskCode?: Maybe<Order_By>;
  RiskScore?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramiddlename?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
export enum Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column {
  /** column name */
  Averagerateperrentedproperty = "Averagerateperrentedproperty",
  /** column name */
  Compliancecategory = "Compliancecategory",
  /** column name */
  Countura = "Countura",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  Difference = "Difference",
  /** column name */
  Emailid = "Emailid",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Fullname = "Fullname",
  /** column name */
  Grossrentalincome = "Grossrentalincome",
  /** column name */
  Isamendment = "Isamendment",
  /** column name */
  Isincomesrcbsns = "Isincomesrcbsns",
  /** column name */
  Isincomesrcemp = "Isincomesrcemp",
  /** column name */
  Isincomesrcprpty = "Isincomesrcprpty",
  /** column name */
  Isincomesrcrental = "Isincomesrcrental",
  /** column name */
  Kccabirthdate = "Kccabirthdate",
  /** column name */
  Kccaemail = "Kccaemail",
  /** column name */
  Kccafirstname = "Kccafirstname",
  /** column name */
  Kccamiddlename = "Kccamiddlename",
  /** column name */
  Kccamobile1 = "Kccamobile1",
  /** column name */
  Kccamobile2 = "Kccamobile2",
  /** column name */
  Kccasurname = "Kccasurname",
  /** column name */
  Kccatelephone = "Kccatelephone",
  /** column name */
  Kccatin = "Kccatin",
  /** column name */
  Matchcategory = "Matchcategory",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Matchscoreattributes = "Matchscoreattributes",
  /** column name */
  Mobilenumber = "Mobilenumber",
  /** column name */
  Nationalid = "Nationalid",
  /** column name */
  Numproperties = "Numproperties",
  /** column name */
  Numrentedproperties = "Numrentedproperties",
  /** column name */
  Percentagedifference = "Percentagedifference",
  /** column name */
  Propincincome = "Propincincome",
  /** column name */
  RiskCode = "RiskCode",
  /** column name */
  RiskScore = "RiskScore",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Rtnno = "Rtnno",
  /** column name */
  Rtnperiodyear = "Rtnperiodyear",
  /** column name */
  Source = "Source",
  /** column name */
  Sumadjustedincome = "Sumadjustedincome",
  /** column name */
  Sumgrossvalue = "Sumgrossvalue",
  /** column name */
  Sumnonrateablevalue = "Sumnonrateablevalue",
  /** column name */
  Sumrateablevalue = "Sumrateablevalue",
  /** column name */
  Surname = "Surname",
  /** column name */
  Urafirstname = "Urafirstname",
  /** column name */
  Uramiddlename = "Uramiddlename",
  /** column name */
  Urasurname = "Urasurname",
  /** column name */
  Uratinno = "Uratinno",
}

/** input type for updating data in table "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Set_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["float8"]>;
  Kccamobile2?: Maybe<Scalars["float8"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["float8"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  RiskCode?: Maybe<Scalars["String"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Stddev_Fields = {
  __typename?: "rtcs_db_RPT_IndividualComplianceSummaryPlusRisk_stddev_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Countura?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Kccamobile1?: Maybe<Scalars["Float"]>;
  Kccamobile2?: Maybe<Scalars["Float"]>;
  Kccatelephone?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Mobilenumber?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  RiskScore?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Stddev_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Countura?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  RiskScore?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Stddev_Pop_Fields =
  {
    __typename?: "rtcs_db_RPT_IndividualComplianceSummaryPlusRisk_stddev_pop_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Countura?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Kccamobile1?: Maybe<Scalars["Float"]>;
    Kccamobile2?: Maybe<Scalars["Float"]>;
    Kccatelephone?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Mobilenumber?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Propincincome?: Maybe<Scalars["Float"]>;
    RiskScore?: Maybe<Scalars["Float"]>;
    Sumadjustedincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
    Uratinno?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_pop() on columns of table "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Stddev_Pop_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Countura?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Kccamobile1?: Maybe<Order_By>;
    Kccamobile2?: Maybe<Order_By>;
    Kccatelephone?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Propincincome?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Sumadjustedincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumnonrateablevalue?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Stddev_Samp_Fields =
  {
    __typename?: "rtcs_db_RPT_IndividualComplianceSummaryPlusRisk_stddev_samp_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Countura?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Kccamobile1?: Maybe<Scalars["Float"]>;
    Kccamobile2?: Maybe<Scalars["Float"]>;
    Kccatelephone?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Mobilenumber?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Propincincome?: Maybe<Scalars["Float"]>;
    RiskScore?: Maybe<Scalars["Float"]>;
    Sumadjustedincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
    Uratinno?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_samp() on columns of table "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Stddev_Samp_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Countura?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Kccamobile1?: Maybe<Order_By>;
    Kccamobile2?: Maybe<Order_By>;
    Kccatelephone?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Propincincome?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Sumadjustedincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumnonrateablevalue?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Sum_Fields = {
  __typename?: "rtcs_db_RPT_IndividualComplianceSummaryPlusRisk_sum_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Countura?: Maybe<Scalars["float8"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Kccamobile1?: Maybe<Scalars["float8"]>;
  Kccamobile2?: Maybe<Scalars["float8"]>;
  Kccatelephone?: Maybe<Scalars["float8"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Sum_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Countura?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  RiskScore?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Var_Pop_Fields = {
  __typename?: "rtcs_db_RPT_IndividualComplianceSummaryPlusRisk_var_pop_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Countura?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Kccamobile1?: Maybe<Scalars["Float"]>;
  Kccamobile2?: Maybe<Scalars["Float"]>;
  Kccatelephone?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Mobilenumber?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  RiskScore?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Var_Pop_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Countura?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  RiskScore?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Var_Samp_Fields = {
  __typename?: "rtcs_db_RPT_IndividualComplianceSummaryPlusRisk_var_samp_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Countura?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Kccamobile1?: Maybe<Scalars["Float"]>;
  Kccamobile2?: Maybe<Scalars["Float"]>;
  Kccatelephone?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Mobilenumber?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  RiskScore?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Var_Samp_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Countura?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Kccamobile1?: Maybe<Order_By>;
    Kccamobile2?: Maybe<Order_By>;
    Kccatelephone?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Propincincome?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Sumadjustedincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumnonrateablevalue?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** aggregate variance on columns */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Variance_Fields = {
  __typename?: "rtcs_db_RPT_IndividualComplianceSummaryPlusRisk_variance_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Countura?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Kccamobile1?: Maybe<Scalars["Float"]>;
  Kccamobile2?: Maybe<Scalars["Float"]>;
  Kccatelephone?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Mobilenumber?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  RiskScore?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Variance_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Countura?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Kccamobile1?: Maybe<Order_By>;
    Kccamobile2?: Maybe<Order_By>;
    Kccatelephone?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Propincincome?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Sumadjustedincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumnonrateablevalue?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** columns and relationships of "rtcs_db.RPT_IndividualComposite" */
export type Rtcs_Db_Rpt_IndividualComposite = {
  __typename?: "rtcs_db_RPT_IndividualComposite";
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["timestamptz"]>;
  BirthState?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["float8"]>;
  File_x?: Maybe<Scalars["String"]>;
  File_y?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated_x?: Maybe<Scalars["String"]>;
  LastUpdated_y?: Maybe<Scalars["String"]>;
  Line_x?: Maybe<Scalars["float8"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchScoreDetails?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["float8"]>;
  TownName?: Maybe<Scalars["String"]>;
  UraFamilyName?: Maybe<Scalars["String"]>;
  UraFirstName?: Maybe<Scalars["String"]>;
  UraMiddleName?: Maybe<Scalars["String"]>;
  UraOtherName?: Maybe<Scalars["String"]>;
  UraSurName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated_x?: Maybe<Scalars["String"]>;
  WhoUpdated_y?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.RPT_IndividualComposite" */
export type Rtcs_Db_Rpt_IndividualComposite_Aggregate = {
  __typename?: "rtcs_db_RPT_IndividualComposite_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rpt_IndividualComposite>;
};

/** aggregate fields of "rtcs_db.RPT_IndividualComposite" */
export type Rtcs_Db_Rpt_IndividualComposite_Aggregate_Fields = {
  __typename?: "rtcs_db_RPT_IndividualComposite_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.RPT_IndividualComposite" */
export type Rtcs_Db_Rpt_IndividualComposite_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rpt_IndividualComposite_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.RPT_IndividualComposite" */
export type Rtcs_Db_Rpt_IndividualComposite_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.RPT_IndividualComposite" */
export type Rtcs_Db_Rpt_IndividualComposite_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rpt_IndividualComposite_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rpt_IndividualComposite_Avg_Fields = {
  __typename?: "rtcs_db_RPT_IndividualComposite_avg_fields";
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  BirthCity?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Fax?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.RPT_IndividualComposite" */
export type Rtcs_Db_Rpt_IndividualComposite_Avg_Order_By = {
  AdditionalProperties?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.RPT_IndividualComposite". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rpt_IndividualComposite_Bool_Exp = {
  AdditionalProperties?: Maybe<Float8_Comparison_Exp>;
  AppliedForTin?: Maybe<String_Comparison_Exp>;
  AuthctcdEmailId?: Maybe<String_Comparison_Exp>;
  AuthctcdFamilyName?: Maybe<String_Comparison_Exp>;
  AuthctcdFirstName?: Maybe<String_Comparison_Exp>;
  AuthctcdLandlineNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdMobileNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdOtherName?: Maybe<String_Comparison_Exp>;
  AuthctcdPrsnDesgn?: Maybe<String_Comparison_Exp>;
  AuthctcdSurname?: Maybe<String_Comparison_Exp>;
  AuthctcdTin?: Maybe<String_Comparison_Exp>;
  BirthCity?: Maybe<Bigint_Comparison_Exp>;
  BirthCountry?: Maybe<String_Comparison_Exp>;
  BirthDt?: Maybe<Timestamptz_Comparison_Exp>;
  BirthState?: Maybe<String_Comparison_Exp>;
  Brn?: Maybe<String_Comparison_Exp>;
  BsnsCertNumber?: Maybe<String_Comparison_Exp>;
  BsnsName?: Maybe<String_Comparison_Exp>;
  BuildingName?: Maybe<String_Comparison_Exp>;
  BusinessName?: Maybe<String_Comparison_Exp>;
  CitizenCountry?: Maybe<String_Comparison_Exp>;
  Coin?: Maybe<Float8_Comparison_Exp>;
  CountUra?: Maybe<Float8_Comparison_Exp>;
  CreatedBy?: Maybe<Float8_Comparison_Exp>;
  CreatedDate?: Maybe<String_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  DistrictId?: Maybe<Float8_Comparison_Exp>;
  EmailId?: Maybe<String_Comparison_Exp>;
  EntityLegalName?: Maybe<String_Comparison_Exp>;
  ExistTinNo?: Maybe<String_Comparison_Exp>;
  Fax?: Maybe<Float8_Comparison_Exp>;
  File_x?: Maybe<String_Comparison_Exp>;
  File_y?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Fullname?: Maybe<String_Comparison_Exp>;
  Gender?: Maybe<String_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  HomeAddrBuildingname?: Maybe<String_Comparison_Exp>;
  HomeAddrDistrictName?: Maybe<String_Comparison_Exp>;
  HomeAddrLocalCouncil?: Maybe<String_Comparison_Exp>;
  HomeAddrParishName?: Maybe<String_Comparison_Exp>;
  HomeAddrPlotno?: Maybe<String_Comparison_Exp>;
  HomeAddrStrretAddr?: Maybe<String_Comparison_Exp>;
  HomeAddrSubCountyName?: Maybe<String_Comparison_Exp>;
  HomeAddrTownName?: Maybe<String_Comparison_Exp>;
  HomeAddrTradeCentre?: Maybe<String_Comparison_Exp>;
  HomeAddrVillageName?: Maybe<String_Comparison_Exp>;
  IdentificationRefugeeId?: Maybe<String_Comparison_Exp>;
  IdentificationWorkPermit?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcEmp?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  LandlineNumber?: Maybe<String_Comparison_Exp>;
  LastUpdated_x?: Maybe<String_Comparison_Exp>;
  LastUpdated_y?: Maybe<String_Comparison_Exp>;
  Line_x?: Maybe<Float8_Comparison_Exp>;
  Line_y?: Maybe<Float8_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Bigint_Comparison_Exp>;
  MatchScoreAttributes?: Maybe<String_Comparison_Exp>;
  MatchScoreDetails?: Maybe<String_Comparison_Exp>;
  MatchedNiraNin?: Maybe<String_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  MobileNumber?: Maybe<String_Comparison_Exp>;
  NationalId?: Maybe<String_Comparison_Exp>;
  NiraNin?: Maybe<String_Comparison_Exp>;
  NssfId?: Maybe<String_Comparison_Exp>;
  NumProperties?: Maybe<Float8_Comparison_Exp>;
  Photo?: Maybe<Float8_Comparison_Exp>;
  PhotoContent?: Maybe<Float8_Comparison_Exp>;
  PhotoMime?: Maybe<Float8_Comparison_Exp>;
  PhotoName?: Maybe<Float8_Comparison_Exp>;
  PhotoSize?: Maybe<Float8_Comparison_Exp>;
  PostaddrDistictName?: Maybe<String_Comparison_Exp>;
  PostaddrId?: Maybe<String_Comparison_Exp>;
  RefEmailId?: Maybe<String_Comparison_Exp>;
  RefFamilyName?: Maybe<String_Comparison_Exp>;
  RefFirstName?: Maybe<String_Comparison_Exp>;
  RefLandlineNumber?: Maybe<String_Comparison_Exp>;
  RefMobileNumber?: Maybe<String_Comparison_Exp>;
  RefOtherName?: Maybe<String_Comparison_Exp>;
  RefPrsnDesgn?: Maybe<String_Comparison_Exp>;
  RefSurname?: Maybe<String_Comparison_Exp>;
  RefTin?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Float8_Comparison_Exp>;
  SumGrossRentValueAdjusted?: Maybe<Float8_Comparison_Exp>;
  SumNonRateableValue?: Maybe<Float8_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  TaxPayerId?: Maybe<String_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TaxPayerType?: Maybe<String_Comparison_Exp>;
  TownCd?: Maybe<String_Comparison_Exp>;
  TownId?: Maybe<Float8_Comparison_Exp>;
  TownName?: Maybe<String_Comparison_Exp>;
  UraFamilyName?: Maybe<String_Comparison_Exp>;
  UraFirstName?: Maybe<String_Comparison_Exp>;
  UraMiddleName?: Maybe<String_Comparison_Exp>;
  UraOtherName?: Maybe<String_Comparison_Exp>;
  UraSurName?: Maybe<String_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  Uuid?: Maybe<String_Comparison_Exp>;
  WhoUpdated_x?: Maybe<String_Comparison_Exp>;
  WhoUpdated_y?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rpt_IndividualComposite_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rpt_IndividualComposite_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.RPT_IndividualComposite" */
export type Rtcs_Db_Rpt_IndividualComposite_Inc_Input = {
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  Fax?: Maybe<Scalars["float8"]>;
  Line_x?: Maybe<Scalars["float8"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  TownId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.RPT_IndividualComposite" */
export type Rtcs_Db_Rpt_IndividualComposite_Insert_Input = {
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["timestamptz"]>;
  BirthState?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["float8"]>;
  File_x?: Maybe<Scalars["String"]>;
  File_y?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated_x?: Maybe<Scalars["String"]>;
  LastUpdated_y?: Maybe<Scalars["String"]>;
  Line_x?: Maybe<Scalars["float8"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchScoreDetails?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["float8"]>;
  TownName?: Maybe<Scalars["String"]>;
  UraFamilyName?: Maybe<Scalars["String"]>;
  UraFirstName?: Maybe<Scalars["String"]>;
  UraMiddleName?: Maybe<Scalars["String"]>;
  UraOtherName?: Maybe<Scalars["String"]>;
  UraSurName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated_x?: Maybe<Scalars["String"]>;
  WhoUpdated_y?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rpt_IndividualComposite_Max_Fields = {
  __typename?: "rtcs_db_RPT_IndividualComposite_max_fields";
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["timestamptz"]>;
  BirthState?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["float8"]>;
  File_x?: Maybe<Scalars["String"]>;
  File_y?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated_x?: Maybe<Scalars["String"]>;
  LastUpdated_y?: Maybe<Scalars["String"]>;
  Line_x?: Maybe<Scalars["float8"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchScoreDetails?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["float8"]>;
  TownName?: Maybe<Scalars["String"]>;
  UraFamilyName?: Maybe<Scalars["String"]>;
  UraFirstName?: Maybe<Scalars["String"]>;
  UraMiddleName?: Maybe<Scalars["String"]>;
  UraOtherName?: Maybe<Scalars["String"]>;
  UraSurName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated_x?: Maybe<Scalars["String"]>;
  WhoUpdated_y?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.RPT_IndividualComposite" */
export type Rtcs_Db_Rpt_IndividualComposite_Max_Order_By = {
  AdditionalProperties?: Maybe<Order_By>;
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  Brn?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  File_x?: Maybe<Order_By>;
  File_y?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated_x?: Maybe<Order_By>;
  LastUpdated_y?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MatchScoreDetails?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TownCd?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
  TownName?: Maybe<Order_By>;
  UraFamilyName?: Maybe<Order_By>;
  UraFirstName?: Maybe<Order_By>;
  UraMiddleName?: Maybe<Order_By>;
  UraOtherName?: Maybe<Order_By>;
  UraSurName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated_x?: Maybe<Order_By>;
  WhoUpdated_y?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rpt_IndividualComposite_Min_Fields = {
  __typename?: "rtcs_db_RPT_IndividualComposite_min_fields";
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["timestamptz"]>;
  BirthState?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["float8"]>;
  File_x?: Maybe<Scalars["String"]>;
  File_y?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated_x?: Maybe<Scalars["String"]>;
  LastUpdated_y?: Maybe<Scalars["String"]>;
  Line_x?: Maybe<Scalars["float8"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchScoreDetails?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["float8"]>;
  TownName?: Maybe<Scalars["String"]>;
  UraFamilyName?: Maybe<Scalars["String"]>;
  UraFirstName?: Maybe<Scalars["String"]>;
  UraMiddleName?: Maybe<Scalars["String"]>;
  UraOtherName?: Maybe<Scalars["String"]>;
  UraSurName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated_x?: Maybe<Scalars["String"]>;
  WhoUpdated_y?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.RPT_IndividualComposite" */
export type Rtcs_Db_Rpt_IndividualComposite_Min_Order_By = {
  AdditionalProperties?: Maybe<Order_By>;
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  Brn?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  File_x?: Maybe<Order_By>;
  File_y?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated_x?: Maybe<Order_By>;
  LastUpdated_y?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MatchScoreDetails?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TownCd?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
  TownName?: Maybe<Order_By>;
  UraFamilyName?: Maybe<Order_By>;
  UraFirstName?: Maybe<Order_By>;
  UraMiddleName?: Maybe<Order_By>;
  UraOtherName?: Maybe<Order_By>;
  UraSurName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated_x?: Maybe<Order_By>;
  WhoUpdated_y?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.RPT_IndividualComposite" */
export type Rtcs_Db_Rpt_IndividualComposite_Mutation_Response = {
  __typename?: "rtcs_db_RPT_IndividualComposite_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rpt_IndividualComposite>;
};

/** input type for inserting object relation for remote table "rtcs_db.RPT_IndividualComposite" */
export type Rtcs_Db_Rpt_IndividualComposite_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rpt_IndividualComposite_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.RPT_IndividualComposite" */
export type Rtcs_Db_Rpt_IndividualComposite_Order_By = {
  AdditionalProperties?: Maybe<Order_By>;
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  Brn?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  File_x?: Maybe<Order_By>;
  File_y?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated_x?: Maybe<Order_By>;
  LastUpdated_y?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MatchScoreDetails?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TownCd?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
  TownName?: Maybe<Order_By>;
  UraFamilyName?: Maybe<Order_By>;
  UraFirstName?: Maybe<Order_By>;
  UraMiddleName?: Maybe<Order_By>;
  UraOtherName?: Maybe<Order_By>;
  UraSurName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated_x?: Maybe<Order_By>;
  WhoUpdated_y?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.RPT_IndividualComposite" */
export enum Rtcs_Db_Rpt_IndividualComposite_Select_Column {
  /** column name */
  AdditionalProperties = "AdditionalProperties",
  /** column name */
  AppliedForTin = "AppliedForTin",
  /** column name */
  AuthctcdEmailId = "AuthctcdEmailId",
  /** column name */
  AuthctcdFamilyName = "AuthctcdFamilyName",
  /** column name */
  AuthctcdFirstName = "AuthctcdFirstName",
  /** column name */
  AuthctcdLandlineNumber = "AuthctcdLandlineNumber",
  /** column name */
  AuthctcdMobileNumber = "AuthctcdMobileNumber",
  /** column name */
  AuthctcdOtherName = "AuthctcdOtherName",
  /** column name */
  AuthctcdPrsnDesgn = "AuthctcdPrsnDesgn",
  /** column name */
  AuthctcdSurname = "AuthctcdSurname",
  /** column name */
  AuthctcdTin = "AuthctcdTin",
  /** column name */
  BirthCity = "BirthCity",
  /** column name */
  BirthCountry = "BirthCountry",
  /** column name */
  BirthDt = "BirthDt",
  /** column name */
  BirthState = "BirthState",
  /** column name */
  Brn = "Brn",
  /** column name */
  BsnsCertNumber = "BsnsCertNumber",
  /** column name */
  BsnsName = "BsnsName",
  /** column name */
  BuildingName = "BuildingName",
  /** column name */
  BusinessName = "BusinessName",
  /** column name */
  CitizenCountry = "CitizenCountry",
  /** column name */
  Coin = "Coin",
  /** column name */
  CountUra = "CountUra",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DistrictId = "DistrictId",
  /** column name */
  EmailId = "EmailId",
  /** column name */
  EntityLegalName = "EntityLegalName",
  /** column name */
  ExistTinNo = "ExistTinNo",
  /** column name */
  Fax = "Fax",
  /** column name */
  FileX = "File_x",
  /** column name */
  FileY = "File_y",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Fullname = "Fullname",
  /** column name */
  Gender = "Gender",
  /** column name */
  Gml = "Gml",
  /** column name */
  HomeAddrBuildingname = "HomeAddrBuildingname",
  /** column name */
  HomeAddrDistrictName = "HomeAddrDistrictName",
  /** column name */
  HomeAddrLocalCouncil = "HomeAddrLocalCouncil",
  /** column name */
  HomeAddrParishName = "HomeAddrParishName",
  /** column name */
  HomeAddrPlotno = "HomeAddrPlotno",
  /** column name */
  HomeAddrStrretAddr = "HomeAddrStrretAddr",
  /** column name */
  HomeAddrSubCountyName = "HomeAddrSubCountyName",
  /** column name */
  HomeAddrTownName = "HomeAddrTownName",
  /** column name */
  HomeAddrTradeCentre = "HomeAddrTradeCentre",
  /** column name */
  HomeAddrVillageName = "HomeAddrVillageName",
  /** column name */
  IdentificationRefugeeId = "IdentificationRefugeeId",
  /** column name */
  IdentificationWorkPermit = "IdentificationWorkPermit",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcEmp = "IsIncomeSrcEmp",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  LandlineNumber = "LandlineNumber",
  /** column name */
  LastUpdatedX = "LastUpdated_x",
  /** column name */
  LastUpdatedY = "LastUpdated_y",
  /** column name */
  LineX = "Line_x",
  /** column name */
  LineY = "Line_y",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MatchScoreAttributes = "MatchScoreAttributes",
  /** column name */
  MatchScoreDetails = "MatchScoreDetails",
  /** column name */
  MatchedNiraNin = "MatchedNiraNin",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  NationalId = "NationalId",
  /** column name */
  NiraNin = "NiraNin",
  /** column name */
  NssfId = "NssfId",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  Photo = "Photo",
  /** column name */
  PhotoContent = "PhotoContent",
  /** column name */
  PhotoMime = "PhotoMime",
  /** column name */
  PhotoName = "PhotoName",
  /** column name */
  PhotoSize = "PhotoSize",
  /** column name */
  PostaddrDistictName = "PostaddrDistictName",
  /** column name */
  PostaddrId = "PostaddrId",
  /** column name */
  RefEmailId = "RefEmailId",
  /** column name */
  RefFamilyName = "RefFamilyName",
  /** column name */
  RefFirstName = "RefFirstName",
  /** column name */
  RefLandlineNumber = "RefLandlineNumber",
  /** column name */
  RefMobileNumber = "RefMobileNumber",
  /** column name */
  RefOtherName = "RefOtherName",
  /** column name */
  RefPrsnDesgn = "RefPrsnDesgn",
  /** column name */
  RefSurname = "RefSurname",
  /** column name */
  RefTin = "RefTin",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Source = "Source",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  SumGrossRentValueAdjusted = "SumGrossRentValueAdjusted",
  /** column name */
  SumNonRateableValue = "SumNonRateableValue",
  /** column name */
  Surname = "Surname",
  /** column name */
  TaxPayerId = "TaxPayerId",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TaxPayerType = "TaxPayerType",
  /** column name */
  TownCd = "TownCd",
  /** column name */
  TownId = "TownId",
  /** column name */
  TownName = "TownName",
  /** column name */
  UraFamilyName = "UraFamilyName",
  /** column name */
  UraFirstName = "UraFirstName",
  /** column name */
  UraMiddleName = "UraMiddleName",
  /** column name */
  UraOtherName = "UraOtherName",
  /** column name */
  UraSurName = "UraSurName",
  /** column name */
  UraTinNo = "UraTinNo",
  /** column name */
  Uuid = "Uuid",
  /** column name */
  WhoUpdatedX = "WhoUpdated_x",
  /** column name */
  WhoUpdatedY = "WhoUpdated_y",
}

/** input type for updating data in table "rtcs_db.RPT_IndividualComposite" */
export type Rtcs_Db_Rpt_IndividualComposite_Set_Input = {
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["timestamptz"]>;
  BirthState?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["float8"]>;
  File_x?: Maybe<Scalars["String"]>;
  File_y?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated_x?: Maybe<Scalars["String"]>;
  LastUpdated_y?: Maybe<Scalars["String"]>;
  Line_x?: Maybe<Scalars["float8"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchScoreDetails?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["float8"]>;
  TownName?: Maybe<Scalars["String"]>;
  UraFamilyName?: Maybe<Scalars["String"]>;
  UraFirstName?: Maybe<Scalars["String"]>;
  UraMiddleName?: Maybe<Scalars["String"]>;
  UraOtherName?: Maybe<Scalars["String"]>;
  UraSurName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated_x?: Maybe<Scalars["String"]>;
  WhoUpdated_y?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rpt_IndividualComposite_Stddev_Fields = {
  __typename?: "rtcs_db_RPT_IndividualComposite_stddev_fields";
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  BirthCity?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Fax?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.RPT_IndividualComposite" */
export type Rtcs_Db_Rpt_IndividualComposite_Stddev_Order_By = {
  AdditionalProperties?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rpt_IndividualComposite_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_RPT_IndividualComposite_stddev_pop_fields";
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  BirthCity?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Fax?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.RPT_IndividualComposite" */
export type Rtcs_Db_Rpt_IndividualComposite_Stddev_Pop_Order_By = {
  AdditionalProperties?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rpt_IndividualComposite_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_RPT_IndividualComposite_stddev_samp_fields";
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  BirthCity?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Fax?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.RPT_IndividualComposite" */
export type Rtcs_Db_Rpt_IndividualComposite_Stddev_Samp_Order_By = {
  AdditionalProperties?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rpt_IndividualComposite_Sum_Fields = {
  __typename?: "rtcs_db_RPT_IndividualComposite_sum_fields";
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  Fax?: Maybe<Scalars["float8"]>;
  Line_x?: Maybe<Scalars["float8"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  TownId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.RPT_IndividualComposite" */
export type Rtcs_Db_Rpt_IndividualComposite_Sum_Order_By = {
  AdditionalProperties?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rpt_IndividualComposite_Var_Pop_Fields = {
  __typename?: "rtcs_db_RPT_IndividualComposite_var_pop_fields";
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  BirthCity?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Fax?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.RPT_IndividualComposite" */
export type Rtcs_Db_Rpt_IndividualComposite_Var_Pop_Order_By = {
  AdditionalProperties?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rpt_IndividualComposite_Var_Samp_Fields = {
  __typename?: "rtcs_db_RPT_IndividualComposite_var_samp_fields";
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  BirthCity?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Fax?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.RPT_IndividualComposite" */
export type Rtcs_Db_Rpt_IndividualComposite_Var_Samp_Order_By = {
  AdditionalProperties?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rpt_IndividualComposite_Variance_Fields = {
  __typename?: "rtcs_db_RPT_IndividualComposite_variance_fields";
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  BirthCity?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Fax?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.RPT_IndividualComposite" */
export type Rtcs_Db_Rpt_IndividualComposite_Variance_Order_By = {
  AdditionalProperties?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.RPT_IndividualRegistration" */
export type Rtcs_Db_Rpt_IndividualRegistration = {
  __typename?: "rtcs_db_RPT_IndividualRegistration";
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["timestamptz"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["float8"]>;
  TownName?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.RPT_IndividualRegistration" */
export type Rtcs_Db_Rpt_IndividualRegistration_Aggregate = {
  __typename?: "rtcs_db_RPT_IndividualRegistration_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rpt_IndividualRegistration>;
};

/** aggregate fields of "rtcs_db.RPT_IndividualRegistration" */
export type Rtcs_Db_Rpt_IndividualRegistration_Aggregate_Fields = {
  __typename?: "rtcs_db_RPT_IndividualRegistration_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.RPT_IndividualRegistration" */
export type Rtcs_Db_Rpt_IndividualRegistration_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rpt_IndividualRegistration_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.RPT_IndividualRegistration" */
export type Rtcs_Db_Rpt_IndividualRegistration_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.RPT_IndividualRegistration" */
export type Rtcs_Db_Rpt_IndividualRegistration_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rpt_IndividualRegistration_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rpt_IndividualRegistration_Avg_Fields = {
  __typename?: "rtcs_db_RPT_IndividualRegistration_avg_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.RPT_IndividualRegistration" */
export type Rtcs_Db_Rpt_IndividualRegistration_Avg_Order_By = {
  BirthCity?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.RPT_IndividualRegistration". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rpt_IndividualRegistration_Bool_Exp = {
  AppliedForTin?: Maybe<String_Comparison_Exp>;
  AuthctcdEmailId?: Maybe<String_Comparison_Exp>;
  AuthctcdFamilyName?: Maybe<String_Comparison_Exp>;
  AuthctcdFirstName?: Maybe<String_Comparison_Exp>;
  AuthctcdLandlineNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdMobileNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdOtherName?: Maybe<String_Comparison_Exp>;
  AuthctcdPrsnDesgn?: Maybe<String_Comparison_Exp>;
  AuthctcdSurname?: Maybe<String_Comparison_Exp>;
  AuthctcdTin?: Maybe<String_Comparison_Exp>;
  BirthCity?: Maybe<Bigint_Comparison_Exp>;
  BirthCountry?: Maybe<String_Comparison_Exp>;
  BirthDt?: Maybe<Timestamptz_Comparison_Exp>;
  BirthState?: Maybe<String_Comparison_Exp>;
  BsnsCertNumber?: Maybe<String_Comparison_Exp>;
  BsnsName?: Maybe<String_Comparison_Exp>;
  CitizenCountry?: Maybe<String_Comparison_Exp>;
  DistrictId?: Maybe<Float8_Comparison_Exp>;
  EmailId?: Maybe<String_Comparison_Exp>;
  ExistTinNo?: Maybe<String_Comparison_Exp>;
  FamilyName?: Maybe<String_Comparison_Exp>;
  FirstName?: Maybe<String_Comparison_Exp>;
  FullName?: Maybe<String_Comparison_Exp>;
  Gender?: Maybe<String_Comparison_Exp>;
  HomeAddrBuildingname?: Maybe<String_Comparison_Exp>;
  HomeAddrDistrictName?: Maybe<String_Comparison_Exp>;
  HomeAddrLocalCouncil?: Maybe<String_Comparison_Exp>;
  HomeAddrParishName?: Maybe<String_Comparison_Exp>;
  HomeAddrPlotno?: Maybe<String_Comparison_Exp>;
  HomeAddrStrretAddr?: Maybe<String_Comparison_Exp>;
  HomeAddrSubCountyName?: Maybe<String_Comparison_Exp>;
  HomeAddrTownName?: Maybe<String_Comparison_Exp>;
  HomeAddrTradeCentre?: Maybe<String_Comparison_Exp>;
  HomeAddrVillageName?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcEmp?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  LandlineNumber?: Maybe<String_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  MobileNumber?: Maybe<String_Comparison_Exp>;
  NationalId?: Maybe<String_Comparison_Exp>;
  NssfId?: Maybe<String_Comparison_Exp>;
  OtherName?: Maybe<String_Comparison_Exp>;
  PostaddrDistictName?: Maybe<String_Comparison_Exp>;
  PostaddrId?: Maybe<String_Comparison_Exp>;
  RefEmailId?: Maybe<String_Comparison_Exp>;
  RefFamilyName?: Maybe<String_Comparison_Exp>;
  RefFirstName?: Maybe<String_Comparison_Exp>;
  RefLandlineNumber?: Maybe<String_Comparison_Exp>;
  RefMobileNumber?: Maybe<String_Comparison_Exp>;
  RefOtherName?: Maybe<String_Comparison_Exp>;
  RefPrsnDesgn?: Maybe<String_Comparison_Exp>;
  RefSurname?: Maybe<String_Comparison_Exp>;
  RefTin?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  SurName?: Maybe<String_Comparison_Exp>;
  TaxPayerId?: Maybe<String_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TaxPayerType?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  TownCd?: Maybe<String_Comparison_Exp>;
  TownId?: Maybe<Float8_Comparison_Exp>;
  TownName?: Maybe<String_Comparison_Exp>;
  Uuid?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rpt_IndividualRegistration_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rpt_IndividualRegistration_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.RPT_IndividualRegistration" */
export type Rtcs_Db_Rpt_IndividualRegistration_Inc_Input = {
  BirthCity?: Maybe<Scalars["bigint"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  TownId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.RPT_IndividualRegistration" */
export type Rtcs_Db_Rpt_IndividualRegistration_Insert_Input = {
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["timestamptz"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["float8"]>;
  TownName?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rpt_IndividualRegistration_Max_Fields = {
  __typename?: "rtcs_db_RPT_IndividualRegistration_max_fields";
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["timestamptz"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["float8"]>;
  TownName?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.RPT_IndividualRegistration" */
export type Rtcs_Db_Rpt_IndividualRegistration_Max_Order_By = {
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SurName?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TownCd?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
  TownName?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rpt_IndividualRegistration_Min_Fields = {
  __typename?: "rtcs_db_RPT_IndividualRegistration_min_fields";
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["timestamptz"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["float8"]>;
  TownName?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.RPT_IndividualRegistration" */
export type Rtcs_Db_Rpt_IndividualRegistration_Min_Order_By = {
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SurName?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TownCd?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
  TownName?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.RPT_IndividualRegistration" */
export type Rtcs_Db_Rpt_IndividualRegistration_Mutation_Response = {
  __typename?: "rtcs_db_RPT_IndividualRegistration_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rpt_IndividualRegistration>;
};

/** input type for inserting object relation for remote table "rtcs_db.RPT_IndividualRegistration" */
export type Rtcs_Db_Rpt_IndividualRegistration_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rpt_IndividualRegistration_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.RPT_IndividualRegistration" */
export type Rtcs_Db_Rpt_IndividualRegistration_Order_By = {
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SurName?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TownCd?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
  TownName?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.RPT_IndividualRegistration" */
export enum Rtcs_Db_Rpt_IndividualRegistration_Select_Column {
  /** column name */
  AppliedForTin = "AppliedForTin",
  /** column name */
  AuthctcdEmailId = "AuthctcdEmailId",
  /** column name */
  AuthctcdFamilyName = "AuthctcdFamilyName",
  /** column name */
  AuthctcdFirstName = "AuthctcdFirstName",
  /** column name */
  AuthctcdLandlineNumber = "AuthctcdLandlineNumber",
  /** column name */
  AuthctcdMobileNumber = "AuthctcdMobileNumber",
  /** column name */
  AuthctcdOtherName = "AuthctcdOtherName",
  /** column name */
  AuthctcdPrsnDesgn = "AuthctcdPrsnDesgn",
  /** column name */
  AuthctcdSurname = "AuthctcdSurname",
  /** column name */
  AuthctcdTin = "AuthctcdTin",
  /** column name */
  BirthCity = "BirthCity",
  /** column name */
  BirthCountry = "BirthCountry",
  /** column name */
  BirthDt = "BirthDt",
  /** column name */
  BirthState = "BirthState",
  /** column name */
  BsnsCertNumber = "BsnsCertNumber",
  /** column name */
  BsnsName = "BsnsName",
  /** column name */
  CitizenCountry = "CitizenCountry",
  /** column name */
  DistrictId = "DistrictId",
  /** column name */
  EmailId = "EmailId",
  /** column name */
  ExistTinNo = "ExistTinNo",
  /** column name */
  FamilyName = "FamilyName",
  /** column name */
  FirstName = "FirstName",
  /** column name */
  FullName = "FullName",
  /** column name */
  Gender = "Gender",
  /** column name */
  HomeAddrBuildingname = "HomeAddrBuildingname",
  /** column name */
  HomeAddrDistrictName = "HomeAddrDistrictName",
  /** column name */
  HomeAddrLocalCouncil = "HomeAddrLocalCouncil",
  /** column name */
  HomeAddrParishName = "HomeAddrParishName",
  /** column name */
  HomeAddrPlotno = "HomeAddrPlotno",
  /** column name */
  HomeAddrStrretAddr = "HomeAddrStrretAddr",
  /** column name */
  HomeAddrSubCountyName = "HomeAddrSubCountyName",
  /** column name */
  HomeAddrTownName = "HomeAddrTownName",
  /** column name */
  HomeAddrTradeCentre = "HomeAddrTradeCentre",
  /** column name */
  HomeAddrVillageName = "HomeAddrVillageName",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcEmp = "IsIncomeSrcEmp",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  LandlineNumber = "LandlineNumber",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  NationalId = "NationalId",
  /** column name */
  NssfId = "NssfId",
  /** column name */
  OtherName = "OtherName",
  /** column name */
  PostaddrDistictName = "PostaddrDistictName",
  /** column name */
  PostaddrId = "PostaddrId",
  /** column name */
  RefEmailId = "RefEmailId",
  /** column name */
  RefFamilyName = "RefFamilyName",
  /** column name */
  RefFirstName = "RefFirstName",
  /** column name */
  RefLandlineNumber = "RefLandlineNumber",
  /** column name */
  RefMobileNumber = "RefMobileNumber",
  /** column name */
  RefOtherName = "RefOtherName",
  /** column name */
  RefPrsnDesgn = "RefPrsnDesgn",
  /** column name */
  RefSurname = "RefSurname",
  /** column name */
  RefTin = "RefTin",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  SurName = "SurName",
  /** column name */
  TaxPayerId = "TaxPayerId",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TaxPayerType = "TaxPayerType",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  TownCd = "TownCd",
  /** column name */
  TownId = "TownId",
  /** column name */
  TownName = "TownName",
  /** column name */
  Uuid = "Uuid",
}

/** input type for updating data in table "rtcs_db.RPT_IndividualRegistration" */
export type Rtcs_Db_Rpt_IndividualRegistration_Set_Input = {
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["timestamptz"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["float8"]>;
  TownName?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rpt_IndividualRegistration_Stddev_Fields = {
  __typename?: "rtcs_db_RPT_IndividualRegistration_stddev_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.RPT_IndividualRegistration" */
export type Rtcs_Db_Rpt_IndividualRegistration_Stddev_Order_By = {
  BirthCity?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rpt_IndividualRegistration_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_RPT_IndividualRegistration_stddev_pop_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.RPT_IndividualRegistration" */
export type Rtcs_Db_Rpt_IndividualRegistration_Stddev_Pop_Order_By = {
  BirthCity?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rpt_IndividualRegistration_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_RPT_IndividualRegistration_stddev_samp_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.RPT_IndividualRegistration" */
export type Rtcs_Db_Rpt_IndividualRegistration_Stddev_Samp_Order_By = {
  BirthCity?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rpt_IndividualRegistration_Sum_Fields = {
  __typename?: "rtcs_db_RPT_IndividualRegistration_sum_fields";
  BirthCity?: Maybe<Scalars["bigint"]>;
  DistrictId?: Maybe<Scalars["float8"]>;
  TownId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.RPT_IndividualRegistration" */
export type Rtcs_Db_Rpt_IndividualRegistration_Sum_Order_By = {
  BirthCity?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rpt_IndividualRegistration_Var_Pop_Fields = {
  __typename?: "rtcs_db_RPT_IndividualRegistration_var_pop_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.RPT_IndividualRegistration" */
export type Rtcs_Db_Rpt_IndividualRegistration_Var_Pop_Order_By = {
  BirthCity?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rpt_IndividualRegistration_Var_Samp_Fields = {
  __typename?: "rtcs_db_RPT_IndividualRegistration_var_samp_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.RPT_IndividualRegistration" */
export type Rtcs_Db_Rpt_IndividualRegistration_Var_Samp_Order_By = {
  BirthCity?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rpt_IndividualRegistration_Variance_Fields = {
  __typename?: "rtcs_db_RPT_IndividualRegistration_variance_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.RPT_IndividualRegistration" */
export type Rtcs_Db_Rpt_IndividualRegistration_Variance_Order_By = {
  BirthCity?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData = {
  __typename?: "rtcs_db_RPT_IndividualTaxSummaryData";
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["float8"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["String"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["float8"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  RentalIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Aggregate = {
  __typename?: "rtcs_db_RPT_IndividualTaxSummaryData_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rpt_IndividualTaxSummaryData>;
};

/** aggregate fields of "rtcs_db.RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Aggregate_Fields = {
  __typename?: "rtcs_db_RPT_IndividualTaxSummaryData_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rpt_IndividualTaxSummaryData_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rpt_IndividualTaxSummaryData_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Avg_Fields = {
  __typename?: "rtcs_db_RPT_IndividualTaxSummaryData_avg_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentalIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Avg_Order_By = {
  BirthCity?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentalIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.RPT_IndividualTaxSummaryData". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Bool_Exp = {
  AppliedForTin?: Maybe<String_Comparison_Exp>;
  AuthctcdEmailId?: Maybe<String_Comparison_Exp>;
  AuthctcdFamilyName?: Maybe<String_Comparison_Exp>;
  AuthctcdFirstName?: Maybe<String_Comparison_Exp>;
  AuthctcdLandlineNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdMobileNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdOtherName?: Maybe<String_Comparison_Exp>;
  AuthctcdPrsnDesgn?: Maybe<String_Comparison_Exp>;
  AuthctcdSurname?: Maybe<String_Comparison_Exp>;
  AuthctcdTin?: Maybe<String_Comparison_Exp>;
  BirthCity?: Maybe<Float8_Comparison_Exp>;
  BirthCountry?: Maybe<String_Comparison_Exp>;
  BirthDt?: Maybe<String_Comparison_Exp>;
  BirthState?: Maybe<String_Comparison_Exp>;
  BsnsCertNumber?: Maybe<String_Comparison_Exp>;
  BsnsName?: Maybe<String_Comparison_Exp>;
  ChargeableIncome?: Maybe<Float8_Comparison_Exp>;
  ChargeableIncomeP?: Maybe<Float8_Comparison_Exp>;
  ChrgdRentalIncm?: Maybe<Float8_Comparison_Exp>;
  CitizenCountry?: Maybe<String_Comparison_Exp>;
  DeductIncmTax?: Maybe<Float8_Comparison_Exp>;
  EmailId?: Maybe<String_Comparison_Exp>;
  EmplymntIncome?: Maybe<Float8_Comparison_Exp>;
  EmplymntRate?: Maybe<Float8_Comparison_Exp>;
  EmplymntTax?: Maybe<Float8_Comparison_Exp>;
  ExistTinNo?: Maybe<String_Comparison_Exp>;
  FamilyName?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FirstName?: Maybe<String_Comparison_Exp>;
  Gender?: Maybe<String_Comparison_Exp>;
  GrossRentalIncm?: Maybe<Float8_Comparison_Exp>;
  GrossRentalIncome?: Maybe<Float8_Comparison_Exp>;
  HomeAddrBuildingname?: Maybe<String_Comparison_Exp>;
  HomeAddrDistrictName?: Maybe<String_Comparison_Exp>;
  HomeAddrLocalCouncil?: Maybe<String_Comparison_Exp>;
  HomeAddrParishName?: Maybe<String_Comparison_Exp>;
  HomeAddrPlotno?: Maybe<String_Comparison_Exp>;
  HomeAddrStrretAddr?: Maybe<String_Comparison_Exp>;
  HomeAddrSubCountyName?: Maybe<String_Comparison_Exp>;
  HomeAddrTownName?: Maybe<String_Comparison_Exp>;
  HomeAddrTradeCentre?: Maybe<String_Comparison_Exp>;
  HomeAddrVillageName?: Maybe<String_Comparison_Exp>;
  IsAmendment?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcEmp?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  IsResident?: Maybe<String_Comparison_Exp>;
  LandlineNumber?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Float8_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  MobileNumber?: Maybe<String_Comparison_Exp>;
  MotgageIntDecd?: Maybe<Float8_Comparison_Exp>;
  NationalId?: Maybe<String_Comparison_Exp>;
  NetRentIncm?: Maybe<Float8_Comparison_Exp>;
  NssfId?: Maybe<String_Comparison_Exp>;
  OtherName?: Maybe<String_Comparison_Exp>;
  PartnerRentIncome?: Maybe<Float8_Comparison_Exp>;
  PostaddrDistictName?: Maybe<String_Comparison_Exp>;
  PostaddrId?: Maybe<String_Comparison_Exp>;
  PropincIncome?: Maybe<Float8_Comparison_Exp>;
  PropincRate?: Maybe<Float8_Comparison_Exp>;
  PropincTax?: Maybe<Float8_Comparison_Exp>;
  RefEmailId?: Maybe<String_Comparison_Exp>;
  RefFamilyName?: Maybe<String_Comparison_Exp>;
  RefFirstName?: Maybe<String_Comparison_Exp>;
  RefLandlineNumber?: Maybe<String_Comparison_Exp>;
  RefMobileNumber?: Maybe<String_Comparison_Exp>;
  RefOtherName?: Maybe<String_Comparison_Exp>;
  RefPrsnDesgn?: Maybe<String_Comparison_Exp>;
  RefSurname?: Maybe<String_Comparison_Exp>;
  RefTin?: Maybe<String_Comparison_Exp>;
  RentalIncomeP?: Maybe<Float8_Comparison_Exp>;
  RentincRate?: Maybe<Float8_Comparison_Exp>;
  RentincTax?: Maybe<Float8_Comparison_Exp>;
  ReturnType?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  RtnDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnFromDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnPeriodYear?: Maybe<String_Comparison_Exp>;
  RtnPeriodYearGenerated?: Maybe<String_Comparison_Exp>;
  RtnToDt?: Maybe<String_Comparison_Exp>;
  SurName?: Maybe<String_Comparison_Exp>;
  TaxAssessed?: Maybe<Float8_Comparison_Exp>;
  TaxPayerId?: Maybe<String_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TaxPayerType?: Maybe<String_Comparison_Exp>;
  ThresholdAmnt?: Maybe<Float8_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  TotIncome?: Maybe<Float8_Comparison_Exp>;
  TotRate?: Maybe<Float8_Comparison_Exp>;
  TotRentalIncm?: Maybe<Float8_Comparison_Exp>;
  TotTax?: Maybe<Float8_Comparison_Exp>;
  Uuid?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Inc_Input = {
  BirthCity?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["float8"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RentalIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Insert_Input = {
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["float8"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["String"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["float8"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  RentalIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Max_Fields = {
  __typename?: "rtcs_db_RPT_IndividualTaxSummaryData_max_fields";
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["float8"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["String"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["float8"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  RentalIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Max_Order_By = {
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  ChrgdRentalIncm?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  RentalIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnPeriodYearGenerated?: Maybe<Order_By>;
  RtnToDt?: Maybe<Order_By>;
  SurName?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Min_Fields = {
  __typename?: "rtcs_db_RPT_IndividualTaxSummaryData_min_fields";
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["float8"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["String"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["float8"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  RentalIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Min_Order_By = {
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  ChrgdRentalIncm?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  RentalIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnPeriodYearGenerated?: Maybe<Order_By>;
  RtnToDt?: Maybe<Order_By>;
  SurName?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Mutation_Response = {
  __typename?: "rtcs_db_RPT_IndividualTaxSummaryData_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rpt_IndividualTaxSummaryData>;
};

/** input type for inserting object relation for remote table "rtcs_db.RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rpt_IndividualTaxSummaryData_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Order_By = {
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  ChrgdRentalIncm?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  RentalIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnPeriodYearGenerated?: Maybe<Order_By>;
  RtnToDt?: Maybe<Order_By>;
  SurName?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.RPT_IndividualTaxSummaryData" */
export enum Rtcs_Db_Rpt_IndividualTaxSummaryData_Select_Column {
  /** column name */
  AppliedForTin = "AppliedForTin",
  /** column name */
  AuthctcdEmailId = "AuthctcdEmailId",
  /** column name */
  AuthctcdFamilyName = "AuthctcdFamilyName",
  /** column name */
  AuthctcdFirstName = "AuthctcdFirstName",
  /** column name */
  AuthctcdLandlineNumber = "AuthctcdLandlineNumber",
  /** column name */
  AuthctcdMobileNumber = "AuthctcdMobileNumber",
  /** column name */
  AuthctcdOtherName = "AuthctcdOtherName",
  /** column name */
  AuthctcdPrsnDesgn = "AuthctcdPrsnDesgn",
  /** column name */
  AuthctcdSurname = "AuthctcdSurname",
  /** column name */
  AuthctcdTin = "AuthctcdTin",
  /** column name */
  BirthCity = "BirthCity",
  /** column name */
  BirthCountry = "BirthCountry",
  /** column name */
  BirthDt = "BirthDt",
  /** column name */
  BirthState = "BirthState",
  /** column name */
  BsnsCertNumber = "BsnsCertNumber",
  /** column name */
  BsnsName = "BsnsName",
  /** column name */
  ChargeableIncome = "ChargeableIncome",
  /** column name */
  ChargeableIncomeP = "ChargeableIncomeP",
  /** column name */
  ChrgdRentalIncm = "ChrgdRentalIncm",
  /** column name */
  CitizenCountry = "CitizenCountry",
  /** column name */
  DeductIncmTax = "DeductIncmTax",
  /** column name */
  EmailId = "EmailId",
  /** column name */
  EmplymntIncome = "EmplymntIncome",
  /** column name */
  EmplymntRate = "EmplymntRate",
  /** column name */
  EmplymntTax = "EmplymntTax",
  /** column name */
  ExistTinNo = "ExistTinNo",
  /** column name */
  FamilyName = "FamilyName",
  /** column name */
  File = "File",
  /** column name */
  FirstName = "FirstName",
  /** column name */
  Gender = "Gender",
  /** column name */
  GrossRentalIncm = "GrossRentalIncm",
  /** column name */
  GrossRentalIncome = "GrossRentalIncome",
  /** column name */
  HomeAddrBuildingname = "HomeAddrBuildingname",
  /** column name */
  HomeAddrDistrictName = "HomeAddrDistrictName",
  /** column name */
  HomeAddrLocalCouncil = "HomeAddrLocalCouncil",
  /** column name */
  HomeAddrParishName = "HomeAddrParishName",
  /** column name */
  HomeAddrPlotno = "HomeAddrPlotno",
  /** column name */
  HomeAddrStrretAddr = "HomeAddrStrretAddr",
  /** column name */
  HomeAddrSubCountyName = "HomeAddrSubCountyName",
  /** column name */
  HomeAddrTownName = "HomeAddrTownName",
  /** column name */
  HomeAddrTradeCentre = "HomeAddrTradeCentre",
  /** column name */
  HomeAddrVillageName = "HomeAddrVillageName",
  /** column name */
  IsAmendment = "IsAmendment",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcEmp = "IsIncomeSrcEmp",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  IsResident = "IsResident",
  /** column name */
  LandlineNumber = "LandlineNumber",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  MotgageIntDecd = "MotgageIntDecd",
  /** column name */
  NationalId = "NationalId",
  /** column name */
  NetRentIncm = "NetRentIncm",
  /** column name */
  NssfId = "NssfId",
  /** column name */
  OtherName = "OtherName",
  /** column name */
  PartnerRentIncome = "PartnerRentIncome",
  /** column name */
  PostaddrDistictName = "PostaddrDistictName",
  /** column name */
  PostaddrId = "PostaddrId",
  /** column name */
  PropincIncome = "PropincIncome",
  /** column name */
  PropincRate = "PropincRate",
  /** column name */
  PropincTax = "PropincTax",
  /** column name */
  RefEmailId = "RefEmailId",
  /** column name */
  RefFamilyName = "RefFamilyName",
  /** column name */
  RefFirstName = "RefFirstName",
  /** column name */
  RefLandlineNumber = "RefLandlineNumber",
  /** column name */
  RefMobileNumber = "RefMobileNumber",
  /** column name */
  RefOtherName = "RefOtherName",
  /** column name */
  RefPrsnDesgn = "RefPrsnDesgn",
  /** column name */
  RefSurname = "RefSurname",
  /** column name */
  RefTin = "RefTin",
  /** column name */
  RentalIncomeP = "RentalIncomeP",
  /** column name */
  RentincRate = "RentincRate",
  /** column name */
  RentincTax = "RentincTax",
  /** column name */
  ReturnType = "ReturnType",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  RtnDt = "RtnDt",
  /** column name */
  RtnFromDt = "RtnFromDt",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnPeriodYear = "RtnPeriodYear",
  /** column name */
  RtnPeriodYearGenerated = "RtnPeriodYearGenerated",
  /** column name */
  RtnToDt = "RtnToDt",
  /** column name */
  SurName = "SurName",
  /** column name */
  TaxAssessed = "TaxAssessed",
  /** column name */
  TaxPayerId = "TaxPayerId",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TaxPayerType = "TaxPayerType",
  /** column name */
  ThresholdAmnt = "ThresholdAmnt",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  TotIncome = "TotIncome",
  /** column name */
  TotRate = "TotRate",
  /** column name */
  TotRentalIncm = "TotRentalIncm",
  /** column name */
  TotTax = "TotTax",
  /** column name */
  Uuid = "Uuid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Set_Input = {
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["float8"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["String"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["float8"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  RentalIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Stddev_Fields = {
  __typename?: "rtcs_db_RPT_IndividualTaxSummaryData_stddev_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentalIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Stddev_Order_By = {
  BirthCity?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentalIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_RPT_IndividualTaxSummaryData_stddev_pop_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentalIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Stddev_Pop_Order_By = {
  BirthCity?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentalIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_RPT_IndividualTaxSummaryData_stddev_samp_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentalIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Stddev_Samp_Order_By = {
  BirthCity?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentalIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Sum_Fields = {
  __typename?: "rtcs_db_RPT_IndividualTaxSummaryData_sum_fields";
  BirthCity?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["float8"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RentalIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Sum_Order_By = {
  BirthCity?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentalIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Var_Pop_Fields = {
  __typename?: "rtcs_db_RPT_IndividualTaxSummaryData_var_pop_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentalIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Var_Pop_Order_By = {
  BirthCity?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentalIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Var_Samp_Fields = {
  __typename?: "rtcs_db_RPT_IndividualTaxSummaryData_var_samp_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentalIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Var_Samp_Order_By = {
  BirthCity?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentalIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Variance_Fields = {
  __typename?: "rtcs_db_RPT_IndividualTaxSummaryData_variance_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentalIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.RPT_IndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_IndividualTaxSummaryData_Variance_Order_By = {
  BirthCity?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentalIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.RPT_KccaAffiliates" */
export type Rtcs_Db_Rpt_KccaAffiliates = {
  __typename?: "rtcs_db_RPT_KccaAffiliates";
  AcquiredName?: Maybe<Scalars["String"]>;
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["float8"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["float8"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["float8"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["float8"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["float8"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerGuardianCoin?: Maybe<Scalars["float8"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["float8"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["float8"]>;
  CustomerGuardianSurname?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  CustomerReferenceCoin?: Maybe<Scalars["float8"]>;
  CustomerReferenceEmail?: Maybe<Scalars["String"]>;
  CustomerReferenceFirstname?: Maybe<Scalars["String"]>;
  CustomerReferenceMiddleName?: Maybe<Scalars["String"]>;
  CustomerReferenceMobile?: Maybe<Scalars["String"]>;
  CustomerReferenceModifiedBy?: Maybe<Scalars["String"]>;
  CustomerReferenceModifiedDate?: Maybe<Scalars["String"]>;
  CustomerReferenceRelationshipDesignation?: Maybe<Scalars["String"]>;
  CustomerReferenceSurname?: Maybe<Scalars["String"]>;
  CustomerReferenceTelephone?: Maybe<Scalars["String"]>;
  EffectDate?: Maybe<Scalars["bigint"]>;
  IdentificationNumber?: Maybe<Scalars["float8"]>;
  IdentificationTypeId?: Maybe<Scalars["float8"]>;
  MotherMaidenName?: Maybe<Scalars["float8"]>;
  OfficialDesignation?: Maybe<Scalars["float8"]>;
  OtherTitle?: Maybe<Scalars["float8"]>;
};

/** aggregated selection of "rtcs_db.RPT_KccaAffiliates" */
export type Rtcs_Db_Rpt_KccaAffiliates_Aggregate = {
  __typename?: "rtcs_db_RPT_KccaAffiliates_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rpt_KccaAffiliates>;
};

/** aggregate fields of "rtcs_db.RPT_KccaAffiliates" */
export type Rtcs_Db_Rpt_KccaAffiliates_Aggregate_Fields = {
  __typename?: "rtcs_db_RPT_KccaAffiliates_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.RPT_KccaAffiliates" */
export type Rtcs_Db_Rpt_KccaAffiliates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rpt_KccaAffiliates_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.RPT_KccaAffiliates" */
export type Rtcs_Db_Rpt_KccaAffiliates_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.RPT_KccaAffiliates" */
export type Rtcs_Db_Rpt_KccaAffiliates_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rpt_KccaAffiliates_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rpt_KccaAffiliates_Avg_Fields = {
  __typename?: "rtcs_db_RPT_KccaAffiliates_avg_fields";
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["Float"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["Float"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["Float"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["Float"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["Float"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerGuardianCoin?: Maybe<Scalars["Float"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["Float"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["Float"]>;
  CustomerGuardianSurname?: Maybe<Scalars["Float"]>;
  CustomerReferenceCoin?: Maybe<Scalars["Float"]>;
  EffectDate?: Maybe<Scalars["Float"]>;
  IdentificationNumber?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  MotherMaidenName?: Maybe<Scalars["Float"]>;
  OfficialDesignation?: Maybe<Scalars["Float"]>;
  OtherTitle?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.RPT_KccaAffiliates" */
export type Rtcs_Db_Rpt_KccaAffiliates_Avg_Order_By = {
  CustomerBusinessAssociateAcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateFirstname?: Maybe<Order_By>;
  CustomerBusinessAssociateMiddleName?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Order_By>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Order_By>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Order_By>;
  CustomerBusinessAssociateSurname?: Maybe<Order_By>;
  CustomerBusinessAssociateTitleId?: Maybe<Order_By>;
  CustomerContactPersonAcquiredName?: Maybe<Order_By>;
  CustomerContactPersonCoin?: Maybe<Order_By>;
  CustomerContactPersonEmail?: Maybe<Order_By>;
  CustomerContactPersonFirstname?: Maybe<Order_By>;
  CustomerContactPersonMiddleName?: Maybe<Order_By>;
  CustomerContactPersonMobile?: Maybe<Order_By>;
  CustomerContactPersonModifiedBy?: Maybe<Order_By>;
  CustomerContactPersonModifiedDate?: Maybe<Order_By>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Order_By>;
  CustomerContactPersonSurname?: Maybe<Order_By>;
  CustomerContactPersonTelephone?: Maybe<Order_By>;
  CustomerContactPersonTitleId?: Maybe<Order_By>;
  CustomerGuardianAcquiredName?: Maybe<Order_By>;
  CustomerGuardianCoin?: Maybe<Order_By>;
  CustomerGuardianFirstname?: Maybe<Order_By>;
  CustomerGuardianMiddleName?: Maybe<Order_By>;
  CustomerGuardianSurname?: Maybe<Order_By>;
  CustomerReferenceCoin?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.RPT_KccaAffiliates". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rpt_KccaAffiliates_Bool_Exp = {
  AcquiredName?: Maybe<String_Comparison_Exp>;
  CustomerBusinessAssociateAcquiredName?: Maybe<Float8_Comparison_Exp>;
  CustomerBusinessAssociateFirstname?: Maybe<Float8_Comparison_Exp>;
  CustomerBusinessAssociateMiddleName?: Maybe<Float8_Comparison_Exp>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Float8_Comparison_Exp>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Bigint_Comparison_Exp>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Float8_Comparison_Exp>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Float8_Comparison_Exp>;
  CustomerBusinessAssociateSurname?: Maybe<Float8_Comparison_Exp>;
  CustomerBusinessAssociateTitleId?: Maybe<Float8_Comparison_Exp>;
  CustomerContactPersonAcquiredName?: Maybe<Float8_Comparison_Exp>;
  CustomerContactPersonCoin?: Maybe<Float8_Comparison_Exp>;
  CustomerContactPersonEmail?: Maybe<Float8_Comparison_Exp>;
  CustomerContactPersonFirstname?: Maybe<Float8_Comparison_Exp>;
  CustomerContactPersonMiddleName?: Maybe<Float8_Comparison_Exp>;
  CustomerContactPersonMobile?: Maybe<Float8_Comparison_Exp>;
  CustomerContactPersonModifiedBy?: Maybe<Float8_Comparison_Exp>;
  CustomerContactPersonModifiedDate?: Maybe<Bigint_Comparison_Exp>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Float8_Comparison_Exp>;
  CustomerContactPersonSurname?: Maybe<Float8_Comparison_Exp>;
  CustomerContactPersonTelephone?: Maybe<Float8_Comparison_Exp>;
  CustomerContactPersonTitleId?: Maybe<Float8_Comparison_Exp>;
  CustomerGuardianAcquiredName?: Maybe<Float8_Comparison_Exp>;
  CustomerGuardianCoin?: Maybe<Float8_Comparison_Exp>;
  CustomerGuardianFirstname?: Maybe<Float8_Comparison_Exp>;
  CustomerGuardianMiddleName?: Maybe<Float8_Comparison_Exp>;
  CustomerGuardianSurname?: Maybe<Float8_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  CustomerReferenceCoin?: Maybe<Float8_Comparison_Exp>;
  CustomerReferenceEmail?: Maybe<String_Comparison_Exp>;
  CustomerReferenceFirstname?: Maybe<String_Comparison_Exp>;
  CustomerReferenceMiddleName?: Maybe<String_Comparison_Exp>;
  CustomerReferenceMobile?: Maybe<String_Comparison_Exp>;
  CustomerReferenceModifiedBy?: Maybe<String_Comparison_Exp>;
  CustomerReferenceModifiedDate?: Maybe<String_Comparison_Exp>;
  CustomerReferenceRelationshipDesignation?: Maybe<String_Comparison_Exp>;
  CustomerReferenceSurname?: Maybe<String_Comparison_Exp>;
  CustomerReferenceTelephone?: Maybe<String_Comparison_Exp>;
  EffectDate?: Maybe<Bigint_Comparison_Exp>;
  IdentificationNumber?: Maybe<Float8_Comparison_Exp>;
  IdentificationTypeId?: Maybe<Float8_Comparison_Exp>;
  MotherMaidenName?: Maybe<Float8_Comparison_Exp>;
  OfficialDesignation?: Maybe<Float8_Comparison_Exp>;
  OtherTitle?: Maybe<Float8_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rpt_KccaAffiliates_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rpt_KccaAffiliates_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.RPT_KccaAffiliates" */
export type Rtcs_Db_Rpt_KccaAffiliates_Inc_Input = {
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["float8"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["float8"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["float8"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["float8"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["float8"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerGuardianCoin?: Maybe<Scalars["float8"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["float8"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["float8"]>;
  CustomerGuardianSurname?: Maybe<Scalars["float8"]>;
  CustomerReferenceCoin?: Maybe<Scalars["float8"]>;
  EffectDate?: Maybe<Scalars["bigint"]>;
  IdentificationNumber?: Maybe<Scalars["float8"]>;
  IdentificationTypeId?: Maybe<Scalars["float8"]>;
  MotherMaidenName?: Maybe<Scalars["float8"]>;
  OfficialDesignation?: Maybe<Scalars["float8"]>;
  OtherTitle?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.RPT_KccaAffiliates" */
export type Rtcs_Db_Rpt_KccaAffiliates_Insert_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["float8"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["float8"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["float8"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["float8"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["float8"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerGuardianCoin?: Maybe<Scalars["float8"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["float8"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["float8"]>;
  CustomerGuardianSurname?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  CustomerReferenceCoin?: Maybe<Scalars["float8"]>;
  CustomerReferenceEmail?: Maybe<Scalars["String"]>;
  CustomerReferenceFirstname?: Maybe<Scalars["String"]>;
  CustomerReferenceMiddleName?: Maybe<Scalars["String"]>;
  CustomerReferenceMobile?: Maybe<Scalars["String"]>;
  CustomerReferenceModifiedBy?: Maybe<Scalars["String"]>;
  CustomerReferenceModifiedDate?: Maybe<Scalars["String"]>;
  CustomerReferenceRelationshipDesignation?: Maybe<Scalars["String"]>;
  CustomerReferenceSurname?: Maybe<Scalars["String"]>;
  CustomerReferenceTelephone?: Maybe<Scalars["String"]>;
  EffectDate?: Maybe<Scalars["bigint"]>;
  IdentificationNumber?: Maybe<Scalars["float8"]>;
  IdentificationTypeId?: Maybe<Scalars["float8"]>;
  MotherMaidenName?: Maybe<Scalars["float8"]>;
  OfficialDesignation?: Maybe<Scalars["float8"]>;
  OtherTitle?: Maybe<Scalars["float8"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rpt_KccaAffiliates_Max_Fields = {
  __typename?: "rtcs_db_RPT_KccaAffiliates_max_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["float8"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["float8"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["float8"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["float8"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["float8"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerGuardianCoin?: Maybe<Scalars["float8"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["float8"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["float8"]>;
  CustomerGuardianSurname?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  CustomerReferenceCoin?: Maybe<Scalars["float8"]>;
  CustomerReferenceEmail?: Maybe<Scalars["String"]>;
  CustomerReferenceFirstname?: Maybe<Scalars["String"]>;
  CustomerReferenceMiddleName?: Maybe<Scalars["String"]>;
  CustomerReferenceMobile?: Maybe<Scalars["String"]>;
  CustomerReferenceModifiedBy?: Maybe<Scalars["String"]>;
  CustomerReferenceModifiedDate?: Maybe<Scalars["String"]>;
  CustomerReferenceRelationshipDesignation?: Maybe<Scalars["String"]>;
  CustomerReferenceSurname?: Maybe<Scalars["String"]>;
  CustomerReferenceTelephone?: Maybe<Scalars["String"]>;
  EffectDate?: Maybe<Scalars["bigint"]>;
  IdentificationNumber?: Maybe<Scalars["float8"]>;
  IdentificationTypeId?: Maybe<Scalars["float8"]>;
  MotherMaidenName?: Maybe<Scalars["float8"]>;
  OfficialDesignation?: Maybe<Scalars["float8"]>;
  OtherTitle?: Maybe<Scalars["float8"]>;
};

/** order by max() on columns of table "rtcs_db.RPT_KccaAffiliates" */
export type Rtcs_Db_Rpt_KccaAffiliates_Max_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateAcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateFirstname?: Maybe<Order_By>;
  CustomerBusinessAssociateMiddleName?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Order_By>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Order_By>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Order_By>;
  CustomerBusinessAssociateSurname?: Maybe<Order_By>;
  CustomerBusinessAssociateTitleId?: Maybe<Order_By>;
  CustomerContactPersonAcquiredName?: Maybe<Order_By>;
  CustomerContactPersonCoin?: Maybe<Order_By>;
  CustomerContactPersonEmail?: Maybe<Order_By>;
  CustomerContactPersonFirstname?: Maybe<Order_By>;
  CustomerContactPersonMiddleName?: Maybe<Order_By>;
  CustomerContactPersonMobile?: Maybe<Order_By>;
  CustomerContactPersonModifiedBy?: Maybe<Order_By>;
  CustomerContactPersonModifiedDate?: Maybe<Order_By>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Order_By>;
  CustomerContactPersonSurname?: Maybe<Order_By>;
  CustomerContactPersonTelephone?: Maybe<Order_By>;
  CustomerContactPersonTitleId?: Maybe<Order_By>;
  CustomerGuardianAcquiredName?: Maybe<Order_By>;
  CustomerGuardianCoin?: Maybe<Order_By>;
  CustomerGuardianFirstname?: Maybe<Order_By>;
  CustomerGuardianMiddleName?: Maybe<Order_By>;
  CustomerGuardianSurname?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  CustomerReferenceCoin?: Maybe<Order_By>;
  CustomerReferenceEmail?: Maybe<Order_By>;
  CustomerReferenceFirstname?: Maybe<Order_By>;
  CustomerReferenceMiddleName?: Maybe<Order_By>;
  CustomerReferenceMobile?: Maybe<Order_By>;
  CustomerReferenceModifiedBy?: Maybe<Order_By>;
  CustomerReferenceModifiedDate?: Maybe<Order_By>;
  CustomerReferenceRelationshipDesignation?: Maybe<Order_By>;
  CustomerReferenceSurname?: Maybe<Order_By>;
  CustomerReferenceTelephone?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rpt_KccaAffiliates_Min_Fields = {
  __typename?: "rtcs_db_RPT_KccaAffiliates_min_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["float8"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["float8"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["float8"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["float8"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["float8"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerGuardianCoin?: Maybe<Scalars["float8"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["float8"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["float8"]>;
  CustomerGuardianSurname?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  CustomerReferenceCoin?: Maybe<Scalars["float8"]>;
  CustomerReferenceEmail?: Maybe<Scalars["String"]>;
  CustomerReferenceFirstname?: Maybe<Scalars["String"]>;
  CustomerReferenceMiddleName?: Maybe<Scalars["String"]>;
  CustomerReferenceMobile?: Maybe<Scalars["String"]>;
  CustomerReferenceModifiedBy?: Maybe<Scalars["String"]>;
  CustomerReferenceModifiedDate?: Maybe<Scalars["String"]>;
  CustomerReferenceRelationshipDesignation?: Maybe<Scalars["String"]>;
  CustomerReferenceSurname?: Maybe<Scalars["String"]>;
  CustomerReferenceTelephone?: Maybe<Scalars["String"]>;
  EffectDate?: Maybe<Scalars["bigint"]>;
  IdentificationNumber?: Maybe<Scalars["float8"]>;
  IdentificationTypeId?: Maybe<Scalars["float8"]>;
  MotherMaidenName?: Maybe<Scalars["float8"]>;
  OfficialDesignation?: Maybe<Scalars["float8"]>;
  OtherTitle?: Maybe<Scalars["float8"]>;
};

/** order by min() on columns of table "rtcs_db.RPT_KccaAffiliates" */
export type Rtcs_Db_Rpt_KccaAffiliates_Min_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateAcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateFirstname?: Maybe<Order_By>;
  CustomerBusinessAssociateMiddleName?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Order_By>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Order_By>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Order_By>;
  CustomerBusinessAssociateSurname?: Maybe<Order_By>;
  CustomerBusinessAssociateTitleId?: Maybe<Order_By>;
  CustomerContactPersonAcquiredName?: Maybe<Order_By>;
  CustomerContactPersonCoin?: Maybe<Order_By>;
  CustomerContactPersonEmail?: Maybe<Order_By>;
  CustomerContactPersonFirstname?: Maybe<Order_By>;
  CustomerContactPersonMiddleName?: Maybe<Order_By>;
  CustomerContactPersonMobile?: Maybe<Order_By>;
  CustomerContactPersonModifiedBy?: Maybe<Order_By>;
  CustomerContactPersonModifiedDate?: Maybe<Order_By>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Order_By>;
  CustomerContactPersonSurname?: Maybe<Order_By>;
  CustomerContactPersonTelephone?: Maybe<Order_By>;
  CustomerContactPersonTitleId?: Maybe<Order_By>;
  CustomerGuardianAcquiredName?: Maybe<Order_By>;
  CustomerGuardianCoin?: Maybe<Order_By>;
  CustomerGuardianFirstname?: Maybe<Order_By>;
  CustomerGuardianMiddleName?: Maybe<Order_By>;
  CustomerGuardianSurname?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  CustomerReferenceCoin?: Maybe<Order_By>;
  CustomerReferenceEmail?: Maybe<Order_By>;
  CustomerReferenceFirstname?: Maybe<Order_By>;
  CustomerReferenceMiddleName?: Maybe<Order_By>;
  CustomerReferenceMobile?: Maybe<Order_By>;
  CustomerReferenceModifiedBy?: Maybe<Order_By>;
  CustomerReferenceModifiedDate?: Maybe<Order_By>;
  CustomerReferenceRelationshipDesignation?: Maybe<Order_By>;
  CustomerReferenceSurname?: Maybe<Order_By>;
  CustomerReferenceTelephone?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.RPT_KccaAffiliates" */
export type Rtcs_Db_Rpt_KccaAffiliates_Mutation_Response = {
  __typename?: "rtcs_db_RPT_KccaAffiliates_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rpt_KccaAffiliates>;
};

/** input type for inserting object relation for remote table "rtcs_db.RPT_KccaAffiliates" */
export type Rtcs_Db_Rpt_KccaAffiliates_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rpt_KccaAffiliates_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.RPT_KccaAffiliates" */
export type Rtcs_Db_Rpt_KccaAffiliates_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateAcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateFirstname?: Maybe<Order_By>;
  CustomerBusinessAssociateMiddleName?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Order_By>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Order_By>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Order_By>;
  CustomerBusinessAssociateSurname?: Maybe<Order_By>;
  CustomerBusinessAssociateTitleId?: Maybe<Order_By>;
  CustomerContactPersonAcquiredName?: Maybe<Order_By>;
  CustomerContactPersonCoin?: Maybe<Order_By>;
  CustomerContactPersonEmail?: Maybe<Order_By>;
  CustomerContactPersonFirstname?: Maybe<Order_By>;
  CustomerContactPersonMiddleName?: Maybe<Order_By>;
  CustomerContactPersonMobile?: Maybe<Order_By>;
  CustomerContactPersonModifiedBy?: Maybe<Order_By>;
  CustomerContactPersonModifiedDate?: Maybe<Order_By>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Order_By>;
  CustomerContactPersonSurname?: Maybe<Order_By>;
  CustomerContactPersonTelephone?: Maybe<Order_By>;
  CustomerContactPersonTitleId?: Maybe<Order_By>;
  CustomerGuardianAcquiredName?: Maybe<Order_By>;
  CustomerGuardianCoin?: Maybe<Order_By>;
  CustomerGuardianFirstname?: Maybe<Order_By>;
  CustomerGuardianMiddleName?: Maybe<Order_By>;
  CustomerGuardianSurname?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  CustomerReferenceCoin?: Maybe<Order_By>;
  CustomerReferenceEmail?: Maybe<Order_By>;
  CustomerReferenceFirstname?: Maybe<Order_By>;
  CustomerReferenceMiddleName?: Maybe<Order_By>;
  CustomerReferenceMobile?: Maybe<Order_By>;
  CustomerReferenceModifiedBy?: Maybe<Order_By>;
  CustomerReferenceModifiedDate?: Maybe<Order_By>;
  CustomerReferenceRelationshipDesignation?: Maybe<Order_By>;
  CustomerReferenceSurname?: Maybe<Order_By>;
  CustomerReferenceTelephone?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.RPT_KccaAffiliates" */
export enum Rtcs_Db_Rpt_KccaAffiliates_Select_Column {
  /** column name */
  AcquiredName = "AcquiredName",
  /** column name */
  CustomerBusinessAssociateAcquiredName = "CustomerBusinessAssociateAcquiredName",
  /** column name */
  CustomerBusinessAssociateFirstname = "CustomerBusinessAssociateFirstname",
  /** column name */
  CustomerBusinessAssociateMiddleName = "CustomerBusinessAssociateMiddleName",
  /** column name */
  CustomerBusinessAssociateModifiedBy = "CustomerBusinessAssociateModifiedBy",
  /** column name */
  CustomerBusinessAssociateModifiedDate = "CustomerBusinessAssociateModifiedDate",
  /** column name */
  CustomerBusinessAssociateMotherMaidenName = "CustomerBusinessAssociateMotherMaidenName",
  /** column name */
  CustomerBusinessAssociateOtherTitle = "CustomerBusinessAssociateOtherTitle",
  /** column name */
  CustomerBusinessAssociateSurname = "CustomerBusinessAssociateSurname",
  /** column name */
  CustomerBusinessAssociateTitleId = "CustomerBusinessAssociateTitleId",
  /** column name */
  CustomerContactPersonAcquiredName = "CustomerContactPersonAcquiredName",
  /** column name */
  CustomerContactPersonCoin = "CustomerContactPersonCoin",
  /** column name */
  CustomerContactPersonEmail = "CustomerContactPersonEmail",
  /** column name */
  CustomerContactPersonFirstname = "CustomerContactPersonFirstname",
  /** column name */
  CustomerContactPersonMiddleName = "CustomerContactPersonMiddleName",
  /** column name */
  CustomerContactPersonMobile = "CustomerContactPersonMobile",
  /** column name */
  CustomerContactPersonModifiedBy = "CustomerContactPersonModifiedBy",
  /** column name */
  CustomerContactPersonModifiedDate = "CustomerContactPersonModifiedDate",
  /** column name */
  CustomerContactPersonRelationshipDesignation = "CustomerContactPersonRelationshipDesignation",
  /** column name */
  CustomerContactPersonSurname = "CustomerContactPersonSurname",
  /** column name */
  CustomerContactPersonTelephone = "CustomerContactPersonTelephone",
  /** column name */
  CustomerContactPersonTitleId = "CustomerContactPersonTitleId",
  /** column name */
  CustomerGuardianAcquiredName = "CustomerGuardianAcquiredName",
  /** column name */
  CustomerGuardianCoin = "CustomerGuardianCoin",
  /** column name */
  CustomerGuardianFirstname = "CustomerGuardianFirstname",
  /** column name */
  CustomerGuardianMiddleName = "CustomerGuardianMiddleName",
  /** column name */
  CustomerGuardianSurname = "CustomerGuardianSurname",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  CustomerReferenceCoin = "CustomerReferenceCoin",
  /** column name */
  CustomerReferenceEmail = "CustomerReferenceEmail",
  /** column name */
  CustomerReferenceFirstname = "CustomerReferenceFirstname",
  /** column name */
  CustomerReferenceMiddleName = "CustomerReferenceMiddleName",
  /** column name */
  CustomerReferenceMobile = "CustomerReferenceMobile",
  /** column name */
  CustomerReferenceModifiedBy = "CustomerReferenceModifiedBy",
  /** column name */
  CustomerReferenceModifiedDate = "CustomerReferenceModifiedDate",
  /** column name */
  CustomerReferenceRelationshipDesignation = "CustomerReferenceRelationshipDesignation",
  /** column name */
  CustomerReferenceSurname = "CustomerReferenceSurname",
  /** column name */
  CustomerReferenceTelephone = "CustomerReferenceTelephone",
  /** column name */
  EffectDate = "EffectDate",
  /** column name */
  IdentificationNumber = "IdentificationNumber",
  /** column name */
  IdentificationTypeId = "IdentificationTypeId",
  /** column name */
  MotherMaidenName = "MotherMaidenName",
  /** column name */
  OfficialDesignation = "OfficialDesignation",
  /** column name */
  OtherTitle = "OtherTitle",
}

/** input type for updating data in table "rtcs_db.RPT_KccaAffiliates" */
export type Rtcs_Db_Rpt_KccaAffiliates_Set_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["float8"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["float8"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["float8"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["float8"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["float8"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerGuardianCoin?: Maybe<Scalars["float8"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["float8"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["float8"]>;
  CustomerGuardianSurname?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  CustomerReferenceCoin?: Maybe<Scalars["float8"]>;
  CustomerReferenceEmail?: Maybe<Scalars["String"]>;
  CustomerReferenceFirstname?: Maybe<Scalars["String"]>;
  CustomerReferenceMiddleName?: Maybe<Scalars["String"]>;
  CustomerReferenceMobile?: Maybe<Scalars["String"]>;
  CustomerReferenceModifiedBy?: Maybe<Scalars["String"]>;
  CustomerReferenceModifiedDate?: Maybe<Scalars["String"]>;
  CustomerReferenceRelationshipDesignation?: Maybe<Scalars["String"]>;
  CustomerReferenceSurname?: Maybe<Scalars["String"]>;
  CustomerReferenceTelephone?: Maybe<Scalars["String"]>;
  EffectDate?: Maybe<Scalars["bigint"]>;
  IdentificationNumber?: Maybe<Scalars["float8"]>;
  IdentificationTypeId?: Maybe<Scalars["float8"]>;
  MotherMaidenName?: Maybe<Scalars["float8"]>;
  OfficialDesignation?: Maybe<Scalars["float8"]>;
  OtherTitle?: Maybe<Scalars["float8"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rpt_KccaAffiliates_Stddev_Fields = {
  __typename?: "rtcs_db_RPT_KccaAffiliates_stddev_fields";
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["Float"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["Float"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["Float"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["Float"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["Float"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerGuardianCoin?: Maybe<Scalars["Float"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["Float"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["Float"]>;
  CustomerGuardianSurname?: Maybe<Scalars["Float"]>;
  CustomerReferenceCoin?: Maybe<Scalars["Float"]>;
  EffectDate?: Maybe<Scalars["Float"]>;
  IdentificationNumber?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  MotherMaidenName?: Maybe<Scalars["Float"]>;
  OfficialDesignation?: Maybe<Scalars["Float"]>;
  OtherTitle?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.RPT_KccaAffiliates" */
export type Rtcs_Db_Rpt_KccaAffiliates_Stddev_Order_By = {
  CustomerBusinessAssociateAcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateFirstname?: Maybe<Order_By>;
  CustomerBusinessAssociateMiddleName?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Order_By>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Order_By>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Order_By>;
  CustomerBusinessAssociateSurname?: Maybe<Order_By>;
  CustomerBusinessAssociateTitleId?: Maybe<Order_By>;
  CustomerContactPersonAcquiredName?: Maybe<Order_By>;
  CustomerContactPersonCoin?: Maybe<Order_By>;
  CustomerContactPersonEmail?: Maybe<Order_By>;
  CustomerContactPersonFirstname?: Maybe<Order_By>;
  CustomerContactPersonMiddleName?: Maybe<Order_By>;
  CustomerContactPersonMobile?: Maybe<Order_By>;
  CustomerContactPersonModifiedBy?: Maybe<Order_By>;
  CustomerContactPersonModifiedDate?: Maybe<Order_By>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Order_By>;
  CustomerContactPersonSurname?: Maybe<Order_By>;
  CustomerContactPersonTelephone?: Maybe<Order_By>;
  CustomerContactPersonTitleId?: Maybe<Order_By>;
  CustomerGuardianAcquiredName?: Maybe<Order_By>;
  CustomerGuardianCoin?: Maybe<Order_By>;
  CustomerGuardianFirstname?: Maybe<Order_By>;
  CustomerGuardianMiddleName?: Maybe<Order_By>;
  CustomerGuardianSurname?: Maybe<Order_By>;
  CustomerReferenceCoin?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rpt_KccaAffiliates_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_RPT_KccaAffiliates_stddev_pop_fields";
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["Float"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["Float"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["Float"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["Float"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["Float"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerGuardianCoin?: Maybe<Scalars["Float"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["Float"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["Float"]>;
  CustomerGuardianSurname?: Maybe<Scalars["Float"]>;
  CustomerReferenceCoin?: Maybe<Scalars["Float"]>;
  EffectDate?: Maybe<Scalars["Float"]>;
  IdentificationNumber?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  MotherMaidenName?: Maybe<Scalars["Float"]>;
  OfficialDesignation?: Maybe<Scalars["Float"]>;
  OtherTitle?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.RPT_KccaAffiliates" */
export type Rtcs_Db_Rpt_KccaAffiliates_Stddev_Pop_Order_By = {
  CustomerBusinessAssociateAcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateFirstname?: Maybe<Order_By>;
  CustomerBusinessAssociateMiddleName?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Order_By>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Order_By>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Order_By>;
  CustomerBusinessAssociateSurname?: Maybe<Order_By>;
  CustomerBusinessAssociateTitleId?: Maybe<Order_By>;
  CustomerContactPersonAcquiredName?: Maybe<Order_By>;
  CustomerContactPersonCoin?: Maybe<Order_By>;
  CustomerContactPersonEmail?: Maybe<Order_By>;
  CustomerContactPersonFirstname?: Maybe<Order_By>;
  CustomerContactPersonMiddleName?: Maybe<Order_By>;
  CustomerContactPersonMobile?: Maybe<Order_By>;
  CustomerContactPersonModifiedBy?: Maybe<Order_By>;
  CustomerContactPersonModifiedDate?: Maybe<Order_By>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Order_By>;
  CustomerContactPersonSurname?: Maybe<Order_By>;
  CustomerContactPersonTelephone?: Maybe<Order_By>;
  CustomerContactPersonTitleId?: Maybe<Order_By>;
  CustomerGuardianAcquiredName?: Maybe<Order_By>;
  CustomerGuardianCoin?: Maybe<Order_By>;
  CustomerGuardianFirstname?: Maybe<Order_By>;
  CustomerGuardianMiddleName?: Maybe<Order_By>;
  CustomerGuardianSurname?: Maybe<Order_By>;
  CustomerReferenceCoin?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rpt_KccaAffiliates_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_RPT_KccaAffiliates_stddev_samp_fields";
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["Float"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["Float"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["Float"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["Float"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["Float"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerGuardianCoin?: Maybe<Scalars["Float"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["Float"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["Float"]>;
  CustomerGuardianSurname?: Maybe<Scalars["Float"]>;
  CustomerReferenceCoin?: Maybe<Scalars["Float"]>;
  EffectDate?: Maybe<Scalars["Float"]>;
  IdentificationNumber?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  MotherMaidenName?: Maybe<Scalars["Float"]>;
  OfficialDesignation?: Maybe<Scalars["Float"]>;
  OtherTitle?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.RPT_KccaAffiliates" */
export type Rtcs_Db_Rpt_KccaAffiliates_Stddev_Samp_Order_By = {
  CustomerBusinessAssociateAcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateFirstname?: Maybe<Order_By>;
  CustomerBusinessAssociateMiddleName?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Order_By>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Order_By>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Order_By>;
  CustomerBusinessAssociateSurname?: Maybe<Order_By>;
  CustomerBusinessAssociateTitleId?: Maybe<Order_By>;
  CustomerContactPersonAcquiredName?: Maybe<Order_By>;
  CustomerContactPersonCoin?: Maybe<Order_By>;
  CustomerContactPersonEmail?: Maybe<Order_By>;
  CustomerContactPersonFirstname?: Maybe<Order_By>;
  CustomerContactPersonMiddleName?: Maybe<Order_By>;
  CustomerContactPersonMobile?: Maybe<Order_By>;
  CustomerContactPersonModifiedBy?: Maybe<Order_By>;
  CustomerContactPersonModifiedDate?: Maybe<Order_By>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Order_By>;
  CustomerContactPersonSurname?: Maybe<Order_By>;
  CustomerContactPersonTelephone?: Maybe<Order_By>;
  CustomerContactPersonTitleId?: Maybe<Order_By>;
  CustomerGuardianAcquiredName?: Maybe<Order_By>;
  CustomerGuardianCoin?: Maybe<Order_By>;
  CustomerGuardianFirstname?: Maybe<Order_By>;
  CustomerGuardianMiddleName?: Maybe<Order_By>;
  CustomerGuardianSurname?: Maybe<Order_By>;
  CustomerReferenceCoin?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rpt_KccaAffiliates_Sum_Fields = {
  __typename?: "rtcs_db_RPT_KccaAffiliates_sum_fields";
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["float8"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["float8"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["float8"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["float8"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["float8"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["float8"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["bigint"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["float8"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["float8"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["float8"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["float8"]>;
  CustomerGuardianCoin?: Maybe<Scalars["float8"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["float8"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["float8"]>;
  CustomerGuardianSurname?: Maybe<Scalars["float8"]>;
  CustomerReferenceCoin?: Maybe<Scalars["float8"]>;
  EffectDate?: Maybe<Scalars["bigint"]>;
  IdentificationNumber?: Maybe<Scalars["float8"]>;
  IdentificationTypeId?: Maybe<Scalars["float8"]>;
  MotherMaidenName?: Maybe<Scalars["float8"]>;
  OfficialDesignation?: Maybe<Scalars["float8"]>;
  OtherTitle?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.RPT_KccaAffiliates" */
export type Rtcs_Db_Rpt_KccaAffiliates_Sum_Order_By = {
  CustomerBusinessAssociateAcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateFirstname?: Maybe<Order_By>;
  CustomerBusinessAssociateMiddleName?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Order_By>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Order_By>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Order_By>;
  CustomerBusinessAssociateSurname?: Maybe<Order_By>;
  CustomerBusinessAssociateTitleId?: Maybe<Order_By>;
  CustomerContactPersonAcquiredName?: Maybe<Order_By>;
  CustomerContactPersonCoin?: Maybe<Order_By>;
  CustomerContactPersonEmail?: Maybe<Order_By>;
  CustomerContactPersonFirstname?: Maybe<Order_By>;
  CustomerContactPersonMiddleName?: Maybe<Order_By>;
  CustomerContactPersonMobile?: Maybe<Order_By>;
  CustomerContactPersonModifiedBy?: Maybe<Order_By>;
  CustomerContactPersonModifiedDate?: Maybe<Order_By>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Order_By>;
  CustomerContactPersonSurname?: Maybe<Order_By>;
  CustomerContactPersonTelephone?: Maybe<Order_By>;
  CustomerContactPersonTitleId?: Maybe<Order_By>;
  CustomerGuardianAcquiredName?: Maybe<Order_By>;
  CustomerGuardianCoin?: Maybe<Order_By>;
  CustomerGuardianFirstname?: Maybe<Order_By>;
  CustomerGuardianMiddleName?: Maybe<Order_By>;
  CustomerGuardianSurname?: Maybe<Order_By>;
  CustomerReferenceCoin?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rpt_KccaAffiliates_Var_Pop_Fields = {
  __typename?: "rtcs_db_RPT_KccaAffiliates_var_pop_fields";
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["Float"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["Float"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["Float"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["Float"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["Float"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerGuardianCoin?: Maybe<Scalars["Float"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["Float"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["Float"]>;
  CustomerGuardianSurname?: Maybe<Scalars["Float"]>;
  CustomerReferenceCoin?: Maybe<Scalars["Float"]>;
  EffectDate?: Maybe<Scalars["Float"]>;
  IdentificationNumber?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  MotherMaidenName?: Maybe<Scalars["Float"]>;
  OfficialDesignation?: Maybe<Scalars["Float"]>;
  OtherTitle?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.RPT_KccaAffiliates" */
export type Rtcs_Db_Rpt_KccaAffiliates_Var_Pop_Order_By = {
  CustomerBusinessAssociateAcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateFirstname?: Maybe<Order_By>;
  CustomerBusinessAssociateMiddleName?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Order_By>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Order_By>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Order_By>;
  CustomerBusinessAssociateSurname?: Maybe<Order_By>;
  CustomerBusinessAssociateTitleId?: Maybe<Order_By>;
  CustomerContactPersonAcquiredName?: Maybe<Order_By>;
  CustomerContactPersonCoin?: Maybe<Order_By>;
  CustomerContactPersonEmail?: Maybe<Order_By>;
  CustomerContactPersonFirstname?: Maybe<Order_By>;
  CustomerContactPersonMiddleName?: Maybe<Order_By>;
  CustomerContactPersonMobile?: Maybe<Order_By>;
  CustomerContactPersonModifiedBy?: Maybe<Order_By>;
  CustomerContactPersonModifiedDate?: Maybe<Order_By>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Order_By>;
  CustomerContactPersonSurname?: Maybe<Order_By>;
  CustomerContactPersonTelephone?: Maybe<Order_By>;
  CustomerContactPersonTitleId?: Maybe<Order_By>;
  CustomerGuardianAcquiredName?: Maybe<Order_By>;
  CustomerGuardianCoin?: Maybe<Order_By>;
  CustomerGuardianFirstname?: Maybe<Order_By>;
  CustomerGuardianMiddleName?: Maybe<Order_By>;
  CustomerGuardianSurname?: Maybe<Order_By>;
  CustomerReferenceCoin?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rpt_KccaAffiliates_Var_Samp_Fields = {
  __typename?: "rtcs_db_RPT_KccaAffiliates_var_samp_fields";
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["Float"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["Float"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["Float"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["Float"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["Float"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerGuardianCoin?: Maybe<Scalars["Float"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["Float"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["Float"]>;
  CustomerGuardianSurname?: Maybe<Scalars["Float"]>;
  CustomerReferenceCoin?: Maybe<Scalars["Float"]>;
  EffectDate?: Maybe<Scalars["Float"]>;
  IdentificationNumber?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  MotherMaidenName?: Maybe<Scalars["Float"]>;
  OfficialDesignation?: Maybe<Scalars["Float"]>;
  OtherTitle?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.RPT_KccaAffiliates" */
export type Rtcs_Db_Rpt_KccaAffiliates_Var_Samp_Order_By = {
  CustomerBusinessAssociateAcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateFirstname?: Maybe<Order_By>;
  CustomerBusinessAssociateMiddleName?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Order_By>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Order_By>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Order_By>;
  CustomerBusinessAssociateSurname?: Maybe<Order_By>;
  CustomerBusinessAssociateTitleId?: Maybe<Order_By>;
  CustomerContactPersonAcquiredName?: Maybe<Order_By>;
  CustomerContactPersonCoin?: Maybe<Order_By>;
  CustomerContactPersonEmail?: Maybe<Order_By>;
  CustomerContactPersonFirstname?: Maybe<Order_By>;
  CustomerContactPersonMiddleName?: Maybe<Order_By>;
  CustomerContactPersonMobile?: Maybe<Order_By>;
  CustomerContactPersonModifiedBy?: Maybe<Order_By>;
  CustomerContactPersonModifiedDate?: Maybe<Order_By>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Order_By>;
  CustomerContactPersonSurname?: Maybe<Order_By>;
  CustomerContactPersonTelephone?: Maybe<Order_By>;
  CustomerContactPersonTitleId?: Maybe<Order_By>;
  CustomerGuardianAcquiredName?: Maybe<Order_By>;
  CustomerGuardianCoin?: Maybe<Order_By>;
  CustomerGuardianFirstname?: Maybe<Order_By>;
  CustomerGuardianMiddleName?: Maybe<Order_By>;
  CustomerGuardianSurname?: Maybe<Order_By>;
  CustomerReferenceCoin?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rpt_KccaAffiliates_Variance_Fields = {
  __typename?: "rtcs_db_RPT_KccaAffiliates_variance_fields";
  CustomerBusinessAssociateAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateFirstname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMiddleName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateSurname?: Maybe<Scalars["Float"]>;
  CustomerBusinessAssociateTitleId?: Maybe<Scalars["Float"]>;
  CustomerContactPersonAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonCoin?: Maybe<Scalars["Float"]>;
  CustomerContactPersonEmail?: Maybe<Scalars["Float"]>;
  CustomerContactPersonFirstname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMiddleName?: Maybe<Scalars["Float"]>;
  CustomerContactPersonMobile?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedBy?: Maybe<Scalars["Float"]>;
  CustomerContactPersonModifiedDate?: Maybe<Scalars["Float"]>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Scalars["Float"]>;
  CustomerContactPersonSurname?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTelephone?: Maybe<Scalars["Float"]>;
  CustomerContactPersonTitleId?: Maybe<Scalars["Float"]>;
  CustomerGuardianAcquiredName?: Maybe<Scalars["Float"]>;
  CustomerGuardianCoin?: Maybe<Scalars["Float"]>;
  CustomerGuardianFirstname?: Maybe<Scalars["Float"]>;
  CustomerGuardianMiddleName?: Maybe<Scalars["Float"]>;
  CustomerGuardianSurname?: Maybe<Scalars["Float"]>;
  CustomerReferenceCoin?: Maybe<Scalars["Float"]>;
  EffectDate?: Maybe<Scalars["Float"]>;
  IdentificationNumber?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  MotherMaidenName?: Maybe<Scalars["Float"]>;
  OfficialDesignation?: Maybe<Scalars["Float"]>;
  OtherTitle?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.RPT_KccaAffiliates" */
export type Rtcs_Db_Rpt_KccaAffiliates_Variance_Order_By = {
  CustomerBusinessAssociateAcquiredName?: Maybe<Order_By>;
  CustomerBusinessAssociateFirstname?: Maybe<Order_By>;
  CustomerBusinessAssociateMiddleName?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedBy?: Maybe<Order_By>;
  CustomerBusinessAssociateModifiedDate?: Maybe<Order_By>;
  CustomerBusinessAssociateMotherMaidenName?: Maybe<Order_By>;
  CustomerBusinessAssociateOtherTitle?: Maybe<Order_By>;
  CustomerBusinessAssociateSurname?: Maybe<Order_By>;
  CustomerBusinessAssociateTitleId?: Maybe<Order_By>;
  CustomerContactPersonAcquiredName?: Maybe<Order_By>;
  CustomerContactPersonCoin?: Maybe<Order_By>;
  CustomerContactPersonEmail?: Maybe<Order_By>;
  CustomerContactPersonFirstname?: Maybe<Order_By>;
  CustomerContactPersonMiddleName?: Maybe<Order_By>;
  CustomerContactPersonMobile?: Maybe<Order_By>;
  CustomerContactPersonModifiedBy?: Maybe<Order_By>;
  CustomerContactPersonModifiedDate?: Maybe<Order_By>;
  CustomerContactPersonRelationshipDesignation?: Maybe<Order_By>;
  CustomerContactPersonSurname?: Maybe<Order_By>;
  CustomerContactPersonTelephone?: Maybe<Order_By>;
  CustomerContactPersonTitleId?: Maybe<Order_By>;
  CustomerGuardianAcquiredName?: Maybe<Order_By>;
  CustomerGuardianCoin?: Maybe<Order_By>;
  CustomerGuardianFirstname?: Maybe<Order_By>;
  CustomerGuardianMiddleName?: Maybe<Order_By>;
  CustomerGuardianSurname?: Maybe<Order_By>;
  CustomerReferenceCoin?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData = {
  __typename?: "rtcs_db_RPT_KccaCustomerRegistrationData";
  AcquiredName?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["String"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerApplicantType?: Maybe<Scalars["String"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessType?: Maybe<Scalars["String"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerType?: Maybe<Scalars["String"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCounty?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrict?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParish?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCounty?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillage?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["String"]>;
  ResidentialAddressCounty?: Maybe<Scalars["String"]>;
  ResidentialAddressDistrict?: Maybe<Scalars["String"]>;
  ResidentialAddressParish?: Maybe<Scalars["String"]>;
  ResidentialAddressSubCounty?: Maybe<Scalars["String"]>;
  ResidentialAddressVillage?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Aggregate = {
  __typename?: "rtcs_db_RPT_KccaCustomerRegistrationData_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rpt_KccaCustomerRegistrationData>;
};

/** aggregate fields of "rtcs_db.RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Aggregate_Fields = {
  __typename?: "rtcs_db_RPT_KccaCustomerRegistrationData_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Avg_Fields = {
  __typename?: "rtcs_db_RPT_KccaCustomerRegistrationData_avg_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Avg_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.RPT_KccaCustomerRegistrationData". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Bool_Exp = {
  AcquiredName?: Maybe<String_Comparison_Exp>;
  Birthdate?: Maybe<String_Comparison_Exp>;
  BoxDistrictId?: Maybe<Float8_Comparison_Exp>;
  BoxNumber?: Maybe<String_Comparison_Exp>;
  Brn?: Maybe<String_Comparison_Exp>;
  BuildingName?: Maybe<String_Comparison_Exp>;
  BusinessName?: Maybe<String_Comparison_Exp>;
  CitizenshipCountryId?: Maybe<Float8_Comparison_Exp>;
  Coin?: Maybe<Float8_Comparison_Exp>;
  CreatedBy?: Maybe<String_Comparison_Exp>;
  CreatedDate?: Maybe<String_Comparison_Exp>;
  CustomerApplicantType?: Maybe<String_Comparison_Exp>;
  CustomerApplicantTypeId?: Maybe<Float8_Comparison_Exp>;
  CustomerBusinessType?: Maybe<String_Comparison_Exp>;
  CustomerBusinessTypeId?: Maybe<Float8_Comparison_Exp>;
  CustomerType?: Maybe<String_Comparison_Exp>;
  CustomerTypeId?: Maybe<Bigint_Comparison_Exp>;
  DateOfIncorporation?: Maybe<String_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  EntityLegalName?: Maybe<String_Comparison_Exp>;
  Fax?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  FormerBusinessName?: Maybe<String_Comparison_Exp>;
  GenderId?: Maybe<Float8_Comparison_Exp>;
  HaveAlternatePrimaryContact?: Maybe<Float8_Comparison_Exp>;
  HaveReference?: Maybe<Float8_Comparison_Exp>;
  HaveRevenueAgent?: Maybe<Float8_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  IdentificationDiplomaticId?: Maybe<String_Comparison_Exp>;
  IdentificationDrivingPermit?: Maybe<String_Comparison_Exp>;
  IdentificationEmployeeId?: Maybe<String_Comparison_Exp>;
  IdentificationFinancialCard?: Maybe<String_Comparison_Exp>;
  IdentificationNationalId?: Maybe<String_Comparison_Exp>;
  IdentificationNssfNumber?: Maybe<String_Comparison_Exp>;
  IdentificationPassportNo?: Maybe<String_Comparison_Exp>;
  IdentificationRefugeeId?: Maybe<String_Comparison_Exp>;
  IdentificationVillageId?: Maybe<String_Comparison_Exp>;
  IdentificationVoterId?: Maybe<String_Comparison_Exp>;
  IdentificationWorkId?: Maybe<String_Comparison_Exp>;
  IdentificationWorkPermit?: Maybe<String_Comparison_Exp>;
  IsForcedAction?: Maybe<Float8_Comparison_Exp>;
  IsMinor?: Maybe<Float8_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  Mobile?: Maybe<String_Comparison_Exp>;
  MotherMaidenName?: Maybe<String_Comparison_Exp>;
  Photo?: Maybe<Float8_Comparison_Exp>;
  PhotoContent?: Maybe<Float8_Comparison_Exp>;
  PhotoMime?: Maybe<Float8_Comparison_Exp>;
  PhotoName?: Maybe<Float8_Comparison_Exp>;
  PhotoSize?: Maybe<Float8_Comparison_Exp>;
  PlotNumber?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressBuildingName?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressCounty?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressCountyId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressDistrict?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressDistrictId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressParish?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressParishId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressPlotNumber?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressStreetName?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressSubCounty?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressSubCountyId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressTradingCenter?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressVillage?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressVillageId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessName?: Maybe<String_Comparison_Exp>;
  ResidentialAddressCounty?: Maybe<String_Comparison_Exp>;
  ResidentialAddressDistrict?: Maybe<String_Comparison_Exp>;
  ResidentialAddressParish?: Maybe<String_Comparison_Exp>;
  ResidentialAddressSubCounty?: Maybe<String_Comparison_Exp>;
  ResidentialAddressVillage?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  SecondMobile?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Float8_Comparison_Exp>;
  StreetName?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Telephone?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<String_Comparison_Exp>;
  TitleId?: Maybe<Float8_Comparison_Exp>;
  TradingCenter?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Inc_Input = {
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TitleId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Insert_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["String"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerApplicantType?: Maybe<Scalars["String"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessType?: Maybe<Scalars["String"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerType?: Maybe<Scalars["String"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCounty?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrict?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParish?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCounty?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillage?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["String"]>;
  ResidentialAddressCounty?: Maybe<Scalars["String"]>;
  ResidentialAddressDistrict?: Maybe<Scalars["String"]>;
  ResidentialAddressParish?: Maybe<Scalars["String"]>;
  ResidentialAddressSubCounty?: Maybe<Scalars["String"]>;
  ResidentialAddressVillage?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Max_Fields = {
  __typename?: "rtcs_db_RPT_KccaCustomerRegistrationData_max_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["String"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerApplicantType?: Maybe<Scalars["String"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessType?: Maybe<Scalars["String"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerType?: Maybe<Scalars["String"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCounty?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrict?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParish?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCounty?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillage?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["String"]>;
  ResidentialAddressCounty?: Maybe<Scalars["String"]>;
  ResidentialAddressDistrict?: Maybe<Scalars["String"]>;
  ResidentialAddressParish?: Maybe<Scalars["String"]>;
  ResidentialAddressSubCounty?: Maybe<Scalars["String"]>;
  ResidentialAddressVillage?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Max_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Birthdate?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  BoxNumber?: Maybe<Order_By>;
  Brn?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerApplicantType?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessType?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerType?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FormerBusinessName?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationDiplomaticId?: Maybe<Order_By>;
  IdentificationDrivingPermit?: Maybe<Order_By>;
  IdentificationEmployeeId?: Maybe<Order_By>;
  IdentificationFinancialCard?: Maybe<Order_By>;
  IdentificationNationalId?: Maybe<Order_By>;
  IdentificationNssfNumber?: Maybe<Order_By>;
  IdentificationPassportNo?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationVillageId?: Maybe<Order_By>;
  IdentificationVoterId?: Maybe<Order_By>;
  IdentificationWorkId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressBuildingName?: Maybe<Order_By>;
  PreviousBusinessAddressCounty?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrict?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParish?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressStreetName?: Maybe<Order_By>;
  PreviousBusinessAddressSubCounty?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressTradingCenter?: Maybe<Order_By>;
  PreviousBusinessAddressVillage?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCounty?: Maybe<Order_By>;
  ResidentialAddressDistrict?: Maybe<Order_By>;
  ResidentialAddressParish?: Maybe<Order_By>;
  ResidentialAddressSubCounty?: Maybe<Order_By>;
  ResidentialAddressVillage?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetName?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TradingCenter?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Min_Fields = {
  __typename?: "rtcs_db_RPT_KccaCustomerRegistrationData_min_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["String"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerApplicantType?: Maybe<Scalars["String"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessType?: Maybe<Scalars["String"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerType?: Maybe<Scalars["String"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCounty?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrict?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParish?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCounty?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillage?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["String"]>;
  ResidentialAddressCounty?: Maybe<Scalars["String"]>;
  ResidentialAddressDistrict?: Maybe<Scalars["String"]>;
  ResidentialAddressParish?: Maybe<Scalars["String"]>;
  ResidentialAddressSubCounty?: Maybe<Scalars["String"]>;
  ResidentialAddressVillage?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Min_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Birthdate?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  BoxNumber?: Maybe<Order_By>;
  Brn?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerApplicantType?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessType?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerType?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FormerBusinessName?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationDiplomaticId?: Maybe<Order_By>;
  IdentificationDrivingPermit?: Maybe<Order_By>;
  IdentificationEmployeeId?: Maybe<Order_By>;
  IdentificationFinancialCard?: Maybe<Order_By>;
  IdentificationNationalId?: Maybe<Order_By>;
  IdentificationNssfNumber?: Maybe<Order_By>;
  IdentificationPassportNo?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationVillageId?: Maybe<Order_By>;
  IdentificationVoterId?: Maybe<Order_By>;
  IdentificationWorkId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressBuildingName?: Maybe<Order_By>;
  PreviousBusinessAddressCounty?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrict?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParish?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressStreetName?: Maybe<Order_By>;
  PreviousBusinessAddressSubCounty?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressTradingCenter?: Maybe<Order_By>;
  PreviousBusinessAddressVillage?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCounty?: Maybe<Order_By>;
  ResidentialAddressDistrict?: Maybe<Order_By>;
  ResidentialAddressParish?: Maybe<Order_By>;
  ResidentialAddressSubCounty?: Maybe<Order_By>;
  ResidentialAddressVillage?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetName?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TradingCenter?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Mutation_Response = {
  __typename?: "rtcs_db_RPT_KccaCustomerRegistrationData_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rpt_KccaCustomerRegistrationData>;
};

/** input type for inserting object relation for remote table "rtcs_db.RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rpt_KccaCustomerRegistrationData_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Birthdate?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  BoxNumber?: Maybe<Order_By>;
  Brn?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerApplicantType?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessType?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerType?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FormerBusinessName?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationDiplomaticId?: Maybe<Order_By>;
  IdentificationDrivingPermit?: Maybe<Order_By>;
  IdentificationEmployeeId?: Maybe<Order_By>;
  IdentificationFinancialCard?: Maybe<Order_By>;
  IdentificationNationalId?: Maybe<Order_By>;
  IdentificationNssfNumber?: Maybe<Order_By>;
  IdentificationPassportNo?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationVillageId?: Maybe<Order_By>;
  IdentificationVoterId?: Maybe<Order_By>;
  IdentificationWorkId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressBuildingName?: Maybe<Order_By>;
  PreviousBusinessAddressCounty?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrict?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParish?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressStreetName?: Maybe<Order_By>;
  PreviousBusinessAddressSubCounty?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressTradingCenter?: Maybe<Order_By>;
  PreviousBusinessAddressVillage?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCounty?: Maybe<Order_By>;
  ResidentialAddressDistrict?: Maybe<Order_By>;
  ResidentialAddressParish?: Maybe<Order_By>;
  ResidentialAddressSubCounty?: Maybe<Order_By>;
  ResidentialAddressVillage?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetName?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TradingCenter?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.RPT_KccaCustomerRegistrationData" */
export enum Rtcs_Db_Rpt_KccaCustomerRegistrationData_Select_Column {
  /** column name */
  AcquiredName = "AcquiredName",
  /** column name */
  Birthdate = "Birthdate",
  /** column name */
  BoxDistrictId = "BoxDistrictId",
  /** column name */
  BoxNumber = "BoxNumber",
  /** column name */
  Brn = "Brn",
  /** column name */
  BuildingName = "BuildingName",
  /** column name */
  BusinessName = "BusinessName",
  /** column name */
  CitizenshipCountryId = "CitizenshipCountryId",
  /** column name */
  Coin = "Coin",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerApplicantType = "CustomerApplicantType",
  /** column name */
  CustomerApplicantTypeId = "CustomerApplicantTypeId",
  /** column name */
  CustomerBusinessType = "CustomerBusinessType",
  /** column name */
  CustomerBusinessTypeId = "CustomerBusinessTypeId",
  /** column name */
  CustomerType = "CustomerType",
  /** column name */
  CustomerTypeId = "CustomerTypeId",
  /** column name */
  DateOfIncorporation = "DateOfIncorporation",
  /** column name */
  Email = "Email",
  /** column name */
  EntityLegalName = "EntityLegalName",
  /** column name */
  Fax = "Fax",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  FormerBusinessName = "FormerBusinessName",
  /** column name */
  GenderId = "GenderId",
  /** column name */
  HaveAlternatePrimaryContact = "HaveAlternatePrimaryContact",
  /** column name */
  HaveReference = "HaveReference",
  /** column name */
  HaveRevenueAgent = "HaveRevenueAgent",
  /** column name */
  Id = "Id",
  /** column name */
  IdentificationDiplomaticId = "IdentificationDiplomaticId",
  /** column name */
  IdentificationDrivingPermit = "IdentificationDrivingPermit",
  /** column name */
  IdentificationEmployeeId = "IdentificationEmployeeId",
  /** column name */
  IdentificationFinancialCard = "IdentificationFinancialCard",
  /** column name */
  IdentificationNationalId = "IdentificationNationalId",
  /** column name */
  IdentificationNssfNumber = "IdentificationNssfNumber",
  /** column name */
  IdentificationPassportNo = "IdentificationPassportNo",
  /** column name */
  IdentificationRefugeeId = "IdentificationRefugeeId",
  /** column name */
  IdentificationVillageId = "IdentificationVillageId",
  /** column name */
  IdentificationVoterId = "IdentificationVoterId",
  /** column name */
  IdentificationWorkId = "IdentificationWorkId",
  /** column name */
  IdentificationWorkPermit = "IdentificationWorkPermit",
  /** column name */
  IsForcedAction = "IsForcedAction",
  /** column name */
  IsMinor = "IsMinor",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  MotherMaidenName = "MotherMaidenName",
  /** column name */
  Photo = "Photo",
  /** column name */
  PhotoContent = "PhotoContent",
  /** column name */
  PhotoMime = "PhotoMime",
  /** column name */
  PhotoName = "PhotoName",
  /** column name */
  PhotoSize = "PhotoSize",
  /** column name */
  PlotNumber = "PlotNumber",
  /** column name */
  PreviousBusinessAddressBuildingName = "PreviousBusinessAddressBuildingName",
  /** column name */
  PreviousBusinessAddressCounty = "PreviousBusinessAddressCounty",
  /** column name */
  PreviousBusinessAddressCountyId = "PreviousBusinessAddressCountyId",
  /** column name */
  PreviousBusinessAddressDistrict = "PreviousBusinessAddressDistrict",
  /** column name */
  PreviousBusinessAddressDistrictId = "PreviousBusinessAddressDistrictId",
  /** column name */
  PreviousBusinessAddressParish = "PreviousBusinessAddressParish",
  /** column name */
  PreviousBusinessAddressParishId = "PreviousBusinessAddressParishId",
  /** column name */
  PreviousBusinessAddressPlotNumber = "PreviousBusinessAddressPlotNumber",
  /** column name */
  PreviousBusinessAddressStreetName = "PreviousBusinessAddressStreetName",
  /** column name */
  PreviousBusinessAddressSubCounty = "PreviousBusinessAddressSubCounty",
  /** column name */
  PreviousBusinessAddressSubCountyId = "PreviousBusinessAddressSubCountyId",
  /** column name */
  PreviousBusinessAddressTradingCenter = "PreviousBusinessAddressTradingCenter",
  /** column name */
  PreviousBusinessAddressVillage = "PreviousBusinessAddressVillage",
  /** column name */
  PreviousBusinessAddressVillageId = "PreviousBusinessAddressVillageId",
  /** column name */
  PreviousBusinessName = "PreviousBusinessName",
  /** column name */
  ResidentialAddressCounty = "ResidentialAddressCounty",
  /** column name */
  ResidentialAddressDistrict = "ResidentialAddressDistrict",
  /** column name */
  ResidentialAddressParish = "ResidentialAddressParish",
  /** column name */
  ResidentialAddressSubCounty = "ResidentialAddressSubCounty",
  /** column name */
  ResidentialAddressVillage = "ResidentialAddressVillage",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  SecondMobile = "SecondMobile",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  StreetName = "StreetName",
  /** column name */
  Surname = "Surname",
  /** column name */
  Telephone = "Telephone",
  /** column name */
  Tin = "Tin",
  /** column name */
  TitleId = "TitleId",
  /** column name */
  TradingCenter = "TradingCenter",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Set_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["String"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerApplicantType?: Maybe<Scalars["String"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessType?: Maybe<Scalars["String"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerType?: Maybe<Scalars["String"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCounty?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrict?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParish?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCounty?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillage?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["String"]>;
  ResidentialAddressCounty?: Maybe<Scalars["String"]>;
  ResidentialAddressDistrict?: Maybe<Scalars["String"]>;
  ResidentialAddressParish?: Maybe<Scalars["String"]>;
  ResidentialAddressSubCounty?: Maybe<Scalars["String"]>;
  ResidentialAddressVillage?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Stddev_Fields = {
  __typename?: "rtcs_db_RPT_KccaCustomerRegistrationData_stddev_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Stddev_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_RPT_KccaCustomerRegistrationData_stddev_pop_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Stddev_Pop_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_RPT_KccaCustomerRegistrationData_stddev_samp_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Stddev_Samp_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Sum_Fields = {
  __typename?: "rtcs_db_RPT_KccaCustomerRegistrationData_sum_fields";
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TitleId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Sum_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Var_Pop_Fields = {
  __typename?: "rtcs_db_RPT_KccaCustomerRegistrationData_var_pop_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Var_Pop_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Var_Samp_Fields = {
  __typename?: "rtcs_db_RPT_KccaCustomerRegistrationData_var_samp_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Var_Samp_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Variance_Fields = {
  __typename?: "rtcs_db_RPT_KccaCustomerRegistrationData_variance_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.RPT_KccaCustomerRegistrationData" */
export type Rtcs_Db_Rpt_KccaCustomerRegistrationData_Variance_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Rpt_KccaPropertyRegistration = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistration";
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  Address?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  ContactEmail?: Maybe<Scalars["String"]>;
  ContactLandline?: Maybe<Scalars["String"]>;
  ContactMobile1?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  OwnerFirstname?: Maybe<Scalars["String"]>;
  OwnerIndividualCompany?: Maybe<Scalars["String"]>;
  OwnerLegalSurnameMaiden?: Maybe<Scalars["String"]>;
  OwnerMiddlename?: Maybe<Scalars["String"]>;
  OwnerOthernames?: Maybe<Scalars["String"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["float8"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  ParticularsTypeOfLandInterest?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatus?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidOwner?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistrationData";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatus?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Aggregate = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistrationData_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rpt_KccaPropertyRegistrationData>;
};

/** aggregate fields of "rtcs_db.RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Aggregate_Fields = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistrationData_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Avg_Fields = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistrationData_avg_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Avg_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.RPT_KccaPropertyRegistrationData". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  BlockNo?: Maybe<String_Comparison_Exp>;
  CamvId?: Maybe<String_Comparison_Exp>;
  CreatedBy?: Maybe<String_Comparison_Exp>;
  CreatedDate?: Maybe<String_Comparison_Exp>;
  CurrentRateableValue?: Maybe<String_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  Division?: Maybe<String_Comparison_Exp>;
  DivisionId?: Maybe<Bigint_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  EntityLegalName?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  GprsCordinateX?: Maybe<String_Comparison_Exp>;
  GprsCordinateY?: Maybe<String_Comparison_Exp>;
  GrossValue?: Maybe<String_Comparison_Exp>;
  HouseNo?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  ImgPath?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<String_Comparison_Exp>;
  Latitude?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  LocationAddress?: Maybe<String_Comparison_Exp>;
  Longitude?: Maybe<String_Comparison_Exp>;
  MapTitle?: Maybe<String_Comparison_Exp>;
  Mobile?: Maybe<String_Comparison_Exp>;
  ModifiedBy?: Maybe<String_Comparison_Exp>;
  ModifiedDate?: Maybe<String_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  Parish?: Maybe<String_Comparison_Exp>;
  ParishId?: Maybe<Bigint_Comparison_Exp>;
  ParticularsFrontages?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyNo?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatus?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatusId?: Maybe<Bigint_Comparison_Exp>;
  PropertySubType?: Maybe<String_Comparison_Exp>;
  PropertyType?: Maybe<String_Comparison_Exp>;
  PropertyTypeId?: Maybe<Bigint_Comparison_Exp>;
  RateableValue?: Maybe<String_Comparison_Exp>;
  Remisson?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  RoadOthers?: Maybe<String_Comparison_Exp>;
  SecondMobile?: Maybe<String_Comparison_Exp>;
  SerialNo?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Float8_Comparison_Exp>;
  StreetId?: Maybe<String_Comparison_Exp>;
  SubPropertyTypeId?: Maybe<Float8_Comparison_Exp>;
  Supplementary?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<String_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  VillageId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Inc_Input = {
  DivisionId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatus?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Max_Fields = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistrationData_max_fields";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatus?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Max_Order_By = {
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GprsCordinateX?: Maybe<Order_By>;
  GprsCordinateY?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LocationAddress?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatus?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertySubType?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RoadOthers?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  SerialNo?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Min_Fields = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistrationData_min_fields";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatus?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Min_Order_By = {
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GprsCordinateX?: Maybe<Order_By>;
  GprsCordinateY?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LocationAddress?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatus?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertySubType?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RoadOthers?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  SerialNo?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Mutation_Response = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistrationData_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rpt_KccaPropertyRegistrationData>;
};

/** input type for inserting object relation for remote table "rtcs_db.RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rpt_KccaPropertyRegistrationData_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Order_By = {
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GprsCordinateX?: Maybe<Order_By>;
  GprsCordinateY?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LocationAddress?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatus?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertySubType?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RoadOthers?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  SerialNo?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.RPT_KccaPropertyRegistrationData" */
export enum Rtcs_Db_Rpt_KccaPropertyRegistrationData_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  CamvId = "CamvId",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CurrentRateableValue = "CurrentRateableValue",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  Division = "Division",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  Email = "Email",
  /** column name */
  EntityLegalName = "EntityLegalName",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  GprsCordinateX = "GprsCordinateX",
  /** column name */
  GprsCordinateY = "GprsCordinateY",
  /** column name */
  GrossValue = "GrossValue",
  /** column name */
  HouseNo = "HouseNo",
  /** column name */
  Id = "Id",
  /** column name */
  ImgPath = "ImgPath",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  LocationAddress = "LocationAddress",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MapTitle = "MapTitle",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  Name = "Name",
  /** column name */
  Parish = "Parish",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  ParticularsFrontages = "ParticularsFrontages",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyNo = "PropertyNo",
  /** column name */
  PropertyRentedStatus = "PropertyRentedStatus",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertySubType = "PropertySubType",
  /** column name */
  PropertyType = "PropertyType",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  RateableValue = "RateableValue",
  /** column name */
  Remisson = "Remisson",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  RoadOthers = "RoadOthers",
  /** column name */
  SecondMobile = "SecondMobile",
  /** column name */
  SerialNo = "SerialNo",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  SubPropertyTypeId = "SubPropertyTypeId",
  /** column name */
  Supplementary = "Supplementary",
  /** column name */
  Surname = "Surname",
  /** column name */
  Tin = "Tin",
  /** column name */
  Village = "Village",
  /** column name */
  VillageId = "VillageId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatus?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Stddev_Fields = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistrationData_stddev_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Stddev_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistrationData_stddev_pop_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Stddev_Pop_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistrationData_stddev_samp_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Stddev_Samp_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Sum_Fields = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistrationData_sum_fields";
  DivisionId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Sum_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Var_Pop_Fields = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistrationData_var_pop_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Var_Pop_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Var_Samp_Fields = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistrationData_var_samp_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Var_Samp_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Variance_Fields = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistrationData_variance_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.RPT_KccaPropertyRegistrationData" */
export type Rtcs_Db_Rpt_KccaPropertyRegistrationData_Variance_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregated selection of "rtcs_db.RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Aggregate = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistration_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rpt_KccaPropertyRegistration>;
};

/** aggregate fields of "rtcs_db.RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Aggregate_Fields = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistration_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rpt_KccaPropertyRegistration_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rpt_KccaPropertyRegistration_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Avg_Fields = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistration_avg_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Avg_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.RPT_KccaPropertyRegistration". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Bool_Exp = {
  AccomodationBedrooms?: Maybe<Float8_Comparison_Exp>;
  Address?: Maybe<String_Comparison_Exp>;
  Birthdate?: Maybe<String_Comparison_Exp>;
  BlockNo?: Maybe<String_Comparison_Exp>;
  BusinessName?: Maybe<String_Comparison_Exp>;
  CamvId?: Maybe<String_Comparison_Exp>;
  Coin?: Maybe<Float8_Comparison_Exp>;
  ContactEmail?: Maybe<String_Comparison_Exp>;
  ContactLandline?: Maybe<String_Comparison_Exp>;
  ContactMobile1?: Maybe<String_Comparison_Exp>;
  CreatedBy?: Maybe<String_Comparison_Exp>;
  CreatedDate?: Maybe<String_Comparison_Exp>;
  CurrentRateableValue?: Maybe<String_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  Division?: Maybe<String_Comparison_Exp>;
  DivisionId?: Maybe<Bigint_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  EntityLegalName?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  GprsCordinateX?: Maybe<String_Comparison_Exp>;
  GprsCordinateY?: Maybe<String_Comparison_Exp>;
  GrossValue?: Maybe<String_Comparison_Exp>;
  HouseNo?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  ImgPath?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<String_Comparison_Exp>;
  Latitude?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  LocationAddress?: Maybe<String_Comparison_Exp>;
  Longitude?: Maybe<String_Comparison_Exp>;
  MapTitle?: Maybe<String_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  Mobile?: Maybe<String_Comparison_Exp>;
  ModifiedBy?: Maybe<String_Comparison_Exp>;
  ModifiedDate?: Maybe<String_Comparison_Exp>;
  MotherMaidenName?: Maybe<String_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  OwnerFirstname?: Maybe<String_Comparison_Exp>;
  OwnerIndividualCompany?: Maybe<String_Comparison_Exp>;
  OwnerLegalSurnameMaiden?: Maybe<String_Comparison_Exp>;
  OwnerMiddlename?: Maybe<String_Comparison_Exp>;
  OwnerOthernames?: Maybe<String_Comparison_Exp>;
  OwnerTin?: Maybe<Float8_Comparison_Exp>;
  OwnerTitle?: Maybe<Float8_Comparison_Exp>;
  Parish?: Maybe<String_Comparison_Exp>;
  ParishId?: Maybe<Bigint_Comparison_Exp>;
  ParticularsFrontages?: Maybe<String_Comparison_Exp>;
  ParticularsTypeOfLandInterest?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyNo?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatus?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatusId?: Maybe<Bigint_Comparison_Exp>;
  PropertySubType?: Maybe<String_Comparison_Exp>;
  PropertyType?: Maybe<String_Comparison_Exp>;
  PropertyTypeId?: Maybe<Bigint_Comparison_Exp>;
  RateableValue?: Maybe<String_Comparison_Exp>;
  Remisson?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  RnidOwner?: Maybe<String_Comparison_Exp>;
  RoadOthers?: Maybe<String_Comparison_Exp>;
  SecondMobile?: Maybe<String_Comparison_Exp>;
  SerialNo?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Float8_Comparison_Exp>;
  StreetId?: Maybe<String_Comparison_Exp>;
  SubPropertyTypeId?: Maybe<Float8_Comparison_Exp>;
  Supplementary?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<String_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  VillageId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Inc_Input = {
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Insert_Input = {
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  Address?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  ContactEmail?: Maybe<Scalars["String"]>;
  ContactLandline?: Maybe<Scalars["String"]>;
  ContactMobile1?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  OwnerFirstname?: Maybe<Scalars["String"]>;
  OwnerIndividualCompany?: Maybe<Scalars["String"]>;
  OwnerLegalSurnameMaiden?: Maybe<Scalars["String"]>;
  OwnerMiddlename?: Maybe<Scalars["String"]>;
  OwnerOthernames?: Maybe<Scalars["String"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["float8"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  ParticularsTypeOfLandInterest?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatus?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidOwner?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Max_Fields = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistration_max_fields";
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  Address?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  ContactEmail?: Maybe<Scalars["String"]>;
  ContactLandline?: Maybe<Scalars["String"]>;
  ContactMobile1?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  OwnerFirstname?: Maybe<Scalars["String"]>;
  OwnerIndividualCompany?: Maybe<Scalars["String"]>;
  OwnerLegalSurnameMaiden?: Maybe<Scalars["String"]>;
  OwnerMiddlename?: Maybe<Scalars["String"]>;
  OwnerOthernames?: Maybe<Scalars["String"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["float8"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  ParticularsTypeOfLandInterest?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatus?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidOwner?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Max_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  Address?: Maybe<Order_By>;
  Birthdate?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  ContactEmail?: Maybe<Order_By>;
  ContactLandline?: Maybe<Order_By>;
  ContactMobile1?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GprsCordinateX?: Maybe<Order_By>;
  GprsCordinateY?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LocationAddress?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OwnerFirstname?: Maybe<Order_By>;
  OwnerIndividualCompany?: Maybe<Order_By>;
  OwnerLegalSurnameMaiden?: Maybe<Order_By>;
  OwnerMiddlename?: Maybe<Order_By>;
  OwnerOthernames?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  ParticularsTypeOfLandInterest?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatus?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertySubType?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidOwner?: Maybe<Order_By>;
  RoadOthers?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  SerialNo?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Min_Fields = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistration_min_fields";
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  Address?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  ContactEmail?: Maybe<Scalars["String"]>;
  ContactLandline?: Maybe<Scalars["String"]>;
  ContactMobile1?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  OwnerFirstname?: Maybe<Scalars["String"]>;
  OwnerIndividualCompany?: Maybe<Scalars["String"]>;
  OwnerLegalSurnameMaiden?: Maybe<Scalars["String"]>;
  OwnerMiddlename?: Maybe<Scalars["String"]>;
  OwnerOthernames?: Maybe<Scalars["String"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["float8"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  ParticularsTypeOfLandInterest?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatus?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidOwner?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Min_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  Address?: Maybe<Order_By>;
  Birthdate?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  ContactEmail?: Maybe<Order_By>;
  ContactLandline?: Maybe<Order_By>;
  ContactMobile1?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GprsCordinateX?: Maybe<Order_By>;
  GprsCordinateY?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LocationAddress?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OwnerFirstname?: Maybe<Order_By>;
  OwnerIndividualCompany?: Maybe<Order_By>;
  OwnerLegalSurnameMaiden?: Maybe<Order_By>;
  OwnerMiddlename?: Maybe<Order_By>;
  OwnerOthernames?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  ParticularsTypeOfLandInterest?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatus?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertySubType?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidOwner?: Maybe<Order_By>;
  RoadOthers?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  SerialNo?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Mutation_Response = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistration_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rpt_KccaPropertyRegistration>;
};

/** input type for inserting object relation for remote table "rtcs_db.RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rpt_KccaPropertyRegistration_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  Address?: Maybe<Order_By>;
  Birthdate?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  ContactEmail?: Maybe<Order_By>;
  ContactLandline?: Maybe<Order_By>;
  ContactMobile1?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GprsCordinateX?: Maybe<Order_By>;
  GprsCordinateY?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LocationAddress?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OwnerFirstname?: Maybe<Order_By>;
  OwnerIndividualCompany?: Maybe<Order_By>;
  OwnerLegalSurnameMaiden?: Maybe<Order_By>;
  OwnerMiddlename?: Maybe<Order_By>;
  OwnerOthernames?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  ParticularsTypeOfLandInterest?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatus?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertySubType?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidOwner?: Maybe<Order_By>;
  RoadOthers?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  SerialNo?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.RPT_KccaPropertyRegistration" */
export enum Rtcs_Db_Rpt_KccaPropertyRegistration_Select_Column {
  /** column name */
  AccomodationBedrooms = "AccomodationBedrooms",
  /** column name */
  Address = "Address",
  /** column name */
  Birthdate = "Birthdate",
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  BusinessName = "BusinessName",
  /** column name */
  CamvId = "CamvId",
  /** column name */
  Coin = "Coin",
  /** column name */
  ContactEmail = "ContactEmail",
  /** column name */
  ContactLandline = "ContactLandline",
  /** column name */
  ContactMobile1 = "ContactMobile1",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CurrentRateableValue = "CurrentRateableValue",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  Division = "Division",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  Email = "Email",
  /** column name */
  EntityLegalName = "EntityLegalName",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  GprsCordinateX = "GprsCordinateX",
  /** column name */
  GprsCordinateY = "GprsCordinateY",
  /** column name */
  GrossValue = "GrossValue",
  /** column name */
  HouseNo = "HouseNo",
  /** column name */
  Id = "Id",
  /** column name */
  ImgPath = "ImgPath",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  LocationAddress = "LocationAddress",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MapTitle = "MapTitle",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  MotherMaidenName = "MotherMaidenName",
  /** column name */
  Name = "Name",
  /** column name */
  OwnerFirstname = "OwnerFirstname",
  /** column name */
  OwnerIndividualCompany = "OwnerIndividualCompany",
  /** column name */
  OwnerLegalSurnameMaiden = "OwnerLegalSurnameMaiden",
  /** column name */
  OwnerMiddlename = "OwnerMiddlename",
  /** column name */
  OwnerOthernames = "OwnerOthernames",
  /** column name */
  OwnerTin = "OwnerTin",
  /** column name */
  OwnerTitle = "OwnerTitle",
  /** column name */
  Parish = "Parish",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  ParticularsFrontages = "ParticularsFrontages",
  /** column name */
  ParticularsTypeOfLandInterest = "ParticularsTypeOfLandInterest",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyNo = "PropertyNo",
  /** column name */
  PropertyRentedStatus = "PropertyRentedStatus",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertySubType = "PropertySubType",
  /** column name */
  PropertyType = "PropertyType",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  RateableValue = "RateableValue",
  /** column name */
  Remisson = "Remisson",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  RnidOwner = "RnidOwner",
  /** column name */
  RoadOthers = "RoadOthers",
  /** column name */
  SecondMobile = "SecondMobile",
  /** column name */
  SerialNo = "SerialNo",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  SubPropertyTypeId = "SubPropertyTypeId",
  /** column name */
  Supplementary = "Supplementary",
  /** column name */
  Surname = "Surname",
  /** column name */
  Tin = "Tin",
  /** column name */
  Village = "Village",
  /** column name */
  VillageId = "VillageId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Set_Input = {
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  Address?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  ContactEmail?: Maybe<Scalars["String"]>;
  ContactLandline?: Maybe<Scalars["String"]>;
  ContactMobile1?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  OwnerFirstname?: Maybe<Scalars["String"]>;
  OwnerIndividualCompany?: Maybe<Scalars["String"]>;
  OwnerLegalSurnameMaiden?: Maybe<Scalars["String"]>;
  OwnerMiddlename?: Maybe<Scalars["String"]>;
  OwnerOthernames?: Maybe<Scalars["String"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["float8"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  ParticularsTypeOfLandInterest?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatus?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidOwner?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Stddev_Fields = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistration_stddev_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Stddev_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistration_stddev_pop_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Stddev_Pop_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistration_stddev_samp_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Stddev_Samp_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Sum_Fields = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistration_sum_fields";
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Sum_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Var_Pop_Fields = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistration_var_pop_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Var_Pop_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Var_Samp_Fields = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistration_var_samp_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Var_Samp_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Variance_Fields = {
  __typename?: "rtcs_db_RPT_KccaPropertyRegistration_variance_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.RPT_KccaPropertyRegistration" */
export type Rtcs_Db_Rpt_KccaPropertyRegistration_Variance_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet = {
  __typename?: "rtcs_db_RPT_NonIndividualBalanceSheet";
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  ApplicationFund?: Maybe<Scalars["String"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetCurrentAsset?: Maybe<Scalars["String"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  OthrLiability?: Maybe<Scalars["String"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProfitLossAccount?: Maybe<Scalars["String"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  RtnId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnId?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAccntRcvdDebtors?: Maybe<Scalars["String"]>;
  TotAssetLoanAdvance?: Maybe<Scalars["String"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotCurrLiability?: Maybe<Scalars["String"]>;
  TotCurrentAsset?: Maybe<Scalars["String"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLiabilityProv?: Maybe<Scalars["String"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalLoanFund?: Maybe<Scalars["String"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureCreditorLoan?: Maybe<Scalars["String"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Aggregate = {
  __typename?: "rtcs_db_RPT_NonIndividualBalanceSheet_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rpt_NonIndividualBalanceSheet>;
};

/** aggregate fields of "rtcs_db.RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Aggregate_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualBalanceSheet_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Avg_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualBalanceSheet_avg_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Avg_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.RPT_NonIndividualBalanceSheet". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Bool_Exp = {
  AccntRcvdOthers?: Maybe<Float8_Comparison_Exp>;
  AccntRcvdOverSix?: Maybe<Float8_Comparison_Exp>;
  AdvanceCorporate?: Maybe<Float8_Comparison_Exp>;
  AdvanceRecoverable?: Maybe<Float8_Comparison_Exp>;
  ApplicationFund?: Maybe<String_Comparison_Exp>;
  BalRevenueAuth?: Maybe<Float8_Comparison_Exp>;
  BankBalance?: Maybe<Float8_Comparison_Exp>;
  BankOverDraft?: Maybe<Float8_Comparison_Exp>;
  CnsmPckInventory?: Maybe<Float8_Comparison_Exp>;
  Debenture?: Maybe<Float8_Comparison_Exp>;
  DeferTaxLiability?: Maybe<Float8_Comparison_Exp>;
  DeferredAsset?: Maybe<Float8_Comparison_Exp>;
  Deposits?: Maybe<Float8_Comparison_Exp>;
  Depreciation?: Maybe<Float8_Comparison_Exp>;
  EquityShare?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FinishTradeGoods?: Maybe<Float8_Comparison_Exp>;
  FixedDeposit?: Maybe<Float8_Comparison_Exp>;
  FurnitureFixture?: Maybe<Float8_Comparison_Exp>;
  GeneralReserve?: Maybe<Float8_Comparison_Exp>;
  GovtSecurities?: Maybe<Float8_Comparison_Exp>;
  HandCash?: Maybe<Float8_Comparison_Exp>;
  IncomeTaxProv?: Maybe<Float8_Comparison_Exp>;
  IntLeaseAsset?: Maybe<Float8_Comparison_Exp>;
  IntangibleAssets?: Maybe<Float8_Comparison_Exp>;
  IsAmendment?: Maybe<String_Comparison_Exp>;
  LandBuild?: Maybe<Float8_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  LessDrawings?: Maybe<Float8_Comparison_Exp>;
  LiableLeaseAsset?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  LoanSubCompany?: Maybe<Float8_Comparison_Exp>;
  MotorVehicles?: Maybe<Float8_Comparison_Exp>;
  NetCurrentAsset?: Maybe<String_Comparison_Exp>;
  NetFixedAsset?: Maybe<Float8_Comparison_Exp>;
  OtherInvestments?: Maybe<Float8_Comparison_Exp>;
  OtherProvision?: Maybe<Float8_Comparison_Exp>;
  OtherReserve?: Maybe<Float8_Comparison_Exp>;
  OthrCurrentAsset?: Maybe<Float8_Comparison_Exp>;
  OthrDeprecAsset?: Maybe<Float8_Comparison_Exp>;
  OthrLiability?: Maybe<String_Comparison_Exp>;
  PlantMachine?: Maybe<Float8_Comparison_Exp>;
  Prepayments?: Maybe<Float8_Comparison_Exp>;
  ProfitLossAccount?: Maybe<String_Comparison_Exp>;
  ProprietorCapital?: Maybe<Float8_Comparison_Exp>;
  ProvForBadDebts?: Maybe<Float8_Comparison_Exp>;
  RawMtrlInventory?: Maybe<Float8_Comparison_Exp>;
  ReturnType?: Maybe<String_Comparison_Exp>;
  RevaluationReserve?: Maybe<Float8_Comparison_Exp>;
  RtnId?: Maybe<String_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnNoRtnId?: Maybe<String_Comparison_Exp>;
  RtnPeriodYear?: Maybe<String_Comparison_Exp>;
  SecureDebtIssued?: Maybe<Float8_Comparison_Exp>;
  SecureLoanBank?: Maybe<Float8_Comparison_Exp>;
  SecureLoanOther?: Maybe<Float8_Comparison_Exp>;
  SecureLoanParties?: Maybe<Float8_Comparison_Exp>;
  SecureTotLoan?: Maybe<String_Comparison_Exp>;
  SourceFund?: Maybe<String_Comparison_Exp>;
  StockInProcess?: Maybe<Float8_Comparison_Exp>;
  SundryCreditors?: Maybe<Float8_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  TotAccntRcvdDebtors?: Maybe<String_Comparison_Exp>;
  TotAssetLoanAdvance?: Maybe<String_Comparison_Exp>;
  TotBalanceAvail?: Maybe<Float8_Comparison_Exp>;
  TotCurrLiability?: Maybe<String_Comparison_Exp>;
  TotCurrentAsset?: Maybe<String_Comparison_Exp>;
  TotFixedAsset?: Maybe<Float8_Comparison_Exp>;
  TotInventory?: Maybe<Float8_Comparison_Exp>;
  TotLiabilityProv?: Maybe<String_Comparison_Exp>;
  TotLoanAdvance?: Maybe<Float8_Comparison_Exp>;
  TotTradeInvestment?: Maybe<Float8_Comparison_Exp>;
  TotalLoanFund?: Maybe<String_Comparison_Exp>;
  TotalPropFund?: Maybe<String_Comparison_Exp>;
  TotalProvision?: Maybe<Float8_Comparison_Exp>;
  TranslationReserve?: Maybe<Float8_Comparison_Exp>;
  UnpaidMatureDebts?: Maybe<Float8_Comparison_Exp>;
  UnsecureCreditorLoan?: Maybe<String_Comparison_Exp>;
  UnsecureLoanBank?: Maybe<Float8_Comparison_Exp>;
  UnsecureLoanOther?: Maybe<Float8_Comparison_Exp>;
  UnsecureLoanParties?: Maybe<Float8_Comparison_Exp>;
  UnsecureTotLoan?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Inc_Input = {
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Insert_Input = {
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  ApplicationFund?: Maybe<Scalars["String"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetCurrentAsset?: Maybe<Scalars["String"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  OthrLiability?: Maybe<Scalars["String"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProfitLossAccount?: Maybe<Scalars["String"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  RtnId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnId?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAccntRcvdDebtors?: Maybe<Scalars["String"]>;
  TotAssetLoanAdvance?: Maybe<Scalars["String"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotCurrLiability?: Maybe<Scalars["String"]>;
  TotCurrentAsset?: Maybe<Scalars["String"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLiabilityProv?: Maybe<Scalars["String"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalLoanFund?: Maybe<Scalars["String"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureCreditorLoan?: Maybe<Scalars["String"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Max_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualBalanceSheet_max_fields";
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  ApplicationFund?: Maybe<Scalars["String"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetCurrentAsset?: Maybe<Scalars["String"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  OthrLiability?: Maybe<Scalars["String"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProfitLossAccount?: Maybe<Scalars["String"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  RtnId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnId?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAccntRcvdDebtors?: Maybe<Scalars["String"]>;
  TotAssetLoanAdvance?: Maybe<Scalars["String"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotCurrLiability?: Maybe<Scalars["String"]>;
  TotCurrentAsset?: Maybe<Scalars["String"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLiabilityProv?: Maybe<Scalars["String"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalLoanFund?: Maybe<Scalars["String"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureCreditorLoan?: Maybe<Scalars["String"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Max_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  ApplicationFund?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetCurrentAsset?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  OthrLiability?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProfitLossAccount?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  RtnId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnId?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  SecureTotLoan?: Maybe<Order_By>;
  SourceFund?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotAccntRcvdDebtors?: Maybe<Order_By>;
  TotAssetLoanAdvance?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotCurrLiability?: Maybe<Order_By>;
  TotCurrentAsset?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLiabilityProv?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalLoanFund?: Maybe<Order_By>;
  TotalPropFund?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureCreditorLoan?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
  UnsecureTotLoan?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Min_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualBalanceSheet_min_fields";
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  ApplicationFund?: Maybe<Scalars["String"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetCurrentAsset?: Maybe<Scalars["String"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  OthrLiability?: Maybe<Scalars["String"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProfitLossAccount?: Maybe<Scalars["String"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  RtnId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnId?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAccntRcvdDebtors?: Maybe<Scalars["String"]>;
  TotAssetLoanAdvance?: Maybe<Scalars["String"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotCurrLiability?: Maybe<Scalars["String"]>;
  TotCurrentAsset?: Maybe<Scalars["String"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLiabilityProv?: Maybe<Scalars["String"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalLoanFund?: Maybe<Scalars["String"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureCreditorLoan?: Maybe<Scalars["String"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Min_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  ApplicationFund?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetCurrentAsset?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  OthrLiability?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProfitLossAccount?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  RtnId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnId?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  SecureTotLoan?: Maybe<Order_By>;
  SourceFund?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotAccntRcvdDebtors?: Maybe<Order_By>;
  TotAssetLoanAdvance?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotCurrLiability?: Maybe<Order_By>;
  TotCurrentAsset?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLiabilityProv?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalLoanFund?: Maybe<Order_By>;
  TotalPropFund?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureCreditorLoan?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
  UnsecureTotLoan?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Mutation_Response = {
  __typename?: "rtcs_db_RPT_NonIndividualBalanceSheet_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rpt_NonIndividualBalanceSheet>;
};

/** input type for inserting object relation for remote table "rtcs_db.RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rpt_NonIndividualBalanceSheet_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  ApplicationFund?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetCurrentAsset?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  OthrLiability?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProfitLossAccount?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  RtnId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnId?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  SecureTotLoan?: Maybe<Order_By>;
  SourceFund?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotAccntRcvdDebtors?: Maybe<Order_By>;
  TotAssetLoanAdvance?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotCurrLiability?: Maybe<Order_By>;
  TotCurrentAsset?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLiabilityProv?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalLoanFund?: Maybe<Order_By>;
  TotalPropFund?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureCreditorLoan?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
  UnsecureTotLoan?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.RPT_NonIndividualBalanceSheet" */
export enum Rtcs_Db_Rpt_NonIndividualBalanceSheet_Select_Column {
  /** column name */
  AccntRcvdOthers = "AccntRcvdOthers",
  /** column name */
  AccntRcvdOverSix = "AccntRcvdOverSix",
  /** column name */
  AdvanceCorporate = "AdvanceCorporate",
  /** column name */
  AdvanceRecoverable = "AdvanceRecoverable",
  /** column name */
  ApplicationFund = "ApplicationFund",
  /** column name */
  BalRevenueAuth = "BalRevenueAuth",
  /** column name */
  BankBalance = "BankBalance",
  /** column name */
  BankOverDraft = "BankOverDraft",
  /** column name */
  CnsmPckInventory = "CnsmPckInventory",
  /** column name */
  Debenture = "Debenture",
  /** column name */
  DeferTaxLiability = "DeferTaxLiability",
  /** column name */
  DeferredAsset = "DeferredAsset",
  /** column name */
  Deposits = "Deposits",
  /** column name */
  Depreciation = "Depreciation",
  /** column name */
  EquityShare = "EquityShare",
  /** column name */
  File = "File",
  /** column name */
  FinishTradeGoods = "FinishTradeGoods",
  /** column name */
  FixedDeposit = "FixedDeposit",
  /** column name */
  FurnitureFixture = "FurnitureFixture",
  /** column name */
  GeneralReserve = "GeneralReserve",
  /** column name */
  GovtSecurities = "GovtSecurities",
  /** column name */
  HandCash = "HandCash",
  /** column name */
  IncomeTaxProv = "IncomeTaxProv",
  /** column name */
  IntLeaseAsset = "IntLeaseAsset",
  /** column name */
  IntangibleAssets = "IntangibleAssets",
  /** column name */
  IsAmendment = "IsAmendment",
  /** column name */
  LandBuild = "LandBuild",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  LessDrawings = "LessDrawings",
  /** column name */
  LiableLeaseAsset = "LiableLeaseAsset",
  /** column name */
  Line = "Line",
  /** column name */
  LoanSubCompany = "LoanSubCompany",
  /** column name */
  MotorVehicles = "MotorVehicles",
  /** column name */
  NetCurrentAsset = "NetCurrentAsset",
  /** column name */
  NetFixedAsset = "NetFixedAsset",
  /** column name */
  OtherInvestments = "OtherInvestments",
  /** column name */
  OtherProvision = "OtherProvision",
  /** column name */
  OtherReserve = "OtherReserve",
  /** column name */
  OthrCurrentAsset = "OthrCurrentAsset",
  /** column name */
  OthrDeprecAsset = "OthrDeprecAsset",
  /** column name */
  OthrLiability = "OthrLiability",
  /** column name */
  PlantMachine = "PlantMachine",
  /** column name */
  Prepayments = "Prepayments",
  /** column name */
  ProfitLossAccount = "ProfitLossAccount",
  /** column name */
  ProprietorCapital = "ProprietorCapital",
  /** column name */
  ProvForBadDebts = "ProvForBadDebts",
  /** column name */
  RawMtrlInventory = "RawMtrlInventory",
  /** column name */
  ReturnType = "ReturnType",
  /** column name */
  RevaluationReserve = "RevaluationReserve",
  /** column name */
  RtnId = "RtnId",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnNoRtnId = "RtnNoRtnId",
  /** column name */
  RtnPeriodYear = "RtnPeriodYear",
  /** column name */
  SecureDebtIssued = "SecureDebtIssued",
  /** column name */
  SecureLoanBank = "SecureLoanBank",
  /** column name */
  SecureLoanOther = "SecureLoanOther",
  /** column name */
  SecureLoanParties = "SecureLoanParties",
  /** column name */
  SecureTotLoan = "SecureTotLoan",
  /** column name */
  SourceFund = "SourceFund",
  /** column name */
  StockInProcess = "StockInProcess",
  /** column name */
  SundryCreditors = "SundryCreditors",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  TotAccntRcvdDebtors = "TotAccntRcvdDebtors",
  /** column name */
  TotAssetLoanAdvance = "TotAssetLoanAdvance",
  /** column name */
  TotBalanceAvail = "TotBalanceAvail",
  /** column name */
  TotCurrLiability = "TotCurrLiability",
  /** column name */
  TotCurrentAsset = "TotCurrentAsset",
  /** column name */
  TotFixedAsset = "TotFixedAsset",
  /** column name */
  TotInventory = "TotInventory",
  /** column name */
  TotLiabilityProv = "TotLiabilityProv",
  /** column name */
  TotLoanAdvance = "TotLoanAdvance",
  /** column name */
  TotTradeInvestment = "TotTradeInvestment",
  /** column name */
  TotalLoanFund = "TotalLoanFund",
  /** column name */
  TotalPropFund = "TotalPropFund",
  /** column name */
  TotalProvision = "TotalProvision",
  /** column name */
  TranslationReserve = "TranslationReserve",
  /** column name */
  UnpaidMatureDebts = "UnpaidMatureDebts",
  /** column name */
  UnsecureCreditorLoan = "UnsecureCreditorLoan",
  /** column name */
  UnsecureLoanBank = "UnsecureLoanBank",
  /** column name */
  UnsecureLoanOther = "UnsecureLoanOther",
  /** column name */
  UnsecureLoanParties = "UnsecureLoanParties",
  /** column name */
  UnsecureTotLoan = "UnsecureTotLoan",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Set_Input = {
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  ApplicationFund?: Maybe<Scalars["String"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetCurrentAsset?: Maybe<Scalars["String"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  OthrLiability?: Maybe<Scalars["String"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProfitLossAccount?: Maybe<Scalars["String"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  RtnId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnId?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAccntRcvdDebtors?: Maybe<Scalars["String"]>;
  TotAssetLoanAdvance?: Maybe<Scalars["String"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotCurrLiability?: Maybe<Scalars["String"]>;
  TotCurrentAsset?: Maybe<Scalars["String"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLiabilityProv?: Maybe<Scalars["String"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalLoanFund?: Maybe<Scalars["String"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureCreditorLoan?: Maybe<Scalars["String"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Stddev_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualBalanceSheet_stddev_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Stddev_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualBalanceSheet_stddev_pop_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Stddev_Pop_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualBalanceSheet_stddev_samp_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Stddev_Samp_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Sum_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualBalanceSheet_sum_fields";
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Sum_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Var_Pop_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualBalanceSheet_var_pop_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Var_Pop_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Var_Samp_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualBalanceSheet_var_samp_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Var_Samp_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Variance_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualBalanceSheet_variance_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.RPT_NonIndividualBalanceSheet" */
export type Rtcs_Db_Rpt_NonIndividualBalanceSheet_Variance_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk = {
  __typename?: "rtcs_db_RPT_NonIndividualComplianceSummaryPlusRisk";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  RiskCode?: Maybe<Scalars["String"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** aggregated selection of "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Aggregate = {
  __typename?: "rtcs_db_RPT_NonIndividualComplianceSummaryPlusRisk_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk>;
};

/** aggregate fields of "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Aggregate_Fields =
  {
    __typename?: "rtcs_db_RPT_NonIndividualComplianceSummaryPlusRisk_aggregate_fields";
    avg?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Avg_Fields>;
    count?: Maybe<Scalars["Int"]>;
    max?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Max_Fields>;
    min?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Min_Fields>;
    stddev?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Stddev_Fields>;
    stddev_pop?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Stddev_Samp_Fields>;
    sum?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Sum_Fields>;
    var_pop?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Var_Pop_Fields>;
    var_samp?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Var_Samp_Fields>;
    variance?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Variance_Fields>;
  };

/** aggregate fields of "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Aggregate_Order_By =
  {
    avg?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Max_Order_By>;
    min?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Min_Order_By>;
    stddev?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Stddev_Order_By>;
    stddev_pop?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Stddev_Samp_Order_By>;
    sum?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Sum_Order_By>;
    var_pop?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Var_Pop_Order_By>;
    var_samp?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Var_Samp_Order_By>;
    variance?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Variance_Order_By>;
  };

/** input type for inserting array relation for remote table "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Avg_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualComplianceSummaryPlusRisk_avg_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Mobilenumber?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  RiskScore?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumofadjustedincome?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Tinno?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Avg_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  RiskScore?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Bool_Exp = {
  Averagerateperrentedproperty?: Maybe<Float8_Comparison_Exp>;
  Compliancecategory?: Maybe<String_Comparison_Exp>;
  Difference?: Maybe<Float8_Comparison_Exp>;
  Emailid?: Maybe<String_Comparison_Exp>;
  Formerbusinessname?: Maybe<String_Comparison_Exp>;
  Grossrentalincome?: Maybe<Float8_Comparison_Exp>;
  Holdingcompname?: Maybe<String_Comparison_Exp>;
  Holdingcomptin?: Maybe<String_Comparison_Exp>;
  Isamendment?: Maybe<String_Comparison_Exp>;
  Isincomesrcbsns?: Maybe<String_Comparison_Exp>;
  Isincomesrcprpty?: Maybe<String_Comparison_Exp>;
  Isincomesrcrental?: Maybe<String_Comparison_Exp>;
  Kccacustomerid?: Maybe<String_Comparison_Exp>;
  Kccaname?: Maybe<String_Comparison_Exp>;
  Matchscore?: Maybe<Float8_Comparison_Exp>;
  Mobilenumber?: Maybe<Float8_Comparison_Exp>;
  Numproperties?: Maybe<Float8_Comparison_Exp>;
  Numrentedproperties?: Maybe<Float8_Comparison_Exp>;
  Orgsubtype?: Maybe<String_Comparison_Exp>;
  Orgtype?: Maybe<String_Comparison_Exp>;
  Percentagedifference?: Maybe<Float8_Comparison_Exp>;
  Rentincincome?: Maybe<Float8_Comparison_Exp>;
  RiskCode?: Maybe<String_Comparison_Exp>;
  RiskScore?: Maybe<Bigint_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Rtnno?: Maybe<String_Comparison_Exp>;
  Rtnperiodyear?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  Sumgrossvalue?: Maybe<Float8_Comparison_Exp>;
  Sumofadjustedincome?: Maybe<Float8_Comparison_Exp>;
  Sumrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Taxpayerassumed?: Maybe<String_Comparison_Exp>;
  Tinno?: Maybe<Float8_Comparison_Exp>;
  Tradingcenter?: Maybe<String_Comparison_Exp>;
  Tradingname?: Maybe<String_Comparison_Exp>;
  Uraname?: Maybe<String_Comparison_Exp>;
  Uratinno?: Maybe<Float8_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Inc_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Insert_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  RiskCode?: Maybe<Scalars["String"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Max_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualComplianceSummaryPlusRisk_max_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  RiskCode?: Maybe<Scalars["String"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** order by max() on columns of table "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Max_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  Formerbusinessname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Holdingcompname?: Maybe<Order_By>;
  Holdingcomptin?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Kccaname?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Orgsubtype?: Maybe<Order_By>;
  Orgtype?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  RiskCode?: Maybe<Order_By>;
  RiskScore?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Taxpayerassumed?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Tradingcenter?: Maybe<Order_By>;
  Tradingname?: Maybe<Order_By>;
  Uraname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Min_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualComplianceSummaryPlusRisk_min_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  RiskCode?: Maybe<Scalars["String"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** order by min() on columns of table "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Min_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  Formerbusinessname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Holdingcompname?: Maybe<Order_By>;
  Holdingcomptin?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Kccaname?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Orgsubtype?: Maybe<Order_By>;
  Orgtype?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  RiskCode?: Maybe<Order_By>;
  RiskScore?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Taxpayerassumed?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Tradingcenter?: Maybe<Order_By>;
  Tradingname?: Maybe<Order_By>;
  Uraname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Mutation_Response =
  {
    __typename?: "rtcs_db_RPT_NonIndividualComplianceSummaryPlusRisk_mutation_response";
    /** number of affected rows by the mutation */
    affected_rows: Scalars["Int"];
    /** data of the affected rows by the mutation */
    returning: Array<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk>;
  };

/** input type for inserting object relation for remote table "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  Formerbusinessname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Holdingcompname?: Maybe<Order_By>;
  Holdingcomptin?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Kccaname?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Orgsubtype?: Maybe<Order_By>;
  Orgtype?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  RiskCode?: Maybe<Order_By>;
  RiskScore?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Taxpayerassumed?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Tradingcenter?: Maybe<Order_By>;
  Tradingname?: Maybe<Order_By>;
  Uraname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
export enum Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Select_Column {
  /** column name */
  Averagerateperrentedproperty = "Averagerateperrentedproperty",
  /** column name */
  Compliancecategory = "Compliancecategory",
  /** column name */
  Difference = "Difference",
  /** column name */
  Emailid = "Emailid",
  /** column name */
  Formerbusinessname = "Formerbusinessname",
  /** column name */
  Grossrentalincome = "Grossrentalincome",
  /** column name */
  Holdingcompname = "Holdingcompname",
  /** column name */
  Holdingcomptin = "Holdingcomptin",
  /** column name */
  Isamendment = "Isamendment",
  /** column name */
  Isincomesrcbsns = "Isincomesrcbsns",
  /** column name */
  Isincomesrcprpty = "Isincomesrcprpty",
  /** column name */
  Isincomesrcrental = "Isincomesrcrental",
  /** column name */
  Kccacustomerid = "Kccacustomerid",
  /** column name */
  Kccaname = "Kccaname",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Mobilenumber = "Mobilenumber",
  /** column name */
  Numproperties = "Numproperties",
  /** column name */
  Numrentedproperties = "Numrentedproperties",
  /** column name */
  Orgsubtype = "Orgsubtype",
  /** column name */
  Orgtype = "Orgtype",
  /** column name */
  Percentagedifference = "Percentagedifference",
  /** column name */
  Rentincincome = "Rentincincome",
  /** column name */
  RiskCode = "RiskCode",
  /** column name */
  RiskScore = "RiskScore",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Rtnno = "Rtnno",
  /** column name */
  Rtnperiodyear = "Rtnperiodyear",
  /** column name */
  Source = "Source",
  /** column name */
  Sumgrossvalue = "Sumgrossvalue",
  /** column name */
  Sumofadjustedincome = "Sumofadjustedincome",
  /** column name */
  Sumrateablevalue = "Sumrateablevalue",
  /** column name */
  Taxpayerassumed = "Taxpayerassumed",
  /** column name */
  Tinno = "Tinno",
  /** column name */
  Tradingcenter = "Tradingcenter",
  /** column name */
  Tradingname = "Tradingname",
  /** column name */
  Uraname = "Uraname",
  /** column name */
  Uratinno = "Uratinno",
}

/** input type for updating data in table "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Set_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  RiskCode?: Maybe<Scalars["String"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Stddev_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualComplianceSummaryPlusRisk_stddev_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Mobilenumber?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  RiskScore?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumofadjustedincome?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Tinno?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Stddev_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Tinno?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Stddev_Pop_Fields =
  {
    __typename?: "rtcs_db_RPT_NonIndividualComplianceSummaryPlusRisk_stddev_pop_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Mobilenumber?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Rentincincome?: Maybe<Scalars["Float"]>;
    RiskScore?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumofadjustedincome?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
    Tinno?: Maybe<Scalars["Float"]>;
    Uratinno?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_pop() on columns of table "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Stddev_Pop_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Tinno?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Stddev_Samp_Fields =
  {
    __typename?: "rtcs_db_RPT_NonIndividualComplianceSummaryPlusRisk_stddev_samp_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Mobilenumber?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Rentincincome?: Maybe<Scalars["Float"]>;
    RiskScore?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumofadjustedincome?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
    Tinno?: Maybe<Scalars["Float"]>;
    Uratinno?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_samp() on columns of table "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Stddev_Samp_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Tinno?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Sum_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualComplianceSummaryPlusRisk_sum_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  RiskScore?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Sum_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  RiskScore?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Var_Pop_Fields =
  {
    __typename?: "rtcs_db_RPT_NonIndividualComplianceSummaryPlusRisk_var_pop_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Mobilenumber?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Rentincincome?: Maybe<Scalars["Float"]>;
    RiskScore?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumofadjustedincome?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
    Tinno?: Maybe<Scalars["Float"]>;
    Uratinno?: Maybe<Scalars["Float"]>;
  };

/** order by var_pop() on columns of table "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Var_Pop_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Tinno?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** aggregate var_samp on columns */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Var_Samp_Fields =
  {
    __typename?: "rtcs_db_RPT_NonIndividualComplianceSummaryPlusRisk_var_samp_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Mobilenumber?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Rentincincome?: Maybe<Scalars["Float"]>;
    RiskScore?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumofadjustedincome?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
    Tinno?: Maybe<Scalars["Float"]>;
    Uratinno?: Maybe<Scalars["Float"]>;
  };

/** order by var_samp() on columns of table "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Var_Samp_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Tinno?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** aggregate variance on columns */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Variance_Fields =
  {
    __typename?: "rtcs_db_RPT_NonIndividualComplianceSummaryPlusRisk_variance_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Mobilenumber?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Rentincincome?: Maybe<Scalars["Float"]>;
    RiskScore?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumofadjustedincome?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
    Tinno?: Maybe<Scalars["Float"]>;
    Uratinno?: Maybe<Scalars["Float"]>;
  };

/** order by variance() on columns of table "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
export type Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Variance_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Mobilenumber?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    RiskScore?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
    Tinno?: Maybe<Order_By>;
    Uratinno?: Maybe<Order_By>;
  };

/** columns and relationships of "rtcs_db.RPT_NonIndividualComposite" */
export type Rtcs_Db_Rpt_NonIndividualComposite = {
  __typename?: "rtcs_db_RPT_NonIndividualComposite";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["float8"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.RPT_NonIndividualComposite" */
export type Rtcs_Db_Rpt_NonIndividualComposite_Aggregate = {
  __typename?: "rtcs_db_RPT_NonIndividualComposite_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rpt_NonIndividualComposite>;
};

/** aggregate fields of "rtcs_db.RPT_NonIndividualComposite" */
export type Rtcs_Db_Rpt_NonIndividualComposite_Aggregate_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualComposite_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.RPT_NonIndividualComposite" */
export type Rtcs_Db_Rpt_NonIndividualComposite_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rpt_NonIndividualComposite_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.RPT_NonIndividualComposite" */
export type Rtcs_Db_Rpt_NonIndividualComposite_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.RPT_NonIndividualComposite" */
export type Rtcs_Db_Rpt_NonIndividualComposite_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rpt_NonIndividualComposite_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rpt_NonIndividualComposite_Avg_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualComposite_avg_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  IdentificationRefugeeId?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.RPT_NonIndividualComposite" */
export type Rtcs_Db_Rpt_NonIndividualComposite_Avg_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.RPT_NonIndividualComposite". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rpt_NonIndividualComposite_Bool_Exp = {
  ActvtyDesc?: Maybe<String_Comparison_Exp>;
  AdditionalOwners?: Maybe<Float8_Comparison_Exp>;
  AdditionalProperties?: Maybe<Float8_Comparison_Exp>;
  AuthctcdSurname?: Maybe<String_Comparison_Exp>;
  AuthctcdTin?: Maybe<String_Comparison_Exp>;
  Brn?: Maybe<String_Comparison_Exp>;
  BsnsName?: Maybe<String_Comparison_Exp>;
  BsnsaddrLocalCouncil?: Maybe<String_Comparison_Exp>;
  BuildingName?: Maybe<String_Comparison_Exp>;
  BusinessStartDt?: Maybe<String_Comparison_Exp>;
  Coin?: Maybe<Float8_Comparison_Exp>;
  CreatedBy?: Maybe<Float8_Comparison_Exp>;
  CreatedDate?: Maybe<String_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  DateOfIncorporation?: Maybe<String_Comparison_Exp>;
  EmailId?: Maybe<String_Comparison_Exp>;
  Fax?: Maybe<String_Comparison_Exp>;
  FormerBusinessName?: Maybe<String_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  HoldingCompName?: Maybe<String_Comparison_Exp>;
  HoldingCompTin?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  IdentificationRefugeeId?: Maybe<Float8_Comparison_Exp>;
  IdentificationWorkPermit?: Maybe<String_Comparison_Exp>;
  KccaName?: Maybe<String_Comparison_Exp>;
  KccaTin?: Maybe<String_Comparison_Exp>;
  LandlineNumber?: Maybe<Float8_Comparison_Exp>;
  MainActivity?: Maybe<String_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Bigint_Comparison_Exp>;
  MatchScoreAttributes?: Maybe<String_Comparison_Exp>;
  MobileNumber?: Maybe<String_Comparison_Exp>;
  NumProperties?: Maybe<Bigint_Comparison_Exp>;
  OrgSubType?: Maybe<String_Comparison_Exp>;
  OrgSubTypeDesc?: Maybe<String_Comparison_Exp>;
  OrgType?: Maybe<String_Comparison_Exp>;
  Photo?: Maybe<Float8_Comparison_Exp>;
  PhotoContent?: Maybe<Float8_Comparison_Exp>;
  PhotoMime?: Maybe<Float8_Comparison_Exp>;
  PhotoName?: Maybe<Float8_Comparison_Exp>;
  PhotoSize?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressPlotNumber?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessName?: Maybe<Float8_Comparison_Exp>;
  RefEmailId?: Maybe<String_Comparison_Exp>;
  RefFirstName?: Maybe<String_Comparison_Exp>;
  RefLandlineNumber?: Maybe<String_Comparison_Exp>;
  RefMobileNumber?: Maybe<String_Comparison_Exp>;
  RefPrsnDesgn?: Maybe<String_Comparison_Exp>;
  RefSurname?: Maybe<String_Comparison_Exp>;
  RefTin?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Float8_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TaxPayerType?: Maybe<String_Comparison_Exp>;
  TradingName?: Maybe<String_Comparison_Exp>;
  UraName?: Maybe<String_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.RPT_NonIndividualComposite" */
export type Rtcs_Db_Rpt_NonIndividualComposite_Inc_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  IdentificationRefugeeId?: Maybe<Scalars["float8"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.RPT_NonIndividualComposite" */
export type Rtcs_Db_Rpt_NonIndividualComposite_Insert_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["float8"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rpt_NonIndividualComposite_Max_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualComposite_max_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["float8"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.RPT_NonIndividualComposite" */
export type Rtcs_Db_Rpt_NonIndividualComposite_Max_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  Brn?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BsnsaddrLocalCouncil?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessStartDt?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  FormerBusinessName?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  HoldingCompName?: Maybe<Order_By>;
  HoldingCompTin?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  KccaName?: Maybe<Order_By>;
  KccaTin?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  OrgSubType?: Maybe<Order_By>;
  OrgSubTypeDesc?: Maybe<Order_By>;
  OrgType?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TradingName?: Maybe<Order_By>;
  UraName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rpt_NonIndividualComposite_Min_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualComposite_min_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["float8"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.RPT_NonIndividualComposite" */
export type Rtcs_Db_Rpt_NonIndividualComposite_Min_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  Brn?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BsnsaddrLocalCouncil?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessStartDt?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  FormerBusinessName?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  HoldingCompName?: Maybe<Order_By>;
  HoldingCompTin?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  KccaName?: Maybe<Order_By>;
  KccaTin?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  OrgSubType?: Maybe<Order_By>;
  OrgSubTypeDesc?: Maybe<Order_By>;
  OrgType?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TradingName?: Maybe<Order_By>;
  UraName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.RPT_NonIndividualComposite" */
export type Rtcs_Db_Rpt_NonIndividualComposite_Mutation_Response = {
  __typename?: "rtcs_db_RPT_NonIndividualComposite_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rpt_NonIndividualComposite>;
};

/** input type for inserting object relation for remote table "rtcs_db.RPT_NonIndividualComposite" */
export type Rtcs_Db_Rpt_NonIndividualComposite_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rpt_NonIndividualComposite_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.RPT_NonIndividualComposite" */
export type Rtcs_Db_Rpt_NonIndividualComposite_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  Brn?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BsnsaddrLocalCouncil?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessStartDt?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  FormerBusinessName?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  HoldingCompName?: Maybe<Order_By>;
  HoldingCompTin?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  KccaName?: Maybe<Order_By>;
  KccaTin?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  OrgSubType?: Maybe<Order_By>;
  OrgSubTypeDesc?: Maybe<Order_By>;
  OrgType?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TradingName?: Maybe<Order_By>;
  UraName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.RPT_NonIndividualComposite" */
export enum Rtcs_Db_Rpt_NonIndividualComposite_Select_Column {
  /** column name */
  ActvtyDesc = "ActvtyDesc",
  /** column name */
  AdditionalOwners = "AdditionalOwners",
  /** column name */
  AdditionalProperties = "AdditionalProperties",
  /** column name */
  AuthctcdSurname = "AuthctcdSurname",
  /** column name */
  AuthctcdTin = "AuthctcdTin",
  /** column name */
  Brn = "Brn",
  /** column name */
  BsnsName = "BsnsName",
  /** column name */
  BsnsaddrLocalCouncil = "BsnsaddrLocalCouncil",
  /** column name */
  BuildingName = "BuildingName",
  /** column name */
  BusinessStartDt = "BusinessStartDt",
  /** column name */
  Coin = "Coin",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DateOfIncorporation = "DateOfIncorporation",
  /** column name */
  EmailId = "EmailId",
  /** column name */
  Fax = "Fax",
  /** column name */
  FormerBusinessName = "FormerBusinessName",
  /** column name */
  Gml = "Gml",
  /** column name */
  HoldingCompName = "HoldingCompName",
  /** column name */
  HoldingCompTin = "HoldingCompTin",
  /** column name */
  Id = "Id",
  /** column name */
  IdentificationRefugeeId = "IdentificationRefugeeId",
  /** column name */
  IdentificationWorkPermit = "IdentificationWorkPermit",
  /** column name */
  KccaName = "KccaName",
  /** column name */
  KccaTin = "KccaTin",
  /** column name */
  LandlineNumber = "LandlineNumber",
  /** column name */
  MainActivity = "MainActivity",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MatchScoreAttributes = "MatchScoreAttributes",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  OrgSubType = "OrgSubType",
  /** column name */
  OrgSubTypeDesc = "OrgSubTypeDesc",
  /** column name */
  OrgType = "OrgType",
  /** column name */
  Photo = "Photo",
  /** column name */
  PhotoContent = "PhotoContent",
  /** column name */
  PhotoMime = "PhotoMime",
  /** column name */
  PhotoName = "PhotoName",
  /** column name */
  PhotoSize = "PhotoSize",
  /** column name */
  PreviousBusinessAddressPlotNumber = "PreviousBusinessAddressPlotNumber",
  /** column name */
  PreviousBusinessName = "PreviousBusinessName",
  /** column name */
  RefEmailId = "RefEmailId",
  /** column name */
  RefFirstName = "RefFirstName",
  /** column name */
  RefLandlineNumber = "RefLandlineNumber",
  /** column name */
  RefMobileNumber = "RefMobileNumber",
  /** column name */
  RefPrsnDesgn = "RefPrsnDesgn",
  /** column name */
  RefSurname = "RefSurname",
  /** column name */
  RefTin = "RefTin",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Source = "Source",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TaxPayerType = "TaxPayerType",
  /** column name */
  TradingName = "TradingName",
  /** column name */
  UraName = "UraName",
  /** column name */
  UraTinNo = "UraTinNo",
}

/** input type for updating data in table "rtcs_db.RPT_NonIndividualComposite" */
export type Rtcs_Db_Rpt_NonIndividualComposite_Set_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["float8"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rpt_NonIndividualComposite_Stddev_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualComposite_stddev_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  IdentificationRefugeeId?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.RPT_NonIndividualComposite" */
export type Rtcs_Db_Rpt_NonIndividualComposite_Stddev_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rpt_NonIndividualComposite_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualComposite_stddev_pop_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  IdentificationRefugeeId?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.RPT_NonIndividualComposite" */
export type Rtcs_Db_Rpt_NonIndividualComposite_Stddev_Pop_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rpt_NonIndividualComposite_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualComposite_stddev_samp_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  IdentificationRefugeeId?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.RPT_NonIndividualComposite" */
export type Rtcs_Db_Rpt_NonIndividualComposite_Stddev_Samp_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rpt_NonIndividualComposite_Sum_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualComposite_sum_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  IdentificationRefugeeId?: Maybe<Scalars["float8"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.RPT_NonIndividualComposite" */
export type Rtcs_Db_Rpt_NonIndividualComposite_Sum_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rpt_NonIndividualComposite_Var_Pop_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualComposite_var_pop_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  IdentificationRefugeeId?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.RPT_NonIndividualComposite" */
export type Rtcs_Db_Rpt_NonIndividualComposite_Var_Pop_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rpt_NonIndividualComposite_Var_Samp_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualComposite_var_samp_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  IdentificationRefugeeId?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.RPT_NonIndividualComposite" */
export type Rtcs_Db_Rpt_NonIndividualComposite_Var_Samp_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rpt_NonIndividualComposite_Variance_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualComposite_variance_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  IdentificationRefugeeId?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.RPT_NonIndividualComposite" */
export type Rtcs_Db_Rpt_NonIndividualComposite_Variance_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData = {
  __typename?: "rtcs_db_RPT_NonIndividualTaxSummaryData";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BalanceCarryForward?: Maybe<Scalars["String"]>;
  BalancePrevPeriod?: Maybe<Scalars["String"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  File_x?: Maybe<Scalars["String"]>;
  File_y?: Maybe<Scalars["String"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["String"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  LastUpdated_x?: Maybe<Scalars["timestamptz"]>;
  LastUpdated_y?: Maybe<Scalars["String"]>;
  Line_x?: Maybe<Scalars["bigint"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["String"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalReserve?: Maybe<Scalars["String"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
  TradingName?: Maybe<Scalars["String"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated_x?: Maybe<Scalars["String"]>;
  WhoUpdated_y?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Aggregate = {
  __typename?: "rtcs_db_RPT_NonIndividualTaxSummaryData_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rpt_NonIndividualTaxSummaryData>;
};

/** aggregate fields of "rtcs_db.RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Aggregate_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualTaxSummaryData_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Avg_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualTaxSummaryData_avg_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  AuthctcdTin?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Avg_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.RPT_NonIndividualTaxSummaryData". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Bool_Exp = {
  ActvtyDesc?: Maybe<String_Comparison_Exp>;
  AdjProfitLossRental?: Maybe<Float8_Comparison_Exp>;
  AdjRentlIncmLoss?: Maybe<Float8_Comparison_Exp>;
  AdministratingExp?: Maybe<Float8_Comparison_Exp>;
  AllowblApportionedCap?: Maybe<Float8_Comparison_Exp>;
  AppliedForTin?: Maybe<String_Comparison_Exp>;
  AssessedChrgblRentlIncm?: Maybe<Float8_Comparison_Exp>;
  AssessedRentlLoss?: Maybe<Float8_Comparison_Exp>;
  AuthctcdEmailId?: Maybe<String_Comparison_Exp>;
  AuthctcdFamilyName?: Maybe<String_Comparison_Exp>;
  AuthctcdFirstName?: Maybe<String_Comparison_Exp>;
  AuthctcdLandlineNumber?: Maybe<Float8_Comparison_Exp>;
  AuthctcdMobileNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdOtherName?: Maybe<String_Comparison_Exp>;
  AuthctcdPrsnDesgn?: Maybe<String_Comparison_Exp>;
  AuthctcdSurname?: Maybe<String_Comparison_Exp>;
  AuthctcdTin?: Maybe<Float8_Comparison_Exp>;
  BadDebtWriteOff?: Maybe<Float8_Comparison_Exp>;
  BalanceCarryForward?: Maybe<String_Comparison_Exp>;
  BalancePrevPeriod?: Maybe<String_Comparison_Exp>;
  BrghtFwdAssessedLoss?: Maybe<Float8_Comparison_Exp>;
  BsnsCertNumber?: Maybe<String_Comparison_Exp>;
  BsnsName?: Maybe<String_Comparison_Exp>;
  BsnsaddrBuildname?: Maybe<String_Comparison_Exp>;
  BsnsaddrDistrictName?: Maybe<String_Comparison_Exp>;
  BsnsaddrLocalCouncil?: Maybe<String_Comparison_Exp>;
  BsnsaddrParishName?: Maybe<String_Comparison_Exp>;
  BsnsaddrPlotNo?: Maybe<String_Comparison_Exp>;
  BsnsaddrStreetAddr?: Maybe<String_Comparison_Exp>;
  BsnsaddrSubCountyName?: Maybe<String_Comparison_Exp>;
  BsnsaddrTownName?: Maybe<String_Comparison_Exp>;
  BsnsaddrTradeCentre?: Maybe<String_Comparison_Exp>;
  BsnsaddrVillageName?: Maybe<String_Comparison_Exp>;
  BusinessIncome?: Maybe<Float8_Comparison_Exp>;
  BusinessRate?: Maybe<Float8_Comparison_Exp>;
  BusinessStartDt?: Maybe<String_Comparison_Exp>;
  BusinessTax?: Maybe<Float8_Comparison_Exp>;
  ChargeableIncome?: Maybe<Float8_Comparison_Exp>;
  ChargeableIncomeP?: Maybe<Float8_Comparison_Exp>;
  CostOfSales?: Maybe<Float8_Comparison_Exp>;
  Debenture?: Maybe<Float8_Comparison_Exp>;
  DeferTaxLiability?: Maybe<Float8_Comparison_Exp>;
  Depreciation?: Maybe<Float8_Comparison_Exp>;
  EmailId?: Maybe<String_Comparison_Exp>;
  EquityShare?: Maybe<Float8_Comparison_Exp>;
  ExistTinNo?: Maybe<String_Comparison_Exp>;
  FactoryRentRates?: Maybe<Float8_Comparison_Exp>;
  File_x?: Maybe<String_Comparison_Exp>;
  File_y?: Maybe<String_Comparison_Exp>;
  FinanceExp?: Maybe<Float8_Comparison_Exp>;
  FixedDeposit?: Maybe<Float8_Comparison_Exp>;
  GovtSecurities?: Maybe<String_Comparison_Exp>;
  GrossProfit?: Maybe<Float8_Comparison_Exp>;
  GrossRentalIncome?: Maybe<Float8_Comparison_Exp>;
  HoldingCompName?: Maybe<String_Comparison_Exp>;
  HoldingCompTin?: Maybe<String_Comparison_Exp>;
  IsAmendment?: Maybe<String_Comparison_Exp>;
  IsHldCmpInUganda?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  IsResident?: Maybe<String_Comparison_Exp>;
  IsSubsidaryComp?: Maybe<String_Comparison_Exp>;
  LandBuild?: Maybe<String_Comparison_Exp>;
  LandlineNumber?: Maybe<Float8_Comparison_Exp>;
  LastUpdated_x?: Maybe<Timestamptz_Comparison_Exp>;
  LastUpdated_y?: Maybe<String_Comparison_Exp>;
  Line_x?: Maybe<Bigint_Comparison_Exp>;
  Line_y?: Maybe<Float8_Comparison_Exp>;
  MainActivity?: Maybe<String_Comparison_Exp>;
  MobileNumber?: Maybe<String_Comparison_Exp>;
  NonAllowblExp?: Maybe<Float8_Comparison_Exp>;
  OperatingExp?: Maybe<Float8_Comparison_Exp>;
  OrgSubType?: Maybe<String_Comparison_Exp>;
  OrgSubTypeDesc?: Maybe<String_Comparison_Exp>;
  OrgType?: Maybe<String_Comparison_Exp>;
  OtherInvestments?: Maybe<Float8_Comparison_Exp>;
  OthrAllowblExp?: Maybe<Float8_Comparison_Exp>;
  PayableAmtInc?: Maybe<Float8_Comparison_Exp>;
  PayableAmtRent?: Maybe<Float8_Comparison_Exp>;
  PostaddrDistictName?: Maybe<String_Comparison_Exp>;
  PostaddrId?: Maybe<String_Comparison_Exp>;
  ProfitAfterDividend?: Maybe<Float8_Comparison_Exp>;
  ProfitAfterTax?: Maybe<Float8_Comparison_Exp>;
  ProfitBeforeTax?: Maybe<Float8_Comparison_Exp>;
  ProfitLossRental?: Maybe<Float8_Comparison_Exp>;
  ProposedDividend?: Maybe<Float8_Comparison_Exp>;
  ProvisionIncomeTax?: Maybe<Float8_Comparison_Exp>;
  RefEmailId?: Maybe<String_Comparison_Exp>;
  RefFamilyName?: Maybe<String_Comparison_Exp>;
  RefFirstName?: Maybe<String_Comparison_Exp>;
  RefLandlineNumber?: Maybe<String_Comparison_Exp>;
  RefMiddleName?: Maybe<String_Comparison_Exp>;
  RefMobileNumber?: Maybe<String_Comparison_Exp>;
  RefOtherName?: Maybe<String_Comparison_Exp>;
  RefPrsnDesgn?: Maybe<String_Comparison_Exp>;
  RefSurname?: Maybe<String_Comparison_Exp>;
  RefTin?: Maybe<String_Comparison_Exp>;
  RefundAmtInc?: Maybe<Float8_Comparison_Exp>;
  RefundAmtRent?: Maybe<Float8_Comparison_Exp>;
  RentRates?: Maybe<Float8_Comparison_Exp>;
  RentincIncome?: Maybe<Float8_Comparison_Exp>;
  RentincIncomeP?: Maybe<Float8_Comparison_Exp>;
  RentincRate?: Maybe<Float8_Comparison_Exp>;
  RentincTax?: Maybe<Float8_Comparison_Exp>;
  ReturnType?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  RtnDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnFromDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnPeriodYear?: Maybe<String_Comparison_Exp>;
  RtnPeriodYearGenerated?: Maybe<String_Comparison_Exp>;
  SecureTotLoan?: Maybe<String_Comparison_Exp>;
  SelfAssessTaxPaidInc?: Maybe<Float8_Comparison_Exp>;
  SelfAssessTaxPaidRent?: Maybe<Float8_Comparison_Exp>;
  SourceFund?: Maybe<String_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TaxPayerType?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  TotAdminExpense?: Maybe<Float8_Comparison_Exp>;
  TotBsnsProfSales?: Maybe<Float8_Comparison_Exp>;
  TotExpPurelyRental?: Maybe<Float8_Comparison_Exp>;
  TotFinancialExpense?: Maybe<Float8_Comparison_Exp>;
  TotFixedAsset?: Maybe<String_Comparison_Exp>;
  TotIncome?: Maybe<Float8_Comparison_Exp>;
  TotNonChargeableIncome?: Maybe<Float8_Comparison_Exp>;
  TotOperationExpense?: Maybe<Float8_Comparison_Exp>;
  TotOtherExpense?: Maybe<Float8_Comparison_Exp>;
  TotRate?: Maybe<Float8_Comparison_Exp>;
  TotRentAdminiExpns?: Maybe<Float8_Comparison_Exp>;
  TotRentFinancExpns?: Maybe<Float8_Comparison_Exp>;
  TotRentIncmExpns?: Maybe<Float8_Comparison_Exp>;
  TotRentOprtgExpns?: Maybe<Float8_Comparison_Exp>;
  TotTax?: Maybe<Float8_Comparison_Exp>;
  TotTaxLiability?: Maybe<Float8_Comparison_Exp>;
  TotTaxLiabilityInc?: Maybe<Float8_Comparison_Exp>;
  TotTaxLiabilityRent?: Maybe<Float8_Comparison_Exp>;
  TotTaxesPaidInc?: Maybe<Float8_Comparison_Exp>;
  TotTaxesPaidRent?: Maybe<Float8_Comparison_Exp>;
  TotTradeInvestment?: Maybe<String_Comparison_Exp>;
  TotWthdTaxDeduct?: Maybe<Float8_Comparison_Exp>;
  TotalPropFund?: Maybe<String_Comparison_Exp>;
  TotalReserve?: Maybe<String_Comparison_Exp>;
  TotalShareCapital?: Maybe<Float8_Comparison_Exp>;
  TradingName?: Maybe<String_Comparison_Exp>;
  UnsecureTotLoan?: Maybe<String_Comparison_Exp>;
  Uuid?: Maybe<String_Comparison_Exp>;
  WhoUpdated_x?: Maybe<String_Comparison_Exp>;
  WhoUpdated_y?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Inc_Input = {
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  AuthctcdTin?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  Line_x?: Maybe<Scalars["bigint"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Insert_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BalanceCarryForward?: Maybe<Scalars["String"]>;
  BalancePrevPeriod?: Maybe<Scalars["String"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  File_x?: Maybe<Scalars["String"]>;
  File_y?: Maybe<Scalars["String"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["String"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  LastUpdated_x?: Maybe<Scalars["timestamptz"]>;
  LastUpdated_y?: Maybe<Scalars["String"]>;
  Line_x?: Maybe<Scalars["bigint"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["String"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalReserve?: Maybe<Scalars["String"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
  TradingName?: Maybe<Scalars["String"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated_x?: Maybe<Scalars["String"]>;
  WhoUpdated_y?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Max_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualTaxSummaryData_max_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BalanceCarryForward?: Maybe<Scalars["String"]>;
  BalancePrevPeriod?: Maybe<Scalars["String"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  File_x?: Maybe<Scalars["String"]>;
  File_y?: Maybe<Scalars["String"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["String"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  LastUpdated_x?: Maybe<Scalars["timestamptz"]>;
  LastUpdated_y?: Maybe<Scalars["String"]>;
  Line_x?: Maybe<Scalars["bigint"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["String"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalReserve?: Maybe<Scalars["String"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
  TradingName?: Maybe<Scalars["String"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated_x?: Maybe<Scalars["String"]>;
  WhoUpdated_y?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Max_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AppliedForTin?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BalanceCarryForward?: Maybe<Order_By>;
  BalancePrevPeriod?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BsnsaddrBuildname?: Maybe<Order_By>;
  BsnsaddrDistrictName?: Maybe<Order_By>;
  BsnsaddrLocalCouncil?: Maybe<Order_By>;
  BsnsaddrParishName?: Maybe<Order_By>;
  BsnsaddrPlotNo?: Maybe<Order_By>;
  BsnsaddrStreetAddr?: Maybe<Order_By>;
  BsnsaddrSubCountyName?: Maybe<Order_By>;
  BsnsaddrTownName?: Maybe<Order_By>;
  BsnsaddrTradeCentre?: Maybe<Order_By>;
  BsnsaddrVillageName?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessStartDt?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  File_x?: Maybe<Order_By>;
  File_y?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  HoldingCompName?: Maybe<Order_By>;
  HoldingCompTin?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsHldCmpInUganda?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  IsSubsidaryComp?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated_x?: Maybe<Order_By>;
  LastUpdated_y?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OrgSubType?: Maybe<Order_By>;
  OrgSubTypeDesc?: Maybe<Order_By>;
  OrgType?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMiddleName?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnPeriodYearGenerated?: Maybe<Order_By>;
  SecureTotLoan?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  SourceFund?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalPropFund?: Maybe<Order_By>;
  TotalReserve?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
  TradingName?: Maybe<Order_By>;
  UnsecureTotLoan?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated_x?: Maybe<Order_By>;
  WhoUpdated_y?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Min_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualTaxSummaryData_min_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BalanceCarryForward?: Maybe<Scalars["String"]>;
  BalancePrevPeriod?: Maybe<Scalars["String"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  File_x?: Maybe<Scalars["String"]>;
  File_y?: Maybe<Scalars["String"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["String"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  LastUpdated_x?: Maybe<Scalars["timestamptz"]>;
  LastUpdated_y?: Maybe<Scalars["String"]>;
  Line_x?: Maybe<Scalars["bigint"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["String"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalReserve?: Maybe<Scalars["String"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
  TradingName?: Maybe<Scalars["String"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated_x?: Maybe<Scalars["String"]>;
  WhoUpdated_y?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Min_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AppliedForTin?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BalanceCarryForward?: Maybe<Order_By>;
  BalancePrevPeriod?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BsnsaddrBuildname?: Maybe<Order_By>;
  BsnsaddrDistrictName?: Maybe<Order_By>;
  BsnsaddrLocalCouncil?: Maybe<Order_By>;
  BsnsaddrParishName?: Maybe<Order_By>;
  BsnsaddrPlotNo?: Maybe<Order_By>;
  BsnsaddrStreetAddr?: Maybe<Order_By>;
  BsnsaddrSubCountyName?: Maybe<Order_By>;
  BsnsaddrTownName?: Maybe<Order_By>;
  BsnsaddrTradeCentre?: Maybe<Order_By>;
  BsnsaddrVillageName?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessStartDt?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  File_x?: Maybe<Order_By>;
  File_y?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  HoldingCompName?: Maybe<Order_By>;
  HoldingCompTin?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsHldCmpInUganda?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  IsSubsidaryComp?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated_x?: Maybe<Order_By>;
  LastUpdated_y?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OrgSubType?: Maybe<Order_By>;
  OrgSubTypeDesc?: Maybe<Order_By>;
  OrgType?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMiddleName?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnPeriodYearGenerated?: Maybe<Order_By>;
  SecureTotLoan?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  SourceFund?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalPropFund?: Maybe<Order_By>;
  TotalReserve?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
  TradingName?: Maybe<Order_By>;
  UnsecureTotLoan?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated_x?: Maybe<Order_By>;
  WhoUpdated_y?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Mutation_Response = {
  __typename?: "rtcs_db_RPT_NonIndividualTaxSummaryData_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rpt_NonIndividualTaxSummaryData>;
};

/** input type for inserting object relation for remote table "rtcs_db.RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AppliedForTin?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BalanceCarryForward?: Maybe<Order_By>;
  BalancePrevPeriod?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BsnsaddrBuildname?: Maybe<Order_By>;
  BsnsaddrDistrictName?: Maybe<Order_By>;
  BsnsaddrLocalCouncil?: Maybe<Order_By>;
  BsnsaddrParishName?: Maybe<Order_By>;
  BsnsaddrPlotNo?: Maybe<Order_By>;
  BsnsaddrStreetAddr?: Maybe<Order_By>;
  BsnsaddrSubCountyName?: Maybe<Order_By>;
  BsnsaddrTownName?: Maybe<Order_By>;
  BsnsaddrTradeCentre?: Maybe<Order_By>;
  BsnsaddrVillageName?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessStartDt?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  File_x?: Maybe<Order_By>;
  File_y?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  HoldingCompName?: Maybe<Order_By>;
  HoldingCompTin?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsHldCmpInUganda?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  IsSubsidaryComp?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated_x?: Maybe<Order_By>;
  LastUpdated_y?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OrgSubType?: Maybe<Order_By>;
  OrgSubTypeDesc?: Maybe<Order_By>;
  OrgType?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMiddleName?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnPeriodYearGenerated?: Maybe<Order_By>;
  SecureTotLoan?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  SourceFund?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalPropFund?: Maybe<Order_By>;
  TotalReserve?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
  TradingName?: Maybe<Order_By>;
  UnsecureTotLoan?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated_x?: Maybe<Order_By>;
  WhoUpdated_y?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.RPT_NonIndividualTaxSummaryData" */
export enum Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Select_Column {
  /** column name */
  ActvtyDesc = "ActvtyDesc",
  /** column name */
  AdjProfitLossRental = "AdjProfitLossRental",
  /** column name */
  AdjRentlIncmLoss = "AdjRentlIncmLoss",
  /** column name */
  AdministratingExp = "AdministratingExp",
  /** column name */
  AllowblApportionedCap = "AllowblApportionedCap",
  /** column name */
  AppliedForTin = "AppliedForTin",
  /** column name */
  AssessedChrgblRentlIncm = "AssessedChrgblRentlIncm",
  /** column name */
  AssessedRentlLoss = "AssessedRentlLoss",
  /** column name */
  AuthctcdEmailId = "AuthctcdEmailId",
  /** column name */
  AuthctcdFamilyName = "AuthctcdFamilyName",
  /** column name */
  AuthctcdFirstName = "AuthctcdFirstName",
  /** column name */
  AuthctcdLandlineNumber = "AuthctcdLandlineNumber",
  /** column name */
  AuthctcdMobileNumber = "AuthctcdMobileNumber",
  /** column name */
  AuthctcdOtherName = "AuthctcdOtherName",
  /** column name */
  AuthctcdPrsnDesgn = "AuthctcdPrsnDesgn",
  /** column name */
  AuthctcdSurname = "AuthctcdSurname",
  /** column name */
  AuthctcdTin = "AuthctcdTin",
  /** column name */
  BadDebtWriteOff = "BadDebtWriteOff",
  /** column name */
  BalanceCarryForward = "BalanceCarryForward",
  /** column name */
  BalancePrevPeriod = "BalancePrevPeriod",
  /** column name */
  BrghtFwdAssessedLoss = "BrghtFwdAssessedLoss",
  /** column name */
  BsnsCertNumber = "BsnsCertNumber",
  /** column name */
  BsnsName = "BsnsName",
  /** column name */
  BsnsaddrBuildname = "BsnsaddrBuildname",
  /** column name */
  BsnsaddrDistrictName = "BsnsaddrDistrictName",
  /** column name */
  BsnsaddrLocalCouncil = "BsnsaddrLocalCouncil",
  /** column name */
  BsnsaddrParishName = "BsnsaddrParishName",
  /** column name */
  BsnsaddrPlotNo = "BsnsaddrPlotNo",
  /** column name */
  BsnsaddrStreetAddr = "BsnsaddrStreetAddr",
  /** column name */
  BsnsaddrSubCountyName = "BsnsaddrSubCountyName",
  /** column name */
  BsnsaddrTownName = "BsnsaddrTownName",
  /** column name */
  BsnsaddrTradeCentre = "BsnsaddrTradeCentre",
  /** column name */
  BsnsaddrVillageName = "BsnsaddrVillageName",
  /** column name */
  BusinessIncome = "BusinessIncome",
  /** column name */
  BusinessRate = "BusinessRate",
  /** column name */
  BusinessStartDt = "BusinessStartDt",
  /** column name */
  BusinessTax = "BusinessTax",
  /** column name */
  ChargeableIncome = "ChargeableIncome",
  /** column name */
  ChargeableIncomeP = "ChargeableIncomeP",
  /** column name */
  CostOfSales = "CostOfSales",
  /** column name */
  Debenture = "Debenture",
  /** column name */
  DeferTaxLiability = "DeferTaxLiability",
  /** column name */
  Depreciation = "Depreciation",
  /** column name */
  EmailId = "EmailId",
  /** column name */
  EquityShare = "EquityShare",
  /** column name */
  ExistTinNo = "ExistTinNo",
  /** column name */
  FactoryRentRates = "FactoryRentRates",
  /** column name */
  FileX = "File_x",
  /** column name */
  FileY = "File_y",
  /** column name */
  FinanceExp = "FinanceExp",
  /** column name */
  FixedDeposit = "FixedDeposit",
  /** column name */
  GovtSecurities = "GovtSecurities",
  /** column name */
  GrossProfit = "GrossProfit",
  /** column name */
  GrossRentalIncome = "GrossRentalIncome",
  /** column name */
  HoldingCompName = "HoldingCompName",
  /** column name */
  HoldingCompTin = "HoldingCompTin",
  /** column name */
  IsAmendment = "IsAmendment",
  /** column name */
  IsHldCmpInUganda = "IsHldCmpInUganda",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  IsResident = "IsResident",
  /** column name */
  IsSubsidaryComp = "IsSubsidaryComp",
  /** column name */
  LandBuild = "LandBuild",
  /** column name */
  LandlineNumber = "LandlineNumber",
  /** column name */
  LastUpdatedX = "LastUpdated_x",
  /** column name */
  LastUpdatedY = "LastUpdated_y",
  /** column name */
  LineX = "Line_x",
  /** column name */
  LineY = "Line_y",
  /** column name */
  MainActivity = "MainActivity",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  NonAllowblExp = "NonAllowblExp",
  /** column name */
  OperatingExp = "OperatingExp",
  /** column name */
  OrgSubType = "OrgSubType",
  /** column name */
  OrgSubTypeDesc = "OrgSubTypeDesc",
  /** column name */
  OrgType = "OrgType",
  /** column name */
  OtherInvestments = "OtherInvestments",
  /** column name */
  OthrAllowblExp = "OthrAllowblExp",
  /** column name */
  PayableAmtInc = "PayableAmtInc",
  /** column name */
  PayableAmtRent = "PayableAmtRent",
  /** column name */
  PostaddrDistictName = "PostaddrDistictName",
  /** column name */
  PostaddrId = "PostaddrId",
  /** column name */
  ProfitAfterDividend = "ProfitAfterDividend",
  /** column name */
  ProfitAfterTax = "ProfitAfterTax",
  /** column name */
  ProfitBeforeTax = "ProfitBeforeTax",
  /** column name */
  ProfitLossRental = "ProfitLossRental",
  /** column name */
  ProposedDividend = "ProposedDividend",
  /** column name */
  ProvisionIncomeTax = "ProvisionIncomeTax",
  /** column name */
  RefEmailId = "RefEmailId",
  /** column name */
  RefFamilyName = "RefFamilyName",
  /** column name */
  RefFirstName = "RefFirstName",
  /** column name */
  RefLandlineNumber = "RefLandlineNumber",
  /** column name */
  RefMiddleName = "RefMiddleName",
  /** column name */
  RefMobileNumber = "RefMobileNumber",
  /** column name */
  RefOtherName = "RefOtherName",
  /** column name */
  RefPrsnDesgn = "RefPrsnDesgn",
  /** column name */
  RefSurname = "RefSurname",
  /** column name */
  RefTin = "RefTin",
  /** column name */
  RefundAmtInc = "RefundAmtInc",
  /** column name */
  RefundAmtRent = "RefundAmtRent",
  /** column name */
  RentRates = "RentRates",
  /** column name */
  RentincIncome = "RentincIncome",
  /** column name */
  RentincIncomeP = "RentincIncomeP",
  /** column name */
  RentincRate = "RentincRate",
  /** column name */
  RentincTax = "RentincTax",
  /** column name */
  ReturnType = "ReturnType",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  RtnDt = "RtnDt",
  /** column name */
  RtnFromDt = "RtnFromDt",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnPeriodYear = "RtnPeriodYear",
  /** column name */
  RtnPeriodYearGenerated = "RtnPeriodYearGenerated",
  /** column name */
  SecureTotLoan = "SecureTotLoan",
  /** column name */
  SelfAssessTaxPaidInc = "SelfAssessTaxPaidInc",
  /** column name */
  SelfAssessTaxPaidRent = "SelfAssessTaxPaidRent",
  /** column name */
  SourceFund = "SourceFund",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TaxPayerType = "TaxPayerType",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  TotAdminExpense = "TotAdminExpense",
  /** column name */
  TotBsnsProfSales = "TotBsnsProfSales",
  /** column name */
  TotExpPurelyRental = "TotExpPurelyRental",
  /** column name */
  TotFinancialExpense = "TotFinancialExpense",
  /** column name */
  TotFixedAsset = "TotFixedAsset",
  /** column name */
  TotIncome = "TotIncome",
  /** column name */
  TotNonChargeableIncome = "TotNonChargeableIncome",
  /** column name */
  TotOperationExpense = "TotOperationExpense",
  /** column name */
  TotOtherExpense = "TotOtherExpense",
  /** column name */
  TotRate = "TotRate",
  /** column name */
  TotRentAdminiExpns = "TotRentAdminiExpns",
  /** column name */
  TotRentFinancExpns = "TotRentFinancExpns",
  /** column name */
  TotRentIncmExpns = "TotRentIncmExpns",
  /** column name */
  TotRentOprtgExpns = "TotRentOprtgExpns",
  /** column name */
  TotTax = "TotTax",
  /** column name */
  TotTaxLiability = "TotTaxLiability",
  /** column name */
  TotTaxLiabilityInc = "TotTaxLiabilityInc",
  /** column name */
  TotTaxLiabilityRent = "TotTaxLiabilityRent",
  /** column name */
  TotTaxesPaidInc = "TotTaxesPaidInc",
  /** column name */
  TotTaxesPaidRent = "TotTaxesPaidRent",
  /** column name */
  TotTradeInvestment = "TotTradeInvestment",
  /** column name */
  TotWthdTaxDeduct = "TotWthdTaxDeduct",
  /** column name */
  TotalPropFund = "TotalPropFund",
  /** column name */
  TotalReserve = "TotalReserve",
  /** column name */
  TotalShareCapital = "TotalShareCapital",
  /** column name */
  TradingName = "TradingName",
  /** column name */
  UnsecureTotLoan = "UnsecureTotLoan",
  /** column name */
  Uuid = "Uuid",
  /** column name */
  WhoUpdatedX = "WhoUpdated_x",
  /** column name */
  WhoUpdatedY = "WhoUpdated_y",
}

/** input type for updating data in table "rtcs_db.RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Set_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BalanceCarryForward?: Maybe<Scalars["String"]>;
  BalancePrevPeriod?: Maybe<Scalars["String"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  File_x?: Maybe<Scalars["String"]>;
  File_y?: Maybe<Scalars["String"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["String"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  LastUpdated_x?: Maybe<Scalars["timestamptz"]>;
  LastUpdated_y?: Maybe<Scalars["String"]>;
  Line_x?: Maybe<Scalars["bigint"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["String"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalReserve?: Maybe<Scalars["String"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
  TradingName?: Maybe<Scalars["String"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated_x?: Maybe<Scalars["String"]>;
  WhoUpdated_y?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Stddev_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualTaxSummaryData_stddev_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  AuthctcdTin?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Stddev_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualTaxSummaryData_stddev_pop_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  AuthctcdTin?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Stddev_Pop_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualTaxSummaryData_stddev_samp_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  AuthctcdTin?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Stddev_Samp_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Sum_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualTaxSummaryData_sum_fields";
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  AuthctcdTin?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  ChargeableIncomeP?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  Line_x?: Maybe<Scalars["bigint"]>;
  Line_y?: Maybe<Scalars["float8"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincIncomeP?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Sum_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Var_Pop_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualTaxSummaryData_var_pop_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  AuthctcdTin?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Var_Pop_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Var_Samp_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualTaxSummaryData_var_samp_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  AuthctcdTin?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Var_Samp_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Variance_Fields = {
  __typename?: "rtcs_db_RPT_NonIndividualTaxSummaryData_variance_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  AuthctcdTin?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  ChargeableIncomeP?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line_x?: Maybe<Scalars["Float"]>;
  Line_y?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincIncomeP?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.RPT_NonIndividualTaxSummaryData" */
export type Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Variance_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  ChargeableIncomeP?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line_x?: Maybe<Order_By>;
  Line_y?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincIncomeP?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.RPT_PropertiesComposite" */
export type Rtcs_Db_Rpt_PropertiesComposite = {
  __typename?: "rtcs_db_RPT_PropertiesComposite";
  BlockNo?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  CustomerMatchScore?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  GrossRentValueAdjusted?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["String"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.RPT_PropertiesComposite" */
export type Rtcs_Db_Rpt_PropertiesComposite_Aggregate = {
  __typename?: "rtcs_db_RPT_PropertiesComposite_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rpt_PropertiesComposite>;
};

/** aggregate fields of "rtcs_db.RPT_PropertiesComposite" */
export type Rtcs_Db_Rpt_PropertiesComposite_Aggregate_Fields = {
  __typename?: "rtcs_db_RPT_PropertiesComposite_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.RPT_PropertiesComposite" */
export type Rtcs_Db_Rpt_PropertiesComposite_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rpt_PropertiesComposite_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.RPT_PropertiesComposite" */
export type Rtcs_Db_Rpt_PropertiesComposite_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.RPT_PropertiesComposite" */
export type Rtcs_Db_Rpt_PropertiesComposite_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rpt_PropertiesComposite_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rpt_PropertiesComposite_Avg_Fields = {
  __typename?: "rtcs_db_RPT_PropertiesComposite_avg_fields";
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.RPT_PropertiesComposite" */
export type Rtcs_Db_Rpt_PropertiesComposite_Avg_Order_By = {
  CurrentRateableValue?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.RPT_PropertiesComposite". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rpt_PropertiesComposite_Bool_Exp = {
  BlockNo?: Maybe<String_Comparison_Exp>;
  BusinessName?: Maybe<String_Comparison_Exp>;
  CamvId?: Maybe<String_Comparison_Exp>;
  CountyId?: Maybe<String_Comparison_Exp>;
  CreatedDate?: Maybe<String_Comparison_Exp>;
  CurrentRateableValue?: Maybe<Float8_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  CustomerMatchScore?: Maybe<String_Comparison_Exp>;
  DistrictId?: Maybe<String_Comparison_Exp>;
  DivisionId?: Maybe<String_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  EntityLegalName?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  GrossRentValueAdjusted?: Maybe<String_Comparison_Exp>;
  GrossValue?: Maybe<Float8_Comparison_Exp>;
  ImgPath?: Maybe<String_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  MapTitle?: Maybe<String_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Float8_Comparison_Exp>;
  NumProperties?: Maybe<Bigint_Comparison_Exp>;
  NumRentedProperties?: Maybe<Bigint_Comparison_Exp>;
  NwscCustomerId?: Maybe<String_Comparison_Exp>;
  NwscCustomerName?: Maybe<String_Comparison_Exp>;
  ParishId?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyNo?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatusId?: Maybe<String_Comparison_Exp>;
  PropertyTypeId?: Maybe<String_Comparison_Exp>;
  RateableValue?: Maybe<Float8_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  RnidCustomer?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  SourceCustomer?: Maybe<String_Comparison_Exp>;
  StreetId?: Maybe<String_Comparison_Exp>;
  SubCountyId?: Maybe<String_Comparison_Exp>;
  SubPropertyTypeId?: Maybe<String_Comparison_Exp>;
  Supplementary?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  VillageId?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rpt_PropertiesComposite_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rpt_PropertiesComposite_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.RPT_PropertiesComposite" */
export type Rtcs_Db_Rpt_PropertiesComposite_Inc_Input = {
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  GrossValue?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.RPT_PropertiesComposite" */
export type Rtcs_Db_Rpt_PropertiesComposite_Insert_Input = {
  BlockNo?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  CustomerMatchScore?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  GrossRentValueAdjusted?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["String"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rpt_PropertiesComposite_Max_Fields = {
  __typename?: "rtcs_db_RPT_PropertiesComposite_max_fields";
  BlockNo?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  CustomerMatchScore?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  GrossRentValueAdjusted?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["String"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.RPT_PropertiesComposite" */
export type Rtcs_Db_Rpt_PropertiesComposite_Max_Order_By = {
  BlockNo?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  CustomerMatchScore?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  GrossRentValueAdjusted?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerName?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidCustomer?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SourceCustomer?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rpt_PropertiesComposite_Min_Fields = {
  __typename?: "rtcs_db_RPT_PropertiesComposite_min_fields";
  BlockNo?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  CustomerMatchScore?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  GrossRentValueAdjusted?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["String"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.RPT_PropertiesComposite" */
export type Rtcs_Db_Rpt_PropertiesComposite_Min_Order_By = {
  BlockNo?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  CustomerMatchScore?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  GrossRentValueAdjusted?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerName?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidCustomer?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SourceCustomer?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.RPT_PropertiesComposite" */
export type Rtcs_Db_Rpt_PropertiesComposite_Mutation_Response = {
  __typename?: "rtcs_db_RPT_PropertiesComposite_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rpt_PropertiesComposite>;
};

/** input type for inserting object relation for remote table "rtcs_db.RPT_PropertiesComposite" */
export type Rtcs_Db_Rpt_PropertiesComposite_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rpt_PropertiesComposite_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.RPT_PropertiesComposite" */
export type Rtcs_Db_Rpt_PropertiesComposite_Order_By = {
  BlockNo?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  CustomerMatchScore?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  GrossRentValueAdjusted?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerName?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidCustomer?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SourceCustomer?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.RPT_PropertiesComposite" */
export enum Rtcs_Db_Rpt_PropertiesComposite_Select_Column {
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  BusinessName = "BusinessName",
  /** column name */
  CamvId = "CamvId",
  /** column name */
  CountyId = "CountyId",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CurrentRateableValue = "CurrentRateableValue",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  CustomerMatchScore = "CustomerMatchScore",
  /** column name */
  DistrictId = "DistrictId",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  Email = "Email",
  /** column name */
  EntityLegalName = "EntityLegalName",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  Gml = "Gml",
  /** column name */
  GrossRentValueAdjusted = "GrossRentValueAdjusted",
  /** column name */
  GrossValue = "GrossValue",
  /** column name */
  ImgPath = "ImgPath",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MapTitle = "MapTitle",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  NumRentedProperties = "NumRentedProperties",
  /** column name */
  NwscCustomerId = "NwscCustomerId",
  /** column name */
  NwscCustomerName = "NwscCustomerName",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyNo = "PropertyNo",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  RateableValue = "RateableValue",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  RnidCustomer = "RnidCustomer",
  /** column name */
  Source = "Source",
  /** column name */
  SourceCustomer = "SourceCustomer",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  SubCountyId = "SubCountyId",
  /** column name */
  SubPropertyTypeId = "SubPropertyTypeId",
  /** column name */
  Supplementary = "Supplementary",
  /** column name */
  Surname = "Surname",
  /** column name */
  VillageId = "VillageId",
}

/** input type for updating data in table "rtcs_db.RPT_PropertiesComposite" */
export type Rtcs_Db_Rpt_PropertiesComposite_Set_Input = {
  BlockNo?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  CustomerMatchScore?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  GrossRentValueAdjusted?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["String"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rpt_PropertiesComposite_Stddev_Fields = {
  __typename?: "rtcs_db_RPT_PropertiesComposite_stddev_fields";
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.RPT_PropertiesComposite" */
export type Rtcs_Db_Rpt_PropertiesComposite_Stddev_Order_By = {
  CurrentRateableValue?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rpt_PropertiesComposite_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_RPT_PropertiesComposite_stddev_pop_fields";
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.RPT_PropertiesComposite" */
export type Rtcs_Db_Rpt_PropertiesComposite_Stddev_Pop_Order_By = {
  CurrentRateableValue?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rpt_PropertiesComposite_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_RPT_PropertiesComposite_stddev_samp_fields";
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.RPT_PropertiesComposite" */
export type Rtcs_Db_Rpt_PropertiesComposite_Stddev_Samp_Order_By = {
  CurrentRateableValue?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rpt_PropertiesComposite_Sum_Fields = {
  __typename?: "rtcs_db_RPT_PropertiesComposite_sum_fields";
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  GrossValue?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.RPT_PropertiesComposite" */
export type Rtcs_Db_Rpt_PropertiesComposite_Sum_Order_By = {
  CurrentRateableValue?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rpt_PropertiesComposite_Var_Pop_Fields = {
  __typename?: "rtcs_db_RPT_PropertiesComposite_var_pop_fields";
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.RPT_PropertiesComposite" */
export type Rtcs_Db_Rpt_PropertiesComposite_Var_Pop_Order_By = {
  CurrentRateableValue?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rpt_PropertiesComposite_Var_Samp_Fields = {
  __typename?: "rtcs_db_RPT_PropertiesComposite_var_samp_fields";
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.RPT_PropertiesComposite" */
export type Rtcs_Db_Rpt_PropertiesComposite_Var_Samp_Order_By = {
  CurrentRateableValue?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rpt_PropertiesComposite_Variance_Fields = {
  __typename?: "rtcs_db_RPT_PropertiesComposite_variance_fields";
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.RPT_PropertiesComposite" */
export type Rtcs_Db_Rpt_PropertiesComposite_Variance_Order_By = {
  CurrentRateableValue?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.RPT_PropertyValuation" */
export type Rtcs_Db_Rpt_PropertyValuation = {
  __typename?: "rtcs_db_RPT_PropertyValuation";
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["float8"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  AnnualGrossValue?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["float8"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  RentPerSquareMeter?: Maybe<Scalars["float8"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.RPT_PropertyValuation" */
export type Rtcs_Db_Rpt_PropertyValuation_Aggregate = {
  __typename?: "rtcs_db_RPT_PropertyValuation_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rpt_PropertyValuation>;
};

/** aggregate fields of "rtcs_db.RPT_PropertyValuation" */
export type Rtcs_Db_Rpt_PropertyValuation_Aggregate_Fields = {
  __typename?: "rtcs_db_RPT_PropertyValuation_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.RPT_PropertyValuation" */
export type Rtcs_Db_Rpt_PropertyValuation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rpt_PropertyValuation_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.RPT_PropertyValuation" */
export type Rtcs_Db_Rpt_PropertyValuation_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.RPT_PropertyValuation" */
export type Rtcs_Db_Rpt_PropertyValuation_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rpt_PropertyValuation_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rpt_PropertyValuation_Avg_Fields = {
  __typename?: "rtcs_db_RPT_PropertyValuation_avg_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["Float"]>;
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  AnnualGrossValue?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  RentPerSquareMeter?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.RPT_PropertyValuation" */
export type Rtcs_Db_Rpt_PropertyValuation_Avg_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AnnualGazetteValue?: Maybe<Order_By>;
  AnnualGrossValue?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  RentPerSquareMeter?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.RPT_PropertyValuation". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rpt_PropertyValuation_Bool_Exp = {
  AccomodationBedrooms?: Maybe<Float8_Comparison_Exp>;
  AccomodationBuiltUpArea?: Maybe<Float8_Comparison_Exp>;
  AccomodationOthers?: Maybe<String_Comparison_Exp>;
  AnnualGazetteValue?: Maybe<Float8_Comparison_Exp>;
  AnnualGrossValue?: Maybe<Float8_Comparison_Exp>;
  BlockNo?: Maybe<String_Comparison_Exp>;
  CamvId?: Maybe<String_Comparison_Exp>;
  CountyId?: Maybe<String_Comparison_Exp>;
  CreatedDate?: Maybe<String_Comparison_Exp>;
  CurrentRateableValue?: Maybe<Float8_Comparison_Exp>;
  CustomerId?: Maybe<Bigint_Comparison_Exp>;
  DerivedNoOfBedrooms?: Maybe<Float8_Comparison_Exp>;
  DistrictId?: Maybe<String_Comparison_Exp>;
  DivisionId?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  Grossrentvalueadjusted?: Maybe<Float8_Comparison_Exp>;
  Grossvalue?: Maybe<Float8_Comparison_Exp>;
  ImgPath?: Maybe<String_Comparison_Exp>;
  KccaNoOfBedrooms?: Maybe<Float8_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  MapTitle?: Maybe<String_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Float8_Comparison_Exp>;
  MonthlyGazetteValue?: Maybe<Float8_Comparison_Exp>;
  NumProperties?: Maybe<Bigint_Comparison_Exp>;
  NumRentedProperties?: Maybe<Bigint_Comparison_Exp>;
  NwscCustomerId?: Maybe<Float8_Comparison_Exp>;
  NwscCustomerName?: Maybe<String_Comparison_Exp>;
  ParishId?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyId?: Maybe<String_Comparison_Exp>;
  PropertyLegalEntity?: Maybe<String_Comparison_Exp>;
  PropertyNo?: Maybe<String_Comparison_Exp>;
  PropertyOwnerFirstname?: Maybe<String_Comparison_Exp>;
  PropertyOwnerSurname?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatusId?: Maybe<String_Comparison_Exp>;
  PropertyType?: Maybe<String_Comparison_Exp>;
  PropertyTypeId?: Maybe<String_Comparison_Exp>;
  Propertyno?: Maybe<String_Comparison_Exp>;
  Propertyrentedstatus?: Maybe<String_Comparison_Exp>;
  Rateablevalue?: Maybe<Float8_Comparison_Exp>;
  RentPerSquareMeter?: Maybe<Float8_Comparison_Exp>;
  RnSubpropertyType?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  RnidCustomer?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  SourceCustomer?: Maybe<String_Comparison_Exp>;
  SquareMeters?: Maybe<Float8_Comparison_Exp>;
  StreetId?: Maybe<String_Comparison_Exp>;
  SubCountyId?: Maybe<String_Comparison_Exp>;
  SubPropertyTypeId?: Maybe<String_Comparison_Exp>;
  Supplementary?: Maybe<Float8_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  VillageId?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rpt_PropertyValuation_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rpt_PropertyValuation_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.RPT_PropertyValuation" */
export type Rtcs_Db_Rpt_PropertyValuation_Inc_Input = {
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["float8"]>;
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  AnnualGrossValue?: Maybe<Scalars["float8"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  RentPerSquareMeter?: Maybe<Scalars["float8"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.RPT_PropertyValuation" */
export type Rtcs_Db_Rpt_PropertyValuation_Insert_Input = {
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["float8"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  AnnualGrossValue?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["float8"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  RentPerSquareMeter?: Maybe<Scalars["float8"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rpt_PropertyValuation_Max_Fields = {
  __typename?: "rtcs_db_RPT_PropertyValuation_max_fields";
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["float8"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  AnnualGrossValue?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["float8"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  RentPerSquareMeter?: Maybe<Scalars["float8"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.RPT_PropertyValuation" */
export type Rtcs_Db_Rpt_PropertyValuation_Max_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AccomodationOthers?: Maybe<Order_By>;
  AnnualGazetteValue?: Maybe<Order_By>;
  AnnualGrossValue?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerName?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyLegalEntity?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyOwnerFirstname?: Maybe<Order_By>;
  PropertyOwnerSurname?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  RentPerSquareMeter?: Maybe<Order_By>;
  RnSubpropertyType?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidCustomer?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SourceCustomer?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rpt_PropertyValuation_Min_Fields = {
  __typename?: "rtcs_db_RPT_PropertyValuation_min_fields";
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["float8"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  AnnualGrossValue?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["float8"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  RentPerSquareMeter?: Maybe<Scalars["float8"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.RPT_PropertyValuation" */
export type Rtcs_Db_Rpt_PropertyValuation_Min_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AccomodationOthers?: Maybe<Order_By>;
  AnnualGazetteValue?: Maybe<Order_By>;
  AnnualGrossValue?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerName?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyLegalEntity?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyOwnerFirstname?: Maybe<Order_By>;
  PropertyOwnerSurname?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  RentPerSquareMeter?: Maybe<Order_By>;
  RnSubpropertyType?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidCustomer?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SourceCustomer?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.RPT_PropertyValuation" */
export type Rtcs_Db_Rpt_PropertyValuation_Mutation_Response = {
  __typename?: "rtcs_db_RPT_PropertyValuation_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rpt_PropertyValuation>;
};

/** input type for inserting object relation for remote table "rtcs_db.RPT_PropertyValuation" */
export type Rtcs_Db_Rpt_PropertyValuation_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rpt_PropertyValuation_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.RPT_PropertyValuation" */
export type Rtcs_Db_Rpt_PropertyValuation_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AccomodationOthers?: Maybe<Order_By>;
  AnnualGazetteValue?: Maybe<Order_By>;
  AnnualGrossValue?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerName?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyLegalEntity?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyOwnerFirstname?: Maybe<Order_By>;
  PropertyOwnerSurname?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  RentPerSquareMeter?: Maybe<Order_By>;
  RnSubpropertyType?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidCustomer?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SourceCustomer?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.RPT_PropertyValuation" */
export enum Rtcs_Db_Rpt_PropertyValuation_Select_Column {
  /** column name */
  AccomodationBedrooms = "AccomodationBedrooms",
  /** column name */
  AccomodationBuiltUpArea = "AccomodationBuiltUpArea",
  /** column name */
  AccomodationOthers = "AccomodationOthers",
  /** column name */
  AnnualGazetteValue = "AnnualGazetteValue",
  /** column name */
  AnnualGrossValue = "AnnualGrossValue",
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  CamvId = "CamvId",
  /** column name */
  CountyId = "CountyId",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CurrentRateableValue = "CurrentRateableValue",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DerivedNoOfBedrooms = "DerivedNoOfBedrooms",
  /** column name */
  DistrictId = "DistrictId",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  Gml = "Gml",
  /** column name */
  Grossrentvalueadjusted = "Grossrentvalueadjusted",
  /** column name */
  Grossvalue = "Grossvalue",
  /** column name */
  ImgPath = "ImgPath",
  /** column name */
  KccaNoOfBedrooms = "KccaNoOfBedrooms",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MapTitle = "MapTitle",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MonthlyGazetteValue = "MonthlyGazetteValue",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  NumRentedProperties = "NumRentedProperties",
  /** column name */
  NwscCustomerId = "NwscCustomerId",
  /** column name */
  NwscCustomerName = "NwscCustomerName",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyId = "PropertyId",
  /** column name */
  PropertyLegalEntity = "PropertyLegalEntity",
  /** column name */
  PropertyNo = "PropertyNo",
  /** column name */
  PropertyOwnerFirstname = "PropertyOwnerFirstname",
  /** column name */
  PropertyOwnerSurname = "PropertyOwnerSurname",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertyType = "PropertyType",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  Propertyno = "Propertyno",
  /** column name */
  Propertyrentedstatus = "Propertyrentedstatus",
  /** column name */
  Rateablevalue = "Rateablevalue",
  /** column name */
  RentPerSquareMeter = "RentPerSquareMeter",
  /** column name */
  RnSubpropertyType = "RnSubpropertyType",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  RnidCustomer = "RnidCustomer",
  /** column name */
  Source = "Source",
  /** column name */
  SourceCustomer = "SourceCustomer",
  /** column name */
  SquareMeters = "SquareMeters",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  SubCountyId = "SubCountyId",
  /** column name */
  SubPropertyTypeId = "SubPropertyTypeId",
  /** column name */
  Supplementary = "Supplementary",
  /** column name */
  Village = "Village",
  /** column name */
  VillageId = "VillageId",
}

/** input type for updating data in table "rtcs_db.RPT_PropertyValuation" */
export type Rtcs_Db_Rpt_PropertyValuation_Set_Input = {
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["float8"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  AnnualGrossValue?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["float8"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  RentPerSquareMeter?: Maybe<Scalars["float8"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rpt_PropertyValuation_Stddev_Fields = {
  __typename?: "rtcs_db_RPT_PropertyValuation_stddev_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["Float"]>;
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  AnnualGrossValue?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  RentPerSquareMeter?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.RPT_PropertyValuation" */
export type Rtcs_Db_Rpt_PropertyValuation_Stddev_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AnnualGazetteValue?: Maybe<Order_By>;
  AnnualGrossValue?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  RentPerSquareMeter?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rpt_PropertyValuation_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_RPT_PropertyValuation_stddev_pop_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["Float"]>;
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  AnnualGrossValue?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  RentPerSquareMeter?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.RPT_PropertyValuation" */
export type Rtcs_Db_Rpt_PropertyValuation_Stddev_Pop_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AnnualGazetteValue?: Maybe<Order_By>;
  AnnualGrossValue?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  RentPerSquareMeter?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rpt_PropertyValuation_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_RPT_PropertyValuation_stddev_samp_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["Float"]>;
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  AnnualGrossValue?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  RentPerSquareMeter?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.RPT_PropertyValuation" */
export type Rtcs_Db_Rpt_PropertyValuation_Stddev_Samp_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AnnualGazetteValue?: Maybe<Order_By>;
  AnnualGrossValue?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  RentPerSquareMeter?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rpt_PropertyValuation_Sum_Fields = {
  __typename?: "rtcs_db_RPT_PropertyValuation_sum_fields";
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["float8"]>;
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  AnnualGrossValue?: Maybe<Scalars["float8"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  RentPerSquareMeter?: Maybe<Scalars["float8"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.RPT_PropertyValuation" */
export type Rtcs_Db_Rpt_PropertyValuation_Sum_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AnnualGazetteValue?: Maybe<Order_By>;
  AnnualGrossValue?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  RentPerSquareMeter?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rpt_PropertyValuation_Var_Pop_Fields = {
  __typename?: "rtcs_db_RPT_PropertyValuation_var_pop_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["Float"]>;
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  AnnualGrossValue?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  RentPerSquareMeter?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.RPT_PropertyValuation" */
export type Rtcs_Db_Rpt_PropertyValuation_Var_Pop_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AnnualGazetteValue?: Maybe<Order_By>;
  AnnualGrossValue?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  RentPerSquareMeter?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rpt_PropertyValuation_Var_Samp_Fields = {
  __typename?: "rtcs_db_RPT_PropertyValuation_var_samp_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["Float"]>;
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  AnnualGrossValue?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  RentPerSquareMeter?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.RPT_PropertyValuation" */
export type Rtcs_Db_Rpt_PropertyValuation_Var_Samp_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AnnualGazetteValue?: Maybe<Order_By>;
  AnnualGrossValue?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  RentPerSquareMeter?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rpt_PropertyValuation_Variance_Fields = {
  __typename?: "rtcs_db_RPT_PropertyValuation_variance_fields";
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["Float"]>;
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  AnnualGrossValue?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  RentPerSquareMeter?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.RPT_PropertyValuation" */
export type Rtcs_Db_Rpt_PropertyValuation_Variance_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AnnualGazetteValue?: Maybe<Order_By>;
  AnnualGrossValue?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  RentPerSquareMeter?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.RPT_RentalTenantPayments" */
export type Rtcs_Db_Rpt_RentalTenantPayments = {
  __typename?: "rtcs_db_RPT_RentalTenantPayments";
  File?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["String"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["String"]>;
  TenancyPeriodTo?: Maybe<Scalars["String"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.RPT_RentalTenantPayments" */
export type Rtcs_Db_Rpt_RentalTenantPayments_Aggregate = {
  __typename?: "rtcs_db_RPT_RentalTenantPayments_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rpt_RentalTenantPayments>;
};

/** aggregate fields of "rtcs_db.RPT_RentalTenantPayments" */
export type Rtcs_Db_Rpt_RentalTenantPayments_Aggregate_Fields = {
  __typename?: "rtcs_db_RPT_RentalTenantPayments_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.RPT_RentalTenantPayments" */
export type Rtcs_Db_Rpt_RentalTenantPayments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rpt_RentalTenantPayments_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.RPT_RentalTenantPayments" */
export type Rtcs_Db_Rpt_RentalTenantPayments_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.RPT_RentalTenantPayments" */
export type Rtcs_Db_Rpt_RentalTenantPayments_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rpt_RentalTenantPayments_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rpt_RentalTenantPayments_Avg_Fields = {
  __typename?: "rtcs_db_RPT_RentalTenantPayments_avg_fields";
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.RPT_RentalTenantPayments" */
export type Rtcs_Db_Rpt_RentalTenantPayments_Avg_Order_By = {
  RentPaid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.RPT_RentalTenantPayments". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rpt_RentalTenantPayments_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  IsAmendment?: Maybe<String_Comparison_Exp>;
  LandlordName?: Maybe<String_Comparison_Exp>;
  LandlordTin?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<String_Comparison_Exp>;
  PremiseLocation?: Maybe<String_Comparison_Exp>;
  RentPaid?: Maybe<Float8_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnRentPaidId?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  TenancyPeriodFrom?: Maybe<String_Comparison_Exp>;
  TenancyPeriodTo?: Maybe<String_Comparison_Exp>;
  TenantName?: Maybe<String_Comparison_Exp>;
  TenantTin?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.RPT_RentalTenantPayments" */
export type Rtcs_Db_Rpt_RentalTenantPayments_Inc_Input = {
  RentPaid?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.RPT_RentalTenantPayments" */
export type Rtcs_Db_Rpt_RentalTenantPayments_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["String"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["String"]>;
  TenancyPeriodTo?: Maybe<Scalars["String"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rpt_RentalTenantPayments_Max_Fields = {
  __typename?: "rtcs_db_RPT_RentalTenantPayments_max_fields";
  File?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["String"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["String"]>;
  TenancyPeriodTo?: Maybe<Scalars["String"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.RPT_RentalTenantPayments" */
export type Rtcs_Db_Rpt_RentalTenantPayments_Max_Order_By = {
  File?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  LandlordName?: Maybe<Order_By>;
  LandlordTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnRentPaidId?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  TenantName?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rpt_RentalTenantPayments_Min_Fields = {
  __typename?: "rtcs_db_RPT_RentalTenantPayments_min_fields";
  File?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["String"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["String"]>;
  TenancyPeriodTo?: Maybe<Scalars["String"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.RPT_RentalTenantPayments" */
export type Rtcs_Db_Rpt_RentalTenantPayments_Min_Order_By = {
  File?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  LandlordName?: Maybe<Order_By>;
  LandlordTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnRentPaidId?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  TenantName?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.RPT_RentalTenantPayments" */
export type Rtcs_Db_Rpt_RentalTenantPayments_Mutation_Response = {
  __typename?: "rtcs_db_RPT_RentalTenantPayments_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rpt_RentalTenantPayments>;
};

/** input type for inserting object relation for remote table "rtcs_db.RPT_RentalTenantPayments" */
export type Rtcs_Db_Rpt_RentalTenantPayments_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rpt_RentalTenantPayments_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.RPT_RentalTenantPayments" */
export type Rtcs_Db_Rpt_RentalTenantPayments_Order_By = {
  File?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  LandlordName?: Maybe<Order_By>;
  LandlordTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnRentPaidId?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  TenantName?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.RPT_RentalTenantPayments" */
export enum Rtcs_Db_Rpt_RentalTenantPayments_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  IsAmendment = "IsAmendment",
  /** column name */
  LandlordName = "LandlordName",
  /** column name */
  LandlordTin = "LandlordTin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  PremiseLocation = "PremiseLocation",
  /** column name */
  RentPaid = "RentPaid",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnRentPaidId = "RtnRentPaidId",
  /** column name */
  Source = "Source",
  /** column name */
  TenancyPeriodFrom = "TenancyPeriodFrom",
  /** column name */
  TenancyPeriodTo = "TenancyPeriodTo",
  /** column name */
  TenantName = "TenantName",
  /** column name */
  TenantTin = "TenantTin",
}

/** input type for updating data in table "rtcs_db.RPT_RentalTenantPayments" */
export type Rtcs_Db_Rpt_RentalTenantPayments_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["String"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["String"]>;
  TenancyPeriodTo?: Maybe<Scalars["String"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rpt_RentalTenantPayments_Stddev_Fields = {
  __typename?: "rtcs_db_RPT_RentalTenantPayments_stddev_fields";
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.RPT_RentalTenantPayments" */
export type Rtcs_Db_Rpt_RentalTenantPayments_Stddev_Order_By = {
  RentPaid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rpt_RentalTenantPayments_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_RPT_RentalTenantPayments_stddev_pop_fields";
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.RPT_RentalTenantPayments" */
export type Rtcs_Db_Rpt_RentalTenantPayments_Stddev_Pop_Order_By = {
  RentPaid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rpt_RentalTenantPayments_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_RPT_RentalTenantPayments_stddev_samp_fields";
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.RPT_RentalTenantPayments" */
export type Rtcs_Db_Rpt_RentalTenantPayments_Stddev_Samp_Order_By = {
  RentPaid?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rpt_RentalTenantPayments_Sum_Fields = {
  __typename?: "rtcs_db_RPT_RentalTenantPayments_sum_fields";
  RentPaid?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.RPT_RentalTenantPayments" */
export type Rtcs_Db_Rpt_RentalTenantPayments_Sum_Order_By = {
  RentPaid?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rpt_RentalTenantPayments_Var_Pop_Fields = {
  __typename?: "rtcs_db_RPT_RentalTenantPayments_var_pop_fields";
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.RPT_RentalTenantPayments" */
export type Rtcs_Db_Rpt_RentalTenantPayments_Var_Pop_Order_By = {
  RentPaid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rpt_RentalTenantPayments_Var_Samp_Fields = {
  __typename?: "rtcs_db_RPT_RentalTenantPayments_var_samp_fields";
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.RPT_RentalTenantPayments" */
export type Rtcs_Db_Rpt_RentalTenantPayments_Var_Samp_Order_By = {
  RentPaid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rpt_RentalTenantPayments_Variance_Fields = {
  __typename?: "rtcs_db_RPT_RentalTenantPayments_variance_fields";
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.RPT_RentalTenantPayments" */
export type Rtcs_Db_Rpt_RentalTenantPayments_Variance_Order_By = {
  RentPaid?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.aggregate_result" */
export type Rtcs_Db_Aggregate_Result = {
  __typename?: "rtcs_db_aggregate_result";
  operation?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["bigint"]>;
};

/** aggregated selection of "rtcs_db.aggregate_result" */
export type Rtcs_Db_Aggregate_Result_Aggregate = {
  __typename?: "rtcs_db_aggregate_result_aggregate";
  aggregate?: Maybe<Rtcs_Db_Aggregate_Result_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Aggregate_Result>;
};

/** aggregate fields of "rtcs_db.aggregate_result" */
export type Rtcs_Db_Aggregate_Result_Aggregate_Fields = {
  __typename?: "rtcs_db_aggregate_result_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Aggregate_Result_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Aggregate_Result_Max_Fields>;
  min?: Maybe<Rtcs_Db_Aggregate_Result_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Aggregate_Result_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Aggregate_Result_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Aggregate_Result_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Aggregate_Result_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Aggregate_Result_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Aggregate_Result_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Aggregate_Result_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.aggregate_result" */
export type Rtcs_Db_Aggregate_Result_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.aggregate_result" */
export type Rtcs_Db_Aggregate_Result_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Aggregate_Result_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Aggregate_Result_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Aggregate_Result_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Aggregate_Result_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Aggregate_Result_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Aggregate_Result_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Aggregate_Result_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Aggregate_Result_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Aggregate_Result_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Aggregate_Result_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.aggregate_result" */
export type Rtcs_Db_Aggregate_Result_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Aggregate_Result_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Aggregate_Result_Avg_Fields = {
  __typename?: "rtcs_db_aggregate_result_avg_fields";
  value?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.aggregate_result" */
export type Rtcs_Db_Aggregate_Result_Avg_Order_By = {
  value?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.aggregate_result". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Aggregate_Result_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>>>;
  operation?: Maybe<String_Comparison_Exp>;
  value?: Maybe<Bigint_Comparison_Exp>;
};

/** input type for incrementing integer column in table "rtcs_db.aggregate_result" */
export type Rtcs_Db_Aggregate_Result_Inc_Input = {
  value?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.aggregate_result" */
export type Rtcs_Db_Aggregate_Result_Insert_Input = {
  operation?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["bigint"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Aggregate_Result_Max_Fields = {
  __typename?: "rtcs_db_aggregate_result_max_fields";
  operation?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["bigint"]>;
};

/** order by max() on columns of table "rtcs_db.aggregate_result" */
export type Rtcs_Db_Aggregate_Result_Max_Order_By = {
  operation?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Aggregate_Result_Min_Fields = {
  __typename?: "rtcs_db_aggregate_result_min_fields";
  operation?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["bigint"]>;
};

/** order by min() on columns of table "rtcs_db.aggregate_result" */
export type Rtcs_Db_Aggregate_Result_Min_Order_By = {
  operation?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.aggregate_result" */
export type Rtcs_Db_Aggregate_Result_Mutation_Response = {
  __typename?: "rtcs_db_aggregate_result_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Aggregate_Result>;
};

/** input type for inserting object relation for remote table "rtcs_db.aggregate_result" */
export type Rtcs_Db_Aggregate_Result_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Aggregate_Result_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.aggregate_result" */
export type Rtcs_Db_Aggregate_Result_Order_By = {
  operation?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.aggregate_result" */
export enum Rtcs_Db_Aggregate_Result_Select_Column {
  /** column name */
  Operation = "operation",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "rtcs_db.aggregate_result" */
export type Rtcs_Db_Aggregate_Result_Set_Input = {
  operation?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["bigint"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Aggregate_Result_Stddev_Fields = {
  __typename?: "rtcs_db_aggregate_result_stddev_fields";
  value?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.aggregate_result" */
export type Rtcs_Db_Aggregate_Result_Stddev_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Aggregate_Result_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_aggregate_result_stddev_pop_fields";
  value?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.aggregate_result" */
export type Rtcs_Db_Aggregate_Result_Stddev_Pop_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Aggregate_Result_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_aggregate_result_stddev_samp_fields";
  value?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.aggregate_result" */
export type Rtcs_Db_Aggregate_Result_Stddev_Samp_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Aggregate_Result_Sum_Fields = {
  __typename?: "rtcs_db_aggregate_result_sum_fields";
  value?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.aggregate_result" */
export type Rtcs_Db_Aggregate_Result_Sum_Order_By = {
  value?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.aggregate_result_txt" */
export type Rtcs_Db_Aggregate_Result_Txt = {
  __typename?: "rtcs_db_aggregate_result_txt";
  operation?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.aggregate_result_txt" */
export type Rtcs_Db_Aggregate_Result_Txt_Aggregate = {
  __typename?: "rtcs_db_aggregate_result_txt_aggregate";
  aggregate?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Aggregate_Result_Txt>;
};

/** aggregate fields of "rtcs_db.aggregate_result_txt" */
export type Rtcs_Db_Aggregate_Result_Txt_Aggregate_Fields = {
  __typename?: "rtcs_db_aggregate_result_txt_aggregate_fields";
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Max_Fields>;
  min?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Min_Fields>;
};

/** aggregate fields of "rtcs_db.aggregate_result_txt" */
export type Rtcs_Db_Aggregate_Result_Txt_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.aggregate_result_txt" */
export type Rtcs_Db_Aggregate_Result_Txt_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Min_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.aggregate_result_txt" */
export type Rtcs_Db_Aggregate_Result_Txt_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Aggregate_Result_Txt_Insert_Input>;
};

/** Boolean expression to filter rows from the table "rtcs_db.aggregate_result_txt". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Aggregate_Result_Txt_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>>>;
  operation?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "rtcs_db.aggregate_result_txt" */
export type Rtcs_Db_Aggregate_Result_Txt_Insert_Input = {
  operation?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Aggregate_Result_Txt_Max_Fields = {
  __typename?: "rtcs_db_aggregate_result_txt_max_fields";
  operation?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.aggregate_result_txt" */
export type Rtcs_Db_Aggregate_Result_Txt_Max_Order_By = {
  operation?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Aggregate_Result_Txt_Min_Fields = {
  __typename?: "rtcs_db_aggregate_result_txt_min_fields";
  operation?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.aggregate_result_txt" */
export type Rtcs_Db_Aggregate_Result_Txt_Min_Order_By = {
  operation?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.aggregate_result_txt" */
export type Rtcs_Db_Aggregate_Result_Txt_Mutation_Response = {
  __typename?: "rtcs_db_aggregate_result_txt_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Aggregate_Result_Txt>;
};

/** input type for inserting object relation for remote table "rtcs_db.aggregate_result_txt" */
export type Rtcs_Db_Aggregate_Result_Txt_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Aggregate_Result_Txt_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.aggregate_result_txt" */
export type Rtcs_Db_Aggregate_Result_Txt_Order_By = {
  operation?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.aggregate_result_txt" */
export enum Rtcs_Db_Aggregate_Result_Txt_Select_Column {
  /** column name */
  Operation = "operation",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "rtcs_db.aggregate_result_txt" */
export type Rtcs_Db_Aggregate_Result_Txt_Set_Input = {
  operation?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Aggregate_Result_Var_Pop_Fields = {
  __typename?: "rtcs_db_aggregate_result_var_pop_fields";
  value?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.aggregate_result" */
export type Rtcs_Db_Aggregate_Result_Var_Pop_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Aggregate_Result_Var_Samp_Fields = {
  __typename?: "rtcs_db_aggregate_result_var_samp_fields";
  value?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.aggregate_result" */
export type Rtcs_Db_Aggregate_Result_Var_Samp_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Aggregate_Result_Variance_Fields = {
  __typename?: "rtcs_db_aggregate_result_variance_fields";
  value?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.aggregate_result" */
export type Rtcs_Db_Aggregate_Result_Variance_Order_By = {
  value?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.gazette_CommercialRates" */
export type Rtcs_Db_Gazette_CommercialRates = {
  __typename?: "rtcs_db_gazette_CommercialRates";
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["String"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.gazette_CommercialRates" */
export type Rtcs_Db_Gazette_CommercialRates_Aggregate = {
  __typename?: "rtcs_db_gazette_CommercialRates_aggregate";
  aggregate?: Maybe<Rtcs_Db_Gazette_CommercialRates_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Gazette_CommercialRates>;
};

/** aggregate fields of "rtcs_db.gazette_CommercialRates" */
export type Rtcs_Db_Gazette_CommercialRates_Aggregate_Fields = {
  __typename?: "rtcs_db_gazette_CommercialRates_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Gazette_CommercialRates_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Gazette_CommercialRates_Max_Fields>;
  min?: Maybe<Rtcs_Db_Gazette_CommercialRates_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Gazette_CommercialRates_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Gazette_CommercialRates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Gazette_CommercialRates_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Gazette_CommercialRates_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Gazette_CommercialRates_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Gazette_CommercialRates_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Gazette_CommercialRates_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.gazette_CommercialRates" */
export type Rtcs_Db_Gazette_CommercialRates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Gazette_CommercialRates_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.gazette_CommercialRates" */
export type Rtcs_Db_Gazette_CommercialRates_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Gazette_CommercialRates_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Gazette_CommercialRates_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Gazette_CommercialRates_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Gazette_CommercialRates_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Gazette_CommercialRates_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Gazette_CommercialRates_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Gazette_CommercialRates_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Gazette_CommercialRates_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Gazette_CommercialRates_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Gazette_CommercialRates_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.gazette_CommercialRates" */
export type Rtcs_Db_Gazette_CommercialRates_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Gazette_CommercialRates_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Gazette_CommercialRates_Avg_Fields = {
  __typename?: "rtcs_db_gazette_CommercialRates_avg_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.gazette_CommercialRates" */
export type Rtcs_Db_Gazette_CommercialRates_Avg_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.gazette_CommercialRates". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Gazette_CommercialRates_Bool_Exp = {
  AnnualGazetteValue?: Maybe<Float8_Comparison_Exp>;
  DerivedNoOfBedrooms?: Maybe<String_Comparison_Exp>;
  KccaNoOfBedrooms?: Maybe<Float8_Comparison_Exp>;
  MonthlyGazetteValue?: Maybe<Float8_Comparison_Exp>;
  PropertyId?: Maybe<String_Comparison_Exp>;
  PropertyLegalEntity?: Maybe<String_Comparison_Exp>;
  PropertyOwnerFirstname?: Maybe<String_Comparison_Exp>;
  PropertyOwnerSurname?: Maybe<String_Comparison_Exp>;
  PropertyType?: Maybe<String_Comparison_Exp>;
  RnSubpropertyType?: Maybe<String_Comparison_Exp>;
  SquareMeters?: Maybe<Float8_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Gazette_CommercialRates_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Gazette_CommercialRates_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Gazette_CommercialRates_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.gazette_CommercialRates" */
export type Rtcs_Db_Gazette_CommercialRates_Inc_Input = {
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.gazette_CommercialRates" */
export type Rtcs_Db_Gazette_CommercialRates_Insert_Input = {
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["String"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Gazette_CommercialRates_Max_Fields = {
  __typename?: "rtcs_db_gazette_CommercialRates_max_fields";
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["String"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.gazette_CommercialRates" */
export type Rtcs_Db_Gazette_CommercialRates_Max_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyLegalEntity?: Maybe<Order_By>;
  PropertyOwnerFirstname?: Maybe<Order_By>;
  PropertyOwnerSurname?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  RnSubpropertyType?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Gazette_CommercialRates_Min_Fields = {
  __typename?: "rtcs_db_gazette_CommercialRates_min_fields";
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["String"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.gazette_CommercialRates" */
export type Rtcs_Db_Gazette_CommercialRates_Min_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyLegalEntity?: Maybe<Order_By>;
  PropertyOwnerFirstname?: Maybe<Order_By>;
  PropertyOwnerSurname?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  RnSubpropertyType?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.gazette_CommercialRates" */
export type Rtcs_Db_Gazette_CommercialRates_Mutation_Response = {
  __typename?: "rtcs_db_gazette_CommercialRates_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Gazette_CommercialRates>;
};

/** input type for inserting object relation for remote table "rtcs_db.gazette_CommercialRates" */
export type Rtcs_Db_Gazette_CommercialRates_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Gazette_CommercialRates_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.gazette_CommercialRates" */
export type Rtcs_Db_Gazette_CommercialRates_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyLegalEntity?: Maybe<Order_By>;
  PropertyOwnerFirstname?: Maybe<Order_By>;
  PropertyOwnerSurname?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  RnSubpropertyType?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.gazette_CommercialRates" */
export enum Rtcs_Db_Gazette_CommercialRates_Select_Column {
  /** column name */
  AnnualGazetteValue = "AnnualGazetteValue",
  /** column name */
  DerivedNoOfBedrooms = "DerivedNoOfBedrooms",
  /** column name */
  KccaNoOfBedrooms = "KccaNoOfBedrooms",
  /** column name */
  MonthlyGazetteValue = "MonthlyGazetteValue",
  /** column name */
  PropertyId = "PropertyId",
  /** column name */
  PropertyLegalEntity = "PropertyLegalEntity",
  /** column name */
  PropertyOwnerFirstname = "PropertyOwnerFirstname",
  /** column name */
  PropertyOwnerSurname = "PropertyOwnerSurname",
  /** column name */
  PropertyType = "PropertyType",
  /** column name */
  RnSubpropertyType = "RnSubpropertyType",
  /** column name */
  SquareMeters = "SquareMeters",
  /** column name */
  Village = "Village",
}

/** input type for updating data in table "rtcs_db.gazette_CommercialRates" */
export type Rtcs_Db_Gazette_CommercialRates_Set_Input = {
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["String"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Gazette_CommercialRates_Stddev_Fields = {
  __typename?: "rtcs_db_gazette_CommercialRates_stddev_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.gazette_CommercialRates" */
export type Rtcs_Db_Gazette_CommercialRates_Stddev_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Gazette_CommercialRates_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_gazette_CommercialRates_stddev_pop_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.gazette_CommercialRates" */
export type Rtcs_Db_Gazette_CommercialRates_Stddev_Pop_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Gazette_CommercialRates_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_gazette_CommercialRates_stddev_samp_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.gazette_CommercialRates" */
export type Rtcs_Db_Gazette_CommercialRates_Stddev_Samp_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Gazette_CommercialRates_Sum_Fields = {
  __typename?: "rtcs_db_gazette_CommercialRates_sum_fields";
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.gazette_CommercialRates" */
export type Rtcs_Db_Gazette_CommercialRates_Sum_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Gazette_CommercialRates_Var_Pop_Fields = {
  __typename?: "rtcs_db_gazette_CommercialRates_var_pop_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.gazette_CommercialRates" */
export type Rtcs_Db_Gazette_CommercialRates_Var_Pop_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Gazette_CommercialRates_Var_Samp_Fields = {
  __typename?: "rtcs_db_gazette_CommercialRates_var_samp_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.gazette_CommercialRates" */
export type Rtcs_Db_Gazette_CommercialRates_Var_Samp_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Gazette_CommercialRates_Variance_Fields = {
  __typename?: "rtcs_db_gazette_CommercialRates_variance_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.gazette_CommercialRates" */
export type Rtcs_Db_Gazette_CommercialRates_Variance_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.gazette_ResidentialRates" */
export type Rtcs_Db_Gazette_ResidentialRates = {
  __typename?: "rtcs_db_gazette_ResidentialRates";
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.gazette_ResidentialRates" */
export type Rtcs_Db_Gazette_ResidentialRates_Aggregate = {
  __typename?: "rtcs_db_gazette_ResidentialRates_aggregate";
  aggregate?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Gazette_ResidentialRates>;
};

/** aggregate fields of "rtcs_db.gazette_ResidentialRates" */
export type Rtcs_Db_Gazette_ResidentialRates_Aggregate_Fields = {
  __typename?: "rtcs_db_gazette_ResidentialRates_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Max_Fields>;
  min?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.gazette_ResidentialRates" */
export type Rtcs_Db_Gazette_ResidentialRates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Gazette_ResidentialRates_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.gazette_ResidentialRates" */
export type Rtcs_Db_Gazette_ResidentialRates_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.gazette_ResidentialRates" */
export type Rtcs_Db_Gazette_ResidentialRates_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Gazette_ResidentialRates_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Gazette_ResidentialRates_Avg_Fields = {
  __typename?: "rtcs_db_gazette_ResidentialRates_avg_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.gazette_ResidentialRates" */
export type Rtcs_Db_Gazette_ResidentialRates_Avg_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.gazette_ResidentialRates". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Gazette_ResidentialRates_Bool_Exp = {
  AnnualGazetteValue?: Maybe<Float8_Comparison_Exp>;
  DerivedNoOfBedrooms?: Maybe<Float8_Comparison_Exp>;
  KccaNoOfBedrooms?: Maybe<Float8_Comparison_Exp>;
  MonthlyGazetteValue?: Maybe<Float8_Comparison_Exp>;
  PropertyId?: Maybe<String_Comparison_Exp>;
  PropertyLegalEntity?: Maybe<String_Comparison_Exp>;
  PropertyOwnerFirstname?: Maybe<String_Comparison_Exp>;
  PropertyOwnerSurname?: Maybe<String_Comparison_Exp>;
  PropertyType?: Maybe<String_Comparison_Exp>;
  RnSubpropertyType?: Maybe<String_Comparison_Exp>;
  SquareMeters?: Maybe<Float8_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Gazette_ResidentialRates_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Gazette_ResidentialRates_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.gazette_ResidentialRates" */
export type Rtcs_Db_Gazette_ResidentialRates_Inc_Input = {
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.gazette_ResidentialRates" */
export type Rtcs_Db_Gazette_ResidentialRates_Insert_Input = {
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Gazette_ResidentialRates_Max_Fields = {
  __typename?: "rtcs_db_gazette_ResidentialRates_max_fields";
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.gazette_ResidentialRates" */
export type Rtcs_Db_Gazette_ResidentialRates_Max_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyLegalEntity?: Maybe<Order_By>;
  PropertyOwnerFirstname?: Maybe<Order_By>;
  PropertyOwnerSurname?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  RnSubpropertyType?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Gazette_ResidentialRates_Min_Fields = {
  __typename?: "rtcs_db_gazette_ResidentialRates_min_fields";
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.gazette_ResidentialRates" */
export type Rtcs_Db_Gazette_ResidentialRates_Min_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyLegalEntity?: Maybe<Order_By>;
  PropertyOwnerFirstname?: Maybe<Order_By>;
  PropertyOwnerSurname?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  RnSubpropertyType?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.gazette_ResidentialRates" */
export type Rtcs_Db_Gazette_ResidentialRates_Mutation_Response = {
  __typename?: "rtcs_db_gazette_ResidentialRates_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Gazette_ResidentialRates>;
};

/** input type for inserting object relation for remote table "rtcs_db.gazette_ResidentialRates" */
export type Rtcs_Db_Gazette_ResidentialRates_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Gazette_ResidentialRates_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.gazette_ResidentialRates" */
export type Rtcs_Db_Gazette_ResidentialRates_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyLegalEntity?: Maybe<Order_By>;
  PropertyOwnerFirstname?: Maybe<Order_By>;
  PropertyOwnerSurname?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  RnSubpropertyType?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.gazette_ResidentialRates" */
export enum Rtcs_Db_Gazette_ResidentialRates_Select_Column {
  /** column name */
  AnnualGazetteValue = "AnnualGazetteValue",
  /** column name */
  DerivedNoOfBedrooms = "DerivedNoOfBedrooms",
  /** column name */
  KccaNoOfBedrooms = "KccaNoOfBedrooms",
  /** column name */
  MonthlyGazetteValue = "MonthlyGazetteValue",
  /** column name */
  PropertyId = "PropertyId",
  /** column name */
  PropertyLegalEntity = "PropertyLegalEntity",
  /** column name */
  PropertyOwnerFirstname = "PropertyOwnerFirstname",
  /** column name */
  PropertyOwnerSurname = "PropertyOwnerSurname",
  /** column name */
  PropertyType = "PropertyType",
  /** column name */
  RnSubpropertyType = "RnSubpropertyType",
  /** column name */
  SquareMeters = "SquareMeters",
  /** column name */
  Village = "Village",
}

/** input type for updating data in table "rtcs_db.gazette_ResidentialRates" */
export type Rtcs_Db_Gazette_ResidentialRates_Set_Input = {
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyLegalEntity?: Maybe<Scalars["String"]>;
  PropertyOwnerFirstname?: Maybe<Scalars["String"]>;
  PropertyOwnerSurname?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RnSubpropertyType?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Gazette_ResidentialRates_Stddev_Fields = {
  __typename?: "rtcs_db_gazette_ResidentialRates_stddev_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.gazette_ResidentialRates" */
export type Rtcs_Db_Gazette_ResidentialRates_Stddev_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Gazette_ResidentialRates_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_gazette_ResidentialRates_stddev_pop_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.gazette_ResidentialRates" */
export type Rtcs_Db_Gazette_ResidentialRates_Stddev_Pop_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Gazette_ResidentialRates_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_gazette_ResidentialRates_stddev_samp_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.gazette_ResidentialRates" */
export type Rtcs_Db_Gazette_ResidentialRates_Stddev_Samp_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Gazette_ResidentialRates_Sum_Fields = {
  __typename?: "rtcs_db_gazette_ResidentialRates_sum_fields";
  AnnualGazetteValue?: Maybe<Scalars["float8"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.gazette_ResidentialRates" */
export type Rtcs_Db_Gazette_ResidentialRates_Sum_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Gazette_ResidentialRates_Var_Pop_Fields = {
  __typename?: "rtcs_db_gazette_ResidentialRates_var_pop_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.gazette_ResidentialRates" */
export type Rtcs_Db_Gazette_ResidentialRates_Var_Pop_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Gazette_ResidentialRates_Var_Samp_Fields = {
  __typename?: "rtcs_db_gazette_ResidentialRates_var_samp_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.gazette_ResidentialRates" */
export type Rtcs_Db_Gazette_ResidentialRates_Var_Samp_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Gazette_ResidentialRates_Variance_Fields = {
  __typename?: "rtcs_db_gazette_ResidentialRates_variance_fields";
  AnnualGazetteValue?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  KccaNoOfBedrooms?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.gazette_ResidentialRates" */
export type Rtcs_Db_Gazette_ResidentialRates_Variance_Order_By = {
  AnnualGazetteValue?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  KccaNoOfBedrooms?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_AssociateType" */
export type Rtcs_Db_Kcca_AssociateType = {
  __typename?: "rtcs_db_kcca_AssociateType";
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_AssociateType" */
export type Rtcs_Db_Kcca_AssociateType_Aggregate = {
  __typename?: "rtcs_db_kcca_AssociateType_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_AssociateType_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_AssociateType>;
};

/** aggregate fields of "rtcs_db.kcca_AssociateType" */
export type Rtcs_Db_Kcca_AssociateType_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_AssociateType_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_AssociateType_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_AssociateType_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_AssociateType_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_AssociateType_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_AssociateType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_AssociateType_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_AssociateType_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_AssociateType_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_AssociateType_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_AssociateType_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_AssociateType" */
export type Rtcs_Db_Kcca_AssociateType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_AssociateType_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_AssociateType" */
export type Rtcs_Db_Kcca_AssociateType_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_AssociateType_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_AssociateType_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_AssociateType_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_AssociateType_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_AssociateType_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_AssociateType_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_AssociateType_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_AssociateType_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_AssociateType_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_AssociateType_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_AssociateType" */
export type Rtcs_Db_Kcca_AssociateType_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_AssociateType_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_AssociateType_Avg_Fields = {
  __typename?: "rtcs_db_kcca_AssociateType_avg_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_AssociateType" */
export type Rtcs_Db_Kcca_AssociateType_Avg_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_AssociateType". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_AssociateType_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamp_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_AssociateType_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_AssociateType_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_AssociateType_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_AssociateType" */
export type Rtcs_Db_Kcca_AssociateType_Inc_Input = {
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_AssociateType" */
export type Rtcs_Db_Kcca_AssociateType_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_AssociateType_Max_Fields = {
  __typename?: "rtcs_db_kcca_AssociateType_max_fields";
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_AssociateType" */
export type Rtcs_Db_Kcca_AssociateType_Max_Order_By = {
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_AssociateType_Min_Fields = {
  __typename?: "rtcs_db_kcca_AssociateType_min_fields";
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_AssociateType" */
export type Rtcs_Db_Kcca_AssociateType_Min_Order_By = {
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_AssociateType" */
export type Rtcs_Db_Kcca_AssociateType_Mutation_Response = {
  __typename?: "rtcs_db_kcca_AssociateType_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_AssociateType>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_AssociateType" */
export type Rtcs_Db_Kcca_AssociateType_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_AssociateType_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_AssociateType" */
export type Rtcs_Db_Kcca_AssociateType_Order_By = {
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_AssociateType" */
export enum Rtcs_Db_Kcca_AssociateType_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_AssociateType" */
export type Rtcs_Db_Kcca_AssociateType_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_AssociateType_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_AssociateType_stddev_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_AssociateType" */
export type Rtcs_Db_Kcca_AssociateType_Stddev_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_AssociateType_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_AssociateType_stddev_pop_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_AssociateType" */
export type Rtcs_Db_Kcca_AssociateType_Stddev_Pop_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_AssociateType_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_AssociateType_stddev_samp_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_AssociateType" */
export type Rtcs_Db_Kcca_AssociateType_Stddev_Samp_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_AssociateType_Sum_Fields = {
  __typename?: "rtcs_db_kcca_AssociateType_sum_fields";
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_AssociateType" */
export type Rtcs_Db_Kcca_AssociateType_Sum_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_AssociateType_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_AssociateType_var_pop_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_AssociateType" */
export type Rtcs_Db_Kcca_AssociateType_Var_Pop_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_AssociateType_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_AssociateType_var_samp_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_AssociateType" */
export type Rtcs_Db_Kcca_AssociateType_Var_Samp_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_AssociateType_Variance_Fields = {
  __typename?: "rtcs_db_kcca_AssociateType_variance_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_AssociateType" */
export type Rtcs_Db_Kcca_AssociateType_Variance_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_CommercialProperties" */
export type Rtcs_Db_Kcca_CommercialProperties = {
  __typename?: "rtcs_db_kcca_CommercialProperties";
  Address?: Maybe<Scalars["String"]>;
  AnnualGrossIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CurrencyType?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  MonthlyGrossIncome?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RippleNamiPropertySubtype?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_CommercialProperties" */
export type Rtcs_Db_Kcca_CommercialProperties_Aggregate = {
  __typename?: "rtcs_db_kcca_CommercialProperties_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_CommercialProperties>;
};

/** aggregate fields of "rtcs_db.kcca_CommercialProperties" */
export type Rtcs_Db_Kcca_CommercialProperties_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_CommercialProperties_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_CommercialProperties" */
export type Rtcs_Db_Kcca_CommercialProperties_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_CommercialProperties_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_CommercialProperties" */
export type Rtcs_Db_Kcca_CommercialProperties_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_CommercialProperties" */
export type Rtcs_Db_Kcca_CommercialProperties_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_CommercialProperties_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_CommercialProperties_Avg_Fields = {
  __typename?: "rtcs_db_kcca_CommercialProperties_avg_fields";
  AnnualGrossIncome?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  MonthlyGrossIncome?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_CommercialProperties" */
export type Rtcs_Db_Kcca_CommercialProperties_Avg_Order_By = {
  AnnualGrossIncome?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  MonthlyGrossIncome?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_CommercialProperties". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_CommercialProperties_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  AnnualGrossIncome?: Maybe<Float8_Comparison_Exp>;
  BlockNo?: Maybe<String_Comparison_Exp>;
  CamvId?: Maybe<String_Comparison_Exp>;
  CurrencyType?: Maybe<String_Comparison_Exp>;
  CustomerId?: Maybe<Bigint_Comparison_Exp>;
  DivisionId?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  GrossValue?: Maybe<String_Comparison_Exp>;
  Grossrentvalueadjusted?: Maybe<Float8_Comparison_Exp>;
  HouseNo?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  MonthlyGazetteValue?: Maybe<Float8_Comparison_Exp>;
  MonthlyGrossIncome?: Maybe<Float8_Comparison_Exp>;
  ParishId?: Maybe<Float8_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyNo?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatusId?: Maybe<Float8_Comparison_Exp>;
  PropertyType?: Maybe<String_Comparison_Exp>;
  PropertyTypeId?: Maybe<Float8_Comparison_Exp>;
  Propertyrentedstatus?: Maybe<String_Comparison_Exp>;
  RateableValue?: Maybe<Float8_Comparison_Exp>;
  RippleNamiPropertySubtype?: Maybe<String_Comparison_Exp>;
  SquareMeters?: Maybe<Float8_Comparison_Exp>;
  StreetId?: Maybe<String_Comparison_Exp>;
  VillageId?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_CommercialProperties_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_CommercialProperties_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_CommercialProperties" */
export type Rtcs_Db_Kcca_CommercialProperties_Inc_Input = {
  AnnualGrossIncome?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  MonthlyGrossIncome?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_CommercialProperties" */
export type Rtcs_Db_Kcca_CommercialProperties_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  AnnualGrossIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CurrencyType?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  MonthlyGrossIncome?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RippleNamiPropertySubtype?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_CommercialProperties_Max_Fields = {
  __typename?: "rtcs_db_kcca_CommercialProperties_max_fields";
  Address?: Maybe<Scalars["String"]>;
  AnnualGrossIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CurrencyType?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  MonthlyGrossIncome?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RippleNamiPropertySubtype?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_CommercialProperties" */
export type Rtcs_Db_Kcca_CommercialProperties_Max_Order_By = {
  Address?: Maybe<Order_By>;
  AnnualGrossIncome?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CurrencyType?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  MonthlyGrossIncome?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RippleNamiPropertySubtype?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_CommercialProperties_Min_Fields = {
  __typename?: "rtcs_db_kcca_CommercialProperties_min_fields";
  Address?: Maybe<Scalars["String"]>;
  AnnualGrossIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CurrencyType?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  MonthlyGrossIncome?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RippleNamiPropertySubtype?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_CommercialProperties" */
export type Rtcs_Db_Kcca_CommercialProperties_Min_Order_By = {
  Address?: Maybe<Order_By>;
  AnnualGrossIncome?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CurrencyType?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  MonthlyGrossIncome?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RippleNamiPropertySubtype?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_CommercialProperties" */
export type Rtcs_Db_Kcca_CommercialProperties_Mutation_Response = {
  __typename?: "rtcs_db_kcca_CommercialProperties_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_CommercialProperties>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_CommercialProperties" */
export type Rtcs_Db_Kcca_CommercialProperties_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_CommercialProperties_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_CommercialProperties" */
export type Rtcs_Db_Kcca_CommercialProperties_Order_By = {
  Address?: Maybe<Order_By>;
  AnnualGrossIncome?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CurrencyType?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  MonthlyGrossIncome?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RippleNamiPropertySubtype?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_CommercialProperties" */
export enum Rtcs_Db_Kcca_CommercialProperties_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  AnnualGrossIncome = "AnnualGrossIncome",
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  CamvId = "CamvId",
  /** column name */
  CurrencyType = "CurrencyType",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  File = "File",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  GrossValue = "GrossValue",
  /** column name */
  Grossrentvalueadjusted = "Grossrentvalueadjusted",
  /** column name */
  HouseNo = "HouseNo",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MonthlyGazetteValue = "MonthlyGazetteValue",
  /** column name */
  MonthlyGrossIncome = "MonthlyGrossIncome",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyNo = "PropertyNo",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertyType = "PropertyType",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  Propertyrentedstatus = "Propertyrentedstatus",
  /** column name */
  RateableValue = "RateableValue",
  /** column name */
  RippleNamiPropertySubtype = "RippleNamiPropertySubtype",
  /** column name */
  SquareMeters = "SquareMeters",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  VillageId = "VillageId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_CommercialProperties" */
export type Rtcs_Db_Kcca_CommercialProperties_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  AnnualGrossIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CurrencyType?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  MonthlyGrossIncome?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RippleNamiPropertySubtype?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_CommercialProperties_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_CommercialProperties_stddev_fields";
  AnnualGrossIncome?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  MonthlyGrossIncome?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_CommercialProperties" */
export type Rtcs_Db_Kcca_CommercialProperties_Stddev_Order_By = {
  AnnualGrossIncome?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  MonthlyGrossIncome?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_CommercialProperties_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_CommercialProperties_stddev_pop_fields";
  AnnualGrossIncome?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  MonthlyGrossIncome?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_CommercialProperties" */
export type Rtcs_Db_Kcca_CommercialProperties_Stddev_Pop_Order_By = {
  AnnualGrossIncome?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  MonthlyGrossIncome?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_CommercialProperties_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_CommercialProperties_stddev_samp_fields";
  AnnualGrossIncome?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  MonthlyGrossIncome?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_CommercialProperties" */
export type Rtcs_Db_Kcca_CommercialProperties_Stddev_Samp_Order_By = {
  AnnualGrossIncome?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  MonthlyGrossIncome?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_CommercialProperties_Sum_Fields = {
  __typename?: "rtcs_db_kcca_CommercialProperties_sum_fields";
  AnnualGrossIncome?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MonthlyGazetteValue?: Maybe<Scalars["float8"]>;
  MonthlyGrossIncome?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_CommercialProperties" */
export type Rtcs_Db_Kcca_CommercialProperties_Sum_Order_By = {
  AnnualGrossIncome?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  MonthlyGrossIncome?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_CommercialProperties_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_CommercialProperties_var_pop_fields";
  AnnualGrossIncome?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  MonthlyGrossIncome?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_CommercialProperties" */
export type Rtcs_Db_Kcca_CommercialProperties_Var_Pop_Order_By = {
  AnnualGrossIncome?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  MonthlyGrossIncome?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_CommercialProperties_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_CommercialProperties_var_samp_fields";
  AnnualGrossIncome?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  MonthlyGrossIncome?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_CommercialProperties" */
export type Rtcs_Db_Kcca_CommercialProperties_Var_Samp_Order_By = {
  AnnualGrossIncome?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  MonthlyGrossIncome?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_CommercialProperties_Variance_Fields = {
  __typename?: "rtcs_db_kcca_CommercialProperties_variance_fields";
  AnnualGrossIncome?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MonthlyGazetteValue?: Maybe<Scalars["Float"]>;
  MonthlyGrossIncome?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_CommercialProperties" */
export type Rtcs_Db_Kcca_CommercialProperties_Variance_Order_By = {
  AnnualGrossIncome?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MonthlyGazetteValue?: Maybe<Order_By>;
  MonthlyGrossIncome?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_County" */
export type Rtcs_Db_Kcca_County = {
  __typename?: "rtcs_db_kcca_County";
  CountryId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_County" */
export type Rtcs_Db_Kcca_County_Aggregate = {
  __typename?: "rtcs_db_kcca_County_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_County_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_County>;
};

/** aggregate fields of "rtcs_db.kcca_County" */
export type Rtcs_Db_Kcca_County_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_County_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_County_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_County_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_County_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_County_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_County_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_County_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_County_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_County_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_County_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_County_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_County" */
export type Rtcs_Db_Kcca_County_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_County_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_County" */
export type Rtcs_Db_Kcca_County_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_County_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_County_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_County_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_County_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_County_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_County_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_County_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_County_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_County_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_County_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_County" */
export type Rtcs_Db_Kcca_County_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_County_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_County_Avg_Fields = {
  __typename?: "rtcs_db_kcca_County_avg_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_County" */
export type Rtcs_Db_Kcca_County_Avg_Order_By = {
  CountryId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_County". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_County_Bool_Exp = {
  CountryId?: Maybe<Bigint_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  DistrictId?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_County_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_County_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_County_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_County" */
export type Rtcs_Db_Kcca_County_Inc_Input = {
  CountryId?: Maybe<Scalars["bigint"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_County" */
export type Rtcs_Db_Kcca_County_Insert_Input = {
  CountryId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_County_Max_Fields = {
  __typename?: "rtcs_db_kcca_County_max_fields";
  CountryId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_County" */
export type Rtcs_Db_Kcca_County_Max_Order_By = {
  CountryId?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_County_Min_Fields = {
  __typename?: "rtcs_db_kcca_County_min_fields";
  CountryId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_County" */
export type Rtcs_Db_Kcca_County_Min_Order_By = {
  CountryId?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_County" */
export type Rtcs_Db_Kcca_County_Mutation_Response = {
  __typename?: "rtcs_db_kcca_County_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_County>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_County" */
export type Rtcs_Db_Kcca_County_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_County_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_County" */
export type Rtcs_Db_Kcca_County_Order_By = {
  CountryId?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_County" */
export enum Rtcs_Db_Kcca_County_Select_Column {
  /** column name */
  CountryId = "CountryId",
  /** column name */
  Description = "Description",
  /** column name */
  DistrictId = "DistrictId",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_County" */
export type Rtcs_Db_Kcca_County_Set_Input = {
  CountryId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_County_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_County_stddev_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_County" */
export type Rtcs_Db_Kcca_County_Stddev_Order_By = {
  CountryId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_County_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_County_stddev_pop_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_County" */
export type Rtcs_Db_Kcca_County_Stddev_Pop_Order_By = {
  CountryId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_County_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_County_stddev_samp_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_County" */
export type Rtcs_Db_Kcca_County_Stddev_Samp_Order_By = {
  CountryId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_County_Sum_Fields = {
  __typename?: "rtcs_db_kcca_County_sum_fields";
  CountryId?: Maybe<Scalars["bigint"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_County" */
export type Rtcs_Db_Kcca_County_Sum_Order_By = {
  CountryId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_County_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_County_var_pop_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_County" */
export type Rtcs_Db_Kcca_County_Var_Pop_Order_By = {
  CountryId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_County_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_County_var_samp_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_County" */
export type Rtcs_Db_Kcca_County_Var_Samp_Order_By = {
  CountryId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_County_Variance_Fields = {
  __typename?: "rtcs_db_kcca_County_variance_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_County" */
export type Rtcs_Db_Kcca_County_Variance_Order_By = {
  CountryId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_Customer" */
export type Rtcs_Db_Kcca_Customer = {
  __typename?: "rtcs_db_kcca_Customer";
  AcquiredName?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["timestamp"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["String"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.kcca_CustomerApplicantType" */
export type Rtcs_Db_Kcca_CustomerApplicantType = {
  __typename?: "rtcs_db_kcca_CustomerApplicantType";
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_CustomerApplicantType" */
export type Rtcs_Db_Kcca_CustomerApplicantType_Aggregate = {
  __typename?: "rtcs_db_kcca_CustomerApplicantType_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_CustomerApplicantType>;
};

/** aggregate fields of "rtcs_db.kcca_CustomerApplicantType" */
export type Rtcs_Db_Kcca_CustomerApplicantType_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_CustomerApplicantType_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_CustomerApplicantType" */
export type Rtcs_Db_Kcca_CustomerApplicantType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_CustomerApplicantType_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_CustomerApplicantType" */
export type Rtcs_Db_Kcca_CustomerApplicantType_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_CustomerApplicantType" */
export type Rtcs_Db_Kcca_CustomerApplicantType_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_CustomerApplicantType_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_CustomerApplicantType_Avg_Fields = {
  __typename?: "rtcs_db_kcca_CustomerApplicantType_avg_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_CustomerApplicantType" */
export type Rtcs_Db_Kcca_CustomerApplicantType_Avg_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_CustomerApplicantType". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_CustomerApplicantType_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_CustomerApplicantType" */
export type Rtcs_Db_Kcca_CustomerApplicantType_Inc_Input = {
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_CustomerApplicantType" */
export type Rtcs_Db_Kcca_CustomerApplicantType_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_CustomerApplicantType_Max_Fields = {
  __typename?: "rtcs_db_kcca_CustomerApplicantType_max_fields";
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_CustomerApplicantType" */
export type Rtcs_Db_Kcca_CustomerApplicantType_Max_Order_By = {
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_CustomerApplicantType_Min_Fields = {
  __typename?: "rtcs_db_kcca_CustomerApplicantType_min_fields";
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_CustomerApplicantType" */
export type Rtcs_Db_Kcca_CustomerApplicantType_Min_Order_By = {
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_CustomerApplicantType" */
export type Rtcs_Db_Kcca_CustomerApplicantType_Mutation_Response = {
  __typename?: "rtcs_db_kcca_CustomerApplicantType_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_CustomerApplicantType>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_CustomerApplicantType" */
export type Rtcs_Db_Kcca_CustomerApplicantType_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_CustomerApplicantType_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_CustomerApplicantType" */
export type Rtcs_Db_Kcca_CustomerApplicantType_Order_By = {
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_CustomerApplicantType" */
export enum Rtcs_Db_Kcca_CustomerApplicantType_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_CustomerApplicantType" */
export type Rtcs_Db_Kcca_CustomerApplicantType_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_CustomerApplicantType_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_CustomerApplicantType_stddev_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_CustomerApplicantType" */
export type Rtcs_Db_Kcca_CustomerApplicantType_Stddev_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_CustomerApplicantType_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_CustomerApplicantType_stddev_pop_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_CustomerApplicantType" */
export type Rtcs_Db_Kcca_CustomerApplicantType_Stddev_Pop_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_CustomerApplicantType_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_CustomerApplicantType_stddev_samp_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_CustomerApplicantType" */
export type Rtcs_Db_Kcca_CustomerApplicantType_Stddev_Samp_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_CustomerApplicantType_Sum_Fields = {
  __typename?: "rtcs_db_kcca_CustomerApplicantType_sum_fields";
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_CustomerApplicantType" */
export type Rtcs_Db_Kcca_CustomerApplicantType_Sum_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_CustomerApplicantType_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_CustomerApplicantType_var_pop_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_CustomerApplicantType" */
export type Rtcs_Db_Kcca_CustomerApplicantType_Var_Pop_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_CustomerApplicantType_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_CustomerApplicantType_var_samp_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_CustomerApplicantType" */
export type Rtcs_Db_Kcca_CustomerApplicantType_Var_Samp_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_CustomerApplicantType_Variance_Fields = {
  __typename?: "rtcs_db_kcca_CustomerApplicantType_variance_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_CustomerApplicantType" */
export type Rtcs_Db_Kcca_CustomerApplicantType_Variance_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate = {
  __typename?: "rtcs_db_kcca_CustomerBusinessAssociate";
  AcquiredName?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  EffectDate?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IdentificationNumber?: Maybe<Scalars["String"]>;
  IdentificationTypeId?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  OfficialDesignation?: Maybe<Scalars["String"]>;
  OtherTitle?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Aggregate = {
  __typename?: "rtcs_db_kcca_CustomerBusinessAssociate_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_CustomerBusinessAssociate>;
};

/** aggregate fields of "rtcs_db.kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_CustomerBusinessAssociate_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_CustomerBusinessAssociate_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_CustomerBusinessAssociate_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Avg_Fields = {
  __typename?: "rtcs_db_kcca_CustomerBusinessAssociate_avg_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Avg_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_CustomerBusinessAssociate". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Bool_Exp = {
  AcquiredName?: Maybe<String_Comparison_Exp>;
  CreatedBy?: Maybe<Float8_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamptz_Comparison_Exp>;
  CustomerId?: Maybe<Bigint_Comparison_Exp>;
  EffectDate?: Maybe<Timestamptz_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  IdentificationNumber?: Maybe<String_Comparison_Exp>;
  IdentificationTypeId?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  ModifiedBy?: Maybe<Float8_Comparison_Exp>;
  ModifiedDate?: Maybe<Timestamptz_Comparison_Exp>;
  MotherMaidenName?: Maybe<String_Comparison_Exp>;
  OfficialDesignation?: Maybe<String_Comparison_Exp>;
  OtherTitle?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  TitleId?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Inc_Input = {
  CreatedBy?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IdentificationTypeId?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  TitleId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Insert_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  EffectDate?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IdentificationNumber?: Maybe<Scalars["String"]>;
  IdentificationTypeId?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  OfficialDesignation?: Maybe<Scalars["String"]>;
  OtherTitle?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Max_Fields = {
  __typename?: "rtcs_db_kcca_CustomerBusinessAssociate_max_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  EffectDate?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IdentificationNumber?: Maybe<Scalars["String"]>;
  IdentificationTypeId?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  OfficialDesignation?: Maybe<Scalars["String"]>;
  OtherTitle?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Max_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Min_Fields = {
  __typename?: "rtcs_db_kcca_CustomerBusinessAssociate_min_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  EffectDate?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IdentificationNumber?: Maybe<Scalars["String"]>;
  IdentificationTypeId?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  OfficialDesignation?: Maybe<Scalars["String"]>;
  OtherTitle?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Min_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Mutation_Response = {
  __typename?: "rtcs_db_kcca_CustomerBusinessAssociate_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_CustomerBusinessAssociate>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_CustomerBusinessAssociate_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  EffectDate?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationNumber?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OfficialDesignation?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_CustomerBusinessAssociate" */
export enum Rtcs_Db_Kcca_CustomerBusinessAssociate_Select_Column {
  /** column name */
  AcquiredName = "AcquiredName",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  EffectDate = "EffectDate",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Id = "Id",
  /** column name */
  IdentificationNumber = "IdentificationNumber",
  /** column name */
  IdentificationTypeId = "IdentificationTypeId",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  MotherMaidenName = "MotherMaidenName",
  /** column name */
  OfficialDesignation = "OfficialDesignation",
  /** column name */
  OtherTitle = "OtherTitle",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  Surname = "Surname",
  /** column name */
  TitleId = "TitleId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Set_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  EffectDate?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IdentificationNumber?: Maybe<Scalars["String"]>;
  IdentificationTypeId?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  OfficialDesignation?: Maybe<Scalars["String"]>;
  OtherTitle?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_CustomerBusinessAssociate_stddev_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Stddev_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_CustomerBusinessAssociate_stddev_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Stddev_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_CustomerBusinessAssociate_stddev_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Stddev_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Sum_Fields = {
  __typename?: "rtcs_db_kcca_CustomerBusinessAssociate_sum_fields";
  CreatedBy?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IdentificationTypeId?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  TitleId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Sum_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_CustomerBusinessAssociate_var_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Var_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_CustomerBusinessAssociate_var_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Var_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Variance_Fields = {
  __typename?: "rtcs_db_kcca_CustomerBusinessAssociate_variance_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IdentificationTypeId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_CustomerBusinessAssociate" */
export type Rtcs_Db_Kcca_CustomerBusinessAssociate_Variance_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationTypeId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_CustomerBusinessType" */
export type Rtcs_Db_Kcca_CustomerBusinessType = {
  __typename?: "rtcs_db_kcca_CustomerBusinessType";
  CustomerApplicantTypeId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_CustomerBusinessType" */
export type Rtcs_Db_Kcca_CustomerBusinessType_Aggregate = {
  __typename?: "rtcs_db_kcca_CustomerBusinessType_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_CustomerBusinessType>;
};

/** aggregate fields of "rtcs_db.kcca_CustomerBusinessType" */
export type Rtcs_Db_Kcca_CustomerBusinessType_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_CustomerBusinessType_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_CustomerBusinessType" */
export type Rtcs_Db_Kcca_CustomerBusinessType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_CustomerBusinessType_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_CustomerBusinessType" */
export type Rtcs_Db_Kcca_CustomerBusinessType_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_CustomerBusinessType" */
export type Rtcs_Db_Kcca_CustomerBusinessType_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_CustomerBusinessType_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_CustomerBusinessType_Avg_Fields = {
  __typename?: "rtcs_db_kcca_CustomerBusinessType_avg_fields";
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_CustomerBusinessType" */
export type Rtcs_Db_Kcca_CustomerBusinessType_Avg_Order_By = {
  CustomerApplicantTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_CustomerBusinessType". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_CustomerBusinessType_Bool_Exp = {
  CustomerApplicantTypeId?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_CustomerBusinessType" */
export type Rtcs_Db_Kcca_CustomerBusinessType_Inc_Input = {
  CustomerApplicantTypeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_CustomerBusinessType" */
export type Rtcs_Db_Kcca_CustomerBusinessType_Insert_Input = {
  CustomerApplicantTypeId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_CustomerBusinessType_Max_Fields = {
  __typename?: "rtcs_db_kcca_CustomerBusinessType_max_fields";
  CustomerApplicantTypeId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_CustomerBusinessType" */
export type Rtcs_Db_Kcca_CustomerBusinessType_Max_Order_By = {
  CustomerApplicantTypeId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_CustomerBusinessType_Min_Fields = {
  __typename?: "rtcs_db_kcca_CustomerBusinessType_min_fields";
  CustomerApplicantTypeId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_CustomerBusinessType" */
export type Rtcs_Db_Kcca_CustomerBusinessType_Min_Order_By = {
  CustomerApplicantTypeId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_CustomerBusinessType" */
export type Rtcs_Db_Kcca_CustomerBusinessType_Mutation_Response = {
  __typename?: "rtcs_db_kcca_CustomerBusinessType_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_CustomerBusinessType>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_CustomerBusinessType" */
export type Rtcs_Db_Kcca_CustomerBusinessType_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_CustomerBusinessType_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_CustomerBusinessType" */
export type Rtcs_Db_Kcca_CustomerBusinessType_Order_By = {
  CustomerApplicantTypeId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_CustomerBusinessType" */
export enum Rtcs_Db_Kcca_CustomerBusinessType_Select_Column {
  /** column name */
  CustomerApplicantTypeId = "CustomerApplicantTypeId",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_CustomerBusinessType" */
export type Rtcs_Db_Kcca_CustomerBusinessType_Set_Input = {
  CustomerApplicantTypeId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_CustomerBusinessType_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_CustomerBusinessType_stddev_fields";
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_CustomerBusinessType" */
export type Rtcs_Db_Kcca_CustomerBusinessType_Stddev_Order_By = {
  CustomerApplicantTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_CustomerBusinessType_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_CustomerBusinessType_stddev_pop_fields";
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_CustomerBusinessType" */
export type Rtcs_Db_Kcca_CustomerBusinessType_Stddev_Pop_Order_By = {
  CustomerApplicantTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_CustomerBusinessType_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_CustomerBusinessType_stddev_samp_fields";
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_CustomerBusinessType" */
export type Rtcs_Db_Kcca_CustomerBusinessType_Stddev_Samp_Order_By = {
  CustomerApplicantTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_CustomerBusinessType_Sum_Fields = {
  __typename?: "rtcs_db_kcca_CustomerBusinessType_sum_fields";
  CustomerApplicantTypeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_CustomerBusinessType" */
export type Rtcs_Db_Kcca_CustomerBusinessType_Sum_Order_By = {
  CustomerApplicantTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_CustomerBusinessType_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_CustomerBusinessType_var_pop_fields";
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_CustomerBusinessType" */
export type Rtcs_Db_Kcca_CustomerBusinessType_Var_Pop_Order_By = {
  CustomerApplicantTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_CustomerBusinessType_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_CustomerBusinessType_var_samp_fields";
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_CustomerBusinessType" */
export type Rtcs_Db_Kcca_CustomerBusinessType_Var_Samp_Order_By = {
  CustomerApplicantTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_CustomerBusinessType_Variance_Fields = {
  __typename?: "rtcs_db_kcca_CustomerBusinessType_variance_fields";
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_CustomerBusinessType" */
export type Rtcs_Db_Kcca_CustomerBusinessType_Variance_Order_By = {
  CustomerApplicantTypeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive = {
  __typename?: "rtcs_db_kcca_CustomerCoinDeactivateReactive";
  AcknowledgementId?: Maybe<Scalars["bigint"]>;
  ApplicationReason?: Maybe<Scalars["String"]>;
  ApplicationType?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DeactivateReactivate?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsForwardingContact?: Maybe<Scalars["bigint"]>;
  IsNewOwner?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamp"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Aggregate = {
  __typename?: "rtcs_db_kcca_CustomerCoinDeactivateReactive_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive>;
};

/** aggregate fields of "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_CustomerCoinDeactivateReactive_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Avg_Fields = {
  __typename?: "rtcs_db_kcca_CustomerCoinDeactivateReactive_avg_fields";
  AcknowledgementId?: Maybe<Scalars["Float"]>;
  ApplicationType?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsForwardingContact?: Maybe<Scalars["Float"]>;
  IsNewOwner?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Avg_Order_By = {
  AcknowledgementId?: Maybe<Order_By>;
  ApplicationType?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsForwardingContact?: Maybe<Order_By>;
  IsNewOwner?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_CustomerCoinDeactivateReactive". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Bool_Exp = {
  AcknowledgementId?: Maybe<Bigint_Comparison_Exp>;
  ApplicationReason?: Maybe<String_Comparison_Exp>;
  ApplicationType?: Maybe<Bigint_Comparison_Exp>;
  CreatedBy?: Maybe<Bigint_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamp_Comparison_Exp>;
  CustomerId?: Maybe<Bigint_Comparison_Exp>;
  DeactivateReactivate?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  IsForcedAction?: Maybe<Float8_Comparison_Exp>;
  IsForwardingContact?: Maybe<Bigint_Comparison_Exp>;
  IsNewOwner?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamp_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  ModifiedBy?: Maybe<Float8_Comparison_Exp>;
  ModifiedDate?: Maybe<Timestamp_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Inc_Input = {
  AcknowledgementId?: Maybe<Scalars["bigint"]>;
  ApplicationType?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsForwardingContact?: Maybe<Scalars["bigint"]>;
  IsNewOwner?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Insert_Input = {
  AcknowledgementId?: Maybe<Scalars["bigint"]>;
  ApplicationReason?: Maybe<Scalars["String"]>;
  ApplicationType?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DeactivateReactivate?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsForwardingContact?: Maybe<Scalars["bigint"]>;
  IsNewOwner?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamp"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Max_Fields = {
  __typename?: "rtcs_db_kcca_CustomerCoinDeactivateReactive_max_fields";
  AcknowledgementId?: Maybe<Scalars["bigint"]>;
  ApplicationReason?: Maybe<Scalars["String"]>;
  ApplicationType?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DeactivateReactivate?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsForwardingContact?: Maybe<Scalars["bigint"]>;
  IsNewOwner?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamp"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Max_Order_By = {
  AcknowledgementId?: Maybe<Order_By>;
  ApplicationReason?: Maybe<Order_By>;
  ApplicationType?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DeactivateReactivate?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsForwardingContact?: Maybe<Order_By>;
  IsNewOwner?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Min_Fields = {
  __typename?: "rtcs_db_kcca_CustomerCoinDeactivateReactive_min_fields";
  AcknowledgementId?: Maybe<Scalars["bigint"]>;
  ApplicationReason?: Maybe<Scalars["String"]>;
  ApplicationType?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DeactivateReactivate?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsForwardingContact?: Maybe<Scalars["bigint"]>;
  IsNewOwner?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamp"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Min_Order_By = {
  AcknowledgementId?: Maybe<Order_By>;
  ApplicationReason?: Maybe<Order_By>;
  ApplicationType?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DeactivateReactivate?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsForwardingContact?: Maybe<Order_By>;
  IsNewOwner?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Mutation_Response = {
  __typename?: "rtcs_db_kcca_CustomerCoinDeactivateReactive_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Order_By = {
  AcknowledgementId?: Maybe<Order_By>;
  ApplicationReason?: Maybe<Order_By>;
  ApplicationType?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DeactivateReactivate?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsForwardingContact?: Maybe<Order_By>;
  IsNewOwner?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
export enum Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Select_Column {
  /** column name */
  AcknowledgementId = "AcknowledgementId",
  /** column name */
  ApplicationReason = "ApplicationReason",
  /** column name */
  ApplicationType = "ApplicationType",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DeactivateReactivate = "DeactivateReactivate",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  IsForcedAction = "IsForcedAction",
  /** column name */
  IsForwardingContact = "IsForwardingContact",
  /** column name */
  IsNewOwner = "IsNewOwner",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Set_Input = {
  AcknowledgementId?: Maybe<Scalars["bigint"]>;
  ApplicationReason?: Maybe<Scalars["String"]>;
  ApplicationType?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DeactivateReactivate?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsForwardingContact?: Maybe<Scalars["bigint"]>;
  IsNewOwner?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamp"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_CustomerCoinDeactivateReactive_stddev_fields";
  AcknowledgementId?: Maybe<Scalars["Float"]>;
  ApplicationType?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsForwardingContact?: Maybe<Scalars["Float"]>;
  IsNewOwner?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Stddev_Order_By = {
  AcknowledgementId?: Maybe<Order_By>;
  ApplicationType?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsForwardingContact?: Maybe<Order_By>;
  IsNewOwner?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_CustomerCoinDeactivateReactive_stddev_pop_fields";
  AcknowledgementId?: Maybe<Scalars["Float"]>;
  ApplicationType?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsForwardingContact?: Maybe<Scalars["Float"]>;
  IsNewOwner?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Stddev_Pop_Order_By = {
  AcknowledgementId?: Maybe<Order_By>;
  ApplicationType?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsForwardingContact?: Maybe<Order_By>;
  IsNewOwner?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_CustomerCoinDeactivateReactive_stddev_samp_fields";
  AcknowledgementId?: Maybe<Scalars["Float"]>;
  ApplicationType?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsForwardingContact?: Maybe<Scalars["Float"]>;
  IsNewOwner?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Stddev_Samp_Order_By = {
  AcknowledgementId?: Maybe<Order_By>;
  ApplicationType?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsForwardingContact?: Maybe<Order_By>;
  IsNewOwner?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Sum_Fields = {
  __typename?: "rtcs_db_kcca_CustomerCoinDeactivateReactive_sum_fields";
  AcknowledgementId?: Maybe<Scalars["bigint"]>;
  ApplicationType?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsForwardingContact?: Maybe<Scalars["bigint"]>;
  IsNewOwner?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Sum_Order_By = {
  AcknowledgementId?: Maybe<Order_By>;
  ApplicationType?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsForwardingContact?: Maybe<Order_By>;
  IsNewOwner?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_CustomerCoinDeactivateReactive_var_pop_fields";
  AcknowledgementId?: Maybe<Scalars["Float"]>;
  ApplicationType?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsForwardingContact?: Maybe<Scalars["Float"]>;
  IsNewOwner?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Var_Pop_Order_By = {
  AcknowledgementId?: Maybe<Order_By>;
  ApplicationType?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsForwardingContact?: Maybe<Order_By>;
  IsNewOwner?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_CustomerCoinDeactivateReactive_var_samp_fields";
  AcknowledgementId?: Maybe<Scalars["Float"]>;
  ApplicationType?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsForwardingContact?: Maybe<Scalars["Float"]>;
  IsNewOwner?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Var_Samp_Order_By = {
  AcknowledgementId?: Maybe<Order_By>;
  ApplicationType?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsForwardingContact?: Maybe<Order_By>;
  IsNewOwner?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Variance_Fields = {
  __typename?: "rtcs_db_kcca_CustomerCoinDeactivateReactive_variance_fields";
  AcknowledgementId?: Maybe<Scalars["Float"]>;
  ApplicationType?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsForwardingContact?: Maybe<Scalars["Float"]>;
  IsNewOwner?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
export type Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Variance_Order_By = {
  AcknowledgementId?: Maybe<Order_By>;
  ApplicationType?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsForwardingContact?: Maybe<Order_By>;
  IsNewOwner?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_CustomerContactPerson" */
export type Rtcs_Db_Kcca_CustomerContactPerson = {
  __typename?: "rtcs_db_kcca_CustomerContactPerson";
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  OtherTitle?: Maybe<Scalars["String"]>;
  RelationshipDesignation?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_CustomerContactPerson" */
export type Rtcs_Db_Kcca_CustomerContactPerson_Aggregate = {
  __typename?: "rtcs_db_kcca_CustomerContactPerson_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_CustomerContactPerson>;
};

/** aggregate fields of "rtcs_db.kcca_CustomerContactPerson" */
export type Rtcs_Db_Kcca_CustomerContactPerson_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_CustomerContactPerson_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_CustomerContactPerson" */
export type Rtcs_Db_Kcca_CustomerContactPerson_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_CustomerContactPerson_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_CustomerContactPerson" */
export type Rtcs_Db_Kcca_CustomerContactPerson_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_CustomerContactPerson" */
export type Rtcs_Db_Kcca_CustomerContactPerson_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_CustomerContactPerson_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_CustomerContactPerson_Avg_Fields = {
  __typename?: "rtcs_db_kcca_CustomerContactPerson_avg_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_CustomerContactPerson" */
export type Rtcs_Db_Kcca_CustomerContactPerson_Avg_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_CustomerContactPerson". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_CustomerContactPerson_Bool_Exp = {
  AcquiredName?: Maybe<String_Comparison_Exp>;
  Coin?: Maybe<Float8_Comparison_Exp>;
  CreatedBy?: Maybe<Float8_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamptz_Comparison_Exp>;
  CustomerId?: Maybe<Bigint_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  Mobile?: Maybe<String_Comparison_Exp>;
  ModifiedBy?: Maybe<String_Comparison_Exp>;
  ModifiedDate?: Maybe<Timestamptz_Comparison_Exp>;
  MotherMaidenName?: Maybe<String_Comparison_Exp>;
  OtherTitle?: Maybe<String_Comparison_Exp>;
  RelationshipDesignation?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Float8_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Telephone?: Maybe<String_Comparison_Exp>;
  TitleId?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_CustomerContactPerson" */
export type Rtcs_Db_Kcca_CustomerContactPerson_Inc_Input = {
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TitleId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_CustomerContactPerson" */
export type Rtcs_Db_Kcca_CustomerContactPerson_Insert_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  OtherTitle?: Maybe<Scalars["String"]>;
  RelationshipDesignation?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_CustomerContactPerson_Max_Fields = {
  __typename?: "rtcs_db_kcca_CustomerContactPerson_max_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  OtherTitle?: Maybe<Scalars["String"]>;
  RelationshipDesignation?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_CustomerContactPerson" */
export type Rtcs_Db_Kcca_CustomerContactPerson_Max_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
  RelationshipDesignation?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_CustomerContactPerson_Min_Fields = {
  __typename?: "rtcs_db_kcca_CustomerContactPerson_min_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  OtherTitle?: Maybe<Scalars["String"]>;
  RelationshipDesignation?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_CustomerContactPerson" */
export type Rtcs_Db_Kcca_CustomerContactPerson_Min_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
  RelationshipDesignation?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_CustomerContactPerson" */
export type Rtcs_Db_Kcca_CustomerContactPerson_Mutation_Response = {
  __typename?: "rtcs_db_kcca_CustomerContactPerson_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_CustomerContactPerson>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_CustomerContactPerson" */
export type Rtcs_Db_Kcca_CustomerContactPerson_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_CustomerContactPerson_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_CustomerContactPerson" */
export type Rtcs_Db_Kcca_CustomerContactPerson_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  OtherTitle?: Maybe<Order_By>;
  RelationshipDesignation?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_CustomerContactPerson" */
export enum Rtcs_Db_Kcca_CustomerContactPerson_Select_Column {
  /** column name */
  AcquiredName = "AcquiredName",
  /** column name */
  Coin = "Coin",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  Email = "Email",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  MotherMaidenName = "MotherMaidenName",
  /** column name */
  OtherTitle = "OtherTitle",
  /** column name */
  RelationshipDesignation = "RelationshipDesignation",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  Surname = "Surname",
  /** column name */
  Telephone = "Telephone",
  /** column name */
  TitleId = "TitleId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_CustomerContactPerson" */
export type Rtcs_Db_Kcca_CustomerContactPerson_Set_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  OtherTitle?: Maybe<Scalars["String"]>;
  RelationshipDesignation?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_CustomerContactPerson_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_CustomerContactPerson_stddev_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_CustomerContactPerson" */
export type Rtcs_Db_Kcca_CustomerContactPerson_Stddev_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_CustomerContactPerson_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_CustomerContactPerson_stddev_pop_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_CustomerContactPerson" */
export type Rtcs_Db_Kcca_CustomerContactPerson_Stddev_Pop_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_CustomerContactPerson_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_CustomerContactPerson_stddev_samp_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_CustomerContactPerson" */
export type Rtcs_Db_Kcca_CustomerContactPerson_Stddev_Samp_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_CustomerContactPerson_Sum_Fields = {
  __typename?: "rtcs_db_kcca_CustomerContactPerson_sum_fields";
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TitleId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_CustomerContactPerson" */
export type Rtcs_Db_Kcca_CustomerContactPerson_Sum_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_CustomerContactPerson_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_CustomerContactPerson_var_pop_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_CustomerContactPerson" */
export type Rtcs_Db_Kcca_CustomerContactPerson_Var_Pop_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_CustomerContactPerson_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_CustomerContactPerson_var_samp_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_CustomerContactPerson" */
export type Rtcs_Db_Kcca_CustomerContactPerson_Var_Samp_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_CustomerContactPerson_Variance_Fields = {
  __typename?: "rtcs_db_kcca_CustomerContactPerson_variance_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_CustomerContactPerson" */
export type Rtcs_Db_Kcca_CustomerContactPerson_Variance_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_CustomerDocumentItem" */
export type Rtcs_Db_Kcca_CustomerDocumentItem = {
  __typename?: "rtcs_db_kcca_CustomerDocumentItem";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  DocumentId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MovementType?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_CustomerDocumentItem" */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Aggregate = {
  __typename?: "rtcs_db_kcca_CustomerDocumentItem_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_CustomerDocumentItem>;
};

/** aggregate fields of "rtcs_db.kcca_CustomerDocumentItem" */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_CustomerDocumentItem_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_CustomerDocumentItem" */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_CustomerDocumentItem_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_CustomerDocumentItem" */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_CustomerDocumentItem" */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_CustomerDocumentItem_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Avg_Fields = {
  __typename?: "rtcs_db_kcca_CustomerDocumentItem_avg_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  DocumentId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MovementType?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_CustomerDocumentItem" */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Avg_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MovementType?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_CustomerDocumentItem". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Bool_Exp = {
  CreatedBy?: Maybe<Bigint_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamptz_Comparison_Exp>;
  DocumentId?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MovementType?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_CustomerDocumentItem" */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Inc_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  DocumentId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MovementType?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_CustomerDocumentItem" */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Insert_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  DocumentId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MovementType?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Max_Fields = {
  __typename?: "rtcs_db_kcca_CustomerDocumentItem_max_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  DocumentId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MovementType?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_CustomerDocumentItem" */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Max_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MovementType?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Min_Fields = {
  __typename?: "rtcs_db_kcca_CustomerDocumentItem_min_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  DocumentId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MovementType?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_CustomerDocumentItem" */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Min_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MovementType?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_CustomerDocumentItem" */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Mutation_Response = {
  __typename?: "rtcs_db_kcca_CustomerDocumentItem_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_CustomerDocumentItem>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_CustomerDocumentItem" */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_CustomerDocumentItem_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_CustomerDocumentItem" */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MovementType?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_CustomerDocumentItem" */
export enum Rtcs_Db_Kcca_CustomerDocumentItem_Select_Column {
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  DocumentId = "DocumentId",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MovementType = "MovementType",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_CustomerDocumentItem" */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Set_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  DocumentId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MovementType?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_CustomerDocumentItem_stddev_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  DocumentId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MovementType?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_CustomerDocumentItem" */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Stddev_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MovementType?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_CustomerDocumentItem_stddev_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  DocumentId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MovementType?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_CustomerDocumentItem" */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Stddev_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MovementType?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_CustomerDocumentItem_stddev_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  DocumentId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MovementType?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_CustomerDocumentItem" */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Stddev_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MovementType?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Sum_Fields = {
  __typename?: "rtcs_db_kcca_CustomerDocumentItem_sum_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  DocumentId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MovementType?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_CustomerDocumentItem" */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Sum_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MovementType?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_CustomerDocumentItem_var_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  DocumentId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MovementType?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_CustomerDocumentItem" */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Var_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MovementType?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_CustomerDocumentItem_var_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  DocumentId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MovementType?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_CustomerDocumentItem" */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Var_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MovementType?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Variance_Fields = {
  __typename?: "rtcs_db_kcca_CustomerDocumentItem_variance_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  DocumentId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MovementType?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_CustomerDocumentItem" */
export type Rtcs_Db_Kcca_CustomerDocumentItem_Variance_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MovementType?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_CustomerGuardian" */
export type Rtcs_Db_Kcca_CustomerGuardian = {
  __typename?: "rtcs_db_kcca_CustomerGuardian";
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_CustomerGuardian" */
export type Rtcs_Db_Kcca_CustomerGuardian_Aggregate = {
  __typename?: "rtcs_db_kcca_CustomerGuardian_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_CustomerGuardian>;
};

/** aggregate fields of "rtcs_db.kcca_CustomerGuardian" */
export type Rtcs_Db_Kcca_CustomerGuardian_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_CustomerGuardian_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_CustomerGuardian" */
export type Rtcs_Db_Kcca_CustomerGuardian_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_CustomerGuardian_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_CustomerGuardian" */
export type Rtcs_Db_Kcca_CustomerGuardian_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_CustomerGuardian" */
export type Rtcs_Db_Kcca_CustomerGuardian_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_CustomerGuardian_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_CustomerGuardian_Avg_Fields = {
  __typename?: "rtcs_db_kcca_CustomerGuardian_avg_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_CustomerGuardian" */
export type Rtcs_Db_Kcca_CustomerGuardian_Avg_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_CustomerGuardian". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_CustomerGuardian_Bool_Exp = {
  AcquiredName?: Maybe<String_Comparison_Exp>;
  Coin?: Maybe<Float8_Comparison_Exp>;
  CreatedBy?: Maybe<Bigint_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamp_Comparison_Exp>;
  CustomerId?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamp_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_CustomerGuardian_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_CustomerGuardian_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_CustomerGuardian" */
export type Rtcs_Db_Kcca_CustomerGuardian_Inc_Input = {
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_CustomerGuardian" */
export type Rtcs_Db_Kcca_CustomerGuardian_Insert_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_CustomerGuardian_Max_Fields = {
  __typename?: "rtcs_db_kcca_CustomerGuardian_max_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_CustomerGuardian" */
export type Rtcs_Db_Kcca_CustomerGuardian_Max_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_CustomerGuardian_Min_Fields = {
  __typename?: "rtcs_db_kcca_CustomerGuardian_min_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_CustomerGuardian" */
export type Rtcs_Db_Kcca_CustomerGuardian_Min_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_CustomerGuardian" */
export type Rtcs_Db_Kcca_CustomerGuardian_Mutation_Response = {
  __typename?: "rtcs_db_kcca_CustomerGuardian_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_CustomerGuardian>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_CustomerGuardian" */
export type Rtcs_Db_Kcca_CustomerGuardian_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_CustomerGuardian_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_CustomerGuardian" */
export type Rtcs_Db_Kcca_CustomerGuardian_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_CustomerGuardian" */
export enum Rtcs_Db_Kcca_CustomerGuardian_Select_Column {
  /** column name */
  AcquiredName = "AcquiredName",
  /** column name */
  Coin = "Coin",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  Surname = "Surname",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_CustomerGuardian" */
export type Rtcs_Db_Kcca_CustomerGuardian_Set_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_CustomerGuardian_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_CustomerGuardian_stddev_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_CustomerGuardian" */
export type Rtcs_Db_Kcca_CustomerGuardian_Stddev_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_CustomerGuardian_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_CustomerGuardian_stddev_pop_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_CustomerGuardian" */
export type Rtcs_Db_Kcca_CustomerGuardian_Stddev_Pop_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_CustomerGuardian_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_CustomerGuardian_stddev_samp_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_CustomerGuardian" */
export type Rtcs_Db_Kcca_CustomerGuardian_Stddev_Samp_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_CustomerGuardian_Sum_Fields = {
  __typename?: "rtcs_db_kcca_CustomerGuardian_sum_fields";
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_CustomerGuardian" */
export type Rtcs_Db_Kcca_CustomerGuardian_Sum_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_CustomerGuardian_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_CustomerGuardian_var_pop_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_CustomerGuardian" */
export type Rtcs_Db_Kcca_CustomerGuardian_Var_Pop_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_CustomerGuardian_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_CustomerGuardian_var_samp_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_CustomerGuardian" */
export type Rtcs_Db_Kcca_CustomerGuardian_Var_Samp_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_CustomerGuardian_Variance_Fields = {
  __typename?: "rtcs_db_kcca_CustomerGuardian_variance_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_CustomerGuardian" */
export type Rtcs_Db_Kcca_CustomerGuardian_Variance_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_CustomerReference" */
export type Rtcs_Db_Kcca_CustomerReference = {
  __typename?: "rtcs_db_kcca_CustomerReference";
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["float8"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  RelationshipDesignation?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_CustomerReference" */
export type Rtcs_Db_Kcca_CustomerReference_Aggregate = {
  __typename?: "rtcs_db_kcca_CustomerReference_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_CustomerReference_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_CustomerReference>;
};

/** aggregate fields of "rtcs_db.kcca_CustomerReference" */
export type Rtcs_Db_Kcca_CustomerReference_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_CustomerReference_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_CustomerReference_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_CustomerReference_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_CustomerReference_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_CustomerReference_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_CustomerReference_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_CustomerReference_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_CustomerReference_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_CustomerReference_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_CustomerReference_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_CustomerReference_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_CustomerReference" */
export type Rtcs_Db_Kcca_CustomerReference_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_CustomerReference_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_CustomerReference" */
export type Rtcs_Db_Kcca_CustomerReference_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_CustomerReference_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_CustomerReference_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_CustomerReference_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_CustomerReference_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_CustomerReference_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_CustomerReference_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_CustomerReference_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_CustomerReference_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_CustomerReference_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_CustomerReference_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_CustomerReference" */
export type Rtcs_Db_Kcca_CustomerReference_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_CustomerReference_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_CustomerReference_Avg_Fields = {
  __typename?: "rtcs_db_kcca_CustomerReference_avg_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_CustomerReference" */
export type Rtcs_Db_Kcca_CustomerReference_Avg_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_CustomerReference". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_CustomerReference_Bool_Exp = {
  AcquiredName?: Maybe<String_Comparison_Exp>;
  Coin?: Maybe<Float8_Comparison_Exp>;
  CreatedBy?: Maybe<Float8_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamptz_Comparison_Exp>;
  CustomerId?: Maybe<Float8_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  Mobile?: Maybe<String_Comparison_Exp>;
  ModifiedBy?: Maybe<String_Comparison_Exp>;
  ModifiedDate?: Maybe<Timestamptz_Comparison_Exp>;
  RelationshipDesignation?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Float8_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Telephone?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_CustomerReference_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_CustomerReference_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_CustomerReference_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_CustomerReference" */
export type Rtcs_Db_Kcca_CustomerReference_Inc_Input = {
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_CustomerReference" */
export type Rtcs_Db_Kcca_CustomerReference_Insert_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["float8"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  RelationshipDesignation?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_CustomerReference_Max_Fields = {
  __typename?: "rtcs_db_kcca_CustomerReference_max_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["float8"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  RelationshipDesignation?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_CustomerReference" */
export type Rtcs_Db_Kcca_CustomerReference_Max_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  RelationshipDesignation?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_CustomerReference_Min_Fields = {
  __typename?: "rtcs_db_kcca_CustomerReference_min_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["float8"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  RelationshipDesignation?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_CustomerReference" */
export type Rtcs_Db_Kcca_CustomerReference_Min_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  RelationshipDesignation?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_CustomerReference" */
export type Rtcs_Db_Kcca_CustomerReference_Mutation_Response = {
  __typename?: "rtcs_db_kcca_CustomerReference_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_CustomerReference>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_CustomerReference" */
export type Rtcs_Db_Kcca_CustomerReference_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_CustomerReference_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_CustomerReference" */
export type Rtcs_Db_Kcca_CustomerReference_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  RelationshipDesignation?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_CustomerReference" */
export enum Rtcs_Db_Kcca_CustomerReference_Select_Column {
  /** column name */
  AcquiredName = "AcquiredName",
  /** column name */
  Coin = "Coin",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  Email = "Email",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  RelationshipDesignation = "RelationshipDesignation",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  Surname = "Surname",
  /** column name */
  Telephone = "Telephone",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_CustomerReference" */
export type Rtcs_Db_Kcca_CustomerReference_Set_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["float8"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  RelationshipDesignation?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_CustomerReference_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_CustomerReference_stddev_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_CustomerReference" */
export type Rtcs_Db_Kcca_CustomerReference_Stddev_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_CustomerReference_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_CustomerReference_stddev_pop_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_CustomerReference" */
export type Rtcs_Db_Kcca_CustomerReference_Stddev_Pop_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_CustomerReference_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_CustomerReference_stddev_samp_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_CustomerReference" */
export type Rtcs_Db_Kcca_CustomerReference_Stddev_Samp_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_CustomerReference_Sum_Fields = {
  __typename?: "rtcs_db_kcca_CustomerReference_sum_fields";
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_CustomerReference" */
export type Rtcs_Db_Kcca_CustomerReference_Sum_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_CustomerReference_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_CustomerReference_var_pop_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_CustomerReference" */
export type Rtcs_Db_Kcca_CustomerReference_Var_Pop_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_CustomerReference_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_CustomerReference_var_samp_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_CustomerReference" */
export type Rtcs_Db_Kcca_CustomerReference_Var_Samp_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_CustomerReference_Variance_Fields = {
  __typename?: "rtcs_db_kcca_CustomerReference_variance_fields";
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_CustomerReference" */
export type Rtcs_Db_Kcca_CustomerReference_Variance_Order_By = {
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Kcca_CustomerRegistrationReason = {
  __typename?: "rtcs_db_kcca_CustomerRegistrationReason";
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RevenueSystemCode?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Aggregate = {
  __typename?: "rtcs_db_kcca_CustomerRegistrationReason_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_CustomerRegistrationReason>;
};

/** aggregate fields of "rtcs_db.kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_CustomerRegistrationReason_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Kcca_CustomerRegistrationReason_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_CustomerRegistrationReason_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Avg_Fields = {
  __typename?: "rtcs_db_kcca_CustomerRegistrationReason_avg_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Avg_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_CustomerRegistrationReason". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Bool_Exp = {
  CreatedBy?: Maybe<Float8_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamptz_Comparison_Exp>;
  CustomerId?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  RevenueSystemCode?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Inc_Input = {
  CreatedBy?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Insert_Input = {
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RevenueSystemCode?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Max_Fields = {
  __typename?: "rtcs_db_kcca_CustomerRegistrationReason_max_fields";
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RevenueSystemCode?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Max_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RevenueSystemCode?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Min_Fields = {
  __typename?: "rtcs_db_kcca_CustomerRegistrationReason_min_fields";
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RevenueSystemCode?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Min_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RevenueSystemCode?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Mutation_Response = {
  __typename?: "rtcs_db_kcca_CustomerRegistrationReason_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_CustomerRegistrationReason>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_CustomerRegistrationReason_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RevenueSystemCode?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_CustomerRegistrationReason" */
export enum Rtcs_Db_Kcca_CustomerRegistrationReason_Select_Column {
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  RevenueSystemCode = "RevenueSystemCode",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Set_Input = {
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RevenueSystemCode?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_CustomerRegistrationReason_stddev_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Stddev_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_CustomerRegistrationReason_stddev_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Stddev_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_CustomerRegistrationReason_stddev_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Stddev_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Sum_Fields = {
  __typename?: "rtcs_db_kcca_CustomerRegistrationReason_sum_fields";
  CreatedBy?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Sum_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_CustomerRegistrationReason_var_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Var_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_CustomerRegistrationReason_var_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Var_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Variance_Fields = {
  __typename?: "rtcs_db_kcca_CustomerRegistrationReason_variance_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_CustomerRegistrationReason" */
export type Rtcs_Db_Kcca_CustomerRegistrationReason_Variance_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Kcca_CustomerRevenueAgent = {
  __typename?: "rtcs_db_kcca_CustomerRevenueAgent";
  AgentLegalName?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Aggregate = {
  __typename?: "rtcs_db_kcca_CustomerRevenueAgent_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_CustomerRevenueAgent>;
};

/** aggregate fields of "rtcs_db.kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_CustomerRevenueAgent_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_CustomerRevenueAgent_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_CustomerRevenueAgent_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Avg_Fields = {
  __typename?: "rtcs_db_kcca_CustomerRevenueAgent_avg_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Avg_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_CustomerRevenueAgent". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Bool_Exp = {
  AgentLegalName?: Maybe<String_Comparison_Exp>;
  CreatedBy?: Maybe<Bigint_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamptz_Comparison_Exp>;
  CustomerId?: Maybe<Bigint_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Mobile?: Maybe<String_Comparison_Exp>;
  ModifiedBy?: Maybe<Float8_Comparison_Exp>;
  ModifiedDate?: Maybe<Timestamptz_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  Telephone?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Inc_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Insert_Input = {
  AgentLegalName?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Max_Fields = {
  __typename?: "rtcs_db_kcca_CustomerRevenueAgent_max_fields";
  AgentLegalName?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Max_Order_By = {
  AgentLegalName?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Min_Fields = {
  __typename?: "rtcs_db_kcca_CustomerRevenueAgent_min_fields";
  AgentLegalName?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Min_Order_By = {
  AgentLegalName?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Mutation_Response = {
  __typename?: "rtcs_db_kcca_CustomerRevenueAgent_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_CustomerRevenueAgent>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_CustomerRevenueAgent_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Order_By = {
  AgentLegalName?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_CustomerRevenueAgent" */
export enum Rtcs_Db_Kcca_CustomerRevenueAgent_Select_Column {
  /** column name */
  AgentLegalName = "AgentLegalName",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  Email = "Email",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  Telephone = "Telephone",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Set_Input = {
  AgentLegalName?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_CustomerRevenueAgent_stddev_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Stddev_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_CustomerRevenueAgent_stddev_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Stddev_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_CustomerRevenueAgent_stddev_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Stddev_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Sum_Fields = {
  __typename?: "rtcs_db_kcca_CustomerRevenueAgent_sum_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Sum_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_CustomerRevenueAgent_var_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Var_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_CustomerRevenueAgent_var_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Var_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Variance_Fields = {
  __typename?: "rtcs_db_kcca_CustomerRevenueAgent_variance_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_CustomerRevenueAgent" */
export type Rtcs_Db_Kcca_CustomerRevenueAgent_Variance_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_CustomerType" */
export type Rtcs_Db_Kcca_CustomerType = {
  __typename?: "rtcs_db_kcca_CustomerType";
  Description?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_CustomerType" */
export type Rtcs_Db_Kcca_CustomerType_Aggregate = {
  __typename?: "rtcs_db_kcca_CustomerType_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_CustomerType_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_CustomerType>;
};

/** aggregate fields of "rtcs_db.kcca_CustomerType" */
export type Rtcs_Db_Kcca_CustomerType_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_CustomerType_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_CustomerType_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_CustomerType_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_CustomerType_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_CustomerType_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_CustomerType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_CustomerType_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_CustomerType_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_CustomerType_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_CustomerType_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_CustomerType_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_CustomerType" */
export type Rtcs_Db_Kcca_CustomerType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_CustomerType_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_CustomerType" */
export type Rtcs_Db_Kcca_CustomerType_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_CustomerType_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_CustomerType_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_CustomerType_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_CustomerType_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_CustomerType_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_CustomerType_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_CustomerType_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_CustomerType_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_CustomerType_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_CustomerType_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_CustomerType" */
export type Rtcs_Db_Kcca_CustomerType_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_CustomerType_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_CustomerType_Avg_Fields = {
  __typename?: "rtcs_db_kcca_CustomerType_avg_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_CustomerType" */
export type Rtcs_Db_Kcca_CustomerType_Avg_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_CustomerType". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_CustomerType_Bool_Exp = {
  Description?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_CustomerType_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_CustomerType_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_CustomerType_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_CustomerType" */
export type Rtcs_Db_Kcca_CustomerType_Inc_Input = {
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_CustomerType" */
export type Rtcs_Db_Kcca_CustomerType_Insert_Input = {
  Description?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_CustomerType_Max_Fields = {
  __typename?: "rtcs_db_kcca_CustomerType_max_fields";
  Description?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_CustomerType" */
export type Rtcs_Db_Kcca_CustomerType_Max_Order_By = {
  Description?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_CustomerType_Min_Fields = {
  __typename?: "rtcs_db_kcca_CustomerType_min_fields";
  Description?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_CustomerType" */
export type Rtcs_Db_Kcca_CustomerType_Min_Order_By = {
  Description?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_CustomerType" */
export type Rtcs_Db_Kcca_CustomerType_Mutation_Response = {
  __typename?: "rtcs_db_kcca_CustomerType_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_CustomerType>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_CustomerType" */
export type Rtcs_Db_Kcca_CustomerType_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_CustomerType_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_CustomerType" */
export type Rtcs_Db_Kcca_CustomerType_Order_By = {
  Description?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_CustomerType" */
export enum Rtcs_Db_Kcca_CustomerType_Select_Column {
  /** column name */
  Description = "Description",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_CustomerType" */
export type Rtcs_Db_Kcca_CustomerType_Set_Input = {
  Description?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_CustomerType_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_CustomerType_stddev_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_CustomerType" */
export type Rtcs_Db_Kcca_CustomerType_Stddev_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_CustomerType_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_CustomerType_stddev_pop_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_CustomerType" */
export type Rtcs_Db_Kcca_CustomerType_Stddev_Pop_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_CustomerType_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_CustomerType_stddev_samp_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_CustomerType" */
export type Rtcs_Db_Kcca_CustomerType_Stddev_Samp_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_CustomerType_Sum_Fields = {
  __typename?: "rtcs_db_kcca_CustomerType_sum_fields";
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_CustomerType" */
export type Rtcs_Db_Kcca_CustomerType_Sum_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_CustomerType_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_CustomerType_var_pop_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_CustomerType" */
export type Rtcs_Db_Kcca_CustomerType_Var_Pop_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_CustomerType_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_CustomerType_var_samp_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_CustomerType" */
export type Rtcs_Db_Kcca_CustomerType_Var_Samp_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_CustomerType_Variance_Fields = {
  __typename?: "rtcs_db_kcca_CustomerType_variance_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_CustomerType" */
export type Rtcs_Db_Kcca_CustomerType_Variance_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregated selection of "rtcs_db.kcca_Customer" */
export type Rtcs_Db_Kcca_Customer_Aggregate = {
  __typename?: "rtcs_db_kcca_Customer_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_Customer_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_Customer>;
};

/** aggregate fields of "rtcs_db.kcca_Customer" */
export type Rtcs_Db_Kcca_Customer_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_Customer_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_Customer_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_Customer_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_Customer_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_Customer_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_Customer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_Customer_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_Customer_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_Customer_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_Customer_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_Customer_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_Customer" */
export type Rtcs_Db_Kcca_Customer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_Customer_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_Customer" */
export type Rtcs_Db_Kcca_Customer_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_Customer_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_Customer_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_Customer_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_Customer_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_Customer_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_Customer_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_Customer_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_Customer_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_Customer_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_Customer_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_Customer" */
export type Rtcs_Db_Kcca_Customer_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_Customer_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_Customer_Avg_Fields = {
  __typename?: "rtcs_db_kcca_Customer_avg_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_Customer" */
export type Rtcs_Db_Kcca_Customer_Avg_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_Customer". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_Customer_Bool_Exp = {
  AcquiredName?: Maybe<String_Comparison_Exp>;
  Birthdate?: Maybe<Timestamp_Comparison_Exp>;
  BoxDistrictId?: Maybe<Float8_Comparison_Exp>;
  BoxNumber?: Maybe<String_Comparison_Exp>;
  Brn?: Maybe<String_Comparison_Exp>;
  BuildingName?: Maybe<String_Comparison_Exp>;
  BusinessName?: Maybe<String_Comparison_Exp>;
  CitizenshipCountryId?: Maybe<Float8_Comparison_Exp>;
  Coin?: Maybe<Float8_Comparison_Exp>;
  CreatedBy?: Maybe<String_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamptz_Comparison_Exp>;
  CustomerApplicantTypeId?: Maybe<Float8_Comparison_Exp>;
  CustomerBusinessTypeId?: Maybe<Float8_Comparison_Exp>;
  CustomerTypeId?: Maybe<Bigint_Comparison_Exp>;
  DateOfIncorporation?: Maybe<Timestamptz_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  EntityLegalName?: Maybe<String_Comparison_Exp>;
  Fax?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  FormerBusinessName?: Maybe<String_Comparison_Exp>;
  GenderId?: Maybe<Float8_Comparison_Exp>;
  HaveAlternatePrimaryContact?: Maybe<Float8_Comparison_Exp>;
  HaveReference?: Maybe<Float8_Comparison_Exp>;
  HaveRevenueAgent?: Maybe<Float8_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  IdentificationDiplomaticId?: Maybe<String_Comparison_Exp>;
  IdentificationDrivingPermit?: Maybe<String_Comparison_Exp>;
  IdentificationEmployeeId?: Maybe<String_Comparison_Exp>;
  IdentificationFinancialCard?: Maybe<String_Comparison_Exp>;
  IdentificationNationalId?: Maybe<String_Comparison_Exp>;
  IdentificationNssfNumber?: Maybe<String_Comparison_Exp>;
  IdentificationPassportNo?: Maybe<String_Comparison_Exp>;
  IdentificationRefugeeId?: Maybe<String_Comparison_Exp>;
  IdentificationVillageId?: Maybe<String_Comparison_Exp>;
  IdentificationVoterId?: Maybe<String_Comparison_Exp>;
  IdentificationWorkId?: Maybe<String_Comparison_Exp>;
  IdentificationWorkPermit?: Maybe<String_Comparison_Exp>;
  IsForcedAction?: Maybe<Float8_Comparison_Exp>;
  IsMinor?: Maybe<Float8_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  Mobile?: Maybe<String_Comparison_Exp>;
  MotherMaidenName?: Maybe<String_Comparison_Exp>;
  Photo?: Maybe<Float8_Comparison_Exp>;
  PhotoContent?: Maybe<Float8_Comparison_Exp>;
  PhotoMime?: Maybe<Float8_Comparison_Exp>;
  PhotoName?: Maybe<Float8_Comparison_Exp>;
  PhotoSize?: Maybe<Float8_Comparison_Exp>;
  PlotNumber?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressBuildingName?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressCountyId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressDistrictId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressParishId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressPlotNumber?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressStreetName?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressSubCountyId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressTradingCenter?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressVillageId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessName?: Maybe<String_Comparison_Exp>;
  ResidentialAddressCountyId?: Maybe<Float8_Comparison_Exp>;
  ResidentialAddressDistrictId?: Maybe<Float8_Comparison_Exp>;
  ResidentialAddressParishId?: Maybe<Float8_Comparison_Exp>;
  ResidentialAddressSubCountyId?: Maybe<Float8_Comparison_Exp>;
  ResidentialAddressVillageId?: Maybe<Float8_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  SecondMobile?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Float8_Comparison_Exp>;
  StreetName?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Telephone?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<String_Comparison_Exp>;
  TitleId?: Maybe<Float8_Comparison_Exp>;
  TradingCenter?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_Customer_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_Customer_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_Customer_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_Customer" */
export type Rtcs_Db_Kcca_Customer_Inc_Input = {
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TitleId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_Customer" */
export type Rtcs_Db_Kcca_Customer_Insert_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["timestamp"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["String"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_Customer_Max_Fields = {
  __typename?: "rtcs_db_kcca_Customer_max_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["timestamp"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["String"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_Customer" */
export type Rtcs_Db_Kcca_Customer_Max_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Birthdate?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  BoxNumber?: Maybe<Order_By>;
  Brn?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FormerBusinessName?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationDiplomaticId?: Maybe<Order_By>;
  IdentificationDrivingPermit?: Maybe<Order_By>;
  IdentificationEmployeeId?: Maybe<Order_By>;
  IdentificationFinancialCard?: Maybe<Order_By>;
  IdentificationNationalId?: Maybe<Order_By>;
  IdentificationNssfNumber?: Maybe<Order_By>;
  IdentificationPassportNo?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationVillageId?: Maybe<Order_By>;
  IdentificationVoterId?: Maybe<Order_By>;
  IdentificationWorkId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressBuildingName?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressStreetName?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressTradingCenter?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetName?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TradingCenter?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_Customer_Min_Fields = {
  __typename?: "rtcs_db_kcca_Customer_min_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["timestamp"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["String"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_Customer" */
export type Rtcs_Db_Kcca_Customer_Min_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Birthdate?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  BoxNumber?: Maybe<Order_By>;
  Brn?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FormerBusinessName?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationDiplomaticId?: Maybe<Order_By>;
  IdentificationDrivingPermit?: Maybe<Order_By>;
  IdentificationEmployeeId?: Maybe<Order_By>;
  IdentificationFinancialCard?: Maybe<Order_By>;
  IdentificationNationalId?: Maybe<Order_By>;
  IdentificationNssfNumber?: Maybe<Order_By>;
  IdentificationPassportNo?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationVillageId?: Maybe<Order_By>;
  IdentificationVoterId?: Maybe<Order_By>;
  IdentificationWorkId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressBuildingName?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressStreetName?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressTradingCenter?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetName?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TradingCenter?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_Customer" */
export type Rtcs_Db_Kcca_Customer_Mutation_Response = {
  __typename?: "rtcs_db_kcca_Customer_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_Customer>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_Customer" */
export type Rtcs_Db_Kcca_Customer_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_Customer_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_Customer" */
export type Rtcs_Db_Kcca_Customer_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Birthdate?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  BoxNumber?: Maybe<Order_By>;
  Brn?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FormerBusinessName?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationDiplomaticId?: Maybe<Order_By>;
  IdentificationDrivingPermit?: Maybe<Order_By>;
  IdentificationEmployeeId?: Maybe<Order_By>;
  IdentificationFinancialCard?: Maybe<Order_By>;
  IdentificationNationalId?: Maybe<Order_By>;
  IdentificationNssfNumber?: Maybe<Order_By>;
  IdentificationPassportNo?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationVillageId?: Maybe<Order_By>;
  IdentificationVoterId?: Maybe<Order_By>;
  IdentificationWorkId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressBuildingName?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressStreetName?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressTradingCenter?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetName?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TradingCenter?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_Customer" */
export enum Rtcs_Db_Kcca_Customer_Select_Column {
  /** column name */
  AcquiredName = "AcquiredName",
  /** column name */
  Birthdate = "Birthdate",
  /** column name */
  BoxDistrictId = "BoxDistrictId",
  /** column name */
  BoxNumber = "BoxNumber",
  /** column name */
  Brn = "Brn",
  /** column name */
  BuildingName = "BuildingName",
  /** column name */
  BusinessName = "BusinessName",
  /** column name */
  CitizenshipCountryId = "CitizenshipCountryId",
  /** column name */
  Coin = "Coin",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerApplicantTypeId = "CustomerApplicantTypeId",
  /** column name */
  CustomerBusinessTypeId = "CustomerBusinessTypeId",
  /** column name */
  CustomerTypeId = "CustomerTypeId",
  /** column name */
  DateOfIncorporation = "DateOfIncorporation",
  /** column name */
  Email = "Email",
  /** column name */
  EntityLegalName = "EntityLegalName",
  /** column name */
  Fax = "Fax",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  FormerBusinessName = "FormerBusinessName",
  /** column name */
  GenderId = "GenderId",
  /** column name */
  HaveAlternatePrimaryContact = "HaveAlternatePrimaryContact",
  /** column name */
  HaveReference = "HaveReference",
  /** column name */
  HaveRevenueAgent = "HaveRevenueAgent",
  /** column name */
  Id = "Id",
  /** column name */
  IdentificationDiplomaticId = "IdentificationDiplomaticId",
  /** column name */
  IdentificationDrivingPermit = "IdentificationDrivingPermit",
  /** column name */
  IdentificationEmployeeId = "IdentificationEmployeeId",
  /** column name */
  IdentificationFinancialCard = "IdentificationFinancialCard",
  /** column name */
  IdentificationNationalId = "IdentificationNationalId",
  /** column name */
  IdentificationNssfNumber = "IdentificationNssfNumber",
  /** column name */
  IdentificationPassportNo = "IdentificationPassportNo",
  /** column name */
  IdentificationRefugeeId = "IdentificationRefugeeId",
  /** column name */
  IdentificationVillageId = "IdentificationVillageId",
  /** column name */
  IdentificationVoterId = "IdentificationVoterId",
  /** column name */
  IdentificationWorkId = "IdentificationWorkId",
  /** column name */
  IdentificationWorkPermit = "IdentificationWorkPermit",
  /** column name */
  IsForcedAction = "IsForcedAction",
  /** column name */
  IsMinor = "IsMinor",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  MotherMaidenName = "MotherMaidenName",
  /** column name */
  Photo = "Photo",
  /** column name */
  PhotoContent = "PhotoContent",
  /** column name */
  PhotoMime = "PhotoMime",
  /** column name */
  PhotoName = "PhotoName",
  /** column name */
  PhotoSize = "PhotoSize",
  /** column name */
  PlotNumber = "PlotNumber",
  /** column name */
  PreviousBusinessAddressBuildingName = "PreviousBusinessAddressBuildingName",
  /** column name */
  PreviousBusinessAddressCountyId = "PreviousBusinessAddressCountyId",
  /** column name */
  PreviousBusinessAddressDistrictId = "PreviousBusinessAddressDistrictId",
  /** column name */
  PreviousBusinessAddressParishId = "PreviousBusinessAddressParishId",
  /** column name */
  PreviousBusinessAddressPlotNumber = "PreviousBusinessAddressPlotNumber",
  /** column name */
  PreviousBusinessAddressStreetName = "PreviousBusinessAddressStreetName",
  /** column name */
  PreviousBusinessAddressSubCountyId = "PreviousBusinessAddressSubCountyId",
  /** column name */
  PreviousBusinessAddressTradingCenter = "PreviousBusinessAddressTradingCenter",
  /** column name */
  PreviousBusinessAddressVillageId = "PreviousBusinessAddressVillageId",
  /** column name */
  PreviousBusinessName = "PreviousBusinessName",
  /** column name */
  ResidentialAddressCountyId = "ResidentialAddressCountyId",
  /** column name */
  ResidentialAddressDistrictId = "ResidentialAddressDistrictId",
  /** column name */
  ResidentialAddressParishId = "ResidentialAddressParishId",
  /** column name */
  ResidentialAddressSubCountyId = "ResidentialAddressSubCountyId",
  /** column name */
  ResidentialAddressVillageId = "ResidentialAddressVillageId",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  SecondMobile = "SecondMobile",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  StreetName = "StreetName",
  /** column name */
  Surname = "Surname",
  /** column name */
  Telephone = "Telephone",
  /** column name */
  Tin = "Tin",
  /** column name */
  TitleId = "TitleId",
  /** column name */
  TradingCenter = "TradingCenter",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_Customer" */
export type Rtcs_Db_Kcca_Customer_Set_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  Birthdate?: Maybe<Scalars["timestamp"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  Brn?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  Fax?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["String"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.kcca_Customer_sid" */
export type Rtcs_Db_Kcca_Customer_Sid = {
  __typename?: "rtcs_db_kcca_Customer_sid";
  AcquiredName?: Maybe<Scalars["String"]>;
  Balance?: Maybe<Scalars["float8"]>;
  Birthdate?: Maybe<Scalars["date"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["date"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  TransactionalStatus?: Maybe<Scalars["float8"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_Customer_sid" */
export type Rtcs_Db_Kcca_Customer_Sid_Aggregate = {
  __typename?: "rtcs_db_kcca_Customer_sid_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_Customer_Sid>;
};

/** aggregate fields of "rtcs_db.kcca_Customer_sid" */
export type Rtcs_Db_Kcca_Customer_Sid_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_Customer_sid_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_Customer_sid" */
export type Rtcs_Db_Kcca_Customer_Sid_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_Customer_Sid_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_Customer_sid" */
export type Rtcs_Db_Kcca_Customer_Sid_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_Customer_sid" */
export type Rtcs_Db_Kcca_Customer_Sid_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_Customer_Sid_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_Customer_Sid_Avg_Fields = {
  __typename?: "rtcs_db_kcca_Customer_sid_avg_fields";
  Balance?: Maybe<Scalars["Float"]>;
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
  TransactionalStatus?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_Customer_sid" */
export type Rtcs_Db_Kcca_Customer_Sid_Avg_Order_By = {
  Balance?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TransactionalStatus?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_Customer_sid". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_Customer_Sid_Bool_Exp = {
  AcquiredName?: Maybe<String_Comparison_Exp>;
  Balance?: Maybe<Float8_Comparison_Exp>;
  Birthdate?: Maybe<Date_Comparison_Exp>;
  BoxDistrictId?: Maybe<Float8_Comparison_Exp>;
  BoxNumber?: Maybe<String_Comparison_Exp>;
  BuildingName?: Maybe<String_Comparison_Exp>;
  BusinessName?: Maybe<String_Comparison_Exp>;
  CitizenshipCountryId?: Maybe<Float8_Comparison_Exp>;
  Coin?: Maybe<Float8_Comparison_Exp>;
  CreatedBy?: Maybe<Float8_Comparison_Exp>;
  CreatedDate?: Maybe<String_Comparison_Exp>;
  CustomerApplicantTypeId?: Maybe<Float8_Comparison_Exp>;
  CustomerBusinessTypeId?: Maybe<Float8_Comparison_Exp>;
  CustomerTypeId?: Maybe<Bigint_Comparison_Exp>;
  DateOfIncorporation?: Maybe<Date_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  EntityLegalName?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  FormerBusinessName?: Maybe<String_Comparison_Exp>;
  GenderId?: Maybe<Float8_Comparison_Exp>;
  HaveAlternatePrimaryContact?: Maybe<Float8_Comparison_Exp>;
  HaveReference?: Maybe<Float8_Comparison_Exp>;
  HaveRevenueAgent?: Maybe<Float8_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  IdentificationDiplomaticId?: Maybe<String_Comparison_Exp>;
  IdentificationDrivingPermit?: Maybe<String_Comparison_Exp>;
  IdentificationEmployeeId?: Maybe<String_Comparison_Exp>;
  IdentificationFinancialCard?: Maybe<String_Comparison_Exp>;
  IdentificationNationalId?: Maybe<String_Comparison_Exp>;
  IdentificationNssfNumber?: Maybe<String_Comparison_Exp>;
  IdentificationPassportNo?: Maybe<String_Comparison_Exp>;
  IdentificationRefugeeId?: Maybe<String_Comparison_Exp>;
  IdentificationVillageId?: Maybe<String_Comparison_Exp>;
  IdentificationVoterId?: Maybe<String_Comparison_Exp>;
  IdentificationWorkId?: Maybe<String_Comparison_Exp>;
  IdentificationWorkPermit?: Maybe<String_Comparison_Exp>;
  IsForcedAction?: Maybe<Float8_Comparison_Exp>;
  IsMinor?: Maybe<Float8_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  Mobile?: Maybe<String_Comparison_Exp>;
  ModifiedBy?: Maybe<Float8_Comparison_Exp>;
  ModifiedDate?: Maybe<String_Comparison_Exp>;
  MotherMaidenName?: Maybe<String_Comparison_Exp>;
  PlotNumber?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressBuildingName?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressCountyId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressDistrictId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressParishId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressPlotNumber?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressStreetName?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressSubCountyId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessAddressTradingCenter?: Maybe<String_Comparison_Exp>;
  PreviousBusinessAddressVillageId?: Maybe<Float8_Comparison_Exp>;
  PreviousBusinessName?: Maybe<Float8_Comparison_Exp>;
  ResidentialAddressCountyId?: Maybe<Float8_Comparison_Exp>;
  ResidentialAddressDistrictId?: Maybe<Float8_Comparison_Exp>;
  ResidentialAddressParishId?: Maybe<Float8_Comparison_Exp>;
  ResidentialAddressSubCountyId?: Maybe<Float8_Comparison_Exp>;
  ResidentialAddressVillageId?: Maybe<Float8_Comparison_Exp>;
  SecondMobile?: Maybe<String_Comparison_Exp>;
  Sid?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Float8_Comparison_Exp>;
  StreetName?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Telephone?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<String_Comparison_Exp>;
  TitleId?: Maybe<Float8_Comparison_Exp>;
  TradingCenter?: Maybe<String_Comparison_Exp>;
  TransactionalStatus?: Maybe<Float8_Comparison_Exp>;
  VisibleLevel?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_Customer_Sid_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_Customer_Sid_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_Customer_sid" */
export type Rtcs_Db_Kcca_Customer_Sid_Inc_Input = {
  Balance?: Maybe<Scalars["float8"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TransactionalStatus?: Maybe<Scalars["float8"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_Customer_sid" */
export type Rtcs_Db_Kcca_Customer_Sid_Insert_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  Balance?: Maybe<Scalars["float8"]>;
  Birthdate?: Maybe<Scalars["date"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["date"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  TransactionalStatus?: Maybe<Scalars["float8"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_Customer_Sid_Max_Fields = {
  __typename?: "rtcs_db_kcca_Customer_sid_max_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  Balance?: Maybe<Scalars["float8"]>;
  Birthdate?: Maybe<Scalars["date"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["date"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  TransactionalStatus?: Maybe<Scalars["float8"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_Customer_sid" */
export type Rtcs_Db_Kcca_Customer_Sid_Max_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Balance?: Maybe<Order_By>;
  Birthdate?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  BoxNumber?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FormerBusinessName?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationDiplomaticId?: Maybe<Order_By>;
  IdentificationDrivingPermit?: Maybe<Order_By>;
  IdentificationEmployeeId?: Maybe<Order_By>;
  IdentificationFinancialCard?: Maybe<Order_By>;
  IdentificationNationalId?: Maybe<Order_By>;
  IdentificationNssfNumber?: Maybe<Order_By>;
  IdentificationPassportNo?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationVillageId?: Maybe<Order_By>;
  IdentificationVoterId?: Maybe<Order_By>;
  IdentificationWorkId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  PlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressBuildingName?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressStreetName?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressTradingCenter?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  Sid?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetName?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TradingCenter?: Maybe<Order_By>;
  TransactionalStatus?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_Customer_Sid_Min_Fields = {
  __typename?: "rtcs_db_kcca_Customer_sid_min_fields";
  AcquiredName?: Maybe<Scalars["String"]>;
  Balance?: Maybe<Scalars["float8"]>;
  Birthdate?: Maybe<Scalars["date"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["date"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  TransactionalStatus?: Maybe<Scalars["float8"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_Customer_sid" */
export type Rtcs_Db_Kcca_Customer_Sid_Min_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Balance?: Maybe<Order_By>;
  Birthdate?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  BoxNumber?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FormerBusinessName?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationDiplomaticId?: Maybe<Order_By>;
  IdentificationDrivingPermit?: Maybe<Order_By>;
  IdentificationEmployeeId?: Maybe<Order_By>;
  IdentificationFinancialCard?: Maybe<Order_By>;
  IdentificationNationalId?: Maybe<Order_By>;
  IdentificationNssfNumber?: Maybe<Order_By>;
  IdentificationPassportNo?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationVillageId?: Maybe<Order_By>;
  IdentificationVoterId?: Maybe<Order_By>;
  IdentificationWorkId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  PlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressBuildingName?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressStreetName?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressTradingCenter?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  Sid?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetName?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TradingCenter?: Maybe<Order_By>;
  TransactionalStatus?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_Customer_sid" */
export type Rtcs_Db_Kcca_Customer_Sid_Mutation_Response = {
  __typename?: "rtcs_db_kcca_Customer_sid_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_Customer_Sid>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_Customer_sid" */
export type Rtcs_Db_Kcca_Customer_Sid_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_Customer_Sid_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_Customer_sid" */
export type Rtcs_Db_Kcca_Customer_Sid_Order_By = {
  AcquiredName?: Maybe<Order_By>;
  Balance?: Maybe<Order_By>;
  Birthdate?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  BoxNumber?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BusinessName?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  FormerBusinessName?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationDiplomaticId?: Maybe<Order_By>;
  IdentificationDrivingPermit?: Maybe<Order_By>;
  IdentificationEmployeeId?: Maybe<Order_By>;
  IdentificationFinancialCard?: Maybe<Order_By>;
  IdentificationNationalId?: Maybe<Order_By>;
  IdentificationNssfNumber?: Maybe<Order_By>;
  IdentificationPassportNo?: Maybe<Order_By>;
  IdentificationRefugeeId?: Maybe<Order_By>;
  IdentificationVillageId?: Maybe<Order_By>;
  IdentificationVoterId?: Maybe<Order_By>;
  IdentificationWorkId?: Maybe<Order_By>;
  IdentificationWorkPermit?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  MotherMaidenName?: Maybe<Order_By>;
  PlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressBuildingName?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressStreetName?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressTradingCenter?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  Sid?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetName?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TradingCenter?: Maybe<Order_By>;
  TransactionalStatus?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_Customer_sid" */
export enum Rtcs_Db_Kcca_Customer_Sid_Select_Column {
  /** column name */
  AcquiredName = "AcquiredName",
  /** column name */
  Balance = "Balance",
  /** column name */
  Birthdate = "Birthdate",
  /** column name */
  BoxDistrictId = "BoxDistrictId",
  /** column name */
  BoxNumber = "BoxNumber",
  /** column name */
  BuildingName = "BuildingName",
  /** column name */
  BusinessName = "BusinessName",
  /** column name */
  CitizenshipCountryId = "CitizenshipCountryId",
  /** column name */
  Coin = "Coin",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerApplicantTypeId = "CustomerApplicantTypeId",
  /** column name */
  CustomerBusinessTypeId = "CustomerBusinessTypeId",
  /** column name */
  CustomerTypeId = "CustomerTypeId",
  /** column name */
  DateOfIncorporation = "DateOfIncorporation",
  /** column name */
  Email = "Email",
  /** column name */
  EntityLegalName = "EntityLegalName",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  FormerBusinessName = "FormerBusinessName",
  /** column name */
  GenderId = "GenderId",
  /** column name */
  HaveAlternatePrimaryContact = "HaveAlternatePrimaryContact",
  /** column name */
  HaveReference = "HaveReference",
  /** column name */
  HaveRevenueAgent = "HaveRevenueAgent",
  /** column name */
  Id = "Id",
  /** column name */
  IdentificationDiplomaticId = "IdentificationDiplomaticId",
  /** column name */
  IdentificationDrivingPermit = "IdentificationDrivingPermit",
  /** column name */
  IdentificationEmployeeId = "IdentificationEmployeeId",
  /** column name */
  IdentificationFinancialCard = "IdentificationFinancialCard",
  /** column name */
  IdentificationNationalId = "IdentificationNationalId",
  /** column name */
  IdentificationNssfNumber = "IdentificationNssfNumber",
  /** column name */
  IdentificationPassportNo = "IdentificationPassportNo",
  /** column name */
  IdentificationRefugeeId = "IdentificationRefugeeId",
  /** column name */
  IdentificationVillageId = "IdentificationVillageId",
  /** column name */
  IdentificationVoterId = "IdentificationVoterId",
  /** column name */
  IdentificationWorkId = "IdentificationWorkId",
  /** column name */
  IdentificationWorkPermit = "IdentificationWorkPermit",
  /** column name */
  IsForcedAction = "IsForcedAction",
  /** column name */
  IsMinor = "IsMinor",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  MotherMaidenName = "MotherMaidenName",
  /** column name */
  PlotNumber = "PlotNumber",
  /** column name */
  PreviousBusinessAddressBuildingName = "PreviousBusinessAddressBuildingName",
  /** column name */
  PreviousBusinessAddressCountyId = "PreviousBusinessAddressCountyId",
  /** column name */
  PreviousBusinessAddressDistrictId = "PreviousBusinessAddressDistrictId",
  /** column name */
  PreviousBusinessAddressParishId = "PreviousBusinessAddressParishId",
  /** column name */
  PreviousBusinessAddressPlotNumber = "PreviousBusinessAddressPlotNumber",
  /** column name */
  PreviousBusinessAddressStreetName = "PreviousBusinessAddressStreetName",
  /** column name */
  PreviousBusinessAddressSubCountyId = "PreviousBusinessAddressSubCountyId",
  /** column name */
  PreviousBusinessAddressTradingCenter = "PreviousBusinessAddressTradingCenter",
  /** column name */
  PreviousBusinessAddressVillageId = "PreviousBusinessAddressVillageId",
  /** column name */
  PreviousBusinessName = "PreviousBusinessName",
  /** column name */
  ResidentialAddressCountyId = "ResidentialAddressCountyId",
  /** column name */
  ResidentialAddressDistrictId = "ResidentialAddressDistrictId",
  /** column name */
  ResidentialAddressParishId = "ResidentialAddressParishId",
  /** column name */
  ResidentialAddressSubCountyId = "ResidentialAddressSubCountyId",
  /** column name */
  ResidentialAddressVillageId = "ResidentialAddressVillageId",
  /** column name */
  SecondMobile = "SecondMobile",
  /** column name */
  Sid = "Sid",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  StreetName = "StreetName",
  /** column name */
  Surname = "Surname",
  /** column name */
  Telephone = "Telephone",
  /** column name */
  Tin = "Tin",
  /** column name */
  TitleId = "TitleId",
  /** column name */
  TradingCenter = "TradingCenter",
  /** column name */
  TransactionalStatus = "TransactionalStatus",
  /** column name */
  VisibleLevel = "VisibleLevel",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_Customer_sid" */
export type Rtcs_Db_Kcca_Customer_Sid_Set_Input = {
  AcquiredName?: Maybe<Scalars["String"]>;
  Balance?: Maybe<Scalars["float8"]>;
  Birthdate?: Maybe<Scalars["date"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  BoxNumber?: Maybe<Scalars["String"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BusinessName?: Maybe<Scalars["String"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  DateOfIncorporation?: Maybe<Scalars["date"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  FormerBusinessName?: Maybe<Scalars["String"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IdentificationDiplomaticId?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationEmployeeId?: Maybe<Scalars["String"]>;
  IdentificationFinancialCard?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfNumber?: Maybe<Scalars["String"]>;
  IdentificationPassportNo?: Maybe<Scalars["String"]>;
  IdentificationRefugeeId?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  IdentificationVoterId?: Maybe<Scalars["String"]>;
  IdentificationWorkId?: Maybe<Scalars["String"]>;
  IdentificationWorkPermit?: Maybe<Scalars["String"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  MotherMaidenName?: Maybe<Scalars["String"]>;
  PlotNumber?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressBuildingName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressStreetName?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressTradingCenter?: Maybe<Scalars["String"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetName?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TradingCenter?: Maybe<Scalars["String"]>;
  TransactionalStatus?: Maybe<Scalars["float8"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_Customer_Sid_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_Customer_sid_stddev_fields";
  Balance?: Maybe<Scalars["Float"]>;
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
  TransactionalStatus?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_Customer_sid" */
export type Rtcs_Db_Kcca_Customer_Sid_Stddev_Order_By = {
  Balance?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TransactionalStatus?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_Customer_Sid_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_Customer_sid_stddev_pop_fields";
  Balance?: Maybe<Scalars["Float"]>;
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
  TransactionalStatus?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_Customer_sid" */
export type Rtcs_Db_Kcca_Customer_Sid_Stddev_Pop_Order_By = {
  Balance?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TransactionalStatus?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_Customer_Sid_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_Customer_sid_stddev_samp_fields";
  Balance?: Maybe<Scalars["Float"]>;
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
  TransactionalStatus?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_Customer_sid" */
export type Rtcs_Db_Kcca_Customer_Sid_Stddev_Samp_Order_By = {
  Balance?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TransactionalStatus?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_Customer_Sid_Sum_Fields = {
  __typename?: "rtcs_db_kcca_Customer_sid_sum_fields";
  Balance?: Maybe<Scalars["float8"]>;
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  PreviousBusinessName?: Maybe<Scalars["float8"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TitleId?: Maybe<Scalars["float8"]>;
  TransactionalStatus?: Maybe<Scalars["float8"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_Customer_sid" */
export type Rtcs_Db_Kcca_Customer_Sid_Sum_Order_By = {
  Balance?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TransactionalStatus?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_Customer_Sid_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_Customer_sid_var_pop_fields";
  Balance?: Maybe<Scalars["Float"]>;
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
  TransactionalStatus?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_Customer_sid" */
export type Rtcs_Db_Kcca_Customer_Sid_Var_Pop_Order_By = {
  Balance?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TransactionalStatus?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_Customer_Sid_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_Customer_sid_var_samp_fields";
  Balance?: Maybe<Scalars["Float"]>;
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
  TransactionalStatus?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_Customer_sid" */
export type Rtcs_Db_Kcca_Customer_Sid_Var_Samp_Order_By = {
  Balance?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TransactionalStatus?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_Customer_Sid_Variance_Fields = {
  __typename?: "rtcs_db_kcca_Customer_sid_variance_fields";
  Balance?: Maybe<Scalars["Float"]>;
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  PreviousBusinessName?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
  TransactionalStatus?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_Customer_sid" */
export type Rtcs_Db_Kcca_Customer_Sid_Variance_Order_By = {
  Balance?: Maybe<Order_By>;
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  PreviousBusinessName?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
  TransactionalStatus?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_Customer_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_Customer_stddev_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_Customer" */
export type Rtcs_Db_Kcca_Customer_Stddev_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_Customer_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_Customer_stddev_pop_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_Customer" */
export type Rtcs_Db_Kcca_Customer_Stddev_Pop_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_Customer_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_Customer_stddev_samp_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_Customer" */
export type Rtcs_Db_Kcca_Customer_Stddev_Samp_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_Customer_Sum_Fields = {
  __typename?: "rtcs_db_kcca_Customer_sum_fields";
  BoxDistrictId?: Maybe<Scalars["float8"]>;
  CitizenshipCountryId?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["float8"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["float8"]>;
  CustomerTypeId?: Maybe<Scalars["bigint"]>;
  GenderId?: Maybe<Scalars["float8"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["float8"]>;
  HaveReference?: Maybe<Scalars["float8"]>;
  HaveRevenueAgent?: Maybe<Scalars["float8"]>;
  IsForcedAction?: Maybe<Scalars["float8"]>;
  IsMinor?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Photo?: Maybe<Scalars["float8"]>;
  PhotoContent?: Maybe<Scalars["float8"]>;
  PhotoMime?: Maybe<Scalars["float8"]>;
  PhotoName?: Maybe<Scalars["float8"]>;
  PhotoSize?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["float8"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["float8"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["float8"]>;
  ResidentialAddressParishId?: Maybe<Scalars["float8"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["float8"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  TitleId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_Customer" */
export type Rtcs_Db_Kcca_Customer_Sum_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_Customer_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_Customer_var_pop_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_Customer" */
export type Rtcs_Db_Kcca_Customer_Var_Pop_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_Customer_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_Customer_var_samp_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_Customer" */
export type Rtcs_Db_Kcca_Customer_Var_Samp_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_Customer_Variance_Fields = {
  __typename?: "rtcs_db_kcca_Customer_variance_fields";
  BoxDistrictId?: Maybe<Scalars["Float"]>;
  CitizenshipCountryId?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  CustomerApplicantTypeId?: Maybe<Scalars["Float"]>;
  CustomerBusinessTypeId?: Maybe<Scalars["Float"]>;
  CustomerTypeId?: Maybe<Scalars["Float"]>;
  GenderId?: Maybe<Scalars["Float"]>;
  HaveAlternatePrimaryContact?: Maybe<Scalars["Float"]>;
  HaveReference?: Maybe<Scalars["Float"]>;
  HaveRevenueAgent?: Maybe<Scalars["Float"]>;
  IsForcedAction?: Maybe<Scalars["Float"]>;
  IsMinor?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Photo?: Maybe<Scalars["Float"]>;
  PhotoContent?: Maybe<Scalars["Float"]>;
  PhotoMime?: Maybe<Scalars["Float"]>;
  PhotoName?: Maybe<Scalars["Float"]>;
  PhotoSize?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressDistrictId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressParishId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressPlotNumber?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressSubCountyId?: Maybe<Scalars["Float"]>;
  PreviousBusinessAddressVillageId?: Maybe<Scalars["Float"]>;
  ResidentialAddressCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressDistrictId?: Maybe<Scalars["Float"]>;
  ResidentialAddressParishId?: Maybe<Scalars["Float"]>;
  ResidentialAddressSubCountyId?: Maybe<Scalars["Float"]>;
  ResidentialAddressVillageId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  TitleId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_Customer" */
export type Rtcs_Db_Kcca_Customer_Variance_Order_By = {
  BoxDistrictId?: Maybe<Order_By>;
  CitizenshipCountryId?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  CustomerApplicantTypeId?: Maybe<Order_By>;
  CustomerBusinessTypeId?: Maybe<Order_By>;
  CustomerTypeId?: Maybe<Order_By>;
  GenderId?: Maybe<Order_By>;
  HaveAlternatePrimaryContact?: Maybe<Order_By>;
  HaveReference?: Maybe<Order_By>;
  HaveRevenueAgent?: Maybe<Order_By>;
  IsForcedAction?: Maybe<Order_By>;
  IsMinor?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  PhotoContent?: Maybe<Order_By>;
  PhotoMime?: Maybe<Order_By>;
  PhotoName?: Maybe<Order_By>;
  PhotoSize?: Maybe<Order_By>;
  PreviousBusinessAddressCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressDistrictId?: Maybe<Order_By>;
  PreviousBusinessAddressParishId?: Maybe<Order_By>;
  PreviousBusinessAddressPlotNumber?: Maybe<Order_By>;
  PreviousBusinessAddressSubCountyId?: Maybe<Order_By>;
  PreviousBusinessAddressVillageId?: Maybe<Order_By>;
  ResidentialAddressCountyId?: Maybe<Order_By>;
  ResidentialAddressDistrictId?: Maybe<Order_By>;
  ResidentialAddressParishId?: Maybe<Order_By>;
  ResidentialAddressSubCountyId?: Maybe<Order_By>;
  ResidentialAddressVillageId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TitleId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_District" */
export type Rtcs_Db_Kcca_District = {
  __typename?: "rtcs_db_kcca_District";
  CountryId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_District" */
export type Rtcs_Db_Kcca_District_Aggregate = {
  __typename?: "rtcs_db_kcca_District_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_District_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_District>;
};

/** aggregate fields of "rtcs_db.kcca_District" */
export type Rtcs_Db_Kcca_District_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_District_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_District_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_District_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_District_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_District_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_District_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_District_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_District_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_District_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_District_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_District_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_District" */
export type Rtcs_Db_Kcca_District_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_District_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_District" */
export type Rtcs_Db_Kcca_District_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_District_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_District_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_District_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_District_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_District_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_District_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_District_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_District_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_District_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_District_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_District" */
export type Rtcs_Db_Kcca_District_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_District_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_District_Avg_Fields = {
  __typename?: "rtcs_db_kcca_District_avg_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_District" */
export type Rtcs_Db_Kcca_District_Avg_Order_By = {
  CountryId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_District". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_District_Bool_Exp = {
  CountryId?: Maybe<Bigint_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamp_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_District_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_District_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_District_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_District" */
export type Rtcs_Db_Kcca_District_Inc_Input = {
  CountryId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_District" */
export type Rtcs_Db_Kcca_District_Insert_Input = {
  CountryId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_District_Max_Fields = {
  __typename?: "rtcs_db_kcca_District_max_fields";
  CountryId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_District" */
export type Rtcs_Db_Kcca_District_Max_Order_By = {
  CountryId?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_District_Min_Fields = {
  __typename?: "rtcs_db_kcca_District_min_fields";
  CountryId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_District" */
export type Rtcs_Db_Kcca_District_Min_Order_By = {
  CountryId?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_District" */
export type Rtcs_Db_Kcca_District_Mutation_Response = {
  __typename?: "rtcs_db_kcca_District_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_District>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_District" */
export type Rtcs_Db_Kcca_District_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_District_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_District" */
export type Rtcs_Db_Kcca_District_Order_By = {
  CountryId?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_District" */
export enum Rtcs_Db_Kcca_District_Select_Column {
  /** column name */
  CountryId = "CountryId",
  /** column name */
  Description = "Description",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_District" */
export type Rtcs_Db_Kcca_District_Set_Input = {
  CountryId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_District_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_District_stddev_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_District" */
export type Rtcs_Db_Kcca_District_Stddev_Order_By = {
  CountryId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_District_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_District_stddev_pop_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_District" */
export type Rtcs_Db_Kcca_District_Stddev_Pop_Order_By = {
  CountryId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_District_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_District_stddev_samp_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_District" */
export type Rtcs_Db_Kcca_District_Stddev_Samp_Order_By = {
  CountryId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_District_Sum_Fields = {
  __typename?: "rtcs_db_kcca_District_sum_fields";
  CountryId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_District" */
export type Rtcs_Db_Kcca_District_Sum_Order_By = {
  CountryId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_District_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_District_var_pop_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_District" */
export type Rtcs_Db_Kcca_District_Var_Pop_Order_By = {
  CountryId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_District_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_District_var_samp_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_District" */
export type Rtcs_Db_Kcca_District_Var_Samp_Order_By = {
  CountryId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_District_Variance_Fields = {
  __typename?: "rtcs_db_kcca_District_variance_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_District" */
export type Rtcs_Db_Kcca_District_Variance_Order_By = {
  CountryId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_Division" */
export type Rtcs_Db_Kcca_Division = {
  __typename?: "rtcs_db_kcca_Division";
  Address?: Maybe<Scalars["String"]>;
  CityId?: Maybe<Scalars["bigint"]>;
  Code?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_Division" */
export type Rtcs_Db_Kcca_Division_Aggregate = {
  __typename?: "rtcs_db_kcca_Division_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_Division_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_Division>;
};

/** aggregate fields of "rtcs_db.kcca_Division" */
export type Rtcs_Db_Kcca_Division_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_Division_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_Division_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_Division_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_Division_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_Division_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_Division_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_Division_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_Division_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_Division_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_Division_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_Division_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_Division" */
export type Rtcs_Db_Kcca_Division_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_Division_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_Division" */
export type Rtcs_Db_Kcca_Division_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_Division_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_Division_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_Division_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_Division_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_Division_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_Division_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_Division_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_Division_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_Division_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_Division_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_Division" */
export type Rtcs_Db_Kcca_Division_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_Division_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_Division_Avg_Fields = {
  __typename?: "rtcs_db_kcca_Division_avg_fields";
  CityId?: Maybe<Scalars["Float"]>;
  Code?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_Division" */
export type Rtcs_Db_Kcca_Division_Avg_Order_By = {
  CityId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_Division". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_Division_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  CityId?: Maybe<Bigint_Comparison_Exp>;
  Code?: Maybe<Bigint_Comparison_Exp>;
  CreatedBy?: Maybe<Bigint_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamptz_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  ModifiedBy?: Maybe<Float8_Comparison_Exp>;
  ModifiedDate?: Maybe<Timestamptz_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  Telephone?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_Division_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_Division_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_Division_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_Division" */
export type Rtcs_Db_Kcca_Division_Inc_Input = {
  CityId?: Maybe<Scalars["bigint"]>;
  Code?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_Division" */
export type Rtcs_Db_Kcca_Division_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  CityId?: Maybe<Scalars["bigint"]>;
  Code?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_Division_Max_Fields = {
  __typename?: "rtcs_db_kcca_Division_max_fields";
  Address?: Maybe<Scalars["String"]>;
  CityId?: Maybe<Scalars["bigint"]>;
  Code?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_Division" */
export type Rtcs_Db_Kcca_Division_Max_Order_By = {
  Address?: Maybe<Order_By>;
  CityId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_Division_Min_Fields = {
  __typename?: "rtcs_db_kcca_Division_min_fields";
  Address?: Maybe<Scalars["String"]>;
  CityId?: Maybe<Scalars["bigint"]>;
  Code?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_Division" */
export type Rtcs_Db_Kcca_Division_Min_Order_By = {
  Address?: Maybe<Order_By>;
  CityId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_Division" */
export type Rtcs_Db_Kcca_Division_Mutation_Response = {
  __typename?: "rtcs_db_kcca_Division_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_Division>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_Division" */
export type Rtcs_Db_Kcca_Division_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_Division_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_Division" */
export type Rtcs_Db_Kcca_Division_Order_By = {
  Address?: Maybe<Order_By>;
  CityId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_Division" */
export enum Rtcs_Db_Kcca_Division_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  CityId = "CityId",
  /** column name */
  Code = "Code",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  Email = "Email",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  Telephone = "Telephone",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_Division" */
export type Rtcs_Db_Kcca_Division_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  CityId?: Maybe<Scalars["bigint"]>;
  Code?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Telephone?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_Division_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_Division_stddev_fields";
  CityId?: Maybe<Scalars["Float"]>;
  Code?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_Division" */
export type Rtcs_Db_Kcca_Division_Stddev_Order_By = {
  CityId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_Division_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_Division_stddev_pop_fields";
  CityId?: Maybe<Scalars["Float"]>;
  Code?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_Division" */
export type Rtcs_Db_Kcca_Division_Stddev_Pop_Order_By = {
  CityId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_Division_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_Division_stddev_samp_fields";
  CityId?: Maybe<Scalars["Float"]>;
  Code?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_Division" */
export type Rtcs_Db_Kcca_Division_Stddev_Samp_Order_By = {
  CityId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_Division_Sum_Fields = {
  __typename?: "rtcs_db_kcca_Division_sum_fields";
  CityId?: Maybe<Scalars["bigint"]>;
  Code?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_Division" */
export type Rtcs_Db_Kcca_Division_Sum_Order_By = {
  CityId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_Division_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_Division_var_pop_fields";
  CityId?: Maybe<Scalars["Float"]>;
  Code?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_Division" */
export type Rtcs_Db_Kcca_Division_Var_Pop_Order_By = {
  CityId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_Division_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_Division_var_samp_fields";
  CityId?: Maybe<Scalars["Float"]>;
  Code?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_Division" */
export type Rtcs_Db_Kcca_Division_Var_Samp_Order_By = {
  CityId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_Division_Variance_Fields = {
  __typename?: "rtcs_db_kcca_Division_variance_fields";
  CityId?: Maybe<Scalars["Float"]>;
  Code?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_Division" */
export type Rtcs_Db_Kcca_Division_Variance_Order_By = {
  CityId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_ImgsData" */
export type Rtcs_Db_Kcca_ImgsData = {
  __typename?: "rtcs_db_kcca_ImgsData";
  CamvId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FolderName?: Maybe<Scalars["String"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_ImgsData" */
export type Rtcs_Db_Kcca_ImgsData_Aggregate = {
  __typename?: "rtcs_db_kcca_ImgsData_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_ImgsData_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_ImgsData>;
};

/** aggregate fields of "rtcs_db.kcca_ImgsData" */
export type Rtcs_Db_Kcca_ImgsData_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_ImgsData_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_ImgsData_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_ImgsData_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_ImgsData_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_ImgsData_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_ImgsData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_ImgsData_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_ImgsData_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_ImgsData_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_ImgsData_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_ImgsData_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_ImgsData" */
export type Rtcs_Db_Kcca_ImgsData_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_ImgsData_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_ImgsData" */
export type Rtcs_Db_Kcca_ImgsData_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_ImgsData_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_ImgsData_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_ImgsData_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_ImgsData_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_ImgsData_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_ImgsData_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_ImgsData_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_ImgsData_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_ImgsData_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_ImgsData_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_ImgsData" */
export type Rtcs_Db_Kcca_ImgsData_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_ImgsData_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_ImgsData_Avg_Fields = {
  __typename?: "rtcs_db_kcca_ImgsData_avg_fields";
  CamvId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_ImgsData" */
export type Rtcs_Db_Kcca_ImgsData_Avg_Order_By = {
  CamvId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_ImgsData". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_ImgsData_Bool_Exp = {
  CamvId?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FolderName?: Maybe<String_Comparison_Exp>;
  ImgPath?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  PropertyNo?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_ImgsData_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_ImgsData_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_ImgsData_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_ImgsData" */
export type Rtcs_Db_Kcca_ImgsData_Inc_Input = {
  CamvId?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_ImgsData" */
export type Rtcs_Db_Kcca_ImgsData_Insert_Input = {
  CamvId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FolderName?: Maybe<Scalars["String"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_ImgsData_Max_Fields = {
  __typename?: "rtcs_db_kcca_ImgsData_max_fields";
  CamvId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FolderName?: Maybe<Scalars["String"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_ImgsData" */
export type Rtcs_Db_Kcca_ImgsData_Max_Order_By = {
  CamvId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FolderName?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_ImgsData_Min_Fields = {
  __typename?: "rtcs_db_kcca_ImgsData_min_fields";
  CamvId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FolderName?: Maybe<Scalars["String"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_ImgsData" */
export type Rtcs_Db_Kcca_ImgsData_Min_Order_By = {
  CamvId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FolderName?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_ImgsData" */
export type Rtcs_Db_Kcca_ImgsData_Mutation_Response = {
  __typename?: "rtcs_db_kcca_ImgsData_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_ImgsData>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_ImgsData" */
export type Rtcs_Db_Kcca_ImgsData_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_ImgsData_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_ImgsData" */
export type Rtcs_Db_Kcca_ImgsData_Order_By = {
  CamvId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FolderName?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_ImgsData" */
export enum Rtcs_Db_Kcca_ImgsData_Select_Column {
  /** column name */
  CamvId = "CamvId",
  /** column name */
  File = "File",
  /** column name */
  FolderName = "FolderName",
  /** column name */
  ImgPath = "ImgPath",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  PropertyNo = "PropertyNo",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_ImgsData" */
export type Rtcs_Db_Kcca_ImgsData_Set_Input = {
  CamvId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FolderName?: Maybe<Scalars["String"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_ImgsData_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_ImgsData_stddev_fields";
  CamvId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_ImgsData" */
export type Rtcs_Db_Kcca_ImgsData_Stddev_Order_By = {
  CamvId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_ImgsData_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_ImgsData_stddev_pop_fields";
  CamvId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_ImgsData" */
export type Rtcs_Db_Kcca_ImgsData_Stddev_Pop_Order_By = {
  CamvId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_ImgsData_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_ImgsData_stddev_samp_fields";
  CamvId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_ImgsData" */
export type Rtcs_Db_Kcca_ImgsData_Stddev_Samp_Order_By = {
  CamvId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_ImgsData_Sum_Fields = {
  __typename?: "rtcs_db_kcca_ImgsData_sum_fields";
  CamvId?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_ImgsData" */
export type Rtcs_Db_Kcca_ImgsData_Sum_Order_By = {
  CamvId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_ImgsData_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_ImgsData_var_pop_fields";
  CamvId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_ImgsData" */
export type Rtcs_Db_Kcca_ImgsData_Var_Pop_Order_By = {
  CamvId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_ImgsData_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_ImgsData_var_samp_fields";
  CamvId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_ImgsData" */
export type Rtcs_Db_Kcca_ImgsData_Var_Samp_Order_By = {
  CamvId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_ImgsData_Variance_Fields = {
  __typename?: "rtcs_db_kcca_ImgsData_variance_fields";
  CamvId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_ImgsData" */
export type Rtcs_Db_Kcca_ImgsData_Variance_Order_By = {
  CamvId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_Parish" */
export type Rtcs_Db_Kcca_Parish = {
  __typename?: "rtcs_db_kcca_Parish";
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_Parish" */
export type Rtcs_Db_Kcca_Parish_Aggregate = {
  __typename?: "rtcs_db_kcca_Parish_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_Parish_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_Parish>;
};

/** aggregate fields of "rtcs_db.kcca_Parish" */
export type Rtcs_Db_Kcca_Parish_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_Parish_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_Parish_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_Parish_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_Parish_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_Parish_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_Parish_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_Parish_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_Parish_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_Parish_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_Parish_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_Parish_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_Parish" */
export type Rtcs_Db_Kcca_Parish_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_Parish_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_Parish" */
export type Rtcs_Db_Kcca_Parish_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_Parish_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_Parish_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_Parish_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_Parish_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_Parish_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_Parish_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_Parish_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_Parish_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_Parish_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_Parish_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_Parish" */
export type Rtcs_Db_Kcca_Parish_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_Parish_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_Parish_Avg_Fields = {
  __typename?: "rtcs_db_kcca_Parish_avg_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_Parish" */
export type Rtcs_Db_Kcca_Parish_Avg_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_Parish". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_Parish_Bool_Exp = {
  CountryId?: Maybe<Float8_Comparison_Exp>;
  CountyId?: Maybe<Bigint_Comparison_Exp>;
  CreatedBy?: Maybe<Float8_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamp_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  DistrictId?: Maybe<Bigint_Comparison_Exp>;
  DivisionId?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamp_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  SubCountyId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_Parish_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_Parish_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_Parish_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_Parish" */
export type Rtcs_Db_Kcca_Parish_Inc_Input = {
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_Parish" */
export type Rtcs_Db_Kcca_Parish_Insert_Input = {
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_Parish_Max_Fields = {
  __typename?: "rtcs_db_kcca_Parish_max_fields";
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_Parish" */
export type Rtcs_Db_Kcca_Parish_Max_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_Parish_Min_Fields = {
  __typename?: "rtcs_db_kcca_Parish_min_fields";
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_Parish" */
export type Rtcs_Db_Kcca_Parish_Min_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_Parish" */
export type Rtcs_Db_Kcca_Parish_Mutation_Response = {
  __typename?: "rtcs_db_kcca_Parish_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_Parish>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_Parish" */
export type Rtcs_Db_Kcca_Parish_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_Parish_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_Parish" */
export type Rtcs_Db_Kcca_Parish_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_Parish" */
export enum Rtcs_Db_Kcca_Parish_Select_Column {
  /** column name */
  CountryId = "CountryId",
  /** column name */
  CountyId = "CountyId",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  Description = "Description",
  /** column name */
  DistrictId = "DistrictId",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  SubCountyId = "SubCountyId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_Parish" */
export type Rtcs_Db_Kcca_Parish_Set_Input = {
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_Parish_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_Parish_stddev_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_Parish" */
export type Rtcs_Db_Kcca_Parish_Stddev_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_Parish_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_Parish_stddev_pop_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_Parish" */
export type Rtcs_Db_Kcca_Parish_Stddev_Pop_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_Parish_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_Parish_stddev_samp_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_Parish" */
export type Rtcs_Db_Kcca_Parish_Stddev_Samp_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_Parish_Sum_Fields = {
  __typename?: "rtcs_db_kcca_Parish_sum_fields";
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_Parish" */
export type Rtcs_Db_Kcca_Parish_Sum_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_Parish_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_Parish_var_pop_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_Parish" */
export type Rtcs_Db_Kcca_Parish_Var_Pop_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_Parish_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_Parish_var_samp_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_Parish" */
export type Rtcs_Db_Kcca_Parish_Var_Samp_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_Parish_Variance_Fields = {
  __typename?: "rtcs_db_kcca_Parish_variance_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_Parish" */
export type Rtcs_Db_Kcca_Parish_Variance_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_PaymentDetails" */
export type Rtcs_Db_Kcca_PaymentDetails = {
  __typename?: "rtcs_db_kcca_PaymentDetails";
  Amount?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  PaymentId?: Maybe<Scalars["String"]>;
  Reason?: Maybe<Scalars["String"]>;
  ReconciliationStatus?: Maybe<Scalars["String"]>;
  ReferenceId?: Maybe<Scalars["bigint"]>;
  ReferenceName?: Maybe<Scalars["String"]>;
  RevenueModelId?: Maybe<Scalars["float8"]>;
  RevenueTypeId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_PaymentDetails" */
export type Rtcs_Db_Kcca_PaymentDetails_Aggregate = {
  __typename?: "rtcs_db_kcca_PaymentDetails_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_PaymentDetails>;
};

/** aggregate fields of "rtcs_db.kcca_PaymentDetails" */
export type Rtcs_Db_Kcca_PaymentDetails_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_PaymentDetails_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_PaymentDetails" */
export type Rtcs_Db_Kcca_PaymentDetails_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_PaymentDetails_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_PaymentDetails" */
export type Rtcs_Db_Kcca_PaymentDetails_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_PaymentDetails" */
export type Rtcs_Db_Kcca_PaymentDetails_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_PaymentDetails_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_PaymentDetails_Avg_Fields = {
  __typename?: "rtcs_db_kcca_PaymentDetails_avg_fields";
  Amount?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ReferenceId?: Maybe<Scalars["Float"]>;
  RevenueModelId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_PaymentDetails" */
export type Rtcs_Db_Kcca_PaymentDetails_Avg_Order_By = {
  Amount?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ReferenceId?: Maybe<Order_By>;
  RevenueModelId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_PaymentDetails". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_PaymentDetails_Bool_Exp = {
  Amount?: Maybe<Bigint_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamptz_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  DivisionId?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FinancialYear?: Maybe<Bigint_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  ModifiedBy?: Maybe<Float8_Comparison_Exp>;
  ModifiedDate?: Maybe<String_Comparison_Exp>;
  PaymentId?: Maybe<String_Comparison_Exp>;
  Reason?: Maybe<String_Comparison_Exp>;
  ReconciliationStatus?: Maybe<String_Comparison_Exp>;
  ReferenceId?: Maybe<Bigint_Comparison_Exp>;
  ReferenceName?: Maybe<String_Comparison_Exp>;
  RevenueModelId?: Maybe<Float8_Comparison_Exp>;
  RevenueTypeId?: Maybe<Float8_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_PaymentDetails_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_PaymentDetails_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_PaymentDetails" */
export type Rtcs_Db_Kcca_PaymentDetails_Inc_Input = {
  Amount?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ReferenceId?: Maybe<Scalars["bigint"]>;
  RevenueModelId?: Maybe<Scalars["float8"]>;
  RevenueTypeId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_PaymentDetails" */
export type Rtcs_Db_Kcca_PaymentDetails_Insert_Input = {
  Amount?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  PaymentId?: Maybe<Scalars["String"]>;
  Reason?: Maybe<Scalars["String"]>;
  ReconciliationStatus?: Maybe<Scalars["String"]>;
  ReferenceId?: Maybe<Scalars["bigint"]>;
  ReferenceName?: Maybe<Scalars["String"]>;
  RevenueModelId?: Maybe<Scalars["float8"]>;
  RevenueTypeId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_PaymentDetails_Max_Fields = {
  __typename?: "rtcs_db_kcca_PaymentDetails_max_fields";
  Amount?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  PaymentId?: Maybe<Scalars["String"]>;
  Reason?: Maybe<Scalars["String"]>;
  ReconciliationStatus?: Maybe<Scalars["String"]>;
  ReferenceId?: Maybe<Scalars["bigint"]>;
  ReferenceName?: Maybe<Scalars["String"]>;
  RevenueModelId?: Maybe<Scalars["float8"]>;
  RevenueTypeId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_PaymentDetails" */
export type Rtcs_Db_Kcca_PaymentDetails_Max_Order_By = {
  Amount?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  PaymentId?: Maybe<Order_By>;
  Reason?: Maybe<Order_By>;
  ReconciliationStatus?: Maybe<Order_By>;
  ReferenceId?: Maybe<Order_By>;
  ReferenceName?: Maybe<Order_By>;
  RevenueModelId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_PaymentDetails_Min_Fields = {
  __typename?: "rtcs_db_kcca_PaymentDetails_min_fields";
  Amount?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  PaymentId?: Maybe<Scalars["String"]>;
  Reason?: Maybe<Scalars["String"]>;
  ReconciliationStatus?: Maybe<Scalars["String"]>;
  ReferenceId?: Maybe<Scalars["bigint"]>;
  ReferenceName?: Maybe<Scalars["String"]>;
  RevenueModelId?: Maybe<Scalars["float8"]>;
  RevenueTypeId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_PaymentDetails" */
export type Rtcs_Db_Kcca_PaymentDetails_Min_Order_By = {
  Amount?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  PaymentId?: Maybe<Order_By>;
  Reason?: Maybe<Order_By>;
  ReconciliationStatus?: Maybe<Order_By>;
  ReferenceId?: Maybe<Order_By>;
  ReferenceName?: Maybe<Order_By>;
  RevenueModelId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_PaymentDetails" */
export type Rtcs_Db_Kcca_PaymentDetails_Mutation_Response = {
  __typename?: "rtcs_db_kcca_PaymentDetails_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_PaymentDetails>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_PaymentDetails" */
export type Rtcs_Db_Kcca_PaymentDetails_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_PaymentDetails_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_PaymentDetails" */
export type Rtcs_Db_Kcca_PaymentDetails_Order_By = {
  Amount?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  PaymentId?: Maybe<Order_By>;
  Reason?: Maybe<Order_By>;
  ReconciliationStatus?: Maybe<Order_By>;
  ReferenceId?: Maybe<Order_By>;
  ReferenceName?: Maybe<Order_By>;
  RevenueModelId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_PaymentDetails" */
export enum Rtcs_Db_Kcca_PaymentDetails_Select_Column {
  /** column name */
  Amount = "Amount",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  File = "File",
  /** column name */
  FinancialYear = "FinancialYear",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  PaymentId = "PaymentId",
  /** column name */
  Reason = "Reason",
  /** column name */
  ReconciliationStatus = "ReconciliationStatus",
  /** column name */
  ReferenceId = "ReferenceId",
  /** column name */
  ReferenceName = "ReferenceName",
  /** column name */
  RevenueModelId = "RevenueModelId",
  /** column name */
  RevenueTypeId = "RevenueTypeId",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_PaymentDetails" */
export type Rtcs_Db_Kcca_PaymentDetails_Set_Input = {
  Amount?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  PaymentId?: Maybe<Scalars["String"]>;
  Reason?: Maybe<Scalars["String"]>;
  ReconciliationStatus?: Maybe<Scalars["String"]>;
  ReferenceId?: Maybe<Scalars["bigint"]>;
  ReferenceName?: Maybe<Scalars["String"]>;
  RevenueModelId?: Maybe<Scalars["float8"]>;
  RevenueTypeId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_PaymentDetails_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_PaymentDetails_stddev_fields";
  Amount?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ReferenceId?: Maybe<Scalars["Float"]>;
  RevenueModelId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_PaymentDetails" */
export type Rtcs_Db_Kcca_PaymentDetails_Stddev_Order_By = {
  Amount?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ReferenceId?: Maybe<Order_By>;
  RevenueModelId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_PaymentDetails_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_PaymentDetails_stddev_pop_fields";
  Amount?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ReferenceId?: Maybe<Scalars["Float"]>;
  RevenueModelId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_PaymentDetails" */
export type Rtcs_Db_Kcca_PaymentDetails_Stddev_Pop_Order_By = {
  Amount?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ReferenceId?: Maybe<Order_By>;
  RevenueModelId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_PaymentDetails_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_PaymentDetails_stddev_samp_fields";
  Amount?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ReferenceId?: Maybe<Scalars["Float"]>;
  RevenueModelId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_PaymentDetails" */
export type Rtcs_Db_Kcca_PaymentDetails_Stddev_Samp_Order_By = {
  Amount?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ReferenceId?: Maybe<Order_By>;
  RevenueModelId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_PaymentDetails_Sum_Fields = {
  __typename?: "rtcs_db_kcca_PaymentDetails_sum_fields";
  Amount?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ReferenceId?: Maybe<Scalars["bigint"]>;
  RevenueModelId?: Maybe<Scalars["float8"]>;
  RevenueTypeId?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_PaymentDetails" */
export type Rtcs_Db_Kcca_PaymentDetails_Sum_Order_By = {
  Amount?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ReferenceId?: Maybe<Order_By>;
  RevenueModelId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_PaymentDetails_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_PaymentDetails_var_pop_fields";
  Amount?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ReferenceId?: Maybe<Scalars["Float"]>;
  RevenueModelId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_PaymentDetails" */
export type Rtcs_Db_Kcca_PaymentDetails_Var_Pop_Order_By = {
  Amount?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ReferenceId?: Maybe<Order_By>;
  RevenueModelId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_PaymentDetails_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_PaymentDetails_var_samp_fields";
  Amount?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ReferenceId?: Maybe<Scalars["Float"]>;
  RevenueModelId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_PaymentDetails" */
export type Rtcs_Db_Kcca_PaymentDetails_Var_Samp_Order_By = {
  Amount?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ReferenceId?: Maybe<Order_By>;
  RevenueModelId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_PaymentDetails_Variance_Fields = {
  __typename?: "rtcs_db_kcca_PaymentDetails_variance_fields";
  Amount?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ReferenceId?: Maybe<Scalars["Float"]>;
  RevenueModelId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_PaymentDetails" */
export type Rtcs_Db_Kcca_PaymentDetails_Variance_Order_By = {
  Amount?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ReferenceId?: Maybe<Order_By>;
  RevenueModelId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_Payments" */
export type Rtcs_Db_Kcca_Payments = {
  __typename?: "rtcs_db_kcca_Payments";
  Amount?: Maybe<Scalars["bigint"]>;
  ApiId?: Maybe<Scalars["float8"]>;
  BankBranchCode?: Maybe<Scalars["float8"]>;
  CheckNumber?: Maybe<Scalars["float8"]>;
  CollectionAgentId?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerBankAccountName?: Maybe<Scalars["String"]>;
  CustomerBankAccountNumber?: Maybe<Scalars["String"]>;
  CustomerBankName?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DatePaid?: Maybe<Scalars["timestamptz"]>;
  ExpiryDate?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  ObjectionBy?: Maybe<Scalars["float8"]>;
  ObjectionReason?: Maybe<Scalars["String"]>;
  ObjectionStatus?: Maybe<Scalars["float8"]>;
  PaymentModeId?: Maybe<Scalars["bigint"]>;
  PaymentTypeId?: Maybe<Scalars["bigint"]>;
  Prn?: Maybe<Scalars["String"]>;
  ReconciliationStatus?: Maybe<Scalars["String"]>;
  RegistrationStatus?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  TransactionId?: Maybe<Scalars["String"]>;
  TransactionStatus?: Maybe<Scalars["String"]>;
  UraResponseId?: Maybe<Scalars["float8"]>;
  ValueDate?: Maybe<Scalars["timestamptz"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_Payments" */
export type Rtcs_Db_Kcca_Payments_Aggregate = {
  __typename?: "rtcs_db_kcca_Payments_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_Payments_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_Payments>;
};

/** aggregate fields of "rtcs_db.kcca_Payments" */
export type Rtcs_Db_Kcca_Payments_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_Payments_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_Payments_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_Payments_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_Payments_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_Payments_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_Payments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_Payments_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_Payments_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_Payments_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_Payments_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_Payments_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_Payments" */
export type Rtcs_Db_Kcca_Payments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_Payments_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_Payments" */
export type Rtcs_Db_Kcca_Payments_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_Payments_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_Payments_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_Payments_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_Payments_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_Payments_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_Payments_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_Payments_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_Payments_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_Payments_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_Payments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_Payments" */
export type Rtcs_Db_Kcca_Payments_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_Payments_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_Payments_Avg_Fields = {
  __typename?: "rtcs_db_kcca_Payments_avg_fields";
  Amount?: Maybe<Scalars["Float"]>;
  ApiId?: Maybe<Scalars["Float"]>;
  BankBranchCode?: Maybe<Scalars["Float"]>;
  CheckNumber?: Maybe<Scalars["Float"]>;
  CollectionAgentId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ObjectionBy?: Maybe<Scalars["Float"]>;
  ObjectionStatus?: Maybe<Scalars["Float"]>;
  PaymentModeId?: Maybe<Scalars["Float"]>;
  PaymentTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  UraResponseId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_Payments" */
export type Rtcs_Db_Kcca_Payments_Avg_Order_By = {
  Amount?: Maybe<Order_By>;
  ApiId?: Maybe<Order_By>;
  BankBranchCode?: Maybe<Order_By>;
  CheckNumber?: Maybe<Order_By>;
  CollectionAgentId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ObjectionBy?: Maybe<Order_By>;
  ObjectionStatus?: Maybe<Order_By>;
  PaymentModeId?: Maybe<Order_By>;
  PaymentTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UraResponseId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_Payments". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_Payments_Bool_Exp = {
  Amount?: Maybe<Bigint_Comparison_Exp>;
  ApiId?: Maybe<Float8_Comparison_Exp>;
  BankBranchCode?: Maybe<Float8_Comparison_Exp>;
  CheckNumber?: Maybe<Float8_Comparison_Exp>;
  CollectionAgentId?: Maybe<Bigint_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamptz_Comparison_Exp>;
  CustomerBankAccountName?: Maybe<String_Comparison_Exp>;
  CustomerBankAccountNumber?: Maybe<String_Comparison_Exp>;
  CustomerBankName?: Maybe<String_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  DatePaid?: Maybe<Timestamptz_Comparison_Exp>;
  ExpiryDate?: Maybe<Timestamptz_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FinancialYear?: Maybe<Bigint_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  ModifiedDate?: Maybe<Timestamptz_Comparison_Exp>;
  ObjectionBy?: Maybe<Float8_Comparison_Exp>;
  ObjectionReason?: Maybe<String_Comparison_Exp>;
  ObjectionStatus?: Maybe<Float8_Comparison_Exp>;
  PaymentModeId?: Maybe<Bigint_Comparison_Exp>;
  PaymentTypeId?: Maybe<Bigint_Comparison_Exp>;
  Prn?: Maybe<String_Comparison_Exp>;
  ReconciliationStatus?: Maybe<String_Comparison_Exp>;
  RegistrationStatus?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  TransactionId?: Maybe<String_Comparison_Exp>;
  TransactionStatus?: Maybe<String_Comparison_Exp>;
  UraResponseId?: Maybe<Float8_Comparison_Exp>;
  ValueDate?: Maybe<Timestamptz_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_Payments_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_Payments_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_Payments_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_Payments" */
export type Rtcs_Db_Kcca_Payments_Inc_Input = {
  Amount?: Maybe<Scalars["bigint"]>;
  ApiId?: Maybe<Scalars["float8"]>;
  BankBranchCode?: Maybe<Scalars["float8"]>;
  CheckNumber?: Maybe<Scalars["float8"]>;
  CollectionAgentId?: Maybe<Scalars["bigint"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ObjectionBy?: Maybe<Scalars["float8"]>;
  ObjectionStatus?: Maybe<Scalars["float8"]>;
  PaymentModeId?: Maybe<Scalars["bigint"]>;
  PaymentTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  UraResponseId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_Payments" */
export type Rtcs_Db_Kcca_Payments_Insert_Input = {
  Amount?: Maybe<Scalars["bigint"]>;
  ApiId?: Maybe<Scalars["float8"]>;
  BankBranchCode?: Maybe<Scalars["float8"]>;
  CheckNumber?: Maybe<Scalars["float8"]>;
  CollectionAgentId?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerBankAccountName?: Maybe<Scalars["String"]>;
  CustomerBankAccountNumber?: Maybe<Scalars["String"]>;
  CustomerBankName?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DatePaid?: Maybe<Scalars["timestamptz"]>;
  ExpiryDate?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  ObjectionBy?: Maybe<Scalars["float8"]>;
  ObjectionReason?: Maybe<Scalars["String"]>;
  ObjectionStatus?: Maybe<Scalars["float8"]>;
  PaymentModeId?: Maybe<Scalars["bigint"]>;
  PaymentTypeId?: Maybe<Scalars["bigint"]>;
  Prn?: Maybe<Scalars["String"]>;
  ReconciliationStatus?: Maybe<Scalars["String"]>;
  RegistrationStatus?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  TransactionId?: Maybe<Scalars["String"]>;
  TransactionStatus?: Maybe<Scalars["String"]>;
  UraResponseId?: Maybe<Scalars["float8"]>;
  ValueDate?: Maybe<Scalars["timestamptz"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_Payments_Max_Fields = {
  __typename?: "rtcs_db_kcca_Payments_max_fields";
  Amount?: Maybe<Scalars["bigint"]>;
  ApiId?: Maybe<Scalars["float8"]>;
  BankBranchCode?: Maybe<Scalars["float8"]>;
  CheckNumber?: Maybe<Scalars["float8"]>;
  CollectionAgentId?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerBankAccountName?: Maybe<Scalars["String"]>;
  CustomerBankAccountNumber?: Maybe<Scalars["String"]>;
  CustomerBankName?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DatePaid?: Maybe<Scalars["timestamptz"]>;
  ExpiryDate?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  ObjectionBy?: Maybe<Scalars["float8"]>;
  ObjectionReason?: Maybe<Scalars["String"]>;
  ObjectionStatus?: Maybe<Scalars["float8"]>;
  PaymentModeId?: Maybe<Scalars["bigint"]>;
  PaymentTypeId?: Maybe<Scalars["bigint"]>;
  Prn?: Maybe<Scalars["String"]>;
  ReconciliationStatus?: Maybe<Scalars["String"]>;
  RegistrationStatus?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  TransactionId?: Maybe<Scalars["String"]>;
  TransactionStatus?: Maybe<Scalars["String"]>;
  UraResponseId?: Maybe<Scalars["float8"]>;
  ValueDate?: Maybe<Scalars["timestamptz"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_Payments" */
export type Rtcs_Db_Kcca_Payments_Max_Order_By = {
  Amount?: Maybe<Order_By>;
  ApiId?: Maybe<Order_By>;
  BankBranchCode?: Maybe<Order_By>;
  CheckNumber?: Maybe<Order_By>;
  CollectionAgentId?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerBankAccountName?: Maybe<Order_By>;
  CustomerBankAccountNumber?: Maybe<Order_By>;
  CustomerBankName?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DatePaid?: Maybe<Order_By>;
  ExpiryDate?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  ObjectionBy?: Maybe<Order_By>;
  ObjectionReason?: Maybe<Order_By>;
  ObjectionStatus?: Maybe<Order_By>;
  PaymentModeId?: Maybe<Order_By>;
  PaymentTypeId?: Maybe<Order_By>;
  Prn?: Maybe<Order_By>;
  ReconciliationStatus?: Maybe<Order_By>;
  RegistrationStatus?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TransactionId?: Maybe<Order_By>;
  TransactionStatus?: Maybe<Order_By>;
  UraResponseId?: Maybe<Order_By>;
  ValueDate?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_Payments_Min_Fields = {
  __typename?: "rtcs_db_kcca_Payments_min_fields";
  Amount?: Maybe<Scalars["bigint"]>;
  ApiId?: Maybe<Scalars["float8"]>;
  BankBranchCode?: Maybe<Scalars["float8"]>;
  CheckNumber?: Maybe<Scalars["float8"]>;
  CollectionAgentId?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerBankAccountName?: Maybe<Scalars["String"]>;
  CustomerBankAccountNumber?: Maybe<Scalars["String"]>;
  CustomerBankName?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DatePaid?: Maybe<Scalars["timestamptz"]>;
  ExpiryDate?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  ObjectionBy?: Maybe<Scalars["float8"]>;
  ObjectionReason?: Maybe<Scalars["String"]>;
  ObjectionStatus?: Maybe<Scalars["float8"]>;
  PaymentModeId?: Maybe<Scalars["bigint"]>;
  PaymentTypeId?: Maybe<Scalars["bigint"]>;
  Prn?: Maybe<Scalars["String"]>;
  ReconciliationStatus?: Maybe<Scalars["String"]>;
  RegistrationStatus?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  TransactionId?: Maybe<Scalars["String"]>;
  TransactionStatus?: Maybe<Scalars["String"]>;
  UraResponseId?: Maybe<Scalars["float8"]>;
  ValueDate?: Maybe<Scalars["timestamptz"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_Payments" */
export type Rtcs_Db_Kcca_Payments_Min_Order_By = {
  Amount?: Maybe<Order_By>;
  ApiId?: Maybe<Order_By>;
  BankBranchCode?: Maybe<Order_By>;
  CheckNumber?: Maybe<Order_By>;
  CollectionAgentId?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerBankAccountName?: Maybe<Order_By>;
  CustomerBankAccountNumber?: Maybe<Order_By>;
  CustomerBankName?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DatePaid?: Maybe<Order_By>;
  ExpiryDate?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  ObjectionBy?: Maybe<Order_By>;
  ObjectionReason?: Maybe<Order_By>;
  ObjectionStatus?: Maybe<Order_By>;
  PaymentModeId?: Maybe<Order_By>;
  PaymentTypeId?: Maybe<Order_By>;
  Prn?: Maybe<Order_By>;
  ReconciliationStatus?: Maybe<Order_By>;
  RegistrationStatus?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TransactionId?: Maybe<Order_By>;
  TransactionStatus?: Maybe<Order_By>;
  UraResponseId?: Maybe<Order_By>;
  ValueDate?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_Payments" */
export type Rtcs_Db_Kcca_Payments_Mutation_Response = {
  __typename?: "rtcs_db_kcca_Payments_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_Payments>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_Payments" */
export type Rtcs_Db_Kcca_Payments_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_Payments_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_Payments" */
export type Rtcs_Db_Kcca_Payments_Order_By = {
  Amount?: Maybe<Order_By>;
  ApiId?: Maybe<Order_By>;
  BankBranchCode?: Maybe<Order_By>;
  CheckNumber?: Maybe<Order_By>;
  CollectionAgentId?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CustomerBankAccountName?: Maybe<Order_By>;
  CustomerBankAccountNumber?: Maybe<Order_By>;
  CustomerBankName?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DatePaid?: Maybe<Order_By>;
  ExpiryDate?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  ObjectionBy?: Maybe<Order_By>;
  ObjectionReason?: Maybe<Order_By>;
  ObjectionStatus?: Maybe<Order_By>;
  PaymentModeId?: Maybe<Order_By>;
  PaymentTypeId?: Maybe<Order_By>;
  Prn?: Maybe<Order_By>;
  ReconciliationStatus?: Maybe<Order_By>;
  RegistrationStatus?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  TransactionId?: Maybe<Order_By>;
  TransactionStatus?: Maybe<Order_By>;
  UraResponseId?: Maybe<Order_By>;
  ValueDate?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_Payments" */
export enum Rtcs_Db_Kcca_Payments_Select_Column {
  /** column name */
  Amount = "Amount",
  /** column name */
  ApiId = "ApiId",
  /** column name */
  BankBranchCode = "BankBranchCode",
  /** column name */
  CheckNumber = "CheckNumber",
  /** column name */
  CollectionAgentId = "CollectionAgentId",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CustomerBankAccountName = "CustomerBankAccountName",
  /** column name */
  CustomerBankAccountNumber = "CustomerBankAccountNumber",
  /** column name */
  CustomerBankName = "CustomerBankName",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DatePaid = "DatePaid",
  /** column name */
  ExpiryDate = "ExpiryDate",
  /** column name */
  File = "File",
  /** column name */
  FinancialYear = "FinancialYear",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  ObjectionBy = "ObjectionBy",
  /** column name */
  ObjectionReason = "ObjectionReason",
  /** column name */
  ObjectionStatus = "ObjectionStatus",
  /** column name */
  PaymentModeId = "PaymentModeId",
  /** column name */
  PaymentTypeId = "PaymentTypeId",
  /** column name */
  Prn = "Prn",
  /** column name */
  ReconciliationStatus = "ReconciliationStatus",
  /** column name */
  RegistrationStatus = "RegistrationStatus",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  TransactionId = "TransactionId",
  /** column name */
  TransactionStatus = "TransactionStatus",
  /** column name */
  UraResponseId = "UraResponseId",
  /** column name */
  ValueDate = "ValueDate",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_Payments" */
export type Rtcs_Db_Kcca_Payments_Set_Input = {
  Amount?: Maybe<Scalars["bigint"]>;
  ApiId?: Maybe<Scalars["float8"]>;
  BankBranchCode?: Maybe<Scalars["float8"]>;
  CheckNumber?: Maybe<Scalars["float8"]>;
  CollectionAgentId?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CustomerBankAccountName?: Maybe<Scalars["String"]>;
  CustomerBankAccountNumber?: Maybe<Scalars["String"]>;
  CustomerBankName?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DatePaid?: Maybe<Scalars["timestamptz"]>;
  ExpiryDate?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  ObjectionBy?: Maybe<Scalars["float8"]>;
  ObjectionReason?: Maybe<Scalars["String"]>;
  ObjectionStatus?: Maybe<Scalars["float8"]>;
  PaymentModeId?: Maybe<Scalars["bigint"]>;
  PaymentTypeId?: Maybe<Scalars["bigint"]>;
  Prn?: Maybe<Scalars["String"]>;
  ReconciliationStatus?: Maybe<Scalars["String"]>;
  RegistrationStatus?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  TransactionId?: Maybe<Scalars["String"]>;
  TransactionStatus?: Maybe<Scalars["String"]>;
  UraResponseId?: Maybe<Scalars["float8"]>;
  ValueDate?: Maybe<Scalars["timestamptz"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_Payments_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_Payments_stddev_fields";
  Amount?: Maybe<Scalars["Float"]>;
  ApiId?: Maybe<Scalars["Float"]>;
  BankBranchCode?: Maybe<Scalars["Float"]>;
  CheckNumber?: Maybe<Scalars["Float"]>;
  CollectionAgentId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ObjectionBy?: Maybe<Scalars["Float"]>;
  ObjectionStatus?: Maybe<Scalars["Float"]>;
  PaymentModeId?: Maybe<Scalars["Float"]>;
  PaymentTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  UraResponseId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_Payments" */
export type Rtcs_Db_Kcca_Payments_Stddev_Order_By = {
  Amount?: Maybe<Order_By>;
  ApiId?: Maybe<Order_By>;
  BankBranchCode?: Maybe<Order_By>;
  CheckNumber?: Maybe<Order_By>;
  CollectionAgentId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ObjectionBy?: Maybe<Order_By>;
  ObjectionStatus?: Maybe<Order_By>;
  PaymentModeId?: Maybe<Order_By>;
  PaymentTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UraResponseId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_Payments_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_Payments_stddev_pop_fields";
  Amount?: Maybe<Scalars["Float"]>;
  ApiId?: Maybe<Scalars["Float"]>;
  BankBranchCode?: Maybe<Scalars["Float"]>;
  CheckNumber?: Maybe<Scalars["Float"]>;
  CollectionAgentId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ObjectionBy?: Maybe<Scalars["Float"]>;
  ObjectionStatus?: Maybe<Scalars["Float"]>;
  PaymentModeId?: Maybe<Scalars["Float"]>;
  PaymentTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  UraResponseId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_Payments" */
export type Rtcs_Db_Kcca_Payments_Stddev_Pop_Order_By = {
  Amount?: Maybe<Order_By>;
  ApiId?: Maybe<Order_By>;
  BankBranchCode?: Maybe<Order_By>;
  CheckNumber?: Maybe<Order_By>;
  CollectionAgentId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ObjectionBy?: Maybe<Order_By>;
  ObjectionStatus?: Maybe<Order_By>;
  PaymentModeId?: Maybe<Order_By>;
  PaymentTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UraResponseId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_Payments_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_Payments_stddev_samp_fields";
  Amount?: Maybe<Scalars["Float"]>;
  ApiId?: Maybe<Scalars["Float"]>;
  BankBranchCode?: Maybe<Scalars["Float"]>;
  CheckNumber?: Maybe<Scalars["Float"]>;
  CollectionAgentId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ObjectionBy?: Maybe<Scalars["Float"]>;
  ObjectionStatus?: Maybe<Scalars["Float"]>;
  PaymentModeId?: Maybe<Scalars["Float"]>;
  PaymentTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  UraResponseId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_Payments" */
export type Rtcs_Db_Kcca_Payments_Stddev_Samp_Order_By = {
  Amount?: Maybe<Order_By>;
  ApiId?: Maybe<Order_By>;
  BankBranchCode?: Maybe<Order_By>;
  CheckNumber?: Maybe<Order_By>;
  CollectionAgentId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ObjectionBy?: Maybe<Order_By>;
  ObjectionStatus?: Maybe<Order_By>;
  PaymentModeId?: Maybe<Order_By>;
  PaymentTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UraResponseId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_Payments_Sum_Fields = {
  __typename?: "rtcs_db_kcca_Payments_sum_fields";
  Amount?: Maybe<Scalars["bigint"]>;
  ApiId?: Maybe<Scalars["float8"]>;
  BankBranchCode?: Maybe<Scalars["float8"]>;
  CheckNumber?: Maybe<Scalars["float8"]>;
  CollectionAgentId?: Maybe<Scalars["bigint"]>;
  FinancialYear?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ObjectionBy?: Maybe<Scalars["float8"]>;
  ObjectionStatus?: Maybe<Scalars["float8"]>;
  PaymentModeId?: Maybe<Scalars["bigint"]>;
  PaymentTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  UraResponseId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_Payments" */
export type Rtcs_Db_Kcca_Payments_Sum_Order_By = {
  Amount?: Maybe<Order_By>;
  ApiId?: Maybe<Order_By>;
  BankBranchCode?: Maybe<Order_By>;
  CheckNumber?: Maybe<Order_By>;
  CollectionAgentId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ObjectionBy?: Maybe<Order_By>;
  ObjectionStatus?: Maybe<Order_By>;
  PaymentModeId?: Maybe<Order_By>;
  PaymentTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UraResponseId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_Payments_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_Payments_var_pop_fields";
  Amount?: Maybe<Scalars["Float"]>;
  ApiId?: Maybe<Scalars["Float"]>;
  BankBranchCode?: Maybe<Scalars["Float"]>;
  CheckNumber?: Maybe<Scalars["Float"]>;
  CollectionAgentId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ObjectionBy?: Maybe<Scalars["Float"]>;
  ObjectionStatus?: Maybe<Scalars["Float"]>;
  PaymentModeId?: Maybe<Scalars["Float"]>;
  PaymentTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  UraResponseId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_Payments" */
export type Rtcs_Db_Kcca_Payments_Var_Pop_Order_By = {
  Amount?: Maybe<Order_By>;
  ApiId?: Maybe<Order_By>;
  BankBranchCode?: Maybe<Order_By>;
  CheckNumber?: Maybe<Order_By>;
  CollectionAgentId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ObjectionBy?: Maybe<Order_By>;
  ObjectionStatus?: Maybe<Order_By>;
  PaymentModeId?: Maybe<Order_By>;
  PaymentTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UraResponseId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_Payments_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_Payments_var_samp_fields";
  Amount?: Maybe<Scalars["Float"]>;
  ApiId?: Maybe<Scalars["Float"]>;
  BankBranchCode?: Maybe<Scalars["Float"]>;
  CheckNumber?: Maybe<Scalars["Float"]>;
  CollectionAgentId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ObjectionBy?: Maybe<Scalars["Float"]>;
  ObjectionStatus?: Maybe<Scalars["Float"]>;
  PaymentModeId?: Maybe<Scalars["Float"]>;
  PaymentTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  UraResponseId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_Payments" */
export type Rtcs_Db_Kcca_Payments_Var_Samp_Order_By = {
  Amount?: Maybe<Order_By>;
  ApiId?: Maybe<Order_By>;
  BankBranchCode?: Maybe<Order_By>;
  CheckNumber?: Maybe<Order_By>;
  CollectionAgentId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ObjectionBy?: Maybe<Order_By>;
  ObjectionStatus?: Maybe<Order_By>;
  PaymentModeId?: Maybe<Order_By>;
  PaymentTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UraResponseId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_Payments_Variance_Fields = {
  __typename?: "rtcs_db_kcca_Payments_variance_fields";
  Amount?: Maybe<Scalars["Float"]>;
  ApiId?: Maybe<Scalars["Float"]>;
  BankBranchCode?: Maybe<Scalars["Float"]>;
  CheckNumber?: Maybe<Scalars["Float"]>;
  CollectionAgentId?: Maybe<Scalars["Float"]>;
  FinancialYear?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ObjectionBy?: Maybe<Scalars["Float"]>;
  ObjectionStatus?: Maybe<Scalars["Float"]>;
  PaymentModeId?: Maybe<Scalars["Float"]>;
  PaymentTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  UraResponseId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_Payments" */
export type Rtcs_Db_Kcca_Payments_Variance_Order_By = {
  Amount?: Maybe<Order_By>;
  ApiId?: Maybe<Order_By>;
  BankBranchCode?: Maybe<Order_By>;
  CheckNumber?: Maybe<Order_By>;
  CollectionAgentId?: Maybe<Order_By>;
  FinancialYear?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ObjectionBy?: Maybe<Order_By>;
  ObjectionStatus?: Maybe<Order_By>;
  PaymentModeId?: Maybe<Order_By>;
  PaymentTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UraResponseId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_Property" */
export type Rtcs_Db_Kcca_Property = {
  __typename?: "rtcs_db_kcca_Property";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.kcca_PropertyFloors" */
export type Rtcs_Db_Kcca_PropertyFloors = {
  __typename?: "rtcs_db_kcca_PropertyFloors";
  File?: Maybe<Scalars["String"]>;
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_PropertyFloors" */
export type Rtcs_Db_Kcca_PropertyFloors_Aggregate = {
  __typename?: "rtcs_db_kcca_PropertyFloors_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_PropertyFloors>;
};

/** aggregate fields of "rtcs_db.kcca_PropertyFloors" */
export type Rtcs_Db_Kcca_PropertyFloors_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_PropertyFloors_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_PropertyFloors" */
export type Rtcs_Db_Kcca_PropertyFloors_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_PropertyFloors_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_PropertyFloors" */
export type Rtcs_Db_Kcca_PropertyFloors_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_PropertyFloors" */
export type Rtcs_Db_Kcca_PropertyFloors_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_PropertyFloors_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_PropertyFloors_Avg_Fields = {
  __typename?: "rtcs_db_kcca_PropertyFloors_avg_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_PropertyFloors" */
export type Rtcs_Db_Kcca_PropertyFloors_Avg_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_PropertyFloors". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_PropertyFloors_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  GradeId?: Maybe<Bigint_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_PropertyFloors_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_PropertyFloors_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_PropertyFloors" */
export type Rtcs_Db_Kcca_PropertyFloors_Inc_Input = {
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_PropertyFloors" */
export type Rtcs_Db_Kcca_PropertyFloors_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_PropertyFloors_Max_Fields = {
  __typename?: "rtcs_db_kcca_PropertyFloors_max_fields";
  File?: Maybe<Scalars["String"]>;
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_PropertyFloors" */
export type Rtcs_Db_Kcca_PropertyFloors_Max_Order_By = {
  File?: Maybe<Order_By>;
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_PropertyFloors_Min_Fields = {
  __typename?: "rtcs_db_kcca_PropertyFloors_min_fields";
  File?: Maybe<Scalars["String"]>;
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_PropertyFloors" */
export type Rtcs_Db_Kcca_PropertyFloors_Min_Order_By = {
  File?: Maybe<Order_By>;
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_PropertyFloors" */
export type Rtcs_Db_Kcca_PropertyFloors_Mutation_Response = {
  __typename?: "rtcs_db_kcca_PropertyFloors_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_PropertyFloors>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_PropertyFloors" */
export type Rtcs_Db_Kcca_PropertyFloors_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_PropertyFloors_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_PropertyFloors" */
export type Rtcs_Db_Kcca_PropertyFloors_Order_By = {
  File?: Maybe<Order_By>;
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_PropertyFloors" */
export enum Rtcs_Db_Kcca_PropertyFloors_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  GradeId = "GradeId",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_PropertyFloors" */
export type Rtcs_Db_Kcca_PropertyFloors_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_PropertyFloors_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_PropertyFloors_stddev_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_PropertyFloors" */
export type Rtcs_Db_Kcca_PropertyFloors_Stddev_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_PropertyFloors_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_PropertyFloors_stddev_pop_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_PropertyFloors" */
export type Rtcs_Db_Kcca_PropertyFloors_Stddev_Pop_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_PropertyFloors_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_PropertyFloors_stddev_samp_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_PropertyFloors" */
export type Rtcs_Db_Kcca_PropertyFloors_Stddev_Samp_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_PropertyFloors_Sum_Fields = {
  __typename?: "rtcs_db_kcca_PropertyFloors_sum_fields";
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_PropertyFloors" */
export type Rtcs_Db_Kcca_PropertyFloors_Sum_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_PropertyFloors_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_PropertyFloors_var_pop_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_PropertyFloors" */
export type Rtcs_Db_Kcca_PropertyFloors_Var_Pop_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_PropertyFloors_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_PropertyFloors_var_samp_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_PropertyFloors" */
export type Rtcs_Db_Kcca_PropertyFloors_Var_Samp_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_PropertyFloors_Variance_Fields = {
  __typename?: "rtcs_db_kcca_PropertyFloors_variance_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_PropertyFloors" */
export type Rtcs_Db_Kcca_PropertyFloors_Variance_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms = {
  __typename?: "rtcs_db_kcca_PropertyNumberBedrooms";
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Aggregate = {
  __typename?: "rtcs_db_kcca_PropertyNumberBedrooms_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_PropertyNumberBedrooms>;
};

/** aggregate fields of "rtcs_db.kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_PropertyNumberBedrooms_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_PropertyNumberBedrooms_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_PropertyNumberBedrooms_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Avg_Fields = {
  __typename?: "rtcs_db_kcca_PropertyNumberBedrooms_avg_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Avg_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_PropertyNumberBedrooms". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamp_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Inc_Input = {
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Max_Fields = {
  __typename?: "rtcs_db_kcca_PropertyNumberBedrooms_max_fields";
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Max_Order_By = {
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Min_Fields = {
  __typename?: "rtcs_db_kcca_PropertyNumberBedrooms_min_fields";
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Min_Order_By = {
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Mutation_Response = {
  __typename?: "rtcs_db_kcca_PropertyNumberBedrooms_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_PropertyNumberBedrooms>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_PropertyNumberBedrooms_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Order_By = {
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_PropertyNumberBedrooms" */
export enum Rtcs_Db_Kcca_PropertyNumberBedrooms_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_PropertyNumberBedrooms_stddev_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Stddev_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_PropertyNumberBedrooms_stddev_pop_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Stddev_Pop_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_PropertyNumberBedrooms_stddev_samp_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Stddev_Samp_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Sum_Fields = {
  __typename?: "rtcs_db_kcca_PropertyNumberBedrooms_sum_fields";
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Sum_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_PropertyNumberBedrooms_var_pop_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Var_Pop_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_PropertyNumberBedrooms_var_samp_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Var_Samp_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Variance_Fields = {
  __typename?: "rtcs_db_kcca_PropertyNumberBedrooms_variance_fields";
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_PropertyNumberBedrooms" */
export type Rtcs_Db_Kcca_PropertyNumberBedrooms_Variance_Order_By = {
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_PropertyRentedStatus" */
export type Rtcs_Db_Kcca_PropertyRentedStatus = {
  __typename?: "rtcs_db_kcca_PropertyRentedStatus";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["date"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_PropertyRentedStatus" */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Aggregate = {
  __typename?: "rtcs_db_kcca_PropertyRentedStatus_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_PropertyRentedStatus>;
};

/** aggregate fields of "rtcs_db.kcca_PropertyRentedStatus" */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_PropertyRentedStatus_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_PropertyRentedStatus" */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_PropertyRentedStatus_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_PropertyRentedStatus" */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_PropertyRentedStatus" */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_PropertyRentedStatus_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Avg_Fields = {
  __typename?: "rtcs_db_kcca_PropertyRentedStatus_avg_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_PropertyRentedStatus" */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Avg_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_PropertyRentedStatus". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Bool_Exp = {
  CreatedBy?: Maybe<Bigint_Comparison_Exp>;
  CreatedDate?: Maybe<Date_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamp_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_PropertyRentedStatus" */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Inc_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_PropertyRentedStatus" */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Insert_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["date"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Max_Fields = {
  __typename?: "rtcs_db_kcca_PropertyRentedStatus_max_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["date"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_PropertyRentedStatus" */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Max_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Min_Fields = {
  __typename?: "rtcs_db_kcca_PropertyRentedStatus_min_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["date"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_PropertyRentedStatus" */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Min_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_PropertyRentedStatus" */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Mutation_Response = {
  __typename?: "rtcs_db_kcca_PropertyRentedStatus_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_PropertyRentedStatus>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_PropertyRentedStatus" */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_PropertyRentedStatus_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_PropertyRentedStatus" */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_PropertyRentedStatus" */
export enum Rtcs_Db_Kcca_PropertyRentedStatus_Select_Column {
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_PropertyRentedStatus" */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Set_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  CreatedDate?: Maybe<Scalars["date"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_PropertyRentedStatus_stddev_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_PropertyRentedStatus" */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Stddev_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_PropertyRentedStatus_stddev_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_PropertyRentedStatus" */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Stddev_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_PropertyRentedStatus_stddev_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_PropertyRentedStatus" */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Stddev_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Sum_Fields = {
  __typename?: "rtcs_db_kcca_PropertyRentedStatus_sum_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_PropertyRentedStatus" */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Sum_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_PropertyRentedStatus_var_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_PropertyRentedStatus" */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Var_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_PropertyRentedStatus_var_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_PropertyRentedStatus" */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Var_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Variance_Fields = {
  __typename?: "rtcs_db_kcca_PropertyRentedStatus_variance_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_PropertyRentedStatus" */
export type Rtcs_Db_Kcca_PropertyRentedStatus_Variance_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_PropertySecurity" */
export type Rtcs_Db_Kcca_PropertySecurity = {
  __typename?: "rtcs_db_kcca_PropertySecurity";
  File?: Maybe<Scalars["String"]>;
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_PropertySecurity" */
export type Rtcs_Db_Kcca_PropertySecurity_Aggregate = {
  __typename?: "rtcs_db_kcca_PropertySecurity_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_PropertySecurity>;
};

/** aggregate fields of "rtcs_db.kcca_PropertySecurity" */
export type Rtcs_Db_Kcca_PropertySecurity_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_PropertySecurity_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_PropertySecurity" */
export type Rtcs_Db_Kcca_PropertySecurity_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_PropertySecurity_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_PropertySecurity" */
export type Rtcs_Db_Kcca_PropertySecurity_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_PropertySecurity" */
export type Rtcs_Db_Kcca_PropertySecurity_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_PropertySecurity_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_PropertySecurity_Avg_Fields = {
  __typename?: "rtcs_db_kcca_PropertySecurity_avg_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_PropertySecurity" */
export type Rtcs_Db_Kcca_PropertySecurity_Avg_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_PropertySecurity". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_PropertySecurity_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  GradeId?: Maybe<Bigint_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_PropertySecurity_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_PropertySecurity_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_PropertySecurity" */
export type Rtcs_Db_Kcca_PropertySecurity_Inc_Input = {
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_PropertySecurity" */
export type Rtcs_Db_Kcca_PropertySecurity_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_PropertySecurity_Max_Fields = {
  __typename?: "rtcs_db_kcca_PropertySecurity_max_fields";
  File?: Maybe<Scalars["String"]>;
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_PropertySecurity" */
export type Rtcs_Db_Kcca_PropertySecurity_Max_Order_By = {
  File?: Maybe<Order_By>;
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_PropertySecurity_Min_Fields = {
  __typename?: "rtcs_db_kcca_PropertySecurity_min_fields";
  File?: Maybe<Scalars["String"]>;
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_PropertySecurity" */
export type Rtcs_Db_Kcca_PropertySecurity_Min_Order_By = {
  File?: Maybe<Order_By>;
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_PropertySecurity" */
export type Rtcs_Db_Kcca_PropertySecurity_Mutation_Response = {
  __typename?: "rtcs_db_kcca_PropertySecurity_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_PropertySecurity>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_PropertySecurity" */
export type Rtcs_Db_Kcca_PropertySecurity_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_PropertySecurity_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_PropertySecurity" */
export type Rtcs_Db_Kcca_PropertySecurity_Order_By = {
  File?: Maybe<Order_By>;
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_PropertySecurity" */
export enum Rtcs_Db_Kcca_PropertySecurity_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  GradeId = "GradeId",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_PropertySecurity" */
export type Rtcs_Db_Kcca_PropertySecurity_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_PropertySecurity_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_PropertySecurity_stddev_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_PropertySecurity" */
export type Rtcs_Db_Kcca_PropertySecurity_Stddev_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_PropertySecurity_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_PropertySecurity_stddev_pop_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_PropertySecurity" */
export type Rtcs_Db_Kcca_PropertySecurity_Stddev_Pop_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_PropertySecurity_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_PropertySecurity_stddev_samp_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_PropertySecurity" */
export type Rtcs_Db_Kcca_PropertySecurity_Stddev_Samp_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_PropertySecurity_Sum_Fields = {
  __typename?: "rtcs_db_kcca_PropertySecurity_sum_fields";
  GradeId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_PropertySecurity" */
export type Rtcs_Db_Kcca_PropertySecurity_Sum_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_PropertySecurity_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_PropertySecurity_var_pop_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_PropertySecurity" */
export type Rtcs_Db_Kcca_PropertySecurity_Var_Pop_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_PropertySecurity_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_PropertySecurity_var_samp_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_PropertySecurity" */
export type Rtcs_Db_Kcca_PropertySecurity_Var_Samp_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_PropertySecurity_Variance_Fields = {
  __typename?: "rtcs_db_kcca_PropertySecurity_variance_fields";
  GradeId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_PropertySecurity" */
export type Rtcs_Db_Kcca_PropertySecurity_Variance_Order_By = {
  GradeId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_PropertySubType" */
export type Rtcs_Db_Kcca_PropertySubType = {
  __typename?: "rtcs_db_kcca_PropertySubType";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_PropertySubType" */
export type Rtcs_Db_Kcca_PropertySubType_Aggregate = {
  __typename?: "rtcs_db_kcca_PropertySubType_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_PropertySubType_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_PropertySubType>;
};

/** aggregate fields of "rtcs_db.kcca_PropertySubType" */
export type Rtcs_Db_Kcca_PropertySubType_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_PropertySubType_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_PropertySubType_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_PropertySubType_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_PropertySubType_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_PropertySubType_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_PropertySubType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_PropertySubType_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_PropertySubType_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_PropertySubType_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_PropertySubType_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_PropertySubType_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_PropertySubType" */
export type Rtcs_Db_Kcca_PropertySubType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_PropertySubType_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_PropertySubType" */
export type Rtcs_Db_Kcca_PropertySubType_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_PropertySubType_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_PropertySubType_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_PropertySubType_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_PropertySubType_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_PropertySubType_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_PropertySubType_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_PropertySubType_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_PropertySubType_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_PropertySubType_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_PropertySubType_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_PropertySubType" */
export type Rtcs_Db_Kcca_PropertySubType_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_PropertySubType_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_PropertySubType_Avg_Fields = {
  __typename?: "rtcs_db_kcca_PropertySubType_avg_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_PropertySubType" */
export type Rtcs_Db_Kcca_PropertySubType_Avg_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_PropertySubType". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_PropertySubType_Bool_Exp = {
  CreatedBy?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_PropertySubType_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_PropertySubType_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_PropertySubType_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_PropertySubType" */
export type Rtcs_Db_Kcca_PropertySubType_Inc_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_PropertySubType" */
export type Rtcs_Db_Kcca_PropertySubType_Insert_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_PropertySubType_Max_Fields = {
  __typename?: "rtcs_db_kcca_PropertySubType_max_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_PropertySubType" */
export type Rtcs_Db_Kcca_PropertySubType_Max_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_PropertySubType_Min_Fields = {
  __typename?: "rtcs_db_kcca_PropertySubType_min_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_PropertySubType" */
export type Rtcs_Db_Kcca_PropertySubType_Min_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_PropertySubType" */
export type Rtcs_Db_Kcca_PropertySubType_Mutation_Response = {
  __typename?: "rtcs_db_kcca_PropertySubType_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_PropertySubType>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_PropertySubType" */
export type Rtcs_Db_Kcca_PropertySubType_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_PropertySubType_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_PropertySubType" */
export type Rtcs_Db_Kcca_PropertySubType_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_PropertySubType" */
export enum Rtcs_Db_Kcca_PropertySubType_Select_Column {
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_PropertySubType" */
export type Rtcs_Db_Kcca_PropertySubType_Set_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_PropertySubType_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_PropertySubType_stddev_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_PropertySubType" */
export type Rtcs_Db_Kcca_PropertySubType_Stddev_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_PropertySubType_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_PropertySubType_stddev_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_PropertySubType" */
export type Rtcs_Db_Kcca_PropertySubType_Stddev_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_PropertySubType_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_PropertySubType_stddev_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_PropertySubType" */
export type Rtcs_Db_Kcca_PropertySubType_Stddev_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_PropertySubType_Sum_Fields = {
  __typename?: "rtcs_db_kcca_PropertySubType_sum_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_PropertySubType" */
export type Rtcs_Db_Kcca_PropertySubType_Sum_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_PropertySubType_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_PropertySubType_var_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_PropertySubType" */
export type Rtcs_Db_Kcca_PropertySubType_Var_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_PropertySubType_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_PropertySubType_var_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_PropertySubType" */
export type Rtcs_Db_Kcca_PropertySubType_Var_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_PropertySubType_Variance_Fields = {
  __typename?: "rtcs_db_kcca_PropertySubType_variance_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_PropertySubType" */
export type Rtcs_Db_Kcca_PropertySubType_Variance_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_PropertyType" */
export type Rtcs_Db_Kcca_PropertyType = {
  __typename?: "rtcs_db_kcca_PropertyType";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  PaymentFrequencyId?: Maybe<Scalars["float8"]>;
  ReferenceName?: Maybe<Scalars["String"]>;
  RevenueTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  UraCode?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_PropertyType" */
export type Rtcs_Db_Kcca_PropertyType_Aggregate = {
  __typename?: "rtcs_db_kcca_PropertyType_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_PropertyType_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_PropertyType>;
};

/** aggregate fields of "rtcs_db.kcca_PropertyType" */
export type Rtcs_Db_Kcca_PropertyType_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_PropertyType_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_PropertyType_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_PropertyType_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_PropertyType_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_PropertyType_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_PropertyType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_PropertyType_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_PropertyType_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_PropertyType_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_PropertyType_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_PropertyType_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_PropertyType" */
export type Rtcs_Db_Kcca_PropertyType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_PropertyType_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_PropertyType" */
export type Rtcs_Db_Kcca_PropertyType_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_PropertyType_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_PropertyType_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_PropertyType_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_PropertyType_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_PropertyType_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_PropertyType_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_PropertyType_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_PropertyType_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_PropertyType_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_PropertyType_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_PropertyType" */
export type Rtcs_Db_Kcca_PropertyType_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_PropertyType_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_PropertyType_Avg_Fields = {
  __typename?: "rtcs_db_kcca_PropertyType_avg_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PaymentFrequencyId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_PropertyType" */
export type Rtcs_Db_Kcca_PropertyType_Avg_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PaymentFrequencyId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_PropertyType". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_PropertyType_Bool_Exp = {
  CreatedBy?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  PaymentFrequencyId?: Maybe<Float8_Comparison_Exp>;
  ReferenceName?: Maybe<String_Comparison_Exp>;
  RevenueTypeId?: Maybe<Bigint_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  UraCode?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_PropertyType_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_PropertyType_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_PropertyType_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_PropertyType" */
export type Rtcs_Db_Kcca_PropertyType_Inc_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PaymentFrequencyId?: Maybe<Scalars["float8"]>;
  RevenueTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_PropertyType" */
export type Rtcs_Db_Kcca_PropertyType_Insert_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  PaymentFrequencyId?: Maybe<Scalars["float8"]>;
  ReferenceName?: Maybe<Scalars["String"]>;
  RevenueTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  UraCode?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_PropertyType_Max_Fields = {
  __typename?: "rtcs_db_kcca_PropertyType_max_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  PaymentFrequencyId?: Maybe<Scalars["float8"]>;
  ReferenceName?: Maybe<Scalars["String"]>;
  RevenueTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  UraCode?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_PropertyType" */
export type Rtcs_Db_Kcca_PropertyType_Max_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  PaymentFrequencyId?: Maybe<Order_By>;
  ReferenceName?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UraCode?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_PropertyType_Min_Fields = {
  __typename?: "rtcs_db_kcca_PropertyType_min_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  PaymentFrequencyId?: Maybe<Scalars["float8"]>;
  ReferenceName?: Maybe<Scalars["String"]>;
  RevenueTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  UraCode?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_PropertyType" */
export type Rtcs_Db_Kcca_PropertyType_Min_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  PaymentFrequencyId?: Maybe<Order_By>;
  ReferenceName?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UraCode?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_PropertyType" */
export type Rtcs_Db_Kcca_PropertyType_Mutation_Response = {
  __typename?: "rtcs_db_kcca_PropertyType_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_PropertyType>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_PropertyType" */
export type Rtcs_Db_Kcca_PropertyType_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_PropertyType_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_PropertyType" */
export type Rtcs_Db_Kcca_PropertyType_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  PaymentFrequencyId?: Maybe<Order_By>;
  ReferenceName?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  UraCode?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_PropertyType" */
export enum Rtcs_Db_Kcca_PropertyType_Select_Column {
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  PaymentFrequencyId = "PaymentFrequencyId",
  /** column name */
  ReferenceName = "ReferenceName",
  /** column name */
  RevenueTypeId = "RevenueTypeId",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  UraCode = "UraCode",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_PropertyType" */
export type Rtcs_Db_Kcca_PropertyType_Set_Input = {
  CreatedBy?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  PaymentFrequencyId?: Maybe<Scalars["float8"]>;
  ReferenceName?: Maybe<Scalars["String"]>;
  RevenueTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  UraCode?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_PropertyType_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_PropertyType_stddev_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PaymentFrequencyId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_PropertyType" */
export type Rtcs_Db_Kcca_PropertyType_Stddev_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PaymentFrequencyId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_PropertyType_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_PropertyType_stddev_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PaymentFrequencyId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_PropertyType" */
export type Rtcs_Db_Kcca_PropertyType_Stddev_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PaymentFrequencyId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_PropertyType_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_PropertyType_stddev_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PaymentFrequencyId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_PropertyType" */
export type Rtcs_Db_Kcca_PropertyType_Stddev_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PaymentFrequencyId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_PropertyType_Sum_Fields = {
  __typename?: "rtcs_db_kcca_PropertyType_sum_fields";
  CreatedBy?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PaymentFrequencyId?: Maybe<Scalars["float8"]>;
  RevenueTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_PropertyType" */
export type Rtcs_Db_Kcca_PropertyType_Sum_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PaymentFrequencyId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_PropertyType_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_PropertyType_var_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PaymentFrequencyId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_PropertyType" */
export type Rtcs_Db_Kcca_PropertyType_Var_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PaymentFrequencyId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_PropertyType_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_PropertyType_var_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PaymentFrequencyId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_PropertyType" */
export type Rtcs_Db_Kcca_PropertyType_Var_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PaymentFrequencyId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_PropertyType_Variance_Fields = {
  __typename?: "rtcs_db_kcca_PropertyType_variance_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PaymentFrequencyId?: Maybe<Scalars["Float"]>;
  RevenueTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_PropertyType" */
export type Rtcs_Db_Kcca_PropertyType_Variance_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PaymentFrequencyId?: Maybe<Order_By>;
  RevenueTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregated selection of "rtcs_db.kcca_Property" */
export type Rtcs_Db_Kcca_Property_Aggregate = {
  __typename?: "rtcs_db_kcca_Property_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_Property_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_Property>;
};

/** aggregate fields of "rtcs_db.kcca_Property" */
export type Rtcs_Db_Kcca_Property_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_Property_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_Property_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_Property_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_Property_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_Property_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_Property_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_Property_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_Property_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_Property_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_Property_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_Property_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_Property" */
export type Rtcs_Db_Kcca_Property_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_Property_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_Property" */
export type Rtcs_Db_Kcca_Property_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_Property_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_Property_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_Property_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_Property_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_Property_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_Property_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_Property_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_Property_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_Property_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_Property_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_Property" */
export type Rtcs_Db_Kcca_Property_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_Property_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_Property_Avg_Fields = {
  __typename?: "rtcs_db_kcca_Property_avg_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_Property" */
export type Rtcs_Db_Kcca_Property_Avg_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_Property". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_Property_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  BlockNo?: Maybe<String_Comparison_Exp>;
  CamvId?: Maybe<String_Comparison_Exp>;
  CreatedBy?: Maybe<String_Comparison_Exp>;
  CreatedDate?: Maybe<String_Comparison_Exp>;
  CurrentRateableValue?: Maybe<String_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  DivisionId?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  GprsCordinateX?: Maybe<String_Comparison_Exp>;
  GprsCordinateY?: Maybe<String_Comparison_Exp>;
  GrossValue?: Maybe<String_Comparison_Exp>;
  HouseNo?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  ImgPath?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<String_Comparison_Exp>;
  Latitude?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  LocationAddress?: Maybe<String_Comparison_Exp>;
  Longitude?: Maybe<String_Comparison_Exp>;
  MapTitle?: Maybe<String_Comparison_Exp>;
  ModifiedBy?: Maybe<String_Comparison_Exp>;
  ModifiedDate?: Maybe<String_Comparison_Exp>;
  ParishId?: Maybe<Bigint_Comparison_Exp>;
  ParticularsFrontages?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyNo?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatusId?: Maybe<Bigint_Comparison_Exp>;
  PropertyTypeId?: Maybe<Bigint_Comparison_Exp>;
  RateableValue?: Maybe<String_Comparison_Exp>;
  Remisson?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  RoadOthers?: Maybe<String_Comparison_Exp>;
  SerialNo?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Float8_Comparison_Exp>;
  StreetId?: Maybe<String_Comparison_Exp>;
  SubPropertyTypeId?: Maybe<Float8_Comparison_Exp>;
  Supplementary?: Maybe<String_Comparison_Exp>;
  VillageId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_Property_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_Property_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_Property_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_Property" */
export type Rtcs_Db_Kcca_Property_Inc_Input = {
  DivisionId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_Property" */
export type Rtcs_Db_Kcca_Property_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_Property_Max_Fields = {
  __typename?: "rtcs_db_kcca_Property_max_fields";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_Property" */
export type Rtcs_Db_Kcca_Property_Max_Order_By = {
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GprsCordinateX?: Maybe<Order_By>;
  GprsCordinateY?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LocationAddress?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RoadOthers?: Maybe<Order_By>;
  SerialNo?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_Property_Min_Fields = {
  __typename?: "rtcs_db_kcca_Property_min_fields";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_Property" */
export type Rtcs_Db_Kcca_Property_Min_Order_By = {
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GprsCordinateX?: Maybe<Order_By>;
  GprsCordinateY?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LocationAddress?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RoadOthers?: Maybe<Order_By>;
  SerialNo?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_Property" */
export type Rtcs_Db_Kcca_Property_Mutation_Response = {
  __typename?: "rtcs_db_kcca_Property_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_Property>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_Property" */
export type Rtcs_Db_Kcca_Property_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_Property_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_Property" */
export type Rtcs_Db_Kcca_Property_Order_By = {
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GprsCordinateX?: Maybe<Order_By>;
  GprsCordinateY?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LocationAddress?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RoadOthers?: Maybe<Order_By>;
  SerialNo?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_Property" */
export enum Rtcs_Db_Kcca_Property_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  CamvId = "CamvId",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CurrentRateableValue = "CurrentRateableValue",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  File = "File",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  GprsCordinateX = "GprsCordinateX",
  /** column name */
  GprsCordinateY = "GprsCordinateY",
  /** column name */
  GrossValue = "GrossValue",
  /** column name */
  HouseNo = "HouseNo",
  /** column name */
  Id = "Id",
  /** column name */
  ImgPath = "ImgPath",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  LocationAddress = "LocationAddress",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MapTitle = "MapTitle",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  ParticularsFrontages = "ParticularsFrontages",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyNo = "PropertyNo",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  RateableValue = "RateableValue",
  /** column name */
  Remisson = "Remisson",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  RoadOthers = "RoadOthers",
  /** column name */
  SerialNo = "SerialNo",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  SubPropertyTypeId = "SubPropertyTypeId",
  /** column name */
  Supplementary = "Supplementary",
  /** column name */
  VillageId = "VillageId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_Property" */
export type Rtcs_Db_Kcca_Property_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["String"]>;
  CreatedDate?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["String"]>;
  ModifiedDate?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["String"]>;
  Remisson?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_Property_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_Property_stddev_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_Property" */
export type Rtcs_Db_Kcca_Property_Stddev_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_Property_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_Property_stddev_pop_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_Property" */
export type Rtcs_Db_Kcca_Property_Stddev_Pop_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_Property_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_Property_stddev_samp_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_Property" */
export type Rtcs_Db_Kcca_Property_Stddev_Samp_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_Property_Sum_Fields = {
  __typename?: "rtcs_db_kcca_Property_sum_fields";
  DivisionId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  PropertyRentedStatusId?: Maybe<Scalars["bigint"]>;
  PropertyTypeId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_Property" */
export type Rtcs_Db_Kcca_Property_Sum_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_Property_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_Property_var_pop_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_Property" */
export type Rtcs_Db_Kcca_Property_Var_Pop_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_Property_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_Property_var_samp_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_Property" */
export type Rtcs_Db_Kcca_Property_Var_Samp_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_Property_Variance_Fields = {
  __typename?: "rtcs_db_kcca_Property_variance_fields";
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_Property" */
export type Rtcs_Db_Kcca_Property_Variance_Order_By = {
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_RawProperties1" */
export type Rtcs_Db_Kcca_RawProperties1 = {
  __typename?: "rtcs_db_kcca_RawProperties1";
  AccomodationBathroom?: Maybe<Scalars["float8"]>;
  AccomodationBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["String"]>;
  AccomodationComment?: Maybe<Scalars["String"]>;
  AccomodationDiningRooms?: Maybe<Scalars["float8"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["float8"]>;
  AccomodationEnviromentalNoise?: Maybe<Scalars["String"]>;
  AccomodationGarage?: Maybe<Scalars["float8"]>;
  AccomodationKitchen?: Maybe<Scalars["float8"]>;
  AccomodationLaundry?: Maybe<Scalars["float8"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  AccomodationSecurity?: Maybe<Scalars["String"]>;
  AccomodationShops?: Maybe<Scalars["float8"]>;
  AccomodationShower?: Maybe<Scalars["float8"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["float8"]>;
  AccomodationStore?: Maybe<Scalars["float8"]>;
  AccomodationStudyRoom?: Maybe<Scalars["float8"]>;
  AccomodationVerandah?: Maybe<Scalars["float8"]>;
  AccomodationWc?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroom?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationWcShower?: Maybe<Scalars["float8"]>;
  AlternateEmail?: Maybe<Scalars["String"]>;
  AlternateFirstname?: Maybe<Scalars["String"]>;
  AlternateLandlineWithCode?: Maybe<Scalars["String"]>;
  AlternateMiddlename?: Maybe<Scalars["String"]>;
  AlternateMobile1?: Maybe<Scalars["String"]>;
  AlternateMobile2?: Maybe<Scalars["float8"]>;
  AlternateSurname?: Maybe<Scalars["String"]>;
  AlternateTittle?: Maybe<Scalars["String"]>;
  AlternativeDrivingPermit?: Maybe<Scalars["String"]>;
  AlternativeFinancialCard?: Maybe<Scalars["String"]>;
  AlternativeNationalId?: Maybe<Scalars["String"]>;
  AlternativeNssfCard?: Maybe<Scalars["float8"]>;
  AlternativePassport?: Maybe<Scalars["String"]>;
  AlternativeVillageId?: Maybe<Scalars["String"]>;
  BuildingBlockNumber?: Maybe<Scalars["String"]>;
  BuildingCondition?: Maybe<Scalars["String"]>;
  BuildingFlatNumber?: Maybe<Scalars["String"]>;
  BuildingFloorNumber?: Maybe<Scalars["float8"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BuildingNumber?: Maybe<Scalars["String"]>;
  BuildingOtherServices?: Maybe<Scalars["String"]>;
  BuildingParkingSlots?: Maybe<Scalars["String"]>;
  BuildingParkingSpaceAvailable?: Maybe<Scalars["String"]>;
  BuildingParkingSpaceSufficient?: Maybe<Scalars["String"]>;
  BuildingPowerSupply?: Maybe<Scalars["String"]>;
  BuildingRent?: Maybe<Scalars["String"]>;
  BuildingSanitationType?: Maybe<Scalars["String"]>;
  BuildingType?: Maybe<Scalars["String"]>;
  BuildingVerticalCirculation?: Maybe<Scalars["String"]>;
  BuildingWaterAccessibility?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  ConstructionCeiling?: Maybe<Scalars["String"]>;
  ConstructionDoorOthers?: Maybe<Scalars["String"]>;
  ConstructionDoors?: Maybe<Scalars["String"]>;
  ConstructionFloorsFinish?: Maybe<Scalars["String"]>;
  ConstructionFoorsConstruction?: Maybe<Scalars["String"]>;
  ConstructionOtherDetails?: Maybe<Scalars["String"]>;
  ConstructionRoofCovering?: Maybe<Scalars["String"]>;
  ConstructionWallFinish?: Maybe<Scalars["String"]>;
  ConstructionWalls?: Maybe<Scalars["String"]>;
  ConstructionWindowOthers?: Maybe<Scalars["String"]>;
  ConstructionWindows?: Maybe<Scalars["String"]>;
  ContactAddress?: Maybe<Scalars["String"]>;
  ContactCountry?: Maybe<Scalars["String"]>;
  ContactCountyMunicipality?: Maybe<Scalars["String"]>;
  ContactDistrict?: Maybe<Scalars["String"]>;
  ContactEmail?: Maybe<Scalars["String"]>;
  ContactLandline?: Maybe<Scalars["String"]>;
  ContactMobile1?: Maybe<Scalars["String"]>;
  ContactMobile2?: Maybe<Scalars["String"]>;
  ContactParishWard?: Maybe<Scalars["String"]>;
  ContactSubcountryDivision?: Maybe<Scalars["String"]>;
  ContactVillageCellZone?: Maybe<Scalars["String"]>;
  CordinateX?: Maybe<Scalars["String"]>;
  CordinateY?: Maybe<Scalars["String"]>;
  DataDate?: Maybe<Scalars["timestamptz"]>;
  Division?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Grade?: Maybe<Scalars["float8"]>;
  Gv?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationMaritalCertificate?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfCard?: Maybe<Scalars["float8"]>;
  IdentificationPassport?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  NameContact?: Maybe<Scalars["String"]>;
  OtherBoundaryFencespecify?: Maybe<Scalars["String"]>;
  OwnerCoin?: Maybe<Scalars["String"]>;
  OwnerFirstname?: Maybe<Scalars["String"]>;
  OwnerIndividualCompany?: Maybe<Scalars["String"]>;
  OwnerLegalSurnameMaiden?: Maybe<Scalars["String"]>;
  OwnerMiddlename?: Maybe<Scalars["String"]>;
  OwnerOthernames?: Maybe<Scalars["String"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["bigint"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParticularsAccess?: Maybe<Scalars["String"]>;
  ParticularsBlockName?: Maybe<Scalars["String"]>;
  ParticularsBlockNumber?: Maybe<Scalars["String"]>;
  ParticularsFolioNumber?: Maybe<Scalars["String"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["float8"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["float8"]>;
  ParticularsHouseNumber?: Maybe<Scalars["String"]>;
  ParticularsIsTitled?: Maybe<Scalars["String"]>;
  ParticularsLandReference?: Maybe<Scalars["String"]>;
  ParticularsNeighborhood?: Maybe<Scalars["String"]>;
  ParticularsPlotNumber?: Maybe<Scalars["String"]>;
  ParticularsPlotShape?: Maybe<Scalars["String"]>;
  ParticularsPropertyName?: Maybe<Scalars["String"]>;
  ParticularsRoadStreet?: Maybe<Scalars["String"]>;
  ParticularsTopographyOthers?: Maybe<Scalars["String"]>;
  ParticularsTopograpy?: Maybe<Scalars["String"]>;
  ParticularsTypeOfLandInterest?: Maybe<Scalars["String"]>;
  ParticularsVolume?: Maybe<Scalars["String"]>;
  PropertyOwnerOccupiedOrRented?: Maybe<Scalars["String"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  Pvillage?: Maybe<Scalars["String"]>;
  Reference?: Maybe<Scalars["float8"]>;
  ReferenceNumberNew?: Maybe<Scalars["float8"]>;
  RoadsOtherspecify?: Maybe<Scalars["String"]>;
  Rv?: Maybe<Scalars["float8"]>;
  SiteworksBoundaryFence?: Maybe<Scalars["String"]>;
  SiteworksBuildingNumber?: Maybe<Scalars["String"]>;
  SiteworksCompound?: Maybe<Scalars["String"]>;
  SiteworksCourt?: Maybe<Scalars["String"]>;
  SiteworksGate?: Maybe<Scalars["String"]>;
  SiteworksOtherFacilities?: Maybe<Scalars["String"]>;
  SiteworksOthers?: Maybe<Scalars["String"]>;
  SiteworksSwimmingPool?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_RawProperties1" */
export type Rtcs_Db_Kcca_RawProperties1_Aggregate = {
  __typename?: "rtcs_db_kcca_RawProperties1_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_RawProperties1_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_RawProperties1>;
};

/** aggregate fields of "rtcs_db.kcca_RawProperties1" */
export type Rtcs_Db_Kcca_RawProperties1_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_RawProperties1_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_RawProperties1_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_RawProperties1_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_RawProperties1_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_RawProperties1_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_RawProperties1_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_RawProperties1_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_RawProperties1_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_RawProperties1_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_RawProperties1_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_RawProperties1_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_RawProperties1" */
export type Rtcs_Db_Kcca_RawProperties1_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_RawProperties1_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_RawProperties1" */
export type Rtcs_Db_Kcca_RawProperties1_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_RawProperties1_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_RawProperties1_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_RawProperties1_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_RawProperties1_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_RawProperties1_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_RawProperties1_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_RawProperties1_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_RawProperties1_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_RawProperties1_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_RawProperties1_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_RawProperties1" */
export type Rtcs_Db_Kcca_RawProperties1_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_RawProperties1_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_RawProperties1_Avg_Fields = {
  __typename?: "rtcs_db_kcca_RawProperties1_avg_fields";
  AccomodationBathroom?: Maybe<Scalars["Float"]>;
  AccomodationBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationDiningRooms?: Maybe<Scalars["Float"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["Float"]>;
  AccomodationGarage?: Maybe<Scalars["Float"]>;
  AccomodationKitchen?: Maybe<Scalars["Float"]>;
  AccomodationLaundry?: Maybe<Scalars["Float"]>;
  AccomodationShops?: Maybe<Scalars["Float"]>;
  AccomodationShower?: Maybe<Scalars["Float"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["Float"]>;
  AccomodationStore?: Maybe<Scalars["Float"]>;
  AccomodationStudyRoom?: Maybe<Scalars["Float"]>;
  AccomodationVerandah?: Maybe<Scalars["Float"]>;
  AccomodationWc?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroom?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationWcShower?: Maybe<Scalars["Float"]>;
  AlternateMobile2?: Maybe<Scalars["Float"]>;
  AlternativeNssfCard?: Maybe<Scalars["Float"]>;
  BuildingFloorNumber?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  Grade?: Maybe<Scalars["Float"]>;
  IdentificationNssfCard?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["Float"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  ReferenceNumberNew?: Maybe<Scalars["Float"]>;
  Rv?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_RawProperties1" */
export type Rtcs_Db_Kcca_RawProperties1_Avg_Order_By = {
  AccomodationBathroom?: Maybe<Order_By>;
  AccomodationBathroomShower?: Maybe<Order_By>;
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationDiningRooms?: Maybe<Order_By>;
  AccomodationEntertainmentRoom?: Maybe<Order_By>;
  AccomodationGarage?: Maybe<Order_By>;
  AccomodationKitchen?: Maybe<Order_By>;
  AccomodationLaundry?: Maybe<Order_By>;
  AccomodationShops?: Maybe<Order_By>;
  AccomodationShower?: Maybe<Order_By>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Order_By>;
  AccomodationStore?: Maybe<Order_By>;
  AccomodationStudyRoom?: Maybe<Order_By>;
  AccomodationVerandah?: Maybe<Order_By>;
  AccomodationWc?: Maybe<Order_By>;
  AccomodationWcBathroom?: Maybe<Order_By>;
  AccomodationWcBathroomShower?: Maybe<Order_By>;
  AccomodationWcShower?: Maybe<Order_By>;
  AlternateMobile2?: Maybe<Order_By>;
  AlternativeNssfCard?: Maybe<Order_By>;
  BuildingFloorNumber?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  Grade?: Maybe<Order_By>;
  IdentificationNssfCard?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParticularsGpsEastingsUtm?: Maybe<Order_By>;
  ParticularsGpsNorthingsUtm?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  ReferenceNumberNew?: Maybe<Order_By>;
  Rv?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_RawProperties1". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_RawProperties1_Bool_Exp = {
  AccomodationBathroom?: Maybe<Float8_Comparison_Exp>;
  AccomodationBathroomShower?: Maybe<Float8_Comparison_Exp>;
  AccomodationBedrooms?: Maybe<Float8_Comparison_Exp>;
  AccomodationBuiltUpArea?: Maybe<String_Comparison_Exp>;
  AccomodationComment?: Maybe<String_Comparison_Exp>;
  AccomodationDiningRooms?: Maybe<Float8_Comparison_Exp>;
  AccomodationEntertainmentRoom?: Maybe<Float8_Comparison_Exp>;
  AccomodationEnviromentalNoise?: Maybe<String_Comparison_Exp>;
  AccomodationGarage?: Maybe<Float8_Comparison_Exp>;
  AccomodationKitchen?: Maybe<Float8_Comparison_Exp>;
  AccomodationLaundry?: Maybe<Float8_Comparison_Exp>;
  AccomodationOthers?: Maybe<String_Comparison_Exp>;
  AccomodationSecurity?: Maybe<String_Comparison_Exp>;
  AccomodationShops?: Maybe<Float8_Comparison_Exp>;
  AccomodationShower?: Maybe<Float8_Comparison_Exp>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Float8_Comparison_Exp>;
  AccomodationStore?: Maybe<Float8_Comparison_Exp>;
  AccomodationStudyRoom?: Maybe<Float8_Comparison_Exp>;
  AccomodationVerandah?: Maybe<Float8_Comparison_Exp>;
  AccomodationWc?: Maybe<Float8_Comparison_Exp>;
  AccomodationWcBathroom?: Maybe<Float8_Comparison_Exp>;
  AccomodationWcBathroomShower?: Maybe<Float8_Comparison_Exp>;
  AccomodationWcShower?: Maybe<Float8_Comparison_Exp>;
  AlternateEmail?: Maybe<String_Comparison_Exp>;
  AlternateFirstname?: Maybe<String_Comparison_Exp>;
  AlternateLandlineWithCode?: Maybe<String_Comparison_Exp>;
  AlternateMiddlename?: Maybe<String_Comparison_Exp>;
  AlternateMobile1?: Maybe<String_Comparison_Exp>;
  AlternateMobile2?: Maybe<Float8_Comparison_Exp>;
  AlternateSurname?: Maybe<String_Comparison_Exp>;
  AlternateTittle?: Maybe<String_Comparison_Exp>;
  AlternativeDrivingPermit?: Maybe<String_Comparison_Exp>;
  AlternativeFinancialCard?: Maybe<String_Comparison_Exp>;
  AlternativeNationalId?: Maybe<String_Comparison_Exp>;
  AlternativeNssfCard?: Maybe<Float8_Comparison_Exp>;
  AlternativePassport?: Maybe<String_Comparison_Exp>;
  AlternativeVillageId?: Maybe<String_Comparison_Exp>;
  BuildingBlockNumber?: Maybe<String_Comparison_Exp>;
  BuildingCondition?: Maybe<String_Comparison_Exp>;
  BuildingFlatNumber?: Maybe<String_Comparison_Exp>;
  BuildingFloorNumber?: Maybe<Float8_Comparison_Exp>;
  BuildingName?: Maybe<String_Comparison_Exp>;
  BuildingNumber?: Maybe<String_Comparison_Exp>;
  BuildingOtherServices?: Maybe<String_Comparison_Exp>;
  BuildingParkingSlots?: Maybe<String_Comparison_Exp>;
  BuildingParkingSpaceAvailable?: Maybe<String_Comparison_Exp>;
  BuildingParkingSpaceSufficient?: Maybe<String_Comparison_Exp>;
  BuildingPowerSupply?: Maybe<String_Comparison_Exp>;
  BuildingRent?: Maybe<String_Comparison_Exp>;
  BuildingSanitationType?: Maybe<String_Comparison_Exp>;
  BuildingType?: Maybe<String_Comparison_Exp>;
  BuildingVerticalCirculation?: Maybe<String_Comparison_Exp>;
  BuildingWaterAccessibility?: Maybe<String_Comparison_Exp>;
  Coin?: Maybe<Float8_Comparison_Exp>;
  ConstructionCeiling?: Maybe<String_Comparison_Exp>;
  ConstructionDoorOthers?: Maybe<String_Comparison_Exp>;
  ConstructionDoors?: Maybe<String_Comparison_Exp>;
  ConstructionFloorsFinish?: Maybe<String_Comparison_Exp>;
  ConstructionFoorsConstruction?: Maybe<String_Comparison_Exp>;
  ConstructionOtherDetails?: Maybe<String_Comparison_Exp>;
  ConstructionRoofCovering?: Maybe<String_Comparison_Exp>;
  ConstructionWallFinish?: Maybe<String_Comparison_Exp>;
  ConstructionWalls?: Maybe<String_Comparison_Exp>;
  ConstructionWindowOthers?: Maybe<String_Comparison_Exp>;
  ConstructionWindows?: Maybe<String_Comparison_Exp>;
  ContactAddress?: Maybe<String_Comparison_Exp>;
  ContactCountry?: Maybe<String_Comparison_Exp>;
  ContactCountyMunicipality?: Maybe<String_Comparison_Exp>;
  ContactDistrict?: Maybe<String_Comparison_Exp>;
  ContactEmail?: Maybe<String_Comparison_Exp>;
  ContactLandline?: Maybe<String_Comparison_Exp>;
  ContactMobile1?: Maybe<String_Comparison_Exp>;
  ContactMobile2?: Maybe<String_Comparison_Exp>;
  ContactParishWard?: Maybe<String_Comparison_Exp>;
  ContactSubcountryDivision?: Maybe<String_Comparison_Exp>;
  ContactVillageCellZone?: Maybe<String_Comparison_Exp>;
  CordinateX?: Maybe<String_Comparison_Exp>;
  CordinateY?: Maybe<String_Comparison_Exp>;
  DataDate?: Maybe<Timestamptz_Comparison_Exp>;
  Division?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Grade?: Maybe<Float8_Comparison_Exp>;
  Gv?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  IdentificationDrivingPermit?: Maybe<String_Comparison_Exp>;
  IdentificationMaritalCertificate?: Maybe<String_Comparison_Exp>;
  IdentificationNationalId?: Maybe<String_Comparison_Exp>;
  IdentificationNssfCard?: Maybe<Float8_Comparison_Exp>;
  IdentificationPassport?: Maybe<String_Comparison_Exp>;
  IdentificationVillageId?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  NameContact?: Maybe<String_Comparison_Exp>;
  OtherBoundaryFencespecify?: Maybe<String_Comparison_Exp>;
  OwnerCoin?: Maybe<String_Comparison_Exp>;
  OwnerFirstname?: Maybe<String_Comparison_Exp>;
  OwnerIndividualCompany?: Maybe<String_Comparison_Exp>;
  OwnerLegalSurnameMaiden?: Maybe<String_Comparison_Exp>;
  OwnerMiddlename?: Maybe<String_Comparison_Exp>;
  OwnerOthernames?: Maybe<String_Comparison_Exp>;
  OwnerTin?: Maybe<Float8_Comparison_Exp>;
  OwnerTitle?: Maybe<Bigint_Comparison_Exp>;
  Parish?: Maybe<String_Comparison_Exp>;
  ParticularsAccess?: Maybe<String_Comparison_Exp>;
  ParticularsBlockName?: Maybe<String_Comparison_Exp>;
  ParticularsBlockNumber?: Maybe<String_Comparison_Exp>;
  ParticularsFolioNumber?: Maybe<String_Comparison_Exp>;
  ParticularsFrontages?: Maybe<String_Comparison_Exp>;
  ParticularsGpsEastingsUtm?: Maybe<Float8_Comparison_Exp>;
  ParticularsGpsNorthingsUtm?: Maybe<Float8_Comparison_Exp>;
  ParticularsHouseNumber?: Maybe<String_Comparison_Exp>;
  ParticularsIsTitled?: Maybe<String_Comparison_Exp>;
  ParticularsLandReference?: Maybe<String_Comparison_Exp>;
  ParticularsNeighborhood?: Maybe<String_Comparison_Exp>;
  ParticularsPlotNumber?: Maybe<String_Comparison_Exp>;
  ParticularsPlotShape?: Maybe<String_Comparison_Exp>;
  ParticularsPropertyName?: Maybe<String_Comparison_Exp>;
  ParticularsRoadStreet?: Maybe<String_Comparison_Exp>;
  ParticularsTopographyOthers?: Maybe<String_Comparison_Exp>;
  ParticularsTopograpy?: Maybe<String_Comparison_Exp>;
  ParticularsTypeOfLandInterest?: Maybe<String_Comparison_Exp>;
  ParticularsVolume?: Maybe<String_Comparison_Exp>;
  PropertyOwnerOccupiedOrRented?: Maybe<String_Comparison_Exp>;
  PropertySubType?: Maybe<String_Comparison_Exp>;
  PropertyType?: Maybe<String_Comparison_Exp>;
  Pvillage?: Maybe<String_Comparison_Exp>;
  Reference?: Maybe<Float8_Comparison_Exp>;
  ReferenceNumberNew?: Maybe<Float8_Comparison_Exp>;
  RoadsOtherspecify?: Maybe<String_Comparison_Exp>;
  Rv?: Maybe<Float8_Comparison_Exp>;
  SiteworksBoundaryFence?: Maybe<String_Comparison_Exp>;
  SiteworksBuildingNumber?: Maybe<String_Comparison_Exp>;
  SiteworksCompound?: Maybe<String_Comparison_Exp>;
  SiteworksCourt?: Maybe<String_Comparison_Exp>;
  SiteworksGate?: Maybe<String_Comparison_Exp>;
  SiteworksOtherFacilities?: Maybe<String_Comparison_Exp>;
  SiteworksOthers?: Maybe<String_Comparison_Exp>;
  SiteworksSwimmingPool?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_RawProperties1_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_RawProperties1_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_RawProperties1_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_RawProperties1" */
export type Rtcs_Db_Kcca_RawProperties1_Inc_Input = {
  AccomodationBathroom?: Maybe<Scalars["float8"]>;
  AccomodationBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationDiningRooms?: Maybe<Scalars["float8"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["float8"]>;
  AccomodationGarage?: Maybe<Scalars["float8"]>;
  AccomodationKitchen?: Maybe<Scalars["float8"]>;
  AccomodationLaundry?: Maybe<Scalars["float8"]>;
  AccomodationShops?: Maybe<Scalars["float8"]>;
  AccomodationShower?: Maybe<Scalars["float8"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["float8"]>;
  AccomodationStore?: Maybe<Scalars["float8"]>;
  AccomodationStudyRoom?: Maybe<Scalars["float8"]>;
  AccomodationVerandah?: Maybe<Scalars["float8"]>;
  AccomodationWc?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroom?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationWcShower?: Maybe<Scalars["float8"]>;
  AlternateMobile2?: Maybe<Scalars["float8"]>;
  AlternativeNssfCard?: Maybe<Scalars["float8"]>;
  BuildingFloorNumber?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  Grade?: Maybe<Scalars["float8"]>;
  IdentificationNssfCard?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["bigint"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["float8"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["float8"]>;
  Reference?: Maybe<Scalars["float8"]>;
  ReferenceNumberNew?: Maybe<Scalars["float8"]>;
  Rv?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_RawProperties1" */
export type Rtcs_Db_Kcca_RawProperties1_Insert_Input = {
  AccomodationBathroom?: Maybe<Scalars["float8"]>;
  AccomodationBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["String"]>;
  AccomodationComment?: Maybe<Scalars["String"]>;
  AccomodationDiningRooms?: Maybe<Scalars["float8"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["float8"]>;
  AccomodationEnviromentalNoise?: Maybe<Scalars["String"]>;
  AccomodationGarage?: Maybe<Scalars["float8"]>;
  AccomodationKitchen?: Maybe<Scalars["float8"]>;
  AccomodationLaundry?: Maybe<Scalars["float8"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  AccomodationSecurity?: Maybe<Scalars["String"]>;
  AccomodationShops?: Maybe<Scalars["float8"]>;
  AccomodationShower?: Maybe<Scalars["float8"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["float8"]>;
  AccomodationStore?: Maybe<Scalars["float8"]>;
  AccomodationStudyRoom?: Maybe<Scalars["float8"]>;
  AccomodationVerandah?: Maybe<Scalars["float8"]>;
  AccomodationWc?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroom?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationWcShower?: Maybe<Scalars["float8"]>;
  AlternateEmail?: Maybe<Scalars["String"]>;
  AlternateFirstname?: Maybe<Scalars["String"]>;
  AlternateLandlineWithCode?: Maybe<Scalars["String"]>;
  AlternateMiddlename?: Maybe<Scalars["String"]>;
  AlternateMobile1?: Maybe<Scalars["String"]>;
  AlternateMobile2?: Maybe<Scalars["float8"]>;
  AlternateSurname?: Maybe<Scalars["String"]>;
  AlternateTittle?: Maybe<Scalars["String"]>;
  AlternativeDrivingPermit?: Maybe<Scalars["String"]>;
  AlternativeFinancialCard?: Maybe<Scalars["String"]>;
  AlternativeNationalId?: Maybe<Scalars["String"]>;
  AlternativeNssfCard?: Maybe<Scalars["float8"]>;
  AlternativePassport?: Maybe<Scalars["String"]>;
  AlternativeVillageId?: Maybe<Scalars["String"]>;
  BuildingBlockNumber?: Maybe<Scalars["String"]>;
  BuildingCondition?: Maybe<Scalars["String"]>;
  BuildingFlatNumber?: Maybe<Scalars["String"]>;
  BuildingFloorNumber?: Maybe<Scalars["float8"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BuildingNumber?: Maybe<Scalars["String"]>;
  BuildingOtherServices?: Maybe<Scalars["String"]>;
  BuildingParkingSlots?: Maybe<Scalars["String"]>;
  BuildingParkingSpaceAvailable?: Maybe<Scalars["String"]>;
  BuildingParkingSpaceSufficient?: Maybe<Scalars["String"]>;
  BuildingPowerSupply?: Maybe<Scalars["String"]>;
  BuildingRent?: Maybe<Scalars["String"]>;
  BuildingSanitationType?: Maybe<Scalars["String"]>;
  BuildingType?: Maybe<Scalars["String"]>;
  BuildingVerticalCirculation?: Maybe<Scalars["String"]>;
  BuildingWaterAccessibility?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  ConstructionCeiling?: Maybe<Scalars["String"]>;
  ConstructionDoorOthers?: Maybe<Scalars["String"]>;
  ConstructionDoors?: Maybe<Scalars["String"]>;
  ConstructionFloorsFinish?: Maybe<Scalars["String"]>;
  ConstructionFoorsConstruction?: Maybe<Scalars["String"]>;
  ConstructionOtherDetails?: Maybe<Scalars["String"]>;
  ConstructionRoofCovering?: Maybe<Scalars["String"]>;
  ConstructionWallFinish?: Maybe<Scalars["String"]>;
  ConstructionWalls?: Maybe<Scalars["String"]>;
  ConstructionWindowOthers?: Maybe<Scalars["String"]>;
  ConstructionWindows?: Maybe<Scalars["String"]>;
  ContactAddress?: Maybe<Scalars["String"]>;
  ContactCountry?: Maybe<Scalars["String"]>;
  ContactCountyMunicipality?: Maybe<Scalars["String"]>;
  ContactDistrict?: Maybe<Scalars["String"]>;
  ContactEmail?: Maybe<Scalars["String"]>;
  ContactLandline?: Maybe<Scalars["String"]>;
  ContactMobile1?: Maybe<Scalars["String"]>;
  ContactMobile2?: Maybe<Scalars["String"]>;
  ContactParishWard?: Maybe<Scalars["String"]>;
  ContactSubcountryDivision?: Maybe<Scalars["String"]>;
  ContactVillageCellZone?: Maybe<Scalars["String"]>;
  CordinateX?: Maybe<Scalars["String"]>;
  CordinateY?: Maybe<Scalars["String"]>;
  DataDate?: Maybe<Scalars["timestamptz"]>;
  Division?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Grade?: Maybe<Scalars["float8"]>;
  Gv?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationMaritalCertificate?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfCard?: Maybe<Scalars["float8"]>;
  IdentificationPassport?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  NameContact?: Maybe<Scalars["String"]>;
  OtherBoundaryFencespecify?: Maybe<Scalars["String"]>;
  OwnerCoin?: Maybe<Scalars["String"]>;
  OwnerFirstname?: Maybe<Scalars["String"]>;
  OwnerIndividualCompany?: Maybe<Scalars["String"]>;
  OwnerLegalSurnameMaiden?: Maybe<Scalars["String"]>;
  OwnerMiddlename?: Maybe<Scalars["String"]>;
  OwnerOthernames?: Maybe<Scalars["String"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["bigint"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParticularsAccess?: Maybe<Scalars["String"]>;
  ParticularsBlockName?: Maybe<Scalars["String"]>;
  ParticularsBlockNumber?: Maybe<Scalars["String"]>;
  ParticularsFolioNumber?: Maybe<Scalars["String"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["float8"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["float8"]>;
  ParticularsHouseNumber?: Maybe<Scalars["String"]>;
  ParticularsIsTitled?: Maybe<Scalars["String"]>;
  ParticularsLandReference?: Maybe<Scalars["String"]>;
  ParticularsNeighborhood?: Maybe<Scalars["String"]>;
  ParticularsPlotNumber?: Maybe<Scalars["String"]>;
  ParticularsPlotShape?: Maybe<Scalars["String"]>;
  ParticularsPropertyName?: Maybe<Scalars["String"]>;
  ParticularsRoadStreet?: Maybe<Scalars["String"]>;
  ParticularsTopographyOthers?: Maybe<Scalars["String"]>;
  ParticularsTopograpy?: Maybe<Scalars["String"]>;
  ParticularsTypeOfLandInterest?: Maybe<Scalars["String"]>;
  ParticularsVolume?: Maybe<Scalars["String"]>;
  PropertyOwnerOccupiedOrRented?: Maybe<Scalars["String"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  Pvillage?: Maybe<Scalars["String"]>;
  Reference?: Maybe<Scalars["float8"]>;
  ReferenceNumberNew?: Maybe<Scalars["float8"]>;
  RoadsOtherspecify?: Maybe<Scalars["String"]>;
  Rv?: Maybe<Scalars["float8"]>;
  SiteworksBoundaryFence?: Maybe<Scalars["String"]>;
  SiteworksBuildingNumber?: Maybe<Scalars["String"]>;
  SiteworksCompound?: Maybe<Scalars["String"]>;
  SiteworksCourt?: Maybe<Scalars["String"]>;
  SiteworksGate?: Maybe<Scalars["String"]>;
  SiteworksOtherFacilities?: Maybe<Scalars["String"]>;
  SiteworksOthers?: Maybe<Scalars["String"]>;
  SiteworksSwimmingPool?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_RawProperties1_Max_Fields = {
  __typename?: "rtcs_db_kcca_RawProperties1_max_fields";
  AccomodationBathroom?: Maybe<Scalars["float8"]>;
  AccomodationBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["String"]>;
  AccomodationComment?: Maybe<Scalars["String"]>;
  AccomodationDiningRooms?: Maybe<Scalars["float8"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["float8"]>;
  AccomodationEnviromentalNoise?: Maybe<Scalars["String"]>;
  AccomodationGarage?: Maybe<Scalars["float8"]>;
  AccomodationKitchen?: Maybe<Scalars["float8"]>;
  AccomodationLaundry?: Maybe<Scalars["float8"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  AccomodationSecurity?: Maybe<Scalars["String"]>;
  AccomodationShops?: Maybe<Scalars["float8"]>;
  AccomodationShower?: Maybe<Scalars["float8"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["float8"]>;
  AccomodationStore?: Maybe<Scalars["float8"]>;
  AccomodationStudyRoom?: Maybe<Scalars["float8"]>;
  AccomodationVerandah?: Maybe<Scalars["float8"]>;
  AccomodationWc?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroom?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationWcShower?: Maybe<Scalars["float8"]>;
  AlternateEmail?: Maybe<Scalars["String"]>;
  AlternateFirstname?: Maybe<Scalars["String"]>;
  AlternateLandlineWithCode?: Maybe<Scalars["String"]>;
  AlternateMiddlename?: Maybe<Scalars["String"]>;
  AlternateMobile1?: Maybe<Scalars["String"]>;
  AlternateMobile2?: Maybe<Scalars["float8"]>;
  AlternateSurname?: Maybe<Scalars["String"]>;
  AlternateTittle?: Maybe<Scalars["String"]>;
  AlternativeDrivingPermit?: Maybe<Scalars["String"]>;
  AlternativeFinancialCard?: Maybe<Scalars["String"]>;
  AlternativeNationalId?: Maybe<Scalars["String"]>;
  AlternativeNssfCard?: Maybe<Scalars["float8"]>;
  AlternativePassport?: Maybe<Scalars["String"]>;
  AlternativeVillageId?: Maybe<Scalars["String"]>;
  BuildingBlockNumber?: Maybe<Scalars["String"]>;
  BuildingCondition?: Maybe<Scalars["String"]>;
  BuildingFlatNumber?: Maybe<Scalars["String"]>;
  BuildingFloorNumber?: Maybe<Scalars["float8"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BuildingNumber?: Maybe<Scalars["String"]>;
  BuildingOtherServices?: Maybe<Scalars["String"]>;
  BuildingParkingSlots?: Maybe<Scalars["String"]>;
  BuildingParkingSpaceAvailable?: Maybe<Scalars["String"]>;
  BuildingParkingSpaceSufficient?: Maybe<Scalars["String"]>;
  BuildingPowerSupply?: Maybe<Scalars["String"]>;
  BuildingRent?: Maybe<Scalars["String"]>;
  BuildingSanitationType?: Maybe<Scalars["String"]>;
  BuildingType?: Maybe<Scalars["String"]>;
  BuildingVerticalCirculation?: Maybe<Scalars["String"]>;
  BuildingWaterAccessibility?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  ConstructionCeiling?: Maybe<Scalars["String"]>;
  ConstructionDoorOthers?: Maybe<Scalars["String"]>;
  ConstructionDoors?: Maybe<Scalars["String"]>;
  ConstructionFloorsFinish?: Maybe<Scalars["String"]>;
  ConstructionFoorsConstruction?: Maybe<Scalars["String"]>;
  ConstructionOtherDetails?: Maybe<Scalars["String"]>;
  ConstructionRoofCovering?: Maybe<Scalars["String"]>;
  ConstructionWallFinish?: Maybe<Scalars["String"]>;
  ConstructionWalls?: Maybe<Scalars["String"]>;
  ConstructionWindowOthers?: Maybe<Scalars["String"]>;
  ConstructionWindows?: Maybe<Scalars["String"]>;
  ContactAddress?: Maybe<Scalars["String"]>;
  ContactCountry?: Maybe<Scalars["String"]>;
  ContactCountyMunicipality?: Maybe<Scalars["String"]>;
  ContactDistrict?: Maybe<Scalars["String"]>;
  ContactEmail?: Maybe<Scalars["String"]>;
  ContactLandline?: Maybe<Scalars["String"]>;
  ContactMobile1?: Maybe<Scalars["String"]>;
  ContactMobile2?: Maybe<Scalars["String"]>;
  ContactParishWard?: Maybe<Scalars["String"]>;
  ContactSubcountryDivision?: Maybe<Scalars["String"]>;
  ContactVillageCellZone?: Maybe<Scalars["String"]>;
  CordinateX?: Maybe<Scalars["String"]>;
  CordinateY?: Maybe<Scalars["String"]>;
  DataDate?: Maybe<Scalars["timestamptz"]>;
  Division?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Grade?: Maybe<Scalars["float8"]>;
  Gv?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationMaritalCertificate?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfCard?: Maybe<Scalars["float8"]>;
  IdentificationPassport?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  NameContact?: Maybe<Scalars["String"]>;
  OtherBoundaryFencespecify?: Maybe<Scalars["String"]>;
  OwnerCoin?: Maybe<Scalars["String"]>;
  OwnerFirstname?: Maybe<Scalars["String"]>;
  OwnerIndividualCompany?: Maybe<Scalars["String"]>;
  OwnerLegalSurnameMaiden?: Maybe<Scalars["String"]>;
  OwnerMiddlename?: Maybe<Scalars["String"]>;
  OwnerOthernames?: Maybe<Scalars["String"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["bigint"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParticularsAccess?: Maybe<Scalars["String"]>;
  ParticularsBlockName?: Maybe<Scalars["String"]>;
  ParticularsBlockNumber?: Maybe<Scalars["String"]>;
  ParticularsFolioNumber?: Maybe<Scalars["String"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["float8"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["float8"]>;
  ParticularsHouseNumber?: Maybe<Scalars["String"]>;
  ParticularsIsTitled?: Maybe<Scalars["String"]>;
  ParticularsLandReference?: Maybe<Scalars["String"]>;
  ParticularsNeighborhood?: Maybe<Scalars["String"]>;
  ParticularsPlotNumber?: Maybe<Scalars["String"]>;
  ParticularsPlotShape?: Maybe<Scalars["String"]>;
  ParticularsPropertyName?: Maybe<Scalars["String"]>;
  ParticularsRoadStreet?: Maybe<Scalars["String"]>;
  ParticularsTopographyOthers?: Maybe<Scalars["String"]>;
  ParticularsTopograpy?: Maybe<Scalars["String"]>;
  ParticularsTypeOfLandInterest?: Maybe<Scalars["String"]>;
  ParticularsVolume?: Maybe<Scalars["String"]>;
  PropertyOwnerOccupiedOrRented?: Maybe<Scalars["String"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  Pvillage?: Maybe<Scalars["String"]>;
  Reference?: Maybe<Scalars["float8"]>;
  ReferenceNumberNew?: Maybe<Scalars["float8"]>;
  RoadsOtherspecify?: Maybe<Scalars["String"]>;
  Rv?: Maybe<Scalars["float8"]>;
  SiteworksBoundaryFence?: Maybe<Scalars["String"]>;
  SiteworksBuildingNumber?: Maybe<Scalars["String"]>;
  SiteworksCompound?: Maybe<Scalars["String"]>;
  SiteworksCourt?: Maybe<Scalars["String"]>;
  SiteworksGate?: Maybe<Scalars["String"]>;
  SiteworksOtherFacilities?: Maybe<Scalars["String"]>;
  SiteworksOthers?: Maybe<Scalars["String"]>;
  SiteworksSwimmingPool?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_RawProperties1" */
export type Rtcs_Db_Kcca_RawProperties1_Max_Order_By = {
  AccomodationBathroom?: Maybe<Order_By>;
  AccomodationBathroomShower?: Maybe<Order_By>;
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AccomodationComment?: Maybe<Order_By>;
  AccomodationDiningRooms?: Maybe<Order_By>;
  AccomodationEntertainmentRoom?: Maybe<Order_By>;
  AccomodationEnviromentalNoise?: Maybe<Order_By>;
  AccomodationGarage?: Maybe<Order_By>;
  AccomodationKitchen?: Maybe<Order_By>;
  AccomodationLaundry?: Maybe<Order_By>;
  AccomodationOthers?: Maybe<Order_By>;
  AccomodationSecurity?: Maybe<Order_By>;
  AccomodationShops?: Maybe<Order_By>;
  AccomodationShower?: Maybe<Order_By>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Order_By>;
  AccomodationStore?: Maybe<Order_By>;
  AccomodationStudyRoom?: Maybe<Order_By>;
  AccomodationVerandah?: Maybe<Order_By>;
  AccomodationWc?: Maybe<Order_By>;
  AccomodationWcBathroom?: Maybe<Order_By>;
  AccomodationWcBathroomShower?: Maybe<Order_By>;
  AccomodationWcShower?: Maybe<Order_By>;
  AlternateEmail?: Maybe<Order_By>;
  AlternateFirstname?: Maybe<Order_By>;
  AlternateLandlineWithCode?: Maybe<Order_By>;
  AlternateMiddlename?: Maybe<Order_By>;
  AlternateMobile1?: Maybe<Order_By>;
  AlternateMobile2?: Maybe<Order_By>;
  AlternateSurname?: Maybe<Order_By>;
  AlternateTittle?: Maybe<Order_By>;
  AlternativeDrivingPermit?: Maybe<Order_By>;
  AlternativeFinancialCard?: Maybe<Order_By>;
  AlternativeNationalId?: Maybe<Order_By>;
  AlternativeNssfCard?: Maybe<Order_By>;
  AlternativePassport?: Maybe<Order_By>;
  AlternativeVillageId?: Maybe<Order_By>;
  BuildingBlockNumber?: Maybe<Order_By>;
  BuildingCondition?: Maybe<Order_By>;
  BuildingFlatNumber?: Maybe<Order_By>;
  BuildingFloorNumber?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BuildingNumber?: Maybe<Order_By>;
  BuildingOtherServices?: Maybe<Order_By>;
  BuildingParkingSlots?: Maybe<Order_By>;
  BuildingParkingSpaceAvailable?: Maybe<Order_By>;
  BuildingParkingSpaceSufficient?: Maybe<Order_By>;
  BuildingPowerSupply?: Maybe<Order_By>;
  BuildingRent?: Maybe<Order_By>;
  BuildingSanitationType?: Maybe<Order_By>;
  BuildingType?: Maybe<Order_By>;
  BuildingVerticalCirculation?: Maybe<Order_By>;
  BuildingWaterAccessibility?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  ConstructionCeiling?: Maybe<Order_By>;
  ConstructionDoorOthers?: Maybe<Order_By>;
  ConstructionDoors?: Maybe<Order_By>;
  ConstructionFloorsFinish?: Maybe<Order_By>;
  ConstructionFoorsConstruction?: Maybe<Order_By>;
  ConstructionOtherDetails?: Maybe<Order_By>;
  ConstructionRoofCovering?: Maybe<Order_By>;
  ConstructionWallFinish?: Maybe<Order_By>;
  ConstructionWalls?: Maybe<Order_By>;
  ConstructionWindowOthers?: Maybe<Order_By>;
  ConstructionWindows?: Maybe<Order_By>;
  ContactAddress?: Maybe<Order_By>;
  ContactCountry?: Maybe<Order_By>;
  ContactCountyMunicipality?: Maybe<Order_By>;
  ContactDistrict?: Maybe<Order_By>;
  ContactEmail?: Maybe<Order_By>;
  ContactLandline?: Maybe<Order_By>;
  ContactMobile1?: Maybe<Order_By>;
  ContactMobile2?: Maybe<Order_By>;
  ContactParishWard?: Maybe<Order_By>;
  ContactSubcountryDivision?: Maybe<Order_By>;
  ContactVillageCellZone?: Maybe<Order_By>;
  CordinateX?: Maybe<Order_By>;
  CordinateY?: Maybe<Order_By>;
  DataDate?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Grade?: Maybe<Order_By>;
  Gv?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationDrivingPermit?: Maybe<Order_By>;
  IdentificationMaritalCertificate?: Maybe<Order_By>;
  IdentificationNationalId?: Maybe<Order_By>;
  IdentificationNssfCard?: Maybe<Order_By>;
  IdentificationPassport?: Maybe<Order_By>;
  IdentificationVillageId?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NameContact?: Maybe<Order_By>;
  OtherBoundaryFencespecify?: Maybe<Order_By>;
  OwnerCoin?: Maybe<Order_By>;
  OwnerFirstname?: Maybe<Order_By>;
  OwnerIndividualCompany?: Maybe<Order_By>;
  OwnerLegalSurnameMaiden?: Maybe<Order_By>;
  OwnerMiddlename?: Maybe<Order_By>;
  OwnerOthernames?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  ParticularsAccess?: Maybe<Order_By>;
  ParticularsBlockName?: Maybe<Order_By>;
  ParticularsBlockNumber?: Maybe<Order_By>;
  ParticularsFolioNumber?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  ParticularsGpsEastingsUtm?: Maybe<Order_By>;
  ParticularsGpsNorthingsUtm?: Maybe<Order_By>;
  ParticularsHouseNumber?: Maybe<Order_By>;
  ParticularsIsTitled?: Maybe<Order_By>;
  ParticularsLandReference?: Maybe<Order_By>;
  ParticularsNeighborhood?: Maybe<Order_By>;
  ParticularsPlotNumber?: Maybe<Order_By>;
  ParticularsPlotShape?: Maybe<Order_By>;
  ParticularsPropertyName?: Maybe<Order_By>;
  ParticularsRoadStreet?: Maybe<Order_By>;
  ParticularsTopographyOthers?: Maybe<Order_By>;
  ParticularsTopograpy?: Maybe<Order_By>;
  ParticularsTypeOfLandInterest?: Maybe<Order_By>;
  ParticularsVolume?: Maybe<Order_By>;
  PropertyOwnerOccupiedOrRented?: Maybe<Order_By>;
  PropertySubType?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  Pvillage?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  ReferenceNumberNew?: Maybe<Order_By>;
  RoadsOtherspecify?: Maybe<Order_By>;
  Rv?: Maybe<Order_By>;
  SiteworksBoundaryFence?: Maybe<Order_By>;
  SiteworksBuildingNumber?: Maybe<Order_By>;
  SiteworksCompound?: Maybe<Order_By>;
  SiteworksCourt?: Maybe<Order_By>;
  SiteworksGate?: Maybe<Order_By>;
  SiteworksOtherFacilities?: Maybe<Order_By>;
  SiteworksOthers?: Maybe<Order_By>;
  SiteworksSwimmingPool?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_RawProperties1_Min_Fields = {
  __typename?: "rtcs_db_kcca_RawProperties1_min_fields";
  AccomodationBathroom?: Maybe<Scalars["float8"]>;
  AccomodationBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["String"]>;
  AccomodationComment?: Maybe<Scalars["String"]>;
  AccomodationDiningRooms?: Maybe<Scalars["float8"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["float8"]>;
  AccomodationEnviromentalNoise?: Maybe<Scalars["String"]>;
  AccomodationGarage?: Maybe<Scalars["float8"]>;
  AccomodationKitchen?: Maybe<Scalars["float8"]>;
  AccomodationLaundry?: Maybe<Scalars["float8"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  AccomodationSecurity?: Maybe<Scalars["String"]>;
  AccomodationShops?: Maybe<Scalars["float8"]>;
  AccomodationShower?: Maybe<Scalars["float8"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["float8"]>;
  AccomodationStore?: Maybe<Scalars["float8"]>;
  AccomodationStudyRoom?: Maybe<Scalars["float8"]>;
  AccomodationVerandah?: Maybe<Scalars["float8"]>;
  AccomodationWc?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroom?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationWcShower?: Maybe<Scalars["float8"]>;
  AlternateEmail?: Maybe<Scalars["String"]>;
  AlternateFirstname?: Maybe<Scalars["String"]>;
  AlternateLandlineWithCode?: Maybe<Scalars["String"]>;
  AlternateMiddlename?: Maybe<Scalars["String"]>;
  AlternateMobile1?: Maybe<Scalars["String"]>;
  AlternateMobile2?: Maybe<Scalars["float8"]>;
  AlternateSurname?: Maybe<Scalars["String"]>;
  AlternateTittle?: Maybe<Scalars["String"]>;
  AlternativeDrivingPermit?: Maybe<Scalars["String"]>;
  AlternativeFinancialCard?: Maybe<Scalars["String"]>;
  AlternativeNationalId?: Maybe<Scalars["String"]>;
  AlternativeNssfCard?: Maybe<Scalars["float8"]>;
  AlternativePassport?: Maybe<Scalars["String"]>;
  AlternativeVillageId?: Maybe<Scalars["String"]>;
  BuildingBlockNumber?: Maybe<Scalars["String"]>;
  BuildingCondition?: Maybe<Scalars["String"]>;
  BuildingFlatNumber?: Maybe<Scalars["String"]>;
  BuildingFloorNumber?: Maybe<Scalars["float8"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BuildingNumber?: Maybe<Scalars["String"]>;
  BuildingOtherServices?: Maybe<Scalars["String"]>;
  BuildingParkingSlots?: Maybe<Scalars["String"]>;
  BuildingParkingSpaceAvailable?: Maybe<Scalars["String"]>;
  BuildingParkingSpaceSufficient?: Maybe<Scalars["String"]>;
  BuildingPowerSupply?: Maybe<Scalars["String"]>;
  BuildingRent?: Maybe<Scalars["String"]>;
  BuildingSanitationType?: Maybe<Scalars["String"]>;
  BuildingType?: Maybe<Scalars["String"]>;
  BuildingVerticalCirculation?: Maybe<Scalars["String"]>;
  BuildingWaterAccessibility?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  ConstructionCeiling?: Maybe<Scalars["String"]>;
  ConstructionDoorOthers?: Maybe<Scalars["String"]>;
  ConstructionDoors?: Maybe<Scalars["String"]>;
  ConstructionFloorsFinish?: Maybe<Scalars["String"]>;
  ConstructionFoorsConstruction?: Maybe<Scalars["String"]>;
  ConstructionOtherDetails?: Maybe<Scalars["String"]>;
  ConstructionRoofCovering?: Maybe<Scalars["String"]>;
  ConstructionWallFinish?: Maybe<Scalars["String"]>;
  ConstructionWalls?: Maybe<Scalars["String"]>;
  ConstructionWindowOthers?: Maybe<Scalars["String"]>;
  ConstructionWindows?: Maybe<Scalars["String"]>;
  ContactAddress?: Maybe<Scalars["String"]>;
  ContactCountry?: Maybe<Scalars["String"]>;
  ContactCountyMunicipality?: Maybe<Scalars["String"]>;
  ContactDistrict?: Maybe<Scalars["String"]>;
  ContactEmail?: Maybe<Scalars["String"]>;
  ContactLandline?: Maybe<Scalars["String"]>;
  ContactMobile1?: Maybe<Scalars["String"]>;
  ContactMobile2?: Maybe<Scalars["String"]>;
  ContactParishWard?: Maybe<Scalars["String"]>;
  ContactSubcountryDivision?: Maybe<Scalars["String"]>;
  ContactVillageCellZone?: Maybe<Scalars["String"]>;
  CordinateX?: Maybe<Scalars["String"]>;
  CordinateY?: Maybe<Scalars["String"]>;
  DataDate?: Maybe<Scalars["timestamptz"]>;
  Division?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Grade?: Maybe<Scalars["float8"]>;
  Gv?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationMaritalCertificate?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfCard?: Maybe<Scalars["float8"]>;
  IdentificationPassport?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  NameContact?: Maybe<Scalars["String"]>;
  OtherBoundaryFencespecify?: Maybe<Scalars["String"]>;
  OwnerCoin?: Maybe<Scalars["String"]>;
  OwnerFirstname?: Maybe<Scalars["String"]>;
  OwnerIndividualCompany?: Maybe<Scalars["String"]>;
  OwnerLegalSurnameMaiden?: Maybe<Scalars["String"]>;
  OwnerMiddlename?: Maybe<Scalars["String"]>;
  OwnerOthernames?: Maybe<Scalars["String"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["bigint"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParticularsAccess?: Maybe<Scalars["String"]>;
  ParticularsBlockName?: Maybe<Scalars["String"]>;
  ParticularsBlockNumber?: Maybe<Scalars["String"]>;
  ParticularsFolioNumber?: Maybe<Scalars["String"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["float8"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["float8"]>;
  ParticularsHouseNumber?: Maybe<Scalars["String"]>;
  ParticularsIsTitled?: Maybe<Scalars["String"]>;
  ParticularsLandReference?: Maybe<Scalars["String"]>;
  ParticularsNeighborhood?: Maybe<Scalars["String"]>;
  ParticularsPlotNumber?: Maybe<Scalars["String"]>;
  ParticularsPlotShape?: Maybe<Scalars["String"]>;
  ParticularsPropertyName?: Maybe<Scalars["String"]>;
  ParticularsRoadStreet?: Maybe<Scalars["String"]>;
  ParticularsTopographyOthers?: Maybe<Scalars["String"]>;
  ParticularsTopograpy?: Maybe<Scalars["String"]>;
  ParticularsTypeOfLandInterest?: Maybe<Scalars["String"]>;
  ParticularsVolume?: Maybe<Scalars["String"]>;
  PropertyOwnerOccupiedOrRented?: Maybe<Scalars["String"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  Pvillage?: Maybe<Scalars["String"]>;
  Reference?: Maybe<Scalars["float8"]>;
  ReferenceNumberNew?: Maybe<Scalars["float8"]>;
  RoadsOtherspecify?: Maybe<Scalars["String"]>;
  Rv?: Maybe<Scalars["float8"]>;
  SiteworksBoundaryFence?: Maybe<Scalars["String"]>;
  SiteworksBuildingNumber?: Maybe<Scalars["String"]>;
  SiteworksCompound?: Maybe<Scalars["String"]>;
  SiteworksCourt?: Maybe<Scalars["String"]>;
  SiteworksGate?: Maybe<Scalars["String"]>;
  SiteworksOtherFacilities?: Maybe<Scalars["String"]>;
  SiteworksOthers?: Maybe<Scalars["String"]>;
  SiteworksSwimmingPool?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_RawProperties1" */
export type Rtcs_Db_Kcca_RawProperties1_Min_Order_By = {
  AccomodationBathroom?: Maybe<Order_By>;
  AccomodationBathroomShower?: Maybe<Order_By>;
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AccomodationComment?: Maybe<Order_By>;
  AccomodationDiningRooms?: Maybe<Order_By>;
  AccomodationEntertainmentRoom?: Maybe<Order_By>;
  AccomodationEnviromentalNoise?: Maybe<Order_By>;
  AccomodationGarage?: Maybe<Order_By>;
  AccomodationKitchen?: Maybe<Order_By>;
  AccomodationLaundry?: Maybe<Order_By>;
  AccomodationOthers?: Maybe<Order_By>;
  AccomodationSecurity?: Maybe<Order_By>;
  AccomodationShops?: Maybe<Order_By>;
  AccomodationShower?: Maybe<Order_By>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Order_By>;
  AccomodationStore?: Maybe<Order_By>;
  AccomodationStudyRoom?: Maybe<Order_By>;
  AccomodationVerandah?: Maybe<Order_By>;
  AccomodationWc?: Maybe<Order_By>;
  AccomodationWcBathroom?: Maybe<Order_By>;
  AccomodationWcBathroomShower?: Maybe<Order_By>;
  AccomodationWcShower?: Maybe<Order_By>;
  AlternateEmail?: Maybe<Order_By>;
  AlternateFirstname?: Maybe<Order_By>;
  AlternateLandlineWithCode?: Maybe<Order_By>;
  AlternateMiddlename?: Maybe<Order_By>;
  AlternateMobile1?: Maybe<Order_By>;
  AlternateMobile2?: Maybe<Order_By>;
  AlternateSurname?: Maybe<Order_By>;
  AlternateTittle?: Maybe<Order_By>;
  AlternativeDrivingPermit?: Maybe<Order_By>;
  AlternativeFinancialCard?: Maybe<Order_By>;
  AlternativeNationalId?: Maybe<Order_By>;
  AlternativeNssfCard?: Maybe<Order_By>;
  AlternativePassport?: Maybe<Order_By>;
  AlternativeVillageId?: Maybe<Order_By>;
  BuildingBlockNumber?: Maybe<Order_By>;
  BuildingCondition?: Maybe<Order_By>;
  BuildingFlatNumber?: Maybe<Order_By>;
  BuildingFloorNumber?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BuildingNumber?: Maybe<Order_By>;
  BuildingOtherServices?: Maybe<Order_By>;
  BuildingParkingSlots?: Maybe<Order_By>;
  BuildingParkingSpaceAvailable?: Maybe<Order_By>;
  BuildingParkingSpaceSufficient?: Maybe<Order_By>;
  BuildingPowerSupply?: Maybe<Order_By>;
  BuildingRent?: Maybe<Order_By>;
  BuildingSanitationType?: Maybe<Order_By>;
  BuildingType?: Maybe<Order_By>;
  BuildingVerticalCirculation?: Maybe<Order_By>;
  BuildingWaterAccessibility?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  ConstructionCeiling?: Maybe<Order_By>;
  ConstructionDoorOthers?: Maybe<Order_By>;
  ConstructionDoors?: Maybe<Order_By>;
  ConstructionFloorsFinish?: Maybe<Order_By>;
  ConstructionFoorsConstruction?: Maybe<Order_By>;
  ConstructionOtherDetails?: Maybe<Order_By>;
  ConstructionRoofCovering?: Maybe<Order_By>;
  ConstructionWallFinish?: Maybe<Order_By>;
  ConstructionWalls?: Maybe<Order_By>;
  ConstructionWindowOthers?: Maybe<Order_By>;
  ConstructionWindows?: Maybe<Order_By>;
  ContactAddress?: Maybe<Order_By>;
  ContactCountry?: Maybe<Order_By>;
  ContactCountyMunicipality?: Maybe<Order_By>;
  ContactDistrict?: Maybe<Order_By>;
  ContactEmail?: Maybe<Order_By>;
  ContactLandline?: Maybe<Order_By>;
  ContactMobile1?: Maybe<Order_By>;
  ContactMobile2?: Maybe<Order_By>;
  ContactParishWard?: Maybe<Order_By>;
  ContactSubcountryDivision?: Maybe<Order_By>;
  ContactVillageCellZone?: Maybe<Order_By>;
  CordinateX?: Maybe<Order_By>;
  CordinateY?: Maybe<Order_By>;
  DataDate?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Grade?: Maybe<Order_By>;
  Gv?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationDrivingPermit?: Maybe<Order_By>;
  IdentificationMaritalCertificate?: Maybe<Order_By>;
  IdentificationNationalId?: Maybe<Order_By>;
  IdentificationNssfCard?: Maybe<Order_By>;
  IdentificationPassport?: Maybe<Order_By>;
  IdentificationVillageId?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NameContact?: Maybe<Order_By>;
  OtherBoundaryFencespecify?: Maybe<Order_By>;
  OwnerCoin?: Maybe<Order_By>;
  OwnerFirstname?: Maybe<Order_By>;
  OwnerIndividualCompany?: Maybe<Order_By>;
  OwnerLegalSurnameMaiden?: Maybe<Order_By>;
  OwnerMiddlename?: Maybe<Order_By>;
  OwnerOthernames?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  ParticularsAccess?: Maybe<Order_By>;
  ParticularsBlockName?: Maybe<Order_By>;
  ParticularsBlockNumber?: Maybe<Order_By>;
  ParticularsFolioNumber?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  ParticularsGpsEastingsUtm?: Maybe<Order_By>;
  ParticularsGpsNorthingsUtm?: Maybe<Order_By>;
  ParticularsHouseNumber?: Maybe<Order_By>;
  ParticularsIsTitled?: Maybe<Order_By>;
  ParticularsLandReference?: Maybe<Order_By>;
  ParticularsNeighborhood?: Maybe<Order_By>;
  ParticularsPlotNumber?: Maybe<Order_By>;
  ParticularsPlotShape?: Maybe<Order_By>;
  ParticularsPropertyName?: Maybe<Order_By>;
  ParticularsRoadStreet?: Maybe<Order_By>;
  ParticularsTopographyOthers?: Maybe<Order_By>;
  ParticularsTopograpy?: Maybe<Order_By>;
  ParticularsTypeOfLandInterest?: Maybe<Order_By>;
  ParticularsVolume?: Maybe<Order_By>;
  PropertyOwnerOccupiedOrRented?: Maybe<Order_By>;
  PropertySubType?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  Pvillage?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  ReferenceNumberNew?: Maybe<Order_By>;
  RoadsOtherspecify?: Maybe<Order_By>;
  Rv?: Maybe<Order_By>;
  SiteworksBoundaryFence?: Maybe<Order_By>;
  SiteworksBuildingNumber?: Maybe<Order_By>;
  SiteworksCompound?: Maybe<Order_By>;
  SiteworksCourt?: Maybe<Order_By>;
  SiteworksGate?: Maybe<Order_By>;
  SiteworksOtherFacilities?: Maybe<Order_By>;
  SiteworksOthers?: Maybe<Order_By>;
  SiteworksSwimmingPool?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_RawProperties1" */
export type Rtcs_Db_Kcca_RawProperties1_Mutation_Response = {
  __typename?: "rtcs_db_kcca_RawProperties1_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_RawProperties1>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_RawProperties1" */
export type Rtcs_Db_Kcca_RawProperties1_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_RawProperties1_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_RawProperties1" */
export type Rtcs_Db_Kcca_RawProperties1_Order_By = {
  AccomodationBathroom?: Maybe<Order_By>;
  AccomodationBathroomShower?: Maybe<Order_By>;
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AccomodationComment?: Maybe<Order_By>;
  AccomodationDiningRooms?: Maybe<Order_By>;
  AccomodationEntertainmentRoom?: Maybe<Order_By>;
  AccomodationEnviromentalNoise?: Maybe<Order_By>;
  AccomodationGarage?: Maybe<Order_By>;
  AccomodationKitchen?: Maybe<Order_By>;
  AccomodationLaundry?: Maybe<Order_By>;
  AccomodationOthers?: Maybe<Order_By>;
  AccomodationSecurity?: Maybe<Order_By>;
  AccomodationShops?: Maybe<Order_By>;
  AccomodationShower?: Maybe<Order_By>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Order_By>;
  AccomodationStore?: Maybe<Order_By>;
  AccomodationStudyRoom?: Maybe<Order_By>;
  AccomodationVerandah?: Maybe<Order_By>;
  AccomodationWc?: Maybe<Order_By>;
  AccomodationWcBathroom?: Maybe<Order_By>;
  AccomodationWcBathroomShower?: Maybe<Order_By>;
  AccomodationWcShower?: Maybe<Order_By>;
  AlternateEmail?: Maybe<Order_By>;
  AlternateFirstname?: Maybe<Order_By>;
  AlternateLandlineWithCode?: Maybe<Order_By>;
  AlternateMiddlename?: Maybe<Order_By>;
  AlternateMobile1?: Maybe<Order_By>;
  AlternateMobile2?: Maybe<Order_By>;
  AlternateSurname?: Maybe<Order_By>;
  AlternateTittle?: Maybe<Order_By>;
  AlternativeDrivingPermit?: Maybe<Order_By>;
  AlternativeFinancialCard?: Maybe<Order_By>;
  AlternativeNationalId?: Maybe<Order_By>;
  AlternativeNssfCard?: Maybe<Order_By>;
  AlternativePassport?: Maybe<Order_By>;
  AlternativeVillageId?: Maybe<Order_By>;
  BuildingBlockNumber?: Maybe<Order_By>;
  BuildingCondition?: Maybe<Order_By>;
  BuildingFlatNumber?: Maybe<Order_By>;
  BuildingFloorNumber?: Maybe<Order_By>;
  BuildingName?: Maybe<Order_By>;
  BuildingNumber?: Maybe<Order_By>;
  BuildingOtherServices?: Maybe<Order_By>;
  BuildingParkingSlots?: Maybe<Order_By>;
  BuildingParkingSpaceAvailable?: Maybe<Order_By>;
  BuildingParkingSpaceSufficient?: Maybe<Order_By>;
  BuildingPowerSupply?: Maybe<Order_By>;
  BuildingRent?: Maybe<Order_By>;
  BuildingSanitationType?: Maybe<Order_By>;
  BuildingType?: Maybe<Order_By>;
  BuildingVerticalCirculation?: Maybe<Order_By>;
  BuildingWaterAccessibility?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  ConstructionCeiling?: Maybe<Order_By>;
  ConstructionDoorOthers?: Maybe<Order_By>;
  ConstructionDoors?: Maybe<Order_By>;
  ConstructionFloorsFinish?: Maybe<Order_By>;
  ConstructionFoorsConstruction?: Maybe<Order_By>;
  ConstructionOtherDetails?: Maybe<Order_By>;
  ConstructionRoofCovering?: Maybe<Order_By>;
  ConstructionWallFinish?: Maybe<Order_By>;
  ConstructionWalls?: Maybe<Order_By>;
  ConstructionWindowOthers?: Maybe<Order_By>;
  ConstructionWindows?: Maybe<Order_By>;
  ContactAddress?: Maybe<Order_By>;
  ContactCountry?: Maybe<Order_By>;
  ContactCountyMunicipality?: Maybe<Order_By>;
  ContactDistrict?: Maybe<Order_By>;
  ContactEmail?: Maybe<Order_By>;
  ContactLandline?: Maybe<Order_By>;
  ContactMobile1?: Maybe<Order_By>;
  ContactMobile2?: Maybe<Order_By>;
  ContactParishWard?: Maybe<Order_By>;
  ContactSubcountryDivision?: Maybe<Order_By>;
  ContactVillageCellZone?: Maybe<Order_By>;
  CordinateX?: Maybe<Order_By>;
  CordinateY?: Maybe<Order_By>;
  DataDate?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Grade?: Maybe<Order_By>;
  Gv?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IdentificationDrivingPermit?: Maybe<Order_By>;
  IdentificationMaritalCertificate?: Maybe<Order_By>;
  IdentificationNationalId?: Maybe<Order_By>;
  IdentificationNssfCard?: Maybe<Order_By>;
  IdentificationPassport?: Maybe<Order_By>;
  IdentificationVillageId?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NameContact?: Maybe<Order_By>;
  OtherBoundaryFencespecify?: Maybe<Order_By>;
  OwnerCoin?: Maybe<Order_By>;
  OwnerFirstname?: Maybe<Order_By>;
  OwnerIndividualCompany?: Maybe<Order_By>;
  OwnerLegalSurnameMaiden?: Maybe<Order_By>;
  OwnerMiddlename?: Maybe<Order_By>;
  OwnerOthernames?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  ParticularsAccess?: Maybe<Order_By>;
  ParticularsBlockName?: Maybe<Order_By>;
  ParticularsBlockNumber?: Maybe<Order_By>;
  ParticularsFolioNumber?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  ParticularsGpsEastingsUtm?: Maybe<Order_By>;
  ParticularsGpsNorthingsUtm?: Maybe<Order_By>;
  ParticularsHouseNumber?: Maybe<Order_By>;
  ParticularsIsTitled?: Maybe<Order_By>;
  ParticularsLandReference?: Maybe<Order_By>;
  ParticularsNeighborhood?: Maybe<Order_By>;
  ParticularsPlotNumber?: Maybe<Order_By>;
  ParticularsPlotShape?: Maybe<Order_By>;
  ParticularsPropertyName?: Maybe<Order_By>;
  ParticularsRoadStreet?: Maybe<Order_By>;
  ParticularsTopographyOthers?: Maybe<Order_By>;
  ParticularsTopograpy?: Maybe<Order_By>;
  ParticularsTypeOfLandInterest?: Maybe<Order_By>;
  ParticularsVolume?: Maybe<Order_By>;
  PropertyOwnerOccupiedOrRented?: Maybe<Order_By>;
  PropertySubType?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  Pvillage?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  ReferenceNumberNew?: Maybe<Order_By>;
  RoadsOtherspecify?: Maybe<Order_By>;
  Rv?: Maybe<Order_By>;
  SiteworksBoundaryFence?: Maybe<Order_By>;
  SiteworksBuildingNumber?: Maybe<Order_By>;
  SiteworksCompound?: Maybe<Order_By>;
  SiteworksCourt?: Maybe<Order_By>;
  SiteworksGate?: Maybe<Order_By>;
  SiteworksOtherFacilities?: Maybe<Order_By>;
  SiteworksOthers?: Maybe<Order_By>;
  SiteworksSwimmingPool?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_RawProperties1" */
export enum Rtcs_Db_Kcca_RawProperties1_Select_Column {
  /** column name */
  AccomodationBathroom = "AccomodationBathroom",
  /** column name */
  AccomodationBathroomShower = "AccomodationBathroomShower",
  /** column name */
  AccomodationBedrooms = "AccomodationBedrooms",
  /** column name */
  AccomodationBuiltUpArea = "AccomodationBuiltUpArea",
  /** column name */
  AccomodationComment = "AccomodationComment",
  /** column name */
  AccomodationDiningRooms = "AccomodationDiningRooms",
  /** column name */
  AccomodationEntertainmentRoom = "AccomodationEntertainmentRoom",
  /** column name */
  AccomodationEnviromentalNoise = "AccomodationEnviromentalNoise",
  /** column name */
  AccomodationGarage = "AccomodationGarage",
  /** column name */
  AccomodationKitchen = "AccomodationKitchen",
  /** column name */
  AccomodationLaundry = "AccomodationLaundry",
  /** column name */
  AccomodationOthers = "AccomodationOthers",
  /** column name */
  AccomodationSecurity = "AccomodationSecurity",
  /** column name */
  AccomodationShops = "AccomodationShops",
  /** column name */
  AccomodationShower = "AccomodationShower",
  /** column name */
  AccomodationSittingRoomLoungeNumber = "AccomodationSittingRoomLoungeNumber",
  /** column name */
  AccomodationStore = "AccomodationStore",
  /** column name */
  AccomodationStudyRoom = "AccomodationStudyRoom",
  /** column name */
  AccomodationVerandah = "AccomodationVerandah",
  /** column name */
  AccomodationWc = "AccomodationWc",
  /** column name */
  AccomodationWcBathroom = "AccomodationWcBathroom",
  /** column name */
  AccomodationWcBathroomShower = "AccomodationWcBathroomShower",
  /** column name */
  AccomodationWcShower = "AccomodationWcShower",
  /** column name */
  AlternateEmail = "AlternateEmail",
  /** column name */
  AlternateFirstname = "AlternateFirstname",
  /** column name */
  AlternateLandlineWithCode = "AlternateLandlineWithCode",
  /** column name */
  AlternateMiddlename = "AlternateMiddlename",
  /** column name */
  AlternateMobile1 = "AlternateMobile1",
  /** column name */
  AlternateMobile2 = "AlternateMobile2",
  /** column name */
  AlternateSurname = "AlternateSurname",
  /** column name */
  AlternateTittle = "AlternateTittle",
  /** column name */
  AlternativeDrivingPermit = "AlternativeDrivingPermit",
  /** column name */
  AlternativeFinancialCard = "AlternativeFinancialCard",
  /** column name */
  AlternativeNationalId = "AlternativeNationalId",
  /** column name */
  AlternativeNssfCard = "AlternativeNssfCard",
  /** column name */
  AlternativePassport = "AlternativePassport",
  /** column name */
  AlternativeVillageId = "AlternativeVillageId",
  /** column name */
  BuildingBlockNumber = "BuildingBlockNumber",
  /** column name */
  BuildingCondition = "BuildingCondition",
  /** column name */
  BuildingFlatNumber = "BuildingFlatNumber",
  /** column name */
  BuildingFloorNumber = "BuildingFloorNumber",
  /** column name */
  BuildingName = "BuildingName",
  /** column name */
  BuildingNumber = "BuildingNumber",
  /** column name */
  BuildingOtherServices = "BuildingOtherServices",
  /** column name */
  BuildingParkingSlots = "BuildingParkingSlots",
  /** column name */
  BuildingParkingSpaceAvailable = "BuildingParkingSpaceAvailable",
  /** column name */
  BuildingParkingSpaceSufficient = "BuildingParkingSpaceSufficient",
  /** column name */
  BuildingPowerSupply = "BuildingPowerSupply",
  /** column name */
  BuildingRent = "BuildingRent",
  /** column name */
  BuildingSanitationType = "BuildingSanitationType",
  /** column name */
  BuildingType = "BuildingType",
  /** column name */
  BuildingVerticalCirculation = "BuildingVerticalCirculation",
  /** column name */
  BuildingWaterAccessibility = "BuildingWaterAccessibility",
  /** column name */
  Coin = "Coin",
  /** column name */
  ConstructionCeiling = "ConstructionCeiling",
  /** column name */
  ConstructionDoorOthers = "ConstructionDoorOthers",
  /** column name */
  ConstructionDoors = "ConstructionDoors",
  /** column name */
  ConstructionFloorsFinish = "ConstructionFloorsFinish",
  /** column name */
  ConstructionFoorsConstruction = "ConstructionFoorsConstruction",
  /** column name */
  ConstructionOtherDetails = "ConstructionOtherDetails",
  /** column name */
  ConstructionRoofCovering = "ConstructionRoofCovering",
  /** column name */
  ConstructionWallFinish = "ConstructionWallFinish",
  /** column name */
  ConstructionWalls = "ConstructionWalls",
  /** column name */
  ConstructionWindowOthers = "ConstructionWindowOthers",
  /** column name */
  ConstructionWindows = "ConstructionWindows",
  /** column name */
  ContactAddress = "ContactAddress",
  /** column name */
  ContactCountry = "ContactCountry",
  /** column name */
  ContactCountyMunicipality = "ContactCountyMunicipality",
  /** column name */
  ContactDistrict = "ContactDistrict",
  /** column name */
  ContactEmail = "ContactEmail",
  /** column name */
  ContactLandline = "ContactLandline",
  /** column name */
  ContactMobile1 = "ContactMobile1",
  /** column name */
  ContactMobile2 = "ContactMobile2",
  /** column name */
  ContactParishWard = "ContactParishWard",
  /** column name */
  ContactSubcountryDivision = "ContactSubcountryDivision",
  /** column name */
  ContactVillageCellZone = "ContactVillageCellZone",
  /** column name */
  CordinateX = "CordinateX",
  /** column name */
  CordinateY = "CordinateY",
  /** column name */
  DataDate = "DataDate",
  /** column name */
  Division = "Division",
  /** column name */
  File = "File",
  /** column name */
  Grade = "Grade",
  /** column name */
  Gv = "Gv",
  /** column name */
  Id = "Id",
  /** column name */
  IdentificationDrivingPermit = "IdentificationDrivingPermit",
  /** column name */
  IdentificationMaritalCertificate = "IdentificationMaritalCertificate",
  /** column name */
  IdentificationNationalId = "IdentificationNationalId",
  /** column name */
  IdentificationNssfCard = "IdentificationNssfCard",
  /** column name */
  IdentificationPassport = "IdentificationPassport",
  /** column name */
  IdentificationVillageId = "IdentificationVillageId",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  NameContact = "NameContact",
  /** column name */
  OtherBoundaryFencespecify = "OtherBoundaryFencespecify",
  /** column name */
  OwnerCoin = "OwnerCoin",
  /** column name */
  OwnerFirstname = "OwnerFirstname",
  /** column name */
  OwnerIndividualCompany = "OwnerIndividualCompany",
  /** column name */
  OwnerLegalSurnameMaiden = "OwnerLegalSurnameMaiden",
  /** column name */
  OwnerMiddlename = "OwnerMiddlename",
  /** column name */
  OwnerOthernames = "OwnerOthernames",
  /** column name */
  OwnerTin = "OwnerTin",
  /** column name */
  OwnerTitle = "OwnerTitle",
  /** column name */
  Parish = "Parish",
  /** column name */
  ParticularsAccess = "ParticularsAccess",
  /** column name */
  ParticularsBlockName = "ParticularsBlockName",
  /** column name */
  ParticularsBlockNumber = "ParticularsBlockNumber",
  /** column name */
  ParticularsFolioNumber = "ParticularsFolioNumber",
  /** column name */
  ParticularsFrontages = "ParticularsFrontages",
  /** column name */
  ParticularsGpsEastingsUtm = "ParticularsGpsEastingsUtm",
  /** column name */
  ParticularsGpsNorthingsUtm = "ParticularsGpsNorthingsUtm",
  /** column name */
  ParticularsHouseNumber = "ParticularsHouseNumber",
  /** column name */
  ParticularsIsTitled = "ParticularsIsTitled",
  /** column name */
  ParticularsLandReference = "ParticularsLandReference",
  /** column name */
  ParticularsNeighborhood = "ParticularsNeighborhood",
  /** column name */
  ParticularsPlotNumber = "ParticularsPlotNumber",
  /** column name */
  ParticularsPlotShape = "ParticularsPlotShape",
  /** column name */
  ParticularsPropertyName = "ParticularsPropertyName",
  /** column name */
  ParticularsRoadStreet = "ParticularsRoadStreet",
  /** column name */
  ParticularsTopographyOthers = "ParticularsTopographyOthers",
  /** column name */
  ParticularsTopograpy = "ParticularsTopograpy",
  /** column name */
  ParticularsTypeOfLandInterest = "ParticularsTypeOfLandInterest",
  /** column name */
  ParticularsVolume = "ParticularsVolume",
  /** column name */
  PropertyOwnerOccupiedOrRented = "PropertyOwnerOccupiedOrRented",
  /** column name */
  PropertySubType = "PropertySubType",
  /** column name */
  PropertyType = "PropertyType",
  /** column name */
  Pvillage = "Pvillage",
  /** column name */
  Reference = "Reference",
  /** column name */
  ReferenceNumberNew = "ReferenceNumberNew",
  /** column name */
  RoadsOtherspecify = "RoadsOtherspecify",
  /** column name */
  Rv = "Rv",
  /** column name */
  SiteworksBoundaryFence = "SiteworksBoundaryFence",
  /** column name */
  SiteworksBuildingNumber = "SiteworksBuildingNumber",
  /** column name */
  SiteworksCompound = "SiteworksCompound",
  /** column name */
  SiteworksCourt = "SiteworksCourt",
  /** column name */
  SiteworksGate = "SiteworksGate",
  /** column name */
  SiteworksOtherFacilities = "SiteworksOtherFacilities",
  /** column name */
  SiteworksOthers = "SiteworksOthers",
  /** column name */
  SiteworksSwimmingPool = "SiteworksSwimmingPool",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_RawProperties1" */
export type Rtcs_Db_Kcca_RawProperties1_Set_Input = {
  AccomodationBathroom?: Maybe<Scalars["float8"]>;
  AccomodationBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["String"]>;
  AccomodationComment?: Maybe<Scalars["String"]>;
  AccomodationDiningRooms?: Maybe<Scalars["float8"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["float8"]>;
  AccomodationEnviromentalNoise?: Maybe<Scalars["String"]>;
  AccomodationGarage?: Maybe<Scalars["float8"]>;
  AccomodationKitchen?: Maybe<Scalars["float8"]>;
  AccomodationLaundry?: Maybe<Scalars["float8"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  AccomodationSecurity?: Maybe<Scalars["String"]>;
  AccomodationShops?: Maybe<Scalars["float8"]>;
  AccomodationShower?: Maybe<Scalars["float8"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["float8"]>;
  AccomodationStore?: Maybe<Scalars["float8"]>;
  AccomodationStudyRoom?: Maybe<Scalars["float8"]>;
  AccomodationVerandah?: Maybe<Scalars["float8"]>;
  AccomodationWc?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroom?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationWcShower?: Maybe<Scalars["float8"]>;
  AlternateEmail?: Maybe<Scalars["String"]>;
  AlternateFirstname?: Maybe<Scalars["String"]>;
  AlternateLandlineWithCode?: Maybe<Scalars["String"]>;
  AlternateMiddlename?: Maybe<Scalars["String"]>;
  AlternateMobile1?: Maybe<Scalars["String"]>;
  AlternateMobile2?: Maybe<Scalars["float8"]>;
  AlternateSurname?: Maybe<Scalars["String"]>;
  AlternateTittle?: Maybe<Scalars["String"]>;
  AlternativeDrivingPermit?: Maybe<Scalars["String"]>;
  AlternativeFinancialCard?: Maybe<Scalars["String"]>;
  AlternativeNationalId?: Maybe<Scalars["String"]>;
  AlternativeNssfCard?: Maybe<Scalars["float8"]>;
  AlternativePassport?: Maybe<Scalars["String"]>;
  AlternativeVillageId?: Maybe<Scalars["String"]>;
  BuildingBlockNumber?: Maybe<Scalars["String"]>;
  BuildingCondition?: Maybe<Scalars["String"]>;
  BuildingFlatNumber?: Maybe<Scalars["String"]>;
  BuildingFloorNumber?: Maybe<Scalars["float8"]>;
  BuildingName?: Maybe<Scalars["String"]>;
  BuildingNumber?: Maybe<Scalars["String"]>;
  BuildingOtherServices?: Maybe<Scalars["String"]>;
  BuildingParkingSlots?: Maybe<Scalars["String"]>;
  BuildingParkingSpaceAvailable?: Maybe<Scalars["String"]>;
  BuildingParkingSpaceSufficient?: Maybe<Scalars["String"]>;
  BuildingPowerSupply?: Maybe<Scalars["String"]>;
  BuildingRent?: Maybe<Scalars["String"]>;
  BuildingSanitationType?: Maybe<Scalars["String"]>;
  BuildingType?: Maybe<Scalars["String"]>;
  BuildingVerticalCirculation?: Maybe<Scalars["String"]>;
  BuildingWaterAccessibility?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["float8"]>;
  ConstructionCeiling?: Maybe<Scalars["String"]>;
  ConstructionDoorOthers?: Maybe<Scalars["String"]>;
  ConstructionDoors?: Maybe<Scalars["String"]>;
  ConstructionFloorsFinish?: Maybe<Scalars["String"]>;
  ConstructionFoorsConstruction?: Maybe<Scalars["String"]>;
  ConstructionOtherDetails?: Maybe<Scalars["String"]>;
  ConstructionRoofCovering?: Maybe<Scalars["String"]>;
  ConstructionWallFinish?: Maybe<Scalars["String"]>;
  ConstructionWalls?: Maybe<Scalars["String"]>;
  ConstructionWindowOthers?: Maybe<Scalars["String"]>;
  ConstructionWindows?: Maybe<Scalars["String"]>;
  ContactAddress?: Maybe<Scalars["String"]>;
  ContactCountry?: Maybe<Scalars["String"]>;
  ContactCountyMunicipality?: Maybe<Scalars["String"]>;
  ContactDistrict?: Maybe<Scalars["String"]>;
  ContactEmail?: Maybe<Scalars["String"]>;
  ContactLandline?: Maybe<Scalars["String"]>;
  ContactMobile1?: Maybe<Scalars["String"]>;
  ContactMobile2?: Maybe<Scalars["String"]>;
  ContactParishWard?: Maybe<Scalars["String"]>;
  ContactSubcountryDivision?: Maybe<Scalars["String"]>;
  ContactVillageCellZone?: Maybe<Scalars["String"]>;
  CordinateX?: Maybe<Scalars["String"]>;
  CordinateY?: Maybe<Scalars["String"]>;
  DataDate?: Maybe<Scalars["timestamptz"]>;
  Division?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Grade?: Maybe<Scalars["float8"]>;
  Gv?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  IdentificationDrivingPermit?: Maybe<Scalars["String"]>;
  IdentificationMaritalCertificate?: Maybe<Scalars["String"]>;
  IdentificationNationalId?: Maybe<Scalars["String"]>;
  IdentificationNssfCard?: Maybe<Scalars["float8"]>;
  IdentificationPassport?: Maybe<Scalars["String"]>;
  IdentificationVillageId?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  NameContact?: Maybe<Scalars["String"]>;
  OtherBoundaryFencespecify?: Maybe<Scalars["String"]>;
  OwnerCoin?: Maybe<Scalars["String"]>;
  OwnerFirstname?: Maybe<Scalars["String"]>;
  OwnerIndividualCompany?: Maybe<Scalars["String"]>;
  OwnerLegalSurnameMaiden?: Maybe<Scalars["String"]>;
  OwnerMiddlename?: Maybe<Scalars["String"]>;
  OwnerOthernames?: Maybe<Scalars["String"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["bigint"]>;
  Parish?: Maybe<Scalars["String"]>;
  ParticularsAccess?: Maybe<Scalars["String"]>;
  ParticularsBlockName?: Maybe<Scalars["String"]>;
  ParticularsBlockNumber?: Maybe<Scalars["String"]>;
  ParticularsFolioNumber?: Maybe<Scalars["String"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["float8"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["float8"]>;
  ParticularsHouseNumber?: Maybe<Scalars["String"]>;
  ParticularsIsTitled?: Maybe<Scalars["String"]>;
  ParticularsLandReference?: Maybe<Scalars["String"]>;
  ParticularsNeighborhood?: Maybe<Scalars["String"]>;
  ParticularsPlotNumber?: Maybe<Scalars["String"]>;
  ParticularsPlotShape?: Maybe<Scalars["String"]>;
  ParticularsPropertyName?: Maybe<Scalars["String"]>;
  ParticularsRoadStreet?: Maybe<Scalars["String"]>;
  ParticularsTopographyOthers?: Maybe<Scalars["String"]>;
  ParticularsTopograpy?: Maybe<Scalars["String"]>;
  ParticularsTypeOfLandInterest?: Maybe<Scalars["String"]>;
  ParticularsVolume?: Maybe<Scalars["String"]>;
  PropertyOwnerOccupiedOrRented?: Maybe<Scalars["String"]>;
  PropertySubType?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  Pvillage?: Maybe<Scalars["String"]>;
  Reference?: Maybe<Scalars["float8"]>;
  ReferenceNumberNew?: Maybe<Scalars["float8"]>;
  RoadsOtherspecify?: Maybe<Scalars["String"]>;
  Rv?: Maybe<Scalars["float8"]>;
  SiteworksBoundaryFence?: Maybe<Scalars["String"]>;
  SiteworksBuildingNumber?: Maybe<Scalars["String"]>;
  SiteworksCompound?: Maybe<Scalars["String"]>;
  SiteworksCourt?: Maybe<Scalars["String"]>;
  SiteworksGate?: Maybe<Scalars["String"]>;
  SiteworksOtherFacilities?: Maybe<Scalars["String"]>;
  SiteworksOthers?: Maybe<Scalars["String"]>;
  SiteworksSwimmingPool?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_RawProperties1_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_RawProperties1_stddev_fields";
  AccomodationBathroom?: Maybe<Scalars["Float"]>;
  AccomodationBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationDiningRooms?: Maybe<Scalars["Float"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["Float"]>;
  AccomodationGarage?: Maybe<Scalars["Float"]>;
  AccomodationKitchen?: Maybe<Scalars["Float"]>;
  AccomodationLaundry?: Maybe<Scalars["Float"]>;
  AccomodationShops?: Maybe<Scalars["Float"]>;
  AccomodationShower?: Maybe<Scalars["Float"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["Float"]>;
  AccomodationStore?: Maybe<Scalars["Float"]>;
  AccomodationStudyRoom?: Maybe<Scalars["Float"]>;
  AccomodationVerandah?: Maybe<Scalars["Float"]>;
  AccomodationWc?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroom?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationWcShower?: Maybe<Scalars["Float"]>;
  AlternateMobile2?: Maybe<Scalars["Float"]>;
  AlternativeNssfCard?: Maybe<Scalars["Float"]>;
  BuildingFloorNumber?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  Grade?: Maybe<Scalars["Float"]>;
  IdentificationNssfCard?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["Float"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  ReferenceNumberNew?: Maybe<Scalars["Float"]>;
  Rv?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_RawProperties1" */
export type Rtcs_Db_Kcca_RawProperties1_Stddev_Order_By = {
  AccomodationBathroom?: Maybe<Order_By>;
  AccomodationBathroomShower?: Maybe<Order_By>;
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationDiningRooms?: Maybe<Order_By>;
  AccomodationEntertainmentRoom?: Maybe<Order_By>;
  AccomodationGarage?: Maybe<Order_By>;
  AccomodationKitchen?: Maybe<Order_By>;
  AccomodationLaundry?: Maybe<Order_By>;
  AccomodationShops?: Maybe<Order_By>;
  AccomodationShower?: Maybe<Order_By>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Order_By>;
  AccomodationStore?: Maybe<Order_By>;
  AccomodationStudyRoom?: Maybe<Order_By>;
  AccomodationVerandah?: Maybe<Order_By>;
  AccomodationWc?: Maybe<Order_By>;
  AccomodationWcBathroom?: Maybe<Order_By>;
  AccomodationWcBathroomShower?: Maybe<Order_By>;
  AccomodationWcShower?: Maybe<Order_By>;
  AlternateMobile2?: Maybe<Order_By>;
  AlternativeNssfCard?: Maybe<Order_By>;
  BuildingFloorNumber?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  Grade?: Maybe<Order_By>;
  IdentificationNssfCard?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParticularsGpsEastingsUtm?: Maybe<Order_By>;
  ParticularsGpsNorthingsUtm?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  ReferenceNumberNew?: Maybe<Order_By>;
  Rv?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_RawProperties1_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_RawProperties1_stddev_pop_fields";
  AccomodationBathroom?: Maybe<Scalars["Float"]>;
  AccomodationBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationDiningRooms?: Maybe<Scalars["Float"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["Float"]>;
  AccomodationGarage?: Maybe<Scalars["Float"]>;
  AccomodationKitchen?: Maybe<Scalars["Float"]>;
  AccomodationLaundry?: Maybe<Scalars["Float"]>;
  AccomodationShops?: Maybe<Scalars["Float"]>;
  AccomodationShower?: Maybe<Scalars["Float"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["Float"]>;
  AccomodationStore?: Maybe<Scalars["Float"]>;
  AccomodationStudyRoom?: Maybe<Scalars["Float"]>;
  AccomodationVerandah?: Maybe<Scalars["Float"]>;
  AccomodationWc?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroom?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationWcShower?: Maybe<Scalars["Float"]>;
  AlternateMobile2?: Maybe<Scalars["Float"]>;
  AlternativeNssfCard?: Maybe<Scalars["Float"]>;
  BuildingFloorNumber?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  Grade?: Maybe<Scalars["Float"]>;
  IdentificationNssfCard?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["Float"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  ReferenceNumberNew?: Maybe<Scalars["Float"]>;
  Rv?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_RawProperties1" */
export type Rtcs_Db_Kcca_RawProperties1_Stddev_Pop_Order_By = {
  AccomodationBathroom?: Maybe<Order_By>;
  AccomodationBathroomShower?: Maybe<Order_By>;
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationDiningRooms?: Maybe<Order_By>;
  AccomodationEntertainmentRoom?: Maybe<Order_By>;
  AccomodationGarage?: Maybe<Order_By>;
  AccomodationKitchen?: Maybe<Order_By>;
  AccomodationLaundry?: Maybe<Order_By>;
  AccomodationShops?: Maybe<Order_By>;
  AccomodationShower?: Maybe<Order_By>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Order_By>;
  AccomodationStore?: Maybe<Order_By>;
  AccomodationStudyRoom?: Maybe<Order_By>;
  AccomodationVerandah?: Maybe<Order_By>;
  AccomodationWc?: Maybe<Order_By>;
  AccomodationWcBathroom?: Maybe<Order_By>;
  AccomodationWcBathroomShower?: Maybe<Order_By>;
  AccomodationWcShower?: Maybe<Order_By>;
  AlternateMobile2?: Maybe<Order_By>;
  AlternativeNssfCard?: Maybe<Order_By>;
  BuildingFloorNumber?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  Grade?: Maybe<Order_By>;
  IdentificationNssfCard?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParticularsGpsEastingsUtm?: Maybe<Order_By>;
  ParticularsGpsNorthingsUtm?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  ReferenceNumberNew?: Maybe<Order_By>;
  Rv?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_RawProperties1_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_RawProperties1_stddev_samp_fields";
  AccomodationBathroom?: Maybe<Scalars["Float"]>;
  AccomodationBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationDiningRooms?: Maybe<Scalars["Float"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["Float"]>;
  AccomodationGarage?: Maybe<Scalars["Float"]>;
  AccomodationKitchen?: Maybe<Scalars["Float"]>;
  AccomodationLaundry?: Maybe<Scalars["Float"]>;
  AccomodationShops?: Maybe<Scalars["Float"]>;
  AccomodationShower?: Maybe<Scalars["Float"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["Float"]>;
  AccomodationStore?: Maybe<Scalars["Float"]>;
  AccomodationStudyRoom?: Maybe<Scalars["Float"]>;
  AccomodationVerandah?: Maybe<Scalars["Float"]>;
  AccomodationWc?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroom?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationWcShower?: Maybe<Scalars["Float"]>;
  AlternateMobile2?: Maybe<Scalars["Float"]>;
  AlternativeNssfCard?: Maybe<Scalars["Float"]>;
  BuildingFloorNumber?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  Grade?: Maybe<Scalars["Float"]>;
  IdentificationNssfCard?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["Float"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  ReferenceNumberNew?: Maybe<Scalars["Float"]>;
  Rv?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_RawProperties1" */
export type Rtcs_Db_Kcca_RawProperties1_Stddev_Samp_Order_By = {
  AccomodationBathroom?: Maybe<Order_By>;
  AccomodationBathroomShower?: Maybe<Order_By>;
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationDiningRooms?: Maybe<Order_By>;
  AccomodationEntertainmentRoom?: Maybe<Order_By>;
  AccomodationGarage?: Maybe<Order_By>;
  AccomodationKitchen?: Maybe<Order_By>;
  AccomodationLaundry?: Maybe<Order_By>;
  AccomodationShops?: Maybe<Order_By>;
  AccomodationShower?: Maybe<Order_By>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Order_By>;
  AccomodationStore?: Maybe<Order_By>;
  AccomodationStudyRoom?: Maybe<Order_By>;
  AccomodationVerandah?: Maybe<Order_By>;
  AccomodationWc?: Maybe<Order_By>;
  AccomodationWcBathroom?: Maybe<Order_By>;
  AccomodationWcBathroomShower?: Maybe<Order_By>;
  AccomodationWcShower?: Maybe<Order_By>;
  AlternateMobile2?: Maybe<Order_By>;
  AlternativeNssfCard?: Maybe<Order_By>;
  BuildingFloorNumber?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  Grade?: Maybe<Order_By>;
  IdentificationNssfCard?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParticularsGpsEastingsUtm?: Maybe<Order_By>;
  ParticularsGpsNorthingsUtm?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  ReferenceNumberNew?: Maybe<Order_By>;
  Rv?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_RawProperties1_Sum_Fields = {
  __typename?: "rtcs_db_kcca_RawProperties1_sum_fields";
  AccomodationBathroom?: Maybe<Scalars["float8"]>;
  AccomodationBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationBedrooms?: Maybe<Scalars["float8"]>;
  AccomodationDiningRooms?: Maybe<Scalars["float8"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["float8"]>;
  AccomodationGarage?: Maybe<Scalars["float8"]>;
  AccomodationKitchen?: Maybe<Scalars["float8"]>;
  AccomodationLaundry?: Maybe<Scalars["float8"]>;
  AccomodationShops?: Maybe<Scalars["float8"]>;
  AccomodationShower?: Maybe<Scalars["float8"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["float8"]>;
  AccomodationStore?: Maybe<Scalars["float8"]>;
  AccomodationStudyRoom?: Maybe<Scalars["float8"]>;
  AccomodationVerandah?: Maybe<Scalars["float8"]>;
  AccomodationWc?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroom?: Maybe<Scalars["float8"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["float8"]>;
  AccomodationWcShower?: Maybe<Scalars["float8"]>;
  AlternateMobile2?: Maybe<Scalars["float8"]>;
  AlternativeNssfCard?: Maybe<Scalars["float8"]>;
  BuildingFloorNumber?: Maybe<Scalars["float8"]>;
  Coin?: Maybe<Scalars["float8"]>;
  Grade?: Maybe<Scalars["float8"]>;
  IdentificationNssfCard?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  OwnerTin?: Maybe<Scalars["float8"]>;
  OwnerTitle?: Maybe<Scalars["bigint"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["float8"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["float8"]>;
  Reference?: Maybe<Scalars["float8"]>;
  ReferenceNumberNew?: Maybe<Scalars["float8"]>;
  Rv?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_RawProperties1" */
export type Rtcs_Db_Kcca_RawProperties1_Sum_Order_By = {
  AccomodationBathroom?: Maybe<Order_By>;
  AccomodationBathroomShower?: Maybe<Order_By>;
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationDiningRooms?: Maybe<Order_By>;
  AccomodationEntertainmentRoom?: Maybe<Order_By>;
  AccomodationGarage?: Maybe<Order_By>;
  AccomodationKitchen?: Maybe<Order_By>;
  AccomodationLaundry?: Maybe<Order_By>;
  AccomodationShops?: Maybe<Order_By>;
  AccomodationShower?: Maybe<Order_By>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Order_By>;
  AccomodationStore?: Maybe<Order_By>;
  AccomodationStudyRoom?: Maybe<Order_By>;
  AccomodationVerandah?: Maybe<Order_By>;
  AccomodationWc?: Maybe<Order_By>;
  AccomodationWcBathroom?: Maybe<Order_By>;
  AccomodationWcBathroomShower?: Maybe<Order_By>;
  AccomodationWcShower?: Maybe<Order_By>;
  AlternateMobile2?: Maybe<Order_By>;
  AlternativeNssfCard?: Maybe<Order_By>;
  BuildingFloorNumber?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  Grade?: Maybe<Order_By>;
  IdentificationNssfCard?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParticularsGpsEastingsUtm?: Maybe<Order_By>;
  ParticularsGpsNorthingsUtm?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  ReferenceNumberNew?: Maybe<Order_By>;
  Rv?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_RawProperties1_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_RawProperties1_var_pop_fields";
  AccomodationBathroom?: Maybe<Scalars["Float"]>;
  AccomodationBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationDiningRooms?: Maybe<Scalars["Float"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["Float"]>;
  AccomodationGarage?: Maybe<Scalars["Float"]>;
  AccomodationKitchen?: Maybe<Scalars["Float"]>;
  AccomodationLaundry?: Maybe<Scalars["Float"]>;
  AccomodationShops?: Maybe<Scalars["Float"]>;
  AccomodationShower?: Maybe<Scalars["Float"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["Float"]>;
  AccomodationStore?: Maybe<Scalars["Float"]>;
  AccomodationStudyRoom?: Maybe<Scalars["Float"]>;
  AccomodationVerandah?: Maybe<Scalars["Float"]>;
  AccomodationWc?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroom?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationWcShower?: Maybe<Scalars["Float"]>;
  AlternateMobile2?: Maybe<Scalars["Float"]>;
  AlternativeNssfCard?: Maybe<Scalars["Float"]>;
  BuildingFloorNumber?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  Grade?: Maybe<Scalars["Float"]>;
  IdentificationNssfCard?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["Float"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  ReferenceNumberNew?: Maybe<Scalars["Float"]>;
  Rv?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_RawProperties1" */
export type Rtcs_Db_Kcca_RawProperties1_Var_Pop_Order_By = {
  AccomodationBathroom?: Maybe<Order_By>;
  AccomodationBathroomShower?: Maybe<Order_By>;
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationDiningRooms?: Maybe<Order_By>;
  AccomodationEntertainmentRoom?: Maybe<Order_By>;
  AccomodationGarage?: Maybe<Order_By>;
  AccomodationKitchen?: Maybe<Order_By>;
  AccomodationLaundry?: Maybe<Order_By>;
  AccomodationShops?: Maybe<Order_By>;
  AccomodationShower?: Maybe<Order_By>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Order_By>;
  AccomodationStore?: Maybe<Order_By>;
  AccomodationStudyRoom?: Maybe<Order_By>;
  AccomodationVerandah?: Maybe<Order_By>;
  AccomodationWc?: Maybe<Order_By>;
  AccomodationWcBathroom?: Maybe<Order_By>;
  AccomodationWcBathroomShower?: Maybe<Order_By>;
  AccomodationWcShower?: Maybe<Order_By>;
  AlternateMobile2?: Maybe<Order_By>;
  AlternativeNssfCard?: Maybe<Order_By>;
  BuildingFloorNumber?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  Grade?: Maybe<Order_By>;
  IdentificationNssfCard?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParticularsGpsEastingsUtm?: Maybe<Order_By>;
  ParticularsGpsNorthingsUtm?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  ReferenceNumberNew?: Maybe<Order_By>;
  Rv?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_RawProperties1_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_RawProperties1_var_samp_fields";
  AccomodationBathroom?: Maybe<Scalars["Float"]>;
  AccomodationBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationDiningRooms?: Maybe<Scalars["Float"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["Float"]>;
  AccomodationGarage?: Maybe<Scalars["Float"]>;
  AccomodationKitchen?: Maybe<Scalars["Float"]>;
  AccomodationLaundry?: Maybe<Scalars["Float"]>;
  AccomodationShops?: Maybe<Scalars["Float"]>;
  AccomodationShower?: Maybe<Scalars["Float"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["Float"]>;
  AccomodationStore?: Maybe<Scalars["Float"]>;
  AccomodationStudyRoom?: Maybe<Scalars["Float"]>;
  AccomodationVerandah?: Maybe<Scalars["Float"]>;
  AccomodationWc?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroom?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationWcShower?: Maybe<Scalars["Float"]>;
  AlternateMobile2?: Maybe<Scalars["Float"]>;
  AlternativeNssfCard?: Maybe<Scalars["Float"]>;
  BuildingFloorNumber?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  Grade?: Maybe<Scalars["Float"]>;
  IdentificationNssfCard?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["Float"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  ReferenceNumberNew?: Maybe<Scalars["Float"]>;
  Rv?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_RawProperties1" */
export type Rtcs_Db_Kcca_RawProperties1_Var_Samp_Order_By = {
  AccomodationBathroom?: Maybe<Order_By>;
  AccomodationBathroomShower?: Maybe<Order_By>;
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationDiningRooms?: Maybe<Order_By>;
  AccomodationEntertainmentRoom?: Maybe<Order_By>;
  AccomodationGarage?: Maybe<Order_By>;
  AccomodationKitchen?: Maybe<Order_By>;
  AccomodationLaundry?: Maybe<Order_By>;
  AccomodationShops?: Maybe<Order_By>;
  AccomodationShower?: Maybe<Order_By>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Order_By>;
  AccomodationStore?: Maybe<Order_By>;
  AccomodationStudyRoom?: Maybe<Order_By>;
  AccomodationVerandah?: Maybe<Order_By>;
  AccomodationWc?: Maybe<Order_By>;
  AccomodationWcBathroom?: Maybe<Order_By>;
  AccomodationWcBathroomShower?: Maybe<Order_By>;
  AccomodationWcShower?: Maybe<Order_By>;
  AlternateMobile2?: Maybe<Order_By>;
  AlternativeNssfCard?: Maybe<Order_By>;
  BuildingFloorNumber?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  Grade?: Maybe<Order_By>;
  IdentificationNssfCard?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParticularsGpsEastingsUtm?: Maybe<Order_By>;
  ParticularsGpsNorthingsUtm?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  ReferenceNumberNew?: Maybe<Order_By>;
  Rv?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_RawProperties1_Variance_Fields = {
  __typename?: "rtcs_db_kcca_RawProperties1_variance_fields";
  AccomodationBathroom?: Maybe<Scalars["Float"]>;
  AccomodationBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationBedrooms?: Maybe<Scalars["Float"]>;
  AccomodationDiningRooms?: Maybe<Scalars["Float"]>;
  AccomodationEntertainmentRoom?: Maybe<Scalars["Float"]>;
  AccomodationGarage?: Maybe<Scalars["Float"]>;
  AccomodationKitchen?: Maybe<Scalars["Float"]>;
  AccomodationLaundry?: Maybe<Scalars["Float"]>;
  AccomodationShops?: Maybe<Scalars["Float"]>;
  AccomodationShower?: Maybe<Scalars["Float"]>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Scalars["Float"]>;
  AccomodationStore?: Maybe<Scalars["Float"]>;
  AccomodationStudyRoom?: Maybe<Scalars["Float"]>;
  AccomodationVerandah?: Maybe<Scalars["Float"]>;
  AccomodationWc?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroom?: Maybe<Scalars["Float"]>;
  AccomodationWcBathroomShower?: Maybe<Scalars["Float"]>;
  AccomodationWcShower?: Maybe<Scalars["Float"]>;
  AlternateMobile2?: Maybe<Scalars["Float"]>;
  AlternativeNssfCard?: Maybe<Scalars["Float"]>;
  BuildingFloorNumber?: Maybe<Scalars["Float"]>;
  Coin?: Maybe<Scalars["Float"]>;
  Grade?: Maybe<Scalars["Float"]>;
  IdentificationNssfCard?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OwnerTin?: Maybe<Scalars["Float"]>;
  OwnerTitle?: Maybe<Scalars["Float"]>;
  ParticularsGpsEastingsUtm?: Maybe<Scalars["Float"]>;
  ParticularsGpsNorthingsUtm?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  ReferenceNumberNew?: Maybe<Scalars["Float"]>;
  Rv?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_RawProperties1" */
export type Rtcs_Db_Kcca_RawProperties1_Variance_Order_By = {
  AccomodationBathroom?: Maybe<Order_By>;
  AccomodationBathroomShower?: Maybe<Order_By>;
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationDiningRooms?: Maybe<Order_By>;
  AccomodationEntertainmentRoom?: Maybe<Order_By>;
  AccomodationGarage?: Maybe<Order_By>;
  AccomodationKitchen?: Maybe<Order_By>;
  AccomodationLaundry?: Maybe<Order_By>;
  AccomodationShops?: Maybe<Order_By>;
  AccomodationShower?: Maybe<Order_By>;
  AccomodationSittingRoomLoungeNumber?: Maybe<Order_By>;
  AccomodationStore?: Maybe<Order_By>;
  AccomodationStudyRoom?: Maybe<Order_By>;
  AccomodationVerandah?: Maybe<Order_By>;
  AccomodationWc?: Maybe<Order_By>;
  AccomodationWcBathroom?: Maybe<Order_By>;
  AccomodationWcBathroomShower?: Maybe<Order_By>;
  AccomodationWcShower?: Maybe<Order_By>;
  AlternateMobile2?: Maybe<Order_By>;
  AlternativeNssfCard?: Maybe<Order_By>;
  BuildingFloorNumber?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  Grade?: Maybe<Order_By>;
  IdentificationNssfCard?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OwnerTin?: Maybe<Order_By>;
  OwnerTitle?: Maybe<Order_By>;
  ParticularsGpsEastingsUtm?: Maybe<Order_By>;
  ParticularsGpsNorthingsUtm?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  ReferenceNumberNew?: Maybe<Order_By>;
  Rv?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_RawProperties2" */
export type Rtcs_Db_Kcca_RawProperties2 = {
  __typename?: "rtcs_db_kcca_RawProperties2";
  Access?: Maybe<Scalars["String"]>;
  Agent?: Maybe<Scalars["String"]>;
  Alternative?: Maybe<Scalars["String"]>;
  Alternativeemailaddress?: Maybe<Scalars["String"]>;
  Alternativefirstname?: Maybe<Scalars["String"]>;
  Alternativelandlinenumber?: Maybe<Scalars["float8"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["float8"]>;
  Alternativeothername?: Maybe<Scalars["String"]>;
  Alternativephonenumber1?: Maybe<Scalars["float8"]>;
  Alternativephonenumber1code?: Maybe<Scalars["String"]>;
  Alternativephonenumber2?: Maybe<Scalars["String"]>;
  Alternativephonenumber2code?: Maybe<Scalars["String"]>;
  Alternativesurname?: Maybe<Scalars["String"]>;
  Altphn1?: Maybe<Scalars["float8"]>;
  Altphn2?: Maybe<Scalars["float8"]>;
  Anyother?: Maybe<Scalars["String"]>;
  AreaCount?: Maybe<Scalars["String"]>;
  Authorizedcontactperson?: Maybe<Scalars["String"]>;
  Bldg2?: Maybe<Scalars["String"]>;
  Blockname?: Maybe<Scalars["String"]>;
  Blocknumber?: Maybe<Scalars["String"]>;
  Blockother?: Maybe<Scalars["String"]>;
  Boundary?: Maybe<Scalars["String"]>;
  Boundaryfenceother?: Maybe<Scalars["String"]>;
  Buildingcondition?: Maybe<Scalars["String"]>;
  Buildingname?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["bigint"]>;
  CamvSerial?: Maybe<Scalars["String"]>;
  Ceiling?: Maybe<Scalars["String"]>;
  Ceilingothers?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["String"]>;
  Coin1?: Maybe<Scalars["String"]>;
  Comment?: Maybe<Scalars["String"]>;
  Compound?: Maybe<Scalars["String"]>;
  Compoundother?: Maybe<Scalars["String"]>;
  Contactdetailsofdirector?: Maybe<Scalars["String"]>;
  Country?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  County1?: Maybe<Scalars["String"]>;
  Creationdate?: Maybe<Scalars["timestamptz"]>;
  Creator?: Maybe<Scalars["String"]>;
  Ctrycde?: Maybe<Scalars["String"]>;
  Ctrycde1?: Maybe<Scalars["String"]>;
  Ctrycde2?: Maybe<Scalars["String"]>;
  Ctrycde4?: Maybe<Scalars["String"]>;
  Datacollector?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  District1?: Maybe<Scalars["String"]>;
  Districtorcitywhereboxislocated?: Maybe<Scalars["String"]>;
  Districtorcitywhereboxislocated1?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Doors?: Maybe<Scalars["String"]>;
  Doorsothers?: Maybe<Scalars["String"]>;
  Editdate?: Maybe<Scalars["timestamptz"]>;
  Editor?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Email1?: Maybe<Scalars["String"]>;
  Email3?: Maybe<Scalars["String"]>;
  Email5?: Maybe<Scalars["String"]>;
  Entitytype?: Maybe<Scalars["String"]>;
  Environment?: Maybe<Scalars["String"]>;
  Facilities?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Firstname3?: Maybe<Scalars["String"]>;
  Flatblocknumber?: Maybe<Scalars["String"]>;
  Flatnumber?: Maybe<Scalars["String"]>;
  Floorconstruction?: Maybe<Scalars["String"]>;
  Floorconstructionothers?: Maybe<Scalars["String"]>;
  Floorfinish?: Maybe<Scalars["String"]>;
  Floorfinishothers?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Games?: Maybe<Scalars["String"]>;
  Gamesother?: Maybe<Scalars["String"]>;
  Gate?: Maybe<Scalars["String"]>;
  Gateother?: Maybe<Scalars["String"]>;
  Globalid?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Hsenbr?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Id2?: Maybe<Scalars["String"]>;
  Id3?: Maybe<Scalars["bigint"]>;
  Land4?: Maybe<Scalars["float8"]>;
  Landline?: Maybe<Scalars["String"]>;
  Landline1?: Maybe<Scalars["String"]>;
  Landline4?: Maybe<Scalars["String"]>;
  Landlinecode?: Maybe<Scalars["String"]>;
  Landlinecode4?: Maybe<Scalars["String"]>;
  Landregister?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  LatitudeA?: Maybe<Scalars["float8"]>;
  Latlong?: Maybe<Scalars["String"]>;
  Legalname?: Maybe<Scalars["String"]>;
  Levelofcompletion?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Lln?: Maybe<Scalars["String"]>;
  Lln1?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  LongitudeB?: Maybe<Scalars["float8"]>;
  Lvls?: Maybe<Scalars["String"]>;
  Mainbusinessname?: Maybe<Scalars["String"]>;
  Mob?: Maybe<Scalars["float8"]>;
  Mob1?: Maybe<Scalars["float8"]>;
  Mob3?: Maybe<Scalars["String"]>;
  Mob4?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  Mobile1?: Maybe<Scalars["String"]>;
  Mobile1code?: Maybe<Scalars["String"]>;
  Mobile3?: Maybe<Scalars["String"]>;
  Mobile3code?: Maybe<Scalars["String"]>;
  Mobile4?: Maybe<Scalars["String"]>;
  Mobile4code?: Maybe<Scalars["String"]>;
  Mobile5?: Maybe<Scalars["String"]>;
  Mobile5code?: Maybe<Scalars["String"]>;
  Mobilecode?: Maybe<Scalars["String"]>;
  Name2?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["float8"]>;
  Nationalid2?: Maybe<Scalars["float8"]>;
  Nbrofslots?: Maybe<Scalars["String"]>;
  Neighbourhoodactivities?: Maybe<Scalars["String"]>;
  Neighbourhoodstatus?: Maybe<Scalars["String"]>;
  Nin?: Maybe<Scalars["String"]>;
  Nin2?: Maybe<Scalars["String"]>;
  Objectid?: Maybe<Scalars["float8"]>;
  Other?: Maybe<Scalars["String"]>;
  Otherfacilities?: Maybe<Scalars["String"]>;
  Otherid?: Maybe<Scalars["String"]>;
  Otherid1?: Maybe<Scalars["String"]>;
  Otherid2?: Maybe<Scalars["String"]>;
  Otherid3?: Maybe<Scalars["float8"]>;
  Otherid4?: Maybe<Scalars["String"]>;
  Otherid4nbr?: Maybe<Scalars["String"]>;
  Otherid6?: Maybe<Scalars["String"]>;
  Otherid7?: Maybe<Scalars["float8"]>;
  Othernames?: Maybe<Scalars["String"]>;
  Othernames3?: Maybe<Scalars["String"]>;
  Otherservices?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Parish1?: Maybe<Scalars["String"]>;
  Parish2?: Maybe<Scalars["String"]>;
  Parking?: Maybe<Scalars["String"]>;
  Parkingsufficient?: Maybe<Scalars["String"]>;
  Partiiindividualownershipdetails?: Maybe<Scalars["String"]>;
  Partinonindividualownershipdetails?: Maybe<Scalars["String"]>;
  Pass?: Maybe<Scalars["float8"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Postalofficeboxnumber?: Maybe<Scalars["String"]>;
  Postalofficeboxnumber1?: Maybe<Scalars["String"]>;
  Powersupply?: Maybe<Scalars["String"]>;
  Propertyname?: Maybe<Scalars["String"]>;
  Propertysubtype?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Reference?: Maybe<Scalars["bigint"]>;
  Relationshiptoowner?: Maybe<Scalars["String"]>;
  Roadfrontageorgrid?: Maybe<Scalars["String"]>;
  Roadname?: Maybe<Scalars["String"]>;
  Roofcovering?: Maybe<Scalars["String"]>;
  Roofcoveringothers?: Maybe<Scalars["String"]>;
  Sanitation?: Maybe<Scalars["String"]>;
  Sectionapropertyownersdetails?: Maybe<Scalars["String"]>;
  Security?: Maybe<Scalars["String"]>;
  Shape?: Maybe<Scalars["String"]>;
  Shapeofplot?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Subcounty1?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Surname3?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Title1?: Maybe<Scalars["String"]>;
  Topography?: Maybe<Scalars["String"]>;
  TotalBuiltUpArea?: Maybe<Scalars["float8"]>;
  TotalGrossArea?: Maybe<Scalars["float8"]>;
  Typeofaccess?: Maybe<Scalars["String"]>;
  Typeofbuilding?: Maybe<Scalars["String"]>;
  Typeofcompany?: Maybe<Scalars["String"]>;
  Typeoflandinterest?: Maybe<Scalars["String"]>;
  Username?: Maybe<Scalars["float8"]>;
  Verticalcirculation?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  Village1?: Maybe<Scalars["String"]>;
  Village2?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  Wallfinish?: Maybe<Scalars["String"]>;
  Wallfinishothers?: Maybe<Scalars["String"]>;
  Walls?: Maybe<Scalars["String"]>;
  Wallsothers?: Maybe<Scalars["String"]>;
  Wateraccessibility?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Windows?: Maybe<Scalars["String"]>;
  Windowsothers?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_RawProperties2" */
export type Rtcs_Db_Kcca_RawProperties2_Aggregate = {
  __typename?: "rtcs_db_kcca_RawProperties2_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_RawProperties2_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_RawProperties2>;
};

/** aggregate fields of "rtcs_db.kcca_RawProperties2" */
export type Rtcs_Db_Kcca_RawProperties2_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_RawProperties2_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_RawProperties2_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_RawProperties2_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_RawProperties2_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_RawProperties2_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_RawProperties2_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_RawProperties2_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_RawProperties2_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_RawProperties2_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_RawProperties2_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_RawProperties2_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_RawProperties2" */
export type Rtcs_Db_Kcca_RawProperties2_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_RawProperties2_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_RawProperties2" */
export type Rtcs_Db_Kcca_RawProperties2_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_RawProperties2_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_RawProperties2_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_RawProperties2_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_RawProperties2_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_RawProperties2_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_RawProperties2_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_RawProperties2_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_RawProperties2_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_RawProperties2_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_RawProperties2_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_RawProperties2" */
export type Rtcs_Db_Kcca_RawProperties2_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_RawProperties2_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_RawProperties2_Avg_Fields = {
  __typename?: "rtcs_db_kcca_RawProperties2_avg_fields";
  Alternativelandlinenumber?: Maybe<Scalars["Float"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["Float"]>;
  Alternativephonenumber1?: Maybe<Scalars["Float"]>;
  Altphn1?: Maybe<Scalars["Float"]>;
  Altphn2?: Maybe<Scalars["Float"]>;
  CamvId?: Maybe<Scalars["Float"]>;
  Id3?: Maybe<Scalars["Float"]>;
  Land4?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  LatitudeA?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LongitudeB?: Maybe<Scalars["Float"]>;
  Mob?: Maybe<Scalars["Float"]>;
  Mob1?: Maybe<Scalars["Float"]>;
  Nationalid?: Maybe<Scalars["Float"]>;
  Nationalid2?: Maybe<Scalars["Float"]>;
  Objectid?: Maybe<Scalars["Float"]>;
  Otherid3?: Maybe<Scalars["Float"]>;
  Otherid7?: Maybe<Scalars["Float"]>;
  Pass?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
  TotalBuiltUpArea?: Maybe<Scalars["Float"]>;
  TotalGrossArea?: Maybe<Scalars["Float"]>;
  Username?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_RawProperties2" */
export type Rtcs_Db_Kcca_RawProperties2_Avg_Order_By = {
  Alternativelandlinenumber?: Maybe<Order_By>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Order_By>;
  Alternativephonenumber1?: Maybe<Order_By>;
  Altphn1?: Maybe<Order_By>;
  Altphn2?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  Id3?: Maybe<Order_By>;
  Land4?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  LatitudeA?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeB?: Maybe<Order_By>;
  Mob?: Maybe<Order_By>;
  Mob1?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Nationalid2?: Maybe<Order_By>;
  Objectid?: Maybe<Order_By>;
  Otherid3?: Maybe<Order_By>;
  Otherid7?: Maybe<Order_By>;
  Pass?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TotalBuiltUpArea?: Maybe<Order_By>;
  TotalGrossArea?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_RawProperties2". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_RawProperties2_Bool_Exp = {
  Access?: Maybe<String_Comparison_Exp>;
  Agent?: Maybe<String_Comparison_Exp>;
  Alternative?: Maybe<String_Comparison_Exp>;
  Alternativeemailaddress?: Maybe<String_Comparison_Exp>;
  Alternativefirstname?: Maybe<String_Comparison_Exp>;
  Alternativelandlinenumber?: Maybe<Float8_Comparison_Exp>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Float8_Comparison_Exp>;
  Alternativeothername?: Maybe<String_Comparison_Exp>;
  Alternativephonenumber1?: Maybe<Float8_Comparison_Exp>;
  Alternativephonenumber1code?: Maybe<String_Comparison_Exp>;
  Alternativephonenumber2?: Maybe<String_Comparison_Exp>;
  Alternativephonenumber2code?: Maybe<String_Comparison_Exp>;
  Alternativesurname?: Maybe<String_Comparison_Exp>;
  Altphn1?: Maybe<Float8_Comparison_Exp>;
  Altphn2?: Maybe<Float8_Comparison_Exp>;
  Anyother?: Maybe<String_Comparison_Exp>;
  AreaCount?: Maybe<String_Comparison_Exp>;
  Authorizedcontactperson?: Maybe<String_Comparison_Exp>;
  Bldg2?: Maybe<String_Comparison_Exp>;
  Blockname?: Maybe<String_Comparison_Exp>;
  Blocknumber?: Maybe<String_Comparison_Exp>;
  Blockother?: Maybe<String_Comparison_Exp>;
  Boundary?: Maybe<String_Comparison_Exp>;
  Boundaryfenceother?: Maybe<String_Comparison_Exp>;
  Buildingcondition?: Maybe<String_Comparison_Exp>;
  Buildingname?: Maybe<String_Comparison_Exp>;
  CamvId?: Maybe<Bigint_Comparison_Exp>;
  CamvSerial?: Maybe<String_Comparison_Exp>;
  Ceiling?: Maybe<String_Comparison_Exp>;
  Ceilingothers?: Maybe<String_Comparison_Exp>;
  Coin?: Maybe<String_Comparison_Exp>;
  Coin1?: Maybe<String_Comparison_Exp>;
  Comment?: Maybe<String_Comparison_Exp>;
  Compound?: Maybe<String_Comparison_Exp>;
  Compoundother?: Maybe<String_Comparison_Exp>;
  Contactdetailsofdirector?: Maybe<String_Comparison_Exp>;
  Country?: Maybe<String_Comparison_Exp>;
  County?: Maybe<String_Comparison_Exp>;
  County1?: Maybe<String_Comparison_Exp>;
  Creationdate?: Maybe<Timestamptz_Comparison_Exp>;
  Creator?: Maybe<String_Comparison_Exp>;
  Ctrycde?: Maybe<String_Comparison_Exp>;
  Ctrycde1?: Maybe<String_Comparison_Exp>;
  Ctrycde2?: Maybe<String_Comparison_Exp>;
  Ctrycde4?: Maybe<String_Comparison_Exp>;
  Datacollector?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  District1?: Maybe<String_Comparison_Exp>;
  Districtorcitywhereboxislocated?: Maybe<String_Comparison_Exp>;
  Districtorcitywhereboxislocated1?: Maybe<String_Comparison_Exp>;
  Division?: Maybe<String_Comparison_Exp>;
  Doors?: Maybe<String_Comparison_Exp>;
  Doorsothers?: Maybe<String_Comparison_Exp>;
  Editdate?: Maybe<Timestamptz_Comparison_Exp>;
  Editor?: Maybe<String_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  Email1?: Maybe<String_Comparison_Exp>;
  Email3?: Maybe<String_Comparison_Exp>;
  Email5?: Maybe<String_Comparison_Exp>;
  Entitytype?: Maybe<String_Comparison_Exp>;
  Environment?: Maybe<String_Comparison_Exp>;
  Facilities?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Firstname3?: Maybe<String_Comparison_Exp>;
  Flatblocknumber?: Maybe<String_Comparison_Exp>;
  Flatnumber?: Maybe<String_Comparison_Exp>;
  Floorconstruction?: Maybe<String_Comparison_Exp>;
  Floorconstructionothers?: Maybe<String_Comparison_Exp>;
  Floorfinish?: Maybe<String_Comparison_Exp>;
  Floorfinishothers?: Maybe<String_Comparison_Exp>;
  Folio?: Maybe<String_Comparison_Exp>;
  Games?: Maybe<String_Comparison_Exp>;
  Gamesother?: Maybe<String_Comparison_Exp>;
  Gate?: Maybe<String_Comparison_Exp>;
  Gateother?: Maybe<String_Comparison_Exp>;
  Globalid?: Maybe<String_Comparison_Exp>;
  Housenumber?: Maybe<String_Comparison_Exp>;
  Hsenbr?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  Id2?: Maybe<String_Comparison_Exp>;
  Id3?: Maybe<Bigint_Comparison_Exp>;
  Land4?: Maybe<Float8_Comparison_Exp>;
  Landline?: Maybe<String_Comparison_Exp>;
  Landline1?: Maybe<String_Comparison_Exp>;
  Landline4?: Maybe<String_Comparison_Exp>;
  Landlinecode?: Maybe<String_Comparison_Exp>;
  Landlinecode4?: Maybe<String_Comparison_Exp>;
  Landregister?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  LatitudeA?: Maybe<Float8_Comparison_Exp>;
  Latlong?: Maybe<String_Comparison_Exp>;
  Legalname?: Maybe<String_Comparison_Exp>;
  Levelofcompletion?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Lln?: Maybe<String_Comparison_Exp>;
  Lln1?: Maybe<String_Comparison_Exp>;
  Longitude?: Maybe<String_Comparison_Exp>;
  LongitudeB?: Maybe<Float8_Comparison_Exp>;
  Lvls?: Maybe<String_Comparison_Exp>;
  Mainbusinessname?: Maybe<String_Comparison_Exp>;
  Mob?: Maybe<Float8_Comparison_Exp>;
  Mob1?: Maybe<Float8_Comparison_Exp>;
  Mob3?: Maybe<String_Comparison_Exp>;
  Mob4?: Maybe<String_Comparison_Exp>;
  Mobile?: Maybe<String_Comparison_Exp>;
  Mobile1?: Maybe<String_Comparison_Exp>;
  Mobile1code?: Maybe<String_Comparison_Exp>;
  Mobile3?: Maybe<String_Comparison_Exp>;
  Mobile3code?: Maybe<String_Comparison_Exp>;
  Mobile4?: Maybe<String_Comparison_Exp>;
  Mobile4code?: Maybe<String_Comparison_Exp>;
  Mobile5?: Maybe<String_Comparison_Exp>;
  Mobile5code?: Maybe<String_Comparison_Exp>;
  Mobilecode?: Maybe<String_Comparison_Exp>;
  Name2?: Maybe<String_Comparison_Exp>;
  Nationalid?: Maybe<Float8_Comparison_Exp>;
  Nationalid2?: Maybe<Float8_Comparison_Exp>;
  Nbrofslots?: Maybe<String_Comparison_Exp>;
  Neighbourhoodactivities?: Maybe<String_Comparison_Exp>;
  Neighbourhoodstatus?: Maybe<String_Comparison_Exp>;
  Nin?: Maybe<String_Comparison_Exp>;
  Nin2?: Maybe<String_Comparison_Exp>;
  Objectid?: Maybe<Float8_Comparison_Exp>;
  Other?: Maybe<String_Comparison_Exp>;
  Otherfacilities?: Maybe<String_Comparison_Exp>;
  Otherid?: Maybe<String_Comparison_Exp>;
  Otherid1?: Maybe<String_Comparison_Exp>;
  Otherid2?: Maybe<String_Comparison_Exp>;
  Otherid3?: Maybe<Float8_Comparison_Exp>;
  Otherid4?: Maybe<String_Comparison_Exp>;
  Otherid4nbr?: Maybe<String_Comparison_Exp>;
  Otherid6?: Maybe<String_Comparison_Exp>;
  Otherid7?: Maybe<Float8_Comparison_Exp>;
  Othernames?: Maybe<String_Comparison_Exp>;
  Othernames3?: Maybe<String_Comparison_Exp>;
  Otherservices?: Maybe<String_Comparison_Exp>;
  Parish?: Maybe<String_Comparison_Exp>;
  Parish1?: Maybe<String_Comparison_Exp>;
  Parish2?: Maybe<String_Comparison_Exp>;
  Parking?: Maybe<String_Comparison_Exp>;
  Parkingsufficient?: Maybe<String_Comparison_Exp>;
  Partiiindividualownershipdetails?: Maybe<String_Comparison_Exp>;
  Partinonindividualownershipdetails?: Maybe<String_Comparison_Exp>;
  Pass?: Maybe<Float8_Comparison_Exp>;
  Plotnumber?: Maybe<String_Comparison_Exp>;
  Postalofficeboxnumber?: Maybe<String_Comparison_Exp>;
  Postalofficeboxnumber1?: Maybe<String_Comparison_Exp>;
  Powersupply?: Maybe<String_Comparison_Exp>;
  Propertyname?: Maybe<String_Comparison_Exp>;
  Propertysubtype?: Maybe<String_Comparison_Exp>;
  Propertytype?: Maybe<String_Comparison_Exp>;
  Reference?: Maybe<Bigint_Comparison_Exp>;
  Relationshiptoowner?: Maybe<String_Comparison_Exp>;
  Roadfrontageorgrid?: Maybe<String_Comparison_Exp>;
  Roadname?: Maybe<String_Comparison_Exp>;
  Roofcovering?: Maybe<String_Comparison_Exp>;
  Roofcoveringothers?: Maybe<String_Comparison_Exp>;
  Sanitation?: Maybe<String_Comparison_Exp>;
  Sectionapropertyownersdetails?: Maybe<String_Comparison_Exp>;
  Security?: Maybe<String_Comparison_Exp>;
  Shape?: Maybe<String_Comparison_Exp>;
  Shapeofplot?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  Subcounty?: Maybe<String_Comparison_Exp>;
  Subcounty1?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Surname3?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<Float8_Comparison_Exp>;
  Title?: Maybe<String_Comparison_Exp>;
  Title1?: Maybe<String_Comparison_Exp>;
  Topography?: Maybe<String_Comparison_Exp>;
  TotalBuiltUpArea?: Maybe<Float8_Comparison_Exp>;
  TotalGrossArea?: Maybe<Float8_Comparison_Exp>;
  Typeofaccess?: Maybe<String_Comparison_Exp>;
  Typeofbuilding?: Maybe<String_Comparison_Exp>;
  Typeofcompany?: Maybe<String_Comparison_Exp>;
  Typeoflandinterest?: Maybe<String_Comparison_Exp>;
  Username?: Maybe<Float8_Comparison_Exp>;
  Verticalcirculation?: Maybe<String_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  Village1?: Maybe<String_Comparison_Exp>;
  Village2?: Maybe<String_Comparison_Exp>;
  Volume?: Maybe<String_Comparison_Exp>;
  Wallfinish?: Maybe<String_Comparison_Exp>;
  Wallfinishothers?: Maybe<String_Comparison_Exp>;
  Walls?: Maybe<String_Comparison_Exp>;
  Wallsothers?: Maybe<String_Comparison_Exp>;
  Wateraccessibility?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  Windows?: Maybe<String_Comparison_Exp>;
  Windowsothers?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_RawProperties2_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_RawProperties2_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_RawProperties2_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_RawProperties2" */
export type Rtcs_Db_Kcca_RawProperties2_Inc_Input = {
  Alternativelandlinenumber?: Maybe<Scalars["float8"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["float8"]>;
  Alternativephonenumber1?: Maybe<Scalars["float8"]>;
  Altphn1?: Maybe<Scalars["float8"]>;
  Altphn2?: Maybe<Scalars["float8"]>;
  CamvId?: Maybe<Scalars["bigint"]>;
  Id3?: Maybe<Scalars["bigint"]>;
  Land4?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  LatitudeA?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LongitudeB?: Maybe<Scalars["float8"]>;
  Mob?: Maybe<Scalars["float8"]>;
  Mob1?: Maybe<Scalars["float8"]>;
  Nationalid?: Maybe<Scalars["float8"]>;
  Nationalid2?: Maybe<Scalars["float8"]>;
  Objectid?: Maybe<Scalars["float8"]>;
  Otherid3?: Maybe<Scalars["float8"]>;
  Otherid7?: Maybe<Scalars["float8"]>;
  Pass?: Maybe<Scalars["float8"]>;
  Reference?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Tin?: Maybe<Scalars["float8"]>;
  TotalBuiltUpArea?: Maybe<Scalars["float8"]>;
  TotalGrossArea?: Maybe<Scalars["float8"]>;
  Username?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_RawProperties2" */
export type Rtcs_Db_Kcca_RawProperties2_Insert_Input = {
  Access?: Maybe<Scalars["String"]>;
  Agent?: Maybe<Scalars["String"]>;
  Alternative?: Maybe<Scalars["String"]>;
  Alternativeemailaddress?: Maybe<Scalars["String"]>;
  Alternativefirstname?: Maybe<Scalars["String"]>;
  Alternativelandlinenumber?: Maybe<Scalars["float8"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["float8"]>;
  Alternativeothername?: Maybe<Scalars["String"]>;
  Alternativephonenumber1?: Maybe<Scalars["float8"]>;
  Alternativephonenumber1code?: Maybe<Scalars["String"]>;
  Alternativephonenumber2?: Maybe<Scalars["String"]>;
  Alternativephonenumber2code?: Maybe<Scalars["String"]>;
  Alternativesurname?: Maybe<Scalars["String"]>;
  Altphn1?: Maybe<Scalars["float8"]>;
  Altphn2?: Maybe<Scalars["float8"]>;
  Anyother?: Maybe<Scalars["String"]>;
  AreaCount?: Maybe<Scalars["String"]>;
  Authorizedcontactperson?: Maybe<Scalars["String"]>;
  Bldg2?: Maybe<Scalars["String"]>;
  Blockname?: Maybe<Scalars["String"]>;
  Blocknumber?: Maybe<Scalars["String"]>;
  Blockother?: Maybe<Scalars["String"]>;
  Boundary?: Maybe<Scalars["String"]>;
  Boundaryfenceother?: Maybe<Scalars["String"]>;
  Buildingcondition?: Maybe<Scalars["String"]>;
  Buildingname?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["bigint"]>;
  CamvSerial?: Maybe<Scalars["String"]>;
  Ceiling?: Maybe<Scalars["String"]>;
  Ceilingothers?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["String"]>;
  Coin1?: Maybe<Scalars["String"]>;
  Comment?: Maybe<Scalars["String"]>;
  Compound?: Maybe<Scalars["String"]>;
  Compoundother?: Maybe<Scalars["String"]>;
  Contactdetailsofdirector?: Maybe<Scalars["String"]>;
  Country?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  County1?: Maybe<Scalars["String"]>;
  Creationdate?: Maybe<Scalars["timestamptz"]>;
  Creator?: Maybe<Scalars["String"]>;
  Ctrycde?: Maybe<Scalars["String"]>;
  Ctrycde1?: Maybe<Scalars["String"]>;
  Ctrycde2?: Maybe<Scalars["String"]>;
  Ctrycde4?: Maybe<Scalars["String"]>;
  Datacollector?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  District1?: Maybe<Scalars["String"]>;
  Districtorcitywhereboxislocated?: Maybe<Scalars["String"]>;
  Districtorcitywhereboxislocated1?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Doors?: Maybe<Scalars["String"]>;
  Doorsothers?: Maybe<Scalars["String"]>;
  Editdate?: Maybe<Scalars["timestamptz"]>;
  Editor?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Email1?: Maybe<Scalars["String"]>;
  Email3?: Maybe<Scalars["String"]>;
  Email5?: Maybe<Scalars["String"]>;
  Entitytype?: Maybe<Scalars["String"]>;
  Environment?: Maybe<Scalars["String"]>;
  Facilities?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Firstname3?: Maybe<Scalars["String"]>;
  Flatblocknumber?: Maybe<Scalars["String"]>;
  Flatnumber?: Maybe<Scalars["String"]>;
  Floorconstruction?: Maybe<Scalars["String"]>;
  Floorconstructionothers?: Maybe<Scalars["String"]>;
  Floorfinish?: Maybe<Scalars["String"]>;
  Floorfinishothers?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Games?: Maybe<Scalars["String"]>;
  Gamesother?: Maybe<Scalars["String"]>;
  Gate?: Maybe<Scalars["String"]>;
  Gateother?: Maybe<Scalars["String"]>;
  Globalid?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Hsenbr?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Id2?: Maybe<Scalars["String"]>;
  Id3?: Maybe<Scalars["bigint"]>;
  Land4?: Maybe<Scalars["float8"]>;
  Landline?: Maybe<Scalars["String"]>;
  Landline1?: Maybe<Scalars["String"]>;
  Landline4?: Maybe<Scalars["String"]>;
  Landlinecode?: Maybe<Scalars["String"]>;
  Landlinecode4?: Maybe<Scalars["String"]>;
  Landregister?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  LatitudeA?: Maybe<Scalars["float8"]>;
  Latlong?: Maybe<Scalars["String"]>;
  Legalname?: Maybe<Scalars["String"]>;
  Levelofcompletion?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Lln?: Maybe<Scalars["String"]>;
  Lln1?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  LongitudeB?: Maybe<Scalars["float8"]>;
  Lvls?: Maybe<Scalars["String"]>;
  Mainbusinessname?: Maybe<Scalars["String"]>;
  Mob?: Maybe<Scalars["float8"]>;
  Mob1?: Maybe<Scalars["float8"]>;
  Mob3?: Maybe<Scalars["String"]>;
  Mob4?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  Mobile1?: Maybe<Scalars["String"]>;
  Mobile1code?: Maybe<Scalars["String"]>;
  Mobile3?: Maybe<Scalars["String"]>;
  Mobile3code?: Maybe<Scalars["String"]>;
  Mobile4?: Maybe<Scalars["String"]>;
  Mobile4code?: Maybe<Scalars["String"]>;
  Mobile5?: Maybe<Scalars["String"]>;
  Mobile5code?: Maybe<Scalars["String"]>;
  Mobilecode?: Maybe<Scalars["String"]>;
  Name2?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["float8"]>;
  Nationalid2?: Maybe<Scalars["float8"]>;
  Nbrofslots?: Maybe<Scalars["String"]>;
  Neighbourhoodactivities?: Maybe<Scalars["String"]>;
  Neighbourhoodstatus?: Maybe<Scalars["String"]>;
  Nin?: Maybe<Scalars["String"]>;
  Nin2?: Maybe<Scalars["String"]>;
  Objectid?: Maybe<Scalars["float8"]>;
  Other?: Maybe<Scalars["String"]>;
  Otherfacilities?: Maybe<Scalars["String"]>;
  Otherid?: Maybe<Scalars["String"]>;
  Otherid1?: Maybe<Scalars["String"]>;
  Otherid2?: Maybe<Scalars["String"]>;
  Otherid3?: Maybe<Scalars["float8"]>;
  Otherid4?: Maybe<Scalars["String"]>;
  Otherid4nbr?: Maybe<Scalars["String"]>;
  Otherid6?: Maybe<Scalars["String"]>;
  Otherid7?: Maybe<Scalars["float8"]>;
  Othernames?: Maybe<Scalars["String"]>;
  Othernames3?: Maybe<Scalars["String"]>;
  Otherservices?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Parish1?: Maybe<Scalars["String"]>;
  Parish2?: Maybe<Scalars["String"]>;
  Parking?: Maybe<Scalars["String"]>;
  Parkingsufficient?: Maybe<Scalars["String"]>;
  Partiiindividualownershipdetails?: Maybe<Scalars["String"]>;
  Partinonindividualownershipdetails?: Maybe<Scalars["String"]>;
  Pass?: Maybe<Scalars["float8"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Postalofficeboxnumber?: Maybe<Scalars["String"]>;
  Postalofficeboxnumber1?: Maybe<Scalars["String"]>;
  Powersupply?: Maybe<Scalars["String"]>;
  Propertyname?: Maybe<Scalars["String"]>;
  Propertysubtype?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Reference?: Maybe<Scalars["bigint"]>;
  Relationshiptoowner?: Maybe<Scalars["String"]>;
  Roadfrontageorgrid?: Maybe<Scalars["String"]>;
  Roadname?: Maybe<Scalars["String"]>;
  Roofcovering?: Maybe<Scalars["String"]>;
  Roofcoveringothers?: Maybe<Scalars["String"]>;
  Sanitation?: Maybe<Scalars["String"]>;
  Sectionapropertyownersdetails?: Maybe<Scalars["String"]>;
  Security?: Maybe<Scalars["String"]>;
  Shape?: Maybe<Scalars["String"]>;
  Shapeofplot?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Subcounty1?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Surname3?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Title1?: Maybe<Scalars["String"]>;
  Topography?: Maybe<Scalars["String"]>;
  TotalBuiltUpArea?: Maybe<Scalars["float8"]>;
  TotalGrossArea?: Maybe<Scalars["float8"]>;
  Typeofaccess?: Maybe<Scalars["String"]>;
  Typeofbuilding?: Maybe<Scalars["String"]>;
  Typeofcompany?: Maybe<Scalars["String"]>;
  Typeoflandinterest?: Maybe<Scalars["String"]>;
  Username?: Maybe<Scalars["float8"]>;
  Verticalcirculation?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  Village1?: Maybe<Scalars["String"]>;
  Village2?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  Wallfinish?: Maybe<Scalars["String"]>;
  Wallfinishothers?: Maybe<Scalars["String"]>;
  Walls?: Maybe<Scalars["String"]>;
  Wallsothers?: Maybe<Scalars["String"]>;
  Wateraccessibility?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Windows?: Maybe<Scalars["String"]>;
  Windowsothers?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_RawProperties2_Max_Fields = {
  __typename?: "rtcs_db_kcca_RawProperties2_max_fields";
  Access?: Maybe<Scalars["String"]>;
  Agent?: Maybe<Scalars["String"]>;
  Alternative?: Maybe<Scalars["String"]>;
  Alternativeemailaddress?: Maybe<Scalars["String"]>;
  Alternativefirstname?: Maybe<Scalars["String"]>;
  Alternativelandlinenumber?: Maybe<Scalars["float8"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["float8"]>;
  Alternativeothername?: Maybe<Scalars["String"]>;
  Alternativephonenumber1?: Maybe<Scalars["float8"]>;
  Alternativephonenumber1code?: Maybe<Scalars["String"]>;
  Alternativephonenumber2?: Maybe<Scalars["String"]>;
  Alternativephonenumber2code?: Maybe<Scalars["String"]>;
  Alternativesurname?: Maybe<Scalars["String"]>;
  Altphn1?: Maybe<Scalars["float8"]>;
  Altphn2?: Maybe<Scalars["float8"]>;
  Anyother?: Maybe<Scalars["String"]>;
  AreaCount?: Maybe<Scalars["String"]>;
  Authorizedcontactperson?: Maybe<Scalars["String"]>;
  Bldg2?: Maybe<Scalars["String"]>;
  Blockname?: Maybe<Scalars["String"]>;
  Blocknumber?: Maybe<Scalars["String"]>;
  Blockother?: Maybe<Scalars["String"]>;
  Boundary?: Maybe<Scalars["String"]>;
  Boundaryfenceother?: Maybe<Scalars["String"]>;
  Buildingcondition?: Maybe<Scalars["String"]>;
  Buildingname?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["bigint"]>;
  CamvSerial?: Maybe<Scalars["String"]>;
  Ceiling?: Maybe<Scalars["String"]>;
  Ceilingothers?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["String"]>;
  Coin1?: Maybe<Scalars["String"]>;
  Comment?: Maybe<Scalars["String"]>;
  Compound?: Maybe<Scalars["String"]>;
  Compoundother?: Maybe<Scalars["String"]>;
  Contactdetailsofdirector?: Maybe<Scalars["String"]>;
  Country?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  County1?: Maybe<Scalars["String"]>;
  Creationdate?: Maybe<Scalars["timestamptz"]>;
  Creator?: Maybe<Scalars["String"]>;
  Ctrycde?: Maybe<Scalars["String"]>;
  Ctrycde1?: Maybe<Scalars["String"]>;
  Ctrycde2?: Maybe<Scalars["String"]>;
  Ctrycde4?: Maybe<Scalars["String"]>;
  Datacollector?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  District1?: Maybe<Scalars["String"]>;
  Districtorcitywhereboxislocated?: Maybe<Scalars["String"]>;
  Districtorcitywhereboxislocated1?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Doors?: Maybe<Scalars["String"]>;
  Doorsothers?: Maybe<Scalars["String"]>;
  Editdate?: Maybe<Scalars["timestamptz"]>;
  Editor?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Email1?: Maybe<Scalars["String"]>;
  Email3?: Maybe<Scalars["String"]>;
  Email5?: Maybe<Scalars["String"]>;
  Entitytype?: Maybe<Scalars["String"]>;
  Environment?: Maybe<Scalars["String"]>;
  Facilities?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Firstname3?: Maybe<Scalars["String"]>;
  Flatblocknumber?: Maybe<Scalars["String"]>;
  Flatnumber?: Maybe<Scalars["String"]>;
  Floorconstruction?: Maybe<Scalars["String"]>;
  Floorconstructionothers?: Maybe<Scalars["String"]>;
  Floorfinish?: Maybe<Scalars["String"]>;
  Floorfinishothers?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Games?: Maybe<Scalars["String"]>;
  Gamesother?: Maybe<Scalars["String"]>;
  Gate?: Maybe<Scalars["String"]>;
  Gateother?: Maybe<Scalars["String"]>;
  Globalid?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Hsenbr?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Id2?: Maybe<Scalars["String"]>;
  Id3?: Maybe<Scalars["bigint"]>;
  Land4?: Maybe<Scalars["float8"]>;
  Landline?: Maybe<Scalars["String"]>;
  Landline1?: Maybe<Scalars["String"]>;
  Landline4?: Maybe<Scalars["String"]>;
  Landlinecode?: Maybe<Scalars["String"]>;
  Landlinecode4?: Maybe<Scalars["String"]>;
  Landregister?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  LatitudeA?: Maybe<Scalars["float8"]>;
  Latlong?: Maybe<Scalars["String"]>;
  Legalname?: Maybe<Scalars["String"]>;
  Levelofcompletion?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Lln?: Maybe<Scalars["String"]>;
  Lln1?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  LongitudeB?: Maybe<Scalars["float8"]>;
  Lvls?: Maybe<Scalars["String"]>;
  Mainbusinessname?: Maybe<Scalars["String"]>;
  Mob?: Maybe<Scalars["float8"]>;
  Mob1?: Maybe<Scalars["float8"]>;
  Mob3?: Maybe<Scalars["String"]>;
  Mob4?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  Mobile1?: Maybe<Scalars["String"]>;
  Mobile1code?: Maybe<Scalars["String"]>;
  Mobile3?: Maybe<Scalars["String"]>;
  Mobile3code?: Maybe<Scalars["String"]>;
  Mobile4?: Maybe<Scalars["String"]>;
  Mobile4code?: Maybe<Scalars["String"]>;
  Mobile5?: Maybe<Scalars["String"]>;
  Mobile5code?: Maybe<Scalars["String"]>;
  Mobilecode?: Maybe<Scalars["String"]>;
  Name2?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["float8"]>;
  Nationalid2?: Maybe<Scalars["float8"]>;
  Nbrofslots?: Maybe<Scalars["String"]>;
  Neighbourhoodactivities?: Maybe<Scalars["String"]>;
  Neighbourhoodstatus?: Maybe<Scalars["String"]>;
  Nin?: Maybe<Scalars["String"]>;
  Nin2?: Maybe<Scalars["String"]>;
  Objectid?: Maybe<Scalars["float8"]>;
  Other?: Maybe<Scalars["String"]>;
  Otherfacilities?: Maybe<Scalars["String"]>;
  Otherid?: Maybe<Scalars["String"]>;
  Otherid1?: Maybe<Scalars["String"]>;
  Otherid2?: Maybe<Scalars["String"]>;
  Otherid3?: Maybe<Scalars["float8"]>;
  Otherid4?: Maybe<Scalars["String"]>;
  Otherid4nbr?: Maybe<Scalars["String"]>;
  Otherid6?: Maybe<Scalars["String"]>;
  Otherid7?: Maybe<Scalars["float8"]>;
  Othernames?: Maybe<Scalars["String"]>;
  Othernames3?: Maybe<Scalars["String"]>;
  Otherservices?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Parish1?: Maybe<Scalars["String"]>;
  Parish2?: Maybe<Scalars["String"]>;
  Parking?: Maybe<Scalars["String"]>;
  Parkingsufficient?: Maybe<Scalars["String"]>;
  Partiiindividualownershipdetails?: Maybe<Scalars["String"]>;
  Partinonindividualownershipdetails?: Maybe<Scalars["String"]>;
  Pass?: Maybe<Scalars["float8"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Postalofficeboxnumber?: Maybe<Scalars["String"]>;
  Postalofficeboxnumber1?: Maybe<Scalars["String"]>;
  Powersupply?: Maybe<Scalars["String"]>;
  Propertyname?: Maybe<Scalars["String"]>;
  Propertysubtype?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Reference?: Maybe<Scalars["bigint"]>;
  Relationshiptoowner?: Maybe<Scalars["String"]>;
  Roadfrontageorgrid?: Maybe<Scalars["String"]>;
  Roadname?: Maybe<Scalars["String"]>;
  Roofcovering?: Maybe<Scalars["String"]>;
  Roofcoveringothers?: Maybe<Scalars["String"]>;
  Sanitation?: Maybe<Scalars["String"]>;
  Sectionapropertyownersdetails?: Maybe<Scalars["String"]>;
  Security?: Maybe<Scalars["String"]>;
  Shape?: Maybe<Scalars["String"]>;
  Shapeofplot?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Subcounty1?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Surname3?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Title1?: Maybe<Scalars["String"]>;
  Topography?: Maybe<Scalars["String"]>;
  TotalBuiltUpArea?: Maybe<Scalars["float8"]>;
  TotalGrossArea?: Maybe<Scalars["float8"]>;
  Typeofaccess?: Maybe<Scalars["String"]>;
  Typeofbuilding?: Maybe<Scalars["String"]>;
  Typeofcompany?: Maybe<Scalars["String"]>;
  Typeoflandinterest?: Maybe<Scalars["String"]>;
  Username?: Maybe<Scalars["float8"]>;
  Verticalcirculation?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  Village1?: Maybe<Scalars["String"]>;
  Village2?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  Wallfinish?: Maybe<Scalars["String"]>;
  Wallfinishothers?: Maybe<Scalars["String"]>;
  Walls?: Maybe<Scalars["String"]>;
  Wallsothers?: Maybe<Scalars["String"]>;
  Wateraccessibility?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Windows?: Maybe<Scalars["String"]>;
  Windowsothers?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_RawProperties2" */
export type Rtcs_Db_Kcca_RawProperties2_Max_Order_By = {
  Access?: Maybe<Order_By>;
  Agent?: Maybe<Order_By>;
  Alternative?: Maybe<Order_By>;
  Alternativeemailaddress?: Maybe<Order_By>;
  Alternativefirstname?: Maybe<Order_By>;
  Alternativelandlinenumber?: Maybe<Order_By>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Order_By>;
  Alternativeothername?: Maybe<Order_By>;
  Alternativephonenumber1?: Maybe<Order_By>;
  Alternativephonenumber1code?: Maybe<Order_By>;
  Alternativephonenumber2?: Maybe<Order_By>;
  Alternativephonenumber2code?: Maybe<Order_By>;
  Alternativesurname?: Maybe<Order_By>;
  Altphn1?: Maybe<Order_By>;
  Altphn2?: Maybe<Order_By>;
  Anyother?: Maybe<Order_By>;
  AreaCount?: Maybe<Order_By>;
  Authorizedcontactperson?: Maybe<Order_By>;
  Bldg2?: Maybe<Order_By>;
  Blockname?: Maybe<Order_By>;
  Blocknumber?: Maybe<Order_By>;
  Blockother?: Maybe<Order_By>;
  Boundary?: Maybe<Order_By>;
  Boundaryfenceother?: Maybe<Order_By>;
  Buildingcondition?: Maybe<Order_By>;
  Buildingname?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CamvSerial?: Maybe<Order_By>;
  Ceiling?: Maybe<Order_By>;
  Ceilingothers?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  Coin1?: Maybe<Order_By>;
  Comment?: Maybe<Order_By>;
  Compound?: Maybe<Order_By>;
  Compoundother?: Maybe<Order_By>;
  Contactdetailsofdirector?: Maybe<Order_By>;
  Country?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  County1?: Maybe<Order_By>;
  Creationdate?: Maybe<Order_By>;
  Creator?: Maybe<Order_By>;
  Ctrycde?: Maybe<Order_By>;
  Ctrycde1?: Maybe<Order_By>;
  Ctrycde2?: Maybe<Order_By>;
  Ctrycde4?: Maybe<Order_By>;
  Datacollector?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  District1?: Maybe<Order_By>;
  Districtorcitywhereboxislocated?: Maybe<Order_By>;
  Districtorcitywhereboxislocated1?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  Doors?: Maybe<Order_By>;
  Doorsothers?: Maybe<Order_By>;
  Editdate?: Maybe<Order_By>;
  Editor?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  Email1?: Maybe<Order_By>;
  Email3?: Maybe<Order_By>;
  Email5?: Maybe<Order_By>;
  Entitytype?: Maybe<Order_By>;
  Environment?: Maybe<Order_By>;
  Facilities?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Firstname3?: Maybe<Order_By>;
  Flatblocknumber?: Maybe<Order_By>;
  Flatnumber?: Maybe<Order_By>;
  Floorconstruction?: Maybe<Order_By>;
  Floorconstructionothers?: Maybe<Order_By>;
  Floorfinish?: Maybe<Order_By>;
  Floorfinishothers?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Games?: Maybe<Order_By>;
  Gamesother?: Maybe<Order_By>;
  Gate?: Maybe<Order_By>;
  Gateother?: Maybe<Order_By>;
  Globalid?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Hsenbr?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Id2?: Maybe<Order_By>;
  Id3?: Maybe<Order_By>;
  Land4?: Maybe<Order_By>;
  Landline?: Maybe<Order_By>;
  Landline1?: Maybe<Order_By>;
  Landline4?: Maybe<Order_By>;
  Landlinecode?: Maybe<Order_By>;
  Landlinecode4?: Maybe<Order_By>;
  Landregister?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  LatitudeA?: Maybe<Order_By>;
  Latlong?: Maybe<Order_By>;
  Legalname?: Maybe<Order_By>;
  Levelofcompletion?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Lln?: Maybe<Order_By>;
  Lln1?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  LongitudeB?: Maybe<Order_By>;
  Lvls?: Maybe<Order_By>;
  Mainbusinessname?: Maybe<Order_By>;
  Mob?: Maybe<Order_By>;
  Mob1?: Maybe<Order_By>;
  Mob3?: Maybe<Order_By>;
  Mob4?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobile1?: Maybe<Order_By>;
  Mobile1code?: Maybe<Order_By>;
  Mobile3?: Maybe<Order_By>;
  Mobile3code?: Maybe<Order_By>;
  Mobile4?: Maybe<Order_By>;
  Mobile4code?: Maybe<Order_By>;
  Mobile5?: Maybe<Order_By>;
  Mobile5code?: Maybe<Order_By>;
  Mobilecode?: Maybe<Order_By>;
  Name2?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Nationalid2?: Maybe<Order_By>;
  Nbrofslots?: Maybe<Order_By>;
  Neighbourhoodactivities?: Maybe<Order_By>;
  Neighbourhoodstatus?: Maybe<Order_By>;
  Nin?: Maybe<Order_By>;
  Nin2?: Maybe<Order_By>;
  Objectid?: Maybe<Order_By>;
  Other?: Maybe<Order_By>;
  Otherfacilities?: Maybe<Order_By>;
  Otherid?: Maybe<Order_By>;
  Otherid1?: Maybe<Order_By>;
  Otherid2?: Maybe<Order_By>;
  Otherid3?: Maybe<Order_By>;
  Otherid4?: Maybe<Order_By>;
  Otherid4nbr?: Maybe<Order_By>;
  Otherid6?: Maybe<Order_By>;
  Otherid7?: Maybe<Order_By>;
  Othernames?: Maybe<Order_By>;
  Othernames3?: Maybe<Order_By>;
  Otherservices?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Parish1?: Maybe<Order_By>;
  Parish2?: Maybe<Order_By>;
  Parking?: Maybe<Order_By>;
  Parkingsufficient?: Maybe<Order_By>;
  Partiiindividualownershipdetails?: Maybe<Order_By>;
  Partinonindividualownershipdetails?: Maybe<Order_By>;
  Pass?: Maybe<Order_By>;
  Plotnumber?: Maybe<Order_By>;
  Postalofficeboxnumber?: Maybe<Order_By>;
  Postalofficeboxnumber1?: Maybe<Order_By>;
  Powersupply?: Maybe<Order_By>;
  Propertyname?: Maybe<Order_By>;
  Propertysubtype?: Maybe<Order_By>;
  Propertytype?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  Relationshiptoowner?: Maybe<Order_By>;
  Roadfrontageorgrid?: Maybe<Order_By>;
  Roadname?: Maybe<Order_By>;
  Roofcovering?: Maybe<Order_By>;
  Roofcoveringothers?: Maybe<Order_By>;
  Sanitation?: Maybe<Order_By>;
  Sectionapropertyownersdetails?: Maybe<Order_By>;
  Security?: Maybe<Order_By>;
  Shape?: Maybe<Order_By>;
  Shapeofplot?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Subcounty1?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Surname3?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Title?: Maybe<Order_By>;
  Title1?: Maybe<Order_By>;
  Topography?: Maybe<Order_By>;
  TotalBuiltUpArea?: Maybe<Order_By>;
  TotalGrossArea?: Maybe<Order_By>;
  Typeofaccess?: Maybe<Order_By>;
  Typeofbuilding?: Maybe<Order_By>;
  Typeofcompany?: Maybe<Order_By>;
  Typeoflandinterest?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
  Verticalcirculation?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  Village1?: Maybe<Order_By>;
  Village2?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  Wallfinish?: Maybe<Order_By>;
  Wallfinishothers?: Maybe<Order_By>;
  Walls?: Maybe<Order_By>;
  Wallsothers?: Maybe<Order_By>;
  Wateraccessibility?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  Windows?: Maybe<Order_By>;
  Windowsothers?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_RawProperties2_Min_Fields = {
  __typename?: "rtcs_db_kcca_RawProperties2_min_fields";
  Access?: Maybe<Scalars["String"]>;
  Agent?: Maybe<Scalars["String"]>;
  Alternative?: Maybe<Scalars["String"]>;
  Alternativeemailaddress?: Maybe<Scalars["String"]>;
  Alternativefirstname?: Maybe<Scalars["String"]>;
  Alternativelandlinenumber?: Maybe<Scalars["float8"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["float8"]>;
  Alternativeothername?: Maybe<Scalars["String"]>;
  Alternativephonenumber1?: Maybe<Scalars["float8"]>;
  Alternativephonenumber1code?: Maybe<Scalars["String"]>;
  Alternativephonenumber2?: Maybe<Scalars["String"]>;
  Alternativephonenumber2code?: Maybe<Scalars["String"]>;
  Alternativesurname?: Maybe<Scalars["String"]>;
  Altphn1?: Maybe<Scalars["float8"]>;
  Altphn2?: Maybe<Scalars["float8"]>;
  Anyother?: Maybe<Scalars["String"]>;
  AreaCount?: Maybe<Scalars["String"]>;
  Authorizedcontactperson?: Maybe<Scalars["String"]>;
  Bldg2?: Maybe<Scalars["String"]>;
  Blockname?: Maybe<Scalars["String"]>;
  Blocknumber?: Maybe<Scalars["String"]>;
  Blockother?: Maybe<Scalars["String"]>;
  Boundary?: Maybe<Scalars["String"]>;
  Boundaryfenceother?: Maybe<Scalars["String"]>;
  Buildingcondition?: Maybe<Scalars["String"]>;
  Buildingname?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["bigint"]>;
  CamvSerial?: Maybe<Scalars["String"]>;
  Ceiling?: Maybe<Scalars["String"]>;
  Ceilingothers?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["String"]>;
  Coin1?: Maybe<Scalars["String"]>;
  Comment?: Maybe<Scalars["String"]>;
  Compound?: Maybe<Scalars["String"]>;
  Compoundother?: Maybe<Scalars["String"]>;
  Contactdetailsofdirector?: Maybe<Scalars["String"]>;
  Country?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  County1?: Maybe<Scalars["String"]>;
  Creationdate?: Maybe<Scalars["timestamptz"]>;
  Creator?: Maybe<Scalars["String"]>;
  Ctrycde?: Maybe<Scalars["String"]>;
  Ctrycde1?: Maybe<Scalars["String"]>;
  Ctrycde2?: Maybe<Scalars["String"]>;
  Ctrycde4?: Maybe<Scalars["String"]>;
  Datacollector?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  District1?: Maybe<Scalars["String"]>;
  Districtorcitywhereboxislocated?: Maybe<Scalars["String"]>;
  Districtorcitywhereboxislocated1?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Doors?: Maybe<Scalars["String"]>;
  Doorsothers?: Maybe<Scalars["String"]>;
  Editdate?: Maybe<Scalars["timestamptz"]>;
  Editor?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Email1?: Maybe<Scalars["String"]>;
  Email3?: Maybe<Scalars["String"]>;
  Email5?: Maybe<Scalars["String"]>;
  Entitytype?: Maybe<Scalars["String"]>;
  Environment?: Maybe<Scalars["String"]>;
  Facilities?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Firstname3?: Maybe<Scalars["String"]>;
  Flatblocknumber?: Maybe<Scalars["String"]>;
  Flatnumber?: Maybe<Scalars["String"]>;
  Floorconstruction?: Maybe<Scalars["String"]>;
  Floorconstructionothers?: Maybe<Scalars["String"]>;
  Floorfinish?: Maybe<Scalars["String"]>;
  Floorfinishothers?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Games?: Maybe<Scalars["String"]>;
  Gamesother?: Maybe<Scalars["String"]>;
  Gate?: Maybe<Scalars["String"]>;
  Gateother?: Maybe<Scalars["String"]>;
  Globalid?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Hsenbr?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Id2?: Maybe<Scalars["String"]>;
  Id3?: Maybe<Scalars["bigint"]>;
  Land4?: Maybe<Scalars["float8"]>;
  Landline?: Maybe<Scalars["String"]>;
  Landline1?: Maybe<Scalars["String"]>;
  Landline4?: Maybe<Scalars["String"]>;
  Landlinecode?: Maybe<Scalars["String"]>;
  Landlinecode4?: Maybe<Scalars["String"]>;
  Landregister?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  LatitudeA?: Maybe<Scalars["float8"]>;
  Latlong?: Maybe<Scalars["String"]>;
  Legalname?: Maybe<Scalars["String"]>;
  Levelofcompletion?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Lln?: Maybe<Scalars["String"]>;
  Lln1?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  LongitudeB?: Maybe<Scalars["float8"]>;
  Lvls?: Maybe<Scalars["String"]>;
  Mainbusinessname?: Maybe<Scalars["String"]>;
  Mob?: Maybe<Scalars["float8"]>;
  Mob1?: Maybe<Scalars["float8"]>;
  Mob3?: Maybe<Scalars["String"]>;
  Mob4?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  Mobile1?: Maybe<Scalars["String"]>;
  Mobile1code?: Maybe<Scalars["String"]>;
  Mobile3?: Maybe<Scalars["String"]>;
  Mobile3code?: Maybe<Scalars["String"]>;
  Mobile4?: Maybe<Scalars["String"]>;
  Mobile4code?: Maybe<Scalars["String"]>;
  Mobile5?: Maybe<Scalars["String"]>;
  Mobile5code?: Maybe<Scalars["String"]>;
  Mobilecode?: Maybe<Scalars["String"]>;
  Name2?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["float8"]>;
  Nationalid2?: Maybe<Scalars["float8"]>;
  Nbrofslots?: Maybe<Scalars["String"]>;
  Neighbourhoodactivities?: Maybe<Scalars["String"]>;
  Neighbourhoodstatus?: Maybe<Scalars["String"]>;
  Nin?: Maybe<Scalars["String"]>;
  Nin2?: Maybe<Scalars["String"]>;
  Objectid?: Maybe<Scalars["float8"]>;
  Other?: Maybe<Scalars["String"]>;
  Otherfacilities?: Maybe<Scalars["String"]>;
  Otherid?: Maybe<Scalars["String"]>;
  Otherid1?: Maybe<Scalars["String"]>;
  Otherid2?: Maybe<Scalars["String"]>;
  Otherid3?: Maybe<Scalars["float8"]>;
  Otherid4?: Maybe<Scalars["String"]>;
  Otherid4nbr?: Maybe<Scalars["String"]>;
  Otherid6?: Maybe<Scalars["String"]>;
  Otherid7?: Maybe<Scalars["float8"]>;
  Othernames?: Maybe<Scalars["String"]>;
  Othernames3?: Maybe<Scalars["String"]>;
  Otherservices?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Parish1?: Maybe<Scalars["String"]>;
  Parish2?: Maybe<Scalars["String"]>;
  Parking?: Maybe<Scalars["String"]>;
  Parkingsufficient?: Maybe<Scalars["String"]>;
  Partiiindividualownershipdetails?: Maybe<Scalars["String"]>;
  Partinonindividualownershipdetails?: Maybe<Scalars["String"]>;
  Pass?: Maybe<Scalars["float8"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Postalofficeboxnumber?: Maybe<Scalars["String"]>;
  Postalofficeboxnumber1?: Maybe<Scalars["String"]>;
  Powersupply?: Maybe<Scalars["String"]>;
  Propertyname?: Maybe<Scalars["String"]>;
  Propertysubtype?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Reference?: Maybe<Scalars["bigint"]>;
  Relationshiptoowner?: Maybe<Scalars["String"]>;
  Roadfrontageorgrid?: Maybe<Scalars["String"]>;
  Roadname?: Maybe<Scalars["String"]>;
  Roofcovering?: Maybe<Scalars["String"]>;
  Roofcoveringothers?: Maybe<Scalars["String"]>;
  Sanitation?: Maybe<Scalars["String"]>;
  Sectionapropertyownersdetails?: Maybe<Scalars["String"]>;
  Security?: Maybe<Scalars["String"]>;
  Shape?: Maybe<Scalars["String"]>;
  Shapeofplot?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Subcounty1?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Surname3?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Title1?: Maybe<Scalars["String"]>;
  Topography?: Maybe<Scalars["String"]>;
  TotalBuiltUpArea?: Maybe<Scalars["float8"]>;
  TotalGrossArea?: Maybe<Scalars["float8"]>;
  Typeofaccess?: Maybe<Scalars["String"]>;
  Typeofbuilding?: Maybe<Scalars["String"]>;
  Typeofcompany?: Maybe<Scalars["String"]>;
  Typeoflandinterest?: Maybe<Scalars["String"]>;
  Username?: Maybe<Scalars["float8"]>;
  Verticalcirculation?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  Village1?: Maybe<Scalars["String"]>;
  Village2?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  Wallfinish?: Maybe<Scalars["String"]>;
  Wallfinishothers?: Maybe<Scalars["String"]>;
  Walls?: Maybe<Scalars["String"]>;
  Wallsothers?: Maybe<Scalars["String"]>;
  Wateraccessibility?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Windows?: Maybe<Scalars["String"]>;
  Windowsothers?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_RawProperties2" */
export type Rtcs_Db_Kcca_RawProperties2_Min_Order_By = {
  Access?: Maybe<Order_By>;
  Agent?: Maybe<Order_By>;
  Alternative?: Maybe<Order_By>;
  Alternativeemailaddress?: Maybe<Order_By>;
  Alternativefirstname?: Maybe<Order_By>;
  Alternativelandlinenumber?: Maybe<Order_By>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Order_By>;
  Alternativeothername?: Maybe<Order_By>;
  Alternativephonenumber1?: Maybe<Order_By>;
  Alternativephonenumber1code?: Maybe<Order_By>;
  Alternativephonenumber2?: Maybe<Order_By>;
  Alternativephonenumber2code?: Maybe<Order_By>;
  Alternativesurname?: Maybe<Order_By>;
  Altphn1?: Maybe<Order_By>;
  Altphn2?: Maybe<Order_By>;
  Anyother?: Maybe<Order_By>;
  AreaCount?: Maybe<Order_By>;
  Authorizedcontactperson?: Maybe<Order_By>;
  Bldg2?: Maybe<Order_By>;
  Blockname?: Maybe<Order_By>;
  Blocknumber?: Maybe<Order_By>;
  Blockother?: Maybe<Order_By>;
  Boundary?: Maybe<Order_By>;
  Boundaryfenceother?: Maybe<Order_By>;
  Buildingcondition?: Maybe<Order_By>;
  Buildingname?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CamvSerial?: Maybe<Order_By>;
  Ceiling?: Maybe<Order_By>;
  Ceilingothers?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  Coin1?: Maybe<Order_By>;
  Comment?: Maybe<Order_By>;
  Compound?: Maybe<Order_By>;
  Compoundother?: Maybe<Order_By>;
  Contactdetailsofdirector?: Maybe<Order_By>;
  Country?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  County1?: Maybe<Order_By>;
  Creationdate?: Maybe<Order_By>;
  Creator?: Maybe<Order_By>;
  Ctrycde?: Maybe<Order_By>;
  Ctrycde1?: Maybe<Order_By>;
  Ctrycde2?: Maybe<Order_By>;
  Ctrycde4?: Maybe<Order_By>;
  Datacollector?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  District1?: Maybe<Order_By>;
  Districtorcitywhereboxislocated?: Maybe<Order_By>;
  Districtorcitywhereboxislocated1?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  Doors?: Maybe<Order_By>;
  Doorsothers?: Maybe<Order_By>;
  Editdate?: Maybe<Order_By>;
  Editor?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  Email1?: Maybe<Order_By>;
  Email3?: Maybe<Order_By>;
  Email5?: Maybe<Order_By>;
  Entitytype?: Maybe<Order_By>;
  Environment?: Maybe<Order_By>;
  Facilities?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Firstname3?: Maybe<Order_By>;
  Flatblocknumber?: Maybe<Order_By>;
  Flatnumber?: Maybe<Order_By>;
  Floorconstruction?: Maybe<Order_By>;
  Floorconstructionothers?: Maybe<Order_By>;
  Floorfinish?: Maybe<Order_By>;
  Floorfinishothers?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Games?: Maybe<Order_By>;
  Gamesother?: Maybe<Order_By>;
  Gate?: Maybe<Order_By>;
  Gateother?: Maybe<Order_By>;
  Globalid?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Hsenbr?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Id2?: Maybe<Order_By>;
  Id3?: Maybe<Order_By>;
  Land4?: Maybe<Order_By>;
  Landline?: Maybe<Order_By>;
  Landline1?: Maybe<Order_By>;
  Landline4?: Maybe<Order_By>;
  Landlinecode?: Maybe<Order_By>;
  Landlinecode4?: Maybe<Order_By>;
  Landregister?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  LatitudeA?: Maybe<Order_By>;
  Latlong?: Maybe<Order_By>;
  Legalname?: Maybe<Order_By>;
  Levelofcompletion?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Lln?: Maybe<Order_By>;
  Lln1?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  LongitudeB?: Maybe<Order_By>;
  Lvls?: Maybe<Order_By>;
  Mainbusinessname?: Maybe<Order_By>;
  Mob?: Maybe<Order_By>;
  Mob1?: Maybe<Order_By>;
  Mob3?: Maybe<Order_By>;
  Mob4?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobile1?: Maybe<Order_By>;
  Mobile1code?: Maybe<Order_By>;
  Mobile3?: Maybe<Order_By>;
  Mobile3code?: Maybe<Order_By>;
  Mobile4?: Maybe<Order_By>;
  Mobile4code?: Maybe<Order_By>;
  Mobile5?: Maybe<Order_By>;
  Mobile5code?: Maybe<Order_By>;
  Mobilecode?: Maybe<Order_By>;
  Name2?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Nationalid2?: Maybe<Order_By>;
  Nbrofslots?: Maybe<Order_By>;
  Neighbourhoodactivities?: Maybe<Order_By>;
  Neighbourhoodstatus?: Maybe<Order_By>;
  Nin?: Maybe<Order_By>;
  Nin2?: Maybe<Order_By>;
  Objectid?: Maybe<Order_By>;
  Other?: Maybe<Order_By>;
  Otherfacilities?: Maybe<Order_By>;
  Otherid?: Maybe<Order_By>;
  Otherid1?: Maybe<Order_By>;
  Otherid2?: Maybe<Order_By>;
  Otherid3?: Maybe<Order_By>;
  Otherid4?: Maybe<Order_By>;
  Otherid4nbr?: Maybe<Order_By>;
  Otherid6?: Maybe<Order_By>;
  Otherid7?: Maybe<Order_By>;
  Othernames?: Maybe<Order_By>;
  Othernames3?: Maybe<Order_By>;
  Otherservices?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Parish1?: Maybe<Order_By>;
  Parish2?: Maybe<Order_By>;
  Parking?: Maybe<Order_By>;
  Parkingsufficient?: Maybe<Order_By>;
  Partiiindividualownershipdetails?: Maybe<Order_By>;
  Partinonindividualownershipdetails?: Maybe<Order_By>;
  Pass?: Maybe<Order_By>;
  Plotnumber?: Maybe<Order_By>;
  Postalofficeboxnumber?: Maybe<Order_By>;
  Postalofficeboxnumber1?: Maybe<Order_By>;
  Powersupply?: Maybe<Order_By>;
  Propertyname?: Maybe<Order_By>;
  Propertysubtype?: Maybe<Order_By>;
  Propertytype?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  Relationshiptoowner?: Maybe<Order_By>;
  Roadfrontageorgrid?: Maybe<Order_By>;
  Roadname?: Maybe<Order_By>;
  Roofcovering?: Maybe<Order_By>;
  Roofcoveringothers?: Maybe<Order_By>;
  Sanitation?: Maybe<Order_By>;
  Sectionapropertyownersdetails?: Maybe<Order_By>;
  Security?: Maybe<Order_By>;
  Shape?: Maybe<Order_By>;
  Shapeofplot?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Subcounty1?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Surname3?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Title?: Maybe<Order_By>;
  Title1?: Maybe<Order_By>;
  Topography?: Maybe<Order_By>;
  TotalBuiltUpArea?: Maybe<Order_By>;
  TotalGrossArea?: Maybe<Order_By>;
  Typeofaccess?: Maybe<Order_By>;
  Typeofbuilding?: Maybe<Order_By>;
  Typeofcompany?: Maybe<Order_By>;
  Typeoflandinterest?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
  Verticalcirculation?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  Village1?: Maybe<Order_By>;
  Village2?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  Wallfinish?: Maybe<Order_By>;
  Wallfinishothers?: Maybe<Order_By>;
  Walls?: Maybe<Order_By>;
  Wallsothers?: Maybe<Order_By>;
  Wateraccessibility?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  Windows?: Maybe<Order_By>;
  Windowsothers?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_RawProperties2" */
export type Rtcs_Db_Kcca_RawProperties2_Mutation_Response = {
  __typename?: "rtcs_db_kcca_RawProperties2_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_RawProperties2>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_RawProperties2" */
export type Rtcs_Db_Kcca_RawProperties2_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_RawProperties2_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_RawProperties2" */
export type Rtcs_Db_Kcca_RawProperties2_Order_By = {
  Access?: Maybe<Order_By>;
  Agent?: Maybe<Order_By>;
  Alternative?: Maybe<Order_By>;
  Alternativeemailaddress?: Maybe<Order_By>;
  Alternativefirstname?: Maybe<Order_By>;
  Alternativelandlinenumber?: Maybe<Order_By>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Order_By>;
  Alternativeothername?: Maybe<Order_By>;
  Alternativephonenumber1?: Maybe<Order_By>;
  Alternativephonenumber1code?: Maybe<Order_By>;
  Alternativephonenumber2?: Maybe<Order_By>;
  Alternativephonenumber2code?: Maybe<Order_By>;
  Alternativesurname?: Maybe<Order_By>;
  Altphn1?: Maybe<Order_By>;
  Altphn2?: Maybe<Order_By>;
  Anyother?: Maybe<Order_By>;
  AreaCount?: Maybe<Order_By>;
  Authorizedcontactperson?: Maybe<Order_By>;
  Bldg2?: Maybe<Order_By>;
  Blockname?: Maybe<Order_By>;
  Blocknumber?: Maybe<Order_By>;
  Blockother?: Maybe<Order_By>;
  Boundary?: Maybe<Order_By>;
  Boundaryfenceother?: Maybe<Order_By>;
  Buildingcondition?: Maybe<Order_By>;
  Buildingname?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CamvSerial?: Maybe<Order_By>;
  Ceiling?: Maybe<Order_By>;
  Ceilingothers?: Maybe<Order_By>;
  Coin?: Maybe<Order_By>;
  Coin1?: Maybe<Order_By>;
  Comment?: Maybe<Order_By>;
  Compound?: Maybe<Order_By>;
  Compoundother?: Maybe<Order_By>;
  Contactdetailsofdirector?: Maybe<Order_By>;
  Country?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  County1?: Maybe<Order_By>;
  Creationdate?: Maybe<Order_By>;
  Creator?: Maybe<Order_By>;
  Ctrycde?: Maybe<Order_By>;
  Ctrycde1?: Maybe<Order_By>;
  Ctrycde2?: Maybe<Order_By>;
  Ctrycde4?: Maybe<Order_By>;
  Datacollector?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  District1?: Maybe<Order_By>;
  Districtorcitywhereboxislocated?: Maybe<Order_By>;
  Districtorcitywhereboxislocated1?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  Doors?: Maybe<Order_By>;
  Doorsothers?: Maybe<Order_By>;
  Editdate?: Maybe<Order_By>;
  Editor?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  Email1?: Maybe<Order_By>;
  Email3?: Maybe<Order_By>;
  Email5?: Maybe<Order_By>;
  Entitytype?: Maybe<Order_By>;
  Environment?: Maybe<Order_By>;
  Facilities?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Firstname3?: Maybe<Order_By>;
  Flatblocknumber?: Maybe<Order_By>;
  Flatnumber?: Maybe<Order_By>;
  Floorconstruction?: Maybe<Order_By>;
  Floorconstructionothers?: Maybe<Order_By>;
  Floorfinish?: Maybe<Order_By>;
  Floorfinishothers?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Games?: Maybe<Order_By>;
  Gamesother?: Maybe<Order_By>;
  Gate?: Maybe<Order_By>;
  Gateother?: Maybe<Order_By>;
  Globalid?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Hsenbr?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Id2?: Maybe<Order_By>;
  Id3?: Maybe<Order_By>;
  Land4?: Maybe<Order_By>;
  Landline?: Maybe<Order_By>;
  Landline1?: Maybe<Order_By>;
  Landline4?: Maybe<Order_By>;
  Landlinecode?: Maybe<Order_By>;
  Landlinecode4?: Maybe<Order_By>;
  Landregister?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  LatitudeA?: Maybe<Order_By>;
  Latlong?: Maybe<Order_By>;
  Legalname?: Maybe<Order_By>;
  Levelofcompletion?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Lln?: Maybe<Order_By>;
  Lln1?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  LongitudeB?: Maybe<Order_By>;
  Lvls?: Maybe<Order_By>;
  Mainbusinessname?: Maybe<Order_By>;
  Mob?: Maybe<Order_By>;
  Mob1?: Maybe<Order_By>;
  Mob3?: Maybe<Order_By>;
  Mob4?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobile1?: Maybe<Order_By>;
  Mobile1code?: Maybe<Order_By>;
  Mobile3?: Maybe<Order_By>;
  Mobile3code?: Maybe<Order_By>;
  Mobile4?: Maybe<Order_By>;
  Mobile4code?: Maybe<Order_By>;
  Mobile5?: Maybe<Order_By>;
  Mobile5code?: Maybe<Order_By>;
  Mobilecode?: Maybe<Order_By>;
  Name2?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Nationalid2?: Maybe<Order_By>;
  Nbrofslots?: Maybe<Order_By>;
  Neighbourhoodactivities?: Maybe<Order_By>;
  Neighbourhoodstatus?: Maybe<Order_By>;
  Nin?: Maybe<Order_By>;
  Nin2?: Maybe<Order_By>;
  Objectid?: Maybe<Order_By>;
  Other?: Maybe<Order_By>;
  Otherfacilities?: Maybe<Order_By>;
  Otherid?: Maybe<Order_By>;
  Otherid1?: Maybe<Order_By>;
  Otherid2?: Maybe<Order_By>;
  Otherid3?: Maybe<Order_By>;
  Otherid4?: Maybe<Order_By>;
  Otherid4nbr?: Maybe<Order_By>;
  Otherid6?: Maybe<Order_By>;
  Otherid7?: Maybe<Order_By>;
  Othernames?: Maybe<Order_By>;
  Othernames3?: Maybe<Order_By>;
  Otherservices?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Parish1?: Maybe<Order_By>;
  Parish2?: Maybe<Order_By>;
  Parking?: Maybe<Order_By>;
  Parkingsufficient?: Maybe<Order_By>;
  Partiiindividualownershipdetails?: Maybe<Order_By>;
  Partinonindividualownershipdetails?: Maybe<Order_By>;
  Pass?: Maybe<Order_By>;
  Plotnumber?: Maybe<Order_By>;
  Postalofficeboxnumber?: Maybe<Order_By>;
  Postalofficeboxnumber1?: Maybe<Order_By>;
  Powersupply?: Maybe<Order_By>;
  Propertyname?: Maybe<Order_By>;
  Propertysubtype?: Maybe<Order_By>;
  Propertytype?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  Relationshiptoowner?: Maybe<Order_By>;
  Roadfrontageorgrid?: Maybe<Order_By>;
  Roadname?: Maybe<Order_By>;
  Roofcovering?: Maybe<Order_By>;
  Roofcoveringothers?: Maybe<Order_By>;
  Sanitation?: Maybe<Order_By>;
  Sectionapropertyownersdetails?: Maybe<Order_By>;
  Security?: Maybe<Order_By>;
  Shape?: Maybe<Order_By>;
  Shapeofplot?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Subcounty1?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Surname3?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Title?: Maybe<Order_By>;
  Title1?: Maybe<Order_By>;
  Topography?: Maybe<Order_By>;
  TotalBuiltUpArea?: Maybe<Order_By>;
  TotalGrossArea?: Maybe<Order_By>;
  Typeofaccess?: Maybe<Order_By>;
  Typeofbuilding?: Maybe<Order_By>;
  Typeofcompany?: Maybe<Order_By>;
  Typeoflandinterest?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
  Verticalcirculation?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  Village1?: Maybe<Order_By>;
  Village2?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  Wallfinish?: Maybe<Order_By>;
  Wallfinishothers?: Maybe<Order_By>;
  Walls?: Maybe<Order_By>;
  Wallsothers?: Maybe<Order_By>;
  Wateraccessibility?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  Windows?: Maybe<Order_By>;
  Windowsothers?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_RawProperties2" */
export enum Rtcs_Db_Kcca_RawProperties2_Select_Column {
  /** column name */
  Access = "Access",
  /** column name */
  Agent = "Agent",
  /** column name */
  Alternative = "Alternative",
  /** column name */
  Alternativeemailaddress = "Alternativeemailaddress",
  /** column name */
  Alternativefirstname = "Alternativefirstname",
  /** column name */
  Alternativelandlinenumber = "Alternativelandlinenumber",
  /** column name */
  Alternativelandlinenumberwithcountrycode = "Alternativelandlinenumberwithcountrycode",
  /** column name */
  Alternativeothername = "Alternativeothername",
  /** column name */
  Alternativephonenumber1 = "Alternativephonenumber1",
  /** column name */
  Alternativephonenumber1code = "Alternativephonenumber1code",
  /** column name */
  Alternativephonenumber2 = "Alternativephonenumber2",
  /** column name */
  Alternativephonenumber2code = "Alternativephonenumber2code",
  /** column name */
  Alternativesurname = "Alternativesurname",
  /** column name */
  Altphn1 = "Altphn1",
  /** column name */
  Altphn2 = "Altphn2",
  /** column name */
  Anyother = "Anyother",
  /** column name */
  AreaCount = "AreaCount",
  /** column name */
  Authorizedcontactperson = "Authorizedcontactperson",
  /** column name */
  Bldg2 = "Bldg2",
  /** column name */
  Blockname = "Blockname",
  /** column name */
  Blocknumber = "Blocknumber",
  /** column name */
  Blockother = "Blockother",
  /** column name */
  Boundary = "Boundary",
  /** column name */
  Boundaryfenceother = "Boundaryfenceother",
  /** column name */
  Buildingcondition = "Buildingcondition",
  /** column name */
  Buildingname = "Buildingname",
  /** column name */
  CamvId = "CamvId",
  /** column name */
  CamvSerial = "CamvSerial",
  /** column name */
  Ceiling = "Ceiling",
  /** column name */
  Ceilingothers = "Ceilingothers",
  /** column name */
  Coin = "Coin",
  /** column name */
  Coin1 = "Coin1",
  /** column name */
  Comment = "Comment",
  /** column name */
  Compound = "Compound",
  /** column name */
  Compoundother = "Compoundother",
  /** column name */
  Contactdetailsofdirector = "Contactdetailsofdirector",
  /** column name */
  Country = "Country",
  /** column name */
  County = "County",
  /** column name */
  County1 = "County1",
  /** column name */
  Creationdate = "Creationdate",
  /** column name */
  Creator = "Creator",
  /** column name */
  Ctrycde = "Ctrycde",
  /** column name */
  Ctrycde1 = "Ctrycde1",
  /** column name */
  Ctrycde2 = "Ctrycde2",
  /** column name */
  Ctrycde4 = "Ctrycde4",
  /** column name */
  Datacollector = "Datacollector",
  /** column name */
  District = "District",
  /** column name */
  District1 = "District1",
  /** column name */
  Districtorcitywhereboxislocated = "Districtorcitywhereboxislocated",
  /** column name */
  Districtorcitywhereboxislocated1 = "Districtorcitywhereboxislocated1",
  /** column name */
  Division = "Division",
  /** column name */
  Doors = "Doors",
  /** column name */
  Doorsothers = "Doorsothers",
  /** column name */
  Editdate = "Editdate",
  /** column name */
  Editor = "Editor",
  /** column name */
  Email = "Email",
  /** column name */
  Email1 = "Email1",
  /** column name */
  Email3 = "Email3",
  /** column name */
  Email5 = "Email5",
  /** column name */
  Entitytype = "Entitytype",
  /** column name */
  Environment = "Environment",
  /** column name */
  Facilities = "Facilities",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Firstname3 = "Firstname3",
  /** column name */
  Flatblocknumber = "Flatblocknumber",
  /** column name */
  Flatnumber = "Flatnumber",
  /** column name */
  Floorconstruction = "Floorconstruction",
  /** column name */
  Floorconstructionothers = "Floorconstructionothers",
  /** column name */
  Floorfinish = "Floorfinish",
  /** column name */
  Floorfinishothers = "Floorfinishothers",
  /** column name */
  Folio = "Folio",
  /** column name */
  Games = "Games",
  /** column name */
  Gamesother = "Gamesother",
  /** column name */
  Gate = "Gate",
  /** column name */
  Gateother = "Gateother",
  /** column name */
  Globalid = "Globalid",
  /** column name */
  Housenumber = "Housenumber",
  /** column name */
  Hsenbr = "Hsenbr",
  /** column name */
  Id = "Id",
  /** column name */
  Id2 = "Id2",
  /** column name */
  Id3 = "Id3",
  /** column name */
  Land4 = "Land4",
  /** column name */
  Landline = "Landline",
  /** column name */
  Landline1 = "Landline1",
  /** column name */
  Landline4 = "Landline4",
  /** column name */
  Landlinecode = "Landlinecode",
  /** column name */
  Landlinecode4 = "Landlinecode4",
  /** column name */
  Landregister = "Landregister",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  LatitudeA = "LatitudeA",
  /** column name */
  Latlong = "Latlong",
  /** column name */
  Legalname = "Legalname",
  /** column name */
  Levelofcompletion = "Levelofcompletion",
  /** column name */
  Line = "Line",
  /** column name */
  Lln = "Lln",
  /** column name */
  Lln1 = "Lln1",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  LongitudeB = "LongitudeB",
  /** column name */
  Lvls = "Lvls",
  /** column name */
  Mainbusinessname = "Mainbusinessname",
  /** column name */
  Mob = "Mob",
  /** column name */
  Mob1 = "Mob1",
  /** column name */
  Mob3 = "Mob3",
  /** column name */
  Mob4 = "Mob4",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  Mobile1 = "Mobile1",
  /** column name */
  Mobile1code = "Mobile1code",
  /** column name */
  Mobile3 = "Mobile3",
  /** column name */
  Mobile3code = "Mobile3code",
  /** column name */
  Mobile4 = "Mobile4",
  /** column name */
  Mobile4code = "Mobile4code",
  /** column name */
  Mobile5 = "Mobile5",
  /** column name */
  Mobile5code = "Mobile5code",
  /** column name */
  Mobilecode = "Mobilecode",
  /** column name */
  Name2 = "Name2",
  /** column name */
  Nationalid = "Nationalid",
  /** column name */
  Nationalid2 = "Nationalid2",
  /** column name */
  Nbrofslots = "Nbrofslots",
  /** column name */
  Neighbourhoodactivities = "Neighbourhoodactivities",
  /** column name */
  Neighbourhoodstatus = "Neighbourhoodstatus",
  /** column name */
  Nin = "Nin",
  /** column name */
  Nin2 = "Nin2",
  /** column name */
  Objectid = "Objectid",
  /** column name */
  Other = "Other",
  /** column name */
  Otherfacilities = "Otherfacilities",
  /** column name */
  Otherid = "Otherid",
  /** column name */
  Otherid1 = "Otherid1",
  /** column name */
  Otherid2 = "Otherid2",
  /** column name */
  Otherid3 = "Otherid3",
  /** column name */
  Otherid4 = "Otherid4",
  /** column name */
  Otherid4nbr = "Otherid4nbr",
  /** column name */
  Otherid6 = "Otherid6",
  /** column name */
  Otherid7 = "Otherid7",
  /** column name */
  Othernames = "Othernames",
  /** column name */
  Othernames3 = "Othernames3",
  /** column name */
  Otherservices = "Otherservices",
  /** column name */
  Parish = "Parish",
  /** column name */
  Parish1 = "Parish1",
  /** column name */
  Parish2 = "Parish2",
  /** column name */
  Parking = "Parking",
  /** column name */
  Parkingsufficient = "Parkingsufficient",
  /** column name */
  Partiiindividualownershipdetails = "Partiiindividualownershipdetails",
  /** column name */
  Partinonindividualownershipdetails = "Partinonindividualownershipdetails",
  /** column name */
  Pass = "Pass",
  /** column name */
  Plotnumber = "Plotnumber",
  /** column name */
  Postalofficeboxnumber = "Postalofficeboxnumber",
  /** column name */
  Postalofficeboxnumber1 = "Postalofficeboxnumber1",
  /** column name */
  Powersupply = "Powersupply",
  /** column name */
  Propertyname = "Propertyname",
  /** column name */
  Propertysubtype = "Propertysubtype",
  /** column name */
  Propertytype = "Propertytype",
  /** column name */
  Reference = "Reference",
  /** column name */
  Relationshiptoowner = "Relationshiptoowner",
  /** column name */
  Roadfrontageorgrid = "Roadfrontageorgrid",
  /** column name */
  Roadname = "Roadname",
  /** column name */
  Roofcovering = "Roofcovering",
  /** column name */
  Roofcoveringothers = "Roofcoveringothers",
  /** column name */
  Sanitation = "Sanitation",
  /** column name */
  Sectionapropertyownersdetails = "Sectionapropertyownersdetails",
  /** column name */
  Security = "Security",
  /** column name */
  Shape = "Shape",
  /** column name */
  Shapeofplot = "Shapeofplot",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  Subcounty = "Subcounty",
  /** column name */
  Subcounty1 = "Subcounty1",
  /** column name */
  Surname = "Surname",
  /** column name */
  Surname3 = "Surname3",
  /** column name */
  Tin = "Tin",
  /** column name */
  Title = "Title",
  /** column name */
  Title1 = "Title1",
  /** column name */
  Topography = "Topography",
  /** column name */
  TotalBuiltUpArea = "TotalBuiltUpArea",
  /** column name */
  TotalGrossArea = "TotalGrossArea",
  /** column name */
  Typeofaccess = "Typeofaccess",
  /** column name */
  Typeofbuilding = "Typeofbuilding",
  /** column name */
  Typeofcompany = "Typeofcompany",
  /** column name */
  Typeoflandinterest = "Typeoflandinterest",
  /** column name */
  Username = "Username",
  /** column name */
  Verticalcirculation = "Verticalcirculation",
  /** column name */
  Village = "Village",
  /** column name */
  Village1 = "Village1",
  /** column name */
  Village2 = "Village2",
  /** column name */
  Volume = "Volume",
  /** column name */
  Wallfinish = "Wallfinish",
  /** column name */
  Wallfinishothers = "Wallfinishothers",
  /** column name */
  Walls = "Walls",
  /** column name */
  Wallsothers = "Wallsothers",
  /** column name */
  Wateraccessibility = "Wateraccessibility",
  /** column name */
  WhoUpdated = "WhoUpdated",
  /** column name */
  Windows = "Windows",
  /** column name */
  Windowsothers = "Windowsothers",
}

/** input type for updating data in table "rtcs_db.kcca_RawProperties2" */
export type Rtcs_Db_Kcca_RawProperties2_Set_Input = {
  Access?: Maybe<Scalars["String"]>;
  Agent?: Maybe<Scalars["String"]>;
  Alternative?: Maybe<Scalars["String"]>;
  Alternativeemailaddress?: Maybe<Scalars["String"]>;
  Alternativefirstname?: Maybe<Scalars["String"]>;
  Alternativelandlinenumber?: Maybe<Scalars["float8"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["float8"]>;
  Alternativeothername?: Maybe<Scalars["String"]>;
  Alternativephonenumber1?: Maybe<Scalars["float8"]>;
  Alternativephonenumber1code?: Maybe<Scalars["String"]>;
  Alternativephonenumber2?: Maybe<Scalars["String"]>;
  Alternativephonenumber2code?: Maybe<Scalars["String"]>;
  Alternativesurname?: Maybe<Scalars["String"]>;
  Altphn1?: Maybe<Scalars["float8"]>;
  Altphn2?: Maybe<Scalars["float8"]>;
  Anyother?: Maybe<Scalars["String"]>;
  AreaCount?: Maybe<Scalars["String"]>;
  Authorizedcontactperson?: Maybe<Scalars["String"]>;
  Bldg2?: Maybe<Scalars["String"]>;
  Blockname?: Maybe<Scalars["String"]>;
  Blocknumber?: Maybe<Scalars["String"]>;
  Blockother?: Maybe<Scalars["String"]>;
  Boundary?: Maybe<Scalars["String"]>;
  Boundaryfenceother?: Maybe<Scalars["String"]>;
  Buildingcondition?: Maybe<Scalars["String"]>;
  Buildingname?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["bigint"]>;
  CamvSerial?: Maybe<Scalars["String"]>;
  Ceiling?: Maybe<Scalars["String"]>;
  Ceilingothers?: Maybe<Scalars["String"]>;
  Coin?: Maybe<Scalars["String"]>;
  Coin1?: Maybe<Scalars["String"]>;
  Comment?: Maybe<Scalars["String"]>;
  Compound?: Maybe<Scalars["String"]>;
  Compoundother?: Maybe<Scalars["String"]>;
  Contactdetailsofdirector?: Maybe<Scalars["String"]>;
  Country?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  County1?: Maybe<Scalars["String"]>;
  Creationdate?: Maybe<Scalars["timestamptz"]>;
  Creator?: Maybe<Scalars["String"]>;
  Ctrycde?: Maybe<Scalars["String"]>;
  Ctrycde1?: Maybe<Scalars["String"]>;
  Ctrycde2?: Maybe<Scalars["String"]>;
  Ctrycde4?: Maybe<Scalars["String"]>;
  Datacollector?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  District1?: Maybe<Scalars["String"]>;
  Districtorcitywhereboxislocated?: Maybe<Scalars["String"]>;
  Districtorcitywhereboxislocated1?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Doors?: Maybe<Scalars["String"]>;
  Doorsothers?: Maybe<Scalars["String"]>;
  Editdate?: Maybe<Scalars["timestamptz"]>;
  Editor?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Email1?: Maybe<Scalars["String"]>;
  Email3?: Maybe<Scalars["String"]>;
  Email5?: Maybe<Scalars["String"]>;
  Entitytype?: Maybe<Scalars["String"]>;
  Environment?: Maybe<Scalars["String"]>;
  Facilities?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Firstname3?: Maybe<Scalars["String"]>;
  Flatblocknumber?: Maybe<Scalars["String"]>;
  Flatnumber?: Maybe<Scalars["String"]>;
  Floorconstruction?: Maybe<Scalars["String"]>;
  Floorconstructionothers?: Maybe<Scalars["String"]>;
  Floorfinish?: Maybe<Scalars["String"]>;
  Floorfinishothers?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Games?: Maybe<Scalars["String"]>;
  Gamesother?: Maybe<Scalars["String"]>;
  Gate?: Maybe<Scalars["String"]>;
  Gateother?: Maybe<Scalars["String"]>;
  Globalid?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Hsenbr?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Id2?: Maybe<Scalars["String"]>;
  Id3?: Maybe<Scalars["bigint"]>;
  Land4?: Maybe<Scalars["float8"]>;
  Landline?: Maybe<Scalars["String"]>;
  Landline1?: Maybe<Scalars["String"]>;
  Landline4?: Maybe<Scalars["String"]>;
  Landlinecode?: Maybe<Scalars["String"]>;
  Landlinecode4?: Maybe<Scalars["String"]>;
  Landregister?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  LatitudeA?: Maybe<Scalars["float8"]>;
  Latlong?: Maybe<Scalars["String"]>;
  Legalname?: Maybe<Scalars["String"]>;
  Levelofcompletion?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Lln?: Maybe<Scalars["String"]>;
  Lln1?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["String"]>;
  LongitudeB?: Maybe<Scalars["float8"]>;
  Lvls?: Maybe<Scalars["String"]>;
  Mainbusinessname?: Maybe<Scalars["String"]>;
  Mob?: Maybe<Scalars["float8"]>;
  Mob1?: Maybe<Scalars["float8"]>;
  Mob3?: Maybe<Scalars["String"]>;
  Mob4?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  Mobile1?: Maybe<Scalars["String"]>;
  Mobile1code?: Maybe<Scalars["String"]>;
  Mobile3?: Maybe<Scalars["String"]>;
  Mobile3code?: Maybe<Scalars["String"]>;
  Mobile4?: Maybe<Scalars["String"]>;
  Mobile4code?: Maybe<Scalars["String"]>;
  Mobile5?: Maybe<Scalars["String"]>;
  Mobile5code?: Maybe<Scalars["String"]>;
  Mobilecode?: Maybe<Scalars["String"]>;
  Name2?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["float8"]>;
  Nationalid2?: Maybe<Scalars["float8"]>;
  Nbrofslots?: Maybe<Scalars["String"]>;
  Neighbourhoodactivities?: Maybe<Scalars["String"]>;
  Neighbourhoodstatus?: Maybe<Scalars["String"]>;
  Nin?: Maybe<Scalars["String"]>;
  Nin2?: Maybe<Scalars["String"]>;
  Objectid?: Maybe<Scalars["float8"]>;
  Other?: Maybe<Scalars["String"]>;
  Otherfacilities?: Maybe<Scalars["String"]>;
  Otherid?: Maybe<Scalars["String"]>;
  Otherid1?: Maybe<Scalars["String"]>;
  Otherid2?: Maybe<Scalars["String"]>;
  Otherid3?: Maybe<Scalars["float8"]>;
  Otherid4?: Maybe<Scalars["String"]>;
  Otherid4nbr?: Maybe<Scalars["String"]>;
  Otherid6?: Maybe<Scalars["String"]>;
  Otherid7?: Maybe<Scalars["float8"]>;
  Othernames?: Maybe<Scalars["String"]>;
  Othernames3?: Maybe<Scalars["String"]>;
  Otherservices?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Parish1?: Maybe<Scalars["String"]>;
  Parish2?: Maybe<Scalars["String"]>;
  Parking?: Maybe<Scalars["String"]>;
  Parkingsufficient?: Maybe<Scalars["String"]>;
  Partiiindividualownershipdetails?: Maybe<Scalars["String"]>;
  Partinonindividualownershipdetails?: Maybe<Scalars["String"]>;
  Pass?: Maybe<Scalars["float8"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Postalofficeboxnumber?: Maybe<Scalars["String"]>;
  Postalofficeboxnumber1?: Maybe<Scalars["String"]>;
  Powersupply?: Maybe<Scalars["String"]>;
  Propertyname?: Maybe<Scalars["String"]>;
  Propertysubtype?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Reference?: Maybe<Scalars["bigint"]>;
  Relationshiptoowner?: Maybe<Scalars["String"]>;
  Roadfrontageorgrid?: Maybe<Scalars["String"]>;
  Roadname?: Maybe<Scalars["String"]>;
  Roofcovering?: Maybe<Scalars["String"]>;
  Roofcoveringothers?: Maybe<Scalars["String"]>;
  Sanitation?: Maybe<Scalars["String"]>;
  Sectionapropertyownersdetails?: Maybe<Scalars["String"]>;
  Security?: Maybe<Scalars["String"]>;
  Shape?: Maybe<Scalars["String"]>;
  Shapeofplot?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Subcounty1?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Surname3?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Title1?: Maybe<Scalars["String"]>;
  Topography?: Maybe<Scalars["String"]>;
  TotalBuiltUpArea?: Maybe<Scalars["float8"]>;
  TotalGrossArea?: Maybe<Scalars["float8"]>;
  Typeofaccess?: Maybe<Scalars["String"]>;
  Typeofbuilding?: Maybe<Scalars["String"]>;
  Typeofcompany?: Maybe<Scalars["String"]>;
  Typeoflandinterest?: Maybe<Scalars["String"]>;
  Username?: Maybe<Scalars["float8"]>;
  Verticalcirculation?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  Village1?: Maybe<Scalars["String"]>;
  Village2?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  Wallfinish?: Maybe<Scalars["String"]>;
  Wallfinishothers?: Maybe<Scalars["String"]>;
  Walls?: Maybe<Scalars["String"]>;
  Wallsothers?: Maybe<Scalars["String"]>;
  Wateraccessibility?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Windows?: Maybe<Scalars["String"]>;
  Windowsothers?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_RawProperties2_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_RawProperties2_stddev_fields";
  Alternativelandlinenumber?: Maybe<Scalars["Float"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["Float"]>;
  Alternativephonenumber1?: Maybe<Scalars["Float"]>;
  Altphn1?: Maybe<Scalars["Float"]>;
  Altphn2?: Maybe<Scalars["Float"]>;
  CamvId?: Maybe<Scalars["Float"]>;
  Id3?: Maybe<Scalars["Float"]>;
  Land4?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  LatitudeA?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LongitudeB?: Maybe<Scalars["Float"]>;
  Mob?: Maybe<Scalars["Float"]>;
  Mob1?: Maybe<Scalars["Float"]>;
  Nationalid?: Maybe<Scalars["Float"]>;
  Nationalid2?: Maybe<Scalars["Float"]>;
  Objectid?: Maybe<Scalars["Float"]>;
  Otherid3?: Maybe<Scalars["Float"]>;
  Otherid7?: Maybe<Scalars["Float"]>;
  Pass?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
  TotalBuiltUpArea?: Maybe<Scalars["Float"]>;
  TotalGrossArea?: Maybe<Scalars["Float"]>;
  Username?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_RawProperties2" */
export type Rtcs_Db_Kcca_RawProperties2_Stddev_Order_By = {
  Alternativelandlinenumber?: Maybe<Order_By>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Order_By>;
  Alternativephonenumber1?: Maybe<Order_By>;
  Altphn1?: Maybe<Order_By>;
  Altphn2?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  Id3?: Maybe<Order_By>;
  Land4?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  LatitudeA?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeB?: Maybe<Order_By>;
  Mob?: Maybe<Order_By>;
  Mob1?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Nationalid2?: Maybe<Order_By>;
  Objectid?: Maybe<Order_By>;
  Otherid3?: Maybe<Order_By>;
  Otherid7?: Maybe<Order_By>;
  Pass?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TotalBuiltUpArea?: Maybe<Order_By>;
  TotalGrossArea?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_RawProperties2_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_RawProperties2_stddev_pop_fields";
  Alternativelandlinenumber?: Maybe<Scalars["Float"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["Float"]>;
  Alternativephonenumber1?: Maybe<Scalars["Float"]>;
  Altphn1?: Maybe<Scalars["Float"]>;
  Altphn2?: Maybe<Scalars["Float"]>;
  CamvId?: Maybe<Scalars["Float"]>;
  Id3?: Maybe<Scalars["Float"]>;
  Land4?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  LatitudeA?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LongitudeB?: Maybe<Scalars["Float"]>;
  Mob?: Maybe<Scalars["Float"]>;
  Mob1?: Maybe<Scalars["Float"]>;
  Nationalid?: Maybe<Scalars["Float"]>;
  Nationalid2?: Maybe<Scalars["Float"]>;
  Objectid?: Maybe<Scalars["Float"]>;
  Otherid3?: Maybe<Scalars["Float"]>;
  Otherid7?: Maybe<Scalars["Float"]>;
  Pass?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
  TotalBuiltUpArea?: Maybe<Scalars["Float"]>;
  TotalGrossArea?: Maybe<Scalars["Float"]>;
  Username?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_RawProperties2" */
export type Rtcs_Db_Kcca_RawProperties2_Stddev_Pop_Order_By = {
  Alternativelandlinenumber?: Maybe<Order_By>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Order_By>;
  Alternativephonenumber1?: Maybe<Order_By>;
  Altphn1?: Maybe<Order_By>;
  Altphn2?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  Id3?: Maybe<Order_By>;
  Land4?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  LatitudeA?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeB?: Maybe<Order_By>;
  Mob?: Maybe<Order_By>;
  Mob1?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Nationalid2?: Maybe<Order_By>;
  Objectid?: Maybe<Order_By>;
  Otherid3?: Maybe<Order_By>;
  Otherid7?: Maybe<Order_By>;
  Pass?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TotalBuiltUpArea?: Maybe<Order_By>;
  TotalGrossArea?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_RawProperties2_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_RawProperties2_stddev_samp_fields";
  Alternativelandlinenumber?: Maybe<Scalars["Float"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["Float"]>;
  Alternativephonenumber1?: Maybe<Scalars["Float"]>;
  Altphn1?: Maybe<Scalars["Float"]>;
  Altphn2?: Maybe<Scalars["Float"]>;
  CamvId?: Maybe<Scalars["Float"]>;
  Id3?: Maybe<Scalars["Float"]>;
  Land4?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  LatitudeA?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LongitudeB?: Maybe<Scalars["Float"]>;
  Mob?: Maybe<Scalars["Float"]>;
  Mob1?: Maybe<Scalars["Float"]>;
  Nationalid?: Maybe<Scalars["Float"]>;
  Nationalid2?: Maybe<Scalars["Float"]>;
  Objectid?: Maybe<Scalars["Float"]>;
  Otherid3?: Maybe<Scalars["Float"]>;
  Otherid7?: Maybe<Scalars["Float"]>;
  Pass?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
  TotalBuiltUpArea?: Maybe<Scalars["Float"]>;
  TotalGrossArea?: Maybe<Scalars["Float"]>;
  Username?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_RawProperties2" */
export type Rtcs_Db_Kcca_RawProperties2_Stddev_Samp_Order_By = {
  Alternativelandlinenumber?: Maybe<Order_By>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Order_By>;
  Alternativephonenumber1?: Maybe<Order_By>;
  Altphn1?: Maybe<Order_By>;
  Altphn2?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  Id3?: Maybe<Order_By>;
  Land4?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  LatitudeA?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeB?: Maybe<Order_By>;
  Mob?: Maybe<Order_By>;
  Mob1?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Nationalid2?: Maybe<Order_By>;
  Objectid?: Maybe<Order_By>;
  Otherid3?: Maybe<Order_By>;
  Otherid7?: Maybe<Order_By>;
  Pass?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TotalBuiltUpArea?: Maybe<Order_By>;
  TotalGrossArea?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_RawProperties2_Sum_Fields = {
  __typename?: "rtcs_db_kcca_RawProperties2_sum_fields";
  Alternativelandlinenumber?: Maybe<Scalars["float8"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["float8"]>;
  Alternativephonenumber1?: Maybe<Scalars["float8"]>;
  Altphn1?: Maybe<Scalars["float8"]>;
  Altphn2?: Maybe<Scalars["float8"]>;
  CamvId?: Maybe<Scalars["bigint"]>;
  Id3?: Maybe<Scalars["bigint"]>;
  Land4?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  LatitudeA?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LongitudeB?: Maybe<Scalars["float8"]>;
  Mob?: Maybe<Scalars["float8"]>;
  Mob1?: Maybe<Scalars["float8"]>;
  Nationalid?: Maybe<Scalars["float8"]>;
  Nationalid2?: Maybe<Scalars["float8"]>;
  Objectid?: Maybe<Scalars["float8"]>;
  Otherid3?: Maybe<Scalars["float8"]>;
  Otherid7?: Maybe<Scalars["float8"]>;
  Pass?: Maybe<Scalars["float8"]>;
  Reference?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  Tin?: Maybe<Scalars["float8"]>;
  TotalBuiltUpArea?: Maybe<Scalars["float8"]>;
  TotalGrossArea?: Maybe<Scalars["float8"]>;
  Username?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_RawProperties2" */
export type Rtcs_Db_Kcca_RawProperties2_Sum_Order_By = {
  Alternativelandlinenumber?: Maybe<Order_By>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Order_By>;
  Alternativephonenumber1?: Maybe<Order_By>;
  Altphn1?: Maybe<Order_By>;
  Altphn2?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  Id3?: Maybe<Order_By>;
  Land4?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  LatitudeA?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeB?: Maybe<Order_By>;
  Mob?: Maybe<Order_By>;
  Mob1?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Nationalid2?: Maybe<Order_By>;
  Objectid?: Maybe<Order_By>;
  Otherid3?: Maybe<Order_By>;
  Otherid7?: Maybe<Order_By>;
  Pass?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TotalBuiltUpArea?: Maybe<Order_By>;
  TotalGrossArea?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_RawProperties2_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_RawProperties2_var_pop_fields";
  Alternativelandlinenumber?: Maybe<Scalars["Float"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["Float"]>;
  Alternativephonenumber1?: Maybe<Scalars["Float"]>;
  Altphn1?: Maybe<Scalars["Float"]>;
  Altphn2?: Maybe<Scalars["Float"]>;
  CamvId?: Maybe<Scalars["Float"]>;
  Id3?: Maybe<Scalars["Float"]>;
  Land4?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  LatitudeA?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LongitudeB?: Maybe<Scalars["Float"]>;
  Mob?: Maybe<Scalars["Float"]>;
  Mob1?: Maybe<Scalars["Float"]>;
  Nationalid?: Maybe<Scalars["Float"]>;
  Nationalid2?: Maybe<Scalars["Float"]>;
  Objectid?: Maybe<Scalars["Float"]>;
  Otherid3?: Maybe<Scalars["Float"]>;
  Otherid7?: Maybe<Scalars["Float"]>;
  Pass?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
  TotalBuiltUpArea?: Maybe<Scalars["Float"]>;
  TotalGrossArea?: Maybe<Scalars["Float"]>;
  Username?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_RawProperties2" */
export type Rtcs_Db_Kcca_RawProperties2_Var_Pop_Order_By = {
  Alternativelandlinenumber?: Maybe<Order_By>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Order_By>;
  Alternativephonenumber1?: Maybe<Order_By>;
  Altphn1?: Maybe<Order_By>;
  Altphn2?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  Id3?: Maybe<Order_By>;
  Land4?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  LatitudeA?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeB?: Maybe<Order_By>;
  Mob?: Maybe<Order_By>;
  Mob1?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Nationalid2?: Maybe<Order_By>;
  Objectid?: Maybe<Order_By>;
  Otherid3?: Maybe<Order_By>;
  Otherid7?: Maybe<Order_By>;
  Pass?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TotalBuiltUpArea?: Maybe<Order_By>;
  TotalGrossArea?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_RawProperties2_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_RawProperties2_var_samp_fields";
  Alternativelandlinenumber?: Maybe<Scalars["Float"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["Float"]>;
  Alternativephonenumber1?: Maybe<Scalars["Float"]>;
  Altphn1?: Maybe<Scalars["Float"]>;
  Altphn2?: Maybe<Scalars["Float"]>;
  CamvId?: Maybe<Scalars["Float"]>;
  Id3?: Maybe<Scalars["Float"]>;
  Land4?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  LatitudeA?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LongitudeB?: Maybe<Scalars["Float"]>;
  Mob?: Maybe<Scalars["Float"]>;
  Mob1?: Maybe<Scalars["Float"]>;
  Nationalid?: Maybe<Scalars["Float"]>;
  Nationalid2?: Maybe<Scalars["Float"]>;
  Objectid?: Maybe<Scalars["Float"]>;
  Otherid3?: Maybe<Scalars["Float"]>;
  Otherid7?: Maybe<Scalars["Float"]>;
  Pass?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
  TotalBuiltUpArea?: Maybe<Scalars["Float"]>;
  TotalGrossArea?: Maybe<Scalars["Float"]>;
  Username?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_RawProperties2" */
export type Rtcs_Db_Kcca_RawProperties2_Var_Samp_Order_By = {
  Alternativelandlinenumber?: Maybe<Order_By>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Order_By>;
  Alternativephonenumber1?: Maybe<Order_By>;
  Altphn1?: Maybe<Order_By>;
  Altphn2?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  Id3?: Maybe<Order_By>;
  Land4?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  LatitudeA?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeB?: Maybe<Order_By>;
  Mob?: Maybe<Order_By>;
  Mob1?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Nationalid2?: Maybe<Order_By>;
  Objectid?: Maybe<Order_By>;
  Otherid3?: Maybe<Order_By>;
  Otherid7?: Maybe<Order_By>;
  Pass?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TotalBuiltUpArea?: Maybe<Order_By>;
  TotalGrossArea?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_RawProperties2_Variance_Fields = {
  __typename?: "rtcs_db_kcca_RawProperties2_variance_fields";
  Alternativelandlinenumber?: Maybe<Scalars["Float"]>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Scalars["Float"]>;
  Alternativephonenumber1?: Maybe<Scalars["Float"]>;
  Altphn1?: Maybe<Scalars["Float"]>;
  Altphn2?: Maybe<Scalars["Float"]>;
  CamvId?: Maybe<Scalars["Float"]>;
  Id3?: Maybe<Scalars["Float"]>;
  Land4?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  LatitudeA?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LongitudeB?: Maybe<Scalars["Float"]>;
  Mob?: Maybe<Scalars["Float"]>;
  Mob1?: Maybe<Scalars["Float"]>;
  Nationalid?: Maybe<Scalars["Float"]>;
  Nationalid2?: Maybe<Scalars["Float"]>;
  Objectid?: Maybe<Scalars["Float"]>;
  Otherid3?: Maybe<Scalars["Float"]>;
  Otherid7?: Maybe<Scalars["Float"]>;
  Pass?: Maybe<Scalars["Float"]>;
  Reference?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
  TotalBuiltUpArea?: Maybe<Scalars["Float"]>;
  TotalGrossArea?: Maybe<Scalars["Float"]>;
  Username?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_RawProperties2" */
export type Rtcs_Db_Kcca_RawProperties2_Variance_Order_By = {
  Alternativelandlinenumber?: Maybe<Order_By>;
  Alternativelandlinenumberwithcountrycode?: Maybe<Order_By>;
  Alternativephonenumber1?: Maybe<Order_By>;
  Altphn1?: Maybe<Order_By>;
  Altphn2?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  Id3?: Maybe<Order_By>;
  Land4?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  LatitudeA?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeB?: Maybe<Order_By>;
  Mob?: Maybe<Order_By>;
  Mob1?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Nationalid2?: Maybe<Order_By>;
  Objectid?: Maybe<Order_By>;
  Otherid3?: Maybe<Order_By>;
  Otherid7?: Maybe<Order_By>;
  Pass?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TotalBuiltUpArea?: Maybe<Order_By>;
  TotalGrossArea?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_ResidentialProperties" */
export type Rtcs_Db_Kcca_ResidentialProperties = {
  __typename?: "rtcs_db_kcca_ResidentialProperties";
  AccomodationBedrooms?: Maybe<Scalars["String"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["String"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RippleNamiPropertySubtype?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_ResidentialProperties" */
export type Rtcs_Db_Kcca_ResidentialProperties_Aggregate = {
  __typename?: "rtcs_db_kcca_ResidentialProperties_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_ResidentialProperties>;
};

/** aggregate fields of "rtcs_db.kcca_ResidentialProperties" */
export type Rtcs_Db_Kcca_ResidentialProperties_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_ResidentialProperties_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_ResidentialProperties" */
export type Rtcs_Db_Kcca_ResidentialProperties_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_ResidentialProperties_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_ResidentialProperties" */
export type Rtcs_Db_Kcca_ResidentialProperties_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_ResidentialProperties" */
export type Rtcs_Db_Kcca_ResidentialProperties_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_ResidentialProperties_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_ResidentialProperties_Avg_Fields = {
  __typename?: "rtcs_db_kcca_ResidentialProperties_avg_fields";
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_ResidentialProperties" */
export type Rtcs_Db_Kcca_ResidentialProperties_Avg_Order_By = {
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_ResidentialProperties". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_ResidentialProperties_Bool_Exp = {
  AccomodationBedrooms?: Maybe<String_Comparison_Exp>;
  AccomodationBuiltUpArea?: Maybe<String_Comparison_Exp>;
  AccomodationOthers?: Maybe<String_Comparison_Exp>;
  Address?: Maybe<String_Comparison_Exp>;
  BlockNo?: Maybe<String_Comparison_Exp>;
  CamvId?: Maybe<String_Comparison_Exp>;
  CustomerId?: Maybe<Bigint_Comparison_Exp>;
  DerivedNoOfBedrooms?: Maybe<Float8_Comparison_Exp>;
  DivisionId?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  GrossValue?: Maybe<String_Comparison_Exp>;
  Grossrentvalueadjusted?: Maybe<Float8_Comparison_Exp>;
  HouseNo?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  ParishId?: Maybe<Float8_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyNo?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatusId?: Maybe<Float8_Comparison_Exp>;
  PropertyType?: Maybe<String_Comparison_Exp>;
  PropertyTypeId?: Maybe<Float8_Comparison_Exp>;
  Propertyrentedstatus?: Maybe<String_Comparison_Exp>;
  RateableValue?: Maybe<Float8_Comparison_Exp>;
  RippleNamiPropertySubtype?: Maybe<String_Comparison_Exp>;
  SquareMeters?: Maybe<Float8_Comparison_Exp>;
  StreetId?: Maybe<String_Comparison_Exp>;
  VillageId?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_ResidentialProperties_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_ResidentialProperties_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_ResidentialProperties" */
export type Rtcs_Db_Kcca_ResidentialProperties_Inc_Input = {
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_ResidentialProperties" */
export type Rtcs_Db_Kcca_ResidentialProperties_Insert_Input = {
  AccomodationBedrooms?: Maybe<Scalars["String"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["String"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RippleNamiPropertySubtype?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_ResidentialProperties_Max_Fields = {
  __typename?: "rtcs_db_kcca_ResidentialProperties_max_fields";
  AccomodationBedrooms?: Maybe<Scalars["String"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["String"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RippleNamiPropertySubtype?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_ResidentialProperties" */
export type Rtcs_Db_Kcca_ResidentialProperties_Max_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AccomodationOthers?: Maybe<Order_By>;
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RippleNamiPropertySubtype?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_ResidentialProperties_Min_Fields = {
  __typename?: "rtcs_db_kcca_ResidentialProperties_min_fields";
  AccomodationBedrooms?: Maybe<Scalars["String"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["String"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RippleNamiPropertySubtype?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_ResidentialProperties" */
export type Rtcs_Db_Kcca_ResidentialProperties_Min_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AccomodationOthers?: Maybe<Order_By>;
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RippleNamiPropertySubtype?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_ResidentialProperties" */
export type Rtcs_Db_Kcca_ResidentialProperties_Mutation_Response = {
  __typename?: "rtcs_db_kcca_ResidentialProperties_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_ResidentialProperties>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_ResidentialProperties" */
export type Rtcs_Db_Kcca_ResidentialProperties_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_ResidentialProperties_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_ResidentialProperties" */
export type Rtcs_Db_Kcca_ResidentialProperties_Order_By = {
  AccomodationBedrooms?: Maybe<Order_By>;
  AccomodationBuiltUpArea?: Maybe<Order_By>;
  AccomodationOthers?: Maybe<Order_By>;
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RippleNamiPropertySubtype?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_ResidentialProperties" */
export enum Rtcs_Db_Kcca_ResidentialProperties_Select_Column {
  /** column name */
  AccomodationBedrooms = "AccomodationBedrooms",
  /** column name */
  AccomodationBuiltUpArea = "AccomodationBuiltUpArea",
  /** column name */
  AccomodationOthers = "AccomodationOthers",
  /** column name */
  Address = "Address",
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  CamvId = "CamvId",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DerivedNoOfBedrooms = "DerivedNoOfBedrooms",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  File = "File",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  GrossValue = "GrossValue",
  /** column name */
  Grossrentvalueadjusted = "Grossrentvalueadjusted",
  /** column name */
  HouseNo = "HouseNo",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyNo = "PropertyNo",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertyType = "PropertyType",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  Propertyrentedstatus = "Propertyrentedstatus",
  /** column name */
  RateableValue = "RateableValue",
  /** column name */
  RippleNamiPropertySubtype = "RippleNamiPropertySubtype",
  /** column name */
  SquareMeters = "SquareMeters",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  VillageId = "VillageId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_ResidentialProperties" */
export type Rtcs_Db_Kcca_ResidentialProperties_Set_Input = {
  AccomodationBedrooms?: Maybe<Scalars["String"]>;
  AccomodationBuiltUpArea?: Maybe<Scalars["String"]>;
  AccomodationOthers?: Maybe<Scalars["String"]>;
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RippleNamiPropertySubtype?: Maybe<Scalars["String"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_ResidentialProperties_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_ResidentialProperties_stddev_fields";
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_ResidentialProperties" */
export type Rtcs_Db_Kcca_ResidentialProperties_Stddev_Order_By = {
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_ResidentialProperties_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_ResidentialProperties_stddev_pop_fields";
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_ResidentialProperties" */
export type Rtcs_Db_Kcca_ResidentialProperties_Stddev_Pop_Order_By = {
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_ResidentialProperties_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_ResidentialProperties_stddev_samp_fields";
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_ResidentialProperties" */
export type Rtcs_Db_Kcca_ResidentialProperties_Stddev_Samp_Order_By = {
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_ResidentialProperties_Sum_Fields = {
  __typename?: "rtcs_db_kcca_ResidentialProperties_sum_fields";
  CustomerId?: Maybe<Scalars["bigint"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["float8"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  SquareMeters?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_ResidentialProperties" */
export type Rtcs_Db_Kcca_ResidentialProperties_Sum_Order_By = {
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_ResidentialProperties_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_ResidentialProperties_var_pop_fields";
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_ResidentialProperties" */
export type Rtcs_Db_Kcca_ResidentialProperties_Var_Pop_Order_By = {
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_ResidentialProperties_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_ResidentialProperties_var_samp_fields";
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_ResidentialProperties" */
export type Rtcs_Db_Kcca_ResidentialProperties_Var_Samp_Order_By = {
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_ResidentialProperties_Variance_Fields = {
  __typename?: "rtcs_db_kcca_ResidentialProperties_variance_fields";
  CustomerId?: Maybe<Scalars["Float"]>;
  DerivedNoOfBedrooms?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  SquareMeters?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_ResidentialProperties" */
export type Rtcs_Db_Kcca_ResidentialProperties_Variance_Order_By = {
  CustomerId?: Maybe<Order_By>;
  DerivedNoOfBedrooms?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  SquareMeters?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_RevenueType" */
export type Rtcs_Db_Kcca_RevenueType = {
  __typename?: "rtcs_db_kcca_RevenueType";
  Code?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubRevenue?: Maybe<Scalars["String"]>;
  SystemCode?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_RevenueType" */
export type Rtcs_Db_Kcca_RevenueType_Aggregate = {
  __typename?: "rtcs_db_kcca_RevenueType_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_RevenueType_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_RevenueType>;
};

/** aggregate fields of "rtcs_db.kcca_RevenueType" */
export type Rtcs_Db_Kcca_RevenueType_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_RevenueType_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_RevenueType_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_RevenueType_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_RevenueType_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_RevenueType_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_RevenueType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_RevenueType_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_RevenueType_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_RevenueType_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_RevenueType_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_RevenueType_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_RevenueType" */
export type Rtcs_Db_Kcca_RevenueType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_RevenueType_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_RevenueType" */
export type Rtcs_Db_Kcca_RevenueType_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_RevenueType_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_RevenueType_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_RevenueType_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_RevenueType_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_RevenueType_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_RevenueType_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_RevenueType_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_RevenueType_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_RevenueType_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_RevenueType_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_RevenueType" */
export type Rtcs_Db_Kcca_RevenueType_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_RevenueType_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_RevenueType_Avg_Fields = {
  __typename?: "rtcs_db_kcca_RevenueType_avg_fields";
  Code?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_RevenueType" */
export type Rtcs_Db_Kcca_RevenueType_Avg_Order_By = {
  Code?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_RevenueType". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_RevenueType_Bool_Exp = {
  Code?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Float8_Comparison_Exp>;
  SubRevenue?: Maybe<String_Comparison_Exp>;
  SystemCode?: Maybe<String_Comparison_Exp>;
  Type?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_RevenueType_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_RevenueType_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_RevenueType_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_RevenueType" */
export type Rtcs_Db_Kcca_RevenueType_Inc_Input = {
  Code?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_RevenueType" */
export type Rtcs_Db_Kcca_RevenueType_Insert_Input = {
  Code?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubRevenue?: Maybe<Scalars["String"]>;
  SystemCode?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_RevenueType_Max_Fields = {
  __typename?: "rtcs_db_kcca_RevenueType_max_fields";
  Code?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubRevenue?: Maybe<Scalars["String"]>;
  SystemCode?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_RevenueType" */
export type Rtcs_Db_Kcca_RevenueType_Max_Order_By = {
  Code?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubRevenue?: Maybe<Order_By>;
  SystemCode?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_RevenueType_Min_Fields = {
  __typename?: "rtcs_db_kcca_RevenueType_min_fields";
  Code?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubRevenue?: Maybe<Scalars["String"]>;
  SystemCode?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_RevenueType" */
export type Rtcs_Db_Kcca_RevenueType_Min_Order_By = {
  Code?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubRevenue?: Maybe<Order_By>;
  SystemCode?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_RevenueType" */
export type Rtcs_Db_Kcca_RevenueType_Mutation_Response = {
  __typename?: "rtcs_db_kcca_RevenueType_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_RevenueType>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_RevenueType" */
export type Rtcs_Db_Kcca_RevenueType_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_RevenueType_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_RevenueType" */
export type Rtcs_Db_Kcca_RevenueType_Order_By = {
  Code?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubRevenue?: Maybe<Order_By>;
  SystemCode?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_RevenueType" */
export enum Rtcs_Db_Kcca_RevenueType_Select_Column {
  /** column name */
  Code = "Code",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  SubRevenue = "SubRevenue",
  /** column name */
  SystemCode = "SystemCode",
  /** column name */
  Type = "Type",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_RevenueType" */
export type Rtcs_Db_Kcca_RevenueType_Set_Input = {
  Code?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubRevenue?: Maybe<Scalars["String"]>;
  SystemCode?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_RevenueType_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_RevenueType_stddev_fields";
  Code?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_RevenueType" */
export type Rtcs_Db_Kcca_RevenueType_Stddev_Order_By = {
  Code?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_RevenueType_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_RevenueType_stddev_pop_fields";
  Code?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_RevenueType" */
export type Rtcs_Db_Kcca_RevenueType_Stddev_Pop_Order_By = {
  Code?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_RevenueType_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_RevenueType_stddev_samp_fields";
  Code?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_RevenueType" */
export type Rtcs_Db_Kcca_RevenueType_Stddev_Samp_Order_By = {
  Code?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_RevenueType_Sum_Fields = {
  __typename?: "rtcs_db_kcca_RevenueType_sum_fields";
  Code?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_RevenueType" */
export type Rtcs_Db_Kcca_RevenueType_Sum_Order_By = {
  Code?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_RevenueType_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_RevenueType_var_pop_fields";
  Code?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_RevenueType" */
export type Rtcs_Db_Kcca_RevenueType_Var_Pop_Order_By = {
  Code?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_RevenueType_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_RevenueType_var_samp_fields";
  Code?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_RevenueType" */
export type Rtcs_Db_Kcca_RevenueType_Var_Samp_Order_By = {
  Code?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_RevenueType_Variance_Fields = {
  __typename?: "rtcs_db_kcca_RevenueType_variance_fields";
  Code?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_RevenueType" */
export type Rtcs_Db_Kcca_RevenueType_Variance_Order_By = {
  Code?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_SubCounty" */
export type Rtcs_Db_Kcca_SubCounty = {
  __typename?: "rtcs_db_kcca_SubCounty";
  CountryId?: Maybe<Scalars["bigint"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_SubCounty" */
export type Rtcs_Db_Kcca_SubCounty_Aggregate = {
  __typename?: "rtcs_db_kcca_SubCounty_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_SubCounty_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_SubCounty>;
};

/** aggregate fields of "rtcs_db.kcca_SubCounty" */
export type Rtcs_Db_Kcca_SubCounty_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_SubCounty_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_SubCounty_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_SubCounty_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_SubCounty_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_SubCounty_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_SubCounty_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_SubCounty_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_SubCounty_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_SubCounty_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_SubCounty_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_SubCounty_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_SubCounty" */
export type Rtcs_Db_Kcca_SubCounty_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_SubCounty_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_SubCounty" */
export type Rtcs_Db_Kcca_SubCounty_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_SubCounty_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_SubCounty_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_SubCounty_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_SubCounty_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_SubCounty_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_SubCounty_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_SubCounty_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_SubCounty_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_SubCounty_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_SubCounty_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_SubCounty" */
export type Rtcs_Db_Kcca_SubCounty_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_SubCounty_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_SubCounty_Avg_Fields = {
  __typename?: "rtcs_db_kcca_SubCounty_avg_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_SubCounty" */
export type Rtcs_Db_Kcca_SubCounty_Avg_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_SubCounty". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_SubCounty_Bool_Exp = {
  CountryId?: Maybe<Bigint_Comparison_Exp>;
  CountyId?: Maybe<Bigint_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  DistrictId?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  ModifiedBy?: Maybe<Float8_Comparison_Exp>;
  ModifiedDate?: Maybe<Timestamptz_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_SubCounty_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_SubCounty_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_SubCounty_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_SubCounty" */
export type Rtcs_Db_Kcca_SubCounty_Inc_Input = {
  CountryId?: Maybe<Scalars["bigint"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_SubCounty" */
export type Rtcs_Db_Kcca_SubCounty_Insert_Input = {
  CountryId?: Maybe<Scalars["bigint"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_SubCounty_Max_Fields = {
  __typename?: "rtcs_db_kcca_SubCounty_max_fields";
  CountryId?: Maybe<Scalars["bigint"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_SubCounty" */
export type Rtcs_Db_Kcca_SubCounty_Max_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_SubCounty_Min_Fields = {
  __typename?: "rtcs_db_kcca_SubCounty_min_fields";
  CountryId?: Maybe<Scalars["bigint"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_SubCounty" */
export type Rtcs_Db_Kcca_SubCounty_Min_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_SubCounty" */
export type Rtcs_Db_Kcca_SubCounty_Mutation_Response = {
  __typename?: "rtcs_db_kcca_SubCounty_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_SubCounty>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_SubCounty" */
export type Rtcs_Db_Kcca_SubCounty_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_SubCounty_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_SubCounty" */
export type Rtcs_Db_Kcca_SubCounty_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_SubCounty" */
export enum Rtcs_Db_Kcca_SubCounty_Select_Column {
  /** column name */
  CountryId = "CountryId",
  /** column name */
  CountyId = "CountyId",
  /** column name */
  Description = "Description",
  /** column name */
  DistrictId = "DistrictId",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  Name = "Name",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_SubCounty" */
export type Rtcs_Db_Kcca_SubCounty_Set_Input = {
  CountryId?: Maybe<Scalars["bigint"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  Name?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_SubCounty_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_SubCounty_stddev_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_SubCounty" */
export type Rtcs_Db_Kcca_SubCounty_Stddev_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_SubCounty_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_SubCounty_stddev_pop_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_SubCounty" */
export type Rtcs_Db_Kcca_SubCounty_Stddev_Pop_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_SubCounty_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_SubCounty_stddev_samp_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_SubCounty" */
export type Rtcs_Db_Kcca_SubCounty_Stddev_Samp_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_SubCounty_Sum_Fields = {
  __typename?: "rtcs_db_kcca_SubCounty_sum_fields";
  CountryId?: Maybe<Scalars["bigint"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_SubCounty" */
export type Rtcs_Db_Kcca_SubCounty_Sum_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_SubCounty_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_SubCounty_var_pop_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_SubCounty" */
export type Rtcs_Db_Kcca_SubCounty_Var_Pop_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_SubCounty_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_SubCounty_var_samp_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_SubCounty" */
export type Rtcs_Db_Kcca_SubCounty_Var_Samp_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_SubCounty_Variance_Fields = {
  __typename?: "rtcs_db_kcca_SubCounty_variance_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_SubCounty" */
export type Rtcs_Db_Kcca_SubCounty_Variance_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion = {
  __typename?: "rtcs_db_kcca_SubpropertyTypeConversion";
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RnCategory?: Maybe<Scalars["String"]>;
  SubpropertyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Aggregate = {
  __typename?: "rtcs_db_kcca_SubpropertyTypeConversion_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_SubpropertyTypeConversion>;
};

/** aggregate fields of "rtcs_db.kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_SubpropertyTypeConversion_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_SubpropertyTypeConversion_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_SubpropertyTypeConversion_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Avg_Fields = {
  __typename?: "rtcs_db_kcca_SubpropertyTypeConversion_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
  SubpropertyId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Avg_Order_By = {
  Line?: Maybe<Order_By>;
  SubpropertyId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_SubpropertyTypeConversion". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  RnCategory?: Maybe<String_Comparison_Exp>;
  SubpropertyId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
  SubpropertyId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RnCategory?: Maybe<Scalars["String"]>;
  SubpropertyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Max_Fields = {
  __typename?: "rtcs_db_kcca_SubpropertyTypeConversion_max_fields";
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RnCategory?: Maybe<Scalars["String"]>;
  SubpropertyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Max_Order_By = {
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RnCategory?: Maybe<Order_By>;
  SubpropertyId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Min_Fields = {
  __typename?: "rtcs_db_kcca_SubpropertyTypeConversion_min_fields";
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RnCategory?: Maybe<Scalars["String"]>;
  SubpropertyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Min_Order_By = {
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RnCategory?: Maybe<Order_By>;
  SubpropertyId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Mutation_Response = {
  __typename?: "rtcs_db_kcca_SubpropertyTypeConversion_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_SubpropertyTypeConversion>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_SubpropertyTypeConversion_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Order_By = {
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RnCategory?: Maybe<Order_By>;
  SubpropertyId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_SubpropertyTypeConversion" */
export enum Rtcs_Db_Kcca_SubpropertyTypeConversion_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  RnCategory = "RnCategory",
  /** column name */
  SubpropertyId = "SubpropertyId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RnCategory?: Maybe<Scalars["String"]>;
  SubpropertyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_SubpropertyTypeConversion_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
  SubpropertyId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
  SubpropertyId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_SubpropertyTypeConversion_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  SubpropertyId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  SubpropertyId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_SubpropertyTypeConversion_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  SubpropertyId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  SubpropertyId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Sum_Fields = {
  __typename?: "rtcs_db_kcca_SubpropertyTypeConversion_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
  SubpropertyId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Sum_Order_By = {
  Line?: Maybe<Order_By>;
  SubpropertyId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_SubpropertyTypeConversion_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  SubpropertyId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  SubpropertyId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_SubpropertyTypeConversion_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  SubpropertyId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  SubpropertyId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Variance_Fields = {
  __typename?: "rtcs_db_kcca_SubpropertyTypeConversion_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
  SubpropertyId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_SubpropertyTypeConversion" */
export type Rtcs_Db_Kcca_SubpropertyTypeConversion_Variance_Order_By = {
  Line?: Maybe<Order_By>;
  SubpropertyId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_TradingLicense" */
export type Rtcs_Db_Kcca_TradingLicense = {
  __typename?: "rtcs_db_kcca_TradingLicense";
  BusinessName?: Maybe<Scalars["String"]>;
  Category?: Maybe<Scalars["String"]>;
  Code?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Dob?: Maybe<Scalars["date"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  NatureOfBusiness?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_TradingLicense" */
export type Rtcs_Db_Kcca_TradingLicense_Aggregate = {
  __typename?: "rtcs_db_kcca_TradingLicense_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_TradingLicense_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_TradingLicense>;
};

/** aggregate fields of "rtcs_db.kcca_TradingLicense" */
export type Rtcs_Db_Kcca_TradingLicense_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_TradingLicense_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_TradingLicense_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_TradingLicense_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_TradingLicense_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_TradingLicense_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_TradingLicense_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_TradingLicense_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_TradingLicense_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_TradingLicense_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_TradingLicense_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_TradingLicense_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_TradingLicense" */
export type Rtcs_Db_Kcca_TradingLicense_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_TradingLicense_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_TradingLicense" */
export type Rtcs_Db_Kcca_TradingLicense_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_TradingLicense_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_TradingLicense_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_TradingLicense_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_TradingLicense_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_TradingLicense_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_TradingLicense_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_TradingLicense_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_TradingLicense_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_TradingLicense_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_TradingLicense_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_TradingLicense" */
export type Rtcs_Db_Kcca_TradingLicense_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_TradingLicense_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_TradingLicense_Avg_Fields = {
  __typename?: "rtcs_db_kcca_TradingLicense_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_TradingLicense" */
export type Rtcs_Db_Kcca_TradingLicense_Avg_Order_By = {
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_TradingLicense". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_TradingLicense_Bool_Exp = {
  BusinessName?: Maybe<String_Comparison_Exp>;
  Category?: Maybe<String_Comparison_Exp>;
  Code?: Maybe<String_Comparison_Exp>;
  Division?: Maybe<String_Comparison_Exp>;
  Dob?: Maybe<Date_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  EntityLegalName?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamp_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Mobile?: Maybe<String_Comparison_Exp>;
  NatureOfBusiness?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_TradingLicense_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_TradingLicense_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_TradingLicense_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_TradingLicense" */
export type Rtcs_Db_Kcca_TradingLicense_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_TradingLicense" */
export type Rtcs_Db_Kcca_TradingLicense_Insert_Input = {
  BusinessName?: Maybe<Scalars["String"]>;
  Category?: Maybe<Scalars["String"]>;
  Code?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Dob?: Maybe<Scalars["date"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  NatureOfBusiness?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_TradingLicense_Max_Fields = {
  __typename?: "rtcs_db_kcca_TradingLicense_max_fields";
  BusinessName?: Maybe<Scalars["String"]>;
  Category?: Maybe<Scalars["String"]>;
  Code?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Dob?: Maybe<Scalars["date"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  NatureOfBusiness?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_TradingLicense" */
export type Rtcs_Db_Kcca_TradingLicense_Max_Order_By = {
  BusinessName?: Maybe<Order_By>;
  Category?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  Dob?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  NatureOfBusiness?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_TradingLicense_Min_Fields = {
  __typename?: "rtcs_db_kcca_TradingLicense_min_fields";
  BusinessName?: Maybe<Scalars["String"]>;
  Category?: Maybe<Scalars["String"]>;
  Code?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Dob?: Maybe<Scalars["date"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  NatureOfBusiness?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_TradingLicense" */
export type Rtcs_Db_Kcca_TradingLicense_Min_Order_By = {
  BusinessName?: Maybe<Order_By>;
  Category?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  Dob?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  NatureOfBusiness?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_TradingLicense" */
export type Rtcs_Db_Kcca_TradingLicense_Mutation_Response = {
  __typename?: "rtcs_db_kcca_TradingLicense_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_TradingLicense>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_TradingLicense" */
export type Rtcs_Db_Kcca_TradingLicense_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_TradingLicense_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_TradingLicense" */
export type Rtcs_Db_Kcca_TradingLicense_Order_By = {
  BusinessName?: Maybe<Order_By>;
  Category?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  Dob?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EntityLegalName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  NatureOfBusiness?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_TradingLicense" */
export enum Rtcs_Db_Kcca_TradingLicense_Select_Column {
  /** column name */
  BusinessName = "BusinessName",
  /** column name */
  Category = "Category",
  /** column name */
  Code = "Code",
  /** column name */
  Division = "Division",
  /** column name */
  Dob = "Dob",
  /** column name */
  Email = "Email",
  /** column name */
  EntityLegalName = "EntityLegalName",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  NatureOfBusiness = "NatureOfBusiness",
  /** column name */
  Surname = "Surname",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_TradingLicense" */
export type Rtcs_Db_Kcca_TradingLicense_Set_Input = {
  BusinessName?: Maybe<Scalars["String"]>;
  Category?: Maybe<Scalars["String"]>;
  Code?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Dob?: Maybe<Scalars["date"]>;
  Email?: Maybe<Scalars["String"]>;
  EntityLegalName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  NatureOfBusiness?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_TradingLicense_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_TradingLicense_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_TradingLicense" */
export type Rtcs_Db_Kcca_TradingLicense_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_TradingLicense_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_TradingLicense_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_TradingLicense" */
export type Rtcs_Db_Kcca_TradingLicense_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_TradingLicense_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_TradingLicense_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_TradingLicense" */
export type Rtcs_Db_Kcca_TradingLicense_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_TradingLicense_Sum_Fields = {
  __typename?: "rtcs_db_kcca_TradingLicense_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_TradingLicense" */
export type Rtcs_Db_Kcca_TradingLicense_Sum_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_TradingLicense_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_TradingLicense_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_TradingLicense" */
export type Rtcs_Db_Kcca_TradingLicense_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_TradingLicense_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_TradingLicense_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_TradingLicense" */
export type Rtcs_Db_Kcca_TradingLicense_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_TradingLicense_Variance_Fields = {
  __typename?: "rtcs_db_kcca_TradingLicense_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_TradingLicense" */
export type Rtcs_Db_Kcca_TradingLicense_Variance_Order_By = {
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.kcca_Village" */
export type Rtcs_Db_Kcca_Village = {
  __typename?: "rtcs_db_kcca_Village";
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamp"]>;
  Name?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.kcca_Village" */
export type Rtcs_Db_Kcca_Village_Aggregate = {
  __typename?: "rtcs_db_kcca_Village_aggregate";
  aggregate?: Maybe<Rtcs_Db_Kcca_Village_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Kcca_Village>;
};

/** aggregate fields of "rtcs_db.kcca_Village" */
export type Rtcs_Db_Kcca_Village_Aggregate_Fields = {
  __typename?: "rtcs_db_kcca_Village_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Kcca_Village_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Kcca_Village_Max_Fields>;
  min?: Maybe<Rtcs_Db_Kcca_Village_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Kcca_Village_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_Village_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_Village_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Kcca_Village_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Kcca_Village_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Kcca_Village_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Kcca_Village_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.kcca_Village" */
export type Rtcs_Db_Kcca_Village_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Kcca_Village_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.kcca_Village" */
export type Rtcs_Db_Kcca_Village_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Kcca_Village_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Kcca_Village_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Kcca_Village_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Kcca_Village_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Kcca_Village_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Kcca_Village_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Kcca_Village_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Kcca_Village_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Kcca_Village_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Kcca_Village_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.kcca_Village" */
export type Rtcs_Db_Kcca_Village_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Kcca_Village_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Kcca_Village_Avg_Fields = {
  __typename?: "rtcs_db_kcca_Village_avg_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.kcca_Village" */
export type Rtcs_Db_Kcca_Village_Avg_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.kcca_Village". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Kcca_Village_Bool_Exp = {
  CountryId?: Maybe<Float8_Comparison_Exp>;
  CountyId?: Maybe<Bigint_Comparison_Exp>;
  CreatedBy?: Maybe<Float8_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamp_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  DistrictId?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamp_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  ModifiedBy?: Maybe<Float8_Comparison_Exp>;
  ModifiedDate?: Maybe<Timestamp_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  ParishId?: Maybe<Bigint_Comparison_Exp>;
  StatusId?: Maybe<Bigint_Comparison_Exp>;
  SubCountyId?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Kcca_Village_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Kcca_Village_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Kcca_Village_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.kcca_Village" */
export type Rtcs_Db_Kcca_Village_Inc_Input = {
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.kcca_Village" */
export type Rtcs_Db_Kcca_Village_Insert_Input = {
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamp"]>;
  Name?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Kcca_Village_Max_Fields = {
  __typename?: "rtcs_db_kcca_Village_max_fields";
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamp"]>;
  Name?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.kcca_Village" */
export type Rtcs_Db_Kcca_Village_Max_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Kcca_Village_Min_Fields = {
  __typename?: "rtcs_db_kcca_Village_min_fields";
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamp"]>;
  Name?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.kcca_Village" */
export type Rtcs_Db_Kcca_Village_Min_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.kcca_Village" */
export type Rtcs_Db_Kcca_Village_Mutation_Response = {
  __typename?: "rtcs_db_kcca_Village_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Kcca_Village>;
};

/** input type for inserting object relation for remote table "rtcs_db.kcca_Village" */
export type Rtcs_Db_Kcca_Village_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Kcca_Village_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.kcca_Village" */
export type Rtcs_Db_Kcca_Village_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.kcca_Village" */
export enum Rtcs_Db_Kcca_Village_Select_Column {
  /** column name */
  CountryId = "CountryId",
  /** column name */
  CountyId = "CountyId",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  Description = "Description",
  /** column name */
  DistrictId = "DistrictId",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  Name = "Name",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  SubCountyId = "SubCountyId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.kcca_Village" */
export type Rtcs_Db_Kcca_Village_Set_Input = {
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamp"]>;
  Description?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamp"]>;
  Name?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Kcca_Village_Stddev_Fields = {
  __typename?: "rtcs_db_kcca_Village_stddev_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.kcca_Village" */
export type Rtcs_Db_Kcca_Village_Stddev_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Kcca_Village_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_kcca_Village_stddev_pop_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.kcca_Village" */
export type Rtcs_Db_Kcca_Village_Stddev_Pop_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Kcca_Village_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_kcca_Village_stddev_samp_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.kcca_Village" */
export type Rtcs_Db_Kcca_Village_Stddev_Samp_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Kcca_Village_Sum_Fields = {
  __typename?: "rtcs_db_kcca_Village_sum_fields";
  CountryId?: Maybe<Scalars["float8"]>;
  CountyId?: Maybe<Scalars["bigint"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  DistrictId?: Maybe<Scalars["bigint"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["bigint"]>;
  StatusId?: Maybe<Scalars["bigint"]>;
  SubCountyId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.kcca_Village" */
export type Rtcs_Db_Kcca_Village_Sum_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Kcca_Village_Var_Pop_Fields = {
  __typename?: "rtcs_db_kcca_Village_var_pop_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.kcca_Village" */
export type Rtcs_Db_Kcca_Village_Var_Pop_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Kcca_Village_Var_Samp_Fields = {
  __typename?: "rtcs_db_kcca_Village_var_samp_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.kcca_Village" */
export type Rtcs_Db_Kcca_Village_Var_Samp_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Kcca_Village_Variance_Fields = {
  __typename?: "rtcs_db_kcca_Village_variance_fields";
  CountryId?: Maybe<Scalars["Float"]>;
  CountyId?: Maybe<Scalars["Float"]>;
  CreatedBy?: Maybe<Scalars["Float"]>;
  DistrictId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubCountyId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.kcca_Village" */
export type Rtcs_Db_Kcca_Village_Variance_Order_By = {
  CountryId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.master_MasterIndiv" */
export type Rtcs_Db_Master_MasterIndiv = {
  __typename?: "rtcs_db_master_MasterIndiv";
  File?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  KccaId?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  RmatchNumProperties?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["bigint"]>;
  RmatchScoreAttributes?: Maybe<Scalars["String"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["float8"]>;
  RmatchSumGrossValue?: Maybe<Scalars["float8"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["bigint"]>;
  RmatchSumRateableValue?: Maybe<Scalars["float8"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["bigint"]>;
  RmatchUraMatchingTins?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.master_MasterIndiv" */
export type Rtcs_Db_Master_MasterIndiv_Aggregate = {
  __typename?: "rtcs_db_master_MasterIndiv_aggregate";
  aggregate?: Maybe<Rtcs_Db_Master_MasterIndiv_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Master_MasterIndiv>;
};

/** aggregate fields of "rtcs_db.master_MasterIndiv" */
export type Rtcs_Db_Master_MasterIndiv_Aggregate_Fields = {
  __typename?: "rtcs_db_master_MasterIndiv_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Master_MasterIndiv_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Master_MasterIndiv_Max_Fields>;
  min?: Maybe<Rtcs_Db_Master_MasterIndiv_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Master_MasterIndiv_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Master_MasterIndiv_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Master_MasterIndiv_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Master_MasterIndiv_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Master_MasterIndiv_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Master_MasterIndiv_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Master_MasterIndiv_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.master_MasterIndiv" */
export type Rtcs_Db_Master_MasterIndiv_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Master_MasterIndiv_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.master_MasterIndiv" */
export type Rtcs_Db_Master_MasterIndiv_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Master_MasterIndiv_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Master_MasterIndiv_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Master_MasterIndiv_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Master_MasterIndiv_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Master_MasterIndiv_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Master_MasterIndiv_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Master_MasterIndiv_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Master_MasterIndiv_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Master_MasterIndiv_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Master_MasterIndiv_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.master_MasterIndiv" */
export type Rtcs_Db_Master_MasterIndiv_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Master_MasterIndiv_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Master_MasterIndiv_Avg_Fields = {
  __typename?: "rtcs_db_master_MasterIndiv_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  RmatchNumProperties?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["Float"]>;
  RmatchSumGrossValue?: Maybe<Scalars["Float"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["Float"]>;
  RmatchSumRateableValue?: Maybe<Scalars["Float"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.master_MasterIndiv" */
export type Rtcs_Db_Master_MasterIndiv_Avg_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchNumOwnedNonRateable?: Maybe<Order_By>;
  RmatchNumProperties?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RmatchSumAdjustedIncome?: Maybe<Order_By>;
  RmatchSumGrossValue?: Maybe<Order_By>;
  RmatchSumNonRateableValue?: Maybe<Order_By>;
  RmatchSumRateableValue?: Maybe<Order_By>;
  RmatchTotalRentedProperties?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.master_MasterIndiv". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Master_MasterIndiv_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  Fullname?: Maybe<String_Comparison_Exp>;
  KccaId?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  RmatchNumOwnedNonRateable?: Maybe<Bigint_Comparison_Exp>;
  RmatchNumProperties?: Maybe<Bigint_Comparison_Exp>;
  RmatchScore?: Maybe<Bigint_Comparison_Exp>;
  RmatchScoreAttributes?: Maybe<String_Comparison_Exp>;
  RmatchSumAdjustedIncome?: Maybe<Float8_Comparison_Exp>;
  RmatchSumGrossValue?: Maybe<Float8_Comparison_Exp>;
  RmatchSumNonRateableValue?: Maybe<Bigint_Comparison_Exp>;
  RmatchSumRateableValue?: Maybe<Float8_Comparison_Exp>;
  RmatchTotalRentedProperties?: Maybe<Bigint_Comparison_Exp>;
  RmatchUraMatchingTins?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Master_MasterIndiv_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Master_MasterIndiv_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Master_MasterIndiv_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.master_MasterIndiv" */
export type Rtcs_Db_Master_MasterIndiv_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  RmatchNumProperties?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["bigint"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["float8"]>;
  RmatchSumGrossValue?: Maybe<Scalars["float8"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["bigint"]>;
  RmatchSumRateableValue?: Maybe<Scalars["float8"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.master_MasterIndiv" */
export type Rtcs_Db_Master_MasterIndiv_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  KccaId?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  RmatchNumProperties?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["bigint"]>;
  RmatchScoreAttributes?: Maybe<Scalars["String"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["float8"]>;
  RmatchSumGrossValue?: Maybe<Scalars["float8"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["bigint"]>;
  RmatchSumRateableValue?: Maybe<Scalars["float8"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["bigint"]>;
  RmatchUraMatchingTins?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Master_MasterIndiv_Max_Fields = {
  __typename?: "rtcs_db_master_MasterIndiv_max_fields";
  File?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  KccaId?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  RmatchNumProperties?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["bigint"]>;
  RmatchScoreAttributes?: Maybe<Scalars["String"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["float8"]>;
  RmatchSumGrossValue?: Maybe<Scalars["float8"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["bigint"]>;
  RmatchSumRateableValue?: Maybe<Scalars["float8"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["bigint"]>;
  RmatchUraMatchingTins?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.master_MasterIndiv" */
export type Rtcs_Db_Master_MasterIndiv_Max_Order_By = {
  File?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  KccaId?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchNumOwnedNonRateable?: Maybe<Order_By>;
  RmatchNumProperties?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RmatchScoreAttributes?: Maybe<Order_By>;
  RmatchSumAdjustedIncome?: Maybe<Order_By>;
  RmatchSumGrossValue?: Maybe<Order_By>;
  RmatchSumNonRateableValue?: Maybe<Order_By>;
  RmatchSumRateableValue?: Maybe<Order_By>;
  RmatchTotalRentedProperties?: Maybe<Order_By>;
  RmatchUraMatchingTins?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Master_MasterIndiv_Min_Fields = {
  __typename?: "rtcs_db_master_MasterIndiv_min_fields";
  File?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  KccaId?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  RmatchNumProperties?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["bigint"]>;
  RmatchScoreAttributes?: Maybe<Scalars["String"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["float8"]>;
  RmatchSumGrossValue?: Maybe<Scalars["float8"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["bigint"]>;
  RmatchSumRateableValue?: Maybe<Scalars["float8"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["bigint"]>;
  RmatchUraMatchingTins?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.master_MasterIndiv" */
export type Rtcs_Db_Master_MasterIndiv_Min_Order_By = {
  File?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  KccaId?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchNumOwnedNonRateable?: Maybe<Order_By>;
  RmatchNumProperties?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RmatchScoreAttributes?: Maybe<Order_By>;
  RmatchSumAdjustedIncome?: Maybe<Order_By>;
  RmatchSumGrossValue?: Maybe<Order_By>;
  RmatchSumNonRateableValue?: Maybe<Order_By>;
  RmatchSumRateableValue?: Maybe<Order_By>;
  RmatchTotalRentedProperties?: Maybe<Order_By>;
  RmatchUraMatchingTins?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.master_MasterIndiv" */
export type Rtcs_Db_Master_MasterIndiv_Mutation_Response = {
  __typename?: "rtcs_db_master_MasterIndiv_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Master_MasterIndiv>;
};

/** input type for inserting object relation for remote table "rtcs_db.master_MasterIndiv" */
export type Rtcs_Db_Master_MasterIndiv_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Master_MasterIndiv_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.master_MasterIndiv" */
export type Rtcs_Db_Master_MasterIndiv_Order_By = {
  File?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  KccaId?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchNumOwnedNonRateable?: Maybe<Order_By>;
  RmatchNumProperties?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RmatchScoreAttributes?: Maybe<Order_By>;
  RmatchSumAdjustedIncome?: Maybe<Order_By>;
  RmatchSumGrossValue?: Maybe<Order_By>;
  RmatchSumNonRateableValue?: Maybe<Order_By>;
  RmatchSumRateableValue?: Maybe<Order_By>;
  RmatchTotalRentedProperties?: Maybe<Order_By>;
  RmatchUraMatchingTins?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.master_MasterIndiv" */
export enum Rtcs_Db_Master_MasterIndiv_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  Fullname = "Fullname",
  /** column name */
  KccaId = "KccaId",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  RmatchNumOwnedNonRateable = "RmatchNumOwnedNonRateable",
  /** column name */
  RmatchNumProperties = "RmatchNumProperties",
  /** column name */
  RmatchScore = "RmatchScore",
  /** column name */
  RmatchScoreAttributes = "RmatchScoreAttributes",
  /** column name */
  RmatchSumAdjustedIncome = "RmatchSumAdjustedIncome",
  /** column name */
  RmatchSumGrossValue = "RmatchSumGrossValue",
  /** column name */
  RmatchSumNonRateableValue = "RmatchSumNonRateableValue",
  /** column name */
  RmatchSumRateableValue = "RmatchSumRateableValue",
  /** column name */
  RmatchTotalRentedProperties = "RmatchTotalRentedProperties",
  /** column name */
  RmatchUraMatchingTins = "RmatchUraMatchingTins",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Source = "Source",
  /** column name */
  UraTinNo = "UraTinNo",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.master_MasterIndiv" */
export type Rtcs_Db_Master_MasterIndiv_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  KccaId?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  RmatchNumProperties?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["bigint"]>;
  RmatchScoreAttributes?: Maybe<Scalars["String"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["float8"]>;
  RmatchSumGrossValue?: Maybe<Scalars["float8"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["bigint"]>;
  RmatchSumRateableValue?: Maybe<Scalars["float8"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["bigint"]>;
  RmatchUraMatchingTins?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Master_MasterIndiv_Stddev_Fields = {
  __typename?: "rtcs_db_master_MasterIndiv_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  RmatchNumProperties?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["Float"]>;
  RmatchSumGrossValue?: Maybe<Scalars["Float"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["Float"]>;
  RmatchSumRateableValue?: Maybe<Scalars["Float"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.master_MasterIndiv" */
export type Rtcs_Db_Master_MasterIndiv_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchNumOwnedNonRateable?: Maybe<Order_By>;
  RmatchNumProperties?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RmatchSumAdjustedIncome?: Maybe<Order_By>;
  RmatchSumGrossValue?: Maybe<Order_By>;
  RmatchSumNonRateableValue?: Maybe<Order_By>;
  RmatchSumRateableValue?: Maybe<Order_By>;
  RmatchTotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Master_MasterIndiv_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_master_MasterIndiv_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  RmatchNumProperties?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["Float"]>;
  RmatchSumGrossValue?: Maybe<Scalars["Float"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["Float"]>;
  RmatchSumRateableValue?: Maybe<Scalars["Float"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.master_MasterIndiv" */
export type Rtcs_Db_Master_MasterIndiv_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchNumOwnedNonRateable?: Maybe<Order_By>;
  RmatchNumProperties?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RmatchSumAdjustedIncome?: Maybe<Order_By>;
  RmatchSumGrossValue?: Maybe<Order_By>;
  RmatchSumNonRateableValue?: Maybe<Order_By>;
  RmatchSumRateableValue?: Maybe<Order_By>;
  RmatchTotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Master_MasterIndiv_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_master_MasterIndiv_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  RmatchNumProperties?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["Float"]>;
  RmatchSumGrossValue?: Maybe<Scalars["Float"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["Float"]>;
  RmatchSumRateableValue?: Maybe<Scalars["Float"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.master_MasterIndiv" */
export type Rtcs_Db_Master_MasterIndiv_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchNumOwnedNonRateable?: Maybe<Order_By>;
  RmatchNumProperties?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RmatchSumAdjustedIncome?: Maybe<Order_By>;
  RmatchSumGrossValue?: Maybe<Order_By>;
  RmatchSumNonRateableValue?: Maybe<Order_By>;
  RmatchSumRateableValue?: Maybe<Order_By>;
  RmatchTotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Master_MasterIndiv_Sum_Fields = {
  __typename?: "rtcs_db_master_MasterIndiv_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  RmatchNumProperties?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["bigint"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["float8"]>;
  RmatchSumGrossValue?: Maybe<Scalars["float8"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["bigint"]>;
  RmatchSumRateableValue?: Maybe<Scalars["float8"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.master_MasterIndiv" */
export type Rtcs_Db_Master_MasterIndiv_Sum_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchNumOwnedNonRateable?: Maybe<Order_By>;
  RmatchNumProperties?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RmatchSumAdjustedIncome?: Maybe<Order_By>;
  RmatchSumGrossValue?: Maybe<Order_By>;
  RmatchSumNonRateableValue?: Maybe<Order_By>;
  RmatchSumRateableValue?: Maybe<Order_By>;
  RmatchTotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Master_MasterIndiv_Var_Pop_Fields = {
  __typename?: "rtcs_db_master_MasterIndiv_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  RmatchNumProperties?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["Float"]>;
  RmatchSumGrossValue?: Maybe<Scalars["Float"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["Float"]>;
  RmatchSumRateableValue?: Maybe<Scalars["Float"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.master_MasterIndiv" */
export type Rtcs_Db_Master_MasterIndiv_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchNumOwnedNonRateable?: Maybe<Order_By>;
  RmatchNumProperties?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RmatchSumAdjustedIncome?: Maybe<Order_By>;
  RmatchSumGrossValue?: Maybe<Order_By>;
  RmatchSumNonRateableValue?: Maybe<Order_By>;
  RmatchSumRateableValue?: Maybe<Order_By>;
  RmatchTotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Master_MasterIndiv_Var_Samp_Fields = {
  __typename?: "rtcs_db_master_MasterIndiv_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  RmatchNumProperties?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["Float"]>;
  RmatchSumGrossValue?: Maybe<Scalars["Float"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["Float"]>;
  RmatchSumRateableValue?: Maybe<Scalars["Float"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.master_MasterIndiv" */
export type Rtcs_Db_Master_MasterIndiv_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchNumOwnedNonRateable?: Maybe<Order_By>;
  RmatchNumProperties?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RmatchSumAdjustedIncome?: Maybe<Order_By>;
  RmatchSumGrossValue?: Maybe<Order_By>;
  RmatchSumNonRateableValue?: Maybe<Order_By>;
  RmatchSumRateableValue?: Maybe<Order_By>;
  RmatchTotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Master_MasterIndiv_Variance_Fields = {
  __typename?: "rtcs_db_master_MasterIndiv_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchNumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  RmatchNumProperties?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  RmatchSumAdjustedIncome?: Maybe<Scalars["Float"]>;
  RmatchSumGrossValue?: Maybe<Scalars["Float"]>;
  RmatchSumNonRateableValue?: Maybe<Scalars["Float"]>;
  RmatchSumRateableValue?: Maybe<Scalars["Float"]>;
  RmatchTotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.master_MasterIndiv" */
export type Rtcs_Db_Master_MasterIndiv_Variance_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchNumOwnedNonRateable?: Maybe<Order_By>;
  RmatchNumProperties?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RmatchSumAdjustedIncome?: Maybe<Order_By>;
  RmatchSumGrossValue?: Maybe<Order_By>;
  RmatchSumNonRateableValue?: Maybe<Order_By>;
  RmatchSumRateableValue?: Maybe<Order_By>;
  RmatchTotalRentedProperties?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.mlhud_KCCA" */
export type Rtcs_Db_Mlhud_Kcca = {
  __typename?: "rtcs_db_mlhud_KCCA";
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  TitleNumber?: Maybe<Scalars["float8"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.mlhud_KCCA" */
export type Rtcs_Db_Mlhud_Kcca_Aggregate = {
  __typename?: "rtcs_db_mlhud_KCCA_aggregate";
  aggregate?: Maybe<Rtcs_Db_Mlhud_Kcca_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Mlhud_Kcca>;
};

/** aggregate fields of "rtcs_db.mlhud_KCCA" */
export type Rtcs_Db_Mlhud_Kcca_Aggregate_Fields = {
  __typename?: "rtcs_db_mlhud_KCCA_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Mlhud_Kcca_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Mlhud_Kcca_Max_Fields>;
  min?: Maybe<Rtcs_Db_Mlhud_Kcca_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Mlhud_Kcca_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Mlhud_Kcca_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Mlhud_Kcca_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Mlhud_Kcca_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Mlhud_Kcca_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Mlhud_Kcca_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Mlhud_Kcca_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.mlhud_KCCA" */
export type Rtcs_Db_Mlhud_Kcca_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Mlhud_Kcca_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.mlhud_KCCA" */
export type Rtcs_Db_Mlhud_Kcca_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Mlhud_Kcca_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Mlhud_Kcca_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Mlhud_Kcca_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Mlhud_Kcca_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Mlhud_Kcca_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Mlhud_Kcca_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Mlhud_Kcca_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Mlhud_Kcca_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Mlhud_Kcca_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Mlhud_Kcca_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.mlhud_KCCA" */
export type Rtcs_Db_Mlhud_Kcca_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Mlhud_Kcca_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Mlhud_Kcca_Avg_Fields = {
  __typename?: "rtcs_db_mlhud_KCCA_avg_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TitleNumber?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.mlhud_KCCA" */
export type Rtcs_Db_Mlhud_Kcca_Avg_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TitleNumber?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.mlhud_KCCA". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Mlhud_Kcca_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  BaunitType?: Maybe<String_Comparison_Exp>;
  BlockNumber?: Maybe<String_Comparison_Exp>;
  CondoPlanNo?: Maybe<String_Comparison_Exp>;
  CondoUnitFactor?: Maybe<Float8_Comparison_Exp>;
  County?: Maybe<String_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FirstName?: Maybe<String_Comparison_Exp>;
  Folio?: Maybe<Float8_Comparison_Exp>;
  FullName?: Maybe<String_Comparison_Exp>;
  Gender?: Maybe<String_Comparison_Exp>;
  LafNumber?: Maybe<String_Comparison_Exp>;
  LandType?: Maybe<String_Comparison_Exp>;
  LandUseType?: Maybe<String_Comparison_Exp>;
  LastName?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  LegalArea?: Maybe<Float8_Comparison_Exp>;
  LegalAreaUnitType?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  Municipality?: Maybe<String_Comparison_Exp>;
  Phone?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  RegistrationDatetime?: Maybe<Timestamptz_Comparison_Exp>;
  RoadName?: Maybe<String_Comparison_Exp>;
  Subcounty?: Maybe<String_Comparison_Exp>;
  TenureType?: Maybe<String_Comparison_Exp>;
  TitleLocation?: Maybe<String_Comparison_Exp>;
  TitleNumber?: Maybe<Float8_Comparison_Exp>;
  Volume?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Mlhud_Kcca_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Mlhud_Kcca_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Mlhud_Kcca_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.mlhud_KCCA" */
export type Rtcs_Db_Mlhud_Kcca_Inc_Input = {
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  Folio?: Maybe<Scalars["float8"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  TitleNumber?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.mlhud_KCCA" */
export type Rtcs_Db_Mlhud_Kcca_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  TitleNumber?: Maybe<Scalars["float8"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Mlhud_Kcca_Max_Fields = {
  __typename?: "rtcs_db_mlhud_KCCA_max_fields";
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  TitleNumber?: Maybe<Scalars["float8"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.mlhud_KCCA" */
export type Rtcs_Db_Mlhud_Kcca_Max_Order_By = {
  Address?: Maybe<Order_By>;
  BaunitType?: Maybe<Order_By>;
  BlockNumber?: Maybe<Order_By>;
  CondoPlanNo?: Maybe<Order_By>;
  CondoUnitFactor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  LafNumber?: Maybe<Order_By>;
  LandType?: Maybe<Order_By>;
  LandUseType?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  LegalAreaUnitType?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  RegistrationDatetime?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  TenureType?: Maybe<Order_By>;
  TitleLocation?: Maybe<Order_By>;
  TitleNumber?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Mlhud_Kcca_Min_Fields = {
  __typename?: "rtcs_db_mlhud_KCCA_min_fields";
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  TitleNumber?: Maybe<Scalars["float8"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.mlhud_KCCA" */
export type Rtcs_Db_Mlhud_Kcca_Min_Order_By = {
  Address?: Maybe<Order_By>;
  BaunitType?: Maybe<Order_By>;
  BlockNumber?: Maybe<Order_By>;
  CondoPlanNo?: Maybe<Order_By>;
  CondoUnitFactor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  LafNumber?: Maybe<Order_By>;
  LandType?: Maybe<Order_By>;
  LandUseType?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  LegalAreaUnitType?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  RegistrationDatetime?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  TenureType?: Maybe<Order_By>;
  TitleLocation?: Maybe<Order_By>;
  TitleNumber?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.mlhud_KCCA" */
export type Rtcs_Db_Mlhud_Kcca_Mutation_Response = {
  __typename?: "rtcs_db_mlhud_KCCA_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Mlhud_Kcca>;
};

/** input type for inserting object relation for remote table "rtcs_db.mlhud_KCCA" */
export type Rtcs_Db_Mlhud_Kcca_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Mlhud_Kcca_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.mlhud_KCCA" */
export type Rtcs_Db_Mlhud_Kcca_Order_By = {
  Address?: Maybe<Order_By>;
  BaunitType?: Maybe<Order_By>;
  BlockNumber?: Maybe<Order_By>;
  CondoPlanNo?: Maybe<Order_By>;
  CondoUnitFactor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  LafNumber?: Maybe<Order_By>;
  LandType?: Maybe<Order_By>;
  LandUseType?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  LegalAreaUnitType?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  RegistrationDatetime?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  TenureType?: Maybe<Order_By>;
  TitleLocation?: Maybe<Order_By>;
  TitleNumber?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.mlhud_KCCA" */
export enum Rtcs_Db_Mlhud_Kcca_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  BaunitType = "BaunitType",
  /** column name */
  BlockNumber = "BlockNumber",
  /** column name */
  CondoPlanNo = "CondoPlanNo",
  /** column name */
  CondoUnitFactor = "CondoUnitFactor",
  /** column name */
  County = "County",
  /** column name */
  Description = "Description",
  /** column name */
  District = "District",
  /** column name */
  File = "File",
  /** column name */
  FirstName = "FirstName",
  /** column name */
  Folio = "Folio",
  /** column name */
  FullName = "FullName",
  /** column name */
  Gender = "Gender",
  /** column name */
  LafNumber = "LafNumber",
  /** column name */
  LandType = "LandType",
  /** column name */
  LandUseType = "LandUseType",
  /** column name */
  LastName = "LastName",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  LegalArea = "LegalArea",
  /** column name */
  LegalAreaUnitType = "LegalAreaUnitType",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  Municipality = "Municipality",
  /** column name */
  Phone = "Phone",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  RegistrationDatetime = "RegistrationDatetime",
  /** column name */
  RoadName = "RoadName",
  /** column name */
  Subcounty = "Subcounty",
  /** column name */
  TenureType = "TenureType",
  /** column name */
  TitleLocation = "TitleLocation",
  /** column name */
  TitleNumber = "TitleNumber",
  /** column name */
  Volume = "Volume",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.mlhud_KCCA" */
export type Rtcs_Db_Mlhud_Kcca_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  TitleNumber?: Maybe<Scalars["float8"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Mlhud_Kcca_Stddev_Fields = {
  __typename?: "rtcs_db_mlhud_KCCA_stddev_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TitleNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.mlhud_KCCA" */
export type Rtcs_Db_Mlhud_Kcca_Stddev_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TitleNumber?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Mlhud_Kcca_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_mlhud_KCCA_stddev_pop_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TitleNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.mlhud_KCCA" */
export type Rtcs_Db_Mlhud_Kcca_Stddev_Pop_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TitleNumber?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Mlhud_Kcca_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_mlhud_KCCA_stddev_samp_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TitleNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.mlhud_KCCA" */
export type Rtcs_Db_Mlhud_Kcca_Stddev_Samp_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TitleNumber?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Mlhud_Kcca_Sum_Fields = {
  __typename?: "rtcs_db_mlhud_KCCA_sum_fields";
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  Folio?: Maybe<Scalars["float8"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  TitleNumber?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.mlhud_KCCA" */
export type Rtcs_Db_Mlhud_Kcca_Sum_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TitleNumber?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Mlhud_Kcca_Var_Pop_Fields = {
  __typename?: "rtcs_db_mlhud_KCCA_var_pop_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TitleNumber?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.mlhud_KCCA" */
export type Rtcs_Db_Mlhud_Kcca_Var_Pop_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TitleNumber?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Mlhud_Kcca_Var_Samp_Fields = {
  __typename?: "rtcs_db_mlhud_KCCA_var_samp_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TitleNumber?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.mlhud_KCCA" */
export type Rtcs_Db_Mlhud_Kcca_Var_Samp_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TitleNumber?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Mlhud_Kcca_Variance_Fields = {
  __typename?: "rtcs_db_mlhud_KCCA_variance_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TitleNumber?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.mlhud_KCCA" */
export type Rtcs_Db_Mlhud_Kcca_Variance_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TitleNumber?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.mlhud_Mukono" */
export type Rtcs_Db_Mlhud_Mukono = {
  __typename?: "rtcs_db_mlhud_Mukono";
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.mlhud_Mukono" */
export type Rtcs_Db_Mlhud_Mukono_Aggregate = {
  __typename?: "rtcs_db_mlhud_Mukono_aggregate";
  aggregate?: Maybe<Rtcs_Db_Mlhud_Mukono_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Mlhud_Mukono>;
};

/** aggregate fields of "rtcs_db.mlhud_Mukono" */
export type Rtcs_Db_Mlhud_Mukono_Aggregate_Fields = {
  __typename?: "rtcs_db_mlhud_Mukono_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Mlhud_Mukono_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Mlhud_Mukono_Max_Fields>;
  min?: Maybe<Rtcs_Db_Mlhud_Mukono_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Mlhud_Mukono_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Mlhud_Mukono_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Mlhud_Mukono_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Mlhud_Mukono_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Mlhud_Mukono_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Mlhud_Mukono_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Mlhud_Mukono_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.mlhud_Mukono" */
export type Rtcs_Db_Mlhud_Mukono_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Mlhud_Mukono_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.mlhud_Mukono" */
export type Rtcs_Db_Mlhud_Mukono_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Mlhud_Mukono_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Mlhud_Mukono_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Mlhud_Mukono_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Mlhud_Mukono_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Mlhud_Mukono_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Mlhud_Mukono_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Mlhud_Mukono_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Mlhud_Mukono_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Mlhud_Mukono_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Mlhud_Mukono_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.mlhud_Mukono" */
export type Rtcs_Db_Mlhud_Mukono_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Mlhud_Mukono_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Mlhud_Mukono_Avg_Fields = {
  __typename?: "rtcs_db_mlhud_Mukono_avg_fields";
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.mlhud_Mukono" */
export type Rtcs_Db_Mlhud_Mukono_Avg_Order_By = {
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.mlhud_Mukono". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Mlhud_Mukono_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  BaunitType?: Maybe<String_Comparison_Exp>;
  BlockNumber?: Maybe<String_Comparison_Exp>;
  County?: Maybe<String_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FirstName?: Maybe<String_Comparison_Exp>;
  Folio?: Maybe<Float8_Comparison_Exp>;
  FullName?: Maybe<String_Comparison_Exp>;
  Gender?: Maybe<String_Comparison_Exp>;
  LafNumber?: Maybe<String_Comparison_Exp>;
  LandType?: Maybe<String_Comparison_Exp>;
  LandUseType?: Maybe<String_Comparison_Exp>;
  LastName?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  LegalArea?: Maybe<Float8_Comparison_Exp>;
  LegalAreaUnitType?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  Municipality?: Maybe<String_Comparison_Exp>;
  Phone?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  RegistrationDatetime?: Maybe<Timestamptz_Comparison_Exp>;
  RoadName?: Maybe<String_Comparison_Exp>;
  Subcounty?: Maybe<String_Comparison_Exp>;
  TenureType?: Maybe<String_Comparison_Exp>;
  TitleLocation?: Maybe<String_Comparison_Exp>;
  Volume?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Mlhud_Mukono_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Mlhud_Mukono_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Mlhud_Mukono_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.mlhud_Mukono" */
export type Rtcs_Db_Mlhud_Mukono_Inc_Input = {
  Folio?: Maybe<Scalars["float8"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.mlhud_Mukono" */
export type Rtcs_Db_Mlhud_Mukono_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Mlhud_Mukono_Max_Fields = {
  __typename?: "rtcs_db_mlhud_Mukono_max_fields";
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.mlhud_Mukono" */
export type Rtcs_Db_Mlhud_Mukono_Max_Order_By = {
  Address?: Maybe<Order_By>;
  BaunitType?: Maybe<Order_By>;
  BlockNumber?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  LafNumber?: Maybe<Order_By>;
  LandType?: Maybe<Order_By>;
  LandUseType?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  LegalAreaUnitType?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  RegistrationDatetime?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  TenureType?: Maybe<Order_By>;
  TitleLocation?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Mlhud_Mukono_Min_Fields = {
  __typename?: "rtcs_db_mlhud_Mukono_min_fields";
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.mlhud_Mukono" */
export type Rtcs_Db_Mlhud_Mukono_Min_Order_By = {
  Address?: Maybe<Order_By>;
  BaunitType?: Maybe<Order_By>;
  BlockNumber?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  LafNumber?: Maybe<Order_By>;
  LandType?: Maybe<Order_By>;
  LandUseType?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  LegalAreaUnitType?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  RegistrationDatetime?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  TenureType?: Maybe<Order_By>;
  TitleLocation?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.mlhud_Mukono" */
export type Rtcs_Db_Mlhud_Mukono_Mutation_Response = {
  __typename?: "rtcs_db_mlhud_Mukono_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Mlhud_Mukono>;
};

/** input type for inserting object relation for remote table "rtcs_db.mlhud_Mukono" */
export type Rtcs_Db_Mlhud_Mukono_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Mlhud_Mukono_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.mlhud_Mukono" */
export type Rtcs_Db_Mlhud_Mukono_Order_By = {
  Address?: Maybe<Order_By>;
  BaunitType?: Maybe<Order_By>;
  BlockNumber?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  LafNumber?: Maybe<Order_By>;
  LandType?: Maybe<Order_By>;
  LandUseType?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  LegalAreaUnitType?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  RegistrationDatetime?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  TenureType?: Maybe<Order_By>;
  TitleLocation?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.mlhud_Mukono" */
export enum Rtcs_Db_Mlhud_Mukono_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  BaunitType = "BaunitType",
  /** column name */
  BlockNumber = "BlockNumber",
  /** column name */
  County = "County",
  /** column name */
  Description = "Description",
  /** column name */
  District = "District",
  /** column name */
  File = "File",
  /** column name */
  FirstName = "FirstName",
  /** column name */
  Folio = "Folio",
  /** column name */
  FullName = "FullName",
  /** column name */
  Gender = "Gender",
  /** column name */
  LafNumber = "LafNumber",
  /** column name */
  LandType = "LandType",
  /** column name */
  LandUseType = "LandUseType",
  /** column name */
  LastName = "LastName",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  LegalArea = "LegalArea",
  /** column name */
  LegalAreaUnitType = "LegalAreaUnitType",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  Municipality = "Municipality",
  /** column name */
  Phone = "Phone",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  RegistrationDatetime = "RegistrationDatetime",
  /** column name */
  RoadName = "RoadName",
  /** column name */
  Subcounty = "Subcounty",
  /** column name */
  TenureType = "TenureType",
  /** column name */
  TitleLocation = "TitleLocation",
  /** column name */
  Volume = "Volume",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.mlhud_Mukono" */
export type Rtcs_Db_Mlhud_Mukono_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["float8"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Mlhud_Mukono_Stddev_Fields = {
  __typename?: "rtcs_db_mlhud_Mukono_stddev_fields";
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.mlhud_Mukono" */
export type Rtcs_Db_Mlhud_Mukono_Stddev_Order_By = {
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Mlhud_Mukono_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_mlhud_Mukono_stddev_pop_fields";
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.mlhud_Mukono" */
export type Rtcs_Db_Mlhud_Mukono_Stddev_Pop_Order_By = {
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Mlhud_Mukono_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_mlhud_Mukono_stddev_samp_fields";
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.mlhud_Mukono" */
export type Rtcs_Db_Mlhud_Mukono_Stddev_Samp_Order_By = {
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Mlhud_Mukono_Sum_Fields = {
  __typename?: "rtcs_db_mlhud_Mukono_sum_fields";
  Folio?: Maybe<Scalars["float8"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.mlhud_Mukono" */
export type Rtcs_Db_Mlhud_Mukono_Sum_Order_By = {
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Mlhud_Mukono_Var_Pop_Fields = {
  __typename?: "rtcs_db_mlhud_Mukono_var_pop_fields";
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.mlhud_Mukono" */
export type Rtcs_Db_Mlhud_Mukono_Var_Pop_Order_By = {
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Mlhud_Mukono_Var_Samp_Fields = {
  __typename?: "rtcs_db_mlhud_Mukono_var_samp_fields";
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.mlhud_Mukono" */
export type Rtcs_Db_Mlhud_Mukono_Var_Samp_Order_By = {
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Mlhud_Mukono_Variance_Fields = {
  __typename?: "rtcs_db_mlhud_Mukono_variance_fields";
  Folio?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.mlhud_Mukono" */
export type Rtcs_Db_Mlhud_Mukono_Variance_Order_By = {
  Folio?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.mlhud_Properties" */
export type Rtcs_Db_Mlhud_Properties = {
  __typename?: "rtcs_db_mlhud_Properties";
  BlockNumb?: Maybe<Scalars["String"]>;
  CadCounty?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  LotNumber?: Maybe<Scalars["String"]>;
  RoadName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.mlhud_Properties" */
export type Rtcs_Db_Mlhud_Properties_Aggregate = {
  __typename?: "rtcs_db_mlhud_Properties_aggregate";
  aggregate?: Maybe<Rtcs_Db_Mlhud_Properties_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Mlhud_Properties>;
};

/** aggregate fields of "rtcs_db.mlhud_Properties" */
export type Rtcs_Db_Mlhud_Properties_Aggregate_Fields = {
  __typename?: "rtcs_db_mlhud_Properties_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Mlhud_Properties_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Mlhud_Properties_Max_Fields>;
  min?: Maybe<Rtcs_Db_Mlhud_Properties_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Mlhud_Properties_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Mlhud_Properties_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Mlhud_Properties_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Mlhud_Properties_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Mlhud_Properties_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Mlhud_Properties_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Mlhud_Properties_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.mlhud_Properties" */
export type Rtcs_Db_Mlhud_Properties_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Mlhud_Properties_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.mlhud_Properties" */
export type Rtcs_Db_Mlhud_Properties_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Mlhud_Properties_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Mlhud_Properties_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Mlhud_Properties_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Mlhud_Properties_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Mlhud_Properties_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Mlhud_Properties_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Mlhud_Properties_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Mlhud_Properties_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Mlhud_Properties_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Mlhud_Properties_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.mlhud_Properties" */
export type Rtcs_Db_Mlhud_Properties_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Mlhud_Properties_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Mlhud_Properties_Avg_Fields = {
  __typename?: "rtcs_db_mlhud_Properties_avg_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.mlhud_Properties" */
export type Rtcs_Db_Mlhud_Properties_Avg_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.mlhud_Properties". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Mlhud_Properties_Bool_Exp = {
  BlockNumb?: Maybe<String_Comparison_Exp>;
  CadCounty?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  LotNumber?: Maybe<String_Comparison_Exp>;
  RoadName?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Mlhud_Properties_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Mlhud_Properties_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Mlhud_Properties_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.mlhud_Properties" */
export type Rtcs_Db_Mlhud_Properties_Inc_Input = {
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.mlhud_Properties" */
export type Rtcs_Db_Mlhud_Properties_Insert_Input = {
  BlockNumb?: Maybe<Scalars["String"]>;
  CadCounty?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  LotNumber?: Maybe<Scalars["String"]>;
  RoadName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Mlhud_Properties_Max_Fields = {
  __typename?: "rtcs_db_mlhud_Properties_max_fields";
  BlockNumb?: Maybe<Scalars["String"]>;
  CadCounty?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  LotNumber?: Maybe<Scalars["String"]>;
  RoadName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.mlhud_Properties" */
export type Rtcs_Db_Mlhud_Properties_Max_Order_By = {
  BlockNumb?: Maybe<Order_By>;
  CadCounty?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  LotNumber?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Mlhud_Properties_Min_Fields = {
  __typename?: "rtcs_db_mlhud_Properties_min_fields";
  BlockNumb?: Maybe<Scalars["String"]>;
  CadCounty?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  LotNumber?: Maybe<Scalars["String"]>;
  RoadName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.mlhud_Properties" */
export type Rtcs_Db_Mlhud_Properties_Min_Order_By = {
  BlockNumb?: Maybe<Order_By>;
  CadCounty?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  LotNumber?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.mlhud_Properties" */
export type Rtcs_Db_Mlhud_Properties_Mutation_Response = {
  __typename?: "rtcs_db_mlhud_Properties_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Mlhud_Properties>;
};

/** input type for inserting object relation for remote table "rtcs_db.mlhud_Properties" */
export type Rtcs_Db_Mlhud_Properties_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Mlhud_Properties_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.mlhud_Properties" */
export type Rtcs_Db_Mlhud_Properties_Order_By = {
  BlockNumb?: Maybe<Order_By>;
  CadCounty?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  LotNumber?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.mlhud_Properties" */
export enum Rtcs_Db_Mlhud_Properties_Select_Column {
  /** column name */
  BlockNumb = "BlockNumb",
  /** column name */
  CadCounty = "CadCounty",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  LotNumber = "LotNumber",
  /** column name */
  RoadName = "RoadName",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.mlhud_Properties" */
export type Rtcs_Db_Mlhud_Properties_Set_Input = {
  BlockNumb?: Maybe<Scalars["String"]>;
  CadCounty?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  LotNumber?: Maybe<Scalars["String"]>;
  RoadName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Mlhud_Properties_Stddev_Fields = {
  __typename?: "rtcs_db_mlhud_Properties_stddev_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.mlhud_Properties" */
export type Rtcs_Db_Mlhud_Properties_Stddev_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Mlhud_Properties_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_mlhud_Properties_stddev_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.mlhud_Properties" */
export type Rtcs_Db_Mlhud_Properties_Stddev_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Mlhud_Properties_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_mlhud_Properties_stddev_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.mlhud_Properties" */
export type Rtcs_Db_Mlhud_Properties_Stddev_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Mlhud_Properties_Sum_Fields = {
  __typename?: "rtcs_db_mlhud_Properties_sum_fields";
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.mlhud_Properties" */
export type Rtcs_Db_Mlhud_Properties_Sum_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Mlhud_Properties_Var_Pop_Fields = {
  __typename?: "rtcs_db_mlhud_Properties_var_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.mlhud_Properties" */
export type Rtcs_Db_Mlhud_Properties_Var_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Mlhud_Properties_Var_Samp_Fields = {
  __typename?: "rtcs_db_mlhud_Properties_var_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.mlhud_Properties" */
export type Rtcs_Db_Mlhud_Properties_Var_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Mlhud_Properties_Variance_Fields = {
  __typename?: "rtcs_db_mlhud_Properties_variance_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.mlhud_Properties" */
export type Rtcs_Db_Mlhud_Properties_Variance_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.mlhud_WakisoBusiro" */
export type Rtcs_Db_Mlhud_WakisoBusiro = {
  __typename?: "rtcs_db_mlhud_WakisoBusiro";
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.mlhud_WakisoBusiro" */
export type Rtcs_Db_Mlhud_WakisoBusiro_Aggregate = {
  __typename?: "rtcs_db_mlhud_WakisoBusiro_aggregate";
  aggregate?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Mlhud_WakisoBusiro>;
};

/** aggregate fields of "rtcs_db.mlhud_WakisoBusiro" */
export type Rtcs_Db_Mlhud_WakisoBusiro_Aggregate_Fields = {
  __typename?: "rtcs_db_mlhud_WakisoBusiro_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Max_Fields>;
  min?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.mlhud_WakisoBusiro" */
export type Rtcs_Db_Mlhud_WakisoBusiro_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Mlhud_WakisoBusiro_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.mlhud_WakisoBusiro" */
export type Rtcs_Db_Mlhud_WakisoBusiro_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.mlhud_WakisoBusiro" */
export type Rtcs_Db_Mlhud_WakisoBusiro_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Mlhud_WakisoBusiro_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Mlhud_WakisoBusiro_Avg_Fields = {
  __typename?: "rtcs_db_mlhud_WakisoBusiro_avg_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.mlhud_WakisoBusiro" */
export type Rtcs_Db_Mlhud_WakisoBusiro_Avg_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.mlhud_WakisoBusiro". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Mlhud_WakisoBusiro_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  BaunitType?: Maybe<String_Comparison_Exp>;
  BlockNumber?: Maybe<String_Comparison_Exp>;
  CondoPlanNo?: Maybe<String_Comparison_Exp>;
  CondoUnitFactor?: Maybe<Float8_Comparison_Exp>;
  County?: Maybe<String_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FirstName?: Maybe<String_Comparison_Exp>;
  Folio?: Maybe<String_Comparison_Exp>;
  FullName?: Maybe<String_Comparison_Exp>;
  Gender?: Maybe<String_Comparison_Exp>;
  LafNumber?: Maybe<String_Comparison_Exp>;
  LandType?: Maybe<String_Comparison_Exp>;
  LandUseType?: Maybe<String_Comparison_Exp>;
  LastName?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  LegalArea?: Maybe<Float8_Comparison_Exp>;
  LegalAreaUnitType?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  Municipality?: Maybe<String_Comparison_Exp>;
  Phone?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  RegistrationDatetime?: Maybe<Timestamptz_Comparison_Exp>;
  RoadName?: Maybe<String_Comparison_Exp>;
  Subcounty?: Maybe<String_Comparison_Exp>;
  TenureType?: Maybe<String_Comparison_Exp>;
  TitleLocation?: Maybe<String_Comparison_Exp>;
  Volume?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.mlhud_WakisoBusiro" */
export type Rtcs_Db_Mlhud_WakisoBusiro_Inc_Input = {
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.mlhud_WakisoBusiro" */
export type Rtcs_Db_Mlhud_WakisoBusiro_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Mlhud_WakisoBusiro_Max_Fields = {
  __typename?: "rtcs_db_mlhud_WakisoBusiro_max_fields";
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.mlhud_WakisoBusiro" */
export type Rtcs_Db_Mlhud_WakisoBusiro_Max_Order_By = {
  Address?: Maybe<Order_By>;
  BaunitType?: Maybe<Order_By>;
  BlockNumber?: Maybe<Order_By>;
  CondoPlanNo?: Maybe<Order_By>;
  CondoUnitFactor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  LafNumber?: Maybe<Order_By>;
  LandType?: Maybe<Order_By>;
  LandUseType?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  LegalAreaUnitType?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  RegistrationDatetime?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  TenureType?: Maybe<Order_By>;
  TitleLocation?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Mlhud_WakisoBusiro_Min_Fields = {
  __typename?: "rtcs_db_mlhud_WakisoBusiro_min_fields";
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.mlhud_WakisoBusiro" */
export type Rtcs_Db_Mlhud_WakisoBusiro_Min_Order_By = {
  Address?: Maybe<Order_By>;
  BaunitType?: Maybe<Order_By>;
  BlockNumber?: Maybe<Order_By>;
  CondoPlanNo?: Maybe<Order_By>;
  CondoUnitFactor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  LafNumber?: Maybe<Order_By>;
  LandType?: Maybe<Order_By>;
  LandUseType?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  LegalAreaUnitType?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  RegistrationDatetime?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  TenureType?: Maybe<Order_By>;
  TitleLocation?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.mlhud_WakisoBusiro" */
export type Rtcs_Db_Mlhud_WakisoBusiro_Mutation_Response = {
  __typename?: "rtcs_db_mlhud_WakisoBusiro_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Mlhud_WakisoBusiro>;
};

/** input type for inserting object relation for remote table "rtcs_db.mlhud_WakisoBusiro" */
export type Rtcs_Db_Mlhud_WakisoBusiro_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Mlhud_WakisoBusiro_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.mlhud_WakisoBusiro" */
export type Rtcs_Db_Mlhud_WakisoBusiro_Order_By = {
  Address?: Maybe<Order_By>;
  BaunitType?: Maybe<Order_By>;
  BlockNumber?: Maybe<Order_By>;
  CondoPlanNo?: Maybe<Order_By>;
  CondoUnitFactor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  LafNumber?: Maybe<Order_By>;
  LandType?: Maybe<Order_By>;
  LandUseType?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  LegalAreaUnitType?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  RegistrationDatetime?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  TenureType?: Maybe<Order_By>;
  TitleLocation?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.mlhud_WakisoBusiro" */
export enum Rtcs_Db_Mlhud_WakisoBusiro_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  BaunitType = "BaunitType",
  /** column name */
  BlockNumber = "BlockNumber",
  /** column name */
  CondoPlanNo = "CondoPlanNo",
  /** column name */
  CondoUnitFactor = "CondoUnitFactor",
  /** column name */
  County = "County",
  /** column name */
  Description = "Description",
  /** column name */
  District = "District",
  /** column name */
  File = "File",
  /** column name */
  FirstName = "FirstName",
  /** column name */
  Folio = "Folio",
  /** column name */
  FullName = "FullName",
  /** column name */
  Gender = "Gender",
  /** column name */
  LafNumber = "LafNumber",
  /** column name */
  LandType = "LandType",
  /** column name */
  LandUseType = "LandUseType",
  /** column name */
  LastName = "LastName",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  LegalArea = "LegalArea",
  /** column name */
  LegalAreaUnitType = "LegalAreaUnitType",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  Municipality = "Municipality",
  /** column name */
  Phone = "Phone",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  RegistrationDatetime = "RegistrationDatetime",
  /** column name */
  RoadName = "RoadName",
  /** column name */
  Subcounty = "Subcounty",
  /** column name */
  TenureType = "TenureType",
  /** column name */
  TitleLocation = "TitleLocation",
  /** column name */
  Volume = "Volume",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.mlhud_WakisoBusiro" */
export type Rtcs_Db_Mlhud_WakisoBusiro_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Mlhud_WakisoBusiro_Stddev_Fields = {
  __typename?: "rtcs_db_mlhud_WakisoBusiro_stddev_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.mlhud_WakisoBusiro" */
export type Rtcs_Db_Mlhud_WakisoBusiro_Stddev_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Mlhud_WakisoBusiro_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_mlhud_WakisoBusiro_stddev_pop_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.mlhud_WakisoBusiro" */
export type Rtcs_Db_Mlhud_WakisoBusiro_Stddev_Pop_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Mlhud_WakisoBusiro_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_mlhud_WakisoBusiro_stddev_samp_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.mlhud_WakisoBusiro" */
export type Rtcs_Db_Mlhud_WakisoBusiro_Stddev_Samp_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Mlhud_WakisoBusiro_Sum_Fields = {
  __typename?: "rtcs_db_mlhud_WakisoBusiro_sum_fields";
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.mlhud_WakisoBusiro" */
export type Rtcs_Db_Mlhud_WakisoBusiro_Sum_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Mlhud_WakisoBusiro_Var_Pop_Fields = {
  __typename?: "rtcs_db_mlhud_WakisoBusiro_var_pop_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.mlhud_WakisoBusiro" */
export type Rtcs_Db_Mlhud_WakisoBusiro_Var_Pop_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Mlhud_WakisoBusiro_Var_Samp_Fields = {
  __typename?: "rtcs_db_mlhud_WakisoBusiro_var_samp_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.mlhud_WakisoBusiro" */
export type Rtcs_Db_Mlhud_WakisoBusiro_Var_Samp_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Mlhud_WakisoBusiro_Variance_Fields = {
  __typename?: "rtcs_db_mlhud_WakisoBusiro_variance_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.mlhud_WakisoBusiro" */
export type Rtcs_Db_Mlhud_WakisoBusiro_Variance_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.mlhud_WakisoKyadondo" */
export type Rtcs_Db_Mlhud_WakisoKyadondo = {
  __typename?: "rtcs_db_mlhud_WakisoKyadondo";
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.mlhud_WakisoKyadondo" */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Aggregate = {
  __typename?: "rtcs_db_mlhud_WakisoKyadondo_aggregate";
  aggregate?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Mlhud_WakisoKyadondo>;
};

/** aggregate fields of "rtcs_db.mlhud_WakisoKyadondo" */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Aggregate_Fields = {
  __typename?: "rtcs_db_mlhud_WakisoKyadondo_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Max_Fields>;
  min?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.mlhud_WakisoKyadondo" */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Mlhud_WakisoKyadondo_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.mlhud_WakisoKyadondo" */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.mlhud_WakisoKyadondo" */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Mlhud_WakisoKyadondo_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Avg_Fields = {
  __typename?: "rtcs_db_mlhud_WakisoKyadondo_avg_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.mlhud_WakisoKyadondo" */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Avg_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.mlhud_WakisoKyadondo". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  BaunitType?: Maybe<String_Comparison_Exp>;
  BlockNumber?: Maybe<String_Comparison_Exp>;
  CondoPlanNo?: Maybe<String_Comparison_Exp>;
  CondoUnitFactor?: Maybe<Float8_Comparison_Exp>;
  County?: Maybe<String_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FirstName?: Maybe<String_Comparison_Exp>;
  Folio?: Maybe<String_Comparison_Exp>;
  FullName?: Maybe<String_Comparison_Exp>;
  Gender?: Maybe<String_Comparison_Exp>;
  LafNumber?: Maybe<String_Comparison_Exp>;
  LandType?: Maybe<String_Comparison_Exp>;
  LandUseType?: Maybe<String_Comparison_Exp>;
  LastName?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  LegalArea?: Maybe<Float8_Comparison_Exp>;
  LegalAreaUnitType?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  Municipality?: Maybe<String_Comparison_Exp>;
  Phone?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  RegistrationDatetime?: Maybe<Timestamptz_Comparison_Exp>;
  RoadName?: Maybe<String_Comparison_Exp>;
  Subcounty?: Maybe<String_Comparison_Exp>;
  TenureType?: Maybe<String_Comparison_Exp>;
  TitleLocation?: Maybe<String_Comparison_Exp>;
  Volume?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.mlhud_WakisoKyadondo" */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Inc_Input = {
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.mlhud_WakisoKyadondo" */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Max_Fields = {
  __typename?: "rtcs_db_mlhud_WakisoKyadondo_max_fields";
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.mlhud_WakisoKyadondo" */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Max_Order_By = {
  Address?: Maybe<Order_By>;
  BaunitType?: Maybe<Order_By>;
  BlockNumber?: Maybe<Order_By>;
  CondoPlanNo?: Maybe<Order_By>;
  CondoUnitFactor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  LafNumber?: Maybe<Order_By>;
  LandType?: Maybe<Order_By>;
  LandUseType?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  LegalAreaUnitType?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  RegistrationDatetime?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  TenureType?: Maybe<Order_By>;
  TitleLocation?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Min_Fields = {
  __typename?: "rtcs_db_mlhud_WakisoKyadondo_min_fields";
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.mlhud_WakisoKyadondo" */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Min_Order_By = {
  Address?: Maybe<Order_By>;
  BaunitType?: Maybe<Order_By>;
  BlockNumber?: Maybe<Order_By>;
  CondoPlanNo?: Maybe<Order_By>;
  CondoUnitFactor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  LafNumber?: Maybe<Order_By>;
  LandType?: Maybe<Order_By>;
  LandUseType?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  LegalAreaUnitType?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  RegistrationDatetime?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  TenureType?: Maybe<Order_By>;
  TitleLocation?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.mlhud_WakisoKyadondo" */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Mutation_Response = {
  __typename?: "rtcs_db_mlhud_WakisoKyadondo_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Mlhud_WakisoKyadondo>;
};

/** input type for inserting object relation for remote table "rtcs_db.mlhud_WakisoKyadondo" */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Mlhud_WakisoKyadondo_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.mlhud_WakisoKyadondo" */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Order_By = {
  Address?: Maybe<Order_By>;
  BaunitType?: Maybe<Order_By>;
  BlockNumber?: Maybe<Order_By>;
  CondoPlanNo?: Maybe<Order_By>;
  CondoUnitFactor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  FullName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  LafNumber?: Maybe<Order_By>;
  LandType?: Maybe<Order_By>;
  LandUseType?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  LegalAreaUnitType?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  RegistrationDatetime?: Maybe<Order_By>;
  RoadName?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  TenureType?: Maybe<Order_By>;
  TitleLocation?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.mlhud_WakisoKyadondo" */
export enum Rtcs_Db_Mlhud_WakisoKyadondo_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  BaunitType = "BaunitType",
  /** column name */
  BlockNumber = "BlockNumber",
  /** column name */
  CondoPlanNo = "CondoPlanNo",
  /** column name */
  CondoUnitFactor = "CondoUnitFactor",
  /** column name */
  County = "County",
  /** column name */
  Description = "Description",
  /** column name */
  District = "District",
  /** column name */
  File = "File",
  /** column name */
  FirstName = "FirstName",
  /** column name */
  Folio = "Folio",
  /** column name */
  FullName = "FullName",
  /** column name */
  Gender = "Gender",
  /** column name */
  LafNumber = "LafNumber",
  /** column name */
  LandType = "LandType",
  /** column name */
  LandUseType = "LandUseType",
  /** column name */
  LastName = "LastName",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  LegalArea = "LegalArea",
  /** column name */
  LegalAreaUnitType = "LegalAreaUnitType",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  Municipality = "Municipality",
  /** column name */
  Phone = "Phone",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  RegistrationDatetime = "RegistrationDatetime",
  /** column name */
  RoadName = "RoadName",
  /** column name */
  Subcounty = "Subcounty",
  /** column name */
  TenureType = "TenureType",
  /** column name */
  TitleLocation = "TitleLocation",
  /** column name */
  Volume = "Volume",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.mlhud_WakisoKyadondo" */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  BaunitType?: Maybe<Scalars["String"]>;
  BlockNumber?: Maybe<Scalars["String"]>;
  CondoPlanNo?: Maybe<Scalars["String"]>;
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  FullName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  LafNumber?: Maybe<Scalars["String"]>;
  LandType?: Maybe<Scalars["String"]>;
  LandUseType?: Maybe<Scalars["String"]>;
  LastName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  LegalAreaUnitType?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  RegistrationDatetime?: Maybe<Scalars["timestamptz"]>;
  RoadName?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  TenureType?: Maybe<Scalars["String"]>;
  TitleLocation?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Stddev_Fields = {
  __typename?: "rtcs_db_mlhud_WakisoKyadondo_stddev_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.mlhud_WakisoKyadondo" */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Stddev_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_mlhud_WakisoKyadondo_stddev_pop_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.mlhud_WakisoKyadondo" */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Stddev_Pop_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_mlhud_WakisoKyadondo_stddev_samp_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.mlhud_WakisoKyadondo" */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Stddev_Samp_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Sum_Fields = {
  __typename?: "rtcs_db_mlhud_WakisoKyadondo_sum_fields";
  CondoUnitFactor?: Maybe<Scalars["float8"]>;
  LegalArea?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.mlhud_WakisoKyadondo" */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Sum_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Var_Pop_Fields = {
  __typename?: "rtcs_db_mlhud_WakisoKyadondo_var_pop_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.mlhud_WakisoKyadondo" */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Var_Pop_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Var_Samp_Fields = {
  __typename?: "rtcs_db_mlhud_WakisoKyadondo_var_samp_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.mlhud_WakisoKyadondo" */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Var_Samp_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Variance_Fields = {
  __typename?: "rtcs_db_mlhud_WakisoKyadondo_variance_fields";
  CondoUnitFactor?: Maybe<Scalars["Float"]>;
  LegalArea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.mlhud_WakisoKyadondo" */
export type Rtcs_Db_Mlhud_WakisoKyadondo_Variance_Order_By = {
  CondoUnitFactor?: Maybe<Order_By>;
  LegalArea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.nira_Nira" */
export type Rtcs_Db_Nira_Nira = {
  __typename?: "rtcs_db_nira_Nira";
  BirthCity?: Maybe<Scalars["String"]>;
  Dob?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  Nin?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.nira_Nira" */
export type Rtcs_Db_Nira_Nira_Aggregate = {
  __typename?: "rtcs_db_nira_Nira_aggregate";
  aggregate?: Maybe<Rtcs_Db_Nira_Nira_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Nira_Nira>;
};

/** aggregate fields of "rtcs_db.nira_Nira" */
export type Rtcs_Db_Nira_Nira_Aggregate_Fields = {
  __typename?: "rtcs_db_nira_Nira_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Nira_Nira_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Nira_Nira_Max_Fields>;
  min?: Maybe<Rtcs_Db_Nira_Nira_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Nira_Nira_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Nira_Nira_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Nira_Nira_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Nira_Nira_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Nira_Nira_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Nira_Nira_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Nira_Nira_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.nira_Nira" */
export type Rtcs_Db_Nira_Nira_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Nira_Nira_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.nira_Nira" */
export type Rtcs_Db_Nira_Nira_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Nira_Nira_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Nira_Nira_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Nira_Nira_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Nira_Nira_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Nira_Nira_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Nira_Nira_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Nira_Nira_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Nira_Nira_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Nira_Nira_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Nira_Nira_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.nira_Nira" */
export type Rtcs_Db_Nira_Nira_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Nira_Nira_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Nira_Nira_Avg_Fields = {
  __typename?: "rtcs_db_nira_Nira_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.nira_Nira" */
export type Rtcs_Db_Nira_Nira_Avg_Order_By = {
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.nira_Nira". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Nira_Nira_Bool_Exp = {
  BirthCity?: Maybe<String_Comparison_Exp>;
  Dob?: Maybe<Timestamptz_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Mobile?: Maybe<String_Comparison_Exp>;
  Nin?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Nira_Nira_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Nira_Nira_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Nira_Nira_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.nira_Nira" */
export type Rtcs_Db_Nira_Nira_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.nira_Nira" */
export type Rtcs_Db_Nira_Nira_Insert_Input = {
  BirthCity?: Maybe<Scalars["String"]>;
  Dob?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  Nin?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Nira_Nira_Max_Fields = {
  __typename?: "rtcs_db_nira_Nira_max_fields";
  BirthCity?: Maybe<Scalars["String"]>;
  Dob?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  Nin?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.nira_Nira" */
export type Rtcs_Db_Nira_Nira_Max_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Dob?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Nin?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Nira_Nira_Min_Fields = {
  __typename?: "rtcs_db_nira_Nira_min_fields";
  BirthCity?: Maybe<Scalars["String"]>;
  Dob?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  Nin?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.nira_Nira" */
export type Rtcs_Db_Nira_Nira_Min_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Dob?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Nin?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.nira_Nira" */
export type Rtcs_Db_Nira_Nira_Mutation_Response = {
  __typename?: "rtcs_db_nira_Nira_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Nira_Nira>;
};

/** input type for inserting object relation for remote table "rtcs_db.nira_Nira" */
export type Rtcs_Db_Nira_Nira_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Nira_Nira_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.nira_Nira" */
export type Rtcs_Db_Nira_Nira_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Dob?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Nin?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.nira_Nira" */
export enum Rtcs_Db_Nira_Nira_Select_Column {
  /** column name */
  BirthCity = "BirthCity",
  /** column name */
  Dob = "Dob",
  /** column name */
  Email = "Email",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  Nin = "Nin",
  /** column name */
  Surname = "Surname",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.nira_Nira" */
export type Rtcs_Db_Nira_Nira_Set_Input = {
  BirthCity?: Maybe<Scalars["String"]>;
  Dob?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["String"]>;
  Nin?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Nira_Nira_Stddev_Fields = {
  __typename?: "rtcs_db_nira_Nira_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.nira_Nira" */
export type Rtcs_Db_Nira_Nira_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Nira_Nira_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_nira_Nira_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.nira_Nira" */
export type Rtcs_Db_Nira_Nira_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Nira_Nira_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_nira_Nira_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.nira_Nira" */
export type Rtcs_Db_Nira_Nira_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Nira_Nira_Sum_Fields = {
  __typename?: "rtcs_db_nira_Nira_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.nira_Nira" */
export type Rtcs_Db_Nira_Nira_Sum_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Nira_Nira_Var_Pop_Fields = {
  __typename?: "rtcs_db_nira_Nira_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.nira_Nira" */
export type Rtcs_Db_Nira_Nira_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Nira_Nira_Var_Samp_Fields = {
  __typename?: "rtcs_db_nira_Nira_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.nira_Nira" */
export type Rtcs_Db_Nira_Nira_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Nira_Nira_Variance_Fields = {
  __typename?: "rtcs_db_nira_Nira_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.nira_Nira" */
export type Rtcs_Db_Nira_Nira_Variance_Order_By = {
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.nira_RawData" */
export type Rtcs_Db_Nira_RawData = {
  __typename?: "rtcs_db_nira_RawData";
  DateOfBirth?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  GivenName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalID?: Maybe<Scalars["String"]>;
  PseudoNIN?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TinNumber?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.nira_RawData" */
export type Rtcs_Db_Nira_RawData_Aggregate = {
  __typename?: "rtcs_db_nira_RawData_aggregate";
  aggregate?: Maybe<Rtcs_Db_Nira_RawData_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Nira_RawData>;
};

/** aggregate fields of "rtcs_db.nira_RawData" */
export type Rtcs_Db_Nira_RawData_Aggregate_Fields = {
  __typename?: "rtcs_db_nira_RawData_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Nira_RawData_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Nira_RawData_Max_Fields>;
  min?: Maybe<Rtcs_Db_Nira_RawData_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Nira_RawData_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Nira_RawData_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Nira_RawData_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Nira_RawData_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Nira_RawData_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Nira_RawData_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Nira_RawData_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.nira_RawData" */
export type Rtcs_Db_Nira_RawData_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Nira_RawData_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.nira_RawData" */
export type Rtcs_Db_Nira_RawData_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Nira_RawData_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Nira_RawData_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Nira_RawData_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Nira_RawData_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Nira_RawData_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Nira_RawData_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Nira_RawData_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Nira_RawData_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Nira_RawData_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Nira_RawData_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.nira_RawData" */
export type Rtcs_Db_Nira_RawData_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Nira_RawData_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Nira_RawData_Avg_Fields = {
  __typename?: "rtcs_db_nira_RawData_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.nira_RawData" */
export type Rtcs_Db_Nira_RawData_Avg_Order_By = {
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.nira_RawData". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Nira_RawData_Bool_Exp = {
  DateOfBirth?: Maybe<Timestamptz_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  GivenName?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MobileNumber?: Maybe<String_Comparison_Exp>;
  NationalID?: Maybe<String_Comparison_Exp>;
  PseudoNIN?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  TinNumber?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Nira_RawData_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Nira_RawData_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Nira_RawData_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.nira_RawData" */
export type Rtcs_Db_Nira_RawData_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.nira_RawData" */
export type Rtcs_Db_Nira_RawData_Insert_Input = {
  DateOfBirth?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  GivenName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalID?: Maybe<Scalars["String"]>;
  PseudoNIN?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TinNumber?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Nira_RawData_Max_Fields = {
  __typename?: "rtcs_db_nira_RawData_max_fields";
  DateOfBirth?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  GivenName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalID?: Maybe<Scalars["String"]>;
  PseudoNIN?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TinNumber?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.nira_RawData" */
export type Rtcs_Db_Nira_RawData_Max_Order_By = {
  DateOfBirth?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GivenName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalID?: Maybe<Order_By>;
  PseudoNIN?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TinNumber?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Nira_RawData_Min_Fields = {
  __typename?: "rtcs_db_nira_RawData_min_fields";
  DateOfBirth?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  GivenName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalID?: Maybe<Scalars["String"]>;
  PseudoNIN?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TinNumber?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.nira_RawData" */
export type Rtcs_Db_Nira_RawData_Min_Order_By = {
  DateOfBirth?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GivenName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalID?: Maybe<Order_By>;
  PseudoNIN?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TinNumber?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.nira_RawData" */
export type Rtcs_Db_Nira_RawData_Mutation_Response = {
  __typename?: "rtcs_db_nira_RawData_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Nira_RawData>;
};

/** input type for inserting object relation for remote table "rtcs_db.nira_RawData" */
export type Rtcs_Db_Nira_RawData_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Nira_RawData_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.nira_RawData" */
export type Rtcs_Db_Nira_RawData_Order_By = {
  DateOfBirth?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GivenName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalID?: Maybe<Order_By>;
  PseudoNIN?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TinNumber?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.nira_RawData" */
export enum Rtcs_Db_Nira_RawData_Select_Column {
  /** column name */
  DateOfBirth = "DateOfBirth",
  /** column name */
  Email = "Email",
  /** column name */
  File = "File",
  /** column name */
  GivenName = "GivenName",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  NationalId = "NationalID",
  /** column name */
  PseudoNin = "PseudoNIN",
  /** column name */
  Surname = "Surname",
  /** column name */
  TinNumber = "TinNumber",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.nira_RawData" */
export type Rtcs_Db_Nira_RawData_Set_Input = {
  DateOfBirth?: Maybe<Scalars["timestamptz"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  GivenName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalID?: Maybe<Scalars["String"]>;
  PseudoNIN?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TinNumber?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Nira_RawData_Stddev_Fields = {
  __typename?: "rtcs_db_nira_RawData_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.nira_RawData" */
export type Rtcs_Db_Nira_RawData_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Nira_RawData_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_nira_RawData_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.nira_RawData" */
export type Rtcs_Db_Nira_RawData_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Nira_RawData_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_nira_RawData_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.nira_RawData" */
export type Rtcs_Db_Nira_RawData_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Nira_RawData_Sum_Fields = {
  __typename?: "rtcs_db_nira_RawData_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.nira_RawData" */
export type Rtcs_Db_Nira_RawData_Sum_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Nira_RawData_Var_Pop_Fields = {
  __typename?: "rtcs_db_nira_RawData_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.nira_RawData" */
export type Rtcs_Db_Nira_RawData_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Nira_RawData_Var_Samp_Fields = {
  __typename?: "rtcs_db_nira_RawData_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.nira_RawData" */
export type Rtcs_Db_Nira_RawData_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Nira_RawData_Variance_Fields = {
  __typename?: "rtcs_db_nira_RawData_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.nira_RawData" */
export type Rtcs_Db_Nira_RawData_Variance_Order_By = {
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.nwsc_Customers" */
export type Rtcs_Db_Nwsc_Customers = {
  __typename?: "rtcs_db_nwsc_Customers";
  AddressCons?: Maybe<Scalars["String"]>;
  AddressPay?: Maybe<Scalars["String"]>;
  AddressTrans?: Maybe<Scalars["String"]>;
  AreaPay?: Maybe<Scalars["String"]>;
  AreaTrans?: Maybe<Scalars["String"]>;
  CustomernameCons?: Maybe<Scalars["String"]>;
  CustomernamePay?: Maybe<Scalars["String"]>;
  CustomernameTrans?: Maybe<Scalars["String"]>;
  Customerphoneno1Cons?: Maybe<Scalars["String"]>;
  Customerphoneno1Pay?: Maybe<Scalars["String"]>;
  Customerphoneno1Trans?: Maybe<Scalars["String"]>;
  Customerphoneno2Cons?: Maybe<Scalars["String"]>;
  Customerphoneno2Pay?: Maybe<Scalars["String"]>;
  Customerphoneno2Trans?: Maybe<Scalars["String"]>;
  Customerphoneno3Pay?: Maybe<Scalars["String"]>;
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LatitudeCons?: Maybe<Scalars["String"]>;
  LatitudePay?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LongitudeCons?: Maybe<Scalars["String"]>;
  LongitudePay?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.nwsc_Customers" */
export type Rtcs_Db_Nwsc_Customers_Aggregate = {
  __typename?: "rtcs_db_nwsc_Customers_aggregate";
  aggregate?: Maybe<Rtcs_Db_Nwsc_Customers_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Nwsc_Customers>;
};

/** aggregate fields of "rtcs_db.nwsc_Customers" */
export type Rtcs_Db_Nwsc_Customers_Aggregate_Fields = {
  __typename?: "rtcs_db_nwsc_Customers_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Nwsc_Customers_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Nwsc_Customers_Max_Fields>;
  min?: Maybe<Rtcs_Db_Nwsc_Customers_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Nwsc_Customers_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Nwsc_Customers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Nwsc_Customers_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Nwsc_Customers_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Nwsc_Customers_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Nwsc_Customers_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Nwsc_Customers_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.nwsc_Customers" */
export type Rtcs_Db_Nwsc_Customers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Nwsc_Customers_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.nwsc_Customers" */
export type Rtcs_Db_Nwsc_Customers_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Nwsc_Customers_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Nwsc_Customers_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Nwsc_Customers_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Nwsc_Customers_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Nwsc_Customers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Nwsc_Customers_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Nwsc_Customers_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Nwsc_Customers_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Nwsc_Customers_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Nwsc_Customers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.nwsc_Customers" */
export type Rtcs_Db_Nwsc_Customers_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Nwsc_Customers_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Nwsc_Customers_Avg_Fields = {
  __typename?: "rtcs_db_nwsc_Customers_avg_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.nwsc_Customers" */
export type Rtcs_Db_Nwsc_Customers_Avg_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.nwsc_Customers". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Nwsc_Customers_Bool_Exp = {
  AddressCons?: Maybe<String_Comparison_Exp>;
  AddressPay?: Maybe<String_Comparison_Exp>;
  AddressTrans?: Maybe<String_Comparison_Exp>;
  AreaPay?: Maybe<String_Comparison_Exp>;
  AreaTrans?: Maybe<String_Comparison_Exp>;
  CustomernameCons?: Maybe<String_Comparison_Exp>;
  CustomernamePay?: Maybe<String_Comparison_Exp>;
  CustomernameTrans?: Maybe<String_Comparison_Exp>;
  Customerphoneno1Cons?: Maybe<String_Comparison_Exp>;
  Customerphoneno1Pay?: Maybe<String_Comparison_Exp>;
  Customerphoneno1Trans?: Maybe<String_Comparison_Exp>;
  Customerphoneno2Cons?: Maybe<String_Comparison_Exp>;
  Customerphoneno2Pay?: Maybe<String_Comparison_Exp>;
  Customerphoneno2Trans?: Maybe<String_Comparison_Exp>;
  Customerphoneno3Pay?: Maybe<String_Comparison_Exp>;
  Customerreference?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  LatitudeCons?: Maybe<String_Comparison_Exp>;
  LatitudePay?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  LongitudeCons?: Maybe<String_Comparison_Exp>;
  LongitudePay?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Nwsc_Customers_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Nwsc_Customers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Nwsc_Customers_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.nwsc_Customers" */
export type Rtcs_Db_Nwsc_Customers_Inc_Input = {
  Customerreference?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.nwsc_Customers" */
export type Rtcs_Db_Nwsc_Customers_Insert_Input = {
  AddressCons?: Maybe<Scalars["String"]>;
  AddressPay?: Maybe<Scalars["String"]>;
  AddressTrans?: Maybe<Scalars["String"]>;
  AreaPay?: Maybe<Scalars["String"]>;
  AreaTrans?: Maybe<Scalars["String"]>;
  CustomernameCons?: Maybe<Scalars["String"]>;
  CustomernamePay?: Maybe<Scalars["String"]>;
  CustomernameTrans?: Maybe<Scalars["String"]>;
  Customerphoneno1Cons?: Maybe<Scalars["String"]>;
  Customerphoneno1Pay?: Maybe<Scalars["String"]>;
  Customerphoneno1Trans?: Maybe<Scalars["String"]>;
  Customerphoneno2Cons?: Maybe<Scalars["String"]>;
  Customerphoneno2Pay?: Maybe<Scalars["String"]>;
  Customerphoneno2Trans?: Maybe<Scalars["String"]>;
  Customerphoneno3Pay?: Maybe<Scalars["String"]>;
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LatitudeCons?: Maybe<Scalars["String"]>;
  LatitudePay?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LongitudeCons?: Maybe<Scalars["String"]>;
  LongitudePay?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Nwsc_Customers_Max_Fields = {
  __typename?: "rtcs_db_nwsc_Customers_max_fields";
  AddressCons?: Maybe<Scalars["String"]>;
  AddressPay?: Maybe<Scalars["String"]>;
  AddressTrans?: Maybe<Scalars["String"]>;
  AreaPay?: Maybe<Scalars["String"]>;
  AreaTrans?: Maybe<Scalars["String"]>;
  CustomernameCons?: Maybe<Scalars["String"]>;
  CustomernamePay?: Maybe<Scalars["String"]>;
  CustomernameTrans?: Maybe<Scalars["String"]>;
  Customerphoneno1Cons?: Maybe<Scalars["String"]>;
  Customerphoneno1Pay?: Maybe<Scalars["String"]>;
  Customerphoneno1Trans?: Maybe<Scalars["String"]>;
  Customerphoneno2Cons?: Maybe<Scalars["String"]>;
  Customerphoneno2Pay?: Maybe<Scalars["String"]>;
  Customerphoneno2Trans?: Maybe<Scalars["String"]>;
  Customerphoneno3Pay?: Maybe<Scalars["String"]>;
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LatitudeCons?: Maybe<Scalars["String"]>;
  LatitudePay?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LongitudeCons?: Maybe<Scalars["String"]>;
  LongitudePay?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.nwsc_Customers" */
export type Rtcs_Db_Nwsc_Customers_Max_Order_By = {
  AddressCons?: Maybe<Order_By>;
  AddressPay?: Maybe<Order_By>;
  AddressTrans?: Maybe<Order_By>;
  AreaPay?: Maybe<Order_By>;
  AreaTrans?: Maybe<Order_By>;
  CustomernameCons?: Maybe<Order_By>;
  CustomernamePay?: Maybe<Order_By>;
  CustomernameTrans?: Maybe<Order_By>;
  Customerphoneno1Cons?: Maybe<Order_By>;
  Customerphoneno1Pay?: Maybe<Order_By>;
  Customerphoneno1Trans?: Maybe<Order_By>;
  Customerphoneno2Cons?: Maybe<Order_By>;
  Customerphoneno2Pay?: Maybe<Order_By>;
  Customerphoneno2Trans?: Maybe<Order_By>;
  Customerphoneno3Pay?: Maybe<Order_By>;
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LatitudeCons?: Maybe<Order_By>;
  LatitudePay?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeCons?: Maybe<Order_By>;
  LongitudePay?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Nwsc_Customers_Min_Fields = {
  __typename?: "rtcs_db_nwsc_Customers_min_fields";
  AddressCons?: Maybe<Scalars["String"]>;
  AddressPay?: Maybe<Scalars["String"]>;
  AddressTrans?: Maybe<Scalars["String"]>;
  AreaPay?: Maybe<Scalars["String"]>;
  AreaTrans?: Maybe<Scalars["String"]>;
  CustomernameCons?: Maybe<Scalars["String"]>;
  CustomernamePay?: Maybe<Scalars["String"]>;
  CustomernameTrans?: Maybe<Scalars["String"]>;
  Customerphoneno1Cons?: Maybe<Scalars["String"]>;
  Customerphoneno1Pay?: Maybe<Scalars["String"]>;
  Customerphoneno1Trans?: Maybe<Scalars["String"]>;
  Customerphoneno2Cons?: Maybe<Scalars["String"]>;
  Customerphoneno2Pay?: Maybe<Scalars["String"]>;
  Customerphoneno2Trans?: Maybe<Scalars["String"]>;
  Customerphoneno3Pay?: Maybe<Scalars["String"]>;
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LatitudeCons?: Maybe<Scalars["String"]>;
  LatitudePay?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LongitudeCons?: Maybe<Scalars["String"]>;
  LongitudePay?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.nwsc_Customers" */
export type Rtcs_Db_Nwsc_Customers_Min_Order_By = {
  AddressCons?: Maybe<Order_By>;
  AddressPay?: Maybe<Order_By>;
  AddressTrans?: Maybe<Order_By>;
  AreaPay?: Maybe<Order_By>;
  AreaTrans?: Maybe<Order_By>;
  CustomernameCons?: Maybe<Order_By>;
  CustomernamePay?: Maybe<Order_By>;
  CustomernameTrans?: Maybe<Order_By>;
  Customerphoneno1Cons?: Maybe<Order_By>;
  Customerphoneno1Pay?: Maybe<Order_By>;
  Customerphoneno1Trans?: Maybe<Order_By>;
  Customerphoneno2Cons?: Maybe<Order_By>;
  Customerphoneno2Pay?: Maybe<Order_By>;
  Customerphoneno2Trans?: Maybe<Order_By>;
  Customerphoneno3Pay?: Maybe<Order_By>;
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LatitudeCons?: Maybe<Order_By>;
  LatitudePay?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeCons?: Maybe<Order_By>;
  LongitudePay?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.nwsc_Customers" */
export type Rtcs_Db_Nwsc_Customers_Mutation_Response = {
  __typename?: "rtcs_db_nwsc_Customers_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Nwsc_Customers>;
};

/** input type for inserting object relation for remote table "rtcs_db.nwsc_Customers" */
export type Rtcs_Db_Nwsc_Customers_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Nwsc_Customers_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.nwsc_Customers" */
export type Rtcs_Db_Nwsc_Customers_Order_By = {
  AddressCons?: Maybe<Order_By>;
  AddressPay?: Maybe<Order_By>;
  AddressTrans?: Maybe<Order_By>;
  AreaPay?: Maybe<Order_By>;
  AreaTrans?: Maybe<Order_By>;
  CustomernameCons?: Maybe<Order_By>;
  CustomernamePay?: Maybe<Order_By>;
  CustomernameTrans?: Maybe<Order_By>;
  Customerphoneno1Cons?: Maybe<Order_By>;
  Customerphoneno1Pay?: Maybe<Order_By>;
  Customerphoneno1Trans?: Maybe<Order_By>;
  Customerphoneno2Cons?: Maybe<Order_By>;
  Customerphoneno2Pay?: Maybe<Order_By>;
  Customerphoneno2Trans?: Maybe<Order_By>;
  Customerphoneno3Pay?: Maybe<Order_By>;
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LatitudeCons?: Maybe<Order_By>;
  LatitudePay?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeCons?: Maybe<Order_By>;
  LongitudePay?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.nwsc_Customers" */
export enum Rtcs_Db_Nwsc_Customers_Select_Column {
  /** column name */
  AddressCons = "AddressCons",
  /** column name */
  AddressPay = "AddressPay",
  /** column name */
  AddressTrans = "AddressTrans",
  /** column name */
  AreaPay = "AreaPay",
  /** column name */
  AreaTrans = "AreaTrans",
  /** column name */
  CustomernameCons = "CustomernameCons",
  /** column name */
  CustomernamePay = "CustomernamePay",
  /** column name */
  CustomernameTrans = "CustomernameTrans",
  /** column name */
  Customerphoneno1Cons = "Customerphoneno1Cons",
  /** column name */
  Customerphoneno1Pay = "Customerphoneno1Pay",
  /** column name */
  Customerphoneno1Trans = "Customerphoneno1Trans",
  /** column name */
  Customerphoneno2Cons = "Customerphoneno2Cons",
  /** column name */
  Customerphoneno2Pay = "Customerphoneno2Pay",
  /** column name */
  Customerphoneno2Trans = "Customerphoneno2Trans",
  /** column name */
  Customerphoneno3Pay = "Customerphoneno3Pay",
  /** column name */
  Customerreference = "Customerreference",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  LatitudeCons = "LatitudeCons",
  /** column name */
  LatitudePay = "LatitudePay",
  /** column name */
  Line = "Line",
  /** column name */
  LongitudeCons = "LongitudeCons",
  /** column name */
  LongitudePay = "LongitudePay",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.nwsc_Customers" */
export type Rtcs_Db_Nwsc_Customers_Set_Input = {
  AddressCons?: Maybe<Scalars["String"]>;
  AddressPay?: Maybe<Scalars["String"]>;
  AddressTrans?: Maybe<Scalars["String"]>;
  AreaPay?: Maybe<Scalars["String"]>;
  AreaTrans?: Maybe<Scalars["String"]>;
  CustomernameCons?: Maybe<Scalars["String"]>;
  CustomernamePay?: Maybe<Scalars["String"]>;
  CustomernameTrans?: Maybe<Scalars["String"]>;
  Customerphoneno1Cons?: Maybe<Scalars["String"]>;
  Customerphoneno1Pay?: Maybe<Scalars["String"]>;
  Customerphoneno1Trans?: Maybe<Scalars["String"]>;
  Customerphoneno2Cons?: Maybe<Scalars["String"]>;
  Customerphoneno2Pay?: Maybe<Scalars["String"]>;
  Customerphoneno2Trans?: Maybe<Scalars["String"]>;
  Customerphoneno3Pay?: Maybe<Scalars["String"]>;
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LatitudeCons?: Maybe<Scalars["String"]>;
  LatitudePay?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LongitudeCons?: Maybe<Scalars["String"]>;
  LongitudePay?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Nwsc_Customers_Stddev_Fields = {
  __typename?: "rtcs_db_nwsc_Customers_stddev_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.nwsc_Customers" */
export type Rtcs_Db_Nwsc_Customers_Stddev_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Nwsc_Customers_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_nwsc_Customers_stddev_pop_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.nwsc_Customers" */
export type Rtcs_Db_Nwsc_Customers_Stddev_Pop_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Nwsc_Customers_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_nwsc_Customers_stddev_samp_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.nwsc_Customers" */
export type Rtcs_Db_Nwsc_Customers_Stddev_Samp_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Nwsc_Customers_Sum_Fields = {
  __typename?: "rtcs_db_nwsc_Customers_sum_fields";
  Customerreference?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.nwsc_Customers" */
export type Rtcs_Db_Nwsc_Customers_Sum_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Nwsc_Customers_Var_Pop_Fields = {
  __typename?: "rtcs_db_nwsc_Customers_var_pop_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.nwsc_Customers" */
export type Rtcs_Db_Nwsc_Customers_Var_Pop_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Nwsc_Customers_Var_Samp_Fields = {
  __typename?: "rtcs_db_nwsc_Customers_var_samp_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.nwsc_Customers" */
export type Rtcs_Db_Nwsc_Customers_Var_Samp_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Nwsc_Customers_Variance_Fields = {
  __typename?: "rtcs_db_nwsc_Customers_variance_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.nwsc_Customers" */
export type Rtcs_Db_Nwsc_Customers_Variance_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.nwsc_NWCustomers" */
export type Rtcs_Db_Nwsc_NwCustomers = {
  __typename?: "rtcs_db_nwsc_NWCustomers";
  AddressCons?: Maybe<Scalars["String"]>;
  AddressPay?: Maybe<Scalars["String"]>;
  AddressTrans?: Maybe<Scalars["String"]>;
  AreaPay?: Maybe<Scalars["String"]>;
  AreaTrans?: Maybe<Scalars["String"]>;
  CustomernameCons?: Maybe<Scalars["String"]>;
  CustomernamePay?: Maybe<Scalars["String"]>;
  CustomernameTrans?: Maybe<Scalars["String"]>;
  Customerphoneno1Cons?: Maybe<Scalars["String"]>;
  Customerphoneno1Pay?: Maybe<Scalars["String"]>;
  Customerphoneno1Trans?: Maybe<Scalars["String"]>;
  Customerphoneno2Cons?: Maybe<Scalars["String"]>;
  Customerphoneno2Pay?: Maybe<Scalars["String"]>;
  Customerphoneno2Trans?: Maybe<Scalars["String"]>;
  Customerphoneno3Pay?: Maybe<Scalars["String"]>;
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LatitudeCons?: Maybe<Scalars["String"]>;
  LatitudePay?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LongitudeCons?: Maybe<Scalars["String"]>;
  LongitudePay?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.nwsc_NWCustomers" */
export type Rtcs_Db_Nwsc_NwCustomers_Aggregate = {
  __typename?: "rtcs_db_nwsc_NWCustomers_aggregate";
  aggregate?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Nwsc_NwCustomers>;
};

/** aggregate fields of "rtcs_db.nwsc_NWCustomers" */
export type Rtcs_Db_Nwsc_NwCustomers_Aggregate_Fields = {
  __typename?: "rtcs_db_nwsc_NWCustomers_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Max_Fields>;
  min?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.nwsc_NWCustomers" */
export type Rtcs_Db_Nwsc_NwCustomers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Nwsc_NwCustomers_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.nwsc_NWCustomers" */
export type Rtcs_Db_Nwsc_NwCustomers_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.nwsc_NWCustomers" */
export type Rtcs_Db_Nwsc_NwCustomers_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Nwsc_NwCustomers_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Nwsc_NwCustomers_Avg_Fields = {
  __typename?: "rtcs_db_nwsc_NWCustomers_avg_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.nwsc_NWCustomers" */
export type Rtcs_Db_Nwsc_NwCustomers_Avg_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.nwsc_NWCustomers". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Nwsc_NwCustomers_Bool_Exp = {
  AddressCons?: Maybe<String_Comparison_Exp>;
  AddressPay?: Maybe<String_Comparison_Exp>;
  AddressTrans?: Maybe<String_Comparison_Exp>;
  AreaPay?: Maybe<String_Comparison_Exp>;
  AreaTrans?: Maybe<String_Comparison_Exp>;
  CustomernameCons?: Maybe<String_Comparison_Exp>;
  CustomernamePay?: Maybe<String_Comparison_Exp>;
  CustomernameTrans?: Maybe<String_Comparison_Exp>;
  Customerphoneno1Cons?: Maybe<String_Comparison_Exp>;
  Customerphoneno1Pay?: Maybe<String_Comparison_Exp>;
  Customerphoneno1Trans?: Maybe<String_Comparison_Exp>;
  Customerphoneno2Cons?: Maybe<String_Comparison_Exp>;
  Customerphoneno2Pay?: Maybe<String_Comparison_Exp>;
  Customerphoneno2Trans?: Maybe<String_Comparison_Exp>;
  Customerphoneno3Pay?: Maybe<String_Comparison_Exp>;
  Customerreference?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  LatitudeCons?: Maybe<String_Comparison_Exp>;
  LatitudePay?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  LongitudeCons?: Maybe<String_Comparison_Exp>;
  LongitudePay?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Nwsc_NwCustomers_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Nwsc_NwCustomers_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.nwsc_NWCustomers" */
export type Rtcs_Db_Nwsc_NwCustomers_Inc_Input = {
  Customerreference?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.nwsc_NWCustomers" */
export type Rtcs_Db_Nwsc_NwCustomers_Insert_Input = {
  AddressCons?: Maybe<Scalars["String"]>;
  AddressPay?: Maybe<Scalars["String"]>;
  AddressTrans?: Maybe<Scalars["String"]>;
  AreaPay?: Maybe<Scalars["String"]>;
  AreaTrans?: Maybe<Scalars["String"]>;
  CustomernameCons?: Maybe<Scalars["String"]>;
  CustomernamePay?: Maybe<Scalars["String"]>;
  CustomernameTrans?: Maybe<Scalars["String"]>;
  Customerphoneno1Cons?: Maybe<Scalars["String"]>;
  Customerphoneno1Pay?: Maybe<Scalars["String"]>;
  Customerphoneno1Trans?: Maybe<Scalars["String"]>;
  Customerphoneno2Cons?: Maybe<Scalars["String"]>;
  Customerphoneno2Pay?: Maybe<Scalars["String"]>;
  Customerphoneno2Trans?: Maybe<Scalars["String"]>;
  Customerphoneno3Pay?: Maybe<Scalars["String"]>;
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LatitudeCons?: Maybe<Scalars["String"]>;
  LatitudePay?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LongitudeCons?: Maybe<Scalars["String"]>;
  LongitudePay?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Nwsc_NwCustomers_Max_Fields = {
  __typename?: "rtcs_db_nwsc_NWCustomers_max_fields";
  AddressCons?: Maybe<Scalars["String"]>;
  AddressPay?: Maybe<Scalars["String"]>;
  AddressTrans?: Maybe<Scalars["String"]>;
  AreaPay?: Maybe<Scalars["String"]>;
  AreaTrans?: Maybe<Scalars["String"]>;
  CustomernameCons?: Maybe<Scalars["String"]>;
  CustomernamePay?: Maybe<Scalars["String"]>;
  CustomernameTrans?: Maybe<Scalars["String"]>;
  Customerphoneno1Cons?: Maybe<Scalars["String"]>;
  Customerphoneno1Pay?: Maybe<Scalars["String"]>;
  Customerphoneno1Trans?: Maybe<Scalars["String"]>;
  Customerphoneno2Cons?: Maybe<Scalars["String"]>;
  Customerphoneno2Pay?: Maybe<Scalars["String"]>;
  Customerphoneno2Trans?: Maybe<Scalars["String"]>;
  Customerphoneno3Pay?: Maybe<Scalars["String"]>;
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LatitudeCons?: Maybe<Scalars["String"]>;
  LatitudePay?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LongitudeCons?: Maybe<Scalars["String"]>;
  LongitudePay?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.nwsc_NWCustomers" */
export type Rtcs_Db_Nwsc_NwCustomers_Max_Order_By = {
  AddressCons?: Maybe<Order_By>;
  AddressPay?: Maybe<Order_By>;
  AddressTrans?: Maybe<Order_By>;
  AreaPay?: Maybe<Order_By>;
  AreaTrans?: Maybe<Order_By>;
  CustomernameCons?: Maybe<Order_By>;
  CustomernamePay?: Maybe<Order_By>;
  CustomernameTrans?: Maybe<Order_By>;
  Customerphoneno1Cons?: Maybe<Order_By>;
  Customerphoneno1Pay?: Maybe<Order_By>;
  Customerphoneno1Trans?: Maybe<Order_By>;
  Customerphoneno2Cons?: Maybe<Order_By>;
  Customerphoneno2Pay?: Maybe<Order_By>;
  Customerphoneno2Trans?: Maybe<Order_By>;
  Customerphoneno3Pay?: Maybe<Order_By>;
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LatitudeCons?: Maybe<Order_By>;
  LatitudePay?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeCons?: Maybe<Order_By>;
  LongitudePay?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Nwsc_NwCustomers_Min_Fields = {
  __typename?: "rtcs_db_nwsc_NWCustomers_min_fields";
  AddressCons?: Maybe<Scalars["String"]>;
  AddressPay?: Maybe<Scalars["String"]>;
  AddressTrans?: Maybe<Scalars["String"]>;
  AreaPay?: Maybe<Scalars["String"]>;
  AreaTrans?: Maybe<Scalars["String"]>;
  CustomernameCons?: Maybe<Scalars["String"]>;
  CustomernamePay?: Maybe<Scalars["String"]>;
  CustomernameTrans?: Maybe<Scalars["String"]>;
  Customerphoneno1Cons?: Maybe<Scalars["String"]>;
  Customerphoneno1Pay?: Maybe<Scalars["String"]>;
  Customerphoneno1Trans?: Maybe<Scalars["String"]>;
  Customerphoneno2Cons?: Maybe<Scalars["String"]>;
  Customerphoneno2Pay?: Maybe<Scalars["String"]>;
  Customerphoneno2Trans?: Maybe<Scalars["String"]>;
  Customerphoneno3Pay?: Maybe<Scalars["String"]>;
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LatitudeCons?: Maybe<Scalars["String"]>;
  LatitudePay?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LongitudeCons?: Maybe<Scalars["String"]>;
  LongitudePay?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.nwsc_NWCustomers" */
export type Rtcs_Db_Nwsc_NwCustomers_Min_Order_By = {
  AddressCons?: Maybe<Order_By>;
  AddressPay?: Maybe<Order_By>;
  AddressTrans?: Maybe<Order_By>;
  AreaPay?: Maybe<Order_By>;
  AreaTrans?: Maybe<Order_By>;
  CustomernameCons?: Maybe<Order_By>;
  CustomernamePay?: Maybe<Order_By>;
  CustomernameTrans?: Maybe<Order_By>;
  Customerphoneno1Cons?: Maybe<Order_By>;
  Customerphoneno1Pay?: Maybe<Order_By>;
  Customerphoneno1Trans?: Maybe<Order_By>;
  Customerphoneno2Cons?: Maybe<Order_By>;
  Customerphoneno2Pay?: Maybe<Order_By>;
  Customerphoneno2Trans?: Maybe<Order_By>;
  Customerphoneno3Pay?: Maybe<Order_By>;
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LatitudeCons?: Maybe<Order_By>;
  LatitudePay?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeCons?: Maybe<Order_By>;
  LongitudePay?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.nwsc_NWCustomers" */
export type Rtcs_Db_Nwsc_NwCustomers_Mutation_Response = {
  __typename?: "rtcs_db_nwsc_NWCustomers_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Nwsc_NwCustomers>;
};

/** input type for inserting object relation for remote table "rtcs_db.nwsc_NWCustomers" */
export type Rtcs_Db_Nwsc_NwCustomers_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Nwsc_NwCustomers_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.nwsc_NWCustomers" */
export type Rtcs_Db_Nwsc_NwCustomers_Order_By = {
  AddressCons?: Maybe<Order_By>;
  AddressPay?: Maybe<Order_By>;
  AddressTrans?: Maybe<Order_By>;
  AreaPay?: Maybe<Order_By>;
  AreaTrans?: Maybe<Order_By>;
  CustomernameCons?: Maybe<Order_By>;
  CustomernamePay?: Maybe<Order_By>;
  CustomernameTrans?: Maybe<Order_By>;
  Customerphoneno1Cons?: Maybe<Order_By>;
  Customerphoneno1Pay?: Maybe<Order_By>;
  Customerphoneno1Trans?: Maybe<Order_By>;
  Customerphoneno2Cons?: Maybe<Order_By>;
  Customerphoneno2Pay?: Maybe<Order_By>;
  Customerphoneno2Trans?: Maybe<Order_By>;
  Customerphoneno3Pay?: Maybe<Order_By>;
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LatitudeCons?: Maybe<Order_By>;
  LatitudePay?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LongitudeCons?: Maybe<Order_By>;
  LongitudePay?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.nwsc_NWCustomers" */
export enum Rtcs_Db_Nwsc_NwCustomers_Select_Column {
  /** column name */
  AddressCons = "AddressCons",
  /** column name */
  AddressPay = "AddressPay",
  /** column name */
  AddressTrans = "AddressTrans",
  /** column name */
  AreaPay = "AreaPay",
  /** column name */
  AreaTrans = "AreaTrans",
  /** column name */
  CustomernameCons = "CustomernameCons",
  /** column name */
  CustomernamePay = "CustomernamePay",
  /** column name */
  CustomernameTrans = "CustomernameTrans",
  /** column name */
  Customerphoneno1Cons = "Customerphoneno1Cons",
  /** column name */
  Customerphoneno1Pay = "Customerphoneno1Pay",
  /** column name */
  Customerphoneno1Trans = "Customerphoneno1Trans",
  /** column name */
  Customerphoneno2Cons = "Customerphoneno2Cons",
  /** column name */
  Customerphoneno2Pay = "Customerphoneno2Pay",
  /** column name */
  Customerphoneno2Trans = "Customerphoneno2Trans",
  /** column name */
  Customerphoneno3Pay = "Customerphoneno3Pay",
  /** column name */
  Customerreference = "Customerreference",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  LatitudeCons = "LatitudeCons",
  /** column name */
  LatitudePay = "LatitudePay",
  /** column name */
  Line = "Line",
  /** column name */
  LongitudeCons = "LongitudeCons",
  /** column name */
  LongitudePay = "LongitudePay",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.nwsc_NWCustomers" */
export type Rtcs_Db_Nwsc_NwCustomers_Set_Input = {
  AddressCons?: Maybe<Scalars["String"]>;
  AddressPay?: Maybe<Scalars["String"]>;
  AddressTrans?: Maybe<Scalars["String"]>;
  AreaPay?: Maybe<Scalars["String"]>;
  AreaTrans?: Maybe<Scalars["String"]>;
  CustomernameCons?: Maybe<Scalars["String"]>;
  CustomernamePay?: Maybe<Scalars["String"]>;
  CustomernameTrans?: Maybe<Scalars["String"]>;
  Customerphoneno1Cons?: Maybe<Scalars["String"]>;
  Customerphoneno1Pay?: Maybe<Scalars["String"]>;
  Customerphoneno1Trans?: Maybe<Scalars["String"]>;
  Customerphoneno2Cons?: Maybe<Scalars["String"]>;
  Customerphoneno2Pay?: Maybe<Scalars["String"]>;
  Customerphoneno2Trans?: Maybe<Scalars["String"]>;
  Customerphoneno3Pay?: Maybe<Scalars["String"]>;
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LatitudeCons?: Maybe<Scalars["String"]>;
  LatitudePay?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LongitudeCons?: Maybe<Scalars["String"]>;
  LongitudePay?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Nwsc_NwCustomers_Stddev_Fields = {
  __typename?: "rtcs_db_nwsc_NWCustomers_stddev_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.nwsc_NWCustomers" */
export type Rtcs_Db_Nwsc_NwCustomers_Stddev_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Nwsc_NwCustomers_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_nwsc_NWCustomers_stddev_pop_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.nwsc_NWCustomers" */
export type Rtcs_Db_Nwsc_NwCustomers_Stddev_Pop_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Nwsc_NwCustomers_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_nwsc_NWCustomers_stddev_samp_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.nwsc_NWCustomers" */
export type Rtcs_Db_Nwsc_NwCustomers_Stddev_Samp_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Nwsc_NwCustomers_Sum_Fields = {
  __typename?: "rtcs_db_nwsc_NWCustomers_sum_fields";
  Customerreference?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.nwsc_NWCustomers" */
export type Rtcs_Db_Nwsc_NwCustomers_Sum_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Nwsc_NwCustomers_Var_Pop_Fields = {
  __typename?: "rtcs_db_nwsc_NWCustomers_var_pop_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.nwsc_NWCustomers" */
export type Rtcs_Db_Nwsc_NwCustomers_Var_Pop_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Nwsc_NwCustomers_Var_Samp_Fields = {
  __typename?: "rtcs_db_nwsc_NWCustomers_var_samp_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.nwsc_NWCustomers" */
export type Rtcs_Db_Nwsc_NwCustomers_Var_Samp_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Nwsc_NwCustomers_Variance_Fields = {
  __typename?: "rtcs_db_nwsc_NWCustomers_variance_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.nwsc_NWCustomers" */
export type Rtcs_Db_Nwsc_NwCustomers_Variance_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.nwsc_RPT_Transactions" */
export type Rtcs_Db_Nwsc_Rpt_Transactions = {
  __typename?: "rtcs_db_nwsc_RPT_Transactions";
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Period?: Maybe<Scalars["timestamptz"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.nwsc_RPT_Transactions" */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Aggregate = {
  __typename?: "rtcs_db_nwsc_RPT_Transactions_aggregate";
  aggregate?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Nwsc_Rpt_Transactions>;
};

/** aggregate fields of "rtcs_db.nwsc_RPT_Transactions" */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Aggregate_Fields = {
  __typename?: "rtcs_db_nwsc_RPT_Transactions_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Max_Fields>;
  min?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.nwsc_RPT_Transactions" */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Nwsc_Rpt_Transactions_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.nwsc_RPT_Transactions" */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.nwsc_RPT_Transactions" */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Nwsc_Rpt_Transactions_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Avg_Fields = {
  __typename?: "rtcs_db_nwsc_RPT_Transactions_avg_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.nwsc_RPT_Transactions" */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Avg_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.nwsc_RPT_Transactions". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Bool_Exp = {
  Customerreference?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Period?: Maybe<Timestamptz_Comparison_Exp>;
  Type?: Maybe<String_Comparison_Exp>;
  Value?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.nwsc_RPT_Transactions" */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Inc_Input = {
  Customerreference?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Value?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.nwsc_RPT_Transactions" */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Insert_Input = {
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Period?: Maybe<Scalars["timestamptz"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Max_Fields = {
  __typename?: "rtcs_db_nwsc_RPT_Transactions_max_fields";
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Period?: Maybe<Scalars["timestamptz"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.nwsc_RPT_Transactions" */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Max_Order_By = {
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Period?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Min_Fields = {
  __typename?: "rtcs_db_nwsc_RPT_Transactions_min_fields";
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Period?: Maybe<Scalars["timestamptz"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.nwsc_RPT_Transactions" */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Min_Order_By = {
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Period?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.nwsc_RPT_Transactions" */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Mutation_Response = {
  __typename?: "rtcs_db_nwsc_RPT_Transactions_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Nwsc_Rpt_Transactions>;
};

/** input type for inserting object relation for remote table "rtcs_db.nwsc_RPT_Transactions" */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Nwsc_Rpt_Transactions_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.nwsc_RPT_Transactions" */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Order_By = {
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Period?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.nwsc_RPT_Transactions" */
export enum Rtcs_Db_Nwsc_Rpt_Transactions_Select_Column {
  /** column name */
  Customerreference = "Customerreference",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Period = "Period",
  /** column name */
  Type = "Type",
  /** column name */
  Value = "Value",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.nwsc_RPT_Transactions" */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Set_Input = {
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Period?: Maybe<Scalars["timestamptz"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Stddev_Fields = {
  __typename?: "rtcs_db_nwsc_RPT_Transactions_stddev_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.nwsc_RPT_Transactions" */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Stddev_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_nwsc_RPT_Transactions_stddev_pop_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.nwsc_RPT_Transactions" */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Stddev_Pop_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_nwsc_RPT_Transactions_stddev_samp_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.nwsc_RPT_Transactions" */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Stddev_Samp_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Sum_Fields = {
  __typename?: "rtcs_db_nwsc_RPT_Transactions_sum_fields";
  Customerreference?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Value?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.nwsc_RPT_Transactions" */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Sum_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Var_Pop_Fields = {
  __typename?: "rtcs_db_nwsc_RPT_Transactions_var_pop_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.nwsc_RPT_Transactions" */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Var_Pop_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Var_Samp_Fields = {
  __typename?: "rtcs_db_nwsc_RPT_Transactions_var_samp_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.nwsc_RPT_Transactions" */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Var_Samp_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Variance_Fields = {
  __typename?: "rtcs_db_nwsc_RPT_Transactions_variance_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.nwsc_RPT_Transactions" */
export type Rtcs_Db_Nwsc_Rpt_Transactions_Variance_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.nwsc_Transactions" */
export type Rtcs_Db_Nwsc_Transactions = {
  __typename?: "rtcs_db_nwsc_Transactions";
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Period?: Maybe<Scalars["timestamptz"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.nwsc_Transactions" */
export type Rtcs_Db_Nwsc_Transactions_Aggregate = {
  __typename?: "rtcs_db_nwsc_Transactions_aggregate";
  aggregate?: Maybe<Rtcs_Db_Nwsc_Transactions_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Nwsc_Transactions>;
};

/** aggregate fields of "rtcs_db.nwsc_Transactions" */
export type Rtcs_Db_Nwsc_Transactions_Aggregate_Fields = {
  __typename?: "rtcs_db_nwsc_Transactions_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Nwsc_Transactions_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Nwsc_Transactions_Max_Fields>;
  min?: Maybe<Rtcs_Db_Nwsc_Transactions_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Nwsc_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Nwsc_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Nwsc_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Nwsc_Transactions_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Nwsc_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Nwsc_Transactions_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Nwsc_Transactions_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.nwsc_Transactions" */
export type Rtcs_Db_Nwsc_Transactions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Nwsc_Transactions_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.nwsc_Transactions" */
export type Rtcs_Db_Nwsc_Transactions_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Nwsc_Transactions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Nwsc_Transactions_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Nwsc_Transactions_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Nwsc_Transactions_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Nwsc_Transactions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Nwsc_Transactions_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Nwsc_Transactions_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Nwsc_Transactions_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Nwsc_Transactions_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Nwsc_Transactions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.nwsc_Transactions" */
export type Rtcs_Db_Nwsc_Transactions_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Nwsc_Transactions_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Nwsc_Transactions_Avg_Fields = {
  __typename?: "rtcs_db_nwsc_Transactions_avg_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.nwsc_Transactions" */
export type Rtcs_Db_Nwsc_Transactions_Avg_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.nwsc_Transactions". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Nwsc_Transactions_Bool_Exp = {
  Customerreference?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Period?: Maybe<Timestamptz_Comparison_Exp>;
  Type?: Maybe<String_Comparison_Exp>;
  Value?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Nwsc_Transactions_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Nwsc_Transactions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Nwsc_Transactions_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.nwsc_Transactions" */
export type Rtcs_Db_Nwsc_Transactions_Inc_Input = {
  Customerreference?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Value?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.nwsc_Transactions" */
export type Rtcs_Db_Nwsc_Transactions_Insert_Input = {
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Period?: Maybe<Scalars["timestamptz"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Nwsc_Transactions_Max_Fields = {
  __typename?: "rtcs_db_nwsc_Transactions_max_fields";
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Period?: Maybe<Scalars["timestamptz"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.nwsc_Transactions" */
export type Rtcs_Db_Nwsc_Transactions_Max_Order_By = {
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Period?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Nwsc_Transactions_Min_Fields = {
  __typename?: "rtcs_db_nwsc_Transactions_min_fields";
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Period?: Maybe<Scalars["timestamptz"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.nwsc_Transactions" */
export type Rtcs_Db_Nwsc_Transactions_Min_Order_By = {
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Period?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.nwsc_Transactions" */
export type Rtcs_Db_Nwsc_Transactions_Mutation_Response = {
  __typename?: "rtcs_db_nwsc_Transactions_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Nwsc_Transactions>;
};

/** input type for inserting object relation for remote table "rtcs_db.nwsc_Transactions" */
export type Rtcs_Db_Nwsc_Transactions_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Nwsc_Transactions_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.nwsc_Transactions" */
export type Rtcs_Db_Nwsc_Transactions_Order_By = {
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Period?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.nwsc_Transactions" */
export enum Rtcs_Db_Nwsc_Transactions_Select_Column {
  /** column name */
  Customerreference = "Customerreference",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Period = "Period",
  /** column name */
  Type = "Type",
  /** column name */
  Value = "Value",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.nwsc_Transactions" */
export type Rtcs_Db_Nwsc_Transactions_Set_Input = {
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Period?: Maybe<Scalars["timestamptz"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Nwsc_Transactions_Stddev_Fields = {
  __typename?: "rtcs_db_nwsc_Transactions_stddev_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.nwsc_Transactions" */
export type Rtcs_Db_Nwsc_Transactions_Stddev_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Nwsc_Transactions_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_nwsc_Transactions_stddev_pop_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.nwsc_Transactions" */
export type Rtcs_Db_Nwsc_Transactions_Stddev_Pop_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Nwsc_Transactions_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_nwsc_Transactions_stddev_samp_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.nwsc_Transactions" */
export type Rtcs_Db_Nwsc_Transactions_Stddev_Samp_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Nwsc_Transactions_Sum_Fields = {
  __typename?: "rtcs_db_nwsc_Transactions_sum_fields";
  Customerreference?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Value?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.nwsc_Transactions" */
export type Rtcs_Db_Nwsc_Transactions_Sum_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Nwsc_Transactions_Var_Pop_Fields = {
  __typename?: "rtcs_db_nwsc_Transactions_var_pop_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.nwsc_Transactions" */
export type Rtcs_Db_Nwsc_Transactions_Var_Pop_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Nwsc_Transactions_Var_Samp_Fields = {
  __typename?: "rtcs_db_nwsc_Transactions_var_samp_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.nwsc_Transactions" */
export type Rtcs_Db_Nwsc_Transactions_Var_Samp_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Nwsc_Transactions_Variance_Fields = {
  __typename?: "rtcs_db_nwsc_Transactions_variance_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Value?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.nwsc_Transactions" */
export type Rtcs_Db_Nwsc_Transactions_Variance_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.nwsc_URATransactions" */
export type Rtcs_Db_Nwsc_UraTransactions = {
  __typename?: "rtcs_db_nwsc_URATransactions";
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Month?: Maybe<Scalars["bigint"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Year?: Maybe<Scalars["bigint"]>;
};

/** aggregated selection of "rtcs_db.nwsc_URATransactions" */
export type Rtcs_Db_Nwsc_UraTransactions_Aggregate = {
  __typename?: "rtcs_db_nwsc_URATransactions_aggregate";
  aggregate?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Nwsc_UraTransactions>;
};

/** aggregate fields of "rtcs_db.nwsc_URATransactions" */
export type Rtcs_Db_Nwsc_UraTransactions_Aggregate_Fields = {
  __typename?: "rtcs_db_nwsc_URATransactions_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Max_Fields>;
  min?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.nwsc_URATransactions" */
export type Rtcs_Db_Nwsc_UraTransactions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Nwsc_UraTransactions_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.nwsc_URATransactions" */
export type Rtcs_Db_Nwsc_UraTransactions_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.nwsc_URATransactions" */
export type Rtcs_Db_Nwsc_UraTransactions_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Nwsc_UraTransactions_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Nwsc_UraTransactions_Avg_Fields = {
  __typename?: "rtcs_db_nwsc_URATransactions_avg_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Month?: Maybe<Scalars["Float"]>;
  Year?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.nwsc_URATransactions" */
export type Rtcs_Db_Nwsc_UraTransactions_Avg_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Month?: Maybe<Order_By>;
  Year?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.nwsc_URATransactions". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Nwsc_UraTransactions_Bool_Exp = {
  Customerreference?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamp_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Month?: Maybe<Bigint_Comparison_Exp>;
  Type?: Maybe<String_Comparison_Exp>;
  Value?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  Year?: Maybe<Bigint_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Nwsc_UraTransactions_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Nwsc_UraTransactions_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.nwsc_URATransactions" */
export type Rtcs_Db_Nwsc_UraTransactions_Inc_Input = {
  Customerreference?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Month?: Maybe<Scalars["bigint"]>;
  Year?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.nwsc_URATransactions" */
export type Rtcs_Db_Nwsc_UraTransactions_Insert_Input = {
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Month?: Maybe<Scalars["bigint"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Year?: Maybe<Scalars["bigint"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Nwsc_UraTransactions_Max_Fields = {
  __typename?: "rtcs_db_nwsc_URATransactions_max_fields";
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Month?: Maybe<Scalars["bigint"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Year?: Maybe<Scalars["bigint"]>;
};

/** order by max() on columns of table "rtcs_db.nwsc_URATransactions" */
export type Rtcs_Db_Nwsc_UraTransactions_Max_Order_By = {
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Month?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  Year?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Nwsc_UraTransactions_Min_Fields = {
  __typename?: "rtcs_db_nwsc_URATransactions_min_fields";
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Month?: Maybe<Scalars["bigint"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Year?: Maybe<Scalars["bigint"]>;
};

/** order by min() on columns of table "rtcs_db.nwsc_URATransactions" */
export type Rtcs_Db_Nwsc_UraTransactions_Min_Order_By = {
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Month?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  Year?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.nwsc_URATransactions" */
export type Rtcs_Db_Nwsc_UraTransactions_Mutation_Response = {
  __typename?: "rtcs_db_nwsc_URATransactions_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Nwsc_UraTransactions>;
};

/** input type for inserting object relation for remote table "rtcs_db.nwsc_URATransactions" */
export type Rtcs_Db_Nwsc_UraTransactions_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Nwsc_UraTransactions_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.nwsc_URATransactions" */
export type Rtcs_Db_Nwsc_UraTransactions_Order_By = {
  Customerreference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Month?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  Year?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.nwsc_URATransactions" */
export enum Rtcs_Db_Nwsc_UraTransactions_Select_Column {
  /** column name */
  Customerreference = "Customerreference",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Month = "Month",
  /** column name */
  Type = "Type",
  /** column name */
  Value = "Value",
  /** column name */
  WhoUpdated = "WhoUpdated",
  /** column name */
  Year = "Year",
}

/** input type for updating data in table "rtcs_db.nwsc_URATransactions" */
export type Rtcs_Db_Nwsc_UraTransactions_Set_Input = {
  Customerreference?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Month?: Maybe<Scalars["bigint"]>;
  Type?: Maybe<Scalars["String"]>;
  Value?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Year?: Maybe<Scalars["bigint"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Nwsc_UraTransactions_Stddev_Fields = {
  __typename?: "rtcs_db_nwsc_URATransactions_stddev_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Month?: Maybe<Scalars["Float"]>;
  Year?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.nwsc_URATransactions" */
export type Rtcs_Db_Nwsc_UraTransactions_Stddev_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Month?: Maybe<Order_By>;
  Year?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Nwsc_UraTransactions_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_nwsc_URATransactions_stddev_pop_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Month?: Maybe<Scalars["Float"]>;
  Year?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.nwsc_URATransactions" */
export type Rtcs_Db_Nwsc_UraTransactions_Stddev_Pop_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Month?: Maybe<Order_By>;
  Year?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Nwsc_UraTransactions_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_nwsc_URATransactions_stddev_samp_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Month?: Maybe<Scalars["Float"]>;
  Year?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.nwsc_URATransactions" */
export type Rtcs_Db_Nwsc_UraTransactions_Stddev_Samp_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Month?: Maybe<Order_By>;
  Year?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Nwsc_UraTransactions_Sum_Fields = {
  __typename?: "rtcs_db_nwsc_URATransactions_sum_fields";
  Customerreference?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Month?: Maybe<Scalars["bigint"]>;
  Year?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.nwsc_URATransactions" */
export type Rtcs_Db_Nwsc_UraTransactions_Sum_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Month?: Maybe<Order_By>;
  Year?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Nwsc_UraTransactions_Var_Pop_Fields = {
  __typename?: "rtcs_db_nwsc_URATransactions_var_pop_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Month?: Maybe<Scalars["Float"]>;
  Year?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.nwsc_URATransactions" */
export type Rtcs_Db_Nwsc_UraTransactions_Var_Pop_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Month?: Maybe<Order_By>;
  Year?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Nwsc_UraTransactions_Var_Samp_Fields = {
  __typename?: "rtcs_db_nwsc_URATransactions_var_samp_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Month?: Maybe<Scalars["Float"]>;
  Year?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.nwsc_URATransactions" */
export type Rtcs_Db_Nwsc_UraTransactions_Var_Samp_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Month?: Maybe<Order_By>;
  Year?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Nwsc_UraTransactions_Variance_Fields = {
  __typename?: "rtcs_db_nwsc_URATransactions_variance_fields";
  Customerreference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Month?: Maybe<Scalars["Float"]>;
  Year?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.nwsc_URATransactions" */
export type Rtcs_Db_Nwsc_UraTransactions_Variance_Order_By = {
  Customerreference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Month?: Maybe<Order_By>;
  Year?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rcapture_RPTUnittypes" */
export type Rtcs_Db_Rcapture_RptUnittypes = {
  __typename?: "rtcs_db_rcapture_RPTUnittypes";
  Createdby?: Maybe<Scalars["String"]>;
  Currencyadvertisedrent?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Numberunitstype?: Maybe<Scalars["bigint"]>;
  Pguid?: Maybe<Scalars["String"]>;
  Uguid?: Maybe<Scalars["String"]>;
  Unitfurnished?: Maybe<Scalars["String"]>;
  Unitrent?: Maybe<Scalars["float8"]>;
  Unitsize?: Maybe<Scalars["float8"]>;
  Unittype?: Maybe<Scalars["String"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rcapture_RPTUnittypes" */
export type Rtcs_Db_Rcapture_RptUnittypes_Aggregate = {
  __typename?: "rtcs_db_rcapture_RPTUnittypes_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rcapture_RptUnittypes>;
};

/** aggregate fields of "rtcs_db.rcapture_RPTUnittypes" */
export type Rtcs_Db_Rcapture_RptUnittypes_Aggregate_Fields = {
  __typename?: "rtcs_db_rcapture_RPTUnittypes_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rcapture_RPTUnittypes" */
export type Rtcs_Db_Rcapture_RptUnittypes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rcapture_RptUnittypes_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rcapture_RPTUnittypes" */
export type Rtcs_Db_Rcapture_RptUnittypes_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rcapture_RPTUnittypes" */
export type Rtcs_Db_Rcapture_RptUnittypes_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rcapture_RptUnittypes_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rcapture_RptUnittypes_Avg_Fields = {
  __typename?: "rtcs_db_rcapture_RPTUnittypes_avg_fields";
  Currencyadvertisedrent?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Numberunitstype?: Maybe<Scalars["Float"]>;
  Unitrent?: Maybe<Scalars["Float"]>;
  Unitsize?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rcapture_RPTUnittypes" */
export type Rtcs_Db_Rcapture_RptUnittypes_Avg_Order_By = {
  Currencyadvertisedrent?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Numberunitstype?: Maybe<Order_By>;
  Unitrent?: Maybe<Order_By>;
  Unitsize?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rcapture_RPTUnittypes". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rcapture_RptUnittypes_Bool_Exp = {
  Createdby?: Maybe<String_Comparison_Exp>;
  Currencyadvertisedrent?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Numberbathrooms?: Maybe<Bigint_Comparison_Exp>;
  Numberbedrooms?: Maybe<Bigint_Comparison_Exp>;
  Numberunitstype?: Maybe<Bigint_Comparison_Exp>;
  Pguid?: Maybe<String_Comparison_Exp>;
  Uguid?: Maybe<String_Comparison_Exp>;
  Unitfurnished?: Maybe<String_Comparison_Exp>;
  Unitrent?: Maybe<Float8_Comparison_Exp>;
  Unitsize?: Maybe<Float8_Comparison_Exp>;
  Unittype?: Maybe<String_Comparison_Exp>;
  Uuidproperty?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rcapture_RptUnittypes_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rcapture_RptUnittypes_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rcapture_RPTUnittypes" */
export type Rtcs_Db_Rcapture_RptUnittypes_Inc_Input = {
  Currencyadvertisedrent?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Numberunitstype?: Maybe<Scalars["bigint"]>;
  Unitrent?: Maybe<Scalars["float8"]>;
  Unitsize?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rcapture_RPTUnittypes" */
export type Rtcs_Db_Rcapture_RptUnittypes_Insert_Input = {
  Createdby?: Maybe<Scalars["String"]>;
  Currencyadvertisedrent?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Numberunitstype?: Maybe<Scalars["bigint"]>;
  Pguid?: Maybe<Scalars["String"]>;
  Uguid?: Maybe<Scalars["String"]>;
  Unitfurnished?: Maybe<Scalars["String"]>;
  Unitrent?: Maybe<Scalars["float8"]>;
  Unitsize?: Maybe<Scalars["float8"]>;
  Unittype?: Maybe<Scalars["String"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rcapture_RptUnittypes_Max_Fields = {
  __typename?: "rtcs_db_rcapture_RPTUnittypes_max_fields";
  Createdby?: Maybe<Scalars["String"]>;
  Currencyadvertisedrent?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Numberunitstype?: Maybe<Scalars["bigint"]>;
  Pguid?: Maybe<Scalars["String"]>;
  Uguid?: Maybe<Scalars["String"]>;
  Unitfurnished?: Maybe<Scalars["String"]>;
  Unitrent?: Maybe<Scalars["float8"]>;
  Unitsize?: Maybe<Scalars["float8"]>;
  Unittype?: Maybe<Scalars["String"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rcapture_RPTUnittypes" */
export type Rtcs_Db_Rcapture_RptUnittypes_Max_Order_By = {
  Createdby?: Maybe<Order_By>;
  Currencyadvertisedrent?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Numberunitstype?: Maybe<Order_By>;
  Pguid?: Maybe<Order_By>;
  Uguid?: Maybe<Order_By>;
  Unitfurnished?: Maybe<Order_By>;
  Unitrent?: Maybe<Order_By>;
  Unitsize?: Maybe<Order_By>;
  Unittype?: Maybe<Order_By>;
  Uuidproperty?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rcapture_RptUnittypes_Min_Fields = {
  __typename?: "rtcs_db_rcapture_RPTUnittypes_min_fields";
  Createdby?: Maybe<Scalars["String"]>;
  Currencyadvertisedrent?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Numberunitstype?: Maybe<Scalars["bigint"]>;
  Pguid?: Maybe<Scalars["String"]>;
  Uguid?: Maybe<Scalars["String"]>;
  Unitfurnished?: Maybe<Scalars["String"]>;
  Unitrent?: Maybe<Scalars["float8"]>;
  Unitsize?: Maybe<Scalars["float8"]>;
  Unittype?: Maybe<Scalars["String"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rcapture_RPTUnittypes" */
export type Rtcs_Db_Rcapture_RptUnittypes_Min_Order_By = {
  Createdby?: Maybe<Order_By>;
  Currencyadvertisedrent?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Numberunitstype?: Maybe<Order_By>;
  Pguid?: Maybe<Order_By>;
  Uguid?: Maybe<Order_By>;
  Unitfurnished?: Maybe<Order_By>;
  Unitrent?: Maybe<Order_By>;
  Unitsize?: Maybe<Order_By>;
  Unittype?: Maybe<Order_By>;
  Uuidproperty?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rcapture_RPTUnittypes" */
export type Rtcs_Db_Rcapture_RptUnittypes_Mutation_Response = {
  __typename?: "rtcs_db_rcapture_RPTUnittypes_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rcapture_RptUnittypes>;
};

/** input type for inserting object relation for remote table "rtcs_db.rcapture_RPTUnittypes" */
export type Rtcs_Db_Rcapture_RptUnittypes_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rcapture_RptUnittypes_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rcapture_RPTUnittypes" */
export type Rtcs_Db_Rcapture_RptUnittypes_Order_By = {
  Createdby?: Maybe<Order_By>;
  Currencyadvertisedrent?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Numberunitstype?: Maybe<Order_By>;
  Pguid?: Maybe<Order_By>;
  Uguid?: Maybe<Order_By>;
  Unitfurnished?: Maybe<Order_By>;
  Unitrent?: Maybe<Order_By>;
  Unitsize?: Maybe<Order_By>;
  Unittype?: Maybe<Order_By>;
  Uuidproperty?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rcapture_RPTUnittypes" */
export enum Rtcs_Db_Rcapture_RptUnittypes_Select_Column {
  /** column name */
  Createdby = "Createdby",
  /** column name */
  Currencyadvertisedrent = "Currencyadvertisedrent",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Numberbathrooms = "Numberbathrooms",
  /** column name */
  Numberbedrooms = "Numberbedrooms",
  /** column name */
  Numberunitstype = "Numberunitstype",
  /** column name */
  Pguid = "Pguid",
  /** column name */
  Uguid = "Uguid",
  /** column name */
  Unitfurnished = "Unitfurnished",
  /** column name */
  Unitrent = "Unitrent",
  /** column name */
  Unitsize = "Unitsize",
  /** column name */
  Unittype = "Unittype",
  /** column name */
  Uuidproperty = "Uuidproperty",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rcapture_RPTUnittypes" */
export type Rtcs_Db_Rcapture_RptUnittypes_Set_Input = {
  Createdby?: Maybe<Scalars["String"]>;
  Currencyadvertisedrent?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Numberunitstype?: Maybe<Scalars["bigint"]>;
  Pguid?: Maybe<Scalars["String"]>;
  Uguid?: Maybe<Scalars["String"]>;
  Unitfurnished?: Maybe<Scalars["String"]>;
  Unitrent?: Maybe<Scalars["float8"]>;
  Unitsize?: Maybe<Scalars["float8"]>;
  Unittype?: Maybe<Scalars["String"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rcapture_RptUnittypes_Stddev_Fields = {
  __typename?: "rtcs_db_rcapture_RPTUnittypes_stddev_fields";
  Currencyadvertisedrent?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Numberunitstype?: Maybe<Scalars["Float"]>;
  Unitrent?: Maybe<Scalars["Float"]>;
  Unitsize?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rcapture_RPTUnittypes" */
export type Rtcs_Db_Rcapture_RptUnittypes_Stddev_Order_By = {
  Currencyadvertisedrent?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Numberunitstype?: Maybe<Order_By>;
  Unitrent?: Maybe<Order_By>;
  Unitsize?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rcapture_RptUnittypes_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rcapture_RPTUnittypes_stddev_pop_fields";
  Currencyadvertisedrent?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Numberunitstype?: Maybe<Scalars["Float"]>;
  Unitrent?: Maybe<Scalars["Float"]>;
  Unitsize?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rcapture_RPTUnittypes" */
export type Rtcs_Db_Rcapture_RptUnittypes_Stddev_Pop_Order_By = {
  Currencyadvertisedrent?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Numberunitstype?: Maybe<Order_By>;
  Unitrent?: Maybe<Order_By>;
  Unitsize?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rcapture_RptUnittypes_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rcapture_RPTUnittypes_stddev_samp_fields";
  Currencyadvertisedrent?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Numberunitstype?: Maybe<Scalars["Float"]>;
  Unitrent?: Maybe<Scalars["Float"]>;
  Unitsize?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rcapture_RPTUnittypes" */
export type Rtcs_Db_Rcapture_RptUnittypes_Stddev_Samp_Order_By = {
  Currencyadvertisedrent?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Numberunitstype?: Maybe<Order_By>;
  Unitrent?: Maybe<Order_By>;
  Unitsize?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rcapture_RptUnittypes_Sum_Fields = {
  __typename?: "rtcs_db_rcapture_RPTUnittypes_sum_fields";
  Currencyadvertisedrent?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Numberunitstype?: Maybe<Scalars["bigint"]>;
  Unitrent?: Maybe<Scalars["float8"]>;
  Unitsize?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rcapture_RPTUnittypes" */
export type Rtcs_Db_Rcapture_RptUnittypes_Sum_Order_By = {
  Currencyadvertisedrent?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Numberunitstype?: Maybe<Order_By>;
  Unitrent?: Maybe<Order_By>;
  Unitsize?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rcapture_RptUnittypes_Var_Pop_Fields = {
  __typename?: "rtcs_db_rcapture_RPTUnittypes_var_pop_fields";
  Currencyadvertisedrent?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Numberunitstype?: Maybe<Scalars["Float"]>;
  Unitrent?: Maybe<Scalars["Float"]>;
  Unitsize?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rcapture_RPTUnittypes" */
export type Rtcs_Db_Rcapture_RptUnittypes_Var_Pop_Order_By = {
  Currencyadvertisedrent?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Numberunitstype?: Maybe<Order_By>;
  Unitrent?: Maybe<Order_By>;
  Unitsize?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rcapture_RptUnittypes_Var_Samp_Fields = {
  __typename?: "rtcs_db_rcapture_RPTUnittypes_var_samp_fields";
  Currencyadvertisedrent?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Numberunitstype?: Maybe<Scalars["Float"]>;
  Unitrent?: Maybe<Scalars["Float"]>;
  Unitsize?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rcapture_RPTUnittypes" */
export type Rtcs_Db_Rcapture_RptUnittypes_Var_Samp_Order_By = {
  Currencyadvertisedrent?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Numberunitstype?: Maybe<Order_By>;
  Unitrent?: Maybe<Order_By>;
  Unitsize?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rcapture_RptUnittypes_Variance_Fields = {
  __typename?: "rtcs_db_rcapture_RPTUnittypes_variance_fields";
  Currencyadvertisedrent?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Numberunitstype?: Maybe<Scalars["Float"]>;
  Unitrent?: Maybe<Scalars["Float"]>;
  Unitsize?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rcapture_RPTUnittypes" */
export type Rtcs_Db_Rcapture_RptUnittypes_Variance_Order_By = {
  Currencyadvertisedrent?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Numberunitstype?: Maybe<Order_By>;
  Unitrent?: Maybe<Order_By>;
  Unitsize?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rcapture_RPTlessors" */
export type Rtcs_Db_Rcapture_RpTlessors = {
  __typename?: "rtcs_db_rcapture_RPTlessors";
  Buildingname?: Maybe<Scalars["String"]>;
  Businesstradingname?: Maybe<Scalars["String"]>;
  Cin?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Customeridsrc?: Maybe<Scalars["String"]>;
  Datecreated?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalbusinessname?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Nationality?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Phone1?: Maybe<Scalars["String"]>;
  Phone2?: Maybe<Scalars["String"]>;
  Phone3?: Maybe<Scalars["String"]>;
  Phone4?: Maybe<Scalars["String"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Positionincompany?: Maybe<Scalars["String"]>;
  Postaldistrict?: Maybe<Scalars["String"]>;
  Postalpobox?: Maybe<Scalars["String"]>;
  Propertiesuuids?: Maybe<Scalars["String"]>;
  Region?: Maybe<Scalars["String"]>;
  Registeredbusinessnumber?: Maybe<Scalars["String"]>;
  Repdob?: Maybe<Scalars["timestamptz"]>;
  Repfirstname?: Maybe<Scalars["String"]>;
  Replastname?: Maybe<Scalars["String"]>;
  Repnationality?: Maybe<Scalars["String"]>;
  Repothercountry?: Maybe<Scalars["String"]>;
  Repphone1?: Maybe<Scalars["String"]>;
  Reptitle?: Maybe<Scalars["String"]>;
  Sguid?: Maybe<Scalars["String"]>;
  Streetname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Tradinglicensenumber?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rcapture_RPTlessors" */
export type Rtcs_Db_Rcapture_RpTlessors_Aggregate = {
  __typename?: "rtcs_db_rcapture_RPTlessors_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rcapture_RpTlessors>;
};

/** aggregate fields of "rtcs_db.rcapture_RPTlessors" */
export type Rtcs_Db_Rcapture_RpTlessors_Aggregate_Fields = {
  __typename?: "rtcs_db_rcapture_RPTlessors_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rcapture_RPTlessors" */
export type Rtcs_Db_Rcapture_RpTlessors_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rcapture_RpTlessors_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rcapture_RPTlessors" */
export type Rtcs_Db_Rcapture_RpTlessors_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rcapture_RPTlessors" */
export type Rtcs_Db_Rcapture_RpTlessors_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rcapture_RpTlessors_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rcapture_RpTlessors_Avg_Fields = {
  __typename?: "rtcs_db_rcapture_RPTlessors_avg_fields";
  Cin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rcapture_RPTlessors" */
export type Rtcs_Db_Rcapture_RpTlessors_Avg_Order_By = {
  Cin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rcapture_RPTlessors". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rcapture_RpTlessors_Bool_Exp = {
  Buildingname?: Maybe<String_Comparison_Exp>;
  Businesstradingname?: Maybe<String_Comparison_Exp>;
  Cin?: Maybe<Float8_Comparison_Exp>;
  County?: Maybe<String_Comparison_Exp>;
  Createdby?: Maybe<String_Comparison_Exp>;
  Customerid?: Maybe<String_Comparison_Exp>;
  Customeridsrc?: Maybe<String_Comparison_Exp>;
  Datecreated?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  Identifier?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Lastmodified?: Maybe<Timestamptz_Comparison_Exp>;
  Lastname?: Maybe<String_Comparison_Exp>;
  Legalbusinessname?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Modifiedby?: Maybe<String_Comparison_Exp>;
  Nationality?: Maybe<String_Comparison_Exp>;
  Parish?: Maybe<String_Comparison_Exp>;
  Phone1?: Maybe<String_Comparison_Exp>;
  Phone2?: Maybe<String_Comparison_Exp>;
  Phone3?: Maybe<String_Comparison_Exp>;
  Phone4?: Maybe<String_Comparison_Exp>;
  Plotnumber?: Maybe<String_Comparison_Exp>;
  Positionincompany?: Maybe<String_Comparison_Exp>;
  Postaldistrict?: Maybe<String_Comparison_Exp>;
  Postalpobox?: Maybe<String_Comparison_Exp>;
  Propertiesuuids?: Maybe<String_Comparison_Exp>;
  Region?: Maybe<String_Comparison_Exp>;
  Registeredbusinessnumber?: Maybe<String_Comparison_Exp>;
  Repdob?: Maybe<Timestamptz_Comparison_Exp>;
  Repfirstname?: Maybe<String_Comparison_Exp>;
  Replastname?: Maybe<String_Comparison_Exp>;
  Repnationality?: Maybe<String_Comparison_Exp>;
  Repothercountry?: Maybe<String_Comparison_Exp>;
  Repphone1?: Maybe<String_Comparison_Exp>;
  Reptitle?: Maybe<String_Comparison_Exp>;
  Sguid?: Maybe<String_Comparison_Exp>;
  Streetname?: Maybe<String_Comparison_Exp>;
  Subcounty?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<Float8_Comparison_Exp>;
  Title?: Maybe<String_Comparison_Exp>;
  Tradinglicensenumber?: Maybe<String_Comparison_Exp>;
  Type?: Maybe<String_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rcapture_RpTlessors_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rcapture_RpTlessors_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rcapture_RPTlessors" */
export type Rtcs_Db_Rcapture_RpTlessors_Inc_Input = {
  Cin?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Tin?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rcapture_RPTlessors" */
export type Rtcs_Db_Rcapture_RpTlessors_Insert_Input = {
  Buildingname?: Maybe<Scalars["String"]>;
  Businesstradingname?: Maybe<Scalars["String"]>;
  Cin?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Customeridsrc?: Maybe<Scalars["String"]>;
  Datecreated?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalbusinessname?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Nationality?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Phone1?: Maybe<Scalars["String"]>;
  Phone2?: Maybe<Scalars["String"]>;
  Phone3?: Maybe<Scalars["String"]>;
  Phone4?: Maybe<Scalars["String"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Positionincompany?: Maybe<Scalars["String"]>;
  Postaldistrict?: Maybe<Scalars["String"]>;
  Postalpobox?: Maybe<Scalars["String"]>;
  Propertiesuuids?: Maybe<Scalars["String"]>;
  Region?: Maybe<Scalars["String"]>;
  Registeredbusinessnumber?: Maybe<Scalars["String"]>;
  Repdob?: Maybe<Scalars["timestamptz"]>;
  Repfirstname?: Maybe<Scalars["String"]>;
  Replastname?: Maybe<Scalars["String"]>;
  Repnationality?: Maybe<Scalars["String"]>;
  Repothercountry?: Maybe<Scalars["String"]>;
  Repphone1?: Maybe<Scalars["String"]>;
  Reptitle?: Maybe<Scalars["String"]>;
  Sguid?: Maybe<Scalars["String"]>;
  Streetname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Tradinglicensenumber?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rcapture_RpTlessors_Max_Fields = {
  __typename?: "rtcs_db_rcapture_RPTlessors_max_fields";
  Buildingname?: Maybe<Scalars["String"]>;
  Businesstradingname?: Maybe<Scalars["String"]>;
  Cin?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Customeridsrc?: Maybe<Scalars["String"]>;
  Datecreated?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalbusinessname?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Nationality?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Phone1?: Maybe<Scalars["String"]>;
  Phone2?: Maybe<Scalars["String"]>;
  Phone3?: Maybe<Scalars["String"]>;
  Phone4?: Maybe<Scalars["String"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Positionincompany?: Maybe<Scalars["String"]>;
  Postaldistrict?: Maybe<Scalars["String"]>;
  Postalpobox?: Maybe<Scalars["String"]>;
  Propertiesuuids?: Maybe<Scalars["String"]>;
  Region?: Maybe<Scalars["String"]>;
  Registeredbusinessnumber?: Maybe<Scalars["String"]>;
  Repdob?: Maybe<Scalars["timestamptz"]>;
  Repfirstname?: Maybe<Scalars["String"]>;
  Replastname?: Maybe<Scalars["String"]>;
  Repnationality?: Maybe<Scalars["String"]>;
  Repothercountry?: Maybe<Scalars["String"]>;
  Repphone1?: Maybe<Scalars["String"]>;
  Reptitle?: Maybe<Scalars["String"]>;
  Sguid?: Maybe<Scalars["String"]>;
  Streetname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Tradinglicensenumber?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rcapture_RPTlessors" */
export type Rtcs_Db_Rcapture_RpTlessors_Max_Order_By = {
  Buildingname?: Maybe<Order_By>;
  Businesstradingname?: Maybe<Order_By>;
  Cin?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Createdby?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Customeridsrc?: Maybe<Order_By>;
  Datecreated?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Identifier?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastmodified?: Maybe<Order_By>;
  Lastname?: Maybe<Order_By>;
  Legalbusinessname?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Modifiedby?: Maybe<Order_By>;
  Nationality?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Phone1?: Maybe<Order_By>;
  Phone2?: Maybe<Order_By>;
  Phone3?: Maybe<Order_By>;
  Phone4?: Maybe<Order_By>;
  Plotnumber?: Maybe<Order_By>;
  Positionincompany?: Maybe<Order_By>;
  Postaldistrict?: Maybe<Order_By>;
  Postalpobox?: Maybe<Order_By>;
  Propertiesuuids?: Maybe<Order_By>;
  Region?: Maybe<Order_By>;
  Registeredbusinessnumber?: Maybe<Order_By>;
  Repdob?: Maybe<Order_By>;
  Repfirstname?: Maybe<Order_By>;
  Replastname?: Maybe<Order_By>;
  Repnationality?: Maybe<Order_By>;
  Repothercountry?: Maybe<Order_By>;
  Repphone1?: Maybe<Order_By>;
  Reptitle?: Maybe<Order_By>;
  Sguid?: Maybe<Order_By>;
  Streetname?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Title?: Maybe<Order_By>;
  Tradinglicensenumber?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rcapture_RpTlessors_Min_Fields = {
  __typename?: "rtcs_db_rcapture_RPTlessors_min_fields";
  Buildingname?: Maybe<Scalars["String"]>;
  Businesstradingname?: Maybe<Scalars["String"]>;
  Cin?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Customeridsrc?: Maybe<Scalars["String"]>;
  Datecreated?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalbusinessname?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Nationality?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Phone1?: Maybe<Scalars["String"]>;
  Phone2?: Maybe<Scalars["String"]>;
  Phone3?: Maybe<Scalars["String"]>;
  Phone4?: Maybe<Scalars["String"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Positionincompany?: Maybe<Scalars["String"]>;
  Postaldistrict?: Maybe<Scalars["String"]>;
  Postalpobox?: Maybe<Scalars["String"]>;
  Propertiesuuids?: Maybe<Scalars["String"]>;
  Region?: Maybe<Scalars["String"]>;
  Registeredbusinessnumber?: Maybe<Scalars["String"]>;
  Repdob?: Maybe<Scalars["timestamptz"]>;
  Repfirstname?: Maybe<Scalars["String"]>;
  Replastname?: Maybe<Scalars["String"]>;
  Repnationality?: Maybe<Scalars["String"]>;
  Repothercountry?: Maybe<Scalars["String"]>;
  Repphone1?: Maybe<Scalars["String"]>;
  Reptitle?: Maybe<Scalars["String"]>;
  Sguid?: Maybe<Scalars["String"]>;
  Streetname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Tradinglicensenumber?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rcapture_RPTlessors" */
export type Rtcs_Db_Rcapture_RpTlessors_Min_Order_By = {
  Buildingname?: Maybe<Order_By>;
  Businesstradingname?: Maybe<Order_By>;
  Cin?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Createdby?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Customeridsrc?: Maybe<Order_By>;
  Datecreated?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Identifier?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastmodified?: Maybe<Order_By>;
  Lastname?: Maybe<Order_By>;
  Legalbusinessname?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Modifiedby?: Maybe<Order_By>;
  Nationality?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Phone1?: Maybe<Order_By>;
  Phone2?: Maybe<Order_By>;
  Phone3?: Maybe<Order_By>;
  Phone4?: Maybe<Order_By>;
  Plotnumber?: Maybe<Order_By>;
  Positionincompany?: Maybe<Order_By>;
  Postaldistrict?: Maybe<Order_By>;
  Postalpobox?: Maybe<Order_By>;
  Propertiesuuids?: Maybe<Order_By>;
  Region?: Maybe<Order_By>;
  Registeredbusinessnumber?: Maybe<Order_By>;
  Repdob?: Maybe<Order_By>;
  Repfirstname?: Maybe<Order_By>;
  Replastname?: Maybe<Order_By>;
  Repnationality?: Maybe<Order_By>;
  Repothercountry?: Maybe<Order_By>;
  Repphone1?: Maybe<Order_By>;
  Reptitle?: Maybe<Order_By>;
  Sguid?: Maybe<Order_By>;
  Streetname?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Title?: Maybe<Order_By>;
  Tradinglicensenumber?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rcapture_RPTlessors" */
export type Rtcs_Db_Rcapture_RpTlessors_Mutation_Response = {
  __typename?: "rtcs_db_rcapture_RPTlessors_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rcapture_RpTlessors>;
};

/** input type for inserting object relation for remote table "rtcs_db.rcapture_RPTlessors" */
export type Rtcs_Db_Rcapture_RpTlessors_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rcapture_RpTlessors_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rcapture_RPTlessors" */
export type Rtcs_Db_Rcapture_RpTlessors_Order_By = {
  Buildingname?: Maybe<Order_By>;
  Businesstradingname?: Maybe<Order_By>;
  Cin?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Createdby?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Customeridsrc?: Maybe<Order_By>;
  Datecreated?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Identifier?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastmodified?: Maybe<Order_By>;
  Lastname?: Maybe<Order_By>;
  Legalbusinessname?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Modifiedby?: Maybe<Order_By>;
  Nationality?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Phone1?: Maybe<Order_By>;
  Phone2?: Maybe<Order_By>;
  Phone3?: Maybe<Order_By>;
  Phone4?: Maybe<Order_By>;
  Plotnumber?: Maybe<Order_By>;
  Positionincompany?: Maybe<Order_By>;
  Postaldistrict?: Maybe<Order_By>;
  Postalpobox?: Maybe<Order_By>;
  Propertiesuuids?: Maybe<Order_By>;
  Region?: Maybe<Order_By>;
  Registeredbusinessnumber?: Maybe<Order_By>;
  Repdob?: Maybe<Order_By>;
  Repfirstname?: Maybe<Order_By>;
  Replastname?: Maybe<Order_By>;
  Repnationality?: Maybe<Order_By>;
  Repothercountry?: Maybe<Order_By>;
  Repphone1?: Maybe<Order_By>;
  Reptitle?: Maybe<Order_By>;
  Sguid?: Maybe<Order_By>;
  Streetname?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Title?: Maybe<Order_By>;
  Tradinglicensenumber?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rcapture_RPTlessors" */
export enum Rtcs_Db_Rcapture_RpTlessors_Select_Column {
  /** column name */
  Buildingname = "Buildingname",
  /** column name */
  Businesstradingname = "Businesstradingname",
  /** column name */
  Cin = "Cin",
  /** column name */
  County = "County",
  /** column name */
  Createdby = "Createdby",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  Customeridsrc = "Customeridsrc",
  /** column name */
  Datecreated = "Datecreated",
  /** column name */
  District = "District",
  /** column name */
  Email = "Email",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Id = "Id",
  /** column name */
  Identifier = "Identifier",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Lastmodified = "Lastmodified",
  /** column name */
  Lastname = "Lastname",
  /** column name */
  Legalbusinessname = "Legalbusinessname",
  /** column name */
  Line = "Line",
  /** column name */
  Modifiedby = "Modifiedby",
  /** column name */
  Nationality = "Nationality",
  /** column name */
  Parish = "Parish",
  /** column name */
  Phone1 = "Phone1",
  /** column name */
  Phone2 = "Phone2",
  /** column name */
  Phone3 = "Phone3",
  /** column name */
  Phone4 = "Phone4",
  /** column name */
  Plotnumber = "Plotnumber",
  /** column name */
  Positionincompany = "Positionincompany",
  /** column name */
  Postaldistrict = "Postaldistrict",
  /** column name */
  Postalpobox = "Postalpobox",
  /** column name */
  Propertiesuuids = "Propertiesuuids",
  /** column name */
  Region = "Region",
  /** column name */
  Registeredbusinessnumber = "Registeredbusinessnumber",
  /** column name */
  Repdob = "Repdob",
  /** column name */
  Repfirstname = "Repfirstname",
  /** column name */
  Replastname = "Replastname",
  /** column name */
  Repnationality = "Repnationality",
  /** column name */
  Repothercountry = "Repothercountry",
  /** column name */
  Repphone1 = "Repphone1",
  /** column name */
  Reptitle = "Reptitle",
  /** column name */
  Sguid = "Sguid",
  /** column name */
  Streetname = "Streetname",
  /** column name */
  Subcounty = "Subcounty",
  /** column name */
  Tin = "Tin",
  /** column name */
  Title = "Title",
  /** column name */
  Tradinglicensenumber = "Tradinglicensenumber",
  /** column name */
  Type = "Type",
  /** column name */
  Village = "Village",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rcapture_RPTlessors" */
export type Rtcs_Db_Rcapture_RpTlessors_Set_Input = {
  Buildingname?: Maybe<Scalars["String"]>;
  Businesstradingname?: Maybe<Scalars["String"]>;
  Cin?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Customeridsrc?: Maybe<Scalars["String"]>;
  Datecreated?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalbusinessname?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Nationality?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Phone1?: Maybe<Scalars["String"]>;
  Phone2?: Maybe<Scalars["String"]>;
  Phone3?: Maybe<Scalars["String"]>;
  Phone4?: Maybe<Scalars["String"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Positionincompany?: Maybe<Scalars["String"]>;
  Postaldistrict?: Maybe<Scalars["String"]>;
  Postalpobox?: Maybe<Scalars["String"]>;
  Propertiesuuids?: Maybe<Scalars["String"]>;
  Region?: Maybe<Scalars["String"]>;
  Registeredbusinessnumber?: Maybe<Scalars["String"]>;
  Repdob?: Maybe<Scalars["timestamptz"]>;
  Repfirstname?: Maybe<Scalars["String"]>;
  Replastname?: Maybe<Scalars["String"]>;
  Repnationality?: Maybe<Scalars["String"]>;
  Repothercountry?: Maybe<Scalars["String"]>;
  Repphone1?: Maybe<Scalars["String"]>;
  Reptitle?: Maybe<Scalars["String"]>;
  Sguid?: Maybe<Scalars["String"]>;
  Streetname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Tradinglicensenumber?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rcapture_RpTlessors_Stddev_Fields = {
  __typename?: "rtcs_db_rcapture_RPTlessors_stddev_fields";
  Cin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rcapture_RPTlessors" */
export type Rtcs_Db_Rcapture_RpTlessors_Stddev_Order_By = {
  Cin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rcapture_RpTlessors_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rcapture_RPTlessors_stddev_pop_fields";
  Cin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rcapture_RPTlessors" */
export type Rtcs_Db_Rcapture_RpTlessors_Stddev_Pop_Order_By = {
  Cin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rcapture_RpTlessors_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rcapture_RPTlessors_stddev_samp_fields";
  Cin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rcapture_RPTlessors" */
export type Rtcs_Db_Rcapture_RpTlessors_Stddev_Samp_Order_By = {
  Cin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rcapture_RpTlessors_Sum_Fields = {
  __typename?: "rtcs_db_rcapture_RPTlessors_sum_fields";
  Cin?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Tin?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rcapture_RPTlessors" */
export type Rtcs_Db_Rcapture_RpTlessors_Sum_Order_By = {
  Cin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rcapture_RpTlessors_Var_Pop_Fields = {
  __typename?: "rtcs_db_rcapture_RPTlessors_var_pop_fields";
  Cin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rcapture_RPTlessors" */
export type Rtcs_Db_Rcapture_RpTlessors_Var_Pop_Order_By = {
  Cin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rcapture_RpTlessors_Var_Samp_Fields = {
  __typename?: "rtcs_db_rcapture_RPTlessors_var_samp_fields";
  Cin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rcapture_RPTlessors" */
export type Rtcs_Db_Rcapture_RpTlessors_Var_Samp_Order_By = {
  Cin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rcapture_RpTlessors_Variance_Fields = {
  __typename?: "rtcs_db_rcapture_RPTlessors_variance_fields";
  Cin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rcapture_RPTlessors" */
export type Rtcs_Db_Rcapture_RpTlessors_Variance_Order_By = {
  Cin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rcapture_RPTmeta" */
export type Rtcs_Db_Rcapture_RpTmeta = {
  __typename?: "rtcs_db_rcapture_RPTmeta";
  Appversion?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  Datecreated?: Maybe<Scalars["timestamptz"]>;
  Devicename?: Maybe<Scalars["String"]>;
  Deviceos?: Maybe<Scalars["String"]>;
  Deviceosversion?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Localdate?: Maybe<Scalars["timestamptz"]>;
  Localtimestamp?: Maybe<Scalars["timestamptz"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Orgname?: Maybe<Scalars["String"]>;
  Screendate?: Maybe<Scalars["String"]>;
  Screenversion?: Maybe<Scalars["float8"]>;
  Useremail?: Maybe<Scalars["String"]>;
  Username?: Maybe<Scalars["String"]>;
  Utcshorttimestamp?: Maybe<Scalars["timestamptz"]>;
  Utctimestamp?: Maybe<Scalars["timestamptz"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rcapture_RPTmeta" */
export type Rtcs_Db_Rcapture_RpTmeta_Aggregate = {
  __typename?: "rtcs_db_rcapture_RPTmeta_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rcapture_RpTmeta>;
};

/** aggregate fields of "rtcs_db.rcapture_RPTmeta" */
export type Rtcs_Db_Rcapture_RpTmeta_Aggregate_Fields = {
  __typename?: "rtcs_db_rcapture_RPTmeta_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rcapture_RPTmeta" */
export type Rtcs_Db_Rcapture_RpTmeta_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rcapture_RpTmeta_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rcapture_RPTmeta" */
export type Rtcs_Db_Rcapture_RpTmeta_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rcapture_RPTmeta" */
export type Rtcs_Db_Rcapture_RpTmeta_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rcapture_RpTmeta_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rcapture_RpTmeta_Avg_Fields = {
  __typename?: "rtcs_db_rcapture_RPTmeta_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
  Screenversion?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rcapture_RPTmeta" */
export type Rtcs_Db_Rcapture_RpTmeta_Avg_Order_By = {
  Line?: Maybe<Order_By>;
  Screenversion?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rcapture_RPTmeta". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rcapture_RpTmeta_Bool_Exp = {
  Appversion?: Maybe<String_Comparison_Exp>;
  Createdby?: Maybe<String_Comparison_Exp>;
  Datecreated?: Maybe<Timestamptz_Comparison_Exp>;
  Devicename?: Maybe<String_Comparison_Exp>;
  Deviceos?: Maybe<String_Comparison_Exp>;
  Deviceosversion?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Lastmodified?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Localdate?: Maybe<Timestamptz_Comparison_Exp>;
  Localtimestamp?: Maybe<Timestamptz_Comparison_Exp>;
  Modifiedby?: Maybe<String_Comparison_Exp>;
  Notes?: Maybe<String_Comparison_Exp>;
  Orgname?: Maybe<String_Comparison_Exp>;
  Screendate?: Maybe<String_Comparison_Exp>;
  Screenversion?: Maybe<Float8_Comparison_Exp>;
  Useremail?: Maybe<String_Comparison_Exp>;
  Username?: Maybe<String_Comparison_Exp>;
  Utcshorttimestamp?: Maybe<Timestamptz_Comparison_Exp>;
  Utctimestamp?: Maybe<Timestamptz_Comparison_Exp>;
  Uuidproperty?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rcapture_RpTmeta_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rcapture_RpTmeta_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rcapture_RPTmeta" */
export type Rtcs_Db_Rcapture_RpTmeta_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
  Screenversion?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rcapture_RPTmeta" */
export type Rtcs_Db_Rcapture_RpTmeta_Insert_Input = {
  Appversion?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  Datecreated?: Maybe<Scalars["timestamptz"]>;
  Devicename?: Maybe<Scalars["String"]>;
  Deviceos?: Maybe<Scalars["String"]>;
  Deviceosversion?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Localdate?: Maybe<Scalars["timestamptz"]>;
  Localtimestamp?: Maybe<Scalars["timestamptz"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Orgname?: Maybe<Scalars["String"]>;
  Screendate?: Maybe<Scalars["String"]>;
  Screenversion?: Maybe<Scalars["float8"]>;
  Useremail?: Maybe<Scalars["String"]>;
  Username?: Maybe<Scalars["String"]>;
  Utcshorttimestamp?: Maybe<Scalars["timestamptz"]>;
  Utctimestamp?: Maybe<Scalars["timestamptz"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rcapture_RpTmeta_Max_Fields = {
  __typename?: "rtcs_db_rcapture_RPTmeta_max_fields";
  Appversion?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  Datecreated?: Maybe<Scalars["timestamptz"]>;
  Devicename?: Maybe<Scalars["String"]>;
  Deviceos?: Maybe<Scalars["String"]>;
  Deviceosversion?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Localdate?: Maybe<Scalars["timestamptz"]>;
  Localtimestamp?: Maybe<Scalars["timestamptz"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Orgname?: Maybe<Scalars["String"]>;
  Screendate?: Maybe<Scalars["String"]>;
  Screenversion?: Maybe<Scalars["float8"]>;
  Useremail?: Maybe<Scalars["String"]>;
  Username?: Maybe<Scalars["String"]>;
  Utcshorttimestamp?: Maybe<Scalars["timestamptz"]>;
  Utctimestamp?: Maybe<Scalars["timestamptz"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rcapture_RPTmeta" */
export type Rtcs_Db_Rcapture_RpTmeta_Max_Order_By = {
  Appversion?: Maybe<Order_By>;
  Createdby?: Maybe<Order_By>;
  Datecreated?: Maybe<Order_By>;
  Devicename?: Maybe<Order_By>;
  Deviceos?: Maybe<Order_By>;
  Deviceosversion?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastmodified?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Localdate?: Maybe<Order_By>;
  Localtimestamp?: Maybe<Order_By>;
  Modifiedby?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  Orgname?: Maybe<Order_By>;
  Screendate?: Maybe<Order_By>;
  Screenversion?: Maybe<Order_By>;
  Useremail?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
  Utcshorttimestamp?: Maybe<Order_By>;
  Utctimestamp?: Maybe<Order_By>;
  Uuidproperty?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rcapture_RpTmeta_Min_Fields = {
  __typename?: "rtcs_db_rcapture_RPTmeta_min_fields";
  Appversion?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  Datecreated?: Maybe<Scalars["timestamptz"]>;
  Devicename?: Maybe<Scalars["String"]>;
  Deviceos?: Maybe<Scalars["String"]>;
  Deviceosversion?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Localdate?: Maybe<Scalars["timestamptz"]>;
  Localtimestamp?: Maybe<Scalars["timestamptz"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Orgname?: Maybe<Scalars["String"]>;
  Screendate?: Maybe<Scalars["String"]>;
  Screenversion?: Maybe<Scalars["float8"]>;
  Useremail?: Maybe<Scalars["String"]>;
  Username?: Maybe<Scalars["String"]>;
  Utcshorttimestamp?: Maybe<Scalars["timestamptz"]>;
  Utctimestamp?: Maybe<Scalars["timestamptz"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rcapture_RPTmeta" */
export type Rtcs_Db_Rcapture_RpTmeta_Min_Order_By = {
  Appversion?: Maybe<Order_By>;
  Createdby?: Maybe<Order_By>;
  Datecreated?: Maybe<Order_By>;
  Devicename?: Maybe<Order_By>;
  Deviceos?: Maybe<Order_By>;
  Deviceosversion?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastmodified?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Localdate?: Maybe<Order_By>;
  Localtimestamp?: Maybe<Order_By>;
  Modifiedby?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  Orgname?: Maybe<Order_By>;
  Screendate?: Maybe<Order_By>;
  Screenversion?: Maybe<Order_By>;
  Useremail?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
  Utcshorttimestamp?: Maybe<Order_By>;
  Utctimestamp?: Maybe<Order_By>;
  Uuidproperty?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rcapture_RPTmeta" */
export type Rtcs_Db_Rcapture_RpTmeta_Mutation_Response = {
  __typename?: "rtcs_db_rcapture_RPTmeta_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rcapture_RpTmeta>;
};

/** input type for inserting object relation for remote table "rtcs_db.rcapture_RPTmeta" */
export type Rtcs_Db_Rcapture_RpTmeta_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rcapture_RpTmeta_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rcapture_RPTmeta" */
export type Rtcs_Db_Rcapture_RpTmeta_Order_By = {
  Appversion?: Maybe<Order_By>;
  Createdby?: Maybe<Order_By>;
  Datecreated?: Maybe<Order_By>;
  Devicename?: Maybe<Order_By>;
  Deviceos?: Maybe<Order_By>;
  Deviceosversion?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastmodified?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Localdate?: Maybe<Order_By>;
  Localtimestamp?: Maybe<Order_By>;
  Modifiedby?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  Orgname?: Maybe<Order_By>;
  Screendate?: Maybe<Order_By>;
  Screenversion?: Maybe<Order_By>;
  Useremail?: Maybe<Order_By>;
  Username?: Maybe<Order_By>;
  Utcshorttimestamp?: Maybe<Order_By>;
  Utctimestamp?: Maybe<Order_By>;
  Uuidproperty?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rcapture_RPTmeta" */
export enum Rtcs_Db_Rcapture_RpTmeta_Select_Column {
  /** column name */
  Appversion = "Appversion",
  /** column name */
  Createdby = "Createdby",
  /** column name */
  Datecreated = "Datecreated",
  /** column name */
  Devicename = "Devicename",
  /** column name */
  Deviceos = "Deviceos",
  /** column name */
  Deviceosversion = "Deviceosversion",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Lastmodified = "Lastmodified",
  /** column name */
  Line = "Line",
  /** column name */
  Localdate = "Localdate",
  /** column name */
  Localtimestamp = "Localtimestamp",
  /** column name */
  Modifiedby = "Modifiedby",
  /** column name */
  Notes = "Notes",
  /** column name */
  Orgname = "Orgname",
  /** column name */
  Screendate = "Screendate",
  /** column name */
  Screenversion = "Screenversion",
  /** column name */
  Useremail = "Useremail",
  /** column name */
  Username = "Username",
  /** column name */
  Utcshorttimestamp = "Utcshorttimestamp",
  /** column name */
  Utctimestamp = "Utctimestamp",
  /** column name */
  Uuidproperty = "Uuidproperty",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rcapture_RPTmeta" */
export type Rtcs_Db_Rcapture_RpTmeta_Set_Input = {
  Appversion?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  Datecreated?: Maybe<Scalars["timestamptz"]>;
  Devicename?: Maybe<Scalars["String"]>;
  Deviceos?: Maybe<Scalars["String"]>;
  Deviceosversion?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Localdate?: Maybe<Scalars["timestamptz"]>;
  Localtimestamp?: Maybe<Scalars["timestamptz"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Orgname?: Maybe<Scalars["String"]>;
  Screendate?: Maybe<Scalars["String"]>;
  Screenversion?: Maybe<Scalars["float8"]>;
  Useremail?: Maybe<Scalars["String"]>;
  Username?: Maybe<Scalars["String"]>;
  Utcshorttimestamp?: Maybe<Scalars["timestamptz"]>;
  Utctimestamp?: Maybe<Scalars["timestamptz"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rcapture_RpTmeta_Stddev_Fields = {
  __typename?: "rtcs_db_rcapture_RPTmeta_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
  Screenversion?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rcapture_RPTmeta" */
export type Rtcs_Db_Rcapture_RpTmeta_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
  Screenversion?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rcapture_RpTmeta_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rcapture_RPTmeta_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  Screenversion?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rcapture_RPTmeta" */
export type Rtcs_Db_Rcapture_RpTmeta_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  Screenversion?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rcapture_RpTmeta_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rcapture_RPTmeta_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  Screenversion?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rcapture_RPTmeta" */
export type Rtcs_Db_Rcapture_RpTmeta_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  Screenversion?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rcapture_RpTmeta_Sum_Fields = {
  __typename?: "rtcs_db_rcapture_RPTmeta_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
  Screenversion?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rcapture_RPTmeta" */
export type Rtcs_Db_Rcapture_RpTmeta_Sum_Order_By = {
  Line?: Maybe<Order_By>;
  Screenversion?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rcapture_RpTmeta_Var_Pop_Fields = {
  __typename?: "rtcs_db_rcapture_RPTmeta_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  Screenversion?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rcapture_RPTmeta" */
export type Rtcs_Db_Rcapture_RpTmeta_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  Screenversion?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rcapture_RpTmeta_Var_Samp_Fields = {
  __typename?: "rtcs_db_rcapture_RPTmeta_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  Screenversion?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rcapture_RPTmeta" */
export type Rtcs_Db_Rcapture_RpTmeta_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  Screenversion?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rcapture_RpTmeta_Variance_Fields = {
  __typename?: "rtcs_db_rcapture_RPTmeta_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
  Screenversion?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rcapture_RPTmeta" */
export type Rtcs_Db_Rcapture_RpTmeta_Variance_Order_By = {
  Line?: Maybe<Order_By>;
  Screenversion?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rcapture_RPTproperties" */
export type Rtcs_Db_Rcapture_RpTproperties = {
  __typename?: "rtcs_db_rcapture_RPTproperties";
  Blocknumber?: Maybe<Scalars["String"]>;
  Buildingcondition?: Maybe<Scalars["String"]>;
  Buildingname?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Found?: Maybe<Scalars["String"]>;
  Geolocation?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  Landlord1identifier?: Maybe<Scalars["String"]>;
  Landlotsize?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Lessor1firstname?: Maybe<Scalars["String"]>;
  Lessor1identifier?: Maybe<Scalars["String"]>;
  Lessor1lastname?: Maybe<Scalars["String"]>;
  Lessor2firstname?: Maybe<Scalars["String"]>;
  Lessor2identifier?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Numberunits?: Maybe<Scalars["bigint"]>;
  Outcome?: Maybe<Scalars["String"]>;
  Owner1identifier?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Pguid?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["String"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Propertyid?: Maybe<Scalars["String"]>;
  Propertyidsrc?: Maybe<Scalars["String"]>;
  Propertymanager1identifier?: Maybe<Scalars["String"]>;
  Region?: Maybe<Scalars["String"]>;
  Streetname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Subtype?: Maybe<Scalars["String"]>;
  Totallessors?: Maybe<Scalars["bigint"]>;
  Totaltenants?: Maybe<Scalars["bigint"]>;
  Totalunits?: Maybe<Scalars["bigint"]>;
  Type?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  Websiteurl?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rcapture_RPTproperties" */
export type Rtcs_Db_Rcapture_RpTproperties_Aggregate = {
  __typename?: "rtcs_db_rcapture_RPTproperties_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rcapture_RpTproperties>;
};

/** aggregate fields of "rtcs_db.rcapture_RPTproperties" */
export type Rtcs_Db_Rcapture_RpTproperties_Aggregate_Fields = {
  __typename?: "rtcs_db_rcapture_RPTproperties_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rcapture_RPTproperties" */
export type Rtcs_Db_Rcapture_RpTproperties_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rcapture_RpTproperties_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rcapture_RPTproperties" */
export type Rtcs_Db_Rcapture_RpTproperties_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rcapture_RPTproperties" */
export type Rtcs_Db_Rcapture_RpTproperties_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rcapture_RpTproperties_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rcapture_RpTproperties_Avg_Fields = {
  __typename?: "rtcs_db_rcapture_RPTproperties_avg_fields";
  Landlotsize?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Numberunits?: Maybe<Scalars["Float"]>;
  Totallessors?: Maybe<Scalars["Float"]>;
  Totaltenants?: Maybe<Scalars["Float"]>;
  Totalunits?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rcapture_RPTproperties" */
export type Rtcs_Db_Rcapture_RpTproperties_Avg_Order_By = {
  Landlotsize?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Numberunits?: Maybe<Order_By>;
  Totallessors?: Maybe<Order_By>;
  Totaltenants?: Maybe<Order_By>;
  Totalunits?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rcapture_RPTproperties". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rcapture_RpTproperties_Bool_Exp = {
  Blocknumber?: Maybe<String_Comparison_Exp>;
  Buildingcondition?: Maybe<String_Comparison_Exp>;
  Buildingname?: Maybe<String_Comparison_Exp>;
  County?: Maybe<String_Comparison_Exp>;
  Createdby?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Found?: Maybe<String_Comparison_Exp>;
  Geolocation?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  Identifier?: Maybe<String_Comparison_Exp>;
  Landlord1identifier?: Maybe<String_Comparison_Exp>;
  Landlotsize?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Lastmodified?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Lessor1firstname?: Maybe<String_Comparison_Exp>;
  Lessor1identifier?: Maybe<String_Comparison_Exp>;
  Lessor1lastname?: Maybe<String_Comparison_Exp>;
  Lessor2firstname?: Maybe<String_Comparison_Exp>;
  Lessor2identifier?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  Modifiedby?: Maybe<String_Comparison_Exp>;
  Notes?: Maybe<String_Comparison_Exp>;
  Numberunits?: Maybe<Bigint_Comparison_Exp>;
  Outcome?: Maybe<String_Comparison_Exp>;
  Owner1identifier?: Maybe<String_Comparison_Exp>;
  Parish?: Maybe<String_Comparison_Exp>;
  Pguid?: Maybe<String_Comparison_Exp>;
  Photo?: Maybe<String_Comparison_Exp>;
  Plotnumber?: Maybe<String_Comparison_Exp>;
  Propertyid?: Maybe<String_Comparison_Exp>;
  Propertyidsrc?: Maybe<String_Comparison_Exp>;
  Propertymanager1identifier?: Maybe<String_Comparison_Exp>;
  Region?: Maybe<String_Comparison_Exp>;
  Streetname?: Maybe<String_Comparison_Exp>;
  Subcounty?: Maybe<String_Comparison_Exp>;
  Subtype?: Maybe<String_Comparison_Exp>;
  Totallessors?: Maybe<Bigint_Comparison_Exp>;
  Totaltenants?: Maybe<Bigint_Comparison_Exp>;
  Totalunits?: Maybe<Bigint_Comparison_Exp>;
  Type?: Maybe<String_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  Websiteurl?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rcapture_RpTproperties_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rcapture_RpTproperties_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rcapture_RPTproperties" */
export type Rtcs_Db_Rcapture_RpTproperties_Inc_Input = {
  Landlotsize?: Maybe<Scalars["bigint"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Numberunits?: Maybe<Scalars["bigint"]>;
  Totallessors?: Maybe<Scalars["bigint"]>;
  Totaltenants?: Maybe<Scalars["bigint"]>;
  Totalunits?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.rcapture_RPTproperties" */
export type Rtcs_Db_Rcapture_RpTproperties_Insert_Input = {
  Blocknumber?: Maybe<Scalars["String"]>;
  Buildingcondition?: Maybe<Scalars["String"]>;
  Buildingname?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Found?: Maybe<Scalars["String"]>;
  Geolocation?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  Landlord1identifier?: Maybe<Scalars["String"]>;
  Landlotsize?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Lessor1firstname?: Maybe<Scalars["String"]>;
  Lessor1identifier?: Maybe<Scalars["String"]>;
  Lessor1lastname?: Maybe<Scalars["String"]>;
  Lessor2firstname?: Maybe<Scalars["String"]>;
  Lessor2identifier?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Numberunits?: Maybe<Scalars["bigint"]>;
  Outcome?: Maybe<Scalars["String"]>;
  Owner1identifier?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Pguid?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["String"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Propertyid?: Maybe<Scalars["String"]>;
  Propertyidsrc?: Maybe<Scalars["String"]>;
  Propertymanager1identifier?: Maybe<Scalars["String"]>;
  Region?: Maybe<Scalars["String"]>;
  Streetname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Subtype?: Maybe<Scalars["String"]>;
  Totallessors?: Maybe<Scalars["bigint"]>;
  Totaltenants?: Maybe<Scalars["bigint"]>;
  Totalunits?: Maybe<Scalars["bigint"]>;
  Type?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  Websiteurl?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rcapture_RpTproperties_Max_Fields = {
  __typename?: "rtcs_db_rcapture_RPTproperties_max_fields";
  Blocknumber?: Maybe<Scalars["String"]>;
  Buildingcondition?: Maybe<Scalars["String"]>;
  Buildingname?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Found?: Maybe<Scalars["String"]>;
  Geolocation?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  Landlord1identifier?: Maybe<Scalars["String"]>;
  Landlotsize?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Lessor1firstname?: Maybe<Scalars["String"]>;
  Lessor1identifier?: Maybe<Scalars["String"]>;
  Lessor1lastname?: Maybe<Scalars["String"]>;
  Lessor2firstname?: Maybe<Scalars["String"]>;
  Lessor2identifier?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Numberunits?: Maybe<Scalars["bigint"]>;
  Outcome?: Maybe<Scalars["String"]>;
  Owner1identifier?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Pguid?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["String"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Propertyid?: Maybe<Scalars["String"]>;
  Propertyidsrc?: Maybe<Scalars["String"]>;
  Propertymanager1identifier?: Maybe<Scalars["String"]>;
  Region?: Maybe<Scalars["String"]>;
  Streetname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Subtype?: Maybe<Scalars["String"]>;
  Totallessors?: Maybe<Scalars["bigint"]>;
  Totaltenants?: Maybe<Scalars["bigint"]>;
  Totalunits?: Maybe<Scalars["bigint"]>;
  Type?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  Websiteurl?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rcapture_RPTproperties" */
export type Rtcs_Db_Rcapture_RpTproperties_Max_Order_By = {
  Blocknumber?: Maybe<Order_By>;
  Buildingcondition?: Maybe<Order_By>;
  Buildingname?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Createdby?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Found?: Maybe<Order_By>;
  Geolocation?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Identifier?: Maybe<Order_By>;
  Landlord1identifier?: Maybe<Order_By>;
  Landlotsize?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastmodified?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Lessor1firstname?: Maybe<Order_By>;
  Lessor1identifier?: Maybe<Order_By>;
  Lessor1lastname?: Maybe<Order_By>;
  Lessor2firstname?: Maybe<Order_By>;
  Lessor2identifier?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Modifiedby?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  Numberunits?: Maybe<Order_By>;
  Outcome?: Maybe<Order_By>;
  Owner1identifier?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Pguid?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  Plotnumber?: Maybe<Order_By>;
  Propertyid?: Maybe<Order_By>;
  Propertyidsrc?: Maybe<Order_By>;
  Propertymanager1identifier?: Maybe<Order_By>;
  Region?: Maybe<Order_By>;
  Streetname?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Subtype?: Maybe<Order_By>;
  Totallessors?: Maybe<Order_By>;
  Totaltenants?: Maybe<Order_By>;
  Totalunits?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  Websiteurl?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rcapture_RpTproperties_Min_Fields = {
  __typename?: "rtcs_db_rcapture_RPTproperties_min_fields";
  Blocknumber?: Maybe<Scalars["String"]>;
  Buildingcondition?: Maybe<Scalars["String"]>;
  Buildingname?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Found?: Maybe<Scalars["String"]>;
  Geolocation?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  Landlord1identifier?: Maybe<Scalars["String"]>;
  Landlotsize?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Lessor1firstname?: Maybe<Scalars["String"]>;
  Lessor1identifier?: Maybe<Scalars["String"]>;
  Lessor1lastname?: Maybe<Scalars["String"]>;
  Lessor2firstname?: Maybe<Scalars["String"]>;
  Lessor2identifier?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Numberunits?: Maybe<Scalars["bigint"]>;
  Outcome?: Maybe<Scalars["String"]>;
  Owner1identifier?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Pguid?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["String"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Propertyid?: Maybe<Scalars["String"]>;
  Propertyidsrc?: Maybe<Scalars["String"]>;
  Propertymanager1identifier?: Maybe<Scalars["String"]>;
  Region?: Maybe<Scalars["String"]>;
  Streetname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Subtype?: Maybe<Scalars["String"]>;
  Totallessors?: Maybe<Scalars["bigint"]>;
  Totaltenants?: Maybe<Scalars["bigint"]>;
  Totalunits?: Maybe<Scalars["bigint"]>;
  Type?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  Websiteurl?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rcapture_RPTproperties" */
export type Rtcs_Db_Rcapture_RpTproperties_Min_Order_By = {
  Blocknumber?: Maybe<Order_By>;
  Buildingcondition?: Maybe<Order_By>;
  Buildingname?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Createdby?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Found?: Maybe<Order_By>;
  Geolocation?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Identifier?: Maybe<Order_By>;
  Landlord1identifier?: Maybe<Order_By>;
  Landlotsize?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastmodified?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Lessor1firstname?: Maybe<Order_By>;
  Lessor1identifier?: Maybe<Order_By>;
  Lessor1lastname?: Maybe<Order_By>;
  Lessor2firstname?: Maybe<Order_By>;
  Lessor2identifier?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Modifiedby?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  Numberunits?: Maybe<Order_By>;
  Outcome?: Maybe<Order_By>;
  Owner1identifier?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Pguid?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  Plotnumber?: Maybe<Order_By>;
  Propertyid?: Maybe<Order_By>;
  Propertyidsrc?: Maybe<Order_By>;
  Propertymanager1identifier?: Maybe<Order_By>;
  Region?: Maybe<Order_By>;
  Streetname?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Subtype?: Maybe<Order_By>;
  Totallessors?: Maybe<Order_By>;
  Totaltenants?: Maybe<Order_By>;
  Totalunits?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  Websiteurl?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rcapture_RPTproperties" */
export type Rtcs_Db_Rcapture_RpTproperties_Mutation_Response = {
  __typename?: "rtcs_db_rcapture_RPTproperties_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rcapture_RpTproperties>;
};

/** input type for inserting object relation for remote table "rtcs_db.rcapture_RPTproperties" */
export type Rtcs_Db_Rcapture_RpTproperties_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rcapture_RpTproperties_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rcapture_RPTproperties" */
export type Rtcs_Db_Rcapture_RpTproperties_Order_By = {
  Blocknumber?: Maybe<Order_By>;
  Buildingcondition?: Maybe<Order_By>;
  Buildingname?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Createdby?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Found?: Maybe<Order_By>;
  Geolocation?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Identifier?: Maybe<Order_By>;
  Landlord1identifier?: Maybe<Order_By>;
  Landlotsize?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastmodified?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Lessor1firstname?: Maybe<Order_By>;
  Lessor1identifier?: Maybe<Order_By>;
  Lessor1lastname?: Maybe<Order_By>;
  Lessor2firstname?: Maybe<Order_By>;
  Lessor2identifier?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Modifiedby?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  Numberunits?: Maybe<Order_By>;
  Outcome?: Maybe<Order_By>;
  Owner1identifier?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Pguid?: Maybe<Order_By>;
  Photo?: Maybe<Order_By>;
  Plotnumber?: Maybe<Order_By>;
  Propertyid?: Maybe<Order_By>;
  Propertyidsrc?: Maybe<Order_By>;
  Propertymanager1identifier?: Maybe<Order_By>;
  Region?: Maybe<Order_By>;
  Streetname?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Subtype?: Maybe<Order_By>;
  Totallessors?: Maybe<Order_By>;
  Totaltenants?: Maybe<Order_By>;
  Totalunits?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  Websiteurl?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rcapture_RPTproperties" */
export enum Rtcs_Db_Rcapture_RpTproperties_Select_Column {
  /** column name */
  Blocknumber = "Blocknumber",
  /** column name */
  Buildingcondition = "Buildingcondition",
  /** column name */
  Buildingname = "Buildingname",
  /** column name */
  County = "County",
  /** column name */
  Createdby = "Createdby",
  /** column name */
  District = "District",
  /** column name */
  File = "File",
  /** column name */
  Found = "Found",
  /** column name */
  Geolocation = "Geolocation",
  /** column name */
  Id = "Id",
  /** column name */
  Identifier = "Identifier",
  /** column name */
  Landlord1identifier = "Landlord1identifier",
  /** column name */
  Landlotsize = "Landlotsize",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Lastmodified = "Lastmodified",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Lessor1firstname = "Lessor1firstname",
  /** column name */
  Lessor1identifier = "Lessor1identifier",
  /** column name */
  Lessor1lastname = "Lessor1lastname",
  /** column name */
  Lessor2firstname = "Lessor2firstname",
  /** column name */
  Lessor2identifier = "Lessor2identifier",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  Modifiedby = "Modifiedby",
  /** column name */
  Notes = "Notes",
  /** column name */
  Numberunits = "Numberunits",
  /** column name */
  Outcome = "Outcome",
  /** column name */
  Owner1identifier = "Owner1identifier",
  /** column name */
  Parish = "Parish",
  /** column name */
  Pguid = "Pguid",
  /** column name */
  Photo = "Photo",
  /** column name */
  Plotnumber = "Plotnumber",
  /** column name */
  Propertyid = "Propertyid",
  /** column name */
  Propertyidsrc = "Propertyidsrc",
  /** column name */
  Propertymanager1identifier = "Propertymanager1identifier",
  /** column name */
  Region = "Region",
  /** column name */
  Streetname = "Streetname",
  /** column name */
  Subcounty = "Subcounty",
  /** column name */
  Subtype = "Subtype",
  /** column name */
  Totallessors = "Totallessors",
  /** column name */
  Totaltenants = "Totaltenants",
  /** column name */
  Totalunits = "Totalunits",
  /** column name */
  Type = "Type",
  /** column name */
  Village = "Village",
  /** column name */
  Websiteurl = "Websiteurl",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rcapture_RPTproperties" */
export type Rtcs_Db_Rcapture_RpTproperties_Set_Input = {
  Blocknumber?: Maybe<Scalars["String"]>;
  Buildingcondition?: Maybe<Scalars["String"]>;
  Buildingname?: Maybe<Scalars["String"]>;
  County?: Maybe<Scalars["String"]>;
  Createdby?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Found?: Maybe<Scalars["String"]>;
  Geolocation?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  Landlord1identifier?: Maybe<Scalars["String"]>;
  Landlotsize?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastmodified?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Lessor1firstname?: Maybe<Scalars["String"]>;
  Lessor1identifier?: Maybe<Scalars["String"]>;
  Lessor1lastname?: Maybe<Scalars["String"]>;
  Lessor2firstname?: Maybe<Scalars["String"]>;
  Lessor2identifier?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Modifiedby?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Numberunits?: Maybe<Scalars["bigint"]>;
  Outcome?: Maybe<Scalars["String"]>;
  Owner1identifier?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Pguid?: Maybe<Scalars["String"]>;
  Photo?: Maybe<Scalars["String"]>;
  Plotnumber?: Maybe<Scalars["String"]>;
  Propertyid?: Maybe<Scalars["String"]>;
  Propertyidsrc?: Maybe<Scalars["String"]>;
  Propertymanager1identifier?: Maybe<Scalars["String"]>;
  Region?: Maybe<Scalars["String"]>;
  Streetname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Subtype?: Maybe<Scalars["String"]>;
  Totallessors?: Maybe<Scalars["bigint"]>;
  Totaltenants?: Maybe<Scalars["bigint"]>;
  Totalunits?: Maybe<Scalars["bigint"]>;
  Type?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  Websiteurl?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rcapture_RpTproperties_Stddev_Fields = {
  __typename?: "rtcs_db_rcapture_RPTproperties_stddev_fields";
  Landlotsize?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Numberunits?: Maybe<Scalars["Float"]>;
  Totallessors?: Maybe<Scalars["Float"]>;
  Totaltenants?: Maybe<Scalars["Float"]>;
  Totalunits?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rcapture_RPTproperties" */
export type Rtcs_Db_Rcapture_RpTproperties_Stddev_Order_By = {
  Landlotsize?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Numberunits?: Maybe<Order_By>;
  Totallessors?: Maybe<Order_By>;
  Totaltenants?: Maybe<Order_By>;
  Totalunits?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rcapture_RpTproperties_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rcapture_RPTproperties_stddev_pop_fields";
  Landlotsize?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Numberunits?: Maybe<Scalars["Float"]>;
  Totallessors?: Maybe<Scalars["Float"]>;
  Totaltenants?: Maybe<Scalars["Float"]>;
  Totalunits?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rcapture_RPTproperties" */
export type Rtcs_Db_Rcapture_RpTproperties_Stddev_Pop_Order_By = {
  Landlotsize?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Numberunits?: Maybe<Order_By>;
  Totallessors?: Maybe<Order_By>;
  Totaltenants?: Maybe<Order_By>;
  Totalunits?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rcapture_RpTproperties_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rcapture_RPTproperties_stddev_samp_fields";
  Landlotsize?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Numberunits?: Maybe<Scalars["Float"]>;
  Totallessors?: Maybe<Scalars["Float"]>;
  Totaltenants?: Maybe<Scalars["Float"]>;
  Totalunits?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rcapture_RPTproperties" */
export type Rtcs_Db_Rcapture_RpTproperties_Stddev_Samp_Order_By = {
  Landlotsize?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Numberunits?: Maybe<Order_By>;
  Totallessors?: Maybe<Order_By>;
  Totaltenants?: Maybe<Order_By>;
  Totalunits?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rcapture_RpTproperties_Sum_Fields = {
  __typename?: "rtcs_db_rcapture_RPTproperties_sum_fields";
  Landlotsize?: Maybe<Scalars["bigint"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Numberunits?: Maybe<Scalars["bigint"]>;
  Totallessors?: Maybe<Scalars["bigint"]>;
  Totaltenants?: Maybe<Scalars["bigint"]>;
  Totalunits?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.rcapture_RPTproperties" */
export type Rtcs_Db_Rcapture_RpTproperties_Sum_Order_By = {
  Landlotsize?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Numberunits?: Maybe<Order_By>;
  Totallessors?: Maybe<Order_By>;
  Totaltenants?: Maybe<Order_By>;
  Totalunits?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rcapture_RpTproperties_Var_Pop_Fields = {
  __typename?: "rtcs_db_rcapture_RPTproperties_var_pop_fields";
  Landlotsize?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Numberunits?: Maybe<Scalars["Float"]>;
  Totallessors?: Maybe<Scalars["Float"]>;
  Totaltenants?: Maybe<Scalars["Float"]>;
  Totalunits?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rcapture_RPTproperties" */
export type Rtcs_Db_Rcapture_RpTproperties_Var_Pop_Order_By = {
  Landlotsize?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Numberunits?: Maybe<Order_By>;
  Totallessors?: Maybe<Order_By>;
  Totaltenants?: Maybe<Order_By>;
  Totalunits?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rcapture_RpTproperties_Var_Samp_Fields = {
  __typename?: "rtcs_db_rcapture_RPTproperties_var_samp_fields";
  Landlotsize?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Numberunits?: Maybe<Scalars["Float"]>;
  Totallessors?: Maybe<Scalars["Float"]>;
  Totaltenants?: Maybe<Scalars["Float"]>;
  Totalunits?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rcapture_RPTproperties" */
export type Rtcs_Db_Rcapture_RpTproperties_Var_Samp_Order_By = {
  Landlotsize?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Numberunits?: Maybe<Order_By>;
  Totallessors?: Maybe<Order_By>;
  Totaltenants?: Maybe<Order_By>;
  Totalunits?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rcapture_RpTproperties_Variance_Fields = {
  __typename?: "rtcs_db_rcapture_RPTproperties_variance_fields";
  Landlotsize?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Numberunits?: Maybe<Scalars["Float"]>;
  Totallessors?: Maybe<Scalars["Float"]>;
  Totaltenants?: Maybe<Scalars["Float"]>;
  Totalunits?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rcapture_RPTproperties" */
export type Rtcs_Db_Rcapture_RpTproperties_Variance_Order_By = {
  Landlotsize?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Numberunits?: Maybe<Order_By>;
  Totallessors?: Maybe<Order_By>;
  Totaltenants?: Maybe<Order_By>;
  Totalunits?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rcapture_RPTtenants" */
export type Rtcs_Db_Rcapture_RpTtenants = {
  __typename?: "rtcs_db_rcapture_RPTtenants";
  Actualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Amountofrentreceipts?: Maybe<Scalars["float8"]>;
  Businesstradingname?: Maybe<Scalars["String"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Currencyactualrent?: Maybe<Scalars["String"]>;
  Currencyreportedrent?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Estimatedshopsize?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Floornumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  Isunitlet?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalbusinessname?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mda?: Maybe<Scalars["String"]>;
  Nationality?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Owneroccupiedorrented?: Maybe<Scalars["String"]>;
  Phone1?: Maybe<Scalars["String"]>;
  Phone2?: Maybe<Scalars["String"]>;
  Phone3?: Maybe<Scalars["String"]>;
  Phone4?: Maybe<Scalars["String"]>;
  Positionincompany?: Maybe<Scalars["String"]>;
  Registeredbusinessnumber?: Maybe<Scalars["String"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["float8"]>;
  Repemail?: Maybe<Scalars["String"]>;
  Repfirstname?: Maybe<Scalars["String"]>;
  Replastname?: Maybe<Scalars["String"]>;
  Repmiddlename?: Maybe<Scalars["String"]>;
  Repnationality?: Maybe<Scalars["String"]>;
  Repothercountry?: Maybe<Scalars["String"]>;
  Repphone1?: Maybe<Scalars["String"]>;
  Reptitle?: Maybe<Scalars["String"]>;
  Reptitleother?: Maybe<Scalars["String"]>;
  Shopname?: Maybe<Scalars["String"]>;
  Shopnumber?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  Typeofgoodssold?: Maybe<Scalars["String"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rcapture_RPTtenants" */
export type Rtcs_Db_Rcapture_RpTtenants_Aggregate = {
  __typename?: "rtcs_db_rcapture_RPTtenants_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rcapture_RpTtenants>;
};

/** aggregate fields of "rtcs_db.rcapture_RPTtenants" */
export type Rtcs_Db_Rcapture_RpTtenants_Aggregate_Fields = {
  __typename?: "rtcs_db_rcapture_RPTtenants_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rcapture_RPTtenants" */
export type Rtcs_Db_Rcapture_RpTtenants_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rcapture_RpTtenants_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rcapture_RPTtenants" */
export type Rtcs_Db_Rcapture_RpTtenants_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rcapture_RPTtenants" */
export type Rtcs_Db_Rcapture_RpTtenants_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rcapture_RpTtenants_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rcapture_RpTtenants_Avg_Fields = {
  __typename?: "rtcs_db_rcapture_RPTtenants_avg_fields";
  Actualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Amountofrentreceipts?: Maybe<Scalars["Float"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rcapture_RPTtenants" */
export type Rtcs_Db_Rcapture_RpTtenants_Avg_Order_By = {
  Actualrentpaidpermonth?: Maybe<Order_By>;
  Amountofrentreceipts?: Maybe<Order_By>;
  Cactualrentpaidpermonth?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Rentreportedbylessorpermonth?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rcapture_RPTtenants". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rcapture_RpTtenants_Bool_Exp = {
  Actualrentpaidpermonth?: Maybe<Float8_Comparison_Exp>;
  Amountofrentreceipts?: Maybe<Float8_Comparison_Exp>;
  Businesstradingname?: Maybe<String_Comparison_Exp>;
  Cactualrentpaidpermonth?: Maybe<Float8_Comparison_Exp>;
  Currencyactualrent?: Maybe<String_Comparison_Exp>;
  Currencyreportedrent?: Maybe<String_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  Estimatedshopsize?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Floornumber?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  Identifier?: Maybe<String_Comparison_Exp>;
  Isunitlet?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Lastname?: Maybe<String_Comparison_Exp>;
  Legalbusinessname?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Mda?: Maybe<String_Comparison_Exp>;
  Nationality?: Maybe<String_Comparison_Exp>;
  Notes?: Maybe<String_Comparison_Exp>;
  Numberbathrooms?: Maybe<Bigint_Comparison_Exp>;
  Numberbedrooms?: Maybe<Bigint_Comparison_Exp>;
  Owneroccupiedorrented?: Maybe<String_Comparison_Exp>;
  Phone1?: Maybe<String_Comparison_Exp>;
  Phone2?: Maybe<String_Comparison_Exp>;
  Phone3?: Maybe<String_Comparison_Exp>;
  Phone4?: Maybe<String_Comparison_Exp>;
  Positionincompany?: Maybe<String_Comparison_Exp>;
  Registeredbusinessnumber?: Maybe<String_Comparison_Exp>;
  Rentreportedbylessorpermonth?: Maybe<Float8_Comparison_Exp>;
  Repemail?: Maybe<String_Comparison_Exp>;
  Repfirstname?: Maybe<String_Comparison_Exp>;
  Replastname?: Maybe<String_Comparison_Exp>;
  Repmiddlename?: Maybe<String_Comparison_Exp>;
  Repnationality?: Maybe<String_Comparison_Exp>;
  Repothercountry?: Maybe<String_Comparison_Exp>;
  Repphone1?: Maybe<String_Comparison_Exp>;
  Reptitle?: Maybe<String_Comparison_Exp>;
  Reptitleother?: Maybe<String_Comparison_Exp>;
  Shopname?: Maybe<String_Comparison_Exp>;
  Shopnumber?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<Float8_Comparison_Exp>;
  Title?: Maybe<String_Comparison_Exp>;
  Type?: Maybe<String_Comparison_Exp>;
  Typeofgoodssold?: Maybe<String_Comparison_Exp>;
  Uuidproperty?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rcapture_RpTtenants_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rcapture_RpTtenants_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rcapture_RPTtenants" */
export type Rtcs_Db_Rcapture_RpTtenants_Inc_Input = {
  Actualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Amountofrentreceipts?: Maybe<Scalars["float8"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["float8"]>;
  Tin?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rcapture_RPTtenants" */
export type Rtcs_Db_Rcapture_RpTtenants_Insert_Input = {
  Actualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Amountofrentreceipts?: Maybe<Scalars["float8"]>;
  Businesstradingname?: Maybe<Scalars["String"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Currencyactualrent?: Maybe<Scalars["String"]>;
  Currencyreportedrent?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Estimatedshopsize?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Floornumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  Isunitlet?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalbusinessname?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mda?: Maybe<Scalars["String"]>;
  Nationality?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Owneroccupiedorrented?: Maybe<Scalars["String"]>;
  Phone1?: Maybe<Scalars["String"]>;
  Phone2?: Maybe<Scalars["String"]>;
  Phone3?: Maybe<Scalars["String"]>;
  Phone4?: Maybe<Scalars["String"]>;
  Positionincompany?: Maybe<Scalars["String"]>;
  Registeredbusinessnumber?: Maybe<Scalars["String"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["float8"]>;
  Repemail?: Maybe<Scalars["String"]>;
  Repfirstname?: Maybe<Scalars["String"]>;
  Replastname?: Maybe<Scalars["String"]>;
  Repmiddlename?: Maybe<Scalars["String"]>;
  Repnationality?: Maybe<Scalars["String"]>;
  Repothercountry?: Maybe<Scalars["String"]>;
  Repphone1?: Maybe<Scalars["String"]>;
  Reptitle?: Maybe<Scalars["String"]>;
  Reptitleother?: Maybe<Scalars["String"]>;
  Shopname?: Maybe<Scalars["String"]>;
  Shopnumber?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  Typeofgoodssold?: Maybe<Scalars["String"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rcapture_RpTtenants_Max_Fields = {
  __typename?: "rtcs_db_rcapture_RPTtenants_max_fields";
  Actualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Amountofrentreceipts?: Maybe<Scalars["float8"]>;
  Businesstradingname?: Maybe<Scalars["String"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Currencyactualrent?: Maybe<Scalars["String"]>;
  Currencyreportedrent?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Estimatedshopsize?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Floornumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  Isunitlet?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalbusinessname?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mda?: Maybe<Scalars["String"]>;
  Nationality?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Owneroccupiedorrented?: Maybe<Scalars["String"]>;
  Phone1?: Maybe<Scalars["String"]>;
  Phone2?: Maybe<Scalars["String"]>;
  Phone3?: Maybe<Scalars["String"]>;
  Phone4?: Maybe<Scalars["String"]>;
  Positionincompany?: Maybe<Scalars["String"]>;
  Registeredbusinessnumber?: Maybe<Scalars["String"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["float8"]>;
  Repemail?: Maybe<Scalars["String"]>;
  Repfirstname?: Maybe<Scalars["String"]>;
  Replastname?: Maybe<Scalars["String"]>;
  Repmiddlename?: Maybe<Scalars["String"]>;
  Repnationality?: Maybe<Scalars["String"]>;
  Repothercountry?: Maybe<Scalars["String"]>;
  Repphone1?: Maybe<Scalars["String"]>;
  Reptitle?: Maybe<Scalars["String"]>;
  Reptitleother?: Maybe<Scalars["String"]>;
  Shopname?: Maybe<Scalars["String"]>;
  Shopnumber?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  Typeofgoodssold?: Maybe<Scalars["String"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rcapture_RPTtenants" */
export type Rtcs_Db_Rcapture_RpTtenants_Max_Order_By = {
  Actualrentpaidpermonth?: Maybe<Order_By>;
  Amountofrentreceipts?: Maybe<Order_By>;
  Businesstradingname?: Maybe<Order_By>;
  Cactualrentpaidpermonth?: Maybe<Order_By>;
  Currencyactualrent?: Maybe<Order_By>;
  Currencyreportedrent?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  Estimatedshopsize?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Floornumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Identifier?: Maybe<Order_By>;
  Isunitlet?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastname?: Maybe<Order_By>;
  Legalbusinessname?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mda?: Maybe<Order_By>;
  Nationality?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Owneroccupiedorrented?: Maybe<Order_By>;
  Phone1?: Maybe<Order_By>;
  Phone2?: Maybe<Order_By>;
  Phone3?: Maybe<Order_By>;
  Phone4?: Maybe<Order_By>;
  Positionincompany?: Maybe<Order_By>;
  Registeredbusinessnumber?: Maybe<Order_By>;
  Rentreportedbylessorpermonth?: Maybe<Order_By>;
  Repemail?: Maybe<Order_By>;
  Repfirstname?: Maybe<Order_By>;
  Replastname?: Maybe<Order_By>;
  Repmiddlename?: Maybe<Order_By>;
  Repnationality?: Maybe<Order_By>;
  Repothercountry?: Maybe<Order_By>;
  Repphone1?: Maybe<Order_By>;
  Reptitle?: Maybe<Order_By>;
  Reptitleother?: Maybe<Order_By>;
  Shopname?: Maybe<Order_By>;
  Shopnumber?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Title?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Typeofgoodssold?: Maybe<Order_By>;
  Uuidproperty?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rcapture_RpTtenants_Min_Fields = {
  __typename?: "rtcs_db_rcapture_RPTtenants_min_fields";
  Actualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Amountofrentreceipts?: Maybe<Scalars["float8"]>;
  Businesstradingname?: Maybe<Scalars["String"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Currencyactualrent?: Maybe<Scalars["String"]>;
  Currencyreportedrent?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Estimatedshopsize?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Floornumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  Isunitlet?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalbusinessname?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mda?: Maybe<Scalars["String"]>;
  Nationality?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Owneroccupiedorrented?: Maybe<Scalars["String"]>;
  Phone1?: Maybe<Scalars["String"]>;
  Phone2?: Maybe<Scalars["String"]>;
  Phone3?: Maybe<Scalars["String"]>;
  Phone4?: Maybe<Scalars["String"]>;
  Positionincompany?: Maybe<Scalars["String"]>;
  Registeredbusinessnumber?: Maybe<Scalars["String"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["float8"]>;
  Repemail?: Maybe<Scalars["String"]>;
  Repfirstname?: Maybe<Scalars["String"]>;
  Replastname?: Maybe<Scalars["String"]>;
  Repmiddlename?: Maybe<Scalars["String"]>;
  Repnationality?: Maybe<Scalars["String"]>;
  Repothercountry?: Maybe<Scalars["String"]>;
  Repphone1?: Maybe<Scalars["String"]>;
  Reptitle?: Maybe<Scalars["String"]>;
  Reptitleother?: Maybe<Scalars["String"]>;
  Shopname?: Maybe<Scalars["String"]>;
  Shopnumber?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  Typeofgoodssold?: Maybe<Scalars["String"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rcapture_RPTtenants" */
export type Rtcs_Db_Rcapture_RpTtenants_Min_Order_By = {
  Actualrentpaidpermonth?: Maybe<Order_By>;
  Amountofrentreceipts?: Maybe<Order_By>;
  Businesstradingname?: Maybe<Order_By>;
  Cactualrentpaidpermonth?: Maybe<Order_By>;
  Currencyactualrent?: Maybe<Order_By>;
  Currencyreportedrent?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  Estimatedshopsize?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Floornumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Identifier?: Maybe<Order_By>;
  Isunitlet?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastname?: Maybe<Order_By>;
  Legalbusinessname?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mda?: Maybe<Order_By>;
  Nationality?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Owneroccupiedorrented?: Maybe<Order_By>;
  Phone1?: Maybe<Order_By>;
  Phone2?: Maybe<Order_By>;
  Phone3?: Maybe<Order_By>;
  Phone4?: Maybe<Order_By>;
  Positionincompany?: Maybe<Order_By>;
  Registeredbusinessnumber?: Maybe<Order_By>;
  Rentreportedbylessorpermonth?: Maybe<Order_By>;
  Repemail?: Maybe<Order_By>;
  Repfirstname?: Maybe<Order_By>;
  Replastname?: Maybe<Order_By>;
  Repmiddlename?: Maybe<Order_By>;
  Repnationality?: Maybe<Order_By>;
  Repothercountry?: Maybe<Order_By>;
  Repphone1?: Maybe<Order_By>;
  Reptitle?: Maybe<Order_By>;
  Reptitleother?: Maybe<Order_By>;
  Shopname?: Maybe<Order_By>;
  Shopnumber?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Title?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Typeofgoodssold?: Maybe<Order_By>;
  Uuidproperty?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rcapture_RPTtenants" */
export type Rtcs_Db_Rcapture_RpTtenants_Mutation_Response = {
  __typename?: "rtcs_db_rcapture_RPTtenants_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rcapture_RpTtenants>;
};

/** input type for inserting object relation for remote table "rtcs_db.rcapture_RPTtenants" */
export type Rtcs_Db_Rcapture_RpTtenants_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rcapture_RpTtenants_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rcapture_RPTtenants" */
export type Rtcs_Db_Rcapture_RpTtenants_Order_By = {
  Actualrentpaidpermonth?: Maybe<Order_By>;
  Amountofrentreceipts?: Maybe<Order_By>;
  Businesstradingname?: Maybe<Order_By>;
  Cactualrentpaidpermonth?: Maybe<Order_By>;
  Currencyactualrent?: Maybe<Order_By>;
  Currencyreportedrent?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  Estimatedshopsize?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Floornumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Identifier?: Maybe<Order_By>;
  Isunitlet?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastname?: Maybe<Order_By>;
  Legalbusinessname?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mda?: Maybe<Order_By>;
  Nationality?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Owneroccupiedorrented?: Maybe<Order_By>;
  Phone1?: Maybe<Order_By>;
  Phone2?: Maybe<Order_By>;
  Phone3?: Maybe<Order_By>;
  Phone4?: Maybe<Order_By>;
  Positionincompany?: Maybe<Order_By>;
  Registeredbusinessnumber?: Maybe<Order_By>;
  Rentreportedbylessorpermonth?: Maybe<Order_By>;
  Repemail?: Maybe<Order_By>;
  Repfirstname?: Maybe<Order_By>;
  Replastname?: Maybe<Order_By>;
  Repmiddlename?: Maybe<Order_By>;
  Repnationality?: Maybe<Order_By>;
  Repothercountry?: Maybe<Order_By>;
  Repphone1?: Maybe<Order_By>;
  Reptitle?: Maybe<Order_By>;
  Reptitleother?: Maybe<Order_By>;
  Shopname?: Maybe<Order_By>;
  Shopnumber?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Title?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Typeofgoodssold?: Maybe<Order_By>;
  Uuidproperty?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rcapture_RPTtenants" */
export enum Rtcs_Db_Rcapture_RpTtenants_Select_Column {
  /** column name */
  Actualrentpaidpermonth = "Actualrentpaidpermonth",
  /** column name */
  Amountofrentreceipts = "Amountofrentreceipts",
  /** column name */
  Businesstradingname = "Businesstradingname",
  /** column name */
  Cactualrentpaidpermonth = "Cactualrentpaidpermonth",
  /** column name */
  Currencyactualrent = "Currencyactualrent",
  /** column name */
  Currencyreportedrent = "Currencyreportedrent",
  /** column name */
  Email = "Email",
  /** column name */
  Estimatedshopsize = "Estimatedshopsize",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Floornumber = "Floornumber",
  /** column name */
  Id = "Id",
  /** column name */
  Identifier = "Identifier",
  /** column name */
  Isunitlet = "Isunitlet",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Lastname = "Lastname",
  /** column name */
  Legalbusinessname = "Legalbusinessname",
  /** column name */
  Line = "Line",
  /** column name */
  Mda = "Mda",
  /** column name */
  Nationality = "Nationality",
  /** column name */
  Notes = "Notes",
  /** column name */
  Numberbathrooms = "Numberbathrooms",
  /** column name */
  Numberbedrooms = "Numberbedrooms",
  /** column name */
  Owneroccupiedorrented = "Owneroccupiedorrented",
  /** column name */
  Phone1 = "Phone1",
  /** column name */
  Phone2 = "Phone2",
  /** column name */
  Phone3 = "Phone3",
  /** column name */
  Phone4 = "Phone4",
  /** column name */
  Positionincompany = "Positionincompany",
  /** column name */
  Registeredbusinessnumber = "Registeredbusinessnumber",
  /** column name */
  Rentreportedbylessorpermonth = "Rentreportedbylessorpermonth",
  /** column name */
  Repemail = "Repemail",
  /** column name */
  Repfirstname = "Repfirstname",
  /** column name */
  Replastname = "Replastname",
  /** column name */
  Repmiddlename = "Repmiddlename",
  /** column name */
  Repnationality = "Repnationality",
  /** column name */
  Repothercountry = "Repothercountry",
  /** column name */
  Repphone1 = "Repphone1",
  /** column name */
  Reptitle = "Reptitle",
  /** column name */
  Reptitleother = "Reptitleother",
  /** column name */
  Shopname = "Shopname",
  /** column name */
  Shopnumber = "Shopnumber",
  /** column name */
  Tin = "Tin",
  /** column name */
  Title = "Title",
  /** column name */
  Type = "Type",
  /** column name */
  Typeofgoodssold = "Typeofgoodssold",
  /** column name */
  Uuidproperty = "Uuidproperty",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rcapture_RPTtenants" */
export type Rtcs_Db_Rcapture_RpTtenants_Set_Input = {
  Actualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Amountofrentreceipts?: Maybe<Scalars["float8"]>;
  Businesstradingname?: Maybe<Scalars["String"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Currencyactualrent?: Maybe<Scalars["String"]>;
  Currencyreportedrent?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Estimatedshopsize?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Floornumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  Identifier?: Maybe<Scalars["String"]>;
  Isunitlet?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalbusinessname?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mda?: Maybe<Scalars["String"]>;
  Nationality?: Maybe<Scalars["String"]>;
  Notes?: Maybe<Scalars["String"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Owneroccupiedorrented?: Maybe<Scalars["String"]>;
  Phone1?: Maybe<Scalars["String"]>;
  Phone2?: Maybe<Scalars["String"]>;
  Phone3?: Maybe<Scalars["String"]>;
  Phone4?: Maybe<Scalars["String"]>;
  Positionincompany?: Maybe<Scalars["String"]>;
  Registeredbusinessnumber?: Maybe<Scalars["String"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["float8"]>;
  Repemail?: Maybe<Scalars["String"]>;
  Repfirstname?: Maybe<Scalars["String"]>;
  Replastname?: Maybe<Scalars["String"]>;
  Repmiddlename?: Maybe<Scalars["String"]>;
  Repnationality?: Maybe<Scalars["String"]>;
  Repothercountry?: Maybe<Scalars["String"]>;
  Repphone1?: Maybe<Scalars["String"]>;
  Reptitle?: Maybe<Scalars["String"]>;
  Reptitleother?: Maybe<Scalars["String"]>;
  Shopname?: Maybe<Scalars["String"]>;
  Shopnumber?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["float8"]>;
  Title?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  Typeofgoodssold?: Maybe<Scalars["String"]>;
  Uuidproperty?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rcapture_RpTtenants_Stddev_Fields = {
  __typename?: "rtcs_db_rcapture_RPTtenants_stddev_fields";
  Actualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Amountofrentreceipts?: Maybe<Scalars["Float"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rcapture_RPTtenants" */
export type Rtcs_Db_Rcapture_RpTtenants_Stddev_Order_By = {
  Actualrentpaidpermonth?: Maybe<Order_By>;
  Amountofrentreceipts?: Maybe<Order_By>;
  Cactualrentpaidpermonth?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Rentreportedbylessorpermonth?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rcapture_RpTtenants_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rcapture_RPTtenants_stddev_pop_fields";
  Actualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Amountofrentreceipts?: Maybe<Scalars["Float"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rcapture_RPTtenants" */
export type Rtcs_Db_Rcapture_RpTtenants_Stddev_Pop_Order_By = {
  Actualrentpaidpermonth?: Maybe<Order_By>;
  Amountofrentreceipts?: Maybe<Order_By>;
  Cactualrentpaidpermonth?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Rentreportedbylessorpermonth?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rcapture_RpTtenants_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rcapture_RPTtenants_stddev_samp_fields";
  Actualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Amountofrentreceipts?: Maybe<Scalars["Float"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rcapture_RPTtenants" */
export type Rtcs_Db_Rcapture_RpTtenants_Stddev_Samp_Order_By = {
  Actualrentpaidpermonth?: Maybe<Order_By>;
  Amountofrentreceipts?: Maybe<Order_By>;
  Cactualrentpaidpermonth?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Rentreportedbylessorpermonth?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rcapture_RpTtenants_Sum_Fields = {
  __typename?: "rtcs_db_rcapture_RPTtenants_sum_fields";
  Actualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Amountofrentreceipts?: Maybe<Scalars["float8"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Numberbathrooms?: Maybe<Scalars["bigint"]>;
  Numberbedrooms?: Maybe<Scalars["bigint"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["float8"]>;
  Tin?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rcapture_RPTtenants" */
export type Rtcs_Db_Rcapture_RpTtenants_Sum_Order_By = {
  Actualrentpaidpermonth?: Maybe<Order_By>;
  Amountofrentreceipts?: Maybe<Order_By>;
  Cactualrentpaidpermonth?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Rentreportedbylessorpermonth?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rcapture_RpTtenants_Var_Pop_Fields = {
  __typename?: "rtcs_db_rcapture_RPTtenants_var_pop_fields";
  Actualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Amountofrentreceipts?: Maybe<Scalars["Float"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rcapture_RPTtenants" */
export type Rtcs_Db_Rcapture_RpTtenants_Var_Pop_Order_By = {
  Actualrentpaidpermonth?: Maybe<Order_By>;
  Amountofrentreceipts?: Maybe<Order_By>;
  Cactualrentpaidpermonth?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Rentreportedbylessorpermonth?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rcapture_RpTtenants_Var_Samp_Fields = {
  __typename?: "rtcs_db_rcapture_RPTtenants_var_samp_fields";
  Actualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Amountofrentreceipts?: Maybe<Scalars["Float"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rcapture_RPTtenants" */
export type Rtcs_Db_Rcapture_RpTtenants_Var_Samp_Order_By = {
  Actualrentpaidpermonth?: Maybe<Order_By>;
  Amountofrentreceipts?: Maybe<Order_By>;
  Cactualrentpaidpermonth?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Rentreportedbylessorpermonth?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rcapture_RpTtenants_Variance_Fields = {
  __typename?: "rtcs_db_rcapture_RPTtenants_variance_fields";
  Actualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Amountofrentreceipts?: Maybe<Scalars["Float"]>;
  Cactualrentpaidpermonth?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Numberbathrooms?: Maybe<Scalars["Float"]>;
  Numberbedrooms?: Maybe<Scalars["Float"]>;
  Rentreportedbylessorpermonth?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rcapture_RPTtenants" */
export type Rtcs_Db_Rcapture_RpTtenants_Variance_Order_By = {
  Actualrentpaidpermonth?: Maybe<Order_By>;
  Amountofrentreceipts?: Maybe<Order_By>;
  Cactualrentpaidpermonth?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Numberbathrooms?: Maybe<Order_By>;
  Numberbedrooms?: Maybe<Order_By>;
  Rentreportedbylessorpermonth?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_Individual" */
export type Rtcs_Db_Rmatch_Individual = {
  __typename?: "rtcs_db_rmatch_Individual";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport = {
  __typename?: "rtcs_db_rmatch_IndividualComplianceDetailReport";
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Createddate?: Maybe<Scalars["timestamptz"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  District?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Houseno?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Ownerfirstname?: Maybe<Scalars["String"]>;
  Ownerlegalsurnamemaiden?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Propertyrnid?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Rnidowner?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytype?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Aggregate = {
  __typename?: "rtcs_db_rmatch_IndividualComplianceDetailReport_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_IndividualComplianceDetailReport>;
};

/** aggregate fields of "rtcs_db.rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualComplianceDetailReport_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Aggregate_Order_By =
  {
    avg?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Max_Order_By>;
    min?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Min_Order_By>;
    stddev?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Stddev_Order_By>;
    stddev_pop?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Stddev_Samp_Order_By>;
    sum?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Sum_Order_By>;
    var_pop?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Var_Pop_Order_By>;
    var_samp?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Var_Samp_Order_By>;
    variance?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Variance_Order_By>;
  };

/** input type for inserting array relation for remote table "rtcs_db.rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualComplianceDetailReport_avg_fields";
  Currentrateablevalue?: Maybe<Scalars["Float"]>;
  Customermatchscore?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Avg_Order_By = {
  Currentrateablevalue?: Maybe<Order_By>;
  Customermatchscore?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_IndividualComplianceDetailReport". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  Blockno?: Maybe<String_Comparison_Exp>;
  Createddate?: Maybe<Timestamptz_Comparison_Exp>;
  Currentrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Customerid?: Maybe<String_Comparison_Exp>;
  Customermatchscore?: Maybe<Bigint_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  Division?: Maybe<String_Comparison_Exp>;
  Emailid?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Flatno?: Maybe<String_Comparison_Exp>;
  Fullname?: Maybe<String_Comparison_Exp>;
  Grossrentvalueadjusted?: Maybe<Float8_Comparison_Exp>;
  Grossvalue?: Maybe<Float8_Comparison_Exp>;
  Houseno?: Maybe<String_Comparison_Exp>;
  Isincomesrcbsns?: Maybe<String_Comparison_Exp>;
  Isincomesrcemp?: Maybe<String_Comparison_Exp>;
  Isincomesrcprpty?: Maybe<String_Comparison_Exp>;
  Isincomesrcrental?: Maybe<String_Comparison_Exp>;
  Kccabirthdate?: Maybe<Timestamptz_Comparison_Exp>;
  Kccaemail?: Maybe<String_Comparison_Exp>;
  Kccafirstname?: Maybe<String_Comparison_Exp>;
  Kccamiddlename?: Maybe<String_Comparison_Exp>;
  Kccamobile1?: Maybe<String_Comparison_Exp>;
  Kccamobile2?: Maybe<String_Comparison_Exp>;
  Kccasurname?: Maybe<String_Comparison_Exp>;
  Kccatelephone?: Maybe<String_Comparison_Exp>;
  Kccatin?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  Maptitle?: Maybe<String_Comparison_Exp>;
  Matchscore?: Maybe<Float8_Comparison_Exp>;
  Mobilenumber?: Maybe<String_Comparison_Exp>;
  Ownerfirstname?: Maybe<String_Comparison_Exp>;
  Ownerlegalsurnamemaiden?: Maybe<String_Comparison_Exp>;
  Parish?: Maybe<String_Comparison_Exp>;
  Plotno?: Maybe<String_Comparison_Exp>;
  Propertyno?: Maybe<String_Comparison_Exp>;
  Propertyrentedstatus?: Maybe<String_Comparison_Exp>;
  Propertyrnid?: Maybe<String_Comparison_Exp>;
  Propertytype?: Maybe<String_Comparison_Exp>;
  Rateablevalue?: Maybe<Float8_Comparison_Exp>;
  Rnidcustomer?: Maybe<String_Comparison_Exp>;
  Rnidowner?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  Streetid?: Maybe<String_Comparison_Exp>;
  Subpropertytype?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Urafirstname?: Maybe<String_Comparison_Exp>;
  Uramiddlename?: Maybe<String_Comparison_Exp>;
  Urasurname?: Maybe<String_Comparison_Exp>;
  Uratinno?: Maybe<String_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Inc_Input = {
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Createddate?: Maybe<Scalars["timestamptz"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  District?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Houseno?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Ownerfirstname?: Maybe<Scalars["String"]>;
  Ownerlegalsurnamemaiden?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Propertyrnid?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Rnidowner?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytype?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Max_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualComplianceDetailReport_max_fields";
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Createddate?: Maybe<Scalars["timestamptz"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  District?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Houseno?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Ownerfirstname?: Maybe<Scalars["String"]>;
  Ownerlegalsurnamemaiden?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Propertyrnid?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Rnidowner?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytype?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Max_Order_By = {
  Address?: Maybe<Order_By>;
  Blockno?: Maybe<Order_By>;
  Createddate?: Maybe<Order_By>;
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Customermatchscore?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Houseno?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccabirthdate?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Maptitle?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Ownerfirstname?: Maybe<Order_By>;
  Ownerlegalsurnamemaiden?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  Propertyrnid?: Maybe<Order_By>;
  Propertytype?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rnidcustomer?: Maybe<Order_By>;
  Rnidowner?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subpropertytype?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramiddlename?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Min_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualComplianceDetailReport_min_fields";
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Createddate?: Maybe<Scalars["timestamptz"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  District?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Houseno?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Ownerfirstname?: Maybe<Scalars["String"]>;
  Ownerlegalsurnamemaiden?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Propertyrnid?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Rnidowner?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytype?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Min_Order_By = {
  Address?: Maybe<Order_By>;
  Blockno?: Maybe<Order_By>;
  Createddate?: Maybe<Order_By>;
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Customermatchscore?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Houseno?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccabirthdate?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Maptitle?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Ownerfirstname?: Maybe<Order_By>;
  Ownerlegalsurnamemaiden?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  Propertyrnid?: Maybe<Order_By>;
  Propertytype?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rnidcustomer?: Maybe<Order_By>;
  Rnidowner?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subpropertytype?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramiddlename?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Mutation_Response =
  {
    __typename?: "rtcs_db_rmatch_IndividualComplianceDetailReport_mutation_response";
    /** number of affected rows by the mutation */
    affected_rows: Scalars["Int"];
    /** data of the affected rows by the mutation */
    returning: Array<Rtcs_Db_Rmatch_IndividualComplianceDetailReport>;
  };

/** input type for inserting object relation for remote table "rtcs_db.rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Order_By = {
  Address?: Maybe<Order_By>;
  Blockno?: Maybe<Order_By>;
  Createddate?: Maybe<Order_By>;
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Customermatchscore?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Houseno?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccabirthdate?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Maptitle?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Ownerfirstname?: Maybe<Order_By>;
  Ownerlegalsurnamemaiden?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  Propertyrnid?: Maybe<Order_By>;
  Propertytype?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rnidcustomer?: Maybe<Order_By>;
  Rnidowner?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subpropertytype?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramiddlename?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_IndividualComplianceDetailReport" */
export enum Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  Blockno = "Blockno",
  /** column name */
  Createddate = "Createddate",
  /** column name */
  Currentrateablevalue = "Currentrateablevalue",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  Customermatchscore = "Customermatchscore",
  /** column name */
  District = "District",
  /** column name */
  Division = "Division",
  /** column name */
  Emailid = "Emailid",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Flatno = "Flatno",
  /** column name */
  Fullname = "Fullname",
  /** column name */
  Grossrentvalueadjusted = "Grossrentvalueadjusted",
  /** column name */
  Grossvalue = "Grossvalue",
  /** column name */
  Houseno = "Houseno",
  /** column name */
  Isincomesrcbsns = "Isincomesrcbsns",
  /** column name */
  Isincomesrcemp = "Isincomesrcemp",
  /** column name */
  Isincomesrcprpty = "Isincomesrcprpty",
  /** column name */
  Isincomesrcrental = "Isincomesrcrental",
  /** column name */
  Kccabirthdate = "Kccabirthdate",
  /** column name */
  Kccaemail = "Kccaemail",
  /** column name */
  Kccafirstname = "Kccafirstname",
  /** column name */
  Kccamiddlename = "Kccamiddlename",
  /** column name */
  Kccamobile1 = "Kccamobile1",
  /** column name */
  Kccamobile2 = "Kccamobile2",
  /** column name */
  Kccasurname = "Kccasurname",
  /** column name */
  Kccatelephone = "Kccatelephone",
  /** column name */
  Kccatin = "Kccatin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  Maptitle = "Maptitle",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Mobilenumber = "Mobilenumber",
  /** column name */
  Ownerfirstname = "Ownerfirstname",
  /** column name */
  Ownerlegalsurnamemaiden = "Ownerlegalsurnamemaiden",
  /** column name */
  Parish = "Parish",
  /** column name */
  Plotno = "Plotno",
  /** column name */
  Propertyno = "Propertyno",
  /** column name */
  Propertyrentedstatus = "Propertyrentedstatus",
  /** column name */
  Propertyrnid = "Propertyrnid",
  /** column name */
  Propertytype = "Propertytype",
  /** column name */
  Rateablevalue = "Rateablevalue",
  /** column name */
  Rnidcustomer = "Rnidcustomer",
  /** column name */
  Rnidowner = "Rnidowner",
  /** column name */
  Source = "Source",
  /** column name */
  Streetid = "Streetid",
  /** column name */
  Subpropertytype = "Subpropertytype",
  /** column name */
  Surname = "Surname",
  /** column name */
  Urafirstname = "Urafirstname",
  /** column name */
  Uramiddlename = "Uramiddlename",
  /** column name */
  Urasurname = "Urasurname",
  /** column name */
  Uratinno = "Uratinno",
  /** column name */
  Village = "Village",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Createddate?: Maybe<Scalars["timestamptz"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  District?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Houseno?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Ownerfirstname?: Maybe<Scalars["String"]>;
  Ownerlegalsurnamemaiden?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Propertyrnid?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Rnidowner?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytype?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualComplianceDetailReport_stddev_fields";
  Currentrateablevalue?: Maybe<Scalars["Float"]>;
  Customermatchscore?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Stddev_Order_By = {
  Currentrateablevalue?: Maybe<Order_By>;
  Customermatchscore?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Stddev_Pop_Fields =
  {
    __typename?: "rtcs_db_rmatch_IndividualComplianceDetailReport_stddev_pop_fields";
    Currentrateablevalue?: Maybe<Scalars["Float"]>;
    Customermatchscore?: Maybe<Scalars["Float"]>;
    Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
    Grossvalue?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Rateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Stddev_Pop_Order_By =
  {
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
  };

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Stddev_Samp_Fields =
  {
    __typename?: "rtcs_db_rmatch_IndividualComplianceDetailReport_stddev_samp_fields";
    Currentrateablevalue?: Maybe<Scalars["Float"]>;
    Customermatchscore?: Maybe<Scalars["Float"]>;
    Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
    Grossvalue?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Rateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Stddev_Samp_Order_By =
  {
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualComplianceDetailReport_sum_fields";
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Sum_Order_By = {
  Currentrateablevalue?: Maybe<Order_By>;
  Customermatchscore?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualComplianceDetailReport_var_pop_fields";
  Currentrateablevalue?: Maybe<Scalars["Float"]>;
  Customermatchscore?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Var_Pop_Order_By = {
  Currentrateablevalue?: Maybe<Order_By>;
  Customermatchscore?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualComplianceDetailReport_var_samp_fields";
  Currentrateablevalue?: Maybe<Scalars["Float"]>;
  Customermatchscore?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Var_Samp_Order_By =
  {
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
  };

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualComplianceDetailReport_variance_fields";
  Currentrateablevalue?: Maybe<Scalars["Float"]>;
  Customermatchscore?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_IndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Variance_Order_By =
  {
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
  };

/** columns and relationships of "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport = {
  __typename?: "rtcs_db_rmatch_IndividualComplianceSummaryReport";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Returntype?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Aggregate = {
  __typename?: "rtcs_db_rmatch_IndividualComplianceSummaryReport_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport>;
};

/** aggregate fields of "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Aggregate_Fields =
  {
    __typename?: "rtcs_db_rmatch_IndividualComplianceSummaryReport_aggregate_fields";
    avg?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Avg_Fields>;
    count?: Maybe<Scalars["Int"]>;
    max?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Max_Fields>;
    min?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Min_Fields>;
    stddev?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Stddev_Fields>;
    stddev_pop?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Stddev_Samp_Fields>;
    sum?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Sum_Fields>;
    var_pop?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Var_Pop_Fields>;
    var_samp?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Var_Samp_Fields>;
    variance?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Variance_Fields>;
  };

/** aggregate fields of "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Aggregate_Order_By =
  {
    avg?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Max_Order_By>;
    min?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Min_Order_By>;
    stddev?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Stddev_Order_By>;
    stddev_pop?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Stddev_Samp_Order_By>;
    sum?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Sum_Order_By>;
    var_pop?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Var_Pop_Order_By>;
    var_samp?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Var_Samp_Order_By>;
    variance?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Variance_Order_By>;
  };

/** input type for inserting array relation for remote table "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualComplianceSummaryReport_avg_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Avg_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_IndividualComplianceSummaryReport". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Bool_Exp = {
  Averagerateperrentedproperty?: Maybe<Float8_Comparison_Exp>;
  Compliancecategory?: Maybe<String_Comparison_Exp>;
  Countura?: Maybe<String_Comparison_Exp>;
  Customerid?: Maybe<String_Comparison_Exp>;
  Difference?: Maybe<Float8_Comparison_Exp>;
  Emailid?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Fullname?: Maybe<String_Comparison_Exp>;
  Grossrentalincome?: Maybe<Float8_Comparison_Exp>;
  Isamendment?: Maybe<String_Comparison_Exp>;
  Isincomesrcbsns?: Maybe<String_Comparison_Exp>;
  Isincomesrcemp?: Maybe<String_Comparison_Exp>;
  Isincomesrcprpty?: Maybe<String_Comparison_Exp>;
  Isincomesrcrental?: Maybe<String_Comparison_Exp>;
  Kccabirthdate?: Maybe<Timestamptz_Comparison_Exp>;
  Kccaemail?: Maybe<String_Comparison_Exp>;
  Kccafirstname?: Maybe<String_Comparison_Exp>;
  Kccamiddlename?: Maybe<String_Comparison_Exp>;
  Kccamobile1?: Maybe<String_Comparison_Exp>;
  Kccamobile2?: Maybe<String_Comparison_Exp>;
  Kccasurname?: Maybe<String_Comparison_Exp>;
  Kccatelephone?: Maybe<String_Comparison_Exp>;
  Kccatin?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Matchcategory?: Maybe<String_Comparison_Exp>;
  Matchscore?: Maybe<Float8_Comparison_Exp>;
  Matchscoreattributes?: Maybe<String_Comparison_Exp>;
  Mobilenumber?: Maybe<String_Comparison_Exp>;
  Nationalid?: Maybe<String_Comparison_Exp>;
  Numproperties?: Maybe<Bigint_Comparison_Exp>;
  Numrentedproperties?: Maybe<Bigint_Comparison_Exp>;
  Percentagedifference?: Maybe<Float8_Comparison_Exp>;
  Propincincome?: Maybe<Float8_Comparison_Exp>;
  Returntype?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Rtnno?: Maybe<String_Comparison_Exp>;
  Rtnperiodyear?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  Sumadjustedincome?: Maybe<Float8_Comparison_Exp>;
  Sumgrossvalue?: Maybe<Float8_Comparison_Exp>;
  Sumnonrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Sumrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Urafirstname?: Maybe<String_Comparison_Exp>;
  Uramiddlename?: Maybe<String_Comparison_Exp>;
  Urasurname?: Maybe<String_Comparison_Exp>;
  Uratinno?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Inc_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Insert_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Returntype?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Max_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualComplianceSummaryReport_max_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Returntype?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Max_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Countura?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccabirthdate?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchcategory?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Returntype?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramiddlename?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Min_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualComplianceSummaryReport_min_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Returntype?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Min_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Countura?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccabirthdate?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchcategory?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Returntype?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramiddlename?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Mutation_Response =
  {
    __typename?: "rtcs_db_rmatch_IndividualComplianceSummaryReport_mutation_response";
    /** number of affected rows by the mutation */
    affected_rows: Scalars["Int"];
    /** data of the affected rows by the mutation */
    returning: Array<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport>;
  };

/** input type for inserting object relation for remote table "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Countura?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccabirthdate?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchcategory?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Returntype?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramiddlename?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
export enum Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Select_Column {
  /** column name */
  Averagerateperrentedproperty = "Averagerateperrentedproperty",
  /** column name */
  Compliancecategory = "Compliancecategory",
  /** column name */
  Countura = "Countura",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  Difference = "Difference",
  /** column name */
  Emailid = "Emailid",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Fullname = "Fullname",
  /** column name */
  Grossrentalincome = "Grossrentalincome",
  /** column name */
  Isamendment = "Isamendment",
  /** column name */
  Isincomesrcbsns = "Isincomesrcbsns",
  /** column name */
  Isincomesrcemp = "Isincomesrcemp",
  /** column name */
  Isincomesrcprpty = "Isincomesrcprpty",
  /** column name */
  Isincomesrcrental = "Isincomesrcrental",
  /** column name */
  Kccabirthdate = "Kccabirthdate",
  /** column name */
  Kccaemail = "Kccaemail",
  /** column name */
  Kccafirstname = "Kccafirstname",
  /** column name */
  Kccamiddlename = "Kccamiddlename",
  /** column name */
  Kccamobile1 = "Kccamobile1",
  /** column name */
  Kccamobile2 = "Kccamobile2",
  /** column name */
  Kccasurname = "Kccasurname",
  /** column name */
  Kccatelephone = "Kccatelephone",
  /** column name */
  Kccatin = "Kccatin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Matchcategory = "Matchcategory",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Matchscoreattributes = "Matchscoreattributes",
  /** column name */
  Mobilenumber = "Mobilenumber",
  /** column name */
  Nationalid = "Nationalid",
  /** column name */
  Numproperties = "Numproperties",
  /** column name */
  Numrentedproperties = "Numrentedproperties",
  /** column name */
  Percentagedifference = "Percentagedifference",
  /** column name */
  Propincincome = "Propincincome",
  /** column name */
  Returntype = "Returntype",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Rtnno = "Rtnno",
  /** column name */
  Rtnperiodyear = "Rtnperiodyear",
  /** column name */
  Source = "Source",
  /** column name */
  Sumadjustedincome = "Sumadjustedincome",
  /** column name */
  Sumgrossvalue = "Sumgrossvalue",
  /** column name */
  Sumnonrateablevalue = "Sumnonrateablevalue",
  /** column name */
  Sumrateablevalue = "Sumrateablevalue",
  /** column name */
  Surname = "Surname",
  /** column name */
  Urafirstname = "Urafirstname",
  /** column name */
  Uramiddlename = "Uramiddlename",
  /** column name */
  Urasurname = "Urasurname",
  /** column name */
  Uratinno = "Uratinno",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Set_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Returntype?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualComplianceSummaryReport_stddev_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Stddev_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Stddev_Pop_Fields =
  {
    __typename?: "rtcs_db_rmatch_IndividualComplianceSummaryReport_stddev_pop_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Propincincome?: Maybe<Scalars["Float"]>;
    Sumadjustedincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Stddev_Pop_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Propincincome?: Maybe<Order_By>;
    Sumadjustedincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumnonrateablevalue?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
  };

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Stddev_Samp_Fields =
  {
    __typename?: "rtcs_db_rmatch_IndividualComplianceSummaryReport_stddev_samp_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Propincincome?: Maybe<Scalars["Float"]>;
    Sumadjustedincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Stddev_Samp_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Propincincome?: Maybe<Order_By>;
    Sumadjustedincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumnonrateablevalue?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualComplianceSummaryReport_sum_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Sum_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualComplianceSummaryReport_var_pop_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Var_Pop_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Propincincome?: Maybe<Order_By>;
    Sumadjustedincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumnonrateablevalue?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
  };

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualComplianceSummaryReport_var_samp_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Var_Samp_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Propincincome?: Maybe<Order_By>;
    Sumadjustedincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumnonrateablevalue?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
  };

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualComplianceSummaryReport_variance_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Variance_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Propincincome?: Maybe<Order_By>;
    Sumadjustedincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumnonrateablevalue?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
  };

/** columns and relationships of "rtcs_db.rmatch_IndividualRiskScore" */
export type Rtcs_Db_Rmatch_IndividualRiskScore = {
  __typename?: "rtcs_db_rmatch_IndividualRiskScore";
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["String"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentiledifferencezscore?: Maybe<Scalars["String"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Riskcode?: Maybe<Scalars["String"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_IndividualRiskScore" */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Aggregate = {
  __typename?: "rtcs_db_rmatch_IndividualRiskScore_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_IndividualRiskScore>;
};

/** aggregate fields of "rtcs_db.rmatch_IndividualRiskScore" */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualRiskScore_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_IndividualRiskScore" */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_IndividualRiskScore_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_IndividualRiskScore" */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_IndividualRiskScore" */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_IndividualRiskScore_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualRiskScore_avg_fields";
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_IndividualRiskScore" */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Avg_Order_By = {
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_IndividualRiskScore". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Bool_Exp = {
  Avgadjustedgrossincomeperproperty?: Maybe<Float8_Comparison_Exp>;
  Compliancecategory?: Maybe<String_Comparison_Exp>;
  Countura?: Maybe<String_Comparison_Exp>;
  Customerid?: Maybe<String_Comparison_Exp>;
  Difference?: Maybe<Float8_Comparison_Exp>;
  Emailid?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Fullname?: Maybe<String_Comparison_Exp>;
  Grossrentalincome?: Maybe<Float8_Comparison_Exp>;
  Isamendment?: Maybe<String_Comparison_Exp>;
  Isincomesrcbsns?: Maybe<String_Comparison_Exp>;
  Isincomesrcemp?: Maybe<String_Comparison_Exp>;
  Isincomesrcprpty?: Maybe<String_Comparison_Exp>;
  Isincomesrcrental?: Maybe<String_Comparison_Exp>;
  Kccabirthdate?: Maybe<Timestamptz_Comparison_Exp>;
  Kccaemail?: Maybe<String_Comparison_Exp>;
  Kccafirstname?: Maybe<String_Comparison_Exp>;
  Kccamiddlename?: Maybe<String_Comparison_Exp>;
  Kccamobile1?: Maybe<String_Comparison_Exp>;
  Kccamobile2?: Maybe<String_Comparison_Exp>;
  Kccasurname?: Maybe<String_Comparison_Exp>;
  Kccatelephone?: Maybe<String_Comparison_Exp>;
  Kccatin?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Matchscore?: Maybe<Float8_Comparison_Exp>;
  Matchscoreattributes?: Maybe<String_Comparison_Exp>;
  Mobilenumber?: Maybe<String_Comparison_Exp>;
  Nationalid?: Maybe<String_Comparison_Exp>;
  Numproperties?: Maybe<Bigint_Comparison_Exp>;
  Numrentedproperties?: Maybe<Bigint_Comparison_Exp>;
  Percentagedifference?: Maybe<Float8_Comparison_Exp>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Float8_Comparison_Exp>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Float8_Comparison_Exp>;
  Percentileavgperproperty?: Maybe<Float8_Comparison_Exp>;
  Percentileavgperpropertyzscore?: Maybe<String_Comparison_Exp>;
  Percentiledifference?: Maybe<Bigint_Comparison_Exp>;
  Percentiledifferenceavg?: Maybe<Float8_Comparison_Exp>;
  Percentiledifferenceavg1?: Maybe<Float8_Comparison_Exp>;
  Percentiledifferencestandarddeviation?: Maybe<Float8_Comparison_Exp>;
  Percentiledifferencezscore?: Maybe<String_Comparison_Exp>;
  Percentilenumrentedpropertiesavg?: Maybe<Float8_Comparison_Exp>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Float8_Comparison_Exp>;
  Percentilepropertyrented?: Maybe<Bigint_Comparison_Exp>;
  Percentilepropertyrentedzscore?: Maybe<Float8_Comparison_Exp>;
  Propincincome?: Maybe<Float8_Comparison_Exp>;
  Riskcode?: Maybe<String_Comparison_Exp>;
  Riskscore?: Maybe<Bigint_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Rtnno?: Maybe<String_Comparison_Exp>;
  Rtnperiodyear?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  Sumadjustedincome?: Maybe<Float8_Comparison_Exp>;
  Sumgrossvalue?: Maybe<Float8_Comparison_Exp>;
  Sumnonrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Sumrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Urafirstname?: Maybe<String_Comparison_Exp>;
  Uramiddlename?: Maybe<String_Comparison_Exp>;
  Urasurname?: Maybe<String_Comparison_Exp>;
  Uratinno?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_IndividualRiskScore" */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Inc_Input = {
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_IndividualRiskScore" */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Insert_Input = {
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["String"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentiledifferencezscore?: Maybe<Scalars["String"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Riskcode?: Maybe<Scalars["String"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Max_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualRiskScore_max_fields";
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["String"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentiledifferencezscore?: Maybe<Scalars["String"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Riskcode?: Maybe<Scalars["String"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_IndividualRiskScore" */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Max_Order_By = {
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Countura?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccabirthdate?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Riskcode?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramiddlename?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Min_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualRiskScore_min_fields";
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["String"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentiledifferencezscore?: Maybe<Scalars["String"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Riskcode?: Maybe<Scalars["String"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_IndividualRiskScore" */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Min_Order_By = {
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Countura?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccabirthdate?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Riskcode?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramiddlename?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_IndividualRiskScore" */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_IndividualRiskScore_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_IndividualRiskScore>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_IndividualRiskScore" */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_IndividualRiskScore_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_IndividualRiskScore" */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Order_By = {
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Countura?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccabirthdate?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatelephone?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Nationalid?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Riskcode?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramiddlename?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_IndividualRiskScore" */
export enum Rtcs_Db_Rmatch_IndividualRiskScore_Select_Column {
  /** column name */
  Avgadjustedgrossincomeperproperty = "Avgadjustedgrossincomeperproperty",
  /** column name */
  Compliancecategory = "Compliancecategory",
  /** column name */
  Countura = "Countura",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  Difference = "Difference",
  /** column name */
  Emailid = "Emailid",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Fullname = "Fullname",
  /** column name */
  Grossrentalincome = "Grossrentalincome",
  /** column name */
  Isamendment = "Isamendment",
  /** column name */
  Isincomesrcbsns = "Isincomesrcbsns",
  /** column name */
  Isincomesrcemp = "Isincomesrcemp",
  /** column name */
  Isincomesrcprpty = "Isincomesrcprpty",
  /** column name */
  Isincomesrcrental = "Isincomesrcrental",
  /** column name */
  Kccabirthdate = "Kccabirthdate",
  /** column name */
  Kccaemail = "Kccaemail",
  /** column name */
  Kccafirstname = "Kccafirstname",
  /** column name */
  Kccamiddlename = "Kccamiddlename",
  /** column name */
  Kccamobile1 = "Kccamobile1",
  /** column name */
  Kccamobile2 = "Kccamobile2",
  /** column name */
  Kccasurname = "Kccasurname",
  /** column name */
  Kccatelephone = "Kccatelephone",
  /** column name */
  Kccatin = "Kccatin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Matchscoreattributes = "Matchscoreattributes",
  /** column name */
  Mobilenumber = "Mobilenumber",
  /** column name */
  Nationalid = "Nationalid",
  /** column name */
  Numproperties = "Numproperties",
  /** column name */
  Numrentedproperties = "Numrentedproperties",
  /** column name */
  Percentagedifference = "Percentagedifference",
  /** column name */
  Percentileavgadjustedgrossincomeperpropertyavg = "Percentileavgadjustedgrossincomeperpropertyavg",
  /** column name */
  Percentileavgadjustedgrossincomeperpropertystandarddeviation = "Percentileavgadjustedgrossincomeperpropertystandarddeviation",
  /** column name */
  Percentileavgperproperty = "Percentileavgperproperty",
  /** column name */
  Percentileavgperpropertyzscore = "Percentileavgperpropertyzscore",
  /** column name */
  Percentiledifference = "Percentiledifference",
  /** column name */
  Percentiledifferenceavg = "Percentiledifferenceavg",
  /** column name */
  Percentiledifferenceavg1 = "Percentiledifferenceavg1",
  /** column name */
  Percentiledifferencestandarddeviation = "Percentiledifferencestandarddeviation",
  /** column name */
  Percentiledifferencezscore = "Percentiledifferencezscore",
  /** column name */
  Percentilenumrentedpropertiesavg = "Percentilenumrentedpropertiesavg",
  /** column name */
  Percentilenumrentedpropertiesstandarddeviation = "Percentilenumrentedpropertiesstandarddeviation",
  /** column name */
  Percentilepropertyrented = "Percentilepropertyrented",
  /** column name */
  Percentilepropertyrentedzscore = "Percentilepropertyrentedzscore",
  /** column name */
  Propincincome = "Propincincome",
  /** column name */
  Riskcode = "Riskcode",
  /** column name */
  Riskscore = "Riskscore",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Rtnno = "Rtnno",
  /** column name */
  Rtnperiodyear = "Rtnperiodyear",
  /** column name */
  Source = "Source",
  /** column name */
  Sumadjustedincome = "Sumadjustedincome",
  /** column name */
  Sumgrossvalue = "Sumgrossvalue",
  /** column name */
  Sumnonrateablevalue = "Sumnonrateablevalue",
  /** column name */
  Sumrateablevalue = "Sumrateablevalue",
  /** column name */
  Surname = "Surname",
  /** column name */
  Urafirstname = "Urafirstname",
  /** column name */
  Uramiddlename = "Uramiddlename",
  /** column name */
  Urasurname = "Urasurname",
  /** column name */
  Uratinno = "Uratinno",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_IndividualRiskScore" */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Set_Input = {
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Countura?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccabirthdate?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatelephone?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Nationalid?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["String"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentiledifferencezscore?: Maybe<Scalars["String"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Riskcode?: Maybe<Scalars["String"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramiddlename?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualRiskScore_stddev_fields";
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_IndividualRiskScore" */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Stddev_Order_By = {
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualRiskScore_stddev_pop_fields";
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_IndividualRiskScore" */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Stddev_Pop_Order_By = {
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualRiskScore_stddev_samp_fields";
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_IndividualRiskScore" */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Stddev_Samp_Order_By = {
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualRiskScore_sum_fields";
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Propincincome?: Maybe<Scalars["float8"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_IndividualRiskScore" */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Sum_Order_By = {
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualRiskScore_var_pop_fields";
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_IndividualRiskScore" */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Var_Pop_Order_By = {
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualRiskScore_var_samp_fields";
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_IndividualRiskScore" */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Var_Samp_Order_By = {
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualRiskScore_variance_fields";
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Propincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_IndividualRiskScore" */
export type Rtcs_Db_Rmatch_IndividualRiskScore_Variance_Order_By = {
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Propincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregated selection of "rtcs_db.rmatch_Individual" */
export type Rtcs_Db_Rmatch_Individual_Aggregate = {
  __typename?: "rtcs_db_rmatch_Individual_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_Individual_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_Individual>;
};

/** aggregate fields of "rtcs_db.rmatch_Individual" */
export type Rtcs_Db_Rmatch_Individual_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_Individual_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_Individual_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_Individual_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_Individual_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_Individual_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_Individual_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_Individual_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_Individual_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_Individual_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_Individual_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_Individual_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_Individual" */
export type Rtcs_Db_Rmatch_Individual_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_Individual_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_Individual" */
export type Rtcs_Db_Rmatch_Individual_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_Individual_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_Individual_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_Individual_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_Individual_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_Individual_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_Individual_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_Individual_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_Individual_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_Individual_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_Individual_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_Individual" */
export type Rtcs_Db_Rmatch_Individual_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_Individual_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_Individual_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_Individual_avg_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_Individual" */
export type Rtcs_Db_Rmatch_Individual_Avg_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_Individual". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_Individual_Bool_Exp = {
  AdditionalOwners?: Maybe<Float8_Comparison_Exp>;
  AdditionalProperties?: Maybe<Float8_Comparison_Exp>;
  CountUra?: Maybe<Float8_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Fullname?: Maybe<String_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchDetails?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Bigint_Comparison_Exp>;
  MatchScoreAttributes?: Maybe<String_Comparison_Exp>;
  MatchedKccaDob?: Maybe<String_Comparison_Exp>;
  MatchedKccaEmail?: Maybe<String_Comparison_Exp>;
  MatchedKccaMobile?: Maybe<String_Comparison_Exp>;
  MatchedKccaNin?: Maybe<String_Comparison_Exp>;
  MatchedKccaPhone?: Maybe<String_Comparison_Exp>;
  MatchedNiraDob?: Maybe<String_Comparison_Exp>;
  MatchedNiraEmail?: Maybe<String_Comparison_Exp>;
  MatchedNiraNin?: Maybe<String_Comparison_Exp>;
  MatchedNiraPhone?: Maybe<String_Comparison_Exp>;
  MatchedUraEmail?: Maybe<String_Comparison_Exp>;
  MatchedUraMobile?: Maybe<String_Comparison_Exp>;
  NiraFirstname?: Maybe<String_Comparison_Exp>;
  NiraNin?: Maybe<String_Comparison_Exp>;
  NiraSurname?: Maybe<String_Comparison_Exp>;
  NumOwnedNonRateable?: Maybe<Float8_Comparison_Exp>;
  NumProperties?: Maybe<Float8_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  SumAdjustedIncome?: Maybe<Float8_Comparison_Exp>;
  SumGrossValue?: Maybe<Float8_Comparison_Exp>;
  SumNonRateableValue?: Maybe<Float8_Comparison_Exp>;
  SumRateableValue?: Maybe<Float8_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  TotalRentedProperties?: Maybe<Float8_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_Individual_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_Individual_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_Individual_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_Individual" */
export type Rtcs_Db_Rmatch_Individual_Inc_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_Individual" */
export type Rtcs_Db_Rmatch_Individual_Insert_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_Individual_Max_Fields = {
  __typename?: "rtcs_db_rmatch_Individual_max_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_Individual" */
export type Rtcs_Db_Rmatch_Individual_Max_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MatchedKccaDob?: Maybe<Order_By>;
  MatchedKccaEmail?: Maybe<Order_By>;
  MatchedKccaMobile?: Maybe<Order_By>;
  MatchedKccaNin?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedNiraDob?: Maybe<Order_By>;
  MatchedNiraEmail?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraEmail?: Maybe<Order_By>;
  MatchedUraMobile?: Maybe<Order_By>;
  NiraFirstname?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NiraSurname?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_Individual_Min_Fields = {
  __typename?: "rtcs_db_rmatch_Individual_min_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_Individual" */
export type Rtcs_Db_Rmatch_Individual_Min_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MatchedKccaDob?: Maybe<Order_By>;
  MatchedKccaEmail?: Maybe<Order_By>;
  MatchedKccaMobile?: Maybe<Order_By>;
  MatchedKccaNin?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedNiraDob?: Maybe<Order_By>;
  MatchedNiraEmail?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraEmail?: Maybe<Order_By>;
  MatchedUraMobile?: Maybe<Order_By>;
  NiraFirstname?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NiraSurname?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_Individual" */
export type Rtcs_Db_Rmatch_Individual_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_Individual_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_Individual>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_Individual" */
export type Rtcs_Db_Rmatch_Individual_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_Individual_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_Individual" */
export type Rtcs_Db_Rmatch_Individual_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MatchedKccaDob?: Maybe<Order_By>;
  MatchedKccaEmail?: Maybe<Order_By>;
  MatchedKccaMobile?: Maybe<Order_By>;
  MatchedKccaNin?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedNiraDob?: Maybe<Order_By>;
  MatchedNiraEmail?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraEmail?: Maybe<Order_By>;
  MatchedUraMobile?: Maybe<Order_By>;
  NiraFirstname?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NiraSurname?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_Individual" */
export enum Rtcs_Db_Rmatch_Individual_Select_Column {
  /** column name */
  AdditionalOwners = "AdditionalOwners",
  /** column name */
  AdditionalProperties = "AdditionalProperties",
  /** column name */
  CountUra = "CountUra",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Fullname = "Fullname",
  /** column name */
  Gml = "Gml",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchDetails = "MatchDetails",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MatchScoreAttributes = "MatchScoreAttributes",
  /** column name */
  MatchedKccaDob = "MatchedKccaDob",
  /** column name */
  MatchedKccaEmail = "MatchedKccaEmail",
  /** column name */
  MatchedKccaMobile = "MatchedKccaMobile",
  /** column name */
  MatchedKccaNin = "MatchedKccaNin",
  /** column name */
  MatchedKccaPhone = "MatchedKccaPhone",
  /** column name */
  MatchedNiraDob = "MatchedNiraDob",
  /** column name */
  MatchedNiraEmail = "MatchedNiraEmail",
  /** column name */
  MatchedNiraNin = "MatchedNiraNin",
  /** column name */
  MatchedNiraPhone = "MatchedNiraPhone",
  /** column name */
  MatchedUraEmail = "MatchedUraEmail",
  /** column name */
  MatchedUraMobile = "MatchedUraMobile",
  /** column name */
  NiraFirstname = "NiraFirstname",
  /** column name */
  NiraNin = "NiraNin",
  /** column name */
  NiraSurname = "NiraSurname",
  /** column name */
  NumOwnedNonRateable = "NumOwnedNonRateable",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Source = "Source",
  /** column name */
  SumAdjustedIncome = "SumAdjustedIncome",
  /** column name */
  SumGrossValue = "SumGrossValue",
  /** column name */
  SumNonRateableValue = "SumNonRateableValue",
  /** column name */
  SumRateableValue = "SumRateableValue",
  /** column name */
  Surname = "Surname",
  /** column name */
  TotalRentedProperties = "TotalRentedProperties",
  /** column name */
  UraTinNo = "UraTinNo",
}

/** input type for updating data in table "rtcs_db.rmatch_Individual" */
export type Rtcs_Db_Rmatch_Individual_Set_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_Individual_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_Individual_stddev_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_Individual" */
export type Rtcs_Db_Rmatch_Individual_Stddev_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_Individual_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_Individual_stddev_pop_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_Individual" */
export type Rtcs_Db_Rmatch_Individual_Stddev_Pop_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_Individual_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_Individual_stddev_samp_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_Individual" */
export type Rtcs_Db_Rmatch_Individual_Stddev_Samp_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_Individual_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_Individual_sum_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_Individual" */
export type Rtcs_Db_Rmatch_Individual_Sum_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_Individual_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_Individual_var_pop_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_Individual" */
export type Rtcs_Db_Rmatch_Individual_Var_Pop_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_Individual_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_Individual_var_samp_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_Individual" */
export type Rtcs_Db_Rmatch_Individual_Var_Samp_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_Individual_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_Individual_variance_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_Individual" */
export type Rtcs_Db_Rmatch_Individual_Variance_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_IndividualsNetwork" */
export type Rtcs_Db_Rmatch_IndividualsNetwork = {
  __typename?: "rtcs_db_rmatch_IndividualsNetwork";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedKccaTin?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** columns and relationships of "rtcs_db.rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting = {
  __typename?: "rtcs_db_rmatch_IndividualsNetworkTesting";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedKccaTin?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** aggregated selection of "rtcs_db.rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Aggregate = {
  __typename?: "rtcs_db_rmatch_IndividualsNetworkTesting_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_IndividualsNetworkTesting>;
};

/** aggregate fields of "rtcs_db.rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualsNetworkTesting_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualsNetworkTesting_avg_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Avg_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_IndividualsNetworkTesting". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Bool_Exp = {
  AdditionalOwners?: Maybe<Float8_Comparison_Exp>;
  AdditionalProperties?: Maybe<Float8_Comparison_Exp>;
  CountUra?: Maybe<Float8_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Fullname?: Maybe<String_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchDetails?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Bigint_Comparison_Exp>;
  MatchScoreAttributes?: Maybe<String_Comparison_Exp>;
  MatchedKccaDob?: Maybe<String_Comparison_Exp>;
  MatchedKccaEmail?: Maybe<String_Comparison_Exp>;
  MatchedKccaMobile?: Maybe<String_Comparison_Exp>;
  MatchedKccaNin?: Maybe<String_Comparison_Exp>;
  MatchedKccaPhone?: Maybe<String_Comparison_Exp>;
  MatchedKccaTin?: Maybe<String_Comparison_Exp>;
  MatchedNiraDob?: Maybe<String_Comparison_Exp>;
  MatchedNiraEmail?: Maybe<String_Comparison_Exp>;
  MatchedNiraNin?: Maybe<String_Comparison_Exp>;
  MatchedNiraPhone?: Maybe<String_Comparison_Exp>;
  MatchedUraEmail?: Maybe<String_Comparison_Exp>;
  MatchedUraMobile?: Maybe<String_Comparison_Exp>;
  NiraFirstname?: Maybe<String_Comparison_Exp>;
  NiraNin?: Maybe<String_Comparison_Exp>;
  NiraSurname?: Maybe<String_Comparison_Exp>;
  NumOwnedNonRateable?: Maybe<Float8_Comparison_Exp>;
  NumProperties?: Maybe<Float8_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  SumAdjustedIncome?: Maybe<Float8_Comparison_Exp>;
  SumGrossValue?: Maybe<Float8_Comparison_Exp>;
  SumNonRateableValue?: Maybe<Float8_Comparison_Exp>;
  SumRateableValue?: Maybe<Float8_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  TotalRentedProperties?: Maybe<Float8_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  VisibilityLevel?: Maybe<Float8_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Inc_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Insert_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedKccaTin?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Max_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualsNetworkTesting_max_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedKccaTin?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Max_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MatchedKccaDob?: Maybe<Order_By>;
  MatchedKccaEmail?: Maybe<Order_By>;
  MatchedKccaMobile?: Maybe<Order_By>;
  MatchedKccaNin?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedKccaTin?: Maybe<Order_By>;
  MatchedNiraDob?: Maybe<Order_By>;
  MatchedNiraEmail?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraEmail?: Maybe<Order_By>;
  MatchedUraMobile?: Maybe<Order_By>;
  NiraFirstname?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NiraSurname?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Min_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualsNetworkTesting_min_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedKccaTin?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Min_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MatchedKccaDob?: Maybe<Order_By>;
  MatchedKccaEmail?: Maybe<Order_By>;
  MatchedKccaMobile?: Maybe<Order_By>;
  MatchedKccaNin?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedKccaTin?: Maybe<Order_By>;
  MatchedNiraDob?: Maybe<Order_By>;
  MatchedNiraEmail?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraEmail?: Maybe<Order_By>;
  MatchedUraMobile?: Maybe<Order_By>;
  NiraFirstname?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NiraSurname?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_IndividualsNetworkTesting_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_IndividualsNetworkTesting>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_IndividualsNetworkTesting_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MatchedKccaDob?: Maybe<Order_By>;
  MatchedKccaEmail?: Maybe<Order_By>;
  MatchedKccaMobile?: Maybe<Order_By>;
  MatchedKccaNin?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedKccaTin?: Maybe<Order_By>;
  MatchedNiraDob?: Maybe<Order_By>;
  MatchedNiraEmail?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraEmail?: Maybe<Order_By>;
  MatchedUraMobile?: Maybe<Order_By>;
  NiraFirstname?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NiraSurname?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_IndividualsNetworkTesting" */
export enum Rtcs_Db_Rmatch_IndividualsNetworkTesting_Select_Column {
  /** column name */
  AdditionalOwners = "AdditionalOwners",
  /** column name */
  AdditionalProperties = "AdditionalProperties",
  /** column name */
  CountUra = "CountUra",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Fullname = "Fullname",
  /** column name */
  Gml = "Gml",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchDetails = "MatchDetails",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MatchScoreAttributes = "MatchScoreAttributes",
  /** column name */
  MatchedKccaDob = "MatchedKccaDob",
  /** column name */
  MatchedKccaEmail = "MatchedKccaEmail",
  /** column name */
  MatchedKccaMobile = "MatchedKccaMobile",
  /** column name */
  MatchedKccaNin = "MatchedKccaNin",
  /** column name */
  MatchedKccaPhone = "MatchedKccaPhone",
  /** column name */
  MatchedKccaTin = "MatchedKccaTin",
  /** column name */
  MatchedNiraDob = "MatchedNiraDob",
  /** column name */
  MatchedNiraEmail = "MatchedNiraEmail",
  /** column name */
  MatchedNiraNin = "MatchedNiraNin",
  /** column name */
  MatchedNiraPhone = "MatchedNiraPhone",
  /** column name */
  MatchedUraEmail = "MatchedUraEmail",
  /** column name */
  MatchedUraMobile = "MatchedUraMobile",
  /** column name */
  NiraFirstname = "NiraFirstname",
  /** column name */
  NiraNin = "NiraNin",
  /** column name */
  NiraSurname = "NiraSurname",
  /** column name */
  NumOwnedNonRateable = "NumOwnedNonRateable",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Source = "Source",
  /** column name */
  SumAdjustedIncome = "SumAdjustedIncome",
  /** column name */
  SumGrossValue = "SumGrossValue",
  /** column name */
  SumNonRateableValue = "SumNonRateableValue",
  /** column name */
  SumRateableValue = "SumRateableValue",
  /** column name */
  Surname = "Surname",
  /** column name */
  TotalRentedProperties = "TotalRentedProperties",
  /** column name */
  UraTinNo = "UraTinNo",
  /** column name */
  VisibilityLevel = "VisibilityLevel",
}

/** input type for updating data in table "rtcs_db.rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Set_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedKccaTin?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualsNetworkTesting_stddev_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Stddev_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualsNetworkTesting_stddev_pop_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Stddev_Pop_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualsNetworkTesting_stddev_samp_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Stddev_Samp_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualsNetworkTesting_sum_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Sum_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualsNetworkTesting_var_pop_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Var_Pop_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualsNetworkTesting_var_samp_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Var_Samp_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualsNetworkTesting_variance_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_IndividualsNetworkTesting" */
export type Rtcs_Db_Rmatch_IndividualsNetworkTesting_Variance_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregated selection of "rtcs_db.rmatch_IndividualsNetwork" */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Aggregate = {
  __typename?: "rtcs_db_rmatch_IndividualsNetwork_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_IndividualsNetwork>;
};

/** aggregate fields of "rtcs_db.rmatch_IndividualsNetwork" */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualsNetwork_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_IndividualsNetwork" */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_IndividualsNetwork_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_IndividualsNetwork" */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_IndividualsNetwork" */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_IndividualsNetwork_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualsNetwork_avg_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_IndividualsNetwork" */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Avg_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_IndividualsNetwork". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Bool_Exp = {
  AdditionalOwners?: Maybe<Float8_Comparison_Exp>;
  AdditionalProperties?: Maybe<Float8_Comparison_Exp>;
  CountUra?: Maybe<Float8_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Fullname?: Maybe<String_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchDetails?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Bigint_Comparison_Exp>;
  MatchScoreAttributes?: Maybe<String_Comparison_Exp>;
  MatchedKccaDob?: Maybe<String_Comparison_Exp>;
  MatchedKccaEmail?: Maybe<String_Comparison_Exp>;
  MatchedKccaMobile?: Maybe<String_Comparison_Exp>;
  MatchedKccaNin?: Maybe<String_Comparison_Exp>;
  MatchedKccaPhone?: Maybe<String_Comparison_Exp>;
  MatchedKccaTin?: Maybe<String_Comparison_Exp>;
  MatchedNiraDob?: Maybe<String_Comparison_Exp>;
  MatchedNiraEmail?: Maybe<String_Comparison_Exp>;
  MatchedNiraNin?: Maybe<String_Comparison_Exp>;
  MatchedNiraPhone?: Maybe<String_Comparison_Exp>;
  MatchedUraEmail?: Maybe<String_Comparison_Exp>;
  MatchedUraMobile?: Maybe<String_Comparison_Exp>;
  NiraFirstname?: Maybe<String_Comparison_Exp>;
  NiraNin?: Maybe<String_Comparison_Exp>;
  NiraSurname?: Maybe<String_Comparison_Exp>;
  NumOwnedNonRateable?: Maybe<Float8_Comparison_Exp>;
  NumProperties?: Maybe<Float8_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  SumAdjustedIncome?: Maybe<Float8_Comparison_Exp>;
  SumGrossRentValueAdjusted?: Maybe<Float8_Comparison_Exp>;
  SumGrossValue?: Maybe<Float8_Comparison_Exp>;
  SumNonRateableValue?: Maybe<Float8_Comparison_Exp>;
  SumRateableValue?: Maybe<Float8_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  TotalRentedProperties?: Maybe<Float8_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  VisibilityLevel?: Maybe<Float8_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_IndividualsNetwork" */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Inc_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_IndividualsNetwork" */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Insert_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedKccaTin?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Max_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualsNetwork_max_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedKccaTin?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_IndividualsNetwork" */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Max_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MatchedKccaDob?: Maybe<Order_By>;
  MatchedKccaEmail?: Maybe<Order_By>;
  MatchedKccaMobile?: Maybe<Order_By>;
  MatchedKccaNin?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedKccaTin?: Maybe<Order_By>;
  MatchedNiraDob?: Maybe<Order_By>;
  MatchedNiraEmail?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraEmail?: Maybe<Order_By>;
  MatchedUraMobile?: Maybe<Order_By>;
  NiraFirstname?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NiraSurname?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Min_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualsNetwork_min_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedKccaTin?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_IndividualsNetwork" */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Min_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MatchedKccaDob?: Maybe<Order_By>;
  MatchedKccaEmail?: Maybe<Order_By>;
  MatchedKccaMobile?: Maybe<Order_By>;
  MatchedKccaNin?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedKccaTin?: Maybe<Order_By>;
  MatchedNiraDob?: Maybe<Order_By>;
  MatchedNiraEmail?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraEmail?: Maybe<Order_By>;
  MatchedUraMobile?: Maybe<Order_By>;
  NiraFirstname?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NiraSurname?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_IndividualsNetwork" */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_IndividualsNetwork_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_IndividualsNetwork>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_IndividualsNetwork" */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_IndividualsNetwork_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_IndividualsNetwork" */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  MatchedKccaDob?: Maybe<Order_By>;
  MatchedKccaEmail?: Maybe<Order_By>;
  MatchedKccaMobile?: Maybe<Order_By>;
  MatchedKccaNin?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedKccaTin?: Maybe<Order_By>;
  MatchedNiraDob?: Maybe<Order_By>;
  MatchedNiraEmail?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraEmail?: Maybe<Order_By>;
  MatchedUraMobile?: Maybe<Order_By>;
  NiraFirstname?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NiraSurname?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_IndividualsNetwork" */
export enum Rtcs_Db_Rmatch_IndividualsNetwork_Select_Column {
  /** column name */
  AdditionalOwners = "AdditionalOwners",
  /** column name */
  AdditionalProperties = "AdditionalProperties",
  /** column name */
  CountUra = "CountUra",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Fullname = "Fullname",
  /** column name */
  Gml = "Gml",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchDetails = "MatchDetails",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MatchScoreAttributes = "MatchScoreAttributes",
  /** column name */
  MatchedKccaDob = "MatchedKccaDob",
  /** column name */
  MatchedKccaEmail = "MatchedKccaEmail",
  /** column name */
  MatchedKccaMobile = "MatchedKccaMobile",
  /** column name */
  MatchedKccaNin = "MatchedKccaNin",
  /** column name */
  MatchedKccaPhone = "MatchedKccaPhone",
  /** column name */
  MatchedKccaTin = "MatchedKccaTin",
  /** column name */
  MatchedNiraDob = "MatchedNiraDob",
  /** column name */
  MatchedNiraEmail = "MatchedNiraEmail",
  /** column name */
  MatchedNiraNin = "MatchedNiraNin",
  /** column name */
  MatchedNiraPhone = "MatchedNiraPhone",
  /** column name */
  MatchedUraEmail = "MatchedUraEmail",
  /** column name */
  MatchedUraMobile = "MatchedUraMobile",
  /** column name */
  NiraFirstname = "NiraFirstname",
  /** column name */
  NiraNin = "NiraNin",
  /** column name */
  NiraSurname = "NiraSurname",
  /** column name */
  NumOwnedNonRateable = "NumOwnedNonRateable",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Source = "Source",
  /** column name */
  SumAdjustedIncome = "SumAdjustedIncome",
  /** column name */
  SumGrossRentValueAdjusted = "SumGrossRentValueAdjusted",
  /** column name */
  SumGrossValue = "SumGrossValue",
  /** column name */
  SumNonRateableValue = "SumNonRateableValue",
  /** column name */
  SumRateableValue = "SumRateableValue",
  /** column name */
  Surname = "Surname",
  /** column name */
  TotalRentedProperties = "TotalRentedProperties",
  /** column name */
  UraTinNo = "UraTinNo",
  /** column name */
  VisibilityLevel = "VisibilityLevel",
}

/** input type for updating data in table "rtcs_db.rmatch_IndividualsNetwork" */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Set_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaMobile?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["String"]>;
  MatchedKccaTin?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraEmail?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraMobile?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  Surname?: Maybe<Scalars["String"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualsNetwork_stddev_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_IndividualsNetwork" */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Stddev_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualsNetwork_stddev_pop_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_IndividualsNetwork" */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Stddev_Pop_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualsNetwork_stddev_samp_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_IndividualsNetwork" */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Stddev_Samp_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualsNetwork_sum_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  VisibilityLevel?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_IndividualsNetwork" */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Sum_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualsNetwork_var_pop_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_IndividualsNetwork" */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Var_Pop_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualsNetwork_var_samp_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_IndividualsNetwork" */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Var_Samp_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_IndividualsNetwork_variance_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossRentValueAdjusted?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  VisibilityLevel?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_IndividualsNetwork" */
export type Rtcs_Db_Rmatch_IndividualsNetwork_Variance_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossRentValueAdjusted?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  VisibilityLevel?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners = {
  __typename?: "rtcs_db_rmatch_KccaToKccaIndividualOwners";
  BirthdateOwner1?: Maybe<Scalars["timestamp"]>;
  BirthdateOwner2?: Maybe<Scalars["timestamptz"]>;
  EmailOwner1?: Maybe<Scalars["String"]>;
  EmailOwner2?: Maybe<Scalars["String"]>;
  FirstNameOwner1?: Maybe<Scalars["String"]>;
  FirstNameOwner2?: Maybe<Scalars["String"]>;
  IdOwner1?: Maybe<Scalars["String"]>;
  IdOwner2?: Maybe<Scalars["String"]>;
  IdentificationNationalIdOwner1?: Maybe<Scalars["String"]>;
  IdentificationNationalIdOwner2?: Maybe<Scalars["String"]>;
  MatchBirthdate?: Maybe<Scalars["timestamptz"]>;
  MatchEmail?: Maybe<Scalars["String"]>;
  MatchFirstName?: Maybe<Scalars["String"]>;
  MatchIdentificationNationalId?: Maybe<Scalars["String"]>;
  MatchMiddleName?: Maybe<Scalars["String"]>;
  MatchMobile?: Maybe<Scalars["String"]>;
  MatchSurname?: Maybe<Scalars["String"]>;
  MatchTelephone?: Maybe<Scalars["String"]>;
  MiddleNameOwner1?: Maybe<Scalars["String"]>;
  MiddleNameOwner2?: Maybe<Scalars["String"]>;
  MobileOwner1?: Maybe<Scalars["String"]>;
  MobileOwner2?: Maybe<Scalars["String"]>;
  Score?: Maybe<Scalars["bigint"]>;
  SidOwner1?: Maybe<Scalars["String"]>;
  SidOwner2?: Maybe<Scalars["String"]>;
  SurnameOwner1?: Maybe<Scalars["String"]>;
  SurnameOwner2?: Maybe<Scalars["String"]>;
  TelephoneOwner1?: Maybe<Scalars["String"]>;
  TelephoneOwner2?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Aggregate = {
  __typename?: "rtcs_db_rmatch_KccaToKccaIndividualOwners_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners>;
};

/** aggregate fields of "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_KccaToKccaIndividualOwners_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_KccaToKccaIndividualOwners_avg_fields";
  Score?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Avg_Order_By = {
  Score?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_KccaToKccaIndividualOwners". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Bool_Exp = {
  BirthdateOwner1?: Maybe<Timestamp_Comparison_Exp>;
  BirthdateOwner2?: Maybe<Timestamptz_Comparison_Exp>;
  EmailOwner1?: Maybe<String_Comparison_Exp>;
  EmailOwner2?: Maybe<String_Comparison_Exp>;
  FirstNameOwner1?: Maybe<String_Comparison_Exp>;
  FirstNameOwner2?: Maybe<String_Comparison_Exp>;
  IdOwner1?: Maybe<String_Comparison_Exp>;
  IdOwner2?: Maybe<String_Comparison_Exp>;
  IdentificationNationalIdOwner1?: Maybe<String_Comparison_Exp>;
  IdentificationNationalIdOwner2?: Maybe<String_Comparison_Exp>;
  MatchBirthdate?: Maybe<Timestamptz_Comparison_Exp>;
  MatchEmail?: Maybe<String_Comparison_Exp>;
  MatchFirstName?: Maybe<String_Comparison_Exp>;
  MatchIdentificationNationalId?: Maybe<String_Comparison_Exp>;
  MatchMiddleName?: Maybe<String_Comparison_Exp>;
  MatchMobile?: Maybe<String_Comparison_Exp>;
  MatchSurname?: Maybe<String_Comparison_Exp>;
  MatchTelephone?: Maybe<String_Comparison_Exp>;
  MiddleNameOwner1?: Maybe<String_Comparison_Exp>;
  MiddleNameOwner2?: Maybe<String_Comparison_Exp>;
  MobileOwner1?: Maybe<String_Comparison_Exp>;
  MobileOwner2?: Maybe<String_Comparison_Exp>;
  Score?: Maybe<Bigint_Comparison_Exp>;
  SidOwner1?: Maybe<String_Comparison_Exp>;
  SidOwner2?: Maybe<String_Comparison_Exp>;
  SurnameOwner1?: Maybe<String_Comparison_Exp>;
  SurnameOwner2?: Maybe<String_Comparison_Exp>;
  TelephoneOwner1?: Maybe<String_Comparison_Exp>;
  TelephoneOwner2?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Inc_Input = {
  Score?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Insert_Input = {
  BirthdateOwner1?: Maybe<Scalars["timestamp"]>;
  BirthdateOwner2?: Maybe<Scalars["timestamptz"]>;
  EmailOwner1?: Maybe<Scalars["String"]>;
  EmailOwner2?: Maybe<Scalars["String"]>;
  FirstNameOwner1?: Maybe<Scalars["String"]>;
  FirstNameOwner2?: Maybe<Scalars["String"]>;
  IdOwner1?: Maybe<Scalars["String"]>;
  IdOwner2?: Maybe<Scalars["String"]>;
  IdentificationNationalIdOwner1?: Maybe<Scalars["String"]>;
  IdentificationNationalIdOwner2?: Maybe<Scalars["String"]>;
  MatchBirthdate?: Maybe<Scalars["timestamptz"]>;
  MatchEmail?: Maybe<Scalars["String"]>;
  MatchFirstName?: Maybe<Scalars["String"]>;
  MatchIdentificationNationalId?: Maybe<Scalars["String"]>;
  MatchMiddleName?: Maybe<Scalars["String"]>;
  MatchMobile?: Maybe<Scalars["String"]>;
  MatchSurname?: Maybe<Scalars["String"]>;
  MatchTelephone?: Maybe<Scalars["String"]>;
  MiddleNameOwner1?: Maybe<Scalars["String"]>;
  MiddleNameOwner2?: Maybe<Scalars["String"]>;
  MobileOwner1?: Maybe<Scalars["String"]>;
  MobileOwner2?: Maybe<Scalars["String"]>;
  Score?: Maybe<Scalars["bigint"]>;
  SidOwner1?: Maybe<Scalars["String"]>;
  SidOwner2?: Maybe<Scalars["String"]>;
  SurnameOwner1?: Maybe<Scalars["String"]>;
  SurnameOwner2?: Maybe<Scalars["String"]>;
  TelephoneOwner1?: Maybe<Scalars["String"]>;
  TelephoneOwner2?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Max_Fields = {
  __typename?: "rtcs_db_rmatch_KccaToKccaIndividualOwners_max_fields";
  BirthdateOwner1?: Maybe<Scalars["timestamp"]>;
  BirthdateOwner2?: Maybe<Scalars["timestamptz"]>;
  EmailOwner1?: Maybe<Scalars["String"]>;
  EmailOwner2?: Maybe<Scalars["String"]>;
  FirstNameOwner1?: Maybe<Scalars["String"]>;
  FirstNameOwner2?: Maybe<Scalars["String"]>;
  IdOwner1?: Maybe<Scalars["String"]>;
  IdOwner2?: Maybe<Scalars["String"]>;
  IdentificationNationalIdOwner1?: Maybe<Scalars["String"]>;
  IdentificationNationalIdOwner2?: Maybe<Scalars["String"]>;
  MatchBirthdate?: Maybe<Scalars["timestamptz"]>;
  MatchEmail?: Maybe<Scalars["String"]>;
  MatchFirstName?: Maybe<Scalars["String"]>;
  MatchIdentificationNationalId?: Maybe<Scalars["String"]>;
  MatchMiddleName?: Maybe<Scalars["String"]>;
  MatchMobile?: Maybe<Scalars["String"]>;
  MatchSurname?: Maybe<Scalars["String"]>;
  MatchTelephone?: Maybe<Scalars["String"]>;
  MiddleNameOwner1?: Maybe<Scalars["String"]>;
  MiddleNameOwner2?: Maybe<Scalars["String"]>;
  MobileOwner1?: Maybe<Scalars["String"]>;
  MobileOwner2?: Maybe<Scalars["String"]>;
  Score?: Maybe<Scalars["bigint"]>;
  SidOwner1?: Maybe<Scalars["String"]>;
  SidOwner2?: Maybe<Scalars["String"]>;
  SurnameOwner1?: Maybe<Scalars["String"]>;
  SurnameOwner2?: Maybe<Scalars["String"]>;
  TelephoneOwner1?: Maybe<Scalars["String"]>;
  TelephoneOwner2?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Max_Order_By = {
  BirthdateOwner1?: Maybe<Order_By>;
  BirthdateOwner2?: Maybe<Order_By>;
  EmailOwner1?: Maybe<Order_By>;
  EmailOwner2?: Maybe<Order_By>;
  FirstNameOwner1?: Maybe<Order_By>;
  FirstNameOwner2?: Maybe<Order_By>;
  IdOwner1?: Maybe<Order_By>;
  IdOwner2?: Maybe<Order_By>;
  IdentificationNationalIdOwner1?: Maybe<Order_By>;
  IdentificationNationalIdOwner2?: Maybe<Order_By>;
  MatchBirthdate?: Maybe<Order_By>;
  MatchEmail?: Maybe<Order_By>;
  MatchFirstName?: Maybe<Order_By>;
  MatchIdentificationNationalId?: Maybe<Order_By>;
  MatchMiddleName?: Maybe<Order_By>;
  MatchMobile?: Maybe<Order_By>;
  MatchSurname?: Maybe<Order_By>;
  MatchTelephone?: Maybe<Order_By>;
  MiddleNameOwner1?: Maybe<Order_By>;
  MiddleNameOwner2?: Maybe<Order_By>;
  MobileOwner1?: Maybe<Order_By>;
  MobileOwner2?: Maybe<Order_By>;
  Score?: Maybe<Order_By>;
  SidOwner1?: Maybe<Order_By>;
  SidOwner2?: Maybe<Order_By>;
  SurnameOwner1?: Maybe<Order_By>;
  SurnameOwner2?: Maybe<Order_By>;
  TelephoneOwner1?: Maybe<Order_By>;
  TelephoneOwner2?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Min_Fields = {
  __typename?: "rtcs_db_rmatch_KccaToKccaIndividualOwners_min_fields";
  BirthdateOwner1?: Maybe<Scalars["timestamp"]>;
  BirthdateOwner2?: Maybe<Scalars["timestamptz"]>;
  EmailOwner1?: Maybe<Scalars["String"]>;
  EmailOwner2?: Maybe<Scalars["String"]>;
  FirstNameOwner1?: Maybe<Scalars["String"]>;
  FirstNameOwner2?: Maybe<Scalars["String"]>;
  IdOwner1?: Maybe<Scalars["String"]>;
  IdOwner2?: Maybe<Scalars["String"]>;
  IdentificationNationalIdOwner1?: Maybe<Scalars["String"]>;
  IdentificationNationalIdOwner2?: Maybe<Scalars["String"]>;
  MatchBirthdate?: Maybe<Scalars["timestamptz"]>;
  MatchEmail?: Maybe<Scalars["String"]>;
  MatchFirstName?: Maybe<Scalars["String"]>;
  MatchIdentificationNationalId?: Maybe<Scalars["String"]>;
  MatchMiddleName?: Maybe<Scalars["String"]>;
  MatchMobile?: Maybe<Scalars["String"]>;
  MatchSurname?: Maybe<Scalars["String"]>;
  MatchTelephone?: Maybe<Scalars["String"]>;
  MiddleNameOwner1?: Maybe<Scalars["String"]>;
  MiddleNameOwner2?: Maybe<Scalars["String"]>;
  MobileOwner1?: Maybe<Scalars["String"]>;
  MobileOwner2?: Maybe<Scalars["String"]>;
  Score?: Maybe<Scalars["bigint"]>;
  SidOwner1?: Maybe<Scalars["String"]>;
  SidOwner2?: Maybe<Scalars["String"]>;
  SurnameOwner1?: Maybe<Scalars["String"]>;
  SurnameOwner2?: Maybe<Scalars["String"]>;
  TelephoneOwner1?: Maybe<Scalars["String"]>;
  TelephoneOwner2?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Min_Order_By = {
  BirthdateOwner1?: Maybe<Order_By>;
  BirthdateOwner2?: Maybe<Order_By>;
  EmailOwner1?: Maybe<Order_By>;
  EmailOwner2?: Maybe<Order_By>;
  FirstNameOwner1?: Maybe<Order_By>;
  FirstNameOwner2?: Maybe<Order_By>;
  IdOwner1?: Maybe<Order_By>;
  IdOwner2?: Maybe<Order_By>;
  IdentificationNationalIdOwner1?: Maybe<Order_By>;
  IdentificationNationalIdOwner2?: Maybe<Order_By>;
  MatchBirthdate?: Maybe<Order_By>;
  MatchEmail?: Maybe<Order_By>;
  MatchFirstName?: Maybe<Order_By>;
  MatchIdentificationNationalId?: Maybe<Order_By>;
  MatchMiddleName?: Maybe<Order_By>;
  MatchMobile?: Maybe<Order_By>;
  MatchSurname?: Maybe<Order_By>;
  MatchTelephone?: Maybe<Order_By>;
  MiddleNameOwner1?: Maybe<Order_By>;
  MiddleNameOwner2?: Maybe<Order_By>;
  MobileOwner1?: Maybe<Order_By>;
  MobileOwner2?: Maybe<Order_By>;
  Score?: Maybe<Order_By>;
  SidOwner1?: Maybe<Order_By>;
  SidOwner2?: Maybe<Order_By>;
  SurnameOwner1?: Maybe<Order_By>;
  SurnameOwner2?: Maybe<Order_By>;
  TelephoneOwner1?: Maybe<Order_By>;
  TelephoneOwner2?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_KccaToKccaIndividualOwners_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Order_By = {
  BirthdateOwner1?: Maybe<Order_By>;
  BirthdateOwner2?: Maybe<Order_By>;
  EmailOwner1?: Maybe<Order_By>;
  EmailOwner2?: Maybe<Order_By>;
  FirstNameOwner1?: Maybe<Order_By>;
  FirstNameOwner2?: Maybe<Order_By>;
  IdOwner1?: Maybe<Order_By>;
  IdOwner2?: Maybe<Order_By>;
  IdentificationNationalIdOwner1?: Maybe<Order_By>;
  IdentificationNationalIdOwner2?: Maybe<Order_By>;
  MatchBirthdate?: Maybe<Order_By>;
  MatchEmail?: Maybe<Order_By>;
  MatchFirstName?: Maybe<Order_By>;
  MatchIdentificationNationalId?: Maybe<Order_By>;
  MatchMiddleName?: Maybe<Order_By>;
  MatchMobile?: Maybe<Order_By>;
  MatchSurname?: Maybe<Order_By>;
  MatchTelephone?: Maybe<Order_By>;
  MiddleNameOwner1?: Maybe<Order_By>;
  MiddleNameOwner2?: Maybe<Order_By>;
  MobileOwner1?: Maybe<Order_By>;
  MobileOwner2?: Maybe<Order_By>;
  Score?: Maybe<Order_By>;
  SidOwner1?: Maybe<Order_By>;
  SidOwner2?: Maybe<Order_By>;
  SurnameOwner1?: Maybe<Order_By>;
  SurnameOwner2?: Maybe<Order_By>;
  TelephoneOwner1?: Maybe<Order_By>;
  TelephoneOwner2?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
export enum Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Select_Column {
  /** column name */
  BirthdateOwner1 = "BirthdateOwner1",
  /** column name */
  BirthdateOwner2 = "BirthdateOwner2",
  /** column name */
  EmailOwner1 = "EmailOwner1",
  /** column name */
  EmailOwner2 = "EmailOwner2",
  /** column name */
  FirstNameOwner1 = "FirstNameOwner1",
  /** column name */
  FirstNameOwner2 = "FirstNameOwner2",
  /** column name */
  IdOwner1 = "IdOwner1",
  /** column name */
  IdOwner2 = "IdOwner2",
  /** column name */
  IdentificationNationalIdOwner1 = "IdentificationNationalIdOwner1",
  /** column name */
  IdentificationNationalIdOwner2 = "IdentificationNationalIdOwner2",
  /** column name */
  MatchBirthdate = "MatchBirthdate",
  /** column name */
  MatchEmail = "MatchEmail",
  /** column name */
  MatchFirstName = "MatchFirstName",
  /** column name */
  MatchIdentificationNationalId = "MatchIdentificationNationalId",
  /** column name */
  MatchMiddleName = "MatchMiddleName",
  /** column name */
  MatchMobile = "MatchMobile",
  /** column name */
  MatchSurname = "MatchSurname",
  /** column name */
  MatchTelephone = "MatchTelephone",
  /** column name */
  MiddleNameOwner1 = "MiddleNameOwner1",
  /** column name */
  MiddleNameOwner2 = "MiddleNameOwner2",
  /** column name */
  MobileOwner1 = "MobileOwner1",
  /** column name */
  MobileOwner2 = "MobileOwner2",
  /** column name */
  Score = "Score",
  /** column name */
  SidOwner1 = "SidOwner1",
  /** column name */
  SidOwner2 = "SidOwner2",
  /** column name */
  SurnameOwner1 = "SurnameOwner1",
  /** column name */
  SurnameOwner2 = "SurnameOwner2",
  /** column name */
  TelephoneOwner1 = "TelephoneOwner1",
  /** column name */
  TelephoneOwner2 = "TelephoneOwner2",
}

/** input type for updating data in table "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Set_Input = {
  BirthdateOwner1?: Maybe<Scalars["timestamp"]>;
  BirthdateOwner2?: Maybe<Scalars["timestamptz"]>;
  EmailOwner1?: Maybe<Scalars["String"]>;
  EmailOwner2?: Maybe<Scalars["String"]>;
  FirstNameOwner1?: Maybe<Scalars["String"]>;
  FirstNameOwner2?: Maybe<Scalars["String"]>;
  IdOwner1?: Maybe<Scalars["String"]>;
  IdOwner2?: Maybe<Scalars["String"]>;
  IdentificationNationalIdOwner1?: Maybe<Scalars["String"]>;
  IdentificationNationalIdOwner2?: Maybe<Scalars["String"]>;
  MatchBirthdate?: Maybe<Scalars["timestamptz"]>;
  MatchEmail?: Maybe<Scalars["String"]>;
  MatchFirstName?: Maybe<Scalars["String"]>;
  MatchIdentificationNationalId?: Maybe<Scalars["String"]>;
  MatchMiddleName?: Maybe<Scalars["String"]>;
  MatchMobile?: Maybe<Scalars["String"]>;
  MatchSurname?: Maybe<Scalars["String"]>;
  MatchTelephone?: Maybe<Scalars["String"]>;
  MiddleNameOwner1?: Maybe<Scalars["String"]>;
  MiddleNameOwner2?: Maybe<Scalars["String"]>;
  MobileOwner1?: Maybe<Scalars["String"]>;
  MobileOwner2?: Maybe<Scalars["String"]>;
  Score?: Maybe<Scalars["bigint"]>;
  SidOwner1?: Maybe<Scalars["String"]>;
  SidOwner2?: Maybe<Scalars["String"]>;
  SurnameOwner1?: Maybe<Scalars["String"]>;
  SurnameOwner2?: Maybe<Scalars["String"]>;
  TelephoneOwner1?: Maybe<Scalars["String"]>;
  TelephoneOwner2?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_KccaToKccaIndividualOwners_stddev_fields";
  Score?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Stddev_Order_By = {
  Score?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_KccaToKccaIndividualOwners_stddev_pop_fields";
  Score?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Stddev_Pop_Order_By = {
  Score?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_KccaToKccaIndividualOwners_stddev_samp_fields";
  Score?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Stddev_Samp_Order_By = {
  Score?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_KccaToKccaIndividualOwners_sum_fields";
  Score?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Sum_Order_By = {
  Score?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_KccaToKccaIndividualOwners_var_pop_fields";
  Score?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Var_Pop_Order_By = {
  Score?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_KccaToKccaIndividualOwners_var_samp_fields";
  Score?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Var_Samp_Order_By = {
  Score?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_KccaToKccaIndividualOwners_variance_fields";
  Score?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Variance_Order_By = {
  Score?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners = {
  __typename?: "rtcs_db_rmatch_KccaToKccaNonindividualOwners";
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId1?: Maybe<Scalars["bigint"]>;
  KccaCustomerId2?: Maybe<Scalars["bigint"]>;
  KccaEntityLegalName1?: Maybe<Scalars["String"]>;
  KccaEntityLegalName2?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  urid?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Aggregate = {
  __typename?: "rtcs_db_rmatch_KccaToKccaNonindividualOwners_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners>;
};

/** aggregate fields of "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_KccaToKccaNonindividualOwners_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_KccaToKccaNonindividualOwners_avg_fields";
  KccaCustomerId1?: Maybe<Scalars["Float"]>;
  KccaCustomerId2?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Avg_Order_By = {
  KccaCustomerId1?: Maybe<Order_By>;
  KccaCustomerId2?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_KccaToKccaNonindividualOwners". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  FinalMatchCategory?: Maybe<String_Comparison_Exp>;
  KccaCustomerId1?: Maybe<Bigint_Comparison_Exp>;
  KccaCustomerId2?: Maybe<Bigint_Comparison_Exp>;
  KccaEntityLegalName1?: Maybe<String_Comparison_Exp>;
  KccaEntityLegalName2?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  RmatchScore?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Bool_Exp>>
  >;
  urid?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Inc_Input = {
  KccaCustomerId1?: Maybe<Scalars["bigint"]>;
  KccaCustomerId2?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId1?: Maybe<Scalars["bigint"]>;
  KccaCustomerId2?: Maybe<Scalars["bigint"]>;
  KccaEntityLegalName1?: Maybe<Scalars["String"]>;
  KccaEntityLegalName2?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  urid?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Max_Fields = {
  __typename?: "rtcs_db_rmatch_KccaToKccaNonindividualOwners_max_fields";
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId1?: Maybe<Scalars["bigint"]>;
  KccaCustomerId2?: Maybe<Scalars["bigint"]>;
  KccaEntityLegalName1?: Maybe<Scalars["String"]>;
  KccaEntityLegalName2?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  urid?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Max_Order_By = {
  File?: Maybe<Order_By>;
  FinalMatchCategory?: Maybe<Order_By>;
  KccaCustomerId1?: Maybe<Order_By>;
  KccaCustomerId2?: Maybe<Order_By>;
  KccaEntityLegalName1?: Maybe<Order_By>;
  KccaEntityLegalName2?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  urid?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Min_Fields = {
  __typename?: "rtcs_db_rmatch_KccaToKccaNonindividualOwners_min_fields";
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId1?: Maybe<Scalars["bigint"]>;
  KccaCustomerId2?: Maybe<Scalars["bigint"]>;
  KccaEntityLegalName1?: Maybe<Scalars["String"]>;
  KccaEntityLegalName2?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  urid?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Min_Order_By = {
  File?: Maybe<Order_By>;
  FinalMatchCategory?: Maybe<Order_By>;
  KccaCustomerId1?: Maybe<Order_By>;
  KccaCustomerId2?: Maybe<Order_By>;
  KccaEntityLegalName1?: Maybe<Order_By>;
  KccaEntityLegalName2?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  urid?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_KccaToKccaNonindividualOwners_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Order_By = {
  File?: Maybe<Order_By>;
  FinalMatchCategory?: Maybe<Order_By>;
  KccaCustomerId1?: Maybe<Order_By>;
  KccaCustomerId2?: Maybe<Order_By>;
  KccaEntityLegalName1?: Maybe<Order_By>;
  KccaEntityLegalName2?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  urid?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
export enum Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  FinalMatchCategory = "FinalMatchCategory",
  /** column name */
  KccaCustomerId1 = "KccaCustomerId1",
  /** column name */
  KccaCustomerId2 = "KccaCustomerId2",
  /** column name */
  KccaEntityLegalName1 = "KccaEntityLegalName1",
  /** column name */
  KccaEntityLegalName2 = "KccaEntityLegalName2",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  RmatchScore = "RmatchScore",
  /** column name */
  WhoUpdated = "WhoUpdated",
  /** column name */
  Urid = "urid",
}

/** input type for updating data in table "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId1?: Maybe<Scalars["bigint"]>;
  KccaCustomerId2?: Maybe<Scalars["bigint"]>;
  KccaEntityLegalName1?: Maybe<Scalars["String"]>;
  KccaEntityLegalName2?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  urid?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_KccaToKccaNonindividualOwners_stddev_fields";
  KccaCustomerId1?: Maybe<Scalars["Float"]>;
  KccaCustomerId2?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Stddev_Order_By = {
  KccaCustomerId1?: Maybe<Order_By>;
  KccaCustomerId2?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_KccaToKccaNonindividualOwners_stddev_pop_fields";
  KccaCustomerId1?: Maybe<Scalars["Float"]>;
  KccaCustomerId2?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Stddev_Pop_Order_By = {
  KccaCustomerId1?: Maybe<Order_By>;
  KccaCustomerId2?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_KccaToKccaNonindividualOwners_stddev_samp_fields";
  KccaCustomerId1?: Maybe<Scalars["Float"]>;
  KccaCustomerId2?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Stddev_Samp_Order_By =
  {
    KccaCustomerId1?: Maybe<Order_By>;
    KccaCustomerId2?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    RmatchScore?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_KccaToKccaNonindividualOwners_sum_fields";
  KccaCustomerId1?: Maybe<Scalars["bigint"]>;
  KccaCustomerId2?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Sum_Order_By = {
  KccaCustomerId1?: Maybe<Order_By>;
  KccaCustomerId2?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_KccaToKccaNonindividualOwners_var_pop_fields";
  KccaCustomerId1?: Maybe<Scalars["Float"]>;
  KccaCustomerId2?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Var_Pop_Order_By = {
  KccaCustomerId1?: Maybe<Order_By>;
  KccaCustomerId2?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_KccaToKccaNonindividualOwners_var_samp_fields";
  KccaCustomerId1?: Maybe<Scalars["Float"]>;
  KccaCustomerId2?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Var_Samp_Order_By = {
  KccaCustomerId1?: Maybe<Order_By>;
  KccaCustomerId2?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_KccaToKccaNonindividualOwners_variance_fields";
  KccaCustomerId1?: Maybe<Scalars["Float"]>;
  KccaCustomerId2?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
export type Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Variance_Order_By = {
  KccaCustomerId1?: Maybe<Order_By>;
  KccaCustomerId2?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProperties";
  CountUra?: Maybe<Scalars["bigint"]>;
  Gml?: Maybe<Scalars["String"]>;
  JsonStructure?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
};

/** aggregated selection of "rtcs_db.rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Aggregate = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProperties_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_MatchIndividualsProperties>;
};

/** aggregate fields of "rtcs_db.rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProperties_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Rmatch_MatchIndividualsProperties_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_MatchIndividualsProperties_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProperties_avg_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Avg_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_MatchIndividualsProperties". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Bool_Exp = {
  CountUra?: Maybe<Bigint_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  JsonStructure?: Maybe<String_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchDetails?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Bigint_Comparison_Exp>;
  MatchScoreAttributes?: Maybe<String_Comparison_Exp>;
  NumOwnedNonRateable?: Maybe<Bigint_Comparison_Exp>;
  NumProperties?: Maybe<Bigint_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  SumAdjustedIncome?: Maybe<Float8_Comparison_Exp>;
  SumGrossValue?: Maybe<Float8_Comparison_Exp>;
  SumNonRateableValue?: Maybe<Float8_Comparison_Exp>;
  SumRateableValue?: Maybe<Float8_Comparison_Exp>;
  TotalRentedProperties?: Maybe<Bigint_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Inc_Input = {
  CountUra?: Maybe<Scalars["bigint"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Insert_Input = {
  CountUra?: Maybe<Scalars["bigint"]>;
  Gml?: Maybe<Scalars["String"]>;
  JsonStructure?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Max_Fields = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProperties_max_fields";
  CountUra?: Maybe<Scalars["bigint"]>;
  Gml?: Maybe<Scalars["String"]>;
  JsonStructure?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Max_Order_By = {
  CountUra?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  JsonStructure?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Min_Fields = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProperties_min_fields";
  CountUra?: Maybe<Scalars["bigint"]>;
  Gml?: Maybe<Scalars["String"]>;
  JsonStructure?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Min_Order_By = {
  CountUra?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  JsonStructure?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProperties_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_MatchIndividualsProperties>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_MatchIndividualsProperties_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Order_By = {
  CountUra?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  JsonStructure?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_MatchIndividualsProperties" */
export enum Rtcs_Db_Rmatch_MatchIndividualsProperties_Select_Column {
  /** column name */
  CountUra = "CountUra",
  /** column name */
  Gml = "Gml",
  /** column name */
  JsonStructure = "JsonStructure",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchDetails = "MatchDetails",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MatchScoreAttributes = "MatchScoreAttributes",
  /** column name */
  NumOwnedNonRateable = "NumOwnedNonRateable",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  SumAdjustedIncome = "SumAdjustedIncome",
  /** column name */
  SumGrossValue = "SumGrossValue",
  /** column name */
  SumNonRateableValue = "SumNonRateableValue",
  /** column name */
  SumRateableValue = "SumRateableValue",
  /** column name */
  TotalRentedProperties = "TotalRentedProperties",
}

/** input type for updating data in table "rtcs_db.rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Set_Input = {
  CountUra?: Maybe<Scalars["bigint"]>;
  Gml?: Maybe<Scalars["String"]>;
  JsonStructure?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProperties_stddev_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Stddev_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProperties_stddev_pop_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Stddev_Pop_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProperties_stddev_samp_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Stddev_Samp_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProperties_sum_fields";
  CountUra?: Maybe<Scalars["bigint"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Sum_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProperties_var_pop_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Var_Pop_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProperties_var_samp_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Var_Samp_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProperties_variance_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_MatchIndividualsProperties" */
export type Rtcs_Db_Rmatch_MatchIndividualsProperties_Variance_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Rmatch_MatchIndividualsProps = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProps";
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  JsonStructure?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Aggregate = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProps_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_MatchIndividualsProps>;
};

/** aggregate fields of "rtcs_db.rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProps_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_MatchIndividualsProps_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_MatchIndividualsProps_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProps_avg_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Avg_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_MatchIndividualsProps". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Bool_Exp = {
  CountUra?: Maybe<Float8_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  JsonStructure?: Maybe<String_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchDetails?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Float8_Comparison_Exp>;
  MatchScoreAttributes?: Maybe<String_Comparison_Exp>;
  NumOwnedNonRateable?: Maybe<Float8_Comparison_Exp>;
  NumProperties?: Maybe<Float8_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  SumAdjustedIncome?: Maybe<Float8_Comparison_Exp>;
  SumGrossValue?: Maybe<Float8_Comparison_Exp>;
  SumNonRateableValue?: Maybe<Float8_Comparison_Exp>;
  SumRateableValue?: Maybe<Float8_Comparison_Exp>;
  TotalRentedProperties?: Maybe<Float8_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Inc_Input = {
  CountUra?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Insert_Input = {
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  JsonStructure?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Max_Fields = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProps_max_fields";
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  JsonStructure?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Max_Order_By = {
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  JsonStructure?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Min_Fields = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProps_min_fields";
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  JsonStructure?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Min_Order_By = {
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  JsonStructure?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProps_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_MatchIndividualsProps>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_MatchIndividualsProps_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Order_By = {
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  JsonStructure?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAttributes?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_MatchIndividualsProps" */
export enum Rtcs_Db_Rmatch_MatchIndividualsProps_Select_Column {
  /** column name */
  CountUra = "CountUra",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  Gml = "Gml",
  /** column name */
  JsonStructure = "JsonStructure",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchDetails = "MatchDetails",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MatchScoreAttributes = "MatchScoreAttributes",
  /** column name */
  NumOwnedNonRateable = "NumOwnedNonRateable",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  SumAdjustedIncome = "SumAdjustedIncome",
  /** column name */
  SumGrossValue = "SumGrossValue",
  /** column name */
  SumNonRateableValue = "SumNonRateableValue",
  /** column name */
  SumRateableValue = "SumRateableValue",
  /** column name */
  TotalRentedProperties = "TotalRentedProperties",
  /** column name */
  UraTinNo = "UraTinNo",
}

/** input type for updating data in table "rtcs_db.rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Set_Input = {
  CountUra?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  JsonStructure?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  MatchScoreAttributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProps_stddev_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Stddev_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProps_stddev_pop_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Stddev_Pop_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProps_stddev_samp_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Stddev_Samp_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProps_sum_fields";
  CountUra?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumOwnedNonRateable?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Sum_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProps_var_pop_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Var_Pop_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProps_var_samp_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Var_Samp_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_MatchIndividualsProps_variance_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_MatchIndividualsProps" */
export type Rtcs_Db_Rmatch_MatchIndividualsProps_Variance_Order_By = {
  CountUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_NoReturns" */
export type Rtcs_Db_Rmatch_NoReturns = {
  __typename?: "rtcs_db_rmatch_NoReturns";
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_NoReturns" */
export type Rtcs_Db_Rmatch_NoReturns_Aggregate = {
  __typename?: "rtcs_db_rmatch_NoReturns_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_NoReturns_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_NoReturns>;
};

/** aggregate fields of "rtcs_db.rmatch_NoReturns" */
export type Rtcs_Db_Rmatch_NoReturns_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_NoReturns_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_NoReturns_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_NoReturns_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_NoReturns_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_NoReturns_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_NoReturns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_NoReturns_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_NoReturns_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_NoReturns_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_NoReturns_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_NoReturns_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_NoReturns" */
export type Rtcs_Db_Rmatch_NoReturns_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_NoReturns_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_NoReturns" */
export type Rtcs_Db_Rmatch_NoReturns_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_NoReturns_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_NoReturns_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_NoReturns_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_NoReturns_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_NoReturns_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_NoReturns_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_NoReturns_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_NoReturns_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_NoReturns_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_NoReturns_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_NoReturns" */
export type Rtcs_Db_Rmatch_NoReturns_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_NoReturns_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_NoReturns_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_NoReturns_avg_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_NoReturns" */
export type Rtcs_Db_Rmatch_NoReturns_Avg_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_NoReturns". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_NoReturns_Bool_Exp = {
  Compliancecategory?: Maybe<String_Comparison_Exp>;
  Customerid?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Isincomesrcrental?: Maybe<String_Comparison_Exp>;
  Kccadob?: Maybe<String_Comparison_Exp>;
  Kccaemail?: Maybe<String_Comparison_Exp>;
  Kccafirstname?: Maybe<String_Comparison_Exp>;
  Kccalandline?: Maybe<String_Comparison_Exp>;
  Kccamiddlename?: Maybe<String_Comparison_Exp>;
  Kccamobile1?: Maybe<String_Comparison_Exp>;
  Kccamobile2?: Maybe<String_Comparison_Exp>;
  Kccanin?: Maybe<String_Comparison_Exp>;
  Kccasurname?: Maybe<String_Comparison_Exp>;
  Kccatin?: Maybe<Float8_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Matchscore?: Maybe<Bigint_Comparison_Exp>;
  Matchscoreattributes?: Maybe<String_Comparison_Exp>;
  Numproperties?: Maybe<Bigint_Comparison_Exp>;
  Sumgrossvalue?: Maybe<Float8_Comparison_Exp>;
  Sumrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Totalrentedproperties?: Maybe<Bigint_Comparison_Exp>;
  Uraemailid?: Maybe<String_Comparison_Exp>;
  Urafirstname?: Maybe<String_Comparison_Exp>;
  Uramidlename?: Maybe<String_Comparison_Exp>;
  Uramobilenumber?: Maybe<Float8_Comparison_Exp>;
  Uranationalid?: Maybe<String_Comparison_Exp>;
  Urasurname?: Maybe<String_Comparison_Exp>;
  Uratinno?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_NoReturns_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_NoReturns_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_NoReturns_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_NoReturns" */
export type Rtcs_Db_Rmatch_NoReturns_Inc_Input = {
  Kccatin?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_NoReturns" */
export type Rtcs_Db_Rmatch_NoReturns_Insert_Input = {
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_NoReturns_Max_Fields = {
  __typename?: "rtcs_db_rmatch_NoReturns_max_fields";
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_NoReturns" */
export type Rtcs_Db_Rmatch_NoReturns_Max_Order_By = {
  Compliancecategory?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccadob?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccalandline?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccanin?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uraemailid?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramidlename?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
  Uranationalid?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_NoReturns_Min_Fields = {
  __typename?: "rtcs_db_rmatch_NoReturns_min_fields";
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_NoReturns" */
export type Rtcs_Db_Rmatch_NoReturns_Min_Order_By = {
  Compliancecategory?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccadob?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccalandline?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccanin?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uraemailid?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramidlename?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
  Uranationalid?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_NoReturns" */
export type Rtcs_Db_Rmatch_NoReturns_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_NoReturns_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_NoReturns>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_NoReturns" */
export type Rtcs_Db_Rmatch_NoReturns_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_NoReturns_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_NoReturns" */
export type Rtcs_Db_Rmatch_NoReturns_Order_By = {
  Compliancecategory?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccadob?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccalandline?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccanin?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uraemailid?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramidlename?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
  Uranationalid?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_NoReturns" */
export enum Rtcs_Db_Rmatch_NoReturns_Select_Column {
  /** column name */
  Compliancecategory = "Compliancecategory",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  File = "File",
  /** column name */
  Isincomesrcrental = "Isincomesrcrental",
  /** column name */
  Kccadob = "Kccadob",
  /** column name */
  Kccaemail = "Kccaemail",
  /** column name */
  Kccafirstname = "Kccafirstname",
  /** column name */
  Kccalandline = "Kccalandline",
  /** column name */
  Kccamiddlename = "Kccamiddlename",
  /** column name */
  Kccamobile1 = "Kccamobile1",
  /** column name */
  Kccamobile2 = "Kccamobile2",
  /** column name */
  Kccanin = "Kccanin",
  /** column name */
  Kccasurname = "Kccasurname",
  /** column name */
  Kccatin = "Kccatin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Matchscoreattributes = "Matchscoreattributes",
  /** column name */
  Numproperties = "Numproperties",
  /** column name */
  Sumgrossvalue = "Sumgrossvalue",
  /** column name */
  Sumrateablevalue = "Sumrateablevalue",
  /** column name */
  Totalrentedproperties = "Totalrentedproperties",
  /** column name */
  Uraemailid = "Uraemailid",
  /** column name */
  Urafirstname = "Urafirstname",
  /** column name */
  Uramidlename = "Uramidlename",
  /** column name */
  Uramobilenumber = "Uramobilenumber",
  /** column name */
  Uranationalid = "Uranationalid",
  /** column name */
  Urasurname = "Urasurname",
  /** column name */
  Uratinno = "Uratinno",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_NoReturns" */
export type Rtcs_Db_Rmatch_NoReturns_Set_Input = {
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_NoReturns_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_NoReturns_stddev_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_NoReturns" */
export type Rtcs_Db_Rmatch_NoReturns_Stddev_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_NoReturns_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_NoReturns_stddev_pop_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_NoReturns" */
export type Rtcs_Db_Rmatch_NoReturns_Stddev_Pop_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_NoReturns_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_NoReturns_stddev_samp_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_NoReturns" */
export type Rtcs_Db_Rmatch_NoReturns_Stddev_Samp_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_NoReturns_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_NoReturns_sum_fields";
  Kccatin?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_NoReturns" */
export type Rtcs_Db_Rmatch_NoReturns_Sum_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_NoReturns_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_NoReturns_var_pop_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_NoReturns" */
export type Rtcs_Db_Rmatch_NoReturns_Var_Pop_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_NoReturns_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_NoReturns_var_samp_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_NoReturns" */
export type Rtcs_Db_Rmatch_NoReturns_Var_Samp_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_NoReturns_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_NoReturns_variance_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_NoReturns" */
export type Rtcs_Db_Rmatch_NoReturns_Variance_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_NonIndividual" */
export type Rtcs_Db_Rmatch_NonIndividual = {
  __typename?: "rtcs_db_rmatch_NonIndividual";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport = {
  __typename?: "rtcs_db_rmatch_NonIndividualComplianceDetailReport";
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Createddate?: Maybe<Scalars["timestamptz"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  District?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Entitylegalname?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["bigint"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Ownerfirstname?: Maybe<Scalars["String"]>;
  Ownerlegalsurnamemaiden?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Propertyrnid?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Rnidowner?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytype?: Maybe<Scalars["String"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Taxpayername?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Aggregate = {
  __typename?: "rtcs_db_rmatch_NonIndividualComplianceDetailReport_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport>;
};

/** aggregate fields of "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Aggregate_Fields =
  {
    __typename?: "rtcs_db_rmatch_NonIndividualComplianceDetailReport_aggregate_fields";
    avg?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Avg_Fields>;
    count?: Maybe<Scalars["Int"]>;
    max?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Max_Fields>;
    min?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Min_Fields>;
    stddev?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Stddev_Fields>;
    stddev_pop?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Stddev_Samp_Fields>;
    sum?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Sum_Fields>;
    var_pop?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Var_Pop_Fields>;
    var_samp?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Var_Samp_Fields>;
    variance?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Variance_Fields>;
  };

/** aggregate fields of "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Aggregate_Order_By =
  {
    avg?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Max_Order_By>;
    min?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Min_Order_By>;
    stddev?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Stddev_Order_By>;
    stddev_pop?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Stddev_Samp_Order_By>;
    sum?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Sum_Order_By>;
    var_pop?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Var_Pop_Order_By>;
    var_samp?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Var_Samp_Order_By>;
    variance?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Variance_Order_By>;
  };

/** input type for inserting array relation for remote table "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualComplianceDetailReport_avg_fields";
  Currentrateablevalue?: Maybe<Scalars["Float"]>;
  Customermatchscore?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  Kccacustomerid?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Avg_Order_By = {
  Currentrateablevalue?: Maybe<Order_By>;
  Customermatchscore?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_NonIndividualComplianceDetailReport". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  Blockno?: Maybe<String_Comparison_Exp>;
  Createddate?: Maybe<Timestamptz_Comparison_Exp>;
  Currentrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Customermatchscore?: Maybe<Bigint_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  Division?: Maybe<String_Comparison_Exp>;
  Emailid?: Maybe<String_Comparison_Exp>;
  Entitylegalname?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Flatno?: Maybe<String_Comparison_Exp>;
  Grossrentvalueadjusted?: Maybe<Float8_Comparison_Exp>;
  Grossvalue?: Maybe<Float8_Comparison_Exp>;
  Holdingcompname?: Maybe<String_Comparison_Exp>;
  Holdingcomptin?: Maybe<String_Comparison_Exp>;
  Houseno?: Maybe<String_Comparison_Exp>;
  Isincomesrcbsns?: Maybe<String_Comparison_Exp>;
  Isincomesrcprpty?: Maybe<String_Comparison_Exp>;
  Isincomesrcrental?: Maybe<String_Comparison_Exp>;
  Kccacustomerid?: Maybe<Bigint_Comparison_Exp>;
  Kccaname?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  Maptitle?: Maybe<String_Comparison_Exp>;
  Matchscore?: Maybe<Float8_Comparison_Exp>;
  Mobilenumber?: Maybe<String_Comparison_Exp>;
  Orgsubtype?: Maybe<String_Comparison_Exp>;
  Orgtype?: Maybe<String_Comparison_Exp>;
  Ownerfirstname?: Maybe<String_Comparison_Exp>;
  Ownerlegalsurnamemaiden?: Maybe<String_Comparison_Exp>;
  Parish?: Maybe<String_Comparison_Exp>;
  Plotno?: Maybe<String_Comparison_Exp>;
  Propertyno?: Maybe<String_Comparison_Exp>;
  Propertyrentedstatus?: Maybe<String_Comparison_Exp>;
  Propertyrnid?: Maybe<String_Comparison_Exp>;
  Propertytype?: Maybe<String_Comparison_Exp>;
  Rateablevalue?: Maybe<Float8_Comparison_Exp>;
  Rnidcustomer?: Maybe<String_Comparison_Exp>;
  Rnidowner?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  Streetid?: Maybe<String_Comparison_Exp>;
  Subpropertytype?: Maybe<String_Comparison_Exp>;
  Taxpayerassumed?: Maybe<String_Comparison_Exp>;
  Taxpayername?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<String_Comparison_Exp>;
  Tinno?: Maybe<String_Comparison_Exp>;
  Tradingcenter?: Maybe<String_Comparison_Exp>;
  Tradingname?: Maybe<String_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Inc_Input = {
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Kccacustomerid?: Maybe<Scalars["bigint"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Createddate?: Maybe<Scalars["timestamptz"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  District?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Entitylegalname?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["bigint"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Ownerfirstname?: Maybe<Scalars["String"]>;
  Ownerlegalsurnamemaiden?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Propertyrnid?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Rnidowner?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytype?: Maybe<Scalars["String"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Taxpayername?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Max_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualComplianceDetailReport_max_fields";
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Createddate?: Maybe<Scalars["timestamptz"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  District?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Entitylegalname?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["bigint"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Ownerfirstname?: Maybe<Scalars["String"]>;
  Ownerlegalsurnamemaiden?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Propertyrnid?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Rnidowner?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytype?: Maybe<Scalars["String"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Taxpayername?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Max_Order_By = {
  Address?: Maybe<Order_By>;
  Blockno?: Maybe<Order_By>;
  Createddate?: Maybe<Order_By>;
  Currentrateablevalue?: Maybe<Order_By>;
  Customermatchscore?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  Entitylegalname?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Holdingcompname?: Maybe<Order_By>;
  Holdingcomptin?: Maybe<Order_By>;
  Houseno?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Kccaname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Maptitle?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Orgsubtype?: Maybe<Order_By>;
  Orgtype?: Maybe<Order_By>;
  Ownerfirstname?: Maybe<Order_By>;
  Ownerlegalsurnamemaiden?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  Propertyrnid?: Maybe<Order_By>;
  Propertytype?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rnidcustomer?: Maybe<Order_By>;
  Rnidowner?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subpropertytype?: Maybe<Order_By>;
  Taxpayerassumed?: Maybe<Order_By>;
  Taxpayername?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Tradingcenter?: Maybe<Order_By>;
  Tradingname?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Min_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualComplianceDetailReport_min_fields";
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Createddate?: Maybe<Scalars["timestamptz"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  District?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Entitylegalname?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["bigint"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Ownerfirstname?: Maybe<Scalars["String"]>;
  Ownerlegalsurnamemaiden?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Propertyrnid?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Rnidowner?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytype?: Maybe<Scalars["String"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Taxpayername?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Min_Order_By = {
  Address?: Maybe<Order_By>;
  Blockno?: Maybe<Order_By>;
  Createddate?: Maybe<Order_By>;
  Currentrateablevalue?: Maybe<Order_By>;
  Customermatchscore?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  Entitylegalname?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Holdingcompname?: Maybe<Order_By>;
  Holdingcomptin?: Maybe<Order_By>;
  Houseno?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Kccaname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Maptitle?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Orgsubtype?: Maybe<Order_By>;
  Orgtype?: Maybe<Order_By>;
  Ownerfirstname?: Maybe<Order_By>;
  Ownerlegalsurnamemaiden?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  Propertyrnid?: Maybe<Order_By>;
  Propertytype?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rnidcustomer?: Maybe<Order_By>;
  Rnidowner?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subpropertytype?: Maybe<Order_By>;
  Taxpayerassumed?: Maybe<Order_By>;
  Taxpayername?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Tradingcenter?: Maybe<Order_By>;
  Tradingname?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Mutation_Response =
  {
    __typename?: "rtcs_db_rmatch_NonIndividualComplianceDetailReport_mutation_response";
    /** number of affected rows by the mutation */
    affected_rows: Scalars["Int"];
    /** data of the affected rows by the mutation */
    returning: Array<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport>;
  };

/** input type for inserting object relation for remote table "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Order_By = {
  Address?: Maybe<Order_By>;
  Blockno?: Maybe<Order_By>;
  Createddate?: Maybe<Order_By>;
  Currentrateablevalue?: Maybe<Order_By>;
  Customermatchscore?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  Entitylegalname?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Holdingcompname?: Maybe<Order_By>;
  Holdingcomptin?: Maybe<Order_By>;
  Houseno?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Kccaname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Maptitle?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Orgsubtype?: Maybe<Order_By>;
  Orgtype?: Maybe<Order_By>;
  Ownerfirstname?: Maybe<Order_By>;
  Ownerlegalsurnamemaiden?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatus?: Maybe<Order_By>;
  Propertyrnid?: Maybe<Order_By>;
  Propertytype?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rnidcustomer?: Maybe<Order_By>;
  Rnidowner?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subpropertytype?: Maybe<Order_By>;
  Taxpayerassumed?: Maybe<Order_By>;
  Taxpayername?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Tradingcenter?: Maybe<Order_By>;
  Tradingname?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
export enum Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  Blockno = "Blockno",
  /** column name */
  Createddate = "Createddate",
  /** column name */
  Currentrateablevalue = "Currentrateablevalue",
  /** column name */
  Customermatchscore = "Customermatchscore",
  /** column name */
  District = "District",
  /** column name */
  Division = "Division",
  /** column name */
  Emailid = "Emailid",
  /** column name */
  Entitylegalname = "Entitylegalname",
  /** column name */
  File = "File",
  /** column name */
  Flatno = "Flatno",
  /** column name */
  Grossrentvalueadjusted = "Grossrentvalueadjusted",
  /** column name */
  Grossvalue = "Grossvalue",
  /** column name */
  Holdingcompname = "Holdingcompname",
  /** column name */
  Holdingcomptin = "Holdingcomptin",
  /** column name */
  Houseno = "Houseno",
  /** column name */
  Isincomesrcbsns = "Isincomesrcbsns",
  /** column name */
  Isincomesrcprpty = "Isincomesrcprpty",
  /** column name */
  Isincomesrcrental = "Isincomesrcrental",
  /** column name */
  Kccacustomerid = "Kccacustomerid",
  /** column name */
  Kccaname = "Kccaname",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  Maptitle = "Maptitle",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Mobilenumber = "Mobilenumber",
  /** column name */
  Orgsubtype = "Orgsubtype",
  /** column name */
  Orgtype = "Orgtype",
  /** column name */
  Ownerfirstname = "Ownerfirstname",
  /** column name */
  Ownerlegalsurnamemaiden = "Ownerlegalsurnamemaiden",
  /** column name */
  Parish = "Parish",
  /** column name */
  Plotno = "Plotno",
  /** column name */
  Propertyno = "Propertyno",
  /** column name */
  Propertyrentedstatus = "Propertyrentedstatus",
  /** column name */
  Propertyrnid = "Propertyrnid",
  /** column name */
  Propertytype = "Propertytype",
  /** column name */
  Rateablevalue = "Rateablevalue",
  /** column name */
  Rnidcustomer = "Rnidcustomer",
  /** column name */
  Rnidowner = "Rnidowner",
  /** column name */
  Source = "Source",
  /** column name */
  Streetid = "Streetid",
  /** column name */
  Subpropertytype = "Subpropertytype",
  /** column name */
  Taxpayerassumed = "Taxpayerassumed",
  /** column name */
  Taxpayername = "Taxpayername",
  /** column name */
  Tin = "Tin",
  /** column name */
  Tinno = "Tinno",
  /** column name */
  Tradingcenter = "Tradingcenter",
  /** column name */
  Tradingname = "Tradingname",
  /** column name */
  Village = "Village",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Createddate?: Maybe<Scalars["timestamptz"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  District?: Maybe<Scalars["String"]>;
  Division?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  Entitylegalname?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["bigint"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Ownerfirstname?: Maybe<Scalars["String"]>;
  Ownerlegalsurnamemaiden?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatus?: Maybe<Scalars["String"]>;
  Propertyrnid?: Maybe<Scalars["String"]>;
  Propertytype?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Rnidowner?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytype?: Maybe<Scalars["String"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Taxpayername?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualComplianceDetailReport_stddev_fields";
  Currentrateablevalue?: Maybe<Scalars["Float"]>;
  Customermatchscore?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  Kccacustomerid?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Stddev_Order_By =
  {
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Kccacustomerid?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
  };

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Stddev_Pop_Fields =
  {
    __typename?: "rtcs_db_rmatch_NonIndividualComplianceDetailReport_stddev_pop_fields";
    Currentrateablevalue?: Maybe<Scalars["Float"]>;
    Customermatchscore?: Maybe<Scalars["Float"]>;
    Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
    Grossvalue?: Maybe<Scalars["Float"]>;
    Kccacustomerid?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Rateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Stddev_Pop_Order_By =
  {
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Kccacustomerid?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
  };

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Stddev_Samp_Fields =
  {
    __typename?: "rtcs_db_rmatch_NonIndividualComplianceDetailReport_stddev_samp_fields";
    Currentrateablevalue?: Maybe<Scalars["Float"]>;
    Customermatchscore?: Maybe<Scalars["Float"]>;
    Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
    Grossvalue?: Maybe<Scalars["Float"]>;
    Kccacustomerid?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Rateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Stddev_Samp_Order_By =
  {
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Kccacustomerid?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualComplianceDetailReport_sum_fields";
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customermatchscore?: Maybe<Scalars["bigint"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Kccacustomerid?: Maybe<Scalars["bigint"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Sum_Order_By = {
  Currentrateablevalue?: Maybe<Order_By>;
  Customermatchscore?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Var_Pop_Fields =
  {
    __typename?: "rtcs_db_rmatch_NonIndividualComplianceDetailReport_var_pop_fields";
    Currentrateablevalue?: Maybe<Scalars["Float"]>;
    Customermatchscore?: Maybe<Scalars["Float"]>;
    Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
    Grossvalue?: Maybe<Scalars["Float"]>;
    Kccacustomerid?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Rateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by var_pop() on columns of table "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Var_Pop_Order_By =
  {
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Kccacustomerid?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
  };

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Var_Samp_Fields =
  {
    __typename?: "rtcs_db_rmatch_NonIndividualComplianceDetailReport_var_samp_fields";
    Currentrateablevalue?: Maybe<Scalars["Float"]>;
    Customermatchscore?: Maybe<Scalars["Float"]>;
    Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
    Grossvalue?: Maybe<Scalars["Float"]>;
    Kccacustomerid?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Rateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by var_samp() on columns of table "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Var_Samp_Order_By =
  {
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Kccacustomerid?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
  };

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Variance_Fields =
  {
    __typename?: "rtcs_db_rmatch_NonIndividualComplianceDetailReport_variance_fields";
    Currentrateablevalue?: Maybe<Scalars["Float"]>;
    Customermatchscore?: Maybe<Scalars["Float"]>;
    Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
    Grossvalue?: Maybe<Scalars["Float"]>;
    Kccacustomerid?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Rateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by variance() on columns of table "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Variance_Order_By =
  {
    Currentrateablevalue?: Maybe<Order_By>;
    Customermatchscore?: Maybe<Order_By>;
    Grossrentvalueadjusted?: Maybe<Order_By>;
    Grossvalue?: Maybe<Order_By>;
    Kccacustomerid?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Rateablevalue?: Maybe<Order_By>;
  };

/** columns and relationships of "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport = {
  __typename?: "rtcs_db_rmatch_NonIndividualComplianceSummaryReport";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Returntype?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Aggregate = {
  __typename?: "rtcs_db_rmatch_NonIndividualComplianceSummaryReport_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport>;
};

/** aggregate fields of "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Aggregate_Fields =
  {
    __typename?: "rtcs_db_rmatch_NonIndividualComplianceSummaryReport_aggregate_fields";
    avg?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Avg_Fields>;
    count?: Maybe<Scalars["Int"]>;
    max?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Max_Fields>;
    min?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Min_Fields>;
    stddev?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Stddev_Fields>;
    stddev_pop?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Stddev_Samp_Fields>;
    sum?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Sum_Fields>;
    var_pop?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Var_Pop_Fields>;
    var_samp?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Var_Samp_Fields>;
    variance?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Variance_Fields>;
  };

/** aggregate fields of "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Aggregate_Order_By =
  {
    avg?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Max_Order_By>;
    min?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Min_Order_By>;
    stddev?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Stddev_Order_By>;
    stddev_pop?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Stddev_Samp_Order_By>;
    sum?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Sum_Order_By>;
    var_pop?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Var_Pop_Order_By>;
    var_samp?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Var_Samp_Order_By>;
    variance?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Variance_Order_By>;
  };

/** input type for inserting array relation for remote table "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualComplianceSummaryReport_avg_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumofadjustedincome?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Avg_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_NonIndividualComplianceSummaryReport". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Bool_Exp = {
  Averagerateperrentedproperty?: Maybe<Float8_Comparison_Exp>;
  Compliancecategory?: Maybe<String_Comparison_Exp>;
  Difference?: Maybe<Float8_Comparison_Exp>;
  Emailid?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Formerbusinessname?: Maybe<String_Comparison_Exp>;
  Grossrentalincome?: Maybe<Float8_Comparison_Exp>;
  Holdingcompname?: Maybe<String_Comparison_Exp>;
  Holdingcomptin?: Maybe<String_Comparison_Exp>;
  Isamendment?: Maybe<String_Comparison_Exp>;
  Isincomesrcbsns?: Maybe<String_Comparison_Exp>;
  Isincomesrcprpty?: Maybe<String_Comparison_Exp>;
  Isincomesrcrental?: Maybe<String_Comparison_Exp>;
  Kccacustomerid?: Maybe<String_Comparison_Exp>;
  Kccaname?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Matchscore?: Maybe<Float8_Comparison_Exp>;
  Mobilenumber?: Maybe<String_Comparison_Exp>;
  Numproperties?: Maybe<Float8_Comparison_Exp>;
  Numrentedproperties?: Maybe<Float8_Comparison_Exp>;
  Orgsubtype?: Maybe<String_Comparison_Exp>;
  Orgtype?: Maybe<String_Comparison_Exp>;
  Percentagedifference?: Maybe<Float8_Comparison_Exp>;
  Rentincincome?: Maybe<Float8_Comparison_Exp>;
  Returntype?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Rtnno?: Maybe<String_Comparison_Exp>;
  Rtnperiodyear?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  Sumgrossvalue?: Maybe<Float8_Comparison_Exp>;
  Sumofadjustedincome?: Maybe<Float8_Comparison_Exp>;
  Sumrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Taxpayerassumed?: Maybe<String_Comparison_Exp>;
  Tinno?: Maybe<String_Comparison_Exp>;
  Tradingcenter?: Maybe<String_Comparison_Exp>;
  Tradingname?: Maybe<String_Comparison_Exp>;
  Uraname?: Maybe<String_Comparison_Exp>;
  Uratinno?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Inc_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Insert_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Returntype?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Max_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualComplianceSummaryReport_max_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Returntype?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Max_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Formerbusinessname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Holdingcompname?: Maybe<Order_By>;
  Holdingcomptin?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Kccaname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Orgsubtype?: Maybe<Order_By>;
  Orgtype?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Returntype?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Taxpayerassumed?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Tradingcenter?: Maybe<Order_By>;
  Tradingname?: Maybe<Order_By>;
  Uraname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Min_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualComplianceSummaryReport_min_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Returntype?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Min_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Formerbusinessname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Holdingcompname?: Maybe<Order_By>;
  Holdingcomptin?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Kccaname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Orgsubtype?: Maybe<Order_By>;
  Orgtype?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Returntype?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Taxpayerassumed?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Tradingcenter?: Maybe<Order_By>;
  Tradingname?: Maybe<Order_By>;
  Uraname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Mutation_Response =
  {
    __typename?: "rtcs_db_rmatch_NonIndividualComplianceSummaryReport_mutation_response";
    /** number of affected rows by the mutation */
    affected_rows: Scalars["Int"];
    /** data of the affected rows by the mutation */
    returning: Array<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport>;
  };

/** input type for inserting object relation for remote table "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Formerbusinessname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Holdingcompname?: Maybe<Order_By>;
  Holdingcomptin?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Kccaname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Orgsubtype?: Maybe<Order_By>;
  Orgtype?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Returntype?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Taxpayerassumed?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Tradingcenter?: Maybe<Order_By>;
  Tradingname?: Maybe<Order_By>;
  Uraname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
export enum Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Select_Column {
  /** column name */
  Averagerateperrentedproperty = "Averagerateperrentedproperty",
  /** column name */
  Compliancecategory = "Compliancecategory",
  /** column name */
  Difference = "Difference",
  /** column name */
  Emailid = "Emailid",
  /** column name */
  File = "File",
  /** column name */
  Formerbusinessname = "Formerbusinessname",
  /** column name */
  Grossrentalincome = "Grossrentalincome",
  /** column name */
  Holdingcompname = "Holdingcompname",
  /** column name */
  Holdingcomptin = "Holdingcomptin",
  /** column name */
  Isamendment = "Isamendment",
  /** column name */
  Isincomesrcbsns = "Isincomesrcbsns",
  /** column name */
  Isincomesrcprpty = "Isincomesrcprpty",
  /** column name */
  Isincomesrcrental = "Isincomesrcrental",
  /** column name */
  Kccacustomerid = "Kccacustomerid",
  /** column name */
  Kccaname = "Kccaname",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Mobilenumber = "Mobilenumber",
  /** column name */
  Numproperties = "Numproperties",
  /** column name */
  Numrentedproperties = "Numrentedproperties",
  /** column name */
  Orgsubtype = "Orgsubtype",
  /** column name */
  Orgtype = "Orgtype",
  /** column name */
  Percentagedifference = "Percentagedifference",
  /** column name */
  Rentincincome = "Rentincincome",
  /** column name */
  Returntype = "Returntype",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Rtnno = "Rtnno",
  /** column name */
  Rtnperiodyear = "Rtnperiodyear",
  /** column name */
  Source = "Source",
  /** column name */
  Sumgrossvalue = "Sumgrossvalue",
  /** column name */
  Sumofadjustedincome = "Sumofadjustedincome",
  /** column name */
  Sumrateablevalue = "Sumrateablevalue",
  /** column name */
  Taxpayerassumed = "Taxpayerassumed",
  /** column name */
  Tinno = "Tinno",
  /** column name */
  Tradingcenter = "Tradingcenter",
  /** column name */
  Tradingname = "Tradingname",
  /** column name */
  Uraname = "Uraname",
  /** column name */
  Uratinno = "Uratinno",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Set_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Returntype?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Stddev_Fields =
  {
    __typename?: "rtcs_db_rmatch_NonIndividualComplianceSummaryReport_stddev_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Rentincincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumofadjustedincome?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by stddev() on columns of table "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Stddev_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
  };

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Stddev_Pop_Fields =
  {
    __typename?: "rtcs_db_rmatch_NonIndividualComplianceSummaryReport_stddev_pop_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Rentincincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumofadjustedincome?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Stddev_Pop_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
  };

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Stddev_Samp_Fields =
  {
    __typename?: "rtcs_db_rmatch_NonIndividualComplianceSummaryReport_stddev_samp_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Rentincincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumofadjustedincome?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Stddev_Samp_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualComplianceSummaryReport_sum_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Sum_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Var_Pop_Fields =
  {
    __typename?: "rtcs_db_rmatch_NonIndividualComplianceSummaryReport_var_pop_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Rentincincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumofadjustedincome?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by var_pop() on columns of table "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Var_Pop_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
  };

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Var_Samp_Fields =
  {
    __typename?: "rtcs_db_rmatch_NonIndividualComplianceSummaryReport_var_samp_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Rentincincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumofadjustedincome?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by var_samp() on columns of table "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Var_Samp_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
  };

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Variance_Fields =
  {
    __typename?: "rtcs_db_rmatch_NonIndividualComplianceSummaryReport_variance_fields";
    Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
    Difference?: Maybe<Scalars["Float"]>;
    Grossrentalincome?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Matchscore?: Maybe<Scalars["Float"]>;
    Numproperties?: Maybe<Scalars["Float"]>;
    Numrentedproperties?: Maybe<Scalars["Float"]>;
    Percentagedifference?: Maybe<Scalars["Float"]>;
    Rentincincome?: Maybe<Scalars["Float"]>;
    Sumgrossvalue?: Maybe<Scalars["Float"]>;
    Sumofadjustedincome?: Maybe<Scalars["Float"]>;
    Sumrateablevalue?: Maybe<Scalars["Float"]>;
  };

/** order by variance() on columns of table "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
export type Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Variance_Order_By =
  {
    Averagerateperrentedproperty?: Maybe<Order_By>;
    Difference?: Maybe<Order_By>;
    Grossrentalincome?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Matchscore?: Maybe<Order_By>;
    Numproperties?: Maybe<Order_By>;
    Numrentedproperties?: Maybe<Order_By>;
    Percentagedifference?: Maybe<Order_By>;
    Rentincincome?: Maybe<Order_By>;
    Sumgrossvalue?: Maybe<Order_By>;
    Sumofadjustedincome?: Maybe<Order_By>;
    Sumrateablevalue?: Maybe<Order_By>;
  };

/** columns and relationships of "rtcs_db.rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore = {
  __typename?: "rtcs_db_rmatch_NonIndividualRiskScore";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["float8"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentiledifferencezscore?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Riskcode?: Maybe<Scalars["String"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Aggregate = {
  __typename?: "rtcs_db_rmatch_NonIndividualRiskScore_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_NonIndividualRiskScore>;
};

/** aggregate fields of "rtcs_db.rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualRiskScore_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividualRiskScore_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_NonIndividualRiskScore_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualRiskScore_avg_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentiledifferencezscore?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumofadjustedincome?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Avg_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_NonIndividualRiskScore". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Bool_Exp = {
  Averagerateperrentedproperty?: Maybe<Float8_Comparison_Exp>;
  Avgadjustedgrossincomeperproperty?: Maybe<Float8_Comparison_Exp>;
  Compliancecategory?: Maybe<String_Comparison_Exp>;
  Difference?: Maybe<Float8_Comparison_Exp>;
  Emailid?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Formerbusinessname?: Maybe<String_Comparison_Exp>;
  Grossrentalincome?: Maybe<Float8_Comparison_Exp>;
  Holdingcompname?: Maybe<String_Comparison_Exp>;
  Holdingcomptin?: Maybe<String_Comparison_Exp>;
  Isamendment?: Maybe<String_Comparison_Exp>;
  Isincomesrcbsns?: Maybe<String_Comparison_Exp>;
  Isincomesrcprpty?: Maybe<String_Comparison_Exp>;
  Isincomesrcrental?: Maybe<String_Comparison_Exp>;
  Kccacustomerid?: Maybe<String_Comparison_Exp>;
  Kccaname?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Matchscore?: Maybe<Float8_Comparison_Exp>;
  Mobilenumber?: Maybe<String_Comparison_Exp>;
  Numproperties?: Maybe<Float8_Comparison_Exp>;
  Numrentedproperties?: Maybe<Float8_Comparison_Exp>;
  Orgsubtype?: Maybe<String_Comparison_Exp>;
  Orgtype?: Maybe<String_Comparison_Exp>;
  Percentagedifference?: Maybe<Float8_Comparison_Exp>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Float8_Comparison_Exp>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Float8_Comparison_Exp>;
  Percentileavgperproperty?: Maybe<Float8_Comparison_Exp>;
  Percentileavgperpropertyzscore?: Maybe<Float8_Comparison_Exp>;
  Percentiledifference?: Maybe<Bigint_Comparison_Exp>;
  Percentiledifferenceavg?: Maybe<Float8_Comparison_Exp>;
  Percentiledifferenceavg1?: Maybe<Float8_Comparison_Exp>;
  Percentiledifferencestandarddeviation?: Maybe<Float8_Comparison_Exp>;
  Percentiledifferencezscore?: Maybe<Float8_Comparison_Exp>;
  Percentilenumrentedpropertiesavg?: Maybe<Float8_Comparison_Exp>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Float8_Comparison_Exp>;
  Percentilepropertyrented?: Maybe<Bigint_Comparison_Exp>;
  Percentilepropertyrentedzscore?: Maybe<Float8_Comparison_Exp>;
  Rentincincome?: Maybe<Float8_Comparison_Exp>;
  Riskcode?: Maybe<String_Comparison_Exp>;
  Riskscore?: Maybe<Bigint_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Rtnno?: Maybe<String_Comparison_Exp>;
  Rtnperiodyear?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  Sumgrossvalue?: Maybe<Float8_Comparison_Exp>;
  Sumofadjustedincome?: Maybe<Float8_Comparison_Exp>;
  Sumrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Taxpayerassumed?: Maybe<String_Comparison_Exp>;
  Tinno?: Maybe<String_Comparison_Exp>;
  Tradingcenter?: Maybe<String_Comparison_Exp>;
  Tradingname?: Maybe<String_Comparison_Exp>;
  Uraname?: Maybe<String_Comparison_Exp>;
  Uratinno?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Inc_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["float8"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentiledifferencezscore?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Insert_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["float8"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentiledifferencezscore?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Riskcode?: Maybe<Scalars["String"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Max_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualRiskScore_max_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["float8"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentiledifferencezscore?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Riskcode?: Maybe<Scalars["String"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Max_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Formerbusinessname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Holdingcompname?: Maybe<Order_By>;
  Holdingcomptin?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Kccaname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Orgsubtype?: Maybe<Order_By>;
  Orgtype?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Riskcode?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Taxpayerassumed?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Tradingcenter?: Maybe<Order_By>;
  Tradingname?: Maybe<Order_By>;
  Uraname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Min_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualRiskScore_min_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["float8"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentiledifferencezscore?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Riskcode?: Maybe<Scalars["String"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Min_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Formerbusinessname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Holdingcompname?: Maybe<Order_By>;
  Holdingcomptin?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Kccaname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Orgsubtype?: Maybe<Order_By>;
  Orgtype?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Riskcode?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Taxpayerassumed?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Tradingcenter?: Maybe<Order_By>;
  Tradingname?: Maybe<Order_By>;
  Uraname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_NonIndividualRiskScore_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_NonIndividualRiskScore>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_NonIndividualRiskScore_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Compliancecategory?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Formerbusinessname?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Holdingcompname?: Maybe<Order_By>;
  Holdingcomptin?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Kccaname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Orgsubtype?: Maybe<Order_By>;
  Orgtype?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Riskcode?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Taxpayerassumed?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Tradingcenter?: Maybe<Order_By>;
  Tradingname?: Maybe<Order_By>;
  Uraname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_NonIndividualRiskScore" */
export enum Rtcs_Db_Rmatch_NonIndividualRiskScore_Select_Column {
  /** column name */
  Averagerateperrentedproperty = "Averagerateperrentedproperty",
  /** column name */
  Avgadjustedgrossincomeperproperty = "Avgadjustedgrossincomeperproperty",
  /** column name */
  Compliancecategory = "Compliancecategory",
  /** column name */
  Difference = "Difference",
  /** column name */
  Emailid = "Emailid",
  /** column name */
  File = "File",
  /** column name */
  Formerbusinessname = "Formerbusinessname",
  /** column name */
  Grossrentalincome = "Grossrentalincome",
  /** column name */
  Holdingcompname = "Holdingcompname",
  /** column name */
  Holdingcomptin = "Holdingcomptin",
  /** column name */
  Isamendment = "Isamendment",
  /** column name */
  Isincomesrcbsns = "Isincomesrcbsns",
  /** column name */
  Isincomesrcprpty = "Isincomesrcprpty",
  /** column name */
  Isincomesrcrental = "Isincomesrcrental",
  /** column name */
  Kccacustomerid = "Kccacustomerid",
  /** column name */
  Kccaname = "Kccaname",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Mobilenumber = "Mobilenumber",
  /** column name */
  Numproperties = "Numproperties",
  /** column name */
  Numrentedproperties = "Numrentedproperties",
  /** column name */
  Orgsubtype = "Orgsubtype",
  /** column name */
  Orgtype = "Orgtype",
  /** column name */
  Percentagedifference = "Percentagedifference",
  /** column name */
  Percentileavgadjustedgrossincomeperpropertyavg = "Percentileavgadjustedgrossincomeperpropertyavg",
  /** column name */
  Percentileavgadjustedgrossincomeperpropertystandarddeviation = "Percentileavgadjustedgrossincomeperpropertystandarddeviation",
  /** column name */
  Percentileavgperproperty = "Percentileavgperproperty",
  /** column name */
  Percentileavgperpropertyzscore = "Percentileavgperpropertyzscore",
  /** column name */
  Percentiledifference = "Percentiledifference",
  /** column name */
  Percentiledifferenceavg = "Percentiledifferenceavg",
  /** column name */
  Percentiledifferenceavg1 = "Percentiledifferenceavg1",
  /** column name */
  Percentiledifferencestandarddeviation = "Percentiledifferencestandarddeviation",
  /** column name */
  Percentiledifferencezscore = "Percentiledifferencezscore",
  /** column name */
  Percentilenumrentedpropertiesavg = "Percentilenumrentedpropertiesavg",
  /** column name */
  Percentilenumrentedpropertiesstandarddeviation = "Percentilenumrentedpropertiesstandarddeviation",
  /** column name */
  Percentilepropertyrented = "Percentilepropertyrented",
  /** column name */
  Percentilepropertyrentedzscore = "Percentilepropertyrentedzscore",
  /** column name */
  Rentincincome = "Rentincincome",
  /** column name */
  Riskcode = "Riskcode",
  /** column name */
  Riskscore = "Riskscore",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Rtnno = "Rtnno",
  /** column name */
  Rtnperiodyear = "Rtnperiodyear",
  /** column name */
  Source = "Source",
  /** column name */
  Sumgrossvalue = "Sumgrossvalue",
  /** column name */
  Sumofadjustedincome = "Sumofadjustedincome",
  /** column name */
  Sumrateablevalue = "Sumrateablevalue",
  /** column name */
  Taxpayerassumed = "Taxpayerassumed",
  /** column name */
  Tinno = "Tinno",
  /** column name */
  Tradingcenter = "Tradingcenter",
  /** column name */
  Tradingname = "Tradingname",
  /** column name */
  Uraname = "Uraname",
  /** column name */
  Uratinno = "Uratinno",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Set_Input = {
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Compliancecategory?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Formerbusinessname?: Maybe<Scalars["String"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Holdingcompname?: Maybe<Scalars["String"]>;
  Holdingcomptin?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["String"]>;
  Kccaname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Orgsubtype?: Maybe<Scalars["String"]>;
  Orgtype?: Maybe<Scalars["String"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["float8"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentiledifferencezscore?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Riskcode?: Maybe<Scalars["String"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Taxpayerassumed?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  Tradingcenter?: Maybe<Scalars["String"]>;
  Tradingname?: Maybe<Scalars["String"]>;
  Uraname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualRiskScore_stddev_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentiledifferencezscore?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumofadjustedincome?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Stddev_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualRiskScore_stddev_pop_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentiledifferencezscore?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumofadjustedincome?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Stddev_Pop_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualRiskScore_stddev_samp_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentiledifferencezscore?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumofadjustedincome?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Stddev_Samp_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualRiskScore_sum_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["float8"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["float8"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Grossrentalincome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["float8"]>;
  Numrentedproperties?: Maybe<Scalars["float8"]>;
  Percentagedifference?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["float8"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["float8"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["float8"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["float8"]>;
  Percentiledifference?: Maybe<Scalars["bigint"]>;
  Percentiledifferenceavg?: Maybe<Scalars["float8"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["float8"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["float8"]>;
  Percentiledifferencezscore?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["float8"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["float8"]>;
  Percentilepropertyrented?: Maybe<Scalars["bigint"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["float8"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Riskscore?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumofadjustedincome?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Sum_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualRiskScore_var_pop_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentiledifferencezscore?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumofadjustedincome?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Var_Pop_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualRiskScore_var_samp_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentiledifferencezscore?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumofadjustedincome?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Var_Samp_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualRiskScore_variance_fields";
  Averagerateperrentedproperty?: Maybe<Scalars["Float"]>;
  Avgadjustedgrossincomeperproperty?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Grossrentalincome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Scalars["Float"]>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<
    Scalars["Float"]
  >;
  Percentileavgperproperty?: Maybe<Scalars["Float"]>;
  Percentileavgperpropertyzscore?: Maybe<Scalars["Float"]>;
  Percentiledifference?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg?: Maybe<Scalars["Float"]>;
  Percentiledifferenceavg1?: Maybe<Scalars["Float"]>;
  Percentiledifferencestandarddeviation?: Maybe<Scalars["Float"]>;
  Percentiledifferencezscore?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesavg?: Maybe<Scalars["Float"]>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Scalars["Float"]>;
  Percentilepropertyrented?: Maybe<Scalars["Float"]>;
  Percentilepropertyrentedzscore?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Riskscore?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumofadjustedincome?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_NonIndividualRiskScore" */
export type Rtcs_Db_Rmatch_NonIndividualRiskScore_Variance_Order_By = {
  Averagerateperrentedproperty?: Maybe<Order_By>;
  Avgadjustedgrossincomeperproperty?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Grossrentalincome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertyavg?: Maybe<Order_By>;
  Percentileavgadjustedgrossincomeperpropertystandarddeviation?: Maybe<Order_By>;
  Percentileavgperproperty?: Maybe<Order_By>;
  Percentileavgperpropertyzscore?: Maybe<Order_By>;
  Percentiledifference?: Maybe<Order_By>;
  Percentiledifferenceavg?: Maybe<Order_By>;
  Percentiledifferenceavg1?: Maybe<Order_By>;
  Percentiledifferencestandarddeviation?: Maybe<Order_By>;
  Percentiledifferencezscore?: Maybe<Order_By>;
  Percentilenumrentedpropertiesavg?: Maybe<Order_By>;
  Percentilenumrentedpropertiesstandarddeviation?: Maybe<Order_By>;
  Percentilepropertyrented?: Maybe<Order_By>;
  Percentilepropertyrentedzscore?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumofadjustedincome?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
};

/** aggregated selection of "rtcs_db.rmatch_NonIndividual" */
export type Rtcs_Db_Rmatch_NonIndividual_Aggregate = {
  __typename?: "rtcs_db_rmatch_NonIndividual_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_NonIndividual>;
};

/** aggregate fields of "rtcs_db.rmatch_NonIndividual" */
export type Rtcs_Db_Rmatch_NonIndividual_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividual_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_NonIndividual" */
export type Rtcs_Db_Rmatch_NonIndividual_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividual_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_NonIndividual" */
export type Rtcs_Db_Rmatch_NonIndividual_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_NonIndividual" */
export type Rtcs_Db_Rmatch_NonIndividual_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_NonIndividual_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_NonIndividual_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividual_avg_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_NonIndividual" */
export type Rtcs_Db_Rmatch_NonIndividual_Avg_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_NonIndividual". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_NonIndividual_Bool_Exp = {
  AdditionalOwners?: Maybe<Float8_Comparison_Exp>;
  AdditionalProperties?: Maybe<Float8_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  KccaName?: Maybe<String_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Float8_Comparison_Exp>;
  NumProperties?: Maybe<Bigint_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  UraName?: Maybe<String_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_NonIndividual_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_NonIndividual_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_NonIndividual" */
export type Rtcs_Db_Rmatch_NonIndividual_Inc_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_NonIndividual" */
export type Rtcs_Db_Rmatch_NonIndividual_Insert_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_NonIndividual_Max_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividual_max_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_NonIndividual" */
export type Rtcs_Db_Rmatch_NonIndividual_Max_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  KccaName?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  UraName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_NonIndividual_Min_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividual_min_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_NonIndividual" */
export type Rtcs_Db_Rmatch_NonIndividual_Min_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  KccaName?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  UraName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_NonIndividual" */
export type Rtcs_Db_Rmatch_NonIndividual_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_NonIndividual_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_NonIndividual>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_NonIndividual" */
export type Rtcs_Db_Rmatch_NonIndividual_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_NonIndividual_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_NonIndividual" */
export type Rtcs_Db_Rmatch_NonIndividual_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  KccaName?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  UraName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_NonIndividual" */
export enum Rtcs_Db_Rmatch_NonIndividual_Select_Column {
  /** column name */
  AdditionalOwners = "AdditionalOwners",
  /** column name */
  AdditionalProperties = "AdditionalProperties",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  Gml = "Gml",
  /** column name */
  KccaName = "KccaName",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Source = "Source",
  /** column name */
  UraName = "UraName",
  /** column name */
  UraTinNo = "UraTinNo",
}

/** input type for updating data in table "rtcs_db.rmatch_NonIndividual" */
export type Rtcs_Db_Rmatch_NonIndividual_Set_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_NonIndividual_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividual_stddev_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_NonIndividual" */
export type Rtcs_Db_Rmatch_NonIndividual_Stddev_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_NonIndividual_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividual_stddev_pop_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_NonIndividual" */
export type Rtcs_Db_Rmatch_NonIndividual_Stddev_Pop_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_NonIndividual_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividual_stddev_samp_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_NonIndividual" */
export type Rtcs_Db_Rmatch_NonIndividual_Stddev_Samp_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_NonIndividual_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividual_sum_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_NonIndividual" */
export type Rtcs_Db_Rmatch_NonIndividual_Sum_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_NonIndividual_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividual_var_pop_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_NonIndividual" */
export type Rtcs_Db_Rmatch_NonIndividual_Var_Pop_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_NonIndividual_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividual_var_samp_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_NonIndividual" */
export type Rtcs_Db_Rmatch_NonIndividual_Var_Samp_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_NonIndividual_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividual_variance_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_NonIndividual" */
export type Rtcs_Db_Rmatch_NonIndividual_Variance_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_NonIndividuals" */
export type Rtcs_Db_Rmatch_NonIndividuals = {
  __typename?: "rtcs_db_rmatch_NonIndividuals";
  Businessstartdt?: Maybe<Scalars["String"]>;
  Dateofincorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["float8"]>;
  Kccaentitylegalname?: Maybe<Scalars["String"]>;
  Landlinenumber?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastupdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchattributes?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Rmatchcategory?: Maybe<Scalars["String"]>;
  Rmatchconfidence?: Maybe<Scalars["float8"]>;
  Secondmobile?: Maybe<Scalars["float8"]>;
  Telephone?: Maybe<Scalars["float8"]>;
  Tin?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Urataxpayername?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
  Urid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Whoupdated?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork = {
  __typename?: "rtcs_db_rmatch_NonIndividualsNetwork";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Aggregate = {
  __typename?: "rtcs_db_rmatch_NonIndividualsNetwork_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_NonIndividualsNetwork>;
};

/** aggregate fields of "rtcs_db.rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualsNetwork_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividualsNetwork_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_NonIndividualsNetwork_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualsNetwork_avg_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Avg_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_NonIndividualsNetwork". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Bool_Exp = {
  AdditionalOwners?: Maybe<Float8_Comparison_Exp>;
  AdditionalProperties?: Maybe<Float8_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  KccaName?: Maybe<String_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Float8_Comparison_Exp>;
  NumProperties?: Maybe<Bigint_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  UraName?: Maybe<String_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Inc_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Insert_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Max_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualsNetwork_max_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Max_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  KccaName?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  UraName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Min_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualsNetwork_min_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Min_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  KccaName?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  UraName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_NonIndividualsNetwork_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_NonIndividualsNetwork>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_NonIndividualsNetwork_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  KccaName?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  UraName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_NonIndividualsNetwork" */
export enum Rtcs_Db_Rmatch_NonIndividualsNetwork_Select_Column {
  /** column name */
  AdditionalOwners = "AdditionalOwners",
  /** column name */
  AdditionalProperties = "AdditionalProperties",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  Gml = "Gml",
  /** column name */
  KccaName = "KccaName",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Source = "Source",
  /** column name */
  UraName = "UraName",
  /** column name */
  UraTinNo = "UraTinNo",
}

/** input type for updating data in table "rtcs_db.rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Set_Input = {
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualsNetwork_stddev_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Stddev_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualsNetwork_stddev_pop_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Stddev_Pop_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualsNetwork_stddev_samp_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Stddev_Samp_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualsNetwork_sum_fields";
  AdditionalOwners?: Maybe<Scalars["float8"]>;
  AdditionalProperties?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Sum_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualsNetwork_var_pop_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Var_Pop_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualsNetwork_var_samp_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Var_Samp_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividualsNetwork_variance_fields";
  AdditionalOwners?: Maybe<Scalars["Float"]>;
  AdditionalProperties?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_NonIndividualsNetwork" */
export type Rtcs_Db_Rmatch_NonIndividualsNetwork_Variance_Order_By = {
  AdditionalOwners?: Maybe<Order_By>;
  AdditionalProperties?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
};

/** aggregated selection of "rtcs_db.rmatch_NonIndividuals" */
export type Rtcs_Db_Rmatch_NonIndividuals_Aggregate = {
  __typename?: "rtcs_db_rmatch_NonIndividuals_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_NonIndividuals>;
};

/** aggregate fields of "rtcs_db.rmatch_NonIndividuals" */
export type Rtcs_Db_Rmatch_NonIndividuals_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividuals_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_NonIndividuals" */
export type Rtcs_Db_Rmatch_NonIndividuals_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividuals_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_NonIndividuals" */
export type Rtcs_Db_Rmatch_NonIndividuals_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_NonIndividuals" */
export type Rtcs_Db_Rmatch_NonIndividuals_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_NonIndividuals_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_NonIndividuals_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividuals_avg_fields";
  Kccacustomerid?: Maybe<Scalars["Float"]>;
  Landlinenumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Mobile?: Maybe<Scalars["Float"]>;
  Mobilenumber?: Maybe<Scalars["Float"]>;
  Rmatchconfidence?: Maybe<Scalars["Float"]>;
  Secondmobile?: Maybe<Scalars["Float"]>;
  Telephone?: Maybe<Scalars["Float"]>;
  Tinno?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_NonIndividuals" */
export type Rtcs_Db_Rmatch_NonIndividuals_Avg_Order_By = {
  Kccacustomerid?: Maybe<Order_By>;
  Landlinenumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Rmatchconfidence?: Maybe<Order_By>;
  Secondmobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_NonIndividuals". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_NonIndividuals_Bool_Exp = {
  Businessstartdt?: Maybe<String_Comparison_Exp>;
  Dateofincorporation?: Maybe<String_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  Emailid?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Kccacustomerid?: Maybe<Float8_Comparison_Exp>;
  Kccaentitylegalname?: Maybe<String_Comparison_Exp>;
  Landlinenumber?: Maybe<Float8_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Lastupdated?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Matchattributes?: Maybe<String_Comparison_Exp>;
  Mobile?: Maybe<Float8_Comparison_Exp>;
  Mobilenumber?: Maybe<Float8_Comparison_Exp>;
  Rmatchcategory?: Maybe<String_Comparison_Exp>;
  Rmatchconfidence?: Maybe<Float8_Comparison_Exp>;
  Secondmobile?: Maybe<Float8_Comparison_Exp>;
  Telephone?: Maybe<Float8_Comparison_Exp>;
  Tin?: Maybe<String_Comparison_Exp>;
  Tinno?: Maybe<Float8_Comparison_Exp>;
  Urataxpayername?: Maybe<String_Comparison_Exp>;
  Uratinno?: Maybe<Float8_Comparison_Exp>;
  Urid?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  Whoupdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_NonIndividuals_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_NonIndividuals_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_NonIndividuals" */
export type Rtcs_Db_Rmatch_NonIndividuals_Inc_Input = {
  Kccacustomerid?: Maybe<Scalars["float8"]>;
  Landlinenumber?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Rmatchconfidence?: Maybe<Scalars["float8"]>;
  Secondmobile?: Maybe<Scalars["float8"]>;
  Telephone?: Maybe<Scalars["float8"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_NonIndividuals" */
export type Rtcs_Db_Rmatch_NonIndividuals_Insert_Input = {
  Businessstartdt?: Maybe<Scalars["String"]>;
  Dateofincorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["float8"]>;
  Kccaentitylegalname?: Maybe<Scalars["String"]>;
  Landlinenumber?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastupdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchattributes?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Rmatchcategory?: Maybe<Scalars["String"]>;
  Rmatchconfidence?: Maybe<Scalars["float8"]>;
  Secondmobile?: Maybe<Scalars["float8"]>;
  Telephone?: Maybe<Scalars["float8"]>;
  Tin?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Urataxpayername?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
  Urid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Whoupdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_NonIndividuals_Max_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividuals_max_fields";
  Businessstartdt?: Maybe<Scalars["String"]>;
  Dateofincorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["float8"]>;
  Kccaentitylegalname?: Maybe<Scalars["String"]>;
  Landlinenumber?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastupdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchattributes?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Rmatchcategory?: Maybe<Scalars["String"]>;
  Rmatchconfidence?: Maybe<Scalars["float8"]>;
  Secondmobile?: Maybe<Scalars["float8"]>;
  Telephone?: Maybe<Scalars["float8"]>;
  Tin?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Urataxpayername?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
  Urid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Whoupdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_NonIndividuals" */
export type Rtcs_Db_Rmatch_NonIndividuals_Max_Order_By = {
  Businessstartdt?: Maybe<Order_By>;
  Dateofincorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Kccaentitylegalname?: Maybe<Order_By>;
  Landlinenumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastupdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchattributes?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Rmatchcategory?: Maybe<Order_By>;
  Rmatchconfidence?: Maybe<Order_By>;
  Secondmobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Urataxpayername?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  Urid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  Whoupdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_NonIndividuals_Min_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividuals_min_fields";
  Businessstartdt?: Maybe<Scalars["String"]>;
  Dateofincorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["float8"]>;
  Kccaentitylegalname?: Maybe<Scalars["String"]>;
  Landlinenumber?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastupdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchattributes?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Rmatchcategory?: Maybe<Scalars["String"]>;
  Rmatchconfidence?: Maybe<Scalars["float8"]>;
  Secondmobile?: Maybe<Scalars["float8"]>;
  Telephone?: Maybe<Scalars["float8"]>;
  Tin?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Urataxpayername?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
  Urid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Whoupdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_NonIndividuals" */
export type Rtcs_Db_Rmatch_NonIndividuals_Min_Order_By = {
  Businessstartdt?: Maybe<Order_By>;
  Dateofincorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Kccaentitylegalname?: Maybe<Order_By>;
  Landlinenumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastupdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchattributes?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Rmatchcategory?: Maybe<Order_By>;
  Rmatchconfidence?: Maybe<Order_By>;
  Secondmobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Urataxpayername?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  Urid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  Whoupdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_NonIndividuals" */
export type Rtcs_Db_Rmatch_NonIndividuals_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_NonIndividuals_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_NonIndividuals>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_NonIndividuals" */
export type Rtcs_Db_Rmatch_NonIndividuals_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_NonIndividuals_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_NonIndividuals" */
export type Rtcs_Db_Rmatch_NonIndividuals_Order_By = {
  Businessstartdt?: Maybe<Order_By>;
  Dateofincorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  Emailid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Kccacustomerid?: Maybe<Order_By>;
  Kccaentitylegalname?: Maybe<Order_By>;
  Landlinenumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastupdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchattributes?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Rmatchcategory?: Maybe<Order_By>;
  Rmatchconfidence?: Maybe<Order_By>;
  Secondmobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Urataxpayername?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  Urid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  Whoupdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_NonIndividuals" */
export enum Rtcs_Db_Rmatch_NonIndividuals_Select_Column {
  /** column name */
  Businessstartdt = "Businessstartdt",
  /** column name */
  Dateofincorporation = "Dateofincorporation",
  /** column name */
  Email = "Email",
  /** column name */
  Emailid = "Emailid",
  /** column name */
  File = "File",
  /** column name */
  Kccacustomerid = "Kccacustomerid",
  /** column name */
  Kccaentitylegalname = "Kccaentitylegalname",
  /** column name */
  Landlinenumber = "Landlinenumber",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Lastupdated = "Lastupdated",
  /** column name */
  Line = "Line",
  /** column name */
  Matchattributes = "Matchattributes",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  Mobilenumber = "Mobilenumber",
  /** column name */
  Rmatchcategory = "Rmatchcategory",
  /** column name */
  Rmatchconfidence = "Rmatchconfidence",
  /** column name */
  Secondmobile = "Secondmobile",
  /** column name */
  Telephone = "Telephone",
  /** column name */
  Tin = "Tin",
  /** column name */
  Tinno = "Tinno",
  /** column name */
  Urataxpayername = "Urataxpayername",
  /** column name */
  Uratinno = "Uratinno",
  /** column name */
  Urid = "Urid",
  /** column name */
  WhoUpdated = "WhoUpdated",
  /** column name */
  Whoupdated = "Whoupdated",
}

/** input type for updating data in table "rtcs_db.rmatch_NonIndividuals" */
export type Rtcs_Db_Rmatch_NonIndividuals_Set_Input = {
  Businessstartdt?: Maybe<Scalars["String"]>;
  Dateofincorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Emailid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Kccacustomerid?: Maybe<Scalars["float8"]>;
  Kccaentitylegalname?: Maybe<Scalars["String"]>;
  Landlinenumber?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastupdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchattributes?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Rmatchcategory?: Maybe<Scalars["String"]>;
  Rmatchconfidence?: Maybe<Scalars["float8"]>;
  Secondmobile?: Maybe<Scalars["float8"]>;
  Telephone?: Maybe<Scalars["float8"]>;
  Tin?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Urataxpayername?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
  Urid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  Whoupdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_NonIndividuals_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividuals_stddev_fields";
  Kccacustomerid?: Maybe<Scalars["Float"]>;
  Landlinenumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Mobile?: Maybe<Scalars["Float"]>;
  Mobilenumber?: Maybe<Scalars["Float"]>;
  Rmatchconfidence?: Maybe<Scalars["Float"]>;
  Secondmobile?: Maybe<Scalars["Float"]>;
  Telephone?: Maybe<Scalars["Float"]>;
  Tinno?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_NonIndividuals" */
export type Rtcs_Db_Rmatch_NonIndividuals_Stddev_Order_By = {
  Kccacustomerid?: Maybe<Order_By>;
  Landlinenumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Rmatchconfidence?: Maybe<Order_By>;
  Secondmobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_NonIndividuals_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividuals_stddev_pop_fields";
  Kccacustomerid?: Maybe<Scalars["Float"]>;
  Landlinenumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Mobile?: Maybe<Scalars["Float"]>;
  Mobilenumber?: Maybe<Scalars["Float"]>;
  Rmatchconfidence?: Maybe<Scalars["Float"]>;
  Secondmobile?: Maybe<Scalars["Float"]>;
  Telephone?: Maybe<Scalars["Float"]>;
  Tinno?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_NonIndividuals" */
export type Rtcs_Db_Rmatch_NonIndividuals_Stddev_Pop_Order_By = {
  Kccacustomerid?: Maybe<Order_By>;
  Landlinenumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Rmatchconfidence?: Maybe<Order_By>;
  Secondmobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_NonIndividuals_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividuals_stddev_samp_fields";
  Kccacustomerid?: Maybe<Scalars["Float"]>;
  Landlinenumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Mobile?: Maybe<Scalars["Float"]>;
  Mobilenumber?: Maybe<Scalars["Float"]>;
  Rmatchconfidence?: Maybe<Scalars["Float"]>;
  Secondmobile?: Maybe<Scalars["Float"]>;
  Telephone?: Maybe<Scalars["Float"]>;
  Tinno?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_NonIndividuals" */
export type Rtcs_Db_Rmatch_NonIndividuals_Stddev_Samp_Order_By = {
  Kccacustomerid?: Maybe<Order_By>;
  Landlinenumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Rmatchconfidence?: Maybe<Order_By>;
  Secondmobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_NonIndividuals_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividuals_sum_fields";
  Kccacustomerid?: Maybe<Scalars["float8"]>;
  Landlinenumber?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Mobile?: Maybe<Scalars["float8"]>;
  Mobilenumber?: Maybe<Scalars["float8"]>;
  Rmatchconfidence?: Maybe<Scalars["float8"]>;
  Secondmobile?: Maybe<Scalars["float8"]>;
  Telephone?: Maybe<Scalars["float8"]>;
  Tinno?: Maybe<Scalars["float8"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_NonIndividuals" */
export type Rtcs_Db_Rmatch_NonIndividuals_Sum_Order_By = {
  Kccacustomerid?: Maybe<Order_By>;
  Landlinenumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Rmatchconfidence?: Maybe<Order_By>;
  Secondmobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_NonIndividuals_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividuals_var_pop_fields";
  Kccacustomerid?: Maybe<Scalars["Float"]>;
  Landlinenumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Mobile?: Maybe<Scalars["Float"]>;
  Mobilenumber?: Maybe<Scalars["Float"]>;
  Rmatchconfidence?: Maybe<Scalars["Float"]>;
  Secondmobile?: Maybe<Scalars["Float"]>;
  Telephone?: Maybe<Scalars["Float"]>;
  Tinno?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_NonIndividuals" */
export type Rtcs_Db_Rmatch_NonIndividuals_Var_Pop_Order_By = {
  Kccacustomerid?: Maybe<Order_By>;
  Landlinenumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Rmatchconfidence?: Maybe<Order_By>;
  Secondmobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_NonIndividuals_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividuals_var_samp_fields";
  Kccacustomerid?: Maybe<Scalars["Float"]>;
  Landlinenumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Mobile?: Maybe<Scalars["Float"]>;
  Mobilenumber?: Maybe<Scalars["Float"]>;
  Rmatchconfidence?: Maybe<Scalars["Float"]>;
  Secondmobile?: Maybe<Scalars["Float"]>;
  Telephone?: Maybe<Scalars["Float"]>;
  Tinno?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_NonIndividuals" */
export type Rtcs_Db_Rmatch_NonIndividuals_Var_Samp_Order_By = {
  Kccacustomerid?: Maybe<Order_By>;
  Landlinenumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Rmatchconfidence?: Maybe<Order_By>;
  Secondmobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_NonIndividuals_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_NonIndividuals_variance_fields";
  Kccacustomerid?: Maybe<Scalars["Float"]>;
  Landlinenumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Mobile?: Maybe<Scalars["Float"]>;
  Mobilenumber?: Maybe<Scalars["Float"]>;
  Rmatchconfidence?: Maybe<Scalars["Float"]>;
  Secondmobile?: Maybe<Scalars["Float"]>;
  Telephone?: Maybe<Scalars["Float"]>;
  Tinno?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_NonIndividuals" */
export type Rtcs_Db_Rmatch_NonIndividuals_Variance_Order_By = {
  Kccacustomerid?: Maybe<Order_By>;
  Landlinenumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  Mobilenumber?: Maybe<Order_By>;
  Rmatchconfidence?: Maybe<Order_By>;
  Secondmobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_NonindividualOwners" */
export type Rtcs_Db_Rmatch_NonindividualOwners = {
  __typename?: "rtcs_db_rmatch_NonindividualOwners";
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId1?: Maybe<Scalars["String"]>;
  KccaCustomerId2?: Maybe<Scalars["String"]>;
  KccaEntityLegalName1?: Maybe<Scalars["String"]>;
  KccaEntityLegalName2?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_NonindividualOwners" */
export type Rtcs_Db_Rmatch_NonindividualOwners_Aggregate = {
  __typename?: "rtcs_db_rmatch_NonindividualOwners_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_NonindividualOwners>;
};

/** aggregate fields of "rtcs_db.rmatch_NonindividualOwners" */
export type Rtcs_Db_Rmatch_NonindividualOwners_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_NonindividualOwners_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_NonindividualOwners" */
export type Rtcs_Db_Rmatch_NonindividualOwners_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_NonindividualOwners_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_NonindividualOwners" */
export type Rtcs_Db_Rmatch_NonindividualOwners_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_NonindividualOwners" */
export type Rtcs_Db_Rmatch_NonindividualOwners_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_NonindividualOwners_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_NonindividualOwners_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_NonindividualOwners_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_NonindividualOwners" */
export type Rtcs_Db_Rmatch_NonindividualOwners_Avg_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_NonindividualOwners". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_NonindividualOwners_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  FinalMatchCategory?: Maybe<String_Comparison_Exp>;
  KccaCustomerId1?: Maybe<String_Comparison_Exp>;
  KccaCustomerId2?: Maybe<String_Comparison_Exp>;
  KccaEntityLegalName1?: Maybe<String_Comparison_Exp>;
  KccaEntityLegalName2?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  RmatchScore?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_NonindividualOwners" */
export type Rtcs_Db_Rmatch_NonindividualOwners_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_NonindividualOwners" */
export type Rtcs_Db_Rmatch_NonindividualOwners_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId1?: Maybe<Scalars["String"]>;
  KccaCustomerId2?: Maybe<Scalars["String"]>;
  KccaEntityLegalName1?: Maybe<Scalars["String"]>;
  KccaEntityLegalName2?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_NonindividualOwners_Max_Fields = {
  __typename?: "rtcs_db_rmatch_NonindividualOwners_max_fields";
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId1?: Maybe<Scalars["String"]>;
  KccaCustomerId2?: Maybe<Scalars["String"]>;
  KccaEntityLegalName1?: Maybe<Scalars["String"]>;
  KccaEntityLegalName2?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_NonindividualOwners" */
export type Rtcs_Db_Rmatch_NonindividualOwners_Max_Order_By = {
  File?: Maybe<Order_By>;
  FinalMatchCategory?: Maybe<Order_By>;
  KccaCustomerId1?: Maybe<Order_By>;
  KccaCustomerId2?: Maybe<Order_By>;
  KccaEntityLegalName1?: Maybe<Order_By>;
  KccaEntityLegalName2?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_NonindividualOwners_Min_Fields = {
  __typename?: "rtcs_db_rmatch_NonindividualOwners_min_fields";
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId1?: Maybe<Scalars["String"]>;
  KccaCustomerId2?: Maybe<Scalars["String"]>;
  KccaEntityLegalName1?: Maybe<Scalars["String"]>;
  KccaEntityLegalName2?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_NonindividualOwners" */
export type Rtcs_Db_Rmatch_NonindividualOwners_Min_Order_By = {
  File?: Maybe<Order_By>;
  FinalMatchCategory?: Maybe<Order_By>;
  KccaCustomerId1?: Maybe<Order_By>;
  KccaCustomerId2?: Maybe<Order_By>;
  KccaEntityLegalName1?: Maybe<Order_By>;
  KccaEntityLegalName2?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_NonindividualOwners" */
export type Rtcs_Db_Rmatch_NonindividualOwners_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_NonindividualOwners_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_NonindividualOwners>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_NonindividualOwners" */
export type Rtcs_Db_Rmatch_NonindividualOwners_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_NonindividualOwners_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_NonindividualOwners" */
export type Rtcs_Db_Rmatch_NonindividualOwners_Order_By = {
  File?: Maybe<Order_By>;
  FinalMatchCategory?: Maybe<Order_By>;
  KccaCustomerId1?: Maybe<Order_By>;
  KccaCustomerId2?: Maybe<Order_By>;
  KccaEntityLegalName1?: Maybe<Order_By>;
  KccaEntityLegalName2?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_NonindividualOwners" */
export enum Rtcs_Db_Rmatch_NonindividualOwners_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  FinalMatchCategory = "FinalMatchCategory",
  /** column name */
  KccaCustomerId1 = "KccaCustomerId1",
  /** column name */
  KccaCustomerId2 = "KccaCustomerId2",
  /** column name */
  KccaEntityLegalName1 = "KccaEntityLegalName1",
  /** column name */
  KccaEntityLegalName2 = "KccaEntityLegalName2",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  RmatchScore = "RmatchScore",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_NonindividualOwners" */
export type Rtcs_Db_Rmatch_NonindividualOwners_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId1?: Maybe<Scalars["String"]>;
  KccaCustomerId2?: Maybe<Scalars["String"]>;
  KccaEntityLegalName1?: Maybe<Scalars["String"]>;
  KccaEntityLegalName2?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_NonindividualOwners_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_NonindividualOwners_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_NonindividualOwners" */
export type Rtcs_Db_Rmatch_NonindividualOwners_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_NonindividualOwners_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_NonindividualOwners_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_NonindividualOwners" */
export type Rtcs_Db_Rmatch_NonindividualOwners_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_NonindividualOwners_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_NonindividualOwners_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_NonindividualOwners" */
export type Rtcs_Db_Rmatch_NonindividualOwners_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_NonindividualOwners_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_NonindividualOwners_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_NonindividualOwners" */
export type Rtcs_Db_Rmatch_NonindividualOwners_Sum_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_NonindividualOwners_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_NonindividualOwners_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_NonindividualOwners" */
export type Rtcs_Db_Rmatch_NonindividualOwners_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_NonindividualOwners_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_NonindividualOwners_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_NonindividualOwners" */
export type Rtcs_Db_Rmatch_NonindividualOwners_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_NonindividualOwners_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_NonindividualOwners_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_NonindividualOwners" */
export type Rtcs_Db_Rmatch_NonindividualOwners_Variance_Order_By = {
  Line?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_Nonindividuals" */
export type Rtcs_Db_Rmatch_Nonindividuals = {
  __typename?: "rtcs_db_rmatch_Nonindividuals";
  BusinessStartDt?: Maybe<Scalars["String"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  KccaCustomerId?: Maybe<Scalars["String"]>;
  KccaEntityLegalName?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["String"]>;
  MatchAttributes?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  RmatchCategory?: Maybe<Scalars["String"]>;
  RmatchConfidence?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  UraTaxpayerName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_Nonindividuals" */
export type Rtcs_Db_Rmatch_Nonindividuals_Aggregate = {
  __typename?: "rtcs_db_rmatch_Nonindividuals_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_Nonindividuals_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_Nonindividuals>;
};

/** aggregate fields of "rtcs_db.rmatch_Nonindividuals" */
export type Rtcs_Db_Rmatch_Nonindividuals_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_Nonindividuals_aggregate_fields";
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_Nonindividuals_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_Nonindividuals_Min_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_Nonindividuals" */
export type Rtcs_Db_Rmatch_Nonindividuals_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_Nonindividuals_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_Nonindividuals" */
export type Rtcs_Db_Rmatch_Nonindividuals_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_Nonindividuals_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_Nonindividuals_Min_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_Nonindividuals" */
export type Rtcs_Db_Rmatch_Nonindividuals_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_Nonindividuals_Insert_Input>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_Nonindividuals". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_Nonindividuals_Bool_Exp = {
  BusinessStartDt?: Maybe<String_Comparison_Exp>;
  DateOfIncorporation?: Maybe<String_Comparison_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  EmailId?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  KccaCustomerId?: Maybe<String_Comparison_Exp>;
  KccaEntityLegalName?: Maybe<String_Comparison_Exp>;
  LandlineNumber?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<String_Comparison_Exp>;
  MatchAttributes?: Maybe<String_Comparison_Exp>;
  Mobile?: Maybe<String_Comparison_Exp>;
  MobileNumber?: Maybe<String_Comparison_Exp>;
  RmatchCategory?: Maybe<String_Comparison_Exp>;
  RmatchConfidence?: Maybe<String_Comparison_Exp>;
  SecondMobile?: Maybe<String_Comparison_Exp>;
  Telephone?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  UraTaxpayerName?: Maybe<String_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_Nonindividuals_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_Nonindividuals_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_Nonindividuals_Bool_Exp>>>;
};

/** input type for inserting data into table "rtcs_db.rmatch_Nonindividuals" */
export type Rtcs_Db_Rmatch_Nonindividuals_Insert_Input = {
  BusinessStartDt?: Maybe<Scalars["String"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  KccaCustomerId?: Maybe<Scalars["String"]>;
  KccaEntityLegalName?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["String"]>;
  MatchAttributes?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  RmatchCategory?: Maybe<Scalars["String"]>;
  RmatchConfidence?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  UraTaxpayerName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_Nonindividuals_Max_Fields = {
  __typename?: "rtcs_db_rmatch_Nonindividuals_max_fields";
  BusinessStartDt?: Maybe<Scalars["String"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  KccaCustomerId?: Maybe<Scalars["String"]>;
  KccaEntityLegalName?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["String"]>;
  MatchAttributes?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  RmatchCategory?: Maybe<Scalars["String"]>;
  RmatchConfidence?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  UraTaxpayerName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_Nonindividuals" */
export type Rtcs_Db_Rmatch_Nonindividuals_Max_Order_By = {
  BusinessStartDt?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  KccaCustomerId?: Maybe<Order_By>;
  KccaEntityLegalName?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchAttributes?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  RmatchCategory?: Maybe<Order_By>;
  RmatchConfidence?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  UraTaxpayerName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_Nonindividuals_Min_Fields = {
  __typename?: "rtcs_db_rmatch_Nonindividuals_min_fields";
  BusinessStartDt?: Maybe<Scalars["String"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  KccaCustomerId?: Maybe<Scalars["String"]>;
  KccaEntityLegalName?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["String"]>;
  MatchAttributes?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  RmatchCategory?: Maybe<Scalars["String"]>;
  RmatchConfidence?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  UraTaxpayerName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_Nonindividuals" */
export type Rtcs_Db_Rmatch_Nonindividuals_Min_Order_By = {
  BusinessStartDt?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  KccaCustomerId?: Maybe<Order_By>;
  KccaEntityLegalName?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchAttributes?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  RmatchCategory?: Maybe<Order_By>;
  RmatchConfidence?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  UraTaxpayerName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_Nonindividuals" */
export type Rtcs_Db_Rmatch_Nonindividuals_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_Nonindividuals_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_Nonindividuals>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_Nonindividuals" */
export type Rtcs_Db_Rmatch_Nonindividuals_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_Nonindividuals_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_Nonindividuals" */
export type Rtcs_Db_Rmatch_Nonindividuals_Order_By = {
  BusinessStartDt?: Maybe<Order_By>;
  DateOfIncorporation?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  KccaCustomerId?: Maybe<Order_By>;
  KccaEntityLegalName?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchAttributes?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  RmatchCategory?: Maybe<Order_By>;
  RmatchConfidence?: Maybe<Order_By>;
  SecondMobile?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  UraTaxpayerName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_Nonindividuals" */
export enum Rtcs_Db_Rmatch_Nonindividuals_Select_Column {
  /** column name */
  BusinessStartDt = "BusinessStartDt",
  /** column name */
  DateOfIncorporation = "DateOfIncorporation",
  /** column name */
  Email = "Email",
  /** column name */
  EmailId = "EmailId",
  /** column name */
  File = "File",
  /** column name */
  KccaCustomerId = "KccaCustomerId",
  /** column name */
  KccaEntityLegalName = "KccaEntityLegalName",
  /** column name */
  LandlineNumber = "LandlineNumber",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MatchAttributes = "MatchAttributes",
  /** column name */
  Mobile = "Mobile",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  RmatchCategory = "RmatchCategory",
  /** column name */
  RmatchConfidence = "RmatchConfidence",
  /** column name */
  SecondMobile = "SecondMobile",
  /** column name */
  Telephone = "Telephone",
  /** column name */
  Tin = "Tin",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  UraTaxpayerName = "UraTaxpayerName",
  /** column name */
  UraTinNo = "UraTinNo",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_Nonindividuals" */
export type Rtcs_Db_Rmatch_Nonindividuals_Set_Input = {
  BusinessStartDt?: Maybe<Scalars["String"]>;
  DateOfIncorporation?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  KccaCustomerId?: Maybe<Scalars["String"]>;
  KccaEntityLegalName?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["String"]>;
  MatchAttributes?: Maybe<Scalars["String"]>;
  Mobile?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  RmatchCategory?: Maybe<Scalars["String"]>;
  RmatchConfidence?: Maybe<Scalars["String"]>;
  SecondMobile?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  UraTaxpayerName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.rmatch_PropertiesComposite" */
export type Rtcs_Db_Rmatch_PropertiesComposite = {
  __typename?: "rtcs_db_rmatch_PropertiesComposite";
  Blockno?: Maybe<Scalars["String"]>;
  Camvid?: Maybe<Scalars["String"]>;
  Countyid?: Maybe<Scalars["String"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Districtid?: Maybe<Scalars["String"]>;
  Divisionid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Imgpath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Nwsccustomerid?: Maybe<Scalars["float8"]>;
  Nwsccustomername?: Maybe<Scalars["String"]>;
  Parishid?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["String"]>;
  Propertytypeid?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sourcecustomer?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subcountyid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  Urid?: Maybe<Scalars["String"]>;
  Villageid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_PropertiesComposite" */
export type Rtcs_Db_Rmatch_PropertiesComposite_Aggregate = {
  __typename?: "rtcs_db_rmatch_PropertiesComposite_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_PropertiesComposite>;
};

/** aggregate fields of "rtcs_db.rmatch_PropertiesComposite" */
export type Rtcs_Db_Rmatch_PropertiesComposite_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_PropertiesComposite_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_PropertiesComposite" */
export type Rtcs_Db_Rmatch_PropertiesComposite_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_PropertiesComposite_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_PropertiesComposite" */
export type Rtcs_Db_Rmatch_PropertiesComposite_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_PropertiesComposite" */
export type Rtcs_Db_Rmatch_PropertiesComposite_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_PropertiesComposite_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_PropertiesComposite_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_PropertiesComposite_avg_fields";
  Currentrateablevalue?: Maybe<Scalars["Float"]>;
  Customerid?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Nwsccustomerid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_PropertiesComposite" */
export type Rtcs_Db_Rmatch_PropertiesComposite_Avg_Order_By = {
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Nwsccustomerid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_PropertiesComposite". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_PropertiesComposite_Bool_Exp = {
  Blockno?: Maybe<String_Comparison_Exp>;
  Camvid?: Maybe<String_Comparison_Exp>;
  Countyid?: Maybe<String_Comparison_Exp>;
  Currentrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Customerid?: Maybe<Bigint_Comparison_Exp>;
  Districtid?: Maybe<String_Comparison_Exp>;
  Divisionid?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Flatno?: Maybe<String_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  Grossrentvalueadjusted?: Maybe<Float8_Comparison_Exp>;
  Grossvalue?: Maybe<Float8_Comparison_Exp>;
  Imgpath?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  Maptitle?: Maybe<String_Comparison_Exp>;
  Matchcategory?: Maybe<String_Comparison_Exp>;
  Matchscore?: Maybe<Float8_Comparison_Exp>;
  Numproperties?: Maybe<Bigint_Comparison_Exp>;
  Numrentedproperties?: Maybe<Bigint_Comparison_Exp>;
  Nwsccustomerid?: Maybe<Float8_Comparison_Exp>;
  Nwsccustomername?: Maybe<String_Comparison_Exp>;
  Parishid?: Maybe<String_Comparison_Exp>;
  Plotno?: Maybe<String_Comparison_Exp>;
  Propertyno?: Maybe<String_Comparison_Exp>;
  Propertyrentedstatusid?: Maybe<String_Comparison_Exp>;
  Propertytypeid?: Maybe<String_Comparison_Exp>;
  Rateablevalue?: Maybe<Float8_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Rnidcustomer?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  Sourcecustomer?: Maybe<String_Comparison_Exp>;
  Streetid?: Maybe<String_Comparison_Exp>;
  Subcountyid?: Maybe<String_Comparison_Exp>;
  Subpropertytypeid?: Maybe<String_Comparison_Exp>;
  Supplementary?: Maybe<Float8_Comparison_Exp>;
  Urid?: Maybe<String_Comparison_Exp>;
  Villageid?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_PropertiesComposite" */
export type Rtcs_Db_Rmatch_PropertiesComposite_Inc_Input = {
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Nwsccustomerid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_PropertiesComposite" */
export type Rtcs_Db_Rmatch_PropertiesComposite_Insert_Input = {
  Blockno?: Maybe<Scalars["String"]>;
  Camvid?: Maybe<Scalars["String"]>;
  Countyid?: Maybe<Scalars["String"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Districtid?: Maybe<Scalars["String"]>;
  Divisionid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Imgpath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Nwsccustomerid?: Maybe<Scalars["float8"]>;
  Nwsccustomername?: Maybe<Scalars["String"]>;
  Parishid?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["String"]>;
  Propertytypeid?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sourcecustomer?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subcountyid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  Urid?: Maybe<Scalars["String"]>;
  Villageid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_PropertiesComposite_Max_Fields = {
  __typename?: "rtcs_db_rmatch_PropertiesComposite_max_fields";
  Blockno?: Maybe<Scalars["String"]>;
  Camvid?: Maybe<Scalars["String"]>;
  Countyid?: Maybe<Scalars["String"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Districtid?: Maybe<Scalars["String"]>;
  Divisionid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Imgpath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Nwsccustomerid?: Maybe<Scalars["float8"]>;
  Nwsccustomername?: Maybe<Scalars["String"]>;
  Parishid?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["String"]>;
  Propertytypeid?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sourcecustomer?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subcountyid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  Urid?: Maybe<Scalars["String"]>;
  Villageid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_PropertiesComposite" */
export type Rtcs_Db_Rmatch_PropertiesComposite_Max_Order_By = {
  Blockno?: Maybe<Order_By>;
  Camvid?: Maybe<Order_By>;
  Countyid?: Maybe<Order_By>;
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Districtid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Imgpath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Maptitle?: Maybe<Order_By>;
  Matchcategory?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Nwsccustomerid?: Maybe<Order_By>;
  Nwsccustomername?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rnidcustomer?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sourcecustomer?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subcountyid?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Urid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_PropertiesComposite_Min_Fields = {
  __typename?: "rtcs_db_rmatch_PropertiesComposite_min_fields";
  Blockno?: Maybe<Scalars["String"]>;
  Camvid?: Maybe<Scalars["String"]>;
  Countyid?: Maybe<Scalars["String"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Districtid?: Maybe<Scalars["String"]>;
  Divisionid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Imgpath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Nwsccustomerid?: Maybe<Scalars["float8"]>;
  Nwsccustomername?: Maybe<Scalars["String"]>;
  Parishid?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["String"]>;
  Propertytypeid?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sourcecustomer?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subcountyid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  Urid?: Maybe<Scalars["String"]>;
  Villageid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_PropertiesComposite" */
export type Rtcs_Db_Rmatch_PropertiesComposite_Min_Order_By = {
  Blockno?: Maybe<Order_By>;
  Camvid?: Maybe<Order_By>;
  Countyid?: Maybe<Order_By>;
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Districtid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Imgpath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Maptitle?: Maybe<Order_By>;
  Matchcategory?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Nwsccustomerid?: Maybe<Order_By>;
  Nwsccustomername?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rnidcustomer?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sourcecustomer?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subcountyid?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Urid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_PropertiesComposite" */
export type Rtcs_Db_Rmatch_PropertiesComposite_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_PropertiesComposite_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_PropertiesComposite>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_PropertiesComposite" */
export type Rtcs_Db_Rmatch_PropertiesComposite_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_PropertiesComposite_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_PropertiesComposite" */
export type Rtcs_Db_Rmatch_PropertiesComposite_Order_By = {
  Blockno?: Maybe<Order_By>;
  Camvid?: Maybe<Order_By>;
  Countyid?: Maybe<Order_By>;
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Districtid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Imgpath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Maptitle?: Maybe<Order_By>;
  Matchcategory?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Nwsccustomerid?: Maybe<Order_By>;
  Nwsccustomername?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rnidcustomer?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  Sourcecustomer?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subcountyid?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  Urid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_PropertiesComposite" */
export enum Rtcs_Db_Rmatch_PropertiesComposite_Select_Column {
  /** column name */
  Blockno = "Blockno",
  /** column name */
  Camvid = "Camvid",
  /** column name */
  Countyid = "Countyid",
  /** column name */
  Currentrateablevalue = "Currentrateablevalue",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  Districtid = "Districtid",
  /** column name */
  Divisionid = "Divisionid",
  /** column name */
  File = "File",
  /** column name */
  Flatno = "Flatno",
  /** column name */
  Gml = "Gml",
  /** column name */
  Grossrentvalueadjusted = "Grossrentvalueadjusted",
  /** column name */
  Grossvalue = "Grossvalue",
  /** column name */
  Imgpath = "Imgpath",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  Maptitle = "Maptitle",
  /** column name */
  Matchcategory = "Matchcategory",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Numproperties = "Numproperties",
  /** column name */
  Numrentedproperties = "Numrentedproperties",
  /** column name */
  Nwsccustomerid = "Nwsccustomerid",
  /** column name */
  Nwsccustomername = "Nwsccustomername",
  /** column name */
  Parishid = "Parishid",
  /** column name */
  Plotno = "Plotno",
  /** column name */
  Propertyno = "Propertyno",
  /** column name */
  Propertyrentedstatusid = "Propertyrentedstatusid",
  /** column name */
  Propertytypeid = "Propertytypeid",
  /** column name */
  Rateablevalue = "Rateablevalue",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Rnidcustomer = "Rnidcustomer",
  /** column name */
  Source = "Source",
  /** column name */
  Sourcecustomer = "Sourcecustomer",
  /** column name */
  Streetid = "Streetid",
  /** column name */
  Subcountyid = "Subcountyid",
  /** column name */
  Subpropertytypeid = "Subpropertytypeid",
  /** column name */
  Supplementary = "Supplementary",
  /** column name */
  Urid = "Urid",
  /** column name */
  Villageid = "Villageid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_PropertiesComposite" */
export type Rtcs_Db_Rmatch_PropertiesComposite_Set_Input = {
  Blockno?: Maybe<Scalars["String"]>;
  Camvid?: Maybe<Scalars["String"]>;
  Countyid?: Maybe<Scalars["String"]>;
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Districtid?: Maybe<Scalars["String"]>;
  Divisionid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Imgpath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Maptitle?: Maybe<Scalars["String"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Nwsccustomerid?: Maybe<Scalars["float8"]>;
  Nwsccustomername?: Maybe<Scalars["String"]>;
  Parishid?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["String"]>;
  Propertytypeid?: Maybe<Scalars["String"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rnidcustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  Sourcecustomer?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subcountyid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  Urid?: Maybe<Scalars["String"]>;
  Villageid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_PropertiesComposite_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_PropertiesComposite_stddev_fields";
  Currentrateablevalue?: Maybe<Scalars["Float"]>;
  Customerid?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Nwsccustomerid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_PropertiesComposite" */
export type Rtcs_Db_Rmatch_PropertiesComposite_Stddev_Order_By = {
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Nwsccustomerid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_PropertiesComposite_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_PropertiesComposite_stddev_pop_fields";
  Currentrateablevalue?: Maybe<Scalars["Float"]>;
  Customerid?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Nwsccustomerid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_PropertiesComposite" */
export type Rtcs_Db_Rmatch_PropertiesComposite_Stddev_Pop_Order_By = {
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Nwsccustomerid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_PropertiesComposite_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_PropertiesComposite_stddev_samp_fields";
  Currentrateablevalue?: Maybe<Scalars["Float"]>;
  Customerid?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Nwsccustomerid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_PropertiesComposite" */
export type Rtcs_Db_Rmatch_PropertiesComposite_Stddev_Samp_Order_By = {
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Nwsccustomerid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_PropertiesComposite_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_PropertiesComposite_sum_fields";
  Currentrateablevalue?: Maybe<Scalars["float8"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["float8"]>;
  Grossvalue?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Matchscore?: Maybe<Scalars["float8"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Numrentedproperties?: Maybe<Scalars["bigint"]>;
  Nwsccustomerid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_PropertiesComposite" */
export type Rtcs_Db_Rmatch_PropertiesComposite_Sum_Order_By = {
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Nwsccustomerid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_PropertiesComposite_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_PropertiesComposite_var_pop_fields";
  Currentrateablevalue?: Maybe<Scalars["Float"]>;
  Customerid?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Nwsccustomerid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_PropertiesComposite" */
export type Rtcs_Db_Rmatch_PropertiesComposite_Var_Pop_Order_By = {
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Nwsccustomerid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_PropertiesComposite_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_PropertiesComposite_var_samp_fields";
  Currentrateablevalue?: Maybe<Scalars["Float"]>;
  Customerid?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Nwsccustomerid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_PropertiesComposite" */
export type Rtcs_Db_Rmatch_PropertiesComposite_Var_Samp_Order_By = {
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Nwsccustomerid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_PropertiesComposite_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_PropertiesComposite_variance_fields";
  Currentrateablevalue?: Maybe<Scalars["Float"]>;
  Customerid?: Maybe<Scalars["Float"]>;
  Grossrentvalueadjusted?: Maybe<Scalars["Float"]>;
  Grossvalue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Numrentedproperties?: Maybe<Scalars["Float"]>;
  Nwsccustomerid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_PropertiesComposite" */
export type Rtcs_Db_Rmatch_PropertiesComposite_Variance_Order_By = {
  Currentrateablevalue?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Grossrentvalueadjusted?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Numrentedproperties?: Maybe<Order_By>;
  Nwsccustomerid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_PropertiesNetwork" */
export type Rtcs_Db_Rmatch_PropertiesNetwork = {
  __typename?: "rtcs_db_rmatch_PropertiesNetwork";
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["String"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_PropertiesNetwork" */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Aggregate = {
  __typename?: "rtcs_db_rmatch_PropertiesNetwork_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_PropertiesNetwork>;
};

/** aggregate fields of "rtcs_db.rmatch_PropertiesNetwork" */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_PropertiesNetwork_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_PropertiesNetwork" */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_PropertiesNetwork_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_PropertiesNetwork" */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_PropertiesNetwork" */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_PropertiesNetwork_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_PropertiesNetwork_avg_fields";
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_PropertiesNetwork" */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Avg_Order_By = {
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_PropertiesNetwork". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Bool_Exp = {
  BlockNo?: Maybe<String_Comparison_Exp>;
  CamvId?: Maybe<String_Comparison_Exp>;
  CountyId?: Maybe<String_Comparison_Exp>;
  CurrentRateableValue?: Maybe<String_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  DistrictId?: Maybe<String_Comparison_Exp>;
  DivisionId?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  Gml?: Maybe<String_Comparison_Exp>;
  GrossValue?: Maybe<Float8_Comparison_Exp>;
  ImgPath?: Maybe<String_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  MapTitle?: Maybe<String_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Float8_Comparison_Exp>;
  NumProperties?: Maybe<Bigint_Comparison_Exp>;
  NumRentedProperties?: Maybe<Bigint_Comparison_Exp>;
  NwscCustomerId?: Maybe<String_Comparison_Exp>;
  NwscCustomerName?: Maybe<String_Comparison_Exp>;
  ParishId?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyNo?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatusId?: Maybe<String_Comparison_Exp>;
  PropertyTypeId?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  RnidCustomer?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  SourceCustomer?: Maybe<String_Comparison_Exp>;
  Street?: Maybe<String_Comparison_Exp>;
  StreetId?: Maybe<String_Comparison_Exp>;
  SubCountyId?: Maybe<String_Comparison_Exp>;
  SubPropertyTypeId?: Maybe<String_Comparison_Exp>;
  Supplementary?: Maybe<String_Comparison_Exp>;
  VillageId?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_PropertiesNetwork" */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Inc_Input = {
  GrossValue?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_PropertiesNetwork" */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Insert_Input = {
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["String"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Max_Fields = {
  __typename?: "rtcs_db_rmatch_PropertiesNetwork_max_fields";
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["String"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_PropertiesNetwork" */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Max_Order_By = {
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerName?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidCustomer?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SourceCustomer?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Min_Fields = {
  __typename?: "rtcs_db_rmatch_PropertiesNetwork_min_fields";
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["String"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_PropertiesNetwork" */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Min_Order_By = {
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerName?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidCustomer?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SourceCustomer?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_PropertiesNetwork" */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_PropertiesNetwork_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_PropertiesNetwork>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_PropertiesNetwork" */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_PropertiesNetwork_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_PropertiesNetwork" */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Order_By = {
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CountyId?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DistrictId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  Gml?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerName?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidCustomer?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  SourceCustomer?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubCountyId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_PropertiesNetwork" */
export enum Rtcs_Db_Rmatch_PropertiesNetwork_Select_Column {
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  CamvId = "CamvId",
  /** column name */
  CountyId = "CountyId",
  /** column name */
  CurrentRateableValue = "CurrentRateableValue",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DistrictId = "DistrictId",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  Gml = "Gml",
  /** column name */
  GrossValue = "GrossValue",
  /** column name */
  ImgPath = "ImgPath",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MapTitle = "MapTitle",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  NumRentedProperties = "NumRentedProperties",
  /** column name */
  NwscCustomerId = "NwscCustomerId",
  /** column name */
  NwscCustomerName = "NwscCustomerName",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyNo = "PropertyNo",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  RnidCustomer = "RnidCustomer",
  /** column name */
  Source = "Source",
  /** column name */
  SourceCustomer = "SourceCustomer",
  /** column name */
  Street = "Street",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  SubCountyId = "SubCountyId",
  /** column name */
  SubPropertyTypeId = "SubPropertyTypeId",
  /** column name */
  Supplementary = "Supplementary",
  /** column name */
  VillageId = "VillageId",
}

/** input type for updating data in table "rtcs_db.rmatch_PropertiesNetwork" */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Set_Input = {
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CountyId?: Maybe<Scalars["String"]>;
  CurrentRateableValue?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DistrictId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  Gml?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["float8"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["String"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidCustomer?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  SourceCustomer?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubCountyId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  Supplementary?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_PropertiesNetwork_stddev_fields";
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_PropertiesNetwork" */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Stddev_Order_By = {
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_PropertiesNetwork_stddev_pop_fields";
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_PropertiesNetwork" */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Stddev_Pop_Order_By = {
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_PropertiesNetwork_stddev_samp_fields";
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_PropertiesNetwork" */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Stddev_Samp_Order_By = {
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_PropertiesNetwork_sum_fields";
  GrossValue?: Maybe<Scalars["float8"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  NumRentedProperties?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_PropertiesNetwork" */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Sum_Order_By = {
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_PropertiesNetwork_var_pop_fields";
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_PropertiesNetwork" */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Var_Pop_Order_By = {
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_PropertiesNetwork_var_samp_fields";
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_PropertiesNetwork" */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Var_Samp_Order_By = {
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_PropertiesNetwork_variance_fields";
  GrossValue?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  NumRentedProperties?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_PropertiesNetwork" */
export type Rtcs_Db_Rmatch_PropertiesNetwork_Variance_Order_By = {
  GrossValue?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  NumRentedProperties?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_PropertyNetwork" */
export type Rtcs_Db_Rmatch_PropertyNetwork = {
  __typename?: "rtcs_db_rmatch_PropertyNetwork";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RatingZoneId?: Maybe<Scalars["float8"]>;
  Remisson?: Maybe<Scalars["float8"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_PropertyNetwork" */
export type Rtcs_Db_Rmatch_PropertyNetwork_Aggregate = {
  __typename?: "rtcs_db_rmatch_PropertyNetwork_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_PropertyNetwork>;
};

/** aggregate fields of "rtcs_db.rmatch_PropertyNetwork" */
export type Rtcs_Db_Rmatch_PropertyNetwork_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_PropertyNetwork_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_PropertyNetwork" */
export type Rtcs_Db_Rmatch_PropertyNetwork_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_PropertyNetwork_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_PropertyNetwork" */
export type Rtcs_Db_Rmatch_PropertyNetwork_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_PropertyNetwork" */
export type Rtcs_Db_Rmatch_PropertyNetwork_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_PropertyNetwork_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_PropertyNetwork_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_PropertyNetwork_avg_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  RatingZoneId?: Maybe<Scalars["Float"]>;
  Remisson?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_PropertyNetwork" */
export type Rtcs_Db_Rmatch_PropertyNetwork_Avg_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RatingZoneId?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_PropertyNetwork". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_PropertyNetwork_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  BlockNo?: Maybe<String_Comparison_Exp>;
  CamvId?: Maybe<String_Comparison_Exp>;
  CreatedBy?: Maybe<Float8_Comparison_Exp>;
  CreatedDate?: Maybe<Timestamptz_Comparison_Exp>;
  CurrentRateableValue?: Maybe<Float8_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  DivisionId?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  GprsCordinateX?: Maybe<String_Comparison_Exp>;
  GprsCordinateY?: Maybe<String_Comparison_Exp>;
  GrossValue?: Maybe<String_Comparison_Exp>;
  HouseNo?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Bigint_Comparison_Exp>;
  ImgPath?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  LocationAddress?: Maybe<String_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  MapTitle?: Maybe<String_Comparison_Exp>;
  ModifiedBy?: Maybe<Float8_Comparison_Exp>;
  ModifiedDate?: Maybe<Timestamptz_Comparison_Exp>;
  ParishId?: Maybe<Float8_Comparison_Exp>;
  ParticularsFrontages?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyNo?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatusId?: Maybe<Float8_Comparison_Exp>;
  PropertyTypeId?: Maybe<Float8_Comparison_Exp>;
  RateableValue?: Maybe<Float8_Comparison_Exp>;
  RatingZoneId?: Maybe<Float8_Comparison_Exp>;
  Remisson?: Maybe<Float8_Comparison_Exp>;
  RoadOthers?: Maybe<String_Comparison_Exp>;
  SerialNo?: Maybe<String_Comparison_Exp>;
  Sid?: Maybe<String_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  StatusId?: Maybe<Float8_Comparison_Exp>;
  StreetId?: Maybe<String_Comparison_Exp>;
  SubPropertyTypeId?: Maybe<Float8_Comparison_Exp>;
  Supplementary?: Maybe<Float8_Comparison_Exp>;
  VillageId?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_PropertyNetwork" */
export type Rtcs_Db_Rmatch_PropertyNetwork_Inc_Input = {
  CreatedBy?: Maybe<Scalars["float8"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RatingZoneId?: Maybe<Scalars["float8"]>;
  Remisson?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_PropertyNetwork" */
export type Rtcs_Db_Rmatch_PropertyNetwork_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RatingZoneId?: Maybe<Scalars["float8"]>;
  Remisson?: Maybe<Scalars["float8"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_PropertyNetwork_Max_Fields = {
  __typename?: "rtcs_db_rmatch_PropertyNetwork_max_fields";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RatingZoneId?: Maybe<Scalars["float8"]>;
  Remisson?: Maybe<Scalars["float8"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_PropertyNetwork" */
export type Rtcs_Db_Rmatch_PropertyNetwork_Max_Order_By = {
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GprsCordinateX?: Maybe<Order_By>;
  GprsCordinateY?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LocationAddress?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RatingZoneId?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  RoadOthers?: Maybe<Order_By>;
  SerialNo?: Maybe<Order_By>;
  Sid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_PropertyNetwork_Min_Fields = {
  __typename?: "rtcs_db_rmatch_PropertyNetwork_min_fields";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RatingZoneId?: Maybe<Scalars["float8"]>;
  Remisson?: Maybe<Scalars["float8"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_PropertyNetwork" */
export type Rtcs_Db_Rmatch_PropertyNetwork_Min_Order_By = {
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GprsCordinateX?: Maybe<Order_By>;
  GprsCordinateY?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LocationAddress?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RatingZoneId?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  RoadOthers?: Maybe<Order_By>;
  SerialNo?: Maybe<Order_By>;
  Sid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_PropertyNetwork" */
export type Rtcs_Db_Rmatch_PropertyNetwork_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_PropertyNetwork_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_PropertyNetwork>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_PropertyNetwork" */
export type Rtcs_Db_Rmatch_PropertyNetwork_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_PropertyNetwork_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_PropertyNetwork" */
export type Rtcs_Db_Rmatch_PropertyNetwork_Order_By = {
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CamvId?: Maybe<Order_By>;
  CreatedBy?: Maybe<Order_By>;
  CreatedDate?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GprsCordinateX?: Maybe<Order_By>;
  GprsCordinateY?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  ImgPath?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LocationAddress?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MapTitle?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ModifiedDate?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  ParticularsFrontages?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyNo?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RatingZoneId?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  RoadOthers?: Maybe<Order_By>;
  SerialNo?: Maybe<Order_By>;
  Sid?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_PropertyNetwork" */
export enum Rtcs_Db_Rmatch_PropertyNetwork_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  CamvId = "CamvId",
  /** column name */
  CreatedBy = "CreatedBy",
  /** column name */
  CreatedDate = "CreatedDate",
  /** column name */
  CurrentRateableValue = "CurrentRateableValue",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  File = "File",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  GprsCordinateX = "GprsCordinateX",
  /** column name */
  GprsCordinateY = "GprsCordinateY",
  /** column name */
  GrossValue = "GrossValue",
  /** column name */
  HouseNo = "HouseNo",
  /** column name */
  Id = "Id",
  /** column name */
  ImgPath = "ImgPath",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  LocationAddress = "LocationAddress",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MapTitle = "MapTitle",
  /** column name */
  ModifiedBy = "ModifiedBy",
  /** column name */
  ModifiedDate = "ModifiedDate",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  ParticularsFrontages = "ParticularsFrontages",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyNo = "PropertyNo",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  RateableValue = "RateableValue",
  /** column name */
  RatingZoneId = "RatingZoneId",
  /** column name */
  Remisson = "Remisson",
  /** column name */
  RoadOthers = "RoadOthers",
  /** column name */
  SerialNo = "SerialNo",
  /** column name */
  Sid = "Sid",
  /** column name */
  Source = "Source",
  /** column name */
  StatusId = "StatusId",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  SubPropertyTypeId = "SubPropertyTypeId",
  /** column name */
  Supplementary = "Supplementary",
  /** column name */
  VillageId = "VillageId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_PropertyNetwork" */
export type Rtcs_Db_Rmatch_PropertyNetwork_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CamvId?: Maybe<Scalars["String"]>;
  CreatedBy?: Maybe<Scalars["float8"]>;
  CreatedDate?: Maybe<Scalars["timestamptz"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GprsCordinateX?: Maybe<Scalars["String"]>;
  GprsCordinateY?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["bigint"]>;
  ImgPath?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LocationAddress?: Maybe<Scalars["String"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MapTitle?: Maybe<Scalars["String"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ModifiedDate?: Maybe<Scalars["timestamptz"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  ParticularsFrontages?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyNo?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RatingZoneId?: Maybe<Scalars["float8"]>;
  Remisson?: Maybe<Scalars["float8"]>;
  RoadOthers?: Maybe<Scalars["String"]>;
  SerialNo?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  Source?: Maybe<Scalars["String"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_PropertyNetwork_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_PropertyNetwork_stddev_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  RatingZoneId?: Maybe<Scalars["Float"]>;
  Remisson?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_PropertyNetwork" */
export type Rtcs_Db_Rmatch_PropertyNetwork_Stddev_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RatingZoneId?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_PropertyNetwork_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_PropertyNetwork_stddev_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  RatingZoneId?: Maybe<Scalars["Float"]>;
  Remisson?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_PropertyNetwork" */
export type Rtcs_Db_Rmatch_PropertyNetwork_Stddev_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RatingZoneId?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_PropertyNetwork_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_PropertyNetwork_stddev_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  RatingZoneId?: Maybe<Scalars["Float"]>;
  Remisson?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_PropertyNetwork" */
export type Rtcs_Db_Rmatch_PropertyNetwork_Stddev_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RatingZoneId?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_PropertyNetwork_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_PropertyNetwork_sum_fields";
  CreatedBy?: Maybe<Scalars["float8"]>;
  CurrentRateableValue?: Maybe<Scalars["float8"]>;
  DivisionId?: Maybe<Scalars["float8"]>;
  Id?: Maybe<Scalars["bigint"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  ModifiedBy?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["float8"]>;
  PropertyRentedStatusId?: Maybe<Scalars["float8"]>;
  PropertyTypeId?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RatingZoneId?: Maybe<Scalars["float8"]>;
  Remisson?: Maybe<Scalars["float8"]>;
  StatusId?: Maybe<Scalars["float8"]>;
  SubPropertyTypeId?: Maybe<Scalars["float8"]>;
  Supplementary?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_PropertyNetwork" */
export type Rtcs_Db_Rmatch_PropertyNetwork_Sum_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RatingZoneId?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_PropertyNetwork_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_PropertyNetwork_var_pop_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  RatingZoneId?: Maybe<Scalars["Float"]>;
  Remisson?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_PropertyNetwork" */
export type Rtcs_Db_Rmatch_PropertyNetwork_Var_Pop_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RatingZoneId?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_PropertyNetwork_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_PropertyNetwork_var_samp_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  RatingZoneId?: Maybe<Scalars["Float"]>;
  Remisson?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_PropertyNetwork" */
export type Rtcs_Db_Rmatch_PropertyNetwork_Var_Samp_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RatingZoneId?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_PropertyNetwork_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_PropertyNetwork_variance_fields";
  CreatedBy?: Maybe<Scalars["Float"]>;
  CurrentRateableValue?: Maybe<Scalars["Float"]>;
  DivisionId?: Maybe<Scalars["Float"]>;
  Id?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  ModifiedBy?: Maybe<Scalars["Float"]>;
  ParishId?: Maybe<Scalars["Float"]>;
  PropertyRentedStatusId?: Maybe<Scalars["Float"]>;
  PropertyTypeId?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  RatingZoneId?: Maybe<Scalars["Float"]>;
  Remisson?: Maybe<Scalars["Float"]>;
  StatusId?: Maybe<Scalars["Float"]>;
  SubPropertyTypeId?: Maybe<Scalars["Float"]>;
  Supplementary?: Maybe<Scalars["Float"]>;
  VillageId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_PropertyNetwork" */
export type Rtcs_Db_Rmatch_PropertyNetwork_Variance_Order_By = {
  CreatedBy?: Maybe<Order_By>;
  CurrentRateableValue?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  ModifiedBy?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RatingZoneId?: Maybe<Order_By>;
  Remisson?: Maybe<Order_By>;
  StatusId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  Supplementary?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_RMatchDetailed904" */
export type Rtcs_Db_Rmatch_RMatchDetailed904 = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed904";
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaEmails?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile1?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAtributes?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraDob?: Maybe<Scalars["String"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_RMatchDetailed904" */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Aggregate = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed904_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_RMatchDetailed904>;
};

/** aggregate fields of "rtcs_db.rmatch_RMatchDetailed904" */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed904_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_RMatchDetailed904" */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed904_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_RMatchDetailed904" */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_RMatchDetailed904" */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_RMatchDetailed904_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed904_avg_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_RMatchDetailed904" */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Avg_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_RMatchDetailed904". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  AdjustedIncome?: Maybe<Float8_Comparison_Exp>;
  BlockNo?: Maybe<String_Comparison_Exp>;
  CountUra?: Maybe<Bigint_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  DivisionId?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  GrossValue?: Maybe<String_Comparison_Exp>;
  HouseNo?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcEmp?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  KccaDob?: Maybe<Timestamp_Comparison_Exp>;
  KccaEmail?: Maybe<String_Comparison_Exp>;
  KccaEmails?: Maybe<String_Comparison_Exp>;
  KccaFirstName?: Maybe<String_Comparison_Exp>;
  KccaLandline?: Maybe<String_Comparison_Exp>;
  KccaMiddleName?: Maybe<String_Comparison_Exp>;
  KccaMobile1?: Maybe<String_Comparison_Exp>;
  KccaMobile2?: Maybe<String_Comparison_Exp>;
  KccaNin?: Maybe<String_Comparison_Exp>;
  KccaSurname?: Maybe<String_Comparison_Exp>;
  KccaTin?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchDetails?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Bigint_Comparison_Exp>;
  MatchScoreAtributes?: Maybe<String_Comparison_Exp>;
  NumProperties?: Maybe<Bigint_Comparison_Exp>;
  ParishId?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyId?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatusId?: Maybe<String_Comparison_Exp>;
  PropertyTypeId?: Maybe<String_Comparison_Exp>;
  RateableValue?: Maybe<Float8_Comparison_Exp>;
  StreetId?: Maybe<String_Comparison_Exp>;
  SubPropertyTypeId?: Maybe<String_Comparison_Exp>;
  UraDob?: Maybe<String_Comparison_Exp>;
  UraEmailId?: Maybe<String_Comparison_Exp>;
  UraFirstname?: Maybe<String_Comparison_Exp>;
  UraLandlineNumber?: Maybe<Float8_Comparison_Exp>;
  UraMiddlename?: Maybe<String_Comparison_Exp>;
  UraMobileNumber?: Maybe<String_Comparison_Exp>;
  UraNationalId?: Maybe<String_Comparison_Exp>;
  UraSurname?: Maybe<String_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  VillageId?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_RMatchDetailed904" */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Inc_Input = {
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_RMatchDetailed904" */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaEmails?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile1?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAtributes?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraDob?: Maybe<Scalars["String"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Max_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed904_max_fields";
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaEmails?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile1?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAtributes?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraDob?: Maybe<Scalars["String"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_RMatchDetailed904" */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Max_Order_By = {
  Address?: Maybe<Order_By>;
  AdjustedIncome?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaDob?: Maybe<Order_By>;
  KccaEmail?: Maybe<Order_By>;
  KccaEmails?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaLandline?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaMobile1?: Maybe<Order_By>;
  KccaMobile2?: Maybe<Order_By>;
  KccaNin?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  KccaTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAtributes?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  UraDob?: Maybe<Order_By>;
  UraEmailId?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraMobileNumber?: Maybe<Order_By>;
  UraNationalId?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Min_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed904_min_fields";
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaEmails?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile1?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAtributes?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraDob?: Maybe<Scalars["String"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_RMatchDetailed904" */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Min_Order_By = {
  Address?: Maybe<Order_By>;
  AdjustedIncome?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaDob?: Maybe<Order_By>;
  KccaEmail?: Maybe<Order_By>;
  KccaEmails?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaLandline?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaMobile1?: Maybe<Order_By>;
  KccaMobile2?: Maybe<Order_By>;
  KccaNin?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  KccaTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAtributes?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  UraDob?: Maybe<Order_By>;
  UraEmailId?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraMobileNumber?: Maybe<Order_By>;
  UraNationalId?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_RMatchDetailed904" */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed904_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_RMatchDetailed904>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_RMatchDetailed904" */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_RMatchDetailed904_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_RMatchDetailed904" */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Order_By = {
  Address?: Maybe<Order_By>;
  AdjustedIncome?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaDob?: Maybe<Order_By>;
  KccaEmail?: Maybe<Order_By>;
  KccaEmails?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaLandline?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaMobile1?: Maybe<Order_By>;
  KccaMobile2?: Maybe<Order_By>;
  KccaNin?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  KccaTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAtributes?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  UraDob?: Maybe<Order_By>;
  UraEmailId?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraMobileNumber?: Maybe<Order_By>;
  UraNationalId?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_RMatchDetailed904" */
export enum Rtcs_Db_Rmatch_RMatchDetailed904_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  AdjustedIncome = "AdjustedIncome",
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  CountUra = "CountUra",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  File = "File",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  GrossValue = "GrossValue",
  /** column name */
  HouseNo = "HouseNo",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcEmp = "IsIncomeSrcEmp",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  KccaDob = "KccaDob",
  /** column name */
  KccaEmail = "KccaEmail",
  /** column name */
  KccaEmails = "KccaEmails",
  /** column name */
  KccaFirstName = "KccaFirstName",
  /** column name */
  KccaLandline = "KccaLandline",
  /** column name */
  KccaMiddleName = "KccaMiddleName",
  /** column name */
  KccaMobile1 = "KccaMobile1",
  /** column name */
  KccaMobile2 = "KccaMobile2",
  /** column name */
  KccaNin = "KccaNin",
  /** column name */
  KccaSurname = "KccaSurname",
  /** column name */
  KccaTin = "KccaTin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchDetails = "MatchDetails",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MatchScoreAtributes = "MatchScoreAtributes",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyId = "PropertyId",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  RateableValue = "RateableValue",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  SubPropertyTypeId = "SubPropertyTypeId",
  /** column name */
  UraDob = "UraDob",
  /** column name */
  UraEmailId = "UraEmailId",
  /** column name */
  UraFirstname = "UraFirstname",
  /** column name */
  UraLandlineNumber = "UraLandlineNumber",
  /** column name */
  UraMiddlename = "UraMiddlename",
  /** column name */
  UraMobileNumber = "UraMobileNumber",
  /** column name */
  UraNationalId = "UraNationalId",
  /** column name */
  UraSurname = "UraSurname",
  /** column name */
  UraTinNo = "UraTinNo",
  /** column name */
  VillageId = "VillageId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_RMatchDetailed904" */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaEmails?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile1?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAtributes?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraDob?: Maybe<Scalars["String"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed904_stddev_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_RMatchDetailed904" */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Stddev_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed904_stddev_pop_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_RMatchDetailed904" */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Stddev_Pop_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed904_stddev_samp_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_RMatchDetailed904" */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Stddev_Samp_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed904_sum_fields";
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_RMatchDetailed904" */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Sum_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed904_var_pop_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_RMatchDetailed904" */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Var_Pop_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed904_var_samp_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_RMatchDetailed904" */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Var_Samp_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed904_variance_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_RMatchDetailed904" */
export type Rtcs_Db_Rmatch_RMatchDetailed904_Variance_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_RMatchDetailed916" */
export type Rtcs_Db_Rmatch_RMatchDetailed916 = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed916";
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTinNo?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  RateableValueAdjusted?: Maybe<Scalars["float8"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTin?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregate =
  {
    __typename?: "rtcs_db_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregate";
    aggregate?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregate_Fields>;
    nodes: Array<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy>;
  };

/** aggregate fields of "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregate_Fields =
  {
    __typename?: "rtcs_db_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregate_fields";
    avg?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Avg_Fields>;
    count?: Maybe<Scalars["Int"]>;
    max?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Max_Fields>;
    min?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Min_Fields>;
    stddev?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Stddev_Fields>;
    stddev_pop?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Stddev_Samp_Fields>;
    sum?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Sum_Fields>;
    var_pop?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Var_Pop_Fields>;
    var_samp?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Var_Samp_Fields>;
    variance?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Variance_Fields>;
  };

/** aggregate fields of "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregate_Order_By =
  {
    avg?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Max_Order_By>;
    min?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Min_Order_By>;
    stddev?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Stddev_Order_By>;
    stddev_pop?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Stddev_Samp_Order_By>;
    sum?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Sum_Order_By>;
    var_pop?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Var_Pop_Order_By>;
    var_samp?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Var_Samp_Order_By>;
    variance?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Variance_Order_By>;
  };

/** input type for inserting array relation for remote table "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Avg_Fields =
  {
    __typename?: "rtcs_db_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_avg_fields";
    KccaTinNo?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    MatchScore?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    RateableValue?: Maybe<Scalars["Float"]>;
    RateableValueAdjusted?: Maybe<Scalars["Float"]>;
  };

/** order by avg() on columns of table "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Avg_Order_By =
  {
    KccaTinNo?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    RateableValue?: Maybe<Order_By>;
    RateableValueAdjusted?: Maybe<Order_By>;
  };

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  BlockNo?: Maybe<String_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  DivisionId?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  GrossValue?: Maybe<String_Comparison_Exp>;
  HouseNo?: Maybe<String_Comparison_Exp>;
  KccaFirstName?: Maybe<String_Comparison_Exp>;
  KccaMiddleName?: Maybe<String_Comparison_Exp>;
  KccaSurname?: Maybe<String_Comparison_Exp>;
  KccaTinNo?: Maybe<Float8_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  MatchKccaUra?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Bigint_Comparison_Exp>;
  NumProperties?: Maybe<Bigint_Comparison_Exp>;
  ParishId?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyId?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatusId?: Maybe<String_Comparison_Exp>;
  PropertyTypeId?: Maybe<String_Comparison_Exp>;
  RateableValue?: Maybe<Float8_Comparison_Exp>;
  RateableValueAdjusted?: Maybe<Float8_Comparison_Exp>;
  ScoreMatchAtributes?: Maybe<String_Comparison_Exp>;
  StreetId?: Maybe<String_Comparison_Exp>;
  SubPropertyTypeId?: Maybe<String_Comparison_Exp>;
  UraFirstname?: Maybe<String_Comparison_Exp>;
  UraMatchingTins?: Maybe<String_Comparison_Exp>;
  UraMiddlename?: Maybe<String_Comparison_Exp>;
  UraSurname?: Maybe<String_Comparison_Exp>;
  UraTin?: Maybe<String_Comparison_Exp>;
  VillageId?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<
      Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Bool_Exp>
    >
  >;
  _not?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Bool_Exp>;
  _or?: Maybe<
    Array<
      Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Bool_Exp>
    >
  >;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Inc_Input =
  {
    KccaTinNo?: Maybe<Scalars["float8"]>;
    Latitude?: Maybe<Scalars["float8"]>;
    Line?: Maybe<Scalars["bigint"]>;
    Longitude?: Maybe<Scalars["float8"]>;
    MatchScore?: Maybe<Scalars["bigint"]>;
    NumProperties?: Maybe<Scalars["bigint"]>;
    RateableValue?: Maybe<Scalars["float8"]>;
    RateableValueAdjusted?: Maybe<Scalars["float8"]>;
  };

/** input type for inserting data into table "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Insert_Input =
  {
    Address?: Maybe<Scalars["String"]>;
    BlockNo?: Maybe<Scalars["String"]>;
    CustomerId?: Maybe<Scalars["String"]>;
    DivisionId?: Maybe<Scalars["String"]>;
    File?: Maybe<Scalars["String"]>;
    FlatNo?: Maybe<Scalars["String"]>;
    GrossValue?: Maybe<Scalars["String"]>;
    HouseNo?: Maybe<Scalars["String"]>;
    KccaFirstName?: Maybe<Scalars["String"]>;
    KccaMiddleName?: Maybe<Scalars["String"]>;
    KccaSurname?: Maybe<Scalars["String"]>;
    KccaTinNo?: Maybe<Scalars["float8"]>;
    LastUpdated?: Maybe<Scalars["timestamptz"]>;
    Latitude?: Maybe<Scalars["float8"]>;
    Line?: Maybe<Scalars["bigint"]>;
    Longitude?: Maybe<Scalars["float8"]>;
    MatchKccaUra?: Maybe<Scalars["String"]>;
    MatchScore?: Maybe<Scalars["bigint"]>;
    NumProperties?: Maybe<Scalars["bigint"]>;
    ParishId?: Maybe<Scalars["String"]>;
    PlotNo?: Maybe<Scalars["String"]>;
    PropertyId?: Maybe<Scalars["String"]>;
    PropertyRentedStatusId?: Maybe<Scalars["String"]>;
    PropertyTypeId?: Maybe<Scalars["String"]>;
    RateableValue?: Maybe<Scalars["float8"]>;
    RateableValueAdjusted?: Maybe<Scalars["float8"]>;
    ScoreMatchAtributes?: Maybe<Scalars["String"]>;
    StreetId?: Maybe<Scalars["String"]>;
    SubPropertyTypeId?: Maybe<Scalars["String"]>;
    UraFirstname?: Maybe<Scalars["String"]>;
    UraMatchingTins?: Maybe<Scalars["String"]>;
    UraMiddlename?: Maybe<Scalars["String"]>;
    UraSurname?: Maybe<Scalars["String"]>;
    UraTin?: Maybe<Scalars["String"]>;
    VillageId?: Maybe<Scalars["String"]>;
    WhoUpdated?: Maybe<Scalars["String"]>;
  };

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Max_Fields =
  {
    __typename?: "rtcs_db_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_max_fields";
    Address?: Maybe<Scalars["String"]>;
    BlockNo?: Maybe<Scalars["String"]>;
    CustomerId?: Maybe<Scalars["String"]>;
    DivisionId?: Maybe<Scalars["String"]>;
    File?: Maybe<Scalars["String"]>;
    FlatNo?: Maybe<Scalars["String"]>;
    GrossValue?: Maybe<Scalars["String"]>;
    HouseNo?: Maybe<Scalars["String"]>;
    KccaFirstName?: Maybe<Scalars["String"]>;
    KccaMiddleName?: Maybe<Scalars["String"]>;
    KccaSurname?: Maybe<Scalars["String"]>;
    KccaTinNo?: Maybe<Scalars["float8"]>;
    LastUpdated?: Maybe<Scalars["timestamptz"]>;
    Latitude?: Maybe<Scalars["float8"]>;
    Line?: Maybe<Scalars["bigint"]>;
    Longitude?: Maybe<Scalars["float8"]>;
    MatchKccaUra?: Maybe<Scalars["String"]>;
    MatchScore?: Maybe<Scalars["bigint"]>;
    NumProperties?: Maybe<Scalars["bigint"]>;
    ParishId?: Maybe<Scalars["String"]>;
    PlotNo?: Maybe<Scalars["String"]>;
    PropertyId?: Maybe<Scalars["String"]>;
    PropertyRentedStatusId?: Maybe<Scalars["String"]>;
    PropertyTypeId?: Maybe<Scalars["String"]>;
    RateableValue?: Maybe<Scalars["float8"]>;
    RateableValueAdjusted?: Maybe<Scalars["float8"]>;
    ScoreMatchAtributes?: Maybe<Scalars["String"]>;
    StreetId?: Maybe<Scalars["String"]>;
    SubPropertyTypeId?: Maybe<Scalars["String"]>;
    UraFirstname?: Maybe<Scalars["String"]>;
    UraMatchingTins?: Maybe<Scalars["String"]>;
    UraMiddlename?: Maybe<Scalars["String"]>;
    UraSurname?: Maybe<Scalars["String"]>;
    UraTin?: Maybe<Scalars["String"]>;
    VillageId?: Maybe<Scalars["String"]>;
    WhoUpdated?: Maybe<Scalars["String"]>;
  };

/** order by max() on columns of table "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Max_Order_By =
  {
    Address?: Maybe<Order_By>;
    BlockNo?: Maybe<Order_By>;
    CustomerId?: Maybe<Order_By>;
    DivisionId?: Maybe<Order_By>;
    File?: Maybe<Order_By>;
    FlatNo?: Maybe<Order_By>;
    GrossValue?: Maybe<Order_By>;
    HouseNo?: Maybe<Order_By>;
    KccaFirstName?: Maybe<Order_By>;
    KccaMiddleName?: Maybe<Order_By>;
    KccaSurname?: Maybe<Order_By>;
    KccaTinNo?: Maybe<Order_By>;
    LastUpdated?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    MatchKccaUra?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    ParishId?: Maybe<Order_By>;
    PlotNo?: Maybe<Order_By>;
    PropertyId?: Maybe<Order_By>;
    PropertyRentedStatusId?: Maybe<Order_By>;
    PropertyTypeId?: Maybe<Order_By>;
    RateableValue?: Maybe<Order_By>;
    RateableValueAdjusted?: Maybe<Order_By>;
    ScoreMatchAtributes?: Maybe<Order_By>;
    StreetId?: Maybe<Order_By>;
    SubPropertyTypeId?: Maybe<Order_By>;
    UraFirstname?: Maybe<Order_By>;
    UraMatchingTins?: Maybe<Order_By>;
    UraMiddlename?: Maybe<Order_By>;
    UraSurname?: Maybe<Order_By>;
    UraTin?: Maybe<Order_By>;
    VillageId?: Maybe<Order_By>;
    WhoUpdated?: Maybe<Order_By>;
  };

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Min_Fields =
  {
    __typename?: "rtcs_db_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_min_fields";
    Address?: Maybe<Scalars["String"]>;
    BlockNo?: Maybe<Scalars["String"]>;
    CustomerId?: Maybe<Scalars["String"]>;
    DivisionId?: Maybe<Scalars["String"]>;
    File?: Maybe<Scalars["String"]>;
    FlatNo?: Maybe<Scalars["String"]>;
    GrossValue?: Maybe<Scalars["String"]>;
    HouseNo?: Maybe<Scalars["String"]>;
    KccaFirstName?: Maybe<Scalars["String"]>;
    KccaMiddleName?: Maybe<Scalars["String"]>;
    KccaSurname?: Maybe<Scalars["String"]>;
    KccaTinNo?: Maybe<Scalars["float8"]>;
    LastUpdated?: Maybe<Scalars["timestamptz"]>;
    Latitude?: Maybe<Scalars["float8"]>;
    Line?: Maybe<Scalars["bigint"]>;
    Longitude?: Maybe<Scalars["float8"]>;
    MatchKccaUra?: Maybe<Scalars["String"]>;
    MatchScore?: Maybe<Scalars["bigint"]>;
    NumProperties?: Maybe<Scalars["bigint"]>;
    ParishId?: Maybe<Scalars["String"]>;
    PlotNo?: Maybe<Scalars["String"]>;
    PropertyId?: Maybe<Scalars["String"]>;
    PropertyRentedStatusId?: Maybe<Scalars["String"]>;
    PropertyTypeId?: Maybe<Scalars["String"]>;
    RateableValue?: Maybe<Scalars["float8"]>;
    RateableValueAdjusted?: Maybe<Scalars["float8"]>;
    ScoreMatchAtributes?: Maybe<Scalars["String"]>;
    StreetId?: Maybe<Scalars["String"]>;
    SubPropertyTypeId?: Maybe<Scalars["String"]>;
    UraFirstname?: Maybe<Scalars["String"]>;
    UraMatchingTins?: Maybe<Scalars["String"]>;
    UraMiddlename?: Maybe<Scalars["String"]>;
    UraSurname?: Maybe<Scalars["String"]>;
    UraTin?: Maybe<Scalars["String"]>;
    VillageId?: Maybe<Scalars["String"]>;
    WhoUpdated?: Maybe<Scalars["String"]>;
  };

/** order by min() on columns of table "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Min_Order_By =
  {
    Address?: Maybe<Order_By>;
    BlockNo?: Maybe<Order_By>;
    CustomerId?: Maybe<Order_By>;
    DivisionId?: Maybe<Order_By>;
    File?: Maybe<Order_By>;
    FlatNo?: Maybe<Order_By>;
    GrossValue?: Maybe<Order_By>;
    HouseNo?: Maybe<Order_By>;
    KccaFirstName?: Maybe<Order_By>;
    KccaMiddleName?: Maybe<Order_By>;
    KccaSurname?: Maybe<Order_By>;
    KccaTinNo?: Maybe<Order_By>;
    LastUpdated?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    MatchKccaUra?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    ParishId?: Maybe<Order_By>;
    PlotNo?: Maybe<Order_By>;
    PropertyId?: Maybe<Order_By>;
    PropertyRentedStatusId?: Maybe<Order_By>;
    PropertyTypeId?: Maybe<Order_By>;
    RateableValue?: Maybe<Order_By>;
    RateableValueAdjusted?: Maybe<Order_By>;
    ScoreMatchAtributes?: Maybe<Order_By>;
    StreetId?: Maybe<Order_By>;
    SubPropertyTypeId?: Maybe<Order_By>;
    UraFirstname?: Maybe<Order_By>;
    UraMatchingTins?: Maybe<Order_By>;
    UraMiddlename?: Maybe<Order_By>;
    UraSurname?: Maybe<Order_By>;
    UraTin?: Maybe<Order_By>;
    VillageId?: Maybe<Order_By>;
    WhoUpdated?: Maybe<Order_By>;
  };

/** response of any mutation on the table "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Mutation_Response =
  {
    __typename?: "rtcs_db_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_mutation_response";
    /** number of affected rows by the mutation */
    affected_rows: Scalars["Int"];
    /** data of the affected rows by the mutation */
    returning: Array<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy>;
  };

/** input type for inserting object relation for remote table "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Order_By = {
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  KccaTinNo?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchKccaUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  RateableValueAdjusted?: Maybe<Order_By>;
  ScoreMatchAtributes?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraMatchingTins?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTin?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export enum Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  File = "File",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  GrossValue = "GrossValue",
  /** column name */
  HouseNo = "HouseNo",
  /** column name */
  KccaFirstName = "KccaFirstName",
  /** column name */
  KccaMiddleName = "KccaMiddleName",
  /** column name */
  KccaSurname = "KccaSurname",
  /** column name */
  KccaTinNo = "KccaTinNo",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MatchKccaUra = "MatchKccaUra",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyId = "PropertyId",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  RateableValue = "RateableValue",
  /** column name */
  RateableValueAdjusted = "RateableValueAdjusted",
  /** column name */
  ScoreMatchAtributes = "ScoreMatchAtributes",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  SubPropertyTypeId = "SubPropertyTypeId",
  /** column name */
  UraFirstname = "UraFirstname",
  /** column name */
  UraMatchingTins = "UraMatchingTins",
  /** column name */
  UraMiddlename = "UraMiddlename",
  /** column name */
  UraSurname = "UraSurname",
  /** column name */
  UraTin = "UraTin",
  /** column name */
  VillageId = "VillageId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Set_Input =
  {
    Address?: Maybe<Scalars["String"]>;
    BlockNo?: Maybe<Scalars["String"]>;
    CustomerId?: Maybe<Scalars["String"]>;
    DivisionId?: Maybe<Scalars["String"]>;
    File?: Maybe<Scalars["String"]>;
    FlatNo?: Maybe<Scalars["String"]>;
    GrossValue?: Maybe<Scalars["String"]>;
    HouseNo?: Maybe<Scalars["String"]>;
    KccaFirstName?: Maybe<Scalars["String"]>;
    KccaMiddleName?: Maybe<Scalars["String"]>;
    KccaSurname?: Maybe<Scalars["String"]>;
    KccaTinNo?: Maybe<Scalars["float8"]>;
    LastUpdated?: Maybe<Scalars["timestamptz"]>;
    Latitude?: Maybe<Scalars["float8"]>;
    Line?: Maybe<Scalars["bigint"]>;
    Longitude?: Maybe<Scalars["float8"]>;
    MatchKccaUra?: Maybe<Scalars["String"]>;
    MatchScore?: Maybe<Scalars["bigint"]>;
    NumProperties?: Maybe<Scalars["bigint"]>;
    ParishId?: Maybe<Scalars["String"]>;
    PlotNo?: Maybe<Scalars["String"]>;
    PropertyId?: Maybe<Scalars["String"]>;
    PropertyRentedStatusId?: Maybe<Scalars["String"]>;
    PropertyTypeId?: Maybe<Scalars["String"]>;
    RateableValue?: Maybe<Scalars["float8"]>;
    RateableValueAdjusted?: Maybe<Scalars["float8"]>;
    ScoreMatchAtributes?: Maybe<Scalars["String"]>;
    StreetId?: Maybe<Scalars["String"]>;
    SubPropertyTypeId?: Maybe<Scalars["String"]>;
    UraFirstname?: Maybe<Scalars["String"]>;
    UraMatchingTins?: Maybe<Scalars["String"]>;
    UraMiddlename?: Maybe<Scalars["String"]>;
    UraSurname?: Maybe<Scalars["String"]>;
    UraTin?: Maybe<Scalars["String"]>;
    VillageId?: Maybe<Scalars["String"]>;
    WhoUpdated?: Maybe<Scalars["String"]>;
  };

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Stddev_Fields =
  {
    __typename?: "rtcs_db_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_stddev_fields";
    KccaTinNo?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    MatchScore?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    RateableValue?: Maybe<Scalars["Float"]>;
    RateableValueAdjusted?: Maybe<Scalars["Float"]>;
  };

/** order by stddev() on columns of table "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Stddev_Order_By =
  {
    KccaTinNo?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    RateableValue?: Maybe<Order_By>;
    RateableValueAdjusted?: Maybe<Order_By>;
  };

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Stddev_Pop_Fields =
  {
    __typename?: "rtcs_db_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_stddev_pop_fields";
    KccaTinNo?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    MatchScore?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    RateableValue?: Maybe<Scalars["Float"]>;
    RateableValueAdjusted?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Stddev_Pop_Order_By =
  {
    KccaTinNo?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    RateableValue?: Maybe<Order_By>;
    RateableValueAdjusted?: Maybe<Order_By>;
  };

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Stddev_Samp_Fields =
  {
    __typename?: "rtcs_db_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_stddev_samp_fields";
    KccaTinNo?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    MatchScore?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    RateableValue?: Maybe<Scalars["Float"]>;
    RateableValueAdjusted?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Stddev_Samp_Order_By =
  {
    KccaTinNo?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    RateableValue?: Maybe<Order_By>;
    RateableValueAdjusted?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Sum_Fields =
  {
    __typename?: "rtcs_db_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_sum_fields";
    KccaTinNo?: Maybe<Scalars["float8"]>;
    Latitude?: Maybe<Scalars["float8"]>;
    Line?: Maybe<Scalars["bigint"]>;
    Longitude?: Maybe<Scalars["float8"]>;
    MatchScore?: Maybe<Scalars["bigint"]>;
    NumProperties?: Maybe<Scalars["bigint"]>;
    RateableValue?: Maybe<Scalars["float8"]>;
    RateableValueAdjusted?: Maybe<Scalars["float8"]>;
  };

/** order by sum() on columns of table "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Sum_Order_By =
  {
    KccaTinNo?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    RateableValue?: Maybe<Order_By>;
    RateableValueAdjusted?: Maybe<Order_By>;
  };

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Var_Pop_Fields =
  {
    __typename?: "rtcs_db_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_var_pop_fields";
    KccaTinNo?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    MatchScore?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    RateableValue?: Maybe<Scalars["Float"]>;
    RateableValueAdjusted?: Maybe<Scalars["Float"]>;
  };

/** order by var_pop() on columns of table "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Var_Pop_Order_By =
  {
    KccaTinNo?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    RateableValue?: Maybe<Order_By>;
    RateableValueAdjusted?: Maybe<Order_By>;
  };

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Var_Samp_Fields =
  {
    __typename?: "rtcs_db_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_var_samp_fields";
    KccaTinNo?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    MatchScore?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    RateableValue?: Maybe<Scalars["Float"]>;
    RateableValueAdjusted?: Maybe<Scalars["Float"]>;
  };

/** order by var_samp() on columns of table "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Var_Samp_Order_By =
  {
    KccaTinNo?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    RateableValue?: Maybe<Order_By>;
    RateableValueAdjusted?: Maybe<Order_By>;
  };

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Variance_Fields =
  {
    __typename?: "rtcs_db_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_variance_fields";
    KccaTinNo?: Maybe<Scalars["Float"]>;
    Latitude?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    Longitude?: Maybe<Scalars["Float"]>;
    MatchScore?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    RateableValue?: Maybe<Scalars["Float"]>;
    RateableValueAdjusted?: Maybe<Scalars["Float"]>;
  };

/** order by variance() on columns of table "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Variance_Order_By =
  {
    KccaTinNo?: Maybe<Order_By>;
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    RateableValue?: Maybe<Order_By>;
    RateableValueAdjusted?: Maybe<Order_By>;
  };

/** aggregated selection of "rtcs_db.rmatch_RMatchDetailed916" */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Aggregate = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed916_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_RMatchDetailed916>;
};

/** aggregate fields of "rtcs_db.rmatch_RMatchDetailed916" */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed916_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_RMatchDetailed916" */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed916_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_RMatchDetailed916" */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_RMatchDetailed916" */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_RMatchDetailed916_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed916_avg_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_RMatchDetailed916" */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Avg_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_RMatchDetailed916". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  AdjustedIncome?: Maybe<Float8_Comparison_Exp>;
  BlockNo?: Maybe<String_Comparison_Exp>;
  CountUra?: Maybe<Bigint_Comparison_Exp>;
  CustomerId?: Maybe<Bigint_Comparison_Exp>;
  DivisionId?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  GrossValue?: Maybe<String_Comparison_Exp>;
  HouseNo?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcEmp?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  KccaFirstName?: Maybe<String_Comparison_Exp>;
  KccaMiddleName?: Maybe<String_Comparison_Exp>;
  KccaSurname?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  MatchKccaUra?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Bigint_Comparison_Exp>;
  NumProperties?: Maybe<Bigint_Comparison_Exp>;
  ParishId?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyId?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatusId?: Maybe<String_Comparison_Exp>;
  PropertyTypeId?: Maybe<String_Comparison_Exp>;
  RateableValue?: Maybe<Float8_Comparison_Exp>;
  ScoreMatchAtributes?: Maybe<String_Comparison_Exp>;
  StreetId?: Maybe<String_Comparison_Exp>;
  SubPropertyTypeId?: Maybe<String_Comparison_Exp>;
  UraEmailId?: Maybe<String_Comparison_Exp>;
  UraFirstname?: Maybe<String_Comparison_Exp>;
  UraMatchingTins?: Maybe<String_Comparison_Exp>;
  UraMiddlename?: Maybe<String_Comparison_Exp>;
  UraMobileNumber?: Maybe<String_Comparison_Exp>;
  UraNationalId?: Maybe<String_Comparison_Exp>;
  UraSurname?: Maybe<String_Comparison_Exp>;
  VillageId?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_RMatchDetailed916" */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Inc_Input = {
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_RMatchDetailed916" */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Max_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed916_max_fields";
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_RMatchDetailed916" */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Max_Order_By = {
  Address?: Maybe<Order_By>;
  AdjustedIncome?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchKccaUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  ScoreMatchAtributes?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  UraEmailId?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraMatchingTins?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraMobileNumber?: Maybe<Order_By>;
  UraNationalId?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Min_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed916_min_fields";
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_RMatchDetailed916" */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Min_Order_By = {
  Address?: Maybe<Order_By>;
  AdjustedIncome?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchKccaUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  ScoreMatchAtributes?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  UraEmailId?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraMatchingTins?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraMobileNumber?: Maybe<Order_By>;
  UraNationalId?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_RMatchDetailed916" */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed916_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_RMatchDetailed916>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_RMatchDetailed916" */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_RMatchDetailed916_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_RMatchDetailed916" */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Order_By = {
  Address?: Maybe<Order_By>;
  AdjustedIncome?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchKccaUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  ScoreMatchAtributes?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  UraEmailId?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraMatchingTins?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraMobileNumber?: Maybe<Order_By>;
  UraNationalId?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_RMatchDetailed916" */
export enum Rtcs_Db_Rmatch_RMatchDetailed916_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  AdjustedIncome = "AdjustedIncome",
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  CountUra = "CountUra",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  File = "File",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  GrossValue = "GrossValue",
  /** column name */
  HouseNo = "HouseNo",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcEmp = "IsIncomeSrcEmp",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  KccaFirstName = "KccaFirstName",
  /** column name */
  KccaMiddleName = "KccaMiddleName",
  /** column name */
  KccaSurname = "KccaSurname",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MatchKccaUra = "MatchKccaUra",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyId = "PropertyId",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  RateableValue = "RateableValue",
  /** column name */
  ScoreMatchAtributes = "ScoreMatchAtributes",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  SubPropertyTypeId = "SubPropertyTypeId",
  /** column name */
  UraEmailId = "UraEmailId",
  /** column name */
  UraFirstname = "UraFirstname",
  /** column name */
  UraMatchingTins = "UraMatchingTins",
  /** column name */
  UraMiddlename = "UraMiddlename",
  /** column name */
  UraMobileNumber = "UraMobileNumber",
  /** column name */
  UraNationalId = "UraNationalId",
  /** column name */
  UraSurname = "UraSurname",
  /** column name */
  VillageId = "VillageId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_RMatchDetailed916" */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed916_stddev_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_RMatchDetailed916" */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Stddev_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed916_stddev_pop_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_RMatchDetailed916" */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Stddev_Pop_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed916_stddev_samp_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_RMatchDetailed916" */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Stddev_Samp_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed916_sum_fields";
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_RMatchDetailed916" */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Sum_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed916_var_pop_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_RMatchDetailed916" */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Var_Pop_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed916_var_samp_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_RMatchDetailed916" */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Var_Samp_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed916_variance_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_RMatchDetailed916" */
export type Rtcs_Db_Rmatch_RMatchDetailed916_Variance_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_RMatchDetailed937" */
export type Rtcs_Db_Rmatch_RMatchDetailed937 = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed937";
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaEmails?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaNiraMatchAtributes?: Maybe<Scalars["String"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["float8"]>;
  KccaNiraMatchScore?: Maybe<Scalars["float8"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchDobKccaUra?: Maybe<Scalars["String"]>;
  MatchDobNiraUra?: Maybe<Scalars["String"]>;
  MatchEmailKccaUra?: Maybe<Scalars["String"]>;
  MatchKccaDob?: Maybe<Scalars["String"]>;
  MatchKccaEmail?: Maybe<Scalars["String"]>;
  MatchKccaFirstName?: Maybe<Scalars["String"]>;
  MatchKccaLandline?: Maybe<Scalars["String"]>;
  MatchKccaMiddleName?: Maybe<Scalars["String"]>;
  MatchKccaMobile?: Maybe<Scalars["String"]>;
  MatchKccaMobile2?: Maybe<Scalars["String"]>;
  MatchKccaNin?: Maybe<Scalars["String"]>;
  MatchKccaSurname?: Maybe<Scalars["String"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchNinKccaUra?: Maybe<Scalars["String"]>;
  MatchNiraDob?: Maybe<Scalars["String"]>;
  MatchNiraFirstname?: Maybe<Scalars["String"]>;
  MatchNiraMobile?: Maybe<Scalars["String"]>;
  MatchNiraSurname?: Maybe<Scalars["String"]>;
  MatchPhoneKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchUraDob?: Maybe<Scalars["String"]>;
  MatchUraEmailId?: Maybe<Scalars["String"]>;
  MatchUraLandlineNumber?: Maybe<Scalars["String"]>;
  MatchUraMobileNumber?: Maybe<Scalars["String"]>;
  MatchUraMobileNumber1?: Maybe<Scalars["String"]>;
  MatchUraNationalId?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaFirstName?: Maybe<Scalars["String"]>;
  MatchedKccaMiddleName?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["float8"]>;
  MatchedKccaSurname?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["float8"]>;
  MatchedUraDob?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraNin?: Maybe<Scalars["String"]>;
  MatchedUraPhone?: Maybe<Scalars["float8"]>;
  MatchedUraSurname?: Maybe<Scalars["String"]>;
  NiraBirthcity?: Maybe<Scalars["String"]>;
  NiraDob?: Maybe<Scalars["timestamptz"]>;
  NiraEmail?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraMobile?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraDob?: Maybe<Scalars["timestamptz"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_RMatchDetailed937" */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Aggregate = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed937_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_RMatchDetailed937>;
};

/** aggregate fields of "rtcs_db.rmatch_RMatchDetailed937" */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed937_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_RMatchDetailed937" */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed937_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_RMatchDetailed937" */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_RMatchDetailed937" */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_RMatchDetailed937_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed937_avg_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["Float"]>;
  KccaNiraMatchScore?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MatchedKccaPhone?: Maybe<Scalars["Float"]>;
  MatchedNiraPhone?: Maybe<Scalars["Float"]>;
  MatchedUraPhone?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraTinNo?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_RMatchDetailed937" */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Avg_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  KccaNiraMatchFeatCount?: Maybe<Order_By>;
  KccaNiraMatchScore?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraPhone?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_RMatchDetailed937". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  AdjustedIncome?: Maybe<Float8_Comparison_Exp>;
  BlockNo?: Maybe<String_Comparison_Exp>;
  CountUra?: Maybe<Bigint_Comparison_Exp>;
  CustomerId?: Maybe<Bigint_Comparison_Exp>;
  DivisionId?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  GrossValue?: Maybe<String_Comparison_Exp>;
  HouseNo?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcEmp?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  KccaDob?: Maybe<Timestamp_Comparison_Exp>;
  KccaEmail?: Maybe<String_Comparison_Exp>;
  KccaEmails?: Maybe<String_Comparison_Exp>;
  KccaFirstName?: Maybe<String_Comparison_Exp>;
  KccaLandline?: Maybe<String_Comparison_Exp>;
  KccaMiddleName?: Maybe<String_Comparison_Exp>;
  KccaMobile?: Maybe<String_Comparison_Exp>;
  KccaMobile2?: Maybe<String_Comparison_Exp>;
  KccaNin?: Maybe<String_Comparison_Exp>;
  KccaNiraMatchAtributes?: Maybe<String_Comparison_Exp>;
  KccaNiraMatchFeatCount?: Maybe<Float8_Comparison_Exp>;
  KccaNiraMatchScore?: Maybe<Float8_Comparison_Exp>;
  KccaSurname?: Maybe<String_Comparison_Exp>;
  KccaTin?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  MatchDobKccaUra?: Maybe<String_Comparison_Exp>;
  MatchDobNiraUra?: Maybe<String_Comparison_Exp>;
  MatchEmailKccaUra?: Maybe<String_Comparison_Exp>;
  MatchKccaDob?: Maybe<String_Comparison_Exp>;
  MatchKccaEmail?: Maybe<String_Comparison_Exp>;
  MatchKccaFirstName?: Maybe<String_Comparison_Exp>;
  MatchKccaLandline?: Maybe<String_Comparison_Exp>;
  MatchKccaMiddleName?: Maybe<String_Comparison_Exp>;
  MatchKccaMobile?: Maybe<String_Comparison_Exp>;
  MatchKccaMobile2?: Maybe<String_Comparison_Exp>;
  MatchKccaNin?: Maybe<String_Comparison_Exp>;
  MatchKccaSurname?: Maybe<String_Comparison_Exp>;
  MatchKccaUra?: Maybe<String_Comparison_Exp>;
  MatchNinKccaUra?: Maybe<String_Comparison_Exp>;
  MatchNiraDob?: Maybe<String_Comparison_Exp>;
  MatchNiraFirstname?: Maybe<String_Comparison_Exp>;
  MatchNiraMobile?: Maybe<String_Comparison_Exp>;
  MatchNiraSurname?: Maybe<String_Comparison_Exp>;
  MatchPhoneKccaUra?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Bigint_Comparison_Exp>;
  MatchUraDob?: Maybe<String_Comparison_Exp>;
  MatchUraEmailId?: Maybe<String_Comparison_Exp>;
  MatchUraLandlineNumber?: Maybe<String_Comparison_Exp>;
  MatchUraMobileNumber?: Maybe<String_Comparison_Exp>;
  MatchUraMobileNumber1?: Maybe<String_Comparison_Exp>;
  MatchUraNationalId?: Maybe<String_Comparison_Exp>;
  MatchedKccaDob?: Maybe<String_Comparison_Exp>;
  MatchedKccaEmail?: Maybe<String_Comparison_Exp>;
  MatchedKccaFirstName?: Maybe<String_Comparison_Exp>;
  MatchedKccaMiddleName?: Maybe<String_Comparison_Exp>;
  MatchedKccaNin?: Maybe<String_Comparison_Exp>;
  MatchedKccaPhone?: Maybe<Float8_Comparison_Exp>;
  MatchedKccaSurname?: Maybe<String_Comparison_Exp>;
  MatchedNiraDob?: Maybe<String_Comparison_Exp>;
  MatchedNiraNin?: Maybe<String_Comparison_Exp>;
  MatchedNiraPhone?: Maybe<Float8_Comparison_Exp>;
  MatchedUraDob?: Maybe<String_Comparison_Exp>;
  MatchedUraEmail?: Maybe<String_Comparison_Exp>;
  MatchedUraNin?: Maybe<String_Comparison_Exp>;
  MatchedUraPhone?: Maybe<Float8_Comparison_Exp>;
  MatchedUraSurname?: Maybe<String_Comparison_Exp>;
  NiraBirthcity?: Maybe<String_Comparison_Exp>;
  NiraDob?: Maybe<Timestamptz_Comparison_Exp>;
  NiraEmail?: Maybe<String_Comparison_Exp>;
  NiraFirstname?: Maybe<String_Comparison_Exp>;
  NiraMobile?: Maybe<String_Comparison_Exp>;
  NiraNin?: Maybe<String_Comparison_Exp>;
  NiraSurname?: Maybe<String_Comparison_Exp>;
  NumProperties?: Maybe<Float8_Comparison_Exp>;
  ParishId?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyId?: Maybe<String_Comparison_Exp>;
  PropertyRentedStatusId?: Maybe<String_Comparison_Exp>;
  PropertyTypeId?: Maybe<String_Comparison_Exp>;
  RateableValue?: Maybe<Float8_Comparison_Exp>;
  ScoreMatchAtributes?: Maybe<String_Comparison_Exp>;
  StreetId?: Maybe<String_Comparison_Exp>;
  SubPropertyTypeId?: Maybe<String_Comparison_Exp>;
  UraDob?: Maybe<Timestamptz_Comparison_Exp>;
  UraEmailId?: Maybe<String_Comparison_Exp>;
  UraFirstname?: Maybe<String_Comparison_Exp>;
  UraMatchingTins?: Maybe<String_Comparison_Exp>;
  UraMiddlename?: Maybe<String_Comparison_Exp>;
  UraMobileNumber?: Maybe<String_Comparison_Exp>;
  UraNationalId?: Maybe<String_Comparison_Exp>;
  UraSurname?: Maybe<String_Comparison_Exp>;
  UraTinNo?: Maybe<Float8_Comparison_Exp>;
  VillageId?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_RMatchDetailed937" */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Inc_Input = {
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["float8"]>;
  KccaNiraMatchScore?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchedKccaPhone?: Maybe<Scalars["float8"]>;
  MatchedNiraPhone?: Maybe<Scalars["float8"]>;
  MatchedUraPhone?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_RMatchDetailed937" */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaEmails?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaNiraMatchAtributes?: Maybe<Scalars["String"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["float8"]>;
  KccaNiraMatchScore?: Maybe<Scalars["float8"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchDobKccaUra?: Maybe<Scalars["String"]>;
  MatchDobNiraUra?: Maybe<Scalars["String"]>;
  MatchEmailKccaUra?: Maybe<Scalars["String"]>;
  MatchKccaDob?: Maybe<Scalars["String"]>;
  MatchKccaEmail?: Maybe<Scalars["String"]>;
  MatchKccaFirstName?: Maybe<Scalars["String"]>;
  MatchKccaLandline?: Maybe<Scalars["String"]>;
  MatchKccaMiddleName?: Maybe<Scalars["String"]>;
  MatchKccaMobile?: Maybe<Scalars["String"]>;
  MatchKccaMobile2?: Maybe<Scalars["String"]>;
  MatchKccaNin?: Maybe<Scalars["String"]>;
  MatchKccaSurname?: Maybe<Scalars["String"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchNinKccaUra?: Maybe<Scalars["String"]>;
  MatchNiraDob?: Maybe<Scalars["String"]>;
  MatchNiraFirstname?: Maybe<Scalars["String"]>;
  MatchNiraMobile?: Maybe<Scalars["String"]>;
  MatchNiraSurname?: Maybe<Scalars["String"]>;
  MatchPhoneKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchUraDob?: Maybe<Scalars["String"]>;
  MatchUraEmailId?: Maybe<Scalars["String"]>;
  MatchUraLandlineNumber?: Maybe<Scalars["String"]>;
  MatchUraMobileNumber?: Maybe<Scalars["String"]>;
  MatchUraMobileNumber1?: Maybe<Scalars["String"]>;
  MatchUraNationalId?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaFirstName?: Maybe<Scalars["String"]>;
  MatchedKccaMiddleName?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["float8"]>;
  MatchedKccaSurname?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["float8"]>;
  MatchedUraDob?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraNin?: Maybe<Scalars["String"]>;
  MatchedUraPhone?: Maybe<Scalars["float8"]>;
  MatchedUraSurname?: Maybe<Scalars["String"]>;
  NiraBirthcity?: Maybe<Scalars["String"]>;
  NiraDob?: Maybe<Scalars["timestamptz"]>;
  NiraEmail?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraMobile?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraDob?: Maybe<Scalars["timestamptz"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Max_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed937_max_fields";
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaEmails?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaNiraMatchAtributes?: Maybe<Scalars["String"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["float8"]>;
  KccaNiraMatchScore?: Maybe<Scalars["float8"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchDobKccaUra?: Maybe<Scalars["String"]>;
  MatchDobNiraUra?: Maybe<Scalars["String"]>;
  MatchEmailKccaUra?: Maybe<Scalars["String"]>;
  MatchKccaDob?: Maybe<Scalars["String"]>;
  MatchKccaEmail?: Maybe<Scalars["String"]>;
  MatchKccaFirstName?: Maybe<Scalars["String"]>;
  MatchKccaLandline?: Maybe<Scalars["String"]>;
  MatchKccaMiddleName?: Maybe<Scalars["String"]>;
  MatchKccaMobile?: Maybe<Scalars["String"]>;
  MatchKccaMobile2?: Maybe<Scalars["String"]>;
  MatchKccaNin?: Maybe<Scalars["String"]>;
  MatchKccaSurname?: Maybe<Scalars["String"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchNinKccaUra?: Maybe<Scalars["String"]>;
  MatchNiraDob?: Maybe<Scalars["String"]>;
  MatchNiraFirstname?: Maybe<Scalars["String"]>;
  MatchNiraMobile?: Maybe<Scalars["String"]>;
  MatchNiraSurname?: Maybe<Scalars["String"]>;
  MatchPhoneKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchUraDob?: Maybe<Scalars["String"]>;
  MatchUraEmailId?: Maybe<Scalars["String"]>;
  MatchUraLandlineNumber?: Maybe<Scalars["String"]>;
  MatchUraMobileNumber?: Maybe<Scalars["String"]>;
  MatchUraMobileNumber1?: Maybe<Scalars["String"]>;
  MatchUraNationalId?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaFirstName?: Maybe<Scalars["String"]>;
  MatchedKccaMiddleName?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["float8"]>;
  MatchedKccaSurname?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["float8"]>;
  MatchedUraDob?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraNin?: Maybe<Scalars["String"]>;
  MatchedUraPhone?: Maybe<Scalars["float8"]>;
  MatchedUraSurname?: Maybe<Scalars["String"]>;
  NiraBirthcity?: Maybe<Scalars["String"]>;
  NiraDob?: Maybe<Scalars["timestamptz"]>;
  NiraEmail?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraMobile?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraDob?: Maybe<Scalars["timestamptz"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_RMatchDetailed937" */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Max_Order_By = {
  Address?: Maybe<Order_By>;
  AdjustedIncome?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaDob?: Maybe<Order_By>;
  KccaEmail?: Maybe<Order_By>;
  KccaEmails?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaLandline?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaMobile?: Maybe<Order_By>;
  KccaMobile2?: Maybe<Order_By>;
  KccaNin?: Maybe<Order_By>;
  KccaNiraMatchAtributes?: Maybe<Order_By>;
  KccaNiraMatchFeatCount?: Maybe<Order_By>;
  KccaNiraMatchScore?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  KccaTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchDobKccaUra?: Maybe<Order_By>;
  MatchDobNiraUra?: Maybe<Order_By>;
  MatchEmailKccaUra?: Maybe<Order_By>;
  MatchKccaDob?: Maybe<Order_By>;
  MatchKccaEmail?: Maybe<Order_By>;
  MatchKccaFirstName?: Maybe<Order_By>;
  MatchKccaLandline?: Maybe<Order_By>;
  MatchKccaMiddleName?: Maybe<Order_By>;
  MatchKccaMobile?: Maybe<Order_By>;
  MatchKccaMobile2?: Maybe<Order_By>;
  MatchKccaNin?: Maybe<Order_By>;
  MatchKccaSurname?: Maybe<Order_By>;
  MatchKccaUra?: Maybe<Order_By>;
  MatchNinKccaUra?: Maybe<Order_By>;
  MatchNiraDob?: Maybe<Order_By>;
  MatchNiraFirstname?: Maybe<Order_By>;
  MatchNiraMobile?: Maybe<Order_By>;
  MatchNiraSurname?: Maybe<Order_By>;
  MatchPhoneKccaUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchUraDob?: Maybe<Order_By>;
  MatchUraEmailId?: Maybe<Order_By>;
  MatchUraLandlineNumber?: Maybe<Order_By>;
  MatchUraMobileNumber?: Maybe<Order_By>;
  MatchUraMobileNumber1?: Maybe<Order_By>;
  MatchUraNationalId?: Maybe<Order_By>;
  MatchedKccaDob?: Maybe<Order_By>;
  MatchedKccaEmail?: Maybe<Order_By>;
  MatchedKccaFirstName?: Maybe<Order_By>;
  MatchedKccaMiddleName?: Maybe<Order_By>;
  MatchedKccaNin?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedKccaSurname?: Maybe<Order_By>;
  MatchedNiraDob?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraDob?: Maybe<Order_By>;
  MatchedUraEmail?: Maybe<Order_By>;
  MatchedUraNin?: Maybe<Order_By>;
  MatchedUraPhone?: Maybe<Order_By>;
  MatchedUraSurname?: Maybe<Order_By>;
  NiraBirthcity?: Maybe<Order_By>;
  NiraDob?: Maybe<Order_By>;
  NiraEmail?: Maybe<Order_By>;
  NiraFirstname?: Maybe<Order_By>;
  NiraMobile?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NiraSurname?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  ScoreMatchAtributes?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  UraDob?: Maybe<Order_By>;
  UraEmailId?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraMatchingTins?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraMobileNumber?: Maybe<Order_By>;
  UraNationalId?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Min_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed937_min_fields";
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaEmails?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaNiraMatchAtributes?: Maybe<Scalars["String"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["float8"]>;
  KccaNiraMatchScore?: Maybe<Scalars["float8"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchDobKccaUra?: Maybe<Scalars["String"]>;
  MatchDobNiraUra?: Maybe<Scalars["String"]>;
  MatchEmailKccaUra?: Maybe<Scalars["String"]>;
  MatchKccaDob?: Maybe<Scalars["String"]>;
  MatchKccaEmail?: Maybe<Scalars["String"]>;
  MatchKccaFirstName?: Maybe<Scalars["String"]>;
  MatchKccaLandline?: Maybe<Scalars["String"]>;
  MatchKccaMiddleName?: Maybe<Scalars["String"]>;
  MatchKccaMobile?: Maybe<Scalars["String"]>;
  MatchKccaMobile2?: Maybe<Scalars["String"]>;
  MatchKccaNin?: Maybe<Scalars["String"]>;
  MatchKccaSurname?: Maybe<Scalars["String"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchNinKccaUra?: Maybe<Scalars["String"]>;
  MatchNiraDob?: Maybe<Scalars["String"]>;
  MatchNiraFirstname?: Maybe<Scalars["String"]>;
  MatchNiraMobile?: Maybe<Scalars["String"]>;
  MatchNiraSurname?: Maybe<Scalars["String"]>;
  MatchPhoneKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchUraDob?: Maybe<Scalars["String"]>;
  MatchUraEmailId?: Maybe<Scalars["String"]>;
  MatchUraLandlineNumber?: Maybe<Scalars["String"]>;
  MatchUraMobileNumber?: Maybe<Scalars["String"]>;
  MatchUraMobileNumber1?: Maybe<Scalars["String"]>;
  MatchUraNationalId?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaFirstName?: Maybe<Scalars["String"]>;
  MatchedKccaMiddleName?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["float8"]>;
  MatchedKccaSurname?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["float8"]>;
  MatchedUraDob?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraNin?: Maybe<Scalars["String"]>;
  MatchedUraPhone?: Maybe<Scalars["float8"]>;
  MatchedUraSurname?: Maybe<Scalars["String"]>;
  NiraBirthcity?: Maybe<Scalars["String"]>;
  NiraDob?: Maybe<Scalars["timestamptz"]>;
  NiraEmail?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraMobile?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraDob?: Maybe<Scalars["timestamptz"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_RMatchDetailed937" */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Min_Order_By = {
  Address?: Maybe<Order_By>;
  AdjustedIncome?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaDob?: Maybe<Order_By>;
  KccaEmail?: Maybe<Order_By>;
  KccaEmails?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaLandline?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaMobile?: Maybe<Order_By>;
  KccaMobile2?: Maybe<Order_By>;
  KccaNin?: Maybe<Order_By>;
  KccaNiraMatchAtributes?: Maybe<Order_By>;
  KccaNiraMatchFeatCount?: Maybe<Order_By>;
  KccaNiraMatchScore?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  KccaTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchDobKccaUra?: Maybe<Order_By>;
  MatchDobNiraUra?: Maybe<Order_By>;
  MatchEmailKccaUra?: Maybe<Order_By>;
  MatchKccaDob?: Maybe<Order_By>;
  MatchKccaEmail?: Maybe<Order_By>;
  MatchKccaFirstName?: Maybe<Order_By>;
  MatchKccaLandline?: Maybe<Order_By>;
  MatchKccaMiddleName?: Maybe<Order_By>;
  MatchKccaMobile?: Maybe<Order_By>;
  MatchKccaMobile2?: Maybe<Order_By>;
  MatchKccaNin?: Maybe<Order_By>;
  MatchKccaSurname?: Maybe<Order_By>;
  MatchKccaUra?: Maybe<Order_By>;
  MatchNinKccaUra?: Maybe<Order_By>;
  MatchNiraDob?: Maybe<Order_By>;
  MatchNiraFirstname?: Maybe<Order_By>;
  MatchNiraMobile?: Maybe<Order_By>;
  MatchNiraSurname?: Maybe<Order_By>;
  MatchPhoneKccaUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchUraDob?: Maybe<Order_By>;
  MatchUraEmailId?: Maybe<Order_By>;
  MatchUraLandlineNumber?: Maybe<Order_By>;
  MatchUraMobileNumber?: Maybe<Order_By>;
  MatchUraMobileNumber1?: Maybe<Order_By>;
  MatchUraNationalId?: Maybe<Order_By>;
  MatchedKccaDob?: Maybe<Order_By>;
  MatchedKccaEmail?: Maybe<Order_By>;
  MatchedKccaFirstName?: Maybe<Order_By>;
  MatchedKccaMiddleName?: Maybe<Order_By>;
  MatchedKccaNin?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedKccaSurname?: Maybe<Order_By>;
  MatchedNiraDob?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraDob?: Maybe<Order_By>;
  MatchedUraEmail?: Maybe<Order_By>;
  MatchedUraNin?: Maybe<Order_By>;
  MatchedUraPhone?: Maybe<Order_By>;
  MatchedUraSurname?: Maybe<Order_By>;
  NiraBirthcity?: Maybe<Order_By>;
  NiraDob?: Maybe<Order_By>;
  NiraEmail?: Maybe<Order_By>;
  NiraFirstname?: Maybe<Order_By>;
  NiraMobile?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NiraSurname?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  ScoreMatchAtributes?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  UraDob?: Maybe<Order_By>;
  UraEmailId?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraMatchingTins?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraMobileNumber?: Maybe<Order_By>;
  UraNationalId?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_RMatchDetailed937" */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed937_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_RMatchDetailed937>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_RMatchDetailed937" */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_RMatchDetailed937_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_RMatchDetailed937" */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Order_By = {
  Address?: Maybe<Order_By>;
  AdjustedIncome?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  DivisionId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  GrossValue?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaDob?: Maybe<Order_By>;
  KccaEmail?: Maybe<Order_By>;
  KccaEmails?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaLandline?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaMobile?: Maybe<Order_By>;
  KccaMobile2?: Maybe<Order_By>;
  KccaNin?: Maybe<Order_By>;
  KccaNiraMatchAtributes?: Maybe<Order_By>;
  KccaNiraMatchFeatCount?: Maybe<Order_By>;
  KccaNiraMatchScore?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  KccaTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchDobKccaUra?: Maybe<Order_By>;
  MatchDobNiraUra?: Maybe<Order_By>;
  MatchEmailKccaUra?: Maybe<Order_By>;
  MatchKccaDob?: Maybe<Order_By>;
  MatchKccaEmail?: Maybe<Order_By>;
  MatchKccaFirstName?: Maybe<Order_By>;
  MatchKccaLandline?: Maybe<Order_By>;
  MatchKccaMiddleName?: Maybe<Order_By>;
  MatchKccaMobile?: Maybe<Order_By>;
  MatchKccaMobile2?: Maybe<Order_By>;
  MatchKccaNin?: Maybe<Order_By>;
  MatchKccaSurname?: Maybe<Order_By>;
  MatchKccaUra?: Maybe<Order_By>;
  MatchNinKccaUra?: Maybe<Order_By>;
  MatchNiraDob?: Maybe<Order_By>;
  MatchNiraFirstname?: Maybe<Order_By>;
  MatchNiraMobile?: Maybe<Order_By>;
  MatchNiraSurname?: Maybe<Order_By>;
  MatchPhoneKccaUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchUraDob?: Maybe<Order_By>;
  MatchUraEmailId?: Maybe<Order_By>;
  MatchUraLandlineNumber?: Maybe<Order_By>;
  MatchUraMobileNumber?: Maybe<Order_By>;
  MatchUraMobileNumber1?: Maybe<Order_By>;
  MatchUraNationalId?: Maybe<Order_By>;
  MatchedKccaDob?: Maybe<Order_By>;
  MatchedKccaEmail?: Maybe<Order_By>;
  MatchedKccaFirstName?: Maybe<Order_By>;
  MatchedKccaMiddleName?: Maybe<Order_By>;
  MatchedKccaNin?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedKccaSurname?: Maybe<Order_By>;
  MatchedNiraDob?: Maybe<Order_By>;
  MatchedNiraNin?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraDob?: Maybe<Order_By>;
  MatchedUraEmail?: Maybe<Order_By>;
  MatchedUraNin?: Maybe<Order_By>;
  MatchedUraPhone?: Maybe<Order_By>;
  MatchedUraSurname?: Maybe<Order_By>;
  NiraBirthcity?: Maybe<Order_By>;
  NiraDob?: Maybe<Order_By>;
  NiraEmail?: Maybe<Order_By>;
  NiraFirstname?: Maybe<Order_By>;
  NiraMobile?: Maybe<Order_By>;
  NiraNin?: Maybe<Order_By>;
  NiraSurname?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  ParishId?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyRentedStatusId?: Maybe<Order_By>;
  PropertyTypeId?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  ScoreMatchAtributes?: Maybe<Order_By>;
  StreetId?: Maybe<Order_By>;
  SubPropertyTypeId?: Maybe<Order_By>;
  UraDob?: Maybe<Order_By>;
  UraEmailId?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraMatchingTins?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraMobileNumber?: Maybe<Order_By>;
  UraNationalId?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  VillageId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_RMatchDetailed937" */
export enum Rtcs_Db_Rmatch_RMatchDetailed937_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  AdjustedIncome = "AdjustedIncome",
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  CountUra = "CountUra",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DivisionId = "DivisionId",
  /** column name */
  File = "File",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  GrossValue = "GrossValue",
  /** column name */
  HouseNo = "HouseNo",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcEmp = "IsIncomeSrcEmp",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  KccaDob = "KccaDob",
  /** column name */
  KccaEmail = "KccaEmail",
  /** column name */
  KccaEmails = "KccaEmails",
  /** column name */
  KccaFirstName = "KccaFirstName",
  /** column name */
  KccaLandline = "KccaLandline",
  /** column name */
  KccaMiddleName = "KccaMiddleName",
  /** column name */
  KccaMobile = "KccaMobile",
  /** column name */
  KccaMobile2 = "KccaMobile2",
  /** column name */
  KccaNin = "KccaNin",
  /** column name */
  KccaNiraMatchAtributes = "KccaNiraMatchAtributes",
  /** column name */
  KccaNiraMatchFeatCount = "KccaNiraMatchFeatCount",
  /** column name */
  KccaNiraMatchScore = "KccaNiraMatchScore",
  /** column name */
  KccaSurname = "KccaSurname",
  /** column name */
  KccaTin = "KccaTin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MatchDobKccaUra = "MatchDobKccaUra",
  /** column name */
  MatchDobNiraUra = "MatchDobNiraUra",
  /** column name */
  MatchEmailKccaUra = "MatchEmailKccaUra",
  /** column name */
  MatchKccaDob = "MatchKccaDob",
  /** column name */
  MatchKccaEmail = "MatchKccaEmail",
  /** column name */
  MatchKccaFirstName = "MatchKccaFirstName",
  /** column name */
  MatchKccaLandline = "MatchKccaLandline",
  /** column name */
  MatchKccaMiddleName = "MatchKccaMiddleName",
  /** column name */
  MatchKccaMobile = "MatchKccaMobile",
  /** column name */
  MatchKccaMobile2 = "MatchKccaMobile2",
  /** column name */
  MatchKccaNin = "MatchKccaNin",
  /** column name */
  MatchKccaSurname = "MatchKccaSurname",
  /** column name */
  MatchKccaUra = "MatchKccaUra",
  /** column name */
  MatchNinKccaUra = "MatchNinKccaUra",
  /** column name */
  MatchNiraDob = "MatchNiraDob",
  /** column name */
  MatchNiraFirstname = "MatchNiraFirstname",
  /** column name */
  MatchNiraMobile = "MatchNiraMobile",
  /** column name */
  MatchNiraSurname = "MatchNiraSurname",
  /** column name */
  MatchPhoneKccaUra = "MatchPhoneKccaUra",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MatchUraDob = "MatchUraDob",
  /** column name */
  MatchUraEmailId = "MatchUraEmailId",
  /** column name */
  MatchUraLandlineNumber = "MatchUraLandlineNumber",
  /** column name */
  MatchUraMobileNumber = "MatchUraMobileNumber",
  /** column name */
  MatchUraMobileNumber1 = "MatchUraMobileNumber1",
  /** column name */
  MatchUraNationalId = "MatchUraNationalId",
  /** column name */
  MatchedKccaDob = "MatchedKccaDob",
  /** column name */
  MatchedKccaEmail = "MatchedKccaEmail",
  /** column name */
  MatchedKccaFirstName = "MatchedKccaFirstName",
  /** column name */
  MatchedKccaMiddleName = "MatchedKccaMiddleName",
  /** column name */
  MatchedKccaNin = "MatchedKccaNin",
  /** column name */
  MatchedKccaPhone = "MatchedKccaPhone",
  /** column name */
  MatchedKccaSurname = "MatchedKccaSurname",
  /** column name */
  MatchedNiraDob = "MatchedNiraDob",
  /** column name */
  MatchedNiraNin = "MatchedNiraNin",
  /** column name */
  MatchedNiraPhone = "MatchedNiraPhone",
  /** column name */
  MatchedUraDob = "MatchedUraDob",
  /** column name */
  MatchedUraEmail = "MatchedUraEmail",
  /** column name */
  MatchedUraNin = "MatchedUraNin",
  /** column name */
  MatchedUraPhone = "MatchedUraPhone",
  /** column name */
  MatchedUraSurname = "MatchedUraSurname",
  /** column name */
  NiraBirthcity = "NiraBirthcity",
  /** column name */
  NiraDob = "NiraDob",
  /** column name */
  NiraEmail = "NiraEmail",
  /** column name */
  NiraFirstname = "NiraFirstname",
  /** column name */
  NiraMobile = "NiraMobile",
  /** column name */
  NiraNin = "NiraNin",
  /** column name */
  NiraSurname = "NiraSurname",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  ParishId = "ParishId",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyId = "PropertyId",
  /** column name */
  PropertyRentedStatusId = "PropertyRentedStatusId",
  /** column name */
  PropertyTypeId = "PropertyTypeId",
  /** column name */
  RateableValue = "RateableValue",
  /** column name */
  ScoreMatchAtributes = "ScoreMatchAtributes",
  /** column name */
  StreetId = "StreetId",
  /** column name */
  SubPropertyTypeId = "SubPropertyTypeId",
  /** column name */
  UraDob = "UraDob",
  /** column name */
  UraEmailId = "UraEmailId",
  /** column name */
  UraFirstname = "UraFirstname",
  /** column name */
  UraMatchingTins = "UraMatchingTins",
  /** column name */
  UraMiddlename = "UraMiddlename",
  /** column name */
  UraMobileNumber = "UraMobileNumber",
  /** column name */
  UraNationalId = "UraNationalId",
  /** column name */
  UraSurname = "UraSurname",
  /** column name */
  UraTinNo = "UraTinNo",
  /** column name */
  VillageId = "VillageId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_RMatchDetailed937" */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  DivisionId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  GrossValue?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaEmails?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaNiraMatchAtributes?: Maybe<Scalars["String"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["float8"]>;
  KccaNiraMatchScore?: Maybe<Scalars["float8"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchDobKccaUra?: Maybe<Scalars["String"]>;
  MatchDobNiraUra?: Maybe<Scalars["String"]>;
  MatchEmailKccaUra?: Maybe<Scalars["String"]>;
  MatchKccaDob?: Maybe<Scalars["String"]>;
  MatchKccaEmail?: Maybe<Scalars["String"]>;
  MatchKccaFirstName?: Maybe<Scalars["String"]>;
  MatchKccaLandline?: Maybe<Scalars["String"]>;
  MatchKccaMiddleName?: Maybe<Scalars["String"]>;
  MatchKccaMobile?: Maybe<Scalars["String"]>;
  MatchKccaMobile2?: Maybe<Scalars["String"]>;
  MatchKccaNin?: Maybe<Scalars["String"]>;
  MatchKccaSurname?: Maybe<Scalars["String"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchNinKccaUra?: Maybe<Scalars["String"]>;
  MatchNiraDob?: Maybe<Scalars["String"]>;
  MatchNiraFirstname?: Maybe<Scalars["String"]>;
  MatchNiraMobile?: Maybe<Scalars["String"]>;
  MatchNiraSurname?: Maybe<Scalars["String"]>;
  MatchPhoneKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchUraDob?: Maybe<Scalars["String"]>;
  MatchUraEmailId?: Maybe<Scalars["String"]>;
  MatchUraLandlineNumber?: Maybe<Scalars["String"]>;
  MatchUraMobileNumber?: Maybe<Scalars["String"]>;
  MatchUraMobileNumber1?: Maybe<Scalars["String"]>;
  MatchUraNationalId?: Maybe<Scalars["String"]>;
  MatchedKccaDob?: Maybe<Scalars["String"]>;
  MatchedKccaEmail?: Maybe<Scalars["String"]>;
  MatchedKccaFirstName?: Maybe<Scalars["String"]>;
  MatchedKccaMiddleName?: Maybe<Scalars["String"]>;
  MatchedKccaNin?: Maybe<Scalars["String"]>;
  MatchedKccaPhone?: Maybe<Scalars["float8"]>;
  MatchedKccaSurname?: Maybe<Scalars["String"]>;
  MatchedNiraDob?: Maybe<Scalars["String"]>;
  MatchedNiraNin?: Maybe<Scalars["String"]>;
  MatchedNiraPhone?: Maybe<Scalars["float8"]>;
  MatchedUraDob?: Maybe<Scalars["String"]>;
  MatchedUraEmail?: Maybe<Scalars["String"]>;
  MatchedUraNin?: Maybe<Scalars["String"]>;
  MatchedUraPhone?: Maybe<Scalars["float8"]>;
  MatchedUraSurname?: Maybe<Scalars["String"]>;
  NiraBirthcity?: Maybe<Scalars["String"]>;
  NiraDob?: Maybe<Scalars["timestamptz"]>;
  NiraEmail?: Maybe<Scalars["String"]>;
  NiraFirstname?: Maybe<Scalars["String"]>;
  NiraMobile?: Maybe<Scalars["String"]>;
  NiraNin?: Maybe<Scalars["String"]>;
  NiraSurname?: Maybe<Scalars["String"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  ParishId?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyRentedStatusId?: Maybe<Scalars["String"]>;
  PropertyTypeId?: Maybe<Scalars["String"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  StreetId?: Maybe<Scalars["String"]>;
  SubPropertyTypeId?: Maybe<Scalars["String"]>;
  UraDob?: Maybe<Scalars["timestamptz"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["float8"]>;
  VillageId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed937_stddev_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["Float"]>;
  KccaNiraMatchScore?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MatchedKccaPhone?: Maybe<Scalars["Float"]>;
  MatchedNiraPhone?: Maybe<Scalars["Float"]>;
  MatchedUraPhone?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraTinNo?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_RMatchDetailed937" */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Stddev_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  KccaNiraMatchFeatCount?: Maybe<Order_By>;
  KccaNiraMatchScore?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraPhone?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed937_stddev_pop_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["Float"]>;
  KccaNiraMatchScore?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MatchedKccaPhone?: Maybe<Scalars["Float"]>;
  MatchedNiraPhone?: Maybe<Scalars["Float"]>;
  MatchedUraPhone?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraTinNo?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_RMatchDetailed937" */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Stddev_Pop_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  KccaNiraMatchFeatCount?: Maybe<Order_By>;
  KccaNiraMatchScore?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraPhone?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed937_stddev_samp_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["Float"]>;
  KccaNiraMatchScore?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MatchedKccaPhone?: Maybe<Scalars["Float"]>;
  MatchedNiraPhone?: Maybe<Scalars["Float"]>;
  MatchedUraPhone?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraTinNo?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_RMatchDetailed937" */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Stddev_Samp_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  KccaNiraMatchFeatCount?: Maybe<Order_By>;
  KccaNiraMatchScore?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraPhone?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed937_sum_fields";
  AdjustedIncome?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["float8"]>;
  KccaNiraMatchScore?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchedKccaPhone?: Maybe<Scalars["float8"]>;
  MatchedNiraPhone?: Maybe<Scalars["float8"]>;
  MatchedUraPhone?: Maybe<Scalars["float8"]>;
  NumProperties?: Maybe<Scalars["float8"]>;
  RateableValue?: Maybe<Scalars["float8"]>;
  UraTinNo?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_RMatchDetailed937" */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Sum_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  KccaNiraMatchFeatCount?: Maybe<Order_By>;
  KccaNiraMatchScore?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraPhone?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed937_var_pop_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["Float"]>;
  KccaNiraMatchScore?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MatchedKccaPhone?: Maybe<Scalars["Float"]>;
  MatchedNiraPhone?: Maybe<Scalars["Float"]>;
  MatchedUraPhone?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraTinNo?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_RMatchDetailed937" */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Var_Pop_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  KccaNiraMatchFeatCount?: Maybe<Order_By>;
  KccaNiraMatchScore?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraPhone?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed937_var_samp_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["Float"]>;
  KccaNiraMatchScore?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MatchedKccaPhone?: Maybe<Scalars["Float"]>;
  MatchedNiraPhone?: Maybe<Scalars["Float"]>;
  MatchedUraPhone?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraTinNo?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_RMatchDetailed937" */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Var_Samp_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  KccaNiraMatchFeatCount?: Maybe<Order_By>;
  KccaNiraMatchScore?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraPhone?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchDetailed937_variance_fields";
  AdjustedIncome?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  KccaNiraMatchFeatCount?: Maybe<Scalars["Float"]>;
  KccaNiraMatchScore?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  MatchedKccaPhone?: Maybe<Scalars["Float"]>;
  MatchedNiraPhone?: Maybe<Scalars["Float"]>;
  MatchedUraPhone?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  RateableValue?: Maybe<Scalars["Float"]>;
  UraTinNo?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_RMatchDetailed937" */
export type Rtcs_Db_Rmatch_RMatchDetailed937_Variance_Order_By = {
  AdjustedIncome?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  KccaNiraMatchFeatCount?: Maybe<Order_By>;
  KccaNiraMatchScore?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchedKccaPhone?: Maybe<Order_By>;
  MatchedNiraPhone?: Maybe<Order_By>;
  MatchedUraPhone?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  RateableValue?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Rmatch_RMatchKccaProperties = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperties";
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossvalue?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Rid?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Aggregate = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperties_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_RMatchKccaProperties>;
};

/** aggregate fields of "rtcs_db.rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperties_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_RMatchKccaProperties_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_RMatchKccaProperties_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperties_avg_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Avg_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_RMatchKccaProperties". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  Blockno?: Maybe<String_Comparison_Exp>;
  Customerid?: Maybe<Bigint_Comparison_Exp>;
  Divisionid?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Flatno?: Maybe<String_Comparison_Exp>;
  Grossvalue?: Maybe<String_Comparison_Exp>;
  Houseno?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  Parishid?: Maybe<Float8_Comparison_Exp>;
  Plotno?: Maybe<String_Comparison_Exp>;
  Propertyno?: Maybe<String_Comparison_Exp>;
  Propertyrentedstatusid?: Maybe<Float8_Comparison_Exp>;
  Propertytypeid?: Maybe<Float8_Comparison_Exp>;
  Rateablevalue?: Maybe<Float8_Comparison_Exp>;
  Rateablevalue2?: Maybe<Float8_Comparison_Exp>;
  Rid?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Streetid?: Maybe<String_Comparison_Exp>;
  Subpropertytypeid?: Maybe<Float8_Comparison_Exp>;
  Villageid?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Inc_Input = {
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossvalue?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Rid?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Max_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperties_max_fields";
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossvalue?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Rid?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Max_Order_By = {
  Address?: Maybe<Order_By>;
  Blockno?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Houseno?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Rid?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Min_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperties_min_fields";
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossvalue?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Rid?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Min_Order_By = {
  Address?: Maybe<Order_By>;
  Blockno?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Houseno?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Rid?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperties_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_RMatchKccaProperties>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_RMatchKccaProperties_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Order_By = {
  Address?: Maybe<Order_By>;
  Blockno?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Houseno?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Rid?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_RMatchKccaProperties" */
export enum Rtcs_Db_Rmatch_RMatchKccaProperties_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  Blockno = "Blockno",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  Divisionid = "Divisionid",
  /** column name */
  File = "File",
  /** column name */
  Flatno = "Flatno",
  /** column name */
  Grossvalue = "Grossvalue",
  /** column name */
  Houseno = "Houseno",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  Parishid = "Parishid",
  /** column name */
  Plotno = "Plotno",
  /** column name */
  Propertyno = "Propertyno",
  /** column name */
  Propertyrentedstatusid = "Propertyrentedstatusid",
  /** column name */
  Propertytypeid = "Propertytypeid",
  /** column name */
  Rateablevalue = "Rateablevalue",
  /** column name */
  Rateablevalue2 = "Rateablevalue2",
  /** column name */
  Rid = "Rid",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Streetid = "Streetid",
  /** column name */
  Subpropertytypeid = "Subpropertytypeid",
  /** column name */
  Villageid = "Villageid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossvalue?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Rid?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperties_stddev_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Stddev_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperties_stddev_pop_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Stddev_Pop_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperties_stddev_samp_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Stddev_Samp_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperties_sum_fields";
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Sum_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperties_var_pop_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Var_Pop_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperties_var_samp_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Var_Samp_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperties_variance_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_RMatchKccaProperties" */
export type Rtcs_Db_Rmatch_RMatchKccaProperties_Variance_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Rmatch_RMatchKccaProperty = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperty";
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossvalue?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Rid?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Aggregate = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperty_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_RMatchKccaProperty>;
};

/** aggregate fields of "rtcs_db.rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperty_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_RMatchKccaProperty_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_RMatchKccaProperty_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperty_avg_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Avg_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_RMatchKccaProperty". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  Blockno?: Maybe<String_Comparison_Exp>;
  Customerid?: Maybe<Bigint_Comparison_Exp>;
  Divisionid?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Flatno?: Maybe<String_Comparison_Exp>;
  Grossvalue?: Maybe<String_Comparison_Exp>;
  Houseno?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  Parishid?: Maybe<Float8_Comparison_Exp>;
  Plotno?: Maybe<String_Comparison_Exp>;
  Propertyno?: Maybe<String_Comparison_Exp>;
  Propertyrentedstatusid?: Maybe<Float8_Comparison_Exp>;
  Propertytypeid?: Maybe<Float8_Comparison_Exp>;
  Rateablevalue?: Maybe<Float8_Comparison_Exp>;
  Rateablevalue2?: Maybe<Float8_Comparison_Exp>;
  Rid?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Streetid?: Maybe<String_Comparison_Exp>;
  Subpropertytypeid?: Maybe<Float8_Comparison_Exp>;
  Villageid?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Inc_Input = {
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossvalue?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Rid?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Max_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperty_max_fields";
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossvalue?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Rid?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Max_Order_By = {
  Address?: Maybe<Order_By>;
  Blockno?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Houseno?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Rid?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Min_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperty_min_fields";
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossvalue?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Rid?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Min_Order_By = {
  Address?: Maybe<Order_By>;
  Blockno?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Houseno?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Rid?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperty_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_RMatchKccaProperty>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_RMatchKccaProperty_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Order_By = {
  Address?: Maybe<Order_By>;
  Blockno?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Flatno?: Maybe<Order_By>;
  Grossvalue?: Maybe<Order_By>;
  Houseno?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Propertyno?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Rid?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Streetid?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_RMatchKccaProperty" */
export enum Rtcs_Db_Rmatch_RMatchKccaProperty_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  Blockno = "Blockno",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  Divisionid = "Divisionid",
  /** column name */
  File = "File",
  /** column name */
  Flatno = "Flatno",
  /** column name */
  Grossvalue = "Grossvalue",
  /** column name */
  Houseno = "Houseno",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  Parishid = "Parishid",
  /** column name */
  Plotno = "Plotno",
  /** column name */
  Propertyno = "Propertyno",
  /** column name */
  Propertyrentedstatusid = "Propertyrentedstatusid",
  /** column name */
  Propertytypeid = "Propertytypeid",
  /** column name */
  Rateablevalue = "Rateablevalue",
  /** column name */
  Rateablevalue2 = "Rateablevalue2",
  /** column name */
  Rid = "Rid",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Streetid = "Streetid",
  /** column name */
  Subpropertytypeid = "Subpropertytypeid",
  /** column name */
  Villageid = "Villageid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  Blockno?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Flatno?: Maybe<Scalars["String"]>;
  Grossvalue?: Maybe<Scalars["String"]>;
  Houseno?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Propertyno?: Maybe<Scalars["String"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Rid?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Streetid?: Maybe<Scalars["String"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperty_stddev_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Stddev_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperty_stddev_pop_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Stddev_Pop_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperty_stddev_samp_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Stddev_Samp_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperty_sum_fields";
  Customerid?: Maybe<Scalars["bigint"]>;
  Divisionid?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Parishid?: Maybe<Scalars["float8"]>;
  Propertyrentedstatusid?: Maybe<Scalars["float8"]>;
  Propertytypeid?: Maybe<Scalars["float8"]>;
  Rateablevalue?: Maybe<Scalars["float8"]>;
  Rateablevalue2?: Maybe<Scalars["float8"]>;
  Subpropertytypeid?: Maybe<Scalars["float8"]>;
  Villageid?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Sum_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperty_var_pop_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Var_Pop_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperty_var_samp_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Var_Samp_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchKccaProperty_variance_fields";
  Customerid?: Maybe<Scalars["Float"]>;
  Divisionid?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Parishid?: Maybe<Scalars["Float"]>;
  Propertyrentedstatusid?: Maybe<Scalars["Float"]>;
  Propertytypeid?: Maybe<Scalars["Float"]>;
  Rateablevalue?: Maybe<Scalars["Float"]>;
  Rateablevalue2?: Maybe<Scalars["Float"]>;
  Subpropertytypeid?: Maybe<Scalars["Float"]>;
  Villageid?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_RMatchKccaProperty" */
export type Rtcs_Db_Rmatch_RMatchKccaProperty_Variance_Order_By = {
  Customerid?: Maybe<Order_By>;
  Divisionid?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Parishid?: Maybe<Order_By>;
  Propertyrentedstatusid?: Maybe<Order_By>;
  Propertytypeid?: Maybe<Order_By>;
  Rateablevalue?: Maybe<Order_By>;
  Rateablevalue2?: Maybe<Order_By>;
  Subpropertytypeid?: Maybe<Order_By>;
  Villageid?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903 = {
  __typename?: "rtcs_db_rmatch_RMatchNonIndividual903";
  AlgorithmMatchCategory?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchConfidence?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Aggregate = {
  __typename?: "rtcs_db_rmatch_RMatchNonIndividual903_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_RMatchNonIndividual903>;
};

/** aggregate fields of "rtcs_db.rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchNonIndividual903_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_RMatchNonIndividual903_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_RMatchNonIndividual903_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchNonIndividual903_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
  MatchConfidence?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Avg_Order_By = {
  Line?: Maybe<Order_By>;
  MatchConfidence?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_RMatchNonIndividual903". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Bool_Exp = {
  AlgorithmMatchCategory?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FinalMatchCategory?: Maybe<String_Comparison_Exp>;
  KccaCustomerId?: Maybe<String_Comparison_Exp>;
  KccaName?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MatchConfidence?: Maybe<Bigint_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  UraName?: Maybe<String_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
  MatchConfidence?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Insert_Input = {
  AlgorithmMatchCategory?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchConfidence?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Max_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchNonIndividual903_max_fields";
  AlgorithmMatchCategory?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchConfidence?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Max_Order_By = {
  AlgorithmMatchCategory?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinalMatchCategory?: Maybe<Order_By>;
  KccaCustomerId?: Maybe<Order_By>;
  KccaName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchConfidence?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  UraName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Min_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchNonIndividual903_min_fields";
  AlgorithmMatchCategory?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchConfidence?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Min_Order_By = {
  AlgorithmMatchCategory?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinalMatchCategory?: Maybe<Order_By>;
  KccaCustomerId?: Maybe<Order_By>;
  KccaName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchConfidence?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  UraName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_RMatchNonIndividual903_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_RMatchNonIndividual903>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_RMatchNonIndividual903_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Order_By = {
  AlgorithmMatchCategory?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinalMatchCategory?: Maybe<Order_By>;
  KccaCustomerId?: Maybe<Order_By>;
  KccaName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchConfidence?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  UraName?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_RMatchNonIndividual903" */
export enum Rtcs_Db_Rmatch_RMatchNonIndividual903_Select_Column {
  /** column name */
  AlgorithmMatchCategory = "AlgorithmMatchCategory",
  /** column name */
  File = "File",
  /** column name */
  FinalMatchCategory = "FinalMatchCategory",
  /** column name */
  KccaCustomerId = "KccaCustomerId",
  /** column name */
  KccaName = "KccaName",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MatchConfidence = "MatchConfidence",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  UraName = "UraName",
  /** column name */
  UraTinNo = "UraTinNo",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Set_Input = {
  AlgorithmMatchCategory?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FinalMatchCategory?: Maybe<Scalars["String"]>;
  KccaCustomerId?: Maybe<Scalars["String"]>;
  KccaName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchConfidence?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  UraName?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchNonIndividual903_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
  MatchConfidence?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
  MatchConfidence?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchNonIndividual903_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  MatchConfidence?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  MatchConfidence?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchNonIndividual903_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  MatchConfidence?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  MatchConfidence?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchNonIndividual903_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
  MatchConfidence?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Sum_Order_By = {
  Line?: Maybe<Order_By>;
  MatchConfidence?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchNonIndividual903_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  MatchConfidence?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  MatchConfidence?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchNonIndividual903_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  MatchConfidence?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  MatchConfidence?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchNonIndividual903_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
  MatchConfidence?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_RMatchNonIndividual903" */
export type Rtcs_Db_Rmatch_RMatchNonIndividual903_Variance_Order_By = {
  Line?: Maybe<Order_By>;
  MatchConfidence?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_RMatchProperties1115" */
export type Rtcs_Db_Rmatch_RMatchProperties1115 = {
  __typename?: "rtcs_db_rmatch_RMatchProperties1115";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CustomerName?: Maybe<Scalars["bigint"]>;
  Division?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  MatchResult?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["String"]>;
  MdaLatitude?: Maybe<Scalars["String"]>;
  MdaLongitude?: Maybe<Scalars["String"]>;
  ModifyDate?: Maybe<Scalars["String"]>;
  NwscCustomerId?: Maybe<Scalars["bigint"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  NwscCustomerReference?: Maybe<Scalars["bigint"]>;
  NwscLatitude?: Maybe<Scalars["String"]>;
  NwscLongitude?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RentedStatus?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_RMatchProperties1115" */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Aggregate = {
  __typename?: "rtcs_db_rmatch_RMatchProperties1115_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_RMatchProperties1115>;
};

/** aggregate fields of "rtcs_db.rmatch_RMatchProperties1115" */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchProperties1115_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_RMatchProperties1115" */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_RMatchProperties1115_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_RMatchProperties1115" */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_RMatchProperties1115" */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_RMatchProperties1115_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchProperties1115_avg_fields";
  CustomerName?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  NwscCustomerReference?: Maybe<Scalars["Float"]>;
  RentedStatus?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_RMatchProperties1115" */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Avg_Order_By = {
  CustomerName?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerReference?: Maybe<Order_By>;
  RentedStatus?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_RMatchProperties1115". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  BlockNo?: Maybe<String_Comparison_Exp>;
  CustomerName?: Maybe<Bigint_Comparison_Exp>;
  Division?: Maybe<String_Comparison_Exp>;
  FlatNo?: Maybe<String_Comparison_Exp>;
  HouseNo?: Maybe<String_Comparison_Exp>;
  MatchResult?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<String_Comparison_Exp>;
  MdaLatitude?: Maybe<String_Comparison_Exp>;
  MdaLongitude?: Maybe<String_Comparison_Exp>;
  ModifyDate?: Maybe<String_Comparison_Exp>;
  NwscCustomerId?: Maybe<Bigint_Comparison_Exp>;
  NwscCustomerName?: Maybe<String_Comparison_Exp>;
  NwscCustomerReference?: Maybe<Bigint_Comparison_Exp>;
  NwscLatitude?: Maybe<String_Comparison_Exp>;
  NwscLongitude?: Maybe<String_Comparison_Exp>;
  Parish?: Maybe<String_Comparison_Exp>;
  PlotNo?: Maybe<String_Comparison_Exp>;
  PropertyId?: Maybe<String_Comparison_Exp>;
  PropertyType?: Maybe<String_Comparison_Exp>;
  RentedStatus?: Maybe<Bigint_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Street?: Maybe<String_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_RMatchProperties1115" */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Inc_Input = {
  CustomerName?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["bigint"]>;
  NwscCustomerReference?: Maybe<Scalars["bigint"]>;
  RentedStatus?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_RMatchProperties1115" */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CustomerName?: Maybe<Scalars["bigint"]>;
  Division?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  MatchResult?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["String"]>;
  MdaLatitude?: Maybe<Scalars["String"]>;
  MdaLongitude?: Maybe<Scalars["String"]>;
  ModifyDate?: Maybe<Scalars["String"]>;
  NwscCustomerId?: Maybe<Scalars["bigint"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  NwscCustomerReference?: Maybe<Scalars["bigint"]>;
  NwscLatitude?: Maybe<Scalars["String"]>;
  NwscLongitude?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RentedStatus?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Max_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchProperties1115_max_fields";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CustomerName?: Maybe<Scalars["bigint"]>;
  Division?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  MatchResult?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["String"]>;
  MdaLatitude?: Maybe<Scalars["String"]>;
  MdaLongitude?: Maybe<Scalars["String"]>;
  ModifyDate?: Maybe<Scalars["String"]>;
  NwscCustomerId?: Maybe<Scalars["bigint"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  NwscCustomerReference?: Maybe<Scalars["bigint"]>;
  NwscLatitude?: Maybe<Scalars["String"]>;
  NwscLongitude?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RentedStatus?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_RMatchProperties1115" */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Max_Order_By = {
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CustomerName?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  MatchResult?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MdaLatitude?: Maybe<Order_By>;
  MdaLongitude?: Maybe<Order_By>;
  ModifyDate?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerName?: Maybe<Order_By>;
  NwscCustomerReference?: Maybe<Order_By>;
  NwscLatitude?: Maybe<Order_By>;
  NwscLongitude?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  RentedStatus?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Min_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchProperties1115_min_fields";
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CustomerName?: Maybe<Scalars["bigint"]>;
  Division?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  MatchResult?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["String"]>;
  MdaLatitude?: Maybe<Scalars["String"]>;
  MdaLongitude?: Maybe<Scalars["String"]>;
  ModifyDate?: Maybe<Scalars["String"]>;
  NwscCustomerId?: Maybe<Scalars["bigint"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  NwscCustomerReference?: Maybe<Scalars["bigint"]>;
  NwscLatitude?: Maybe<Scalars["String"]>;
  NwscLongitude?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RentedStatus?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_RMatchProperties1115" */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Min_Order_By = {
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CustomerName?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  MatchResult?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MdaLatitude?: Maybe<Order_By>;
  MdaLongitude?: Maybe<Order_By>;
  ModifyDate?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerName?: Maybe<Order_By>;
  NwscCustomerReference?: Maybe<Order_By>;
  NwscLatitude?: Maybe<Order_By>;
  NwscLongitude?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  RentedStatus?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_RMatchProperties1115" */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_RMatchProperties1115_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_RMatchProperties1115>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_RMatchProperties1115" */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_RMatchProperties1115_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_RMatchProperties1115" */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Order_By = {
  Address?: Maybe<Order_By>;
  BlockNo?: Maybe<Order_By>;
  CustomerName?: Maybe<Order_By>;
  Division?: Maybe<Order_By>;
  FlatNo?: Maybe<Order_By>;
  HouseNo?: Maybe<Order_By>;
  MatchResult?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MdaLatitude?: Maybe<Order_By>;
  MdaLongitude?: Maybe<Order_By>;
  ModifyDate?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerName?: Maybe<Order_By>;
  NwscCustomerReference?: Maybe<Order_By>;
  NwscLatitude?: Maybe<Order_By>;
  NwscLongitude?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  PlotNo?: Maybe<Order_By>;
  PropertyId?: Maybe<Order_By>;
  PropertyType?: Maybe<Order_By>;
  RentedStatus?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_RMatchProperties1115" */
export enum Rtcs_Db_Rmatch_RMatchProperties1115_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  BlockNo = "BlockNo",
  /** column name */
  CustomerName = "CustomerName",
  /** column name */
  Division = "Division",
  /** column name */
  FlatNo = "FlatNo",
  /** column name */
  HouseNo = "HouseNo",
  /** column name */
  MatchResult = "MatchResult",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MdaLatitude = "MdaLatitude",
  /** column name */
  MdaLongitude = "MdaLongitude",
  /** column name */
  ModifyDate = "ModifyDate",
  /** column name */
  NwscCustomerId = "NwscCustomerId",
  /** column name */
  NwscCustomerName = "NwscCustomerName",
  /** column name */
  NwscCustomerReference = "NwscCustomerReference",
  /** column name */
  NwscLatitude = "NwscLatitude",
  /** column name */
  NwscLongitude = "NwscLongitude",
  /** column name */
  Parish = "Parish",
  /** column name */
  PlotNo = "PlotNo",
  /** column name */
  PropertyId = "PropertyId",
  /** column name */
  PropertyType = "PropertyType",
  /** column name */
  RentedStatus = "RentedStatus",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Street = "Street",
  /** column name */
  Village = "Village",
}

/** input type for updating data in table "rtcs_db.rmatch_RMatchProperties1115" */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  BlockNo?: Maybe<Scalars["String"]>;
  CustomerName?: Maybe<Scalars["bigint"]>;
  Division?: Maybe<Scalars["String"]>;
  FlatNo?: Maybe<Scalars["String"]>;
  HouseNo?: Maybe<Scalars["String"]>;
  MatchResult?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["String"]>;
  MdaLatitude?: Maybe<Scalars["String"]>;
  MdaLongitude?: Maybe<Scalars["String"]>;
  ModifyDate?: Maybe<Scalars["String"]>;
  NwscCustomerId?: Maybe<Scalars["bigint"]>;
  NwscCustomerName?: Maybe<Scalars["String"]>;
  NwscCustomerReference?: Maybe<Scalars["bigint"]>;
  NwscLatitude?: Maybe<Scalars["String"]>;
  NwscLongitude?: Maybe<Scalars["String"]>;
  Parish?: Maybe<Scalars["String"]>;
  PlotNo?: Maybe<Scalars["String"]>;
  PropertyId?: Maybe<Scalars["String"]>;
  PropertyType?: Maybe<Scalars["String"]>;
  RentedStatus?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchProperties1115_stddev_fields";
  CustomerName?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  NwscCustomerReference?: Maybe<Scalars["Float"]>;
  RentedStatus?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_RMatchProperties1115" */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Stddev_Order_By = {
  CustomerName?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerReference?: Maybe<Order_By>;
  RentedStatus?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchProperties1115_stddev_pop_fields";
  CustomerName?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  NwscCustomerReference?: Maybe<Scalars["Float"]>;
  RentedStatus?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_RMatchProperties1115" */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Stddev_Pop_Order_By = {
  CustomerName?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerReference?: Maybe<Order_By>;
  RentedStatus?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchProperties1115_stddev_samp_fields";
  CustomerName?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  NwscCustomerReference?: Maybe<Scalars["Float"]>;
  RentedStatus?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_RMatchProperties1115" */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Stddev_Samp_Order_By = {
  CustomerName?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerReference?: Maybe<Order_By>;
  RentedStatus?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchProperties1115_sum_fields";
  CustomerName?: Maybe<Scalars["bigint"]>;
  NwscCustomerId?: Maybe<Scalars["bigint"]>;
  NwscCustomerReference?: Maybe<Scalars["bigint"]>;
  RentedStatus?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_RMatchProperties1115" */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Sum_Order_By = {
  CustomerName?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerReference?: Maybe<Order_By>;
  RentedStatus?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchProperties1115_var_pop_fields";
  CustomerName?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  NwscCustomerReference?: Maybe<Scalars["Float"]>;
  RentedStatus?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_RMatchProperties1115" */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Var_Pop_Order_By = {
  CustomerName?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerReference?: Maybe<Order_By>;
  RentedStatus?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchProperties1115_var_samp_fields";
  CustomerName?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  NwscCustomerReference?: Maybe<Scalars["Float"]>;
  RentedStatus?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_RMatchProperties1115" */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Var_Samp_Order_By = {
  CustomerName?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerReference?: Maybe<Order_By>;
  RentedStatus?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchProperties1115_variance_fields";
  CustomerName?: Maybe<Scalars["Float"]>;
  NwscCustomerId?: Maybe<Scalars["Float"]>;
  NwscCustomerReference?: Maybe<Scalars["Float"]>;
  RentedStatus?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_RMatchProperties1115" */
export type Rtcs_Db_Rmatch_RMatchProperties1115_Variance_Order_By = {
  CustomerName?: Maybe<Order_By>;
  NwscCustomerId?: Maybe<Order_By>;
  NwscCustomerReference?: Maybe<Order_By>;
  RentedStatus?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_RMatchSummary904" */
export type Rtcs_Db_Rmatch_RMatchSummary904 = {
  __typename?: "rtcs_db_rmatch_RMatchSummary904";
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile1?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAtributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraDob?: Maybe<Scalars["timestamptz"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_RMatchSummary904" */
export type Rtcs_Db_Rmatch_RMatchSummary904_Aggregate = {
  __typename?: "rtcs_db_rmatch_RMatchSummary904_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_RMatchSummary904>;
};

/** aggregate fields of "rtcs_db.rmatch_RMatchSummary904" */
export type Rtcs_Db_Rmatch_RMatchSummary904_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary904_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_RMatchSummary904" */
export type Rtcs_Db_Rmatch_RMatchSummary904_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary904_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_RMatchSummary904" */
export type Rtcs_Db_Rmatch_RMatchSummary904_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_RMatchSummary904" */
export type Rtcs_Db_Rmatch_RMatchSummary904_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_RMatchSummary904_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_RMatchSummary904_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary904_avg_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_RMatchSummary904" */
export type Rtcs_Db_Rmatch_RMatchSummary904_Avg_Order_By = {
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_RMatchSummary904". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_RMatchSummary904_Bool_Exp = {
  CountUra?: Maybe<Bigint_Comparison_Exp>;
  CustomerId?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcEmp?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  KccaDob?: Maybe<Timestamp_Comparison_Exp>;
  KccaEmail?: Maybe<String_Comparison_Exp>;
  KccaFirstName?: Maybe<String_Comparison_Exp>;
  KccaLandline?: Maybe<String_Comparison_Exp>;
  KccaMiddleName?: Maybe<String_Comparison_Exp>;
  KccaMobile1?: Maybe<String_Comparison_Exp>;
  KccaMobile2?: Maybe<String_Comparison_Exp>;
  KccaNin?: Maybe<String_Comparison_Exp>;
  KccaSurname?: Maybe<String_Comparison_Exp>;
  KccaTin?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MatchCategory?: Maybe<String_Comparison_Exp>;
  MatchDetails?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Bigint_Comparison_Exp>;
  MatchScoreAtributes?: Maybe<String_Comparison_Exp>;
  NumOwnedNonRateable?: Maybe<Bigint_Comparison_Exp>;
  NumProperties?: Maybe<Bigint_Comparison_Exp>;
  SumAdjustedIncome?: Maybe<Float8_Comparison_Exp>;
  SumGrossValue?: Maybe<Float8_Comparison_Exp>;
  SumNonRateableValue?: Maybe<Float8_Comparison_Exp>;
  SumRateableValue?: Maybe<Float8_Comparison_Exp>;
  TotalRentedProperties?: Maybe<Bigint_Comparison_Exp>;
  UraDob?: Maybe<Timestamptz_Comparison_Exp>;
  UraEmailId?: Maybe<String_Comparison_Exp>;
  UraFirstname?: Maybe<String_Comparison_Exp>;
  UraLandlineNumber?: Maybe<Float8_Comparison_Exp>;
  UraMiddlename?: Maybe<String_Comparison_Exp>;
  UraMobileNumber?: Maybe<String_Comparison_Exp>;
  UraNationalId?: Maybe<String_Comparison_Exp>;
  UraSurname?: Maybe<String_Comparison_Exp>;
  UraTinNo?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_RMatchSummary904" */
export type Rtcs_Db_Rmatch_RMatchSummary904_Inc_Input = {
  CountUra?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_RMatchSummary904" */
export type Rtcs_Db_Rmatch_RMatchSummary904_Insert_Input = {
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile1?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAtributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraDob?: Maybe<Scalars["timestamptz"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_RMatchSummary904_Max_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary904_max_fields";
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile1?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAtributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraDob?: Maybe<Scalars["timestamptz"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_RMatchSummary904" */
export type Rtcs_Db_Rmatch_RMatchSummary904_Max_Order_By = {
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaDob?: Maybe<Order_By>;
  KccaEmail?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaLandline?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaMobile1?: Maybe<Order_By>;
  KccaMobile2?: Maybe<Order_By>;
  KccaNin?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  KccaTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAtributes?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraDob?: Maybe<Order_By>;
  UraEmailId?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraMobileNumber?: Maybe<Order_By>;
  UraNationalId?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_RMatchSummary904_Min_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary904_min_fields";
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile1?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAtributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraDob?: Maybe<Scalars["timestamptz"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_RMatchSummary904" */
export type Rtcs_Db_Rmatch_RMatchSummary904_Min_Order_By = {
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaDob?: Maybe<Order_By>;
  KccaEmail?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaLandline?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaMobile1?: Maybe<Order_By>;
  KccaMobile2?: Maybe<Order_By>;
  KccaNin?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  KccaTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAtributes?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraDob?: Maybe<Order_By>;
  UraEmailId?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraMobileNumber?: Maybe<Order_By>;
  UraNationalId?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_RMatchSummary904" */
export type Rtcs_Db_Rmatch_RMatchSummary904_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_RMatchSummary904_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_RMatchSummary904>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_RMatchSummary904" */
export type Rtcs_Db_Rmatch_RMatchSummary904_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_RMatchSummary904_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_RMatchSummary904" */
export type Rtcs_Db_Rmatch_RMatchSummary904_Order_By = {
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaDob?: Maybe<Order_By>;
  KccaEmail?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaLandline?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaMobile1?: Maybe<Order_By>;
  KccaMobile2?: Maybe<Order_By>;
  KccaNin?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  KccaTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchCategory?: Maybe<Order_By>;
  MatchDetails?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MatchScoreAtributes?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraDob?: Maybe<Order_By>;
  UraEmailId?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraMobileNumber?: Maybe<Order_By>;
  UraNationalId?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_RMatchSummary904" */
export enum Rtcs_Db_Rmatch_RMatchSummary904_Select_Column {
  /** column name */
  CountUra = "CountUra",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  File = "File",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcEmp = "IsIncomeSrcEmp",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  KccaDob = "KccaDob",
  /** column name */
  KccaEmail = "KccaEmail",
  /** column name */
  KccaFirstName = "KccaFirstName",
  /** column name */
  KccaLandline = "KccaLandline",
  /** column name */
  KccaMiddleName = "KccaMiddleName",
  /** column name */
  KccaMobile1 = "KccaMobile1",
  /** column name */
  KccaMobile2 = "KccaMobile2",
  /** column name */
  KccaNin = "KccaNin",
  /** column name */
  KccaSurname = "KccaSurname",
  /** column name */
  KccaTin = "KccaTin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MatchCategory = "MatchCategory",
  /** column name */
  MatchDetails = "MatchDetails",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MatchScoreAtributes = "MatchScoreAtributes",
  /** column name */
  NumOwnedNonRateable = "NumOwnedNonRateable",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  SumAdjustedIncome = "SumAdjustedIncome",
  /** column name */
  SumGrossValue = "SumGrossValue",
  /** column name */
  SumNonRateableValue = "SumNonRateableValue",
  /** column name */
  SumRateableValue = "SumRateableValue",
  /** column name */
  TotalRentedProperties = "TotalRentedProperties",
  /** column name */
  UraDob = "UraDob",
  /** column name */
  UraEmailId = "UraEmailId",
  /** column name */
  UraFirstname = "UraFirstname",
  /** column name */
  UraLandlineNumber = "UraLandlineNumber",
  /** column name */
  UraMiddlename = "UraMiddlename",
  /** column name */
  UraMobileNumber = "UraMobileNumber",
  /** column name */
  UraNationalId = "UraNationalId",
  /** column name */
  UraSurname = "UraSurname",
  /** column name */
  UraTinNo = "UraTinNo",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_RMatchSummary904" */
export type Rtcs_Db_Rmatch_RMatchSummary904_Set_Input = {
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaDob?: Maybe<Scalars["timestamp"]>;
  KccaEmail?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaLandline?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaMobile1?: Maybe<Scalars["String"]>;
  KccaMobile2?: Maybe<Scalars["String"]>;
  KccaNin?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchCategory?: Maybe<Scalars["String"]>;
  MatchDetails?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MatchScoreAtributes?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraDob?: Maybe<Scalars["timestamptz"]>;
  UraEmailId?: Maybe<Scalars["String"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraMobileNumber?: Maybe<Scalars["String"]>;
  UraNationalId?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_RMatchSummary904_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary904_stddev_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_RMatchSummary904" */
export type Rtcs_Db_Rmatch_RMatchSummary904_Stddev_Order_By = {
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_RMatchSummary904_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary904_stddev_pop_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_RMatchSummary904" */
export type Rtcs_Db_Rmatch_RMatchSummary904_Stddev_Pop_Order_By = {
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_RMatchSummary904_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary904_stddev_samp_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_RMatchSummary904" */
export type Rtcs_Db_Rmatch_RMatchSummary904_Stddev_Samp_Order_By = {
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_RMatchSummary904_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary904_sum_fields";
  CountUra?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraLandlineNumber?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_RMatchSummary904" */
export type Rtcs_Db_Rmatch_RMatchSummary904_Sum_Order_By = {
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_RMatchSummary904_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary904_var_pop_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_RMatchSummary904" */
export type Rtcs_Db_Rmatch_RMatchSummary904_Var_Pop_Order_By = {
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_RMatchSummary904_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary904_var_samp_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_RMatchSummary904" */
export type Rtcs_Db_Rmatch_RMatchSummary904_Var_Samp_Order_By = {
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_RMatchSummary904_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary904_variance_fields";
  CountUra?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraLandlineNumber?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_RMatchSummary904" */
export type Rtcs_Db_Rmatch_RMatchSummary904_Variance_Order_By = {
  CountUra?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraLandlineNumber?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_RMatchSummary916" */
export type Rtcs_Db_Rmatch_RMatchSummary916 = {
  __typename?: "rtcs_db_rmatch_RMatchSummary916";
  AvgRate?: Maybe<Scalars["float8"]>;
  ComplianceCategory?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Difference?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  PercentageDifference?: Maybe<Scalars["float8"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTin?: Maybe<Scalars["bigint"]>;
  Urid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy = {
  __typename?: "rtcs_db_rmatch_RMatchSummary916ExcessiveOwnerOccupancy";
  CustomerId?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NumOwnedOccupied?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Riskscore?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTin?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregate = {
  __typename?: "rtcs_db_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy>;
};

/** aggregate fields of "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregate_Fields =
  {
    __typename?: "rtcs_db_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregate_fields";
    avg?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Avg_Fields>;
    count?: Maybe<Scalars["Int"]>;
    max?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Max_Fields>;
    min?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Min_Fields>;
    stddev?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Stddev_Fields>;
    stddev_pop?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Stddev_Samp_Fields>;
    sum?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Sum_Fields>;
    var_pop?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Var_Pop_Fields>;
    var_samp?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Var_Samp_Fields>;
    variance?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Variance_Fields>;
  };

/** aggregate fields of "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregate_Order_By =
  {
    avg?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Max_Order_By>;
    min?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Min_Order_By>;
    stddev?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Stddev_Order_By>;
    stddev_pop?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Stddev_Samp_Order_By>;
    sum?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Sum_Order_By>;
    var_pop?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Var_Pop_Order_By>;
    var_samp?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Var_Samp_Order_By>;
    variance?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Variance_Order_By>;
  };

/** input type for inserting array relation for remote table "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Avg_Fields =
  {
    __typename?: "rtcs_db_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_avg_fields";
    MatchScore?: Maybe<Scalars["Float"]>;
    NumOwnedOccupied?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    Riskscore?: Maybe<Scalars["Float"]>;
    SumAdjustedIncome?: Maybe<Scalars["Float"]>;
    SumGrossValue?: Maybe<Scalars["Float"]>;
    SumNonRateableValue?: Maybe<Scalars["Float"]>;
    SumRateableValue?: Maybe<Scalars["Float"]>;
    TotalRentedProperties?: Maybe<Scalars["Float"]>;
  };

/** order by avg() on columns of table "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Avg_Order_By =
  {
    MatchScore?: Maybe<Order_By>;
    NumOwnedOccupied?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    Riskscore?: Maybe<Order_By>;
    SumAdjustedIncome?: Maybe<Order_By>;
    SumGrossValue?: Maybe<Order_By>;
    SumNonRateableValue?: Maybe<Order_By>;
    SumRateableValue?: Maybe<Order_By>;
    TotalRentedProperties?: Maybe<Order_By>;
  };

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Bool_Exp = {
  CustomerId?: Maybe<String_Comparison_Exp>;
  EmailId?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  KccaFirstName?: Maybe<String_Comparison_Exp>;
  KccaMiddleName?: Maybe<String_Comparison_Exp>;
  KccaSurname?: Maybe<String_Comparison_Exp>;
  KccaTin?: Maybe<String_Comparison_Exp>;
  MatchKccaUra?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Bigint_Comparison_Exp>;
  MobileNumber?: Maybe<String_Comparison_Exp>;
  NationalId?: Maybe<String_Comparison_Exp>;
  NumOwnedOccupied?: Maybe<Bigint_Comparison_Exp>;
  NumProperties?: Maybe<Bigint_Comparison_Exp>;
  Riskscore?: Maybe<Float8_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  ScoreMatchAtributes?: Maybe<String_Comparison_Exp>;
  SumAdjustedIncome?: Maybe<Float8_Comparison_Exp>;
  SumGrossValue?: Maybe<Float8_Comparison_Exp>;
  SumNonRateableValue?: Maybe<Float8_Comparison_Exp>;
  SumRateableValue?: Maybe<Float8_Comparison_Exp>;
  TotalRentedProperties?: Maybe<Bigint_Comparison_Exp>;
  UraFirstname?: Maybe<String_Comparison_Exp>;
  UraMatchingTins?: Maybe<String_Comparison_Exp>;
  UraMiddlename?: Maybe<String_Comparison_Exp>;
  UraSurname?: Maybe<String_Comparison_Exp>;
  UraTin?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<
      Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Bool_Exp>
    >
  >;
  _not?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Bool_Exp>;
  _or?: Maybe<
    Array<
      Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Bool_Exp>
    >
  >;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Inc_Input = {
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumOwnedOccupied?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Riskscore?: Maybe<Scalars["float8"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Insert_Input =
  {
    CustomerId?: Maybe<Scalars["String"]>;
    EmailId?: Maybe<Scalars["String"]>;
    IsIncomeSrcRental?: Maybe<Scalars["String"]>;
    KccaFirstName?: Maybe<Scalars["String"]>;
    KccaMiddleName?: Maybe<Scalars["String"]>;
    KccaSurname?: Maybe<Scalars["String"]>;
    KccaTin?: Maybe<Scalars["String"]>;
    MatchKccaUra?: Maybe<Scalars["String"]>;
    MatchScore?: Maybe<Scalars["bigint"]>;
    MobileNumber?: Maybe<Scalars["String"]>;
    NationalId?: Maybe<Scalars["String"]>;
    NumOwnedOccupied?: Maybe<Scalars["bigint"]>;
    NumProperties?: Maybe<Scalars["bigint"]>;
    Riskscore?: Maybe<Scalars["float8"]>;
    Rnid?: Maybe<Scalars["String"]>;
    ScoreMatchAtributes?: Maybe<Scalars["String"]>;
    SumAdjustedIncome?: Maybe<Scalars["float8"]>;
    SumGrossValue?: Maybe<Scalars["float8"]>;
    SumNonRateableValue?: Maybe<Scalars["float8"]>;
    SumRateableValue?: Maybe<Scalars["float8"]>;
    TotalRentedProperties?: Maybe<Scalars["bigint"]>;
    UraFirstname?: Maybe<Scalars["String"]>;
    UraMatchingTins?: Maybe<Scalars["String"]>;
    UraMiddlename?: Maybe<Scalars["String"]>;
    UraSurname?: Maybe<Scalars["String"]>;
    UraTin?: Maybe<Scalars["String"]>;
  };

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Max_Fields =
  {
    __typename?: "rtcs_db_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_max_fields";
    CustomerId?: Maybe<Scalars["String"]>;
    EmailId?: Maybe<Scalars["String"]>;
    IsIncomeSrcRental?: Maybe<Scalars["String"]>;
    KccaFirstName?: Maybe<Scalars["String"]>;
    KccaMiddleName?: Maybe<Scalars["String"]>;
    KccaSurname?: Maybe<Scalars["String"]>;
    KccaTin?: Maybe<Scalars["String"]>;
    MatchKccaUra?: Maybe<Scalars["String"]>;
    MatchScore?: Maybe<Scalars["bigint"]>;
    MobileNumber?: Maybe<Scalars["String"]>;
    NationalId?: Maybe<Scalars["String"]>;
    NumOwnedOccupied?: Maybe<Scalars["bigint"]>;
    NumProperties?: Maybe<Scalars["bigint"]>;
    Riskscore?: Maybe<Scalars["float8"]>;
    Rnid?: Maybe<Scalars["String"]>;
    ScoreMatchAtributes?: Maybe<Scalars["String"]>;
    SumAdjustedIncome?: Maybe<Scalars["float8"]>;
    SumGrossValue?: Maybe<Scalars["float8"]>;
    SumNonRateableValue?: Maybe<Scalars["float8"]>;
    SumRateableValue?: Maybe<Scalars["float8"]>;
    TotalRentedProperties?: Maybe<Scalars["bigint"]>;
    UraFirstname?: Maybe<Scalars["String"]>;
    UraMatchingTins?: Maybe<Scalars["String"]>;
    UraMiddlename?: Maybe<Scalars["String"]>;
    UraSurname?: Maybe<Scalars["String"]>;
    UraTin?: Maybe<Scalars["String"]>;
  };

/** order by max() on columns of table "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Max_Order_By =
  {
    CustomerId?: Maybe<Order_By>;
    EmailId?: Maybe<Order_By>;
    IsIncomeSrcRental?: Maybe<Order_By>;
    KccaFirstName?: Maybe<Order_By>;
    KccaMiddleName?: Maybe<Order_By>;
    KccaSurname?: Maybe<Order_By>;
    KccaTin?: Maybe<Order_By>;
    MatchKccaUra?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    MobileNumber?: Maybe<Order_By>;
    NationalId?: Maybe<Order_By>;
    NumOwnedOccupied?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    Riskscore?: Maybe<Order_By>;
    Rnid?: Maybe<Order_By>;
    ScoreMatchAtributes?: Maybe<Order_By>;
    SumAdjustedIncome?: Maybe<Order_By>;
    SumGrossValue?: Maybe<Order_By>;
    SumNonRateableValue?: Maybe<Order_By>;
    SumRateableValue?: Maybe<Order_By>;
    TotalRentedProperties?: Maybe<Order_By>;
    UraFirstname?: Maybe<Order_By>;
    UraMatchingTins?: Maybe<Order_By>;
    UraMiddlename?: Maybe<Order_By>;
    UraSurname?: Maybe<Order_By>;
    UraTin?: Maybe<Order_By>;
  };

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Min_Fields =
  {
    __typename?: "rtcs_db_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_min_fields";
    CustomerId?: Maybe<Scalars["String"]>;
    EmailId?: Maybe<Scalars["String"]>;
    IsIncomeSrcRental?: Maybe<Scalars["String"]>;
    KccaFirstName?: Maybe<Scalars["String"]>;
    KccaMiddleName?: Maybe<Scalars["String"]>;
    KccaSurname?: Maybe<Scalars["String"]>;
    KccaTin?: Maybe<Scalars["String"]>;
    MatchKccaUra?: Maybe<Scalars["String"]>;
    MatchScore?: Maybe<Scalars["bigint"]>;
    MobileNumber?: Maybe<Scalars["String"]>;
    NationalId?: Maybe<Scalars["String"]>;
    NumOwnedOccupied?: Maybe<Scalars["bigint"]>;
    NumProperties?: Maybe<Scalars["bigint"]>;
    Riskscore?: Maybe<Scalars["float8"]>;
    Rnid?: Maybe<Scalars["String"]>;
    ScoreMatchAtributes?: Maybe<Scalars["String"]>;
    SumAdjustedIncome?: Maybe<Scalars["float8"]>;
    SumGrossValue?: Maybe<Scalars["float8"]>;
    SumNonRateableValue?: Maybe<Scalars["float8"]>;
    SumRateableValue?: Maybe<Scalars["float8"]>;
    TotalRentedProperties?: Maybe<Scalars["bigint"]>;
    UraFirstname?: Maybe<Scalars["String"]>;
    UraMatchingTins?: Maybe<Scalars["String"]>;
    UraMiddlename?: Maybe<Scalars["String"]>;
    UraSurname?: Maybe<Scalars["String"]>;
    UraTin?: Maybe<Scalars["String"]>;
  };

/** order by min() on columns of table "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Min_Order_By =
  {
    CustomerId?: Maybe<Order_By>;
    EmailId?: Maybe<Order_By>;
    IsIncomeSrcRental?: Maybe<Order_By>;
    KccaFirstName?: Maybe<Order_By>;
    KccaMiddleName?: Maybe<Order_By>;
    KccaSurname?: Maybe<Order_By>;
    KccaTin?: Maybe<Order_By>;
    MatchKccaUra?: Maybe<Order_By>;
    MatchScore?: Maybe<Order_By>;
    MobileNumber?: Maybe<Order_By>;
    NationalId?: Maybe<Order_By>;
    NumOwnedOccupied?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    Riskscore?: Maybe<Order_By>;
    Rnid?: Maybe<Order_By>;
    ScoreMatchAtributes?: Maybe<Order_By>;
    SumAdjustedIncome?: Maybe<Order_By>;
    SumGrossValue?: Maybe<Order_By>;
    SumNonRateableValue?: Maybe<Order_By>;
    SumRateableValue?: Maybe<Order_By>;
    TotalRentedProperties?: Maybe<Order_By>;
    UraFirstname?: Maybe<Order_By>;
    UraMatchingTins?: Maybe<Order_By>;
    UraMiddlename?: Maybe<Order_By>;
    UraSurname?: Maybe<Order_By>;
    UraTin?: Maybe<Order_By>;
  };

/** response of any mutation on the table "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Mutation_Response =
  {
    __typename?: "rtcs_db_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_mutation_response";
    /** number of affected rows by the mutation */
    affected_rows: Scalars["Int"];
    /** data of the affected rows by the mutation */
    returning: Array<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy>;
  };

/** input type for inserting object relation for remote table "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Order_By = {
  CustomerId?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  KccaTin?: Maybe<Order_By>;
  MatchKccaUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NumOwnedOccupied?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  Riskscore?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  ScoreMatchAtributes?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraMatchingTins?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTin?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export enum Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Select_Column {
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  EmailId = "EmailId",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  KccaFirstName = "KccaFirstName",
  /** column name */
  KccaMiddleName = "KccaMiddleName",
  /** column name */
  KccaSurname = "KccaSurname",
  /** column name */
  KccaTin = "KccaTin",
  /** column name */
  MatchKccaUra = "MatchKccaUra",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  NationalId = "NationalId",
  /** column name */
  NumOwnedOccupied = "NumOwnedOccupied",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  Riskscore = "Riskscore",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  ScoreMatchAtributes = "ScoreMatchAtributes",
  /** column name */
  SumAdjustedIncome = "SumAdjustedIncome",
  /** column name */
  SumGrossValue = "SumGrossValue",
  /** column name */
  SumNonRateableValue = "SumNonRateableValue",
  /** column name */
  SumRateableValue = "SumRateableValue",
  /** column name */
  TotalRentedProperties = "TotalRentedProperties",
  /** column name */
  UraFirstname = "UraFirstname",
  /** column name */
  UraMatchingTins = "UraMatchingTins",
  /** column name */
  UraMiddlename = "UraMiddlename",
  /** column name */
  UraSurname = "UraSurname",
  /** column name */
  UraTin = "UraTin",
}

/** input type for updating data in table "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Set_Input = {
  CustomerId?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  KccaTin?: Maybe<Scalars["String"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NumOwnedOccupied?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  Riskscore?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTin?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Stddev_Fields =
  {
    __typename?: "rtcs_db_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_stddev_fields";
    MatchScore?: Maybe<Scalars["Float"]>;
    NumOwnedOccupied?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    Riskscore?: Maybe<Scalars["Float"]>;
    SumAdjustedIncome?: Maybe<Scalars["Float"]>;
    SumGrossValue?: Maybe<Scalars["Float"]>;
    SumNonRateableValue?: Maybe<Scalars["Float"]>;
    SumRateableValue?: Maybe<Scalars["Float"]>;
    TotalRentedProperties?: Maybe<Scalars["Float"]>;
  };

/** order by stddev() on columns of table "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Stddev_Order_By =
  {
    MatchScore?: Maybe<Order_By>;
    NumOwnedOccupied?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    Riskscore?: Maybe<Order_By>;
    SumAdjustedIncome?: Maybe<Order_By>;
    SumGrossValue?: Maybe<Order_By>;
    SumNonRateableValue?: Maybe<Order_By>;
    SumRateableValue?: Maybe<Order_By>;
    TotalRentedProperties?: Maybe<Order_By>;
  };

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Stddev_Pop_Fields =
  {
    __typename?: "rtcs_db_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_stddev_pop_fields";
    MatchScore?: Maybe<Scalars["Float"]>;
    NumOwnedOccupied?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    Riskscore?: Maybe<Scalars["Float"]>;
    SumAdjustedIncome?: Maybe<Scalars["Float"]>;
    SumGrossValue?: Maybe<Scalars["Float"]>;
    SumNonRateableValue?: Maybe<Scalars["Float"]>;
    SumRateableValue?: Maybe<Scalars["Float"]>;
    TotalRentedProperties?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Stddev_Pop_Order_By =
  {
    MatchScore?: Maybe<Order_By>;
    NumOwnedOccupied?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    Riskscore?: Maybe<Order_By>;
    SumAdjustedIncome?: Maybe<Order_By>;
    SumGrossValue?: Maybe<Order_By>;
    SumNonRateableValue?: Maybe<Order_By>;
    SumRateableValue?: Maybe<Order_By>;
    TotalRentedProperties?: Maybe<Order_By>;
  };

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Stddev_Samp_Fields =
  {
    __typename?: "rtcs_db_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_stddev_samp_fields";
    MatchScore?: Maybe<Scalars["Float"]>;
    NumOwnedOccupied?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    Riskscore?: Maybe<Scalars["Float"]>;
    SumAdjustedIncome?: Maybe<Scalars["Float"]>;
    SumGrossValue?: Maybe<Scalars["Float"]>;
    SumNonRateableValue?: Maybe<Scalars["Float"]>;
    SumRateableValue?: Maybe<Scalars["Float"]>;
    TotalRentedProperties?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Stddev_Samp_Order_By =
  {
    MatchScore?: Maybe<Order_By>;
    NumOwnedOccupied?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    Riskscore?: Maybe<Order_By>;
    SumAdjustedIncome?: Maybe<Order_By>;
    SumGrossValue?: Maybe<Order_By>;
    SumNonRateableValue?: Maybe<Order_By>;
    SumRateableValue?: Maybe<Order_By>;
    TotalRentedProperties?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Sum_Fields =
  {
    __typename?: "rtcs_db_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_sum_fields";
    MatchScore?: Maybe<Scalars["bigint"]>;
    NumOwnedOccupied?: Maybe<Scalars["bigint"]>;
    NumProperties?: Maybe<Scalars["bigint"]>;
    Riskscore?: Maybe<Scalars["float8"]>;
    SumAdjustedIncome?: Maybe<Scalars["float8"]>;
    SumGrossValue?: Maybe<Scalars["float8"]>;
    SumNonRateableValue?: Maybe<Scalars["float8"]>;
    SumRateableValue?: Maybe<Scalars["float8"]>;
    TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  };

/** order by sum() on columns of table "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Sum_Order_By =
  {
    MatchScore?: Maybe<Order_By>;
    NumOwnedOccupied?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    Riskscore?: Maybe<Order_By>;
    SumAdjustedIncome?: Maybe<Order_By>;
    SumGrossValue?: Maybe<Order_By>;
    SumNonRateableValue?: Maybe<Order_By>;
    SumRateableValue?: Maybe<Order_By>;
    TotalRentedProperties?: Maybe<Order_By>;
  };

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Var_Pop_Fields =
  {
    __typename?: "rtcs_db_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_var_pop_fields";
    MatchScore?: Maybe<Scalars["Float"]>;
    NumOwnedOccupied?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    Riskscore?: Maybe<Scalars["Float"]>;
    SumAdjustedIncome?: Maybe<Scalars["Float"]>;
    SumGrossValue?: Maybe<Scalars["Float"]>;
    SumNonRateableValue?: Maybe<Scalars["Float"]>;
    SumRateableValue?: Maybe<Scalars["Float"]>;
    TotalRentedProperties?: Maybe<Scalars["Float"]>;
  };

/** order by var_pop() on columns of table "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Var_Pop_Order_By =
  {
    MatchScore?: Maybe<Order_By>;
    NumOwnedOccupied?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    Riskscore?: Maybe<Order_By>;
    SumAdjustedIncome?: Maybe<Order_By>;
    SumGrossValue?: Maybe<Order_By>;
    SumNonRateableValue?: Maybe<Order_By>;
    SumRateableValue?: Maybe<Order_By>;
    TotalRentedProperties?: Maybe<Order_By>;
  };

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Var_Samp_Fields =
  {
    __typename?: "rtcs_db_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_var_samp_fields";
    MatchScore?: Maybe<Scalars["Float"]>;
    NumOwnedOccupied?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    Riskscore?: Maybe<Scalars["Float"]>;
    SumAdjustedIncome?: Maybe<Scalars["Float"]>;
    SumGrossValue?: Maybe<Scalars["Float"]>;
    SumNonRateableValue?: Maybe<Scalars["Float"]>;
    SumRateableValue?: Maybe<Scalars["Float"]>;
    TotalRentedProperties?: Maybe<Scalars["Float"]>;
  };

/** order by var_samp() on columns of table "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Var_Samp_Order_By =
  {
    MatchScore?: Maybe<Order_By>;
    NumOwnedOccupied?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    Riskscore?: Maybe<Order_By>;
    SumAdjustedIncome?: Maybe<Order_By>;
    SumGrossValue?: Maybe<Order_By>;
    SumNonRateableValue?: Maybe<Order_By>;
    SumRateableValue?: Maybe<Order_By>;
    TotalRentedProperties?: Maybe<Order_By>;
  };

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Variance_Fields =
  {
    __typename?: "rtcs_db_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_variance_fields";
    MatchScore?: Maybe<Scalars["Float"]>;
    NumOwnedOccupied?: Maybe<Scalars["Float"]>;
    NumProperties?: Maybe<Scalars["Float"]>;
    Riskscore?: Maybe<Scalars["Float"]>;
    SumAdjustedIncome?: Maybe<Scalars["Float"]>;
    SumGrossValue?: Maybe<Scalars["Float"]>;
    SumNonRateableValue?: Maybe<Scalars["Float"]>;
    SumRateableValue?: Maybe<Scalars["Float"]>;
    TotalRentedProperties?: Maybe<Scalars["Float"]>;
  };

/** order by variance() on columns of table "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
export type Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Variance_Order_By =
  {
    MatchScore?: Maybe<Order_By>;
    NumOwnedOccupied?: Maybe<Order_By>;
    NumProperties?: Maybe<Order_By>;
    Riskscore?: Maybe<Order_By>;
    SumAdjustedIncome?: Maybe<Order_By>;
    SumGrossValue?: Maybe<Order_By>;
    SumNonRateableValue?: Maybe<Order_By>;
    SumRateableValue?: Maybe<Order_By>;
    TotalRentedProperties?: Maybe<Order_By>;
  };

/** aggregated selection of "rtcs_db.rmatch_RMatchSummary916" */
export type Rtcs_Db_Rmatch_RMatchSummary916_Aggregate = {
  __typename?: "rtcs_db_rmatch_RMatchSummary916_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_RMatchSummary916>;
};

/** aggregate fields of "rtcs_db.rmatch_RMatchSummary916" */
export type Rtcs_Db_Rmatch_RMatchSummary916_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary916_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_RMatchSummary916" */
export type Rtcs_Db_Rmatch_RMatchSummary916_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary916_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_RMatchSummary916" */
export type Rtcs_Db_Rmatch_RMatchSummary916_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_RMatchSummary916" */
export type Rtcs_Db_Rmatch_RMatchSummary916_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_RMatchSummary916_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_RMatchSummary916_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary916_avg_fields";
  AvgRate?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  PercentageDifference?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraTin?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_RMatchSummary916" */
export type Rtcs_Db_Rmatch_RMatchSummary916_Avg_Order_By = {
  AvgRate?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  PercentageDifference?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTin?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_RMatchSummary916". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_RMatchSummary916_Bool_Exp = {
  AvgRate?: Maybe<Float8_Comparison_Exp>;
  ComplianceCategory?: Maybe<String_Comparison_Exp>;
  CountUra?: Maybe<Bigint_Comparison_Exp>;
  CustomerId?: Maybe<Bigint_Comparison_Exp>;
  Difference?: Maybe<Float8_Comparison_Exp>;
  EmailId?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  IsAmendment?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcEmp?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  KccaFirstName?: Maybe<String_Comparison_Exp>;
  KccaMiddleName?: Maybe<String_Comparison_Exp>;
  KccaSurname?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MatchKccaUra?: Maybe<String_Comparison_Exp>;
  MatchScore?: Maybe<Bigint_Comparison_Exp>;
  MobileNumber?: Maybe<String_Comparison_Exp>;
  NationalId?: Maybe<String_Comparison_Exp>;
  NumOwnedNonRateable?: Maybe<Bigint_Comparison_Exp>;
  NumProperties?: Maybe<Bigint_Comparison_Exp>;
  PercentageDifference?: Maybe<Float8_Comparison_Exp>;
  PropincIncome?: Maybe<Float8_Comparison_Exp>;
  RentincIncome?: Maybe<Float8_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnPeriodYear?: Maybe<String_Comparison_Exp>;
  ScoreMatchAtributes?: Maybe<String_Comparison_Exp>;
  SumAdjustedIncome?: Maybe<Float8_Comparison_Exp>;
  SumGrossValue?: Maybe<Float8_Comparison_Exp>;
  SumNonRateableValue?: Maybe<Float8_Comparison_Exp>;
  SumRateableValue?: Maybe<Float8_Comparison_Exp>;
  TotalRentedProperties?: Maybe<Bigint_Comparison_Exp>;
  UraFirstname?: Maybe<String_Comparison_Exp>;
  UraMatchingTins?: Maybe<String_Comparison_Exp>;
  UraMiddlename?: Maybe<String_Comparison_Exp>;
  UraSurname?: Maybe<String_Comparison_Exp>;
  UraTin?: Maybe<Bigint_Comparison_Exp>;
  Urid?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_RMatchSummary916" */
export type Rtcs_Db_Rmatch_RMatchSummary916_Inc_Input = {
  AvgRate?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  PercentageDifference?: Maybe<Scalars["float8"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraTin?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_RMatchSummary916" */
export type Rtcs_Db_Rmatch_RMatchSummary916_Insert_Input = {
  AvgRate?: Maybe<Scalars["float8"]>;
  ComplianceCategory?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Difference?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  PercentageDifference?: Maybe<Scalars["float8"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTin?: Maybe<Scalars["bigint"]>;
  Urid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_RMatchSummary916_Max_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary916_max_fields";
  AvgRate?: Maybe<Scalars["float8"]>;
  ComplianceCategory?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Difference?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  PercentageDifference?: Maybe<Scalars["float8"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTin?: Maybe<Scalars["bigint"]>;
  Urid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_RMatchSummary916" */
export type Rtcs_Db_Rmatch_RMatchSummary916_Max_Order_By = {
  AvgRate?: Maybe<Order_By>;
  ComplianceCategory?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchKccaUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  PercentageDifference?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  ScoreMatchAtributes?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraMatchingTins?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTin?: Maybe<Order_By>;
  Urid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_RMatchSummary916_Min_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary916_min_fields";
  AvgRate?: Maybe<Scalars["float8"]>;
  ComplianceCategory?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Difference?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  PercentageDifference?: Maybe<Scalars["float8"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTin?: Maybe<Scalars["bigint"]>;
  Urid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_RMatchSummary916" */
export type Rtcs_Db_Rmatch_RMatchSummary916_Min_Order_By = {
  AvgRate?: Maybe<Order_By>;
  ComplianceCategory?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchKccaUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  PercentageDifference?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  ScoreMatchAtributes?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraMatchingTins?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTin?: Maybe<Order_By>;
  Urid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_RMatchSummary916" */
export type Rtcs_Db_Rmatch_RMatchSummary916_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_RMatchSummary916_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_RMatchSummary916>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_RMatchSummary916" */
export type Rtcs_Db_Rmatch_RMatchSummary916_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_RMatchSummary916_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_RMatchSummary916" */
export type Rtcs_Db_Rmatch_RMatchSummary916_Order_By = {
  AvgRate?: Maybe<Order_By>;
  ComplianceCategory?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  KccaFirstName?: Maybe<Order_By>;
  KccaMiddleName?: Maybe<Order_By>;
  KccaSurname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchKccaUra?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  PercentageDifference?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  ScoreMatchAtributes?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraFirstname?: Maybe<Order_By>;
  UraMatchingTins?: Maybe<Order_By>;
  UraMiddlename?: Maybe<Order_By>;
  UraSurname?: Maybe<Order_By>;
  UraTin?: Maybe<Order_By>;
  Urid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_RMatchSummary916" */
export enum Rtcs_Db_Rmatch_RMatchSummary916_Select_Column {
  /** column name */
  AvgRate = "AvgRate",
  /** column name */
  ComplianceCategory = "ComplianceCategory",
  /** column name */
  CountUra = "CountUra",
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  Difference = "Difference",
  /** column name */
  EmailId = "EmailId",
  /** column name */
  File = "File",
  /** column name */
  IsAmendment = "IsAmendment",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcEmp = "IsIncomeSrcEmp",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  KccaFirstName = "KccaFirstName",
  /** column name */
  KccaMiddleName = "KccaMiddleName",
  /** column name */
  KccaSurname = "KccaSurname",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MatchKccaUra = "MatchKccaUra",
  /** column name */
  MatchScore = "MatchScore",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  NationalId = "NationalId",
  /** column name */
  NumOwnedNonRateable = "NumOwnedNonRateable",
  /** column name */
  NumProperties = "NumProperties",
  /** column name */
  PercentageDifference = "PercentageDifference",
  /** column name */
  PropincIncome = "PropincIncome",
  /** column name */
  RentincIncome = "RentincIncome",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnPeriodYear = "RtnPeriodYear",
  /** column name */
  ScoreMatchAtributes = "ScoreMatchAtributes",
  /** column name */
  SumAdjustedIncome = "SumAdjustedIncome",
  /** column name */
  SumGrossValue = "SumGrossValue",
  /** column name */
  SumNonRateableValue = "SumNonRateableValue",
  /** column name */
  SumRateableValue = "SumRateableValue",
  /** column name */
  TotalRentedProperties = "TotalRentedProperties",
  /** column name */
  UraFirstname = "UraFirstname",
  /** column name */
  UraMatchingTins = "UraMatchingTins",
  /** column name */
  UraMiddlename = "UraMiddlename",
  /** column name */
  UraSurname = "UraSurname",
  /** column name */
  UraTin = "UraTin",
  /** column name */
  Urid = "Urid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_RMatchSummary916" */
export type Rtcs_Db_Rmatch_RMatchSummary916_Set_Input = {
  AvgRate?: Maybe<Scalars["float8"]>;
  ComplianceCategory?: Maybe<Scalars["String"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Difference?: Maybe<Scalars["float8"]>;
  EmailId?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  KccaFirstName?: Maybe<Scalars["String"]>;
  KccaMiddleName?: Maybe<Scalars["String"]>;
  KccaSurname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchKccaUra?: Maybe<Scalars["String"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  PercentageDifference?: Maybe<Scalars["float8"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  ScoreMatchAtributes?: Maybe<Scalars["String"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraFirstname?: Maybe<Scalars["String"]>;
  UraMatchingTins?: Maybe<Scalars["String"]>;
  UraMiddlename?: Maybe<Scalars["String"]>;
  UraSurname?: Maybe<Scalars["String"]>;
  UraTin?: Maybe<Scalars["bigint"]>;
  Urid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_RMatchSummary916_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary916_stddev_fields";
  AvgRate?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  PercentageDifference?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraTin?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_RMatchSummary916" */
export type Rtcs_Db_Rmatch_RMatchSummary916_Stddev_Order_By = {
  AvgRate?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  PercentageDifference?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTin?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_RMatchSummary916_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary916_stddev_pop_fields";
  AvgRate?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  PercentageDifference?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraTin?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_RMatchSummary916" */
export type Rtcs_Db_Rmatch_RMatchSummary916_Stddev_Pop_Order_By = {
  AvgRate?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  PercentageDifference?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTin?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_RMatchSummary916_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary916_stddev_samp_fields";
  AvgRate?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  PercentageDifference?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraTin?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_RMatchSummary916" */
export type Rtcs_Db_Rmatch_RMatchSummary916_Stddev_Samp_Order_By = {
  AvgRate?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  PercentageDifference?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTin?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_RMatchSummary916_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary916_sum_fields";
  AvgRate?: Maybe<Scalars["float8"]>;
  CountUra?: Maybe<Scalars["bigint"]>;
  CustomerId?: Maybe<Scalars["bigint"]>;
  Difference?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchScore?: Maybe<Scalars["bigint"]>;
  NumOwnedNonRateable?: Maybe<Scalars["bigint"]>;
  NumProperties?: Maybe<Scalars["bigint"]>;
  PercentageDifference?: Maybe<Scalars["float8"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  SumAdjustedIncome?: Maybe<Scalars["float8"]>;
  SumGrossValue?: Maybe<Scalars["float8"]>;
  SumNonRateableValue?: Maybe<Scalars["float8"]>;
  SumRateableValue?: Maybe<Scalars["float8"]>;
  TotalRentedProperties?: Maybe<Scalars["bigint"]>;
  UraTin?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_RMatchSummary916" */
export type Rtcs_Db_Rmatch_RMatchSummary916_Sum_Order_By = {
  AvgRate?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  PercentageDifference?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTin?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_RMatchSummary916_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary916_var_pop_fields";
  AvgRate?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  PercentageDifference?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraTin?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_RMatchSummary916" */
export type Rtcs_Db_Rmatch_RMatchSummary916_Var_Pop_Order_By = {
  AvgRate?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  PercentageDifference?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTin?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_RMatchSummary916_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary916_var_samp_fields";
  AvgRate?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  PercentageDifference?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraTin?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_RMatchSummary916" */
export type Rtcs_Db_Rmatch_RMatchSummary916_Var_Samp_Order_By = {
  AvgRate?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  PercentageDifference?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTin?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_RMatchSummary916_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary916_variance_fields";
  AvgRate?: Maybe<Scalars["Float"]>;
  CountUra?: Maybe<Scalars["Float"]>;
  CustomerId?: Maybe<Scalars["Float"]>;
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MatchScore?: Maybe<Scalars["Float"]>;
  NumOwnedNonRateable?: Maybe<Scalars["Float"]>;
  NumProperties?: Maybe<Scalars["Float"]>;
  PercentageDifference?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  SumAdjustedIncome?: Maybe<Scalars["Float"]>;
  SumGrossValue?: Maybe<Scalars["Float"]>;
  SumNonRateableValue?: Maybe<Scalars["Float"]>;
  SumRateableValue?: Maybe<Scalars["Float"]>;
  TotalRentedProperties?: Maybe<Scalars["Float"]>;
  UraTin?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_RMatchSummary916" */
export type Rtcs_Db_Rmatch_RMatchSummary916_Variance_Order_By = {
  AvgRate?: Maybe<Order_By>;
  CountUra?: Maybe<Order_By>;
  CustomerId?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchScore?: Maybe<Order_By>;
  NumOwnedNonRateable?: Maybe<Order_By>;
  NumProperties?: Maybe<Order_By>;
  PercentageDifference?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  SumAdjustedIncome?: Maybe<Order_By>;
  SumGrossValue?: Maybe<Order_By>;
  SumNonRateableValue?: Maybe<Order_By>;
  SumRateableValue?: Maybe<Order_By>;
  TotalRentedProperties?: Maybe<Order_By>;
  UraTin?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_RMatchSummary937" */
export type Rtcs_Db_Rmatch_RMatchSummary937 = {
  __typename?: "rtcs_db_rmatch_RMatchSummary937";
  Countura?: Maybe<Scalars["bigint"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccaemail1?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccanin1?: Maybe<Scalars["String"]>;
  Kccaphones?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchdetails?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numownednonrateable?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uradob?: Maybe<Scalars["timestamptz"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Uralandlinenumber?: Maybe<Scalars["float8"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["String"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_RMatchSummary937" */
export type Rtcs_Db_Rmatch_RMatchSummary937_Aggregate = {
  __typename?: "rtcs_db_rmatch_RMatchSummary937_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_RMatchSummary937>;
};

/** aggregate fields of "rtcs_db.rmatch_RMatchSummary937" */
export type Rtcs_Db_Rmatch_RMatchSummary937_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary937_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_RMatchSummary937" */
export type Rtcs_Db_Rmatch_RMatchSummary937_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary937_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_RMatchSummary937" */
export type Rtcs_Db_Rmatch_RMatchSummary937_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_RMatchSummary937" */
export type Rtcs_Db_Rmatch_RMatchSummary937_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_RMatchSummary937_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_RMatchSummary937_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary937_avg_fields";
  Countura?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numownednonrateable?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uralandlinenumber?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_RMatchSummary937" */
export type Rtcs_Db_Rmatch_RMatchSummary937_Avg_Order_By = {
  Countura?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numownednonrateable?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uralandlinenumber?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_RMatchSummary937". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_RMatchSummary937_Bool_Exp = {
  Countura?: Maybe<Bigint_Comparison_Exp>;
  Customerid?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Isincomesrcbsns?: Maybe<String_Comparison_Exp>;
  Isincomesrcemp?: Maybe<String_Comparison_Exp>;
  Isincomesrcprpty?: Maybe<String_Comparison_Exp>;
  Isincomesrcrental?: Maybe<String_Comparison_Exp>;
  Kccadob?: Maybe<Timestamptz_Comparison_Exp>;
  Kccaemail?: Maybe<String_Comparison_Exp>;
  Kccaemail1?: Maybe<String_Comparison_Exp>;
  Kccafirstname?: Maybe<String_Comparison_Exp>;
  Kccalandline?: Maybe<String_Comparison_Exp>;
  Kccamiddlename?: Maybe<String_Comparison_Exp>;
  Kccamobile1?: Maybe<String_Comparison_Exp>;
  Kccamobile2?: Maybe<String_Comparison_Exp>;
  Kccanin?: Maybe<String_Comparison_Exp>;
  Kccanin1?: Maybe<String_Comparison_Exp>;
  Kccaphones?: Maybe<String_Comparison_Exp>;
  Kccasurname?: Maybe<String_Comparison_Exp>;
  Kccatin?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Matchcategory?: Maybe<String_Comparison_Exp>;
  Matchdetails?: Maybe<String_Comparison_Exp>;
  Matchscore?: Maybe<Bigint_Comparison_Exp>;
  Matchscoreattributes?: Maybe<String_Comparison_Exp>;
  Numownednonrateable?: Maybe<Bigint_Comparison_Exp>;
  Numproperties?: Maybe<Bigint_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Sumadjustedincome?: Maybe<Float8_Comparison_Exp>;
  Sumgrossvalue?: Maybe<Float8_Comparison_Exp>;
  Sumnonrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Sumrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Totalrentedproperties?: Maybe<Bigint_Comparison_Exp>;
  Uradob?: Maybe<Timestamptz_Comparison_Exp>;
  Uraemailid?: Maybe<String_Comparison_Exp>;
  Uralandlinenumber?: Maybe<Float8_Comparison_Exp>;
  Uramidlename?: Maybe<String_Comparison_Exp>;
  Uramobilenumber?: Maybe<String_Comparison_Exp>;
  Uranationalid?: Maybe<String_Comparison_Exp>;
  Urasurname?: Maybe<String_Comparison_Exp>;
  Uratinno?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_RMatchSummary937" */
export type Rtcs_Db_Rmatch_RMatchSummary937_Inc_Input = {
  Countura?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numownednonrateable?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uralandlinenumber?: Maybe<Scalars["float8"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_RMatchSummary937" */
export type Rtcs_Db_Rmatch_RMatchSummary937_Insert_Input = {
  Countura?: Maybe<Scalars["bigint"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccaemail1?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccanin1?: Maybe<Scalars["String"]>;
  Kccaphones?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchdetails?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numownednonrateable?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uradob?: Maybe<Scalars["timestamptz"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Uralandlinenumber?: Maybe<Scalars["float8"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["String"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_RMatchSummary937_Max_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary937_max_fields";
  Countura?: Maybe<Scalars["bigint"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccaemail1?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccanin1?: Maybe<Scalars["String"]>;
  Kccaphones?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchdetails?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numownednonrateable?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uradob?: Maybe<Scalars["timestamptz"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Uralandlinenumber?: Maybe<Scalars["float8"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["String"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_RMatchSummary937" */
export type Rtcs_Db_Rmatch_RMatchSummary937_Max_Order_By = {
  Countura?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccadob?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccaemail1?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccalandline?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccanin?: Maybe<Order_By>;
  Kccanin1?: Maybe<Order_By>;
  Kccaphones?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchcategory?: Maybe<Order_By>;
  Matchdetails?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Numownednonrateable?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uradob?: Maybe<Order_By>;
  Uraemailid?: Maybe<Order_By>;
  Uralandlinenumber?: Maybe<Order_By>;
  Uramidlename?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
  Uranationalid?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_RMatchSummary937_Min_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary937_min_fields";
  Countura?: Maybe<Scalars["bigint"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccaemail1?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccanin1?: Maybe<Scalars["String"]>;
  Kccaphones?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchdetails?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numownednonrateable?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uradob?: Maybe<Scalars["timestamptz"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Uralandlinenumber?: Maybe<Scalars["float8"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["String"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_RMatchSummary937" */
export type Rtcs_Db_Rmatch_RMatchSummary937_Min_Order_By = {
  Countura?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccadob?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccaemail1?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccalandline?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccanin?: Maybe<Order_By>;
  Kccanin1?: Maybe<Order_By>;
  Kccaphones?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchcategory?: Maybe<Order_By>;
  Matchdetails?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Numownednonrateable?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uradob?: Maybe<Order_By>;
  Uraemailid?: Maybe<Order_By>;
  Uralandlinenumber?: Maybe<Order_By>;
  Uramidlename?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
  Uranationalid?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_RMatchSummary937" */
export type Rtcs_Db_Rmatch_RMatchSummary937_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_RMatchSummary937_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_RMatchSummary937>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_RMatchSummary937" */
export type Rtcs_Db_Rmatch_RMatchSummary937_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_RMatchSummary937_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_RMatchSummary937" */
export type Rtcs_Db_Rmatch_RMatchSummary937_Order_By = {
  Countura?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Isincomesrcbsns?: Maybe<Order_By>;
  Isincomesrcemp?: Maybe<Order_By>;
  Isincomesrcprpty?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccadob?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccaemail1?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccalandline?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccanin?: Maybe<Order_By>;
  Kccanin1?: Maybe<Order_By>;
  Kccaphones?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchcategory?: Maybe<Order_By>;
  Matchdetails?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Numownednonrateable?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uradob?: Maybe<Order_By>;
  Uraemailid?: Maybe<Order_By>;
  Uralandlinenumber?: Maybe<Order_By>;
  Uramidlename?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
  Uranationalid?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_RMatchSummary937" */
export enum Rtcs_Db_Rmatch_RMatchSummary937_Select_Column {
  /** column name */
  Countura = "Countura",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  File = "File",
  /** column name */
  Isincomesrcbsns = "Isincomesrcbsns",
  /** column name */
  Isincomesrcemp = "Isincomesrcemp",
  /** column name */
  Isincomesrcprpty = "Isincomesrcprpty",
  /** column name */
  Isincomesrcrental = "Isincomesrcrental",
  /** column name */
  Kccadob = "Kccadob",
  /** column name */
  Kccaemail = "Kccaemail",
  /** column name */
  Kccaemail1 = "Kccaemail1",
  /** column name */
  Kccafirstname = "Kccafirstname",
  /** column name */
  Kccalandline = "Kccalandline",
  /** column name */
  Kccamiddlename = "Kccamiddlename",
  /** column name */
  Kccamobile1 = "Kccamobile1",
  /** column name */
  Kccamobile2 = "Kccamobile2",
  /** column name */
  Kccanin = "Kccanin",
  /** column name */
  Kccanin1 = "Kccanin1",
  /** column name */
  Kccaphones = "Kccaphones",
  /** column name */
  Kccasurname = "Kccasurname",
  /** column name */
  Kccatin = "Kccatin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Matchcategory = "Matchcategory",
  /** column name */
  Matchdetails = "Matchdetails",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Matchscoreattributes = "Matchscoreattributes",
  /** column name */
  Numownednonrateable = "Numownednonrateable",
  /** column name */
  Numproperties = "Numproperties",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Sumadjustedincome = "Sumadjustedincome",
  /** column name */
  Sumgrossvalue = "Sumgrossvalue",
  /** column name */
  Sumnonrateablevalue = "Sumnonrateablevalue",
  /** column name */
  Sumrateablevalue = "Sumrateablevalue",
  /** column name */
  Totalrentedproperties = "Totalrentedproperties",
  /** column name */
  Uradob = "Uradob",
  /** column name */
  Uraemailid = "Uraemailid",
  /** column name */
  Uralandlinenumber = "Uralandlinenumber",
  /** column name */
  Uramidlename = "Uramidlename",
  /** column name */
  Uramobilenumber = "Uramobilenumber",
  /** column name */
  Uranationalid = "Uranationalid",
  /** column name */
  Urasurname = "Urasurname",
  /** column name */
  Uratinno = "Uratinno",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_RMatchSummary937" */
export type Rtcs_Db_Rmatch_RMatchSummary937_Set_Input = {
  Countura?: Maybe<Scalars["bigint"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcbsns?: Maybe<Scalars["String"]>;
  Isincomesrcemp?: Maybe<Scalars["String"]>;
  Isincomesrcprpty?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["timestamptz"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccaemail1?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccanin1?: Maybe<Scalars["String"]>;
  Kccaphones?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchcategory?: Maybe<Scalars["String"]>;
  Matchdetails?: Maybe<Scalars["String"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numownednonrateable?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uradob?: Maybe<Scalars["timestamptz"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Uralandlinenumber?: Maybe<Scalars["float8"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["String"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_RMatchSummary937_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary937_stddev_fields";
  Countura?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numownednonrateable?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uralandlinenumber?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_RMatchSummary937" */
export type Rtcs_Db_Rmatch_RMatchSummary937_Stddev_Order_By = {
  Countura?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numownednonrateable?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uralandlinenumber?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_RMatchSummary937_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary937_stddev_pop_fields";
  Countura?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numownednonrateable?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uralandlinenumber?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_RMatchSummary937" */
export type Rtcs_Db_Rmatch_RMatchSummary937_Stddev_Pop_Order_By = {
  Countura?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numownednonrateable?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uralandlinenumber?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_RMatchSummary937_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary937_stddev_samp_fields";
  Countura?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numownednonrateable?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uralandlinenumber?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_RMatchSummary937" */
export type Rtcs_Db_Rmatch_RMatchSummary937_Stddev_Samp_Order_By = {
  Countura?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numownednonrateable?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uralandlinenumber?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_RMatchSummary937_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary937_sum_fields";
  Countura?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numownednonrateable?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumadjustedincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumnonrateablevalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uralandlinenumber?: Maybe<Scalars["float8"]>;
  Uratinno?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_RMatchSummary937" */
export type Rtcs_Db_Rmatch_RMatchSummary937_Sum_Order_By = {
  Countura?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numownednonrateable?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uralandlinenumber?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_RMatchSummary937_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary937_var_pop_fields";
  Countura?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numownednonrateable?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uralandlinenumber?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_RMatchSummary937" */
export type Rtcs_Db_Rmatch_RMatchSummary937_Var_Pop_Order_By = {
  Countura?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numownednonrateable?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uralandlinenumber?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_RMatchSummary937_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary937_var_samp_fields";
  Countura?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numownednonrateable?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uralandlinenumber?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_RMatchSummary937" */
export type Rtcs_Db_Rmatch_RMatchSummary937_Var_Samp_Order_By = {
  Countura?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numownednonrateable?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uralandlinenumber?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_RMatchSummary937_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_RMatchSummary937_variance_fields";
  Countura?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numownednonrateable?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumadjustedincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumnonrateablevalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uralandlinenumber?: Maybe<Scalars["Float"]>;
  Uratinno?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_RMatchSummary937" */
export type Rtcs_Db_Rmatch_RMatchSummary937_Variance_Order_By = {
  Countura?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numownednonrateable?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumadjustedincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumnonrateablevalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uralandlinenumber?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_TinsURA" */
export type Rtcs_Db_Rmatch_TinsUra = {
  __typename?: "rtcs_db_rmatch_TinsURA";
  Dob?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_TinsURA" */
export type Rtcs_Db_Rmatch_TinsUra_Aggregate = {
  __typename?: "rtcs_db_rmatch_TinsURA_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_TinsUra_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_TinsUra>;
};

/** aggregate fields of "rtcs_db.rmatch_TinsURA" */
export type Rtcs_Db_Rmatch_TinsUra_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_TinsURA_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_TinsUra_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_TinsUra_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_TinsUra_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_TinsUra_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_TinsUra_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_TinsUra_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_TinsUra_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_TinsUra_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_TinsUra_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_TinsUra_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_TinsURA" */
export type Rtcs_Db_Rmatch_TinsUra_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_TinsUra_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_TinsURA" */
export type Rtcs_Db_Rmatch_TinsUra_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_TinsUra_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_TinsUra_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_TinsUra_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_TinsUra_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_TinsUra_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_TinsUra_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_TinsUra_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_TinsUra_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_TinsUra_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_TinsUra_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_TinsURA" */
export type Rtcs_Db_Rmatch_TinsUra_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_TinsUra_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_TinsUra_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_TinsURA_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_TinsURA" */
export type Rtcs_Db_Rmatch_TinsUra_Avg_Order_By = {
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_TinsURA". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_TinsUra_Bool_Exp = {
  Dob?: Maybe<Timestamptz_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_TinsUra_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_TinsUra_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_TinsUra_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_TinsURA" */
export type Rtcs_Db_Rmatch_TinsUra_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
  Tin?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_TinsURA" */
export type Rtcs_Db_Rmatch_TinsUra_Insert_Input = {
  Dob?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_TinsUra_Max_Fields = {
  __typename?: "rtcs_db_rmatch_TinsURA_max_fields";
  Dob?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_TinsURA" */
export type Rtcs_Db_Rmatch_TinsUra_Max_Order_By = {
  Dob?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_TinsUra_Min_Fields = {
  __typename?: "rtcs_db_rmatch_TinsURA_min_fields";
  Dob?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_TinsURA" */
export type Rtcs_Db_Rmatch_TinsUra_Min_Order_By = {
  Dob?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_TinsURA" */
export type Rtcs_Db_Rmatch_TinsUra_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_TinsURA_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_TinsUra>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_TinsURA" */
export type Rtcs_Db_Rmatch_TinsUra_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_TinsUra_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_TinsURA" */
export type Rtcs_Db_Rmatch_TinsUra_Order_By = {
  Dob?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_TinsURA" */
export enum Rtcs_Db_Rmatch_TinsUra_Select_Column {
  /** column name */
  Dob = "Dob",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Surname = "Surname",
  /** column name */
  Tin = "Tin",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_TinsURA" */
export type Rtcs_Db_Rmatch_TinsUra_Set_Input = {
  Dob?: Maybe<Scalars["timestamptz"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_TinsUra_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_TinsURA_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_TinsURA" */
export type Rtcs_Db_Rmatch_TinsUra_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_TinsUra_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_TinsURA_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_TinsURA" */
export type Rtcs_Db_Rmatch_TinsUra_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_TinsUra_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_TinsURA_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_TinsURA" */
export type Rtcs_Db_Rmatch_TinsUra_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_TinsUra_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_TinsURA_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
  Tin?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_TinsURA" */
export type Rtcs_Db_Rmatch_TinsUra_Sum_Order_By = {
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_TinsUra_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_TinsURA_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_TinsURA" */
export type Rtcs_Db_Rmatch_TinsUra_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_TinsUra_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_TinsURA_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_TinsURA" */
export type Rtcs_Db_Rmatch_TinsUra_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_TinsUra_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_TinsURA_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
  Tin?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_TinsURA" */
export type Rtcs_Db_Rmatch_TinsUra_Variance_Order_By = {
  Line?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_Undeclared" */
export type Rtcs_Db_Rmatch_Undeclared = {
  __typename?: "rtcs_db_rmatch_Undeclared";
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_Undeclared" */
export type Rtcs_Db_Rmatch_Undeclared_Aggregate = {
  __typename?: "rtcs_db_rmatch_Undeclared_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_Undeclared_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_Undeclared>;
};

/** aggregate fields of "rtcs_db.rmatch_Undeclared" */
export type Rtcs_Db_Rmatch_Undeclared_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_Undeclared_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_Undeclared_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_Undeclared_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_Undeclared_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_Undeclared_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_Undeclared_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_Undeclared_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_Undeclared_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_Undeclared_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_Undeclared_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_Undeclared_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_Undeclared" */
export type Rtcs_Db_Rmatch_Undeclared_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_Undeclared_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_Undeclared" */
export type Rtcs_Db_Rmatch_Undeclared_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_Undeclared_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_Undeclared_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_Undeclared_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_Undeclared_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_Undeclared_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_Undeclared_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_Undeclared_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_Undeclared_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_Undeclared_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_Undeclared_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_Undeclared" */
export type Rtcs_Db_Rmatch_Undeclared_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_Undeclared_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_Undeclared_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_Undeclared_avg_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_Undeclared" */
export type Rtcs_Db_Rmatch_Undeclared_Avg_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_Undeclared". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_Undeclared_Bool_Exp = {
  Compliancecategory?: Maybe<String_Comparison_Exp>;
  Customerid?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Isincomesrcrental?: Maybe<String_Comparison_Exp>;
  Kccadob?: Maybe<String_Comparison_Exp>;
  Kccaemail?: Maybe<String_Comparison_Exp>;
  Kccafirstname?: Maybe<String_Comparison_Exp>;
  Kccalandline?: Maybe<String_Comparison_Exp>;
  Kccamiddlename?: Maybe<String_Comparison_Exp>;
  Kccamobile1?: Maybe<String_Comparison_Exp>;
  Kccamobile2?: Maybe<String_Comparison_Exp>;
  Kccanin?: Maybe<String_Comparison_Exp>;
  Kccasurname?: Maybe<String_Comparison_Exp>;
  Kccatin?: Maybe<Float8_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Matchscore?: Maybe<Bigint_Comparison_Exp>;
  Matchscoreattributes?: Maybe<String_Comparison_Exp>;
  Numproperties?: Maybe<Bigint_Comparison_Exp>;
  Sumgrossvalue?: Maybe<Float8_Comparison_Exp>;
  Sumrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Totalrentedproperties?: Maybe<Bigint_Comparison_Exp>;
  Uraemailid?: Maybe<String_Comparison_Exp>;
  Urafirstname?: Maybe<String_Comparison_Exp>;
  Uramidlename?: Maybe<String_Comparison_Exp>;
  Uramobilenumber?: Maybe<Float8_Comparison_Exp>;
  Uranationalid?: Maybe<String_Comparison_Exp>;
  Urasurname?: Maybe<String_Comparison_Exp>;
  Uratinno?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_Undeclared_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_Undeclared_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_Undeclared_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_Undeclared" */
export type Rtcs_Db_Rmatch_Undeclared_Inc_Input = {
  Kccatin?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_Undeclared" */
export type Rtcs_Db_Rmatch_Undeclared_Insert_Input = {
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_Undeclared_Max_Fields = {
  __typename?: "rtcs_db_rmatch_Undeclared_max_fields";
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_Undeclared" */
export type Rtcs_Db_Rmatch_Undeclared_Max_Order_By = {
  Compliancecategory?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccadob?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccalandline?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccanin?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uraemailid?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramidlename?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
  Uranationalid?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_Undeclared_Min_Fields = {
  __typename?: "rtcs_db_rmatch_Undeclared_min_fields";
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_Undeclared" */
export type Rtcs_Db_Rmatch_Undeclared_Min_Order_By = {
  Compliancecategory?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccadob?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccalandline?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccanin?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uraemailid?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramidlename?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
  Uranationalid?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_Undeclared" */
export type Rtcs_Db_Rmatch_Undeclared_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_Undeclared_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_Undeclared>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_Undeclared" */
export type Rtcs_Db_Rmatch_Undeclared_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_Undeclared_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_Undeclared" */
export type Rtcs_Db_Rmatch_Undeclared_Order_By = {
  Compliancecategory?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccadob?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccalandline?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccanin?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uraemailid?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramidlename?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
  Uranationalid?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_Undeclared" */
export enum Rtcs_Db_Rmatch_Undeclared_Select_Column {
  /** column name */
  Compliancecategory = "Compliancecategory",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  File = "File",
  /** column name */
  Isincomesrcrental = "Isincomesrcrental",
  /** column name */
  Kccadob = "Kccadob",
  /** column name */
  Kccaemail = "Kccaemail",
  /** column name */
  Kccafirstname = "Kccafirstname",
  /** column name */
  Kccalandline = "Kccalandline",
  /** column name */
  Kccamiddlename = "Kccamiddlename",
  /** column name */
  Kccamobile1 = "Kccamobile1",
  /** column name */
  Kccamobile2 = "Kccamobile2",
  /** column name */
  Kccanin = "Kccanin",
  /** column name */
  Kccasurname = "Kccasurname",
  /** column name */
  Kccatin = "Kccatin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Matchscoreattributes = "Matchscoreattributes",
  /** column name */
  Numproperties = "Numproperties",
  /** column name */
  Sumgrossvalue = "Sumgrossvalue",
  /** column name */
  Sumrateablevalue = "Sumrateablevalue",
  /** column name */
  Totalrentedproperties = "Totalrentedproperties",
  /** column name */
  Uraemailid = "Uraemailid",
  /** column name */
  Urafirstname = "Urafirstname",
  /** column name */
  Uramidlename = "Uramidlename",
  /** column name */
  Uramobilenumber = "Uramobilenumber",
  /** column name */
  Uranationalid = "Uranationalid",
  /** column name */
  Urasurname = "Urasurname",
  /** column name */
  Uratinno = "Uratinno",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_Undeclared" */
export type Rtcs_Db_Rmatch_Undeclared_Set_Input = {
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_Undeclared_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_Undeclared_stddev_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_Undeclared" */
export type Rtcs_Db_Rmatch_Undeclared_Stddev_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_Undeclared_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_Undeclared_stddev_pop_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_Undeclared" */
export type Rtcs_Db_Rmatch_Undeclared_Stddev_Pop_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_Undeclared_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_Undeclared_stddev_samp_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_Undeclared" */
export type Rtcs_Db_Rmatch_Undeclared_Stddev_Samp_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_Undeclared_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_Undeclared_sum_fields";
  Kccatin?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uramobilenumber?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_Undeclared" */
export type Rtcs_Db_Rmatch_Undeclared_Sum_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_Undeclared_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_Undeclared_var_pop_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_Undeclared" */
export type Rtcs_Db_Rmatch_Undeclared_Var_Pop_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_Undeclared_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_Undeclared_var_samp_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_Undeclared" */
export type Rtcs_Db_Rmatch_Undeclared_Var_Samp_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_Undeclared_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_Undeclared_variance_fields";
  Kccatin?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_Undeclared" */
export type Rtcs_Db_Rmatch_Undeclared_Variance_Order_By = {
  Kccatin?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_Underreported" */
export type Rtcs_Db_Rmatch_Underreported = {
  __typename?: "rtcs_db_rmatch_Underreported";
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["bigint"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["bigint"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_Underreported" */
export type Rtcs_Db_Rmatch_Underreported_Aggregate = {
  __typename?: "rtcs_db_rmatch_Underreported_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_Underreported_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_Underreported>;
};

/** aggregate fields of "rtcs_db.rmatch_Underreported" */
export type Rtcs_Db_Rmatch_Underreported_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_Underreported_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_Underreported_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_Underreported_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_Underreported_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_Underreported_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_Underreported_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_Underreported_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_Underreported_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_Underreported_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_Underreported_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_Underreported_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_Underreported" */
export type Rtcs_Db_Rmatch_Underreported_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_Underreported_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_Underreported" */
export type Rtcs_Db_Rmatch_Underreported_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_Underreported_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_Underreported_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_Underreported_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_Underreported_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_Underreported_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_Underreported_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_Underreported_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_Underreported_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_Underreported_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_Underreported_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_Underreported" */
export type Rtcs_Db_Rmatch_Underreported_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_Underreported_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_Underreported_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_Underreported_avg_fields";
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_Underreported" */
export type Rtcs_Db_Rmatch_Underreported_Avg_Order_By = {
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_Underreported". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_Underreported_Bool_Exp = {
  Compliancecategory?: Maybe<String_Comparison_Exp>;
  Customerid?: Maybe<String_Comparison_Exp>;
  Difference?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Isamendment?: Maybe<String_Comparison_Exp>;
  Isincomesrcrental?: Maybe<String_Comparison_Exp>;
  Kccaemail?: Maybe<String_Comparison_Exp>;
  Kccamobile1?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Matchscore?: Maybe<Bigint_Comparison_Exp>;
  Matchscoreattributes?: Maybe<String_Comparison_Exp>;
  Numproperties?: Maybe<Bigint_Comparison_Exp>;
  Percentagedifference?: Maybe<Bigint_Comparison_Exp>;
  Rentincincome?: Maybe<Float8_Comparison_Exp>;
  Rtnno?: Maybe<String_Comparison_Exp>;
  Rtnperiodyear?: Maybe<String_Comparison_Exp>;
  Sumgrossvalue?: Maybe<Float8_Comparison_Exp>;
  Sumrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Totalrentedproperties?: Maybe<Bigint_Comparison_Exp>;
  Uraemailid?: Maybe<String_Comparison_Exp>;
  Urafirstname?: Maybe<String_Comparison_Exp>;
  Uramidlename?: Maybe<String_Comparison_Exp>;
  Uramobilenumber?: Maybe<Bigint_Comparison_Exp>;
  Uranationalid?: Maybe<String_Comparison_Exp>;
  Urasurname?: Maybe<String_Comparison_Exp>;
  Uratinno?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_Underreported_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_Underreported_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_Underreported_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_Underreported" */
export type Rtcs_Db_Rmatch_Underreported_Inc_Input = {
  Difference?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["bigint"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uramobilenumber?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_Underreported" */
export type Rtcs_Db_Rmatch_Underreported_Insert_Input = {
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["bigint"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["bigint"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_Underreported_Max_Fields = {
  __typename?: "rtcs_db_rmatch_Underreported_max_fields";
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["bigint"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["bigint"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_Underreported" */
export type Rtcs_Db_Rmatch_Underreported_Max_Order_By = {
  Compliancecategory?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uraemailid?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramidlename?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
  Uranationalid?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_Underreported_Min_Fields = {
  __typename?: "rtcs_db_rmatch_Underreported_min_fields";
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["bigint"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["bigint"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_Underreported" */
export type Rtcs_Db_Rmatch_Underreported_Min_Order_By = {
  Compliancecategory?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uraemailid?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramidlename?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
  Uranationalid?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_Underreported" */
export type Rtcs_Db_Rmatch_Underreported_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_Underreported_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_Underreported>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_Underreported" */
export type Rtcs_Db_Rmatch_Underreported_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_Underreported_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_Underreported" */
export type Rtcs_Db_Rmatch_Underreported_Order_By = {
  Compliancecategory?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  Difference?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Isamendment?: Maybe<Order_By>;
  Isincomesrcrental?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Matchscoreattributes?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uraemailid?: Maybe<Order_By>;
  Urafirstname?: Maybe<Order_By>;
  Uramidlename?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
  Uranationalid?: Maybe<Order_By>;
  Urasurname?: Maybe<Order_By>;
  Uratinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_Underreported" */
export enum Rtcs_Db_Rmatch_Underreported_Select_Column {
  /** column name */
  Compliancecategory = "Compliancecategory",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  Difference = "Difference",
  /** column name */
  File = "File",
  /** column name */
  Isamendment = "Isamendment",
  /** column name */
  Isincomesrcrental = "Isincomesrcrental",
  /** column name */
  Kccaemail = "Kccaemail",
  /** column name */
  Kccamobile1 = "Kccamobile1",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Matchscoreattributes = "Matchscoreattributes",
  /** column name */
  Numproperties = "Numproperties",
  /** column name */
  Percentagedifference = "Percentagedifference",
  /** column name */
  Rentincincome = "Rentincincome",
  /** column name */
  Rtnno = "Rtnno",
  /** column name */
  Rtnperiodyear = "Rtnperiodyear",
  /** column name */
  Sumgrossvalue = "Sumgrossvalue",
  /** column name */
  Sumrateablevalue = "Sumrateablevalue",
  /** column name */
  Totalrentedproperties = "Totalrentedproperties",
  /** column name */
  Uraemailid = "Uraemailid",
  /** column name */
  Urafirstname = "Urafirstname",
  /** column name */
  Uramidlename = "Uramidlename",
  /** column name */
  Uramobilenumber = "Uramobilenumber",
  /** column name */
  Uranationalid = "Uranationalid",
  /** column name */
  Urasurname = "Urasurname",
  /** column name */
  Uratinno = "Uratinno",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_Underreported" */
export type Rtcs_Db_Rmatch_Underreported_Set_Input = {
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  Difference?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  Isamendment?: Maybe<Scalars["String"]>;
  Isincomesrcrental?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Matchscoreattributes?: Maybe<Scalars["String"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["bigint"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uraemailid?: Maybe<Scalars["String"]>;
  Urafirstname?: Maybe<Scalars["String"]>;
  Uramidlename?: Maybe<Scalars["String"]>;
  Uramobilenumber?: Maybe<Scalars["bigint"]>;
  Uranationalid?: Maybe<Scalars["String"]>;
  Urasurname?: Maybe<Scalars["String"]>;
  Uratinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_Underreported_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_Underreported_stddev_fields";
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_Underreported" */
export type Rtcs_Db_Rmatch_Underreported_Stddev_Order_By = {
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_Underreported_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_Underreported_stddev_pop_fields";
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_Underreported" */
export type Rtcs_Db_Rmatch_Underreported_Stddev_Pop_Order_By = {
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_Underreported_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_Underreported_stddev_samp_fields";
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_Underreported" */
export type Rtcs_Db_Rmatch_Underreported_Stddev_Samp_Order_By = {
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_Underreported_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_Underreported_sum_fields";
  Difference?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Percentagedifference?: Maybe<Scalars["bigint"]>;
  Rentincincome?: Maybe<Scalars["float8"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  Uramobilenumber?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_Underreported" */
export type Rtcs_Db_Rmatch_Underreported_Sum_Order_By = {
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_Underreported_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_Underreported_var_pop_fields";
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_Underreported" */
export type Rtcs_Db_Rmatch_Underreported_Var_Pop_Order_By = {
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_Underreported_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_Underreported_var_samp_fields";
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_Underreported" */
export type Rtcs_Db_Rmatch_Underreported_Var_Samp_Order_By = {
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_Underreported_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_Underreported_variance_fields";
  Difference?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Percentagedifference?: Maybe<Scalars["Float"]>;
  Rentincincome?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
  Uramobilenumber?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_Underreported" */
export type Rtcs_Db_Rmatch_Underreported_Variance_Order_By = {
  Difference?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Percentagedifference?: Maybe<Order_By>;
  Rentincincome?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  Uramobilenumber?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_Unregistered" */
export type Rtcs_Db_Rmatch_Unregistered = {
  __typename?: "rtcs_db_rmatch_Unregistered";
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_Unregistered" */
export type Rtcs_Db_Rmatch_Unregistered_Aggregate = {
  __typename?: "rtcs_db_rmatch_Unregistered_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_Unregistered_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_Unregistered>;
};

/** aggregate fields of "rtcs_db.rmatch_Unregistered" */
export type Rtcs_Db_Rmatch_Unregistered_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_Unregistered_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_Unregistered_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_Unregistered_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_Unregistered_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_Unregistered_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_Unregistered_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_Unregistered_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_Unregistered_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_Unregistered_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_Unregistered_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_Unregistered_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_Unregistered" */
export type Rtcs_Db_Rmatch_Unregistered_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_Unregistered_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_Unregistered" */
export type Rtcs_Db_Rmatch_Unregistered_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_Unregistered_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_Unregistered_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_Unregistered_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_Unregistered_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_Unregistered_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_Unregistered_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_Unregistered_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_Unregistered_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_Unregistered_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_Unregistered_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_Unregistered" */
export type Rtcs_Db_Rmatch_Unregistered_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_Unregistered_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_Unregistered_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_Unregistered_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_Unregistered" */
export type Rtcs_Db_Rmatch_Unregistered_Avg_Order_By = {
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_Unregistered". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_Unregistered_Bool_Exp = {
  Compliancecategory?: Maybe<String_Comparison_Exp>;
  Customerid?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Kccadob?: Maybe<String_Comparison_Exp>;
  Kccaemail?: Maybe<String_Comparison_Exp>;
  Kccafirstname?: Maybe<String_Comparison_Exp>;
  Kccalandline?: Maybe<String_Comparison_Exp>;
  Kccamiddlename?: Maybe<String_Comparison_Exp>;
  Kccamobile1?: Maybe<String_Comparison_Exp>;
  Kccamobile2?: Maybe<String_Comparison_Exp>;
  Kccanin?: Maybe<String_Comparison_Exp>;
  Kccasurname?: Maybe<String_Comparison_Exp>;
  Kccatin?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Matchscore?: Maybe<Bigint_Comparison_Exp>;
  Numproperties?: Maybe<Bigint_Comparison_Exp>;
  Sumgrossvalue?: Maybe<Float8_Comparison_Exp>;
  Sumrateablevalue?: Maybe<Float8_Comparison_Exp>;
  Totalrentedproperties?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_Unregistered_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_Unregistered_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_Unregistered_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_Unregistered" */
export type Rtcs_Db_Rmatch_Unregistered_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_Unregistered" */
export type Rtcs_Db_Rmatch_Unregistered_Insert_Input = {
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_Unregistered_Max_Fields = {
  __typename?: "rtcs_db_rmatch_Unregistered_max_fields";
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_Unregistered" */
export type Rtcs_Db_Rmatch_Unregistered_Max_Order_By = {
  Compliancecategory?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Kccadob?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccalandline?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccanin?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_Unregistered_Min_Fields = {
  __typename?: "rtcs_db_rmatch_Unregistered_min_fields";
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_Unregistered" */
export type Rtcs_Db_Rmatch_Unregistered_Min_Order_By = {
  Compliancecategory?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Kccadob?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccalandline?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccanin?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_Unregistered" */
export type Rtcs_Db_Rmatch_Unregistered_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_Unregistered_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_Unregistered>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_Unregistered" */
export type Rtcs_Db_Rmatch_Unregistered_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_Unregistered_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_Unregistered" */
export type Rtcs_Db_Rmatch_Unregistered_Order_By = {
  Compliancecategory?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Kccadob?: Maybe<Order_By>;
  Kccaemail?: Maybe<Order_By>;
  Kccafirstname?: Maybe<Order_By>;
  Kccalandline?: Maybe<Order_By>;
  Kccamiddlename?: Maybe<Order_By>;
  Kccamobile1?: Maybe<Order_By>;
  Kccamobile2?: Maybe<Order_By>;
  Kccanin?: Maybe<Order_By>;
  Kccasurname?: Maybe<Order_By>;
  Kccatin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_Unregistered" */
export enum Rtcs_Db_Rmatch_Unregistered_Select_Column {
  /** column name */
  Compliancecategory = "Compliancecategory",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  File = "File",
  /** column name */
  Kccadob = "Kccadob",
  /** column name */
  Kccaemail = "Kccaemail",
  /** column name */
  Kccafirstname = "Kccafirstname",
  /** column name */
  Kccalandline = "Kccalandline",
  /** column name */
  Kccamiddlename = "Kccamiddlename",
  /** column name */
  Kccamobile1 = "Kccamobile1",
  /** column name */
  Kccamobile2 = "Kccamobile2",
  /** column name */
  Kccanin = "Kccanin",
  /** column name */
  Kccasurname = "Kccasurname",
  /** column name */
  Kccatin = "Kccatin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Matchscore = "Matchscore",
  /** column name */
  Numproperties = "Numproperties",
  /** column name */
  Sumgrossvalue = "Sumgrossvalue",
  /** column name */
  Sumrateablevalue = "Sumrateablevalue",
  /** column name */
  Totalrentedproperties = "Totalrentedproperties",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_Unregistered" */
export type Rtcs_Db_Rmatch_Unregistered_Set_Input = {
  Compliancecategory?: Maybe<Scalars["String"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Kccadob?: Maybe<Scalars["String"]>;
  Kccaemail?: Maybe<Scalars["String"]>;
  Kccafirstname?: Maybe<Scalars["String"]>;
  Kccalandline?: Maybe<Scalars["String"]>;
  Kccamiddlename?: Maybe<Scalars["String"]>;
  Kccamobile1?: Maybe<Scalars["String"]>;
  Kccamobile2?: Maybe<Scalars["String"]>;
  Kccanin?: Maybe<Scalars["String"]>;
  Kccasurname?: Maybe<Scalars["String"]>;
  Kccatin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_Unregistered_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_Unregistered_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_Unregistered" */
export type Rtcs_Db_Rmatch_Unregistered_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_Unregistered_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_Unregistered_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_Unregistered" */
export type Rtcs_Db_Rmatch_Unregistered_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_Unregistered_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_Unregistered_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_Unregistered" */
export type Rtcs_Db_Rmatch_Unregistered_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_Unregistered_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_Unregistered_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
  Matchscore?: Maybe<Scalars["bigint"]>;
  Numproperties?: Maybe<Scalars["bigint"]>;
  Sumgrossvalue?: Maybe<Scalars["float8"]>;
  Sumrateablevalue?: Maybe<Scalars["float8"]>;
  Totalrentedproperties?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_Unregistered" */
export type Rtcs_Db_Rmatch_Unregistered_Sum_Order_By = {
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_Unregistered_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_Unregistered_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_Unregistered" */
export type Rtcs_Db_Rmatch_Unregistered_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_Unregistered_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_Unregistered_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_Unregistered" */
export type Rtcs_Db_Rmatch_Unregistered_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_Unregistered_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_Unregistered_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
  Matchscore?: Maybe<Scalars["Float"]>;
  Numproperties?: Maybe<Scalars["Float"]>;
  Sumgrossvalue?: Maybe<Scalars["Float"]>;
  Sumrateablevalue?: Maybe<Scalars["Float"]>;
  Totalrentedproperties?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_Unregistered" */
export type Rtcs_Db_Rmatch_Unregistered_Variance_Order_By = {
  Line?: Maybe<Order_By>;
  Matchscore?: Maybe<Order_By>;
  Numproperties?: Maybe<Order_By>;
  Sumgrossvalue?: Maybe<Order_By>;
  Sumrateablevalue?: Maybe<Order_By>;
  Totalrentedproperties?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.rmatch_mlhudConsolidated" */
export type Rtcs_Db_Rmatch_MlhudConsolidated = {
  __typename?: "rtcs_db_rmatch_mlhudConsolidated";
  Address?: Maybe<Scalars["String"]>;
  Baunittype?: Maybe<Scalars["String"]>;
  Blocknumber?: Maybe<Scalars["String"]>;
  CadastralKey1?: Maybe<Scalars["String"]>;
  CadastralKey2?: Maybe<Scalars["String"]>;
  Cadblocknumb?: Maybe<Scalars["String"]>;
  Cadcounty?: Maybe<Scalars["String"]>;
  Cadlatitude?: Maybe<Scalars["float8"]>;
  Cadlongitude?: Maybe<Scalars["float8"]>;
  Cadlotnumber?: Maybe<Scalars["String"]>;
  Cadroadname?: Maybe<Scalars["String"]>;
  Condoplanno?: Maybe<Scalars["String"]>;
  Condounitfactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Lafnumber?: Maybe<Scalars["String"]>;
  Landtype?: Maybe<Scalars["String"]>;
  Landusetype?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalarea?: Maybe<Scalars["float8"]>;
  Legalareaunittype?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchResult?: Maybe<Scalars["String"]>;
  Middlename?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Registrationdatetime?: Maybe<Scalars["timestamptz"]>;
  Roadname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenuretype?: Maybe<Scalars["String"]>;
  Titlelocation?: Maybe<Scalars["String"]>;
  Titlenumber?: Maybe<Scalars["float8"]>;
  UgnlisKey1?: Maybe<Scalars["String"]>;
  UgnlisKey2?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.rmatch_mlhudConsolidated" */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Aggregate = {
  __typename?: "rtcs_db_rmatch_mlhudConsolidated_aggregate";
  aggregate?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Rmatch_MlhudConsolidated>;
};

/** aggregate fields of "rtcs_db.rmatch_mlhudConsolidated" */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Aggregate_Fields = {
  __typename?: "rtcs_db_rmatch_mlhudConsolidated_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Max_Fields>;
  min?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.rmatch_mlhudConsolidated" */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Rmatch_MlhudConsolidated_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.rmatch_mlhudConsolidated" */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.rmatch_mlhudConsolidated" */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Rmatch_MlhudConsolidated_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Avg_Fields = {
  __typename?: "rtcs_db_rmatch_mlhudConsolidated_avg_fields";
  Cadlatitude?: Maybe<Scalars["Float"]>;
  Cadlongitude?: Maybe<Scalars["Float"]>;
  Condounitfactor?: Maybe<Scalars["Float"]>;
  Legalarea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Titlenumber?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.rmatch_mlhudConsolidated" */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Avg_Order_By = {
  Cadlatitude?: Maybe<Order_By>;
  Cadlongitude?: Maybe<Order_By>;
  Condounitfactor?: Maybe<Order_By>;
  Legalarea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Titlenumber?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.rmatch_mlhudConsolidated". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  Baunittype?: Maybe<String_Comparison_Exp>;
  Blocknumber?: Maybe<String_Comparison_Exp>;
  CadastralKey1?: Maybe<String_Comparison_Exp>;
  CadastralKey2?: Maybe<String_Comparison_Exp>;
  Cadblocknumb?: Maybe<String_Comparison_Exp>;
  Cadcounty?: Maybe<String_Comparison_Exp>;
  Cadlatitude?: Maybe<Float8_Comparison_Exp>;
  Cadlongitude?: Maybe<Float8_Comparison_Exp>;
  Cadlotnumber?: Maybe<String_Comparison_Exp>;
  Cadroadname?: Maybe<String_Comparison_Exp>;
  Condoplanno?: Maybe<String_Comparison_Exp>;
  Condounitfactor?: Maybe<Float8_Comparison_Exp>;
  County?: Maybe<String_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Folio?: Maybe<String_Comparison_Exp>;
  Fullname?: Maybe<String_Comparison_Exp>;
  Gender?: Maybe<String_Comparison_Exp>;
  Lafnumber?: Maybe<String_Comparison_Exp>;
  Landtype?: Maybe<String_Comparison_Exp>;
  Landusetype?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Lastname?: Maybe<String_Comparison_Exp>;
  Legalarea?: Maybe<Float8_Comparison_Exp>;
  Legalareaunittype?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MatchResult?: Maybe<String_Comparison_Exp>;
  Middlename?: Maybe<String_Comparison_Exp>;
  Municipality?: Maybe<String_Comparison_Exp>;
  Phone?: Maybe<String_Comparison_Exp>;
  Plotno?: Maybe<String_Comparison_Exp>;
  Registrationdatetime?: Maybe<Timestamptz_Comparison_Exp>;
  Roadname?: Maybe<String_Comparison_Exp>;
  Subcounty?: Maybe<String_Comparison_Exp>;
  Tenuretype?: Maybe<String_Comparison_Exp>;
  Titlelocation?: Maybe<String_Comparison_Exp>;
  Titlenumber?: Maybe<Float8_Comparison_Exp>;
  UgnlisKey1?: Maybe<String_Comparison_Exp>;
  UgnlisKey2?: Maybe<String_Comparison_Exp>;
  Volume?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.rmatch_mlhudConsolidated" */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Inc_Input = {
  Cadlatitude?: Maybe<Scalars["float8"]>;
  Cadlongitude?: Maybe<Scalars["float8"]>;
  Condounitfactor?: Maybe<Scalars["float8"]>;
  Legalarea?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Titlenumber?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.rmatch_mlhudConsolidated" */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  Baunittype?: Maybe<Scalars["String"]>;
  Blocknumber?: Maybe<Scalars["String"]>;
  CadastralKey1?: Maybe<Scalars["String"]>;
  CadastralKey2?: Maybe<Scalars["String"]>;
  Cadblocknumb?: Maybe<Scalars["String"]>;
  Cadcounty?: Maybe<Scalars["String"]>;
  Cadlatitude?: Maybe<Scalars["float8"]>;
  Cadlongitude?: Maybe<Scalars["float8"]>;
  Cadlotnumber?: Maybe<Scalars["String"]>;
  Cadroadname?: Maybe<Scalars["String"]>;
  Condoplanno?: Maybe<Scalars["String"]>;
  Condounitfactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Lafnumber?: Maybe<Scalars["String"]>;
  Landtype?: Maybe<Scalars["String"]>;
  Landusetype?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalarea?: Maybe<Scalars["float8"]>;
  Legalareaunittype?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchResult?: Maybe<Scalars["String"]>;
  Middlename?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Registrationdatetime?: Maybe<Scalars["timestamptz"]>;
  Roadname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenuretype?: Maybe<Scalars["String"]>;
  Titlelocation?: Maybe<Scalars["String"]>;
  Titlenumber?: Maybe<Scalars["float8"]>;
  UgnlisKey1?: Maybe<Scalars["String"]>;
  UgnlisKey2?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Max_Fields = {
  __typename?: "rtcs_db_rmatch_mlhudConsolidated_max_fields";
  Address?: Maybe<Scalars["String"]>;
  Baunittype?: Maybe<Scalars["String"]>;
  Blocknumber?: Maybe<Scalars["String"]>;
  CadastralKey1?: Maybe<Scalars["String"]>;
  CadastralKey2?: Maybe<Scalars["String"]>;
  Cadblocknumb?: Maybe<Scalars["String"]>;
  Cadcounty?: Maybe<Scalars["String"]>;
  Cadlatitude?: Maybe<Scalars["float8"]>;
  Cadlongitude?: Maybe<Scalars["float8"]>;
  Cadlotnumber?: Maybe<Scalars["String"]>;
  Cadroadname?: Maybe<Scalars["String"]>;
  Condoplanno?: Maybe<Scalars["String"]>;
  Condounitfactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Lafnumber?: Maybe<Scalars["String"]>;
  Landtype?: Maybe<Scalars["String"]>;
  Landusetype?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalarea?: Maybe<Scalars["float8"]>;
  Legalareaunittype?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchResult?: Maybe<Scalars["String"]>;
  Middlename?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Registrationdatetime?: Maybe<Scalars["timestamptz"]>;
  Roadname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenuretype?: Maybe<Scalars["String"]>;
  Titlelocation?: Maybe<Scalars["String"]>;
  Titlenumber?: Maybe<Scalars["float8"]>;
  UgnlisKey1?: Maybe<Scalars["String"]>;
  UgnlisKey2?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.rmatch_mlhudConsolidated" */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Max_Order_By = {
  Address?: Maybe<Order_By>;
  Baunittype?: Maybe<Order_By>;
  Blocknumber?: Maybe<Order_By>;
  CadastralKey1?: Maybe<Order_By>;
  CadastralKey2?: Maybe<Order_By>;
  Cadblocknumb?: Maybe<Order_By>;
  Cadcounty?: Maybe<Order_By>;
  Cadlatitude?: Maybe<Order_By>;
  Cadlongitude?: Maybe<Order_By>;
  Cadlotnumber?: Maybe<Order_By>;
  Cadroadname?: Maybe<Order_By>;
  Condoplanno?: Maybe<Order_By>;
  Condounitfactor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  Lafnumber?: Maybe<Order_By>;
  Landtype?: Maybe<Order_By>;
  Landusetype?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastname?: Maybe<Order_By>;
  Legalarea?: Maybe<Order_By>;
  Legalareaunittype?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchResult?: Maybe<Order_By>;
  Middlename?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Registrationdatetime?: Maybe<Order_By>;
  Roadname?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Tenuretype?: Maybe<Order_By>;
  Titlelocation?: Maybe<Order_By>;
  Titlenumber?: Maybe<Order_By>;
  UgnlisKey1?: Maybe<Order_By>;
  UgnlisKey2?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Min_Fields = {
  __typename?: "rtcs_db_rmatch_mlhudConsolidated_min_fields";
  Address?: Maybe<Scalars["String"]>;
  Baunittype?: Maybe<Scalars["String"]>;
  Blocknumber?: Maybe<Scalars["String"]>;
  CadastralKey1?: Maybe<Scalars["String"]>;
  CadastralKey2?: Maybe<Scalars["String"]>;
  Cadblocknumb?: Maybe<Scalars["String"]>;
  Cadcounty?: Maybe<Scalars["String"]>;
  Cadlatitude?: Maybe<Scalars["float8"]>;
  Cadlongitude?: Maybe<Scalars["float8"]>;
  Cadlotnumber?: Maybe<Scalars["String"]>;
  Cadroadname?: Maybe<Scalars["String"]>;
  Condoplanno?: Maybe<Scalars["String"]>;
  Condounitfactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Lafnumber?: Maybe<Scalars["String"]>;
  Landtype?: Maybe<Scalars["String"]>;
  Landusetype?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalarea?: Maybe<Scalars["float8"]>;
  Legalareaunittype?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchResult?: Maybe<Scalars["String"]>;
  Middlename?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Registrationdatetime?: Maybe<Scalars["timestamptz"]>;
  Roadname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenuretype?: Maybe<Scalars["String"]>;
  Titlelocation?: Maybe<Scalars["String"]>;
  Titlenumber?: Maybe<Scalars["float8"]>;
  UgnlisKey1?: Maybe<Scalars["String"]>;
  UgnlisKey2?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.rmatch_mlhudConsolidated" */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Min_Order_By = {
  Address?: Maybe<Order_By>;
  Baunittype?: Maybe<Order_By>;
  Blocknumber?: Maybe<Order_By>;
  CadastralKey1?: Maybe<Order_By>;
  CadastralKey2?: Maybe<Order_By>;
  Cadblocknumb?: Maybe<Order_By>;
  Cadcounty?: Maybe<Order_By>;
  Cadlatitude?: Maybe<Order_By>;
  Cadlongitude?: Maybe<Order_By>;
  Cadlotnumber?: Maybe<Order_By>;
  Cadroadname?: Maybe<Order_By>;
  Condoplanno?: Maybe<Order_By>;
  Condounitfactor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  Lafnumber?: Maybe<Order_By>;
  Landtype?: Maybe<Order_By>;
  Landusetype?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastname?: Maybe<Order_By>;
  Legalarea?: Maybe<Order_By>;
  Legalareaunittype?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchResult?: Maybe<Order_By>;
  Middlename?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Registrationdatetime?: Maybe<Order_By>;
  Roadname?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Tenuretype?: Maybe<Order_By>;
  Titlelocation?: Maybe<Order_By>;
  Titlenumber?: Maybe<Order_By>;
  UgnlisKey1?: Maybe<Order_By>;
  UgnlisKey2?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.rmatch_mlhudConsolidated" */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Mutation_Response = {
  __typename?: "rtcs_db_rmatch_mlhudConsolidated_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Rmatch_MlhudConsolidated>;
};

/** input type for inserting object relation for remote table "rtcs_db.rmatch_mlhudConsolidated" */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Rmatch_MlhudConsolidated_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.rmatch_mlhudConsolidated" */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Order_By = {
  Address?: Maybe<Order_By>;
  Baunittype?: Maybe<Order_By>;
  Blocknumber?: Maybe<Order_By>;
  CadastralKey1?: Maybe<Order_By>;
  CadastralKey2?: Maybe<Order_By>;
  Cadblocknumb?: Maybe<Order_By>;
  Cadcounty?: Maybe<Order_By>;
  Cadlatitude?: Maybe<Order_By>;
  Cadlongitude?: Maybe<Order_By>;
  Cadlotnumber?: Maybe<Order_By>;
  Cadroadname?: Maybe<Order_By>;
  Condoplanno?: Maybe<Order_By>;
  Condounitfactor?: Maybe<Order_By>;
  County?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Folio?: Maybe<Order_By>;
  Fullname?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  Lafnumber?: Maybe<Order_By>;
  Landtype?: Maybe<Order_By>;
  Landusetype?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Lastname?: Maybe<Order_By>;
  Legalarea?: Maybe<Order_By>;
  Legalareaunittype?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MatchResult?: Maybe<Order_By>;
  Middlename?: Maybe<Order_By>;
  Municipality?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  Plotno?: Maybe<Order_By>;
  Registrationdatetime?: Maybe<Order_By>;
  Roadname?: Maybe<Order_By>;
  Subcounty?: Maybe<Order_By>;
  Tenuretype?: Maybe<Order_By>;
  Titlelocation?: Maybe<Order_By>;
  Titlenumber?: Maybe<Order_By>;
  UgnlisKey1?: Maybe<Order_By>;
  UgnlisKey2?: Maybe<Order_By>;
  Volume?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.rmatch_mlhudConsolidated" */
export enum Rtcs_Db_Rmatch_MlhudConsolidated_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  Baunittype = "Baunittype",
  /** column name */
  Blocknumber = "Blocknumber",
  /** column name */
  CadastralKey1 = "CadastralKey1",
  /** column name */
  CadastralKey2 = "CadastralKey2",
  /** column name */
  Cadblocknumb = "Cadblocknumb",
  /** column name */
  Cadcounty = "Cadcounty",
  /** column name */
  Cadlatitude = "Cadlatitude",
  /** column name */
  Cadlongitude = "Cadlongitude",
  /** column name */
  Cadlotnumber = "Cadlotnumber",
  /** column name */
  Cadroadname = "Cadroadname",
  /** column name */
  Condoplanno = "Condoplanno",
  /** column name */
  Condounitfactor = "Condounitfactor",
  /** column name */
  County = "County",
  /** column name */
  Description = "Description",
  /** column name */
  District = "District",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Folio = "Folio",
  /** column name */
  Fullname = "Fullname",
  /** column name */
  Gender = "Gender",
  /** column name */
  Lafnumber = "Lafnumber",
  /** column name */
  Landtype = "Landtype",
  /** column name */
  Landusetype = "Landusetype",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Lastname = "Lastname",
  /** column name */
  Legalarea = "Legalarea",
  /** column name */
  Legalareaunittype = "Legalareaunittype",
  /** column name */
  Line = "Line",
  /** column name */
  MatchResult = "MatchResult",
  /** column name */
  Middlename = "Middlename",
  /** column name */
  Municipality = "Municipality",
  /** column name */
  Phone = "Phone",
  /** column name */
  Plotno = "Plotno",
  /** column name */
  Registrationdatetime = "Registrationdatetime",
  /** column name */
  Roadname = "Roadname",
  /** column name */
  Subcounty = "Subcounty",
  /** column name */
  Tenuretype = "Tenuretype",
  /** column name */
  Titlelocation = "Titlelocation",
  /** column name */
  Titlenumber = "Titlenumber",
  /** column name */
  UgnlisKey1 = "UgnlisKey1",
  /** column name */
  UgnlisKey2 = "UgnlisKey2",
  /** column name */
  Volume = "Volume",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.rmatch_mlhudConsolidated" */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  Baunittype?: Maybe<Scalars["String"]>;
  Blocknumber?: Maybe<Scalars["String"]>;
  CadastralKey1?: Maybe<Scalars["String"]>;
  CadastralKey2?: Maybe<Scalars["String"]>;
  Cadblocknumb?: Maybe<Scalars["String"]>;
  Cadcounty?: Maybe<Scalars["String"]>;
  Cadlatitude?: Maybe<Scalars["float8"]>;
  Cadlongitude?: Maybe<Scalars["float8"]>;
  Cadlotnumber?: Maybe<Scalars["String"]>;
  Cadroadname?: Maybe<Scalars["String"]>;
  Condoplanno?: Maybe<Scalars["String"]>;
  Condounitfactor?: Maybe<Scalars["float8"]>;
  County?: Maybe<Scalars["String"]>;
  Description?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Folio?: Maybe<Scalars["String"]>;
  Fullname?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  Lafnumber?: Maybe<Scalars["String"]>;
  Landtype?: Maybe<Scalars["String"]>;
  Landusetype?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Lastname?: Maybe<Scalars["String"]>;
  Legalarea?: Maybe<Scalars["float8"]>;
  Legalareaunittype?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MatchResult?: Maybe<Scalars["String"]>;
  Middlename?: Maybe<Scalars["String"]>;
  Municipality?: Maybe<Scalars["String"]>;
  Phone?: Maybe<Scalars["String"]>;
  Plotno?: Maybe<Scalars["String"]>;
  Registrationdatetime?: Maybe<Scalars["timestamptz"]>;
  Roadname?: Maybe<Scalars["String"]>;
  Subcounty?: Maybe<Scalars["String"]>;
  Tenuretype?: Maybe<Scalars["String"]>;
  Titlelocation?: Maybe<Scalars["String"]>;
  Titlenumber?: Maybe<Scalars["float8"]>;
  UgnlisKey1?: Maybe<Scalars["String"]>;
  UgnlisKey2?: Maybe<Scalars["String"]>;
  Volume?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Stddev_Fields = {
  __typename?: "rtcs_db_rmatch_mlhudConsolidated_stddev_fields";
  Cadlatitude?: Maybe<Scalars["Float"]>;
  Cadlongitude?: Maybe<Scalars["Float"]>;
  Condounitfactor?: Maybe<Scalars["Float"]>;
  Legalarea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Titlenumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.rmatch_mlhudConsolidated" */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Stddev_Order_By = {
  Cadlatitude?: Maybe<Order_By>;
  Cadlongitude?: Maybe<Order_By>;
  Condounitfactor?: Maybe<Order_By>;
  Legalarea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Titlenumber?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_mlhudConsolidated_stddev_pop_fields";
  Cadlatitude?: Maybe<Scalars["Float"]>;
  Cadlongitude?: Maybe<Scalars["Float"]>;
  Condounitfactor?: Maybe<Scalars["Float"]>;
  Legalarea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Titlenumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.rmatch_mlhudConsolidated" */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Stddev_Pop_Order_By = {
  Cadlatitude?: Maybe<Order_By>;
  Cadlongitude?: Maybe<Order_By>;
  Condounitfactor?: Maybe<Order_By>;
  Legalarea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Titlenumber?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_mlhudConsolidated_stddev_samp_fields";
  Cadlatitude?: Maybe<Scalars["Float"]>;
  Cadlongitude?: Maybe<Scalars["Float"]>;
  Condounitfactor?: Maybe<Scalars["Float"]>;
  Legalarea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Titlenumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.rmatch_mlhudConsolidated" */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Stddev_Samp_Order_By = {
  Cadlatitude?: Maybe<Order_By>;
  Cadlongitude?: Maybe<Order_By>;
  Condounitfactor?: Maybe<Order_By>;
  Legalarea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Titlenumber?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Sum_Fields = {
  __typename?: "rtcs_db_rmatch_mlhudConsolidated_sum_fields";
  Cadlatitude?: Maybe<Scalars["float8"]>;
  Cadlongitude?: Maybe<Scalars["float8"]>;
  Condounitfactor?: Maybe<Scalars["float8"]>;
  Legalarea?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Titlenumber?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.rmatch_mlhudConsolidated" */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Sum_Order_By = {
  Cadlatitude?: Maybe<Order_By>;
  Cadlongitude?: Maybe<Order_By>;
  Condounitfactor?: Maybe<Order_By>;
  Legalarea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Titlenumber?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Var_Pop_Fields = {
  __typename?: "rtcs_db_rmatch_mlhudConsolidated_var_pop_fields";
  Cadlatitude?: Maybe<Scalars["Float"]>;
  Cadlongitude?: Maybe<Scalars["Float"]>;
  Condounitfactor?: Maybe<Scalars["Float"]>;
  Legalarea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Titlenumber?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.rmatch_mlhudConsolidated" */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Var_Pop_Order_By = {
  Cadlatitude?: Maybe<Order_By>;
  Cadlongitude?: Maybe<Order_By>;
  Condounitfactor?: Maybe<Order_By>;
  Legalarea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Titlenumber?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Var_Samp_Fields = {
  __typename?: "rtcs_db_rmatch_mlhudConsolidated_var_samp_fields";
  Cadlatitude?: Maybe<Scalars["Float"]>;
  Cadlongitude?: Maybe<Scalars["Float"]>;
  Condounitfactor?: Maybe<Scalars["Float"]>;
  Legalarea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Titlenumber?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.rmatch_mlhudConsolidated" */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Var_Samp_Order_By = {
  Cadlatitude?: Maybe<Order_By>;
  Cadlongitude?: Maybe<Order_By>;
  Condounitfactor?: Maybe<Order_By>;
  Legalarea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Titlenumber?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Variance_Fields = {
  __typename?: "rtcs_db_rmatch_mlhudConsolidated_variance_fields";
  Cadlatitude?: Maybe<Scalars["Float"]>;
  Cadlongitude?: Maybe<Scalars["Float"]>;
  Condounitfactor?: Maybe<Scalars["Float"]>;
  Legalarea?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Titlenumber?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.rmatch_mlhudConsolidated" */
export type Rtcs_Db_Rmatch_MlhudConsolidated_Variance_Order_By = {
  Cadlatitude?: Maybe<Order_By>;
  Cadlongitude?: Maybe<Order_By>;
  Condounitfactor?: Maybe<Order_By>;
  Legalarea?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Titlenumber?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality = {
  __typename?: "rtcs_db_streetmap_OsmMukonoMunicipality";
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Aggregate = {
  __typename?: "rtcs_db_streetmap_OsmMukonoMunicipality_aggregate";
  aggregate?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Streetmap_OsmMukonoMunicipality>;
};

/** aggregate fields of "rtcs_db.streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Aggregate_Fields = {
  __typename?: "rtcs_db_streetmap_OsmMukonoMunicipality_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Max_Fields>;
  min?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Avg_Fields = {
  __typename?: "rtcs_db_streetmap_OsmMukonoMunicipality_avg_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Avg_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.streetmap_OsmMukonoMunicipality". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Bool_Exp = {
  Amenity?: Maybe<String_Comparison_Exp>;
  Building?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Housenumber?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  OpeningHours?: Maybe<String_Comparison_Exp>;
  Shop?: Maybe<String_Comparison_Exp>;
  Street?: Maybe<String_Comparison_Exp>;
  Tourism?: Maybe<String_Comparison_Exp>;
  Uid?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Inc_Input = {
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Uid?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Insert_Input = {
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Max_Fields = {
  __typename?: "rtcs_db_streetmap_OsmMukonoMunicipality_max_fields";
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Max_Order_By = {
  Amenity?: Maybe<Order_By>;
  Building?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Min_Fields = {
  __typename?: "rtcs_db_streetmap_OsmMukonoMunicipality_min_fields";
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Min_Order_By = {
  Amenity?: Maybe<Order_By>;
  Building?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Mutation_Response = {
  __typename?: "rtcs_db_streetmap_OsmMukonoMunicipality_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Streetmap_OsmMukonoMunicipality>;
};

/** input type for inserting object relation for remote table "rtcs_db.streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Streetmap_OsmMukonoMunicipality_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Order_By = {
  Amenity?: Maybe<Order_By>;
  Building?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.streetmap_OsmMukonoMunicipality" */
export enum Rtcs_Db_Streetmap_OsmMukonoMunicipality_Select_Column {
  /** column name */
  Amenity = "Amenity",
  /** column name */
  Building = "Building",
  /** column name */
  File = "File",
  /** column name */
  Housenumber = "Housenumber",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  Name = "Name",
  /** column name */
  OpeningHours = "OpeningHours",
  /** column name */
  Shop = "Shop",
  /** column name */
  Street = "Street",
  /** column name */
  Tourism = "Tourism",
  /** column name */
  Uid = "Uid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Set_Input = {
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Stddev_Fields = {
  __typename?: "rtcs_db_streetmap_OsmMukonoMunicipality_stddev_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Stddev_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_streetmap_OsmMukonoMunicipality_stddev_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Stddev_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_streetmap_OsmMukonoMunicipality_stddev_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Stddev_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Sum_Fields = {
  __typename?: "rtcs_db_streetmap_OsmMukonoMunicipality_sum_fields";
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Uid?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Sum_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Var_Pop_Fields = {
  __typename?: "rtcs_db_streetmap_OsmMukonoMunicipality_var_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Var_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Var_Samp_Fields = {
  __typename?: "rtcs_db_streetmap_OsmMukonoMunicipality_var_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Var_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Variance_Fields = {
  __typename?: "rtcs_db_streetmap_OsmMukonoMunicipality_variance_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.streetmap_OsmMukonoMunicipality" */
export type Rtcs_Db_Streetmap_OsmMukonoMunicipality_Variance_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma = {
  __typename?: "rtcs_db_streetmap_OsmMukonoNakifuma";
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Aggregate = {
  __typename?: "rtcs_db_streetmap_OsmMukonoNakifuma_aggregate";
  aggregate?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Streetmap_OsmMukonoNakifuma>;
};

/** aggregate fields of "rtcs_db.streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Aggregate_Fields = {
  __typename?: "rtcs_db_streetmap_OsmMukonoNakifuma_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Max_Fields>;
  min?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Avg_Fields = {
  __typename?: "rtcs_db_streetmap_OsmMukonoNakifuma_avg_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Avg_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.streetmap_OsmMukonoNakifuma". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Bool_Exp = {
  Amenity?: Maybe<String_Comparison_Exp>;
  Building?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Housenumber?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  OpeningHours?: Maybe<String_Comparison_Exp>;
  Shop?: Maybe<String_Comparison_Exp>;
  Street?: Maybe<String_Comparison_Exp>;
  Tourism?: Maybe<String_Comparison_Exp>;
  Uid?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Inc_Input = {
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Uid?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Insert_Input = {
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Max_Fields = {
  __typename?: "rtcs_db_streetmap_OsmMukonoNakifuma_max_fields";
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Max_Order_By = {
  Amenity?: Maybe<Order_By>;
  Building?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Min_Fields = {
  __typename?: "rtcs_db_streetmap_OsmMukonoNakifuma_min_fields";
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Min_Order_By = {
  Amenity?: Maybe<Order_By>;
  Building?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Mutation_Response = {
  __typename?: "rtcs_db_streetmap_OsmMukonoNakifuma_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Streetmap_OsmMukonoNakifuma>;
};

/** input type for inserting object relation for remote table "rtcs_db.streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Streetmap_OsmMukonoNakifuma_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Order_By = {
  Amenity?: Maybe<Order_By>;
  Building?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.streetmap_OsmMukonoNakifuma" */
export enum Rtcs_Db_Streetmap_OsmMukonoNakifuma_Select_Column {
  /** column name */
  Amenity = "Amenity",
  /** column name */
  Building = "Building",
  /** column name */
  File = "File",
  /** column name */
  Housenumber = "Housenumber",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  Name = "Name",
  /** column name */
  OpeningHours = "OpeningHours",
  /** column name */
  Shop = "Shop",
  /** column name */
  Street = "Street",
  /** column name */
  Tourism = "Tourism",
  /** column name */
  Uid = "Uid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Set_Input = {
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Stddev_Fields = {
  __typename?: "rtcs_db_streetmap_OsmMukonoNakifuma_stddev_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Stddev_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_streetmap_OsmMukonoNakifuma_stddev_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Stddev_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_streetmap_OsmMukonoNakifuma_stddev_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Stddev_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Sum_Fields = {
  __typename?: "rtcs_db_streetmap_OsmMukonoNakifuma_sum_fields";
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Uid?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Sum_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Var_Pop_Fields = {
  __typename?: "rtcs_db_streetmap_OsmMukonoNakifuma_var_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Var_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Var_Samp_Fields = {
  __typename?: "rtcs_db_streetmap_OsmMukonoNakifuma_var_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Var_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Variance_Fields = {
  __typename?: "rtcs_db_streetmap_OsmMukonoNakifuma_variance_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.streetmap_OsmMukonoNakifuma" */
export type Rtcs_Db_Streetmap_OsmMukonoNakifuma_Variance_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe = {
  __typename?: "rtcs_db_streetmap_OsmWakisoEntebbe";
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Rooms?: Maybe<Scalars["float8"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Aggregate = {
  __typename?: "rtcs_db_streetmap_OsmWakisoEntebbe_aggregate";
  aggregate?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Streetmap_OsmWakisoEntebbe>;
};

/** aggregate fields of "rtcs_db.streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Aggregate_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoEntebbe_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Max_Fields>;
  min?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Avg_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoEntebbe_avg_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Rooms?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Avg_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Rooms?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.streetmap_OsmWakisoEntebbe". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Bool_Exp = {
  Amenity?: Maybe<String_Comparison_Exp>;
  Building?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Housenumber?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  OpeningHours?: Maybe<String_Comparison_Exp>;
  Rooms?: Maybe<Float8_Comparison_Exp>;
  Shop?: Maybe<String_Comparison_Exp>;
  Street?: Maybe<String_Comparison_Exp>;
  Tourism?: Maybe<String_Comparison_Exp>;
  Uid?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Inc_Input = {
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Rooms?: Maybe<Scalars["float8"]>;
  Uid?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Insert_Input = {
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Rooms?: Maybe<Scalars["float8"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Max_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoEntebbe_max_fields";
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Rooms?: Maybe<Scalars["float8"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Max_Order_By = {
  Amenity?: Maybe<Order_By>;
  Building?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Rooms?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Min_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoEntebbe_min_fields";
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Rooms?: Maybe<Scalars["float8"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Min_Order_By = {
  Amenity?: Maybe<Order_By>;
  Building?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Rooms?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Mutation_Response = {
  __typename?: "rtcs_db_streetmap_OsmWakisoEntebbe_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Streetmap_OsmWakisoEntebbe>;
};

/** input type for inserting object relation for remote table "rtcs_db.streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Streetmap_OsmWakisoEntebbe_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Order_By = {
  Amenity?: Maybe<Order_By>;
  Building?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Rooms?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.streetmap_OsmWakisoEntebbe" */
export enum Rtcs_Db_Streetmap_OsmWakisoEntebbe_Select_Column {
  /** column name */
  Amenity = "Amenity",
  /** column name */
  Building = "Building",
  /** column name */
  File = "File",
  /** column name */
  Housenumber = "Housenumber",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  Name = "Name",
  /** column name */
  OpeningHours = "OpeningHours",
  /** column name */
  Rooms = "Rooms",
  /** column name */
  Shop = "Shop",
  /** column name */
  Street = "Street",
  /** column name */
  Tourism = "Tourism",
  /** column name */
  Uid = "Uid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Set_Input = {
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Rooms?: Maybe<Scalars["float8"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Stddev_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoEntebbe_stddev_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Rooms?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Stddev_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Rooms?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoEntebbe_stddev_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Rooms?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Stddev_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Rooms?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoEntebbe_stddev_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Rooms?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Stddev_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Rooms?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Sum_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoEntebbe_sum_fields";
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Rooms?: Maybe<Scalars["float8"]>;
  Uid?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Sum_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Rooms?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Var_Pop_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoEntebbe_var_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Rooms?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Var_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Rooms?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Var_Samp_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoEntebbe_var_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Rooms?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Var_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Rooms?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Variance_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoEntebbe_variance_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Rooms?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.streetmap_OsmWakisoEntebbe" */
export type Rtcs_Db_Streetmap_OsmWakisoEntebbe_Variance_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Rooms?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.streetmap_OsmWakisoKira" */
export type Rtcs_Db_Streetmap_OsmWakisoKira = {
  __typename?: "rtcs_db_streetmap_OsmWakisoKira";
  Amenity?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.streetmap_OsmWakisoKira" */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Aggregate = {
  __typename?: "rtcs_db_streetmap_OsmWakisoKira_aggregate";
  aggregate?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Streetmap_OsmWakisoKira>;
};

/** aggregate fields of "rtcs_db.streetmap_OsmWakisoKira" */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Aggregate_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoKira_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Max_Fields>;
  min?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.streetmap_OsmWakisoKira" */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Streetmap_OsmWakisoKira_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.streetmap_OsmWakisoKira" */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.streetmap_OsmWakisoKira" */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Streetmap_OsmWakisoKira_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Avg_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoKira_avg_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.streetmap_OsmWakisoKira" */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Avg_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.streetmap_OsmWakisoKira". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Bool_Exp = {
  Amenity?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Housenumber?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  OpeningHours?: Maybe<String_Comparison_Exp>;
  Shop?: Maybe<String_Comparison_Exp>;
  Street?: Maybe<String_Comparison_Exp>;
  Tourism?: Maybe<String_Comparison_Exp>;
  Uid?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.streetmap_OsmWakisoKira" */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Inc_Input = {
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Uid?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.streetmap_OsmWakisoKira" */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Insert_Input = {
  Amenity?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Max_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoKira_max_fields";
  Amenity?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.streetmap_OsmWakisoKira" */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Max_Order_By = {
  Amenity?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Min_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoKira_min_fields";
  Amenity?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.streetmap_OsmWakisoKira" */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Min_Order_By = {
  Amenity?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.streetmap_OsmWakisoKira" */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Mutation_Response = {
  __typename?: "rtcs_db_streetmap_OsmWakisoKira_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Streetmap_OsmWakisoKira>;
};

/** input type for inserting object relation for remote table "rtcs_db.streetmap_OsmWakisoKira" */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Streetmap_OsmWakisoKira_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.streetmap_OsmWakisoKira" */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Order_By = {
  Amenity?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.streetmap_OsmWakisoKira" */
export enum Rtcs_Db_Streetmap_OsmWakisoKira_Select_Column {
  /** column name */
  Amenity = "Amenity",
  /** column name */
  File = "File",
  /** column name */
  Housenumber = "Housenumber",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  Name = "Name",
  /** column name */
  OpeningHours = "OpeningHours",
  /** column name */
  Shop = "Shop",
  /** column name */
  Street = "Street",
  /** column name */
  Tourism = "Tourism",
  /** column name */
  Uid = "Uid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.streetmap_OsmWakisoKira" */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Set_Input = {
  Amenity?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Stddev_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoKira_stddev_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.streetmap_OsmWakisoKira" */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Stddev_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoKira_stddev_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.streetmap_OsmWakisoKira" */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Stddev_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoKira_stddev_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.streetmap_OsmWakisoKira" */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Stddev_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Sum_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoKira_sum_fields";
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Uid?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.streetmap_OsmWakisoKira" */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Sum_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Var_Pop_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoKira_var_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.streetmap_OsmWakisoKira" */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Var_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Var_Samp_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoKira_var_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.streetmap_OsmWakisoKira" */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Var_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Variance_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoKira_variance_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.streetmap_OsmWakisoKira" */
export type Rtcs_Db_Streetmap_OsmWakisoKira_Variance_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo = {
  __typename?: "rtcs_db_streetmap_OsmWakisoMakindyeSsabagabo";
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Aggregate = {
  __typename?: "rtcs_db_streetmap_OsmWakisoMakindyeSsabagabo_aggregate";
  aggregate?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo>;
};

/** aggregate fields of "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Aggregate_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoMakindyeSsabagabo_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Max_Fields>;
  min?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Avg_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoMakindyeSsabagabo_avg_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Avg_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Bool_Exp = {
  Amenity?: Maybe<String_Comparison_Exp>;
  Building?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Housenumber?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  OpeningHours?: Maybe<String_Comparison_Exp>;
  Shop?: Maybe<String_Comparison_Exp>;
  Street?: Maybe<String_Comparison_Exp>;
  Tourism?: Maybe<String_Comparison_Exp>;
  Uid?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Inc_Input = {
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Uid?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Insert_Input = {
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Max_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoMakindyeSsabagabo_max_fields";
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Max_Order_By = {
  Amenity?: Maybe<Order_By>;
  Building?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Min_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoMakindyeSsabagabo_min_fields";
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Min_Order_By = {
  Amenity?: Maybe<Order_By>;
  Building?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Mutation_Response = {
  __typename?: "rtcs_db_streetmap_OsmWakisoMakindyeSsabagabo_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo>;
};

/** input type for inserting object relation for remote table "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Order_By = {
  Amenity?: Maybe<Order_By>;
  Building?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Housenumber?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
export enum Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Select_Column {
  /** column name */
  Amenity = "Amenity",
  /** column name */
  Building = "Building",
  /** column name */
  File = "File",
  /** column name */
  Housenumber = "Housenumber",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  Name = "Name",
  /** column name */
  OpeningHours = "OpeningHours",
  /** column name */
  Shop = "Shop",
  /** column name */
  Street = "Street",
  /** column name */
  Tourism = "Tourism",
  /** column name */
  Uid = "Uid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Set_Input = {
  Amenity?: Maybe<Scalars["String"]>;
  Building?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Housenumber?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Stddev_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoMakindyeSsabagabo_stddev_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Stddev_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoMakindyeSsabagabo_stddev_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Stddev_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoMakindyeSsabagabo_stddev_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Stddev_Samp_Order_By =
  {
    Latitude?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    Longitude?: Maybe<Order_By>;
    Uid?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Sum_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoMakindyeSsabagabo_sum_fields";
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Uid?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Sum_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Var_Pop_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoMakindyeSsabagabo_var_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Var_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Var_Samp_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoMakindyeSsabagabo_var_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Var_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Variance_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoMakindyeSsabagabo_variance_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
export type Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Variance_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Streetmap_OsmWakisoNansana = {
  __typename?: "rtcs_db_streetmap_OsmWakisoNansana";
  Amenity?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Aggregate = {
  __typename?: "rtcs_db_streetmap_OsmWakisoNansana_aggregate";
  aggregate?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Streetmap_OsmWakisoNansana>;
};

/** aggregate fields of "rtcs_db.streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Aggregate_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoNansana_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Max_Fields>;
  min?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Streetmap_OsmWakisoNansana_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Streetmap_OsmWakisoNansana_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Avg_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoNansana_avg_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Avg_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.streetmap_OsmWakisoNansana". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Bool_Exp = {
  Amenity?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Latitude?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Float8_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  OpeningHours?: Maybe<String_Comparison_Exp>;
  Shop?: Maybe<String_Comparison_Exp>;
  Street?: Maybe<String_Comparison_Exp>;
  Tourism?: Maybe<String_Comparison_Exp>;
  Uid?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Inc_Input = {
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Uid?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Insert_Input = {
  Amenity?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Max_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoNansana_max_fields";
  Amenity?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Max_Order_By = {
  Amenity?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Min_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoNansana_min_fields";
  Amenity?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Min_Order_By = {
  Amenity?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Mutation_Response = {
  __typename?: "rtcs_db_streetmap_OsmWakisoNansana_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Streetmap_OsmWakisoNansana>;
};

/** input type for inserting object relation for remote table "rtcs_db.streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Streetmap_OsmWakisoNansana_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Order_By = {
  Amenity?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  OpeningHours?: Maybe<Order_By>;
  Shop?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Tourism?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.streetmap_OsmWakisoNansana" */
export enum Rtcs_Db_Streetmap_OsmWakisoNansana_Select_Column {
  /** column name */
  Amenity = "Amenity",
  /** column name */
  File = "File",
  /** column name */
  Id = "Id",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  Name = "Name",
  /** column name */
  OpeningHours = "OpeningHours",
  /** column name */
  Shop = "Shop",
  /** column name */
  Street = "Street",
  /** column name */
  Tourism = "Tourism",
  /** column name */
  Uid = "Uid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Set_Input = {
  Amenity?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Name?: Maybe<Scalars["String"]>;
  OpeningHours?: Maybe<Scalars["String"]>;
  Shop?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Tourism?: Maybe<Scalars["String"]>;
  Uid?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Stddev_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoNansana_stddev_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Stddev_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoNansana_stddev_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Stddev_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoNansana_stddev_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Stddev_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Sum_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoNansana_sum_fields";
  Latitude?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["float8"]>;
  Uid?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Sum_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Var_Pop_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoNansana_var_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Var_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Var_Samp_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoNansana_var_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Var_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Variance_Fields = {
  __typename?: "rtcs_db_streetmap_OsmWakisoNansana_variance_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  Uid?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.streetmap_OsmWakisoNansana" */
export type Rtcs_Db_Streetmap_OsmWakisoNansana_Variance_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Uid?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.ucc_UCC" */
export type Rtcs_Db_Ucc_Ucc = {
  __typename?: "rtcs_db_ucc_UCC";
  Carrier?: Maybe<Scalars["String"]>;
  Count?: Maybe<Scalars["bigint"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Idnumber?: Maybe<Scalars["String"]>;
  Idtype?: Maybe<Scalars["String"]>;
  Kccamobile?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ucc_UCC" */
export type Rtcs_Db_Ucc_Ucc_Aggregate = {
  __typename?: "rtcs_db_ucc_UCC_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ucc_Ucc_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ucc_Ucc>;
};

/** aggregate fields of "rtcs_db.ucc_UCC" */
export type Rtcs_Db_Ucc_Ucc_Aggregate_Fields = {
  __typename?: "rtcs_db_ucc_UCC_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ucc_Ucc_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ucc_Ucc_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ucc_Ucc_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ucc_Ucc_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ucc_Ucc_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ucc_Ucc_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ucc_Ucc_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ucc_Ucc_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ucc_Ucc_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ucc_Ucc_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ucc_UCC" */
export type Rtcs_Db_Ucc_Ucc_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ucc_Ucc_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.ucc_UCC" */
export type Rtcs_Db_Ucc_Ucc_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ucc_Ucc_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ucc_Ucc_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ucc_Ucc_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ucc_Ucc_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ucc_Ucc_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ucc_Ucc_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ucc_Ucc_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ucc_Ucc_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ucc_Ucc_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ucc_Ucc_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ucc_UCC" */
export type Rtcs_Db_Ucc_Ucc_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ucc_Ucc_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ucc_Ucc_Avg_Fields = {
  __typename?: "rtcs_db_ucc_UCC_avg_fields";
  Count?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ucc_UCC" */
export type Rtcs_Db_Ucc_Ucc_Avg_Order_By = {
  Count?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ucc_UCC". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ucc_Ucc_Bool_Exp = {
  Carrier?: Maybe<String_Comparison_Exp>;
  Count?: Maybe<Bigint_Comparison_Exp>;
  Customerid?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Firstname?: Maybe<String_Comparison_Exp>;
  Idnumber?: Maybe<String_Comparison_Exp>;
  Idtype?: Maybe<String_Comparison_Exp>;
  Kccamobile?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  Uuid?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ucc_Ucc_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ucc_Ucc_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ucc_Ucc_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.ucc_UCC" */
export type Rtcs_Db_Ucc_Ucc_Inc_Input = {
  Count?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.ucc_UCC" */
export type Rtcs_Db_Ucc_Ucc_Insert_Input = {
  Carrier?: Maybe<Scalars["String"]>;
  Count?: Maybe<Scalars["bigint"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Idnumber?: Maybe<Scalars["String"]>;
  Idtype?: Maybe<Scalars["String"]>;
  Kccamobile?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ucc_Ucc_Max_Fields = {
  __typename?: "rtcs_db_ucc_UCC_max_fields";
  Carrier?: Maybe<Scalars["String"]>;
  Count?: Maybe<Scalars["bigint"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Idnumber?: Maybe<Scalars["String"]>;
  Idtype?: Maybe<Scalars["String"]>;
  Kccamobile?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ucc_UCC" */
export type Rtcs_Db_Ucc_Ucc_Max_Order_By = {
  Carrier?: Maybe<Order_By>;
  Count?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Idnumber?: Maybe<Order_By>;
  Idtype?: Maybe<Order_By>;
  Kccamobile?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ucc_Ucc_Min_Fields = {
  __typename?: "rtcs_db_ucc_UCC_min_fields";
  Carrier?: Maybe<Scalars["String"]>;
  Count?: Maybe<Scalars["bigint"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Idnumber?: Maybe<Scalars["String"]>;
  Idtype?: Maybe<Scalars["String"]>;
  Kccamobile?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ucc_UCC" */
export type Rtcs_Db_Ucc_Ucc_Min_Order_By = {
  Carrier?: Maybe<Order_By>;
  Count?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Idnumber?: Maybe<Order_By>;
  Idtype?: Maybe<Order_By>;
  Kccamobile?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ucc_UCC" */
export type Rtcs_Db_Ucc_Ucc_Mutation_Response = {
  __typename?: "rtcs_db_ucc_UCC_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ucc_Ucc>;
};

/** input type for inserting object relation for remote table "rtcs_db.ucc_UCC" */
export type Rtcs_Db_Ucc_Ucc_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ucc_Ucc_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.ucc_UCC" */
export type Rtcs_Db_Ucc_Ucc_Order_By = {
  Carrier?: Maybe<Order_By>;
  Count?: Maybe<Order_By>;
  Customerid?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Firstname?: Maybe<Order_By>;
  Idnumber?: Maybe<Order_By>;
  Idtype?: Maybe<Order_By>;
  Kccamobile?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ucc_UCC" */
export enum Rtcs_Db_Ucc_Ucc_Select_Column {
  /** column name */
  Carrier = "Carrier",
  /** column name */
  Count = "Count",
  /** column name */
  Customerid = "Customerid",
  /** column name */
  File = "File",
  /** column name */
  Firstname = "Firstname",
  /** column name */
  Idnumber = "Idnumber",
  /** column name */
  Idtype = "Idtype",
  /** column name */
  Kccamobile = "Kccamobile",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Surname = "Surname",
  /** column name */
  Uuid = "Uuid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ucc_UCC" */
export type Rtcs_Db_Ucc_Ucc_Set_Input = {
  Carrier?: Maybe<Scalars["String"]>;
  Count?: Maybe<Scalars["bigint"]>;
  Customerid?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Firstname?: Maybe<Scalars["String"]>;
  Idnumber?: Maybe<Scalars["String"]>;
  Idtype?: Maybe<Scalars["String"]>;
  Kccamobile?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Surname?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ucc_Ucc_Stddev_Fields = {
  __typename?: "rtcs_db_ucc_UCC_stddev_fields";
  Count?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ucc_UCC" */
export type Rtcs_Db_Ucc_Ucc_Stddev_Order_By = {
  Count?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ucc_Ucc_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ucc_UCC_stddev_pop_fields";
  Count?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ucc_UCC" */
export type Rtcs_Db_Ucc_Ucc_Stddev_Pop_Order_By = {
  Count?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ucc_Ucc_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ucc_UCC_stddev_samp_fields";
  Count?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ucc_UCC" */
export type Rtcs_Db_Ucc_Ucc_Stddev_Samp_Order_By = {
  Count?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ucc_Ucc_Sum_Fields = {
  __typename?: "rtcs_db_ucc_UCC_sum_fields";
  Count?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.ucc_UCC" */
export type Rtcs_Db_Ucc_Ucc_Sum_Order_By = {
  Count?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ucc_Ucc_Var_Pop_Fields = {
  __typename?: "rtcs_db_ucc_UCC_var_pop_fields";
  Count?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ucc_UCC" */
export type Rtcs_Db_Ucc_Ucc_Var_Pop_Order_By = {
  Count?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ucc_Ucc_Var_Samp_Fields = {
  __typename?: "rtcs_db_ucc_UCC_var_samp_fields";
  Count?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ucc_UCC" */
export type Rtcs_Db_Ucc_Ucc_Var_Samp_Order_By = {
  Count?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ucc_Ucc_Variance_Fields = {
  __typename?: "rtcs_db_ucc_UCC_variance_fields";
  Count?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ucc_UCC" */
export type Rtcs_Db_Ucc_Ucc_Variance_Order_By = {
  Count?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.umeme_SubscriberAndProperty" */
export type Rtcs_Db_Umeme_SubscriberAndProperty = {
  __typename?: "rtcs_db_umeme_SubscriberAndProperty";
  CustomerName?: Maybe<Scalars["String"]>;
  Customerno?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["bigint"]>;
  MeterNo?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  SupplyPointNo?: Maybe<Scalars["String"]>;
  TariffDesc?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Town?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  XCoord?: Maybe<Scalars["float8"]>;
  YCoord?: Maybe<Scalars["float8"]>;
};

/** aggregated selection of "rtcs_db.umeme_SubscriberAndProperty" */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Aggregate = {
  __typename?: "rtcs_db_umeme_SubscriberAndProperty_aggregate";
  aggregate?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Umeme_SubscriberAndProperty>;
};

/** aggregate fields of "rtcs_db.umeme_SubscriberAndProperty" */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Aggregate_Fields = {
  __typename?: "rtcs_db_umeme_SubscriberAndProperty_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Max_Fields>;
  min?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.umeme_SubscriberAndProperty" */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Umeme_SubscriberAndProperty_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.umeme_SubscriberAndProperty" */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.umeme_SubscriberAndProperty" */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Umeme_SubscriberAndProperty_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Avg_Fields = {
  __typename?: "rtcs_db_umeme_SubscriberAndProperty_avg_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  XCoord?: Maybe<Scalars["Float"]>;
  YCoord?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.umeme_SubscriberAndProperty" */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Avg_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  XCoord?: Maybe<Order_By>;
  YCoord?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.umeme_SubscriberAndProperty". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Bool_Exp = {
  CustomerName?: Maybe<String_Comparison_Exp>;
  Customerno?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<String_Comparison_Exp>;
  Latitude?: Maybe<Bigint_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Longitude?: Maybe<Bigint_Comparison_Exp>;
  MeterNo?: Maybe<String_Comparison_Exp>;
  Street?: Maybe<String_Comparison_Exp>;
  SupplyPointNo?: Maybe<String_Comparison_Exp>;
  TariffDesc?: Maybe<String_Comparison_Exp>;
  Telephone?: Maybe<String_Comparison_Exp>;
  Town?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  XCoord?: Maybe<Float8_Comparison_Exp>;
  YCoord?: Maybe<Float8_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.umeme_SubscriberAndProperty" */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Inc_Input = {
  Latitude?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["bigint"]>;
  XCoord?: Maybe<Scalars["float8"]>;
  YCoord?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.umeme_SubscriberAndProperty" */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Insert_Input = {
  CustomerName?: Maybe<Scalars["String"]>;
  Customerno?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["bigint"]>;
  MeterNo?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  SupplyPointNo?: Maybe<Scalars["String"]>;
  TariffDesc?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Town?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  XCoord?: Maybe<Scalars["float8"]>;
  YCoord?: Maybe<Scalars["float8"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Max_Fields = {
  __typename?: "rtcs_db_umeme_SubscriberAndProperty_max_fields";
  CustomerName?: Maybe<Scalars["String"]>;
  Customerno?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["bigint"]>;
  MeterNo?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  SupplyPointNo?: Maybe<Scalars["String"]>;
  TariffDesc?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Town?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  XCoord?: Maybe<Scalars["float8"]>;
  YCoord?: Maybe<Scalars["float8"]>;
};

/** order by max() on columns of table "rtcs_db.umeme_SubscriberAndProperty" */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Max_Order_By = {
  CustomerName?: Maybe<Order_By>;
  Customerno?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MeterNo?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  SupplyPointNo?: Maybe<Order_By>;
  TariffDesc?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Town?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  XCoord?: Maybe<Order_By>;
  YCoord?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Min_Fields = {
  __typename?: "rtcs_db_umeme_SubscriberAndProperty_min_fields";
  CustomerName?: Maybe<Scalars["String"]>;
  Customerno?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["bigint"]>;
  MeterNo?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  SupplyPointNo?: Maybe<Scalars["String"]>;
  TariffDesc?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Town?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  XCoord?: Maybe<Scalars["float8"]>;
  YCoord?: Maybe<Scalars["float8"]>;
};

/** order by min() on columns of table "rtcs_db.umeme_SubscriberAndProperty" */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Min_Order_By = {
  CustomerName?: Maybe<Order_By>;
  Customerno?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MeterNo?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  SupplyPointNo?: Maybe<Order_By>;
  TariffDesc?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Town?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  XCoord?: Maybe<Order_By>;
  YCoord?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.umeme_SubscriberAndProperty" */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Mutation_Response = {
  __typename?: "rtcs_db_umeme_SubscriberAndProperty_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Umeme_SubscriberAndProperty>;
};

/** input type for inserting object relation for remote table "rtcs_db.umeme_SubscriberAndProperty" */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Umeme_SubscriberAndProperty_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.umeme_SubscriberAndProperty" */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Order_By = {
  CustomerName?: Maybe<Order_By>;
  Customerno?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  MeterNo?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  SupplyPointNo?: Maybe<Order_By>;
  TariffDesc?: Maybe<Order_By>;
  Telephone?: Maybe<Order_By>;
  Town?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
  XCoord?: Maybe<Order_By>;
  YCoord?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.umeme_SubscriberAndProperty" */
export enum Rtcs_Db_Umeme_SubscriberAndProperty_Select_Column {
  /** column name */
  CustomerName = "CustomerName",
  /** column name */
  Customerno = "Customerno",
  /** column name */
  District = "District",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Latitude = "Latitude",
  /** column name */
  Line = "Line",
  /** column name */
  Longitude = "Longitude",
  /** column name */
  MeterNo = "MeterNo",
  /** column name */
  Street = "Street",
  /** column name */
  SupplyPointNo = "SupplyPointNo",
  /** column name */
  TariffDesc = "TariffDesc",
  /** column name */
  Telephone = "Telephone",
  /** column name */
  Town = "Town",
  /** column name */
  WhoUpdated = "WhoUpdated",
  /** column name */
  XCoord = "XCoord",
  /** column name */
  YCoord = "YCoord",
}

/** input type for updating data in table "rtcs_db.umeme_SubscriberAndProperty" */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Set_Input = {
  CustomerName?: Maybe<Scalars["String"]>;
  Customerno?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Latitude?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["bigint"]>;
  MeterNo?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  SupplyPointNo?: Maybe<Scalars["String"]>;
  TariffDesc?: Maybe<Scalars["String"]>;
  Telephone?: Maybe<Scalars["String"]>;
  Town?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
  XCoord?: Maybe<Scalars["float8"]>;
  YCoord?: Maybe<Scalars["float8"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Stddev_Fields = {
  __typename?: "rtcs_db_umeme_SubscriberAndProperty_stddev_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  XCoord?: Maybe<Scalars["Float"]>;
  YCoord?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.umeme_SubscriberAndProperty" */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Stddev_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  XCoord?: Maybe<Order_By>;
  YCoord?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_umeme_SubscriberAndProperty_stddev_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  XCoord?: Maybe<Scalars["Float"]>;
  YCoord?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.umeme_SubscriberAndProperty" */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Stddev_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  XCoord?: Maybe<Order_By>;
  YCoord?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_umeme_SubscriberAndProperty_stddev_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  XCoord?: Maybe<Scalars["Float"]>;
  YCoord?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.umeme_SubscriberAndProperty" */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Stddev_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  XCoord?: Maybe<Order_By>;
  YCoord?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Sum_Fields = {
  __typename?: "rtcs_db_umeme_SubscriberAndProperty_sum_fields";
  Latitude?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Longitude?: Maybe<Scalars["bigint"]>;
  XCoord?: Maybe<Scalars["float8"]>;
  YCoord?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.umeme_SubscriberAndProperty" */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Sum_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  XCoord?: Maybe<Order_By>;
  YCoord?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Var_Pop_Fields = {
  __typename?: "rtcs_db_umeme_SubscriberAndProperty_var_pop_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  XCoord?: Maybe<Scalars["Float"]>;
  YCoord?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.umeme_SubscriberAndProperty" */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Var_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  XCoord?: Maybe<Order_By>;
  YCoord?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Var_Samp_Fields = {
  __typename?: "rtcs_db_umeme_SubscriberAndProperty_var_samp_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  XCoord?: Maybe<Scalars["Float"]>;
  YCoord?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.umeme_SubscriberAndProperty" */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Var_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  XCoord?: Maybe<Order_By>;
  YCoord?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Variance_Fields = {
  __typename?: "rtcs_db_umeme_SubscriberAndProperty_variance_fields";
  Latitude?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  Longitude?: Maybe<Scalars["Float"]>;
  XCoord?: Maybe<Scalars["Float"]>;
  YCoord?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.umeme_SubscriberAndProperty" */
export type Rtcs_Db_Umeme_SubscriberAndProperty_Variance_Order_By = {
  Latitude?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  XCoord?: Maybe<Order_By>;
  YCoord?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.umeme_Transactions" */
export type Rtcs_Db_Umeme_Transactions = {
  __typename?: "rtcs_db_umeme_Transactions";
  BillNumber?: Maybe<Scalars["String"]>;
  BillingDate?: Maybe<Scalars["String"]>;
  CashTendered?: Maybe<Scalars["String"]>;
  Consumption?: Maybe<Scalars["String"]>;
  CostOfEnergy?: Maybe<Scalars["String"]>;
  Customername?: Maybe<Scalars["String"]>;
  Customerno?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Meterno?: Maybe<Scalars["String"]>;
  Period?: Maybe<Scalars["String"]>;
  RevenueCategory?: Maybe<Scalars["String"]>;
  TotalRevenueNetamount?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.umeme_Transactions" */
export type Rtcs_Db_Umeme_Transactions_Aggregate = {
  __typename?: "rtcs_db_umeme_Transactions_aggregate";
  aggregate?: Maybe<Rtcs_Db_Umeme_Transactions_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Umeme_Transactions>;
};

/** aggregate fields of "rtcs_db.umeme_Transactions" */
export type Rtcs_Db_Umeme_Transactions_Aggregate_Fields = {
  __typename?: "rtcs_db_umeme_Transactions_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Umeme_Transactions_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Umeme_Transactions_Max_Fields>;
  min?: Maybe<Rtcs_Db_Umeme_Transactions_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Umeme_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Umeme_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Umeme_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Umeme_Transactions_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Umeme_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Umeme_Transactions_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Umeme_Transactions_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.umeme_Transactions" */
export type Rtcs_Db_Umeme_Transactions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Umeme_Transactions_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.umeme_Transactions" */
export type Rtcs_Db_Umeme_Transactions_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Umeme_Transactions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Umeme_Transactions_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Umeme_Transactions_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Umeme_Transactions_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Umeme_Transactions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Umeme_Transactions_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Umeme_Transactions_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Umeme_Transactions_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Umeme_Transactions_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Umeme_Transactions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.umeme_Transactions" */
export type Rtcs_Db_Umeme_Transactions_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Umeme_Transactions_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Umeme_Transactions_Avg_Fields = {
  __typename?: "rtcs_db_umeme_Transactions_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.umeme_Transactions" */
export type Rtcs_Db_Umeme_Transactions_Avg_Order_By = {
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.umeme_Transactions". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Umeme_Transactions_Bool_Exp = {
  BillNumber?: Maybe<String_Comparison_Exp>;
  BillingDate?: Maybe<String_Comparison_Exp>;
  CashTendered?: Maybe<String_Comparison_Exp>;
  Consumption?: Maybe<String_Comparison_Exp>;
  CostOfEnergy?: Maybe<String_Comparison_Exp>;
  Customername?: Maybe<String_Comparison_Exp>;
  Customerno?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<String_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Meterno?: Maybe<String_Comparison_Exp>;
  Period?: Maybe<String_Comparison_Exp>;
  RevenueCategory?: Maybe<String_Comparison_Exp>;
  TotalRevenueNetamount?: Maybe<String_Comparison_Exp>;
  Type?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Umeme_Transactions_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Umeme_Transactions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Umeme_Transactions_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.umeme_Transactions" */
export type Rtcs_Db_Umeme_Transactions_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.umeme_Transactions" */
export type Rtcs_Db_Umeme_Transactions_Insert_Input = {
  BillNumber?: Maybe<Scalars["String"]>;
  BillingDate?: Maybe<Scalars["String"]>;
  CashTendered?: Maybe<Scalars["String"]>;
  Consumption?: Maybe<Scalars["String"]>;
  CostOfEnergy?: Maybe<Scalars["String"]>;
  Customername?: Maybe<Scalars["String"]>;
  Customerno?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Meterno?: Maybe<Scalars["String"]>;
  Period?: Maybe<Scalars["String"]>;
  RevenueCategory?: Maybe<Scalars["String"]>;
  TotalRevenueNetamount?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Umeme_Transactions_Max_Fields = {
  __typename?: "rtcs_db_umeme_Transactions_max_fields";
  BillNumber?: Maybe<Scalars["String"]>;
  BillingDate?: Maybe<Scalars["String"]>;
  CashTendered?: Maybe<Scalars["String"]>;
  Consumption?: Maybe<Scalars["String"]>;
  CostOfEnergy?: Maybe<Scalars["String"]>;
  Customername?: Maybe<Scalars["String"]>;
  Customerno?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Meterno?: Maybe<Scalars["String"]>;
  Period?: Maybe<Scalars["String"]>;
  RevenueCategory?: Maybe<Scalars["String"]>;
  TotalRevenueNetamount?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.umeme_Transactions" */
export type Rtcs_Db_Umeme_Transactions_Max_Order_By = {
  BillNumber?: Maybe<Order_By>;
  BillingDate?: Maybe<Order_By>;
  CashTendered?: Maybe<Order_By>;
  Consumption?: Maybe<Order_By>;
  CostOfEnergy?: Maybe<Order_By>;
  Customername?: Maybe<Order_By>;
  Customerno?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Meterno?: Maybe<Order_By>;
  Period?: Maybe<Order_By>;
  RevenueCategory?: Maybe<Order_By>;
  TotalRevenueNetamount?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Umeme_Transactions_Min_Fields = {
  __typename?: "rtcs_db_umeme_Transactions_min_fields";
  BillNumber?: Maybe<Scalars["String"]>;
  BillingDate?: Maybe<Scalars["String"]>;
  CashTendered?: Maybe<Scalars["String"]>;
  Consumption?: Maybe<Scalars["String"]>;
  CostOfEnergy?: Maybe<Scalars["String"]>;
  Customername?: Maybe<Scalars["String"]>;
  Customerno?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Meterno?: Maybe<Scalars["String"]>;
  Period?: Maybe<Scalars["String"]>;
  RevenueCategory?: Maybe<Scalars["String"]>;
  TotalRevenueNetamount?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.umeme_Transactions" */
export type Rtcs_Db_Umeme_Transactions_Min_Order_By = {
  BillNumber?: Maybe<Order_By>;
  BillingDate?: Maybe<Order_By>;
  CashTendered?: Maybe<Order_By>;
  Consumption?: Maybe<Order_By>;
  CostOfEnergy?: Maybe<Order_By>;
  Customername?: Maybe<Order_By>;
  Customerno?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Meterno?: Maybe<Order_By>;
  Period?: Maybe<Order_By>;
  RevenueCategory?: Maybe<Order_By>;
  TotalRevenueNetamount?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.umeme_Transactions" */
export type Rtcs_Db_Umeme_Transactions_Mutation_Response = {
  __typename?: "rtcs_db_umeme_Transactions_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Umeme_Transactions>;
};

/** input type for inserting object relation for remote table "rtcs_db.umeme_Transactions" */
export type Rtcs_Db_Umeme_Transactions_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Umeme_Transactions_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.umeme_Transactions" */
export type Rtcs_Db_Umeme_Transactions_Order_By = {
  BillNumber?: Maybe<Order_By>;
  BillingDate?: Maybe<Order_By>;
  CashTendered?: Maybe<Order_By>;
  Consumption?: Maybe<Order_By>;
  CostOfEnergy?: Maybe<Order_By>;
  Customername?: Maybe<Order_By>;
  Customerno?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Meterno?: Maybe<Order_By>;
  Period?: Maybe<Order_By>;
  RevenueCategory?: Maybe<Order_By>;
  TotalRevenueNetamount?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.umeme_Transactions" */
export enum Rtcs_Db_Umeme_Transactions_Select_Column {
  /** column name */
  BillNumber = "BillNumber",
  /** column name */
  BillingDate = "BillingDate",
  /** column name */
  CashTendered = "CashTendered",
  /** column name */
  Consumption = "Consumption",
  /** column name */
  CostOfEnergy = "CostOfEnergy",
  /** column name */
  Customername = "Customername",
  /** column name */
  Customerno = "Customerno",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Meterno = "Meterno",
  /** column name */
  Period = "Period",
  /** column name */
  RevenueCategory = "RevenueCategory",
  /** column name */
  TotalRevenueNetamount = "TotalRevenueNetamount",
  /** column name */
  Type = "Type",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.umeme_Transactions" */
export type Rtcs_Db_Umeme_Transactions_Set_Input = {
  BillNumber?: Maybe<Scalars["String"]>;
  BillingDate?: Maybe<Scalars["String"]>;
  CashTendered?: Maybe<Scalars["String"]>;
  Consumption?: Maybe<Scalars["String"]>;
  CostOfEnergy?: Maybe<Scalars["String"]>;
  Customername?: Maybe<Scalars["String"]>;
  Customerno?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["String"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Meterno?: Maybe<Scalars["String"]>;
  Period?: Maybe<Scalars["String"]>;
  RevenueCategory?: Maybe<Scalars["String"]>;
  TotalRevenueNetamount?: Maybe<Scalars["String"]>;
  Type?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Umeme_Transactions_Stddev_Fields = {
  __typename?: "rtcs_db_umeme_Transactions_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.umeme_Transactions" */
export type Rtcs_Db_Umeme_Transactions_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Umeme_Transactions_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_umeme_Transactions_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.umeme_Transactions" */
export type Rtcs_Db_Umeme_Transactions_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Umeme_Transactions_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_umeme_Transactions_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.umeme_Transactions" */
export type Rtcs_Db_Umeme_Transactions_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Umeme_Transactions_Sum_Fields = {
  __typename?: "rtcs_db_umeme_Transactions_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.umeme_Transactions" */
export type Rtcs_Db_Umeme_Transactions_Sum_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Umeme_Transactions_Var_Pop_Fields = {
  __typename?: "rtcs_db_umeme_Transactions_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.umeme_Transactions" */
export type Rtcs_Db_Umeme_Transactions_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Umeme_Transactions_Var_Samp_Fields = {
  __typename?: "rtcs_db_umeme_Transactions_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.umeme_Transactions" */
export type Rtcs_Db_Umeme_Transactions_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Umeme_Transactions_Variance_Fields = {
  __typename?: "rtcs_db_umeme_Transactions_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.umeme_Transactions" */
export type Rtcs_Db_Umeme_Transactions_Variance_Order_By = {
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.ura_BalanceSheetInformation" */
export type Rtcs_Db_Ura_BalanceSheetInformation = {
  __typename?: "rtcs_db_ura_BalanceSheetInformation";
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  ApplicationFund?: Maybe<Scalars["String"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetCurrentAsset?: Maybe<Scalars["String"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  OthrLiability?: Maybe<Scalars["String"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProfitLossAccount?: Maybe<Scalars["String"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  RtnId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnId?: Maybe<Scalars["String"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TotAccntRcvdDebtors?: Maybe<Scalars["String"]>;
  TotAssetLoanAdvance?: Maybe<Scalars["String"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotCurrLiability?: Maybe<Scalars["String"]>;
  TotCurrentAsset?: Maybe<Scalars["String"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLiabilityProv?: Maybe<Scalars["String"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalLoanFund?: Maybe<Scalars["String"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureCreditorLoan?: Maybe<Scalars["String"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_BalanceSheetInformation" */
export type Rtcs_Db_Ura_BalanceSheetInformation_Aggregate = {
  __typename?: "rtcs_db_ura_BalanceSheetInformation_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_BalanceSheetInformation>;
};

/** aggregate fields of "rtcs_db.ura_BalanceSheetInformation" */
export type Rtcs_Db_Ura_BalanceSheetInformation_Aggregate_Fields = {
  __typename?: "rtcs_db_ura_BalanceSheetInformation_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ura_BalanceSheetInformation" */
export type Rtcs_Db_Ura_BalanceSheetInformation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ura_BalanceSheetInformation_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.ura_BalanceSheetInformation" */
export type Rtcs_Db_Ura_BalanceSheetInformation_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ura_BalanceSheetInformation" */
export type Rtcs_Db_Ura_BalanceSheetInformation_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ura_BalanceSheetInformation_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ura_BalanceSheetInformation_Avg_Fields = {
  __typename?: "rtcs_db_ura_BalanceSheetInformation_avg_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_BalanceSheetInformation" */
export type Rtcs_Db_Ura_BalanceSheetInformation_Avg_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_BalanceSheetInformation". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_BalanceSheetInformation_Bool_Exp = {
  AccntRcvdOthers?: Maybe<Float8_Comparison_Exp>;
  AccntRcvdOverSix?: Maybe<Float8_Comparison_Exp>;
  AdvanceCorporate?: Maybe<Float8_Comparison_Exp>;
  AdvanceRecoverable?: Maybe<Float8_Comparison_Exp>;
  ApplicationFund?: Maybe<String_Comparison_Exp>;
  BalRevenueAuth?: Maybe<Float8_Comparison_Exp>;
  BankBalance?: Maybe<Float8_Comparison_Exp>;
  BankOverDraft?: Maybe<Float8_Comparison_Exp>;
  CnsmPckInventory?: Maybe<Float8_Comparison_Exp>;
  Debenture?: Maybe<Float8_Comparison_Exp>;
  DeferTaxLiability?: Maybe<Float8_Comparison_Exp>;
  DeferredAsset?: Maybe<Float8_Comparison_Exp>;
  Deposits?: Maybe<Float8_Comparison_Exp>;
  Depreciation?: Maybe<Float8_Comparison_Exp>;
  EquityShare?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FinishTradeGoods?: Maybe<Float8_Comparison_Exp>;
  FixedDeposit?: Maybe<Float8_Comparison_Exp>;
  FurnitureFixture?: Maybe<Float8_Comparison_Exp>;
  GeneralReserve?: Maybe<Float8_Comparison_Exp>;
  GovtSecurities?: Maybe<Float8_Comparison_Exp>;
  HandCash?: Maybe<Float8_Comparison_Exp>;
  IncomeTaxProv?: Maybe<Float8_Comparison_Exp>;
  IntLeaseAsset?: Maybe<Float8_Comparison_Exp>;
  IntangibleAssets?: Maybe<Float8_Comparison_Exp>;
  LandBuild?: Maybe<Float8_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  LessDrawings?: Maybe<Float8_Comparison_Exp>;
  LiableLeaseAsset?: Maybe<Float8_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  LoanSubCompany?: Maybe<Float8_Comparison_Exp>;
  MotorVehicles?: Maybe<Float8_Comparison_Exp>;
  NetCurrentAsset?: Maybe<String_Comparison_Exp>;
  NetFixedAsset?: Maybe<Float8_Comparison_Exp>;
  OtherInvestments?: Maybe<Float8_Comparison_Exp>;
  OtherProvision?: Maybe<Float8_Comparison_Exp>;
  OtherReserve?: Maybe<Float8_Comparison_Exp>;
  OthrCurrentAsset?: Maybe<Float8_Comparison_Exp>;
  OthrDeprecAsset?: Maybe<Float8_Comparison_Exp>;
  OthrLiability?: Maybe<String_Comparison_Exp>;
  PlantMachine?: Maybe<Float8_Comparison_Exp>;
  Prepayments?: Maybe<Float8_Comparison_Exp>;
  ProfitLossAccount?: Maybe<String_Comparison_Exp>;
  ProprietorCapital?: Maybe<Float8_Comparison_Exp>;
  ProvForBadDebts?: Maybe<Float8_Comparison_Exp>;
  RawMtrlInventory?: Maybe<Float8_Comparison_Exp>;
  ReturnType?: Maybe<String_Comparison_Exp>;
  RevaluationReserve?: Maybe<Float8_Comparison_Exp>;
  RtnId?: Maybe<String_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnNoRtnId?: Maybe<String_Comparison_Exp>;
  SecureDebtIssued?: Maybe<Float8_Comparison_Exp>;
  SecureLoanBank?: Maybe<Float8_Comparison_Exp>;
  SecureLoanOther?: Maybe<Float8_Comparison_Exp>;
  SecureLoanParties?: Maybe<Float8_Comparison_Exp>;
  SecureTotLoan?: Maybe<String_Comparison_Exp>;
  SourceFund?: Maybe<String_Comparison_Exp>;
  StockInProcess?: Maybe<Float8_Comparison_Exp>;
  SundryCreditors?: Maybe<Float8_Comparison_Exp>;
  TotAccntRcvdDebtors?: Maybe<String_Comparison_Exp>;
  TotAssetLoanAdvance?: Maybe<String_Comparison_Exp>;
  TotBalanceAvail?: Maybe<Float8_Comparison_Exp>;
  TotCurrLiability?: Maybe<String_Comparison_Exp>;
  TotCurrentAsset?: Maybe<String_Comparison_Exp>;
  TotFixedAsset?: Maybe<Float8_Comparison_Exp>;
  TotInventory?: Maybe<Float8_Comparison_Exp>;
  TotLiabilityProv?: Maybe<String_Comparison_Exp>;
  TotLoanAdvance?: Maybe<Float8_Comparison_Exp>;
  TotTradeInvestment?: Maybe<Float8_Comparison_Exp>;
  TotalLoanFund?: Maybe<String_Comparison_Exp>;
  TotalPropFund?: Maybe<String_Comparison_Exp>;
  TotalProvision?: Maybe<Float8_Comparison_Exp>;
  TranslationReserve?: Maybe<Float8_Comparison_Exp>;
  UnpaidMatureDebts?: Maybe<Float8_Comparison_Exp>;
  UnsecureCreditorLoan?: Maybe<String_Comparison_Exp>;
  UnsecureLoanBank?: Maybe<Float8_Comparison_Exp>;
  UnsecureLoanOther?: Maybe<Float8_Comparison_Exp>;
  UnsecureLoanParties?: Maybe<Float8_Comparison_Exp>;
  UnsecureTotLoan?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.ura_BalanceSheetInformation" */
export type Rtcs_Db_Ura_BalanceSheetInformation_Inc_Input = {
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.ura_BalanceSheetInformation" */
export type Rtcs_Db_Ura_BalanceSheetInformation_Insert_Input = {
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  ApplicationFund?: Maybe<Scalars["String"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetCurrentAsset?: Maybe<Scalars["String"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  OthrLiability?: Maybe<Scalars["String"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProfitLossAccount?: Maybe<Scalars["String"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  RtnId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnId?: Maybe<Scalars["String"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TotAccntRcvdDebtors?: Maybe<Scalars["String"]>;
  TotAssetLoanAdvance?: Maybe<Scalars["String"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotCurrLiability?: Maybe<Scalars["String"]>;
  TotCurrentAsset?: Maybe<Scalars["String"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLiabilityProv?: Maybe<Scalars["String"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalLoanFund?: Maybe<Scalars["String"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureCreditorLoan?: Maybe<Scalars["String"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_BalanceSheetInformation_Max_Fields = {
  __typename?: "rtcs_db_ura_BalanceSheetInformation_max_fields";
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  ApplicationFund?: Maybe<Scalars["String"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetCurrentAsset?: Maybe<Scalars["String"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  OthrLiability?: Maybe<Scalars["String"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProfitLossAccount?: Maybe<Scalars["String"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  RtnId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnId?: Maybe<Scalars["String"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TotAccntRcvdDebtors?: Maybe<Scalars["String"]>;
  TotAssetLoanAdvance?: Maybe<Scalars["String"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotCurrLiability?: Maybe<Scalars["String"]>;
  TotCurrentAsset?: Maybe<Scalars["String"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLiabilityProv?: Maybe<Scalars["String"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalLoanFund?: Maybe<Scalars["String"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureCreditorLoan?: Maybe<Scalars["String"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_BalanceSheetInformation" */
export type Rtcs_Db_Ura_BalanceSheetInformation_Max_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  ApplicationFund?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetCurrentAsset?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  OthrLiability?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProfitLossAccount?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  RtnId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnId?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  SecureTotLoan?: Maybe<Order_By>;
  SourceFund?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotAccntRcvdDebtors?: Maybe<Order_By>;
  TotAssetLoanAdvance?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotCurrLiability?: Maybe<Order_By>;
  TotCurrentAsset?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLiabilityProv?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalLoanFund?: Maybe<Order_By>;
  TotalPropFund?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureCreditorLoan?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
  UnsecureTotLoan?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_BalanceSheetInformation_Min_Fields = {
  __typename?: "rtcs_db_ura_BalanceSheetInformation_min_fields";
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  ApplicationFund?: Maybe<Scalars["String"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetCurrentAsset?: Maybe<Scalars["String"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  OthrLiability?: Maybe<Scalars["String"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProfitLossAccount?: Maybe<Scalars["String"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  RtnId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnId?: Maybe<Scalars["String"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TotAccntRcvdDebtors?: Maybe<Scalars["String"]>;
  TotAssetLoanAdvance?: Maybe<Scalars["String"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotCurrLiability?: Maybe<Scalars["String"]>;
  TotCurrentAsset?: Maybe<Scalars["String"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLiabilityProv?: Maybe<Scalars["String"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalLoanFund?: Maybe<Scalars["String"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureCreditorLoan?: Maybe<Scalars["String"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_BalanceSheetInformation" */
export type Rtcs_Db_Ura_BalanceSheetInformation_Min_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  ApplicationFund?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetCurrentAsset?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  OthrLiability?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProfitLossAccount?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  RtnId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnId?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  SecureTotLoan?: Maybe<Order_By>;
  SourceFund?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotAccntRcvdDebtors?: Maybe<Order_By>;
  TotAssetLoanAdvance?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotCurrLiability?: Maybe<Order_By>;
  TotCurrentAsset?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLiabilityProv?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalLoanFund?: Maybe<Order_By>;
  TotalPropFund?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureCreditorLoan?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
  UnsecureTotLoan?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_BalanceSheetInformation" */
export type Rtcs_Db_Ura_BalanceSheetInformation_Mutation_Response = {
  __typename?: "rtcs_db_ura_BalanceSheetInformation_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ura_BalanceSheetInformation>;
};

/** input type for inserting object relation for remote table "rtcs_db.ura_BalanceSheetInformation" */
export type Rtcs_Db_Ura_BalanceSheetInformation_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ura_BalanceSheetInformation_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.ura_BalanceSheetInformation" */
export type Rtcs_Db_Ura_BalanceSheetInformation_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  ApplicationFund?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetCurrentAsset?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  OthrLiability?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProfitLossAccount?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  RtnId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnId?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  SecureTotLoan?: Maybe<Order_By>;
  SourceFund?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotAccntRcvdDebtors?: Maybe<Order_By>;
  TotAssetLoanAdvance?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotCurrLiability?: Maybe<Order_By>;
  TotCurrentAsset?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLiabilityProv?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalLoanFund?: Maybe<Order_By>;
  TotalPropFund?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureCreditorLoan?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
  UnsecureTotLoan?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_BalanceSheetInformation" */
export enum Rtcs_Db_Ura_BalanceSheetInformation_Select_Column {
  /** column name */
  AccntRcvdOthers = "AccntRcvdOthers",
  /** column name */
  AccntRcvdOverSix = "AccntRcvdOverSix",
  /** column name */
  AdvanceCorporate = "AdvanceCorporate",
  /** column name */
  AdvanceRecoverable = "AdvanceRecoverable",
  /** column name */
  ApplicationFund = "ApplicationFund",
  /** column name */
  BalRevenueAuth = "BalRevenueAuth",
  /** column name */
  BankBalance = "BankBalance",
  /** column name */
  BankOverDraft = "BankOverDraft",
  /** column name */
  CnsmPckInventory = "CnsmPckInventory",
  /** column name */
  Debenture = "Debenture",
  /** column name */
  DeferTaxLiability = "DeferTaxLiability",
  /** column name */
  DeferredAsset = "DeferredAsset",
  /** column name */
  Deposits = "Deposits",
  /** column name */
  Depreciation = "Depreciation",
  /** column name */
  EquityShare = "EquityShare",
  /** column name */
  File = "File",
  /** column name */
  FinishTradeGoods = "FinishTradeGoods",
  /** column name */
  FixedDeposit = "FixedDeposit",
  /** column name */
  FurnitureFixture = "FurnitureFixture",
  /** column name */
  GeneralReserve = "GeneralReserve",
  /** column name */
  GovtSecurities = "GovtSecurities",
  /** column name */
  HandCash = "HandCash",
  /** column name */
  IncomeTaxProv = "IncomeTaxProv",
  /** column name */
  IntLeaseAsset = "IntLeaseAsset",
  /** column name */
  IntangibleAssets = "IntangibleAssets",
  /** column name */
  LandBuild = "LandBuild",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  LessDrawings = "LessDrawings",
  /** column name */
  LiableLeaseAsset = "LiableLeaseAsset",
  /** column name */
  Line = "Line",
  /** column name */
  LoanSubCompany = "LoanSubCompany",
  /** column name */
  MotorVehicles = "MotorVehicles",
  /** column name */
  NetCurrentAsset = "NetCurrentAsset",
  /** column name */
  NetFixedAsset = "NetFixedAsset",
  /** column name */
  OtherInvestments = "OtherInvestments",
  /** column name */
  OtherProvision = "OtherProvision",
  /** column name */
  OtherReserve = "OtherReserve",
  /** column name */
  OthrCurrentAsset = "OthrCurrentAsset",
  /** column name */
  OthrDeprecAsset = "OthrDeprecAsset",
  /** column name */
  OthrLiability = "OthrLiability",
  /** column name */
  PlantMachine = "PlantMachine",
  /** column name */
  Prepayments = "Prepayments",
  /** column name */
  ProfitLossAccount = "ProfitLossAccount",
  /** column name */
  ProprietorCapital = "ProprietorCapital",
  /** column name */
  ProvForBadDebts = "ProvForBadDebts",
  /** column name */
  RawMtrlInventory = "RawMtrlInventory",
  /** column name */
  ReturnType = "ReturnType",
  /** column name */
  RevaluationReserve = "RevaluationReserve",
  /** column name */
  RtnId = "RtnId",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnNoRtnId = "RtnNoRtnId",
  /** column name */
  SecureDebtIssued = "SecureDebtIssued",
  /** column name */
  SecureLoanBank = "SecureLoanBank",
  /** column name */
  SecureLoanOther = "SecureLoanOther",
  /** column name */
  SecureLoanParties = "SecureLoanParties",
  /** column name */
  SecureTotLoan = "SecureTotLoan",
  /** column name */
  SourceFund = "SourceFund",
  /** column name */
  StockInProcess = "StockInProcess",
  /** column name */
  SundryCreditors = "SundryCreditors",
  /** column name */
  TotAccntRcvdDebtors = "TotAccntRcvdDebtors",
  /** column name */
  TotAssetLoanAdvance = "TotAssetLoanAdvance",
  /** column name */
  TotBalanceAvail = "TotBalanceAvail",
  /** column name */
  TotCurrLiability = "TotCurrLiability",
  /** column name */
  TotCurrentAsset = "TotCurrentAsset",
  /** column name */
  TotFixedAsset = "TotFixedAsset",
  /** column name */
  TotInventory = "TotInventory",
  /** column name */
  TotLiabilityProv = "TotLiabilityProv",
  /** column name */
  TotLoanAdvance = "TotLoanAdvance",
  /** column name */
  TotTradeInvestment = "TotTradeInvestment",
  /** column name */
  TotalLoanFund = "TotalLoanFund",
  /** column name */
  TotalPropFund = "TotalPropFund",
  /** column name */
  TotalProvision = "TotalProvision",
  /** column name */
  TranslationReserve = "TranslationReserve",
  /** column name */
  UnpaidMatureDebts = "UnpaidMatureDebts",
  /** column name */
  UnsecureCreditorLoan = "UnsecureCreditorLoan",
  /** column name */
  UnsecureLoanBank = "UnsecureLoanBank",
  /** column name */
  UnsecureLoanOther = "UnsecureLoanOther",
  /** column name */
  UnsecureLoanParties = "UnsecureLoanParties",
  /** column name */
  UnsecureTotLoan = "UnsecureTotLoan",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_BalanceSheetInformation" */
export type Rtcs_Db_Ura_BalanceSheetInformation_Set_Input = {
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  ApplicationFund?: Maybe<Scalars["String"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetCurrentAsset?: Maybe<Scalars["String"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  OthrLiability?: Maybe<Scalars["String"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProfitLossAccount?: Maybe<Scalars["String"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  RtnId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnId?: Maybe<Scalars["String"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TotAccntRcvdDebtors?: Maybe<Scalars["String"]>;
  TotAssetLoanAdvance?: Maybe<Scalars["String"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotCurrLiability?: Maybe<Scalars["String"]>;
  TotCurrentAsset?: Maybe<Scalars["String"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLiabilityProv?: Maybe<Scalars["String"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalLoanFund?: Maybe<Scalars["String"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureCreditorLoan?: Maybe<Scalars["String"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_BalanceSheetInformation_Stddev_Fields = {
  __typename?: "rtcs_db_ura_BalanceSheetInformation_stddev_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_BalanceSheetInformation" */
export type Rtcs_Db_Ura_BalanceSheetInformation_Stddev_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_BalanceSheetInformation_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ura_BalanceSheetInformation_stddev_pop_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ura_BalanceSheetInformation" */
export type Rtcs_Db_Ura_BalanceSheetInformation_Stddev_Pop_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_BalanceSheetInformation_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ura_BalanceSheetInformation_stddev_samp_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ura_BalanceSheetInformation" */
export type Rtcs_Db_Ura_BalanceSheetInformation_Stddev_Samp_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ura_BalanceSheetInformation_Sum_Fields = {
  __typename?: "rtcs_db_ura_BalanceSheetInformation_sum_fields";
  AccntRcvdOthers?: Maybe<Scalars["float8"]>;
  AccntRcvdOverSix?: Maybe<Scalars["float8"]>;
  AdvanceCorporate?: Maybe<Scalars["float8"]>;
  AdvanceRecoverable?: Maybe<Scalars["float8"]>;
  BalRevenueAuth?: Maybe<Scalars["float8"]>;
  BankBalance?: Maybe<Scalars["float8"]>;
  BankOverDraft?: Maybe<Scalars["float8"]>;
  CnsmPckInventory?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  DeferredAsset?: Maybe<Scalars["float8"]>;
  Deposits?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FinishTradeGoods?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  FurnitureFixture?: Maybe<Scalars["float8"]>;
  GeneralReserve?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["float8"]>;
  HandCash?: Maybe<Scalars["float8"]>;
  IncomeTaxProv?: Maybe<Scalars["float8"]>;
  IntLeaseAsset?: Maybe<Scalars["float8"]>;
  IntangibleAssets?: Maybe<Scalars["float8"]>;
  LandBuild?: Maybe<Scalars["float8"]>;
  LessDrawings?: Maybe<Scalars["float8"]>;
  LiableLeaseAsset?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  LoanSubCompany?: Maybe<Scalars["float8"]>;
  MotorVehicles?: Maybe<Scalars["float8"]>;
  NetFixedAsset?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OtherProvision?: Maybe<Scalars["float8"]>;
  OtherReserve?: Maybe<Scalars["float8"]>;
  OthrCurrentAsset?: Maybe<Scalars["float8"]>;
  OthrDeprecAsset?: Maybe<Scalars["float8"]>;
  PlantMachine?: Maybe<Scalars["float8"]>;
  Prepayments?: Maybe<Scalars["float8"]>;
  ProprietorCapital?: Maybe<Scalars["float8"]>;
  ProvForBadDebts?: Maybe<Scalars["float8"]>;
  RawMtrlInventory?: Maybe<Scalars["float8"]>;
  RevaluationReserve?: Maybe<Scalars["float8"]>;
  SecureDebtIssued?: Maybe<Scalars["float8"]>;
  SecureLoanBank?: Maybe<Scalars["float8"]>;
  SecureLoanOther?: Maybe<Scalars["float8"]>;
  SecureLoanParties?: Maybe<Scalars["float8"]>;
  StockInProcess?: Maybe<Scalars["float8"]>;
  SundryCreditors?: Maybe<Scalars["float8"]>;
  TotBalanceAvail?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["float8"]>;
  TotInventory?: Maybe<Scalars["float8"]>;
  TotLoanAdvance?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["float8"]>;
  TotalProvision?: Maybe<Scalars["float8"]>;
  TranslationReserve?: Maybe<Scalars["float8"]>;
  UnpaidMatureDebts?: Maybe<Scalars["float8"]>;
  UnsecureLoanBank?: Maybe<Scalars["float8"]>;
  UnsecureLoanOther?: Maybe<Scalars["float8"]>;
  UnsecureLoanParties?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_BalanceSheetInformation" */
export type Rtcs_Db_Ura_BalanceSheetInformation_Sum_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_BalanceSheetInformation_Var_Pop_Fields = {
  __typename?: "rtcs_db_ura_BalanceSheetInformation_var_pop_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ura_BalanceSheetInformation" */
export type Rtcs_Db_Ura_BalanceSheetInformation_Var_Pop_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_BalanceSheetInformation_Var_Samp_Fields = {
  __typename?: "rtcs_db_ura_BalanceSheetInformation_var_samp_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ura_BalanceSheetInformation" */
export type Rtcs_Db_Ura_BalanceSheetInformation_Var_Samp_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ura_BalanceSheetInformation_Variance_Fields = {
  __typename?: "rtcs_db_ura_BalanceSheetInformation_variance_fields";
  AccntRcvdOthers?: Maybe<Scalars["Float"]>;
  AccntRcvdOverSix?: Maybe<Scalars["Float"]>;
  AdvanceCorporate?: Maybe<Scalars["Float"]>;
  AdvanceRecoverable?: Maybe<Scalars["Float"]>;
  BalRevenueAuth?: Maybe<Scalars["Float"]>;
  BankBalance?: Maybe<Scalars["Float"]>;
  BankOverDraft?: Maybe<Scalars["Float"]>;
  CnsmPckInventory?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  DeferredAsset?: Maybe<Scalars["Float"]>;
  Deposits?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FinishTradeGoods?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  FurnitureFixture?: Maybe<Scalars["Float"]>;
  GeneralReserve?: Maybe<Scalars["Float"]>;
  GovtSecurities?: Maybe<Scalars["Float"]>;
  HandCash?: Maybe<Scalars["Float"]>;
  IncomeTaxProv?: Maybe<Scalars["Float"]>;
  IntLeaseAsset?: Maybe<Scalars["Float"]>;
  IntangibleAssets?: Maybe<Scalars["Float"]>;
  LandBuild?: Maybe<Scalars["Float"]>;
  LessDrawings?: Maybe<Scalars["Float"]>;
  LiableLeaseAsset?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  LoanSubCompany?: Maybe<Scalars["Float"]>;
  MotorVehicles?: Maybe<Scalars["Float"]>;
  NetFixedAsset?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OtherProvision?: Maybe<Scalars["Float"]>;
  OtherReserve?: Maybe<Scalars["Float"]>;
  OthrCurrentAsset?: Maybe<Scalars["Float"]>;
  OthrDeprecAsset?: Maybe<Scalars["Float"]>;
  PlantMachine?: Maybe<Scalars["Float"]>;
  Prepayments?: Maybe<Scalars["Float"]>;
  ProprietorCapital?: Maybe<Scalars["Float"]>;
  ProvForBadDebts?: Maybe<Scalars["Float"]>;
  RawMtrlInventory?: Maybe<Scalars["Float"]>;
  RevaluationReserve?: Maybe<Scalars["Float"]>;
  SecureDebtIssued?: Maybe<Scalars["Float"]>;
  SecureLoanBank?: Maybe<Scalars["Float"]>;
  SecureLoanOther?: Maybe<Scalars["Float"]>;
  SecureLoanParties?: Maybe<Scalars["Float"]>;
  StockInProcess?: Maybe<Scalars["Float"]>;
  SundryCreditors?: Maybe<Scalars["Float"]>;
  TotBalanceAvail?: Maybe<Scalars["Float"]>;
  TotFixedAsset?: Maybe<Scalars["Float"]>;
  TotInventory?: Maybe<Scalars["Float"]>;
  TotLoanAdvance?: Maybe<Scalars["Float"]>;
  TotTradeInvestment?: Maybe<Scalars["Float"]>;
  TotalProvision?: Maybe<Scalars["Float"]>;
  TranslationReserve?: Maybe<Scalars["Float"]>;
  UnpaidMatureDebts?: Maybe<Scalars["Float"]>;
  UnsecureLoanBank?: Maybe<Scalars["Float"]>;
  UnsecureLoanOther?: Maybe<Scalars["Float"]>;
  UnsecureLoanParties?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ura_BalanceSheetInformation" */
export type Rtcs_Db_Ura_BalanceSheetInformation_Variance_Order_By = {
  AccntRcvdOthers?: Maybe<Order_By>;
  AccntRcvdOverSix?: Maybe<Order_By>;
  AdvanceCorporate?: Maybe<Order_By>;
  AdvanceRecoverable?: Maybe<Order_By>;
  BalRevenueAuth?: Maybe<Order_By>;
  BankBalance?: Maybe<Order_By>;
  BankOverDraft?: Maybe<Order_By>;
  CnsmPckInventory?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  DeferredAsset?: Maybe<Order_By>;
  Deposits?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FinishTradeGoods?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  FurnitureFixture?: Maybe<Order_By>;
  GeneralReserve?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  HandCash?: Maybe<Order_By>;
  IncomeTaxProv?: Maybe<Order_By>;
  IntLeaseAsset?: Maybe<Order_By>;
  IntangibleAssets?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LessDrawings?: Maybe<Order_By>;
  LiableLeaseAsset?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  LoanSubCompany?: Maybe<Order_By>;
  MotorVehicles?: Maybe<Order_By>;
  NetFixedAsset?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OtherProvision?: Maybe<Order_By>;
  OtherReserve?: Maybe<Order_By>;
  OthrCurrentAsset?: Maybe<Order_By>;
  OthrDeprecAsset?: Maybe<Order_By>;
  PlantMachine?: Maybe<Order_By>;
  Prepayments?: Maybe<Order_By>;
  ProprietorCapital?: Maybe<Order_By>;
  ProvForBadDebts?: Maybe<Order_By>;
  RawMtrlInventory?: Maybe<Order_By>;
  RevaluationReserve?: Maybe<Order_By>;
  SecureDebtIssued?: Maybe<Order_By>;
  SecureLoanBank?: Maybe<Order_By>;
  SecureLoanOther?: Maybe<Order_By>;
  SecureLoanParties?: Maybe<Order_By>;
  StockInProcess?: Maybe<Order_By>;
  SundryCreditors?: Maybe<Order_By>;
  TotBalanceAvail?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotInventory?: Maybe<Order_By>;
  TotLoanAdvance?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotalProvision?: Maybe<Order_By>;
  TranslationReserve?: Maybe<Order_By>;
  UnpaidMatureDebts?: Maybe<Order_By>;
  UnsecureLoanBank?: Maybe<Order_By>;
  UnsecureLoanOther?: Maybe<Order_By>;
  UnsecureLoanParties?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners = {
  __typename?: "rtcs_db_ura_CombinedOfficersAndOwners";
  Address?: Maybe<Scalars["String"]>;
  Designation?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Individualtin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  Pershare?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["float8"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["float8"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Taxpayername?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Aggregate = {
  __typename?: "rtcs_db_ura_CombinedOfficersAndOwners_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_CombinedOfficersAndOwners>;
};

/** aggregate fields of "rtcs_db.ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Aggregate_Fields = {
  __typename?: "rtcs_db_ura_CombinedOfficersAndOwners_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ura_CombinedOfficersAndOwners_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ura_CombinedOfficersAndOwners_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Avg_Fields = {
  __typename?: "rtcs_db_ura_CombinedOfficersAndOwners_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
  Pershare?: Maybe<Scalars["Float"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["Float"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Avg_Order_By = {
  Line?: Maybe<Order_By>;
  Pershare?: Maybe<Order_By>;
  Rtnholdingofficersdtlid?: Maybe<Order_By>;
  Rtnholdingownersdtlid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_CombinedOfficersAndOwners". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  Designation?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  Individualtin?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  Pershare?: Maybe<Float8_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Rtnholdingofficersdtlid?: Maybe<Float8_Comparison_Exp>;
  Rtnholdingownersdtlid?: Maybe<Float8_Comparison_Exp>;
  Rtnno?: Maybe<String_Comparison_Exp>;
  Rtnperiodyear?: Maybe<String_Comparison_Exp>;
  Taxpayername?: Maybe<String_Comparison_Exp>;
  Tinno?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
  Pershare?: Maybe<Scalars["float8"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["float8"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  Designation?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Individualtin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  Pershare?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["float8"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["float8"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Taxpayername?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Max_Fields = {
  __typename?: "rtcs_db_ura_CombinedOfficersAndOwners_max_fields";
  Address?: Maybe<Scalars["String"]>;
  Designation?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Individualtin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  Pershare?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["float8"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["float8"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Taxpayername?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Max_Order_By = {
  Address?: Maybe<Order_By>;
  Designation?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Individualtin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  Pershare?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnholdingofficersdtlid?: Maybe<Order_By>;
  Rtnholdingownersdtlid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Taxpayername?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Min_Fields = {
  __typename?: "rtcs_db_ura_CombinedOfficersAndOwners_min_fields";
  Address?: Maybe<Scalars["String"]>;
  Designation?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Individualtin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  Pershare?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["float8"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["float8"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Taxpayername?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Min_Order_By = {
  Address?: Maybe<Order_By>;
  Designation?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Individualtin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  Pershare?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnholdingofficersdtlid?: Maybe<Order_By>;
  Rtnholdingownersdtlid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Taxpayername?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Mutation_Response = {
  __typename?: "rtcs_db_ura_CombinedOfficersAndOwners_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ura_CombinedOfficersAndOwners>;
};

/** input type for inserting object relation for remote table "rtcs_db.ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ura_CombinedOfficersAndOwners_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Order_By = {
  Address?: Maybe<Order_By>;
  Designation?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  Individualtin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  Pershare?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Rtnholdingofficersdtlid?: Maybe<Order_By>;
  Rtnholdingownersdtlid?: Maybe<Order_By>;
  Rtnno?: Maybe<Order_By>;
  Rtnperiodyear?: Maybe<Order_By>;
  Taxpayername?: Maybe<Order_By>;
  Tinno?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_CombinedOfficersAndOwners" */
export enum Rtcs_Db_Ura_CombinedOfficersAndOwners_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  Designation = "Designation",
  /** column name */
  File = "File",
  /** column name */
  Individualtin = "Individualtin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  Pershare = "Pershare",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Rtnholdingofficersdtlid = "Rtnholdingofficersdtlid",
  /** column name */
  Rtnholdingownersdtlid = "Rtnholdingownersdtlid",
  /** column name */
  Rtnno = "Rtnno",
  /** column name */
  Rtnperiodyear = "Rtnperiodyear",
  /** column name */
  Taxpayername = "Taxpayername",
  /** column name */
  Tinno = "Tinno",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  Designation?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  Individualtin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  Pershare?: Maybe<Scalars["float8"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["float8"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["float8"]>;
  Rtnno?: Maybe<Scalars["String"]>;
  Rtnperiodyear?: Maybe<Scalars["String"]>;
  Taxpayername?: Maybe<Scalars["String"]>;
  Tinno?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Stddev_Fields = {
  __typename?: "rtcs_db_ura_CombinedOfficersAndOwners_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
  Pershare?: Maybe<Scalars["Float"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["Float"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
  Pershare?: Maybe<Order_By>;
  Rtnholdingofficersdtlid?: Maybe<Order_By>;
  Rtnholdingownersdtlid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ura_CombinedOfficersAndOwners_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  Pershare?: Maybe<Scalars["Float"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["Float"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  Pershare?: Maybe<Order_By>;
  Rtnholdingofficersdtlid?: Maybe<Order_By>;
  Rtnholdingownersdtlid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ura_CombinedOfficersAndOwners_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  Pershare?: Maybe<Scalars["Float"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["Float"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  Pershare?: Maybe<Order_By>;
  Rtnholdingofficersdtlid?: Maybe<Order_By>;
  Rtnholdingownersdtlid?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Sum_Fields = {
  __typename?: "rtcs_db_ura_CombinedOfficersAndOwners_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
  Pershare?: Maybe<Scalars["float8"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["float8"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Sum_Order_By = {
  Line?: Maybe<Order_By>;
  Pershare?: Maybe<Order_By>;
  Rtnholdingofficersdtlid?: Maybe<Order_By>;
  Rtnholdingownersdtlid?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Var_Pop_Fields = {
  __typename?: "rtcs_db_ura_CombinedOfficersAndOwners_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  Pershare?: Maybe<Scalars["Float"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["Float"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  Pershare?: Maybe<Order_By>;
  Rtnholdingofficersdtlid?: Maybe<Order_By>;
  Rtnholdingownersdtlid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Var_Samp_Fields = {
  __typename?: "rtcs_db_ura_CombinedOfficersAndOwners_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  Pershare?: Maybe<Scalars["Float"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["Float"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  Pershare?: Maybe<Order_By>;
  Rtnholdingofficersdtlid?: Maybe<Order_By>;
  Rtnholdingownersdtlid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Variance_Fields = {
  __typename?: "rtcs_db_ura_CombinedOfficersAndOwners_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
  Pershare?: Maybe<Scalars["Float"]>;
  Rtnholdingofficersdtlid?: Maybe<Scalars["Float"]>;
  Rtnholdingownersdtlid?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ura_CombinedOfficersAndOwners" */
export type Rtcs_Db_Ura_CombinedOfficersAndOwners_Variance_Order_By = {
  Line?: Maybe<Order_By>;
  Pershare?: Maybe<Order_By>;
  Rtnholdingofficersdtlid?: Maybe<Order_By>;
  Rtnholdingownersdtlid?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ura_EmploymentIncomeDetail = {
  __typename?: "rtcs_db_ura_EmploymentIncomeDetail";
  AllwExmptInctax?: Maybe<Scalars["float8"]>;
  AllwNotExempt?: Maybe<Scalars["float8"]>;
  BenefitValue?: Maybe<Scalars["float8"]>;
  EmployerName?: Maybe<Scalars["String"]>;
  EmployerTin?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ProfitLieuSalary?: Maybe<Scalars["float8"]>;
  RtnEmploymentIncmId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnEmploymentIncmId?: Maybe<Scalars["String"]>;
  SalaryExcldAllw?: Maybe<Scalars["float8"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Aggregate = {
  __typename?: "rtcs_db_ura_EmploymentIncomeDetail_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_EmploymentIncomeDetail>;
};

/** aggregate fields of "rtcs_db.ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Aggregate_Fields = {
  __typename?: "rtcs_db_ura_EmploymentIncomeDetail_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ura_EmploymentIncomeDetail_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ura_EmploymentIncomeDetail_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Avg_Fields = {
  __typename?: "rtcs_db_ura_EmploymentIncomeDetail_avg_fields";
  AllwExmptInctax?: Maybe<Scalars["Float"]>;
  AllwNotExempt?: Maybe<Scalars["Float"]>;
  BenefitValue?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ProfitLieuSalary?: Maybe<Scalars["Float"]>;
  SalaryExcldAllw?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Avg_Order_By = {
  AllwExmptInctax?: Maybe<Order_By>;
  AllwNotExempt?: Maybe<Order_By>;
  BenefitValue?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ProfitLieuSalary?: Maybe<Order_By>;
  SalaryExcldAllw?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_EmploymentIncomeDetail". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Bool_Exp = {
  AllwExmptInctax?: Maybe<Float8_Comparison_Exp>;
  AllwNotExempt?: Maybe<Float8_Comparison_Exp>;
  BenefitValue?: Maybe<Float8_Comparison_Exp>;
  EmployerName?: Maybe<String_Comparison_Exp>;
  EmployerTin?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  ProfitLieuSalary?: Maybe<Float8_Comparison_Exp>;
  RtnEmploymentIncmId?: Maybe<String_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnNoRtnEmploymentIncmId?: Maybe<String_Comparison_Exp>;
  SalaryExcldAllw?: Maybe<Float8_Comparison_Exp>;
  SourceType?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Inc_Input = {
  AllwExmptInctax?: Maybe<Scalars["float8"]>;
  AllwNotExempt?: Maybe<Scalars["float8"]>;
  BenefitValue?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ProfitLieuSalary?: Maybe<Scalars["float8"]>;
  SalaryExcldAllw?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Insert_Input = {
  AllwExmptInctax?: Maybe<Scalars["float8"]>;
  AllwNotExempt?: Maybe<Scalars["float8"]>;
  BenefitValue?: Maybe<Scalars["float8"]>;
  EmployerName?: Maybe<Scalars["String"]>;
  EmployerTin?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ProfitLieuSalary?: Maybe<Scalars["float8"]>;
  RtnEmploymentIncmId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnEmploymentIncmId?: Maybe<Scalars["String"]>;
  SalaryExcldAllw?: Maybe<Scalars["float8"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Max_Fields = {
  __typename?: "rtcs_db_ura_EmploymentIncomeDetail_max_fields";
  AllwExmptInctax?: Maybe<Scalars["float8"]>;
  AllwNotExempt?: Maybe<Scalars["float8"]>;
  BenefitValue?: Maybe<Scalars["float8"]>;
  EmployerName?: Maybe<Scalars["String"]>;
  EmployerTin?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ProfitLieuSalary?: Maybe<Scalars["float8"]>;
  RtnEmploymentIncmId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnEmploymentIncmId?: Maybe<Scalars["String"]>;
  SalaryExcldAllw?: Maybe<Scalars["float8"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Max_Order_By = {
  AllwExmptInctax?: Maybe<Order_By>;
  AllwNotExempt?: Maybe<Order_By>;
  BenefitValue?: Maybe<Order_By>;
  EmployerName?: Maybe<Order_By>;
  EmployerTin?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ProfitLieuSalary?: Maybe<Order_By>;
  RtnEmploymentIncmId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnEmploymentIncmId?: Maybe<Order_By>;
  SalaryExcldAllw?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Min_Fields = {
  __typename?: "rtcs_db_ura_EmploymentIncomeDetail_min_fields";
  AllwExmptInctax?: Maybe<Scalars["float8"]>;
  AllwNotExempt?: Maybe<Scalars["float8"]>;
  BenefitValue?: Maybe<Scalars["float8"]>;
  EmployerName?: Maybe<Scalars["String"]>;
  EmployerTin?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ProfitLieuSalary?: Maybe<Scalars["float8"]>;
  RtnEmploymentIncmId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnEmploymentIncmId?: Maybe<Scalars["String"]>;
  SalaryExcldAllw?: Maybe<Scalars["float8"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Min_Order_By = {
  AllwExmptInctax?: Maybe<Order_By>;
  AllwNotExempt?: Maybe<Order_By>;
  BenefitValue?: Maybe<Order_By>;
  EmployerName?: Maybe<Order_By>;
  EmployerTin?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ProfitLieuSalary?: Maybe<Order_By>;
  RtnEmploymentIncmId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnEmploymentIncmId?: Maybe<Order_By>;
  SalaryExcldAllw?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Mutation_Response = {
  __typename?: "rtcs_db_ura_EmploymentIncomeDetail_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ura_EmploymentIncomeDetail>;
};

/** input type for inserting object relation for remote table "rtcs_db.ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ura_EmploymentIncomeDetail_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Order_By = {
  AllwExmptInctax?: Maybe<Order_By>;
  AllwNotExempt?: Maybe<Order_By>;
  BenefitValue?: Maybe<Order_By>;
  EmployerName?: Maybe<Order_By>;
  EmployerTin?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ProfitLieuSalary?: Maybe<Order_By>;
  RtnEmploymentIncmId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnEmploymentIncmId?: Maybe<Order_By>;
  SalaryExcldAllw?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_EmploymentIncomeDetail" */
export enum Rtcs_Db_Ura_EmploymentIncomeDetail_Select_Column {
  /** column name */
  AllwExmptInctax = "AllwExmptInctax",
  /** column name */
  AllwNotExempt = "AllwNotExempt",
  /** column name */
  BenefitValue = "BenefitValue",
  /** column name */
  EmployerName = "EmployerName",
  /** column name */
  EmployerTin = "EmployerTin",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  ProfitLieuSalary = "ProfitLieuSalary",
  /** column name */
  RtnEmploymentIncmId = "RtnEmploymentIncmId",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnNoRtnEmploymentIncmId = "RtnNoRtnEmploymentIncmId",
  /** column name */
  SalaryExcldAllw = "SalaryExcldAllw",
  /** column name */
  SourceType = "SourceType",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Set_Input = {
  AllwExmptInctax?: Maybe<Scalars["float8"]>;
  AllwNotExempt?: Maybe<Scalars["float8"]>;
  BenefitValue?: Maybe<Scalars["float8"]>;
  EmployerName?: Maybe<Scalars["String"]>;
  EmployerTin?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ProfitLieuSalary?: Maybe<Scalars["float8"]>;
  RtnEmploymentIncmId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnEmploymentIncmId?: Maybe<Scalars["String"]>;
  SalaryExcldAllw?: Maybe<Scalars["float8"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Stddev_Fields = {
  __typename?: "rtcs_db_ura_EmploymentIncomeDetail_stddev_fields";
  AllwExmptInctax?: Maybe<Scalars["Float"]>;
  AllwNotExempt?: Maybe<Scalars["Float"]>;
  BenefitValue?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ProfitLieuSalary?: Maybe<Scalars["Float"]>;
  SalaryExcldAllw?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Stddev_Order_By = {
  AllwExmptInctax?: Maybe<Order_By>;
  AllwNotExempt?: Maybe<Order_By>;
  BenefitValue?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ProfitLieuSalary?: Maybe<Order_By>;
  SalaryExcldAllw?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ura_EmploymentIncomeDetail_stddev_pop_fields";
  AllwExmptInctax?: Maybe<Scalars["Float"]>;
  AllwNotExempt?: Maybe<Scalars["Float"]>;
  BenefitValue?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ProfitLieuSalary?: Maybe<Scalars["Float"]>;
  SalaryExcldAllw?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Stddev_Pop_Order_By = {
  AllwExmptInctax?: Maybe<Order_By>;
  AllwNotExempt?: Maybe<Order_By>;
  BenefitValue?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ProfitLieuSalary?: Maybe<Order_By>;
  SalaryExcldAllw?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ura_EmploymentIncomeDetail_stddev_samp_fields";
  AllwExmptInctax?: Maybe<Scalars["Float"]>;
  AllwNotExempt?: Maybe<Scalars["Float"]>;
  BenefitValue?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ProfitLieuSalary?: Maybe<Scalars["Float"]>;
  SalaryExcldAllw?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Stddev_Samp_Order_By = {
  AllwExmptInctax?: Maybe<Order_By>;
  AllwNotExempt?: Maybe<Order_By>;
  BenefitValue?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ProfitLieuSalary?: Maybe<Order_By>;
  SalaryExcldAllw?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Sum_Fields = {
  __typename?: "rtcs_db_ura_EmploymentIncomeDetail_sum_fields";
  AllwExmptInctax?: Maybe<Scalars["float8"]>;
  AllwNotExempt?: Maybe<Scalars["float8"]>;
  BenefitValue?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  ProfitLieuSalary?: Maybe<Scalars["float8"]>;
  SalaryExcldAllw?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Sum_Order_By = {
  AllwExmptInctax?: Maybe<Order_By>;
  AllwNotExempt?: Maybe<Order_By>;
  BenefitValue?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ProfitLieuSalary?: Maybe<Order_By>;
  SalaryExcldAllw?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Var_Pop_Fields = {
  __typename?: "rtcs_db_ura_EmploymentIncomeDetail_var_pop_fields";
  AllwExmptInctax?: Maybe<Scalars["Float"]>;
  AllwNotExempt?: Maybe<Scalars["Float"]>;
  BenefitValue?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ProfitLieuSalary?: Maybe<Scalars["Float"]>;
  SalaryExcldAllw?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Var_Pop_Order_By = {
  AllwExmptInctax?: Maybe<Order_By>;
  AllwNotExempt?: Maybe<Order_By>;
  BenefitValue?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ProfitLieuSalary?: Maybe<Order_By>;
  SalaryExcldAllw?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Var_Samp_Fields = {
  __typename?: "rtcs_db_ura_EmploymentIncomeDetail_var_samp_fields";
  AllwExmptInctax?: Maybe<Scalars["Float"]>;
  AllwNotExempt?: Maybe<Scalars["Float"]>;
  BenefitValue?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ProfitLieuSalary?: Maybe<Scalars["Float"]>;
  SalaryExcldAllw?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Var_Samp_Order_By = {
  AllwExmptInctax?: Maybe<Order_By>;
  AllwNotExempt?: Maybe<Order_By>;
  BenefitValue?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ProfitLieuSalary?: Maybe<Order_By>;
  SalaryExcldAllw?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Variance_Fields = {
  __typename?: "rtcs_db_ura_EmploymentIncomeDetail_variance_fields";
  AllwExmptInctax?: Maybe<Scalars["Float"]>;
  AllwNotExempt?: Maybe<Scalars["Float"]>;
  BenefitValue?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  ProfitLieuSalary?: Maybe<Scalars["Float"]>;
  SalaryExcldAllw?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ura_EmploymentIncomeDetail" */
export type Rtcs_Db_Ura_EmploymentIncomeDetail_Variance_Order_By = {
  AllwExmptInctax?: Maybe<Order_By>;
  AllwNotExempt?: Maybe<Order_By>;
  BenefitValue?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  ProfitLieuSalary?: Maybe<Order_By>;
  SalaryExcldAllw?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage = {
  __typename?: "rtcs_db_ura_GazetteCommercialRatesPerSquareFootage";
  CommercialRentSqm?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  OfficeRentSqm?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Aggregate = {
  __typename?: "rtcs_db_ura_GazetteCommercialRatesPerSquareFootage_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage>;
};

/** aggregate fields of "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Aggregate_Fields =
  {
    __typename?: "rtcs_db_ura_GazetteCommercialRatesPerSquareFootage_aggregate_fields";
    avg?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Avg_Fields>;
    count?: Maybe<Scalars["Int"]>;
    max?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Max_Fields>;
    min?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Min_Fields>;
    stddev?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Stddev_Fields>;
    stddev_pop?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Stddev_Samp_Fields>;
    sum?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Sum_Fields>;
    var_pop?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Var_Pop_Fields>;
    var_samp?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Var_Samp_Fields>;
    variance?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Variance_Fields>;
  };

/** aggregate fields of "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Aggregate_Order_By =
  {
    avg?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Avg_Order_By>;
    count?: Maybe<Order_By>;
    max?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Max_Order_By>;
    min?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Min_Order_By>;
    stddev?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Stddev_Order_By>;
    stddev_pop?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Stddev_Pop_Order_By>;
    stddev_samp?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Stddev_Samp_Order_By>;
    sum?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Sum_Order_By>;
    var_pop?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Var_Pop_Order_By>;
    var_samp?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Var_Samp_Order_By>;
    variance?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Variance_Order_By>;
  };

/** input type for inserting array relation for remote table "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Avg_Fields = {
  __typename?: "rtcs_db_ura_GazetteCommercialRatesPerSquareFootage_avg_fields";
  CommercialRentSqm?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OfficeRentSqm?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Avg_Order_By = {
  CommercialRentSqm?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OfficeRentSqm?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Bool_Exp = {
  CommercialRentSqm?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  OfficeRentSqm?: Maybe<Float8_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Inc_Input = {
  CommercialRentSqm?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  OfficeRentSqm?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Insert_Input = {
  CommercialRentSqm?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  OfficeRentSqm?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Max_Fields = {
  __typename?: "rtcs_db_ura_GazetteCommercialRatesPerSquareFootage_max_fields";
  CommercialRentSqm?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  OfficeRentSqm?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Max_Order_By = {
  CommercialRentSqm?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OfficeRentSqm?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Min_Fields = {
  __typename?: "rtcs_db_ura_GazetteCommercialRatesPerSquareFootage_min_fields";
  CommercialRentSqm?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  OfficeRentSqm?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Min_Order_By = {
  CommercialRentSqm?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OfficeRentSqm?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Mutation_Response =
  {
    __typename?: "rtcs_db_ura_GazetteCommercialRatesPerSquareFootage_mutation_response";
    /** number of affected rows by the mutation */
    affected_rows: Scalars["Int"];
    /** data of the affected rows by the mutation */
    returning: Array<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage>;
  };

/** input type for inserting object relation for remote table "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Order_By = {
  CommercialRentSqm?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OfficeRentSqm?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
export enum Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Select_Column {
  /** column name */
  CommercialRentSqm = "CommercialRentSqm",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  OfficeRentSqm = "OfficeRentSqm",
  /** column name */
  Village = "Village",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Set_Input = {
  CommercialRentSqm?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  OfficeRentSqm?: Maybe<Scalars["float8"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Stddev_Fields = {
  __typename?: "rtcs_db_ura_GazetteCommercialRatesPerSquareFootage_stddev_fields";
  CommercialRentSqm?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  OfficeRentSqm?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Stddev_Order_By =
  {
    CommercialRentSqm?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    OfficeRentSqm?: Maybe<Order_By>;
  };

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Stddev_Pop_Fields =
  {
    __typename?: "rtcs_db_ura_GazetteCommercialRatesPerSquareFootage_stddev_pop_fields";
    CommercialRentSqm?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    OfficeRentSqm?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_pop() on columns of table "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Stddev_Pop_Order_By =
  {
    CommercialRentSqm?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    OfficeRentSqm?: Maybe<Order_By>;
  };

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Stddev_Samp_Fields =
  {
    __typename?: "rtcs_db_ura_GazetteCommercialRatesPerSquareFootage_stddev_samp_fields";
    CommercialRentSqm?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    OfficeRentSqm?: Maybe<Scalars["Float"]>;
  };

/** order by stddev_samp() on columns of table "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Stddev_Samp_Order_By =
  {
    CommercialRentSqm?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    OfficeRentSqm?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Sum_Fields = {
  __typename?: "rtcs_db_ura_GazetteCommercialRatesPerSquareFootage_sum_fields";
  CommercialRentSqm?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  OfficeRentSqm?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Sum_Order_By = {
  CommercialRentSqm?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  OfficeRentSqm?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Var_Pop_Fields =
  {
    __typename?: "rtcs_db_ura_GazetteCommercialRatesPerSquareFootage_var_pop_fields";
    CommercialRentSqm?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    OfficeRentSqm?: Maybe<Scalars["Float"]>;
  };

/** order by var_pop() on columns of table "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Var_Pop_Order_By =
  {
    CommercialRentSqm?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    OfficeRentSqm?: Maybe<Order_By>;
  };

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Var_Samp_Fields =
  {
    __typename?: "rtcs_db_ura_GazetteCommercialRatesPerSquareFootage_var_samp_fields";
    CommercialRentSqm?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    OfficeRentSqm?: Maybe<Scalars["Float"]>;
  };

/** order by var_samp() on columns of table "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Var_Samp_Order_By =
  {
    CommercialRentSqm?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    OfficeRentSqm?: Maybe<Order_By>;
  };

/** aggregate variance on columns */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Variance_Fields =
  {
    __typename?: "rtcs_db_ura_GazetteCommercialRatesPerSquareFootage_variance_fields";
    CommercialRentSqm?: Maybe<Scalars["Float"]>;
    Line?: Maybe<Scalars["Float"]>;
    OfficeRentSqm?: Maybe<Scalars["Float"]>;
  };

/** order by variance() on columns of table "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
export type Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Variance_Order_By =
  {
    CommercialRentSqm?: Maybe<Order_By>;
    Line?: Maybe<Order_By>;
    OfficeRentSqm?: Maybe<Order_By>;
  };

/** columns and relationships of "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom = {
  __typename?: "rtcs_db_ura_GazetteResidentialRatesPerBedroom";
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Aggregate = {
  __typename?: "rtcs_db_ura_GazetteResidentialRatesPerBedroom_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom>;
};

/** aggregate fields of "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Aggregate_Fields = {
  __typename?: "rtcs_db_ura_GazetteResidentialRatesPerBedroom_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Avg_Fields = {
  __typename?: "rtcs_db_ura_GazetteResidentialRatesPerBedroom_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Avg_Order_By = {
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_GazetteResidentialRatesPerBedroom". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Max_Fields = {
  __typename?: "rtcs_db_ura_GazetteResidentialRatesPerBedroom_max_fields";
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Max_Order_By = {
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Min_Fields = {
  __typename?: "rtcs_db_ura_GazetteResidentialRatesPerBedroom_min_fields";
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Min_Order_By = {
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Mutation_Response = {
  __typename?: "rtcs_db_ura_GazetteResidentialRatesPerBedroom_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom>;
};

/** input type for inserting object relation for remote table "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Order_By = {
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
export enum Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Village = "Village",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Stddev_Fields = {
  __typename?: "rtcs_db_ura_GazetteResidentialRatesPerBedroom_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ura_GazetteResidentialRatesPerBedroom_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Stddev_Pop_Order_By =
  {
    Line?: Maybe<Order_By>;
  };

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ura_GazetteResidentialRatesPerBedroom_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Stddev_Samp_Order_By =
  {
    Line?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Sum_Fields = {
  __typename?: "rtcs_db_ura_GazetteResidentialRatesPerBedroom_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Sum_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Var_Pop_Fields = {
  __typename?: "rtcs_db_ura_GazetteResidentialRatesPerBedroom_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Var_Samp_Fields = {
  __typename?: "rtcs_db_ura_GazetteResidentialRatesPerBedroom_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Variance_Fields = {
  __typename?: "rtcs_db_ura_GazetteResidentialRatesPerBedroom_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
export type Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Variance_Order_By = {
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.ura_HoldingOfficers" */
export type Rtcs_Db_Ura_HoldingOfficers = {
  __typename?: "rtcs_db_ura_HoldingOfficers";
  Address?: Maybe<Scalars["String"]>;
  Designation?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  RtnHoldingOfficersDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnHoldingOfficersDtlId?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_HoldingOfficers" */
export type Rtcs_Db_Ura_HoldingOfficers_Aggregate = {
  __typename?: "rtcs_db_ura_HoldingOfficers_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_HoldingOfficers>;
};

/** aggregate fields of "rtcs_db.ura_HoldingOfficers" */
export type Rtcs_Db_Ura_HoldingOfficers_Aggregate_Fields = {
  __typename?: "rtcs_db_ura_HoldingOfficers_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ura_HoldingOfficers" */
export type Rtcs_Db_Ura_HoldingOfficers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ura_HoldingOfficers_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.ura_HoldingOfficers" */
export type Rtcs_Db_Ura_HoldingOfficers_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ura_HoldingOfficers" */
export type Rtcs_Db_Ura_HoldingOfficers_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ura_HoldingOfficers_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ura_HoldingOfficers_Avg_Fields = {
  __typename?: "rtcs_db_ura_HoldingOfficers_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_HoldingOfficers" */
export type Rtcs_Db_Ura_HoldingOfficers_Avg_Order_By = {
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_HoldingOfficers". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_HoldingOfficers_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  Designation?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  RtnHoldingOfficersDtlId?: Maybe<String_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnNoRtnHoldingOfficersDtlId?: Maybe<String_Comparison_Exp>;
  SourceType?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ura_HoldingOfficers_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ura_HoldingOfficers_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.ura_HoldingOfficers" */
export type Rtcs_Db_Ura_HoldingOfficers_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.ura_HoldingOfficers" */
export type Rtcs_Db_Ura_HoldingOfficers_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  Designation?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  RtnHoldingOfficersDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnHoldingOfficersDtlId?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_HoldingOfficers_Max_Fields = {
  __typename?: "rtcs_db_ura_HoldingOfficers_max_fields";
  Address?: Maybe<Scalars["String"]>;
  Designation?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  RtnHoldingOfficersDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnHoldingOfficersDtlId?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_HoldingOfficers" */
export type Rtcs_Db_Ura_HoldingOfficers_Max_Order_By = {
  Address?: Maybe<Order_By>;
  Designation?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  RtnHoldingOfficersDtlId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnHoldingOfficersDtlId?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_HoldingOfficers_Min_Fields = {
  __typename?: "rtcs_db_ura_HoldingOfficers_min_fields";
  Address?: Maybe<Scalars["String"]>;
  Designation?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  RtnHoldingOfficersDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnHoldingOfficersDtlId?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_HoldingOfficers" */
export type Rtcs_Db_Ura_HoldingOfficers_Min_Order_By = {
  Address?: Maybe<Order_By>;
  Designation?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  RtnHoldingOfficersDtlId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnHoldingOfficersDtlId?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_HoldingOfficers" */
export type Rtcs_Db_Ura_HoldingOfficers_Mutation_Response = {
  __typename?: "rtcs_db_ura_HoldingOfficers_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ura_HoldingOfficers>;
};

/** input type for inserting object relation for remote table "rtcs_db.ura_HoldingOfficers" */
export type Rtcs_Db_Ura_HoldingOfficers_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ura_HoldingOfficers_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.ura_HoldingOfficers" */
export type Rtcs_Db_Ura_HoldingOfficers_Order_By = {
  Address?: Maybe<Order_By>;
  Designation?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  RtnHoldingOfficersDtlId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnHoldingOfficersDtlId?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_HoldingOfficers" */
export enum Rtcs_Db_Ura_HoldingOfficers_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  Designation = "Designation",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  RtnHoldingOfficersDtlId = "RtnHoldingOfficersDtlId",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnNoRtnHoldingOfficersDtlId = "RtnNoRtnHoldingOfficersDtlId",
  /** column name */
  SourceType = "SourceType",
  /** column name */
  Tin = "Tin",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_HoldingOfficers" */
export type Rtcs_Db_Ura_HoldingOfficers_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  Designation?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  RtnHoldingOfficersDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnHoldingOfficersDtlId?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_HoldingOfficers_Stddev_Fields = {
  __typename?: "rtcs_db_ura_HoldingOfficers_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_HoldingOfficers" */
export type Rtcs_Db_Ura_HoldingOfficers_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_HoldingOfficers_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ura_HoldingOfficers_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ura_HoldingOfficers" */
export type Rtcs_Db_Ura_HoldingOfficers_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_HoldingOfficers_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ura_HoldingOfficers_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ura_HoldingOfficers" */
export type Rtcs_Db_Ura_HoldingOfficers_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ura_HoldingOfficers_Sum_Fields = {
  __typename?: "rtcs_db_ura_HoldingOfficers_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_HoldingOfficers" */
export type Rtcs_Db_Ura_HoldingOfficers_Sum_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_HoldingOfficers_Var_Pop_Fields = {
  __typename?: "rtcs_db_ura_HoldingOfficers_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ura_HoldingOfficers" */
export type Rtcs_Db_Ura_HoldingOfficers_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_HoldingOfficers_Var_Samp_Fields = {
  __typename?: "rtcs_db_ura_HoldingOfficers_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ura_HoldingOfficers" */
export type Rtcs_Db_Ura_HoldingOfficers_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ura_HoldingOfficers_Variance_Fields = {
  __typename?: "rtcs_db_ura_HoldingOfficers_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ura_HoldingOfficers" */
export type Rtcs_Db_Ura_HoldingOfficers_Variance_Order_By = {
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.ura_HoldingOwner" */
export type Rtcs_Db_Ura_HoldingOwner = {
  __typename?: "rtcs_db_ura_HoldingOwner";
  Address?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  PerShare?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnHoldingOwnersDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnHoldingOwnersDtlId?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_HoldingOwner" */
export type Rtcs_Db_Ura_HoldingOwner_Aggregate = {
  __typename?: "rtcs_db_ura_HoldingOwner_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_HoldingOwner_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_HoldingOwner>;
};

/** aggregate fields of "rtcs_db.ura_HoldingOwner" */
export type Rtcs_Db_Ura_HoldingOwner_Aggregate_Fields = {
  __typename?: "rtcs_db_ura_HoldingOwner_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ura_HoldingOwner_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ura_HoldingOwner_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ura_HoldingOwner_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ura_HoldingOwner_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_HoldingOwner_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_HoldingOwner_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ura_HoldingOwner_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ura_HoldingOwner_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ura_HoldingOwner_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ura_HoldingOwner_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ura_HoldingOwner" */
export type Rtcs_Db_Ura_HoldingOwner_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ura_HoldingOwner_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.ura_HoldingOwner" */
export type Rtcs_Db_Ura_HoldingOwner_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ura_HoldingOwner_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ura_HoldingOwner_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ura_HoldingOwner_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ura_HoldingOwner_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_HoldingOwner_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_HoldingOwner_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ura_HoldingOwner_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ura_HoldingOwner_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ura_HoldingOwner_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ura_HoldingOwner_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ura_HoldingOwner" */
export type Rtcs_Db_Ura_HoldingOwner_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ura_HoldingOwner_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ura_HoldingOwner_Avg_Fields = {
  __typename?: "rtcs_db_ura_HoldingOwner_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
  PerShare?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_HoldingOwner" */
export type Rtcs_Db_Ura_HoldingOwner_Avg_Order_By = {
  Line?: Maybe<Order_By>;
  PerShare?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_HoldingOwner". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_HoldingOwner_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  PerShare?: Maybe<Float8_Comparison_Exp>;
  ReturnType?: Maybe<String_Comparison_Exp>;
  RtnHoldingOwnersDtlId?: Maybe<String_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnNoRtnHoldingOwnersDtlId?: Maybe<String_Comparison_Exp>;
  Tin?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ura_HoldingOwner_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ura_HoldingOwner_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ura_HoldingOwner_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.ura_HoldingOwner" */
export type Rtcs_Db_Ura_HoldingOwner_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
  PerShare?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.ura_HoldingOwner" */
export type Rtcs_Db_Ura_HoldingOwner_Insert_Input = {
  Address?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  PerShare?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnHoldingOwnersDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnHoldingOwnersDtlId?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_HoldingOwner_Max_Fields = {
  __typename?: "rtcs_db_ura_HoldingOwner_max_fields";
  Address?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  PerShare?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnHoldingOwnersDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnHoldingOwnersDtlId?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_HoldingOwner" */
export type Rtcs_Db_Ura_HoldingOwner_Max_Order_By = {
  Address?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  PerShare?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RtnHoldingOwnersDtlId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnHoldingOwnersDtlId?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_HoldingOwner_Min_Fields = {
  __typename?: "rtcs_db_ura_HoldingOwner_min_fields";
  Address?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  PerShare?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnHoldingOwnersDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnHoldingOwnersDtlId?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_HoldingOwner" */
export type Rtcs_Db_Ura_HoldingOwner_Min_Order_By = {
  Address?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  PerShare?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RtnHoldingOwnersDtlId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnHoldingOwnersDtlId?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_HoldingOwner" */
export type Rtcs_Db_Ura_HoldingOwner_Mutation_Response = {
  __typename?: "rtcs_db_ura_HoldingOwner_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ura_HoldingOwner>;
};

/** input type for inserting object relation for remote table "rtcs_db.ura_HoldingOwner" */
export type Rtcs_Db_Ura_HoldingOwner_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ura_HoldingOwner_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.ura_HoldingOwner" */
export type Rtcs_Db_Ura_HoldingOwner_Order_By = {
  Address?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  PerShare?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RtnHoldingOwnersDtlId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnHoldingOwnersDtlId?: Maybe<Order_By>;
  Tin?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_HoldingOwner" */
export enum Rtcs_Db_Ura_HoldingOwner_Select_Column {
  /** column name */
  Address = "Address",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  PerShare = "PerShare",
  /** column name */
  ReturnType = "ReturnType",
  /** column name */
  RtnHoldingOwnersDtlId = "RtnHoldingOwnersDtlId",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnNoRtnHoldingOwnersDtlId = "RtnNoRtnHoldingOwnersDtlId",
  /** column name */
  Tin = "Tin",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_HoldingOwner" */
export type Rtcs_Db_Ura_HoldingOwner_Set_Input = {
  Address?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  PerShare?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnHoldingOwnersDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnHoldingOwnersDtlId?: Maybe<Scalars["String"]>;
  Tin?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_HoldingOwner_Stddev_Fields = {
  __typename?: "rtcs_db_ura_HoldingOwner_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
  PerShare?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_HoldingOwner" */
export type Rtcs_Db_Ura_HoldingOwner_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
  PerShare?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_HoldingOwner_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ura_HoldingOwner_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  PerShare?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ura_HoldingOwner" */
export type Rtcs_Db_Ura_HoldingOwner_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  PerShare?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_HoldingOwner_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ura_HoldingOwner_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  PerShare?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ura_HoldingOwner" */
export type Rtcs_Db_Ura_HoldingOwner_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  PerShare?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ura_HoldingOwner_Sum_Fields = {
  __typename?: "rtcs_db_ura_HoldingOwner_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
  PerShare?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_HoldingOwner" */
export type Rtcs_Db_Ura_HoldingOwner_Sum_Order_By = {
  Line?: Maybe<Order_By>;
  PerShare?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_HoldingOwner_Var_Pop_Fields = {
  __typename?: "rtcs_db_ura_HoldingOwner_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  PerShare?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ura_HoldingOwner" */
export type Rtcs_Db_Ura_HoldingOwner_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  PerShare?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_HoldingOwner_Var_Samp_Fields = {
  __typename?: "rtcs_db_ura_HoldingOwner_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  PerShare?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ura_HoldingOwner" */
export type Rtcs_Db_Ura_HoldingOwner_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  PerShare?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ura_HoldingOwner_Variance_Fields = {
  __typename?: "rtcs_db_ura_HoldingOwner_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
  PerShare?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ura_HoldingOwner" */
export type Rtcs_Db_Ura_HoldingOwner_Variance_Order_By = {
  Line?: Maybe<Order_By>;
  PerShare?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.ura_IncomeTaxHolding" */
export type Rtcs_Db_Ura_IncomeTaxHolding = {
  __typename?: "rtcs_db_ura_IncomeTaxHolding";
  File?: Maybe<Scalars["String"]>;
  HoldingCmpnyAddress?: Maybe<Scalars["String"]>;
  HoldingCmpnyName?: Maybe<Scalars["String"]>;
  HoldingCmpnyTin?: Maybe<Scalars["String"]>;
  HoldingCompanyStatus?: Maybe<Scalars["String"]>;
  HoldingStatus?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RtnHoldingStatusDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_IncomeTaxHolding" */
export type Rtcs_Db_Ura_IncomeTaxHolding_Aggregate = {
  __typename?: "rtcs_db_ura_IncomeTaxHolding_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_IncomeTaxHolding>;
};

/** aggregate fields of "rtcs_db.ura_IncomeTaxHolding" */
export type Rtcs_Db_Ura_IncomeTaxHolding_Aggregate_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxHolding_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ura_IncomeTaxHolding" */
export type Rtcs_Db_Ura_IncomeTaxHolding_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ura_IncomeTaxHolding_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.ura_IncomeTaxHolding" */
export type Rtcs_Db_Ura_IncomeTaxHolding_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ura_IncomeTaxHolding" */
export type Rtcs_Db_Ura_IncomeTaxHolding_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ura_IncomeTaxHolding_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ura_IncomeTaxHolding_Avg_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxHolding_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_IncomeTaxHolding" */
export type Rtcs_Db_Ura_IncomeTaxHolding_Avg_Order_By = {
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_IncomeTaxHolding". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_IncomeTaxHolding_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  HoldingCmpnyAddress?: Maybe<String_Comparison_Exp>;
  HoldingCmpnyName?: Maybe<String_Comparison_Exp>;
  HoldingCmpnyTin?: Maybe<String_Comparison_Exp>;
  HoldingCompanyStatus?: Maybe<String_Comparison_Exp>;
  HoldingStatus?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  RtnHoldingStatusDtlId?: Maybe<String_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  SourceType?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.ura_IncomeTaxHolding" */
export type Rtcs_Db_Ura_IncomeTaxHolding_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.ura_IncomeTaxHolding" */
export type Rtcs_Db_Ura_IncomeTaxHolding_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  HoldingCmpnyAddress?: Maybe<Scalars["String"]>;
  HoldingCmpnyName?: Maybe<Scalars["String"]>;
  HoldingCmpnyTin?: Maybe<Scalars["String"]>;
  HoldingCompanyStatus?: Maybe<Scalars["String"]>;
  HoldingStatus?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RtnHoldingStatusDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_IncomeTaxHolding_Max_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxHolding_max_fields";
  File?: Maybe<Scalars["String"]>;
  HoldingCmpnyAddress?: Maybe<Scalars["String"]>;
  HoldingCmpnyName?: Maybe<Scalars["String"]>;
  HoldingCmpnyTin?: Maybe<Scalars["String"]>;
  HoldingCompanyStatus?: Maybe<Scalars["String"]>;
  HoldingStatus?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RtnHoldingStatusDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_IncomeTaxHolding" */
export type Rtcs_Db_Ura_IncomeTaxHolding_Max_Order_By = {
  File?: Maybe<Order_By>;
  HoldingCmpnyAddress?: Maybe<Order_By>;
  HoldingCmpnyName?: Maybe<Order_By>;
  HoldingCmpnyTin?: Maybe<Order_By>;
  HoldingCompanyStatus?: Maybe<Order_By>;
  HoldingStatus?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RtnHoldingStatusDtlId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_IncomeTaxHolding_Min_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxHolding_min_fields";
  File?: Maybe<Scalars["String"]>;
  HoldingCmpnyAddress?: Maybe<Scalars["String"]>;
  HoldingCmpnyName?: Maybe<Scalars["String"]>;
  HoldingCmpnyTin?: Maybe<Scalars["String"]>;
  HoldingCompanyStatus?: Maybe<Scalars["String"]>;
  HoldingStatus?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RtnHoldingStatusDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_IncomeTaxHolding" */
export type Rtcs_Db_Ura_IncomeTaxHolding_Min_Order_By = {
  File?: Maybe<Order_By>;
  HoldingCmpnyAddress?: Maybe<Order_By>;
  HoldingCmpnyName?: Maybe<Order_By>;
  HoldingCmpnyTin?: Maybe<Order_By>;
  HoldingCompanyStatus?: Maybe<Order_By>;
  HoldingStatus?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RtnHoldingStatusDtlId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_IncomeTaxHolding" */
export type Rtcs_Db_Ura_IncomeTaxHolding_Mutation_Response = {
  __typename?: "rtcs_db_ura_IncomeTaxHolding_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ura_IncomeTaxHolding>;
};

/** input type for inserting object relation for remote table "rtcs_db.ura_IncomeTaxHolding" */
export type Rtcs_Db_Ura_IncomeTaxHolding_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ura_IncomeTaxHolding_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.ura_IncomeTaxHolding" */
export type Rtcs_Db_Ura_IncomeTaxHolding_Order_By = {
  File?: Maybe<Order_By>;
  HoldingCmpnyAddress?: Maybe<Order_By>;
  HoldingCmpnyName?: Maybe<Order_By>;
  HoldingCmpnyTin?: Maybe<Order_By>;
  HoldingCompanyStatus?: Maybe<Order_By>;
  HoldingStatus?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RtnHoldingStatusDtlId?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_IncomeTaxHolding" */
export enum Rtcs_Db_Ura_IncomeTaxHolding_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  HoldingCmpnyAddress = "HoldingCmpnyAddress",
  /** column name */
  HoldingCmpnyName = "HoldingCmpnyName",
  /** column name */
  HoldingCmpnyTin = "HoldingCmpnyTin",
  /** column name */
  HoldingCompanyStatus = "HoldingCompanyStatus",
  /** column name */
  HoldingStatus = "HoldingStatus",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  RtnHoldingStatusDtlId = "RtnHoldingStatusDtlId",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  SourceType = "SourceType",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_IncomeTaxHolding" */
export type Rtcs_Db_Ura_IncomeTaxHolding_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  HoldingCmpnyAddress?: Maybe<Scalars["String"]>;
  HoldingCmpnyName?: Maybe<Scalars["String"]>;
  HoldingCmpnyTin?: Maybe<Scalars["String"]>;
  HoldingCompanyStatus?: Maybe<Scalars["String"]>;
  HoldingStatus?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RtnHoldingStatusDtlId?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_IncomeTaxHolding_Stddev_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxHolding_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_IncomeTaxHolding" */
export type Rtcs_Db_Ura_IncomeTaxHolding_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_IncomeTaxHolding_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxHolding_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ura_IncomeTaxHolding" */
export type Rtcs_Db_Ura_IncomeTaxHolding_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_IncomeTaxHolding_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxHolding_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ura_IncomeTaxHolding" */
export type Rtcs_Db_Ura_IncomeTaxHolding_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ura_IncomeTaxHolding_Sum_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxHolding_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_IncomeTaxHolding" */
export type Rtcs_Db_Ura_IncomeTaxHolding_Sum_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_IncomeTaxHolding_Var_Pop_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxHolding_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ura_IncomeTaxHolding" */
export type Rtcs_Db_Ura_IncomeTaxHolding_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_IncomeTaxHolding_Var_Samp_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxHolding_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ura_IncomeTaxHolding" */
export type Rtcs_Db_Ura_IncomeTaxHolding_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ura_IncomeTaxHolding_Variance_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxHolding_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ura_IncomeTaxHolding" */
export type Rtcs_Db_Ura_IncomeTaxHolding_Variance_Order_By = {
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryIndividual";
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Aggregate = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryIndividual_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_IncomeTaxSummaryIndividual>;
};

/** aggregate fields of "rtcs_db.ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Aggregate_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryIndividual_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Avg_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryIndividual_avg_fields";
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Avg_Order_By = {
  ChargeableIncome?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_IncomeTaxSummaryIndividual". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Bool_Exp = {
  ChargeableIncome?: Maybe<Float8_Comparison_Exp>;
  EmplymntIncome?: Maybe<Float8_Comparison_Exp>;
  EmplymntRate?: Maybe<Float8_Comparison_Exp>;
  EmplymntTax?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  GrossRentalIncome?: Maybe<Float8_Comparison_Exp>;
  IsAmendment?: Maybe<String_Comparison_Exp>;
  IsResident?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  PropincIncome?: Maybe<Float8_Comparison_Exp>;
  PropincRate?: Maybe<Float8_Comparison_Exp>;
  PropincTax?: Maybe<Float8_Comparison_Exp>;
  RentincRate?: Maybe<Float8_Comparison_Exp>;
  RentincTax?: Maybe<Float8_Comparison_Exp>;
  RtnDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnFromDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnPeriodYear?: Maybe<String_Comparison_Exp>;
  RtnPeriodYearGenerated?: Maybe<String_Comparison_Exp>;
  RtnToDt?: Maybe<Timestamptz_Comparison_Exp>;
  SourceType?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  TotIncome?: Maybe<Float8_Comparison_Exp>;
  TotRate?: Maybe<Float8_Comparison_Exp>;
  TotTax?: Maybe<Float8_Comparison_Exp>;
  Uuid?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Inc_Input = {
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Insert_Input = {
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Max_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryIndividual_max_fields";
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Max_Order_By = {
  ChargeableIncome?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnPeriodYearGenerated?: Maybe<Order_By>;
  RtnToDt?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Min_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryIndividual_min_fields";
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Min_Order_By = {
  ChargeableIncome?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnPeriodYearGenerated?: Maybe<Order_By>;
  RtnToDt?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Mutation_Response = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryIndividual_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ura_IncomeTaxSummaryIndividual>;
};

/** input type for inserting object relation for remote table "rtcs_db.ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Order_By = {
  ChargeableIncome?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnPeriodYearGenerated?: Maybe<Order_By>;
  RtnToDt?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_IncomeTaxSummaryIndividual" */
export enum Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Select_Column {
  /** column name */
  ChargeableIncome = "ChargeableIncome",
  /** column name */
  EmplymntIncome = "EmplymntIncome",
  /** column name */
  EmplymntRate = "EmplymntRate",
  /** column name */
  EmplymntTax = "EmplymntTax",
  /** column name */
  File = "File",
  /** column name */
  GrossRentalIncome = "GrossRentalIncome",
  /** column name */
  IsAmendment = "IsAmendment",
  /** column name */
  IsResident = "IsResident",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  PropincIncome = "PropincIncome",
  /** column name */
  PropincRate = "PropincRate",
  /** column name */
  PropincTax = "PropincTax",
  /** column name */
  RentincRate = "RentincRate",
  /** column name */
  RentincTax = "RentincTax",
  /** column name */
  RtnDt = "RtnDt",
  /** column name */
  RtnFromDt = "RtnFromDt",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnPeriodYear = "RtnPeriodYear",
  /** column name */
  RtnPeriodYearGenerated = "RtnPeriodYearGenerated",
  /** column name */
  RtnToDt = "RtnToDt",
  /** column name */
  SourceType = "SourceType",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  TotIncome = "TotIncome",
  /** column name */
  TotRate = "TotRate",
  /** column name */
  TotTax = "TotTax",
  /** column name */
  Uuid = "Uuid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Set_Input = {
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Stddev_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryIndividual_stddev_fields";
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Stddev_Order_By = {
  ChargeableIncome?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryIndividual_stddev_pop_fields";
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Stddev_Pop_Order_By = {
  ChargeableIncome?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryIndividual_stddev_samp_fields";
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Stddev_Samp_Order_By = {
  ChargeableIncome?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Sum_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryIndividual_sum_fields";
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  EmplymntIncome?: Maybe<Scalars["float8"]>;
  EmplymntRate?: Maybe<Scalars["float8"]>;
  EmplymntTax?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PropincIncome?: Maybe<Scalars["float8"]>;
  PropincRate?: Maybe<Scalars["float8"]>;
  PropincTax?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Sum_Order_By = {
  ChargeableIncome?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Var_Pop_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryIndividual_var_pop_fields";
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Var_Pop_Order_By = {
  ChargeableIncome?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Var_Samp_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryIndividual_var_samp_fields";
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Var_Samp_Order_By = {
  ChargeableIncome?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Variance_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryIndividual_variance_fields";
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  EmplymntIncome?: Maybe<Scalars["Float"]>;
  EmplymntRate?: Maybe<Scalars["Float"]>;
  EmplymntTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  PropincIncome?: Maybe<Scalars["Float"]>;
  PropincRate?: Maybe<Scalars["Float"]>;
  PropincTax?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ura_IncomeTaxSummaryIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Variance_Order_By = {
  ChargeableIncome?: Maybe<Order_By>;
  EmplymntIncome?: Maybe<Order_By>;
  EmplymntRate?: Maybe<Order_By>;
  EmplymntTax?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PropincIncome?: Maybe<Order_By>;
  PropincRate?: Maybe<Order_By>;
  PropincTax?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryNonIndividual";
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BalanceCarryForward?: Maybe<Scalars["String"]>;
  BalancePrevPeriod?: Maybe<Scalars["String"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["String"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["String"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalReserve?: Maybe<Scalars["String"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Aggregate = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryNonIndividual_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual>;
};

/** aggregate fields of "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Aggregate_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryNonIndividual_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Avg_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryNonIndividual_avg_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Avg_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_IncomeTaxSummaryNonIndividual". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Bool_Exp = {
  AdjProfitLossRental?: Maybe<Float8_Comparison_Exp>;
  AdjRentlIncmLoss?: Maybe<Float8_Comparison_Exp>;
  AdministratingExp?: Maybe<Float8_Comparison_Exp>;
  AllowblApportionedCap?: Maybe<Float8_Comparison_Exp>;
  AssessedChrgblRentlIncm?: Maybe<Float8_Comparison_Exp>;
  AssessedRentlLoss?: Maybe<Float8_Comparison_Exp>;
  BadDebtWriteOff?: Maybe<Float8_Comparison_Exp>;
  BalanceCarryForward?: Maybe<String_Comparison_Exp>;
  BalancePrevPeriod?: Maybe<String_Comparison_Exp>;
  BrghtFwdAssessedLoss?: Maybe<Float8_Comparison_Exp>;
  BusinessIncome?: Maybe<Float8_Comparison_Exp>;
  BusinessRate?: Maybe<Float8_Comparison_Exp>;
  BusinessTax?: Maybe<Float8_Comparison_Exp>;
  ChargeableIncome?: Maybe<Float8_Comparison_Exp>;
  CostOfSales?: Maybe<Float8_Comparison_Exp>;
  Debenture?: Maybe<Float8_Comparison_Exp>;
  DeferTaxLiability?: Maybe<Float8_Comparison_Exp>;
  Depreciation?: Maybe<Float8_Comparison_Exp>;
  EquityShare?: Maybe<Float8_Comparison_Exp>;
  FactoryRentRates?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FinanceExp?: Maybe<Float8_Comparison_Exp>;
  FixedDeposit?: Maybe<Float8_Comparison_Exp>;
  GovtSecurities?: Maybe<String_Comparison_Exp>;
  GrossProfit?: Maybe<Float8_Comparison_Exp>;
  GrossRentalIncome?: Maybe<Float8_Comparison_Exp>;
  IsAmendment?: Maybe<String_Comparison_Exp>;
  IsResident?: Maybe<String_Comparison_Exp>;
  LandBuild?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  NonAllowblExp?: Maybe<Float8_Comparison_Exp>;
  OperatingExp?: Maybe<Float8_Comparison_Exp>;
  OtherInvestments?: Maybe<Float8_Comparison_Exp>;
  OthrAllowblExp?: Maybe<Float8_Comparison_Exp>;
  PayableAmtInc?: Maybe<Float8_Comparison_Exp>;
  PayableAmtRent?: Maybe<Float8_Comparison_Exp>;
  ProfitAfterDividend?: Maybe<Float8_Comparison_Exp>;
  ProfitAfterTax?: Maybe<Float8_Comparison_Exp>;
  ProfitBeforeTax?: Maybe<Float8_Comparison_Exp>;
  ProfitLossRental?: Maybe<Float8_Comparison_Exp>;
  ProposedDividend?: Maybe<Float8_Comparison_Exp>;
  ProvisionIncomeTax?: Maybe<Float8_Comparison_Exp>;
  RefundAmtInc?: Maybe<Float8_Comparison_Exp>;
  RefundAmtRent?: Maybe<Float8_Comparison_Exp>;
  RentRates?: Maybe<Float8_Comparison_Exp>;
  RentincIncome?: Maybe<Float8_Comparison_Exp>;
  RentincRate?: Maybe<Float8_Comparison_Exp>;
  RentincTax?: Maybe<Float8_Comparison_Exp>;
  RtnDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnFromDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnPeriodYear?: Maybe<String_Comparison_Exp>;
  RtnPeriodYearGenerated?: Maybe<String_Comparison_Exp>;
  SecureTotLoan?: Maybe<String_Comparison_Exp>;
  SelfAssessTaxPaidInc?: Maybe<Float8_Comparison_Exp>;
  SelfAssessTaxPaidRent?: Maybe<Float8_Comparison_Exp>;
  SourceFund?: Maybe<String_Comparison_Exp>;
  SourceType?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  TotAdminExpense?: Maybe<Float8_Comparison_Exp>;
  TotBsnsProfSales?: Maybe<Float8_Comparison_Exp>;
  TotExpPurelyRental?: Maybe<Float8_Comparison_Exp>;
  TotFinancialExpense?: Maybe<Float8_Comparison_Exp>;
  TotFixedAsset?: Maybe<String_Comparison_Exp>;
  TotIncome?: Maybe<Float8_Comparison_Exp>;
  TotNonChargeableIncome?: Maybe<Float8_Comparison_Exp>;
  TotOperationExpense?: Maybe<Float8_Comparison_Exp>;
  TotOtherExpense?: Maybe<Float8_Comparison_Exp>;
  TotRate?: Maybe<Float8_Comparison_Exp>;
  TotRentAdminiExpns?: Maybe<Float8_Comparison_Exp>;
  TotRentFinancExpns?: Maybe<Float8_Comparison_Exp>;
  TotRentIncmExpns?: Maybe<Float8_Comparison_Exp>;
  TotRentOprtgExpns?: Maybe<Float8_Comparison_Exp>;
  TotTax?: Maybe<Float8_Comparison_Exp>;
  TotTaxLiability?: Maybe<Float8_Comparison_Exp>;
  TotTaxLiabilityInc?: Maybe<Float8_Comparison_Exp>;
  TotTaxLiabilityRent?: Maybe<Float8_Comparison_Exp>;
  TotTaxesPaidInc?: Maybe<Float8_Comparison_Exp>;
  TotTaxesPaidRent?: Maybe<Float8_Comparison_Exp>;
  TotTradeInvestment?: Maybe<String_Comparison_Exp>;
  TotWthdTaxDeduct?: Maybe<Float8_Comparison_Exp>;
  TotalPropFund?: Maybe<String_Comparison_Exp>;
  TotalReserve?: Maybe<String_Comparison_Exp>;
  TotalShareCapital?: Maybe<Float8_Comparison_Exp>;
  UnsecureTotLoan?: Maybe<String_Comparison_Exp>;
  Uuid?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Inc_Input = {
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Insert_Input = {
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BalanceCarryForward?: Maybe<Scalars["String"]>;
  BalancePrevPeriod?: Maybe<Scalars["String"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["String"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["String"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalReserve?: Maybe<Scalars["String"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Max_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryNonIndividual_max_fields";
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BalanceCarryForward?: Maybe<Scalars["String"]>;
  BalancePrevPeriod?: Maybe<Scalars["String"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["String"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["String"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalReserve?: Maybe<Scalars["String"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Max_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BalanceCarryForward?: Maybe<Order_By>;
  BalancePrevPeriod?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnPeriodYearGenerated?: Maybe<Order_By>;
  SecureTotLoan?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  SourceFund?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalPropFund?: Maybe<Order_By>;
  TotalReserve?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
  UnsecureTotLoan?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Min_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryNonIndividual_min_fields";
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BalanceCarryForward?: Maybe<Scalars["String"]>;
  BalancePrevPeriod?: Maybe<Scalars["String"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["String"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["String"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalReserve?: Maybe<Scalars["String"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Min_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BalanceCarryForward?: Maybe<Order_By>;
  BalancePrevPeriod?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnPeriodYearGenerated?: Maybe<Order_By>;
  SecureTotLoan?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  SourceFund?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalPropFund?: Maybe<Order_By>;
  TotalReserve?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
  UnsecureTotLoan?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Mutation_Response = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryNonIndividual_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual>;
};

/** input type for inserting object relation for remote table "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BalanceCarryForward?: Maybe<Order_By>;
  BalancePrevPeriod?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GovtSecurities?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  LandBuild?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnPeriodYearGenerated?: Maybe<Order_By>;
  SecureTotLoan?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  SourceFund?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotFixedAsset?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotTradeInvestment?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalPropFund?: Maybe<Order_By>;
  TotalReserve?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
  UnsecureTotLoan?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
export enum Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Select_Column {
  /** column name */
  AdjProfitLossRental = "AdjProfitLossRental",
  /** column name */
  AdjRentlIncmLoss = "AdjRentlIncmLoss",
  /** column name */
  AdministratingExp = "AdministratingExp",
  /** column name */
  AllowblApportionedCap = "AllowblApportionedCap",
  /** column name */
  AssessedChrgblRentlIncm = "AssessedChrgblRentlIncm",
  /** column name */
  AssessedRentlLoss = "AssessedRentlLoss",
  /** column name */
  BadDebtWriteOff = "BadDebtWriteOff",
  /** column name */
  BalanceCarryForward = "BalanceCarryForward",
  /** column name */
  BalancePrevPeriod = "BalancePrevPeriod",
  /** column name */
  BrghtFwdAssessedLoss = "BrghtFwdAssessedLoss",
  /** column name */
  BusinessIncome = "BusinessIncome",
  /** column name */
  BusinessRate = "BusinessRate",
  /** column name */
  BusinessTax = "BusinessTax",
  /** column name */
  ChargeableIncome = "ChargeableIncome",
  /** column name */
  CostOfSales = "CostOfSales",
  /** column name */
  Debenture = "Debenture",
  /** column name */
  DeferTaxLiability = "DeferTaxLiability",
  /** column name */
  Depreciation = "Depreciation",
  /** column name */
  EquityShare = "EquityShare",
  /** column name */
  FactoryRentRates = "FactoryRentRates",
  /** column name */
  File = "File",
  /** column name */
  FinanceExp = "FinanceExp",
  /** column name */
  FixedDeposit = "FixedDeposit",
  /** column name */
  GovtSecurities = "GovtSecurities",
  /** column name */
  GrossProfit = "GrossProfit",
  /** column name */
  GrossRentalIncome = "GrossRentalIncome",
  /** column name */
  IsAmendment = "IsAmendment",
  /** column name */
  IsResident = "IsResident",
  /** column name */
  LandBuild = "LandBuild",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  NonAllowblExp = "NonAllowblExp",
  /** column name */
  OperatingExp = "OperatingExp",
  /** column name */
  OtherInvestments = "OtherInvestments",
  /** column name */
  OthrAllowblExp = "OthrAllowblExp",
  /** column name */
  PayableAmtInc = "PayableAmtInc",
  /** column name */
  PayableAmtRent = "PayableAmtRent",
  /** column name */
  ProfitAfterDividend = "ProfitAfterDividend",
  /** column name */
  ProfitAfterTax = "ProfitAfterTax",
  /** column name */
  ProfitBeforeTax = "ProfitBeforeTax",
  /** column name */
  ProfitLossRental = "ProfitLossRental",
  /** column name */
  ProposedDividend = "ProposedDividend",
  /** column name */
  ProvisionIncomeTax = "ProvisionIncomeTax",
  /** column name */
  RefundAmtInc = "RefundAmtInc",
  /** column name */
  RefundAmtRent = "RefundAmtRent",
  /** column name */
  RentRates = "RentRates",
  /** column name */
  RentincIncome = "RentincIncome",
  /** column name */
  RentincRate = "RentincRate",
  /** column name */
  RentincTax = "RentincTax",
  /** column name */
  RtnDt = "RtnDt",
  /** column name */
  RtnFromDt = "RtnFromDt",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnPeriodYear = "RtnPeriodYear",
  /** column name */
  RtnPeriodYearGenerated = "RtnPeriodYearGenerated",
  /** column name */
  SecureTotLoan = "SecureTotLoan",
  /** column name */
  SelfAssessTaxPaidInc = "SelfAssessTaxPaidInc",
  /** column name */
  SelfAssessTaxPaidRent = "SelfAssessTaxPaidRent",
  /** column name */
  SourceFund = "SourceFund",
  /** column name */
  SourceType = "SourceType",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  TotAdminExpense = "TotAdminExpense",
  /** column name */
  TotBsnsProfSales = "TotBsnsProfSales",
  /** column name */
  TotExpPurelyRental = "TotExpPurelyRental",
  /** column name */
  TotFinancialExpense = "TotFinancialExpense",
  /** column name */
  TotFixedAsset = "TotFixedAsset",
  /** column name */
  TotIncome = "TotIncome",
  /** column name */
  TotNonChargeableIncome = "TotNonChargeableIncome",
  /** column name */
  TotOperationExpense = "TotOperationExpense",
  /** column name */
  TotOtherExpense = "TotOtherExpense",
  /** column name */
  TotRate = "TotRate",
  /** column name */
  TotRentAdminiExpns = "TotRentAdminiExpns",
  /** column name */
  TotRentFinancExpns = "TotRentFinancExpns",
  /** column name */
  TotRentIncmExpns = "TotRentIncmExpns",
  /** column name */
  TotRentOprtgExpns = "TotRentOprtgExpns",
  /** column name */
  TotTax = "TotTax",
  /** column name */
  TotTaxLiability = "TotTaxLiability",
  /** column name */
  TotTaxLiabilityInc = "TotTaxLiabilityInc",
  /** column name */
  TotTaxLiabilityRent = "TotTaxLiabilityRent",
  /** column name */
  TotTaxesPaidInc = "TotTaxesPaidInc",
  /** column name */
  TotTaxesPaidRent = "TotTaxesPaidRent",
  /** column name */
  TotTradeInvestment = "TotTradeInvestment",
  /** column name */
  TotWthdTaxDeduct = "TotWthdTaxDeduct",
  /** column name */
  TotalPropFund = "TotalPropFund",
  /** column name */
  TotalReserve = "TotalReserve",
  /** column name */
  TotalShareCapital = "TotalShareCapital",
  /** column name */
  UnsecureTotLoan = "UnsecureTotLoan",
  /** column name */
  Uuid = "Uuid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Set_Input = {
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BalanceCarryForward?: Maybe<Scalars["String"]>;
  BalancePrevPeriod?: Maybe<Scalars["String"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GovtSecurities?: Maybe<Scalars["String"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LandBuild?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnPeriodYearGenerated?: Maybe<Scalars["String"]>;
  SecureTotLoan?: Maybe<Scalars["String"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  SourceFund?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotFixedAsset?: Maybe<Scalars["String"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotTradeInvestment?: Maybe<Scalars["String"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalPropFund?: Maybe<Scalars["String"]>;
  TotalReserve?: Maybe<Scalars["String"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
  UnsecureTotLoan?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Stddev_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryNonIndividual_stddev_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Stddev_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryNonIndividual_stddev_pop_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Stddev_Pop_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryNonIndividual_stddev_samp_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Stddev_Samp_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Sum_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryNonIndividual_sum_fields";
  AdjProfitLossRental?: Maybe<Scalars["float8"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["float8"]>;
  AdministratingExp?: Maybe<Scalars["float8"]>;
  AllowblApportionedCap?: Maybe<Scalars["float8"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["float8"]>;
  AssessedRentlLoss?: Maybe<Scalars["float8"]>;
  BadDebtWriteOff?: Maybe<Scalars["float8"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["float8"]>;
  BusinessIncome?: Maybe<Scalars["float8"]>;
  BusinessRate?: Maybe<Scalars["float8"]>;
  BusinessTax?: Maybe<Scalars["float8"]>;
  ChargeableIncome?: Maybe<Scalars["float8"]>;
  CostOfSales?: Maybe<Scalars["float8"]>;
  Debenture?: Maybe<Scalars["float8"]>;
  DeferTaxLiability?: Maybe<Scalars["float8"]>;
  Depreciation?: Maybe<Scalars["float8"]>;
  EquityShare?: Maybe<Scalars["float8"]>;
  FactoryRentRates?: Maybe<Scalars["float8"]>;
  FinanceExp?: Maybe<Scalars["float8"]>;
  FixedDeposit?: Maybe<Scalars["float8"]>;
  GrossProfit?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  NonAllowblExp?: Maybe<Scalars["float8"]>;
  OperatingExp?: Maybe<Scalars["float8"]>;
  OtherInvestments?: Maybe<Scalars["float8"]>;
  OthrAllowblExp?: Maybe<Scalars["float8"]>;
  PayableAmtInc?: Maybe<Scalars["float8"]>;
  PayableAmtRent?: Maybe<Scalars["float8"]>;
  ProfitAfterDividend?: Maybe<Scalars["float8"]>;
  ProfitAfterTax?: Maybe<Scalars["float8"]>;
  ProfitBeforeTax?: Maybe<Scalars["float8"]>;
  ProfitLossRental?: Maybe<Scalars["float8"]>;
  ProposedDividend?: Maybe<Scalars["float8"]>;
  ProvisionIncomeTax?: Maybe<Scalars["float8"]>;
  RefundAmtInc?: Maybe<Scalars["float8"]>;
  RefundAmtRent?: Maybe<Scalars["float8"]>;
  RentRates?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RentincRate?: Maybe<Scalars["float8"]>;
  RentincTax?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["float8"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["float8"]>;
  TotAdminExpense?: Maybe<Scalars["float8"]>;
  TotBsnsProfSales?: Maybe<Scalars["float8"]>;
  TotExpPurelyRental?: Maybe<Scalars["float8"]>;
  TotFinancialExpense?: Maybe<Scalars["float8"]>;
  TotIncome?: Maybe<Scalars["float8"]>;
  TotNonChargeableIncome?: Maybe<Scalars["float8"]>;
  TotOperationExpense?: Maybe<Scalars["float8"]>;
  TotOtherExpense?: Maybe<Scalars["float8"]>;
  TotRate?: Maybe<Scalars["float8"]>;
  TotRentAdminiExpns?: Maybe<Scalars["float8"]>;
  TotRentFinancExpns?: Maybe<Scalars["float8"]>;
  TotRentIncmExpns?: Maybe<Scalars["float8"]>;
  TotRentOprtgExpns?: Maybe<Scalars["float8"]>;
  TotTax?: Maybe<Scalars["float8"]>;
  TotTaxLiability?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["float8"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["float8"]>;
  TotTaxesPaidInc?: Maybe<Scalars["float8"]>;
  TotTaxesPaidRent?: Maybe<Scalars["float8"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["float8"]>;
  TotalShareCapital?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Sum_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Var_Pop_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryNonIndividual_var_pop_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Var_Pop_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Var_Samp_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryNonIndividual_var_samp_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Var_Samp_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Variance_Fields = {
  __typename?: "rtcs_db_ura_IncomeTaxSummaryNonIndividual_variance_fields";
  AdjProfitLossRental?: Maybe<Scalars["Float"]>;
  AdjRentlIncmLoss?: Maybe<Scalars["Float"]>;
  AdministratingExp?: Maybe<Scalars["Float"]>;
  AllowblApportionedCap?: Maybe<Scalars["Float"]>;
  AssessedChrgblRentlIncm?: Maybe<Scalars["Float"]>;
  AssessedRentlLoss?: Maybe<Scalars["Float"]>;
  BadDebtWriteOff?: Maybe<Scalars["Float"]>;
  BrghtFwdAssessedLoss?: Maybe<Scalars["Float"]>;
  BusinessIncome?: Maybe<Scalars["Float"]>;
  BusinessRate?: Maybe<Scalars["Float"]>;
  BusinessTax?: Maybe<Scalars["Float"]>;
  ChargeableIncome?: Maybe<Scalars["Float"]>;
  CostOfSales?: Maybe<Scalars["Float"]>;
  Debenture?: Maybe<Scalars["Float"]>;
  DeferTaxLiability?: Maybe<Scalars["Float"]>;
  Depreciation?: Maybe<Scalars["Float"]>;
  EquityShare?: Maybe<Scalars["Float"]>;
  FactoryRentRates?: Maybe<Scalars["Float"]>;
  FinanceExp?: Maybe<Scalars["Float"]>;
  FixedDeposit?: Maybe<Scalars["Float"]>;
  GrossProfit?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  NonAllowblExp?: Maybe<Scalars["Float"]>;
  OperatingExp?: Maybe<Scalars["Float"]>;
  OtherInvestments?: Maybe<Scalars["Float"]>;
  OthrAllowblExp?: Maybe<Scalars["Float"]>;
  PayableAmtInc?: Maybe<Scalars["Float"]>;
  PayableAmtRent?: Maybe<Scalars["Float"]>;
  ProfitAfterDividend?: Maybe<Scalars["Float"]>;
  ProfitAfterTax?: Maybe<Scalars["Float"]>;
  ProfitBeforeTax?: Maybe<Scalars["Float"]>;
  ProfitLossRental?: Maybe<Scalars["Float"]>;
  ProposedDividend?: Maybe<Scalars["Float"]>;
  ProvisionIncomeTax?: Maybe<Scalars["Float"]>;
  RefundAmtInc?: Maybe<Scalars["Float"]>;
  RefundAmtRent?: Maybe<Scalars["Float"]>;
  RentRates?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
  RentincRate?: Maybe<Scalars["Float"]>;
  RentincTax?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidInc?: Maybe<Scalars["Float"]>;
  SelfAssessTaxPaidRent?: Maybe<Scalars["Float"]>;
  TotAdminExpense?: Maybe<Scalars["Float"]>;
  TotBsnsProfSales?: Maybe<Scalars["Float"]>;
  TotExpPurelyRental?: Maybe<Scalars["Float"]>;
  TotFinancialExpense?: Maybe<Scalars["Float"]>;
  TotIncome?: Maybe<Scalars["Float"]>;
  TotNonChargeableIncome?: Maybe<Scalars["Float"]>;
  TotOperationExpense?: Maybe<Scalars["Float"]>;
  TotOtherExpense?: Maybe<Scalars["Float"]>;
  TotRate?: Maybe<Scalars["Float"]>;
  TotRentAdminiExpns?: Maybe<Scalars["Float"]>;
  TotRentFinancExpns?: Maybe<Scalars["Float"]>;
  TotRentIncmExpns?: Maybe<Scalars["Float"]>;
  TotRentOprtgExpns?: Maybe<Scalars["Float"]>;
  TotTax?: Maybe<Scalars["Float"]>;
  TotTaxLiability?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityInc?: Maybe<Scalars["Float"]>;
  TotTaxLiabilityRent?: Maybe<Scalars["Float"]>;
  TotTaxesPaidInc?: Maybe<Scalars["Float"]>;
  TotTaxesPaidRent?: Maybe<Scalars["Float"]>;
  TotWthdTaxDeduct?: Maybe<Scalars["Float"]>;
  TotalShareCapital?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
export type Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Variance_Order_By = {
  AdjProfitLossRental?: Maybe<Order_By>;
  AdjRentlIncmLoss?: Maybe<Order_By>;
  AdministratingExp?: Maybe<Order_By>;
  AllowblApportionedCap?: Maybe<Order_By>;
  AssessedChrgblRentlIncm?: Maybe<Order_By>;
  AssessedRentlLoss?: Maybe<Order_By>;
  BadDebtWriteOff?: Maybe<Order_By>;
  BrghtFwdAssessedLoss?: Maybe<Order_By>;
  BusinessIncome?: Maybe<Order_By>;
  BusinessRate?: Maybe<Order_By>;
  BusinessTax?: Maybe<Order_By>;
  ChargeableIncome?: Maybe<Order_By>;
  CostOfSales?: Maybe<Order_By>;
  Debenture?: Maybe<Order_By>;
  DeferTaxLiability?: Maybe<Order_By>;
  Depreciation?: Maybe<Order_By>;
  EquityShare?: Maybe<Order_By>;
  FactoryRentRates?: Maybe<Order_By>;
  FinanceExp?: Maybe<Order_By>;
  FixedDeposit?: Maybe<Order_By>;
  GrossProfit?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  NonAllowblExp?: Maybe<Order_By>;
  OperatingExp?: Maybe<Order_By>;
  OtherInvestments?: Maybe<Order_By>;
  OthrAllowblExp?: Maybe<Order_By>;
  PayableAmtInc?: Maybe<Order_By>;
  PayableAmtRent?: Maybe<Order_By>;
  ProfitAfterDividend?: Maybe<Order_By>;
  ProfitAfterTax?: Maybe<Order_By>;
  ProfitBeforeTax?: Maybe<Order_By>;
  ProfitLossRental?: Maybe<Order_By>;
  ProposedDividend?: Maybe<Order_By>;
  ProvisionIncomeTax?: Maybe<Order_By>;
  RefundAmtInc?: Maybe<Order_By>;
  RefundAmtRent?: Maybe<Order_By>;
  RentRates?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RentincRate?: Maybe<Order_By>;
  RentincTax?: Maybe<Order_By>;
  SelfAssessTaxPaidInc?: Maybe<Order_By>;
  SelfAssessTaxPaidRent?: Maybe<Order_By>;
  TotAdminExpense?: Maybe<Order_By>;
  TotBsnsProfSales?: Maybe<Order_By>;
  TotExpPurelyRental?: Maybe<Order_By>;
  TotFinancialExpense?: Maybe<Order_By>;
  TotIncome?: Maybe<Order_By>;
  TotNonChargeableIncome?: Maybe<Order_By>;
  TotOperationExpense?: Maybe<Order_By>;
  TotOtherExpense?: Maybe<Order_By>;
  TotRate?: Maybe<Order_By>;
  TotRentAdminiExpns?: Maybe<Order_By>;
  TotRentFinancExpns?: Maybe<Order_By>;
  TotRentIncmExpns?: Maybe<Order_By>;
  TotRentOprtgExpns?: Maybe<Order_By>;
  TotTax?: Maybe<Order_By>;
  TotTaxLiability?: Maybe<Order_By>;
  TotTaxLiabilityInc?: Maybe<Order_By>;
  TotTaxLiabilityRent?: Maybe<Order_By>;
  TotTaxesPaidInc?: Maybe<Order_By>;
  TotTaxesPaidRent?: Maybe<Order_By>;
  TotWthdTaxDeduct?: Maybe<Order_By>;
  TotalShareCapital?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.ura_IndividualMortgage" */
export type Rtcs_Db_Ura_IndividualMortgage = {
  __typename?: "rtcs_db_ura_IndividualMortgage";
  DeedNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FinanceName?: Maybe<Scalars["String"]>;
  FinanceTin?: Maybe<Scalars["bigint"]>;
  IntersetMortgage?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PeriodFrom?: Maybe<Scalars["timestamptz"]>;
  PeriodTo?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TotalMortgage?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_IndividualMortgage" */
export type Rtcs_Db_Ura_IndividualMortgage_Aggregate = {
  __typename?: "rtcs_db_ura_IndividualMortgage_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_IndividualMortgage>;
};

/** aggregate fields of "rtcs_db.ura_IndividualMortgage" */
export type Rtcs_Db_Ura_IndividualMortgage_Aggregate_Fields = {
  __typename?: "rtcs_db_ura_IndividualMortgage_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ura_IndividualMortgage" */
export type Rtcs_Db_Ura_IndividualMortgage_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ura_IndividualMortgage_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.ura_IndividualMortgage" */
export type Rtcs_Db_Ura_IndividualMortgage_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ura_IndividualMortgage" */
export type Rtcs_Db_Ura_IndividualMortgage_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ura_IndividualMortgage_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ura_IndividualMortgage_Avg_Fields = {
  __typename?: "rtcs_db_ura_IndividualMortgage_avg_fields";
  FinanceTin?: Maybe<Scalars["Float"]>;
  IntersetMortgage?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TotalMortgage?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_IndividualMortgage" */
export type Rtcs_Db_Ura_IndividualMortgage_Avg_Order_By = {
  FinanceTin?: Maybe<Order_By>;
  IntersetMortgage?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TotalMortgage?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_IndividualMortgage". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_IndividualMortgage_Bool_Exp = {
  DeedNo?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FinanceName?: Maybe<String_Comparison_Exp>;
  FinanceTin?: Maybe<Bigint_Comparison_Exp>;
  IntersetMortgage?: Maybe<Float8_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  PeriodFrom?: Maybe<Timestamptz_Comparison_Exp>;
  PeriodTo?: Maybe<Timestamptz_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  SourceType?: Maybe<String_Comparison_Exp>;
  TotalMortgage?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ura_IndividualMortgage_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ura_IndividualMortgage_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.ura_IndividualMortgage" */
export type Rtcs_Db_Ura_IndividualMortgage_Inc_Input = {
  FinanceTin?: Maybe<Scalars["bigint"]>;
  IntersetMortgage?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  TotalMortgage?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.ura_IndividualMortgage" */
export type Rtcs_Db_Ura_IndividualMortgage_Insert_Input = {
  DeedNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FinanceName?: Maybe<Scalars["String"]>;
  FinanceTin?: Maybe<Scalars["bigint"]>;
  IntersetMortgage?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PeriodFrom?: Maybe<Scalars["timestamptz"]>;
  PeriodTo?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TotalMortgage?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_IndividualMortgage_Max_Fields = {
  __typename?: "rtcs_db_ura_IndividualMortgage_max_fields";
  DeedNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FinanceName?: Maybe<Scalars["String"]>;
  FinanceTin?: Maybe<Scalars["bigint"]>;
  IntersetMortgage?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PeriodFrom?: Maybe<Scalars["timestamptz"]>;
  PeriodTo?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TotalMortgage?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_IndividualMortgage" */
export type Rtcs_Db_Ura_IndividualMortgage_Max_Order_By = {
  DeedNo?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinanceName?: Maybe<Order_By>;
  FinanceTin?: Maybe<Order_By>;
  IntersetMortgage?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PeriodFrom?: Maybe<Order_By>;
  PeriodTo?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  TotalMortgage?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_IndividualMortgage_Min_Fields = {
  __typename?: "rtcs_db_ura_IndividualMortgage_min_fields";
  DeedNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FinanceName?: Maybe<Scalars["String"]>;
  FinanceTin?: Maybe<Scalars["bigint"]>;
  IntersetMortgage?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PeriodFrom?: Maybe<Scalars["timestamptz"]>;
  PeriodTo?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TotalMortgage?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_IndividualMortgage" */
export type Rtcs_Db_Ura_IndividualMortgage_Min_Order_By = {
  DeedNo?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinanceName?: Maybe<Order_By>;
  FinanceTin?: Maybe<Order_By>;
  IntersetMortgage?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PeriodFrom?: Maybe<Order_By>;
  PeriodTo?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  TotalMortgage?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_IndividualMortgage" */
export type Rtcs_Db_Ura_IndividualMortgage_Mutation_Response = {
  __typename?: "rtcs_db_ura_IndividualMortgage_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ura_IndividualMortgage>;
};

/** input type for inserting object relation for remote table "rtcs_db.ura_IndividualMortgage" */
export type Rtcs_Db_Ura_IndividualMortgage_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ura_IndividualMortgage_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.ura_IndividualMortgage" */
export type Rtcs_Db_Ura_IndividualMortgage_Order_By = {
  DeedNo?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FinanceName?: Maybe<Order_By>;
  FinanceTin?: Maybe<Order_By>;
  IntersetMortgage?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PeriodFrom?: Maybe<Order_By>;
  PeriodTo?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  TotalMortgage?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_IndividualMortgage" */
export enum Rtcs_Db_Ura_IndividualMortgage_Select_Column {
  /** column name */
  DeedNo = "DeedNo",
  /** column name */
  File = "File",
  /** column name */
  FinanceName = "FinanceName",
  /** column name */
  FinanceTin = "FinanceTin",
  /** column name */
  IntersetMortgage = "IntersetMortgage",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  PeriodFrom = "PeriodFrom",
  /** column name */
  PeriodTo = "PeriodTo",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  SourceType = "SourceType",
  /** column name */
  TotalMortgage = "TotalMortgage",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_IndividualMortgage" */
export type Rtcs_Db_Ura_IndividualMortgage_Set_Input = {
  DeedNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FinanceName?: Maybe<Scalars["String"]>;
  FinanceTin?: Maybe<Scalars["bigint"]>;
  IntersetMortgage?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PeriodFrom?: Maybe<Scalars["timestamptz"]>;
  PeriodTo?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  TotalMortgage?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_IndividualMortgage_Stddev_Fields = {
  __typename?: "rtcs_db_ura_IndividualMortgage_stddev_fields";
  FinanceTin?: Maybe<Scalars["Float"]>;
  IntersetMortgage?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TotalMortgage?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_IndividualMortgage" */
export type Rtcs_Db_Ura_IndividualMortgage_Stddev_Order_By = {
  FinanceTin?: Maybe<Order_By>;
  IntersetMortgage?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TotalMortgage?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_IndividualMortgage_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ura_IndividualMortgage_stddev_pop_fields";
  FinanceTin?: Maybe<Scalars["Float"]>;
  IntersetMortgage?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TotalMortgage?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ura_IndividualMortgage" */
export type Rtcs_Db_Ura_IndividualMortgage_Stddev_Pop_Order_By = {
  FinanceTin?: Maybe<Order_By>;
  IntersetMortgage?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TotalMortgage?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_IndividualMortgage_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ura_IndividualMortgage_stddev_samp_fields";
  FinanceTin?: Maybe<Scalars["Float"]>;
  IntersetMortgage?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TotalMortgage?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ura_IndividualMortgage" */
export type Rtcs_Db_Ura_IndividualMortgage_Stddev_Samp_Order_By = {
  FinanceTin?: Maybe<Order_By>;
  IntersetMortgage?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TotalMortgage?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ura_IndividualMortgage_Sum_Fields = {
  __typename?: "rtcs_db_ura_IndividualMortgage_sum_fields";
  FinanceTin?: Maybe<Scalars["bigint"]>;
  IntersetMortgage?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  TotalMortgage?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_IndividualMortgage" */
export type Rtcs_Db_Ura_IndividualMortgage_Sum_Order_By = {
  FinanceTin?: Maybe<Order_By>;
  IntersetMortgage?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TotalMortgage?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_IndividualMortgage_Var_Pop_Fields = {
  __typename?: "rtcs_db_ura_IndividualMortgage_var_pop_fields";
  FinanceTin?: Maybe<Scalars["Float"]>;
  IntersetMortgage?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TotalMortgage?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ura_IndividualMortgage" */
export type Rtcs_Db_Ura_IndividualMortgage_Var_Pop_Order_By = {
  FinanceTin?: Maybe<Order_By>;
  IntersetMortgage?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TotalMortgage?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_IndividualMortgage_Var_Samp_Fields = {
  __typename?: "rtcs_db_ura_IndividualMortgage_var_samp_fields";
  FinanceTin?: Maybe<Scalars["Float"]>;
  IntersetMortgage?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TotalMortgage?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ura_IndividualMortgage" */
export type Rtcs_Db_Ura_IndividualMortgage_Var_Samp_Order_By = {
  FinanceTin?: Maybe<Order_By>;
  IntersetMortgage?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TotalMortgage?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ura_IndividualMortgage_Variance_Fields = {
  __typename?: "rtcs_db_ura_IndividualMortgage_variance_fields";
  FinanceTin?: Maybe<Scalars["Float"]>;
  IntersetMortgage?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TotalMortgage?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ura_IndividualMortgage" */
export type Rtcs_Db_Ura_IndividualMortgage_Variance_Order_By = {
  FinanceTin?: Maybe<Order_By>;
  IntersetMortgage?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TotalMortgage?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness = {
  __typename?: "rtcs_db_ura_IndividualsAssociatedBusiness";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTaxPayerName?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Aggregate = {
  __typename?: "rtcs_db_ura_IndividualsAssociatedBusiness_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_IndividualsAssociatedBusiness>;
};

/** aggregate fields of "rtcs_db.ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Aggregate_Fields = {
  __typename?: "rtcs_db_ura_IndividualsAssociatedBusiness_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Avg_Fields = {
  __typename?: "rtcs_db_ura_IndividualsAssociatedBusiness_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Avg_Order_By = {
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_IndividualsAssociatedBusiness". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Bool_Exp = {
  ActvtyDesc?: Maybe<String_Comparison_Exp>;
  AssociateTaxPayerName?: Maybe<String_Comparison_Exp>;
  AssociateTin?: Maybe<String_Comparison_Exp>;
  EntityDesignation?: Maybe<String_Comparison_Exp>;
  FamilyName?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FirstName?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MainActivity?: Maybe<String_Comparison_Exp>;
  OtherName?: Maybe<String_Comparison_Exp>;
  RegAssociatedEntityDtlId?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  TaxPayerType?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Insert_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTaxPayerName?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Max_Fields = {
  __typename?: "rtcs_db_ura_IndividualsAssociatedBusiness_max_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTaxPayerName?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Max_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AssociateTaxPayerName?: Maybe<Order_By>;
  AssociateTin?: Maybe<Order_By>;
  EntityDesignation?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  RegAssociatedEntityDtlId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Min_Fields = {
  __typename?: "rtcs_db_ura_IndividualsAssociatedBusiness_min_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTaxPayerName?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Min_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AssociateTaxPayerName?: Maybe<Order_By>;
  AssociateTin?: Maybe<Order_By>;
  EntityDesignation?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  RegAssociatedEntityDtlId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Mutation_Response = {
  __typename?: "rtcs_db_ura_IndividualsAssociatedBusiness_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ura_IndividualsAssociatedBusiness>;
};

/** input type for inserting object relation for remote table "rtcs_db.ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ura_IndividualsAssociatedBusiness_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AssociateTaxPayerName?: Maybe<Order_By>;
  AssociateTin?: Maybe<Order_By>;
  EntityDesignation?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  RegAssociatedEntityDtlId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_IndividualsAssociatedBusiness" */
export enum Rtcs_Db_Ura_IndividualsAssociatedBusiness_Select_Column {
  /** column name */
  ActvtyDesc = "ActvtyDesc",
  /** column name */
  AssociateTaxPayerName = "AssociateTaxPayerName",
  /** column name */
  AssociateTin = "AssociateTin",
  /** column name */
  EntityDesignation = "EntityDesignation",
  /** column name */
  FamilyName = "FamilyName",
  /** column name */
  File = "File",
  /** column name */
  FirstName = "FirstName",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MainActivity = "MainActivity",
  /** column name */
  OtherName = "OtherName",
  /** column name */
  RegAssociatedEntityDtlId = "RegAssociatedEntityDtlId",
  /** column name */
  Surname = "Surname",
  /** column name */
  TaxPayerType = "TaxPayerType",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Set_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTaxPayerName?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Stddev_Fields = {
  __typename?: "rtcs_db_ura_IndividualsAssociatedBusiness_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ura_IndividualsAssociatedBusiness_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ura_IndividualsAssociatedBusiness_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Sum_Fields = {
  __typename?: "rtcs_db_ura_IndividualsAssociatedBusiness_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Sum_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Var_Pop_Fields = {
  __typename?: "rtcs_db_ura_IndividualsAssociatedBusiness_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Var_Samp_Fields = {
  __typename?: "rtcs_db_ura_IndividualsAssociatedBusiness_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Variance_Fields = {
  __typename?: "rtcs_db_ura_IndividualsAssociatedBusiness_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ura_IndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_IndividualsAssociatedBusiness_Variance_Order_By = {
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ura_LandlordIncomeDetail = {
  __typename?: "rtcs_db_ura_LandlordIncomeDetail";
  AssessedChrgbleRentlIncm?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  RtnToDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Aggregate = {
  __typename?: "rtcs_db_ura_LandlordIncomeDetail_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_LandlordIncomeDetail>;
};

/** aggregate fields of "rtcs_db.ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Aggregate_Fields = {
  __typename?: "rtcs_db_ura_LandlordIncomeDetail_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ura_LandlordIncomeDetail_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ura_LandlordIncomeDetail_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Avg_Fields = {
  __typename?: "rtcs_db_ura_LandlordIncomeDetail_avg_fields";
  AssessedChrgbleRentlIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Avg_Order_By = {
  AssessedChrgbleRentlIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_LandlordIncomeDetail". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Bool_Exp = {
  AssessedChrgbleRentlIncm?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  GrossRentalIncome?: Maybe<Float8_Comparison_Exp>;
  IsAmendment?: Maybe<String_Comparison_Exp>;
  IsResident?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  PremiseLocation?: Maybe<String_Comparison_Exp>;
  RentalIncome?: Maybe<Float8_Comparison_Exp>;
  RentincIncome?: Maybe<Float8_Comparison_Exp>;
  RtnDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnDtTaxSummary?: Maybe<Timestamptz_Comparison_Exp>;
  RtnFromDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnFromDtTaxSummary?: Maybe<Timestamptz_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnPeriodYear?: Maybe<String_Comparison_Exp>;
  RtnRentalIncomeId?: Maybe<String_Comparison_Exp>;
  RtnToDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnToDtTaxSummary?: Maybe<Timestamptz_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TenancyPeriodFrom?: Maybe<Timestamptz_Comparison_Exp>;
  TenancyPeriodTo?: Maybe<Timestamptz_Comparison_Exp>;
  TenantName?: Maybe<String_Comparison_Exp>;
  TenantTin?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Inc_Input = {
  AssessedChrgbleRentlIncm?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Insert_Input = {
  AssessedChrgbleRentlIncm?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  RtnToDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Max_Fields = {
  __typename?: "rtcs_db_ura_LandlordIncomeDetail_max_fields";
  AssessedChrgbleRentlIncm?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  RtnToDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Max_Order_By = {
  AssessedChrgbleRentlIncm?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnDtTaxSummary?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnFromDtTaxSummary?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnRentalIncomeId?: Maybe<Order_By>;
  RtnToDt?: Maybe<Order_By>;
  RtnToDtTaxSummary?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  TenantName?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Min_Fields = {
  __typename?: "rtcs_db_ura_LandlordIncomeDetail_min_fields";
  AssessedChrgbleRentlIncm?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  RtnToDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Min_Order_By = {
  AssessedChrgbleRentlIncm?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnDtTaxSummary?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnFromDtTaxSummary?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnRentalIncomeId?: Maybe<Order_By>;
  RtnToDt?: Maybe<Order_By>;
  RtnToDtTaxSummary?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  TenantName?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Mutation_Response = {
  __typename?: "rtcs_db_ura_LandlordIncomeDetail_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ura_LandlordIncomeDetail>;
};

/** input type for inserting object relation for remote table "rtcs_db.ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ura_LandlordIncomeDetail_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Order_By = {
  AssessedChrgbleRentlIncm?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  IsAmendment?: Maybe<Order_By>;
  IsResident?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnDtTaxSummary?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnFromDtTaxSummary?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnPeriodYear?: Maybe<Order_By>;
  RtnRentalIncomeId?: Maybe<Order_By>;
  RtnToDt?: Maybe<Order_By>;
  RtnToDtTaxSummary?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  TenantName?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_LandlordIncomeDetail" */
export enum Rtcs_Db_Ura_LandlordIncomeDetail_Select_Column {
  /** column name */
  AssessedChrgbleRentlIncm = "AssessedChrgbleRentlIncm",
  /** column name */
  File = "File",
  /** column name */
  GrossRentalIncome = "GrossRentalIncome",
  /** column name */
  IsAmendment = "IsAmendment",
  /** column name */
  IsResident = "IsResident",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  PremiseLocation = "PremiseLocation",
  /** column name */
  RentalIncome = "RentalIncome",
  /** column name */
  RentincIncome = "RentincIncome",
  /** column name */
  RtnDt = "RtnDt",
  /** column name */
  RtnDtTaxSummary = "RtnDtTaxSummary",
  /** column name */
  RtnFromDt = "RtnFromDt",
  /** column name */
  RtnFromDtTaxSummary = "RtnFromDtTaxSummary",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnPeriodYear = "RtnPeriodYear",
  /** column name */
  RtnRentalIncomeId = "RtnRentalIncomeId",
  /** column name */
  RtnToDt = "RtnToDt",
  /** column name */
  RtnToDtTaxSummary = "RtnToDtTaxSummary",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TenancyPeriodFrom = "TenancyPeriodFrom",
  /** column name */
  TenancyPeriodTo = "TenancyPeriodTo",
  /** column name */
  TenantName = "TenantName",
  /** column name */
  TenantTin = "TenantTin",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Set_Input = {
  AssessedChrgbleRentlIncm?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  IsAmendment?: Maybe<Scalars["String"]>;
  IsResident?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnPeriodYear?: Maybe<Scalars["String"]>;
  RtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  RtnToDtTaxSummary?: Maybe<Scalars["timestamptz"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Stddev_Fields = {
  __typename?: "rtcs_db_ura_LandlordIncomeDetail_stddev_fields";
  AssessedChrgbleRentlIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Stddev_Order_By = {
  AssessedChrgbleRentlIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ura_LandlordIncomeDetail_stddev_pop_fields";
  AssessedChrgbleRentlIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Stddev_Pop_Order_By = {
  AssessedChrgbleRentlIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ura_LandlordIncomeDetail_stddev_samp_fields";
  AssessedChrgbleRentlIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Stddev_Samp_Order_By = {
  AssessedChrgbleRentlIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Sum_Fields = {
  __typename?: "rtcs_db_ura_LandlordIncomeDetail_sum_fields";
  AssessedChrgbleRentlIncm?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
  RentincIncome?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Sum_Order_By = {
  AssessedChrgbleRentlIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Var_Pop_Fields = {
  __typename?: "rtcs_db_ura_LandlordIncomeDetail_var_pop_fields";
  AssessedChrgbleRentlIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Var_Pop_Order_By = {
  AssessedChrgbleRentlIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Var_Samp_Fields = {
  __typename?: "rtcs_db_ura_LandlordIncomeDetail_var_samp_fields";
  AssessedChrgbleRentlIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Var_Samp_Order_By = {
  AssessedChrgbleRentlIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Variance_Fields = {
  __typename?: "rtcs_db_ura_LandlordIncomeDetail_variance_fields";
  AssessedChrgbleRentlIncm?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
  RentincIncome?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ura_LandlordIncomeDetail" */
export type Rtcs_Db_Ura_LandlordIncomeDetail_Variance_Order_By = {
  AssessedChrgbleRentlIncm?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  RentincIncome?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ura_LandlordIncomeSummary = {
  __typename?: "rtcs_db_ura_LandlordIncomeSummary";
  Difference?: Maybe<Scalars["float8"]>;
  DifferencePercentage?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  GrossDifference?: Maybe<Scalars["float8"]>;
  GrossDifferencePercentage?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  LandlordFirstName?: Maybe<Scalars["String"]>;
  LandlordSurname?: Maybe<Scalars["String"]>;
  LandlordTaxpayerName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LandlordTinSource?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["float8"]>;
  RentReportedByTenants?: Maybe<Scalars["float8"]>;
  ReturnNo?: Maybe<Scalars["String"]>;
  ReturnNoSource?: Maybe<Scalars["String"]>;
  ReturnPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  ReturnPeriodTo?: Maybe<Scalars["timestamptz"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  TaxYear?: Maybe<Scalars["String"]>;
  TaxpayerAndTinDataSource?: Maybe<Scalars["String"]>;
  Unnamed0?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Aggregate = {
  __typename?: "rtcs_db_ura_LandlordIncomeSummary_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_LandlordIncomeSummary>;
};

/** aggregate fields of "rtcs_db.ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Aggregate_Fields = {
  __typename?: "rtcs_db_ura_LandlordIncomeSummary_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ura_LandlordIncomeSummary_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ura_LandlordIncomeSummary_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Avg_Fields = {
  __typename?: "rtcs_db_ura_LandlordIncomeSummary_avg_fields";
  Difference?: Maybe<Scalars["Float"]>;
  GrossDifference?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["Float"]>;
  RentReportedByTenants?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  Unnamed0?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Avg_Order_By = {
  Difference?: Maybe<Order_By>;
  GrossDifference?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentDeclaredByLandlord?: Maybe<Order_By>;
  RentReportedByTenants?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  Unnamed0?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_LandlordIncomeSummary". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Bool_Exp = {
  Difference?: Maybe<Float8_Comparison_Exp>;
  DifferencePercentage?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  GrossDifference?: Maybe<Float8_Comparison_Exp>;
  GrossDifferencePercentage?: Maybe<String_Comparison_Exp>;
  GrossRentalIncome?: Maybe<Float8_Comparison_Exp>;
  LandlordFirstName?: Maybe<String_Comparison_Exp>;
  LandlordSurname?: Maybe<String_Comparison_Exp>;
  LandlordTaxpayerName?: Maybe<String_Comparison_Exp>;
  LandlordTin?: Maybe<String_Comparison_Exp>;
  LandlordTinSource?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  RentDeclaredByLandlord?: Maybe<Float8_Comparison_Exp>;
  RentReportedByTenants?: Maybe<Float8_Comparison_Exp>;
  ReturnNo?: Maybe<String_Comparison_Exp>;
  ReturnNoSource?: Maybe<String_Comparison_Exp>;
  ReturnPeriodFrom?: Maybe<Timestamptz_Comparison_Exp>;
  ReturnPeriodTo?: Maybe<Timestamptz_Comparison_Exp>;
  RmatchScore?: Maybe<Float8_Comparison_Exp>;
  TaxYear?: Maybe<String_Comparison_Exp>;
  TaxpayerAndTinDataSource?: Maybe<String_Comparison_Exp>;
  Unnamed0?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Inc_Input = {
  Difference?: Maybe<Scalars["float8"]>;
  GrossDifference?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["float8"]>;
  RentReportedByTenants?: Maybe<Scalars["float8"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  Unnamed0?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Insert_Input = {
  Difference?: Maybe<Scalars["float8"]>;
  DifferencePercentage?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  GrossDifference?: Maybe<Scalars["float8"]>;
  GrossDifferencePercentage?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  LandlordFirstName?: Maybe<Scalars["String"]>;
  LandlordSurname?: Maybe<Scalars["String"]>;
  LandlordTaxpayerName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LandlordTinSource?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["float8"]>;
  RentReportedByTenants?: Maybe<Scalars["float8"]>;
  ReturnNo?: Maybe<Scalars["String"]>;
  ReturnNoSource?: Maybe<Scalars["String"]>;
  ReturnPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  ReturnPeriodTo?: Maybe<Scalars["timestamptz"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  TaxYear?: Maybe<Scalars["String"]>;
  TaxpayerAndTinDataSource?: Maybe<Scalars["String"]>;
  Unnamed0?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Max_Fields = {
  __typename?: "rtcs_db_ura_LandlordIncomeSummary_max_fields";
  Difference?: Maybe<Scalars["float8"]>;
  DifferencePercentage?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  GrossDifference?: Maybe<Scalars["float8"]>;
  GrossDifferencePercentage?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  LandlordFirstName?: Maybe<Scalars["String"]>;
  LandlordSurname?: Maybe<Scalars["String"]>;
  LandlordTaxpayerName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LandlordTinSource?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["float8"]>;
  RentReportedByTenants?: Maybe<Scalars["float8"]>;
  ReturnNo?: Maybe<Scalars["String"]>;
  ReturnNoSource?: Maybe<Scalars["String"]>;
  ReturnPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  ReturnPeriodTo?: Maybe<Scalars["timestamptz"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  TaxYear?: Maybe<Scalars["String"]>;
  TaxpayerAndTinDataSource?: Maybe<Scalars["String"]>;
  Unnamed0?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Max_Order_By = {
  Difference?: Maybe<Order_By>;
  DifferencePercentage?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GrossDifference?: Maybe<Order_By>;
  GrossDifferencePercentage?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  LandlordFirstName?: Maybe<Order_By>;
  LandlordSurname?: Maybe<Order_By>;
  LandlordTaxpayerName?: Maybe<Order_By>;
  LandlordTin?: Maybe<Order_By>;
  LandlordTinSource?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentDeclaredByLandlord?: Maybe<Order_By>;
  RentReportedByTenants?: Maybe<Order_By>;
  ReturnNo?: Maybe<Order_By>;
  ReturnNoSource?: Maybe<Order_By>;
  ReturnPeriodFrom?: Maybe<Order_By>;
  ReturnPeriodTo?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  TaxYear?: Maybe<Order_By>;
  TaxpayerAndTinDataSource?: Maybe<Order_By>;
  Unnamed0?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Min_Fields = {
  __typename?: "rtcs_db_ura_LandlordIncomeSummary_min_fields";
  Difference?: Maybe<Scalars["float8"]>;
  DifferencePercentage?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  GrossDifference?: Maybe<Scalars["float8"]>;
  GrossDifferencePercentage?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  LandlordFirstName?: Maybe<Scalars["String"]>;
  LandlordSurname?: Maybe<Scalars["String"]>;
  LandlordTaxpayerName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LandlordTinSource?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["float8"]>;
  RentReportedByTenants?: Maybe<Scalars["float8"]>;
  ReturnNo?: Maybe<Scalars["String"]>;
  ReturnNoSource?: Maybe<Scalars["String"]>;
  ReturnPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  ReturnPeriodTo?: Maybe<Scalars["timestamptz"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  TaxYear?: Maybe<Scalars["String"]>;
  TaxpayerAndTinDataSource?: Maybe<Scalars["String"]>;
  Unnamed0?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Min_Order_By = {
  Difference?: Maybe<Order_By>;
  DifferencePercentage?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GrossDifference?: Maybe<Order_By>;
  GrossDifferencePercentage?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  LandlordFirstName?: Maybe<Order_By>;
  LandlordSurname?: Maybe<Order_By>;
  LandlordTaxpayerName?: Maybe<Order_By>;
  LandlordTin?: Maybe<Order_By>;
  LandlordTinSource?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentDeclaredByLandlord?: Maybe<Order_By>;
  RentReportedByTenants?: Maybe<Order_By>;
  ReturnNo?: Maybe<Order_By>;
  ReturnNoSource?: Maybe<Order_By>;
  ReturnPeriodFrom?: Maybe<Order_By>;
  ReturnPeriodTo?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  TaxYear?: Maybe<Order_By>;
  TaxpayerAndTinDataSource?: Maybe<Order_By>;
  Unnamed0?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Mutation_Response = {
  __typename?: "rtcs_db_ura_LandlordIncomeSummary_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ura_LandlordIncomeSummary>;
};

/** input type for inserting object relation for remote table "rtcs_db.ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ura_LandlordIncomeSummary_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Order_By = {
  Difference?: Maybe<Order_By>;
  DifferencePercentage?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GrossDifference?: Maybe<Order_By>;
  GrossDifferencePercentage?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  LandlordFirstName?: Maybe<Order_By>;
  LandlordSurname?: Maybe<Order_By>;
  LandlordTaxpayerName?: Maybe<Order_By>;
  LandlordTin?: Maybe<Order_By>;
  LandlordTinSource?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentDeclaredByLandlord?: Maybe<Order_By>;
  RentReportedByTenants?: Maybe<Order_By>;
  ReturnNo?: Maybe<Order_By>;
  ReturnNoSource?: Maybe<Order_By>;
  ReturnPeriodFrom?: Maybe<Order_By>;
  ReturnPeriodTo?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  TaxYear?: Maybe<Order_By>;
  TaxpayerAndTinDataSource?: Maybe<Order_By>;
  Unnamed0?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_LandlordIncomeSummary" */
export enum Rtcs_Db_Ura_LandlordIncomeSummary_Select_Column {
  /** column name */
  Difference = "Difference",
  /** column name */
  DifferencePercentage = "DifferencePercentage",
  /** column name */
  File = "File",
  /** column name */
  GrossDifference = "GrossDifference",
  /** column name */
  GrossDifferencePercentage = "GrossDifferencePercentage",
  /** column name */
  GrossRentalIncome = "GrossRentalIncome",
  /** column name */
  LandlordFirstName = "LandlordFirstName",
  /** column name */
  LandlordSurname = "LandlordSurname",
  /** column name */
  LandlordTaxpayerName = "LandlordTaxpayerName",
  /** column name */
  LandlordTin = "LandlordTin",
  /** column name */
  LandlordTinSource = "LandlordTinSource",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  RentDeclaredByLandlord = "RentDeclaredByLandlord",
  /** column name */
  RentReportedByTenants = "RentReportedByTenants",
  /** column name */
  ReturnNo = "ReturnNo",
  /** column name */
  ReturnNoSource = "ReturnNoSource",
  /** column name */
  ReturnPeriodFrom = "ReturnPeriodFrom",
  /** column name */
  ReturnPeriodTo = "ReturnPeriodTo",
  /** column name */
  RmatchScore = "RmatchScore",
  /** column name */
  TaxYear = "TaxYear",
  /** column name */
  TaxpayerAndTinDataSource = "TaxpayerAndTinDataSource",
  /** column name */
  Unnamed0 = "Unnamed0",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Set_Input = {
  Difference?: Maybe<Scalars["float8"]>;
  DifferencePercentage?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  GrossDifference?: Maybe<Scalars["float8"]>;
  GrossDifferencePercentage?: Maybe<Scalars["String"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  LandlordFirstName?: Maybe<Scalars["String"]>;
  LandlordSurname?: Maybe<Scalars["String"]>;
  LandlordTaxpayerName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LandlordTinSource?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["float8"]>;
  RentReportedByTenants?: Maybe<Scalars["float8"]>;
  ReturnNo?: Maybe<Scalars["String"]>;
  ReturnNoSource?: Maybe<Scalars["String"]>;
  ReturnPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  ReturnPeriodTo?: Maybe<Scalars["timestamptz"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  TaxYear?: Maybe<Scalars["String"]>;
  TaxpayerAndTinDataSource?: Maybe<Scalars["String"]>;
  Unnamed0?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Stddev_Fields = {
  __typename?: "rtcs_db_ura_LandlordIncomeSummary_stddev_fields";
  Difference?: Maybe<Scalars["Float"]>;
  GrossDifference?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["Float"]>;
  RentReportedByTenants?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  Unnamed0?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Stddev_Order_By = {
  Difference?: Maybe<Order_By>;
  GrossDifference?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentDeclaredByLandlord?: Maybe<Order_By>;
  RentReportedByTenants?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  Unnamed0?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ura_LandlordIncomeSummary_stddev_pop_fields";
  Difference?: Maybe<Scalars["Float"]>;
  GrossDifference?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["Float"]>;
  RentReportedByTenants?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  Unnamed0?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Stddev_Pop_Order_By = {
  Difference?: Maybe<Order_By>;
  GrossDifference?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentDeclaredByLandlord?: Maybe<Order_By>;
  RentReportedByTenants?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  Unnamed0?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ura_LandlordIncomeSummary_stddev_samp_fields";
  Difference?: Maybe<Scalars["Float"]>;
  GrossDifference?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["Float"]>;
  RentReportedByTenants?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  Unnamed0?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Stddev_Samp_Order_By = {
  Difference?: Maybe<Order_By>;
  GrossDifference?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentDeclaredByLandlord?: Maybe<Order_By>;
  RentReportedByTenants?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  Unnamed0?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Sum_Fields = {
  __typename?: "rtcs_db_ura_LandlordIncomeSummary_sum_fields";
  Difference?: Maybe<Scalars["float8"]>;
  GrossDifference?: Maybe<Scalars["float8"]>;
  GrossRentalIncome?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["float8"]>;
  RentReportedByTenants?: Maybe<Scalars["float8"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  Unnamed0?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Sum_Order_By = {
  Difference?: Maybe<Order_By>;
  GrossDifference?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentDeclaredByLandlord?: Maybe<Order_By>;
  RentReportedByTenants?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  Unnamed0?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Var_Pop_Fields = {
  __typename?: "rtcs_db_ura_LandlordIncomeSummary_var_pop_fields";
  Difference?: Maybe<Scalars["Float"]>;
  GrossDifference?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["Float"]>;
  RentReportedByTenants?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  Unnamed0?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Var_Pop_Order_By = {
  Difference?: Maybe<Order_By>;
  GrossDifference?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentDeclaredByLandlord?: Maybe<Order_By>;
  RentReportedByTenants?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  Unnamed0?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Var_Samp_Fields = {
  __typename?: "rtcs_db_ura_LandlordIncomeSummary_var_samp_fields";
  Difference?: Maybe<Scalars["Float"]>;
  GrossDifference?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["Float"]>;
  RentReportedByTenants?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  Unnamed0?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Var_Samp_Order_By = {
  Difference?: Maybe<Order_By>;
  GrossDifference?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentDeclaredByLandlord?: Maybe<Order_By>;
  RentReportedByTenants?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  Unnamed0?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Variance_Fields = {
  __typename?: "rtcs_db_ura_LandlordIncomeSummary_variance_fields";
  Difference?: Maybe<Scalars["Float"]>;
  GrossDifference?: Maybe<Scalars["Float"]>;
  GrossRentalIncome?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentDeclaredByLandlord?: Maybe<Scalars["Float"]>;
  RentReportedByTenants?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  Unnamed0?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ura_LandlordIncomeSummary" */
export type Rtcs_Db_Ura_LandlordIncomeSummary_Variance_Order_By = {
  Difference?: Maybe<Order_By>;
  GrossDifference?: Maybe<Order_By>;
  GrossRentalIncome?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentDeclaredByLandlord?: Maybe<Order_By>;
  RentReportedByTenants?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  Unnamed0?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness = {
  __typename?: "rtcs_db_ura_NonIndividualsAssociatedBusiness";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  AssociatedNonIndividual?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Aggregate = {
  __typename?: "rtcs_db_ura_NonIndividualsAssociatedBusiness_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness>;
};

/** aggregate fields of "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Aggregate_Fields = {
  __typename?: "rtcs_db_ura_NonIndividualsAssociatedBusiness_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Arr_Rel_Insert_Input =
  {
    data: Array<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Insert_Input>;
  };

/** aggregate avg on columns */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Avg_Fields = {
  __typename?: "rtcs_db_ura_NonIndividualsAssociatedBusiness_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Avg_Order_By = {
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_NonIndividualsAssociatedBusiness". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Bool_Exp = {
  ActvtyDesc?: Maybe<String_Comparison_Exp>;
  AssociateTin?: Maybe<String_Comparison_Exp>;
  AssociatedNonIndividual?: Maybe<String_Comparison_Exp>;
  EntityDesignation?: Maybe<String_Comparison_Exp>;
  FamilyName?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MainActivity?: Maybe<String_Comparison_Exp>;
  OtherName?: Maybe<String_Comparison_Exp>;
  RegAssociatedEntityDtlId?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TaxPayerType?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Insert_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  AssociatedNonIndividual?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Max_Fields = {
  __typename?: "rtcs_db_ura_NonIndividualsAssociatedBusiness_max_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  AssociatedNonIndividual?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Max_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AssociateTin?: Maybe<Order_By>;
  AssociatedNonIndividual?: Maybe<Order_By>;
  EntityDesignation?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  RegAssociatedEntityDtlId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Min_Fields = {
  __typename?: "rtcs_db_ura_NonIndividualsAssociatedBusiness_min_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  AssociatedNonIndividual?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Min_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AssociateTin?: Maybe<Order_By>;
  AssociatedNonIndividual?: Maybe<Order_By>;
  EntityDesignation?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  RegAssociatedEntityDtlId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Mutation_Response = {
  __typename?: "rtcs_db_ura_NonIndividualsAssociatedBusiness_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness>;
};

/** input type for inserting object relation for remote table "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Obj_Rel_Insert_Input =
  {
    data: Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Insert_Input;
  };

/** ordering options when selecting data from "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AssociateTin?: Maybe<Order_By>;
  AssociatedNonIndividual?: Maybe<Order_By>;
  EntityDesignation?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  RegAssociatedEntityDtlId?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
export enum Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Select_Column {
  /** column name */
  ActvtyDesc = "ActvtyDesc",
  /** column name */
  AssociateTin = "AssociateTin",
  /** column name */
  AssociatedNonIndividual = "AssociatedNonIndividual",
  /** column name */
  EntityDesignation = "EntityDesignation",
  /** column name */
  FamilyName = "FamilyName",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MainActivity = "MainActivity",
  /** column name */
  OtherName = "OtherName",
  /** column name */
  RegAssociatedEntityDtlId = "RegAssociatedEntityDtlId",
  /** column name */
  Surname = "Surname",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TaxPayerType = "TaxPayerType",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Set_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  AssociatedNonIndividual?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Stddev_Fields = {
  __typename?: "rtcs_db_ura_NonIndividualsAssociatedBusiness_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ura_NonIndividualsAssociatedBusiness_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ura_NonIndividualsAssociatedBusiness_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Stddev_Samp_Order_By =
  {
    Line?: Maybe<Order_By>;
  };

/** aggregate sum on columns */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Sum_Fields = {
  __typename?: "rtcs_db_ura_NonIndividualsAssociatedBusiness_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Sum_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Var_Pop_Fields = {
  __typename?: "rtcs_db_ura_NonIndividualsAssociatedBusiness_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Var_Samp_Fields = {
  __typename?: "rtcs_db_ura_NonIndividualsAssociatedBusiness_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Variance_Fields = {
  __typename?: "rtcs_db_ura_NonIndividualsAssociatedBusiness_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
export type Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Variance_Order_By = {
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.ura_RegAssociates" */
export type Rtcs_Db_Ura_RegAssociates = {
  __typename?: "rtcs_db_ura_RegAssociates";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidAssociate?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TinNoRegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_RegAssociates" */
export type Rtcs_Db_Ura_RegAssociates_Aggregate = {
  __typename?: "rtcs_db_ura_RegAssociates_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_RegAssociates_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_RegAssociates>;
};

/** aggregate fields of "rtcs_db.ura_RegAssociates" */
export type Rtcs_Db_Ura_RegAssociates_Aggregate_Fields = {
  __typename?: "rtcs_db_ura_RegAssociates_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ura_RegAssociates_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ura_RegAssociates_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ura_RegAssociates_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ura_RegAssociates_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_RegAssociates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_RegAssociates_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ura_RegAssociates_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ura_RegAssociates_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ura_RegAssociates_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ura_RegAssociates_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ura_RegAssociates" */
export type Rtcs_Db_Ura_RegAssociates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ura_RegAssociates_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.ura_RegAssociates" */
export type Rtcs_Db_Ura_RegAssociates_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ura_RegAssociates_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ura_RegAssociates_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ura_RegAssociates_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ura_RegAssociates_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_RegAssociates_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_RegAssociates_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ura_RegAssociates_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ura_RegAssociates_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ura_RegAssociates_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ura_RegAssociates_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ura_RegAssociates" */
export type Rtcs_Db_Ura_RegAssociates_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ura_RegAssociates_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ura_RegAssociates_Avg_Fields = {
  __typename?: "rtcs_db_ura_RegAssociates_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_RegAssociates" */
export type Rtcs_Db_Ura_RegAssociates_Avg_Order_By = {
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_RegAssociates". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_RegAssociates_Bool_Exp = {
  ActvtyDesc?: Maybe<String_Comparison_Exp>;
  AssociateTin?: Maybe<String_Comparison_Exp>;
  EntityDesignation?: Maybe<String_Comparison_Exp>;
  FamilyName?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FirstName?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MainActivity?: Maybe<String_Comparison_Exp>;
  OtherName?: Maybe<String_Comparison_Exp>;
  RegAssociatedEntityDtlId?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  RnidAssociate?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TaxPayerType?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  TinNoRegAssociatedEntityDtlId?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ura_RegAssociates_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ura_RegAssociates_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ura_RegAssociates_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.ura_RegAssociates" */
export type Rtcs_Db_Ura_RegAssociates_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.ura_RegAssociates" */
export type Rtcs_Db_Ura_RegAssociates_Insert_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidAssociate?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TinNoRegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_RegAssociates_Max_Fields = {
  __typename?: "rtcs_db_ura_RegAssociates_max_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidAssociate?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TinNoRegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_RegAssociates" */
export type Rtcs_Db_Ura_RegAssociates_Max_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AssociateTin?: Maybe<Order_By>;
  EntityDesignation?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  RegAssociatedEntityDtlId?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidAssociate?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TinNoRegAssociatedEntityDtlId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_RegAssociates_Min_Fields = {
  __typename?: "rtcs_db_ura_RegAssociates_min_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidAssociate?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TinNoRegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_RegAssociates" */
export type Rtcs_Db_Ura_RegAssociates_Min_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AssociateTin?: Maybe<Order_By>;
  EntityDesignation?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  RegAssociatedEntityDtlId?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidAssociate?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TinNoRegAssociatedEntityDtlId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_RegAssociates" */
export type Rtcs_Db_Ura_RegAssociates_Mutation_Response = {
  __typename?: "rtcs_db_ura_RegAssociates_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ura_RegAssociates>;
};

/** input type for inserting object relation for remote table "rtcs_db.ura_RegAssociates" */
export type Rtcs_Db_Ura_RegAssociates_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ura_RegAssociates_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.ura_RegAssociates" */
export type Rtcs_Db_Ura_RegAssociates_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AssociateTin?: Maybe<Order_By>;
  EntityDesignation?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  RegAssociatedEntityDtlId?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  RnidAssociate?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TinNoRegAssociatedEntityDtlId?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_RegAssociates" */
export enum Rtcs_Db_Ura_RegAssociates_Select_Column {
  /** column name */
  ActvtyDesc = "ActvtyDesc",
  /** column name */
  AssociateTin = "AssociateTin",
  /** column name */
  EntityDesignation = "EntityDesignation",
  /** column name */
  FamilyName = "FamilyName",
  /** column name */
  File = "File",
  /** column name */
  FirstName = "FirstName",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MainActivity = "MainActivity",
  /** column name */
  OtherName = "OtherName",
  /** column name */
  RegAssociatedEntityDtlId = "RegAssociatedEntityDtlId",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  RnidAssociate = "RnidAssociate",
  /** column name */
  Surname = "Surname",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TaxPayerType = "TaxPayerType",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  TinNoRegAssociatedEntityDtlId = "TinNoRegAssociatedEntityDtlId",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_RegAssociates" */
export type Rtcs_Db_Ura_RegAssociates_Set_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AssociateTin?: Maybe<Scalars["String"]>;
  EntityDesignation?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  RegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  RnidAssociate?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TinNoRegAssociatedEntityDtlId?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_RegAssociates_Stddev_Fields = {
  __typename?: "rtcs_db_ura_RegAssociates_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_RegAssociates" */
export type Rtcs_Db_Ura_RegAssociates_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_RegAssociates_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ura_RegAssociates_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ura_RegAssociates" */
export type Rtcs_Db_Ura_RegAssociates_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_RegAssociates_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ura_RegAssociates_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ura_RegAssociates" */
export type Rtcs_Db_Ura_RegAssociates_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ura_RegAssociates_Sum_Fields = {
  __typename?: "rtcs_db_ura_RegAssociates_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_RegAssociates" */
export type Rtcs_Db_Ura_RegAssociates_Sum_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_RegAssociates_Var_Pop_Fields = {
  __typename?: "rtcs_db_ura_RegAssociates_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ura_RegAssociates" */
export type Rtcs_Db_Ura_RegAssociates_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_RegAssociates_Var_Samp_Fields = {
  __typename?: "rtcs_db_ura_RegAssociates_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ura_RegAssociates" */
export type Rtcs_Db_Ura_RegAssociates_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ura_RegAssociates_Variance_Fields = {
  __typename?: "rtcs_db_ura_RegAssociates_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ura_RegAssociates" */
export type Rtcs_Db_Ura_RegAssociates_Variance_Order_By = {
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.ura_RegIndividual" */
export type Rtcs_Db_Ura_RegIndividual = {
  __typename?: "rtcs_db_ura_RegIndividual";
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["String"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_RegIndividual" */
export type Rtcs_Db_Ura_RegIndividual_Aggregate = {
  __typename?: "rtcs_db_ura_RegIndividual_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_RegIndividual_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_RegIndividual>;
};

/** aggregate fields of "rtcs_db.ura_RegIndividual" */
export type Rtcs_Db_Ura_RegIndividual_Aggregate_Fields = {
  __typename?: "rtcs_db_ura_RegIndividual_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ura_RegIndividual_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ura_RegIndividual_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ura_RegIndividual_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ura_RegIndividual_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_RegIndividual_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_RegIndividual_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ura_RegIndividual_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ura_RegIndividual_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ura_RegIndividual_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ura_RegIndividual_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ura_RegIndividual" */
export type Rtcs_Db_Ura_RegIndividual_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ura_RegIndividual_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.ura_RegIndividual" */
export type Rtcs_Db_Ura_RegIndividual_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ura_RegIndividual_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ura_RegIndividual_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ura_RegIndividual_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ura_RegIndividual_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_RegIndividual_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_RegIndividual_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ura_RegIndividual_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ura_RegIndividual_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ura_RegIndividual_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ura_RegIndividual_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ura_RegIndividual" */
export type Rtcs_Db_Ura_RegIndividual_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ura_RegIndividual_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ura_RegIndividual_Avg_Fields = {
  __typename?: "rtcs_db_ura_RegIndividual_avg_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_RegIndividual" */
export type Rtcs_Db_Ura_RegIndividual_Avg_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_RegIndividual". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_RegIndividual_Bool_Exp = {
  AppliedForTin?: Maybe<String_Comparison_Exp>;
  AuthctcdEmailId?: Maybe<String_Comparison_Exp>;
  AuthctcdFamilyName?: Maybe<String_Comparison_Exp>;
  AuthctcdFirstName?: Maybe<String_Comparison_Exp>;
  AuthctcdLandlineNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdMobileNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdOtherName?: Maybe<String_Comparison_Exp>;
  AuthctcdPrsnDesgn?: Maybe<String_Comparison_Exp>;
  AuthctcdSurname?: Maybe<String_Comparison_Exp>;
  AuthctcdTin?: Maybe<String_Comparison_Exp>;
  BirthCity?: Maybe<Bigint_Comparison_Exp>;
  BirthCountry?: Maybe<String_Comparison_Exp>;
  BirthDt?: Maybe<String_Comparison_Exp>;
  BirthState?: Maybe<String_Comparison_Exp>;
  BsnsCertNumber?: Maybe<String_Comparison_Exp>;
  BsnsName?: Maybe<String_Comparison_Exp>;
  CitizenCountry?: Maybe<String_Comparison_Exp>;
  EmailId?: Maybe<String_Comparison_Exp>;
  ExistTinNo?: Maybe<String_Comparison_Exp>;
  FamilyName?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FirstName?: Maybe<String_Comparison_Exp>;
  Gender?: Maybe<String_Comparison_Exp>;
  HomeAddrBuildingname?: Maybe<String_Comparison_Exp>;
  HomeAddrDistrictName?: Maybe<String_Comparison_Exp>;
  HomeAddrLocalCouncil?: Maybe<String_Comparison_Exp>;
  HomeAddrParishName?: Maybe<String_Comparison_Exp>;
  HomeAddrPlotno?: Maybe<String_Comparison_Exp>;
  HomeAddrStrretAddr?: Maybe<String_Comparison_Exp>;
  HomeAddrSubCountyName?: Maybe<String_Comparison_Exp>;
  HomeAddrTownName?: Maybe<String_Comparison_Exp>;
  HomeAddrTradeCentre?: Maybe<String_Comparison_Exp>;
  HomeAddrVillageName?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcEmp?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  LandlineNumber?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  MobileNumber?: Maybe<String_Comparison_Exp>;
  NationalId?: Maybe<String_Comparison_Exp>;
  NssfId?: Maybe<String_Comparison_Exp>;
  OtherName?: Maybe<String_Comparison_Exp>;
  PostaddrDistictName?: Maybe<String_Comparison_Exp>;
  PostaddrId?: Maybe<String_Comparison_Exp>;
  RefEmailId?: Maybe<String_Comparison_Exp>;
  RefFamilyName?: Maybe<String_Comparison_Exp>;
  RefFirstName?: Maybe<String_Comparison_Exp>;
  RefLandlineNumber?: Maybe<String_Comparison_Exp>;
  RefMobileNumber?: Maybe<String_Comparison_Exp>;
  RefOtherName?: Maybe<String_Comparison_Exp>;
  RefPrsnDesgn?: Maybe<String_Comparison_Exp>;
  RefSurname?: Maybe<String_Comparison_Exp>;
  RefTin?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  SurName?: Maybe<String_Comparison_Exp>;
  TaxPayerId?: Maybe<String_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TaxPayerType?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  Uuid?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ura_RegIndividual_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ura_RegIndividual_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ura_RegIndividual_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.ura_RegIndividual" */
export type Rtcs_Db_Ura_RegIndividual_Inc_Input = {
  BirthCity?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.ura_RegIndividual" */
export type Rtcs_Db_Ura_RegIndividual_Insert_Input = {
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["String"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_RegIndividual_Max_Fields = {
  __typename?: "rtcs_db_ura_RegIndividual_max_fields";
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["String"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_RegIndividual" */
export type Rtcs_Db_Ura_RegIndividual_Max_Order_By = {
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SurName?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_RegIndividual_Min_Fields = {
  __typename?: "rtcs_db_ura_RegIndividual_min_fields";
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["String"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_RegIndividual" */
export type Rtcs_Db_Ura_RegIndividual_Min_Order_By = {
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SurName?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_RegIndividual" */
export type Rtcs_Db_Ura_RegIndividual_Mutation_Response = {
  __typename?: "rtcs_db_ura_RegIndividual_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ura_RegIndividual>;
};

/** input type for inserting object relation for remote table "rtcs_db.ura_RegIndividual" */
export type Rtcs_Db_Ura_RegIndividual_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ura_RegIndividual_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.ura_RegIndividual" */
export type Rtcs_Db_Ura_RegIndividual_Order_By = {
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  SurName?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_RegIndividual" */
export enum Rtcs_Db_Ura_RegIndividual_Select_Column {
  /** column name */
  AppliedForTin = "AppliedForTin",
  /** column name */
  AuthctcdEmailId = "AuthctcdEmailId",
  /** column name */
  AuthctcdFamilyName = "AuthctcdFamilyName",
  /** column name */
  AuthctcdFirstName = "AuthctcdFirstName",
  /** column name */
  AuthctcdLandlineNumber = "AuthctcdLandlineNumber",
  /** column name */
  AuthctcdMobileNumber = "AuthctcdMobileNumber",
  /** column name */
  AuthctcdOtherName = "AuthctcdOtherName",
  /** column name */
  AuthctcdPrsnDesgn = "AuthctcdPrsnDesgn",
  /** column name */
  AuthctcdSurname = "AuthctcdSurname",
  /** column name */
  AuthctcdTin = "AuthctcdTin",
  /** column name */
  BirthCity = "BirthCity",
  /** column name */
  BirthCountry = "BirthCountry",
  /** column name */
  BirthDt = "BirthDt",
  /** column name */
  BirthState = "BirthState",
  /** column name */
  BsnsCertNumber = "BsnsCertNumber",
  /** column name */
  BsnsName = "BsnsName",
  /** column name */
  CitizenCountry = "CitizenCountry",
  /** column name */
  EmailId = "EmailId",
  /** column name */
  ExistTinNo = "ExistTinNo",
  /** column name */
  FamilyName = "FamilyName",
  /** column name */
  File = "File",
  /** column name */
  FirstName = "FirstName",
  /** column name */
  Gender = "Gender",
  /** column name */
  HomeAddrBuildingname = "HomeAddrBuildingname",
  /** column name */
  HomeAddrDistrictName = "HomeAddrDistrictName",
  /** column name */
  HomeAddrLocalCouncil = "HomeAddrLocalCouncil",
  /** column name */
  HomeAddrParishName = "HomeAddrParishName",
  /** column name */
  HomeAddrPlotno = "HomeAddrPlotno",
  /** column name */
  HomeAddrStrretAddr = "HomeAddrStrretAddr",
  /** column name */
  HomeAddrSubCountyName = "HomeAddrSubCountyName",
  /** column name */
  HomeAddrTownName = "HomeAddrTownName",
  /** column name */
  HomeAddrTradeCentre = "HomeAddrTradeCentre",
  /** column name */
  HomeAddrVillageName = "HomeAddrVillageName",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcEmp = "IsIncomeSrcEmp",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  LandlineNumber = "LandlineNumber",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  NationalId = "NationalId",
  /** column name */
  NssfId = "NssfId",
  /** column name */
  OtherName = "OtherName",
  /** column name */
  PostaddrDistictName = "PostaddrDistictName",
  /** column name */
  PostaddrId = "PostaddrId",
  /** column name */
  RefEmailId = "RefEmailId",
  /** column name */
  RefFamilyName = "RefFamilyName",
  /** column name */
  RefFirstName = "RefFirstName",
  /** column name */
  RefLandlineNumber = "RefLandlineNumber",
  /** column name */
  RefMobileNumber = "RefMobileNumber",
  /** column name */
  RefOtherName = "RefOtherName",
  /** column name */
  RefPrsnDesgn = "RefPrsnDesgn",
  /** column name */
  RefSurname = "RefSurname",
  /** column name */
  RefTin = "RefTin",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  SurName = "SurName",
  /** column name */
  TaxPayerId = "TaxPayerId",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TaxPayerType = "TaxPayerType",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  Uuid = "Uuid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_RegIndividual" */
export type Rtcs_Db_Ura_RegIndividual_Set_Input = {
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["bigint"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["String"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.ura_RegIndividual_sid" */
export type Rtcs_Db_Ura_RegIndividual_Sid = {
  __typename?: "rtcs_db_ura_RegIndividual_sid";
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["float8"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["date"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_RegIndividual_sid" */
export type Rtcs_Db_Ura_RegIndividual_Sid_Aggregate = {
  __typename?: "rtcs_db_ura_RegIndividual_sid_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_RegIndividual_Sid>;
};

/** aggregate fields of "rtcs_db.ura_RegIndividual_sid" */
export type Rtcs_Db_Ura_RegIndividual_Sid_Aggregate_Fields = {
  __typename?: "rtcs_db_ura_RegIndividual_sid_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ura_RegIndividual_sid" */
export type Rtcs_Db_Ura_RegIndividual_Sid_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ura_RegIndividual_Sid_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.ura_RegIndividual_sid" */
export type Rtcs_Db_Ura_RegIndividual_Sid_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ura_RegIndividual_sid" */
export type Rtcs_Db_Ura_RegIndividual_Sid_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ura_RegIndividual_Sid_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ura_RegIndividual_Sid_Avg_Fields = {
  __typename?: "rtcs_db_ura_RegIndividual_sid_avg_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_RegIndividual_sid" */
export type Rtcs_Db_Ura_RegIndividual_Sid_Avg_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_RegIndividual_sid". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_RegIndividual_Sid_Bool_Exp = {
  AppliedForTin?: Maybe<String_Comparison_Exp>;
  AuthctcdEmailId?: Maybe<String_Comparison_Exp>;
  AuthctcdFamilyName?: Maybe<String_Comparison_Exp>;
  AuthctcdFirstName?: Maybe<String_Comparison_Exp>;
  AuthctcdLandlineNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdMobileNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdOtherName?: Maybe<String_Comparison_Exp>;
  AuthctcdPrsnDesgn?: Maybe<String_Comparison_Exp>;
  AuthctcdSurname?: Maybe<String_Comparison_Exp>;
  AuthctcdTin?: Maybe<String_Comparison_Exp>;
  BirthCity?: Maybe<Float8_Comparison_Exp>;
  BirthCountry?: Maybe<String_Comparison_Exp>;
  BirthDt?: Maybe<Date_Comparison_Exp>;
  BirthState?: Maybe<String_Comparison_Exp>;
  BsnsName?: Maybe<String_Comparison_Exp>;
  CitizenCountry?: Maybe<String_Comparison_Exp>;
  EmailId?: Maybe<String_Comparison_Exp>;
  ExistTinNo?: Maybe<String_Comparison_Exp>;
  FamilyName?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  FirstName?: Maybe<String_Comparison_Exp>;
  Gender?: Maybe<String_Comparison_Exp>;
  HomeAddrBuildingname?: Maybe<String_Comparison_Exp>;
  HomeAddrDistrictName?: Maybe<String_Comparison_Exp>;
  HomeAddrLocalCouncil?: Maybe<String_Comparison_Exp>;
  HomeAddrParishName?: Maybe<String_Comparison_Exp>;
  HomeAddrPlotno?: Maybe<String_Comparison_Exp>;
  HomeAddrStrretAddr?: Maybe<String_Comparison_Exp>;
  HomeAddrSubCountyName?: Maybe<String_Comparison_Exp>;
  HomeAddrTownName?: Maybe<String_Comparison_Exp>;
  HomeAddrTradeCentre?: Maybe<String_Comparison_Exp>;
  HomeAddrVillageName?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcEmp?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  LandlineNumber?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MiddleName?: Maybe<String_Comparison_Exp>;
  MobileNumber?: Maybe<String_Comparison_Exp>;
  NationalId?: Maybe<String_Comparison_Exp>;
  NssfId?: Maybe<String_Comparison_Exp>;
  OtherName?: Maybe<String_Comparison_Exp>;
  PostaddrDistictName?: Maybe<String_Comparison_Exp>;
  PostaddrId?: Maybe<String_Comparison_Exp>;
  RefEmailId?: Maybe<String_Comparison_Exp>;
  RefFamilyName?: Maybe<String_Comparison_Exp>;
  RefFirstName?: Maybe<String_Comparison_Exp>;
  RefLandlineNumber?: Maybe<String_Comparison_Exp>;
  RefMobileNumber?: Maybe<String_Comparison_Exp>;
  RefOtherName?: Maybe<String_Comparison_Exp>;
  RefPrsnDesgn?: Maybe<String_Comparison_Exp>;
  RefSurname?: Maybe<String_Comparison_Exp>;
  RefTin?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Sid?: Maybe<String_Comparison_Exp>;
  SurName?: Maybe<String_Comparison_Exp>;
  TaxPayerId?: Maybe<String_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TaxPayerType?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  VisibleLevel?: Maybe<Bigint_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.ura_RegIndividual_sid" */
export type Rtcs_Db_Ura_RegIndividual_Sid_Inc_Input = {
  BirthCity?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.ura_RegIndividual_sid" */
export type Rtcs_Db_Ura_RegIndividual_Sid_Insert_Input = {
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["float8"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["date"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_RegIndividual_Sid_Max_Fields = {
  __typename?: "rtcs_db_ura_RegIndividual_sid_max_fields";
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["float8"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["date"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_RegIndividual_sid" */
export type Rtcs_Db_Ura_RegIndividual_Sid_Max_Order_By = {
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Sid?: Maybe<Order_By>;
  SurName?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_RegIndividual_Sid_Min_Fields = {
  __typename?: "rtcs_db_ura_RegIndividual_sid_min_fields";
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["float8"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["date"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_RegIndividual_sid" */
export type Rtcs_Db_Ura_RegIndividual_Sid_Min_Order_By = {
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Sid?: Maybe<Order_By>;
  SurName?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_RegIndividual_sid" */
export type Rtcs_Db_Ura_RegIndividual_Sid_Mutation_Response = {
  __typename?: "rtcs_db_ura_RegIndividual_sid_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ura_RegIndividual_Sid>;
};

/** input type for inserting object relation for remote table "rtcs_db.ura_RegIndividual_sid" */
export type Rtcs_Db_Ura_RegIndividual_Sid_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ura_RegIndividual_Sid_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.ura_RegIndividual_sid" */
export type Rtcs_Db_Ura_RegIndividual_Sid_Order_By = {
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BirthCity?: Maybe<Order_By>;
  BirthCountry?: Maybe<Order_By>;
  BirthDt?: Maybe<Order_By>;
  BirthState?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  CitizenCountry?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  FamilyName?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Gender?: Maybe<Order_By>;
  HomeAddrBuildingname?: Maybe<Order_By>;
  HomeAddrDistrictName?: Maybe<Order_By>;
  HomeAddrLocalCouncil?: Maybe<Order_By>;
  HomeAddrParishName?: Maybe<Order_By>;
  HomeAddrPlotno?: Maybe<Order_By>;
  HomeAddrStrretAddr?: Maybe<Order_By>;
  HomeAddrSubCountyName?: Maybe<Order_By>;
  HomeAddrTownName?: Maybe<Order_By>;
  HomeAddrTradeCentre?: Maybe<Order_By>;
  HomeAddrVillageName?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcEmp?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MiddleName?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  NationalId?: Maybe<Order_By>;
  NssfId?: Maybe<Order_By>;
  OtherName?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Sid?: Maybe<Order_By>;
  SurName?: Maybe<Order_By>;
  TaxPayerId?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_RegIndividual_sid" */
export enum Rtcs_Db_Ura_RegIndividual_Sid_Select_Column {
  /** column name */
  AppliedForTin = "AppliedForTin",
  /** column name */
  AuthctcdEmailId = "AuthctcdEmailId",
  /** column name */
  AuthctcdFamilyName = "AuthctcdFamilyName",
  /** column name */
  AuthctcdFirstName = "AuthctcdFirstName",
  /** column name */
  AuthctcdLandlineNumber = "AuthctcdLandlineNumber",
  /** column name */
  AuthctcdMobileNumber = "AuthctcdMobileNumber",
  /** column name */
  AuthctcdOtherName = "AuthctcdOtherName",
  /** column name */
  AuthctcdPrsnDesgn = "AuthctcdPrsnDesgn",
  /** column name */
  AuthctcdSurname = "AuthctcdSurname",
  /** column name */
  AuthctcdTin = "AuthctcdTin",
  /** column name */
  BirthCity = "BirthCity",
  /** column name */
  BirthCountry = "BirthCountry",
  /** column name */
  BirthDt = "BirthDt",
  /** column name */
  BirthState = "BirthState",
  /** column name */
  BsnsName = "BsnsName",
  /** column name */
  CitizenCountry = "CitizenCountry",
  /** column name */
  EmailId = "EmailId",
  /** column name */
  ExistTinNo = "ExistTinNo",
  /** column name */
  FamilyName = "FamilyName",
  /** column name */
  File = "File",
  /** column name */
  FirstName = "FirstName",
  /** column name */
  Gender = "Gender",
  /** column name */
  HomeAddrBuildingname = "HomeAddrBuildingname",
  /** column name */
  HomeAddrDistrictName = "HomeAddrDistrictName",
  /** column name */
  HomeAddrLocalCouncil = "HomeAddrLocalCouncil",
  /** column name */
  HomeAddrParishName = "HomeAddrParishName",
  /** column name */
  HomeAddrPlotno = "HomeAddrPlotno",
  /** column name */
  HomeAddrStrretAddr = "HomeAddrStrretAddr",
  /** column name */
  HomeAddrSubCountyName = "HomeAddrSubCountyName",
  /** column name */
  HomeAddrTownName = "HomeAddrTownName",
  /** column name */
  HomeAddrTradeCentre = "HomeAddrTradeCentre",
  /** column name */
  HomeAddrVillageName = "HomeAddrVillageName",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcEmp = "IsIncomeSrcEmp",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  LandlineNumber = "LandlineNumber",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MiddleName = "MiddleName",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  NationalId = "NationalId",
  /** column name */
  NssfId = "NssfId",
  /** column name */
  OtherName = "OtherName",
  /** column name */
  PostaddrDistictName = "PostaddrDistictName",
  /** column name */
  PostaddrId = "PostaddrId",
  /** column name */
  RefEmailId = "RefEmailId",
  /** column name */
  RefFamilyName = "RefFamilyName",
  /** column name */
  RefFirstName = "RefFirstName",
  /** column name */
  RefLandlineNumber = "RefLandlineNumber",
  /** column name */
  RefMobileNumber = "RefMobileNumber",
  /** column name */
  RefOtherName = "RefOtherName",
  /** column name */
  RefPrsnDesgn = "RefPrsnDesgn",
  /** column name */
  RefSurname = "RefSurname",
  /** column name */
  RefTin = "RefTin",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Sid = "Sid",
  /** column name */
  SurName = "SurName",
  /** column name */
  TaxPayerId = "TaxPayerId",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TaxPayerType = "TaxPayerType",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  VisibleLevel = "VisibleLevel",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_RegIndividual_sid" */
export type Rtcs_Db_Ura_RegIndividual_Sid_Set_Input = {
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BirthCity?: Maybe<Scalars["float8"]>;
  BirthCountry?: Maybe<Scalars["String"]>;
  BirthDt?: Maybe<Scalars["date"]>;
  BirthState?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  CitizenCountry?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  FamilyName?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  FirstName?: Maybe<Scalars["String"]>;
  Gender?: Maybe<Scalars["String"]>;
  HomeAddrBuildingname?: Maybe<Scalars["String"]>;
  HomeAddrDistrictName?: Maybe<Scalars["String"]>;
  HomeAddrLocalCouncil?: Maybe<Scalars["String"]>;
  HomeAddrParishName?: Maybe<Scalars["String"]>;
  HomeAddrPlotno?: Maybe<Scalars["String"]>;
  HomeAddrStrretAddr?: Maybe<Scalars["String"]>;
  HomeAddrSubCountyName?: Maybe<Scalars["String"]>;
  HomeAddrTownName?: Maybe<Scalars["String"]>;
  HomeAddrTradeCentre?: Maybe<Scalars["String"]>;
  HomeAddrVillageName?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcEmp?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MiddleName?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  NationalId?: Maybe<Scalars["String"]>;
  NssfId?: Maybe<Scalars["String"]>;
  OtherName?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  SurName?: Maybe<Scalars["String"]>;
  TaxPayerId?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_RegIndividual_Sid_Stddev_Fields = {
  __typename?: "rtcs_db_ura_RegIndividual_sid_stddev_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_RegIndividual_sid" */
export type Rtcs_Db_Ura_RegIndividual_Sid_Stddev_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_RegIndividual_Sid_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ura_RegIndividual_sid_stddev_pop_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ura_RegIndividual_sid" */
export type Rtcs_Db_Ura_RegIndividual_Sid_Stddev_Pop_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_RegIndividual_Sid_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ura_RegIndividual_sid_stddev_samp_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ura_RegIndividual_sid" */
export type Rtcs_Db_Ura_RegIndividual_Sid_Stddev_Samp_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ura_RegIndividual_Sid_Sum_Fields = {
  __typename?: "rtcs_db_ura_RegIndividual_sid_sum_fields";
  BirthCity?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  VisibleLevel?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_RegIndividual_sid" */
export type Rtcs_Db_Ura_RegIndividual_Sid_Sum_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_RegIndividual_Sid_Var_Pop_Fields = {
  __typename?: "rtcs_db_ura_RegIndividual_sid_var_pop_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ura_RegIndividual_sid" */
export type Rtcs_Db_Ura_RegIndividual_Sid_Var_Pop_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_RegIndividual_Sid_Var_Samp_Fields = {
  __typename?: "rtcs_db_ura_RegIndividual_sid_var_samp_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ura_RegIndividual_sid" */
export type Rtcs_Db_Ura_RegIndividual_Sid_Var_Samp_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ura_RegIndividual_Sid_Variance_Fields = {
  __typename?: "rtcs_db_ura_RegIndividual_sid_variance_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  VisibleLevel?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ura_RegIndividual_sid" */
export type Rtcs_Db_Ura_RegIndividual_Sid_Variance_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  VisibleLevel?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_RegIndividual_Stddev_Fields = {
  __typename?: "rtcs_db_ura_RegIndividual_stddev_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_RegIndividual" */
export type Rtcs_Db_Ura_RegIndividual_Stddev_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_RegIndividual_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ura_RegIndividual_stddev_pop_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ura_RegIndividual" */
export type Rtcs_Db_Ura_RegIndividual_Stddev_Pop_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_RegIndividual_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ura_RegIndividual_stddev_samp_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ura_RegIndividual" */
export type Rtcs_Db_Ura_RegIndividual_Stddev_Samp_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ura_RegIndividual_Sum_Fields = {
  __typename?: "rtcs_db_ura_RegIndividual_sum_fields";
  BirthCity?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_RegIndividual" */
export type Rtcs_Db_Ura_RegIndividual_Sum_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_RegIndividual_Var_Pop_Fields = {
  __typename?: "rtcs_db_ura_RegIndividual_var_pop_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ura_RegIndividual" */
export type Rtcs_Db_Ura_RegIndividual_Var_Pop_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_RegIndividual_Var_Samp_Fields = {
  __typename?: "rtcs_db_ura_RegIndividual_var_samp_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ura_RegIndividual" */
export type Rtcs_Db_Ura_RegIndividual_Var_Samp_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ura_RegIndividual_Variance_Fields = {
  __typename?: "rtcs_db_ura_RegIndividual_variance_fields";
  BirthCity?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ura_RegIndividual" */
export type Rtcs_Db_Ura_RegIndividual_Variance_Order_By = {
  BirthCity?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.ura_RegNonIndividual" */
export type Rtcs_Db_Ura_RegNonIndividual = {
  __typename?: "rtcs_db_ura_RegNonIndividual";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["timestamptz"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_RegNonIndividual" */
export type Rtcs_Db_Ura_RegNonIndividual_Aggregate = {
  __typename?: "rtcs_db_ura_RegNonIndividual_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_RegNonIndividual>;
};

/** aggregate fields of "rtcs_db.ura_RegNonIndividual" */
export type Rtcs_Db_Ura_RegNonIndividual_Aggregate_Fields = {
  __typename?: "rtcs_db_ura_RegNonIndividual_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ura_RegNonIndividual" */
export type Rtcs_Db_Ura_RegNonIndividual_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ura_RegNonIndividual_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.ura_RegNonIndividual" */
export type Rtcs_Db_Ura_RegNonIndividual_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ura_RegNonIndividual" */
export type Rtcs_Db_Ura_RegNonIndividual_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ura_RegNonIndividual_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ura_RegNonIndividual_Avg_Fields = {
  __typename?: "rtcs_db_ura_RegNonIndividual_avg_fields";
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_RegNonIndividual" */
export type Rtcs_Db_Ura_RegNonIndividual_Avg_Order_By = {
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_RegNonIndividual". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_RegNonIndividual_Bool_Exp = {
  ActvtyDesc?: Maybe<String_Comparison_Exp>;
  AppliedForTin?: Maybe<String_Comparison_Exp>;
  AuthctcdEmailId?: Maybe<String_Comparison_Exp>;
  AuthctcdFamilyName?: Maybe<String_Comparison_Exp>;
  AuthctcdFirstName?: Maybe<String_Comparison_Exp>;
  AuthctcdLandlineNumber?: Maybe<Float8_Comparison_Exp>;
  AuthctcdMobileNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdOtherName?: Maybe<String_Comparison_Exp>;
  AuthctcdPrsnDesgn?: Maybe<String_Comparison_Exp>;
  AuthctcdSurname?: Maybe<String_Comparison_Exp>;
  AuthctcdTin?: Maybe<String_Comparison_Exp>;
  BsnsCertNumber?: Maybe<String_Comparison_Exp>;
  BsnsName?: Maybe<String_Comparison_Exp>;
  BsnsaddrBuildname?: Maybe<String_Comparison_Exp>;
  BsnsaddrDistrictName?: Maybe<String_Comparison_Exp>;
  BsnsaddrLocalCouncil?: Maybe<String_Comparison_Exp>;
  BsnsaddrParishName?: Maybe<String_Comparison_Exp>;
  BsnsaddrPlotNo?: Maybe<String_Comparison_Exp>;
  BsnsaddrStreetAddr?: Maybe<String_Comparison_Exp>;
  BsnsaddrSubCountyName?: Maybe<String_Comparison_Exp>;
  BsnsaddrTownName?: Maybe<String_Comparison_Exp>;
  BsnsaddrTradeCentre?: Maybe<String_Comparison_Exp>;
  BsnsaddrVillageName?: Maybe<String_Comparison_Exp>;
  BusinessStartDt?: Maybe<Timestamptz_Comparison_Exp>;
  EmailId?: Maybe<String_Comparison_Exp>;
  ExistTinNo?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  HoldingCompName?: Maybe<String_Comparison_Exp>;
  HoldingCompTin?: Maybe<String_Comparison_Exp>;
  IsHldCmpInUganda?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  IsSubsidaryComp?: Maybe<String_Comparison_Exp>;
  LandlineNumber?: Maybe<Bigint_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MainActivity?: Maybe<String_Comparison_Exp>;
  MobileNumber?: Maybe<String_Comparison_Exp>;
  OrgSubType?: Maybe<String_Comparison_Exp>;
  OrgSubTypeDesc?: Maybe<String_Comparison_Exp>;
  OrgType?: Maybe<String_Comparison_Exp>;
  PostaddrDistictName?: Maybe<String_Comparison_Exp>;
  PostaddrId?: Maybe<String_Comparison_Exp>;
  RefEmailId?: Maybe<String_Comparison_Exp>;
  RefFamilyName?: Maybe<String_Comparison_Exp>;
  RefFirstName?: Maybe<String_Comparison_Exp>;
  RefLandlineNumber?: Maybe<String_Comparison_Exp>;
  RefMiddleName?: Maybe<String_Comparison_Exp>;
  RefMobileNumber?: Maybe<String_Comparison_Exp>;
  RefOtherName?: Maybe<String_Comparison_Exp>;
  RefPrsnDesgn?: Maybe<String_Comparison_Exp>;
  RefSurname?: Maybe<String_Comparison_Exp>;
  RefTin?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TaxPayerType?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  TradingName?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ura_RegNonIndividual_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ura_RegNonIndividual_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.ura_RegNonIndividual" */
export type Rtcs_Db_Ura_RegNonIndividual_Inc_Input = {
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  LandlineNumber?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.ura_RegNonIndividual" */
export type Rtcs_Db_Ura_RegNonIndividual_Insert_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["timestamptz"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_RegNonIndividual_Max_Fields = {
  __typename?: "rtcs_db_ura_RegNonIndividual_max_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["timestamptz"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_RegNonIndividual" */
export type Rtcs_Db_Ura_RegNonIndividual_Max_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BsnsaddrBuildname?: Maybe<Order_By>;
  BsnsaddrDistrictName?: Maybe<Order_By>;
  BsnsaddrLocalCouncil?: Maybe<Order_By>;
  BsnsaddrParishName?: Maybe<Order_By>;
  BsnsaddrPlotNo?: Maybe<Order_By>;
  BsnsaddrStreetAddr?: Maybe<Order_By>;
  BsnsaddrSubCountyName?: Maybe<Order_By>;
  BsnsaddrTownName?: Maybe<Order_By>;
  BsnsaddrTradeCentre?: Maybe<Order_By>;
  BsnsaddrVillageName?: Maybe<Order_By>;
  BusinessStartDt?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  HoldingCompName?: Maybe<Order_By>;
  HoldingCompTin?: Maybe<Order_By>;
  IsHldCmpInUganda?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  IsSubsidaryComp?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  OrgSubType?: Maybe<Order_By>;
  OrgSubTypeDesc?: Maybe<Order_By>;
  OrgType?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMiddleName?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TradingName?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_RegNonIndividual_Min_Fields = {
  __typename?: "rtcs_db_ura_RegNonIndividual_min_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["timestamptz"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_RegNonIndividual" */
export type Rtcs_Db_Ura_RegNonIndividual_Min_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BsnsaddrBuildname?: Maybe<Order_By>;
  BsnsaddrDistrictName?: Maybe<Order_By>;
  BsnsaddrLocalCouncil?: Maybe<Order_By>;
  BsnsaddrParishName?: Maybe<Order_By>;
  BsnsaddrPlotNo?: Maybe<Order_By>;
  BsnsaddrStreetAddr?: Maybe<Order_By>;
  BsnsaddrSubCountyName?: Maybe<Order_By>;
  BsnsaddrTownName?: Maybe<Order_By>;
  BsnsaddrTradeCentre?: Maybe<Order_By>;
  BsnsaddrVillageName?: Maybe<Order_By>;
  BusinessStartDt?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  HoldingCompName?: Maybe<Order_By>;
  HoldingCompTin?: Maybe<Order_By>;
  IsHldCmpInUganda?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  IsSubsidaryComp?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  OrgSubType?: Maybe<Order_By>;
  OrgSubTypeDesc?: Maybe<Order_By>;
  OrgType?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMiddleName?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TradingName?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_RegNonIndividual" */
export type Rtcs_Db_Ura_RegNonIndividual_Mutation_Response = {
  __typename?: "rtcs_db_ura_RegNonIndividual_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ura_RegNonIndividual>;
};

/** input type for inserting object relation for remote table "rtcs_db.ura_RegNonIndividual" */
export type Rtcs_Db_Ura_RegNonIndividual_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ura_RegNonIndividual_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.ura_RegNonIndividual" */
export type Rtcs_Db_Ura_RegNonIndividual_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  AuthctcdTin?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BsnsaddrBuildname?: Maybe<Order_By>;
  BsnsaddrDistrictName?: Maybe<Order_By>;
  BsnsaddrLocalCouncil?: Maybe<Order_By>;
  BsnsaddrParishName?: Maybe<Order_By>;
  BsnsaddrPlotNo?: Maybe<Order_By>;
  BsnsaddrStreetAddr?: Maybe<Order_By>;
  BsnsaddrSubCountyName?: Maybe<Order_By>;
  BsnsaddrTownName?: Maybe<Order_By>;
  BsnsaddrTradeCentre?: Maybe<Order_By>;
  BsnsaddrVillageName?: Maybe<Order_By>;
  BusinessStartDt?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  HoldingCompName?: Maybe<Order_By>;
  HoldingCompTin?: Maybe<Order_By>;
  IsHldCmpInUganda?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  IsSubsidaryComp?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  OrgSubType?: Maybe<Order_By>;
  OrgSubTypeDesc?: Maybe<Order_By>;
  OrgType?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMiddleName?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TradingName?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_RegNonIndividual" */
export enum Rtcs_Db_Ura_RegNonIndividual_Select_Column {
  /** column name */
  ActvtyDesc = "ActvtyDesc",
  /** column name */
  AppliedForTin = "AppliedForTin",
  /** column name */
  AuthctcdEmailId = "AuthctcdEmailId",
  /** column name */
  AuthctcdFamilyName = "AuthctcdFamilyName",
  /** column name */
  AuthctcdFirstName = "AuthctcdFirstName",
  /** column name */
  AuthctcdLandlineNumber = "AuthctcdLandlineNumber",
  /** column name */
  AuthctcdMobileNumber = "AuthctcdMobileNumber",
  /** column name */
  AuthctcdOtherName = "AuthctcdOtherName",
  /** column name */
  AuthctcdPrsnDesgn = "AuthctcdPrsnDesgn",
  /** column name */
  AuthctcdSurname = "AuthctcdSurname",
  /** column name */
  AuthctcdTin = "AuthctcdTin",
  /** column name */
  BsnsCertNumber = "BsnsCertNumber",
  /** column name */
  BsnsName = "BsnsName",
  /** column name */
  BsnsaddrBuildname = "BsnsaddrBuildname",
  /** column name */
  BsnsaddrDistrictName = "BsnsaddrDistrictName",
  /** column name */
  BsnsaddrLocalCouncil = "BsnsaddrLocalCouncil",
  /** column name */
  BsnsaddrParishName = "BsnsaddrParishName",
  /** column name */
  BsnsaddrPlotNo = "BsnsaddrPlotNo",
  /** column name */
  BsnsaddrStreetAddr = "BsnsaddrStreetAddr",
  /** column name */
  BsnsaddrSubCountyName = "BsnsaddrSubCountyName",
  /** column name */
  BsnsaddrTownName = "BsnsaddrTownName",
  /** column name */
  BsnsaddrTradeCentre = "BsnsaddrTradeCentre",
  /** column name */
  BsnsaddrVillageName = "BsnsaddrVillageName",
  /** column name */
  BusinessStartDt = "BusinessStartDt",
  /** column name */
  EmailId = "EmailId",
  /** column name */
  ExistTinNo = "ExistTinNo",
  /** column name */
  File = "File",
  /** column name */
  HoldingCompName = "HoldingCompName",
  /** column name */
  HoldingCompTin = "HoldingCompTin",
  /** column name */
  IsHldCmpInUganda = "IsHldCmpInUganda",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  IsSubsidaryComp = "IsSubsidaryComp",
  /** column name */
  LandlineNumber = "LandlineNumber",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MainActivity = "MainActivity",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  OrgSubType = "OrgSubType",
  /** column name */
  OrgSubTypeDesc = "OrgSubTypeDesc",
  /** column name */
  OrgType = "OrgType",
  /** column name */
  PostaddrDistictName = "PostaddrDistictName",
  /** column name */
  PostaddrId = "PostaddrId",
  /** column name */
  RefEmailId = "RefEmailId",
  /** column name */
  RefFamilyName = "RefFamilyName",
  /** column name */
  RefFirstName = "RefFirstName",
  /** column name */
  RefLandlineNumber = "RefLandlineNumber",
  /** column name */
  RefMiddleName = "RefMiddleName",
  /** column name */
  RefMobileNumber = "RefMobileNumber",
  /** column name */
  RefOtherName = "RefOtherName",
  /** column name */
  RefPrsnDesgn = "RefPrsnDesgn",
  /** column name */
  RefSurname = "RefSurname",
  /** column name */
  RefTin = "RefTin",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TaxPayerType = "TaxPayerType",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  TradingName = "TradingName",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_RegNonIndividual" */
export type Rtcs_Db_Ura_RegNonIndividual_Set_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  AuthctcdTin?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["timestamptz"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["bigint"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["String"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "rtcs_db.ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ura_RegNonIndividual_Sid = {
  __typename?: "rtcs_db_ura_RegNonIndividual_sid";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["float8"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Aggregate = {
  __typename?: "rtcs_db_ura_RegNonIndividual_sid_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_RegNonIndividual_Sid>;
};

/** aggregate fields of "rtcs_db.ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Aggregate_Fields = {
  __typename?: "rtcs_db_ura_RegNonIndividual_sid_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ura_RegNonIndividual_Sid_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ura_RegNonIndividual_Sid_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Avg_Fields = {
  __typename?: "rtcs_db_ura_RegNonIndividual_sid_avg_fields";
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MobileNumber?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Avg_Order_By = {
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_RegNonIndividual_sid". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Bool_Exp = {
  ActvtyDesc?: Maybe<String_Comparison_Exp>;
  AppliedForTin?: Maybe<String_Comparison_Exp>;
  AuthctcdEmailId?: Maybe<String_Comparison_Exp>;
  AuthctcdFamilyName?: Maybe<String_Comparison_Exp>;
  AuthctcdFirstName?: Maybe<String_Comparison_Exp>;
  AuthctcdLandlineNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdMobileNumber?: Maybe<String_Comparison_Exp>;
  AuthctcdOtherName?: Maybe<String_Comparison_Exp>;
  AuthctcdPrsnDesgn?: Maybe<String_Comparison_Exp>;
  AuthctcdSurname?: Maybe<String_Comparison_Exp>;
  BsnsCertNumber?: Maybe<String_Comparison_Exp>;
  BsnsName?: Maybe<String_Comparison_Exp>;
  BsnsaddrBuildname?: Maybe<String_Comparison_Exp>;
  BsnsaddrDistrictName?: Maybe<String_Comparison_Exp>;
  BsnsaddrLocalCouncil?: Maybe<String_Comparison_Exp>;
  BsnsaddrParishName?: Maybe<String_Comparison_Exp>;
  BsnsaddrPlotNo?: Maybe<String_Comparison_Exp>;
  BsnsaddrStreetAddr?: Maybe<String_Comparison_Exp>;
  BsnsaddrSubCountyName?: Maybe<String_Comparison_Exp>;
  BsnsaddrTownName?: Maybe<String_Comparison_Exp>;
  BsnsaddrTradeCentre?: Maybe<String_Comparison_Exp>;
  BsnsaddrVillageName?: Maybe<String_Comparison_Exp>;
  BusinessStartDt?: Maybe<String_Comparison_Exp>;
  EmailId?: Maybe<String_Comparison_Exp>;
  ExistTinNo?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  HoldingCompName?: Maybe<String_Comparison_Exp>;
  HoldingCompTin?: Maybe<String_Comparison_Exp>;
  IsHldCmpInUganda?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcBsns?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcPrpty?: Maybe<String_Comparison_Exp>;
  IsIncomeSrcRental?: Maybe<String_Comparison_Exp>;
  IsSubsidaryComp?: Maybe<String_Comparison_Exp>;
  LandlineNumber?: Maybe<Float8_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MainActivity?: Maybe<String_Comparison_Exp>;
  MobileNumber?: Maybe<Float8_Comparison_Exp>;
  OrgSubType?: Maybe<String_Comparison_Exp>;
  OrgSubTypeDesc?: Maybe<String_Comparison_Exp>;
  OrgType?: Maybe<String_Comparison_Exp>;
  PostaddrDistictName?: Maybe<String_Comparison_Exp>;
  PostaddrId?: Maybe<String_Comparison_Exp>;
  RefEmailId?: Maybe<String_Comparison_Exp>;
  RefFamilyName?: Maybe<String_Comparison_Exp>;
  RefFirstName?: Maybe<String_Comparison_Exp>;
  RefLandlineNumber?: Maybe<String_Comparison_Exp>;
  RefMiddleName?: Maybe<String_Comparison_Exp>;
  RefMobileNumber?: Maybe<String_Comparison_Exp>;
  RefOtherName?: Maybe<String_Comparison_Exp>;
  RefPrsnDesgn?: Maybe<String_Comparison_Exp>;
  RefSurname?: Maybe<String_Comparison_Exp>;
  RefTin?: Maybe<String_Comparison_Exp>;
  Rnid?: Maybe<String_Comparison_Exp>;
  Sid?: Maybe<String_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TaxPayerType?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  TradingName?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Inc_Input = {
  LandlineNumber?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Insert_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["float8"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Max_Fields = {
  __typename?: "rtcs_db_ura_RegNonIndividual_sid_max_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["float8"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Max_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BsnsaddrBuildname?: Maybe<Order_By>;
  BsnsaddrDistrictName?: Maybe<Order_By>;
  BsnsaddrLocalCouncil?: Maybe<Order_By>;
  BsnsaddrParishName?: Maybe<Order_By>;
  BsnsaddrPlotNo?: Maybe<Order_By>;
  BsnsaddrStreetAddr?: Maybe<Order_By>;
  BsnsaddrSubCountyName?: Maybe<Order_By>;
  BsnsaddrTownName?: Maybe<Order_By>;
  BsnsaddrTradeCentre?: Maybe<Order_By>;
  BsnsaddrVillageName?: Maybe<Order_By>;
  BusinessStartDt?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  HoldingCompName?: Maybe<Order_By>;
  HoldingCompTin?: Maybe<Order_By>;
  IsHldCmpInUganda?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  IsSubsidaryComp?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  OrgSubType?: Maybe<Order_By>;
  OrgSubTypeDesc?: Maybe<Order_By>;
  OrgType?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMiddleName?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Sid?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TradingName?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Min_Fields = {
  __typename?: "rtcs_db_ura_RegNonIndividual_sid_min_fields";
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["float8"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Min_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BsnsaddrBuildname?: Maybe<Order_By>;
  BsnsaddrDistrictName?: Maybe<Order_By>;
  BsnsaddrLocalCouncil?: Maybe<Order_By>;
  BsnsaddrParishName?: Maybe<Order_By>;
  BsnsaddrPlotNo?: Maybe<Order_By>;
  BsnsaddrStreetAddr?: Maybe<Order_By>;
  BsnsaddrSubCountyName?: Maybe<Order_By>;
  BsnsaddrTownName?: Maybe<Order_By>;
  BsnsaddrTradeCentre?: Maybe<Order_By>;
  BsnsaddrVillageName?: Maybe<Order_By>;
  BusinessStartDt?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  HoldingCompName?: Maybe<Order_By>;
  HoldingCompTin?: Maybe<Order_By>;
  IsHldCmpInUganda?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  IsSubsidaryComp?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  OrgSubType?: Maybe<Order_By>;
  OrgSubTypeDesc?: Maybe<Order_By>;
  OrgType?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMiddleName?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Sid?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TradingName?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Mutation_Response = {
  __typename?: "rtcs_db_ura_RegNonIndividual_sid_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ura_RegNonIndividual_Sid>;
};

/** input type for inserting object relation for remote table "rtcs_db.ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ura_RegNonIndividual_Sid_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Order_By = {
  ActvtyDesc?: Maybe<Order_By>;
  AppliedForTin?: Maybe<Order_By>;
  AuthctcdEmailId?: Maybe<Order_By>;
  AuthctcdFamilyName?: Maybe<Order_By>;
  AuthctcdFirstName?: Maybe<Order_By>;
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  AuthctcdMobileNumber?: Maybe<Order_By>;
  AuthctcdOtherName?: Maybe<Order_By>;
  AuthctcdPrsnDesgn?: Maybe<Order_By>;
  AuthctcdSurname?: Maybe<Order_By>;
  BsnsCertNumber?: Maybe<Order_By>;
  BsnsName?: Maybe<Order_By>;
  BsnsaddrBuildname?: Maybe<Order_By>;
  BsnsaddrDistrictName?: Maybe<Order_By>;
  BsnsaddrLocalCouncil?: Maybe<Order_By>;
  BsnsaddrParishName?: Maybe<Order_By>;
  BsnsaddrPlotNo?: Maybe<Order_By>;
  BsnsaddrStreetAddr?: Maybe<Order_By>;
  BsnsaddrSubCountyName?: Maybe<Order_By>;
  BsnsaddrTownName?: Maybe<Order_By>;
  BsnsaddrTradeCentre?: Maybe<Order_By>;
  BsnsaddrVillageName?: Maybe<Order_By>;
  BusinessStartDt?: Maybe<Order_By>;
  EmailId?: Maybe<Order_By>;
  ExistTinNo?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  HoldingCompName?: Maybe<Order_By>;
  HoldingCompTin?: Maybe<Order_By>;
  IsHldCmpInUganda?: Maybe<Order_By>;
  IsIncomeSrcBsns?: Maybe<Order_By>;
  IsIncomeSrcPrpty?: Maybe<Order_By>;
  IsIncomeSrcRental?: Maybe<Order_By>;
  IsSubsidaryComp?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MainActivity?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
  OrgSubType?: Maybe<Order_By>;
  OrgSubTypeDesc?: Maybe<Order_By>;
  OrgType?: Maybe<Order_By>;
  PostaddrDistictName?: Maybe<Order_By>;
  PostaddrId?: Maybe<Order_By>;
  RefEmailId?: Maybe<Order_By>;
  RefFamilyName?: Maybe<Order_By>;
  RefFirstName?: Maybe<Order_By>;
  RefLandlineNumber?: Maybe<Order_By>;
  RefMiddleName?: Maybe<Order_By>;
  RefMobileNumber?: Maybe<Order_By>;
  RefOtherName?: Maybe<Order_By>;
  RefPrsnDesgn?: Maybe<Order_By>;
  RefSurname?: Maybe<Order_By>;
  RefTin?: Maybe<Order_By>;
  Rnid?: Maybe<Order_By>;
  Sid?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TaxPayerType?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  TradingName?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_RegNonIndividual_sid" */
export enum Rtcs_Db_Ura_RegNonIndividual_Sid_Select_Column {
  /** column name */
  ActvtyDesc = "ActvtyDesc",
  /** column name */
  AppliedForTin = "AppliedForTin",
  /** column name */
  AuthctcdEmailId = "AuthctcdEmailId",
  /** column name */
  AuthctcdFamilyName = "AuthctcdFamilyName",
  /** column name */
  AuthctcdFirstName = "AuthctcdFirstName",
  /** column name */
  AuthctcdLandlineNumber = "AuthctcdLandlineNumber",
  /** column name */
  AuthctcdMobileNumber = "AuthctcdMobileNumber",
  /** column name */
  AuthctcdOtherName = "AuthctcdOtherName",
  /** column name */
  AuthctcdPrsnDesgn = "AuthctcdPrsnDesgn",
  /** column name */
  AuthctcdSurname = "AuthctcdSurname",
  /** column name */
  BsnsCertNumber = "BsnsCertNumber",
  /** column name */
  BsnsName = "BsnsName",
  /** column name */
  BsnsaddrBuildname = "BsnsaddrBuildname",
  /** column name */
  BsnsaddrDistrictName = "BsnsaddrDistrictName",
  /** column name */
  BsnsaddrLocalCouncil = "BsnsaddrLocalCouncil",
  /** column name */
  BsnsaddrParishName = "BsnsaddrParishName",
  /** column name */
  BsnsaddrPlotNo = "BsnsaddrPlotNo",
  /** column name */
  BsnsaddrStreetAddr = "BsnsaddrStreetAddr",
  /** column name */
  BsnsaddrSubCountyName = "BsnsaddrSubCountyName",
  /** column name */
  BsnsaddrTownName = "BsnsaddrTownName",
  /** column name */
  BsnsaddrTradeCentre = "BsnsaddrTradeCentre",
  /** column name */
  BsnsaddrVillageName = "BsnsaddrVillageName",
  /** column name */
  BusinessStartDt = "BusinessStartDt",
  /** column name */
  EmailId = "EmailId",
  /** column name */
  ExistTinNo = "ExistTinNo",
  /** column name */
  File = "File",
  /** column name */
  HoldingCompName = "HoldingCompName",
  /** column name */
  HoldingCompTin = "HoldingCompTin",
  /** column name */
  IsHldCmpInUganda = "IsHldCmpInUganda",
  /** column name */
  IsIncomeSrcBsns = "IsIncomeSrcBsns",
  /** column name */
  IsIncomeSrcPrpty = "IsIncomeSrcPrpty",
  /** column name */
  IsIncomeSrcRental = "IsIncomeSrcRental",
  /** column name */
  IsSubsidaryComp = "IsSubsidaryComp",
  /** column name */
  LandlineNumber = "LandlineNumber",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MainActivity = "MainActivity",
  /** column name */
  MobileNumber = "MobileNumber",
  /** column name */
  OrgSubType = "OrgSubType",
  /** column name */
  OrgSubTypeDesc = "OrgSubTypeDesc",
  /** column name */
  OrgType = "OrgType",
  /** column name */
  PostaddrDistictName = "PostaddrDistictName",
  /** column name */
  PostaddrId = "PostaddrId",
  /** column name */
  RefEmailId = "RefEmailId",
  /** column name */
  RefFamilyName = "RefFamilyName",
  /** column name */
  RefFirstName = "RefFirstName",
  /** column name */
  RefLandlineNumber = "RefLandlineNumber",
  /** column name */
  RefMiddleName = "RefMiddleName",
  /** column name */
  RefMobileNumber = "RefMobileNumber",
  /** column name */
  RefOtherName = "RefOtherName",
  /** column name */
  RefPrsnDesgn = "RefPrsnDesgn",
  /** column name */
  RefSurname = "RefSurname",
  /** column name */
  RefTin = "RefTin",
  /** column name */
  Rnid = "Rnid",
  /** column name */
  Sid = "Sid",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TaxPayerType = "TaxPayerType",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  TradingName = "TradingName",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Set_Input = {
  ActvtyDesc?: Maybe<Scalars["String"]>;
  AppliedForTin?: Maybe<Scalars["String"]>;
  AuthctcdEmailId?: Maybe<Scalars["String"]>;
  AuthctcdFamilyName?: Maybe<Scalars["String"]>;
  AuthctcdFirstName?: Maybe<Scalars["String"]>;
  AuthctcdLandlineNumber?: Maybe<Scalars["String"]>;
  AuthctcdMobileNumber?: Maybe<Scalars["String"]>;
  AuthctcdOtherName?: Maybe<Scalars["String"]>;
  AuthctcdPrsnDesgn?: Maybe<Scalars["String"]>;
  AuthctcdSurname?: Maybe<Scalars["String"]>;
  BsnsCertNumber?: Maybe<Scalars["String"]>;
  BsnsName?: Maybe<Scalars["String"]>;
  BsnsaddrBuildname?: Maybe<Scalars["String"]>;
  BsnsaddrDistrictName?: Maybe<Scalars["String"]>;
  BsnsaddrLocalCouncil?: Maybe<Scalars["String"]>;
  BsnsaddrParishName?: Maybe<Scalars["String"]>;
  BsnsaddrPlotNo?: Maybe<Scalars["String"]>;
  BsnsaddrStreetAddr?: Maybe<Scalars["String"]>;
  BsnsaddrSubCountyName?: Maybe<Scalars["String"]>;
  BsnsaddrTownName?: Maybe<Scalars["String"]>;
  BsnsaddrTradeCentre?: Maybe<Scalars["String"]>;
  BsnsaddrVillageName?: Maybe<Scalars["String"]>;
  BusinessStartDt?: Maybe<Scalars["String"]>;
  EmailId?: Maybe<Scalars["String"]>;
  ExistTinNo?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  HoldingCompName?: Maybe<Scalars["String"]>;
  HoldingCompTin?: Maybe<Scalars["String"]>;
  IsHldCmpInUganda?: Maybe<Scalars["String"]>;
  IsIncomeSrcBsns?: Maybe<Scalars["String"]>;
  IsIncomeSrcPrpty?: Maybe<Scalars["String"]>;
  IsIncomeSrcRental?: Maybe<Scalars["String"]>;
  IsSubsidaryComp?: Maybe<Scalars["String"]>;
  LandlineNumber?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MainActivity?: Maybe<Scalars["String"]>;
  MobileNumber?: Maybe<Scalars["float8"]>;
  OrgSubType?: Maybe<Scalars["String"]>;
  OrgSubTypeDesc?: Maybe<Scalars["String"]>;
  OrgType?: Maybe<Scalars["String"]>;
  PostaddrDistictName?: Maybe<Scalars["String"]>;
  PostaddrId?: Maybe<Scalars["String"]>;
  RefEmailId?: Maybe<Scalars["String"]>;
  RefFamilyName?: Maybe<Scalars["String"]>;
  RefFirstName?: Maybe<Scalars["String"]>;
  RefLandlineNumber?: Maybe<Scalars["String"]>;
  RefMiddleName?: Maybe<Scalars["String"]>;
  RefMobileNumber?: Maybe<Scalars["String"]>;
  RefOtherName?: Maybe<Scalars["String"]>;
  RefPrsnDesgn?: Maybe<Scalars["String"]>;
  RefSurname?: Maybe<Scalars["String"]>;
  RefTin?: Maybe<Scalars["String"]>;
  Rnid?: Maybe<Scalars["String"]>;
  Sid?: Maybe<Scalars["String"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TaxPayerType?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  TradingName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Stddev_Fields = {
  __typename?: "rtcs_db_ura_RegNonIndividual_sid_stddev_fields";
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MobileNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Stddev_Order_By = {
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ura_RegNonIndividual_sid_stddev_pop_fields";
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MobileNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Stddev_Pop_Order_By = {
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ura_RegNonIndividual_sid_stddev_samp_fields";
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MobileNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Stddev_Samp_Order_By = {
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Sum_Fields = {
  __typename?: "rtcs_db_ura_RegNonIndividual_sid_sum_fields";
  LandlineNumber?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MobileNumber?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Sum_Order_By = {
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Var_Pop_Fields = {
  __typename?: "rtcs_db_ura_RegNonIndividual_sid_var_pop_fields";
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MobileNumber?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Var_Pop_Order_By = {
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Var_Samp_Fields = {
  __typename?: "rtcs_db_ura_RegNonIndividual_sid_var_samp_fields";
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MobileNumber?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Var_Samp_Order_By = {
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Variance_Fields = {
  __typename?: "rtcs_db_ura_RegNonIndividual_sid_variance_fields";
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MobileNumber?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ura_RegNonIndividual_sid" */
export type Rtcs_Db_Ura_RegNonIndividual_Sid_Variance_Order_By = {
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MobileNumber?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_RegNonIndividual_Stddev_Fields = {
  __typename?: "rtcs_db_ura_RegNonIndividual_stddev_fields";
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_RegNonIndividual" */
export type Rtcs_Db_Ura_RegNonIndividual_Stddev_Order_By = {
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_RegNonIndividual_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ura_RegNonIndividual_stddev_pop_fields";
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ura_RegNonIndividual" */
export type Rtcs_Db_Ura_RegNonIndividual_Stddev_Pop_Order_By = {
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_RegNonIndividual_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ura_RegNonIndividual_stddev_samp_fields";
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ura_RegNonIndividual" */
export type Rtcs_Db_Ura_RegNonIndividual_Stddev_Samp_Order_By = {
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ura_RegNonIndividual_Sum_Fields = {
  __typename?: "rtcs_db_ura_RegNonIndividual_sum_fields";
  AuthctcdLandlineNumber?: Maybe<Scalars["float8"]>;
  LandlineNumber?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_RegNonIndividual" */
export type Rtcs_Db_Ura_RegNonIndividual_Sum_Order_By = {
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_RegNonIndividual_Var_Pop_Fields = {
  __typename?: "rtcs_db_ura_RegNonIndividual_var_pop_fields";
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ura_RegNonIndividual" */
export type Rtcs_Db_Ura_RegNonIndividual_Var_Pop_Order_By = {
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_RegNonIndividual_Var_Samp_Fields = {
  __typename?: "rtcs_db_ura_RegNonIndividual_var_samp_fields";
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ura_RegNonIndividual" */
export type Rtcs_Db_Ura_RegNonIndividual_Var_Samp_Order_By = {
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ura_RegNonIndividual_Variance_Fields = {
  __typename?: "rtcs_db_ura_RegNonIndividual_variance_fields";
  AuthctcdLandlineNumber?: Maybe<Scalars["Float"]>;
  LandlineNumber?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ura_RegNonIndividual" */
export type Rtcs_Db_Ura_RegNonIndividual_Variance_Order_By = {
  AuthctcdLandlineNumber?: Maybe<Order_By>;
  LandlineNumber?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.ura_RentExpenseDetails" */
export type Rtcs_Db_Ura_RentExpenseDetails = {
  __typename?: "rtcs_db_ura_RentExpenseDetails";
  Amount?: Maybe<Scalars["float8"]>;
  Expensetype?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnOtherExpnsDtlId?: Maybe<Scalars["String"]>;
  RtnOtherExpnsDtlId?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_RentExpenseDetails" */
export type Rtcs_Db_Ura_RentExpenseDetails_Aggregate = {
  __typename?: "rtcs_db_ura_RentExpenseDetails_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_RentExpenseDetails>;
};

/** aggregate fields of "rtcs_db.ura_RentExpenseDetails" */
export type Rtcs_Db_Ura_RentExpenseDetails_Aggregate_Fields = {
  __typename?: "rtcs_db_ura_RentExpenseDetails_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ura_RentExpenseDetails" */
export type Rtcs_Db_Ura_RentExpenseDetails_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ura_RentExpenseDetails_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.ura_RentExpenseDetails" */
export type Rtcs_Db_Ura_RentExpenseDetails_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ura_RentExpenseDetails" */
export type Rtcs_Db_Ura_RentExpenseDetails_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ura_RentExpenseDetails_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ura_RentExpenseDetails_Avg_Fields = {
  __typename?: "rtcs_db_ura_RentExpenseDetails_avg_fields";
  Amount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_RentExpenseDetails" */
export type Rtcs_Db_Ura_RentExpenseDetails_Avg_Order_By = {
  Amount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_RentExpenseDetails". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_RentExpenseDetails_Bool_Exp = {
  Amount?: Maybe<Float8_Comparison_Exp>;
  Expensetype?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnNoRtnOtherExpnsDtlId?: Maybe<String_Comparison_Exp>;
  RtnOtherExpnsDtlId?: Maybe<String_Comparison_Exp>;
  SourceType?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ura_RentExpenseDetails_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ura_RentExpenseDetails_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.ura_RentExpenseDetails" */
export type Rtcs_Db_Ura_RentExpenseDetails_Inc_Input = {
  Amount?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.ura_RentExpenseDetails" */
export type Rtcs_Db_Ura_RentExpenseDetails_Insert_Input = {
  Amount?: Maybe<Scalars["float8"]>;
  Expensetype?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnOtherExpnsDtlId?: Maybe<Scalars["String"]>;
  RtnOtherExpnsDtlId?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_RentExpenseDetails_Max_Fields = {
  __typename?: "rtcs_db_ura_RentExpenseDetails_max_fields";
  Amount?: Maybe<Scalars["float8"]>;
  Expensetype?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnOtherExpnsDtlId?: Maybe<Scalars["String"]>;
  RtnOtherExpnsDtlId?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_RentExpenseDetails" */
export type Rtcs_Db_Ura_RentExpenseDetails_Max_Order_By = {
  Amount?: Maybe<Order_By>;
  Expensetype?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnOtherExpnsDtlId?: Maybe<Order_By>;
  RtnOtherExpnsDtlId?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_RentExpenseDetails_Min_Fields = {
  __typename?: "rtcs_db_ura_RentExpenseDetails_min_fields";
  Amount?: Maybe<Scalars["float8"]>;
  Expensetype?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnOtherExpnsDtlId?: Maybe<Scalars["String"]>;
  RtnOtherExpnsDtlId?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_RentExpenseDetails" */
export type Rtcs_Db_Ura_RentExpenseDetails_Min_Order_By = {
  Amount?: Maybe<Order_By>;
  Expensetype?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnOtherExpnsDtlId?: Maybe<Order_By>;
  RtnOtherExpnsDtlId?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_RentExpenseDetails" */
export type Rtcs_Db_Ura_RentExpenseDetails_Mutation_Response = {
  __typename?: "rtcs_db_ura_RentExpenseDetails_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ura_RentExpenseDetails>;
};

/** input type for inserting object relation for remote table "rtcs_db.ura_RentExpenseDetails" */
export type Rtcs_Db_Ura_RentExpenseDetails_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ura_RentExpenseDetails_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.ura_RentExpenseDetails" */
export type Rtcs_Db_Ura_RentExpenseDetails_Order_By = {
  Amount?: Maybe<Order_By>;
  Expensetype?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnOtherExpnsDtlId?: Maybe<Order_By>;
  RtnOtherExpnsDtlId?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_RentExpenseDetails" */
export enum Rtcs_Db_Ura_RentExpenseDetails_Select_Column {
  /** column name */
  Amount = "Amount",
  /** column name */
  Expensetype = "Expensetype",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Name = "Name",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnNoRtnOtherExpnsDtlId = "RtnNoRtnOtherExpnsDtlId",
  /** column name */
  RtnOtherExpnsDtlId = "RtnOtherExpnsDtlId",
  /** column name */
  SourceType = "SourceType",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_RentExpenseDetails" */
export type Rtcs_Db_Ura_RentExpenseDetails_Set_Input = {
  Amount?: Maybe<Scalars["float8"]>;
  Expensetype?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Name?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnOtherExpnsDtlId?: Maybe<Scalars["String"]>;
  RtnOtherExpnsDtlId?: Maybe<Scalars["String"]>;
  SourceType?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_RentExpenseDetails_Stddev_Fields = {
  __typename?: "rtcs_db_ura_RentExpenseDetails_stddev_fields";
  Amount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_RentExpenseDetails" */
export type Rtcs_Db_Ura_RentExpenseDetails_Stddev_Order_By = {
  Amount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_RentExpenseDetails_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ura_RentExpenseDetails_stddev_pop_fields";
  Amount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ura_RentExpenseDetails" */
export type Rtcs_Db_Ura_RentExpenseDetails_Stddev_Pop_Order_By = {
  Amount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_RentExpenseDetails_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ura_RentExpenseDetails_stddev_samp_fields";
  Amount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ura_RentExpenseDetails" */
export type Rtcs_Db_Ura_RentExpenseDetails_Stddev_Samp_Order_By = {
  Amount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ura_RentExpenseDetails_Sum_Fields = {
  __typename?: "rtcs_db_ura_RentExpenseDetails_sum_fields";
  Amount?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_RentExpenseDetails" */
export type Rtcs_Db_Ura_RentExpenseDetails_Sum_Order_By = {
  Amount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_RentExpenseDetails_Var_Pop_Fields = {
  __typename?: "rtcs_db_ura_RentExpenseDetails_var_pop_fields";
  Amount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ura_RentExpenseDetails" */
export type Rtcs_Db_Ura_RentExpenseDetails_Var_Pop_Order_By = {
  Amount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_RentExpenseDetails_Var_Samp_Fields = {
  __typename?: "rtcs_db_ura_RentExpenseDetails_var_samp_fields";
  Amount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ura_RentExpenseDetails" */
export type Rtcs_Db_Ura_RentExpenseDetails_Var_Samp_Order_By = {
  Amount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ura_RentExpenseDetails_Variance_Fields = {
  __typename?: "rtcs_db_ura_RentExpenseDetails_variance_fields";
  Amount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ura_RentExpenseDetails" */
export type Rtcs_Db_Ura_RentExpenseDetails_Variance_Order_By = {
  Amount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.ura_RentalLandLordIncome" */
export type Rtcs_Db_Ura_RentalLandLordIncome = {
  __typename?: "rtcs_db_ura_RentalLandLordIncome";
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_RentalLandLordIncome" */
export type Rtcs_Db_Ura_RentalLandLordIncome_Aggregate = {
  __typename?: "rtcs_db_ura_RentalLandLordIncome_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_RentalLandLordIncome>;
};

/** aggregate fields of "rtcs_db.ura_RentalLandLordIncome" */
export type Rtcs_Db_Ura_RentalLandLordIncome_Aggregate_Fields = {
  __typename?: "rtcs_db_ura_RentalLandLordIncome_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ura_RentalLandLordIncome" */
export type Rtcs_Db_Ura_RentalLandLordIncome_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ura_RentalLandLordIncome_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.ura_RentalLandLordIncome" */
export type Rtcs_Db_Ura_RentalLandLordIncome_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ura_RentalLandLordIncome" */
export type Rtcs_Db_Ura_RentalLandLordIncome_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ura_RentalLandLordIncome_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ura_RentalLandLordIncome_Avg_Fields = {
  __typename?: "rtcs_db_ura_RentalLandLordIncome_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_RentalLandLordIncome" */
export type Rtcs_Db_Ura_RentalLandLordIncome_Avg_Order_By = {
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_RentalLandLordIncome". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_RentalLandLordIncome_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  PremiseLocation?: Maybe<String_Comparison_Exp>;
  RentalIncome?: Maybe<Float8_Comparison_Exp>;
  ReturnType?: Maybe<String_Comparison_Exp>;
  RtnDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnFromDt?: Maybe<Timestamptz_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnNoRtnRentalIncomeId?: Maybe<String_Comparison_Exp>;
  RtnRentalIncomeId?: Maybe<String_Comparison_Exp>;
  RtnToDt?: Maybe<Timestamptz_Comparison_Exp>;
  TaxPayerName?: Maybe<String_Comparison_Exp>;
  TenancyPeriodFrom?: Maybe<Timestamptz_Comparison_Exp>;
  TenancyPeriodTo?: Maybe<Timestamptz_Comparison_Exp>;
  TenantName?: Maybe<String_Comparison_Exp>;
  TenantTin?: Maybe<String_Comparison_Exp>;
  TinNo?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.ura_RentalLandLordIncome" */
export type Rtcs_Db_Ura_RentalLandLordIncome_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.ura_RentalLandLordIncome" */
export type Rtcs_Db_Ura_RentalLandLordIncome_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_RentalLandLordIncome_Max_Fields = {
  __typename?: "rtcs_db_ura_RentalLandLordIncome_max_fields";
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_RentalLandLordIncome" */
export type Rtcs_Db_Ura_RentalLandLordIncome_Max_Order_By = {
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnRentalIncomeId?: Maybe<Order_By>;
  RtnRentalIncomeId?: Maybe<Order_By>;
  RtnToDt?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  TenantName?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_RentalLandLordIncome_Min_Fields = {
  __typename?: "rtcs_db_ura_RentalLandLordIncome_min_fields";
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_RentalLandLordIncome" */
export type Rtcs_Db_Ura_RentalLandLordIncome_Min_Order_By = {
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnRentalIncomeId?: Maybe<Order_By>;
  RtnRentalIncomeId?: Maybe<Order_By>;
  RtnToDt?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  TenantName?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_RentalLandLordIncome" */
export type Rtcs_Db_Ura_RentalLandLordIncome_Mutation_Response = {
  __typename?: "rtcs_db_ura_RentalLandLordIncome_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ura_RentalLandLordIncome>;
};

/** input type for inserting object relation for remote table "rtcs_db.ura_RentalLandLordIncome" */
export type Rtcs_Db_Ura_RentalLandLordIncome_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ura_RentalLandLordIncome_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.ura_RentalLandLordIncome" */
export type Rtcs_Db_Ura_RentalLandLordIncome_Order_By = {
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RtnDt?: Maybe<Order_By>;
  RtnFromDt?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnRentalIncomeId?: Maybe<Order_By>;
  RtnRentalIncomeId?: Maybe<Order_By>;
  RtnToDt?: Maybe<Order_By>;
  TaxPayerName?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  TenantName?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
  TinNo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_RentalLandLordIncome" */
export enum Rtcs_Db_Ura_RentalLandLordIncome_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  PremiseLocation = "PremiseLocation",
  /** column name */
  RentalIncome = "RentalIncome",
  /** column name */
  ReturnType = "ReturnType",
  /** column name */
  RtnDt = "RtnDt",
  /** column name */
  RtnFromDt = "RtnFromDt",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnNoRtnRentalIncomeId = "RtnNoRtnRentalIncomeId",
  /** column name */
  RtnRentalIncomeId = "RtnRentalIncomeId",
  /** column name */
  RtnToDt = "RtnToDt",
  /** column name */
  TaxPayerName = "TaxPayerName",
  /** column name */
  TenancyPeriodFrom = "TenancyPeriodFrom",
  /** column name */
  TenancyPeriodTo = "TenancyPeriodTo",
  /** column name */
  TenantName = "TenantName",
  /** column name */
  TenantTin = "TenantTin",
  /** column name */
  TinNo = "TinNo",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_RentalLandLordIncome" */
export type Rtcs_Db_Ura_RentalLandLordIncome_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnDt?: Maybe<Scalars["timestamptz"]>;
  RtnFromDt?: Maybe<Scalars["timestamptz"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnRentalIncomeId?: Maybe<Scalars["String"]>;
  RtnToDt?: Maybe<Scalars["timestamptz"]>;
  TaxPayerName?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["String"]>;
  TinNo?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_RentalLandLordIncome_Stddev_Fields = {
  __typename?: "rtcs_db_ura_RentalLandLordIncome_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_RentalLandLordIncome" */
export type Rtcs_Db_Ura_RentalLandLordIncome_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_RentalLandLordIncome_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ura_RentalLandLordIncome_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ura_RentalLandLordIncome" */
export type Rtcs_Db_Ura_RentalLandLordIncome_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_RentalLandLordIncome_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ura_RentalLandLordIncome_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ura_RentalLandLordIncome" */
export type Rtcs_Db_Ura_RentalLandLordIncome_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ura_RentalLandLordIncome_Sum_Fields = {
  __typename?: "rtcs_db_ura_RentalLandLordIncome_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
  RentalIncome?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_RentalLandLordIncome" */
export type Rtcs_Db_Ura_RentalLandLordIncome_Sum_Order_By = {
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_RentalLandLordIncome_Var_Pop_Fields = {
  __typename?: "rtcs_db_ura_RentalLandLordIncome_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ura_RentalLandLordIncome" */
export type Rtcs_Db_Ura_RentalLandLordIncome_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_RentalLandLordIncome_Var_Samp_Fields = {
  __typename?: "rtcs_db_ura_RentalLandLordIncome_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ura_RentalLandLordIncome" */
export type Rtcs_Db_Ura_RentalLandLordIncome_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ura_RentalLandLordIncome_Variance_Fields = {
  __typename?: "rtcs_db_ura_RentalLandLordIncome_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentalIncome?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ura_RentalLandLordIncome" */
export type Rtcs_Db_Ura_RentalLandLordIncome_Variance_Order_By = {
  Line?: Maybe<Order_By>;
  RentalIncome?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.ura_RentalTenantPayments" */
export type Rtcs_Db_Ura_RentalTenantPayments = {
  __typename?: "rtcs_db_ura_RentalTenantPayments";
  File?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnRentPaidId?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_RentalTenantPayments" */
export type Rtcs_Db_Ura_RentalTenantPayments_Aggregate = {
  __typename?: "rtcs_db_ura_RentalTenantPayments_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_RentalTenantPayments>;
};

/** aggregate fields of "rtcs_db.ura_RentalTenantPayments" */
export type Rtcs_Db_Ura_RentalTenantPayments_Aggregate_Fields = {
  __typename?: "rtcs_db_ura_RentalTenantPayments_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ura_RentalTenantPayments" */
export type Rtcs_Db_Ura_RentalTenantPayments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ura_RentalTenantPayments_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.ura_RentalTenantPayments" */
export type Rtcs_Db_Ura_RentalTenantPayments_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ura_RentalTenantPayments" */
export type Rtcs_Db_Ura_RentalTenantPayments_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ura_RentalTenantPayments_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ura_RentalTenantPayments_Avg_Fields = {
  __typename?: "rtcs_db_ura_RentalTenantPayments_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_RentalTenantPayments" */
export type Rtcs_Db_Ura_RentalTenantPayments_Avg_Order_By = {
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_RentalTenantPayments". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_RentalTenantPayments_Bool_Exp = {
  File?: Maybe<String_Comparison_Exp>;
  LandlordName?: Maybe<String_Comparison_Exp>;
  LandlordTin?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  PremiseLocation?: Maybe<String_Comparison_Exp>;
  RentPaid?: Maybe<Float8_Comparison_Exp>;
  ReturnType?: Maybe<String_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnNoRtnRentPaidId?: Maybe<String_Comparison_Exp>;
  RtnRentPaidId?: Maybe<String_Comparison_Exp>;
  TenancyPeriodFrom?: Maybe<Timestamptz_Comparison_Exp>;
  TenancyPeriodTo?: Maybe<Timestamptz_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ura_RentalTenantPayments_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ura_RentalTenantPayments_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.ura_RentalTenantPayments" */
export type Rtcs_Db_Ura_RentalTenantPayments_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.ura_RentalTenantPayments" */
export type Rtcs_Db_Ura_RentalTenantPayments_Insert_Input = {
  File?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnRentPaidId?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_RentalTenantPayments_Max_Fields = {
  __typename?: "rtcs_db_ura_RentalTenantPayments_max_fields";
  File?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnRentPaidId?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_RentalTenantPayments" */
export type Rtcs_Db_Ura_RentalTenantPayments_Max_Order_By = {
  File?: Maybe<Order_By>;
  LandlordName?: Maybe<Order_By>;
  LandlordTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnRentPaidId?: Maybe<Order_By>;
  RtnRentPaidId?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_RentalTenantPayments_Min_Fields = {
  __typename?: "rtcs_db_ura_RentalTenantPayments_min_fields";
  File?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnRentPaidId?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_RentalTenantPayments" */
export type Rtcs_Db_Ura_RentalTenantPayments_Min_Order_By = {
  File?: Maybe<Order_By>;
  LandlordName?: Maybe<Order_By>;
  LandlordTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnRentPaidId?: Maybe<Order_By>;
  RtnRentPaidId?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_RentalTenantPayments" */
export type Rtcs_Db_Ura_RentalTenantPayments_Mutation_Response = {
  __typename?: "rtcs_db_ura_RentalTenantPayments_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ura_RentalTenantPayments>;
};

/** input type for inserting object relation for remote table "rtcs_db.ura_RentalTenantPayments" */
export type Rtcs_Db_Ura_RentalTenantPayments_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ura_RentalTenantPayments_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.ura_RentalTenantPayments" */
export type Rtcs_Db_Ura_RentalTenantPayments_Order_By = {
  File?: Maybe<Order_By>;
  LandlordName?: Maybe<Order_By>;
  LandlordTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnNoRtnRentPaidId?: Maybe<Order_By>;
  RtnRentPaidId?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_RentalTenantPayments" */
export enum Rtcs_Db_Ura_RentalTenantPayments_Select_Column {
  /** column name */
  File = "File",
  /** column name */
  LandlordName = "LandlordName",
  /** column name */
  LandlordTin = "LandlordTin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  PremiseLocation = "PremiseLocation",
  /** column name */
  RentPaid = "RentPaid",
  /** column name */
  ReturnType = "ReturnType",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnNoRtnRentPaidId = "RtnNoRtnRentPaidId",
  /** column name */
  RtnRentPaidId = "RtnRentPaidId",
  /** column name */
  TenancyPeriodFrom = "TenancyPeriodFrom",
  /** column name */
  TenancyPeriodTo = "TenancyPeriodTo",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_RentalTenantPayments" */
export type Rtcs_Db_Ura_RentalTenantPayments_Set_Input = {
  File?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnNoRtnRentPaidId?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_RentalTenantPayments_Stddev_Fields = {
  __typename?: "rtcs_db_ura_RentalTenantPayments_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_RentalTenantPayments" */
export type Rtcs_Db_Ura_RentalTenantPayments_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_RentalTenantPayments_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ura_RentalTenantPayments_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ura_RentalTenantPayments" */
export type Rtcs_Db_Ura_RentalTenantPayments_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_RentalTenantPayments_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ura_RentalTenantPayments_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ura_RentalTenantPayments" */
export type Rtcs_Db_Ura_RentalTenantPayments_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ura_RentalTenantPayments_Sum_Fields = {
  __typename?: "rtcs_db_ura_RentalTenantPayments_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_RentalTenantPayments" */
export type Rtcs_Db_Ura_RentalTenantPayments_Sum_Order_By = {
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_RentalTenantPayments_Var_Pop_Fields = {
  __typename?: "rtcs_db_ura_RentalTenantPayments_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ura_RentalTenantPayments" */
export type Rtcs_Db_Ura_RentalTenantPayments_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_RentalTenantPayments_Var_Samp_Fields = {
  __typename?: "rtcs_db_ura_RentalTenantPayments_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ura_RentalTenantPayments" */
export type Rtcs_Db_Ura_RentalTenantPayments_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ura_RentalTenantPayments_Variance_Fields = {
  __typename?: "rtcs_db_ura_RentalTenantPayments_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ura_RentalTenantPayments" */
export type Rtcs_Db_Ura_RentalTenantPayments_Variance_Order_By = {
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary = {
  __typename?: "rtcs_db_ura_RipoIndRentalIncSummary";
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Aggregate = {
  __typename?: "rtcs_db_ura_RipoIndRentalIncSummary_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_RipoIndRentalIncSummary>;
};

/** aggregate fields of "rtcs_db.ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Aggregate_Fields = {
  __typename?: "rtcs_db_ura_RipoIndRentalIncSummary_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ura_RipoIndRentalIncSummary_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ura_RipoIndRentalIncSummary_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Avg_Fields = {
  __typename?: "rtcs_db_ura_RipoIndRentalIncSummary_avg_fields";
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Avg_Order_By = {
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_RipoIndRentalIncSummary". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Bool_Exp = {
  ChrgdRentalIncm?: Maybe<Float8_Comparison_Exp>;
  DeductIncmTax?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  GrossRentalIncm?: Maybe<Float8_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  MotgageIntDecd?: Maybe<Float8_Comparison_Exp>;
  NetRentIncm?: Maybe<Float8_Comparison_Exp>;
  PartnerRentIncome?: Maybe<Float8_Comparison_Exp>;
  ReturnType?: Maybe<String_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  TaxAssessed?: Maybe<Float8_Comparison_Exp>;
  ThresholdAmnt?: Maybe<Float8_Comparison_Exp>;
  TotRentalIncm?: Maybe<Float8_Comparison_Exp>;
  Uuid?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Inc_Input = {
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Insert_Input = {
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Max_Fields = {
  __typename?: "rtcs_db_ura_RipoIndRentalIncSummary_max_fields";
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Max_Order_By = {
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Min_Fields = {
  __typename?: "rtcs_db_ura_RipoIndRentalIncSummary_min_fields";
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Min_Order_By = {
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Mutation_Response = {
  __typename?: "rtcs_db_ura_RipoIndRentalIncSummary_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ura_RipoIndRentalIncSummary>;
};

/** input type for inserting object relation for remote table "rtcs_db.ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ura_RipoIndRentalIncSummary_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Order_By = {
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  ReturnType?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
  Uuid?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_RipoIndRentalIncSummary" */
export enum Rtcs_Db_Ura_RipoIndRentalIncSummary_Select_Column {
  /** column name */
  ChrgdRentalIncm = "ChrgdRentalIncm",
  /** column name */
  DeductIncmTax = "DeductIncmTax",
  /** column name */
  File = "File",
  /** column name */
  GrossRentalIncm = "GrossRentalIncm",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  MotgageIntDecd = "MotgageIntDecd",
  /** column name */
  NetRentIncm = "NetRentIncm",
  /** column name */
  PartnerRentIncome = "PartnerRentIncome",
  /** column name */
  ReturnType = "ReturnType",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  TaxAssessed = "TaxAssessed",
  /** column name */
  ThresholdAmnt = "ThresholdAmnt",
  /** column name */
  TotRentalIncm = "TotRentalIncm",
  /** column name */
  Uuid = "Uuid",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Set_Input = {
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  ReturnType?: Maybe<Scalars["String"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
  Uuid?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Stddev_Fields = {
  __typename?: "rtcs_db_ura_RipoIndRentalIncSummary_stddev_fields";
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Stddev_Order_By = {
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ura_RipoIndRentalIncSummary_stddev_pop_fields";
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Stddev_Pop_Order_By = {
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ura_RipoIndRentalIncSummary_stddev_samp_fields";
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Stddev_Samp_Order_By = {
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Sum_Fields = {
  __typename?: "rtcs_db_ura_RipoIndRentalIncSummary_sum_fields";
  ChrgdRentalIncm?: Maybe<Scalars["float8"]>;
  DeductIncmTax?: Maybe<Scalars["float8"]>;
  GrossRentalIncm?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  MotgageIntDecd?: Maybe<Scalars["float8"]>;
  NetRentIncm?: Maybe<Scalars["float8"]>;
  PartnerRentIncome?: Maybe<Scalars["float8"]>;
  TaxAssessed?: Maybe<Scalars["float8"]>;
  ThresholdAmnt?: Maybe<Scalars["float8"]>;
  TotRentalIncm?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Sum_Order_By = {
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Var_Pop_Fields = {
  __typename?: "rtcs_db_ura_RipoIndRentalIncSummary_var_pop_fields";
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Var_Pop_Order_By = {
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Var_Samp_Fields = {
  __typename?: "rtcs_db_ura_RipoIndRentalIncSummary_var_samp_fields";
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Var_Samp_Order_By = {
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Variance_Fields = {
  __typename?: "rtcs_db_ura_RipoIndRentalIncSummary_variance_fields";
  ChrgdRentalIncm?: Maybe<Scalars["Float"]>;
  DeductIncmTax?: Maybe<Scalars["Float"]>;
  GrossRentalIncm?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  MotgageIntDecd?: Maybe<Scalars["Float"]>;
  NetRentIncm?: Maybe<Scalars["Float"]>;
  PartnerRentIncome?: Maybe<Scalars["Float"]>;
  TaxAssessed?: Maybe<Scalars["Float"]>;
  ThresholdAmnt?: Maybe<Scalars["Float"]>;
  TotRentalIncm?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ura_RipoIndRentalIncSummary" */
export type Rtcs_Db_Ura_RipoIndRentalIncSummary_Variance_Order_By = {
  ChrgdRentalIncm?: Maybe<Order_By>;
  DeductIncmTax?: Maybe<Order_By>;
  GrossRentalIncm?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  MotgageIntDecd?: Maybe<Order_By>;
  NetRentIncm?: Maybe<Order_By>;
  PartnerRentIncome?: Maybe<Order_By>;
  TaxAssessed?: Maybe<Order_By>;
  ThresholdAmnt?: Maybe<Order_By>;
  TotRentalIncm?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.ura_TenantIncomeDetail" */
export type Rtcs_Db_Ura_TenantIncomeDetail = {
  __typename?: "rtcs_db_ura_TenantIncomeDetail";
  Contribution?: Maybe<Scalars["float8"]>;
  ContributionAmount?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  LandlordPeriodTo?: Maybe<Scalars["timestamptz"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  TaxYear?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantTaxPayerName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_TenantIncomeDetail" */
export type Rtcs_Db_Ura_TenantIncomeDetail_Aggregate = {
  __typename?: "rtcs_db_ura_TenantIncomeDetail_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_TenantIncomeDetail>;
};

/** aggregate fields of "rtcs_db.ura_TenantIncomeDetail" */
export type Rtcs_Db_Ura_TenantIncomeDetail_Aggregate_Fields = {
  __typename?: "rtcs_db_ura_TenantIncomeDetail_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ura_TenantIncomeDetail" */
export type Rtcs_Db_Ura_TenantIncomeDetail_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ura_TenantIncomeDetail_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.ura_TenantIncomeDetail" */
export type Rtcs_Db_Ura_TenantIncomeDetail_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ura_TenantIncomeDetail" */
export type Rtcs_Db_Ura_TenantIncomeDetail_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ura_TenantIncomeDetail_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ura_TenantIncomeDetail_Avg_Fields = {
  __typename?: "rtcs_db_ura_TenantIncomeDetail_avg_fields";
  Contribution?: Maybe<Scalars["Float"]>;
  ContributionAmount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  TenantTin?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_TenantIncomeDetail" */
export type Rtcs_Db_Ura_TenantIncomeDetail_Avg_Order_By = {
  Contribution?: Maybe<Order_By>;
  ContributionAmount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_TenantIncomeDetail". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_TenantIncomeDetail_Bool_Exp = {
  Contribution?: Maybe<Float8_Comparison_Exp>;
  ContributionAmount?: Maybe<Float8_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LandlordName?: Maybe<String_Comparison_Exp>;
  LandlordPeriodFrom?: Maybe<Timestamptz_Comparison_Exp>;
  LandlordPeriodTo?: Maybe<Timestamptz_Comparison_Exp>;
  LandlordTin?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  PremiseLocation?: Maybe<String_Comparison_Exp>;
  RentPaid?: Maybe<Float8_Comparison_Exp>;
  RmatchScore?: Maybe<Float8_Comparison_Exp>;
  RtnNo?: Maybe<String_Comparison_Exp>;
  RtnRentPaidId?: Maybe<String_Comparison_Exp>;
  TaxYear?: Maybe<String_Comparison_Exp>;
  TenancyPeriodFrom?: Maybe<Timestamptz_Comparison_Exp>;
  TenancyPeriodTo?: Maybe<Timestamptz_Comparison_Exp>;
  TenantTaxPayerName?: Maybe<String_Comparison_Exp>;
  TenantTin?: Maybe<Float8_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.ura_TenantIncomeDetail" */
export type Rtcs_Db_Ura_TenantIncomeDetail_Inc_Input = {
  Contribution?: Maybe<Scalars["float8"]>;
  ContributionAmount?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  TenantTin?: Maybe<Scalars["float8"]>;
};

/** input type for inserting data into table "rtcs_db.ura_TenantIncomeDetail" */
export type Rtcs_Db_Ura_TenantIncomeDetail_Insert_Input = {
  Contribution?: Maybe<Scalars["float8"]>;
  ContributionAmount?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  LandlordPeriodTo?: Maybe<Scalars["timestamptz"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  TaxYear?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantTaxPayerName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_TenantIncomeDetail_Max_Fields = {
  __typename?: "rtcs_db_ura_TenantIncomeDetail_max_fields";
  Contribution?: Maybe<Scalars["float8"]>;
  ContributionAmount?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  LandlordPeriodTo?: Maybe<Scalars["timestamptz"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  TaxYear?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantTaxPayerName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_TenantIncomeDetail" */
export type Rtcs_Db_Ura_TenantIncomeDetail_Max_Order_By = {
  Contribution?: Maybe<Order_By>;
  ContributionAmount?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LandlordName?: Maybe<Order_By>;
  LandlordPeriodFrom?: Maybe<Order_By>;
  LandlordPeriodTo?: Maybe<Order_By>;
  LandlordTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnRentPaidId?: Maybe<Order_By>;
  TaxYear?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  TenantTaxPayerName?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_TenantIncomeDetail_Min_Fields = {
  __typename?: "rtcs_db_ura_TenantIncomeDetail_min_fields";
  Contribution?: Maybe<Scalars["float8"]>;
  ContributionAmount?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  LandlordPeriodTo?: Maybe<Scalars["timestamptz"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  TaxYear?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantTaxPayerName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_TenantIncomeDetail" */
export type Rtcs_Db_Ura_TenantIncomeDetail_Min_Order_By = {
  Contribution?: Maybe<Order_By>;
  ContributionAmount?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LandlordName?: Maybe<Order_By>;
  LandlordPeriodFrom?: Maybe<Order_By>;
  LandlordPeriodTo?: Maybe<Order_By>;
  LandlordTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnRentPaidId?: Maybe<Order_By>;
  TaxYear?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  TenantTaxPayerName?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_TenantIncomeDetail" */
export type Rtcs_Db_Ura_TenantIncomeDetail_Mutation_Response = {
  __typename?: "rtcs_db_ura_TenantIncomeDetail_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ura_TenantIncomeDetail>;
};

/** input type for inserting object relation for remote table "rtcs_db.ura_TenantIncomeDetail" */
export type Rtcs_Db_Ura_TenantIncomeDetail_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ura_TenantIncomeDetail_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.ura_TenantIncomeDetail" */
export type Rtcs_Db_Ura_TenantIncomeDetail_Order_By = {
  Contribution?: Maybe<Order_By>;
  ContributionAmount?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LandlordName?: Maybe<Order_By>;
  LandlordPeriodFrom?: Maybe<Order_By>;
  LandlordPeriodTo?: Maybe<Order_By>;
  LandlordTin?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  PremiseLocation?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  RtnNo?: Maybe<Order_By>;
  RtnRentPaidId?: Maybe<Order_By>;
  TaxYear?: Maybe<Order_By>;
  TenancyPeriodFrom?: Maybe<Order_By>;
  TenancyPeriodTo?: Maybe<Order_By>;
  TenantTaxPayerName?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_TenantIncomeDetail" */
export enum Rtcs_Db_Ura_TenantIncomeDetail_Select_Column {
  /** column name */
  Contribution = "Contribution",
  /** column name */
  ContributionAmount = "ContributionAmount",
  /** column name */
  File = "File",
  /** column name */
  LandlordName = "LandlordName",
  /** column name */
  LandlordPeriodFrom = "LandlordPeriodFrom",
  /** column name */
  LandlordPeriodTo = "LandlordPeriodTo",
  /** column name */
  LandlordTin = "LandlordTin",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  PremiseLocation = "PremiseLocation",
  /** column name */
  RentPaid = "RentPaid",
  /** column name */
  RmatchScore = "RmatchScore",
  /** column name */
  RtnNo = "RtnNo",
  /** column name */
  RtnRentPaidId = "RtnRentPaidId",
  /** column name */
  TaxYear = "TaxYear",
  /** column name */
  TenancyPeriodFrom = "TenancyPeriodFrom",
  /** column name */
  TenancyPeriodTo = "TenancyPeriodTo",
  /** column name */
  TenantTaxPayerName = "TenantTaxPayerName",
  /** column name */
  TenantTin = "TenantTin",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_TenantIncomeDetail" */
export type Rtcs_Db_Ura_TenantIncomeDetail_Set_Input = {
  Contribution?: Maybe<Scalars["float8"]>;
  ContributionAmount?: Maybe<Scalars["float8"]>;
  File?: Maybe<Scalars["String"]>;
  LandlordName?: Maybe<Scalars["String"]>;
  LandlordPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  LandlordPeriodTo?: Maybe<Scalars["timestamptz"]>;
  LandlordTin?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  PremiseLocation?: Maybe<Scalars["String"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  RtnNo?: Maybe<Scalars["String"]>;
  RtnRentPaidId?: Maybe<Scalars["String"]>;
  TaxYear?: Maybe<Scalars["String"]>;
  TenancyPeriodFrom?: Maybe<Scalars["timestamptz"]>;
  TenancyPeriodTo?: Maybe<Scalars["timestamptz"]>;
  TenantTaxPayerName?: Maybe<Scalars["String"]>;
  TenantTin?: Maybe<Scalars["float8"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_TenantIncomeDetail_Stddev_Fields = {
  __typename?: "rtcs_db_ura_TenantIncomeDetail_stddev_fields";
  Contribution?: Maybe<Scalars["Float"]>;
  ContributionAmount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  TenantTin?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_TenantIncomeDetail" */
export type Rtcs_Db_Ura_TenantIncomeDetail_Stddev_Order_By = {
  Contribution?: Maybe<Order_By>;
  ContributionAmount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_TenantIncomeDetail_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ura_TenantIncomeDetail_stddev_pop_fields";
  Contribution?: Maybe<Scalars["Float"]>;
  ContributionAmount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  TenantTin?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ura_TenantIncomeDetail" */
export type Rtcs_Db_Ura_TenantIncomeDetail_Stddev_Pop_Order_By = {
  Contribution?: Maybe<Order_By>;
  ContributionAmount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_TenantIncomeDetail_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ura_TenantIncomeDetail_stddev_samp_fields";
  Contribution?: Maybe<Scalars["Float"]>;
  ContributionAmount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  TenantTin?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ura_TenantIncomeDetail" */
export type Rtcs_Db_Ura_TenantIncomeDetail_Stddev_Samp_Order_By = {
  Contribution?: Maybe<Order_By>;
  ContributionAmount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ura_TenantIncomeDetail_Sum_Fields = {
  __typename?: "rtcs_db_ura_TenantIncomeDetail_sum_fields";
  Contribution?: Maybe<Scalars["float8"]>;
  ContributionAmount?: Maybe<Scalars["float8"]>;
  Line?: Maybe<Scalars["bigint"]>;
  RentPaid?: Maybe<Scalars["float8"]>;
  RmatchScore?: Maybe<Scalars["float8"]>;
  TenantTin?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_TenantIncomeDetail" */
export type Rtcs_Db_Ura_TenantIncomeDetail_Sum_Order_By = {
  Contribution?: Maybe<Order_By>;
  ContributionAmount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_TenantIncomeDetail_Var_Pop_Fields = {
  __typename?: "rtcs_db_ura_TenantIncomeDetail_var_pop_fields";
  Contribution?: Maybe<Scalars["Float"]>;
  ContributionAmount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  TenantTin?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ura_TenantIncomeDetail" */
export type Rtcs_Db_Ura_TenantIncomeDetail_Var_Pop_Order_By = {
  Contribution?: Maybe<Order_By>;
  ContributionAmount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_TenantIncomeDetail_Var_Samp_Fields = {
  __typename?: "rtcs_db_ura_TenantIncomeDetail_var_samp_fields";
  Contribution?: Maybe<Scalars["Float"]>;
  ContributionAmount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  TenantTin?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ura_TenantIncomeDetail" */
export type Rtcs_Db_Ura_TenantIncomeDetail_Var_Samp_Order_By = {
  Contribution?: Maybe<Order_By>;
  ContributionAmount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ura_TenantIncomeDetail_Variance_Fields = {
  __typename?: "rtcs_db_ura_TenantIncomeDetail_variance_fields";
  Contribution?: Maybe<Scalars["Float"]>;
  ContributionAmount?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  RentPaid?: Maybe<Scalars["Float"]>;
  RmatchScore?: Maybe<Scalars["Float"]>;
  TenantTin?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ura_TenantIncomeDetail" */
export type Rtcs_Db_Ura_TenantIncomeDetail_Variance_Order_By = {
  Contribution?: Maybe<Order_By>;
  ContributionAmount?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  RentPaid?: Maybe<Order_By>;
  RmatchScore?: Maybe<Order_By>;
  TenantTin?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.ura_Town" */
export type Rtcs_Db_Ura_Town = {
  __typename?: "rtcs_db_ura_Town";
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["bigint"]>;
  TownName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ura_Town" */
export type Rtcs_Db_Ura_Town_Aggregate = {
  __typename?: "rtcs_db_ura_Town_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ura_Town_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ura_Town>;
};

/** aggregate fields of "rtcs_db.ura_Town" */
export type Rtcs_Db_Ura_Town_Aggregate_Fields = {
  __typename?: "rtcs_db_ura_Town_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ura_Town_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ura_Town_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ura_Town_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ura_Town_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_Town_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_Town_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ura_Town_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ura_Town_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ura_Town_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ura_Town_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ura_Town" */
export type Rtcs_Db_Ura_Town_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rtcs_Db_Ura_Town_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "rtcs_db.ura_Town" */
export type Rtcs_Db_Ura_Town_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ura_Town_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ura_Town_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ura_Town_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ura_Town_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ura_Town_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ura_Town_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ura_Town_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ura_Town_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ura_Town_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ura_Town_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ura_Town" */
export type Rtcs_Db_Ura_Town_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ura_Town_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ura_Town_Avg_Fields = {
  __typename?: "rtcs_db_ura_Town_avg_fields";
  DistrictId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ura_Town" */
export type Rtcs_Db_Ura_Town_Avg_Order_By = {
  DistrictId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ura_Town". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ura_Town_Bool_Exp = {
  DistrictId?: Maybe<Bigint_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamp_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  TownCd?: Maybe<String_Comparison_Exp>;
  TownId?: Maybe<Bigint_Comparison_Exp>;
  TownName?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Rtcs_Db_Ura_Town_Bool_Exp>>>;
  _not?: Maybe<Rtcs_Db_Ura_Town_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Rtcs_Db_Ura_Town_Bool_Exp>>>;
};

/** input type for incrementing integer column in table "rtcs_db.ura_Town" */
export type Rtcs_Db_Ura_Town_Inc_Input = {
  DistrictId?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  TownId?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.ura_Town" */
export type Rtcs_Db_Ura_Town_Insert_Input = {
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["bigint"]>;
  TownName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ura_Town_Max_Fields = {
  __typename?: "rtcs_db_ura_Town_max_fields";
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["bigint"]>;
  TownName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ura_Town" */
export type Rtcs_Db_Ura_Town_Max_Order_By = {
  DistrictId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TownCd?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
  TownName?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ura_Town_Min_Fields = {
  __typename?: "rtcs_db_ura_Town_min_fields";
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["bigint"]>;
  TownName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ura_Town" */
export type Rtcs_Db_Ura_Town_Min_Order_By = {
  DistrictId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TownCd?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
  TownName?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ura_Town" */
export type Rtcs_Db_Ura_Town_Mutation_Response = {
  __typename?: "rtcs_db_ura_Town_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ura_Town>;
};

/** input type for inserting object relation for remote table "rtcs_db.ura_Town" */
export type Rtcs_Db_Ura_Town_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ura_Town_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.ura_Town" */
export type Rtcs_Db_Ura_Town_Order_By = {
  DistrictId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TownCd?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
  TownName?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ura_Town" */
export enum Rtcs_Db_Ura_Town_Select_Column {
  /** column name */
  DistrictId = "DistrictId",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  TownCd = "TownCd",
  /** column name */
  TownId = "TownId",
  /** column name */
  TownName = "TownName",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ura_Town" */
export type Rtcs_Db_Ura_Town_Set_Input = {
  DistrictId?: Maybe<Scalars["bigint"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamp"]>;
  Line?: Maybe<Scalars["bigint"]>;
  TownCd?: Maybe<Scalars["String"]>;
  TownId?: Maybe<Scalars["bigint"]>;
  TownName?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ura_Town_Stddev_Fields = {
  __typename?: "rtcs_db_ura_Town_stddev_fields";
  DistrictId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ura_Town" */
export type Rtcs_Db_Ura_Town_Stddev_Order_By = {
  DistrictId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ura_Town_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ura_Town_stddev_pop_fields";
  DistrictId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ura_Town" */
export type Rtcs_Db_Ura_Town_Stddev_Pop_Order_By = {
  DistrictId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ura_Town_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ura_Town_stddev_samp_fields";
  DistrictId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ura_Town" */
export type Rtcs_Db_Ura_Town_Stddev_Samp_Order_By = {
  DistrictId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ura_Town_Sum_Fields = {
  __typename?: "rtcs_db_ura_Town_sum_fields";
  DistrictId?: Maybe<Scalars["bigint"]>;
  Line?: Maybe<Scalars["bigint"]>;
  TownId?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.ura_Town" */
export type Rtcs_Db_Ura_Town_Sum_Order_By = {
  DistrictId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ura_Town_Var_Pop_Fields = {
  __typename?: "rtcs_db_ura_Town_var_pop_fields";
  DistrictId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ura_Town" */
export type Rtcs_Db_Ura_Town_Var_Pop_Order_By = {
  DistrictId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ura_Town_Var_Samp_Fields = {
  __typename?: "rtcs_db_ura_Town_var_samp_fields";
  DistrictId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ura_Town" */
export type Rtcs_Db_Ura_Town_Var_Samp_Order_By = {
  DistrictId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ura_Town_Variance_Fields = {
  __typename?: "rtcs_db_ura_Town_variance_fields";
  DistrictId?: Maybe<Scalars["Float"]>;
  Line?: Maybe<Scalars["Float"]>;
  TownId?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ura_Town" */
export type Rtcs_Db_Ura_Town_Variance_Order_By = {
  DistrictId?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  TownId?: Maybe<Order_By>;
};

/** columns and relationships of "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb = {
  __typename?: "rtcs_db_ursb_CompaniesAndBusinessNamesURSB";
  BusinessName?: Maybe<Scalars["String"]>;
  CompanyName?: Maybe<Scalars["String"]>;
  CompanyType?: Maybe<Scalars["String"]>;
  DataSource?: Maybe<Scalars["String"]>;
  DirectorFirstname?: Maybe<Scalars["String"]>;
  DirectorSurname?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Parish?: Maybe<Scalars["String"]>;
  PartnersFirstname?: Maybe<Scalars["String"]>;
  PartnersSurname?: Maybe<Scalars["String"]>;
  RegistrationDate?: Maybe<Scalars["timestamptz"]>;
  RegistrationNumber?: Maybe<Scalars["bigint"]>;
  ShareholderFirstname?: Maybe<Scalars["String"]>;
  ShareholdersSurname?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Subtype?: Maybe<Scalars["String"]>;
  Town?: Maybe<Scalars["String"]>;
  Trading?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Aggregate = {
  __typename?: "rtcs_db_ursb_CompaniesAndBusinessNamesURSB_aggregate";
  aggregate?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Aggregate_Fields>;
  nodes: Array<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb>;
};

/** aggregate fields of "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Aggregate_Fields = {
  __typename?: "rtcs_db_ursb_CompaniesAndBusinessNamesURSB_aggregate_fields";
  avg?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Avg_Fields>;
  count?: Maybe<Scalars["Int"]>;
  max?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Max_Fields>;
  min?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Min_Fields>;
  stddev?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Fields>;
  stddev_pop?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Samp_Fields>;
  sum?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Sum_Fields>;
  var_pop?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Var_Pop_Fields>;
  var_samp?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Var_Samp_Fields>;
  variance?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Variance_Fields>;
};

/** aggregate fields of "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column>
    >;
    distinct?: Maybe<Scalars["Boolean"]>;
  };

/** order by aggregate values of table "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Aggregate_Order_By = {
  avg?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Max_Order_By>;
  min?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Min_Order_By>;
  stddev?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Order_By>;
  stddev_pop?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Samp_Order_By>;
  sum?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Sum_Order_By>;
  var_pop?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Var_Pop_Order_By>;
  var_samp?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Var_Samp_Order_By>;
  variance?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Arr_Rel_Insert_Input = {
  data: Array<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Insert_Input>;
};

/** aggregate avg on columns */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Avg_Fields = {
  __typename?: "rtcs_db_ursb_CompaniesAndBusinessNamesURSB_avg_fields";
  Line?: Maybe<Scalars["Float"]>;
  RegistrationNumber?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Avg_Order_By = {
  Line?: Maybe<Order_By>;
  RegistrationNumber?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rtcs_db.ursb_CompaniesAndBusinessNamesURSB". All fields are combined with a logical 'AND'. */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp = {
  BusinessName?: Maybe<String_Comparison_Exp>;
  CompanyName?: Maybe<String_Comparison_Exp>;
  CompanyType?: Maybe<String_Comparison_Exp>;
  DataSource?: Maybe<String_Comparison_Exp>;
  DirectorFirstname?: Maybe<String_Comparison_Exp>;
  DirectorSurname?: Maybe<String_Comparison_Exp>;
  District?: Maybe<String_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  LastUpdated?: Maybe<Timestamptz_Comparison_Exp>;
  Line?: Maybe<Bigint_Comparison_Exp>;
  Parish?: Maybe<String_Comparison_Exp>;
  PartnersFirstname?: Maybe<String_Comparison_Exp>;
  PartnersSurname?: Maybe<String_Comparison_Exp>;
  RegistrationDate?: Maybe<Timestamptz_Comparison_Exp>;
  RegistrationNumber?: Maybe<Bigint_Comparison_Exp>;
  ShareholderFirstname?: Maybe<String_Comparison_Exp>;
  ShareholdersSurname?: Maybe<String_Comparison_Exp>;
  Street?: Maybe<String_Comparison_Exp>;
  Subtype?: Maybe<String_Comparison_Exp>;
  Town?: Maybe<String_Comparison_Exp>;
  Trading?: Maybe<String_Comparison_Exp>;
  Village?: Maybe<String_Comparison_Exp>;
  WhoUpdated?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<
    Array<Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp>>
  >;
  _not?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp>;
  _or?: Maybe<
    Array<Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp>>
  >;
};

/** input type for incrementing integer column in table "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Inc_Input = {
  Line?: Maybe<Scalars["bigint"]>;
  RegistrationNumber?: Maybe<Scalars["bigint"]>;
};

/** input type for inserting data into table "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Insert_Input = {
  BusinessName?: Maybe<Scalars["String"]>;
  CompanyName?: Maybe<Scalars["String"]>;
  CompanyType?: Maybe<Scalars["String"]>;
  DataSource?: Maybe<Scalars["String"]>;
  DirectorFirstname?: Maybe<Scalars["String"]>;
  DirectorSurname?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Parish?: Maybe<Scalars["String"]>;
  PartnersFirstname?: Maybe<Scalars["String"]>;
  PartnersSurname?: Maybe<Scalars["String"]>;
  RegistrationDate?: Maybe<Scalars["timestamptz"]>;
  RegistrationNumber?: Maybe<Scalars["bigint"]>;
  ShareholderFirstname?: Maybe<Scalars["String"]>;
  ShareholdersSurname?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Subtype?: Maybe<Scalars["String"]>;
  Town?: Maybe<Scalars["String"]>;
  Trading?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Max_Fields = {
  __typename?: "rtcs_db_ursb_CompaniesAndBusinessNamesURSB_max_fields";
  BusinessName?: Maybe<Scalars["String"]>;
  CompanyName?: Maybe<Scalars["String"]>;
  CompanyType?: Maybe<Scalars["String"]>;
  DataSource?: Maybe<Scalars["String"]>;
  DirectorFirstname?: Maybe<Scalars["String"]>;
  DirectorSurname?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Parish?: Maybe<Scalars["String"]>;
  PartnersFirstname?: Maybe<Scalars["String"]>;
  PartnersSurname?: Maybe<Scalars["String"]>;
  RegistrationDate?: Maybe<Scalars["timestamptz"]>;
  RegistrationNumber?: Maybe<Scalars["bigint"]>;
  ShareholderFirstname?: Maybe<Scalars["String"]>;
  ShareholdersSurname?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Subtype?: Maybe<Scalars["String"]>;
  Town?: Maybe<Scalars["String"]>;
  Trading?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Max_Order_By = {
  BusinessName?: Maybe<Order_By>;
  CompanyName?: Maybe<Order_By>;
  CompanyType?: Maybe<Order_By>;
  DataSource?: Maybe<Order_By>;
  DirectorFirstname?: Maybe<Order_By>;
  DirectorSurname?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  PartnersFirstname?: Maybe<Order_By>;
  PartnersSurname?: Maybe<Order_By>;
  RegistrationDate?: Maybe<Order_By>;
  RegistrationNumber?: Maybe<Order_By>;
  ShareholderFirstname?: Maybe<Order_By>;
  ShareholdersSurname?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Subtype?: Maybe<Order_By>;
  Town?: Maybe<Order_By>;
  Trading?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Min_Fields = {
  __typename?: "rtcs_db_ursb_CompaniesAndBusinessNamesURSB_min_fields";
  BusinessName?: Maybe<Scalars["String"]>;
  CompanyName?: Maybe<Scalars["String"]>;
  CompanyType?: Maybe<Scalars["String"]>;
  DataSource?: Maybe<Scalars["String"]>;
  DirectorFirstname?: Maybe<Scalars["String"]>;
  DirectorSurname?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Parish?: Maybe<Scalars["String"]>;
  PartnersFirstname?: Maybe<Scalars["String"]>;
  PartnersSurname?: Maybe<Scalars["String"]>;
  RegistrationDate?: Maybe<Scalars["timestamptz"]>;
  RegistrationNumber?: Maybe<Scalars["bigint"]>;
  ShareholderFirstname?: Maybe<Scalars["String"]>;
  ShareholdersSurname?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Subtype?: Maybe<Scalars["String"]>;
  Town?: Maybe<Scalars["String"]>;
  Trading?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Min_Order_By = {
  BusinessName?: Maybe<Order_By>;
  CompanyName?: Maybe<Order_By>;
  CompanyType?: Maybe<Order_By>;
  DataSource?: Maybe<Order_By>;
  DirectorFirstname?: Maybe<Order_By>;
  DirectorSurname?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  PartnersFirstname?: Maybe<Order_By>;
  PartnersSurname?: Maybe<Order_By>;
  RegistrationDate?: Maybe<Order_By>;
  RegistrationNumber?: Maybe<Order_By>;
  ShareholderFirstname?: Maybe<Order_By>;
  ShareholdersSurname?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Subtype?: Maybe<Order_By>;
  Town?: Maybe<Order_By>;
  Trading?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** response of any mutation on the table "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Mutation_Response = {
  __typename?: "rtcs_db_ursb_CompaniesAndBusinessNamesURSB_mutation_response";
  /** number of affected rows by the mutation */
  affected_rows: Scalars["Int"];
  /** data of the affected rows by the mutation */
  returning: Array<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb>;
};

/** input type for inserting object relation for remote table "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Obj_Rel_Insert_Input = {
  data: Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Insert_Input;
};

/** ordering options when selecting data from "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Order_By = {
  BusinessName?: Maybe<Order_By>;
  CompanyName?: Maybe<Order_By>;
  CompanyType?: Maybe<Order_By>;
  DataSource?: Maybe<Order_By>;
  DirectorFirstname?: Maybe<Order_By>;
  DirectorSurname?: Maybe<Order_By>;
  District?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  LastUpdated?: Maybe<Order_By>;
  Line?: Maybe<Order_By>;
  Parish?: Maybe<Order_By>;
  PartnersFirstname?: Maybe<Order_By>;
  PartnersSurname?: Maybe<Order_By>;
  RegistrationDate?: Maybe<Order_By>;
  RegistrationNumber?: Maybe<Order_By>;
  ShareholderFirstname?: Maybe<Order_By>;
  ShareholdersSurname?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  Subtype?: Maybe<Order_By>;
  Town?: Maybe<Order_By>;
  Trading?: Maybe<Order_By>;
  Village?: Maybe<Order_By>;
  WhoUpdated?: Maybe<Order_By>;
};

/** select columns of table "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
export enum Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column {
  /** column name */
  BusinessName = "BusinessName",
  /** column name */
  CompanyName = "CompanyName",
  /** column name */
  CompanyType = "CompanyType",
  /** column name */
  DataSource = "DataSource",
  /** column name */
  DirectorFirstname = "DirectorFirstname",
  /** column name */
  DirectorSurname = "DirectorSurname",
  /** column name */
  District = "District",
  /** column name */
  File = "File",
  /** column name */
  LastUpdated = "LastUpdated",
  /** column name */
  Line = "Line",
  /** column name */
  Parish = "Parish",
  /** column name */
  PartnersFirstname = "PartnersFirstname",
  /** column name */
  PartnersSurname = "PartnersSurname",
  /** column name */
  RegistrationDate = "RegistrationDate",
  /** column name */
  RegistrationNumber = "RegistrationNumber",
  /** column name */
  ShareholderFirstname = "ShareholderFirstname",
  /** column name */
  ShareholdersSurname = "ShareholdersSurname",
  /** column name */
  Street = "Street",
  /** column name */
  Subtype = "Subtype",
  /** column name */
  Town = "Town",
  /** column name */
  Trading = "Trading",
  /** column name */
  Village = "Village",
  /** column name */
  WhoUpdated = "WhoUpdated",
}

/** input type for updating data in table "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Set_Input = {
  BusinessName?: Maybe<Scalars["String"]>;
  CompanyName?: Maybe<Scalars["String"]>;
  CompanyType?: Maybe<Scalars["String"]>;
  DataSource?: Maybe<Scalars["String"]>;
  DirectorFirstname?: Maybe<Scalars["String"]>;
  DirectorSurname?: Maybe<Scalars["String"]>;
  District?: Maybe<Scalars["String"]>;
  File?: Maybe<Scalars["String"]>;
  LastUpdated?: Maybe<Scalars["timestamptz"]>;
  Line?: Maybe<Scalars["bigint"]>;
  Parish?: Maybe<Scalars["String"]>;
  PartnersFirstname?: Maybe<Scalars["String"]>;
  PartnersSurname?: Maybe<Scalars["String"]>;
  RegistrationDate?: Maybe<Scalars["timestamptz"]>;
  RegistrationNumber?: Maybe<Scalars["bigint"]>;
  ShareholderFirstname?: Maybe<Scalars["String"]>;
  ShareholdersSurname?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  Subtype?: Maybe<Scalars["String"]>;
  Town?: Maybe<Scalars["String"]>;
  Trading?: Maybe<Scalars["String"]>;
  Village?: Maybe<Scalars["String"]>;
  WhoUpdated?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Fields = {
  __typename?: "rtcs_db_ursb_CompaniesAndBusinessNamesURSB_stddev_fields";
  Line?: Maybe<Scalars["Float"]>;
  RegistrationNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Order_By = {
  Line?: Maybe<Order_By>;
  RegistrationNumber?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Pop_Fields = {
  __typename?: "rtcs_db_ursb_CompaniesAndBusinessNamesURSB_stddev_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  RegistrationNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  RegistrationNumber?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Samp_Fields = {
  __typename?: "rtcs_db_ursb_CompaniesAndBusinessNamesURSB_stddev_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  RegistrationNumber?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Stddev_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  RegistrationNumber?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Sum_Fields = {
  __typename?: "rtcs_db_ursb_CompaniesAndBusinessNamesURSB_sum_fields";
  Line?: Maybe<Scalars["bigint"]>;
  RegistrationNumber?: Maybe<Scalars["bigint"]>;
};

/** order by sum() on columns of table "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Sum_Order_By = {
  Line?: Maybe<Order_By>;
  RegistrationNumber?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Var_Pop_Fields = {
  __typename?: "rtcs_db_ursb_CompaniesAndBusinessNamesURSB_var_pop_fields";
  Line?: Maybe<Scalars["Float"]>;
  RegistrationNumber?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Var_Pop_Order_By = {
  Line?: Maybe<Order_By>;
  RegistrationNumber?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Var_Samp_Fields = {
  __typename?: "rtcs_db_ursb_CompaniesAndBusinessNamesURSB_var_samp_fields";
  Line?: Maybe<Scalars["Float"]>;
  RegistrationNumber?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Var_Samp_Order_By = {
  Line?: Maybe<Order_By>;
  RegistrationNumber?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Variance_Fields = {
  __typename?: "rtcs_db_ursb_CompaniesAndBusinessNamesURSB_variance_fields";
  Line?: Maybe<Scalars["Float"]>;
  RegistrationNumber?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
export type Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Variance_Order_By = {
  Line?: Maybe<Order_By>;
  RegistrationNumber?: Maybe<Order_By>;
};

/** subscription root */
export type Subscription_Root = {
  __typename?: "subscription_root";
  /** fetch data from the table: "rtcs_db.EntityFilter" */
  rtcs_db_EntityFilter: Array<Rtcs_Db_EntityFilter>;
  /** fetch aggregated fields from the table: "rtcs_db.EntityFilter" */
  rtcs_db_EntityFilter_aggregate: Rtcs_Db_EntityFilter_Aggregate;
  /** fetch data from the table: "rtcs_db.Filter" */
  rtcs_db_Filter: Array<Rtcs_Db_Filter>;
  /** fetch aggregated fields from the table: "rtcs_db.Filter" */
  rtcs_db_Filter_aggregate: Rtcs_Db_Filter_Aggregate;
  /** execute function "rtcs_db.FnEntityFilter" which returns "rtcs_db.PH_EntityFilter" */
  rtcs_db_FnEntityFilter: Array<Rtcs_Db_Ph_EntityFilter>;
  /** execute function "rtcs_db.FnEntityFilter" and query aggregates on result of table type "rtcs_db.PH_EntityFilter" */
  rtcs_db_FnEntityFilter_aggregate: Rtcs_Db_Ph_EntityFilter_Aggregate;
  /** execute function "rtcs_db.FnEntityFilter_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnEntityFilter_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnEntityFilter_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnEntityFilter_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnEntityFilter_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnEntityFilter_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnEntityFilter_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnEntityFilter_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnFilter" which returns "rtcs_db.PH_Filter" */
  rtcs_db_FnFilter: Array<Rtcs_Db_Ph_Filter>;
  /** execute function "rtcs_db.FnFilter" and query aggregates on result of table type "rtcs_db.PH_Filter" */
  rtcs_db_FnFilter_aggregate: Rtcs_Db_Ph_Filter_Aggregate;
  /** execute function "rtcs_db.FnFilter_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnFilter_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnFilter_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnFilter_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnFilter_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnFilter_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnFilter_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnFilter_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnGazzetteRates" which returns "rtcs_db.PH_GazzetteRates" */
  rtcs_db_FnGazzetteRates: Array<Rtcs_Db_Ph_GazzetteRates>;
  /** execute function "rtcs_db.FnGazzetteRates" and query aggregates on result of table type "rtcs_db.PH_GazzetteRates" */
  rtcs_db_FnGazzetteRates_aggregate: Rtcs_Db_Ph_GazzetteRates_Aggregate;
  /** execute function "rtcs_db.FnGazzetteRates_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnGazzetteRates_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnGazzetteRates_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnGazzetteRates_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnGazzetteRates_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnGazzetteRates_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnGazzetteRates_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnGazzetteRates_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_IndividualComplianceSummaryPlusRisk" which returns "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
  rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk: Array<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk>;
  /** execute function "rtcs_db.FnRPT_IndividualComplianceSummaryPlusRisk" and query aggregates on result of table type "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
  rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk_aggregate: Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Aggregate;
  /** execute function "rtcs_db.FnRPT_IndividualComplianceSummaryPlusRisk_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_IndividualComplianceSummaryPlusRisk_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_IndividualComplianceSummaryPlusRisk_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_IndividualComplianceSummaryPlusRisk_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_IndividualComposite" which returns "rtcs_db.PH_RPT_IndividualComposite" */
  rtcs_db_FnRPT_IndividualComposite: Array<Rtcs_Db_Ph_Rpt_IndividualComposite>;
  /** execute function "rtcs_db.FnRPT_IndividualComposite" and query aggregates on result of table type "rtcs_db.PH_RPT_IndividualComposite" */
  rtcs_db_FnRPT_IndividualComposite_aggregate: Rtcs_Db_Ph_Rpt_IndividualComposite_Aggregate;
  /** execute function "rtcs_db.FnRPT_IndividualComposite_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_IndividualComposite_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_IndividualComposite_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_IndividualComposite_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_IndividualComposite_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_IndividualComposite_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_IndividualComposite_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_IndividualComposite_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_IndividualRegistration" which returns "rtcs_db.PH_RPT_IndividualRegistration" */
  rtcs_db_FnRPT_IndividualRegistration: Array<Rtcs_Db_Ph_Rpt_IndividualRegistration>;
  /** execute function "rtcs_db.FnRPT_IndividualRegistration" and query aggregates on result of table type "rtcs_db.PH_RPT_IndividualRegistration" */
  rtcs_db_FnRPT_IndividualRegistration_aggregate: Rtcs_Db_Ph_Rpt_IndividualRegistration_Aggregate;
  /** execute function "rtcs_db.FnRPT_IndividualRegistration_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_IndividualRegistration_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_IndividualRegistration_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_IndividualRegistration_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_IndividualRegistration_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_IndividualRegistration_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_IndividualRegistration_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_IndividualRegistration_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_IndividualTaxSummaryData" which returns "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
  rtcs_db_FnRPT_IndividualTaxSummaryData: Array<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData>;
  /** execute function "rtcs_db.FnRPT_IndividualTaxSummaryData" and query aggregates on result of table type "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
  rtcs_db_FnRPT_IndividualTaxSummaryData_aggregate: Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Aggregate;
  /** execute function "rtcs_db.FnRPT_IndividualTaxSummaryData_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_IndividualTaxSummaryData_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_IndividualTaxSummaryData_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_IndividualTaxSummaryData_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_IndividualTaxSummaryData_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_IndividualTaxSummaryData_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_IndividualTaxSummaryData_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_IndividualTaxSummaryData_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_KccaAffiliates" which returns "rtcs_db.PH_RPT_KccaAffiliates" */
  rtcs_db_FnRPT_KccaAffiliates: Array<Rtcs_Db_Ph_Rpt_KccaAffiliates>;
  /** execute function "rtcs_db.FnRPT_KccaAffiliates" and query aggregates on result of table type "rtcs_db.PH_RPT_KccaAffiliates" */
  rtcs_db_FnRPT_KccaAffiliates_aggregate: Rtcs_Db_Ph_Rpt_KccaAffiliates_Aggregate;
  /** execute function "rtcs_db.FnRPT_KccaAffiliates_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_KccaAffiliates_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_KccaAffiliates_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_KccaAffiliates_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_KccaAffiliates_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_KccaAffiliates_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_KccaAffiliates_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_KccaAffiliates_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_KccaCustomerRegistrationData" which returns "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
  rtcs_db_FnRPT_KccaCustomerRegistrationData: Array<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData>;
  /** execute function "rtcs_db.FnRPT_KccaCustomerRegistrationData" and query aggregates on result of table type "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
  rtcs_db_FnRPT_KccaCustomerRegistrationData_aggregate: Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Aggregate;
  /** execute function "rtcs_db.FnRPT_KccaCustomerRegistrationData_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_KccaCustomerRegistrationData_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_KccaCustomerRegistrationData_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_KccaCustomerRegistrationData_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_KccaCustomerRegistrationData_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_KccaCustomerRegistrationData_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_KccaCustomerRegistrationData_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_KccaCustomerRegistrationData_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_KccaPropertyRegistration" which returns "rtcs_db.PH_RPT_KccaPropertyRegistration" */
  rtcs_db_FnRPT_KccaPropertyRegistration: Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration>;
  /** execute function "rtcs_db.FnRPT_KccaPropertyRegistrationData" which returns "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
  rtcs_db_FnRPT_KccaPropertyRegistrationData: Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData>;
  /** execute function "rtcs_db.FnRPT_KccaPropertyRegistrationData" and query aggregates on result of table type "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
  rtcs_db_FnRPT_KccaPropertyRegistrationData_aggregate: Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Aggregate;
  /** execute function "rtcs_db.FnRPT_KccaPropertyRegistrationData_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_KccaPropertyRegistrationData_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_KccaPropertyRegistrationData_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_KccaPropertyRegistrationData_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_KccaPropertyRegistrationData_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_KccaPropertyRegistrationData_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_KccaPropertyRegistrationData_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_KccaPropertyRegistrationData_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_KccaPropertyRegistration" and query aggregates on result of table type "rtcs_db.PH_RPT_KccaPropertyRegistration" */
  rtcs_db_FnRPT_KccaPropertyRegistration_aggregate: Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Aggregate;
  /** execute function "rtcs_db.FnRPT_KccaPropertyRegistration_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_KccaPropertyRegistration_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_KccaPropertyRegistration_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_KccaPropertyRegistration_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_KccaPropertyRegistration_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_KccaPropertyRegistration_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_KccaPropertyRegistration_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_KccaPropertyRegistration_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_NonIndividualBalanceSheet" which returns "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
  rtcs_db_FnRPT_NonIndividualBalanceSheet: Array<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet>;
  /** execute function "rtcs_db.FnRPT_NonIndividualBalanceSheet" and query aggregates on result of table type "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
  rtcs_db_FnRPT_NonIndividualBalanceSheet_aggregate: Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Aggregate;
  /** execute function "rtcs_db.FnRPT_NonIndividualBalanceSheet_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_NonIndividualBalanceSheet_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_NonIndividualBalanceSheet_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_NonIndividualBalanceSheet_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_NonIndividualBalanceSheet_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_NonIndividualBalanceSheet_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_NonIndividualBalanceSheet_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_NonIndividualBalanceSheet_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_NonIndividualComplianceSummaryPlusRisk" which returns "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
  rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk: Array<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk>;
  /** execute function "rtcs_db.FnRPT_NonIndividualComplianceSummaryPlusRisk" and query aggregates on result of table type "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
  rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk_aggregate: Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Aggregate;
  /** execute function "rtcs_db.FnRPT_NonIndividualComplianceSummaryPlusRisk_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_NonIndividualComplianceSummaryPlusRisk_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_NonIndividualComplianceSummaryPlusRisk_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_NonIndividualComplianceSummaryPlusRisk_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_NonIndividualComposite" which returns "rtcs_db.PH_RPT_NonIndividualComposite" */
  rtcs_db_FnRPT_NonIndividualComposite: Array<Rtcs_Db_Ph_Rpt_NonIndividualComposite>;
  /** execute function "rtcs_db.FnRPT_NonIndividualComposite" and query aggregates on result of table type "rtcs_db.PH_RPT_NonIndividualComposite" */
  rtcs_db_FnRPT_NonIndividualComposite_aggregate: Rtcs_Db_Ph_Rpt_NonIndividualComposite_Aggregate;
  /** execute function "rtcs_db.FnRPT_NonIndividualComposite_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_NonIndividualComposite_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_NonIndividualComposite_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_NonIndividualComposite_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_NonIndividualComposite_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_NonIndividualComposite_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_NonIndividualComposite_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_NonIndividualComposite_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_NonIndividualTaxSummaryData" which returns "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
  rtcs_db_FnRPT_NonIndividualTaxSummaryData: Array<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData>;
  /** execute function "rtcs_db.FnRPT_NonIndividualTaxSummaryData" and query aggregates on result of table type "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
  rtcs_db_FnRPT_NonIndividualTaxSummaryData_aggregate: Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Aggregate;
  /** execute function "rtcs_db.FnRPT_NonIndividualTaxSummaryData_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_NonIndividualTaxSummaryData_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_NonIndividualTaxSummaryData_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_NonIndividualTaxSummaryData_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_NonIndividualTaxSummaryData_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_NonIndividualTaxSummaryData_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_NonIndividualTaxSummaryData_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_NonIndividualTaxSummaryData_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_PropertiesComposite" which returns "rtcs_db.PH_RPT_PropertiesComposite" */
  rtcs_db_FnRPT_PropertiesComposite: Array<Rtcs_Db_Ph_Rpt_PropertiesComposite>;
  /** execute function "rtcs_db.FnRPT_PropertiesComposite" and query aggregates on result of table type "rtcs_db.PH_RPT_PropertiesComposite" */
  rtcs_db_FnRPT_PropertiesComposite_aggregate: Rtcs_Db_Ph_Rpt_PropertiesComposite_Aggregate;
  /** execute function "rtcs_db.FnRPT_PropertiesComposite_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_PropertiesComposite_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_PropertiesComposite_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_PropertiesComposite_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_PropertiesComposite_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_PropertiesComposite_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_PropertiesComposite_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_PropertiesComposite_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_PropertyValuation" which returns "rtcs_db.PH_RPT_PropertyValuation" */
  rtcs_db_FnRPT_PropertyValuation: Array<Rtcs_Db_Ph_Rpt_PropertyValuation>;
  /** execute function "rtcs_db.FnRPT_PropertyValuation" and query aggregates on result of table type "rtcs_db.PH_RPT_PropertyValuation" */
  rtcs_db_FnRPT_PropertyValuation_aggregate: Rtcs_Db_Ph_Rpt_PropertyValuation_Aggregate;
  /** execute function "rtcs_db.FnRPT_PropertyValuation_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_PropertyValuation_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_PropertyValuation_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_PropertyValuation_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_PropertyValuation_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_PropertyValuation_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_PropertyValuation_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_PropertyValuation_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.FnRPT_RentalTenantPayments" which returns "rtcs_db.PH_RPT_RentalTenantPayments" */
  rtcs_db_FnRPT_RentalTenantPayments: Array<Rtcs_Db_Ph_Rpt_RentalTenantPayments>;
  /** execute function "rtcs_db.FnRPT_RentalTenantPayments" and query aggregates on result of table type "rtcs_db.PH_RPT_RentalTenantPayments" */
  rtcs_db_FnRPT_RentalTenantPayments_aggregate: Rtcs_Db_Ph_Rpt_RentalTenantPayments_Aggregate;
  /** execute function "rtcs_db.FnRPT_RentalTenantPayments_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_RentalTenantPayments_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.FnRPT_RentalTenantPayments_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_FnRPT_RentalTenantPayments_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.FnRPT_RentalTenantPayments_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_RentalTenantPayments_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.FnRPT_RentalTenantPayments_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_FnRPT_RentalTenantPayments_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fngazette_CommercialRates" which returns "rtcs_db.PH_gazette_CommercialRates" */
  rtcs_db_Fngazette_CommercialRates: Array<Rtcs_Db_Ph_Gazette_CommercialRates>;
  /** execute function "rtcs_db.Fngazette_CommercialRates" and query aggregates on result of table type "rtcs_db.PH_gazette_CommercialRates" */
  rtcs_db_Fngazette_CommercialRates_aggregate: Rtcs_Db_Ph_Gazette_CommercialRates_Aggregate;
  /** execute function "rtcs_db.Fngazette_CommercialRates_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fngazette_CommercialRates_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fngazette_CommercialRates_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fngazette_CommercialRates_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fngazette_CommercialRates_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fngazette_CommercialRates_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fngazette_CommercialRates_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fngazette_CommercialRates_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fngazette_ResidentialRates" which returns "rtcs_db.PH_gazette_ResidentialRates" */
  rtcs_db_Fngazette_ResidentialRates: Array<Rtcs_Db_Ph_Gazette_ResidentialRates>;
  /** execute function "rtcs_db.Fngazette_ResidentialRates" and query aggregates on result of table type "rtcs_db.PH_gazette_ResidentialRates" */
  rtcs_db_Fngazette_ResidentialRates_aggregate: Rtcs_Db_Ph_Gazette_ResidentialRates_Aggregate;
  /** execute function "rtcs_db.Fngazette_ResidentialRates_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fngazette_ResidentialRates_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fngazette_ResidentialRates_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fngazette_ResidentialRates_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fngazette_ResidentialRates_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fngazette_ResidentialRates_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fngazette_ResidentialRates_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fngazette_ResidentialRates_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_AssociateType" which returns "rtcs_db.PH_kcca_AssociateType" */
  rtcs_db_Fnkcca_AssociateType: Array<Rtcs_Db_Ph_Kcca_AssociateType>;
  /** execute function "rtcs_db.Fnkcca_AssociateType" and query aggregates on result of table type "rtcs_db.PH_kcca_AssociateType" */
  rtcs_db_Fnkcca_AssociateType_aggregate: Rtcs_Db_Ph_Kcca_AssociateType_Aggregate;
  /** execute function "rtcs_db.Fnkcca_AssociateType_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_AssociateType_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_AssociateType_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_AssociateType_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_AssociateType_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_AssociateType_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_AssociateType_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_AssociateType_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CommercialProperties" which returns "rtcs_db.PH_kcca_CommercialProperties" */
  rtcs_db_Fnkcca_CommercialProperties: Array<Rtcs_Db_Ph_Kcca_CommercialProperties>;
  /** execute function "rtcs_db.Fnkcca_CommercialProperties" and query aggregates on result of table type "rtcs_db.PH_kcca_CommercialProperties" */
  rtcs_db_Fnkcca_CommercialProperties_aggregate: Rtcs_Db_Ph_Kcca_CommercialProperties_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CommercialProperties_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CommercialProperties_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_CommercialProperties_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CommercialProperties_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CommercialProperties_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CommercialProperties_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_CommercialProperties_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CommercialProperties_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_County" which returns "rtcs_db.PH_kcca_County" */
  rtcs_db_Fnkcca_County: Array<Rtcs_Db_Ph_Kcca_County>;
  /** execute function "rtcs_db.Fnkcca_County" and query aggregates on result of table type "rtcs_db.PH_kcca_County" */
  rtcs_db_Fnkcca_County_aggregate: Rtcs_Db_Ph_Kcca_County_Aggregate;
  /** execute function "rtcs_db.Fnkcca_County_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_County_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_County_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_County_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_County_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_County_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_County_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_County_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Customer" which returns "rtcs_db.PH_kcca_Customer" */
  rtcs_db_Fnkcca_Customer: Array<Rtcs_Db_Ph_Kcca_Customer>;
  /** execute function "rtcs_db.Fnkcca_CustomerApplicantType" which returns "rtcs_db.PH_kcca_CustomerApplicantType" */
  rtcs_db_Fnkcca_CustomerApplicantType: Array<Rtcs_Db_Ph_Kcca_CustomerApplicantType>;
  /** execute function "rtcs_db.Fnkcca_CustomerApplicantType" and query aggregates on result of table type "rtcs_db.PH_kcca_CustomerApplicantType" */
  rtcs_db_Fnkcca_CustomerApplicantType_aggregate: Rtcs_Db_Ph_Kcca_CustomerApplicantType_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerApplicantType_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerApplicantType_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_CustomerApplicantType_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerApplicantType_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerApplicantType_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerApplicantType_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_CustomerApplicantType_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerApplicantType_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerBusinessAssociate" which returns "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
  rtcs_db_Fnkcca_CustomerBusinessAssociate: Array<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate>;
  /** execute function "rtcs_db.Fnkcca_CustomerBusinessAssociate" and query aggregates on result of table type "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
  rtcs_db_Fnkcca_CustomerBusinessAssociate_aggregate: Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerBusinessAssociate_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerBusinessAssociate_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_CustomerBusinessAssociate_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerBusinessAssociate_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerBusinessAssociate_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerBusinessAssociate_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_CustomerBusinessAssociate_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerBusinessAssociate_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerBusinessType" which returns "rtcs_db.PH_kcca_CustomerBusinessType" */
  rtcs_db_Fnkcca_CustomerBusinessType: Array<Rtcs_Db_Ph_Kcca_CustomerBusinessType>;
  /** execute function "rtcs_db.Fnkcca_CustomerBusinessType" and query aggregates on result of table type "rtcs_db.PH_kcca_CustomerBusinessType" */
  rtcs_db_Fnkcca_CustomerBusinessType_aggregate: Rtcs_Db_Ph_Kcca_CustomerBusinessType_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerBusinessType_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerBusinessType_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_CustomerBusinessType_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerBusinessType_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerBusinessType_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerBusinessType_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_CustomerBusinessType_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerBusinessType_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerCoinDeactivateReactive" which returns "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
  rtcs_db_Fnkcca_CustomerCoinDeactivateReactive: Array<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive>;
  /** execute function "rtcs_db.Fnkcca_CustomerCoinDeactivateReactive" and query aggregates on result of table type "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
  rtcs_db_Fnkcca_CustomerCoinDeactivateReactive_aggregate: Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerCoinDeactivateReactive_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerCoinDeactivateReactive_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_CustomerCoinDeactivateReactive_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerCoinDeactivateReactive_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerCoinDeactivateReactive_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerCoinDeactivateReactive_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_CustomerCoinDeactivateReactive_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerCoinDeactivateReactive_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerContactPerson" which returns "rtcs_db.PH_kcca_CustomerContactPerson" */
  rtcs_db_Fnkcca_CustomerContactPerson: Array<Rtcs_Db_Ph_Kcca_CustomerContactPerson>;
  /** execute function "rtcs_db.Fnkcca_CustomerContactPerson" and query aggregates on result of table type "rtcs_db.PH_kcca_CustomerContactPerson" */
  rtcs_db_Fnkcca_CustomerContactPerson_aggregate: Rtcs_Db_Ph_Kcca_CustomerContactPerson_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerContactPerson_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerContactPerson_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_CustomerContactPerson_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerContactPerson_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerContactPerson_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerContactPerson_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_CustomerContactPerson_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerContactPerson_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerDocumentItem" which returns "rtcs_db.PH_kcca_CustomerDocumentItem" */
  rtcs_db_Fnkcca_CustomerDocumentItem: Array<Rtcs_Db_Ph_Kcca_CustomerDocumentItem>;
  /** execute function "rtcs_db.Fnkcca_CustomerDocumentItem" and query aggregates on result of table type "rtcs_db.PH_kcca_CustomerDocumentItem" */
  rtcs_db_Fnkcca_CustomerDocumentItem_aggregate: Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerDocumentItem_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerDocumentItem_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_CustomerDocumentItem_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerDocumentItem_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerDocumentItem_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerDocumentItem_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_CustomerDocumentItem_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerDocumentItem_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerGuardian" which returns "rtcs_db.PH_kcca_CustomerGuardian" */
  rtcs_db_Fnkcca_CustomerGuardian: Array<Rtcs_Db_Ph_Kcca_CustomerGuardian>;
  /** execute function "rtcs_db.Fnkcca_CustomerGuardian" and query aggregates on result of table type "rtcs_db.PH_kcca_CustomerGuardian" */
  rtcs_db_Fnkcca_CustomerGuardian_aggregate: Rtcs_Db_Ph_Kcca_CustomerGuardian_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerGuardian_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerGuardian_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_CustomerGuardian_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerGuardian_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerGuardian_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerGuardian_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_CustomerGuardian_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerGuardian_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerReference" which returns "rtcs_db.PH_kcca_CustomerReference" */
  rtcs_db_Fnkcca_CustomerReference: Array<Rtcs_Db_Ph_Kcca_CustomerReference>;
  /** execute function "rtcs_db.Fnkcca_CustomerReference" and query aggregates on result of table type "rtcs_db.PH_kcca_CustomerReference" */
  rtcs_db_Fnkcca_CustomerReference_aggregate: Rtcs_Db_Ph_Kcca_CustomerReference_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerReference_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerReference_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_CustomerReference_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerReference_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerReference_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerReference_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_CustomerReference_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerReference_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerRegistrationReason" which returns "rtcs_db.PH_kcca_CustomerRegistrationReason" */
  rtcs_db_Fnkcca_CustomerRegistrationReason: Array<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason>;
  /** execute function "rtcs_db.Fnkcca_CustomerRegistrationReason" and query aggregates on result of table type "rtcs_db.PH_kcca_CustomerRegistrationReason" */
  rtcs_db_Fnkcca_CustomerRegistrationReason_aggregate: Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerRegistrationReason_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerRegistrationReason_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_CustomerRegistrationReason_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerRegistrationReason_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerRegistrationReason_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerRegistrationReason_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_CustomerRegistrationReason_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerRegistrationReason_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerRevenueAgent" which returns "rtcs_db.PH_kcca_CustomerRevenueAgent" */
  rtcs_db_Fnkcca_CustomerRevenueAgent: Array<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent>;
  /** execute function "rtcs_db.Fnkcca_CustomerRevenueAgent" and query aggregates on result of table type "rtcs_db.PH_kcca_CustomerRevenueAgent" */
  rtcs_db_Fnkcca_CustomerRevenueAgent_aggregate: Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerRevenueAgent_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerRevenueAgent_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_CustomerRevenueAgent_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerRevenueAgent_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerRevenueAgent_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerRevenueAgent_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_CustomerRevenueAgent_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerRevenueAgent_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerType" which returns "rtcs_db.PH_kcca_CustomerType" */
  rtcs_db_Fnkcca_CustomerType: Array<Rtcs_Db_Ph_Kcca_CustomerType>;
  /** execute function "rtcs_db.Fnkcca_CustomerType" and query aggregates on result of table type "rtcs_db.PH_kcca_CustomerType" */
  rtcs_db_Fnkcca_CustomerType_aggregate: Rtcs_Db_Ph_Kcca_CustomerType_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerType_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerType_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_CustomerType_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_CustomerType_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_CustomerType_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerType_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_CustomerType_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_CustomerType_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Customer" and query aggregates on result of table type "rtcs_db.PH_kcca_Customer" */
  rtcs_db_Fnkcca_Customer_aggregate: Rtcs_Db_Ph_Kcca_Customer_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Customer_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Customer_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_Customer_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Customer_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Customer_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Customer_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_Customer_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Customer_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Customer_sid" which returns "rtcs_db.PH_kcca_Customer_sid" */
  rtcs_db_Fnkcca_Customer_sid: Array<Rtcs_Db_Ph_Kcca_Customer_Sid>;
  /** execute function "rtcs_db.Fnkcca_Customer_sid" and query aggregates on result of table type "rtcs_db.PH_kcca_Customer_sid" */
  rtcs_db_Fnkcca_Customer_sid_aggregate: Rtcs_Db_Ph_Kcca_Customer_Sid_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Customer_sid_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Customer_sid_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_Customer_sid_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Customer_sid_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Customer_sid_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Customer_sid_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_Customer_sid_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Customer_sid_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_District" which returns "rtcs_db.PH_kcca_District" */
  rtcs_db_Fnkcca_District: Array<Rtcs_Db_Ph_Kcca_District>;
  /** execute function "rtcs_db.Fnkcca_District" and query aggregates on result of table type "rtcs_db.PH_kcca_District" */
  rtcs_db_Fnkcca_District_aggregate: Rtcs_Db_Ph_Kcca_District_Aggregate;
  /** execute function "rtcs_db.Fnkcca_District_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_District_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_District_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_District_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_District_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_District_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_District_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_District_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Division" which returns "rtcs_db.PH_kcca_Division" */
  rtcs_db_Fnkcca_Division: Array<Rtcs_Db_Ph_Kcca_Division>;
  /** execute function "rtcs_db.Fnkcca_Division" and query aggregates on result of table type "rtcs_db.PH_kcca_Division" */
  rtcs_db_Fnkcca_Division_aggregate: Rtcs_Db_Ph_Kcca_Division_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Division_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Division_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_Division_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Division_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Division_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Division_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_Division_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Division_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_ImgsData" which returns "rtcs_db.PH_kcca_ImgsData" */
  rtcs_db_Fnkcca_ImgsData: Array<Rtcs_Db_Ph_Kcca_ImgsData>;
  /** execute function "rtcs_db.Fnkcca_ImgsData" and query aggregates on result of table type "rtcs_db.PH_kcca_ImgsData" */
  rtcs_db_Fnkcca_ImgsData_aggregate: Rtcs_Db_Ph_Kcca_ImgsData_Aggregate;
  /** execute function "rtcs_db.Fnkcca_ImgsData_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_ImgsData_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_ImgsData_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_ImgsData_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_ImgsData_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_ImgsData_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_ImgsData_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_ImgsData_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Parish" which returns "rtcs_db.PH_kcca_Parish" */
  rtcs_db_Fnkcca_Parish: Array<Rtcs_Db_Ph_Kcca_Parish>;
  /** execute function "rtcs_db.Fnkcca_Parish" and query aggregates on result of table type "rtcs_db.PH_kcca_Parish" */
  rtcs_db_Fnkcca_Parish_aggregate: Rtcs_Db_Ph_Kcca_Parish_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Parish_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Parish_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_Parish_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Parish_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Parish_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Parish_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_Parish_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Parish_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PaymentDetails" which returns "rtcs_db.PH_kcca_PaymentDetails" */
  rtcs_db_Fnkcca_PaymentDetails: Array<Rtcs_Db_Ph_Kcca_PaymentDetails>;
  /** execute function "rtcs_db.Fnkcca_PaymentDetails" and query aggregates on result of table type "rtcs_db.PH_kcca_PaymentDetails" */
  rtcs_db_Fnkcca_PaymentDetails_aggregate: Rtcs_Db_Ph_Kcca_PaymentDetails_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PaymentDetails_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PaymentDetails_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_PaymentDetails_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PaymentDetails_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PaymentDetails_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PaymentDetails_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_PaymentDetails_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PaymentDetails_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Payments" which returns "rtcs_db.PH_kcca_Payments" */
  rtcs_db_Fnkcca_Payments: Array<Rtcs_Db_Ph_Kcca_Payments>;
  /** execute function "rtcs_db.Fnkcca_Payments" and query aggregates on result of table type "rtcs_db.PH_kcca_Payments" */
  rtcs_db_Fnkcca_Payments_aggregate: Rtcs_Db_Ph_Kcca_Payments_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Payments_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Payments_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_Payments_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Payments_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Payments_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Payments_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_Payments_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Payments_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Property" which returns "rtcs_db.PH_kcca_Property" */
  rtcs_db_Fnkcca_Property: Array<Rtcs_Db_Ph_Kcca_Property>;
  /** execute function "rtcs_db.Fnkcca_PropertyFloors" which returns "rtcs_db.PH_kcca_PropertyFloors" */
  rtcs_db_Fnkcca_PropertyFloors: Array<Rtcs_Db_Ph_Kcca_PropertyFloors>;
  /** execute function "rtcs_db.Fnkcca_PropertyFloors" and query aggregates on result of table type "rtcs_db.PH_kcca_PropertyFloors" */
  rtcs_db_Fnkcca_PropertyFloors_aggregate: Rtcs_Db_Ph_Kcca_PropertyFloors_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertyFloors_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PropertyFloors_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_PropertyFloors_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PropertyFloors_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertyFloors_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PropertyFloors_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_PropertyFloors_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PropertyFloors_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertyNumberBedrooms" which returns "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
  rtcs_db_Fnkcca_PropertyNumberBedrooms: Array<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms>;
  /** execute function "rtcs_db.Fnkcca_PropertyNumberBedrooms" and query aggregates on result of table type "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
  rtcs_db_Fnkcca_PropertyNumberBedrooms_aggregate: Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertyNumberBedrooms_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PropertyNumberBedrooms_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_PropertyNumberBedrooms_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PropertyNumberBedrooms_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertyNumberBedrooms_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PropertyNumberBedrooms_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_PropertyNumberBedrooms_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PropertyNumberBedrooms_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertyRentedStatus" which returns "rtcs_db.PH_kcca_PropertyRentedStatus" */
  rtcs_db_Fnkcca_PropertyRentedStatus: Array<Rtcs_Db_Ph_Kcca_PropertyRentedStatus>;
  /** execute function "rtcs_db.Fnkcca_PropertyRentedStatus" and query aggregates on result of table type "rtcs_db.PH_kcca_PropertyRentedStatus" */
  rtcs_db_Fnkcca_PropertyRentedStatus_aggregate: Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertyRentedStatus_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PropertyRentedStatus_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_PropertyRentedStatus_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PropertyRentedStatus_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertyRentedStatus_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PropertyRentedStatus_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_PropertyRentedStatus_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PropertyRentedStatus_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertySecurity" which returns "rtcs_db.PH_kcca_PropertySecurity" */
  rtcs_db_Fnkcca_PropertySecurity: Array<Rtcs_Db_Ph_Kcca_PropertySecurity>;
  /** execute function "rtcs_db.Fnkcca_PropertySecurity" and query aggregates on result of table type "rtcs_db.PH_kcca_PropertySecurity" */
  rtcs_db_Fnkcca_PropertySecurity_aggregate: Rtcs_Db_Ph_Kcca_PropertySecurity_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertySecurity_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PropertySecurity_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_PropertySecurity_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PropertySecurity_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertySecurity_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PropertySecurity_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_PropertySecurity_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PropertySecurity_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertySubType" which returns "rtcs_db.PH_kcca_PropertySubType" */
  rtcs_db_Fnkcca_PropertySubType: Array<Rtcs_Db_Ph_Kcca_PropertySubType>;
  /** execute function "rtcs_db.Fnkcca_PropertySubType" and query aggregates on result of table type "rtcs_db.PH_kcca_PropertySubType" */
  rtcs_db_Fnkcca_PropertySubType_aggregate: Rtcs_Db_Ph_Kcca_PropertySubType_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertySubType_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PropertySubType_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_PropertySubType_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PropertySubType_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertySubType_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PropertySubType_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_PropertySubType_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PropertySubType_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertyType" which returns "rtcs_db.PH_kcca_PropertyType" */
  rtcs_db_Fnkcca_PropertyType: Array<Rtcs_Db_Ph_Kcca_PropertyType>;
  /** execute function "rtcs_db.Fnkcca_PropertyType" and query aggregates on result of table type "rtcs_db.PH_kcca_PropertyType" */
  rtcs_db_Fnkcca_PropertyType_aggregate: Rtcs_Db_Ph_Kcca_PropertyType_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertyType_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PropertyType_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_PropertyType_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_PropertyType_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_PropertyType_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PropertyType_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_PropertyType_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_PropertyType_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Property" and query aggregates on result of table type "rtcs_db.PH_kcca_Property" */
  rtcs_db_Fnkcca_Property_aggregate: Rtcs_Db_Ph_Kcca_Property_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Property_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Property_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_Property_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Property_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Property_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Property_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_Property_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Property_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_RawProperties1" which returns "rtcs_db.PH_kcca_RawProperties1" */
  rtcs_db_Fnkcca_RawProperties1: Array<Rtcs_Db_Ph_Kcca_RawProperties1>;
  /** execute function "rtcs_db.Fnkcca_RawProperties1" and query aggregates on result of table type "rtcs_db.PH_kcca_RawProperties1" */
  rtcs_db_Fnkcca_RawProperties1_aggregate: Rtcs_Db_Ph_Kcca_RawProperties1_Aggregate;
  /** execute function "rtcs_db.Fnkcca_RawProperties1_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_RawProperties1_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_RawProperties1_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_RawProperties1_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_RawProperties1_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_RawProperties1_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_RawProperties1_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_RawProperties1_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_RawProperties2" which returns "rtcs_db.PH_kcca_RawProperties2" */
  rtcs_db_Fnkcca_RawProperties2: Array<Rtcs_Db_Ph_Kcca_RawProperties2>;
  /** execute function "rtcs_db.Fnkcca_RawProperties2" and query aggregates on result of table type "rtcs_db.PH_kcca_RawProperties2" */
  rtcs_db_Fnkcca_RawProperties2_aggregate: Rtcs_Db_Ph_Kcca_RawProperties2_Aggregate;
  /** execute function "rtcs_db.Fnkcca_RawProperties2_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_RawProperties2_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_RawProperties2_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_RawProperties2_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_RawProperties2_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_RawProperties2_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_RawProperties2_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_RawProperties2_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_ResidentialProperties" which returns "rtcs_db.PH_kcca_ResidentialProperties" */
  rtcs_db_Fnkcca_ResidentialProperties: Array<Rtcs_Db_Ph_Kcca_ResidentialProperties>;
  /** execute function "rtcs_db.Fnkcca_ResidentialProperties" and query aggregates on result of table type "rtcs_db.PH_kcca_ResidentialProperties" */
  rtcs_db_Fnkcca_ResidentialProperties_aggregate: Rtcs_Db_Ph_Kcca_ResidentialProperties_Aggregate;
  /** execute function "rtcs_db.Fnkcca_ResidentialProperties_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_ResidentialProperties_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_ResidentialProperties_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_ResidentialProperties_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_ResidentialProperties_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_ResidentialProperties_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_ResidentialProperties_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_ResidentialProperties_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_RevenueType" which returns "rtcs_db.PH_kcca_RevenueType" */
  rtcs_db_Fnkcca_RevenueType: Array<Rtcs_Db_Ph_Kcca_RevenueType>;
  /** execute function "rtcs_db.Fnkcca_RevenueType" and query aggregates on result of table type "rtcs_db.PH_kcca_RevenueType" */
  rtcs_db_Fnkcca_RevenueType_aggregate: Rtcs_Db_Ph_Kcca_RevenueType_Aggregate;
  /** execute function "rtcs_db.Fnkcca_RevenueType_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_RevenueType_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_RevenueType_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_RevenueType_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_RevenueType_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_RevenueType_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_RevenueType_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_RevenueType_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_SubCounty" which returns "rtcs_db.PH_kcca_SubCounty" */
  rtcs_db_Fnkcca_SubCounty: Array<Rtcs_Db_Ph_Kcca_SubCounty>;
  /** execute function "rtcs_db.Fnkcca_SubCounty" and query aggregates on result of table type "rtcs_db.PH_kcca_SubCounty" */
  rtcs_db_Fnkcca_SubCounty_aggregate: Rtcs_Db_Ph_Kcca_SubCounty_Aggregate;
  /** execute function "rtcs_db.Fnkcca_SubCounty_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_SubCounty_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_SubCounty_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_SubCounty_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_SubCounty_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_SubCounty_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_SubCounty_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_SubCounty_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_SubpropertyTypeConversion" which returns "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
  rtcs_db_Fnkcca_SubpropertyTypeConversion: Array<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion>;
  /** execute function "rtcs_db.Fnkcca_SubpropertyTypeConversion" and query aggregates on result of table type "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
  rtcs_db_Fnkcca_SubpropertyTypeConversion_aggregate: Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Aggregate;
  /** execute function "rtcs_db.Fnkcca_SubpropertyTypeConversion_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_SubpropertyTypeConversion_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_SubpropertyTypeConversion_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_SubpropertyTypeConversion_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_SubpropertyTypeConversion_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_SubpropertyTypeConversion_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_SubpropertyTypeConversion_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_SubpropertyTypeConversion_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_TradingLicense" which returns "rtcs_db.PH_kcca_TradingLicense" */
  rtcs_db_Fnkcca_TradingLicense: Array<Rtcs_Db_Ph_Kcca_TradingLicense>;
  /** execute function "rtcs_db.Fnkcca_TradingLicense" and query aggregates on result of table type "rtcs_db.PH_kcca_TradingLicense" */
  rtcs_db_Fnkcca_TradingLicense_aggregate: Rtcs_Db_Ph_Kcca_TradingLicense_Aggregate;
  /** execute function "rtcs_db.Fnkcca_TradingLicense_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_TradingLicense_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_TradingLicense_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_TradingLicense_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_TradingLicense_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_TradingLicense_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_TradingLicense_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_TradingLicense_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Village" which returns "rtcs_db.PH_kcca_Village" */
  rtcs_db_Fnkcca_Village: Array<Rtcs_Db_Ph_Kcca_Village>;
  /** execute function "rtcs_db.Fnkcca_Village" and query aggregates on result of table type "rtcs_db.PH_kcca_Village" */
  rtcs_db_Fnkcca_Village_aggregate: Rtcs_Db_Ph_Kcca_Village_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Village_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Village_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnkcca_Village_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnkcca_Village_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnkcca_Village_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Village_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnkcca_Village_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnkcca_Village_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnmaster_MasterIndiv" which returns "rtcs_db.PH_master_MasterIndiv" */
  rtcs_db_Fnmaster_MasterIndiv: Array<Rtcs_Db_Ph_Master_MasterIndiv>;
  /** execute function "rtcs_db.Fnmaster_MasterIndiv" and query aggregates on result of table type "rtcs_db.PH_master_MasterIndiv" */
  rtcs_db_Fnmaster_MasterIndiv_aggregate: Rtcs_Db_Ph_Master_MasterIndiv_Aggregate;
  /** execute function "rtcs_db.Fnmaster_MasterIndiv_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnmaster_MasterIndiv_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnmaster_MasterIndiv_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnmaster_MasterIndiv_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnmaster_MasterIndiv_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnmaster_MasterIndiv_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnmaster_MasterIndiv_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnmaster_MasterIndiv_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_KCCA" which returns "rtcs_db.PH_mlhud_KCCA" */
  rtcs_db_Fnmlhud_KCCA: Array<Rtcs_Db_Ph_Mlhud_Kcca>;
  /** execute function "rtcs_db.Fnmlhud_KCCA" and query aggregates on result of table type "rtcs_db.PH_mlhud_KCCA" */
  rtcs_db_Fnmlhud_KCCA_aggregate: Rtcs_Db_Ph_Mlhud_Kcca_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_KCCA_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnmlhud_KCCA_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnmlhud_KCCA_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnmlhud_KCCA_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_KCCA_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnmlhud_KCCA_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnmlhud_KCCA_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnmlhud_KCCA_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_Mukono" which returns "rtcs_db.PH_mlhud_Mukono" */
  rtcs_db_Fnmlhud_Mukono: Array<Rtcs_Db_Ph_Mlhud_Mukono>;
  /** execute function "rtcs_db.Fnmlhud_Mukono" and query aggregates on result of table type "rtcs_db.PH_mlhud_Mukono" */
  rtcs_db_Fnmlhud_Mukono_aggregate: Rtcs_Db_Ph_Mlhud_Mukono_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_Mukono_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnmlhud_Mukono_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnmlhud_Mukono_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnmlhud_Mukono_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_Mukono_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnmlhud_Mukono_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnmlhud_Mukono_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnmlhud_Mukono_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_Properties" which returns "rtcs_db.PH_mlhud_Properties" */
  rtcs_db_Fnmlhud_Properties: Array<Rtcs_Db_Ph_Mlhud_Properties>;
  /** execute function "rtcs_db.Fnmlhud_Properties" and query aggregates on result of table type "rtcs_db.PH_mlhud_Properties" */
  rtcs_db_Fnmlhud_Properties_aggregate: Rtcs_Db_Ph_Mlhud_Properties_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_Properties_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnmlhud_Properties_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnmlhud_Properties_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnmlhud_Properties_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_Properties_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnmlhud_Properties_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnmlhud_Properties_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnmlhud_Properties_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_WakisoBusiro" which returns "rtcs_db.PH_mlhud_WakisoBusiro" */
  rtcs_db_Fnmlhud_WakisoBusiro: Array<Rtcs_Db_Ph_Mlhud_WakisoBusiro>;
  /** execute function "rtcs_db.Fnmlhud_WakisoBusiro" and query aggregates on result of table type "rtcs_db.PH_mlhud_WakisoBusiro" */
  rtcs_db_Fnmlhud_WakisoBusiro_aggregate: Rtcs_Db_Ph_Mlhud_WakisoBusiro_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_WakisoBusiro_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnmlhud_WakisoBusiro_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnmlhud_WakisoBusiro_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnmlhud_WakisoBusiro_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_WakisoBusiro_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnmlhud_WakisoBusiro_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnmlhud_WakisoBusiro_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnmlhud_WakisoBusiro_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_WakisoKyadondo" which returns "rtcs_db.PH_mlhud_WakisoKyadondo" */
  rtcs_db_Fnmlhud_WakisoKyadondo: Array<Rtcs_Db_Ph_Mlhud_WakisoKyadondo>;
  /** execute function "rtcs_db.Fnmlhud_WakisoKyadondo" and query aggregates on result of table type "rtcs_db.PH_mlhud_WakisoKyadondo" */
  rtcs_db_Fnmlhud_WakisoKyadondo_aggregate: Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_WakisoKyadondo_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnmlhud_WakisoKyadondo_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnmlhud_WakisoKyadondo_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnmlhud_WakisoKyadondo_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnmlhud_WakisoKyadondo_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnmlhud_WakisoKyadondo_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnmlhud_WakisoKyadondo_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnmlhud_WakisoKyadondo_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnnira_Nira" which returns "rtcs_db.PH_nira_Nira" */
  rtcs_db_Fnnira_Nira: Array<Rtcs_Db_Ph_Nira_Nira>;
  /** execute function "rtcs_db.Fnnira_Nira" and query aggregates on result of table type "rtcs_db.PH_nira_Nira" */
  rtcs_db_Fnnira_Nira_aggregate: Rtcs_Db_Ph_Nira_Nira_Aggregate;
  /** execute function "rtcs_db.Fnnira_Nira_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnnira_Nira_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnnira_Nira_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnnira_Nira_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnnira_Nira_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnira_Nira_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnnira_Nira_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnira_Nira_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnnira_RawData" which returns "rtcs_db.PH_nira_RawData" */
  rtcs_db_Fnnira_RawData: Array<Rtcs_Db_Ph_Nira_RawData>;
  /** execute function "rtcs_db.Fnnira_RawData" and query aggregates on result of table type "rtcs_db.PH_nira_RawData" */
  rtcs_db_Fnnira_RawData_aggregate: Rtcs_Db_Ph_Nira_RawData_Aggregate;
  /** execute function "rtcs_db.Fnnira_RawData_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnnira_RawData_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnnira_RawData_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnnira_RawData_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnnira_RawData_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnira_RawData_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnnira_RawData_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnira_RawData_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_Customers" which returns "rtcs_db.PH_nwsc_Customers" */
  rtcs_db_Fnnwsc_Customers: Array<Rtcs_Db_Ph_Nwsc_Customers>;
  /** execute function "rtcs_db.Fnnwsc_Customers" and query aggregates on result of table type "rtcs_db.PH_nwsc_Customers" */
  rtcs_db_Fnnwsc_Customers_aggregate: Rtcs_Db_Ph_Nwsc_Customers_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_Customers_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnnwsc_Customers_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnnwsc_Customers_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnnwsc_Customers_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_Customers_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnwsc_Customers_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnnwsc_Customers_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnwsc_Customers_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_NWCustomers" which returns "rtcs_db.PH_nwsc_NWCustomers" */
  rtcs_db_Fnnwsc_NWCustomers: Array<Rtcs_Db_Ph_Nwsc_NwCustomers>;
  /** execute function "rtcs_db.Fnnwsc_NWCustomers" and query aggregates on result of table type "rtcs_db.PH_nwsc_NWCustomers" */
  rtcs_db_Fnnwsc_NWCustomers_aggregate: Rtcs_Db_Ph_Nwsc_NwCustomers_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_NWCustomers_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnnwsc_NWCustomers_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnnwsc_NWCustomers_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnnwsc_NWCustomers_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_NWCustomers_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnwsc_NWCustomers_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnnwsc_NWCustomers_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnwsc_NWCustomers_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_RPT_Transactions" which returns "rtcs_db.PH_nwsc_RPT_Transactions" */
  rtcs_db_Fnnwsc_RPT_Transactions: Array<Rtcs_Db_Ph_Nwsc_Rpt_Transactions>;
  /** execute function "rtcs_db.Fnnwsc_RPT_Transactions" and query aggregates on result of table type "rtcs_db.PH_nwsc_RPT_Transactions" */
  rtcs_db_Fnnwsc_RPT_Transactions_aggregate: Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_RPT_Transactions_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnnwsc_RPT_Transactions_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnnwsc_RPT_Transactions_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnnwsc_RPT_Transactions_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_RPT_Transactions_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnwsc_RPT_Transactions_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnnwsc_RPT_Transactions_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnwsc_RPT_Transactions_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_Transactions" which returns "rtcs_db.PH_nwsc_Transactions" */
  rtcs_db_Fnnwsc_Transactions: Array<Rtcs_Db_Ph_Nwsc_Transactions>;
  /** execute function "rtcs_db.Fnnwsc_Transactions" and query aggregates on result of table type "rtcs_db.PH_nwsc_Transactions" */
  rtcs_db_Fnnwsc_Transactions_aggregate: Rtcs_Db_Ph_Nwsc_Transactions_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_Transactions_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnnwsc_Transactions_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnnwsc_Transactions_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnnwsc_Transactions_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_Transactions_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnwsc_Transactions_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnnwsc_Transactions_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnwsc_Transactions_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_URATransactions" which returns "rtcs_db.PH_nwsc_URATransactions" */
  rtcs_db_Fnnwsc_URATransactions: Array<Rtcs_Db_Ph_Nwsc_UraTransactions>;
  /** execute function "rtcs_db.Fnnwsc_URATransactions" and query aggregates on result of table type "rtcs_db.PH_nwsc_URATransactions" */
  rtcs_db_Fnnwsc_URATransactions_aggregate: Rtcs_Db_Ph_Nwsc_UraTransactions_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_URATransactions_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnnwsc_URATransactions_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnnwsc_URATransactions_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnnwsc_URATransactions_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnnwsc_URATransactions_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnwsc_URATransactions_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnnwsc_URATransactions_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnnwsc_URATransactions_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTUnittypes" which returns "rtcs_db.PH_rcapture_RPTUnittypes" */
  rtcs_db_Fnrcapture_RPTUnittypes: Array<Rtcs_Db_Ph_Rcapture_RptUnittypes>;
  /** execute function "rtcs_db.Fnrcapture_RPTUnittypes" and query aggregates on result of table type "rtcs_db.PH_rcapture_RPTUnittypes" */
  rtcs_db_Fnrcapture_RPTUnittypes_aggregate: Rtcs_Db_Ph_Rcapture_RptUnittypes_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTUnittypes_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrcapture_RPTUnittypes_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrcapture_RPTUnittypes_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrcapture_RPTUnittypes_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTUnittypes_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrcapture_RPTUnittypes_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrcapture_RPTUnittypes_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrcapture_RPTUnittypes_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTlessors" which returns "rtcs_db.PH_rcapture_RPTlessors" */
  rtcs_db_Fnrcapture_RPTlessors: Array<Rtcs_Db_Ph_Rcapture_RpTlessors>;
  /** execute function "rtcs_db.Fnrcapture_RPTlessors" and query aggregates on result of table type "rtcs_db.PH_rcapture_RPTlessors" */
  rtcs_db_Fnrcapture_RPTlessors_aggregate: Rtcs_Db_Ph_Rcapture_RpTlessors_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTlessors_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrcapture_RPTlessors_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrcapture_RPTlessors_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrcapture_RPTlessors_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTlessors_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrcapture_RPTlessors_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrcapture_RPTlessors_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrcapture_RPTlessors_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTmeta" which returns "rtcs_db.PH_rcapture_RPTmeta" */
  rtcs_db_Fnrcapture_RPTmeta: Array<Rtcs_Db_Ph_Rcapture_RpTmeta>;
  /** execute function "rtcs_db.Fnrcapture_RPTmeta" and query aggregates on result of table type "rtcs_db.PH_rcapture_RPTmeta" */
  rtcs_db_Fnrcapture_RPTmeta_aggregate: Rtcs_Db_Ph_Rcapture_RpTmeta_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTmeta_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrcapture_RPTmeta_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrcapture_RPTmeta_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrcapture_RPTmeta_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTmeta_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrcapture_RPTmeta_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrcapture_RPTmeta_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrcapture_RPTmeta_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTproperties" which returns "rtcs_db.PH_rcapture_RPTproperties" */
  rtcs_db_Fnrcapture_RPTproperties: Array<Rtcs_Db_Ph_Rcapture_RpTproperties>;
  /** execute function "rtcs_db.Fnrcapture_RPTproperties" and query aggregates on result of table type "rtcs_db.PH_rcapture_RPTproperties" */
  rtcs_db_Fnrcapture_RPTproperties_aggregate: Rtcs_Db_Ph_Rcapture_RpTproperties_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTproperties_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrcapture_RPTproperties_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrcapture_RPTproperties_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrcapture_RPTproperties_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTproperties_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrcapture_RPTproperties_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrcapture_RPTproperties_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrcapture_RPTproperties_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTtenants" which returns "rtcs_db.PH_rcapture_RPTtenants" */
  rtcs_db_Fnrcapture_RPTtenants: Array<Rtcs_Db_Ph_Rcapture_RpTtenants>;
  /** execute function "rtcs_db.Fnrcapture_RPTtenants" and query aggregates on result of table type "rtcs_db.PH_rcapture_RPTtenants" */
  rtcs_db_Fnrcapture_RPTtenants_aggregate: Rtcs_Db_Ph_Rcapture_RpTtenants_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTtenants_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrcapture_RPTtenants_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrcapture_RPTtenants_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrcapture_RPTtenants_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrcapture_RPTtenants_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrcapture_RPTtenants_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrcapture_RPTtenants_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrcapture_RPTtenants_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Individual" which returns "rtcs_db.PH_rmatch_Individual" */
  rtcs_db_Fnrmatch_Individual: Array<Rtcs_Db_Ph_Rmatch_Individual>;
  /** execute function "rtcs_db.Fnrmatch_IndividualComplianceDetailReport" which returns "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
  rtcs_db_Fnrmatch_IndividualComplianceDetailReport: Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport>;
  /** execute function "rtcs_db.Fnrmatch_IndividualComplianceDetailReport" and query aggregates on result of table type "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
  rtcs_db_Fnrmatch_IndividualComplianceDetailReport_aggregate: Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualComplianceDetailReport_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_IndividualComplianceDetailReport_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_IndividualComplianceDetailReport_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_IndividualComplianceDetailReport_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualComplianceDetailReport_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_IndividualComplianceDetailReport_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_IndividualComplianceDetailReport_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_IndividualComplianceDetailReport_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualComplianceSummaryReport" which returns "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
  rtcs_db_Fnrmatch_IndividualComplianceSummaryReport: Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport>;
  /** execute function "rtcs_db.Fnrmatch_IndividualComplianceSummaryReport" and query aggregates on result of table type "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
  rtcs_db_Fnrmatch_IndividualComplianceSummaryReport_aggregate: Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualComplianceSummaryReport_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_IndividualComplianceSummaryReport_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_IndividualComplianceSummaryReport_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_IndividualComplianceSummaryReport_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualComplianceSummaryReport_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_IndividualComplianceSummaryReport_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_IndividualComplianceSummaryReport_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_IndividualComplianceSummaryReport_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualRiskScore" which returns "rtcs_db.PH_rmatch_IndividualRiskScore" */
  rtcs_db_Fnrmatch_IndividualRiskScore: Array<Rtcs_Db_Ph_Rmatch_IndividualRiskScore>;
  /** execute function "rtcs_db.Fnrmatch_IndividualRiskScore" and query aggregates on result of table type "rtcs_db.PH_rmatch_IndividualRiskScore" */
  rtcs_db_Fnrmatch_IndividualRiskScore_aggregate: Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualRiskScore_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_IndividualRiskScore_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_IndividualRiskScore_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_IndividualRiskScore_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualRiskScore_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_IndividualRiskScore_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_IndividualRiskScore_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_IndividualRiskScore_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Individual" and query aggregates on result of table type "rtcs_db.PH_rmatch_Individual" */
  rtcs_db_Fnrmatch_Individual_aggregate: Rtcs_Db_Ph_Rmatch_Individual_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Individual_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_Individual_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_Individual_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_Individual_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Individual_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_Individual_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_Individual_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_Individual_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualsNetwork" which returns "rtcs_db.PH_rmatch_IndividualsNetwork" */
  rtcs_db_Fnrmatch_IndividualsNetwork: Array<Rtcs_Db_Ph_Rmatch_IndividualsNetwork>;
  /** execute function "rtcs_db.Fnrmatch_IndividualsNetworkTesting" which returns "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
  rtcs_db_Fnrmatch_IndividualsNetworkTesting: Array<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting>;
  /** execute function "rtcs_db.Fnrmatch_IndividualsNetworkTesting" and query aggregates on result of table type "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
  rtcs_db_Fnrmatch_IndividualsNetworkTesting_aggregate: Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualsNetworkTesting_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_IndividualsNetworkTesting_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_IndividualsNetworkTesting_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_IndividualsNetworkTesting_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualsNetworkTesting_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_IndividualsNetworkTesting_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_IndividualsNetworkTesting_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_IndividualsNetworkTesting_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualsNetwork" and query aggregates on result of table type "rtcs_db.PH_rmatch_IndividualsNetwork" */
  rtcs_db_Fnrmatch_IndividualsNetwork_aggregate: Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualsNetwork_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_IndividualsNetwork_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_IndividualsNetwork_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_IndividualsNetwork_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_IndividualsNetwork_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_IndividualsNetwork_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_IndividualsNetwork_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_IndividualsNetwork_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_KccaToKccaIndividualOwners" which returns "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
  rtcs_db_Fnrmatch_KccaToKccaIndividualOwners: Array<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners>;
  /** execute function "rtcs_db.Fnrmatch_KccaToKccaIndividualOwners" and query aggregates on result of table type "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
  rtcs_db_Fnrmatch_KccaToKccaIndividualOwners_aggregate: Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_KccaToKccaIndividualOwners_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_KccaToKccaIndividualOwners_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_KccaToKccaIndividualOwners_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_KccaToKccaIndividualOwners_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_KccaToKccaIndividualOwners_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_KccaToKccaIndividualOwners_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_KccaToKccaIndividualOwners_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_KccaToKccaIndividualOwners_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_KccaToKccaNonindividualOwners" which returns "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
  rtcs_db_Fnrmatch_KccaToKccaNonindividualOwners: Array<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners>;
  /** execute function "rtcs_db.Fnrmatch_KccaToKccaNonindividualOwners" and query aggregates on result of table type "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
  rtcs_db_Fnrmatch_KccaToKccaNonindividualOwners_aggregate: Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_KccaToKccaNonindividualOwners_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_KccaToKccaNonindividualOwners_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_KccaToKccaNonindividualOwners_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_KccaToKccaNonindividualOwners_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_KccaToKccaNonindividualOwners_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_KccaToKccaNonindividualOwners_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_KccaToKccaNonindividualOwners_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_KccaToKccaNonindividualOwners_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_MatchIndividualsProperties" which returns "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
  rtcs_db_Fnrmatch_MatchIndividualsProperties: Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties>;
  /** execute function "rtcs_db.Fnrmatch_MatchIndividualsProperties" and query aggregates on result of table type "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
  rtcs_db_Fnrmatch_MatchIndividualsProperties_aggregate: Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_MatchIndividualsProperties_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_MatchIndividualsProperties_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_MatchIndividualsProperties_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_MatchIndividualsProperties_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_MatchIndividualsProperties_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_MatchIndividualsProperties_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_MatchIndividualsProperties_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_MatchIndividualsProperties_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_MatchIndividualsProps" which returns "rtcs_db.PH_rmatch_MatchIndividualsProps" */
  rtcs_db_Fnrmatch_MatchIndividualsProps: Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps>;
  /** execute function "rtcs_db.Fnrmatch_MatchIndividualsProps" and query aggregates on result of table type "rtcs_db.PH_rmatch_MatchIndividualsProps" */
  rtcs_db_Fnrmatch_MatchIndividualsProps_aggregate: Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_MatchIndividualsProps_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_MatchIndividualsProps_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_MatchIndividualsProps_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_MatchIndividualsProps_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_MatchIndividualsProps_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_MatchIndividualsProps_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_MatchIndividualsProps_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_MatchIndividualsProps_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NoReturns" which returns "rtcs_db.PH_rmatch_NoReturns" */
  rtcs_db_Fnrmatch_NoReturns: Array<Rtcs_Db_Ph_Rmatch_NoReturns>;
  /** execute function "rtcs_db.Fnrmatch_NoReturns" and query aggregates on result of table type "rtcs_db.PH_rmatch_NoReturns" */
  rtcs_db_Fnrmatch_NoReturns_aggregate: Rtcs_Db_Ph_Rmatch_NoReturns_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NoReturns_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NoReturns_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_NoReturns_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NoReturns_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NoReturns_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NoReturns_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_NoReturns_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NoReturns_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividual" which returns "rtcs_db.PH_rmatch_NonIndividual" */
  rtcs_db_Fnrmatch_NonIndividual: Array<Rtcs_Db_Ph_Rmatch_NonIndividual>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualComplianceDetailReport" which returns "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
  rtcs_db_Fnrmatch_NonIndividualComplianceDetailReport: Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualComplianceDetailReport" and query aggregates on result of table type "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
  rtcs_db_Fnrmatch_NonIndividualComplianceDetailReport_aggregate: Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualComplianceDetailReport_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonIndividualComplianceDetailReport_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualComplianceDetailReport_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonIndividualComplianceDetailReport_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualComplianceDetailReport_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonIndividualComplianceDetailReport_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualComplianceDetailReport_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonIndividualComplianceDetailReport_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualComplianceSummaryReport" which returns "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
  rtcs_db_Fnrmatch_NonIndividualComplianceSummaryReport: Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualComplianceSummaryReport" and query aggregates on result of table type "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
  rtcs_db_Fnrmatch_NonIndividualComplianceSummaryReport_aggregate: Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualComplianceSummaryReport_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonIndividualComplianceSummaryReport_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualComplianceSummaryReport_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonIndividualComplianceSummaryReport_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualComplianceSummaryReport_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonIndividualComplianceSummaryReport_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualComplianceSummaryReport_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonIndividualComplianceSummaryReport_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualRiskScore" which returns "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
  rtcs_db_Fnrmatch_NonIndividualRiskScore: Array<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualRiskScore" and query aggregates on result of table type "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
  rtcs_db_Fnrmatch_NonIndividualRiskScore_aggregate: Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualRiskScore_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonIndividualRiskScore_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualRiskScore_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonIndividualRiskScore_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualRiskScore_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonIndividualRiskScore_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualRiskScore_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonIndividualRiskScore_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividual" and query aggregates on result of table type "rtcs_db.PH_rmatch_NonIndividual" */
  rtcs_db_Fnrmatch_NonIndividual_aggregate: Rtcs_Db_Ph_Rmatch_NonIndividual_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividual_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonIndividual_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividual_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonIndividual_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividual_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonIndividual_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividual_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonIndividual_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividuals" which returns "rtcs_db.PH_rmatch_NonIndividuals" */
  rtcs_db_Fnrmatch_NonIndividuals: Array<Rtcs_Db_Ph_Rmatch_NonIndividuals>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualsNetwork" which returns "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
  rtcs_db_Fnrmatch_NonIndividualsNetwork: Array<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualsNetwork" and query aggregates on result of table type "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
  rtcs_db_Fnrmatch_NonIndividualsNetwork_aggregate: Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualsNetwork_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonIndividualsNetwork_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualsNetwork_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonIndividualsNetwork_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualsNetwork_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonIndividualsNetwork_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividualsNetwork_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonIndividualsNetwork_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividuals" and query aggregates on result of table type "rtcs_db.PH_rmatch_NonIndividuals" */
  rtcs_db_Fnrmatch_NonIndividuals_aggregate: Rtcs_Db_Ph_Rmatch_NonIndividuals_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividuals_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonIndividuals_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividuals_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonIndividuals_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonIndividuals_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonIndividuals_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_NonIndividuals_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonIndividuals_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonindividualOwners" which returns "rtcs_db.PH_rmatch_NonindividualOwners" */
  rtcs_db_Fnrmatch_NonindividualOwners: Array<Rtcs_Db_Ph_Rmatch_NonindividualOwners>;
  /** execute function "rtcs_db.Fnrmatch_NonindividualOwners" and query aggregates on result of table type "rtcs_db.PH_rmatch_NonindividualOwners" */
  rtcs_db_Fnrmatch_NonindividualOwners_aggregate: Rtcs_Db_Ph_Rmatch_NonindividualOwners_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonindividualOwners_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonindividualOwners_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_NonindividualOwners_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_NonindividualOwners_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_NonindividualOwners_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonindividualOwners_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_NonindividualOwners_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_NonindividualOwners_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Nonindividuals" which returns "rtcs_db.PH_rmatch_Nonindividuals" */
  rtcs_db_Fnrmatch_Nonindividuals: Array<Rtcs_Db_Ph_Rmatch_Nonindividuals>;
  /** execute function "rtcs_db.Fnrmatch_Nonindividuals" and query aggregates on result of table type "rtcs_db.PH_rmatch_Nonindividuals" */
  rtcs_db_Fnrmatch_Nonindividuals_aggregate: Rtcs_Db_Ph_Rmatch_Nonindividuals_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Nonindividuals_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_Nonindividuals_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_Nonindividuals_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_Nonindividuals_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Nonindividuals_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_Nonindividuals_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_Nonindividuals_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_Nonindividuals_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_PropertiesComposite" which returns "rtcs_db.PH_rmatch_PropertiesComposite" */
  rtcs_db_Fnrmatch_PropertiesComposite: Array<Rtcs_Db_Ph_Rmatch_PropertiesComposite>;
  /** execute function "rtcs_db.Fnrmatch_PropertiesComposite" and query aggregates on result of table type "rtcs_db.PH_rmatch_PropertiesComposite" */
  rtcs_db_Fnrmatch_PropertiesComposite_aggregate: Rtcs_Db_Ph_Rmatch_PropertiesComposite_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_PropertiesComposite_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_PropertiesComposite_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_PropertiesComposite_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_PropertiesComposite_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_PropertiesComposite_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_PropertiesComposite_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_PropertiesComposite_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_PropertiesComposite_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_PropertiesNetwork" which returns "rtcs_db.PH_rmatch_PropertiesNetwork" */
  rtcs_db_Fnrmatch_PropertiesNetwork: Array<Rtcs_Db_Ph_Rmatch_PropertiesNetwork>;
  /** execute function "rtcs_db.Fnrmatch_PropertiesNetwork" and query aggregates on result of table type "rtcs_db.PH_rmatch_PropertiesNetwork" */
  rtcs_db_Fnrmatch_PropertiesNetwork_aggregate: Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_PropertiesNetwork_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_PropertiesNetwork_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_PropertiesNetwork_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_PropertiesNetwork_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_PropertiesNetwork_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_PropertiesNetwork_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_PropertiesNetwork_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_PropertiesNetwork_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_PropertyNetwork" which returns "rtcs_db.PH_rmatch_PropertyNetwork" */
  rtcs_db_Fnrmatch_PropertyNetwork: Array<Rtcs_Db_Ph_Rmatch_PropertyNetwork>;
  /** execute function "rtcs_db.Fnrmatch_PropertyNetwork" and query aggregates on result of table type "rtcs_db.PH_rmatch_PropertyNetwork" */
  rtcs_db_Fnrmatch_PropertyNetwork_aggregate: Rtcs_Db_Ph_Rmatch_PropertyNetwork_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_PropertyNetwork_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_PropertyNetwork_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_PropertyNetwork_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_PropertyNetwork_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_PropertyNetwork_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_PropertyNetwork_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_PropertyNetwork_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_PropertyNetwork_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed904" which returns "rtcs_db.PH_rmatch_RMatchDetailed904" */
  rtcs_db_Fnrmatch_RMatchDetailed904: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed904>;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed904" and query aggregates on result of table type "rtcs_db.PH_rmatch_RMatchDetailed904" */
  rtcs_db_Fnrmatch_RMatchDetailed904_aggregate: Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed904_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchDetailed904_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed904_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchDetailed904_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed904_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchDetailed904_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed904_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchDetailed904_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed916" which returns "rtcs_db.PH_rmatch_RMatchDetailed916" */
  rtcs_db_Fnrmatch_RMatchDetailed916: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916>;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy" which returns "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
  rtcs_db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy>;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy" and query aggregates on result of table type "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
  rtcs_db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregate: Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed916" and query aggregates on result of table type "rtcs_db.PH_rmatch_RMatchDetailed916" */
  rtcs_db_Fnrmatch_RMatchDetailed916_aggregate: Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed916_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchDetailed916_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed916_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchDetailed916_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed916_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchDetailed916_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed916_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchDetailed916_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed937" which returns "rtcs_db.PH_rmatch_RMatchDetailed937" */
  rtcs_db_Fnrmatch_RMatchDetailed937: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed937>;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed937" and query aggregates on result of table type "rtcs_db.PH_rmatch_RMatchDetailed937" */
  rtcs_db_Fnrmatch_RMatchDetailed937_aggregate: Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed937_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchDetailed937_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed937_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchDetailed937_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed937_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchDetailed937_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_RMatchDetailed937_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchDetailed937_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchKccaProperties" which returns "rtcs_db.PH_rmatch_RMatchKccaProperties" */
  rtcs_db_Fnrmatch_RMatchKccaProperties: Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties>;
  /** execute function "rtcs_db.Fnrmatch_RMatchKccaProperties" and query aggregates on result of table type "rtcs_db.PH_rmatch_RMatchKccaProperties" */
  rtcs_db_Fnrmatch_RMatchKccaProperties_aggregate: Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchKccaProperties_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchKccaProperties_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_RMatchKccaProperties_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchKccaProperties_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchKccaProperties_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchKccaProperties_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_RMatchKccaProperties_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchKccaProperties_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchKccaProperty" which returns "rtcs_db.PH_rmatch_RMatchKccaProperty" */
  rtcs_db_Fnrmatch_RMatchKccaProperty: Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty>;
  /** execute function "rtcs_db.Fnrmatch_RMatchKccaProperty" and query aggregates on result of table type "rtcs_db.PH_rmatch_RMatchKccaProperty" */
  rtcs_db_Fnrmatch_RMatchKccaProperty_aggregate: Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchKccaProperty_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchKccaProperty_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_RMatchKccaProperty_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchKccaProperty_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchKccaProperty_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchKccaProperty_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_RMatchKccaProperty_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchKccaProperty_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchNonIndividual903" which returns "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
  rtcs_db_Fnrmatch_RMatchNonIndividual903: Array<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903>;
  /** execute function "rtcs_db.Fnrmatch_RMatchNonIndividual903" and query aggregates on result of table type "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
  rtcs_db_Fnrmatch_RMatchNonIndividual903_aggregate: Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchNonIndividual903_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchNonIndividual903_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_RMatchNonIndividual903_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchNonIndividual903_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchNonIndividual903_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchNonIndividual903_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_RMatchNonIndividual903_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchNonIndividual903_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchProperties1115" which returns "rtcs_db.PH_rmatch_RMatchProperties1115" */
  rtcs_db_Fnrmatch_RMatchProperties1115: Array<Rtcs_Db_Ph_Rmatch_RMatchProperties1115>;
  /** execute function "rtcs_db.Fnrmatch_RMatchProperties1115" and query aggregates on result of table type "rtcs_db.PH_rmatch_RMatchProperties1115" */
  rtcs_db_Fnrmatch_RMatchProperties1115_aggregate: Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchProperties1115_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchProperties1115_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_RMatchProperties1115_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchProperties1115_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchProperties1115_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchProperties1115_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_RMatchProperties1115_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchProperties1115_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary904" which returns "rtcs_db.PH_rmatch_RMatchSummary904" */
  rtcs_db_Fnrmatch_RMatchSummary904: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary904>;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary904" and query aggregates on result of table type "rtcs_db.PH_rmatch_RMatchSummary904" */
  rtcs_db_Fnrmatch_RMatchSummary904_aggregate: Rtcs_Db_Ph_Rmatch_RMatchSummary904_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary904_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchSummary904_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary904_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchSummary904_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary904_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchSummary904_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary904_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchSummary904_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary916" which returns "rtcs_db.PH_rmatch_RMatchSummary916" */
  rtcs_db_Fnrmatch_RMatchSummary916: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916>;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy" which returns "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
  rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy>;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy" and query aggregates on result of table type "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
  rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregate: Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary916" and query aggregates on result of table type "rtcs_db.PH_rmatch_RMatchSummary916" */
  rtcs_db_Fnrmatch_RMatchSummary916_aggregate: Rtcs_Db_Ph_Rmatch_RMatchSummary916_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary916_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchSummary916_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary916_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchSummary916_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary916_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchSummary916_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary916_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchSummary916_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary937" which returns "rtcs_db.PH_rmatch_RMatchSummary937" */
  rtcs_db_Fnrmatch_RMatchSummary937: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary937>;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary937" and query aggregates on result of table type "rtcs_db.PH_rmatch_RMatchSummary937" */
  rtcs_db_Fnrmatch_RMatchSummary937_aggregate: Rtcs_Db_Ph_Rmatch_RMatchSummary937_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary937_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchSummary937_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary937_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_RMatchSummary937_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary937_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchSummary937_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_RMatchSummary937_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_RMatchSummary937_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_TinsURA" which returns "rtcs_db.PH_rmatch_TinsURA" */
  rtcs_db_Fnrmatch_TinsURA: Array<Rtcs_Db_Ph_Rmatch_TinsUra>;
  /** execute function "rtcs_db.Fnrmatch_TinsURA" and query aggregates on result of table type "rtcs_db.PH_rmatch_TinsURA" */
  rtcs_db_Fnrmatch_TinsURA_aggregate: Rtcs_Db_Ph_Rmatch_TinsUra_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_TinsURA_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_TinsURA_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_TinsURA_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_TinsURA_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_TinsURA_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_TinsURA_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_TinsURA_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_TinsURA_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Undeclared" which returns "rtcs_db.PH_rmatch_Undeclared" */
  rtcs_db_Fnrmatch_Undeclared: Array<Rtcs_Db_Ph_Rmatch_Undeclared>;
  /** execute function "rtcs_db.Fnrmatch_Undeclared" and query aggregates on result of table type "rtcs_db.PH_rmatch_Undeclared" */
  rtcs_db_Fnrmatch_Undeclared_aggregate: Rtcs_Db_Ph_Rmatch_Undeclared_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Undeclared_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_Undeclared_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_Undeclared_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_Undeclared_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Undeclared_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_Undeclared_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_Undeclared_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_Undeclared_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Underreported" which returns "rtcs_db.PH_rmatch_Underreported" */
  rtcs_db_Fnrmatch_Underreported: Array<Rtcs_Db_Ph_Rmatch_Underreported>;
  /** execute function "rtcs_db.Fnrmatch_Underreported" and query aggregates on result of table type "rtcs_db.PH_rmatch_Underreported" */
  rtcs_db_Fnrmatch_Underreported_aggregate: Rtcs_Db_Ph_Rmatch_Underreported_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Underreported_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_Underreported_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_Underreported_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_Underreported_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Underreported_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_Underreported_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_Underreported_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_Underreported_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Unregistered" which returns "rtcs_db.PH_rmatch_Unregistered" */
  rtcs_db_Fnrmatch_Unregistered: Array<Rtcs_Db_Ph_Rmatch_Unregistered>;
  /** execute function "rtcs_db.Fnrmatch_Unregistered" and query aggregates on result of table type "rtcs_db.PH_rmatch_Unregistered" */
  rtcs_db_Fnrmatch_Unregistered_aggregate: Rtcs_Db_Ph_Rmatch_Unregistered_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Unregistered_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_Unregistered_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_Unregistered_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_Unregistered_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_Unregistered_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_Unregistered_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_Unregistered_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_Unregistered_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_mlhudConsolidated" which returns "rtcs_db.PH_rmatch_mlhudConsolidated" */
  rtcs_db_Fnrmatch_mlhudConsolidated: Array<Rtcs_Db_Ph_Rmatch_MlhudConsolidated>;
  /** execute function "rtcs_db.Fnrmatch_mlhudConsolidated" and query aggregates on result of table type "rtcs_db.PH_rmatch_mlhudConsolidated" */
  rtcs_db_Fnrmatch_mlhudConsolidated_aggregate: Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_mlhudConsolidated_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_mlhudConsolidated_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnrmatch_mlhudConsolidated_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnrmatch_mlhudConsolidated_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnrmatch_mlhudConsolidated_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_mlhudConsolidated_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnrmatch_mlhudConsolidated_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnrmatch_mlhudConsolidated_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmMukonoMunicipality" which returns "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
  rtcs_db_Fnstreetmap_OsmMukonoMunicipality: Array<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality>;
  /** execute function "rtcs_db.Fnstreetmap_OsmMukonoMunicipality" and query aggregates on result of table type "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
  rtcs_db_Fnstreetmap_OsmMukonoMunicipality_aggregate: Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmMukonoMunicipality_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnstreetmap_OsmMukonoMunicipality_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnstreetmap_OsmMukonoMunicipality_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnstreetmap_OsmMukonoMunicipality_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmMukonoMunicipality_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnstreetmap_OsmMukonoMunicipality_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnstreetmap_OsmMukonoMunicipality_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnstreetmap_OsmMukonoMunicipality_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmMukonoNakifuma" which returns "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
  rtcs_db_Fnstreetmap_OsmMukonoNakifuma: Array<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma>;
  /** execute function "rtcs_db.Fnstreetmap_OsmMukonoNakifuma" and query aggregates on result of table type "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
  rtcs_db_Fnstreetmap_OsmMukonoNakifuma_aggregate: Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmMukonoNakifuma_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnstreetmap_OsmMukonoNakifuma_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnstreetmap_OsmMukonoNakifuma_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnstreetmap_OsmMukonoNakifuma_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmMukonoNakifuma_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnstreetmap_OsmMukonoNakifuma_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnstreetmap_OsmMukonoNakifuma_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnstreetmap_OsmMukonoNakifuma_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoEntebbe" which returns "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
  rtcs_db_Fnstreetmap_OsmWakisoEntebbe: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe>;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoEntebbe" and query aggregates on result of table type "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
  rtcs_db_Fnstreetmap_OsmWakisoEntebbe_aggregate: Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoEntebbe_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnstreetmap_OsmWakisoEntebbe_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoEntebbe_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnstreetmap_OsmWakisoEntebbe_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoEntebbe_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnstreetmap_OsmWakisoEntebbe_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoEntebbe_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnstreetmap_OsmWakisoEntebbe_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoKira" which returns "rtcs_db.PH_streetmap_OsmWakisoKira" */
  rtcs_db_Fnstreetmap_OsmWakisoKira: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoKira>;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoKira" and query aggregates on result of table type "rtcs_db.PH_streetmap_OsmWakisoKira" */
  rtcs_db_Fnstreetmap_OsmWakisoKira_aggregate: Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoKira_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnstreetmap_OsmWakisoKira_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoKira_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnstreetmap_OsmWakisoKira_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoKira_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnstreetmap_OsmWakisoKira_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoKira_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnstreetmap_OsmWakisoKira_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoMakindyeSsabagabo" which returns "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
  rtcs_db_Fnstreetmap_OsmWakisoMakindyeSsabagabo: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo>;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoMakindyeSsabagabo" and query aggregates on result of table type "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
  rtcs_db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_aggregate: Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoMakindyeSsabagabo_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoMakindyeSsabagabo_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoMakindyeSsabagabo_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoMakindyeSsabagabo_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoNansana" which returns "rtcs_db.PH_streetmap_OsmWakisoNansana" */
  rtcs_db_Fnstreetmap_OsmWakisoNansana: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana>;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoNansana" and query aggregates on result of table type "rtcs_db.PH_streetmap_OsmWakisoNansana" */
  rtcs_db_Fnstreetmap_OsmWakisoNansana_aggregate: Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoNansana_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnstreetmap_OsmWakisoNansana_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoNansana_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnstreetmap_OsmWakisoNansana_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoNansana_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnstreetmap_OsmWakisoNansana_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnstreetmap_OsmWakisoNansana_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnstreetmap_OsmWakisoNansana_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnucc_UCC" which returns "rtcs_db.PH_ucc_UCC" */
  rtcs_db_Fnucc_UCC: Array<Rtcs_Db_Ph_Ucc_Ucc>;
  /** execute function "rtcs_db.Fnucc_UCC" and query aggregates on result of table type "rtcs_db.PH_ucc_UCC" */
  rtcs_db_Fnucc_UCC_aggregate: Rtcs_Db_Ph_Ucc_Ucc_Aggregate;
  /** execute function "rtcs_db.Fnucc_UCC_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnucc_UCC_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnucc_UCC_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnucc_UCC_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnucc_UCC_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnucc_UCC_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnucc_UCC_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnucc_UCC_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnumeme_SubscriberAndProperty" which returns "rtcs_db.PH_umeme_SubscriberAndProperty" */
  rtcs_db_Fnumeme_SubscriberAndProperty: Array<Rtcs_Db_Ph_Umeme_SubscriberAndProperty>;
  /** execute function "rtcs_db.Fnumeme_SubscriberAndProperty" and query aggregates on result of table type "rtcs_db.PH_umeme_SubscriberAndProperty" */
  rtcs_db_Fnumeme_SubscriberAndProperty_aggregate: Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Aggregate;
  /** execute function "rtcs_db.Fnumeme_SubscriberAndProperty_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnumeme_SubscriberAndProperty_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnumeme_SubscriberAndProperty_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnumeme_SubscriberAndProperty_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnumeme_SubscriberAndProperty_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnumeme_SubscriberAndProperty_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnumeme_SubscriberAndProperty_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnumeme_SubscriberAndProperty_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnumeme_Transactions" which returns "rtcs_db.PH_umeme_Transactions" */
  rtcs_db_Fnumeme_Transactions: Array<Rtcs_Db_Ph_Umeme_Transactions>;
  /** execute function "rtcs_db.Fnumeme_Transactions" and query aggregates on result of table type "rtcs_db.PH_umeme_Transactions" */
  rtcs_db_Fnumeme_Transactions_aggregate: Rtcs_Db_Ph_Umeme_Transactions_Aggregate;
  /** execute function "rtcs_db.Fnumeme_Transactions_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnumeme_Transactions_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnumeme_Transactions_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnumeme_Transactions_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnumeme_Transactions_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnumeme_Transactions_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnumeme_Transactions_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnumeme_Transactions_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_BalanceSheetInformation" which returns "rtcs_db.PH_ura_BalanceSheetInformation" */
  rtcs_db_Fnura_BalanceSheetInformation: Array<Rtcs_Db_Ph_Ura_BalanceSheetInformation>;
  /** execute function "rtcs_db.Fnura_BalanceSheetInformation" and query aggregates on result of table type "rtcs_db.PH_ura_BalanceSheetInformation" */
  rtcs_db_Fnura_BalanceSheetInformation_aggregate: Rtcs_Db_Ph_Ura_BalanceSheetInformation_Aggregate;
  /** execute function "rtcs_db.Fnura_BalanceSheetInformation_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_BalanceSheetInformation_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_BalanceSheetInformation_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_BalanceSheetInformation_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_BalanceSheetInformation_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_BalanceSheetInformation_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_BalanceSheetInformation_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_BalanceSheetInformation_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_CombinedOfficersAndOwners" which returns "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
  rtcs_db_Fnura_CombinedOfficersAndOwners: Array<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners>;
  /** execute function "rtcs_db.Fnura_CombinedOfficersAndOwners" and query aggregates on result of table type "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
  rtcs_db_Fnura_CombinedOfficersAndOwners_aggregate: Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Aggregate;
  /** execute function "rtcs_db.Fnura_CombinedOfficersAndOwners_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_CombinedOfficersAndOwners_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_CombinedOfficersAndOwners_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_CombinedOfficersAndOwners_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_CombinedOfficersAndOwners_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_CombinedOfficersAndOwners_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_CombinedOfficersAndOwners_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_CombinedOfficersAndOwners_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_EmploymentIncomeDetail" which returns "rtcs_db.PH_ura_EmploymentIncomeDetail" */
  rtcs_db_Fnura_EmploymentIncomeDetail: Array<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail>;
  /** execute function "rtcs_db.Fnura_EmploymentIncomeDetail" and query aggregates on result of table type "rtcs_db.PH_ura_EmploymentIncomeDetail" */
  rtcs_db_Fnura_EmploymentIncomeDetail_aggregate: Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Aggregate;
  /** execute function "rtcs_db.Fnura_EmploymentIncomeDetail_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_EmploymentIncomeDetail_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_EmploymentIncomeDetail_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_EmploymentIncomeDetail_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_EmploymentIncomeDetail_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_EmploymentIncomeDetail_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_EmploymentIncomeDetail_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_EmploymentIncomeDetail_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_GazetteCommercialRatesPerSquareFootage" which returns "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
  rtcs_db_Fnura_GazetteCommercialRatesPerSquareFootage: Array<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage>;
  /** execute function "rtcs_db.Fnura_GazetteCommercialRatesPerSquareFootage" and query aggregates on result of table type "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
  rtcs_db_Fnura_GazetteCommercialRatesPerSquareFootage_aggregate: Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Aggregate;
  /** execute function "rtcs_db.Fnura_GazetteCommercialRatesPerSquareFootage_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_GazetteCommercialRatesPerSquareFootage_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_GazetteCommercialRatesPerSquareFootage_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_GazetteCommercialRatesPerSquareFootage_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_GazetteCommercialRatesPerSquareFootage_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_GazetteCommercialRatesPerSquareFootage_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_GazetteCommercialRatesPerSquareFootage_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_GazetteCommercialRatesPerSquareFootage_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_GazetteResidentialRatesPerBedroom" which returns "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
  rtcs_db_Fnura_GazetteResidentialRatesPerBedroom: Array<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom>;
  /** execute function "rtcs_db.Fnura_GazetteResidentialRatesPerBedroom" and query aggregates on result of table type "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
  rtcs_db_Fnura_GazetteResidentialRatesPerBedroom_aggregate: Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Aggregate;
  /** execute function "rtcs_db.Fnura_GazetteResidentialRatesPerBedroom_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_GazetteResidentialRatesPerBedroom_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_GazetteResidentialRatesPerBedroom_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_GazetteResidentialRatesPerBedroom_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_GazetteResidentialRatesPerBedroom_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_GazetteResidentialRatesPerBedroom_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_GazetteResidentialRatesPerBedroom_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_GazetteResidentialRatesPerBedroom_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_HoldingOfficers" which returns "rtcs_db.PH_ura_HoldingOfficers" */
  rtcs_db_Fnura_HoldingOfficers: Array<Rtcs_Db_Ph_Ura_HoldingOfficers>;
  /** execute function "rtcs_db.Fnura_HoldingOfficers" and query aggregates on result of table type "rtcs_db.PH_ura_HoldingOfficers" */
  rtcs_db_Fnura_HoldingOfficers_aggregate: Rtcs_Db_Ph_Ura_HoldingOfficers_Aggregate;
  /** execute function "rtcs_db.Fnura_HoldingOfficers_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_HoldingOfficers_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_HoldingOfficers_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_HoldingOfficers_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_HoldingOfficers_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_HoldingOfficers_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_HoldingOfficers_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_HoldingOfficers_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_HoldingOwner" which returns "rtcs_db.PH_ura_HoldingOwner" */
  rtcs_db_Fnura_HoldingOwner: Array<Rtcs_Db_Ph_Ura_HoldingOwner>;
  /** execute function "rtcs_db.Fnura_HoldingOwner" and query aggregates on result of table type "rtcs_db.PH_ura_HoldingOwner" */
  rtcs_db_Fnura_HoldingOwner_aggregate: Rtcs_Db_Ph_Ura_HoldingOwner_Aggregate;
  /** execute function "rtcs_db.Fnura_HoldingOwner_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_HoldingOwner_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_HoldingOwner_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_HoldingOwner_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_HoldingOwner_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_HoldingOwner_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_HoldingOwner_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_HoldingOwner_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_IncomeTaxHolding" which returns "rtcs_db.PH_ura_IncomeTaxHolding" */
  rtcs_db_Fnura_IncomeTaxHolding: Array<Rtcs_Db_Ph_Ura_IncomeTaxHolding>;
  /** execute function "rtcs_db.Fnura_IncomeTaxHolding" and query aggregates on result of table type "rtcs_db.PH_ura_IncomeTaxHolding" */
  rtcs_db_Fnura_IncomeTaxHolding_aggregate: Rtcs_Db_Ph_Ura_IncomeTaxHolding_Aggregate;
  /** execute function "rtcs_db.Fnura_IncomeTaxHolding_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_IncomeTaxHolding_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_IncomeTaxHolding_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_IncomeTaxHolding_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_IncomeTaxHolding_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_IncomeTaxHolding_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_IncomeTaxHolding_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_IncomeTaxHolding_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_IncomeTaxSummaryIndividual" which returns "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
  rtcs_db_Fnura_IncomeTaxSummaryIndividual: Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual>;
  /** execute function "rtcs_db.Fnura_IncomeTaxSummaryIndividual" and query aggregates on result of table type "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
  rtcs_db_Fnura_IncomeTaxSummaryIndividual_aggregate: Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Aggregate;
  /** execute function "rtcs_db.Fnura_IncomeTaxSummaryIndividual_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_IncomeTaxSummaryIndividual_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_IncomeTaxSummaryIndividual_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_IncomeTaxSummaryIndividual_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_IncomeTaxSummaryIndividual_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_IncomeTaxSummaryIndividual_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_IncomeTaxSummaryIndividual_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_IncomeTaxSummaryIndividual_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_IncomeTaxSummaryNonIndividual" which returns "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
  rtcs_db_Fnura_IncomeTaxSummaryNonIndividual: Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual>;
  /** execute function "rtcs_db.Fnura_IncomeTaxSummaryNonIndividual" and query aggregates on result of table type "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
  rtcs_db_Fnura_IncomeTaxSummaryNonIndividual_aggregate: Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Aggregate;
  /** execute function "rtcs_db.Fnura_IncomeTaxSummaryNonIndividual_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_IncomeTaxSummaryNonIndividual_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_IncomeTaxSummaryNonIndividual_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_IncomeTaxSummaryNonIndividual_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_IncomeTaxSummaryNonIndividual_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_IncomeTaxSummaryNonIndividual_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_IncomeTaxSummaryNonIndividual_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_IncomeTaxSummaryNonIndividual_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_IndividualMortgage" which returns "rtcs_db.PH_ura_IndividualMortgage" */
  rtcs_db_Fnura_IndividualMortgage: Array<Rtcs_Db_Ph_Ura_IndividualMortgage>;
  /** execute function "rtcs_db.Fnura_IndividualMortgage" and query aggregates on result of table type "rtcs_db.PH_ura_IndividualMortgage" */
  rtcs_db_Fnura_IndividualMortgage_aggregate: Rtcs_Db_Ph_Ura_IndividualMortgage_Aggregate;
  /** execute function "rtcs_db.Fnura_IndividualMortgage_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_IndividualMortgage_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_IndividualMortgage_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_IndividualMortgage_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_IndividualMortgage_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_IndividualMortgage_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_IndividualMortgage_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_IndividualMortgage_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_IndividualsAssociatedBusiness" which returns "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
  rtcs_db_Fnura_IndividualsAssociatedBusiness: Array<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness>;
  /** execute function "rtcs_db.Fnura_IndividualsAssociatedBusiness" and query aggregates on result of table type "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
  rtcs_db_Fnura_IndividualsAssociatedBusiness_aggregate: Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Aggregate;
  /** execute function "rtcs_db.Fnura_IndividualsAssociatedBusiness_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_IndividualsAssociatedBusiness_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_IndividualsAssociatedBusiness_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_IndividualsAssociatedBusiness_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_IndividualsAssociatedBusiness_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_IndividualsAssociatedBusiness_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_IndividualsAssociatedBusiness_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_IndividualsAssociatedBusiness_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_LandlordIncomeDetail" which returns "rtcs_db.PH_ura_LandlordIncomeDetail" */
  rtcs_db_Fnura_LandlordIncomeDetail: Array<Rtcs_Db_Ph_Ura_LandlordIncomeDetail>;
  /** execute function "rtcs_db.Fnura_LandlordIncomeDetail" and query aggregates on result of table type "rtcs_db.PH_ura_LandlordIncomeDetail" */
  rtcs_db_Fnura_LandlordIncomeDetail_aggregate: Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Aggregate;
  /** execute function "rtcs_db.Fnura_LandlordIncomeDetail_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_LandlordIncomeDetail_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_LandlordIncomeDetail_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_LandlordIncomeDetail_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_LandlordIncomeDetail_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_LandlordIncomeDetail_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_LandlordIncomeDetail_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_LandlordIncomeDetail_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_LandlordIncomeSummary" which returns "rtcs_db.PH_ura_LandlordIncomeSummary" */
  rtcs_db_Fnura_LandlordIncomeSummary: Array<Rtcs_Db_Ph_Ura_LandlordIncomeSummary>;
  /** execute function "rtcs_db.Fnura_LandlordIncomeSummary" and query aggregates on result of table type "rtcs_db.PH_ura_LandlordIncomeSummary" */
  rtcs_db_Fnura_LandlordIncomeSummary_aggregate: Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Aggregate;
  /** execute function "rtcs_db.Fnura_LandlordIncomeSummary_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_LandlordIncomeSummary_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_LandlordIncomeSummary_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_LandlordIncomeSummary_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_LandlordIncomeSummary_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_LandlordIncomeSummary_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_LandlordIncomeSummary_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_LandlordIncomeSummary_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_NonIndividualsAssociatedBusiness" which returns "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
  rtcs_db_Fnura_NonIndividualsAssociatedBusiness: Array<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness>;
  /** execute function "rtcs_db.Fnura_NonIndividualsAssociatedBusiness" and query aggregates on result of table type "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
  rtcs_db_Fnura_NonIndividualsAssociatedBusiness_aggregate: Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Aggregate;
  /** execute function "rtcs_db.Fnura_NonIndividualsAssociatedBusiness_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_NonIndividualsAssociatedBusiness_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_NonIndividualsAssociatedBusiness_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_NonIndividualsAssociatedBusiness_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_NonIndividualsAssociatedBusiness_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_NonIndividualsAssociatedBusiness_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_NonIndividualsAssociatedBusiness_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_NonIndividualsAssociatedBusiness_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_RegAssociates" which returns "rtcs_db.PH_ura_RegAssociates" */
  rtcs_db_Fnura_RegAssociates: Array<Rtcs_Db_Ph_Ura_RegAssociates>;
  /** execute function "rtcs_db.Fnura_RegAssociates" and query aggregates on result of table type "rtcs_db.PH_ura_RegAssociates" */
  rtcs_db_Fnura_RegAssociates_aggregate: Rtcs_Db_Ph_Ura_RegAssociates_Aggregate;
  /** execute function "rtcs_db.Fnura_RegAssociates_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RegAssociates_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_RegAssociates_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RegAssociates_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_RegAssociates_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RegAssociates_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_RegAssociates_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RegAssociates_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_RegIndividual" which returns "rtcs_db.PH_ura_RegIndividual" */
  rtcs_db_Fnura_RegIndividual: Array<Rtcs_Db_Ph_Ura_RegIndividual>;
  /** execute function "rtcs_db.Fnura_RegIndividual" and query aggregates on result of table type "rtcs_db.PH_ura_RegIndividual" */
  rtcs_db_Fnura_RegIndividual_aggregate: Rtcs_Db_Ph_Ura_RegIndividual_Aggregate;
  /** execute function "rtcs_db.Fnura_RegIndividual_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RegIndividual_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_RegIndividual_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RegIndividual_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_RegIndividual_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RegIndividual_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_RegIndividual_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RegIndividual_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_RegIndividual_sid" which returns "rtcs_db.PH_ura_RegIndividual_sid" */
  rtcs_db_Fnura_RegIndividual_sid: Array<Rtcs_Db_Ph_Ura_RegIndividual_Sid>;
  /** execute function "rtcs_db.Fnura_RegIndividual_sid" and query aggregates on result of table type "rtcs_db.PH_ura_RegIndividual_sid" */
  rtcs_db_Fnura_RegIndividual_sid_aggregate: Rtcs_Db_Ph_Ura_RegIndividual_Sid_Aggregate;
  /** execute function "rtcs_db.Fnura_RegIndividual_sid_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RegIndividual_sid_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_RegIndividual_sid_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RegIndividual_sid_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_RegIndividual_sid_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RegIndividual_sid_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_RegIndividual_sid_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RegIndividual_sid_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_RegNonIndividual" which returns "rtcs_db.PH_ura_RegNonIndividual" */
  rtcs_db_Fnura_RegNonIndividual: Array<Rtcs_Db_Ph_Ura_RegNonIndividual>;
  /** execute function "rtcs_db.Fnura_RegNonIndividual" and query aggregates on result of table type "rtcs_db.PH_ura_RegNonIndividual" */
  rtcs_db_Fnura_RegNonIndividual_aggregate: Rtcs_Db_Ph_Ura_RegNonIndividual_Aggregate;
  /** execute function "rtcs_db.Fnura_RegNonIndividual_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RegNonIndividual_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_RegNonIndividual_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RegNonIndividual_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_RegNonIndividual_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RegNonIndividual_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_RegNonIndividual_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RegNonIndividual_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_RegNonIndividual_sid" which returns "rtcs_db.PH_ura_RegNonIndividual_sid" */
  rtcs_db_Fnura_RegNonIndividual_sid: Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid>;
  /** execute function "rtcs_db.Fnura_RegNonIndividual_sid" and query aggregates on result of table type "rtcs_db.PH_ura_RegNonIndividual_sid" */
  rtcs_db_Fnura_RegNonIndividual_sid_aggregate: Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Aggregate;
  /** execute function "rtcs_db.Fnura_RegNonIndividual_sid_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RegNonIndividual_sid_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_RegNonIndividual_sid_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RegNonIndividual_sid_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_RegNonIndividual_sid_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RegNonIndividual_sid_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_RegNonIndividual_sid_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RegNonIndividual_sid_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_RentExpenseDetails" which returns "rtcs_db.PH_ura_RentExpenseDetails" */
  rtcs_db_Fnura_RentExpenseDetails: Array<Rtcs_Db_Ph_Ura_RentExpenseDetails>;
  /** execute function "rtcs_db.Fnura_RentExpenseDetails" and query aggregates on result of table type "rtcs_db.PH_ura_RentExpenseDetails" */
  rtcs_db_Fnura_RentExpenseDetails_aggregate: Rtcs_Db_Ph_Ura_RentExpenseDetails_Aggregate;
  /** execute function "rtcs_db.Fnura_RentExpenseDetails_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RentExpenseDetails_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_RentExpenseDetails_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RentExpenseDetails_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_RentExpenseDetails_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RentExpenseDetails_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_RentExpenseDetails_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RentExpenseDetails_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_RentalLandLordIncome" which returns "rtcs_db.PH_ura_RentalLandLordIncome" */
  rtcs_db_Fnura_RentalLandLordIncome: Array<Rtcs_Db_Ph_Ura_RentalLandLordIncome>;
  /** execute function "rtcs_db.Fnura_RentalLandLordIncome" and query aggregates on result of table type "rtcs_db.PH_ura_RentalLandLordIncome" */
  rtcs_db_Fnura_RentalLandLordIncome_aggregate: Rtcs_Db_Ph_Ura_RentalLandLordIncome_Aggregate;
  /** execute function "rtcs_db.Fnura_RentalLandLordIncome_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RentalLandLordIncome_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_RentalLandLordIncome_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RentalLandLordIncome_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_RentalLandLordIncome_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RentalLandLordIncome_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_RentalLandLordIncome_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RentalLandLordIncome_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_RentalTenantPayments" which returns "rtcs_db.PH_ura_RentalTenantPayments" */
  rtcs_db_Fnura_RentalTenantPayments: Array<Rtcs_Db_Ph_Ura_RentalTenantPayments>;
  /** execute function "rtcs_db.Fnura_RentalTenantPayments" and query aggregates on result of table type "rtcs_db.PH_ura_RentalTenantPayments" */
  rtcs_db_Fnura_RentalTenantPayments_aggregate: Rtcs_Db_Ph_Ura_RentalTenantPayments_Aggregate;
  /** execute function "rtcs_db.Fnura_RentalTenantPayments_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RentalTenantPayments_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_RentalTenantPayments_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RentalTenantPayments_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_RentalTenantPayments_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RentalTenantPayments_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_RentalTenantPayments_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RentalTenantPayments_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_RipoIndRentalIncSummary" which returns "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
  rtcs_db_Fnura_RipoIndRentalIncSummary: Array<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary>;
  /** execute function "rtcs_db.Fnura_RipoIndRentalIncSummary" and query aggregates on result of table type "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
  rtcs_db_Fnura_RipoIndRentalIncSummary_aggregate: Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Aggregate;
  /** execute function "rtcs_db.Fnura_RipoIndRentalIncSummary_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RipoIndRentalIncSummary_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_RipoIndRentalIncSummary_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_RipoIndRentalIncSummary_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_RipoIndRentalIncSummary_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RipoIndRentalIncSummary_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_RipoIndRentalIncSummary_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_RipoIndRentalIncSummary_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_TenantIncomeDetail" which returns "rtcs_db.PH_ura_TenantIncomeDetail" */
  rtcs_db_Fnura_TenantIncomeDetail: Array<Rtcs_Db_Ph_Ura_TenantIncomeDetail>;
  /** execute function "rtcs_db.Fnura_TenantIncomeDetail" and query aggregates on result of table type "rtcs_db.PH_ura_TenantIncomeDetail" */
  rtcs_db_Fnura_TenantIncomeDetail_aggregate: Rtcs_Db_Ph_Ura_TenantIncomeDetail_Aggregate;
  /** execute function "rtcs_db.Fnura_TenantIncomeDetail_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_TenantIncomeDetail_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_TenantIncomeDetail_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_TenantIncomeDetail_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_TenantIncomeDetail_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_TenantIncomeDetail_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_TenantIncomeDetail_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_TenantIncomeDetail_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnura_Town" which returns "rtcs_db.PH_ura_Town" */
  rtcs_db_Fnura_Town: Array<Rtcs_Db_Ph_Ura_Town>;
  /** execute function "rtcs_db.Fnura_Town" and query aggregates on result of table type "rtcs_db.PH_ura_Town" */
  rtcs_db_Fnura_Town_aggregate: Rtcs_Db_Ph_Ura_Town_Aggregate;
  /** execute function "rtcs_db.Fnura_Town_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_Town_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnura_Town_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnura_Town_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnura_Town_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_Town_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnura_Town_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnura_Town_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** execute function "rtcs_db.Fnursb_CompaniesAndBusinessNamesURSB" which returns "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
  rtcs_db_Fnursb_CompaniesAndBusinessNamesURSB: Array<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb>;
  /** execute function "rtcs_db.Fnursb_CompaniesAndBusinessNamesURSB" and query aggregates on result of table type "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
  rtcs_db_Fnursb_CompaniesAndBusinessNamesURSB_aggregate: Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Aggregate;
  /** execute function "rtcs_db.Fnursb_CompaniesAndBusinessNamesURSB_aggregatecm" which returns "rtcs_db.aggregate_result" */
  rtcs_db_Fnursb_CompaniesAndBusinessNamesURSB_aggregatecm: Array<Rtcs_Db_Aggregate_Result>;
  /** execute function "rtcs_db.Fnursb_CompaniesAndBusinessNamesURSB_aggregatecm" and query aggregates on result of table type "rtcs_db.aggregate_result" */
  rtcs_db_Fnursb_CompaniesAndBusinessNamesURSB_aggregatecm_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** execute function "rtcs_db.Fnursb_CompaniesAndBusinessNamesURSB_aggregatetxt" which returns "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnursb_CompaniesAndBusinessNamesURSB_aggregatetxt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** execute function "rtcs_db.Fnursb_CompaniesAndBusinessNamesURSB_aggregatetxt" and query aggregates on result of table type "rtcs_db.aggregate_result_txt" */
  rtcs_db_Fnursb_CompaniesAndBusinessNamesURSB_aggregatetxt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** fetch data from the table: "rtcs_db.GazzetteRates" */
  rtcs_db_GazzetteRates: Array<Rtcs_Db_GazzetteRates>;
  /** fetch aggregated fields from the table: "rtcs_db.GazzetteRates" */
  rtcs_db_GazzetteRates_aggregate: Rtcs_Db_GazzetteRates_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_EntityFilter" */
  rtcs_db_PH_EntityFilter: Array<Rtcs_Db_Ph_EntityFilter>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_EntityFilter" */
  rtcs_db_PH_EntityFilter_aggregate: Rtcs_Db_Ph_EntityFilter_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_Filter" */
  rtcs_db_PH_Filter: Array<Rtcs_Db_Ph_Filter>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_Filter" */
  rtcs_db_PH_Filter_aggregate: Rtcs_Db_Ph_Filter_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_GazzetteRates" */
  rtcs_db_PH_GazzetteRates: Array<Rtcs_Db_Ph_GazzetteRates>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_GazzetteRates" */
  rtcs_db_PH_GazzetteRates_aggregate: Rtcs_Db_Ph_GazzetteRates_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
  rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk: Array<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_IndividualComplianceSummaryPlusRisk" */
  rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk_aggregate: Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_IndividualComposite" */
  rtcs_db_PH_RPT_IndividualComposite: Array<Rtcs_Db_Ph_Rpt_IndividualComposite>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_IndividualComposite" */
  rtcs_db_PH_RPT_IndividualComposite_aggregate: Rtcs_Db_Ph_Rpt_IndividualComposite_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_IndividualRegistration" */
  rtcs_db_PH_RPT_IndividualRegistration: Array<Rtcs_Db_Ph_Rpt_IndividualRegistration>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_IndividualRegistration" */
  rtcs_db_PH_RPT_IndividualRegistration_aggregate: Rtcs_Db_Ph_Rpt_IndividualRegistration_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
  rtcs_db_PH_RPT_IndividualTaxSummaryData: Array<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_IndividualTaxSummaryData" */
  rtcs_db_PH_RPT_IndividualTaxSummaryData_aggregate: Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_KccaAffiliates" */
  rtcs_db_PH_RPT_KccaAffiliates: Array<Rtcs_Db_Ph_Rpt_KccaAffiliates>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_KccaAffiliates" */
  rtcs_db_PH_RPT_KccaAffiliates_aggregate: Rtcs_Db_Ph_Rpt_KccaAffiliates_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
  rtcs_db_PH_RPT_KccaCustomerRegistrationData: Array<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_KccaCustomerRegistrationData" */
  rtcs_db_PH_RPT_KccaCustomerRegistrationData_aggregate: Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_KccaPropertyRegistration" */
  rtcs_db_PH_RPT_KccaPropertyRegistration: Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration>;
  /** fetch data from the table: "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
  rtcs_db_PH_RPT_KccaPropertyRegistrationData: Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_KccaPropertyRegistrationData" */
  rtcs_db_PH_RPT_KccaPropertyRegistrationData_aggregate: Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_KccaPropertyRegistration" */
  rtcs_db_PH_RPT_KccaPropertyRegistration_aggregate: Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
  rtcs_db_PH_RPT_NonIndividualBalanceSheet: Array<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_NonIndividualBalanceSheet" */
  rtcs_db_PH_RPT_NonIndividualBalanceSheet_aggregate: Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
  rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk: Array<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_NonIndividualComplianceSummaryPlusRisk" */
  rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk_aggregate: Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_NonIndividualComposite" */
  rtcs_db_PH_RPT_NonIndividualComposite: Array<Rtcs_Db_Ph_Rpt_NonIndividualComposite>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_NonIndividualComposite" */
  rtcs_db_PH_RPT_NonIndividualComposite_aggregate: Rtcs_Db_Ph_Rpt_NonIndividualComposite_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
  rtcs_db_PH_RPT_NonIndividualTaxSummaryData: Array<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_NonIndividualTaxSummaryData" */
  rtcs_db_PH_RPT_NonIndividualTaxSummaryData_aggregate: Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_PropertiesComposite" */
  rtcs_db_PH_RPT_PropertiesComposite: Array<Rtcs_Db_Ph_Rpt_PropertiesComposite>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_PropertiesComposite" */
  rtcs_db_PH_RPT_PropertiesComposite_aggregate: Rtcs_Db_Ph_Rpt_PropertiesComposite_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_PropertyValuation" */
  rtcs_db_PH_RPT_PropertyValuation: Array<Rtcs_Db_Ph_Rpt_PropertyValuation>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_PropertyValuation" */
  rtcs_db_PH_RPT_PropertyValuation_aggregate: Rtcs_Db_Ph_Rpt_PropertyValuation_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_RPT_RentalTenantPayments" */
  rtcs_db_PH_RPT_RentalTenantPayments: Array<Rtcs_Db_Ph_Rpt_RentalTenantPayments>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_RPT_RentalTenantPayments" */
  rtcs_db_PH_RPT_RentalTenantPayments_aggregate: Rtcs_Db_Ph_Rpt_RentalTenantPayments_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_gazette_CommercialRates" */
  rtcs_db_PH_gazette_CommercialRates: Array<Rtcs_Db_Ph_Gazette_CommercialRates>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_gazette_CommercialRates" */
  rtcs_db_PH_gazette_CommercialRates_aggregate: Rtcs_Db_Ph_Gazette_CommercialRates_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_gazette_ResidentialRates" */
  rtcs_db_PH_gazette_ResidentialRates: Array<Rtcs_Db_Ph_Gazette_ResidentialRates>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_gazette_ResidentialRates" */
  rtcs_db_PH_gazette_ResidentialRates_aggregate: Rtcs_Db_Ph_Gazette_ResidentialRates_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_AssociateType" */
  rtcs_db_PH_kcca_AssociateType: Array<Rtcs_Db_Ph_Kcca_AssociateType>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_AssociateType" */
  rtcs_db_PH_kcca_AssociateType_aggregate: Rtcs_Db_Ph_Kcca_AssociateType_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_CommercialProperties" */
  rtcs_db_PH_kcca_CommercialProperties: Array<Rtcs_Db_Ph_Kcca_CommercialProperties>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_CommercialProperties" */
  rtcs_db_PH_kcca_CommercialProperties_aggregate: Rtcs_Db_Ph_Kcca_CommercialProperties_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_County" */
  rtcs_db_PH_kcca_County: Array<Rtcs_Db_Ph_Kcca_County>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_County" */
  rtcs_db_PH_kcca_County_aggregate: Rtcs_Db_Ph_Kcca_County_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_Customer" */
  rtcs_db_PH_kcca_Customer: Array<Rtcs_Db_Ph_Kcca_Customer>;
  /** fetch data from the table: "rtcs_db.PH_kcca_CustomerApplicantType" */
  rtcs_db_PH_kcca_CustomerApplicantType: Array<Rtcs_Db_Ph_Kcca_CustomerApplicantType>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_CustomerApplicantType" */
  rtcs_db_PH_kcca_CustomerApplicantType_aggregate: Rtcs_Db_Ph_Kcca_CustomerApplicantType_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
  rtcs_db_PH_kcca_CustomerBusinessAssociate: Array<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_CustomerBusinessAssociate" */
  rtcs_db_PH_kcca_CustomerBusinessAssociate_aggregate: Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_CustomerBusinessType" */
  rtcs_db_PH_kcca_CustomerBusinessType: Array<Rtcs_Db_Ph_Kcca_CustomerBusinessType>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_CustomerBusinessType" */
  rtcs_db_PH_kcca_CustomerBusinessType_aggregate: Rtcs_Db_Ph_Kcca_CustomerBusinessType_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
  rtcs_db_PH_kcca_CustomerCoinDeactivateReactive: Array<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_CustomerCoinDeactivateReactive" */
  rtcs_db_PH_kcca_CustomerCoinDeactivateReactive_aggregate: Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_CustomerContactPerson" */
  rtcs_db_PH_kcca_CustomerContactPerson: Array<Rtcs_Db_Ph_Kcca_CustomerContactPerson>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_CustomerContactPerson" */
  rtcs_db_PH_kcca_CustomerContactPerson_aggregate: Rtcs_Db_Ph_Kcca_CustomerContactPerson_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_CustomerDocumentItem" */
  rtcs_db_PH_kcca_CustomerDocumentItem: Array<Rtcs_Db_Ph_Kcca_CustomerDocumentItem>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_CustomerDocumentItem" */
  rtcs_db_PH_kcca_CustomerDocumentItem_aggregate: Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_CustomerGuardian" */
  rtcs_db_PH_kcca_CustomerGuardian: Array<Rtcs_Db_Ph_Kcca_CustomerGuardian>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_CustomerGuardian" */
  rtcs_db_PH_kcca_CustomerGuardian_aggregate: Rtcs_Db_Ph_Kcca_CustomerGuardian_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_CustomerReference" */
  rtcs_db_PH_kcca_CustomerReference: Array<Rtcs_Db_Ph_Kcca_CustomerReference>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_CustomerReference" */
  rtcs_db_PH_kcca_CustomerReference_aggregate: Rtcs_Db_Ph_Kcca_CustomerReference_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_CustomerRegistrationReason" */
  rtcs_db_PH_kcca_CustomerRegistrationReason: Array<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_CustomerRegistrationReason" */
  rtcs_db_PH_kcca_CustomerRegistrationReason_aggregate: Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_CustomerRevenueAgent" */
  rtcs_db_PH_kcca_CustomerRevenueAgent: Array<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_CustomerRevenueAgent" */
  rtcs_db_PH_kcca_CustomerRevenueAgent_aggregate: Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_CustomerType" */
  rtcs_db_PH_kcca_CustomerType: Array<Rtcs_Db_Ph_Kcca_CustomerType>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_CustomerType" */
  rtcs_db_PH_kcca_CustomerType_aggregate: Rtcs_Db_Ph_Kcca_CustomerType_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_Customer" */
  rtcs_db_PH_kcca_Customer_aggregate: Rtcs_Db_Ph_Kcca_Customer_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_Customer_sid" */
  rtcs_db_PH_kcca_Customer_sid: Array<Rtcs_Db_Ph_Kcca_Customer_Sid>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_Customer_sid" */
  rtcs_db_PH_kcca_Customer_sid_aggregate: Rtcs_Db_Ph_Kcca_Customer_Sid_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_District" */
  rtcs_db_PH_kcca_District: Array<Rtcs_Db_Ph_Kcca_District>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_District" */
  rtcs_db_PH_kcca_District_aggregate: Rtcs_Db_Ph_Kcca_District_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_Division" */
  rtcs_db_PH_kcca_Division: Array<Rtcs_Db_Ph_Kcca_Division>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_Division" */
  rtcs_db_PH_kcca_Division_aggregate: Rtcs_Db_Ph_Kcca_Division_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_ImgsData" */
  rtcs_db_PH_kcca_ImgsData: Array<Rtcs_Db_Ph_Kcca_ImgsData>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_ImgsData" */
  rtcs_db_PH_kcca_ImgsData_aggregate: Rtcs_Db_Ph_Kcca_ImgsData_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_Parish" */
  rtcs_db_PH_kcca_Parish: Array<Rtcs_Db_Ph_Kcca_Parish>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_Parish" */
  rtcs_db_PH_kcca_Parish_aggregate: Rtcs_Db_Ph_Kcca_Parish_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_PaymentDetails" */
  rtcs_db_PH_kcca_PaymentDetails: Array<Rtcs_Db_Ph_Kcca_PaymentDetails>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_PaymentDetails" */
  rtcs_db_PH_kcca_PaymentDetails_aggregate: Rtcs_Db_Ph_Kcca_PaymentDetails_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_Payments" */
  rtcs_db_PH_kcca_Payments: Array<Rtcs_Db_Ph_Kcca_Payments>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_Payments" */
  rtcs_db_PH_kcca_Payments_aggregate: Rtcs_Db_Ph_Kcca_Payments_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_Property" */
  rtcs_db_PH_kcca_Property: Array<Rtcs_Db_Ph_Kcca_Property>;
  /** fetch data from the table: "rtcs_db.PH_kcca_PropertyFloors" */
  rtcs_db_PH_kcca_PropertyFloors: Array<Rtcs_Db_Ph_Kcca_PropertyFloors>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_PropertyFloors" */
  rtcs_db_PH_kcca_PropertyFloors_aggregate: Rtcs_Db_Ph_Kcca_PropertyFloors_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
  rtcs_db_PH_kcca_PropertyNumberBedrooms: Array<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_PropertyNumberBedrooms" */
  rtcs_db_PH_kcca_PropertyNumberBedrooms_aggregate: Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_PropertyRentedStatus" */
  rtcs_db_PH_kcca_PropertyRentedStatus: Array<Rtcs_Db_Ph_Kcca_PropertyRentedStatus>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_PropertyRentedStatus" */
  rtcs_db_PH_kcca_PropertyRentedStatus_aggregate: Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_PropertySecurity" */
  rtcs_db_PH_kcca_PropertySecurity: Array<Rtcs_Db_Ph_Kcca_PropertySecurity>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_PropertySecurity" */
  rtcs_db_PH_kcca_PropertySecurity_aggregate: Rtcs_Db_Ph_Kcca_PropertySecurity_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_PropertySubType" */
  rtcs_db_PH_kcca_PropertySubType: Array<Rtcs_Db_Ph_Kcca_PropertySubType>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_PropertySubType" */
  rtcs_db_PH_kcca_PropertySubType_aggregate: Rtcs_Db_Ph_Kcca_PropertySubType_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_PropertyType" */
  rtcs_db_PH_kcca_PropertyType: Array<Rtcs_Db_Ph_Kcca_PropertyType>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_PropertyType" */
  rtcs_db_PH_kcca_PropertyType_aggregate: Rtcs_Db_Ph_Kcca_PropertyType_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_Property" */
  rtcs_db_PH_kcca_Property_aggregate: Rtcs_Db_Ph_Kcca_Property_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_RawProperties1" */
  rtcs_db_PH_kcca_RawProperties1: Array<Rtcs_Db_Ph_Kcca_RawProperties1>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_RawProperties1" */
  rtcs_db_PH_kcca_RawProperties1_aggregate: Rtcs_Db_Ph_Kcca_RawProperties1_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_RawProperties2" */
  rtcs_db_PH_kcca_RawProperties2: Array<Rtcs_Db_Ph_Kcca_RawProperties2>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_RawProperties2" */
  rtcs_db_PH_kcca_RawProperties2_aggregate: Rtcs_Db_Ph_Kcca_RawProperties2_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_ResidentialProperties" */
  rtcs_db_PH_kcca_ResidentialProperties: Array<Rtcs_Db_Ph_Kcca_ResidentialProperties>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_ResidentialProperties" */
  rtcs_db_PH_kcca_ResidentialProperties_aggregate: Rtcs_Db_Ph_Kcca_ResidentialProperties_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_RevenueType" */
  rtcs_db_PH_kcca_RevenueType: Array<Rtcs_Db_Ph_Kcca_RevenueType>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_RevenueType" */
  rtcs_db_PH_kcca_RevenueType_aggregate: Rtcs_Db_Ph_Kcca_RevenueType_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_SubCounty" */
  rtcs_db_PH_kcca_SubCounty: Array<Rtcs_Db_Ph_Kcca_SubCounty>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_SubCounty" */
  rtcs_db_PH_kcca_SubCounty_aggregate: Rtcs_Db_Ph_Kcca_SubCounty_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
  rtcs_db_PH_kcca_SubpropertyTypeConversion: Array<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_SubpropertyTypeConversion" */
  rtcs_db_PH_kcca_SubpropertyTypeConversion_aggregate: Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_TradingLicense" */
  rtcs_db_PH_kcca_TradingLicense: Array<Rtcs_Db_Ph_Kcca_TradingLicense>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_TradingLicense" */
  rtcs_db_PH_kcca_TradingLicense_aggregate: Rtcs_Db_Ph_Kcca_TradingLicense_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_kcca_Village" */
  rtcs_db_PH_kcca_Village: Array<Rtcs_Db_Ph_Kcca_Village>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_kcca_Village" */
  rtcs_db_PH_kcca_Village_aggregate: Rtcs_Db_Ph_Kcca_Village_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_master_MasterIndiv" */
  rtcs_db_PH_master_MasterIndiv: Array<Rtcs_Db_Ph_Master_MasterIndiv>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_master_MasterIndiv" */
  rtcs_db_PH_master_MasterIndiv_aggregate: Rtcs_Db_Ph_Master_MasterIndiv_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_mlhud_KCCA" */
  rtcs_db_PH_mlhud_KCCA: Array<Rtcs_Db_Ph_Mlhud_Kcca>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_mlhud_KCCA" */
  rtcs_db_PH_mlhud_KCCA_aggregate: Rtcs_Db_Ph_Mlhud_Kcca_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_mlhud_Mukono" */
  rtcs_db_PH_mlhud_Mukono: Array<Rtcs_Db_Ph_Mlhud_Mukono>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_mlhud_Mukono" */
  rtcs_db_PH_mlhud_Mukono_aggregate: Rtcs_Db_Ph_Mlhud_Mukono_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_mlhud_Properties" */
  rtcs_db_PH_mlhud_Properties: Array<Rtcs_Db_Ph_Mlhud_Properties>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_mlhud_Properties" */
  rtcs_db_PH_mlhud_Properties_aggregate: Rtcs_Db_Ph_Mlhud_Properties_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_mlhud_WakisoBusiro" */
  rtcs_db_PH_mlhud_WakisoBusiro: Array<Rtcs_Db_Ph_Mlhud_WakisoBusiro>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_mlhud_WakisoBusiro" */
  rtcs_db_PH_mlhud_WakisoBusiro_aggregate: Rtcs_Db_Ph_Mlhud_WakisoBusiro_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_mlhud_WakisoKyadondo" */
  rtcs_db_PH_mlhud_WakisoKyadondo: Array<Rtcs_Db_Ph_Mlhud_WakisoKyadondo>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_mlhud_WakisoKyadondo" */
  rtcs_db_PH_mlhud_WakisoKyadondo_aggregate: Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_nira_Nira" */
  rtcs_db_PH_nira_Nira: Array<Rtcs_Db_Ph_Nira_Nira>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_nira_Nira" */
  rtcs_db_PH_nira_Nira_aggregate: Rtcs_Db_Ph_Nira_Nira_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_nira_RawData" */
  rtcs_db_PH_nira_RawData: Array<Rtcs_Db_Ph_Nira_RawData>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_nira_RawData" */
  rtcs_db_PH_nira_RawData_aggregate: Rtcs_Db_Ph_Nira_RawData_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_nwsc_Customers" */
  rtcs_db_PH_nwsc_Customers: Array<Rtcs_Db_Ph_Nwsc_Customers>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_nwsc_Customers" */
  rtcs_db_PH_nwsc_Customers_aggregate: Rtcs_Db_Ph_Nwsc_Customers_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_nwsc_NWCustomers" */
  rtcs_db_PH_nwsc_NWCustomers: Array<Rtcs_Db_Ph_Nwsc_NwCustomers>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_nwsc_NWCustomers" */
  rtcs_db_PH_nwsc_NWCustomers_aggregate: Rtcs_Db_Ph_Nwsc_NwCustomers_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_nwsc_RPT_Transactions" */
  rtcs_db_PH_nwsc_RPT_Transactions: Array<Rtcs_Db_Ph_Nwsc_Rpt_Transactions>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_nwsc_RPT_Transactions" */
  rtcs_db_PH_nwsc_RPT_Transactions_aggregate: Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_nwsc_Transactions" */
  rtcs_db_PH_nwsc_Transactions: Array<Rtcs_Db_Ph_Nwsc_Transactions>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_nwsc_Transactions" */
  rtcs_db_PH_nwsc_Transactions_aggregate: Rtcs_Db_Ph_Nwsc_Transactions_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_nwsc_URATransactions" */
  rtcs_db_PH_nwsc_URATransactions: Array<Rtcs_Db_Ph_Nwsc_UraTransactions>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_nwsc_URATransactions" */
  rtcs_db_PH_nwsc_URATransactions_aggregate: Rtcs_Db_Ph_Nwsc_UraTransactions_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rcapture_RPTUnittypes" */
  rtcs_db_PH_rcapture_RPTUnittypes: Array<Rtcs_Db_Ph_Rcapture_RptUnittypes>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rcapture_RPTUnittypes" */
  rtcs_db_PH_rcapture_RPTUnittypes_aggregate: Rtcs_Db_Ph_Rcapture_RptUnittypes_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rcapture_RPTlessors" */
  rtcs_db_PH_rcapture_RPTlessors: Array<Rtcs_Db_Ph_Rcapture_RpTlessors>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rcapture_RPTlessors" */
  rtcs_db_PH_rcapture_RPTlessors_aggregate: Rtcs_Db_Ph_Rcapture_RpTlessors_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rcapture_RPTmeta" */
  rtcs_db_PH_rcapture_RPTmeta: Array<Rtcs_Db_Ph_Rcapture_RpTmeta>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rcapture_RPTmeta" */
  rtcs_db_PH_rcapture_RPTmeta_aggregate: Rtcs_Db_Ph_Rcapture_RpTmeta_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rcapture_RPTproperties" */
  rtcs_db_PH_rcapture_RPTproperties: Array<Rtcs_Db_Ph_Rcapture_RpTproperties>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rcapture_RPTproperties" */
  rtcs_db_PH_rcapture_RPTproperties_aggregate: Rtcs_Db_Ph_Rcapture_RpTproperties_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rcapture_RPTtenants" */
  rtcs_db_PH_rcapture_RPTtenants: Array<Rtcs_Db_Ph_Rcapture_RpTtenants>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rcapture_RPTtenants" */
  rtcs_db_PH_rcapture_RPTtenants_aggregate: Rtcs_Db_Ph_Rcapture_RpTtenants_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_Individual" */
  rtcs_db_PH_rmatch_Individual: Array<Rtcs_Db_Ph_Rmatch_Individual>;
  /** fetch data from the table: "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
  rtcs_db_PH_rmatch_IndividualComplianceDetailReport: Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_IndividualComplianceDetailReport" */
  rtcs_db_PH_rmatch_IndividualComplianceDetailReport_aggregate: Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
  rtcs_db_PH_rmatch_IndividualComplianceSummaryReport: Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_IndividualComplianceSummaryReport" */
  rtcs_db_PH_rmatch_IndividualComplianceSummaryReport_aggregate: Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_IndividualRiskScore" */
  rtcs_db_PH_rmatch_IndividualRiskScore: Array<Rtcs_Db_Ph_Rmatch_IndividualRiskScore>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_IndividualRiskScore" */
  rtcs_db_PH_rmatch_IndividualRiskScore_aggregate: Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_Individual" */
  rtcs_db_PH_rmatch_Individual_aggregate: Rtcs_Db_Ph_Rmatch_Individual_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_IndividualsNetwork" */
  rtcs_db_PH_rmatch_IndividualsNetwork: Array<Rtcs_Db_Ph_Rmatch_IndividualsNetwork>;
  /** fetch data from the table: "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
  rtcs_db_PH_rmatch_IndividualsNetworkTesting: Array<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_IndividualsNetworkTesting" */
  rtcs_db_PH_rmatch_IndividualsNetworkTesting_aggregate: Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_IndividualsNetwork" */
  rtcs_db_PH_rmatch_IndividualsNetwork_aggregate: Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
  rtcs_db_PH_rmatch_KccaToKccaIndividualOwners: Array<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_KccaToKccaIndividualOwners" */
  rtcs_db_PH_rmatch_KccaToKccaIndividualOwners_aggregate: Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
  rtcs_db_PH_rmatch_KccaToKccaNonindividualOwners: Array<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_KccaToKccaNonindividualOwners" */
  rtcs_db_PH_rmatch_KccaToKccaNonindividualOwners_aggregate: Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
  rtcs_db_PH_rmatch_MatchIndividualsProperties: Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_MatchIndividualsProperties" */
  rtcs_db_PH_rmatch_MatchIndividualsProperties_aggregate: Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_MatchIndividualsProps" */
  rtcs_db_PH_rmatch_MatchIndividualsProps: Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_MatchIndividualsProps" */
  rtcs_db_PH_rmatch_MatchIndividualsProps_aggregate: Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_NoReturns" */
  rtcs_db_PH_rmatch_NoReturns: Array<Rtcs_Db_Ph_Rmatch_NoReturns>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_NoReturns" */
  rtcs_db_PH_rmatch_NoReturns_aggregate: Rtcs_Db_Ph_Rmatch_NoReturns_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_NonIndividual" */
  rtcs_db_PH_rmatch_NonIndividual: Array<Rtcs_Db_Ph_Rmatch_NonIndividual>;
  /** fetch data from the table: "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
  rtcs_db_PH_rmatch_NonIndividualComplianceDetailReport: Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_NonIndividualComplianceDetailReport" */
  rtcs_db_PH_rmatch_NonIndividualComplianceDetailReport_aggregate: Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
  rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport: Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_NonIndividualComplianceSummaryReport" */
  rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport_aggregate: Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
  rtcs_db_PH_rmatch_NonIndividualRiskScore: Array<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_NonIndividualRiskScore" */
  rtcs_db_PH_rmatch_NonIndividualRiskScore_aggregate: Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_NonIndividual" */
  rtcs_db_PH_rmatch_NonIndividual_aggregate: Rtcs_Db_Ph_Rmatch_NonIndividual_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_NonIndividuals" */
  rtcs_db_PH_rmatch_NonIndividuals: Array<Rtcs_Db_Ph_Rmatch_NonIndividuals>;
  /** fetch data from the table: "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
  rtcs_db_PH_rmatch_NonIndividualsNetwork: Array<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_NonIndividualsNetwork" */
  rtcs_db_PH_rmatch_NonIndividualsNetwork_aggregate: Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_NonIndividuals" */
  rtcs_db_PH_rmatch_NonIndividuals_aggregate: Rtcs_Db_Ph_Rmatch_NonIndividuals_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_NonindividualOwners" */
  rtcs_db_PH_rmatch_NonindividualOwners: Array<Rtcs_Db_Ph_Rmatch_NonindividualOwners>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_NonindividualOwners" */
  rtcs_db_PH_rmatch_NonindividualOwners_aggregate: Rtcs_Db_Ph_Rmatch_NonindividualOwners_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_Nonindividuals" */
  rtcs_db_PH_rmatch_Nonindividuals: Array<Rtcs_Db_Ph_Rmatch_Nonindividuals>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_Nonindividuals" */
  rtcs_db_PH_rmatch_Nonindividuals_aggregate: Rtcs_Db_Ph_Rmatch_Nonindividuals_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_PropertiesComposite" */
  rtcs_db_PH_rmatch_PropertiesComposite: Array<Rtcs_Db_Ph_Rmatch_PropertiesComposite>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_PropertiesComposite" */
  rtcs_db_PH_rmatch_PropertiesComposite_aggregate: Rtcs_Db_Ph_Rmatch_PropertiesComposite_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_PropertiesNetwork" */
  rtcs_db_PH_rmatch_PropertiesNetwork: Array<Rtcs_Db_Ph_Rmatch_PropertiesNetwork>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_PropertiesNetwork" */
  rtcs_db_PH_rmatch_PropertiesNetwork_aggregate: Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_PropertyNetwork" */
  rtcs_db_PH_rmatch_PropertyNetwork: Array<Rtcs_Db_Ph_Rmatch_PropertyNetwork>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_PropertyNetwork" */
  rtcs_db_PH_rmatch_PropertyNetwork_aggregate: Rtcs_Db_Ph_Rmatch_PropertyNetwork_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_RMatchDetailed904" */
  rtcs_db_PH_rmatch_RMatchDetailed904: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed904>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_RMatchDetailed904" */
  rtcs_db_PH_rmatch_RMatchDetailed904_aggregate: Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_RMatchDetailed916" */
  rtcs_db_PH_rmatch_RMatchDetailed916: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916>;
  /** fetch data from the table: "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
  rtcs_db_PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
  rtcs_db_PH_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregate: Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_RMatchDetailed916" */
  rtcs_db_PH_rmatch_RMatchDetailed916_aggregate: Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_RMatchDetailed937" */
  rtcs_db_PH_rmatch_RMatchDetailed937: Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed937>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_RMatchDetailed937" */
  rtcs_db_PH_rmatch_RMatchDetailed937_aggregate: Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_RMatchKccaProperties" */
  rtcs_db_PH_rmatch_RMatchKccaProperties: Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_RMatchKccaProperties" */
  rtcs_db_PH_rmatch_RMatchKccaProperties_aggregate: Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_RMatchKccaProperty" */
  rtcs_db_PH_rmatch_RMatchKccaProperty: Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_RMatchKccaProperty" */
  rtcs_db_PH_rmatch_RMatchKccaProperty_aggregate: Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
  rtcs_db_PH_rmatch_RMatchNonIndividual903: Array<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_RMatchNonIndividual903" */
  rtcs_db_PH_rmatch_RMatchNonIndividual903_aggregate: Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_RMatchProperties1115" */
  rtcs_db_PH_rmatch_RMatchProperties1115: Array<Rtcs_Db_Ph_Rmatch_RMatchProperties1115>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_RMatchProperties1115" */
  rtcs_db_PH_rmatch_RMatchProperties1115_aggregate: Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_RMatchSummary904" */
  rtcs_db_PH_rmatch_RMatchSummary904: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary904>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_RMatchSummary904" */
  rtcs_db_PH_rmatch_RMatchSummary904_aggregate: Rtcs_Db_Ph_Rmatch_RMatchSummary904_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_RMatchSummary916" */
  rtcs_db_PH_rmatch_RMatchSummary916: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916>;
  /** fetch data from the table: "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
  rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
  rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregate: Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_RMatchSummary916" */
  rtcs_db_PH_rmatch_RMatchSummary916_aggregate: Rtcs_Db_Ph_Rmatch_RMatchSummary916_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_RMatchSummary937" */
  rtcs_db_PH_rmatch_RMatchSummary937: Array<Rtcs_Db_Ph_Rmatch_RMatchSummary937>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_RMatchSummary937" */
  rtcs_db_PH_rmatch_RMatchSummary937_aggregate: Rtcs_Db_Ph_Rmatch_RMatchSummary937_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_TinsURA" */
  rtcs_db_PH_rmatch_TinsURA: Array<Rtcs_Db_Ph_Rmatch_TinsUra>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_TinsURA" */
  rtcs_db_PH_rmatch_TinsURA_aggregate: Rtcs_Db_Ph_Rmatch_TinsUra_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_Undeclared" */
  rtcs_db_PH_rmatch_Undeclared: Array<Rtcs_Db_Ph_Rmatch_Undeclared>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_Undeclared" */
  rtcs_db_PH_rmatch_Undeclared_aggregate: Rtcs_Db_Ph_Rmatch_Undeclared_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_Underreported" */
  rtcs_db_PH_rmatch_Underreported: Array<Rtcs_Db_Ph_Rmatch_Underreported>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_Underreported" */
  rtcs_db_PH_rmatch_Underreported_aggregate: Rtcs_Db_Ph_Rmatch_Underreported_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_Unregistered" */
  rtcs_db_PH_rmatch_Unregistered: Array<Rtcs_Db_Ph_Rmatch_Unregistered>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_Unregistered" */
  rtcs_db_PH_rmatch_Unregistered_aggregate: Rtcs_Db_Ph_Rmatch_Unregistered_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_rmatch_mlhudConsolidated" */
  rtcs_db_PH_rmatch_mlhudConsolidated: Array<Rtcs_Db_Ph_Rmatch_MlhudConsolidated>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_rmatch_mlhudConsolidated" */
  rtcs_db_PH_rmatch_mlhudConsolidated_aggregate: Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
  rtcs_db_PH_streetmap_OsmMukonoMunicipality: Array<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_streetmap_OsmMukonoMunicipality" */
  rtcs_db_PH_streetmap_OsmMukonoMunicipality_aggregate: Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
  rtcs_db_PH_streetmap_OsmMukonoNakifuma: Array<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_streetmap_OsmMukonoNakifuma" */
  rtcs_db_PH_streetmap_OsmMukonoNakifuma_aggregate: Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
  rtcs_db_PH_streetmap_OsmWakisoEntebbe: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_streetmap_OsmWakisoEntebbe" */
  rtcs_db_PH_streetmap_OsmWakisoEntebbe_aggregate: Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_streetmap_OsmWakisoKira" */
  rtcs_db_PH_streetmap_OsmWakisoKira: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoKira>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_streetmap_OsmWakisoKira" */
  rtcs_db_PH_streetmap_OsmWakisoKira_aggregate: Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
  rtcs_db_PH_streetmap_OsmWakisoMakindyeSsabagabo: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_streetmap_OsmWakisoMakindyeSsabagabo" */
  rtcs_db_PH_streetmap_OsmWakisoMakindyeSsabagabo_aggregate: Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_streetmap_OsmWakisoNansana" */
  rtcs_db_PH_streetmap_OsmWakisoNansana: Array<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_streetmap_OsmWakisoNansana" */
  rtcs_db_PH_streetmap_OsmWakisoNansana_aggregate: Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ucc_UCC" */
  rtcs_db_PH_ucc_UCC: Array<Rtcs_Db_Ph_Ucc_Ucc>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ucc_UCC" */
  rtcs_db_PH_ucc_UCC_aggregate: Rtcs_Db_Ph_Ucc_Ucc_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_umeme_SubscriberAndProperty" */
  rtcs_db_PH_umeme_SubscriberAndProperty: Array<Rtcs_Db_Ph_Umeme_SubscriberAndProperty>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_umeme_SubscriberAndProperty" */
  rtcs_db_PH_umeme_SubscriberAndProperty_aggregate: Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_umeme_Transactions" */
  rtcs_db_PH_umeme_Transactions: Array<Rtcs_Db_Ph_Umeme_Transactions>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_umeme_Transactions" */
  rtcs_db_PH_umeme_Transactions_aggregate: Rtcs_Db_Ph_Umeme_Transactions_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_BalanceSheetInformation" */
  rtcs_db_PH_ura_BalanceSheetInformation: Array<Rtcs_Db_Ph_Ura_BalanceSheetInformation>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_BalanceSheetInformation" */
  rtcs_db_PH_ura_BalanceSheetInformation_aggregate: Rtcs_Db_Ph_Ura_BalanceSheetInformation_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
  rtcs_db_PH_ura_CombinedOfficersAndOwners: Array<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_CombinedOfficersAndOwners" */
  rtcs_db_PH_ura_CombinedOfficersAndOwners_aggregate: Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_EmploymentIncomeDetail" */
  rtcs_db_PH_ura_EmploymentIncomeDetail: Array<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_EmploymentIncomeDetail" */
  rtcs_db_PH_ura_EmploymentIncomeDetail_aggregate: Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
  rtcs_db_PH_ura_GazetteCommercialRatesPerSquareFootage: Array<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_GazetteCommercialRatesPerSquareFootage" */
  rtcs_db_PH_ura_GazetteCommercialRatesPerSquareFootage_aggregate: Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
  rtcs_db_PH_ura_GazetteResidentialRatesPerBedroom: Array<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_GazetteResidentialRatesPerBedroom" */
  rtcs_db_PH_ura_GazetteResidentialRatesPerBedroom_aggregate: Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_HoldingOfficers" */
  rtcs_db_PH_ura_HoldingOfficers: Array<Rtcs_Db_Ph_Ura_HoldingOfficers>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_HoldingOfficers" */
  rtcs_db_PH_ura_HoldingOfficers_aggregate: Rtcs_Db_Ph_Ura_HoldingOfficers_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_HoldingOwner" */
  rtcs_db_PH_ura_HoldingOwner: Array<Rtcs_Db_Ph_Ura_HoldingOwner>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_HoldingOwner" */
  rtcs_db_PH_ura_HoldingOwner_aggregate: Rtcs_Db_Ph_Ura_HoldingOwner_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_IncomeTaxHolding" */
  rtcs_db_PH_ura_IncomeTaxHolding: Array<Rtcs_Db_Ph_Ura_IncomeTaxHolding>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_IncomeTaxHolding" */
  rtcs_db_PH_ura_IncomeTaxHolding_aggregate: Rtcs_Db_Ph_Ura_IncomeTaxHolding_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
  rtcs_db_PH_ura_IncomeTaxSummaryIndividual: Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_IncomeTaxSummaryIndividual" */
  rtcs_db_PH_ura_IncomeTaxSummaryIndividual_aggregate: Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
  rtcs_db_PH_ura_IncomeTaxSummaryNonIndividual: Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_IncomeTaxSummaryNonIndividual" */
  rtcs_db_PH_ura_IncomeTaxSummaryNonIndividual_aggregate: Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_IndividualMortgage" */
  rtcs_db_PH_ura_IndividualMortgage: Array<Rtcs_Db_Ph_Ura_IndividualMortgage>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_IndividualMortgage" */
  rtcs_db_PH_ura_IndividualMortgage_aggregate: Rtcs_Db_Ph_Ura_IndividualMortgage_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
  rtcs_db_PH_ura_IndividualsAssociatedBusiness: Array<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_IndividualsAssociatedBusiness" */
  rtcs_db_PH_ura_IndividualsAssociatedBusiness_aggregate: Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_LandlordIncomeDetail" */
  rtcs_db_PH_ura_LandlordIncomeDetail: Array<Rtcs_Db_Ph_Ura_LandlordIncomeDetail>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_LandlordIncomeDetail" */
  rtcs_db_PH_ura_LandlordIncomeDetail_aggregate: Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_LandlordIncomeSummary" */
  rtcs_db_PH_ura_LandlordIncomeSummary: Array<Rtcs_Db_Ph_Ura_LandlordIncomeSummary>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_LandlordIncomeSummary" */
  rtcs_db_PH_ura_LandlordIncomeSummary_aggregate: Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
  rtcs_db_PH_ura_NonIndividualsAssociatedBusiness: Array<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_NonIndividualsAssociatedBusiness" */
  rtcs_db_PH_ura_NonIndividualsAssociatedBusiness_aggregate: Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_RegAssociates" */
  rtcs_db_PH_ura_RegAssociates: Array<Rtcs_Db_Ph_Ura_RegAssociates>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_RegAssociates" */
  rtcs_db_PH_ura_RegAssociates_aggregate: Rtcs_Db_Ph_Ura_RegAssociates_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_RegIndividual" */
  rtcs_db_PH_ura_RegIndividual: Array<Rtcs_Db_Ph_Ura_RegIndividual>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_RegIndividual" */
  rtcs_db_PH_ura_RegIndividual_aggregate: Rtcs_Db_Ph_Ura_RegIndividual_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_RegIndividual_sid" */
  rtcs_db_PH_ura_RegIndividual_sid: Array<Rtcs_Db_Ph_Ura_RegIndividual_Sid>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_RegIndividual_sid" */
  rtcs_db_PH_ura_RegIndividual_sid_aggregate: Rtcs_Db_Ph_Ura_RegIndividual_Sid_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_RegNonIndividual" */
  rtcs_db_PH_ura_RegNonIndividual: Array<Rtcs_Db_Ph_Ura_RegNonIndividual>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_RegNonIndividual" */
  rtcs_db_PH_ura_RegNonIndividual_aggregate: Rtcs_Db_Ph_Ura_RegNonIndividual_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_RegNonIndividual_sid" */
  rtcs_db_PH_ura_RegNonIndividual_sid: Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_RegNonIndividual_sid" */
  rtcs_db_PH_ura_RegNonIndividual_sid_aggregate: Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_RentExpenseDetails" */
  rtcs_db_PH_ura_RentExpenseDetails: Array<Rtcs_Db_Ph_Ura_RentExpenseDetails>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_RentExpenseDetails" */
  rtcs_db_PH_ura_RentExpenseDetails_aggregate: Rtcs_Db_Ph_Ura_RentExpenseDetails_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_RentalLandLordIncome" */
  rtcs_db_PH_ura_RentalLandLordIncome: Array<Rtcs_Db_Ph_Ura_RentalLandLordIncome>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_RentalLandLordIncome" */
  rtcs_db_PH_ura_RentalLandLordIncome_aggregate: Rtcs_Db_Ph_Ura_RentalLandLordIncome_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_RentalTenantPayments" */
  rtcs_db_PH_ura_RentalTenantPayments: Array<Rtcs_Db_Ph_Ura_RentalTenantPayments>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_RentalTenantPayments" */
  rtcs_db_PH_ura_RentalTenantPayments_aggregate: Rtcs_Db_Ph_Ura_RentalTenantPayments_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
  rtcs_db_PH_ura_RipoIndRentalIncSummary: Array<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_RipoIndRentalIncSummary" */
  rtcs_db_PH_ura_RipoIndRentalIncSummary_aggregate: Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_TenantIncomeDetail" */
  rtcs_db_PH_ura_TenantIncomeDetail: Array<Rtcs_Db_Ph_Ura_TenantIncomeDetail>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_TenantIncomeDetail" */
  rtcs_db_PH_ura_TenantIncomeDetail_aggregate: Rtcs_Db_Ph_Ura_TenantIncomeDetail_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ura_Town" */
  rtcs_db_PH_ura_Town: Array<Rtcs_Db_Ph_Ura_Town>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ura_Town" */
  rtcs_db_PH_ura_Town_aggregate: Rtcs_Db_Ph_Ura_Town_Aggregate;
  /** fetch data from the table: "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
  rtcs_db_PH_ursb_CompaniesAndBusinessNamesURSB: Array<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb>;
  /** fetch aggregated fields from the table: "rtcs_db.PH_ursb_CompaniesAndBusinessNamesURSB" */
  rtcs_db_PH_ursb_CompaniesAndBusinessNamesURSB_aggregate: Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
  rtcs_db_RPT_IndividualComplianceSummaryPlusRisk: Array<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_IndividualComplianceSummaryPlusRisk" */
  rtcs_db_RPT_IndividualComplianceSummaryPlusRisk_aggregate: Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_IndividualComposite" */
  rtcs_db_RPT_IndividualComposite: Array<Rtcs_Db_Rpt_IndividualComposite>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_IndividualComposite" */
  rtcs_db_RPT_IndividualComposite_aggregate: Rtcs_Db_Rpt_IndividualComposite_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_IndividualRegistration" */
  rtcs_db_RPT_IndividualRegistration: Array<Rtcs_Db_Rpt_IndividualRegistration>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_IndividualRegistration" */
  rtcs_db_RPT_IndividualRegistration_aggregate: Rtcs_Db_Rpt_IndividualRegistration_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_IndividualTaxSummaryData" */
  rtcs_db_RPT_IndividualTaxSummaryData: Array<Rtcs_Db_Rpt_IndividualTaxSummaryData>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_IndividualTaxSummaryData" */
  rtcs_db_RPT_IndividualTaxSummaryData_aggregate: Rtcs_Db_Rpt_IndividualTaxSummaryData_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_KccaAffiliates" */
  rtcs_db_RPT_KccaAffiliates: Array<Rtcs_Db_Rpt_KccaAffiliates>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_KccaAffiliates" */
  rtcs_db_RPT_KccaAffiliates_aggregate: Rtcs_Db_Rpt_KccaAffiliates_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_KccaCustomerRegistrationData" */
  rtcs_db_RPT_KccaCustomerRegistrationData: Array<Rtcs_Db_Rpt_KccaCustomerRegistrationData>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_KccaCustomerRegistrationData" */
  rtcs_db_RPT_KccaCustomerRegistrationData_aggregate: Rtcs_Db_Rpt_KccaCustomerRegistrationData_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_KccaPropertyRegistration" */
  rtcs_db_RPT_KccaPropertyRegistration: Array<Rtcs_Db_Rpt_KccaPropertyRegistration>;
  /** fetch data from the table: "rtcs_db.RPT_KccaPropertyRegistrationData" */
  rtcs_db_RPT_KccaPropertyRegistrationData: Array<Rtcs_Db_Rpt_KccaPropertyRegistrationData>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_KccaPropertyRegistrationData" */
  rtcs_db_RPT_KccaPropertyRegistrationData_aggregate: Rtcs_Db_Rpt_KccaPropertyRegistrationData_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_KccaPropertyRegistration" */
  rtcs_db_RPT_KccaPropertyRegistration_aggregate: Rtcs_Db_Rpt_KccaPropertyRegistration_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_NonIndividualBalanceSheet" */
  rtcs_db_RPT_NonIndividualBalanceSheet: Array<Rtcs_Db_Rpt_NonIndividualBalanceSheet>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_NonIndividualBalanceSheet" */
  rtcs_db_RPT_NonIndividualBalanceSheet_aggregate: Rtcs_Db_Rpt_NonIndividualBalanceSheet_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
  rtcs_db_RPT_NonIndividualComplianceSummaryPlusRisk: Array<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_NonIndividualComplianceSummaryPlusRisk" */
  rtcs_db_RPT_NonIndividualComplianceSummaryPlusRisk_aggregate: Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_NonIndividualComposite" */
  rtcs_db_RPT_NonIndividualComposite: Array<Rtcs_Db_Rpt_NonIndividualComposite>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_NonIndividualComposite" */
  rtcs_db_RPT_NonIndividualComposite_aggregate: Rtcs_Db_Rpt_NonIndividualComposite_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_NonIndividualTaxSummaryData" */
  rtcs_db_RPT_NonIndividualTaxSummaryData: Array<Rtcs_Db_Rpt_NonIndividualTaxSummaryData>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_NonIndividualTaxSummaryData" */
  rtcs_db_RPT_NonIndividualTaxSummaryData_aggregate: Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_PropertiesComposite" */
  rtcs_db_RPT_PropertiesComposite: Array<Rtcs_Db_Rpt_PropertiesComposite>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_PropertiesComposite" */
  rtcs_db_RPT_PropertiesComposite_aggregate: Rtcs_Db_Rpt_PropertiesComposite_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_PropertyValuation" */
  rtcs_db_RPT_PropertyValuation: Array<Rtcs_Db_Rpt_PropertyValuation>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_PropertyValuation" */
  rtcs_db_RPT_PropertyValuation_aggregate: Rtcs_Db_Rpt_PropertyValuation_Aggregate;
  /** fetch data from the table: "rtcs_db.RPT_RentalTenantPayments" */
  rtcs_db_RPT_RentalTenantPayments: Array<Rtcs_Db_Rpt_RentalTenantPayments>;
  /** fetch aggregated fields from the table: "rtcs_db.RPT_RentalTenantPayments" */
  rtcs_db_RPT_RentalTenantPayments_aggregate: Rtcs_Db_Rpt_RentalTenantPayments_Aggregate;
  /** fetch data from the table: "rtcs_db.aggregate_result" */
  rtcs_db_aggregate_result: Array<Rtcs_Db_Aggregate_Result>;
  /** fetch aggregated fields from the table: "rtcs_db.aggregate_result" */
  rtcs_db_aggregate_result_aggregate: Rtcs_Db_Aggregate_Result_Aggregate;
  /** fetch data from the table: "rtcs_db.aggregate_result_txt" */
  rtcs_db_aggregate_result_txt: Array<Rtcs_Db_Aggregate_Result_Txt>;
  /** fetch aggregated fields from the table: "rtcs_db.aggregate_result_txt" */
  rtcs_db_aggregate_result_txt_aggregate: Rtcs_Db_Aggregate_Result_Txt_Aggregate;
  /** fetch data from the table: "rtcs_db.gazette_CommercialRates" */
  rtcs_db_gazette_CommercialRates: Array<Rtcs_Db_Gazette_CommercialRates>;
  /** fetch aggregated fields from the table: "rtcs_db.gazette_CommercialRates" */
  rtcs_db_gazette_CommercialRates_aggregate: Rtcs_Db_Gazette_CommercialRates_Aggregate;
  /** fetch data from the table: "rtcs_db.gazette_ResidentialRates" */
  rtcs_db_gazette_ResidentialRates: Array<Rtcs_Db_Gazette_ResidentialRates>;
  /** fetch aggregated fields from the table: "rtcs_db.gazette_ResidentialRates" */
  rtcs_db_gazette_ResidentialRates_aggregate: Rtcs_Db_Gazette_ResidentialRates_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_AssociateType" */
  rtcs_db_kcca_AssociateType: Array<Rtcs_Db_Kcca_AssociateType>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_AssociateType" */
  rtcs_db_kcca_AssociateType_aggregate: Rtcs_Db_Kcca_AssociateType_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_CommercialProperties" */
  rtcs_db_kcca_CommercialProperties: Array<Rtcs_Db_Kcca_CommercialProperties>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_CommercialProperties" */
  rtcs_db_kcca_CommercialProperties_aggregate: Rtcs_Db_Kcca_CommercialProperties_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_County" */
  rtcs_db_kcca_County: Array<Rtcs_Db_Kcca_County>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_County" */
  rtcs_db_kcca_County_aggregate: Rtcs_Db_Kcca_County_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_Customer" */
  rtcs_db_kcca_Customer: Array<Rtcs_Db_Kcca_Customer>;
  /** fetch data from the table: "rtcs_db.kcca_CustomerApplicantType" */
  rtcs_db_kcca_CustomerApplicantType: Array<Rtcs_Db_Kcca_CustomerApplicantType>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_CustomerApplicantType" */
  rtcs_db_kcca_CustomerApplicantType_aggregate: Rtcs_Db_Kcca_CustomerApplicantType_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_CustomerBusinessAssociate" */
  rtcs_db_kcca_CustomerBusinessAssociate: Array<Rtcs_Db_Kcca_CustomerBusinessAssociate>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_CustomerBusinessAssociate" */
  rtcs_db_kcca_CustomerBusinessAssociate_aggregate: Rtcs_Db_Kcca_CustomerBusinessAssociate_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_CustomerBusinessType" */
  rtcs_db_kcca_CustomerBusinessType: Array<Rtcs_Db_Kcca_CustomerBusinessType>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_CustomerBusinessType" */
  rtcs_db_kcca_CustomerBusinessType_aggregate: Rtcs_Db_Kcca_CustomerBusinessType_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
  rtcs_db_kcca_CustomerCoinDeactivateReactive: Array<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_CustomerCoinDeactivateReactive" */
  rtcs_db_kcca_CustomerCoinDeactivateReactive_aggregate: Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_CustomerContactPerson" */
  rtcs_db_kcca_CustomerContactPerson: Array<Rtcs_Db_Kcca_CustomerContactPerson>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_CustomerContactPerson" */
  rtcs_db_kcca_CustomerContactPerson_aggregate: Rtcs_Db_Kcca_CustomerContactPerson_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_CustomerDocumentItem" */
  rtcs_db_kcca_CustomerDocumentItem: Array<Rtcs_Db_Kcca_CustomerDocumentItem>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_CustomerDocumentItem" */
  rtcs_db_kcca_CustomerDocumentItem_aggregate: Rtcs_Db_Kcca_CustomerDocumentItem_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_CustomerGuardian" */
  rtcs_db_kcca_CustomerGuardian: Array<Rtcs_Db_Kcca_CustomerGuardian>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_CustomerGuardian" */
  rtcs_db_kcca_CustomerGuardian_aggregate: Rtcs_Db_Kcca_CustomerGuardian_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_CustomerReference" */
  rtcs_db_kcca_CustomerReference: Array<Rtcs_Db_Kcca_CustomerReference>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_CustomerReference" */
  rtcs_db_kcca_CustomerReference_aggregate: Rtcs_Db_Kcca_CustomerReference_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_CustomerRegistrationReason" */
  rtcs_db_kcca_CustomerRegistrationReason: Array<Rtcs_Db_Kcca_CustomerRegistrationReason>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_CustomerRegistrationReason" */
  rtcs_db_kcca_CustomerRegistrationReason_aggregate: Rtcs_Db_Kcca_CustomerRegistrationReason_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_CustomerRevenueAgent" */
  rtcs_db_kcca_CustomerRevenueAgent: Array<Rtcs_Db_Kcca_CustomerRevenueAgent>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_CustomerRevenueAgent" */
  rtcs_db_kcca_CustomerRevenueAgent_aggregate: Rtcs_Db_Kcca_CustomerRevenueAgent_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_CustomerType" */
  rtcs_db_kcca_CustomerType: Array<Rtcs_Db_Kcca_CustomerType>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_CustomerType" */
  rtcs_db_kcca_CustomerType_aggregate: Rtcs_Db_Kcca_CustomerType_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_Customer" */
  rtcs_db_kcca_Customer_aggregate: Rtcs_Db_Kcca_Customer_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_Customer_sid" */
  rtcs_db_kcca_Customer_sid: Array<Rtcs_Db_Kcca_Customer_Sid>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_Customer_sid" */
  rtcs_db_kcca_Customer_sid_aggregate: Rtcs_Db_Kcca_Customer_Sid_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_District" */
  rtcs_db_kcca_District: Array<Rtcs_Db_Kcca_District>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_District" */
  rtcs_db_kcca_District_aggregate: Rtcs_Db_Kcca_District_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_Division" */
  rtcs_db_kcca_Division: Array<Rtcs_Db_Kcca_Division>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_Division" */
  rtcs_db_kcca_Division_aggregate: Rtcs_Db_Kcca_Division_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_ImgsData" */
  rtcs_db_kcca_ImgsData: Array<Rtcs_Db_Kcca_ImgsData>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_ImgsData" */
  rtcs_db_kcca_ImgsData_aggregate: Rtcs_Db_Kcca_ImgsData_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_Parish" */
  rtcs_db_kcca_Parish: Array<Rtcs_Db_Kcca_Parish>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_Parish" */
  rtcs_db_kcca_Parish_aggregate: Rtcs_Db_Kcca_Parish_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_PaymentDetails" */
  rtcs_db_kcca_PaymentDetails: Array<Rtcs_Db_Kcca_PaymentDetails>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_PaymentDetails" */
  rtcs_db_kcca_PaymentDetails_aggregate: Rtcs_Db_Kcca_PaymentDetails_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_Payments" */
  rtcs_db_kcca_Payments: Array<Rtcs_Db_Kcca_Payments>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_Payments" */
  rtcs_db_kcca_Payments_aggregate: Rtcs_Db_Kcca_Payments_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_Property" */
  rtcs_db_kcca_Property: Array<Rtcs_Db_Kcca_Property>;
  /** fetch data from the table: "rtcs_db.kcca_PropertyFloors" */
  rtcs_db_kcca_PropertyFloors: Array<Rtcs_Db_Kcca_PropertyFloors>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_PropertyFloors" */
  rtcs_db_kcca_PropertyFloors_aggregate: Rtcs_Db_Kcca_PropertyFloors_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_PropertyNumberBedrooms" */
  rtcs_db_kcca_PropertyNumberBedrooms: Array<Rtcs_Db_Kcca_PropertyNumberBedrooms>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_PropertyNumberBedrooms" */
  rtcs_db_kcca_PropertyNumberBedrooms_aggregate: Rtcs_Db_Kcca_PropertyNumberBedrooms_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_PropertyRentedStatus" */
  rtcs_db_kcca_PropertyRentedStatus: Array<Rtcs_Db_Kcca_PropertyRentedStatus>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_PropertyRentedStatus" */
  rtcs_db_kcca_PropertyRentedStatus_aggregate: Rtcs_Db_Kcca_PropertyRentedStatus_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_PropertySecurity" */
  rtcs_db_kcca_PropertySecurity: Array<Rtcs_Db_Kcca_PropertySecurity>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_PropertySecurity" */
  rtcs_db_kcca_PropertySecurity_aggregate: Rtcs_Db_Kcca_PropertySecurity_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_PropertySubType" */
  rtcs_db_kcca_PropertySubType: Array<Rtcs_Db_Kcca_PropertySubType>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_PropertySubType" */
  rtcs_db_kcca_PropertySubType_aggregate: Rtcs_Db_Kcca_PropertySubType_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_PropertyType" */
  rtcs_db_kcca_PropertyType: Array<Rtcs_Db_Kcca_PropertyType>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_PropertyType" */
  rtcs_db_kcca_PropertyType_aggregate: Rtcs_Db_Kcca_PropertyType_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_Property" */
  rtcs_db_kcca_Property_aggregate: Rtcs_Db_Kcca_Property_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_RawProperties1" */
  rtcs_db_kcca_RawProperties1: Array<Rtcs_Db_Kcca_RawProperties1>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_RawProperties1" */
  rtcs_db_kcca_RawProperties1_aggregate: Rtcs_Db_Kcca_RawProperties1_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_RawProperties2" */
  rtcs_db_kcca_RawProperties2: Array<Rtcs_Db_Kcca_RawProperties2>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_RawProperties2" */
  rtcs_db_kcca_RawProperties2_aggregate: Rtcs_Db_Kcca_RawProperties2_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_ResidentialProperties" */
  rtcs_db_kcca_ResidentialProperties: Array<Rtcs_Db_Kcca_ResidentialProperties>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_ResidentialProperties" */
  rtcs_db_kcca_ResidentialProperties_aggregate: Rtcs_Db_Kcca_ResidentialProperties_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_RevenueType" */
  rtcs_db_kcca_RevenueType: Array<Rtcs_Db_Kcca_RevenueType>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_RevenueType" */
  rtcs_db_kcca_RevenueType_aggregate: Rtcs_Db_Kcca_RevenueType_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_SubCounty" */
  rtcs_db_kcca_SubCounty: Array<Rtcs_Db_Kcca_SubCounty>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_SubCounty" */
  rtcs_db_kcca_SubCounty_aggregate: Rtcs_Db_Kcca_SubCounty_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_SubpropertyTypeConversion" */
  rtcs_db_kcca_SubpropertyTypeConversion: Array<Rtcs_Db_Kcca_SubpropertyTypeConversion>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_SubpropertyTypeConversion" */
  rtcs_db_kcca_SubpropertyTypeConversion_aggregate: Rtcs_Db_Kcca_SubpropertyTypeConversion_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_TradingLicense" */
  rtcs_db_kcca_TradingLicense: Array<Rtcs_Db_Kcca_TradingLicense>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_TradingLicense" */
  rtcs_db_kcca_TradingLicense_aggregate: Rtcs_Db_Kcca_TradingLicense_Aggregate;
  /** fetch data from the table: "rtcs_db.kcca_Village" */
  rtcs_db_kcca_Village: Array<Rtcs_Db_Kcca_Village>;
  /** fetch aggregated fields from the table: "rtcs_db.kcca_Village" */
  rtcs_db_kcca_Village_aggregate: Rtcs_Db_Kcca_Village_Aggregate;
  /** fetch data from the table: "rtcs_db.master_MasterIndiv" */
  rtcs_db_master_MasterIndiv: Array<Rtcs_Db_Master_MasterIndiv>;
  /** fetch aggregated fields from the table: "rtcs_db.master_MasterIndiv" */
  rtcs_db_master_MasterIndiv_aggregate: Rtcs_Db_Master_MasterIndiv_Aggregate;
  /** fetch data from the table: "rtcs_db.mlhud_KCCA" */
  rtcs_db_mlhud_KCCA: Array<Rtcs_Db_Mlhud_Kcca>;
  /** fetch aggregated fields from the table: "rtcs_db.mlhud_KCCA" */
  rtcs_db_mlhud_KCCA_aggregate: Rtcs_Db_Mlhud_Kcca_Aggregate;
  /** fetch data from the table: "rtcs_db.mlhud_Mukono" */
  rtcs_db_mlhud_Mukono: Array<Rtcs_Db_Mlhud_Mukono>;
  /** fetch aggregated fields from the table: "rtcs_db.mlhud_Mukono" */
  rtcs_db_mlhud_Mukono_aggregate: Rtcs_Db_Mlhud_Mukono_Aggregate;
  /** fetch data from the table: "rtcs_db.mlhud_Properties" */
  rtcs_db_mlhud_Properties: Array<Rtcs_Db_Mlhud_Properties>;
  /** fetch aggregated fields from the table: "rtcs_db.mlhud_Properties" */
  rtcs_db_mlhud_Properties_aggregate: Rtcs_Db_Mlhud_Properties_Aggregate;
  /** fetch data from the table: "rtcs_db.mlhud_WakisoBusiro" */
  rtcs_db_mlhud_WakisoBusiro: Array<Rtcs_Db_Mlhud_WakisoBusiro>;
  /** fetch aggregated fields from the table: "rtcs_db.mlhud_WakisoBusiro" */
  rtcs_db_mlhud_WakisoBusiro_aggregate: Rtcs_Db_Mlhud_WakisoBusiro_Aggregate;
  /** fetch data from the table: "rtcs_db.mlhud_WakisoKyadondo" */
  rtcs_db_mlhud_WakisoKyadondo: Array<Rtcs_Db_Mlhud_WakisoKyadondo>;
  /** fetch aggregated fields from the table: "rtcs_db.mlhud_WakisoKyadondo" */
  rtcs_db_mlhud_WakisoKyadondo_aggregate: Rtcs_Db_Mlhud_WakisoKyadondo_Aggregate;
  /** fetch data from the table: "rtcs_db.nira_Nira" */
  rtcs_db_nira_Nira: Array<Rtcs_Db_Nira_Nira>;
  /** fetch aggregated fields from the table: "rtcs_db.nira_Nira" */
  rtcs_db_nira_Nira_aggregate: Rtcs_Db_Nira_Nira_Aggregate;
  /** fetch data from the table: "rtcs_db.nira_RawData" */
  rtcs_db_nira_RawData: Array<Rtcs_Db_Nira_RawData>;
  /** fetch aggregated fields from the table: "rtcs_db.nira_RawData" */
  rtcs_db_nira_RawData_aggregate: Rtcs_Db_Nira_RawData_Aggregate;
  /** fetch data from the table: "rtcs_db.nwsc_Customers" */
  rtcs_db_nwsc_Customers: Array<Rtcs_Db_Nwsc_Customers>;
  /** fetch aggregated fields from the table: "rtcs_db.nwsc_Customers" */
  rtcs_db_nwsc_Customers_aggregate: Rtcs_Db_Nwsc_Customers_Aggregate;
  /** fetch data from the table: "rtcs_db.nwsc_NWCustomers" */
  rtcs_db_nwsc_NWCustomers: Array<Rtcs_Db_Nwsc_NwCustomers>;
  /** fetch aggregated fields from the table: "rtcs_db.nwsc_NWCustomers" */
  rtcs_db_nwsc_NWCustomers_aggregate: Rtcs_Db_Nwsc_NwCustomers_Aggregate;
  /** fetch data from the table: "rtcs_db.nwsc_RPT_Transactions" */
  rtcs_db_nwsc_RPT_Transactions: Array<Rtcs_Db_Nwsc_Rpt_Transactions>;
  /** fetch aggregated fields from the table: "rtcs_db.nwsc_RPT_Transactions" */
  rtcs_db_nwsc_RPT_Transactions_aggregate: Rtcs_Db_Nwsc_Rpt_Transactions_Aggregate;
  /** fetch data from the table: "rtcs_db.nwsc_Transactions" */
  rtcs_db_nwsc_Transactions: Array<Rtcs_Db_Nwsc_Transactions>;
  /** fetch aggregated fields from the table: "rtcs_db.nwsc_Transactions" */
  rtcs_db_nwsc_Transactions_aggregate: Rtcs_Db_Nwsc_Transactions_Aggregate;
  /** fetch data from the table: "rtcs_db.nwsc_URATransactions" */
  rtcs_db_nwsc_URATransactions: Array<Rtcs_Db_Nwsc_UraTransactions>;
  /** fetch aggregated fields from the table: "rtcs_db.nwsc_URATransactions" */
  rtcs_db_nwsc_URATransactions_aggregate: Rtcs_Db_Nwsc_UraTransactions_Aggregate;
  /** fetch data from the table: "rtcs_db.rcapture_RPTUnittypes" */
  rtcs_db_rcapture_RPTUnittypes: Array<Rtcs_Db_Rcapture_RptUnittypes>;
  /** fetch aggregated fields from the table: "rtcs_db.rcapture_RPTUnittypes" */
  rtcs_db_rcapture_RPTUnittypes_aggregate: Rtcs_Db_Rcapture_RptUnittypes_Aggregate;
  /** fetch data from the table: "rtcs_db.rcapture_RPTlessors" */
  rtcs_db_rcapture_RPTlessors: Array<Rtcs_Db_Rcapture_RpTlessors>;
  /** fetch aggregated fields from the table: "rtcs_db.rcapture_RPTlessors" */
  rtcs_db_rcapture_RPTlessors_aggregate: Rtcs_Db_Rcapture_RpTlessors_Aggregate;
  /** fetch data from the table: "rtcs_db.rcapture_RPTmeta" */
  rtcs_db_rcapture_RPTmeta: Array<Rtcs_Db_Rcapture_RpTmeta>;
  /** fetch aggregated fields from the table: "rtcs_db.rcapture_RPTmeta" */
  rtcs_db_rcapture_RPTmeta_aggregate: Rtcs_Db_Rcapture_RpTmeta_Aggregate;
  /** fetch data from the table: "rtcs_db.rcapture_RPTproperties" */
  rtcs_db_rcapture_RPTproperties: Array<Rtcs_Db_Rcapture_RpTproperties>;
  /** fetch aggregated fields from the table: "rtcs_db.rcapture_RPTproperties" */
  rtcs_db_rcapture_RPTproperties_aggregate: Rtcs_Db_Rcapture_RpTproperties_Aggregate;
  /** fetch data from the table: "rtcs_db.rcapture_RPTtenants" */
  rtcs_db_rcapture_RPTtenants: Array<Rtcs_Db_Rcapture_RpTtenants>;
  /** fetch aggregated fields from the table: "rtcs_db.rcapture_RPTtenants" */
  rtcs_db_rcapture_RPTtenants_aggregate: Rtcs_Db_Rcapture_RpTtenants_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_Individual" */
  rtcs_db_rmatch_Individual: Array<Rtcs_Db_Rmatch_Individual>;
  /** fetch data from the table: "rtcs_db.rmatch_IndividualComplianceDetailReport" */
  rtcs_db_rmatch_IndividualComplianceDetailReport: Array<Rtcs_Db_Rmatch_IndividualComplianceDetailReport>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_IndividualComplianceDetailReport" */
  rtcs_db_rmatch_IndividualComplianceDetailReport_aggregate: Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
  rtcs_db_rmatch_IndividualComplianceSummaryReport: Array<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_IndividualComplianceSummaryReport" */
  rtcs_db_rmatch_IndividualComplianceSummaryReport_aggregate: Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_IndividualRiskScore" */
  rtcs_db_rmatch_IndividualRiskScore: Array<Rtcs_Db_Rmatch_IndividualRiskScore>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_IndividualRiskScore" */
  rtcs_db_rmatch_IndividualRiskScore_aggregate: Rtcs_Db_Rmatch_IndividualRiskScore_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_Individual" */
  rtcs_db_rmatch_Individual_aggregate: Rtcs_Db_Rmatch_Individual_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_IndividualsNetwork" */
  rtcs_db_rmatch_IndividualsNetwork: Array<Rtcs_Db_Rmatch_IndividualsNetwork>;
  /** fetch data from the table: "rtcs_db.rmatch_IndividualsNetworkTesting" */
  rtcs_db_rmatch_IndividualsNetworkTesting: Array<Rtcs_Db_Rmatch_IndividualsNetworkTesting>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_IndividualsNetworkTesting" */
  rtcs_db_rmatch_IndividualsNetworkTesting_aggregate: Rtcs_Db_Rmatch_IndividualsNetworkTesting_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_IndividualsNetwork" */
  rtcs_db_rmatch_IndividualsNetwork_aggregate: Rtcs_Db_Rmatch_IndividualsNetwork_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
  rtcs_db_rmatch_KccaToKccaIndividualOwners: Array<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_KccaToKccaIndividualOwners" */
  rtcs_db_rmatch_KccaToKccaIndividualOwners_aggregate: Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
  rtcs_db_rmatch_KccaToKccaNonindividualOwners: Array<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_KccaToKccaNonindividualOwners" */
  rtcs_db_rmatch_KccaToKccaNonindividualOwners_aggregate: Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_MatchIndividualsProperties" */
  rtcs_db_rmatch_MatchIndividualsProperties: Array<Rtcs_Db_Rmatch_MatchIndividualsProperties>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_MatchIndividualsProperties" */
  rtcs_db_rmatch_MatchIndividualsProperties_aggregate: Rtcs_Db_Rmatch_MatchIndividualsProperties_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_MatchIndividualsProps" */
  rtcs_db_rmatch_MatchIndividualsProps: Array<Rtcs_Db_Rmatch_MatchIndividualsProps>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_MatchIndividualsProps" */
  rtcs_db_rmatch_MatchIndividualsProps_aggregate: Rtcs_Db_Rmatch_MatchIndividualsProps_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_NoReturns" */
  rtcs_db_rmatch_NoReturns: Array<Rtcs_Db_Rmatch_NoReturns>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_NoReturns" */
  rtcs_db_rmatch_NoReturns_aggregate: Rtcs_Db_Rmatch_NoReturns_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_NonIndividual" */
  rtcs_db_rmatch_NonIndividual: Array<Rtcs_Db_Rmatch_NonIndividual>;
  /** fetch data from the table: "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
  rtcs_db_rmatch_NonIndividualComplianceDetailReport: Array<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_NonIndividualComplianceDetailReport" */
  rtcs_db_rmatch_NonIndividualComplianceDetailReport_aggregate: Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
  rtcs_db_rmatch_NonIndividualComplianceSummaryReport: Array<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_NonIndividualComplianceSummaryReport" */
  rtcs_db_rmatch_NonIndividualComplianceSummaryReport_aggregate: Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_NonIndividualRiskScore" */
  rtcs_db_rmatch_NonIndividualRiskScore: Array<Rtcs_Db_Rmatch_NonIndividualRiskScore>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_NonIndividualRiskScore" */
  rtcs_db_rmatch_NonIndividualRiskScore_aggregate: Rtcs_Db_Rmatch_NonIndividualRiskScore_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_NonIndividual" */
  rtcs_db_rmatch_NonIndividual_aggregate: Rtcs_Db_Rmatch_NonIndividual_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_NonIndividuals" */
  rtcs_db_rmatch_NonIndividuals: Array<Rtcs_Db_Rmatch_NonIndividuals>;
  /** fetch data from the table: "rtcs_db.rmatch_NonIndividualsNetwork" */
  rtcs_db_rmatch_NonIndividualsNetwork: Array<Rtcs_Db_Rmatch_NonIndividualsNetwork>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_NonIndividualsNetwork" */
  rtcs_db_rmatch_NonIndividualsNetwork_aggregate: Rtcs_Db_Rmatch_NonIndividualsNetwork_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_NonIndividuals" */
  rtcs_db_rmatch_NonIndividuals_aggregate: Rtcs_Db_Rmatch_NonIndividuals_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_NonindividualOwners" */
  rtcs_db_rmatch_NonindividualOwners: Array<Rtcs_Db_Rmatch_NonindividualOwners>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_NonindividualOwners" */
  rtcs_db_rmatch_NonindividualOwners_aggregate: Rtcs_Db_Rmatch_NonindividualOwners_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_Nonindividuals" */
  rtcs_db_rmatch_Nonindividuals: Array<Rtcs_Db_Rmatch_Nonindividuals>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_Nonindividuals" */
  rtcs_db_rmatch_Nonindividuals_aggregate: Rtcs_Db_Rmatch_Nonindividuals_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_PropertiesComposite" */
  rtcs_db_rmatch_PropertiesComposite: Array<Rtcs_Db_Rmatch_PropertiesComposite>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_PropertiesComposite" */
  rtcs_db_rmatch_PropertiesComposite_aggregate: Rtcs_Db_Rmatch_PropertiesComposite_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_PropertiesNetwork" */
  rtcs_db_rmatch_PropertiesNetwork: Array<Rtcs_Db_Rmatch_PropertiesNetwork>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_PropertiesNetwork" */
  rtcs_db_rmatch_PropertiesNetwork_aggregate: Rtcs_Db_Rmatch_PropertiesNetwork_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_PropertyNetwork" */
  rtcs_db_rmatch_PropertyNetwork: Array<Rtcs_Db_Rmatch_PropertyNetwork>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_PropertyNetwork" */
  rtcs_db_rmatch_PropertyNetwork_aggregate: Rtcs_Db_Rmatch_PropertyNetwork_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_RMatchDetailed904" */
  rtcs_db_rmatch_RMatchDetailed904: Array<Rtcs_Db_Rmatch_RMatchDetailed904>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_RMatchDetailed904" */
  rtcs_db_rmatch_RMatchDetailed904_aggregate: Rtcs_Db_Rmatch_RMatchDetailed904_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_RMatchDetailed916" */
  rtcs_db_rmatch_RMatchDetailed916: Array<Rtcs_Db_Rmatch_RMatchDetailed916>;
  /** fetch data from the table: "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
  rtcs_db_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy: Array<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_RMatchDetailed916ExcessiveOwnerOccupancy" */
  rtcs_db_rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_aggregate: Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_RMatchDetailed916" */
  rtcs_db_rmatch_RMatchDetailed916_aggregate: Rtcs_Db_Rmatch_RMatchDetailed916_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_RMatchDetailed937" */
  rtcs_db_rmatch_RMatchDetailed937: Array<Rtcs_Db_Rmatch_RMatchDetailed937>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_RMatchDetailed937" */
  rtcs_db_rmatch_RMatchDetailed937_aggregate: Rtcs_Db_Rmatch_RMatchDetailed937_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_RMatchKccaProperties" */
  rtcs_db_rmatch_RMatchKccaProperties: Array<Rtcs_Db_Rmatch_RMatchKccaProperties>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_RMatchKccaProperties" */
  rtcs_db_rmatch_RMatchKccaProperties_aggregate: Rtcs_Db_Rmatch_RMatchKccaProperties_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_RMatchKccaProperty" */
  rtcs_db_rmatch_RMatchKccaProperty: Array<Rtcs_Db_Rmatch_RMatchKccaProperty>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_RMatchKccaProperty" */
  rtcs_db_rmatch_RMatchKccaProperty_aggregate: Rtcs_Db_Rmatch_RMatchKccaProperty_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_RMatchNonIndividual903" */
  rtcs_db_rmatch_RMatchNonIndividual903: Array<Rtcs_Db_Rmatch_RMatchNonIndividual903>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_RMatchNonIndividual903" */
  rtcs_db_rmatch_RMatchNonIndividual903_aggregate: Rtcs_Db_Rmatch_RMatchNonIndividual903_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_RMatchProperties1115" */
  rtcs_db_rmatch_RMatchProperties1115: Array<Rtcs_Db_Rmatch_RMatchProperties1115>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_RMatchProperties1115" */
  rtcs_db_rmatch_RMatchProperties1115_aggregate: Rtcs_Db_Rmatch_RMatchProperties1115_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_RMatchSummary904" */
  rtcs_db_rmatch_RMatchSummary904: Array<Rtcs_Db_Rmatch_RMatchSummary904>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_RMatchSummary904" */
  rtcs_db_rmatch_RMatchSummary904_aggregate: Rtcs_Db_Rmatch_RMatchSummary904_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_RMatchSummary916" */
  rtcs_db_rmatch_RMatchSummary916: Array<Rtcs_Db_Rmatch_RMatchSummary916>;
  /** fetch data from the table: "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
  rtcs_db_rmatch_RMatchSummary916ExcessiveOwnerOccupancy: Array<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_RMatchSummary916ExcessiveOwnerOccupancy" */
  rtcs_db_rmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregate: Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregate;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_RMatchSummary916" */
  rtcs_db_rmatch_RMatchSummary916_aggregate: Rtcs_Db_Rmatch_RMatchSummary916_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_RMatchSummary937" */
  rtcs_db_rmatch_RMatchSummary937: Array<Rtcs_Db_Rmatch_RMatchSummary937>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_RMatchSummary937" */
  rtcs_db_rmatch_RMatchSummary937_aggregate: Rtcs_Db_Rmatch_RMatchSummary937_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_TinsURA" */
  rtcs_db_rmatch_TinsURA: Array<Rtcs_Db_Rmatch_TinsUra>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_TinsURA" */
  rtcs_db_rmatch_TinsURA_aggregate: Rtcs_Db_Rmatch_TinsUra_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_Undeclared" */
  rtcs_db_rmatch_Undeclared: Array<Rtcs_Db_Rmatch_Undeclared>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_Undeclared" */
  rtcs_db_rmatch_Undeclared_aggregate: Rtcs_Db_Rmatch_Undeclared_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_Underreported" */
  rtcs_db_rmatch_Underreported: Array<Rtcs_Db_Rmatch_Underreported>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_Underreported" */
  rtcs_db_rmatch_Underreported_aggregate: Rtcs_Db_Rmatch_Underreported_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_Unregistered" */
  rtcs_db_rmatch_Unregistered: Array<Rtcs_Db_Rmatch_Unregistered>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_Unregistered" */
  rtcs_db_rmatch_Unregistered_aggregate: Rtcs_Db_Rmatch_Unregistered_Aggregate;
  /** fetch data from the table: "rtcs_db.rmatch_mlhudConsolidated" */
  rtcs_db_rmatch_mlhudConsolidated: Array<Rtcs_Db_Rmatch_MlhudConsolidated>;
  /** fetch aggregated fields from the table: "rtcs_db.rmatch_mlhudConsolidated" */
  rtcs_db_rmatch_mlhudConsolidated_aggregate: Rtcs_Db_Rmatch_MlhudConsolidated_Aggregate;
  /** fetch data from the table: "rtcs_db.streetmap_OsmMukonoMunicipality" */
  rtcs_db_streetmap_OsmMukonoMunicipality: Array<Rtcs_Db_Streetmap_OsmMukonoMunicipality>;
  /** fetch aggregated fields from the table: "rtcs_db.streetmap_OsmMukonoMunicipality" */
  rtcs_db_streetmap_OsmMukonoMunicipality_aggregate: Rtcs_Db_Streetmap_OsmMukonoMunicipality_Aggregate;
  /** fetch data from the table: "rtcs_db.streetmap_OsmMukonoNakifuma" */
  rtcs_db_streetmap_OsmMukonoNakifuma: Array<Rtcs_Db_Streetmap_OsmMukonoNakifuma>;
  /** fetch aggregated fields from the table: "rtcs_db.streetmap_OsmMukonoNakifuma" */
  rtcs_db_streetmap_OsmMukonoNakifuma_aggregate: Rtcs_Db_Streetmap_OsmMukonoNakifuma_Aggregate;
  /** fetch data from the table: "rtcs_db.streetmap_OsmWakisoEntebbe" */
  rtcs_db_streetmap_OsmWakisoEntebbe: Array<Rtcs_Db_Streetmap_OsmWakisoEntebbe>;
  /** fetch aggregated fields from the table: "rtcs_db.streetmap_OsmWakisoEntebbe" */
  rtcs_db_streetmap_OsmWakisoEntebbe_aggregate: Rtcs_Db_Streetmap_OsmWakisoEntebbe_Aggregate;
  /** fetch data from the table: "rtcs_db.streetmap_OsmWakisoKira" */
  rtcs_db_streetmap_OsmWakisoKira: Array<Rtcs_Db_Streetmap_OsmWakisoKira>;
  /** fetch aggregated fields from the table: "rtcs_db.streetmap_OsmWakisoKira" */
  rtcs_db_streetmap_OsmWakisoKira_aggregate: Rtcs_Db_Streetmap_OsmWakisoKira_Aggregate;
  /** fetch data from the table: "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
  rtcs_db_streetmap_OsmWakisoMakindyeSsabagabo: Array<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo>;
  /** fetch aggregated fields from the table: "rtcs_db.streetmap_OsmWakisoMakindyeSsabagabo" */
  rtcs_db_streetmap_OsmWakisoMakindyeSsabagabo_aggregate: Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Aggregate;
  /** fetch data from the table: "rtcs_db.streetmap_OsmWakisoNansana" */
  rtcs_db_streetmap_OsmWakisoNansana: Array<Rtcs_Db_Streetmap_OsmWakisoNansana>;
  /** fetch aggregated fields from the table: "rtcs_db.streetmap_OsmWakisoNansana" */
  rtcs_db_streetmap_OsmWakisoNansana_aggregate: Rtcs_Db_Streetmap_OsmWakisoNansana_Aggregate;
  /** fetch data from the table: "rtcs_db.ucc_UCC" */
  rtcs_db_ucc_UCC: Array<Rtcs_Db_Ucc_Ucc>;
  /** fetch aggregated fields from the table: "rtcs_db.ucc_UCC" */
  rtcs_db_ucc_UCC_aggregate: Rtcs_Db_Ucc_Ucc_Aggregate;
  /** fetch data from the table: "rtcs_db.umeme_SubscriberAndProperty" */
  rtcs_db_umeme_SubscriberAndProperty: Array<Rtcs_Db_Umeme_SubscriberAndProperty>;
  /** fetch aggregated fields from the table: "rtcs_db.umeme_SubscriberAndProperty" */
  rtcs_db_umeme_SubscriberAndProperty_aggregate: Rtcs_Db_Umeme_SubscriberAndProperty_Aggregate;
  /** fetch data from the table: "rtcs_db.umeme_Transactions" */
  rtcs_db_umeme_Transactions: Array<Rtcs_Db_Umeme_Transactions>;
  /** fetch aggregated fields from the table: "rtcs_db.umeme_Transactions" */
  rtcs_db_umeme_Transactions_aggregate: Rtcs_Db_Umeme_Transactions_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_BalanceSheetInformation" */
  rtcs_db_ura_BalanceSheetInformation: Array<Rtcs_Db_Ura_BalanceSheetInformation>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_BalanceSheetInformation" */
  rtcs_db_ura_BalanceSheetInformation_aggregate: Rtcs_Db_Ura_BalanceSheetInformation_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_CombinedOfficersAndOwners" */
  rtcs_db_ura_CombinedOfficersAndOwners: Array<Rtcs_Db_Ura_CombinedOfficersAndOwners>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_CombinedOfficersAndOwners" */
  rtcs_db_ura_CombinedOfficersAndOwners_aggregate: Rtcs_Db_Ura_CombinedOfficersAndOwners_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_EmploymentIncomeDetail" */
  rtcs_db_ura_EmploymentIncomeDetail: Array<Rtcs_Db_Ura_EmploymentIncomeDetail>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_EmploymentIncomeDetail" */
  rtcs_db_ura_EmploymentIncomeDetail_aggregate: Rtcs_Db_Ura_EmploymentIncomeDetail_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
  rtcs_db_ura_GazetteCommercialRatesPerSquareFootage: Array<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_GazetteCommercialRatesPerSquareFootage" */
  rtcs_db_ura_GazetteCommercialRatesPerSquareFootage_aggregate: Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
  rtcs_db_ura_GazetteResidentialRatesPerBedroom: Array<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_GazetteResidentialRatesPerBedroom" */
  rtcs_db_ura_GazetteResidentialRatesPerBedroom_aggregate: Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_HoldingOfficers" */
  rtcs_db_ura_HoldingOfficers: Array<Rtcs_Db_Ura_HoldingOfficers>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_HoldingOfficers" */
  rtcs_db_ura_HoldingOfficers_aggregate: Rtcs_Db_Ura_HoldingOfficers_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_HoldingOwner" */
  rtcs_db_ura_HoldingOwner: Array<Rtcs_Db_Ura_HoldingOwner>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_HoldingOwner" */
  rtcs_db_ura_HoldingOwner_aggregate: Rtcs_Db_Ura_HoldingOwner_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_IncomeTaxHolding" */
  rtcs_db_ura_IncomeTaxHolding: Array<Rtcs_Db_Ura_IncomeTaxHolding>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_IncomeTaxHolding" */
  rtcs_db_ura_IncomeTaxHolding_aggregate: Rtcs_Db_Ura_IncomeTaxHolding_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_IncomeTaxSummaryIndividual" */
  rtcs_db_ura_IncomeTaxSummaryIndividual: Array<Rtcs_Db_Ura_IncomeTaxSummaryIndividual>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_IncomeTaxSummaryIndividual" */
  rtcs_db_ura_IncomeTaxSummaryIndividual_aggregate: Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
  rtcs_db_ura_IncomeTaxSummaryNonIndividual: Array<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_IncomeTaxSummaryNonIndividual" */
  rtcs_db_ura_IncomeTaxSummaryNonIndividual_aggregate: Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_IndividualMortgage" */
  rtcs_db_ura_IndividualMortgage: Array<Rtcs_Db_Ura_IndividualMortgage>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_IndividualMortgage" */
  rtcs_db_ura_IndividualMortgage_aggregate: Rtcs_Db_Ura_IndividualMortgage_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_IndividualsAssociatedBusiness" */
  rtcs_db_ura_IndividualsAssociatedBusiness: Array<Rtcs_Db_Ura_IndividualsAssociatedBusiness>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_IndividualsAssociatedBusiness" */
  rtcs_db_ura_IndividualsAssociatedBusiness_aggregate: Rtcs_Db_Ura_IndividualsAssociatedBusiness_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_LandlordIncomeDetail" */
  rtcs_db_ura_LandlordIncomeDetail: Array<Rtcs_Db_Ura_LandlordIncomeDetail>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_LandlordIncomeDetail" */
  rtcs_db_ura_LandlordIncomeDetail_aggregate: Rtcs_Db_Ura_LandlordIncomeDetail_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_LandlordIncomeSummary" */
  rtcs_db_ura_LandlordIncomeSummary: Array<Rtcs_Db_Ura_LandlordIncomeSummary>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_LandlordIncomeSummary" */
  rtcs_db_ura_LandlordIncomeSummary_aggregate: Rtcs_Db_Ura_LandlordIncomeSummary_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
  rtcs_db_ura_NonIndividualsAssociatedBusiness: Array<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_NonIndividualsAssociatedBusiness" */
  rtcs_db_ura_NonIndividualsAssociatedBusiness_aggregate: Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_RegAssociates" */
  rtcs_db_ura_RegAssociates: Array<Rtcs_Db_Ura_RegAssociates>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_RegAssociates" */
  rtcs_db_ura_RegAssociates_aggregate: Rtcs_Db_Ura_RegAssociates_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_RegIndividual" */
  rtcs_db_ura_RegIndividual: Array<Rtcs_Db_Ura_RegIndividual>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_RegIndividual" */
  rtcs_db_ura_RegIndividual_aggregate: Rtcs_Db_Ura_RegIndividual_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_RegIndividual_sid" */
  rtcs_db_ura_RegIndividual_sid: Array<Rtcs_Db_Ura_RegIndividual_Sid>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_RegIndividual_sid" */
  rtcs_db_ura_RegIndividual_sid_aggregate: Rtcs_Db_Ura_RegIndividual_Sid_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_RegNonIndividual" */
  rtcs_db_ura_RegNonIndividual: Array<Rtcs_Db_Ura_RegNonIndividual>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_RegNonIndividual" */
  rtcs_db_ura_RegNonIndividual_aggregate: Rtcs_Db_Ura_RegNonIndividual_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_RegNonIndividual_sid" */
  rtcs_db_ura_RegNonIndividual_sid: Array<Rtcs_Db_Ura_RegNonIndividual_Sid>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_RegNonIndividual_sid" */
  rtcs_db_ura_RegNonIndividual_sid_aggregate: Rtcs_Db_Ura_RegNonIndividual_Sid_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_RentExpenseDetails" */
  rtcs_db_ura_RentExpenseDetails: Array<Rtcs_Db_Ura_RentExpenseDetails>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_RentExpenseDetails" */
  rtcs_db_ura_RentExpenseDetails_aggregate: Rtcs_Db_Ura_RentExpenseDetails_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_RentalLandLordIncome" */
  rtcs_db_ura_RentalLandLordIncome: Array<Rtcs_Db_Ura_RentalLandLordIncome>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_RentalLandLordIncome" */
  rtcs_db_ura_RentalLandLordIncome_aggregate: Rtcs_Db_Ura_RentalLandLordIncome_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_RentalTenantPayments" */
  rtcs_db_ura_RentalTenantPayments: Array<Rtcs_Db_Ura_RentalTenantPayments>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_RentalTenantPayments" */
  rtcs_db_ura_RentalTenantPayments_aggregate: Rtcs_Db_Ura_RentalTenantPayments_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_RipoIndRentalIncSummary" */
  rtcs_db_ura_RipoIndRentalIncSummary: Array<Rtcs_Db_Ura_RipoIndRentalIncSummary>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_RipoIndRentalIncSummary" */
  rtcs_db_ura_RipoIndRentalIncSummary_aggregate: Rtcs_Db_Ura_RipoIndRentalIncSummary_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_TenantIncomeDetail" */
  rtcs_db_ura_TenantIncomeDetail: Array<Rtcs_Db_Ura_TenantIncomeDetail>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_TenantIncomeDetail" */
  rtcs_db_ura_TenantIncomeDetail_aggregate: Rtcs_Db_Ura_TenantIncomeDetail_Aggregate;
  /** fetch data from the table: "rtcs_db.ura_Town" */
  rtcs_db_ura_Town: Array<Rtcs_Db_Ura_Town>;
  /** fetch aggregated fields from the table: "rtcs_db.ura_Town" */
  rtcs_db_ura_Town_aggregate: Rtcs_Db_Ura_Town_Aggregate;
  /** fetch data from the table: "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
  rtcs_db_ursb_CompaniesAndBusinessNamesURSB: Array<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb>;
  /** fetch aggregated fields from the table: "rtcs_db.ursb_CompaniesAndBusinessNamesURSB" */
  rtcs_db_ursb_CompaniesAndBusinessNamesURSB_aggregate: Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Aggregate;
};

/** subscription root */
export type Subscription_RootRtcs_Db_EntityFilterArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_EntityFilter_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_EntityFilter_Order_By>>;
  where?: Maybe<Rtcs_Db_EntityFilter_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_EntityFilter_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_EntityFilter_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_EntityFilter_Order_By>>;
  where?: Maybe<Rtcs_Db_EntityFilter_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FilterArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Filter_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Filter_Order_By>>;
  where?: Maybe<Rtcs_Db_Filter_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Filter_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Filter_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Filter_Order_By>>;
  where?: Maybe<Rtcs_Db_Filter_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnEntityFilterArgs = {
  args: Rtcs_Db_FnEntityFilter_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_EntityFilter_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_EntityFilter_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_EntityFilter_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnEntityFilter_AggregateArgs = {
  args: Rtcs_Db_FnEntityFilter_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_EntityFilter_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_EntityFilter_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_EntityFilter_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnEntityFilter_AggregatecmArgs = {
  args: Rtcs_Db_FnEntityFilter_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnEntityFilter_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnEntityFilter_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnEntityFilter_AggregatetxtArgs = {
  args: Rtcs_Db_FnEntityFilter_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnEntityFilter_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnEntityFilter_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnFilterArgs = {
  args: Rtcs_Db_FnFilter_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Filter_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Filter_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Filter_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnFilter_AggregateArgs = {
  args: Rtcs_Db_FnFilter_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Filter_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Filter_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Filter_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnFilter_AggregatecmArgs = {
  args: Rtcs_Db_FnFilter_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnFilter_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_FnFilter_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnFilter_AggregatetxtArgs = {
  args: Rtcs_Db_FnFilter_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnFilter_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_FnFilter_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnGazzetteRatesArgs = {
  args: Rtcs_Db_FnGazzetteRates_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_GazzetteRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_GazzetteRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_GazzetteRates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnGazzetteRates_AggregateArgs = {
  args: Rtcs_Db_FnGazzetteRates_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_GazzetteRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_GazzetteRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_GazzetteRates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnGazzetteRates_AggregatecmArgs = {
  args: Rtcs_Db_FnGazzetteRates_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnGazzetteRates_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnGazzetteRates_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnGazzetteRates_AggregatetxtArgs = {
  args: Rtcs_Db_FnGazzetteRates_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnGazzetteRates_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnGazzetteRates_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_IndividualComplianceSummaryPlusRiskArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualComplianceSummaryPlusRisk_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_IndividualComplianceSummaryPlusRisk_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualComplianceSummaryPlusRisk_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_IndividualComplianceSummaryPlusRisk_AggregatecmArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualComplianceSummaryPlusRisk_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_IndividualComplianceSummaryPlusRisk_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualComplianceSummaryPlusRisk_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_IndividualComplianceSummaryPlusRisk_AggregatetxtArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualComplianceSummaryPlusRisk_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_IndividualComplianceSummaryPlusRisk_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualComplianceSummaryPlusRisk_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_IndividualCompositeArgs = {
  args: Rtcs_Db_FnRpt_IndividualComposite_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_IndividualComposite_AggregateArgs = {
  args: Rtcs_Db_FnRpt_IndividualComposite_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_IndividualComposite_AggregatecmArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualComposite_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_IndividualComposite_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualComposite_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_IndividualComposite_AggregatetxtArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualComposite_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_IndividualComposite_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualComposite_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_IndividualRegistrationArgs = {
  args: Rtcs_Db_FnRpt_IndividualRegistration_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_IndividualRegistration_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualRegistration_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_IndividualRegistration_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualRegistration_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_IndividualRegistration_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualRegistration_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_IndividualRegistration_AggregatecmArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualRegistration_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_IndividualRegistration_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualRegistration_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_IndividualRegistration_AggregatetxtArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualRegistration_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_IndividualRegistration_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualRegistration_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_IndividualTaxSummaryDataArgs = {
  args: Rtcs_Db_FnRpt_IndividualTaxSummaryData_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_IndividualTaxSummaryData_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualTaxSummaryData_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_IndividualTaxSummaryData_AggregatecmArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualTaxSummaryData_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_IndividualTaxSummaryData_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualTaxSummaryData_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_IndividualTaxSummaryData_AggregatetxtArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualTaxSummaryData_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_IndividualTaxSummaryData_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_IndividualTaxSummaryData_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_KccaAffiliatesArgs = {
  args: Rtcs_Db_FnRpt_KccaAffiliates_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaAffiliates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaAffiliates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_KccaAffiliates_AggregateArgs = {
  args: Rtcs_Db_FnRpt_KccaAffiliates_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaAffiliates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaAffiliates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_KccaAffiliates_AggregatecmArgs = {
  args: Rtcs_Db_FnRpt_KccaAffiliates_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_KccaAffiliates_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_KccaAffiliates_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_KccaAffiliates_AggregatetxtArgs = {
  args: Rtcs_Db_FnRpt_KccaAffiliates_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_KccaAffiliates_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_KccaAffiliates_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_KccaCustomerRegistrationDataArgs = {
  args: Rtcs_Db_FnRpt_KccaCustomerRegistrationData_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_KccaCustomerRegistrationData_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_KccaCustomerRegistrationData_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_KccaCustomerRegistrationData_AggregatecmArgs =
  {
    args: Rtcs_Db_FnRpt_KccaCustomerRegistrationData_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_KccaCustomerRegistrationData_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_KccaCustomerRegistrationData_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_KccaCustomerRegistrationData_AggregatetxtArgs =
  {
    args: Rtcs_Db_FnRpt_KccaCustomerRegistrationData_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_KccaCustomerRegistrationData_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_KccaCustomerRegistrationData_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_KccaPropertyRegistrationArgs = {
  args: Rtcs_Db_FnRpt_KccaPropertyRegistration_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_KccaPropertyRegistrationDataArgs = {
  args: Rtcs_Db_FnRpt_KccaPropertyRegistrationData_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_KccaPropertyRegistrationData_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_KccaPropertyRegistrationData_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_KccaPropertyRegistrationData_AggregatecmArgs =
  {
    args: Rtcs_Db_FnRpt_KccaPropertyRegistrationData_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_KccaPropertyRegistrationData_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_KccaPropertyRegistrationData_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_KccaPropertyRegistrationData_AggregatetxtArgs =
  {
    args: Rtcs_Db_FnRpt_KccaPropertyRegistrationData_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_KccaPropertyRegistrationData_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_KccaPropertyRegistrationData_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_KccaPropertyRegistration_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_KccaPropertyRegistration_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_KccaPropertyRegistration_AggregatecmArgs =
  {
    args: Rtcs_Db_FnRpt_KccaPropertyRegistration_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_KccaPropertyRegistration_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_KccaPropertyRegistration_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_KccaPropertyRegistration_AggregatetxtArgs =
  {
    args: Rtcs_Db_FnRpt_KccaPropertyRegistration_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_KccaPropertyRegistration_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_KccaPropertyRegistration_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_NonIndividualBalanceSheetArgs = {
  args: Rtcs_Db_FnRpt_NonIndividualBalanceSheet_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_NonIndividualBalanceSheet_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualBalanceSheet_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_NonIndividualBalanceSheet_AggregatecmArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualBalanceSheet_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_NonIndividualBalanceSheet_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualBalanceSheet_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_NonIndividualBalanceSheet_AggregatetxtArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualBalanceSheet_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_NonIndividualBalanceSheet_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualBalanceSheet_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRiskArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRisk_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRisk_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRisk_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRisk_AggregatecmArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRisk_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRisk_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRisk_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRisk_AggregatetxtArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRisk_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRisk_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualComplianceSummaryPlusRisk_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_NonIndividualCompositeArgs = {
  args: Rtcs_Db_FnRpt_NonIndividualComposite_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_NonIndividualComposite_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualComposite_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_NonIndividualComposite_AggregatecmArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualComposite_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_NonIndividualComposite_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualComposite_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_NonIndividualComposite_AggregatetxtArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualComposite_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_NonIndividualComposite_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualComposite_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_NonIndividualTaxSummaryDataArgs = {
  args: Rtcs_Db_FnRpt_NonIndividualTaxSummaryData_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_NonIndividualTaxSummaryData_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualTaxSummaryData_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_NonIndividualTaxSummaryData_AggregatecmArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualTaxSummaryData_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_NonIndividualTaxSummaryData_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualTaxSummaryData_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_NonIndividualTaxSummaryData_AggregatetxtArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualTaxSummaryData_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_NonIndividualTaxSummaryData_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_NonIndividualTaxSummaryData_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_PropertiesCompositeArgs = {
  args: Rtcs_Db_FnRpt_PropertiesComposite_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertiesComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertiesComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_PropertiesComposite_AggregateArgs = {
  args: Rtcs_Db_FnRpt_PropertiesComposite_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertiesComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertiesComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_PropertiesComposite_AggregatecmArgs =
  {
    args: Rtcs_Db_FnRpt_PropertiesComposite_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_PropertiesComposite_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_PropertiesComposite_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_PropertiesComposite_AggregatetxtArgs =
  {
    args: Rtcs_Db_FnRpt_PropertiesComposite_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_PropertiesComposite_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_PropertiesComposite_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_PropertyValuationArgs = {
  args: Rtcs_Db_FnRpt_PropertyValuation_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertyValuation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertyValuation_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_PropertyValuation_AggregateArgs = {
  args: Rtcs_Db_FnRpt_PropertyValuation_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertyValuation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertyValuation_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_PropertyValuation_AggregatecmArgs = {
  args: Rtcs_Db_FnRpt_PropertyValuation_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_PropertyValuation_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_PropertyValuation_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_PropertyValuation_AggregatetxtArgs =
  {
    args: Rtcs_Db_FnRpt_PropertyValuation_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_PropertyValuation_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_PropertyValuation_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_RentalTenantPaymentsArgs = {
  args: Rtcs_Db_FnRpt_RentalTenantPayments_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_RentalTenantPayments_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_RentalTenantPayments_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_RentalTenantPayments_AggregatecmArgs =
  {
    args: Rtcs_Db_FnRpt_RentalTenantPayments_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_RentalTenantPayments_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_RentalTenantPayments_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_RentalTenantPayments_AggregatetxtArgs =
  {
    args: Rtcs_Db_FnRpt_RentalTenantPayments_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_FnRpt_RentalTenantPayments_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_FnRpt_RentalTenantPayments_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fngazette_CommercialRatesArgs = {
  args: Rtcs_Db_Fngazette_CommercialRates_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Gazette_CommercialRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Gazette_CommercialRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fngazette_CommercialRates_AggregateArgs = {
  args: Rtcs_Db_Fngazette_CommercialRates_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Gazette_CommercialRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Gazette_CommercialRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fngazette_CommercialRates_AggregatecmArgs =
  {
    args: Rtcs_Db_Fngazette_CommercialRates_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fngazette_CommercialRates_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fngazette_CommercialRates_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fngazette_CommercialRates_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fngazette_CommercialRates_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fngazette_CommercialRates_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fngazette_CommercialRates_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fngazette_ResidentialRatesArgs = {
  args: Rtcs_Db_Fngazette_ResidentialRates_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Gazette_ResidentialRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Gazette_ResidentialRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fngazette_ResidentialRates_AggregateArgs =
  {
    args: Rtcs_Db_Fngazette_ResidentialRates_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Gazette_ResidentialRates_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Gazette_ResidentialRates_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fngazette_ResidentialRates_AggregatecmArgs =
  {
    args: Rtcs_Db_Fngazette_ResidentialRates_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fngazette_ResidentialRates_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fngazette_ResidentialRates_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fngazette_ResidentialRates_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fngazette_ResidentialRates_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fngazette_ResidentialRates_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fngazette_ResidentialRates_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_AssociateTypeArgs = {
  args: Rtcs_Db_Fnkcca_AssociateType_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_AssociateType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_AssociateType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_AssociateType_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_AssociateType_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_AssociateType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_AssociateType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_AssociateType_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_AssociateType_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_AssociateType_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_AssociateType_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_AssociateType_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_AssociateType_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_AssociateType_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_AssociateType_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CommercialPropertiesArgs = {
  args: Rtcs_Db_Fnkcca_CommercialProperties_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CommercialProperties_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CommercialProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CommercialProperties_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CommercialProperties_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CommercialProperties_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CommercialProperties_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CommercialProperties_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnkcca_CommercialProperties_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CommercialProperties_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CommercialProperties_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CommercialProperties_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnkcca_CommercialProperties_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CommercialProperties_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CommercialProperties_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CountyArgs = {
  args: Rtcs_Db_Fnkcca_County_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_County_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_County_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_County_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_County_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_County_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_County_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_County_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_County_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_County_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_County_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_County_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_County_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_County_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_County_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_County_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_County_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerArgs = {
  args: Rtcs_Db_Fnkcca_Customer_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerApplicantTypeArgs = {
  args: Rtcs_Db_Fnkcca_CustomerApplicantType_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerApplicantType_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerApplicantType_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerApplicantType_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerApplicantType_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerApplicantType_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerApplicantType_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerApplicantType_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerApplicantType_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerApplicantType_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerApplicantType_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerBusinessAssociateArgs = {
  args: Rtcs_Db_Fnkcca_CustomerBusinessAssociate_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerBusinessAssociate_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerBusinessAssociate_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerBusinessAssociate_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerBusinessAssociate_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerBusinessAssociate_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerBusinessAssociate_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerBusinessAssociate_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerBusinessAssociate_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerBusinessAssociate_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerBusinessAssociate_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerBusinessTypeArgs = {
  args: Rtcs_Db_Fnkcca_CustomerBusinessType_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerBusinessType_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerBusinessType_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerBusinessType_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerBusinessType_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerBusinessType_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerBusinessType_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerBusinessType_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerBusinessType_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerBusinessType_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerBusinessType_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerCoinDeactivateReactiveArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerCoinDeactivateReactive_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerCoinDeactivateReactive_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerCoinDeactivateReactive_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerCoinDeactivateReactive_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerCoinDeactivateReactive_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerCoinDeactivateReactive_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerCoinDeactivateReactive_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerCoinDeactivateReactive_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerCoinDeactivateReactive_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerCoinDeactivateReactive_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerCoinDeactivateReactive_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerContactPersonArgs = {
  args: Rtcs_Db_Fnkcca_CustomerContactPerson_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerContactPerson_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerContactPerson_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerContactPerson_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerContactPerson_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerContactPerson_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerContactPerson_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerContactPerson_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerContactPerson_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerContactPerson_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerContactPerson_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerDocumentItemArgs = {
  args: Rtcs_Db_Fnkcca_CustomerDocumentItem_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerDocumentItem_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerDocumentItem_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerDocumentItem_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerDocumentItem_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerDocumentItem_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerDocumentItem_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerDocumentItem_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerDocumentItem_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerDocumentItem_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerDocumentItem_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerGuardianArgs = {
  args: Rtcs_Db_Fnkcca_CustomerGuardian_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerGuardian_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerGuardian_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerGuardian_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_CustomerGuardian_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerGuardian_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerGuardian_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerGuardian_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_CustomerGuardian_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerGuardian_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerGuardian_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerGuardian_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerGuardian_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerGuardian_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerGuardian_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerReferenceArgs = {
  args: Rtcs_Db_Fnkcca_CustomerReference_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerReference_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerReference_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerReference_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_CustomerReference_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerReference_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerReference_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerReference_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerReference_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerReference_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerReference_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerReference_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerReference_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerReference_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerReference_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerRegistrationReasonArgs = {
  args: Rtcs_Db_Fnkcca_CustomerRegistrationReason_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerRegistrationReason_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerRegistrationReason_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerRegistrationReason_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerRegistrationReason_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerRegistrationReason_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerRegistrationReason_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerRegistrationReason_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerRegistrationReason_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerRegistrationReason_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerRegistrationReason_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerRevenueAgentArgs = {
  args: Rtcs_Db_Fnkcca_CustomerRevenueAgent_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerRevenueAgent_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerRevenueAgent_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerRevenueAgent_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerRevenueAgent_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerRevenueAgent_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerRevenueAgent_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerRevenueAgent_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerRevenueAgent_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerRevenueAgent_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerRevenueAgent_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerTypeArgs = {
  args: Rtcs_Db_Fnkcca_CustomerType_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerType_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_CustomerType_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerType_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_CustomerType_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerType_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerType_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerType_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_CustomerType_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_CustomerType_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_CustomerType_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Customer_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Customer_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Customer_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_Customer_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Customer_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_Customer_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Customer_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_Customer_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Customer_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_Customer_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Customer_SidArgs = {
  args: Rtcs_Db_Fnkcca_Customer_Sid_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Customer_Sid_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Customer_Sid_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Customer_Sid_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_Customer_Sid_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Customer_Sid_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_Customer_Sid_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Customer_Sid_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_Customer_Sid_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Customer_Sid_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_Customer_Sid_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_DistrictArgs = {
  args: Rtcs_Db_Fnkcca_District_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_District_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_District_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_District_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_District_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_District_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_District_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_District_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_District_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_District_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_District_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_District_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_District_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_District_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_District_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_District_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_District_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_DivisionArgs = {
  args: Rtcs_Db_Fnkcca_Division_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Division_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Division_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Division_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Division_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Division_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Division_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Division_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Division_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Division_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_Division_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Division_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_Division_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Division_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_Division_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Division_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_Division_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_ImgsDataArgs = {
  args: Rtcs_Db_Fnkcca_ImgsData_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_ImgsData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_ImgsData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_ImgsData_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_ImgsData_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_ImgsData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_ImgsData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_ImgsData_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_ImgsData_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_ImgsData_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_ImgsData_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_ImgsData_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_ImgsData_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_ImgsData_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_ImgsData_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_ParishArgs = {
  args: Rtcs_Db_Fnkcca_Parish_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Parish_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Parish_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Parish_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Parish_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Parish_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Parish_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Parish_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_Parish_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Parish_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Parish_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Parish_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_Parish_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Parish_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_Parish_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PaymentDetailsArgs = {
  args: Rtcs_Db_Fnkcca_PaymentDetails_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PaymentDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PaymentDetails_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PaymentDetails_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_PaymentDetails_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PaymentDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PaymentDetails_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PaymentDetails_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_PaymentDetails_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PaymentDetails_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PaymentDetails_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PaymentDetails_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_PaymentDetails_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PaymentDetails_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PaymentDetails_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PaymentsArgs = {
  args: Rtcs_Db_Fnkcca_Payments_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Payments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Payments_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Payments_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Payments_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Payments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Payments_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Payments_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_Payments_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Payments_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_Payments_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Payments_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_Payments_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Payments_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_Payments_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertyArgs = {
  args: Rtcs_Db_Fnkcca_Property_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Property_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Property_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Property_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertyFloorsArgs = {
  args: Rtcs_Db_Fnkcca_PropertyFloors_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyFloors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyFloors_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertyFloors_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_PropertyFloors_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyFloors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyFloors_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertyFloors_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_PropertyFloors_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertyFloors_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertyFloors_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertyFloors_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_PropertyFloors_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertyFloors_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertyFloors_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertyNumberBedroomsArgs = {
  args: Rtcs_Db_Fnkcca_PropertyNumberBedrooms_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertyNumberBedrooms_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertyNumberBedrooms_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertyNumberBedrooms_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertyNumberBedrooms_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertyNumberBedrooms_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertyNumberBedrooms_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertyNumberBedrooms_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertyNumberBedrooms_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertyNumberBedrooms_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertyNumberBedrooms_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertyRentedStatusArgs = {
  args: Rtcs_Db_Fnkcca_PropertyRentedStatus_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertyRentedStatus_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertyRentedStatus_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertyRentedStatus_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertyRentedStatus_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertyRentedStatus_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertyRentedStatus_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertyRentedStatus_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertyRentedStatus_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertyRentedStatus_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertyRentedStatus_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertySecurityArgs = {
  args: Rtcs_Db_Fnkcca_PropertySecurity_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySecurity_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySecurity_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertySecurity_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_PropertySecurity_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySecurity_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySecurity_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertySecurity_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_PropertySecurity_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertySecurity_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertySecurity_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertySecurity_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertySecurity_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertySecurity_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertySecurity_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertySubTypeArgs = {
  args: Rtcs_Db_Fnkcca_PropertySubType_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySubType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySubType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertySubType_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_PropertySubType_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySubType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySubType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertySubType_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_PropertySubType_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertySubType_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertySubType_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertySubType_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_PropertySubType_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertySubType_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertySubType_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertyTypeArgs = {
  args: Rtcs_Db_Fnkcca_PropertyType_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertyType_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_PropertyType_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertyType_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_PropertyType_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertyType_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertyType_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertyType_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_PropertyType_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_PropertyType_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_PropertyType_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Property_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Property_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Property_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Property_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Property_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Property_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_Property_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Property_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_Property_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Property_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_Property_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Property_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_Property_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_RawProperties1Args = {
  args: Rtcs_Db_Fnkcca_RawProperties1_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties1_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties1_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_RawProperties1_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_RawProperties1_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties1_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties1_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_RawProperties1_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_RawProperties1_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_RawProperties1_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_RawProperties1_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_RawProperties1_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_RawProperties1_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_RawProperties1_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_RawProperties1_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_RawProperties2Args = {
  args: Rtcs_Db_Fnkcca_RawProperties2_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties2_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties2_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_RawProperties2_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_RawProperties2_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties2_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties2_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_RawProperties2_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_RawProperties2_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_RawProperties2_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_RawProperties2_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_RawProperties2_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_RawProperties2_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_RawProperties2_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_RawProperties2_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_ResidentialPropertiesArgs = {
  args: Rtcs_Db_Fnkcca_ResidentialProperties_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_ResidentialProperties_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_ResidentialProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_ResidentialProperties_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_ResidentialProperties_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_ResidentialProperties_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_ResidentialProperties_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_ResidentialProperties_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnkcca_ResidentialProperties_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_ResidentialProperties_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_ResidentialProperties_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_ResidentialProperties_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnkcca_ResidentialProperties_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_ResidentialProperties_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_ResidentialProperties_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_RevenueTypeArgs = {
  args: Rtcs_Db_Fnkcca_RevenueType_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_RevenueType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_RevenueType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_RevenueType_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_RevenueType_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_RevenueType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_RevenueType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_RevenueType_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_RevenueType_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_RevenueType_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_RevenueType_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_RevenueType_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_RevenueType_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_RevenueType_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_RevenueType_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_SubCountyArgs = {
  args: Rtcs_Db_Fnkcca_SubCounty_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_SubCounty_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_SubCounty_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_SubCounty_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_SubCounty_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_SubCounty_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_SubCounty_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_SubCounty_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_SubCounty_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_SubCounty_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_SubCounty_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_SubCounty_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_SubCounty_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_SubCounty_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_SubCounty_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_SubpropertyTypeConversionArgs = {
  args: Rtcs_Db_Fnkcca_SubpropertyTypeConversion_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_SubpropertyTypeConversion_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_SubpropertyTypeConversion_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_SubpropertyTypeConversion_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnkcca_SubpropertyTypeConversion_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_SubpropertyTypeConversion_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_SubpropertyTypeConversion_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_SubpropertyTypeConversion_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnkcca_SubpropertyTypeConversion_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_SubpropertyTypeConversion_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_SubpropertyTypeConversion_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_TradingLicenseArgs = {
  args: Rtcs_Db_Fnkcca_TradingLicense_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_TradingLicense_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_TradingLicense_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_TradingLicense_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_TradingLicense_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_TradingLicense_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_TradingLicense_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_TradingLicense_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_TradingLicense_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_TradingLicense_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_TradingLicense_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_TradingLicense_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_TradingLicense_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_TradingLicense_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_TradingLicense_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_VillageArgs = {
  args: Rtcs_Db_Fnkcca_Village_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Village_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Village_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Village_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Village_AggregateArgs = {
  args: Rtcs_Db_Fnkcca_Village_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Village_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Village_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Village_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Village_AggregatecmArgs = {
  args: Rtcs_Db_Fnkcca_Village_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Village_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_Village_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Village_AggregatetxtArgs = {
  args: Rtcs_Db_Fnkcca_Village_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnkcca_Village_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnkcca_Village_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmaster_MasterIndivArgs = {
  args: Rtcs_Db_Fnmaster_MasterIndiv_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Master_MasterIndiv_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Master_MasterIndiv_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmaster_MasterIndiv_AggregateArgs = {
  args: Rtcs_Db_Fnmaster_MasterIndiv_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Master_MasterIndiv_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Master_MasterIndiv_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmaster_MasterIndiv_AggregatecmArgs = {
  args: Rtcs_Db_Fnmaster_MasterIndiv_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmaster_MasterIndiv_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnmaster_MasterIndiv_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmaster_MasterIndiv_AggregatetxtArgs = {
  args: Rtcs_Db_Fnmaster_MasterIndiv_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmaster_MasterIndiv_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnmaster_MasterIndiv_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_KccaArgs = {
  args: Rtcs_Db_Fnmlhud_Kcca_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Kcca_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Kcca_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_Kcca_AggregateArgs = {
  args: Rtcs_Db_Fnmlhud_Kcca_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Kcca_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Kcca_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_Kcca_AggregatecmArgs = {
  args: Rtcs_Db_Fnmlhud_Kcca_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_Kcca_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnmlhud_Kcca_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_Kcca_AggregatetxtArgs = {
  args: Rtcs_Db_Fnmlhud_Kcca_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_Kcca_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnmlhud_Kcca_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_MukonoArgs = {
  args: Rtcs_Db_Fnmlhud_Mukono_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Mukono_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Mukono_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_Mukono_AggregateArgs = {
  args: Rtcs_Db_Fnmlhud_Mukono_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Mukono_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Mukono_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_Mukono_AggregatecmArgs = {
  args: Rtcs_Db_Fnmlhud_Mukono_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_Mukono_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnmlhud_Mukono_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_Mukono_AggregatetxtArgs = {
  args: Rtcs_Db_Fnmlhud_Mukono_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_Mukono_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnmlhud_Mukono_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_PropertiesArgs = {
  args: Rtcs_Db_Fnmlhud_Properties_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Properties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Properties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_Properties_AggregateArgs = {
  args: Rtcs_Db_Fnmlhud_Properties_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Properties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Properties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_Properties_AggregatecmArgs = {
  args: Rtcs_Db_Fnmlhud_Properties_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_Properties_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnmlhud_Properties_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_Properties_AggregatetxtArgs = {
  args: Rtcs_Db_Fnmlhud_Properties_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_Properties_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnmlhud_Properties_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_WakisoBusiroArgs = {
  args: Rtcs_Db_Fnmlhud_WakisoBusiro_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_WakisoBusiro_AggregateArgs = {
  args: Rtcs_Db_Fnmlhud_WakisoBusiro_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_WakisoBusiro_AggregatecmArgs = {
  args: Rtcs_Db_Fnmlhud_WakisoBusiro_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_WakisoBusiro_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnmlhud_WakisoBusiro_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_WakisoBusiro_AggregatetxtArgs = {
  args: Rtcs_Db_Fnmlhud_WakisoBusiro_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_WakisoBusiro_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnmlhud_WakisoBusiro_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_WakisoKyadondoArgs = {
  args: Rtcs_Db_Fnmlhud_WakisoKyadondo_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_WakisoKyadondo_AggregateArgs = {
  args: Rtcs_Db_Fnmlhud_WakisoKyadondo_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_WakisoKyadondo_AggregatecmArgs = {
  args: Rtcs_Db_Fnmlhud_WakisoKyadondo_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_WakisoKyadondo_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnmlhud_WakisoKyadondo_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_WakisoKyadondo_AggregatetxtArgs = {
  args: Rtcs_Db_Fnmlhud_WakisoKyadondo_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnmlhud_WakisoKyadondo_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnmlhud_WakisoKyadondo_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnira_NiraArgs = {
  args: Rtcs_Db_Fnnira_Nira_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nira_Nira_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nira_Nira_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nira_Nira_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnira_Nira_AggregateArgs = {
  args: Rtcs_Db_Fnnira_Nira_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nira_Nira_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nira_Nira_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nira_Nira_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnira_Nira_AggregatecmArgs = {
  args: Rtcs_Db_Fnnira_Nira_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnira_Nira_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnnira_Nira_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnira_Nira_AggregatetxtArgs = {
  args: Rtcs_Db_Fnnira_Nira_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnira_Nira_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnnira_Nira_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnira_RawDataArgs = {
  args: Rtcs_Db_Fnnira_RawData_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nira_RawData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nira_RawData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nira_RawData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnira_RawData_AggregateArgs = {
  args: Rtcs_Db_Fnnira_RawData_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nira_RawData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nira_RawData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nira_RawData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnira_RawData_AggregatecmArgs = {
  args: Rtcs_Db_Fnnira_RawData_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnira_RawData_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnnira_RawData_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnira_RawData_AggregatetxtArgs = {
  args: Rtcs_Db_Fnnira_RawData_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnira_RawData_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnnira_RawData_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_CustomersArgs = {
  args: Rtcs_Db_Fnnwsc_Customers_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Customers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Customers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_Customers_AggregateArgs = {
  args: Rtcs_Db_Fnnwsc_Customers_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Customers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Customers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_Customers_AggregatecmArgs = {
  args: Rtcs_Db_Fnnwsc_Customers_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_Customers_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnnwsc_Customers_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_Customers_AggregatetxtArgs = {
  args: Rtcs_Db_Fnnwsc_Customers_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_Customers_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnnwsc_Customers_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_NwCustomersArgs = {
  args: Rtcs_Db_Fnnwsc_NwCustomers_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_NwCustomers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_NwCustomers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_NwCustomers_AggregateArgs = {
  args: Rtcs_Db_Fnnwsc_NwCustomers_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_NwCustomers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_NwCustomers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_NwCustomers_AggregatecmArgs = {
  args: Rtcs_Db_Fnnwsc_NwCustomers_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_NwCustomers_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnnwsc_NwCustomers_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_NwCustomers_AggregatetxtArgs = {
  args: Rtcs_Db_Fnnwsc_NwCustomers_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_NwCustomers_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnnwsc_NwCustomers_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_Rpt_TransactionsArgs = {
  args: Rtcs_Db_Fnnwsc_Rpt_Transactions_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_Rpt_Transactions_AggregateArgs = {
  args: Rtcs_Db_Fnnwsc_Rpt_Transactions_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_Rpt_Transactions_AggregatecmArgs = {
  args: Rtcs_Db_Fnnwsc_Rpt_Transactions_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_Rpt_Transactions_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnnwsc_Rpt_Transactions_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_Rpt_Transactions_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnnwsc_Rpt_Transactions_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_Rpt_Transactions_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnnwsc_Rpt_Transactions_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_TransactionsArgs = {
  args: Rtcs_Db_Fnnwsc_Transactions_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_Transactions_AggregateArgs = {
  args: Rtcs_Db_Fnnwsc_Transactions_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_Transactions_AggregatecmArgs = {
  args: Rtcs_Db_Fnnwsc_Transactions_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_Transactions_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnnwsc_Transactions_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_Transactions_AggregatetxtArgs = {
  args: Rtcs_Db_Fnnwsc_Transactions_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_Transactions_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnnwsc_Transactions_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_UraTransactionsArgs = {
  args: Rtcs_Db_Fnnwsc_UraTransactions_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_UraTransactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_UraTransactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_UraTransactions_AggregateArgs = {
  args: Rtcs_Db_Fnnwsc_UraTransactions_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_UraTransactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_UraTransactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_UraTransactions_AggregatecmArgs = {
  args: Rtcs_Db_Fnnwsc_UraTransactions_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_UraTransactions_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnnwsc_UraTransactions_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_UraTransactions_AggregatetxtArgs = {
  args: Rtcs_Db_Fnnwsc_UraTransactions_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnnwsc_UraTransactions_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnnwsc_UraTransactions_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RptUnittypesArgs = {
  args: Rtcs_Db_Fnrcapture_RptUnittypes_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RptUnittypes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RptUnittypes_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RptUnittypes_AggregateArgs = {
  args: Rtcs_Db_Fnrcapture_RptUnittypes_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RptUnittypes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RptUnittypes_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RptUnittypes_AggregatecmArgs = {
  args: Rtcs_Db_Fnrcapture_RptUnittypes_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RptUnittypes_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrcapture_RptUnittypes_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RptUnittypes_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrcapture_RptUnittypes_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RptUnittypes_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrcapture_RptUnittypes_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RpTlessorsArgs = {
  args: Rtcs_Db_Fnrcapture_RpTlessors_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTlessors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTlessors_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RpTlessors_AggregateArgs = {
  args: Rtcs_Db_Fnrcapture_RpTlessors_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTlessors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTlessors_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RpTlessors_AggregatecmArgs = {
  args: Rtcs_Db_Fnrcapture_RpTlessors_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RpTlessors_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrcapture_RpTlessors_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RpTlessors_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrcapture_RpTlessors_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RpTlessors_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrcapture_RpTlessors_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RpTmetaArgs = {
  args: Rtcs_Db_Fnrcapture_RpTmeta_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTmeta_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTmeta_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RpTmeta_AggregateArgs = {
  args: Rtcs_Db_Fnrcapture_RpTmeta_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTmeta_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTmeta_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RpTmeta_AggregatecmArgs = {
  args: Rtcs_Db_Fnrcapture_RpTmeta_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RpTmeta_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrcapture_RpTmeta_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RpTmeta_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrcapture_RpTmeta_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RpTmeta_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrcapture_RpTmeta_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RpTpropertiesArgs = {
  args: Rtcs_Db_Fnrcapture_RpTproperties_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTproperties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTproperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RpTproperties_AggregateArgs = {
  args: Rtcs_Db_Fnrcapture_RpTproperties_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTproperties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTproperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RpTproperties_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrcapture_RpTproperties_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RpTproperties_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrcapture_RpTproperties_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RpTproperties_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrcapture_RpTproperties_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RpTproperties_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrcapture_RpTproperties_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RpTtenantsArgs = {
  args: Rtcs_Db_Fnrcapture_RpTtenants_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTtenants_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTtenants_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RpTtenants_AggregateArgs = {
  args: Rtcs_Db_Fnrcapture_RpTtenants_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTtenants_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTtenants_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RpTtenants_AggregatecmArgs = {
  args: Rtcs_Db_Fnrcapture_RpTtenants_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RpTtenants_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrcapture_RpTtenants_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RpTtenants_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrcapture_RpTtenants_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrcapture_RpTtenants_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrcapture_RpTtenants_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualArgs = {
  args: Rtcs_Db_Fnrmatch_Individual_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Individual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Individual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualComplianceDetailReportArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualComplianceDetailReport_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualComplianceDetailReport_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualComplianceDetailReport_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualComplianceDetailReport_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualComplianceDetailReport_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualComplianceDetailReport_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualComplianceDetailReport_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualComplianceDetailReport_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualComplianceDetailReport_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualComplianceDetailReport_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualComplianceDetailReport_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualComplianceSummaryReportArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualComplianceSummaryReport_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualComplianceSummaryReport_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualComplianceSummaryReport_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualComplianceSummaryReport_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualComplianceSummaryReport_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualComplianceSummaryReport_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualComplianceSummaryReport_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualComplianceSummaryReport_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualComplianceSummaryReport_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualComplianceSummaryReport_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualComplianceSummaryReport_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualRiskScoreArgs = {
  args: Rtcs_Db_Fnrmatch_IndividualRiskScore_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualRiskScore_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualRiskScore_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualRiskScore_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualRiskScore_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualRiskScore_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualRiskScore_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualRiskScore_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualRiskScore_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualRiskScore_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualRiskScore_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_Individual_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_Individual_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Individual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Individual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_Individual_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_Individual_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_Individual_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_Individual_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_Individual_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_Individual_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_Individual_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_Individual_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualsNetworkArgs = {
  args: Rtcs_Db_Fnrmatch_IndividualsNetwork_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualsNetworkTestingArgs = {
  args: Rtcs_Db_Fnrmatch_IndividualsNetworkTesting_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualsNetworkTesting_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualsNetworkTesting_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualsNetworkTesting_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualsNetworkTesting_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualsNetworkTesting_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualsNetworkTesting_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualsNetworkTesting_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualsNetworkTesting_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualsNetworkTesting_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualsNetworkTesting_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualsNetwork_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualsNetwork_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualsNetwork_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualsNetwork_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualsNetwork_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualsNetwork_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualsNetwork_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualsNetwork_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_IndividualsNetwork_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_IndividualsNetwork_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_KccaToKccaIndividualOwnersArgs = {
  args: Rtcs_Db_Fnrmatch_KccaToKccaIndividualOwners_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_KccaToKccaIndividualOwners_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_KccaToKccaIndividualOwners_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_KccaToKccaIndividualOwners_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_KccaToKccaIndividualOwners_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_KccaToKccaIndividualOwners_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_KccaToKccaIndividualOwners_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_KccaToKccaIndividualOwners_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_KccaToKccaIndividualOwners_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_KccaToKccaIndividualOwners_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_KccaToKccaIndividualOwners_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_KccaToKccaNonindividualOwnersArgs =
  {
    args: Rtcs_Db_Fnrmatch_KccaToKccaNonindividualOwners_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_KccaToKccaNonindividualOwners_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_KccaToKccaNonindividualOwners_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_KccaToKccaNonindividualOwners_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_KccaToKccaNonindividualOwners_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_KccaToKccaNonindividualOwners_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_KccaToKccaNonindividualOwners_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_KccaToKccaNonindividualOwners_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_KccaToKccaNonindividualOwners_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_KccaToKccaNonindividualOwners_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_KccaToKccaNonindividualOwners_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_MatchIndividualsPropertiesArgs = {
  args: Rtcs_Db_Fnrmatch_MatchIndividualsProperties_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_MatchIndividualsProperties_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_MatchIndividualsProperties_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_MatchIndividualsProperties_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_MatchIndividualsProperties_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_MatchIndividualsProperties_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_MatchIndividualsProperties_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_MatchIndividualsProperties_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_MatchIndividualsProperties_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_MatchIndividualsProperties_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_MatchIndividualsProperties_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_MatchIndividualsPropsArgs = {
  args: Rtcs_Db_Fnrmatch_MatchIndividualsProps_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_MatchIndividualsProps_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_MatchIndividualsProps_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_MatchIndividualsProps_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_MatchIndividualsProps_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_MatchIndividualsProps_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_MatchIndividualsProps_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_MatchIndividualsProps_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_MatchIndividualsProps_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_MatchIndividualsProps_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_MatchIndividualsProps_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NoReturnsArgs = {
  args: Rtcs_Db_Fnrmatch_NoReturns_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NoReturns_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NoReturns_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NoReturns_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_NoReturns_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NoReturns_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NoReturns_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NoReturns_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_NoReturns_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NoReturns_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NoReturns_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NoReturns_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_NoReturns_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NoReturns_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NoReturns_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualArgs = {
  args: Rtcs_Db_Fnrmatch_NonIndividual_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualComplianceDetailReportArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualComplianceDetailReport_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualComplianceDetailReport_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualComplianceDetailReport_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualComplianceDetailReport_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualComplianceDetailReport_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualComplianceDetailReport_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualComplianceDetailReport_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualComplianceDetailReport_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualComplianceDetailReport_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualComplianceDetailReport_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualComplianceDetailReport_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReportArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReport_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReport_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReport_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReport_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReport_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReport_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReport_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReport_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReport_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReport_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualComplianceSummaryReport_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualRiskScoreArgs = {
  args: Rtcs_Db_Fnrmatch_NonIndividualRiskScore_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualRiskScore_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualRiskScore_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualRiskScore_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualRiskScore_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualRiskScore_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualRiskScore_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualRiskScore_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualRiskScore_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualRiskScore_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualRiskScore_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividual_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_NonIndividual_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividual_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_NonIndividual_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividual_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividual_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividual_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_NonIndividual_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividual_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividual_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualsArgs = {
  args: Rtcs_Db_Fnrmatch_NonIndividuals_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividuals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividuals_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualsNetworkArgs = {
  args: Rtcs_Db_Fnrmatch_NonIndividualsNetwork_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualsNetwork_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualsNetwork_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualsNetwork_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualsNetwork_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualsNetwork_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualsNetwork_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualsNetwork_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualsNetwork_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividualsNetwork_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividualsNetwork_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividuals_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_NonIndividuals_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividuals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividuals_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividuals_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_NonIndividuals_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividuals_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividuals_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividuals_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividuals_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonIndividuals_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonIndividuals_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonindividualOwnersArgs = {
  args: Rtcs_Db_Fnrmatch_NonindividualOwners_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonindividualOwners_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonindividualOwners_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonindividualOwners_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonindividualOwners_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonindividualOwners_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonindividualOwners_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonindividualOwners_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonindividualOwners_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonindividualOwners_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_NonindividualOwners_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_NonindividualsArgs = {
  args: Rtcs_Db_Fnrmatch_Nonindividuals_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Nonindividuals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Nonindividuals_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Nonindividuals_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_Nonindividuals_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_Nonindividuals_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Nonindividuals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Nonindividuals_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Nonindividuals_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_Nonindividuals_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_Nonindividuals_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_Nonindividuals_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_Nonindividuals_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_Nonindividuals_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_Nonindividuals_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_Nonindividuals_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_Nonindividuals_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_PropertiesCompositeArgs = {
  args: Rtcs_Db_Fnrmatch_PropertiesComposite_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_PropertiesComposite_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_PropertiesComposite_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_PropertiesComposite_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_PropertiesComposite_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_PropertiesComposite_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_PropertiesComposite_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_PropertiesComposite_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_PropertiesComposite_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_PropertiesComposite_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_PropertiesComposite_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_PropertiesNetworkArgs = {
  args: Rtcs_Db_Fnrmatch_PropertiesNetwork_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_PropertiesNetwork_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_PropertiesNetwork_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_PropertiesNetwork_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_PropertiesNetwork_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_PropertiesNetwork_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_PropertiesNetwork_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_PropertiesNetwork_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_PropertiesNetwork_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_PropertiesNetwork_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_PropertiesNetwork_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_PropertyNetworkArgs = {
  args: Rtcs_Db_Fnrmatch_PropertyNetwork_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_PropertyNetwork_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_PropertyNetwork_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_PropertyNetwork_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_PropertyNetwork_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_PropertyNetwork_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_PropertyNetwork_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_PropertyNetwork_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_PropertyNetwork_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_PropertyNetwork_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_PropertyNetwork_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchDetailed904Args = {
  args: Rtcs_Db_Fnrmatch_RMatchDetailed904_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchDetailed904_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed904_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchDetailed904_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed904_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchDetailed904_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed904_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchDetailed904_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed904_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchDetailed904_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed904_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchDetailed916Args = {
  args: Rtcs_Db_Fnrmatch_RMatchDetailed916_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancyArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchDetailed916_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed916_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchDetailed916_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed916_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchDetailed916_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed916_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchDetailed916_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed916_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchDetailed916_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed916_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchDetailed937Args = {
  args: Rtcs_Db_Fnrmatch_RMatchDetailed937_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchDetailed937_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed937_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchDetailed937_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed937_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchDetailed937_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed937_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchDetailed937_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed937_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchDetailed937_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchDetailed937_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchKccaPropertiesArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchKccaProperties_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchKccaProperties_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchKccaProperties_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchKccaProperties_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchKccaProperties_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchKccaProperties_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchKccaProperties_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchKccaProperties_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchKccaProperties_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchKccaProperties_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchKccaProperties_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchKccaPropertyArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchKccaProperty_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchKccaProperty_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchKccaProperty_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchKccaProperty_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchKccaProperty_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchKccaProperty_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchKccaProperty_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchKccaProperty_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchKccaProperty_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchKccaProperty_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchKccaProperty_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchNonIndividual903Args = {
  args: Rtcs_Db_Fnrmatch_RMatchNonIndividual903_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchNonIndividual903_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchNonIndividual903_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchNonIndividual903_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchNonIndividual903_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchNonIndividual903_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchNonIndividual903_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchNonIndividual903_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchNonIndividual903_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchNonIndividual903_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchNonIndividual903_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchProperties1115Args = {
  args: Rtcs_Db_Fnrmatch_RMatchProperties1115_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchProperties1115_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchProperties1115_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchProperties1115_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchProperties1115_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchProperties1115_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchProperties1115_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchProperties1115_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchProperties1115_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchProperties1115_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchProperties1115_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchSummary904Args = {
  args: Rtcs_Db_Fnrmatch_RMatchSummary904_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchSummary904_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchSummary904_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchSummary904_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary904_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchSummary904_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary904_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchSummary904_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary904_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchSummary904_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary904_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchSummary916Args = {
  args: Rtcs_Db_Fnrmatch_RMatchSummary916_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancyArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchSummary916_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchSummary916_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchSummary916_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary916_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchSummary916_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary916_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchSummary916_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary916_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchSummary916_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary916_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchSummary937Args = {
  args: Rtcs_Db_Fnrmatch_RMatchSummary937_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchSummary937_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_RMatchSummary937_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchSummary937_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary937_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchSummary937_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary937_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchSummary937_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary937_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_RMatchSummary937_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_RMatchSummary937_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_TinsUraArgs = {
  args: Rtcs_Db_Fnrmatch_TinsUra_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_TinsUra_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_TinsUra_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_TinsUra_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_TinsUra_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_TinsUra_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_TinsUra_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_TinsUra_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_TinsUra_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_TinsUra_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_TinsUra_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_TinsUra_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_TinsUra_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_TinsUra_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_TinsUra_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_UndeclaredArgs = {
  args: Rtcs_Db_Fnrmatch_Undeclared_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Undeclared_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Undeclared_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_Undeclared_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_Undeclared_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Undeclared_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Undeclared_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_Undeclared_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_Undeclared_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_Undeclared_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_Undeclared_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_Undeclared_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_Undeclared_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_Undeclared_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_Undeclared_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_UnderreportedArgs = {
  args: Rtcs_Db_Fnrmatch_Underreported_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Underreported_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Underreported_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_Underreported_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_Underreported_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Underreported_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Underreported_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_Underreported_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_Underreported_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_Underreported_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_Underreported_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_Underreported_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_Underreported_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_Underreported_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_Underreported_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_UnregisteredArgs = {
  args: Rtcs_Db_Fnrmatch_Unregistered_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Unregistered_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Unregistered_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_Unregistered_AggregateArgs = {
  args: Rtcs_Db_Fnrmatch_Unregistered_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Unregistered_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Unregistered_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_Unregistered_AggregatecmArgs = {
  args: Rtcs_Db_Fnrmatch_Unregistered_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_Unregistered_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_Unregistered_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_Unregistered_AggregatetxtArgs = {
  args: Rtcs_Db_Fnrmatch_Unregistered_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_Unregistered_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_Unregistered_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_MlhudConsolidatedArgs = {
  args: Rtcs_Db_Fnrmatch_MlhudConsolidated_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_MlhudConsolidated_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_MlhudConsolidated_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_MlhudConsolidated_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnrmatch_MlhudConsolidated_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_MlhudConsolidated_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_MlhudConsolidated_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_MlhudConsolidated_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnrmatch_MlhudConsolidated_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnrmatch_MlhudConsolidated_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnrmatch_MlhudConsolidated_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmMukonoMunicipalityArgs = {
  args: Rtcs_Db_Fnstreetmap_OsmMukonoMunicipality_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmMukonoMunicipality_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmMukonoMunicipality_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmMukonoMunicipality_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmMukonoMunicipality_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmMukonoMunicipality_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmMukonoMunicipality_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmMukonoMunicipality_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmMukonoMunicipality_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmMukonoMunicipality_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmMukonoMunicipality_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmMukonoNakifumaArgs = {
  args: Rtcs_Db_Fnstreetmap_OsmMukonoNakifuma_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmMukonoNakifuma_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmMukonoNakifuma_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmMukonoNakifuma_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmMukonoNakifuma_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmMukonoNakifuma_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmMukonoNakifuma_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmMukonoNakifuma_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmMukonoNakifuma_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmMukonoNakifuma_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmMukonoNakifuma_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmWakisoEntebbeArgs = {
  args: Rtcs_Db_Fnstreetmap_OsmWakisoEntebbe_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmWakisoEntebbe_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoEntebbe_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmWakisoEntebbe_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoEntebbe_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmWakisoEntebbe_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoEntebbe_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmWakisoEntebbe_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoEntebbe_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmWakisoEntebbe_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoEntebbe_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmWakisoKiraArgs = {
  args: Rtcs_Db_Fnstreetmap_OsmWakisoKira_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmWakisoKira_AggregateArgs = {
  args: Rtcs_Db_Fnstreetmap_OsmWakisoKira_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmWakisoKira_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoKira_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmWakisoKira_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoKira_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmWakisoKira_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoKira_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmWakisoKira_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoKira_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagaboArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoMakindyeSsabagabo_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmWakisoNansanaArgs = {
  args: Rtcs_Db_Fnstreetmap_OsmWakisoNansana_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmWakisoNansana_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoNansana_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmWakisoNansana_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoNansana_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmWakisoNansana_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoNansana_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmWakisoNansana_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoNansana_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnstreetmap_OsmWakisoNansana_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnstreetmap_OsmWakisoNansana_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnucc_UccArgs = {
  args: Rtcs_Db_Fnucc_Ucc_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ucc_Ucc_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ucc_Ucc_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnucc_Ucc_AggregateArgs = {
  args: Rtcs_Db_Fnucc_Ucc_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ucc_Ucc_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ucc_Ucc_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnucc_Ucc_AggregatecmArgs = {
  args: Rtcs_Db_Fnucc_Ucc_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnucc_Ucc_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnucc_Ucc_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnucc_Ucc_AggregatetxtArgs = {
  args: Rtcs_Db_Fnucc_Ucc_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnucc_Ucc_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnucc_Ucc_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnumeme_SubscriberAndPropertyArgs = {
  args: Rtcs_Db_Fnumeme_SubscriberAndProperty_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnumeme_SubscriberAndProperty_AggregateArgs =
  {
    args: Rtcs_Db_Fnumeme_SubscriberAndProperty_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnumeme_SubscriberAndProperty_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnumeme_SubscriberAndProperty_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnumeme_SubscriberAndProperty_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnumeme_SubscriberAndProperty_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnumeme_SubscriberAndProperty_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnumeme_SubscriberAndProperty_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnumeme_SubscriberAndProperty_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnumeme_SubscriberAndProperty_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnumeme_TransactionsArgs = {
  args: Rtcs_Db_Fnumeme_Transactions_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Umeme_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Umeme_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnumeme_Transactions_AggregateArgs = {
  args: Rtcs_Db_Fnumeme_Transactions_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Umeme_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Umeme_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnumeme_Transactions_AggregatecmArgs = {
  args: Rtcs_Db_Fnumeme_Transactions_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnumeme_Transactions_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnumeme_Transactions_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnumeme_Transactions_AggregatetxtArgs = {
  args: Rtcs_Db_Fnumeme_Transactions_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnumeme_Transactions_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnumeme_Transactions_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_BalanceSheetInformationArgs = {
  args: Rtcs_Db_Fnura_BalanceSheetInformation_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_BalanceSheetInformation_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_BalanceSheetInformation_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_BalanceSheetInformation_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnura_BalanceSheetInformation_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_BalanceSheetInformation_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_BalanceSheetInformation_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_BalanceSheetInformation_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_BalanceSheetInformation_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_BalanceSheetInformation_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_BalanceSheetInformation_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_CombinedOfficersAndOwnersArgs = {
  args: Rtcs_Db_Fnura_CombinedOfficersAndOwners_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_CombinedOfficersAndOwners_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_CombinedOfficersAndOwners_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_CombinedOfficersAndOwners_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnura_CombinedOfficersAndOwners_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_CombinedOfficersAndOwners_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_CombinedOfficersAndOwners_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_CombinedOfficersAndOwners_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_CombinedOfficersAndOwners_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_CombinedOfficersAndOwners_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_CombinedOfficersAndOwners_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_EmploymentIncomeDetailArgs = {
  args: Rtcs_Db_Fnura_EmploymentIncomeDetail_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_EmploymentIncomeDetail_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_EmploymentIncomeDetail_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_EmploymentIncomeDetail_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnura_EmploymentIncomeDetail_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_EmploymentIncomeDetail_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_EmploymentIncomeDetail_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_EmploymentIncomeDetail_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_EmploymentIncomeDetail_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_EmploymentIncomeDetail_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_EmploymentIncomeDetail_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootageArgs =
  {
    args: Rtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootage_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootage_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootage_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootage_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootage_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootage_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootage_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootage_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootage_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootage_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_GazetteCommercialRatesPerSquareFootage_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_GazetteResidentialRatesPerBedroomArgs =
  {
    args: Rtcs_Db_Fnura_GazetteResidentialRatesPerBedroom_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_GazetteResidentialRatesPerBedroom_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_GazetteResidentialRatesPerBedroom_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_GazetteResidentialRatesPerBedroom_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnura_GazetteResidentialRatesPerBedroom_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_GazetteResidentialRatesPerBedroom_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_GazetteResidentialRatesPerBedroom_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_GazetteResidentialRatesPerBedroom_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_GazetteResidentialRatesPerBedroom_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_GazetteResidentialRatesPerBedroom_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_GazetteResidentialRatesPerBedroom_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_HoldingOfficersArgs = {
  args: Rtcs_Db_Fnura_HoldingOfficers_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOfficers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOfficers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_HoldingOfficers_AggregateArgs = {
  args: Rtcs_Db_Fnura_HoldingOfficers_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOfficers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOfficers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_HoldingOfficers_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_HoldingOfficers_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_HoldingOfficers_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_HoldingOfficers_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_HoldingOfficers_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_HoldingOfficers_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_HoldingOfficers_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_HoldingOfficers_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_HoldingOwnerArgs = {
  args: Rtcs_Db_Fnura_HoldingOwner_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOwner_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOwner_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_HoldingOwner_AggregateArgs = {
  args: Rtcs_Db_Fnura_HoldingOwner_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOwner_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOwner_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_HoldingOwner_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_HoldingOwner_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_HoldingOwner_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_HoldingOwner_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_HoldingOwner_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_HoldingOwner_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_HoldingOwner_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_HoldingOwner_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IncomeTaxHoldingArgs = {
  args: Rtcs_Db_Fnura_IncomeTaxHolding_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IncomeTaxHolding_AggregateArgs = {
  args: Rtcs_Db_Fnura_IncomeTaxHolding_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IncomeTaxHolding_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_IncomeTaxHolding_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IncomeTaxHolding_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_IncomeTaxHolding_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IncomeTaxHolding_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_IncomeTaxHolding_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IncomeTaxHolding_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_IncomeTaxHolding_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IncomeTaxSummaryIndividualArgs = {
  args: Rtcs_Db_Fnura_IncomeTaxSummaryIndividual_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IncomeTaxSummaryIndividual_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_IncomeTaxSummaryIndividual_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IncomeTaxSummaryIndividual_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnura_IncomeTaxSummaryIndividual_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IncomeTaxSummaryIndividual_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_IncomeTaxSummaryIndividual_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IncomeTaxSummaryIndividual_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_IncomeTaxSummaryIndividual_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IncomeTaxSummaryIndividual_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_IncomeTaxSummaryIndividual_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IncomeTaxSummaryNonIndividualArgs = {
  args: Rtcs_Db_Fnura_IncomeTaxSummaryNonIndividual_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IncomeTaxSummaryNonIndividual_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_IncomeTaxSummaryNonIndividual_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IncomeTaxSummaryNonIndividual_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnura_IncomeTaxSummaryNonIndividual_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IncomeTaxSummaryNonIndividual_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_IncomeTaxSummaryNonIndividual_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IncomeTaxSummaryNonIndividual_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_IncomeTaxSummaryNonIndividual_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IncomeTaxSummaryNonIndividual_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_IncomeTaxSummaryNonIndividual_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IndividualMortgageArgs = {
  args: Rtcs_Db_Fnura_IndividualMortgage_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_IndividualMortgage_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_IndividualMortgage_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IndividualMortgage_AggregateArgs = {
  args: Rtcs_Db_Fnura_IndividualMortgage_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_IndividualMortgage_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_IndividualMortgage_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IndividualMortgage_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnura_IndividualMortgage_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IndividualMortgage_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_IndividualMortgage_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IndividualMortgage_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_IndividualMortgage_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IndividualMortgage_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_IndividualMortgage_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IndividualsAssociatedBusinessArgs = {
  args: Rtcs_Db_Fnura_IndividualsAssociatedBusiness_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<
    Array<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Order_By>
  >;
  where?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IndividualsAssociatedBusiness_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_IndividualsAssociatedBusiness_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IndividualsAssociatedBusiness_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnura_IndividualsAssociatedBusiness_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IndividualsAssociatedBusiness_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_IndividualsAssociatedBusiness_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IndividualsAssociatedBusiness_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_IndividualsAssociatedBusiness_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_IndividualsAssociatedBusiness_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_IndividualsAssociatedBusiness_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_LandlordIncomeDetailArgs = {
  args: Rtcs_Db_Fnura_LandlordIncomeDetail_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_LandlordIncomeDetail_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_LandlordIncomeDetail_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_LandlordIncomeDetail_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnura_LandlordIncomeDetail_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_LandlordIncomeDetail_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_LandlordIncomeDetail_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_LandlordIncomeDetail_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_LandlordIncomeDetail_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_LandlordIncomeDetail_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_LandlordIncomeDetail_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_LandlordIncomeSummaryArgs = {
  args: Rtcs_Db_Fnura_LandlordIncomeSummary_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_LandlordIncomeSummary_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_LandlordIncomeSummary_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_LandlordIncomeSummary_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnura_LandlordIncomeSummary_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_LandlordIncomeSummary_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_LandlordIncomeSummary_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_LandlordIncomeSummary_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_LandlordIncomeSummary_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_LandlordIncomeSummary_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_LandlordIncomeSummary_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_NonIndividualsAssociatedBusinessArgs =
  {
    args: Rtcs_Db_Fnura_NonIndividualsAssociatedBusiness_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_NonIndividualsAssociatedBusiness_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_NonIndividualsAssociatedBusiness_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_NonIndividualsAssociatedBusiness_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnura_NonIndividualsAssociatedBusiness_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_NonIndividualsAssociatedBusiness_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_NonIndividualsAssociatedBusiness_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_NonIndividualsAssociatedBusiness_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_NonIndividualsAssociatedBusiness_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_NonIndividualsAssociatedBusiness_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_NonIndividualsAssociatedBusiness_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegAssociatesArgs = {
  args: Rtcs_Db_Fnura_RegAssociates_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegAssociates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegAssociates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegAssociates_AggregateArgs = {
  args: Rtcs_Db_Fnura_RegAssociates_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegAssociates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegAssociates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegAssociates_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_RegAssociates_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegAssociates_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RegAssociates_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegAssociates_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_RegAssociates_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegAssociates_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RegAssociates_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegIndividualArgs = {
  args: Rtcs_Db_Fnura_RegIndividual_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegIndividual_AggregateArgs = {
  args: Rtcs_Db_Fnura_RegIndividual_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegIndividual_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_RegIndividual_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegIndividual_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RegIndividual_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegIndividual_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_RegIndividual_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegIndividual_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RegIndividual_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegIndividual_SidArgs = {
  args: Rtcs_Db_Fnura_RegIndividual_Sid_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegIndividual_Sid_AggregateArgs = {
  args: Rtcs_Db_Fnura_RegIndividual_Sid_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegIndividual_Sid_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_RegIndividual_Sid_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegIndividual_Sid_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RegIndividual_Sid_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegIndividual_Sid_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_RegIndividual_Sid_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegIndividual_Sid_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RegIndividual_Sid_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegNonIndividualArgs = {
  args: Rtcs_Db_Fnura_RegNonIndividual_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegNonIndividual_AggregateArgs = {
  args: Rtcs_Db_Fnura_RegNonIndividual_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegNonIndividual_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_RegNonIndividual_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegNonIndividual_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RegNonIndividual_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegNonIndividual_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_RegNonIndividual_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegNonIndividual_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RegNonIndividual_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegNonIndividual_SidArgs = {
  args: Rtcs_Db_Fnura_RegNonIndividual_Sid_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegNonIndividual_Sid_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RegNonIndividual_Sid_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegNonIndividual_Sid_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnura_RegNonIndividual_Sid_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegNonIndividual_Sid_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RegNonIndividual_Sid_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegNonIndividual_Sid_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_RegNonIndividual_Sid_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RegNonIndividual_Sid_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RegNonIndividual_Sid_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RentExpenseDetailsArgs = {
  args: Rtcs_Db_Fnura_RentExpenseDetails_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RentExpenseDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RentExpenseDetails_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RentExpenseDetails_AggregateArgs = {
  args: Rtcs_Db_Fnura_RentExpenseDetails_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RentExpenseDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RentExpenseDetails_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RentExpenseDetails_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnura_RentExpenseDetails_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RentExpenseDetails_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RentExpenseDetails_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RentExpenseDetails_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_RentExpenseDetails_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RentExpenseDetails_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RentExpenseDetails_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RentalLandLordIncomeArgs = {
  args: Rtcs_Db_Fnura_RentalLandLordIncome_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RentalLandLordIncome_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RentalLandLordIncome_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RentalLandLordIncome_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnura_RentalLandLordIncome_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RentalLandLordIncome_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RentalLandLordIncome_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RentalLandLordIncome_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_RentalLandLordIncome_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RentalLandLordIncome_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RentalLandLordIncome_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RentalTenantPaymentsArgs = {
  args: Rtcs_Db_Fnura_RentalTenantPayments_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalTenantPayments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalTenantPayments_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RentalTenantPayments_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RentalTenantPayments_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_RentalTenantPayments_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalTenantPayments_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RentalTenantPayments_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnura_RentalTenantPayments_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RentalTenantPayments_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RentalTenantPayments_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RentalTenantPayments_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_RentalTenantPayments_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RentalTenantPayments_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RentalTenantPayments_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RipoIndRentalIncSummaryArgs = {
  args: Rtcs_Db_Fnura_RipoIndRentalIncSummary_Args;
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RipoIndRentalIncSummary_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RipoIndRentalIncSummary_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RipoIndRentalIncSummary_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnura_RipoIndRentalIncSummary_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RipoIndRentalIncSummary_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RipoIndRentalIncSummary_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RipoIndRentalIncSummary_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_RipoIndRentalIncSummary_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_RipoIndRentalIncSummary_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_RipoIndRentalIncSummary_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_TenantIncomeDetailArgs = {
  args: Rtcs_Db_Fnura_TenantIncomeDetail_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_TenantIncomeDetail_AggregateArgs = {
  args: Rtcs_Db_Fnura_TenantIncomeDetail_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_TenantIncomeDetail_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnura_TenantIncomeDetail_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_TenantIncomeDetail_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_TenantIncomeDetail_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_TenantIncomeDetail_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnura_TenantIncomeDetail_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_TenantIncomeDetail_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnura_TenantIncomeDetail_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_TownArgs = {
  args: Rtcs_Db_Fnura_Town_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_Town_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_Town_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_Town_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_Town_AggregateArgs = {
  args: Rtcs_Db_Fnura_Town_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_Town_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_Town_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_Town_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_Town_AggregatecmArgs = {
  args: Rtcs_Db_Fnura_Town_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_Town_Aggregatecm_AggregateArgs = {
  args: Rtcs_Db_Fnura_Town_Aggregatecm_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_Town_AggregatetxtArgs = {
  args: Rtcs_Db_Fnura_Town_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnura_Town_Aggregatetxt_AggregateArgs = {
  args: Rtcs_Db_Fnura_Town_Aggregatetxt_Args;
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsbArgs =
  {
    args: Rtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsb_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsb_AggregateArgs =
  {
    args: Rtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsb_Args;
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsb_AggregatecmArgs =
  {
    args: Rtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsb_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsb_Aggregatecm_AggregateArgs =
  {
    args: Rtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsb_Aggregatecm_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsb_AggregatetxtArgs =
  {
    args: Rtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsb_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsb_Aggregatetxt_AggregateArgs =
  {
    args: Rtcs_Db_Fnursb_CompaniesAndBusinessNamesUrsb_Aggregatetxt_Args;
    distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
    where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_GazzetteRatesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_GazzetteRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_GazzetteRates_Order_By>>;
  where?: Maybe<Rtcs_Db_GazzetteRates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_GazzetteRates_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_GazzetteRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_GazzetteRates_Order_By>>;
  where?: Maybe<Rtcs_Db_GazzetteRates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_EntityFilterArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_EntityFilter_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_EntityFilter_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_EntityFilter_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_EntityFilter_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_EntityFilter_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_EntityFilter_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_EntityFilter_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_FilterArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Filter_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Filter_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Filter_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Filter_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Filter_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Filter_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Filter_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_GazzetteRatesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_GazzetteRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_GazzetteRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_GazzetteRates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_GazzetteRates_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_GazzetteRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_GazzetteRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_GazzetteRates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRiskArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_IndividualCompositeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_IndividualComposite_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_IndividualComposite_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualComposite_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualComposite_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_IndividualRegistrationArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_IndividualRegistration_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualRegistration_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_IndividualRegistration_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_IndividualRegistration_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualRegistration_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualRegistration_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_IndividualTaxSummaryDataArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_IndividualTaxSummaryData_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_KccaAffiliatesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaAffiliates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaAffiliates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_KccaAffiliates_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaAffiliates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaAffiliates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_KccaAffiliates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_KccaCustomerRegistrationDataArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_KccaPropertyRegistrationArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_KccaPropertyRegistrationDataArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_KccaPropertyRegistration_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rpt_KccaPropertyRegistration_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_NonIndividualBalanceSheetArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRiskArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_NonIndividualCompositeArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_NonIndividualComposite_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualComposite_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_NonIndividualTaxSummaryDataArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_PropertiesCompositeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertiesComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertiesComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_PropertiesComposite_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_PropertiesComposite_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertiesComposite_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rpt_PropertiesComposite_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_PropertyValuationArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertyValuation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertyValuation_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_PropertyValuation_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertyValuation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_PropertyValuation_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_PropertyValuation_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_RentalTenantPaymentsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rpt_RentalTenantPayments_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rpt_RentalTenantPayments_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Gazette_CommercialRatesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Gazette_CommercialRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Gazette_CommercialRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Gazette_CommercialRates_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Gazette_CommercialRates_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Gazette_CommercialRates_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Gazette_CommercialRates_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Gazette_ResidentialRatesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Gazette_ResidentialRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Gazette_ResidentialRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Gazette_ResidentialRates_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Gazette_ResidentialRates_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Gazette_ResidentialRates_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Gazette_ResidentialRates_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_AssociateTypeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_AssociateType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_AssociateType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_AssociateType_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_AssociateType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_AssociateType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_AssociateType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CommercialPropertiesArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CommercialProperties_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CommercialProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CommercialProperties_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CommercialProperties_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CommercialProperties_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Kcca_CommercialProperties_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CountyArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_County_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_County_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_County_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_County_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_County_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_County_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_County_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CustomerArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CustomerApplicantTypeArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CustomerApplicantType_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerApplicantType_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CustomerBusinessAssociateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CustomerBusinessAssociate_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CustomerBusinessTypeArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CustomerBusinessType_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerBusinessType_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactiveArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerCoinDeactivateReactive_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CustomerContactPersonArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CustomerContactPerson_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerContactPerson_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CustomerDocumentItemArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CustomerDocumentItem_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerDocumentItem_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CustomerGuardianArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerGuardian_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerGuardian_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CustomerGuardian_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerGuardian_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerGuardian_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerGuardian_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CustomerReferenceArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerReference_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerReference_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CustomerReference_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerReference_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerReference_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerReference_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CustomerRegistrationReasonArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CustomerRegistrationReason_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRegistrationReason_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CustomerRevenueAgentArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CustomerRevenueAgent_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerRevenueAgent_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CustomerTypeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_CustomerType_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_CustomerType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_CustomerType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_Customer_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_Customer_SidArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_Customer_Sid_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Customer_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Customer_Sid_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_DistrictArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_District_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_District_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_District_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_District_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_District_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_District_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_District_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_DivisionArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Division_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Division_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Division_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_Division_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Division_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Division_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Division_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_ImgsDataArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_ImgsData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_ImgsData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_ImgsData_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_ImgsData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_ImgsData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_ImgsData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_ParishArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Parish_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Parish_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_Parish_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Parish_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Parish_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Parish_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_PaymentDetailsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PaymentDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PaymentDetails_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_PaymentDetails_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PaymentDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PaymentDetails_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PaymentDetails_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_PaymentsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Payments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Payments_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_Payments_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Payments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Payments_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Payments_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_PropertyArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Property_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Property_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Property_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_PropertyFloorsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyFloors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyFloors_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_PropertyFloors_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyFloors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyFloors_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyFloors_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_PropertyNumberBedroomsArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_PropertyNumberBedrooms_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyNumberBedrooms_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_PropertyRentedStatusArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_PropertyRentedStatus_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyRentedStatus_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_PropertySecurityArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySecurity_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySecurity_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_PropertySecurity_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySecurity_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySecurity_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertySecurity_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_PropertySubTypeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySubType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySubType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_PropertySubType_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySubType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertySubType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertySubType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_PropertyTypeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_PropertyType_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_PropertyType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_PropertyType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_Property_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Property_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Property_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Property_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_RawProperties1Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties1_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties1_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_RawProperties1_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties1_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties1_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties1_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_RawProperties2Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties2_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties2_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_RawProperties2_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties2_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_RawProperties2_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_RawProperties2_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_ResidentialPropertiesArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_ResidentialProperties_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_ResidentialProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_ResidentialProperties_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_ResidentialProperties_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_ResidentialProperties_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Kcca_ResidentialProperties_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_RevenueTypeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_RevenueType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_RevenueType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_RevenueType_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_RevenueType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_RevenueType_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_RevenueType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_SubCountyArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_SubCounty_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_SubCounty_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_SubCounty_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_SubCounty_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_SubCounty_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_SubCounty_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_SubpropertyTypeConversionArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_SubpropertyTypeConversion_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Kcca_SubpropertyTypeConversion_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_TradingLicenseArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_TradingLicense_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_TradingLicense_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_TradingLicense_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_TradingLicense_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_TradingLicense_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_TradingLicense_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_VillageArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Village_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Village_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Village_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Kcca_Village_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Kcca_Village_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Kcca_Village_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Kcca_Village_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Master_MasterIndivArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Master_MasterIndiv_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Master_MasterIndiv_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Master_MasterIndiv_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Master_MasterIndiv_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Master_MasterIndiv_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Master_MasterIndiv_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Mlhud_KccaArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Kcca_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Kcca_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Mlhud_Kcca_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Kcca_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Kcca_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_Kcca_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Mlhud_MukonoArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Mukono_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Mukono_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Mlhud_Mukono_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Mukono_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Mukono_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_Mukono_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Mlhud_PropertiesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Properties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Properties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Mlhud_Properties_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Properties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_Properties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_Properties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Mlhud_WakisoBusiroArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Mlhud_WakisoBusiro_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoBusiro_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Mlhud_WakisoKyadondoArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Mlhud_WakisoKyadondo_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Mlhud_WakisoKyadondo_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Nira_NiraArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nira_Nira_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nira_Nira_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nira_Nira_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Nira_Nira_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nira_Nira_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nira_Nira_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nira_Nira_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Nira_RawDataArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nira_RawData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nira_RawData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nira_RawData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Nira_RawData_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nira_RawData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nira_RawData_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nira_RawData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Nwsc_CustomersArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Customers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Customers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Nwsc_Customers_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Customers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Customers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_Customers_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Nwsc_NwCustomersArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_NwCustomers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_NwCustomers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Nwsc_NwCustomers_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_NwCustomers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_NwCustomers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_NwCustomers_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Nwsc_Rpt_TransactionsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Nwsc_Rpt_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_Rpt_Transactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Nwsc_TransactionsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Nwsc_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_Transactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Nwsc_UraTransactionsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_UraTransactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_UraTransactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Nwsc_UraTransactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Nwsc_UraTransactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Nwsc_UraTransactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Nwsc_UraTransactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rcapture_RptUnittypesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RptUnittypes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RptUnittypes_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rcapture_RptUnittypes_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RptUnittypes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RptUnittypes_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RptUnittypes_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rcapture_RpTlessorsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTlessors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTlessors_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rcapture_RpTlessors_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTlessors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTlessors_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTlessors_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rcapture_RpTmetaArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTmeta_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTmeta_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rcapture_RpTmeta_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTmeta_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTmeta_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTmeta_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rcapture_RpTpropertiesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTproperties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTproperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rcapture_RpTproperties_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTproperties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTproperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTproperties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rcapture_RpTtenantsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTtenants_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTtenants_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rcapture_RpTtenants_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTtenants_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rcapture_RpTtenants_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rcapture_RpTtenants_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_IndividualArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Individual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Individual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_IndividualComplianceDetailReportArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReportArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_IndividualRiskScoreArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_IndividualRiskScore_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_Individual_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Individual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Individual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Individual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_IndividualsNetworkArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_IndividualsNetworkTestingArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetworkTesting_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_IndividualsNetwork_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_IndividualsNetwork_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwnersArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaIndividualOwners_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwnersArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_KccaToKccaNonindividualOwners_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_MatchIndividualsPropertiesArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_MatchIndividualsProperties_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProperties_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_MatchIndividualsPropsArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_MatchIndividualsProps_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_MatchIndividualsProps_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_NoReturnsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NoReturns_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NoReturns_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_NoReturns_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NoReturns_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NoReturns_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NoReturns_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_NonIndividualArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReportArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReportArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_NonIndividualRiskScoreArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_NonIndividualRiskScore_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_NonIndividual_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_NonIndividualsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividuals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividuals_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_NonIndividualsNetworkArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_NonIndividualsNetwork_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividualsNetwork_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_NonIndividuals_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividuals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonIndividuals_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonIndividuals_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_NonindividualOwnersArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_NonindividualOwners_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_NonindividualOwners_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_NonindividualsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Nonindividuals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Nonindividuals_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Nonindividuals_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_Nonindividuals_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Nonindividuals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Nonindividuals_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Nonindividuals_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_PropertiesCompositeArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_PropertiesComposite_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesComposite_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_PropertiesNetworkArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_PropertiesNetwork_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_PropertiesNetwork_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_PropertyNetworkArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_PropertyNetwork_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_PropertyNetwork_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_RMatchDetailed904Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_RMatchDetailed904_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed904_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_RMatchDetailed916Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancyArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_RMatchDetailed916_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed916_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_RMatchDetailed937Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_RMatchDetailed937_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchDetailed937_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_RMatchKccaPropertiesArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_RMatchKccaProperties_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperties_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_RMatchKccaPropertyArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_RMatchKccaProperty_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchKccaProperty_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_RMatchNonIndividual903Args = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_RMatchNonIndividual903_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchNonIndividual903_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_RMatchProperties1115Args = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_RMatchProperties1115_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchProperties1115_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_RMatchSummary904Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_RMatchSummary904_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary904_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_RMatchSummary916Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancyArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_RMatchSummary916_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary916_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_RMatchSummary937Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_RMatchSummary937_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_RMatchSummary937_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_TinsUraArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_TinsUra_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_TinsUra_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_TinsUra_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_TinsUra_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_TinsUra_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_TinsUra_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_UndeclaredArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Undeclared_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Undeclared_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_Undeclared_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Undeclared_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Undeclared_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Undeclared_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_UnderreportedArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Underreported_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Underreported_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_Underreported_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Underreported_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Underreported_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Underreported_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_UnregisteredArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Unregistered_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Unregistered_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_Unregistered_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Unregistered_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_Unregistered_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_Unregistered_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_MlhudConsolidatedArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Rmatch_MlhudConsolidated_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Rmatch_MlhudConsolidated_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Streetmap_OsmMukonoMunicipalityArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoMunicipality_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Streetmap_OsmMukonoNakifumaArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmMukonoNakifuma_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Streetmap_OsmWakisoEntebbeArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoEntebbe_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Streetmap_OsmWakisoKiraArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Streetmap_OsmWakisoKira_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoKira_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagaboArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoMakindyeSsabagabo_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Streetmap_OsmWakisoNansanaArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Streetmap_OsmWakisoNansana_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Streetmap_OsmWakisoNansana_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ucc_UccArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ucc_Ucc_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ucc_Ucc_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ucc_Ucc_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ucc_Ucc_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ucc_Ucc_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ucc_Ucc_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Umeme_SubscriberAndPropertyArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Umeme_SubscriberAndProperty_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Umeme_TransactionsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Umeme_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Umeme_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Umeme_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Umeme_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Umeme_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Umeme_Transactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_BalanceSheetInformationArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_BalanceSheetInformation_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Ura_BalanceSheetInformation_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_CombinedOfficersAndOwnersArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_CombinedOfficersAndOwners_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_EmploymentIncomeDetailArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_EmploymentIncomeDetail_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Ura_EmploymentIncomeDetail_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootageArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_GazetteCommercialRatesPerSquareFootage_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroomArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_GazetteResidentialRatesPerBedroom_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_HoldingOfficersArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOfficers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOfficers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_HoldingOfficers_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOfficers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOfficers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_HoldingOfficers_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_HoldingOwnerArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOwner_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOwner_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_HoldingOwner_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOwner_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_HoldingOwner_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_HoldingOwner_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_IncomeTaxHoldingArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_IncomeTaxHolding_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxHolding_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_IncomeTaxSummaryIndividualArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryIndividual_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividualArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividual_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_IndividualMortgageArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_IndividualMortgage_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_IndividualMortgage_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_IndividualMortgage_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_IndividualMortgage_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_IndividualMortgage_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_IndividualMortgage_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_IndividualsAssociatedBusinessArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_LandlordIncomeDetailArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_LandlordIncomeDetail_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeDetail_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_LandlordIncomeSummaryArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_LandlordIncomeSummary_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Ura_LandlordIncomeSummary_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_NonIndividualsAssociatedBusinessArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_RegAssociatesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegAssociates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegAssociates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_RegAssociates_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegAssociates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegAssociates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegAssociates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_RegIndividualArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_RegIndividual_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_RegIndividual_SidArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_RegIndividual_Sid_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegIndividual_Sid_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_RegNonIndividualArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_RegNonIndividual_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_RegNonIndividual_SidArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_RegNonIndividual_Sid_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Ura_RegNonIndividual_Sid_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_RentExpenseDetailsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RentExpenseDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RentExpenseDetails_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_RentExpenseDetails_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RentExpenseDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RentExpenseDetails_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RentExpenseDetails_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_RentalLandLordIncomeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_RentalLandLordIncome_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Ura_RentalLandLordIncome_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_RentalTenantPaymentsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalTenantPayments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalTenantPayments_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_RentalTenantPayments_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_RentalTenantPayments_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RentalTenantPayments_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Ura_RentalTenantPayments_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_RipoIndRentalIncSummaryArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_RipoIndRentalIncSummary_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Order_By>>;
    where?: Maybe<Rtcs_Db_Ph_Ura_RipoIndRentalIncSummary_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_TenantIncomeDetailArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_TenantIncomeDetail_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_TenantIncomeDetail_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_TownArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_Town_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_Town_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_Town_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ura_Town_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ph_Ura_Town_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ph_Ura_Town_Order_By>>;
  where?: Maybe<Rtcs_Db_Ph_Ura_Town_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsbArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_IndividualComplianceSummaryPlusRiskArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rpt_IndividualComplianceSummaryPlusRisk_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_IndividualCompositeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_IndividualComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_IndividualComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_IndividualComposite_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_IndividualComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_IndividualComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_IndividualComposite_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_IndividualRegistrationArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_IndividualRegistration_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_IndividualRegistration_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_IndividualRegistration_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rpt_IndividualRegistration_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Rpt_IndividualRegistration_Order_By>>;
    where?: Maybe<Rtcs_Db_Rpt_IndividualRegistration_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_IndividualTaxSummaryDataArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rpt_IndividualTaxSummaryData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_IndividualTaxSummaryData_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_IndividualTaxSummaryData_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rpt_IndividualTaxSummaryData_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Rpt_IndividualTaxSummaryData_Order_By>>;
    where?: Maybe<Rtcs_Db_Rpt_IndividualTaxSummaryData_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_KccaAffiliatesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_KccaAffiliates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_KccaAffiliates_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_KccaAffiliates_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_KccaAffiliates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_KccaAffiliates_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_KccaAffiliates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_KccaCustomerRegistrationDataArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_KccaCustomerRegistrationData_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Order_By>>;
    where?: Maybe<Rtcs_Db_Rpt_KccaCustomerRegistrationData_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_KccaPropertyRegistrationArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rpt_KccaPropertyRegistration_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_KccaPropertyRegistration_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_KccaPropertyRegistrationDataArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_KccaPropertyRegistrationData_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Order_By>>;
    where?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistrationData_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_KccaPropertyRegistration_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rpt_KccaPropertyRegistration_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Rpt_KccaPropertyRegistration_Order_By>>;
    where?: Maybe<Rtcs_Db_Rpt_KccaPropertyRegistration_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_NonIndividualBalanceSheetArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_NonIndividualBalanceSheet_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Order_By>>;
    where?: Maybe<Rtcs_Db_Rpt_NonIndividualBalanceSheet_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRiskArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rpt_NonIndividualComplianceSummaryPlusRisk_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_NonIndividualCompositeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_NonIndividualComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_NonIndividualComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_NonIndividualComposite_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rpt_NonIndividualComposite_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Rpt_NonIndividualComposite_Order_By>>;
    where?: Maybe<Rtcs_Db_Rpt_NonIndividualComposite_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_NonIndividualTaxSummaryDataArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_NonIndividualTaxSummaryData_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Order_By>>;
    where?: Maybe<Rtcs_Db_Rpt_NonIndividualTaxSummaryData_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_PropertiesCompositeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_PropertiesComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_PropertiesComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_PropertiesComposite_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_PropertiesComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_PropertiesComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_PropertiesComposite_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_PropertyValuationArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_PropertyValuation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_PropertyValuation_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_PropertyValuation_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_PropertyValuation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_PropertyValuation_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_PropertyValuation_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_RentalTenantPaymentsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_RentalTenantPayments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_RentalTenantPayments_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rpt_RentalTenantPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rpt_RentalTenantPayments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rpt_RentalTenantPayments_Order_By>>;
  where?: Maybe<Rtcs_Db_Rpt_RentalTenantPayments_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Aggregate_ResultArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Aggregate_Result_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Aggregate_Result_TxtArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Aggregate_Result_Txt_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Aggregate_Result_Txt_Order_By>>;
  where?: Maybe<Rtcs_Db_Aggregate_Result_Txt_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Gazette_CommercialRatesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Gazette_CommercialRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Gazette_CommercialRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Gazette_CommercialRates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Gazette_CommercialRates_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Gazette_CommercialRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Gazette_CommercialRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Gazette_CommercialRates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Gazette_ResidentialRatesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Gazette_ResidentialRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Gazette_ResidentialRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Gazette_ResidentialRates_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Gazette_ResidentialRates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Gazette_ResidentialRates_Order_By>>;
  where?: Maybe<Rtcs_Db_Gazette_ResidentialRates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_AssociateTypeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_AssociateType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_AssociateType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_AssociateType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_AssociateType_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_AssociateType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_AssociateType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_AssociateType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CommercialPropertiesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CommercialProperties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CommercialProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CommercialProperties_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CommercialProperties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CommercialProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CommercialProperties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CountyArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_County_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_County_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_County_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_County_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_County_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_County_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_County_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CustomerArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Customer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Customer_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Customer_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CustomerApplicantTypeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerApplicantType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerApplicantType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CustomerApplicantType_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Kcca_CustomerApplicantType_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerApplicantType_Order_By>>;
    where?: Maybe<Rtcs_Db_Kcca_CustomerApplicantType_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CustomerBusinessAssociateArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Kcca_CustomerBusinessAssociate_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerBusinessAssociate_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CustomerBusinessAssociate_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Kcca_CustomerBusinessAssociate_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerBusinessAssociate_Order_By>>;
    where?: Maybe<Rtcs_Db_Kcca_CustomerBusinessAssociate_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CustomerBusinessTypeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerBusinessType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerBusinessType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CustomerBusinessType_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerBusinessType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerBusinessType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerBusinessType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CustomerCoinDeactivateReactiveArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CustomerCoinDeactivateReactive_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Kcca_CustomerCoinDeactivateReactive_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CustomerContactPersonArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerContactPerson_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerContactPerson_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CustomerContactPerson_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Kcca_CustomerContactPerson_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerContactPerson_Order_By>>;
    where?: Maybe<Rtcs_Db_Kcca_CustomerContactPerson_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CustomerDocumentItemArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerDocumentItem_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerDocumentItem_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CustomerDocumentItem_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerDocumentItem_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerDocumentItem_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerDocumentItem_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CustomerGuardianArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerGuardian_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerGuardian_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CustomerGuardian_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerGuardian_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerGuardian_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerGuardian_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CustomerReferenceArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerReference_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerReference_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerReference_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CustomerReference_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerReference_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerReference_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerReference_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CustomerRegistrationReasonArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Kcca_CustomerRegistrationReason_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerRegistrationReason_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CustomerRegistrationReason_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Kcca_CustomerRegistrationReason_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerRegistrationReason_Order_By>>;
    where?: Maybe<Rtcs_Db_Kcca_CustomerRegistrationReason_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CustomerRevenueAgentArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerRevenueAgent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerRevenueAgent_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CustomerRevenueAgent_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerRevenueAgent_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerRevenueAgent_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerRevenueAgent_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CustomerTypeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_CustomerType_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_CustomerType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_CustomerType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_CustomerType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_Customer_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Customer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Customer_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Customer_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_Customer_SidArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Customer_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Customer_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_Customer_Sid_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Customer_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Customer_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Customer_Sid_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_DistrictArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_District_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_District_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_District_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_District_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_District_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_District_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_District_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_DivisionArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Division_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Division_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Division_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_Division_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Division_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Division_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Division_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_ImgsDataArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_ImgsData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_ImgsData_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_ImgsData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_ImgsData_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_ImgsData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_ImgsData_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_ImgsData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_ParishArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Parish_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Parish_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Parish_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_Parish_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Parish_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Parish_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Parish_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_PaymentDetailsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PaymentDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PaymentDetails_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_PaymentDetails_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PaymentDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PaymentDetails_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PaymentDetails_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_PaymentsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Payments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Payments_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Payments_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_Payments_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Payments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Payments_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Payments_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_PropertyArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Property_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Property_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Property_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_PropertyFloorsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PropertyFloors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PropertyFloors_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_PropertyFloors_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PropertyFloors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PropertyFloors_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PropertyFloors_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_PropertyNumberBedroomsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PropertyNumberBedrooms_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PropertyNumberBedrooms_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_PropertyNumberBedrooms_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Kcca_PropertyNumberBedrooms_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Kcca_PropertyNumberBedrooms_Order_By>>;
    where?: Maybe<Rtcs_Db_Kcca_PropertyNumberBedrooms_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_PropertyRentedStatusArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PropertyRentedStatus_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PropertyRentedStatus_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_PropertyRentedStatus_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PropertyRentedStatus_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PropertyRentedStatus_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PropertyRentedStatus_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_PropertySecurityArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PropertySecurity_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PropertySecurity_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_PropertySecurity_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PropertySecurity_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PropertySecurity_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PropertySecurity_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_PropertySubTypeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PropertySubType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PropertySubType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PropertySubType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_PropertySubType_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PropertySubType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PropertySubType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PropertySubType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_PropertyTypeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PropertyType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PropertyType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PropertyType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_PropertyType_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_PropertyType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_PropertyType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_PropertyType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_Property_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Property_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Property_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Property_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_RawProperties1Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_RawProperties1_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_RawProperties1_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_RawProperties1_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_RawProperties1_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_RawProperties1_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_RawProperties1_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_RawProperties1_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_RawProperties2Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_RawProperties2_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_RawProperties2_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_RawProperties2_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_RawProperties2_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_RawProperties2_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_RawProperties2_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_RawProperties2_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_ResidentialPropertiesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_ResidentialProperties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_ResidentialProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_ResidentialProperties_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Kcca_ResidentialProperties_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Kcca_ResidentialProperties_Order_By>>;
    where?: Maybe<Rtcs_Db_Kcca_ResidentialProperties_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_RevenueTypeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_RevenueType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_RevenueType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_RevenueType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_RevenueType_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_RevenueType_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_RevenueType_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_RevenueType_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_SubCountyArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_SubCounty_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_SubCounty_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_SubCounty_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_SubCounty_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_SubCounty_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_SubCounty_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_SubCounty_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_SubpropertyTypeConversionArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Kcca_SubpropertyTypeConversion_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_SubpropertyTypeConversion_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_SubpropertyTypeConversion_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Kcca_SubpropertyTypeConversion_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Kcca_SubpropertyTypeConversion_Order_By>>;
    where?: Maybe<Rtcs_Db_Kcca_SubpropertyTypeConversion_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_TradingLicenseArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_TradingLicense_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_TradingLicense_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_TradingLicense_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_TradingLicense_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_TradingLicense_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_TradingLicense_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_TradingLicense_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_VillageArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Village_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Village_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Village_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Kcca_Village_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Kcca_Village_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Kcca_Village_Order_By>>;
  where?: Maybe<Rtcs_Db_Kcca_Village_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Master_MasterIndivArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Master_MasterIndiv_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Master_MasterIndiv_Order_By>>;
  where?: Maybe<Rtcs_Db_Master_MasterIndiv_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Master_MasterIndiv_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Master_MasterIndiv_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Master_MasterIndiv_Order_By>>;
  where?: Maybe<Rtcs_Db_Master_MasterIndiv_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Mlhud_KccaArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Mlhud_Kcca_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Mlhud_Kcca_Order_By>>;
  where?: Maybe<Rtcs_Db_Mlhud_Kcca_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Mlhud_Kcca_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Mlhud_Kcca_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Mlhud_Kcca_Order_By>>;
  where?: Maybe<Rtcs_Db_Mlhud_Kcca_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Mlhud_MukonoArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Mlhud_Mukono_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Mlhud_Mukono_Order_By>>;
  where?: Maybe<Rtcs_Db_Mlhud_Mukono_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Mlhud_Mukono_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Mlhud_Mukono_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Mlhud_Mukono_Order_By>>;
  where?: Maybe<Rtcs_Db_Mlhud_Mukono_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Mlhud_PropertiesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Mlhud_Properties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Mlhud_Properties_Order_By>>;
  where?: Maybe<Rtcs_Db_Mlhud_Properties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Mlhud_Properties_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Mlhud_Properties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Mlhud_Properties_Order_By>>;
  where?: Maybe<Rtcs_Db_Mlhud_Properties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Mlhud_WakisoBusiroArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Mlhud_WakisoBusiro_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Mlhud_WakisoBusiro_Order_By>>;
  where?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Mlhud_WakisoBusiro_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Mlhud_WakisoBusiro_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Mlhud_WakisoBusiro_Order_By>>;
  where?: Maybe<Rtcs_Db_Mlhud_WakisoBusiro_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Mlhud_WakisoKyadondoArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Mlhud_WakisoKyadondo_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Mlhud_WakisoKyadondo_Order_By>>;
  where?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Mlhud_WakisoKyadondo_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Mlhud_WakisoKyadondo_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Mlhud_WakisoKyadondo_Order_By>>;
  where?: Maybe<Rtcs_Db_Mlhud_WakisoKyadondo_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Nira_NiraArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nira_Nira_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nira_Nira_Order_By>>;
  where?: Maybe<Rtcs_Db_Nira_Nira_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Nira_Nira_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nira_Nira_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nira_Nira_Order_By>>;
  where?: Maybe<Rtcs_Db_Nira_Nira_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Nira_RawDataArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nira_RawData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nira_RawData_Order_By>>;
  where?: Maybe<Rtcs_Db_Nira_RawData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Nira_RawData_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nira_RawData_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nira_RawData_Order_By>>;
  where?: Maybe<Rtcs_Db_Nira_RawData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Nwsc_CustomersArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nwsc_Customers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nwsc_Customers_Order_By>>;
  where?: Maybe<Rtcs_Db_Nwsc_Customers_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Nwsc_Customers_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nwsc_Customers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nwsc_Customers_Order_By>>;
  where?: Maybe<Rtcs_Db_Nwsc_Customers_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Nwsc_NwCustomersArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nwsc_NwCustomers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nwsc_NwCustomers_Order_By>>;
  where?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Nwsc_NwCustomers_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nwsc_NwCustomers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nwsc_NwCustomers_Order_By>>;
  where?: Maybe<Rtcs_Db_Nwsc_NwCustomers_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Nwsc_Rpt_TransactionsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nwsc_Rpt_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nwsc_Rpt_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Nwsc_Rpt_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nwsc_Rpt_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nwsc_Rpt_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Nwsc_Rpt_Transactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Nwsc_TransactionsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nwsc_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nwsc_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Nwsc_Transactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Nwsc_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nwsc_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nwsc_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Nwsc_Transactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Nwsc_UraTransactionsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nwsc_UraTransactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nwsc_UraTransactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Nwsc_UraTransactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Nwsc_UraTransactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Nwsc_UraTransactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Nwsc_UraTransactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rcapture_RptUnittypesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rcapture_RptUnittypes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rcapture_RptUnittypes_Order_By>>;
  where?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rcapture_RptUnittypes_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rcapture_RptUnittypes_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rcapture_RptUnittypes_Order_By>>;
  where?: Maybe<Rtcs_Db_Rcapture_RptUnittypes_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rcapture_RpTlessorsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rcapture_RpTlessors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rcapture_RpTlessors_Order_By>>;
  where?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rcapture_RpTlessors_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rcapture_RpTlessors_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rcapture_RpTlessors_Order_By>>;
  where?: Maybe<Rtcs_Db_Rcapture_RpTlessors_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rcapture_RpTmetaArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rcapture_RpTmeta_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rcapture_RpTmeta_Order_By>>;
  where?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rcapture_RpTmeta_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rcapture_RpTmeta_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rcapture_RpTmeta_Order_By>>;
  where?: Maybe<Rtcs_Db_Rcapture_RpTmeta_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rcapture_RpTpropertiesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rcapture_RpTproperties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rcapture_RpTproperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rcapture_RpTproperties_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rcapture_RpTproperties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rcapture_RpTproperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Rcapture_RpTproperties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rcapture_RpTtenantsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rcapture_RpTtenants_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rcapture_RpTtenants_Order_By>>;
  where?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rcapture_RpTtenants_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rcapture_RpTtenants_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rcapture_RpTtenants_Order_By>>;
  where?: Maybe<Rtcs_Db_Rcapture_RpTtenants_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_IndividualArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_Individual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_Individual_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_Individual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_IndividualComplianceDetailReportArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_IndividualComplianceDetailReport_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceDetailReport_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_IndividualComplianceSummaryReportArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_IndividualComplianceSummaryReport_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rmatch_IndividualComplianceSummaryReport_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_IndividualRiskScoreArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_IndividualRiskScore_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_IndividualRiskScore_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_IndividualRiskScore_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_IndividualRiskScore_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Rmatch_IndividualRiskScore_Order_By>>;
    where?: Maybe<Rtcs_Db_Rmatch_IndividualRiskScore_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_Individual_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_Individual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_Individual_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_Individual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_IndividualsNetworkArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_IndividualsNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_IndividualsNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_IndividualsNetworkTestingArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_IndividualsNetworkTesting_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Order_By>>;
    where?: Maybe<Rtcs_Db_Rmatch_IndividualsNetworkTesting_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_IndividualsNetwork_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_IndividualsNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_IndividualsNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_IndividualsNetwork_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_KccaToKccaIndividualOwnersArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_KccaToKccaIndividualOwners_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Order_By>>;
    where?: Maybe<Rtcs_Db_Rmatch_KccaToKccaIndividualOwners_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_KccaToKccaNonindividualOwnersArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_KccaToKccaNonindividualOwners_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rmatch_KccaToKccaNonindividualOwners_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_MatchIndividualsPropertiesArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rmatch_MatchIndividualsProperties_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_MatchIndividualsProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_MatchIndividualsProperties_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_MatchIndividualsProperties_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Rmatch_MatchIndividualsProperties_Order_By>>;
    where?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProperties_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_MatchIndividualsPropsArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rmatch_MatchIndividualsProps_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_MatchIndividualsProps_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_MatchIndividualsProps_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_MatchIndividualsProps_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Rmatch_MatchIndividualsProps_Order_By>>;
    where?: Maybe<Rtcs_Db_Rmatch_MatchIndividualsProps_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_NoReturnsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_NoReturns_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_NoReturns_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_NoReturns_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_NoReturns_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_NoReturns_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_NoReturns_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_NoReturns_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_NonIndividualArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_NonIndividualComplianceDetailReportArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_NonIndividualComplianceDetailReport_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceDetailReport_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_NonIndividualComplianceSummaryReportArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rmatch_NonIndividualComplianceSummaryReport_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_NonIndividualRiskScoreArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rmatch_NonIndividualRiskScore_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividualRiskScore_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_NonIndividualRiskScore_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_NonIndividualRiskScore_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividualRiskScore_Order_By>>;
    where?: Maybe<Rtcs_Db_Rmatch_NonIndividualRiskScore_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_NonIndividual_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_NonIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_NonIndividualsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividuals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividuals_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_NonIndividualsNetworkArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rmatch_NonIndividualsNetwork_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividualsNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_NonIndividualsNetwork_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_NonIndividualsNetwork_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividualsNetwork_Order_By>>;
    where?: Maybe<Rtcs_Db_Rmatch_NonIndividualsNetwork_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_NonIndividuals_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividuals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_NonIndividuals_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_NonIndividuals_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_NonindividualOwnersArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_NonindividualOwners_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_NonindividualOwners_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_NonindividualOwners_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_NonindividualOwners_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Rmatch_NonindividualOwners_Order_By>>;
    where?: Maybe<Rtcs_Db_Rmatch_NonindividualOwners_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_NonindividualsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_Nonindividuals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_Nonindividuals_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_Nonindividuals_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_Nonindividuals_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_Nonindividuals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_Nonindividuals_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_Nonindividuals_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_PropertiesCompositeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_PropertiesComposite_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_PropertiesComposite_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_PropertiesComposite_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_PropertiesComposite_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Rmatch_PropertiesComposite_Order_By>>;
    where?: Maybe<Rtcs_Db_Rmatch_PropertiesComposite_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_PropertiesNetworkArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_PropertiesNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_PropertiesNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_PropertiesNetwork_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_PropertiesNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_PropertiesNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_PropertiesNetwork_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_PropertyNetworkArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_PropertyNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_PropertyNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_PropertyNetwork_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_PropertyNetwork_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_PropertyNetwork_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_PropertyNetwork_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_RMatchDetailed904Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed904_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_RMatchDetailed904_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed904_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed904_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_RMatchDetailed916Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed916_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancyArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916ExcessiveOwnerOccupancy_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_RMatchDetailed916_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed916_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed916_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_RMatchDetailed937Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed937_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed937_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_RMatchDetailed937_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed937_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchDetailed937_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchDetailed937_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_RMatchKccaPropertiesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchKccaProperties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchKccaProperties_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_RMatchKccaProperties_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_RMatchKccaProperties_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchKccaProperties_Order_By>>;
    where?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperties_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_RMatchKccaPropertyArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchKccaProperty_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchKccaProperty_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_RMatchKccaProperty_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchKccaProperty_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchKccaProperty_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchKccaProperty_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_RMatchNonIndividual903Args = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Rmatch_RMatchNonIndividual903_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchNonIndividual903_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_RMatchNonIndividual903_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_RMatchNonIndividual903_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchNonIndividual903_Order_By>>;
    where?: Maybe<Rtcs_Db_Rmatch_RMatchNonIndividual903_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_RMatchProperties1115Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchProperties1115_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchProperties1115_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_RMatchProperties1115_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_RMatchProperties1115_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchProperties1115_Order_By>>;
    where?: Maybe<Rtcs_Db_Rmatch_RMatchProperties1115_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_RMatchSummary904Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary904_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_RMatchSummary904_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary904_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary904_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchSummary904_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_RMatchSummary916Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary916_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancyArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_RMatchSummary916_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary916_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary916_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchSummary916_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_RMatchSummary937Args = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary937_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary937_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_RMatchSummary937_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary937_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_RMatchSummary937_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_RMatchSummary937_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_TinsUraArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_TinsUra_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_TinsUra_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_TinsUra_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_TinsUra_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_TinsUra_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_TinsUra_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_TinsUra_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_UndeclaredArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_Undeclared_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_Undeclared_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_Undeclared_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_Undeclared_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_Undeclared_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_Undeclared_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_Undeclared_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_UnderreportedArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_Underreported_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_Underreported_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_Underreported_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_Underreported_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_Underreported_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_Underreported_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_Underreported_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_UnregisteredArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_Unregistered_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_Unregistered_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_Unregistered_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_Unregistered_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_Unregistered_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_Unregistered_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_Unregistered_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_MlhudConsolidatedArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_MlhudConsolidated_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_MlhudConsolidated_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Rmatch_MlhudConsolidated_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Rmatch_MlhudConsolidated_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Rmatch_MlhudConsolidated_Order_By>>;
  where?: Maybe<Rtcs_Db_Rmatch_MlhudConsolidated_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Streetmap_OsmMukonoMunicipalityArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Order_By>>;
  where?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Streetmap_OsmMukonoMunicipality_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Order_By>>;
    where?: Maybe<Rtcs_Db_Streetmap_OsmMukonoMunicipality_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Streetmap_OsmMukonoNakifumaArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Order_By>>;
  where?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Streetmap_OsmMukonoNakifuma_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Order_By>>;
    where?: Maybe<Rtcs_Db_Streetmap_OsmMukonoNakifuma_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Streetmap_OsmWakisoEntebbeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Order_By>>;
  where?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Streetmap_OsmWakisoEntebbe_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Order_By>>;
    where?: Maybe<Rtcs_Db_Streetmap_OsmWakisoEntebbe_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Streetmap_OsmWakisoKiraArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Streetmap_OsmWakisoKira_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Streetmap_OsmWakisoKira_Order_By>>;
  where?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Streetmap_OsmWakisoKira_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Streetmap_OsmWakisoKira_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Streetmap_OsmWakisoKira_Order_By>>;
  where?: Maybe<Rtcs_Db_Streetmap_OsmWakisoKira_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Streetmap_OsmWakisoMakindyeSsabagaboArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Streetmap_OsmWakisoMakindyeSsabagabo_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Streetmap_OsmWakisoNansanaArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Streetmap_OsmWakisoNansana_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Streetmap_OsmWakisoNansana_Order_By>>;
  where?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Streetmap_OsmWakisoNansana_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Streetmap_OsmWakisoNansana_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Streetmap_OsmWakisoNansana_Order_By>>;
    where?: Maybe<Rtcs_Db_Streetmap_OsmWakisoNansana_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ucc_UccArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ucc_Ucc_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ucc_Ucc_Order_By>>;
  where?: Maybe<Rtcs_Db_Ucc_Ucc_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ucc_Ucc_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ucc_Ucc_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ucc_Ucc_Order_By>>;
  where?: Maybe<Rtcs_Db_Ucc_Ucc_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Umeme_SubscriberAndPropertyArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Umeme_SubscriberAndProperty_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Umeme_SubscriberAndProperty_Order_By>>;
  where?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Umeme_SubscriberAndProperty_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Umeme_SubscriberAndProperty_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Umeme_SubscriberAndProperty_Order_By>>;
    where?: Maybe<Rtcs_Db_Umeme_SubscriberAndProperty_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Umeme_TransactionsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Umeme_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Umeme_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Umeme_Transactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Umeme_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Umeme_Transactions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Umeme_Transactions_Order_By>>;
  where?: Maybe<Rtcs_Db_Umeme_Transactions_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_BalanceSheetInformationArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_BalanceSheetInformation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_BalanceSheetInformation_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_BalanceSheetInformation_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ura_BalanceSheetInformation_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ura_BalanceSheetInformation_Order_By>>;
    where?: Maybe<Rtcs_Db_Ura_BalanceSheetInformation_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_CombinedOfficersAndOwnersArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ura_CombinedOfficersAndOwners_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_CombinedOfficersAndOwners_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_CombinedOfficersAndOwners_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ura_CombinedOfficersAndOwners_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ura_CombinedOfficersAndOwners_Order_By>>;
    where?: Maybe<Rtcs_Db_Ura_CombinedOfficersAndOwners_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_EmploymentIncomeDetailArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_EmploymentIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_EmploymentIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_EmploymentIncomeDetail_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ura_EmploymentIncomeDetail_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ura_EmploymentIncomeDetail_Order_By>>;
    where?: Maybe<Rtcs_Db_Ura_EmploymentIncomeDetail_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_GazetteCommercialRatesPerSquareFootageArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ura_GazetteCommercialRatesPerSquareFootage_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_GazetteResidentialRatesPerBedroomArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_GazetteResidentialRatesPerBedroom_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ura_GazetteResidentialRatesPerBedroom_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_HoldingOfficersArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_HoldingOfficers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_HoldingOfficers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_HoldingOfficers_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_HoldingOfficers_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_HoldingOfficers_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_HoldingOfficers_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_HoldingOwnerArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_HoldingOwner_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_HoldingOwner_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_HoldingOwner_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_HoldingOwner_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_HoldingOwner_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_HoldingOwner_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_HoldingOwner_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_IncomeTaxHoldingArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_IncomeTaxHolding_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_IncomeTaxHolding_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_IncomeTaxHolding_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_IncomeTaxHolding_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_IncomeTaxHolding_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_IncomeTaxHolding_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_IncomeTaxSummaryIndividualArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_IncomeTaxSummaryIndividual_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Order_By>>;
    where?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryIndividual_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_IncomeTaxSummaryNonIndividualArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_IncomeTaxSummaryNonIndividual_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Order_By>>;
    where?: Maybe<Rtcs_Db_Ura_IncomeTaxSummaryNonIndividual_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_IndividualMortgageArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_IndividualMortgage_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_IndividualMortgage_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_IndividualMortgage_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_IndividualMortgage_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_IndividualMortgage_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_IndividualMortgage_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_IndividualsAssociatedBusinessArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_IndividualsAssociatedBusiness_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Order_By>>;
    where?: Maybe<Rtcs_Db_Ura_IndividualsAssociatedBusiness_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_LandlordIncomeDetailArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_LandlordIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_LandlordIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_LandlordIncomeDetail_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_LandlordIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_LandlordIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_LandlordIncomeDetail_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_LandlordIncomeSummaryArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_LandlordIncomeSummary_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_LandlordIncomeSummary_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_LandlordIncomeSummary_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_LandlordIncomeSummary_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_LandlordIncomeSummary_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_LandlordIncomeSummary_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_NonIndividualsAssociatedBusinessArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_NonIndividualsAssociatedBusiness_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ura_NonIndividualsAssociatedBusiness_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_RegAssociatesArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RegAssociates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RegAssociates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RegAssociates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_RegAssociates_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RegAssociates_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RegAssociates_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RegAssociates_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_RegIndividualArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RegIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RegIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RegIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_RegIndividual_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RegIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RegIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RegIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_RegIndividual_SidArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RegIndividual_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RegIndividual_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_RegIndividual_Sid_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RegIndividual_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RegIndividual_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RegIndividual_Sid_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_RegNonIndividualArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RegNonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RegNonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_RegNonIndividual_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RegNonIndividual_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RegNonIndividual_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_RegNonIndividual_SidArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RegNonIndividual_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RegNonIndividual_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_RegNonIndividual_Sid_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RegNonIndividual_Sid_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RegNonIndividual_Sid_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RegNonIndividual_Sid_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_RentExpenseDetailsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RentExpenseDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RentExpenseDetails_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_RentExpenseDetails_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RentExpenseDetails_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RentExpenseDetails_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RentExpenseDetails_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_RentalLandLordIncomeArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RentalLandLordIncome_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RentalLandLordIncome_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_RentalLandLordIncome_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RentalLandLordIncome_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RentalLandLordIncome_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RentalLandLordIncome_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_RentalTenantPaymentsArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RentalTenantPayments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RentalTenantPayments_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_RentalTenantPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RentalTenantPayments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RentalTenantPayments_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RentalTenantPayments_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_RipoIndRentalIncSummaryArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_RipoIndRentalIncSummary_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_RipoIndRentalIncSummary_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_RipoIndRentalIncSummary_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ura_RipoIndRentalIncSummary_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Rtcs_Db_Ura_RipoIndRentalIncSummary_Order_By>>;
    where?: Maybe<Rtcs_Db_Ura_RipoIndRentalIncSummary_Bool_Exp>;
  };

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_TenantIncomeDetailArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_TenantIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_TenantIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_TenantIncomeDetail_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_TenantIncomeDetail_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_TenantIncomeDetail_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_TenantIncomeDetail_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_TownArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_Town_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_Town_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_Town_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ura_Town_AggregateArgs = {
  distinct_on?: Maybe<Array<Rtcs_Db_Ura_Town_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ura_Town_Order_By>>;
  where?: Maybe<Rtcs_Db_Ura_Town_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ursb_CompaniesAndBusinessNamesUrsbArgs = {
  distinct_on?: Maybe<
    Array<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column>
  >;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Order_By>>;
  where?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootRtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Select_Column>
    >;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<
      Array<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Order_By>
    >;
    where?: Maybe<Rtcs_Db_Ursb_CompaniesAndBusinessNamesUrsb_Bool_Exp>;
  };

/** expression to compare columns of type timestamp. All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars["timestamp"]>;
  _gt?: Maybe<Scalars["timestamp"]>;
  _gte?: Maybe<Scalars["timestamp"]>;
  _in?: Maybe<Array<Scalars["timestamp"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["timestamp"]>;
  _lte?: Maybe<Scalars["timestamp"]>;
  _neq?: Maybe<Scalars["timestamp"]>;
  _nin?: Maybe<Array<Scalars["timestamp"]>>;
};

/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars["timestamptz"]>;
  _gt?: Maybe<Scalars["timestamptz"]>;
  _gte?: Maybe<Scalars["timestamptz"]>;
  _in?: Maybe<Array<Scalars["timestamptz"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["timestamptz"]>;
  _lte?: Maybe<Scalars["timestamptz"]>;
  _neq?: Maybe<Scalars["timestamptz"]>;
  _nin?: Maybe<Array<Scalars["timestamptz"]>>;
};

export type RangeSliderTestQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RangeSliderTestQueryQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnura_IncomeTaxSummaryIndividual_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type PropertyImagesFragment = {
  __typename?: "rtcs_db_PH_kcca_ImgsData";
} & Pick<Rtcs_Db_Ph_Kcca_ImgsData, "FolderName" | "ImgPath" | "PropertyNo">;

export type PropertyImagesQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type PropertyImagesQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnkcca_ImgsData: Array<
    { __typename?: "rtcs_db_PH_kcca_ImgsData" } & PropertyImagesFragment
  >;
};

export type RegIndividualDataFragment = {
  __typename?: "rtcs_db_PH_RPT_IndividualRegistration";
} & Pick<
  Rtcs_Db_Ph_Rpt_IndividualRegistration,
  | "FullName"
  | "TinNo"
  | "FirstName"
  | "SurName"
  | "MiddleName"
  | "FamilyName"
  | "BirthDt"
  | "BirthCity"
  | "MobileNumber"
  | "EmailId"
  | "HomeAddrPlotno"
  | "HomeAddrStrretAddr"
  | "HomeAddrDistrictName"
  | "HomeAddrSubCountyName"
  | "HomeAddrParishName"
  | "HomeAddrLocalCouncil"
  | "IsIncomeSrcRental"
  | "IsIncomeSrcPrpty"
  | "IsIncomeSrcBsns"
  | "IsIncomeSrcEmp"
>;

export type GetPaginatedRegIndividualsQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type GetPaginatedRegIndividualsQuery = { __typename?: "query_root" } & {
  rtcs_db_FnRPT_IndividualRegistration: Array<
    {
      __typename?: "rtcs_db_PH_RPT_IndividualRegistration";
    } & RegIndividualDataFragment
  >;
  rtcs_db_FnRPT_IndividualRegistration_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type MasterIndividualDataFragment = {
  __typename?: "rtcs_db_PH_rmatch_IndividualRiskScore";
} & Pick<
  Rtcs_Db_Ph_Rmatch_IndividualRiskScore,
  | "Rnid"
  | "Firstname"
  | "Surname"
  | "Nationalid"
  | "Uratinno"
  | "Isincomesrcrental"
  | "Riskscore"
  | "Riskcode"
  | "Sumadjustedincome"
  | "Grossrentalincome"
  | "Rtnperiodyear"
  | "Numrentedproperties"
  | "Numproperties"
>;

export type GetPaginatedMasterIndividualsQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type GetPaginatedMasterIndividualsQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnrmatch_IndividualRiskScore: Array<
    {
      __typename?: "rtcs_db_PH_rmatch_IndividualRiskScore";
    } & MasterIndividualDataFragment
  >;
  rtcs_db_Fnrmatch_IndividualRiskScore_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type IndividualRiskScoresFilterQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type IndividualRiskScoresFilterQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnrmatch_IndividualRiskScore: Array<
    {
      __typename?: "rtcs_db_PH_rmatch_IndividualRiskScore";
    } & MasterIndividualDataFragment
  >;
};

export type GetMaxMinIndividualRiskScoreQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMaxMinIndividualRiskScoreQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnrmatch_IndividualRiskScore_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type KccaCommercialPropertiesFragment = {
  __typename?: "rtcs_db_PH_kcca_CommercialProperties";
} & Pick<
  Rtcs_Db_Ph_Kcca_CommercialProperties,
  | "Latitude"
  | "Longitude"
  | "Address"
  | "AnnualGrossIncome"
  | "BlockNo"
  | "CamvId"
  | "CurrencyType"
  | "CustomerId"
  | "DivisionId"
  | "File"
  | "FlatNo"
  | "GrossValue"
  | "Grossrentvalueadjusted"
  | "HouseNo"
  | "LastUpdated"
  | "WhoUpdated"
  | "VillageId"
  | "StreetId"
  | "SquareMeters"
  | "RippleNamiPropertySubtype"
  | "RateableValue"
  | "PropertyTypeId"
  | "Propertyrentedstatus"
  | "PropertyType"
  | "PropertyRentedStatusId"
  | "PropertyNo"
  | "PlotNo"
  | "ParishId"
  | "MonthlyGrossIncome"
  | "MonthlyGazetteValue"
  | "Line"
>;

export type KccaResidentialPropertiesFragment = {
  __typename?: "rtcs_db_PH_kcca_ResidentialProperties";
} & Pick<
  Rtcs_Db_Ph_Kcca_ResidentialProperties,
  | "RippleNamiPropertySubtype"
  | "SquareMeters"
  | "StreetId"
  | "VillageId"
  | "WhoUpdated"
  | "RateableValue"
  | "Propertyrentedstatus"
  | "PropertyTypeId"
  | "PropertyType"
  | "PropertyRentedStatusId"
  | "PropertyNo"
  | "PlotNo"
  | "ParishId"
  | "Longitude"
  | "DivisionId"
  | "File"
  | "FlatNo"
  | "GrossValue"
  | "Grossrentvalueadjusted"
  | "HouseNo"
  | "LastUpdated"
  | "Latitude"
  | "Line"
  | "AccomodationBedrooms"
  | "AccomodationBuiltUpArea"
  | "AccomodationOthers"
  | "Address"
  | "BlockNo"
  | "CamvId"
  | "CustomerId"
  | "DerivedNoOfBedrooms"
>;

export type GetKccaCommercialPropertiesQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type GetKccaCommercialPropertiesQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnkcca_CommercialProperties: Array<
    {
      __typename?: "rtcs_db_PH_kcca_CommercialProperties";
    } & KccaCommercialPropertiesFragment
  >;
};

export type GetKccaResidentialPropertiesQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type GetKccaResidentialPropertiesQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnkcca_ResidentialProperties: Array<
    {
      __typename?: "rtcs_db_PH_kcca_ResidentialProperties";
    } & KccaResidentialPropertiesFragment
  >;
};

export type NetworkPropertiesFragment = {
  __typename?: "rtcs_db_PH_rmatch_PropertiesNetwork";
} & Pick<
  Rtcs_Db_Ph_Rmatch_PropertiesNetwork,
  | "CamvId"
  | "CountyId"
  | "CurrentRateableValue"
  | "CustomerId"
  | "DistrictId"
  | "DivisionId"
  | "FlatNo"
  | "Gml"
  | "GrossValue"
  | "ImgPath"
  | "Latitude"
  | "Longitude"
  | "MapTitle"
  | "MatchCategory"
  | "MatchScore"
  | "NwscCustomerId"
  | "NwscCustomerName"
  | "ParishId"
  | "PropertyNo"
  | "PropertyRentedStatusId"
  | "PropertyTypeId"
  | "Rnid"
  | "Street"
  | "StreetId"
  | "SubCountyId"
  | "SubPropertyTypeId"
  | "Supplementary"
  | "VillageId"
>;

export type GetNetworkPropertiesQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type GetNetworkPropertiesQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnrmatch_PropertiesNetwork: Array<
    {
      __typename?: "rtcs_db_PH_rmatch_PropertiesNetwork";
    } & NetworkPropertiesFragment
  >;
};

export type GetPaginatedNetworkPropertiesQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type GetPaginatedNetworkPropertiesQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnrmatch_PropertiesNetwork: Array<
    {
      __typename?: "rtcs_db_PH_rmatch_PropertiesNetwork";
    } & NetworkPropertiesFragment
  >;
  rtcs_db_Fnrmatch_PropertiesNetwork_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type GetMaxMinNetworkPropertiesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMaxMinNetworkPropertiesQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnrmatch_PropertiesNetwork_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type PropertiesValuationFragment = {
  __typename?: "rtcs_db_PH_RPT_PropertyValuation";
} & Pick<
  Rtcs_Db_Ph_Rpt_PropertyValuation,
  | "AccomodationOthers"
  | "StreetId"
  | "AccomodationBedrooms"
  | "AccomodationBuiltUpArea"
  | "AnnualGazetteValue"
  | "AnnualGrossValue"
  | "BlockNo"
  | "CamvId"
  | "CountyId"
  | "CurrentRateableValue"
  | "CustomerId"
  | "DerivedNoOfBedrooms"
  | "DistrictId"
  | "DivisionId"
  | "FlatNo"
  | "Gml"
  | "Grossrentvalueadjusted"
  | "ImgPath"
  | "KccaNoOfBedrooms"
  | "Latitude"
  | "Longitude"
  | "MapTitle"
  | "MatchCategory"
  | "MatchScore"
  | "MonthlyGazetteValue"
  | "NumProperties"
  | "NumRentedProperties"
  | "NwscCustomerId"
  | "NwscCustomerName"
  | "ParishId"
  | "PlotNo"
  | "PropertyId"
  | "PropertyLegalEntity"
  | "PropertyNo"
  | "PropertyOwnerFirstname"
  | "PropertyOwnerSurname"
  | "PropertyRentedStatusId"
  | "PropertyType"
  | "PropertyTypeId"
  | "Propertyno"
  | "Rateablevalue"
  | "RentPerSquareMeter"
  | "RnSubpropertyType"
  | "Rnid"
  | "Source"
  | "SubCountyId"
  | "SourceCustomer"
  | "SquareMeters"
  | "SubPropertyTypeId"
  | "Supplementary"
  | "Village"
  | "VillageId"
  | "Grossvalue"
  | "Propertyrentedstatus"
  | "RnidCustomer"
>;

export type PropertiesValuationGetQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type PropertiesValuationGetQuery = { __typename?: "query_root" } & {
  rtcs_db_FnRPT_PropertyValuation: Array<
    {
      __typename?: "rtcs_db_PH_RPT_PropertyValuation";
    } & PropertiesValuationFragment
  >;
};

export type RegNonIndividualDataFragment = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual";
} & Pick<
  Rtcs_Db_Ph_Ura_RegNonIndividual,
  | "IsIncomeSrcBsns"
  | "ActvtyDesc"
  | "AppliedForTin"
  | "AuthctcdEmailId"
  | "AuthctcdFamilyName"
  | "AuthctcdFirstName"
  | "AuthctcdLandlineNumber"
  | "AuthctcdMobileNumber"
  | "AuthctcdOtherName"
  | "AuthctcdPrsnDesgn"
  | "AuthctcdSurname"
  | "BsnsCertNumber"
  | "BsnsName"
  | "BsnsaddrBuildname"
  | "BsnsaddrDistrictName"
  | "BsnsaddrLocalCouncil"
  | "BsnsaddrParishName"
  | "BsnsaddrPlotNo"
  | "BsnsaddrStreetAddr"
  | "BsnsaddrSubCountyName"
  | "BsnsaddrTownName"
  | "BsnsaddrTradeCentre"
  | "BsnsaddrVillageName"
  | "BusinessStartDt"
  | "EmailId"
  | "IsHldCmpInUganda"
  | "HoldingCompTin"
  | "HoldingCompName"
  | "File"
  | "ExistTinNo"
  | "IsIncomeSrcPrpty"
  | "IsIncomeSrcRental"
  | "IsSubsidaryComp"
  | "LandlineNumber"
  | "LastUpdated"
  | "Line"
  | "MainActivity"
  | "MobileNumber"
  | "OrgSubType"
  | "OrgSubTypeDesc"
  | "OrgType"
  | "PostaddrDistictName"
  | "PostaddrId"
  | "RefEmailId"
  | "RefFamilyName"
  | "RefFirstName"
  | "WhoUpdated"
  | "TradingName"
  | "TinNo"
  | "TaxPayerType"
  | "TaxPayerName"
  | "RefTin"
  | "RefSurname"
  | "RefPrsnDesgn"
  | "RefOtherName"
  | "RefMobileNumber"
  | "RefMiddleName"
  | "RefLandlineNumber"
  | "Rnid"
>;

export type RegNonIndividualFilterQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type RegNonIndividualFilterQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnura_RegNonIndividual: Array<
    {
      __typename?: "rtcs_db_PH_ura_RegNonIndividual";
    } & RegNonIndividualDataFragment
  >;
};

export type RegNonIndividualFilterOneQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type RegNonIndividualFilterOneQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnura_RegNonIndividual: Array<
    {
      __typename?: "rtcs_db_PH_ura_RegNonIndividual";
    } & RegNonIndividualDataFragment
  >;
};

export type PaginatedRegNonIndividualQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type PaginatedRegNonIndividualQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnura_RegNonIndividual: Array<
    {
      __typename?: "rtcs_db_PH_ura_RegNonIndividual";
    } & RegNonIndividualDataFragment
  >;
  rtcs_db_Fnura_RegNonIndividual_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type UpdateRegNonIndividualMutationVariables = Exact<{
  objects:
    | Array<Rtcs_Db_Ph_Ura_RegNonIndividual_Insert_Input>
    | Rtcs_Db_Ph_Ura_RegNonIndividual_Insert_Input;
}>;

export type UpdateRegNonIndividualMutation = {
  __typename?: "mutation_root";
} & {
  insert_rtcs_db_PH_ura_RegNonIndividual?: Maybe<
    { __typename?: "rtcs_db_PH_ura_RegNonIndividual_mutation_response" } & Pick<
      Rtcs_Db_Ph_Ura_RegNonIndividual_Mutation_Response,
      "affected_rows"
    > & {
        returning: Array<
          {
            __typename?: "rtcs_db_PH_ura_RegNonIndividual";
          } & RegNonIndividualDataFragment
        >;
      }
  >;
};

export type NonIndividualRiskScoresFragment = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualRiskScore";
} & Pick<
  Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore,
  | "Rnid"
  | "Taxpayerassumed"
  | "Tradingname"
  | "Uratinno"
  | "Isincomesrcrental"
  | "Riskscore"
  | "Compliancecategory"
  | "Avgadjustedgrossincomeperproperty"
  | "Grossrentalincome"
  | "Rtnperiodyear"
  | "Numproperties"
  | "Numrentedproperties"
  | "Sumofadjustedincome"
  | "Averagerateperrentedproperty"
  | "Riskcode"
>;

export type NonIndividualRiskScoresFilterQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type NonIndividualRiskScoresFilterQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnrmatch_NonIndividualRiskScore: Array<
    {
      __typename?: "rtcs_db_PH_rmatch_NonIndividualRiskScore";
    } & NonIndividualRiskScoresFragment
  >;
};

export type GetPaginatedNonIndividualRiskScoresQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type GetPaginatedNonIndividualRiskScoresQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnrmatch_NonIndividualRiskScore: Array<
    {
      __typename?: "rtcs_db_PH_rmatch_NonIndividualRiskScore";
    } & NonIndividualRiskScoresFragment
  >;
  rtcs_db_Fnrmatch_NonIndividualRiskScore_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type GetMaxMinNonIndividualRiskScoreQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMaxMinNonIndividualRiskScoreQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnrmatch_NonIndividualRiskScore_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type RegIndividualDataHpocFragment = {
  __typename?: "rtcs_db_PH_RPT_IndividualRegistration";
} & Pick<
  Rtcs_Db_Ph_Rpt_IndividualRegistration,
  | "FullName"
  | "TinNo"
  | "FirstName"
  | "SurName"
  | "MiddleName"
  | "FamilyName"
  | "BirthDt"
  | "BirthCity"
  | "MobileNumber"
  | "EmailId"
  | "HomeAddrPlotno"
  | "HomeAddrStrretAddr"
  | "HomeAddrDistrictName"
  | "HomeAddrSubCountyName"
  | "HomeAddrParishName"
  | "HomeAddrLocalCouncil"
  | "IsIncomeSrcRental"
  | "IsIncomeSrcPrpty"
  | "IsIncomeSrcBsns"
  | "IsIncomeSrcEmp"
>;

export type RegIndividualDataQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type RegIndividualDataQuery = { __typename?: "query_root" } & {
  rtcs_db_FnRPT_IndividualRegistration: Array<
    {
      __typename?: "rtcs_db_PH_RPT_IndividualRegistration";
    } & RegIndividualDataHpocFragment
  >;
};

export type RegIndividualSuggestionFragment = {
  __typename?: "rtcs_db_PH_RPT_IndividualRegistration";
} & Pick<
  Rtcs_Db_Ph_Rpt_IndividualRegistration,
  "TinNo" | "TaxPayerName" | "NationalId"
>;

export type RegIndividualSuggestionsQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type RegIndividualSuggestionsQuery = { __typename?: "query_root" } & {
  rtcs_db_FnRPT_IndividualRegistration: Array<
    {
      __typename?: "rtcs_db_PH_RPT_IndividualRegistration";
    } & RegIndividualSuggestionFragment
  >;
};

export type PropertyCompositeFragment = {
  __typename?: "rtcs_db_PH_RPT_PropertiesComposite";
} & Pick<
  Rtcs_Db_Ph_Rpt_PropertiesComposite,
  | "VillageId"
  | "Supplementary"
  | "SubPropertyTypeId"
  | "SubCountyId"
  | "StreetId"
  | "SourceCustomer"
  | "Source"
  | "RnidCustomer"
  | "Rnid"
  | "RateableValue"
  | "PropertyTypeId"
  | "PropertyRentedStatusId"
  | "PropertyNo"
  | "PlotNo"
  | "ParishId"
  | "NwscCustomerName"
  | "NwscCustomerId"
  | "NumRentedProperties"
  | "MatchScore"
  | "CustomerMatchScore"
  | "MatchCategory"
  | "NumProperties"
  | "MapTitle"
  | "Longitude"
  | "Latitude"
  | "ImgPath"
  | "GrossValue"
  | "Gml"
  | "FlatNo"
  | "EntityLegalName"
  | "Firstname"
  | "Surname"
  | "DivisionId"
  | "DistrictId"
  | "CustomerId"
  | "CurrentRateableValue"
  | "CountyId"
  | "CamvId"
  | "BlockNo"
>;

export type Rpt_PropertyCompositeQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type Rpt_PropertyCompositeQuery = { __typename?: "query_root" } & {
  rtcs_db_FnRPT_PropertiesComposite: Array<
    {
      __typename?: "rtcs_db_PH_RPT_PropertiesComposite";
    } & PropertyCompositeFragment
  >;
};

export type GetPaginatedRpt_PropertyCompositeQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type GetPaginatedRpt_PropertyCompositeQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_FnRPT_PropertiesComposite: Array<
    {
      __typename?: "rtcs_db_PH_RPT_PropertiesComposite";
    } & PropertyCompositeFragment
  >;
  rtcs_db_FnRPT_PropertiesComposite_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type GetMaxMinRpt_PropertyCompositeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMaxMinRpt_PropertyCompositeQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_FnRPT_PropertiesComposite_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type LandlordIncomeSummaryFragment = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeSummary";
} & Pick<
  Rtcs_Db_Ph_Ura_LandlordIncomeSummary,
  | "LandlordTin"
  | "LandlordTaxpayerName"
  | "TaxYear"
  | "ReturnNo"
  | "ReturnPeriodFrom"
  | "ReturnPeriodTo"
  | "RentReportedByTenants"
  | "GrossRentalIncome"
  | "RentDeclaredByLandlord"
  | "RmatchScore"
  | "Difference"
  | "DifferencePercentage"
  | "GrossDifference"
  | "GrossDifferencePercentage"
  | "LandlordTinSource"
  | "ReturnNoSource"
>;

export type GetPaginatedLandlordIncomeSummaryQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type GetPaginatedLandlordIncomeSummaryQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnura_LandlordIncomeSummary: Array<
    {
      __typename?: "rtcs_db_PH_ura_LandlordIncomeSummary";
    } & LandlordIncomeSummaryFragment
  >;
  rtcs_db_Fnura_LandlordIncomeSummary_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type LandlordIncomeDetailFragment = {
  __typename?: "rtcs_db_PH_ura_LandlordIncomeDetail";
} & Pick<
  Rtcs_Db_Ph_Ura_LandlordIncomeDetail,
  | "RtnRentalIncomeId"
  | "RtnNo"
  | "TinNo"
  | "TaxPayerName"
  | "RtnDt"
  | "RtnFromDt"
  | "RtnToDt"
  | "TenantTin"
  | "TenantName"
  | "PremiseLocation"
  | "TenancyPeriodFrom"
  | "TenancyPeriodTo"
  | "RentalIncome"
  | "IsAmendment"
  | "IsResident"
  | "RtnPeriodYear"
  | "RtnDtTaxSummary"
  | "RtnFromDtTaxSummary"
  | "RtnToDtTaxSummary"
  | "GrossRentalIncome"
  | "AssessedChrgbleRentlIncm"
  | "RentincIncome"
>;

export type GetPaginatedLandlordIncomeDetailQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type GetPaginatedLandlordIncomeDetailQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnura_LandlordIncomeDetail: Array<
    {
      __typename?: "rtcs_db_PH_ura_LandlordIncomeDetail";
    } & LandlordIncomeDetailFragment
  >;
  rtcs_db_Fnura_LandlordIncomeDetail_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type TenantIncomeDetailFragment = {
  __typename?: "rtcs_db_PH_ura_TenantIncomeDetail";
} & Pick<
  Rtcs_Db_Ph_Ura_TenantIncomeDetail,
  | "RtnRentPaidId"
  | "RtnNo"
  | "TenantTin"
  | "TenantTaxPayerName"
  | "LandlordTin"
  | "LandlordName"
  | "PremiseLocation"
  | "RmatchScore"
  | "TenancyPeriodFrom"
  | "TenancyPeriodTo"
  | "RentPaid"
  | "TaxYear"
  | "LandlordPeriodFrom"
  | "LandlordPeriodTo"
  | "Contribution"
  | "ContributionAmount"
>;

export type GetPaginatedTenantIncomeDetailQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type GetPaginatedTenantIncomeDetailQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnura_TenantIncomeDetail: Array<
    {
      __typename?: "rtcs_db_PH_ura_TenantIncomeDetail";
    } & TenantIncomeDetailFragment
  >;
  rtcs_db_Fnura_TenantIncomeDetail_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type ExcessiveOwnerOccupancyFragment = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy";
} & Pick<
  Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy,
  | "CustomerId"
  | "KccaFirstName"
  | "KccaMiddleName"
  | "KccaSurname"
  | "KccaTin"
  | "MatchScore"
  | "NumOwnedOccupied"
  | "SumRateableValue"
  | "MatchKccaUra"
  | "UraFirstname"
  | "UraMiddlename"
  | "UraSurname"
  | "EmailId"
  | "MobileNumber"
  | "IsIncomeSrcRental"
  | "UraTin"
  | "NationalId"
  | "SumAdjustedIncome"
  | "Rnid"
>;

export type ExcessiveOwnerOccupancyFilterQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type ExcessiveOwnerOccupancyFilterQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy: Array<
    {
      __typename?: "rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy";
    } & ExcessiveOwnerOccupancyFragment
  >;
};

export type GetPaginatedExcessiveOwnerOccupancyReportsQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type GetPaginatedExcessiveOwnerOccupancyReportsQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy: Array<
    {
      __typename?: "rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy";
    } & ExcessiveOwnerOccupancyFragment
  >;
  rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type GetMaxMinExcessiveOwnerOccupancyReportsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMaxMinExcessiveOwnerOccupancyReportsQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type IndividualCompliancePlusRiskReportFragment = {
  __typename?: "rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk";
} & Pick<
  Rtcs_Db_Ph_Rpt_IndividualComplianceSummaryPlusRisk,
  | "Customerid"
  | "Difference"
  | "Emailid"
  | "Grossrentalincome"
  | "Matchscore"
  | "Propincincome"
  | "Averagerateperrentedproperty"
  | "Compliancecategory"
  | "Countura"
  | "Percentagedifference"
  | "Numrentedproperties"
  | "Numproperties"
  | "Nationalid"
  | "Mobilenumber"
  | "Matchscoreattributes"
  | "Matchcategory"
  | "Kccatin"
  | "Kccasurname"
  | "Kccafirstname"
  | "Kccabirthdate"
  | "Kccamobile1"
  | "Kccamobile2"
  | "Kccaemail"
  | "Kccatelephone"
  | "Isincomesrcrental"
  | "Isincomesrcprpty"
  | "Isincomesrcemp"
  | "Isincomesrcbsns"
  | "Isamendment"
  | "Rnid"
  | "RiskCode"
  | "RiskScore"
  | "Rtnperiodyear"
  | "Rtnno"
  | "Source"
  | "Sumrateablevalue"
  | "Sumnonrateablevalue"
  | "Sumgrossvalue"
  | "Sumadjustedincome"
  | "Uratinno"
  | "Uramiddlename"
  | "Urafirstname"
  | "Urasurname"
>;

export type IndividualReportsFilterQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type IndividualReportsFilterQuery = { __typename?: "query_root" } & {
  rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk: Array<
    {
      __typename?: "rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk";
    } & IndividualCompliancePlusRiskReportFragment
  >;
};

export type GetPaginatedIndividualReportsQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type GetPaginatedIndividualReportsQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk: Array<
    {
      __typename?: "rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk";
    } & IndividualCompliancePlusRiskReportFragment
  >;
  rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type GetMaxMinIndividualReportsQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type GetMaxMinIndividualReportsQuery = { __typename?: "query_root" } & {
  rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type KccaCustomerRegistrationFragment = {
  __typename?: "rtcs_db_PH_RPT_KccaCustomerRegistrationData";
} & Pick<
  Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData,
  | "HaveRevenueAgent"
  | "HaveReference"
  | "HaveAlternatePrimaryContact"
  | "GenderId"
  | "FormerBusinessName"
  | "Firstname"
  | "File"
  | "EntityLegalName"
  | "Email"
  | "DateOfIncorporation"
  | "IdentificationDiplomaticId"
  | "IdentificationDrivingPermit"
  | "IdentificationEmployeeId"
  | "IdentificationFinancialCard"
  | "IdentificationNationalId"
  | "AcquiredName"
  | "Birthdate"
  | "IdentificationNssfNumber"
  | "IdentificationPassportNo"
  | "IdentificationVillageId"
  | "IdentificationVoterId"
  | "IdentificationWorkId"
  | "IsForcedAction"
  | "IsMinor"
  | "LastUpdated"
  | "Line"
  | "MiddleName"
  | "Mobile"
  | "MotherMaidenName"
  | "PlotNumber"
  | "PreviousBusinessAddressBuildingName"
  | "PreviousBusinessAddressCounty"
  | "PreviousBusinessAddressCountyId"
  | "PreviousBusinessAddressDistrict"
  | "PreviousBusinessAddressDistrictId"
  | "PreviousBusinessAddressParish"
  | "PreviousBusinessAddressParishId"
  | "PreviousBusinessAddressPlotNumber"
  | "PreviousBusinessAddressStreetName"
  | "PreviousBusinessAddressSubCountyId"
  | "PreviousBusinessAddressTradingCenter"
  | "PreviousBusinessAddressVillage"
  | "PreviousBusinessName"
  | "ResidentialAddressDistrict"
  | "ResidentialAddressSubCounty"
  | "ResidentialAddressVillage"
  | "Rnid"
  | "WhoUpdated"
  | "TradingCenter"
  | "TitleId"
  | "Tin"
  | "Telephone"
  | "Surname"
  | "SecondMobile"
  | "StreetName"
  | "ResidentialAddressParish"
  | "ResidentialAddressCounty"
  | "PreviousBusinessAddressVillageId"
  | "PreviousBusinessAddressSubCounty"
  | "Id"
  | "BoxNumber"
  | "BuildingName"
  | "CustomerApplicantTypeId"
  | "CustomerBusinessTypeId"
  | "CustomerBusinessType"
  | "Coin"
  | "CustomerApplicantType"
  | "CitizenshipCountryId"
  | "BusinessName"
  | "BoxDistrictId"
  | "CustomerType"
  | "CustomerTypeId"
>;

export type KccaCustomerRegistrationFilterQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type KccaCustomerRegistrationFilterQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_FnRPT_KccaCustomerRegistrationData: Array<
    {
      __typename?: "rtcs_db_PH_RPT_KccaCustomerRegistrationData";
    } & KccaCustomerRegistrationFragment
  >;
};

export type PaginatedKccaCustomerRegistrationQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type PaginatedKccaCustomerRegistrationQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_FnRPT_KccaCustomerRegistrationData: Array<
    {
      __typename?: "rtcs_db_PH_RPT_KccaCustomerRegistrationData";
    } & KccaCustomerRegistrationFragment
  >;
  rtcs_db_FnRPT_KccaCustomerRegistrationData_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type KccaPropertyRegistrationFragment = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistration";
} & Pick<
  Rtcs_Db_Ph_Rpt_KccaPropertyRegistration,
  | "Rnid"
  | "RnidOwner"
  | "CustomerId"
  | "LocationAddress"
  | "Address"
  | "PlotNo"
  | "FlatNo"
  | "HouseNo"
  | "BlockNo"
  | "StreetId"
  | "RoadOthers"
  | "ParticularsFrontages"
  | "Village"
  | "Parish"
  | "Division"
  | "PropertyNo"
  | "SerialNo"
  | "Latitude"
  | "Longitude"
  | "PropertyType"
  | "PropertySubType"
  | "PropertyRentedStatus"
  | "GrossValue"
  | "RateableValue"
  | "CurrentRateableValue"
  | "StatusId"
  | "CamvId"
  | "CreatedBy"
  | "CreatedDate"
  | "Firstname"
  | "Surname"
  | "MotherMaidenName"
  | "EntityLegalName"
  | "Birthdate"
  | "Mobile"
  | "SecondMobile"
  | "Email"
  | "BusinessName"
  | "Coin"
  | "MiddleName"
  | "Tin"
  | "OwnerTin"
  | "OwnerFirstname"
  | "OwnerLegalSurnameMaiden"
  | "OwnerTitle"
  | "ContactMobile1"
  | "ContactLandline"
  | "ContactEmail"
  | "AccomodationBedrooms"
>;

export type KccaPropertyRegistrationFilterQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type KccaPropertyRegistrationFilterQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_FnRPT_KccaPropertyRegistration: Array<
    {
      __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistration";
    } & KccaPropertyRegistrationFragment
  >;
};

export type PaginatedKccaPropertyRegistrationQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type PaginatedKccaPropertyRegistrationQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_FnRPT_KccaPropertyRegistration: Array<
    {
      __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistration";
    } & KccaPropertyRegistrationFragment
  >;
  rtcs_db_FnRPT_KccaPropertyRegistration_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type MlhudFragment = {
  __typename?: "rtcs_db_PH_rmatch_mlhudConsolidated";
} & Pick<
  Rtcs_Db_Ph_Rmatch_MlhudConsolidated,
  | "Firstname"
  | "Middlename"
  | "Fullname"
  | "Lastname"
  | "Gender"
  | "Titlenumber"
  | "Blocknumber"
  | "Plotno"
  | "Roadname"
  | "Address"
  | "Subcounty"
  | "County"
  | "District"
  | "Municipality"
  | "Tenuretype"
  | "Legalarea"
  | "Legalareaunittype"
  | "Volume"
  | "Folio"
  | "Titlelocation"
  | "Baunittype"
  | "Landtype"
  | "Landusetype"
  | "Condounitfactor"
  | "Condoplanno"
  | "Lafnumber"
  | "Phone"
  | "Description"
  | "Registrationdatetime"
  | "Cadlatitude"
  | "Cadlongitude"
  | "Cadlotnumber"
  | "Cadblocknumb"
  | "Cadroadname"
  | "Cadcounty"
  | "MatchResult"
>;

export type MlhudFilterQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type MlhudFilterQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnrmatch_mlhudConsolidated: Array<
    { __typename?: "rtcs_db_PH_rmatch_mlhudConsolidated" } & MlhudFragment
  >;
};

export type MlhudRegistrationQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type MlhudRegistrationQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnrmatch_mlhudConsolidated: Array<
    { __typename?: "rtcs_db_PH_rmatch_mlhudConsolidated" } & MlhudFragment
  >;
  rtcs_db_Fnrmatch_mlhudConsolidated_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type NiraFragment = { __typename?: "rtcs_db_PH_nira_RawData" } & Pick<
  Rtcs_Db_Ph_Nira_RawData,
  | "PseudoNIN"
  | "Surname"
  | "NationalID"
  | "MobileNumber"
  | "Email"
  | "GivenName"
  | "DateOfBirth"
>;

export type NiraFilterQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type NiraFilterQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnnira_RawData: Array<
    { __typename?: "rtcs_db_PH_nira_RawData" } & NiraFragment
  >;
};

export type NiraRegistrationQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type NiraRegistrationQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnnira_RawData: Array<
    { __typename?: "rtcs_db_PH_nira_RawData" } & NiraFragment
  >;
  rtcs_db_Fnnira_RawData_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type NonIndividualCompliancePlusRiskReportFragment = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk";
} & Pick<
  Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk,
  | "Averagerateperrentedproperty"
  | "Compliancecategory"
  | "Difference"
  | "Emailid"
  | "Formerbusinessname"
  | "Grossrentalincome"
  | "Isamendment"
  | "Isincomesrcrental"
  | "Kccacustomerid"
  | "Kccaname"
  | "Matchscore"
  | "Mobilenumber"
  | "Numproperties"
  | "Numrentedproperties"
  | "Percentagedifference"
  | "Rentincincome"
  | "Rnid"
  | "Rtnno"
  | "RiskCode"
  | "RiskScore"
  | "Rtnperiodyear"
  | "Source"
  | "Sumgrossvalue"
  | "Sumofadjustedincome"
  | "Sumrateablevalue"
  | "Tinno"
  | "Tradingcenter"
  | "Uraname"
  | "Uratinno"
>;

export type NonIndividualReportsFilterQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type NonIndividualReportsFilterQuery = { __typename?: "query_root" } & {
  rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk: Array<
    {
      __typename?: "rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk";
    } & NonIndividualCompliancePlusRiskReportFragment
  >;
};

export type GetPaginatedNonIndividualReportsQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type GetPaginatedNonIndividualReportsQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk: Array<
    {
      __typename?: "rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk";
    } & NonIndividualCompliancePlusRiskReportFragment
  >;
  rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type GetMaxMinNonIndividualReportsQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type GetMaxMinNonIndividualReportsQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type NwscCustomersFragment = {
  __typename?: "rtcs_db_PH_nwsc_Customers";
} & Pick<
  Rtcs_Db_Ph_Nwsc_Customers,
  | "Customerreference"
  | "CustomernamePay"
  | "Customerphoneno1Pay"
  | "Customerphoneno1Cons"
  | "AddressPay"
  | "AreaPay"
  | "LatitudePay"
  | "LongitudePay"
>;

export type NwscCustomersFilterQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type NwscCustomersFilterQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnnwsc_Customers: Array<
    { __typename?: "rtcs_db_PH_nwsc_Customers" } & NwscCustomersFragment
  >;
};

export type GetPaginatedCustomersQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type GetPaginatedCustomersQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnnwsc_Customers: Array<
    { __typename?: "rtcs_db_PH_nwsc_Customers" } & NwscCustomersFragment
  >;
  rtcs_db_Fnnwsc_Customers_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type OwnersAndOfficersFragment = {
  __typename?: "rtcs_db_PH_ura_CombinedOfficersAndOwners";
} & Pick<
  Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners,
  | "Individualtin"
  | "Name"
  | "Tinno"
  | "Taxpayername"
  | "Designation"
  | "Pershare"
  | "Address"
  | "Rtnno"
  | "Rtnholdingofficersdtlid"
  | "Rtnholdingownersdtlid"
  | "Rtnperiodyear"
  | "Rnid"
>;

export type OwnersAndOfficersFilterQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type OwnersAndOfficersFilterQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnura_CombinedOfficersAndOwners: Array<
    {
      __typename?: "rtcs_db_PH_ura_CombinedOfficersAndOwners";
    } & OwnersAndOfficersFragment
  >;
};

export type OwnersAndOfficersRegistrationQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type OwnersAndOfficersRegistrationQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnura_CombinedOfficersAndOwners: Array<
    {
      __typename?: "rtcs_db_PH_ura_CombinedOfficersAndOwners";
    } & OwnersAndOfficersFragment
  >;
  rtcs_db_Fnura_CombinedOfficersAndOwners_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type RCapturePropertiesFragment = {
  __typename?: "rtcs_db_PH_rcapture_RPTproperties";
} & Pick<
  Rtcs_Db_Ph_Rcapture_RpTproperties,
  | "Id"
  | "Blocknumber"
  | "Buildingcondition"
  | "Buildingname"
  | "County"
  | "District"
  | "Found"
  | "Geolocation"
  | "Identifier"
  | "Landlord1identifier"
  | "Landlotsize"
  | "Latitude"
  | "Lessor1firstname"
  | "Lessor1identifier"
  | "Lessor1lastname"
  | "Lessor2firstname"
  | "Lessor2identifier"
  | "Longitude"
  | "Modifiedby"
  | "Notes"
  | "Numberunits"
  | "Outcome"
  | "Owner1identifier"
  | "Parish"
  | "Pguid"
  | "Photo"
  | "Plotnumber"
  | "Propertyid"
  | "Propertyidsrc"
  | "Propertymanager1identifier"
  | "Region"
  | "Streetname"
  | "Subcounty"
  | "Type"
  | "Subtype"
  | "Totallessors"
  | "Totaltenants"
  | "Totalunits"
  | "Village"
  | "Websiteurl"
>;

export type RCapturePropertyLessorsFragment = {
  __typename?: "rtcs_db_PH_rcapture_RPTlessors";
} & Pick<
  Rtcs_Db_Ph_Rcapture_RpTlessors,
  | "Buildingname"
  | "Businesstradingname"
  | "Cin"
  | "County"
  | "Customerid"
  | "Customeridsrc"
  | "District"
  | "Email"
  | "Firstname"
  | "Id"
  | "Identifier"
  | "Lastname"
  | "Legalbusinessname"
  | "Nationality"
  | "Parish"
  | "Phone1"
  | "Phone2"
  | "Phone3"
  | "Phone4"
  | "Plotnumber"
  | "Positionincompany"
  | "Postaldistrict"
  | "Postalpobox"
  | "Region"
  | "Registeredbusinessnumber"
  | "Repdob"
  | "Repfirstname"
  | "Replastname"
  | "Repnationality"
  | "Repothercountry"
  | "Repphone1"
  | "Reptitle"
  | "Sguid"
  | "Streetname"
  | "Subcounty"
  | "Tin"
  | "Title"
  | "Tradinglicensenumber"
  | "Type"
  | "Village"
>;

export type RCapturePropertyTenantFragment = {
  __typename?: "rtcs_db_PH_rcapture_RPTtenants";
} & Pick<
  Rtcs_Db_Ph_Rcapture_RpTtenants,
  | "Actualrentpaidpermonth"
  | "Amountofrentreceipts"
  | "Businesstradingname"
  | "Cactualrentpaidpermonth"
  | "Currencyactualrent"
  | "Currencyreportedrent"
  | "Email"
  | "Estimatedshopsize"
  | "Firstname"
  | "Floornumber"
  | "Id"
  | "Identifier"
  | "Isunitlet"
  | "Lastname"
  | "Legalbusinessname"
  | "Mda"
  | "Nationality"
  | "Notes"
  | "Numberbathrooms"
  | "Numberbedrooms"
  | "Owneroccupiedorrented"
  | "Phone1"
  | "Phone2"
  | "Phone3"
  | "Phone4"
  | "Positionincompany"
  | "Registeredbusinessnumber"
  | "Rentreportedbylessorpermonth"
  | "Repemail"
  | "Repfirstname"
  | "Replastname"
  | "Repmiddlename"
  | "Repnationality"
  | "Repothercountry"
  | "Repphone1"
  | "Reptitle"
  | "Reptitleother"
  | "Shopname"
  | "Shopnumber"
  | "Tin"
  | "Title"
  | "Type"
  | "Typeofgoodssold"
>;

export type RCapturePropertyUnitTypeFragment = {
  __typename?: "rtcs_db_PH_rcapture_RPTUnittypes";
} & Pick<
  Rtcs_Db_Ph_Rcapture_RptUnittypes,
  | "Createdby"
  | "Currencyadvertisedrent"
  | "Id"
  | "Numberbathrooms"
  | "Numberbedrooms"
  | "Numberunitstype"
  | "Pguid"
  | "Uguid"
  | "Unitfurnished"
  | "Unitrent"
  | "Unitsize"
  | "Unittype"
  | "Uuidproperty"
>;

export type RCapturePropertiesFilterQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type RCapturePropertiesFilterQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnrcapture_RPTproperties: Array<
    {
      __typename?: "rtcs_db_PH_rcapture_RPTproperties";
    } & RCapturePropertiesFragment
  >;
};

export type RCapturePropertiesQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type RCapturePropertiesQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnrcapture_RPTproperties: Array<
    {
      __typename?: "rtcs_db_PH_rcapture_RPTproperties";
    } & RCapturePropertiesFragment
  >;
  rtcs_db_Fnrcapture_RPTproperties_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type RCapturePropertyDetailsQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type RCapturePropertyDetailsQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnrcapture_RPTproperties: Array<
    {
      __typename?: "rtcs_db_PH_rcapture_RPTproperties";
    } & RCapturePropertiesFragment
  >;
};

export type RCapturePropertyLessorsQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type RCapturePropertyLessorsQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnrcapture_RPTlessors: Array<
    {
      __typename?: "rtcs_db_PH_rcapture_RPTlessors";
    } & RCapturePropertyLessorsFragment
  >;
};

export type RCapturePropertyTenantsQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type RCapturePropertyTenantsQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnrcapture_RPTtenants: Array<
    {
      __typename?: "rtcs_db_PH_rcapture_RPTtenants";
    } & RCapturePropertyTenantFragment
  >;
};

export type RCapturePropertyUnitTypesQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type RCapturePropertyUnitTypesQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnrcapture_RPTUnittypes: Array<
    {
      __typename?: "rtcs_db_PH_rcapture_RPTUnittypes";
    } & RCapturePropertyUnitTypeFragment
  >;
};

export type UccReportFragment = { __typename?: "rtcs_db_PH_ucc_UCC" } & Pick<
  Rtcs_Db_Ph_Ucc_Ucc,
  | "Carrier"
  | "Count"
  | "Customerid"
  | "Firstname"
  | "Idnumber"
  | "Idtype"
  | "Kccamobile"
  | "Surname"
>;

export type UccReportFilterQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type UccReportFilterQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnucc_UCC: Array<
    { __typename?: "rtcs_db_PH_ucc_UCC" } & UccReportFragment
  >;
};

export type GetPaginateduccReportQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type GetPaginateduccReportQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnucc_UCC: Array<
    { __typename?: "rtcs_db_PH_ucc_UCC" } & UccReportFragment
  >;
  rtcs_db_Fnucc_UCC_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type SubscriberAndPropertyFragment = {
  __typename?: "rtcs_db_PH_umeme_SubscriberAndProperty";
} & Pick<
  Rtcs_Db_Ph_Umeme_SubscriberAndProperty,
  | "MeterNo"
  | "Customerno"
  | "CustomerName"
  | "District"
  | "Street"
  | "SupplyPointNo"
  | "TariffDesc"
  | "Telephone"
  | "Town"
  | "XCoord"
  | "YCoord"
  | "Longitude"
  | "Latitude"
>;

export type SubscriberAndPropertyFilterQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type SubscriberAndPropertyFilterQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnumeme_SubscriberAndProperty: Array<
    {
      __typename?: "rtcs_db_PH_umeme_SubscriberAndProperty";
    } & SubscriberAndPropertyFragment
  >;
};

export type GetPaginatedSubscriberAndPropertyQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type GetPaginatedSubscriberAndPropertyQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnumeme_SubscriberAndProperty: Array<
    {
      __typename?: "rtcs_db_PH_umeme_SubscriberAndProperty";
    } & SubscriberAndPropertyFragment
  >;
  rtcs_db_Fnumeme_SubscriberAndProperty_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type UraIndividualFragment = {
  __typename?: "rtcs_db_PH_RPT_IndividualRegistration";
} & Pick<
  Rtcs_Db_Ph_Rpt_IndividualRegistration,
  | "TinNo"
  | "TaxPayerType"
  | "TaxPayerName"
  | "TaxPayerId"
  | "SurName"
  | "RefTin"
  | "RefSurname"
  | "RefPrsnDesgn"
  | "RefOtherName"
  | "RefMobileNumber"
  | "RefLandlineNumber"
  | "RefFirstName"
  | "RefFamilyName"
  | "RefEmailId"
  | "PostaddrId"
  | "PostaddrDistictName"
  | "OtherName"
  | "NssfId"
  | "NationalId"
  | "MobileNumber"
  | "MiddleName"
  | "LandlineNumber"
  | "IsIncomeSrcRental"
  | "IsIncomeSrcPrpty"
  | "IsIncomeSrcEmp"
  | "IsIncomeSrcBsns"
  | "HomeAddrVillageName"
  | "HomeAddrTradeCentre"
  | "HomeAddrTownName"
  | "HomeAddrSubCountyName"
  | "HomeAddrStrretAddr"
  | "HomeAddrPlotno"
  | "HomeAddrParishName"
  | "HomeAddrLocalCouncil"
  | "HomeAddrDistrictName"
  | "HomeAddrBuildingname"
  | "Gender"
  | "FirstName"
  | "FamilyName"
  | "ExistTinNo"
  | "EmailId"
  | "CitizenCountry"
  | "BsnsName"
  | "BirthState"
  | "BirthDt"
  | "BirthCountry"
  | "TownName"
  | "AuthctcdTin"
  | "AuthctcdSurname"
  | "AuthctcdPrsnDesgn"
  | "AuthctcdOtherName"
  | "AuthctcdMobileNumber"
  | "AuthctcdLandlineNumber"
  | "AuthctcdFirstName"
  | "AuthctcdFamilyName"
  | "AuthctcdEmailId"
  | "AppliedForTin"
>;

export type UraIndividualFilterQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type UraIndividualFilterQuery = { __typename?: "query_root" } & {
  rtcs_db_FnRPT_IndividualRegistration: Array<
    {
      __typename?: "rtcs_db_PH_RPT_IndividualRegistration";
    } & UraIndividualFragment
  >;
};

export type GetPaginatedRegIndividualQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type GetPaginatedRegIndividualQuery = { __typename?: "query_root" } & {
  rtcs_db_FnRPT_IndividualRegistration: Array<
    {
      __typename?: "rtcs_db_PH_RPT_IndividualRegistration";
    } & UraIndividualFragment
  >;
  rtcs_db_FnRPT_IndividualRegistration_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type UraNonIndividualReportFragment = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual";
} & Pick<
  Rtcs_Db_Ph_Ura_RegNonIndividual,
  | "ActvtyDesc"
  | "AppliedForTin"
  | "AuthctcdEmailId"
  | "AuthctcdFamilyName"
  | "AuthctcdFirstName"
  | "AuthctcdLandlineNumber"
  | "AuthctcdMobileNumber"
  | "AuthctcdOtherName"
  | "AuthctcdPrsnDesgn"
  | "AuthctcdSurname"
  | "BsnsaddrBuildname"
  | "BsnsaddrDistrictName"
  | "BsnsaddrLocalCouncil"
  | "BsnsaddrParishName"
  | "BsnsaddrPlotNo"
  | "BsnsaddrStreetAddr"
  | "BsnsaddrSubCountyName"
  | "BsnsaddrTownName"
  | "BsnsaddrTradeCentre"
  | "BsnsaddrVillageName"
  | "BsnsCertNumber"
  | "BsnsName"
  | "BusinessStartDt"
  | "EmailId"
  | "ExistTinNo"
  | "HoldingCompName"
  | "HoldingCompTin"
  | "IsHldCmpInUganda"
  | "IsIncomeSrcBsns"
  | "IsIncomeSrcPrpty"
  | "IsIncomeSrcRental"
  | "IsSubsidaryComp"
  | "LandlineNumber"
  | "MainActivity"
  | "MobileNumber"
  | "OrgSubType"
  | "OrgSubTypeDesc"
  | "OrgType"
  | "PostaddrDistictName"
  | "PostaddrId"
  | "RefEmailId"
  | "RefFamilyName"
  | "RefFirstName"
  | "RefLandlineNumber"
  | "RefMiddleName"
  | "RefMobileNumber"
  | "RefOtherName"
  | "RefPrsnDesgn"
  | "RefSurname"
  | "RefTin"
  | "Rnid"
  | "TaxPayerName"
  | "TaxPayerType"
  | "TinNo"
  | "TradingName"
>;

export type UraNonIndividualReportFilterQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type UraNonIndividualReportFilterQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnura_RegNonIndividual: Array<
    {
      __typename?: "rtcs_db_PH_ura_RegNonIndividual";
    } & UraNonIndividualReportFragment
  >;
};

export type GetPaginatedRegNonIndividualsReportQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type GetPaginatedRegNonIndividualsReportQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnura_RegNonIndividual: Array<
    {
      __typename?: "rtcs_db_PH_ura_RegNonIndividual";
    } & UraNonIndividualReportFragment
  >;
  rtcs_db_Fnura_RegNonIndividual_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type UraIndividualTaxFragment = {
  __typename?: "rtcs_db_PH_RPT_IndividualTaxSummaryData";
} & Pick<
  Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData,
  | "TinNo"
  | "IsAmendment"
  | "IsResident"
  | "RtnNo"
  | "RtnPeriodYear"
  | "RtnFromDt"
  | "RtnToDt"
  | "RtnDt"
  | "EmplymntIncome"
  | "EmplymntTax"
  | "EmplymntRate"
  | "RentincTax"
  | "RentincRate"
  | "PropincIncome"
  | "PropincTax"
  | "PropincRate"
  | "TotIncome"
  | "TotTax"
  | "TotRate"
  | "FirstName"
  | "SurName"
  | "IsIncomeSrcRental"
  | "TotRentalIncm"
  | "PartnerRentIncome"
  | "GrossRentalIncome"
  | "DeductIncmTax"
  | "MotgageIntDecd"
  | "NetRentIncm"
  | "ThresholdAmnt"
  | "ChargeableIncome"
  | "TaxAssessed"
  | "ReturnType"
>;

export type UraIndividualTaxFilterQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type UraIndividualTaxFilterQuery = { __typename?: "query_root" } & {
  rtcs_db_FnRPT_IndividualTaxSummaryData: Array<
    {
      __typename?: "rtcs_db_PH_RPT_IndividualTaxSummaryData";
    } & UraIndividualTaxFragment
  >;
};

export type GetPaginatedRegIndividualsTaxQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type GetPaginatedRegIndividualsTaxQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_FnRPT_IndividualTaxSummaryData: Array<
    {
      __typename?: "rtcs_db_PH_RPT_IndividualTaxSummaryData";
    } & UraIndividualTaxFragment
  >;
  rtcs_db_FnRPT_IndividualTaxSummaryData_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type UraNonIndividualTaxReportFragment = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualTaxSummaryData";
} & Pick<
  Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData,
  | "AdjProfitLossRental"
  | "AdjRentlIncmLoss"
  | "AdministratingExp"
  | "AllowblApportionedCap"
  | "AssessedChrgblRentlIncm"
  | "AssessedRentlLoss"
  | "BadDebtWriteOff"
  | "BalanceCarryForward"
  | "BalancePrevPeriod"
  | "BrghtFwdAssessedLoss"
  | "BusinessIncome"
  | "BusinessRate"
  | "CostOfSales"
  | "BusinessTax"
  | "Debenture"
  | "DeferTaxLiability"
  | "Depreciation"
  | "EquityShare"
  | "FactoryRentRates"
  | "FinanceExp"
  | "FixedDeposit"
  | "GovtSecurities"
  | "GrossProfit"
  | "GrossRentalIncome"
  | "IsAmendment"
  | "IsResident"
  | "LandBuild"
  | "NonAllowblExp"
  | "OperatingExp"
  | "OtherInvestments"
  | "OthrAllowblExp"
  | "PayableAmtInc"
  | "PayableAmtRent"
  | "ProfitAfterDividend"
  | "ProfitAfterTax"
  | "ProfitBeforeTax"
  | "ProfitLossRental"
  | "ProposedDividend"
  | "ProvisionIncomeTax"
  | "RefundAmtInc"
  | "RefundAmtRent"
  | "RentincIncome"
  | "RentincRate"
  | "RentincTax"
  | "RentRates"
  | "Rnid"
  | "RtnDt"
  | "RtnFromDt"
  | "RtnNo"
  | "RtnPeriodYear"
  | "SecureTotLoan"
  | "SelfAssessTaxPaidInc"
  | "SelfAssessTaxPaidRent"
  | "SourceFund"
  | "TinNo"
  | "TotalPropFund"
  | "TotalReserve"
  | "TotalShareCapital"
  | "TotAdminExpense"
  | "TotBsnsProfSales"
  | "TotExpPurelyRental"
  | "TotFinancialExpense"
  | "TotFixedAsset"
  | "TotNonChargeableIncome"
  | "TotOperationExpense"
  | "TotOtherExpense"
  | "TotRentAdminiExpns"
  | "TotRentFinancExpns"
  | "TotRentIncmExpns"
  | "TotRentOprtgExpns"
  | "TotTaxesPaidInc"
  | "TotTaxLiability"
  | "TotTaxesPaidRent"
  | "TotTaxLiabilityInc"
  | "TotTaxLiabilityRent"
  | "TotTradeInvestment"
  | "TotWthdTaxDeduct"
  | "TotIncome"
  | "TotRate"
  | "TotTax"
  | "UnsecureTotLoan"
  | "TaxPayerName"
  | "TradingName"
  | "OrgSubType"
  | "IsIncomeSrcRental"
  | "IsIncomeSrcPrpty"
  | "MainActivity"
  | "BsnsName"
  | "OrgType"
  | "ReturnType"
>;

export type UraNonIndividualTaxReportFilterQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type UraNonIndividualTaxReportFilterQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_FnRPT_NonIndividualTaxSummaryData: Array<
    {
      __typename?: "rtcs_db_PH_RPT_NonIndividualTaxSummaryData";
    } & UraNonIndividualTaxReportFragment
  >;
};

export type GetPaginatedNonIndividualsTaxReportQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type GetPaginatedNonIndividualsTaxReportQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_FnRPT_NonIndividualTaxSummaryData: Array<
    {
      __typename?: "rtcs_db_PH_RPT_NonIndividualTaxSummaryData";
    } & UraNonIndividualTaxReportFragment
  >;
  rtcs_db_FnRPT_NonIndividualTaxSummaryData_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type NonIndividualIncomeTaxSummaryFragment = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualTaxSummaryData";
} & Pick<
  Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData,
  | "RtnPeriodYear"
  | "RtnNo"
  | "IsAmendment"
  | "TotIncome"
  | "TotTax"
  | "TotRate"
  | "RentincIncome"
  | "RentincTax"
  | "RentincRate"
  | "RtnDt"
  | "GrossRentalIncome"
  | "TotExpPurelyRental"
  | "OperatingExp"
  | "AdministratingExp"
  | "FinanceExp"
  | "ProfitLossRental"
  | "NonAllowblExp"
  | "AdjProfitLossRental"
  | "AllowblApportionedCap"
  | "OthrAllowblExp"
  | "AdjRentlIncmLoss"
  | "BrghtFwdAssessedLoss"
  | "AssessedChrgblRentlIncm"
  | "AssessedRentlLoss"
  | "SelfAssessTaxPaidRent"
  | "ReturnType"
>;

export type NonIndividualIncomeTaxSummaryQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type NonIndividualIncomeTaxSummaryQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_FnRPT_NonIndividualTaxSummaryData: Array<
    {
      __typename?: "rtcs_db_PH_RPT_NonIndividualTaxSummaryData";
    } & NonIndividualIncomeTaxSummaryFragment
  >;
};

export type NonIndividualIncomeTaxSummariesFragment = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualTaxSummaryData";
} & Pick<
  Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData,
  | "RtnPeriodYear"
  | "RtnNo"
  | "IsAmendment"
  | "TotIncome"
  | "TotTax"
  | "TotRate"
  | "GrossRentalIncome"
  | "RentincIncome"
  | "RentincTax"
  | "RentincRate"
  | "ChargeableIncomeP"
  | "RentincIncomeP"
  | "ReturnType"
  | "RtnDt"
>;

export type NonIndividualIncomeTaxSummariesQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type NonIndividualIncomeTaxSummariesQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_FnRPT_NonIndividualTaxSummaryData: Array<
    {
      __typename?: "rtcs_db_PH_RPT_NonIndividualTaxSummaryData";
    } & NonIndividualIncomeTaxSummariesFragment
  >;
};

export type Rpt_NonIndividualBalanceSheetFragment = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualBalanceSheet";
} & Pick<
  Rtcs_Db_Ph_Rpt_NonIndividualBalanceSheet,
  | "AccntRcvdOthers"
  | "AccntRcvdOverSix"
  | "AdvanceCorporate"
  | "AdvanceRecoverable"
  | "ApplicationFund"
  | "BalRevenueAuth"
  | "BankBalance"
  | "BankOverDraft"
  | "CnsmPckInventory"
  | "Debenture"
  | "DeferTaxLiability"
  | "DeferredAsset"
  | "Deposits"
  | "Depreciation"
  | "EquityShare"
  | "FinishTradeGoods"
  | "FixedDeposit"
  | "FurnitureFixture"
  | "GeneralReserve"
  | "GovtSecurities"
  | "HandCash"
  | "IncomeTaxProv"
  | "IntLeaseAsset"
  | "IntangibleAssets"
  | "IsAmendment"
  | "LandBuild"
  | "LessDrawings"
  | "LiableLeaseAsset"
  | "LoanSubCompany"
  | "MotorVehicles"
  | "NetCurrentAsset"
  | "NetFixedAsset"
  | "OtherInvestments"
  | "OtherProvision"
  | "OtherReserve"
  | "OthrCurrentAsset"
  | "OthrDeprecAsset"
  | "OthrLiability"
  | "PlantMachine"
  | "Prepayments"
  | "ProfitLossAccount"
  | "ProprietorCapital"
  | "ProvForBadDebts"
  | "RawMtrlInventory"
  | "RevaluationReserve"
  | "RtnId"
  | "RtnNo"
  | "RtnPeriodYear"
  | "SecureDebtIssued"
  | "SecureLoanBank"
  | "SecureLoanOther"
  | "SecureLoanParties"
  | "SecureTotLoan"
  | "SourceFund"
  | "StockInProcess"
  | "SundryCreditors"
  | "TinNo"
  | "TotAccntRcvdDebtors"
  | "TotAssetLoanAdvance"
  | "TotBalanceAvail"
  | "TotCurrLiability"
  | "TotCurrentAsset"
  | "TotFixedAsset"
  | "TotInventory"
  | "TotLiabilityProv"
  | "TotLoanAdvance"
  | "TotTradeInvestment"
  | "TotalLoanFund"
  | "TotalPropFund"
  | "TotalProvision"
  | "TranslationReserve"
  | "UnpaidMatureDebts"
  | "UnsecureCreditorLoan"
  | "UnsecureLoanBank"
  | "UnsecureLoanOther"
  | "UnsecureLoanParties"
  | "UnsecureTotLoan"
>;

export type Rpt_NonIndividualBalanceSheetDetailsQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type Rpt_NonIndividualBalanceSheetDetailsQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_FnRPT_NonIndividualBalanceSheet: Array<
    {
      __typename?: "rtcs_db_PH_RPT_NonIndividualBalanceSheet";
    } & Rpt_NonIndividualBalanceSheetFragment
  >;
};

export type UraIndividualsAssociatedBusinessFragment = {
  __typename?: "rtcs_db_PH_ura_IndividualsAssociatedBusiness";
} & Pick<
  Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness,
  | "Surname"
  | "TinNo"
  | "RegAssociatedEntityDtlId"
  | "FirstName"
  | "OtherName"
  | "FamilyName"
  | "AssociateTin"
  | "AssociateTaxPayerName"
  | "EntityDesignation"
  | "TaxPayerType"
  | "MainActivity"
  | "ActvtyDesc"
  | "WhoUpdated"
  | "File"
  | "LastUpdated"
  | "Line"
>;

export type IndividualsAssociatedBusinessReportQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type IndividualsAssociatedBusinessReportQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnura_IndividualsAssociatedBusiness: Array<
    {
      __typename?: "rtcs_db_PH_ura_IndividualsAssociatedBusiness";
    } & UraIndividualsAssociatedBusinessFragment
  >;
};

export type GetPaginatedIndividualsAssociatedBusinessQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type GetPaginatedIndividualsAssociatedBusinessQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnura_IndividualsAssociatedBusiness: Array<
    {
      __typename?: "rtcs_db_PH_ura_IndividualsAssociatedBusiness";
    } & UraIndividualsAssociatedBusinessFragment
  >;
  rtcs_db_Fnura_IndividualsAssociatedBusiness_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type UraNonIndividualsAssociatedBusinessFragment = {
  __typename?: "rtcs_db_PH_ura_NonIndividualsAssociatedBusiness";
} & Pick<
  Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness,
  | "EntityDesignation"
  | "TaxPayerType"
  | "MainActivity"
  | "ActvtyDesc"
  | "WhoUpdated"
  | "File"
  | "LastUpdated"
  | "Line"
  | "AssociateTin"
  | "AssociatedNonIndividual"
  | "RegAssociatedEntityDtlId"
  | "TaxPayerName"
  | "TinNo"
>;

export type NonIndividualsAssociatedBusinessReportQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type NonIndividualsAssociatedBusinessReportQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnura_NonIndividualsAssociatedBusiness: Array<
    {
      __typename?: "rtcs_db_PH_ura_NonIndividualsAssociatedBusiness";
    } & UraNonIndividualsAssociatedBusinessFragment
  >;
};

export type GetPaginatedNonIndividualsAssociatedBusinessQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type GetPaginatedNonIndividualsAssociatedBusinessQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnura_NonIndividualsAssociatedBusiness: Array<
    {
      __typename?: "rtcs_db_PH_ura_NonIndividualsAssociatedBusiness";
    } & UraNonIndividualsAssociatedBusinessFragment
  >;
  rtcs_db_Fnura_NonIndividualsAssociatedBusiness_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type UraRentalTenantPaymentsFragment = {
  __typename?: "rtcs_db_PH_ura_RentalTenantPayments";
} & Pick<
  Rtcs_Db_Ph_Ura_RentalTenantPayments,
  | "RtnRentPaidId"
  | "LandlordName"
  | "LandlordTin"
  | "PremiseLocation"
  | "RentPaid"
  | "RtnNo"
  | "TenancyPeriodFrom"
  | "TenancyPeriodTo"
>;

export type RptRentalTenantPaymentsFragment = {
  __typename?: "rtcs_db_PH_RPT_RentalTenantPayments";
} & Pick<
  Rtcs_Db_Ph_Rpt_RentalTenantPayments,
  | "File"
  | "IsAmendment"
  | "LandlordName"
  | "LandlordTin"
  | "LastUpdated"
  | "Line"
  | "PremiseLocation"
  | "RentPaid"
  | "RtnNo"
  | "RtnRentPaidId"
  | "TenancyPeriodFrom"
  | "TenancyPeriodTo"
  | "TenantName"
  | "TenantTin"
>;

export type UraRentalTenantPaymentsFilterQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type UraRentalTenantPaymentsFilterQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnura_RentalTenantPayments: Array<
    {
      __typename?: "rtcs_db_PH_ura_RentalTenantPayments";
    } & UraRentalTenantPaymentsFragment
  >;
};

export type RptRentalTenantPaymentsFilterQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type RptRentalTenantPaymentsFilterQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_FnRPT_RentalTenantPayments: Array<
    {
      __typename?: "rtcs_db_PH_RPT_RentalTenantPayments";
    } & RptRentalTenantPaymentsFragment
  >;
};

export type GetPaginatedUraRentalTenantPaymentsQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type GetPaginatedUraRentalTenantPaymentsQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnura_RentalTenantPayments: Array<
    {
      __typename?: "rtcs_db_PH_ura_RentalTenantPayments";
    } & UraRentalTenantPaymentsFragment
  >;
  rtcs_db_Fnura_RentalTenantPayments_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type GetPaginatedRptRentalTenantPaymentsQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type GetPaginatedRptRentalTenantPaymentsQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_FnRPT_RentalTenantPayments: Array<
    {
      __typename?: "rtcs_db_PH_RPT_RentalTenantPayments";
    } & RptRentalTenantPaymentsFragment
  >;
  rtcs_db_FnRPT_RentalTenantPayments_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type GetMaxMinUraRentalTenantPaymentsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMaxMinUraRentalTenantPaymentsQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnura_RentalTenantPayments_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type GetMaxMinRptRentalTenantPaymentsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMaxMinRptRentalTenantPaymentsQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_FnRPT_RentalTenantPayments_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type UraRentalLandLordIncomeFragment = {
  __typename?: "rtcs_db_PH_ura_RentalLandLordIncome";
} & Pick<
  Rtcs_Db_Ph_Ura_RentalLandLordIncome,
  | "PremiseLocation"
  | "RentalIncome"
  | "RtnDt"
  | "RtnFromDt"
  | "RtnNo"
  | "RtnRentalIncomeId"
  | "RtnToDt"
  | "TaxPayerName"
  | "TenancyPeriodFrom"
  | "TenancyPeriodTo"
  | "TenantName"
  | "TenantTin"
  | "TinNo"
>;

export type UraRentalLandLordIncomeReportQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type UraRentalLandLordIncomeReportQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnura_RentalLandLordIncome: Array<
    {
      __typename?: "rtcs_db_PH_ura_RentalLandLordIncome";
    } & UraRentalLandLordIncomeFragment
  >;
};

export type UgandaRegistrationSbFragment = {
  __typename?: "rtcs_db_PH_ursb_CompaniesAndBusinessNamesURSB";
} & Pick<
  Rtcs_Db_Ph_Ursb_CompaniesAndBusinessNamesUrsb,
  | "BusinessName"
  | "CompanyName"
  | "CompanyType"
  | "Subtype"
  | "DirectorFirstname"
  | "DirectorSurname"
  | "District"
  | "Parish"
  | "PartnersFirstname"
  | "PartnersSurname"
  | "RegistrationDate"
  | "RegistrationNumber"
  | "ShareholderFirstname"
  | "ShareholdersSurname"
  | "Street"
  | "Town"
  | "Trading"
  | "Village"
>;

export type GetPaginatedUgandaRegistrationQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type GetPaginatedUgandaRegistrationQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnursb_CompaniesAndBusinessNamesURSB: Array<
    {
      __typename?: "rtcs_db_PH_ursb_CompaniesAndBusinessNamesURSB";
    } & UgandaRegistrationSbFragment
  >;
  rtcs_db_Fnursb_CompaniesAndBusinessNamesURSB_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export type RegIndividualDataReportFragment = {
  __typename?: "rtcs_db_PH_RPT_IndividualComposite";
} & Pick<
  Rtcs_Db_Ph_Rpt_IndividualComposite,
  | "WhoUpdated_y"
  | "WhoUpdated_x"
  | "UraTinNo"
  | "UraSurName"
  | "UraOtherName"
  | "UraMiddleName"
  | "UraFirstName"
  | "UraFamilyName"
  | "TownName"
  | "TownId"
  | "TownCd"
  | "TaxPayerType"
  | "TaxPayerName"
  | "TaxPayerId"
  | "Surname"
  | "SumNonRateableValue"
  | "Source"
  | "Rnid"
  | "RefTin"
  | "RefSurname"
  | "RefPrsnDesgn"
  | "RefOtherName"
  | "RefMobileNumber"
  | "RefLandlineNumber"
  | "RefFirstName"
  | "RefEmailId"
  | "RefFamilyName"
  | "HomeAddrPlotno"
  | "HomeAddrStrretAddr"
  | "HomeAddrSubCountyName"
  | "HomeAddrTownName"
  | "HomeAddrTradeCentre"
  | "HomeAddrVillageName"
  | "IsIncomeSrcBsns"
  | "IsIncomeSrcEmp"
  | "IsIncomeSrcPrpty"
  | "IsIncomeSrcRental"
  | "LandlineNumber"
  | "LastUpdated_x"
  | "LastUpdated_y"
  | "Line_x"
  | "Line_y"
  | "MatchCategory"
  | "MatchScore"
  | "MatchScoreAttributes"
  | "MiddleName"
  | "MobileNumber"
  | "NationalId"
  | "NssfId"
  | "NumProperties"
  | "PostaddrDistictName"
  | "PostaddrId"
  | "CountUra"
  | "CustomerId"
  | "DistrictId"
  | "EmailId"
  | "EntityLegalName"
  | "ExistTinNo"
  | "File_x"
  | "File_y"
  | "Firstname"
  | "Fullname"
  | "Gender"
  | "HomeAddrBuildingname"
  | "HomeAddrDistrictName"
  | "HomeAddrLocalCouncil"
  | "HomeAddrParishName"
  | "AuthctcdLandlineNumber"
  | "AuthctcdMobileNumber"
  | "AuthctcdOtherName"
  | "AuthctcdPrsnDesgn"
  | "AuthctcdSurname"
  | "AuthctcdTin"
  | "BirthCity"
  | "BuildingName"
  | "BsnsName"
  | "BirthState"
  | "BirthDt"
  | "BirthCountry"
  | "BusinessName"
  | "CitizenCountry"
  | "Coin"
  | "AdditionalProperties"
  | "AppliedForTin"
  | "AuthctcdEmailId"
  | "AuthctcdFamilyName"
  | "AuthctcdFirstName"
>;

export type UraIndividualsQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type UraIndividualsQuery = { __typename?: "query_root" } & {
  rtcs_db_FnRPT_IndividualComposite: Array<
    {
      __typename?: "rtcs_db_PH_RPT_IndividualComposite";
    } & RegIndividualDataReportFragment
  >;
};

export type NonIndividualComplianceFragment = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport_aggregate";
} & {
  aggregate?: Maybe<
    {
      __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport_aggregate_fields";
    } & Pick<
      Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Aggregate_Fields,
      "count"
    > & {
        sum?: Maybe<
          {
            __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport_sum_fields";
          } & Pick<
            Rtcs_Db_Ph_Rmatch_NonIndividualComplianceSummaryReport_Sum_Fields,
            "Numrentedproperties" | "Grossrentalincome" | "Sumofadjustedincome"
          >
        >;
      }
  >;
};

export type NonIndividualComplianceQueryVariables = Exact<{
  [key: string]: never;
}>;

export type NonIndividualComplianceQuery = { __typename?: "query_root" } & {
  no_income: {
    __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport_aggregate";
  } & NonIndividualComplianceFragment;
  compliant: {
    __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport_aggregate";
  } & NonIndividualComplianceFragment;
  no_match: {
    __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport_aggregate";
  } & NonIndividualComplianceFragment;
  no_return: {
    __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport_aggregate";
  } & NonIndividualComplianceFragment;
  underreported: {
    __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport_aggregate";
  } & NonIndividualComplianceFragment;
};

export type IndividualComplianceFragment = {
  __typename?: "rtcs_db_PH_rmatch_IndividualComplianceSummaryReport_aggregate";
} & {
  aggregate?: Maybe<
    {
      __typename?: "rtcs_db_PH_rmatch_IndividualComplianceSummaryReport_aggregate_fields";
    } & Pick<
      Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Aggregate_Fields,
      "count"
    > & {
        sum?: Maybe<
          {
            __typename?: "rtcs_db_PH_rmatch_IndividualComplianceSummaryReport_sum_fields";
          } & Pick<
            Rtcs_Db_Ph_Rmatch_IndividualComplianceSummaryReport_Sum_Fields,
            "Numrentedproperties" | "Grossrentalincome" | "Sumadjustedincome"
          >
        >;
      }
  >;
};

export type IndividualComplianceQueryVariables = Exact<{
  [key: string]: never;
}>;

export type IndividualComplianceQuery = { __typename?: "query_root" } & {
  no_income: {
    __typename?: "rtcs_db_PH_rmatch_IndividualComplianceSummaryReport_aggregate";
  } & IndividualComplianceFragment;
  compliant: {
    __typename?: "rtcs_db_PH_rmatch_IndividualComplianceSummaryReport_aggregate";
  } & IndividualComplianceFragment;
  no_match: {
    __typename?: "rtcs_db_PH_rmatch_IndividualComplianceSummaryReport_aggregate";
  } & IndividualComplianceFragment;
  no_return: {
    __typename?: "rtcs_db_PH_rmatch_IndividualComplianceSummaryReport_aggregate";
  } & IndividualComplianceFragment;
  underreported: {
    __typename?: "rtcs_db_PH_rmatch_IndividualComplianceSummaryReport_aggregate";
  } & IndividualComplianceFragment;
};

export type UraRegIndividualFragmentFragment = {
  __typename?: "rtcs_db_PH_RPT_IndividualComposite";
} & Pick<
  Rtcs_Db_Ph_Rpt_IndividualComposite,
  | "UraTinNo"
  | "UraSurName"
  | "UraOtherName"
  | "UraMiddleName"
  | "UraFirstName"
  | "UraFamilyName"
  | "TownName"
  | "TownId"
  | "TownCd"
  | "TaxPayerType"
  | "TaxPayerName"
  | "TaxPayerId"
  | "Surname"
  | "SumNonRateableValue"
  | "Source"
  | "Rnid"
  | "RefTin"
  | "RefSurname"
  | "RefPrsnDesgn"
  | "RefOtherName"
  | "RefMobileNumber"
  | "RefLandlineNumber"
  | "RefFirstName"
  | "RefEmailId"
  | "RefFamilyName"
  | "HomeAddrPlotno"
  | "HomeAddrStrretAddr"
  | "HomeAddrSubCountyName"
  | "HomeAddrTownName"
  | "HomeAddrTradeCentre"
  | "HomeAddrVillageName"
  | "IsIncomeSrcBsns"
  | "IsIncomeSrcEmp"
  | "IsIncomeSrcPrpty"
  | "IsIncomeSrcRental"
  | "LandlineNumber"
  | "MatchCategory"
  | "MatchScore"
  | "MatchScoreAttributes"
  | "MiddleName"
  | "MobileNumber"
  | "NationalId"
  | "NssfId"
  | "NumProperties"
  | "PostaddrDistictName"
  | "PostaddrId"
  | "CountUra"
  | "CustomerId"
  | "DistrictId"
  | "EmailId"
  | "EntityLegalName"
  | "ExistTinNo"
  | "Firstname"
  | "Fullname"
  | "Gender"
  | "HomeAddrBuildingname"
  | "HomeAddrDistrictName"
  | "HomeAddrLocalCouncil"
  | "HomeAddrParishName"
  | "AuthctcdLandlineNumber"
  | "AuthctcdMobileNumber"
  | "AuthctcdOtherName"
  | "AuthctcdPrsnDesgn"
  | "AuthctcdSurname"
  | "AuthctcdTin"
  | "BirthCity"
  | "BuildingName"
  | "BsnsName"
  | "BirthState"
  | "BirthDt"
  | "BirthCountry"
  | "BusinessName"
  | "CitizenCountry"
  | "Coin"
  | "AdditionalProperties"
  | "AppliedForTin"
  | "AuthctcdEmailId"
  | "AuthctcdFamilyName"
  | "AuthctcdFirstName"
>;

export type UraRegIndividualDataQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type UraRegIndividualDataQuery = { __typename?: "query_root" } & {
  rtcs_db_FnRPT_IndividualComposite: Array<
    {
      __typename?: "rtcs_db_PH_RPT_IndividualComposite";
    } & UraRegIndividualFragmentFragment
  >;
};

export type UraTownQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type UraTownQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnura_Town: Array<
    { __typename?: "rtcs_db_PH_ura_Town" } & Pick<
      Rtcs_Db_Ph_Ura_Town,
      "TownName"
    >
  >;
};

export type KccaCustomerFragment = {
  __typename?: "rtcs_db_PH_kcca_Customer";
} & Pick<
  Rtcs_Db_Ph_Kcca_Customer,
  | "Id"
  | "Firstname"
  | "MiddleName"
  | "Surname"
  | "Email"
  | "MotherMaidenName"
  | "Coin"
  | "Tin"
  | "IdentificationNationalId"
  | "IdentificationNssfNumber"
  | "IdentificationPassportNo"
  | "PlotNumber"
  | "StreetName"
  | "ResidentialAddressVillageId"
  | "ResidentialAddressParishId"
  | "ResidentialAddressSubCountyId"
  | "ResidentialAddressCountyId"
  | "ResidentialAddressDistrictId"
  | "Mobile"
  | "SecondMobile"
  | "Telephone"
  | "Birthdate"
  | "BuildingName"
  | "TradingCenter"
  | "Rnid"
>;

export type KccaCustomerQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type KccaCustomerQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnkcca_Customer: Array<
    { __typename?: "rtcs_db_PH_kcca_Customer" } & KccaCustomerFragment
  >;
};

export type KccaCustomerVillageQueryVariables = Exact<{ [key: string]: never }>;

export type KccaCustomerVillageQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnkcca_Village: Array<
    { __typename?: "rtcs_db_PH_kcca_Village" } & Pick<
      Rtcs_Db_Ph_Kcca_Village,
      "Id" | "Name"
    >
  >;
};

export type KccaCustomerParishQueryVariables = Exact<{ [key: string]: never }>;

export type KccaCustomerParishQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnkcca_Parish: Array<
    { __typename?: "rtcs_db_PH_kcca_Parish" } & Pick<
      Rtcs_Db_Ph_Kcca_Parish,
      "Id" | "Name"
    >
  >;
};

export type KccaCustomerSubcountyQueryVariables = Exact<{
  [key: string]: never;
}>;

export type KccaCustomerSubcountyQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnkcca_SubCounty: Array<
    { __typename?: "rtcs_db_PH_kcca_SubCounty" } & Pick<
      Rtcs_Db_Ph_Kcca_SubCounty,
      "Id" | "Name"
    >
  >;
};

export type KccaCustomerCountyQueryVariables = Exact<{ [key: string]: never }>;

export type KccaCustomerCountyQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnkcca_County: Array<
    { __typename?: "rtcs_db_PH_kcca_County" } & Pick<
      Rtcs_Db_Ph_Kcca_County,
      "Id" | "Name"
    >
  >;
};

export type KccaCustomerDistrictQueryVariables = Exact<{
  [key: string]: never;
}>;

export type KccaCustomerDistrictQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnkcca_District: Array<
    { __typename?: "rtcs_db_PH_kcca_District" } & Pick<
      Rtcs_Db_Ph_Kcca_District,
      "Id" | "Name"
    >
  >;
};

export type NiraDataQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type NiraDataQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnnira_RawData: Array<
    { __typename?: "rtcs_db_PH_nira_RawData" } & Pick<
      Rtcs_Db_Ph_Nira_RawData,
      | "Surname"
      | "GivenName"
      | "DateOfBirth"
      | "NationalID"
      | "MobileNumber"
      | "Email"
      | "TinNumber"
      | "PseudoNIN"
    >
  >;
};

export type UraDataQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type UraDataQuery = { __typename?: "query_root" } & {
  rtcs_db_FnRPT_IndividualComposite: Array<
    { __typename?: "rtcs_db_PH_RPT_IndividualComposite" } & Pick<
      Rtcs_Db_Ph_Rpt_IndividualComposite,
      | "BirthState"
      | "BirthCountry"
      | "BsnsName"
      | "CitizenCountry"
      | "ExistTinNo"
      | "Fullname"
      | "Firstname"
      | "Surname"
      | "BirthDt"
    >
  >;
};

export type PropertiesDataQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type PropertiesDataQuery = { __typename?: "query_root" } & {
  rtcs_db_FnRPT_KccaPropertyRegistration: Array<
    { __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistration" } & Pick<
      Rtcs_Db_Ph_Rpt_KccaPropertyRegistration,
      | "MapTitle"
      | "Latitude"
      | "Longitude"
      | "CustomerId"
      | "LocationAddress"
      | "Address"
      | "PlotNo"
      | "FlatNo"
      | "HouseNo"
      | "BlockNo"
      | "StreetId"
      | "RoadOthers"
      | "ParticularsFrontages"
      | "Village"
      | "Parish"
      | "Division"
      | "PropertyNo"
      | "SerialNo"
      | "GprsCordinateX"
      | "GprsCordinateY"
      | "PropertyType"
      | "PropertySubType"
      | "PropertyRentedStatus"
      | "GrossValue"
      | "RateableValue"
      | "CurrentRateableValue"
      | "StatusId"
      | "CamvId"
      | "CreatedBy"
      | "CreatedDate"
      | "Firstname"
      | "Surname"
      | "MotherMaidenName"
      | "EntityLegalName"
      | "Birthdate"
      | "Mobile"
      | "SecondMobile"
      | "Email"
      | "BusinessName"
      | "Coin"
      | "MiddleName"
      | "Tin"
      | "OwnerTin"
      | "OwnerFirstname"
      | "OwnerLegalSurnameMaiden"
      | "OwnerTitle"
      | "ContactMobile1"
      | "ContactLandline"
      | "ContactEmail"
      | "AccomodationBedrooms"
    >
  >;
};

export type IndividualPropertiesFragment = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistrationData";
} & Pick<
  Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData,
  | "PlotNo"
  | "PropertyNo"
  | "HouseNo"
  | "CurrentRateableValue"
  | "BlockNo"
  | "Address"
  | "RateableValue"
  | "Village"
  | "StreetId"
  | "StatusId"
  | "PropertyRentedStatus"
  | "FlatNo"
  | "RoadOthers"
  | "GrossValue"
  | "Id"
  | "CustomerId"
  | "LocationAddress"
  | "LastUpdated"
  | "PropertyType"
  | "Parish"
  | "Division"
>;

export type IndividualPropertiesQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type IndividualPropertiesQuery = { __typename?: "query_root" } & {
  rtcs_db_FnRPT_KccaPropertyRegistrationData: Array<
    {
      __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistrationData";
    } & IndividualPropertiesFragment
  >;
};

export type IndividualsAssociatedBusinessFragment = {
  __typename?: "rtcs_db_PH_ura_IndividualsAssociatedBusiness";
} & Pick<
  Rtcs_Db_Ph_Ura_IndividualsAssociatedBusiness,
  | "TinNo"
  | "TaxPayerType"
  | "Surname"
  | "RegAssociatedEntityDtlId"
  | "OtherName"
  | "MainActivity"
  | "FirstName"
  | "FamilyName"
  | "EntityDesignation"
  | "ActvtyDesc"
  | "AssociateTaxPayerName"
  | "AssociateTin"
>;

export type IndividualsAssociatedBusinessQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type IndividualsAssociatedBusinessQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnura_IndividualsAssociatedBusiness: Array<
    {
      __typename?: "rtcs_db_PH_ura_IndividualsAssociatedBusiness";
    } & IndividualsAssociatedBusinessFragment
  >;
};

export type UraRptIndividualTaxSummariesFragment = {
  __typename?: "rtcs_db_PH_RPT_IndividualTaxSummaryData";
} & Pick<
  Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData,
  | "RtnPeriodYear"
  | "RtnNo"
  | "IsAmendment"
  | "TotIncome"
  | "TotTax"
  | "TotRate"
  | "GrossRentalIncome"
  | "RentincTax"
  | "RentincRate"
  | "RentalIncomeP"
  | "ChargeableIncomeP"
  | "ChargeableIncome"
  | "ReturnType"
  | "RtnDt"
>;

export type UraRptIndividualTaxSummariesQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type UraRptIndividualTaxSummariesQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_FnRPT_IndividualTaxSummaryData: Array<
    {
      __typename?: "rtcs_db_PH_RPT_IndividualTaxSummaryData";
    } & UraRptIndividualTaxSummariesFragment
  >;
};

export type RegIndividualAffiliatesFragment = {
  __typename?: "rtcs_db_PH_RPT_IndividualComposite";
} & Pick<
  Rtcs_Db_Ph_Rpt_IndividualComposite,
  | "RefFamilyName"
  | "RefFirstName"
  | "RefSurname"
  | "RefOtherName"
  | "RefTin"
  | "RefMobileNumber"
  | "RefEmailId"
  | "RefPrsnDesgn"
  | "RefLandlineNumber"
>;

export type KccaCustomerReferenceIndividualAffiliatesFragment = {
  __typename?: "rtcs_db_PH_kcca_CustomerReference";
} & Pick<
  Rtcs_Db_Ph_Kcca_CustomerReference,
  "Id" | "CustomerId" | "MiddleName" | "Firstname" | "Surname"
>;

export type KccaContactPersonIndividualAffiliatesFragment = {
  __typename?: "rtcs_db_PH_kcca_CustomerContactPerson";
} & Pick<
  Rtcs_Db_Ph_Kcca_CustomerContactPerson,
  "Id" | "CustomerId" | "MiddleName" | "Firstname" | "Surname"
>;

export type KccaCustomerGuardianIndividualAffiliatesFragment = {
  __typename?: "rtcs_db_PH_kcca_CustomerGuardian";
} & Pick<
  Rtcs_Db_Ph_Kcca_CustomerGuardian,
  "Id" | "Surname" | "MiddleName" | "Firstname" | "CustomerId"
>;

export type KccaCustomerBusinessAssociateIndividualAffiliatesFragment = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessAssociate";
} & Pick<
  Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate,
  "Id" | "CustomerId" | "MiddleName" | "Firstname" | "Surname"
>;

export type RegIndividualAffiliatesQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type RegIndividualAffiliatesQuery = { __typename?: "query_root" } & {
  rtcs_db_FnRPT_IndividualComposite: Array<
    {
      __typename?: "rtcs_db_PH_RPT_IndividualComposite";
    } & RegIndividualAffiliatesFragment
  >;
};

export type KccaIndividualAffiliatesQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type KccaIndividualAffiliatesQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnkcca_CustomerReference: Array<
    {
      __typename?: "rtcs_db_PH_kcca_CustomerReference";
    } & KccaCustomerReferenceIndividualAffiliatesFragment
  >;
  rtcs_db_Fnkcca_CustomerContactPerson: Array<
    {
      __typename?: "rtcs_db_PH_kcca_CustomerContactPerson";
    } & KccaContactPersonIndividualAffiliatesFragment
  >;
  rtcs_db_Fnkcca_CustomerGuardian: Array<
    {
      __typename?: "rtcs_db_PH_kcca_CustomerGuardian";
    } & KccaCustomerGuardianIndividualAffiliatesFragment
  >;
  rtcs_db_Fnkcca_CustomerBusinessAssociate: Array<
    {
      __typename?: "rtcs_db_PH_kcca_CustomerBusinessAssociate";
    } & KccaCustomerBusinessAssociateIndividualAffiliatesFragment
  >;
};

export type UraRptIndividualTaxSummaryFragment = {
  __typename?: "rtcs_db_PH_RPT_IndividualTaxSummaryData";
} & Pick<
  Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData,
  | "RtnPeriodYear"
  | "RtnNo"
  | "IsAmendment"
  | "TotIncome"
  | "TotTax"
  | "RentincTax"
  | "GrossRentalIncome"
  | "DeductIncmTax"
  | "MotgageIntDecd"
  | "ChargeableIncome"
  | "ThresholdAmnt"
  | "NetRentIncm"
  | "TaxAssessed"
  | "EmplymntIncome"
  | "PropincIncome"
  | "TotRentalIncm"
  | "PartnerRentIncome"
  | "IsResident"
  | "RtnDt"
  | "ReturnType"
>;

export type UraRptIndividualTaxSummaryQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type UraRptIndividualTaxSummaryQuery = { __typename?: "query_root" } & {
  rtcs_db_FnRPT_IndividualTaxSummaryData: Array<
    {
      __typename?: "rtcs_db_PH_RPT_IndividualTaxSummaryData";
    } & UraRptIndividualTaxSummaryFragment
  >;
};

export type IndividualMortgageFragment = {
  __typename?: "rtcs_db_PH_ura_IndividualMortgage";
} & Pick<
  Rtcs_Db_Ph_Ura_IndividualMortgage,
  | "DeedNo"
  | "FinanceName"
  | "FinanceTin"
  | "IntersetMortgage"
  | "PeriodFrom"
  | "PeriodTo"
  | "TotalMortgage"
>;

export type IndividualMortgageQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type IndividualMortgageQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnura_IndividualMortgage: Array<
    {
      __typename?: "rtcs_db_PH_ura_IndividualMortgage";
    } & IndividualMortgageFragment
  >;
};

export type IndividualTenantFragment = {
  __typename?: "rtcs_db_PH_ura_RentalLandLordIncome";
} & Pick<
  Rtcs_Db_Ph_Ura_RentalLandLordIncome,
  | "TenantName"
  | "TenantTin"
  | "PremiseLocation"
  | "TenancyPeriodFrom"
  | "TenancyPeriodTo"
  | "RentalIncome"
>;

export type IndividualTenantsQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type IndividualTenantsQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnura_RentalLandLordIncome: Array<
    {
      __typename?: "rtcs_db_PH_ura_RentalLandLordIncome";
    } & IndividualTenantFragment
  >;
};

export type IndividualExcessiveOwnerOccupancyFragment = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy";
} & Pick<
  Rtcs_Db_Ph_Rmatch_RMatchSummary916ExcessiveOwnerOccupancy,
  | "CustomerId"
  | "EmailId"
  | "IsIncomeSrcRental"
  | "KccaFirstName"
  | "KccaMiddleName"
  | "KccaSurname"
  | "KccaTin"
  | "MatchKccaUra"
  | "MatchScore"
  | "MobileNumber"
  | "NationalId"
  | "NumOwnedOccupied"
  | "NumProperties"
  | "ScoreMatchAtributes"
  | "SumAdjustedIncome"
  | "SumGrossValue"
  | "SumNonRateableValue"
  | "SumRateableValue"
  | "TotalRentedProperties"
  | "UraFirstname"
  | "UraMatchingTins"
  | "UraMiddlename"
  | "UraSurname"
  | "UraTin"
>;

export type IndividualExcessiveOwnerOccupancyQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type IndividualExcessiveOwnerOccupancyQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy: Array<
    {
      __typename?: "rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy";
    } & IndividualExcessiveOwnerOccupancyFragment
  >;
};

export type RMatchIndividualsFragment = {
  __typename?: "rtcs_db_PH_RPT_IndividualComposite";
} & Pick<
  Rtcs_Db_Ph_Rpt_IndividualComposite,
  | "CountUra"
  | "CustomerId"
  | "Firstname"
  | "Fullname"
  | "Gml"
  | "NationalId"
  | "Rnid"
  | "UraTinNo"
  | "UraFirstName"
  | "UraSurName"
  | "UraMiddleName"
  | "UraFamilyName"
  | "Source"
  | "Surname"
  | "MatchScore"
>;

export type GetRMatchIndividualsQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type GetRMatchIndividualsQuery = { __typename?: "query_root" } & {
  rtcs_db_FnRPT_IndividualComposite: Array<
    {
      __typename?: "rtcs_db_PH_RPT_IndividualComposite";
    } & RMatchIndividualsFragment
  >;
};

export type UraIndividualsWithNoTinFragment = {
  __typename?: "rtcs_db_PH_rmatch_RMatchSummary904";
} & Pick<
  Rtcs_Db_Ph_Rmatch_RMatchSummary904,
  | "CustomerId"
  | "KccaFirstName"
  | "KccaMiddleName"
  | "KccaSurname"
  | "KccaTin"
  | "KccaMobile1"
  | "KccaMobile2"
  | "KccaLandline"
  | "KccaDob"
  | "KccaEmail"
  | "KccaNin"
  | "NumProperties"
  | "TotalRentedProperties"
  | "SumGrossValue"
  | "SumRateableValue"
>;

export type IndividualUnregisteredLandLordQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type IndividualUnregisteredLandLordQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnrmatch_RMatchSummary904: Array<
    {
      __typename?: "rtcs_db_PH_rmatch_RMatchSummary904";
    } & UraIndividualsWithNoTinFragment
  >;
};

export type IndividualPropertyDetailsFragment = {
  __typename?: "rtcs_db_PH_rmatch_IndividualComplianceDetailReport";
} & Pick<
  Rtcs_Db_Ph_Rmatch_IndividualComplianceDetailReport,
  | "Address"
  | "Blockno"
  | "Createddate"
  | "Currentrateablevalue"
  | "Customerid"
  | "District"
  | "Division"
  | "Emailid"
  | "File"
  | "Firstname"
  | "Flatno"
  | "Fullname"
  | "Grossrentvalueadjusted"
  | "Grossvalue"
  | "Houseno"
  | "Isincomesrcbsns"
  | "Isincomesrcemp"
  | "Isincomesrcprpty"
  | "Isincomesrcrental"
  | "Kccabirthdate"
  | "Kccaemail"
  | "Kccafirstname"
  | "Kccamiddlename"
  | "Kccamobile1"
  | "Kccamobile2"
  | "Kccasurname"
  | "Kccatelephone"
  | "LastUpdated"
  | "Latitude"
  | "Line"
  | "Longitude"
  | "Matchscore"
  | "Customermatchscore"
  | "Mobilenumber"
  | "Ownerfirstname"
  | "Ownerlegalsurnamemaiden"
  | "Parish"
  | "Plotno"
  | "Propertyno"
  | "Propertyrentedstatus"
  | "Propertyrnid"
  | "Propertytype"
  | "Rateablevalue"
  | "Rnidowner"
  | "Source"
  | "Streetid"
  | "Subpropertytype"
  | "Surname"
  | "Urafirstname"
  | "Uramiddlename"
  | "Urasurname"
  | "Uratinno"
  | "Maptitle"
  | "Village"
>;

export type IndividualPropertyDetailsQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type IndividualPropertyDetailsQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnrmatch_IndividualComplianceDetailReport: Array<
    {
      __typename?: "rtcs_db_PH_rmatch_IndividualComplianceDetailReport";
    } & IndividualPropertyDetailsFragment
  >;
};

export type UraNonIndividualFragment = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual";
} & Pick<
  Rtcs_Db_Ph_Ura_RegNonIndividual,
  | "Rnid"
  | "BsnsCertNumber"
  | "BsnsaddrPlotNo"
  | "BsnsaddrStreetAddr"
  | "BsnsaddrBuildname"
  | "BsnsaddrDistrictName"
  | "BsnsaddrTownName"
  | "BsnsaddrSubCountyName"
  | "BsnsaddrParishName"
  | "BsnsaddrVillageName"
  | "TradingName"
  | "EmailId"
  | "LandlineNumber"
  | "MobileNumber"
  | "IsIncomeSrcRental"
  | "IsIncomeSrcBsns"
  | "IsIncomeSrcPrpty"
  | "TinNo"
  | "TaxPayerName"
  | "BsnsName"
  | "HoldingCompTin"
  | "HoldingCompName"
  | "IsSubsidaryComp"
  | "OrgType"
  | "OrgSubType"
  | "OrgSubTypeDesc"
  | "MainActivity"
  | "ActvtyDesc"
>;

export type UraNonIndividualQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type UraNonIndividualQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnura_RegNonIndividual: Array<
    {
      __typename?: "rtcs_db_PH_ura_RegNonIndividual";
    } & UraNonIndividualFragment
  >;
};

export type KccaCustomerNonIndividualFragment = {
  __typename?: "rtcs_db_PH_kcca_Customer";
} & Pick<
  Rtcs_Db_Ph_Kcca_Customer,
  | "Coin"
  | "Tin"
  | "EntityLegalName"
  | "BusinessName"
  | "BuildingName"
  | "DateOfIncorporation"
  | "TradingCenter"
  | "CustomerTypeId"
  | "CustomerApplicantTypeId"
  | "CustomerBusinessTypeId"
  | "Telephone"
  | "Id"
  | "Mobile"
  | "SecondMobile"
  | "Email"
  | "PlotNumber"
  | "StreetName"
  | "ResidentialAddressCountyId"
  | "ResidentialAddressDistrictId"
  | "ResidentialAddressParishId"
  | "ResidentialAddressSubCountyId"
  | "ResidentialAddressVillageId"
  | "Rnid"
>;

export type KccaCustomerResidentialAddressCountyQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type KccaCustomerResidentialAddressCountyQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnkcca_County: Array<
    { __typename?: "rtcs_db_PH_kcca_County" } & Pick<
      Rtcs_Db_Ph_Kcca_County,
      "Name"
    >
  >;
};

export type KccaCustomerResidentialAddressDistrictQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type KccaCustomerResidentialAddressDistrictQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnkcca_District: Array<
    { __typename?: "rtcs_db_PH_kcca_District" } & Pick<
      Rtcs_Db_Ph_Kcca_District,
      "Name"
    >
  >;
};

export type KccaCustomerResidentialAddressParishQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type KccaCustomerResidentialAddressParishQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnkcca_Parish: Array<
    { __typename?: "rtcs_db_PH_kcca_Parish" } & Pick<
      Rtcs_Db_Ph_Kcca_Parish,
      "Name"
    >
  >;
};

export type KccaCustomerResidentialAddressSubCountyQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type KccaCustomerResidentialAddressSubCountyQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnkcca_SubCounty: Array<
    { __typename?: "rtcs_db_PH_kcca_SubCounty" } & Pick<
      Rtcs_Db_Ph_Kcca_SubCounty,
      "Name"
    >
  >;
};

export type KccaCustomerResidentialAddressVillageQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type KccaCustomerResidentialAddressVillageQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnkcca_Village: Array<
    { __typename?: "rtcs_db_PH_kcca_Village" } & Pick<
      Rtcs_Db_Ph_Kcca_Village,
      "Name"
    >
  >;
};

export type KccaCustomerNonIndividualQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type KccaCustomerNonIndividualQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnkcca_Customer: Array<
    {
      __typename?: "rtcs_db_PH_kcca_Customer";
    } & KccaCustomerNonIndividualFragment
  >;
};

export type KccaCustomerTypeQueryVariables = Exact<{ [key: string]: never }>;

export type KccaCustomerTypeQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnkcca_CustomerType: Array<
    { __typename?: "rtcs_db_PH_kcca_CustomerType" } & Pick<
      Rtcs_Db_Ph_Kcca_CustomerType,
      "Id" | "Name"
    >
  >;
};

export type KccaApplicantTypeQueryVariables = Exact<{ [key: string]: never }>;

export type KccaApplicantTypeQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnkcca_CustomerApplicantType: Array<
    { __typename?: "rtcs_db_PH_kcca_CustomerApplicantType" } & Pick<
      Rtcs_Db_Ph_Kcca_CustomerApplicantType,
      "Id" | "Name"
    >
  >;
};

export type KccaBusinessTypeQueryVariables = Exact<{ [key: string]: never }>;

export type KccaBusinessTypeQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnkcca_CustomerBusinessType: Array<
    { __typename?: "rtcs_db_PH_kcca_CustomerBusinessType" } & Pick<
      Rtcs_Db_Ph_Kcca_CustomerBusinessType,
      "Id" | "Name"
    >
  >;
};

export type UraAuthIndividualFragment = {
  __typename?: "rtcs_db_PH_ura_RegNonIndividual";
} & Pick<
  Rtcs_Db_Ph_Ura_RegNonIndividual,
  | "AuthctcdSurname"
  | "TinNo"
  | "AuthctcdFirstName"
  | "AuthctcdOtherName"
  | "AuthctcdPrsnDesgn"
  | "AuthctcdMobileNumber"
  | "AuthctcdEmailId"
  | "AuthctcdFamilyName"
  | "AuthctcdLandlineNumber"
>;

export type UraRegAssociateFragment = {
  __typename?: "rtcs_db_PH_ura_RegAssociates";
} & Pick<
  Rtcs_Db_Ph_Ura_RegAssociates,
  | "RnidAssociate"
  | "FirstName"
  | "AssociateTin"
  | "FamilyName"
  | "Surname"
  | "EntityDesignation"
>;

export type NonIndividualAffilatesAndIndividualsQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type NonIndividualAffilatesAndIndividualsQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnura_RegNonIndividual: Array<
    {
      __typename?: "rtcs_db_PH_ura_RegNonIndividual";
    } & UraAuthIndividualFragment
  >;
  rtcs_db_Fnura_RegAssociates: Array<
    { __typename?: "rtcs_db_PH_ura_RegAssociates" } & UraRegAssociateFragment
  >;
};

export type NonIndividualRentExpensesFragment = {
  __typename?: "rtcs_db_PH_ura_RentExpenseDetails";
} & Pick<Rtcs_Db_Ph_Ura_RentExpenseDetails, "Name" | "Expensetype" | "Amount">;

export type NonIndividualRentExpensesQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type NonIndividualRentExpensesQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnura_RentExpenseDetails: Array<
    {
      __typename?: "rtcs_db_PH_ura_RentExpenseDetails";
    } & NonIndividualRentExpensesFragment
  >;
};

export type NonIndividualOwnersAndOfficersFragment = {
  __typename?: "rtcs_db_PH_ura_CombinedOfficersAndOwners";
} & Pick<
  Rtcs_Db_Ph_Ura_CombinedOfficersAndOwners,
  | "Individualtin"
  | "Name"
  | "Tinno"
  | "Taxpayername"
  | "Designation"
  | "Pershare"
  | "Address"
  | "Rtnno"
  | "Rtnholdingofficersdtlid"
  | "Rtnholdingownersdtlid"
  | "Rtnperiodyear"
>;

export type NonIndividualOwnersAndOfficersQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type NonIndividualOwnersAndOfficersQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnura_CombinedOfficersAndOwners: Array<
    {
      __typename?: "rtcs_db_PH_ura_CombinedOfficersAndOwners";
    } & OwnersAndOfficersFragment
  >;
};

export type RMatchNonIndividualFragment = {
  __typename?: "rtcs_db_PH_RPT_NonIndividualComposite";
} & Pick<
  Rtcs_Db_Ph_Rpt_NonIndividualComposite,
  | "Gml"
  | "CustomerId"
  | "KccaName"
  | "MatchScore"
  | "MatchCategory"
  | "NumProperties"
  | "Rnid"
  | "Source"
  | "UraName"
  | "UraTinNo"
  | "AdditionalOwners"
  | "AdditionalProperties"
>;

export type GetRMatchNonIndividualQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type GetRMatchNonIndividualQuery = { __typename?: "query_root" } & {
  rtcs_db_FnRPT_NonIndividualComposite: Array<
    {
      __typename?: "rtcs_db_PH_RPT_NonIndividualComposite";
    } & RMatchNonIndividualFragment
  >;
};

export type NonIndividualPropertiesQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type NonIndividualPropertiesQuery = { __typename?: "query_root" } & {
  rtcs_db_FnRPT_KccaPropertyRegistration: Array<
    { __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistration" } & Pick<
      Rtcs_Db_Ph_Rpt_KccaPropertyRegistration,
      | "MapTitle"
      | "Latitude"
      | "Longitude"
      | "CustomerId"
      | "LocationAddress"
      | "Address"
      | "PlotNo"
      | "FlatNo"
      | "HouseNo"
      | "BlockNo"
      | "StreetId"
      | "RoadOthers"
      | "ParticularsFrontages"
      | "Village"
      | "Parish"
      | "Division"
      | "PropertyNo"
      | "SerialNo"
      | "GprsCordinateX"
      | "GprsCordinateY"
      | "PropertyType"
      | "PropertySubType"
      | "PropertyRentedStatus"
      | "GrossValue"
      | "RateableValue"
      | "CurrentRateableValue"
      | "StatusId"
      | "CamvId"
      | "CreatedBy"
      | "CreatedDate"
      | "Firstname"
      | "Surname"
      | "MotherMaidenName"
      | "EntityLegalName"
      | "Birthdate"
      | "Mobile"
      | "SecondMobile"
      | "Email"
      | "BusinessName"
      | "Coin"
      | "MiddleName"
      | "Tin"
      | "OwnerTin"
      | "OwnerFirstname"
      | "OwnerLegalSurnameMaiden"
      | "OwnerTitle"
      | "ContactMobile1"
      | "ContactLandline"
      | "ContactEmail"
      | "AccomodationBedrooms"
    >
  >;
};

export type NonIndividualPropertyDetailsFragment = {
  __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceDetailReport";
} & Pick<
  Rtcs_Db_Ph_Rmatch_NonIndividualComplianceDetailReport,
  | "Address"
  | "Blockno"
  | "Createddate"
  | "Currentrateablevalue"
  | "District"
  | "Division"
  | "Entitylegalname"
  | "File"
  | "Flatno"
  | "Grossrentvalueadjusted"
  | "Grossvalue"
  | "Houseno"
  | "Kccacustomerid"
  | "Kccaname"
  | "LastUpdated"
  | "Latitude"
  | "Line"
  | "Longitude"
  | "Matchscore"
  | "Customermatchscore"
  | "Ownerfirstname"
  | "Ownerlegalsurnamemaiden"
  | "Parish"
  | "Plotno"
  | "Propertyno"
  | "Propertyrentedstatus"
  | "Propertyrnid"
  | "Propertytype"
  | "Rateablevalue"
  | "Rnidowner"
  | "Source"
  | "Streetid"
  | "Subpropertytype"
  | "Taxpayerassumed"
  | "Tin"
  | "Tradingcenter"
  | "Village"
  | "Maptitle"
  | "WhoUpdated"
>;

export type NonIndividualPropertyDetailsQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type NonIndividualPropertyDetailsQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnrmatch_NonIndividualComplianceDetailReport: Array<
    {
      __typename?: "rtcs_db_PH_rmatch_NonIndividualComplianceDetailReport";
    } & NonIndividualPropertyDetailsFragment
  >;
};

export type NonIndividualsAssociatedIndividualsFragment = {
  __typename?: "rtcs_db_PH_ura_NonIndividualsAssociatedBusiness";
} & Pick<
  Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness,
  | "TaxPayerName"
  | "TaxPayerType"
  | "TinNo"
  | "ActvtyDesc"
  | "AssociateTin"
  | "AssociatedNonIndividual"
  | "EntityDesignation"
  | "MainActivity"
  | "RegAssociatedEntityDtlId"
>;

export type NonIndividualsAssociatedIndividualsQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type NonIndividualsAssociatedIndividualsQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnura_NonIndividualsAssociatedBusiness: Array<
    {
      __typename?: "rtcs_db_PH_ura_NonIndividualsAssociatedBusiness";
    } & NonIndividualsAssociatedIndividualsFragment
  >;
};

export type KccaPropertiesFragment = {
  __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistrationData";
} & Pick<
  Rtcs_Db_Ph_Rpt_KccaPropertyRegistrationData,
  | "Address"
  | "BlockNo"
  | "CurrentRateableValue"
  | "CreatedDate"
  | "Id"
  | "PlotNo"
  | "FlatNo"
  | "HouseNo"
  | "StreetId"
  | "Village"
  | "Parish"
  | "Division"
  | "PropertyNo"
  | "LocationAddress"
  | "PropertyRentedStatus"
  | "GrossValue"
  | "RateableValue"
  | "CamvId"
>;

export type KccaPropertiesQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type KccaPropertiesQuery = { __typename?: "query_root" } & {
  rtcs_db_FnRPT_KccaPropertyRegistrationData: Array<
    {
      __typename?: "rtcs_db_PH_RPT_KccaPropertyRegistrationData";
    } & KccaPropertiesFragment
  >;
};

export type KccaPropertyDetailsFragment = {
  __typename?: "rtcs_db_PH_kcca_RawProperties1";
} & Pick<
  Rtcs_Db_Ph_Kcca_RawProperties1,
  | "BuildingName"
  | "CordinateX"
  | "CordinateY"
  | "PropertySubType"
  | "PropertyType"
  | "Rv"
  | "Gv"
  | "ParticularsBlockName"
  | "ParticularsBlockNumber"
  | "ParticularsFolioNumber"
  | "ParticularsHouseNumber"
  | "ParticularsIsTitled"
  | "ParticularsPlotNumber"
  | "ParticularsPlotShape"
  | "ParticularsPropertyName"
  | "ParticularsRoadStreet"
  | "ParticularsTypeOfLandInterest"
  | "AccomodationBathroom"
  | "AccomodationBathroomShower"
  | "AccomodationBedrooms"
  | "AccomodationBuiltUpArea"
  | "AccomodationComment"
  | "AccomodationDiningRooms"
  | "AccomodationEntertainmentRoom"
  | "AccomodationEnviromentalNoise"
  | "AccomodationGarage"
  | "AccomodationKitchen"
  | "AccomodationLaundry"
  | "AccomodationOthers"
  | "AccomodationSecurity"
  | "AccomodationShops"
  | "AccomodationShower"
  | "AccomodationSittingRoomLoungeNumber"
  | "AccomodationStore"
  | "AccomodationStudyRoom"
  | "AccomodationVerandah"
  | "AccomodationWc"
  | "AccomodationWcBathroom"
  | "AccomodationWcBathroomShower"
  | "AccomodationWcShower"
  | "ParticularsAccess"
  | "ParticularsFrontages"
  | "ParticularsGpsEastingsUtm"
  | "ParticularsGpsNorthingsUtm"
  | "ParticularsLandReference"
  | "ParticularsNeighborhood"
  | "ParticularsTopographyOthers"
  | "ParticularsTopograpy"
  | "ParticularsVolume"
  | "PropertyOwnerOccupiedOrRented"
  | "Pvillage"
  | "Id"
>;

export type KccaPropertyDetailsQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type KccaPropertyDetailsQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnkcca_RawProperties1: Array<
    {
      __typename?: "rtcs_db_PH_kcca_RawProperties1";
    } & KccaPropertyDetailsFragment
  >;
};

export type KccaPropertyOwnerQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type KccaPropertyOwnerQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnkcca_Property: Array<
    { __typename?: "rtcs_db_PH_kcca_Property" } & Pick<
      Rtcs_Db_Ph_Kcca_Property,
      "CustomerId"
    >
  >;
};

export type KccaCustomerAlternateOwnerFragment = {
  __typename?: "rtcs_db_PH_kcca_RawProperties1";
} & Pick<
  Rtcs_Db_Ph_Kcca_RawProperties1,
  | "ContactAddress"
  | "ContactCountry"
  | "ContactCountyMunicipality"
  | "ContactDistrict"
  | "ContactEmail"
  | "ContactMobile1"
  | "ContactMobile2"
  | "ContactParishWard"
  | "ContactSubcountryDivision"
  | "ContactVillageCellZone"
>;

export type KccaCustomerAlternateOwnerQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type KccaCustomerAlternateOwnerQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnkcca_RawProperties1: Array<
    {
      __typename?: "rtcs_db_PH_kcca_RawProperties1";
    } & KccaCustomerAlternateOwnerFragment
  >;
};

export type KccaCustomerPrimaryOwnerFragment = {
  __typename?: "rtcs_db_PH_kcca_Customer";
} & Pick<
  Rtcs_Db_Ph_Kcca_Customer,
  | "BusinessName"
  | "Coin"
  | "EntityLegalName"
  | "Firstname"
  | "MiddleName"
  | "Surname"
  | "MotherMaidenName"
  | "Tin"
  | "Id"
>;

export type KccaCustomerPrimaryOwnerQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type KccaCustomerPrimaryOwnerQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnkcca_Customer: Array<
    {
      __typename?: "rtcs_db_PH_kcca_Customer";
    } & KccaCustomerPrimaryOwnerFragment
  >;
};

export type KccaCustomerSecondaryOwnerFragment = {
  __typename?: "rtcs_db_PH_RPT_KccaCustomerRegistrationData";
} & Pick<
  Rtcs_Db_Ph_Rpt_KccaCustomerRegistrationData,
  | "CustomerApplicantType"
  | "CustomerBusinessType"
  | "CustomerType"
  | "Email"
  | "IdentificationNationalId"
  | "IdentificationNssfNumber"
  | "Mobile"
  | "MotherMaidenName"
  | "ResidentialAddressCounty"
  | "ResidentialAddressDistrict"
  | "ResidentialAddressParish"
  | "ResidentialAddressSubCounty"
  | "ResidentialAddressVillage"
  | "StreetName"
>;

export type KccaCustomerSecondaryOwnerQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type KccaCustomerSecondaryOwnerQuery = { __typename?: "query_root" } & {
  rtcs_db_FnRPT_KccaCustomerRegistrationData: Array<
    {
      __typename?: "rtcs_db_PH_RPT_KccaCustomerRegistrationData";
    } & KccaCustomerSecondaryOwnerFragment
  >;
};

export type KccaCustomerBusinessAssociateAffiliatesFragment = {
  __typename?: "rtcs_db_PH_kcca_CustomerBusinessAssociate";
} & Pick<
  Rtcs_Db_Ph_Kcca_CustomerBusinessAssociate,
  | "AcquiredName"
  | "CustomerId"
  | "Firstname"
  | "Id"
  | "MiddleName"
  | "MotherMaidenName"
  | "OfficialDesignation"
  | "Surname"
>;

export type KccaCustomerBusinessAssociateAffiliatesQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type KccaCustomerBusinessAssociateAffiliatesQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnkcca_CustomerBusinessAssociate: Array<
    {
      __typename?: "rtcs_db_PH_kcca_CustomerBusinessAssociate";
    } & KccaCustomerBusinessAssociateAffiliatesFragment
  >;
};

export type KccaCustomerContactPersonAffiliatesFragment = {
  __typename?: "rtcs_db_PH_kcca_CustomerContactPerson";
} & Pick<
  Rtcs_Db_Ph_Kcca_CustomerContactPerson,
  | "AcquiredName"
  | "CustomerId"
  | "Email"
  | "Firstname"
  | "Id"
  | "MiddleName"
  | "Mobile"
  | "MotherMaidenName"
  | "OtherTitle"
  | "RelationshipDesignation"
  | "Surname"
  | "Telephone"
  | "TitleId"
>;

export type KccaCustomerContactPersonAffiliatesQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type KccaCustomerContactPersonAffiliatesQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnkcca_CustomerContactPerson: Array<
    {
      __typename?: "rtcs_db_PH_kcca_CustomerContactPerson";
    } & KccaCustomerContactPersonAffiliatesFragment
  >;
};

export type KccaCustomerReferenceAffiliatesFragment = {
  __typename?: "rtcs_db_PH_kcca_CustomerReference";
} & Pick<
  Rtcs_Db_Ph_Kcca_CustomerReference,
  | "AcquiredName"
  | "Coin"
  | "CustomerId"
  | "Email"
  | "Firstname"
  | "Id"
  | "MiddleName"
  | "Mobile"
  | "RelationshipDesignation"
  | "Surname"
>;

export type KccaCustomerReferenceAffiliatesQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type KccaCustomerReferenceAffiliatesQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnkcca_CustomerReference: Array<
    {
      __typename?: "rtcs_db_PH_kcca_CustomerReference";
    } & KccaCustomerReferenceAffiliatesFragment
  >;
};

export type KccaCustomerRevenueAgentAffiliatesFragment = {
  __typename?: "rtcs_db_PH_kcca_CustomerRevenueAgent";
} & Pick<
  Rtcs_Db_Ph_Kcca_CustomerRevenueAgent,
  "AgentLegalName" | "CustomerId" | "Email" | "Mobile" | "Telephone"
>;

export type KccaCustomerRevenueAgentAffiliatesQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type KccaCustomerRevenueAgentAffiliatesQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_Fnkcca_CustomerRevenueAgent: Array<
    {
      __typename?: "rtcs_db_PH_kcca_CustomerRevenueAgent";
    } & KccaCustomerRevenueAgentAffiliatesFragment
  >;
};

export type PropertyUtilitiesFragment = {
  __typename?: "rtcs_db_PH_nwsc_Customers";
} & Pick<
  Rtcs_Db_Ph_Nwsc_Customers,
  | "CustomernamePay"
  | "Customerreference"
  | "Customerphoneno1Pay"
  | "Customerphoneno2Pay"
  | "Customerphoneno3Pay"
  | "AddressPay"
  | "AreaPay"
  | "LatitudePay"
  | "LongitudePay"
>;

export type NwscCustomerInfoQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type NwscCustomerInfoQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnnwsc_Customers: Array<
    { __typename?: "rtcs_db_PH_nwsc_Customers" } & PropertyUtilitiesFragment
  >;
};

export type NwscTransactionsFragment = {
  __typename?: "rtcs_db_PH_nwsc_RPT_Transactions";
} & Pick<
  Rtcs_Db_Ph_Nwsc_Rpt_Transactions,
  "Value" | "Period" | "Type" | "Customerreference"
>;

export type PropertyUtilitiesQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type PropertyUtilitiesQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnnwsc_RPT_Transactions: Array<
    {
      __typename?: "rtcs_db_PH_nwsc_RPT_Transactions";
    } & NwscTransactionsFragment
  >;
};

export type NwscTransactionsQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type NwscTransactionsQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnnwsc_RPT_Transactions: Array<
    {
      __typename?: "rtcs_db_PH_nwsc_RPT_Transactions";
    } & NwscTransactionsFragment
  >;
};

export type MinMaxUraTransactionsQueryVariables = Exact<{
  aggregateParams: Scalars["String"];
}>;

export type MinMaxUraTransactionsQuery = { __typename?: "query_root" } & {
  rtcs_db_Fnnwsc_RPT_Transactions_aggregatetxt: Array<
    { __typename?: "rtcs_db_aggregate_result_txt" } & Pick<
      Rtcs_Db_Aggregate_Result_Txt,
      "operation" | "value"
    >
  >;
};

export type IndividualCompositeVisibilityDataFragment = {
  __typename?: "rtcs_db_PH_RPT_IndividualComposite";
} & Pick<
  Rtcs_Db_Ph_Rpt_IndividualComposite,
  | "Rnid"
  | "Firstname"
  | "MiddleName"
  | "Surname"
  | "EmailId"
  | "UraTinNo"
  | "BirthDt"
>;

export type GetVisibilityIndividualCompositeQueryVariables = Exact<{
  params: Scalars["String"];
}>;

export type GetVisibilityIndividualCompositeQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_FnRPT_IndividualComposite: Array<
    {
      __typename?: "rtcs_db_PH_RPT_IndividualComposite";
    } & IndividualCompositeVisibilityDataFragment
  >;
};

export type GetPaginatedVisibilityIndividualCompositeQueryVariables = Exact<{
  params: Scalars["String"];
  aggregateParams: Scalars["String"];
}>;

export type GetPaginatedVisibilityIndividualCompositeQuery = {
  __typename?: "query_root";
} & {
  rtcs_db_FnRPT_IndividualComposite: Array<
    {
      __typename?: "rtcs_db_PH_RPT_IndividualComposite";
    } & IndividualCompositeVisibilityDataFragment
  >;
  rtcs_db_FnRPT_IndividualComposite_aggregatecm: Array<
    { __typename?: "rtcs_db_aggregate_result" } & Pick<
      Rtcs_Db_Aggregate_Result,
      "operation" | "value"
    >
  >;
};

export const PropertyImagesFragmentDoc = gql`
  fragment PropertyImages on rtcs_db_PH_kcca_ImgsData {
    FolderName
    ImgPath
    PropertyNo
  }
`;
export const RegIndividualDataFragmentDoc = gql`
  fragment RegIndividualData on rtcs_db_PH_RPT_IndividualRegistration {
    FullName
    TinNo
    FirstName
    SurName
    MiddleName
    FamilyName
    BirthDt
    BirthCity
    MobileNumber
    EmailId
    HomeAddrPlotno
    HomeAddrStrretAddr
    HomeAddrDistrictName
    HomeAddrSubCountyName
    HomeAddrParishName
    HomeAddrLocalCouncil
    IsIncomeSrcRental
    IsIncomeSrcPrpty
    IsIncomeSrcBsns
    IsIncomeSrcEmp
  }
`;
export const MasterIndividualDataFragmentDoc = gql`
  fragment MasterIndividualData on rtcs_db_PH_rmatch_IndividualRiskScore {
    Rnid
    Firstname
    Surname
    Nationalid
    Uratinno
    Isincomesrcrental
    Riskscore
    Riskcode
    Sumadjustedincome
    Grossrentalincome
    Rtnperiodyear
    Numrentedproperties
    Numproperties
  }
`;
export const KccaCommercialPropertiesFragmentDoc = gql`
  fragment KccaCommercialProperties on rtcs_db_PH_kcca_CommercialProperties {
    Latitude
    Longitude
    Address
    AnnualGrossIncome
    BlockNo
    CamvId
    CurrencyType
    CustomerId
    DivisionId
    File
    FlatNo
    GrossValue
    Grossrentvalueadjusted
    HouseNo
    LastUpdated
    WhoUpdated
    VillageId
    StreetId
    SquareMeters
    RippleNamiPropertySubtype
    RateableValue
    PropertyTypeId
    Propertyrentedstatus
    PropertyType
    PropertyRentedStatusId
    PropertyNo
    PlotNo
    ParishId
    MonthlyGrossIncome
    MonthlyGazetteValue
    Line
  }
`;
export const KccaResidentialPropertiesFragmentDoc = gql`
  fragment KccaResidentialProperties on rtcs_db_PH_kcca_ResidentialProperties {
    RippleNamiPropertySubtype
    SquareMeters
    StreetId
    VillageId
    WhoUpdated
    RateableValue
    Propertyrentedstatus
    PropertyTypeId
    PropertyType
    PropertyRentedStatusId
    PropertyNo
    PlotNo
    ParishId
    Longitude
    DivisionId
    File
    FlatNo
    GrossValue
    Grossrentvalueadjusted
    HouseNo
    LastUpdated
    Latitude
    Line
    AccomodationBedrooms
    AccomodationBuiltUpArea
    AccomodationOthers
    Address
    BlockNo
    CamvId
    CustomerId
    DerivedNoOfBedrooms
  }
`;
export const NetworkPropertiesFragmentDoc = gql`
  fragment NetworkProperties on rtcs_db_PH_rmatch_PropertiesNetwork {
    CamvId
    CountyId
    CurrentRateableValue
    CustomerId
    DistrictId
    DivisionId
    FlatNo
    Gml
    GrossValue
    ImgPath
    Latitude
    Longitude
    MapTitle
    MatchCategory
    MatchScore
    NwscCustomerId
    NwscCustomerName
    ParishId
    PropertyNo
    PropertyRentedStatusId
    PropertyTypeId
    Rnid
    Street
    StreetId
    SubCountyId
    SubPropertyTypeId
    Supplementary
    VillageId
  }
`;
export const PropertiesValuationFragmentDoc = gql`
  fragment PropertiesValuation on rtcs_db_PH_RPT_PropertyValuation {
    AccomodationOthers
    StreetId
    AccomodationBedrooms
    AccomodationBuiltUpArea
    AnnualGazetteValue
    AnnualGrossValue
    BlockNo
    CamvId
    CountyId
    CurrentRateableValue
    CustomerId
    DerivedNoOfBedrooms
    DistrictId
    DivisionId
    FlatNo
    Gml
    Grossrentvalueadjusted
    ImgPath
    KccaNoOfBedrooms
    Latitude
    Longitude
    MapTitle
    MatchCategory
    MatchScore
    MonthlyGazetteValue
    NumProperties
    NumRentedProperties
    NwscCustomerId
    NwscCustomerName
    ParishId
    PlotNo
    PropertyId
    PropertyLegalEntity
    PropertyNo
    PropertyOwnerFirstname
    PropertyOwnerSurname
    PropertyRentedStatusId
    PropertyType
    PropertyTypeId
    Propertyno
    Rateablevalue
    RentPerSquareMeter
    RnSubpropertyType
    Rnid
    Source
    SubCountyId
    SourceCustomer
    SquareMeters
    SubPropertyTypeId
    Supplementary
    Village
    VillageId
    Grossvalue
    Propertyrentedstatus
    RnidCustomer
  }
`;
export const RegNonIndividualDataFragmentDoc = gql`
  fragment RegNonIndividualData on rtcs_db_PH_ura_RegNonIndividual {
    IsIncomeSrcBsns
    ActvtyDesc
    AppliedForTin
    AuthctcdEmailId
    AuthctcdFamilyName
    AuthctcdFirstName
    AuthctcdLandlineNumber
    AuthctcdMobileNumber
    AuthctcdOtherName
    AuthctcdPrsnDesgn
    AuthctcdSurname
    BsnsCertNumber
    BsnsName
    BsnsaddrBuildname
    BsnsaddrDistrictName
    BsnsaddrLocalCouncil
    BsnsaddrParishName
    BsnsaddrPlotNo
    BsnsaddrStreetAddr
    BsnsaddrSubCountyName
    BsnsaddrTownName
    BsnsaddrTradeCentre
    BsnsaddrVillageName
    BusinessStartDt
    EmailId
    IsHldCmpInUganda
    HoldingCompTin
    HoldingCompName
    File
    ExistTinNo
    IsIncomeSrcPrpty
    IsIncomeSrcRental
    IsSubsidaryComp
    LandlineNumber
    LastUpdated
    Line
    MainActivity
    MobileNumber
    OrgSubType
    OrgSubTypeDesc
    OrgType
    PostaddrDistictName
    PostaddrId
    RefEmailId
    RefFamilyName
    RefFirstName
    WhoUpdated
    TradingName
    TinNo
    TaxPayerType
    TaxPayerName
    RefTin
    RefSurname
    RefPrsnDesgn
    RefOtherName
    RefMobileNumber
    RefMiddleName
    RefLandlineNumber
    Rnid
  }
`;
export const NonIndividualRiskScoresFragmentDoc = gql`
  fragment NonIndividualRiskScores on rtcs_db_PH_rmatch_NonIndividualRiskScore {
    Rnid
    Taxpayerassumed
    Tradingname
    Uratinno
    Isincomesrcrental
    Riskscore
    Compliancecategory
    Avgadjustedgrossincomeperproperty
    Grossrentalincome
    Rtnperiodyear
    Numproperties
    Numrentedproperties
    Sumofadjustedincome
    Averagerateperrentedproperty
    Riskcode
  }
`;
export const RegIndividualDataHpocFragmentDoc = gql`
  fragment RegIndividualDataHPOC on rtcs_db_PH_RPT_IndividualRegistration {
    FullName
    TinNo
    FirstName
    SurName
    MiddleName
    FamilyName
    BirthDt
    BirthCity
    MobileNumber
    EmailId
    HomeAddrPlotno
    HomeAddrStrretAddr
    HomeAddrDistrictName
    HomeAddrSubCountyName
    HomeAddrParishName
    HomeAddrLocalCouncil
    IsIncomeSrcRental
    IsIncomeSrcPrpty
    IsIncomeSrcBsns
    IsIncomeSrcEmp
  }
`;
export const RegIndividualSuggestionFragmentDoc = gql`
  fragment RegIndividualSuggestion on rtcs_db_PH_RPT_IndividualRegistration {
    TinNo
    TaxPayerName
    NationalId
  }
`;
export const PropertyCompositeFragmentDoc = gql`
  fragment PropertyComposite on rtcs_db_PH_RPT_PropertiesComposite {
    VillageId
    Supplementary
    SubPropertyTypeId
    SubCountyId
    StreetId
    SourceCustomer
    Source
    RnidCustomer
    Rnid
    RateableValue
    PropertyTypeId
    PropertyRentedStatusId
    PropertyNo
    PlotNo
    ParishId
    NwscCustomerName
    NwscCustomerId
    NumRentedProperties
    MatchScore
    CustomerMatchScore
    MatchCategory
    NumProperties
    MapTitle
    Longitude
    Latitude
    ImgPath
    GrossValue
    Gml
    FlatNo
    EntityLegalName
    Firstname
    Surname
    DivisionId
    DistrictId
    CustomerId
    CurrentRateableValue
    CountyId
    CamvId
    BlockNo
  }
`;
export const LandlordIncomeSummaryFragmentDoc = gql`
  fragment LandlordIncomeSummary on rtcs_db_PH_ura_LandlordIncomeSummary {
    LandlordTin
    LandlordTaxpayerName
    TaxYear
    ReturnNo
    ReturnPeriodFrom
    ReturnPeriodTo
    RentReportedByTenants
    GrossRentalIncome
    RentDeclaredByLandlord
    RmatchScore
    Difference
    DifferencePercentage
    GrossDifference
    GrossDifferencePercentage
    LandlordTinSource
    ReturnNoSource
  }
`;
export const LandlordIncomeDetailFragmentDoc = gql`
  fragment LandlordIncomeDetail on rtcs_db_PH_ura_LandlordIncomeDetail {
    RtnRentalIncomeId
    RtnNo
    TinNo
    TaxPayerName
    RtnDt
    RtnFromDt
    RtnToDt
    TenantTin
    TenantName
    PremiseLocation
    TenancyPeriodFrom
    TenancyPeriodTo
    RentalIncome
    IsAmendment
    IsResident
    RtnPeriodYear
    RtnDtTaxSummary
    RtnFromDtTaxSummary
    RtnToDtTaxSummary
    GrossRentalIncome
    AssessedChrgbleRentlIncm
    RentincIncome
  }
`;
export const TenantIncomeDetailFragmentDoc = gql`
  fragment TenantIncomeDetail on rtcs_db_PH_ura_TenantIncomeDetail {
    RtnRentPaidId
    RtnNo
    TenantTin
    TenantTaxPayerName
    LandlordTin
    LandlordName
    PremiseLocation
    RmatchScore
    TenancyPeriodFrom
    TenancyPeriodTo
    RentPaid
    TaxYear
    LandlordPeriodFrom
    LandlordPeriodTo
    Contribution
    ContributionAmount
  }
`;
export const ExcessiveOwnerOccupancyFragmentDoc = gql`
  fragment ExcessiveOwnerOccupancy on rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy {
    CustomerId
    KccaFirstName
    KccaMiddleName
    KccaSurname
    KccaTin
    MatchScore
    NumOwnedOccupied
    SumRateableValue
    MatchKccaUra
    UraFirstname
    UraMiddlename
    UraSurname
    EmailId
    MobileNumber
    IsIncomeSrcRental
    UraTin
    NationalId
    SumAdjustedIncome
    Rnid
  }
`;
export const IndividualCompliancePlusRiskReportFragmentDoc = gql`
  fragment IndividualCompliancePlusRiskReport on rtcs_db_PH_RPT_IndividualComplianceSummaryPlusRisk {
    Customerid
    Difference
    Emailid
    Grossrentalincome
    Matchscore
    Propincincome
    Averagerateperrentedproperty
    Compliancecategory
    Countura
    Percentagedifference
    Numrentedproperties
    Numproperties
    Nationalid
    Mobilenumber
    Matchscoreattributes
    Matchcategory
    Kccatin
    Kccasurname
    Kccafirstname
    Kccabirthdate
    Kccamobile1
    Kccamobile2
    Kccabirthdate
    Kccaemail
    Kccatelephone
    Isincomesrcrental
    Isincomesrcprpty
    Isincomesrcemp
    Isincomesrcbsns
    Isamendment
    Rnid
    RiskCode
    RiskScore
    Rtnperiodyear
    Rtnno
    Source
    Sumrateablevalue
    Sumnonrateablevalue
    Sumgrossvalue
    Sumadjustedincome
    Uratinno
    Uramiddlename
    Urafirstname
    Urasurname
  }
`;
export const KccaCustomerRegistrationFragmentDoc = gql`
  fragment kccaCustomerRegistration on rtcs_db_PH_RPT_KccaCustomerRegistrationData {
    HaveRevenueAgent
    HaveReference
    HaveAlternatePrimaryContact
    GenderId
    FormerBusinessName
    Firstname
    File
    EntityLegalName
    Email
    DateOfIncorporation
    IdentificationDiplomaticId
    IdentificationDrivingPermit
    IdentificationEmployeeId
    IdentificationFinancialCard
    IdentificationNationalId
    AcquiredName
    Birthdate
    IdentificationNssfNumber
    IdentificationPassportNo
    IdentificationVillageId
    IdentificationVoterId
    IdentificationWorkId
    IsForcedAction
    IsMinor
    LastUpdated
    Line
    MiddleName
    Mobile
    MotherMaidenName
    PlotNumber
    PreviousBusinessAddressBuildingName
    PreviousBusinessAddressCounty
    PreviousBusinessAddressCountyId
    PreviousBusinessAddressDistrict
    PreviousBusinessAddressDistrictId
    PreviousBusinessAddressParish
    PreviousBusinessAddressParishId
    PreviousBusinessAddressPlotNumber
    PreviousBusinessAddressStreetName
    PreviousBusinessAddressSubCountyId
    PreviousBusinessAddressTradingCenter
    PreviousBusinessAddressVillage
    PreviousBusinessName
    ResidentialAddressDistrict
    ResidentialAddressSubCounty
    ResidentialAddressVillage
    Rnid
    WhoUpdated
    TradingCenter
    TitleId
    Tin
    Telephone
    Surname
    SecondMobile
    StreetName
    ResidentialAddressParish
    ResidentialAddressCounty
    PreviousBusinessAddressVillageId
    PreviousBusinessAddressSubCounty
    Id
    BoxNumber
    BuildingName
    CustomerApplicantTypeId
    CustomerBusinessTypeId
    CustomerBusinessType
    Coin
    CustomerApplicantType
    CitizenshipCountryId
    BusinessName
    BoxDistrictId
    CustomerType
    CustomerTypeId
  }
`;
export const KccaPropertyRegistrationFragmentDoc = gql`
  fragment kccaPropertyRegistration on rtcs_db_PH_RPT_KccaPropertyRegistration {
    Rnid
    RnidOwner
    CustomerId
    LocationAddress
    Address
    PlotNo
    FlatNo
    HouseNo
    BlockNo
    StreetId
    RoadOthers
    ParticularsFrontages
    Village
    Parish
    Division
    PropertyNo
    SerialNo
    Latitude
    Longitude
    PropertyType
    PropertySubType
    PropertyRentedStatus
    GrossValue
    RateableValue
    CurrentRateableValue
    StatusId
    CamvId
    CreatedBy
    CreatedDate
    Firstname
    Surname
    MotherMaidenName
    EntityLegalName
    Birthdate
    Mobile
    SecondMobile
    Email
    BusinessName
    Coin
    MiddleName
    Tin
    OwnerTin
    OwnerFirstname
    OwnerLegalSurnameMaiden
    OwnerTitle
    ContactMobile1
    ContactLandline
    ContactEmail
    AccomodationBedrooms
  }
`;
export const MlhudFragmentDoc = gql`
  fragment mlhud on rtcs_db_PH_rmatch_mlhudConsolidated {
    Firstname
    Middlename
    Fullname
    Lastname
    Gender
    Titlenumber
    Blocknumber
    Plotno
    Roadname
    Address
    Subcounty
    County
    District
    Municipality
    Tenuretype
    Legalarea
    Legalareaunittype
    Volume
    Folio
    Titlelocation
    Baunittype
    Landtype
    Landusetype
    Condounitfactor
    Condoplanno
    Lafnumber
    Phone
    Description
    Registrationdatetime
    Cadlatitude
    Cadlongitude
    Cadlotnumber
    Cadblocknumb
    Cadroadname
    Cadcounty
    MatchResult
  }
`;
export const NiraFragmentDoc = gql`
  fragment nira on rtcs_db_PH_nira_RawData {
    PseudoNIN
    Surname
    NationalID
    MobileNumber
    Email
    GivenName
    DateOfBirth
  }
`;
export const NonIndividualCompliancePlusRiskReportFragmentDoc = gql`
  fragment NonIndividualCompliancePlusRiskReport on rtcs_db_PH_RPT_NonIndividualComplianceSummaryPlusRisk {
    Averagerateperrentedproperty
    Compliancecategory
    Difference
    Emailid
    Formerbusinessname
    Grossrentalincome
    Isamendment
    Isincomesrcrental
    Kccacustomerid
    Kccaname
    Matchscore
    Mobilenumber
    Numproperties
    Numrentedproperties
    Percentagedifference
    Rentincincome
    Rnid
    Rtnno
    RiskCode
    RiskScore
    Rtnperiodyear
    Source
    Sumgrossvalue
    Sumofadjustedincome
    Sumrateablevalue
    Tinno
    Tradingcenter
    Uraname
    Uratinno
  }
`;
export const NwscCustomersFragmentDoc = gql`
  fragment NwscCustomers on rtcs_db_PH_nwsc_Customers {
    Customerreference
    CustomernamePay
    Customerphoneno1Pay
    Customerphoneno1Cons
    AddressPay
    AreaPay
    LatitudePay
    LongitudePay
  }
`;
export const OwnersAndOfficersFragmentDoc = gql`
  fragment ownersAndOfficers on rtcs_db_PH_ura_CombinedOfficersAndOwners {
    Individualtin
    Name
    Tinno
    Taxpayername
    Designation
    Pershare
    Address
    Rtnno
    Rtnholdingofficersdtlid
    Rtnholdingownersdtlid
    Rtnperiodyear
    Rnid
  }
`;
export const RCapturePropertiesFragmentDoc = gql`
  fragment rCaptureProperties on rtcs_db_PH_rcapture_RPTproperties {
    Id
    Blocknumber
    Buildingcondition
    Buildingname
    County
    District
    Found
    Geolocation
    Identifier
    Landlord1identifier
    Landlotsize
    Latitude
    Lessor1firstname
    Lessor1identifier
    Lessor1lastname
    Lessor2firstname
    Lessor2identifier
    Longitude
    Modifiedby
    Notes
    Numberunits
    Outcome
    Owner1identifier
    Parish
    Pguid
    Photo
    Plotnumber
    Propertyid
    Propertyidsrc
    Propertymanager1identifier
    Region
    Streetname
    Subcounty
    Type
    Subtype
    Totallessors
    Totaltenants
    Totalunits
    Village
    Websiteurl
  }
`;
export const RCapturePropertyLessorsFragmentDoc = gql`
  fragment rCapturePropertyLessors on rtcs_db_PH_rcapture_RPTlessors {
    Buildingname
    Businesstradingname
    Cin
    County
    Customerid
    Customeridsrc
    District
    Email
    Firstname
    Id
    Identifier
    Lastname
    Legalbusinessname
    Nationality
    Parish
    Phone1
    Phone2
    Phone3
    Phone4
    Plotnumber
    Positionincompany
    Postaldistrict
    Postalpobox
    Region
    Registeredbusinessnumber
    Repdob
    Repfirstname
    Replastname
    Repnationality
    Repothercountry
    Repphone1
    Reptitle
    Sguid
    Streetname
    Subcounty
    Tin
    Title
    Tradinglicensenumber
    Type
    Village
  }
`;
export const RCapturePropertyTenantFragmentDoc = gql`
  fragment rCapturePropertyTenant on rtcs_db_PH_rcapture_RPTtenants {
    Actualrentpaidpermonth
    Amountofrentreceipts
    Businesstradingname
    Cactualrentpaidpermonth
    Currencyactualrent
    Currencyreportedrent
    Email
    Estimatedshopsize
    Firstname
    Floornumber
    Id
    Identifier
    Isunitlet
    Lastname
    Legalbusinessname
    Mda
    Nationality
    Notes
    Numberbathrooms
    Numberbedrooms
    Owneroccupiedorrented
    Phone1
    Phone2
    Phone3
    Phone4
    Positionincompany
    Registeredbusinessnumber
    Rentreportedbylessorpermonth
    Repemail
    Repfirstname
    Replastname
    Repmiddlename
    Repnationality
    Repothercountry
    Repphone1
    Reptitle
    Reptitleother
    Shopname
    Shopnumber
    Tin
    Title
    Type
    Typeofgoodssold
  }
`;
export const RCapturePropertyUnitTypeFragmentDoc = gql`
  fragment rCapturePropertyUnitType on rtcs_db_PH_rcapture_RPTUnittypes {
    Createdby
    Currencyadvertisedrent
    Id
    Numberbathrooms
    Numberbedrooms
    Numberunitstype
    Pguid
    Uguid
    Unitfurnished
    Unitrent
    Unitsize
    Unittype
    Uuidproperty
  }
`;
export const UccReportFragmentDoc = gql`
  fragment uccReport on rtcs_db_PH_ucc_UCC {
    Carrier
    Count
    Customerid
    Firstname
    Idnumber
    Idtype
    Kccamobile
    Surname
  }
`;
export const SubscriberAndPropertyFragmentDoc = gql`
  fragment SubscriberAndProperty on rtcs_db_PH_umeme_SubscriberAndProperty {
    MeterNo
    Customerno
    CustomerName
    District
    Street
    SupplyPointNo
    TariffDesc
    Telephone
    Town
    XCoord
    YCoord
    Longitude
    Latitude
  }
`;
export const UraIndividualFragmentDoc = gql`
  fragment UraIndividual on rtcs_db_PH_RPT_IndividualRegistration {
    TinNo
    TaxPayerType
    TaxPayerName
    TaxPayerId
    SurName
    RefTin
    RefSurname
    RefPrsnDesgn
    RefOtherName
    RefMobileNumber
    RefLandlineNumber
    RefFirstName
    RefFamilyName
    RefEmailId
    PostaddrId
    PostaddrDistictName
    OtherName
    NssfId
    NationalId
    MobileNumber
    MiddleName
    LandlineNumber
    IsIncomeSrcRental
    IsIncomeSrcPrpty
    IsIncomeSrcEmp
    IsIncomeSrcBsns
    HomeAddrVillageName
    HomeAddrTradeCentre
    HomeAddrTownName
    HomeAddrSubCountyName
    HomeAddrStrretAddr
    HomeAddrPlotno
    HomeAddrParishName
    HomeAddrLocalCouncil
    HomeAddrDistrictName
    HomeAddrBuildingname
    Gender
    FirstName
    FamilyName
    ExistTinNo
    EmailId
    CitizenCountry
    BsnsName
    BirthState
    BirthDt
    BirthCountry
    TownName
    AuthctcdTin
    AuthctcdSurname
    AuthctcdPrsnDesgn
    AuthctcdOtherName
    AuthctcdMobileNumber
    AuthctcdLandlineNumber
    AuthctcdFirstName
    AuthctcdFamilyName
    AuthctcdEmailId
    AppliedForTin
  }
`;
export const UraNonIndividualReportFragmentDoc = gql`
  fragment UraNonIndividualReport on rtcs_db_PH_ura_RegNonIndividual {
    ActvtyDesc
    AppliedForTin
    AuthctcdEmailId
    AuthctcdFamilyName
    AuthctcdFirstName
    AuthctcdLandlineNumber
    AuthctcdMobileNumber
    AuthctcdOtherName
    AuthctcdPrsnDesgn
    AuthctcdSurname
    BsnsaddrBuildname
    BsnsaddrDistrictName
    BsnsaddrLocalCouncil
    BsnsaddrParishName
    BsnsaddrPlotNo
    BsnsaddrStreetAddr
    BsnsaddrSubCountyName
    BsnsaddrTownName
    BsnsaddrTradeCentre
    BsnsaddrVillageName
    BsnsCertNumber
    BsnsName
    BusinessStartDt
    EmailId
    ExistTinNo
    HoldingCompName
    HoldingCompTin
    IsHldCmpInUganda
    IsIncomeSrcBsns
    IsIncomeSrcPrpty
    IsIncomeSrcRental
    IsSubsidaryComp
    LandlineNumber
    MainActivity
    MobileNumber
    OrgSubType
    OrgSubTypeDesc
    OrgType
    PostaddrDistictName
    PostaddrId
    RefEmailId
    RefFamilyName
    RefFirstName
    RefLandlineNumber
    RefMiddleName
    RefMobileNumber
    RefOtherName
    RefPrsnDesgn
    RefSurname
    RefTin
    Rnid
    TaxPayerName
    TaxPayerType
    TinNo
    TradingName
  }
`;
export const UraIndividualTaxFragmentDoc = gql`
  fragment UraIndividualTax on rtcs_db_PH_RPT_IndividualTaxSummaryData {
    TinNo
    IsAmendment
    IsResident
    RtnNo
    RtnPeriodYear
    RtnFromDt
    RtnToDt
    RtnDt
    EmplymntIncome
    EmplymntTax
    EmplymntRate
    RentincTax
    RentincRate
    PropincIncome
    PropincTax
    PropincRate
    TotIncome
    TotTax
    TotRate
    FirstName
    SurName
    IsIncomeSrcRental
    TotRentalIncm
    PartnerRentIncome
    GrossRentalIncome
    DeductIncmTax
    MotgageIntDecd
    NetRentIncm
    ThresholdAmnt
    ChargeableIncome
    TaxAssessed
    ReturnType
  }
`;
export const UraNonIndividualTaxReportFragmentDoc = gql`
  fragment UraNonIndividualTaxReport on rtcs_db_PH_RPT_NonIndividualTaxSummaryData {
    AdjProfitLossRental
    AdjRentlIncmLoss
    AdministratingExp
    AllowblApportionedCap
    AssessedChrgblRentlIncm
    AssessedRentlLoss
    BadDebtWriteOff
    BalanceCarryForward
    BalancePrevPeriod
    BrghtFwdAssessedLoss
    BusinessIncome
    BusinessRate
    CostOfSales
    BusinessTax
    Debenture
    DeferTaxLiability
    Depreciation
    EquityShare
    FactoryRentRates
    FinanceExp
    FixedDeposit
    GovtSecurities
    GrossProfit
    GrossRentalIncome
    IsAmendment
    IsResident
    LandBuild
    NonAllowblExp
    OperatingExp
    OtherInvestments
    OthrAllowblExp
    PayableAmtInc
    PayableAmtRent
    ProfitAfterDividend
    ProfitAfterTax
    ProfitBeforeTax
    ProfitLossRental
    ProposedDividend
    ProvisionIncomeTax
    RefundAmtInc
    RefundAmtRent
    RentincIncome
    RentincRate
    RentincTax
    RentRates
    Rnid
    RtnDt
    RtnFromDt
    RtnNo
    RtnPeriodYear
    SecureTotLoan
    SelfAssessTaxPaidInc
    SelfAssessTaxPaidRent
    SourceFund
    TinNo
    TotalPropFund
    TotalReserve
    TotalShareCapital
    TotAdminExpense
    TotBsnsProfSales
    TotExpPurelyRental
    TotFinancialExpense
    TotFixedAsset
    TotNonChargeableIncome
    TotOperationExpense
    TotOtherExpense
    TotRentAdminiExpns
    TotRentFinancExpns
    TotRentIncmExpns
    TotRentOprtgExpns
    TotTaxesPaidInc
    TotTaxLiability
    TotTaxesPaidRent
    TotTaxLiabilityInc
    TotTaxLiabilityRent
    TotTradeInvestment
    TotWthdTaxDeduct
    TotIncome
    TotRate
    TotTax
    UnsecureTotLoan
    TaxPayerName
    TradingName
    OrgSubType
    IsIncomeSrcRental
    IsIncomeSrcPrpty
    MainActivity
    BsnsName
    OrgType
    ReturnType
  }
`;
export const NonIndividualIncomeTaxSummaryFragmentDoc = gql`
  fragment NonIndividualIncomeTaxSummary on rtcs_db_PH_RPT_NonIndividualTaxSummaryData {
    RtnPeriodYear
    RtnNo
    IsAmendment
    TotIncome
    TotTax
    TotRate
    RentincIncome
    RentincTax
    RentincRate
    RtnDt
    GrossRentalIncome
    TotExpPurelyRental
    OperatingExp
    AdministratingExp
    FinanceExp
    ProfitLossRental
    NonAllowblExp
    AdjProfitLossRental
    AllowblApportionedCap
    OthrAllowblExp
    AdjRentlIncmLoss
    BrghtFwdAssessedLoss
    AssessedChrgblRentlIncm
    AssessedRentlLoss
    SelfAssessTaxPaidRent
    ReturnType
  }
`;
export const NonIndividualIncomeTaxSummariesFragmentDoc = gql`
  fragment NonIndividualIncomeTaxSummaries on rtcs_db_PH_RPT_NonIndividualTaxSummaryData {
    RtnPeriodYear
    RtnNo
    IsAmendment
    TotIncome
    TotTax
    TotRate
    GrossRentalIncome
    RentincIncome
    RentincTax
    RentincRate
    ChargeableIncomeP
    RentincIncomeP
    ReturnType
    RtnDt
  }
`;
export const Rpt_NonIndividualBalanceSheetFragmentDoc = gql`
  fragment RPT_NonIndividualBalanceSheet on rtcs_db_PH_RPT_NonIndividualBalanceSheet {
    AccntRcvdOthers
    AccntRcvdOverSix
    AdvanceCorporate
    AdvanceRecoverable
    ApplicationFund
    BalRevenueAuth
    BankBalance
    BankOverDraft
    CnsmPckInventory
    Debenture
    DeferTaxLiability
    DeferredAsset
    Deposits
    Depreciation
    EquityShare
    FinishTradeGoods
    FixedDeposit
    FurnitureFixture
    GeneralReserve
    GovtSecurities
    HandCash
    IncomeTaxProv
    IntLeaseAsset
    IntangibleAssets
    IsAmendment
    LandBuild
    LessDrawings
    LiableLeaseAsset
    LoanSubCompany
    MotorVehicles
    NetCurrentAsset
    NetFixedAsset
    OtherInvestments
    OtherProvision
    OtherReserve
    OthrCurrentAsset
    OthrDeprecAsset
    OthrLiability
    PlantMachine
    Prepayments
    ProfitLossAccount
    ProprietorCapital
    ProvForBadDebts
    RawMtrlInventory
    RevaluationReserve
    RtnId
    RtnNo
    RtnPeriodYear
    SecureDebtIssued
    SecureLoanBank
    SecureLoanOther
    SecureLoanParties
    SecureTotLoan
    SourceFund
    StockInProcess
    SundryCreditors
    TinNo
    TotAccntRcvdDebtors
    TotAssetLoanAdvance
    TotBalanceAvail
    TotCurrLiability
    TotCurrentAsset
    TotFixedAsset
    TotInventory
    TotLiabilityProv
    TotLoanAdvance
    TotTradeInvestment
    TotalLoanFund
    TotalPropFund
    TotalProvision
    TranslationReserve
    UnpaidMatureDebts
    UnsecureCreditorLoan
    UnsecureLoanBank
    UnsecureLoanOther
    UnsecureLoanParties
    UnsecureTotLoan
  }
`;
export const UraIndividualsAssociatedBusinessFragmentDoc = gql`
  fragment UraIndividualsAssociatedBusiness on rtcs_db_PH_ura_IndividualsAssociatedBusiness {
    Surname
    TinNo
    RegAssociatedEntityDtlId
    FirstName
    OtherName
    FamilyName
    AssociateTin
    AssociateTaxPayerName
    EntityDesignation
    TaxPayerType
    MainActivity
    ActvtyDesc
    WhoUpdated
    File
    LastUpdated
    Line
  }
`;
export const UraNonIndividualsAssociatedBusinessFragmentDoc = gql`
  fragment UraNonIndividualsAssociatedBusiness on rtcs_db_PH_ura_NonIndividualsAssociatedBusiness {
    EntityDesignation
    TaxPayerType
    MainActivity
    ActvtyDesc
    WhoUpdated
    File
    LastUpdated
    Line
    AssociateTin
    AssociatedNonIndividual
    RegAssociatedEntityDtlId
    TaxPayerName
    TinNo
  }
`;
export const UraRentalTenantPaymentsFragmentDoc = gql`
  fragment UraRentalTenantPayments on rtcs_db_PH_ura_RentalTenantPayments {
    RtnRentPaidId
    LandlordName
    LandlordTin
    PremiseLocation
    RentPaid
    RtnNo
    TenancyPeriodFrom
    TenancyPeriodTo
  }
`;
export const RptRentalTenantPaymentsFragmentDoc = gql`
  fragment RptRentalTenantPayments on rtcs_db_PH_RPT_RentalTenantPayments {
    File
    IsAmendment
    LandlordName
    LandlordTin
    LastUpdated
    Line
    PremiseLocation
    RentPaid
    RtnNo
    RtnRentPaidId
    TenancyPeriodFrom
    TenancyPeriodTo
    TenantName
    TenantTin
  }
`;
export const UraRentalLandLordIncomeFragmentDoc = gql`
  fragment UraRentalLandLordIncome on rtcs_db_PH_ura_RentalLandLordIncome {
    PremiseLocation
    RentalIncome
    RtnDt
    RtnFromDt
    RtnNo
    RtnRentalIncomeId
    RtnToDt
    TaxPayerName
    TenancyPeriodFrom
    TenancyPeriodTo
    TenantName
    TenantTin
    TinNo
  }
`;
export const UgandaRegistrationSbFragmentDoc = gql`
  fragment UgandaRegistrationSB on rtcs_db_PH_ursb_CompaniesAndBusinessNamesURSB {
    BusinessName
    CompanyName
    CompanyType
    Subtype
    DirectorFirstname
    DirectorSurname
    District
    Parish
    PartnersFirstname
    PartnersSurname
    RegistrationDate
    RegistrationNumber
    ShareholderFirstname
    ShareholdersSurname
    Street
    Town
    Trading
    Village
  }
`;
export const RegIndividualDataReportFragmentDoc = gql`
  fragment RegIndividualDataReport on rtcs_db_PH_RPT_IndividualComposite {
    WhoUpdated_y
    WhoUpdated_x
    UraTinNo
    UraSurName
    UraOtherName
    UraMiddleName
    UraFirstName
    UraFamilyName
    TownName
    TownId
    TownCd
    TaxPayerType
    TaxPayerName
    TaxPayerId
    Surname
    SumNonRateableValue
    Source
    Rnid
    RefTin
    RefSurname
    RefPrsnDesgn
    RefOtherName
    RefMobileNumber
    RefLandlineNumber
    RefFirstName
    RefEmailId
    RefFamilyName
    HomeAddrPlotno
    HomeAddrStrretAddr
    HomeAddrSubCountyName
    HomeAddrTownName
    HomeAddrTradeCentre
    HomeAddrVillageName
    IsIncomeSrcBsns
    IsIncomeSrcEmp
    IsIncomeSrcPrpty
    IsIncomeSrcRental
    LandlineNumber
    LastUpdated_x
    LastUpdated_y
    Line_x
    Line_y
    MatchCategory
    MatchScore
    MatchScoreAttributes
    MiddleName
    MobileNumber
    NationalId
    NssfId
    NumProperties
    PostaddrDistictName
    PostaddrId
    CountUra
    CustomerId
    DistrictId
    EmailId
    EntityLegalName
    ExistTinNo
    File_x
    File_y
    Firstname
    Fullname
    Gender
    HomeAddrBuildingname
    HomeAddrDistrictName
    HomeAddrLocalCouncil
    HomeAddrParishName
    AuthctcdLandlineNumber
    AuthctcdMobileNumber
    AuthctcdOtherName
    AuthctcdPrsnDesgn
    AuthctcdSurname
    AuthctcdTin
    BirthCity
    BuildingName
    BsnsName
    BirthState
    BirthDt
    BirthCountry
    BusinessName
    CitizenCountry
    Coin
    AdditionalProperties
    AppliedForTin
    AuthctcdEmailId
    AuthctcdFamilyName
    AuthctcdFirstName
  }
`;
export const NonIndividualComplianceFragmentDoc = gql`
  fragment NonIndividualCompliance on rtcs_db_PH_rmatch_NonIndividualComplianceSummaryReport_aggregate {
    aggregate {
      sum {
        Numrentedproperties
        Grossrentalincome
        Sumofadjustedincome
      }
      count(columns: [Numrentedproperties])
    }
  }
`;
export const IndividualComplianceFragmentDoc = gql`
  fragment IndividualCompliance on rtcs_db_PH_rmatch_IndividualComplianceSummaryReport_aggregate {
    aggregate {
      sum {
        Numrentedproperties
        Grossrentalincome
        Sumadjustedincome
      }
      count(columns: [Numrentedproperties])
    }
  }
`;
export const UraRegIndividualFragmentFragmentDoc = gql`
  fragment UraRegIndividualFragment on rtcs_db_PH_RPT_IndividualComposite {
    UraTinNo
    UraSurName
    UraOtherName
    UraMiddleName
    UraFirstName
    UraFamilyName
    TownName
    TownId
    TownCd
    TaxPayerType
    TaxPayerName
    TaxPayerId
    Surname
    SumNonRateableValue
    Source
    Rnid
    RefTin
    RefSurname
    RefPrsnDesgn
    RefOtherName
    RefMobileNumber
    RefLandlineNumber
    RefFirstName
    RefEmailId
    RefFamilyName
    HomeAddrPlotno
    HomeAddrStrretAddr
    HomeAddrSubCountyName
    HomeAddrTownName
    HomeAddrTradeCentre
    HomeAddrVillageName
    IsIncomeSrcBsns
    IsIncomeSrcEmp
    IsIncomeSrcPrpty
    IsIncomeSrcRental
    LandlineNumber
    MatchCategory
    MatchScore
    MatchScoreAttributes
    MiddleName
    MobileNumber
    NationalId
    NssfId
    NumProperties
    PostaddrDistictName
    PostaddrId
    CountUra
    CustomerId
    DistrictId
    EmailId
    EntityLegalName
    ExistTinNo
    Firstname
    Fullname
    Gender
    HomeAddrBuildingname
    HomeAddrDistrictName
    HomeAddrLocalCouncil
    HomeAddrParishName
    AuthctcdLandlineNumber
    AuthctcdMobileNumber
    AuthctcdOtherName
    AuthctcdPrsnDesgn
    AuthctcdSurname
    AuthctcdTin
    BirthCity
    BuildingName
    BsnsName
    BirthState
    BirthDt
    BirthCountry
    BusinessName
    CitizenCountry
    Coin
    AdditionalProperties
    AppliedForTin
    AuthctcdEmailId
    AuthctcdFamilyName
    AuthctcdFirstName
  }
`;
export const KccaCustomerFragmentDoc = gql`
  fragment kccaCustomer on rtcs_db_PH_kcca_Customer {
    Id
    Firstname
    MiddleName
    Surname
    Email
    MotherMaidenName
    Coin
    Tin
    IdentificationNationalId
    IdentificationNssfNumber
    IdentificationPassportNo
    PlotNumber
    StreetName
    ResidentialAddressVillageId
    ResidentialAddressParishId
    ResidentialAddressSubCountyId
    ResidentialAddressCountyId
    ResidentialAddressDistrictId
    Mobile
    SecondMobile
    Telephone
    Birthdate
    BuildingName
    TradingCenter
    Rnid
  }
`;
export const IndividualPropertiesFragmentDoc = gql`
  fragment IndividualProperties on rtcs_db_PH_RPT_KccaPropertyRegistrationData {
    PlotNo
    PropertyNo
    HouseNo
    CurrentRateableValue
    BlockNo
    Address
    RateableValue
    Village
    StreetId
    StatusId
    PropertyRentedStatus
    FlatNo
    RoadOthers
    GrossValue
    Id
    CustomerId
    LocationAddress
    LastUpdated
    PropertyType
    Parish
    Division
  }
`;
export const IndividualsAssociatedBusinessFragmentDoc = gql`
  fragment IndividualsAssociatedBusiness on rtcs_db_PH_ura_IndividualsAssociatedBusiness {
    TinNo
    TaxPayerType
    Surname
    RegAssociatedEntityDtlId
    OtherName
    MainActivity
    FirstName
    FamilyName
    EntityDesignation
    ActvtyDesc
    AssociateTaxPayerName
    AssociateTin
  }
`;
export const UraRptIndividualTaxSummariesFragmentDoc = gql`
  fragment UraRPTIndividualTaxSummaries on rtcs_db_PH_RPT_IndividualTaxSummaryData {
    RtnPeriodYear
    RtnNo
    IsAmendment
    TotIncome
    TotTax
    TotRate
    GrossRentalIncome
    RentincTax
    TotRate
    RentincTax
    RentincRate
    RentalIncomeP
    ChargeableIncomeP
    ChargeableIncome
    ReturnType
    RtnDt
  }
`;
export const RegIndividualAffiliatesFragmentDoc = gql`
  fragment RegIndividualAffiliates on rtcs_db_PH_RPT_IndividualComposite {
    RefFamilyName
    RefFirstName
    RefSurname
    RefOtherName
    RefTin
    RefMobileNumber
    RefEmailId
    RefPrsnDesgn
    RefLandlineNumber
  }
`;
export const KccaCustomerReferenceIndividualAffiliatesFragmentDoc = gql`
  fragment KccaCustomerReferenceIndividualAffiliates on rtcs_db_PH_kcca_CustomerReference {
    Id
    CustomerId
    MiddleName
    Firstname
    Surname
  }
`;
export const KccaContactPersonIndividualAffiliatesFragmentDoc = gql`
  fragment KccaContactPersonIndividualAffiliates on rtcs_db_PH_kcca_CustomerContactPerson {
    Id
    CustomerId
    MiddleName
    Firstname
    Surname
  }
`;
export const KccaCustomerGuardianIndividualAffiliatesFragmentDoc = gql`
  fragment KccaCustomerGuardianIndividualAffiliates on rtcs_db_PH_kcca_CustomerGuardian {
    Id
    Surname
    MiddleName
    Firstname
    CustomerId
  }
`;
export const KccaCustomerBusinessAssociateIndividualAffiliatesFragmentDoc = gql`
  fragment KccaCustomerBusinessAssociateIndividualAffiliates on rtcs_db_PH_kcca_CustomerBusinessAssociate {
    Id
    CustomerId
    MiddleName
    Firstname
    Surname
  }
`;
export const UraRptIndividualTaxSummaryFragmentDoc = gql`
  fragment UraRPTIndividualTaxSummary on rtcs_db_PH_RPT_IndividualTaxSummaryData {
    RtnPeriodYear
    RtnNo
    IsAmendment
    TotIncome
    TotTax
    RentincTax
    GrossRentalIncome
    DeductIncmTax
    MotgageIntDecd
    ChargeableIncome
    ThresholdAmnt
    NetRentIncm
    TaxAssessed
    EmplymntIncome
    PropincIncome
    TotRentalIncm
    PartnerRentIncome
    IsResident
    RtnDt
    ReturnType
  }
`;
export const IndividualMortgageFragmentDoc = gql`
  fragment IndividualMortgage on rtcs_db_PH_ura_IndividualMortgage {
    DeedNo
    FinanceName
    FinanceTin
    IntersetMortgage
    PeriodFrom
    PeriodTo
    TotalMortgage
  }
`;
export const IndividualTenantFragmentDoc = gql`
  fragment IndividualTenant on rtcs_db_PH_ura_RentalLandLordIncome {
    TenantName
    TenantTin
    PremiseLocation
    TenancyPeriodFrom
    TenancyPeriodTo
    RentalIncome
  }
`;
export const IndividualExcessiveOwnerOccupancyFragmentDoc = gql`
  fragment IndividualExcessiveOwnerOccupancy on rtcs_db_PH_rmatch_RMatchSummary916ExcessiveOwnerOccupancy {
    CustomerId
    EmailId
    IsIncomeSrcRental
    KccaFirstName
    KccaMiddleName
    KccaSurname
    KccaTin
    MatchKccaUra
    MatchScore
    MobileNumber
    NationalId
    NumOwnedOccupied
    NumProperties
    ScoreMatchAtributes
    SumAdjustedIncome
    SumGrossValue
    SumNonRateableValue
    SumRateableValue
    TotalRentedProperties
    UraFirstname
    UraMatchingTins
    UraMiddlename
    UraSurname
    UraTin
  }
`;
export const RMatchIndividualsFragmentDoc = gql`
  fragment RMatchIndividuals on rtcs_db_PH_RPT_IndividualComposite {
    CountUra
    CustomerId
    Firstname
    Fullname
    Gml
    NationalId
    Rnid
    UraTinNo
    UraFirstName
    UraSurName
    UraMiddleName
    UraFamilyName
    Source
    Surname
    MatchScore
  }
`;
export const UraIndividualsWithNoTinFragmentDoc = gql`
  fragment UraIndividualsWithNoTin on rtcs_db_PH_rmatch_RMatchSummary904 {
    CustomerId
    KccaFirstName
    KccaMiddleName
    KccaSurname
    KccaTin
    KccaMobile1
    KccaMobile2
    KccaLandline
    KccaDob
    KccaEmail
    KccaNin
    NumProperties
    TotalRentedProperties
    SumGrossValue
    SumRateableValue
  }
`;
export const IndividualPropertyDetailsFragmentDoc = gql`
  fragment IndividualPropertyDetails on rtcs_db_PH_rmatch_IndividualComplianceDetailReport {
    Address
    Blockno
    Createddate
    Currentrateablevalue
    Customerid
    District
    Division
    Emailid
    File
    Firstname
    Flatno
    Fullname
    Grossrentvalueadjusted
    Grossvalue
    Houseno
    Isincomesrcbsns
    Isincomesrcemp
    Isincomesrcprpty
    Isincomesrcrental
    Kccabirthdate
    Kccaemail
    Kccafirstname
    Kccamiddlename
    Kccamobile1
    Kccamobile2
    Kccasurname
    Kccatelephone
    LastUpdated
    Latitude
    Line
    Longitude
    Matchscore
    Customermatchscore
    Mobilenumber
    Ownerfirstname
    Ownerlegalsurnamemaiden
    Parish
    Plotno
    Propertyno
    Propertyrentedstatus
    Propertyrnid
    Propertytype
    Rateablevalue
    Rnidowner
    Source
    Streetid
    Subpropertytype
    Surname
    Urafirstname
    Uramiddlename
    Urasurname
    Uratinno
    Maptitle
    Village
  }
`;
export const UraNonIndividualFragmentDoc = gql`
  fragment UraNonIndividual on rtcs_db_PH_ura_RegNonIndividual {
    Rnid
    BsnsCertNumber
    BsnsaddrPlotNo
    BsnsaddrStreetAddr
    BsnsaddrBuildname
    BsnsaddrDistrictName
    BsnsaddrTownName
    BsnsaddrSubCountyName
    BsnsaddrParishName
    BsnsaddrVillageName
    TradingName
    EmailId
    LandlineNumber
    MobileNumber
    IsIncomeSrcRental
    IsIncomeSrcBsns
    IsIncomeSrcPrpty
    TinNo
    TaxPayerName
    BsnsName
    HoldingCompTin
    HoldingCompName
    IsSubsidaryComp
    OrgType
    OrgSubType
    OrgSubTypeDesc
    MainActivity
    ActvtyDesc
  }
`;
export const KccaCustomerNonIndividualFragmentDoc = gql`
  fragment kccaCustomerNonIndividual on rtcs_db_PH_kcca_Customer {
    Coin
    Tin
    EntityLegalName
    BusinessName
    BuildingName
    DateOfIncorporation
    TradingCenter
    CustomerTypeId
    CustomerApplicantTypeId
    CustomerBusinessTypeId
    Telephone
    Id
    Mobile
    SecondMobile
    Email
    PlotNumber
    StreetName
    ResidentialAddressCountyId
    ResidentialAddressDistrictId
    ResidentialAddressParishId
    ResidentialAddressSubCountyId
    ResidentialAddressVillageId
    Rnid
  }
`;
export const UraAuthIndividualFragmentDoc = gql`
  fragment UraAuthIndividual on rtcs_db_PH_ura_RegNonIndividual {
    AuthctcdSurname
    TinNo
    AuthctcdFirstName
    AuthctcdOtherName
    AuthctcdPrsnDesgn
    AuthctcdMobileNumber
    AuthctcdEmailId
    AuthctcdFamilyName
    AuthctcdLandlineNumber
  }
`;
export const UraRegAssociateFragmentDoc = gql`
  fragment UraRegAssociate on rtcs_db_PH_ura_RegAssociates {
    RnidAssociate
    FirstName
    AssociateTin
    FamilyName
    Surname
    EntityDesignation
  }
`;
export const NonIndividualRentExpensesFragmentDoc = gql`
  fragment NonIndividualRentExpenses on rtcs_db_PH_ura_RentExpenseDetails {
    Name
    Expensetype
    Amount
  }
`;
export const NonIndividualOwnersAndOfficersFragmentDoc = gql`
  fragment nonIndividualOwnersAndOfficers on rtcs_db_PH_ura_CombinedOfficersAndOwners {
    Individualtin
    Name
    Tinno
    Taxpayername
    Designation
    Pershare
    Address
    Rtnno
    Rtnholdingofficersdtlid
    Rtnholdingownersdtlid
    Rtnperiodyear
  }
`;
export const RMatchNonIndividualFragmentDoc = gql`
  fragment RMatchNonIndividual on rtcs_db_PH_RPT_NonIndividualComposite {
    Gml
    CustomerId
    KccaName
    MatchScore
    MatchCategory
    NumProperties
    Rnid
    Source
    UraName
    UraTinNo
    AdditionalOwners
    AdditionalProperties
  }
`;
export const NonIndividualPropertyDetailsFragmentDoc = gql`
  fragment NonIndividualPropertyDetails on rtcs_db_PH_rmatch_NonIndividualComplianceDetailReport {
    Address
    Blockno
    Createddate
    Currentrateablevalue
    District
    Division
    Entitylegalname
    File
    Flatno
    Grossrentvalueadjusted
    Grossvalue
    Houseno
    Kccacustomerid
    Kccaname
    LastUpdated
    Latitude
    Line
    Longitude
    Matchscore
    Customermatchscore
    Ownerfirstname
    Ownerlegalsurnamemaiden
    Parish
    Plotno
    Propertyno
    Propertyrentedstatus
    Propertyrnid
    Propertytype
    Rateablevalue
    Rnidowner
    Source
    Streetid
    Subpropertytype
    Taxpayerassumed
    Tin
    Tradingcenter
    Village
    Maptitle
    WhoUpdated
  }
`;
export const NonIndividualsAssociatedIndividualsFragmentDoc = gql`
  fragment NonIndividualsAssociatedIndividuals on rtcs_db_PH_ura_NonIndividualsAssociatedBusiness {
    TaxPayerName
    TaxPayerType
    TinNo
    ActvtyDesc
    AssociateTin
    AssociatedNonIndividual
    EntityDesignation
    MainActivity
    RegAssociatedEntityDtlId
  }
`;
export const KccaPropertiesFragmentDoc = gql`
  fragment KccaProperties on rtcs_db_PH_RPT_KccaPropertyRegistrationData {
    Address
    BlockNo
    CurrentRateableValue
    CreatedDate
    Id
    PlotNo
    FlatNo
    HouseNo
    StreetId
    Village
    Parish
    Division
    PropertyNo
    LocationAddress
    PropertyRentedStatus
    GrossValue
    RateableValue
    CamvId
  }
`;
export const KccaPropertyDetailsFragmentDoc = gql`
  fragment KccaPropertyDetails on rtcs_db_PH_kcca_RawProperties1 {
    BuildingName
    CordinateX
    CordinateY
    PropertySubType
    PropertyType
    Rv
    Gv
    ParticularsBlockName
    ParticularsBlockNumber
    ParticularsFolioNumber
    ParticularsHouseNumber
    ParticularsIsTitled
    ParticularsPlotNumber
    ParticularsPlotShape
    ParticularsPropertyName
    ParticularsRoadStreet
    ParticularsTypeOfLandInterest
    AccomodationBathroom
    AccomodationBathroomShower
    AccomodationBedrooms
    AccomodationBuiltUpArea
    AccomodationComment
    AccomodationDiningRooms
    AccomodationEntertainmentRoom
    AccomodationEnviromentalNoise
    AccomodationGarage
    AccomodationKitchen
    AccomodationLaundry
    AccomodationOthers
    AccomodationSecurity
    AccomodationShops
    AccomodationShower
    AccomodationSittingRoomLoungeNumber
    AccomodationStore
    AccomodationStudyRoom
    AccomodationVerandah
    AccomodationWc
    AccomodationWcBathroom
    AccomodationWcBathroomShower
    AccomodationWcShower
    ParticularsAccess
    ParticularsFrontages
    ParticularsGpsEastingsUtm
    ParticularsGpsNorthingsUtm
    ParticularsLandReference
    ParticularsNeighborhood
    ParticularsTopographyOthers
    ParticularsTopograpy
    ParticularsVolume
    PropertyOwnerOccupiedOrRented
    Pvillage
    Id
  }
`;
export const KccaCustomerAlternateOwnerFragmentDoc = gql`
  fragment KccaCustomerAlternateOwner on rtcs_db_PH_kcca_RawProperties1 {
    ContactAddress
    ContactCountry
    ContactCountyMunicipality
    ContactDistrict
    ContactEmail
    ContactMobile1
    ContactMobile2
    ContactParishWard
    ContactSubcountryDivision
    ContactVillageCellZone
  }
`;
export const KccaCustomerPrimaryOwnerFragmentDoc = gql`
  fragment KccaCustomerPrimaryOwner on rtcs_db_PH_kcca_Customer {
    BusinessName
    Coin
    EntityLegalName
    Firstname
    MiddleName
    Surname
    MotherMaidenName
    Tin
    Id
  }
`;
export const KccaCustomerSecondaryOwnerFragmentDoc = gql`
  fragment KccaCustomerSecondaryOwner on rtcs_db_PH_RPT_KccaCustomerRegistrationData {
    CustomerApplicantType
    CustomerBusinessType
    CustomerType
    Email
    IdentificationNationalId
    IdentificationNssfNumber
    Mobile
    MotherMaidenName
    ResidentialAddressCounty
    ResidentialAddressDistrict
    ResidentialAddressParish
    ResidentialAddressSubCounty
    ResidentialAddressVillage
    StreetName
  }
`;
export const KccaCustomerBusinessAssociateAffiliatesFragmentDoc = gql`
  fragment KccaCustomerBusinessAssociateAffiliates on rtcs_db_PH_kcca_CustomerBusinessAssociate {
    AcquiredName
    CustomerId
    Firstname
    Id
    MiddleName
    MotherMaidenName
    OfficialDesignation
    Surname
  }
`;
export const KccaCustomerContactPersonAffiliatesFragmentDoc = gql`
  fragment KccaCustomerContactPersonAffiliates on rtcs_db_PH_kcca_CustomerContactPerson {
    AcquiredName
    CustomerId
    Email
    Firstname
    Id
    MiddleName
    Mobile
    MotherMaidenName
    OtherTitle
    RelationshipDesignation
    Surname
    Telephone
    TitleId
  }
`;
export const KccaCustomerReferenceAffiliatesFragmentDoc = gql`
  fragment KccaCustomerReferenceAffiliates on rtcs_db_PH_kcca_CustomerReference {
    AcquiredName
    Coin
    CustomerId
    Email
    Firstname
    Id
    MiddleName
    Mobile
    RelationshipDesignation
    Surname
  }
`;
export const KccaCustomerRevenueAgentAffiliatesFragmentDoc = gql`
  fragment KccaCustomerRevenueAgentAffiliates on rtcs_db_PH_kcca_CustomerRevenueAgent {
    AgentLegalName
    CustomerId
    Email
    Mobile
    Telephone
  }
`;
export const PropertyUtilitiesFragmentDoc = gql`
  fragment PropertyUtilities on rtcs_db_PH_nwsc_Customers {
    CustomernamePay
    Customerreference
    Customerphoneno1Pay
    Customerphoneno2Pay
    Customerphoneno3Pay
    AddressPay
    AreaPay
    LatitudePay
    LongitudePay
  }
`;
export const NwscTransactionsFragmentDoc = gql`
  fragment NwscTransactions on rtcs_db_PH_nwsc_RPT_Transactions {
    Value
    Period
    Type
    Customerreference
  }
`;
export const IndividualCompositeVisibilityDataFragmentDoc = gql`
  fragment IndividualCompositeVisibilityData on rtcs_db_PH_RPT_IndividualComposite {
    Rnid
    Firstname
    MiddleName
    Surname
    EmailId
    UraTinNo
    BirthDt
  }
`;
export const RangeSliderTestQueryDocument = gql`
  query RangeSliderTestQuery {
    rtcs_db_Fnura_IncomeTaxSummaryIndividual_aggregatecm(
      args: {
        aggregate_params: "{\\"min\\":\\"TotIncome\\", \\"max\\":\\"TotIncome\\"}"
        params: "{\\"variables\\":{\\"where\\":{}}}"
      }
    ) {
      operation
      value
    }
  }
`;

/**
 * __useRangeSliderTestQueryQuery__
 *
 * To run a query within a React component, call `useRangeSliderTestQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRangeSliderTestQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRangeSliderTestQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useRangeSliderTestQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RangeSliderTestQueryQuery,
    RangeSliderTestQueryQueryVariables
  >
) {
  return Apollo.useQuery<
    RangeSliderTestQueryQuery,
    RangeSliderTestQueryQueryVariables
  >(RangeSliderTestQueryDocument, baseOptions);
}
export function useRangeSliderTestQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RangeSliderTestQueryQuery,
    RangeSliderTestQueryQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    RangeSliderTestQueryQuery,
    RangeSliderTestQueryQueryVariables
  >(RangeSliderTestQueryDocument, baseOptions);
}
export type RangeSliderTestQueryQueryHookResult = ReturnType<
  typeof useRangeSliderTestQueryQuery
>;
export type RangeSliderTestQueryLazyQueryHookResult = ReturnType<
  typeof useRangeSliderTestQueryLazyQuery
>;
export type RangeSliderTestQueryQueryResult = Apollo.QueryResult<
  RangeSliderTestQueryQuery,
  RangeSliderTestQueryQueryVariables
>;
export const PropertyImagesDocument = gql`
  query PropertyImages($params: String!) {
    rtcs_db_Fnkcca_ImgsData(args: { params: $params }) {
      ...PropertyImages
    }
  }
  ${PropertyImagesFragmentDoc}
`;

/**
 * __usePropertyImagesQuery__
 *
 * To run a query within a React component, call `usePropertyImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyImagesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function usePropertyImagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    PropertyImagesQuery,
    PropertyImagesQueryVariables
  >
) {
  return Apollo.useQuery<PropertyImagesQuery, PropertyImagesQueryVariables>(
    PropertyImagesDocument,
    baseOptions
  );
}
export function usePropertyImagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PropertyImagesQuery,
    PropertyImagesQueryVariables
  >
) {
  return Apollo.useLazyQuery<PropertyImagesQuery, PropertyImagesQueryVariables>(
    PropertyImagesDocument,
    baseOptions
  );
}
export type PropertyImagesQueryHookResult = ReturnType<
  typeof usePropertyImagesQuery
>;
export type PropertyImagesLazyQueryHookResult = ReturnType<
  typeof usePropertyImagesLazyQuery
>;
export type PropertyImagesQueryResult = Apollo.QueryResult<
  PropertyImagesQuery,
  PropertyImagesQueryVariables
>;
export const GetPaginatedRegIndividualsDocument = gql`
  query GetPaginatedRegIndividuals(
    $params: String!
    $aggregateParams: String!
  ) {
    rtcs_db_FnRPT_IndividualRegistration(args: { params: $params }) {
      ...RegIndividualData
    }
    rtcs_db_FnRPT_IndividualRegistration_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${RegIndividualDataFragmentDoc}
`;

/**
 * __useGetPaginatedRegIndividualsQuery__
 *
 * To run a query within a React component, call `useGetPaginatedRegIndividualsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedRegIndividualsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedRegIndividualsQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedRegIndividualsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedRegIndividualsQuery,
    GetPaginatedRegIndividualsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedRegIndividualsQuery,
    GetPaginatedRegIndividualsQueryVariables
  >(GetPaginatedRegIndividualsDocument, baseOptions);
}
export function useGetPaginatedRegIndividualsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedRegIndividualsQuery,
    GetPaginatedRegIndividualsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedRegIndividualsQuery,
    GetPaginatedRegIndividualsQueryVariables
  >(GetPaginatedRegIndividualsDocument, baseOptions);
}
export type GetPaginatedRegIndividualsQueryHookResult = ReturnType<
  typeof useGetPaginatedRegIndividualsQuery
>;
export type GetPaginatedRegIndividualsLazyQueryHookResult = ReturnType<
  typeof useGetPaginatedRegIndividualsLazyQuery
>;
export type GetPaginatedRegIndividualsQueryResult = Apollo.QueryResult<
  GetPaginatedRegIndividualsQuery,
  GetPaginatedRegIndividualsQueryVariables
>;
export const GetPaginatedMasterIndividualsDocument = gql`
  query GetPaginatedMasterIndividuals(
    $params: String!
    $aggregateParams: String!
  ) {
    rtcs_db_Fnrmatch_IndividualRiskScore(args: { params: $params }) {
      ...MasterIndividualData
    }
    rtcs_db_Fnrmatch_IndividualRiskScore_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${MasterIndividualDataFragmentDoc}
`;

/**
 * __useGetPaginatedMasterIndividualsQuery__
 *
 * To run a query within a React component, call `useGetPaginatedMasterIndividualsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedMasterIndividualsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedMasterIndividualsQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedMasterIndividualsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedMasterIndividualsQuery,
    GetPaginatedMasterIndividualsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedMasterIndividualsQuery,
    GetPaginatedMasterIndividualsQueryVariables
  >(GetPaginatedMasterIndividualsDocument, baseOptions);
}
export function useGetPaginatedMasterIndividualsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedMasterIndividualsQuery,
    GetPaginatedMasterIndividualsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedMasterIndividualsQuery,
    GetPaginatedMasterIndividualsQueryVariables
  >(GetPaginatedMasterIndividualsDocument, baseOptions);
}
export type GetPaginatedMasterIndividualsQueryHookResult = ReturnType<
  typeof useGetPaginatedMasterIndividualsQuery
>;
export type GetPaginatedMasterIndividualsLazyQueryHookResult = ReturnType<
  typeof useGetPaginatedMasterIndividualsLazyQuery
>;
export type GetPaginatedMasterIndividualsQueryResult = Apollo.QueryResult<
  GetPaginatedMasterIndividualsQuery,
  GetPaginatedMasterIndividualsQueryVariables
>;
export const IndividualRiskScoresFilterDocument = gql`
  query IndividualRiskScoresFilter($params: String!) {
    rtcs_db_Fnrmatch_IndividualRiskScore(args: { params: $params }) {
      ...MasterIndividualData
    }
  }
  ${MasterIndividualDataFragmentDoc}
`;

/**
 * __useIndividualRiskScoresFilterQuery__
 *
 * To run a query within a React component, call `useIndividualRiskScoresFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndividualRiskScoresFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndividualRiskScoresFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useIndividualRiskScoresFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndividualRiskScoresFilterQuery,
    IndividualRiskScoresFilterQueryVariables
  >
) {
  return Apollo.useQuery<
    IndividualRiskScoresFilterQuery,
    IndividualRiskScoresFilterQueryVariables
  >(IndividualRiskScoresFilterDocument, baseOptions);
}
export function useIndividualRiskScoresFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndividualRiskScoresFilterQuery,
    IndividualRiskScoresFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    IndividualRiskScoresFilterQuery,
    IndividualRiskScoresFilterQueryVariables
  >(IndividualRiskScoresFilterDocument, baseOptions);
}
export type IndividualRiskScoresFilterQueryHookResult = ReturnType<
  typeof useIndividualRiskScoresFilterQuery
>;
export type IndividualRiskScoresFilterLazyQueryHookResult = ReturnType<
  typeof useIndividualRiskScoresFilterLazyQuery
>;
export type IndividualRiskScoresFilterQueryResult = Apollo.QueryResult<
  IndividualRiskScoresFilterQuery,
  IndividualRiskScoresFilterQueryVariables
>;
export const GetMaxMinIndividualRiskScoreDocument = gql`
  query GetMaxMinIndividualRiskScore {
    rtcs_db_Fnrmatch_IndividualRiskScore_aggregatecm(
      args: {
        aggregate_params: "{\\"min\\": [\\"Riskscore\\"],\\"max\\": [\\"Riskscore\\"] }"
        params: "{\\"variables\\":{\\"where\\":{}}}"
      }
    ) {
      operation
      value
    }
  }
`;

/**
 * __useGetMaxMinIndividualRiskScoreQuery__
 *
 * To run a query within a React component, call `useGetMaxMinIndividualRiskScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaxMinIndividualRiskScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaxMinIndividualRiskScoreQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMaxMinIndividualRiskScoreQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMaxMinIndividualRiskScoreQuery,
    GetMaxMinIndividualRiskScoreQueryVariables
  >
) {
  return Apollo.useQuery<
    GetMaxMinIndividualRiskScoreQuery,
    GetMaxMinIndividualRiskScoreQueryVariables
  >(GetMaxMinIndividualRiskScoreDocument, baseOptions);
}
export function useGetMaxMinIndividualRiskScoreLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMaxMinIndividualRiskScoreQuery,
    GetMaxMinIndividualRiskScoreQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetMaxMinIndividualRiskScoreQuery,
    GetMaxMinIndividualRiskScoreQueryVariables
  >(GetMaxMinIndividualRiskScoreDocument, baseOptions);
}
export type GetMaxMinIndividualRiskScoreQueryHookResult = ReturnType<
  typeof useGetMaxMinIndividualRiskScoreQuery
>;
export type GetMaxMinIndividualRiskScoreLazyQueryHookResult = ReturnType<
  typeof useGetMaxMinIndividualRiskScoreLazyQuery
>;
export type GetMaxMinIndividualRiskScoreQueryResult = Apollo.QueryResult<
  GetMaxMinIndividualRiskScoreQuery,
  GetMaxMinIndividualRiskScoreQueryVariables
>;
export const GetKccaCommercialPropertiesDocument = gql`
  query GetKccaCommercialProperties($params: String!) {
    rtcs_db_Fnkcca_CommercialProperties(args: { params: $params }) {
      ...KccaCommercialProperties
    }
  }
  ${KccaCommercialPropertiesFragmentDoc}
`;

/**
 * __useGetKccaCommercialPropertiesQuery__
 *
 * To run a query within a React component, call `useGetKccaCommercialPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKccaCommercialPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKccaCommercialPropertiesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetKccaCommercialPropertiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetKccaCommercialPropertiesQuery,
    GetKccaCommercialPropertiesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetKccaCommercialPropertiesQuery,
    GetKccaCommercialPropertiesQueryVariables
  >(GetKccaCommercialPropertiesDocument, baseOptions);
}
export function useGetKccaCommercialPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetKccaCommercialPropertiesQuery,
    GetKccaCommercialPropertiesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetKccaCommercialPropertiesQuery,
    GetKccaCommercialPropertiesQueryVariables
  >(GetKccaCommercialPropertiesDocument, baseOptions);
}
export type GetKccaCommercialPropertiesQueryHookResult = ReturnType<
  typeof useGetKccaCommercialPropertiesQuery
>;
export type GetKccaCommercialPropertiesLazyQueryHookResult = ReturnType<
  typeof useGetKccaCommercialPropertiesLazyQuery
>;
export type GetKccaCommercialPropertiesQueryResult = Apollo.QueryResult<
  GetKccaCommercialPropertiesQuery,
  GetKccaCommercialPropertiesQueryVariables
>;
export const GetKccaResidentialPropertiesDocument = gql`
  query GetKccaResidentialProperties($params: String!) {
    rtcs_db_Fnkcca_ResidentialProperties(args: { params: $params }) {
      ...KccaResidentialProperties
    }
  }
  ${KccaResidentialPropertiesFragmentDoc}
`;

/**
 * __useGetKccaResidentialPropertiesQuery__
 *
 * To run a query within a React component, call `useGetKccaResidentialPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKccaResidentialPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKccaResidentialPropertiesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetKccaResidentialPropertiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetKccaResidentialPropertiesQuery,
    GetKccaResidentialPropertiesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetKccaResidentialPropertiesQuery,
    GetKccaResidentialPropertiesQueryVariables
  >(GetKccaResidentialPropertiesDocument, baseOptions);
}
export function useGetKccaResidentialPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetKccaResidentialPropertiesQuery,
    GetKccaResidentialPropertiesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetKccaResidentialPropertiesQuery,
    GetKccaResidentialPropertiesQueryVariables
  >(GetKccaResidentialPropertiesDocument, baseOptions);
}
export type GetKccaResidentialPropertiesQueryHookResult = ReturnType<
  typeof useGetKccaResidentialPropertiesQuery
>;
export type GetKccaResidentialPropertiesLazyQueryHookResult = ReturnType<
  typeof useGetKccaResidentialPropertiesLazyQuery
>;
export type GetKccaResidentialPropertiesQueryResult = Apollo.QueryResult<
  GetKccaResidentialPropertiesQuery,
  GetKccaResidentialPropertiesQueryVariables
>;
export const GetNetworkPropertiesDocument = gql`
  query GetNetworkProperties($params: String!) {
    rtcs_db_Fnrmatch_PropertiesNetwork(args: { params: $params }) {
      ...NetworkProperties
    }
  }
  ${NetworkPropertiesFragmentDoc}
`;

/**
 * __useGetNetworkPropertiesQuery__
 *
 * To run a query within a React component, call `useGetNetworkPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNetworkPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNetworkPropertiesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetNetworkPropertiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNetworkPropertiesQuery,
    GetNetworkPropertiesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetNetworkPropertiesQuery,
    GetNetworkPropertiesQueryVariables
  >(GetNetworkPropertiesDocument, baseOptions);
}
export function useGetNetworkPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNetworkPropertiesQuery,
    GetNetworkPropertiesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetNetworkPropertiesQuery,
    GetNetworkPropertiesQueryVariables
  >(GetNetworkPropertiesDocument, baseOptions);
}
export type GetNetworkPropertiesQueryHookResult = ReturnType<
  typeof useGetNetworkPropertiesQuery
>;
export type GetNetworkPropertiesLazyQueryHookResult = ReturnType<
  typeof useGetNetworkPropertiesLazyQuery
>;
export type GetNetworkPropertiesQueryResult = Apollo.QueryResult<
  GetNetworkPropertiesQuery,
  GetNetworkPropertiesQueryVariables
>;
export const GetPaginatedNetworkPropertiesDocument = gql`
  query GetPaginatedNetworkProperties(
    $params: String!
    $aggregateParams: String!
  ) {
    rtcs_db_Fnrmatch_PropertiesNetwork(args: { params: $params }) {
      ...NetworkProperties
    }
    rtcs_db_Fnrmatch_PropertiesNetwork_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${NetworkPropertiesFragmentDoc}
`;

/**
 * __useGetPaginatedNetworkPropertiesQuery__
 *
 * To run a query within a React component, call `useGetPaginatedNetworkPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedNetworkPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedNetworkPropertiesQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedNetworkPropertiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedNetworkPropertiesQuery,
    GetPaginatedNetworkPropertiesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedNetworkPropertiesQuery,
    GetPaginatedNetworkPropertiesQueryVariables
  >(GetPaginatedNetworkPropertiesDocument, baseOptions);
}
export function useGetPaginatedNetworkPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedNetworkPropertiesQuery,
    GetPaginatedNetworkPropertiesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedNetworkPropertiesQuery,
    GetPaginatedNetworkPropertiesQueryVariables
  >(GetPaginatedNetworkPropertiesDocument, baseOptions);
}
export type GetPaginatedNetworkPropertiesQueryHookResult = ReturnType<
  typeof useGetPaginatedNetworkPropertiesQuery
>;
export type GetPaginatedNetworkPropertiesLazyQueryHookResult = ReturnType<
  typeof useGetPaginatedNetworkPropertiesLazyQuery
>;
export type GetPaginatedNetworkPropertiesQueryResult = Apollo.QueryResult<
  GetPaginatedNetworkPropertiesQuery,
  GetPaginatedNetworkPropertiesQueryVariables
>;
export const GetMaxMinNetworkPropertiesDocument = gql`
  query GetMaxMinNetworkProperties {
    rtcs_db_Fnrmatch_PropertiesNetwork_aggregatecm(
      args: {
        aggregate_params: "{\\"min\\": [\\"GrossValue\\"],\\"max\\": [\\"GrossValue\\"] }"
        params: "{\\"variables\\":{\\"where\\":{}}}"
      }
    ) {
      operation
      value
    }
  }
`;

/**
 * __useGetMaxMinNetworkPropertiesQuery__
 *
 * To run a query within a React component, call `useGetMaxMinNetworkPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaxMinNetworkPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaxMinNetworkPropertiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMaxMinNetworkPropertiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMaxMinNetworkPropertiesQuery,
    GetMaxMinNetworkPropertiesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetMaxMinNetworkPropertiesQuery,
    GetMaxMinNetworkPropertiesQueryVariables
  >(GetMaxMinNetworkPropertiesDocument, baseOptions);
}
export function useGetMaxMinNetworkPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMaxMinNetworkPropertiesQuery,
    GetMaxMinNetworkPropertiesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetMaxMinNetworkPropertiesQuery,
    GetMaxMinNetworkPropertiesQueryVariables
  >(GetMaxMinNetworkPropertiesDocument, baseOptions);
}
export type GetMaxMinNetworkPropertiesQueryHookResult = ReturnType<
  typeof useGetMaxMinNetworkPropertiesQuery
>;
export type GetMaxMinNetworkPropertiesLazyQueryHookResult = ReturnType<
  typeof useGetMaxMinNetworkPropertiesLazyQuery
>;
export type GetMaxMinNetworkPropertiesQueryResult = Apollo.QueryResult<
  GetMaxMinNetworkPropertiesQuery,
  GetMaxMinNetworkPropertiesQueryVariables
>;
export const PropertiesValuationGetDocument = gql`
  query PropertiesValuationGet($params: String!) {
    rtcs_db_FnRPT_PropertyValuation(args: { params: $params }) {
      ...PropertiesValuation
    }
  }
  ${PropertiesValuationFragmentDoc}
`;

/**
 * __usePropertiesValuationGetQuery__
 *
 * To run a query within a React component, call `usePropertiesValuationGetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertiesValuationGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertiesValuationGetQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function usePropertiesValuationGetQuery(
  baseOptions: Apollo.QueryHookOptions<
    PropertiesValuationGetQuery,
    PropertiesValuationGetQueryVariables
  >
) {
  return Apollo.useQuery<
    PropertiesValuationGetQuery,
    PropertiesValuationGetQueryVariables
  >(PropertiesValuationGetDocument, baseOptions);
}
export function usePropertiesValuationGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PropertiesValuationGetQuery,
    PropertiesValuationGetQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    PropertiesValuationGetQuery,
    PropertiesValuationGetQueryVariables
  >(PropertiesValuationGetDocument, baseOptions);
}
export type PropertiesValuationGetQueryHookResult = ReturnType<
  typeof usePropertiesValuationGetQuery
>;
export type PropertiesValuationGetLazyQueryHookResult = ReturnType<
  typeof usePropertiesValuationGetLazyQuery
>;
export type PropertiesValuationGetQueryResult = Apollo.QueryResult<
  PropertiesValuationGetQuery,
  PropertiesValuationGetQueryVariables
>;
export const RegNonIndividualFilterDocument = gql`
  query RegNonIndividualFilter($params: String!) {
    rtcs_db_Fnura_RegNonIndividual(args: { params: $params }) {
      ...RegNonIndividualData
    }
  }
  ${RegNonIndividualDataFragmentDoc}
`;

/**
 * __useRegNonIndividualFilterQuery__
 *
 * To run a query within a React component, call `useRegNonIndividualFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegNonIndividualFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegNonIndividualFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRegNonIndividualFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegNonIndividualFilterQuery,
    RegNonIndividualFilterQueryVariables
  >
) {
  return Apollo.useQuery<
    RegNonIndividualFilterQuery,
    RegNonIndividualFilterQueryVariables
  >(RegNonIndividualFilterDocument, baseOptions);
}
export function useRegNonIndividualFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegNonIndividualFilterQuery,
    RegNonIndividualFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    RegNonIndividualFilterQuery,
    RegNonIndividualFilterQueryVariables
  >(RegNonIndividualFilterDocument, baseOptions);
}
export type RegNonIndividualFilterQueryHookResult = ReturnType<
  typeof useRegNonIndividualFilterQuery
>;
export type RegNonIndividualFilterLazyQueryHookResult = ReturnType<
  typeof useRegNonIndividualFilterLazyQuery
>;
export type RegNonIndividualFilterQueryResult = Apollo.QueryResult<
  RegNonIndividualFilterQuery,
  RegNonIndividualFilterQueryVariables
>;
export const RegNonIndividualFilterOneDocument = gql`
  query RegNonIndividualFilterOne($params: String!) {
    rtcs_db_Fnura_RegNonIndividual(limit: 1, args: { params: $params }) {
      ...RegNonIndividualData
    }
  }
  ${RegNonIndividualDataFragmentDoc}
`;

/**
 * __useRegNonIndividualFilterOneQuery__
 *
 * To run a query within a React component, call `useRegNonIndividualFilterOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegNonIndividualFilterOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegNonIndividualFilterOneQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRegNonIndividualFilterOneQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegNonIndividualFilterOneQuery,
    RegNonIndividualFilterOneQueryVariables
  >
) {
  return Apollo.useQuery<
    RegNonIndividualFilterOneQuery,
    RegNonIndividualFilterOneQueryVariables
  >(RegNonIndividualFilterOneDocument, baseOptions);
}
export function useRegNonIndividualFilterOneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegNonIndividualFilterOneQuery,
    RegNonIndividualFilterOneQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    RegNonIndividualFilterOneQuery,
    RegNonIndividualFilterOneQueryVariables
  >(RegNonIndividualFilterOneDocument, baseOptions);
}
export type RegNonIndividualFilterOneQueryHookResult = ReturnType<
  typeof useRegNonIndividualFilterOneQuery
>;
export type RegNonIndividualFilterOneLazyQueryHookResult = ReturnType<
  typeof useRegNonIndividualFilterOneLazyQuery
>;
export type RegNonIndividualFilterOneQueryResult = Apollo.QueryResult<
  RegNonIndividualFilterOneQuery,
  RegNonIndividualFilterOneQueryVariables
>;
export const PaginatedRegNonIndividualDocument = gql`
  query paginatedRegNonIndividual($params: String!, $aggregateParams: String!) {
    rtcs_db_Fnura_RegNonIndividual(args: { params: $params }) {
      ...RegNonIndividualData
    }
    rtcs_db_Fnura_RegNonIndividual_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${RegNonIndividualDataFragmentDoc}
`;

/**
 * __usePaginatedRegNonIndividualQuery__
 *
 * To run a query within a React component, call `usePaginatedRegNonIndividualQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedRegNonIndividualQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedRegNonIndividualQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function usePaginatedRegNonIndividualQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaginatedRegNonIndividualQuery,
    PaginatedRegNonIndividualQueryVariables
  >
) {
  return Apollo.useQuery<
    PaginatedRegNonIndividualQuery,
    PaginatedRegNonIndividualQueryVariables
  >(PaginatedRegNonIndividualDocument, baseOptions);
}
export function usePaginatedRegNonIndividualLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedRegNonIndividualQuery,
    PaginatedRegNonIndividualQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    PaginatedRegNonIndividualQuery,
    PaginatedRegNonIndividualQueryVariables
  >(PaginatedRegNonIndividualDocument, baseOptions);
}
export type PaginatedRegNonIndividualQueryHookResult = ReturnType<
  typeof usePaginatedRegNonIndividualQuery
>;
export type PaginatedRegNonIndividualLazyQueryHookResult = ReturnType<
  typeof usePaginatedRegNonIndividualLazyQuery
>;
export type PaginatedRegNonIndividualQueryResult = Apollo.QueryResult<
  PaginatedRegNonIndividualQuery,
  PaginatedRegNonIndividualQueryVariables
>;
export const UpdateRegNonIndividualDocument = gql`
  mutation updateRegNonIndividual(
    $objects: [rtcs_db_PH_ura_RegNonIndividual_insert_input!]!
  ) {
    insert_rtcs_db_PH_ura_RegNonIndividual(objects: $objects) {
      affected_rows
      returning {
        ...RegNonIndividualData
      }
    }
  }
  ${RegNonIndividualDataFragmentDoc}
`;
export type UpdateRegNonIndividualMutationFn = Apollo.MutationFunction<
  UpdateRegNonIndividualMutation,
  UpdateRegNonIndividualMutationVariables
>;

/**
 * __useUpdateRegNonIndividualMutation__
 *
 * To run a mutation, you first call `useUpdateRegNonIndividualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegNonIndividualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegNonIndividualMutation, { data, loading, error }] = useUpdateRegNonIndividualMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateRegNonIndividualMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRegNonIndividualMutation,
    UpdateRegNonIndividualMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateRegNonIndividualMutation,
    UpdateRegNonIndividualMutationVariables
  >(UpdateRegNonIndividualDocument, baseOptions);
}
export type UpdateRegNonIndividualMutationHookResult = ReturnType<
  typeof useUpdateRegNonIndividualMutation
>;
export type UpdateRegNonIndividualMutationResult =
  Apollo.MutationResult<UpdateRegNonIndividualMutation>;
export type UpdateRegNonIndividualMutationOptions = Apollo.BaseMutationOptions<
  UpdateRegNonIndividualMutation,
  UpdateRegNonIndividualMutationVariables
>;
export const NonIndividualRiskScoresFilterDocument = gql`
  query NonIndividualRiskScoresFilter($params: String!) {
    rtcs_db_Fnrmatch_NonIndividualRiskScore(args: { params: $params }) {
      ...NonIndividualRiskScores
    }
  }
  ${NonIndividualRiskScoresFragmentDoc}
`;

/**
 * __useNonIndividualRiskScoresFilterQuery__
 *
 * To run a query within a React component, call `useNonIndividualRiskScoresFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonIndividualRiskScoresFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonIndividualRiskScoresFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useNonIndividualRiskScoresFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    NonIndividualRiskScoresFilterQuery,
    NonIndividualRiskScoresFilterQueryVariables
  >
) {
  return Apollo.useQuery<
    NonIndividualRiskScoresFilterQuery,
    NonIndividualRiskScoresFilterQueryVariables
  >(NonIndividualRiskScoresFilterDocument, baseOptions);
}
export function useNonIndividualRiskScoresFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NonIndividualRiskScoresFilterQuery,
    NonIndividualRiskScoresFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    NonIndividualRiskScoresFilterQuery,
    NonIndividualRiskScoresFilterQueryVariables
  >(NonIndividualRiskScoresFilterDocument, baseOptions);
}
export type NonIndividualRiskScoresFilterQueryHookResult = ReturnType<
  typeof useNonIndividualRiskScoresFilterQuery
>;
export type NonIndividualRiskScoresFilterLazyQueryHookResult = ReturnType<
  typeof useNonIndividualRiskScoresFilterLazyQuery
>;
export type NonIndividualRiskScoresFilterQueryResult = Apollo.QueryResult<
  NonIndividualRiskScoresFilterQuery,
  NonIndividualRiskScoresFilterQueryVariables
>;
export const GetPaginatedNonIndividualRiskScoresDocument = gql`
  query GetPaginatedNonIndividualRiskScores(
    $params: String!
    $aggregateParams: String!
  ) {
    rtcs_db_Fnrmatch_NonIndividualRiskScore(args: { params: $params }) {
      ...NonIndividualRiskScores
    }
    rtcs_db_Fnrmatch_NonIndividualRiskScore_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${NonIndividualRiskScoresFragmentDoc}
`;

/**
 * __useGetPaginatedNonIndividualRiskScoresQuery__
 *
 * To run a query within a React component, call `useGetPaginatedNonIndividualRiskScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedNonIndividualRiskScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedNonIndividualRiskScoresQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedNonIndividualRiskScoresQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedNonIndividualRiskScoresQuery,
    GetPaginatedNonIndividualRiskScoresQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedNonIndividualRiskScoresQuery,
    GetPaginatedNonIndividualRiskScoresQueryVariables
  >(GetPaginatedNonIndividualRiskScoresDocument, baseOptions);
}
export function useGetPaginatedNonIndividualRiskScoresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedNonIndividualRiskScoresQuery,
    GetPaginatedNonIndividualRiskScoresQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedNonIndividualRiskScoresQuery,
    GetPaginatedNonIndividualRiskScoresQueryVariables
  >(GetPaginatedNonIndividualRiskScoresDocument, baseOptions);
}
export type GetPaginatedNonIndividualRiskScoresQueryHookResult = ReturnType<
  typeof useGetPaginatedNonIndividualRiskScoresQuery
>;
export type GetPaginatedNonIndividualRiskScoresLazyQueryHookResult = ReturnType<
  typeof useGetPaginatedNonIndividualRiskScoresLazyQuery
>;
export type GetPaginatedNonIndividualRiskScoresQueryResult = Apollo.QueryResult<
  GetPaginatedNonIndividualRiskScoresQuery,
  GetPaginatedNonIndividualRiskScoresQueryVariables
>;
export const GetMaxMinNonIndividualRiskScoreDocument = gql`
  query GetMaxMinNonIndividualRiskScore {
    rtcs_db_Fnrmatch_NonIndividualRiskScore_aggregatecm(
      args: {
        aggregate_params: "{\\"min\\": [\\"Riskscore\\"],\\"max\\": [\\"Riskscore\\"] }"
        params: "{\\"variables\\":{\\"where\\":{}}}"
      }
    ) {
      operation
      value
    }
  }
`;

/**
 * __useGetMaxMinNonIndividualRiskScoreQuery__
 *
 * To run a query within a React component, call `useGetMaxMinNonIndividualRiskScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaxMinNonIndividualRiskScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaxMinNonIndividualRiskScoreQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMaxMinNonIndividualRiskScoreQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMaxMinNonIndividualRiskScoreQuery,
    GetMaxMinNonIndividualRiskScoreQueryVariables
  >
) {
  return Apollo.useQuery<
    GetMaxMinNonIndividualRiskScoreQuery,
    GetMaxMinNonIndividualRiskScoreQueryVariables
  >(GetMaxMinNonIndividualRiskScoreDocument, baseOptions);
}
export function useGetMaxMinNonIndividualRiskScoreLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMaxMinNonIndividualRiskScoreQuery,
    GetMaxMinNonIndividualRiskScoreQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetMaxMinNonIndividualRiskScoreQuery,
    GetMaxMinNonIndividualRiskScoreQueryVariables
  >(GetMaxMinNonIndividualRiskScoreDocument, baseOptions);
}
export type GetMaxMinNonIndividualRiskScoreQueryHookResult = ReturnType<
  typeof useGetMaxMinNonIndividualRiskScoreQuery
>;
export type GetMaxMinNonIndividualRiskScoreLazyQueryHookResult = ReturnType<
  typeof useGetMaxMinNonIndividualRiskScoreLazyQuery
>;
export type GetMaxMinNonIndividualRiskScoreQueryResult = Apollo.QueryResult<
  GetMaxMinNonIndividualRiskScoreQuery,
  GetMaxMinNonIndividualRiskScoreQueryVariables
>;
export const RegIndividualDataDocument = gql`
  query RegIndividualData($params: String!) {
    rtcs_db_FnRPT_IndividualRegistration(args: { params: $params }) {
      ...RegIndividualDataHPOC
    }
  }
  ${RegIndividualDataHpocFragmentDoc}
`;

/**
 * __useRegIndividualDataQuery__
 *
 * To run a query within a React component, call `useRegIndividualDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegIndividualDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegIndividualDataQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRegIndividualDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegIndividualDataQuery,
    RegIndividualDataQueryVariables
  >
) {
  return Apollo.useQuery<
    RegIndividualDataQuery,
    RegIndividualDataQueryVariables
  >(RegIndividualDataDocument, baseOptions);
}
export function useRegIndividualDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegIndividualDataQuery,
    RegIndividualDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    RegIndividualDataQuery,
    RegIndividualDataQueryVariables
  >(RegIndividualDataDocument, baseOptions);
}
export type RegIndividualDataQueryHookResult = ReturnType<
  typeof useRegIndividualDataQuery
>;
export type RegIndividualDataLazyQueryHookResult = ReturnType<
  typeof useRegIndividualDataLazyQuery
>;
export type RegIndividualDataQueryResult = Apollo.QueryResult<
  RegIndividualDataQuery,
  RegIndividualDataQueryVariables
>;
export const RegIndividualSuggestionsDocument = gql`
  query RegIndividualSuggestions($params: String!) {
    rtcs_db_FnRPT_IndividualRegistration(args: { params: $params }) {
      ...RegIndividualSuggestion
    }
  }
  ${RegIndividualSuggestionFragmentDoc}
`;

/**
 * __useRegIndividualSuggestionsQuery__
 *
 * To run a query within a React component, call `useRegIndividualSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegIndividualSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegIndividualSuggestionsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRegIndividualSuggestionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegIndividualSuggestionsQuery,
    RegIndividualSuggestionsQueryVariables
  >
) {
  return Apollo.useQuery<
    RegIndividualSuggestionsQuery,
    RegIndividualSuggestionsQueryVariables
  >(RegIndividualSuggestionsDocument, baseOptions);
}
export function useRegIndividualSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegIndividualSuggestionsQuery,
    RegIndividualSuggestionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    RegIndividualSuggestionsQuery,
    RegIndividualSuggestionsQueryVariables
  >(RegIndividualSuggestionsDocument, baseOptions);
}
export type RegIndividualSuggestionsQueryHookResult = ReturnType<
  typeof useRegIndividualSuggestionsQuery
>;
export type RegIndividualSuggestionsLazyQueryHookResult = ReturnType<
  typeof useRegIndividualSuggestionsLazyQuery
>;
export type RegIndividualSuggestionsQueryResult = Apollo.QueryResult<
  RegIndividualSuggestionsQuery,
  RegIndividualSuggestionsQueryVariables
>;
export const Rpt_PropertyCompositeDocument = gql`
  query RPT_PropertyComposite($params: String!) {
    rtcs_db_FnRPT_PropertiesComposite(args: { params: $params }) {
      ...PropertyComposite
    }
  }
  ${PropertyCompositeFragmentDoc}
`;

/**
 * __useRpt_PropertyCompositeQuery__
 *
 * To run a query within a React component, call `useRpt_PropertyCompositeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRpt_PropertyCompositeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRpt_PropertyCompositeQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRpt_PropertyCompositeQuery(
  baseOptions: Apollo.QueryHookOptions<
    Rpt_PropertyCompositeQuery,
    Rpt_PropertyCompositeQueryVariables
  >
) {
  return Apollo.useQuery<
    Rpt_PropertyCompositeQuery,
    Rpt_PropertyCompositeQueryVariables
  >(Rpt_PropertyCompositeDocument, baseOptions);
}
export function useRpt_PropertyCompositeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Rpt_PropertyCompositeQuery,
    Rpt_PropertyCompositeQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    Rpt_PropertyCompositeQuery,
    Rpt_PropertyCompositeQueryVariables
  >(Rpt_PropertyCompositeDocument, baseOptions);
}
export type Rpt_PropertyCompositeQueryHookResult = ReturnType<
  typeof useRpt_PropertyCompositeQuery
>;
export type Rpt_PropertyCompositeLazyQueryHookResult = ReturnType<
  typeof useRpt_PropertyCompositeLazyQuery
>;
export type Rpt_PropertyCompositeQueryResult = Apollo.QueryResult<
  Rpt_PropertyCompositeQuery,
  Rpt_PropertyCompositeQueryVariables
>;
export const GetPaginatedRpt_PropertyCompositeDocument = gql`
  query GetPaginatedRPT_PropertyComposite(
    $params: String!
    $aggregateParams: String!
  ) {
    rtcs_db_FnRPT_PropertiesComposite(args: { params: $params }) {
      ...PropertyComposite
    }
    rtcs_db_FnRPT_PropertiesComposite_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${PropertyCompositeFragmentDoc}
`;

/**
 * __useGetPaginatedRpt_PropertyCompositeQuery__
 *
 * To run a query within a React component, call `useGetPaginatedRpt_PropertyCompositeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedRpt_PropertyCompositeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedRpt_PropertyCompositeQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedRpt_PropertyCompositeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedRpt_PropertyCompositeQuery,
    GetPaginatedRpt_PropertyCompositeQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedRpt_PropertyCompositeQuery,
    GetPaginatedRpt_PropertyCompositeQueryVariables
  >(GetPaginatedRpt_PropertyCompositeDocument, baseOptions);
}
export function useGetPaginatedRpt_PropertyCompositeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedRpt_PropertyCompositeQuery,
    GetPaginatedRpt_PropertyCompositeQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedRpt_PropertyCompositeQuery,
    GetPaginatedRpt_PropertyCompositeQueryVariables
  >(GetPaginatedRpt_PropertyCompositeDocument, baseOptions);
}
export type GetPaginatedRpt_PropertyCompositeQueryHookResult = ReturnType<
  typeof useGetPaginatedRpt_PropertyCompositeQuery
>;
export type GetPaginatedRpt_PropertyCompositeLazyQueryHookResult = ReturnType<
  typeof useGetPaginatedRpt_PropertyCompositeLazyQuery
>;
export type GetPaginatedRpt_PropertyCompositeQueryResult = Apollo.QueryResult<
  GetPaginatedRpt_PropertyCompositeQuery,
  GetPaginatedRpt_PropertyCompositeQueryVariables
>;
export const GetMaxMinRpt_PropertyCompositeDocument = gql`
  query GetMaxMinRPT_PropertyComposite {
    rtcs_db_FnRPT_PropertiesComposite_aggregatecm(
      args: {
        aggregate_params: "{\\"min\\": [\\"GrossRentValueAdjusted\\"],\\"max\\": [\\"GrossRentValueAdjusted\\"] }"
        params: "{\\"variables\\":{\\"where\\":{}}}"
      }
    ) {
      operation
      value
    }
  }
`;

/**
 * __useGetMaxMinRpt_PropertyCompositeQuery__
 *
 * To run a query within a React component, call `useGetMaxMinRpt_PropertyCompositeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaxMinRpt_PropertyCompositeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaxMinRpt_PropertyCompositeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMaxMinRpt_PropertyCompositeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMaxMinRpt_PropertyCompositeQuery,
    GetMaxMinRpt_PropertyCompositeQueryVariables
  >
) {
  return Apollo.useQuery<
    GetMaxMinRpt_PropertyCompositeQuery,
    GetMaxMinRpt_PropertyCompositeQueryVariables
  >(GetMaxMinRpt_PropertyCompositeDocument, baseOptions);
}
export function useGetMaxMinRpt_PropertyCompositeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMaxMinRpt_PropertyCompositeQuery,
    GetMaxMinRpt_PropertyCompositeQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetMaxMinRpt_PropertyCompositeQuery,
    GetMaxMinRpt_PropertyCompositeQueryVariables
  >(GetMaxMinRpt_PropertyCompositeDocument, baseOptions);
}
export type GetMaxMinRpt_PropertyCompositeQueryHookResult = ReturnType<
  typeof useGetMaxMinRpt_PropertyCompositeQuery
>;
export type GetMaxMinRpt_PropertyCompositeLazyQueryHookResult = ReturnType<
  typeof useGetMaxMinRpt_PropertyCompositeLazyQuery
>;
export type GetMaxMinRpt_PropertyCompositeQueryResult = Apollo.QueryResult<
  GetMaxMinRpt_PropertyCompositeQuery,
  GetMaxMinRpt_PropertyCompositeQueryVariables
>;
export const GetPaginatedLandlordIncomeSummaryDocument = gql`
  query GetPaginatedLandlordIncomeSummary(
    $params: String!
    $aggregateParams: String!
  ) {
    rtcs_db_Fnura_LandlordIncomeSummary(args: { params: $params }) {
      ...LandlordIncomeSummary
    }
    rtcs_db_Fnura_LandlordIncomeSummary_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${LandlordIncomeSummaryFragmentDoc}
`;

/**
 * __useGetPaginatedLandlordIncomeSummaryQuery__
 *
 * To run a query within a React component, call `useGetPaginatedLandlordIncomeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedLandlordIncomeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedLandlordIncomeSummaryQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedLandlordIncomeSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedLandlordIncomeSummaryQuery,
    GetPaginatedLandlordIncomeSummaryQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedLandlordIncomeSummaryQuery,
    GetPaginatedLandlordIncomeSummaryQueryVariables
  >(GetPaginatedLandlordIncomeSummaryDocument, baseOptions);
}
export function useGetPaginatedLandlordIncomeSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedLandlordIncomeSummaryQuery,
    GetPaginatedLandlordIncomeSummaryQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedLandlordIncomeSummaryQuery,
    GetPaginatedLandlordIncomeSummaryQueryVariables
  >(GetPaginatedLandlordIncomeSummaryDocument, baseOptions);
}
export type GetPaginatedLandlordIncomeSummaryQueryHookResult = ReturnType<
  typeof useGetPaginatedLandlordIncomeSummaryQuery
>;
export type GetPaginatedLandlordIncomeSummaryLazyQueryHookResult = ReturnType<
  typeof useGetPaginatedLandlordIncomeSummaryLazyQuery
>;
export type GetPaginatedLandlordIncomeSummaryQueryResult = Apollo.QueryResult<
  GetPaginatedLandlordIncomeSummaryQuery,
  GetPaginatedLandlordIncomeSummaryQueryVariables
>;
export const GetPaginatedLandlordIncomeDetailDocument = gql`
  query GetPaginatedLandlordIncomeDetail(
    $params: String!
    $aggregateParams: String!
  ) {
    rtcs_db_Fnura_LandlordIncomeDetail(args: { params: $params }) {
      ...LandlordIncomeDetail
    }
    rtcs_db_Fnura_LandlordIncomeDetail_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${LandlordIncomeDetailFragmentDoc}
`;

/**
 * __useGetPaginatedLandlordIncomeDetailQuery__
 *
 * To run a query within a React component, call `useGetPaginatedLandlordIncomeDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedLandlordIncomeDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedLandlordIncomeDetailQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedLandlordIncomeDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedLandlordIncomeDetailQuery,
    GetPaginatedLandlordIncomeDetailQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedLandlordIncomeDetailQuery,
    GetPaginatedLandlordIncomeDetailQueryVariables
  >(GetPaginatedLandlordIncomeDetailDocument, baseOptions);
}
export function useGetPaginatedLandlordIncomeDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedLandlordIncomeDetailQuery,
    GetPaginatedLandlordIncomeDetailQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedLandlordIncomeDetailQuery,
    GetPaginatedLandlordIncomeDetailQueryVariables
  >(GetPaginatedLandlordIncomeDetailDocument, baseOptions);
}
export type GetPaginatedLandlordIncomeDetailQueryHookResult = ReturnType<
  typeof useGetPaginatedLandlordIncomeDetailQuery
>;
export type GetPaginatedLandlordIncomeDetailLazyQueryHookResult = ReturnType<
  typeof useGetPaginatedLandlordIncomeDetailLazyQuery
>;
export type GetPaginatedLandlordIncomeDetailQueryResult = Apollo.QueryResult<
  GetPaginatedLandlordIncomeDetailQuery,
  GetPaginatedLandlordIncomeDetailQueryVariables
>;
export const GetPaginatedTenantIncomeDetailDocument = gql`
  query GetPaginatedTenantIncomeDetail(
    $params: String!
    $aggregateParams: String!
  ) {
    rtcs_db_Fnura_TenantIncomeDetail(args: { params: $params }) {
      ...TenantIncomeDetail
    }
    rtcs_db_Fnura_TenantIncomeDetail_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${TenantIncomeDetailFragmentDoc}
`;

/**
 * __useGetPaginatedTenantIncomeDetailQuery__
 *
 * To run a query within a React component, call `useGetPaginatedTenantIncomeDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedTenantIncomeDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedTenantIncomeDetailQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedTenantIncomeDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedTenantIncomeDetailQuery,
    GetPaginatedTenantIncomeDetailQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedTenantIncomeDetailQuery,
    GetPaginatedTenantIncomeDetailQueryVariables
  >(GetPaginatedTenantIncomeDetailDocument, baseOptions);
}
export function useGetPaginatedTenantIncomeDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedTenantIncomeDetailQuery,
    GetPaginatedTenantIncomeDetailQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedTenantIncomeDetailQuery,
    GetPaginatedTenantIncomeDetailQueryVariables
  >(GetPaginatedTenantIncomeDetailDocument, baseOptions);
}
export type GetPaginatedTenantIncomeDetailQueryHookResult = ReturnType<
  typeof useGetPaginatedTenantIncomeDetailQuery
>;
export type GetPaginatedTenantIncomeDetailLazyQueryHookResult = ReturnType<
  typeof useGetPaginatedTenantIncomeDetailLazyQuery
>;
export type GetPaginatedTenantIncomeDetailQueryResult = Apollo.QueryResult<
  GetPaginatedTenantIncomeDetailQuery,
  GetPaginatedTenantIncomeDetailQueryVariables
>;
export const ExcessiveOwnerOccupancyFilterDocument = gql`
  query ExcessiveOwnerOccupancyFilter($params: String!) {
    rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy(
      args: { params: $params }
    ) {
      ...ExcessiveOwnerOccupancy
    }
  }
  ${ExcessiveOwnerOccupancyFragmentDoc}
`;

/**
 * __useExcessiveOwnerOccupancyFilterQuery__
 *
 * To run a query within a React component, call `useExcessiveOwnerOccupancyFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useExcessiveOwnerOccupancyFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExcessiveOwnerOccupancyFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useExcessiveOwnerOccupancyFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExcessiveOwnerOccupancyFilterQuery,
    ExcessiveOwnerOccupancyFilterQueryVariables
  >
) {
  return Apollo.useQuery<
    ExcessiveOwnerOccupancyFilterQuery,
    ExcessiveOwnerOccupancyFilterQueryVariables
  >(ExcessiveOwnerOccupancyFilterDocument, baseOptions);
}
export function useExcessiveOwnerOccupancyFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExcessiveOwnerOccupancyFilterQuery,
    ExcessiveOwnerOccupancyFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ExcessiveOwnerOccupancyFilterQuery,
    ExcessiveOwnerOccupancyFilterQueryVariables
  >(ExcessiveOwnerOccupancyFilterDocument, baseOptions);
}
export type ExcessiveOwnerOccupancyFilterQueryHookResult = ReturnType<
  typeof useExcessiveOwnerOccupancyFilterQuery
>;
export type ExcessiveOwnerOccupancyFilterLazyQueryHookResult = ReturnType<
  typeof useExcessiveOwnerOccupancyFilterLazyQuery
>;
export type ExcessiveOwnerOccupancyFilterQueryResult = Apollo.QueryResult<
  ExcessiveOwnerOccupancyFilterQuery,
  ExcessiveOwnerOccupancyFilterQueryVariables
>;
export const GetPaginatedExcessiveOwnerOccupancyReportsDocument = gql`
  query GetPaginatedExcessiveOwnerOccupancyReports(
    $params: String!
    $aggregateParams: String!
  ) {
    rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy(
      args: { params: $params }
    ) {
      ...ExcessiveOwnerOccupancy
    }
    rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${ExcessiveOwnerOccupancyFragmentDoc}
`;

/**
 * __useGetPaginatedExcessiveOwnerOccupancyReportsQuery__
 *
 * To run a query within a React component, call `useGetPaginatedExcessiveOwnerOccupancyReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedExcessiveOwnerOccupancyReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedExcessiveOwnerOccupancyReportsQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedExcessiveOwnerOccupancyReportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedExcessiveOwnerOccupancyReportsQuery,
    GetPaginatedExcessiveOwnerOccupancyReportsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedExcessiveOwnerOccupancyReportsQuery,
    GetPaginatedExcessiveOwnerOccupancyReportsQueryVariables
  >(GetPaginatedExcessiveOwnerOccupancyReportsDocument, baseOptions);
}
export function useGetPaginatedExcessiveOwnerOccupancyReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedExcessiveOwnerOccupancyReportsQuery,
    GetPaginatedExcessiveOwnerOccupancyReportsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedExcessiveOwnerOccupancyReportsQuery,
    GetPaginatedExcessiveOwnerOccupancyReportsQueryVariables
  >(GetPaginatedExcessiveOwnerOccupancyReportsDocument, baseOptions);
}
export type GetPaginatedExcessiveOwnerOccupancyReportsQueryHookResult =
  ReturnType<typeof useGetPaginatedExcessiveOwnerOccupancyReportsQuery>;
export type GetPaginatedExcessiveOwnerOccupancyReportsLazyQueryHookResult =
  ReturnType<typeof useGetPaginatedExcessiveOwnerOccupancyReportsLazyQuery>;
export type GetPaginatedExcessiveOwnerOccupancyReportsQueryResult =
  Apollo.QueryResult<
    GetPaginatedExcessiveOwnerOccupancyReportsQuery,
    GetPaginatedExcessiveOwnerOccupancyReportsQueryVariables
  >;
export const GetMaxMinExcessiveOwnerOccupancyReportsDocument = gql`
  query GetMaxMinExcessiveOwnerOccupancyReports {
    rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy_aggregatecm(
      args: {
        aggregate_params: "{\\"min\\": [\\"NumOwnedOccupied\\",\\"SumRateableValue\\", \\"MatchScore\\"],\\"max\\": [\\"NumOwnedOccupied\\",\\"SumRateableValue\\", \\"MatchScore\\"] }"
        params: "{\\"variables\\":{\\"where\\":{}}}"
      }
    ) {
      operation
      value
    }
  }
`;

/**
 * __useGetMaxMinExcessiveOwnerOccupancyReportsQuery__
 *
 * To run a query within a React component, call `useGetMaxMinExcessiveOwnerOccupancyReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaxMinExcessiveOwnerOccupancyReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaxMinExcessiveOwnerOccupancyReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMaxMinExcessiveOwnerOccupancyReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMaxMinExcessiveOwnerOccupancyReportsQuery,
    GetMaxMinExcessiveOwnerOccupancyReportsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetMaxMinExcessiveOwnerOccupancyReportsQuery,
    GetMaxMinExcessiveOwnerOccupancyReportsQueryVariables
  >(GetMaxMinExcessiveOwnerOccupancyReportsDocument, baseOptions);
}
export function useGetMaxMinExcessiveOwnerOccupancyReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMaxMinExcessiveOwnerOccupancyReportsQuery,
    GetMaxMinExcessiveOwnerOccupancyReportsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetMaxMinExcessiveOwnerOccupancyReportsQuery,
    GetMaxMinExcessiveOwnerOccupancyReportsQueryVariables
  >(GetMaxMinExcessiveOwnerOccupancyReportsDocument, baseOptions);
}
export type GetMaxMinExcessiveOwnerOccupancyReportsQueryHookResult = ReturnType<
  typeof useGetMaxMinExcessiveOwnerOccupancyReportsQuery
>;
export type GetMaxMinExcessiveOwnerOccupancyReportsLazyQueryHookResult =
  ReturnType<typeof useGetMaxMinExcessiveOwnerOccupancyReportsLazyQuery>;
export type GetMaxMinExcessiveOwnerOccupancyReportsQueryResult =
  Apollo.QueryResult<
    GetMaxMinExcessiveOwnerOccupancyReportsQuery,
    GetMaxMinExcessiveOwnerOccupancyReportsQueryVariables
  >;
export const IndividualReportsFilterDocument = gql`
  query IndividualReportsFilter($params: String!) {
    rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk(
      args: { params: $params }
    ) {
      ...IndividualCompliancePlusRiskReport
    }
  }
  ${IndividualCompliancePlusRiskReportFragmentDoc}
`;

/**
 * __useIndividualReportsFilterQuery__
 *
 * To run a query within a React component, call `useIndividualReportsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndividualReportsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndividualReportsFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useIndividualReportsFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndividualReportsFilterQuery,
    IndividualReportsFilterQueryVariables
  >
) {
  return Apollo.useQuery<
    IndividualReportsFilterQuery,
    IndividualReportsFilterQueryVariables
  >(IndividualReportsFilterDocument, baseOptions);
}
export function useIndividualReportsFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndividualReportsFilterQuery,
    IndividualReportsFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    IndividualReportsFilterQuery,
    IndividualReportsFilterQueryVariables
  >(IndividualReportsFilterDocument, baseOptions);
}
export type IndividualReportsFilterQueryHookResult = ReturnType<
  typeof useIndividualReportsFilterQuery
>;
export type IndividualReportsFilterLazyQueryHookResult = ReturnType<
  typeof useIndividualReportsFilterLazyQuery
>;
export type IndividualReportsFilterQueryResult = Apollo.QueryResult<
  IndividualReportsFilterQuery,
  IndividualReportsFilterQueryVariables
>;
export const GetPaginatedIndividualReportsDocument = gql`
  query GetPaginatedIndividualReports(
    $params: String!
    $aggregateParams: String!
  ) {
    rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk(
      args: { params: $params }
    ) {
      ...IndividualCompliancePlusRiskReport
    }
    rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${IndividualCompliancePlusRiskReportFragmentDoc}
`;

/**
 * __useGetPaginatedIndividualReportsQuery__
 *
 * To run a query within a React component, call `useGetPaginatedIndividualReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedIndividualReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedIndividualReportsQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedIndividualReportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedIndividualReportsQuery,
    GetPaginatedIndividualReportsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedIndividualReportsQuery,
    GetPaginatedIndividualReportsQueryVariables
  >(GetPaginatedIndividualReportsDocument, baseOptions);
}
export function useGetPaginatedIndividualReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedIndividualReportsQuery,
    GetPaginatedIndividualReportsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedIndividualReportsQuery,
    GetPaginatedIndividualReportsQueryVariables
  >(GetPaginatedIndividualReportsDocument, baseOptions);
}
export type GetPaginatedIndividualReportsQueryHookResult = ReturnType<
  typeof useGetPaginatedIndividualReportsQuery
>;
export type GetPaginatedIndividualReportsLazyQueryHookResult = ReturnType<
  typeof useGetPaginatedIndividualReportsLazyQuery
>;
export type GetPaginatedIndividualReportsQueryResult = Apollo.QueryResult<
  GetPaginatedIndividualReportsQuery,
  GetPaginatedIndividualReportsQueryVariables
>;
export const GetMaxMinIndividualReportsDocument = gql`
  query GetMaxMinIndividualReports($params: String!) {
    rtcs_db_FnRPT_IndividualComplianceSummaryPlusRisk_aggregatecm(
      args: {
        aggregate_params: "{\\"min\\": [\\"Numrentedproperties\\",\\"Sumadjustedincome\\"],\\"max\\": [\\"Numrentedproperties\\",\\"Sumadjustedincome\\"] }"
        params: $params
      }
    ) {
      operation
      value
    }
  }
`;

/**
 * __useGetMaxMinIndividualReportsQuery__
 *
 * To run a query within a React component, call `useGetMaxMinIndividualReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaxMinIndividualReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaxMinIndividualReportsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetMaxMinIndividualReportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMaxMinIndividualReportsQuery,
    GetMaxMinIndividualReportsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetMaxMinIndividualReportsQuery,
    GetMaxMinIndividualReportsQueryVariables
  >(GetMaxMinIndividualReportsDocument, baseOptions);
}
export function useGetMaxMinIndividualReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMaxMinIndividualReportsQuery,
    GetMaxMinIndividualReportsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetMaxMinIndividualReportsQuery,
    GetMaxMinIndividualReportsQueryVariables
  >(GetMaxMinIndividualReportsDocument, baseOptions);
}
export type GetMaxMinIndividualReportsQueryHookResult = ReturnType<
  typeof useGetMaxMinIndividualReportsQuery
>;
export type GetMaxMinIndividualReportsLazyQueryHookResult = ReturnType<
  typeof useGetMaxMinIndividualReportsLazyQuery
>;
export type GetMaxMinIndividualReportsQueryResult = Apollo.QueryResult<
  GetMaxMinIndividualReportsQuery,
  GetMaxMinIndividualReportsQueryVariables
>;
export const KccaCustomerRegistrationFilterDocument = gql`
  query KccaCustomerRegistrationFilter($params: String!) {
    rtcs_db_FnRPT_KccaCustomerRegistrationData(args: { params: $params }) {
      ...kccaCustomerRegistration
    }
  }
  ${KccaCustomerRegistrationFragmentDoc}
`;

/**
 * __useKccaCustomerRegistrationFilterQuery__
 *
 * To run a query within a React component, call `useKccaCustomerRegistrationFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerRegistrationFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerRegistrationFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useKccaCustomerRegistrationFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    KccaCustomerRegistrationFilterQuery,
    KccaCustomerRegistrationFilterQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaCustomerRegistrationFilterQuery,
    KccaCustomerRegistrationFilterQueryVariables
  >(KccaCustomerRegistrationFilterDocument, baseOptions);
}
export function useKccaCustomerRegistrationFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerRegistrationFilterQuery,
    KccaCustomerRegistrationFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerRegistrationFilterQuery,
    KccaCustomerRegistrationFilterQueryVariables
  >(KccaCustomerRegistrationFilterDocument, baseOptions);
}
export type KccaCustomerRegistrationFilterQueryHookResult = ReturnType<
  typeof useKccaCustomerRegistrationFilterQuery
>;
export type KccaCustomerRegistrationFilterLazyQueryHookResult = ReturnType<
  typeof useKccaCustomerRegistrationFilterLazyQuery
>;
export type KccaCustomerRegistrationFilterQueryResult = Apollo.QueryResult<
  KccaCustomerRegistrationFilterQuery,
  KccaCustomerRegistrationFilterQueryVariables
>;
export const PaginatedKccaCustomerRegistrationDocument = gql`
  query paginatedKccaCustomerRegistration(
    $params: String!
    $aggregateParams: String!
  ) {
    rtcs_db_FnRPT_KccaCustomerRegistrationData(args: { params: $params }) {
      ...kccaCustomerRegistration
    }
    rtcs_db_FnRPT_KccaCustomerRegistrationData_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${KccaCustomerRegistrationFragmentDoc}
`;

/**
 * __usePaginatedKccaCustomerRegistrationQuery__
 *
 * To run a query within a React component, call `usePaginatedKccaCustomerRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedKccaCustomerRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedKccaCustomerRegistrationQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function usePaginatedKccaCustomerRegistrationQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaginatedKccaCustomerRegistrationQuery,
    PaginatedKccaCustomerRegistrationQueryVariables
  >
) {
  return Apollo.useQuery<
    PaginatedKccaCustomerRegistrationQuery,
    PaginatedKccaCustomerRegistrationQueryVariables
  >(PaginatedKccaCustomerRegistrationDocument, baseOptions);
}
export function usePaginatedKccaCustomerRegistrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedKccaCustomerRegistrationQuery,
    PaginatedKccaCustomerRegistrationQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    PaginatedKccaCustomerRegistrationQuery,
    PaginatedKccaCustomerRegistrationQueryVariables
  >(PaginatedKccaCustomerRegistrationDocument, baseOptions);
}
export type PaginatedKccaCustomerRegistrationQueryHookResult = ReturnType<
  typeof usePaginatedKccaCustomerRegistrationQuery
>;
export type PaginatedKccaCustomerRegistrationLazyQueryHookResult = ReturnType<
  typeof usePaginatedKccaCustomerRegistrationLazyQuery
>;
export type PaginatedKccaCustomerRegistrationQueryResult = Apollo.QueryResult<
  PaginatedKccaCustomerRegistrationQuery,
  PaginatedKccaCustomerRegistrationQueryVariables
>;
export const KccaPropertyRegistrationFilterDocument = gql`
  query KccaPropertyRegistrationFilter($params: String!) {
    rtcs_db_FnRPT_KccaPropertyRegistration(args: { params: $params }) {
      ...kccaPropertyRegistration
    }
  }
  ${KccaPropertyRegistrationFragmentDoc}
`;

/**
 * __useKccaPropertyRegistrationFilterQuery__
 *
 * To run a query within a React component, call `useKccaPropertyRegistrationFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaPropertyRegistrationFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaPropertyRegistrationFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useKccaPropertyRegistrationFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    KccaPropertyRegistrationFilterQuery,
    KccaPropertyRegistrationFilterQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaPropertyRegistrationFilterQuery,
    KccaPropertyRegistrationFilterQueryVariables
  >(KccaPropertyRegistrationFilterDocument, baseOptions);
}
export function useKccaPropertyRegistrationFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaPropertyRegistrationFilterQuery,
    KccaPropertyRegistrationFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaPropertyRegistrationFilterQuery,
    KccaPropertyRegistrationFilterQueryVariables
  >(KccaPropertyRegistrationFilterDocument, baseOptions);
}
export type KccaPropertyRegistrationFilterQueryHookResult = ReturnType<
  typeof useKccaPropertyRegistrationFilterQuery
>;
export type KccaPropertyRegistrationFilterLazyQueryHookResult = ReturnType<
  typeof useKccaPropertyRegistrationFilterLazyQuery
>;
export type KccaPropertyRegistrationFilterQueryResult = Apollo.QueryResult<
  KccaPropertyRegistrationFilterQuery,
  KccaPropertyRegistrationFilterQueryVariables
>;
export const PaginatedKccaPropertyRegistrationDocument = gql`
  query paginatedKccaPropertyRegistration(
    $params: String!
    $aggregateParams: String!
  ) {
    rtcs_db_FnRPT_KccaPropertyRegistration(args: { params: $params }) {
      ...kccaPropertyRegistration
    }
    rtcs_db_FnRPT_KccaPropertyRegistration_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${KccaPropertyRegistrationFragmentDoc}
`;

/**
 * __usePaginatedKccaPropertyRegistrationQuery__
 *
 * To run a query within a React component, call `usePaginatedKccaPropertyRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedKccaPropertyRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedKccaPropertyRegistrationQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function usePaginatedKccaPropertyRegistrationQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaginatedKccaPropertyRegistrationQuery,
    PaginatedKccaPropertyRegistrationQueryVariables
  >
) {
  return Apollo.useQuery<
    PaginatedKccaPropertyRegistrationQuery,
    PaginatedKccaPropertyRegistrationQueryVariables
  >(PaginatedKccaPropertyRegistrationDocument, baseOptions);
}
export function usePaginatedKccaPropertyRegistrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedKccaPropertyRegistrationQuery,
    PaginatedKccaPropertyRegistrationQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    PaginatedKccaPropertyRegistrationQuery,
    PaginatedKccaPropertyRegistrationQueryVariables
  >(PaginatedKccaPropertyRegistrationDocument, baseOptions);
}
export type PaginatedKccaPropertyRegistrationQueryHookResult = ReturnType<
  typeof usePaginatedKccaPropertyRegistrationQuery
>;
export type PaginatedKccaPropertyRegistrationLazyQueryHookResult = ReturnType<
  typeof usePaginatedKccaPropertyRegistrationLazyQuery
>;
export type PaginatedKccaPropertyRegistrationQueryResult = Apollo.QueryResult<
  PaginatedKccaPropertyRegistrationQuery,
  PaginatedKccaPropertyRegistrationQueryVariables
>;
export const MlhudFilterDocument = gql`
  query mlhudFilter($params: String!) {
    rtcs_db_Fnrmatch_mlhudConsolidated(args: { params: $params }) {
      ...mlhud
    }
  }
  ${MlhudFragmentDoc}
`;

/**
 * __useMlhudFilterQuery__
 *
 * To run a query within a React component, call `useMlhudFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useMlhudFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMlhudFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useMlhudFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    MlhudFilterQuery,
    MlhudFilterQueryVariables
  >
) {
  return Apollo.useQuery<MlhudFilterQuery, MlhudFilterQueryVariables>(
    MlhudFilterDocument,
    baseOptions
  );
}
export function useMlhudFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MlhudFilterQuery,
    MlhudFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<MlhudFilterQuery, MlhudFilterQueryVariables>(
    MlhudFilterDocument,
    baseOptions
  );
}
export type MlhudFilterQueryHookResult = ReturnType<typeof useMlhudFilterQuery>;
export type MlhudFilterLazyQueryHookResult = ReturnType<
  typeof useMlhudFilterLazyQuery
>;
export type MlhudFilterQueryResult = Apollo.QueryResult<
  MlhudFilterQuery,
  MlhudFilterQueryVariables
>;
export const MlhudRegistrationDocument = gql`
  query mlhudRegistration($params: String!, $aggregateParams: String!) {
    rtcs_db_Fnrmatch_mlhudConsolidated(args: { params: $params }) {
      ...mlhud
    }
    rtcs_db_Fnrmatch_mlhudConsolidated_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${MlhudFragmentDoc}
`;

/**
 * __useMlhudRegistrationQuery__
 *
 * To run a query within a React component, call `useMlhudRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMlhudRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMlhudRegistrationQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useMlhudRegistrationQuery(
  baseOptions: Apollo.QueryHookOptions<
    MlhudRegistrationQuery,
    MlhudRegistrationQueryVariables
  >
) {
  return Apollo.useQuery<
    MlhudRegistrationQuery,
    MlhudRegistrationQueryVariables
  >(MlhudRegistrationDocument, baseOptions);
}
export function useMlhudRegistrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MlhudRegistrationQuery,
    MlhudRegistrationQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    MlhudRegistrationQuery,
    MlhudRegistrationQueryVariables
  >(MlhudRegistrationDocument, baseOptions);
}
export type MlhudRegistrationQueryHookResult = ReturnType<
  typeof useMlhudRegistrationQuery
>;
export type MlhudRegistrationLazyQueryHookResult = ReturnType<
  typeof useMlhudRegistrationLazyQuery
>;
export type MlhudRegistrationQueryResult = Apollo.QueryResult<
  MlhudRegistrationQuery,
  MlhudRegistrationQueryVariables
>;
export const NiraFilterDocument = gql`
  query niraFilter($params: String!) {
    rtcs_db_Fnnira_RawData(args: { params: $params }) {
      ...nira
    }
  }
  ${NiraFragmentDoc}
`;

/**
 * __useNiraFilterQuery__
 *
 * To run a query within a React component, call `useNiraFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useNiraFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNiraFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useNiraFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    NiraFilterQuery,
    NiraFilterQueryVariables
  >
) {
  return Apollo.useQuery<NiraFilterQuery, NiraFilterQueryVariables>(
    NiraFilterDocument,
    baseOptions
  );
}
export function useNiraFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NiraFilterQuery,
    NiraFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<NiraFilterQuery, NiraFilterQueryVariables>(
    NiraFilterDocument,
    baseOptions
  );
}
export type NiraFilterQueryHookResult = ReturnType<typeof useNiraFilterQuery>;
export type NiraFilterLazyQueryHookResult = ReturnType<
  typeof useNiraFilterLazyQuery
>;
export type NiraFilterQueryResult = Apollo.QueryResult<
  NiraFilterQuery,
  NiraFilterQueryVariables
>;
export const NiraRegistrationDocument = gql`
  query niraRegistration($params: String!, $aggregateParams: String!) {
    rtcs_db_Fnnira_RawData(args: { params: $params }) {
      ...nira
    }
    rtcs_db_Fnnira_RawData_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${NiraFragmentDoc}
`;

/**
 * __useNiraRegistrationQuery__
 *
 * To run a query within a React component, call `useNiraRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNiraRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNiraRegistrationQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useNiraRegistrationQuery(
  baseOptions: Apollo.QueryHookOptions<
    NiraRegistrationQuery,
    NiraRegistrationQueryVariables
  >
) {
  return Apollo.useQuery<NiraRegistrationQuery, NiraRegistrationQueryVariables>(
    NiraRegistrationDocument,
    baseOptions
  );
}
export function useNiraRegistrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NiraRegistrationQuery,
    NiraRegistrationQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    NiraRegistrationQuery,
    NiraRegistrationQueryVariables
  >(NiraRegistrationDocument, baseOptions);
}
export type NiraRegistrationQueryHookResult = ReturnType<
  typeof useNiraRegistrationQuery
>;
export type NiraRegistrationLazyQueryHookResult = ReturnType<
  typeof useNiraRegistrationLazyQuery
>;
export type NiraRegistrationQueryResult = Apollo.QueryResult<
  NiraRegistrationQuery,
  NiraRegistrationQueryVariables
>;
export const NonIndividualReportsFilterDocument = gql`
  query NonIndividualReportsFilter($params: String!) {
    rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk(
      args: { params: $params }
    ) {
      ...NonIndividualCompliancePlusRiskReport
    }
  }
  ${NonIndividualCompliancePlusRiskReportFragmentDoc}
`;

/**
 * __useNonIndividualReportsFilterQuery__
 *
 * To run a query within a React component, call `useNonIndividualReportsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonIndividualReportsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonIndividualReportsFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useNonIndividualReportsFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    NonIndividualReportsFilterQuery,
    NonIndividualReportsFilterQueryVariables
  >
) {
  return Apollo.useQuery<
    NonIndividualReportsFilterQuery,
    NonIndividualReportsFilterQueryVariables
  >(NonIndividualReportsFilterDocument, baseOptions);
}
export function useNonIndividualReportsFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NonIndividualReportsFilterQuery,
    NonIndividualReportsFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    NonIndividualReportsFilterQuery,
    NonIndividualReportsFilterQueryVariables
  >(NonIndividualReportsFilterDocument, baseOptions);
}
export type NonIndividualReportsFilterQueryHookResult = ReturnType<
  typeof useNonIndividualReportsFilterQuery
>;
export type NonIndividualReportsFilterLazyQueryHookResult = ReturnType<
  typeof useNonIndividualReportsFilterLazyQuery
>;
export type NonIndividualReportsFilterQueryResult = Apollo.QueryResult<
  NonIndividualReportsFilterQuery,
  NonIndividualReportsFilterQueryVariables
>;
export const GetPaginatedNonIndividualReportsDocument = gql`
  query GetPaginatedNonIndividualReports(
    $params: String!
    $aggregateParams: String!
  ) {
    rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk(
      args: { params: $params }
    ) {
      ...NonIndividualCompliancePlusRiskReport
    }
    rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${NonIndividualCompliancePlusRiskReportFragmentDoc}
`;

/**
 * __useGetPaginatedNonIndividualReportsQuery__
 *
 * To run a query within a React component, call `useGetPaginatedNonIndividualReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedNonIndividualReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedNonIndividualReportsQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedNonIndividualReportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedNonIndividualReportsQuery,
    GetPaginatedNonIndividualReportsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedNonIndividualReportsQuery,
    GetPaginatedNonIndividualReportsQueryVariables
  >(GetPaginatedNonIndividualReportsDocument, baseOptions);
}
export function useGetPaginatedNonIndividualReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedNonIndividualReportsQuery,
    GetPaginatedNonIndividualReportsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedNonIndividualReportsQuery,
    GetPaginatedNonIndividualReportsQueryVariables
  >(GetPaginatedNonIndividualReportsDocument, baseOptions);
}
export type GetPaginatedNonIndividualReportsQueryHookResult = ReturnType<
  typeof useGetPaginatedNonIndividualReportsQuery
>;
export type GetPaginatedNonIndividualReportsLazyQueryHookResult = ReturnType<
  typeof useGetPaginatedNonIndividualReportsLazyQuery
>;
export type GetPaginatedNonIndividualReportsQueryResult = Apollo.QueryResult<
  GetPaginatedNonIndividualReportsQuery,
  GetPaginatedNonIndividualReportsQueryVariables
>;
export const GetMaxMinNonIndividualReportsDocument = gql`
  query GetMaxMinNonIndividualReports($params: String!) {
    rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk_aggregatecm(
      args: {
        aggregate_params: "{\\"min\\": [\\"Numrentedproperties\\",\\"Sumofadjustedincome\\"],\\"max\\": [\\"Numrentedproperties\\",\\"Sumofadjustedincome\\"] }"
        params: $params
      }
    ) {
      operation
      value
    }
  }
`;

/**
 * __useGetMaxMinNonIndividualReportsQuery__
 *
 * To run a query within a React component, call `useGetMaxMinNonIndividualReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaxMinNonIndividualReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaxMinNonIndividualReportsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetMaxMinNonIndividualReportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMaxMinNonIndividualReportsQuery,
    GetMaxMinNonIndividualReportsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetMaxMinNonIndividualReportsQuery,
    GetMaxMinNonIndividualReportsQueryVariables
  >(GetMaxMinNonIndividualReportsDocument, baseOptions);
}
export function useGetMaxMinNonIndividualReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMaxMinNonIndividualReportsQuery,
    GetMaxMinNonIndividualReportsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetMaxMinNonIndividualReportsQuery,
    GetMaxMinNonIndividualReportsQueryVariables
  >(GetMaxMinNonIndividualReportsDocument, baseOptions);
}
export type GetMaxMinNonIndividualReportsQueryHookResult = ReturnType<
  typeof useGetMaxMinNonIndividualReportsQuery
>;
export type GetMaxMinNonIndividualReportsLazyQueryHookResult = ReturnType<
  typeof useGetMaxMinNonIndividualReportsLazyQuery
>;
export type GetMaxMinNonIndividualReportsQueryResult = Apollo.QueryResult<
  GetMaxMinNonIndividualReportsQuery,
  GetMaxMinNonIndividualReportsQueryVariables
>;
export const NwscCustomersFilterDocument = gql`
  query NwscCustomersFilter($params: String!) {
    rtcs_db_Fnnwsc_Customers(args: { params: $params }) {
      ...NwscCustomers
    }
  }
  ${NwscCustomersFragmentDoc}
`;

/**
 * __useNwscCustomersFilterQuery__
 *
 * To run a query within a React component, call `useNwscCustomersFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useNwscCustomersFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNwscCustomersFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useNwscCustomersFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    NwscCustomersFilterQuery,
    NwscCustomersFilterQueryVariables
  >
) {
  return Apollo.useQuery<
    NwscCustomersFilterQuery,
    NwscCustomersFilterQueryVariables
  >(NwscCustomersFilterDocument, baseOptions);
}
export function useNwscCustomersFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NwscCustomersFilterQuery,
    NwscCustomersFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    NwscCustomersFilterQuery,
    NwscCustomersFilterQueryVariables
  >(NwscCustomersFilterDocument, baseOptions);
}
export type NwscCustomersFilterQueryHookResult = ReturnType<
  typeof useNwscCustomersFilterQuery
>;
export type NwscCustomersFilterLazyQueryHookResult = ReturnType<
  typeof useNwscCustomersFilterLazyQuery
>;
export type NwscCustomersFilterQueryResult = Apollo.QueryResult<
  NwscCustomersFilterQuery,
  NwscCustomersFilterQueryVariables
>;
export const GetPaginatedCustomersDocument = gql`
  query GetPaginatedCustomers($params: String!, $aggregateParams: String!) {
    rtcs_db_Fnnwsc_Customers(args: { params: $params }) {
      ...NwscCustomers
    }
    rtcs_db_Fnnwsc_Customers_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${NwscCustomersFragmentDoc}
`;

/**
 * __useGetPaginatedCustomersQuery__
 *
 * To run a query within a React component, call `useGetPaginatedCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedCustomersQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedCustomersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedCustomersQuery,
    GetPaginatedCustomersQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedCustomersQuery,
    GetPaginatedCustomersQueryVariables
  >(GetPaginatedCustomersDocument, baseOptions);
}
export function useGetPaginatedCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedCustomersQuery,
    GetPaginatedCustomersQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedCustomersQuery,
    GetPaginatedCustomersQueryVariables
  >(GetPaginatedCustomersDocument, baseOptions);
}
export type GetPaginatedCustomersQueryHookResult = ReturnType<
  typeof useGetPaginatedCustomersQuery
>;
export type GetPaginatedCustomersLazyQueryHookResult = ReturnType<
  typeof useGetPaginatedCustomersLazyQuery
>;
export type GetPaginatedCustomersQueryResult = Apollo.QueryResult<
  GetPaginatedCustomersQuery,
  GetPaginatedCustomersQueryVariables
>;
export const OwnersAndOfficersFilterDocument = gql`
  query ownersAndOfficersFilter($params: String!) {
    rtcs_db_Fnura_CombinedOfficersAndOwners(args: { params: $params }) {
      ...ownersAndOfficers
    }
  }
  ${OwnersAndOfficersFragmentDoc}
`;

/**
 * __useOwnersAndOfficersFilterQuery__
 *
 * To run a query within a React component, call `useOwnersAndOfficersFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnersAndOfficersFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnersAndOfficersFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useOwnersAndOfficersFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    OwnersAndOfficersFilterQuery,
    OwnersAndOfficersFilterQueryVariables
  >
) {
  return Apollo.useQuery<
    OwnersAndOfficersFilterQuery,
    OwnersAndOfficersFilterQueryVariables
  >(OwnersAndOfficersFilterDocument, baseOptions);
}
export function useOwnersAndOfficersFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OwnersAndOfficersFilterQuery,
    OwnersAndOfficersFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    OwnersAndOfficersFilterQuery,
    OwnersAndOfficersFilterQueryVariables
  >(OwnersAndOfficersFilterDocument, baseOptions);
}
export type OwnersAndOfficersFilterQueryHookResult = ReturnType<
  typeof useOwnersAndOfficersFilterQuery
>;
export type OwnersAndOfficersFilterLazyQueryHookResult = ReturnType<
  typeof useOwnersAndOfficersFilterLazyQuery
>;
export type OwnersAndOfficersFilterQueryResult = Apollo.QueryResult<
  OwnersAndOfficersFilterQuery,
  OwnersAndOfficersFilterQueryVariables
>;
export const OwnersAndOfficersRegistrationDocument = gql`
  query ownersAndOfficersRegistration(
    $params: String!
    $aggregateParams: String!
  ) {
    rtcs_db_Fnura_CombinedOfficersAndOwners(args: { params: $params }) {
      ...ownersAndOfficers
    }
    rtcs_db_Fnura_CombinedOfficersAndOwners_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${OwnersAndOfficersFragmentDoc}
`;

/**
 * __useOwnersAndOfficersRegistrationQuery__
 *
 * To run a query within a React component, call `useOwnersAndOfficersRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnersAndOfficersRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnersAndOfficersRegistrationQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useOwnersAndOfficersRegistrationQuery(
  baseOptions: Apollo.QueryHookOptions<
    OwnersAndOfficersRegistrationQuery,
    OwnersAndOfficersRegistrationQueryVariables
  >
) {
  return Apollo.useQuery<
    OwnersAndOfficersRegistrationQuery,
    OwnersAndOfficersRegistrationQueryVariables
  >(OwnersAndOfficersRegistrationDocument, baseOptions);
}
export function useOwnersAndOfficersRegistrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OwnersAndOfficersRegistrationQuery,
    OwnersAndOfficersRegistrationQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    OwnersAndOfficersRegistrationQuery,
    OwnersAndOfficersRegistrationQueryVariables
  >(OwnersAndOfficersRegistrationDocument, baseOptions);
}
export type OwnersAndOfficersRegistrationQueryHookResult = ReturnType<
  typeof useOwnersAndOfficersRegistrationQuery
>;
export type OwnersAndOfficersRegistrationLazyQueryHookResult = ReturnType<
  typeof useOwnersAndOfficersRegistrationLazyQuery
>;
export type OwnersAndOfficersRegistrationQueryResult = Apollo.QueryResult<
  OwnersAndOfficersRegistrationQuery,
  OwnersAndOfficersRegistrationQueryVariables
>;
export const RCapturePropertiesFilterDocument = gql`
  query rCapturePropertiesFilter($params: String!) {
    rtcs_db_Fnrcapture_RPTproperties(args: { params: $params }) {
      ...rCaptureProperties
    }
  }
  ${RCapturePropertiesFragmentDoc}
`;

/**
 * __useRCapturePropertiesFilterQuery__
 *
 * To run a query within a React component, call `useRCapturePropertiesFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useRCapturePropertiesFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRCapturePropertiesFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRCapturePropertiesFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    RCapturePropertiesFilterQuery,
    RCapturePropertiesFilterQueryVariables
  >
) {
  return Apollo.useQuery<
    RCapturePropertiesFilterQuery,
    RCapturePropertiesFilterQueryVariables
  >(RCapturePropertiesFilterDocument, baseOptions);
}
export function useRCapturePropertiesFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RCapturePropertiesFilterQuery,
    RCapturePropertiesFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    RCapturePropertiesFilterQuery,
    RCapturePropertiesFilterQueryVariables
  >(RCapturePropertiesFilterDocument, baseOptions);
}
export type RCapturePropertiesFilterQueryHookResult = ReturnType<
  typeof useRCapturePropertiesFilterQuery
>;
export type RCapturePropertiesFilterLazyQueryHookResult = ReturnType<
  typeof useRCapturePropertiesFilterLazyQuery
>;
export type RCapturePropertiesFilterQueryResult = Apollo.QueryResult<
  RCapturePropertiesFilterQuery,
  RCapturePropertiesFilterQueryVariables
>;
export const RCapturePropertiesDocument = gql`
  query rCaptureProperties($params: String!, $aggregateParams: String!) {
    rtcs_db_Fnrcapture_RPTproperties(args: { params: $params }) {
      ...rCaptureProperties
    }
    rtcs_db_Fnrcapture_RPTproperties_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${RCapturePropertiesFragmentDoc}
`;

/**
 * __useRCapturePropertiesQuery__
 *
 * To run a query within a React component, call `useRCapturePropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRCapturePropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRCapturePropertiesQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useRCapturePropertiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    RCapturePropertiesQuery,
    RCapturePropertiesQueryVariables
  >
) {
  return Apollo.useQuery<
    RCapturePropertiesQuery,
    RCapturePropertiesQueryVariables
  >(RCapturePropertiesDocument, baseOptions);
}
export function useRCapturePropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RCapturePropertiesQuery,
    RCapturePropertiesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    RCapturePropertiesQuery,
    RCapturePropertiesQueryVariables
  >(RCapturePropertiesDocument, baseOptions);
}
export type RCapturePropertiesQueryHookResult = ReturnType<
  typeof useRCapturePropertiesQuery
>;
export type RCapturePropertiesLazyQueryHookResult = ReturnType<
  typeof useRCapturePropertiesLazyQuery
>;
export type RCapturePropertiesQueryResult = Apollo.QueryResult<
  RCapturePropertiesQuery,
  RCapturePropertiesQueryVariables
>;
export const RCapturePropertyDetailsDocument = gql`
  query rCapturePropertyDetails($params: String!) {
    rtcs_db_Fnrcapture_RPTproperties(args: { params: $params }) {
      ...rCaptureProperties
    }
  }
  ${RCapturePropertiesFragmentDoc}
`;

/**
 * __useRCapturePropertyDetailsQuery__
 *
 * To run a query within a React component, call `useRCapturePropertyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRCapturePropertyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRCapturePropertyDetailsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRCapturePropertyDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RCapturePropertyDetailsQuery,
    RCapturePropertyDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    RCapturePropertyDetailsQuery,
    RCapturePropertyDetailsQueryVariables
  >(RCapturePropertyDetailsDocument, baseOptions);
}
export function useRCapturePropertyDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RCapturePropertyDetailsQuery,
    RCapturePropertyDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    RCapturePropertyDetailsQuery,
    RCapturePropertyDetailsQueryVariables
  >(RCapturePropertyDetailsDocument, baseOptions);
}
export type RCapturePropertyDetailsQueryHookResult = ReturnType<
  typeof useRCapturePropertyDetailsQuery
>;
export type RCapturePropertyDetailsLazyQueryHookResult = ReturnType<
  typeof useRCapturePropertyDetailsLazyQuery
>;
export type RCapturePropertyDetailsQueryResult = Apollo.QueryResult<
  RCapturePropertyDetailsQuery,
  RCapturePropertyDetailsQueryVariables
>;
export const RCapturePropertyLessorsDocument = gql`
  query RCapturePropertyLessors($params: String!) {
    rtcs_db_Fnrcapture_RPTlessors(args: { params: $params }) {
      ...rCapturePropertyLessors
    }
  }
  ${RCapturePropertyLessorsFragmentDoc}
`;

/**
 * __useRCapturePropertyLessorsQuery__
 *
 * To run a query within a React component, call `useRCapturePropertyLessorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRCapturePropertyLessorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRCapturePropertyLessorsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRCapturePropertyLessorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RCapturePropertyLessorsQuery,
    RCapturePropertyLessorsQueryVariables
  >
) {
  return Apollo.useQuery<
    RCapturePropertyLessorsQuery,
    RCapturePropertyLessorsQueryVariables
  >(RCapturePropertyLessorsDocument, baseOptions);
}
export function useRCapturePropertyLessorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RCapturePropertyLessorsQuery,
    RCapturePropertyLessorsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    RCapturePropertyLessorsQuery,
    RCapturePropertyLessorsQueryVariables
  >(RCapturePropertyLessorsDocument, baseOptions);
}
export type RCapturePropertyLessorsQueryHookResult = ReturnType<
  typeof useRCapturePropertyLessorsQuery
>;
export type RCapturePropertyLessorsLazyQueryHookResult = ReturnType<
  typeof useRCapturePropertyLessorsLazyQuery
>;
export type RCapturePropertyLessorsQueryResult = Apollo.QueryResult<
  RCapturePropertyLessorsQuery,
  RCapturePropertyLessorsQueryVariables
>;
export const RCapturePropertyTenantsDocument = gql`
  query rCapturePropertyTenants($params: String!) {
    rtcs_db_Fnrcapture_RPTtenants(args: { params: $params }) {
      ...rCapturePropertyTenant
    }
  }
  ${RCapturePropertyTenantFragmentDoc}
`;

/**
 * __useRCapturePropertyTenantsQuery__
 *
 * To run a query within a React component, call `useRCapturePropertyTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRCapturePropertyTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRCapturePropertyTenantsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRCapturePropertyTenantsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RCapturePropertyTenantsQuery,
    RCapturePropertyTenantsQueryVariables
  >
) {
  return Apollo.useQuery<
    RCapturePropertyTenantsQuery,
    RCapturePropertyTenantsQueryVariables
  >(RCapturePropertyTenantsDocument, baseOptions);
}
export function useRCapturePropertyTenantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RCapturePropertyTenantsQuery,
    RCapturePropertyTenantsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    RCapturePropertyTenantsQuery,
    RCapturePropertyTenantsQueryVariables
  >(RCapturePropertyTenantsDocument, baseOptions);
}
export type RCapturePropertyTenantsQueryHookResult = ReturnType<
  typeof useRCapturePropertyTenantsQuery
>;
export type RCapturePropertyTenantsLazyQueryHookResult = ReturnType<
  typeof useRCapturePropertyTenantsLazyQuery
>;
export type RCapturePropertyTenantsQueryResult = Apollo.QueryResult<
  RCapturePropertyTenantsQuery,
  RCapturePropertyTenantsQueryVariables
>;
export const RCapturePropertyUnitTypesDocument = gql`
  query rCapturePropertyUnitTypes($params: String!) {
    rtcs_db_Fnrcapture_RPTUnittypes(args: { params: $params }) {
      ...rCapturePropertyUnitType
    }
  }
  ${RCapturePropertyUnitTypeFragmentDoc}
`;

/**
 * __useRCapturePropertyUnitTypesQuery__
 *
 * To run a query within a React component, call `useRCapturePropertyUnitTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRCapturePropertyUnitTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRCapturePropertyUnitTypesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRCapturePropertyUnitTypesQuery(
  baseOptions: Apollo.QueryHookOptions<
    RCapturePropertyUnitTypesQuery,
    RCapturePropertyUnitTypesQueryVariables
  >
) {
  return Apollo.useQuery<
    RCapturePropertyUnitTypesQuery,
    RCapturePropertyUnitTypesQueryVariables
  >(RCapturePropertyUnitTypesDocument, baseOptions);
}
export function useRCapturePropertyUnitTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RCapturePropertyUnitTypesQuery,
    RCapturePropertyUnitTypesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    RCapturePropertyUnitTypesQuery,
    RCapturePropertyUnitTypesQueryVariables
  >(RCapturePropertyUnitTypesDocument, baseOptions);
}
export type RCapturePropertyUnitTypesQueryHookResult = ReturnType<
  typeof useRCapturePropertyUnitTypesQuery
>;
export type RCapturePropertyUnitTypesLazyQueryHookResult = ReturnType<
  typeof useRCapturePropertyUnitTypesLazyQuery
>;
export type RCapturePropertyUnitTypesQueryResult = Apollo.QueryResult<
  RCapturePropertyUnitTypesQuery,
  RCapturePropertyUnitTypesQueryVariables
>;
export const UccReportFilterDocument = gql`
  query uccReportFilter($params: String!) {
    rtcs_db_Fnucc_UCC(args: { params: $params }) {
      ...uccReport
    }
  }
  ${UccReportFragmentDoc}
`;

/**
 * __useUccReportFilterQuery__
 *
 * To run a query within a React component, call `useUccReportFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useUccReportFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUccReportFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUccReportFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    UccReportFilterQuery,
    UccReportFilterQueryVariables
  >
) {
  return Apollo.useQuery<UccReportFilterQuery, UccReportFilterQueryVariables>(
    UccReportFilterDocument,
    baseOptions
  );
}
export function useUccReportFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UccReportFilterQuery,
    UccReportFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UccReportFilterQuery,
    UccReportFilterQueryVariables
  >(UccReportFilterDocument, baseOptions);
}
export type UccReportFilterQueryHookResult = ReturnType<
  typeof useUccReportFilterQuery
>;
export type UccReportFilterLazyQueryHookResult = ReturnType<
  typeof useUccReportFilterLazyQuery
>;
export type UccReportFilterQueryResult = Apollo.QueryResult<
  UccReportFilterQuery,
  UccReportFilterQueryVariables
>;
export const GetPaginateduccReportDocument = gql`
  query GetPaginateduccReport($params: String!, $aggregateParams: String!) {
    rtcs_db_Fnucc_UCC(args: { params: $params }) {
      ...uccReport
    }
    rtcs_db_Fnucc_UCC_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${UccReportFragmentDoc}
`;

/**
 * __useGetPaginateduccReportQuery__
 *
 * To run a query within a React component, call `useGetPaginateduccReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginateduccReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginateduccReportQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginateduccReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginateduccReportQuery,
    GetPaginateduccReportQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginateduccReportQuery,
    GetPaginateduccReportQueryVariables
  >(GetPaginateduccReportDocument, baseOptions);
}
export function useGetPaginateduccReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginateduccReportQuery,
    GetPaginateduccReportQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginateduccReportQuery,
    GetPaginateduccReportQueryVariables
  >(GetPaginateduccReportDocument, baseOptions);
}
export type GetPaginateduccReportQueryHookResult = ReturnType<
  typeof useGetPaginateduccReportQuery
>;
export type GetPaginateduccReportLazyQueryHookResult = ReturnType<
  typeof useGetPaginateduccReportLazyQuery
>;
export type GetPaginateduccReportQueryResult = Apollo.QueryResult<
  GetPaginateduccReportQuery,
  GetPaginateduccReportQueryVariables
>;
export const SubscriberAndPropertyFilterDocument = gql`
  query SubscriberAndPropertyFilter($params: String!) {
    rtcs_db_Fnumeme_SubscriberAndProperty(args: { params: $params }) {
      ...SubscriberAndProperty
    }
  }
  ${SubscriberAndPropertyFragmentDoc}
`;

/**
 * __useSubscriberAndPropertyFilterQuery__
 *
 * To run a query within a React component, call `useSubscriberAndPropertyFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriberAndPropertyFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriberAndPropertyFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useSubscriberAndPropertyFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    SubscriberAndPropertyFilterQuery,
    SubscriberAndPropertyFilterQueryVariables
  >
) {
  return Apollo.useQuery<
    SubscriberAndPropertyFilterQuery,
    SubscriberAndPropertyFilterQueryVariables
  >(SubscriberAndPropertyFilterDocument, baseOptions);
}
export function useSubscriberAndPropertyFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubscriberAndPropertyFilterQuery,
    SubscriberAndPropertyFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    SubscriberAndPropertyFilterQuery,
    SubscriberAndPropertyFilterQueryVariables
  >(SubscriberAndPropertyFilterDocument, baseOptions);
}
export type SubscriberAndPropertyFilterQueryHookResult = ReturnType<
  typeof useSubscriberAndPropertyFilterQuery
>;
export type SubscriberAndPropertyFilterLazyQueryHookResult = ReturnType<
  typeof useSubscriberAndPropertyFilterLazyQuery
>;
export type SubscriberAndPropertyFilterQueryResult = Apollo.QueryResult<
  SubscriberAndPropertyFilterQuery,
  SubscriberAndPropertyFilterQueryVariables
>;
export const GetPaginatedSubscriberAndPropertyDocument = gql`
  query GetPaginatedSubscriberAndProperty(
    $params: String!
    $aggregateParams: String!
  ) {
    rtcs_db_Fnumeme_SubscriberAndProperty(args: { params: $params }) {
      ...SubscriberAndProperty
    }
    rtcs_db_Fnumeme_SubscriberAndProperty_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${SubscriberAndPropertyFragmentDoc}
`;

/**
 * __useGetPaginatedSubscriberAndPropertyQuery__
 *
 * To run a query within a React component, call `useGetPaginatedSubscriberAndPropertyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedSubscriberAndPropertyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedSubscriberAndPropertyQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedSubscriberAndPropertyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedSubscriberAndPropertyQuery,
    GetPaginatedSubscriberAndPropertyQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedSubscriberAndPropertyQuery,
    GetPaginatedSubscriberAndPropertyQueryVariables
  >(GetPaginatedSubscriberAndPropertyDocument, baseOptions);
}
export function useGetPaginatedSubscriberAndPropertyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedSubscriberAndPropertyQuery,
    GetPaginatedSubscriberAndPropertyQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedSubscriberAndPropertyQuery,
    GetPaginatedSubscriberAndPropertyQueryVariables
  >(GetPaginatedSubscriberAndPropertyDocument, baseOptions);
}
export type GetPaginatedSubscriberAndPropertyQueryHookResult = ReturnType<
  typeof useGetPaginatedSubscriberAndPropertyQuery
>;
export type GetPaginatedSubscriberAndPropertyLazyQueryHookResult = ReturnType<
  typeof useGetPaginatedSubscriberAndPropertyLazyQuery
>;
export type GetPaginatedSubscriberAndPropertyQueryResult = Apollo.QueryResult<
  GetPaginatedSubscriberAndPropertyQuery,
  GetPaginatedSubscriberAndPropertyQueryVariables
>;
export const UraIndividualFilterDocument = gql`
  query UraIndividualFilter($params: String!) {
    rtcs_db_FnRPT_IndividualRegistration(args: { params: $params }) {
      ...UraIndividual
    }
  }
  ${UraIndividualFragmentDoc}
`;

/**
 * __useUraIndividualFilterQuery__
 *
 * To run a query within a React component, call `useUraIndividualFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useUraIndividualFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUraIndividualFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUraIndividualFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    UraIndividualFilterQuery,
    UraIndividualFilterQueryVariables
  >
) {
  return Apollo.useQuery<
    UraIndividualFilterQuery,
    UraIndividualFilterQueryVariables
  >(UraIndividualFilterDocument, baseOptions);
}
export function useUraIndividualFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UraIndividualFilterQuery,
    UraIndividualFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UraIndividualFilterQuery,
    UraIndividualFilterQueryVariables
  >(UraIndividualFilterDocument, baseOptions);
}
export type UraIndividualFilterQueryHookResult = ReturnType<
  typeof useUraIndividualFilterQuery
>;
export type UraIndividualFilterLazyQueryHookResult = ReturnType<
  typeof useUraIndividualFilterLazyQuery
>;
export type UraIndividualFilterQueryResult = Apollo.QueryResult<
  UraIndividualFilterQuery,
  UraIndividualFilterQueryVariables
>;
export const GetPaginatedRegIndividualDocument = gql`
  query GetPaginatedRegIndividual($params: String!, $aggregateParams: String!) {
    rtcs_db_FnRPT_IndividualRegistration(args: { params: $params }) {
      ...UraIndividual
    }
    rtcs_db_FnRPT_IndividualRegistration_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${UraIndividualFragmentDoc}
`;

/**
 * __useGetPaginatedRegIndividualQuery__
 *
 * To run a query within a React component, call `useGetPaginatedRegIndividualQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedRegIndividualQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedRegIndividualQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedRegIndividualQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedRegIndividualQuery,
    GetPaginatedRegIndividualQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedRegIndividualQuery,
    GetPaginatedRegIndividualQueryVariables
  >(GetPaginatedRegIndividualDocument, baseOptions);
}
export function useGetPaginatedRegIndividualLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedRegIndividualQuery,
    GetPaginatedRegIndividualQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedRegIndividualQuery,
    GetPaginatedRegIndividualQueryVariables
  >(GetPaginatedRegIndividualDocument, baseOptions);
}
export type GetPaginatedRegIndividualQueryHookResult = ReturnType<
  typeof useGetPaginatedRegIndividualQuery
>;
export type GetPaginatedRegIndividualLazyQueryHookResult = ReturnType<
  typeof useGetPaginatedRegIndividualLazyQuery
>;
export type GetPaginatedRegIndividualQueryResult = Apollo.QueryResult<
  GetPaginatedRegIndividualQuery,
  GetPaginatedRegIndividualQueryVariables
>;
export const UraNonIndividualReportFilterDocument = gql`
  query UraNonIndividualReportFilter($params: String!) {
    rtcs_db_Fnura_RegNonIndividual(args: { params: $params }) {
      ...UraNonIndividualReport
    }
  }
  ${UraNonIndividualReportFragmentDoc}
`;

/**
 * __useUraNonIndividualReportFilterQuery__
 *
 * To run a query within a React component, call `useUraNonIndividualReportFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useUraNonIndividualReportFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUraNonIndividualReportFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUraNonIndividualReportFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    UraNonIndividualReportFilterQuery,
    UraNonIndividualReportFilterQueryVariables
  >
) {
  return Apollo.useQuery<
    UraNonIndividualReportFilterQuery,
    UraNonIndividualReportFilterQueryVariables
  >(UraNonIndividualReportFilterDocument, baseOptions);
}
export function useUraNonIndividualReportFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UraNonIndividualReportFilterQuery,
    UraNonIndividualReportFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UraNonIndividualReportFilterQuery,
    UraNonIndividualReportFilterQueryVariables
  >(UraNonIndividualReportFilterDocument, baseOptions);
}
export type UraNonIndividualReportFilterQueryHookResult = ReturnType<
  typeof useUraNonIndividualReportFilterQuery
>;
export type UraNonIndividualReportFilterLazyQueryHookResult = ReturnType<
  typeof useUraNonIndividualReportFilterLazyQuery
>;
export type UraNonIndividualReportFilterQueryResult = Apollo.QueryResult<
  UraNonIndividualReportFilterQuery,
  UraNonIndividualReportFilterQueryVariables
>;
export const GetPaginatedRegNonIndividualsReportDocument = gql`
  query GetPaginatedRegNonIndividualsReport(
    $params: String!
    $aggregateParams: String!
  ) {
    rtcs_db_Fnura_RegNonIndividual(args: { params: $params }) {
      ...UraNonIndividualReport
    }
    rtcs_db_Fnura_RegNonIndividual_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${UraNonIndividualReportFragmentDoc}
`;

/**
 * __useGetPaginatedRegNonIndividualsReportQuery__
 *
 * To run a query within a React component, call `useGetPaginatedRegNonIndividualsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedRegNonIndividualsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedRegNonIndividualsReportQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedRegNonIndividualsReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedRegNonIndividualsReportQuery,
    GetPaginatedRegNonIndividualsReportQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedRegNonIndividualsReportQuery,
    GetPaginatedRegNonIndividualsReportQueryVariables
  >(GetPaginatedRegNonIndividualsReportDocument, baseOptions);
}
export function useGetPaginatedRegNonIndividualsReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedRegNonIndividualsReportQuery,
    GetPaginatedRegNonIndividualsReportQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedRegNonIndividualsReportQuery,
    GetPaginatedRegNonIndividualsReportQueryVariables
  >(GetPaginatedRegNonIndividualsReportDocument, baseOptions);
}
export type GetPaginatedRegNonIndividualsReportQueryHookResult = ReturnType<
  typeof useGetPaginatedRegNonIndividualsReportQuery
>;
export type GetPaginatedRegNonIndividualsReportLazyQueryHookResult = ReturnType<
  typeof useGetPaginatedRegNonIndividualsReportLazyQuery
>;
export type GetPaginatedRegNonIndividualsReportQueryResult = Apollo.QueryResult<
  GetPaginatedRegNonIndividualsReportQuery,
  GetPaginatedRegNonIndividualsReportQueryVariables
>;
export const UraIndividualTaxFilterDocument = gql`
  query UraIndividualTaxFilter($params: String!) {
    rtcs_db_FnRPT_IndividualTaxSummaryData(args: { params: $params }) {
      ...UraIndividualTax
    }
  }
  ${UraIndividualTaxFragmentDoc}
`;

/**
 * __useUraIndividualTaxFilterQuery__
 *
 * To run a query within a React component, call `useUraIndividualTaxFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useUraIndividualTaxFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUraIndividualTaxFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUraIndividualTaxFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    UraIndividualTaxFilterQuery,
    UraIndividualTaxFilterQueryVariables
  >
) {
  return Apollo.useQuery<
    UraIndividualTaxFilterQuery,
    UraIndividualTaxFilterQueryVariables
  >(UraIndividualTaxFilterDocument, baseOptions);
}
export function useUraIndividualTaxFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UraIndividualTaxFilterQuery,
    UraIndividualTaxFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UraIndividualTaxFilterQuery,
    UraIndividualTaxFilterQueryVariables
  >(UraIndividualTaxFilterDocument, baseOptions);
}
export type UraIndividualTaxFilterQueryHookResult = ReturnType<
  typeof useUraIndividualTaxFilterQuery
>;
export type UraIndividualTaxFilterLazyQueryHookResult = ReturnType<
  typeof useUraIndividualTaxFilterLazyQuery
>;
export type UraIndividualTaxFilterQueryResult = Apollo.QueryResult<
  UraIndividualTaxFilterQuery,
  UraIndividualTaxFilterQueryVariables
>;
export const GetPaginatedRegIndividualsTaxDocument = gql`
  query GetPaginatedRegIndividualsTax(
    $params: String!
    $aggregateParams: String!
  ) {
    rtcs_db_FnRPT_IndividualTaxSummaryData(args: { params: $params }) {
      ...UraIndividualTax
    }
    rtcs_db_FnRPT_IndividualTaxSummaryData_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${UraIndividualTaxFragmentDoc}
`;

/**
 * __useGetPaginatedRegIndividualsTaxQuery__
 *
 * To run a query within a React component, call `useGetPaginatedRegIndividualsTaxQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedRegIndividualsTaxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedRegIndividualsTaxQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedRegIndividualsTaxQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedRegIndividualsTaxQuery,
    GetPaginatedRegIndividualsTaxQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedRegIndividualsTaxQuery,
    GetPaginatedRegIndividualsTaxQueryVariables
  >(GetPaginatedRegIndividualsTaxDocument, baseOptions);
}
export function useGetPaginatedRegIndividualsTaxLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedRegIndividualsTaxQuery,
    GetPaginatedRegIndividualsTaxQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedRegIndividualsTaxQuery,
    GetPaginatedRegIndividualsTaxQueryVariables
  >(GetPaginatedRegIndividualsTaxDocument, baseOptions);
}
export type GetPaginatedRegIndividualsTaxQueryHookResult = ReturnType<
  typeof useGetPaginatedRegIndividualsTaxQuery
>;
export type GetPaginatedRegIndividualsTaxLazyQueryHookResult = ReturnType<
  typeof useGetPaginatedRegIndividualsTaxLazyQuery
>;
export type GetPaginatedRegIndividualsTaxQueryResult = Apollo.QueryResult<
  GetPaginatedRegIndividualsTaxQuery,
  GetPaginatedRegIndividualsTaxQueryVariables
>;
export const UraNonIndividualTaxReportFilterDocument = gql`
  query UraNonIndividualTaxReportFilter($params: String!) {
    rtcs_db_FnRPT_NonIndividualTaxSummaryData(args: { params: $params }) {
      ...UraNonIndividualTaxReport
    }
  }
  ${UraNonIndividualTaxReportFragmentDoc}
`;

/**
 * __useUraNonIndividualTaxReportFilterQuery__
 *
 * To run a query within a React component, call `useUraNonIndividualTaxReportFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useUraNonIndividualTaxReportFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUraNonIndividualTaxReportFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUraNonIndividualTaxReportFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    UraNonIndividualTaxReportFilterQuery,
    UraNonIndividualTaxReportFilterQueryVariables
  >
) {
  return Apollo.useQuery<
    UraNonIndividualTaxReportFilterQuery,
    UraNonIndividualTaxReportFilterQueryVariables
  >(UraNonIndividualTaxReportFilterDocument, baseOptions);
}
export function useUraNonIndividualTaxReportFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UraNonIndividualTaxReportFilterQuery,
    UraNonIndividualTaxReportFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UraNonIndividualTaxReportFilterQuery,
    UraNonIndividualTaxReportFilterQueryVariables
  >(UraNonIndividualTaxReportFilterDocument, baseOptions);
}
export type UraNonIndividualTaxReportFilterQueryHookResult = ReturnType<
  typeof useUraNonIndividualTaxReportFilterQuery
>;
export type UraNonIndividualTaxReportFilterLazyQueryHookResult = ReturnType<
  typeof useUraNonIndividualTaxReportFilterLazyQuery
>;
export type UraNonIndividualTaxReportFilterQueryResult = Apollo.QueryResult<
  UraNonIndividualTaxReportFilterQuery,
  UraNonIndividualTaxReportFilterQueryVariables
>;
export const GetPaginatedNonIndividualsTaxReportDocument = gql`
  query GetPaginatedNonIndividualsTaxReport(
    $params: String!
    $aggregateParams: String!
  ) {
    rtcs_db_FnRPT_NonIndividualTaxSummaryData(args: { params: $params }) {
      ...UraNonIndividualTaxReport
    }
    rtcs_db_FnRPT_NonIndividualTaxSummaryData_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${UraNonIndividualTaxReportFragmentDoc}
`;

/**
 * __useGetPaginatedNonIndividualsTaxReportQuery__
 *
 * To run a query within a React component, call `useGetPaginatedNonIndividualsTaxReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedNonIndividualsTaxReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedNonIndividualsTaxReportQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedNonIndividualsTaxReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedNonIndividualsTaxReportQuery,
    GetPaginatedNonIndividualsTaxReportQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedNonIndividualsTaxReportQuery,
    GetPaginatedNonIndividualsTaxReportQueryVariables
  >(GetPaginatedNonIndividualsTaxReportDocument, baseOptions);
}
export function useGetPaginatedNonIndividualsTaxReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedNonIndividualsTaxReportQuery,
    GetPaginatedNonIndividualsTaxReportQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedNonIndividualsTaxReportQuery,
    GetPaginatedNonIndividualsTaxReportQueryVariables
  >(GetPaginatedNonIndividualsTaxReportDocument, baseOptions);
}
export type GetPaginatedNonIndividualsTaxReportQueryHookResult = ReturnType<
  typeof useGetPaginatedNonIndividualsTaxReportQuery
>;
export type GetPaginatedNonIndividualsTaxReportLazyQueryHookResult = ReturnType<
  typeof useGetPaginatedNonIndividualsTaxReportLazyQuery
>;
export type GetPaginatedNonIndividualsTaxReportQueryResult = Apollo.QueryResult<
  GetPaginatedNonIndividualsTaxReportQuery,
  GetPaginatedNonIndividualsTaxReportQueryVariables
>;
export const NonIndividualIncomeTaxSummaryDocument = gql`
  query NonIndividualIncomeTaxSummary($params: String!) {
    rtcs_db_FnRPT_NonIndividualTaxSummaryData(args: { params: $params }) {
      ...NonIndividualIncomeTaxSummary
    }
  }
  ${NonIndividualIncomeTaxSummaryFragmentDoc}
`;

/**
 * __useNonIndividualIncomeTaxSummaryQuery__
 *
 * To run a query within a React component, call `useNonIndividualIncomeTaxSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonIndividualIncomeTaxSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonIndividualIncomeTaxSummaryQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useNonIndividualIncomeTaxSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    NonIndividualIncomeTaxSummaryQuery,
    NonIndividualIncomeTaxSummaryQueryVariables
  >
) {
  return Apollo.useQuery<
    NonIndividualIncomeTaxSummaryQuery,
    NonIndividualIncomeTaxSummaryQueryVariables
  >(NonIndividualIncomeTaxSummaryDocument, baseOptions);
}
export function useNonIndividualIncomeTaxSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NonIndividualIncomeTaxSummaryQuery,
    NonIndividualIncomeTaxSummaryQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    NonIndividualIncomeTaxSummaryQuery,
    NonIndividualIncomeTaxSummaryQueryVariables
  >(NonIndividualIncomeTaxSummaryDocument, baseOptions);
}
export type NonIndividualIncomeTaxSummaryQueryHookResult = ReturnType<
  typeof useNonIndividualIncomeTaxSummaryQuery
>;
export type NonIndividualIncomeTaxSummaryLazyQueryHookResult = ReturnType<
  typeof useNonIndividualIncomeTaxSummaryLazyQuery
>;
export type NonIndividualIncomeTaxSummaryQueryResult = Apollo.QueryResult<
  NonIndividualIncomeTaxSummaryQuery,
  NonIndividualIncomeTaxSummaryQueryVariables
>;
export const NonIndividualIncomeTaxSummariesDocument = gql`
  query NonIndividualIncomeTaxSummaries($params: String!) {
    rtcs_db_FnRPT_NonIndividualTaxSummaryData(args: { params: $params }) {
      ...NonIndividualIncomeTaxSummaries
    }
  }
  ${NonIndividualIncomeTaxSummariesFragmentDoc}
`;

/**
 * __useNonIndividualIncomeTaxSummariesQuery__
 *
 * To run a query within a React component, call `useNonIndividualIncomeTaxSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonIndividualIncomeTaxSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonIndividualIncomeTaxSummariesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useNonIndividualIncomeTaxSummariesQuery(
  baseOptions: Apollo.QueryHookOptions<
    NonIndividualIncomeTaxSummariesQuery,
    NonIndividualIncomeTaxSummariesQueryVariables
  >
) {
  return Apollo.useQuery<
    NonIndividualIncomeTaxSummariesQuery,
    NonIndividualIncomeTaxSummariesQueryVariables
  >(NonIndividualIncomeTaxSummariesDocument, baseOptions);
}
export function useNonIndividualIncomeTaxSummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NonIndividualIncomeTaxSummariesQuery,
    NonIndividualIncomeTaxSummariesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    NonIndividualIncomeTaxSummariesQuery,
    NonIndividualIncomeTaxSummariesQueryVariables
  >(NonIndividualIncomeTaxSummariesDocument, baseOptions);
}
export type NonIndividualIncomeTaxSummariesQueryHookResult = ReturnType<
  typeof useNonIndividualIncomeTaxSummariesQuery
>;
export type NonIndividualIncomeTaxSummariesLazyQueryHookResult = ReturnType<
  typeof useNonIndividualIncomeTaxSummariesLazyQuery
>;
export type NonIndividualIncomeTaxSummariesQueryResult = Apollo.QueryResult<
  NonIndividualIncomeTaxSummariesQuery,
  NonIndividualIncomeTaxSummariesQueryVariables
>;
export const Rpt_NonIndividualBalanceSheetDetailsDocument = gql`
  query RPT_NonIndividualBalanceSheetDetails($params: String!) {
    rtcs_db_FnRPT_NonIndividualBalanceSheet(args: { params: $params }) {
      ...RPT_NonIndividualBalanceSheet
    }
  }
  ${Rpt_NonIndividualBalanceSheetFragmentDoc}
`;

/**
 * __useRpt_NonIndividualBalanceSheetDetailsQuery__
 *
 * To run a query within a React component, call `useRpt_NonIndividualBalanceSheetDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRpt_NonIndividualBalanceSheetDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRpt_NonIndividualBalanceSheetDetailsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRpt_NonIndividualBalanceSheetDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Rpt_NonIndividualBalanceSheetDetailsQuery,
    Rpt_NonIndividualBalanceSheetDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    Rpt_NonIndividualBalanceSheetDetailsQuery,
    Rpt_NonIndividualBalanceSheetDetailsQueryVariables
  >(Rpt_NonIndividualBalanceSheetDetailsDocument, baseOptions);
}
export function useRpt_NonIndividualBalanceSheetDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Rpt_NonIndividualBalanceSheetDetailsQuery,
    Rpt_NonIndividualBalanceSheetDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    Rpt_NonIndividualBalanceSheetDetailsQuery,
    Rpt_NonIndividualBalanceSheetDetailsQueryVariables
  >(Rpt_NonIndividualBalanceSheetDetailsDocument, baseOptions);
}
export type Rpt_NonIndividualBalanceSheetDetailsQueryHookResult = ReturnType<
  typeof useRpt_NonIndividualBalanceSheetDetailsQuery
>;
export type Rpt_NonIndividualBalanceSheetDetailsLazyQueryHookResult =
  ReturnType<typeof useRpt_NonIndividualBalanceSheetDetailsLazyQuery>;
export type Rpt_NonIndividualBalanceSheetDetailsQueryResult =
  Apollo.QueryResult<
    Rpt_NonIndividualBalanceSheetDetailsQuery,
    Rpt_NonIndividualBalanceSheetDetailsQueryVariables
  >;
export const IndividualsAssociatedBusinessReportDocument = gql`
  query IndividualsAssociatedBusinessReport($params: String!) {
    rtcs_db_Fnura_IndividualsAssociatedBusiness(args: { params: $params }) {
      ...UraIndividualsAssociatedBusiness
    }
  }
  ${UraIndividualsAssociatedBusinessFragmentDoc}
`;

/**
 * __useIndividualsAssociatedBusinessReportQuery__
 *
 * To run a query within a React component, call `useIndividualsAssociatedBusinessReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndividualsAssociatedBusinessReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndividualsAssociatedBusinessReportQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useIndividualsAssociatedBusinessReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndividualsAssociatedBusinessReportQuery,
    IndividualsAssociatedBusinessReportQueryVariables
  >
) {
  return Apollo.useQuery<
    IndividualsAssociatedBusinessReportQuery,
    IndividualsAssociatedBusinessReportQueryVariables
  >(IndividualsAssociatedBusinessReportDocument, baseOptions);
}
export function useIndividualsAssociatedBusinessReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndividualsAssociatedBusinessReportQuery,
    IndividualsAssociatedBusinessReportQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    IndividualsAssociatedBusinessReportQuery,
    IndividualsAssociatedBusinessReportQueryVariables
  >(IndividualsAssociatedBusinessReportDocument, baseOptions);
}
export type IndividualsAssociatedBusinessReportQueryHookResult = ReturnType<
  typeof useIndividualsAssociatedBusinessReportQuery
>;
export type IndividualsAssociatedBusinessReportLazyQueryHookResult = ReturnType<
  typeof useIndividualsAssociatedBusinessReportLazyQuery
>;
export type IndividualsAssociatedBusinessReportQueryResult = Apollo.QueryResult<
  IndividualsAssociatedBusinessReportQuery,
  IndividualsAssociatedBusinessReportQueryVariables
>;
export const GetPaginatedIndividualsAssociatedBusinessDocument = gql`
  query GetPaginatedIndividualsAssociatedBusiness(
    $params: String!
    $aggregateParams: String!
  ) {
    rtcs_db_Fnura_IndividualsAssociatedBusiness(args: { params: $params }) {
      ...UraIndividualsAssociatedBusiness
    }
    rtcs_db_Fnura_IndividualsAssociatedBusiness_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${UraIndividualsAssociatedBusinessFragmentDoc}
`;

/**
 * __useGetPaginatedIndividualsAssociatedBusinessQuery__
 *
 * To run a query within a React component, call `useGetPaginatedIndividualsAssociatedBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedIndividualsAssociatedBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedIndividualsAssociatedBusinessQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedIndividualsAssociatedBusinessQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedIndividualsAssociatedBusinessQuery,
    GetPaginatedIndividualsAssociatedBusinessQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedIndividualsAssociatedBusinessQuery,
    GetPaginatedIndividualsAssociatedBusinessQueryVariables
  >(GetPaginatedIndividualsAssociatedBusinessDocument, baseOptions);
}
export function useGetPaginatedIndividualsAssociatedBusinessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedIndividualsAssociatedBusinessQuery,
    GetPaginatedIndividualsAssociatedBusinessQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedIndividualsAssociatedBusinessQuery,
    GetPaginatedIndividualsAssociatedBusinessQueryVariables
  >(GetPaginatedIndividualsAssociatedBusinessDocument, baseOptions);
}
export type GetPaginatedIndividualsAssociatedBusinessQueryHookResult =
  ReturnType<typeof useGetPaginatedIndividualsAssociatedBusinessQuery>;
export type GetPaginatedIndividualsAssociatedBusinessLazyQueryHookResult =
  ReturnType<typeof useGetPaginatedIndividualsAssociatedBusinessLazyQuery>;
export type GetPaginatedIndividualsAssociatedBusinessQueryResult =
  Apollo.QueryResult<
    GetPaginatedIndividualsAssociatedBusinessQuery,
    GetPaginatedIndividualsAssociatedBusinessQueryVariables
  >;
export const NonIndividualsAssociatedBusinessReportDocument = gql`
  query NonIndividualsAssociatedBusinessReport($params: String!) {
    rtcs_db_Fnura_NonIndividualsAssociatedBusiness(args: { params: $params }) {
      ...UraNonIndividualsAssociatedBusiness
    }
  }
  ${UraNonIndividualsAssociatedBusinessFragmentDoc}
`;

/**
 * __useNonIndividualsAssociatedBusinessReportQuery__
 *
 * To run a query within a React component, call `useNonIndividualsAssociatedBusinessReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonIndividualsAssociatedBusinessReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonIndividualsAssociatedBusinessReportQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useNonIndividualsAssociatedBusinessReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    NonIndividualsAssociatedBusinessReportQuery,
    NonIndividualsAssociatedBusinessReportQueryVariables
  >
) {
  return Apollo.useQuery<
    NonIndividualsAssociatedBusinessReportQuery,
    NonIndividualsAssociatedBusinessReportQueryVariables
  >(NonIndividualsAssociatedBusinessReportDocument, baseOptions);
}
export function useNonIndividualsAssociatedBusinessReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NonIndividualsAssociatedBusinessReportQuery,
    NonIndividualsAssociatedBusinessReportQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    NonIndividualsAssociatedBusinessReportQuery,
    NonIndividualsAssociatedBusinessReportQueryVariables
  >(NonIndividualsAssociatedBusinessReportDocument, baseOptions);
}
export type NonIndividualsAssociatedBusinessReportQueryHookResult = ReturnType<
  typeof useNonIndividualsAssociatedBusinessReportQuery
>;
export type NonIndividualsAssociatedBusinessReportLazyQueryHookResult =
  ReturnType<typeof useNonIndividualsAssociatedBusinessReportLazyQuery>;
export type NonIndividualsAssociatedBusinessReportQueryResult =
  Apollo.QueryResult<
    NonIndividualsAssociatedBusinessReportQuery,
    NonIndividualsAssociatedBusinessReportQueryVariables
  >;
export const GetPaginatedNonIndividualsAssociatedBusinessDocument = gql`
  query GetPaginatedNonIndividualsAssociatedBusiness(
    $params: String!
    $aggregateParams: String!
  ) {
    rtcs_db_Fnura_NonIndividualsAssociatedBusiness(args: { params: $params }) {
      ...UraNonIndividualsAssociatedBusiness
    }
    rtcs_db_Fnura_NonIndividualsAssociatedBusiness_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${UraNonIndividualsAssociatedBusinessFragmentDoc}
`;

/**
 * __useGetPaginatedNonIndividualsAssociatedBusinessQuery__
 *
 * To run a query within a React component, call `useGetPaginatedNonIndividualsAssociatedBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedNonIndividualsAssociatedBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedNonIndividualsAssociatedBusinessQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedNonIndividualsAssociatedBusinessQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedNonIndividualsAssociatedBusinessQuery,
    GetPaginatedNonIndividualsAssociatedBusinessQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedNonIndividualsAssociatedBusinessQuery,
    GetPaginatedNonIndividualsAssociatedBusinessQueryVariables
  >(GetPaginatedNonIndividualsAssociatedBusinessDocument, baseOptions);
}
export function useGetPaginatedNonIndividualsAssociatedBusinessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedNonIndividualsAssociatedBusinessQuery,
    GetPaginatedNonIndividualsAssociatedBusinessQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedNonIndividualsAssociatedBusinessQuery,
    GetPaginatedNonIndividualsAssociatedBusinessQueryVariables
  >(GetPaginatedNonIndividualsAssociatedBusinessDocument, baseOptions);
}
export type GetPaginatedNonIndividualsAssociatedBusinessQueryHookResult =
  ReturnType<typeof useGetPaginatedNonIndividualsAssociatedBusinessQuery>;
export type GetPaginatedNonIndividualsAssociatedBusinessLazyQueryHookResult =
  ReturnType<typeof useGetPaginatedNonIndividualsAssociatedBusinessLazyQuery>;
export type GetPaginatedNonIndividualsAssociatedBusinessQueryResult =
  Apollo.QueryResult<
    GetPaginatedNonIndividualsAssociatedBusinessQuery,
    GetPaginatedNonIndividualsAssociatedBusinessQueryVariables
  >;
export const UraRentalTenantPaymentsFilterDocument = gql`
  query UraRentalTenantPaymentsFilter($params: String!) {
    rtcs_db_Fnura_RentalTenantPayments(args: { params: $params }) {
      ...UraRentalTenantPayments
    }
  }
  ${UraRentalTenantPaymentsFragmentDoc}
`;

/**
 * __useUraRentalTenantPaymentsFilterQuery__
 *
 * To run a query within a React component, call `useUraRentalTenantPaymentsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useUraRentalTenantPaymentsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUraRentalTenantPaymentsFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUraRentalTenantPaymentsFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    UraRentalTenantPaymentsFilterQuery,
    UraRentalTenantPaymentsFilterQueryVariables
  >
) {
  return Apollo.useQuery<
    UraRentalTenantPaymentsFilterQuery,
    UraRentalTenantPaymentsFilterQueryVariables
  >(UraRentalTenantPaymentsFilterDocument, baseOptions);
}
export function useUraRentalTenantPaymentsFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UraRentalTenantPaymentsFilterQuery,
    UraRentalTenantPaymentsFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UraRentalTenantPaymentsFilterQuery,
    UraRentalTenantPaymentsFilterQueryVariables
  >(UraRentalTenantPaymentsFilterDocument, baseOptions);
}
export type UraRentalTenantPaymentsFilterQueryHookResult = ReturnType<
  typeof useUraRentalTenantPaymentsFilterQuery
>;
export type UraRentalTenantPaymentsFilterLazyQueryHookResult = ReturnType<
  typeof useUraRentalTenantPaymentsFilterLazyQuery
>;
export type UraRentalTenantPaymentsFilterQueryResult = Apollo.QueryResult<
  UraRentalTenantPaymentsFilterQuery,
  UraRentalTenantPaymentsFilterQueryVariables
>;
export const RptRentalTenantPaymentsFilterDocument = gql`
  query RptRentalTenantPaymentsFilter($params: String!) {
    rtcs_db_FnRPT_RentalTenantPayments(args: { params: $params }) {
      ...RptRentalTenantPayments
    }
  }
  ${RptRentalTenantPaymentsFragmentDoc}
`;

/**
 * __useRptRentalTenantPaymentsFilterQuery__
 *
 * To run a query within a React component, call `useRptRentalTenantPaymentsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useRptRentalTenantPaymentsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRptRentalTenantPaymentsFilterQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRptRentalTenantPaymentsFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    RptRentalTenantPaymentsFilterQuery,
    RptRentalTenantPaymentsFilterQueryVariables
  >
) {
  return Apollo.useQuery<
    RptRentalTenantPaymentsFilterQuery,
    RptRentalTenantPaymentsFilterQueryVariables
  >(RptRentalTenantPaymentsFilterDocument, baseOptions);
}
export function useRptRentalTenantPaymentsFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RptRentalTenantPaymentsFilterQuery,
    RptRentalTenantPaymentsFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    RptRentalTenantPaymentsFilterQuery,
    RptRentalTenantPaymentsFilterQueryVariables
  >(RptRentalTenantPaymentsFilterDocument, baseOptions);
}
export type RptRentalTenantPaymentsFilterQueryHookResult = ReturnType<
  typeof useRptRentalTenantPaymentsFilterQuery
>;
export type RptRentalTenantPaymentsFilterLazyQueryHookResult = ReturnType<
  typeof useRptRentalTenantPaymentsFilterLazyQuery
>;
export type RptRentalTenantPaymentsFilterQueryResult = Apollo.QueryResult<
  RptRentalTenantPaymentsFilterQuery,
  RptRentalTenantPaymentsFilterQueryVariables
>;
export const GetPaginatedUraRentalTenantPaymentsDocument = gql`
  query GetPaginatedUraRentalTenantPayments(
    $params: String!
    $aggregateParams: String!
  ) {
    rtcs_db_Fnura_RentalTenantPayments(args: { params: $params }) {
      ...UraRentalTenantPayments
    }
    rtcs_db_Fnura_RentalTenantPayments_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${UraRentalTenantPaymentsFragmentDoc}
`;

/**
 * __useGetPaginatedUraRentalTenantPaymentsQuery__
 *
 * To run a query within a React component, call `useGetPaginatedUraRentalTenantPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedUraRentalTenantPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedUraRentalTenantPaymentsQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedUraRentalTenantPaymentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedUraRentalTenantPaymentsQuery,
    GetPaginatedUraRentalTenantPaymentsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedUraRentalTenantPaymentsQuery,
    GetPaginatedUraRentalTenantPaymentsQueryVariables
  >(GetPaginatedUraRentalTenantPaymentsDocument, baseOptions);
}
export function useGetPaginatedUraRentalTenantPaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedUraRentalTenantPaymentsQuery,
    GetPaginatedUraRentalTenantPaymentsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedUraRentalTenantPaymentsQuery,
    GetPaginatedUraRentalTenantPaymentsQueryVariables
  >(GetPaginatedUraRentalTenantPaymentsDocument, baseOptions);
}
export type GetPaginatedUraRentalTenantPaymentsQueryHookResult = ReturnType<
  typeof useGetPaginatedUraRentalTenantPaymentsQuery
>;
export type GetPaginatedUraRentalTenantPaymentsLazyQueryHookResult = ReturnType<
  typeof useGetPaginatedUraRentalTenantPaymentsLazyQuery
>;
export type GetPaginatedUraRentalTenantPaymentsQueryResult = Apollo.QueryResult<
  GetPaginatedUraRentalTenantPaymentsQuery,
  GetPaginatedUraRentalTenantPaymentsQueryVariables
>;
export const GetPaginatedRptRentalTenantPaymentsDocument = gql`
  query GetPaginatedRptRentalTenantPayments(
    $params: String!
    $aggregateParams: String!
  ) {
    rtcs_db_FnRPT_RentalTenantPayments(args: { params: $params }) {
      ...RptRentalTenantPayments
    }
    rtcs_db_FnRPT_RentalTenantPayments_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${RptRentalTenantPaymentsFragmentDoc}
`;

/**
 * __useGetPaginatedRptRentalTenantPaymentsQuery__
 *
 * To run a query within a React component, call `useGetPaginatedRptRentalTenantPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedRptRentalTenantPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedRptRentalTenantPaymentsQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedRptRentalTenantPaymentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedRptRentalTenantPaymentsQuery,
    GetPaginatedRptRentalTenantPaymentsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedRptRentalTenantPaymentsQuery,
    GetPaginatedRptRentalTenantPaymentsQueryVariables
  >(GetPaginatedRptRentalTenantPaymentsDocument, baseOptions);
}
export function useGetPaginatedRptRentalTenantPaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedRptRentalTenantPaymentsQuery,
    GetPaginatedRptRentalTenantPaymentsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedRptRentalTenantPaymentsQuery,
    GetPaginatedRptRentalTenantPaymentsQueryVariables
  >(GetPaginatedRptRentalTenantPaymentsDocument, baseOptions);
}
export type GetPaginatedRptRentalTenantPaymentsQueryHookResult = ReturnType<
  typeof useGetPaginatedRptRentalTenantPaymentsQuery
>;
export type GetPaginatedRptRentalTenantPaymentsLazyQueryHookResult = ReturnType<
  typeof useGetPaginatedRptRentalTenantPaymentsLazyQuery
>;
export type GetPaginatedRptRentalTenantPaymentsQueryResult = Apollo.QueryResult<
  GetPaginatedRptRentalTenantPaymentsQuery,
  GetPaginatedRptRentalTenantPaymentsQueryVariables
>;
export const GetMaxMinUraRentalTenantPaymentsDocument = gql`
  query GetMaxMinUraRentalTenantPayments {
    rtcs_db_Fnura_RentalTenantPayments_aggregatecm(
      args: {
        aggregate_params: "{\\"min\\": [\\"RentPaid\\"],\\"max\\": [\\"RentPaid\\"] }"
        params: "{\\"variables\\":{\\"where\\":{}}}"
      }
    ) {
      operation
      value
    }
  }
`;

/**
 * __useGetMaxMinUraRentalTenantPaymentsQuery__
 *
 * To run a query within a React component, call `useGetMaxMinUraRentalTenantPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaxMinUraRentalTenantPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaxMinUraRentalTenantPaymentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMaxMinUraRentalTenantPaymentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMaxMinUraRentalTenantPaymentsQuery,
    GetMaxMinUraRentalTenantPaymentsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetMaxMinUraRentalTenantPaymentsQuery,
    GetMaxMinUraRentalTenantPaymentsQueryVariables
  >(GetMaxMinUraRentalTenantPaymentsDocument, baseOptions);
}
export function useGetMaxMinUraRentalTenantPaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMaxMinUraRentalTenantPaymentsQuery,
    GetMaxMinUraRentalTenantPaymentsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetMaxMinUraRentalTenantPaymentsQuery,
    GetMaxMinUraRentalTenantPaymentsQueryVariables
  >(GetMaxMinUraRentalTenantPaymentsDocument, baseOptions);
}
export type GetMaxMinUraRentalTenantPaymentsQueryHookResult = ReturnType<
  typeof useGetMaxMinUraRentalTenantPaymentsQuery
>;
export type GetMaxMinUraRentalTenantPaymentsLazyQueryHookResult = ReturnType<
  typeof useGetMaxMinUraRentalTenantPaymentsLazyQuery
>;
export type GetMaxMinUraRentalTenantPaymentsQueryResult = Apollo.QueryResult<
  GetMaxMinUraRentalTenantPaymentsQuery,
  GetMaxMinUraRentalTenantPaymentsQueryVariables
>;
export const GetMaxMinRptRentalTenantPaymentsDocument = gql`
  query GetMaxMinRptRentalTenantPayments {
    rtcs_db_FnRPT_RentalTenantPayments_aggregatecm(
      args: {
        aggregate_params: "{\\"min\\": [\\"RentPaid\\"],\\"max\\": [\\"RentPaid\\"] }"
        params: "{\\"variables\\":{\\"where\\":{}}}"
      }
    ) {
      operation
      value
    }
  }
`;

/**
 * __useGetMaxMinRptRentalTenantPaymentsQuery__
 *
 * To run a query within a React component, call `useGetMaxMinRptRentalTenantPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaxMinRptRentalTenantPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaxMinRptRentalTenantPaymentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMaxMinRptRentalTenantPaymentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMaxMinRptRentalTenantPaymentsQuery,
    GetMaxMinRptRentalTenantPaymentsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetMaxMinRptRentalTenantPaymentsQuery,
    GetMaxMinRptRentalTenantPaymentsQueryVariables
  >(GetMaxMinRptRentalTenantPaymentsDocument, baseOptions);
}
export function useGetMaxMinRptRentalTenantPaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMaxMinRptRentalTenantPaymentsQuery,
    GetMaxMinRptRentalTenantPaymentsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetMaxMinRptRentalTenantPaymentsQuery,
    GetMaxMinRptRentalTenantPaymentsQueryVariables
  >(GetMaxMinRptRentalTenantPaymentsDocument, baseOptions);
}
export type GetMaxMinRptRentalTenantPaymentsQueryHookResult = ReturnType<
  typeof useGetMaxMinRptRentalTenantPaymentsQuery
>;
export type GetMaxMinRptRentalTenantPaymentsLazyQueryHookResult = ReturnType<
  typeof useGetMaxMinRptRentalTenantPaymentsLazyQuery
>;
export type GetMaxMinRptRentalTenantPaymentsQueryResult = Apollo.QueryResult<
  GetMaxMinRptRentalTenantPaymentsQuery,
  GetMaxMinRptRentalTenantPaymentsQueryVariables
>;
export const UraRentalLandLordIncomeReportDocument = gql`
  query UraRentalLandLordIncomeReport($params: String!) {
    rtcs_db_Fnura_RentalLandLordIncome(args: { params: $params }) {
      ...UraRentalLandLordIncome
    }
  }
  ${UraRentalLandLordIncomeFragmentDoc}
`;

/**
 * __useUraRentalLandLordIncomeReportQuery__
 *
 * To run a query within a React component, call `useUraRentalLandLordIncomeReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useUraRentalLandLordIncomeReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUraRentalLandLordIncomeReportQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUraRentalLandLordIncomeReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    UraRentalLandLordIncomeReportQuery,
    UraRentalLandLordIncomeReportQueryVariables
  >
) {
  return Apollo.useQuery<
    UraRentalLandLordIncomeReportQuery,
    UraRentalLandLordIncomeReportQueryVariables
  >(UraRentalLandLordIncomeReportDocument, baseOptions);
}
export function useUraRentalLandLordIncomeReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UraRentalLandLordIncomeReportQuery,
    UraRentalLandLordIncomeReportQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UraRentalLandLordIncomeReportQuery,
    UraRentalLandLordIncomeReportQueryVariables
  >(UraRentalLandLordIncomeReportDocument, baseOptions);
}
export type UraRentalLandLordIncomeReportQueryHookResult = ReturnType<
  typeof useUraRentalLandLordIncomeReportQuery
>;
export type UraRentalLandLordIncomeReportLazyQueryHookResult = ReturnType<
  typeof useUraRentalLandLordIncomeReportLazyQuery
>;
export type UraRentalLandLordIncomeReportQueryResult = Apollo.QueryResult<
  UraRentalLandLordIncomeReportQuery,
  UraRentalLandLordIncomeReportQueryVariables
>;
export const GetPaginatedUgandaRegistrationDocument = gql`
  query GetPaginatedUgandaRegistration(
    $params: String!
    $aggregateParams: String!
  ) {
    rtcs_db_Fnursb_CompaniesAndBusinessNamesURSB(args: { params: $params }) {
      ...UgandaRegistrationSB
    }
    rtcs_db_Fnursb_CompaniesAndBusinessNamesURSB_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${UgandaRegistrationSbFragmentDoc}
`;

/**
 * __useGetPaginatedUgandaRegistrationQuery__
 *
 * To run a query within a React component, call `useGetPaginatedUgandaRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedUgandaRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedUgandaRegistrationQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedUgandaRegistrationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedUgandaRegistrationQuery,
    GetPaginatedUgandaRegistrationQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedUgandaRegistrationQuery,
    GetPaginatedUgandaRegistrationQueryVariables
  >(GetPaginatedUgandaRegistrationDocument, baseOptions);
}
export function useGetPaginatedUgandaRegistrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedUgandaRegistrationQuery,
    GetPaginatedUgandaRegistrationQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedUgandaRegistrationQuery,
    GetPaginatedUgandaRegistrationQueryVariables
  >(GetPaginatedUgandaRegistrationDocument, baseOptions);
}
export type GetPaginatedUgandaRegistrationQueryHookResult = ReturnType<
  typeof useGetPaginatedUgandaRegistrationQuery
>;
export type GetPaginatedUgandaRegistrationLazyQueryHookResult = ReturnType<
  typeof useGetPaginatedUgandaRegistrationLazyQuery
>;
export type GetPaginatedUgandaRegistrationQueryResult = Apollo.QueryResult<
  GetPaginatedUgandaRegistrationQuery,
  GetPaginatedUgandaRegistrationQueryVariables
>;
export const UraIndividualsDocument = gql`
  query UraIndividuals($params: String!) {
    rtcs_db_FnRPT_IndividualComposite(args: { params: $params }) {
      ...RegIndividualDataReport
    }
  }
  ${RegIndividualDataReportFragmentDoc}
`;

/**
 * __useUraIndividualsQuery__
 *
 * To run a query within a React component, call `useUraIndividualsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUraIndividualsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUraIndividualsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUraIndividualsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UraIndividualsQuery,
    UraIndividualsQueryVariables
  >
) {
  return Apollo.useQuery<UraIndividualsQuery, UraIndividualsQueryVariables>(
    UraIndividualsDocument,
    baseOptions
  );
}
export function useUraIndividualsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UraIndividualsQuery,
    UraIndividualsQueryVariables
  >
) {
  return Apollo.useLazyQuery<UraIndividualsQuery, UraIndividualsQueryVariables>(
    UraIndividualsDocument,
    baseOptions
  );
}
export type UraIndividualsQueryHookResult = ReturnType<
  typeof useUraIndividualsQuery
>;
export type UraIndividualsLazyQueryHookResult = ReturnType<
  typeof useUraIndividualsLazyQuery
>;
export type UraIndividualsQueryResult = Apollo.QueryResult<
  UraIndividualsQuery,
  UraIndividualsQueryVariables
>;
export const NonIndividualComplianceDocument = gql`
  query NonIndividualCompliance {
    no_income: rtcs_db_Fnrmatch_NonIndividualComplianceSummaryReport_aggregate(
      args: {
        params: "{\\"variables\\":{\\"where\\":{\\"Compliancecategory\\": {\\"_eq\\": \\"NO INCOME\\"}}}}"
      }
    ) {
      ...NonIndividualCompliance
    }
    compliant: rtcs_db_Fnrmatch_NonIndividualComplianceSummaryReport_aggregate(
      args: {
        params: "{\\"variables\\":{\\"where\\":{\\"Compliancecategory\\": {\\"_eq\\": \\"COMPLIANT\\"}}}}"
      }
    ) {
      ...NonIndividualCompliance
    }
    no_match: rtcs_db_Fnrmatch_NonIndividualComplianceSummaryReport_aggregate(
      args: {
        params: "{\\"variables\\":{\\"where\\":{\\"Compliancecategory\\": {\\"_eq\\": \\"NO MATCH\\"}}}}"
      }
    ) {
      ...NonIndividualCompliance
    }
    no_return: rtcs_db_Fnrmatch_NonIndividualComplianceSummaryReport_aggregate(
      args: {
        params: "{\\"variables\\":{\\"where\\":{\\"Compliancecategory\\": {\\"_eq\\": \\"NO RETURN\\"}}}}"
      }
    ) {
      ...NonIndividualCompliance
    }
    underreported: rtcs_db_Fnrmatch_NonIndividualComplianceSummaryReport_aggregate(
      args: {
        params: "{\\"variables\\":{\\"where\\":{\\"Compliancecategory\\": {\\"_eq\\": \\"UNDERREPORTED\\"}}}}"
      }
    ) {
      ...NonIndividualCompliance
    }
  }
  ${NonIndividualComplianceFragmentDoc}
`;

/**
 * __useNonIndividualComplianceQuery__
 *
 * To run a query within a React component, call `useNonIndividualComplianceQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonIndividualComplianceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonIndividualComplianceQuery({
 *   variables: {
 *   },
 * });
 */
export function useNonIndividualComplianceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NonIndividualComplianceQuery,
    NonIndividualComplianceQueryVariables
  >
) {
  return Apollo.useQuery<
    NonIndividualComplianceQuery,
    NonIndividualComplianceQueryVariables
  >(NonIndividualComplianceDocument, baseOptions);
}
export function useNonIndividualComplianceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NonIndividualComplianceQuery,
    NonIndividualComplianceQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    NonIndividualComplianceQuery,
    NonIndividualComplianceQueryVariables
  >(NonIndividualComplianceDocument, baseOptions);
}
export type NonIndividualComplianceQueryHookResult = ReturnType<
  typeof useNonIndividualComplianceQuery
>;
export type NonIndividualComplianceLazyQueryHookResult = ReturnType<
  typeof useNonIndividualComplianceLazyQuery
>;
export type NonIndividualComplianceQueryResult = Apollo.QueryResult<
  NonIndividualComplianceQuery,
  NonIndividualComplianceQueryVariables
>;
export const IndividualComplianceDocument = gql`
  query IndividualCompliance {
    no_income: rtcs_db_Fnrmatch_IndividualComplianceSummaryReport_aggregate(
      args: {
        params: "{\\"variables\\":{\\"where\\":{\\"Compliancecategory\\": {\\"_eq\\": \\"NO INCOME\\"}}}}"
      }
    ) {
      ...IndividualCompliance
    }
    compliant: rtcs_db_Fnrmatch_IndividualComplianceSummaryReport_aggregate(
      args: {
        params: "{\\"variables\\":{\\"where\\":{\\"Compliancecategory\\": {\\"_eq\\": \\"COMPLIANT\\"}}}}"
      }
    ) {
      ...IndividualCompliance
    }
    no_match: rtcs_db_Fnrmatch_IndividualComplianceSummaryReport_aggregate(
      args: {
        params: "{\\"variables\\":{\\"where\\":{\\"Compliancecategory\\": {\\"_eq\\": \\"NO MATCH\\"}}}}"
      }
    ) {
      ...IndividualCompliance
    }
    no_return: rtcs_db_Fnrmatch_IndividualComplianceSummaryReport_aggregate(
      args: {
        params: "{\\"variables\\":{\\"where\\":{\\"Compliancecategory\\": {\\"_eq\\": \\"NO RETURN\\"}}}}"
      }
    ) {
      ...IndividualCompliance
    }
    underreported: rtcs_db_Fnrmatch_IndividualComplianceSummaryReport_aggregate(
      args: {
        params: "{\\"variables\\":{\\"where\\":{\\"Compliancecategory\\": {\\"_eq\\": \\"UNDERREPORTED\\"}}}}"
      }
    ) {
      ...IndividualCompliance
    }
  }
  ${IndividualComplianceFragmentDoc}
`;

/**
 * __useIndividualComplianceQuery__
 *
 * To run a query within a React component, call `useIndividualComplianceQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndividualComplianceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndividualComplianceQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndividualComplianceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IndividualComplianceQuery,
    IndividualComplianceQueryVariables
  >
) {
  return Apollo.useQuery<
    IndividualComplianceQuery,
    IndividualComplianceQueryVariables
  >(IndividualComplianceDocument, baseOptions);
}
export function useIndividualComplianceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndividualComplianceQuery,
    IndividualComplianceQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    IndividualComplianceQuery,
    IndividualComplianceQueryVariables
  >(IndividualComplianceDocument, baseOptions);
}
export type IndividualComplianceQueryHookResult = ReturnType<
  typeof useIndividualComplianceQuery
>;
export type IndividualComplianceLazyQueryHookResult = ReturnType<
  typeof useIndividualComplianceLazyQuery
>;
export type IndividualComplianceQueryResult = Apollo.QueryResult<
  IndividualComplianceQuery,
  IndividualComplianceQueryVariables
>;
export const UraRegIndividualDataDocument = gql`
  query UraRegIndividualData($params: String!) {
    rtcs_db_FnRPT_IndividualComposite(args: { params: $params }) {
      ...UraRegIndividualFragment
    }
  }
  ${UraRegIndividualFragmentFragmentDoc}
`;

/**
 * __useUraRegIndividualDataQuery__
 *
 * To run a query within a React component, call `useUraRegIndividualDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUraRegIndividualDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUraRegIndividualDataQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUraRegIndividualDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    UraRegIndividualDataQuery,
    UraRegIndividualDataQueryVariables
  >
) {
  return Apollo.useQuery<
    UraRegIndividualDataQuery,
    UraRegIndividualDataQueryVariables
  >(UraRegIndividualDataDocument, baseOptions);
}
export function useUraRegIndividualDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UraRegIndividualDataQuery,
    UraRegIndividualDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UraRegIndividualDataQuery,
    UraRegIndividualDataQueryVariables
  >(UraRegIndividualDataDocument, baseOptions);
}
export type UraRegIndividualDataQueryHookResult = ReturnType<
  typeof useUraRegIndividualDataQuery
>;
export type UraRegIndividualDataLazyQueryHookResult = ReturnType<
  typeof useUraRegIndividualDataLazyQuery
>;
export type UraRegIndividualDataQueryResult = Apollo.QueryResult<
  UraRegIndividualDataQuery,
  UraRegIndividualDataQueryVariables
>;
export const UraTownDocument = gql`
  query uraTown($params: String!) {
    rtcs_db_Fnura_Town(args: { params: $params }) {
      TownName
    }
  }
`;

/**
 * __useUraTownQuery__
 *
 * To run a query within a React component, call `useUraTownQuery` and pass it any options that fit your needs.
 * When your component renders, `useUraTownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUraTownQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUraTownQuery(
  baseOptions: Apollo.QueryHookOptions<UraTownQuery, UraTownQueryVariables>
) {
  return Apollo.useQuery<UraTownQuery, UraTownQueryVariables>(
    UraTownDocument,
    baseOptions
  );
}
export function useUraTownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UraTownQuery, UraTownQueryVariables>
) {
  return Apollo.useLazyQuery<UraTownQuery, UraTownQueryVariables>(
    UraTownDocument,
    baseOptions
  );
}
export type UraTownQueryHookResult = ReturnType<typeof useUraTownQuery>;
export type UraTownLazyQueryHookResult = ReturnType<typeof useUraTownLazyQuery>;
export type UraTownQueryResult = Apollo.QueryResult<
  UraTownQuery,
  UraTownQueryVariables
>;
export const KccaCustomerDocument = gql`
  query kccaCustomer($params: String!) {
    rtcs_db_Fnkcca_Customer(args: { params: $params }) {
      ...kccaCustomer
    }
  }
  ${KccaCustomerFragmentDoc}
`;

/**
 * __useKccaCustomerQuery__
 *
 * To run a query within a React component, call `useKccaCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useKccaCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<
    KccaCustomerQuery,
    KccaCustomerQueryVariables
  >
) {
  return Apollo.useQuery<KccaCustomerQuery, KccaCustomerQueryVariables>(
    KccaCustomerDocument,
    baseOptions
  );
}
export function useKccaCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerQuery,
    KccaCustomerQueryVariables
  >
) {
  return Apollo.useLazyQuery<KccaCustomerQuery, KccaCustomerQueryVariables>(
    KccaCustomerDocument,
    baseOptions
  );
}
export type KccaCustomerQueryHookResult = ReturnType<
  typeof useKccaCustomerQuery
>;
export type KccaCustomerLazyQueryHookResult = ReturnType<
  typeof useKccaCustomerLazyQuery
>;
export type KccaCustomerQueryResult = Apollo.QueryResult<
  KccaCustomerQuery,
  KccaCustomerQueryVariables
>;
export const KccaCustomerVillageDocument = gql`
  query kccaCustomerVillage {
    rtcs_db_Fnkcca_Village(
      args: { params: "{\\"variables\\":{\\"where\\":{}}}" }
    ) {
      Id
      Name
    }
  }
`;

/**
 * __useKccaCustomerVillageQuery__
 *
 * To run a query within a React component, call `useKccaCustomerVillageQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerVillageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerVillageQuery({
 *   variables: {
 *   },
 * });
 */
export function useKccaCustomerVillageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    KccaCustomerVillageQuery,
    KccaCustomerVillageQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaCustomerVillageQuery,
    KccaCustomerVillageQueryVariables
  >(KccaCustomerVillageDocument, baseOptions);
}
export function useKccaCustomerVillageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerVillageQuery,
    KccaCustomerVillageQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerVillageQuery,
    KccaCustomerVillageQueryVariables
  >(KccaCustomerVillageDocument, baseOptions);
}
export type KccaCustomerVillageQueryHookResult = ReturnType<
  typeof useKccaCustomerVillageQuery
>;
export type KccaCustomerVillageLazyQueryHookResult = ReturnType<
  typeof useKccaCustomerVillageLazyQuery
>;
export type KccaCustomerVillageQueryResult = Apollo.QueryResult<
  KccaCustomerVillageQuery,
  KccaCustomerVillageQueryVariables
>;
export const KccaCustomerParishDocument = gql`
  query kccaCustomerParish {
    rtcs_db_Fnkcca_Parish(
      args: { params: "{\\"variables\\":{\\"where\\":{}}}" }
    ) {
      Id
      Name
    }
  }
`;

/**
 * __useKccaCustomerParishQuery__
 *
 * To run a query within a React component, call `useKccaCustomerParishQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerParishQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerParishQuery({
 *   variables: {
 *   },
 * });
 */
export function useKccaCustomerParishQuery(
  baseOptions?: Apollo.QueryHookOptions<
    KccaCustomerParishQuery,
    KccaCustomerParishQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaCustomerParishQuery,
    KccaCustomerParishQueryVariables
  >(KccaCustomerParishDocument, baseOptions);
}
export function useKccaCustomerParishLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerParishQuery,
    KccaCustomerParishQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerParishQuery,
    KccaCustomerParishQueryVariables
  >(KccaCustomerParishDocument, baseOptions);
}
export type KccaCustomerParishQueryHookResult = ReturnType<
  typeof useKccaCustomerParishQuery
>;
export type KccaCustomerParishLazyQueryHookResult = ReturnType<
  typeof useKccaCustomerParishLazyQuery
>;
export type KccaCustomerParishQueryResult = Apollo.QueryResult<
  KccaCustomerParishQuery,
  KccaCustomerParishQueryVariables
>;
export const KccaCustomerSubcountyDocument = gql`
  query kccaCustomerSubcounty {
    rtcs_db_Fnkcca_SubCounty(
      args: { params: "{\\"variables\\":{\\"where\\":{}}}" }
    ) {
      Id
      Name
    }
  }
`;

/**
 * __useKccaCustomerSubcountyQuery__
 *
 * To run a query within a React component, call `useKccaCustomerSubcountyQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerSubcountyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerSubcountyQuery({
 *   variables: {
 *   },
 * });
 */
export function useKccaCustomerSubcountyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    KccaCustomerSubcountyQuery,
    KccaCustomerSubcountyQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaCustomerSubcountyQuery,
    KccaCustomerSubcountyQueryVariables
  >(KccaCustomerSubcountyDocument, baseOptions);
}
export function useKccaCustomerSubcountyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerSubcountyQuery,
    KccaCustomerSubcountyQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerSubcountyQuery,
    KccaCustomerSubcountyQueryVariables
  >(KccaCustomerSubcountyDocument, baseOptions);
}
export type KccaCustomerSubcountyQueryHookResult = ReturnType<
  typeof useKccaCustomerSubcountyQuery
>;
export type KccaCustomerSubcountyLazyQueryHookResult = ReturnType<
  typeof useKccaCustomerSubcountyLazyQuery
>;
export type KccaCustomerSubcountyQueryResult = Apollo.QueryResult<
  KccaCustomerSubcountyQuery,
  KccaCustomerSubcountyQueryVariables
>;
export const KccaCustomerCountyDocument = gql`
  query kccaCustomerCounty {
    rtcs_db_Fnkcca_County(
      args: { params: "{\\"variables\\":{\\"where\\":{}}}" }
    ) {
      Id
      Name
    }
  }
`;

/**
 * __useKccaCustomerCountyQuery__
 *
 * To run a query within a React component, call `useKccaCustomerCountyQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerCountyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerCountyQuery({
 *   variables: {
 *   },
 * });
 */
export function useKccaCustomerCountyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    KccaCustomerCountyQuery,
    KccaCustomerCountyQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaCustomerCountyQuery,
    KccaCustomerCountyQueryVariables
  >(KccaCustomerCountyDocument, baseOptions);
}
export function useKccaCustomerCountyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerCountyQuery,
    KccaCustomerCountyQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerCountyQuery,
    KccaCustomerCountyQueryVariables
  >(KccaCustomerCountyDocument, baseOptions);
}
export type KccaCustomerCountyQueryHookResult = ReturnType<
  typeof useKccaCustomerCountyQuery
>;
export type KccaCustomerCountyLazyQueryHookResult = ReturnType<
  typeof useKccaCustomerCountyLazyQuery
>;
export type KccaCustomerCountyQueryResult = Apollo.QueryResult<
  KccaCustomerCountyQuery,
  KccaCustomerCountyQueryVariables
>;
export const KccaCustomerDistrictDocument = gql`
  query kccaCustomerDistrict {
    rtcs_db_Fnkcca_District(
      args: { params: "{\\"variables\\":{\\"where\\":{}}}" }
    ) {
      Id
      Name
    }
  }
`;

/**
 * __useKccaCustomerDistrictQuery__
 *
 * To run a query within a React component, call `useKccaCustomerDistrictQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerDistrictQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerDistrictQuery({
 *   variables: {
 *   },
 * });
 */
export function useKccaCustomerDistrictQuery(
  baseOptions?: Apollo.QueryHookOptions<
    KccaCustomerDistrictQuery,
    KccaCustomerDistrictQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaCustomerDistrictQuery,
    KccaCustomerDistrictQueryVariables
  >(KccaCustomerDistrictDocument, baseOptions);
}
export function useKccaCustomerDistrictLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerDistrictQuery,
    KccaCustomerDistrictQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerDistrictQuery,
    KccaCustomerDistrictQueryVariables
  >(KccaCustomerDistrictDocument, baseOptions);
}
export type KccaCustomerDistrictQueryHookResult = ReturnType<
  typeof useKccaCustomerDistrictQuery
>;
export type KccaCustomerDistrictLazyQueryHookResult = ReturnType<
  typeof useKccaCustomerDistrictLazyQuery
>;
export type KccaCustomerDistrictQueryResult = Apollo.QueryResult<
  KccaCustomerDistrictQuery,
  KccaCustomerDistrictQueryVariables
>;
export const NiraDataDocument = gql`
  query NiraData($params: String!) {
    rtcs_db_Fnnira_RawData(args: { params: $params }) {
      Surname
      GivenName
      DateOfBirth
      NationalID
      MobileNumber
      Email
      TinNumber
      PseudoNIN
    }
  }
`;

/**
 * __useNiraDataQuery__
 *
 * To run a query within a React component, call `useNiraDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useNiraDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNiraDataQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useNiraDataQuery(
  baseOptions: Apollo.QueryHookOptions<NiraDataQuery, NiraDataQueryVariables>
) {
  return Apollo.useQuery<NiraDataQuery, NiraDataQueryVariables>(
    NiraDataDocument,
    baseOptions
  );
}
export function useNiraDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NiraDataQuery,
    NiraDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<NiraDataQuery, NiraDataQueryVariables>(
    NiraDataDocument,
    baseOptions
  );
}
export type NiraDataQueryHookResult = ReturnType<typeof useNiraDataQuery>;
export type NiraDataLazyQueryHookResult = ReturnType<
  typeof useNiraDataLazyQuery
>;
export type NiraDataQueryResult = Apollo.QueryResult<
  NiraDataQuery,
  NiraDataQueryVariables
>;
export const UraDataDocument = gql`
  query UraData($params: String!) {
    rtcs_db_FnRPT_IndividualComposite(args: { params: $params }) {
      BirthState
      BirthCountry
      BsnsName
      CitizenCountry
      ExistTinNo
      Fullname
      Firstname
      Surname
      BirthDt
    }
  }
`;

/**
 * __useUraDataQuery__
 *
 * To run a query within a React component, call `useUraDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUraDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUraDataQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUraDataQuery(
  baseOptions: Apollo.QueryHookOptions<UraDataQuery, UraDataQueryVariables>
) {
  return Apollo.useQuery<UraDataQuery, UraDataQueryVariables>(
    UraDataDocument,
    baseOptions
  );
}
export function useUraDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UraDataQuery, UraDataQueryVariables>
) {
  return Apollo.useLazyQuery<UraDataQuery, UraDataQueryVariables>(
    UraDataDocument,
    baseOptions
  );
}
export type UraDataQueryHookResult = ReturnType<typeof useUraDataQuery>;
export type UraDataLazyQueryHookResult = ReturnType<typeof useUraDataLazyQuery>;
export type UraDataQueryResult = Apollo.QueryResult<
  UraDataQuery,
  UraDataQueryVariables
>;
export const PropertiesDataDocument = gql`
  query PropertiesData($params: String!) {
    rtcs_db_FnRPT_KccaPropertyRegistration(args: { params: $params }) {
      MapTitle
      Latitude
      Longitude
      CustomerId
      LocationAddress
      Address
      PlotNo
      FlatNo
      HouseNo
      BlockNo
      StreetId
      RoadOthers
      ParticularsFrontages
      Village
      Parish
      Division
      PropertyNo
      SerialNo
      GprsCordinateX
      GprsCordinateY
      PropertyType
      PropertySubType
      PropertyRentedStatus
      GrossValue
      RateableValue
      CurrentRateableValue
      StatusId
      CamvId
      CreatedBy
      CreatedDate
      Firstname
      Surname
      MotherMaidenName
      EntityLegalName
      Birthdate
      Mobile
      SecondMobile
      Email
      BusinessName
      Coin
      MiddleName
      Tin
      OwnerTin
      OwnerFirstname
      OwnerLegalSurnameMaiden
      OwnerTitle
      ContactMobile1
      ContactLandline
      ContactEmail
      AccomodationBedrooms
    }
  }
`;

/**
 * __usePropertiesDataQuery__
 *
 * To run a query within a React component, call `usePropertiesDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertiesDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertiesDataQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function usePropertiesDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    PropertiesDataQuery,
    PropertiesDataQueryVariables
  >
) {
  return Apollo.useQuery<PropertiesDataQuery, PropertiesDataQueryVariables>(
    PropertiesDataDocument,
    baseOptions
  );
}
export function usePropertiesDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PropertiesDataQuery,
    PropertiesDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<PropertiesDataQuery, PropertiesDataQueryVariables>(
    PropertiesDataDocument,
    baseOptions
  );
}
export type PropertiesDataQueryHookResult = ReturnType<
  typeof usePropertiesDataQuery
>;
export type PropertiesDataLazyQueryHookResult = ReturnType<
  typeof usePropertiesDataLazyQuery
>;
export type PropertiesDataQueryResult = Apollo.QueryResult<
  PropertiesDataQuery,
  PropertiesDataQueryVariables
>;
export const IndividualPropertiesDocument = gql`
  query IndividualProperties($params: String!) {
    rtcs_db_FnRPT_KccaPropertyRegistrationData(args: { params: $params }) {
      ...IndividualProperties
    }
  }
  ${IndividualPropertiesFragmentDoc}
`;

/**
 * __useIndividualPropertiesQuery__
 *
 * To run a query within a React component, call `useIndividualPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndividualPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndividualPropertiesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useIndividualPropertiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndividualPropertiesQuery,
    IndividualPropertiesQueryVariables
  >
) {
  return Apollo.useQuery<
    IndividualPropertiesQuery,
    IndividualPropertiesQueryVariables
  >(IndividualPropertiesDocument, baseOptions);
}
export function useIndividualPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndividualPropertiesQuery,
    IndividualPropertiesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    IndividualPropertiesQuery,
    IndividualPropertiesQueryVariables
  >(IndividualPropertiesDocument, baseOptions);
}
export type IndividualPropertiesQueryHookResult = ReturnType<
  typeof useIndividualPropertiesQuery
>;
export type IndividualPropertiesLazyQueryHookResult = ReturnType<
  typeof useIndividualPropertiesLazyQuery
>;
export type IndividualPropertiesQueryResult = Apollo.QueryResult<
  IndividualPropertiesQuery,
  IndividualPropertiesQueryVariables
>;
export const IndividualsAssociatedBusinessDocument = gql`
  query IndividualsAssociatedBusiness($params: String!) {
    rtcs_db_Fnura_IndividualsAssociatedBusiness(args: { params: $params }) {
      ...IndividualsAssociatedBusiness
    }
  }
  ${IndividualsAssociatedBusinessFragmentDoc}
`;

/**
 * __useIndividualsAssociatedBusinessQuery__
 *
 * To run a query within a React component, call `useIndividualsAssociatedBusinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndividualsAssociatedBusinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndividualsAssociatedBusinessQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useIndividualsAssociatedBusinessQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndividualsAssociatedBusinessQuery,
    IndividualsAssociatedBusinessQueryVariables
  >
) {
  return Apollo.useQuery<
    IndividualsAssociatedBusinessQuery,
    IndividualsAssociatedBusinessQueryVariables
  >(IndividualsAssociatedBusinessDocument, baseOptions);
}
export function useIndividualsAssociatedBusinessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndividualsAssociatedBusinessQuery,
    IndividualsAssociatedBusinessQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    IndividualsAssociatedBusinessQuery,
    IndividualsAssociatedBusinessQueryVariables
  >(IndividualsAssociatedBusinessDocument, baseOptions);
}
export type IndividualsAssociatedBusinessQueryHookResult = ReturnType<
  typeof useIndividualsAssociatedBusinessQuery
>;
export type IndividualsAssociatedBusinessLazyQueryHookResult = ReturnType<
  typeof useIndividualsAssociatedBusinessLazyQuery
>;
export type IndividualsAssociatedBusinessQueryResult = Apollo.QueryResult<
  IndividualsAssociatedBusinessQuery,
  IndividualsAssociatedBusinessQueryVariables
>;
export const UraRptIndividualTaxSummariesDocument = gql`
  query UraRPTIndividualTaxSummaries($params: String!) {
    rtcs_db_FnRPT_IndividualTaxSummaryData(args: { params: $params }) {
      ...UraRPTIndividualTaxSummaries
    }
  }
  ${UraRptIndividualTaxSummariesFragmentDoc}
`;

/**
 * __useUraRptIndividualTaxSummariesQuery__
 *
 * To run a query within a React component, call `useUraRptIndividualTaxSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUraRptIndividualTaxSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUraRptIndividualTaxSummariesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUraRptIndividualTaxSummariesQuery(
  baseOptions: Apollo.QueryHookOptions<
    UraRptIndividualTaxSummariesQuery,
    UraRptIndividualTaxSummariesQueryVariables
  >
) {
  return Apollo.useQuery<
    UraRptIndividualTaxSummariesQuery,
    UraRptIndividualTaxSummariesQueryVariables
  >(UraRptIndividualTaxSummariesDocument, baseOptions);
}
export function useUraRptIndividualTaxSummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UraRptIndividualTaxSummariesQuery,
    UraRptIndividualTaxSummariesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UraRptIndividualTaxSummariesQuery,
    UraRptIndividualTaxSummariesQueryVariables
  >(UraRptIndividualTaxSummariesDocument, baseOptions);
}
export type UraRptIndividualTaxSummariesQueryHookResult = ReturnType<
  typeof useUraRptIndividualTaxSummariesQuery
>;
export type UraRptIndividualTaxSummariesLazyQueryHookResult = ReturnType<
  typeof useUraRptIndividualTaxSummariesLazyQuery
>;
export type UraRptIndividualTaxSummariesQueryResult = Apollo.QueryResult<
  UraRptIndividualTaxSummariesQuery,
  UraRptIndividualTaxSummariesQueryVariables
>;
export const RegIndividualAffiliatesDocument = gql`
  query RegIndividualAffiliates($params: String!) {
    rtcs_db_FnRPT_IndividualComposite(args: { params: $params }) {
      ...RegIndividualAffiliates
    }
  }
  ${RegIndividualAffiliatesFragmentDoc}
`;

/**
 * __useRegIndividualAffiliatesQuery__
 *
 * To run a query within a React component, call `useRegIndividualAffiliatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegIndividualAffiliatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegIndividualAffiliatesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useRegIndividualAffiliatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegIndividualAffiliatesQuery,
    RegIndividualAffiliatesQueryVariables
  >
) {
  return Apollo.useQuery<
    RegIndividualAffiliatesQuery,
    RegIndividualAffiliatesQueryVariables
  >(RegIndividualAffiliatesDocument, baseOptions);
}
export function useRegIndividualAffiliatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegIndividualAffiliatesQuery,
    RegIndividualAffiliatesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    RegIndividualAffiliatesQuery,
    RegIndividualAffiliatesQueryVariables
  >(RegIndividualAffiliatesDocument, baseOptions);
}
export type RegIndividualAffiliatesQueryHookResult = ReturnType<
  typeof useRegIndividualAffiliatesQuery
>;
export type RegIndividualAffiliatesLazyQueryHookResult = ReturnType<
  typeof useRegIndividualAffiliatesLazyQuery
>;
export type RegIndividualAffiliatesQueryResult = Apollo.QueryResult<
  RegIndividualAffiliatesQuery,
  RegIndividualAffiliatesQueryVariables
>;
export const KccaIndividualAffiliatesDocument = gql`
  query KccaIndividualAffiliates($params: String!) {
    rtcs_db_Fnkcca_CustomerReference(args: { params: $params }) {
      ...KccaCustomerReferenceIndividualAffiliates
    }
    rtcs_db_Fnkcca_CustomerContactPerson(args: { params: $params }) {
      ...KccaContactPersonIndividualAffiliates
    }
    rtcs_db_Fnkcca_CustomerGuardian(args: { params: $params }) {
      ...KccaCustomerGuardianIndividualAffiliates
    }
    rtcs_db_Fnkcca_CustomerBusinessAssociate(args: { params: $params }) {
      ...KccaCustomerBusinessAssociateIndividualAffiliates
    }
  }
  ${KccaCustomerReferenceIndividualAffiliatesFragmentDoc}
  ${KccaContactPersonIndividualAffiliatesFragmentDoc}
  ${KccaCustomerGuardianIndividualAffiliatesFragmentDoc}
  ${KccaCustomerBusinessAssociateIndividualAffiliatesFragmentDoc}
`;

/**
 * __useKccaIndividualAffiliatesQuery__
 *
 * To run a query within a React component, call `useKccaIndividualAffiliatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaIndividualAffiliatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaIndividualAffiliatesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useKccaIndividualAffiliatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    KccaIndividualAffiliatesQuery,
    KccaIndividualAffiliatesQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaIndividualAffiliatesQuery,
    KccaIndividualAffiliatesQueryVariables
  >(KccaIndividualAffiliatesDocument, baseOptions);
}
export function useKccaIndividualAffiliatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaIndividualAffiliatesQuery,
    KccaIndividualAffiliatesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaIndividualAffiliatesQuery,
    KccaIndividualAffiliatesQueryVariables
  >(KccaIndividualAffiliatesDocument, baseOptions);
}
export type KccaIndividualAffiliatesQueryHookResult = ReturnType<
  typeof useKccaIndividualAffiliatesQuery
>;
export type KccaIndividualAffiliatesLazyQueryHookResult = ReturnType<
  typeof useKccaIndividualAffiliatesLazyQuery
>;
export type KccaIndividualAffiliatesQueryResult = Apollo.QueryResult<
  KccaIndividualAffiliatesQuery,
  KccaIndividualAffiliatesQueryVariables
>;
export const UraRptIndividualTaxSummaryDocument = gql`
  query UraRPTIndividualTaxSummary($params: String!) {
    rtcs_db_FnRPT_IndividualTaxSummaryData(args: { params: $params }) {
      ...UraRPTIndividualTaxSummary
    }
  }
  ${UraRptIndividualTaxSummaryFragmentDoc}
`;

/**
 * __useUraRptIndividualTaxSummaryQuery__
 *
 * To run a query within a React component, call `useUraRptIndividualTaxSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUraRptIndividualTaxSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUraRptIndividualTaxSummaryQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUraRptIndividualTaxSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    UraRptIndividualTaxSummaryQuery,
    UraRptIndividualTaxSummaryQueryVariables
  >
) {
  return Apollo.useQuery<
    UraRptIndividualTaxSummaryQuery,
    UraRptIndividualTaxSummaryQueryVariables
  >(UraRptIndividualTaxSummaryDocument, baseOptions);
}
export function useUraRptIndividualTaxSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UraRptIndividualTaxSummaryQuery,
    UraRptIndividualTaxSummaryQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UraRptIndividualTaxSummaryQuery,
    UraRptIndividualTaxSummaryQueryVariables
  >(UraRptIndividualTaxSummaryDocument, baseOptions);
}
export type UraRptIndividualTaxSummaryQueryHookResult = ReturnType<
  typeof useUraRptIndividualTaxSummaryQuery
>;
export type UraRptIndividualTaxSummaryLazyQueryHookResult = ReturnType<
  typeof useUraRptIndividualTaxSummaryLazyQuery
>;
export type UraRptIndividualTaxSummaryQueryResult = Apollo.QueryResult<
  UraRptIndividualTaxSummaryQuery,
  UraRptIndividualTaxSummaryQueryVariables
>;
export const IndividualMortgageDocument = gql`
  query IndividualMortgage($params: String!) {
    rtcs_db_Fnura_IndividualMortgage(args: { params: $params }) {
      ...IndividualMortgage
    }
  }
  ${IndividualMortgageFragmentDoc}
`;

/**
 * __useIndividualMortgageQuery__
 *
 * To run a query within a React component, call `useIndividualMortgageQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndividualMortgageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndividualMortgageQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useIndividualMortgageQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndividualMortgageQuery,
    IndividualMortgageQueryVariables
  >
) {
  return Apollo.useQuery<
    IndividualMortgageQuery,
    IndividualMortgageQueryVariables
  >(IndividualMortgageDocument, baseOptions);
}
export function useIndividualMortgageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndividualMortgageQuery,
    IndividualMortgageQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    IndividualMortgageQuery,
    IndividualMortgageQueryVariables
  >(IndividualMortgageDocument, baseOptions);
}
export type IndividualMortgageQueryHookResult = ReturnType<
  typeof useIndividualMortgageQuery
>;
export type IndividualMortgageLazyQueryHookResult = ReturnType<
  typeof useIndividualMortgageLazyQuery
>;
export type IndividualMortgageQueryResult = Apollo.QueryResult<
  IndividualMortgageQuery,
  IndividualMortgageQueryVariables
>;
export const IndividualTenantsDocument = gql`
  query IndividualTenants($params: String!) {
    rtcs_db_Fnura_RentalLandLordIncome(args: { params: $params }) {
      ...IndividualTenant
    }
  }
  ${IndividualTenantFragmentDoc}
`;

/**
 * __useIndividualTenantsQuery__
 *
 * To run a query within a React component, call `useIndividualTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndividualTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndividualTenantsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useIndividualTenantsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndividualTenantsQuery,
    IndividualTenantsQueryVariables
  >
) {
  return Apollo.useQuery<
    IndividualTenantsQuery,
    IndividualTenantsQueryVariables
  >(IndividualTenantsDocument, baseOptions);
}
export function useIndividualTenantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndividualTenantsQuery,
    IndividualTenantsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    IndividualTenantsQuery,
    IndividualTenantsQueryVariables
  >(IndividualTenantsDocument, baseOptions);
}
export type IndividualTenantsQueryHookResult = ReturnType<
  typeof useIndividualTenantsQuery
>;
export type IndividualTenantsLazyQueryHookResult = ReturnType<
  typeof useIndividualTenantsLazyQuery
>;
export type IndividualTenantsQueryResult = Apollo.QueryResult<
  IndividualTenantsQuery,
  IndividualTenantsQueryVariables
>;
export const IndividualExcessiveOwnerOccupancyDocument = gql`
  query IndividualExcessiveOwnerOccupancy($params: String!) {
    rtcs_db_Fnrmatch_RMatchSummary916ExcessiveOwnerOccupancy(
      args: { params: $params }
    ) {
      ...IndividualExcessiveOwnerOccupancy
    }
  }
  ${IndividualExcessiveOwnerOccupancyFragmentDoc}
`;

/**
 * __useIndividualExcessiveOwnerOccupancyQuery__
 *
 * To run a query within a React component, call `useIndividualExcessiveOwnerOccupancyQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndividualExcessiveOwnerOccupancyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndividualExcessiveOwnerOccupancyQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useIndividualExcessiveOwnerOccupancyQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndividualExcessiveOwnerOccupancyQuery,
    IndividualExcessiveOwnerOccupancyQueryVariables
  >
) {
  return Apollo.useQuery<
    IndividualExcessiveOwnerOccupancyQuery,
    IndividualExcessiveOwnerOccupancyQueryVariables
  >(IndividualExcessiveOwnerOccupancyDocument, baseOptions);
}
export function useIndividualExcessiveOwnerOccupancyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndividualExcessiveOwnerOccupancyQuery,
    IndividualExcessiveOwnerOccupancyQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    IndividualExcessiveOwnerOccupancyQuery,
    IndividualExcessiveOwnerOccupancyQueryVariables
  >(IndividualExcessiveOwnerOccupancyDocument, baseOptions);
}
export type IndividualExcessiveOwnerOccupancyQueryHookResult = ReturnType<
  typeof useIndividualExcessiveOwnerOccupancyQuery
>;
export type IndividualExcessiveOwnerOccupancyLazyQueryHookResult = ReturnType<
  typeof useIndividualExcessiveOwnerOccupancyLazyQuery
>;
export type IndividualExcessiveOwnerOccupancyQueryResult = Apollo.QueryResult<
  IndividualExcessiveOwnerOccupancyQuery,
  IndividualExcessiveOwnerOccupancyQueryVariables
>;
export const GetRMatchIndividualsDocument = gql`
  query GetRMatchIndividuals($params: String!) {
    rtcs_db_FnRPT_IndividualComposite(args: { params: $params }) {
      ...RMatchIndividuals
    }
  }
  ${RMatchIndividualsFragmentDoc}
`;

/**
 * __useGetRMatchIndividualsQuery__
 *
 * To run a query within a React component, call `useGetRMatchIndividualsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRMatchIndividualsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRMatchIndividualsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetRMatchIndividualsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRMatchIndividualsQuery,
    GetRMatchIndividualsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetRMatchIndividualsQuery,
    GetRMatchIndividualsQueryVariables
  >(GetRMatchIndividualsDocument, baseOptions);
}
export function useGetRMatchIndividualsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRMatchIndividualsQuery,
    GetRMatchIndividualsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetRMatchIndividualsQuery,
    GetRMatchIndividualsQueryVariables
  >(GetRMatchIndividualsDocument, baseOptions);
}
export type GetRMatchIndividualsQueryHookResult = ReturnType<
  typeof useGetRMatchIndividualsQuery
>;
export type GetRMatchIndividualsLazyQueryHookResult = ReturnType<
  typeof useGetRMatchIndividualsLazyQuery
>;
export type GetRMatchIndividualsQueryResult = Apollo.QueryResult<
  GetRMatchIndividualsQuery,
  GetRMatchIndividualsQueryVariables
>;
export const IndividualUnregisteredLandLordDocument = gql`
  query IndividualUnregisteredLandLord($params: String!) {
    rtcs_db_Fnrmatch_RMatchSummary904(args: { params: $params }) {
      ...UraIndividualsWithNoTin
    }
  }
  ${UraIndividualsWithNoTinFragmentDoc}
`;

/**
 * __useIndividualUnregisteredLandLordQuery__
 *
 * To run a query within a React component, call `useIndividualUnregisteredLandLordQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndividualUnregisteredLandLordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndividualUnregisteredLandLordQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useIndividualUnregisteredLandLordQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndividualUnregisteredLandLordQuery,
    IndividualUnregisteredLandLordQueryVariables
  >
) {
  return Apollo.useQuery<
    IndividualUnregisteredLandLordQuery,
    IndividualUnregisteredLandLordQueryVariables
  >(IndividualUnregisteredLandLordDocument, baseOptions);
}
export function useIndividualUnregisteredLandLordLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndividualUnregisteredLandLordQuery,
    IndividualUnregisteredLandLordQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    IndividualUnregisteredLandLordQuery,
    IndividualUnregisteredLandLordQueryVariables
  >(IndividualUnregisteredLandLordDocument, baseOptions);
}
export type IndividualUnregisteredLandLordQueryHookResult = ReturnType<
  typeof useIndividualUnregisteredLandLordQuery
>;
export type IndividualUnregisteredLandLordLazyQueryHookResult = ReturnType<
  typeof useIndividualUnregisteredLandLordLazyQuery
>;
export type IndividualUnregisteredLandLordQueryResult = Apollo.QueryResult<
  IndividualUnregisteredLandLordQuery,
  IndividualUnregisteredLandLordQueryVariables
>;
export const IndividualPropertyDetailsDocument = gql`
  query IndividualPropertyDetails($params: String!) {
    rtcs_db_Fnrmatch_IndividualComplianceDetailReport(
      args: { params: $params }
    ) {
      ...IndividualPropertyDetails
    }
  }
  ${IndividualPropertyDetailsFragmentDoc}
`;

/**
 * __useIndividualPropertyDetailsQuery__
 *
 * To run a query within a React component, call `useIndividualPropertyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndividualPropertyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndividualPropertyDetailsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useIndividualPropertyDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IndividualPropertyDetailsQuery,
    IndividualPropertyDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    IndividualPropertyDetailsQuery,
    IndividualPropertyDetailsQueryVariables
  >(IndividualPropertyDetailsDocument, baseOptions);
}
export function useIndividualPropertyDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IndividualPropertyDetailsQuery,
    IndividualPropertyDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    IndividualPropertyDetailsQuery,
    IndividualPropertyDetailsQueryVariables
  >(IndividualPropertyDetailsDocument, baseOptions);
}
export type IndividualPropertyDetailsQueryHookResult = ReturnType<
  typeof useIndividualPropertyDetailsQuery
>;
export type IndividualPropertyDetailsLazyQueryHookResult = ReturnType<
  typeof useIndividualPropertyDetailsLazyQuery
>;
export type IndividualPropertyDetailsQueryResult = Apollo.QueryResult<
  IndividualPropertyDetailsQuery,
  IndividualPropertyDetailsQueryVariables
>;
export const UraNonIndividualDocument = gql`
  query UraNonIndividual($params: String!) {
    rtcs_db_Fnura_RegNonIndividual(args: { params: $params }) {
      ...UraNonIndividual
    }
  }
  ${UraNonIndividualFragmentDoc}
`;

/**
 * __useUraNonIndividualQuery__
 *
 * To run a query within a React component, call `useUraNonIndividualQuery` and pass it any options that fit your needs.
 * When your component renders, `useUraNonIndividualQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUraNonIndividualQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUraNonIndividualQuery(
  baseOptions: Apollo.QueryHookOptions<
    UraNonIndividualQuery,
    UraNonIndividualQueryVariables
  >
) {
  return Apollo.useQuery<UraNonIndividualQuery, UraNonIndividualQueryVariables>(
    UraNonIndividualDocument,
    baseOptions
  );
}
export function useUraNonIndividualLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UraNonIndividualQuery,
    UraNonIndividualQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UraNonIndividualQuery,
    UraNonIndividualQueryVariables
  >(UraNonIndividualDocument, baseOptions);
}
export type UraNonIndividualQueryHookResult = ReturnType<
  typeof useUraNonIndividualQuery
>;
export type UraNonIndividualLazyQueryHookResult = ReturnType<
  typeof useUraNonIndividualLazyQuery
>;
export type UraNonIndividualQueryResult = Apollo.QueryResult<
  UraNonIndividualQuery,
  UraNonIndividualQueryVariables
>;
export const KccaCustomerResidentialAddressCountyDocument = gql`
  query kccaCustomerResidentialAddressCounty($params: String!) {
    rtcs_db_Fnkcca_County(args: { params: $params }) {
      Name
    }
  }
`;

/**
 * __useKccaCustomerResidentialAddressCountyQuery__
 *
 * To run a query within a React component, call `useKccaCustomerResidentialAddressCountyQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerResidentialAddressCountyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerResidentialAddressCountyQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useKccaCustomerResidentialAddressCountyQuery(
  baseOptions: Apollo.QueryHookOptions<
    KccaCustomerResidentialAddressCountyQuery,
    KccaCustomerResidentialAddressCountyQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaCustomerResidentialAddressCountyQuery,
    KccaCustomerResidentialAddressCountyQueryVariables
  >(KccaCustomerResidentialAddressCountyDocument, baseOptions);
}
export function useKccaCustomerResidentialAddressCountyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerResidentialAddressCountyQuery,
    KccaCustomerResidentialAddressCountyQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerResidentialAddressCountyQuery,
    KccaCustomerResidentialAddressCountyQueryVariables
  >(KccaCustomerResidentialAddressCountyDocument, baseOptions);
}
export type KccaCustomerResidentialAddressCountyQueryHookResult = ReturnType<
  typeof useKccaCustomerResidentialAddressCountyQuery
>;
export type KccaCustomerResidentialAddressCountyLazyQueryHookResult =
  ReturnType<typeof useKccaCustomerResidentialAddressCountyLazyQuery>;
export type KccaCustomerResidentialAddressCountyQueryResult =
  Apollo.QueryResult<
    KccaCustomerResidentialAddressCountyQuery,
    KccaCustomerResidentialAddressCountyQueryVariables
  >;
export const KccaCustomerResidentialAddressDistrictDocument = gql`
  query kccaCustomerResidentialAddressDistrict($params: String!) {
    rtcs_db_Fnkcca_District(args: { params: $params }) {
      Name
    }
  }
`;

/**
 * __useKccaCustomerResidentialAddressDistrictQuery__
 *
 * To run a query within a React component, call `useKccaCustomerResidentialAddressDistrictQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerResidentialAddressDistrictQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerResidentialAddressDistrictQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useKccaCustomerResidentialAddressDistrictQuery(
  baseOptions: Apollo.QueryHookOptions<
    KccaCustomerResidentialAddressDistrictQuery,
    KccaCustomerResidentialAddressDistrictQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaCustomerResidentialAddressDistrictQuery,
    KccaCustomerResidentialAddressDistrictQueryVariables
  >(KccaCustomerResidentialAddressDistrictDocument, baseOptions);
}
export function useKccaCustomerResidentialAddressDistrictLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerResidentialAddressDistrictQuery,
    KccaCustomerResidentialAddressDistrictQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerResidentialAddressDistrictQuery,
    KccaCustomerResidentialAddressDistrictQueryVariables
  >(KccaCustomerResidentialAddressDistrictDocument, baseOptions);
}
export type KccaCustomerResidentialAddressDistrictQueryHookResult = ReturnType<
  typeof useKccaCustomerResidentialAddressDistrictQuery
>;
export type KccaCustomerResidentialAddressDistrictLazyQueryHookResult =
  ReturnType<typeof useKccaCustomerResidentialAddressDistrictLazyQuery>;
export type KccaCustomerResidentialAddressDistrictQueryResult =
  Apollo.QueryResult<
    KccaCustomerResidentialAddressDistrictQuery,
    KccaCustomerResidentialAddressDistrictQueryVariables
  >;
export const KccaCustomerResidentialAddressParishDocument = gql`
  query kccaCustomerResidentialAddressParish($params: String!) {
    rtcs_db_Fnkcca_Parish(args: { params: $params }) {
      Name
    }
  }
`;

/**
 * __useKccaCustomerResidentialAddressParishQuery__
 *
 * To run a query within a React component, call `useKccaCustomerResidentialAddressParishQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerResidentialAddressParishQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerResidentialAddressParishQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useKccaCustomerResidentialAddressParishQuery(
  baseOptions: Apollo.QueryHookOptions<
    KccaCustomerResidentialAddressParishQuery,
    KccaCustomerResidentialAddressParishQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaCustomerResidentialAddressParishQuery,
    KccaCustomerResidentialAddressParishQueryVariables
  >(KccaCustomerResidentialAddressParishDocument, baseOptions);
}
export function useKccaCustomerResidentialAddressParishLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerResidentialAddressParishQuery,
    KccaCustomerResidentialAddressParishQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerResidentialAddressParishQuery,
    KccaCustomerResidentialAddressParishQueryVariables
  >(KccaCustomerResidentialAddressParishDocument, baseOptions);
}
export type KccaCustomerResidentialAddressParishQueryHookResult = ReturnType<
  typeof useKccaCustomerResidentialAddressParishQuery
>;
export type KccaCustomerResidentialAddressParishLazyQueryHookResult =
  ReturnType<typeof useKccaCustomerResidentialAddressParishLazyQuery>;
export type KccaCustomerResidentialAddressParishQueryResult =
  Apollo.QueryResult<
    KccaCustomerResidentialAddressParishQuery,
    KccaCustomerResidentialAddressParishQueryVariables
  >;
export const KccaCustomerResidentialAddressSubCountyDocument = gql`
  query kccaCustomerResidentialAddressSubCounty($params: String!) {
    rtcs_db_Fnkcca_SubCounty(args: { params: $params }) {
      Name
    }
  }
`;

/**
 * __useKccaCustomerResidentialAddressSubCountyQuery__
 *
 * To run a query within a React component, call `useKccaCustomerResidentialAddressSubCountyQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerResidentialAddressSubCountyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerResidentialAddressSubCountyQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useKccaCustomerResidentialAddressSubCountyQuery(
  baseOptions: Apollo.QueryHookOptions<
    KccaCustomerResidentialAddressSubCountyQuery,
    KccaCustomerResidentialAddressSubCountyQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaCustomerResidentialAddressSubCountyQuery,
    KccaCustomerResidentialAddressSubCountyQueryVariables
  >(KccaCustomerResidentialAddressSubCountyDocument, baseOptions);
}
export function useKccaCustomerResidentialAddressSubCountyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerResidentialAddressSubCountyQuery,
    KccaCustomerResidentialAddressSubCountyQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerResidentialAddressSubCountyQuery,
    KccaCustomerResidentialAddressSubCountyQueryVariables
  >(KccaCustomerResidentialAddressSubCountyDocument, baseOptions);
}
export type KccaCustomerResidentialAddressSubCountyQueryHookResult = ReturnType<
  typeof useKccaCustomerResidentialAddressSubCountyQuery
>;
export type KccaCustomerResidentialAddressSubCountyLazyQueryHookResult =
  ReturnType<typeof useKccaCustomerResidentialAddressSubCountyLazyQuery>;
export type KccaCustomerResidentialAddressSubCountyQueryResult =
  Apollo.QueryResult<
    KccaCustomerResidentialAddressSubCountyQuery,
    KccaCustomerResidentialAddressSubCountyQueryVariables
  >;
export const KccaCustomerResidentialAddressVillageDocument = gql`
  query kccaCustomerResidentialAddressVillage($params: String!) {
    rtcs_db_Fnkcca_Village(args: { params: $params }) {
      Name
    }
  }
`;

/**
 * __useKccaCustomerResidentialAddressVillageQuery__
 *
 * To run a query within a React component, call `useKccaCustomerResidentialAddressVillageQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerResidentialAddressVillageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerResidentialAddressVillageQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useKccaCustomerResidentialAddressVillageQuery(
  baseOptions: Apollo.QueryHookOptions<
    KccaCustomerResidentialAddressVillageQuery,
    KccaCustomerResidentialAddressVillageQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaCustomerResidentialAddressVillageQuery,
    KccaCustomerResidentialAddressVillageQueryVariables
  >(KccaCustomerResidentialAddressVillageDocument, baseOptions);
}
export function useKccaCustomerResidentialAddressVillageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerResidentialAddressVillageQuery,
    KccaCustomerResidentialAddressVillageQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerResidentialAddressVillageQuery,
    KccaCustomerResidentialAddressVillageQueryVariables
  >(KccaCustomerResidentialAddressVillageDocument, baseOptions);
}
export type KccaCustomerResidentialAddressVillageQueryHookResult = ReturnType<
  typeof useKccaCustomerResidentialAddressVillageQuery
>;
export type KccaCustomerResidentialAddressVillageLazyQueryHookResult =
  ReturnType<typeof useKccaCustomerResidentialAddressVillageLazyQuery>;
export type KccaCustomerResidentialAddressVillageQueryResult =
  Apollo.QueryResult<
    KccaCustomerResidentialAddressVillageQuery,
    KccaCustomerResidentialAddressVillageQueryVariables
  >;
export const KccaCustomerNonIndividualDocument = gql`
  query kccaCustomerNonIndividual($params: String!) {
    rtcs_db_Fnkcca_Customer(args: { params: $params }) {
      ...kccaCustomerNonIndividual
    }
  }
  ${KccaCustomerNonIndividualFragmentDoc}
`;

/**
 * __useKccaCustomerNonIndividualQuery__
 *
 * To run a query within a React component, call `useKccaCustomerNonIndividualQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerNonIndividualQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerNonIndividualQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useKccaCustomerNonIndividualQuery(
  baseOptions: Apollo.QueryHookOptions<
    KccaCustomerNonIndividualQuery,
    KccaCustomerNonIndividualQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaCustomerNonIndividualQuery,
    KccaCustomerNonIndividualQueryVariables
  >(KccaCustomerNonIndividualDocument, baseOptions);
}
export function useKccaCustomerNonIndividualLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerNonIndividualQuery,
    KccaCustomerNonIndividualQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerNonIndividualQuery,
    KccaCustomerNonIndividualQueryVariables
  >(KccaCustomerNonIndividualDocument, baseOptions);
}
export type KccaCustomerNonIndividualQueryHookResult = ReturnType<
  typeof useKccaCustomerNonIndividualQuery
>;
export type KccaCustomerNonIndividualLazyQueryHookResult = ReturnType<
  typeof useKccaCustomerNonIndividualLazyQuery
>;
export type KccaCustomerNonIndividualQueryResult = Apollo.QueryResult<
  KccaCustomerNonIndividualQuery,
  KccaCustomerNonIndividualQueryVariables
>;
export const KccaCustomerTypeDocument = gql`
  query kccaCustomerType {
    rtcs_db_Fnkcca_CustomerType(
      args: { params: "{\\"variables\\":{\\"where\\":{}}}" }
    ) {
      Id
      Name
    }
  }
`;

/**
 * __useKccaCustomerTypeQuery__
 *
 * To run a query within a React component, call `useKccaCustomerTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useKccaCustomerTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    KccaCustomerTypeQuery,
    KccaCustomerTypeQueryVariables
  >
) {
  return Apollo.useQuery<KccaCustomerTypeQuery, KccaCustomerTypeQueryVariables>(
    KccaCustomerTypeDocument,
    baseOptions
  );
}
export function useKccaCustomerTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerTypeQuery,
    KccaCustomerTypeQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerTypeQuery,
    KccaCustomerTypeQueryVariables
  >(KccaCustomerTypeDocument, baseOptions);
}
export type KccaCustomerTypeQueryHookResult = ReturnType<
  typeof useKccaCustomerTypeQuery
>;
export type KccaCustomerTypeLazyQueryHookResult = ReturnType<
  typeof useKccaCustomerTypeLazyQuery
>;
export type KccaCustomerTypeQueryResult = Apollo.QueryResult<
  KccaCustomerTypeQuery,
  KccaCustomerTypeQueryVariables
>;
export const KccaApplicantTypeDocument = gql`
  query kccaApplicantType {
    rtcs_db_Fnkcca_CustomerApplicantType(
      args: { params: "{\\"variables\\":{\\"where\\":{}}}" }
    ) {
      Id
      Name
    }
  }
`;

/**
 * __useKccaApplicantTypeQuery__
 *
 * To run a query within a React component, call `useKccaApplicantTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaApplicantTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaApplicantTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useKccaApplicantTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    KccaApplicantTypeQuery,
    KccaApplicantTypeQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaApplicantTypeQuery,
    KccaApplicantTypeQueryVariables
  >(KccaApplicantTypeDocument, baseOptions);
}
export function useKccaApplicantTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaApplicantTypeQuery,
    KccaApplicantTypeQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaApplicantTypeQuery,
    KccaApplicantTypeQueryVariables
  >(KccaApplicantTypeDocument, baseOptions);
}
export type KccaApplicantTypeQueryHookResult = ReturnType<
  typeof useKccaApplicantTypeQuery
>;
export type KccaApplicantTypeLazyQueryHookResult = ReturnType<
  typeof useKccaApplicantTypeLazyQuery
>;
export type KccaApplicantTypeQueryResult = Apollo.QueryResult<
  KccaApplicantTypeQuery,
  KccaApplicantTypeQueryVariables
>;
export const KccaBusinessTypeDocument = gql`
  query kccaBusinessType {
    rtcs_db_Fnkcca_CustomerBusinessType(
      args: { params: "{\\"variables\\":{\\"where\\":{}}}" }
    ) {
      Id
      Name
    }
  }
`;

/**
 * __useKccaBusinessTypeQuery__
 *
 * To run a query within a React component, call `useKccaBusinessTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaBusinessTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaBusinessTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useKccaBusinessTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    KccaBusinessTypeQuery,
    KccaBusinessTypeQueryVariables
  >
) {
  return Apollo.useQuery<KccaBusinessTypeQuery, KccaBusinessTypeQueryVariables>(
    KccaBusinessTypeDocument,
    baseOptions
  );
}
export function useKccaBusinessTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaBusinessTypeQuery,
    KccaBusinessTypeQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaBusinessTypeQuery,
    KccaBusinessTypeQueryVariables
  >(KccaBusinessTypeDocument, baseOptions);
}
export type KccaBusinessTypeQueryHookResult = ReturnType<
  typeof useKccaBusinessTypeQuery
>;
export type KccaBusinessTypeLazyQueryHookResult = ReturnType<
  typeof useKccaBusinessTypeLazyQuery
>;
export type KccaBusinessTypeQueryResult = Apollo.QueryResult<
  KccaBusinessTypeQuery,
  KccaBusinessTypeQueryVariables
>;
export const NonIndividualAffilatesAndIndividualsDocument = gql`
  query NonIndividualAffilatesAndIndividuals($params: String!) {
    rtcs_db_Fnura_RegNonIndividual(args: { params: $params }) {
      ...UraAuthIndividual
    }
    rtcs_db_Fnura_RegAssociates(args: { params: $params }) {
      ...UraRegAssociate
    }
  }
  ${UraAuthIndividualFragmentDoc}
  ${UraRegAssociateFragmentDoc}
`;

/**
 * __useNonIndividualAffilatesAndIndividualsQuery__
 *
 * To run a query within a React component, call `useNonIndividualAffilatesAndIndividualsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonIndividualAffilatesAndIndividualsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonIndividualAffilatesAndIndividualsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useNonIndividualAffilatesAndIndividualsQuery(
  baseOptions: Apollo.QueryHookOptions<
    NonIndividualAffilatesAndIndividualsQuery,
    NonIndividualAffilatesAndIndividualsQueryVariables
  >
) {
  return Apollo.useQuery<
    NonIndividualAffilatesAndIndividualsQuery,
    NonIndividualAffilatesAndIndividualsQueryVariables
  >(NonIndividualAffilatesAndIndividualsDocument, baseOptions);
}
export function useNonIndividualAffilatesAndIndividualsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NonIndividualAffilatesAndIndividualsQuery,
    NonIndividualAffilatesAndIndividualsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    NonIndividualAffilatesAndIndividualsQuery,
    NonIndividualAffilatesAndIndividualsQueryVariables
  >(NonIndividualAffilatesAndIndividualsDocument, baseOptions);
}
export type NonIndividualAffilatesAndIndividualsQueryHookResult = ReturnType<
  typeof useNonIndividualAffilatesAndIndividualsQuery
>;
export type NonIndividualAffilatesAndIndividualsLazyQueryHookResult =
  ReturnType<typeof useNonIndividualAffilatesAndIndividualsLazyQuery>;
export type NonIndividualAffilatesAndIndividualsQueryResult =
  Apollo.QueryResult<
    NonIndividualAffilatesAndIndividualsQuery,
    NonIndividualAffilatesAndIndividualsQueryVariables
  >;
export const NonIndividualRentExpensesDocument = gql`
  query NonIndividualRentExpenses($params: String!) {
    rtcs_db_Fnura_RentExpenseDetails(args: { params: $params }) {
      ...NonIndividualRentExpenses
    }
  }
  ${NonIndividualRentExpensesFragmentDoc}
`;

/**
 * __useNonIndividualRentExpensesQuery__
 *
 * To run a query within a React component, call `useNonIndividualRentExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonIndividualRentExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonIndividualRentExpensesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useNonIndividualRentExpensesQuery(
  baseOptions: Apollo.QueryHookOptions<
    NonIndividualRentExpensesQuery,
    NonIndividualRentExpensesQueryVariables
  >
) {
  return Apollo.useQuery<
    NonIndividualRentExpensesQuery,
    NonIndividualRentExpensesQueryVariables
  >(NonIndividualRentExpensesDocument, baseOptions);
}
export function useNonIndividualRentExpensesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NonIndividualRentExpensesQuery,
    NonIndividualRentExpensesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    NonIndividualRentExpensesQuery,
    NonIndividualRentExpensesQueryVariables
  >(NonIndividualRentExpensesDocument, baseOptions);
}
export type NonIndividualRentExpensesQueryHookResult = ReturnType<
  typeof useNonIndividualRentExpensesQuery
>;
export type NonIndividualRentExpensesLazyQueryHookResult = ReturnType<
  typeof useNonIndividualRentExpensesLazyQuery
>;
export type NonIndividualRentExpensesQueryResult = Apollo.QueryResult<
  NonIndividualRentExpensesQuery,
  NonIndividualRentExpensesQueryVariables
>;
export const NonIndividualOwnersAndOfficersDocument = gql`
  query nonIndividualOwnersAndOfficers($params: String!) {
    rtcs_db_Fnura_CombinedOfficersAndOwners(args: { params: $params }) {
      ...ownersAndOfficers
    }
  }
  ${OwnersAndOfficersFragmentDoc}
`;

/**
 * __useNonIndividualOwnersAndOfficersQuery__
 *
 * To run a query within a React component, call `useNonIndividualOwnersAndOfficersQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonIndividualOwnersAndOfficersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonIndividualOwnersAndOfficersQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useNonIndividualOwnersAndOfficersQuery(
  baseOptions: Apollo.QueryHookOptions<
    NonIndividualOwnersAndOfficersQuery,
    NonIndividualOwnersAndOfficersQueryVariables
  >
) {
  return Apollo.useQuery<
    NonIndividualOwnersAndOfficersQuery,
    NonIndividualOwnersAndOfficersQueryVariables
  >(NonIndividualOwnersAndOfficersDocument, baseOptions);
}
export function useNonIndividualOwnersAndOfficersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NonIndividualOwnersAndOfficersQuery,
    NonIndividualOwnersAndOfficersQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    NonIndividualOwnersAndOfficersQuery,
    NonIndividualOwnersAndOfficersQueryVariables
  >(NonIndividualOwnersAndOfficersDocument, baseOptions);
}
export type NonIndividualOwnersAndOfficersQueryHookResult = ReturnType<
  typeof useNonIndividualOwnersAndOfficersQuery
>;
export type NonIndividualOwnersAndOfficersLazyQueryHookResult = ReturnType<
  typeof useNonIndividualOwnersAndOfficersLazyQuery
>;
export type NonIndividualOwnersAndOfficersQueryResult = Apollo.QueryResult<
  NonIndividualOwnersAndOfficersQuery,
  NonIndividualOwnersAndOfficersQueryVariables
>;
export const GetRMatchNonIndividualDocument = gql`
  query GetRMatchNonIndividual($params: String!) {
    rtcs_db_FnRPT_NonIndividualComposite(args: { params: $params }) {
      ...RMatchNonIndividual
    }
  }
  ${RMatchNonIndividualFragmentDoc}
`;

/**
 * __useGetRMatchNonIndividualQuery__
 *
 * To run a query within a React component, call `useGetRMatchNonIndividualQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRMatchNonIndividualQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRMatchNonIndividualQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetRMatchNonIndividualQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRMatchNonIndividualQuery,
    GetRMatchNonIndividualQueryVariables
  >
) {
  return Apollo.useQuery<
    GetRMatchNonIndividualQuery,
    GetRMatchNonIndividualQueryVariables
  >(GetRMatchNonIndividualDocument, baseOptions);
}
export function useGetRMatchNonIndividualLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRMatchNonIndividualQuery,
    GetRMatchNonIndividualQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetRMatchNonIndividualQuery,
    GetRMatchNonIndividualQueryVariables
  >(GetRMatchNonIndividualDocument, baseOptions);
}
export type GetRMatchNonIndividualQueryHookResult = ReturnType<
  typeof useGetRMatchNonIndividualQuery
>;
export type GetRMatchNonIndividualLazyQueryHookResult = ReturnType<
  typeof useGetRMatchNonIndividualLazyQuery
>;
export type GetRMatchNonIndividualQueryResult = Apollo.QueryResult<
  GetRMatchNonIndividualQuery,
  GetRMatchNonIndividualQueryVariables
>;
export const NonIndividualPropertiesDocument = gql`
  query NonIndividualProperties($params: String!) {
    rtcs_db_FnRPT_KccaPropertyRegistration(args: { params: $params }) {
      MapTitle
      Latitude
      Longitude
      CustomerId
      LocationAddress
      Address
      PlotNo
      FlatNo
      HouseNo
      BlockNo
      StreetId
      RoadOthers
      ParticularsFrontages
      Village
      Parish
      Division
      PropertyNo
      SerialNo
      GprsCordinateX
      GprsCordinateY
      PropertyType
      PropertySubType
      PropertyRentedStatus
      GrossValue
      RateableValue
      CurrentRateableValue
      StatusId
      CamvId
      CreatedBy
      CreatedDate
      Firstname
      Surname
      MotherMaidenName
      EntityLegalName
      Birthdate
      Mobile
      SecondMobile
      Email
      BusinessName
      Coin
      MiddleName
      Tin
      OwnerTin
      OwnerFirstname
      OwnerLegalSurnameMaiden
      OwnerTitle
      ContactMobile1
      ContactLandline
      ContactEmail
      AccomodationBedrooms
    }
  }
`;

/**
 * __useNonIndividualPropertiesQuery__
 *
 * To run a query within a React component, call `useNonIndividualPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonIndividualPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonIndividualPropertiesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useNonIndividualPropertiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    NonIndividualPropertiesQuery,
    NonIndividualPropertiesQueryVariables
  >
) {
  return Apollo.useQuery<
    NonIndividualPropertiesQuery,
    NonIndividualPropertiesQueryVariables
  >(NonIndividualPropertiesDocument, baseOptions);
}
export function useNonIndividualPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NonIndividualPropertiesQuery,
    NonIndividualPropertiesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    NonIndividualPropertiesQuery,
    NonIndividualPropertiesQueryVariables
  >(NonIndividualPropertiesDocument, baseOptions);
}
export type NonIndividualPropertiesQueryHookResult = ReturnType<
  typeof useNonIndividualPropertiesQuery
>;
export type NonIndividualPropertiesLazyQueryHookResult = ReturnType<
  typeof useNonIndividualPropertiesLazyQuery
>;
export type NonIndividualPropertiesQueryResult = Apollo.QueryResult<
  NonIndividualPropertiesQuery,
  NonIndividualPropertiesQueryVariables
>;
export const NonIndividualPropertyDetailsDocument = gql`
  query NonIndividualPropertyDetails($params: String!) {
    rtcs_db_Fnrmatch_NonIndividualComplianceDetailReport(
      args: { params: $params }
    ) {
      ...NonIndividualPropertyDetails
    }
  }
  ${NonIndividualPropertyDetailsFragmentDoc}
`;

/**
 * __useNonIndividualPropertyDetailsQuery__
 *
 * To run a query within a React component, call `useNonIndividualPropertyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonIndividualPropertyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonIndividualPropertyDetailsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useNonIndividualPropertyDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    NonIndividualPropertyDetailsQuery,
    NonIndividualPropertyDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    NonIndividualPropertyDetailsQuery,
    NonIndividualPropertyDetailsQueryVariables
  >(NonIndividualPropertyDetailsDocument, baseOptions);
}
export function useNonIndividualPropertyDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NonIndividualPropertyDetailsQuery,
    NonIndividualPropertyDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    NonIndividualPropertyDetailsQuery,
    NonIndividualPropertyDetailsQueryVariables
  >(NonIndividualPropertyDetailsDocument, baseOptions);
}
export type NonIndividualPropertyDetailsQueryHookResult = ReturnType<
  typeof useNonIndividualPropertyDetailsQuery
>;
export type NonIndividualPropertyDetailsLazyQueryHookResult = ReturnType<
  typeof useNonIndividualPropertyDetailsLazyQuery
>;
export type NonIndividualPropertyDetailsQueryResult = Apollo.QueryResult<
  NonIndividualPropertyDetailsQuery,
  NonIndividualPropertyDetailsQueryVariables
>;
export const NonIndividualsAssociatedIndividualsDocument = gql`
  query NonIndividualsAssociatedIndividuals($params: String!) {
    rtcs_db_Fnura_NonIndividualsAssociatedBusiness(args: { params: $params }) {
      ...NonIndividualsAssociatedIndividuals
    }
  }
  ${NonIndividualsAssociatedIndividualsFragmentDoc}
`;

/**
 * __useNonIndividualsAssociatedIndividualsQuery__
 *
 * To run a query within a React component, call `useNonIndividualsAssociatedIndividualsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonIndividualsAssociatedIndividualsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonIndividualsAssociatedIndividualsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useNonIndividualsAssociatedIndividualsQuery(
  baseOptions: Apollo.QueryHookOptions<
    NonIndividualsAssociatedIndividualsQuery,
    NonIndividualsAssociatedIndividualsQueryVariables
  >
) {
  return Apollo.useQuery<
    NonIndividualsAssociatedIndividualsQuery,
    NonIndividualsAssociatedIndividualsQueryVariables
  >(NonIndividualsAssociatedIndividualsDocument, baseOptions);
}
export function useNonIndividualsAssociatedIndividualsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NonIndividualsAssociatedIndividualsQuery,
    NonIndividualsAssociatedIndividualsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    NonIndividualsAssociatedIndividualsQuery,
    NonIndividualsAssociatedIndividualsQueryVariables
  >(NonIndividualsAssociatedIndividualsDocument, baseOptions);
}
export type NonIndividualsAssociatedIndividualsQueryHookResult = ReturnType<
  typeof useNonIndividualsAssociatedIndividualsQuery
>;
export type NonIndividualsAssociatedIndividualsLazyQueryHookResult = ReturnType<
  typeof useNonIndividualsAssociatedIndividualsLazyQuery
>;
export type NonIndividualsAssociatedIndividualsQueryResult = Apollo.QueryResult<
  NonIndividualsAssociatedIndividualsQuery,
  NonIndividualsAssociatedIndividualsQueryVariables
>;
export const KccaPropertiesDocument = gql`
  query KccaProperties($params: String!) {
    rtcs_db_FnRPT_KccaPropertyRegistrationData(args: { params: $params }) {
      ...KccaProperties
    }
  }
  ${KccaPropertiesFragmentDoc}
`;

/**
 * __useKccaPropertiesQuery__
 *
 * To run a query within a React component, call `useKccaPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaPropertiesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useKccaPropertiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    KccaPropertiesQuery,
    KccaPropertiesQueryVariables
  >
) {
  return Apollo.useQuery<KccaPropertiesQuery, KccaPropertiesQueryVariables>(
    KccaPropertiesDocument,
    baseOptions
  );
}
export function useKccaPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaPropertiesQuery,
    KccaPropertiesQueryVariables
  >
) {
  return Apollo.useLazyQuery<KccaPropertiesQuery, KccaPropertiesQueryVariables>(
    KccaPropertiesDocument,
    baseOptions
  );
}
export type KccaPropertiesQueryHookResult = ReturnType<
  typeof useKccaPropertiesQuery
>;
export type KccaPropertiesLazyQueryHookResult = ReturnType<
  typeof useKccaPropertiesLazyQuery
>;
export type KccaPropertiesQueryResult = Apollo.QueryResult<
  KccaPropertiesQuery,
  KccaPropertiesQueryVariables
>;
export const KccaPropertyDetailsDocument = gql`
  query KccaPropertyDetails($params: String!) {
    rtcs_db_Fnkcca_RawProperties1(args: { params: $params }) {
      ...KccaPropertyDetails
    }
  }
  ${KccaPropertyDetailsFragmentDoc}
`;

/**
 * __useKccaPropertyDetailsQuery__
 *
 * To run a query within a React component, call `useKccaPropertyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaPropertyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaPropertyDetailsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useKccaPropertyDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    KccaPropertyDetailsQuery,
    KccaPropertyDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaPropertyDetailsQuery,
    KccaPropertyDetailsQueryVariables
  >(KccaPropertyDetailsDocument, baseOptions);
}
export function useKccaPropertyDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaPropertyDetailsQuery,
    KccaPropertyDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaPropertyDetailsQuery,
    KccaPropertyDetailsQueryVariables
  >(KccaPropertyDetailsDocument, baseOptions);
}
export type KccaPropertyDetailsQueryHookResult = ReturnType<
  typeof useKccaPropertyDetailsQuery
>;
export type KccaPropertyDetailsLazyQueryHookResult = ReturnType<
  typeof useKccaPropertyDetailsLazyQuery
>;
export type KccaPropertyDetailsQueryResult = Apollo.QueryResult<
  KccaPropertyDetailsQuery,
  KccaPropertyDetailsQueryVariables
>;
export const KccaPropertyOwnerDocument = gql`
  query KccaPropertyOwner($params: String!) {
    rtcs_db_Fnkcca_Property(args: { params: $params }) {
      CustomerId
    }
  }
`;

/**
 * __useKccaPropertyOwnerQuery__
 *
 * To run a query within a React component, call `useKccaPropertyOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaPropertyOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaPropertyOwnerQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useKccaPropertyOwnerQuery(
  baseOptions: Apollo.QueryHookOptions<
    KccaPropertyOwnerQuery,
    KccaPropertyOwnerQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaPropertyOwnerQuery,
    KccaPropertyOwnerQueryVariables
  >(KccaPropertyOwnerDocument, baseOptions);
}
export function useKccaPropertyOwnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaPropertyOwnerQuery,
    KccaPropertyOwnerQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaPropertyOwnerQuery,
    KccaPropertyOwnerQueryVariables
  >(KccaPropertyOwnerDocument, baseOptions);
}
export type KccaPropertyOwnerQueryHookResult = ReturnType<
  typeof useKccaPropertyOwnerQuery
>;
export type KccaPropertyOwnerLazyQueryHookResult = ReturnType<
  typeof useKccaPropertyOwnerLazyQuery
>;
export type KccaPropertyOwnerQueryResult = Apollo.QueryResult<
  KccaPropertyOwnerQuery,
  KccaPropertyOwnerQueryVariables
>;
export const KccaCustomerAlternateOwnerDocument = gql`
  query KccaCustomerAlternateOwner($params: String!) {
    rtcs_db_Fnkcca_RawProperties1(args: { params: $params }) {
      ...KccaCustomerAlternateOwner
    }
  }
  ${KccaCustomerAlternateOwnerFragmentDoc}
`;

/**
 * __useKccaCustomerAlternateOwnerQuery__
 *
 * To run a query within a React component, call `useKccaCustomerAlternateOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerAlternateOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerAlternateOwnerQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useKccaCustomerAlternateOwnerQuery(
  baseOptions: Apollo.QueryHookOptions<
    KccaCustomerAlternateOwnerQuery,
    KccaCustomerAlternateOwnerQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaCustomerAlternateOwnerQuery,
    KccaCustomerAlternateOwnerQueryVariables
  >(KccaCustomerAlternateOwnerDocument, baseOptions);
}
export function useKccaCustomerAlternateOwnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerAlternateOwnerQuery,
    KccaCustomerAlternateOwnerQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerAlternateOwnerQuery,
    KccaCustomerAlternateOwnerQueryVariables
  >(KccaCustomerAlternateOwnerDocument, baseOptions);
}
export type KccaCustomerAlternateOwnerQueryHookResult = ReturnType<
  typeof useKccaCustomerAlternateOwnerQuery
>;
export type KccaCustomerAlternateOwnerLazyQueryHookResult = ReturnType<
  typeof useKccaCustomerAlternateOwnerLazyQuery
>;
export type KccaCustomerAlternateOwnerQueryResult = Apollo.QueryResult<
  KccaCustomerAlternateOwnerQuery,
  KccaCustomerAlternateOwnerQueryVariables
>;
export const KccaCustomerPrimaryOwnerDocument = gql`
  query KccaCustomerPrimaryOwner($params: String!) {
    rtcs_db_Fnkcca_Customer(args: { params: $params }) {
      ...KccaCustomerPrimaryOwner
    }
  }
  ${KccaCustomerPrimaryOwnerFragmentDoc}
`;

/**
 * __useKccaCustomerPrimaryOwnerQuery__
 *
 * To run a query within a React component, call `useKccaCustomerPrimaryOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerPrimaryOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerPrimaryOwnerQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useKccaCustomerPrimaryOwnerQuery(
  baseOptions: Apollo.QueryHookOptions<
    KccaCustomerPrimaryOwnerQuery,
    KccaCustomerPrimaryOwnerQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaCustomerPrimaryOwnerQuery,
    KccaCustomerPrimaryOwnerQueryVariables
  >(KccaCustomerPrimaryOwnerDocument, baseOptions);
}
export function useKccaCustomerPrimaryOwnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerPrimaryOwnerQuery,
    KccaCustomerPrimaryOwnerQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerPrimaryOwnerQuery,
    KccaCustomerPrimaryOwnerQueryVariables
  >(KccaCustomerPrimaryOwnerDocument, baseOptions);
}
export type KccaCustomerPrimaryOwnerQueryHookResult = ReturnType<
  typeof useKccaCustomerPrimaryOwnerQuery
>;
export type KccaCustomerPrimaryOwnerLazyQueryHookResult = ReturnType<
  typeof useKccaCustomerPrimaryOwnerLazyQuery
>;
export type KccaCustomerPrimaryOwnerQueryResult = Apollo.QueryResult<
  KccaCustomerPrimaryOwnerQuery,
  KccaCustomerPrimaryOwnerQueryVariables
>;
export const KccaCustomerSecondaryOwnerDocument = gql`
  query KccaCustomerSecondaryOwner($params: String!) {
    rtcs_db_FnRPT_KccaCustomerRegistrationData(args: { params: $params }) {
      ...KccaCustomerSecondaryOwner
    }
  }
  ${KccaCustomerSecondaryOwnerFragmentDoc}
`;

/**
 * __useKccaCustomerSecondaryOwnerQuery__
 *
 * To run a query within a React component, call `useKccaCustomerSecondaryOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerSecondaryOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerSecondaryOwnerQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useKccaCustomerSecondaryOwnerQuery(
  baseOptions: Apollo.QueryHookOptions<
    KccaCustomerSecondaryOwnerQuery,
    KccaCustomerSecondaryOwnerQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaCustomerSecondaryOwnerQuery,
    KccaCustomerSecondaryOwnerQueryVariables
  >(KccaCustomerSecondaryOwnerDocument, baseOptions);
}
export function useKccaCustomerSecondaryOwnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerSecondaryOwnerQuery,
    KccaCustomerSecondaryOwnerQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerSecondaryOwnerQuery,
    KccaCustomerSecondaryOwnerQueryVariables
  >(KccaCustomerSecondaryOwnerDocument, baseOptions);
}
export type KccaCustomerSecondaryOwnerQueryHookResult = ReturnType<
  typeof useKccaCustomerSecondaryOwnerQuery
>;
export type KccaCustomerSecondaryOwnerLazyQueryHookResult = ReturnType<
  typeof useKccaCustomerSecondaryOwnerLazyQuery
>;
export type KccaCustomerSecondaryOwnerQueryResult = Apollo.QueryResult<
  KccaCustomerSecondaryOwnerQuery,
  KccaCustomerSecondaryOwnerQueryVariables
>;
export const KccaCustomerBusinessAssociateAffiliatesDocument = gql`
  query KccaCustomerBusinessAssociateAffiliates($params: String!) {
    rtcs_db_Fnkcca_CustomerBusinessAssociate(args: { params: $params }) {
      ...KccaCustomerBusinessAssociateAffiliates
    }
  }
  ${KccaCustomerBusinessAssociateAffiliatesFragmentDoc}
`;

/**
 * __useKccaCustomerBusinessAssociateAffiliatesQuery__
 *
 * To run a query within a React component, call `useKccaCustomerBusinessAssociateAffiliatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerBusinessAssociateAffiliatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerBusinessAssociateAffiliatesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useKccaCustomerBusinessAssociateAffiliatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    KccaCustomerBusinessAssociateAffiliatesQuery,
    KccaCustomerBusinessAssociateAffiliatesQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaCustomerBusinessAssociateAffiliatesQuery,
    KccaCustomerBusinessAssociateAffiliatesQueryVariables
  >(KccaCustomerBusinessAssociateAffiliatesDocument, baseOptions);
}
export function useKccaCustomerBusinessAssociateAffiliatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerBusinessAssociateAffiliatesQuery,
    KccaCustomerBusinessAssociateAffiliatesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerBusinessAssociateAffiliatesQuery,
    KccaCustomerBusinessAssociateAffiliatesQueryVariables
  >(KccaCustomerBusinessAssociateAffiliatesDocument, baseOptions);
}
export type KccaCustomerBusinessAssociateAffiliatesQueryHookResult = ReturnType<
  typeof useKccaCustomerBusinessAssociateAffiliatesQuery
>;
export type KccaCustomerBusinessAssociateAffiliatesLazyQueryHookResult =
  ReturnType<typeof useKccaCustomerBusinessAssociateAffiliatesLazyQuery>;
export type KccaCustomerBusinessAssociateAffiliatesQueryResult =
  Apollo.QueryResult<
    KccaCustomerBusinessAssociateAffiliatesQuery,
    KccaCustomerBusinessAssociateAffiliatesQueryVariables
  >;
export const KccaCustomerContactPersonAffiliatesDocument = gql`
  query KccaCustomerContactPersonAffiliates($params: String!) {
    rtcs_db_Fnkcca_CustomerContactPerson(args: { params: $params }) {
      ...KccaCustomerContactPersonAffiliates
    }
  }
  ${KccaCustomerContactPersonAffiliatesFragmentDoc}
`;

/**
 * __useKccaCustomerContactPersonAffiliatesQuery__
 *
 * To run a query within a React component, call `useKccaCustomerContactPersonAffiliatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerContactPersonAffiliatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerContactPersonAffiliatesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useKccaCustomerContactPersonAffiliatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    KccaCustomerContactPersonAffiliatesQuery,
    KccaCustomerContactPersonAffiliatesQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaCustomerContactPersonAffiliatesQuery,
    KccaCustomerContactPersonAffiliatesQueryVariables
  >(KccaCustomerContactPersonAffiliatesDocument, baseOptions);
}
export function useKccaCustomerContactPersonAffiliatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerContactPersonAffiliatesQuery,
    KccaCustomerContactPersonAffiliatesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerContactPersonAffiliatesQuery,
    KccaCustomerContactPersonAffiliatesQueryVariables
  >(KccaCustomerContactPersonAffiliatesDocument, baseOptions);
}
export type KccaCustomerContactPersonAffiliatesQueryHookResult = ReturnType<
  typeof useKccaCustomerContactPersonAffiliatesQuery
>;
export type KccaCustomerContactPersonAffiliatesLazyQueryHookResult = ReturnType<
  typeof useKccaCustomerContactPersonAffiliatesLazyQuery
>;
export type KccaCustomerContactPersonAffiliatesQueryResult = Apollo.QueryResult<
  KccaCustomerContactPersonAffiliatesQuery,
  KccaCustomerContactPersonAffiliatesQueryVariables
>;
export const KccaCustomerReferenceAffiliatesDocument = gql`
  query KccaCustomerReferenceAffiliates($params: String!) {
    rtcs_db_Fnkcca_CustomerReference(args: { params: $params }) {
      ...KccaCustomerReferenceAffiliates
    }
  }
  ${KccaCustomerReferenceAffiliatesFragmentDoc}
`;

/**
 * __useKccaCustomerReferenceAffiliatesQuery__
 *
 * To run a query within a React component, call `useKccaCustomerReferenceAffiliatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerReferenceAffiliatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerReferenceAffiliatesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useKccaCustomerReferenceAffiliatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    KccaCustomerReferenceAffiliatesQuery,
    KccaCustomerReferenceAffiliatesQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaCustomerReferenceAffiliatesQuery,
    KccaCustomerReferenceAffiliatesQueryVariables
  >(KccaCustomerReferenceAffiliatesDocument, baseOptions);
}
export function useKccaCustomerReferenceAffiliatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerReferenceAffiliatesQuery,
    KccaCustomerReferenceAffiliatesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerReferenceAffiliatesQuery,
    KccaCustomerReferenceAffiliatesQueryVariables
  >(KccaCustomerReferenceAffiliatesDocument, baseOptions);
}
export type KccaCustomerReferenceAffiliatesQueryHookResult = ReturnType<
  typeof useKccaCustomerReferenceAffiliatesQuery
>;
export type KccaCustomerReferenceAffiliatesLazyQueryHookResult = ReturnType<
  typeof useKccaCustomerReferenceAffiliatesLazyQuery
>;
export type KccaCustomerReferenceAffiliatesQueryResult = Apollo.QueryResult<
  KccaCustomerReferenceAffiliatesQuery,
  KccaCustomerReferenceAffiliatesQueryVariables
>;
export const KccaCustomerRevenueAgentAffiliatesDocument = gql`
  query KccaCustomerRevenueAgentAffiliates($params: String!) {
    rtcs_db_Fnkcca_CustomerRevenueAgent(args: { params: $params }) {
      ...KccaCustomerRevenueAgentAffiliates
    }
  }
  ${KccaCustomerRevenueAgentAffiliatesFragmentDoc}
`;

/**
 * __useKccaCustomerRevenueAgentAffiliatesQuery__
 *
 * To run a query within a React component, call `useKccaCustomerRevenueAgentAffiliatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useKccaCustomerRevenueAgentAffiliatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKccaCustomerRevenueAgentAffiliatesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useKccaCustomerRevenueAgentAffiliatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    KccaCustomerRevenueAgentAffiliatesQuery,
    KccaCustomerRevenueAgentAffiliatesQueryVariables
  >
) {
  return Apollo.useQuery<
    KccaCustomerRevenueAgentAffiliatesQuery,
    KccaCustomerRevenueAgentAffiliatesQueryVariables
  >(KccaCustomerRevenueAgentAffiliatesDocument, baseOptions);
}
export function useKccaCustomerRevenueAgentAffiliatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KccaCustomerRevenueAgentAffiliatesQuery,
    KccaCustomerRevenueAgentAffiliatesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    KccaCustomerRevenueAgentAffiliatesQuery,
    KccaCustomerRevenueAgentAffiliatesQueryVariables
  >(KccaCustomerRevenueAgentAffiliatesDocument, baseOptions);
}
export type KccaCustomerRevenueAgentAffiliatesQueryHookResult = ReturnType<
  typeof useKccaCustomerRevenueAgentAffiliatesQuery
>;
export type KccaCustomerRevenueAgentAffiliatesLazyQueryHookResult = ReturnType<
  typeof useKccaCustomerRevenueAgentAffiliatesLazyQuery
>;
export type KccaCustomerRevenueAgentAffiliatesQueryResult = Apollo.QueryResult<
  KccaCustomerRevenueAgentAffiliatesQuery,
  KccaCustomerRevenueAgentAffiliatesQueryVariables
>;
export const NwscCustomerInfoDocument = gql`
  query NwscCustomerInfo($params: String!) {
    rtcs_db_Fnnwsc_Customers(args: { params: $params }) {
      ...PropertyUtilities
    }
  }
  ${PropertyUtilitiesFragmentDoc}
`;

/**
 * __useNwscCustomerInfoQuery__
 *
 * To run a query within a React component, call `useNwscCustomerInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useNwscCustomerInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNwscCustomerInfoQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useNwscCustomerInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    NwscCustomerInfoQuery,
    NwscCustomerInfoQueryVariables
  >
) {
  return Apollo.useQuery<NwscCustomerInfoQuery, NwscCustomerInfoQueryVariables>(
    NwscCustomerInfoDocument,
    baseOptions
  );
}
export function useNwscCustomerInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NwscCustomerInfoQuery,
    NwscCustomerInfoQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    NwscCustomerInfoQuery,
    NwscCustomerInfoQueryVariables
  >(NwscCustomerInfoDocument, baseOptions);
}
export type NwscCustomerInfoQueryHookResult = ReturnType<
  typeof useNwscCustomerInfoQuery
>;
export type NwscCustomerInfoLazyQueryHookResult = ReturnType<
  typeof useNwscCustomerInfoLazyQuery
>;
export type NwscCustomerInfoQueryResult = Apollo.QueryResult<
  NwscCustomerInfoQuery,
  NwscCustomerInfoQueryVariables
>;
export const PropertyUtilitiesDocument = gql`
  query PropertyUtilities($params: String!) {
    rtcs_db_Fnnwsc_RPT_Transactions(args: { params: $params }) {
      ...NwscTransactions
    }
  }
  ${NwscTransactionsFragmentDoc}
`;

/**
 * __usePropertyUtilitiesQuery__
 *
 * To run a query within a React component, call `usePropertyUtilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertyUtilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertyUtilitiesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function usePropertyUtilitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    PropertyUtilitiesQuery,
    PropertyUtilitiesQueryVariables
  >
) {
  return Apollo.useQuery<
    PropertyUtilitiesQuery,
    PropertyUtilitiesQueryVariables
  >(PropertyUtilitiesDocument, baseOptions);
}
export function usePropertyUtilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PropertyUtilitiesQuery,
    PropertyUtilitiesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    PropertyUtilitiesQuery,
    PropertyUtilitiesQueryVariables
  >(PropertyUtilitiesDocument, baseOptions);
}
export type PropertyUtilitiesQueryHookResult = ReturnType<
  typeof usePropertyUtilitiesQuery
>;
export type PropertyUtilitiesLazyQueryHookResult = ReturnType<
  typeof usePropertyUtilitiesLazyQuery
>;
export type PropertyUtilitiesQueryResult = Apollo.QueryResult<
  PropertyUtilitiesQuery,
  PropertyUtilitiesQueryVariables
>;
export const NwscTransactionsDocument = gql`
  query NwscTransactions($params: String!) {
    rtcs_db_Fnnwsc_RPT_Transactions(args: { params: $params }) {
      ...NwscTransactions
    }
  }
  ${NwscTransactionsFragmentDoc}
`;

/**
 * __useNwscTransactionsQuery__
 *
 * To run a query within a React component, call `useNwscTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNwscTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNwscTransactionsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useNwscTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    NwscTransactionsQuery,
    NwscTransactionsQueryVariables
  >
) {
  return Apollo.useQuery<NwscTransactionsQuery, NwscTransactionsQueryVariables>(
    NwscTransactionsDocument,
    baseOptions
  );
}
export function useNwscTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NwscTransactionsQuery,
    NwscTransactionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    NwscTransactionsQuery,
    NwscTransactionsQueryVariables
  >(NwscTransactionsDocument, baseOptions);
}
export type NwscTransactionsQueryHookResult = ReturnType<
  typeof useNwscTransactionsQuery
>;
export type NwscTransactionsLazyQueryHookResult = ReturnType<
  typeof useNwscTransactionsLazyQuery
>;
export type NwscTransactionsQueryResult = Apollo.QueryResult<
  NwscTransactionsQuery,
  NwscTransactionsQueryVariables
>;
export const MinMaxUraTransactionsDocument = gql`
  query MinMaxURATransactions($aggregateParams: String!) {
    rtcs_db_Fnnwsc_RPT_Transactions_aggregatetxt(
      args: {
        aggregate_params: "{\\"max\\": \\"Period\\",\\"min\\": \\"Period\\" }"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
`;

/**
 * __useMinMaxUraTransactionsQuery__
 *
 * To run a query within a React component, call `useMinMaxUraTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMinMaxUraTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMinMaxUraTransactionsQuery({
 *   variables: {
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useMinMaxUraTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MinMaxUraTransactionsQuery,
    MinMaxUraTransactionsQueryVariables
  >
) {
  return Apollo.useQuery<
    MinMaxUraTransactionsQuery,
    MinMaxUraTransactionsQueryVariables
  >(MinMaxUraTransactionsDocument, baseOptions);
}
export function useMinMaxUraTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MinMaxUraTransactionsQuery,
    MinMaxUraTransactionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    MinMaxUraTransactionsQuery,
    MinMaxUraTransactionsQueryVariables
  >(MinMaxUraTransactionsDocument, baseOptions);
}
export type MinMaxUraTransactionsQueryHookResult = ReturnType<
  typeof useMinMaxUraTransactionsQuery
>;
export type MinMaxUraTransactionsLazyQueryHookResult = ReturnType<
  typeof useMinMaxUraTransactionsLazyQuery
>;
export type MinMaxUraTransactionsQueryResult = Apollo.QueryResult<
  MinMaxUraTransactionsQuery,
  MinMaxUraTransactionsQueryVariables
>;
export const GetVisibilityIndividualCompositeDocument = gql`
  query GetVisibilityIndividualComposite($params: String!) {
    rtcs_db_FnRPT_IndividualComposite(args: { params: $params }) {
      ...IndividualCompositeVisibilityData
    }
  }
  ${IndividualCompositeVisibilityDataFragmentDoc}
`;

/**
 * __useGetVisibilityIndividualCompositeQuery__
 *
 * To run a query within a React component, call `useGetVisibilityIndividualCompositeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVisibilityIndividualCompositeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVisibilityIndividualCompositeQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetVisibilityIndividualCompositeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVisibilityIndividualCompositeQuery,
    GetVisibilityIndividualCompositeQueryVariables
  >
) {
  return Apollo.useQuery<
    GetVisibilityIndividualCompositeQuery,
    GetVisibilityIndividualCompositeQueryVariables
  >(GetVisibilityIndividualCompositeDocument, baseOptions);
}
export function useGetVisibilityIndividualCompositeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVisibilityIndividualCompositeQuery,
    GetVisibilityIndividualCompositeQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetVisibilityIndividualCompositeQuery,
    GetVisibilityIndividualCompositeQueryVariables
  >(GetVisibilityIndividualCompositeDocument, baseOptions);
}
export type GetVisibilityIndividualCompositeQueryHookResult = ReturnType<
  typeof useGetVisibilityIndividualCompositeQuery
>;
export type GetVisibilityIndividualCompositeLazyQueryHookResult = ReturnType<
  typeof useGetVisibilityIndividualCompositeLazyQuery
>;
export type GetVisibilityIndividualCompositeQueryResult = Apollo.QueryResult<
  GetVisibilityIndividualCompositeQuery,
  GetVisibilityIndividualCompositeQueryVariables
>;
export const GetPaginatedVisibilityIndividualCompositeDocument = gql`
  query GetPaginatedVisibilityIndividualComposite(
    $params: String!
    $aggregateParams: String!
  ) {
    rtcs_db_FnRPT_IndividualComposite(args: { params: $params }) {
      ...IndividualCompositeVisibilityData
    }
    rtcs_db_FnRPT_IndividualComposite_aggregatecm(
      args: {
        aggregate_params: "{\\"count\\": \\"\\"}"
        params: $aggregateParams
      }
    ) {
      operation
      value
    }
  }
  ${IndividualCompositeVisibilityDataFragmentDoc}
`;

/**
 * __useGetPaginatedVisibilityIndividualCompositeQuery__
 *
 * To run a query within a React component, call `useGetPaginatedVisibilityIndividualCompositeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedVisibilityIndividualCompositeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedVisibilityIndividualCompositeQuery({
 *   variables: {
 *      params: // value for 'params'
 *      aggregateParams: // value for 'aggregateParams'
 *   },
 * });
 */
export function useGetPaginatedVisibilityIndividualCompositeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaginatedVisibilityIndividualCompositeQuery,
    GetPaginatedVisibilityIndividualCompositeQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaginatedVisibilityIndividualCompositeQuery,
    GetPaginatedVisibilityIndividualCompositeQueryVariables
  >(GetPaginatedVisibilityIndividualCompositeDocument, baseOptions);
}
export function useGetPaginatedVisibilityIndividualCompositeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaginatedVisibilityIndividualCompositeQuery,
    GetPaginatedVisibilityIndividualCompositeQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaginatedVisibilityIndividualCompositeQuery,
    GetPaginatedVisibilityIndividualCompositeQueryVariables
  >(GetPaginatedVisibilityIndividualCompositeDocument, baseOptions);
}
export type GetPaginatedVisibilityIndividualCompositeQueryHookResult =
  ReturnType<typeof useGetPaginatedVisibilityIndividualCompositeQuery>;
export type GetPaginatedVisibilityIndividualCompositeLazyQueryHookResult =
  ReturnType<typeof useGetPaginatedVisibilityIndividualCompositeLazyQuery>;
export type GetPaginatedVisibilityIndividualCompositeQueryResult =
  Apollo.QueryResult<
    GetPaginatedVisibilityIndividualCompositeQuery,
    GetPaginatedVisibilityIndividualCompositeQueryVariables
  >;
